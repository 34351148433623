import React, { useState } from 'react';

import StateDropDown from '../state-drop-down/state-drop-down';
import CongressionalDistrictList from '../congressional-district-list/congressional-district-list';

const NoCDL = <div />;

const ListCandidateForm = () => {
  const [stateOrTerritory, setStateOrTerritory] = useState('-');
  const handleStateChange = (selected) => {
    setStateOrTerritory(selected);
  };

  return (
    <div className='list-candidate-form'>
      <h1>List Candidates</h1>
      <StateDropDown callback={handleStateChange} />
      {stateOrTerritory !== '-' ? (
        <CongressionalDistrictList state={stateOrTerritory} />
      ) : (
        NoCDL
      )}
    </div>
  );
};

export default ListCandidateForm;
