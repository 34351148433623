const mergeCandidates = (dataSets) => {
  const returnData = [];
  dataSets.data.getFecCandidatesByState.forEach((fecItem) => {
    const candidateObj = fecItem;
    dataSets.data.candidatesByState.items.forEach((skimbotItem) => {
      if (skimbotItem.candidate_id === fecItem.candidate_id) {
        candidateObj.supporter = skimbotItem.supporter;
        candidateObj.candidate_url = skimbotItem.candidate_url;
        candidateObj.imported = true;
        candidateObj.messages = skimbotItem.messages;
      }
    });
    returnData.push(fecItem);
  });
  return returnData;
};

export default mergeCandidates;
