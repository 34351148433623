import React from 'react';

import ListCandidatesForm from '../list-candidates-form/list-candidate-form';
import Messages from '../messages/messages';

const Menu = () => {
  return (
    <div>
      <Messages />
      <ListCandidatesForm />
    </div>
  );
};

export default Menu;
