import React, { useState } from 'react';
import { API, graphqlOperation } from 'aws-amplify';

import MessageItem from '../message-item/message-item';

import { messagesSortedByCreated } from '../../graphql/queries';

const fetchMessagesGraphql = async () => {
  try {
    const mes = await API.graphql(
      graphqlOperation(messagesSortedByCreated, {
        limit: 20,
        data_type: 'MESSAGE',
        sortDirection: 'DESC',
      })
    );
    return mes.data.messagesSortedByCreated.items;
  } catch (err) {
    console.log('error fetching messages', err);
    return Promise.reject(err);
  }
};

const Messages = (props) => {
  const [messages, setMessages] = useState([]);
  const [buttonText, setButtonText] = useState('view recent messages');

  const clickMessages = async (e) => {
    if (messages.length > 0) {
      setMessages([]);
      setButtonText('show recent messages');
    } else {
      setMessages(await fetchMessagesGraphql());
      setButtonText('hide recent messages');
    }
  };

  const messageList = messages.map((message) => (
    <MessageItem key={message.id} message={message} />
  ));

  return (
    <div className='messageContainer'>
      <button className='messages-button' onClick={clickMessages}>
        {buttonText}
      </button>
      <div className='messages'>{messageList}</div>
    </div>
  );
};

export default Messages;
