import axios from 'axios';
import { API, graphqlOperation } from 'aws-amplify';

import domains from '../../data/domainOptions';
import districts from '../../data/districts-cleaned.js';

import { buyPhoneNumberFromTwilio } from '../../graphql/queries';

const getAddress = (state, district) => {
  const filterString = district === 0 ? `${state}-1` : `${state}-${district}`;
  let dist = districts.filter((el) => el.name === filterString)[0];
  if (!dist) {
    // nothing found, try a single district state query
    dist = districts.filter((el) => el.name === `${state}-${district}`)[0];
  }
  const address = dist.addressesInDistrict[0];
  if (!address) throw new Error('no address found');
  return address;
};

const createEmail = (first, last) => {
  if (!first || !last) return;
  const d = domains[Math.floor(Math.random() * domains.length)];
  return `${first.toLowerCase()}.${last.toLowerCase()}@${d}`;
};

const fetchRandomUser = async () => {
  var params = new URLSearchParams();
  params.append('nat', 'us');
  const data = await axios.get('https://randomuser.me/api/', {
    params: params,
  });
  return data.data.results[0];
};

const buyPhoneNumber = async (state, candidate_id) => {
  if (process.env.REACT_APP_ENV !== 'prod') {
    if (process.env.REACT_APP_ENV !== 'test')
      console.log(
        `NOT PRODUCTION, DO NOT BUY NUMBER ${process.env.REACT_APP_ENV}`
      );
    return {
      phone_number: null,
      sid: 'PN3e0053bf50c248320879f49ac3f12e6a',
      friendly_name: null,
      number_used_for_purchase: '+1555',
      friendly_name_used_for_purchase: 'DEV-RESPONSE',
    };
  }

  const phoneObj = await API.graphql(
    graphqlOperation(buyPhoneNumberFromTwilio, {
      state: state,
      candidate_id: candidate_id,
    })
  );
  const retVal = phoneObj.data.buyPhoneNumberFromTwilio;
  return retVal;
};

const createSupporter = async (state, district, candidate_id) => {
  const supporter = {};
  const rando = await fetchRandomUser();
  supporter.name = `${rando.name.first} ${rando.name.last}`;
  supporter.email = createEmail(rando.name.first, rando.name.last);
  const address = getAddress(state, district);
  supporter.address1 = address.RegAddrLine1;
  supporter.city = address.RegAddrCity;
  supporter.state = state;
  supporter.zip = address.RegAddrZip;
  supporter.city = address.RegAddrCity;
  supporter.congressional_district = address.CongressionalDistrict;
  const phone = await buyPhoneNumber(state, candidate_id);
  supporter.phone = phone.number_used_for_purchase.replace('+1', '');
  return Promise.resolve(supporter);
};

export default createSupporter;
