import React, { useState } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import DOMPurify from 'dompurify';
import { format } from 'timeago.js';

import { getMessage } from '../../graphql/queries';

const MessageItem = (props) => {
  const { message } = props;
  const [messageHtml, setMessageHtml] = useState({
    __html: DOMPurify.sanitize(''),
  });

  let icon = '';
  switch (message.base_type) {
    case 'SMS':
      icon = 'sms';
      break;
    case 'VOICE':
      icon = 'local_phone';
      break;
    case 'EMAIL':
      icon = 'email';
      break;
    default:
  }

  const createMarkup = (m) => {
    return { __html: DOMPurify.sanitize(m) };
  };

  const doGetMessage = async () => {
    const el = document.getElementById(message.id);

    // it's set so turn it off...
    if (messageHtml.__html.length > 1) {
      setMessageHtml(createMarkup(''));
      el.classList.remove('show-full');
      el.classList.add('hide-full');
      return Promise.resolve('');
    }

    el.classList.add('show-full');
    el.classList.remove('hide-full');

    try {
      const mes = await API.graphql(
        graphqlOperation(getMessage, { id: message.id })
      );
      setMessageHtml(createMarkup(mes.data.getMessage.message_html));
      return Promise.resolve(mes.data.getMessage.message_html);
    } catch (err) {
      console.log('error fetching messages', err);
      return Promise.reject(err);
    }
  };

  return (
    <div key={message.id} id={message.id} className='message hide-full'>
      <div>
        <button className='as-link toggle-full-message' onClick={doGetMessage}>
          <i className='expand_more material-icons'>expand_more</i>
          <i className='expand_less material-icons'>expand_less</i>
        </button>
        <i className='message-icon material-icons'>{icon}</i>
      </div>
      <div className='message-from'>
        FROM {message.candidate.name} - {format(message.createdAt)}
      </div>
      <div
        className='message-full-message'
        dangerouslySetInnerHTML={messageHtml}
      />
    </div>
  );
};

export default MessageItem;
