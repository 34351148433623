/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:2397ad50-49f5-430b-8da2-c12ef116d0b8",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_yTUTBvpaQ",
    "aws_user_pools_web_client_id": "6lli26njn76av7pq5u9b110men",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://6fj54s52prfvxpcuj46m4umqvq.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-zyecdnp7cnbpzifxbiisp5ucxa",
    "aws_cloud_logic_custom": [
        {
            "name": "twilioRestAPI",
            "endpoint": "https://oevket3fz8.execute-api.us-east-1.amazonaws.com/prod",
            "region": "us-east-1"
        }
    ],
    "aws_user_files_s3_bucket": "skimbot-data-files120045-prod",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
