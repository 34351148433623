/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getFecCandidatesByState = /* GraphQL */ `
  query GetFecCandidatesByState($state: String) {
    getFecCandidatesByState(state: $state) {
      candidate_id
      name
      party_full
      party
      state
      district
      district_number
      office
      office_full
      candidate_status
      federal_funds_flag
      has_raised_funds
      receipts
    }
  }
`;
export const buyPhoneNumberFromTwilio = /* GraphQL */ `
  query BuyPhoneNumberFromTwilio($state: String, $candidate_id: String) {
    buyPhoneNumberFromTwilio(state: $state, candidate_id: $candidate_id) {
      sid
      account_sid
      friendly_name
      phone_number
      date_created
      date_updated
      uri
      raw
      number_used_for_purchase
      friendly_name_used_for_purchase
    }
  }
`;
export const getCandidate = /* GraphQL */ `
  query GetCandidate($id: ID!) {
    getCandidate(id: $id) {
      id
      name
      candidate_id
      party_full
      party
      state
      district
      district_number
      office
      office_full
      candidate_status
      federal_funds_flag
      has_raised_funds
      receipts
      supporter {
        name
        address1
        address2
        city
        state
        zip
        email
        phone
        congressional_district
      }
      supporter_email
      supporter_phone
      candidate_url
      messages {
        items {
          data_type
          id
          candidate_id
          from
          to
          subject
          message_text
          message_html
          message_html_as_text
          message_raw
          base_type
          created
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listCandidates = /* GraphQL */ `
  query ListCandidates(
    $filter: ModelCandidateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCandidates(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        candidate_id
        party_full
        party
        state
        district
        district_number
        office
        office_full
        candidate_status
        federal_funds_flag
        has_raised_funds
        receipts
        supporter {
          name
          address1
          address2
          city
          state
          zip
          email
          phone
          congressional_district
        }
        supporter_email
        supporter_phone
        candidate_url
        messages {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMessage = /* GraphQL */ `
  query GetMessage($id: ID!) {
    getMessage(id: $id) {
      data_type
      id
      candidate_id
      from
      to
      subject
      message_text
      message_html
      message_html_as_text
      message_raw
      base_type
      created
      candidate {
        id
        name
        candidate_id
        party_full
        party
        state
        district
        district_number
        office
        office_full
        candidate_status
        federal_funds_flag
        has_raised_funds
        receipts
        supporter {
          name
          address1
          address2
          city
          state
          zip
          email
          phone
          congressional_district
        }
        supporter_email
        supporter_phone
        candidate_url
        messages {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listMessages = /* GraphQL */ `
  query ListMessages(
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMessages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        data_type
        id
        candidate_id
        from
        to
        subject
        message_text
        message_html
        message_html_as_text
        message_raw
        base_type
        created
        candidate {
          id
          name
          candidate_id
          party_full
          party
          state
          district
          district_number
          office
          office_full
          candidate_status
          federal_funds_flag
          has_raised_funds
          receipts
          supporter_email
          supporter_phone
          candidate_url
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const candidatesByState = /* GraphQL */ `
  query CandidatesByState(
    $state: String
    $district_number: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCandidateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    candidatesByState(
      state: $state
      district_number: $district_number
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        candidate_id
        party_full
        party
        state
        district
        district_number
        office
        office_full
        candidate_status
        federal_funds_flag
        has_raised_funds
        receipts
        supporter {
          name
          address1
          address2
          city
          state
          zip
          email
          phone
          congressional_district
        }
        supporter_email
        supporter_phone
        candidate_url
        messages {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const candidatesBySupporterEmail = /* GraphQL */ `
  query CandidatesBySupporterEmail(
    $supporter_email: String
    $district_number: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCandidateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    candidatesBySupporterEmail(
      supporter_email: $supporter_email
      district_number: $district_number
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        candidate_id
        party_full
        party
        state
        district
        district_number
        office
        office_full
        candidate_status
        federal_funds_flag
        has_raised_funds
        receipts
        supporter {
          name
          address1
          address2
          city
          state
          zip
          email
          phone
          congressional_district
        }
        supporter_email
        supporter_phone
        candidate_url
        messages {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const candidatesBySupporterPhone = /* GraphQL */ `
  query CandidatesBySupporterPhone(
    $supporter_phone: String
    $district_number: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCandidateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    candidatesBySupporterPhone(
      supporter_phone: $supporter_phone
      district_number: $district_number
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        candidate_id
        party_full
        party
        state
        district
        district_number
        office
        office_full
        candidate_status
        federal_funds_flag
        has_raised_funds
        receipts
        supporter {
          name
          address1
          address2
          city
          state
          zip
          email
          phone
          congressional_district
        }
        supporter_email
        supporter_phone
        candidate_url
        messages {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const messagesSortedByCreated = /* GraphQL */ `
  query MessagesSortedByCreated(
    $data_type: String
    $created: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    messagesSortedByCreated(
      data_type: $data_type
      created: $created
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        data_type
        id
        candidate_id
        from
        to
        subject
        message_text
        message_html
        message_html_as_text
        message_raw
        base_type
        created
        candidate {
          id
          name
          candidate_id
          party_full
          party
          state
          district
          district_number
          office
          office_full
          candidate_status
          federal_funds_flag
          has_raised_funds
          receipts
          supporter_email
          supporter_phone
          candidate_url
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
