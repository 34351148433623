import React from 'react';

import statesAndTerritories from '../../data/states-with-districts';

statesAndTerritories.unshift(
  {
    state: '-',
    stateName: '-- please pick a state --',
  },
  {
    state: 'US',
    stateName: '🇺🇸 United States',
  }
);

const StateDropDown = (props) => {
  const handleChange = (event) => {
    if (props.callback) {
      props.callback(event.target.value);
    }
  };

  const options = statesAndTerritories.map((option, i) => {
    return (
      <option value={option.state} key={`key${option.state}`}>
        {option.stateName}
      </option>
    );
  });

  return (
    <div className='state-drop-down'>
      <label htmlFor='state-select'>
        <h3>Select a State / Territory:</h3>
      </label>

      <select id='state-select' onChange={handleChange}>
        <optgroup className='state-drop-down-optgroup'>{options}</optgroup>
      </select>
    </div>
  );
};

export default StateDropDown;
