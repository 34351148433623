import React, { useState } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import validator from 'validator';

import createSupporter from '../../utils/create-supporter/create-supporter';
import * as mutations from '../../graphql/mutations';

let clickEl;
const copyToClipBoard = (t) => {
  const el = document.createElement('textarea');
  clickEl = t.target;
  clickEl.classList.add('just-clicked');
  el.value = t.target.innerHTML;
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
  setTimeout(() => clickEl.classList.remove('just-clicked'), 100);
};

const combineAndCopyToClipBoard = (t) => {
  const name = `${document.getElementById('supporter-first').innerHTML} ${
    document.getElementById('supporter-last').innerHTML
  }`;
  const el = document.createElement('textarea');
  clickEl = t.target;
  clickEl.classList.add('just-clicked');
  el.value = name;
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
  setTimeout(() => clickEl.classList.remove('just-clicked'), 100);
};

const CandidateModal = (props) => {
  const c = props.candidate;
  c.candidate_url = c.candidate_url || '';
  const [candidate, setCandidate] = useState(c);
  const [supporter, setSupporter] = useState(c.supporter);
  const [candidateUrl, setCandidateUrl] = useState(c.candidate_url);
  const [disableUrlEntry, setDisableUrlEntry] = useState(candidateUrl !== '');
  const [urlError, setUrlError] = useState(false);
  const { closeHandler } = props;
  const { name, googleLink, district_number, state, candidate_id } = c;

  const [closed, setClosed] = useState(false);

  const closeModal = () => {
    setClosed(true);
    closeHandler();
  };

  const copyCandidate = async () => {
    if (!candidate) return false;
    if (closed) return false;

    const newSupporter = await createSupporter(
      state,
      district_number,
      candidate_id
    );
    setSupporter(newSupporter);

    const candidateWithSupporter = Object.assign(candidate, { supporter });
    setCandidate(candidateWithSupporter);

    if (props.updateHandler) {
      props.updateHandler(newSupporter);
    }
    // props.updateHandler(s);
    // candidate.supporter = s;

    const validGraphqlCandidateFields = [
      'id',
      'name',
      'candidate_id',
      'party_full',
      'party',
      'state',
      'district',
      'district_number',
      'office',
      'office_full',
      'candidate_status',
      'federal_funds_flag',
      'has_raised_funds',
      'receipts',
      'supporter',
      'candidate_url',
    ];
    const candidateClean = {};
    validGraphqlCandidateFields.forEach((field) => {
      candidateClean[field] = candidate[field];
    });
    candidateClean.id = candidate.candidate_id;
    candidateClean.supporter_email = newSupporter.email;
    candidateClean.supporter_phone = newSupporter.phone;
    try {
      await API.graphql(
        graphqlOperation(mutations.createCandidate, { input: candidateClean })
      );
    } catch (err) {
      console.log('error: ', err);
    }
  };

  const handleEnterUrl = async () => {
    const url = document.getElementById('candidate-url').value;
    if (validator.isURL(url)) {
      setCandidateUrl(url);
      setDisableUrlEntry(true);
      setCandidate(Object.assign(candidate, { candidate_url: url }));
      const params = {
        input: {
          candidate_url: url,
          id: candidate.candidate_id,
        },
      };
      await API.graphql(graphqlOperation(mutations.updateCandidate, params));
    } else {
      // setCandidateUrl('');
      setUrlError(true);
    }
  };

  const handleUrlChange = (event) => {
    setCandidateUrl(event.target.value);
    setUrlError(false);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleEnterUrl();
    }
  };

  const supporterField = (value) => (
    <div className={`supporter-field-container supporter-${value}`}>
      <p className='supporter-fieldname'>{value}</p>
      <p className='copyable' onClick={copyToClipBoard}>
        {supporter[value]}
      </p>
    </div>
  );

  const createOrShowSupporter = () => {
    const [first, last] = supporter ? supporter.name.split(' ') : ['', ''];

    return supporter ? (
      <div className='supporter-info'>
        <div className='candidate-url'>
          <label htmlFor='candidate-url'>
            Candidate Website{' '}
            {urlError ? <b className='url-error'>BAD URL, PLEASE FIX</b> : ''}
          </label>
          <input
            disabled={disableUrlEntry ? 'disabled' : ''}
            className={urlError ? 'url-error' : ''}
            id='candidate-url'
            type='text'
            placeholder='https://example.candidate.com/volunteer'
            value={candidateUrl}
            onChange={handleUrlChange}
            onKeyDown={handleKeyDown}
          />
          <button
            disabled={disableUrlEntry ? 'disabled' : ''}
            className='as-link material-icons'
            title='Enter URL'
            onClick={handleEnterUrl}
          >
            assignment_turned_in
          </button>
        </div>
        <h3>Supporter Information</h3>
        <div className='supporter-name-container'>
          <div className={`supporter-field-container supporter-first`}>
            <p className='supporter-fieldname'>first name</p>
            <p
              className='copyable'
              onClick={copyToClipBoard}
              id='supporter-first'
            >
              {first}
            </p>
          </div>
          <div className='supporter-field-container supporter-name-combined'>
            <p
              className='material-icons copyable'
              onClick={combineAndCopyToClipBoard}
            >
              content_copy
            </p>
          </div>
          <div className={`supporter-field-container supporter-last`}>
            <p className='supporter-fieldname'>last name</p>
            <p
              className='copyable'
              onClick={copyToClipBoard}
              id='supporter-last'
            >
              {last}
            </p>
          </div>
        </div>

        <div className='supporter-address-container'>
          {supporterField('address1')}
          {supporterField('city')}
          {supporterField('state')}
          {supporterField('zip')}
        </div>

        {supporterField('email')}
        {supporterField('phone')}
      </div>
    ) : (
      <button onClick={copyCandidate} className='buy-phone-number'>
        buy phone number
      </button>
    );
  };

  return (
    <div className='candidate-modal'>
      <div className='candidate-modal-header'>
        <button className='as-link' onClick={closeModal}>
          <i className='material-icons'>close</i>
        </button>
        <h3>
          {name}
          <a href={googleLink} target='_blank' rel='noopener noreferrer'>
            <i className='material-icons'>person_search</i>
          </a>
        </h3>
      </div>
      <div className='candidate-modal-body'>
        {createOrShowSupporter(candidate)}
      </div>
    </div>
  );
};

export default CandidateModal;
