const domainOptions = [
  'allaceauto.com',
  'findlocaltravel.com',
  'goodliberty.com',
  'graphworksllc.com',
  'nexutex.com',
  'postednow.com',
  'ribitsolutions.com',
  'theprimeplace.com',
  'twerby.com',
  'bodyworktips.com',
];

export default domainOptions;
