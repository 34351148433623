/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createCandidate = /* GraphQL */ `
  mutation CreateCandidate(
    $input: CreateCandidateInput!
    $condition: ModelCandidateConditionInput
  ) {
    createCandidate(input: $input, condition: $condition) {
      id
      name
      candidate_id
      party_full
      party
      state
      district
      district_number
      office
      office_full
      candidate_status
      federal_funds_flag
      has_raised_funds
      receipts
      supporter {
        name
        address1
        address2
        city
        state
        zip
        email
        phone
        congressional_district
      }
      supporter_email
      supporter_phone
      candidate_url
      messages {
        items {
          data_type
          id
          candidate_id
          from
          to
          subject
          message_text
          message_html
          message_html_as_text
          message_raw
          base_type
          created
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateCandidate = /* GraphQL */ `
  mutation UpdateCandidate(
    $input: UpdateCandidateInput!
    $condition: ModelCandidateConditionInput
  ) {
    updateCandidate(input: $input, condition: $condition) {
      id
      name
      candidate_id
      party_full
      party
      state
      district
      district_number
      office
      office_full
      candidate_status
      federal_funds_flag
      has_raised_funds
      receipts
      supporter {
        name
        address1
        address2
        city
        state
        zip
        email
        phone
        congressional_district
      }
      supporter_email
      supporter_phone
      candidate_url
      messages {
        items {
          data_type
          id
          candidate_id
          from
          to
          subject
          message_text
          message_html
          message_html_as_text
          message_raw
          base_type
          created
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteCandidate = /* GraphQL */ `
  mutation DeleteCandidate(
    $input: DeleteCandidateInput!
    $condition: ModelCandidateConditionInput
  ) {
    deleteCandidate(input: $input, condition: $condition) {
      id
      name
      candidate_id
      party_full
      party
      state
      district
      district_number
      office
      office_full
      candidate_status
      federal_funds_flag
      has_raised_funds
      receipts
      supporter {
        name
        address1
        address2
        city
        state
        zip
        email
        phone
        congressional_district
      }
      supporter_email
      supporter_phone
      candidate_url
      messages {
        items {
          data_type
          id
          candidate_id
          from
          to
          subject
          message_text
          message_html
          message_html_as_text
          message_raw
          base_type
          created
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createMessage = /* GraphQL */ `
  mutation CreateMessage(
    $input: CreateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    createMessage(input: $input, condition: $condition) {
      data_type
      id
      candidate_id
      from
      to
      subject
      message_text
      message_html
      message_html_as_text
      message_raw
      base_type
      created
      candidate {
        id
        name
        candidate_id
        party_full
        party
        state
        district
        district_number
        office
        office_full
        candidate_status
        federal_funds_flag
        has_raised_funds
        receipts
        supporter {
          name
          address1
          address2
          city
          state
          zip
          email
          phone
          congressional_district
        }
        supporter_email
        supporter_phone
        candidate_url
        messages {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateMessage = /* GraphQL */ `
  mutation UpdateMessage(
    $input: UpdateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    updateMessage(input: $input, condition: $condition) {
      data_type
      id
      candidate_id
      from
      to
      subject
      message_text
      message_html
      message_html_as_text
      message_raw
      base_type
      created
      candidate {
        id
        name
        candidate_id
        party_full
        party
        state
        district
        district_number
        office
        office_full
        candidate_status
        federal_funds_flag
        has_raised_funds
        receipts
        supporter {
          name
          address1
          address2
          city
          state
          zip
          email
          phone
          congressional_district
        }
        supporter_email
        supporter_phone
        candidate_url
        messages {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteMessage = /* GraphQL */ `
  mutation DeleteMessage(
    $input: DeleteMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    deleteMessage(input: $input, condition: $condition) {
      data_type
      id
      candidate_id
      from
      to
      subject
      message_text
      message_html
      message_html_as_text
      message_raw
      base_type
      created
      candidate {
        id
        name
        candidate_id
        party_full
        party
        state
        district
        district_number
        office
        office_full
        candidate_status
        federal_funds_flag
        has_raised_funds
        receipts
        supporter {
          name
          address1
          address2
          city
          state
          zip
          email
          phone
          congressional_district
        }
        supporter_email
        supporter_phone
        candidate_url
        messages {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
