import React from 'react';
import ReactDOM from 'react-dom';
import Amplify from 'aws-amplify';
import Modal from 'react-modal';

import App from './components/app/app';
import * as serviceWorker from './serviceWorker';

import './index.scss';

import awsconfig from './aws-exports';
Amplify.configure(awsconfig);

Modal.setAppElement('#root');

const indexContent = () => (
  <React.StrictMode>
    <div className='site'>
      <App />
    </div>
  </React.StrictMode>
);

ReactDOM.render(indexContent(), document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
