import React, { useState, useEffect } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import compareFunc from 'compare-func';
import Modal from 'react-modal';

import CandidateListItem from '../candidate-list-item/candidate-list-item';
import CandidateModal from '../candidate-modal/candidate-modal';

import mergeCandidates from '../../utils/merge-candidates/merge-candidates';

import { getFecCandidatesAndSkimbotCandidatesByState } from '../../graphql/custom-queries';

const CongressionalDistrictList = (props) => {
  const [candidates, setCandidates] = useState([]);
  const [cand, setCand] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  // const [modal, setModal] = useState('');

  let list = <p>loading...</p>;

  const getCandidatesFromApi = async (state) => {
    try {
      const candidatesData = await API.graphql(
        graphqlOperation(getFecCandidatesAndSkimbotCandidatesByState, {
          state: state,
        })
      );
      return Promise.resolve(mergeCandidates(candidatesData));
    } catch (err) {
      console.log('error fetching candidates', err);
      return Promise.reject(err);
    }
  };

  useEffect(() => {
    const fetchCandidates = async (state) => {
      setCandidates(await getCandidatesFromApi(state));
    };
    fetchCandidates(props.state);
  }, [props.state]);

  candidates.forEach((candidate) => {
    candidate.inverseReceipts = candidate.receipts * -1;
  });

  candidates.sort(compareFunc(['district', 'inverseReceipts']));

  const clickCandidate = (candidate) => {
    setModalIsOpen(true);
    setCand(candidate);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  let currentOffice = '';
  list = candidates.map((candidate) => {
    const { district, office_full } = candidate;
    const officeWithDistrict =
      district > 0 ? `${office_full}-${district}` : office_full;
    let heading = '';
    if (officeWithDistrict !== currentOffice) {
      heading = (
        <h4 className='office'>
          {office_full}
          {district > 0 ? `-${district}` : ''}
        </h4>
      );
      currentOffice = officeWithDistrict;
    }
    return (
      <div key={`${candidate.candidate_id}-div`} className='candidate'>
        {heading}
        <CandidateListItem
          imported={candidate.imported}
          key={candidate.candidate_id}
          candidate={candidate}
          clickHandler={clickCandidate}
        />
      </div>
    );
  });

  const updateHandler = (supporter) => {
    const updatedCandidate = Object.assign(cand, { supporter, imported: true });
    const newCandidates = [...candidates];
    newCandidates.forEach((c, i) => {
      if (cand.candidate_id === c.candidate_id) {
        newCandidates.slice(i, 1);
        newCandidates[i] = updatedCandidate;
      }
    });
    setCandidates(newCandidates);
  };

  return (
    <div className='congressional-district-list'>
      <h3>Candidates Running in {props.state}</h3>
      {list}
      <Modal
        closeTimeoutMS={500}
        className='modal'
        overlayClassName='modal-overlay'
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel='Example Modal'
      >
        <CandidateModal
          candidate={cand}
          closeHandler={closeModal}
          updateHandler={updateHandler}
        />
      </Modal>
    </div>
  );
};

export default CongressionalDistrictList;
