module.exports = [
  {
    name: 'AK-0',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/AK-0?ref=gh-pages',
    state: 'AK',
    state_and_number: 'AK-0',
    zip: 99501,
    congressionalDistrict: 0,
    addressesInDistrict: [
      {
        RegAddrLine1: '35621 NORTH FORK RD',
        RegAddrCity: 'ANCHOR POINT',
        RegAddrState: 'AK',
        RegAddrZip: 99556,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1905 PARKSIDE DR',
        RegAddrCity: 'ANCHORAGE',
        RegAddrState: 'AK',
        RegAddrZip: 99501,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1584 NORTHBLUFF DR',
        RegAddrCity: 'ANCHORAGE',
        RegAddrState: 'AK',
        RegAddrZip: 99501,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '6646 DELONG LANDING CIR',
        RegAddrCity: 'ANCHORAGE',
        RegAddrState: 'AK',
        RegAddrZip: 99502,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '5977 MUIRWOOD DR',
        RegAddrCity: 'ANCHORAGE',
        RegAddrState: 'AK',
        RegAddrZip: 99502,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '4410 MACALISTER DR',
        RegAddrCity: 'ANCHORAGE',
        RegAddrState: 'AK',
        RegAddrZip: 99502,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '9224 STRATHMORE DR',
        RegAddrCity: 'ANCHORAGE',
        RegAddrState: 'AK',
        RegAddrZip: 99502,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '6604 MCGILL WAY',
        RegAddrCity: 'ANCHORAGE',
        RegAddrState: 'AK',
        RegAddrZip: 99502,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1100 W 32ND AVE',
        RegAddrCity: 'ANCHORAGE',
        RegAddrState: 'AK',
        RegAddrZip: 99503,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '8531 E 11TH CT',
        RegAddrCity: 'ANCHORAGE',
        RegAddrState: 'AK',
        RegAddrZip: 99504,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '6346 E 32ND AVE',
        RegAddrCity: 'ANCHORAGE',
        RegAddrState: 'AK',
        RegAddrZip: 99504,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1046 MILA ST',
        RegAddrCity: 'ANCHORAGE',
        RegAddrState: 'AK',
        RegAddrZip: 99504,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1900 OTTER ST',
        RegAddrCity: 'ANCHORAGE',
        RegAddrState: 'AK',
        RegAddrZip: 99504,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '8870 CROSS POINTE LOOP',
        RegAddrCity: 'ANCHORAGE',
        RegAddrState: 'AK',
        RegAddrZip: 99504,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '6731 E 10TH AVE',
        RegAddrCity: 'ANCHORAGE',
        RegAddrState: 'AK',
        RegAddrZip: 99504,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '3615 IMAGE DR',
        RegAddrCity: 'ANCHORAGE',
        RegAddrState: 'AK',
        RegAddrZip: 99504,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '3321 EVERGREEN ST',
        RegAddrCity: 'ANCHORAGE',
        RegAddrState: 'AK',
        RegAddrZip: 99504,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '7021 DICKERSON DR',
        RegAddrCity: 'ANCHORAGE',
        RegAddrState: 'AK',
        RegAddrZip: 99504,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '8630 PIONEER DR',
        RegAddrCity: 'ANCHORAGE',
        RegAddrState: 'AK',
        RegAddrZip: 99504,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '6223 STAEDEM DR',
        RegAddrCity: 'ANCHORAGE',
        RegAddrState: 'AK',
        RegAddrZip: 99504,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '6981 LASER DR',
        RegAddrCity: 'ANCHORAGE',
        RegAddrState: 'AK',
        RegAddrZip: 99504,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '9320 STRUTZ AVE',
        RegAddrCity: 'ANCHORAGE',
        RegAddrState: 'AK',
        RegAddrZip: 99507,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '5800 LAKE OTIS PKWY',
        RegAddrCity: 'ANCHORAGE',
        RegAddrState: 'AK',
        RegAddrZip: 99507,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '6530 MEADOW ST',
        RegAddrCity: 'ANCHORAGE',
        RegAddrState: 'AK',
        RegAddrZip: 99507,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '2922 WIND CHASE CT',
        RegAddrCity: 'ANCHORAGE',
        RegAddrState: 'AK',
        RegAddrZip: 99507,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '4700 GOLDEN SPRING CIR',
        RegAddrCity: 'ANCHORAGE',
        RegAddrState: 'AK',
        RegAddrZip: 99507,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1929 E 58TH AVE',
        RegAddrCity: 'ANCHORAGE',
        RegAddrState: 'AK',
        RegAddrZip: 99507,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '8811 RENDON DR',
        RegAddrCity: 'ANCHORAGE',
        RegAddrState: 'AK',
        RegAddrZip: 99507,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '7851 ALATNA AVE',
        RegAddrCity: 'ANCHORAGE',
        RegAddrState: 'AK',
        RegAddrZip: 99507,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '7231 BULEN DR',
        RegAddrCity: 'ANCHORAGE',
        RegAddrState: 'AK',
        RegAddrZip: 99507,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '733 N BLISS ST',
        RegAddrCity: 'ANCHORAGE',
        RegAddrState: 'AK',
        RegAddrZip: 99508,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '455 CAMELOT DR',
        RegAddrCity: 'ANCHORAGE',
        RegAddrState: 'AK',
        RegAddrZip: 99508,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1401 BIRCHWOOD ST',
        RegAddrCity: 'ANCHORAGE',
        RegAddrState: 'AK',
        RegAddrZip: 99508,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '328 RAY ST',
        RegAddrCity: 'ANCHORAGE',
        RegAddrState: 'AK',
        RegAddrZip: 99508,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '14460 OLD SEWARD HWY',
        RegAddrCity: 'ANCHORAGE',
        RegAddrState: 'AK',
        RegAddrZip: 99515,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '3065 BETTLES BAY LOOP',
        RegAddrCity: 'ANCHORAGE',
        RegAddrState: 'AK',
        RegAddrZip: 99515,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '11019 SOLITUDE CIR',
        RegAddrCity: 'ANCHORAGE',
        RegAddrState: 'AK',
        RegAddrZip: 99515,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '3610 HAZEN LN',
        RegAddrCity: 'ANCHORAGE',
        RegAddrState: 'AK',
        RegAddrZip: 99515,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '10121 GOOSE BERRY PL',
        RegAddrCity: 'ANCHORAGE',
        RegAddrState: 'AK',
        RegAddrZip: 99515,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '4848 POTTER CREST CIR',
        RegAddrCity: 'ANCHORAGE',
        RegAddrState: 'AK',
        RegAddrZip: 99516,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '11434 TULIN PARK LOOP',
        RegAddrCity: 'ANCHORAGE',
        RegAddrState: 'AK',
        RegAddrZip: 99516,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '7035 OUR OWN LN',
        RegAddrCity: 'ANCHORAGE',
        RegAddrState: 'AK',
        RegAddrZip: 99516,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '17501 ROSEMONT DR',
        RegAddrCity: 'ANCHORAGE',
        RegAddrState: 'AK',
        RegAddrZip: 99516,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '3164 MORGAN LOOP',
        RegAddrCity: 'ANCHORAGE',
        RegAddrState: 'AK',
        RegAddrZip: 99516,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '3951 MARCELLE CIR',
        RegAddrCity: 'ANCHORAGE',
        RegAddrState: 'AK',
        RegAddrZip: 99516,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '5910 GREECE DR',
        RegAddrCity: 'ANCHORAGE',
        RegAddrState: 'AK',
        RegAddrZip: 99516,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '5905 GREECE CIR',
        RegAddrCity: 'ANCHORAGE',
        RegAddrState: 'AK',
        RegAddrZip: 99516,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1712 JEFFERSON AVE',
        RegAddrCity: 'ANCHORAGE',
        RegAddrState: 'AK',
        RegAddrZip: 99517,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '3509 STARGATE CIR',
        RegAddrCity: 'ANCHORAGE',
        RegAddrState: 'AK',
        RegAddrZip: 99517,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '4010 BARBARA DR',
        RegAddrCity: 'ANCHORAGE',
        RegAddrState: 'AK',
        RegAddrZip: 99517,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '2927 KNIK AVE',
        RegAddrCity: 'ANCHORAGE',
        RegAddrState: 'AK',
        RegAddrZip: 99517,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '2526 FOREST PARK DR',
        RegAddrCity: 'ANCHORAGE',
        RegAddrState: 'AK',
        RegAddrZip: 99517,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '8261 FRANK ST',
        RegAddrCity: 'ANCHORAGE',
        RegAddrState: 'AK',
        RegAddrZip: 99518,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1320 W 73RD CIR',
        RegAddrCity: 'ANCHORAGE',
        RegAddrState: 'AK',
        RegAddrZip: 99518,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '461 ATLANTIS AVE',
        RegAddrCity: 'ANCHORAGE',
        RegAddrState: 'AK',
        RegAddrZip: 99518,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '21011 EASTSIDE DR',
        RegAddrCity: 'CHUGIAK',
        RegAddrState: 'AK',
        RegAddrZip: 99567,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '18920 SPRUCE CREST DR',
        RegAddrCity: 'CHUGIAK',
        RegAddrState: 'AK',
        RegAddrZip: 99567,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '2 JOHN ST',
        RegAddrCity: 'CROOKED CREEK',
        RegAddrState: 'AK',
        RegAddrZip: 99575,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: 'MI 229.9 PARKS HWY',
        RegAddrCity: 'DENALI PARK',
        RegAddrState: 'AK',
        RegAddrZip: 99755,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '305 KOKWOK CIR',
        RegAddrCity: 'DILLINGHAM',
        RegAddrState: 'AK',
        RegAddrZip: 99576,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '12009 REGENCY DR',
        RegAddrCity: 'EAGLE RIVER',
        RegAddrState: 'AK',
        RegAddrZip: 99577,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '17024 MEADOW CREEK DR',
        RegAddrCity: 'EAGLE RIVER',
        RegAddrState: 'AK',
        RegAddrZip: 99577,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '18911 DANNY DR',
        RegAddrCity: 'EAGLE RIVER',
        RegAddrState: 'AK',
        RegAddrZip: 99577,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '9337 AGATTU CIR',
        RegAddrCity: 'EAGLE RIVER',
        RegAddrState: 'AK',
        RegAddrZip: 99577,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '20710 PTARMIGAN BLVD',
        RegAddrCity: 'EAGLE RIVER',
        RegAddrState: 'AK',
        RegAddrZip: 99577,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '14004 OLD GLENN HWY',
        RegAddrCity: 'EAGLE RIVER',
        RegAddrState: 'AK',
        RegAddrZip: 99577,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '10146 COLVILLE ST',
        RegAddrCity: 'EAGLE RIVER',
        RegAddrState: 'AK',
        RegAddrZip: 99577,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '19143 JAMIE DR',
        RegAddrCity: 'EAGLE RIVER',
        RegAddrState: 'AK',
        RegAddrZip: 99577,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '9823 DINAAKA DR',
        RegAddrCity: 'EAGLE RIVER',
        RegAddrState: 'AK',
        RegAddrZip: 99577,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '5194 KODIAK ST',
        RegAddrCity: 'EIELSON AFB',
        RegAddrState: 'AK',
        RegAddrZip: 99702,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '2 HOUSES FROM CATHOLIC CHURCH',
        RegAddrCity: 'EMMONAK',
        RegAddrState: 'AK',
        RegAddrZip: 99581,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1420 27TH AVE',
        RegAddrCity: 'FAIRBANKS',
        RegAddrState: 'AK',
        RegAddrZip: 99701,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '126 GLACIER AVE',
        RegAddrCity: 'FAIRBANKS',
        RegAddrState: 'AK',
        RegAddrZip: 99701,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '3224 LAKEVIEW DR',
        RegAddrCity: 'FAIRBANKS',
        RegAddrState: 'AK',
        RegAddrZip: 99701,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '2604 17TH AVE',
        RegAddrCity: 'FAIRBANKS',
        RegAddrState: 'AK',
        RegAddrZip: 99709,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '3920 FRENCHMAN RD',
        RegAddrCity: 'FAIRBANKS',
        RegAddrState: 'AK',
        RegAddrZip: 99709,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '860 CHENA HILLS DR',
        RegAddrCity: 'FAIRBANKS',
        RegAddrState: 'AK',
        RegAddrZip: 99709,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '4652 DRAKE ST',
        RegAddrCity: 'FAIRBANKS',
        RegAddrState: 'AK',
        RegAddrZip: 99709,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '731 ETON BLVD',
        RegAddrCity: 'FAIRBANKS',
        RegAddrState: 'AK',
        RegAddrZip: 99709,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1998 KITTIWAKE DR',
        RegAddrCity: 'FAIRBANKS',
        RegAddrState: 'AK',
        RegAddrZip: 99709,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '730 DARRELL DR',
        RegAddrCity: 'FAIRBANKS',
        RegAddrState: 'AK',
        RegAddrZip: 99709,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '811 BIRCH KNOLL RD',
        RegAddrCity: 'FAIRBANKS',
        RegAddrState: 'AK',
        RegAddrZip: 99712,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '887 OSLUND DR E',
        RegAddrCity: 'HAINES',
        RegAddrState: 'AK',
        RegAddrZip: 99827,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: 'MI 6.5 MUD BAY RD',
        RegAddrCity: 'HAINES',
        RegAddrState: 'AK',
        RegAddrZip: 99827,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '60082 CLARICE WAY',
        RegAddrCity: 'HOMER',
        RegAddrState: 'AK',
        RegAddrZip: 99603,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '110 EAGLE DR',
        RegAddrCity: 'HOONAH',
        RegAddrState: 'AK',
        RegAddrZip: 99829,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: 'TOMAGANUK RD HOUSE',
        RegAddrCity: 'HOOPER BAY',
        RegAddrState: 'AK',
        RegAddrZip: 99604,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '120 CEDAR ST',
        RegAddrCity: 'HYDABURG',
        RegAddrState: 'AK',
        RegAddrZip: 99922,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '7359 MCGUIRE AVE',
        RegAddrCity: 'JBER',
        RegAddrState: 'AK',
        RegAddrZip: 99506,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '4101 TAKU BLVD',
        RegAddrCity: 'JUNEAU',
        RegAddrState: 'AK',
        RegAddrZip: 99801,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '10107 SILVER ST',
        RegAddrCity: 'JUNEAU',
        RegAddrState: 'AK',
        RegAddrZip: 99801,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '10705 HORIZON DR',
        RegAddrCity: 'JUNEAU',
        RegAddrState: 'AK',
        RegAddrZip: 99801,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '4017 SPRUCE LN',
        RegAddrCity: 'JUNEAU',
        RegAddrState: 'AK',
        RegAddrZip: 99801,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '9213 SHARON ST',
        RegAddrCity: 'JUNEAU',
        RegAddrState: 'AK',
        RegAddrZip: 99801,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '5851B CHURCHILL WAY',
        RegAddrCity: 'JUNEAU',
        RegAddrState: 'AK',
        RegAddrZip: 99801,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '4119 ASPEN AVE',
        RegAddrCity: 'JUNEAU',
        RegAddrState: 'AK',
        RegAddrZip: 99801,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '370A BUNCHBERRY LN',
        RegAddrCity: 'KETCHIKAN',
        RegAddrState: 'AK',
        RegAddrZip: 99901,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '819 MONROE ST',
        RegAddrCity: 'KETCHIKAN',
        RegAddrState: 'AK',
        RegAddrZip: 99901,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '3453 BARANOF AVE',
        RegAddrCity: 'KETCHIKAN',
        RegAddrState: 'AK',
        RegAddrZip: 99901,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1388 FAIRY CHASM RD',
        RegAddrCity: 'KETCHIKAN',
        RegAddrState: 'AK',
        RegAddrZip: 99901,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '894 MONROE ST',
        RegAddrCity: 'KETCHIKAN',
        RegAddrState: 'AK',
        RegAddrZip: 99901,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1165 ABERCROMBIE DR',
        RegAddrCity: 'KODIAK',
        RegAddrState: 'AK',
        RegAddrZip: 99615,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1579 MONASHKA CIR',
        RegAddrCity: 'KODIAK',
        RegAddrState: 'AK',
        RegAddrZip: 99615,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '739 S ALYESKA AVE',
        RegAddrCity: 'KODIAK',
        RegAddrState: 'AK',
        RegAddrZip: 99615,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '210 3RD AVE',
        RegAddrCity: 'KOTZEBUE',
        RegAddrState: 'AK',
        RegAddrZip: 99752,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '778 B BISON ST',
        RegAddrCity: 'KOTZEBUE',
        RegAddrState: 'AK',
        RegAddrZip: 99752,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '91701 POLTYS AVE',
        RegAddrCity: 'MARSHALL',
        RegAddrState: 'AK',
        RegAddrZip: 99585,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '9109 MISSION RD',
        RegAddrCity: 'NAPAKIAK',
        RegAddrState: 'AK',
        RegAddrZip: 99634,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '17590 PROSPECTOR ST',
        RegAddrCity: 'NINILCHIK',
        RegAddrState: 'AK',
        RegAddrZip: 99639,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '401 ROUND THE CLOCK DR',
        RegAddrCity: 'NOME',
        RegAddrState: 'AK',
        RegAddrZip: 99762,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '208 E KINGS PL',
        RegAddrCity: 'NOME',
        RegAddrState: 'AK',
        RegAddrZip: 99762,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '705 ROUND THE CLOCK DR',
        RegAddrCity: 'NOME',
        RegAddrState: 'AK',
        RegAddrZip: 99762,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1416 REDMOND AVE',
        RegAddrCity: 'NORTH POLE',
        RegAddrState: 'AK',
        RegAddrZip: 99705,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '2149 TOPS ST',
        RegAddrCity: 'NORTH POLE',
        RegAddrState: 'AK',
        RegAddrZip: 99705,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1061 REFINERY LOOP',
        RegAddrCity: 'NORTH POLE',
        RegAddrState: 'AK',
        RegAddrZip: 99705,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1096 DEE LN',
        RegAddrCity: 'NORTH POLE',
        RegAddrState: 'AK',
        RegAddrZip: 99705,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '3601 KALTAG DR',
        RegAddrCity: 'NORTH POLE',
        RegAddrState: 'AK',
        RegAddrZip: 99705,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '3526 ERIN DR',
        RegAddrCity: 'NORTH POLE',
        RegAddrState: 'AK',
        RegAddrZip: 99705,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1132 DEE LN',
        RegAddrCity: 'NORTH POLE',
        RegAddrState: 'AK',
        RegAddrZip: 99705,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '3950 TRENT AVE',
        RegAddrCity: 'NORTH POLE',
        RegAddrState: 'AK',
        RegAddrZip: 99705,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '2425 SAN AUGUSTIN DR',
        RegAddrCity: 'NORTH POLE',
        RegAddrState: 'AK',
        RegAddrZip: 99705,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '8905 E GOLD PAN DR',
        RegAddrCity: 'PALMER',
        RegAddrState: 'AK',
        RegAddrZip: 99645,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1561 N LOMA PRIETA DR',
        RegAddrCity: 'PALMER',
        RegAddrState: 'AK',
        RegAddrZip: 99645,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '7640 E WOODVIEW WAY',
        RegAddrCity: 'PALMER',
        RegAddrState: 'AK',
        RegAddrZip: 99645,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1285 S FRONTIER DR',
        RegAddrCity: 'PALMER',
        RegAddrState: 'AK',
        RegAddrZip: 99645,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '15450 E MOTHERSHEAD LN',
        RegAddrCity: 'PALMER',
        RegAddrState: 'AK',
        RegAddrZip: 99645,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '13724 E CORTO CAMINO AVE',
        RegAddrCity: 'PALMER',
        RegAddrState: 'AK',
        RegAddrZip: 99645,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '7400 E DENELLE ST',
        RegAddrCity: 'PALMER',
        RegAddrState: 'AK',
        RegAddrZip: 99645,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '11070 E EQUESTRIAN ST',
        RegAddrCity: 'PALMER',
        RegAddrState: 'AK',
        RegAddrZip: 99645,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1013 UMIGRAGVIK ST',
        RegAddrCity: 'POINT HOPE',
        RegAddrState: 'AK',
        RegAddrZip: 99766,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '',
        RegAddrCity: 'RUSSIAN MISSION',
        RegAddrState: 'AK',
        RegAddrZip: 99657,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: 'MIDDLE BUNKHOUSE',
        RegAddrCity: 'SAND POINT',
        RegAddrState: 'AK',
        RegAddrZip: 99661,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '15088 QUE SERA DR',
        RegAddrCity: 'SEWARD',
        RegAddrState: 'AK',
        RegAddrZip: 99664,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '704 LAKE ST',
        RegAddrCity: 'SITKA',
        RegAddrState: 'AK',
        RegAddrZip: 99835,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '136 GREEN VALLEY ST',
        RegAddrCity: 'SOLDOTNA',
        RegAddrState: 'AK',
        RegAddrZip: 99669,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '44535 FUNNY RIVER RD',
        RegAddrCity: 'SOLDOTNA',
        RegAddrState: 'AK',
        RegAddrZip: 99669,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '136 ELLERMAN HTS',
        RegAddrCity: 'ST PAUL',
        RegAddrState: 'AK',
        RegAddrZip: 99660,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '35220 ENTRADA DR',
        RegAddrCity: 'STERLING',
        RegAddrState: 'AK',
        RegAddrZip: 99672,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '11993 N WRIGHT WAY',
        RegAddrCity: 'SUTTON',
        RegAddrState: 'AK',
        RegAddrZip: 99674,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '61.5 N GLENN HWY',
        RegAddrCity: 'SUTTON',
        RegAddrState: 'AK',
        RegAddrZip: 99674,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: 'MI 1.5 MISSION HILL RD',
        RegAddrCity: 'TANANA',
        RegAddrState: 'AK',
        RegAddrZip: 99777,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: 'FIRST AVE',
        RegAddrCity: 'TOGIAK',
        RegAddrState: 'AK',
        RegAddrZip: 99678,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '150 SLED DOG WAY SUBD',
        RegAddrCity: 'TOK',
        RegAddrState: 'AK',
        RegAddrZip: 99780,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1ST ST',
        RegAddrCity: 'TULUKSAK',
        RegAddrState: 'AK',
        RegAddrZip: 99679,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '205 N HARBOR DR',
        RegAddrCity: 'VALDEZ',
        RegAddrState: 'AK',
        RegAddrZip: 99686,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '4698 S OLD YACHT CLUB RD',
        RegAddrCity: 'WASILLA',
        RegAddrState: 'AK',
        RegAddrZip: 99623,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '6277 S NORTHERN LIGHTS ST',
        RegAddrCity: 'WASILLA',
        RegAddrState: 'AK',
        RegAddrZip: 99623,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '14121 W MARTEN RD',
        RegAddrCity: 'WASILLA',
        RegAddrState: 'AK',
        RegAddrZip: 99623,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '17469 W LESSER CANADA DR',
        RegAddrCity: 'WASILLA',
        RegAddrState: 'AK',
        RegAddrZip: 99623,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '3881 S COUNTRY DR',
        RegAddrCity: 'WASILLA',
        RegAddrState: 'AK',
        RegAddrZip: 99623,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '150 E RAVENVIEW DR',
        RegAddrCity: 'WASILLA',
        RegAddrState: 'AK',
        RegAddrZip: 99654,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '4021 E TAMBERT DR',
        RegAddrCity: 'WASILLA',
        RegAddrState: 'AK',
        RegAddrZip: 99654,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '4625 E KILO DR',
        RegAddrCity: 'WASILLA',
        RegAddrState: 'AK',
        RegAddrZip: 99654,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '2968 S AVALON CIR',
        RegAddrCity: 'WASILLA',
        RegAddrState: 'AK',
        RegAddrZip: 99654,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1000 W TURK CIR',
        RegAddrCity: 'WASILLA',
        RegAddrState: 'AK',
        RegAddrZip: 99654,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '2351 N LUCILLE ST',
        RegAddrCity: 'WASILLA',
        RegAddrState: 'AK',
        RegAddrZip: 99654,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '6080 W JAKES RD',
        RegAddrCity: 'WASILLA',
        RegAddrState: 'AK',
        RegAddrZip: 99654,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '3450 N CARIBOU ST',
        RegAddrCity: 'WASILLA',
        RegAddrState: 'AK',
        RegAddrZip: 99654,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '6361 N BISHOP DR',
        RegAddrCity: 'WASILLA',
        RegAddrState: 'AK',
        RegAddrZip: 99654,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '3930 N SALVATION ST',
        RegAddrCity: 'WASILLA',
        RegAddrState: 'AK',
        RegAddrZip: 99654,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '2910 S PADDOCK DR',
        RegAddrCity: 'WASILLA',
        RegAddrState: 'AK',
        RegAddrZip: 99654,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '2422 W JAMES T CIR',
        RegAddrCity: 'WASILLA',
        RegAddrState: 'AK',
        RegAddrZip: 99654,
        CongressionalDistrict: 0,
      },
    ],
  },
  {
    name: 'AL-1',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/AL-1?ref=gh-pages',
    state: 'AL',
    state_and_number: 'AL-1',
    zip: 36033,
    congressionalDistrict: 1,
    addressesInDistrict: [
      {
        RegAddrLine1: '208 OLD SHIP CIR',
        RegAddrCity: 'ATMORE',
        RegAddrState: 'AL',
        RegAddrZip: 36502,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '412 E BANYAN ST',
        RegAddrCity: 'BAY MINETTE',
        RegAddrState: 'AL',
        RegAddrZip: 36507,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '9580 ROLAND GODWIN RD',
        RegAddrCity: 'BAY MINETTE',
        RegAddrState: 'AL',
        RegAddrZip: 36507,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '21 BRANTLEY LN',
        RegAddrCity: 'BREWTON',
        RegAddrState: 'AL',
        RegAddrZip: 36426,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '326 5TH ST',
        RegAddrCity: 'CHICKASAW',
        RegAddrState: 'AL',
        RegAddrZip: 36611,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '538 WOODLORE DR',
        RegAddrCity: 'CHICKASAW',
        RegAddrState: 'AL',
        RegAddrZip: 36611,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '8405 CHUNCHULA GEORGETOWN RD',
        RegAddrCity: 'CHUNCHULA',
        RegAddrState: 'AL',
        RegAddrZip: 36521,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '10858 WARRENTON RD',
        RegAddrCity: 'DAPHNE',
        RegAddrState: 'AL',
        RegAddrZip: 36526,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '27432 HOBBY HORSE LN',
        RegAddrCity: 'DAPHNE',
        RegAddrState: 'AL',
        RegAddrZip: 36526,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '111 MARIKESH DR',
        RegAddrCity: 'DAPHNE',
        RegAddrState: 'AL',
        RegAddrZip: 36526,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '5755 DOGWOOD TRL',
        RegAddrCity: 'EIGHT MILE',
        RegAddrState: 'AL',
        RegAddrZip: 36613,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '16712 CALLOWAY DR',
        RegAddrCity: 'FAIRHOPE',
        RegAddrState: 'AL',
        RegAddrZip: 36532,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '112 ECHO LN',
        RegAddrCity: 'FAIRHOPE',
        RegAddrState: 'AL',
        RegAddrZip: 36532,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '7762 AUDUBON DR',
        RegAddrCity: 'FOLEY',
        RegAddrState: 'AL',
        RegAddrZip: 36535,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '30 SCHOOL ST',
        RegAddrCity: 'FRISCO CITY',
        RegAddrState: 'AL',
        RegAddrZip: 36445,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '13130 HOLLY CT',
        RegAddrCity: 'GRAND BAY',
        RegAddrState: 'AL',
        RegAddrZip: 36541,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '5500 TOM GASTON RD S',
        RegAddrCity: 'GRAND BAY',
        RegAddrState: 'AL',
        RegAddrZip: 36541,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '132 MCGOWIN ST',
        RegAddrCity: 'JACKSON',
        RegAddrState: 'AL',
        RegAddrZip: 36545,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1732 SPANISH COVE DR S',
        RegAddrCity: 'LILLIAN',
        RegAddrState: 'AL',
        RegAddrZip: 36549,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '36160 BOYKIN BLVD',
        RegAddrCity: 'LILLIAN',
        RegAddrState: 'AL',
        RegAddrZip: 36549,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '',
        RegAddrCity: 'MEXIA',
        RegAddrState: 'AL',
        RegAddrZip: 36458,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '158 S DEARBORN ST',
        RegAddrCity: 'MOBILE',
        RegAddrState: 'AL',
        RegAddrZip: 36602,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '601 SAVANNAH ST',
        RegAddrCity: 'MOBILE',
        RegAddrState: 'AL',
        RegAddrZip: 36603,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '517 BIZZELL AVE',
        RegAddrCity: 'MOBILE',
        RegAddrState: 'AL',
        RegAddrZip: 36603,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '15 MACY PL',
        RegAddrCity: 'MOBILE',
        RegAddrState: 'AL',
        RegAddrZip: 36604,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2400 VERMILLION DR',
        RegAddrCity: 'MOBILE',
        RegAddrState: 'AL',
        RegAddrZip: 36605,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1804 GULFDALE DR',
        RegAddrCity: 'MOBILE',
        RegAddrState: 'AL',
        RegAddrZip: 36605,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '3207 DESIRE ST',
        RegAddrCity: 'MOBILE',
        RegAddrState: 'AL',
        RegAddrZip: 36606,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1105 ZURICH ST',
        RegAddrCity: 'MOBILE',
        RegAddrState: 'AL',
        RegAddrZip: 36608,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '307 N UNIVERSITY BLVD',
        RegAddrCity: 'MOBILE',
        RegAddrState: 'AL',
        RegAddrZip: 36608,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '201 BELLEVUE CIR',
        RegAddrCity: 'MOBILE',
        RegAddrState: 'AL',
        RegAddrZip: 36608,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1428 REGENCY OAKS DR W',
        RegAddrCity: 'MOBILE',
        RegAddrState: 'AL',
        RegAddrZip: 36609,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '4153 TAMWORTH DR',
        RegAddrCity: 'MOBILE',
        RegAddrState: 'AL',
        RegAddrZip: 36609,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '3809 PLEASANT VALLEY RD',
        RegAddrCity: 'MOBILE',
        RegAddrState: 'AL',
        RegAddrZip: 36609,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '3332 LACOSTE RD',
        RegAddrCity: 'MOBILE',
        RegAddrState: 'AL',
        RegAddrZip: 36618,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '6213 SUMMER PLACE DR N',
        RegAddrCity: 'MOBILE',
        RegAddrState: 'AL',
        RegAddrZip: 36618,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1901 FOXFIRE RD',
        RegAddrCity: 'MOBILE',
        RegAddrState: 'AL',
        RegAddrZip: 36618,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '3395 HARWELL RD',
        RegAddrCity: 'MOBILE',
        RegAddrState: 'AL',
        RegAddrZip: 36618,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '5340 MEDFORD DR N',
        RegAddrCity: 'MOBILE',
        RegAddrState: 'AL',
        RegAddrZip: 36693,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2312 WEST RD',
        RegAddrCity: 'MOBILE',
        RegAddrState: 'AL',
        RegAddrZip: 36693,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2266 EAST RD',
        RegAddrCity: 'MOBILE',
        RegAddrState: 'AL',
        RegAddrZip: 36693,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2525 PONTCHARTRAIN DR',
        RegAddrCity: 'MOBILE',
        RegAddrState: 'AL',
        RegAddrZip: 36695,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '8731 DUTCH VALLEY CT',
        RegAddrCity: 'MOBILE',
        RegAddrState: 'AL',
        RegAddrZip: 36695,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '8260 WINCHESTER WOODS CT',
        RegAddrCity: 'MOBILE',
        RegAddrState: 'AL',
        RegAddrZip: 36695,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '909 SMITH AVE',
        RegAddrCity: 'PRICHARD',
        RegAddrState: 'AL',
        RegAddrZip: 36610,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '21542 ASHER LN',
        RegAddrCity: 'ROBERTSDALE',
        RegAddrState: 'AL',
        RegAddrZip: 36567,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '23141 DIAMOND W RD',
        RegAddrCity: 'ROBERTSDALE',
        RegAddrState: 'AL',
        RegAddrZip: 36567,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '18711 WILTERS ST',
        RegAddrCity: 'ROBERTSDALE',
        RegAddrState: 'AL',
        RegAddrZip: 36567,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '222 SULLIVAN DR',
        RegAddrCity: 'SARALAND',
        RegAddrState: 'AL',
        RegAddrZip: 36571,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '624 SARALAND BLVD S',
        RegAddrCity: 'SARALAND',
        RegAddrState: 'AL',
        RegAddrZip: 36571,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '4505 COUNTRY ESTATES DR',
        RegAddrCity: 'SARALAND',
        RegAddrState: 'AL',
        RegAddrZip: 36571,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '6566 HICKORY HILL DR',
        RegAddrCity: 'SEMMES',
        RegAddrState: 'AL',
        RegAddrZip: 36575,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '18167 COUCH PLANT RD',
        RegAddrCity: 'SUMMERDALE',
        RegAddrState: 'AL',
        RegAddrZip: 36580,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '5850 BELLE TERRACE DR',
        RegAddrCity: 'THEODORE',
        RegAddrState: 'AL',
        RegAddrZip: 36582,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '9371 DAUPHIN ISLAND PKWY S',
        RegAddrCity: 'THEODORE',
        RegAddrState: 'AL',
        RegAddrZip: 36582,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '5250 WASHINGTON BLVD',
        RegAddrCity: 'THEODORE',
        RegAddrState: 'AL',
        RegAddrZip: 36582,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '4541 LAURENDINE RD',
        RegAddrCity: 'THEODORE',
        RegAddrState: 'AL',
        RegAddrZip: 36582,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '15195 TANNER WILLIAMS RD',
        RegAddrCity: 'WILMER',
        RegAddrState: 'AL',
        RegAddrZip: 36587,
        CongressionalDistrict: 1,
      },
    ],
  },
  {
    name: 'AL-2',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/AL-2?ref=gh-pages',
    state: 'AL',
    state_and_number: 'AL-2',
    zip: 35010,
    congressionalDistrict: 2,
    addressesInDistrict: [
      {
        RegAddrLine1: '1802 CEDAR RD',
        RegAddrCity: 'ANDALUSIA',
        RegAddrState: 'AL',
        RegAddrZip: 36421,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1029 COUNTY ROAD 4405',
        RegAddrCity: 'BANKS',
        RegAddrState: 'AL',
        RegAddrZip: 36005,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '162 COUNTY ROAD 4430',
        RegAddrCity: 'BRUNDIDGE',
        RegAddrState: 'AL',
        RegAddrZip: 36010,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '170 E END CIR',
        RegAddrCity: 'BRUNDIDGE',
        RegAddrState: 'AL',
        RegAddrZip: 36010,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '117 DONNELL CIR',
        RegAddrCity: 'DALEVILLE',
        RegAddrState: 'AL',
        RegAddrZip: 36322,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1902 DENNY DR',
        RegAddrCity: 'DEATSVILLE',
        RegAddrState: 'AL',
        RegAddrZip: 36022,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '136 BUCK CREEK DR',
        RegAddrCity: 'DOTHAN',
        RegAddrState: 'AL',
        RegAddrZip: 36301,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '206 OGLETHORPE ST',
        RegAddrCity: 'DOTHAN',
        RegAddrState: 'AL',
        RegAddrZip: 36301,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1104 MEADOWLANE DR',
        RegAddrCity: 'DOTHAN',
        RegAddrState: 'AL',
        RegAddrZip: 36301,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1004 EASTWOOD DR',
        RegAddrCity: 'DOTHAN',
        RegAddrState: 'AL',
        RegAddrZip: 36301,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '106 BENT OAK DR',
        RegAddrCity: 'DOTHAN',
        RegAddrState: 'AL',
        RegAddrZip: 36303,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '430 CARAVELLA DR',
        RegAddrCity: 'DOTHAN',
        RegAddrState: 'AL',
        RegAddrZip: 36305,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '12 HARRINGTON LN',
        RegAddrCity: 'DOTHAN',
        RegAddrState: 'AL',
        RegAddrZip: 36305,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '363 UNION RD',
        RegAddrCity: 'ECLECTIC',
        RegAddrState: 'AL',
        RegAddrZip: 36024,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2316 AL HIGHWAY 143',
        RegAddrCity: 'ELMORE',
        RegAddrState: 'AL',
        RegAddrZip: 36025,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '4825 SHELL FIELD RD',
        RegAddrCity: 'ENTERPRISE',
        RegAddrState: 'AL',
        RegAddrZip: 36330,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '606 TARTAN WAY',
        RegAddrCity: 'ENTERPRISE',
        RegAddrState: 'AL',
        RegAddrZip: 36330,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '124 FOREST AVE',
        RegAddrCity: 'ENTERPRISE',
        RegAddrState: 'AL',
        RegAddrZip: 36330,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '278 COTTONHILL RD',
        RegAddrCity: 'EUFAULA',
        RegAddrState: 'AL',
        RegAddrZip: 36027,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '216 MAGNOLIA AVE',
        RegAddrCity: 'EVERGREEN',
        RegAddrState: 'AL',
        RegAddrZip: 36401,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '66 N MIDDLE RD',
        RegAddrCity: 'EVERGREEN',
        RegAddrState: 'AL',
        RegAddrZip: 36401,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '209 S MAIN ST',
        RegAddrCity: 'EVERGREEN',
        RegAddrState: 'AL',
        RegAddrZip: 36401,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '601 N MORRIS ST',
        RegAddrCity: 'GENEVA',
        RegAddrState: 'AL',
        RegAddrZip: 36340,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1285 MCDOUGALD ST',
        RegAddrCity: 'GENEVA',
        RegAddrState: 'AL',
        RegAddrZip: 36340,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '20 DEPOT ST',
        RegAddrCity: 'GORDON',
        RegAddrState: 'AL',
        RegAddrZip: 36343,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2559 BAZEMORE MILL RD',
        RegAddrCity: 'GORDON',
        RegAddrState: 'AL',
        RegAddrZip: 36343,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '115 SHANNON HILL RD',
        RegAddrCity: 'GREENVILLE',
        RegAddrState: 'AL',
        RegAddrZip: 36037,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '608 MITCHELL ST',
        RegAddrCity: 'HEADLAND',
        RegAddrState: 'AL',
        RegAddrZip: 36345,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '13354 US HIGHWAY 431 S',
        RegAddrCity: 'HEADLAND',
        RegAddrState: 'AL',
        RegAddrZip: 36345,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '11 VICTORIA ST',
        RegAddrCity: 'LOUISVILLE',
        RegAddrState: 'AL',
        RegAddrZip: 36048,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '529 S HAWKINS AVE',
        RegAddrCity: 'LUVERNE',
        RegAddrState: 'AL',
        RegAddrZip: 36049,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '4897 N GARLAND RD',
        RegAddrCity: 'MC KENZIE',
        RegAddrState: 'AL',
        RegAddrZip: 36456,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '44 THOMPSON RD',
        RegAddrCity: 'MIDWAY',
        RegAddrState: 'AL',
        RegAddrZip: 36053,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '3437 SULLIVAN LN',
        RegAddrCity: 'MILLBROOK',
        RegAddrState: 'AL',
        RegAddrZip: 36054,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1258 CLAY ST',
        RegAddrCity: 'MONTGOMERY',
        RegAddrState: 'AL',
        RegAddrZip: 36104,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '3287 S HULL ST',
        RegAddrCity: 'MONTGOMERY',
        RegAddrState: 'AL',
        RegAddrZip: 36105,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1624 YANCEY AVE',
        RegAddrCity: 'MONTGOMERY',
        RegAddrState: 'AL',
        RegAddrZip: 36107,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1903 MADISON AVE',
        RegAddrCity: 'MONTGOMERY',
        RegAddrState: 'AL',
        RegAddrZip: 36107,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '4485 BLACKWOOD DR',
        RegAddrCity: 'MONTGOMERY',
        RegAddrState: 'AL',
        RegAddrZip: 36109,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '3137 DURHAM DR',
        RegAddrCity: 'MONTGOMERY',
        RegAddrState: 'AL',
        RegAddrZip: 36109,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '6200 SAWSTON RD',
        RegAddrCity: 'MONTGOMERY',
        RegAddrState: 'AL',
        RegAddrZip: 36116,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1602 LONDON TOWN LN',
        RegAddrCity: 'MONTGOMERY',
        RegAddrState: 'AL',
        RegAddrZip: 36117,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '7239 FARNSWORTH CT',
        RegAddrCity: 'MONTGOMERY',
        RegAddrState: 'AL',
        RegAddrZip: 36117,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '6383 EASTWOOD GLEN PL',
        RegAddrCity: 'MONTGOMERY',
        RegAddrState: 'AL',
        RegAddrZip: 36117,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '106 DEE RD',
        RegAddrCity: 'OPP',
        RegAddrState: 'AL',
        RegAddrZip: 36467,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '700 DORSEY ST',
        RegAddrCity: 'OPP',
        RegAddrState: 'AL',
        RegAddrZip: 36467,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '201 PATTERSON DR',
        RegAddrCity: 'OPP',
        RegAddrState: 'AL',
        RegAddrZip: 36467,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '992 WHITTLE HUDSON RD',
        RegAddrCity: 'OZARK',
        RegAddrState: 'AL',
        RegAddrZip: 36360,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '3309 MARLER RD',
        RegAddrCity: 'PIKE ROAD',
        RegAddrState: 'AL',
        RegAddrZip: 36064,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '842 HEATHER DR',
        RegAddrCity: 'PRATTVILLE',
        RegAddrState: 'AL',
        RegAddrZip: 36066,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '317 COUNTY ROAD 62',
        RegAddrCity: 'PRATTVILLE',
        RegAddrState: 'AL',
        RegAddrZip: 36067,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '209 SIMMONS RD',
        RegAddrCity: 'PRATTVILLE',
        RegAddrState: 'AL',
        RegAddrZip: 36067,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '412 AZALEA DR',
        RegAddrCity: 'PRATTVILLE',
        RegAddrState: 'AL',
        RegAddrZip: 36067,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1312 CROWS PASS',
        RegAddrCity: 'PRATTVILLE',
        RegAddrState: 'AL',
        RegAddrZip: 36067,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '37 FIFTH ST',
        RegAddrCity: 'RUTLEDGE',
        RegAddrState: 'AL',
        RegAddrZip: 36071,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '5602 FINK MILL RD',
        RegAddrCity: 'SAMSON',
        RegAddrState: 'AL',
        RegAddrZip: 36477,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '275 MCCARTER LN',
        RegAddrCity: 'TITUS',
        RegAddrState: 'AL',
        RegAddrZip: 36080,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '215 M AND M LN',
        RegAddrCity: 'UNION SPRINGS',
        RegAddrState: 'AL',
        RegAddrZip: 36089,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '37 MARCH ST',
        RegAddrCity: 'UNION SPRINGS',
        RegAddrState: 'AL',
        RegAddrZip: 36089,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1765 ATKIN HILL RD',
        RegAddrCity: 'WETUMPKA',
        RegAddrState: 'AL',
        RegAddrZip: 36092,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '4299 GEORGIA RD',
        RegAddrCity: 'WETUMPKA',
        RegAddrState: 'AL',
        RegAddrZip: 36092,
        CongressionalDistrict: 2,
      },
    ],
  },
  {
    name: 'AL-3',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/AL-3?ref=gh-pages',
    state: 'AL',
    state_and_number: 'AL-3',
    zip: 30165,
    congressionalDistrict: 3,
    addressesInDistrict: [
      {
        RegAddrLine1: '3211 PEARSON CHAPEL RD',
        RegAddrCity: 'ALEX CITY',
        RegAddrState: 'AL',
        RegAddrZip: 35010,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '5449 HIGHWAY 22 E',
        RegAddrCity: 'ALEX CITY',
        RegAddrState: 'AL',
        RegAddrZip: 35010,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '3209 MOORE AVE',
        RegAddrCity: 'ANNISTON',
        RegAddrState: 'AL',
        RegAddrZip: 36201,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '6317 SHERWOOD DR',
        RegAddrCity: 'ANNISTON',
        RegAddrState: 'AL',
        RegAddrZip: 36206,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '5913 MEDDERS ST',
        RegAddrCity: 'ANNISTON',
        RegAddrState: 'AL',
        RegAddrZip: 36206,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1194 MOUNTAIN VIEW RD',
        RegAddrCity: 'ASHLAND',
        RegAddrState: 'AL',
        RegAddrZip: 36251,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '364 ESTATE AVE',
        RegAddrCity: 'AUBURN',
        RegAddrState: 'AL',
        RegAddrZip: 36830,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1816 N SAGEWOOD CT',
        RegAddrCity: 'AUBURN',
        RegAddrState: 'AL',
        RegAddrZip: 36830,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '349 BOWDEN DR',
        RegAddrCity: 'AUBURN',
        RegAddrState: 'AL',
        RegAddrZip: 36830,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1894 HILLTON CT',
        RegAddrCity: 'AUBURN',
        RegAddrState: 'AL',
        RegAddrZip: 36830,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '215 MARION CIR',
        RegAddrCity: 'AUBURN',
        RegAddrState: 'AL',
        RegAddrZip: 36830,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '559 VICKERSTAFF ST',
        RegAddrCity: 'AUBURN',
        RegAddrState: 'AL',
        RegAddrZip: 36832,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '222 LITTLE HALL',
        RegAddrCity: 'AUBURN UNIV',
        RegAddrState: 'AL',
        RegAddrZip: 36849,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '307 COUNTY ROAD 233',
        RegAddrCity: 'CEDAR BLUFF',
        RegAddrState: 'AL',
        RegAddrZip: 35959,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '3410 LOGAN MARTIN DAM RD',
        RegAddrCity: 'CROPWELL',
        RegAddrState: 'AL',
        RegAddrZip: 35054,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '641 FULTON ST',
        RegAddrCity: 'DADEVILLE',
        RegAddrState: 'AL',
        RegAddrZip: 36853,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '46 BIRCH LN',
        RegAddrCity: 'DADEVILLE',
        RegAddrState: 'AL',
        RegAddrZip: 36853,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '2384 COUNTY ROAD 84',
        RegAddrCity: 'HEFLIN',
        RegAddrState: 'AL',
        RegAddrZip: 36264,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '410 WILSON DR SW',
        RegAddrCity: 'JACKSONVILLE',
        RegAddrState: 'AL',
        RegAddrZip: 36265,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '6150 NEW LIBERTY RD',
        RegAddrCity: 'JACKSONVILLE',
        RegAddrState: 'AL',
        RegAddrZip: 36265,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '720 12TH ST NE',
        RegAddrCity: 'JACKSONVILLE',
        RegAddrState: 'AL',
        RegAddrZip: 36265,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '3900 ROY WEBB RD',
        RegAddrCity: 'JACKSONVILLE',
        RegAddrState: 'AL',
        RegAddrZip: 36265,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1625 FAIRWAY DR SW',
        RegAddrCity: 'JACKSONVILLE',
        RegAddrState: 'AL',
        RegAddrZip: 36265,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '2011 COUNTY ROAD 102',
        RegAddrCity: 'LAFAYETTE',
        RegAddrState: 'AL',
        RegAddrZip: 36862,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '2131 S PHILLIPS RD',
        RegAddrCity: 'LANETT',
        RegAddrState: 'AL',
        RegAddrZip: 36863,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '820 S 10TH ST',
        RegAddrCity: 'LANETT',
        RegAddrState: 'AL',
        RegAddrZip: 36863,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1705 N 2ND ST',
        RegAddrCity: 'LANETT',
        RegAddrState: 'AL',
        RegAddrZip: 36863,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '90 COUNTY ROAD 120',
        RegAddrCity: 'LINEVILLE',
        RegAddrState: 'AL',
        RegAddrZip: 36266,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '10015 CHANTILLY PKWY',
        RegAddrCity: 'MONTGOMERY',
        RegAddrState: 'AL',
        RegAddrZip: 36117,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '9012 HANSTON CT',
        RegAddrCity: 'MONTGOMERY',
        RegAddrState: 'AL',
        RegAddrZip: 36117,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '8830 WELLSTON PL',
        RegAddrCity: 'MONTGOMERY',
        RegAddrState: 'AL',
        RegAddrZip: 36117,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1399 MARKEETA SPUR RD',
        RegAddrCity: 'MOODY',
        RegAddrState: 'AL',
        RegAddrZip: 35004,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1515 SILVER RUN RD',
        RegAddrCity: 'MUNFORD',
        RegAddrState: 'AL',
        RegAddrZip: 36268,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '196 PINE RD',
        RegAddrCity: 'ODENVILLE',
        RegAddrState: 'AL',
        RegAddrZip: 35120,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '865 MOONLITE DR',
        RegAddrCity: 'ODENVILLE',
        RegAddrState: 'AL',
        RegAddrZip: 35120,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '407 TOOMER CIR',
        RegAddrCity: 'OPELIKA',
        RegAddrState: 'AL',
        RegAddrZip: 36801,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1715 FEASTER ST',
        RegAddrCity: 'OXFORD',
        RegAddrState: 'AL',
        RegAddrZip: 36203,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '545 HILLSTONE DR',
        RegAddrCity: 'PELL CITY',
        RegAddrState: 'AL',
        RegAddrZip: 35125,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '105 LAKEVIEW DR',
        RegAddrCity: 'PELL CITY',
        RegAddrState: 'AL',
        RegAddrZip: 35128,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1B 31ST AVE',
        RegAddrCity: 'PHENIX CITY',
        RegAddrState: 'AL',
        RegAddrZip: 36869,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '13D CRICKET CT',
        RegAddrCity: 'PHENIX CITY',
        RegAddrState: 'AL',
        RegAddrZip: 36869,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '2117 7TH ST',
        RegAddrCity: 'PHENIX CITY',
        RegAddrState: 'AL',
        RegAddrZip: 36869,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '310 LEE ROAD 601',
        RegAddrCity: 'PHENIX CITY',
        RegAddrState: 'AL',
        RegAddrZip: 36870,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '36 CEDARWOOD CT',
        RegAddrCity: 'PHENIX CITY',
        RegAddrState: 'AL',
        RegAddrZip: 36870,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '470 LEE ROAD 236',
        RegAddrCity: 'PHENIX CITY',
        RegAddrState: 'AL',
        RegAddrZip: 36870,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '150 COUNTY ROAD 67',
        RegAddrCity: 'PIEDMONT',
        RegAddrState: 'AL',
        RegAddrZip: 36272,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '555 WOODLAND AVE',
        RegAddrCity: 'PIEDMONT',
        RegAddrState: 'AL',
        RegAddrZip: 36272,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '3400 CENTER STAR RD',
        RegAddrCity: 'RAGLAND',
        RegAddrState: 'AL',
        RegAddrZip: 35131,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1690 AL HIGHWAY 144',
        RegAddrCity: 'RAGLAND',
        RegAddrState: 'AL',
        RegAddrZip: 35131,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '21465 US HIGHWAY 231',
        RegAddrCity: 'RAGLAND',
        RegAddrState: 'AL',
        RegAddrZip: 35131,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '3283 COUNTY ROAD 61',
        RegAddrCity: 'ROANOKE',
        RegAddrState: 'AL',
        RegAddrZip: 36274,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '239 CODY RD',
        RegAddrCity: 'ROANOKE',
        RegAddrState: 'AL',
        RegAddrZip: 36274,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '477 GILHAM RD',
        RegAddrCity: 'ROANOKE',
        RegAddrState: 'AL',
        RegAddrZip: 36274,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '5940 LEE ROAD 179',
        RegAddrCity: 'SALEM',
        RegAddrState: 'AL',
        RegAddrZip: 36874,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '329 HIGHWAY 169',
        RegAddrCity: 'SEALE',
        RegAddrState: 'AL',
        RegAddrZip: 36875,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '800 SUN WOOD DR',
        RegAddrCity: 'SMITHS STA',
        RegAddrState: 'AL',
        RegAddrZip: 36877,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '9 TUSKEGEE ST',
        RegAddrCity: 'SYLACAUGA',
        RegAddrState: 'AL',
        RegAddrZip: 35150,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '267 FLUKER ST',
        RegAddrCity: 'SYLACAUGA',
        RegAddrState: 'AL',
        RegAddrZip: 35150,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '3630 COLEMAN RD',
        RegAddrCity: 'SYLACAUGA',
        RegAddrState: 'AL',
        RegAddrZip: 35150,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '735 MARBLE CITY HEIGHTS CIR',
        RegAddrCity: 'SYLACAUGA',
        RegAddrState: 'AL',
        RegAddrZip: 35150,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '307 LEE ST',
        RegAddrCity: 'SYLACAUGA',
        RegAddrState: 'AL',
        RegAddrZip: 35151,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '209 BRECON ACCESS RD',
        RegAddrCity: 'TALLADEGA',
        RegAddrState: 'AL',
        RegAddrZip: 35160,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '507 MALLORY ST',
        RegAddrCity: 'TALLADEGA',
        RegAddrState: 'AL',
        RegAddrZip: 35160,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '117 COFFEE ST E',
        RegAddrCity: 'TALLADEGA',
        RegAddrState: 'AL',
        RegAddrZip: 35160,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '195 PLEASANT SPRINGS DR',
        RegAddrCity: 'TUSKEGEE',
        RegAddrState: 'AL',
        RegAddrZip: 36083,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '902 MAYWOOD ST',
        RegAddrCity: 'TUSKEGEE',
        RegAddrState: 'AL',
        RegAddrZip: 36083,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '445 LEE ROAD 380',
        RegAddrCity: 'VALLEY',
        RegAddrState: 'AL',
        RegAddrZip: 36854,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1724 COUNTY ROAD 27',
        RegAddrCity: 'WOODLAND',
        RegAddrState: 'AL',
        RegAddrZip: 36280,
        CongressionalDistrict: 3,
      },
    ],
  },
  {
    name: 'AL-4',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/AL-4?ref=gh-pages',
    state: 'AL',
    state_and_number: 'AL-4',
    zip: 35006,
    congressionalDistrict: 4,
    addressesInDistrict: [
      {
        RegAddrLine1: '417 COUNTY ROAD 1836',
        RegAddrCity: 'ARAB',
        RegAddrState: 'AL',
        RegAddrZip: 35016,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '500 HAYNES RD NE',
        RegAddrCity: 'ARAB',
        RegAddrState: 'AL',
        RegAddrZip: 35016,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '125 BENTLEY CIR SE',
        RegAddrCity: 'ARAB',
        RegAddrState: 'AL',
        RegAddrZip: 35016,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1044 COUNTY ROAD 123',
        RegAddrCity: 'BERRY',
        RegAddrState: 'AL',
        RegAddrZip: 35546,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '121 COTTONWOOD CIR',
        RegAddrCity: 'BOAZ',
        RegAddrState: 'AL',
        RegAddrZip: 35957,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '14151 MARY DR',
        RegAddrCity: 'COKER',
        RegAddrState: 'AL',
        RegAddrZip: 35452,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '278 COUNTY ROAD 879',
        RegAddrCity: 'CRANE HILL',
        RegAddrState: 'AL',
        RegAddrZip: 35053,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '146 COUNTY ROAD 732',
        RegAddrCity: 'CULLMAN',
        RegAddrState: 'AL',
        RegAddrZip: 35055,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '147 COUNTY ROAD 765',
        RegAddrCity: 'CULLMAN',
        RegAddrState: 'AL',
        RegAddrZip: 35055,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1005 AVENUE E SE',
        RegAddrCity: 'CULLMAN',
        RegAddrState: 'AL',
        RegAddrZip: 35055,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '317 COUNTY ROAD 843',
        RegAddrCity: 'CULLMAN',
        RegAddrState: 'AL',
        RegAddrZip: 35057,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1045 COUNTY ROAD 1131',
        RegAddrCity: 'CULLMAN',
        RegAddrState: 'AL',
        RegAddrZip: 35057,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '148 COUNTY ROAD 412',
        RegAddrCity: 'CULLMAN',
        RegAddrState: 'AL',
        RegAddrZip: 35057,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '8810 HIGHWAY 36',
        RegAddrCity: 'DANVILLE',
        RegAddrState: 'AL',
        RegAddrZip: 35619,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '3344 BRYAN RD',
        RegAddrCity: 'DORA',
        RegAddrState: 'AL',
        RegAddrZip: 35062,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '83 PHILLIPS RIDGE RD',
        RegAddrCity: 'EMPIRE',
        RegAddrState: 'AL',
        RegAddrZip: 35063,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '411 COUNTY ROAD 1395',
        RegAddrCity: 'FALKVILLE',
        RegAddrState: 'AL',
        RegAddrZip: 35622,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '803 1ST AVE NW',
        RegAddrCity: 'FAYETTE',
        RegAddrState: 'AL',
        RegAddrZip: 35555,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '537 TEMPLE AVE N',
        RegAddrCity: 'FAYETTE',
        RegAddrState: 'AL',
        RegAddrZip: 35555,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '524 CUNNINGHAM DR E',
        RegAddrCity: 'FORT PAYNE',
        RegAddrState: 'AL',
        RegAddrZip: 35967,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2441 COUNTY ROAD 18',
        RegAddrCity: 'FYFFE',
        RegAddrState: 'AL',
        RegAddrZip: 35971,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '419 COUNTRY CLUB DR',
        RegAddrCity: 'GADSDEN',
        RegAddrState: 'AL',
        RegAddrZip: 35901,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '150 MILNER DR',
        RegAddrCity: 'GADSDEN',
        RegAddrState: 'AL',
        RegAddrZip: 35901,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '829 COUNTRY CLUB DR',
        RegAddrCity: 'GADSDEN',
        RegAddrState: 'AL',
        RegAddrZip: 35901,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '620 COUNTRY CLUB DR',
        RegAddrCity: 'GADSDEN',
        RegAddrState: 'AL',
        RegAddrZip: 35901,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '3514 GEORGIA AVE',
        RegAddrCity: 'GADSDEN',
        RegAddrState: 'AL',
        RegAddrZip: 35904,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2904 PATTON ST',
        RegAddrCity: 'GADSDEN',
        RegAddrState: 'AL',
        RegAddrZip: 35904,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '210 S REHM ST',
        RegAddrCity: 'GADSDEN',
        RegAddrState: 'AL',
        RegAddrZip: 35904,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '4790 COUNTY ROAD 91',
        RegAddrCity: 'GAYLESVILLE',
        RegAddrState: 'AL',
        RegAddrZip: 35973,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '16504 AL HIGHWAY 227',
        RegAddrCity: 'GERALDINE',
        RegAddrState: 'AL',
        RegAddrZip: 35974,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '7847 US HIGHWAY 43',
        RegAddrCity: 'GUIN',
        RegAddrState: 'AL',
        RegAddrZip: 35563,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1654 STATE HIGHWAY 107',
        RegAddrCity: 'GUIN',
        RegAddrState: 'AL',
        RegAddrZip: 35563,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '3309 WYETH LN',
        RegAddrCity: 'GUNTERSVILLE',
        RegAddrState: 'AL',
        RegAddrZip: 35976,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1544 CIRCLE DR',
        RegAddrCity: 'GUNTERSVILLE',
        RegAddrState: 'AL',
        RegAddrZip: 35976,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '3708 COLONIAL DR',
        RegAddrCity: 'GUNTERSVILLE',
        RegAddrState: 'AL',
        RegAddrZip: 35976,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '3216 COUNTY HIGHWAY 99',
        RegAddrCity: 'HALEYVILLE',
        RegAddrState: 'AL',
        RegAddrZip: 35565,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1655 COUNTY ROAD 93',
        RegAddrCity: 'HALEYVILLE',
        RegAddrState: 'AL',
        RegAddrZip: 35565,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1728 COUNTY ROAD 541',
        RegAddrCity: 'HANCEVILLE',
        RegAddrState: 'AL',
        RegAddrZip: 35077,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '163 COUNTY ROAD 263',
        RegAddrCity: 'HANCEVILLE',
        RegAddrState: 'AL',
        RegAddrZip: 35077,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2916 COUNTY ROAD 739',
        RegAddrCity: 'HENAGAR',
        RegAddrState: 'AL',
        RegAddrZip: 35978,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '7853 US HIGHWAY 278 E',
        RegAddrCity: 'HOKES BLUFF',
        RegAddrState: 'AL',
        RegAddrZip: 35903,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '83456 US HIGHWAY 278',
        RegAddrCity: 'HORTON',
        RegAddrState: 'AL',
        RegAddrZip: 35980,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '705 PINE ST',
        RegAddrCity: 'JASPER',
        RegAddrState: 'AL',
        RegAddrZip: 35501,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '647 ALEXANDER TUBBS RD',
        RegAddrCity: 'JASPER',
        RegAddrState: 'AL',
        RegAddrZip: 35503,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2011 BURROWS CROSSING RD',
        RegAddrCity: 'JASPER',
        RegAddrState: 'AL',
        RegAddrZip: 35504,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2897 N WALSTON BRIDGE RD',
        RegAddrCity: 'JASPER',
        RegAddrState: 'AL',
        RegAddrZip: 35504,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '19029 HIGHWAY 96',
        RegAddrCity: 'KENNEDY',
        RegAddrState: 'AL',
        RegAddrZip: 35574,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1740 AYCOCK CEMETERY RD',
        RegAddrCity: 'LEIGHTON',
        RegAddrState: 'AL',
        RegAddrZip: 35646,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '8920 COUNTY ROAD 103',
        RegAddrCity: 'MENTONE',
        RegAddrState: 'AL',
        RegAddrZip: 35984,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1409 COUNTY ROAD 625',
        RegAddrCity: 'MENTONE',
        RegAddrState: 'AL',
        RegAddrZip: 35984,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '165 COUNTY ROAD 219',
        RegAddrCity: 'MOULTON',
        RegAddrState: 'AL',
        RegAddrZip: 35650,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '581 COUNTY ROAD 110',
        RegAddrCity: 'MOULTON',
        RegAddrState: 'AL',
        RegAddrZip: 35650,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '104 A C CURRY ST',
        RegAddrCity: 'MUSCLE SHOALS',
        RegAddrState: 'AL',
        RegAddrZip: 35661,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '660 BRICK SCHOOL RD',
        RegAddrCity: 'MUSCLE SHOALS',
        RegAddrState: 'AL',
        RegAddrZip: 35661,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '13255 COUNTY LINE RD',
        RegAddrCity: 'MUSCLE SHOALS',
        RegAddrState: 'AL',
        RegAddrZip: 35661,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '6823 NAUVOO RD',
        RegAddrCity: 'NAUVOO',
        RegAddrState: 'AL',
        RegAddrZip: 35578,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '5318 CHESTERTOWN TRCE',
        RegAddrCity: 'NORTHPORT',
        RegAddrState: 'AL',
        RegAddrZip: 35475,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '6424 TUTWILER RD',
        RegAddrCity: 'OAKMAN',
        RegAddrState: 'AL',
        RegAddrZip: 35579,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '27 NIMROD ST',
        RegAddrCity: 'PHIL CAMPBELL',
        RegAddrState: 'AL',
        RegAddrZip: 35581,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '219 WINDEMERE DR',
        RegAddrCity: 'RAINBOW CITY',
        RegAddrState: 'AL',
        RegAddrZip: 35906,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '143 HORTON RD',
        RegAddrCity: 'RAINSVILLE',
        RegAddrState: 'AL',
        RegAddrZip: 35986,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '965 FRANKFORT RD',
        RegAddrCity: 'RUSSELLVILLE',
        RegAddrState: 'AL',
        RegAddrZip: 35653,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '847 HIGHWAY 144',
        RegAddrCity: 'RUSSELLVILLE',
        RegAddrState: 'AL',
        RegAddrZip: 35654,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '5008 HIGHWAY 56',
        RegAddrCity: 'RUSSELLVILLE',
        RegAddrState: 'AL',
        RegAddrZip: 35654,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '386 SULLIVAN RD',
        RegAddrCity: 'SUMITON',
        RegAddrState: 'AL',
        RegAddrZip: 35148,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '3060 1ST ST',
        RegAddrCity: 'TRUSSVILLE',
        RegAddrState: 'AL',
        RegAddrZip: 35173,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '4138 MERETTA LN',
        RegAddrCity: 'TUSCALOOSA',
        RegAddrState: 'AL',
        RegAddrZip: 35406,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2915 REGATTA WAY',
        RegAddrCity: 'TUSCALOOSA',
        RegAddrState: 'AL',
        RegAddrZip: 35406,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '12034 FRANKFORT RD',
        RegAddrCity: 'TUSCUMBIA',
        RegAddrState: 'AL',
        RegAddrZip: 35674,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '36 PENNINGTON DR',
        RegAddrCity: 'VERNON',
        RegAddrState: 'AL',
        RegAddrZip: 35592,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '222 FOOTHILL RD',
        RegAddrCity: 'WINFIELD',
        RegAddrState: 'AL',
        RegAddrZip: 35594,
        CongressionalDistrict: 4,
      },
    ],
  },
  {
    name: 'AL-5',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/AL-5?ref=gh-pages',
    state: 'AL',
    state_and_number: 'AL-5',
    zip: 35601,
    congressionalDistrict: 5,
    addressesInDistrict: [
      {
        RegAddrLine1: '407 GALE LN',
        RegAddrCity: 'ATHENS',
        RegAddrState: 'AL',
        RegAddrZip: 35611,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '17620 OWENS ST',
        RegAddrCity: 'ATHENS',
        RegAddrState: 'AL',
        RegAddrZip: 35611,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '906 BRAHMA ST',
        RegAddrCity: 'ATHENS',
        RegAddrState: 'AL',
        RegAddrZip: 35611,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '22838 PEPPER RD',
        RegAddrCity: 'ATHENS',
        RegAddrState: 'AL',
        RegAddrZip: 35613,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '26812 ELKINS RD',
        RegAddrCity: 'ATHENS',
        RegAddrState: 'AL',
        RegAddrZip: 35613,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '17702 FAIRWAY DR',
        RegAddrCity: 'ATHENS',
        RegAddrState: 'AL',
        RegAddrZip: 35613,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '189 DUSTY TRL',
        RegAddrCity: 'BRYANT',
        RegAddrState: 'AL',
        RegAddrZip: 35958,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '1509 PENNYLANE SE',
        RegAddrCity: 'DECATUR',
        RegAddrState: 'AL',
        RegAddrZip: 35601,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '429 11TH AVE NW',
        RegAddrCity: 'DECATUR',
        RegAddrState: 'AL',
        RegAddrZip: 35601,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '2306 CROWN CIR SE',
        RegAddrCity: 'DECATUR',
        RegAddrState: 'AL',
        RegAddrZip: 35603,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '2302 RICHMOND ST SW',
        RegAddrCity: 'DECATUR',
        RegAddrState: 'AL',
        RegAddrZip: 35603,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '108 LUCILLE DR SW',
        RegAddrCity: 'DECATUR',
        RegAddrState: 'AL',
        RegAddrZip: 35603,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '4209 INDIAN HILLS RD SE',
        RegAddrCity: 'DECATUR',
        RegAddrState: 'AL',
        RegAddrZip: 35603,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '615 COUNTY ROAD 130',
        RegAddrCity: 'FLAT ROCK',
        RegAddrState: 'AL',
        RegAddrZip: 35966,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '441 N CHERRY ST',
        RegAddrCity: 'FLORENCE',
        RegAddrState: 'AL',
        RegAddrZip: 35630,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '418 W MOBILE ST',
        RegAddrCity: 'FLORENCE',
        RegAddrState: 'AL',
        RegAddrZip: 35630,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '15331 HIGHWAY 157',
        RegAddrCity: 'FLORENCE',
        RegAddrState: 'AL',
        RegAddrZip: 35633,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '1175 SUNSET BEACH RD',
        RegAddrCity: 'FLORENCE',
        RegAddrState: 'AL',
        RegAddrZip: 35633,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '125 COUNTY ROAD 282',
        RegAddrCity: 'FLORENCE',
        RegAddrState: 'AL',
        RegAddrZip: 35633,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '2309 COUNTY ROAD 61',
        RegAddrCity: 'FLORENCE',
        RegAddrState: 'AL',
        RegAddrZip: 35634,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '64 COUNTY ROAD 376',
        RegAddrCity: 'FLORENCE',
        RegAddrState: 'AL',
        RegAddrZip: 35634,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '470 COUNTY ROAD 322',
        RegAddrCity: 'FLORENCE',
        RegAddrState: 'AL',
        RegAddrZip: 35634,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '1146 KILLINGSWORTH COVE RD',
        RegAddrCity: 'GURLEY',
        RegAddrState: 'AL',
        RegAddrZip: 35748,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '522 POOR HOUSE RD',
        RegAddrCity: 'HARTSELLE',
        RegAddrState: 'AL',
        RegAddrZip: 35640,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '116 ORCHID PVT DR',
        RegAddrCity: 'HARTSELLE',
        RegAddrState: 'AL',
        RegAddrZip: 35640,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '2606 DOGWOOD CIR NW',
        RegAddrCity: 'HARTSELLE',
        RegAddrState: 'AL',
        RegAddrZip: 35640,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '1053 FOREST CHAPEL RD',
        RegAddrCity: 'HARTSELLE',
        RegAddrState: 'AL',
        RegAddrZip: 35640,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '108 SANDERS RD SE',
        RegAddrCity: 'HARTSELLE',
        RegAddrState: 'AL',
        RegAddrZip: 35640,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '29608 COUNTRY LN',
        RegAddrCity: 'HARVEST',
        RegAddrState: 'AL',
        RegAddrZip: 35749,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '1588 NICK DAVIS RD',
        RegAddrCity: 'HARVEST',
        RegAddrState: 'AL',
        RegAddrZip: 35749,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '106 SANDERS RD',
        RegAddrCity: 'HAZEL GREEN',
        RegAddrState: 'AL',
        RegAddrZip: 35750,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '106 HAZELCREST RD',
        RegAddrCity: 'HAZEL GREEN',
        RegAddrState: 'AL',
        RegAddrZip: 35750,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '10119 BLUFF DR SE',
        RegAddrCity: 'HUNTSVILLE',
        RegAddrState: 'AL',
        RegAddrZip: 35803,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '12015 CHIMNEY HOLLOW TRL SE',
        RegAddrCity: 'HUNTSVILLE',
        RegAddrState: 'AL',
        RegAddrZip: 35803,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '1309 JOSHUA DR SE',
        RegAddrCity: 'HUNTSVILLE',
        RegAddrState: 'AL',
        RegAddrZip: 35803,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '103 COLBY DR NE',
        RegAddrCity: 'HUNTSVILLE',
        RegAddrState: 'AL',
        RegAddrZip: 35810,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '3709 OAKWOOD AVE NW',
        RegAddrCity: 'HUNTSVILLE',
        RegAddrState: 'AL',
        RegAddrZip: 35810,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '3317 CHARLESTON AVE NW',
        RegAddrCity: 'HUNTSVILLE',
        RegAddrState: 'AL',
        RegAddrZip: 35810,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '3001 EOLYNE KELLY WAY',
        RegAddrCity: 'HUNTSVILLE',
        RegAddrState: 'AL',
        RegAddrZip: 35811,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '366 RUTH CIR',
        RegAddrCity: 'HUNTSVILLE',
        RegAddrState: 'AL',
        RegAddrZip: 35811,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '131 SPRING FARM RD',
        RegAddrCity: 'HUNTSVILLE',
        RegAddrState: 'AL',
        RegAddrZip: 35811,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '4019 KILLARNEY DR NW',
        RegAddrCity: 'HUNTSVILLE',
        RegAddrState: 'AL',
        RegAddrZip: 35816,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '609 JORDAN LN NW',
        RegAddrCity: 'HUNTSVILLE',
        RegAddrState: 'AL',
        RegAddrZip: 35816,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '7000 ADVENTIST BLVD NW',
        RegAddrCity: 'HUNTSVILLE',
        RegAddrState: 'AL',
        RegAddrZip: 35896,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '15341 COUNTY ROAD 47',
        RegAddrCity: 'KILLEN',
        RegAddrState: 'AL',
        RegAddrZip: 35645,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '638 THOMAS RD',
        RegAddrCity: 'LACEYS SPRING',
        RegAddrState: 'AL',
        RegAddrZip: 35754,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '1402 CONTINENTAL DR',
        RegAddrCity: 'MADISON',
        RegAddrState: 'AL',
        RegAddrZip: 35758,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '108 VIOLET DR',
        RegAddrCity: 'MADISON',
        RegAddrState: 'AL',
        RegAddrZip: 35758,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '19 ALLEN ST',
        RegAddrCity: 'MADISON',
        RegAddrState: 'AL',
        RegAddrZip: 35758,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '108 RAMBLE CIR',
        RegAddrCity: 'MADISON',
        RegAddrState: 'AL',
        RegAddrZip: 35758,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '1137 MADISON PARK DR',
        RegAddrCity: 'MADISON',
        RegAddrState: 'AL',
        RegAddrZip: 35758,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '211 CYPRESS CREEK DR',
        RegAddrCity: 'MADISON',
        RegAddrState: 'AL',
        RegAddrZip: 35758,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '115 BRENTSTONE WAY',
        RegAddrCity: 'MERIDIANVILLE',
        RegAddrState: 'AL',
        RegAddrZip: 35759,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '123 ARCHERED WAY',
        RegAddrCity: 'NEW MARKET',
        RegAddrState: 'AL',
        RegAddrZip: 35761,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '4900 MERIDIAN ST NE',
        RegAddrCity: 'NORMAL',
        RegAddrState: 'AL',
        RegAddrZip: 35762,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '4900 MERIDIAN ST NE',
        RegAddrCity: 'NORMAL',
        RegAddrState: 'AL',
        RegAddrZip: 35762,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '4900 MERIDIAN ST NE',
        RegAddrCity: 'NORMAL',
        RegAddrState: 'AL',
        RegAddrZip: 35762,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '2608 TREYBURNE LN SE',
        RegAddrCity: 'OWENS X RDS',
        RegAddrState: 'AL',
        RegAddrZip: 35763,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '2969 HAMPTON COVE WAY SE',
        RegAddrCity: 'OWENS X RDS',
        RegAddrState: 'AL',
        RegAddrZip: 35763,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '115 JONES ST',
        RegAddrCity: 'PAINT ROCK',
        RegAddrState: 'AL',
        RegAddrZip: 35764,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '749 COUNTY ROAD 374',
        RegAddrCity: 'PISGAH',
        RegAddrState: 'AL',
        RegAddrZip: 35765,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '1062 OLD MOUNT CARMEL RD',
        RegAddrCity: 'STEVENSON',
        RegAddrState: 'AL',
        RegAddrZip: 35772,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '28196 MCKEE RD',
        RegAddrCity: 'TONEY',
        RegAddrState: 'AL',
        RegAddrZip: 35773,
        CongressionalDistrict: 5,
      },
    ],
  },
  {
    name: 'AL-6',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/AL-6?ref=gh-pages',
    state: 'AL',
    state_and_number: 'AL-6',
    zip: 35005,
    congressionalDistrict: 6,
    addressesInDistrict: [
      {
        RegAddrLine1: '108 PAIGE ST',
        RegAddrCity: 'ADAMSVILLE',
        RegAddrState: 'AL',
        RegAddrZip: 35005,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '9356 PINE TREE CIR',
        RegAddrCity: 'ADGER',
        RegAddrState: 'AL',
        RegAddrZip: 35006,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '105 ASHFORD LN',
        RegAddrCity: 'ALABASTER',
        RegAddrState: 'AL',
        RegAddrZip: 35007,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '121 AVIATORS VIEW DR',
        RegAddrCity: 'ALABASTER',
        RegAddrState: 'AL',
        RegAddrZip: 35007,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '3469 SMOKEY RD',
        RegAddrCity: 'ALABASTER',
        RegAddrState: 'AL',
        RegAddrZip: 35007,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '1374 COOSA COUNTY ROAD 32',
        RegAddrCity: 'ALEX CITY',
        RegAddrState: 'AL',
        RegAddrZip: 35010,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '1562 RIVERBIRCH DR',
        RegAddrCity: 'BESSEMER',
        RegAddrState: 'AL',
        RegAddrZip: 35023,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '698 CUMMINS AVE',
        RegAddrCity: 'BESSEMER',
        RegAddrState: 'AL',
        RegAddrZip: 35023,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '2834 BROOKLANE DR',
        RegAddrCity: 'BESSEMER',
        RegAddrState: 'AL',
        RegAddrZip: 35023,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '3233 VIRGINIA DR',
        RegAddrCity: 'BESSEMER',
        RegAddrState: 'AL',
        RegAddrZip: 35023,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '140 MCNEAR ST',
        RegAddrCity: 'BESSEMER',
        RegAddrState: 'AL',
        RegAddrZip: 35023,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '400 UNIVERSITY PARK DR',
        RegAddrCity: 'BIRMINGHAM',
        RegAddrState: 'AL',
        RegAddrZip: 35209,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '1512 ROSELAND DR',
        RegAddrCity: 'BIRMINGHAM',
        RegAddrState: 'AL',
        RegAddrZip: 35209,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '404 KENILWORTH DR',
        RegAddrCity: 'BIRMINGHAM',
        RegAddrState: 'AL',
        RegAddrZip: 35209,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '317 LUCERNE BLVD',
        RegAddrCity: 'BIRMINGHAM',
        RegAddrState: 'AL',
        RegAddrZip: 35209,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '5232 BEACON CIR',
        RegAddrCity: 'BIRMINGHAM',
        RegAddrState: 'AL',
        RegAddrZip: 35210,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '2412 KELLY RD',
        RegAddrCity: 'BIRMINGHAM',
        RegAddrState: 'AL',
        RegAddrZip: 35210,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '609 BIENVILLE LN',
        RegAddrCity: 'BIRMINGHAM',
        RegAddrState: 'AL',
        RegAddrZip: 35213,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '745 SPRINGDALE RD',
        RegAddrCity: 'BIRMINGHAM',
        RegAddrState: 'AL',
        RegAddrZip: 35217,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '2852 VALLEY RD',
        RegAddrCity: 'BIRMINGHAM',
        RegAddrState: 'AL',
        RegAddrZip: 35217,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '3609 CHERRY BROOK RUN',
        RegAddrCity: 'BIRMINGHAM',
        RegAddrState: 'AL',
        RegAddrZip: 35223,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '2107 WILLIAMSBURG WAY',
        RegAddrCity: 'BIRMINGHAM',
        RegAddrState: 'AL',
        RegAddrZip: 35223,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '3325 OVERTON RD',
        RegAddrCity: 'BIRMINGHAM',
        RegAddrState: 'AL',
        RegAddrZip: 35223,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '1204 SHADES CREST RD',
        RegAddrCity: 'BIRMINGHAM',
        RegAddrState: 'AL',
        RegAddrZip: 35226,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '3204 WINCHESTER RD',
        RegAddrCity: 'BIRMINGHAM',
        RegAddrState: 'AL',
        RegAddrZip: 35226,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '2513 CARMEL RD',
        RegAddrCity: 'BIRMINGHAM',
        RegAddrState: 'AL',
        RegAddrZip: 35235,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '3335 CHASE CT',
        RegAddrCity: 'BIRMINGHAM',
        RegAddrState: 'AL',
        RegAddrZip: 35235,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '420 BROOK HIGHLAND LN',
        RegAddrCity: 'BIRMINGHAM',
        RegAddrState: 'AL',
        RegAddrZip: 35242,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '3624 TALL TIMBER DR',
        RegAddrCity: 'BIRMINGHAM',
        RegAddrState: 'AL',
        RegAddrZip: 35242,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '2048 BROOK HIGHLAND RDG',
        RegAddrCity: 'BIRMINGHAM',
        RegAddrState: 'AL',
        RegAddrZip: 35242,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '5328 MEADOW BROOK RD',
        RegAddrCity: 'BIRMINGHAM',
        RegAddrState: 'AL',
        RegAddrZip: 35242,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '5150 REDFERN WAY',
        RegAddrCity: 'BIRMINGHAM',
        RegAddrState: 'AL',
        RegAddrZip: 35242,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '3900 NAZHA LN',
        RegAddrCity: 'BIRMINGHAM',
        RegAddrState: 'AL',
        RegAddrZip: 35243,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '3843 GREEN VALLEY DR',
        RegAddrCity: 'BIRMINGHAM',
        RegAddrState: 'AL',
        RegAddrZip: 35243,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '1933 STRAWBERRY LN',
        RegAddrCity: 'BIRMINGHAM',
        RegAddrState: 'AL',
        RegAddrZip: 35244,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '5009 MINEOLA LN',
        RegAddrCity: 'BIRMINGHAM',
        RegAddrState: 'AL',
        RegAddrZip: 35244,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '8 DOGWOOD DR',
        RegAddrCity: 'BRENT',
        RegAddrState: 'AL',
        RegAddrZip: 35034,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '263 ORCHARD LN',
        RegAddrCity: 'CENTREVILLE',
        RegAddrState: 'AL',
        RegAddrZip: 35042,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '100 THOMPSON DR',
        RegAddrCity: 'CHELSEA',
        RegAddrState: 'AL',
        RegAddrZip: 35043,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '103 DOGWOOD LN',
        RegAddrCity: 'CLANTON',
        RegAddrState: 'AL',
        RegAddrZip: 35045,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '4650 COUNTY ROAD 76',
        RegAddrCity: 'CLANTON',
        RegAddrState: 'AL',
        RegAddrZip: 35045,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '106 LILLY ST',
        RegAddrCity: 'CLANTON',
        RegAddrState: 'AL',
        RegAddrZip: 35045,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '15525 STATE HIGHWAY 160',
        RegAddrCity: 'CLEVELAND',
        RegAddrState: 'AL',
        RegAddrZip: 35049,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '2201 BEAR CREEK RD E',
        RegAddrCity: 'DUNCANVILLE',
        RegAddrState: 'AL',
        RegAddrZip: 35456,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '858 HONEYSUCKLE DR',
        RegAddrCity: 'FULTONDALE',
        RegAddrState: 'AL',
        RegAddrZip: 35068,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '2401 WALKER CHAPEL RD',
        RegAddrCity: 'FULTONDALE',
        RegAddrState: 'AL',
        RegAddrZip: 35068,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '818 PARKER AVE',
        RegAddrCity: 'GARDENDALE',
        RegAddrState: 'AL',
        RegAddrZip: 35071,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '3015 LONG LEAF LN',
        RegAddrCity: 'HELENA',
        RegAddrState: 'AL',
        RegAddrZip: 35080,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '567 NEW HOPE MOUNTAIN RD',
        RegAddrCity: 'INDIAN SPGS',
        RegAddrState: 'AL',
        RegAddrZip: 35124,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '6805 VALLEY LN',
        RegAddrCity: 'LEEDS',
        RegAddrState: 'AL',
        RegAddrZip: 35094,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '231 GRANDE VIEW PKWY',
        RegAddrCity: 'MAYLENE',
        RegAddrState: 'AL',
        RegAddrZip: 35114,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '1062 HIDDEN FOREST DR',
        RegAddrCity: 'MONTEVALLO',
        RegAddrState: 'AL',
        RegAddrZip: 35115,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '1109 HAMPTON DR',
        RegAddrCity: 'MORRIS',
        RegAddrState: 'AL',
        RegAddrZip: 35116,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '6090 LAKESIDE DR',
        RegAddrCity: 'MOUNT OLIVE',
        RegAddrState: 'AL',
        RegAddrZip: 35117,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '206 MCGREGOR DR',
        RegAddrCity: 'MULGA',
        RegAddrState: 'AL',
        RegAddrZip: 35118,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '2608 ROYAL CIR',
        RegAddrCity: 'PELHAM',
        RegAddrState: 'AL',
        RegAddrZip: 35124,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '2240 CUMBERLAND LAKE DR',
        RegAddrCity: 'PINSON',
        RegAddrState: 'AL',
        RegAddrZip: 35126,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '4300 BEAR CREEK RD',
        RegAddrCity: 'STERRETT',
        RegAddrState: 'AL',
        RegAddrZip: 35147,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '98 HARDY LN',
        RegAddrCity: 'SYLACAUGA',
        RegAddrState: 'AL',
        RegAddrZip: 35151,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '5297 PROMENADE DR',
        RegAddrCity: 'TRUSSVILLE',
        RegAddrState: 'AL',
        RegAddrZip: 35173,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '7601 COTTONRIDGE RD',
        RegAddrCity: 'TRUSSVILLE',
        RegAddrState: 'AL',
        RegAddrZip: 35173,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '622 LINDEN ST',
        RegAddrCity: 'TRUSSVILLE',
        RegAddrState: 'AL',
        RegAddrZip: 35173,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '6529 MOSS ROCK RDG',
        RegAddrCity: 'TRUSSVILLE',
        RegAddrState: 'AL',
        RegAddrZip: 35173,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '1184 COUNTY ROAD 511',
        RegAddrCity: 'VERBENA',
        RegAddrState: 'AL',
        RegAddrZip: 36091,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '4329 VESTVIEW LN',
        RegAddrCity: 'VESTAVIA',
        RegAddrState: 'AL',
        RegAddrZip: 35242,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '2030 MONTREAT PKWY',
        RegAddrCity: 'VESTAVIA HLS',
        RegAddrState: 'AL',
        RegAddrZip: 35216,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '2160 KELLY LN',
        RegAddrCity: 'VESTAVIA HLS',
        RegAddrState: 'AL',
        RegAddrZip: 35216,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '348 GRAND TRACE DR',
        RegAddrCity: 'WARRIOR',
        RegAddrState: 'AL',
        RegAddrZip: 35180,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '851 ROCK SPRINGS RD',
        RegAddrCity: 'WARRIOR',
        RegAddrState: 'AL',
        RegAddrZip: 35180,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '1519 SAGEFIELD RD',
        RegAddrCity: 'WARRIOR',
        RegAddrState: 'AL',
        RegAddrZip: 35180,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '9687 COOPER DR',
        RegAddrCity: 'WARRIOR',
        RegAddrState: 'AL',
        RegAddrZip: 35180,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '14819 N SCOTTSVILLE RD',
        RegAddrCity: 'WEST BLOCTON',
        RegAddrState: 'AL',
        RegAddrZip: 35184,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '260 WAGES DR',
        RegAddrCity: 'WILSONVILLE',
        RegAddrState: 'AL',
        RegAddrZip: 35186,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '522 BROTHERS AVE',
        RegAddrCity: 'WILSONVILLE',
        RegAddrState: 'AL',
        RegAddrZip: 35186,
        CongressionalDistrict: 6,
      },
    ],
  },
  {
    name: 'AL-7',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/AL-7?ref=gh-pages',
    state: 'AL',
    state_and_number: 'AL-7',
    zip: 35064,
    congressionalDistrict: 7,
    addressesInDistrict: [
      {
        RegAddrLine1: '703 17TH ST NW',
        RegAddrCity: 'ALICEVILLE',
        RegAddrState: 'AL',
        RegAddrZip: 35442,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '228 CENTER ST',
        RegAddrCity: 'BESSEMER',
        RegAddrState: 'AL',
        RegAddrZip: 35020,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '718 HALL AVE',
        RegAddrCity: 'BESSEMER',
        RegAddrState: 'AL',
        RegAddrZip: 35020,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '435 36TH ST S',
        RegAddrCity: 'BESSEMER',
        RegAddrState: 'AL',
        RegAddrZip: 35020,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '5300 WAR EAGLE DR',
        RegAddrCity: 'BESSEMER',
        RegAddrState: 'AL',
        RegAddrZip: 35022,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '809 BRIARWOOD DR',
        RegAddrCity: 'BESSEMER',
        RegAddrState: 'AL',
        RegAddrZip: 35022,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '7841 DIVISION AVE',
        RegAddrCity: 'BIRMINGHAM',
        RegAddrState: 'AL',
        RegAddrZip: 35206,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '8825 PARKWAY E',
        RegAddrCity: 'BIRMINGHAM',
        RegAddrState: 'AL',
        RegAddrZip: 35206,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '1400 44TH STREET ENSLEY',
        RegAddrCity: 'BIRMINGHAM',
        RegAddrState: 'AL',
        RegAddrZip: 35208,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '1120 47TH STREET ENSLEY',
        RegAddrCity: 'BIRMINGHAM',
        RegAddrState: 'AL',
        RegAddrZip: 35208,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '1820 32ND STREET ENSLEY',
        RegAddrCity: 'BIRMINGHAM',
        RegAddrState: 'AL',
        RegAddrZip: 35208,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '1209 GRACE ST',
        RegAddrCity: 'BIRMINGHAM',
        RegAddrState: 'AL',
        RegAddrZip: 35209,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '216 15TH AVE SW',
        RegAddrCity: 'BIRMINGHAM',
        RegAddrState: 'AL',
        RegAddrZip: 35211,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '901 29TH ST SW',
        RegAddrCity: 'BIRMINGHAM',
        RegAddrState: 'AL',
        RegAddrZip: 35211,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '901 CHINCHONA DR',
        RegAddrCity: 'BIRMINGHAM',
        RegAddrState: 'AL',
        RegAddrZip: 35214,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '1108 WESTCHESTER PKWY',
        RegAddrCity: 'BIRMINGHAM',
        RegAddrState: 'AL',
        RegAddrZip: 35214,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '1920 BURGIN AVE',
        RegAddrCity: 'BIRMINGHAM',
        RegAddrState: 'AL',
        RegAddrZip: 35217,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '3408 ALEMEDA AVE SW',
        RegAddrCity: 'BIRMINGHAM',
        RegAddrState: 'AL',
        RegAddrZip: 35221,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '821 DANDRIDGE RD',
        RegAddrCity: 'BIRMINGHAM',
        RegAddrState: 'AL',
        RegAddrZip: 35221,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '1318 32ND ST N',
        RegAddrCity: 'BIRMINGHAM',
        RegAddrState: 'AL',
        RegAddrZip: 35234,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '132 COUNTRY CLUB DR',
        RegAddrCity: 'BUTLER',
        RegAddrState: 'AL',
        RegAddrZip: 36904,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '1936 HIGHWAY 114',
        RegAddrCity: 'BUTLER',
        RegAddrState: 'AL',
        RegAddrZip: 36904,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '1605 S MULBERRY AVE',
        RegAddrCity: 'BUTLER',
        RegAddrState: 'AL',
        RegAddrZip: 36904,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '8 HUMMINGBIRD DR',
        RegAddrCity: 'CARROLLTON',
        RegAddrState: 'AL',
        RegAddrZip: 35447,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '2330 4TH ST NE',
        RegAddrCity: 'CENTER POINT',
        RegAddrState: 'AL',
        RegAddrZip: 35215,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '2700 6TH ST NE',
        RegAddrCity: 'CENTER POINT',
        RegAddrState: 'AL',
        RegAddrZip: 35215,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '521 CAMELLIA RD',
        RegAddrCity: 'CENTER POINT',
        RegAddrState: 'AL',
        RegAddrZip: 35215,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '4912 CREEKWOOD DR',
        RegAddrCity: 'COTTONDALE',
        RegAddrState: 'AL',
        RegAddrZip: 35453,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '6800 OAKVIEW LN',
        RegAddrCity: 'COTTONDALE',
        RegAddrState: 'AL',
        RegAddrZip: 35453,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '133 55TH ST',
        RegAddrCity: 'FAIRFIELD',
        RegAddrState: 'AL',
        RegAddrZip: 35064,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '14560 THOMPSON AVERY RD',
        RegAddrCity: 'FOSTERS',
        RegAddrState: 'AL',
        RegAddrZip: 35463,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '12338 RAY BASS RD',
        RegAddrCity: 'GORDO',
        RegAddrState: 'AL',
        RegAddrZip: 35466,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '1212 WYNN ST',
        RegAddrCity: 'GREENSBORO',
        RegAddrState: 'AL',
        RegAddrZip: 36744,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '65 COAL FIRE RD',
        RegAddrCity: 'JACKSON',
        RegAddrState: 'AL',
        RegAddrZip: 36545,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '16946 COUNTY ROAD 37 S',
        RegAddrCity: 'LETOHATCHEE',
        RegAddrState: 'AL',
        RegAddrZip: 36047,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '6410 COUNTY ROAD 33',
        RegAddrCity: 'LETOHATCHEE',
        RegAddrState: 'AL',
        RegAddrZip: 36047,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '122 CHANDLER ST',
        RegAddrCity: 'MARION',
        RegAddrState: 'AL',
        RegAddrZip: 36756,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '20517 CLANCY DR',
        RegAddrCity: 'MC CALLA',
        RegAddrState: 'AL',
        RegAddrZip: 35111,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '644 VIRGINIA AVE',
        RegAddrCity: 'MONTGOMERY',
        RegAddrState: 'AL',
        RegAddrZip: 36104,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '915 S JACKSON ST',
        RegAddrCity: 'MONTGOMERY',
        RegAddrState: 'AL',
        RegAddrZip: 36104,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '3662 SOUTHMONT DR',
        RegAddrCity: 'MONTGOMERY',
        RegAddrState: 'AL',
        RegAddrZip: 36105,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '931 GARLAND DR',
        RegAddrCity: 'MONTGOMERY',
        RegAddrState: 'AL',
        RegAddrZip: 36108,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '4321 BREWER RD',
        RegAddrCity: 'MONTGOMERY',
        RegAddrState: 'AL',
        RegAddrZip: 36108,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '4323 SUNNYBROOK DR',
        RegAddrCity: 'MONTGOMERY',
        RegAddrState: 'AL',
        RegAddrZip: 36108,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '1520 OAK ST',
        RegAddrCity: 'MONTGOMERY',
        RegAddrState: 'AL',
        RegAddrZip: 36108,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '145 ROSEWOOD LN',
        RegAddrCity: 'MOUNDVILLE',
        RegAddrState: 'AL',
        RegAddrZip: 35474,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '11833 ASPENWOOD DR',
        RegAddrCity: 'MOUNDVILLE',
        RegAddrState: 'AL',
        RegAddrZip: 35474,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '586 VALLEY VIEW GARDENS CIR',
        RegAddrCity: 'MOUNDVILLE',
        RegAddrState: 'AL',
        RegAddrZip: 35474,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '2505 12TH ST',
        RegAddrCity: 'NORTHPORT',
        RegAddrState: 'AL',
        RegAddrZip: 35476,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '3 LUKER CIR',
        RegAddrCity: 'SELMA',
        RegAddrState: 'AL',
        RegAddrZip: 36701,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '140 COUNTY ROAD 401',
        RegAddrCity: 'SELMA',
        RegAddrState: 'AL',
        RegAddrZip: 36701,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '6099 WATER AVE',
        RegAddrCity: 'SELMA',
        RegAddrState: 'AL',
        RegAddrZip: 36703,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '1216 SOUWILPA RD',
        RegAddrCity: 'SILAS',
        RegAddrState: 'AL',
        RegAddrZip: 36919,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '741 MONCRIEF DR',
        RegAddrCity: 'THOMASVILLE',
        RegAddrState: 'AL',
        RegAddrZip: 36784,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '3215 30TH ST',
        RegAddrCity: 'TUSCALOOSA',
        RegAddrState: 'AL',
        RegAddrZip: 35401,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '621 QUEEN CITY AVE',
        RegAddrCity: 'TUSCALOOSA',
        RegAddrState: 'AL',
        RegAddrZip: 35401,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '3500 12TH ST NE',
        RegAddrCity: 'TUSCALOOSA',
        RegAddrState: 'AL',
        RegAddrZip: 35404,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '2437 20TH ST E',
        RegAddrCity: 'TUSCALOOSA',
        RegAddrState: 'AL',
        RegAddrZip: 35404,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '1313 WAKEFIELD DR',
        RegAddrCity: 'TUSCALOOSA',
        RegAddrState: 'AL',
        RegAddrZip: 35405,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '3815 1ST CT E',
        RegAddrCity: 'TUSCALOOSA',
        RegAddrState: 'AL',
        RegAddrZip: 35405,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '144 TURPIN VISE RD',
        RegAddrCity: 'UNIONTOWN',
        RegAddrState: 'AL',
        RegAddrZip: 36786,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '715 OCMULGEE DR',
        RegAddrCity: 'VALLEY GRANDE',
        RegAddrState: 'AL',
        RegAddrZip: 36701,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '14525 WARD 10',
        RegAddrCity: 'WARD',
        RegAddrState: 'AL',
        RegAddrZip: 36922,
        CongressionalDistrict: 7,
      },
    ],
  },
  {
    name: 'AR-1',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/AR-1?ref=gh-pages',
    state: 'AR',
    state_and_number: 'AR-1',
    zip: 38769,
    congressionalDistrict: 1,
    addressesInDistrict: [
      {
        RegAddrLine1: '1008 N 4TH ST',
        RegAddrCity: 'AUGUSTA',
        RegAddrState: 'AR',
        RegAddrZip: 72006,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '711 S 3RD ST',
        RegAddrCity: 'AUGUSTA',
        RegAddrState: 'AR',
        RegAddrZip: 72006,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2502 DOGWOOD LN',
        RegAddrCity: 'AUSTIN',
        RegAddrState: 'AR',
        RegAddrZip: 72007,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '732 LEMAY LOOP',
        RegAddrCity: 'AUSTIN',
        RegAddrState: 'AR',
        RegAddrZip: 72007,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1309 BRIAR DR',
        RegAddrCity: 'BATESVILLE',
        RegAddrState: 'AR',
        RegAddrZip: 72501,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '295 FRANCIS ST',
        RegAddrCity: 'BATESVILLE',
        RegAddrState: 'AR',
        RegAddrZip: 72501,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '204 KYLER RD',
        RegAddrCity: 'BATESVILLE',
        RegAddrState: 'AR',
        RegAddrZip: 72501,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2107 N CIRCLE DR',
        RegAddrCity: 'BLYTHEVILLE',
        RegAddrState: 'AR',
        RegAddrZip: 72315,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '803 S AIR BASE HWY',
        RegAddrCity: 'BLYTHEVILLE',
        RegAddrState: 'AR',
        RegAddrZip: 72315,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1619 THIDA RD',
        RegAddrCity: 'BRADFORD',
        RegAddrState: 'AR',
        RegAddrZip: 72020,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '503 STEVENS ST',
        RegAddrCity: 'BROOKLAND',
        RegAddrState: 'AR',
        RegAddrZip: 72417,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '135 WILLIAMSBURG CV',
        RegAddrCity: 'CABOT',
        RegAddrState: 'AR',
        RegAddrZip: 72023,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '13 TRADEWINDS DR',
        RegAddrCity: 'CABOT',
        RegAddrState: 'AR',
        RegAddrZip: 72023,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '106 HICKORY ST',
        RegAddrCity: 'CABOT',
        RegAddrState: 'AR',
        RegAddrZip: 72023,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '45 NEVADA LN',
        RegAddrCity: 'CABOT',
        RegAddrState: 'AR',
        RegAddrZip: 72023,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '14 WARREN ST',
        RegAddrCity: 'CABOT',
        RegAddrState: 'AR',
        RegAddrZip: 72023,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '403 WACO ST',
        RegAddrCity: 'CARAWAY',
        RegAddrState: 'AR',
        RegAddrZip: 72419,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '73 EASTWOOD ST',
        RegAddrCity: 'CARLISLE',
        RegAddrState: 'AR',
        RegAddrZip: 72024,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '123 TERRIE DR',
        RegAddrCity: 'CAVE CITY',
        RegAddrState: 'AR',
        RegAddrZip: 72521,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '240 CHICK LN',
        RegAddrCity: 'CAVE CITY',
        RegAddrState: 'AR',
        RegAddrZip: 72521,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '519 ARKANSAS 230',
        RegAddrCity: 'CAVE CITY',
        RegAddrState: 'AR',
        RegAddrZip: 72521,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1002 N LAKESHORE DR',
        RegAddrCity: 'CHEROKEE VLG',
        RegAddrState: 'AR',
        RegAddrZip: 72529,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '60 PONTIAC DR',
        RegAddrCity: 'CHEROKEE VLG',
        RegAddrState: 'AR',
        RegAddrZip: 72529,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '500 PHILLIPS 526 RD',
        RegAddrCity: 'CRUMROD',
        RegAddrState: 'AR',
        RegAddrZip: 72328,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '206 S MAIN ST',
        RegAddrCity: 'DERMOTT',
        RegAddrState: 'AR',
        RegAddrZip: 71638,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '708 ERWIN ST',
        RegAddrCity: 'DES ARC',
        RegAddrState: 'AR',
        RegAddrZip: 72040,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '296 CENTER RD',
        RegAddrCity: 'DEWITT',
        RegAddrState: 'AR',
        RegAddrZip: 72042,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '9 CRESCENT PARK DR',
        RegAddrCity: 'DEWITT',
        RegAddrState: 'AR',
        RegAddrZip: 72042,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '324 W 9TH ST',
        RegAddrCity: 'DEWITT',
        RegAddrState: 'AR',
        RegAddrZip: 72042,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '114 N MAY DR',
        RegAddrCity: 'DUMAS',
        RegAddrState: 'AR',
        RegAddrZip: 71639,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '37 EVERGREEN ST',
        RegAddrCity: 'DUMAS',
        RegAddrState: 'AR',
        RegAddrZip: 71639,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '200 WELDON RD',
        RegAddrCity: 'EDGEMONT',
        RegAddrState: 'AR',
        RegAddrZip: 72044,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2002 HENDERSON RD',
        RegAddrCity: 'ENGLAND',
        RegAddrState: 'AR',
        RegAddrZip: 72046,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '7084 HIGHWAY 284',
        RegAddrCity: 'FORREST CITY',
        RegAddrState: 'AR',
        RegAddrZip: 72335,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '435 FUSSELL AVE',
        RegAddrCity: 'FORREST CITY',
        RegAddrState: 'AR',
        RegAddrZip: 72335,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '8782 ROLLING HILLS LN',
        RegAddrCity: 'HARRISBURG',
        RegAddrState: 'AR',
        RegAddrZip: 72432,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '8160 TULIP LN',
        RegAddrCity: 'HARRISBURG',
        RegAddrState: 'AR',
        RegAddrZip: 72432,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '801 W NEWMAN AVE',
        RegAddrCity: 'HARRISON',
        RegAddrState: 'AR',
        RegAddrZip: 72601,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1216 W SEARCY ST',
        RegAddrCity: 'HEBER SPRINGS',
        RegAddrState: 'AR',
        RegAddrZip: 72543,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1431 BLAKEY RD',
        RegAddrCity: 'HEBER SPRINGS',
        RegAddrState: 'AR',
        RegAddrZip: 72543,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '20 PANGBURN RD',
        RegAddrCity: 'HEBER SPRINGS',
        RegAddrState: 'AR',
        RegAddrZip: 72543,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '610 CHERRY ST',
        RegAddrCity: 'HOXIE',
        RegAddrState: 'AR',
        RegAddrZip: 72433,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2642 OLD UNION RD',
        RegAddrCity: 'IMBODEN',
        RegAddrState: 'AR',
        RegAddrZip: 72434,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '300 1ST ST',
        RegAddrCity: 'JOINER',
        RegAddrState: 'AR',
        RegAddrZip: 72350,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1100 BROADMOOR RD',
        RegAddrCity: 'JONESBORO',
        RegAddrState: 'AR',
        RegAddrZip: 72401,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2617 N CHURCH ST',
        RegAddrCity: 'JONESBORO',
        RegAddrState: 'AR',
        RegAddrZip: 72401,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '406 MILLER ST',
        RegAddrCity: 'JONESBORO',
        RegAddrState: 'AR',
        RegAddrZip: 72401,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '3305 JERIDON CV',
        RegAddrCity: 'JONESBORO',
        RegAddrState: 'AR',
        RegAddrZip: 72404,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2501 JUDES CT',
        RegAddrCity: 'JONESBORO',
        RegAddrState: 'AR',
        RegAddrZip: 72404,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2309 MORNINGSIDE DR',
        RegAddrCity: 'JONESBORO',
        RegAddrState: 'AR',
        RegAddrZip: 72404,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '571 GREENE ROAD 142',
        RegAddrCity: 'LAFE',
        RegAddrState: 'AR',
        RegAddrZip: 72436,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '101 OAK ST',
        RegAddrCity: 'LAKE CITY',
        RegAddrState: 'AR',
        RegAddrZip: 72437,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '221 DOGWOOD ST',
        RegAddrCity: 'LAKE CITY',
        RegAddrState: 'AR',
        RegAddrZip: 72437,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '4429 E HIGHWAY 82',
        RegAddrCity: 'LAKE VILLAGE',
        RegAddrState: 'AR',
        RegAddrZip: 71653,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '3728 N COUNTY ROAD 17',
        RegAddrCity: 'LEACHVILLE',
        RegAddrState: 'AR',
        RegAddrZip: 72438,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '214 E 5TH ST',
        RegAddrCity: 'LONOKE',
        RegAddrState: 'AR',
        RegAddrZip: 72086,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '206 SUNSET COUNTRY LN',
        RegAddrCity: 'LONOKE',
        RegAddrState: 'AR',
        RegAddrZip: 72086,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '320 SYDNEY PL',
        RegAddrCity: 'LONOKE',
        RegAddrState: 'AR',
        RegAddrZip: 72086,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '629 N RIVER BEND DR',
        RegAddrCity: 'MARION',
        RegAddrState: 'AR',
        RegAddrZip: 72364,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '8516 WHEELER RD',
        RegAddrCity: 'MARION',
        RegAddrState: 'AR',
        RegAddrZip: 72364,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '395 BEECHWOOD CV',
        RegAddrCity: 'MARION',
        RegAddrState: 'AR',
        RegAddrZip: 72364,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '45 WOLFE DR',
        RegAddrCity: 'MCGEHEE',
        RegAddrState: 'AR',
        RegAddrZip: 71654,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '289 KNOLLWOOD DR',
        RegAddrCity: 'MIDWAY',
        RegAddrState: 'AR',
        RegAddrZip: 72651,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '35 COUNTY ROAD 900',
        RegAddrCity: 'MIDWAY',
        RegAddrState: 'AR',
        RegAddrZip: 72651,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '301 HIGHWAY 148',
        RegAddrCity: 'MONETTE',
        RegAddrState: 'AR',
        RegAddrZip: 72447,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '162 MAGNOLIA CT',
        RegAddrCity: 'MOUNTAIN HOME',
        RegAddrState: 'AR',
        RegAddrZip: 72653,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1211 GAYLER ST',
        RegAddrCity: 'MOUNTAIN VIEW',
        RegAddrState: 'AR',
        RegAddrZip: 72560,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1000 CLARENCE ST',
        RegAddrCity: 'MOUNTAIN VIEW',
        RegAddrState: 'AR',
        RegAddrZip: 72560,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '',
        RegAddrCity: 'Mt Pleasant',
        RegAddrState: 'AR',
        RegAddrZip: 72561,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '11225 HIGHWAY 201 S',
        RegAddrCity: 'MTN HOME',
        RegAddrState: 'AR',
        RegAddrZip: 72653,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2908 WILMANS DR',
        RegAddrCity: 'NEWPORT',
        RegAddrState: 'AR',
        RegAddrZip: 72112,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '400 GARFIELD ST',
        RegAddrCity: 'NEWPORT',
        RegAddrState: 'AR',
        RegAddrZip: 72112,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '120 MOCKINGBIRD LN',
        RegAddrCity: 'OSCEOLA',
        RegAddrState: 'AR',
        RegAddrZip: 72370,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '503 W DRIVER AVE',
        RegAddrCity: 'OSCEOLA',
        RegAddrState: 'AR',
        RegAddrZip: 72370,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '608 N ROCKINGCHAIR RD',
        RegAddrCity: 'PARAGOULD',
        RegAddrState: 'AR',
        RegAddrZip: 72450,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '3715 REYNOLDS PARK RD',
        RegAddrCity: 'PARAGOULD',
        RegAddrState: 'AR',
        RegAddrZip: 72450,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '724 S HOUSTON AVE',
        RegAddrCity: 'PIGGOTT',
        RegAddrState: 'AR',
        RegAddrZip: 72454,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '415 N SCURLOCK AVE',
        RegAddrCity: 'PIGGOTT',
        RegAddrState: 'AR',
        RegAddrZip: 72454,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '840 S GARFIELD AVE',
        RegAddrCity: 'PIGGOTT',
        RegAddrState: 'AR',
        RegAddrZip: 72454,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '455 COUNTY ROAD 341',
        RegAddrCity: 'PIGGOTT',
        RegAddrState: 'AR',
        RegAddrZip: 72454,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '967 SUNRISE MOUNTAIN RD',
        RegAddrCity: 'PINDALL',
        RegAddrState: 'AR',
        RegAddrZip: 72669,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2526 OAK CREEK ST',
        RegAddrCity: 'POCAHONTAS',
        RegAddrState: 'AR',
        RegAddrZip: 72455,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1815 TAMMIE DR',
        RegAddrCity: 'POCAHONTAS',
        RegAddrState: 'AR',
        RegAddrZip: 72455,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2201 ARCHER ST',
        RegAddrCity: 'POCAHONTAS',
        RegAddrState: 'AR',
        RegAddrZip: 72455,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '303 ARKANSAS ST',
        RegAddrCity: 'SAINT CHARLES',
        RegAddrState: 'AR',
        RegAddrZip: 72140,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '941 J S JONES RD',
        RegAddrCity: 'SCOTT',
        RegAddrState: 'AR',
        RegAddrZip: 72142,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '3413 BURR OAK RD',
        RegAddrCity: 'ST JOE',
        RegAddrState: 'AR',
        RegAddrZip: 72675,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '86 KESWICK LN',
        RegAddrCity: 'STAR CITY',
        RegAddrState: 'AR',
        RegAddrZip: 71667,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '800 HURD AVE',
        RegAddrCity: 'TRUMANN',
        RegAddrState: 'AR',
        RegAddrZip: 72472,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '432 BYRN LN',
        RegAddrCity: 'TRUMANN',
        RegAddrState: 'AR',
        RegAddrZip: 72472,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1208 REED AVE',
        RegAddrCity: 'TRUMANN',
        RegAddrState: 'AR',
        RegAddrZip: 72472,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '90 LAWRENCE ROAD 429',
        RegAddrCity: 'WALNUT RIDGE',
        RegAddrState: 'AR',
        RegAddrZip: 72476,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '115 FACULTY DR',
        RegAddrCity: 'WALNUT RIDGE',
        RegAddrState: 'AR',
        RegAddrZip: 72476,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '13 GERALDINE ST',
        RegAddrCity: 'WARD',
        RegAddrState: 'AR',
        RegAddrZip: 72176,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '315 S WESTWIND LN',
        RegAddrCity: 'WEINER',
        RegAddrState: 'AR',
        RegAddrZip: 72479,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '106 CHERRYDALE',
        RegAddrCity: 'WEST HELENA',
        RegAddrState: 'AR',
        RegAddrZip: 72390,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '137 MISSISSIPPI',
        RegAddrCity: 'WEST HELENA',
        RegAddrState: 'AR',
        RegAddrZip: 72390,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '625 TULANE DR',
        RegAddrCity: 'WEST MEMPHIS',
        RegAddrState: 'AR',
        RegAddrZip: 72301,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1301 BISCAYNE DR',
        RegAddrCity: 'WEST MEMPHIS',
        RegAddrState: 'AR',
        RegAddrZip: 72301,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '6228 HIGHWAY 50 E',
        RegAddrCity: 'WIDENER',
        RegAddrState: 'AR',
        RegAddrZip: 72394,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '313 UNION AVE W',
        RegAddrCity: 'WYNNE',
        RegAddrState: 'AR',
        RegAddrZip: 72396,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '3142 HIGHWAY 364',
        RegAddrCity: 'WYNNE',
        RegAddrState: 'AR',
        RegAddrZip: 72396,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1100 MARTIN DR E',
        RegAddrCity: 'WYNNE',
        RegAddrState: 'AR',
        RegAddrZip: 72396,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1485 HAMILTON AVE E',
        RegAddrCity: 'WYNNE',
        RegAddrState: 'AR',
        RegAddrZip: 72396,
        CongressionalDistrict: 1,
      },
    ],
  },
  {
    name: 'AR-2',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/AR-2?ref=gh-pages',
    state: 'AR',
    state_and_number: 'AR-2',
    zip: 71909,
    congressionalDistrict: 2,
    addressesInDistrict: [
      {
        RegAddrLine1: '14301 VIMY RIDGE RD',
        RegAddrCity: 'ALEXANDER',
        RegAddrState: 'AR',
        RegAddrZip: 72002,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '8013 N HAVEN DR',
        RegAddrCity: 'ALEXANDER',
        RegAddrState: 'AR',
        RegAddrZip: 72002,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '12727 GERMANIA',
        RegAddrCity: 'ALEXANDER',
        RegAddrState: 'AR',
        RegAddrZip: 72002,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '5400 WAITE CIR',
        RegAddrCity: 'ALEXANDER',
        RegAddrState: 'AR',
        RegAddrZip: 72002,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '168 GLAZE LOOP',
        RegAddrCity: 'BALD KNOB',
        RegAddrState: 'AR',
        RegAddrZip: 72010,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '726 HONEYSUCKLE RD',
        RegAddrCity: 'BALD KNOB',
        RegAddrState: 'AR',
        RegAddrZip: 72010,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '11101 STYLES RD',
        RegAddrCity: 'BAUXITE',
        RegAddrState: 'AR',
        RegAddrZip: 72011,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '104 HIGHWAY 267 SPUR',
        RegAddrCity: 'BEEBE',
        RegAddrState: 'AR',
        RegAddrZip: 72012,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '108 ORION ST',
        RegAddrCity: 'BEEBE',
        RegAddrState: 'AR',
        RegAddrZip: 72012,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '107 MAHONEY DR',
        RegAddrCity: 'BEEBE',
        RegAddrState: 'AR',
        RegAddrZip: 72012,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '311 PRIEST RD',
        RegAddrCity: 'BEEBE',
        RegAddrState: 'AR',
        RegAddrZip: 72012,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '517 PETRAY ST',
        RegAddrCity: 'BENTON',
        RegAddrState: 'AR',
        RegAddrZip: 72015,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '415 MARYLAND ST',
        RegAddrCity: 'BENTON',
        RegAddrState: 'AR',
        RegAddrZip: 72015,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '524 PEARL',
        RegAddrCity: 'BENTON',
        RegAddrState: 'AR',
        RegAddrZip: 72015,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '4999 BROOKE CV',
        RegAddrCity: 'BENTON',
        RegAddrState: 'AR',
        RegAddrZip: 72019,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '4014 HARTFORD HILLS DR',
        RegAddrCity: 'BENTON',
        RegAddrState: 'AR',
        RegAddrZip: 72019,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '6155 PIERCE MANSE LOOP',
        RegAddrCity: 'BENTON',
        RegAddrState: 'AR',
        RegAddrZip: 72019,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '208 PIKE',
        RegAddrCity: 'BENTON',
        RegAddrState: 'AR',
        RegAddrZip: 72019,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2605 SE 7TH CT',
        RegAddrCity: 'BENTONVILLE',
        RegAddrState: 'AR',
        RegAddrZip: 72712,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '702 SW 3RD ST',
        RegAddrCity: 'BRYANT',
        RegAddrState: 'AR',
        RegAddrZip: 72022,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '5419 BOONE RD',
        RegAddrCity: 'BRYANT',
        RegAddrState: 'AR',
        RegAddrZip: 72022,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '15706 MCGILL RD',
        RegAddrCity: 'CABOT',
        RegAddrState: 'AR',
        RegAddrZip: 72023,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '16 AGARITA CV',
        RegAddrCity: 'CONWAY',
        RegAddrState: 'AR',
        RegAddrZip: 72032,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '10 PINECREST CT',
        RegAddrCity: 'CONWAY',
        RegAddrState: 'AR',
        RegAddrZip: 72032,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2201 BROADVIEW AVE',
        RegAddrCity: 'CONWAY',
        RegAddrState: 'AR',
        RegAddrZip: 72034,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '4305 GAZEBO DR',
        RegAddrCity: 'CONWAY',
        RegAddrState: 'AR',
        RegAddrZip: 72034,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1640 JOSH DR',
        RegAddrCity: 'CONWAY',
        RegAddrState: 'AR',
        RegAddrZip: 72034,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1922 MARTIN ST',
        RegAddrCity: 'CONWAY',
        RegAddrState: 'AR',
        RegAddrZip: 72034,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2960 CRAWFORD LOOP',
        RegAddrCity: 'CONWAY',
        RegAddrState: 'AR',
        RegAddrZip: 72034,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '3110 ROBERT OTT CIR',
        RegAddrCity: 'CONWAY',
        RegAddrState: 'AR',
        RegAddrZip: 72034,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '201 DONAGHEY AVE',
        RegAddrCity: 'CONWAY',
        RegAddrState: 'AR',
        RegAddrZip: 72035,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '3009 SUMMERHILL PL',
        RegAddrCity: 'FAIRFIELD BAY',
        RegAddrState: 'AR',
        RegAddrZip: 72088,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '4 SUNSET ST',
        RegAddrCity: 'GREENBRIER',
        RegAddrState: 'AR',
        RegAddrZip: 72058,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '43 LINDA CIR',
        RegAddrCity: 'GREENBRIER',
        RegAddrState: 'AR',
        RegAddrZip: 72058,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '3 TANNON CV',
        RegAddrCity: 'GREENBRIER',
        RegAddrState: 'AR',
        RegAddrZip: 72058,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '37 ACRES CIR',
        RegAddrCity: 'GREENBRIER',
        RegAddrState: 'AR',
        RegAddrZip: 72058,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '93 S BROADVIEW ST',
        RegAddrCity: 'GREENBRIER',
        RegAddrState: 'AR',
        RegAddrZip: 72058,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '17677 HIGHWAY 11',
        RegAddrCity: 'GRIFFITHVILLE',
        RegAddrState: 'AR',
        RegAddrZip: 72060,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '21714 SCOTT EAST END RD',
        RegAddrCity: 'HENSLEY',
        RegAddrState: 'AR',
        RegAddrZip: 72065,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '459 WREN RD',
        RegAddrCity: 'HIGDEN',
        RegAddrState: 'AR',
        RegAddrZip: 72067,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '28 ROJO LN',
        RegAddrCity: 'HOT SPRINGS',
        RegAddrState: 'AR',
        RegAddrZip: 71909,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1124 LEHMAN DR',
        RegAddrCity: 'JACKSONVILLE',
        RegAddrState: 'AR',
        RegAddrZip: 72076,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '605 HARPOLE ST',
        RegAddrCity: 'JACKSONVILLE',
        RegAddrState: 'AR',
        RegAddrZip: 72076,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '7226 DONNA ST',
        RegAddrCity: 'JACKSONVILLE',
        RegAddrState: 'AR',
        RegAddrZip: 72076,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '11 WESTPOINTE DR',
        RegAddrCity: 'JACKSONVILLE',
        RegAddrState: 'AR',
        RegAddrZip: 72076,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1721 TROOP CT',
        RegAddrCity: 'JACKSONVILLE',
        RegAddrState: 'AR',
        RegAddrZip: 72076,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '104 MONTANA CIR',
        RegAddrCity: 'JACKSONVILLE',
        RegAddrState: 'AR',
        RegAddrZip: 72076,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '161 KENTUCKY CIR',
        RegAddrCity: 'JACKSONVILLE',
        RegAddrState: 'AR',
        RegAddrZip: 72076,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '406 LOCUST',
        RegAddrCity: 'JUDSONIA',
        RegAddrState: 'AR',
        RegAddrZip: 72081,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '900 W DAISY L GATSON BATES DR',
        RegAddrCity: 'LITTLE ROCK',
        RegAddrState: 'AR',
        RegAddrZip: 72202,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2115 RICE ST',
        RegAddrCity: 'LITTLE ROCK',
        RegAddrState: 'AR',
        RegAddrZip: 72202,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '73 BELMONT DR',
        RegAddrCity: 'LITTLE ROCK',
        RegAddrState: 'AR',
        RegAddrZip: 72204,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2810 W 13TH ST',
        RegAddrCity: 'LITTLE ROCK',
        RegAddrState: 'AR',
        RegAddrZip: 72204,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '6023 BOYLE PARK RD',
        RegAddrCity: 'LITTLE ROCK',
        RegAddrState: 'AR',
        RegAddrZip: 72204,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '11 BRITTS LN',
        RegAddrCity: 'LITTLE ROCK',
        RegAddrState: 'AR',
        RegAddrZip: 72204,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '4009 KENYON DR',
        RegAddrCity: 'LITTLE ROCK',
        RegAddrState: 'AR',
        RegAddrZip: 72205,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '5415 B ST',
        RegAddrCity: 'LITTLE ROCK',
        RegAddrState: 'AR',
        RegAddrZip: 72205,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '5520 SOUTHWOOD RD',
        RegAddrCity: 'LITTLE ROCK',
        RegAddrState: 'AR',
        RegAddrZip: 72205,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '136 PEARL AVE',
        RegAddrCity: 'LITTLE ROCK',
        RegAddrState: 'AR',
        RegAddrZip: 72205,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2507 GROVE CIR',
        RegAddrCity: 'LITTLE ROCK',
        RegAddrState: 'AR',
        RegAddrZip: 72205,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '6401 HEATHER LN',
        RegAddrCity: 'LITTLE ROCK',
        RegAddrState: 'AR',
        RegAddrZip: 72206,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2426 LOUISIANA ST',
        RegAddrCity: 'LITTLE ROCK',
        RegAddrState: 'AR',
        RegAddrZip: 72206,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2400 WOLFE ST',
        RegAddrCity: 'LITTLE ROCK',
        RegAddrState: 'AR',
        RegAddrZip: 72206,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2010 S GAINES ST',
        RegAddrCity: 'LITTLE ROCK',
        RegAddrState: 'AR',
        RegAddrZip: 72206,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '8508 SHELLEY DR',
        RegAddrCity: 'LITTLE ROCK',
        RegAddrState: 'AR',
        RegAddrZip: 72209,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '22 SOUTHMONT DR',
        RegAddrCity: 'LITTLE ROCK',
        RegAddrState: 'AR',
        RegAddrZip: 72209,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '7200 WOODSON RD',
        RegAddrCity: 'LITTLE ROCK',
        RegAddrState: 'AR',
        RegAddrZip: 72209,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '5920 TRENTON LN',
        RegAddrCity: 'LITTLE ROCK',
        RegAddrState: 'AR',
        RegAddrZip: 72209,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '3 CLOVER CT',
        RegAddrCity: 'LITTLE ROCK',
        RegAddrState: 'AR',
        RegAddrZip: 72210,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '44 PLANTATION ACRES DR',
        RegAddrCity: 'LITTLE ROCK',
        RegAddrState: 'AR',
        RegAddrZip: 72210,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '13817 SAINT MICHAEL DR',
        RegAddrCity: 'LITTLE ROCK',
        RegAddrState: 'AR',
        RegAddrZip: 72211,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '14 HONEY LOCUST CT',
        RegAddrCity: 'LITTLE ROCK',
        RegAddrState: 'AR',
        RegAddrZip: 72211,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '3 PICAYUNE CT',
        RegAddrCity: 'LITTLE ROCK',
        RegAddrState: 'AR',
        RegAddrZip: 72211,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '3205 N RODNEY PARHAM RD',
        RegAddrCity: 'LITTLE ROCK',
        RegAddrState: 'AR',
        RegAddrZip: 72212,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1909 SAWGRASS DR',
        RegAddrCity: 'LITTLE ROCK',
        RegAddrState: 'AR',
        RegAddrZip: 72212,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '23 CHALAMONT WAY',
        RegAddrCity: 'LITTLE ROCK',
        RegAddrState: 'AR',
        RegAddrZip: 72223,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '4717 WESTCHESTER DR',
        RegAddrCity: 'LITTLE ROCK',
        RegAddrState: 'AR',
        RegAddrZip: 72223,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '25516 JONES RD',
        RegAddrCity: 'LITTLE ROCK',
        RegAddrState: 'AR',
        RegAddrZip: 72223,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '9909 ECHO VALLEY CT',
        RegAddrCity: 'LITTLE ROCK',
        RegAddrState: 'AR',
        RegAddrZip: 72227,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '14 LANTERN HILL RD',
        RegAddrCity: 'LITTLE ROCK',
        RegAddrState: 'AR',
        RegAddrZip: 72227,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '8516 CHICKAMAUGA CT',
        RegAddrCity: 'MABELVALE',
        RegAddrState: 'AR',
        RegAddrZip: 72103,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '10021 W FAIRVIEW RD',
        RegAddrCity: 'MABELVALE',
        RegAddrState: 'AR',
        RegAddrZip: 72103,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '15 GOLDEN OAKS CV',
        RegAddrCity: 'MAUMELLE',
        RegAddrState: 'AR',
        RegAddrZip: 72113,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '10 BLUE MOUNTAIN DR',
        RegAddrCity: 'MAUMELLE',
        RegAddrState: 'AR',
        RegAddrZip: 72113,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '196 EASTERWOOD POINT RD',
        RegAddrCity: 'MAYFLOWER',
        RegAddrState: 'AR',
        RegAddrZip: 72106,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '308 W CENTER ST',
        RegAddrCity: 'MORRILTON',
        RegAddrState: 'AR',
        RegAddrZip: 72110,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '209 S CHEROKEE ST',
        RegAddrCity: 'MORRILTON',
        RegAddrState: 'AR',
        RegAddrZip: 72110,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '17 THINES RD',
        RegAddrCity: 'MORRILTON',
        RegAddrState: 'AR',
        RegAddrZip: 72110,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2705 E WASHINGTON AVE',
        RegAddrCity: 'N LITTLE ROCK',
        RegAddrState: 'AR',
        RegAddrZip: 72114,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '5109 N HILLS BLVD',
        RegAddrCity: 'N LITTLE ROCK',
        RegAddrState: 'AR',
        RegAddrZip: 72116,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '3300 N MAGNOLIA ST',
        RegAddrCity: 'N LITTLE ROCK',
        RegAddrState: 'AR',
        RegAddrZip: 72116,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '4925 OAKLAWN DR',
        RegAddrCity: 'N LITTLE ROCK',
        RegAddrState: 'AR',
        RegAddrZip: 72116,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '714 1/2 HADFIELD RD',
        RegAddrCity: 'N LITTLE ROCK',
        RegAddrState: 'AR',
        RegAddrZip: 72117,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '829 W 22ND ST',
        RegAddrCity: 'NORTH LITTLE ROCK',
        RegAddrState: 'AR',
        RegAddrZip: 72114,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '188 MILLER RD',
        RegAddrCity: 'OLA',
        RegAddrState: 'AR',
        RegAddrZip: 72853,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '471 DEBERRIE RD',
        RegAddrCity: 'PERRYVILLE',
        RegAddrState: 'AR',
        RegAddrZip: 72126,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '5557 HIGHWAY 60 W',
        RegAddrCity: 'PERRYVILLE',
        RegAddrState: 'AR',
        RegAddrZip: 72126,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1046 HIGHWAY 107',
        RegAddrCity: 'QUITMAN',
        RegAddrState: 'AR',
        RegAddrZip: 72131,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '600 N NASHVILLE AVE',
        RegAddrCity: 'RUSSELLVILLE',
        RegAddrState: 'AR',
        RegAddrZip: 72801,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '3028 HIGHWAY 16',
        RegAddrCity: 'SEARCY',
        RegAddrState: 'AR',
        RegAddrZip: 72143,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '217 PANTHER CREEK RD',
        RegAddrCity: 'SEARCY',
        RegAddrState: 'AR',
        RegAddrZip: 72143,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '108 PARKER LN',
        RegAddrCity: 'SEARCY',
        RegAddrState: 'AR',
        RegAddrZip: 72143,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2007 AUDLEY BOLTON DR',
        RegAddrCity: 'SEARCY',
        RegAddrState: 'AR',
        RegAddrZip: 72143,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '6022 SILVER OAK DR',
        RegAddrCity: 'SHERWOOD',
        RegAddrState: 'AR',
        RegAddrZip: 72120,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '8709 HOLIDAY DR',
        RegAddrCity: 'SHERWOOD',
        RegAddrState: 'AR',
        RegAddrZip: 72120,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1207 SILVER CREEK DR',
        RegAddrCity: 'SHERWOOD',
        RegAddrState: 'AR',
        RegAddrZip: 72120,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '36 GREENVIEW CIR',
        RegAddrCity: 'SHERWOOD',
        RegAddrState: 'AR',
        RegAddrZip: 72120,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '7 ARTESIAN WELL RD',
        RegAddrCity: 'SHERWOOD',
        RegAddrState: 'AR',
        RegAddrZip: 72120,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '113 SPRING OAK DR',
        RegAddrCity: 'SHERWOOD',
        RegAddrState: 'AR',
        RegAddrZip: 72120,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '9803 JACKSONVILLE CONWAY RD',
        RegAddrCity: 'SHERWOOD',
        RegAddrState: 'AR',
        RegAddrZip: 72120,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '104 JESSICA DR',
        RegAddrCity: 'SHERWOOD',
        RegAddrState: 'AR',
        RegAddrZip: 72120,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '670 HIGHWAY 287',
        RegAddrCity: 'VILONIA',
        RegAddrState: 'AR',
        RegAddrZip: 72173,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '24 TANNER LN',
        RegAddrCity: 'VILONIA',
        RegAddrState: 'AR',
        RegAddrZip: 72173,
        CongressionalDistrict: 2,
      },
    ],
  },
  {
    name: 'AR-3',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/AR-3?ref=gh-pages',
    state: 'AR',
    state_and_number: 'AR-3',
    zip: 65729,
    congressionalDistrict: 3,
    addressesInDistrict: [
      {
        RegAddrLine1: '1087 CULTIVAR RD',
        RegAddrCity: 'ALMA',
        RegAddrState: 'AR',
        RegAddrZip: 72921,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '2628 WHITNEY LN',
        RegAddrCity: 'ALMA',
        RegAddrState: 'AR',
        RegAddrZip: 72921,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '12 LAVENDON CIRCLE DR',
        RegAddrCity: 'BELLA VISTA',
        RegAddrState: 'AR',
        RegAddrZip: 72714,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '27 EASTLEIGH CIR',
        RegAddrCity: 'BELLA VISTA',
        RegAddrState: 'AR',
        RegAddrZip: 72714,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '15 COALBURN CIR',
        RegAddrCity: 'BELLA VISTA',
        RegAddrState: 'AR',
        RegAddrZip: 72715,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '66 BRANCHWOOD DR',
        RegAddrCity: 'BELLA VISTA',
        RegAddrState: 'AR',
        RegAddrZip: 72715,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '39 CHELSEA LN',
        RegAddrCity: 'BELLA VISTA',
        RegAddrState: 'AR',
        RegAddrZip: 72715,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1 ANTHONY LN',
        RegAddrCity: 'BELLA VISTA',
        RegAddrState: 'AR',
        RegAddrZip: 72715,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '26 SANDWICK CIR',
        RegAddrCity: 'BELLA VISTA',
        RegAddrState: 'AR',
        RegAddrZip: 72715,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '23 CANNICH LN',
        RegAddrCity: 'BELLA VISTA',
        RegAddrState: 'AR',
        RegAddrZip: 72715,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1102 NE BUCKINGHAM BLVD',
        RegAddrCity: 'BENTONVILLE',
        RegAddrState: 'AR',
        RegAddrZip: 72712,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '709 SE FULLERTON ST',
        RegAddrCity: 'BENTONVILLE',
        RegAddrState: 'AR',
        RegAddrZip: 72712,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '3103 SW TUNICA AVE',
        RegAddrCity: 'BENTONVILLE',
        RegAddrState: 'AR',
        RegAddrZip: 72712,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1705 SE 21ST ST',
        RegAddrCity: 'BENTONVILLE',
        RegAddrState: 'AR',
        RegAddrZip: 72712,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1000 LITTLE OSAGE AVE',
        RegAddrCity: 'BENTONVILLE',
        RegAddrState: 'AR',
        RegAddrZip: 72713,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '210 E MOUNTAIN AVE',
        RegAddrCity: 'BERRYVILLE',
        RegAddrState: 'AR',
        RegAddrZip: 72616,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '19729 HALE MOUNTAIN RD',
        RegAddrCity: 'CANEHILL',
        RegAddrState: 'AR',
        RegAddrZip: 72717,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '537 E LOWELL AVE',
        RegAddrCity: 'CAVE SPRINGS',
        RegAddrState: 'AR',
        RegAddrZip: 72718,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '430 RAVENNA DR',
        RegAddrCity: 'CENTERTON',
        RegAddrState: 'AR',
        RegAddrZip: 72719,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1001 KENSINGTON DR',
        RegAddrCity: 'CENTERTON',
        RegAddrState: 'AR',
        RegAddrZip: 72719,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '195 HICKORY ST',
        RegAddrCity: 'DIAMOND CITY',
        RegAddrState: 'AR',
        RegAddrZip: 72644,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '13650 SR 7 N',
        RegAddrCity: 'DOVER',
        RegAddrState: 'AR',
        RegAddrZip: 72837,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '909 SR 164 E',
        RegAddrCity: 'DOVER',
        RegAddrState: 'AR',
        RegAddrZip: 72837,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '2809 E MAIN ST',
        RegAddrCity: 'EL DORADO',
        RegAddrState: 'AR',
        RegAddrZip: 71730,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1022 COUNTY ROAD 114',
        RegAddrCity: 'EUREKA SPRINGS',
        RegAddrState: 'AR',
        RegAddrZip: 72632,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '452 GRANDVIEW CT',
        RegAddrCity: 'FARMINGTON',
        RegAddrState: 'AR',
        RegAddrZip: 72730,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1321 S WASHINGTON AVE',
        RegAddrCity: 'FAYETTEVILLE',
        RegAddrState: 'AR',
        RegAddrZip: 72701,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '3848 E SPYGLASS HILL DR',
        RegAddrCity: 'FAYETTEVILLE',
        RegAddrState: 'AR',
        RegAddrZip: 72701,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '822 N MISSION BLVD',
        RegAddrCity: 'FAYETTEVILLE',
        RegAddrState: 'AR',
        RegAddrZip: 72701,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '2039 E WILKINS PL',
        RegAddrCity: 'FAYETTEVILLE',
        RegAddrState: 'AR',
        RegAddrZip: 72703,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '2656 N WAKEFIELD PL',
        RegAddrCity: 'FAYETTEVILLE',
        RegAddrState: 'AR',
        RegAddrZip: 72703,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1717 N WILLOWBROOK DR',
        RegAddrCity: 'FAYETTEVILLE',
        RegAddrState: 'AR',
        RegAddrZip: 72703,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '2640 N VILLA BLVD',
        RegAddrCity: 'FAYETTEVILLE',
        RegAddrState: 'AR',
        RegAddrZip: 72703,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '110 E MEMORIAL DR',
        RegAddrCity: 'FAYETTEVILLE',
        RegAddrState: 'AR',
        RegAddrZip: 72703,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '2182 N HIDDEN CREEK DR',
        RegAddrCity: 'FAYETTEVILLE',
        RegAddrState: 'AR',
        RegAddrZip: 72704,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '6641 W OWL NEST DR',
        RegAddrCity: 'FAYETTEVILLE',
        RegAddrState: 'AR',
        RegAddrZip: 72704,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '2767 W VANIKE DR',
        RegAddrCity: 'FAYETTEVILLE',
        RegAddrState: 'AR',
        RegAddrZip: 72704,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '5156 CLEAR CREEK BLVD',
        RegAddrCity: 'FAYETTEVILLE',
        RegAddrState: 'AR',
        RegAddrZip: 72704,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '74 MARION COUNTY 8072',
        RegAddrCity: 'FLIPPIN',
        RegAddrState: 'AR',
        RegAddrZip: 72634,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '72 MARION COUNTY 7012',
        RegAddrCity: 'FLIPPIN',
        RegAddrState: 'AR',
        RegAddrZip: 72634,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '206 MARION COUNTY 7001',
        RegAddrCity: 'FLIPPIN',
        RegAddrState: 'AR',
        RegAddrZip: 72634,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1005 N 18TH ST',
        RegAddrCity: 'FORT SMITH',
        RegAddrState: 'AR',
        RegAddrZip: 72901,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '9815 E POINTE DR',
        RegAddrCity: 'FORT SMITH',
        RegAddrState: 'AR',
        RegAddrZip: 72903,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1218 ELIZABETH LN',
        RegAddrCity: 'FORT SMITH',
        RegAddrState: 'AR',
        RegAddrZip: 72903,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '2001 COBBLE HILL LN',
        RegAddrCity: 'FORT SMITH',
        RegAddrState: 'AR',
        RegAddrZip: 72903,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '4509 CLARENDON AVE',
        RegAddrCity: 'FORT SMITH',
        RegAddrState: 'AR',
        RegAddrZip: 72904,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '4512 YORKSHIRE DR',
        RegAddrCity: 'FORT SMITH',
        RegAddrState: 'AR',
        RegAddrZip: 72904,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1705 N 31ST ST',
        RegAddrCity: 'FORT SMITH',
        RegAddrState: 'AR',
        RegAddrZip: 72904,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1305 WILLOWBROOK CIR',
        RegAddrCity: 'FORT SMITH',
        RegAddrState: 'AR',
        RegAddrZip: 72908,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '6821 S 10TH ST',
        RegAddrCity: 'FORT SMITH',
        RegAddrState: 'AR',
        RegAddrZip: 72908,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '2808 HEATHER OAKS WAY',
        RegAddrCity: 'FORT SMITH',
        RegAddrState: 'AR',
        RegAddrZip: 72908,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '9305 BRIDGEFORD CIR',
        RegAddrCity: 'FORT SMITH',
        RegAddrState: 'AR',
        RegAddrZip: 72908,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '8801 VICKERY LN',
        RegAddrCity: 'FORT SMITH',
        RegAddrState: 'AR',
        RegAddrZip: 72908,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '500 BORDEAUX CIR',
        RegAddrCity: 'FORT SMITH',
        RegAddrState: 'AR',
        RegAddrZip: 72908,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '8201 HERMITAGE DR',
        RegAddrCity: 'FORT SMITH',
        RegAddrState: 'AR',
        RegAddrZip: 72908,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '16928 DUTCH LN',
        RegAddrCity: 'FORT SMITH',
        RegAddrState: 'AR',
        RegAddrZip: 72916,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '12707 LIMESTONE DR',
        RegAddrCity: 'FORT SMITH',
        RegAddrState: 'AR',
        RegAddrZip: 72916,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '12308 MITCHELL BND',
        RegAddrCity: 'FORT SMITH',
        RegAddrState: 'AR',
        RegAddrZip: 72916,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '11206 NORTHFIELD CT',
        RegAddrCity: 'FORT SMITH',
        RegAddrState: 'AR',
        RegAddrZip: 72916,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '18190 MARSHALL ST',
        RegAddrCity: 'GARFIELD',
        RegAddrState: 'AR',
        RegAddrZip: 72732,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '205 E 10TH ST',
        RegAddrCity: 'GREEN FOREST',
        RegAddrState: 'AR',
        RegAddrZip: 72638,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1177 FORREST PARK WAY',
        RegAddrCity: 'GREENWOOD',
        RegAddrState: 'AR',
        RegAddrZip: 72936,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '121 E LINCOLN ST',
        RegAddrCity: 'GREENWOOD',
        RegAddrState: 'AR',
        RegAddrZip: 72936,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '3951 ANTLER DR',
        RegAddrCity: 'GREENWOOD',
        RegAddrState: 'AR',
        RegAddrZip: 72936,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '3804 MOUNT ZION RD',
        RegAddrCity: 'GREENWOOD',
        RegAddrState: 'AR',
        RegAddrZip: 72936,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1552 WOLF SPRINGS RD',
        RegAddrCity: 'HARRISON',
        RegAddrState: 'AR',
        RegAddrZip: 72601,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '6653 CASHEW LN',
        RegAddrCity: 'HARRISON',
        RegAddrState: 'AR',
        RegAddrZip: 72601,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '920 QUAIL AVE',
        RegAddrCity: 'HARRISON',
        RegAddrState: 'AR',
        RegAddrZip: 72601,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '10305 SR 27',
        RegAddrCity: 'HECTOR',
        RegAddrState: 'AR',
        RegAddrZip: 72843,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '48 HICKORY LN',
        RegAddrCity: 'HOLIDAY ISLE',
        RegAddrState: 'AR',
        RegAddrZip: 72631,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '5865 E HIGHWAY 14',
        RegAddrCity: 'LEAD HILL',
        RegAddrState: 'AR',
        RegAddrZip: 72644,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '3354 E BLACK RANCH RD',
        RegAddrCity: 'LEAD HILL',
        RegAddrState: 'AR',
        RegAddrZip: 72644,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '10235 MORTENSEN RD',
        RegAddrCity: 'LINCOLN',
        RegAddrState: 'AR',
        RegAddrZip: 72744,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '254 HILL BLVD',
        RegAddrCity: 'LONDON',
        RegAddrState: 'AR',
        RegAddrZip: 72847,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1305 VINEYARD ST',
        RegAddrCity: 'PEA RIDGE',
        RegAddrState: 'AR',
        RegAddrZip: 72751,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '212 REDMOND RD',
        RegAddrCity: 'POTTSVILLE',
        RegAddrState: 'AR',
        RegAddrZip: 72858,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1980 VINEY GROVE RD',
        RegAddrCity: 'PRAIRIE GROVE',
        RegAddrState: 'AR',
        RegAddrZip: 72753,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1007 N 34TH ST',
        RegAddrCity: 'ROGERS',
        RegAddrState: 'AR',
        RegAddrZip: 72756,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '8125 SHERREY LN',
        RegAddrCity: 'ROGERS',
        RegAddrState: 'AR',
        RegAddrZip: 72756,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '300 CAMELOT',
        RegAddrCity: 'ROGERS',
        RegAddrState: 'AR',
        RegAddrZip: 72756,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '17533 HILLTOP HTS',
        RegAddrCity: 'ROGERS',
        RegAddrState: 'AR',
        RegAddrZip: 72756,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '13122 N OLD WIRE RD',
        RegAddrCity: 'ROGERS',
        RegAddrState: 'AR',
        RegAddrZip: 72756,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1204 N 35TH ST',
        RegAddrCity: 'ROGERS',
        RegAddrState: 'AR',
        RegAddrZip: 72756,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '4720 N 2ND ST',
        RegAddrCity: 'ROGERS',
        RegAddrState: 'AR',
        RegAddrZip: 72756,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '2404 S 12TH ST',
        RegAddrCity: 'ROGERS',
        RegAddrState: 'AR',
        RegAddrZip: 72758,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '2809 W NEW HOPE RD',
        RegAddrCity: 'ROGERS',
        RegAddrState: 'AR',
        RegAddrZip: 72758,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '6208 S 49TH ST',
        RegAddrCity: 'ROGERS',
        RegAddrState: 'AR',
        RegAddrZip: 72758,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1000 S 27TH ST',
        RegAddrCity: 'ROGERS',
        RegAddrState: 'AR',
        RegAddrZip: 72758,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1513 S DIXIELAND RD',
        RegAddrCity: 'ROGERS',
        RegAddrState: 'AR',
        RegAddrZip: 72758,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '2401 S 11TH PL',
        RegAddrCity: 'ROGERS',
        RegAddrState: 'AR',
        RegAddrZip: 72758,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1901 S STONE MNR',
        RegAddrCity: 'ROGERS',
        RegAddrState: 'AR',
        RegAddrZip: 72758,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '407 AUSTIN CIR',
        RegAddrCity: 'RUSSELLVILLE',
        RegAddrState: 'AR',
        RegAddrZip: 72801,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '513 W 6TH ST',
        RegAddrCity: 'RUSSELLVILLE',
        RegAddrState: 'AR',
        RegAddrZip: 72801,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '612 SKYLINE VISTA LN',
        RegAddrCity: 'RUSSELLVILLE',
        RegAddrState: 'AR',
        RegAddrZip: 72802,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '2000 W UNIVERSITY ST',
        RegAddrCity: 'SILOAM SPGS',
        RegAddrState: 'AR',
        RegAddrZip: 72761,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '3306 SCOTT LN',
        RegAddrCity: 'SPRINGDALE',
        RegAddrState: 'AR',
        RegAddrZip: 72762,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '4162 TAHOE CIRCLE DR',
        RegAddrCity: 'SPRINGDALE',
        RegAddrState: 'AR',
        RegAddrZip: 72762,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '592 LOCUST ST',
        RegAddrCity: 'SPRINGDALE',
        RegAddrState: 'AR',
        RegAddrZip: 72762,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '961 BRUSH CREEK RD',
        RegAddrCity: 'SPRINGDALE',
        RegAddrState: 'AR',
        RegAddrZip: 72762,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '2009 W HENRI DE TONTI BLVD',
        RegAddrCity: 'SPRINGDALE',
        RegAddrState: 'AR',
        RegAddrZip: 72762,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '324 HART AVE',
        RegAddrCity: 'SPRINGDALE',
        RegAddrState: 'AR',
        RegAddrZip: 72764,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '22566 ABBEYTOWN RD',
        RegAddrCity: 'SPRINGDALE',
        RegAddrState: 'AR',
        RegAddrZip: 72764,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '2103 TASMAN AVE',
        RegAddrCity: 'SPRINGDALE',
        RegAddrState: 'AR',
        RegAddrZip: 72764,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1737 RED OAK CIR',
        RegAddrCity: 'VAN BUREN',
        RegAddrState: 'AR',
        RegAddrZip: 72956,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1010 WHITE OAK EST',
        RegAddrCity: 'VAN BUREN',
        RegAddrState: 'AR',
        RegAddrZip: 72956,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '335 CRYSTAL VW',
        RegAddrCity: 'VAN BUREN',
        RegAddrState: 'AR',
        RegAddrZip: 72956,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '3511 MARGRET DR',
        RegAddrCity: 'VAN BUREN',
        RegAddrState: 'AR',
        RegAddrZip: 72956,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1113 BRECKENRIDGE DR',
        RegAddrCity: 'VAN BUREN',
        RegAddrState: 'AR',
        RegAddrZip: 72956,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '118 SHIBLEY RD',
        RegAddrCity: 'VAN BUREN',
        RegAddrState: 'AR',
        RegAddrZip: 72956,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '16967 W FARM RD',
        RegAddrCity: 'WEST FORK',
        RegAddrState: 'AR',
        RegAddrZip: 72774,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '225 GARFIELD AVE',
        RegAddrCity: 'WEST FORK',
        RegAddrState: 'AR',
        RegAddrZip: 72774,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '14253 LOW GAP RD',
        RegAddrCity: 'WEST FORK',
        RegAddrState: 'AR',
        RegAddrZip: 72774,
        CongressionalDistrict: 3,
      },
    ],
  },
  {
    name: 'AR-4',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/AR-4?ref=gh-pages',
    state: 'AR',
    state_and_number: 'AR-4',
    zip: 71601,
    congressionalDistrict: 4,
    addressesInDistrict: [
      {
        RegAddrLine1: '6816 BELMONT DR',
        RegAddrCity: 'ALMA',
        RegAddrState: 'AR',
        RegAddrZip: 72921,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '314 HOGAN ST',
        RegAddrCity: 'ALTUS',
        RegAddrState: 'AR',
        RegAddrZip: 72821,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1320 CADDO ST',
        RegAddrCity: 'ARKADELPHIA',
        RegAddrState: 'AR',
        RegAddrZip: 71923,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1305 CUTLER ST',
        RegAddrCity: 'ARKADELPHIA',
        RegAddrState: 'AR',
        RegAddrZip: 71923,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '996 HIGHWAY 32 W',
        RegAddrCity: 'ASHDOWN',
        RegAddrState: 'AR',
        RegAddrZip: 71822,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '308 E CENTRAL AVE',
        RegAddrCity: 'BANKS',
        RegAddrState: 'AR',
        RegAddrZip: 71631,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1385 HIGHWAY 9',
        RegAddrCity: 'BEARDEN',
        RegAddrState: 'AR',
        RegAddrZip: 71720,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1258 OUACHITA ROAD 83',
        RegAddrCity: 'BEARDEN',
        RegAddrState: 'AR',
        RegAddrZip: 71720,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '549 DANIEL DR',
        RegAddrCity: 'BISMARCK',
        RegAddrState: 'AR',
        RegAddrZip: 71929,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '5245 N STATE HIGHWAY 23',
        RegAddrCity: 'BOONEVILLE',
        RegAddrState: 'AR',
        RegAddrZip: 72927,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '8144 BARBER RD',
        RegAddrCity: 'BOONEVILLE',
        RegAddrState: 'AR',
        RegAddrZip: 72927,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '26 LAFAYETTE 194',
        RegAddrCity: 'BRADLEY',
        RegAddrState: 'AR',
        RegAddrZip: 71826,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '120 W 7TH ST',
        RegAddrCity: 'BRADLEY',
        RegAddrState: 'AR',
        RegAddrZip: 71826,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '302 E WARD',
        RegAddrCity: 'CALION',
        RegAddrState: 'AR',
        RegAddrZip: 71724,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '841 HIGHWAY 278 E',
        RegAddrCity: 'CAMDEN',
        RegAddrState: 'AR',
        RegAddrZip: 71701,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '431 SHORT AVE',
        RegAddrCity: 'CAMDEN',
        RegAddrState: 'AR',
        RegAddrZip: 71701,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '507 PIERCE ST',
        RegAddrCity: 'CAMDEN',
        RegAddrState: 'AR',
        RegAddrZip: 71701,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2542 WILLIAMS AVE',
        RegAddrCity: 'CAMDEN',
        RegAddrState: 'AR',
        RegAddrZip: 71701,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1010 HIGHWAY 76',
        RegAddrCity: 'CHIDESTER',
        RegAddrState: 'AR',
        RegAddrZip: 71726,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '105 WOODSTOCK LN',
        RegAddrCity: 'CLARKSVILLE',
        RegAddrState: 'AR',
        RegAddrZip: 72830,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '403 OAK ST',
        RegAddrCity: 'CLARKSVILLE',
        RegAddrState: 'AR',
        RegAddrZip: 72830,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1097 COUNTY ROAD 2290',
        RegAddrCity: 'CLARKSVILLE',
        RegAddrState: 'AR',
        RegAddrZip: 72830,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '5190 HIGHWAY 71 S',
        RegAddrCity: 'COVE',
        RegAddrState: 'AR',
        RegAddrZip: 71937,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1304 BOULDER',
        RegAddrCity: 'DANVILLE',
        RegAddrState: 'AR',
        RegAddrZip: 72833,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '204 MAPLE ST',
        RegAddrCity: 'DARDANELLE',
        RegAddrState: 'AR',
        RegAddrZip: 72834,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '302 W ALTENA AVE',
        RegAddrCity: 'DE QUEEN',
        RegAddrState: 'AR',
        RegAddrZip: 71832,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '140 NORTHGATE DR',
        RegAddrCity: 'DE QUEEN',
        RegAddrState: 'AR',
        RegAddrZip: 71832,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1701 W ELBERTA AVE',
        RegAddrCity: 'DE QUEEN',
        RegAddrState: 'AR',
        RegAddrZip: 71832,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1058 OLD HIGHWAY 70 W',
        RegAddrCity: 'DIERKS',
        RegAddrState: 'AR',
        RegAddrZip: 71833,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '3600 MILLER COUNTY 3',
        RegAddrCity: 'DODDRIDGE',
        RegAddrState: 'AR',
        RegAddrZip: 71834,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '150 MILLER COUNTY 413',
        RegAddrCity: 'DODDRIDGE',
        RegAddrState: 'AR',
        RegAddrZip: 71834,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '175 UNION LN',
        RegAddrCity: 'EL DORADO',
        RegAddrState: 'AR',
        RegAddrZip: 71730,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '5697 JUNCTION CITY HWY',
        RegAddrCity: 'EL DORADO',
        RegAddrState: 'AR',
        RegAddrZip: 71730,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '303 N WALNUT ST',
        RegAddrCity: 'EMMET',
        RegAddrState: 'AR',
        RegAddrZip: 71835,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '5108 MARION DR',
        RegAddrCity: 'ENGLAND',
        RegAddrState: 'AR',
        RegAddrZip: 72046,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '704 W 2ND ST',
        RegAddrCity: 'FORDYCE',
        RegAddrState: 'AR',
        RegAddrZip: 71742,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '206 MILLER COUNTY 24',
        RegAddrCity: 'FOUKE',
        RegAddrState: 'AR',
        RegAddrZip: 71837,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '9000 HIGHWAY 71 S',
        RegAddrCity: 'GILLHAM',
        RegAddrState: 'AR',
        RegAddrZip: 71841,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '220 N BUMBLE BEE RD',
        RegAddrCity: 'GLENWOOD',
        RegAddrState: 'AR',
        RegAddrZip: 71943,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '510 MORRISON ST',
        RegAddrCity: 'HAMBURG',
        RegAddrState: 'AR',
        RegAddrZip: 71646,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '706 E SAVAGE ST',
        RegAddrCity: 'HAMBURG',
        RegAddrState: 'AR',
        RegAddrZip: 71646,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '209 S BARLETT ST',
        RegAddrCity: 'HAMBURG',
        RegAddrState: 'AR',
        RegAddrZip: 71646,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '707 W 19TH ST',
        RegAddrCity: 'HOPE',
        RegAddrState: 'AR',
        RegAddrZip: 71801,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '114 SILVERLEAF ST',
        RegAddrCity: 'HOT SPRINGS',
        RegAddrState: 'AR',
        RegAddrZip: 71901,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2007 SPRING ST',
        RegAddrCity: 'HOT SPRINGS',
        RegAddrState: 'AR',
        RegAddrZip: 71901,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '121 TRIVISTA RIGHT ST',
        RegAddrCity: 'HOT SPRINGS',
        RegAddrState: 'AR',
        RegAddrZip: 71901,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '14 BADALONA CIR',
        RegAddrCity: 'HOT SPRINGS',
        RegAddrState: 'AR',
        RegAddrZip: 71909,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '28 LA GRANJA CIR',
        RegAddrCity: 'HOT SPRINGS',
        RegAddrState: 'AR',
        RegAddrZip: 71909,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '5 LA CANADA LN',
        RegAddrCity: 'HOT SPRINGS',
        RegAddrState: 'AR',
        RegAddrZip: 71909,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '117 BRANDT PT',
        RegAddrCity: 'HOT SPRINGS',
        RegAddrState: 'AR',
        RegAddrZip: 71913,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '124 CORPORATE TER',
        RegAddrCity: 'HOT SPRINGS',
        RegAddrState: 'AR',
        RegAddrZip: 71913,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '230 CANDLEWOOD LN',
        RegAddrCity: 'HOT SPRINGS',
        RegAddrState: 'AR',
        RegAddrZip: 71913,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '104 CESSNA CIR',
        RegAddrCity: 'HOT SPRINGS',
        RegAddrState: 'AR',
        RegAddrZip: 71913,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '135 KNOLLWOOD HEIGHTS CT',
        RegAddrCity: 'HOT SPRINGS',
        RegAddrState: 'AR',
        RegAddrZip: 71913,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '214 COACHLIGHT RD',
        RegAddrCity: 'HOT SPRINGS',
        RegAddrState: 'AR',
        RegAddrZip: 71913,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '447 BULL BAYOU RD',
        RegAddrCity: 'HOT SPRINGS',
        RegAddrState: 'AR',
        RegAddrZip: 71913,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '905 SAVALL CIR',
        RegAddrCity: 'JESSIEVILLE',
        RegAddrState: 'AR',
        RegAddrZip: 71949,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '122 HARDING LN',
        RegAddrCity: 'JESSIEVILLE',
        RegAddrState: 'AR',
        RegAddrZip: 71949,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '5700 SHAVER RD',
        RegAddrCity: 'LAVACA',
        RegAddrState: 'AR',
        RegAddrZip: 72941,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '231 N MURPHY ST',
        RegAddrCity: 'LEWISVILLE',
        RegAddrState: 'AR',
        RegAddrZip: 71845,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '3291 COL 15',
        RegAddrCity: 'MAGNOLIA',
        RegAddrState: 'AR',
        RegAddrZip: 71753,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '335 OAKLAWN ST',
        RegAddrCity: 'MALVERN',
        RegAddrState: 'AR',
        RegAddrZip: 72104,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '24111 SULPHUR SPRINGS RD',
        RegAddrCity: 'MALVERN',
        RegAddrState: 'AR',
        RegAddrZip: 72104,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '926 WALLACE ST',
        RegAddrCity: 'MALVERN',
        RegAddrState: 'AR',
        RegAddrZip: 72104,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1058 COOPER RD',
        RegAddrCity: 'MALVERN',
        RegAddrState: 'AR',
        RegAddrZip: 72104,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '313 RIVEROAKS RD',
        RegAddrCity: 'MALVERN',
        RegAddrState: 'AR',
        RegAddrZip: 72104,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '194 HEMPSTEAD 284',
        RegAddrCity: 'MC CASKILL',
        RegAddrState: 'AR',
        RegAddrZip: 71847,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '148 POLK ROAD 135',
        RegAddrCity: 'MENA',
        RegAddrState: 'AR',
        RegAddrZip: 71953,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1900 SMITH AVE',
        RegAddrCity: 'MENA',
        RegAddrState: 'AR',
        RegAddrZip: 71953,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '135 PINECREST CIR',
        RegAddrCity: 'MONTICELLO',
        RegAddrState: 'AR',
        RegAddrZip: 71655,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1205 N MAIN ST',
        RegAddrCity: 'MONTICELLO',
        RegAddrState: 'AR',
        RegAddrZip: 71655,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '168 CENTER DR',
        RegAddrCity: 'MONTICELLO',
        RegAddrState: 'AR',
        RegAddrZip: 71655,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '243 ASHLEY ROAD 58',
        RegAddrCity: 'MONTROSE',
        RegAddrState: 'AR',
        RegAddrZip: 71658,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '113 SEVIER AVE',
        RegAddrCity: 'MOUNTAIN PINE',
        RegAddrState: 'AR',
        RegAddrZip: 71956,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '15216 FORD RIDGE RD',
        RegAddrCity: 'MULBERRY',
        RegAddrState: 'AR',
        RegAddrZip: 72947,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '153 JONES RD',
        RegAddrCity: 'NASHVILLE',
        RegAddrState: 'AR',
        RegAddrZip: 71852,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '462 HARDING RD',
        RegAddrCity: 'NASHVILLE',
        RegAddrState: 'AR',
        RegAddrZip: 71852,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2197 HIGHWAY 371 W',
        RegAddrCity: 'NASHVILLE',
        RegAddrState: 'AR',
        RegAddrZip: 71852,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '302 SPRING VALLEY RD',
        RegAddrCity: 'NEWHOPE',
        RegAddrState: 'AR',
        RegAddrZip: 71959,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '402 E HILL ST',
        RegAddrCity: 'OLA',
        RegAddrState: 'AR',
        RegAddrZip: 72853,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1339 W HIGHWAY 64',
        RegAddrCity: 'OZARK',
        RegAddrState: 'AR',
        RegAddrZip: 72949,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2100 JOHNSON ST',
        RegAddrCity: 'OZARK',
        RegAddrState: 'AR',
        RegAddrZip: 72949,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '111 COOPER DR',
        RegAddrCity: 'PARIS',
        RegAddrState: 'AR',
        RegAddrZip: 72855,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2198 N 5TH ST',
        RegAddrCity: 'PARIS',
        RegAddrState: 'AR',
        RegAddrZip: 72855,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '',
        RegAddrCity: 'Parthenon',
        RegAddrState: 'AR',
        RegAddrZip: 72666,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1701 L A PREXY DAVIS DR',
        RegAddrCity: 'PINE BLUFF',
        RegAddrState: 'AR',
        RegAddrZip: 71601,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '201 N IDAHO ST',
        RegAddrCity: 'PINE BLUFF',
        RegAddrState: 'AR',
        RegAddrZip: 71601,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '3517 W 2ND SHT AVE',
        RegAddrCity: 'PINE BLUFF',
        RegAddrState: 'AR',
        RegAddrZip: 71601,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '413 N MYRTLE ST',
        RegAddrCity: 'PINE BLUFF',
        RegAddrState: 'AR',
        RegAddrZip: 71601,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '4921 W 3RD AVE',
        RegAddrCity: 'PINE BLUFF',
        RegAddrState: 'AR',
        RegAddrZip: 71602,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '4206 W 5TH AVE',
        RegAddrCity: 'PINE BLUFF',
        RegAddrState: 'AR',
        RegAddrZip: 71602,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '8414 VICTORIA DR',
        RegAddrCity: 'PINE BLUFF',
        RegAddrState: 'AR',
        RegAddrZip: 71603,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '6400 TRINITY DR',
        RegAddrCity: 'PINE BLUFF',
        RegAddrState: 'AR',
        RegAddrZip: 71603,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '7615 CROSS RD',
        RegAddrCity: 'PINE BLUFF',
        RegAddrState: 'AR',
        RegAddrZip: 71603,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '6207 TIMBER LAKE DR',
        RegAddrCity: 'PINE BLUFF',
        RegAddrState: 'AR',
        RegAddrZip: 71603,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '303 W 37TH AVE',
        RegAddrCity: 'PINE BLUFF',
        RegAddrState: 'AR',
        RegAddrZip: 71603,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1658 US HIGHWAY 371',
        RegAddrCity: 'PRESCOTT',
        RegAddrState: 'AR',
        RegAddrZip: 71857,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2646 NEVADA 23',
        RegAddrCity: 'PRESCOTT',
        RegAddrState: 'AR',
        RegAddrZip: 71857,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1200 MOUNT ELBA E',
        RegAddrCity: 'RISON',
        RegAddrState: 'AR',
        RegAddrZip: 71665,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '587 RAGWEED VALLEY RD',
        RegAddrCity: 'ROYAL',
        RegAddrState: 'AR',
        RegAddrZip: 71968,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '205 HUGHES RD',
        RegAddrCity: 'SCRANTON',
        RegAddrState: 'AR',
        RegAddrZip: 72863,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '401 E CENTER ST',
        RegAddrCity: 'SHERIDAN',
        RegAddrState: 'AR',
        RegAddrZip: 72150,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '142 KATHY LN',
        RegAddrCity: 'SHERIDAN',
        RegAddrState: 'AR',
        RegAddrZip: 72150,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1000 PERSHING HWY',
        RegAddrCity: 'SMACKOVER',
        RegAddrState: 'AR',
        RegAddrZip: 71762,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2054 AURELLE RD',
        RegAddrCity: 'STRONG',
        RegAddrState: 'AR',
        RegAddrZip: 71765,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1501 JEFFERSON AVE',
        RegAddrCity: 'TEXARKANA',
        RegAddrState: 'AR',
        RegAddrZip: 71854,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '45 BROADMOOR DR',
        RegAddrCity: 'TEXARKANA',
        RegAddrState: 'AR',
        RegAddrZip: 71854,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1305 E 40TH ST',
        RegAddrCity: 'TEXARKANA',
        RegAddrState: 'AR',
        RegAddrZip: 71854,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2019 TRASKWOOD RD',
        RegAddrCity: 'TRASKWOOD',
        RegAddrState: 'AR',
        RegAddrZip: 72167,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '108 N SKINNER',
        RegAddrCity: 'WALDO',
        RegAddrState: 'AR',
        RegAddrZip: 71770,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '130 TIMBER LN',
        RegAddrCity: 'WHITE HALL',
        RegAddrState: 'AR',
        RegAddrZip: 71602,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '165 UP RIVER RD',
        RegAddrCity: 'WINTHROP',
        RegAddrState: 'AR',
        RegAddrZip: 71866,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '3000 HENSLEY LAKE RD',
        RegAddrCity: 'WRIGHT',
        RegAddrState: 'AR',
        RegAddrZip: 72182,
        CongressionalDistrict: 4,
      },
    ],
  },
  {
    name: 'AS-0',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/AS-0?ref=gh-pages',
    state: 'AS',
    state_and_number: 'AS-0',
    congressionalDistrict: 0,
    addressesInDistrict: [],
  },
  {
    name: 'AZ-1',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/AZ-1?ref=gh-pages',
    state: 'AZ',
    state_and_number: 'AZ-1',
    zip: 84531,
    congressionalDistrict: 1,
    addressesInDistrict: [
      {
        RegAddrLine1: '2221 W CAMERON BLVD',
        RegAddrCity: 'COOLIDGE',
        RegAddrState: 'AZ',
        RegAddrZip: 85128,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '4835 W MOHAWK DR',
        RegAddrCity: 'ELOY',
        RegAddrState: 'AZ',
        RegAddrZip: 85131,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '3836 N PARADISE RD',
        RegAddrCity: 'FLAGSTAFF',
        RegAddrState: 'AZ',
        RegAddrZip: 86004,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '6150 E LAST CHANCE RD',
        RegAddrCity: 'FLAGSTAFF',
        RegAddrState: 'AZ',
        RegAddrZip: 86004,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '596 W CATTLE DRIVE TRL',
        RegAddrCity: 'FLAGSTAFF',
        RegAddrState: 'AZ',
        RegAddrZip: 86005,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: 'PO BOX 0',
        RegAddrCity: 'FLAGSTAFF',
        RegAddrState: 'AZ',
        RegAddrZip: 86011,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '5 0M N FT',
        RegAddrCity: 'FT DEFIANCE',
        RegAddrState: 'AZ',
        RegAddrZip: 86504,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '3 50M N FT',
        RegAddrCity: 'FT DEFIANCE',
        RegAddrState: 'AZ',
        RegAddrZip: 86504,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '4 PIGEON SPGS',
        RegAddrCity: 'FT DEFIANCE',
        RegAddrState: 'AZ',
        RegAddrZip: 86504,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '0.25M W TOTSOH TRADING POST',
        RegAddrCity: 'LUKACHUKAI',
        RegAddrState: 'AZ',
        RegAddrZip: 86507,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '523 W HIGHLANDS VIEW PL',
        RegAddrCity: 'ORO VALLEY',
        RegAddrState: 'AZ',
        RegAddrZip: 85755,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1078 E WHITE MOUNTAIN BLVD',
        RegAddrCity: 'PINETOP',
        RegAddrState: 'AZ',
        RegAddrZip: 85935,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '141 BOWSTRING DR',
        RegAddrCity: 'SEDONA',
        RegAddrState: 'AZ',
        RegAddrZip: 86336,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '0 PRC31GRP1',
        RegAddrCity: 'SHONTO',
        RegAddrState: 'AZ',
        RegAddrZip: 86054,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '9511 N STONEBROOK DR',
        RegAddrCity: 'TUCSON',
        RegAddrState: 'AZ',
        RegAddrZip: 85743,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '9368 N SUNFLOWER BLOSSOM PL',
        RegAddrCity: 'TUCSON',
        RegAddrState: 'AZ',
        RegAddrZip: 85743,
        CongressionalDistrict: 1,
      },
    ],
  },
  {
    name: 'AZ-2',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/AZ-2?ref=gh-pages',
    state: 'AZ',
    state_and_number: 'AZ-2',
    zip: 85602,
    congressionalDistrict: 2,
    addressesInDistrict: [
      {
        RegAddrLine1: '888 S LA MESA CIR',
        RegAddrCity: 'BENSON',
        RegAddrState: 'AZ',
        RegAddrZip: 85602,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '0 SIG CORP',
        RegAddrCity: 'FT HUACHUCA',
        RegAddrState: 'AZ',
        RegAddrZip: 85613,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '6655 S JAXEL RD',
        RegAddrCity: 'HEREFORD',
        RegAddrState: 'AZ',
        RegAddrZip: 85615,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1473 PASO ROBLES AVE',
        RegAddrCity: 'SIERRA VISTA',
        RegAddrState: 'AZ',
        RegAddrZip: 85635,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2740 MONTARO DR',
        RegAddrCity: 'SIERRA VISTA',
        RegAddrState: 'AZ',
        RegAddrZip: 85650,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '459 E YVON DR',
        RegAddrCity: 'TUCSON',
        RegAddrState: 'AZ',
        RegAddrZip: 85704,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2564 N GERONIMO AVE',
        RegAddrCity: 'TUCSON',
        RegAddrState: 'AZ',
        RegAddrZip: 85705,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '434 N MEDFORD DR',
        RegAddrCity: 'TUCSON',
        RegAddrState: 'AZ',
        RegAddrZip: 85710,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '5226 E 25TH ST',
        RegAddrCity: 'TUCSON',
        RegAddrState: 'AZ',
        RegAddrZip: 85711,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '5542 E COPPER ST',
        RegAddrCity: 'TUCSON',
        RegAddrState: 'AZ',
        RegAddrZip: 85712,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '3337 N JACKSON AVE',
        RegAddrCity: 'TUCSON',
        RegAddrState: 'AZ',
        RegAddrZip: 85719,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '7256 E STELLA RD',
        RegAddrCity: 'TUCSON',
        RegAddrState: 'AZ',
        RegAddrZip: 85730,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '11105 E PANTANO TRL',
        RegAddrCity: 'TUCSON',
        RegAddrState: 'AZ',
        RegAddrZip: 85730,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '3887 W ORION ST',
        RegAddrCity: 'TUCSON',
        RegAddrState: 'AZ',
        RegAddrZip: 85742,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '9012 N LATTIMORE LN',
        RegAddrCity: 'TUCSON',
        RegAddrState: 'AZ',
        RegAddrZip: 85742,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '6301 E CALLE DE MIRAR',
        RegAddrCity: 'TUCSON',
        RegAddrState: 'AZ',
        RegAddrZip: 85750,
        CongressionalDistrict: 2,
      },
    ],
  },
  {
    name: 'AZ-3',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/AZ-3?ref=gh-pages',
    state: 'AZ',
    state_and_number: 'AZ-3',
    zip: 85033,
    congressionalDistrict: 3,
    addressesInDistrict: [
      {
        RegAddrLine1: '511 W CHOLLA AVE',
        RegAddrCity: 'AJO',
        RegAddrState: 'AZ',
        RegAddrZip: 85321,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '11366 W LINCOLN ST',
        RegAddrCity: 'AVONDALE',
        RegAddrState: 'AZ',
        RegAddrZip: 85323,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '10529 W ROANOKE AVE',
        RegAddrCity: 'AVONDALE',
        RegAddrState: 'AZ',
        RegAddrZip: 85392,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '3206 N 138TH DR',
        RegAddrCity: 'AVONDALE',
        RegAddrState: 'AZ',
        RegAddrZip: 85392,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '19821 W SHERMAN ST',
        RegAddrCity: 'BUCKEYE',
        RegAddrState: 'AZ',
        RegAddrZip: 85326,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '10933 W COLLEGE DR',
        RegAddrCity: 'PHOENIX',
        RegAddrState: 'AZ',
        RegAddrZip: 85037,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '108 STODDARD WAY',
        RegAddrCity: 'RIO RICO',
        RegAddrState: 'AZ',
        RegAddrZip: 85648,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '731 E BILBY RD',
        RegAddrCity: 'TUCSON',
        RegAddrState: 'AZ',
        RegAddrZip: 85706,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '5141 W SALERNO DR',
        RegAddrCity: 'TUCSON',
        RegAddrState: 'AZ',
        RegAddrZip: 85745,
        CongressionalDistrict: 3,
      },
    ],
  },
  {
    name: 'AZ-4',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/AZ-4?ref=gh-pages',
    state: 'AZ',
    state_and_number: 'AZ-4',
    zip: 85087,
    congressionalDistrict: 4,
    addressesInDistrict: [
      {
        RegAddrLine1: '1521 S IDAHO RD',
        RegAddrCity: 'APACHE JCT',
        RegAddrState: 'AZ',
        RegAddrZip: 85119,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '27106 W TONOPAH DR',
        RegAddrCity: 'BUCKEYE',
        RegAddrState: 'AZ',
        RegAddrZip: 85396,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '848 ROADRUNNER DR',
        RegAddrCity: 'BULLHEAD CITY',
        RegAddrState: 'AZ',
        RegAddrZip: 86442,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1410 E ROAD 3 S',
        RegAddrCity: 'CHINO VALLEY',
        RegAddrState: 'AZ',
        RegAddrZip: 86323,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '802 E CINNABAR DR',
        RegAddrCity: 'COTTONWOOD',
        RegAddrState: 'AZ',
        RegAddrZip: 86326,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '10719 E LUPINE LN',
        RegAddrCity: 'FLORENCE',
        RegAddrState: 'AZ',
        RegAddrZip: 85132,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2970 E DEVLIN AVE',
        RegAddrCity: 'KINGMAN',
        RegAddrState: 'AZ',
        RegAddrZip: 86409,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '8130 N IRON WINDS LN',
        RegAddrCity: 'KINGMAN',
        RegAddrState: 'AZ',
        RegAddrZip: 86409,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '31803 CROWS NEST DR',
        RegAddrCity: 'PARKER',
        RegAddrState: 'AZ',
        RegAddrZip: 85344,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '4448 N PLAINSMAN WAY',
        RegAddrCity: 'PRESCOTT VLY',
        RegAddrState: 'AZ',
        RegAddrZip: 86314,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '4596 W WHITE CANYON RD',
        RegAddrCity: 'QUEEN CREEK',
        RegAddrState: 'AZ',
        RegAddrZip: 85142,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '4108 E MICA RD',
        RegAddrCity: 'QUEEN CREEK',
        RegAddrState: 'AZ',
        RegAddrZip: 85143,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '5340 PINAL PL',
        RegAddrCity: 'TOPOCK',
        RegAddrState: 'AZ',
        RegAddrZip: 86436,
        CongressionalDistrict: 4,
      },
    ],
  },
  {
    name: 'AZ-5',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/AZ-5?ref=gh-pages',
    state: 'AZ',
    state_and_number: 'AZ-5',
    zip: 85201,
    congressionalDistrict: 5,
    addressesInDistrict: [
      {
        RegAddrLine1: '461 N DANIELSON WAY',
        RegAddrCity: 'CHANDLER',
        RegAddrState: 'AZ',
        RegAddrZip: 85225,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '925 E PRESCOTT PL',
        RegAddrCity: 'CHANDLER',
        RegAddrState: 'AZ',
        RegAddrZip: 85249,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '1550 W MAPLEWOOD ST',
        RegAddrCity: 'CHANDLER',
        RegAddrState: 'AZ',
        RegAddrZip: 85286,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '784 W ORCHARD WAY',
        RegAddrCity: 'GILBERT',
        RegAddrState: 'AZ',
        RegAddrZip: 85233,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '901 S SCALLOP DR',
        RegAddrCity: 'GILBERT',
        RegAddrState: 'AZ',
        RegAddrZip: 85233,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '3753 E PHELPS ST',
        RegAddrCity: 'GILBERT',
        RegAddrState: 'AZ',
        RegAddrZip: 85295,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '3016 E TRIGGER WAY',
        RegAddrCity: 'GILBERT',
        RegAddrState: 'AZ',
        RegAddrZip: 85297,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '5440 E MAIN ST',
        RegAddrCity: 'MESA',
        RegAddrState: 'AZ',
        RegAddrZip: 85205,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '8318 E INGRAM ST',
        RegAddrCity: 'MESA',
        RegAddrState: 'AZ',
        RegAddrZip: 85207,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '8519 E LINDNER AVE',
        RegAddrCity: 'MESA',
        RegAddrState: 'AZ',
        RegAddrZip: 85209,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '4537 S ADELLE',
        RegAddrCity: 'MESA',
        RegAddrState: 'AZ',
        RegAddrZip: 85212,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '21502 E TWIN ACRES CT',
        RegAddrCity: 'QUEEN CREEK',
        RegAddrState: 'AZ',
        RegAddrZip: 85142,
        CongressionalDistrict: 5,
      },
    ],
  },
  {
    name: 'AZ-6',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/AZ-6?ref=gh-pages',
    state: 'AZ',
    state_and_number: 'AZ-6',
    zip: 85016,
    congressionalDistrict: 6,
    addressesInDistrict: [
      {
        RegAddrLine1: '4801 E WESTLAND RD',
        RegAddrCity: 'CAVE CREEK',
        RegAddrState: 'AZ',
        RegAddrZip: 85331,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '3056 W LONE CACTUS DR',
        RegAddrCity: 'PHOENIX',
        RegAddrState: 'AZ',
        RegAddrZip: 85027,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '13431 N 41ST ST',
        RegAddrCity: 'PHOENIX',
        RegAddrState: 'AZ',
        RegAddrZip: 85032,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '4465 E PARADISE VILLAGE PKWY S',
        RegAddrCity: 'PHOENIX',
        RegAddrState: 'AZ',
        RegAddrZip: 85032,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '3338 E TONTO LN',
        RegAddrCity: 'PHOENIX',
        RegAddrState: 'AZ',
        RegAddrZip: 85050,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '10421 N 33RD AVE',
        RegAddrCity: 'PHOENIX',
        RegAddrState: 'AZ',
        RegAddrZip: 85051,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '27109 N 23RD DR',
        RegAddrCity: 'PHOENIX',
        RegAddrState: 'AZ',
        RegAddrZip: 85085,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '5101 E PRESIDIO RD',
        RegAddrCity: 'SCOTTSDALE',
        RegAddrState: 'AZ',
        RegAddrZip: 85254,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '8441 E SORREL TRL',
        RegAddrCity: 'SCOTTSDALE',
        RegAddrState: 'AZ',
        RegAddrZip: 85255,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '10658 E GOLD DUST AVE',
        RegAddrCity: 'SCOTTSDALE',
        RegAddrState: 'AZ',
        RegAddrZip: 85258,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '7410 E SUTTON DR',
        RegAddrCity: 'SCOTTSDALE',
        RegAddrState: 'AZ',
        RegAddrZip: 85260,
        CongressionalDistrict: 6,
      },
    ],
  },
  {
    name: 'AZ-7',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/AZ-7?ref=gh-pages',
    state: 'AZ',
    state_and_number: 'AZ-7',
    zip: 85003,
    congressionalDistrict: 7,
    addressesInDistrict: [
      {
        RegAddrLine1: '5422 W IAN DR',
        RegAddrCity: 'LAVEEN',
        RegAddrState: 'AZ',
        RegAddrZip: 85339,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '1541 W FILLMORE ST',
        RegAddrCity: 'PHOENIX',
        RegAddrState: 'AZ',
        RegAddrZip: 85007,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '1302 W VERMONT AVE',
        RegAddrCity: 'PHOENIX',
        RegAddrState: 'AZ',
        RegAddrZip: 85013,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '8015 W HEATHERBRAE DR',
        RegAddrCity: 'PHOENIX',
        RegAddrState: 'AZ',
        RegAddrZip: 85033,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '2743 E BROADWAY RD',
        RegAddrCity: 'PHOENIX',
        RegAddrState: 'AZ',
        RegAddrZip: 85040,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '643 W ATLANTA AVE',
        RegAddrCity: 'PHOENIX',
        RegAddrState: 'AZ',
        RegAddrZip: 85041,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '5005 S 24TH AVE',
        RegAddrCity: 'PHOENIX',
        RegAddrState: 'AZ',
        RegAddrZip: 85041,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '3426 S 72ND LN',
        RegAddrCity: 'PHOENIX',
        RegAddrState: 'AZ',
        RegAddrZip: 85043,
        CongressionalDistrict: 7,
      },
    ],
  },
  {
    name: 'AZ-8',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/AZ-8?ref=gh-pages',
    state: 'AZ',
    state_and_number: 'AZ-8',
    zip: 85304,
    congressionalDistrict: 8,
    addressesInDistrict: [
      {
        RegAddrLine1: '2825 W WEBSTER CT',
        RegAddrCity: 'ANTHEM',
        RegAddrState: 'AZ',
        RegAddrZip: 85086,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '8150 N 61ST AVE',
        RegAddrCity: 'GLENDALE',
        RegAddrState: 'AZ',
        RegAddrZip: 85302,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '5415 W BROWN ST',
        RegAddrCity: 'GLENDALE',
        RegAddrState: 'AZ',
        RegAddrZip: 85302,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '16030 W ANASAZI ST',
        RegAddrCity: 'GOODYEAR',
        RegAddrState: 'AZ',
        RegAddrZip: 85338,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '15710 W MCKINLEY ST',
        RegAddrCity: 'GOODYEAR',
        RegAddrState: 'AZ',
        RegAddrZip: 85338,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '5727 N LESLIE CT',
        RegAddrCity: 'LITCHFIELD PK',
        RegAddrState: 'AZ',
        RegAddrZip: 85340,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '10701 N 99TH AVE',
        RegAddrCity: 'PEORIA',
        RegAddrState: 'AZ',
        RegAddrZip: 85345,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '8256 W ADAM AVE',
        RegAddrCity: 'PEORIA',
        RegAddrState: 'AZ',
        RegAddrZip: 85382,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '11616 N BALBOA DR',
        RegAddrCity: 'SUN CITY',
        RegAddrState: 'AZ',
        RegAddrZip: 85351,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '10514 W HIGHWOOD LN',
        RegAddrCity: 'SUN CITY',
        RegAddrState: 'AZ',
        RegAddrZip: 85373,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '17435 N FOOTHILLS DR',
        RegAddrCity: 'SUN CITY',
        RegAddrState: 'AZ',
        RegAddrZip: 85373,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '21832 N 119TH DR',
        RegAddrCity: 'SUN CITY',
        RegAddrState: 'AZ',
        RegAddrZip: 85373,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '19039 N 132ND AVE',
        RegAddrCity: 'SUN CITY WEST',
        RegAddrState: 'AZ',
        RegAddrZip: 85375,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '16409 W QUARRY CT',
        RegAddrCity: 'SURPRISE',
        RegAddrState: 'AZ',
        RegAddrZip: 85374,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '16336 W SANDRA LN',
        RegAddrCity: 'SURPRISE',
        RegAddrState: 'AZ',
        RegAddrZip: 85388,
        CongressionalDistrict: 8,
      },
    ],
  },
  {
    name: 'AZ-9',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/AZ-9?ref=gh-pages',
    state: 'AZ',
    state_and_number: 'AZ-9',
    zip: 85045,
    congressionalDistrict: 9,
    addressesInDistrict: [
      {
        RegAddrLine1: '2339 W MISSION DR',
        RegAddrCity: 'CHANDLER',
        RegAddrState: 'AZ',
        RegAddrZip: 85224,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '1742 W PEPPER PL',
        RegAddrCity: 'MESA',
        RegAddrState: 'AZ',
        RegAddrZip: 85201,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '3064 S SPRUCE',
        RegAddrCity: 'MESA',
        RegAddrState: 'AZ',
        RegAddrZip: 85210,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '1840 S HERITAGE',
        RegAddrCity: 'MESA',
        RegAddrState: 'AZ',
        RegAddrZip: 85210,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '1730 W STATE AVE',
        RegAddrCity: 'PHOENIX',
        RegAddrState: 'AZ',
        RegAddrZip: 85021,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '214 W FRIER DR',
        RegAddrCity: 'PHOENIX',
        RegAddrState: 'AZ',
        RegAddrZip: 85021,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '4757 E BIGHORN AVE',
        RegAddrCity: 'PHOENIX',
        RegAddrState: 'AZ',
        RegAddrZip: 85044,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '2202 E MOUNTAIN SKY AVE',
        RegAddrCity: 'PHOENIX',
        RegAddrState: 'AZ',
        RegAddrZip: 85048,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '8655 E PALO VERDE DR',
        RegAddrCity: 'SCOTTSDALE',
        RegAddrState: 'AZ',
        RegAddrZip: 85250,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '623 E HANCOCK AVE',
        RegAddrCity: 'TEMPE',
        RegAddrState: 'AZ',
        RegAddrZip: 85281,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '1513 E BISHOP DR',
        RegAddrCity: 'TEMPE',
        RegAddrState: 'AZ',
        RegAddrZip: 85282,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '626 E STRAHAN DR',
        RegAddrCity: 'TEMPE',
        RegAddrState: 'AZ',
        RegAddrZip: 85283,
        CongressionalDistrict: 9,
      },
    ],
  },
  {
    name: 'CA-1',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/CA-1?ref=gh-pages',
    state: 'CA',
    state_and_number: 'CA-1',
    zip: 89439,
    congressionalDistrict: 1,
    addressesInDistrict: [
      {
        RegAddrLine1: '23382 LA COSTA CT',
        RegAddrCity: 'AUBURN',
        RegAddrState: 'CA',
        RegAddrZip: 95602,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1668 ALBION CT',
        RegAddrCity: 'CHICO',
        RegAddrState: 'CA',
        RegAddrZip: 95973,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '17950 INDIAN PEAK RD',
        RegAddrCity: 'COTTONWOOD',
        RegAddrState: 'CA',
        RegAddrZip: 96022,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '26379 WILSON ST',
        RegAddrCity: 'LOS MOLINOS',
        RegAddrState: 'CA',
        RegAddrZip: 96055,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1221 WOODLAND PARK DR',
        RegAddrCity: 'MOUNT SHASTA',
        RegAddrState: 'CA',
        RegAddrZip: 96067,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '3142 CLAREMONT DR',
        RegAddrCity: 'OROVILLE',
        RegAddrState: 'CA',
        RegAddrZip: 95966,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1444 SCANDIA WAY',
        RegAddrCity: 'PARADISE',
        RegAddrState: 'CA',
        RegAddrZip: 95969,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '12064 LAKE WILDWOOD DR',
        RegAddrCity: 'PENN VALLEY',
        RegAddrState: 'CA',
        RegAddrZip: 95946,
        CongressionalDistrict: 1,
      },
    ],
  },
  {
    name: 'CA-10',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/CA-10?ref=gh-pages',
    state: 'CA',
    state_and_number: 'CA-10',
    zip: 94550,
    congressionalDistrict: 10,
    addressesInDistrict: [
      {
        RegAddrLine1: '1075 SUMMIT WAY',
        RegAddrCity: 'MANTECA',
        RegAddrState: 'CA',
        RegAddrZip: 95336,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '2180 IVORY LACE AVE',
        RegAddrCity: 'MANTECA',
        RegAddrState: 'CA',
        RegAddrZip: 95337,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '820 SNEAD DR',
        RegAddrCity: 'MODESTO',
        RegAddrState: 'CA',
        RegAddrZip: 95351,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '4593 MORNING BROOK LN',
        RegAddrCity: 'TRACY',
        RegAddrState: 'CA',
        RegAddrZip: 95377,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '1680 PROVO ST',
        RegAddrCity: 'TURLOCK',
        RegAddrState: 'CA',
        RegAddrZip: 95380,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '4076 SAGE WAY',
        RegAddrCity: 'TURLOCK',
        RegAddrState: 'CA',
        RegAddrZip: 95382,
        CongressionalDistrict: 10,
      },
    ],
  },
  {
    name: 'CA-11',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/CA-11?ref=gh-pages',
    state: 'CA',
    state_and_number: 'CA-11',
    zip: 94506,
    congressionalDistrict: 11,
    addressesInDistrict: [
      {
        RegAddrLine1: '458 GRENACHE CIR',
        RegAddrCity: 'CLAYTON',
        RegAddrState: 'CA',
        RegAddrZip: 94517,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '3311 GREEN MEADOW DR',
        RegAddrCity: 'DANVILLE',
        RegAddrState: 'CA',
        RegAddrZip: 94506,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '1417 LIBERTY ST',
        RegAddrCity: 'EL CERRITO',
        RegAddrState: 'CA',
        RegAddrZip: 94530,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '2055 HARPER ST',
        RegAddrCity: 'EL CERRITO',
        RegAddrState: 'CA',
        RegAddrZip: 94530,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '479 PEACOCK BLVD',
        RegAddrCity: 'LAFAYETTE',
        RegAddrState: 'CA',
        RegAddrZip: 94549,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '1955 MEADOW RD',
        RegAddrCity: 'WALNUT CREEK',
        RegAddrState: 'CA',
        RegAddrZip: 94595,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '1767 CAMINO VERDE B',
        RegAddrCity: 'WALNUT CREEK',
        RegAddrState: 'CA',
        RegAddrZip: 94597,
        CongressionalDistrict: 11,
      },
    ],
  },
  {
    name: 'CA-12',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/CA-12?ref=gh-pages',
    state: 'CA',
    state_and_number: 'CA-12',
    zip: 94102,
    congressionalDistrict: 12,
    addressesInDistrict: [
      {
        RegAddrLine1: '1185 PIERCE ST',
        RegAddrCity: 'SAN FRANCISCO',
        RegAddrState: 'CA',
        RegAddrZip: 94115,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '2550 32ND AVE',
        RegAddrCity: 'SAN FRANCISCO',
        RegAddrState: 'CA',
        RegAddrZip: 94116,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '2143 29TH AVE',
        RegAddrCity: 'SAN FRANCISCO',
        RegAddrState: 'CA',
        RegAddrZip: 94116,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '844 DETROIT ST',
        RegAddrCity: 'SAN FRANCISCO',
        RegAddrState: 'CA',
        RegAddrZip: 94131,
        CongressionalDistrict: 12,
      },
    ],
  },
  {
    name: 'CA-13',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/CA-13?ref=gh-pages',
    state: 'CA',
    state_and_number: 'CA-13',
    zip: 94501,
    congressionalDistrict: 13,
    addressesInDistrict: [
      {
        RegAddrLine1: '536 SANTA CLARA AVE',
        RegAddrCity: 'ALAMEDA',
        RegAddrState: 'CA',
        RegAddrZip: 94501,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '3050 ARIZONA ST',
        RegAddrCity: 'OAKLAND',
        RegAddrState: 'CA',
        RegAddrZip: 94602,
        CongressionalDistrict: 13,
      },
    ],
  },
  {
    name: 'CA-14',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/CA-14?ref=gh-pages',
    state: 'CA',
    state_and_number: 'CA-14',
    zip: 94002,
    congressionalDistrict: 14,
    addressesInDistrict: [
      {
        RegAddrLine1: '3 RIDGEWOOD CT',
        RegAddrCity: 'BELMONT',
        RegAddrState: 'CA',
        RegAddrZip: 94002,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '6524 MISSION ST',
        RegAddrCity: 'DALY CITY',
        RegAddrState: 'CA',
        RegAddrZip: 94014,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '290 CURLEW CT',
        RegAddrCity: 'FOSTER CITY',
        RegAddrState: 'CA',
        RegAddrZip: 94404,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '7203 ADMIRALTY LN',
        RegAddrCity: 'FOSTER CITY',
        RegAddrState: 'CA',
        RegAddrZip: 94404,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '341 GRANELLI AVE',
        RegAddrCity: 'HALF MOON BAY',
        RegAddrState: 'CA',
        RegAddrZip: 94019,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '636 SOUTHMOOR DR',
        RegAddrCity: 'PACIFICA',
        RegAddrState: 'CA',
        RegAddrZip: 94044,
        CongressionalDistrict: 14,
      },
    ],
  },
  {
    name: 'CA-15',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/CA-15?ref=gh-pages',
    state: 'CA',
    state_and_number: 'CA-15',
    zip: 94536,
    congressionalDistrict: 15,
    addressesInDistrict: [
      {
        RegAddrLine1: '4809 MANCINI DR',
        RegAddrCity: 'CASTRO VALLEY',
        RegAddrState: 'CA',
        RegAddrZip: 94546,
        CongressionalDistrict: 15,
      },
      {
        RegAddrLine1: '20962 ELBRIDGE CT',
        RegAddrCity: 'CASTRO VALLEY',
        RegAddrState: 'CA',
        RegAddrZip: 94552,
        CongressionalDistrict: 15,
      },
      {
        RegAddrLine1: '27972 FARM HILL DR',
        RegAddrCity: 'HAYWARD',
        RegAddrState: 'CA',
        RegAddrZip: 94542,
        CongressionalDistrict: 15,
      },
      {
        RegAddrLine1: '25764 UNDERWOOD AVE',
        RegAddrCity: 'HAYWARD',
        RegAddrState: 'CA',
        RegAddrZip: 94544,
        CongressionalDistrict: 15,
      },
      {
        RegAddrLine1: '636 OLD OAK RD',
        RegAddrCity: 'LIVERMORE',
        RegAddrState: 'CA',
        RegAddrZip: 94550,
        CongressionalDistrict: 15,
      },
      {
        RegAddrLine1: '2036 AVY AVE',
        RegAddrCity: 'MENLO PARK',
        RegAddrState: 'CA',
        RegAddrZip: 94025,
        CongressionalDistrict: 15,
      },
    ],
  },
  {
    name: 'CA-16',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/CA-16?ref=gh-pages',
    state: 'CA',
    state_and_number: 'CA-16',
    zip: 93606,
    congressionalDistrict: 16,
    addressesInDistrict: [
      {
        RegAddrLine1: '2404 WHIPPLEWOOD DR',
        RegAddrCity: 'ATWATER',
        RegAddrState: 'CA',
        RegAddrZip: 95301,
        CongressionalDistrict: 16,
      },
      {
        RegAddrLine1: '1036 E JOLSON AVE',
        RegAddrCity: 'FRESNO',
        RegAddrState: 'CA',
        RegAddrZip: 93706,
        CongressionalDistrict: 16,
      },
      {
        RegAddrLine1: '2659 MIRA CT',
        RegAddrCity: 'MERCED',
        RegAddrState: 'CA',
        RegAddrZip: 95341,
        CongressionalDistrict: 16,
      },
      {
        RegAddrLine1: '4019 SISTERON CT',
        RegAddrCity: 'MERCED',
        RegAddrState: 'CA',
        RegAddrZip: 95348,
        CongressionalDistrict: 16,
      },
      {
        RegAddrLine1: '279 MORENO CT',
        RegAddrCity: 'PLANADA',
        RegAddrState: 'CA',
        RegAddrZip: 95365,
        CongressionalDistrict: 16,
      },
    ],
  },
  {
    name: 'CA-17',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/CA-17?ref=gh-pages',
    state: 'CA',
    state_and_number: 'CA-17',
    zip: 94024,
    congressionalDistrict: 17,
    addressesInDistrict: [
      {
        RegAddrLine1: '4421 FAULKNER DR',
        RegAddrCity: 'FREMONT',
        RegAddrState: 'CA',
        RegAddrZip: 94536,
        CongressionalDistrict: 17,
      },
      {
        RegAddrLine1: '41764 CHILTERN DR',
        RegAddrCity: 'FREMONT',
        RegAddrState: 'CA',
        RegAddrZip: 94539,
        CongressionalDistrict: 17,
      },
      {
        RegAddrLine1: '1578 COUNTRY CLUB DR',
        RegAddrCity: 'MILPITAS',
        RegAddrState: 'CA',
        RegAddrZip: 95035,
        CongressionalDistrict: 17,
      },
      {
        RegAddrLine1: '2977 TOBIN DR',
        RegAddrCity: 'SAN JOSE',
        RegAddrState: 'CA',
        RegAddrZip: 95132,
        CongressionalDistrict: 17,
      },
      {
        RegAddrLine1: '2760 RAINVIEW DR',
        RegAddrCity: 'SAN JOSE',
        RegAddrState: 'CA',
        RegAddrZip: 95133,
        CongressionalDistrict: 17,
      },
      {
        RegAddrLine1: '131 EL BOSQUE DR',
        RegAddrCity: 'SAN JOSE',
        RegAddrState: 'CA',
        RegAddrZip: 95134,
        CongressionalDistrict: 17,
      },
      {
        RegAddrLine1: '1170 ALVISO ST',
        RegAddrCity: 'SANTA CLARA',
        RegAddrState: 'CA',
        RegAddrZip: 95050,
        CongressionalDistrict: 17,
      },
      {
        RegAddrLine1: '566 DUBLIN WAY',
        RegAddrCity: 'SUNNYVALE',
        RegAddrState: 'CA',
        RegAddrZip: 94087,
        CongressionalDistrict: 17,
      },
    ],
  },
  {
    name: 'CA-18',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/CA-18?ref=gh-pages',
    state: 'CA',
    state_and_number: 'CA-18',
    zip: 94020,
    congressionalDistrict: 18,
    addressesInDistrict: [
      {
        RegAddrLine1: '125 GREENVIEW DR',
        RegAddrCity: 'BOULDER CREEK',
        RegAddrState: 'CA',
        RegAddrZip: 95006,
        CongressionalDistrict: 18,
      },
      {
        RegAddrLine1: '14540 CLEARVIEW DR',
        RegAddrCity: 'LOS GATOS',
        RegAddrState: 'CA',
        RegAddrZip: 95032,
        CongressionalDistrict: 18,
      },
      {
        RegAddrLine1: '769 BRYN MAWR CT',
        RegAddrCity: 'MOUNTAIN VIEW',
        RegAddrState: 'CA',
        RegAddrZip: 94043,
        CongressionalDistrict: 18,
      },
      {
        RegAddrLine1: '263 IRIS ST',
        RegAddrCity: 'REDWOOD CITY',
        RegAddrState: 'CA',
        RegAddrZip: 94062,
        CongressionalDistrict: 18,
      },
      {
        RegAddrLine1: '2509 TOLWORTH DR',
        RegAddrCity: 'SAN JOSE',
        RegAddrState: 'CA',
        RegAddrZip: 95128,
        CongressionalDistrict: 18,
      },
      {
        RegAddrLine1: '4955 KINGSTON WAY',
        RegAddrCity: 'SAN JOSE',
        RegAddrState: 'CA',
        RegAddrZip: 95130,
        CongressionalDistrict: 18,
      },
      {
        RegAddrLine1: '126 S NAVARRA DR',
        RegAddrCity: 'SCOTTS VALLEY',
        RegAddrState: 'CA',
        RegAddrZip: 95066,
        CongressionalDistrict: 18,
      },
      {
        RegAddrLine1: '34534 MAHOGANY LN',
        RegAddrCity: 'UNION CITY',
        RegAddrState: 'CA',
        RegAddrZip: 94587,
        CongressionalDistrict: 18,
      },
    ],
  },
  {
    name: 'CA-19',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/CA-19?ref=gh-pages',
    state: 'CA',
    state_and_number: 'CA-19',
    zip: 95013,
    congressionalDistrict: 19,
    addressesInDistrict: [
      {
        RegAddrLine1: '7440 CRAWFORD CT',
        RegAddrCity: 'GILROY',
        RegAddrState: 'CA',
        RegAddrZip: 95020,
        CongressionalDistrict: 19,
      },
      {
        RegAddrLine1: '4099 SAN SIMEON WAY',
        RegAddrCity: 'SAN JOSE',
        RegAddrState: 'CA',
        RegAddrZip: 95111,
        CongressionalDistrict: 19,
      },
      {
        RegAddrLine1: '629 WASHINGTON ST',
        RegAddrCity: 'SAN JOSE',
        RegAddrState: 'CA',
        RegAddrZip: 95112,
        CongressionalDistrict: 19,
      },
      {
        RegAddrLine1: '263 TRUCKEE LN',
        RegAddrCity: 'SAN JOSE',
        RegAddrState: 'CA',
        RegAddrZip: 95136,
        CongressionalDistrict: 19,
      },
      {
        RegAddrLine1: '1660 EBERTS DR',
        RegAddrCity: 'SAN MARTIN',
        RegAddrState: 'CA',
        RegAddrZip: 95046,
        CongressionalDistrict: 19,
      },
    ],
  },
  {
    name: 'CA-2',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/CA-2?ref=gh-pages',
    state: 'CA',
    state_and_number: 'CA-2',
    zip: 94515,
    congressionalDistrict: 2,
    addressesInDistrict: [
      {
        RegAddrLine1: '37 FLYING CLOUD CRSE',
        RegAddrCity: 'CORTE MADERA',
        RegAddrState: 'CA',
        RegAddrZip: 94925,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '19 PARK AVE',
        RegAddrCity: 'DILLON BEACH',
        RegAddrState: 'CA',
        RegAddrZip: 94929,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '5714 MATSEN WAY',
        RegAddrCity: 'EUREKA',
        RegAddrState: 'CA',
        RegAddrZip: 95503,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '60 IDLEWOOD RD',
        RegAddrCity: 'KENTFIELD',
        RegAddrState: 'CA',
        RegAddrZip: 94904,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '3595 BOHEMIAN HWY',
        RegAddrCity: 'OCCIDENTAL',
        RegAddrState: 'CA',
        RegAddrZip: 95465,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1103 LAKEVILLE CIR',
        RegAddrCity: 'PETALUMA',
        RegAddrState: 'CA',
        RegAddrZip: 94954,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '4401 GRAVENSTEIN HWY N',
        RegAddrCity: 'SEBASTOPOL',
        RegAddrState: 'CA',
        RegAddrZip: 95472,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2083 BLUCHER VALLEY RD',
        RegAddrCity: 'SEBASTOPOL',
        RegAddrState: 'CA',
        RegAddrZip: 95472,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '960 CRANNELL RD',
        RegAddrCity: 'TRINIDAD',
        RegAddrState: 'CA',
        RegAddrZip: 95570,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '807 PULTENEY PL',
        RegAddrCity: 'WINDSOR',
        RegAddrState: 'CA',
        RegAddrZip: 95492,
        CongressionalDistrict: 2,
      },
    ],
  },
  {
    name: 'CA-20',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/CA-20?ref=gh-pages',
    state: 'CA',
    state_and_number: 'CA-20',
    zip: 93210,
    congressionalDistrict: 20,
    addressesInDistrict: [
      {
        RegAddrLine1: '37723 PALO COLORADO RD',
        RegAddrCity: 'CARMEL',
        RegAddrState: 'CA',
        RegAddrZip: 93923,
        CongressionalDistrict: 20,
      },
      {
        RegAddrLine1: '128 GRANT ST',
        RegAddrCity: 'SANTA CRUZ',
        RegAddrState: 'CA',
        RegAddrZip: 95060,
        CongressionalDistrict: 20,
      },
    ],
  },
  {
    name: 'CA-21',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/CA-21?ref=gh-pages',
    state: 'CA',
    state_and_number: 'CA-21',
    zip: 93201,
    congressionalDistrict: 21,
    addressesInDistrict: [
      {
        RegAddrLine1: '1000 PRISCILLA LN',
        RegAddrCity: 'BAKERSFIELD',
        RegAddrState: 'CA',
        RegAddrZip: 93304,
        CongressionalDistrict: 21,
      },
      {
        RegAddrLine1: '508 DORRANCE ST',
        RegAddrCity: 'BAKERSFIELD',
        RegAddrState: 'CA',
        RegAddrZip: 93307,
        CongressionalDistrict: 21,
      },
      {
        RegAddrLine1: '155 E HOUSTON ST',
        RegAddrCity: 'COALINGA',
        RegAddrState: 'CA',
        RegAddrZip: 93210,
        CongressionalDistrict: 21,
      },
      {
        RegAddrLine1: '901 P ST',
        RegAddrCity: 'FIREBAUGH',
        RegAddrState: 'CA',
        RegAddrZip: 93622,
        CongressionalDistrict: 21,
      },
      {
        RegAddrLine1: '280 E SHERWOOD DR',
        RegAddrCity: 'HANFORD',
        RegAddrState: 'CA',
        RegAddrZip: 93230,
        CongressionalDistrict: 21,
      },
      {
        RegAddrLine1: '2320 19TH AVE',
        RegAddrCity: 'KINGSBURG',
        RegAddrState: 'CA',
        RegAddrZip: 93631,
        CongressionalDistrict: 21,
      },
      {
        RegAddrLine1: '2433 9TH ST',
        RegAddrCity: 'WASCO',
        RegAddrState: 'CA',
        RegAddrZip: 93280,
        CongressionalDistrict: 21,
      },
    ],
  },
  {
    name: 'CA-22',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/CA-22?ref=gh-pages',
    state: 'CA',
    state_and_number: 'CA-22',
    zip: 93221,
    congressionalDistrict: 22,
    addressesInDistrict: [
      {
        RegAddrLine1: '2942 BELLAIRE AVE',
        RegAddrCity: 'CLOVIS',
        RegAddrState: 'CA',
        RegAddrZip: 93611,
        CongressionalDistrict: 22,
      },
      {
        RegAddrLine1: '3415 MEGAN AVE',
        RegAddrCity: 'CLOVIS',
        RegAddrState: 'CA',
        RegAddrZip: 93611,
        CongressionalDistrict: 22,
      },
      {
        RegAddrLine1: '1680 E SOLAR AVE',
        RegAddrCity: 'FRESNO',
        RegAddrState: 'CA',
        RegAddrZip: 93720,
        CongressionalDistrict: 22,
      },
      {
        RegAddrLine1: '3065 N HORNET AVE',
        RegAddrCity: 'FRESNO',
        RegAddrState: 'CA',
        RegAddrZip: 93737,
        CongressionalDistrict: 22,
      },
      {
        RegAddrLine1: '3232 W PACKWOOD CT',
        RegAddrCity: 'VISALIA',
        RegAddrState: 'CA',
        RegAddrZip: 93277,
        CongressionalDistrict: 22,
      },
      {
        RegAddrLine1: '4038 W BUENA VISTA AVE',
        RegAddrCity: 'VISALIA',
        RegAddrState: 'CA',
        RegAddrZip: 93291,
        CongressionalDistrict: 22,
      },
      {
        RegAddrLine1: '2519 E SEEGER AVE',
        RegAddrCity: 'VISALIA',
        RegAddrState: 'CA',
        RegAddrZip: 93292,
        CongressionalDistrict: 22,
      },
    ],
  },
  {
    name: 'CA-23',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/CA-23?ref=gh-pages',
    state: 'CA',
    state_and_number: 'CA-23',
    zip: 93205,
    congressionalDistrict: 23,
    addressesInDistrict: [
      {
        RegAddrLine1: '3812 DALEHURST DR',
        RegAddrCity: 'BAKERSFIELD',
        RegAddrState: 'CA',
        RegAddrZip: 93306,
        CongressionalDistrict: 23,
      },
      {
        RegAddrLine1: '5942 N BULL RUN ST',
        RegAddrCity: 'INYOKERN',
        RegAddrState: 'CA',
        RegAddrZip: 93527,
        CongressionalDistrict: 23,
      },
      {
        RegAddrLine1: '1811 WILLIAMS CT',
        RegAddrCity: 'LAKE ISABELLA',
        RegAddrState: 'CA',
        RegAddrZip: 93240,
        CongressionalDistrict: 23,
      },
      {
        RegAddrLine1: '482 N KANAI DR',
        RegAddrCity: 'PORTERVILLE',
        RegAddrState: 'CA',
        RegAddrZip: 93257,
        CongressionalDistrict: 23,
      },
      {
        RegAddrLine1: '613 N SUNLAND DR',
        RegAddrCity: 'RIDGECREST',
        RegAddrState: 'CA',
        RegAddrZip: 93555,
        CongressionalDistrict: 23,
      },
      {
        RegAddrLine1: '312 E SAN EMIDIO ST',
        RegAddrCity: 'TAFT',
        RegAddrState: 'CA',
        RegAddrZip: 93268,
        CongressionalDistrict: 23,
      },
    ],
  },
  {
    name: 'CA-24',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/CA-24?ref=gh-pages',
    state: 'CA',
    state_and_number: 'CA-24',
    zip: 93001,
    congressionalDistrict: 24,
    addressesInDistrict: [
      {
        RegAddrLine1: '3440 SPRING ST',
        RegAddrCity: 'PASO ROBLES',
        RegAddrState: 'CA',
        RegAddrZip: 93446,
        CongressionalDistrict: 24,
      },
      {
        RegAddrLine1: '8432 SETTLERS PL',
        RegAddrCity: 'PASO ROBLES',
        RegAddrState: 'CA',
        RegAddrZip: 93446,
        CongressionalDistrict: 24,
      },
      {
        RegAddrLine1: '124 FLAG WAY',
        RegAddrCity: 'PASO ROBLES',
        RegAddrState: 'CA',
        RegAddrZip: 93446,
        CongressionalDistrict: 24,
      },
      {
        RegAddrLine1: '1234 LAUREL LN',
        RegAddrCity: 'SAN LUIS OBISPO',
        RegAddrState: 'CA',
        RegAddrZip: 93401,
        CongressionalDistrict: 24,
      },
      {
        RegAddrLine1: '1923 MELANIE CT',
        RegAddrCity: 'SANTA MARIA',
        RegAddrState: 'CA',
        RegAddrZip: 93454,
        CongressionalDistrict: 24,
      },
      {
        RegAddrLine1: '967 LARCH AVE',
        RegAddrCity: 'SANTA MARIA',
        RegAddrState: 'CA',
        RegAddrZip: 93455,
        CongressionalDistrict: 24,
      },
      {
        RegAddrLine1: '1339 CHEYENNE LN',
        RegAddrCity: 'SANTA YNEZ',
        RegAddrState: 'CA',
        RegAddrZip: 93460,
        CongressionalDistrict: 24,
      },
    ],
  },
  {
    name: 'CA-25',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/CA-25?ref=gh-pages',
    state: 'CA',
    state_and_number: 'CA-25',
    zip: 91042,
    congressionalDistrict: 25,
    addressesInDistrict: [
      {
        RegAddrLine1: '19365 KILFINAN ST',
        RegAddrCity: 'NORTHRIDGE',
        RegAddrState: 'CA',
        RegAddrZip: 91326,
        CongressionalDistrict: 25,
      },
      {
        RegAddrLine1: '40718 PEBBLE BEACH DR',
        RegAddrCity: 'PALMDALE',
        RegAddrState: 'CA',
        RegAddrZip: 93551,
        CongressionalDistrict: 25,
      },
      {
        RegAddrLine1: '1273 RINCON ST',
        RegAddrCity: 'SIMI VALLEY',
        RegAddrState: 'CA',
        RegAddrZip: 93065,
        CongressionalDistrict: 25,
      },
      {
        RegAddrLine1: '639 RUNNING CREEK CT',
        RegAddrCity: 'SIMI VALLEY',
        RegAddrState: 'CA',
        RegAddrZip: 93065,
        CongressionalDistrict: 25,
      },
      {
        RegAddrLine1: '28236 FORGE CT',
        RegAddrCity: 'VALENCIA',
        RegAddrState: 'CA',
        RegAddrZip: 91354,
        CongressionalDistrict: 25,
      },
    ],
  },
  {
    name: 'CA-26',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/CA-26?ref=gh-pages',
    state: 'CA',
    state_and_number: 'CA-26',
    zip: 90265,
    congressionalDistrict: 26,
    addressesInDistrict: [
      {
        RegAddrLine1: '662 MISSION DR',
        RegAddrCity: 'CAMARILLO',
        RegAddrState: 'CA',
        RegAddrZip: 93010,
        CongressionalDistrict: 26,
      },
      {
        RegAddrLine1: '551 ASHDALE CT',
        RegAddrCity: 'CAMARILLO',
        RegAddrState: 'CA',
        RegAddrZip: 93010,
        CongressionalDistrict: 26,
      },
      {
        RegAddrLine1: '534 S E ST',
        RegAddrCity: 'OXNARD',
        RegAddrState: 'CA',
        RegAddrZip: 93030,
        CongressionalDistrict: 26,
      },
      {
        RegAddrLine1: '1240 ESCALON DR',
        RegAddrCity: 'OXNARD',
        RegAddrState: 'CA',
        RegAddrZip: 93035,
        CongressionalDistrict: 26,
      },
      {
        RegAddrLine1: '5320 CAMINITO CACHORRO',
        RegAddrCity: 'SAN DIEGO',
        RegAddrState: 'CA',
        RegAddrZip: 92105,
        CongressionalDistrict: 26,
      },
      {
        RegAddrLine1: '353 W COLUMBIA RD',
        RegAddrCity: 'THOUSAND OAKS',
        RegAddrState: 'CA',
        RegAddrZip: 91360,
        CongressionalDistrict: 26,
      },
      {
        RegAddrLine1: '10900 TELEPHONE RD',
        RegAddrCity: 'VENTURA',
        RegAddrState: 'CA',
        RegAddrZip: 93004,
        CongressionalDistrict: 26,
      },
    ],
  },
  {
    name: 'CA-27',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/CA-27?ref=gh-pages',
    state: 'CA',
    state_and_number: 'CA-27',
    zip: 90022,
    congressionalDistrict: 27,
    addressesInDistrict: [
      {
        RegAddrLine1: '109 S 6TH ST',
        RegAddrCity: 'ALHAMBRA',
        RegAddrState: 'CA',
        RegAddrZip: 91801,
        CongressionalDistrict: 27,
      },
      {
        RegAddrLine1: '722 S OLD RANCH RD',
        RegAddrCity: 'ARCADIA',
        RegAddrState: 'CA',
        RegAddrZip: 91007,
        CongressionalDistrict: 27,
      },
      {
        RegAddrLine1: '2917 RHODELIA AVE',
        RegAddrCity: 'CLAREMONT',
        RegAddrState: 'CA',
        RegAddrZip: 91711,
        CongressionalDistrict: 27,
      },
      {
        RegAddrLine1: '680 ADIRONDACK LN',
        RegAddrCity: 'CLAREMONT',
        RegAddrState: 'CA',
        RegAddrZip: 91711,
        CongressionalDistrict: 27,
      },
      {
        RegAddrLine1: '1013 SCRIPPS DR',
        RegAddrCity: 'CLAREMONT',
        RegAddrState: 'CA',
        RegAddrZip: 91711,
        CongressionalDistrict: 27,
      },
      {
        RegAddrLine1: '8427 NORWOOD PL',
        RegAddrCity: 'ROSEMEAD',
        RegAddrState: 'CA',
        RegAddrZip: 91770,
        CongressionalDistrict: 27,
      },
      {
        RegAddrLine1: '1439 BANK ST',
        RegAddrCity: 'S PASADENA',
        RegAddrState: 'CA',
        RegAddrZip: 91030,
        CongressionalDistrict: 27,
      },
      {
        RegAddrLine1: '2304 N SAN ANTONIO AVE',
        RegAddrCity: 'UPLAND',
        RegAddrState: 'CA',
        RegAddrZip: 91784,
        CongressionalDistrict: 27,
      },
    ],
  },
  {
    name: 'CA-28',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/CA-28?ref=gh-pages',
    state: 'CA',
    state_and_number: 'CA-28',
    zip: 90004,
    congressionalDistrict: 28,
    addressesInDistrict: [
      {
        RegAddrLine1: '1527 1/2 ROSALIA RD',
        RegAddrCity: 'LOS ANGELES',
        RegAddrState: 'CA',
        RegAddrZip: 90027,
        CongressionalDistrict: 28,
      },
      {
        RegAddrLine1: '3519 HOLLYDALE DR',
        RegAddrCity: 'LOS ANGELES',
        RegAddrState: 'CA',
        RegAddrZip: 90039,
        CongressionalDistrict: 28,
      },
      {
        RegAddrLine1: '3172 LA CLEDE AVE',
        RegAddrCity: 'LOS ANGELES',
        RegAddrState: 'CA',
        RegAddrZip: 90039,
        CongressionalDistrict: 28,
      },
      {
        RegAddrLine1: '10235 MOUNT GLEASON AVE',
        RegAddrCity: 'SUNLAND',
        RegAddrState: 'CA',
        RegAddrZip: 91040,
        CongressionalDistrict: 28,
      },
      {
        RegAddrLine1: '10940 LEOLANG AVE',
        RegAddrCity: 'SUNLAND',
        RegAddrState: 'CA',
        RegAddrZip: 91040,
        CongressionalDistrict: 28,
      },
      {
        RegAddrLine1: '1235 LARRABEE ST',
        RegAddrCity: 'WEST HOLLYWOOD',
        RegAddrState: 'CA',
        RegAddrZip: 90069,
        CongressionalDistrict: 28,
      },
    ],
  },
  {
    name: 'CA-29',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/CA-29?ref=gh-pages',
    state: 'CA',
    state_and_number: 'CA-29',
    zip: 91340,
    congressionalDistrict: 29,
    addressesInDistrict: [
      {
        RegAddrLine1: '5947 TUJUNGA AVE',
        RegAddrCity: 'N HOLLYWOOD',
        RegAddrState: 'CA',
        RegAddrZip: 91601,
        CongressionalDistrict: 29,
      },
      {
        RegAddrLine1: '9701 PINE ORCHARD ST',
        RegAddrCity: 'PACOIMA',
        RegAddrState: 'CA',
        RegAddrZip: 91331,
        CongressionalDistrict: 29,
      },
      {
        RegAddrLine1: '11110 RUNNYMEDE ST',
        RegAddrCity: 'SUN VALLEY',
        RegAddrState: 'CA',
        RegAddrZip: 91352,
        CongressionalDistrict: 29,
      },
      {
        RegAddrLine1: '13848 BESSEMER ST',
        RegAddrCity: 'VAN NUYS',
        RegAddrState: 'CA',
        RegAddrZip: 91401,
        CongressionalDistrict: 29,
      },
      {
        RegAddrLine1: '15749 ARCHWOOD ST',
        RegAddrCity: 'VAN NUYS',
        RegAddrState: 'CA',
        RegAddrZip: 91406,
        CongressionalDistrict: 29,
      },
    ],
  },
  {
    name: 'CA-3',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/CA-3?ref=gh-pages',
    state: 'CA',
    state_and_number: 'CA-3',
    zip: 94510,
    congressionalDistrict: 3,
    addressesInDistrict: [
      {
        RegAddrLine1: '7649 PLAID CIR',
        RegAddrCity: 'ANTELOPE',
        RegAddrState: 'CA',
        RegAddrZip: 95843,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '4775 OLD HIGHWAY 53',
        RegAddrCity: 'CLEARLAKE',
        RegAddrState: 'CA',
        RegAddrZip: 95422,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '5140 BICKFORD CIR',
        RegAddrCity: 'FAIRFIELD',
        RegAddrState: 'CA',
        RegAddrZip: 94533,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '5 JAMES PL',
        RegAddrCity: 'WOODLAND',
        RegAddrState: 'CA',
        RegAddrZip: 95695,
        CongressionalDistrict: 3,
      },
    ],
  },
  {
    name: 'CA-30',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/CA-30?ref=gh-pages',
    state: 'CA',
    state_and_number: 'CA-30',
    zip: 90049,
    congressionalDistrict: 30,
    addressesInDistrict: [
      {
        RegAddrLine1: '19846 DINA PL',
        RegAddrCity: 'CHATSWORTH',
        RegAddrState: 'CA',
        RegAddrZip: 91311,
        CongressionalDistrict: 30,
      },
      {
        RegAddrLine1: '6933 LINDLEY AVE',
        RegAddrCity: 'RESEDA',
        RegAddrState: 'CA',
        RegAddrZip: 91335,
        CongressionalDistrict: 30,
      },
      {
        RegAddrLine1: '14626 GREENLEAF ST',
        RegAddrCity: 'SHERMAN OAKS',
        RegAddrState: 'CA',
        RegAddrZip: 91403,
        CongressionalDistrict: 30,
      },
      {
        RegAddrLine1: '5017 WILKINSON AVE',
        RegAddrCity: 'VALLEY VLG',
        RegAddrState: 'CA',
        RegAddrZip: 91607,
        CongressionalDistrict: 30,
      },
      {
        RegAddrLine1: '20432 COULSON ST',
        RegAddrCity: 'WOODLAND HLS',
        RegAddrState: 'CA',
        RegAddrZip: 91367,
        CongressionalDistrict: 30,
      },
    ],
  },
  {
    name: 'CA-31',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/CA-31?ref=gh-pages',
    state: 'CA',
    state_and_number: 'CA-31',
    zip: 91730,
    congressionalDistrict: 31,
    addressesInDistrict: [
      {
        RegAddrLine1: '5635 ARDEN AVE',
        RegAddrCity: 'HIGHLAND',
        RegAddrState: 'CA',
        RegAddrZip: 92346,
        CongressionalDistrict: 31,
      },
      {
        RegAddrLine1: '1904 HARDT ST',
        RegAddrCity: 'LOMA LINDA',
        RegAddrState: 'CA',
        RegAddrZip: 92354,
        CongressionalDistrict: 31,
      },
      {
        RegAddrLine1: '11040 CARLOW CT',
        RegAddrCity: 'RCH CUCAMONGA',
        RegAddrState: 'CA',
        RegAddrZip: 91701,
        CongressionalDistrict: 31,
      },
      {
        RegAddrLine1: '1805 SMILEY RDG',
        RegAddrCity: 'REDLANDS',
        RegAddrState: 'CA',
        RegAddrZip: 92373,
        CongressionalDistrict: 31,
      },
    ],
  },
  {
    name: 'CA-32',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/CA-32?ref=gh-pages',
    state: 'CA',
    state_and_number: 'CA-32',
    zip: 90601,
    congressionalDistrict: 32,
    addressesInDistrict: [
      {
        RegAddrLine1: '128 N SOLDANO AVE',
        RegAddrCity: 'AZUSA',
        RegAddrState: 'CA',
        RegAddrZip: 91702,
        CongressionalDistrict: 32,
      },
      {
        RegAddrLine1: '153 N PASADENA AVE',
        RegAddrCity: 'AZUSA',
        RegAddrState: 'CA',
        RegAddrZip: 91702,
        CongressionalDistrict: 32,
      },
      {
        RegAddrLine1: '4325 ALTA LAKE AVE',
        RegAddrCity: 'BALDWIN PARK',
        RegAddrState: 'CA',
        RegAddrZip: 91706,
        CongressionalDistrict: 32,
      },
      {
        RegAddrLine1: '21318 E BENBOW ST',
        RegAddrCity: 'COVINA',
        RegAddrState: 'CA',
        RegAddrZip: 91724,
        CongressionalDistrict: 32,
      },
      {
        RegAddrLine1: '11906 BASYE ST',
        RegAddrCity: 'EL MONTE',
        RegAddrState: 'CA',
        RegAddrZip: 91732,
        CongressionalDistrict: 32,
      },
      {
        RegAddrLine1: '1203 BIG DALTON AVE',
        RegAddrCity: 'LA PUENTE',
        RegAddrState: 'CA',
        RegAddrZip: 91746,
        CongressionalDistrict: 32,
      },
      {
        RegAddrLine1: '13703 BESS ST',
        RegAddrCity: 'LA PUENTE',
        RegAddrState: 'CA',
        RegAddrZip: 91746,
        CongressionalDistrict: 32,
      },
      {
        RegAddrLine1: '2609 BRYNWOOD PL',
        RegAddrCity: 'WEST COVINA',
        RegAddrState: 'CA',
        RegAddrZip: 91792,
        CongressionalDistrict: 32,
      },
    ],
  },
  {
    name: 'CA-33',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/CA-33?ref=gh-pages',
    state: 'CA',
    state_and_number: 'CA-33',
    zip: 90005,
    congressionalDistrict: 33,
    addressesInDistrict: [
      {
        RegAddrLine1: '1070 252ND ST',
        RegAddrCity: 'HARBOR CITY',
        RegAddrState: 'CA',
        RegAddrZip: 90710,
        CongressionalDistrict: 33,
      },
      {
        RegAddrLine1: '1716 N POINSETTIA AVE',
        RegAddrCity: 'MANHATTAN BCH',
        RegAddrState: 'CA',
        RegAddrZip: 90266,
        CongressionalDistrict: 33,
      },
      {
        RegAddrLine1: '3749 W 176TH ST',
        RegAddrCity: 'TORRANCE',
        RegAddrState: 'CA',
        RegAddrZip: 90504,
        CongressionalDistrict: 33,
      },
      {
        RegAddrLine1: '3803 ESPLANADE',
        RegAddrCity: 'VENICE',
        RegAddrState: 'CA',
        RegAddrZip: 90292,
        CongressionalDistrict: 33,
      },
    ],
  },
  {
    name: 'CA-34',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/CA-34?ref=gh-pages',
    state: 'CA',
    state_and_number: 'CA-34',
    zip: 90006,
    congressionalDistrict: 34,
    addressesInDistrict: [
      {
        RegAddrLine1: '3677 ROLLE ST',
        RegAddrCity: 'LOS ANGELES',
        RegAddrState: 'CA',
        RegAddrZip: 90031,
        CongressionalDistrict: 34,
      },
      {
        RegAddrLine1: '1025 LARKER AVE',
        RegAddrCity: 'LOS ANGELES',
        RegAddrState: 'CA',
        RegAddrZip: 90042,
        CongressionalDistrict: 34,
      },
    ],
  },
  {
    name: 'CA-35',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/CA-35?ref=gh-pages',
    state: 'CA',
    state_and_number: 'CA-35',
    zip: 91708,
    congressionalDistrict: 35,
    addressesInDistrict: [
      {
        RegAddrLine1: '6680 OHARE CT',
        RegAddrCity: 'FONTANA',
        RegAddrState: 'CA',
        RegAddrZip: 92336,
        CongressionalDistrict: 35,
      },
      {
        RegAddrLine1: '7663 PALMETTO AVE',
        RegAddrCity: 'FONTANA',
        RegAddrState: 'CA',
        RegAddrZip: 92336,
        CongressionalDistrict: 35,
      },
      {
        RegAddrLine1: '2121 ALLSTON ST',
        RegAddrCity: 'MONTEBELLO',
        RegAddrState: 'CA',
        RegAddrZip: 90640,
        CongressionalDistrict: 35,
      },
      {
        RegAddrLine1: '432 W ORANGE GROVE AVE',
        RegAddrCity: 'POMONA',
        RegAddrState: 'CA',
        RegAddrZip: 91768,
        CongressionalDistrict: 35,
      },
      {
        RegAddrLine1: '2693 BARJUD AVE',
        RegAddrCity: 'POMONA',
        RegAddrState: 'CA',
        RegAddrZip: 91768,
        CongressionalDistrict: 35,
      },
    ],
  },
  {
    name: 'CA-36',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/CA-36?ref=gh-pages',
    state: 'CA',
    state_and_number: 'CA-36',
    zip: 92201,
    congressionalDistrict: 36,
    addressesInDistrict: [
      {
        RegAddrLine1: '943 PARK AVE',
        RegAddrCity: 'CALIMESA',
        RegAddrState: 'CA',
        RegAddrZip: 92320,
        CongressionalDistrict: 36,
      },
      {
        RegAddrLine1: '67855 PALETERO RD',
        RegAddrCity: 'CATHEDRAL CITY',
        RegAddrState: 'CA',
        RegAddrZip: 92234,
        CongressionalDistrict: 36,
      },
      {
        RegAddrLine1: '25987 BAYLOR WAY',
        RegAddrCity: 'HEMET',
        RegAddrState: 'CA',
        RegAddrZip: 92544,
        CongressionalDistrict: 36,
      },
      {
        RegAddrLine1: '75425 WILDFLOWER LN',
        RegAddrCity: 'PALM DESERT',
        RegAddrState: 'CA',
        RegAddrZip: 92211,
        CongressionalDistrict: 36,
      },
      {
        RegAddrLine1: '1648 TURQUESA DR',
        RegAddrCity: 'SAN JACINTO',
        RegAddrState: 'CA',
        RegAddrZip: 92583,
        CongressionalDistrict: 36,
      },
    ],
  },
  {
    name: 'CA-37',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/CA-37?ref=gh-pages',
    state: 'CA',
    state_and_number: 'CA-37',
    zip: 90003,
    congressionalDistrict: 37,
    addressesInDistrict: [
      {
        RegAddrLine1: '1549 S CURSON AVE',
        RegAddrCity: 'LOS ANGELES',
        RegAddrState: 'CA',
        RegAddrZip: 90019,
        CongressionalDistrict: 37,
      },
      {
        RegAddrLine1: '1676 COLBY AVE',
        RegAddrCity: 'LOS ANGELES',
        RegAddrState: 'CA',
        RegAddrZip: 90025,
        CongressionalDistrict: 37,
      },
      {
        RegAddrLine1: '1255 W 81ST ST',
        RegAddrCity: 'LOS ANGELES',
        RegAddrState: 'CA',
        RegAddrZip: 90044,
        CongressionalDistrict: 37,
      },
    ],
  },
  {
    name: 'CA-38',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/CA-38?ref=gh-pages',
    state: 'CA',
    state_and_number: 'CA-38',
    zip: 90241,
    congressionalDistrict: 38,
    addressesInDistrict: [
      {
        RegAddrLine1: '9202 PARK ST',
        RegAddrCity: 'BELLFLOWER',
        RegAddrState: 'CA',
        RegAddrZip: 90706,
        CongressionalDistrict: 38,
      },
      {
        RegAddrLine1: '9143 OLIVE ST',
        RegAddrCity: 'BELLFLOWER',
        RegAddrState: 'CA',
        RegAddrZip: 90706,
        CongressionalDistrict: 38,
      },
      {
        RegAddrLine1: '20121 PLAZA DE MADRID',
        RegAddrCity: 'CERRITOS',
        RegAddrState: 'CA',
        RegAddrZip: 90703,
        CongressionalDistrict: 38,
      },
      {
        RegAddrLine1: '6138 MCKNIGHT DR',
        RegAddrCity: 'LAKEWOOD',
        RegAddrState: 'CA',
        RegAddrZip: 90713,
        CongressionalDistrict: 38,
      },
      {
        RegAddrLine1: '1324 S SPRUCE ST',
        RegAddrCity: 'MONTEBELLO',
        RegAddrState: 'CA',
        RegAddrZip: 90640,
        CongressionalDistrict: 38,
      },
      {
        RegAddrLine1: '13865 MAR VISTA ST',
        RegAddrCity: 'WHITTIER',
        RegAddrState: 'CA',
        RegAddrZip: 90602,
        CongressionalDistrict: 38,
      },
      {
        RegAddrLine1: '16211 RUSSELL ST',
        RegAddrCity: 'WHITTIER',
        RegAddrState: 'CA',
        RegAddrZip: 90603,
        CongressionalDistrict: 38,
      },
      {
        RegAddrLine1: '8404 CATALINA AVE',
        RegAddrCity: 'WHITTIER',
        RegAddrState: 'CA',
        RegAddrZip: 90605,
        CongressionalDistrict: 38,
      },
    ],
  },
  {
    name: 'CA-39',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/CA-39?ref=gh-pages',
    state: 'CA',
    state_and_number: 'CA-39',
    zip: 90621,
    congressionalDistrict: 39,
    addressesInDistrict: [
      {
        RegAddrLine1: '4868 ARGYLE DR',
        RegAddrCity: 'BUENA PARK',
        RegAddrState: 'CA',
        RegAddrZip: 90621,
        CongressionalDistrict: 39,
      },
      {
        RegAddrLine1: '23633 SUNSET CROSSING RD',
        RegAddrCity: 'DIAMOND BAR',
        RegAddrState: 'CA',
        RegAddrZip: 91765,
        CongressionalDistrict: 39,
      },
      {
        RegAddrLine1: '1343 MISTY CT',
        RegAddrCity: 'DIAMOND BAR',
        RegAddrState: 'CA',
        RegAddrZip: 91765,
        CongressionalDistrict: 39,
      },
      {
        RegAddrLine1: '809 E UNION AVE',
        RegAddrCity: 'FULLERTON',
        RegAddrState: 'CA',
        RegAddrZip: 92831,
        CongressionalDistrict: 39,
      },
      {
        RegAddrLine1: '1436 W WEST AVE',
        RegAddrCity: 'FULLERTON',
        RegAddrState: 'CA',
        RegAddrZip: 92833,
        CongressionalDistrict: 39,
      },
      {
        RegAddrLine1: '1520 9TH AVE',
        RegAddrCity: 'HACIENDA HTS',
        RegAddrState: 'CA',
        RegAddrZip: 91745,
        CongressionalDistrict: 39,
      },
      {
        RegAddrLine1: '23916 OLD POMEGRANATE RD',
        RegAddrCity: 'YORBA LINDA',
        RegAddrState: 'CA',
        RegAddrZip: 92887,
        CongressionalDistrict: 39,
      },
    ],
  },
  {
    name: 'CA-4',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/CA-4?ref=gh-pages',
    state: 'CA',
    state_and_number: 'CA-4',
    zip: 93601,
    congressionalDistrict: 4,
    addressesInDistrict: [
      {
        RegAddrLine1: '1528 LILAC DR',
        RegAddrCity: 'ARNOLD',
        RegAddrState: 'CA',
        RegAddrZip: 95223,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '30071 STETSON DR',
        RegAddrCity: 'COARSEGOLD',
        RegAddrState: 'CA',
        RegAddrZip: 93614,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '146 GREENSTONE CT',
        RegAddrCity: 'COPPEROPOLIS',
        RegAddrState: 'CA',
        RegAddrZip: 95228,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '3470 SHERWOOD CT',
        RegAddrCity: 'LOOMIS',
        RegAddrState: 'CA',
        RegAddrZip: 95650,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '5472 DAISY DR',
        RegAddrCity: 'POLLOCK PINES',
        RegAddrState: 'CA',
        RegAddrZip: 95726,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1900 BRANDING IRON WAY',
        RegAddrCity: 'ROSEVILLE',
        RegAddrState: 'CA',
        RegAddrZip: 95661,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2007 NICKLAUS CIR',
        RegAddrCity: 'ROSEVILLE',
        RegAddrState: 'CA',
        RegAddrZip: 95678,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2588 WATERFORD GLEN CIR',
        RegAddrCity: 'ROSEVILLE',
        RegAddrState: 'CA',
        RegAddrZip: 95747,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '19240 ROCKRIDGE WAY',
        RegAddrCity: 'SONORA',
        RegAddrState: 'CA',
        RegAddrZip: 95370,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '15545 SHAKE RIDGE RD',
        RegAddrCity: 'SUTTER CREEK',
        RegAddrState: 'CA',
        RegAddrZip: 95685,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '15615 KENT DR',
        RegAddrCity: 'TRUCKEE',
        RegAddrState: 'CA',
        RegAddrZip: 96161,
        CongressionalDistrict: 4,
      },
    ],
  },
  {
    name: 'CA-40',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/CA-40?ref=gh-pages',
    state: 'CA',
    state_and_number: 'CA-40',
    zip: 90001,
    congressionalDistrict: 40,
    addressesInDistrict: [
      {
        RegAddrLine1: '4310 MORGAN AVE',
        RegAddrCity: 'LOS ANGELES',
        RegAddrState: 'CA',
        RegAddrZip: 90011,
        CongressionalDistrict: 40,
      },
      {
        RegAddrLine1: '1305 SIMMONS AVE',
        RegAddrCity: 'LOS ANGELES',
        RegAddrState: 'CA',
        RegAddrZip: 90022,
        CongressionalDistrict: 40,
      },
    ],
  },
  {
    name: 'CA-41',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/CA-41?ref=gh-pages',
    state: 'CA',
    state_and_number: 'CA-41',
    zip: 91752,
    congressionalDistrict: 41,
    addressesInDistrict: [
      {
        RegAddrLine1: '8781 LAKEVIEW AVE',
        RegAddrCity: 'JURUPA VALLEY',
        RegAddrState: 'CA',
        RegAddrZip: 92509,
        CongressionalDistrict: 41,
      },
      {
        RegAddrLine1: '10150 53RD ST',
        RegAddrCity: 'JURUPA VALLEY',
        RegAddrState: 'CA',
        RegAddrZip: 92509,
        CongressionalDistrict: 41,
      },
      {
        RegAddrLine1: '25527 RANCHO TIERRA DR',
        RegAddrCity: 'MORENO VALLEY',
        RegAddrState: 'CA',
        RegAddrZip: 92551,
        CongressionalDistrict: 41,
      },
      {
        RegAddrLine1: '28610 HIGHPOINT AVE',
        RegAddrCity: 'MORENO VALLEY',
        RegAddrState: 'CA',
        RegAddrZip: 92555,
        CongressionalDistrict: 41,
      },
      {
        RegAddrLine1: '19970 GAVILAN RD',
        RegAddrCity: 'PERRIS',
        RegAddrState: 'CA',
        RegAddrZip: 92570,
        CongressionalDistrict: 41,
      },
      {
        RegAddrLine1: '4083 MCKENZIE ST',
        RegAddrCity: 'RIVERSIDE',
        RegAddrState: 'CA',
        RegAddrZip: 92503,
        CongressionalDistrict: 41,
      },
      {
        RegAddrLine1: '3606 MUIR ST',
        RegAddrCity: 'RIVERSIDE',
        RegAddrState: 'CA',
        RegAddrZip: 92503,
        CongressionalDistrict: 41,
      },
    ],
  },
  {
    name: 'CA-42',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/CA-42?ref=gh-pages',
    state: 'CA',
    state_and_number: 'CA-42',
    zip: 92530,
    congressionalDistrict: 42,
    addressesInDistrict: [
      {
        RegAddrLine1: '13673 TURF PARADISE ST',
        RegAddrCity: 'EASTVALE',
        RegAddrState: 'CA',
        RegAddrZip: 92880,
        CongressionalDistrict: 42,
      },
      {
        RegAddrLine1: '14295 BALLAD DR',
        RegAddrCity: 'EASTVALE',
        RegAddrState: 'CA',
        RegAddrZip: 92880,
        CongressionalDistrict: 42,
      },
      {
        RegAddrLine1: '29511 OLYMPIC DR',
        RegAddrCity: 'MENIFEE',
        RegAddrState: 'CA',
        RegAddrZip: 92585,
        CongressionalDistrict: 42,
      },
      {
        RegAddrLine1: '42180 IVY ST',
        RegAddrCity: 'MURRIETA',
        RegAddrState: 'CA',
        RegAddrZip: 92562,
        CongressionalDistrict: 42,
      },
      {
        RegAddrLine1: '25472 DAY LILY DR',
        RegAddrCity: 'MURRIETA',
        RegAddrState: 'CA',
        RegAddrZip: 92563,
        CongressionalDistrict: 42,
      },
      {
        RegAddrLine1: '15700 PRAIRIE WAY',
        RegAddrCity: 'RIVERSIDE',
        RegAddrState: 'CA',
        RegAddrZip: 92508,
        CongressionalDistrict: 42,
      },
    ],
  },
  {
    name: 'CA-43',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/CA-43?ref=gh-pages',
    state: 'CA',
    state_and_number: 'CA-43',
    zip: 90002,
    congressionalDistrict: 43,
    addressesInDistrict: [
      {
        RegAddrLine1: '211 E 87TH ST',
        RegAddrCity: 'LOS ANGELES',
        RegAddrState: 'CA',
        RegAddrZip: 90003,
        CongressionalDistrict: 43,
      },
    ],
  },
  {
    name: 'CA-44',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/CA-44?ref=gh-pages',
    state: 'CA',
    state_and_number: 'CA-44',
    zip: 90220,
    congressionalDistrict: 44,
    addressesInDistrict: [
      {
        RegAddrLine1: '414 E 184TH ST',
        RegAddrCity: 'CARSON',
        RegAddrState: 'CA',
        RegAddrZip: 90746,
        CongressionalDistrict: 44,
      },
      {
        RegAddrLine1: '936 E HELMICK ST',
        RegAddrCity: 'CARSON',
        RegAddrState: 'CA',
        RegAddrZip: 90746,
        CongressionalDistrict: 44,
      },
      {
        RegAddrLine1: '700 W LAUREL ST',
        RegAddrCity: 'COMPTON',
        RegAddrState: 'CA',
        RegAddrZip: 90220,
        CongressionalDistrict: 44,
      },
      {
        RegAddrLine1: '1033 E HARDING ST',
        RegAddrCity: 'LONG BEACH',
        RegAddrState: 'CA',
        RegAddrZip: 90805,
        CongressionalDistrict: 44,
      },
      {
        RegAddrLine1: '1259 E 124TH ST',
        RegAddrCity: 'LOS ANGELES',
        RegAddrState: 'CA',
        RegAddrZip: 90059,
        CongressionalDistrict: 44,
      },
      {
        RegAddrLine1: '11650 ESTHER ST',
        RegAddrCity: 'LYNWOOD',
        RegAddrState: 'CA',
        RegAddrZip: 90262,
        CongressionalDistrict: 44,
      },
      {
        RegAddrLine1: '5343 BATAVIA RD',
        RegAddrCity: 'SOUTH GATE',
        RegAddrState: 'CA',
        RegAddrZip: 90280,
        CongressionalDistrict: 44,
      },
      {
        RegAddrLine1: '10237 SAN LUIS AVE',
        RegAddrCity: 'SOUTH GATE',
        RegAddrState: 'CA',
        RegAddrZip: 90280,
        CongressionalDistrict: 44,
      },
    ],
  },
  {
    name: 'CA-45',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/CA-45?ref=gh-pages',
    state: 'CA',
    state_and_number: 'CA-45',
    zip: 92602,
    congressionalDistrict: 45,
    addressesInDistrict: [
      {
        RegAddrLine1: '21 ALAMITOS',
        RegAddrCity: 'FOOTHILL RNCH',
        RegAddrState: 'CA',
        RegAddrZip: 92610,
        CongressionalDistrict: 45,
      },
      {
        RegAddrLine1: '8 EAGLE PT',
        RegAddrCity: 'IRVINE',
        RegAddrState: 'CA',
        RegAddrZip: 92604,
        CongressionalDistrict: 45,
      },
      {
        RegAddrLine1: '58 SETON RD',
        RegAddrCity: 'IRVINE',
        RegAddrState: 'CA',
        RegAddrZip: 92612,
        CongressionalDistrict: 45,
      },
      {
        RegAddrLine1: '25461 LA HABRA CT',
        RegAddrCity: 'LAGUNA HILLS',
        RegAddrState: 'CA',
        RegAddrZip: 92653,
        CongressionalDistrict: 45,
      },
      {
        RegAddrLine1: '21071 KNIGHTS GLN',
        RegAddrCity: 'LAKE FOREST',
        RegAddrState: 'CA',
        RegAddrZip: 92630,
        CongressionalDistrict: 45,
      },
      {
        RegAddrLine1: '27111 PACIFIC HEIGHTS DR',
        RegAddrCity: 'MISSION VIEJO',
        RegAddrState: 'CA',
        RegAddrZip: 92692,
        CongressionalDistrict: 45,
      },
      {
        RegAddrLine1: '1801 WINDSOR LN',
        RegAddrCity: 'SANTA ANA',
        RegAddrState: 'CA',
        RegAddrZip: 92705,
        CongressionalDistrict: 45,
      },
    ],
  },
  {
    name: 'CA-46',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/CA-46?ref=gh-pages',
    state: 'CA',
    state_and_number: 'CA-46',
    zip: 90680,
    congressionalDistrict: 46,
    addressesInDistrict: [
      {
        RegAddrLine1: '1624 E WILLOW ST',
        RegAddrCity: 'ANAHEIM',
        RegAddrState: 'CA',
        RegAddrZip: 92805,
        CongressionalDistrict: 46,
      },
      {
        RegAddrLine1: '2455 E WESTPORT CIR',
        RegAddrCity: 'ANAHEIM',
        RegAddrState: 'CA',
        RegAddrZip: 92806,
        CongressionalDistrict: 46,
      },
      {
        RegAddrLine1: '378 S TUSTIN ST',
        RegAddrCity: 'ORANGE',
        RegAddrState: 'CA',
        RegAddrZip: 92866,
        CongressionalDistrict: 46,
      },
      {
        RegAddrLine1: '2021 W 10TH ST',
        RegAddrCity: 'SANTA ANA',
        RegAddrState: 'CA',
        RegAddrZip: 92703,
        CongressionalDistrict: 46,
      },
      {
        RegAddrLine1: '2321 MONICA LN',
        RegAddrCity: 'SANTA ANA',
        RegAddrState: 'CA',
        RegAddrZip: 92706,
        CongressionalDistrict: 46,
      },
    ],
  },
  {
    name: 'CA-47',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/CA-47?ref=gh-pages',
    state: 'CA',
    state_and_number: 'CA-47',
    zip: 90704,
    congressionalDistrict: 47,
    addressesInDistrict: [
      {
        RegAddrLine1: '4903 EMBASSY WAY',
        RegAddrCity: 'CYPRESS',
        RegAddrState: 'CA',
        RegAddrZip: 90630,
        CongressionalDistrict: 47,
      },
      {
        RegAddrLine1: '3541 LILLY AVE',
        RegAddrCity: 'LONG BEACH',
        RegAddrState: 'CA',
        RegAddrZip: 90808,
        CongressionalDistrict: 47,
      },
      {
        RegAddrLine1: '1521 RAMILLO AVE',
        RegAddrCity: 'LONG BEACH',
        RegAddrState: 'CA',
        RegAddrZip: 90815,
        CongressionalDistrict: 47,
      },
      {
        RegAddrLine1: '13611 CHESTNUT ST',
        RegAddrCity: 'WESTMINSTER',
        RegAddrState: 'CA',
        RegAddrZip: 92683,
        CongressionalDistrict: 47,
      },
    ],
  },
  {
    name: 'CA-48',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/CA-48?ref=gh-pages',
    state: 'CA',
    state_and_number: 'CA-48',
    zip: 90742,
    congressionalDistrict: 48,
    addressesInDistrict: [
      {
        RegAddrLine1: '20381 NEWLAND ST',
        RegAddrCity: 'HUNTINGTON BEACH',
        RegAddrState: 'CA',
        RegAddrZip: 92646,
        CongressionalDistrict: 48,
      },
      {
        RegAddrLine1: '8 PONDERS END',
        RegAddrCity: 'LAGUNA NIGUEL',
        RegAddrState: 'CA',
        RegAddrZip: 92677,
        CongressionalDistrict: 48,
      },
      {
        RegAddrLine1: '24135 RUE DE CEZANNE',
        RegAddrCity: 'LAGUNA NIGUEL',
        RegAddrState: 'CA',
        RegAddrZip: 92677,
        CongressionalDistrict: 48,
      },
      {
        RegAddrLine1: '817 S ELLIOTT PL',
        RegAddrCity: 'SANTA ANA',
        RegAddrState: 'CA',
        RegAddrZip: 92704,
        CongressionalDistrict: 48,
      },
      {
        RegAddrLine1: '9371 JENNRICH AVE',
        RegAddrCity: 'WESTMINSTER',
        RegAddrState: 'CA',
        RegAddrZip: 92683,
        CongressionalDistrict: 48,
      },
    ],
  },
  {
    name: 'CA-49',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/CA-49?ref=gh-pages',
    state: 'CA',
    state_and_number: 'CA-49',
    zip: 92003,
    congressionalDistrict: 49,
    addressesInDistrict: [
      {
        RegAddrLine1: '6427 ALEXANDRI CIR',
        RegAddrCity: 'CARLSBAD',
        RegAddrState: 'CA',
        RegAddrZip: 92011,
        CongressionalDistrict: 49,
      },
      {
        RegAddrLine1: '24622 HARBOR VIEW DR',
        RegAddrCity: 'DANA POINT',
        RegAddrState: 'CA',
        RegAddrZip: 92629,
        CongressionalDistrict: 49,
      },
      {
        RegAddrLine1: '351 13TH ST',
        RegAddrCity: 'DEL MAR',
        RegAddrState: 'CA',
        RegAddrZip: 92014,
        CongressionalDistrict: 49,
      },
      {
        RegAddrLine1: '101 SMART CT',
        RegAddrCity: 'ENCINITAS',
        RegAddrState: 'CA',
        RegAddrZip: 92024,
        CongressionalDistrict: 49,
      },
      {
        RegAddrLine1: '1412 CRESTHAVEN PL',
        RegAddrCity: 'OCEANSIDE',
        RegAddrState: 'CA',
        RegAddrZip: 92056,
        CongressionalDistrict: 49,
      },
      {
        RegAddrLine1: '722 FREDRICKS AVE',
        RegAddrCity: 'OCEANSIDE',
        RegAddrState: 'CA',
        RegAddrZip: 92058,
        CongressionalDistrict: 49,
      },
      {
        RegAddrLine1: '5804 CAMINO NUBES',
        RegAddrCity: 'SAN CLEMENTE',
        RegAddrState: 'CA',
        RegAddrZip: 92673,
        CongressionalDistrict: 49,
      },
      {
        RegAddrLine1: '373 APOLLO DR',
        RegAddrCity: 'VISTA',
        RegAddrState: 'CA',
        RegAddrZip: 92084,
        CongressionalDistrict: 49,
      },
    ],
  },
  {
    name: 'CA-5',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/CA-5?ref=gh-pages',
    state: 'CA',
    state_and_number: 'CA-5',
    zip: 94503,
    congressionalDistrict: 5,
    addressesInDistrict: [
      {
        RegAddrLine1: '184 MEADOWLARK WAY',
        RegAddrCity: 'HERCULES',
        RegAddrState: 'CA',
        RegAddrZip: 94547,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '173 PALERMO CT',
        RegAddrCity: 'HERCULES',
        RegAddrState: 'CA',
        RegAddrZip: 94547,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '811 ROBINSON ST',
        RegAddrCity: 'MARTINEZ',
        RegAddrState: 'CA',
        RegAddrZip: 94553,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '1465 CAROL DR',
        RegAddrCity: 'NAPA',
        RegAddrState: 'CA',
        RegAddrZip: 94558,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '2472 REDWOOD RD',
        RegAddrCity: 'NAPA',
        RegAddrState: 'CA',
        RegAddrZip: 94558,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '4346 HERITAGE LN',
        RegAddrCity: 'ROHNERT PARK',
        RegAddrState: 'CA',
        RegAddrZip: 94928,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '1436 ESCALERO RD',
        RegAddrCity: 'SANTA ROSA',
        RegAddrState: 'CA',
        RegAddrZip: 95409,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '119 LIGHTHOUSE DR',
        RegAddrCity: 'VALLEJO',
        RegAddrState: 'CA',
        RegAddrZip: 94590,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '123 RODGERS ST',
        RegAddrCity: 'VALLEJO',
        RegAddrState: 'CA',
        RegAddrZip: 94590,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '156 ROUNDHILL CT',
        RegAddrCity: 'VALLEJO',
        RegAddrState: 'CA',
        RegAddrZip: 94591,
        CongressionalDistrict: 5,
      },
    ],
  },
  {
    name: 'CA-50',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/CA-50?ref=gh-pages',
    state: 'CA',
    state_and_number: 'CA-50',
    zip: 91901,
    congressionalDistrict: 50,
    addressesInDistrict: [
      {
        RegAddrLine1: '2138 PASEO DONITO',
        RegAddrCity: 'ALPINE',
        RegAddrState: 'CA',
        RegAddrZip: 91901,
        CongressionalDistrict: 50,
      },
      {
        RegAddrLine1: '11664 VIA JACQUELINA',
        RegAddrCity: 'EL CAJON',
        RegAddrState: 'CA',
        RegAddrZip: 92019,
        CongressionalDistrict: 50,
      },
      {
        RegAddrLine1: '999000 RES PALA',
        RegAddrCity: 'PALA',
        RegAddrState: 'CA',
        RegAddrZip: 92059,
        CongressionalDistrict: 50,
      },
      {
        RegAddrLine1: '25111 COMO ESTA CT',
        RegAddrCity: 'RAMONA',
        RegAddrState: 'CA',
        RegAddrZip: 92065,
        CongressionalDistrict: 50,
      },
      {
        RegAddrLine1: '217 PETUNIA CT',
        RegAddrCity: 'SAN MARCOS',
        RegAddrState: 'CA',
        RegAddrZip: 92069,
        CongressionalDistrict: 50,
      },
      {
        RegAddrLine1: '428 SILVER SHADOW DR',
        RegAddrCity: 'SAN MARCOS',
        RegAddrState: 'CA',
        RegAddrZip: 92078,
        CongressionalDistrict: 50,
      },
      {
        RegAddrLine1: '9803 GANDY AVE',
        RegAddrCity: 'SANTEE',
        RegAddrState: 'CA',
        RegAddrZip: 92071,
        CongressionalDistrict: 50,
      },
      {
        RegAddrLine1: '32183 CAMINITO OSUNA',
        RegAddrCity: 'TEMECULA',
        RegAddrState: 'CA',
        RegAddrZip: 92592,
        CongressionalDistrict: 50,
      },
    ],
  },
  {
    name: 'CA-51',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/CA-51?ref=gh-pages',
    state: 'CA',
    state_and_number: 'CA-51',
    zip: 91902,
    congressionalDistrict: 51,
    addressesInDistrict: [
      {
        RegAddrLine1: '916 CORRAL CT',
        RegAddrCity: 'BRAWLEY',
        RegAddrState: 'CA',
        RegAddrZip: 92227,
        CongressionalDistrict: 51,
      },
      {
        RegAddrLine1: '1823 D AVE',
        RegAddrCity: 'NATIONAL CITY',
        RegAddrState: 'CA',
        RegAddrZip: 91950,
        CongressionalDistrict: 51,
      },
      {
        RegAddrLine1: '1638 THERMAL AVE',
        RegAddrCity: 'SAN DIEGO',
        RegAddrState: 'CA',
        RegAddrZip: 92154,
        CongressionalDistrict: 51,
      },
    ],
  },
  {
    name: 'CA-52',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/CA-52?ref=gh-pages',
    state: 'CA',
    state_and_number: 'CA-52',
    zip: 92106,
    congressionalDistrict: 52,
    addressesInDistrict: [
      {
        RegAddrLine1: '8594 VILLA LA JOLLA DR',
        RegAddrCity: 'LA JOLLA',
        RegAddrState: 'CA',
        RegAddrZip: 92037,
        CongressionalDistrict: 52,
      },
      {
        RegAddrLine1: '13440 MONTEGO DR',
        RegAddrCity: 'POWAY',
        RegAddrState: 'CA',
        RegAddrZip: 92064,
        CongressionalDistrict: 52,
      },
      {
        RegAddrLine1: '4609 BRIGHTON AVE',
        RegAddrCity: 'SAN DIEGO',
        RegAddrState: 'CA',
        RegAddrZip: 92107,
        CongressionalDistrict: 52,
      },
      {
        RegAddrLine1: '8823 PROMENADE NORTH PL',
        RegAddrCity: 'SAN DIEGO',
        RegAddrState: 'CA',
        RegAddrZip: 92123,
        CongressionalDistrict: 52,
      },
      {
        RegAddrLine1: '5628 ANTIGUA BLVD',
        RegAddrCity: 'SAN DIEGO',
        RegAddrState: 'CA',
        RegAddrZip: 92124,
        CongressionalDistrict: 52,
      },
      {
        RegAddrLine1: '3990 DEL MAR MDWS',
        RegAddrCity: 'SAN DIEGO',
        RegAddrState: 'CA',
        RegAddrZip: 92130,
        CongressionalDistrict: 52,
      },
      {
        RegAddrLine1: '12869 BAYWIND PT',
        RegAddrCity: 'SAN DIEGO',
        RegAddrState: 'CA',
        RegAddrZip: 92130,
        CongressionalDistrict: 52,
      },
    ],
  },
  {
    name: 'CA-53',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/CA-53?ref=gh-pages',
    state: 'CA',
    state_and_number: 'CA-53',
    zip: 91913,
    congressionalDistrict: 53,
    addressesInDistrict: [
      {
        RegAddrLine1: '1567 CUMBRE VW',
        RegAddrCity: 'BONITA',
        RegAddrState: 'CA',
        RegAddrZip: 91902,
        CongressionalDistrict: 53,
      },
      {
        RegAddrLine1: '2294 ALICANTE CV',
        RegAddrCity: 'CHULA VISTA',
        RegAddrState: 'CA',
        RegAddrZip: 91914,
        CongressionalDistrict: 53,
      },
      {
        RegAddrLine1: '2864 LYTTON CREEK CT',
        RegAddrCity: 'CHULA VISTA',
        RegAddrState: 'CA',
        RegAddrZip: 91915,
        CongressionalDistrict: 53,
      },
      {
        RegAddrLine1: '4025 CORTE TIERRA ALTA',
        RegAddrCity: 'LA MESA',
        RegAddrState: 'CA',
        RegAddrZip: 91941,
        CongressionalDistrict: 53,
      },
      {
        RegAddrLine1: '6416 STANLEY AVE',
        RegAddrCity: 'SAN DIEGO',
        RegAddrState: 'CA',
        RegAddrZip: 92115,
        CongressionalDistrict: 53,
      },
      {
        RegAddrLine1: '4575 ALICE ST',
        RegAddrCity: 'SAN DIEGO',
        RegAddrState: 'CA',
        RegAddrZip: 92115,
        CongressionalDistrict: 53,
      },
      {
        RegAddrLine1: '4811 LOUKELTON WAY',
        RegAddrCity: 'SAN DIEGO',
        RegAddrState: 'CA',
        RegAddrZip: 92120,
        CongressionalDistrict: 53,
      },
      {
        RegAddrLine1: '2561 CALLE GAVIOTA',
        RegAddrCity: 'SAN DIEGO',
        RegAddrState: 'CA',
        RegAddrZip: 92139,
        CongressionalDistrict: 53,
      },
      {
        RegAddrLine1: '8673 LAMAR ST',
        RegAddrCity: 'SPRING VALLEY',
        RegAddrState: 'CA',
        RegAddrZip: 91977,
        CongressionalDistrict: 53,
      },
    ],
  },
  {
    name: 'CA-6',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/CA-6?ref=gh-pages',
    state: 'CA',
    state_and_number: 'CA-6',
    zip: 95621,
    congressionalDistrict: 6,
    addressesInDistrict: [
      {
        RegAddrLine1: '2854 58TH ST',
        RegAddrCity: 'SACRAMENTO',
        RegAddrState: 'CA',
        RegAddrZip: 95817,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '5217 ARGO WAY',
        RegAddrCity: 'SACRAMENTO',
        RegAddrState: 'CA',
        RegAddrZip: 95820,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '4710 LARGE OAK CT',
        RegAddrCity: 'SACRAMENTO',
        RegAddrState: 'CA',
        RegAddrZip: 95841,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '6904 STANFORD OAK DR',
        RegAddrCity: 'SACRAMENTO',
        RegAddrState: 'CA',
        RegAddrZip: 95842,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '3701 GALIANO ISLAND ST',
        RegAddrCity: 'W SACRAMENTO',
        RegAddrState: 'CA',
        RegAddrZip: 95691,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '3969 TAHOE ST',
        RegAddrCity: 'WEST SACRAMENTO',
        RegAddrState: 'CA',
        RegAddrZip: 95691,
        CongressionalDistrict: 6,
      },
    ],
  },
  {
    name: 'CA-7',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/CA-7?ref=gh-pages',
    state: 'CA',
    state_and_number: 'CA-7',
    zip: 95608,
    congressionalDistrict: 7,
    addressesInDistrict: [
      {
        RegAddrLine1: '8425 TERRACOTTA CIR',
        RegAddrCity: 'ELK GROVE',
        RegAddrState: 'CA',
        RegAddrZip: 95624,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '972 DOLAN CT',
        RegAddrCity: 'FOLSOM',
        RegAddrState: 'CA',
        RegAddrZip: 95630,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '446 ROYAL CREST CIR',
        RegAddrCity: 'RNCHO CORDOVA',
        RegAddrState: 'CA',
        RegAddrZip: 95670,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '10544 COLOMA RD',
        RegAddrCity: 'RNCHO CORDOVA',
        RegAddrState: 'CA',
        RegAddrZip: 95670,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '14789 VALLERO WAY',
        RegAddrCity: 'RNCHO MURIETA',
        RegAddrState: 'CA',
        RegAddrZip: 95683,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '7541 HAZELNUT LN',
        RegAddrCity: 'SACRAMENTO',
        RegAddrState: 'CA',
        RegAddrZip: 95828,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '7434 PROSPERITY CT',
        RegAddrCity: 'SACRAMENTO',
        RegAddrState: 'CA',
        RegAddrZip: 95828,
        CongressionalDistrict: 7,
      },
    ],
  },
  {
    name: 'CA-8',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/CA-8?ref=gh-pages',
    state: 'CA',
    state_and_number: 'CA-8',
    zip: 89010,
    congressionalDistrict: 8,
    addressesInDistrict: [
      {
        RegAddrLine1: '10551 GREEN VALLEY RD',
        RegAddrCity: 'APPLE VALLEY',
        RegAddrState: 'CA',
        RegAddrZip: 92308,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '605 CLARK ST',
        RegAddrCity: 'BARSTOW',
        RegAddrState: 'CA',
        RegAddrZip: 92311,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '904 ANN ST',
        RegAddrCity: 'BARSTOW',
        RegAddrState: 'CA',
        RegAddrZip: 92311,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '11458 7TH AVE',
        RegAddrCity: 'HESPERIA',
        RegAddrState: 'CA',
        RegAddrZip: 92345,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '19154 LINDSAY ST',
        RegAddrCity: 'HESPERIA',
        RegAddrState: 'CA',
        RegAddrZip: 92345,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '34870 COUNTY LINE RD',
        RegAddrCity: 'YUCAIPA',
        RegAddrState: 'CA',
        RegAddrZip: 92399,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '58381 BONANZA DR',
        RegAddrCity: 'YUCCA VALLEY',
        RegAddrState: 'CA',
        RegAddrZip: 92284,
        CongressionalDistrict: 8,
      },
    ],
  },
  {
    name: 'CA-9',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/CA-9?ref=gh-pages',
    state: 'CA',
    state_and_number: 'CA-9',
    zip: 94505,
    congressionalDistrict: 9,
    addressesInDistrict: [
      {
        RegAddrLine1: '4691 CHRISTENSON ST',
        RegAddrCity: 'BRENTWOOD',
        RegAddrState: 'CA',
        RegAddrZip: 94513,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '155 STARVIEW CT',
        RegAddrCity: 'BRENTWOOD',
        RegAddrState: 'CA',
        RegAddrZip: 94513,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '16682 LOGANBERRY WAY',
        RegAddrCity: 'LATHROP',
        RegAddrState: 'CA',
        RegAddrZip: 95330,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '1909 N SIERRA NEVADA ST',
        RegAddrCity: 'STOCKTON',
        RegAddrState: 'CA',
        RegAddrZip: 95205,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '2102 SWAINSONS HAWK ST',
        RegAddrCity: 'STOCKTON',
        RegAddrState: 'CA',
        RegAddrZip: 95209,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '8930 HILARY LN',
        RegAddrCity: 'STOCKTON',
        RegAddrState: 'CA',
        RegAddrZip: 95212,
        CongressionalDistrict: 9,
      },
    ],
  },
  {
    name: 'CO-1',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/CO-1?ref=gh-pages',
    state: 'CO',
    state_and_number: 'CO-1',
    zip: 80010,
    congressionalDistrict: 1,
    addressesInDistrict: [
      {
        RegAddrLine1: '6055 W PROGRESS AVE',
        RegAddrCity: 'DENVER',
        RegAddrState: 'CO',
        RegAddrZip: 80123,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2911 N SAINT PAUL ST',
        RegAddrCity: 'DENVER',
        RegAddrState: 'CO',
        RegAddrZip: 80205,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2615 POPLAR ST',
        RegAddrCity: 'DENVER',
        RegAddrState: 'CO',
        RegAddrZip: 80207,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1058 S RACE ST',
        RegAddrCity: 'DENVER',
        RegAddrState: 'CO',
        RegAddrZip: 80209,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2264 S MARION ST',
        RegAddrCity: 'DENVER',
        RegAddrState: 'CO',
        RegAddrZip: 80210,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1412 S GRANT ST',
        RegAddrCity: 'DENVER',
        RegAddrState: 'CO',
        RegAddrZip: 80210,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1408 S CLARKSON ST',
        RegAddrCity: 'DENVER',
        RegAddrState: 'CO',
        RegAddrZip: 80210,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '3355 E MONTANA PL',
        RegAddrCity: 'DENVER',
        RegAddrState: 'CO',
        RegAddrZip: 80210,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '3733 N CLAY ST',
        RegAddrCity: 'DENVER',
        RegAddrState: 'CO',
        RegAddrZip: 80211,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '3059 ZUNI ST',
        RegAddrCity: 'DENVER',
        RegAddrState: 'CO',
        RegAddrZip: 80211,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '4009 W 29TH AVE',
        RegAddrCity: 'DENVER',
        RegAddrState: 'CO',
        RegAddrZip: 80212,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1535 N HIGH ST',
        RegAddrCity: 'DENVER',
        RegAddrState: 'CO',
        RegAddrZip: 80218,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2641 S TENNYSON WAY',
        RegAddrCity: 'DENVER',
        RegAddrState: 'CO',
        RegAddrZip: 80219,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1085 S CLAY ST',
        RegAddrCity: 'DENVER',
        RegAddrState: 'CO',
        RegAddrZip: 80219,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '4855 HOOKER ST',
        RegAddrCity: 'DENVER',
        RegAddrState: 'CO',
        RegAddrZip: 80221,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '550 ALTON WAY',
        RegAddrCity: 'DENVER',
        RegAddrState: 'CO',
        RegAddrZip: 80230,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '9167 E FLOYD PL',
        RegAddrCity: 'DENVER',
        RegAddrState: 'CO',
        RegAddrZip: 80231,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2760 S NEWTON WAY',
        RegAddrCity: 'DENVER',
        RegAddrState: 'CO',
        RegAddrZip: 80236,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '4093 S ROSEMARY WAY',
        RegAddrCity: 'DENVER',
        RegAddrState: 'CO',
        RegAddrZip: 80237,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '8604 E 28TH AVE',
        RegAddrCity: 'DENVER',
        RegAddrState: 'CO',
        RegAddrZip: 80238,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '10891 E 28TH PL',
        RegAddrCity: 'DENVER',
        RegAddrState: 'CO',
        RegAddrZip: 80238,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '8645 E 49TH PL',
        RegAddrCity: 'DENVER',
        RegAddrState: 'CO',
        RegAddrZip: 80238,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2528 AKRON ST',
        RegAddrCity: 'DENVER',
        RegAddrState: 'CO',
        RegAddrZip: 80238,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '3596 AKRON ST',
        RegAddrCity: 'DENVER',
        RegAddrState: 'CO',
        RegAddrZip: 80238,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1205 S GRAPE ST',
        RegAddrCity: 'DENVER',
        RegAddrState: 'CO',
        RegAddrZip: 80246,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '20362 E 43RD AVE',
        RegAddrCity: 'DENVER',
        RegAddrState: 'CO',
        RegAddrZip: 80249,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '34280 COLUMBINE TRL E',
        RegAddrCity: 'ELIZABETH',
        RegAddrState: 'CO',
        RegAddrZip: 80107,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '4260 S BANNOCK ST',
        RegAddrCity: 'ENGLEWOOD',
        RegAddrState: 'CO',
        RegAddrZip: 80110,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '3346 W GRAND AVE',
        RegAddrCity: 'ENGLEWOOD',
        RegAddrState: 'CO',
        RegAddrZip: 80110,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '5421 NASSAU CIR E',
        RegAddrCity: 'ENGLEWOOD',
        RegAddrState: 'CO',
        RegAddrZip: 80113,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '9682 S DUDLEY WAY',
        RegAddrCity: 'LITTLETON',
        RegAddrState: 'CO',
        RegAddrZip: 80127,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '9796 W MORRAINE AVE',
        RegAddrCity: 'LITTLETON',
        RegAddrState: 'CO',
        RegAddrZip: 80127,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '10732 W BERRY AVE',
        RegAddrCity: 'LITTLETON',
        RegAddrState: 'CO',
        RegAddrZip: 80127,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '7828 S MARSHALL ST',
        RegAddrCity: 'LITTLETON',
        RegAddrState: 'CO',
        RegAddrZip: 80128,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '8588 W TOLLER AVE',
        RegAddrCity: 'LITTLETON',
        RegAddrState: 'CO',
        RegAddrZip: 80128,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '9419 W ONTARIO DR',
        RegAddrCity: 'LITTLETON',
        RegAddrState: 'CO',
        RegAddrZip: 80128,
        CongressionalDistrict: 1,
      },
    ],
  },
  {
    name: 'CO-2',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/CO-2?ref=gh-pages',
    state: 'CO',
    state_and_number: 'CO-2',
    zip: 80007,
    congressionalDistrict: 2,
    addressesInDistrict: [
      {
        RegAddrLine1: '207 CONESTOGA RD',
        RegAddrCity: 'BAILEY',
        RegAddrState: 'CO',
        RegAddrZip: 80421,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '811 HOLMES PL',
        RegAddrCity: 'BERTHOUD',
        RegAddrState: 'CO',
        RegAddrZip: 80513,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '440 GREGORY LN',
        RegAddrCity: 'BOULDER',
        RegAddrState: 'CO',
        RegAddrZip: 80302,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1527 PINE ST',
        RegAddrCity: 'BOULDER',
        RegAddrState: 'CO',
        RegAddrZip: 80302,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '3015 9TH ST',
        RegAddrCity: 'BOULDER',
        RegAddrState: 'CO',
        RegAddrZip: 80304,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1141 QUINCE AVE',
        RegAddrCity: 'BOULDER',
        RegAddrState: 'CO',
        RegAddrZip: 80304,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2842 10TH ST',
        RegAddrCity: 'BOULDER',
        RegAddrState: 'CO',
        RegAddrZip: 80304,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '3961 W 134TH PL',
        RegAddrCity: 'BROOMFIELD',
        RegAddrState: 'CO',
        RegAddrZip: 80020,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1310 W 13TH AVE',
        RegAddrCity: 'BROOMFIELD',
        RegAddrState: 'CO',
        RegAddrZip: 80020,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '4350 CRESTONE CIR',
        RegAddrCity: 'BROOMFIELD',
        RegAddrState: 'CO',
        RegAddrZip: 80023,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '25227 RED CLOUD DR',
        RegAddrCity: 'CONIFER',
        RegAddrState: 'CO',
        RegAddrZip: 80433,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '357 VAIL CIR',
        RegAddrCity: 'DILLON',
        RegAddrState: 'CO',
        RegAddrZip: 80435,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '336 N MAIN ST',
        RegAddrCity: 'EMPIRE',
        RegAddrState: 'CO',
        RegAddrZip: 80438,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '5368 EVERGREEN HEIGHTS DR',
        RegAddrCity: 'EVERGREEN',
        RegAddrState: 'CO',
        RegAddrZip: 80439,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2400 EVERGREEN DR',
        RegAddrCity: 'FORT COLLINS',
        RegAddrState: 'CO',
        RegAddrZip: 80521,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '360 NEWSOM HALL',
        RegAddrCity: 'FORT COLLINS',
        RegAddrState: 'CO',
        RegAddrZip: 80521,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1805 VALLEY VIEW LN',
        RegAddrCity: 'FORT COLLINS',
        RegAddrState: 'CO',
        RegAddrZip: 80524,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '623 LESSER DR',
        RegAddrCity: 'FORT COLLINS',
        RegAddrState: 'CO',
        RegAddrZip: 80524,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2725 MAROON CT',
        RegAddrCity: 'FORT COLLINS',
        RegAddrState: 'CO',
        RegAddrZip: 80525,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '300 STARBOARD CT',
        RegAddrCity: 'FORT COLLINS',
        RegAddrState: 'CO',
        RegAddrZip: 80525,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2630 WALKALOOSA WAY',
        RegAddrCity: 'FORT COLLINS',
        RegAddrState: 'CO',
        RegAddrZip: 80525,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1318 WINFIELD DR',
        RegAddrCity: 'FORT COLLINS',
        RegAddrState: 'CO',
        RegAddrZip: 80526,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2504 CONSTITUTION AVE',
        RegAddrCity: 'FORT COLLINS',
        RegAddrState: 'CO',
        RegAddrZip: 80526,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '26252 CENTER DR',
        RegAddrCity: 'KITTREDGE',
        RegAddrState: 'CO',
        RegAddrZip: 80457,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '480 VERNIER CT',
        RegAddrCity: 'LAFAYETTE',
        RegAddrState: 'CO',
        RegAddrZip: 80026,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '4 SILVERBERRY',
        RegAddrCity: 'LITTLETON',
        RegAddrState: 'CO',
        RegAddrZip: 80127,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '5650 STEEPLECHASE DR',
        RegAddrCity: 'LONGMONT',
        RegAddrState: 'CO',
        RegAddrZip: 80503,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '225 S LARK AVE',
        RegAddrCity: 'LOUISVILLE',
        RegAddrState: 'CO',
        RegAddrZip: 80027,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1008 LA FARGE AVE',
        RegAddrCity: 'LOUISVILLE',
        RegAddrState: 'CO',
        RegAddrZip: 80027,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '330 OWL DR',
        RegAddrCity: 'LOUISVILLE',
        RegAddrState: 'CO',
        RegAddrZip: 80027,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1560 ZINC ST',
        RegAddrCity: 'LOVELAND',
        RegAddrState: 'CO',
        RegAddrZip: 80537,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '300 42ND ST SW',
        RegAddrCity: 'LOVELAND',
        RegAddrState: 'CO',
        RegAddrZip: 80537,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1362 SW22ND ST',
        RegAddrCity: 'LOVELAND',
        RegAddrState: 'CO',
        RegAddrZip: 80537,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1702 3RD ST SW',
        RegAddrCity: 'LOVELAND',
        RegAddrState: 'CO',
        RegAddrZip: 80537,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '6743 HOMER RD',
        RegAddrCity: 'LOVELAND',
        RegAddrState: 'CO',
        RegAddrZip: 80537,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '4060 PLUM CREEK DR',
        RegAddrCity: 'LOVELAND',
        RegAddrState: 'CO',
        RegAddrZip: 80538,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1710 ESTRELLA AVE',
        RegAddrCity: 'LOVELAND',
        RegAddrState: 'CO',
        RegAddrZip: 80538,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '4451 S DEFRAME ST',
        RegAddrCity: 'MORRISON',
        RegAddrState: 'CO',
        RegAddrZip: 80465,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2425 CLAYTON CIR',
        RegAddrCity: 'SUPERIOR',
        RegAddrState: 'CO',
        RegAddrZip: 80027,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '6146 PRYOR RD',
        RegAddrCity: 'TIMNATH',
        RegAddrState: 'CO',
        RegAddrZip: 80547,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '4190 ALDER CREEK LN',
        RegAddrCity: 'WELLINGTON',
        RegAddrState: 'CO',
        RegAddrZip: 80549,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '3524 GARFIELD AVE',
        RegAddrCity: 'WELLINGTON',
        RegAddrState: 'CO',
        RegAddrZip: 80549,
        CongressionalDistrict: 2,
      },
    ],
  },
  {
    name: 'CO-3',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/CO-3?ref=gh-pages',
    state: 'CO',
    state_and_number: 'CO-3',
    zip: 80426,
    congressionalDistrict: 3,
    addressesInDistrict: [
      {
        RegAddrLine1: '148 SAN LUIS AVE',
        RegAddrCity: 'ALAMOSA',
        RegAddrState: 'CO',
        RegAddrZip: 81101,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '4941 COUNTY ROAD 8 S',
        RegAddrCity: 'ALAMOSA',
        RegAddrState: 'CO',
        RegAddrZip: 81101,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '252 WILLOW LN',
        RegAddrCity: 'CARBONDALE',
        RegAddrState: 'CO',
        RegAddrZip: 81623,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '19587 SURFACE CREEK RD',
        RegAddrCity: 'CEDAREDGE',
        RegAddrState: 'CO',
        RegAddrZip: 81413,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '4336 CHAFFEE DR',
        RegAddrCity: 'COLORADO CITY',
        RegAddrState: 'CO',
        RegAddrZip: 81019,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '4466 MUSTANG DR',
        RegAddrCity: 'COLORADO CITY',
        RegAddrState: 'CO',
        RegAddrZip: 81019,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '232 S LINDEN ST',
        RegAddrCity: 'CORTEZ',
        RegAddrState: 'CO',
        RegAddrZip: 81321,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '43179 COTTONWOOD CREEK RD',
        RegAddrCity: 'CRAWFORD',
        RegAddrState: 'CO',
        RegAddrZip: 81415,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '48 HIGHLAND PL',
        RegAddrCity: 'DURANGO',
        RegAddrState: 'CO',
        RegAddrZip: 81301,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '2606 BORREGO DR',
        RegAddrCity: 'DURANGO',
        RegAddrState: 'CO',
        RegAddrZip: 81301,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '236 E HERITAGE CT',
        RegAddrCity: 'FRUITA',
        RegAddrState: 'CO',
        RegAddrZip: 81521,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1533 1/2 CRAWFORD WAY',
        RegAddrCity: 'GLENWOOD SPGS',
        RegAddrState: 'CO',
        RegAddrZip: 81601,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1623 BENNETT AVE',
        RegAddrCity: 'GLENWOOD SPGS',
        RegAddrState: 'CO',
        RegAddrZip: 81601,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '597 N 20TH ST',
        RegAddrCity: 'GRAND JCT',
        RegAddrState: 'CO',
        RegAddrZip: 81501,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '531 N 23RD ST',
        RegAddrCity: 'GRAND JCT',
        RegAddrState: 'CO',
        RegAddrZip: 81501,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '288 CONCORD LN',
        RegAddrCity: 'GRAND JCT',
        RegAddrState: 'CO',
        RegAddrZip: 81503,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '877 22ND RD',
        RegAddrCity: 'GRAND JCT',
        RegAddrState: 'CO',
        RegAddrZip: 81505,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '820 KENNEDY AVE',
        RegAddrCity: 'GRAND JUNCTION',
        RegAddrState: 'CO',
        RegAddrZip: 81501,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '754 CONTINENTAL CT',
        RegAddrCity: 'GRAND JUNCTION',
        RegAddrState: 'CO',
        RegAddrZip: 81506,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '4135 COUNTY ROAD 510',
        RegAddrCity: 'IGNACIO',
        RegAddrState: 'CO',
        RegAddrZip: 81137,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1920 COUNTY ROAD 8',
        RegAddrCity: 'LEADVILLE',
        RegAddrState: 'CO',
        RegAddrZip: 80461,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '2066 16TH RD',
        RegAddrCity: 'LOMA',
        RegAddrState: 'CO',
        RegAddrZip: 81524,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1024 P RD',
        RegAddrCity: 'MACK',
        RegAddrState: 'CO',
        RegAddrZip: 81525,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1855 6TH RD',
        RegAddrCity: 'MACK',
        RegAddrState: 'CO',
        RegAddrZip: 81525,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '430 WAGON WHEEL CIR',
        RegAddrCity: 'NEW CASTLE',
        RegAddrState: 'CO',
        RegAddrZip: 81647,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '515 S 3RD ST',
        RegAddrCity: 'OLATHE',
        RegAddrState: 'CO',
        RegAddrZip: 81425,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '21236 LANNING RD',
        RegAddrCity: 'ORCHARD CITY',
        RegAddrState: 'CO',
        RegAddrZip: 81410,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '8880 W HIGHWAY 160',
        RegAddrCity: 'PAGOSA SPGS',
        RegAddrState: 'CO',
        RegAddrZip: 81147,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '3 CORNFLOWER CT',
        RegAddrCity: 'PUEBLO',
        RegAddrState: 'CO',
        RegAddrZip: 81001,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1438 BONFORTE BLVD',
        RegAddrCity: 'PUEBLO',
        RegAddrState: 'CO',
        RegAddrZip: 81001,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '409 ACERO AVE',
        RegAddrCity: 'PUEBLO',
        RegAddrState: 'CO',
        RegAddrZip: 81004,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '2540 RIDGEWOOD LN',
        RegAddrCity: 'PUEBLO',
        RegAddrState: 'CO',
        RegAddrZip: 81005,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '694 E HERON DR',
        RegAddrCity: 'PUEBLO WEST',
        RegAddrState: 'CO',
        RegAddrZip: 81007,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '218 W HARMONY DR',
        RegAddrCity: 'PUEBLO WEST',
        RegAddrState: 'CO',
        RegAddrZip: 81007,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '619 W COLUMBIA',
        RegAddrCity: 'TELLURIDE',
        RegAddrState: 'CO',
        RegAddrZip: 81435,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '747 W PACIFIC 424',
        RegAddrCity: 'TELLURIDE',
        RegAddrState: 'CO',
        RegAddrZip: 81435,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '867 VISTA DE ASPEN RD',
        RegAddrCity: 'WESTCLIFFE',
        RegAddrState: 'CO',
        RegAddrZip: 81252,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '6054 BELLYACHE RIDGE RD',
        RegAddrCity: 'WOLCOTT',
        RegAddrState: 'CO',
        RegAddrZip: 81655,
        CongressionalDistrict: 3,
      },
    ],
  },
  {
    name: 'CO-4',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/CO-4?ref=gh-pages',
    state: 'CO',
    state_and_number: 'CO-4',
    zip: 67879,
    congressionalDistrict: 4,
    addressesInDistrict: [
      {
        RegAddrLine1: '72 JENNIFER CIR',
        RegAddrCity: 'BRUSH',
        RegAddrState: 'CO',
        RegAddrZip: 80723,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '158 N SHERMAN ST',
        RegAddrCity: 'BYERS',
        RegAddrState: 'CO',
        RegAddrZip: 80103,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '10751 COUNTY ROAD 46',
        RegAddrCity: 'CAMPO',
        RegAddrState: 'CO',
        RegAddrZip: 81029,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '5684 AMBER RIDGE DR',
        RegAddrCity: 'CASTLE PINES',
        RegAddrState: 'CO',
        RegAddrZip: 80108,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '550 LEICESTER LN',
        RegAddrCity: 'CASTLE PINES',
        RegAddrState: 'CO',
        RegAddrZip: 80108,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '307 BENTON ST',
        RegAddrCity: 'CASTLE ROCK',
        RegAddrState: 'CO',
        RegAddrZip: 80104,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1898 OAKCREST CIR',
        RegAddrCity: 'CASTLE ROCK',
        RegAddrState: 'CO',
        RegAddrZip: 80104,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1265 BLACK HAWK RD',
        RegAddrCity: 'EATON',
        RegAddrState: 'CO',
        RegAddrZip: 80615,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '36411 PARADISE CIR',
        RegAddrCity: 'ELIZABETH',
        RegAddrState: 'CO',
        RegAddrZip: 80107,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '202 PIPIT LAKE WAY',
        RegAddrCity: 'ERIE',
        RegAddrState: 'CO',
        RegAddrZip: 80516,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '4762 BETTY PL',
        RegAddrCity: 'ERIE',
        RegAddrState: 'CO',
        RegAddrZip: 80516,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2418 HAVEN CT',
        RegAddrCity: 'EVANS',
        RegAddrState: 'CO',
        RegAddrZip: 80620,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '216 E 9TH ST',
        RegAddrCity: 'FLAGLER',
        RegAddrState: 'CO',
        RegAddrZip: 80815,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1007 PLATTE DR',
        RegAddrCity: 'FORT LUPTON',
        RegAddrState: 'CO',
        RegAddrZip: 80621,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '705 LINCOLN ST',
        RegAddrCity: 'FORT MORGAN',
        RegAddrState: 'CO',
        RegAddrZip: 80701,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '5789 W CONSERVATION DR',
        RegAddrCity: 'FREDERICK',
        RegAddrState: 'CO',
        RegAddrZip: 80504,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '109 1ST AVE',
        RegAddrCity: 'GREELEY',
        RegAddrState: 'CO',
        RegAddrZip: 80631,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1320 5TH ST',
        RegAddrCity: 'GREELEY',
        RegAddrState: 'CO',
        RegAddrZip: 80631,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2019 26TH AVE',
        RegAddrCity: 'GREELEY',
        RegAddrState: 'CO',
        RegAddrZip: 80634,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '3830 E FOX TRL',
        RegAddrCity: 'GREENWOOD VLG',
        RegAddrState: 'CO',
        RegAddrZip: 80121,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '43520 COUNTY ROAD 24',
        RegAddrCity: 'HOLYOKE',
        RegAddrState: 'CO',
        RegAddrZip: 80734,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '322 E 3RD ST',
        RegAddrCity: 'JULESBURG',
        RegAddrState: 'CO',
        RegAddrZip: 80737,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '394 SHASTA CT',
        RegAddrCity: 'KIOWA',
        RegAddrState: 'CO',
        RegAddrZip: 80117,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '10007 WESTSIDE CIR',
        RegAddrCity: 'LITTLETON',
        RegAddrState: 'CO',
        RegAddrZip: 80125,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '10196 WESTSIDE CT',
        RegAddrCity: 'LITTLETON',
        RegAddrState: 'CO',
        RegAddrZip: 80125,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1000 TOWNLEY CIR',
        RegAddrCity: 'LONGMONT',
        RegAddrState: 'CO',
        RegAddrZip: 80501,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '7 CEDAR CT',
        RegAddrCity: 'LONGMONT',
        RegAddrState: 'CO',
        RegAddrZip: 80503,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '3716 STAGHORN DR',
        RegAddrCity: 'LONGMONT',
        RegAddrState: 'CO',
        RegAddrZip: 80503,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '711 SNOWBERRY ST',
        RegAddrCity: 'LONGMONT',
        RegAddrState: 'CO',
        RegAddrZip: 80503,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '822 SNOWBERRY ST',
        RegAddrCity: 'LONGMONT',
        RegAddrState: 'CO',
        RegAddrZip: 80503,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '409 ARKANSAS AVE',
        RegAddrCity: 'ORDWAY',
        RegAddrState: 'CO',
        RegAddrZip: 81063,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '15051 CHICAGO ST',
        RegAddrCity: 'PARKER',
        RegAddrState: 'CO',
        RegAddrZip: 80134,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '17042 NUMA PL',
        RegAddrCity: 'PARKER',
        RegAddrState: 'CO',
        RegAddrZip: 80134,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '10315 KETTERING LN',
        RegAddrCity: 'PARKER',
        RegAddrState: 'CO',
        RegAddrZip: 80134,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '10749 BLACK FOREST DR',
        RegAddrCity: 'PARKER',
        RegAddrState: 'CO',
        RegAddrZip: 80138,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '22348 EAGLE RUN LN',
        RegAddrCity: 'PARKER',
        RegAddrState: 'CO',
        RegAddrZip: 80138,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '3624 CHRISTY RIDGE RD',
        RegAddrCity: 'SEDALIA',
        RegAddrState: 'CO',
        RegAddrZip: 80135,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '205 RUTH AVE',
        RegAddrCity: 'SEVERANCE',
        RegAddrState: 'CO',
        RegAddrZip: 80546,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '4402 INDIGO DR',
        RegAddrCity: 'SEVERANCE',
        RegAddrState: 'CO',
        RegAddrZip: 80550,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '12781 DESERT DR',
        RegAddrCity: 'STERLING',
        RegAddrState: 'CO',
        RegAddrZip: 80751,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '709 MONROE ST',
        RegAddrCity: 'TRINIDAD',
        RegAddrState: 'CO',
        RegAddrZip: 81082,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '109 OAK ST',
        RegAddrCity: 'WINDSOR',
        RegAddrState: 'CO',
        RegAddrZip: 80550,
        CongressionalDistrict: 4,
      },
    ],
  },
  {
    name: 'CO-5',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/CO-5?ref=gh-pages',
    state: 'CO',
    state_and_number: 'CO-5',
    zip: 80132,
    congressionalDistrict: 5,
    addressesInDistrict: [
      {
        RegAddrLine1: '222 S GUNNISON AVE',
        RegAddrCity: 'BUENA VISTA',
        RegAddrState: 'CO',
        RegAddrZip: 81211,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '130 STEINMEIER AVE',
        RegAddrCity: 'CANON CITY',
        RegAddrState: 'CO',
        RegAddrZip: 81212,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '1006 MILKY WAY',
        RegAddrCity: 'COLORADO SPGS',
        RegAddrState: 'CO',
        RegAddrZip: 80905,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '835 N WALNUT ST',
        RegAddrCity: 'COLORADO SPGS',
        RegAddrState: 'CO',
        RegAddrZip: 80905,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '120 ELLSWORTH ST',
        RegAddrCity: 'COLORADO SPGS',
        RegAddrState: 'CO',
        RegAddrZip: 80906,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '13455 FRANK RD',
        RegAddrCity: 'COLORADO SPGS',
        RegAddrState: 'CO',
        RegAddrZip: 80908,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '7154 CABALLERO AVE',
        RegAddrCity: 'COLORADO SPGS',
        RegAddrState: 'CO',
        RegAddrZip: 80911,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '6279 DANCING WATER DR',
        RegAddrCity: 'COLORADO SPGS',
        RegAddrState: 'CO',
        RegAddrZip: 80911,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '2285 ALLYN WAY',
        RegAddrCity: 'COLORADO SPGS',
        RegAddrState: 'CO',
        RegAddrZip: 80915,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '2010 S CHELTON RD',
        RegAddrCity: 'COLORADO SPGS',
        RegAddrState: 'CO',
        RegAddrZip: 80916,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '5920 DRIFTER ST',
        RegAddrCity: 'COLORADO SPGS',
        RegAddrState: 'CO',
        RegAddrZip: 80918,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '6020 APPLEWOOD RIDGE CIR',
        RegAddrCity: 'COLORADO SPGS',
        RegAddrState: 'CO',
        RegAddrZip: 80918,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '7589 SIERRA PINE DR',
        RegAddrCity: 'COLORADO SPGS',
        RegAddrState: 'CO',
        RegAddrZip: 80919,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '1115 POPES VALLEY DR',
        RegAddrCity: 'COLORADO SPGS',
        RegAddrState: 'CO',
        RegAddrZip: 80919,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '1575 VINE CLIFF HTS',
        RegAddrCity: 'COLORADO SPGS',
        RegAddrState: 'CO',
        RegAddrZip: 80921,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '3370 HEATHER GLEN DR',
        RegAddrCity: 'COLORADO SPGS',
        RegAddrState: 'CO',
        RegAddrZip: 80922,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '4644 HIDDEN RIVER DR',
        RegAddrCity: 'COLORADO SPGS',
        RegAddrState: 'CO',
        RegAddrZip: 80922,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '1110 PIROS DR',
        RegAddrCity: 'COLORADO SPGS',
        RegAddrState: 'CO',
        RegAddrZip: 80922,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '4509 BRIDLE PASS DR',
        RegAddrCity: 'COLORADO SPGS',
        RegAddrState: 'CO',
        RegAddrZip: 80923,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '7140 MIDNIGHT ROSE DR',
        RegAddrCity: 'COLORADO SPGS',
        RegAddrState: 'CO',
        RegAddrZip: 80923,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '8037 MOUNT HURON TRL',
        RegAddrCity: 'COLORADO SPGS',
        RegAddrState: 'CO',
        RegAddrZip: 80924,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '9212 LIZARD ROCK TRL',
        RegAddrCity: 'COLORADO SPGS',
        RegAddrState: 'CO',
        RegAddrZip: 80924,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '6696 KEARSARGE DR',
        RegAddrCity: 'COLORADO SPGS',
        RegAddrState: 'CO',
        RegAddrZip: 80925,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: 'CHELTON RD 2303',
        RegAddrCity: 'COLORADO SPRINGS',
        RegAddrState: 'CO',
        RegAddrZip: 80910,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '3320 YOUNGER CT',
        RegAddrCity: 'COLORADO SPRINGS',
        RegAddrState: 'CO',
        RegAddrZip: 80910,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '1801 HALLAM AVE',
        RegAddrCity: 'COLORADO SPRINGS',
        RegAddrState: 'CO',
        RegAddrZip: 80911,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '5402 LA PORTE DR',
        RegAddrCity: 'COLORADO SPRINGS',
        RegAddrState: 'CO',
        RegAddrZip: 80918,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '5521 GALENA PL',
        RegAddrCity: 'COLORADO SPRINGS',
        RegAddrState: 'CO',
        RegAddrZip: 80918,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '5330 VILLA CIR',
        RegAddrCity: 'COLORADO SPRINGS',
        RegAddrState: 'CO',
        RegAddrZip: 80918,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '1310 DANCING HORSE DR',
        RegAddrCity: 'COLORADO SPRINGS',
        RegAddrState: 'CO',
        RegAddrZip: 80919,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '2985 CINDERCONE LN',
        RegAddrCity: 'COLORADO SPRINGS',
        RegAddrState: 'CO',
        RegAddrZip: 80919,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '16110 HOMECREST CIR',
        RegAddrCity: 'ELBERT',
        RegAddrState: 'CO',
        RegAddrZip: 80106,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '315 HIGH MEADOWS DR',
        RegAddrCity: 'FLORENCE',
        RegAddrState: 'CO',
        RegAddrZip: 81226,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '901 WILSON DR',
        RegAddrCity: 'FLORISSANT',
        RegAddrState: 'CO',
        RegAddrZip: 80816,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '7758 SANDY SPRINGS PT',
        RegAddrCity: 'FOUNTAIN',
        RegAddrState: 'CO',
        RegAddrZip: 80817,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '970 WINEBROOK WAY',
        RegAddrCity: 'FOUNTAIN',
        RegAddrState: 'CO',
        RegAddrZip: 80817,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '131 VIA VALLECITO',
        RegAddrCity: 'MANITOU SPGS',
        RegAddrState: 'CO',
        RegAddrZip: 80829,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '25 W KINGS DEER PT',
        RegAddrCity: 'MONUMENT',
        RegAddrState: 'CO',
        RegAddrZip: 80132,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '1251 12TH ST',
        RegAddrCity: 'PENROSE',
        RegAddrState: 'CO',
        RegAddrZip: 81240,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '20765 WARRIORS PATH DR',
        RegAddrCity: 'PEYTON',
        RegAddrState: 'CO',
        RegAddrZip: 80831,
        CongressionalDistrict: 5,
      },
    ],
  },
  {
    name: 'CO-6',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/CO-6?ref=gh-pages',
    state: 'CO',
    state_and_number: 'CO-6',
    zip: 80017,
    congressionalDistrict: 6,
    addressesInDistrict: [
      {
        RegAddrLine1: '1601 NEWARK ST',
        RegAddrCity: 'AURORA',
        RegAddrState: 'CO',
        RegAddrZip: 80010,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '14569 E 25TH AVE',
        RegAddrCity: 'AURORA',
        RegAddrState: 'CO',
        RegAddrZip: 80011,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '590 QUENTIN ST',
        RegAddrCity: 'AURORA',
        RegAddrState: 'CO',
        RegAddrZip: 80011,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '1090 REVERE ST',
        RegAddrCity: 'AURORA',
        RegAddrState: 'CO',
        RegAddrZip: 80011,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '15033 E 11TH AVE',
        RegAddrCity: 'AURORA',
        RegAddrState: 'CO',
        RegAddrZip: 80011,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '950 S PARIS CT',
        RegAddrCity: 'AURORA',
        RegAddrState: 'CO',
        RegAddrZip: 80012,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '2702 S SALIDA WAY',
        RegAddrCity: 'AURORA',
        RegAddrState: 'CO',
        RegAddrZip: 80013,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '2515 S ANDES CIR',
        RegAddrCity: 'AURORA',
        RegAddrState: 'CO',
        RegAddrZip: 80013,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '19067 E HAMPDEN DR',
        RegAddrCity: 'AURORA',
        RegAddrState: 'CO',
        RegAddrZip: 80013,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '2859 S NEWARK PL',
        RegAddrCity: 'AURORA',
        RegAddrState: 'CO',
        RegAddrZip: 80014,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '2358 S RACINE WAY',
        RegAddrCity: 'AURORA',
        RegAddrState: 'CO',
        RegAddrZip: 80014,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '5531 S VALDAI WAY',
        RegAddrCity: 'AURORA',
        RegAddrState: 'CO',
        RegAddrZip: 80015,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '22904 E IDA AVE',
        RegAddrCity: 'AURORA',
        RegAddrState: 'CO',
        RegAddrZip: 80015,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '5239 S ROME ST',
        RegAddrCity: 'AURORA',
        RegAddrState: 'CO',
        RegAddrZip: 80015,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '8265 S IRELAND WAY',
        RegAddrCity: 'AURORA',
        RegAddrState: 'CO',
        RegAddrZip: 80016,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '1161 S JOPLIN ST',
        RegAddrCity: 'AURORA',
        RegAddrState: 'CO',
        RegAddrZip: 80017,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '5205 DAHLIA DR',
        RegAddrCity: 'BRIGHTON',
        RegAddrState: 'CO',
        RegAddrZip: 80601,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '154 CISNE CIR',
        RegAddrCity: 'BRIGHTON',
        RegAddrState: 'CO',
        RegAddrZip: 80601,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '1852 E 142ND AVE',
        RegAddrCity: 'BRIGHTON',
        RegAddrState: 'CO',
        RegAddrZip: 80602,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '19385 E PRENTICE LN',
        RegAddrCity: 'CENTENNIAL',
        RegAddrState: 'CO',
        RegAddrZip: 80015,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '5737 S QUINTERO CIR',
        RegAddrCity: 'CENTENNIAL',
        RegAddrState: 'CO',
        RegAddrZip: 80015,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '20197 E RED FOX LN',
        RegAddrCity: 'CENTENNIAL',
        RegAddrState: 'CO',
        RegAddrZip: 80015,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '6179 S KIRK ST',
        RegAddrCity: 'CENTENNIAL',
        RegAddrState: 'CO',
        RegAddrZip: 80016,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '8720 E OTERO CIR',
        RegAddrCity: 'CENTENNIAL',
        RegAddrState: 'CO',
        RegAddrZip: 80112,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '1855 E NOBLE PL',
        RegAddrCity: 'CENTENNIAL',
        RegAddrState: 'CO',
        RegAddrZip: 80121,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '7423 S WELLINGTON ST',
        RegAddrCity: 'CENTENNIAL',
        RegAddrState: 'CO',
        RegAddrZip: 80122,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '1236 ASCOT AVE',
        RegAddrCity: 'HGHLNDS RANCH',
        RegAddrState: 'CO',
        RegAddrZip: 80126,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '3971 GARNET CT',
        RegAddrCity: 'HGHLNDS RANCH',
        RegAddrState: 'CO',
        RegAddrZip: 80126,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '9430 COVE CREEK DR',
        RegAddrCity: 'HGHLNDS RANCH',
        RegAddrState: 'CO',
        RegAddrZip: 80129,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '10276 CHARISSGLEN CIR',
        RegAddrCity: 'HIGHLANDS RANCH',
        RegAddrState: 'CO',
        RegAddrZip: 80126,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '7801 S WINDERMERE CIR',
        RegAddrCity: 'LITTLETON',
        RegAddrState: 'CO',
        RegAddrZip: 80120,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '7618 S PENNSYLVANIA DR',
        RegAddrCity: 'LITTLETON',
        RegAddrState: 'CO',
        RegAddrZip: 80122,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '5822 S LOWELL WAY',
        RegAddrCity: 'LITTLETON',
        RegAddrState: 'CO',
        RegAddrZip: 80123,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '5225 E 130TH CIR',
        RegAddrCity: 'THORNTON',
        RegAddrState: 'CO',
        RegAddrZip: 80241,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '15003 COLUMBINE ST',
        RegAddrCity: 'THORNTON',
        RegAddrState: 'CO',
        RegAddrZip: 80602,
        CongressionalDistrict: 6,
      },
    ],
  },
  {
    name: 'CO-7',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/CO-7?ref=gh-pages',
    state: 'CO',
    state_and_number: 'CO-7',
    zip: 80002,
    congressionalDistrict: 7,
    addressesInDistrict: [
      {
        RegAddrLine1: '6145 GRAY ST',
        RegAddrCity: 'ARVADA',
        RegAddrState: 'CO',
        RegAddrZip: 80003,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '8283 DEPEW WAY',
        RegAddrCity: 'ARVADA',
        RegAddrState: 'CO',
        RegAddrZip: 80003,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '6135 W 75TH PL',
        RegAddrCity: 'ARVADA',
        RegAddrState: 'CO',
        RegAddrZip: 80003,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '7756 MARSHALL ST',
        RegAddrCity: 'ARVADA',
        RegAddrState: 'CO',
        RegAddrZip: 80003,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '7572 COORS CT',
        RegAddrCity: 'ARVADA',
        RegAddrState: 'CO',
        RegAddrZip: 80005,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '7432 SIMMS CT',
        RegAddrCity: 'ARVADA',
        RegAddrState: 'CO',
        RegAddrZip: 80005,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '15458 W 75TH PL',
        RegAddrCity: 'ARVADA',
        RegAddrState: 'CO',
        RegAddrZip: 80007,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '3053 REDSTONE LN',
        RegAddrCity: 'BOULDER',
        RegAddrState: 'CO',
        RegAddrZip: 80305,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '12159 VILLAGE CIR E',
        RegAddrCity: 'BRIGHTON',
        RegAddrState: 'CO',
        RegAddrZip: 80603,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '4929 SILVER FEATHER CIR',
        RegAddrCity: 'BROOMFIELD',
        RegAddrState: 'CO',
        RegAddrZip: 80023,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '8396 KRAMERIA ST',
        RegAddrCity: 'COMMERCE CITY',
        RegAddrState: 'CO',
        RegAddrZip: 80022,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '1380 W 71ST PL',
        RegAddrCity: 'DENVER',
        RegAddrState: 'CO',
        RegAddrZip: 80221,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '7119 GALAPAGO ST',
        RegAddrCity: 'DENVER',
        RegAddrState: 'CO',
        RegAddrZip: 80221,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '7778 ELLEN LN',
        RegAddrCity: 'DENVER',
        RegAddrState: 'CO',
        RegAddrZip: 80221,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '5501 SHOSHONE ST',
        RegAddrCity: 'DENVER',
        RegAddrState: 'CO',
        RegAddrZip: 80221,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '9644 GRAPE CT',
        RegAddrCity: 'DENVER',
        RegAddrState: 'CO',
        RegAddrZip: 80229,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '11425 IOLA ST',
        RegAddrCity: 'HENDERSON',
        RegAddrState: 'CO',
        RegAddrZip: 80640,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '651 REED ST',
        RegAddrCity: 'LAKEWOOD',
        RegAddrState: 'CO',
        RegAddrZip: 80214,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '2960 SIMMS DR',
        RegAddrCity: 'LAKEWOOD',
        RegAddrState: 'CO',
        RegAddrZip: 80215,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '10090 W 21ST PL',
        RegAddrCity: 'LAKEWOOD',
        RegAddrState: 'CO',
        RegAddrZip: 80215,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '9043 W WARREN DR',
        RegAddrCity: 'LAKEWOOD',
        RegAddrState: 'CO',
        RegAddrZip: 80227,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '13451 W OHIO DR',
        RegAddrCity: 'LAKEWOOD',
        RegAddrState: 'CO',
        RegAddrZip: 80228,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '12302 W IOWA DR',
        RegAddrCity: 'LAKEWOOD',
        RegAddrState: 'CO',
        RegAddrZip: 80228,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '6209 W MEXICO AVE',
        RegAddrCity: 'LAKEWOOD',
        RegAddrState: 'CO',
        RegAddrZip: 80232,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '6599 W OREGON AVE',
        RegAddrCity: 'LAKEWOOD',
        RegAddrState: 'CO',
        RegAddrZip: 80232,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '7482 W ALABAMA DR',
        RegAddrCity: 'LAKEWOOD',
        RegAddrState: 'CO',
        RegAddrZip: 80232,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '1026 E 114TH PL',
        RegAddrCity: 'NORTHGLENN',
        RegAddrState: 'CO',
        RegAddrZip: 80233,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '10151 MELODY DR',
        RegAddrCity: 'NORTHGLENN',
        RegAddrState: 'CO',
        RegAddrZip: 80260,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '9311 WELBY ROAD TER',
        RegAddrCity: 'THORNTON',
        RegAddrState: 'CO',
        RegAddrZip: 80229,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '9061 CORONA ST',
        RegAddrCity: 'THORNTON',
        RegAddrState: 'CO',
        RegAddrZip: 80229,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '8637 DOVER CT',
        RegAddrCity: 'WESTMINSTER',
        RegAddrState: 'CO',
        RegAddrZip: 80005,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '9851 XAVIER CT',
        RegAddrCity: 'WESTMINSTER',
        RegAddrState: 'CO',
        RegAddrZip: 80031,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '3296 W 102ND CIR',
        RegAddrCity: 'WESTMINSTER',
        RegAddrState: 'CO',
        RegAddrZip: 80031,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '4485 HARLAN CT',
        RegAddrCity: 'WHEAT RIDGE',
        RegAddrState: 'CO',
        RegAddrZip: 80033,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '2994 CHASE ST',
        RegAddrCity: 'WHEAT RIDGE',
        RegAddrState: 'CO',
        RegAddrZip: 80214,
        CongressionalDistrict: 7,
      },
    ],
  },
  {
    name: 'CT-1',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/CT-1?ref=gh-pages',
    state: 'CT',
    state_and_number: 'CT-1',
    zip: 1077,
    congressionalDistrict: 1,
    addressesInDistrict: [
      {
        RegAddrLine1: '162 PARK RD',
        RegAddrCity: 'BARKHAMSTED',
        RegAddrState: 'CT',
        RegAddrZip: 6063,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '590 WETHERSFIELD RD',
        RegAddrCity: 'BERLIN',
        RegAddrState: 'CT',
        RegAddrZip: 6037,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1253 WORTHINGTON RDG',
        RegAddrCity: 'BERLIN',
        RegAddrState: 'CT',
        RegAddrZip: 6037,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '561 PARK AVE',
        RegAddrCity: 'BLOOMFIELD',
        RegAddrState: 'CT',
        RegAddrZip: 6002,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '122 DARBY ST',
        RegAddrCity: 'BLOOMFIELD',
        RegAddrState: 'CT',
        RegAddrZip: 6002,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '22 COZY LN',
        RegAddrCity: 'BRISTOL',
        RegAddrState: 'CT',
        RegAddrZip: 6010,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '383 MORRIS AVE',
        RegAddrCity: 'BRISTOL',
        RegAddrState: 'CT',
        RegAddrZip: 6010,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '27 ISAMAY DR',
        RegAddrCity: 'BRISTOL',
        RegAddrState: 'CT',
        RegAddrZip: 6010,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '269 SUNNYDALE AVE',
        RegAddrCity: 'BRISTOL',
        RegAddrState: 'CT',
        RegAddrZip: 6010,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '255 ROBERTSON ST',
        RegAddrCity: 'BRISTOL',
        RegAddrState: 'CT',
        RegAddrZip: 6010,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '26 SUFFOLK PL',
        RegAddrCity: 'BRISTOL',
        RegAddrState: 'CT',
        RegAddrZip: 6010,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '18 BENHAM ST',
        RegAddrCity: 'BRISTOL',
        RegAddrState: 'CT',
        RegAddrZip: 6010,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '300 PERKINS ST',
        RegAddrCity: 'BRISTOL',
        RegAddrState: 'CT',
        RegAddrZip: 6010,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '116 S STREET EXT',
        RegAddrCity: 'BRISTOL',
        RegAddrState: 'CT',
        RegAddrZip: 6010,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '17 ADELINE AVE',
        RegAddrCity: 'BRISTOL',
        RegAddrState: 'CT',
        RegAddrZip: 6010,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '117 BUTTERNUT LN',
        RegAddrCity: 'BRISTOL',
        RegAddrState: 'CT',
        RegAddrZip: 6010,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '26 HIGHLAND AVE',
        RegAddrCity: 'BROAD BROOK',
        RegAddrState: 'CT',
        RegAddrZip: 6016,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '40 WINDSORVILLE RD',
        RegAddrCity: 'BROAD BROOK',
        RegAddrState: 'CT',
        RegAddrZip: 6016,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '76 WYNDING HILLS RD',
        RegAddrCity: 'EAST GRANBY',
        RegAddrState: 'CT',
        RegAddrZip: 6026,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '36 RICHARD RD',
        RegAddrCity: 'EAST HARTFORD',
        RegAddrState: 'CT',
        RegAddrZip: 6108,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '134 CHIPPER DR',
        RegAddrCity: 'EAST HARTFORD',
        RegAddrState: 'CT',
        RegAddrZip: 6108,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '52 BISSELL ST',
        RegAddrCity: 'EAST HARTFORD',
        RegAddrState: 'CT',
        RegAddrZip: 6108,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1966 MAIN ST',
        RegAddrCity: 'EAST HARTFORD',
        RegAddrState: 'CT',
        RegAddrZip: 6108,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '84 STERLING RD',
        RegAddrCity: 'EAST HARTFORD',
        RegAddrState: 'CT',
        RegAddrZip: 6108,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '121 CANNON RD',
        RegAddrCity: 'EAST HARTFORD',
        RegAddrState: 'CT',
        RegAddrZip: 6108,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '13 MERCER AVE',
        RegAddrCity: 'EAST HARTFORD',
        RegAddrState: 'CT',
        RegAddrZip: 6118,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1303 MEADOWVIEW DR',
        RegAddrCity: 'EAST WINDSOR',
        RegAddrState: 'CT',
        RegAddrZip: 6088,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '612 HAZARD AVE',
        RegAddrCity: 'ENFIELD',
        RegAddrState: 'CT',
        RegAddrZip: 6082,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '24 CANDLELIGHT DR',
        RegAddrCity: 'GLASTONBURY',
        RegAddrState: 'CT',
        RegAddrZip: 6033,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '19 RIDGECREST RD',
        RegAddrCity: 'GLASTONBURY',
        RegAddrState: 'CT',
        RegAddrZip: 6033,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '80 CANDLELIGHT DR',
        RegAddrCity: 'GLASTONBURY',
        RegAddrState: 'CT',
        RegAddrZip: 6033,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '26 HUBBARD RUN DR',
        RegAddrCity: 'GLASTONBURY',
        RegAddrState: 'CT',
        RegAddrZip: 6033,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '470 EASTBURY HILL RD',
        RegAddrCity: 'GLASTONBURY',
        RegAddrState: 'CT',
        RegAddrZip: 6033,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '164 WHITNEY ST',
        RegAddrCity: 'HARTFORD',
        RegAddrState: 'CT',
        RegAddrZip: 6105,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '71 MADISON AVE',
        RegAddrCity: 'HARTFORD',
        RegAddrState: 'CT',
        RegAddrZip: 6106,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '195 RETREAT AVE',
        RegAddrCity: 'HARTFORD',
        RegAddrState: 'CT',
        RegAddrZip: 6106,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '99 CHANDLER ST',
        RegAddrCity: 'HARTFORD',
        RegAddrState: 'CT',
        RegAddrZip: 6106,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '124 OAKLAND TER',
        RegAddrCity: 'HARTFORD',
        RegAddrState: 'CT',
        RegAddrZip: 6112,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '48 HEBRON ST',
        RegAddrCity: 'HARTFORD',
        RegAddrState: 'CT',
        RegAddrZip: 6112,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '166 BOND ST',
        RegAddrCity: 'HARTFORD',
        RegAddrState: 'CT',
        RegAddrZip: 6114,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '80 WETHERSFIELD AVE',
        RegAddrCity: 'HARTFORD',
        RegAddrState: 'CT',
        RegAddrZip: 6114,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '48 CROWN ST',
        RegAddrCity: 'HARTFORD',
        RegAddrState: 'CT',
        RegAddrZip: 6114,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '24 BARKER ST',
        RegAddrCity: 'HARTFORD',
        RegAddrState: 'CT',
        RegAddrZip: 6114,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '48 SPRING ST',
        RegAddrCity: 'KENSINGTON',
        RegAddrState: 'CT',
        RegAddrZip: 6037,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '76 ELLWOOD RD',
        RegAddrCity: 'KENSINGTON',
        RegAddrState: 'CT',
        RegAddrZip: 6037,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '25 E ELDRIDGE ST',
        RegAddrCity: 'MANCHESTER',
        RegAddrState: 'CT',
        RegAddrZip: 6040,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '50 DOUGHERTY ST',
        RegAddrCity: 'MANCHESTER',
        RegAddrState: 'CT',
        RegAddrZip: 6040,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '37 WESTWOOD ST',
        RegAddrCity: 'MANCHESTER',
        RegAddrState: 'CT',
        RegAddrZip: 6040,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '305 PORTER ST',
        RegAddrCity: 'MANCHESTER',
        RegAddrState: 'CT',
        RegAddrZip: 6040,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '20 KATHLEEN WAY',
        RegAddrCity: 'MANCHESTER',
        RegAddrState: 'CT',
        RegAddrZip: 6042,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '331 WOODBRIDGE ST',
        RegAddrCity: 'MANCHESTER',
        RegAddrState: 'CT',
        RegAddrZip: 6042,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '69 WESTERLY ST',
        RegAddrCity: 'MANCHESTER',
        RegAddrState: 'CT',
        RegAddrZip: 6042,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '218 UNION ST',
        RegAddrCity: 'MANCHESTER',
        RegAddrState: 'CT',
        RegAddrZip: 6042,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '80 COLUMBUS ST',
        RegAddrCity: 'MANCHESTER',
        RegAddrState: 'CT',
        RegAddrZip: 6042,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '84 HARLAN ST',
        RegAddrCity: 'MANCHESTER',
        RegAddrState: 'CT',
        RegAddrZip: 6042,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '3 EAGLE HOLLOW DR',
        RegAddrCity: 'MIDDLETOWN',
        RegAddrState: 'CT',
        RegAddrZip: 6457,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '38 RUSSELL RD',
        RegAddrCity: 'MILLDALE',
        RegAddrState: 'CT',
        RegAddrZip: 6467,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '90 LAKESHORE DR',
        RegAddrCity: 'NEW HARTFORD',
        RegAddrState: 'CT',
        RegAddrZip: 6057,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '93 ARROWHEAD DR',
        RegAddrCity: 'NEW HARTFORD',
        RegAddrState: 'CT',
        RegAddrZip: 6057,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '20 BARBERRY DR',
        RegAddrCity: 'NEW HARTFORD',
        RegAddrState: 'CT',
        RegAddrZip: 6057,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '92 FLAGLER ST',
        RegAddrCity: 'NEWINGTON',
        RegAddrState: 'CT',
        RegAddrZip: 6111,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '9 DEAN DR',
        RegAddrCity: 'NEWINGTON',
        RegAddrState: 'CT',
        RegAddrZip: 6111,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '253 LAMPLIGHTER LN',
        RegAddrCity: 'NEWINGTON',
        RegAddrState: 'CT',
        RegAddrZip: 6111,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '215 MARLBOROUGH ST',
        RegAddrCity: 'NEWINGTON',
        RegAddrState: 'CT',
        RegAddrZip: 6111,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '38 SUNRISE CIR',
        RegAddrCity: 'NEWINGTON',
        RegAddrState: 'CT',
        RegAddrZip: 6111,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '3 MARIE CIR',
        RegAddrCity: 'PORTLAND',
        RegAddrState: 'CT',
        RegAddrZip: 6480,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '20 TAYLOR DR',
        RegAddrCity: 'PORTLAND',
        RegAddrState: 'CT',
        RegAddrZip: 6480,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '44 HIGH ST',
        RegAddrCity: 'PORTLAND',
        RegAddrState: 'CT',
        RegAddrZip: 6480,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '78 HIGH ST',
        RegAddrCity: 'PORTLAND',
        RegAddrState: 'CT',
        RegAddrZip: 6480,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '109 PEPPERIDGE RD',
        RegAddrCity: 'PORTLAND',
        RegAddrState: 'CT',
        RegAddrZip: 6480,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2 WASHINGTON ST',
        RegAddrCity: 'ROCKY HILL',
        RegAddrState: 'CT',
        RegAddrZip: 6067,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '10 BIRCH RD',
        RegAddrCity: 'ROCKY HILL',
        RegAddrState: 'CT',
        RegAddrZip: 6067,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '35 B GRIMES RD 109',
        RegAddrCity: 'ROCKY HILL',
        RegAddrState: 'CT',
        RegAddrZip: 6067,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '507 AVERY ST',
        RegAddrCity: 'SOUTH WINDSOR',
        RegAddrState: 'CT',
        RegAddrZip: 6074,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '259 BROOKFIELD ST',
        RegAddrCity: 'SOUTH WINDSOR',
        RegAddrState: 'CT',
        RegAddrZip: 6074,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '9 AUSTIN CIR',
        RegAddrCity: 'SOUTH WINDSOR',
        RegAddrState: 'CT',
        RegAddrZip: 6074,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '31 OLD FARM RD',
        RegAddrCity: 'SOUTH WINDSOR',
        RegAddrState: 'CT',
        RegAddrZip: 6074,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '847 ELLINGTON RD',
        RegAddrCity: 'SOUTH WINDSOR',
        RegAddrState: 'CT',
        RegAddrZip: 6074,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '78 METACOMET VW',
        RegAddrCity: 'SOUTHINGTON',
        RegAddrState: 'CT',
        RegAddrZip: 6489,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '218 DEERBROOKE CIR',
        RegAddrCity: 'SOUTHINGTON',
        RegAddrState: 'CT',
        RegAddrZip: 6489,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '121 EDEN AVE',
        RegAddrCity: 'SOUTHINGTON',
        RegAddrState: 'CT',
        RegAddrZip: 6489,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '16 TAYLOR LN',
        RegAddrCity: 'SOUTHINGTON',
        RegAddrState: 'CT',
        RegAddrZip: 6489,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '84 MINTHAL DR',
        RegAddrCity: 'SOUTHINGTON',
        RegAddrState: 'CT',
        RegAddrZip: 6489,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '416 WOODRUFF ST',
        RegAddrCity: 'SOUTHINGTON',
        RegAddrState: 'CT',
        RegAddrZip: 6489,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '25 CHRISTINE DR',
        RegAddrCity: 'SOUTHINGTON',
        RegAddrState: 'CT',
        RegAddrZip: 6489,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '288 GINGER LN',
        RegAddrCity: 'TORRINGTON',
        RegAddrState: 'CT',
        RegAddrZip: 6790,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1360 TORRINGFORD ST',
        RegAddrCity: 'TORRINGTON',
        RegAddrState: 'CT',
        RegAddrZip: 6790,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '85 W DISTRICT RD',
        RegAddrCity: 'UNIONVILLE',
        RegAddrState: 'CT',
        RegAddrZip: 6085,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '8 HIGH POINT LN',
        RegAddrCity: 'WEST HARTFORD',
        RegAddrState: 'CT',
        RegAddrZip: 6107,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '45 WARDWELL RD',
        RegAddrCity: 'WEST HARTFORD',
        RegAddrState: 'CT',
        RegAddrZip: 6107,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '11 CARLETON RD',
        RegAddrCity: 'WEST HARTFORD',
        RegAddrState: 'CT',
        RegAddrZip: 6107,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '41 WESTMINSTER DR',
        RegAddrCity: 'WEST HARTFORD',
        RegAddrState: 'CT',
        RegAddrZip: 6107,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '55 RED TOP DR',
        RegAddrCity: 'WEST HARTFORD',
        RegAddrState: 'CT',
        RegAddrZip: 6110,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '10 CAROL RD',
        RegAddrCity: 'WEST HARTFORD',
        RegAddrState: 'CT',
        RegAddrZip: 6110,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '43 SEQUIN RD',
        RegAddrCity: 'WEST HARTFORD',
        RegAddrState: 'CT',
        RegAddrZip: 6117,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '14 WILTSHIRE LN',
        RegAddrCity: 'WEST HARTFORD',
        RegAddrState: 'CT',
        RegAddrZip: 6117,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1346 BOULEVARD',
        RegAddrCity: 'WEST HARTFORD',
        RegAddrState: 'CT',
        RegAddrZip: 6119,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '21 FOX DEN RD',
        RegAddrCity: 'WEST SIMSBURY',
        RegAddrState: 'CT',
        RegAddrZip: 6092,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '19 FAIRWAY DR',
        RegAddrCity: 'WETHERSFIELD',
        RegAddrState: 'CT',
        RegAddrZip: 6109,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '137 CLOVERCREST RD',
        RegAddrCity: 'WETHERSFIELD',
        RegAddrState: 'CT',
        RegAddrZip: 6109,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '73 STATE ST',
        RegAddrCity: 'WETHERSFIELD',
        RegAddrState: 'CT',
        RegAddrZip: 6109,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '16 TANGLEWOOD RD',
        RegAddrCity: 'WETHERSFIELD',
        RegAddrState: 'CT',
        RegAddrZip: 6109,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '11 ORCHARD HILL DR',
        RegAddrCity: 'WETHERSFIELD',
        RegAddrState: 'CT',
        RegAddrZip: 6109,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '12 SOMERSET ST',
        RegAddrCity: 'WETHERSFIELD',
        RegAddrState: 'CT',
        RegAddrZip: 6109,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '28 WELLS FARM DR',
        RegAddrCity: 'WETHERSFIELD',
        RegAddrState: 'CT',
        RegAddrZip: 6109,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '118 JOSHUA HL',
        RegAddrCity: 'WINDSOR',
        RegAddrState: 'CT',
        RegAddrZip: 6095,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '54 MARY CATHERINE CIR',
        RegAddrCity: 'WINDSOR',
        RegAddrState: 'CT',
        RegAddrZip: 6095,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '43 LOREN CIR',
        RegAddrCity: 'WINDSOR',
        RegAddrState: 'CT',
        RegAddrZip: 6095,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '130 COUNTRY CLUB DR',
        RegAddrCity: 'WINDSOR',
        RegAddrState: 'CT',
        RegAddrZip: 6095,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '224 ETHAN DR',
        RegAddrCity: 'WINDSOR',
        RegAddrState: 'CT',
        RegAddrZip: 6095,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '64 TOBEY AVE',
        RegAddrCity: 'WINDSOR',
        RegAddrState: 'CT',
        RegAddrZip: 6095,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '793 STONE RD',
        RegAddrCity: 'WINDSOR',
        RegAddrState: 'CT',
        RegAddrZip: 6095,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '44 WHITNEY CIR',
        RegAddrCity: 'WINDSOR',
        RegAddrState: 'CT',
        RegAddrZip: 6095,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '17 STEVENS ST',
        RegAddrCity: 'WINDSOR LOCKS',
        RegAddrState: 'CT',
        RegAddrZip: 6096,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '12 CHESTER DR',
        RegAddrCity: 'WINDSOR LOCKS',
        RegAddrState: 'CT',
        RegAddrZip: 6096,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '37 JACKSON ST',
        RegAddrCity: 'WINDSOR LOCKS',
        RegAddrState: 'CT',
        RegAddrZip: 6096,
        CongressionalDistrict: 1,
      },
    ],
  },
  {
    name: 'CT-2',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/CT-2?ref=gh-pages',
    state: 'CT',
    state_and_number: 'CT-2',
    zip: 1036,
    congressionalDistrict: 2,
    addressesInDistrict: [
      {
        RegAddrLine1: '103 REIDY HILL RD',
        RegAddrCity: 'AMSTON',
        RegAddrState: 'CT',
        RegAddrZip: 6231,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '15 HERON HILL RD',
        RegAddrCity: 'AMSTON',
        RegAddrState: 'CT',
        RegAddrZip: 6231,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '18 TURNPIKE RD',
        RegAddrCity: 'ASHFORD',
        RegAddrState: 'CT',
        RegAddrZip: 6278,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '5 BAY ST',
        RegAddrCity: 'BALTIC',
        RegAddrState: 'CT',
        RegAddrZip: 6330,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '36 WESTMINSTER RD',
        RegAddrCity: 'BALTIC',
        RegAddrState: 'CT',
        RegAddrZip: 6330,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '156 BRANDY ST',
        RegAddrCity: 'BOLTON',
        RegAddrState: 'CT',
        RegAddrZip: 6043,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '21 BROOKSIDE LN',
        RegAddrCity: 'BOLTON',
        RegAddrState: 'CT',
        RegAddrZip: 6043,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '19 CAROLINE RD',
        RegAddrCity: 'BOZRAH',
        RegAddrState: 'CT',
        RegAddrZip: 6334,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '23 SCHWARTZ RD',
        RegAddrCity: 'BOZRAH',
        RegAddrState: 'CT',
        RegAddrZip: 6334,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '280 FITCHVILLE RD',
        RegAddrCity: 'BOZRAH',
        RegAddrState: 'CT',
        RegAddrZip: 6334,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '135 LAKE RD',
        RegAddrCity: 'BOZRAH',
        RegAddrState: 'CT',
        RegAddrZip: 6334,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '18A PROULX ST',
        RegAddrCity: 'BROOKLYN',
        RegAddrState: 'CT',
        RegAddrZip: 6234,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '5 LESTER DR',
        RegAddrCity: 'CENTRAL VLG',
        RegAddrState: 'CT',
        RegAddrZip: 6332,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '27 PUTNAM RD',
        RegAddrCity: 'CENTRAL VLG',
        RegAddrState: 'CT',
        RegAddrZip: 6332,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '3 MAPLE ST',
        RegAddrCity: 'CHESTER',
        RegAddrState: 'CT',
        RegAddrZip: 6412,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '18 ELSIE LN',
        RegAddrCity: 'CLINTON',
        RegAddrState: 'CT',
        RegAddrZip: 6413,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '10 BEACH PARK RD',
        RegAddrCity: 'CLINTON',
        RegAddrState: 'CT',
        RegAddrZip: 6413,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '21 GARY LN',
        RegAddrCity: 'COLCHESTER',
        RegAddrState: 'CT',
        RegAddrZip: 6415,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '55 OLD WILLIMANTIC RD',
        RegAddrCity: 'COLUMBIA',
        RegAddrState: 'CT',
        RegAddrZip: 6237,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '10 CARDS MILL RD',
        RegAddrCity: 'COLUMBIA',
        RegAddrState: 'CT',
        RegAddrZip: 6237,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '42 EDGARTON RD',
        RegAddrCity: 'COLUMBIA',
        RegAddrState: 'CT',
        RegAddrZip: 6237,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '250 ROUTE 66 A',
        RegAddrCity: 'COLUMBIA',
        RegAddrState: 'CT',
        RegAddrZip: 6237,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '406 RICHMOND RD',
        RegAddrCity: 'COVENTRY',
        RegAddrState: 'CT',
        RegAddrZip: 6238,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '31 BELLWOOD CT',
        RegAddrCity: 'EAST HAMPTON',
        RegAddrState: 'CT',
        RegAddrZip: 6424,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '23 CURRY LN',
        RegAddrCity: 'EAST HAMPTON',
        RegAddrState: 'CT',
        RegAddrZip: 6424,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '32 VIOLA DR',
        RegAddrCity: 'EAST HAMPTON',
        RegAddrState: 'CT',
        RegAddrZip: 6424,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '49 CRYSTAL RIDGE DR',
        RegAddrCity: 'ELLINGTON',
        RegAddrState: 'CT',
        RegAddrZip: 6029,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '21 ANGEL TRACE RD',
        RegAddrCity: 'ELLINGTON',
        RegAddrState: 'CT',
        RegAddrZip: 6029,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '23 ELIZABETH ST',
        RegAddrCity: 'ELLINGTON',
        RegAddrState: 'CT',
        RegAddrZip: 6029,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '7 CARMELLA TER',
        RegAddrCity: 'ENFIELD',
        RegAddrState: 'CT',
        RegAddrZip: 6082,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '90 SIMON RD',
        RegAddrCity: 'ENFIELD',
        RegAddrState: 'CT',
        RegAddrZip: 6082,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '32 YALE DR',
        RegAddrCity: 'ENFIELD',
        RegAddrState: 'CT',
        RegAddrZip: 6082,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '126 PEARL ST',
        RegAddrCity: 'ENFIELD',
        RegAddrState: 'CT',
        RegAddrZip: 6082,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '54 SPRUCELAND RD',
        RegAddrCity: 'ENFIELD',
        RegAddrState: 'CT',
        RegAddrZip: 6082,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '30 MONTANO RD',
        RegAddrCity: 'ENFIELD',
        RegAddrState: 'CT',
        RegAddrZip: 6082,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '37 RAFFIA RD',
        RegAddrCity: 'ENFIELD',
        RegAddrState: 'CT',
        RegAddrZip: 6082,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '5 PLEASANT RD',
        RegAddrCity: 'ENFIELD',
        RegAddrState: 'CT',
        RegAddrZip: 6082,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '17 BUCHANAN RD',
        RegAddrCity: 'ENFIELD',
        RegAddrState: 'CT',
        RegAddrZip: 6082,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '33 KINGS LN',
        RegAddrCity: 'ESSEX',
        RegAddrState: 'CT',
        RegAddrZip: 6426,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '24 PATRICIA CT',
        RegAddrCity: 'GALES FERRY',
        RegAddrState: 'CT',
        RegAddrZip: 6335,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '235 MINNECHAUG DR',
        RegAddrCity: 'GLASTONBURY',
        RegAddrState: 'CT',
        RegAddrZip: 6033,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '255 LESTER RD',
        RegAddrCity: 'GRISWOLD',
        RegAddrState: 'CT',
        RegAddrZip: 6351,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '109 BENHAM RD',
        RegAddrCity: 'GROTON',
        RegAddrState: 'CT',
        RegAddrZip: 6340,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '30 HAWTHORN RD',
        RegAddrCity: 'GROTON',
        RegAddrState: 'CT',
        RegAddrZip: 6340,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '185 THAMES ST',
        RegAddrCity: 'GROTON',
        RegAddrState: 'CT',
        RegAddrZip: 6340,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '18 FLEMING CT',
        RegAddrCity: 'GROTON',
        RegAddrState: 'CT',
        RegAddrZip: 6340,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '196 S BIGELOW RD',
        RegAddrCity: 'HAMPTON',
        RegAddrState: 'CT',
        RegAddrZip: 6247,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '160 HIDDEN LAKE RD',
        RegAddrCity: 'HIGGANUM',
        RegAddrState: 'CT',
        RegAddrZip: 6441,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '215 LITTLE CITY RD',
        RegAddrCity: 'HIGGANUM',
        RegAddrState: 'CT',
        RegAddrZip: 6441,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '128 KILLINGWORTH RD',
        RegAddrCity: 'HIGGANUM',
        RegAddrState: 'CT',
        RegAddrZip: 6441,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '48 SPENCER RD',
        RegAddrCity: 'HIGGANUM',
        RegAddrState: 'CT',
        RegAddrZip: 6441,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '91 BURLINGAME RD',
        RegAddrCity: 'KILLINGLY',
        RegAddrState: 'CT',
        RegAddrZip: 6239,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '102 SLATER HILL RD',
        RegAddrCity: 'KILLINGLY',
        RegAddrState: 'CT',
        RegAddrZip: 6241,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '57 GREGORY RD',
        RegAddrCity: 'LEBANON',
        RegAddrState: 'CT',
        RegAddrZip: 6249,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '4 JEAN B CT',
        RegAddrCity: 'LEDYARD',
        RegAddrState: 'CT',
        RegAddrZip: 6339,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '98 AVERY HILL RD',
        RegAddrCity: 'LEDYARD',
        RegAddrState: 'CT',
        RegAddrZip: 6339,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '36 BLUEBERRY LN',
        RegAddrCity: 'LISBON',
        RegAddrState: 'CT',
        RegAddrZip: 6351,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '156 MELL RD',
        RegAddrCity: 'LISBON',
        RegAddrState: 'CT',
        RegAddrZip: 6351,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '130 PHILLIPS RD',
        RegAddrCity: 'LISBON',
        RegAddrState: 'CT',
        RegAddrZip: 6351,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '91 COVE RD',
        RegAddrCity: 'LYME',
        RegAddrState: 'CT',
        RegAddrZip: 6371,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '519 HORSE POND RD',
        RegAddrCity: 'MADISON',
        RegAddrState: 'CT',
        RegAddrZip: 6443,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: 'ZZMADISON DIST 1',
        RegAddrCity: 'MADISON',
        RegAddrState: 'CT',
        RegAddrZip: 6443,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '67 COPSE RD',
        RegAddrCity: 'MADISON',
        RegAddrState: 'CT',
        RegAddrZip: 6443,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1 UNIVERSITY OF CT',
        RegAddrCity: 'MANSFIELD',
        RegAddrState: 'CT',
        RegAddrZip: 6269,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '32 HIGHLAND RD',
        RegAddrCity: 'MANSFIELD CENTER',
        RegAddrState: 'CT',
        RegAddrZip: 6250,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '112 SAWMILL BROOK LN',
        RegAddrCity: 'MANSFIELD CENTER',
        RegAddrState: 'CT',
        RegAddrZip: 6250,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '22 PLAINS RD',
        RegAddrCity: 'MOODUS',
        RegAddrState: 'CT',
        RegAddrZip: 6469,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2 WINTHROP DR',
        RegAddrCity: 'MOOSUP',
        RegAddrState: 'CT',
        RegAddrZip: 6354,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '169 HIGH MEADOW LN',
        RegAddrCity: 'MYSTIC',
        RegAddrState: 'CT',
        RegAddrZip: 6355,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '32 WILLOW DR',
        RegAddrCity: 'MYSTIC',
        RegAddrState: 'CT',
        RegAddrZip: 6355,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1 KINGSWOOD DR',
        RegAddrCity: 'N STONINGTON',
        RegAddrState: 'CT',
        RegAddrZip: 6359,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '51 FRANKLIN ST',
        RegAddrCity: 'NEW LONDON',
        RegAddrState: 'CT',
        RegAddrZip: 6320,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '77 SUMMER ST',
        RegAddrCity: 'NEW LONDON',
        RegAddrState: 'CT',
        RegAddrZip: 6320,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '49 SEA BREEZE AVE',
        RegAddrCity: 'NIANTIC',
        RegAddrState: 'CT',
        RegAddrZip: 6357,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '90 LAURELWOOD DR',
        RegAddrCity: 'NIANTIC',
        RegAddrState: 'CT',
        RegAddrZip: 6357,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '18 CUBLES DR',
        RegAddrCity: 'NIANTIC',
        RegAddrState: 'CT',
        RegAddrZip: 6357,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '59 OSWEGATCHIE HILLS RD',
        RegAddrCity: 'NIANTIC',
        RegAddrState: 'CT',
        RegAddrZip: 6357,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1 ROCKWELL ST',
        RegAddrCity: 'NIANTIC',
        RegAddrState: 'CT',
        RegAddrZip: 6357,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '26 PEARL ST',
        RegAddrCity: 'NOANK',
        RegAddrState: 'CT',
        RegAddrZip: 6340,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '279 BEAVER HILL RD',
        RegAddrCity: 'NORTH WINDHAM',
        RegAddrState: 'CT',
        RegAddrZip: 6256,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '342 FRANKLIN ST',
        RegAddrCity: 'NORWICH',
        RegAddrState: 'CT',
        RegAddrZip: 6360,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '25 BRIAR HILL RD',
        RegAddrCity: 'NORWICH',
        RegAddrState: 'CT',
        RegAddrZip: 6360,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '411 WAWECUS HILL RD',
        RegAddrCity: 'NORWICH',
        RegAddrState: 'CT',
        RegAddrZip: 6360,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '11 TRADERS CT',
        RegAddrCity: 'NORWICH',
        RegAddrState: 'CT',
        RegAddrZip: 6360,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '10 OLD SALEM RD',
        RegAddrCity: 'NORWICH',
        RegAddrState: 'CT',
        RegAddrZip: 6360,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '10 CHADWICK DR',
        RegAddrCity: 'OLD LYME',
        RegAddrState: 'CT',
        RegAddrZip: 6371,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '27 CINNAMON RDG',
        RegAddrCity: 'OLD SAYBROOK',
        RegAddrState: 'CT',
        RegAddrZip: 6475,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '14 WILD APPLE LN',
        RegAddrCity: 'OLD SAYBROOK',
        RegAddrState: 'CT',
        RegAddrZip: 6475,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '36 PALMER NECK RD',
        RegAddrCity: 'PAWCATUCK',
        RegAddrState: 'CT',
        RegAddrZip: 6379,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '330 POMFRET ST',
        RegAddrCity: 'POMFRET CTR',
        RegAddrState: 'CT',
        RegAddrZip: 6259,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '36 PRODELL RD',
        RegAddrCity: 'PRESTON',
        RegAddrState: 'CT',
        RegAddrZip: 6365,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '71 OLD JEWETT CITY RD',
        RegAddrCity: 'PRESTON',
        RegAddrState: 'CT',
        RegAddrZip: 6365,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '28 SUNNYSIDE AVE',
        RegAddrCity: 'PUTNAM',
        RegAddrState: 'CT',
        RegAddrZip: 6260,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '5 ALPHA ST',
        RegAddrCity: 'PUTNAM',
        RegAddrState: 'CT',
        RegAddrZip: 6260,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '10 THURBER RD',
        RegAddrCity: 'PUTNAM',
        RegAddrState: 'CT',
        RegAddrZip: 6260,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '66 S PROSPECT ST',
        RegAddrCity: 'PUTNAM',
        RegAddrState: 'CT',
        RegAddrZip: 6260,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '19 PILGRIM RD',
        RegAddrCity: 'QUAKER HILL',
        RegAddrState: 'CT',
        RegAddrZip: 6375,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '620 MAIN ST',
        RegAddrCity: 'S GLASTONBURY',
        RegAddrState: 'CT',
        RegAddrZip: 6073,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '89 WINWOOD CIR',
        RegAddrCity: 'SOMERS',
        RegAddrState: 'CT',
        RegAddrZip: 6071,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '129 SPRINGFIELD RD',
        RegAddrCity: 'SOMERS',
        RegAddrState: 'CT',
        RegAddrZip: 6071,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '16 MCCULLOCH DR',
        RegAddrCity: 'SOMERS',
        RegAddrState: 'CT',
        RegAddrZip: 6071,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '190 WRIGHTS BROOK DR',
        RegAddrCity: 'SOMERS',
        RegAddrState: 'CT',
        RegAddrZip: 6071,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '31 HALL HILL RD',
        RegAddrCity: 'SOMERS',
        RegAddrState: 'CT',
        RegAddrZip: 6071,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '192 ORCUTTVILLE RD',
        RegAddrCity: 'STAFFORD SPGS',
        RegAddrState: 'CT',
        RegAddrZip: 6076,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '10 CLEARVIEW DR',
        RegAddrCity: 'STAFFORD SPRINGS',
        RegAddrState: 'CT',
        RegAddrZip: 6076,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '31 BARBER RD',
        RegAddrCity: 'STERLING',
        RegAddrState: 'CT',
        RegAddrZip: 6377,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '101 CIRCLE DR',
        RegAddrCity: 'STONINGTON',
        RegAddrState: 'CT',
        RegAddrZip: 6378,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '291 N STONINGTON RD',
        RegAddrCity: 'STONINGTON',
        RegAddrState: 'CT',
        RegAddrZip: 6378,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '3 HILLSIDE CIR',
        RegAddrCity: 'STORRS MANFLD',
        RegAddrState: 'CT',
        RegAddrZip: 6268,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '154 MAPLE RD',
        RegAddrCity: 'STORRS MANFLD',
        RegAddrState: 'CT',
        RegAddrZip: 6268,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '65 LYNWOOD RD',
        RegAddrCity: 'STORRS MANSFIELD',
        RegAddrState: 'CT',
        RegAddrZip: 6268,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '104 WILDWOOD RD',
        RegAddrCity: 'STORRS MANSFIELD',
        RegAddrState: 'CT',
        RegAddrZip: 6268,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '670 MAPLETON AVE',
        RegAddrCity: 'SUFFIELD',
        RegAddrState: 'CT',
        RegAddrZip: 6078,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '30 SONNY LN',
        RegAddrCity: 'THOMPSON',
        RegAddrState: 'CT',
        RegAddrZip: 6277,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '285 OLEARY RD',
        RegAddrCity: 'THOMPSON',
        RegAddrState: 'CT',
        RegAddrZip: 6277,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '170 METCALF RD',
        RegAddrCity: 'TOLLAND',
        RegAddrState: 'CT',
        RegAddrZip: 6084,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '20 CHARLES ST',
        RegAddrCity: 'TOLLAND',
        RegAddrState: 'CT',
        RegAddrZip: 6084,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '83 COTTONWOOD LN',
        RegAddrCity: 'UNCASVILLE',
        RegAddrState: 'CT',
        RegAddrZip: 6382,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '30 MAPLE AVE',
        RegAddrCity: 'UNCASVILLE',
        RegAddrState: 'CT',
        RegAddrZip: 6382,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '82 REGAN RD',
        RegAddrCity: 'VERNON',
        RegAddrState: 'CT',
        RegAddrZip: 6066,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1173 HARTFORD TPKE',
        RegAddrCity: 'VERNON',
        RegAddrState: 'CT',
        RegAddrZip: 6066,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '5 DAILEY CIR',
        RegAddrCity: 'VERNON',
        RegAddrState: 'CT',
        RegAddrZip: 6066,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '103 EKONK HILL RD',
        RegAddrCity: 'VOLUNTOWN',
        RegAddrState: 'CT',
        RegAddrZip: 6384,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '33 STONEHEIGHTS DR',
        RegAddrCity: 'WATERFORD',
        RegAddrState: 'CT',
        RegAddrZip: 6385,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '7 LONGVIEW AVE',
        RegAddrCity: 'WATERFORD',
        RegAddrState: 'CT',
        RegAddrZip: 6385,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '815 RATLEY RD',
        RegAddrCity: 'WEST SUFFIELD',
        RegAddrState: 'CT',
        RegAddrZip: 6093,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '31 RUNNING PINE RD',
        RegAddrCity: 'WESTBROOK',
        RegAddrState: 'CT',
        RegAddrZip: 6498,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '114 MEADOWLARK LN',
        RegAddrCity: 'WESTBROOK',
        RegAddrState: 'CT',
        RegAddrZip: 6498,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '223 OXBOW DR',
        RegAddrCity: 'WILLIMANTIC',
        RegAddrState: 'CT',
        RegAddrZip: 6226,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '69 MIRTL RD',
        RegAddrCity: 'WILLINGTON',
        RegAddrState: 'CT',
        RegAddrZip: 6279,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '7 SAINT MORITZ CIR',
        RegAddrCity: 'WILLINGTON',
        RegAddrState: 'CT',
        RegAddrZip: 6279,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '10 FISHER HILL RD',
        RegAddrCity: 'WILLINGTON',
        RegAddrState: 'CT',
        RegAddrZip: 6279,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '37 BRICK TOP LN',
        RegAddrCity: 'WINDHAM',
        RegAddrState: 'CT',
        RegAddrZip: 6280,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '40 HUDSON LN',
        RegAddrCity: 'WINDSOR',
        RegAddrState: 'CT',
        RegAddrZip: 6095,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '169 ROUTE 169',
        RegAddrCity: 'WOODSTOCK',
        RegAddrState: 'CT',
        RegAddrZip: 6281,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '200 CHILD RD',
        RegAddrCity: 'WOODSTOCK',
        RegAddrState: 'CT',
        RegAddrZip: 6281,
        CongressionalDistrict: 2,
      },
    ],
  },
  {
    name: 'CT-3',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/CT-3?ref=gh-pages',
    state: 'CT',
    state_and_number: 'CT-3',
    zip: 6401,
    congressionalDistrict: 3,
    addressesInDistrict: [
      {
        RegAddrLine1: '12 HALE DR',
        RegAddrCity: 'ANSONIA',
        RegAddrState: 'CT',
        RegAddrZip: 6401,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '92 FORD ST',
        RegAddrCity: 'ANSONIA',
        RegAddrState: 'CT',
        RegAddrZip: 6401,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '97 S CLIFF ST',
        RegAddrCity: 'ANSONIA',
        RegAddrState: 'CT',
        RegAddrZip: 6401,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '80 DORCHESTER CT',
        RegAddrCity: 'BEACON FALLS',
        RegAddrState: 'CT',
        RegAddrZip: 6403,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '2 FRANCIS DR',
        RegAddrCity: 'BETHANY',
        RegAddrState: 'CT',
        RegAddrZip: 6524,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '189 ALPS RD',
        RegAddrCity: 'BRANFORD',
        RegAddrState: 'CT',
        RegAddrZip: 6405,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '9 GROVE ST',
        RegAddrCity: 'BRANFORD',
        RegAddrState: 'CT',
        RegAddrZip: 6405,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '21 HOTCHKISS GROVE RD',
        RegAddrCity: 'BRANFORD',
        RegAddrState: 'CT',
        RegAddrZip: 6405,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '77 E MAIN ST',
        RegAddrCity: 'BRANFORD',
        RegAddrState: 'CT',
        RegAddrZip: 6405,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '2 LOMARTRA LN',
        RegAddrCity: 'BRANFORD',
        RegAddrState: 'CT',
        RegAddrZip: 6405,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '9 PENT RD',
        RegAddrCity: 'BRANFORD',
        RegAddrState: 'CT',
        RegAddrZip: 6405,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '36 APPLEWOOD RD',
        RegAddrCity: 'BRANFORD',
        RegAddrState: 'CT',
        RegAddrZip: 6405,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '39 PARK AVE',
        RegAddrCity: 'DERBY',
        RegAddrState: 'CT',
        RegAddrZip: 6418,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '32 CHESTNUT DR',
        RegAddrCity: 'DERBY',
        RegAddrState: 'CT',
        RegAddrZip: 6418,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '264 FOOT HILLS RD',
        RegAddrCity: 'DURHAM',
        RegAddrState: 'CT',
        RegAddrZip: 6422,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '38 TALMADGE AVE',
        RegAddrCity: 'EAST HAVEN',
        RegAddrState: 'CT',
        RegAddrZip: 6512,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '76 MAPLE ST',
        RegAddrCity: 'EAST HAVEN',
        RegAddrState: 'CT',
        RegAddrZip: 6512,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '29 STEVENS ST',
        RegAddrCity: 'EAST HAVEN',
        RegAddrState: 'CT',
        RegAddrZip: 6512,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '6 ALLEN CT',
        RegAddrCity: 'EAST HAVEN',
        RegAddrState: 'CT',
        RegAddrZip: 6512,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '31 HELLSTROM RD',
        RegAddrCity: 'EAST HAVEN',
        RegAddrState: 'CT',
        RegAddrZip: 6512,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '10 ALLIKAT WAY',
        RegAddrCity: 'EAST HAVEN',
        RegAddrState: 'CT',
        RegAddrZip: 6513,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '6 S DALE ST',
        RegAddrCity: 'EAST HAVEN',
        RegAddrState: 'CT',
        RegAddrZip: 6513,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '485 BRIARWOOD DR',
        RegAddrCity: 'GUILFORD',
        RegAddrState: 'CT',
        RegAddrZip: 6437,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '75 LAUREL CLIFFS RD',
        RegAddrCity: 'GUILFORD',
        RegAddrState: 'CT',
        RegAddrZip: 6437,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '91 FAIR ST',
        RegAddrCity: 'GUILFORD',
        RegAddrState: 'CT',
        RegAddrZip: 6437,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '11 SUNSET CIR',
        RegAddrCity: 'GUILFORD',
        RegAddrState: 'CT',
        RegAddrZip: 6437,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '81 PHELPS DR',
        RegAddrCity: 'HAMDEN',
        RegAddrState: 'CT',
        RegAddrZip: 6514,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '134 PIPER RD',
        RegAddrCity: 'HAMDEN',
        RegAddrState: 'CT',
        RegAddrZip: 6514,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '330 HILL ST',
        RegAddrCity: 'HAMDEN',
        RegAddrState: 'CT',
        RegAddrZip: 6514,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '107 NORTH ST',
        RegAddrCity: 'HAMDEN',
        RegAddrState: 'CT',
        RegAddrZip: 6514,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '38 TOWNE HOUSE RD',
        RegAddrCity: 'HAMDEN',
        RegAddrState: 'CT',
        RegAddrZip: 6514,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '194 TREADWELL ST',
        RegAddrCity: 'HAMDEN',
        RegAddrState: 'CT',
        RegAddrZip: 6517,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '67 GREENWAY ST',
        RegAddrCity: 'HAMDEN',
        RegAddrState: 'CT',
        RegAddrZip: 6517,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '93 N LAKE DR',
        RegAddrCity: 'HAMDEN',
        RegAddrState: 'CT',
        RegAddrZip: 6517,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '132 ERAMO TER',
        RegAddrCity: 'HAMDEN',
        RegAddrState: 'CT',
        RegAddrZip: 6518,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '2250 DOWNS RD',
        RegAddrCity: 'HAMDEN',
        RegAddrState: 'CT',
        RegAddrZip: 6518,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '100 CHATTERTON WAY',
        RegAddrCity: 'HAMDEN',
        RegAddrState: 'CT',
        RegAddrZip: 6518,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1 HOTCHKISS LN',
        RegAddrCity: 'HAMDEN',
        RegAddrState: 'CT',
        RegAddrZip: 6518,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '29 DELSOLE RD',
        RegAddrCity: 'HAMDEN',
        RegAddrState: 'CT',
        RegAddrZip: 6518,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '9 CRESTVIEW',
        RegAddrCity: 'MIDDLEFIELD',
        RegAddrState: 'CT',
        RegAddrZip: 6455,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '83 PATERSON DR',
        RegAddrCity: 'MIDDLETOWN',
        RegAddrState: 'CT',
        RegAddrZip: 6457,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '106 DOVE LN',
        RegAddrCity: 'MIDDLETOWN',
        RegAddrState: 'CT',
        RegAddrZip: 6457,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '40 MAPLE PL',
        RegAddrCity: 'MIDDLETOWN',
        RegAddrState: 'CT',
        RegAddrZip: 6457,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '4 PEASE AVE',
        RegAddrCity: 'MIDDLETOWN',
        RegAddrState: 'CT',
        RegAddrZip: 6457,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '46 AFTON TER',
        RegAddrCity: 'MIDDLETOWN',
        RegAddrState: 'CT',
        RegAddrZip: 6457,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '97 LAWN AVE',
        RegAddrCity: 'MIDDLETOWN',
        RegAddrState: 'CT',
        RegAddrZip: 6457,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '65 OLANDER LN',
        RegAddrCity: 'MIDDLETOWN',
        RegAddrState: 'CT',
        RegAddrZip: 6457,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '17 VILLA ST',
        RegAddrCity: 'MIDDLETOWN',
        RegAddrState: 'CT',
        RegAddrZip: 6457,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '10 LEE CT',
        RegAddrCity: 'MILFORD',
        RegAddrState: 'CT',
        RegAddrZip: 6460,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '10 MILL POND CLOSE',
        RegAddrCity: 'MILFORD',
        RegAddrState: 'CT',
        RegAddrZip: 6460,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '6 APPLEWOOD LN',
        RegAddrCity: 'MILFORD',
        RegAddrState: 'CT',
        RegAddrZip: 6460,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '45 COOPER AVE',
        RegAddrCity: 'MILFORD',
        RegAddrState: 'CT',
        RegAddrZip: 6460,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '52 CHESTER ST',
        RegAddrCity: 'MILFORD',
        RegAddrState: 'CT',
        RegAddrZip: 6460,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '137 TERRACE RD',
        RegAddrCity: 'MILFORD',
        RegAddrState: 'CT',
        RegAddrZip: 6460,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '53 SETTLERS RIDGE RD',
        RegAddrCity: 'MILFORD',
        RegAddrState: 'CT',
        RegAddrZip: 6460,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '8 KIRKSIDE AVE',
        RegAddrCity: 'MILFORD',
        RegAddrState: 'CT',
        RegAddrZip: 6460,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '43 JACKSON DR',
        RegAddrCity: 'MILFORD',
        RegAddrState: 'CT',
        RegAddrZip: 6460,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '546 SWANSON CRES',
        RegAddrCity: 'MILFORD',
        RegAddrState: 'CT',
        RegAddrZip: 6461,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '5 ATWOOD ST',
        RegAddrCity: 'MILFORD',
        RegAddrState: 'CT',
        RegAddrZip: 6461,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '74 RUTH ANN TER',
        RegAddrCity: 'MILFORD',
        RegAddrState: 'CT',
        RegAddrZip: 6461,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '19 PASCHAL DR',
        RegAddrCity: 'MILFORD',
        RegAddrState: 'CT',
        RegAddrZip: 6461,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1114 WINDWARD RD',
        RegAddrCity: 'MILFORD',
        RegAddrState: 'CT',
        RegAddrZip: 6461,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '50 MASTERS LN',
        RegAddrCity: 'MILFORD',
        RegAddrState: 'CT',
        RegAddrZip: 6461,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '175 BRANFORD RD',
        RegAddrCity: 'N BRANFORD',
        RegAddrState: 'CT',
        RegAddrZip: 6471,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '196 JOHNSON ST',
        RegAddrCity: 'NAUGATUCK',
        RegAddrState: 'CT',
        RegAddrZip: 6770,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '166 HILLSIDE AVE',
        RegAddrCity: 'NAUGATUCK',
        RegAddrState: 'CT',
        RegAddrZip: 6770,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '166 BEACON MANOR RD',
        RegAddrCity: 'NAUGATUCK',
        RegAddrState: 'CT',
        RegAddrZip: 6770,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '37 HIGH ST',
        RegAddrCity: 'NEW HAVEN',
        RegAddrState: 'CT',
        RegAddrZip: 6510,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '188 MANSFIELD ST',
        RegAddrCity: 'NEW HAVEN',
        RegAddrState: 'CT',
        RegAddrZip: 6511,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '285 VIEW ST',
        RegAddrCity: 'NEW HAVEN',
        RegAddrState: 'CT',
        RegAddrZip: 6511,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '196 UPSON TER',
        RegAddrCity: 'NEW HAVEN',
        RegAddrState: 'CT',
        RegAddrZip: 6512,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '172 TOWNSEND TER',
        RegAddrCity: 'NEW HAVEN',
        RegAddrState: 'CT',
        RegAddrZip: 6512,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1284 DEAN ST',
        RegAddrCity: 'NEW HAVEN',
        RegAddrState: 'CT',
        RegAddrZip: 6512,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '80 E PEARL ST',
        RegAddrCity: 'NEW HAVEN',
        RegAddrState: 'CT',
        RegAddrZip: 6513,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '257 RUSSELL ST',
        RegAddrCity: 'NEW HAVEN',
        RegAddrState: 'CT',
        RegAddrZip: 6513,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '142 LOMBARD ST',
        RegAddrCity: 'NEW HAVEN',
        RegAddrState: 'CT',
        RegAddrZip: 6513,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '131 MCKINLEY AVE',
        RegAddrCity: 'NEW HAVEN',
        RegAddrState: 'CT',
        RegAddrZip: 6515,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '30 BYRON PL',
        RegAddrCity: 'NEW HAVEN',
        RegAddrState: 'CT',
        RegAddrZip: 6515,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '528 VALLEY ST',
        RegAddrCity: 'NEW HAVEN',
        RegAddrState: 'CT',
        RegAddrZip: 6515,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '63 SYLVAN AVE',
        RegAddrCity: 'NEW HAVEN',
        RegAddrState: 'CT',
        RegAddrZip: 6519,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '495 CONGRESS AVE',
        RegAddrCity: 'NEW HAVEN',
        RegAddrState: 'CT',
        RegAddrZip: 6519,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '40 SEA ST',
        RegAddrCity: 'NEW HAVEN',
        RegAddrState: 'CT',
        RegAddrZip: 6519,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '74 HARBOUR CLOSE',
        RegAddrCity: 'NEW HAVEN',
        RegAddrState: 'CT',
        RegAddrZip: 6519,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '47 REDFIELD ST',
        RegAddrCity: 'NEW HAVEN',
        RegAddrState: 'CT',
        RegAddrZip: 6519,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '88 NOTCH HILL RD',
        RegAddrCity: 'NORTH BRANFORD',
        RegAddrState: 'CT',
        RegAddrZip: 6471,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '33 WINDSOR RD E',
        RegAddrCity: 'NORTH HAVEN',
        RegAddrState: 'CT',
        RegAddrZip: 6473,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1 JONES FARM RD',
        RegAddrCity: 'NORTH HAVEN',
        RegAddrState: 'CT',
        RegAddrZip: 6473,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '114 SCRUB OAK RD',
        RegAddrCity: 'NORTH HAVEN',
        RegAddrState: 'CT',
        RegAddrZip: 6473,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1340 RIDGE RD',
        RegAddrCity: 'NORTH HAVEN',
        RegAddrState: 'CT',
        RegAddrZip: 6473,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '115 REEDS GAP RD',
        RegAddrCity: 'NORTHFORD',
        RegAddrState: 'CT',
        RegAddrZip: 6472,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '131 KENNEDY DR',
        RegAddrCity: 'ORANGE',
        RegAddrState: 'CT',
        RegAddrZip: 6477,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '865 DOGBURN RD',
        RegAddrCity: 'ORANGE',
        RegAddrState: 'CT',
        RegAddrZip: 6477,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '29 PUTTING GREEN LN',
        RegAddrCity: 'PROSPECT',
        RegAddrState: 'CT',
        RegAddrZip: 6712,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '114 STRAITSVILLE RD',
        RegAddrCity: 'PROSPECT',
        RegAddrState: 'CT',
        RegAddrZip: 6712,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '7 AMBER CT',
        RegAddrCity: 'PROSPECT',
        RegAddrState: 'CT',
        RegAddrZip: 6712,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '130 CHESHIRE RD',
        RegAddrCity: 'PROSPECT',
        RegAddrState: 'CT',
        RegAddrZip: 6712,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '11 SKOKORAT ST',
        RegAddrCity: 'SEYMOUR',
        RegAddrState: 'CT',
        RegAddrZip: 6483,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '22 STODDARD ST',
        RegAddrCity: 'SEYMOUR',
        RegAddrState: 'CT',
        RegAddrZip: 6483,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '21 GRAND ST',
        RegAddrCity: 'SEYMOUR',
        RegAddrState: 'CT',
        RegAddrZip: 6483,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '97 LOS ANGELES AVE',
        RegAddrCity: 'STRATFORD',
        RegAddrState: 'CT',
        RegAddrZip: 6614,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '504 JAMESTOWN RD',
        RegAddrCity: 'STRATFORD',
        RegAddrState: 'CT',
        RegAddrZip: 6614,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '3059 MAIN ST',
        RegAddrCity: 'STRATFORD',
        RegAddrState: 'CT',
        RegAddrZip: 6614,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '37 CANAAN CT',
        RegAddrCity: 'STRATFORD',
        RegAddrState: 'CT',
        RegAddrZip: 6614,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '32 HAWKINS ST',
        RegAddrCity: 'STRATFORD',
        RegAddrState: 'CT',
        RegAddrZip: 6614,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '28 MCQUILLAN ST',
        RegAddrCity: 'STRATFORD',
        RegAddrState: 'CT',
        RegAddrZip: 6614,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '192 TAFT ST',
        RegAddrCity: 'STRATFORD',
        RegAddrState: 'CT',
        RegAddrZip: 6615,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '4 LENOX AVE',
        RegAddrCity: 'STRATFORD',
        RegAddrState: 'CT',
        RegAddrZip: 6615,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '78 2ND AVE',
        RegAddrCity: 'STRATFORD',
        RegAddrState: 'CT',
        RegAddrZip: 6615,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '302 S ELM ST',
        RegAddrCity: 'WALLINGFORD',
        RegAddrState: 'CT',
        RegAddrZip: 6492,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '22 BIRCH DR',
        RegAddrCity: 'WALLINGFORD',
        RegAddrState: 'CT',
        RegAddrZip: 6492,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '14 CHERYL AVE',
        RegAddrCity: 'WALLINGFORD',
        RegAddrState: 'CT',
        RegAddrZip: 6492,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '8 WOJTASIK DR',
        RegAddrCity: 'WALLINGFORD',
        RegAddrState: 'CT',
        RegAddrZip: 6492,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '47 CLEARVIEW DR',
        RegAddrCity: 'WALLINGFORD',
        RegAddrState: 'CT',
        RegAddrZip: 6492,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '176 WILLIAMS RD',
        RegAddrCity: 'WALLINGFORD',
        RegAddrState: 'CT',
        RegAddrZip: 6492,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '31 OSAGE DR',
        RegAddrCity: 'WALLINGFORD',
        RegAddrState: 'CT',
        RegAddrZip: 6492,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '18 WALL ST',
        RegAddrCity: 'WALLINGFORD',
        RegAddrState: 'CT',
        RegAddrZip: 6492,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '385 MAIN ST',
        RegAddrCity: 'WALLINGFORD',
        RegAddrState: 'CT',
        RegAddrZip: 6492,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '157 E LIBERTY ST',
        RegAddrCity: 'WATERBURY',
        RegAddrState: 'CT',
        RegAddrZip: 6706,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1753 BALDWIN ST',
        RegAddrCity: 'WATERBURY',
        RegAddrState: 'CT',
        RegAddrZip: 6706,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '376 CONGRESS AVE',
        RegAddrCity: 'WATERBURY',
        RegAddrState: 'CT',
        RegAddrZip: 6708,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '206 PLAZA AVE',
        RegAddrCity: 'WATERBURY',
        RegAddrState: 'CT',
        RegAddrZip: 6710,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '67 N UNION AVE',
        RegAddrCity: 'WEST HAVEN',
        RegAddrState: 'CT',
        RegAddrZip: 6516,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '120 HINMAN ST',
        RegAddrCity: 'WEST HAVEN',
        RegAddrState: 'CT',
        RegAddrZip: 6516,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '222 COURT ST',
        RegAddrCity: 'WEST HAVEN',
        RegAddrState: 'CT',
        RegAddrZip: 6516,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '300 BOSTON POST RD',
        RegAddrCity: 'WEST HAVEN',
        RegAddrState: 'CT',
        RegAddrZip: 6516,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '820 W MAIN ST',
        RegAddrCity: 'WEST HAVEN',
        RegAddrState: 'CT',
        RegAddrZip: 6516,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '65 TEMPLETON ST',
        RegAddrCity: 'WEST HAVEN',
        RegAddrState: 'CT',
        RegAddrZip: 6516,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '115 ROBART ST',
        RegAddrCity: 'WEST HAVEN',
        RegAddrState: 'CT',
        RegAddrZip: 6516,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '12 BELLE CIR',
        RegAddrCity: 'WEST HAVEN',
        RegAddrState: 'CT',
        RegAddrZip: 6516,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '6 ROLLIN RD',
        RegAddrCity: 'WOODBRIDGE',
        RegAddrState: 'CT',
        RegAddrZip: 6525,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '75 MAPLE VALE DR',
        RegAddrCity: 'WOODBRIDGE',
        RegAddrState: 'CT',
        RegAddrZip: 6525,
        CongressionalDistrict: 3,
      },
    ],
  },
  {
    name: 'CT-4',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/CT-4?ref=gh-pages',
    state: 'CT',
    state_and_number: 'CT-4',
    zip: 6468,
    congressionalDistrict: 4,
    addressesInDistrict: [
      {
        RegAddrLine1: '503 GRAND ST',
        RegAddrCity: 'BRIDGEPORT',
        RegAddrState: 'CT',
        RegAddrZip: 6604,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '43 HERKIMER ST',
        RegAddrCity: 'BRIDGEPORT',
        RegAddrState: 'CT',
        RegAddrZip: 6604,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1241 MAIN ST',
        RegAddrCity: 'BRIDGEPORT',
        RegAddrState: 'CT',
        RegAddrZip: 6604,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '768 IRANISTAN AVE',
        RegAddrCity: 'BRIDGEPORT',
        RegAddrState: 'CT',
        RegAddrZip: 6605,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '30 SIEMON ST',
        RegAddrCity: 'BRIDGEPORT',
        RegAddrState: 'CT',
        RegAddrZip: 6605,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1015 FAIRFIELD AVE',
        RegAddrCity: 'BRIDGEPORT',
        RegAddrState: 'CT',
        RegAddrZip: 6605,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '43 EDWARDS ST',
        RegAddrCity: 'BRIDGEPORT',
        RegAddrState: 'CT',
        RegAddrZip: 6606,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '721 PLATT ST',
        RegAddrCity: 'BRIDGEPORT',
        RegAddrState: 'CT',
        RegAddrZip: 6606,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '307 HIGH RIDGE DR',
        RegAddrCity: 'BRIDGEPORT',
        RegAddrState: 'CT',
        RegAddrZip: 6606,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '84 CHARLES ST',
        RegAddrCity: 'BRIDGEPORT',
        RegAddrState: 'CT',
        RegAddrZip: 6606,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '920 SYLVAN AVE',
        RegAddrCity: 'BRIDGEPORT',
        RegAddrState: 'CT',
        RegAddrZip: 6606,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '521 GLENDALE AVE',
        RegAddrCity: 'BRIDGEPORT',
        RegAddrState: 'CT',
        RegAddrZip: 6606,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '435 WOODROW AVE',
        RegAddrCity: 'BRIDGEPORT',
        RegAddrState: 'CT',
        RegAddrZip: 6606,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '180 KENNEDY DR',
        RegAddrCity: 'BRIDGEPORT',
        RegAddrState: 'CT',
        RegAddrZip: 6606,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '400 GRIFFIN AVE',
        RegAddrCity: 'BRIDGEPORT',
        RegAddrState: 'CT',
        RegAddrZip: 6606,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '260 EDGEMOOR RD',
        RegAddrCity: 'BRIDGEPORT',
        RegAddrState: 'CT',
        RegAddrZip: 6606,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '176 WILMOT AVE',
        RegAddrCity: 'BRIDGEPORT',
        RegAddrState: 'CT',
        RegAddrZip: 6607,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1347 KOSSUTH ST',
        RegAddrCity: 'BRIDGEPORT',
        RegAddrState: 'CT',
        RegAddrZip: 6608,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '317 NICHOLS ST',
        RegAddrCity: 'BRIDGEPORT',
        RegAddrState: 'CT',
        RegAddrZip: 6608,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '178 ORCHARD ST',
        RegAddrCity: 'BRIDGEPORT',
        RegAddrState: 'CT',
        RegAddrZip: 6608,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '61 BOOTH ST',
        RegAddrCity: 'BRIDGEPORT',
        RegAddrState: 'CT',
        RegAddrZip: 6608,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '147 HALE TER',
        RegAddrCity: 'BRIDGEPORT',
        RegAddrState: 'CT',
        RegAddrZip: 6610,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '59 GODDARD AVE',
        RegAddrCity: 'BRIDGEPORT',
        RegAddrState: 'CT',
        RegAddrZip: 6610,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '955 PEARL HARBOR ST',
        RegAddrCity: 'BRIDGEPORT',
        RegAddrState: 'CT',
        RegAddrZip: 6610,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '49 HOOKER RD',
        RegAddrCity: 'BRIDGEPORT',
        RegAddrState: 'CT',
        RegAddrZip: 6610,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '40 OLD STONE BRIDGE RD',
        RegAddrCity: 'COS COB',
        RegAddrState: 'CT',
        RegAddrZip: 6807,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '22 MIANUS VIEW TER',
        RegAddrCity: 'COS COB',
        RegAddrState: 'CT',
        RegAddrZip: 6807,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '316 VALLEY RD',
        RegAddrCity: 'COS COB',
        RegAddrState: 'CT',
        RegAddrZip: 6807,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '20 BEVERLY PL',
        RegAddrCity: 'DARIEN',
        RegAddrState: 'CT',
        RegAddrZip: 6820,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '68 EDGERTON ST',
        RegAddrCity: 'DARIEN',
        RegAddrState: 'CT',
        RegAddrZip: 6820,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '5 PARK PL',
        RegAddrCity: 'DARIEN',
        RegAddrState: 'CT',
        RegAddrZip: 6820,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '75 N PARK AVE',
        RegAddrCity: 'EASTON',
        RegAddrState: 'CT',
        RegAddrZip: 6612,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2 UNICORN LN',
        RegAddrCity: 'EASTON',
        RegAddrState: 'CT',
        RegAddrZip: 6612,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '161 COLONIAL DR',
        RegAddrCity: 'FAIRFIELD',
        RegAddrState: 'CT',
        RegAddrZip: 6824,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '166 LALLEY BLVD',
        RegAddrCity: 'FAIRFIELD',
        RegAddrState: 'CT',
        RegAddrZip: 6824,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '831 FAIRFIELD BEACH RD',
        RegAddrCity: 'FAIRFIELD',
        RegAddrState: 'CT',
        RegAddrZip: 6824,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '981 FAIRFIELD BEACH RD',
        RegAddrCity: 'FAIRFIELD',
        RegAddrState: 'CT',
        RegAddrZip: 6824,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '27 APPONYI ST',
        RegAddrCity: 'FAIRFIELD',
        RegAddrState: 'CT',
        RegAddrZip: 6824,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '22 HUNTER LN',
        RegAddrCity: 'FAIRFIELD',
        RegAddrState: 'CT',
        RegAddrZip: 6824,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1410 MELVILLE AVE',
        RegAddrCity: 'FAIRFIELD',
        RegAddrState: 'CT',
        RegAddrZip: 6825,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '99 MELVILLE AVE',
        RegAddrCity: 'FAIRFIELD',
        RegAddrState: 'CT',
        RegAddrZip: 6825,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '58 CAMPFIELD DR',
        RegAddrCity: 'FAIRFIELD',
        RegAddrState: 'CT',
        RegAddrZip: 6825,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '79 GATE RIDGE RD',
        RegAddrCity: 'FAIRFIELD',
        RegAddrState: 'CT',
        RegAddrZip: 6825,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '111 CASMIR DR',
        RegAddrCity: 'FAIRFIELD',
        RegAddrState: 'CT',
        RegAddrZip: 6825,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '45 2ND ST',
        RegAddrCity: 'FAIRFIELD',
        RegAddrState: 'CT',
        RegAddrZip: 6825,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '571 CASCADE DR',
        RegAddrCity: 'FAIRFIELD',
        RegAddrState: 'CT',
        RegAddrZip: 6825,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '53A RODWELL AVE',
        RegAddrCity: 'GREENWICH',
        RegAddrState: 'CT',
        RegAddrZip: 6830,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '121 CLAPBOARD RIDGE RD',
        RegAddrCity: 'GREENWICH',
        RegAddrState: 'CT',
        RegAddrZip: 6830,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '44B CHURCH ST W',
        RegAddrCity: 'GREENWICH',
        RegAddrState: 'CT',
        RegAddrZip: 6830,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '88 INDIAN FIELD RD',
        RegAddrCity: 'GREENWICH',
        RegAddrState: 'CT',
        RegAddrZip: 6830,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '64 OLD CHURCH RD',
        RegAddrCity: 'GREENWICH',
        RegAddrState: 'CT',
        RegAddrZip: 6830,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '23 HENRY ST',
        RegAddrCity: 'GREENWICH',
        RegAddrState: 'CT',
        RegAddrZip: 6830,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '29 TACONIC RD',
        RegAddrCity: 'GREENWICH',
        RegAddrState: 'CT',
        RegAddrZip: 6830,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '12 KIRBY ST',
        RegAddrCity: 'GREENWICH',
        RegAddrState: 'CT',
        RegAddrZip: 6830,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '69 BYRAM TERRACE DR',
        RegAddrCity: 'GREENWICH',
        RegAddrState: 'CT',
        RegAddrZip: 6831,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '15 HILLSIDE LN',
        RegAddrCity: 'MONROE',
        RegAddrState: 'CT',
        RegAddrZip: 6468,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '178 CHURCH ST',
        RegAddrCity: 'MONROE',
        RegAddrState: 'CT',
        RegAddrZip: 6468,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '25 SLEEPY HOLLOW DR',
        RegAddrCity: 'MONROE',
        RegAddrState: 'CT',
        RegAddrZip: 6468,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '51 E MAIDEN LN',
        RegAddrCity: 'MONROE',
        RegAddrState: 'CT',
        RegAddrZip: 6468,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '809 OENOKE RDG',
        RegAddrCity: 'NEW CANAAN',
        RegAddrState: 'CT',
        RegAddrZip: 6840,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '766 CHEESE SPRING RD',
        RegAddrCity: 'NEW CANAAN',
        RegAddrState: 'CT',
        RegAddrZip: 6840,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '67 ORCHARD DR',
        RegAddrCity: 'NEW CANAAN',
        RegAddrState: 'CT',
        RegAddrZip: 6840,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1650 PONUS RDG',
        RegAddrCity: 'NEW CANAAN',
        RegAddrState: 'CT',
        RegAddrZip: 6840,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '55 NEW NORWALK RD',
        RegAddrCity: 'NEW CANAAN',
        RegAddrState: 'CT',
        RegAddrZip: 6840,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '267 WHITE OAK SHADE RD',
        RegAddrCity: 'NEW CANAAN',
        RegAddrState: 'CT',
        RegAddrZip: 6840,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '14 LYNES PL',
        RegAddrCity: 'NORWALK',
        RegAddrState: 'CT',
        RegAddrZip: 6850,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '54 GRANDVIEW AVE',
        RegAddrCity: 'NORWALK',
        RegAddrState: 'CT',
        RegAddrZip: 6850,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '9 RIVERVIEW DR',
        RegAddrCity: 'NORWALK',
        RegAddrState: 'CT',
        RegAddrZip: 6850,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '21 LITTLE FOX LN',
        RegAddrCity: 'NORWALK',
        RegAddrState: 'CT',
        RegAddrZip: 6850,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '64 GRANDVIEW AVE',
        RegAddrCity: 'NORWALK',
        RegAddrState: 'CT',
        RegAddrZip: 6850,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '11 LOUNDSBURY AVE',
        RegAddrCity: 'NORWALK',
        RegAddrState: 'CT',
        RegAddrZip: 6851,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '20 KATY LN',
        RegAddrCity: 'NORWALK',
        RegAddrState: 'CT',
        RegAddrZip: 6851,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '6 RICHLEE RD',
        RegAddrCity: 'NORWALK',
        RegAddrState: 'CT',
        RegAddrZip: 6851,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '19 GETNER TRL',
        RegAddrCity: 'NORWALK',
        RegAddrState: 'CT',
        RegAddrZip: 6854,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '334 ELY AVE',
        RegAddrCity: 'NORWALK',
        RegAddrState: 'CT',
        RegAddrZip: 6854,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '67 SOUNDVIEW AVE',
        RegAddrCity: 'NORWALK',
        RegAddrState: 'CT',
        RegAddrZip: 6854,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '20 SOUNDVIEW AVE',
        RegAddrCity: 'NORWALK',
        RegAddrState: 'CT',
        RegAddrZip: 6854,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '12 NATHAN HALE DR',
        RegAddrCity: 'NORWALK',
        RegAddrState: 'CT',
        RegAddrZip: 6854,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '4 TAFT ST',
        RegAddrCity: 'NORWALK',
        RegAddrState: 'CT',
        RegAddrZip: 6854,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '21 FERRIS DR',
        RegAddrCity: 'OLD GREENWICH',
        RegAddrState: 'CT',
        RegAddrZip: 6870,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '62 LARKEY RD',
        RegAddrCity: 'OXFORD',
        RegAddrState: 'CT',
        RegAddrZip: 6478,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '52 GREAT OAK RD',
        RegAddrCity: 'OXFORD',
        RegAddrState: 'CT',
        RegAddrZip: 6478,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '9 PINE MOUNTAIN RD',
        RegAddrCity: 'REDDING',
        RegAddrState: 'CT',
        RegAddrZip: 6896,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '22 WINDING BROOK LN',
        RegAddrCity: 'REDDING',
        RegAddrState: 'CT',
        RegAddrZip: 6896,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '58 CRESCENT DR',
        RegAddrCity: 'RIDGEFIELD',
        RegAddrState: 'CT',
        RegAddrZip: 6877,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '71 LEDGES RD',
        RegAddrCity: 'RIDGEFIELD',
        RegAddrState: 'CT',
        RegAddrZip: 6877,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '85 NEW ST',
        RegAddrCity: 'RIDGEFIELD',
        RegAddrState: 'CT',
        RegAddrZip: 6877,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '59 LAWSON LN',
        RegAddrCity: 'RIDGEFIELD',
        RegAddrState: 'CT',
        RegAddrZip: 6877,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '40 BEECHWOOD LN',
        RegAddrCity: 'RIDGEFIELD',
        RegAddrState: 'CT',
        RegAddrZip: 6877,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '32 CENTERVIEW DR',
        RegAddrCity: 'SHELTON',
        RegAddrState: 'CT',
        RegAddrZip: 6484,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '6 ADAMS DR',
        RegAddrCity: 'SHELTON',
        RegAddrState: 'CT',
        RegAddrZip: 6484,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '53 EASTON CT',
        RegAddrCity: 'SHELTON',
        RegAddrState: 'CT',
        RegAddrZip: 6484,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '117 BUDDINGTON RD',
        RegAddrCity: 'SHELTON',
        RegAddrState: 'CT',
        RegAddrZip: 6484,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '57 WEST ST',
        RegAddrCity: 'SHELTON',
        RegAddrState: 'CT',
        RegAddrZip: 6484,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '49 OJIBWA RD',
        RegAddrCity: 'SHELTON',
        RegAddrState: 'CT',
        RegAddrZip: 6484,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '45 MAPLE AVE',
        RegAddrCity: 'SHELTON',
        RegAddrState: 'CT',
        RegAddrZip: 6484,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '47 SPICEBUSH LN',
        RegAddrCity: 'SHELTON',
        RegAddrState: 'CT',
        RegAddrZip: 6484,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '6 WINFIELD DR',
        RegAddrCity: 'SHELTON',
        RegAddrState: 'CT',
        RegAddrZip: 6484,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '74 HIGH MEADOW RD',
        RegAddrCity: 'SOUTHPORT',
        RegAddrState: 'CT',
        RegAddrZip: 6890,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '26 W HILL LN',
        RegAddrCity: 'STAMFORD',
        RegAddrState: 'CT',
        RegAddrZip: 6902,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '63 WOODRIDGE DR S',
        RegAddrCity: 'STAMFORD',
        RegAddrState: 'CT',
        RegAddrZip: 6902,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '23 DURANT ST',
        RegAddrCity: 'STAMFORD',
        RegAddrState: 'CT',
        RegAddrZip: 6902,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '25 MACARTHUR LN',
        RegAddrCity: 'STAMFORD',
        RegAddrState: 'CT',
        RegAddrZip: 6902,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '140 CONNECTICUT AVE',
        RegAddrCity: 'STAMFORD',
        RegAddrState: 'CT',
        RegAddrZip: 6902,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '68 DORAL FARM RD',
        RegAddrCity: 'STAMFORD',
        RegAddrState: 'CT',
        RegAddrZip: 6902,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '218 WILLOWBROOK AVE',
        RegAddrCity: 'STAMFORD',
        RegAddrState: 'CT',
        RegAddrZip: 6902,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '65 CUSTER ST',
        RegAddrCity: 'STAMFORD',
        RegAddrState: 'CT',
        RegAddrZip: 6902,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '18 BURR ST',
        RegAddrCity: 'STAMFORD',
        RegAddrState: 'CT',
        RegAddrZip: 6902,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '435 OCEAN DR W',
        RegAddrCity: 'STAMFORD',
        RegAddrState: 'CT',
        RegAddrZip: 6902,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '58 ALBIN RD',
        RegAddrCity: 'STAMFORD',
        RegAddrState: 'CT',
        RegAddrZip: 6902,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '242 ROCKY RAPIDS RD',
        RegAddrCity: 'STAMFORD',
        RegAddrState: 'CT',
        RegAddrZip: 6903,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '304 ROCKY RAPIDS RD',
        RegAddrCity: 'STAMFORD',
        RegAddrState: 'CT',
        RegAddrZip: 6903,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '39 JORDAN LN',
        RegAddrCity: 'STAMFORD',
        RegAddrState: 'CT',
        RegAddrZip: 6903,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '171 HIGH CLEAR DR',
        RegAddrCity: 'STAMFORD',
        RegAddrState: 'CT',
        RegAddrZip: 6905,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '236 BELLTOWN RD',
        RegAddrCity: 'STAMFORD',
        RegAddrState: 'CT',
        RegAddrZip: 6905,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '117 EDWARD PL',
        RegAddrCity: 'STAMFORD',
        RegAddrState: 'CT',
        RegAddrZip: 6905,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '81 COWING PL',
        RegAddrCity: 'STAMFORD',
        RegAddrState: 'CT',
        RegAddrZip: 6906,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '120 PARRY RD',
        RegAddrCity: 'STAMFORD',
        RegAddrState: 'CT',
        RegAddrZip: 6907,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '175 OLD DIKE RD',
        RegAddrCity: 'TRUMBULL',
        RegAddrState: 'CT',
        RegAddrZip: 6611,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '215 FERNWOOD RD',
        RegAddrCity: 'TRUMBULL',
        RegAddrState: 'CT',
        RegAddrZip: 6611,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '35 VIXEN RD',
        RegAddrCity: 'TRUMBULL',
        RegAddrState: 'CT',
        RegAddrZip: 6611,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '27 MAYMONT LN',
        RegAddrCity: 'TRUMBULL',
        RegAddrState: 'CT',
        RegAddrZip: 6611,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '36 FIREHOUSE RD',
        RegAddrCity: 'TRUMBULL',
        RegAddrState: 'CT',
        RegAddrZip: 6611,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '4279 MADISON AVE',
        RegAddrCity: 'TRUMBULL',
        RegAddrState: 'CT',
        RegAddrZip: 6611,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '5259 MADISON AVE',
        RegAddrCity: 'TRUMBULL',
        RegAddrState: 'CT',
        RegAddrZip: 6611,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '56 WINSLOW RD',
        RegAddrCity: 'TRUMBULL',
        RegAddrState: 'CT',
        RegAddrZip: 6611,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '38 FINCHWOOD DR',
        RegAddrCity: 'TRUMBULL',
        RegAddrState: 'CT',
        RegAddrZip: 6611,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2750 RESERVOIR AVE',
        RegAddrCity: 'TRUMBULL',
        RegAddrState: 'CT',
        RegAddrZip: 6611,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '18 CARDINAL RD',
        RegAddrCity: 'WESTON',
        RegAddrState: 'CT',
        RegAddrZip: 6883,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '30 ELLERY LN',
        RegAddrCity: 'WESTPORT',
        RegAddrState: 'CT',
        RegAddrZip: 6880,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1 MYSTIC LN',
        RegAddrCity: 'WESTPORT',
        RegAddrState: 'CT',
        RegAddrZip: 6880,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '44 PARK LN',
        RegAddrCity: 'WESTPORT',
        RegAddrState: 'CT',
        RegAddrZip: 6880,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '112 HILLSPOINT RD',
        RegAddrCity: 'WESTPORT',
        RegAddrState: 'CT',
        RegAddrZip: 6880,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '5 GRANT LN',
        RegAddrCity: 'WESTPORT',
        RegAddrState: 'CT',
        RegAddrZip: 6880,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '8 GREEN ACRE LN',
        RegAddrCity: 'WESTPORT',
        RegAddrState: 'CT',
        RegAddrZip: 6880,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '7 DARK POND TRL',
        RegAddrCity: 'WILTON',
        RegAddrState: 'CT',
        RegAddrZip: 6897,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '34 HORSESHOE RD',
        RegAddrCity: 'WILTON',
        RegAddrState: 'CT',
        RegAddrZip: 6897,
        CongressionalDistrict: 4,
      },
    ],
  },
  {
    name: 'CT-5',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/CT-5?ref=gh-pages',
    state: 'CT',
    state_and_number: 'CT-5',
    zip: 6001,
    congressionalDistrict: 5,
    addressesInDistrict: [
      {
        RegAddrLine1: '16 ROCKLEDGE DR',
        RegAddrCity: 'AVON',
        RegAddrState: 'CT',
        RegAddrZip: 6001,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '101 BICKFORD EXT',
        RegAddrCity: 'AVON',
        RegAddrState: 'CT',
        RegAddrZip: 6001,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '76 LEXINGTON RD',
        RegAddrCity: 'AVON',
        RegAddrState: 'CT',
        RegAddrZip: 6001,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '6 BROWNSTONE DR',
        RegAddrCity: 'AVON',
        RegAddrState: 'CT',
        RegAddrZip: 6001,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '69 MAPLE AVE',
        RegAddrCity: 'BETHEL',
        RegAddrState: 'CT',
        RegAddrZip: 6801,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '5 BENEDICT RD',
        RegAddrCity: 'BETHEL',
        RegAddrState: 'CT',
        RegAddrZip: 6801,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '39 WOODLAND RD',
        RegAddrCity: 'BETHLEHEM',
        RegAddrState: 'CT',
        RegAddrZip: 6751,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '78 HARRISON LN',
        RegAddrCity: 'BETHLEHEM',
        RegAddrState: 'CT',
        RegAddrZip: 6751,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '369 HARD HILL RD S',
        RegAddrCity: 'BETHLEHEM',
        RegAddrState: 'CT',
        RegAddrZip: 6751,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '185 BERRY RD',
        RegAddrCity: 'BRIDGEWATER',
        RegAddrState: 'CT',
        RegAddrZip: 6752,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '7 NEW BRIDGE RD',
        RegAddrCity: 'BROOKFIELD',
        RegAddrState: 'CT',
        RegAddrZip: 6804,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '8 TOWER RD',
        RegAddrCity: 'BROOKFIELD',
        RegAddrState: 'CT',
        RegAddrZip: 6804,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '16 ARAMON CIR',
        RegAddrCity: 'BROOKFIELD',
        RegAddrState: 'CT',
        RegAddrZip: 6804,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '277 SPIELMAN HWY',
        RegAddrCity: 'BURLINGTON',
        RegAddrState: 'CT',
        RegAddrZip: 6013,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '61 WILDCAT RD',
        RegAddrCity: 'BURLINGTON',
        RegAddrState: 'CT',
        RegAddrZip: 6013,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '14 MAIN ST',
        RegAddrCity: 'CANAAN',
        RegAddrState: 'CT',
        RegAddrZip: 6018,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '42 GARRETT RD',
        RegAddrCity: 'CANTON',
        RegAddrState: 'CT',
        RegAddrZip: 6019,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '147 INDIAN HILL RD',
        RegAddrCity: 'CANTON',
        RegAddrState: 'CT',
        RegAddrZip: 6019,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '92 N MOUNTAIN RD',
        RegAddrCity: 'CANTON',
        RegAddrState: 'CT',
        RegAddrZip: 6019,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '51 RAILROAD AVE',
        RegAddrCity: 'CHESHIRE',
        RegAddrState: 'CT',
        RegAddrZip: 6410,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '60 BROOK LN',
        RegAddrCity: 'CHESHIRE',
        RegAddrState: 'CT',
        RegAddrZip: 6410,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '376 CHARLES DR',
        RegAddrCity: 'CHESHIRE',
        RegAddrState: 'CT',
        RegAddrZip: 6410,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '45 JUDSON CT',
        RegAddrCity: 'CHESHIRE',
        RegAddrState: 'CT',
        RegAddrZip: 6410,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '35 CURRIER WAY',
        RegAddrCity: 'CHESHIRE',
        RegAddrState: 'CT',
        RegAddrZip: 6410,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '445 N BROOKSVALE RD',
        RegAddrCity: 'CHESHIRE',
        RegAddrState: 'CT',
        RegAddrZip: 6410,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '15 BRIARWOOD DR',
        RegAddrCity: 'DANBURY',
        RegAddrState: 'CT',
        RegAddrZip: 6810,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '4 CEL BRET DR',
        RegAddrCity: 'DANBURY',
        RegAddrState: 'CT',
        RegAddrZip: 6810,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '53 WOODCREST LN',
        RegAddrCity: 'DANBURY',
        RegAddrState: 'CT',
        RegAddrZip: 6810,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '17 RIDGE RD',
        RegAddrCity: 'DANBURY',
        RegAddrState: 'CT',
        RegAddrZip: 6810,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '30 CARRIAGE HOUSE DR',
        RegAddrCity: 'DANBURY',
        RegAddrState: 'CT',
        RegAddrZip: 6810,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '16 IVY LN',
        RegAddrCity: 'DANBURY',
        RegAddrState: 'CT',
        RegAddrZip: 6811,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '40 FOX DEN RD',
        RegAddrCity: 'DANBURY',
        RegAddrState: 'CT',
        RegAddrZip: 6811,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '49 KING ST',
        RegAddrCity: 'DANBURY',
        RegAddrState: 'CT',
        RegAddrZip: 6811,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '4 SUNNYSIDE AVE',
        RegAddrCity: 'DANBURY',
        RegAddrState: 'CT',
        RegAddrZip: 6811,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '15 PINEVIEW DR',
        RegAddrCity: 'DANBURY',
        RegAddrState: 'CT',
        RegAddrZip: 6811,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '11 CLEARBROOK RD',
        RegAddrCity: 'DANBURY',
        RegAddrState: 'CT',
        RegAddrZip: 6811,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '39 BALL POND RD',
        RegAddrCity: 'DANBURY',
        RegAddrState: 'CT',
        RegAddrZip: 6811,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '86 TUNXIS VLG',
        RegAddrCity: 'FARMINGTON',
        RegAddrState: 'CT',
        RegAddrZip: 6032,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '27 COPE FARMS RD',
        RegAddrCity: 'FARMINGTON',
        RegAddrState: 'CT',
        RegAddrZip: 6032,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '166 E HYERDALE DR',
        RegAddrCity: 'GOSHEN',
        RegAddrState: 'CT',
        RegAddrZip: 6756,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '114 BULL RD',
        RegAddrCity: 'HARWINTON',
        RegAddrState: 'CT',
        RegAddrZip: 6791,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '31 MILLERTON RD',
        RegAddrCity: 'LAKEVILLE',
        RegAddrState: 'CT',
        RegAddrZip: 6039,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '114 E MAIN ST',
        RegAddrCity: 'MERIDEN',
        RegAddrState: 'CT',
        RegAddrZip: 6450,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '69 WESTVIEW DR',
        RegAddrCity: 'MERIDEN',
        RegAddrState: 'CT',
        RegAddrZip: 6450,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '373 YALE AVE',
        RegAddrCity: 'MERIDEN',
        RegAddrState: 'CT',
        RegAddrZip: 6450,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '634 MURDOCK AVE',
        RegAddrCity: 'MERIDEN',
        RegAddrState: 'CT',
        RegAddrZip: 6450,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '16 PRINCE ST',
        RegAddrCity: 'MERIDEN',
        RegAddrState: 'CT',
        RegAddrZip: 6450,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '77 LOWE AVE',
        RegAddrCity: 'MERIDEN',
        RegAddrState: 'CT',
        RegAddrZip: 6450,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '227 OXFORD CT',
        RegAddrCity: 'MERIDEN',
        RegAddrState: 'CT',
        RegAddrZip: 6450,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '1165 N BROAD ST',
        RegAddrCity: 'MERIDEN',
        RegAddrState: 'CT',
        RegAddrZip: 6450,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '148 OXBOW DR',
        RegAddrCity: 'MERIDEN',
        RegAddrState: 'CT',
        RegAddrZip: 6450,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '91 LINSLEY AVE',
        RegAddrCity: 'MERIDEN',
        RegAddrState: 'CT',
        RegAddrZip: 6451,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '173 DANA LN',
        RegAddrCity: 'MERIDEN',
        RegAddrState: 'CT',
        RegAddrZip: 6451,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '156 BALDWIN ST',
        RegAddrCity: 'MERIDEN',
        RegAddrState: 'CT',
        RegAddrZip: 6451,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '12 PLYMOUTH LN',
        RegAddrCity: 'MIDDLEBURY',
        RegAddrState: 'CT',
        RegAddrZip: 6762,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '384 BENSON RD',
        RegAddrCity: 'MIDDLEBURY',
        RegAddrState: 'CT',
        RegAddrZip: 6762,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '50 WALNUT ST',
        RegAddrCity: 'NEW BRITAIN',
        RegAddrState: 'CT',
        RegAddrZip: 6051,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '62 BUELL ST',
        RegAddrCity: 'NEW BRITAIN',
        RegAddrState: 'CT',
        RegAddrZip: 6051,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '335 HIGH ST',
        RegAddrCity: 'NEW BRITAIN',
        RegAddrState: 'CT',
        RegAddrZip: 6051,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '117 SEXTON ST',
        RegAddrCity: 'NEW BRITAIN',
        RegAddrState: 'CT',
        RegAddrZip: 6051,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '89 ELBRIDGE RD',
        RegAddrCity: 'NEW BRITAIN',
        RegAddrState: 'CT',
        RegAddrZip: 6052,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '433 MONROE ST',
        RegAddrCity: 'NEW BRITAIN',
        RegAddrState: 'CT',
        RegAddrZip: 6052,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '116 HICKORY HILL RD',
        RegAddrCity: 'NEW BRITAIN',
        RegAddrState: 'CT',
        RegAddrZip: 6052,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '81 PATTON DR',
        RegAddrCity: 'NEW BRITAIN',
        RegAddrState: 'CT',
        RegAddrZip: 6053,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '126 PERSHING AVE',
        RegAddrCity: 'NEW BRITAIN',
        RegAddrState: 'CT',
        RegAddrZip: 6053,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '279 FARMINGTON AVE',
        RegAddrCity: 'NEW BRITAIN',
        RegAddrState: 'CT',
        RegAddrZip: 6053,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '34 ARMISTICE ST',
        RegAddrCity: 'NEW BRITAIN',
        RegAddrState: 'CT',
        RegAddrZip: 6053,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '34 MOHAWK ST',
        RegAddrCity: 'NEW BRITAIN',
        RegAddrState: 'CT',
        RegAddrZip: 6053,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '210 GOVERNOR ST',
        RegAddrCity: 'NEW BRITAIN',
        RegAddrState: 'CT',
        RegAddrZip: 6053,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '110 SMITH ST',
        RegAddrCity: 'NEW BRITAIN',
        RegAddrState: 'CT',
        RegAddrZip: 6053,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '29 LAVELLE AVE',
        RegAddrCity: 'NEW FAIRFIELD',
        RegAddrState: 'CT',
        RegAddrZip: 6812,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '145 PINE HILL RD',
        RegAddrCity: 'NEW FAIRFIELD',
        RegAddrState: 'CT',
        RegAddrZip: 6812,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '14 DONNELLY DR',
        RegAddrCity: 'NEW FAIRFIELD',
        RegAddrState: 'CT',
        RegAddrZip: 6812,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '18 CONCORD WAY',
        RegAddrCity: 'NEW MILFORD',
        RegAddrState: 'CT',
        RegAddrZip: 6776,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '75 BEARDSLEY RD',
        RegAddrCity: 'NEW MILFORD',
        RegAddrState: 'CT',
        RegAddrZip: 6776,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '395 LITCHFIELD RD',
        RegAddrCity: 'NEW MILFORD',
        RegAddrState: 'CT',
        RegAddrZip: 6776,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '3 WIETING RD',
        RegAddrCity: 'NEW MILFORD',
        RegAddrState: 'CT',
        RegAddrZip: 6776,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '57 LITTLEFIELD RD',
        RegAddrCity: 'NEW MILFORD',
        RegAddrState: 'CT',
        RegAddrZip: 6776,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '153 BOGGS HILL RD',
        RegAddrCity: 'NEWTOWN',
        RegAddrState: 'CT',
        RegAddrZip: 6470,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '7 ARLYN RIDGE RD',
        RegAddrCity: 'NEWTOWN',
        RegAddrState: 'CT',
        RegAddrZip: 6470,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '37 BOULEVARD',
        RegAddrCity: 'NEWTOWN',
        RegAddrState: 'CT',
        RegAddrZip: 6470,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '34 PINE TREE HILL RD',
        RegAddrCity: 'NEWTOWN',
        RegAddrState: 'CT',
        RegAddrZip: 6470,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '3 TROUT BROOK CIR',
        RegAddrCity: 'NEWTOWN',
        RegAddrState: 'CT',
        RegAddrZip: 6470,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '19 RYAN AVE',
        RegAddrCity: 'NORTH CANAAN',
        RegAddrState: 'CT',
        RegAddrZip: 6018,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '442 SYLVAN LAKE RD',
        RegAddrCity: 'OAKVILLE',
        RegAddrState: 'CT',
        RegAddrZip: 6779,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '18 WOODSIDE LN',
        RegAddrCity: 'PLAINVILLE',
        RegAddrState: 'CT',
        RegAddrZip: 6062,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '10 PEQUOT RD',
        RegAddrCity: 'PLAINVILLE',
        RegAddrState: 'CT',
        RegAddrZip: 6062,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '35 WAYNE DR',
        RegAddrCity: 'PLAINVILLE',
        RegAddrState: 'CT',
        RegAddrZip: 6062,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '20 CHURCH ST',
        RegAddrCity: 'PLAINVILLE',
        RegAddrState: 'CT',
        RegAddrZip: 6062,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '214 LAKE PLYMOUTH BLVD',
        RegAddrCity: 'PLYMOUTH',
        RegAddrState: 'CT',
        RegAddrZip: 6782,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '34 RUCUM RD',
        RegAddrCity: 'ROXBURY',
        RegAddrState: 'CT',
        RegAddrZip: 6783,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '12 SKIDMORE LN',
        RegAddrCity: 'SANDY HOOK',
        RegAddrState: 'CT',
        RegAddrZip: 6482,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '3 BISHOP CIR',
        RegAddrCity: 'SANDY HOOK',
        RegAddrState: 'CT',
        RegAddrZip: 6482,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '55 NEW LEBBON RD',
        RegAddrCity: 'SANDY HOOK',
        RegAddrState: 'CT',
        RegAddrZip: 6482,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '12 OLD SHARON RD 1',
        RegAddrCity: 'SHARON',
        RegAddrState: 'CT',
        RegAddrZip: 6069,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '2 LOCUST LN',
        RegAddrCity: 'SHERMAN',
        RegAddrState: 'CT',
        RegAddrZip: 6784,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '37 AUGUST RD',
        RegAddrCity: 'SIMSBURY',
        RegAddrState: 'CT',
        RegAddrZip: 6070,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '22 MUNNISUNK DR',
        RegAddrCity: 'SIMSBURY',
        RegAddrState: 'CT',
        RegAddrZip: 6070,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '4 THORNBUSH',
        RegAddrCity: 'SIMSBURY',
        RegAddrState: 'CT',
        RegAddrZip: 6070,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '278 LAURELWOOD LN',
        RegAddrCity: 'SOUTHBURY',
        RegAddrState: 'CT',
        RegAddrZip: 6488,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '123 BEECHER DR',
        RegAddrCity: 'SOUTHBURY',
        RegAddrState: 'CT',
        RegAddrZip: 6488,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '52 STERLING RDG',
        RegAddrCity: 'SOUTHBURY',
        RegAddrState: 'CT',
        RegAddrZip: 6488,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '1031B HERITAGE CONDO 17',
        RegAddrCity: 'SOUTHBURY',
        RegAddrState: 'CT',
        RegAddrZip: 6488,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '579A HERITAGE CONDO 15A',
        RegAddrCity: 'SOUTHBURY',
        RegAddrState: 'CT',
        RegAddrZip: 6488,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '137 POLAND BROOK RD',
        RegAddrCity: 'TERRYVILLE',
        RegAddrState: 'CT',
        RegAddrZip: 6786,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '359 VALLEY VIEW RD',
        RegAddrCity: 'THOMASTON',
        RegAddrState: 'CT',
        RegAddrZip: 6787,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '112 WALNUT ST',
        RegAddrCity: 'THOMASTON',
        RegAddrState: 'CT',
        RegAddrZip: 6787,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '472 MAIN ST',
        RegAddrCity: 'TORRINGTON',
        RegAddrState: 'CT',
        RegAddrZip: 6790,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '193 TORRINGFORD WEST ST',
        RegAddrCity: 'TORRINGTON',
        RegAddrState: 'CT',
        RegAddrZip: 6790,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '197 JOHNSON ST',
        RegAddrCity: 'TORRINGTON',
        RegAddrState: 'CT',
        RegAddrZip: 6790,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '141 WASHINGTON AVE',
        RegAddrCity: 'TORRINGTON',
        RegAddrState: 'CT',
        RegAddrZip: 6790,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '534 BRIGHTWOOD AVE',
        RegAddrCity: 'TORRINGTON',
        RegAddrState: 'CT',
        RegAddrZip: 6790,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '63 BURLINGTON RD',
        RegAddrCity: 'UNIONVILLE',
        RegAddrState: 'CT',
        RegAddrZip: 6085,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '6 BANBURY CRES',
        RegAddrCity: 'UNIONVILLE',
        RegAddrState: 'CT',
        RegAddrZip: 6085,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '158 PERRY ST',
        RegAddrCity: 'UNIONVILLE',
        RegAddrState: 'CT',
        RegAddrZip: 6085,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '126 KENT RD',
        RegAddrCity: 'WARREN',
        RegAddrState: 'CT',
        RegAddrZip: 6754,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '61 ABOVE ALL RD',
        RegAddrCity: 'WARREN',
        RegAddrState: 'CT',
        RegAddrZip: 6754,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '67 DIVISION ST',
        RegAddrCity: 'WATERBURY',
        RegAddrState: 'CT',
        RegAddrZip: 6704,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '60 TRANSIT ST',
        RegAddrCity: 'WATERBURY',
        RegAddrState: 'CT',
        RegAddrZip: 6704,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '66 ELDRIDGE ST',
        RegAddrCity: 'WATERBURY',
        RegAddrState: 'CT',
        RegAddrZip: 6704,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '149 HAUSER ST',
        RegAddrCity: 'WATERBURY',
        RegAddrState: 'CT',
        RegAddrZip: 6704,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '149 MORTON RD',
        RegAddrCity: 'WATERBURY',
        RegAddrState: 'CT',
        RegAddrZip: 6705,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '73 WILDEMERE AVE',
        RegAddrCity: 'WATERBURY',
        RegAddrState: 'CT',
        RegAddrZip: 6705,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '149 CELIA DR',
        RegAddrCity: 'WATERBURY',
        RegAddrState: 'CT',
        RegAddrZip: 6705,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '55 BRENTWOOD AVE',
        RegAddrCity: 'WATERBURY',
        RegAddrState: 'CT',
        RegAddrZip: 6705,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '70 BUNKER HILL AVE',
        RegAddrCity: 'WATERBURY',
        RegAddrState: 'CT',
        RegAddrZip: 6708,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '151 DELHURST DR',
        RegAddrCity: 'WATERTOWN',
        RegAddrState: 'CT',
        RegAddrZip: 6779,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '85 DUNROBIN LN',
        RegAddrCity: 'WATERTOWN',
        RegAddrState: 'CT',
        RegAddrZip: 6795,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '21 BIRCH ST',
        RegAddrCity: 'WATERTOWN',
        RegAddrState: 'CT',
        RegAddrZip: 6795,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '111 BARNES RD',
        RegAddrCity: 'WATERTOWN',
        RegAddrState: 'CT',
        RegAddrZip: 6795,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '15 CROFT LN',
        RegAddrCity: 'WEATOGUE',
        RegAddrState: 'CT',
        RegAddrZip: 6089,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '30 BLUE RIDGE DR',
        RegAddrCity: 'WEATOGUE',
        RegAddrState: 'CT',
        RegAddrZip: 6089,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '157A CREAM HILL RD',
        RegAddrCity: 'WEST CORNWALL',
        RegAddrState: 'CT',
        RegAddrZip: 6796,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '129 W MOUNTAIN RD',
        RegAddrCity: 'WEST SIMSBURY',
        RegAddrState: 'CT',
        RegAddrZip: 6092,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '14 WILSON RD',
        RegAddrCity: 'WOLCOTT',
        RegAddrState: 'CT',
        RegAddrZip: 6716,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '27 MICHAEL TER',
        RegAddrCity: 'WOLCOTT',
        RegAddrState: 'CT',
        RegAddrZip: 6716,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '65 S COLMAN RD',
        RegAddrCity: 'WOLCOTT',
        RegAddrState: 'CT',
        RegAddrZip: 6716,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '24 DEEPWOOD DR',
        RegAddrCity: 'WOLCOTT',
        RegAddrState: 'CT',
        RegAddrZip: 6716,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '49 N FORTY RD',
        RegAddrCity: 'WOODBURY',
        RegAddrState: 'CT',
        RegAddrZip: 6798,
        CongressionalDistrict: 5,
      },
    ],
  },
  {
    name: 'DC-0',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/DC-0?ref=gh-pages',
    state: 'DC',
    state_and_number: 'DC-0',
    congressionalDistrict: 0,
    addressesInDistrict: [],
  },
  {
    name: 'DE-0',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/DE-0?ref=gh-pages',
    state: 'DE',
    state_and_number: 'DE-0',
    zip: 19701,
    congressionalDistrict: 0,
    addressesInDistrict: [
      {
        RegAddrLine1: '983 RUE MADORA',
        RegAddrCity: 'BEAR',
        RegAddrState: 'DE',
        RegAddrZip: 19701,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '48 MARK CT',
        RegAddrCity: 'BEAR',
        RegAddrState: 'DE',
        RegAddrZip: 19701,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '725 KING JAMES CT',
        RegAddrCity: 'BEAR',
        RegAddrState: 'DE',
        RegAddrZip: 19701,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '621 WOODCHUCK PL',
        RegAddrCity: 'BEAR',
        RegAddrState: 'DE',
        RegAddrZip: 19701,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '203 POND VIEW DR',
        RegAddrCity: 'BETHANY BEACH',
        RegAddrState: 'DE',
        RegAddrZip: 19930,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '415 S MAIN ST',
        RegAddrCity: 'BRIDGEVILLE',
        RegAddrState: 'DE',
        RegAddrZip: 19933,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '16782 OAK RD',
        RegAddrCity: 'BRIDGEVILLE',
        RegAddrState: 'DE',
        RegAddrZip: 19933,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '136 CARROLL LN',
        RegAddrCity: 'CAMDEN WYO',
        RegAddrState: 'DE',
        RegAddrZip: 19934,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '230 FAWN PATH DR',
        RegAddrCity: 'CAMDEN WYO',
        RegAddrState: 'DE',
        RegAddrZip: 19934,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '32 PALACE AVE',
        RegAddrCity: 'CLAYMONT',
        RegAddrState: 'DE',
        RegAddrZip: 19703,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '209 PENNSYLVANIA AVE',
        RegAddrCity: 'CLAYMONT',
        RegAddrState: 'DE',
        RegAddrZip: 19703,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '177 TRACER DR',
        RegAddrCity: 'CLAYTON',
        RegAddrState: 'DE',
        RegAddrZip: 19938,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '111 HIGHLAND AVE',
        RegAddrCity: 'CLAYTON',
        RegAddrState: 'DE',
        RegAddrZip: 19938,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '13 GUM TREE RD',
        RegAddrCity: 'DAGSBORO',
        RegAddrState: 'DE',
        RegAddrZip: 19939,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '8 REYBOLD DR',
        RegAddrCity: 'DELAWARE CITY',
        RegAddrState: 'DE',
        RegAddrZip: 19706,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1200 N DUPONT HWY',
        RegAddrCity: 'DOVER',
        RegAddrState: 'DE',
        RegAddrZip: 19901,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '26 W FAIRFIELD DR',
        RegAddrCity: 'DOVER',
        RegAddrState: 'DE',
        RegAddrZip: 19901,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '3183 LEIPSIC RD',
        RegAddrCity: 'DOVER',
        RegAddrState: 'DE',
        RegAddrZip: 19901,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '18 SESKINORE CT',
        RegAddrCity: 'DOVER',
        RegAddrState: 'DE',
        RegAddrZip: 19904,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '116 CRESCENT DR',
        RegAddrCity: 'DOVER',
        RegAddrState: 'DE',
        RegAddrZip: 19904,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '2743 PEARSONS CORNER RD',
        RegAddrCity: 'DOVER',
        RegAddrState: 'DE',
        RegAddrZip: 19904,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '250 LOCHMEATH WAY',
        RegAddrCity: 'DOVER',
        RegAddrState: 'DE',
        RegAddrZip: 19904,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '911 BISON RD',
        RegAddrCity: 'DOVER',
        RegAddrState: 'DE',
        RegAddrZip: 19904,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '16 FREEDOM DR',
        RegAddrCity: 'DOVER',
        RegAddrState: 'DE',
        RegAddrZip: 19904,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '16337 BEACH HWY',
        RegAddrCity: 'ELLENDALE',
        RegAddrState: 'DE',
        RegAddrZip: 19941,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '7359 S DUPONT HWY',
        RegAddrCity: 'FELTON',
        RegAddrState: 'DE',
        RegAddrZip: 19943,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '132 BALDWIN CT',
        RegAddrCity: 'FELTON',
        RegAddrState: 'DE',
        RegAddrZip: 19943,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '74 STARKEY LN',
        RegAddrCity: 'FELTON',
        RegAddrState: 'DE',
        RegAddrZip: 19943,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1441 BUFFALO RD',
        RegAddrCity: 'FREDERICA',
        RegAddrState: 'DE',
        RegAddrZip: 19946,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '112 OTTER WAY',
        RegAddrCity: 'FREDERICA',
        RegAddrState: 'DE',
        RegAddrZip: 19946,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '559 OTTER WAY',
        RegAddrCity: 'FREDERICA',
        RegAddrState: 'DE',
        RegAddrZip: 19946,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '224 E MARKET ST',
        RegAddrCity: 'GEORGETOWN',
        RegAddrState: 'DE',
        RegAddrZip: 19947,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '18984 GRAVEL HILL RD',
        RegAddrCity: 'GEORGETOWN',
        RegAddrState: 'DE',
        RegAddrZip: 19947,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '18119 REDDEN RD',
        RegAddrCity: 'GEORGETOWN',
        RegAddrState: 'DE',
        RegAddrZip: 19947,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '21501 PARK AVE',
        RegAddrCity: 'GEORGETOWN',
        RegAddrState: 'DE',
        RegAddrZip: 19947,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '20669 SAVANNAH RD',
        RegAddrCity: 'GEORGETOWN',
        RegAddrState: 'DE',
        RegAddrZip: 19947,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '6396 HICKMAN RD',
        RegAddrCity: 'GREENWOOD',
        RegAddrState: 'DE',
        RegAddrZip: 19950,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1176 HOGTOWN RD',
        RegAddrCity: 'HARRINGTON',
        RegAddrState: 'DE',
        RegAddrZip: 19952,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '8358 VERNON RD',
        RegAddrCity: 'HARRINGTON',
        RegAddrState: 'DE',
        RegAddrZip: 19952,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '106 CARDINAL CIR',
        RegAddrCity: 'HOCKESSIN',
        RegAddrState: 'DE',
        RegAddrZip: 19707,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '506 DEFOE RD',
        RegAddrCity: 'HOCKESSIN',
        RegAddrState: 'DE',
        RegAddrZip: 19707,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '228 VALLEY LN',
        RegAddrCity: 'HOCKESSIN',
        RegAddrState: 'DE',
        RegAddrZip: 19707,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '53 HARVEST LN',
        RegAddrCity: 'HOCKESSIN',
        RegAddrState: 'DE',
        RegAddrZip: 19707,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '10044 WHITE OAK ST',
        RegAddrCity: 'LAUREL',
        RegAddrState: 'DE',
        RegAddrZip: 19956,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '35280 DANNYS DR',
        RegAddrCity: 'LAUREL',
        RegAddrState: 'DE',
        RegAddrZip: 19956,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '28317 DUKES LUMBER RD',
        RegAddrCity: 'LAUREL',
        RegAddrState: 'DE',
        RegAddrZip: 19956,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '33418 HORSEY CHURCH RD',
        RegAddrCity: 'LAUREL',
        RegAddrState: 'DE',
        RegAddrZip: 19956,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '18999 JOHNSON RD',
        RegAddrCity: 'LAUREL',
        RegAddrState: 'DE',
        RegAddrZip: 19956,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '3 SHEFFIELD CT',
        RegAddrCity: 'LEWES',
        RegAddrState: 'DE',
        RegAddrZip: 19958,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '21752 GRAVES DR',
        RegAddrCity: 'LEWES',
        RegAddrState: 'DE',
        RegAddrZip: 19958,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '23810 BIRCH LN',
        RegAddrCity: 'LEWES',
        RegAddrState: 'DE',
        RegAddrZip: 19958,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '19043 FLEATOWN RD',
        RegAddrCity: 'LINCOLN',
        RegAddrState: 'DE',
        RegAddrZip: 19960,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '20203 ELKS LODGE RD',
        RegAddrCity: 'LINCOLN',
        RegAddrState: 'DE',
        RegAddrZip: 19960,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '9048 BENSON RD',
        RegAddrCity: 'LINCOLN',
        RegAddrState: 'DE',
        RegAddrZip: 19960,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '285 AUGUSTA NATIONAL DR',
        RegAddrCity: 'MAGNOLIA',
        RegAddrState: 'DE',
        RegAddrZip: 19962,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '129 LIMERICK LN',
        RegAddrCity: 'MAGNOLIA',
        RegAddrState: 'DE',
        RegAddrZip: 19962,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '37 AMY AVE',
        RegAddrCity: 'MAGNOLIA',
        RegAddrState: 'DE',
        RegAddrZip: 19962,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '5 GLENN FOREST RD',
        RegAddrCity: 'MAGNOLIA',
        RegAddrState: 'DE',
        RegAddrZip: 19962,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '407 QUAIL LANDING CIR',
        RegAddrCity: 'MAGNOLIA',
        RegAddrState: 'DE',
        RegAddrZip: 19962,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '3919 MAHAN CORNER RD',
        RegAddrCity: 'MARYDEL',
        RegAddrState: 'DE',
        RegAddrZip: 19964,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '124 FAITH WAY',
        RegAddrCity: 'MIDDLETOWN',
        RegAddrState: 'DE',
        RegAddrZip: 19709,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '105 AVIAN WAY',
        RegAddrCity: 'MIDDLETOWN',
        RegAddrState: 'DE',
        RegAddrZip: 19709,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '201 E COCHRAN ST',
        RegAddrCity: 'MIDDLETOWN',
        RegAddrState: 'DE',
        RegAddrZip: 19709,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '247 SUGAR PINE DR',
        RegAddrCity: 'MIDDLETOWN',
        RegAddrState: 'DE',
        RegAddrZip: 19709,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '110 E COCHRAN ST',
        RegAddrCity: 'MIDDLETOWN',
        RegAddrState: 'DE',
        RegAddrZip: 19709,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '13 CANARY CT',
        RegAddrCity: 'MIDDLETOWN',
        RegAddrState: 'DE',
        RegAddrZip: 19709,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '168 BAKERFIELD DR',
        RegAddrCity: 'MIDDLETOWN',
        RegAddrState: 'DE',
        RegAddrZip: 19709,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '408 NORTHHAMPTON WAY',
        RegAddrCity: 'MIDDLETOWN',
        RegAddrState: 'DE',
        RegAddrZip: 19709,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '959 RIVER BIRCH CIR',
        RegAddrCity: 'MIDDLETOWN',
        RegAddrState: 'DE',
        RegAddrZip: 19709,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '207 REDDEN LN',
        RegAddrCity: 'MIDDLETOWN',
        RegAddrState: 'DE',
        RegAddrZip: 19709,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '22289 CEDAR BEACH RD',
        RegAddrCity: 'MILFORD',
        RegAddrState: 'DE',
        RegAddrZip: 19963,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '314 PURPLE FINCH LN',
        RegAddrCity: 'MILFORD',
        RegAddrState: 'DE',
        RegAddrZip: 19963,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '20444 BEACHFIELD LN',
        RegAddrCity: 'MILFORD',
        RegAddrState: 'DE',
        RegAddrZip: 19963,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '429 DELAWARE AVE',
        RegAddrCity: 'MILLSBORO',
        RegAddrState: 'DE',
        RegAddrZip: 19966,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '33893 NEW MOON ST',
        RegAddrCity: 'MILLSBORO',
        RegAddrState: 'DE',
        RegAddrZip: 19966,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '35566 CATAMARAN RD',
        RegAddrCity: 'MILLSBORO',
        RegAddrState: 'DE',
        RegAddrZip: 19966,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '9 COUNTRY LN',
        RegAddrCity: 'MILLSBORO',
        RegAddrState: 'DE',
        RegAddrZip: 19966,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '305 ADELAIDE DR',
        RegAddrCity: 'MILTON',
        RegAddrState: 'DE',
        RegAddrZip: 19968,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '22898 REYNOLDS POND RD',
        RegAddrCity: 'MILTON',
        RegAddrState: 'DE',
        RegAddrZip: 19968,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '26618 MEADOW CREEK DR',
        RegAddrCity: 'MILTON',
        RegAddrState: 'DE',
        RegAddrZip: 19968,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '21 E BALBACH AVE',
        RegAddrCity: 'NEW CASTLE',
        RegAddrState: 'DE',
        RegAddrZip: 19720,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1218 CAITLIN WAY',
        RegAddrCity: 'NEW CASTLE',
        RegAddrState: 'DE',
        RegAddrZip: 19720,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '3083 NEW CASTLE AVE',
        RegAddrCity: 'NEW CASTLE',
        RegAddrState: 'DE',
        RegAddrZip: 19720,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '326 S BOOTH DR',
        RegAddrCity: 'NEW CASTLE',
        RegAddrState: 'DE',
        RegAddrZip: 19720,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '10 JUNIPER ST',
        RegAddrCity: 'NEW CASTLE',
        RegAddrState: 'DE',
        RegAddrZip: 19720,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '120 STUYVESANT AVE',
        RegAddrCity: 'NEW CASTLE',
        RegAddrState: 'DE',
        RegAddrZip: 19720,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '35 PHOEBE FARMS LN',
        RegAddrCity: 'NEW CASTLE',
        RegAddrState: 'DE',
        RegAddrZip: 19720,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '29 W 5TH ST',
        RegAddrCity: 'NEW CASTLE',
        RegAddrState: 'DE',
        RegAddrZip: 19720,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '8 DUNSINANE DR',
        RegAddrCity: 'NEW CASTLE',
        RegAddrState: 'DE',
        RegAddrZip: 19720,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1311 STONEBRIDGE BLVD',
        RegAddrCity: 'NEW CASTLE',
        RegAddrState: 'DE',
        RegAddrZip: 19720,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '114 RITA RD',
        RegAddrCity: 'NEW CASTLE',
        RegAddrState: 'DE',
        RegAddrZip: 19720,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '602 CLARK ST',
        RegAddrCity: 'NEW CASTLE',
        RegAddrState: 'DE',
        RegAddrZip: 19720,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '627 CLYMER ST',
        RegAddrCity: 'NEW CASTLE',
        RegAddrState: 'DE',
        RegAddrZip: 19720,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '7108 WYETH PL',
        RegAddrCity: 'NEWARK',
        RegAddrState: 'DE',
        RegAddrZip: 19702,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '212 W EDGEWATER WAY',
        RegAddrCity: 'NEWARK',
        RegAddrState: 'DE',
        RegAddrZip: 19702,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '14 CASSANDRA RD',
        RegAddrCity: 'NEWARK',
        RegAddrState: 'DE',
        RegAddrZip: 19702,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '126 BLUE RIDGE CIR',
        RegAddrCity: 'NEWARK',
        RegAddrState: 'DE',
        RegAddrZip: 19702,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '97 DEVALINDER DR',
        RegAddrCity: 'NEWARK',
        RegAddrState: 'DE',
        RegAddrZip: 19702,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1206 GREGORY DR',
        RegAddrCity: 'NEWARK',
        RegAddrState: 'DE',
        RegAddrZip: 19702,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '3709 KILDOON DR',
        RegAddrCity: 'NEWARK',
        RegAddrState: 'DE',
        RegAddrZip: 19702,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '20 TEAL CIR',
        RegAddrCity: 'NEWARK',
        RegAddrState: 'DE',
        RegAddrZip: 19702,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '166 COOPERS DR',
        RegAddrCity: 'NEWARK',
        RegAddrState: 'DE',
        RegAddrZip: 19702,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '209 MEDERIA CIR',
        RegAddrCity: 'NEWARK',
        RegAddrState: 'DE',
        RegAddrZip: 19702,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '16 HEMPSTEAD DR',
        RegAddrCity: 'NEWARK',
        RegAddrState: 'DE',
        RegAddrZip: 19702,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '435 PIERCE RUN',
        RegAddrCity: 'NEWARK',
        RegAddrState: 'DE',
        RegAddrZip: 19702,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '124 GLADSTONE WAY',
        RegAddrCity: 'NEWARK',
        RegAddrState: 'DE',
        RegAddrZip: 19702,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '19 PLOVER CIR',
        RegAddrCity: 'NEWARK',
        RegAddrState: 'DE',
        RegAddrZip: 19702,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '10 PINE PL',
        RegAddrCity: 'NEWARK',
        RegAddrState: 'DE',
        RegAddrZip: 19711,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '21 E MILL STATION DR',
        RegAddrCity: 'NEWARK',
        RegAddrState: 'DE',
        RegAddrZip: 19711,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '17 LAUREL AVE',
        RegAddrCity: 'NEWARK',
        RegAddrState: 'DE',
        RegAddrZip: 19711,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '3 CHIPPENHAM DR',
        RegAddrCity: 'NEWARK',
        RegAddrState: 'DE',
        RegAddrZip: 19711,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '365 S COLLEGE AVE',
        RegAddrCity: 'NEWARK',
        RegAddrState: 'DE',
        RegAddrZip: 19711,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '25 BOYDS VALLEY DR',
        RegAddrCity: 'NEWARK',
        RegAddrState: 'DE',
        RegAddrZip: 19711,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '202 WILSON RD',
        RegAddrCity: 'NEWARK',
        RegAddrState: 'DE',
        RegAddrZip: 19711,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '23 CORNWALL DR',
        RegAddrCity: 'NEWARK',
        RegAddrState: 'DE',
        RegAddrZip: 19711,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '40 MEADOWBROOK LN',
        RegAddrCity: 'NEWARK',
        RegAddrState: 'DE',
        RegAddrZip: 19711,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '309 MELROSE PLACE DR',
        RegAddrCity: 'NEWARK',
        RegAddrState: 'DE',
        RegAddrZip: 19711,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '214 SPRUCEGLEN DR',
        RegAddrCity: 'NEWARK',
        RegAddrState: 'DE',
        RegAddrZip: 19711,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '116 HOPKINS CT',
        RegAddrCity: 'NEWARK',
        RegAddrState: 'DE',
        RegAddrZip: 19713,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '45 KOLLMAN DR',
        RegAddrCity: 'NEWARK',
        RegAddrState: 'DE',
        RegAddrZip: 19713,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '123 E RUTHERFORD DR',
        RegAddrCity: 'NEWARK',
        RegAddrState: 'DE',
        RegAddrZip: 19713,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '41 SPECTRUM DR',
        RegAddrCity: 'NEWARK',
        RegAddrState: 'DE',
        RegAddrZip: 19713,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '305 RICHMOND CT',
        RegAddrCity: 'NEWARK',
        RegAddrState: 'DE',
        RegAddrZip: 19713,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '402 BLACKSTONE RD',
        RegAddrCity: 'NEWARK',
        RegAddrState: 'DE',
        RegAddrZip: 19713,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '23 EASTWIND CT',
        RegAddrCity: 'NEWARK',
        RegAddrState: 'DE',
        RegAddrZip: 19713,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '31 ARIZONA STATE DR',
        RegAddrCity: 'NEWARK',
        RegAddrState: 'DE',
        RegAddrZip: 19713,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '18 THORNTON DR',
        RegAddrCity: 'OCEAN VIEW',
        RegAddrState: 'DE',
        RegAddrZip: 19970,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '5 N HORSESHOE DR',
        RegAddrCity: 'OCEAN VIEW',
        RegAddrState: 'DE',
        RegAddrZip: 19970,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '8 WELLINGTON PL',
        RegAddrCity: 'REHOBOTH BCH',
        RegAddrState: 'DE',
        RegAddrZip: 19971,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '18530 FIR DRIVE EXT',
        RegAddrCity: 'REHOBOTH BCH',
        RegAddrState: 'DE',
        RegAddrZip: 19971,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '95 GLADE CIR W',
        RegAddrCity: 'REHOBOTH BEACH',
        RegAddrState: 'DE',
        RegAddrZip: 19971,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '35 N PENNSYLVANIA AVE',
        RegAddrCity: 'S BETHANY',
        RegAddrState: 'DE',
        RegAddrZip: 19930,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '8690 GARDEN LN',
        RegAddrCity: 'SEAFORD',
        RegAddrState: 'DE',
        RegAddrZip: 19973,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '10714 SERENITY CIR',
        RegAddrCity: 'SEAFORD',
        RegAddrState: 'DE',
        RegAddrZip: 19973,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '37589 PINE RD',
        RegAddrCity: 'SELBYVILLE',
        RegAddrState: 'DE',
        RegAddrZip: 19975,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '403 EAGLE WAY',
        RegAddrCity: 'SMYRNA',
        RegAddrState: 'DE',
        RegAddrZip: 19977,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '3136 BRENFORD RD',
        RegAddrCity: 'SMYRNA',
        RegAddrState: 'DE',
        RegAddrZip: 19977,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '588 MASSEY CHURCH RD',
        RegAddrCity: 'SMYRNA',
        RegAddrState: 'DE',
        RegAddrZip: 19977,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '47 HEDGEROW HOLLOW RD',
        RegAddrCity: 'SMYRNA',
        RegAddrState: 'DE',
        RegAddrZip: 19977,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '25 VOLPE WAY',
        RegAddrCity: 'SMYRNA',
        RegAddrState: 'DE',
        RegAddrZip: 19977,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '27 SUMMER DR',
        RegAddrCity: 'SMYRNA',
        RegAddrState: 'DE',
        RegAddrZip: 19977,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '422 W SOUTH ST',
        RegAddrCity: 'SMYRNA',
        RegAddrState: 'DE',
        RegAddrZip: 19977,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '6035 SUMMIT BRIDGE RD',
        RegAddrCity: 'TOWNSEND',
        RegAddrState: 'DE',
        RegAddrZip: 19734,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '119 BLACKBIRD STATION RD',
        RegAddrCity: 'TOWNSEND',
        RegAddrState: 'DE',
        RegAddrZip: 19734,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '436 GLENTURRET WAY',
        RegAddrCity: 'TOWNSEND',
        RegAddrState: 'DE',
        RegAddrZip: 19734,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '824 TOWNE CT',
        RegAddrCity: 'WILMINGTON',
        RegAddrState: 'DE',
        RegAddrZip: 19801,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '411 CARVER DR',
        RegAddrCity: 'WILMINGTON',
        RegAddrState: 'DE',
        RegAddrZip: 19801,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '4611 BIG ROCK DR',
        RegAddrCity: 'WILMINGTON',
        RegAddrState: 'DE',
        RegAddrZip: 19802,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '713 W 27TH ST',
        RegAddrCity: 'WILMINGTON',
        RegAddrState: 'DE',
        RegAddrZip: 19802,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '4405 EMERSON RD',
        RegAddrCity: 'WILMINGTON',
        RegAddrState: 'DE',
        RegAddrZip: 19802,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '523 E 35TH ST',
        RegAddrCity: 'WILMINGTON',
        RegAddrState: 'DE',
        RegAddrZip: 19802,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '610 W 39TH ST',
        RegAddrCity: 'WILMINGTON',
        RegAddrState: 'DE',
        RegAddrZip: 19802,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1116 WEBSTER DR',
        RegAddrCity: 'WILMINGTON',
        RegAddrState: 'DE',
        RegAddrZip: 19803,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1716 GUNNING DR',
        RegAddrCity: 'WILMINGTON',
        RegAddrState: 'DE',
        RegAddrZip: 19803,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1402 DRAKE RD',
        RegAddrCity: 'WILMINGTON',
        RegAddrState: 'DE',
        RegAddrZip: 19803,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1711 FORESTDALE DR',
        RegAddrCity: 'WILMINGTON',
        RegAddrState: 'DE',
        RegAddrZip: 19803,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '307 SPALDING RD',
        RegAddrCity: 'WILMINGTON',
        RegAddrState: 'DE',
        RegAddrZip: 19803,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '207 S MARYLAND AVE',
        RegAddrCity: 'WILMINGTON',
        RegAddrState: 'DE',
        RegAddrZip: 19804,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '2200 HENLOPEN AVE',
        RegAddrCity: 'WILMINGTON',
        RegAddrState: 'DE',
        RegAddrZip: 19804,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '21 W REAMER AVE',
        RegAddrCity: 'WILMINGTON',
        RegAddrState: 'DE',
        RegAddrZip: 19804,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '24 VILONE RD',
        RegAddrCity: 'WILMINGTON',
        RegAddrState: 'DE',
        RegAddrZip: 19805,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1823 W 7TH ST',
        RegAddrCity: 'WILMINGTON',
        RegAddrState: 'DE',
        RegAddrZip: 19805,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1313 MARYLAND AVE',
        RegAddrCity: 'WILMINGTON',
        RegAddrState: 'DE',
        RegAddrZip: 19805,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '122 LINDEN AVE',
        RegAddrCity: 'WILMINGTON',
        RegAddrState: 'DE',
        RegAddrZip: 19805,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1812 LINDEN ST',
        RegAddrCity: 'WILMINGTON',
        RegAddrState: 'DE',
        RegAddrZip: 19805,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '238 N FRANKLIN ST',
        RegAddrCity: 'WILMINGTON',
        RegAddrState: 'DE',
        RegAddrZip: 19805,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1402 W 4TH ST',
        RegAddrCity: 'WILMINGTON',
        RegAddrState: 'DE',
        RegAddrZip: 19805,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '109 CHESTNUT AVE',
        RegAddrCity: 'WILMINGTON',
        RegAddrState: 'DE',
        RegAddrZip: 19805,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1814 N SCOTT ST',
        RegAddrCity: 'WILMINGTON',
        RegAddrState: 'DE',
        RegAddrZip: 19806,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '53 SELBORNE DR',
        RegAddrCity: 'WILMINGTON',
        RegAddrState: 'DE',
        RegAddrZip: 19807,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '45 WINTERBURY CIR',
        RegAddrCity: 'WILMINGTON',
        RegAddrState: 'DE',
        RegAddrZip: 19808,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '12 LARA LN',
        RegAddrCity: 'WILMINGTON',
        RegAddrState: 'DE',
        RegAddrZip: 19808,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '5443 CRESTLINE RD',
        RegAddrCity: 'WILMINGTON',
        RegAddrState: 'DE',
        RegAddrZip: 19808,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1209 ARUNDEL DR',
        RegAddrCity: 'WILMINGTON',
        RegAddrState: 'DE',
        RegAddrZip: 19808,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '4608 HAVERFORD PL',
        RegAddrCity: 'WILMINGTON',
        RegAddrState: 'DE',
        RegAddrZip: 19808,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '28 SHARONS WAY',
        RegAddrCity: 'WILMINGTON',
        RegAddrState: 'DE',
        RegAddrZip: 19808,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '17 ALCOTT DR',
        RegAddrCity: 'WILMINGTON',
        RegAddrState: 'DE',
        RegAddrZip: 19808,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '926 10TH AVE',
        RegAddrCity: 'WILMINGTON',
        RegAddrState: 'DE',
        RegAddrZip: 19808,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '117 BUNTING DR',
        RegAddrCity: 'WILMINGTON',
        RegAddrState: 'DE',
        RegAddrZip: 19808,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '302 CHESTNUT AVE',
        RegAddrCity: 'WILMINGTON',
        RegAddrState: 'DE',
        RegAddrZip: 19809,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '2102 WIND LN',
        RegAddrCity: 'WILMINGTON',
        RegAddrState: 'DE',
        RegAddrZip: 19810,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '2120 VEALE RD',
        RegAddrCity: 'WILMINGTON',
        RegAddrState: 'DE',
        RegAddrZip: 19810,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '3111 CRYSTAL CT',
        RegAddrCity: 'WILMINGTON',
        RegAddrState: 'DE',
        RegAddrZip: 19810,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '2120 VEALE RD',
        RegAddrCity: 'WILMINGTON',
        RegAddrState: 'DE',
        RegAddrZip: 19810,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '940 LONGVIEW CT',
        RegAddrCity: 'WILMINGTON',
        RegAddrState: 'DE',
        RegAddrZip: 19810,
        CongressionalDistrict: 0,
      },
    ],
  },
  {
    name: 'FL-1',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/FL-1?ref=gh-pages',
    state: 'FL',
    state_and_number: 'FL-1',
    zip: 32413,
    congressionalDistrict: 1,
    addressesInDistrict: [
      {
        RegAddrLine1: '2839 BEALL PACKING RD',
        RegAddrCity: 'BONIFAY',
        RegAddrState: 'FL',
        RegAddrZip: 32425,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1156 CHANCE RD',
        RegAddrCity: 'CHIPLEY',
        RegAddrState: 'FL',
        RegAddrZip: 32428,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1412 QUAIL RDG',
        RegAddrCity: 'CRESTVIEW',
        RegAddrState: 'FL',
        RegAddrZip: 32539,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '633 COUNTRY CLUB AVE NE',
        RegAddrCity: 'FT WALTON BCH',
        RegAddrState: 'FL',
        RegAddrZip: 32547,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '728 MAYFLOWER AVE',
        RegAddrCity: 'FT WALTON BCH',
        RegAddrState: 'FL',
        RegAddrZip: 32547,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '525 RICHERSON RD',
        RegAddrCity: 'LAUREL HILL',
        RegAddrState: 'FL',
        RegAddrZip: 32567,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '311 WOODLAND AVE',
        RegAddrCity: 'MARY ESTHER',
        RegAddrState: 'FL',
        RegAddrZip: 32569,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '5071 WIGGINS LAKE RD',
        RegAddrCity: 'MC DAVID',
        RegAddrState: 'FL',
        RegAddrZip: 32568,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '5745 BRONCO PL',
        RegAddrCity: 'MILTON',
        RegAddrState: 'FL',
        RegAddrZip: 32570,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '7843 SKYVIEW BLVD',
        RegAddrCity: 'NAVARRE',
        RegAddrState: 'FL',
        RegAddrZip: 32566,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '8276 COSICA BLVD',
        RegAddrCity: 'NAVARRE',
        RegAddrState: 'FL',
        RegAddrZip: 32566,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '4450 VERN CV',
        RegAddrCity: 'PACE',
        RegAddrState: 'FL',
        RegAddrZip: 32571,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '5551 CHIPPER LN',
        RegAddrCity: 'PACE',
        RegAddrState: 'FL',
        RegAddrZip: 32571,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1004 W GREGORY ST',
        RegAddrCity: 'PENSACOLA',
        RegAddrState: 'FL',
        RegAddrZip: 32502,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '257 AQUAMARINE AVE',
        RegAddrCity: 'PENSACOLA',
        RegAddrState: 'FL',
        RegAddrZip: 32505,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '110 EMORY DR',
        RegAddrCity: 'PENSACOLA',
        RegAddrState: 'FL',
        RegAddrZip: 32506,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '568 PICOU CT',
        RegAddrCity: 'PENSACOLA',
        RegAddrState: 'FL',
        RegAddrZip: 32508,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '5635 MULDOON RD',
        RegAddrCity: 'PENSACOLA',
        RegAddrState: 'FL',
        RegAddrZip: 32526,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '5527 SPOONFLOWER DR',
        RegAddrCity: 'PENSACOLA',
        RegAddrState: 'FL',
        RegAddrZip: 32526,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '241 NEEDLERUSH DR',
        RegAddrCity: 'SANTA RSA BCH',
        RegAddrState: 'FL',
        RegAddrZip: 32459,
        CongressionalDistrict: 1,
      },
    ],
  },
  {
    name: 'FL-10',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/FL-10?ref=gh-pages',
    state: 'FL',
    state_and_number: 'FL-10',
    zip: 32712,
    congressionalDistrict: 10,
    addressesInDistrict: [
      {
        RegAddrLine1: '326 N HAWTHORNE AVE',
        RegAddrCity: 'APOPKA',
        RegAddrState: 'FL',
        RegAddrZip: 32703,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '2213 GRASMERE DR',
        RegAddrCity: 'APOPKA',
        RegAddrState: 'FL',
        RegAddrZip: 32703,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '3224 PINENUT DR',
        RegAddrCity: 'APOPKA',
        RegAddrState: 'FL',
        RegAddrZip: 32712,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '2487 DRESDEN TRL',
        RegAddrCity: 'APOPKA',
        RegAddrState: 'FL',
        RegAddrZip: 32712,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '3013 W KELLY PARK RD',
        RegAddrCity: 'APOPKA',
        RegAddrState: 'FL',
        RegAddrZip: 32712,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '2477 QUIET WATERS LOOP',
        RegAddrCity: 'OCOEE',
        RegAddrState: 'FL',
        RegAddrZip: 34761,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '5537 BURLWOOD DR',
        RegAddrCity: 'ORLANDO',
        RegAddrState: 'FL',
        RegAddrZip: 32810,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '4024 MALLARD POINT CT',
        RegAddrCity: 'ORLANDO',
        RegAddrState: 'FL',
        RegAddrZip: 32810,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '1902 S IVEY LN',
        RegAddrCity: 'ORLANDO',
        RegAddrState: 'FL',
        RegAddrZip: 32811,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '5436 WINCREST CT',
        RegAddrCity: 'ORLANDO',
        RegAddrState: 'FL',
        RegAddrZip: 32812,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '7675 HAVENFORD CT',
        RegAddrCity: 'ORLANDO',
        RegAddrState: 'FL',
        RegAddrZip: 32818,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '922 LAKE BISCAYNE WAY',
        RegAddrCity: 'ORLANDO',
        RegAddrState: 'FL',
        RegAddrZip: 32824,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '424 BECKY ST',
        RegAddrCity: 'ORLANDO',
        RegAddrState: 'FL',
        RegAddrZip: 32824,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '2126 MEADOWMOUSE ST',
        RegAddrCity: 'ORLANDO',
        RegAddrState: 'FL',
        RegAddrZip: 32837,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '13550 LANNER DR',
        RegAddrCity: 'ORLANDO',
        RegAddrState: 'FL',
        RegAddrZip: 32837,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '1161 CENTER GROVE ST',
        RegAddrCity: 'ORLANDO',
        RegAddrState: 'FL',
        RegAddrZip: 32839,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '6131 TALARIA DR',
        RegAddrCity: 'WINDERMERE',
        RegAddrState: 'FL',
        RegAddrZip: 34786,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '1010 HUNTSPOINT ST',
        RegAddrCity: 'WINTER GARDEN',
        RegAddrState: 'FL',
        RegAddrZip: 34787,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '1961 PORTCASTLE CIR',
        RegAddrCity: 'WINTER GARDEN',
        RegAddrState: 'FL',
        RegAddrZip: 34787,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '164 TIMBERCREEK PINES CIR',
        RegAddrCity: 'WINTER GARDEN',
        RegAddrState: 'FL',
        RegAddrZip: 34787,
        CongressionalDistrict: 10,
      },
    ],
  },
  {
    name: 'FL-11',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/FL-11?ref=gh-pages',
    state: 'FL',
    state_and_number: 'FL-11',
    zip: 32133,
    congressionalDistrict: 11,
    addressesInDistrict: [
      {
        RegAddrLine1: '34898 EMILY DR',
        RegAddrCity: 'DADE CITY',
        RegAddrState: 'FL',
        RegAddrZip: 33523,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '9071 S TARA PT',
        RegAddrCity: 'FLORAL CITY',
        RegAddrState: 'FL',
        RegAddrZip: 34436,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '804 PHOENIX AVE',
        RegAddrCity: 'FRUITLAND PK',
        RegAddrState: 'FL',
        RegAddrZip: 34731,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '5110 E MARSH LAKE DR',
        RegAddrCity: 'HERNANDO',
        RegAddrState: 'FL',
        RegAddrZip: 34442,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '1564 E BISMARK ST',
        RegAddrCity: 'HERNANDO',
        RegAddrState: 'FL',
        RegAddrZip: 34442,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '9432 E GABLE CT',
        RegAddrCity: 'INVERNESS',
        RegAddrState: 'FL',
        RegAddrZip: 34450,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '452 LANDING BLVD',
        RegAddrCity: 'INVERNESS',
        RegAddrState: 'FL',
        RegAddrZip: 34450,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '529 EASTWOOD LN',
        RegAddrCity: 'LEESBURG',
        RegAddrState: 'FL',
        RegAddrZip: 34748,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '3723 MULBERRY GROVE LOOP',
        RegAddrCity: 'LEESBURG',
        RegAddrState: 'FL',
        RegAddrZip: 34748,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '1000 MEDIA RD',
        RegAddrCity: 'MINNEOLA',
        RegAddrState: 'FL',
        RegAddrZip: 34715,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '9729 BAHIA RD',
        RegAddrCity: 'OCALA',
        RegAddrState: 'FL',
        RegAddrZip: 34472,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '7718 SW 103RD LOOP',
        RegAddrCity: 'OCALA',
        RegAddrState: 'FL',
        RegAddrZip: 34476,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '13090 SE 109TH TER',
        RegAddrCity: 'OCKLAWAHA',
        RegAddrState: 'FL',
        RegAddrZip: 32179,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '7466 ROSEMONT LN',
        RegAddrCity: 'SPRING HILL',
        RegAddrState: 'FL',
        RegAddrZip: 34606,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '2209 ORCHARD PARK DR',
        RegAddrCity: 'SPRING HILL',
        RegAddrState: 'FL',
        RegAddrZip: 34608,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '3430 LAMBERT AVE',
        RegAddrCity: 'SPRING HILL',
        RegAddrState: 'FL',
        RegAddrZip: 34608,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '12172 BAGDAD ST',
        RegAddrCity: 'SPRING HILL',
        RegAddrState: 'FL',
        RegAddrZip: 34608,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '4273 EVERETT AVE',
        RegAddrCity: 'SPRING HILL',
        RegAddrState: 'FL',
        RegAddrZip: 34609,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '14373 SE 90TH CT',
        RegAddrCity: 'SUMMERFIELD',
        RegAddrState: 'FL',
        RegAddrZip: 34491,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '14345 SE 103RD TER',
        RegAddrCity: 'SUMMERFIELD',
        RegAddrState: 'FL',
        RegAddrZip: 34491,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '8660 SE 166TH BIRCHBROOK LOOP',
        RegAddrCity: 'THE VILLAGES',
        RegAddrState: 'FL',
        RegAddrZip: 32162,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '17186 SE 76TH CALEDONIA TER',
        RegAddrCity: 'THE VILLAGES',
        RegAddrState: 'FL',
        RegAddrZip: 32162,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '14298 REGENCY CT',
        RegAddrCity: 'WEEKI WACHEE',
        RegAddrState: 'FL',
        RegAddrZip: 34614,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '27401 HAMMOCK VIEW CT',
        RegAddrCity: 'YALAHA',
        RegAddrState: 'FL',
        RegAddrZip: 34797,
        CongressionalDistrict: 11,
      },
    ],
  },
  {
    name: 'FL-12',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/FL-12?ref=gh-pages',
    state: 'FL',
    state_and_number: 'FL-12',
    zip: 33525,
    congressionalDistrict: 12,
    addressesInDistrict: [
      {
        RegAddrLine1: '6735 FLICKER LN',
        RegAddrCity: 'HUDSON',
        RegAddrState: 'FL',
        RegAddrZip: 34667,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '6519 FLETCH RD',
        RegAddrCity: 'LAND O LAKES',
        RegAddrState: 'FL',
        RegAddrZip: 34637,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '24851 RAVELLO ST',
        RegAddrCity: 'LAND O LAKES',
        RegAddrState: 'FL',
        RegAddrZip: 34639,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '4329 WHITTNER DR',
        RegAddrCity: 'LAND O LAKES',
        RegAddrState: 'FL',
        RegAddrZip: 34639,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '8801 EHREN CUTOFF',
        RegAddrCity: 'LAND O LAKES',
        RegAddrState: 'FL',
        RegAddrZip: 34639,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '19160 LONG LAKE RANCH BLVD',
        RegAddrCity: 'LUTZ',
        RegAddrState: 'FL',
        RegAddrZip: 33558,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '6346 PARKSIDE DR',
        RegAddrCity: 'NEW PRT RCHY',
        RegAddrState: 'FL',
        RegAddrZip: 34653,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '4341 COLD HARBOR DR',
        RegAddrCity: 'NEW PRT RCHY',
        RegAddrState: 'FL',
        RegAddrZip: 34653,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '9150 LAKE DR',
        RegAddrCity: 'NEW PRT RCHY',
        RegAddrState: 'FL',
        RegAddrZip: 34654,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '3631 ERIN BROOK DR',
        RegAddrCity: 'NEW PRT RCHY',
        RegAddrState: 'FL',
        RegAddrZip: 34655,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '501 WESTMINSTER BLVD',
        RegAddrCity: 'OLDSMAR',
        RegAddrState: 'FL',
        RegAddrZip: 34677,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '40 PELICAN DR S',
        RegAddrCity: 'OLDSMAR',
        RegAddrState: 'FL',
        RegAddrZip: 34677,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '3497 SNOWY EGRET CT',
        RegAddrCity: 'PALM HARBOR',
        RegAddrState: 'FL',
        RegAddrZip: 34683,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '1316 TENBY WAY',
        RegAddrCity: 'PALM HARBOR',
        RegAddrState: 'FL',
        RegAddrZip: 34683,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '1651 E DORCHESTER CT',
        RegAddrCity: 'PALM HARBOR',
        RegAddrState: 'FL',
        RegAddrZip: 34684,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '4321 AUSTON WAY',
        RegAddrCity: 'PALM HARBOR',
        RegAddrState: 'FL',
        RegAddrZip: 34685,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '8855 MARTINIQUE LN',
        RegAddrCity: 'PORT RICHEY',
        RegAddrState: 'FL',
        RegAddrZip: 34668,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '800 E GATE DR',
        RegAddrCity: 'SAFETY HARBOR',
        RegAddrState: 'FL',
        RegAddrZip: 34695,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '3531 FORTINGALE DR',
        RegAddrCity: 'WESLEY CHAPEL',
        RegAddrState: 'FL',
        RegAddrZip: 33543,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '2504 SILVERMOSS DR',
        RegAddrCity: 'WESLEY CHAPEL',
        RegAddrState: 'FL',
        RegAddrZip: 33544,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '7808 MARINERS HARBOUR DR',
        RegAddrCity: 'WESLEY CHAPEL',
        RegAddrState: 'FL',
        RegAddrZip: 33545,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '34542 MORNING GLORY GLN',
        RegAddrCity: 'ZEPHYRHILLS',
        RegAddrState: 'FL',
        RegAddrZip: 33541,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '4654 LA FORCE ST',
        RegAddrCity: 'ZEPHYRHILLS',
        RegAddrState: 'FL',
        RegAddrZip: 33542,
        CongressionalDistrict: 12,
      },
    ],
  },
  {
    name: 'FL-13',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/FL-13?ref=gh-pages',
    state: 'FL',
    state_and_number: 'FL-13',
    zip: 33701,
    congressionalDistrict: 13,
    addressesInDistrict: [
      {
        RegAddrLine1: '1246 FORREST HILL DR',
        RegAddrCity: 'CLEARWATER',
        RegAddrState: 'FL',
        RegAddrZip: 33756,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '5601 15TH AVE S',
        RegAddrCity: 'GULFPORT',
        RegAddrState: 'FL',
        RegAddrZip: 33707,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '70 PALM FOREST DR',
        RegAddrCity: 'LARGO',
        RegAddrState: 'FL',
        RegAddrZip: 33770,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '579 BLUE BIRD LN',
        RegAddrCity: 'LARGO',
        RegAddrState: 'FL',
        RegAddrZip: 33770,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '56 COUNTRY CLUB DR',
        RegAddrCity: 'LARGO',
        RegAddrState: 'FL',
        RegAddrZip: 33771,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '11777 132ND AVE',
        RegAddrCity: 'LARGO',
        RegAddrState: 'FL',
        RegAddrZip: 33778,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '7600 78TH AVE N',
        RegAddrCity: 'PINELLAS PARK',
        RegAddrState: 'FL',
        RegAddrZip: 33781,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '6824 40TH LN N',
        RegAddrCity: 'PINELLAS PARK',
        RegAddrState: 'FL',
        RegAddrZip: 33781,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '3900 HELENA ST NE',
        RegAddrCity: 'ST PETERSBURG',
        RegAddrState: 'FL',
        RegAddrZip: 33703,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '2501 12TH ST N',
        RegAddrCity: 'ST PETERSBURG',
        RegAddrState: 'FL',
        RegAddrZip: 33704,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '2222 E HARBOR DR S',
        RegAddrCity: 'ST PETERSBURG',
        RegAddrState: 'FL',
        RegAddrZip: 33705,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '6140 SUNDOWN DR N',
        RegAddrCity: 'ST PETERSBURG',
        RegAddrState: 'FL',
        RegAddrZip: 33709,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '4527 28TH AVE N',
        RegAddrCity: 'ST PETERSBURG',
        RegAddrState: 'FL',
        RegAddrZip: 33713,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '1801 40TH ST N',
        RegAddrCity: 'ST PETERSBURG',
        RegAddrState: 'FL',
        RegAddrZip: 33713,
        CongressionalDistrict: 13,
      },
    ],
  },
  {
    name: 'FL-14',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/FL-14?ref=gh-pages',
    state: 'FL',
    state_and_number: 'FL-14',
    zip: 33534,
    congressionalDistrict: 14,
    addressesInDistrict: [
      {
        RegAddrLine1: '2412 WALLACE RD',
        RegAddrCity: 'LUTZ',
        RegAddrState: 'FL',
        RegAddrZip: 33549,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '1811 E SITKA ST',
        RegAddrCity: 'TAMPA',
        RegAddrState: 'FL',
        RegAddrZip: 33604,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '6906 N OREGON AVE',
        RegAddrCity: 'TAMPA',
        RegAddrState: 'FL',
        RegAddrZip: 33604,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '15414 LAKESHORE VILLA RD',
        RegAddrCity: 'TAMPA',
        RegAddrState: 'FL',
        RegAddrZip: 33613,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '8409 LAKE MELENA WAY',
        RegAddrCity: 'TAMPA',
        RegAddrState: 'FL',
        RegAddrZip: 33614,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '14309 RAVENWOOD LN',
        RegAddrCity: 'TAMPA',
        RegAddrState: 'FL',
        RegAddrZip: 33618,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '10337 ROSEMOUNT DR',
        RegAddrCity: 'TAMPA',
        RegAddrState: 'FL',
        RegAddrZip: 33624,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '3115 S MANHATTAN AVE',
        RegAddrCity: 'TAMPA',
        RegAddrState: 'FL',
        RegAddrZip: 33629,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '4106 W ZELAR ST',
        RegAddrCity: 'TAMPA',
        RegAddrState: 'FL',
        RegAddrZip: 33629,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '8550 GRAND ALBERATO RD',
        RegAddrCity: 'TAMPA',
        RegAddrState: 'FL',
        RegAddrZip: 33647,
        CongressionalDistrict: 14,
      },
    ],
  },
  {
    name: 'FL-15',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/FL-15?ref=gh-pages',
    state: 'FL',
    state_and_number: 'FL-15',
    zip: 33510,
    congressionalDistrict: 15,
    addressesInDistrict: [
      {
        RegAddrLine1: '112 GLENWOOD BLVD',
        RegAddrCity: 'DAVENPORT',
        RegAddrState: 'FL',
        RegAddrZip: 33897,
        CongressionalDistrict: 15,
      },
      {
        RegAddrLine1: '1410 STACY DR',
        RegAddrCity: 'LAKELAND',
        RegAddrState: 'FL',
        RegAddrZip: 33801,
        CongressionalDistrict: 15,
      },
      {
        RegAddrLine1: '1015 GRIFFIN RD',
        RegAddrCity: 'LAKELAND',
        RegAddrState: 'FL',
        RegAddrZip: 33805,
        CongressionalDistrict: 15,
      },
      {
        RegAddrLine1: '1010 HAMMOCK SHADE DR',
        RegAddrCity: 'LAKELAND',
        RegAddrState: 'FL',
        RegAddrZip: 33809,
        CongressionalDistrict: 15,
      },
      {
        RegAddrLine1: '3507 SWINDELL RD',
        RegAddrCity: 'LAKELAND',
        RegAddrState: 'FL',
        RegAddrZip: 33810,
        CongressionalDistrict: 15,
      },
      {
        RegAddrLine1: '1523 SPRUCE DR',
        RegAddrCity: 'LAKELAND',
        RegAddrState: 'FL',
        RegAddrZip: 33815,
        CongressionalDistrict: 15,
      },
      {
        RegAddrLine1: '2038 W HICKORY ST',
        RegAddrCity: 'LAKELAND',
        RegAddrState: 'FL',
        RegAddrZip: 33815,
        CongressionalDistrict: 15,
      },
      {
        RegAddrLine1: '612 WIGGINS CT',
        RegAddrCity: 'PLANT CITY',
        RegAddrState: 'FL',
        RegAddrZip: 33563,
        CongressionalDistrict: 15,
      },
      {
        RegAddrLine1: '7412 BURNT BARN AVE',
        RegAddrCity: 'PLANT CITY',
        RegAddrState: 'FL',
        RegAddrZip: 33565,
        CongressionalDistrict: 15,
      },
      {
        RegAddrLine1: '10321 AMADEUS DR',
        RegAddrCity: 'PORT RICHEY',
        RegAddrState: 'FL',
        RegAddrZip: 34668,
        CongressionalDistrict: 15,
      },
      {
        RegAddrLine1: '10601 HACKNEY DR',
        RegAddrCity: 'RIVERVIEW',
        RegAddrState: 'FL',
        RegAddrZip: 33578,
        CongressionalDistrict: 15,
      },
      {
        RegAddrLine1: '10408 ALCON BLUE DR',
        RegAddrCity: 'RIVERVIEW',
        RegAddrState: 'FL',
        RegAddrZip: 33578,
        CongressionalDistrict: 15,
      },
      {
        RegAddrLine1: '8769 ORANGE OAKS CIR',
        RegAddrCity: 'TEMPLE TERR',
        RegAddrState: 'FL',
        RegAddrZip: 33637,
        CongressionalDistrict: 15,
      },
      {
        RegAddrLine1: '9631 THERESA DR',
        RegAddrCity: 'THONOTOSASSA',
        RegAddrState: 'FL',
        RegAddrZip: 33592,
        CongressionalDistrict: 15,
      },
      {
        RegAddrLine1: '10534 BAY HILLS CIR',
        RegAddrCity: 'THONOTOSASSA',
        RegAddrState: 'FL',
        RegAddrZip: 33592,
        CongressionalDistrict: 15,
      },
      {
        RegAddrLine1: '3526 FOX SQUIRREL LN',
        RegAddrCity: 'VALRICO',
        RegAddrState: 'FL',
        RegAddrZip: 33596,
        CongressionalDistrict: 15,
      },
    ],
  },
  {
    name: 'FL-16',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/FL-16?ref=gh-pages',
    state: 'FL',
    state_and_number: 'FL-16',
    zip: 33503,
    congressionalDistrict: 16,
    addressesInDistrict: [
      {
        RegAddrLine1: '2107 18TH ST W',
        RegAddrCity: 'BRADENTON',
        RegAddrState: 'FL',
        RegAddrZip: 34205,
        CongressionalDistrict: 16,
      },
      {
        RegAddrLine1: '2537 46TH AVE W',
        RegAddrCity: 'BRADENTON',
        RegAddrState: 'FL',
        RegAddrZip: 34207,
        CongressionalDistrict: 16,
      },
      {
        RegAddrLine1: '417 14TH ST E',
        RegAddrCity: 'BRADENTON',
        RegAddrState: 'FL',
        RegAddrZip: 34208,
        CongressionalDistrict: 16,
      },
      {
        RegAddrLine1: '5921 40TH AVE W',
        RegAddrCity: 'BRADENTON',
        RegAddrState: 'FL',
        RegAddrZip: 34209,
        CongressionalDistrict: 16,
      },
      {
        RegAddrLine1: '5404 1ST AVENUE DR NW',
        RegAddrCity: 'BRADENTON',
        RegAddrState: 'FL',
        RegAddrZip: 34209,
        CongressionalDistrict: 16,
      },
      {
        RegAddrLine1: '6410 21ST AVE W',
        RegAddrCity: 'BRADENTON',
        RegAddrState: 'FL',
        RegAddrZip: 34209,
        CongressionalDistrict: 16,
      },
      {
        RegAddrLine1: '4319 LONG LAKE DR N',
        RegAddrCity: 'ELLENTON',
        RegAddrState: 'FL',
        RegAddrZip: 34222,
        CongressionalDistrict: 16,
      },
      {
        RegAddrLine1: '13930 SIENA LOOP',
        RegAddrCity: 'LAKEWOOD RCH',
        RegAddrState: 'FL',
        RegAddrZip: 34202,
        CongressionalDistrict: 16,
      },
      {
        RegAddrLine1: '8835 PURVIS RD',
        RegAddrCity: 'LITHIA',
        RegAddrState: 'FL',
        RegAddrZip: 33547,
        CongressionalDistrict: 16,
      },
      {
        RegAddrLine1: '9110 34TH AVE E',
        RegAddrCity: 'PALMETTO',
        RegAddrState: 'FL',
        RegAddrZip: 34221,
        CongressionalDistrict: 16,
      },
      {
        RegAddrLine1: '16940 ROSEDOWN GLN',
        RegAddrCity: 'PARRISH',
        RegAddrState: 'FL',
        RegAddrZip: 34219,
        CongressionalDistrict: 16,
      },
      {
        RegAddrLine1: '10627 SHADY PRESERVE DR',
        RegAddrCity: 'RIVERVIEW',
        RegAddrState: 'FL',
        RegAddrZip: 33579,
        CongressionalDistrict: 16,
      },
      {
        RegAddrLine1: '4011 ROCKEFELLER AVE',
        RegAddrCity: 'SARASOTA',
        RegAddrState: 'FL',
        RegAddrZip: 34231,
        CongressionalDistrict: 16,
      },
      {
        RegAddrLine1: '4646 PARNELL DR',
        RegAddrCity: 'SARASOTA',
        RegAddrState: 'FL',
        RegAddrZip: 34232,
        CongressionalDistrict: 16,
      },
      {
        RegAddrLine1: '5680 COUNTRY WALK LN',
        RegAddrCity: 'SARASOTA',
        RegAddrState: 'FL',
        RegAddrZip: 34233,
        CongressionalDistrict: 16,
      },
      {
        RegAddrLine1: '6189 ABACO DR',
        RegAddrCity: 'SARASOTA',
        RegAddrState: 'FL',
        RegAddrZip: 34238,
        CongressionalDistrict: 16,
      },
      {
        RegAddrLine1: '820 ALDERWOOD WAY',
        RegAddrCity: 'SARASOTA',
        RegAddrState: 'FL',
        RegAddrZip: 34243,
        CongressionalDistrict: 16,
      },
    ],
  },
  {
    name: 'FL-17',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/FL-17?ref=gh-pages',
    state: 'FL',
    state_and_number: 'FL-17',
    zip: 33471,
    congressionalDistrict: 17,
    addressesInDistrict: [
      {
        RegAddrLine1: '3595 NW 2ND BUNKER AVE',
        RegAddrCity: 'ARCADIA',
        RegAddrState: 'FL',
        RegAddrZip: 34266,
        CongressionalDistrict: 17,
      },
      {
        RegAddrLine1: '217 FRED CONNER ST',
        RegAddrCity: 'AVON PARK',
        RegAddrState: 'FL',
        RegAddrZip: 33825,
        CongressionalDistrict: 17,
      },
      {
        RegAddrLine1: '1670 LAKESIDE DR',
        RegAddrCity: 'BARTOW',
        RegAddrState: 'FL',
        RegAddrZip: 33830,
        CongressionalDistrict: 17,
      },
      {
        RegAddrLine1: '2831 8TH ST',
        RegAddrCity: 'ENGLEWOOD',
        RegAddrState: 'FL',
        RegAddrZip: 34224,
        CongressionalDistrict: 17,
      },
      {
        RegAddrLine1: '814 GREENWOOD AVE',
        RegAddrCity: 'LEHIGH ACRES',
        RegAddrState: 'FL',
        RegAddrZip: 33972,
        CongressionalDistrict: 17,
      },
      {
        RegAddrLine1: '2566 SUNDANCE CIR',
        RegAddrCity: 'MULBERRY',
        RegAddrState: 'FL',
        RegAddrZip: 33860,
        CongressionalDistrict: 17,
      },
      {
        RegAddrLine1: '300 1ST ST E',
        RegAddrCity: 'NOKOMIS',
        RegAddrState: 'FL',
        RegAddrZip: 34275,
        CongressionalDistrict: 17,
      },
      {
        RegAddrLine1: '3065 STOCKTON AVE',
        RegAddrCity: 'NORTH PORT',
        RegAddrState: 'FL',
        RegAddrZip: 34286,
        CongressionalDistrict: 17,
      },
      {
        RegAddrLine1: '2745 TRAVERSE AVE',
        RegAddrCity: 'NORTH PORT',
        RegAddrState: 'FL',
        RegAddrZip: 34286,
        CongressionalDistrict: 17,
      },
      {
        RegAddrLine1: '1801 COCONUT PALM CIR',
        RegAddrCity: 'NORTH PORT',
        RegAddrState: 'FL',
        RegAddrZip: 34288,
        CongressionalDistrict: 17,
      },
      {
        RegAddrLine1: '3350 NW 172ND CT',
        RegAddrCity: 'OKEECHOBEE',
        RegAddrState: 'FL',
        RegAddrZip: 34972,
        CongressionalDistrict: 17,
      },
      {
        RegAddrLine1: '448 FAIRMONT TER',
        RegAddrCity: 'PT CHARLOTTE',
        RegAddrState: 'FL',
        RegAddrZip: 33954,
        CongressionalDistrict: 17,
      },
      {
        RegAddrLine1: '23283 MOORHEAD AVE',
        RegAddrCity: 'PT CHARLOTTE',
        RegAddrState: 'FL',
        RegAddrZip: 33954,
        CongressionalDistrict: 17,
      },
      {
        RegAddrLine1: '2501 GEORGIA ST',
        RegAddrCity: 'SEBRING',
        RegAddrState: 'FL',
        RegAddrZip: 33870,
        CongressionalDistrict: 17,
      },
      {
        RegAddrLine1: '1507 WILLOW DL',
        RegAddrCity: 'SEBRING',
        RegAddrState: 'FL',
        RegAddrZip: 33872,
        CongressionalDistrict: 17,
      },
      {
        RegAddrLine1: '312 CIRCLE DR',
        RegAddrCity: 'VENICE',
        RegAddrState: 'FL',
        RegAddrZip: 34285,
        CongressionalDistrict: 17,
      },
    ],
  },
  {
    name: 'FL-18',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/FL-18?ref=gh-pages',
    state: 'FL',
    state_and_number: 'FL-18',
    zip: 33401,
    congressionalDistrict: 18,
    addressesInDistrict: [
      {
        RegAddrLine1: '3005 KINGSLEY DR',
        RegAddrCity: 'FORT PIERCE',
        RegAddrState: 'FL',
        RegAddrZip: 34946,
        CongressionalDistrict: 18,
      },
      {
        RegAddrLine1: '354 SMALLWOOD AVE',
        RegAddrCity: 'FORT PIERCE',
        RegAddrState: 'FL',
        RegAddrZip: 34982,
        CongressionalDistrict: 18,
      },
      {
        RegAddrLine1: '334 ASHLEY ST',
        RegAddrCity: 'FORT PIERCE',
        RegAddrState: 'FL',
        RegAddrZip: 34982,
        CongressionalDistrict: 18,
      },
      {
        RegAddrLine1: '61 LINKS RD',
        RegAddrCity: 'HOBE SOUND',
        RegAddrState: 'FL',
        RegAddrZip: 33455,
        CongressionalDistrict: 18,
      },
      {
        RegAddrLine1: '325 NE ELM TER',
        RegAddrCity: 'JENSEN BEACH',
        RegAddrState: 'FL',
        RegAddrZip: 34957,
        CongressionalDistrict: 18,
      },
      {
        RegAddrLine1: '17673 PITTSBURGH DR',
        RegAddrCity: 'JUPITER',
        RegAddrState: 'FL',
        RegAddrZip: 33458,
        CongressionalDistrict: 18,
      },
      {
        RegAddrLine1: '585 SCRUBJAY LN',
        RegAddrCity: 'JUPITER',
        RegAddrState: 'FL',
        RegAddrZip: 33458,
        CongressionalDistrict: 18,
      },
      {
        RegAddrLine1: '102 MAPLECREST CIR',
        RegAddrCity: 'JUPITER',
        RegAddrState: 'FL',
        RegAddrZip: 33458,
        CongressionalDistrict: 18,
      },
      {
        RegAddrLine1: '16933 87TH LN N',
        RegAddrCity: 'LOXAHATCHEE',
        RegAddrState: 'FL',
        RegAddrZip: 33470,
        CongressionalDistrict: 18,
      },
      {
        RegAddrLine1: '4105 LAKESPUR CIR N',
        RegAddrCity: 'PALM BCH GDNS',
        RegAddrState: 'FL',
        RegAddrZip: 33410,
        CongressionalDistrict: 18,
      },
      {
        RegAddrLine1: '2098 DICKENS TER',
        RegAddrCity: 'PALM BCH GDNS',
        RegAddrState: 'FL',
        RegAddrZip: 33418,
        CongressionalDistrict: 18,
      },
      {
        RegAddrLine1: '5100 SESAME ST',
        RegAddrCity: 'PALM BCH GDNS',
        RegAddrState: 'FL',
        RegAddrZip: 33418,
        CongressionalDistrict: 18,
      },
      {
        RegAddrLine1: '4400 SW COUNTRY PL',
        RegAddrCity: 'PALM CITY',
        RegAddrState: 'FL',
        RegAddrZip: 34990,
        CongressionalDistrict: 18,
      },
      {
        RegAddrLine1: '1514 SE PITCHER RD',
        RegAddrCity: 'PORT ST LUCIE',
        RegAddrState: 'FL',
        RegAddrZip: 34952,
        CongressionalDistrict: 18,
      },
      {
        RegAddrLine1: '2192 SE MANDRAKE CIR',
        RegAddrCity: 'PORT ST LUCIE',
        RegAddrState: 'FL',
        RegAddrZip: 34952,
        CongressionalDistrict: 18,
      },
      {
        RegAddrLine1: '1474 SE MARISOL LN',
        RegAddrCity: 'PORT ST LUCIE',
        RegAddrState: 'FL',
        RegAddrZip: 34952,
        CongressionalDistrict: 18,
      },
      {
        RegAddrLine1: '2972 SW ROSETTA ST',
        RegAddrCity: 'PORT ST LUCIE',
        RegAddrState: 'FL',
        RegAddrZip: 34953,
        CongressionalDistrict: 18,
      },
      {
        RegAddrLine1: '4561 SW HALLMARK ST',
        RegAddrCity: 'PORT ST LUCIE',
        RegAddrState: 'FL',
        RegAddrZip: 34953,
        CongressionalDistrict: 18,
      },
      {
        RegAddrLine1: '2608 SW CAMEO BLVD',
        RegAddrCity: 'PORT ST LUCIE',
        RegAddrState: 'FL',
        RegAddrZip: 34953,
        CongressionalDistrict: 18,
      },
      {
        RegAddrLine1: '154 CORDOBA CIR',
        RegAddrCity: 'ROYAL PLM BCH',
        RegAddrState: 'FL',
        RegAddrZip: 33411,
        CongressionalDistrict: 18,
      },
      {
        RegAddrLine1: '2364 SE WASHINGTON ST N',
        RegAddrCity: 'STUART',
        RegAddrState: 'FL',
        RegAddrZip: 34997,
        CongressionalDistrict: 18,
      },
      {
        RegAddrLine1: '86 HASTINGS F',
        RegAddrCity: 'WEST PALM BCH',
        RegAddrState: 'FL',
        RegAddrZip: 33417,
        CongressionalDistrict: 18,
      },
    ],
  },
  {
    name: 'FL-19',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/FL-19?ref=gh-pages',
    state: 'FL',
    state_and_number: 'FL-19',
    zip: 33901,
    congressionalDistrict: 19,
    addressesInDistrict: [
      {
        RegAddrLine1: '1321 SE 27TH ST',
        RegAddrCity: 'CAPE CORAL',
        RegAddrState: 'FL',
        RegAddrZip: 33904,
        CongressionalDistrict: 19,
      },
      {
        RegAddrLine1: '510 SE 32ND TER',
        RegAddrCity: 'CAPE CORAL',
        RegAddrState: 'FL',
        RegAddrZip: 33904,
        CongressionalDistrict: 19,
      },
      {
        RegAddrLine1: '124 SW 46TH ST',
        RegAddrCity: 'CAPE CORAL',
        RegAddrState: 'FL',
        RegAddrZip: 33914,
        CongressionalDistrict: 19,
      },
      {
        RegAddrLine1: '2222 SE 2ND ST',
        RegAddrCity: 'CAPE CORAL',
        RegAddrState: 'FL',
        RegAddrZip: 33990,
        CongressionalDistrict: 19,
      },
      {
        RegAddrLine1: '9000 LIGON CT',
        RegAddrCity: 'FORT MYERS',
        RegAddrState: 'FL',
        RegAddrZip: 33908,
        CongressionalDistrict: 19,
      },
      {
        RegAddrLine1: '16168 CUTTERS CT',
        RegAddrCity: 'FORT MYERS',
        RegAddrState: 'FL',
        RegAddrZip: 33908,
        CongressionalDistrict: 19,
      },
      {
        RegAddrLine1: '11246 LAKELAND CIR',
        RegAddrCity: 'FORT MYERS',
        RegAddrState: 'FL',
        RegAddrZip: 33913,
        CongressionalDistrict: 19,
      },
      {
        RegAddrLine1: '996 WITTMAN DR',
        RegAddrCity: 'FORT MYERS',
        RegAddrState: 'FL',
        RegAddrZip: 33919,
        CongressionalDistrict: 19,
      },
      {
        RegAddrLine1: '226 DELMAR AVE',
        RegAddrCity: 'FT MYERS BCH',
        RegAddrState: 'FL',
        RegAddrZip: 33931,
        CongressionalDistrict: 19,
      },
      {
        RegAddrLine1: '90 ANCHOR CT',
        RegAddrCity: 'MARCO ISLAND',
        RegAddrState: 'FL',
        RegAddrZip: 34145,
        CongressionalDistrict: 19,
      },
      {
        RegAddrLine1: '1133 LOVELY LN',
        RegAddrCity: 'N FT MYERS',
        RegAddrState: 'FL',
        RegAddrZip: 33903,
        CongressionalDistrict: 19,
      },
      {
        RegAddrLine1: '181 SHARWOOD DR',
        RegAddrCity: 'NAPLES',
        RegAddrState: 'FL',
        RegAddrZip: 34110,
        CongressionalDistrict: 19,
      },
      {
        RegAddrLine1: '19498 RAVINES CT',
        RegAddrCity: 'NORTH FORT MYERS',
        RegAddrState: 'FL',
        RegAddrZip: 33903,
        CongressionalDistrict: 19,
      },
    ],
  },
  {
    name: 'FL-2',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/FL-2?ref=gh-pages',
    state: 'FL',
    state_and_number: 'FL-2',
    zip: 32008,
    congressionalDistrict: 2,
    addressesInDistrict: [
      {
        RegAddrLine1: '11920 NW STATE ROAD 20',
        RegAddrCity: 'BRISTOL',
        RegAddrState: 'FL',
        RegAddrZip: 32321,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '118 PINE LOG DR',
        RegAddrCity: 'CARRABELLE',
        RegAddrState: 'FL',
        RegAddrZip: 32322,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '11191 NW 113TH PL',
        RegAddrCity: 'CHIEFLAND',
        RegAddrState: 'FL',
        RegAddrZip: 32626,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1213 JOHNSON AVE',
        RegAddrCity: 'CHIPLEY',
        RegAddrState: 'FL',
        RegAddrZip: 32428,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '20716 WALNUT ST',
        RegAddrCity: 'DUNNELLON',
        RegAddrState: 'FL',
        RegAddrZip: 34431,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '4850 NE 65TH ST',
        RegAddrCity: 'HIGH SPRINGS',
        RegAddrState: 'FL',
        RegAddrZip: 32643,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '212 SW BILLOWING GLN',
        RegAddrCity: 'LAKE CITY',
        RegAddrState: 'FL',
        RegAddrZip: 32024,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2927 SW TUSTENUGGEE AVE',
        RegAddrCity: 'LAKE CITY',
        RegAddrState: 'FL',
        RegAddrZip: 32025,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '20960 LANCASTER RD',
        RegAddrCity: 'LIVE OAK',
        RegAddrState: 'FL',
        RegAddrZip: 32060,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '17051 SE 67TH PL',
        RegAddrCity: 'MORRISTON',
        RegAddrState: 'FL',
        RegAddrZip: 32668,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '9771 SW 97TH PL',
        RegAddrCity: 'OCALA',
        RegAddrState: 'FL',
        RegAddrZip: 34481,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1089 COASTAL HWY',
        RegAddrCity: 'PANACEA',
        RegAddrState: 'FL',
        RegAddrZip: 32346,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '6605 BOAT RACE RD',
        RegAddrCity: 'PANAMA CITY',
        RegAddrState: 'FL',
        RegAddrZip: 32404,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2727 COCOA AVE',
        RegAddrCity: 'PANAMA CITY',
        RegAddrState: 'FL',
        RegAddrZip: 32405,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '112 MONICA DR',
        RegAddrCity: 'PORT ST JOE',
        RegAddrState: 'FL',
        RegAddrZip: 32456,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2505 MCCORMICK RD',
        RegAddrCity: 'SOUTHPORT',
        RegAddrState: 'FL',
        RegAddrZip: 32409,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '5649 BRAVEHEART WAY',
        RegAddrCity: 'TALLAHASSEE',
        RegAddrState: 'FL',
        RegAddrZip: 32317,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '5858 COUNTRYSIDE DR',
        RegAddrCity: 'TALLAHASSEE',
        RegAddrState: 'FL',
        RegAddrZip: 32317,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2678 BRUNER DAIRY RD',
        RegAddrCity: 'VERNON',
        RegAddrState: 'FL',
        RegAddrZip: 32462,
        CongressionalDistrict: 2,
      },
    ],
  },
  {
    name: 'FL-20',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/FL-20?ref=gh-pages',
    state: 'FL',
    state_and_number: 'FL-20',
    zip: 33023,
    congressionalDistrict: 20,
    addressesInDistrict: [
      {
        RegAddrLine1: '1213 NW 3RD ST',
        RegAddrCity: 'FT LAUDERDALE',
        RegAddrState: 'FL',
        RegAddrZip: 33311,
        CongressionalDistrict: 20,
      },
      {
        RegAddrLine1: '2060 NW 47TH TER',
        RegAddrCity: 'LAUDERHILL',
        RegAddrState: 'FL',
        RegAddrZip: 33313,
        CongressionalDistrict: 20,
      },
      {
        RegAddrLine1: '5406 NW 18TH PL',
        RegAddrCity: 'LAUDERHILL',
        RegAddrState: 'FL',
        RegAddrZip: 33313,
        CongressionalDistrict: 20,
      },
      {
        RegAddrLine1: '5491 NW 14TH PL',
        RegAddrCity: 'LAUDERHILL',
        RegAddrState: 'FL',
        RegAddrZip: 33313,
        CongressionalDistrict: 20,
      },
      {
        RegAddrLine1: '3029 DOLPHIN DR',
        RegAddrCity: 'MIRAMAR',
        RegAddrState: 'FL',
        RegAddrZip: 33025,
        CongressionalDistrict: 20,
      },
      {
        RegAddrLine1: '2554 SW 157TH AVE',
        RegAddrCity: 'MIRAMAR',
        RegAddrState: 'FL',
        RegAddrZip: 33027,
        CongressionalDistrict: 20,
      },
      {
        RegAddrLine1: '142 BELLA VISTA WAY',
        RegAddrCity: 'ROYAL PLM BCH',
        RegAddrState: 'FL',
        RegAddrZip: 33411,
        CongressionalDistrict: 20,
      },
      {
        RegAddrLine1: '360 SE 4TH AVE',
        RegAddrCity: 'SOUTH BAY',
        RegAddrState: 'FL',
        RegAddrZip: 33493,
        CongressionalDistrict: 20,
      },
      {
        RegAddrLine1: '8020 NW 28TH PL',
        RegAddrCity: 'SUNRISE',
        RegAddrState: 'FL',
        RegAddrZip: 33322,
        CongressionalDistrict: 20,
      },
      {
        RegAddrLine1: '38 ANN LEE LN',
        RegAddrCity: 'TAMARAC',
        RegAddrState: 'FL',
        RegAddrZip: 33319,
        CongressionalDistrict: 20,
      },
      {
        RegAddrLine1: '7534 GRANVILLE DR',
        RegAddrCity: 'TAMARAC',
        RegAddrState: 'FL',
        RegAddrZip: 33321,
        CongressionalDistrict: 20,
      },
      {
        RegAddrLine1: '939 FOREST HILL BLVD',
        RegAddrCity: 'WEST PALM BCH',
        RegAddrState: 'FL',
        RegAddrZip: 33405,
        CongressionalDistrict: 20,
      },
      {
        RegAddrLine1: '4314 COCOANUT RD',
        RegAddrCity: 'WEST PALM BCH',
        RegAddrState: 'FL',
        RegAddrZip: 33406,
        CongressionalDistrict: 20,
      },
    ],
  },
  {
    name: 'FL-21',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/FL-21?ref=gh-pages',
    state: 'FL',
    state_and_number: 'FL-21',
    zip: 33426,
    congressionalDistrict: 21,
    addressesInDistrict: [
      {
        RegAddrLine1: '10199 182ND LN S',
        RegAddrCity: 'BOCA RATON',
        RegAddrState: 'FL',
        RegAddrZip: 33498,
        CongressionalDistrict: 21,
      },
      {
        RegAddrLine1: '495 BOYNTON BAY CIR',
        RegAddrCity: 'BOYNTON BEACH',
        RegAddrState: 'FL',
        RegAddrZip: 33435,
        CongressionalDistrict: 21,
      },
      {
        RegAddrLine1: '2590 NW 1ST ST',
        RegAddrCity: 'BOYNTON BEACH',
        RegAddrState: 'FL',
        RegAddrZip: 33435,
        CongressionalDistrict: 21,
      },
      {
        RegAddrLine1: '34001 BAEZ BAY',
        RegAddrCity: 'BOYNTON BEACH',
        RegAddrState: 'FL',
        RegAddrZip: 33436,
        CongressionalDistrict: 21,
      },
      {
        RegAddrLine1: '6904 BEACON HOLLOW TURN',
        RegAddrCity: 'BOYNTON BEACH',
        RegAddrState: 'FL',
        RegAddrZip: 33437,
        CongressionalDistrict: 21,
      },
      {
        RegAddrLine1: '9129 CARMA DR',
        RegAddrCity: 'BOYNTON BEACH',
        RegAddrState: 'FL',
        RegAddrZip: 33472,
        CongressionalDistrict: 21,
      },
      {
        RegAddrLine1: '8206 DUOMO CIR',
        RegAddrCity: 'BOYNTON BEACH',
        RegAddrState: 'FL',
        RegAddrZip: 33472,
        CongressionalDistrict: 21,
      },
      {
        RegAddrLine1: '12288 WHISTLER WAY',
        RegAddrCity: 'BOYNTON BEACH',
        RegAddrState: 'FL',
        RegAddrZip: 33473,
        CongressionalDistrict: 21,
      },
      {
        RegAddrLine1: '5149 NW 6TH CT',
        RegAddrCity: 'DELRAY BEACH',
        RegAddrState: 'FL',
        RegAddrZip: 33445,
        CongressionalDistrict: 21,
      },
      {
        RegAddrLine1: '14890 RAPOLLA DR',
        RegAddrCity: 'DELRAY BEACH',
        RegAddrState: 'FL',
        RegAddrZip: 33446,
        CongressionalDistrict: 21,
      },
      {
        RegAddrLine1: '5558 FOREST OAKS TER',
        RegAddrCity: 'DELRAY BEACH',
        RegAddrState: 'FL',
        RegAddrZip: 33484,
        CongressionalDistrict: 21,
      },
      {
        RegAddrLine1: '1313 LAKE AVE',
        RegAddrCity: 'LAKE WORTH',
        RegAddrState: 'FL',
        RegAddrZip: 33460,
        CongressionalDistrict: 21,
      },
      {
        RegAddrLine1: '3826 WOODS WALK BLVD',
        RegAddrCity: 'LAKE WORTH',
        RegAddrState: 'FL',
        RegAddrZip: 33467,
        CongressionalDistrict: 21,
      },
      {
        RegAddrLine1: '427 SEMINOLE DR',
        RegAddrCity: 'LANTANA',
        RegAddrState: 'FL',
        RegAddrZip: 33462,
        CongressionalDistrict: 21,
      },
      {
        RegAddrLine1: '2602 SAWYER TER',
        RegAddrCity: 'WELLINGTON',
        RegAddrState: 'FL',
        RegAddrZip: 33414,
        CongressionalDistrict: 21,
      },
      {
        RegAddrLine1: '712 RIDGEWOOD DR',
        RegAddrCity: 'WEST PALM BCH',
        RegAddrState: 'FL',
        RegAddrZip: 33405,
        CongressionalDistrict: 21,
      },
      {
        RegAddrLine1: '1089 ROSETTA TRL',
        RegAddrCity: 'WEST PALM BCH',
        RegAddrState: 'FL',
        RegAddrZip: 33411,
        CongressionalDistrict: 21,
      },
    ],
  },
  {
    name: 'FL-22',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/FL-22?ref=gh-pages',
    state: 'FL',
    state_and_number: 'FL-22',
    zip: 33062,
    congressionalDistrict: 22,
    addressesInDistrict: [
      {
        RegAddrLine1: '1214 SW 9TH AVE',
        RegAddrCity: 'BOCA RATON',
        RegAddrState: 'FL',
        RegAddrZip: 33486,
        CongressionalDistrict: 22,
      },
      {
        RegAddrLine1: '5358 ASCOT BND',
        RegAddrCity: 'BOCA RATON',
        RegAddrState: 'FL',
        RegAddrZip: 33496,
        CongressionalDistrict: 22,
      },
      {
        RegAddrLine1: '4175 W SAMPLE RD',
        RegAddrCity: 'COCONUT CREEK',
        RegAddrState: 'FL',
        RegAddrZip: 33073,
        CongressionalDistrict: 22,
      },
      {
        RegAddrLine1: '1339 NW 87TH TER',
        RegAddrCity: 'CORAL SPRINGS',
        RegAddrState: 'FL',
        RegAddrZip: 33071,
        CongressionalDistrict: 22,
      },
      {
        RegAddrLine1: '5881 NW 121ST AVE',
        RegAddrCity: 'CORAL SPRINGS',
        RegAddrState: 'FL',
        RegAddrZip: 33076,
        CongressionalDistrict: 22,
      },
      {
        RegAddrLine1: '318 SW 32ND AVE',
        RegAddrCity: 'DEERFIELD BCH',
        RegAddrState: 'FL',
        RegAddrZip: 33442,
        CongressionalDistrict: 22,
      },
      {
        RegAddrLine1: '400 CORAL WAY',
        RegAddrCity: 'FT LAUDERDALE',
        RegAddrState: 'FL',
        RegAddrZip: 33301,
        CongressionalDistrict: 22,
      },
      {
        RegAddrLine1: '400 CORPORATE DR',
        RegAddrCity: 'FT LAUDERDALE',
        RegAddrState: 'FL',
        RegAddrZip: 33334,
        CongressionalDistrict: 22,
      },
      {
        RegAddrLine1: '1065 CORKWOOD ST',
        RegAddrCity: 'HOLLYWOOD',
        RegAddrState: 'FL',
        RegAddrZip: 33019,
        CongressionalDistrict: 22,
      },
      {
        RegAddrLine1: '1495 NW 69TH TER',
        RegAddrCity: 'MARGATE',
        RegAddrState: 'FL',
        RegAddrZip: 33063,
        CongressionalDistrict: 22,
      },
      {
        RegAddrLine1: '6824 NW 26TH ST',
        RegAddrCity: 'MARGATE',
        RegAddrState: 'FL',
        RegAddrZip: 33063,
        CongressionalDistrict: 22,
      },
      {
        RegAddrLine1: '5402 GODFREY RD',
        RegAddrCity: 'PARKLAND',
        RegAddrState: 'FL',
        RegAddrZip: 33067,
        CongressionalDistrict: 22,
      },
    ],
  },
  {
    name: 'FL-23',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/FL-23?ref=gh-pages',
    state: 'FL',
    state_and_number: 'FL-23',
    zip: 33004,
    congressionalDistrict: 23,
    addressesInDistrict: [
      {
        RegAddrLine1: '5695 SW 88TH AVE',
        RegAddrCity: 'COOPER CITY',
        RegAddrState: 'FL',
        RegAddrZip: 33328,
        CongressionalDistrict: 23,
      },
      {
        RegAddrLine1: '4641 SW 48TH AVE',
        RegAddrCity: 'DAVIE',
        RegAddrState: 'FL',
        RegAddrZip: 33314,
        CongressionalDistrict: 23,
      },
      {
        RegAddrLine1: '9631 RIDGESIDE CT',
        RegAddrCity: 'DAVIE',
        RegAddrState: 'FL',
        RegAddrZip: 33328,
        CongressionalDistrict: 23,
      },
      {
        RegAddrLine1: '15100 E WATERFORD DR',
        RegAddrCity: 'DAVIE',
        RegAddrState: 'FL',
        RegAddrZip: 33331,
        CongressionalDistrict: 23,
      },
      {
        RegAddrLine1: '633 GOLDEN BEACH DR',
        RegAddrCity: 'GOLDEN BEACH',
        RegAddrState: 'FL',
        RegAddrZip: 33160,
        CongressionalDistrict: 23,
      },
      {
        RegAddrLine1: '931 POLK ST',
        RegAddrCity: 'HOLLYWOOD',
        RegAddrState: 'FL',
        RegAddrZip: 33019,
        CongressionalDistrict: 23,
      },
      {
        RegAddrLine1: '1841 JACKSON ST',
        RegAddrCity: 'HOLLYWOOD',
        RegAddrState: 'FL',
        RegAddrZip: 33020,
        CongressionalDistrict: 23,
      },
      {
        RegAddrLine1: '5626 ATLANTA ST',
        RegAddrCity: 'HOLLYWOOD',
        RegAddrState: 'FL',
        RegAddrZip: 33021,
        CongressionalDistrict: 23,
      },
      {
        RegAddrLine1: '140 GATE RD',
        RegAddrCity: 'HOLLYWOOD',
        RegAddrState: 'FL',
        RegAddrZip: 33024,
        CongressionalDistrict: 23,
      },
      {
        RegAddrLine1: '1720 NW 85TH WAY',
        RegAddrCity: 'PEMBROKE PNES',
        RegAddrState: 'FL',
        RegAddrZip: 33024,
        CongressionalDistrict: 23,
      },
      {
        RegAddrLine1: '16441 SW 1ST ST',
        RegAddrCity: 'PEMBROKE PNES',
        RegAddrState: 'FL',
        RegAddrZip: 33027,
        CongressionalDistrict: 23,
      },
      {
        RegAddrLine1: '526 NW 164TH AVE',
        RegAddrCity: 'PEMBROKE PNES',
        RegAddrState: 'FL',
        RegAddrZip: 33028,
        CongressionalDistrict: 23,
      },
      {
        RegAddrLine1: '18441 NW 9TH ST',
        RegAddrCity: 'PEMBROKE PNES',
        RegAddrState: 'FL',
        RegAddrZip: 33029,
        CongressionalDistrict: 23,
      },
      {
        RegAddrLine1: '740 NW 72ND AVE',
        RegAddrCity: 'PLANTATION',
        RegAddrState: 'FL',
        RegAddrZip: 33317,
        CongressionalDistrict: 23,
      },
      {
        RegAddrLine1: '9332 CARLYLE AVE',
        RegAddrCity: 'SURFSIDE',
        RegAddrState: 'FL',
        RegAddrZip: 33154,
        CongressionalDistrict: 23,
      },
      {
        RegAddrLine1: '16942 SW 5TH WAY',
        RegAddrCity: 'WESTON',
        RegAddrState: 'FL',
        RegAddrZip: 33326,
        CongressionalDistrict: 23,
      },
    ],
  },
  {
    name: 'FL-24',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/FL-24?ref=gh-pages',
    state: 'FL',
    state_and_number: 'FL-24',
    zip: 33013,
    congressionalDistrict: 24,
    addressesInDistrict: [
      {
        RegAddrLine1: '6725 NW 193RD LN',
        RegAddrCity: 'HIALEAH',
        RegAddrState: 'FL',
        RegAddrZip: 33015,
        CongressionalDistrict: 24,
      },
      {
        RegAddrLine1: '526 S RAINBOW DR',
        RegAddrCity: 'HOLLYWOOD',
        RegAddrState: 'FL',
        RegAddrZip: 33021,
        CongressionalDistrict: 24,
      },
      {
        RegAddrLine1: '2236 NW MIAMI CT',
        RegAddrCity: 'MIAMI',
        RegAddrState: 'FL',
        RegAddrZip: 33127,
        CongressionalDistrict: 24,
      },
      {
        RegAddrLine1: '726 NE 1ST AVE',
        RegAddrCity: 'MIAMI',
        RegAddrState: 'FL',
        RegAddrZip: 33132,
        CongressionalDistrict: 24,
      },
      {
        RegAddrLine1: '7645 NE 1ST AVE',
        RegAddrCity: 'MIAMI',
        RegAddrState: 'FL',
        RegAddrZip: 33138,
        CongressionalDistrict: 24,
      },
      {
        RegAddrLine1: '1364 NW 63RD TER',
        RegAddrCity: 'MIAMI',
        RegAddrState: 'FL',
        RegAddrZip: 33147,
        CongressionalDistrict: 24,
      },
      {
        RegAddrLine1: '2975 NW 101ST ST',
        RegAddrCity: 'MIAMI',
        RegAddrState: 'FL',
        RegAddrZip: 33147,
        CongressionalDistrict: 24,
      },
      {
        RegAddrLine1: '3055 NW 69TH ST',
        RegAddrCity: 'MIAMI',
        RegAddrState: 'FL',
        RegAddrZip: 33147,
        CongressionalDistrict: 24,
      },
      {
        RegAddrLine1: '17541 NW 23RD AVE',
        RegAddrCity: 'MIAMI GARDENS',
        RegAddrState: 'FL',
        RegAddrZip: 33056,
        CongressionalDistrict: 24,
      },
      {
        RegAddrLine1: '600 NW 184TH TER',
        RegAddrCity: 'MIAMI GARDENS',
        RegAddrState: 'FL',
        RegAddrZip: 33169,
        CongressionalDistrict: 24,
      },
      {
        RegAddrLine1: '17630 S GLADES DR',
        RegAddrCity: 'N MIAMI BEACH',
        RegAddrState: 'FL',
        RegAddrZip: 33162,
        CongressionalDistrict: 24,
      },
      {
        RegAddrLine1: '911 NE 155TH TER',
        RegAddrCity: 'N MIAMI BEACH',
        RegAddrState: 'FL',
        RegAddrZip: 33162,
        CongressionalDistrict: 24,
      },
      {
        RegAddrLine1: '4045 SW 19TH ST',
        RegAddrCity: 'WEST PARK',
        RegAddrState: 'FL',
        RegAddrZip: 33023,
        CongressionalDistrict: 24,
      },
    ],
  },
  {
    name: 'FL-25',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/FL-25?ref=gh-pages',
    state: 'FL',
    state_and_number: 'FL-25',
    zip: 33010,
    congressionalDistrict: 25,
    addressesInDistrict: [
      {
        RegAddrLine1: '5170 MONZA CT',
        RegAddrCity: 'AVE MARIA',
        RegAddrState: 'FL',
        RegAddrZip: 34142,
        CongressionalDistrict: 25,
      },
      {
        RegAddrLine1: '323 W SUGARLAND CIR',
        RegAddrCity: 'CLEWISTON',
        RegAddrState: 'FL',
        RegAddrZip: 33440,
        CongressionalDistrict: 25,
      },
      {
        RegAddrLine1: '1025 ALABAMA AVE',
        RegAddrCity: 'CLEWISTON',
        RegAddrState: 'FL',
        RegAddrZip: 33440,
        CongressionalDistrict: 25,
      },
      {
        RegAddrLine1: '10850 NW 52ND ST',
        RegAddrCity: 'DORAL',
        RegAddrState: 'FL',
        RegAddrZip: 33178,
        CongressionalDistrict: 25,
      },
      {
        RegAddrLine1: '6610 NW 104TH PATH',
        RegAddrCity: 'DORAL',
        RegAddrState: 'FL',
        RegAddrZip: 33178,
        CongressionalDistrict: 25,
      },
      {
        RegAddrLine1: '781 SE 6TH PL',
        RegAddrCity: 'HIALEAH',
        RegAddrState: 'FL',
        RegAddrZip: 33010,
        CongressionalDistrict: 25,
      },
      {
        RegAddrLine1: '181 W 31ST ST',
        RegAddrCity: 'HIALEAH',
        RegAddrState: 'FL',
        RegAddrZip: 33012,
        CongressionalDistrict: 25,
      },
      {
        RegAddrLine1: '3855 W 8TH WAY',
        RegAddrCity: 'HIALEAH',
        RegAddrState: 'FL',
        RegAddrZip: 33012,
        CongressionalDistrict: 25,
      },
      {
        RegAddrLine1: '5560 E 2ND AVE',
        RegAddrCity: 'HIALEAH',
        RegAddrState: 'FL',
        RegAddrZip: 33013,
        CongressionalDistrict: 25,
      },
      {
        RegAddrLine1: '250 E 57TH ST',
        RegAddrCity: 'HIALEAH',
        RegAddrState: 'FL',
        RegAddrZip: 33013,
        CongressionalDistrict: 25,
      },
      {
        RegAddrLine1: '7659 NW 182ND LN',
        RegAddrCity: 'HIALEAH',
        RegAddrState: 'FL',
        RegAddrZip: 33015,
        CongressionalDistrict: 25,
      },
      {
        RegAddrLine1: '3083 NW 28TH ST',
        RegAddrCity: 'MIAMI',
        RegAddrState: 'FL',
        RegAddrZip: 33142,
        CongressionalDistrict: 25,
      },
      {
        RegAddrLine1: '313 NW 132ND PL',
        RegAddrCity: 'MIAMI',
        RegAddrState: 'FL',
        RegAddrZip: 33182,
        CongressionalDistrict: 25,
      },
      {
        RegAddrLine1: '160 ANDREA LN',
        RegAddrCity: 'NAPLES',
        RegAddrState: 'FL',
        RegAddrZip: 34114,
        CongressionalDistrict: 25,
      },
      {
        RegAddrLine1: '5347 17TH PL SW',
        RegAddrCity: 'NAPLES',
        RegAddrState: 'FL',
        RegAddrZip: 34116,
        CongressionalDistrict: 25,
      },
      {
        RegAddrLine1: '8483 HOLLOW BROOK CIR',
        RegAddrCity: 'NAPLES',
        RegAddrState: 'FL',
        RegAddrZip: 34119,
        CongressionalDistrict: 25,
      },
      {
        RegAddrLine1: '2929 12TH AVE NE',
        RegAddrCity: 'NAPLES',
        RegAddrState: 'FL',
        RegAddrZip: 34120,
        CongressionalDistrict: 25,
      },
    ],
  },
  {
    name: 'FL-26',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/FL-26?ref=gh-pages',
    state: 'FL',
    state_and_number: 'FL-26',
    zip: 33001,
    congressionalDistrict: 26,
    addressesInDistrict: [
      {
        RegAddrLine1: '235 SW 17TH AVE',
        RegAddrCity: 'HOMESTEAD',
        RegAddrState: 'FL',
        RegAddrZip: 33030,
        CongressionalDistrict: 26,
      },
      {
        RegAddrLine1: '1700 NW 9TH ST',
        RegAddrCity: 'HOMESTEAD',
        RegAddrState: 'FL',
        RegAddrZip: 33030,
        CongressionalDistrict: 26,
      },
      {
        RegAddrLine1: '1310 KIA DR',
        RegAddrCity: 'HOMESTEAD',
        RegAddrState: 'FL',
        RegAddrZip: 33033,
        CongressionalDistrict: 26,
      },
      {
        RegAddrLine1: '2713 SAN REMO CIR',
        RegAddrCity: 'HOMESTEAD',
        RegAddrState: 'FL',
        RegAddrZip: 33035,
        CongressionalDistrict: 26,
      },
      {
        RegAddrLine1: '36 MOORINGS',
        RegAddrCity: 'KEY LARGO',
        RegAddrState: 'FL',
        RegAddrZip: 33037,
        CongressionalDistrict: 26,
      },
      {
        RegAddrLine1: '2459 SW 102ND CT',
        RegAddrCity: 'MIAMI',
        RegAddrState: 'FL',
        RegAddrZip: 33165,
        CongressionalDistrict: 26,
      },
      {
        RegAddrLine1: '4055 SW 107TH PL',
        RegAddrCity: 'MIAMI',
        RegAddrState: 'FL',
        RegAddrZip: 33165,
        CongressionalDistrict: 26,
      },
      {
        RegAddrLine1: '13174 SW 19TH TER',
        RegAddrCity: 'MIAMI',
        RegAddrState: 'FL',
        RegAddrZip: 33175,
        CongressionalDistrict: 26,
      },
      {
        RegAddrLine1: '11920 SW 182ND TER',
        RegAddrCity: 'MIAMI',
        RegAddrState: 'FL',
        RegAddrZip: 33177,
        CongressionalDistrict: 26,
      },
      {
        RegAddrLine1: '19640 SW 127TH CT',
        RegAddrCity: 'MIAMI',
        RegAddrState: 'FL',
        RegAddrZip: 33177,
        CongressionalDistrict: 26,
      },
      {
        RegAddrLine1: '14624 SW 63RD TER',
        RegAddrCity: 'MIAMI',
        RegAddrState: 'FL',
        RegAddrZip: 33183,
        CongressionalDistrict: 26,
      },
      {
        RegAddrLine1: '2443 SW 152ND PATH',
        RegAddrCity: 'MIAMI',
        RegAddrState: 'FL',
        RegAddrZip: 33185,
        CongressionalDistrict: 26,
      },
      {
        RegAddrLine1: '14601 SW 108TH ST',
        RegAddrCity: 'MIAMI',
        RegAddrState: 'FL',
        RegAddrZip: 33186,
        CongressionalDistrict: 26,
      },
      {
        RegAddrLine1: '15211 SW 164TH ST',
        RegAddrCity: 'MIAMI',
        RegAddrState: 'FL',
        RegAddrZip: 33187,
        CongressionalDistrict: 26,
      },
      {
        RegAddrLine1: '14863 SW 104TH ST',
        RegAddrCity: 'MIAMI',
        RegAddrState: 'FL',
        RegAddrZip: 33196,
        CongressionalDistrict: 26,
      },
      {
        RegAddrLine1: '14270 SW 147TH CT',
        RegAddrCity: 'MIAMI',
        RegAddrState: 'FL',
        RegAddrZip: 33196,
        CongressionalDistrict: 26,
      },
      {
        RegAddrLine1: '119 LAKE DR S',
        RegAddrCity: 'SUMMERLAND KEY',
        RegAddrState: 'FL',
        RegAddrZip: 33042,
        CongressionalDistrict: 26,
      },
    ],
  },
  {
    name: 'FL-27',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/FL-27?ref=gh-pages',
    state: 'FL',
    state_and_number: 'FL-27',
    zip: 33109,
    congressionalDistrict: 27,
    addressesInDistrict: [
      {
        RegAddrLine1: '419 VILABELLA AVE',
        RegAddrCity: 'CORAL GABLES',
        RegAddrState: 'FL',
        RegAddrZip: 33146,
        CongressionalDistrict: 27,
      },
      {
        RegAddrLine1: '9380 CARIBBEAN BLVD',
        RegAddrCity: 'CUTLER BAY',
        RegAddrState: 'FL',
        RegAddrZip: 33189,
        CongressionalDistrict: 27,
      },
      {
        RegAddrLine1: '20300 SW 106TH AVE',
        RegAddrCity: 'CUTLER BAY',
        RegAddrState: 'FL',
        RegAddrZip: 33189,
        CongressionalDistrict: 27,
      },
      {
        RegAddrLine1: '3458 DAY AVE',
        RegAddrCity: 'MIAMI',
        RegAddrState: 'FL',
        RegAddrZip: 33133,
        CongressionalDistrict: 27,
      },
      {
        RegAddrLine1: '1863 SW 9TH ST',
        RegAddrCity: 'MIAMI',
        RegAddrState: 'FL',
        RegAddrZip: 33135,
        CongressionalDistrict: 27,
      },
      {
        RegAddrLine1: '4990 SW 63RD AVE',
        RegAddrCity: 'MIAMI',
        RegAddrState: 'FL',
        RegAddrZip: 33155,
        CongressionalDistrict: 27,
      },
      {
        RegAddrLine1: '4925 SW 98TH PL',
        RegAddrCity: 'MIAMI',
        RegAddrState: 'FL',
        RegAddrZip: 33165,
        CongressionalDistrict: 27,
      },
      {
        RegAddrLine1: '10750 SW 67TH DR',
        RegAddrCity: 'MIAMI',
        RegAddrState: 'FL',
        RegAddrZip: 33173,
        CongressionalDistrict: 27,
      },
      {
        RegAddrLine1: '10221 SW 143RD ST',
        RegAddrCity: 'MIAMI',
        RegAddrState: 'FL',
        RegAddrZip: 33176,
        CongressionalDistrict: 27,
      },
      {
        RegAddrLine1: '9064 SW 113TH AVE',
        RegAddrCity: 'MIAMI',
        RegAddrState: 'FL',
        RegAddrZip: 33176,
        CongressionalDistrict: 27,
      },
      {
        RegAddrLine1: '15031 FILLMORE ST',
        RegAddrCity: 'MIAMI',
        RegAddrState: 'FL',
        RegAddrZip: 33176,
        CongressionalDistrict: 27,
      },
      {
        RegAddrLine1: '14644 SW 83RD CT',
        RegAddrCity: 'PALMETTO BAY',
        RegAddrState: 'FL',
        RegAddrZip: 33158,
        CongressionalDistrict: 27,
      },
      {
        RegAddrLine1: '11111 SW 77TH AVE',
        RegAddrCity: 'PINECREST',
        RegAddrState: 'FL',
        RegAddrZip: 33156,
        CongressionalDistrict: 27,
      },
    ],
  },
  {
    name: 'FL-3',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/FL-3?ref=gh-pages',
    state: 'FL',
    state_and_number: 'FL-3',
    zip: 32003,
    congressionalDistrict: 3,
    addressesInDistrict: [
      {
        RegAddrLine1: '12921 W STATE ROAD 235',
        RegAddrCity: 'ALACHUA',
        RegAddrState: 'FL',
        RegAddrZip: 32615,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1730 DOCKSIDE DR',
        RegAddrCity: 'FLEMING ISLAND',
        RegAddrState: 'FL',
        RegAddrZip: 32003,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '725 SE 4TH AVE',
        RegAddrCity: 'GAINESVILLE',
        RegAddrState: 'FL',
        RegAddrZip: 32601,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '6510 SW 40TH TER',
        RegAddrCity: 'GAINESVILLE',
        RegAddrState: 'FL',
        RegAddrZip: 32608,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '4808 NE 3RD PL',
        RegAddrCity: 'GAINESVILLE',
        RegAddrState: 'FL',
        RegAddrZip: 32641,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '9941 US HIGHWAY 301',
        RegAddrCity: 'HAMPTON',
        RegAddrState: 'FL',
        RegAddrZip: 32044,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '116 RAMSEY DR',
        RegAddrCity: 'MELROSE',
        RegAddrState: 'FL',
        RegAddrZip: 32666,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '5751 SHORT HORN RD',
        RegAddrCity: 'MIDDLEBURG',
        RegAddrState: 'FL',
        RegAddrZip: 32068,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '27211 NW 8TH LN',
        RegAddrCity: 'NEWBERRY',
        RegAddrState: 'FL',
        RegAddrZip: 32669,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '36 NE 32ND AVE',
        RegAddrCity: 'OCALA',
        RegAddrState: 'FL',
        RegAddrZip: 34470,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '5479 SE 35TH LOOP',
        RegAddrCity: 'OCALA',
        RegAddrState: 'FL',
        RegAddrZip: 34480,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '3810 HIDDEN VIEW DR',
        RegAddrCity: 'ORANGE PARK',
        RegAddrState: 'FL',
        RegAddrZip: 32065,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '4427 HANGING MOSS DR',
        RegAddrCity: 'ORANGE PARK',
        RegAddrState: 'FL',
        RegAddrZip: 32073,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '904 WOODLAWN ST',
        RegAddrCity: 'STARKE',
        RegAddrState: 'FL',
        RegAddrZip: 32091,
        CongressionalDistrict: 3,
      },
    ],
  },
  {
    name: 'FL-4',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/FL-4?ref=gh-pages',
    state: 'FL',
    state_and_number: 'FL-4',
    zip: 32009,
    congressionalDistrict: 4,
    addressesInDistrict: [
      {
        RegAddrLine1: '775 PLAZA',
        RegAddrCity: 'ATLANTIC BCH',
        RegAddrState: 'FL',
        RegAddrZip: 32233,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1903 SPRINGBROOK RD',
        RegAddrCity: 'FERN BCH',
        RegAddrState: 'FL',
        RegAddrZip: 32034,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2840 SEA GRAPE DR N',
        RegAddrCity: 'FERN BCH',
        RegAddrState: 'FL',
        RegAddrZip: 32034,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1046 MAPLE LN',
        RegAddrCity: 'JACKSONVILLE',
        RegAddrState: 'FL',
        RegAddrZip: 32207,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '3924 ORTEGA BLVD',
        RegAddrCity: 'JACKSONVILLE',
        RegAddrState: 'FL',
        RegAddrZip: 32210,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '15900 GIDRAN CT',
        RegAddrCity: 'JACKSONVILLE',
        RegAddrState: 'FL',
        RegAddrZip: 32218,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '3315 BLACKFOOT TRL S',
        RegAddrCity: 'JACKSONVILLE',
        RegAddrState: 'FL',
        RegAddrZip: 32223,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '13421 AQUILINE RD',
        RegAddrCity: 'JACKSONVILLE',
        RegAddrState: 'FL',
        RegAddrZip: 32224,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2677 ALTA CIR N',
        RegAddrCity: 'JACKSONVILLE',
        RegAddrState: 'FL',
        RegAddrZip: 32226,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '8700 SOUTHERN GLEN DR',
        RegAddrCity: 'JACKSONVILLE',
        RegAddrState: 'FL',
        RegAddrZip: 32256,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '9171 TOTTENHAM CT',
        RegAddrCity: 'JACKSONVILLE',
        RegAddrState: 'FL',
        RegAddrZip: 32257,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '4227 WINDERGATE DR',
        RegAddrCity: 'JACKSONVILLE',
        RegAddrState: 'FL',
        RegAddrZip: 32257,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '4152 MARKIN DR W',
        RegAddrCity: 'JACKSONVILLE',
        RegAddrState: 'FL',
        RegAddrZip: 32277,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '629 2ND AVE S',
        RegAddrCity: 'JAX BCH',
        RegAddrState: 'FL',
        RegAddrZip: 32250,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '35 AUBURNDALE DR',
        RegAddrCity: 'PONTE VEDRA',
        RegAddrState: 'FL',
        RegAddrZip: 32081,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1761 FRUIT COVE WOODS DR',
        RegAddrCity: 'SAINT JOHNS',
        RegAddrState: 'FL',
        RegAddrZip: 32259,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '4120 LONICERA LOOP',
        RegAddrCity: 'SAINT JOHNS',
        RegAddrState: 'FL',
        RegAddrZip: 32259,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '308 ANDREAS ST',
        RegAddrCity: 'ST AUGUSTINE',
        RegAddrState: 'FL',
        RegAddrZip: 32080,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '300 BOSTWICK CIR',
        RegAddrCity: 'ST AUGUSTINE',
        RegAddrState: 'FL',
        RegAddrZip: 32092,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '712 BATTERSEA DR',
        RegAddrCity: 'ST AUGUSTINE',
        RegAddrState: 'FL',
        RegAddrZip: 32095,
        CongressionalDistrict: 4,
      },
    ],
  },
  {
    name: 'FL-5',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/FL-5?ref=gh-pages',
    state: 'FL',
    state_and_number: 'FL-5',
    zip: 32040,
    congressionalDistrict: 5,
    addressesInDistrict: [
      {
        RegAddrLine1: '59 JAYTEE CT',
        RegAddrCity: 'HAVANA',
        RegAddrState: 'FL',
        RegAddrZip: 32333,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '619 LONG BRANCH BLVD',
        RegAddrCity: 'JACKSONVILLE',
        RegAddrState: 'FL',
        RegAddrZip: 32206,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '1443 W 32ND ST',
        RegAddrCity: 'JACKSONVILLE',
        RegAddrState: 'FL',
        RegAddrZip: 32209,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '1321 W 10TH ST',
        RegAddrCity: 'JACKSONVILLE',
        RegAddrState: 'FL',
        RegAddrZip: 32209,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '3024 LOPEZ RD',
        RegAddrCity: 'JACKSONVILLE',
        RegAddrState: 'FL',
        RegAddrZip: 32216,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '8574 PEBBLE ST',
        RegAddrCity: 'JACKSONVILLE',
        RegAddrState: 'FL',
        RegAddrZip: 32221,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '1609 SADDLEBROOK LN',
        RegAddrCity: 'JACKSONVILLE',
        RegAddrState: 'FL',
        RegAddrZip: 32221,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '10181 POWELL CREEK CT',
        RegAddrCity: 'JACKSONVILLE',
        RegAddrState: 'FL',
        RegAddrZip: 32222,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '5905 ROCKY RD',
        RegAddrCity: 'JACKSONVILLE',
        RegAddrState: 'FL',
        RegAddrZip: 32244,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '5522 BENNINGTON DR',
        RegAddrCity: 'JACKSONVILLE',
        RegAddrState: 'FL',
        RegAddrZip: 32244,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '7069 SW 38TH AVE',
        RegAddrCity: 'JASPER',
        RegAddrState: 'FL',
        RegAddrZip: 32052,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '3706 NW 109TH AVE',
        RegAddrCity: 'JASPER',
        RegAddrState: 'FL',
        RegAddrZip: 32052,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '10415 SAINT MARYS CIR E',
        RegAddrCity: 'MACCLENNY',
        RegAddrState: 'FL',
        RegAddrZip: 32063,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '1031 MARTIN LUTHER KING JR BLVD',
        RegAddrCity: 'QUINCY',
        RegAddrState: 'FL',
        RegAddrZip: 32351,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '2079 CONTINENTAL AVE',
        RegAddrCity: 'TALLAHASSEE',
        RegAddrState: 'FL',
        RegAddrZip: 32304,
        CongressionalDistrict: 5,
      },
    ],
  },
  {
    name: 'FL-6',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/FL-6?ref=gh-pages',
    state: 'FL',
    state_and_number: 'FL-6',
    zip: 32102,
    congressionalDistrict: 6,
    addressesInDistrict: [
      {
        RegAddrLine1: '18300 DEMKO RD',
        RegAddrCity: 'ALTOONA',
        RegAddrState: 'FL',
        RegAddrZip: 32702,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '2017 N OLEANDER AVE',
        RegAddrCity: 'DAYTONA BEACH',
        RegAddrState: 'FL',
        RegAddrZip: 32118,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '1818 W MINNESOTA AVE',
        RegAddrCity: 'DELAND',
        RegAddrState: 'FL',
        RegAddrZip: 32720,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '1890 COLLINS GROVE RD',
        RegAddrCity: 'DELAND',
        RegAddrState: 'FL',
        RegAddrZip: 32720,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '3042 TURTLE DOVE TRL',
        RegAddrCity: 'DELAND',
        RegAddrState: 'FL',
        RegAddrZip: 32724,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '745 E RICH AVE',
        RegAddrCity: 'DELAND',
        RegAddrState: 'FL',
        RegAddrZip: 32724,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '2074 SNOOK DR',
        RegAddrCity: 'DELTONA',
        RegAddrState: 'FL',
        RegAddrZip: 32738,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '1502 STONE TRL',
        RegAddrCity: 'ENTERPRISE',
        RegAddrState: 'FL',
        RegAddrZip: 32725,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '32103 STATE ROAD 44',
        RegAddrCity: 'EUSTIS',
        RegAddrState: 'FL',
        RegAddrZip: 32736,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '120 PALM DR',
        RegAddrCity: 'GEORGETOWN',
        RegAddrState: 'FL',
        RegAddrZip: 32139,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '1322 MORAVIA AVE',
        RegAddrCity: 'HOLLY HILL',
        RegAddrState: 'FL',
        RegAddrZip: 32117,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '193 N LAKEVIEW DR',
        RegAddrCity: 'LAKE HELEN',
        RegAddrState: 'FL',
        RegAddrZip: 32744,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '2816 CASANOVA CT',
        RegAddrCity: 'NEW SMYRNA',
        RegAddrState: 'FL',
        RegAddrZip: 32168,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '85 BOBCAT LN',
        RegAddrCity: 'OSTEEN',
        RegAddrState: 'FL',
        RegAddrZip: 32764,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '701 LEMON BLUFF RD',
        RegAddrCity: 'OSTEEN',
        RegAddrState: 'FL',
        RegAddrZip: 32764,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '251 COLLINS RD',
        RegAddrCity: 'OSTEEN',
        RegAddrState: 'FL',
        RegAddrZip: 32764,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '15 BENNETT LN',
        RegAddrCity: 'PALM COAST',
        RegAddrState: 'FL',
        RegAddrZip: 32137,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '7 RED BIRCH LN',
        RegAddrCity: 'PALM COAST',
        RegAddrState: 'FL',
        RegAddrZip: 32164,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '5771 STEWART AVE',
        RegAddrCity: 'PORT ORANGE',
        RegAddrState: 'FL',
        RegAddrZip: 32127,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '107 SAND PEBBLE CIR',
        RegAddrCity: 'PORT ORANGE',
        RegAddrState: 'FL',
        RegAddrZip: 32129,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '925 ASPEN DR',
        RegAddrCity: 'SOUTH DAYTONA',
        RegAddrState: 'FL',
        RegAddrZip: 32119,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '177 CAPTAINS POINTE CIR',
        RegAddrCity: 'ST AUGUSTINE',
        RegAddrState: 'FL',
        RegAddrZip: 32086,
        CongressionalDistrict: 6,
      },
    ],
  },
  {
    name: 'FL-7',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/FL-7?ref=gh-pages',
    state: 'FL',
    state_and_number: 'FL-7',
    zip: 32701,
    congressionalDistrict: 7,
    addressesInDistrict: [
      {
        RegAddrLine1: '5356 PEPPER BRUSH CV',
        RegAddrCity: 'APOPKA',
        RegAddrState: 'FL',
        RegAddrZip: 32703,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '920 PICKFAIR TER',
        RegAddrCity: 'LAKE MARY',
        RegAddrState: 'FL',
        RegAddrZip: 32746,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '370 FERDINAND DR',
        RegAddrCity: 'LONGWOOD',
        RegAddrState: 'FL',
        RegAddrZip: 32750,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '334 WEKIVA COVE RD',
        RegAddrCity: 'LONGWOOD',
        RegAddrState: 'FL',
        RegAddrZip: 32779,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '4321 INWOOD LANDING DR',
        RegAddrCity: 'ORLANDO',
        RegAddrState: 'FL',
        RegAddrZip: 32812,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '212 CHUTNEY DR',
        RegAddrCity: 'ORLANDO',
        RegAddrState: 'FL',
        RegAddrZip: 32825,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '14807 BONNYBRIDGE DR',
        RegAddrCity: 'ORLANDO',
        RegAddrState: 'FL',
        RegAddrZip: 32826,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '3874 QUEEN SAGO CT',
        RegAddrCity: 'OVIEDO',
        RegAddrState: 'FL',
        RegAddrZip: 32765,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '3433 STERLING LAKE CIR',
        RegAddrCity: 'OVIEDO',
        RegAddrState: 'FL',
        RegAddrZip: 32765,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '1771 PRAIRIE VIEW LN',
        RegAddrCity: 'OVIEDO',
        RegAddrState: 'FL',
        RegAddrZip: 32765,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '4912 RED BRICK RUN',
        RegAddrCity: 'SANFORD',
        RegAddrState: 'FL',
        RegAddrZip: 32771,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '795 SUMMER PALM CT',
        RegAddrCity: 'SANFORD',
        RegAddrState: 'FL',
        RegAddrZip: 32771,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '3463 ARNEL DR',
        RegAddrCity: 'WINTER PARK',
        RegAddrState: 'FL',
        RegAddrZip: 32792,
        CongressionalDistrict: 7,
      },
    ],
  },
  {
    name: 'FL-8',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/FL-8?ref=gh-pages',
    state: 'FL',
    state_and_number: 'FL-8',
    zip: 32709,
    congressionalDistrict: 8,
    addressesInDistrict: [
      {
        RegAddrLine1: '2420 DELYS ST',
        RegAddrCity: 'COCOA',
        RegAddrState: 'FL',
        RegAddrZip: 32926,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '4659 N FRIDAY CIR',
        RegAddrCity: 'COCOA',
        RegAddrState: 'FL',
        RegAddrZip: 32926,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '6595 INDIAN RIVER BLVD',
        RegAddrCity: 'GRANT VLKRIA',
        RegAddrState: 'FL',
        RegAddrZip: 32949,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '1875 RIVER SHORE DR',
        RegAddrCity: 'INDIALANTIC',
        RegAddrState: 'FL',
        RegAddrZip: 32903,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '1041 INDIAN OAKS DR',
        RegAddrCity: 'MELBOURNE',
        RegAddrState: 'FL',
        RegAddrZip: 32901,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '4212 COLLINWOOD DR',
        RegAddrCity: 'MELBOURNE',
        RegAddrState: 'FL',
        RegAddrZip: 32901,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '2740 MADERIA CIR',
        RegAddrCity: 'MELBOURNE',
        RegAddrState: 'FL',
        RegAddrZip: 32935,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '680 DEERHURST DR',
        RegAddrCity: 'MELBOURNE',
        RegAddrState: 'FL',
        RegAddrZip: 32940,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '3471 TIPPERARY DR',
        RegAddrCity: 'MERRITT IS',
        RegAddrState: 'FL',
        RegAddrZip: 32953,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '2950 S TROPICAL TRL',
        RegAddrCity: 'MERRITT ISLAND',
        RegAddrState: 'FL',
        RegAddrZip: 32952,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '841 LAMPLIGHTER DR NW',
        RegAddrCity: 'PALM BAY',
        RegAddrState: 'FL',
        RegAddrZip: 32907,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '1676 JACINTO AVE NW',
        RegAddrCity: 'PALM BAY',
        RegAddrState: 'FL',
        RegAddrZip: 32907,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '142 PALM CIR',
        RegAddrCity: 'PALM SHORES',
        RegAddrState: 'FL',
        RegAddrZip: 32940,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '186 SE 1ST ST',
        RegAddrCity: 'SATELLITE BCH',
        RegAddrState: 'FL',
        RegAddrZip: 32937,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '4903 SQUIRES DR',
        RegAddrCity: 'TITUSVILLE',
        RegAddrState: 'FL',
        RegAddrZip: 32796,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '1585 39TH AVE',
        RegAddrCity: 'VERO BEACH',
        RegAddrState: 'FL',
        RegAddrZip: 32960,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '472 10TH PL',
        RegAddrCity: 'VERO BEACH',
        RegAddrState: 'FL',
        RegAddrZip: 32960,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '1335 24TH AVE SW',
        RegAddrCity: 'VERO BEACH',
        RegAddrState: 'FL',
        RegAddrZip: 32962,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '8146 97TH AVE',
        RegAddrCity: 'VERO BEACH',
        RegAddrState: 'FL',
        RegAddrZip: 32967,
        CongressionalDistrict: 8,
      },
    ],
  },
  {
    name: 'FL-9',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/FL-9?ref=gh-pages',
    state: 'FL',
    state_and_number: 'FL-9',
    zip: 32824,
    congressionalDistrict: 9,
    addressesInDistrict: [
      {
        RegAddrLine1: '6843 SUNDROP ST',
        RegAddrCity: 'HARMONY',
        RegAddrState: 'FL',
        RegAddrZip: 34773,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '2438 SHELBY CIR',
        RegAddrCity: 'KISSIMMEE',
        RegAddrState: 'FL',
        RegAddrZip: 34743,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '1408 SLIM CT',
        RegAddrCity: 'KISSIMMEE',
        RegAddrState: 'FL',
        RegAddrZip: 34744,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '2709 GARRETT NICHOLAS LOOP',
        RegAddrCity: 'KISSIMMEE',
        RegAddrState: 'FL',
        RegAddrZip: 34746,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '2149 THE OAKS BLVD',
        RegAddrCity: 'KISSIMMEE',
        RegAddrState: 'FL',
        RegAddrZip: 34746,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '8795 MERCHAN TRL',
        RegAddrCity: 'KISSIMMEE',
        RegAddrState: 'FL',
        RegAddrZip: 34747,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '5426 BRYCE CANYON DR',
        RegAddrCity: 'KISSIMMEE',
        RegAddrState: 'FL',
        RegAddrZip: 34758,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '652 MILAN DR',
        RegAddrCity: 'KISSIMMEE',
        RegAddrState: 'FL',
        RegAddrZip: 34758,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '549 SIENNA DR',
        RegAddrCity: 'KISSIMMEE',
        RegAddrState: 'FL',
        RegAddrZip: 34759,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '120 E HOFFMAN ST',
        RegAddrCity: 'LAKE ALFRED',
        RegAddrState: 'FL',
        RegAddrZip: 33850,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '4207 MUIRFIELD LOOP',
        RegAddrCity: 'LAKE WALES',
        RegAddrState: 'FL',
        RegAddrZip: 33859,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '13753 SACHS AVE',
        RegAddrCity: 'ORLANDO',
        RegAddrState: 'FL',
        RegAddrZip: 32827,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '14430 GOLDEN RAIN TREE BLVD',
        RegAddrCity: 'ORLANDO',
        RegAddrState: 'FL',
        RegAddrZip: 32828,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '14004 CHERRY BLOSSOM LN',
        RegAddrCity: 'ORLANDO',
        RegAddrState: 'FL',
        RegAddrZip: 32828,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '2232 STRAWBERRY TREE LN',
        RegAddrCity: 'ORLANDO',
        RegAddrState: 'FL',
        RegAddrZip: 32828,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '2421 WILD TAMARIND BLVD',
        RegAddrCity: 'ORLANDO',
        RegAddrState: 'FL',
        RegAddrZip: 32828,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '10227 SEROTINA CT',
        RegAddrCity: 'ORLANDO',
        RegAddrState: 'FL',
        RegAddrZip: 32832,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '5205 JONES RD',
        RegAddrCity: 'SAINT CLOUD',
        RegAddrState: 'FL',
        RegAddrZip: 34771,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '1149 SENECA TRL',
        RegAddrCity: 'SAINT CLOUD',
        RegAddrState: 'FL',
        RegAddrZip: 34772,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '1813 SIR LANCELOT CIR',
        RegAddrCity: 'SAINT CLOUD',
        RegAddrState: 'FL',
        RegAddrZip: 34772,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '3521 AVENUE R NW',
        RegAddrCity: 'WINTER HAVEN',
        RegAddrState: 'FL',
        RegAddrZip: 33881,
        CongressionalDistrict: 9,
      },
    ],
  },
  {
    name: 'GA-1',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/GA-1?ref=gh-pages',
    state: 'GA',
    state_and_number: 'GA-1',
    zip: 30427,
    congressionalDistrict: 1,
    addressesInDistrict: [
      {
        RegAddrLine1: '3301 WARE ST',
        RegAddrCity: 'BLACKSHEAR',
        RegAddrState: 'GA',
        RegAddrZip: 31516,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '662 BUCK SWAMP RD',
        RegAddrCity: 'BRUNSWICK',
        RegAddrState: 'GA',
        RegAddrZip: 31523,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '107 TEAK TRL',
        RegAddrCity: 'BRUNSWICK',
        RegAddrState: 'GA',
        RegAddrZip: 31525,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1105 ODYSSEY LAKE CIR S',
        RegAddrCity: 'BRUNSWICK',
        RegAddrState: 'GA',
        RegAddrZip: 31525,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '522 OLD MISSION RD',
        RegAddrCity: 'BRUNSWICK',
        RegAddrState: 'GA',
        RegAddrZip: 31525,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '221 PROMISE LN',
        RegAddrCity: 'BRUNSWICK',
        RegAddrState: 'GA',
        RegAddrZip: 31525,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1028 US HIGHWAY 80',
        RegAddrCity: 'EDEN',
        RegAddrState: 'GA',
        RegAddrZip: 31307,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '75 COURSEY RD',
        RegAddrCity: 'ELLABELL',
        RegAddrState: 'GA',
        RegAddrZip: 31308,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '88 NANCY AVE',
        RegAddrCity: 'FARGO',
        RegAddrState: 'GA',
        RegAddrZip: 31631,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2261 GULICK AVE',
        RegAddrCity: 'FORT STEWART',
        RegAddrState: 'GA',
        RegAddrZip: 31314,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '109 BAYONET WAY',
        RegAddrCity: 'FORT STEWART',
        RegAddrState: 'GA',
        RegAddrZip: 31315,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '617 WINDHAVEN DR',
        RegAddrCity: 'HINESVILLE',
        RegAddrState: 'GA',
        RegAddrZip: 31313,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '802 LEXINGTON PL',
        RegAddrCity: 'HINESVILLE',
        RegAddrState: 'GA',
        RegAddrZip: 31313,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '715 HEMINGWAY DR',
        RegAddrCity: 'HINESVILLE',
        RegAddrState: 'GA',
        RegAddrZip: 31313,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '891 ORANGE ST',
        RegAddrCity: 'HOMELAND',
        RegAddrState: 'GA',
        RegAddrZip: 31537,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '236 OQUINN RD',
        RegAddrCity: 'JESUP',
        RegAddrState: 'GA',
        RegAddrZip: 31545,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '413 N 5TH ST',
        RegAddrCity: 'JESUP',
        RegAddrState: 'GA',
        RegAddrZip: 31545,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '411 IVY ST',
        RegAddrCity: 'JESUP',
        RegAddrState: 'GA',
        RegAddrZip: 31546,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '410 S ARIZONA ST',
        RegAddrCity: 'KINGSLAND',
        RegAddrState: 'GA',
        RegAddrZip: 31548,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '311 LAKE FOREST DR',
        RegAddrCity: 'KINGSLAND',
        RegAddrState: 'GA',
        RegAddrZip: 31548,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '484 WILLIAMS RD',
        RegAddrCity: 'PEMBROKE',
        RegAddrState: 'GA',
        RegAddrZip: 31321,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '118 SUTTON LN',
        RegAddrCity: 'POOLER',
        RegAddrState: 'GA',
        RegAddrZip: 31322,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '114 MALLORY PL',
        RegAddrCity: 'POOLER',
        RegAddrState: 'GA',
        RegAddrZip: 31322,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '49 OLD MILL RD',
        RegAddrCity: 'PORT WENTWRTH',
        RegAddrState: 'GA',
        RegAddrZip: 31407,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '410 BOYD DR',
        RegAddrCity: 'RICHMOND HILL',
        RegAddrState: 'GA',
        RegAddrZip: 31324,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '18 WILLIAM HALL WAY',
        RegAddrCity: 'RICHMOND HILL',
        RegAddrState: 'GA',
        RegAddrZip: 31324,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '191 PEREGRINE CIR',
        RegAddrCity: 'RICHMOND HILL',
        RegAddrState: 'GA',
        RegAddrZip: 31324,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '129 SAPALO DR',
        RegAddrCity: 'SAINT MARYS',
        RegAddrState: 'GA',
        RegAddrZip: 31558,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '255 KRISTINS DR',
        RegAddrCity: 'SAINT MARYS',
        RegAddrState: 'GA',
        RegAddrZip: 31558,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1237 E 40TH ST',
        RegAddrCity: 'SAVANNAH',
        RegAddrState: 'GA',
        RegAddrZip: 31404,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2017 E 59TH ST',
        RegAddrCity: 'SAVANNAH',
        RegAddrState: 'GA',
        RegAddrZip: 31404,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '5009 COMPTON RD',
        RegAddrCity: 'SAVANNAH',
        RegAddrState: 'GA',
        RegAddrZip: 31404,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '4304 TENNYSON LN',
        RegAddrCity: 'SAVANNAH',
        RegAddrState: 'GA',
        RegAddrZip: 31405,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '113 E 57TH ST',
        RegAddrCity: 'SAVANNAH',
        RegAddrState: 'GA',
        RegAddrZip: 31405,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1114 MADRID AVE',
        RegAddrCity: 'SAVANNAH',
        RegAddrState: 'GA',
        RegAddrZip: 31406,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '10182 FERGUSON AVE',
        RegAddrCity: 'SAVANNAH',
        RegAddrState: 'GA',
        RegAddrZip: 31406,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '8 LORWOOD DR',
        RegAddrCity: 'SAVANNAH',
        RegAddrState: 'GA',
        RegAddrZip: 31406,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '10804 WHITE BLUFF RD',
        RegAddrCity: 'SAVANNAH',
        RegAddrState: 'GA',
        RegAddrZip: 31406,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2040 COLONIAL DR',
        RegAddrCity: 'SAVANNAH',
        RegAddrState: 'GA',
        RegAddrZip: 31406,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '7 CENTRAL PARK WAY',
        RegAddrCity: 'SAVANNAH',
        RegAddrState: 'GA',
        RegAddrZip: 31407,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '313 BILLY MITCHELL BLVD',
        RegAddrCity: 'SAVANNAH',
        RegAddrState: 'GA',
        RegAddrZip: 31409,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '105 LAZY LAGOON WAY',
        RegAddrCity: 'SAVANNAH',
        RegAddrState: 'GA',
        RegAddrZip: 31410,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '316 TRAFFORD LN',
        RegAddrCity: 'SAVANNAH',
        RegAddrState: 'GA',
        RegAddrZip: 31410,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '105 HAMPSHIRE RD',
        RegAddrCity: 'SAVANNAH',
        RegAddrState: 'GA',
        RegAddrZip: 31410,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '3 ODINGSELL LN',
        RegAddrCity: 'SAVANNAH',
        RegAddrState: 'GA',
        RegAddrZip: 31411,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '109 STATESBORO RD',
        RegAddrCity: 'SAVANNAH',
        RegAddrState: 'GA',
        RegAddrZip: 31419,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '11406 COLLEEN DR',
        RegAddrCity: 'SAVANNAH',
        RegAddrState: 'GA',
        RegAddrZip: 31419,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '331 W 44TH ST',
        RegAddrCity: 'SEA ISLAND',
        RegAddrState: 'GA',
        RegAddrZip: 31561,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '906 CHAMPNEY',
        RegAddrCity: 'ST SIMONS IS',
        RegAddrState: 'GA',
        RegAddrZip: 31522,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1004 OCEAN BLVD',
        RegAddrCity: 'ST SIMONS IS',
        RegAddrState: 'GA',
        RegAddrZip: 31522,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '3913 CLAYBOURNE LN',
        RegAddrCity: 'VALDOSTA',
        RegAddrState: 'GA',
        RegAddrZip: 31605,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '509 MCDONALD ST',
        RegAddrCity: 'WAYCROSS',
        RegAddrState: 'GA',
        RegAddrZip: 31503,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1249 RIVERDALE DR',
        RegAddrCity: 'WAYCROSS',
        RegAddrState: 'GA',
        RegAddrZip: 31503,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '292 BRICKYARD RD',
        RegAddrCity: 'WAYNESVILLE',
        RegAddrState: 'GA',
        RegAddrZip: 31566,
        CongressionalDistrict: 1,
      },
    ],
  },
  {
    name: 'GA-10',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/GA-10?ref=gh-pages',
    state: 'GA',
    state_and_number: 'GA-10',
    zip: 30011,
    congressionalDistrict: 10,
    addressesInDistrict: [
      {
        RegAddrLine1: '4041 VERN SIKKING RD',
        RegAddrCity: 'APPLING',
        RegAddrState: 'GA',
        RegAddrZip: 30802,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '465 N LUMPKIN ST',
        RegAddrCity: 'ATHENS',
        RegAddrState: 'GA',
        RegAddrZip: 30601,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '170 CARPENTERS CIR',
        RegAddrCity: 'ATHENS',
        RegAddrState: 'GA',
        RegAddrZip: 30601,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '1000 LAKESIDE DR',
        RegAddrCity: 'ATHENS',
        RegAddrState: 'GA',
        RegAddrZip: 30605,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '101 CARRIAGE CT',
        RegAddrCity: 'ATHENS',
        RegAddrState: 'GA',
        RegAddrZip: 30605,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '160 RIVERDALE DR',
        RegAddrCity: 'ATHENS',
        RegAddrState: 'GA',
        RegAddrZip: 30605,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '317 N CHASE ST',
        RegAddrCity: 'ATHENS',
        RegAddrState: 'GA',
        RegAddrZip: 30606,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '1410 BISHOP PT',
        RegAddrCity: 'ATHENS',
        RegAddrState: 'GA',
        RegAddrZip: 30606,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '271 OAK RDG',
        RegAddrCity: 'AUBURN',
        RegAddrState: 'GA',
        RegAddrZip: 30011,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '453 TANNERS BRIDGE RD',
        RegAddrCity: 'BETHLEHEM',
        RegAddrState: 'GA',
        RegAddrZip: 30620,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '169 CEDAR COVE DR',
        RegAddrCity: 'BUCKHEAD',
        RegAddrState: 'GA',
        RegAddrZip: 30625,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '215 PEBBLE RIDGE DR',
        RegAddrCity: 'COVINGTON',
        RegAddrState: 'GA',
        RegAddrZip: 30014,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '115 KAY CIR',
        RegAddrCity: 'COVINGTON',
        RegAddrState: 'GA',
        RegAddrZip: 30014,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '212 HARGROVE LAKE RD',
        RegAddrCity: 'CRAWFORD',
        RegAddrState: 'GA',
        RegAddrZip: 30630,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '379 VILLAGE BROAD ST',
        RegAddrCity: 'DACULA',
        RegAddrState: 'GA',
        RegAddrZip: 30019,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '585 CAPE IVEY DR',
        RegAddrCity: 'DACULA',
        RegAddrState: 'GA',
        RegAddrZip: 30019,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '3695 BURNT HICKORY DR',
        RegAddrCity: 'DACULA',
        RegAddrState: 'GA',
        RegAddrZip: 30019,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '1070 LEIGHS BROOK WAY',
        RegAddrCity: 'DACULA',
        RegAddrState: 'GA',
        RegAddrZip: 30019,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '2330 HINTON RD',
        RegAddrCity: 'DACULA',
        RegAddrState: 'GA',
        RegAddrZip: 30019,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '419 E MARION ST',
        RegAddrCity: 'EATONTON',
        RegAddrState: 'GA',
        RegAddrZip: 31024,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '112 SHELTON WAY',
        RegAddrCity: 'EATONTON',
        RegAddrState: 'GA',
        RegAddrZip: 31024,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '3402 AMBERLEY DR',
        RegAddrCity: 'EVANS',
        RegAddrState: 'GA',
        RegAddrZip: 30809,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '3372 CARL HARRELL RD',
        RegAddrCity: 'GIBSON',
        RegAddrState: 'GA',
        RegAddrZip: 30810,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '1910 HARDEMAN MILL RD',
        RegAddrCity: 'GOOD HOPE',
        RegAddrState: 'GA',
        RegAddrZip: 30641,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '1020 GRANITE COVE CT',
        RegAddrCity: 'GREENSBORO',
        RegAddrState: 'GA',
        RegAddrZip: 30642,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '325 INDIAN SPRINGS ST',
        RegAddrCity: 'JACKSON',
        RegAddrState: 'GA',
        RegAddrZip: 30233,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '422 CASTLE TOP LN',
        RegAddrCity: 'LAWRENCEVILLE',
        RegAddrState: 'GA',
        RegAddrZip: 30045,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '3151 SOUTH SMITH RD',
        RegAddrCity: 'LOGANVILLE',
        RegAddrState: 'GA',
        RegAddrZip: 30052,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '5320 CENTER HILL CHURCH RD',
        RegAddrCity: 'LOGANVILLE',
        RegAddrState: 'GA',
        RegAddrZip: 30052,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '2173 NANCY WAY',
        RegAddrCity: 'LOGANVILLE',
        RegAddrState: 'GA',
        RegAddrZip: 30052,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '6203 GREENS MILL RDG',
        RegAddrCity: 'LOGANVILLE',
        RegAddrState: 'GA',
        RegAddrZip: 30052,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '872 DIXIE AVE',
        RegAddrCity: 'MADISON',
        RegAddrState: 'GA',
        RegAddrZip: 30650,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '1130 JIM THOMAS RD',
        RegAddrCity: 'MADISON',
        RegAddrState: 'GA',
        RegAddrZip: 30650,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '220 OVERLAND TRL',
        RegAddrCity: 'MCDONOUGH',
        RegAddrState: 'GA',
        RegAddrZip: 30252,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '327 CATTLEMANS CIR',
        RegAddrCity: 'MCDONOUGH',
        RegAddrState: 'GA',
        RegAddrZip: 30252,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '1128 RIVER GREEN CT',
        RegAddrCity: 'MCDONOUGH',
        RegAddrState: 'GA',
        RegAddrZip: 30252,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '1018 OLA DALE DR',
        RegAddrCity: 'MCDONOUGH',
        RegAddrState: 'GA',
        RegAddrZip: 30252,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '147 MOUNT BETHEL RD',
        RegAddrCity: 'MCDONOUGH',
        RegAddrState: 'GA',
        RegAddrZip: 30252,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '104 OVERLOOK DR',
        RegAddrCity: 'MCDONOUGH',
        RegAddrState: 'GA',
        RegAddrZip: 30252,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '221 LAKESHORE DR',
        RegAddrCity: 'MILLEDGEVILLE',
        RegAddrState: 'GA',
        RegAddrZip: 31061,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '110 ROSEWOOD DR SE',
        RegAddrCity: 'MILLEDGEVILLE',
        RegAddrState: 'GA',
        RegAddrZip: 31061,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '102 RAMONA DR SW',
        RegAddrCity: 'MILLEDGEVILLE',
        RegAddrState: 'GA',
        RegAddrZip: 31061,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '104 HILANDALE DR',
        RegAddrCity: 'MILLEDGEVILLE',
        RegAddrState: 'GA',
        RegAddrZip: 31061,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '3200 STEWART LAKE RD',
        RegAddrCity: 'MONROE',
        RegAddrState: 'GA',
        RegAddrZip: 30655,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '2095 WOODLAKE BLVD',
        RegAddrCity: 'MONROE',
        RegAddrState: 'GA',
        RegAddrZip: 30655,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '1421 POPLAR OAKS TRL',
        RegAddrCity: 'MONROE',
        RegAddrState: 'GA',
        RegAddrZip: 30655,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '4510 SMITH MILL RD',
        RegAddrCity: 'MONTICELLO',
        RegAddrState: 'GA',
        RegAddrZip: 31064,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '5287 WAPAKONATA TRL',
        RegAddrCity: 'OXFORD',
        RegAddrState: 'GA',
        RegAddrZip: 30054,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '1650 SUBURBAN DR',
        RegAddrCity: 'SANDERSVILLE',
        RegAddrState: 'GA',
        RegAddrZip: 31082,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '10283 DEEPSTEP RD',
        RegAddrCity: 'SANDERSVILLE',
        RegAddrState: 'GA',
        RegAddrZip: 31082,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '740 RIVER COVE RD',
        RegAddrCity: 'SOCIAL CIRCLE',
        RegAddrState: 'GA',
        RegAddrZip: 30025,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '138 KINGSTOWN WAY',
        RegAddrCity: 'THOMSON',
        RegAddrState: 'GA',
        RegAddrZip: 30824,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '1071 PORTER CREEK DR',
        RegAddrCity: 'WATKINSVILLE',
        RegAddrState: 'GA',
        RegAddrZip: 30677,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '506 HEMLOCK DR',
        RegAddrCity: 'WINDER',
        RegAddrState: 'GA',
        RegAddrZip: 30680,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '253 ELKS ST',
        RegAddrCity: 'WINDER',
        RegAddrState: 'GA',
        RegAddrZip: 30680,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '425 BRIARWOOD RD',
        RegAddrCity: 'WINDER',
        RegAddrState: 'GA',
        RegAddrZip: 30680,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '655 MAJESTY XING',
        RegAddrCity: 'WINDER',
        RegAddrState: 'GA',
        RegAddrZip: 30680,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '1562 COUNTRY CLUB CIR',
        RegAddrCity: 'WRENS',
        RegAddrState: 'GA',
        RegAddrZip: 30833,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '1801 US HIGHWAY 319 S',
        RegAddrCity: 'WRIGHTSVILLE',
        RegAddrState: 'GA',
        RegAddrZip: 31096,
        CongressionalDistrict: 10,
      },
    ],
  },
  {
    name: 'GA-11',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/GA-11?ref=gh-pages',
    state: 'GA',
    state_and_number: 'GA-11',
    zip: 30008,
    congressionalDistrict: 11,
    addressesInDistrict: [
      {
        RegAddrLine1: '2579 WILLOW GROVE RD NW',
        RegAddrCity: 'ACWORTH',
        RegAddrState: 'GA',
        RegAddrZip: 30101,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '1984 HEDGE BROOKE TRL NW',
        RegAddrCity: 'ACWORTH',
        RegAddrState: 'GA',
        RegAddrZip: 30101,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '4774 ROSE ARBOR DR NW',
        RegAddrCity: 'ACWORTH',
        RegAddrState: 'GA',
        RegAddrZip: 30101,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '575 DELPHINIUM BLVD NW',
        RegAddrCity: 'ACWORTH',
        RegAddrState: 'GA',
        RegAddrZip: 30102,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '6881 W WOODCLIFFE CT',
        RegAddrCity: 'ACWORTH',
        RegAddrState: 'GA',
        RegAddrZip: 30102,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '4956 DAY LILY WAY NW',
        RegAddrCity: 'ACWORTH',
        RegAddrState: 'GA',
        RegAddrZip: 30102,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '31 EAGLE MOUNTAIN TRL',
        RegAddrCity: 'ADAIRSVILLE',
        RegAddrState: 'GA',
        RegAddrZip: 30103,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '134 OAKDALE DR',
        RegAddrCity: 'ADAIRSVILLE',
        RegAddrState: 'GA',
        RegAddrZip: 30103,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '650 PHIPPS BLVD NE',
        RegAddrCity: 'ATLANTA',
        RegAddrState: 'GA',
        RegAddrZip: 30326,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '850 FAIRFIELD RD NW',
        RegAddrCity: 'ATLANTA',
        RegAddrState: 'GA',
        RegAddrZip: 30327,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '3065 FARMINGTON DR SE',
        RegAddrCity: 'ATLANTA',
        RegAddrState: 'GA',
        RegAddrZip: 30339,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '417 SUMMER TERRACE LN',
        RegAddrCity: 'ATLANTA',
        RegAddrState: 'GA',
        RegAddrZip: 30342,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '606 FOREST PINE DR',
        RegAddrCity: 'BALL GROUND',
        RegAddrState: 'GA',
        RegAddrZip: 30107,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '146 LEGEND CREEK DR',
        RegAddrCity: 'CANTON',
        RegAddrState: 'GA',
        RegAddrZip: 30114,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '355 WOODRIDGE PASS',
        RegAddrCity: 'CANTON',
        RegAddrState: 'GA',
        RegAddrZip: 30114,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '222 WOODLAND WAY',
        RegAddrCity: 'CANTON',
        RegAddrState: 'GA',
        RegAddrZip: 30114,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '260 CARMEL RIDGE RD',
        RegAddrCity: 'CANTON',
        RegAddrState: 'GA',
        RegAddrZip: 30114,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '460 DOUGLAS RIDGE LN',
        RegAddrCity: 'CANTON',
        RegAddrState: 'GA',
        RegAddrZip: 30114,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '149 CLIFFORD CT',
        RegAddrCity: 'CANTON',
        RegAddrState: 'GA',
        RegAddrZip: 30115,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '1129 CHESTERWICK TRCE',
        RegAddrCity: 'CANTON',
        RegAddrState: 'GA',
        RegAddrZip: 30115,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '127B W FELTON RD',
        RegAddrCity: 'CARTERSVILLE',
        RegAddrState: 'GA',
        RegAddrZip: 30120,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '3 DEVON CT',
        RegAddrCity: 'CARTERSVILLE',
        RegAddrState: 'GA',
        RegAddrZip: 30120,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '749 SUGAR VALLEY RD SW',
        RegAddrCity: 'CARTERSVILLE',
        RegAddrState: 'GA',
        RegAddrZip: 30120,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '1162 MISSION RD SW',
        RegAddrCity: 'CARTERSVILLE',
        RegAddrState: 'GA',
        RegAddrZip: 30120,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '464 CEDAR CREEK RD NW',
        RegAddrCity: 'CARTERSVILLE',
        RegAddrState: 'GA',
        RegAddrZip: 30121,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '34 RUDY YORK RD NW',
        RegAddrCity: 'CARTERSVILLE',
        RegAddrState: 'GA',
        RegAddrZip: 30121,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '62 WILLOW BEND DR NW',
        RegAddrCity: 'CARTERSVILLE',
        RegAddrState: 'GA',
        RegAddrZip: 30121,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '113 WALTON GREEN WAY NW',
        RegAddrCity: 'KENNESAW',
        RegAddrState: 'GA',
        RegAddrZip: 30144,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '1612 HEMBURG TRCE NW',
        RegAddrCity: 'KENNESAW',
        RegAddrState: 'GA',
        RegAddrZip: 30144,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '968 THOUSAND OAKS BND NW',
        RegAddrCity: 'KENNESAW',
        RegAddrState: 'GA',
        RegAddrZip: 30152,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '1776 MOUNTAIN OAK RD NW',
        RegAddrCity: 'KENNESAW',
        RegAddrState: 'GA',
        RegAddrZip: 30152,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '3408 OWENS LANDING DR NW',
        RegAddrCity: 'KENNESAW',
        RegAddrState: 'GA',
        RegAddrZip: 30152,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '3427 SAINT JAMES CT NW',
        RegAddrCity: 'KENNESAW',
        RegAddrState: 'GA',
        RegAddrZip: 30152,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '1208 EVERWOOD DR SW',
        RegAddrCity: 'MARIETTA',
        RegAddrState: 'GA',
        RegAddrZip: 30008,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '539 PICKETTS BEND CIR',
        RegAddrCity: 'MARIETTA',
        RegAddrState: 'GA',
        RegAddrZip: 30008,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '2362 BEVERLY LN SW',
        RegAddrCity: 'MARIETTA',
        RegAddrState: 'GA',
        RegAddrZip: 30060,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '875 BALTIMORE PL SW',
        RegAddrCity: 'MARIETTA',
        RegAddrState: 'GA',
        RegAddrZip: 30064,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '329 WESTLAND WAY SW',
        RegAddrCity: 'MARIETTA',
        RegAddrState: 'GA',
        RegAddrZip: 30064,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '257 BEDFORD ST SW',
        RegAddrCity: 'MARIETTA',
        RegAddrState: 'GA',
        RegAddrZip: 30064,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '1349 ECHO MILL CT',
        RegAddrCity: 'POWDER SPGS',
        RegAddrState: 'GA',
        RegAddrZip: 30127,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '6040 CRABAPPLE WAY',
        RegAddrCity: 'POWDER SPRINGS',
        RegAddrState: 'GA',
        RegAddrZip: 30127,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '304 GALLERIA LN SE',
        RegAddrCity: 'SMYRNA',
        RegAddrState: 'GA',
        RegAddrZip: 30080,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '2798 MORRIS CIR SE',
        RegAddrCity: 'SMYRNA',
        RegAddrState: 'GA',
        RegAddrZip: 30080,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '142 WALNUT CT',
        RegAddrCity: 'WALESKA',
        RegAddrState: 'GA',
        RegAddrZip: 30183,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '717 OSCO PKWY',
        RegAddrCity: 'WOODSTOCK',
        RegAddrState: 'GA',
        RegAddrZip: 30188,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '231 HIGHLAND VILLAGE LN',
        RegAddrCity: 'WOODSTOCK',
        RegAddrState: 'GA',
        RegAddrZip: 30188,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '310 LADY SLIPPER LN',
        RegAddrCity: 'WOODSTOCK',
        RegAddrState: 'GA',
        RegAddrZip: 30188,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '817 SOCIETY CT',
        RegAddrCity: 'WOODSTOCK',
        RegAddrState: 'GA',
        RegAddrZip: 30188,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '559 FAIRWAY DR',
        RegAddrCity: 'WOODSTOCK',
        RegAddrState: 'GA',
        RegAddrZip: 30189,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '4040 WATKINS GLEN DR',
        RegAddrCity: 'WOODSTOCK',
        RegAddrState: 'GA',
        RegAddrZip: 30189,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '149 WALLNUT HALL CIR',
        RegAddrCity: 'WOODSTOCK',
        RegAddrState: 'GA',
        RegAddrZip: 30189,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '325 IRONHILL TRCE',
        RegAddrCity: 'WOODSTOCK',
        RegAddrState: 'GA',
        RegAddrZip: 30189,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '1126 BRITLEY PARK LN',
        RegAddrCity: 'WOODSTOCK',
        RegAddrState: 'GA',
        RegAddrZip: 30189,
        CongressionalDistrict: 11,
      },
    ],
  },
  {
    name: 'GA-12',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/GA-12?ref=gh-pages',
    state: 'GA',
    state_and_number: 'GA-12',
    zip: 30401,
    congressionalDistrict: 12,
    addressesInDistrict: [
      {
        RegAddrLine1: '6407 COLUMBIA RD',
        RegAddrCity: 'APPLING',
        RegAddrState: 'GA',
        RegAddrZip: 30802,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '1323 CHERRY AVE',
        RegAddrCity: 'AUGUSTA',
        RegAddrState: 'GA',
        RegAddrZip: 30901,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '1126 8TH AVE',
        RegAddrCity: 'AUGUSTA',
        RegAddrState: 'GA',
        RegAddrZip: 30901,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '1920 KINGS GRANT DR',
        RegAddrCity: 'AUGUSTA',
        RegAddrState: 'GA',
        RegAddrZip: 30906,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '2117 HAROLD RD',
        RegAddrCity: 'AUGUSTA',
        RegAddrState: 'GA',
        RegAddrZip: 30906,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '3404 STONEY BROOK RD',
        RegAddrCity: 'AUGUSTA',
        RegAddrState: 'GA',
        RegAddrZip: 30906,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '3207 BLUFF CT',
        RegAddrCity: 'AUGUSTA',
        RegAddrState: 'GA',
        RegAddrZip: 30907,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '1101 BROOKSTONE WAY',
        RegAddrCity: 'AUGUSTA',
        RegAddrState: 'GA',
        RegAddrZip: 30909,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '759 MCCLURE DR',
        RegAddrCity: 'AUGUSTA',
        RegAddrState: 'GA',
        RegAddrZip: 30909,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '2787 HUNTCLIFFE DR',
        RegAddrCity: 'AUGUSTA',
        RegAddrState: 'GA',
        RegAddrZip: 30909,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '6025 SILVER BIRCH PL',
        RegAddrCity: 'AUGUSTA',
        RegAddrState: 'GA',
        RegAddrZip: 30909,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '2667 ALEXANDER PL',
        RegAddrCity: 'AUGUSTA',
        RegAddrState: 'GA',
        RegAddrZip: 30909,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '3118 LEAFLET WAY',
        RegAddrCity: 'AUGUSTA',
        RegAddrState: 'GA',
        RegAddrZip: 30909,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '95 BOY SCOUT CT',
        RegAddrCity: 'AUGUSTA',
        RegAddrState: 'GA',
        RegAddrZip: 30909,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '292 OAK RIDGE RD',
        RegAddrCity: 'BAXLEY',
        RegAddrState: 'GA',
        RegAddrZip: 31513,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '408 CLAY CIR',
        RegAddrCity: 'BAXLEY',
        RegAddrState: 'GA',
        RegAddrZip: 31513,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '2212 NEVILS DENMARK RD',
        RegAddrCity: 'BROOKLET',
        RegAddrState: 'GA',
        RegAddrZip: 30415,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '90 GREENBRIAR CIR',
        RegAddrCity: 'CLAXTON',
        RegAddrState: 'GA',
        RegAddrZip: 30417,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '906 SELLERS ST E',
        RegAddrCity: 'DOUGLAS',
        RegAddrState: 'GA',
        RegAddrZip: 31533,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '1510 CYPRESS LN',
        RegAddrCity: 'DOUGLAS',
        RegAddrState: 'GA',
        RegAddrZip: 31533,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '120 PARK PL',
        RegAddrCity: 'DUBLIN',
        RegAddrState: 'GA',
        RegAddrZip: 31021,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '1150 HOGAN GROVE CHURCH RD',
        RegAddrCity: 'DUBLIN',
        RegAddrState: 'GA',
        RegAddrZip: 31021,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '1001 RUTLAND DR',
        RegAddrCity: 'DUBLIN',
        RegAddrState: 'GA',
        RegAddrZip: 31021,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '1309 RALPH KEEN RD',
        RegAddrCity: 'DUBLIN',
        RegAddrState: 'GA',
        RegAddrZip: 31027,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '403 BROAD ST',
        RegAddrCity: 'EAST DUBLIN',
        RegAddrState: 'GA',
        RegAddrZip: 31027,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '721 HARRISON DR',
        RegAddrCity: 'EVANS',
        RegAddrState: 'GA',
        RegAddrZip: 30809,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '860 LEYLAND LN',
        RegAddrCity: 'EVANS',
        RegAddrState: 'GA',
        RegAddrZip: 30809,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '1121 HUNTERS CV',
        RegAddrCity: 'EVANS',
        RegAddrState: 'GA',
        RegAddrZip: 30809,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '10710 GA HIGHWAY 121',
        RegAddrCity: 'GARFIELD',
        RegAddrState: 'GA',
        RegAddrZip: 30425,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '365 AREA RD',
        RegAddrCity: 'GLENNVILLE',
        RegAddrState: 'GA',
        RegAddrZip: 30427,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '284 FLOYD BLAND RD',
        RegAddrCity: 'GLENNVILLE',
        RegAddrState: 'GA',
        RegAddrZip: 30427,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '3040 BARNARD RD',
        RegAddrCity: 'GLENNVILLE',
        RegAddrState: 'GA',
        RegAddrZip: 30427,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '226 N HIGHWAY 19',
        RegAddrCity: 'GLENWOOD',
        RegAddrState: 'GA',
        RegAddrZip: 30428,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '6006 REYNOLDS CIR',
        RegAddrCity: 'GROVETOWN',
        RegAddrState: 'GA',
        RegAddrZip: 30813,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '501 WENDELL CT',
        RegAddrCity: 'GROVETOWN',
        RegAddrState: 'GA',
        RegAddrZip: 30813,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '416 RIPSAW CT',
        RegAddrCity: 'GROVETOWN',
        RegAddrState: 'GA',
        RegAddrZip: 30813,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '102 BENICIA LN',
        RegAddrCity: 'GUYTON',
        RegAddrState: 'GA',
        RegAddrZip: 31312,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '398 GARY RD',
        RegAddrCity: 'HEPHZIBAH',
        RegAddrState: 'GA',
        RegAddrZip: 30815,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '5036 DEER TRAIL DR',
        RegAddrCity: 'HEPHZIBAH',
        RegAddrState: 'GA',
        RegAddrZip: 30815,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '3972 WILLOWOOD RD',
        RegAddrCity: 'MARTINEZ',
        RegAddrState: 'GA',
        RegAddrZip: 30907,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '3829 FOREST CREEK WAY',
        RegAddrCity: 'MARTINEZ',
        RegAddrState: 'GA',
        RegAddrZip: 30907,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '3572 GRANITE WAY',
        RegAddrCity: 'MARTINEZ',
        RegAddrState: 'GA',
        RegAddrZip: 30907,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '123 LENOX DR',
        RegAddrCity: 'MARTINEZ',
        RegAddrState: 'GA',
        RegAddrZip: 30907,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '1252 MCGARRH MILL POND RD',
        RegAddrCity: 'MIDVILLE',
        RegAddrState: 'GA',
        RegAddrZip: 30441,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '106 SPIELMANN RD',
        RegAddrCity: 'PEMBROKE',
        RegAddrState: 'GA',
        RegAddrZip: 31321,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '929 2ND ST',
        RegAddrCity: 'SARDIS',
        RegAddrState: 'GA',
        RegAddrZip: 30456,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '408 HUMMINGBIRD LN',
        RegAddrCity: 'SPRINGFIELD',
        RegAddrState: 'GA',
        RegAddrZip: 31329,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '253 TAVERN LN',
        RegAddrCity: 'STATESBORO',
        RegAddrState: 'GA',
        RegAddrZip: 30458,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '122 WHITEHILL RD',
        RegAddrCity: 'SYLVANIA',
        RegAddrState: 'GA',
        RegAddrZip: 30467,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '78 PELICAN RD',
        RegAddrCity: 'TWIN CITY',
        RegAddrState: 'GA',
        RegAddrZip: 30471,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '4835 CEDAR CROSSING VIDALIA RD',
        RegAddrCity: 'UVALDA',
        RegAddrState: 'GA',
        RegAddrZip: 30473,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '1706 KNOLLWOOD ST',
        RegAddrCity: 'VIDALIA',
        RegAddrState: 'GA',
        RegAddrZip: 30474,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '4051 RIVER RD',
        RegAddrCity: 'WAYNESBORO',
        RegAddrState: 'GA',
        RegAddrZip: 30830,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '152 ZERBEE LN',
        RegAddrCity: 'WAYNESBORO',
        RegAddrState: 'GA',
        RegAddrZip: 30830,
        CongressionalDistrict: 12,
      },
    ],
  },
  {
    name: 'GA-13',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/GA-13?ref=gh-pages',
    state: 'GA',
    state_and_number: 'GA-13',
    zip: 30106,
    congressionalDistrict: 13,
    addressesInDistrict: [
      {
        RegAddrLine1: '205 GUNNISON PL SW',
        RegAddrCity: 'ATLANTA',
        RegAddrState: 'GA',
        RegAddrZip: 30331,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '3909 LAKE MANOR WAY',
        RegAddrCity: 'ATLANTA',
        RegAddrState: 'GA',
        RegAddrZip: 30349,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '4057 BRANTLEY DR',
        RegAddrCity: 'AUSTELL',
        RegAddrState: 'GA',
        RegAddrZip: 30106,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '2942 HUMPHRIES HILL RD',
        RegAddrCity: 'AUSTELL',
        RegAddrState: 'GA',
        RegAddrZip: 30106,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '283 FLAGSTONE WAY',
        RegAddrCity: 'AUSTELL',
        RegAddrState: 'GA',
        RegAddrZip: 30168,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '144 SILVER MINE TRL',
        RegAddrCity: 'AUSTELL',
        RegAddrState: 'GA',
        RegAddrZip: 30168,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '3441 AUGUSTA ST',
        RegAddrCity: 'COLLEGE PARK',
        RegAddrState: 'GA',
        RegAddrZip: 30349,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '929 ESTES DR',
        RegAddrCity: 'COLLEGE PARK',
        RegAddrState: 'GA',
        RegAddrZip: 30349,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '6780 JOHN CLARK DR',
        RegAddrCity: 'DOUGLASVILLE',
        RegAddrState: 'GA',
        RegAddrZip: 30134,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '5148 LAKECOVE CT',
        RegAddrCity: 'DOUGLASVILLE',
        RegAddrState: 'GA',
        RegAddrZip: 30135,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '3248 BLACKLEY OLD RD',
        RegAddrCity: 'DOUGLASVILLE',
        RegAddrState: 'GA',
        RegAddrZip: 30135,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '4551 JENKINS WAY',
        RegAddrCity: 'DOUGLASVILLE',
        RegAddrState: 'GA',
        RegAddrZip: 30135,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '4400 SPLIT CREEK DR',
        RegAddrCity: 'DOUGLASVILLE',
        RegAddrState: 'GA',
        RegAddrZip: 30135,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '4910 SPANISH OAK RD',
        RegAddrCity: 'DOUGLASVILLE',
        RegAddrState: 'GA',
        RegAddrZip: 30135,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '238 SAUL DR',
        RegAddrCity: 'ELLENWOOD',
        RegAddrState: 'GA',
        RegAddrZip: 30294,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '5534 VALLEY LOOP',
        RegAddrCity: 'FAIRBURN',
        RegAddrState: 'GA',
        RegAddrZip: 30213,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '1065 AVALON TER',
        RegAddrCity: 'FAIRBURN',
        RegAddrState: 'GA',
        RegAddrZip: 30213,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '135 CEDAR CIR',
        RegAddrCity: 'FAYETTEVILLE',
        RegAddrState: 'GA',
        RegAddrZip: 30214,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '290 NAVARRE DR',
        RegAddrCity: 'FAYETTEVILLE',
        RegAddrState: 'GA',
        RegAddrZip: 30214,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '425 BENJAMIN CIR',
        RegAddrCity: 'FAYETTEVILLE',
        RegAddrState: 'GA',
        RegAddrZip: 30214,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '11922 FULLER ST',
        RegAddrCity: 'HAMPTON',
        RegAddrState: 'GA',
        RegAddrZip: 30228,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '11195 KNOTTY PINE PL',
        RegAddrCity: 'HAMPTON',
        RegAddrState: 'GA',
        RegAddrZip: 30228,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '146 CARL PARKER RD',
        RegAddrCity: 'HAMPTON',
        RegAddrState: 'GA',
        RegAddrZip: 30228,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '80 PATES LAKE DR',
        RegAddrCity: 'HAMPTON',
        RegAddrState: 'GA',
        RegAddrZip: 30228,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '305 BEAR CREEK TRL',
        RegAddrCity: 'HAMPTON',
        RegAddrState: 'GA',
        RegAddrZip: 30228,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '780 N STEELE DR',
        RegAddrCity: 'HAMPTON',
        RegAddrState: 'GA',
        RegAddrZip: 30228,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '8198 TUPELO TRL',
        RegAddrCity: 'JONESBORO',
        RegAddrState: 'GA',
        RegAddrZip: 30236,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '641 BRAMBLE CT',
        RegAddrCity: 'JONESBORO',
        RegAddrState: 'GA',
        RegAddrZip: 30238,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '9512 WHALEYS LAKE TRCE',
        RegAddrCity: 'JONESBORO',
        RegAddrState: 'GA',
        RegAddrZip: 30238,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '1494 INTERLAKEN PASS',
        RegAddrCity: 'JONESBORO',
        RegAddrState: 'GA',
        RegAddrZip: 30238,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '646 WASHINGTON DR',
        RegAddrCity: 'JONESBORO',
        RegAddrState: 'GA',
        RegAddrZip: 30238,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '9273 JORDAN MILL TRL',
        RegAddrCity: 'JONESBORO',
        RegAddrState: 'GA',
        RegAddrZip: 30238,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '10796 MALLARD DR',
        RegAddrCity: 'JONESBORO',
        RegAddrState: 'GA',
        RegAddrZip: 30238,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '715 PRESTON LANDING CIR',
        RegAddrCity: 'LITHIA SPGS',
        RegAddrState: 'GA',
        RegAddrZip: 30122,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '183 WANA CIR SE',
        RegAddrCity: 'MABLETON',
        RegAddrState: 'GA',
        RegAddrZip: 30126,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '704 KING SWORD CT SE',
        RegAddrCity: 'MABLETON',
        RegAddrState: 'GA',
        RegAddrZip: 30126,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '29 DUNAIRE CT SW',
        RegAddrCity: 'MABLETON',
        RegAddrState: 'GA',
        RegAddrZip: 30126,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '2970 MILFORD TRL SW',
        RegAddrCity: 'MARIETTA',
        RegAddrState: 'GA',
        RegAddrZip: 30008,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '2474 WOOD MEADOWS DR SW',
        RegAddrCity: 'MARIETTA',
        RegAddrState: 'GA',
        RegAddrZip: 30064,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '3018 FETLOCK DR SW',
        RegAddrCity: 'MARIETTA',
        RegAddrState: 'GA',
        RegAddrZip: 30064,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '1861 GROVE FIELD LN',
        RegAddrCity: 'MARIETTA',
        RegAddrState: 'GA',
        RegAddrZip: 30064,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '205 PLEASANT ST',
        RegAddrCity: 'PALMETTO',
        RegAddrState: 'GA',
        RegAddrZip: 30268,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '716 CARLTON POINTE WAY',
        RegAddrCity: 'PALMETTO',
        RegAddrState: 'GA',
        RegAddrZip: 30268,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '4819 WINDING LN',
        RegAddrCity: 'POWDER SPGS',
        RegAddrState: 'GA',
        RegAddrZip: 30127,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '3719 LOCKERBIE LN',
        RegAddrCity: 'POWDER SPGS',
        RegAddrState: 'GA',
        RegAddrZip: 30127,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '3477 HOLLOW STREAM TRL',
        RegAddrCity: 'POWDER SPGS',
        RegAddrState: 'GA',
        RegAddrZip: 30127,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '3233 VALLEY VIEW ST',
        RegAddrCity: 'POWDER SPGS',
        RegAddrState: 'GA',
        RegAddrZip: 30127,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '3390 MUSTANG DR',
        RegAddrCity: 'POWDER SPGS',
        RegAddrState: 'GA',
        RegAddrZip: 30127,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '2400 WORTHINGTON DR',
        RegAddrCity: 'POWDER SPRINGS',
        RegAddrState: 'GA',
        RegAddrZip: 30127,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '7364 WHITE CASTLE CT',
        RegAddrCity: 'RIVERDALE',
        RegAddrState: 'GA',
        RegAddrZip: 30274,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '7598 SUMMIT TRL',
        RegAddrCity: 'RIVERDALE',
        RegAddrState: 'GA',
        RegAddrZip: 30274,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '521 PINECREST DR',
        RegAddrCity: 'RIVERDALE',
        RegAddrState: 'GA',
        RegAddrZip: 30274,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '6390 FOX CHASE CT',
        RegAddrCity: 'RIVERDALE',
        RegAddrState: 'GA',
        RegAddrZip: 30296,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '1144 BERKLEY DR SE',
        RegAddrCity: 'SMYRNA',
        RegAddrState: 'GA',
        RegAddrZip: 30082,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '157 SORROW RD',
        RegAddrCity: 'STOCKBRIDGE',
        RegAddrState: 'GA',
        RegAddrZip: 30281,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '505 KETTLETOFT CT',
        RegAddrCity: 'STOCKBRIDGE',
        RegAddrState: 'GA',
        RegAddrZip: 30281,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '20 COOK LN',
        RegAddrCity: 'STOCKBRIDGE',
        RegAddrState: 'GA',
        RegAddrZip: 30281,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '465 AZALEA DR',
        RegAddrCity: 'STOCKBRIDGE',
        RegAddrState: 'GA',
        RegAddrZip: 30281,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '35 SCOTT BLVD',
        RegAddrCity: 'STOCKBRIDGE',
        RegAddrState: 'GA',
        RegAddrZip: 30281,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '702 SHARPSTONE BND',
        RegAddrCity: 'STOCKBRIDGE',
        RegAddrState: 'GA',
        RegAddrZip: 30281,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '1016 S CREEK DR',
        RegAddrCity: 'VILLA RICA',
        RegAddrState: 'GA',
        RegAddrZip: 30180,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '2079 DARTMOTH WAY',
        RegAddrCity: 'VILLA RICA',
        RegAddrState: 'GA',
        RegAddrZip: 30180,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '8780 EPHESUS CHURCH RD',
        RegAddrCity: 'VILLA RICA',
        RegAddrState: 'GA',
        RegAddrZip: 30180,
        CongressionalDistrict: 13,
      },
    ],
  },
  {
    name: 'GA-14',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/GA-14?ref=gh-pages',
    state: 'GA',
    state_and_number: 'GA-14',
    zip: 30105,
    congressionalDistrict: 14,
    addressesInDistrict: [
      {
        RegAddrLine1: '310 VINE CREEK DR',
        RegAddrCity: 'ACWORTH',
        RegAddrState: 'GA',
        RegAddrZip: 30101,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '217 THORNWOOD LN',
        RegAddrCity: 'ACWORTH',
        RegAddrState: 'GA',
        RegAddrZip: 30101,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '195 APPLEWOOD LN',
        RegAddrCity: 'ACWORTH',
        RegAddrState: 'GA',
        RegAddrZip: 30101,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '147 PLEMONS RD SE',
        RegAddrCity: 'ARAGON',
        RegAddrState: 'GA',
        RegAddrZip: 30104,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '1569 PROSPECT RD',
        RegAddrCity: 'ARAGON',
        RegAddrState: 'GA',
        RegAddrZip: 30104,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '280 FARMINGTON DR SE',
        RegAddrCity: 'CALHOUN',
        RegAddrState: 'GA',
        RegAddrZip: 30701,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '1743 DAVIS RD SW',
        RegAddrCity: 'CAVE SPRING',
        RegAddrState: 'GA',
        RegAddrZip: 30124,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '1510 BUTTERMILK RD SW',
        RegAddrCity: 'CAVE SPRING',
        RegAddrState: 'GA',
        RegAddrZip: 30124,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '843 MULLEN RD',
        RegAddrCity: 'CEDARTOWN',
        RegAddrState: 'GA',
        RegAddrZip: 30125,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '840 HUTTO RD',
        RegAddrCity: 'CEDARTOWN',
        RegAddrState: 'GA',
        RegAddrZip: 30125,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '1613 BROWN BRIDGE RD',
        RegAddrCity: 'CHATSWORTH',
        RegAddrState: 'GA',
        RegAddrZip: 30705,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '1030 SMYRNA CHURCH RD',
        RegAddrCity: 'CHATSWORTH',
        RegAddrState: 'GA',
        RegAddrZip: 30705,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '86 SALES AVE',
        RegAddrCity: 'CHATSWORTH',
        RegAddrState: 'GA',
        RegAddrZip: 30705,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '946 W COVE RD',
        RegAddrCity: 'CHICKAMAUGA',
        RegAddrState: 'GA',
        RegAddrZip: 30707,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '442 HIGH POINT DR',
        RegAddrCity: 'CHICKAMAUGA',
        RegAddrState: 'GA',
        RegAddrZip: 30707,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '4222 KEITH VALLEY RD',
        RegAddrCity: 'COHUTTA',
        RegAddrState: 'GA',
        RegAddrZip: 30710,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '392 PALISADE WAY',
        RegAddrCity: 'COHUTTA',
        RegAddrState: 'GA',
        RegAddrZip: 30710,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '71 IVY GROVE WAY',
        RegAddrCity: 'DALLAS',
        RegAddrState: 'GA',
        RegAddrZip: 30132,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '224 BRIGHTWATER DR',
        RegAddrCity: 'DALLAS',
        RegAddrState: 'GA',
        RegAddrZip: 30157,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '108 W BRIDGE DR',
        RegAddrCity: 'DALLAS',
        RegAddrState: 'GA',
        RegAddrZip: 30157,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '1924 BARTENFIELD DR',
        RegAddrCity: 'DALTON',
        RegAddrState: 'GA',
        RegAddrZip: 30721,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '3571 CHATSWORTH RD SE',
        RegAddrCity: 'DALTON',
        RegAddrState: 'GA',
        RegAddrZip: 30721,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '425 CEDAR RIDGE RD SE',
        RegAddrCity: 'DALTON',
        RegAddrState: 'GA',
        RegAddrZip: 30721,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '128 SPORTING WAY',
        RegAddrCity: 'DALTON',
        RegAddrState: 'GA',
        RegAddrZip: 30721,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '2322 WARING RD NW',
        RegAddrCity: 'DALTON',
        RegAddrState: 'GA',
        RegAddrZip: 30721,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '1906 RIDGE RD SE',
        RegAddrCity: 'DALTON',
        RegAddrState: 'GA',
        RegAddrZip: 30721,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '4850 E NANCE SPRINGS RD SE',
        RegAddrCity: 'DALTON',
        RegAddrState: 'GA',
        RegAddrZip: 30721,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '167 WATERBURY WAY',
        RegAddrCity: 'DOUGLASVILLE',
        RegAddrState: 'GA',
        RegAddrZip: 30134,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '451 GARRETTS DR',
        RegAddrCity: 'DOUGLASVILLE',
        RegAddrState: 'GA',
        RegAddrZip: 30134,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '223 RIDGEFIELD DR',
        RegAddrCity: 'DOUGLASVILLE',
        RegAddrState: 'GA',
        RegAddrZip: 30134,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '1126 IRWIN MILL RD SE',
        RegAddrCity: 'FAIRMOUNT',
        RegAddrState: 'GA',
        RegAddrZip: 30139,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '51 COTTON MILL DR',
        RegAddrCity: 'HIRAM',
        RegAddrState: 'GA',
        RegAddrZip: 30141,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '247 HARPERS LAKE DR',
        RegAddrCity: 'HIRAM',
        RegAddrState: 'GA',
        RegAddrZip: 30141,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '45 BOLLEN LN',
        RegAddrCity: 'HIRAM',
        RegAddrState: 'GA',
        RegAddrZip: 30141,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '3488 JERUSALEM CHURCH RD',
        RegAddrCity: 'JASPER',
        RegAddrState: 'GA',
        RegAddrZip: 30143,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '6408 HIGHWAY 53 W',
        RegAddrCity: 'JASPER',
        RegAddrState: 'GA',
        RegAddrZip: 30143,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '391 GENTRY RD',
        RegAddrCity: 'KINGSTON',
        RegAddrState: 'GA',
        RegAddrZip: 30145,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '104 HENDRIX ST',
        RegAddrCity: 'LA FAYETTE',
        RegAddrState: 'GA',
        RegAddrZip: 30728,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '82 HICKORY LN',
        RegAddrCity: 'LOOKOUT MTN',
        RegAddrState: 'GA',
        RegAddrZip: 30750,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '1727 NICKLESVILLE RD NE',
        RegAddrCity: 'RESACA',
        RegAddrState: 'GA',
        RegAddrZip: 30735,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '789 CASTLEVIEW DR',
        RegAddrCity: 'RINGGOLD',
        RegAddrState: 'GA',
        RegAddrZip: 30736,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '545 LIVE OAK RD',
        RegAddrCity: 'RINGGOLD',
        RegAddrState: 'GA',
        RegAddrZip: 30736,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '46 NORTH ST',
        RegAddrCity: 'RINGGOLD',
        RegAddrState: 'GA',
        RegAddrZip: 30736,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '225 LOOKOUT DR',
        RegAddrCity: 'RISING FAWN',
        RegAddrState: 'GA',
        RegAddrZip: 30738,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '723 WOOD STATION RD',
        RegAddrCity: 'ROCK SPRING',
        RegAddrState: 'GA',
        RegAddrZip: 30739,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '79 RED FOX TRL',
        RegAddrCity: 'ROCK SPRING',
        RegAddrState: 'GA',
        RegAddrZip: 30739,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '1103 CARTERSVILLE HWY',
        RegAddrCity: 'ROCKMART',
        RegAddrState: 'GA',
        RegAddrZip: 30153,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '127 BLUE MOUNTAIN PKWY',
        RegAddrCity: 'ROCKY FACE',
        RegAddrState: 'GA',
        RegAddrZip: 30740,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '7 GREENBRIAR LN SE',
        RegAddrCity: 'ROME',
        RegAddrState: 'GA',
        RegAddrZip: 30161,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '10 VALGREEN LN NE',
        RegAddrCity: 'ROME',
        RegAddrState: 'GA',
        RegAddrZip: 30161,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '108 W 6TH AVE',
        RegAddrCity: 'ROME',
        RegAddrState: 'GA',
        RegAddrZip: 30161,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '8 TALL PINES TER NW',
        RegAddrCity: 'ROME',
        RegAddrState: 'GA',
        RegAddrZip: 30165,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '219 IRON ST NW',
        RegAddrCity: 'ROME',
        RegAddrState: 'GA',
        RegAddrZip: 30165,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '701 CHARLTON ST NW',
        RegAddrCity: 'ROME',
        RegAddrState: 'GA',
        RegAddrZip: 30165,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '854 TEXAS VALLEY RD NW',
        RegAddrCity: 'ROME',
        RegAddrState: 'GA',
        RegAddrZip: 30165,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '124B BABY DOE DR NW',
        RegAddrCity: 'ROME',
        RegAddrState: 'GA',
        RegAddrZip: 30165,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '17 LONGSTREET RD',
        RegAddrCity: 'ROSSVILLE',
        RegAddrState: 'GA',
        RegAddrZip: 30741,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '48 SADDLE WAY',
        RegAddrCity: 'TALLAPOOSA',
        RegAddrState: 'GA',
        RegAddrZip: 30176,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '454 PINEY RD',
        RegAddrCity: 'TRENTON',
        RegAddrState: 'GA',
        RegAddrZip: 30752,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '172 PARK AVE',
        RegAddrCity: 'TRION',
        RegAddrState: 'GA',
        RegAddrZip: 30753,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '949 MORGAN DR',
        RegAddrCity: 'TUNNEL HILL',
        RegAddrState: 'GA',
        RegAddrZip: 30755,
        CongressionalDistrict: 14,
      },
    ],
  },
  {
    name: 'GA-2',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/GA-2?ref=gh-pages',
    state: 'GA',
    state_and_number: 'GA-2',
    zip: 31006,
    congressionalDistrict: 2,
    addressesInDistrict: [
      {
        RegAddrLine1: '705 MEADOWLARK DR',
        RegAddrCity: 'ALBANY',
        RegAddrState: 'GA',
        RegAddrZip: 31707,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '401 FLORENCE DR',
        RegAddrCity: 'ALBANY',
        RegAddrState: 'GA',
        RegAddrZip: 31707,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '3707 CASTLE PINES LN',
        RegAddrCity: 'ALBANY',
        RegAddrState: 'GA',
        RegAddrZip: 31721,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '301 S HAMPTON ST',
        RegAddrCity: 'AMERICUS',
        RegAddrState: 'GA',
        RegAddrZip: 31709,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1426 S LEE ST',
        RegAddrCity: 'AMERICUS',
        RegAddrState: 'GA',
        RegAddrZip: 31709,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '414 BAINBRIDGE ST',
        RegAddrCity: 'BAINBRIDGE',
        RegAddrState: 'GA',
        RegAddrZip: 39819,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2950 FLAT CREEK RD',
        RegAddrCity: 'BLAKELY',
        RegAddrState: 'GA',
        RegAddrZip: 39823,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '176 BUFFALO RD',
        RegAddrCity: 'BOX SPRINGS',
        RegAddrState: 'GA',
        RegAddrZip: 31801,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '130 PINE PL',
        RegAddrCity: 'BUENA VISTA',
        RegAddrState: 'GA',
        RegAddrZip: 31803,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1811 GA HIGHWAY 137 W',
        RegAddrCity: 'BUENA VISTA',
        RegAddrState: 'GA',
        RegAddrZip: 31803,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '353 W SEMINOLE DR',
        RegAddrCity: 'BYRON',
        RegAddrState: 'GA',
        RegAddrZip: 31008,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '609 WALKER RD',
        RegAddrCity: 'BYRON',
        RegAddrState: 'GA',
        RegAddrZip: 31008,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1310 MCQUAIG ST NW',
        RegAddrCity: 'CAIRO',
        RegAddrState: 'GA',
        RegAddrZip: 39828,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '84 PALMER ST',
        RegAddrCity: 'CAMILLA',
        RegAddrState: 'GA',
        RegAddrZip: 31730,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1420 23RD ST',
        RegAddrCity: 'COLUMBUS',
        RegAddrState: 'GA',
        RegAddrZip: 31901,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2479 WISE ST',
        RegAddrCity: 'COLUMBUS',
        RegAddrState: 'GA',
        RegAddrZip: 31903,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '943 52ND ST',
        RegAddrCity: 'COLUMBUS',
        RegAddrState: 'GA',
        RegAddrZip: 31904,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '5577 EASTBROOK LN',
        RegAddrCity: 'COLUMBUS',
        RegAddrState: 'GA',
        RegAddrZip: 31907,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '957 BETHUNE CT',
        RegAddrCity: 'COLUMBUS',
        RegAddrState: 'GA',
        RegAddrZip: 31907,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1414 HUNT AVE',
        RegAddrCity: 'COLUMBUS',
        RegAddrState: 'GA',
        RegAddrZip: 31907,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '927 BETHUNE CT',
        RegAddrCity: 'COLUMBUS',
        RegAddrState: 'GA',
        RegAddrZip: 31907,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '5505 SOUTHLEA LN',
        RegAddrCity: 'COLUMBUS',
        RegAddrState: 'GA',
        RegAddrZip: 31909,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1615 GA HIGHWAY 257',
        RegAddrCity: 'CORDELE',
        RegAddrState: 'GA',
        RegAddrZip: 31015,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '608 E 15TH AVE',
        RegAddrCity: 'CORDELE',
        RegAddrState: 'GA',
        RegAddrZip: 31015,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '4563 GRAVES HWY',
        RegAddrCity: 'DAWSON',
        RegAddrState: 'GA',
        RegAddrZip: 39842,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1300 S NEWCOMBE AVE',
        RegAddrCity: 'DONALSONVILLE',
        RegAddrState: 'GA',
        RegAddrZip: 39845,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1970 CONNERS MILL RD',
        RegAddrCity: 'ELLAVILLE',
        RegAddrState: 'GA',
        RegAddrZip: 31806,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '99 SCHOFILL RD',
        RegAddrCity: 'FORT VALLEY',
        RegAddrState: 'GA',
        RegAddrZip: 31030,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '618 TANGLEWOOD RD',
        RegAddrCity: 'FORT VALLEY',
        RegAddrState: 'GA',
        RegAddrZip: 31030,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '615 IDELLA WILLIAMS RD',
        RegAddrCity: 'IDEAL',
        RegAddrState: 'GA',
        RegAddrZip: 31041,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '109 BROOKFAIR LN',
        RegAddrCity: 'LEESBURG',
        RegAddrState: 'GA',
        RegAddrZip: 31763,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '106 APACHE ST',
        RegAddrCity: 'LEESBURG',
        RegAddrState: 'GA',
        RegAddrZip: 31763,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '185 CHURCH ST',
        RegAddrCity: 'LILLY',
        RegAddrState: 'GA',
        RegAddrZip: 31051,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '4367 SUMMERHILL DR',
        RegAddrCity: 'MACON',
        RegAddrState: 'GA',
        RegAddrZip: 31204,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1779 SWAN DR',
        RegAddrCity: 'MACON',
        RegAddrState: 'GA',
        RegAddrZip: 31204,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '4149 SAN CARLOS DR',
        RegAddrCity: 'MACON',
        RegAddrState: 'GA',
        RegAddrZip: 31206,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '4604 CHAMBERS RD',
        RegAddrCity: 'MACON',
        RegAddrState: 'GA',
        RegAddrZip: 31206,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2580 NEW CLINTON RD',
        RegAddrCity: 'MACON',
        RegAddrState: 'GA',
        RegAddrZip: 31211,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '6080 TAYLOR DR',
        RegAddrCity: 'MACON',
        RegAddrState: 'GA',
        RegAddrZip: 31216,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '5678 HARTLEY BRIDGE RD',
        RegAddrCity: 'MACON',
        RegAddrState: 'GA',
        RegAddrZip: 31216,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '3948 JONES RD',
        RegAddrCity: 'MACON',
        RegAddrState: 'GA',
        RegAddrZip: 31216,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '3421 QUEENS DR',
        RegAddrCity: 'MACON',
        RegAddrState: 'GA',
        RegAddrZip: 31217,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '881 MORNINGSIDE DR',
        RegAddrCity: 'MACON',
        RegAddrState: 'GA',
        RegAddrZip: 31217,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '9220 COUNTY LINE RD',
        RegAddrCity: 'MIDLAND',
        RegAddrState: 'GA',
        RegAddrZip: 31820,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1031 OLD IDEAL RD',
        RegAddrCity: 'OGLETHORPE',
        RegAddrState: 'GA',
        RegAddrZip: 31068,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '426 WADE RD',
        RegAddrCity: 'PELHAM',
        RegAddrState: 'GA',
        RegAddrZip: 31779,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '259 HOWARD ST NW',
        RegAddrCity: 'PELHAM',
        RegAddrState: 'GA',
        RegAddrZip: 31779,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1255 ONEAL RD',
        RegAddrCity: 'TALBOTTON',
        RegAddrState: 'GA',
        RegAddrZip: 31827,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '802 N 3RD ST',
        RegAddrCity: 'VIENNA',
        RegAddrState: 'GA',
        RegAddrZip: 31092,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '433 N MCGRIFF ST',
        RegAddrCity: 'WHIGHAM',
        RegAddrState: 'GA',
        RegAddrZip: 39897,
        CongressionalDistrict: 2,
      },
    ],
  },
  {
    name: 'GA-3',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/GA-3?ref=gh-pages',
    state: 'GA',
    state_and_number: 'GA-3',
    zip: 30108,
    congressionalDistrict: 3,
    addressesInDistrict: [
      {
        RegAddrLine1: '109 KATE CT',
        RegAddrCity: 'BARNESVILLE',
        RegAddrState: 'GA',
        RegAddrZip: 30204,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '833 KENT RD',
        RegAddrCity: 'BOWDON',
        RegAddrState: 'GA',
        RegAddrZip: 30108,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '2657 OLD COLUMBUS RD',
        RegAddrCity: 'BOWDON',
        RegAddrState: 'GA',
        RegAddrZip: 30108,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '114 OPAL LN',
        RegAddrCity: 'CARROLLTON',
        RegAddrState: 'GA',
        RegAddrZip: 30116,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '200 TURNBERRY CIR',
        RegAddrCity: 'CARROLLTON',
        RegAddrState: 'GA',
        RegAddrZip: 30116,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '274 DOCK HYDE RD',
        RegAddrCity: 'CARROLLTON',
        RegAddrState: 'GA',
        RegAddrZip: 30116,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '285 ARMSTRONG RD',
        RegAddrCity: 'CARROLLTON',
        RegAddrState: 'GA',
        RegAddrZip: 30117,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1721 13TH AVE',
        RegAddrCity: 'COLUMBUS',
        RegAddrState: 'GA',
        RegAddrZip: 31901,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1312 AUTUMNLEAF CT',
        RegAddrCity: 'COLUMBUS',
        RegAddrState: 'GA',
        RegAddrZip: 31904,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '6133 HEARTHSTONE CT',
        RegAddrCity: 'COLUMBUS',
        RegAddrState: 'GA',
        RegAddrZip: 31909,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '7802 HIGHWAY 362',
        RegAddrCity: 'CONCORD',
        RegAddrState: 'GA',
        RegAddrZip: 30206,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '135 HAMPTON WOODS DR',
        RegAddrCity: 'FAYETTEVILLE',
        RegAddrState: 'GA',
        RegAddrZip: 30215,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '530 CHAPPELL RD',
        RegAddrCity: 'FAYETTEVILLE',
        RegAddrState: 'GA',
        RegAddrZip: 30215,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '670 VIRGINIA HIGHLANDS',
        RegAddrCity: 'FAYETTEVILLE',
        RegAddrState: 'GA',
        RegAddrZip: 30215,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '190 DANIEL DR',
        RegAddrCity: 'FORTSON',
        RegAddrState: 'GA',
        RegAddrZip: 31808,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '22460 ROOSEVELT HWY',
        RegAddrCity: 'GREENVILLE',
        RegAddrState: 'GA',
        RegAddrZip: 30222,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '5380 W MCINTOSH RD',
        RegAddrCity: 'GRIFFIN',
        RegAddrState: 'GA',
        RegAddrZip: 30223,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '101 CHESHIRE DR',
        RegAddrCity: 'GRIFFIN',
        RegAddrState: 'GA',
        RegAddrZip: 30223,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '861 REHOBOTH CHURCH RD',
        RegAddrCity: 'GRIFFIN',
        RegAddrState: 'GA',
        RegAddrZip: 30224,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '33 MOST HIGH RDG',
        RegAddrCity: 'HAMILTON',
        RegAddrState: 'GA',
        RegAddrZip: 31811,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '285 JOHNSON MILL RD',
        RegAddrCity: 'HAMILTON',
        RegAddrState: 'GA',
        RegAddrZip: 31811,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '101 HILLPINE TRL',
        RegAddrCity: 'HAMPTON',
        RegAddrState: 'GA',
        RegAddrZip: 30228,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '42 DEER POINTE CT',
        RegAddrCity: 'LAGRANGE',
        RegAddrState: 'GA',
        RegAddrZip: 30240,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '100 LEATHEA DR',
        RegAddrCity: 'LAGRANGE',
        RegAddrState: 'GA',
        RegAddrZip: 30241,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '101 COTTAGE CLUB DR',
        RegAddrCity: 'LOCUST GROVE',
        RegAddrState: 'GA',
        RegAddrZip: 30248,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '616 PEACH ST',
        RegAddrCity: 'MANCHESTER',
        RegAddrState: 'GA',
        RegAddrZip: 31816,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '2021 TUDOR DR',
        RegAddrCity: 'MCDONOUGH',
        RegAddrState: 'GA',
        RegAddrZip: 30253,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '4204 ENISA WAY',
        RegAddrCity: 'MCDONOUGH',
        RegAddrState: 'GA',
        RegAddrZip: 30253,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1106 HADDON PL',
        RegAddrCity: 'MCDONOUGH',
        RegAddrState: 'GA',
        RegAddrZip: 30253,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '282 HUNTERS CHASE',
        RegAddrCity: 'MCDONOUGH',
        RegAddrState: 'GA',
        RegAddrZip: 30253,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '450 BARKER RD',
        RegAddrCity: 'MOLENA',
        RegAddrState: 'GA',
        RegAddrZip: 30258,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '690 ALEX STEPHENS RD',
        RegAddrCity: 'MORELAND',
        RegAddrState: 'GA',
        RegAddrZip: 30259,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '60 RETREAT DR',
        RegAddrCity: 'NEWNAN',
        RegAddrState: 'GA',
        RegAddrZip: 30263,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '2275 OLD CORINTH RD',
        RegAddrCity: 'NEWNAN',
        RegAddrState: 'GA',
        RegAddrZip: 30263,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '168 WESTWIND DR',
        RegAddrCity: 'NEWNAN',
        RegAddrState: 'GA',
        RegAddrZip: 30263,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1128 ADCOCK RD',
        RegAddrCity: 'NEWNAN',
        RegAddrState: 'GA',
        RegAddrZip: 30263,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '10 WYNN RD',
        RegAddrCity: 'NEWNAN',
        RegAddrState: 'GA',
        RegAddrZip: 30263,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '2546 TOMMY LEE COOK RD',
        RegAddrCity: 'NEWNAN',
        RegAddrState: 'GA',
        RegAddrZip: 30263,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '248 HUDGEN RD',
        RegAddrCity: 'NEWNAN',
        RegAddrState: 'GA',
        RegAddrZip: 30265,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '68 WATERFORD PL',
        RegAddrCity: 'NEWNAN',
        RegAddrState: 'GA',
        RegAddrZip: 30265,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '26 BUCKTHORNE DR',
        RegAddrCity: 'NEWNAN',
        RegAddrState: 'GA',
        RegAddrZip: 30265,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '125 DAISY PL',
        RegAddrCity: 'NEWNAN',
        RegAddrState: 'GA',
        RegAddrZip: 30265,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '173 THOMAS CROSSING DR',
        RegAddrCity: 'NEWNAN',
        RegAddrState: 'GA',
        RegAddrZip: 30265,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '141 PALMETTO PINES RD',
        RegAddrCity: 'PALMETTO',
        RegAddrState: 'GA',
        RegAddrZip: 30268,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '114 ROLLING GRN',
        RegAddrCity: 'PEACHTREE CTY',
        RegAddrState: 'GA',
        RegAddrZip: 30269,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '112 SOUTHERN TRACE CT',
        RegAddrCity: 'PEACHTREE CTY',
        RegAddrState: 'GA',
        RegAddrZip: 30269,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '111 WOODSDALE DR',
        RegAddrCity: 'PEACHTREE CTY',
        RegAddrState: 'GA',
        RegAddrZip: 30269,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '115 WILLOW CREEK DR',
        RegAddrCity: 'PEACHTREE CTY',
        RegAddrState: 'GA',
        RegAddrZip: 30269,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '212 MELRAH HL',
        RegAddrCity: 'PEACHTREE CTY',
        RegAddrState: 'GA',
        RegAddrZip: 30269,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '6564 HOPEWELL CHURCH RD',
        RegAddrCity: 'PINE MOUNTAIN',
        RegAddrState: 'GA',
        RegAddrZip: 31822,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '40 PROVIDENCE CHURCH RD',
        RegAddrCity: 'ROOPVILLE',
        RegAddrState: 'GA',
        RegAddrZip: 30170,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '310 W MOORES CROSSING RD',
        RegAddrCity: 'THOMASTON',
        RegAddrState: 'GA',
        RegAddrZip: 30286,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '5357 MONTICELLO DR',
        RegAddrCity: 'VILLA RICA',
        RegAddrState: 'GA',
        RegAddrZip: 30180,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1294 N VAN WERT RD',
        RegAddrCity: 'VILLA RICA',
        RegAddrState: 'GA',
        RegAddrZip: 30180,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '4185 ARGONNE DR',
        RegAddrCity: 'VILLA RICA',
        RegAddrState: 'GA',
        RegAddrZip: 30180,
        CongressionalDistrict: 3,
      },
    ],
  },
  {
    name: 'GA-4',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/GA-4?ref=gh-pages',
    state: 'GA',
    state_and_number: 'GA-4',
    zip: 30002,
    congressionalDistrict: 4,
    addressesInDistrict: [
      {
        RegAddrLine1: '2261 FISHER TRL NE',
        RegAddrCity: 'ATLANTA',
        RegAddrState: 'GA',
        RegAddrZip: 30345,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '3516 ORCHARD ST',
        RegAddrCity: 'CLARKSTON',
        RegAddrState: 'GA',
        RegAddrZip: 30021,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1216 CEDAR CREEK CT NW',
        RegAddrCity: 'CONYERS',
        RegAddrState: 'GA',
        RegAddrZip: 30012,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '153 ELGIN LN SW',
        RegAddrCity: 'CONYERS',
        RegAddrState: 'GA',
        RegAddrZip: 30012,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '416 LOCH HAVEN DR SE',
        RegAddrCity: 'CONYERS',
        RegAddrState: 'GA',
        RegAddrZip: 30013,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '4805 CAMDEN DR SW',
        RegAddrCity: 'CONYERS',
        RegAddrState: 'GA',
        RegAddrZip: 30094,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2756 SOUTHPARK BLVD SW',
        RegAddrCity: 'CONYERS',
        RegAddrState: 'GA',
        RegAddrZip: 30094,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2809 CHIMNEY VIEW DR SW',
        RegAddrCity: 'CONYERS',
        RegAddrState: 'GA',
        RegAddrZip: 30094,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '910 SUGAR CREEK DR SE',
        RegAddrCity: 'CONYERS',
        RegAddrState: 'GA',
        RegAddrZip: 30094,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2930 LANDMARK DR SE',
        RegAddrCity: 'CONYERS',
        RegAddrState: 'GA',
        RegAddrZip: 30094,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '5201 PINECREST DR SW',
        RegAddrCity: 'COVINGTON',
        RegAddrState: 'GA',
        RegAddrZip: 30014,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '6773 HIGHWAY 212',
        RegAddrCity: 'COVINGTON',
        RegAddrState: 'GA',
        RegAddrZip: 30016,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '315 SUNFLOWER LN',
        RegAddrCity: 'COVINGTON',
        RegAddrState: 'GA',
        RegAddrZip: 30016,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '226 MOUNTAIN VIEW ST',
        RegAddrCity: 'DECATUR',
        RegAddrState: 'GA',
        RegAddrZip: 30030,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1429 ALVERADO WAY',
        RegAddrCity: 'DECATUR',
        RegAddrState: 'GA',
        RegAddrZip: 30032,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1926 BANDERA DR',
        RegAddrCity: 'DECATUR',
        RegAddrState: 'GA',
        RegAddrZip: 30032,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '204 TUXWORTH CIR',
        RegAddrCity: 'DECATUR',
        RegAddrState: 'GA',
        RegAddrZip: 30033,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2573 OAK CROSSING DR',
        RegAddrCity: 'DECATUR',
        RegAddrState: 'GA',
        RegAddrZip: 30033,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '3000 ROSEBROOK DR',
        RegAddrCity: 'DECATUR',
        RegAddrState: 'GA',
        RegAddrZip: 30033,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '3276 N DRUID HILLS RD',
        RegAddrCity: 'DECATUR',
        RegAddrState: 'GA',
        RegAddrZip: 30033,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '4630 GALLEON XING',
        RegAddrCity: 'DECATUR',
        RegAddrState: 'GA',
        RegAddrZip: 30035,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '4532 DORSET CIR',
        RegAddrCity: 'DECATUR',
        RegAddrState: 'GA',
        RegAddrZip: 30035,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2325 TROY COVE RD',
        RegAddrCity: 'DECATUR',
        RegAddrState: 'GA',
        RegAddrZip: 30035,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1227 ARLENE CT SW',
        RegAddrCity: 'LILBURN',
        RegAddrState: 'GA',
        RegAddrZip: 30047,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '808 BRENTWAY CT SW',
        RegAddrCity: 'LILBURN',
        RegAddrState: 'GA',
        RegAddrZip: 30047,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '3899 WALNUT CREEK TRCE',
        RegAddrCity: 'LITHONIA',
        RegAddrState: 'GA',
        RegAddrZip: 30038,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '4578 GARDEN CITY DR',
        RegAddrCity: 'LITHONIA',
        RegAddrState: 'GA',
        RegAddrZip: 30038,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2350 AVERY ST',
        RegAddrCity: 'LITHONIA',
        RegAddrState: 'GA',
        RegAddrZip: 30058,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '5797 KEYSTONE GRV',
        RegAddrCity: 'LITHONIA',
        RegAddrState: 'GA',
        RegAddrZip: 30058,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '5330 SHIREWICK LN',
        RegAddrCity: 'LITHONIA',
        RegAddrState: 'GA',
        RegAddrZip: 30058,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '5693 STRATHMOOR MANOR CIR',
        RegAddrCity: 'LITHONIA',
        RegAddrState: 'GA',
        RegAddrZip: 30058,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '3544 CHERRY BLOSSOM CT',
        RegAddrCity: 'LOGANVILLE',
        RegAddrState: 'GA',
        RegAddrZip: 30052,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2912 BRANDON LAKE DR',
        RegAddrCity: 'SNELLVILLE',
        RegAddrState: 'GA',
        RegAddrZip: 30039,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '4286 CONSTELLATION BLVD',
        RegAddrCity: 'SNELLVILLE',
        RegAddrState: 'GA',
        RegAddrZip: 30039,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2972 GALA TRL',
        RegAddrCity: 'SNELLVILLE',
        RegAddrState: 'GA',
        RegAddrZip: 30039,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '4268 ROCKY LEDGE WAY',
        RegAddrCity: 'SNELLVILLE',
        RegAddrState: 'GA',
        RegAddrZip: 30039,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1066 MASTERS LN',
        RegAddrCity: 'SNELLVILLE',
        RegAddrState: 'GA',
        RegAddrZip: 30078,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '3363 WOODLAUREL DR',
        RegAddrCity: 'SNELLVILLE',
        RegAddrState: 'GA',
        RegAddrZip: 30078,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2622 ABINGTON DR',
        RegAddrCity: 'SNELLVILLE',
        RegAddrState: 'GA',
        RegAddrZip: 30078,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2693 ASHWORTH CIR',
        RegAddrCity: 'SNELLVILLE',
        RegAddrState: 'GA',
        RegAddrZip: 30078,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '311 JAMES ST',
        RegAddrCity: 'STATESBORO',
        RegAddrState: 'GA',
        RegAddrZip: 30458,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '5007 BRITTANY DR',
        RegAddrCity: 'STONE MOUNTAIN',
        RegAddrState: 'GA',
        RegAddrZip: 30083,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '696 CATHER CT',
        RegAddrCity: 'STONE MOUNTAIN',
        RegAddrState: 'GA',
        RegAddrZip: 30088,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '4223 RUE ANTOINETTE',
        RegAddrCity: 'STONE MTN',
        RegAddrState: 'GA',
        RegAddrZip: 30083,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '493 S MALTON CT',
        RegAddrCity: 'STONE MTN',
        RegAddrState: 'GA',
        RegAddrZip: 30083,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '4604 FOUNTAINHEAD DR',
        RegAddrCity: 'STONE MTN',
        RegAddrState: 'GA',
        RegAddrZip: 30083,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '631 N HAIRSTON RD',
        RegAddrCity: 'STONE MTN',
        RegAddrState: 'GA',
        RegAddrZip: 30083,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '4745 RUSSWOOD AVE',
        RegAddrCity: 'STONE MTN',
        RegAddrState: 'GA',
        RegAddrZip: 30083,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '4620 HAIRSTON CROSSING PL',
        RegAddrCity: 'STONE MTN',
        RegAddrState: 'GA',
        RegAddrZip: 30083,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '4582 PLUM ORCHARD LN',
        RegAddrCity: 'STONE MTN',
        RegAddrState: 'GA',
        RegAddrZip: 30083,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1496 INVERNESS DR',
        RegAddrCity: 'STONE MTN',
        RegAddrState: 'GA',
        RegAddrZip: 30083,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '490 LAKEWATER ESTATES DR',
        RegAddrCity: 'STONE MTN',
        RegAddrState: 'GA',
        RegAddrZip: 30087,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '6978 WATKINS GLEN RD',
        RegAddrCity: 'STONE MTN',
        RegAddrState: 'GA',
        RegAddrZip: 30087,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '5800 OAKLEAF WAY',
        RegAddrCity: 'STONE MTN',
        RegAddrState: 'GA',
        RegAddrZip: 30087,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '538 STONEWOOD DR',
        RegAddrCity: 'STONE MTN',
        RegAddrState: 'GA',
        RegAddrZip: 30087,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1592 BURNSTONE DR',
        RegAddrCity: 'STONE MTN',
        RegAddrState: 'GA',
        RegAddrZip: 30088,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '317 ENGLE DR',
        RegAddrCity: 'TUCKER',
        RegAddrState: 'GA',
        RegAddrZip: 30084,
        CongressionalDistrict: 4,
      },
    ],
  },
  {
    name: 'GA-5',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/GA-5?ref=gh-pages',
    state: 'GA',
    state_and_number: 'GA-5',
    zip: 30236,
    congressionalDistrict: 5,
    addressesInDistrict: [
      {
        RegAddrLine1: '816 PONCE DE LEON PL NE',
        RegAddrCity: 'ATLANTA',
        RegAddrState: 'GA',
        RegAddrZip: 30306,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '1352 EMORY RD NE',
        RegAddrCity: 'ATLANTA',
        RegAddrState: 'GA',
        RegAddrZip: 30306,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '812 SPRINGDALE RD NE',
        RegAddrCity: 'ATLANTA',
        RegAddrState: 'GA',
        RegAddrZip: 30306,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '1646 NOBLE DR NE',
        RegAddrCity: 'ATLANTA',
        RegAddrState: 'GA',
        RegAddrZip: 30306,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '848 SPRING ST NW',
        RegAddrCity: 'ATLANTA',
        RegAddrState: 'GA',
        RegAddrZip: 30308,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '420 COURTLAND ST NE',
        RegAddrCity: 'ATLANTA',
        RegAddrState: 'GA',
        RegAddrZip: 30308,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '1184 RICHLAND RD SW',
        RegAddrCity: 'ATLANTA',
        RegAddrState: 'GA',
        RegAddrZip: 30310,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '1688 LISBON DR SW',
        RegAddrCity: 'ATLANTA',
        RegAddrState: 'GA',
        RegAddrZip: 30310,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '153 HERMER CIR NW',
        RegAddrCity: 'ATLANTA',
        RegAddrState: 'GA',
        RegAddrZip: 30311,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '2444 LAUREL CIR NW',
        RegAddrCity: 'ATLANTA',
        RegAddrState: 'GA',
        RegAddrZip: 30311,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '1398 POLLARD DR SW',
        RegAddrCity: 'ATLANTA',
        RegAddrState: 'GA',
        RegAddrZip: 30311,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '770 TECHWOOD DR NW',
        RegAddrCity: 'ATLANTA',
        RegAddrState: 'GA',
        RegAddrZip: 30313,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '1300 JOSEPH E BOONE BLVD NW',
        RegAddrCity: 'ATLANTA',
        RegAddrState: 'GA',
        RegAddrZip: 30314,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '763 LIBERTY COMMONS DR NW',
        RegAddrCity: 'ATLANTA',
        RegAddrState: 'GA',
        RegAddrZip: 30314,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '2318 BURROUGHS AVE SE',
        RegAddrCity: 'ATLANTA',
        RegAddrState: 'GA',
        RegAddrZip: 30315,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '1542 WOODLAND CIR SE',
        RegAddrCity: 'ATLANTA',
        RegAddrState: 'GA',
        RegAddrZip: 30316,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '165 PEARL ST SE',
        RegAddrCity: 'ATLANTA',
        RegAddrState: 'GA',
        RegAddrZip: 30316,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '1980 SHAWN WAYNE CIR SE',
        RegAddrCity: 'ATLANTA',
        RegAddrState: 'GA',
        RegAddrZip: 30316,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '169 POWELL ST SE',
        RegAddrCity: 'ATLANTA',
        RegAddrState: 'GA',
        RegAddrZip: 30316,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '3133 COTTONWOOD DR SE',
        RegAddrCity: 'ATLANTA',
        RegAddrState: 'GA',
        RegAddrZip: 30316,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '616 CENTER HILL AVE NW',
        RegAddrCity: 'ATLANTA',
        RegAddrState: 'GA',
        RegAddrZip: 30318,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '967 WEST HIGHLAND DR NW',
        RegAddrCity: 'ATLANTA',
        RegAddrState: 'GA',
        RegAddrZip: 30318,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '734 MATILDA PL NW',
        RegAddrCity: 'ATLANTA',
        RegAddrState: 'GA',
        RegAddrZip: 30318,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '709 CAMERON M ALEXANDER BLVD NW',
        RegAddrCity: 'ATLANTA',
        RegAddrState: 'GA',
        RegAddrZip: 30318,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '1950 HOWELL MILL RD NW',
        RegAddrCity: 'ATLANTA',
        RegAddrState: 'GA',
        RegAddrZip: 30318,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '318 WINDMONT DR NE',
        RegAddrCity: 'ATLANTA',
        RegAddrState: 'GA',
        RegAddrZip: 30329,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '1312 BRIARCLIFF GABLES CIR NE',
        RegAddrCity: 'ATLANTA',
        RegAddrState: 'GA',
        RegAddrZip: 30329,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '5106 DRUID HILLS RESERVE DR NE',
        RegAddrCity: 'ATLANTA',
        RegAddrState: 'GA',
        RegAddrZip: 30329,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '2560 COUNTY LINE RD SW',
        RegAddrCity: 'ATLANTA',
        RegAddrState: 'GA',
        RegAddrZip: 30331,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '4455 BAKERS FERRY RD SW',
        RegAddrCity: 'ATLANTA',
        RegAddrState: 'GA',
        RegAddrZip: 30331,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '5441 DENNY DR',
        RegAddrCity: 'COLLEGE PARK',
        RegAddrState: 'GA',
        RegAddrZip: 30349,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '719 PINETREE DR',
        RegAddrCity: 'DECATUR',
        RegAddrState: 'GA',
        RegAddrZip: 30030,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '1334 CARTER RD',
        RegAddrCity: 'DECATUR',
        RegAddrState: 'GA',
        RegAddrZip: 30030,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '115 CHURCH ST',
        RegAddrCity: 'DECATUR',
        RegAddrState: 'GA',
        RegAddrZip: 30030,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '11201 WALDROP PL',
        RegAddrCity: 'DECATUR',
        RegAddrState: 'GA',
        RegAddrZip: 30034,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '3493 NEWBERRY DOWNS LN',
        RegAddrCity: 'DECATUR',
        RegAddrState: 'GA',
        RegAddrZip: 30034,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '2795 VINING RIDGE TER',
        RegAddrCity: 'DECATUR',
        RegAddrState: 'GA',
        RegAddrZip: 30034,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '20 TREGONEY DR',
        RegAddrCity: 'DECATUR',
        RegAddrState: 'GA',
        RegAddrZip: 30034,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '2615 JEFFERSON TER',
        RegAddrCity: 'EAST POINT',
        RegAddrState: 'GA',
        RegAddrZip: 30344,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '2495 REX RD',
        RegAddrCity: 'ELLENWOOD',
        RegAddrState: 'GA',
        RegAddrZip: 30294,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '3195 NEAL WAY',
        RegAddrCity: 'ELLENWOOD',
        RegAddrState: 'GA',
        RegAddrZip: 30294,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '6234 SAN JUAN ST',
        RegAddrCity: 'FOREST PARK',
        RegAddrState: 'GA',
        RegAddrZip: 30297,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '6144 DEERFIELD CT',
        RegAddrCity: 'MORROW',
        RegAddrState: 'GA',
        RegAddrZip: 30260,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '6564 BENT CREEK DR',
        RegAddrCity: 'REX',
        RegAddrState: 'GA',
        RegAddrZip: 30273,
        CongressionalDistrict: 5,
      },
    ],
  },
  {
    name: 'GA-6',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/GA-6?ref=gh-pages',
    state: 'GA',
    state_and_number: 'GA-6',
    zip: 30004,
    congressionalDistrict: 6,
    addressesInDistrict: [
      {
        RegAddrLine1: '4819 THORNWOOD DR NW',
        RegAddrCity: 'ACWORTH',
        RegAddrState: 'GA',
        RegAddrZip: 30102,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '1245 BASNETT DR',
        RegAddrCity: 'ALPHARETTA',
        RegAddrState: 'GA',
        RegAddrZip: 30004,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '2936 COMMONWEALTH CIR',
        RegAddrCity: 'ALPHARETTA',
        RegAddrState: 'GA',
        RegAddrZip: 30004,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '1620 BRIERS CHUTE',
        RegAddrCity: 'ALPHARETTA',
        RegAddrState: 'GA',
        RegAddrZip: 30004,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '315 FIELDSTONE WALK',
        RegAddrCity: 'ALPHARETTA',
        RegAddrState: 'GA',
        RegAddrZip: 30005,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '585 STONEBURY DR',
        RegAddrCity: 'ALPHARETTA',
        RegAddrState: 'GA',
        RegAddrZip: 30005,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '130 WENTWORTH TER',
        RegAddrCity: 'ALPHARETTA',
        RegAddrState: 'GA',
        RegAddrZip: 30022,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '335 ACREVIEW DR',
        RegAddrCity: 'ALPHARETTA',
        RegAddrState: 'GA',
        RegAddrZip: 30022,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '5180 SKIDAWAY DR',
        RegAddrCity: 'ALPHARETTA',
        RegAddrState: 'GA',
        RegAddrZip: 30022,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '1182 BROOKHAVEN GLN NE',
        RegAddrCity: 'ATLANTA',
        RegAddrState: 'GA',
        RegAddrZip: 30319,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '1052 ANTIOCH DR NE',
        RegAddrCity: 'ATLANTA',
        RegAddrState: 'GA',
        RegAddrZip: 30319,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '3430 HALLCREST DR NE',
        RegAddrCity: 'ATLANTA',
        RegAddrState: 'GA',
        RegAddrZip: 30319,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '2161 FAIRWAY CIR NE',
        RegAddrCity: 'ATLANTA',
        RegAddrState: 'GA',
        RegAddrZip: 30319,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '6815 RIVER SPRINGS LN',
        RegAddrCity: 'ATLANTA',
        RegAddrState: 'GA',
        RegAddrZip: 30328,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '3270 CLAIRMONT NORTH NE',
        RegAddrCity: 'ATLANTA',
        RegAddrState: 'GA',
        RegAddrZip: 30329,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '1182 WHITEHALL POINTE',
        RegAddrCity: 'ATLANTA',
        RegAddrState: 'GA',
        RegAddrZip: 30338,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '4815 HIGH POINT RD',
        RegAddrCity: 'ATLANTA',
        RegAddrState: 'GA',
        RegAddrZip: 30342,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '501 BRANDYWINE CIR',
        RegAddrCity: 'ATLANTA',
        RegAddrState: 'GA',
        RegAddrZip: 30350,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '11407 JEFFERSON CIR N',
        RegAddrCity: 'CHAMBLEE',
        RegAddrState: 'GA',
        RegAddrZip: 30341,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '270 SELKIRK LN',
        RegAddrCity: 'DULUTH',
        RegAddrState: 'GA',
        RegAddrZip: 30097,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '1676 DURRETT WAY',
        RegAddrCity: 'DUNWOODY',
        RegAddrState: 'GA',
        RegAddrZip: 30338,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '4823 DUNWOODY JCT',
        RegAddrCity: 'DUNWOODY',
        RegAddrState: 'GA',
        RegAddrZip: 30338,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '320 ASHBROOK BLVD',
        RegAddrCity: 'JOHNS CREEK',
        RegAddrState: 'GA',
        RegAddrZip: 30097,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '1191 JUSTICE DR NW',
        RegAddrCity: 'KENNESAW',
        RegAddrState: 'GA',
        RegAddrZip: 30152,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '2853 WENDY SPRINGS CT NE',
        RegAddrCity: 'MARIETTA',
        RegAddrState: 'GA',
        RegAddrZip: 30062,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '3833 FENWAY XING',
        RegAddrCity: 'MARIETTA',
        RegAddrState: 'GA',
        RegAddrZip: 30062,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '3655 LIBERTY LN',
        RegAddrCity: 'MARIETTA',
        RegAddrState: 'GA',
        RegAddrZip: 30062,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '1772 BISHOP LAKE CT',
        RegAddrCity: 'MARIETTA',
        RegAddrState: 'GA',
        RegAddrZip: 30062,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '3900 NORTHPOINT DR',
        RegAddrCity: 'MARIETTA',
        RegAddrState: 'GA',
        RegAddrZip: 30062,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '4304 E SUMMIT CT',
        RegAddrCity: 'MARIETTA',
        RegAddrState: 'GA',
        RegAddrZip: 30066,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '495 KURTZ RD',
        RegAddrCity: 'MARIETTA',
        RegAddrState: 'GA',
        RegAddrZip: 30066,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '1520 WOOD VALLEY DR',
        RegAddrCity: 'MARIETTA',
        RegAddrState: 'GA',
        RegAddrZip: 30066,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '3728 JEFFERSON TOWNSHIP PKWY',
        RegAddrCity: 'MARIETTA',
        RegAddrState: 'GA',
        RegAddrZip: 30066,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '2200 WHITEHALL DR NE',
        RegAddrCity: 'MARIETTA',
        RegAddrState: 'GA',
        RegAddrZip: 30066,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '4150 JEFFERSON TOWNSHIP PKWY',
        RegAddrCity: 'MARIETTA',
        RegAddrState: 'GA',
        RegAddrZip: 30066,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '2929 TORREYA WAY SE',
        RegAddrCity: 'MARIETTA',
        RegAddrState: 'GA',
        RegAddrZip: 30067,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '408 WOODSTONE WEST DR',
        RegAddrCity: 'MARIETTA',
        RegAddrState: 'GA',
        RegAddrZip: 30068,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '4611 VILLA CHASE DR NE',
        RegAddrCity: 'MARIETTA',
        RegAddrState: 'GA',
        RegAddrZip: 30068,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '619 COUNSEL DR NE',
        RegAddrCity: 'MARIETTA',
        RegAddrState: 'GA',
        RegAddrZip: 30068,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '4170 FAIRGREEN DR NE',
        RegAddrCity: 'MARIETTA',
        RegAddrState: 'GA',
        RegAddrZip: 30068,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '5117 RIVERHILL CT',
        RegAddrCity: 'MARIETTA',
        RegAddrState: 'GA',
        RegAddrZip: 30068,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '3203 TEABERRY WAY',
        RegAddrCity: 'MARIETTA',
        RegAddrState: 'GA',
        RegAddrZip: 30068,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '805 PARKAIRE XING',
        RegAddrCity: 'MARIETTA',
        RegAddrState: 'GA',
        RegAddrZip: 30068,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '1220 LEE ST',
        RegAddrCity: 'MILTON',
        RegAddrState: 'GA',
        RegAddrZip: 30004,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '14792 HOPEWELL RD',
        RegAddrCity: 'MILTON',
        RegAddrState: 'GA',
        RegAddrZip: 30004,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '605 ELM ST',
        RegAddrCity: 'ROSWELL',
        RegAddrState: 'GA',
        RegAddrZip: 30075,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '400 WINN PARK CT',
        RegAddrCity: 'ROSWELL',
        RegAddrState: 'GA',
        RegAddrZip: 30075,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '200 CHASON WOOD WAY',
        RegAddrCity: 'ROSWELL',
        RegAddrState: 'GA',
        RegAddrZip: 30076,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '5050 SPALDING DR',
        RegAddrCity: 'SANDY SPRINGS',
        RegAddrState: 'GA',
        RegAddrZip: 30350,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '204 RIVER RUN DR',
        RegAddrCity: 'SANDY SPRINGS',
        RegAddrState: 'GA',
        RegAddrZip: 30350,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '4606 SIMS CT',
        RegAddrCity: 'TUCKER',
        RegAddrState: 'GA',
        RegAddrZip: 30084,
        CongressionalDistrict: 6,
      },
    ],
  },
  {
    name: 'GA-7',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/GA-7?ref=gh-pages',
    state: 'GA',
    state_and_number: 'GA-7',
    zip: 30019,
    congressionalDistrict: 7,
    addressesInDistrict: [
      {
        RegAddrLine1: '161 FERNWOOD CT',
        RegAddrCity: 'ALPHARETTA',
        RegAddrState: 'GA',
        RegAddrZip: 30004,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '2508 HARTFORD RUN',
        RegAddrCity: 'BUFORD',
        RegAddrState: 'GA',
        RegAddrZip: 30518,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '3478 SAG HARBOR CT',
        RegAddrCity: 'BUFORD',
        RegAddrState: 'GA',
        RegAddrZip: 30519,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '2965 IVY BROOK LN',
        RegAddrCity: 'BUFORD',
        RegAddrState: 'GA',
        RegAddrZip: 30519,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '3877 FELLOWSHIP DR',
        RegAddrCity: 'BUFORD',
        RegAddrState: 'GA',
        RegAddrZip: 30519,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '3615 TOWNLEY LN',
        RegAddrCity: 'CUMMING',
        RegAddrState: 'GA',
        RegAddrZip: 30040,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '4605 DARTFORD RD',
        RegAddrCity: 'CUMMING',
        RegAddrState: 'GA',
        RegAddrZip: 30040,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '4830 DUMBBARTON CT',
        RegAddrCity: 'CUMMING',
        RegAddrState: 'GA',
        RegAddrZip: 30040,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '6431 VALLEY STREAM DR',
        RegAddrCity: 'CUMMING',
        RegAddrState: 'GA',
        RegAddrZip: 30040,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '3765 MICHAELS WAY',
        RegAddrCity: 'CUMMING',
        RegAddrState: 'GA',
        RegAddrZip: 30040,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '1580 RUTLAND PKWY',
        RegAddrCity: 'CUMMING',
        RegAddrState: 'GA',
        RegAddrZip: 30041,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '1865 HABERSHAM MARINA RD',
        RegAddrCity: 'CUMMING',
        RegAddrState: 'GA',
        RegAddrZip: 30041,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '2540 MANOR CREEK CT',
        RegAddrCity: 'CUMMING',
        RegAddrState: 'GA',
        RegAddrZip: 30041,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '4301 TREE SUMMIT PKWY',
        RegAddrCity: 'DULUTH',
        RegAddrState: 'GA',
        RegAddrZip: 30096,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '818 PRESTON PARK DR',
        RegAddrCity: 'DULUTH',
        RegAddrState: 'GA',
        RegAddrZip: 30096,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '2694 REGENT WALK DR',
        RegAddrCity: 'DULUTH',
        RegAddrState: 'GA',
        RegAddrZip: 30096,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '3600 BERKELEY PARK CT',
        RegAddrCity: 'DULUTH',
        RegAddrState: 'GA',
        RegAddrZip: 30096,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '2651 CANNON FARM LN',
        RegAddrCity: 'DULUTH',
        RegAddrState: 'GA',
        RegAddrZip: 30097,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '3769 UNION PARK DR',
        RegAddrCity: 'DULUTH',
        RegAddrState: 'GA',
        RegAddrZip: 30097,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '3624 CLEARBROOKE WAY',
        RegAddrCity: 'DULUTH',
        RegAddrState: 'GA',
        RegAddrZip: 30097,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '511 PINE GROVE AVE',
        RegAddrCity: 'GRAYSON',
        RegAddrState: 'GA',
        RegAddrZip: 30017,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '950 OLD PEACHTREE RD NE',
        RegAddrCity: 'LAWRENCEVILLE',
        RegAddrState: 'GA',
        RegAddrZip: 30043,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '2753 HERNDON RD',
        RegAddrCity: 'LAWRENCEVILLE',
        RegAddrState: 'GA',
        RegAddrZip: 30043,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '3037 BAYMOUNT WAY',
        RegAddrCity: 'LAWRENCEVILLE',
        RegAddrState: 'GA',
        RegAddrZip: 30043,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '590 TWIN BROOK WAY',
        RegAddrCity: 'LAWRENCEVILLE',
        RegAddrState: 'GA',
        RegAddrZip: 30043,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '1825 MARSH CREEK DR',
        RegAddrCity: 'LAWRENCEVILLE',
        RegAddrState: 'GA',
        RegAddrZip: 30043,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '1155 BOTTOM LAND CIR',
        RegAddrCity: 'LAWRENCEVILLE',
        RegAddrState: 'GA',
        RegAddrZip: 30043,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '1370 CHALET CIR',
        RegAddrCity: 'LAWRENCEVILLE',
        RegAddrState: 'GA',
        RegAddrZip: 30043,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '2272 TAPANZEE LN',
        RegAddrCity: 'LAWRENCEVILLE',
        RegAddrState: 'GA',
        RegAddrZip: 30044,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '3044 SHADY WOODS CIR',
        RegAddrCity: 'LAWRENCEVILLE',
        RegAddrState: 'GA',
        RegAddrZip: 30044,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '2454 STANCREST LN',
        RegAddrCity: 'LAWRENCEVILLE',
        RegAddrState: 'GA',
        RegAddrZip: 30044,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '1591 EDGELEY WAY',
        RegAddrCity: 'LAWRENCEVILLE',
        RegAddrState: 'GA',
        RegAddrZip: 30044,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '3380 FERNVIEW DR',
        RegAddrCity: 'LAWRENCEVILLE',
        RegAddrState: 'GA',
        RegAddrZip: 30044,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '1540 BRAMLETT BLVD',
        RegAddrCity: 'LAWRENCEVILLE',
        RegAddrState: 'GA',
        RegAddrZip: 30045,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '81 GATES MILL DR',
        RegAddrCity: 'LAWRENCEVILLE',
        RegAddrState: 'GA',
        RegAddrZip: 30045,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '886 SUNSET RIDGE LN',
        RegAddrCity: 'LAWRENCEVILLE',
        RegAddrState: 'GA',
        RegAddrZip: 30045,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '415 WAYSIDE DR',
        RegAddrCity: 'LAWRENCEVILLE',
        RegAddrState: 'GA',
        RegAddrZip: 30046,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '834 WHITE OAK CT',
        RegAddrCity: 'LAWRENCEVILLE',
        RegAddrState: 'GA',
        RegAddrZip: 30046,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '4244 OAK CIR NW',
        RegAddrCity: 'LILBURN',
        RegAddrState: 'GA',
        RegAddrZip: 30047,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '930 PARK FOREST DR NW',
        RegAddrCity: 'LILBURN',
        RegAddrState: 'GA',
        RegAddrZip: 30047,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '4987 HOLLY VILLAGE CT NW',
        RegAddrCity: 'LILBURN',
        RegAddrState: 'GA',
        RegAddrZip: 30047,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '817 SAYBROOK CIR NW',
        RegAddrCity: 'LILBURN',
        RegAddrState: 'GA',
        RegAddrZip: 30047,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '276 KING DAVID DR SW',
        RegAddrCity: 'LILBURN',
        RegAddrState: 'GA',
        RegAddrZip: 30047,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '672 AUTUMN MEADOW DR',
        RegAddrCity: 'LOGANVILLE',
        RegAddrState: 'GA',
        RegAddrZip: 30052,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '3310 GARDENSIDE DR',
        RegAddrCity: 'LOGANVILLE',
        RegAddrState: 'GA',
        RegAddrZip: 30052,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '741 BELLE VISTA ST',
        RegAddrCity: 'MONROE',
        RegAddrState: 'GA',
        RegAddrZip: 30656,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '1173 BALBOA CT',
        RegAddrCity: 'NORCROSS',
        RegAddrState: 'GA',
        RegAddrZip: 30093,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '2472 TWILIGHT VW',
        RegAddrCity: 'SNELLVILLE',
        RegAddrState: 'GA',
        RegAddrZip: 30078,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '5415 PRINCETON OAKS DR',
        RegAddrCity: 'SUGAR HILL',
        RegAddrState: 'GA',
        RegAddrZip: 30518,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '1300 MOUNTAIN IVEY CT',
        RegAddrCity: 'SUGARHILL',
        RegAddrState: 'GA',
        RegAddrZip: 30518,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '3725 ROSEHAVEN WAY',
        RegAddrCity: 'SUWANEE',
        RegAddrState: 'GA',
        RegAddrZip: 30024,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '8010 FRIARBRIDGE DR',
        RegAddrCity: 'SUWANEE',
        RegAddrState: 'GA',
        RegAddrZip: 30024,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '3740 CLAY CT',
        RegAddrCity: 'SUWANEE',
        RegAddrState: 'GA',
        RegAddrZip: 30024,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '6530 CLUB VALLEY CT',
        RegAddrCity: 'SUWANEE',
        RegAddrState: 'GA',
        RegAddrZip: 30024,
        CongressionalDistrict: 7,
      },
    ],
  },
  {
    name: 'GA-8',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/GA-8?ref=gh-pages',
    state: 'GA',
    state_and_number: 'GA-8',
    zip: 30411,
    congressionalDistrict: 8,
    addressesInDistrict: [
      {
        RegAddrLine1: '2608 DOTTIE ST',
        RegAddrCity: 'ADEL',
        RegAddrState: 'GA',
        RegAddrZip: 31620,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '314 E WILLIAMS AVE',
        RegAddrCity: 'ASHBURN',
        RegAddrState: 'GA',
        RegAddrZip: 31714,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '105 OLDE HICKORY CIR',
        RegAddrCity: 'BONAIRE',
        RegAddrState: 'GA',
        RegAddrZip: 31005,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '105 BECKY DR',
        RegAddrCity: 'BONAIRE',
        RegAddrState: 'GA',
        RegAddrZip: 31005,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '152 EMERALD BLVD',
        RegAddrCity: 'BYRON',
        RegAddrState: 'GA',
        RegAddrZip: 31008,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '853 UPPER RIVER RD',
        RegAddrCity: 'COCHRAN',
        RegAddrState: 'GA',
        RegAddrZip: 31014,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '1692 LOG CABIN RD',
        RegAddrCity: 'EASTMAN',
        RegAddrState: 'GA',
        RegAddrZip: 31023,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '10 CHARLIE SINGLETARY RD',
        RegAddrCity: 'EASTMAN',
        RegAddrState: 'GA',
        RegAddrZip: 31023,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '120 GLYNN AVE',
        RegAddrCity: 'FITZGERALD',
        RegAddrState: 'GA',
        RegAddrZip: 31750,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '149 ARBOR CEMETERY RD',
        RegAddrCity: 'FITZGERALD',
        RegAddrState: 'GA',
        RegAddrZip: 31750,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '191 LEE KING RD',
        RegAddrCity: 'FORSYTH',
        RegAddrState: 'GA',
        RegAddrZip: 31029,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '146 STONEGABLES DR',
        RegAddrCity: 'GRAY',
        RegAddrState: 'GA',
        RegAddrZip: 31032,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '203 NEEDLECREST DR',
        RegAddrCity: 'GRAY',
        RegAddrState: 'GA',
        RegAddrZip: 31032,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '4537 AMELIA CIR',
        RegAddrCity: 'HAHIRA',
        RegAddrState: 'GA',
        RegAddrZip: 31632,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '106 THIRD AVE',
        RegAddrCity: 'HAWKINSVILLE',
        RegAddrState: 'GA',
        RegAddrZip: 31036,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '204 KLONDIKE RD',
        RegAddrCity: 'HAWKINSVILLE',
        RegAddrState: 'GA',
        RegAddrZip: 31036,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '14 MCCORMICK AVE',
        RegAddrCity: 'HAWKINSVILLE',
        RegAddrState: 'GA',
        RegAddrZip: 31036,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '131 E MARTIN LUTHER KING JR AVE',
        RegAddrCity: 'LAKELAND',
        RegAddrState: 'GA',
        RegAddrZip: 31635,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '302 HEYDON CT',
        RegAddrCity: 'MACON',
        RegAddrState: 'GA',
        RegAddrZip: 31210,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '2041 MOUNTAIN SPRINGS CHURCH RD',
        RegAddrCity: 'MACON',
        RegAddrState: 'GA',
        RegAddrZip: 31217,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '546 LACEBARK DR',
        RegAddrCity: 'MACON',
        RegAddrState: 'GA',
        RegAddrZip: 31220,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '291 JW WINGATE RD',
        RegAddrCity: 'MOULTRIE',
        RegAddrState: 'GA',
        RegAddrZip: 31768,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '225 3RD ST NW',
        RegAddrCity: 'MOULTRIE',
        RegAddrState: 'GA',
        RegAddrZip: 31768,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '424 1ST AVE SW',
        RegAddrCity: 'MOULTRIE',
        RegAddrState: 'GA',
        RegAddrZip: 31768,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '279 CONNELL RD',
        RegAddrCity: 'MOULTRIE',
        RegAddrState: 'GA',
        RegAddrZip: 31788,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '1457 COTTLE RD',
        RegAddrCity: 'NASHVILLE',
        RegAddrState: 'GA',
        RegAddrZip: 31639,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '1008 BEETREE AVE',
        RegAddrCity: 'NASHVILLE',
        RegAddrState: 'GA',
        RegAddrZip: 31639,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '1278 GA HIGHWAY 135 S',
        RegAddrCity: 'NAYLOR',
        RegAddrState: 'GA',
        RegAddrZip: 31641,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '1292 US HIGHWAY 319 N',
        RegAddrCity: 'NORMAN PARK',
        RegAddrState: 'GA',
        RegAddrZip: 31771,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '200 HILLTOP DR',
        RegAddrCity: 'PERRY',
        RegAddrState: 'GA',
        RegAddrZip: 31069,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '8303 DRY LAKE RD',
        RegAddrCity: 'QUITMAN',
        RegAddrState: 'GA',
        RegAddrZip: 31643,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '1697 TEN MILE RD',
        RegAddrCity: 'REBECCA',
        RegAddrState: 'GA',
        RegAddrZip: 31783,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '1434 ISABELLA TERRELL RD',
        RegAddrCity: 'SUMNER',
        RegAddrState: 'GA',
        RegAddrZip: 31789,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '1862 SANFORD RD',
        RegAddrCity: 'THOMASVILLE',
        RegAddrState: 'GA',
        RegAddrZip: 31757,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '52 FOREST HILLS DR',
        RegAddrCity: 'THOMASVILLE',
        RegAddrState: 'GA',
        RegAddrZip: 31792,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '2047 OLD CASSIDY RD',
        RegAddrCity: 'THOMASVILLE',
        RegAddrState: 'GA',
        RegAddrZip: 31792,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '2621 HALL AVE',
        RegAddrCity: 'TIFTON',
        RegAddrState: 'GA',
        RegAddrZip: 31794,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '202 VICTOR DR',
        RegAddrCity: 'TIFTON',
        RegAddrState: 'GA',
        RegAddrZip: 31794,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '2462 COPELAND RD',
        RegAddrCity: 'VALDOSTA',
        RegAddrState: 'GA',
        RegAddrZip: 31601,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '1314 PONDEROSA DR',
        RegAddrCity: 'VALDOSTA',
        RegAddrState: 'GA',
        RegAddrZip: 31601,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '1806 N TROUP ST',
        RegAddrCity: 'VALDOSTA',
        RegAddrState: 'GA',
        RegAddrZip: 31602,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '1308 DEERBROOK DR',
        RegAddrCity: 'VALDOSTA',
        RegAddrState: 'GA',
        RegAddrZip: 31602,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '5003 OAK DR',
        RegAddrCity: 'VALDOSTA',
        RegAddrState: 'GA',
        RegAddrZip: 31605,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '600 N BRIARCLIFF RD',
        RegAddrCity: 'WARNER ROBINS',
        RegAddrState: 'GA',
        RegAddrZip: 31088,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '208 CRESTWOOD TER',
        RegAddrCity: 'WARNER ROBINS',
        RegAddrState: 'GA',
        RegAddrZip: 31088,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '127 SASANQUA DR',
        RegAddrCity: 'WARNER ROBINS',
        RegAddrState: 'GA',
        RegAddrZip: 31088,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '203 SELF ST',
        RegAddrCity: 'WARNER ROBINS',
        RegAddrState: 'GA',
        RegAddrZip: 31088,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '203 CEDAR RIDGE DR',
        RegAddrCity: 'WARNER ROBINS',
        RegAddrState: 'GA',
        RegAddrZip: 31088,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '332 BURNS DR',
        RegAddrCity: 'WARNER ROBINS',
        RegAddrState: 'GA',
        RegAddrZip: 31088,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '500 GAWIN DR',
        RegAddrCity: 'WARNER ROBINS',
        RegAddrState: 'GA',
        RegAddrZip: 31093,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '500 FOREST LAKE DR',
        RegAddrCity: 'WARNER ROBINS',
        RegAddrState: 'GA',
        RegAddrZip: 31093,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '178 PEACHTREE ST NE',
        RegAddrCity: 'WARWICK',
        RegAddrState: 'GA',
        RegAddrZip: 31796,
        CongressionalDistrict: 8,
      },
    ],
  },
  {
    name: 'GA-9',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/GA-9?ref=gh-pages',
    state: 'GA',
    state_and_number: 'GA-9',
    zip: 28906,
    congressionalDistrict: 9,
    addressesInDistrict: [
      {
        RegAddrLine1: '160 HELENA CT',
        RegAddrCity: 'BALDWIN',
        RegAddrState: 'GA',
        RegAddrZip: 30511,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '10948 BLUE RIDGE HWY',
        RegAddrCity: 'BLAIRSVILLE',
        RegAddrState: 'GA',
        RegAddrZip: 30512,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '2711 ADA ST',
        RegAddrCity: 'BLUE RIDGE',
        RegAddrState: 'GA',
        RegAddrZip: 30513,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '2845 FRIENDSHIP RD',
        RegAddrCity: 'BUFORD',
        RegAddrState: 'GA',
        RegAddrZip: 30519,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '1844 NEW FRANKLIN CHURCH RD',
        RegAddrCity: 'CANON',
        RegAddrState: 'GA',
        RegAddrZip: 30520,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '155 BIRCHWOOD DR',
        RegAddrCity: 'CLARKESVILLE',
        RegAddrState: 'GA',
        RegAddrZip: 30523,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '181 THEODORE ST',
        RegAddrCity: 'CLAYTON',
        RegAddrState: 'GA',
        RegAddrZip: 30525,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '795 BLACK RD',
        RegAddrCity: 'CLEVELAND',
        RegAddrState: 'GA',
        RegAddrZip: 30528,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '153 DEW DROP LN',
        RegAddrCity: 'CLEVELAND',
        RegAddrState: 'GA',
        RegAddrZip: 30528,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '891 STOVALL RD',
        RegAddrCity: 'CLEVELAND',
        RegAddrState: 'GA',
        RegAddrZip: 30528,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '595 ROBINSON RD',
        RegAddrCity: 'CLEVELAND',
        RegAddrState: 'GA',
        RegAddrZip: 30528,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '243 KATIE BETH RD',
        RegAddrCity: 'COMER',
        RegAddrState: 'GA',
        RegAddrZip: 30629,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '311 PARADISE LN',
        RegAddrCity: 'COMMERCE',
        RegAddrState: 'GA',
        RegAddrZip: 30530,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '1027 HARRIS LORD CEMETERY RD',
        RegAddrCity: 'COMMERCE',
        RegAddrState: 'GA',
        RegAddrZip: 30530,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '119 FLAT ST',
        RegAddrCity: 'CORNELIA',
        RegAddrState: 'GA',
        RegAddrZip: 30531,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '6310 PRIVET WAY',
        RegAddrCity: 'CUMMING',
        RegAddrState: 'GA',
        RegAddrZip: 30028,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '4840 MAGNOLIA CREEK DR',
        RegAddrCity: 'CUMMING',
        RegAddrState: 'GA',
        RegAddrZip: 30028,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '7345 ASHLEY TRCE',
        RegAddrCity: 'CUMMING',
        RegAddrState: 'GA',
        RegAddrZip: 30028,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '5250 STEVEHAVEN LN',
        RegAddrCity: 'CUMMING',
        RegAddrState: 'GA',
        RegAddrZip: 30028,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '5525 GLENHAVEN DR',
        RegAddrCity: 'CUMMING',
        RegAddrState: 'GA',
        RegAddrZip: 30041,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '6830 BENNETT RD',
        RegAddrCity: 'CUMMING',
        RegAddrState: 'GA',
        RegAddrZip: 30041,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '689 BLACKS MILL RD',
        RegAddrCity: 'DAWSONVILLE',
        RegAddrState: 'GA',
        RegAddrZip: 30534,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '142 HUNTERS DR',
        RegAddrCity: 'DAWSONVILLE',
        RegAddrState: 'GA',
        RegAddrZip: 30534,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '59 INDIGO LN',
        RegAddrCity: 'DAWSONVILLE',
        RegAddrState: 'GA',
        RegAddrZip: 30534,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '3189 PULLIAM MILL RD NW',
        RegAddrCity: 'DEWY ROSE',
        RegAddrState: 'GA',
        RegAddrZip: 30634,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '2603 SANDERS RD',
        RegAddrCity: 'ELBERTON',
        RegAddrState: 'GA',
        RegAddrZip: 30635,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '80 LIBERTY LN',
        RegAddrCity: 'ELLIJAY',
        RegAddrState: 'GA',
        RegAddrZip: 30536,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '64 HART CT',
        RegAddrCity: 'ELLIJAY',
        RegAddrState: 'GA',
        RegAddrZip: 30540,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '249 DRYAD CT',
        RegAddrCity: 'ELLIJAY',
        RegAddrState: 'GA',
        RegAddrZip: 30540,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '6194 SADDLEHORSE DR',
        RegAddrCity: 'FLOWERY BR',
        RegAddrState: 'GA',
        RegAddrZip: 30542,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '6214 STILLWATER PL',
        RegAddrCity: 'FLOWERY BR',
        RegAddrState: 'GA',
        RegAddrZip: 30542,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '1006 CREST PARK PT',
        RegAddrCity: 'GAINESVILLE',
        RegAddrState: 'GA',
        RegAddrZip: 30504,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '3011 BASIN RD',
        RegAddrCity: 'GAINESVILLE',
        RegAddrState: 'GA',
        RegAddrZip: 30506,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '3360 BENNETT LN',
        RegAddrCity: 'GAINESVILLE',
        RegAddrState: 'GA',
        RegAddrZip: 30507,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '3744 SW RIDGE BLUFF OVERLOOK',
        RegAddrCity: 'GAINESVILLE',
        RegAddrState: 'GA',
        RegAddrZip: 30507,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '4121 ARBOR CHASE RD',
        RegAddrCity: 'GAINESVILLE',
        RegAddrState: 'GA',
        RegAddrZip: 30507,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '470 LITTLE POWDERBAG RD',
        RegAddrCity: 'HARTWELL',
        RegAddrState: 'GA',
        RegAddrZip: 30643,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '68 BENSON ST',
        RegAddrCity: 'HARTWELL',
        RegAddrState: 'GA',
        RegAddrZip: 30643,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '2752 WHIPPOORWILL LN',
        RegAddrCity: 'HIAWASSEE',
        RegAddrState: 'GA',
        RegAddrZip: 30546,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '6524 AUTUMN RIDGE WAY',
        RegAddrCity: 'HOSCHTON',
        RegAddrState: 'GA',
        RegAddrZip: 30548,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '5293 JACKSON TRAIL RD',
        RegAddrCity: 'HOSCHTON',
        RegAddrState: 'GA',
        RegAddrZip: 30548,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '1189 LAKE DEERFIELD RD',
        RegAddrCity: 'HULL',
        RegAddrState: 'GA',
        RegAddrZip: 30646,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '190 ELM ST',
        RegAddrCity: 'JEFFERSON',
        RegAddrState: 'GA',
        RegAddrZip: 30549,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '1357 LAKERIDGE CT',
        RegAddrCity: 'JEFFERSON',
        RegAddrState: 'GA',
        RegAddrZip: 30549,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '245 SWANN CIR',
        RegAddrCity: 'JEFFERSON',
        RegAddrState: 'GA',
        RegAddrZip: 30549,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '4296 WATERWORKS RD',
        RegAddrCity: 'JEFFERSON',
        RegAddrState: 'GA',
        RegAddrZip: 30549,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '7816 COUNTY LINE RD',
        RegAddrCity: 'LULA',
        RegAddrState: 'GA',
        RegAddrZip: 30554,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '5839 THOMPSON BRIDGE RD',
        RegAddrCity: 'MURRAYVILLE',
        RegAddrState: 'GA',
        RegAddrZip: 30564,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '6914 UNDERWOOD DR',
        RegAddrCity: 'MURRAYVILLE',
        RegAddrState: 'GA',
        RegAddrZip: 30564,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '88 POPLAR CIR',
        RegAddrCity: 'TOCCOA',
        RegAddrState: 'GA',
        RegAddrZip: 30577,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '2940 OAK VALLEY RD',
        RegAddrCity: 'TOCCOA',
        RegAddrState: 'GA',
        RegAddrZip: 30577,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '270 MIZE RD',
        RegAddrCity: 'TOCCOA',
        RegAddrState: 'GA',
        RegAddrZip: 30577,
        CongressionalDistrict: 9,
      },
    ],
  },
  {
    name: 'GU-0',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/GU-0?ref=gh-pages',
    state: 'GU',
    state_and_number: 'GU-0',
    congressionalDistrict: 0,
    addressesInDistrict: [],
  },
  {
    name: 'HI-1',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/HI-1?ref=gh-pages',
    state: 'HI',
    state_and_number: 'HI-1',
    zip: 96701,
    congressionalDistrict: 1,
    addressesInDistrict: [
      {
        RegAddrLine1: '99-140 KEALAKAHA DR',
        RegAddrCity: 'AIEA',
        RegAddrState: 'HI',
        RegAddrZip: 96701,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '99-174 HOLO PL',
        RegAddrCity: 'AIEA',
        RegAddrState: 'HI',
        RegAddrZip: 96701,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '99-193 LALANI ST',
        RegAddrCity: 'AIEA',
        RegAddrState: 'HI',
        RegAddrZip: 96701,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '98-1710 KIAWE ST',
        RegAddrCity: 'AIEA',
        RegAddrState: 'HI',
        RegAddrZip: 96701,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '98-402 KOAUKA LOOP',
        RegAddrCity: 'AIEA',
        RegAddrState: 'HI',
        RegAddrZip: 96701,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '99-138 WAIPAO PL',
        RegAddrCity: 'AIEA',
        RegAddrState: 'HI',
        RegAddrZip: 96701,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '98-660 MOANALUA LOOP',
        RegAddrCity: 'AIEA',
        RegAddrState: 'HI',
        RegAddrZip: 96701,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '99-1647 AIEA HEIGHTS DR',
        RegAddrCity: 'AIEA',
        RegAddrState: 'HI',
        RegAddrZip: 96701,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '91-111 HALOKO PL',
        RegAddrCity: 'EWA BEACH',
        RegAddrState: 'HI',
        RegAddrZip: 96706,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '91-1031 WAIMOMONA PL',
        RegAddrCity: 'EWA BEACH',
        RegAddrState: 'HI',
        RegAddrZip: 96706,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '91-336 HOALAUNA PL',
        RegAddrCity: 'EWA BEACH',
        RegAddrState: 'HI',
        RegAddrZip: 96706,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '91-1859 PARK ROW',
        RegAddrCity: 'EWA BEACH',
        RegAddrState: 'HI',
        RegAddrZip: 96706,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '91-2210 FORT WEAVER RD',
        RegAddrCity: 'EWA BEACH',
        RegAddrState: 'HI',
        RegAddrZip: 96706,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '91-327 KAILIKE PL',
        RegAddrCity: 'EWA BEACH',
        RegAddrState: 'HI',
        RegAddrZip: 96706,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '91-128 PUAINA PL',
        RegAddrCity: 'EWA BEACH',
        RegAddrState: 'HI',
        RegAddrZip: 96706,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '91-930 IKULANI ST',
        RegAddrCity: 'EWA BEACH',
        RegAddrState: 'HI',
        RegAddrZip: 96706,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '91-246 KAIELEELE PL',
        RegAddrCity: 'EWA BEACH',
        RegAddrState: 'HI',
        RegAddrZip: 96706,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '91-1316 KAIOKIA ST',
        RegAddrCity: 'EWA BEACH',
        RegAddrState: 'HI',
        RegAddrZip: 96706,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '91-261 FORT WEAVER RD',
        RegAddrCity: 'EWA BEACH',
        RegAddrState: 'HI',
        RegAddrZip: 96706,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '91-215 KEAALII WAY',
        RegAddrCity: 'EWA BEACH',
        RegAddrState: 'HI',
        RegAddrZip: 96706,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '626 CORAL ST',
        RegAddrCity: 'HONOLULU',
        RegAddrState: 'HI',
        RegAddrZip: 96813,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2889 PACIFIC HEIGHTS RD',
        RegAddrCity: 'HONOLULU',
        RegAddrState: 'HI',
        RegAddrZip: 96813,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1234 RYCROFT ST',
        RegAddrCity: 'HONOLULU',
        RegAddrState: 'HI',
        RegAddrZip: 96814,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1122 HOOLAI ST',
        RegAddrCity: 'HONOLULU',
        RegAddrState: 'HI',
        RegAddrZip: 96814,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1848 KAHAKAI DR',
        RegAddrCity: 'HONOLULU',
        RegAddrState: 'HI',
        RegAddrZip: 96814,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '3063 LA PIETRA CIR',
        RegAddrCity: 'HONOLULU',
        RegAddrState: 'HI',
        RegAddrZip: 96815,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1778 ALA MOANA BLVD',
        RegAddrCity: 'HONOLULU',
        RegAddrState: 'HI',
        RegAddrZip: 96815,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '3838 KILAUEA AVE',
        RegAddrCity: 'HONOLULU',
        RegAddrState: 'HI',
        RegAddrZip: 96816,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2724 WAIOMAO RD',
        RegAddrCity: 'HONOLULU',
        RegAddrState: 'HI',
        RegAddrZip: 96816,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '4999 KAHALA AVE',
        RegAddrCity: 'HONOLULU',
        RegAddrState: 'HI',
        RegAddrZip: 96816,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '3522 MAUNALOA AVE',
        RegAddrCity: 'HONOLULU',
        RegAddrState: 'HI',
        RegAddrZip: 96816,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '3514 SIERRA DR',
        RegAddrCity: 'HONOLULU',
        RegAddrState: 'HI',
        RegAddrZip: 96816,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '4167B HUANUI ST',
        RegAddrCity: 'HONOLULU',
        RegAddrState: 'HI',
        RegAddrZip: 96816,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1426 KAMINAKA DR',
        RegAddrCity: 'HONOLULU',
        RegAddrState: 'HI',
        RegAddrZip: 96816,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1647 LILIHA ST',
        RegAddrCity: 'HONOLULU',
        RegAddrState: 'HI',
        RegAddrZip: 96817,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2335 MAKANANI DR',
        RegAddrCity: 'HONOLULU',
        RegAddrState: 'HI',
        RegAddrZip: 96817,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1618 KALANI ST',
        RegAddrCity: 'HONOLULU',
        RegAddrState: 'HI',
        RegAddrZip: 96817,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1026 LAA LN',
        RegAddrCity: 'HONOLULU',
        RegAddrState: 'HI',
        RegAddrZip: 96817,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1638 AHIAHI PL',
        RegAddrCity: 'HONOLULU',
        RegAddrState: 'HI',
        RegAddrZip: 96817,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1940 NAIO ST',
        RegAddrCity: 'HONOLULU',
        RegAddrState: 'HI',
        RegAddrZip: 96817,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '932 WAIAKAMILO RD',
        RegAddrCity: 'HONOLULU',
        RegAddrState: 'HI',
        RegAddrZip: 96817,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1515 NUUANU AVE',
        RegAddrCity: 'HONOLULU',
        RegAddrState: 'HI',
        RegAddrZip: 96817,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '101 KAWANANAKOA PL',
        RegAddrCity: 'HONOLULU',
        RegAddrState: 'HI',
        RegAddrZip: 96817,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2003 HOUGHTAILING ST',
        RegAddrCity: 'HONOLULU',
        RegAddrState: 'HI',
        RegAddrZip: 96817,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1017 HULAKUI DR',
        RegAddrCity: 'HONOLULU',
        RegAddrState: 'HI',
        RegAddrZip: 96818,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1046 ALA OLI ST',
        RegAddrCity: 'HONOLULU',
        RegAddrState: 'HI',
        RegAddrZip: 96818,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '3003 ALA NAPUAA PL',
        RegAddrCity: 'HONOLULU',
        RegAddrState: 'HI',
        RegAddrZip: 96818,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2851 ANDERSON AVE',
        RegAddrCity: 'HONOLULU',
        RegAddrState: 'HI',
        RegAddrZip: 96818,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1437 ALA LELEU ST',
        RegAddrCity: 'HONOLULU',
        RegAddrState: 'HI',
        RegAddrZip: 96818,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1915 KAHANU ST',
        RegAddrCity: 'HONOLULU',
        RegAddrState: 'HI',
        RegAddrZip: 96819,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1909 KALIHI ST',
        RegAddrCity: 'HONOLULU',
        RegAddrState: 'HI',
        RegAddrZip: 96819,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2136 KAMEHAMEHA HWY',
        RegAddrCity: 'HONOLULU',
        RegAddrState: 'HI',
        RegAddrZip: 96819,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1270 ALA AMOAMO ST',
        RegAddrCity: 'HONOLULU',
        RegAddrState: 'HI',
        RegAddrZip: 96819,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1812 AKONE PL',
        RegAddrCity: 'HONOLULU',
        RegAddrState: 'HI',
        RegAddrZip: 96819,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2901 KALIHI ST',
        RegAddrCity: 'HONOLULU',
        RegAddrState: 'HI',
        RegAddrZip: 96819,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '12 SAND ISLAND ACCESS RD',
        RegAddrCity: 'HONOLULU',
        RegAddrState: 'HI',
        RegAddrZip: 96819,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1951 ALA MAHAMOE PL',
        RegAddrCity: 'HONOLULU',
        RegAddrState: 'HI',
        RegAddrZip: 96819,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1927 KUAPAPA PL',
        RegAddrCity: 'HONOLULU',
        RegAddrState: 'HI',
        RegAddrZip: 96819,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '904 GULICK AVE',
        RegAddrCity: 'HONOLULU',
        RegAddrState: 'HI',
        RegAddrZip: 96819,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1169 MONA ST',
        RegAddrCity: 'HONOLULU',
        RegAddrState: 'HI',
        RegAddrZip: 96821,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2110 LAUKAHI ST',
        RegAddrCity: 'HONOLULU',
        RegAddrState: 'HI',
        RegAddrZip: 96821,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1248 AINAKOA AVE',
        RegAddrCity: 'HONOLULU',
        RegAddrState: 'HI',
        RegAddrZip: 96821,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1424 EHUPUA ST',
        RegAddrCity: 'HONOLULU',
        RegAddrState: 'HI',
        RegAddrZip: 96821,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '640 HIND IUKA DR',
        RegAddrCity: 'HONOLULU',
        RegAddrState: 'HI',
        RegAddrZip: 96821,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '5136 KALANIANAOLE HWY',
        RegAddrCity: 'HONOLULU',
        RegAddrState: 'HI',
        RegAddrZip: 96821,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '5609 PIA ST',
        RegAddrCity: 'HONOLULU',
        RegAddrState: 'HI',
        RegAddrZip: 96821,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1143 HASSINGER ST',
        RegAddrCity: 'HONOLULU',
        RegAddrState: 'HI',
        RegAddrZip: 96822,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1621 DOLE ST',
        RegAddrCity: 'HONOLULU',
        RegAddrState: 'HI',
        RegAddrZip: 96822,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2828 KEAMA PL',
        RegAddrCity: 'HONOLULU',
        RegAddrState: 'HI',
        RegAddrZip: 96822,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1017 NEHOA ST',
        RegAddrCity: 'HONOLULU',
        RegAddrState: 'HI',
        RegAddrZip: 96822,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '3402 OAHU AVE',
        RegAddrCity: 'HONOLULU',
        RegAddrState: 'HI',
        RegAddrZip: 96822,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '3504 PILIKINO ST',
        RegAddrCity: 'HONOLULU',
        RegAddrState: 'HI',
        RegAddrZip: 96822,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1264 NEHOA ST',
        RegAddrCity: 'HONOLULU',
        RegAddrState: 'HI',
        RegAddrZip: 96822,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '3286 BEAUMONT WOODS PL',
        RegAddrCity: 'HONOLULU',
        RegAddrState: 'HI',
        RegAddrZip: 96822,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '7260 PULEHU ST',
        RegAddrCity: 'HONOLULU',
        RegAddrState: 'HI',
        RegAddrZip: 96825,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '7128 NIUMALU LOOP',
        RegAddrCity: 'HONOLULU',
        RegAddrState: 'HI',
        RegAddrZip: 96825,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '528 LAUIKI ST',
        RegAddrCity: 'HONOLULU',
        RegAddrState: 'HI',
        RegAddrZip: 96826,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1927 CHURCH LN',
        RegAddrCity: 'HONOLULU',
        RegAddrState: 'HI',
        RegAddrZip: 96826,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1545 KALAKAUA AVE',
        RegAddrCity: 'HONOLULU',
        RegAddrState: 'HI',
        RegAddrZip: 96826,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '91-1253 KAMAAHA AVE',
        RegAddrCity: 'KAPOLEI',
        RegAddrState: 'HI',
        RegAddrZip: 96707,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '92-1507 ALIINUI DR',
        RegAddrCity: 'KAPOLEI',
        RegAddrState: 'HI',
        RegAddrZip: 96707,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '95-208 AHAHUINA PL',
        RegAddrCity: 'MILILANI',
        RegAddrState: 'HI',
        RegAddrZip: 96789,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '95-1096 KELAKELA ST',
        RegAddrCity: 'MILILANI',
        RegAddrState: 'HI',
        RegAddrZip: 96789,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '94-336 ULUKOA ST',
        RegAddrCity: 'MILILANI',
        RegAddrState: 'HI',
        RegAddrZip: 96789,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '95-1093 MILIA ST',
        RegAddrCity: 'MILILANI',
        RegAddrState: 'HI',
        RegAddrZip: 96789,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '94-325 APELE ST',
        RegAddrCity: 'MILILANI',
        RegAddrState: 'HI',
        RegAddrZip: 96789,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '95-1033 KUALAPA ST',
        RegAddrCity: 'MILILANI',
        RegAddrState: 'HI',
        RegAddrZip: 96789,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '94-216 KEALOHI ST',
        RegAddrCity: 'MILILANI',
        RegAddrState: 'HI',
        RegAddrZip: 96789,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '95-257 KAILIULA LOOP',
        RegAddrCity: 'MILILANI',
        RegAddrState: 'HI',
        RegAddrZip: 96789,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '95-1043 HEHINA ST',
        RegAddrCity: 'MILILANI',
        RegAddrState: 'HI',
        RegAddrZip: 96789,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '94-220 KEALAKAA PL',
        RegAddrCity: 'MILILANI',
        RegAddrState: 'HI',
        RegAddrZip: 96789,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1850 HOOWAE ST',
        RegAddrCity: 'PEARL CITY',
        RegAddrState: 'HI',
        RegAddrZip: 96782,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1587 NOELANI ST',
        RegAddrCity: 'PEARL CITY',
        RegAddrState: 'HI',
        RegAddrZip: 96782,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1486 HOOHAKU ST',
        RegAddrCity: 'PEARL CITY',
        RegAddrState: 'HI',
        RegAddrZip: 96782,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '98-413 HOOKANIKE ST',
        RegAddrCity: 'PEARL CITY',
        RegAddrState: 'HI',
        RegAddrZip: 96782,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1962 WAIMANO HOME RD',
        RegAddrCity: 'PEARL CITY',
        RegAddrState: 'HI',
        RegAddrZip: 96782,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '87-543 MANUU ST',
        RegAddrCity: 'WAIANAE',
        RegAddrState: 'HI',
        RegAddrZip: 96792,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '94-612 LUMIAINA ST',
        RegAddrCity: 'WAIPAHU',
        RegAddrState: 'HI',
        RegAddrZip: 96797,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '94-502 OHAPALI ST',
        RegAddrCity: 'WAIPAHU',
        RegAddrState: 'HI',
        RegAddrZip: 96797,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '94-960 AWANANI ST',
        RegAddrCity: 'WAIPAHU',
        RegAddrState: 'HI',
        RegAddrZip: 96797,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '94-710 HONOWAI ST',
        RegAddrCity: 'WAIPAHU',
        RegAddrState: 'HI',
        RegAddrZip: 96797,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '94-1496 KAHUALOA ST',
        RegAddrCity: 'WAIPAHU',
        RegAddrState: 'HI',
        RegAddrZip: 96797,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '94-1538 WAIPAHU ST',
        RegAddrCity: 'WAIPAHU',
        RegAddrState: 'HI',
        RegAddrZip: 96797,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '94-66 LEOWAENA ST',
        RegAddrCity: 'WAIPAHU',
        RegAddrState: 'HI',
        RegAddrZip: 96797,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '94-514 APII ST',
        RegAddrCity: 'WAIPAHU',
        RegAddrState: 'HI',
        RegAddrZip: 96797,
        CongressionalDistrict: 1,
      },
    ],
  },
  {
    name: 'HI-2',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/HI-2?ref=gh-pages',
    state: 'HI',
    state_and_number: 'HI-2',
    zip: 96703,
    congressionalDistrict: 2,
    addressesInDistrict: [
      {
        RegAddrLine1: '84-5168 MAMALAHOA HWY',
        RegAddrCity: 'CAPTAIN COOK',
        RegAddrState: 'HI',
        RegAddrZip: 96704,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '82-6073 MAMALAHOA HWY',
        RegAddrCity: 'CAPTAIN COOK',
        RegAddrState: 'HI',
        RegAddrZip: 96704,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1140 NANIHOKU PL',
        RegAddrCity: 'HAIKU',
        RegAddrState: 'HI',
        RegAddrZip: 96708,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2145 KAIWIKI RD',
        RegAddrCity: 'HILO',
        RegAddrState: 'HI',
        RegAddrZip: 96720,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '60 HOOKANO ST',
        RegAddrCity: 'HILO',
        RegAddrState: 'HI',
        RegAddrZip: 96720,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '87 BANYAN DR',
        RegAddrCity: 'HILO',
        RegAddrState: 'HI',
        RegAddrZip: 96720,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '342 DESHA AVE',
        RegAddrCity: 'HILO',
        RegAddrState: 'HI',
        RegAddrZip: 96720,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1673 HALELOKE ST',
        RegAddrCity: 'HILO',
        RegAddrState: 'HI',
        RegAddrZip: 96720,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '130 IWALANI ST',
        RegAddrCity: 'HILO',
        RegAddrState: 'HI',
        RegAddrZip: 96720,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '760 KAIKEA ST',
        RegAddrCity: 'HILO',
        RegAddrState: 'HI',
        RegAddrZip: 96720,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '94 HOONANEA ST',
        RegAddrCity: 'HILO',
        RegAddrState: 'HI',
        RegAddrZip: 96720,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '93 MIKIHALA ST',
        RegAddrCity: 'HILO',
        RegAddrState: 'HI',
        RegAddrZip: 96720,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '320 AWA ST',
        RegAddrCity: 'HILO',
        RegAddrState: 'HI',
        RegAddrZip: 96720,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '79-1145 KAO RD',
        RegAddrCity: 'HOLUALOA',
        RegAddrState: 'HI',
        RegAddrZip: 96725,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '46-4091 OLD MAMALAHOA HWY',
        RegAddrCity: 'HONOKAA',
        RegAddrState: 'HI',
        RegAddrZip: 96727,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '231 NAONE ST',
        RegAddrCity: 'HONOLULU',
        RegAddrState: 'HI',
        RegAddrZip: 96813,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2765 PUU KAPELE AVE',
        RegAddrCity: 'HOOLEHUA',
        RegAddrState: 'HI',
        RegAddrZip: 96729,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '56-109 HUEHU PL',
        RegAddrCity: 'KAHUKU',
        RegAddrState: 'HI',
        RegAddrZip: 96731,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '57-101 KUILIMA DR',
        RegAddrCity: 'KAHUKU',
        RegAddrState: 'HI',
        RegAddrZip: 96731,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '792 HONOWAI ST',
        RegAddrCity: 'KAHULUI',
        RegAddrState: 'HI',
        RegAddrZip: 96732,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '854 MAKALII ST',
        RegAddrCity: 'KAHULUI',
        RegAddrState: 'HI',
        RegAddrZip: 96732,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '645 KIPAPA PL',
        RegAddrCity: 'KAHULUI',
        RegAddrState: 'HI',
        RegAddrZip: 96732,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '587 ONEHEE AVE',
        RegAddrCity: 'KAHULUI',
        RegAddrState: 'HI',
        RegAddrZip: 96732,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '207 MOKAPU ST',
        RegAddrCity: 'KAHULUI',
        RegAddrState: 'HI',
        RegAddrZip: 96732,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1009 APOKULA ST',
        RegAddrCity: 'KAILUA',
        RegAddrState: 'HI',
        RegAddrZip: 96734,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '396 DUNE CIR',
        RegAddrCity: 'KAILUA',
        RegAddrState: 'HI',
        RegAddrZip: 96734,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '810 ONEAWA ST',
        RegAddrCity: 'KAILUA',
        RegAddrState: 'HI',
        RegAddrZip: 96734,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1504 AUPUPU ST',
        RegAddrCity: 'KAILUA',
        RegAddrState: 'HI',
        RegAddrZip: 96734,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '551 AUWAI ST',
        RegAddrCity: 'KAILUA',
        RegAddrState: 'HI',
        RegAddrZip: 96734,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '73-4411 KAKAHIAKA ST',
        RegAddrCity: 'KAILUA KONA',
        RegAddrState: 'HI',
        RegAddrZip: 96740,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '73-4428 MAMALAHOA HWY',
        RegAddrCity: 'KAILUA KONA',
        RegAddrState: 'HI',
        RegAddrZip: 96740,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '73-1103 KAIMINANI DR',
        RegAddrCity: 'KAILUA KONA',
        RegAddrState: 'HI',
        RegAddrZip: 96740,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '75-5616-E HIENALOLI RD',
        RegAddrCity: 'KAILUA KONA',
        RegAddrState: 'HI',
        RegAddrZip: 96740,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '65-1117 EHIKU PL',
        RegAddrCity: 'KAMUELA',
        RegAddrState: 'HI',
        RegAddrZip: 96743,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '64-634 MANA RD',
        RegAddrCity: 'KAMUELA',
        RegAddrState: 'HI',
        RegAddrZip: 96743,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '59-336 LEHIWA PL',
        RegAddrCity: 'KAMUELA',
        RegAddrState: 'HI',
        RegAddrZip: 96743,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '68-1006 MAUNA LANI POINT DR',
        RegAddrCity: 'KAMUELA',
        RegAddrState: 'HI',
        RegAddrZip: 96743,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '46-187 HINALANI ST',
        RegAddrCity: 'KANEOHE',
        RegAddrState: 'HI',
        RegAddrZip: 96744,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '45-422 KAMEHAMEHA HWY',
        RegAddrCity: 'KANEOHE',
        RegAddrState: 'HI',
        RegAddrZip: 96744,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '47-481 PAKAI PL',
        RegAddrCity: 'KANEOHE',
        RegAddrState: 'HI',
        RegAddrZip: 96744,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '45-509 HALEKOU RD',
        RegAddrCity: 'KANEOHE',
        RegAddrState: 'HI',
        RegAddrZip: 96744,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '45-799 POOKELA ST',
        RegAddrCity: 'KANEOHE',
        RegAddrState: 'HI',
        RegAddrZip: 96744,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '46-130 PUNALEI PL',
        RegAddrCity: 'KANEOHE',
        RegAddrState: 'HI',
        RegAddrZip: 96744,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '6590 KUHOHO ST',
        RegAddrCity: 'KAPAA',
        RegAddrState: 'HI',
        RegAddrZip: 96746,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '4764 PELEHU RD',
        RegAddrCity: 'KAPAA',
        RegAddrState: 'HI',
        RegAddrZip: 96746,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '6510 PUUPILO RD',
        RegAddrCity: 'KAPAA',
        RegAddrState: 'HI',
        RegAddrZip: 96746,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '6438 AHELE DR',
        RegAddrCity: 'KAPAA',
        RegAddrState: 'HI',
        RegAddrZip: 96746,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: 'UOCAVA 15-01',
        RegAddrCity: 'KAPAA',
        RegAddrState: 'HI',
        RegAddrZip: 96746,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '53-419 HALAULA MAULILI RD',
        RegAddrCity: 'KAPAAU',
        RegAddrState: 'HI',
        RegAddrZip: 96755,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '92-543 UALEHEI ST',
        RegAddrCity: 'KAPOLEI',
        RegAddrState: 'HI',
        RegAddrZip: 96707,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '92-1353 HAUONE ST',
        RegAddrCity: 'KAPOLEI',
        RegAddrState: 'HI',
        RegAddrZip: 96707,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '364 KIKIPUA ST',
        RegAddrCity: 'KAUNAKAKAI',
        RegAddrState: 'HI',
        RegAddrZip: 96748,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '17-168 IPUAIWAHA ST',
        RegAddrCity: 'KEAAU',
        RegAddrState: 'HI',
        RegAddrZip: 96749,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '15-1654 MAIA AVE 18TH HPP',
        RegAddrCity: 'KEAAU',
        RegAddrState: 'HI',
        RegAddrZip: 96749,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '16-578 VOLCANO RD',
        RegAddrCity: 'KEAAU',
        RegAddrState: 'HI',
        RegAddrZip: 96749,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '7866 IWIPOLENA RD',
        RegAddrCity: 'KEKAHA',
        RegAddrState: 'HI',
        RegAddrZip: 96752,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '7757 IWIPOLENA RD',
        RegAddrCity: 'KEKAHA',
        RegAddrState: 'HI',
        RegAddrZip: 96752,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '183 MAHINA ST',
        RegAddrCity: 'KIHEI',
        RegAddrState: 'HI',
        RegAddrZip: 96753,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '290 PUALOA NANI PL',
        RegAddrCity: 'KIHEI',
        RegAddrState: 'HI',
        RegAddrZip: 96753,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '308 WAINOHIA ST',
        RegAddrCity: 'KIHEI',
        RegAddrState: 'HI',
        RegAddrZip: 96753,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '140 UWAPO RD',
        RegAddrCity: 'KIHEI',
        RegAddrState: 'HI',
        RegAddrZip: 96753,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '16 HAUMEA PL',
        RegAddrCity: 'KIHEI',
        RegAddrState: 'HI',
        RegAddrZip: 96753,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '304 KAIOLOHIA ST',
        RegAddrCity: 'KIHEI',
        RegAddrState: 'HI',
        RegAddrZip: 96753,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '4620 KAPUNA RD',
        RegAddrCity: 'KILAUEA',
        RegAddrState: 'HI',
        RegAddrZip: 96754,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '3890 KOLOA RD',
        RegAddrCity: 'KOLOA',
        RegAddrState: 'HI',
        RegAddrZip: 96756,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '806 COPP RD',
        RegAddrCity: 'KULA',
        RegAddrState: 'HI',
        RegAddrZip: 96790,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '17526 HALEAKALA HWY',
        RegAddrCity: 'KULA',
        RegAddrState: 'HI',
        RegAddrZip: 96790,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '17-586 PUKO ST',
        RegAddrCity: 'KURTISTOWN',
        RegAddrState: 'HI',
        RegAddrZip: 96760,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '3626 LOWER HONOAPIILANI RD',
        RegAddrCity: 'LAHAINA',
        RegAddrState: 'HI',
        RegAddrZip: 96761,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '940 N NIHEU PL',
        RegAddrCity: 'LAHAINA',
        RegAddrState: 'HI',
        RegAddrZip: 96761,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1243 QUEENS ST',
        RegAddrCity: 'LANAI CITY',
        RegAddrState: 'HI',
        RegAddrZip: 96763,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '36-157 MANOWAIOPAE HMSTD RD',
        RegAddrCity: 'LAUPAHOEHOE',
        RegAddrState: 'HI',
        RegAddrZip: 96764,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '4540 KALEPA CIR',
        RegAddrCity: 'LIHUE',
        RegAddrState: 'HI',
        RegAddrZip: 96766,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '4276 ONO ST',
        RegAddrCity: 'LIHUE',
        RegAddrState: 'HI',
        RegAddrZip: 96766,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '178 ELILANI ST',
        RegAddrCity: 'MAKAWAO',
        RegAddrState: 'HI',
        RegAddrZip: 96768,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '701 ONIPAA PL',
        RegAddrCity: 'MAKAWAO',
        RegAddrState: 'HI',
        RegAddrZip: 96768,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: 'PAKALA',
        RegAddrCity: 'MAKAWELI',
        RegAddrState: 'HI',
        RegAddrZip: 96769,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: 'MAUNALOA VLG',
        RegAddrCity: 'MAUNALOA',
        RegAddrState: 'HI',
        RegAddrZip: 96770,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '31-376 GOVERNMENT RD',
        RegAddrCity: 'NINOLE',
        RegAddrState: 'HI',
        RegAddrZip: 96773,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '92-8897 LEILANI PKWY',
        RegAddrCity: 'OCEAN VIEW',
        RegAddrState: 'HI',
        RegAddrZip: 96737,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '15-2698 KAWAKAWA ST',
        RegAddrCity: 'PAHOA',
        RegAddrState: 'HI',
        RegAddrZip: 96778,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '13-3474 POMAIKAI ST',
        RegAddrCity: 'PAHOA',
        RegAddrState: 'HI',
        RegAddrZip: 96778,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '13-3493 MAILE ST',
        RegAddrCity: 'PAHOA',
        RegAddrState: 'HI',
        RegAddrZip: 96778,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '14-682 KEHAU RD',
        RegAddrCity: 'PAHOA',
        RegAddrState: 'HI',
        RegAddrZip: 96778,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '15-2757 OPAKAPAKA ST',
        RegAddrCity: 'PAHOA',
        RegAddrState: 'HI',
        RegAddrZip: 96778,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '293 HANA HWY',
        RegAddrCity: 'PAIA',
        RegAddrState: 'HI',
        RegAddrZip: 96779,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '27-300A OLD MAMALAHOA HWY',
        RegAddrCity: 'PAPAIKOU',
        RegAddrState: 'HI',
        RegAddrZip: 96781,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1691 CALIFORNIA AVE',
        RegAddrCity: 'WAHIAWA',
        RegAddrState: 'HI',
        RegAddrZip: 96786,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '225 ILIMA ST',
        RegAddrCity: 'WAHIAWA',
        RegAddrState: 'HI',
        RegAddrZip: 96786,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '68-270 CROZIER LOOP',
        RegAddrCity: 'WAIALUA',
        RegAddrState: 'HI',
        RegAddrZip: 96791,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '68-315 MAHINAAI ST',
        RegAddrCity: 'WAIALUA',
        RegAddrState: 'HI',
        RegAddrZip: 96791,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '89-363 PUA AVE',
        RegAddrCity: 'WAIANAE',
        RegAddrState: 'HI',
        RegAddrZip: 96792,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '85-1341 KAPAEKAHI ST',
        RegAddrCity: 'WAIANAE',
        RegAddrState: 'HI',
        RegAddrZip: 96792,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '87-203 AUYONG HOMESTEAD RD',
        RegAddrCity: 'WAIANAE',
        RegAddrState: 'HI',
        RegAddrZip: 96792,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '87-154 WAIOLU ST',
        RegAddrCity: 'WAIANAE',
        RegAddrState: 'HI',
        RegAddrZip: 96792,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '85-926 IMIPONO ST',
        RegAddrCity: 'WAIANAE',
        RegAddrState: 'HI',
        RegAddrZip: 96792,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '87-124 KULAHELELA PL',
        RegAddrCity: 'WAIANAE',
        RegAddrState: 'HI',
        RegAddrZip: 96792,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '84-158 ORANGE ST',
        RegAddrCity: 'WAIANAE',
        RegAddrState: 'HI',
        RegAddrZip: 96792,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '143 EULU PL',
        RegAddrCity: 'WAILUKU',
        RegAddrState: 'HI',
        RegAddrZip: 96793,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '32 KOPI LN',
        RegAddrCity: 'WAILUKU',
        RegAddrState: 'HI',
        RegAddrZip: 96793,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '133 PONIU CIR',
        RegAddrCity: 'WAILUKU',
        RegAddrState: 'HI',
        RegAddrZip: 96793,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2501 MAIN ST',
        RegAddrCity: 'WAILUKU',
        RegAddrState: 'HI',
        RegAddrZip: 96793,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '48 KOANI LOOP',
        RegAddrCity: 'WAILUKU',
        RegAddrState: 'HI',
        RegAddrZip: 96793,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '41-1394 KUMUULA ST',
        RegAddrCity: 'WAIMANALO',
        RegAddrState: 'HI',
        RegAddrZip: 96795,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '41-696 KAAUMANA PL',
        RegAddrCity: 'WAIMANALO',
        RegAddrState: 'HI',
        RegAddrZip: 96795,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '41-235 HULI ST',
        RegAddrCity: 'WAIMANALO',
        RegAddrState: 'HI',
        RegAddrZip: 96795,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '41-909 KALANIANAOLE HWY',
        RegAddrCity: 'WAIMANALO',
        RegAddrState: 'HI',
        RegAddrZip: 96795,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: 'WAIMEA',
        RegAddrCity: 'WAIMEA',
        RegAddrState: 'HI',
        RegAddrZip: 96796,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '94-979 KAAHOLO ST',
        RegAddrCity: 'WAIPAHU',
        RegAddrState: 'HI',
        RegAddrZip: 96797,
        CongressionalDistrict: 2,
      },
    ],
  },
  {
    name: 'IA-1',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/IA-1?ref=gh-pages',
    state: 'IA',
    state_and_number: 'IA-1',
    zip: 50005,
    congressionalDistrict: 1,
    addressesInDistrict: [
      {
        RegAddrLine1: '2401 BURNETT STATION RD',
        RegAddrCity: 'ALBURNETT',
        RegAddrState: 'IA',
        RegAddrZip: 52202,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '12940 AMBER ROAD X44',
        RegAddrCity: 'ANAMOSA',
        RegAddrState: 'IA',
        RegAddrZip: 52205,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1216 SLATER AVE',
        RegAddrCity: 'AURORA',
        RegAddrState: 'IA',
        RegAddrZip: 50607,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '6305 STREFF RD',
        RegAddrCity: 'BELLEVUE',
        RegAddrState: 'IA',
        RegAddrZip: 52031,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '114 E 10TH ST',
        RegAddrCity: 'CEDAR FALLS',
        RegAddrState: 'IA',
        RegAddrZip: 50613,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '3925 STURGIS DR',
        RegAddrCity: 'CEDAR FALLS',
        RegAddrState: 'IA',
        RegAddrZip: 50613,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2219 COVENTRY LN',
        RegAddrCity: 'CEDAR FALLS',
        RegAddrState: 'IA',
        RegAddrZip: 50613,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '143 37TH ST NE',
        RegAddrCity: 'CEDAR RAPIDS',
        RegAddrState: 'IA',
        RegAddrZip: 52402,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1565 LINMAR DR NE',
        RegAddrCity: 'CEDAR RAPIDS',
        RegAddrState: 'IA',
        RegAddrZip: 52402,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '7012 PARKDALE LN NE',
        RegAddrCity: 'CEDAR RAPIDS',
        RegAddrState: 'IA',
        RegAddrZip: 52402,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1708 4TH AVE SE',
        RegAddrCity: 'CEDAR RAPIDS',
        RegAddrState: 'IA',
        RegAddrZip: 52403,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '225 GAS LIGHT CT SW',
        RegAddrCity: 'CEDAR RAPIDS',
        RegAddrState: 'IA',
        RegAddrZip: 52404,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '107 MITCHELL ST SW',
        RegAddrCity: 'CEDAR RAPIDS',
        RegAddrState: 'IA',
        RegAddrZip: 52404,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2623 33RD AVE SW',
        RegAddrCity: 'CEDAR RAPIDS',
        RegAddrState: 'IA',
        RegAddrZip: 52404,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '402 SAINT OLAF ST SW',
        RegAddrCity: 'CEDAR RAPIDS',
        RegAddrState: 'IA',
        RegAddrZip: 52404,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '293 25TH AVE SW',
        RegAddrCity: 'CEDAR RAPIDS',
        RegAddrState: 'IA',
        RegAddrZip: 52404,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '50 27TH ST NW',
        RegAddrCity: 'CEDAR RAPIDS',
        RegAddrState: 'IA',
        RegAddrZip: 52405,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '4710 MONTCLAIR DR NW',
        RegAddrCity: 'CEDAR RAPIDS',
        RegAddrState: 'IA',
        RegAddrZip: 52405,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '253 HIGHLAND DR NW',
        RegAddrCity: 'CEDAR RAPIDS',
        RegAddrState: 'IA',
        RegAddrZip: 52405,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1243 APACHE TRL NW',
        RegAddrCity: 'CEDAR RAPIDS',
        RegAddrState: 'IA',
        RegAddrZip: 52405,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1228 KOUDSI BLVD NW',
        RegAddrCity: 'CEDAR RAPIDS',
        RegAddrState: 'IA',
        RegAddrZip: 52405,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1030 YUMA CT NW',
        RegAddrCity: 'CEDAR RAPIDS',
        RegAddrState: 'IA',
        RegAddrZip: 52405,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '103 MAIDEN ST',
        RegAddrCity: 'CENTER POINT',
        RegAddrState: 'IA',
        RegAddrZip: 52213,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '711 2ND AVE E',
        RegAddrCity: 'CRESCO',
        RegAddrState: 'IA',
        RegAddrZip: 52136,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '807 MAPLE AVE',
        RegAddrCity: 'DECORAH',
        RegAddrState: 'IA',
        RegAddrZip: 52101,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2572 PINE CREEK RD',
        RegAddrCity: 'DECORAH',
        RegAddrState: 'IA',
        RegAddrZip: 52101,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '200 LUTHERLARSON',
        RegAddrCity: 'DECORAH',
        RegAddrState: 'IA',
        RegAddrZip: 52101,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1280 BELMONT ST',
        RegAddrCity: 'DUBUQUE',
        RegAddrState: 'IA',
        RegAddrZip: 52001,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '3340 WINDSOR AVE',
        RegAddrCity: 'DUBUQUE',
        RegAddrState: 'IA',
        RegAddrZip: 52001,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1954 ELLIS ST',
        RegAddrCity: 'DUBUQUE',
        RegAddrState: 'IA',
        RegAddrZip: 52001,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '3225 ASBURY RD',
        RegAddrCity: 'DUBUQUE',
        RegAddrState: 'IA',
        RegAddrZip: 52001,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2330 CLARKE CREST DR',
        RegAddrCity: 'DUBUQUE',
        RegAddrState: 'IA',
        RegAddrZip: 52001,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1996 MADISON ST',
        RegAddrCity: 'DUBUQUE',
        RegAddrState: 'IA',
        RegAddrZip: 52001,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2868 INDIANA AVE',
        RegAddrCity: 'DUBUQUE',
        RegAddrState: 'IA',
        RegAddrZip: 52001,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2811 JOHN F KENNEDY RD',
        RegAddrCity: 'DUBUQUE',
        RegAddrState: 'IA',
        RegAddrZip: 52002,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2265 YORKTOWN RD',
        RegAddrCity: 'DUBUQUE',
        RegAddrState: 'IA',
        RegAddrZip: 52002,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '3486 KIMBERLY DR',
        RegAddrCity: 'DUBUQUE',
        RegAddrState: 'IA',
        RegAddrZip: 52002,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2018 N NESBIT RD',
        RegAddrCity: 'DUNKERTON',
        RegAddrState: 'IA',
        RegAddrZip: 50626,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '202 9TH ST SE',
        RegAddrCity: 'DYERSVILLE',
        RegAddrState: 'IA',
        RegAddrZip: 52040,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '3213 HIGHWAY 8',
        RegAddrCity: 'DYSART',
        RegAddrState: 'IA',
        RegAddrZip: 52224,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '401 STATE ST',
        RegAddrCity: 'DYSART',
        RegAddrState: 'IA',
        RegAddrZip: 52224,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '806 SWITCH ST',
        RegAddrCity: 'ELGIN',
        RegAddrState: 'IA',
        RegAddrZip: 52141,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '23022 293RD ST',
        RegAddrCity: 'ELKADER',
        RegAddrState: 'IA',
        RegAddrZip: 52043,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '',
        RegAddrCity: 'ELY',
        RegAddrState: 'IA',
        RegAddrZip: 52227,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '208 OAK FOREST DR',
        RegAddrCity: 'EPWORTH',
        RegAddrState: 'IA',
        RegAddrZip: 52045,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '105 DIXIE CIR',
        RegAddrCity: 'EVANSDALE',
        RegAddrState: 'IA',
        RegAddrZip: 50707,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '30156 NICKEL RD',
        RegAddrCity: 'FORT ATKINSON',
        RegAddrState: 'IA',
        RegAddrZip: 52144,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '602 6TH ST',
        RegAddrCity: 'GLADBROOK',
        RegAddrState: 'IA',
        RegAddrZip: 50635,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '816 ASPEN DR',
        RegAddrCity: 'GRINNELL',
        RegAddrState: 'IA',
        RegAddrZip: 50112,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2007 REED ST',
        RegAddrCity: 'GRINNELL',
        RegAddrState: 'IA',
        RegAddrZip: 50112,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '811 8TH AVE',
        RegAddrCity: 'GRINNELL',
        RegAddrState: 'IA',
        RegAddrZip: 50112,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '39647 CLAYTON RD',
        RegAddrCity: 'GUTTENBERG',
        RegAddrState: 'IA',
        RegAddrZip: 52052,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '24439 JONES DELAWARE RD',
        RegAddrCity: 'HOPKINTON',
        RegAddrState: 'IA',
        RegAddrZip: 52237,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '615 7TH AVE NE',
        RegAddrCity: 'INDEPENDENCE',
        RegAddrState: 'IA',
        RegAddrZip: 50644,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '600 2ND ST SE',
        RegAddrCity: 'INDEPENDENCE',
        RegAddrState: 'IA',
        RegAddrZip: 50644,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2751 BENSON SHADY GROVE AVE',
        RegAddrCity: 'JESUP',
        RegAddrState: 'IA',
        RegAddrZip: 50648,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1132 282ND ST',
        RegAddrCity: 'JESUP',
        RegAddrState: 'IA',
        RegAddrZip: 50648,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '204 KEERL ST',
        RegAddrCity: 'JOICE',
        RegAddrState: 'IA',
        RegAddrZip: 50446,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '7181 OLDE DAVENPORT RD',
        RegAddrCity: 'LA MOTTE',
        RegAddrState: 'IA',
        RegAddrZip: 52054,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '207 N OLIVE ST',
        RegAddrCity: 'MAQUOKETA',
        RegAddrState: 'IA',
        RegAddrZip: 52060,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '96 COLGATE AVE',
        RegAddrCity: 'MAQUOKETA',
        RegAddrState: 'IA',
        RegAddrZip: 52060,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '3163 233RD AVE',
        RegAddrCity: 'MAQUOKETA',
        RegAddrState: 'IA',
        RegAddrZip: 52060,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '413 N DEARBORN ST',
        RegAddrCity: 'MAQUOKETA',
        RegAddrState: 'IA',
        RegAddrZip: 52060,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2580 79TH STREET DR',
        RegAddrCity: 'MARENGO',
        RegAddrState: 'IA',
        RegAddrZip: 52301,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '3237 PRAIRIE BEND CIR',
        RegAddrCity: 'MARION',
        RegAddrState: 'IA',
        RegAddrZip: 52302,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '5485 WOODBRIDGE CRST',
        RegAddrCity: 'MARION',
        RegAddrState: 'IA',
        RegAddrZip: 52302,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '896 CROSSBOW CT',
        RegAddrCity: 'MARION',
        RegAddrState: 'IA',
        RegAddrZip: 52302,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2715 CLARK AVE',
        RegAddrCity: 'MARION',
        RegAddrState: 'IA',
        RegAddrZip: 52302,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1750 COUNTRY CLUB DR',
        RegAddrCity: 'MARION',
        RegAddrState: 'IA',
        RegAddrZip: 52302,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '211 E FERNER ST',
        RegAddrCity: 'MARSHALLTOWN',
        RegAddrState: 'IA',
        RegAddrZip: 50158,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1213 E BOONE ST',
        RegAddrCity: 'MARSHALLTOWN',
        RegAddrState: 'IA',
        RegAddrZip: 50158,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '10 W NORTH ST',
        RegAddrCity: 'MARSHALLTOWN',
        RegAddrState: 'IA',
        RegAddrZip: 50158,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '210 N 14TH AVE',
        RegAddrCity: 'MARSHALLTOWN',
        RegAddrState: 'IA',
        RegAddrZip: 50158,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '206 S EGBERT ST',
        RegAddrCity: 'MONONA',
        RegAddrState: 'IA',
        RegAddrZip: 52159,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '105 S PAGE ST',
        RegAddrCity: 'MONONA',
        RegAddrState: 'IA',
        RegAddrZip: 52159,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '442 N CEDAR ST',
        RegAddrCity: 'MONTICELLO',
        RegAddrState: 'IA',
        RegAddrZip: 52310,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: 'PO BOX 513',
        RegAddrCity: 'MONTICELLO',
        RegAddrState: 'IA',
        RegAddrZip: 52310,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '233 N CHESTNUT ST',
        RegAddrCity: 'MONTICELLO',
        RegAddrState: 'IA',
        RegAddrZip: 52310,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1318 GABRIEL CT',
        RegAddrCity: 'MOUNT VERNON',
        RegAddrState: 'IA',
        RegAddrZip: 52314,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '4824 MALLARD AVE',
        RegAddrCity: 'NORTHWOOD',
        RegAddrState: 'IA',
        RegAddrZip: 50459,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '400 LINCOLN DR',
        RegAddrCity: 'OELWEIN',
        RegAddrState: 'IA',
        RegAddrZip: 50662,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '510 BROUGHAM RD',
        RegAddrCity: 'ROBINS',
        RegAddrState: 'IA',
        RegAddrZip: 52328,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '411 MAIN ST NW',
        RegAddrCity: 'SHELLSBURG',
        RegAddrState: 'IA',
        RegAddrZip: 52332,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '309 OAK ST',
        RegAddrCity: 'SPILLVILLE',
        RegAddrState: 'IA',
        RegAddrZip: 52168,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '155 5TH ST N',
        RegAddrCity: 'SPRINGVILLE',
        RegAddrState: 'IA',
        RegAddrZip: 52336,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1519 YUKON AVE',
        RegAddrCity: 'SUMNER',
        RegAddrState: 'IA',
        RegAddrZip: 50674,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '302 W 16TH ST',
        RegAddrCity: 'TAMA',
        RegAddrState: 'IA',
        RegAddrZip: 52339,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '306 5TH AVE SW',
        RegAddrCity: 'TRIPOLI',
        RegAddrState: 'IA',
        RegAddrZip: 50676,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '605 RICHLAND AVE',
        RegAddrCity: 'URBANA',
        RegAddrState: 'IA',
        RegAddrZip: 52345,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '17 S MILL ST',
        RegAddrCity: 'WALKER',
        RegAddrState: 'IA',
        RegAddrZip: 52352,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1909 WEMBLEY RD',
        RegAddrCity: 'WATERLOO',
        RegAddrState: 'IA',
        RegAddrZip: 50701,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1840 BLACK HAWK RD',
        RegAddrCity: 'WATERLOO',
        RegAddrState: 'IA',
        RegAddrZip: 50701,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1626 GARDEN AVE',
        RegAddrCity: 'WATERLOO',
        RegAddrState: 'IA',
        RegAddrZip: 50701,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1723 ROBIN RD',
        RegAddrCity: 'WATERLOO',
        RegAddrState: 'IA',
        RegAddrZip: 50701,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1723 KIMBALL AVE',
        RegAddrCity: 'WATERLOO',
        RegAddrState: 'IA',
        RegAddrZip: 50702,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '622 WELLINGTON CT',
        RegAddrCity: 'WATERLOO',
        RegAddrState: 'IA',
        RegAddrZip: 50702,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '901 HAMMOND AVE',
        RegAddrCity: 'WATERLOO',
        RegAddrState: 'IA',
        RegAddrZip: 50702,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '237 MONROE ST',
        RegAddrCity: 'WATERLOO',
        RegAddrState: 'IA',
        RegAddrZip: 50703,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '6211 LOGAN AVE',
        RegAddrCity: 'WATERLOO',
        RegAddrState: 'IA',
        RegAddrZip: 50703,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '633 BOSTON AVE',
        RegAddrCity: 'WATERLOO',
        RegAddrState: 'IA',
        RegAddrZip: 50703,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '520 CUTLER ST',
        RegAddrCity: 'WATERLOO',
        RegAddrState: 'IA',
        RegAddrZip: 50703,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '819 ADRIAN ST',
        RegAddrCity: 'WATERLOO',
        RegAddrState: 'IA',
        RegAddrZip: 50703,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '810 CRESTON AVE',
        RegAddrCity: 'WATERLOO',
        RegAddrState: 'IA',
        RegAddrZip: 50707,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1311 3RD AVE SW',
        RegAddrCity: 'WAVERLY',
        RegAddrState: 'IA',
        RegAddrZip: 50677,
        CongressionalDistrict: 1,
      },
    ],
  },
  {
    name: 'IA-2',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/IA-2?ref=gh-pages',
    state: 'IA',
    state_and_number: 'IA-2',
    zip: 50008,
    congressionalDistrict: 2,
    addressesInDistrict: [
      {
        RegAddrLine1: '1366 WASHINGTON ST',
        RegAddrCity: 'ANDOVER',
        RegAddrState: 'IA',
        RegAddrZip: 52701,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '503 3RD ST',
        RegAddrCity: 'BATAVIA',
        RegAddrState: 'IA',
        RegAddrZip: 52533,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '4471 THORNWOOD DR',
        RegAddrCity: 'BETTENDORF',
        RegAddrState: 'IA',
        RegAddrZip: 52722,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '3608 OAKWOOD DR',
        RegAddrCity: 'BETTENDORF',
        RegAddrState: 'IA',
        RegAddrZip: 52722,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1308 LINCOLN PL',
        RegAddrCity: 'BETTENDORF',
        RegAddrState: 'IA',
        RegAddrZip: 52722,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1245 DENNISTON AVE',
        RegAddrCity: 'BETTENDORF',
        RegAddrState: 'IA',
        RegAddrZip: 52722,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '4918 NORFOLK PL',
        RegAddrCity: 'BETTENDORF',
        RegAddrState: 'IA',
        RegAddrZip: 52722,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '307 PARKVIEW DR',
        RegAddrCity: 'BLOOMFIELD',
        RegAddrState: 'IA',
        RegAddrZip: 52537,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2011 S 4TH ST',
        RegAddrCity: 'BURLINGTON',
        RegAddrState: 'IA',
        RegAddrZip: 52601,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '5149 FERRES LN',
        RegAddrCity: 'BURLINGTON',
        RegAddrState: 'IA',
        RegAddrZip: 52601,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '4301 TOPAZ AVE NW',
        RegAddrCity: 'CEDAR RAPIDS',
        RegAddrState: 'IA',
        RegAddrZip: 52405,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1208 E CROSS ST',
        RegAddrCity: 'CENTERVILLE',
        RegAddrState: 'IA',
        RegAddrZip: 52544,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '625 LINDEN AVE',
        RegAddrCity: 'CHARITON',
        RegAddrState: 'IA',
        RegAddrZip: 50049,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '717 S 4TH ST',
        RegAddrCity: 'CHARITON',
        RegAddrState: 'IA',
        RegAddrZip: 50049,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1778 290TH AVE',
        RegAddrCity: 'CHARLOTTE',
        RegAddrState: 'IA',
        RegAddrZip: 52731,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1923 ROOSEVELT ST',
        RegAddrCity: 'CLINTON',
        RegAddrState: 'IA',
        RegAddrZip: 52732,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1645 27TH AVE S',
        RegAddrCity: 'CLINTON',
        RegAddrState: 'IA',
        RegAddrZip: 52732,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '4308 190TH ST',
        RegAddrCity: 'CLINTON',
        RegAddrState: 'IA',
        RegAddrZip: 52732,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2404 IOWA ST',
        RegAddrCity: 'COLUMBUS CITY',
        RegAddrState: 'IA',
        RegAddrZip: 52737,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '605 FAIRCHILD ST',
        RegAddrCity: 'CORALVILLE',
        RegAddrState: 'IA',
        RegAddrZip: 52241,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '615 12TH AVE',
        RegAddrCity: 'CORALVILLE',
        RegAddrState: 'IA',
        RegAddrZip: 52241,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '708 12TH AVE',
        RegAddrCity: 'CORALVILLE',
        RegAddrState: 'IA',
        RegAddrZip: 52241,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '502 W JEFFERSON ST',
        RegAddrCity: 'CORYDON',
        RegAddrState: 'IA',
        RegAddrZip: 50060,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '3827 HOBSON AVE',
        RegAddrCity: 'DAVENPORT',
        RegAddrState: 'IA',
        RegAddrZip: 52802,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '3147 MARION ST',
        RegAddrCity: 'DAVENPORT',
        RegAddrState: 'IA',
        RegAddrZip: 52802,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2015 CLAUSSEN ST',
        RegAddrCity: 'DAVENPORT',
        RegAddrState: 'IA',
        RegAddrZip: 52802,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2326 REDWOOD AVE',
        RegAddrCity: 'DAVENPORT',
        RegAddrState: 'IA',
        RegAddrZip: 52802,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '401 MCCLELLAN BLVD',
        RegAddrCity: 'DAVENPORT',
        RegAddrState: 'IA',
        RegAddrZip: 52803,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '612 WAVERLY RD',
        RegAddrCity: 'DAVENPORT',
        RegAddrState: 'IA',
        RegAddrZip: 52804,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '6436 CEDAR ST',
        RegAddrCity: 'DAVENPORT',
        RegAddrState: 'IA',
        RegAddrZip: 52806,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '6814 RIDGE DR',
        RegAddrCity: 'DAVENPORT',
        RegAddrState: 'IA',
        RegAddrZip: 52806,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2709 W 34TH ST',
        RegAddrCity: 'DAVENPORT',
        RegAddrState: 'IA',
        RegAddrZip: 52806,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '6145 LAKEHURST DR',
        RegAddrCity: 'DAVENPORT',
        RegAddrState: 'IA',
        RegAddrZip: 52807,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2027 MEADOWBROOK RD',
        RegAddrCity: 'DAVENPORT',
        RegAddrState: 'IA',
        RegAddrZip: 52807,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2520 E 46TH ST',
        RegAddrCity: 'DAVENPORT',
        RegAddrState: 'IA',
        RegAddrZip: 52807,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '18 NICHOLAS CT',
        RegAddrCity: 'ELDRIDGE',
        RegAddrState: 'IA',
        RegAddrZip: 52748,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '701 S 8TH ST',
        RegAddrCity: 'ELDRIDGE',
        RegAddrState: 'IA',
        RegAddrZip: 52748,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '603 E BUCHANAN AVE',
        RegAddrCity: 'FAIRFIELD',
        RegAddrState: 'IA',
        RegAddrZip: 52556,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1101 E BROADWAY AVE',
        RegAddrCity: 'FAIRFIELD',
        RegAddrState: 'IA',
        RegAddrZip: 52556,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '808 W TAYLOR AVE',
        RegAddrCity: 'FAIRFIELD',
        RegAddrState: 'IA',
        RegAddrZip: 52556,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '105 CINDER AVE',
        RegAddrCity: 'HARVEY',
        RegAddrState: 'IA',
        RegAddrZip: 50119,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '207 N WAUGH ST',
        RegAddrCity: 'HEDRICK',
        RegAddrState: 'IA',
        RegAddrZip: 52563,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '402 1/2 S LUCAS ST',
        RegAddrCity: 'IOWA CITY',
        RegAddrState: 'IA',
        RegAddrZip: 52240,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1141 E COLLEGE ST',
        RegAddrCity: 'IOWA CITY',
        RegAddrState: 'IA',
        RegAddrZip: 52240,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '3023 CORNELL AVE',
        RegAddrCity: 'IOWA CITY',
        RegAddrState: 'IA',
        RegAddrZip: 52245,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1822 ROCHESTER AVE',
        RegAddrCity: 'IOWA CITY',
        RegAddrState: 'IA',
        RegAddrZip: 52245,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1022 SUNSET ST',
        RegAddrCity: 'IOWA CITY',
        RegAddrState: 'IA',
        RegAddrZip: 52246,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '8 WESTGATE CIR',
        RegAddrCity: 'IOWA CITY',
        RegAddrState: 'IA',
        RegAddrZip: 52246,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2305 GINKGO AVE',
        RegAddrCity: 'KEOTA',
        RegAddrState: 'IA',
        RegAddrZip: 52248,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1467 S SHORE DR',
        RegAddrCity: 'KNOXVILLE',
        RegAddrState: 'IA',
        RegAddrZip: 50138,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1706 W LARSON ST',
        RegAddrCity: 'KNOXVILLE',
        RegAddrState: 'IA',
        RegAddrZip: 50138,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '713 S DAYTON ST',
        RegAddrCity: 'KNOXVILLE',
        RegAddrState: 'IA',
        RegAddrZip: 50138,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '25560 225TH ST',
        RegAddrCity: 'LECLAIRE',
        RegAddrState: 'IA',
        RegAddrZip: 52753,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '406 SE VINE ST',
        RegAddrCity: 'LEON',
        RegAddrState: 'IA',
        RegAddrZip: 50144,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '29290 171ST AVE',
        RegAddrCity: 'LONG GROVE',
        RegAddrState: 'IA',
        RegAddrZip: 52756,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '115 S WASHINGTON ST',
        RegAddrCity: 'MEDIAPOLIS',
        RegAddrState: 'IA',
        RegAddrZip: 52637,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '711 W WASHINGTON ST',
        RegAddrCity: 'MONROE',
        RegAddrState: 'IA',
        RegAddrZip: 50170,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '3030 HIGHWAY 218',
        RegAddrCity: 'MONTROSE',
        RegAddrState: 'IA',
        RegAddrZip: 52639,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '404 W ORANGE ST',
        RegAddrCity: 'MT PLEASANT',
        RegAddrState: 'IA',
        RegAddrZip: 52641,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '723 E BURKY LN',
        RegAddrCity: 'MT PLEASANT',
        RegAddrState: 'IA',
        RegAddrZip: 52641,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '608 E WARREN ST',
        RegAddrCity: 'MT PLEASANT',
        RegAddrState: 'IA',
        RegAddrZip: 52641,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '410 GREEN ST',
        RegAddrCity: 'MUSCATINE',
        RegAddrState: 'IA',
        RegAddrZip: 52761,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '616 CLIMER ST',
        RegAddrCity: 'MUSCATINE',
        RegAddrState: 'IA',
        RegAddrZip: 52761,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '714 LEROY ST',
        RegAddrCity: 'MUSCATINE',
        RegAddrState: 'IA',
        RegAddrZip: 52761,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '119 PARK AVE',
        RegAddrCity: 'MUSCATINE',
        RegAddrState: 'IA',
        RegAddrZip: 52761,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1893 215TH ST',
        RegAddrCity: 'MUSCATINE',
        RegAddrState: 'IA',
        RegAddrZip: 52761,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2203 DEMOREST AVE',
        RegAddrCity: 'MUSCATINE',
        RegAddrState: 'IA',
        RegAddrZip: 52761,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '625 MAIN ST',
        RegAddrCity: 'NEW LIBERTY',
        RegAddrState: 'IA',
        RegAddrZip: 52765,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1191 IRVINE AVE',
        RegAddrCity: 'NEW SHARON',
        RegAddrState: 'IA',
        RegAddrZip: 50207,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '824 W 4TH ST S',
        RegAddrCity: 'NEWTON',
        RegAddrState: 'IA',
        RegAddrZip: 50208,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '210 E 28TH ST S',
        RegAddrCity: 'NEWTON',
        RegAddrState: 'IA',
        RegAddrZip: 50208,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '302 E 17TH STREET PL N',
        RegAddrCity: 'NEWTON',
        RegAddrState: 'IA',
        RegAddrZip: 50208,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '178 GOLFVIEW CT',
        RegAddrCity: 'NORTH LIBERTY',
        RegAddrState: 'IA',
        RegAddrZip: 52317,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2943 MERINO AVE',
        RegAddrCity: 'OSKALOOSA',
        RegAddrState: 'IA',
        RegAddrZip: 52577,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '310 TAFT AVE',
        RegAddrCity: 'OTTUMWA',
        RegAddrState: 'IA',
        RegAddrZip: 52501,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '10356 MILLER CHAPEL RD',
        RegAddrCity: 'OTTUMWA',
        RegAddrState: 'IA',
        RegAddrZip: 52501,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '202 E WOODLAND AVE',
        RegAddrCity: 'OTTUMWA',
        RegAddrState: 'IA',
        RegAddrZip: 52501,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '306 N MCLEAN ST',
        RegAddrCity: 'OTTUMWA',
        RegAddrState: 'IA',
        RegAddrZip: 52501,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '220 W 6TH ST',
        RegAddrCity: 'OTTUMWA',
        RegAddrState: 'IA',
        RegAddrZip: 52501,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1604 NEIL DR',
        RegAddrCity: 'PELLA',
        RegAddrState: 'IA',
        RegAddrZip: 50219,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1468 ADAMS AVE',
        RegAddrCity: 'PELLA',
        RegAddrState: 'IA',
        RegAddrZip: 50219,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '814 PARK LN',
        RegAddrCity: 'PELLA',
        RegAddrState: 'IA',
        RegAddrZip: 50219,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '516 3RD ST',
        RegAddrCity: 'PRINCETON',
        RegAddrState: 'IA',
        RegAddrZip: 52768,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '112 KLEOPFER AVE',
        RegAddrCity: 'RIVERSIDE',
        RegAddrState: 'IA',
        RegAddrZip: 52327,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1540 PALM AVE',
        RegAddrCity: 'RIVERSIDE',
        RegAddrState: 'IA',
        RegAddrZip: 52327,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '3067 230TH CIR',
        RegAddrCity: 'ROSE HILL',
        RegAddrState: 'IA',
        RegAddrZip: 52586,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '29494 537TH ST',
        RegAddrCity: 'RUSSELL',
        RegAddrState: 'IA',
        RegAddrZip: 50238,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1137 JAMES AVE NE',
        RegAddrCity: 'SWISHER',
        RegAddrState: 'IA',
        RegAddrZip: 52338,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1614 COU FALLS RD NW',
        RegAddrCity: 'SWISHER',
        RegAddrState: 'IA',
        RegAddrZip: 52338,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '140 MAPLE ST SW',
        RegAddrCity: 'SWISHER',
        RegAddrState: 'IA',
        RegAddrZip: 52338,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2830 340TH ST SW',
        RegAddrCity: 'TIFFIN',
        RegAddrState: 'IA',
        RegAddrZip: 52340,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '568 DAKOTA AVE',
        RegAddrCity: 'TIFFIN',
        RegAddrState: 'IA',
        RegAddrZip: 52340,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '508 HORIZON DR',
        RegAddrCity: 'TIPTON',
        RegAddrState: 'IA',
        RegAddrZip: 52772,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1307 S GEAR AVE',
        RegAddrCity: 'W BURLINGTON',
        RegAddrState: 'IA',
        RegAddrZip: 52655,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '812 RAMSEY ST',
        RegAddrCity: 'W BURLINGTON',
        RegAddrState: 'IA',
        RegAddrZip: 52655,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '430 N 2ND ST',
        RegAddrCity: 'WAPELLO',
        RegAddrState: 'IA',
        RegAddrZip: 52653,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2830 COPPOCK RD',
        RegAddrCity: 'WASHINGTON',
        RegAddrState: 'IA',
        RegAddrZip: 52353,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '130 SAGERT DR',
        RegAddrCity: 'WEST BRANCH',
        RegAddrState: 'IA',
        RegAddrZip: 52358,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '419 W 4TH ST',
        RegAddrCity: 'WILTON',
        RegAddrState: 'IA',
        RegAddrZip: 52778,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '313 W 4TH ST',
        RegAddrCity: 'WILTON',
        RegAddrState: 'IA',
        RegAddrZip: 52778,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '305 S OLIVE ST',
        RegAddrCity: 'WINFIELD',
        RegAddrState: 'IA',
        RegAddrZip: 52659,
        CongressionalDistrict: 2,
      },
    ],
  },
  {
    name: 'IA-3',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/IA-3?ref=gh-pages',
    state: 'IA',
    state_and_number: 'IA-3',
    zip: 50001,
    congressionalDistrict: 3,
    addressesInDistrict: [
      {
        RegAddrLine1: '18144 JERSEY TRL',
        RegAddrCity: 'ACKWORTH',
        RegAddrState: 'IA',
        RegAddrZip: 50001,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '505 AUDUBON ST',
        RegAddrCity: 'ADAIR',
        RegAddrState: 'IA',
        RegAddrZip: 50002,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1813 3RD AVE SE',
        RegAddrCity: 'ALTOONA',
        RegAddrState: 'IA',
        RegAddrZip: 50009,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1553 5TH AVE SW',
        RegAddrCity: 'ALTOONA',
        RegAddrState: 'IA',
        RegAddrZip: 50009,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '3211 NE RENAISSANCE DR',
        RegAddrCity: 'ANKENY',
        RegAddrState: 'IA',
        RegAddrZip: 50021,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '9499 NE 28TH CT',
        RegAddrCity: 'ANKENY',
        RegAddrState: 'IA',
        RegAddrZip: 50021,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '313 SE 3RD ST',
        RegAddrCity: 'ANKENY',
        RegAddrState: 'IA',
        RegAddrZip: 50021,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '2103 NE 17TH ST',
        RegAddrCity: 'ANKENY',
        RegAddrState: 'IA',
        RegAddrZip: 50021,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '2416 SW WOODBURY LN',
        RegAddrCity: 'ANKENY',
        RegAddrState: 'IA',
        RegAddrZip: 50023,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '613 NW BOULDER BROOK DR',
        RegAddrCity: 'ANKENY',
        RegAddrState: 'IA',
        RegAddrZip: 50023,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1736 NW 70TH AVE',
        RegAddrCity: 'ANKENY',
        RegAddrState: 'IA',
        RegAddrZip: 50023,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1200 BROOKRIDGE CIR',
        RegAddrCity: 'ATLANTIC',
        RegAddrState: 'IA',
        RegAddrZip: 50022,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1004 WEST ST',
        RegAddrCity: 'BEDFORD',
        RegAddrState: 'IA',
        RegAddrZip: 50833,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1406 BENT ST',
        RegAddrCity: 'BEDFORD',
        RegAddrState: 'IA',
        RegAddrZip: 50833,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '620 N 4TH ST',
        RegAddrCity: 'CARLISLE',
        RegAddrState: 'IA',
        RegAddrZip: 50047,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1520 AVENUE Q',
        RegAddrCity: 'CARTER LAKE',
        RegAddrState: 'IA',
        RegAddrZip: 51510,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1307 WALKER ST',
        RegAddrCity: 'CARTER LAKE',
        RegAddrState: 'IA',
        RegAddrZip: 51510,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '15305 RIDGEVIEW DR',
        RegAddrCity: 'CLIVE',
        RegAddrState: 'IA',
        RegAddrZip: 50325,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '2028 AVENUE A',
        RegAddrCity: 'COUNCIL BLFS',
        RegAddrState: 'IA',
        RegAddrZip: 51501,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '4618 N SHORE DR',
        RegAddrCity: 'COUNCIL BLFS',
        RegAddrState: 'IA',
        RegAddrZip: 51501,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '906 15TH AVE',
        RegAddrCity: 'COUNCIL BLFS',
        RegAddrState: 'IA',
        RegAddrZip: 51501,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '2719 2ND AVE',
        RegAddrCity: 'COUNCIL BLFS',
        RegAddrState: 'IA',
        RegAddrZip: 51501,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '305 WILDWOOD RD',
        RegAddrCity: 'COUNCIL BLFS',
        RegAddrState: 'IA',
        RegAddrZip: 51503,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '7 SCARLET OAKS RD',
        RegAddrCity: 'COUNCIL BLFS',
        RegAddrState: 'IA',
        RegAddrZip: 51503,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '326 FRANK ST',
        RegAddrCity: 'COUNCIL BLFS',
        RegAddrState: 'IA',
        RegAddrZip: 51503,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '78 OPAL DR',
        RegAddrCity: 'COUNCIL BLFS',
        RegAddrState: 'IA',
        RegAddrZip: 51503,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '15504 CRESTVIEW DR',
        RegAddrCity: 'COUNCIL BLFS',
        RegAddrState: 'IA',
        RegAddrZip: 51503,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1001 N ELM ST',
        RegAddrCity: 'CRESTON',
        RegAddrState: 'IA',
        RegAddrZip: 50801,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1175 WARREN AVE',
        RegAddrCity: 'CUMMING',
        RegAddrState: 'IA',
        RegAddrZip: 50061,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1719 NORTHWEST DR',
        RegAddrCity: 'DES MOINES',
        RegAddrState: 'IA',
        RegAddrZip: 50310,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '2904 SHADYOAK DR',
        RegAddrCity: 'DES MOINES',
        RegAddrState: 'IA',
        RegAddrZip: 50310,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1818 57TH ST',
        RegAddrCity: 'DES MOINES',
        RegAddrState: 'IA',
        RegAddrZip: 50310,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '3507 49TH ST',
        RegAddrCity: 'DES MOINES',
        RegAddrState: 'IA',
        RegAddrZip: 50310,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1801 57TH ST',
        RegAddrCity: 'DES MOINES',
        RegAddrState: 'IA',
        RegAddrZip: 50310,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1415 29TH ST',
        RegAddrCity: 'DES MOINES',
        RegAddrState: 'IA',
        RegAddrZip: 50311,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1004 25TH ST',
        RegAddrCity: 'DES MOINES',
        RegAddrState: 'IA',
        RegAddrZip: 50311,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1311 47TH ST',
        RegAddrCity: 'DES MOINES',
        RegAddrState: 'IA',
        RegAddrZip: 50311,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '307 49TH ST',
        RegAddrCity: 'DES MOINES',
        RegAddrState: 'IA',
        RegAddrZip: 50312,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '670 49TH ST',
        RegAddrCity: 'DES MOINES',
        RegAddrState: 'IA',
        RegAddrZip: 50312,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '217 52ND ST',
        RegAddrCity: 'DES MOINES',
        RegAddrState: 'IA',
        RegAddrZip: 50312,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '525 NW TORGERSON LN',
        RegAddrCity: 'DES MOINES',
        RegAddrState: 'IA',
        RegAddrZip: 50313,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1201 LAUREL ST',
        RegAddrCity: 'DES MOINES',
        RegAddrState: 'IA',
        RegAddrZip: 50314,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '517 E TITUS AVE',
        RegAddrCity: 'DES MOINES',
        RegAddrState: 'IA',
        RegAddrZip: 50315,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '510 THORNTON AVE',
        RegAddrCity: 'DES MOINES',
        RegAddrState: 'IA',
        RegAddrZip: 50315,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '2200 SOUTH UNION ST',
        RegAddrCity: 'DES MOINES',
        RegAddrState: 'IA',
        RegAddrZip: 50315,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1204 ELDER LN',
        RegAddrCity: 'DES MOINES',
        RegAddrState: 'IA',
        RegAddrZip: 50315,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '210 BROAD ST',
        RegAddrCity: 'DES MOINES',
        RegAddrState: 'IA',
        RegAddrZip: 50315,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '4610 SE 6TH ST',
        RegAddrCity: 'DES MOINES',
        RegAddrState: 'IA',
        RegAddrZip: 50315,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '7307 SW 16TH ST',
        RegAddrCity: 'DES MOINES',
        RegAddrState: 'IA',
        RegAddrZip: 50315,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1344 E 13TH ST',
        RegAddrCity: 'DES MOINES',
        RegAddrState: 'IA',
        RegAddrZip: 50316,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '4780 NE 23RD AVE',
        RegAddrCity: 'DES MOINES',
        RegAddrState: 'IA',
        RegAddrZip: 50317,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '2202 E 23RD ST',
        RegAddrCity: 'DES MOINES',
        RegAddrState: 'IA',
        RegAddrZip: 50317,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '2760 E AURORA AVE',
        RegAddrCity: 'DES MOINES',
        RegAddrState: 'IA',
        RegAddrZip: 50317,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '2733 E JEFFERSON AVE',
        RegAddrCity: 'DES MOINES',
        RegAddrState: 'IA',
        RegAddrZip: 50317,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '2340 EASTON BLVD',
        RegAddrCity: 'DES MOINES',
        RegAddrState: 'IA',
        RegAddrZip: 50317,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '2323 E 23RD ST',
        RegAddrCity: 'DES MOINES',
        RegAddrState: 'IA',
        RegAddrZip: 50317,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '2666 GUTHRIE AVE',
        RegAddrCity: 'DES MOINES',
        RegAddrState: 'IA',
        RegAddrZip: 50317,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '4712 E MERCED ST',
        RegAddrCity: 'DES MOINES',
        RegAddrState: 'IA',
        RegAddrZip: 50317,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1824 E THORNTON AVE',
        RegAddrCity: 'DES MOINES',
        RegAddrState: 'IA',
        RegAddrZip: 50320,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '6155 SE 19TH ST',
        RegAddrCity: 'DES MOINES',
        RegAddrState: 'IA',
        RegAddrZip: 50320,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1101 NEBRASKA AVE',
        RegAddrCity: 'ESSEX',
        RegAddrState: 'IA',
        RegAddrZip: 51638,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '57073 240TH ST',
        RegAddrCity: 'GLENWOOD',
        RegAddrState: 'IA',
        RegAddrZip: 51534,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '107 HILLCREST DR',
        RegAddrCity: 'GLENWOOD',
        RegAddrState: 'IA',
        RegAddrZip: 51534,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '22859 KALE LN',
        RegAddrCity: 'GLENWOOD',
        RegAddrState: 'IA',
        RegAddrZip: 51534,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '185 GLENBROOK DR',
        RegAddrCity: 'GLENWOOD',
        RegAddrState: 'IA',
        RegAddrZip: 51534,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '2980 HIGHWAY 92',
        RegAddrCity: 'GREENFIELD',
        RegAddrState: 'IA',
        RegAddrZip: 50849,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '307 N 6TH ST',
        RegAddrCity: 'GUTHRIE CTR',
        RegAddrState: 'IA',
        RegAddrZip: 50115,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '507 N KENWOOD BLVD',
        RegAddrCity: 'INDIANOLA',
        RegAddrState: 'IA',
        RegAddrZip: 50125,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1010 W EUCLID AVE',
        RegAddrCity: 'INDIANOLA',
        RegAddrState: 'IA',
        RegAddrZip: 50125,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '6104 FOUR PINES ST',
        RegAddrCity: 'JOHNSTON',
        RegAddrState: 'IA',
        RegAddrZip: 50131,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '6520 NW 99TH ST',
        RegAddrCity: 'JOHNSTON',
        RegAddrState: 'IA',
        RegAddrZip: 50131,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '59637 OSAGE LN',
        RegAddrCity: 'LEWIS',
        RegAddrState: 'IA',
        RegAddrZip: 51544,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '180 CENTER ST',
        RegAddrCity: 'MARTENSDALE',
        RegAddrState: 'IA',
        RegAddrZip: 50160,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '10632 NE 118TH AVE',
        RegAddrCity: 'MAXWELL',
        RegAddrState: 'IA',
        RegAddrZip: 50161,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '2506 SHADY LANE DR',
        RegAddrCity: 'NORWALK',
        RegAddrState: 'IA',
        RegAddrZip: 50211,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '136 ORCHARD TRL',
        RegAddrCity: 'NORWALK',
        RegAddrState: 'IA',
        RegAddrZip: 50211,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '544 CHRISTIE LN',
        RegAddrCity: 'PLEASANT HILL',
        RegAddrState: 'IA',
        RegAddrZip: 50327,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1139 KEYSTONE LN',
        RegAddrCity: 'PLEASANT HILL',
        RegAddrState: 'IA',
        RegAddrZip: 50327,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '3491 160TH ST',
        RegAddrCity: 'RANDOLPH',
        RegAddrState: 'IA',
        RegAddrZip: 51649,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '704 SKYLINE DR',
        RegAddrCity: 'RED OAK',
        RegAddrState: 'IA',
        RegAddrZip: 51566,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '101 CEMETERY RD',
        RegAddrCity: 'REDDING',
        RegAddrState: 'IA',
        RegAddrZip: 50860,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1113 GRANT ST',
        RegAddrCity: 'REDFIELD',
        RegAddrState: 'IA',
        RegAddrZip: 50233,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '904 WEST ST',
        RegAddrCity: 'SHENANDOAH',
        RegAddrState: 'IA',
        RegAddrZip: 51601,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '523 S FREMONT ST',
        RegAddrCity: 'STUART',
        RegAddrState: 'IA',
        RegAddrZip: 50250,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '403 N HARLAN ST',
        RegAddrCity: 'STUART',
        RegAddrState: 'IA',
        RegAddrZip: 50250,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '4746 LOCKNER DR',
        RegAddrCity: 'URBANDALE',
        RegAddrState: 'IA',
        RegAddrZip: 50322,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '4614 84TH ST',
        RegAddrCity: 'URBANDALE',
        RegAddrState: 'IA',
        RegAddrZip: 50322,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '4013 81ST ST',
        RegAddrCity: 'URBANDALE',
        RegAddrState: 'IA',
        RegAddrZip: 50322,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '15609 AURORA AVE',
        RegAddrCity: 'URBANDALE',
        RegAddrState: 'IA',
        RegAddrZip: 50323,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '2803 160TH ST',
        RegAddrCity: 'URBANDALE',
        RegAddrState: 'IA',
        RegAddrZip: 50323,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '4807 145TH ST',
        RegAddrCity: 'URBANDALE',
        RegAddrState: 'IA',
        RegAddrZip: 50323,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '36479 OSAGE AVE',
        RegAddrCity: 'VAN METER',
        RegAddrState: 'IA',
        RegAddrZip: 50261,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '2025 SE GREENTREE DR',
        RegAddrCity: 'WAUKEE',
        RegAddrState: 'IA',
        RegAddrZip: 50263,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1325 SE FLORENCE DR',
        RegAddrCity: 'WAUKEE',
        RegAddrState: 'IA',
        RegAddrZip: 50263,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1107 16TH ST',
        RegAddrCity: 'WDM',
        RegAddrState: 'IA',
        RegAddrZip: 50265,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '4200 ASPEN DR',
        RegAddrCity: 'WDM',
        RegAddrState: 'IA',
        RegAddrZip: 50265,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '632 9TH ST',
        RegAddrCity: 'WDM',
        RegAddrState: 'IA',
        RegAddrZip: 50265,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '912 11TH ST',
        RegAddrCity: 'WDM',
        RegAddrState: 'IA',
        RegAddrZip: 50265,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1501 OFFICE PARK RD',
        RegAddrCity: 'WDM',
        RegAddrState: 'IA',
        RegAddrZip: 50265,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '5949 COTTONWOOD CIR',
        RegAddrCity: 'WDM',
        RegAddrState: 'IA',
        RegAddrZip: 50266,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '8230 CENTURY DR',
        RegAddrCity: 'WDM',
        RegAddrState: 'IA',
        RegAddrZip: 50266,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '505 57TH CT',
        RegAddrCity: 'WDM',
        RegAddrState: 'IA',
        RegAddrZip: 50266,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1024 90TH ST',
        RegAddrCity: 'WDM',
        RegAddrState: 'IA',
        RegAddrZip: 50266,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1011 69TH ST',
        RegAddrCity: 'WINDSOR HTS',
        RegAddrState: 'IA',
        RegAddrZip: 50324,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '310 N 7TH ST',
        RegAddrCity: 'WINTERSET',
        RegAddrState: 'IA',
        RegAddrZip: 50273,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '2083 170TH CT',
        RegAddrCity: 'WINTERSET',
        RegAddrState: 'IA',
        RegAddrZip: 50273,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '223 S 14TH AVE',
        RegAddrCity: 'WINTERSET',
        RegAddrState: 'IA',
        RegAddrZip: 50273,
        CongressionalDistrict: 3,
      },
    ],
  },
  {
    name: 'IA-4',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/IA-4?ref=gh-pages',
    state: 'IA',
    state_and_number: 'IA-4',
    zip: 50006,
    congressionalDistrict: 4,
    addressesInDistrict: [
      {
        RegAddrLine1: '114 RICHARDSON CT',
        RegAddrCity: 'ALGONA',
        RegAddrState: 'IA',
        RegAddrZip: 50511,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '601 S HARRIET ST',
        RegAddrCity: 'ALGONA',
        RegAddrState: 'IA',
        RegAddrZip: 50511,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '111 1/2 S MAIN ST',
        RegAddrCity: 'ALTA',
        RegAddrState: 'IA',
        RegAddrZip: 51002,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2217 PRAIRIE VIEW EAST',
        RegAddrCity: 'AMES',
        RegAddrState: 'IA',
        RegAddrZip: 50010,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '4906 TODD DR',
        RegAddrCity: 'AMES',
        RegAddrState: 'IA',
        RegAddrZip: 50014,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2121 SUNSET DR',
        RegAddrCity: 'AMES',
        RegAddrState: 'IA',
        RegAddrZip: 50014,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1209 SCHOLL RD',
        RegAddrCity: 'AMES',
        RegAddrState: 'IA',
        RegAddrZip: 50014,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '903 8TH AVE NE',
        RegAddrCity: 'BELMOND',
        RegAddrState: 'IA',
        RegAddrZip: 50421,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '803 2ND AVE NE',
        RegAddrCity: 'BELMOND',
        RegAddrState: 'IA',
        RegAddrZip: 50421,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '781 P AVE',
        RegAddrCity: 'BOONE',
        RegAddrState: 'IA',
        RegAddrZip: 50036,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '410 WILLIAMS ST',
        RegAddrCity: 'CALLENDER',
        RegAddrState: 'IA',
        RegAddrZip: 50523,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '329 E PLEASANT RIDGE RD',
        RegAddrCity: 'CARROLL',
        RegAddrState: 'IA',
        RegAddrZip: 51401,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '320 ELY CIR',
        RegAddrCity: 'CARROLL',
        RegAddrState: 'IA',
        RegAddrZip: 51401,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '3102 230TH ST',
        RegAddrCity: 'CHARLES CITY',
        RegAddrState: 'IA',
        RegAddrZip: 50616,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1775 PAGE AVE',
        RegAddrCity: 'CLARION',
        RegAddrState: 'IA',
        RegAddrZip: 50525,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1738 PAGE AVE',
        RegAddrCity: 'CLARION',
        RegAddrState: 'IA',
        RegAddrZip: 50525,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1329 BUDDY HOLLY PL',
        RegAddrCity: 'CLEAR LAKE',
        RegAddrState: 'IA',
        RegAddrZip: 50428,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '5599 H AVE',
        RegAddrCity: 'CLEGHORN',
        RegAddrState: 'IA',
        RegAddrZip: 51014,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '118 HICKORY LN',
        RegAddrCity: 'COON RAPIDS',
        RegAddrState: 'IA',
        RegAddrZip: 50058,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '602 WILKENS ST',
        RegAddrCity: 'DANBURY',
        RegAddrState: 'IA',
        RegAddrZip: 51019,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '3079 SAFFORD AVE',
        RegAddrCity: 'DANBURY',
        RegAddrState: 'IA',
        RegAddrZip: 51019,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2034 VERNON VOSS RD',
        RegAddrCity: 'DENISON',
        RegAddrState: 'IA',
        RegAddrZip: 51442,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '106 N SCHOOL ST',
        RegAddrCity: 'DOW CITY',
        RegAddrState: 'IA',
        RegAddrZip: 51528,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '302 S ESKRIDGE ST',
        RegAddrCity: 'DOWS',
        RegAddrState: 'IA',
        RegAddrZip: 50071,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '32290 HIGHWAY 175',
        RegAddrCity: 'ELDORA',
        RegAddrState: 'IA',
        RegAddrZip: 50627,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '213 N STATE ST',
        RegAddrCity: 'EMMETSBURG',
        RegAddrState: 'IA',
        RegAddrZip: 50536,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '4480 130TH ST',
        RegAddrCity: 'ESTHERVILLE',
        RegAddrState: 'IA',
        RegAddrZip: 51334,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '214 N 12TH ST',
        RegAddrCity: 'ESTHERVILLE',
        RegAddrState: 'IA',
        RegAddrZip: 51334,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '10671 600TH ST',
        RegAddrCity: 'FONDA',
        RegAddrState: 'IA',
        RegAddrZip: 50540,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '607 QUEEN ST',
        RegAddrCity: 'FONDA',
        RegAddrState: 'IA',
        RegAddrZip: 50540,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '135 3RD AVE N',
        RegAddrCity: 'FORT DODGE',
        RegAddrState: 'IA',
        RegAddrZip: 50501,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '216 AVENUE H',
        RegAddrCity: 'FORT DODGE',
        RegAddrState: 'IA',
        RegAddrZip: 50501,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1326 AVENUE B',
        RegAddrCity: 'FORT DODGE',
        RegAddrState: 'IA',
        RegAddrZip: 50501,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1479 JASPER AVE',
        RegAddrCity: 'GALVA',
        RegAddrState: 'IA',
        RegAddrZip: 51020,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '506 E IOWA ST',
        RegAddrCity: 'GREENE',
        RegAddrState: 'IA',
        RegAddrZip: 50636,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '902 WILLOW ST',
        RegAddrCity: 'HARLAN',
        RegAddrState: 'IA',
        RegAddrZip: 51537,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '203 W 1ST ST',
        RegAddrCity: 'HUXLEY',
        RegAddrState: 'IA',
        RegAddrZip: 50124,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '30768 DEER DR',
        RegAddrCity: 'HUXLEY',
        RegAddrState: 'IA',
        RegAddrZip: 50124,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1106 2ND AVE N',
        RegAddrCity: 'IOWA FALLS',
        RegAddrState: 'IA',
        RegAddrZip: 50126,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '19637 160TH ST',
        RegAddrCity: 'IOWA FALLS',
        RegAddrState: 'IA',
        RegAddrZip: 50126,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '917 ELM ST',
        RegAddrCity: 'IOWA FALLS',
        RegAddrState: 'IA',
        RegAddrZip: 50126,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '508 N CHESTNUT ST',
        RegAddrCity: 'JEFFERSON',
        RegAddrState: 'IA',
        RegAddrZip: 50129,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '701 S LOCUST ST',
        RegAddrCity: 'JEFFERSON',
        RegAddrState: 'IA',
        RegAddrZip: 50129,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '202 WASHINGTON ST',
        RegAddrCity: 'KIRKMAN',
        RegAddrState: 'IA',
        RegAddrZip: 51447,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '116 6TH ST',
        RegAddrCity: 'LAKE VIEW',
        RegAddrState: 'IA',
        RegAddrZip: 51450,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1219 HOLDER ST',
        RegAddrCity: 'LARCHWOOD',
        RegAddrState: 'IA',
        RegAddrZip: 51241,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '330 HAZLETT ST',
        RegAddrCity: 'LAURENS',
        RegAddrState: 'IA',
        RegAddrZip: 50554,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '321 E MAIN ST',
        RegAddrCity: 'LAWTON',
        RegAddrState: 'IA',
        RegAddrZip: 51030,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '115 4TH AVE NW',
        RegAddrCity: 'LE MARS',
        RegAddrState: 'IA',
        RegAddrZip: 51031,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '114 3RD ST NW',
        RegAddrCity: 'LE MARS',
        RegAddrState: 'IA',
        RegAddrZip: 51031,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '204 W 1ST ST',
        RegAddrCity: 'LIDDERDALE',
        RegAddrState: 'IA',
        RegAddrZip: 51452,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '413 WESSAR DR',
        RegAddrCity: 'LOGAN',
        RegAddrState: 'IA',
        RegAddrZip: 51546,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '606 300TH ST',
        RegAddrCity: 'LONE ROCK',
        RegAddrState: 'IA',
        RegAddrZip: 50559,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '208 28TH ST SW',
        RegAddrCity: 'MASON CITY',
        RegAddrState: 'IA',
        RegAddrZip: 50401,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '33 CONCORD LN',
        RegAddrCity: 'MASON CITY',
        RegAddrState: 'IA',
        RegAddrZip: 50401,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1604 S PENNSYLVANIA AVE',
        RegAddrCity: 'MASON CITY',
        RegAddrState: 'IA',
        RegAddrZip: 50401,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '440 28TH ST SW',
        RegAddrCity: 'MASON CITY',
        RegAddrState: 'IA',
        RegAddrZip: 50401,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '97 MASON ST',
        RegAddrCity: 'MESERVEY',
        RegAddrState: 'IA',
        RegAddrZip: 50457,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1112 S AVE',
        RegAddrCity: 'MILFORD',
        RegAddrState: 'IA',
        RegAddrZip: 51351,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1600 13TH ST',
        RegAddrCity: 'MILFORD',
        RegAddrState: 'IA',
        RegAddrZip: 51351,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2974 LOESS HILLS TRL',
        RegAddrCity: 'MISSOURI VLY',
        RegAddrState: 'IA',
        RegAddrZip: 51555,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '138 ASH ST',
        RegAddrCity: 'MOVILLE',
        RegAddrState: 'IA',
        RegAddrZip: 51039,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2650 OLD HIGHWAY RD',
        RegAddrCity: 'NASHUA',
        RegAddrState: 'IA',
        RegAddrZip: 50658,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2118 10TH ST',
        RegAddrCity: 'NEVADA',
        RegAddrState: 'IA',
        RegAddrZip: 50201,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1125 JUNEBERRY RD',
        RegAddrCity: 'OGDEN',
        RegAddrState: 'IA',
        RegAddrZip: 50212,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '603 KENTUCKY AVE SW',
        RegAddrCity: 'ORANGE CITY',
        RegAddrState: 'IA',
        RegAddrZip: 51041,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '503 HARTFORD AVE SE',
        RegAddrCity: 'ORANGE CITY',
        RegAddrState: 'IA',
        RegAddrZip: 51041,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '203 KANSAS AVE NW',
        RegAddrCity: 'ORANGE CITY',
        RegAddrState: 'IA',
        RegAddrZip: 51041,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2820 LENOX AVE',
        RegAddrCity: 'OTO',
        RegAddrState: 'IA',
        RegAddrZip: 51044,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '3353 275TH ST',
        RegAddrCity: 'OTO',
        RegAddrState: 'IA',
        RegAddrZip: 51044,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '209 ELM ST',
        RegAddrCity: 'PARKERSBURG',
        RegAddrState: 'IA',
        RegAddrZip: 50665,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '301 PARK ST',
        RegAddrCity: 'PETERSON',
        RegAddrState: 'IA',
        RegAddrZip: 51047,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '211 PINE ST',
        RegAddrCity: 'PLYMOUTH',
        RegAddrState: 'IA',
        RegAddrZip: 50464,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '615 1200TH ST',
        RegAddrCity: 'PORTSMOUTH',
        RegAddrState: 'IA',
        RegAddrZip: 51565,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1534 220TH ST',
        RegAddrCity: 'ROCKFORD',
        RegAddrState: 'IA',
        RegAddrZip: 50468,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '410 S MAIN ST',
        RegAddrCity: 'ROLAND',
        RegAddrState: 'IA',
        RegAddrZip: 50236,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '405 W 4TH ST',
        RegAddrCity: 'SANBORN',
        RegAddrState: 'IA',
        RegAddrZip: 51248,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '422 WINFIELD CT',
        RegAddrCity: 'SERGEANT BLF',
        RegAddrState: 'IA',
        RegAddrZip: 51054,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '146 GOLDEN DR',
        RegAddrCity: 'SERGEANT BLF',
        RegAddrState: 'IA',
        RegAddrZip: 51054,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1436 220TH ST',
        RegAddrCity: 'SERGEANT BLF',
        RegAddrState: 'IA',
        RegAddrZip: 51054,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2275 MALLARD AVE',
        RegAddrCity: 'SHEFFIELD',
        RegAddrState: 'IA',
        RegAddrZip: 50475,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2601 E 6TH ST',
        RegAddrCity: 'SHELDON',
        RegAddrState: 'IA',
        RegAddrZip: 51201,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '230 11TH ST W',
        RegAddrCity: 'SIBLEY',
        RegAddrState: 'IA',
        RegAddrZip: 51249,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1201 12TH ST NE',
        RegAddrCity: 'SIOUX CENTER',
        RegAddrState: 'IA',
        RegAddrZip: 51250,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '745 1ST AVE SE',
        RegAddrCity: 'SIOUX CENTER',
        RegAddrState: 'IA',
        RegAddrZip: 51250,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1108 1/2 6TH ST NE',
        RegAddrCity: 'SIOUX CENTER',
        RegAddrState: 'IA',
        RegAddrZip: 51250,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '102 13TH ST',
        RegAddrCity: 'SIOUX CITY',
        RegAddrState: 'IA',
        RegAddrZip: 51103,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1607 RUBY ST',
        RegAddrCity: 'SIOUX CITY',
        RegAddrState: 'IA',
        RegAddrZip: 51103,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '3001 W 31ST ST',
        RegAddrCity: 'SIOUX CITY',
        RegAddrState: 'IA',
        RegAddrZip: 51103,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2501 JENNINGS ST',
        RegAddrCity: 'SIOUX CITY',
        RegAddrState: 'IA',
        RegAddrZip: 51104,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1209 OLAF CT',
        RegAddrCity: 'SIOUX CITY',
        RegAddrState: 'IA',
        RegAddrZip: 51104,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '112 ALICE ST',
        RegAddrCity: 'SIOUX CITY',
        RegAddrState: 'IA',
        RegAddrZip: 51105,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '6 ROSE LN',
        RegAddrCity: 'SIOUX CITY',
        RegAddrState: 'IA',
        RegAddrZip: 51106,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '113 MAIN ST',
        RegAddrCity: 'SIOUX RAPIDS',
        RegAddrState: 'IA',
        RegAddrZip: 50585,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1123 E 18TH ST',
        RegAddrCity: 'SPENCER',
        RegAddrState: 'IA',
        RegAddrZip: 51301,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '105 27TH ST',
        RegAddrCity: 'SPIRIT LAKE',
        RegAddrState: 'IA',
        RegAddrZip: 51360,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '3020 160TH ST',
        RegAddrCity: 'SPIRIT LAKE',
        RegAddrState: 'IA',
        RegAddrZip: 51360,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1748 310TH AVE',
        RegAddrCity: 'SPIRIT LAKE',
        RegAddrState: 'IA',
        RegAddrZip: 51360,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '608 RENSHAW DR',
        RegAddrCity: 'STORM LAKE',
        RegAddrState: 'IA',
        RegAddrZip: 50588,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '3377 190TH ST',
        RegAddrCity: 'VENTURA',
        RegAddrState: 'IA',
        RegAddrZip: 50482,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1228 2ND ST',
        RegAddrCity: 'WEBSTER CITY',
        RegAddrState: 'IA',
        RegAddrZip: 50595,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1295 260TH ST',
        RegAddrCity: 'WEBSTER CITY',
        RegAddrState: 'IA',
        RegAddrZip: 50595,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '727 CEDAR ST',
        RegAddrCity: 'WEBSTER CITY',
        RegAddrState: 'IA',
        RegAddrZip: 50595,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '831 E 4TH ST',
        RegAddrCity: 'WELLSBURG',
        RegAddrState: 'IA',
        RegAddrZip: 50680,
        CongressionalDistrict: 4,
      },
    ],
  },
  {
    name: 'ID-1',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/ID-1?ref=gh-pages',
    state: 'ID',
    state_and_number: 'ID-1',
    zip: 59842,
    congressionalDistrict: 1,
    addressesInDistrict: [
      {
        RegAddrLine1: '11588 W AMITY RD',
        RegAddrCity: 'BOISE',
        RegAddrState: 'ID',
        RegAddrZip: 83709,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '9703 W MOSSYWOOD DR',
        RegAddrCity: 'BOISE',
        RegAddrState: 'ID',
        RegAddrZip: 83709,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1696 S GOLDKING WAY',
        RegAddrCity: 'BOISE',
        RegAddrState: 'ID',
        RegAddrZip: 83709,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2149 S MOHICAN PL',
        RegAddrCity: 'BOISE',
        RegAddrState: 'ID',
        RegAddrZip: 83709,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '5327 N MAIDSTONE WAY',
        RegAddrCity: 'BOISE',
        RegAddrState: 'ID',
        RegAddrZip: 83713,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '13346 W SILVERBROOK DR',
        RegAddrCity: 'BOISE',
        RegAddrState: 'ID',
        RegAddrZip: 83713,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '5020 N LAWSONIA PL',
        RegAddrCity: 'BOISE',
        RegAddrState: 'ID',
        RegAddrZip: 83713,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '6 FOREST DR',
        RegAddrCity: 'BOISE',
        RegAddrState: 'ID',
        RegAddrZip: 83716,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '324 SPRING HILL RD',
        RegAddrCity: 'BONNERS FERRY',
        RegAddrState: 'ID',
        RegAddrZip: 83805,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '818 FILLMORE ST',
        RegAddrCity: 'CALDWELL',
        RegAddrState: 'ID',
        RegAddrZip: 83605,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '103 E ITHACA ST',
        RegAddrCity: 'CALDWELL',
        RegAddrState: 'ID',
        RegAddrZip: 83605,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1414 E LINDEN ST',
        RegAddrCity: 'CALDWELL',
        RegAddrState: 'ID',
        RegAddrZip: 83605,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1703 N KCID RD',
        RegAddrCity: 'CALDWELL',
        RegAddrState: 'ID',
        RegAddrZip: 83605,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2520 SUMMERCREST ST',
        RegAddrCity: 'CALDWELL',
        RegAddrState: 'ID',
        RegAddrZip: 83607,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1451 BLACKTAIL RD',
        RegAddrCity: 'COCOLALLA',
        RegAddrState: 'ID',
        RegAddrZip: 83813,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '921 N VICTORIAN DR',
        RegAddrCity: 'COEUR D ALENE',
        RegAddrState: 'ID',
        RegAddrZip: 83814,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1186 E BROOKLYN AVE',
        RegAddrCity: 'COEUR D ALENE',
        RegAddrState: 'ID',
        RegAddrZip: 83815,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '5866 N BELLEVILLE DR',
        RegAddrCity: 'COEUR D ALENE',
        RegAddrState: 'ID',
        RegAddrZip: 83815,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '421 N VANDRIES WAY',
        RegAddrCity: 'EAGLE',
        RegAddrState: 'ID',
        RegAddrZip: 83616,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2536 N PRONGHORN LN',
        RegAddrCity: 'EAGLE',
        RegAddrState: 'ID',
        RegAddrZip: 83616,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1825 SUNSET DR',
        RegAddrCity: 'EMMETT',
        RegAddrState: 'ID',
        RegAddrZip: 83617,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2465 HAW CREEK BLVD',
        RegAddrCity: 'EMMETT',
        RegAddrState: 'ID',
        RegAddrZip: 83617,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '3705 LITTLE CARPENTER CREEK RD',
        RegAddrCity: 'FERNWOOD',
        RegAddrState: 'ID',
        RegAddrZip: 83830,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '8478 WILLOWDALE DR',
        RegAddrCity: 'GARDEN CITY',
        RegAddrState: 'ID',
        RegAddrZip: 83714,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '34 TAMARACK CT',
        RegAddrCity: 'GARDEN VALLEY',
        RegAddrState: 'ID',
        RegAddrZip: 83622,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '3195 HIGHWAY 95',
        RegAddrCity: 'GENESEE',
        RegAddrState: 'ID',
        RegAddrZip: 83832,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '337 CHINA GARDEN RD',
        RegAddrCity: 'HARPSTER',
        RegAddrState: 'ID',
        RegAddrZip: 83552,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '4647 E CATTAIL CT',
        RegAddrCity: 'HAYDEN',
        RegAddrState: 'ID',
        RegAddrZip: 83835,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '3918 E GARWOOD RD',
        RegAddrCity: 'HAYDEN',
        RegAddrState: 'ID',
        RegAddrZip: 83835,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '114 E MONTANA AVE',
        RegAddrCity: 'HOMEDALE',
        RegAddrState: 'ID',
        RegAddrZip: 83628,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '311 MONTGOMERY ST',
        RegAddrCity: 'IDAHO CITY',
        RegAddrState: 'ID',
        RegAddrZip: 83631,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '402 W RIVERSIDE AVE',
        RegAddrCity: 'KELLOGG',
        RegAddrState: 'ID',
        RegAddrZip: 83837,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '181 FINLEY LOOP',
        RegAddrCity: 'KINGSTON',
        RegAddrState: 'ID',
        RegAddrZip: 83839,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '12051 KUNA RD',
        RegAddrCity: 'KUNA',
        RegAddrState: 'ID',
        RegAddrZip: 83634,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '520 25TH AVE',
        RegAddrCity: 'LEWISTON',
        RegAddrState: 'ID',
        RegAddrZip: 83501,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1028 RICHARDSON AVE',
        RegAddrCity: 'LEWISTON',
        RegAddrState: 'ID',
        RegAddrZip: 83501,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '18612 RED BIRD RD',
        RegAddrCity: 'LEWISTON',
        RegAddrState: 'ID',
        RegAddrZip: 83501,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2623 ECHO HILLS DR',
        RegAddrCity: 'LEWISTON',
        RegAddrState: 'ID',
        RegAddrZip: 83501,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '655 HANOVER CT',
        RegAddrCity: 'MERIDIAN',
        RegAddrState: 'ID',
        RegAddrZip: 83642,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2255 W LAKE HAZEL RD',
        RegAddrCity: 'MERIDIAN',
        RegAddrState: 'ID',
        RegAddrZip: 83642,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '590 W DAVENPORT ST',
        RegAddrCity: 'MERIDIAN',
        RegAddrState: 'ID',
        RegAddrZip: 83642,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '548 S LINDER RD',
        RegAddrCity: 'MERIDIAN',
        RegAddrState: 'ID',
        RegAddrZip: 83642,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '327 E COPPER RIDGE ST',
        RegAddrCity: 'MERIDIAN',
        RegAddrState: 'ID',
        RegAddrZip: 83646,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2836 W KANDICE CT',
        RegAddrCity: 'MERIDIAN',
        RegAddrState: 'ID',
        RegAddrZip: 83646,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1469 E WILLOWBROOK CT',
        RegAddrCity: 'MERIDIAN',
        RegAddrState: 'ID',
        RegAddrZip: 83646,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2866 N LEBLANC WAY',
        RegAddrCity: 'MERIDIAN',
        RegAddrState: 'ID',
        RegAddrZip: 83646,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '4115 E MEADOW WOOD DR',
        RegAddrCity: 'MERIDIAN',
        RegAddrState: 'ID',
        RegAddrZip: 83646,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '518 KENNEDY CT',
        RegAddrCity: 'MIDDLETON',
        RegAddrState: 'ID',
        RegAddrZip: 83644,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '26956 CEMETERY RD',
        RegAddrCity: 'MIDDLETON',
        RegAddrState: 'ID',
        RegAddrZip: 83644,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '919 PUBLIC AVE',
        RegAddrCity: 'MOSCOW',
        RegAddrState: 'ID',
        RegAddrZip: 83843,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1847 LEXINGTON AVE',
        RegAddrCity: 'MOSCOW',
        RegAddrState: 'ID',
        RegAddrZip: 83843,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '142 WOLVERINE RD',
        RegAddrCity: 'MOYIE SPRINGS',
        RegAddrState: 'ID',
        RegAddrZip: 83845,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1807 W EAGLECREST DR',
        RegAddrCity: 'NAMPA',
        RegAddrState: 'ID',
        RegAddrZip: 83651,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2507 ASHLEY AVE',
        RegAddrCity: 'NAMPA',
        RegAddrState: 'ID',
        RegAddrZip: 83651,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '146 HIGH ST',
        RegAddrCity: 'NAMPA',
        RegAddrState: 'ID',
        RegAddrZip: 83651,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '824 W TOOELE DR',
        RegAddrCity: 'NAMPA',
        RegAddrState: 'ID',
        RegAddrZip: 83686,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2722 E CASTLEBERG LOOP',
        RegAddrCity: 'NAMPA',
        RegAddrState: 'ID',
        RegAddrZip: 83686,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2516 E MARYLAND AVE',
        RegAddrCity: 'NAMPA',
        RegAddrState: 'ID',
        RegAddrZip: 83686,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '917 S LILY CT',
        RegAddrCity: 'NAMPA',
        RegAddrState: 'ID',
        RegAddrZip: 83686,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '336 W WASHINGTON AVE',
        RegAddrCity: 'NAMPA',
        RegAddrState: 'ID',
        RegAddrZip: 83686,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '107 S QUEENS DR',
        RegAddrCity: 'NAMPA',
        RegAddrState: 'ID',
        RegAddrZip: 83687,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '10881 PIXIE ST',
        RegAddrCity: 'NAMPA',
        RegAddrState: 'ID',
        RegAddrZip: 83687,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '41 5TH ST N',
        RegAddrCity: 'NAMPA',
        RegAddrState: 'ID',
        RegAddrZip: 83687,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '9950 PERKINS LN',
        RegAddrCity: 'OLA',
        RegAddrState: 'ID',
        RegAddrZip: 83657,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '37258 EBERHARDT RD',
        RegAddrCity: 'PECK',
        RegAddrState: 'ID',
        RegAddrZip: 83545,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '28914 HIGHWAY 11',
        RegAddrCity: 'PIERCE',
        RegAddrState: 'ID',
        RegAddrZip: 83546,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '5857 E SHORELINE DR',
        RegAddrCity: 'POST FALLS',
        RegAddrState: 'ID',
        RegAddrZip: 83854,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1624 W HYDRILLA AVE',
        RegAddrCity: 'POST FALLS',
        RegAddrState: 'ID',
        RegAddrZip: 83854,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2109 W PELLINORE WAY',
        RegAddrCity: 'POST FALLS',
        RegAddrState: 'ID',
        RegAddrZip: 83854,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2074 W HEAVENLY STAR CT',
        RegAddrCity: 'POST FALLS',
        RegAddrState: 'ID',
        RegAddrZip: 83854,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '881 HOO DOO MOUNTAIN RD',
        RegAddrCity: 'PRIEST RIVER',
        RegAddrState: 'ID',
        RegAddrZip: 83856,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2109 ESTATES LOOP',
        RegAddrCity: 'PRIEST RIVER',
        RegAddrState: 'ID',
        RegAddrZip: 83856,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '17317 N ATLAS RD',
        RegAddrCity: 'RATHDRUM',
        RegAddrState: 'ID',
        RegAddrZip: 83858,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '8305 W MONTANA ST',
        RegAddrCity: 'RATHDRUM',
        RegAddrState: 'ID',
        RegAddrZip: 83858,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '20632 N ALTAMONT RD',
        RegAddrCity: 'RATHDRUM',
        RegAddrState: 'ID',
        RegAddrZip: 83858,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '143 COPPER RIDGE RD',
        RegAddrCity: 'SAGLE',
        RegAddrState: 'ID',
        RegAddrZip: 83860,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1284 FLUME CREEK RD',
        RegAddrCity: 'SANDPOINT',
        RegAddrState: 'ID',
        RegAddrZip: 83864,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1666 COTTONWOOD DR',
        RegAddrCity: 'ST MARIES',
        RegAddrState: 'ID',
        RegAddrZip: 83861,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '6501 SAGE CANYON WAY',
        RegAddrCity: 'STAR',
        RegAddrState: 'ID',
        RegAddrZip: 83669,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '650 E BUTTERFIELD ST',
        RegAddrCity: 'WEISER',
        RegAddrState: 'ID',
        RegAddrZip: 83672,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '26375 PECKHAM RD',
        RegAddrCity: 'WILDER',
        RegAddrState: 'ID',
        RegAddrZip: 83676,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '25062 BOEHNER RD',
        RegAddrCity: 'WILDER',
        RegAddrState: 'ID',
        RegAddrZip: 83676,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '20404 S HEDMARK LANDING RD',
        RegAddrCity: 'WORLEY',
        RegAddrState: 'ID',
        RegAddrZip: 83876,
        CongressionalDistrict: 1,
      },
    ],
  },
  {
    name: 'ID-2',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/ID-2?ref=gh-pages',
    state: 'ID',
    state_and_number: 'ID-2',
    zip: 59725,
    congressionalDistrict: 2,
    addressesInDistrict: [
      {
        RegAddrLine1: '2484 S FOOTHILL RD',
        RegAddrCity: 'AMMON',
        RegAddrState: 'ID',
        RegAddrZip: 83401,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '464 S MERIDIAN ST',
        RegAddrCity: 'BLACKFOOT',
        RegAddrState: 'ID',
        RegAddrZip: 83221,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '395 CENTENNIAL ST',
        RegAddrCity: 'BLACKFOOT',
        RegAddrState: 'ID',
        RegAddrZip: 83221,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '245 A AVE W',
        RegAddrCity: 'BLISS',
        RegAddrState: 'ID',
        RegAddrZip: 83314,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '3017 N CRANE CREEK RD',
        RegAddrCity: 'BOISE',
        RegAddrState: 'ID',
        RegAddrZip: 83702,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '177 W HULLS RIDGE CT',
        RegAddrCity: 'BOISE',
        RegAddrState: 'ID',
        RegAddrZip: 83702,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2608 W WOODLAWN AVE',
        RegAddrCity: 'BOISE',
        RegAddrState: 'ID',
        RegAddrZip: 83702,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2120 W STATE ST',
        RegAddrCity: 'BOISE',
        RegAddrState: 'ID',
        RegAddrZip: 83702,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '5539 N NORTHWALL PL',
        RegAddrCity: 'BOISE',
        RegAddrState: 'ID',
        RegAddrZip: 83703,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '4801 W ALBION ST',
        RegAddrCity: 'BOISE',
        RegAddrState: 'ID',
        RegAddrZip: 83705,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '107 S CYPRESS ST',
        RegAddrCity: 'BOISE',
        RegAddrState: 'ID',
        RegAddrZip: 83705,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2500 W JEAN ST',
        RegAddrCity: 'BOISE',
        RegAddrState: 'ID',
        RegAddrZip: 83705,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1502 S LEADVILLE AVE',
        RegAddrCity: 'BOISE',
        RegAddrState: 'ID',
        RegAddrZip: 83706,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1211 S MICHIGAN AVE',
        RegAddrCity: 'BOISE',
        RegAddrState: 'ID',
        RegAddrZip: 83706,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '7595 S HIDDEN VALLEY DR',
        RegAddrCity: 'BOISE',
        RegAddrState: 'ID',
        RegAddrZip: 83709,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '6312 W DORIAN ST',
        RegAddrCity: 'BOISE',
        RegAddrState: 'ID',
        RegAddrZip: 83709,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '711 S GRANITE WAY',
        RegAddrCity: 'BOISE',
        RegAddrState: 'ID',
        RegAddrZip: 83712,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '5135 S BOVEN AVE',
        RegAddrCity: 'BOISE',
        RegAddrState: 'ID',
        RegAddrZip: 83716,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '724 14TH AVE N',
        RegAddrCity: 'BUHL',
        RegAddrState: 'ID',
        RegAddrZip: 83316,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2351 CEDARWOOD AVE',
        RegAddrCity: 'BURLEY',
        RegAddrState: 'ID',
        RegAddrZip: 83318,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2143 ALBION AVE',
        RegAddrCity: 'BURLEY',
        RegAddrState: 'ID',
        RegAddrZip: 83318,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '626 ELBA AVE',
        RegAddrCity: 'BURLEY',
        RegAddrState: 'ID',
        RegAddrZip: 83318,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '4856 BROOKSTONE ST',
        RegAddrCity: 'CHUBBUCK',
        RegAddrState: 'ID',
        RegAddrZip: 83202,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '4424 N 2000 E',
        RegAddrCity: 'FILER',
        RegAddrState: 'ID',
        RegAddrZip: 83328,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '120 W MIDWAY ST',
        RegAddrCity: 'FILER',
        RegAddrState: 'ID',
        RegAddrZip: 83328,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '3347 S 3600 E',
        RegAddrCity: 'FRANKLIN',
        RegAddrState: 'ID',
        RegAddrZip: 83237,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '8984 W HEPBURN LN',
        RegAddrCity: 'GARDEN CITY',
        RegAddrState: 'ID',
        RegAddrZip: 83714,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '3724 N WILLOWBAR LN',
        RegAddrCity: 'GARDEN CITY',
        RegAddrState: 'ID',
        RegAddrZip: 83714,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '5602 BULLRUN LN',
        RegAddrCity: 'GARDEN CITY',
        RegAddrState: 'ID',
        RegAddrZip: 83714,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '325 19TH AVE W',
        RegAddrCity: 'GOODING',
        RegAddrState: 'ID',
        RegAddrZip: 83330,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '18208 HIGHWAY 30',
        RegAddrCity: 'HAGERMAN',
        RegAddrState: 'ID',
        RegAddrZip: 83332,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '302 SHAUN LN',
        RegAddrCity: 'HAILEY',
        RegAddrState: 'ID',
        RegAddrZip: 83333,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '3652 E 3300 N',
        RegAddrCity: 'HANSEN',
        RegAddrState: 'ID',
        RegAddrZip: 83334,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1340 19TH ST',
        RegAddrCity: 'HEYBURN',
        RegAddrState: 'ID',
        RegAddrZip: 83336,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '570 LYN AVE',
        RegAddrCity: 'IDAHO FALLS',
        RegAddrState: 'ID',
        RegAddrZip: 83401,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '6557 N ORLINDA LN',
        RegAddrCity: 'IDAHO FALLS',
        RegAddrState: 'ID',
        RegAddrZip: 83401,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '690 NE BONNEVILLE DR',
        RegAddrCity: 'IDAHO FALLS',
        RegAddrState: 'ID',
        RegAddrZip: 83401,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '3540 E MICHELLE ST',
        RegAddrCity: 'IDAHO FALLS',
        RegAddrState: 'ID',
        RegAddrZip: 83401,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '9438 COTTONWOOD CIR',
        RegAddrCity: 'IDAHO FALLS',
        RegAddrState: 'ID',
        RegAddrZip: 83401,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '120 N MORNINGSIDE DR',
        RegAddrCity: 'IDAHO FALLS',
        RegAddrState: 'ID',
        RegAddrZip: 83402,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2920 SIMON ST',
        RegAddrCity: 'IDAHO FALLS',
        RegAddrState: 'ID',
        RegAddrZip: 83402,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '797 PESCADERO PL',
        RegAddrCity: 'IDAHO FALLS',
        RegAddrState: 'ID',
        RegAddrZip: 83404,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '62 E LETA DR',
        RegAddrCity: 'INKOM',
        RegAddrState: 'ID',
        RegAddrZip: 83245,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '350 W 350 S',
        RegAddrCity: 'JEROME',
        RegAddrState: 'ID',
        RegAddrZip: 83338,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '3535 E 3195 N',
        RegAddrCity: 'KIMBERLY',
        RegAddrState: 'ID',
        RegAddrZip: 83341,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2141 W 500 S',
        RegAddrCity: 'MALAD',
        RegAddrState: 'ID',
        RegAddrZip: 83252,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '3503 E 630 N',
        RegAddrCity: 'MENAN',
        RegAddrState: 'ID',
        RegAddrZip: 83434,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '3044 NW CANAL RD',
        RegAddrCity: 'MOUNTAIN HOME',
        RegAddrState: 'ID',
        RegAddrZip: 83647,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '82 NW NASHUA ST',
        RegAddrCity: 'MOUNTAIN HOME',
        RegAddrState: 'ID',
        RegAddrZip: 83647,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '735 S 13TH E',
        RegAddrCity: 'MOUNTAIN HOME',
        RegAddrState: 'ID',
        RegAddrZip: 83647,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '18114 LANTANA AVE',
        RegAddrCity: 'NAMPA',
        RegAddrState: 'ID',
        RegAddrZip: 83687,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '569 W 1650 S',
        RegAddrCity: 'OAKLEY',
        RegAddrState: 'ID',
        RegAddrZip: 83346,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '145 FULLER WAY AVE',
        RegAddrCity: 'POCATELLO',
        RegAddrState: 'ID',
        RegAddrZip: 83201,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '168 WILLARD AVE',
        RegAddrCity: 'POCATELLO',
        RegAddrState: 'ID',
        RegAddrZip: 83201,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1735 BETH ST',
        RegAddrCity: 'POCATELLO',
        RegAddrState: 'ID',
        RegAddrZip: 83201,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '14319 W PROMISE LN',
        RegAddrCity: 'POCATELLO',
        RegAddrState: 'ID',
        RegAddrZip: 83202,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1 DRIFTWOOD ST',
        RegAddrCity: 'POCATELLO',
        RegAddrState: 'ID',
        RegAddrZip: 83204,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '639 S 4TH E',
        RegAddrCity: 'PRESTON',
        RegAddrState: 'ID',
        RegAddrZip: 83263,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '793 E 600 S',
        RegAddrCity: 'PRESTON',
        RegAddrState: 'ID',
        RegAddrZip: 83263,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '338 W 1ST S',
        RegAddrCity: 'REXBURG',
        RegAddrState: 'ID',
        RegAddrZip: 83440,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '3646 E 400 N',
        RegAddrCity: 'RIGBY',
        RegAddrState: 'ID',
        RegAddrZip: 83442,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '653 N 2650 E',
        RegAddrCity: 'SAINT ANTHONY',
        RegAddrState: 'ID',
        RegAddrZip: 83445,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '941 N 900 E',
        RegAddrCity: 'SHELLEY',
        RegAddrState: 'ID',
        RegAddrZip: 83274,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '268 MADISON ST',
        RegAddrCity: 'TWIN FALLS',
        RegAddrState: 'ID',
        RegAddrZip: 83301,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1330 HOLLY DR',
        RegAddrCity: 'TWIN FALLS',
        RegAddrState: 'ID',
        RegAddrZip: 83301,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1064 WENDELL ST',
        RegAddrCity: 'TWIN FALLS',
        RegAddrState: 'ID',
        RegAddrZip: 83301,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '3647 E 108TH N',
        RegAddrCity: 'UCON',
        RegAddrState: 'ID',
        RegAddrZip: 83454,
        CongressionalDistrict: 2,
      },
    ],
  },
  {
    name: 'IL-1',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/IL-1?ref=gh-pages',
    state: 'IL',
    state_and_number: 'IL-1',
    zip: 60406,
    congressionalDistrict: 1,
    addressesInDistrict: [
      {
        RegAddrLine1: '12240 S 44TH PL',
        RegAddrCity: 'ALSIP',
        RegAddrState: 'IL',
        RegAddrZip: 60803,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '12043 S PRAIRIE DR',
        RegAddrCity: 'ALSIP',
        RegAddrState: 'IL',
        RegAddrZip: 60803,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '12428 S MORGAN ST',
        RegAddrCity: 'CALUMET PARK',
        RegAddrState: 'IL',
        RegAddrZip: 60827,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '9411 S PRAIRIE AVE',
        RegAddrCity: 'CHICAGO',
        RegAddrState: 'IL',
        RegAddrZip: 60619,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '8736 S HARPER AVE',
        RegAddrCity: 'CHICAGO',
        RegAddrState: 'IL',
        RegAddrZip: 60619,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '843 E 89TH ST',
        RegAddrCity: 'CHICAGO',
        RegAddrState: 'IL',
        RegAddrZip: 60619,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '201 E 82ND ST',
        RegAddrCity: 'CHICAGO',
        RegAddrState: 'IL',
        RegAddrZip: 60619,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '7411 S EBERHART AVE',
        RegAddrCity: 'CHICAGO',
        RegAddrState: 'IL',
        RegAddrZip: 60619,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '8849 S JUSTINE ST',
        RegAddrCity: 'CHICAGO',
        RegAddrState: 'IL',
        RegAddrZip: 60620,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '9041 S RACINE AVE',
        RegAddrCity: 'CHICAGO',
        RegAddrState: 'IL',
        RegAddrZip: 60620,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '7950 S PRINCETON AVE',
        RegAddrCity: 'CHICAGO',
        RegAddrState: 'IL',
        RegAddrZip: 60620,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '7819 S HAMILTON AVE',
        RegAddrCity: 'CHICAGO',
        RegAddrState: 'IL',
        RegAddrZip: 60620,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '8600 S MARSHFIELD AVE',
        RegAddrCity: 'CHICAGO',
        RegAddrState: 'IL',
        RegAddrZip: 60620,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '6414 S HARVARD AVE',
        RegAddrCity: 'CHICAGO',
        RegAddrState: 'IL',
        RegAddrZip: 60621,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '6418 S MAY ST',
        RegAddrCity: 'CHICAGO',
        RegAddrState: 'IL',
        RegAddrZip: 60621,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '7254 S WHIPPLE ST',
        RegAddrCity: 'CHICAGO',
        RegAddrState: 'IL',
        RegAddrZip: 60629,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '6948 S ROCKWELL ST',
        RegAddrCity: 'CHICAGO',
        RegAddrState: 'IL',
        RegAddrZip: 60629,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '5500 S UNIVERSITY AVE',
        RegAddrCity: 'CHICAGO',
        RegAddrState: 'IL',
        RegAddrZip: 60637,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '6411 S MARYLAND AVE',
        RegAddrCity: 'CHICAGO',
        RegAddrState: 'IL',
        RegAddrZip: 60637,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '11521 S BISHOP ST',
        RegAddrCity: 'CHICAGO',
        RegAddrState: 'IL',
        RegAddrZip: 60643,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '6742 S CORNELL AVE',
        RegAddrCity: 'CHICAGO',
        RegAddrState: 'IL',
        RegAddrZip: 60649,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2706 W 86TH ST',
        RegAddrCity: 'CHICAGO',
        RegAddrState: 'IL',
        RegAddrZip: 60652,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '18900 W MANHATTAN RD',
        RegAddrCity: 'ELWOOD',
        RegAddrState: 'IL',
        RegAddrZip: 60421,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '900 MEADOWBROOK RD',
        RegAddrCity: 'ELWOOD',
        RegAddrState: 'IL',
        RegAddrZip: 60421,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '9251 S RICHMOND AVE',
        RegAddrCity: 'EVERGREEN PK',
        RegAddrState: 'IL',
        RegAddrZip: 60805,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '9717 S CENTRAL PARK AVE',
        RegAddrCity: 'EVERGREEN PK',
        RegAddrState: 'IL',
        RegAddrZip: 60805,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '20107 S KINGSTON CT',
        RegAddrCity: 'FRANKFORT',
        RegAddrState: 'IL',
        RegAddrZip: 60423,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '10700 OAKTON CT',
        RegAddrCity: 'FRANKFORT',
        RegAddrState: 'IL',
        RegAddrZip: 60423,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '130 W PRAIRIE AVE',
        RegAddrCity: 'MANHATTAN',
        RegAddrState: 'IL',
        RegAddrZip: 60442,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '5108 148TH ST',
        RegAddrCity: 'MIDLOTHIAN',
        RegAddrState: 'IL',
        RegAddrZip: 60445,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '19418 STONEHENGE DR',
        RegAddrCity: 'MOKENA',
        RegAddrState: 'IL',
        RegAddrZip: 60448,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '19538 115TH AVE',
        RegAddrCity: 'MOKENA',
        RegAddrState: 'IL',
        RegAddrZip: 60448,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '694 SCHOONER DR',
        RegAddrCity: 'NEW LENOX',
        RegAddrState: 'IL',
        RegAddrZip: 60451,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1724 MUIRFIELD DR',
        RegAddrCity: 'NEW LENOX',
        RegAddrState: 'IL',
        RegAddrZip: 60451,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '14829 S BLAINE AVE',
        RegAddrCity: 'POSEN',
        RegAddrState: 'IL',
        RegAddrZip: 60469,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '17053 ODELL AVE',
        RegAddrCity: 'TINLEY PARK',
        RegAddrState: 'IL',
        RegAddrZip: 60477,
        CongressionalDistrict: 1,
      },
    ],
  },
  {
    name: 'IL-10',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/IL-10?ref=gh-pages',
    state: 'IL',
    state_and_number: 'IL-10',
    zip: 60015,
    congressionalDistrict: 10,
    addressesInDistrict: [
      {
        RegAddrLine1: '663 LE PARC CIR',
        RegAddrCity: 'BUFFALO GROVE',
        RegAddrState: 'IL',
        RegAddrZip: 60089,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '144 WILLOW AVE',
        RegAddrCity: 'DEERFIELD',
        RegAddrState: 'IL',
        RegAddrZip: 60015,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '870 KYLEMORE DR',
        RegAddrCity: 'DES PLAINES',
        RegAddrState: 'IL',
        RegAddrZip: 60016,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '2404 INDIAN RIDGE DR',
        RegAddrCity: 'GLENVIEW',
        RegAddrState: 'IL',
        RegAddrZip: 60026,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '365 WOODLAND DR',
        RegAddrCity: 'GRAYSLAKE',
        RegAddrState: 'IL',
        RegAddrZip: 60030,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '1202 PINTO LN',
        RegAddrCity: 'GRAYSLAKE',
        RegAddrState: 'IL',
        RegAddrZip: 60030,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '2108 KENTON LN',
        RegAddrCity: 'GREEN OAKS',
        RegAddrState: 'IL',
        RegAddrZip: 60048,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '4485 LONGMEADOW DR',
        RegAddrCity: 'GURNEE',
        RegAddrState: 'IL',
        RegAddrZip: 60031,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '1102 ANDEAN PL',
        RegAddrCity: 'HIGHLAND PARK',
        RegAddrState: 'IL',
        RegAddrZip: 60035,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '506 CHEROKEE RD',
        RegAddrCity: 'HIGHLAND PARK',
        RegAddrState: 'IL',
        RegAddrZip: 60035,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '32 VALLEY RD',
        RegAddrCity: 'HIGHLAND PARK',
        RegAddrState: 'IL',
        RegAddrZip: 60035,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '1267 KAJER LN',
        RegAddrCity: 'LAKE FOREST',
        RegAddrState: 'IL',
        RegAddrZip: 60045,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '840 SYMPHONY ST',
        RegAddrCity: 'LAKE FOREST',
        RegAddrState: 'IL',
        RegAddrZip: 60045,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '659 BLAZING STAR DR',
        RegAddrCity: 'LAKE VILLA',
        RegAddrState: 'IL',
        RegAddrZip: 60046,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '24 WIMBLEDON CT',
        RegAddrCity: 'LINCOLNSHIRE',
        RegAddrState: 'IL',
        RegAddrZip: 60069,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '231 SPRINGBROOK CT',
        RegAddrCity: 'MUNDELEIN',
        RegAddrState: 'IL',
        RegAddrZip: 60060,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '743 S LAKE ST',
        RegAddrCity: 'MUNDELEIN',
        RegAddrState: 'IL',
        RegAddrZip: 60060,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '1656 BRIGHTON DR',
        RegAddrCity: 'MUNDELEIN',
        RegAddrState: 'IL',
        RegAddrZip: 60060,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '9747 N HUBER OVAL',
        RegAddrCity: 'NILES',
        RegAddrState: 'IL',
        RegAddrZip: 60714,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '1804 ELIZABETH AVE',
        RegAddrCity: 'NORTH CHICAGO',
        RegAddrState: 'IL',
        RegAddrZip: 60064,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '47 CARIBOU XING',
        RegAddrCity: 'NORTHBROOK',
        RegAddrState: 'IL',
        RegAddrZip: 60062,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '3062 ANTELOPE SPRINGS RD',
        RegAddrCity: 'NORTHBROOK',
        RegAddrState: 'IL',
        RegAddrZip: 60062,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '1101 N OAK TER',
        RegAddrCity: 'ROUND LK BCH',
        RegAddrState: 'IL',
        RegAddrZip: 60073,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '203 GENEVA TER',
        RegAddrCity: 'ROUND LK BCH',
        RegAddrState: 'IL',
        RegAddrZip: 60073,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '413 PUBLIC SERVICE RD',
        RegAddrCity: 'WAUKEGAN',
        RegAddrState: 'IL',
        RegAddrZip: 60085,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '12986 W GROVE AVE',
        RegAddrCity: 'WAUKEGAN',
        RegAddrState: 'IL',
        RegAddrZip: 60085,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '2118 CHESTNUT ST',
        RegAddrCity: 'WAUKEGAN',
        RegAddrState: 'IL',
        RegAddrZip: 60087,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '36960 N GARRICK AVE',
        RegAddrCity: 'WAUKEGAN',
        RegAddrState: 'IL',
        RegAddrZip: 60087,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '3510 STONEGATE RD',
        RegAddrCity: 'WAUKEGAN',
        RegAddrState: 'IL',
        RegAddrZip: 60087,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '1422 FULTON AVE',
        RegAddrCity: 'WINTHROP HARBOR',
        RegAddrState: 'IL',
        RegAddrZip: 60096,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '1235 PHEASANT RUN',
        RegAddrCity: 'ZION',
        RegAddrState: 'IL',
        RegAddrZip: 60099,
        CongressionalDistrict: 10,
      },
    ],
  },
  {
    name: 'IL-11',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/IL-11?ref=gh-pages',
    state: 'IL',
    state_and_number: 'IL-11',
    zip: 60404,
    congressionalDistrict: 11,
    addressesInDistrict: [
      {
        RegAddrLine1: '740 PANORAMA CT',
        RegAddrCity: 'AURORA',
        RegAddrState: 'IL',
        RegAddrZip: 60502,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '2966 DIANE DR',
        RegAddrCity: 'AURORA',
        RegAddrState: 'IL',
        RegAddrZip: 60504,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '1700 SIMMS ST',
        RegAddrCity: 'AURORA',
        RegAddrState: 'IL',
        RegAddrZip: 60504,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '1435 LIBERTY ST',
        RegAddrCity: 'AURORA',
        RegAddrState: 'IL',
        RegAddrZip: 60505,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '813 KENMORE AVE',
        RegAddrCity: 'AURORA',
        RegAddrState: 'IL',
        RegAddrZip: 60505,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '1515 EXPOSITION AVE',
        RegAddrCity: 'AURORA',
        RegAddrState: 'IL',
        RegAddrZip: 60506,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '10 N WESTERN AVE',
        RegAddrCity: 'AURORA',
        RegAddrState: 'IL',
        RegAddrZip: 60506,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '310 SUNSET AVE',
        RegAddrCity: 'AURORA',
        RegAddrState: 'IL',
        RegAddrZip: 60506,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '336 CLIFTON LN',
        RegAddrCity: 'BOLINGBROOK',
        RegAddrState: 'IL',
        RegAddrZip: 60440,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '111 QUEENSWOOD RD',
        RegAddrCity: 'BOLINGBROOK',
        RegAddrState: 'IL',
        RegAddrZip: 60440,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '267 PLAINVIEW DR',
        RegAddrCity: 'BOLINGBROOK',
        RegAddrState: 'IL',
        RegAddrZip: 60440,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '427 DEVON LN',
        RegAddrCity: 'BOLINGBROOK',
        RegAddrState: 'IL',
        RegAddrZip: 60440,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '607 HILLCREST DR',
        RegAddrCity: 'BOLINGBROOK',
        RegAddrState: 'IL',
        RegAddrZip: 60440,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '1540 TRAILS END LN',
        RegAddrCity: 'BOLINGBROOK',
        RegAddrState: 'IL',
        RegAddrZip: 60490,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '16 HIDDEN LAKE DR',
        RegAddrCity: 'BURR RIDGE',
        RegAddrState: 'IL',
        RegAddrZip: 60527,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '1409 WILLARD PL',
        RegAddrCity: 'DOWNERS GROVE',
        RegAddrState: 'IL',
        RegAddrZip: 60516,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '8506 BUCKINGHAM RD',
        RegAddrCity: 'JOLIET',
        RegAddrState: 'IL',
        RegAddrZip: 60431,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '8011 AARON LN',
        RegAddrCity: 'JOLIET',
        RegAddrState: 'IL',
        RegAddrZip: 60431,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '1731 YEW CT',
        RegAddrCity: 'JOLIET',
        RegAddrState: 'IL',
        RegAddrZip: 60433,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '1203 CLEMENT ST',
        RegAddrCity: 'JOLIET',
        RegAddrState: 'IL',
        RegAddrZip: 60435,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '6167 HARTH CT',
        RegAddrCity: 'LISLE',
        RegAddrState: 'IL',
        RegAddrZip: 60532,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '1453 FAIRWAY DR',
        RegAddrCity: 'NAPERVILLE',
        RegAddrState: 'IL',
        RegAddrZip: 60563,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '950 CAMPBELL DR',
        RegAddrCity: 'NAPERVILLE',
        RegAddrState: 'IL',
        RegAddrZip: 60563,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '5S270 STEWART DR',
        RegAddrCity: 'NAPERVILLE',
        RegAddrState: 'IL',
        RegAddrZip: 60563,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '72 SALT RIVER CT',
        RegAddrCity: 'NAPERVILLE',
        RegAddrState: 'IL',
        RegAddrZip: 60565,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '143 OAK ST',
        RegAddrCity: 'NEW LENOX',
        RegAddrState: 'IL',
        RegAddrZip: 60451,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '561 HERITAGE DR',
        RegAddrCity: 'OSWEGO',
        RegAddrState: 'IL',
        RegAddrZip: 60543,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '609 PLYMOUTH CT',
        RegAddrCity: 'OSWEGO',
        RegAddrState: 'IL',
        RegAddrZip: 60543,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '7417 SOUTHWORTH CIR',
        RegAddrCity: 'PLAINFIELD',
        RegAddrState: 'IL',
        RegAddrZip: 60586,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '2818 FRANK TURK DR',
        RegAddrCity: 'PLAINFIELD',
        RegAddrState: 'IL',
        RegAddrZip: 60586,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '1651 ROSE LN',
        RegAddrCity: 'ROMEOVILLE',
        RegAddrState: 'IL',
        RegAddrZip: 60446,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '171 MOUNTAIN LAUREL CT',
        RegAddrCity: 'ROMEOVILLE',
        RegAddrState: 'IL',
        RegAddrZip: 60446,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '3012 JONQUIL LN',
        RegAddrCity: 'WOODRIDGE',
        RegAddrState: 'IL',
        RegAddrZip: 60517,
        CongressionalDistrict: 11,
      },
    ],
  },
  {
    name: 'IL-12',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/IL-12?ref=gh-pages',
    state: 'IL',
    state_and_number: 'IL-12',
    zip: 62002,
    congressionalDistrict: 12,
    addressesInDistrict: [
      {
        RegAddrLine1: '3945 ALCOA',
        RegAddrCity: 'ALORTON',
        RegAddrState: 'IL',
        RegAddrZip: 62207,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '2924 WATALEE ST',
        RegAddrCity: 'ALTON',
        RegAddrState: 'IL',
        RegAddrZip: 62002,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '311 PROSPECT ST',
        RegAddrCity: 'ALTON',
        RegAddrState: 'IL',
        RegAddrZip: 62002,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '1629 S RODGERS AVE',
        RegAddrCity: 'ALTON',
        RegAddrState: 'IL',
        RegAddrZip: 62002,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '45 BOSWELL RD',
        RegAddrCity: 'ANNA',
        RegAddrState: 'IL',
        RegAddrZip: 62906,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '408 STONE RIVER RD',
        RegAddrCity: 'BELLEVILLE',
        RegAddrState: 'IL',
        RegAddrZip: 62221,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '3401 VERNIER AVE',
        RegAddrCity: 'BELLEVILLE',
        RegAddrState: 'IL',
        RegAddrZip: 62226,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '424 N 39TH ST',
        RegAddrCity: 'BELLEVILLE',
        RegAddrState: 'IL',
        RegAddrZip: 62226,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '1706 W SUNSET DR',
        RegAddrCity: 'CARBONDALE',
        RegAddrState: 'IL',
        RegAddrZip: 62901,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '1387 W NO NAME RD',
        RegAddrCity: 'CARBONDALE',
        RegAddrState: 'IL',
        RegAddrZip: 62902,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '7710 LITTLE WOLF RD',
        RegAddrCity: 'CARBONDALE',
        RegAddrState: 'IL',
        RegAddrZip: 62902,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '309 W WARNOCK ST',
        RegAddrCity: 'COLUMBIA',
        RegAddrState: 'IL',
        RegAddrZip: 62236,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '1110 DE COUAGNE DR',
        RegAddrCity: 'E SAINT LOUIS',
        RegAddrState: 'IL',
        RegAddrZip: 62203,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '1301 N 15TH ST',
        RegAddrCity: 'E SAINT LOUIS',
        RegAddrState: 'IL',
        RegAddrZip: 62205,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '1502 COLLEGE AVE',
        RegAddrCity: 'EAST SAINT LOUIS',
        RegAddrState: 'IL',
        RegAddrZip: 62205,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '401 E TEMPLE ST',
        RegAddrCity: 'FREEBURG',
        RegAddrState: 'IL',
        RegAddrZip: 62243,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '1614 SPRUCE ST',
        RegAddrCity: 'GRANITE CITY',
        RegAddrState: 'IL',
        RegAddrZip: 62040,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '600 PAUL CT',
        RegAddrCity: 'HERRIN',
        RegAddrState: 'IL',
        RegAddrZip: 62948,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '931 NEUHAUS LN',
        RegAddrCity: 'JACOB',
        RegAddrState: 'IL',
        RegAddrZip: 62950,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '110 E LINCOLN ST',
        RegAddrCity: 'KARNAK',
        RegAddrState: 'IL',
        RegAddrZip: 62956,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '48 HARMON DR',
        RegAddrCity: 'LEBANON',
        RegAddrState: 'IL',
        RegAddrZip: 62254,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '215 W WHITE ST',
        RegAddrCity: 'MILLSTADT',
        RegAddrState: 'IL',
        RegAddrZip: 62260,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '7 SANDRIDGE DR',
        RegAddrCity: 'NEW ATHENS',
        RegAddrState: 'IL',
        RegAddrZip: 62264,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '1579 ALLYSSA CREEK CT',
        RegAddrCity: 'O FALLON',
        RegAddrState: 'IL',
        RegAddrZip: 62269,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '220 W ADAMS ST',
        RegAddrCity: 'O FALLON',
        RegAddrState: 'IL',
        RegAddrZip: 62269,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '10865 RIDGE RD',
        RegAddrCity: 'SPARTA',
        RegAddrState: 'IL',
        RegAddrZip: 62286,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '233 SAINT SABRE DR',
        RegAddrCity: 'SWANSEA',
        RegAddrState: 'IL',
        RegAddrZip: 62226,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '1129 MAPLEWOOD LN',
        RegAddrCity: 'WATERLOO',
        RegAddrState: 'IL',
        RegAddrZip: 62298,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '635 WHITELAW AVE',
        RegAddrCity: 'WOOD RIVER',
        RegAddrState: 'IL',
        RegAddrZip: 62095,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '13437 N WOODLAWN LN',
        RegAddrCity: 'WOODLAWN',
        RegAddrState: 'IL',
        RegAddrZip: 62898,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '5559 E RICHVIEW RD',
        RegAddrCity: 'WOODLAWN',
        RegAddrState: 'IL',
        RegAddrZip: 62898,
        CongressionalDistrict: 12,
      },
    ],
  },
  {
    name: 'IL-13',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/IL-13?ref=gh-pages',
    state: 'IL',
    state_and_number: 'IL-13',
    zip: 61701,
    congressionalDistrict: 13,
    addressesInDistrict: [
      {
        RegAddrLine1: '156 DADDONO CIR',
        RegAddrCity: 'BLOOMINGTON',
        RegAddrState: 'IL',
        RegAddrZip: 61701,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '1108 S LEE ST',
        RegAddrCity: 'BLOOMINGTON',
        RegAddrState: 'IL',
        RegAddrZip: 61701,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '3265 HICKORY RIDGE LN',
        RegAddrCity: 'BRIGHTON',
        RegAddrState: 'IL',
        RegAddrZip: 62012,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '302 E PARK ST',
        RegAddrCity: 'CHAMPAIGN',
        RegAddrState: 'IL',
        RegAddrZip: 61820,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '203 W STANAGE AVE',
        RegAddrCity: 'CHAMPAIGN',
        RegAddrState: 'IL',
        RegAddrZip: 61820,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '1209 W DANIEL ST',
        RegAddrCity: 'CHAMPAIGN',
        RegAddrState: 'IL',
        RegAddrZip: 61821,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '1704 W GREEN ST',
        RegAddrCity: 'CHAMPAIGN',
        RegAddrState: 'IL',
        RegAddrZip: 61821,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '1807 BROADMOOR DR',
        RegAddrCity: 'CHAMPAIGN',
        RegAddrState: 'IL',
        RegAddrZip: 61821,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '2409 ROLLING ACRES DR',
        RegAddrCity: 'CHAMPAIGN',
        RegAddrState: 'IL',
        RegAddrZip: 61822,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '0 R R 1',
        RegAddrCity: 'CLINTON',
        RegAddrState: 'IL',
        RegAddrZip: 61727,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '565 W COUNTRY LN',
        RegAddrCity: 'COLLINSVILLE',
        RegAddrState: 'IL',
        RegAddrZip: 62234,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '212 S GLENCOE AVE',
        RegAddrCity: 'DECATUR',
        RegAddrState: 'IL',
        RegAddrZip: 62522,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '670 S MONROE ST',
        RegAddrCity: 'DECATUR',
        RegAddrState: 'IL',
        RegAddrZip: 62522,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '770 W MOUND RD',
        RegAddrCity: 'DECATUR',
        RegAddrState: 'IL',
        RegAddrZip: 62526,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '2389 N LONGWOOD DR',
        RegAddrCity: 'DECATUR',
        RegAddrState: 'IL',
        RegAddrZip: 62526,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '176 W MARK AVE',
        RegAddrCity: 'DECATUR',
        RegAddrState: 'IL',
        RegAddrZip: 62526,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '314 M ST',
        RegAddrCity: 'EDWARDSVILLE',
        RegAddrState: 'IL',
        RegAddrZip: 62025,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '4836 STREET CAR RD',
        RegAddrCity: 'EDWARDSVILLE',
        RegAddrState: 'IL',
        RegAddrZip: 62025,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '14 CIRCLE DR',
        RegAddrCity: 'FARMERSVILLE',
        RegAddrState: 'IL',
        RegAddrZip: 62533,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '6429 SUNSET DR',
        RegAddrCity: 'GODFREY',
        RegAddrState: 'IL',
        RegAddrZip: 62035,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '1690 N STATE ST',
        RegAddrCity: 'MONTICELLO',
        RegAddrState: 'IL',
        RegAddrZip: 61856,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '900 SE 6TH ST',
        RegAddrCity: 'MORRISONVILLE',
        RegAddrState: 'IL',
        RegAddrZip: 62546,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '1961 STANHOPE RD',
        RegAddrCity: 'SPRINGFIELD',
        RegAddrState: 'IL',
        RegAddrZip: 62702,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '810 N 7TH ST',
        RegAddrCity: 'SPRINGFIELD',
        RegAddrState: 'IL',
        RegAddrZip: 62702,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '1110 SILVER ST',
        RegAddrCity: 'URBANA',
        RegAddrState: 'IL',
        RegAddrZip: 61801,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '1009 W PENNSYLVANIA AVE',
        RegAddrCity: 'URBANA',
        RegAddrState: 'IL',
        RegAddrZip: 61801,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '261 S JACKSONVILLE ST',
        RegAddrCity: 'WHITE HALL',
        RegAddrState: 'IL',
        RegAddrZip: 62092,
        CongressionalDistrict: 13,
      },
    ],
  },
  {
    name: 'IL-14',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/IL-14?ref=gh-pages',
    state: 'IL',
    state_and_number: 'IL-14',
    zip: 60002,
    congressionalDistrict: 14,
    addressesInDistrict: [
      {
        RegAddrLine1: '40482 N SOUTH NEWPORT DR',
        RegAddrCity: 'ANTIOCH',
        RegAddrState: 'IL',
        RegAddrZip: 60002,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '2482 QUAIL CT',
        RegAddrCity: 'AURORA',
        RegAddrState: 'IL',
        RegAddrZip: 60502,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '2320 BROOKSTONE DR',
        RegAddrCity: 'BOLINGBROOK',
        RegAddrState: 'IL',
        RegAddrZip: 60490,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '36 E HIGHLAND DR',
        RegAddrCity: 'BRISTOL',
        RegAddrState: 'IL',
        RegAddrZip: 60512,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '5814 GREENVIEW RD',
        RegAddrCity: 'CARY',
        RegAddrState: 'IL',
        RegAddrZip: 60013,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '70 N CAROLINE ST',
        RegAddrCity: 'CRYSTAL LAKE',
        RegAddrState: 'IL',
        RegAddrZip: 60014,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '1N077 DENALI RD',
        RegAddrCity: 'ELBURN',
        RegAddrState: 'IL',
        RegAddrZip: 60119,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '704 FOX RUN DR',
        RegAddrCity: 'GENEVA',
        RegAddrState: 'IL',
        RegAddrZip: 60134,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '10651 CAPITOL LN',
        RegAddrCity: 'HUNTLEY',
        RegAddrState: 'IL',
        RegAddrZip: 60142,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '27888 N BEECH ST',
        RegAddrCity: 'ISLAND LAKE',
        RegAddrState: 'IL',
        RegAddrZip: 60042,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '735 SUN LAKE RD',
        RegAddrCity: 'LAKE VILLA',
        RegAddrState: 'IL',
        RegAddrZip: 60046,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '211 S SHERIDAN RD',
        RegAddrCity: 'LAKEMOOR',
        RegAddrState: 'IL',
        RegAddrZip: 60051,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '23615 GRANGE RD',
        RegAddrCity: 'MARENGO',
        RegAddrState: 'IL',
        RegAddrZip: 60152,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '5809 AMHERST CT',
        RegAddrCity: 'MCHENRY',
        RegAddrState: 'IL',
        RegAddrZip: 60050,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '3510 TURNBERRY DR',
        RegAddrCity: 'MCHENRY',
        RegAddrState: 'IL',
        RegAddrZip: 60050,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '803 ROYAL DR',
        RegAddrCity: 'MCHENRY',
        RegAddrState: 'IL',
        RegAddrZip: 60050,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '2722 EVERGREEN CIR',
        RegAddrCity: 'MCHENRY',
        RegAddrState: 'IL',
        RegAddrZip: 60050,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '1404 GROMMON RD',
        RegAddrCity: 'NAPERVILLE',
        RegAddrState: 'IL',
        RegAddrZip: 60564,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '10 EASTWAY DR',
        RegAddrCity: 'OSWEGO',
        RegAddrState: 'IL',
        RegAddrZip: 60543,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '46 TOMAHAWK TRL',
        RegAddrCity: 'OSWEGO',
        RegAddrState: 'IL',
        RegAddrZip: 60543,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '346 S MADISON ST',
        RegAddrCity: 'OSWEGO',
        RegAddrState: 'IL',
        RegAddrZip: 60543,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '293 FOX CHASE DR N',
        RegAddrCity: 'OSWEGO',
        RegAddrState: 'IL',
        RegAddrZip: 60543,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '13802 S QUAIL RUN DR',
        RegAddrCity: 'PLAINFIELD',
        RegAddrState: 'IL',
        RegAddrZip: 60544,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '12736 GRANDE POPLAR CIR',
        RegAddrCity: 'PLAINFIELD',
        RegAddrState: 'IL',
        RegAddrZip: 60585,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '1716 FIELDSTONE CT',
        RegAddrCity: 'SHOREWOOD',
        RegAddrState: 'IL',
        RegAddrZip: 60404,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '4N065 WALT WHITMAN RD',
        RegAddrCity: 'ST CHARLES',
        RegAddrState: 'IL',
        RegAddrZip: 60175,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '6N143 WOODLAND RD',
        RegAddrCity: 'ST CHARLES',
        RegAddrState: 'IL',
        RegAddrZip: 60175,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '41W981 OAK HILL DR',
        RegAddrCity: 'ST CHARLES',
        RegAddrState: 'IL',
        RegAddrZip: 60175,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '3315 W LAKE SHORE DR',
        RegAddrCity: 'WONDER LAKE',
        RegAddrState: 'IL',
        RegAddrZip: 60097,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '1338 TAPPAN ST',
        RegAddrCity: 'WOODSTOCK',
        RegAddrState: 'IL',
        RegAddrZip: 60098,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '529 RIDGELAND AVE',
        RegAddrCity: 'WOODSTOCK',
        RegAddrState: 'IL',
        RegAddrZip: 60098,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '548 REDHORSE LN',
        RegAddrCity: 'YORKVILLE',
        RegAddrState: 'IL',
        RegAddrZip: 60560,
        CongressionalDistrict: 14,
      },
    ],
  },
  {
    name: 'IL-15',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/IL-15?ref=gh-pages',
    state: 'IL',
    state_and_number: 'IL-15',
    zip: 60932,
    congressionalDistrict: 15,
    addressesInDistrict: [
      {
        RegAddrLine1: '323 S CHICAGO AVE',
        RegAddrCity: 'BISMARCK',
        RegAddrState: 'IL',
        RegAddrZip: 61814,
        CongressionalDistrict: 15,
      },
      {
        RegAddrLine1: '13185 PRAIRIE GRASS LN',
        RegAddrCity: 'BREESE',
        RegAddrState: 'IL',
        RegAddrZip: 62230,
        CongressionalDistrict: 15,
      },
      {
        RegAddrLine1: '18 LAKEWOOD DR',
        RegAddrCity: 'CENTRALIA',
        RegAddrState: 'IL',
        RegAddrZip: 62801,
        CongressionalDistrict: 15,
      },
      {
        RegAddrLine1: '16 ARLINE DR',
        RegAddrCity: 'CENTRALIA',
        RegAddrState: 'IL',
        RegAddrZip: 62801,
        CongressionalDistrict: 15,
      },
      {
        RegAddrLine1: '15009 E COUNTY ROAD 420N',
        RegAddrCity: 'CHARLESTON',
        RegAddrState: 'IL',
        RegAddrZip: 61920,
        CongressionalDistrict: 15,
      },
      {
        RegAddrLine1: '7 PINEVIEW LN',
        RegAddrCity: 'COLLINSVILLE',
        RegAddrState: 'IL',
        RegAddrZip: 62234,
        CongressionalDistrict: 15,
      },
      {
        RegAddrLine1: '212 S THIRD ST',
        RegAddrCity: 'DAHLGREN',
        RegAddrState: 'IL',
        RegAddrZip: 62828,
        CongressionalDistrict: 15,
      },
      {
        RegAddrLine1: '1215 E FAIRCHILD ST',
        RegAddrCity: 'DANVILLE',
        RegAddrState: 'IL',
        RegAddrZip: 61832,
        CongressionalDistrict: 15,
      },
      {
        RegAddrLine1: '9746 E BLOOMFIELD HILLS DR',
        RegAddrCity: 'EFFINGHAM',
        RegAddrState: 'IL',
        RegAddrZip: 62401,
        CongressionalDistrict: 15,
      },
      {
        RegAddrLine1: '118 FOUR SEASONS EST',
        RegAddrCity: 'EFFINGHAM',
        RegAddrState: 'IL',
        RegAddrZip: 62401,
        CongressionalDistrict: 15,
      },
      {
        RegAddrLine1: '304 SW 7TH ST',
        RegAddrCity: 'FAIRFIELD',
        RegAddrState: 'IL',
        RegAddrZip: 62837,
        CongressionalDistrict: 15,
      },
      {
        RegAddrLine1: '5333 E 2000 NORTH RD',
        RegAddrCity: 'FITHIAN',
        RegAddrState: 'IL',
        RegAddrZip: 61844,
        CongressionalDistrict: 15,
      },
      {
        RegAddrLine1: '4061 OAK MOUND LN',
        RegAddrCity: 'FLORA',
        RegAddrState: 'IL',
        RegAddrZip: 62839,
        CongressionalDistrict: 15,
      },
      {
        RegAddrLine1: '7300 WOODLANE RD',
        RegAddrCity: 'GERMANTOWN',
        RegAddrState: 'IL',
        RegAddrZip: 62245,
        CongressionalDistrict: 15,
      },
      {
        RegAddrLine1: '10862 EVERGREEN BLVD',
        RegAddrCity: 'LAWRENCEVILLE',
        RegAddrState: 'IL',
        RegAddrZip: 62439,
        CongressionalDistrict: 15,
      },
      {
        RegAddrLine1: '201 E FAWN DR',
        RegAddrCity: 'MAHOMET',
        RegAddrState: 'IL',
        RegAddrZip: 61853,
        CongressionalDistrict: 15,
      },
      {
        RegAddrLine1: '114 S LAKE OF THE WOODS RD',
        RegAddrCity: 'MAHOMET',
        RegAddrState: 'IL',
        RegAddrZip: 61853,
        CongressionalDistrict: 15,
      },
      {
        RegAddrLine1: '412 CRESCENT DR',
        RegAddrCity: 'MATTOON',
        RegAddrState: 'IL',
        RegAddrZip: 61938,
        CongressionalDistrict: 15,
      },
      {
        RegAddrLine1: '3109 MARSHALL AVE',
        RegAddrCity: 'MATTOON',
        RegAddrState: 'IL',
        RegAddrZip: 61938,
        CongressionalDistrict: 15,
      },
      {
        RegAddrLine1: '108 N WALNUT ST',
        RegAddrCity: 'MCLEANSBORO',
        RegAddrState: 'IL',
        RegAddrZip: 62859,
        CongressionalDistrict: 15,
      },
      {
        RegAddrLine1: '5001 ORCHARD RD',
        RegAddrCity: 'METROPOLIS',
        RegAddrState: 'IL',
        RegAddrZip: 62960,
        CongressionalDistrict: 15,
      },
      {
        RegAddrLine1: '905 E WARREN ST',
        RegAddrCity: 'MOWEAQUA',
        RegAddrState: 'IL',
        RegAddrZip: 62550,
        CongressionalDistrict: 15,
      },
      {
        RegAddrLine1: '264 COUNTY ROAD 1000 N',
        RegAddrCity: 'NORRIS CITY',
        RegAddrState: 'IL',
        RegAddrZip: 62869,
        CongressionalDistrict: 15,
      },
      {
        RegAddrLine1: '2309 MIMOSA DR',
        RegAddrCity: 'OLNEY',
        RegAddrState: 'IL',
        RegAddrZip: 62450,
        CongressionalDistrict: 15,
      },
      {
        RegAddrLine1: '212 ZIMMERLY ST',
        RegAddrCity: 'PARIS',
        RegAddrState: 'IL',
        RegAddrZip: 61944,
        CongressionalDistrict: 15,
      },
      {
        RegAddrLine1: '14200 HAHN RD',
        RegAddrCity: 'SHATTUC',
        RegAddrState: 'IL',
        RegAddrZip: 62231,
        CongressionalDistrict: 15,
      },
      {
        RegAddrLine1: '1427 E 1450 NORTH RD',
        RegAddrCity: 'SHELBYVILLE',
        RegAddrState: 'IL',
        RegAddrZip: 62565,
        CongressionalDistrict: 15,
      },
      {
        RegAddrLine1: '316 W 5TH ST',
        RegAddrCity: 'ST ELMO',
        RegAddrState: 'IL',
        RegAddrZip: 62458,
        CongressionalDistrict: 15,
      },
      {
        RegAddrLine1: '300 E MAIN ST',
        RegAddrCity: 'TRILLA',
        RegAddrState: 'IL',
        RegAddrZip: 62469,
        CongressionalDistrict: 15,
      },
      {
        RegAddrLine1: '3014 N HIGH CROSS RD',
        RegAddrCity: 'URBANA',
        RegAddrState: 'IL',
        RegAddrZip: 61802,
        CongressionalDistrict: 15,
      },
      {
        RegAddrLine1: '1410 W SAINT LOUIS AVE',
        RegAddrCity: 'VANDALIA',
        RegAddrState: 'IL',
        RegAddrZip: 62471,
        CongressionalDistrict: 15,
      },
      {
        RegAddrLine1: '402 W CHURCH ST',
        RegAddrCity: 'VILLA GROVE',
        RegAddrState: 'IL',
        RegAddrZip: 61956,
        CongressionalDistrict: 15,
      },
      {
        RegAddrLine1: '115 DOLAK ST',
        RegAddrCity: 'WESTVILLE',
        RegAddrState: 'IL',
        RegAddrZip: 61883,
        CongressionalDistrict: 15,
      },
    ],
  },
  {
    name: 'IL-16',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/IL-16?ref=gh-pages',
    state: 'IL',
    state_and_number: 'IL-16',
    zip: 60111,
    congressionalDistrict: 16,
    addressesInDistrict: [
      {
        RegAddrLine1: '4629 TOWN HALL RD',
        RegAddrCity: 'BELVIDERE',
        RegAddrState: 'IL',
        RegAddrZip: 61008,
        CongressionalDistrict: 16,
      },
      {
        RegAddrLine1: '1224 EVERETT AVE',
        RegAddrCity: 'BELVIDERE',
        RegAddrState: 'IL',
        RegAddrZip: 61008,
        CongressionalDistrict: 16,
      },
      {
        RegAddrLine1: '26110 W HIGHLAND DR',
        RegAddrCity: 'CHANNAHON',
        RegAddrState: 'IL',
        RegAddrZip: 60410,
        CongressionalDistrict: 16,
      },
      {
        RegAddrLine1: '222 WILLETT AVE',
        RegAddrCity: 'DIXON',
        RegAddrState: 'IL',
        RegAddrZip: 61021,
        CongressionalDistrict: 16,
      },
      {
        RegAddrLine1: '325 SHERIDAN AVE',
        RegAddrCity: 'DIXON',
        RegAddrState: 'IL',
        RegAddrZip: 61021,
        CongressionalDistrict: 16,
      },
      {
        RegAddrLine1: '711 TYLER ST',
        RegAddrCity: 'GENOA',
        RegAddrState: 'IL',
        RegAddrZip: 60135,
        CongressionalDistrict: 16,
      },
      {
        RegAddrLine1: '520 E MULBERRY ST',
        RegAddrCity: 'HENNEPIN',
        RegAddrState: 'IL',
        RegAddrZip: 61327,
        CongressionalDistrict: 16,
      },
      {
        RegAddrLine1: '110 OAK ST',
        RegAddrCity: 'HOLCOMB',
        RegAddrState: 'IL',
        RegAddrZip: 61043,
        CongressionalDistrict: 16,
      },
      {
        RegAddrLine1: '1017 BAKER AVE',
        RegAddrCity: 'LA SALLE',
        RegAddrState: 'IL',
        RegAddrZip: 61301,
        CongressionalDistrict: 16,
      },
      {
        RegAddrLine1: '7520 SUFFIELD RD',
        RegAddrCity: 'LOVES PARK',
        RegAddrState: 'IL',
        RegAddrZip: 61111,
        CongressionalDistrict: 16,
      },
      {
        RegAddrLine1: '7321 CASEY DR',
        RegAddrCity: 'LOVES PARK',
        RegAddrState: 'IL',
        RegAddrZip: 61111,
        CongressionalDistrict: 16,
      },
      {
        RegAddrLine1: '4605 COMET DR',
        RegAddrCity: 'MACHESNEY PK',
        RegAddrState: 'IL',
        RegAddrZip: 61115,
        CongressionalDistrict: 16,
      },
      {
        RegAddrLine1: '1452 S SADDLEBROOK LN',
        RegAddrCity: 'MINOOKA',
        RegAddrState: 'IL',
        RegAddrZip: 60447,
        CongressionalDistrict: 16,
      },
      {
        RegAddrLine1: '313 RAILROAD AVE',
        RegAddrCity: 'NAPLATE',
        RegAddrState: 'IL',
        RegAddrZip: 61350,
        CongressionalDistrict: 16,
      },
      {
        RegAddrLine1: '119 MAIN ST',
        RegAddrCity: 'OGLESBY',
        RegAddrState: 'IL',
        RegAddrZip: 61348,
        CongressionalDistrict: 16,
      },
      {
        RegAddrLine1: '5967 N IL ROUTE 2',
        RegAddrCity: 'OREGON',
        RegAddrState: 'IL',
        RegAddrZip: 61061,
        CongressionalDistrict: 16,
      },
      {
        RegAddrLine1: '14640 BIRCH ST',
        RegAddrCity: 'ORLAND PARK',
        RegAddrState: 'IL',
        RegAddrZip: 60462,
        CongressionalDistrict: 16,
      },
      {
        RegAddrLine1: '210 ROCHESTER RD NE',
        RegAddrCity: 'POPLAR GROVE',
        RegAddrState: 'IL',
        RegAddrZip: 61065,
        CongressionalDistrict: 16,
      },
      {
        RegAddrLine1: '211 LINDER LN',
        RegAddrCity: 'ROCHELLE',
        RegAddrState: 'IL',
        RegAddrZip: 61068,
        CongressionalDistrict: 16,
      },
      {
        RegAddrLine1: '1116 SCOTT AVE',
        RegAddrCity: 'ROCHELLE',
        RegAddrState: 'IL',
        RegAddrZip: 61068,
        CongressionalDistrict: 16,
      },
      {
        RegAddrLine1: '1424 DEANNA DR',
        RegAddrCity: 'ROCKFORD',
        RegAddrState: 'IL',
        RegAddrZip: 61103,
        CongressionalDistrict: 16,
      },
      {
        RegAddrLine1: '3620 FAIRWAY PL',
        RegAddrCity: 'ROCKFORD',
        RegAddrState: 'IL',
        RegAddrZip: 61107,
        CongressionalDistrict: 16,
      },
      {
        RegAddrLine1: '1320 RADCLIFFE CT',
        RegAddrCity: 'ROCKFORD',
        RegAddrState: 'IL',
        RegAddrZip: 61108,
        CongressionalDistrict: 16,
      },
      {
        RegAddrLine1: '136 W RIVER ST',
        RegAddrCity: 'ROCKTON',
        RegAddrState: 'IL',
        RegAddrZip: 61072,
        CongressionalDistrict: 16,
      },
      {
        RegAddrLine1: '1307 AUTUMNWOOD CT',
        RegAddrCity: 'ROCKTON',
        RegAddrState: 'IL',
        RegAddrZip: 61072,
        CongressionalDistrict: 16,
      },
      {
        RegAddrLine1: '38 BRAMLEY ST',
        RegAddrCity: 'SAUNEMIN',
        RegAddrState: 'IL',
        RegAddrZip: 61769,
        CongressionalDistrict: 16,
      },
      {
        RegAddrLine1: '100 W CLEVELAND ST',
        RegAddrCity: 'SPRING VALLEY',
        RegAddrState: 'IL',
        RegAddrZip: 61362,
        CongressionalDistrict: 16,
      },
      {
        RegAddrLine1: '408 RIVER AVE',
        RegAddrCity: 'STREATOR',
        RegAddrState: 'IL',
        RegAddrZip: 61364,
        CongressionalDistrict: 16,
      },
    ],
  },
  {
    name: 'IL-17',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/IL-17?ref=gh-pages',
    state: 'IL',
    state_and_number: 'IL-17',
    zip: 61001,
    congressionalDistrict: 17,
    addressesInDistrict: [
      {
        RegAddrLine1: '308 E CENTER ST',
        RegAddrCity: 'CAMBRIDGE',
        RegAddrState: 'IL',
        RegAddrZip: 61238,
        CongressionalDistrict: 17,
      },
      {
        RegAddrLine1: '401 3RD ST S',
        RegAddrCity: 'CORDOVA',
        RegAddrState: 'IL',
        RegAddrZip: 61242,
        CongressionalDistrict: 17,
      },
      {
        RegAddrLine1: '537 N HIGHLAND ST',
        RegAddrCity: 'CREVE COEUR',
        RegAddrState: 'IL',
        RegAddrZip: 61610,
        CongressionalDistrict: 17,
      },
      {
        RegAddrLine1: '404 N WEST ST',
        RegAddrCity: 'DAVIS',
        RegAddrState: 'IL',
        RegAddrZip: 61019,
        CongressionalDistrict: 17,
      },
      {
        RegAddrLine1: '416 1/2 N APPLE AVE',
        RegAddrCity: 'FREEPORT',
        RegAddrState: 'IL',
        RegAddrZip: 61032,
        CongressionalDistrict: 17,
      },
      {
        RegAddrLine1: '1687 BATEMAN ST',
        RegAddrCity: 'GALESBURG',
        RegAddrState: 'IL',
        RegAddrZip: 61401,
        CongressionalDistrict: 17,
      },
      {
        RegAddrLine1: '503 SWANK ST',
        RegAddrCity: 'GALVA',
        RegAddrState: 'IL',
        RegAddrZip: 61434,
        CongressionalDistrict: 17,
      },
      {
        RegAddrLine1: '222 S SPRING ST',
        RegAddrCity: 'GENESEO',
        RegAddrState: 'IL',
        RegAddrZip: 61254,
        CongressionalDistrict: 17,
      },
      {
        RegAddrLine1: '1010 KELLOGG ST',
        RegAddrCity: 'GILSON',
        RegAddrState: 'IL',
        RegAddrZip: 61436,
        CongressionalDistrict: 17,
      },
      {
        RegAddrLine1: '29708 34TH AVE N',
        RegAddrCity: 'HILLSDALE',
        RegAddrState: 'IL',
        RegAddrZip: 61257,
        CongressionalDistrict: 17,
      },
      {
        RegAddrLine1: '2271 KNOX ROAD 2350 E',
        RegAddrCity: 'LA FAYETTE',
        RegAddrState: 'IL',
        RegAddrZip: 61449,
        CongressionalDistrict: 17,
      },
      {
        RegAddrLine1: '1151 25TH ST',
        RegAddrCity: 'MOLINE',
        RegAddrState: 'IL',
        RegAddrZip: 61265,
        CongressionalDistrict: 17,
      },
      {
        RegAddrLine1: '1621 16TH AVE',
        RegAddrCity: 'MOLINE',
        RegAddrState: 'IL',
        RegAddrZip: 61265,
        CongressionalDistrict: 17,
      },
      {
        RegAddrLine1: '1417 WINDSTAR LN',
        RegAddrCity: 'PEKIN',
        RegAddrState: 'IL',
        RegAddrZip: 61554,
        CongressionalDistrict: 17,
      },
      {
        RegAddrLine1: '1014 MECHANIC ST',
        RegAddrCity: 'PEKIN',
        RegAddrState: 'IL',
        RegAddrZip: 61554,
        CongressionalDistrict: 17,
      },
      {
        RegAddrLine1: '1111 E WILLCOX AVE',
        RegAddrCity: 'PEORIA',
        RegAddrState: 'IL',
        RegAddrZip: 61603,
        CongressionalDistrict: 17,
      },
      {
        RegAddrLine1: '2724 W RESERVOIR BLVD',
        RegAddrCity: 'PEORIA',
        RegAddrState: 'IL',
        RegAddrZip: 61615,
        CongressionalDistrict: 17,
      },
      {
        RegAddrLine1: '2545 24TH ST',
        RegAddrCity: 'ROCK ISLAND',
        RegAddrState: 'IL',
        RegAddrZip: 61201,
        CongressionalDistrict: 17,
      },
      {
        RegAddrLine1: '8001 7TH ST W',
        RegAddrCity: 'ROCK ISLAND',
        RegAddrState: 'IL',
        RegAddrZip: 61201,
        CongressionalDistrict: 17,
      },
      {
        RegAddrLine1: '1720 PIERCE AVE',
        RegAddrCity: 'ROCKFORD',
        RegAddrState: 'IL',
        RegAddrZip: 61103,
        CongressionalDistrict: 17,
      },
      {
        RegAddrLine1: '7329 WALLINGFORD WAY',
        RegAddrCity: 'ROCKFORD',
        RegAddrState: 'IL',
        RegAddrZip: 61107,
        CongressionalDistrict: 17,
      },
      {
        RegAddrLine1: '2028 23RD ST',
        RegAddrCity: 'ROCKFORD',
        RegAddrState: 'IL',
        RegAddrZip: 61108,
        CongressionalDistrict: 17,
      },
      {
        RegAddrLine1: '3022 RUTGERS PL',
        RegAddrCity: 'ROCKFORD',
        RegAddrState: 'IL',
        RegAddrZip: 61109,
        CongressionalDistrict: 17,
      },
      {
        RegAddrLine1: '11469 OAKTON RD',
        RegAddrCity: 'SAVANNA',
        RegAddrState: 'IL',
        RegAddrZip: 61074,
        CongressionalDistrict: 17,
      },
      {
        RegAddrLine1: '4110 N HAMMER RD',
        RegAddrCity: 'SCALES MOUND',
        RegAddrState: 'IL',
        RegAddrZip: 61075,
        CongressionalDistrict: 17,
      },
      {
        RegAddrLine1: '1407 15TH ST',
        RegAddrCity: 'SILVIS',
        RegAddrState: 'IL',
        RegAddrZip: 61282,
        CongressionalDistrict: 17,
      },
      {
        RegAddrLine1: '304 W 8TH ST',
        RegAddrCity: 'STERLING',
        RegAddrState: 'IL',
        RegAddrZip: 61081,
        CongressionalDistrict: 17,
      },
      {
        RegAddrLine1: '407 W 7TH ST',
        RegAddrCity: 'VERMONT',
        RegAddrState: 'IL',
        RegAddrZip: 61484,
        CongressionalDistrict: 17,
      },
      {
        RegAddrLine1: '2223 W HEADING AVE',
        RegAddrCity: 'WEST PEORIA',
        RegAddrState: 'IL',
        RegAddrZip: 61604,
        CongressionalDistrict: 17,
      },
    ],
  },
  {
    name: 'IL-18',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/IL-18?ref=gh-pages',
    state: 'IL',
    state_and_number: 'IL-18',
    zip: 61369,
    congressionalDistrict: 18,
    addressesInDistrict: [
      {
        RegAddrLine1: '720 COUNTRYSIDE DR',
        RegAddrCity: 'ARENZVILLE',
        RegAddrState: 'IL',
        RegAddrZip: 62611,
        CongressionalDistrict: 18,
      },
      {
        RegAddrLine1: '201 W SOUTH ST',
        RegAddrCity: 'ATLANTA',
        RegAddrState: 'IL',
        RegAddrZip: 61723,
        CongressionalDistrict: 18,
      },
      {
        RegAddrLine1: '219 W CALDWELL ST',
        RegAddrCity: 'AUBURN',
        RegAddrState: 'IL',
        RegAddrZip: 62615,
        CongressionalDistrict: 18,
      },
      {
        RegAddrLine1: '1325 N 1ST ST',
        RegAddrCity: 'CHILLICOTHE',
        RegAddrState: 'IL',
        RegAddrZip: 61523,
        CongressionalDistrict: 18,
      },
      {
        RegAddrLine1: '401 S EAST ST',
        RegAddrCity: 'COLCHESTER',
        RegAddrState: 'IL',
        RegAddrZip: 62326,
        CongressionalDistrict: 18,
      },
      {
        RegAddrLine1: '311 E COOPER ST',
        RegAddrCity: 'COLFAX',
        RegAddrState: 'IL',
        RegAddrZip: 61728,
        CongressionalDistrict: 18,
      },
      {
        RegAddrLine1: '121 FAWN HAVEN DR',
        RegAddrCity: 'EAST PEORIA',
        RegAddrState: 'IL',
        RegAddrZip: 61611,
        CongressionalDistrict: 18,
      },
      {
        RegAddrLine1: '200 E SWORDS DR',
        RegAddrCity: 'EDELSTEIN',
        RegAddrState: 'IL',
        RegAddrZip: 61526,
        CongressionalDistrict: 18,
      },
      {
        RegAddrLine1: '325 E FRONT ST',
        RegAddrCity: 'EL PASO',
        RegAddrState: 'IL',
        RegAddrZip: 61738,
        CongressionalDistrict: 18,
      },
      {
        RegAddrLine1: '22817 W ORION RD',
        RegAddrCity: 'FARMINGTON',
        RegAddrState: 'IL',
        RegAddrZip: 61531,
        CongressionalDistrict: 18,
      },
      {
        RegAddrLine1: '9010 W GOETZ RD',
        RegAddrCity: 'HANNA CITY',
        RegAddrState: 'IL',
        RegAddrZip: 61536,
        CongressionalDistrict: 18,
      },
      {
        RegAddrLine1: '766 S CHURCH ST',
        RegAddrCity: 'JACKSONVILLE',
        RegAddrState: 'IL',
        RegAddrZip: 62650,
        CongressionalDistrict: 18,
      },
      {
        RegAddrLine1: '1044 DOOLIN AVE',
        RegAddrCity: 'JACKSONVILLE',
        RegAddrState: 'IL',
        RegAddrZip: 62650,
        CongressionalDistrict: 18,
      },
      {
        RegAddrLine1: '505 SOUTH ST',
        RegAddrCity: 'LACON',
        RegAddrState: 'IL',
        RegAddrZip: 61540,
        CongressionalDistrict: 18,
      },
      {
        RegAddrLine1: '105 STALEY CT',
        RegAddrCity: 'LE ROY',
        RegAddrState: 'IL',
        RegAddrZip: 61752,
        CongressionalDistrict: 18,
      },
      {
        RegAddrLine1: '24699 N 2125 EAST RD',
        RegAddrCity: 'LEXINGTON',
        RegAddrState: 'IL',
        RegAddrZip: 61753,
        CongressionalDistrict: 18,
      },
      {
        RegAddrLine1: '10 KENT AVE',
        RegAddrCity: 'MACKINAW',
        RegAddrState: 'IL',
        RegAddrZip: 61755,
        CongressionalDistrict: 18,
      },
      {
        RegAddrLine1: '1703 JUDINE LN',
        RegAddrCity: 'NORMAL',
        RegAddrState: 'IL',
        RegAddrZip: 61761,
        CongressionalDistrict: 18,
      },
      {
        RegAddrLine1: '4508 N INDIANA AVE',
        RegAddrCity: 'PEORIA HTS',
        RegAddrState: 'IL',
        RegAddrZip: 61616,
        CongressionalDistrict: 18,
      },
      {
        RegAddrLine1: '1400 N 12TH ST',
        RegAddrCity: 'QUINCY',
        RegAddrState: 'IL',
        RegAddrZip: 62301,
        CongressionalDistrict: 18,
      },
      {
        RegAddrLine1: '532 ADAMS ST',
        RegAddrCity: 'QUINCY',
        RegAddrState: 'IL',
        RegAddrZip: 62301,
        CongressionalDistrict: 18,
      },
      {
        RegAddrLine1: '305 BLUFF VIEW RD',
        RegAddrCity: 'QUINCY',
        RegAddrState: 'IL',
        RegAddrZip: 62305,
        CongressionalDistrict: 18,
      },
      {
        RegAddrLine1: '1420 DEERFIELD DR',
        RegAddrCity: 'QUINCY',
        RegAddrState: 'IL',
        RegAddrZip: 62305,
        CongressionalDistrict: 18,
      },
      {
        RegAddrLine1: '707 W FRONT ST',
        RegAddrCity: 'ROANOKE',
        RegAddrState: 'IL',
        RegAddrZip: 61561,
        CongressionalDistrict: 18,
      },
      {
        RegAddrLine1: 'GENERAL DELIVERY',
        RegAddrCity: 'ROCKPORT',
        RegAddrState: 'IL',
        RegAddrZip: 62370,
        CongressionalDistrict: 18,
      },
      {
        RegAddrLine1: '172 LABOR DR',
        RegAddrCity: 'S JACKSONVLE',
        RegAddrState: 'IL',
        RegAddrZip: 62650,
        CongressionalDistrict: 18,
      },
      {
        RegAddrLine1: '2921 STOKEBRIDGE RD',
        RegAddrCity: 'SPRINGFIELD',
        RegAddrState: 'IL',
        RegAddrZip: 62702,
        CongressionalDistrict: 18,
      },
      {
        RegAddrLine1: '4313 CLEARWATER LN',
        RegAddrCity: 'SPRINGFIELD',
        RegAddrState: 'IL',
        RegAddrZip: 62703,
        CongressionalDistrict: 18,
      },
      {
        RegAddrLine1: '1809 POST OAK CT',
        RegAddrCity: 'SPRINGFIELD',
        RegAddrState: 'IL',
        RegAddrZip: 62704,
        CongressionalDistrict: 18,
      },
      {
        RegAddrLine1: '3609 TIMOTHY RD',
        RegAddrCity: 'SPRINGFIELD',
        RegAddrState: 'IL',
        RegAddrZip: 62711,
        CongressionalDistrict: 18,
      },
      {
        RegAddrLine1: '3900 LEAR DR',
        RegAddrCity: 'SPRINGFIELD',
        RegAddrState: 'IL',
        RegAddrZip: 62711,
        CongressionalDistrict: 18,
      },
      {
        RegAddrLine1: '400 S WEST ST',
        RegAddrCity: 'TREMONT',
        RegAddrState: 'IL',
        RegAddrZip: 61568,
        CongressionalDistrict: 18,
      },
      {
        RegAddrLine1: '109 HILLCREST DR',
        RegAddrCity: 'WASHINGTON',
        RegAddrState: 'IL',
        RegAddrZip: 61571,
        CongressionalDistrict: 18,
      },
    ],
  },
  {
    name: 'IL-2',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/IL-2?ref=gh-pages',
    state: 'IL',
    state_and_number: 'IL-2',
    zip: 60401,
    congressionalDistrict: 2,
    addressesInDistrict: [
      {
        RegAddrLine1: '14341 S BENSLEY AVE',
        RegAddrCity: 'BURNHAM',
        RegAddrState: 'IL',
        RegAddrZip: 60633,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1478 FREELAND AVE',
        RegAddrCity: 'CALUMET CITY',
        RegAddrState: 'IL',
        RegAddrZip: 60409,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '10148 S AVENUE M',
        RegAddrCity: 'CHICAGO',
        RegAddrState: 'IL',
        RegAddrZip: 60617,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '8243 S BRANDON AVE',
        RegAddrCity: 'CHICAGO',
        RegAddrState: 'IL',
        RegAddrZip: 60617,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '11001 S WALLACE ST',
        RegAddrCity: 'CHICAGO',
        RegAddrState: 'IL',
        RegAddrZip: 60628,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '10352 S WALLACE ST',
        RegAddrCity: 'CHICAGO',
        RegAddrState: 'IL',
        RegAddrZip: 60628,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '10827 S WABASH AVE',
        RegAddrCity: 'CHICAGO',
        RegAddrState: 'IL',
        RegAddrZip: 60628,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '13216 S COMMERCIAL AVE',
        RegAddrCity: 'CHICAGO',
        RegAddrState: 'IL',
        RegAddrZip: 60633,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '7619 S PHILLIPS AVE',
        RegAddrCity: 'CHICAGO',
        RegAddrState: 'IL',
        RegAddrZip: 60649,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '13077 S ELLIS AVE',
        RegAddrCity: 'CHICAGO',
        RegAddrState: 'IL',
        RegAddrZip: 60827,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '637 S HALSTED ST',
        RegAddrCity: 'CHICAGO HTS',
        RegAddrState: 'IL',
        RegAddrZip: 60411,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '3813 168TH PL',
        RegAddrCity: 'CNTRY CLB HLS',
        RegAddrState: 'IL',
        RegAddrZip: 60478,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '18646 NIGHTENGALE TER',
        RegAddrCity: 'COUNTRY CLUB HILLS',
        RegAddrState: 'IL',
        RegAddrZip: 60478,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '3146 E CEDAR LN',
        RegAddrCity: 'CRETE',
        RegAddrState: 'IL',
        RegAddrZip: 60417,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '318 N PARLS ST',
        RegAddrCity: 'ESSEX',
        RegAddrState: 'IL',
        RegAddrZip: 60935,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '3037 MATTHEW LN',
        RegAddrCity: 'HOMEWOOD',
        RegAddrState: 'IL',
        RegAddrZip: 60430,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1650 OLIVE RD',
        RegAddrCity: 'HOMEWOOD',
        RegAddrState: 'IL',
        RegAddrZip: 60430,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '18354 HIGHLAND AVE',
        RegAddrCity: 'HOMEWOOD',
        RegAddrState: 'IL',
        RegAddrZip: 60430,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '660 N 5TH AVE',
        RegAddrCity: 'KANKAKEE',
        RegAddrState: 'IL',
        RegAddrZip: 60901,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '855 S 4TH AVE',
        RegAddrCity: 'KANKAKEE',
        RegAddrState: 'IL',
        RegAddrZip: 60901,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1926 183RD PL',
        RegAddrCity: 'LANSING',
        RegAddrState: 'IL',
        RegAddrZip: 60438,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '18333 ADA ST',
        RegAddrCity: 'LANSING',
        RegAddrState: 'IL',
        RegAddrZip: 60438,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '242 INDIANA ST',
        RegAddrCity: 'PARK FOREST',
        RegAddrState: 'IL',
        RegAddrZip: 60466,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '107 INDIANA ST',
        RegAddrCity: 'PARK FOREST',
        RegAddrState: 'IL',
        RegAddrZip: 60466,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '522 MANOR DR',
        RegAddrCity: 'PEOTONE',
        RegAddrState: 'IL',
        RegAddrZip: 60468,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '404 E CORNING AVE',
        RegAddrCity: 'PEOTONE',
        RegAddrState: 'IL',
        RegAddrZip: 60468,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '906 E 151ST PL',
        RegAddrCity: 'PHOENIX',
        RegAddrState: 'IL',
        RegAddrZip: 60426,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1810 222ND PL',
        RegAddrCity: 'SAUK VILLAGE',
        RegAddrState: 'IL',
        RegAddrZip: 60411,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '17232 ELLIS CT',
        RegAddrCity: 'SOUTH HOLLAND',
        RegAddrState: 'IL',
        RegAddrZip: 60473,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '795 SUNNYSIDE AVE',
        RegAddrCity: 'THORNTON',
        RegAddrState: 'IL',
        RegAddrZip: 60476,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '600 GORDON TER',
        RegAddrCity: 'UNIVERSITY PK',
        RegAddrState: 'IL',
        RegAddrZip: 60484,
        CongressionalDistrict: 2,
      },
    ],
  },
  {
    name: 'IL-3',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/IL-3?ref=gh-pages',
    state: 'IL',
    state_and_number: 'IL-3',
    zip: 60402,
    congressionalDistrict: 3,
    addressesInDistrict: [
      {
        RegAddrLine1: '4642 CUSTER AVE',
        RegAddrCity: 'BROOKFIELD',
        RegAddrState: 'IL',
        RegAddrZip: 60513,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '4347 MAPLE AVE',
        RegAddrCity: 'BROOKFIELD',
        RegAddrState: 'IL',
        RegAddrZip: 60513,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '8005 NEENAH AVE',
        RegAddrCity: 'BURBANK',
        RegAddrState: 'IL',
        RegAddrZip: 60459,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '4220 S WALLACE ST',
        RegAddrCity: 'CHICAGO',
        RegAddrState: 'IL',
        RegAddrZip: 60609,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '462 W 28TH ST',
        RegAddrCity: 'CHICAGO',
        RegAddrState: 'IL',
        RegAddrZip: 60616,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '5912 S KENNETH AVE',
        RegAddrCity: 'CHICAGO',
        RegAddrState: 'IL',
        RegAddrZip: 60629,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '6915 S KOMENSKY AVE',
        RegAddrCity: 'CHICAGO',
        RegAddrState: 'IL',
        RegAddrZip: 60629,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '5206 S LATROBE AVE',
        RegAddrCity: 'CHICAGO',
        RegAddrState: 'IL',
        RegAddrZip: 60638,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '5145 S AUSTIN AVE',
        RegAddrCity: 'CHICAGO',
        RegAddrState: 'IL',
        RegAddrZip: 60638,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '10839 S DRAKE AVE',
        RegAddrCity: 'CHICAGO',
        RegAddrState: 'IL',
        RegAddrZip: 60655,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '11132 S MAPLEWOOD AVE',
        RegAddrCity: 'CHICAGO',
        RegAddrState: 'IL',
        RegAddrZip: 60655,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '12647 W YORKSHIRE DR',
        RegAddrCity: 'HOMER GLEN',
        RegAddrState: 'IL',
        RegAddrZip: 60491,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '15659 JAMES LN',
        RegAddrCity: 'HOMER GLEN',
        RegAddrState: 'IL',
        RegAddrZip: 60491,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '13809 S KING RD',
        RegAddrCity: 'HOMER GLEN',
        RegAddrState: 'IL',
        RegAddrZip: 60491,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '118 E WASHINGTON ST',
        RegAddrCity: 'JOLIET',
        RegAddrState: 'IL',
        RegAddrZip: 60432,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '16622 S WINDSOR LN',
        RegAddrCity: 'LOCKPORT',
        RegAddrState: 'IL',
        RegAddrZip: 60441,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '4127 ANNA AVE',
        RegAddrCity: 'LYONS',
        RegAddrState: 'IL',
        RegAddrZip: 60534,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '9005 CRESCENT CT',
        RegAddrCity: 'OAK LAWN',
        RegAddrState: 'IL',
        RegAddrZip: 60453,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '14819 GREENVIEW RD',
        RegAddrCity: 'ORLAND PARK',
        RegAddrState: 'IL',
        RegAddrZip: 60462,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '14724 HOLLOW TREE RD',
        RegAddrCity: 'ORLAND PARK',
        RegAddrState: 'IL',
        RegAddrZip: 60462,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '7307 W ARCHER AVE',
        RegAddrCity: 'SUMMIT',
        RegAddrState: 'IL',
        RegAddrZip: 60501,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '8531 CARRIAGE LN',
        RegAddrCity: 'TINLEY PARK',
        RegAddrState: 'IL',
        RegAddrZip: 60487,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '5128 ELLINGTON AVE',
        RegAddrCity: 'WESTERN SPRGS',
        RegAddrState: 'IL',
        RegAddrZip: 60558,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '5325 FAIR ELMS AVE',
        RegAddrCity: 'WESTERN SPRGS',
        RegAddrState: 'IL',
        RegAddrZip: 60558,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '10738 S NEW ENGLAND AVE',
        RegAddrCity: 'WORTH',
        RegAddrState: 'IL',
        RegAddrZip: 60482,
        CongressionalDistrict: 3,
      },
    ],
  },
  {
    name: 'IL-4',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/IL-4?ref=gh-pages',
    state: 'IL',
    state_and_number: 'IL-4',
    zip: 60104,
    congressionalDistrict: 4,
    addressesInDistrict: [
      {
        RegAddrLine1: '6431 26TH PL',
        RegAddrCity: 'BERWYN',
        RegAddrState: 'IL',
        RegAddrZip: 60402,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2542 WESLEY AVE',
        RegAddrCity: 'BERWYN',
        RegAddrState: 'IL',
        RegAddrZip: 60402,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '3145 N BERNARD ST',
        RegAddrCity: 'CHICAGO',
        RegAddrState: 'IL',
        RegAddrZip: 60618,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '3112 W MONTROSE AVE',
        RegAddrCity: 'CHICAGO',
        RegAddrState: 'IL',
        RegAddrZip: 60618,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1458 N CAMPBELL AVE',
        RegAddrCity: 'CHICAGO',
        RegAddrState: 'IL',
        RegAddrZip: 60622,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2708 S HOMAN AVE',
        RegAddrCity: 'CHICAGO',
        RegAddrState: 'IL',
        RegAddrZip: 60623,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2810 S SAWYER AVE',
        RegAddrCity: 'CHICAGO',
        RegAddrState: 'IL',
        RegAddrZip: 60623,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '4449 N LAWNDALE AVE',
        RegAddrCity: 'CHICAGO',
        RegAddrState: 'IL',
        RegAddrZip: 60625,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '3117 W 39TH PL',
        RegAddrCity: 'CHICAGO',
        RegAddrState: 'IL',
        RegAddrZip: 60632,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2842 N MOODY AVE',
        RegAddrCity: 'CHICAGO',
        RegAddrState: 'IL',
        RegAddrZip: 60634,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2659 N PARKSIDE AVE',
        RegAddrCity: 'CHICAGO',
        RegAddrState: 'IL',
        RegAddrZip: 60639,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2219 N LAMON AVE',
        RegAddrCity: 'CHICAGO',
        RegAddrState: 'IL',
        RegAddrZip: 60639,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '3014 N LOTUS AVE',
        RegAddrCity: 'CHICAGO',
        RegAddrState: 'IL',
        RegAddrZip: 60641,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2913 N CICERO AVE',
        RegAddrCity: 'CHICAGO',
        RegAddrState: 'IL',
        RegAddrZip: 60641,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '4845 W BYRON ST',
        RegAddrCity: 'CHICAGO',
        RegAddrState: 'IL',
        RegAddrZip: 60641,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '5340 W SCHOOL ST',
        RegAddrCity: 'CHICAGO',
        RegAddrState: 'IL',
        RegAddrZip: 60641,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2112 N RICHMOND ST',
        RegAddrCity: 'CHICAGO',
        RegAddrState: 'IL',
        RegAddrZip: 60647,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1626 N FRANCISCO AVE',
        RegAddrCity: 'CHICAGO',
        RegAddrState: 'IL',
        RegAddrZip: 60647,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2303 S 58TH CT',
        RegAddrCity: 'CICERO',
        RegAddrState: 'IL',
        RegAddrZip: 60804,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '4906 W 31ST PL',
        RegAddrCity: 'CICERO',
        RegAddrState: 'IL',
        RegAddrZip: 60804,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '15404 MAPLE LN',
        RegAddrCity: 'MARKHAM',
        RegAddrState: 'IL',
        RegAddrZip: 60428,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '8150 W 26TH ST',
        RegAddrCity: 'N RIVERSIDE',
        RegAddrState: 'IL',
        RegAddrZip: 60546,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '475 SHENSTONE RD',
        RegAddrCity: 'RIVERSIDE',
        RegAddrState: 'IL',
        RegAddrZip: 60546,
        CongressionalDistrict: 4,
      },
    ],
  },
  {
    name: 'IL-5',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/IL-5?ref=gh-pages',
    state: 'IL',
    state_and_number: 'IL-5',
    zip: 60007,
    congressionalDistrict: 5,
    addressesInDistrict: [
      {
        RegAddrLine1: '2708 N SOUTHPORT AVE',
        RegAddrCity: 'CHICAGO',
        RegAddrState: 'IL',
        RegAddrZip: 60614,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '1841 N BISSELL ST',
        RegAddrCity: 'CHICAGO',
        RegAddrState: 'IL',
        RegAddrZip: 60614,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '2934 N WASHTENAW AVE',
        RegAddrCity: 'CHICAGO',
        RegAddrState: 'IL',
        RegAddrZip: 60618,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '2144 W SCHILLER ST',
        RegAddrCity: 'CHICAGO',
        RegAddrState: 'IL',
        RegAddrZip: 60622,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '1742 W BEACH AVE',
        RegAddrCity: 'CHICAGO',
        RegAddrState: 'IL',
        RegAddrZip: 60622,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '4621 N CLAREMONT AVE',
        RegAddrCity: 'CHICAGO',
        RegAddrState: 'IL',
        RegAddrZip: 60625,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '2437 W WINONA ST',
        RegAddrCity: 'CHICAGO',
        RegAddrState: 'IL',
        RegAddrZip: 60625,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '5040 N DRAKE AVE',
        RegAddrCity: 'CHICAGO',
        RegAddrState: 'IL',
        RegAddrZip: 60625,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '6250 W FOSTER AVE',
        RegAddrCity: 'CHICAGO',
        RegAddrState: 'IL',
        RegAddrZip: 60630,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '5009 W AGATITE AVE',
        RegAddrCity: 'CHICAGO',
        RegAddrState: 'IL',
        RegAddrZip: 60630,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '5722 W WILSON AVE',
        RegAddrCity: 'CHICAGO',
        RegAddrState: 'IL',
        RegAddrZip: 60630,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '5519 W WINDSOR AVE',
        RegAddrCity: 'CHICAGO',
        RegAddrState: 'IL',
        RegAddrZip: 60630,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '6118 W BERENICE AVE',
        RegAddrCity: 'CHICAGO',
        RegAddrState: 'IL',
        RegAddrZip: 60634,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '3818 N OCTAVIA AVE',
        RegAddrCity: 'CHICAGO',
        RegAddrState: 'IL',
        RegAddrZip: 60634,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '4439 N GREENVIEW AVE',
        RegAddrCity: 'CHICAGO',
        RegAddrState: 'IL',
        RegAddrZip: 60640,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '4316 N TRIPP AVE',
        RegAddrCity: 'CHICAGO',
        RegAddrState: 'IL',
        RegAddrZip: 60641,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '6528 N HIAWATHA AVE',
        RegAddrCity: 'CHICAGO',
        RegAddrState: 'IL',
        RegAddrZip: 60646,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '6129 N KEDVALE AVE',
        RegAddrCity: 'CHICAGO',
        RegAddrState: 'IL',
        RegAddrZip: 60646,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '6914 W HIGGINS AVE',
        RegAddrCity: 'CHICAGO',
        RegAddrState: 'IL',
        RegAddrZip: 60656,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '1923 W BELMONT AVE',
        RegAddrCity: 'CHICAGO',
        RegAddrState: 'IL',
        RegAddrZip: 60657,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '2942 N RACINE AVE',
        RegAddrCity: 'CHICAGO',
        RegAddrState: 'IL',
        RegAddrZip: 60657,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '3145 N SEMINARY AVE',
        RegAddrCity: 'CHICAGO',
        RegAddrState: 'IL',
        RegAddrZip: 60657,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '14 MANCHESTER LN',
        RegAddrCity: 'ELMHURST',
        RegAddrState: 'IL',
        RegAddrZip: 60126,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '933 S CAMBRIDGE AVE',
        RegAddrCity: 'ELMHURST',
        RegAddrState: 'IL',
        RegAddrZip: 60126,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '788 S PARKSIDE AVE',
        RegAddrCity: 'ELMHURST',
        RegAddrState: 'IL',
        RegAddrZip: 60126,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '2627 N 78TH AVE',
        RegAddrCity: 'ELMWOOD PARK',
        RegAddrState: 'IL',
        RegAddrZip: 60707,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '10316 FRONT AVE',
        RegAddrCity: 'FRANKLIN PARK',
        RegAddrState: 'IL',
        RegAddrZip: 60131,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '4924 N OCTAVIA AVE',
        RegAddrCity: 'HARWOOD HTS',
        RegAddrState: 'IL',
        RegAddrZip: 60706,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '43 BELLE DR',
        RegAddrCity: 'NORTHLAKE',
        RegAddrState: 'IL',
        RegAddrZip: 60164,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '302 LA PORTE AVE',
        RegAddrCity: 'NORTHLAKE',
        RegAddrState: 'IL',
        RegAddrZip: 60164,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '10190 WEHRMAN PL',
        RegAddrCity: 'SCHILLER PARK',
        RegAddrState: 'IL',
        RegAddrZip: 60176,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '9923 SUNNYSIDE CT',
        RegAddrCity: 'SCHILLER PARK',
        RegAddrState: 'IL',
        RegAddrZip: 60176,
        CongressionalDistrict: 5,
      },
    ],
  },
  {
    name: 'IL-6',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/IL-6?ref=gh-pages',
    state: 'IL',
    state_and_number: 'IL-6',
    zip: 60008,
    congressionalDistrict: 6,
    addressesInDistrict: [
      {
        RegAddrLine1: '1207 LAKE SHORE DR N',
        RegAddrCity: 'BARRINGTON',
        RegAddrState: 'IL',
        RegAddrZip: 60010,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '370 ASHFORD CIR',
        RegAddrCity: 'BARTLETT',
        RegAddrState: 'IL',
        RegAddrZip: 60103,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '2422 MEADOWSEDGE LN',
        RegAddrCity: 'CARPENTERSVLE',
        RegAddrState: 'IL',
        RegAddrZip: 60110,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '62 S PRAIRIE ST',
        RegAddrCity: 'CARY',
        RegAddrState: 'IL',
        RegAddrZip: 60013,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '753 MILLWOOD ST',
        RegAddrCity: 'CARY',
        RegAddrState: 'IL',
        RegAddrZip: 60013,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '571 COVENTRY LN',
        RegAddrCity: 'CRYSTAL LAKE',
        RegAddrState: 'IL',
        RegAddrZip: 60014,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '18W035 73RD ST',
        RegAddrCity: 'DARIEN',
        RegAddrState: 'IL',
        RegAddrZip: 60561,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '4508 BRYAN PL',
        RegAddrCity: 'DOWNERS GROVE',
        RegAddrState: 'IL',
        RegAddrZip: 60515,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '6124 MIDDAUGH AVE',
        RegAddrCity: 'DOWNERS GROVE',
        RegAddrState: 'IL',
        RegAddrZip: 60516,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '8N810 NOLAN RD',
        RegAddrCity: 'ELGIN',
        RegAddrState: 'IL',
        RegAddrZip: 60124,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '37W924 MCDONALD RD',
        RegAddrCity: 'ELGIN',
        RegAddrState: 'IL',
        RegAddrZip: 60124,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '4416 BASSWOOD DR',
        RegAddrCity: 'LISLE',
        RegAddrState: 'IL',
        RegAddrZip: 60532,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '6711 INNSBRUCK CT',
        RegAddrCity: 'LISLE',
        RegAddrState: 'IL',
        RegAddrZip: 60532,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '19 INDIAN TRL',
        RegAddrCity: 'LK IN THE HLS',
        RegAddrState: 'IL',
        RegAddrZip: 60156,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '404 CRYSTAL LAKE RD',
        RegAddrCity: 'LK IN THE HLS',
        RegAddrState: 'IL',
        RegAddrZip: 60156,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '904 BARBARA ST',
        RegAddrCity: 'LK IN THE HLS',
        RegAddrState: 'IL',
        RegAddrZip: 60156,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '11 S BRAINARD ST',
        RegAddrCity: 'NAPERVILLE',
        RegAddrState: 'IL',
        RegAddrZip: 60540,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '1607 ABBY DR',
        RegAddrCity: 'NAPERVILLE',
        RegAddrState: 'IL',
        RegAddrZip: 60563,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '1039 E AMBERWOOD CIR',
        RegAddrCity: 'NAPERVILLE',
        RegAddrState: 'IL',
        RegAddrZip: 60563,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '257 N BENTON ST',
        RegAddrCity: 'PALATINE',
        RegAddrState: 'IL',
        RegAddrZip: 60067,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '557 N GERI CT',
        RegAddrCity: 'PALATINE',
        RegAddrState: 'IL',
        RegAddrZip: 60067,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '399 HARVEST LN',
        RegAddrCity: 'SOUTH ELGIN',
        RegAddrState: 'IL',
        RegAddrZip: 60177,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '38W750 EVANSWOOD LN',
        RegAddrCity: 'ST CHARLES',
        RegAddrState: 'IL',
        RegAddrZip: 60175,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '7N430 HAMPTON DR',
        RegAddrCity: 'ST CHARLES',
        RegAddrState: 'IL',
        RegAddrZip: 60175,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '512 E OGDEN AVE',
        RegAddrCity: 'WESTMONT',
        RegAddrState: 'IL',
        RegAddrZip: 60559,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '108 E BURLINGTON AVE',
        RegAddrCity: 'WESTMONT',
        RegAddrState: 'IL',
        RegAddrZip: 60559,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '130 N ERIE ST',
        RegAddrCity: 'WHEATON',
        RegAddrState: 'IL',
        RegAddrZip: 60187,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '521 RANCH RD',
        RegAddrCity: 'WHEATON',
        RegAddrState: 'IL',
        RegAddrZip: 60187,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '805 JEFFREY CT',
        RegAddrCity: 'WHEATON',
        RegAddrState: 'IL',
        RegAddrZip: 60189,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '1211 PERSHING AVE',
        RegAddrCity: 'WHEATON',
        RegAddrState: 'IL',
        RegAddrZip: 60189,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '1815 JASPER CT',
        RegAddrCity: 'WHEATON',
        RegAddrState: 'IL',
        RegAddrZip: 60189,
        CongressionalDistrict: 6,
      },
    ],
  },
  {
    name: 'IL-7',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/IL-7?ref=gh-pages',
    state: 'IL',
    state_and_number: 'IL-7',
    zip: 60130,
    congressionalDistrict: 7,
    addressesInDistrict: [
      {
        RegAddrLine1: '328 24TH AVE',
        RegAddrCity: 'BELLWOOD',
        RegAddrState: 'IL',
        RegAddrZip: 60104,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '2321 S 13TH AVE',
        RegAddrCity: 'BROADVIEW',
        RegAddrState: 'IL',
        RegAddrZip: 60155,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '1111 S LAFLIN ST',
        RegAddrCity: 'CHICAGO',
        RegAddrState: 'IL',
        RegAddrZip: 60607,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '950 W MONROE ST',
        RegAddrCity: 'CHICAGO',
        RegAddrState: 'IL',
        RegAddrZip: 60607,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '1310 W 13TH ST',
        RegAddrCity: 'CHICAGO',
        RegAddrState: 'IL',
        RegAddrZip: 60608,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '3723 S WELLS ST',
        RegAddrCity: 'CHICAGO',
        RegAddrState: 'IL',
        RegAddrZip: 60609,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '2125 W 52ND PL',
        RegAddrCity: 'CHICAGO',
        RegAddrState: 'IL',
        RegAddrZip: 60609,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '551 W NORTH AVE',
        RegAddrCity: 'CHICAGO',
        RegAddrState: 'IL',
        RegAddrZip: 60610,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '2 E OAK ST',
        RegAddrCity: 'CHICAGO',
        RegAddrState: 'IL',
        RegAddrZip: 60611,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '251 N ALBANY AVE',
        RegAddrCity: 'CHICAGO',
        RegAddrState: 'IL',
        RegAddrZip: 60612,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '5024 S PRAIRIE AVE',
        RegAddrCity: 'CHICAGO',
        RegAddrState: 'IL',
        RegAddrZip: 60615,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '2633 S PRINCETON AVE',
        RegAddrCity: 'CHICAGO',
        RegAddrState: 'IL',
        RegAddrZip: 60616,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '1620 S MICHIGAN AVE',
        RegAddrCity: 'CHICAGO',
        RegAddrState: 'IL',
        RegAddrZip: 60616,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '2307 S STEWART AVE',
        RegAddrCity: 'CHICAGO',
        RegAddrState: 'IL',
        RegAddrZip: 60616,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '625 S KEDVALE AVE',
        RegAddrCity: 'CHICAGO',
        RegAddrState: 'IL',
        RegAddrZip: 60624,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '4144 W 5TH AVE',
        RegAddrCity: 'CHICAGO',
        RegAddrState: 'IL',
        RegAddrZip: 60624,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '3337 W 64TH PL',
        RegAddrCity: 'CHICAGO',
        RegAddrState: 'IL',
        RegAddrZip: 60629,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '5902 S FAIRFIELD AVE',
        RegAddrCity: 'CHICAGO',
        RegAddrState: 'IL',
        RegAddrZip: 60629,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '6933 S LOOMIS BLVD',
        RegAddrCity: 'CHICAGO',
        RegAddrState: 'IL',
        RegAddrZip: 60636,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '6549 S JUSTINE ST',
        RegAddrCity: 'CHICAGO',
        RegAddrState: 'IL',
        RegAddrZip: 60636,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '1907 W GARFIELD BLVD',
        RegAddrCity: 'CHICAGO',
        RegAddrState: 'IL',
        RegAddrZip: 60636,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '6137 W GRAND AVE',
        RegAddrCity: 'CHICAGO',
        RegAddrState: 'IL',
        RegAddrZip: 60639,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '1741 N LATROBE AVE',
        RegAddrCity: 'CHICAGO',
        RegAddrState: 'IL',
        RegAddrZip: 60639,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '5839 W MIDWAY PARK',
        RegAddrCity: 'CHICAGO',
        RegAddrState: 'IL',
        RegAddrZip: 60644,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '5832 W HURON ST',
        RegAddrCity: 'CHICAGO',
        RegAddrState: 'IL',
        RegAddrZip: 60644,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '5137 W CRYSTAL ST',
        RegAddrCity: 'CHICAGO',
        RegAddrState: 'IL',
        RegAddrZip: 60651,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '4543 S KING DR',
        RegAddrCity: 'CHICAGO',
        RegAddrState: 'IL',
        RegAddrZip: 60653,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '1 W SUPERIOR ST',
        RegAddrCity: 'CHICAGO',
        RegAddrState: 'IL',
        RegAddrZip: 60654,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '1628 S 17TH AVE',
        RegAddrCity: 'MAYWOOD',
        RegAddrState: 'IL',
        RegAddrZip: 60153,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '1130 COLUMBIAN AVE',
        RegAddrCity: 'OAK PARK',
        RegAddrState: 'IL',
        RegAddrZip: 60302,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '120 S HUMPHREY AVE',
        RegAddrCity: 'OAK PARK',
        RegAddrState: 'IL',
        RegAddrZip: 60302,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '855 S KENILWORTH AVE',
        RegAddrCity: 'OAK PARK',
        RegAddrState: 'IL',
        RegAddrZip: 60304,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '1350 NEWCASTLE AVE',
        RegAddrCity: 'WESTCHESTER',
        RegAddrState: 'IL',
        RegAddrZip: 60154,
        CongressionalDistrict: 7,
      },
    ],
  },
  {
    name: 'IL-8',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/IL-8?ref=gh-pages',
    state: 'IL',
    state_and_number: 'IL-8',
    zip: 60004,
    congressionalDistrict: 8,
    addressesInDistrict: [
      {
        RegAddrLine1: '191 BRAINTREE DR',
        RegAddrCity: 'BLOOMINGDALE',
        RegAddrState: 'IL',
        RegAddrZip: 60108,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '314 TOSCANA',
        RegAddrCity: 'BLOOMINGDALE',
        RegAddrState: 'IL',
        RegAddrZip: 60108,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '172 WEIDNER RD',
        RegAddrCity: 'BUFFALO GROVE',
        RegAddrState: 'IL',
        RegAddrZip: 60089,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '806 BALMORAL DR',
        RegAddrCity: 'DUNDEE',
        RegAddrState: 'IL',
        RegAddrZip: 60118,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '32W769 ALBERT DR',
        RegAddrCity: 'DUNDEE',
        RegAddrState: 'IL',
        RegAddrZip: 60118,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '880 SUZANNE LN',
        RegAddrCity: 'ELGIN',
        RegAddrState: 'IL',
        RegAddrZip: 60120,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '1202 BRADLEY CIR',
        RegAddrCity: 'ELGIN',
        RegAddrState: 'IL',
        RegAddrZip: 60120,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '684 LUDA ST',
        RegAddrCity: 'ELGIN',
        RegAddrState: 'IL',
        RegAddrZip: 60120,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '612 FOREST VIEW AVE',
        RegAddrCity: 'ELK GROVE VLG',
        RegAddrState: 'IL',
        RegAddrZip: 60007,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '1663 VERMONT DR',
        RegAddrCity: 'ELK GROVE VLG',
        RegAddrState: 'IL',
        RegAddrZip: 60007,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '1348 WESTCHESTER DR',
        RegAddrCity: 'GLENDALE HTS',
        RegAddrState: 'IL',
        RegAddrZip: 60139,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '2142 CEDAR AVE',
        RegAddrCity: 'HANOVER PARK',
        RegAddrState: 'IL',
        RegAddrZip: 60133,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '7251 JONQUIL TER',
        RegAddrCity: 'HANOVER PARK',
        RegAddrState: 'IL',
        RegAddrZip: 60133,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '605 WAINSFORD DR',
        RegAddrCity: 'HOFFMAN EST',
        RegAddrState: 'IL',
        RegAddrZip: 60169,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '5500 HUNTERS RIDGE CT',
        RegAddrCity: 'HOFFMAN EST',
        RegAddrState: 'IL',
        RegAddrZip: 60192,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '720 GRAND CANYON ST',
        RegAddrCity: 'HOFFMAN ESTATES',
        RegAddrState: 'IL',
        RegAddrZip: 60169,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '337 VANCE ST',
        RegAddrCity: 'LOMBARD',
        RegAddrState: 'IL',
        RegAddrZip: 60148,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '825 WINFAL DR',
        RegAddrCity: 'SCHAUMBURG',
        RegAddrState: 'IL',
        RegAddrZip: 60173,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '61 ORRINGTON CT',
        RegAddrCity: 'SCHAUMBURG',
        RegAddrState: 'IL',
        RegAddrZip: 60173,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '1105 COUNTRY CLUB LN',
        RegAddrCity: 'SCHAUMBURG',
        RegAddrState: 'IL',
        RegAddrZip: 60193,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '7524 E PRAIRIE RD',
        RegAddrCity: 'SKOKIE',
        RegAddrState: 'IL',
        RegAddrZip: 60076,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '718 W SUNSET AVE',
        RegAddrCity: 'VILLA PARK',
        RegAddrState: 'IL',
        RegAddrZip: 60181,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '931 S HARVARD AVE',
        RegAddrCity: 'VILLA PARK',
        RegAddrState: 'IL',
        RegAddrZip: 60181,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '230 JEFFERSON LN',
        RegAddrCity: 'WOOD DALE',
        RegAddrState: 'IL',
        RegAddrZip: 60191,
        CongressionalDistrict: 8,
      },
    ],
  },
  {
    name: 'IL-9',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/IL-9?ref=gh-pages',
    state: 'IL',
    state_and_number: 'IL-9',
    zip: 60022,
    congressionalDistrict: 9,
    addressesInDistrict: [
      {
        RegAddrLine1: '718 E APPLETREE LN',
        RegAddrCity: 'ARLINGTON HTS',
        RegAddrState: 'IL',
        RegAddrZip: 60004,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '246 S LEONARD LN',
        RegAddrCity: 'ARLINGTON HTS',
        RegAddrState: 'IL',
        RegAddrZip: 60005,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '1410 S PRINCETON AVE',
        RegAddrCity: 'ARLINGTON HTS',
        RegAddrState: 'IL',
        RegAddrZip: 60005,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '6576 N LAKEWOOD AVE',
        RegAddrCity: 'CHICAGO',
        RegAddrState: 'IL',
        RegAddrZip: 60626,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '6633 N SHERIDAN RD',
        RegAddrCity: 'CHICAGO',
        RegAddrState: 'IL',
        RegAddrZip: 60626,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '6945 N OVERHILL AVE',
        RegAddrCity: 'CHICAGO',
        RegAddrState: 'IL',
        RegAddrZip: 60631,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '912 W LELAND AVE',
        RegAddrCity: 'CHICAGO',
        RegAddrState: 'IL',
        RegAddrZip: 60640,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '2101 W HOWARD ST',
        RegAddrCity: 'CHICAGO',
        RegAddrState: 'IL',
        RegAddrZip: 60645,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '2707 W MORSE AVE',
        RegAddrCity: 'CHICAGO',
        RegAddrState: 'IL',
        RegAddrZip: 60645,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '2740 W GRANVILLE AVE',
        RegAddrCity: 'CHICAGO',
        RegAddrState: 'IL',
        RegAddrZip: 60659,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '5833 N HERMITAGE AVE',
        RegAddrCity: 'CHICAGO',
        RegAddrState: 'IL',
        RegAddrZip: 60660,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '2919 N HARLEM AVE',
        RegAddrCity: 'CHICAGO',
        RegAddrState: 'IL',
        RegAddrZip: 60707,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '290 LANCE DR',
        RegAddrCity: 'DES PLAINES',
        RegAddrState: 'IL',
        RegAddrZip: 60016,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '784 E 5TH CT',
        RegAddrCity: 'DES PLAINES',
        RegAddrState: 'IL',
        RegAddrZip: 60016,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '1120 ROSE AVE',
        RegAddrCity: 'DES PLAINES',
        RegAddrState: 'IL',
        RegAddrZip: 60016,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '1015 BROWN AVE',
        RegAddrCity: 'EVANSTON',
        RegAddrState: 'IL',
        RegAddrZip: 60202,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '6444 N KOLMAR AVE',
        RegAddrCity: 'LINCOLNWOOD',
        RegAddrState: 'IL',
        RegAddrZip: 60712,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '7422 CHURCHILL ST',
        RegAddrCity: 'MORTON GROVE',
        RegAddrState: 'IL',
        RegAddrZip: 60053,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '606 N RUSSEL ST',
        RegAddrCity: 'MT PROSPECT',
        RegAddrState: 'IL',
        RegAddrZip: 60056,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '22 N KENILWORTH AVE',
        RegAddrCity: 'MT PROSPECT',
        RegAddrState: 'IL',
        RegAddrZip: 60056,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '407 W GO WANDO AVE',
        RegAddrCity: 'MT PROSPECT',
        RegAddrState: 'IL',
        RegAddrZip: 60056,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '8357 N OCTAVIA AVE',
        RegAddrCity: 'NILES',
        RegAddrState: 'IL',
        RegAddrZip: 60714,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '1942 BEAUMONT PL',
        RegAddrCity: 'NORTHBROOK',
        RegAddrState: 'IL',
        RegAddrZip: 60062,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '8444 KARLOV AVE',
        RegAddrCity: 'SKOKIE',
        RegAddrState: 'IL',
        RegAddrZip: 60076,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '3844 FARGO AVE',
        RegAddrCity: 'SKOKIE',
        RegAddrState: 'IL',
        RegAddrZip: 60076,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '8556 HARDING AVE',
        RegAddrCity: 'SKOKIE',
        RegAddrState: 'IL',
        RegAddrZip: 60076,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '343 WASHINGTON AVE',
        RegAddrCity: 'WILMETTE',
        RegAddrState: 'IL',
        RegAddrZip: 60091,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '607 LAMON AVE',
        RegAddrCity: 'WILMETTE',
        RegAddrState: 'IL',
        RegAddrZip: 60091,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '1082 PINE ST',
        RegAddrCity: 'WINNETKA',
        RegAddrState: 'IL',
        RegAddrZip: 60093,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '1496 ASBURY AVE',
        RegAddrCity: 'WINNETKA',
        RegAddrState: 'IL',
        RegAddrZip: 60093,
        CongressionalDistrict: 9,
      },
    ],
  },
  {
    name: 'IN-1',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/IN-1?ref=gh-pages',
    state: 'IN',
    state_and_number: 'IN-1',
    zip: 46301,
    congressionalDistrict: 1,
    addressesInDistrict: [
      {
        RegAddrLine1: '1162 SALT CREEK RD',
        RegAddrCity: 'BURNS HARBOR',
        RegAddrState: 'IN',
        RegAddrZip: 46304,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '12201 MCCOOK ST',
        RegAddrCity: 'CEDAR LAKE',
        RegAddrState: 'IN',
        RegAddrZip: 46303,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '501 SHANNON DR',
        RegAddrCity: 'CHESTERTON',
        RegAddrState: 'IN',
        RegAddrZip: 46304,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1521 BROADWAY',
        RegAddrCity: 'CHESTERTON',
        RegAddrState: 'IN',
        RegAddrZip: 46304,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '8082 TUCKAWAY CT',
        RegAddrCity: 'CROWN POINT',
        RegAddrState: 'IN',
        RegAddrZip: 46307,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '12108 WALLACE ST',
        RegAddrCity: 'CROWN POINT',
        RegAddrState: 'IN',
        RegAddrZip: 46307,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '6010 E 117TH AVE',
        RegAddrCity: 'CROWN POINT',
        RegAddrState: 'IN',
        RegAddrZip: 46307,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '8400 BURR ST',
        RegAddrCity: 'CROWN POINT',
        RegAddrState: 'IN',
        RegAddrZip: 46307,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '8400 BURR ST',
        RegAddrCity: 'CROWN POINT',
        RegAddrState: 'IN',
        RegAddrZip: 46307,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '10713 MARTINIQUE LN',
        RegAddrCity: 'CROWN POINT',
        RegAddrState: 'IN',
        RegAddrZip: 46307,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '220 HICKORY AVE',
        RegAddrCity: 'CROWN POINT',
        RegAddrState: 'IN',
        RegAddrZip: 46307,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '9221 MORAINE ST',
        RegAddrCity: 'DYER',
        RegAddrState: 'IN',
        RegAddrZip: 46311,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '7871 LINDEN ST',
        RegAddrCity: 'DYER',
        RegAddrState: 'IN',
        RegAddrZip: 46311,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '3923 EVERGREEN ST',
        RegAddrCity: 'EAST CHICAGO',
        RegAddrState: 'IN',
        RegAddrZip: 46312,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1814 E 138TH ST',
        RegAddrCity: 'EAST CHICAGO',
        RegAddrState: 'IN',
        RegAddrZip: 46312,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '3430 FIR ST',
        RegAddrCity: 'EAST CHICAGO',
        RegAddrState: 'IN',
        RegAddrZip: 46312,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2257 GARFIELD ST',
        RegAddrCity: 'GARY',
        RegAddrState: 'IN',
        RegAddrZip: 46404,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '582 MCKINLEY ST',
        RegAddrCity: 'GARY',
        RegAddrState: 'IN',
        RegAddrZip: 46404,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '557 KING ST',
        RegAddrCity: 'GARY',
        RegAddrState: 'IN',
        RegAddrZip: 46406,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2423 FILLMORE ST',
        RegAddrCity: 'GARY',
        RegAddrState: 'IN',
        RegAddrZip: 46407,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1806 E 19TH CT',
        RegAddrCity: 'GARY',
        RegAddrState: 'IN',
        RegAddrZip: 46407,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '3740 TYLER ST',
        RegAddrCity: 'GARY',
        RegAddrState: 'IN',
        RegAddrZip: 46408,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '3746 JOHNSON ST',
        RegAddrCity: 'GARY',
        RegAddrState: 'IN',
        RegAddrZip: 46408,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '4569 MASSACHUSETTS ST',
        RegAddrCity: 'GARY',
        RegAddrState: 'IN',
        RegAddrZip: 46409,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '507 STATE ST',
        RegAddrCity: 'HAMMOND',
        RegAddrState: 'IN',
        RegAddrZip: 46320,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1453 MICHIGAN ST',
        RegAddrCity: 'HAMMOND',
        RegAddrState: 'IN',
        RegAddrZip: 46320,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '6712 MARSHALL AVE',
        RegAddrCity: 'HAMMOND',
        RegAddrState: 'IN',
        RegAddrZip: 46323,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '6638 PARRISH AVE',
        RegAddrCity: 'HAMMOND',
        RegAddrState: 'IN',
        RegAddrZip: 46323,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '3 GASLITE PARK',
        RegAddrCity: 'HEBRON',
        RegAddrState: 'IN',
        RegAddrZip: 46341,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '3321 GRAND BLVD',
        RegAddrCity: 'HIGHLAND',
        RegAddrState: 'IN',
        RegAddrZip: 46322,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '9523 O DAY DR',
        RegAddrCity: 'HIGHLAND',
        RegAddrState: 'IN',
        RegAddrZip: 46322,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1681 RUBY CT',
        RegAddrCity: 'HOBART',
        RegAddrState: 'IN',
        RegAddrZip: 46342,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1305 W 37TH AVE',
        RegAddrCity: 'HOBART',
        RegAddrState: 'IN',
        RegAddrZip: 46342,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1808 LAKE SHORE DR',
        RegAddrCity: 'LONG BEACH',
        RegAddrState: 'IN',
        RegAddrZip: 46360,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2815 LAKE SHORE DR',
        RegAddrCity: 'LONG BEACH',
        RegAddrState: 'IN',
        RegAddrZip: 46360,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1208 SOUTHWOOD DR',
        RegAddrCity: 'LOWELL',
        RegAddrState: 'IN',
        RegAddrZip: 46356,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '3115 W 60TH DR',
        RegAddrCity: 'MERRILLVILLE',
        RegAddrState: 'IN',
        RegAddrZip: 46410,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '5931 JEFFERSON ST',
        RegAddrCity: 'MERRILLVILLE',
        RegAddrState: 'IN',
        RegAddrZip: 46410,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '412 LONG BEACH LN',
        RegAddrCity: 'MICHIGAN CITY',
        RegAddrState: 'IN',
        RegAddrZip: 46360,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '122 RIVIERA DR',
        RegAddrCity: 'MICHIGAN CITY',
        RegAddrState: 'IN',
        RegAddrZip: 46360,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '8036 MONALDI DR',
        RegAddrCity: 'MUNSTER',
        RegAddrState: 'IN',
        RegAddrZip: 46321,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '8146 MEADOW LN',
        RegAddrCity: 'MUNSTER',
        RegAddrState: 'IN',
        RegAddrZip: 46321,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '5610 BIRCH AVE',
        RegAddrCity: 'PORTAGE',
        RegAddrState: 'IN',
        RegAddrZip: 46368,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '6583 FEDERAL AVE',
        RegAddrCity: 'PORTAGE',
        RegAddrState: 'IN',
        RegAddrZip: 46368,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '83 HILLCREST RD',
        RegAddrCity: 'PORTAGE',
        RegAddrState: 'IN',
        RegAddrZip: 46368,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '8986 CROOKED BND',
        RegAddrCity: 'SAINT JOHN',
        RegAddrState: 'IN',
        RegAddrZip: 46373,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '7400 HARVEST DR',
        RegAddrCity: 'SCHERERVILLE',
        RegAddrState: 'IN',
        RegAddrZip: 46375,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1710 VALE PARK RD',
        RegAddrCity: 'VALPARAISO',
        RegAddrState: 'IN',
        RegAddrZip: 46383,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1576 DANVERS PKWY',
        RegAddrCity: 'VALPARAISO',
        RegAddrState: 'IN',
        RegAddrZip: 46385,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '132 CONCORD CIR',
        RegAddrCity: 'VALPARAISO',
        RegAddrState: 'IN',
        RegAddrZip: 46385,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '3405 CAMPBELL ST',
        RegAddrCity: 'VALPARAISO',
        RegAddrState: 'IN',
        RegAddrZip: 46385,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2724 SCHRAGE AVE',
        RegAddrCity: 'WHITING',
        RegAddrState: 'IN',
        RegAddrZip: 46394,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1450 WARWICK AVE',
        RegAddrCity: 'WHITING',
        RegAddrState: 'IN',
        RegAddrZip: 46394,
        CongressionalDistrict: 1,
      },
    ],
  },
  {
    name: 'IN-2',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/IN-2?ref=gh-pages',
    state: 'IN',
    state_and_number: 'IN-2',
    zip: 46345,
    congressionalDistrict: 2,
    addressesInDistrict: [
      {
        RegAddrLine1: '9858 12B RD',
        RegAddrCity: 'ARGOS',
        RegAddrState: 'IN',
        RegAddrZip: 46501,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '3663 W 700 S',
        RegAddrCity: 'CLAYPOOL',
        RegAddrState: 'IN',
        RegAddrZip: 46510,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '11220 E STATE ROAD 8',
        RegAddrCity: 'CULVER',
        RegAddrState: 'IN',
        RegAddrZip: 46511,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '9859 STATE ROAD 110',
        RegAddrCity: 'CULVER',
        RegAddrState: 'IN',
        RegAddrZip: 46511,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1422 W STATE ROAD 16',
        RegAddrCity: 'DENVER',
        RegAddrState: 'IN',
        RegAddrZip: 46926,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1305 ERWIN ST',
        RegAddrCity: 'ELKHART',
        RegAddrState: 'IN',
        RegAddrZip: 46514,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '28588 BENDER DR',
        RegAddrCity: 'ELKHART',
        RegAddrState: 'IN',
        RegAddrZip: 46514,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '670 STRONG AVE',
        RegAddrCity: 'ELKHART',
        RegAddrState: 'IN',
        RegAddrZip: 46514,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '26278 QUAIL RIDGE DR',
        RegAddrCity: 'ELKHART',
        RegAddrState: 'IN',
        RegAddrZip: 46514,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1805 ROYS AVE',
        RegAddrCity: 'ELKHART',
        RegAddrState: 'IN',
        RegAddrZip: 46516,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '63100 COUNTY ROAD 3',
        RegAddrCity: 'ELKHART',
        RegAddrState: 'IN',
        RegAddrZip: 46517,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '10318 W 475 S',
        RegAddrCity: 'FRANCESVILLE',
        RegAddrState: 'IN',
        RegAddrZip: 47946,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '201 N RIVERSIDE BLVD',
        RegAddrCity: 'GOSHEN',
        RegAddrState: 'IN',
        RegAddrZip: 46526,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '504 S 7TH ST',
        RegAddrCity: 'GOSHEN',
        RegAddrState: 'IN',
        RegAddrZip: 46526,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '18033 COUNTY ROAD 40',
        RegAddrCity: 'GOSHEN',
        RegAddrState: 'IN',
        RegAddrZip: 46526,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '14396 NORTHAMPTON DR',
        RegAddrCity: 'GRANGER',
        RegAddrState: 'IN',
        RegAddrZip: 46530,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '52261 CHATEM CT',
        RegAddrCity: 'GRANGER',
        RegAddrState: 'IN',
        RegAddrZip: 46530,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1446 SHORT DR',
        RegAddrCity: 'KNOX',
        RegAddrState: 'IN',
        RegAddrZip: 46534,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '910 E 19TH ST',
        RegAddrCity: 'LA PORTE',
        RegAddrState: 'IN',
        RegAddrZip: 46350,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1609 A ST',
        RegAddrCity: 'LA PORTE',
        RegAddrState: 'IN',
        RegAddrZip: 46350,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1301 CLAY ST',
        RegAddrCity: 'LA PORTE',
        RegAddrState: 'IN',
        RegAddrZip: 46350,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '307 S HENRY ST',
        RegAddrCity: 'MILFORD',
        RegAddrState: 'IN',
        RegAddrZip: 46542,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1233 E 3RD ST',
        RegAddrCity: 'MISHAWAKA',
        RegAddrState: 'IN',
        RegAddrZip: 46544,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1719 PANAMA ST',
        RegAddrCity: 'MISHAWAKA',
        RegAddrState: 'IN',
        RegAddrZip: 46544,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '3288 CAMBRIDGE CT',
        RegAddrCity: 'MISHAWAKA',
        RegAddrState: 'IN',
        RegAddrZip: 46545,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '23288 COUNTY ROAD 52',
        RegAddrCity: 'NAPPANEE',
        RegAddrState: 'IN',
        RegAddrZip: 46550,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '20332 FALCON BROOK CT',
        RegAddrCity: 'NEW PARIS',
        RegAddrState: 'IN',
        RegAddrZip: 46553,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '310 LESLIE ST',
        RegAddrCity: 'NORTH JUDSON',
        RegAddrState: 'IN',
        RegAddrZip: 46366,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '402 N BEECH RD',
        RegAddrCity: 'OSCEOLA',
        RegAddrState: 'IN',
        RegAddrZip: 46561,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '36 CRUME AVE',
        RegAddrCity: 'PERU',
        RegAddrState: 'IN',
        RegAddrZip: 46970,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '461 W 5TH ST',
        RegAddrCity: 'PERU',
        RegAddrState: 'IN',
        RegAddrZip: 46970,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '202 E MAIN ST',
        RegAddrCity: 'PERU',
        RegAddrState: 'IN',
        RegAddrZip: 46970,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1186 W OLSON RD',
        RegAddrCity: 'ROCHESTER',
        RegAddrState: 'IN',
        RegAddrZip: 46975,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '4263 E 350 N',
        RegAddrCity: 'ROLLING PR',
        RegAddrState: 'IN',
        RegAddrZip: 46371,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '120 WAUCOON DR',
        RegAddrCity: 'SOMERSET',
        RegAddrState: 'IN',
        RegAddrZip: 46984,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1241 DENNIS DR',
        RegAddrCity: 'SOUTH BEND',
        RegAddrState: 'IN',
        RegAddrZip: 46614,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1415 E RIDING MALL',
        RegAddrCity: 'SOUTH BEND',
        RegAddrState: 'IN',
        RegAddrZip: 46614,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '502 N IRONWOOD DR',
        RegAddrCity: 'SOUTH BEND',
        RegAddrState: 'IN',
        RegAddrZip: 46615,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '521 SHERMAN AVE',
        RegAddrCity: 'SOUTH BEND',
        RegAddrState: 'IN',
        RegAddrZip: 46616,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '552 N TWYCKENHAM DR',
        RegAddrCity: 'SOUTH BEND',
        RegAddrState: 'IN',
        RegAddrZip: 46617,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '25625 FAWN RIDGE CT',
        RegAddrCity: 'SOUTH BEND',
        RegAddrState: 'IN',
        RegAddrZip: 46619,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '4709 PORTSMOUTH CT',
        RegAddrCity: 'SOUTH BEND',
        RegAddrState: 'IN',
        RegAddrZip: 46619,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1405 HUEY ST',
        RegAddrCity: 'SOUTH BEND',
        RegAddrState: 'IN',
        RegAddrZip: 46628,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '22023 SANDYBROOK DR',
        RegAddrCity: 'SOUTH BEND',
        RegAddrState: 'IN',
        RegAddrZip: 46628,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '18275 BALSTON CIR',
        RegAddrCity: 'SOUTH BEND',
        RegAddrState: 'IN',
        RegAddrZip: 46637,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '53386 WINTERBERRY CT',
        RegAddrCity: 'SOUTH BEND',
        RegAddrState: 'IN',
        RegAddrZip: 46637,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '20660 N GATEHOUSE DR',
        RegAddrCity: 'SOUTH BEND',
        RegAddrState: 'IN',
        RegAddrZip: 46637,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '17851 MAIN ST',
        RegAddrCity: 'TYNER',
        RegAddrState: 'IN',
        RegAddrZip: 46572,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '940 SIVEY ST',
        RegAddrCity: 'WABASH',
        RegAddrState: 'IN',
        RegAddrZip: 46992,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '6846 N 725 W',
        RegAddrCity: 'WINAMAC',
        RegAddrState: 'IN',
        RegAddrZip: 46996,
        CongressionalDistrict: 2,
      },
    ],
  },
  {
    name: 'IN-3',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/IN-3?ref=gh-pages',
    state: 'IN',
    state_and_number: 'IN-3',
    zip: 46555,
    congressionalDistrict: 3,
    addressesInDistrict: [
      {
        RegAddrLine1: '609 E BROAD ST',
        RegAddrCity: 'ANGOLA',
        RegAddrState: 'IN',
        RegAddrZip: 46703,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '447 NORTHCREST RD',
        RegAddrCity: 'ANGOLA',
        RegAddrState: 'IN',
        RegAddrZip: 46703,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '217 IWO ST',
        RegAddrCity: 'AUBURN',
        RegAddrState: 'IN',
        RegAddrZip: 46706,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '6647 COUNTY ROAD 31',
        RegAddrCity: 'AUBURN',
        RegAddrState: 'IN',
        RegAddrZip: 46706,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '711 TECUMSEH CT',
        RegAddrCity: 'AUBURN',
        RegAddrState: 'IN',
        RegAddrZip: 46706,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1319 W SOUTH ST',
        RegAddrCity: 'BLUFFTON',
        RegAddrState: 'IN',
        RegAddrZip: 46714,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '406 JAMES ST',
        RegAddrCity: 'BUTLER',
        RegAddrState: 'IN',
        RegAddrZip: 46721,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '4360 E 700 S',
        RegAddrCity: 'COLUMBIA CITY',
        RegAddrState: 'IN',
        RegAddrZip: 46725,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '2888 N 400 W',
        RegAddrCity: 'DECATUR',
        RegAddrState: 'IN',
        RegAddrZip: 46733,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1211 MICHIGAN AVE',
        RegAddrCity: 'FORT WAYNE',
        RegAddrState: 'IN',
        RegAddrZip: 46802,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '5125 IDLEWOOD DR',
        RegAddrCity: 'FORT WAYNE',
        RegAddrState: 'IN',
        RegAddrZip: 46803,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '9729 KNOLL CREEK CV',
        RegAddrCity: 'FORT WAYNE',
        RegAddrState: 'IN',
        RegAddrZip: 46804,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '2925 CUNNINGHAM DR',
        RegAddrCity: 'FORT WAYNE',
        RegAddrState: 'IN',
        RegAddrZip: 46804,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1105 DODGE AVE',
        RegAddrCity: 'FORT WAYNE',
        RegAddrState: 'IN',
        RegAddrZip: 46805,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '3802 S PARK DR',
        RegAddrCity: 'FORT WAYNE',
        RegAddrState: 'IN',
        RegAddrZip: 46806,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '327 W OAKDALE DR',
        RegAddrCity: 'FORT WAYNE',
        RegAddrState: 'IN',
        RegAddrZip: 46807,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '3208 SIMCOE CT',
        RegAddrCity: 'FORT WAYNE',
        RegAddrState: 'IN',
        RegAddrZip: 46815,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '3136 GOEGLEIN RD',
        RegAddrCity: 'FORT WAYNE',
        RegAddrState: 'IN',
        RegAddrZip: 46815,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1332 SYLVIA ST',
        RegAddrCity: 'FORT WAYNE',
        RegAddrState: 'IN',
        RegAddrZip: 46816,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '205 CORWIN LN',
        RegAddrCity: 'FORT WAYNE',
        RegAddrState: 'IN',
        RegAddrZip: 46816,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '42 COUNTRY FOREST DR',
        RegAddrCity: 'FORT WAYNE',
        RegAddrState: 'IN',
        RegAddrZip: 46818,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '7424 BASS RD',
        RegAddrCity: 'FORT WAYNE',
        RegAddrState: 'IN',
        RegAddrZip: 46818,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '11019 SMOKEY RIDGE PL',
        RegAddrCity: 'FORT WAYNE',
        RegAddrState: 'IN',
        RegAddrZip: 46818,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '8331 W WASHINGTON CENTER RD',
        RegAddrCity: 'FORT WAYNE',
        RegAddrState: 'IN',
        RegAddrZip: 46818,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '8605 O DAY RD',
        RegAddrCity: 'FORT WAYNE',
        RegAddrState: 'IN',
        RegAddrZip: 46818,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '2813 MEADOWS PARK WAY',
        RegAddrCity: 'FORT WAYNE',
        RegAddrState: 'IN',
        RegAddrZip: 46825,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1804 WENDMERE LN',
        RegAddrCity: 'FORT WAYNE',
        RegAddrState: 'IN',
        RegAddrZip: 46825,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '516 STRATTON RD',
        RegAddrCity: 'FORT WAYNE',
        RegAddrState: 'IN',
        RegAddrZip: 46825,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '8131 SILVER SPRINGS RUN',
        RegAddrCity: 'FORT WAYNE',
        RegAddrState: 'IN',
        RegAddrZip: 46825,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '11738 PAINTED PEAK WAY',
        RegAddrCity: 'FORT WAYNE',
        RegAddrState: 'IN',
        RegAddrZip: 46845,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '10617 WOOD FLOWER CT',
        RegAddrCity: 'FORT WAYNE',
        RegAddrState: 'IN',
        RegAddrZip: 46845,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '255 LANE 301 BARTON LK',
        RegAddrCity: 'FREMONT',
        RegAddrState: 'IN',
        RegAddrZip: 46737,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1750 W STATE ROAD 120',
        RegAddrCity: 'FREMONT',
        RegAddrState: 'IN',
        RegAddrZip: 46737,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '5625 W 1050 S',
        RegAddrCity: 'GENEVA',
        RegAddrState: 'IN',
        RegAddrZip: 46740,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '7205 MONROEVILLE RD',
        RegAddrCity: 'HOAGLAND',
        RegAddrState: 'IN',
        RegAddrZip: 46745,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '13450 MARION CENTER RD',
        RegAddrCity: 'HOAGLAND',
        RegAddrState: 'IN',
        RegAddrZip: 46745,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '8339 N CLEAR CREEK RD',
        RegAddrCity: 'HUNTINGTON',
        RegAddrState: 'IN',
        RegAddrZip: 46750,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1249 COLLEGE AVE',
        RegAddrCity: 'HUNTINGTON',
        RegAddrState: 'IN',
        RegAddrZip: 46750,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '558 WILLIAM ST',
        RegAddrCity: 'HUNTINGTON',
        RegAddrState: 'IN',
        RegAddrZip: 46750,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1472 E KAMMERER RD',
        RegAddrCity: 'KENDALLVILLE',
        RegAddrState: 'IN',
        RegAddrZip: 46755,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '526 N MEADOW LN',
        RegAddrCity: 'KENDALLVILLE',
        RegAddrState: 'IN',
        RegAddrZip: 46755,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '440 W 050 N',
        RegAddrCity: 'LAGRANGE',
        RegAddrState: 'IN',
        RegAddrZip: 46761,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '6606 HOSLER RD',
        RegAddrCity: 'LEO',
        RegAddrState: 'IN',
        RegAddrZip: 46765,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '13232 LAKEVIEW DR',
        RegAddrCity: 'LEO',
        RegAddrState: 'IN',
        RegAddrZip: 46765,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '400 E 4TH ST',
        RegAddrCity: 'LIGONIER',
        RegAddrState: 'IN',
        RegAddrZip: 46767,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '7770 E 300 N',
        RegAddrCity: 'MONGO',
        RegAddrState: 'IN',
        RegAddrZip: 46771,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '15827 SLUSHER RD',
        RegAddrCity: 'NEW HAVEN',
        RegAddrState: 'IN',
        RegAddrZip: 46774,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '310 GREENWOOD TRL',
        RegAddrCity: 'OSSIAN',
        RegAddrState: 'IN',
        RegAddrZip: 46777,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '45 W WASHINGTON ST',
        RegAddrCity: 'PONETO',
        RegAddrState: 'IN',
        RegAddrZip: 46781,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1863 W 700 S',
        RegAddrCity: 'PONETO',
        RegAddrState: 'IN',
        RegAddrZip: 46781,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '9078 S SCOUT CABIN RD',
        RegAddrCity: 'WARREN',
        RegAddrState: 'IN',
        RegAddrZip: 46792,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '202 E LEEDY ST',
        RegAddrCity: 'WARSAW',
        RegAddrState: 'IN',
        RegAddrZip: 46580,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '73 EMS C27B LN',
        RegAddrCity: 'WARSAW',
        RegAddrState: 'IN',
        RegAddrZip: 46582,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '4315 CARL ST',
        RegAddrCity: 'WOODBURN',
        RegAddrState: 'IN',
        RegAddrZip: 46797,
        CongressionalDistrict: 3,
      },
    ],
  },
  {
    name: 'IN-4',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/IN-4?ref=gh-pages',
    state: 'IN',
    state_and_number: 'IN-4',
    zip: 46035,
    congressionalDistrict: 4,
    addressesInDistrict: [
      {
        RegAddrLine1: '111 S MAIN ST',
        RegAddrCity: 'ADVANCE',
        RegAddrState: 'IN',
        RegAddrZip: 46102,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '5091 DUNEWOOD WAY',
        RegAddrCity: 'AVON',
        RegAddrState: 'IN',
        RegAddrZip: 46123,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '7409 PARK SHR',
        RegAddrCity: 'AVON',
        RegAddrState: 'IN',
        RegAddrZip: 46123,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '10122 MORNING SUN CT',
        RegAddrCity: 'AVON',
        RegAddrState: 'IN',
        RegAddrZip: 46123,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1441 S BRINGHURST RD',
        RegAddrCity: 'BRINGHURST',
        RegAddrState: 'IN',
        RegAddrZip: 46913,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1654 BEAUTYMEADOW DR',
        RegAddrCity: 'BROWNSBURG',
        RegAddrState: 'IN',
        RegAddrZip: 46112,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '8194 NORTHPOINT DR',
        RegAddrCity: 'BROWNSBURG',
        RegAddrState: 'IN',
        RegAddrZip: 46112,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2 E TILDEN DR',
        RegAddrCity: 'BROWNSBURG',
        RegAddrState: 'IN',
        RegAddrZip: 46112,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '244 MILL SPGS',
        RegAddrCity: 'COATESVILLE',
        RegAddrState: 'IN',
        RegAddrZip: 46121,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '8757 W COUNTY ROAD 700 S',
        RegAddrCity: 'COATESVILLE',
        RegAddrState: 'IN',
        RegAddrZip: 46121,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '4365 N 625 E',
        RegAddrCity: 'CRAWFORDSVLLE',
        RegAddrState: 'IN',
        RegAddrZip: 47933,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '900 S GRANT AVE',
        RegAddrCity: 'CRAWFORDSVLLE',
        RegAddrState: 'IN',
        RegAddrZip: 47933,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2479 E 280 S',
        RegAddrCity: 'CRAWFORDSVLLE',
        RegAddrState: 'IN',
        RegAddrZip: 47933,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1006 E MAIN ST',
        RegAddrCity: 'CRAWFORDSVLLE',
        RegAddrState: 'IN',
        RegAddrZip: 47933,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2787 E STATE ROAD 32',
        RegAddrCity: 'CRAWFORDSVLLE',
        RegAddrState: 'IN',
        RegAddrZip: 47933,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '9337 W 310 N',
        RegAddrCity: 'DELPHI',
        RegAddrState: 'IN',
        RegAddrZip: 46923,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '11283 N 215 E',
        RegAddrCity: 'DEMOTTE',
        RegAddrState: 'IN',
        RegAddrZip: 46310,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '500 S MAISH RD',
        RegAddrCity: 'FRANKFORT',
        RegAddrState: 'IN',
        RegAddrZip: 46041,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '661 S NEWTON ST',
        RegAddrCity: 'GOODLAND',
        RegAddrState: 'IN',
        RegAddrZip: 47948,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '303 S LOCUST ST',
        RegAddrCity: 'GREENCASTLE',
        RegAddrState: 'IN',
        RegAddrZip: 46135,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '213 W HANNA ST',
        RegAddrCity: 'IDAVILLE',
        RegAddrState: 'IN',
        RegAddrZip: 47950,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '10118 CLEAR CREEK CIR',
        RegAddrCity: 'INDIANAPOLIS',
        RegAddrState: 'IN',
        RegAddrZip: 46234,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '201 E ALLEN ST',
        RegAddrCity: 'KENTLAND',
        RegAddrState: 'IN',
        RegAddrZip: 47951,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '5480 N 200 E',
        RegAddrCity: 'KOKOMO',
        RegAddrState: 'IN',
        RegAddrZip: 46901,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1007 W MONROE ST',
        RegAddrCity: 'KOKOMO',
        RegAddrState: 'IN',
        RegAddrZip: 46901,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2825 N LOCKE ST',
        RegAddrCity: 'KOKOMO',
        RegAddrState: 'IN',
        RegAddrZip: 46901,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '11400 CRESTVIEW BLVD',
        RegAddrCity: 'KOKOMO',
        RegAddrState: 'IN',
        RegAddrZip: 46901,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1736 HOGAN DR',
        RegAddrCity: 'KOKOMO',
        RegAddrState: 'IN',
        RegAddrZip: 46902,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2217 GLENORA CT',
        RegAddrCity: 'KOKOMO',
        RegAddrState: 'IN',
        RegAddrZip: 46902,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '927 HIGHLAND AVE',
        RegAddrCity: 'LAFAYETTE',
        RegAddrState: 'IN',
        RegAddrZip: 47905,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '624 CHEROKEE AVE',
        RegAddrCity: 'LAFAYETTE',
        RegAddrState: 'IN',
        RegAddrZip: 47905,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '361 DOUBLE TREE DR',
        RegAddrCity: 'LAFAYETTE',
        RegAddrState: 'IN',
        RegAddrZip: 47905,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1800 PLATTE DR',
        RegAddrCity: 'LAFAYETTE',
        RegAddrState: 'IN',
        RegAddrZip: 47905,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1818 WHISPER VALLEY DR',
        RegAddrCity: 'LAFAYETTE',
        RegAddrState: 'IN',
        RegAddrZip: 47909,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '10605 N STATE ROAD 39',
        RegAddrCity: 'LIZTON',
        RegAddrState: 'IN',
        RegAddrZip: 46149,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1818 SMITH ST',
        RegAddrCity: 'LOGANSPORT',
        RegAddrState: 'IN',
        RegAddrZip: 46947,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '619 W LINDEN AVE',
        RegAddrCity: 'LOGANSPORT',
        RegAddrState: 'IN',
        RegAddrZip: 46947,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '421 DEWEY ST',
        RegAddrCity: 'MONTICELLO',
        RegAddrState: 'IN',
        RegAddrZip: 47960,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '201 STONEVIEW LN',
        RegAddrCity: 'MOORESVILLE',
        RegAddrState: 'IN',
        RegAddrZip: 46158,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '3416 E SYCAMORE LN',
        RegAddrCity: 'MOORESVILLE',
        RegAddrState: 'IN',
        RegAddrZip: 46158,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '333 W JACKSON ST',
        RegAddrCity: 'MULBERRY',
        RegAddrState: 'IN',
        RegAddrZip: 46058,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '10152 E SOUTH RIVER RD',
        RegAddrCity: 'PERU',
        RegAddrState: 'IN',
        RegAddrZip: 46970,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '288 S WOODRIDGE DR',
        RegAddrCity: 'PITTSBORO',
        RegAddrState: 'IN',
        RegAddrZip: 46167,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1023 HESS ST',
        RegAddrCity: 'PLAINFIELD',
        RegAddrState: 'IN',
        RegAddrZip: 46168,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '200 N WASHINGTON ST',
        RegAddrCity: 'REYNOLDS',
        RegAddrState: 'IN',
        RegAddrZip: 47980,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2616 NUTMEG LN',
        RegAddrCity: 'W LAFAYETTE',
        RegAddrState: 'IN',
        RegAddrZip: 47906,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '3456 HAMILTON ST',
        RegAddrCity: 'W LAFAYETTE',
        RegAddrState: 'IN',
        RegAddrZip: 47906,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2111 RINGNECK RD',
        RegAddrCity: 'W LAFAYETTE',
        RegAddrState: 'IN',
        RegAddrZip: 47906,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '3930 DEERPATH PL',
        RegAddrCity: 'W LAFAYETTE',
        RegAddrState: 'IN',
        RegAddrZip: 47906,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '404 S HANCOCK ST',
        RegAddrCity: 'WAYNETOWN',
        RegAddrState: 'IN',
        RegAddrZip: 47990,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2603 SNOWDROP DR',
        RegAddrCity: 'WEST LAFAYETTE',
        RegAddrState: 'IN',
        RegAddrZip: 47906,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '3391 W 400 S',
        RegAddrCity: 'WILLIAMSPORT',
        RegAddrState: 'IN',
        RegAddrZip: 47993,
        CongressionalDistrict: 4,
      },
    ],
  },
  {
    name: 'IN-5',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/IN-5?ref=gh-pages',
    state: 'IN',
    state_and_number: 'IN-5',
    zip: 46001,
    congressionalDistrict: 5,
    addressesInDistrict: [
      {
        RegAddrLine1: '3640 W CROSS ST',
        RegAddrCity: 'ANDERSON',
        RegAddrState: 'IN',
        RegAddrZip: 46011,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '1809 N MADISON AVE',
        RegAddrCity: 'ANDERSON',
        RegAddrState: 'IN',
        RegAddrZip: 46011,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '1704 DALE CT',
        RegAddrCity: 'ANDERSON',
        RegAddrState: 'IN',
        RegAddrZip: 46012,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '2413 HOLDEN DR',
        RegAddrCity: 'ANDERSON',
        RegAddrState: 'IN',
        RegAddrZip: 46012,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '1114 FLATROCK DR',
        RegAddrCity: 'ANDERSON',
        RegAddrState: 'IN',
        RegAddrZip: 46013,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '5019 S 100 W',
        RegAddrCity: 'ANDERSON',
        RegAddrState: 'IN',
        RegAddrZip: 46013,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '1103 W 11TH ST',
        RegAddrCity: 'ANDERSON',
        RegAddrState: 'IN',
        RegAddrZip: 46016,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '1810 DEWEY ST',
        RegAddrCity: 'ANDERSON',
        RegAddrState: 'IN',
        RegAddrZip: 46016,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '5771 OSPREY WAY',
        RegAddrCity: 'CARMEL',
        RegAddrState: 'IN',
        RegAddrZip: 46033,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '12560 PEBBLE KNOLL WAY',
        RegAddrCity: 'CARMEL',
        RegAddrState: 'IN',
        RegAddrZip: 46033,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '13506 DALLAS LN',
        RegAddrCity: 'CARMEL',
        RegAddrState: 'IN',
        RegAddrZip: 46033,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '9433 W 900 N',
        RegAddrCity: 'ELWOOD',
        RegAddrState: 'IN',
        RegAddrZip: 46036,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '11585 S 250 E',
        RegAddrCity: 'FAIRMOUNT',
        RegAddrState: 'IN',
        RegAddrZip: 46928,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '11183 LOCH RAVEN BLVD',
        RegAddrCity: 'FISHERS',
        RegAddrState: 'IN',
        RegAddrZip: 46037,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '14440 MILTON RD',
        RegAddrCity: 'FISHERS',
        RegAddrState: 'IN',
        RegAddrZip: 46037,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '9636 FORTUNE DR',
        RegAddrCity: 'FISHERS',
        RegAddrState: 'IN',
        RegAddrZip: 46037,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '12622 ROCKY MOUNTAIN CT',
        RegAddrCity: 'FISHERS',
        RegAddrState: 'IN',
        RegAddrZip: 46037,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '14506 LANSING PL',
        RegAddrCity: 'FISHERS',
        RegAddrState: 'IN',
        RegAddrZip: 46038,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '7574 MEADOW RIDGE DR',
        RegAddrCity: 'FISHERS',
        RegAddrState: 'IN',
        RegAddrZip: 46038,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '8973 WOOSTER CT',
        RegAddrCity: 'FISHERS',
        RegAddrState: 'IN',
        RegAddrZip: 46038,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '10861 HIGH MEADOW CT',
        RegAddrCity: 'FISHERS',
        RegAddrState: 'IN',
        RegAddrZip: 46040,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '10928 STILL HOLLOW RUN',
        RegAddrCity: 'FORT WAYNE',
        RegAddrState: 'IN',
        RegAddrZip: 46818,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '5238 N COLLEGE AVE',
        RegAddrCity: 'INDIANAPOLIS',
        RegAddrState: 'IN',
        RegAddrZip: 46220,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '5757 N KEYSTONE AVE',
        RegAddrCity: 'INDIANAPOLIS',
        RegAddrState: 'IN',
        RegAddrZip: 46220,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '6258 WELKER DR',
        RegAddrCity: 'INDIANAPOLIS',
        RegAddrState: 'IN',
        RegAddrZip: 46236,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '9934 REDMOND CT',
        RegAddrCity: 'INDIANAPOLIS',
        RegAddrState: 'IN',
        RegAddrZip: 46236,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '1725 E 73RD ST',
        RegAddrCity: 'INDIANAPOLIS',
        RegAddrState: 'IN',
        RegAddrZip: 46240,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '6556 AINTREE PL',
        RegAddrCity: 'INDIANAPOLIS',
        RegAddrState: 'IN',
        RegAddrZip: 46250,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '7537 PINE ROYAL DR',
        RegAddrCity: 'INDIANAPOLIS',
        RegAddrState: 'IN',
        RegAddrZip: 46256,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '8040 TANAGER CT',
        RegAddrCity: 'INDIANAPOLIS',
        RegAddrState: 'IN',
        RegAddrZip: 46256,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '9508 HOLLIDAY CIR',
        RegAddrCity: 'INDIANAPOLIS',
        RegAddrState: 'IN',
        RegAddrZip: 46260,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '3212 SANDPIPER SOUTH DR',
        RegAddrCity: 'INDIANAPOLIS',
        RegAddrState: 'IN',
        RegAddrZip: 46268,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '11085 BEECHWOOD DR E',
        RegAddrCity: 'INDIANAPOLIS',
        RegAddrState: 'IN',
        RegAddrZip: 46280,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '10411 ORCHARD PARK DR S',
        RegAddrCity: 'INDIANAPOLIS',
        RegAddrState: 'IN',
        RegAddrZip: 46280,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '10955 BEECHWOOD DR E',
        RegAddrCity: 'INDIANAPOLIS',
        RegAddrState: 'IN',
        RegAddrZip: 46280,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '403 E BRADFORD ST',
        RegAddrCity: 'MARION',
        RegAddrState: 'IN',
        RegAddrZip: 46952,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '5678 W 600 N',
        RegAddrCity: 'MARION',
        RegAddrState: 'IN',
        RegAddrZip: 46952,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '704 S WHITES AVE',
        RegAddrCity: 'MARION',
        RegAddrState: 'IN',
        RegAddrZip: 46953,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '3264 S 700 E',
        RegAddrCity: 'MARION',
        RegAddrState: 'IN',
        RegAddrZip: 46953,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '1137 W 5TH ST',
        RegAddrCity: 'MARION',
        RegAddrState: 'IN',
        RegAddrZip: 46953,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '3264 E 640 S',
        RegAddrCity: 'MARKLEVILLE',
        RegAddrState: 'IN',
        RegAddrZip: 46056,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '10003 OAKLEAF WAY',
        RegAddrCity: 'MCCORDSVILLE',
        RegAddrState: 'IN',
        RegAddrZip: 46055,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '895 CENTER DR',
        RegAddrCity: 'NOBLESVILLE',
        RegAddrState: 'IN',
        RegAddrZip: 46060,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '15758 SYMPHONY BLVD',
        RegAddrCity: 'NOBLESVILLE',
        RegAddrState: 'IN',
        RegAddrZip: 46060,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '1602 CLINTON ST',
        RegAddrCity: 'NOBLESVILLE',
        RegAddrState: 'IN',
        RegAddrZip: 46060,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '19777 STATE ROAD 37 N',
        RegAddrCity: 'NOBLESVILLE',
        RegAddrState: 'IN',
        RegAddrZip: 46060,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '1654 HUNTZINGER BLVD',
        RegAddrCity: 'PENDLETON',
        RegAddrState: 'IN',
        RegAddrZip: 46064,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '2320 W 226TH ST',
        RegAddrCity: 'SHERIDAN',
        RegAddrState: 'IN',
        RegAddrZip: 46069,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '1555 W 1750 N',
        RegAddrCity: 'SUMMITVILLE',
        RegAddrState: 'IN',
        RegAddrZip: 46070,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '1458 E DIVISION RD',
        RegAddrCity: 'TIPTON',
        RegAddrState: 'IN',
        RegAddrZip: 46072,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '17634 WASHINGTON ST',
        RegAddrCity: 'WESTFIELD',
        RegAddrState: 'IN',
        RegAddrZip: 46074,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '2036 MOBLEY DR',
        RegAddrCity: 'WESTFIELD',
        RegAddrState: 'IN',
        RegAddrZip: 46074,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '16830 DURMAST OAK DR',
        RegAddrCity: 'WESTFIELD',
        RegAddrState: 'IN',
        RegAddrZip: 46074,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '16032 BARRINGER CT',
        RegAddrCity: 'WESTFIELD',
        RegAddrState: 'IN',
        RegAddrZip: 46074,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '815 S INDEPENDENCE ST',
        RegAddrCity: 'WINDFALL',
        RegAddrState: 'IN',
        RegAddrZip: 46076,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '15405 LITTLE EAGLE CREEK AVE',
        RegAddrCity: 'ZIONSVILLE',
        RegAddrState: 'IN',
        RegAddrZip: 46077,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '1325 HICKORY LN',
        RegAddrCity: 'ZIONSVILLE',
        RegAddrState: 'IN',
        RegAddrZip: 46077,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '95 BENNINGTON DR',
        RegAddrCity: 'ZIONSVILLE',
        RegAddrState: 'IN',
        RegAddrZip: 46077,
        CongressionalDistrict: 5,
      },
    ],
  },
  {
    name: 'IN-6',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/IN-6?ref=gh-pages',
    state: 'IN',
    state_and_number: 'IN-6',
    zip: 45053,
    congressionalDistrict: 6,
    addressesInDistrict: [
      {
        RegAddrLine1: '13900 N HOLLOWELL RD',
        RegAddrCity: 'ALBANY',
        RegAddrState: 'IN',
        RegAddrZip: 47320,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '7667 E GREGORY RD',
        RegAddrCity: 'ALBANY',
        RegAddrState: 'IN',
        RegAddrZip: 47320,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '18635 WILLOUGHBY RD',
        RegAddrCity: 'AURORA',
        RegAddrState: 'IN',
        RegAddrZip: 47001,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '202 N MULBERRY ST',
        RegAddrCity: 'BATESVILLE',
        RegAddrState: 'IN',
        RegAddrZip: 47006,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '11110 REBECCA DR',
        RegAddrCity: 'BROOKVILLE',
        RegAddrState: 'IN',
        RegAddrZip: 47012,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '11020 W US HIGHWAY 40',
        RegAddrCity: 'CHARLOTTESVLE',
        RegAddrState: 'IN',
        RegAddrZip: 46117,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '1522 18TH ST',
        RegAddrCity: 'COLUMBUS',
        RegAddrState: 'IN',
        RegAddrZip: 47201,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '2604 CHESTNUT ST',
        RegAddrCity: 'COLUMBUS',
        RegAddrState: 'IN',
        RegAddrZip: 47201,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '5636 POPLAR WOODS CT',
        RegAddrCity: 'COLUMBUS',
        RegAddrState: 'IN',
        RegAddrZip: 47203,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '2019 PARKSIDE DR',
        RegAddrCity: 'COLUMBUS',
        RegAddrState: 'IN',
        RegAddrZip: 47203,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '2713 IOWA AVE',
        RegAddrCity: 'CONNERSVILLE',
        RegAddrState: 'IN',
        RegAddrZip: 47331,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '613 VINE ST',
        RegAddrCity: 'CONNERSVILLE',
        RegAddrState: 'IN',
        RegAddrZip: 47331,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '7610 W COUNTY ROAD 550 S',
        RegAddrCity: 'DALEVILLE',
        RegAddrState: 'IN',
        RegAddrZip: 47334,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '1409 N 1525 W',
        RegAddrCity: 'DEPUTY',
        RegAddrState: 'IN',
        RegAddrZip: 47230,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '13040 MAIN ST',
        RegAddrCity: 'DILLSBORO',
        RegAddrState: 'IN',
        RegAddrZip: 47018,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '10709 N JACKSONBURG RD',
        RegAddrCity: 'ECONOMY',
        RegAddrState: 'IN',
        RegAddrZip: 47339,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '4210 W 700 N',
        RegAddrCity: 'EDINBURGH',
        RegAddrState: 'IN',
        RegAddrZip: 46124,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '15163 N LILLIAN ST',
        RegAddrCity: 'EDINBURGH',
        RegAddrState: 'IN',
        RegAddrZip: 46124,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '10062 TURTLE CREEK RD',
        RegAddrCity: 'FLORENCE',
        RegAddrState: 'IN',
        RegAddrZip: 47020,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '555 TANNER AVE',
        RegAddrCity: 'GREENDALE',
        RegAddrState: 'IN',
        RegAddrZip: 47025,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '863 E 300 S',
        RegAddrCity: 'GREENFIELD',
        RegAddrState: 'IN',
        RegAddrZip: 46140,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '5233 E US HIGHWAY 40',
        RegAddrCity: 'GREENFIELD',
        RegAddrState: 'IN',
        RegAddrZip: 46140,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '602 W 11TH ST',
        RegAddrCity: 'GREENSBURG',
        RegAddrState: 'IN',
        RegAddrZip: 47240,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '524 MEADOWBROOK DR',
        RegAddrCity: 'LAWRENCEBURG',
        RegAddrState: 'IN',
        RegAddrZip: 47025,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '414 EUPHEMIA ST',
        RegAddrCity: 'LAWRENCEBURG',
        RegAddrState: 'IN',
        RegAddrZip: 47025,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '7649 S BLOOMINGPORT RD',
        RegAddrCity: 'LYNN',
        RegAddrState: 'IN',
        RegAddrZip: 47355,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '306 GOLF RIDGE LN',
        RegAddrCity: 'MADISON',
        RegAddrState: 'IN',
        RegAddrZip: 47250,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '6428 W MEDFORD CT',
        RegAddrCity: 'MCCORDSVILLE',
        RegAddrState: 'IN',
        RegAddrZip: 46055,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '139 W BROADWAY ST',
        RegAddrCity: 'MORRISTOWN',
        RegAddrState: 'IN',
        RegAddrZip: 46161,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '1800 W 17TH ST',
        RegAddrCity: 'MUNCIE',
        RegAddrState: 'IN',
        RegAddrZip: 47302,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '1211 S LUICK AVE',
        RegAddrCity: 'MUNCIE',
        RegAddrState: 'IN',
        RegAddrZip: 47302,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '3901 E COUNTY ROAD 450 S',
        RegAddrCity: 'MUNCIE',
        RegAddrState: 'IN',
        RegAddrZip: 47302,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '716 N WALDEMERE AVE',
        RegAddrCity: 'MUNCIE',
        RegAddrState: 'IN',
        RegAddrZip: 47303,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '3109 W GODMAN AVE',
        RegAddrCity: 'MUNCIE',
        RegAddrState: 'IN',
        RegAddrZip: 47304,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '5213 N AUSTIN DR',
        RegAddrCity: 'MUNCIE',
        RegAddrState: 'IN',
        RegAddrZip: 47304,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '3409 W BETHEL AVE',
        RegAddrCity: 'MUNCIE',
        RegAddrState: 'IN',
        RegAddrZip: 47304,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '221 CLEVENGER HALL',
        RegAddrCity: 'MUNCIE',
        RegAddrState: 'IN',
        RegAddrZip: 47306,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '3326 N COUNTY ROAD 125 W',
        RegAddrCity: 'NEW CASTLE',
        RegAddrState: 'IN',
        RegAddrZip: 47362,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '4743 MOHR ESTATE SOUTH DR',
        RegAddrCity: 'NEW PALESTINE',
        RegAddrState: 'IN',
        RegAddrZip: 46163,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '3889 BRANDYWYNE DR',
        RegAddrCity: 'NORTH VERNON',
        RegAddrState: 'IN',
        RegAddrZip: 47265,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '24081 HAMBURG RD',
        RegAddrCity: 'OLDENBURG',
        RegAddrState: 'IN',
        RegAddrZip: 47036,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '8284 W COUNTY ROAD 575 N',
        RegAddrCity: 'OSGOOD',
        RegAddrState: 'IN',
        RegAddrZip: 47037,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '119 E STATE ROAD 350',
        RegAddrCity: 'OSGOOD',
        RegAddrState: 'IN',
        RegAddrZip: 47037,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '458 SW 3RD ST',
        RegAddrCity: 'RICHMOND',
        RegAddrState: 'IN',
        RegAddrZip: 47374,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '1303 S 3RD ST',
        RegAddrCity: 'RICHMOND',
        RegAddrState: 'IN',
        RegAddrZip: 47374,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '417 COLLEGE AVE',
        RegAddrCity: 'RICHMOND',
        RegAddrState: 'IN',
        RegAddrZip: 47374,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '461 SW 3RD ST',
        RegAddrCity: 'RICHMOND',
        RegAddrState: 'IN',
        RegAddrZip: 47374,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '208 5TH ST',
        RegAddrCity: 'RISING SUN',
        RegAddrState: 'IN',
        RegAddrZip: 47040,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '8598 N 200 W',
        RegAddrCity: 'RUSHVILLE',
        RegAddrState: 'IN',
        RegAddrZip: 46173,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '764 SHELBYS CRST',
        RegAddrCity: 'SHELBYVILLE',
        RegAddrState: 'IN',
        RegAddrZip: 46176,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '1548 S THOMPSON RD',
        RegAddrCity: 'SHELBYVILLE',
        RegAddrState: 'IN',
        RegAddrZip: 46176,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '5594 S SPICELAND RD',
        RegAddrCity: 'SPICELAND',
        RegAddrState: 'IN',
        RegAddrZip: 47385,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '7208 N PRAIRIE RD',
        RegAddrCity: 'SPRINGPORT',
        RegAddrState: 'IN',
        RegAddrZip: 47386,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '1225 N PLUM ST',
        RegAddrCity: 'UNION CITY',
        RegAddrState: 'IN',
        RegAddrZip: 47390,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '1483 W TANGLEWOOD RD',
        RegAddrCity: 'VERSAILLES',
        RegAddrState: 'IN',
        RegAddrZip: 47042,
        CongressionalDistrict: 6,
      },
    ],
  },
  {
    name: 'IN-7',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/IN-7?ref=gh-pages',
    state: 'IN',
    state_and_number: 'IN-7',
    zip: 46107,
    congressionalDistrict: 7,
    addressesInDistrict: [
      {
        RegAddrLine1: '121 S 8TH AVE',
        RegAddrCity: 'BEECH GROVE',
        RegAddrState: 'IN',
        RegAddrZip: 46107,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '627 N DEARBORN ST',
        RegAddrCity: 'INDIANAPOLIS',
        RegAddrState: 'IN',
        RegAddrZip: 46201,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '214 N HAMILTON AVE',
        RegAddrCity: 'INDIANAPOLIS',
        RegAddrState: 'IN',
        RegAddrZip: 46201,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '1918 KOEHNE ST',
        RegAddrCity: 'INDIANAPOLIS',
        RegAddrState: 'IN',
        RegAddrZip: 46202,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '1517 S RANDOLPH ST',
        RegAddrCity: 'INDIANAPOLIS',
        RegAddrState: 'IN',
        RegAddrZip: 46203,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '1142 SPRUCE ST',
        RegAddrCity: 'INDIANAPOLIS',
        RegAddrState: 'IN',
        RegAddrZip: 46203,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '1344 W 27TH ST',
        RegAddrCity: 'INDIANAPOLIS',
        RegAddrState: 'IN',
        RegAddrZip: 46208,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '655 W HAMPTON DR',
        RegAddrCity: 'INDIANAPOLIS',
        RegAddrState: 'IN',
        RegAddrZip: 46208,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '3902 GRACELAND AVE',
        RegAddrCity: 'INDIANAPOLIS',
        RegAddrState: 'IN',
        RegAddrZip: 46208,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '8114 WINTERSET CIR',
        RegAddrCity: 'INDIANAPOLIS',
        RegAddrState: 'IN',
        RegAddrZip: 46214,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '7817 INISHMORE WAY',
        RegAddrCity: 'INDIANAPOLIS',
        RegAddrState: 'IN',
        RegAddrZip: 46214,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '749 W BANTA RD',
        RegAddrCity: 'INDIANAPOLIS',
        RegAddrState: 'IN',
        RegAddrZip: 46217,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '3007 REDLAND LN',
        RegAddrCity: 'INDIANAPOLIS',
        RegAddrState: 'IN',
        RegAddrZip: 46217,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '2919 NEWHART ST',
        RegAddrCity: 'INDIANAPOLIS',
        RegAddrState: 'IN',
        RegAddrZip: 46217,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '3414 N PARKER AVE',
        RegAddrCity: 'INDIANAPOLIS',
        RegAddrState: 'IN',
        RegAddrZip: 46218,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '1420 CAMPBELL AVE',
        RegAddrCity: 'INDIANAPOLIS',
        RegAddrState: 'IN',
        RegAddrZip: 46219,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '6126 NAVY CIR',
        RegAddrCity: 'INDIANAPOLIS',
        RegAddrState: 'IN',
        RegAddrZip: 46221,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '1832 S PERSHING AVE',
        RegAddrCity: 'INDIANAPOLIS',
        RegAddrState: 'IN',
        RegAddrZip: 46221,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '7205 CORDOVA DR',
        RegAddrCity: 'INDIANAPOLIS',
        RegAddrState: 'IN',
        RegAddrZip: 46221,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '4643 LONDONDERRY CT',
        RegAddrCity: 'INDIANAPOLIS',
        RegAddrState: 'IN',
        RegAddrZip: 46221,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '3657 GREEN ASH CT',
        RegAddrCity: 'INDIANAPOLIS',
        RegAddrState: 'IN',
        RegAddrZip: 46222,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '2637 W 22ND ST',
        RegAddrCity: 'INDIANAPOLIS',
        RegAddrState: 'IN',
        RegAddrZip: 46222,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '467 N ARNOLDA AVE',
        RegAddrCity: 'INDIANAPOLIS',
        RegAddrState: 'IN',
        RegAddrZip: 46222,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '8710 ELMONTE DR',
        RegAddrCity: 'INDIANAPOLIS',
        RegAddrState: 'IN',
        RegAddrZip: 46226,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '3850 RED MILL WAY',
        RegAddrCity: 'INDIANAPOLIS',
        RegAddrState: 'IN',
        RegAddrZip: 46226,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '1259 MARTIN ST',
        RegAddrCity: 'INDIANAPOLIS',
        RegAddrState: 'IN',
        RegAddrZip: 46227,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '825 E EDGEWOOD AVE',
        RegAddrCity: 'INDIANAPOLIS',
        RegAddrState: 'IN',
        RegAddrZip: 46227,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '1920 W 60TH ST',
        RegAddrCity: 'INDIANAPOLIS',
        RegAddrState: 'IN',
        RegAddrZip: 46228,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '10053 NASSAU LN',
        RegAddrCity: 'INDIANAPOLIS',
        RegAddrState: 'IN',
        RegAddrZip: 46229,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '10846 GREENLEAF DR',
        RegAddrCity: 'INDIANAPOLIS',
        RegAddrState: 'IN',
        RegAddrZip: 46229,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '3358 TANSEL RD',
        RegAddrCity: 'INDIANAPOLIS',
        RegAddrState: 'IN',
        RegAddrZip: 46234,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '1810 BLUE CHIP CIR',
        RegAddrCity: 'INDIANAPOLIS',
        RegAddrState: 'IN',
        RegAddrZip: 46234,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '7552 GUNYON DR',
        RegAddrCity: 'INDIANAPOLIS',
        RegAddrState: 'IN',
        RegAddrZip: 46237,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '740 FREESTONE DR',
        RegAddrCity: 'INDIANAPOLIS',
        RegAddrState: 'IN',
        RegAddrZip: 46239,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '4915 CALLAHAN ST',
        RegAddrCity: 'INDIANAPOLIS',
        RegAddrState: 'IN',
        RegAddrZip: 46239,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '7907 GRAND GULCH DR',
        RegAddrCity: 'INDIANAPOLIS',
        RegAddrState: 'IN',
        RegAddrZip: 46239,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '9850 SOUTHEASTERN AVE',
        RegAddrCity: 'INDIANAPOLIS',
        RegAddrState: 'IN',
        RegAddrZip: 46239,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '2962 S LOCKBURN ST',
        RegAddrCity: 'INDIANAPOLIS',
        RegAddrState: 'IN',
        RegAddrZip: 46241,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '6160 S CARROLL RD',
        RegAddrCity: 'INDIANAPOLIS',
        RegAddrState: 'IN',
        RegAddrZip: 46259,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '4820 PENDRAGON BLVD',
        RegAddrCity: 'INDIANAPOLIS',
        RegAddrState: 'IN',
        RegAddrZip: 46268,
        CongressionalDistrict: 7,
      },
    ],
  },
  {
    name: 'IN-8',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/IN-8?ref=gh-pages',
    state: 'IN',
    state_and_number: 'IN-8',
    zip: 42420,
    congressionalDistrict: 8,
    addressesInDistrict: [
      {
        RegAddrLine1: '426 W JOHNSON RD',
        RegAddrCity: 'BLOOMFIELD',
        RegAddrState: 'IN',
        RegAddrZip: 47424,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '5440 N HIGH ST',
        RegAddrCity: 'CAYUGA',
        RegAddrState: 'IN',
        RegAddrZip: 47928,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '9022 HEIM RD',
        RegAddrCity: 'CHANDLER',
        RegAddrState: 'IN',
        RegAddrZip: 47610,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '6577 NANCY LN',
        RegAddrCity: 'CHANDLER',
        RegAddrState: 'IN',
        RegAddrZip: 47610,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '628 E LINCOLN AVE',
        RegAddrCity: 'CHANDLER',
        RegAddrState: 'IN',
        RegAddrZip: 47610,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '7837 E MONROE ST',
        RegAddrCity: 'DUGGER',
        RegAddrState: 'IN',
        RegAddrZip: 47848,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '5448 E 1300 S',
        RegAddrCity: 'ELBERFELD',
        RegAddrState: 'IN',
        RegAddrZip: 47613,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '9325 N COUNTY ROAD 1250 E',
        RegAddrCity: 'EVANSTON',
        RegAddrState: 'IN',
        RegAddrZip: 47531,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '6 W CAMP GROUND RD',
        RegAddrCity: 'EVANSVILLE',
        RegAddrState: 'IN',
        RegAddrZip: 47710,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '5900 HAMILTON DR',
        RegAddrCity: 'EVANSVILLE',
        RegAddrState: 'IN',
        RegAddrZip: 47711,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '1136 NEGLEY AVE',
        RegAddrCity: 'EVANSVILLE',
        RegAddrState: 'IN',
        RegAddrZip: 47711,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '2715 FOREST AVE',
        RegAddrCity: 'EVANSVILLE',
        RegAddrState: 'IN',
        RegAddrZip: 47712,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '9201 FARMINGTON DR',
        RegAddrCity: 'EVANSVILLE',
        RegAddrState: 'IN',
        RegAddrZip: 47712,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '422 S GARVIN ST',
        RegAddrCity: 'EVANSVILLE',
        RegAddrState: 'IN',
        RegAddrZip: 47713,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '727 BAYARD PARK DR',
        RegAddrCity: 'EVANSVILLE',
        RegAddrState: 'IN',
        RegAddrZip: 47713,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '526 S BOEKE RD',
        RegAddrCity: 'EVANSVILLE',
        RegAddrState: 'IN',
        RegAddrZip: 47714,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '3400 LINCOLN AVE',
        RegAddrCity: 'EVANSVILLE',
        RegAddrState: 'IN',
        RegAddrZip: 47714,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '1415 OLIVE ST',
        RegAddrCity: 'EVANSVILLE',
        RegAddrState: 'IN',
        RegAddrZip: 47714,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '1518 POLLACK AVE',
        RegAddrCity: 'EVANSVILLE',
        RegAddrState: 'IN',
        RegAddrZip: 47714,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '6001 N GREEN RIVER RD',
        RegAddrCity: 'EVANSVILLE',
        RegAddrState: 'IN',
        RegAddrZip: 47715,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '2201 S GREEN RIVER RD',
        RegAddrCity: 'EVANSVILLE',
        RegAddrState: 'IN',
        RegAddrZip: 47715,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '4525 WINDHAM DR',
        RegAddrCity: 'EVANSVILLE',
        RegAddrState: 'IN',
        RegAddrZip: 47725,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '8712 WHETSTONE RD',
        RegAddrCity: 'EVANSVILLE',
        RegAddrState: 'IN',
        RegAddrZip: 47725,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '521 E 23RD ST',
        RegAddrCity: 'FERDINAND',
        RegAddrState: 'IN',
        RegAddrZip: 47532,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '106 E VINE ST',
        RegAddrCity: 'FORT BRANCH',
        RegAddrState: 'IN',
        RegAddrZip: 47648,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '633 W 1200 S',
        RegAddrCity: 'HAUBSTADT',
        RegAddrState: 'IN',
        RegAddrZip: 47639,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '415 E MAIN ST',
        RegAddrCity: 'JASONVILLE',
        RegAddrState: 'IN',
        RegAddrZip: 47438,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '4142 MANOR DR',
        RegAddrCity: 'JASPER',
        RegAddrState: 'IN',
        RegAddrZip: 47546,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '940 N SAVANNAH DR',
        RegAddrCity: 'JASPER',
        RegAddrState: 'IN',
        RegAddrZip: 47546,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '3201 BUCHANAN RD',
        RegAddrCity: 'MOUNT VERNON',
        RegAddrState: 'IN',
        RegAddrZip: 47620,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '4355 WYNBROOKE CT',
        RegAddrCity: 'NEWBURGH',
        RegAddrState: 'IN',
        RegAddrZip: 47630,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '3939 E COUNTY ROAD 475 N',
        RegAddrCity: 'PETERSBURG',
        RegAddrState: 'IN',
        RegAddrZip: 47567,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '12621 STATE ROAD 159',
        RegAddrCity: 'PIMENTO',
        RegAddrState: 'IN',
        RegAddrZip: 47866,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '804 MAPLE DR',
        RegAddrCity: 'ROCKVILLE',
        RegAddrState: 'IN',
        RegAddrZip: 47872,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '3844 S 130 E',
        RegAddrCity: 'ROCKVILLE',
        RegAddrState: 'IN',
        RegAddrZip: 47872,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '11551 N MAIN ST',
        RegAddrCity: 'ROSEDALE',
        RegAddrState: 'IN',
        RegAddrZip: 47874,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '12408 STATE ROAD 62',
        RegAddrCity: 'SAINT CROIX',
        RegAddrState: 'IN',
        RegAddrZip: 47576,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '10557 E COUNTY ROAD 1600 N',
        RegAddrCity: 'SANTA CLAUS',
        RegAddrState: 'IN',
        RegAddrZip: 47579,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '4865 STATE ROAD 550',
        RegAddrCity: 'SHOALS',
        RegAddrState: 'IN',
        RegAddrZip: 47581,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '329 DEER RUN DR',
        RegAddrCity: 'SPENCER',
        RegAddrState: 'IN',
        RegAddrZip: 47460,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '7111 STATE HIGHWAY 246',
        RegAddrCity: 'SPENCER',
        RegAddrState: 'IN',
        RegAddrZip: 47460,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '206 W COLUMBUS ST',
        RegAddrCity: 'STAUNTON',
        RegAddrState: 'IN',
        RegAddrZip: 47881,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '3411 BLUEGRASS LN',
        RegAddrCity: 'TERRE HAUTE',
        RegAddrState: 'IN',
        RegAddrZip: 47802,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '1965 W BELMONT DR',
        RegAddrCity: 'TERRE HAUTE',
        RegAddrState: 'IN',
        RegAddrZip: 47802,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '1700 POPLAR ST',
        RegAddrCity: 'TERRE HAUTE',
        RegAddrState: 'IN',
        RegAddrZip: 47803,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '2541 N 9TH ST',
        RegAddrCity: 'TERRE HAUTE',
        RegAddrState: 'IN',
        RegAddrZip: 47804,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '1100 N FRUITRIDGE AVE',
        RegAddrCity: 'TERRE HAUTE',
        RegAddrState: 'IN',
        RegAddrZip: 47804,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '218 S 7TH ST',
        RegAddrCity: 'VINCENNES',
        RegAddrState: 'IN',
        RegAddrZip: 47591,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '2823 E COUNTY ROAD 150 S',
        RegAddrCity: 'WINSLOW',
        RegAddrState: 'IN',
        RegAddrZip: 47598,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '27 CENTER ST',
        RegAddrCity: 'WORTHINGTON',
        RegAddrState: 'IN',
        RegAddrZip: 47471,
        CongressionalDistrict: 8,
      },
    ],
  },
  {
    name: 'IN-9',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/IN-9?ref=gh-pages',
    state: 'IN',
    state_and_number: 'IN-9',
    zip: 46106,
    congressionalDistrict: 9,
    addressesInDistrict: [
      {
        RegAddrLine1: '806 18TH ST',
        RegAddrCity: 'BEDFORD',
        RegAddrState: 'IN',
        RegAddrZip: 47421,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '104 WINDRIDGE CIR',
        RegAddrCity: 'BEDFORD',
        RegAddrState: 'IN',
        RegAddrZip: 47421,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '1309 AVOCA EUREKA RD',
        RegAddrCity: 'BEDFORD',
        RegAddrState: 'IN',
        RegAddrZip: 47421,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '1523 S ARBORS LN',
        RegAddrCity: 'BLOOMINGTON',
        RegAddrState: 'IN',
        RegAddrZip: 47401,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '618 E MOSS CREEK CT',
        RegAddrCity: 'BLOOMINGTON',
        RegAddrState: 'IN',
        RegAddrZip: 47401,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '529 E GRAHAM PL',
        RegAddrCity: 'BLOOMINGTON',
        RegAddrState: 'IN',
        RegAddrZip: 47401,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '1014 E 1ST ST',
        RegAddrCity: 'BLOOMINGTON',
        RegAddrState: 'IN',
        RegAddrZip: 47401,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '5372 S FAIRFAX RD',
        RegAddrCity: 'BLOOMINGTON',
        RegAddrState: 'IN',
        RegAddrZip: 47401,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '612 S GRANT ST',
        RegAddrCity: 'BLOOMINGTON',
        RegAddrState: 'IN',
        RegAddrZip: 47401,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '3100 W MAPLE GROVE RD',
        RegAddrCity: 'BLOOMINGTON',
        RegAddrState: 'IN',
        RegAddrZip: 47404,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '4710 N WHITE RIVER DR',
        RegAddrCity: 'BLOOMINGTON',
        RegAddrState: 'IN',
        RegAddrZip: 47404,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '1111 N OOLITIC DR',
        RegAddrCity: 'BLOOMINGTON',
        RegAddrState: 'IN',
        RegAddrZip: 47404,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '20936 MARTINSBURG RD',
        RegAddrCity: 'BORDEN',
        RegAddrState: 'IN',
        RegAddrZip: 47106,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '5716 BULL CREEK RD',
        RegAddrCity: 'CHARLESTOWN',
        RegAddrState: 'IN',
        RegAddrZip: 47111,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '2415 BLACKISTON MILL RD',
        RegAddrCity: 'CLARKSVILLE',
        RegAddrState: 'IN',
        RegAddrZip: 47129,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '932 PROVIDENCE WAY',
        RegAddrCity: 'CLARKSVILLE',
        RegAddrState: 'IN',
        RegAddrZip: 47129,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '112 N BETHANY RD',
        RegAddrCity: 'CROTHERSVILLE',
        RegAddrState: 'IN',
        RegAddrZip: 47229,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '127 N EAST ST',
        RegAddrCity: 'CROTHERSVILLE',
        RegAddrState: 'IN',
        RegAddrZip: 47229,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '514 W CAMPBELL ST',
        RegAddrCity: 'EDINBURGH',
        RegAddrState: 'IN',
        RegAddrZip: 46124,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '118 1/2 S HOLLAND ST',
        RegAddrCity: 'EDINBURGH',
        RegAddrState: 'IN',
        RegAddrZip: 46124,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '4200 WINWOOD CT',
        RegAddrCity: 'FLOYDS KNOBS',
        RegAddrState: 'IN',
        RegAddrZip: 47119,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '1703 YOUNCE ST',
        RegAddrCity: 'FRANKLIN',
        RegAddrState: 'IN',
        RegAddrZip: 46131,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '2041 YOUNCE ST',
        RegAddrCity: 'FRANKLIN',
        RegAddrState: 'IN',
        RegAddrZip: 46131,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '705 WILLOW OAK DR',
        RegAddrCity: 'GEORGETOWN',
        RegAddrState: 'IN',
        RegAddrZip: 47122,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '573 YORKTOWN RD',
        RegAddrCity: 'GREENWOOD',
        RegAddrState: 'IN',
        RegAddrZip: 46142,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '617 SAN CARLOS DR',
        RegAddrCity: 'GREENWOOD',
        RegAddrState: 'IN',
        RegAddrZip: 46142,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '791 COLONIAL WAY',
        RegAddrCity: 'GREENWOOD',
        RegAddrState: 'IN',
        RegAddrZip: 46142,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '228 SOUTHWIND WAY',
        RegAddrCity: 'GREENWOOD',
        RegAddrState: 'IN',
        RegAddrZip: 46142,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '2656 FRAISER FIR DR',
        RegAddrCity: 'GREENWOOD',
        RegAddrState: 'IN',
        RegAddrZip: 46143,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '4137 BAYBERRY CT',
        RegAddrCity: 'GREENWOOD',
        RegAddrState: 'IN',
        RegAddrZip: 46143,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '759 SCOTCH PINE DR',
        RegAddrCity: 'GREENWOOD',
        RegAddrState: 'IN',
        RegAddrZip: 46143,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '7115 HENRYVILLE OTISCO RD',
        RegAddrCity: 'HENRYVILLE',
        RegAddrState: 'IN',
        RegAddrZip: 47126,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '3609 SEA PNE',
        RegAddrCity: 'JEFFERSONVILLE',
        RegAddrState: 'IN',
        RegAddrZip: 47130,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '1804 STONELAKE DR',
        RegAddrCity: 'JEFFERSONVLLE',
        RegAddrState: 'IN',
        RegAddrZip: 47130,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '87 FOREST DR',
        RegAddrCity: 'JEFFERSONVLLE',
        RegAddrState: 'IN',
        RegAddrZip: 47130,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '225 MEIGS AVE',
        RegAddrCity: 'JEFFERSONVLLE',
        RegAddrState: 'IN',
        RegAddrZip: 47130,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '559 DUO DR',
        RegAddrCity: 'MARTINSVILLE',
        RegAddrState: 'IN',
        RegAddrZip: 46151,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '11914 NEW MARKET RD',
        RegAddrCity: 'MARYSVILLE',
        RegAddrState: 'IN',
        RegAddrZip: 47141,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '911 WPA RD',
        RegAddrCity: 'MITCHELL',
        RegAddrState: 'IN',
        RegAddrZip: 47446,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '6680 E LAURAL CT N',
        RegAddrCity: 'MOORESVILLE',
        RegAddrState: 'IN',
        RegAddrZip: 46158,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '9587 N STATE ROAD 135',
        RegAddrCity: 'MORGANTOWN',
        RegAddrState: 'IN',
        RegAddrZip: 46160,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '2605 ROANOKE AVE',
        RegAddrCity: 'NEW ALBANY',
        RegAddrState: 'IN',
        RegAddrZip: 47150,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '2211 GARY DR',
        RegAddrCity: 'NEW ALBANY',
        RegAddrState: 'IN',
        RegAddrZip: 47150,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '316 E 10TH ST',
        RegAddrCity: 'NEW ALBANY',
        RegAddrState: 'IN',
        RegAddrZip: 47150,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '1001 PINEWOOD TRL NE',
        RegAddrCity: 'NEW SALISBURY',
        RegAddrState: 'IN',
        RegAddrZip: 47161,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '1025 HIGHWAY 64 NE',
        RegAddrCity: 'NEW SALISBURY',
        RegAddrState: 'IN',
        RegAddrZip: 47161,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '2672 N COUNTY ROAD 1100 W',
        RegAddrCity: 'NORMAN',
        RegAddrState: 'IN',
        RegAddrZip: 47264,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '14860 APPLE LN NE',
        RegAddrCity: 'PALMYRA',
        RegAddrState: 'IN',
        RegAddrZip: 47164,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '106 HILLCREST DR',
        RegAddrCity: 'SALEM',
        RegAddrState: 'IN',
        RegAddrZip: 47167,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '133 MEADOW DELL VLG',
        RegAddrCity: 'SALEM',
        RegAddrState: 'IN',
        RegAddrZip: 47167,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '1003 N WATER ST',
        RegAddrCity: 'SALEM',
        RegAddrState: 'IN',
        RegAddrZip: 47167,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '13002 COVERED BRIDGE RD',
        RegAddrCity: 'SELLERSBURG',
        RegAddrState: 'IN',
        RegAddrZip: 47172,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '11562 FOREST HILL CIR',
        RegAddrCity: 'SELLERSBURG',
        RegAddrState: 'IN',
        RegAddrZip: 47172,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '608 S POPLAR ST',
        RegAddrCity: 'SEYMOUR',
        RegAddrState: 'IN',
        RegAddrZip: 47274,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '8851 E SOUTHSHORE DR',
        RegAddrCity: 'UNIONVILLE',
        RegAddrState: 'IN',
        RegAddrZip: 47468,
        CongressionalDistrict: 9,
      },
    ],
  },
  {
    name: 'KS-1',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/KS-1?ref=gh-pages',
    state: 'KS',
    state_and_number: 'KS-1',
    zip: 66401,
    congressionalDistrict: 1,
    addressesInDistrict: [
      {
        RegAddrLine1: '307 CHARLES RD',
        RegAddrCity: 'ABILENE',
        RegAddrState: 'KS',
        RegAddrZip: 67410,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '104 N PINE ST',
        RegAddrCity: 'ABILENE',
        RegAddrState: 'KS',
        RegAddrZip: 67410,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2845 FAIR RD',
        RegAddrCity: 'ABILENE',
        RegAddrState: 'KS',
        RegAddrZip: 67410,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '848 W 4TH AVE',
        RegAddrCity: 'ASHLAND',
        RegAddrState: 'KS',
        RegAddrZip: 67831,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '212 N MAIN ST',
        RegAddrCity: 'BAZINE',
        RegAddrState: 'KS',
        RegAddrZip: 67516,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '222 W 2ND ST',
        RegAddrCity: 'BIRD CITY',
        RegAddrState: 'KS',
        RegAddrZip: 67731,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '400 S BUHLER RD',
        RegAddrCity: 'BUHLER',
        RegAddrState: 'KS',
        RegAddrZip: 67522,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2425 4TH RD',
        RegAddrCity: 'BUSHTON',
        RegAddrState: 'KS',
        RegAddrZip: 67427,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '403 E CANAL ST',
        RegAddrCity: 'CIMARRON',
        RegAddrState: 'KS',
        RegAddrZip: 67835,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '419 ARTHUR ST',
        RegAddrCity: 'CLAY CENTER',
        RegAddrState: 'KS',
        RegAddrZip: 67432,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2058 OSAGE RD',
        RegAddrCity: 'CLAY CENTER',
        RegAddrState: 'KS',
        RegAddrZip: 67432,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1811 OVERVIEW DR',
        RegAddrCity: 'CLAY CENTER',
        RegAddrState: 'KS',
        RegAddrZip: 67432,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1328 N 280TH RD',
        RegAddrCity: 'CLYDE',
        RegAddrState: 'KS',
        RegAddrZip: 66938,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '328 CHICK ST',
        RegAddrCity: 'COUNCIL GROVE',
        RegAddrState: 'KS',
        RegAddrZip: 66846,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '612 OLIVE ST',
        RegAddrCity: 'DEERFIELD',
        RegAddrState: 'KS',
        RegAddrZip: 67838,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1003 EZ AVE',
        RegAddrCity: 'DODGE CITY',
        RegAddrState: 'KS',
        RegAddrZip: 67801,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '3156 FORT DODGE RD',
        RegAddrCity: 'DODGE CITY',
        RegAddrState: 'KS',
        RegAddrZip: 67801,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '804 LA SALLE ST',
        RegAddrCity: 'DODGE CITY',
        RegAddrState: 'KS',
        RegAddrZip: 67801,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2905 TOALSON AVE',
        RegAddrCity: 'DODGE CITY',
        RegAddrState: 'KS',
        RegAddrZip: 67801,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1505 1ST AVE',
        RegAddrCity: 'DODGE CITY',
        RegAddrState: 'KS',
        RegAddrZip: 67801,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '415 ROSS ST',
        RegAddrCity: 'DOWNS',
        RegAddrState: 'KS',
        RegAddrZip: 67437,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '412 W 4TH ST',
        RegAddrCity: 'ELLINWOOD',
        RegAddrState: 'KS',
        RegAddrZip: 67526,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '570 SE 50TH RD',
        RegAddrCity: 'ELLINWOOD',
        RegAddrState: 'KS',
        RegAddrZip: 67526,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2924 HIDDEN LAKES DR',
        RegAddrCity: 'EMPORIA',
        RegAddrState: 'KS',
        RegAddrZip: 66801,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '915 LAKEVIEW ST',
        RegAddrCity: 'EMPORIA',
        RegAddrState: 'KS',
        RegAddrZip: 66801,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2401 APPLE DR',
        RegAddrCity: 'EMPORIA',
        RegAddrState: 'KS',
        RegAddrZip: 66801,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '520 S UNION ST',
        RegAddrCity: 'EMPORIA',
        RegAddrState: 'KS',
        RegAddrZip: 66801,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '8 STATE ST',
        RegAddrCity: 'EMPORIA',
        RegAddrState: 'KS',
        RegAddrZip: 66801,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '309 W 1ST ST',
        RegAddrCity: 'ENTERPRISE',
        RegAddrState: 'KS',
        RegAddrZip: 67441,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2109 CENTER ST',
        RegAddrCity: 'GARDEN CITY',
        RegAddrState: 'KS',
        RegAddrZip: 67846,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '611 N CAMPUS DR',
        RegAddrCity: 'GARDEN CITY',
        RegAddrState: 'KS',
        RegAddrZip: 67846,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1615 N 11TH ST',
        RegAddrCity: 'GARDEN CITY',
        RegAddrState: 'KS',
        RegAddrZip: 67846,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2914 SQUIRE PL',
        RegAddrCity: 'GARDEN CITY',
        RegAddrState: 'KS',
        RegAddrZip: 67846,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '965 N STARLIGHT DR',
        RegAddrCity: 'GARDEN CITY',
        RegAddrState: 'KS',
        RegAddrZip: 67846,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2016 N 6TH ST',
        RegAddrCity: 'GARDEN CITY',
        RegAddrState: 'KS',
        RegAddrZip: 67846,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '905 N 7TH ST',
        RegAddrCity: 'GARDEN CITY',
        RegAddrState: 'KS',
        RegAddrZip: 67846,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '918 COLORADO AVE',
        RegAddrCity: 'GOODLAND',
        RegAddrState: 'KS',
        RegAddrZip: 67735,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2902 18TH ST',
        RegAddrCity: 'GREAT BEND',
        RegAddrState: 'KS',
        RegAddrZip: 67530,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1814 RIVIERA DR',
        RegAddrCity: 'GREAT BEND',
        RegAddrState: 'KS',
        RegAddrZip: 67530,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1205 MONROE ST',
        RegAddrCity: 'GREAT BEND',
        RegAddrState: 'KS',
        RegAddrZip: 67530,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2030 LOCUST GROVE RD',
        RegAddrCity: 'HAYS',
        RegAddrState: 'KS',
        RegAddrZip: 67601,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2400 MAIN ST',
        RegAddrCity: 'HAYS',
        RegAddrState: 'KS',
        RegAddrZip: 67601,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1301 DONALD DR',
        RegAddrCity: 'HAYS',
        RegAddrState: 'KS',
        RegAddrZip: 67601,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '311 ASH ST',
        RegAddrCity: 'HAYS',
        RegAddrState: 'KS',
        RegAddrZip: 67601,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '216 E FRANKLYNN ST',
        RegAddrCity: 'HERINGTON',
        RegAddrState: 'KS',
        RegAddrZip: 67449,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '911 S VAN BUREN ST',
        RegAddrCity: 'HUGOTON',
        RegAddrState: 'KS',
        RegAddrZip: 67951,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '579 ROAD 24',
        RegAddrCity: 'HUGOTON',
        RegAddrState: 'KS',
        RegAddrZip: 67951,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1312 E 10TH AVE',
        RegAddrCity: 'HUTCHINSON',
        RegAddrState: 'KS',
        RegAddrZip: 67501,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '313 E 11TH AVE',
        RegAddrCity: 'HUTCHINSON',
        RegAddrState: 'KS',
        RegAddrZip: 67501,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '510 16TH TER',
        RegAddrCity: 'HUTCHINSON',
        RegAddrState: 'KS',
        RegAddrZip: 67501,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '325 E 9TH AVE',
        RegAddrCity: 'HUTCHINSON',
        RegAddrState: 'KS',
        RegAddrZip: 67501,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '22 PRAIRIE DUNES DR',
        RegAddrCity: 'HUTCHINSON',
        RegAddrState: 'KS',
        RegAddrZip: 67502,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '401 KISIWA VILLAGE RD',
        RegAddrCity: 'HUTCHINSON',
        RegAddrState: 'KS',
        RegAddrZip: 67502,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '10905 6TH RD',
        RegAddrCity: 'INGALLS',
        RegAddrState: 'KS',
        RegAddrZip: 67853,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '622 EAKIN ST',
        RegAddrCity: 'JETMORE',
        RegAddrState: 'KS',
        RegAddrZip: 67854,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '615 BUFFALO ST',
        RegAddrCity: 'JEWELL',
        RegAddrState: 'KS',
        RegAddrZip: 66949,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1215 CANNON VIEW LN',
        RegAddrCity: 'JUNCTION CITY',
        RegAddrState: 'KS',
        RegAddrZip: 66441,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '133 BYRD ST',
        RegAddrCity: 'JUNCTION CITY',
        RegAddrState: 'KS',
        RegAddrZip: 66441,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '923 MEADOW LN',
        RegAddrCity: 'JUNCTION CITY',
        RegAddrState: 'KS',
        RegAddrZip: 66441,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1714 MARSTON ST',
        RegAddrCity: 'JUNCTION CITY',
        RegAddrState: 'KS',
        RegAddrZip: 66441,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '114 SUNRISE HILL DR',
        RegAddrCity: 'JUNCTION CITY',
        RegAddrState: 'KS',
        RegAddrZip: 66441,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '710 W PINE ST',
        RegAddrCity: 'JUNCTION CITY',
        RegAddrState: 'KS',
        RegAddrZip: 66441,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '118 E 16TH ST',
        RegAddrCity: 'JUNCTION CITY',
        RegAddrState: 'KS',
        RegAddrZip: 66441,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '144 S KIOWA CT',
        RegAddrCity: 'JUNCTION CITY',
        RegAddrState: 'KS',
        RegAddrZip: 66441,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '418 N IOWA AVE',
        RegAddrCity: 'KANOPOLIS',
        RegAddrState: 'KS',
        RegAddrZip: 67454,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '350 ROAD 57',
        RegAddrCity: 'KANORADO',
        RegAddrState: 'KS',
        RegAddrZip: 67741,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1126 T RD',
        RegAddrCity: 'LAKIN',
        RegAddrState: 'KS',
        RegAddrZip: 67860,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '505 ELM ST',
        RegAddrCity: 'LEBANON',
        RegAddrState: 'KS',
        RegAddrZip: 66952,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '26 S LINCOLN AVE',
        RegAddrCity: 'LIBERAL',
        RegAddrState: 'KS',
        RegAddrZip: 67901,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '603 S PENNSYLVANIA AVE',
        RegAddrCity: 'LIBERAL',
        RegAddrState: 'KS',
        RegAddrZip: 67901,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '848 S PENNSYLVANIA AVE',
        RegAddrCity: 'LIBERAL',
        RegAddrState: 'KS',
        RegAddrZip: 67901,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '111 W COOLIDGE ST',
        RegAddrCity: 'LIBERAL',
        RegAddrState: 'KS',
        RegAddrZip: 67901,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '504 STARLIGHT DR',
        RegAddrCity: 'LIBERAL',
        RegAddrState: 'KS',
        RegAddrZip: 67901,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2160 ZINNIA LN',
        RegAddrCity: 'LIBERAL',
        RegAddrState: 'KS',
        RegAddrZip: 67901,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '319 E YAUGER ST',
        RegAddrCity: 'LINCOLN',
        RegAddrState: 'KS',
        RegAddrZip: 67455,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '847 N HIGHWAY 14',
        RegAddrCity: 'LINCOLN',
        RegAddrState: 'KS',
        RegAddrZip: 67455,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '321 N CHESTNUT ST',
        RegAddrCity: 'LINDSBORG',
        RegAddrState: 'KS',
        RegAddrZip: 67456,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '426 N ROOSEVELT ST',
        RegAddrCity: 'LINDSBORG',
        RegAddrState: 'KS',
        RegAddrZip: 67456,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '553 OSAGE RD',
        RegAddrCity: 'LINN',
        RegAddrState: 'KS',
        RegAddrZip: 66953,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '610 W CHURCH ST',
        RegAddrCity: 'LOGAN',
        RegAddrState: 'KS',
        RegAddrZip: 67646,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1717 LEAVENWORTH ST',
        RegAddrCity: 'MANHATTAN',
        RegAddrState: 'KS',
        RegAddrZip: 66502,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '3218 VALLEYWOOD DR',
        RegAddrCity: 'MANHATTAN',
        RegAddrState: 'KS',
        RegAddrZip: 66502,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '714 LEE ST',
        RegAddrCity: 'MANHATTAN',
        RegAddrState: 'KS',
        RegAddrZip: 66502,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '3385 DEMPSEY RD',
        RegAddrCity: 'MANHATTAN',
        RegAddrState: 'KS',
        RegAddrZip: 66502,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1101 WALTERS DR',
        RegAddrCity: 'MANHATTAN',
        RegAddrState: 'KS',
        RegAddrZip: 66502,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '3009 SUNNYSIDE DR',
        RegAddrCity: 'MANHATTAN',
        RegAddrState: 'KS',
        RegAddrZip: 66502,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '5739 GRANNAGAN PT',
        RegAddrCity: 'MANHATTAN',
        RegAddrState: 'KS',
        RegAddrZip: 66502,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '405 S JULIETTE AVE',
        RegAddrCity: 'MANHATTAN',
        RegAddrState: 'KS',
        RegAddrZip: 66502,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2316 WILLOW LN',
        RegAddrCity: 'MANHATTAN',
        RegAddrState: 'KS',
        RegAddrZip: 66502,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '704 GILLESPIE DR',
        RegAddrCity: 'MANHATTAN',
        RegAddrState: 'KS',
        RegAddrZip: 66502,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '8393 FROGG HOLLER',
        RegAddrCity: 'MANHATTAN',
        RegAddrState: 'KS',
        RegAddrZip: 66503,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '5104 GRAND VISTA CT',
        RegAddrCity: 'MANHATTAN',
        RegAddrState: 'KS',
        RegAddrZip: 66503,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '10221 K4 HWY',
        RegAddrCity: 'MAPLE HILL',
        RegAddrState: 'KS',
        RegAddrZip: 66507,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '327 W SANTA FE',
        RegAddrCity: 'MARION',
        RegAddrState: 'KS',
        RegAddrZip: 66861,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '936 SYCAMORE PL',
        RegAddrCity: 'MCPHERSON',
        RegAddrState: 'KS',
        RegAddrZip: 67460,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '904 TURKEY CREEK DR',
        RegAddrCity: 'MCPHERSON',
        RegAddrState: 'KS',
        RegAddrZip: 67460,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '520 ESHELMAN ST',
        RegAddrCity: 'MCPHERSON',
        RegAddrState: 'KS',
        RegAddrZip: 67460,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '835 N BARBER ST',
        RegAddrCity: 'MCPHERSON',
        RegAddrState: 'KS',
        RegAddrZip: 67460,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '902 CLUBHOUSE DR',
        RegAddrCity: 'MCPHERSON',
        RegAddrState: 'KS',
        RegAddrZip: 67460,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '735 N OTTAWA ST',
        RegAddrCity: 'MINNEAPOLIS',
        RegAddrState: 'KS',
        RegAddrZip: 67467,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '310 E 2ND ST',
        RegAddrCity: 'MINNEAPOLIS',
        RegAddrState: 'KS',
        RegAddrZip: 67467,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1662 HOMESTEAD RD',
        RegAddrCity: 'MINNEAPOLIS',
        RegAddrState: 'KS',
        RegAddrZip: 67467,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '501 PARK CT',
        RegAddrCity: 'MOUNDRIDGE',
        RegAddrState: 'KS',
        RegAddrZip: 67107,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2253 ROAD 150',
        RegAddrCity: 'NEOSHO RAPIDS',
        RegAddrState: 'KS',
        RegAddrZip: 66864,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2204 N CUNNINGHAM RD',
        RegAddrCity: 'NEW CAMBRIA',
        RegAddrState: 'KS',
        RegAddrZip: 67470,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1861 COUNTY ROAD BB',
        RegAddrCity: 'OAKLEY',
        RegAddrState: 'KS',
        RegAddrZip: 67748,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '215 W 5TH ST',
        RegAddrCity: 'OAKLEY',
        RegAddrState: 'KS',
        RegAddrZip: 67748,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '601 HUDSON AVE',
        RegAddrCity: 'OAKLEY',
        RegAddrState: 'KS',
        RegAddrZip: 67748,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '201 ELM ST',
        RegAddrCity: 'OGDEN',
        RegAddrState: 'KS',
        RegAddrZip: 66517,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '16673 LONE TREE RD',
        RegAddrCity: 'ONAGA',
        RegAddrState: 'KS',
        RegAddrZip: 66521,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '207 W 1ST ST',
        RegAddrCity: 'PALMER',
        RegAddrState: 'KS',
        RegAddrZip: 66962,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1509 120TH',
        RegAddrCity: 'PEABODY',
        RegAddrState: 'KS',
        RegAddrZip: 66866,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1156 2ND ST',
        RegAddrCity: 'PHILLIPSBURG',
        RegAddrState: 'KS',
        RegAddrZip: 67661,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '106 W OAK ST',
        RegAddrCity: 'PLAINS',
        RegAddrState: 'KS',
        RegAddrZip: 67869,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '503 S SECTION LINE ST',
        RegAddrCity: 'PLAINVILLE',
        RegAddrState: 'KS',
        RegAddrZip: 67663,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '302 W 4TH ST',
        RegAddrCity: 'PORTIS',
        RegAddrState: 'KS',
        RegAddrZip: 67474,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '',
        RegAddrCity: 'Rush Center',
        RegAddrState: 'KS',
        RegAddrZip: 67575,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '833 W DORRANCE ST',
        RegAddrCity: 'RUSSELL',
        RegAddrState: 'KS',
        RegAddrZip: 67665,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '805 W LOCUST ST',
        RegAddrCity: 'SAINT MARYS',
        RegAddrState: 'KS',
        RegAddrZip: 66536,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '616 W PRESCOTT AVE',
        RegAddrCity: 'SALINA',
        RegAddrState: 'KS',
        RegAddrZip: 67401,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2053 ROACH ST',
        RegAddrCity: 'SALINA',
        RegAddrState: 'KS',
        RegAddrZip: 67401,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1104 N 10TH ST',
        RegAddrCity: 'SALINA',
        RegAddrState: 'KS',
        RegAddrZip: 67401,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2221 APPLEWOOD LN',
        RegAddrCity: 'SALINA',
        RegAddrState: 'KS',
        RegAddrZip: 67401,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1319 SIERRA DR',
        RegAddrCity: 'SALINA',
        RegAddrState: 'KS',
        RegAddrZip: 67401,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '500 W ELLSWORTH AVE',
        RegAddrCity: 'SALINA',
        RegAddrState: 'KS',
        RegAddrZip: 67401,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1813 BLUE STEM LN',
        RegAddrCity: 'SALINA',
        RegAddrState: 'KS',
        RegAddrZip: 67401,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '322 N 11TH ST',
        RegAddrCity: 'SALINA',
        RegAddrState: 'KS',
        RegAddrZip: 67401,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '834 MANOR RD',
        RegAddrCity: 'SALINA',
        RegAddrState: 'KS',
        RegAddrZip: 67401,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '122 S COLLEGE AVE',
        RegAddrCity: 'SALINA',
        RegAddrState: 'KS',
        RegAddrZip: 67401,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '228 N KANSAS AVE',
        RegAddrCity: 'SALINA',
        RegAddrState: 'KS',
        RegAddrZip: 67401,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1857 ROAD HH',
        RegAddrCity: 'SATANTA',
        RegAddrState: 'KS',
        RegAddrZip: 67870,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '14570 N PAWNEE RD',
        RegAddrCity: 'SCOTT CITY',
        RegAddrState: 'KS',
        RegAddrZip: 67871,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1205 HILLSIDE DR',
        RegAddrCity: 'SCOTT CITY',
        RegAddrState: 'KS',
        RegAddrZip: 67871,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '400 S DELIVAN ST',
        RegAddrCity: 'SIMPSON',
        RegAddrState: 'KS',
        RegAddrZip: 67478,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '401 W PALMER ST',
        RegAddrCity: 'ST MARYS',
        RegAddrState: 'KS',
        RegAddrZip: 66536,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2170 AVENUE U',
        RegAddrCity: 'STERLING',
        RegAddrState: 'KS',
        RegAddrZip: 67579,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '803 S CEDAR ST',
        RegAddrCity: 'STOCKTON',
        RegAddrState: 'KS',
        RegAddrZip: 67669,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '301 N ROOSEVELT ST',
        RegAddrCity: 'SYRACUSE',
        RegAddrState: 'KS',
        RegAddrZip: 67878,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '813 ULYSSES PKWY',
        RegAddrCity: 'ULYSSES',
        RegAddrState: 'KS',
        RegAddrZip: 67880,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '118 E SANTA FE AVE',
        RegAddrCity: 'ULYSSES',
        RegAddrState: 'KS',
        RegAddrZip: 67880,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '902 LAKESIDE DR',
        RegAddrCity: 'WAKEFIELD',
        RegAddrState: 'KS',
        RegAddrZip: 67487,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '212 ASH ST',
        RegAddrCity: 'WAMEGO',
        RegAddrState: 'KS',
        RegAddrZip: 66547,
        CongressionalDistrict: 1,
      },
    ],
  },
  {
    name: 'KS-2',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/KS-2?ref=gh-pages',
    state: 'KS',
    state_and_number: 'KS-2',
    zip: 66002,
    congressionalDistrict: 2,
    addressesInDistrict: [
      {
        RegAddrLine1: '415 DAVIDSON ST',
        RegAddrCity: 'ALTAMONT',
        RegAddrState: 'KS',
        RegAddrZip: 67330,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '18651 242ND RD',
        RegAddrCity: 'ATCHISON',
        RegAddrState: 'KS',
        RegAddrZip: 66002,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1302 GRANDEUR RD',
        RegAddrCity: 'ATCHISON',
        RegAddrState: 'KS',
        RegAddrZip: 66002,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1602 SANTA FE ST',
        RegAddrCity: 'ATCHISON',
        RegAddrState: 'KS',
        RegAddrZip: 66002,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '702 5TH ST',
        RegAddrCity: 'BALDWIN CITY',
        RegAddrState: 'KS',
        RegAddrZip: 66006,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '209 CHAPEL ST',
        RegAddrCity: 'BALDWIN CITY',
        RegAddrState: 'KS',
        RegAddrZip: 66006,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '415 CHAPEL ST',
        RegAddrCity: 'BALDWIN CITY',
        RegAddrState: 'KS',
        RegAddrZip: 66006,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1734 N 600 RD',
        RegAddrCity: 'BALDWIN CITY',
        RegAddrState: 'KS',
        RegAddrZip: 66006,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2955 N 155TH ST',
        RegAddrCity: 'BASEHOR',
        RegAddrState: 'KS',
        RegAddrZip: 66007,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2940 N 154TH TER',
        RegAddrCity: 'BASEHOR',
        RegAddrState: 'KS',
        RegAddrZip: 66007,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2408 LINCOLN DR',
        RegAddrCity: 'BAXTER SPGS',
        RegAddrState: 'KS',
        RegAddrZip: 66713,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1622 CHEROKEE AVE',
        RegAddrCity: 'BAXTER SPRINGS',
        RegAddrState: 'KS',
        RegAddrZip: 66713,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '226 E SANTA FE AVE',
        RegAddrCity: 'BURLINGAME',
        RegAddrState: 'KS',
        RegAddrZip: 66413,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '515 KENNEDY ST',
        RegAddrCity: 'BURLINGTON',
        RegAddrState: 'KS',
        RegAddrZip: 66839,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '907 N STATE ST',
        RegAddrCity: 'CANEY',
        RegAddrState: 'KS',
        RegAddrZip: 67333,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '106 E 1ST AVE',
        RegAddrCity: 'CANEY',
        RegAddrState: 'KS',
        RegAddrZip: 67333,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '486 24TH RD',
        RegAddrCity: 'CENTRALIA',
        RegAddrState: 'KS',
        RegAddrZip: 66415,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '624 S HIGHLAND AVE',
        RegAddrCity: 'CHANUTE',
        RegAddrState: 'KS',
        RegAddrZip: 66720,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '223 N WASHINGTON AVE',
        RegAddrCity: 'CHANUTE',
        RegAddrState: 'KS',
        RegAddrZip: 66720,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '536 N EVERGREEN AVE',
        RegAddrCity: 'CHANUTE',
        RegAddrState: 'KS',
        RegAddrZip: 66720,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '6225 COUNTY ROAD 5700',
        RegAddrCity: 'CHERRYVALE',
        RegAddrState: 'KS',
        RegAddrZip: 67335,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '4336 COUNTY ROAD 1400',
        RegAddrCity: 'COFFEYVILLE',
        RegAddrState: 'KS',
        RegAddrZip: 67337,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '812 OVERLOOK DR',
        RegAddrCity: 'COFFEYVILLE',
        RegAddrState: 'KS',
        RegAddrZip: 67337,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '814 W NEW ST',
        RegAddrCity: 'COFFEYVILLE',
        RegAddrState: 'KS',
        RegAddrZip: 67337,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '34725 255TH ST',
        RegAddrCity: 'EASTON',
        RegAddrState: 'KS',
        RegAddrZip: 66020,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '206 E 2ND ST',
        RegAddrCity: 'ERIE',
        RegAddrState: 'KS',
        RegAddrZip: 66733,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '13882 HIGHWAY 59',
        RegAddrCity: 'ERIE',
        RegAddrState: 'KS',
        RegAddrZip: 66733,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '814 E 12TH ST',
        RegAddrCity: 'EUDORA',
        RegAddrState: 'KS',
        RegAddrZip: 66025,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '40579 HEDGE LN',
        RegAddrCity: 'FONTANA',
        RegAddrState: 'KS',
        RegAddrZip: 66026,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '802 W 18TH ST',
        RegAddrCity: 'FORT SCOTT',
        RegAddrState: 'KS',
        RegAddrZip: 66701,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1962 INDIAN RD',
        RegAddrCity: 'FORT SCOTT',
        RegAddrState: 'KS',
        RegAddrZip: 66701,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '902 N 12TH ST',
        RegAddrCity: 'FREDONIA',
        RegAddrState: 'KS',
        RegAddrZip: 66736,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '206 N CHEROKEE ST',
        RegAddrCity: 'FRONTENAC',
        RegAddrState: 'KS',
        RegAddrZip: 66763,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '523 TERRY DR',
        RegAddrCity: 'FRONTENAC',
        RegAddrState: 'KS',
        RegAddrZip: 66763,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1134 THOMAS RD',
        RegAddrCity: 'FULTON',
        RegAddrState: 'KS',
        RegAddrZip: 66738,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '7760 SE CLEM RD',
        RegAddrCity: 'GALENA',
        RegAddrState: 'KS',
        RegAddrZip: 66739,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '802 N COLUMBUS ST',
        RegAddrCity: 'GALENA',
        RegAddrState: 'KS',
        RegAddrZip: 66739,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '6886 SE LOSTINE RD',
        RegAddrCity: 'GALENA',
        RegAddrState: 'KS',
        RegAddrZip: 66739,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '441 E 4TH AVE',
        RegAddrCity: 'GARNETT',
        RegAddrState: 'KS',
        RegAddrZip: 66032,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '104 PARK PLZ N',
        RegAddrCity: 'GARNETT',
        RegAddrState: 'KS',
        RegAddrZip: 66032,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '19871 NW 1700TH RD',
        RegAddrCity: 'GARNETT',
        RegAddrState: 'KS',
        RegAddrZip: 66032,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '630 S HUMPHREY',
        RegAddrCity: 'GAS',
        RegAddrState: 'KS',
        RegAddrZip: 66742,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '504 W WILSON ST',
        RegAddrCity: 'GIRARD',
        RegAddrState: 'KS',
        RegAddrZip: 66743,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2458 ALL AMERICAN RD',
        RegAddrCity: 'HANOVER',
        RegAddrState: 'KS',
        RegAddrZip: 66945,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '211 N 12TH ST',
        RegAddrCity: 'HIAWATHA',
        RegAddrState: 'KS',
        RegAddrZip: 66434,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '602 MAIN ST',
        RegAddrCity: 'HIGHLAND',
        RegAddrState: 'KS',
        RegAddrZip: 66035,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '416 W 6TH ST',
        RegAddrCity: 'HOLTON',
        RegAddrState: 'KS',
        RegAddrZip: 66436,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '230 W 15TH ST',
        RegAddrCity: 'HORTON',
        RegAddrState: 'KS',
        RegAddrZip: 66439,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1410 FLORIDA RD',
        RegAddrCity: 'HUMBOLDT',
        RegAddrState: 'KS',
        RegAddrZip: 66748,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1008 N 10TH ST',
        RegAddrCity: 'INDEPENDENCE',
        RegAddrState: 'KS',
        RegAddrZip: 67301,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '601 N 5TH ST',
        RegAddrCity: 'INDEPENDENCE',
        RegAddrState: 'KS',
        RegAddrZip: 67301,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '221 N COLBORN ST',
        RegAddrCity: 'IOLA',
        RegAddrState: 'KS',
        RegAddrZip: 66749,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2713 S 72ND DR',
        RegAddrCity: 'KANSAS CITY',
        RegAddrState: 'KS',
        RegAddrZip: 66106,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '812 N 81ST TER',
        RegAddrCity: 'KANSAS CITY',
        RegAddrState: 'KS',
        RegAddrZip: 66112,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '32452 SE 200TH RD',
        RegAddrCity: 'KINCAID',
        RegAddrState: 'KS',
        RegAddrZip: 66039,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '101 COMMERCIAL ST',
        RegAddrCity: 'LANCASTER',
        RegAddrState: 'KS',
        RegAddrZip: 66041,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '802 BLUEBERRY CT',
        RegAddrCity: 'LANSING',
        RegAddrState: 'KS',
        RegAddrZip: 66043,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '639 WILLOW ST',
        RegAddrCity: 'LANSING',
        RegAddrState: 'KS',
        RegAddrZip: 66043,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1345 LOUISIANA ST',
        RegAddrCity: 'LAWRENCE',
        RegAddrState: 'KS',
        RegAddrZip: 66044,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1910 STRATFORD RD',
        RegAddrCity: 'LAWRENCE',
        RegAddrState: 'KS',
        RegAddrZip: 66044,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1503 LINDENWOOD LN',
        RegAddrCity: 'LAWRENCE',
        RegAddrState: 'KS',
        RegAddrZip: 66044,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2124 MASSACHUSETTS ST',
        RegAddrCity: 'LAWRENCE',
        RegAddrState: 'KS',
        RegAddrZip: 66046,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2011 KENTUCKY ST',
        RegAddrCity: 'LAWRENCE',
        RegAddrState: 'KS',
        RegAddrZip: 66046,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2601 MAYFAIR DR',
        RegAddrCity: 'LAWRENCE',
        RegAddrState: 'KS',
        RegAddrZip: 66046,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2712 W 27TH TER',
        RegAddrCity: 'LAWRENCE',
        RegAddrState: 'KS',
        RegAddrZip: 66047,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '3021 RIMROCK DR',
        RegAddrCity: 'LAWRENCE',
        RegAddrState: 'KS',
        RegAddrZip: 66047,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '4705 RANCH CT',
        RegAddrCity: 'LAWRENCE',
        RegAddrState: 'KS',
        RegAddrZip: 66047,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1513 BURNING TREE CT',
        RegAddrCity: 'LAWRENCE',
        RegAddrState: 'KS',
        RegAddrZip: 66047,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '225 SHARON DR',
        RegAddrCity: 'LAWRENCE',
        RegAddrState: 'KS',
        RegAddrZip: 66049,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '731 COVING CT',
        RegAddrCity: 'LAWRENCE',
        RegAddrState: 'KS',
        RegAddrZip: 66049,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2208 WESTCHESTER RD',
        RegAddrCity: 'LAWRENCE',
        RegAddrState: 'KS',
        RegAddrZip: 66049,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '15382 DEMPSEY RD',
        RegAddrCity: 'LEAVENWORTH',
        RegAddrState: 'KS',
        RegAddrZip: 66048,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1905 THORNTON ST',
        RegAddrCity: 'LEAVENWORTH',
        RegAddrState: 'KS',
        RegAddrZip: 66048,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '16858 DEMPSEY RD',
        RegAddrCity: 'LEAVENWORTH',
        RegAddrState: 'KS',
        RegAddrZip: 66048,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1309 LIMIT ST',
        RegAddrCity: 'LEAVENWORTH',
        RegAddrState: 'KS',
        RegAddrZip: 66048,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1602 DAKOTA ST',
        RegAddrCity: 'LEAVENWORTH',
        RegAddrState: 'KS',
        RegAddrZip: 66048,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '23088 148TH ST',
        RegAddrCity: 'LEAVENWORTH',
        RegAddrState: 'KS',
        RegAddrZip: 66048,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '35194 187TH ST',
        RegAddrCity: 'LEAVENWORTH',
        RegAddrState: 'KS',
        RegAddrZip: 66048,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1948 E 175TH RD',
        RegAddrCity: 'LECOMPTON',
        RegAddrState: 'KS',
        RegAddrZip: 66050,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '5320 CR 3600',
        RegAddrCity: 'LIBERTY',
        RegAddrState: 'KS',
        RegAddrZip: 67351,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '230 W 8TH ST',
        RegAddrCity: 'LYNDON',
        RegAddrState: 'KS',
        RegAddrZip: 66451,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '15705 158TH RD',
        RegAddrCity: 'MAYETTA',
        RegAddrState: 'KS',
        RegAddrZip: 66509,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '6855 150TH RD',
        RegAddrCity: 'MAYETTA',
        RegAddrState: 'KS',
        RegAddrZip: 66509,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '9775 JUPI CT',
        RegAddrCity: 'MERIDEN',
        RegAddrState: 'KS',
        RegAddrZip: 66512,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '5968 86TH ST',
        RegAddrCity: 'MERIDEN',
        RegAddrState: 'KS',
        RegAddrZip: 66512,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '6460 HICKORY PT',
        RegAddrCity: 'MERIDEN',
        RegAddrState: 'KS',
        RegAddrZip: 66512,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '18244 KS HIGHWAY 52',
        RegAddrCity: 'MOUND CITY',
        RegAddrState: 'KS',
        RegAddrZip: 66056,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '110 W 3RD ST',
        RegAddrCity: 'MOUND VALLEY',
        RegAddrState: 'KS',
        RegAddrZip: 67354,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '610 N 8TH ST',
        RegAddrCity: 'NEODESHA',
        RegAddrState: 'KS',
        RegAddrZip: 66757,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '312 S 4TH ST',
        RegAddrCity: 'OSAGE CITY',
        RegAddrState: 'KS',
        RegAddrZip: 66523,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '206 MILL ST',
        RegAddrCity: 'OSAWATOMIE',
        RegAddrState: 'KS',
        RegAddrZip: 66064,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '819 CHEROKEE ST',
        RegAddrCity: 'OSKALOOSA',
        RegAddrState: 'KS',
        RegAddrZip: 66066,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1003 W 5TH ST',
        RegAddrCity: 'OTTAWA',
        RegAddrState: 'KS',
        RegAddrZip: 66067,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1722 S MAPLE ST',
        RegAddrCity: 'OTTAWA',
        RegAddrState: 'KS',
        RegAddrZip: 66067,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '501 ASSEMBLY LN',
        RegAddrCity: 'PAOLA',
        RegAddrState: 'KS',
        RegAddrZip: 66071,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '713 S 33RD ST',
        RegAddrCity: 'PARSONS',
        RegAddrState: 'KS',
        RegAddrZip: 67357,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1900 BELMONT AVE',
        RegAddrCity: 'PARSONS',
        RegAddrState: 'KS',
        RegAddrZip: 67357,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '220 N 26TH ST',
        RegAddrCity: 'PARSONS',
        RegAddrState: 'KS',
        RegAddrZip: 67357,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '11248 NE CENTER STAR RD',
        RegAddrCity: 'PITTSBURG',
        RegAddrState: 'KS',
        RegAddrZip: 66762,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '803 W 3RD ST',
        RegAddrCity: 'PITTSBURG',
        RegAddrState: 'KS',
        RegAddrZip: 66762,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1121 E 160TH HWY',
        RegAddrCity: 'PITTSBURG',
        RegAddrState: 'KS',
        RegAddrZip: 66762,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '208 CRESTWOOD LN',
        RegAddrCity: 'PITTSBURG',
        RegAddrState: 'KS',
        RegAddrZip: 66762,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2704 E 12TH ST',
        RegAddrCity: 'PITTSBURG',
        RegAddrState: 'KS',
        RegAddrZip: 66762,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '8 DEER RUN LN',
        RegAddrCity: 'PITTSBURG',
        RegAddrState: 'KS',
        RegAddrZip: 66762,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '211 N SPRUCE',
        RegAddrCity: 'REDFIELD',
        RegAddrState: 'KS',
        RegAddrZip: 66769,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1024 S 12TH ST',
        RegAddrCity: 'SABETHA',
        RegAddrState: 'KS',
        RegAddrZip: 66534,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2236 T RD',
        RegAddrCity: 'SABETHA',
        RegAddrState: 'KS',
        RegAddrZip: 66534,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '5725 SE SHAWNEE HEIGHTS RD',
        RegAddrCity: 'TECUMSEH',
        RegAddrState: 'KS',
        RegAddrZip: 66542,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2109 WILLOW BEND DR',
        RegAddrCity: 'TONGANOXIE',
        RegAddrState: 'KS',
        RegAddrZip: 66086,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1195 SW BOSWELL AVE',
        RegAddrCity: 'TOPEKA',
        RegAddrState: 'KS',
        RegAddrZip: 66604,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1188 SW WOODWARD AVE',
        RegAddrCity: 'TOPEKA',
        RegAddrState: 'KS',
        RegAddrZip: 66604,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '3631 SE TOMAHAWK TRL',
        RegAddrCity: 'TOPEKA',
        RegAddrState: 'KS',
        RegAddrZip: 66605,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2233 SE JEFFERSON ST',
        RegAddrCity: 'TOPEKA',
        RegAddrState: 'KS',
        RegAddrZip: 66605,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1029 SE PINECREST DR',
        RegAddrCity: 'TOPEKA',
        RegAddrState: 'KS',
        RegAddrZip: 66605,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2632 SE CROCO RD',
        RegAddrCity: 'TOPEKA',
        RegAddrState: 'KS',
        RegAddrZip: 66605,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '3468 SE CRESTWATER DR',
        RegAddrCity: 'TOPEKA',
        RegAddrState: 'KS',
        RegAddrZip: 66605,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '922 SW WARREN AVE',
        RegAddrCity: 'TOPEKA',
        RegAddrState: 'KS',
        RegAddrZip: 66606,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '729 SW JEWELL AVE',
        RegAddrCity: 'TOPEKA',
        RegAddrState: 'KS',
        RegAddrZip: 66606,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '5852 SW WOODBRIDGE DR',
        RegAddrCity: 'TOPEKA',
        RegAddrState: 'KS',
        RegAddrZip: 66606,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '118 NW KENDALL AVE',
        RegAddrCity: 'TOPEKA',
        RegAddrState: 'KS',
        RegAddrZip: 66606,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '311 SW BROADMOOR AVE',
        RegAddrCity: 'TOPEKA',
        RegAddrState: 'KS',
        RegAddrZip: 66606,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1209 SE LOCUST ST',
        RegAddrCity: 'TOPEKA',
        RegAddrState: 'KS',
        RegAddrZip: 66607,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '400 SE RODGERS ST',
        RegAddrCity: 'TOPEKA',
        RegAddrState: 'KS',
        RegAddrZip: 66607,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '903 SE 42ND TER',
        RegAddrCity: 'TOPEKA',
        RegAddrState: 'KS',
        RegAddrZip: 66609,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '4625 SE CALIFORNIA AVE',
        RegAddrCity: 'TOPEKA',
        RegAddrState: 'KS',
        RegAddrZip: 66609,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '211 SE 48TH ST',
        RegAddrCity: 'TOPEKA',
        RegAddrState: 'KS',
        RegAddrZip: 66609,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '4346 SE CHISOLM RD',
        RegAddrCity: 'TOPEKA',
        RegAddrState: 'KS',
        RegAddrZip: 66609,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '5024 SW AUBURN RD',
        RegAddrCity: 'TOPEKA',
        RegAddrState: 'KS',
        RegAddrZip: 66610,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '6000 SW URISH RD',
        RegAddrCity: 'TOPEKA',
        RegAddrState: 'KS',
        RegAddrZip: 66610,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2401 SW PEPPERWOOD CIR',
        RegAddrCity: 'TOPEKA',
        RegAddrState: 'KS',
        RegAddrZip: 66614,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '5648 SW 36TH ST',
        RegAddrCity: 'TOPEKA',
        RegAddrState: 'KS',
        RegAddrZip: 66614,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '3115 SW TUTBURY TOWN RD',
        RegAddrCity: 'TOPEKA',
        RegAddrState: 'KS',
        RegAddrZip: 66614,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '6027 SW 4TH ST',
        RegAddrCity: 'TOPEKA',
        RegAddrState: 'KS',
        RegAddrZip: 66615,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '226 NE 58TH ST',
        RegAddrCity: 'TOPEKA',
        RegAddrState: 'KS',
        RegAddrZip: 66617,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '525 NE 43RD ST',
        RegAddrCity: 'TOPEKA',
        RegAddrState: 'KS',
        RegAddrZip: 66617,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '3805 NW ERIC DR',
        RegAddrCity: 'TOPEKA',
        RegAddrState: 'KS',
        RegAddrZip: 66618,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '4806 NW REDWOOD DR',
        RegAddrCity: 'TOPEKA',
        RegAddrState: 'KS',
        RegAddrZip: 66618,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '6850 NW 54TH ST',
        RegAddrCity: 'TOPEKA',
        RegAddrState: 'KS',
        RegAddrZip: 66618,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '6308 NW KELSHAR DR',
        RegAddrCity: 'TOPEKA',
        RegAddrState: 'KS',
        RegAddrZip: 66618,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '9150 SW WANAMAKER RD',
        RegAddrCity: 'WAKARUSA',
        RegAddrState: 'KS',
        RegAddrZip: 66546,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '239 W ELM ST',
        RegAddrCity: 'WATERVILLE',
        RegAddrState: 'KS',
        RegAddrZip: 66548,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '111 SEWARD ST',
        RegAddrCity: 'WATHENA',
        RegAddrState: 'KS',
        RegAddrZip: 66090,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '804 DANE ST',
        RegAddrCity: 'WAVERLY',
        RegAddrState: 'KS',
        RegAddrZip: 66871,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '620 POPLAR ST',
        RegAddrCity: 'WELLSVILLE',
        RegAddrState: 'KS',
        RegAddrZip: 66092,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '208 2ND ST',
        RegAddrCity: 'WETMORE',
        RegAddrState: 'KS',
        RegAddrZip: 66550,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '107 N FIRST ST',
        RegAddrCity: 'WILLIAMSBURG',
        RegAddrState: 'KS',
        RegAddrZip: 66095,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '603 OSAGE ST',
        RegAddrCity: 'WINCHESTER',
        RegAddrState: 'KS',
        RegAddrZip: 66097,
        CongressionalDistrict: 2,
      },
    ],
  },
  {
    name: 'KS-3',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/KS-3?ref=gh-pages',
    state: 'KS',
    state_and_number: 'KS-3',
    zip: 66013,
    congressionalDistrict: 3,
    addressesInDistrict: [
      {
        RegAddrLine1: '27 LANE R',
        RegAddrCity: 'DE SOTO',
        RegAddrState: 'KS',
        RegAddrZip: 66018,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '30541 EXPLORERS TRL',
        RegAddrCity: 'DE SOTO',
        RegAddrState: 'KS',
        RegAddrZip: 66018,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '104 E MERIWOOD LN',
        RegAddrCity: 'EDGERTON',
        RegAddrState: 'KS',
        RegAddrZip: 66021,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1810 S 98TH ST',
        RegAddrCity: 'EDWARDSVILLE',
        RegAddrState: 'KS',
        RegAddrZip: 66111,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '14887 FOUR CORNERS RD',
        RegAddrCity: 'GARDNER',
        RegAddrState: 'KS',
        RegAddrZip: 66030,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '969 E COTTAGE CREEK DR',
        RegAddrCity: 'GARDNER',
        RegAddrState: 'KS',
        RegAddrZip: 66030,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '674 S SUMAC ST',
        RegAddrCity: 'GARDNER',
        RegAddrState: 'KS',
        RegAddrZip: 66030,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '926 TENNY AVE',
        RegAddrCity: 'KANSAS CITY',
        RegAddrState: 'KS',
        RegAddrZip: 66101,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1219 N 29TH ST',
        RegAddrCity: 'KANSAS CITY',
        RegAddrState: 'KS',
        RegAddrZip: 66102,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1128 ELLA AVE',
        RegAddrCity: 'KANSAS CITY',
        RegAddrState: 'KS',
        RegAddrZip: 66102,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '223 S 10TH ST',
        RegAddrCity: 'KANSAS CITY',
        RegAddrState: 'KS',
        RegAddrZip: 66102,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '4326 CATHERINE DR',
        RegAddrCity: 'KANSAS CITY',
        RegAddrState: 'KS',
        RegAddrZip: 66102,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '3907 WASHINGTON AVE',
        RegAddrCity: 'KANSAS CITY',
        RegAddrState: 'KS',
        RegAddrZip: 66102,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '4045 THOMPSON ST',
        RegAddrCity: 'KANSAS CITY',
        RegAddrState: 'KS',
        RegAddrZip: 66103,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1308 LAWTON LN',
        RegAddrCity: 'KANSAS CITY',
        RegAddrState: 'KS',
        RegAddrZip: 66103,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '2413 S EARLY ST',
        RegAddrCity: 'KANSAS CITY',
        RegAddrState: 'KS',
        RegAddrZip: 66103,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '3101 S 7TH ST',
        RegAddrCity: 'KANSAS CITY',
        RegAddrState: 'KS',
        RegAddrZip: 66103,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '2726 N 59TH ST',
        RegAddrCity: 'KANSAS CITY',
        RegAddrState: 'KS',
        RegAddrZip: 66104,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '3288 CORONADO RD',
        RegAddrCity: 'KANSAS CITY',
        RegAddrState: 'KS',
        RegAddrZip: 66104,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '2709 S 49TH ST',
        RegAddrCity: 'KANSAS CITY',
        RegAddrState: 'KS',
        RegAddrZip: 66106,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1230 S 35TH ST',
        RegAddrCity: 'KANSAS CITY',
        RegAddrState: 'KS',
        RegAddrZip: 66106,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '3417 SILVER AVE',
        RegAddrCity: 'KANSAS CITY',
        RegAddrState: 'KS',
        RegAddrZip: 66106,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '2531 S 53RD ST',
        RegAddrCity: 'KANSAS CITY',
        RegAddrState: 'KS',
        RegAddrZip: 66106,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '6911 HASKELL AVE',
        RegAddrCity: 'KANSAS CITY',
        RegAddrState: 'KS',
        RegAddrZip: 66109,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '4809 N 125TH ST',
        RegAddrCity: 'KANSAS CITY',
        RegAddrState: 'KS',
        RegAddrZip: 66109,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1606 N 127TH ST',
        RegAddrCity: 'KANSAS CITY',
        RegAddrState: 'KS',
        RegAddrZip: 66109,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '7030 LEAVENWORTH RD',
        RegAddrCity: 'KANSAS CITY',
        RegAddrState: 'KS',
        RegAddrZip: 66109,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '2019 N 86TH TER',
        RegAddrCity: 'KANSAS CITY',
        RegAddrState: 'KS',
        RegAddrZip: 66109,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '12524 AUGUSTA DR',
        RegAddrCity: 'KANSAS CITY',
        RegAddrState: 'KS',
        RegAddrZip: 66109,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '4035 N 123RD TER',
        RegAddrCity: 'KANSAS CITY',
        RegAddrState: 'KS',
        RegAddrZip: 66109,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '7231 ASPEN DR',
        RegAddrCity: 'KANSAS CITY',
        RegAddrState: 'KS',
        RegAddrZip: 66111,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '300 S 72ND ST',
        RegAddrCity: 'KANSAS CITY',
        RegAddrState: 'KS',
        RegAddrZip: 66111,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '6756 MONTANA AVE',
        RegAddrCity: 'KANSAS CITY',
        RegAddrState: 'KS',
        RegAddrZip: 66111,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '371 TERRACE TRL W',
        RegAddrCity: 'LAKE QUIVIRA',
        RegAddrState: 'KS',
        RegAddrZip: 66217,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '550 IDAHO ST',
        RegAddrCity: 'LEAVENWORTH',
        RegAddrState: 'KS',
        RegAddrZip: 66048,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '2901 W 92ND ST',
        RegAddrCity: 'LEAWOOD',
        RegAddrState: 'KS',
        RegAddrZip: 66206,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '2337 W 97TH ST',
        RegAddrCity: 'LEAWOOD',
        RegAddrState: 'KS',
        RegAddrZip: 66206,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '2220 W 124TH ST',
        RegAddrCity: 'LEAWOOD',
        RegAddrState: 'KS',
        RegAddrZip: 66209,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '5008 W 112TH TER',
        RegAddrCity: 'LEAWOOD',
        RegAddrState: 'KS',
        RegAddrZip: 66211,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '5006 W 143RD TER',
        RegAddrCity: 'LEAWOOD',
        RegAddrState: 'KS',
        RegAddrZip: 66224,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '10914 HAUSER ST',
        RegAddrCity: 'LENEXA',
        RegAddrState: 'KS',
        RegAddrZip: 66210,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '12316 W 79TH TER',
        RegAddrCity: 'LENEXA',
        RegAddrState: 'KS',
        RegAddrZip: 66215,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '9032 DICE LN',
        RegAddrCity: 'LENEXA',
        RegAddrState: 'KS',
        RegAddrZip: 66215,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '14414 W 91ST ST',
        RegAddrCity: 'LENEXA',
        RegAddrState: 'KS',
        RegAddrZip: 66215,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '12613 W 101ST ST',
        RegAddrCity: 'LENEXA',
        RegAddrState: 'KS',
        RegAddrZip: 66215,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '15522 W 84TH TER',
        RegAddrCity: 'LENEXA',
        RegAddrState: 'KS',
        RegAddrZip: 66219,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '15100 W 83RD ST',
        RegAddrCity: 'LENEXA',
        RegAddrState: 'KS',
        RegAddrZip: 66219,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '20501 W 98TH ST',
        RegAddrCity: 'LENEXA',
        RegAddrState: 'KS',
        RegAddrZip: 66220,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '20824 W 92ND ST',
        RegAddrCity: 'LENEXA',
        RegAddrState: 'KS',
        RegAddrZip: 66220,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '9911 WOODSTOCK ST',
        RegAddrCity: 'LENEXA',
        RegAddrState: 'KS',
        RegAddrZip: 66220,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '27872 W 85TH TER',
        RegAddrCity: 'LENEXA',
        RegAddrState: 'KS',
        RegAddrZip: 66227,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '9769 W 303RD ST',
        RegAddrCity: 'LOUISBURG',
        RegAddrState: 'KS',
        RegAddrZip: 66053,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '25545 NEW LANCASTER RD',
        RegAddrCity: 'LOUISBURG',
        RegAddrState: 'KS',
        RegAddrZip: 66053,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '8014 W 61ST ST',
        RegAddrCity: 'MERRIAM',
        RegAddrState: 'KS',
        RegAddrZip: 66202,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '9511 W 54TH ST',
        RegAddrCity: 'MERRIAM',
        RegAddrState: 'KS',
        RegAddrZip: 66203,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '4725 SWITZER ST',
        RegAddrCity: 'MERRIAM',
        RegAddrState: 'KS',
        RegAddrZip: 66203,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '5522 MAPLE ST',
        RegAddrCity: 'MISSION',
        RegAddrState: 'KS',
        RegAddrZip: 66202,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '3216 W 68TH ST',
        RegAddrCity: 'MISSION HILLS',
        RegAddrState: 'KS',
        RegAddrZip: 66208,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1523 W ROMANY CT',
        RegAddrCity: 'OLATHE',
        RegAddrState: 'KS',
        RegAddrZip: 66061,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '11379 S COOK ST',
        RegAddrCity: 'OLATHE',
        RegAddrState: 'KS',
        RegAddrZip: 66061,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '944 N PINE ST',
        RegAddrCity: 'OLATHE',
        RegAddrState: 'KS',
        RegAddrZip: 66061,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1121 N PURDOM ST',
        RegAddrCity: 'OLATHE',
        RegAddrState: 'KS',
        RegAddrZip: 66061,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '20985 W 117TH TER',
        RegAddrCity: 'OLATHE',
        RegAddrState: 'KS',
        RegAddrZip: 66061,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '829 W FOREST DR',
        RegAddrCity: 'OLATHE',
        RegAddrState: 'KS',
        RegAddrZip: 66061,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '14953 S VIOLET ST',
        RegAddrCity: 'OLATHE',
        RegAddrState: 'KS',
        RegAddrZip: 66061,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1407 E 120TH ST',
        RegAddrCity: 'OLATHE',
        RegAddrState: 'KS',
        RegAddrZip: 66061,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '12496 S CREST CIR',
        RegAddrCity: 'OLATHE',
        RegAddrState: 'KS',
        RegAddrZip: 66061,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1420 S SIOUX DR',
        RegAddrCity: 'OLATHE',
        RegAddrState: 'KS',
        RegAddrZip: 66062,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '21200 W 181ST TER',
        RegAddrCity: 'OLATHE',
        RegAddrState: 'KS',
        RegAddrZip: 66062,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '14194 W 147TH TER',
        RegAddrCity: 'OLATHE',
        RegAddrState: 'KS',
        RegAddrZip: 66062,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '2138 E 152ND ST',
        RegAddrCity: 'OLATHE',
        RegAddrState: 'KS',
        RegAddrZip: 66062,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1318 E 152ND ST',
        RegAddrCity: 'OLATHE',
        RegAddrState: 'KS',
        RegAddrZip: 66062,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '13825 S KAW ST',
        RegAddrCity: 'OLATHE',
        RegAddrState: 'KS',
        RegAddrZip: 66062,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '16786 W 157TH ST',
        RegAddrCity: 'OLATHE',
        RegAddrState: 'KS',
        RegAddrZip: 66062,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1418 S KIOWA DR',
        RegAddrCity: 'OLATHE',
        RegAddrState: 'KS',
        RegAddrZip: 66062,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '16600 W 126TH ST',
        RegAddrCity: 'OLATHE',
        RegAddrState: 'KS',
        RegAddrZip: 66062,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '12872 S WIDMER ST',
        RegAddrCity: 'OLATHE',
        RegAddrState: 'KS',
        RegAddrZip: 66062,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '15222 S ALCAN ST',
        RegAddrCity: 'OLATHE',
        RegAddrState: 'KS',
        RegAddrZip: 66062,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '14009 S TOMAHAWK DR',
        RegAddrCity: 'OLATHE',
        RegAddrState: 'KS',
        RegAddrZip: 66062,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '16018 W 154TH TER',
        RegAddrCity: 'OLATHE',
        RegAddrState: 'KS',
        RegAddrZip: 66062,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '7337 WALMER ST',
        RegAddrCity: 'OVERLAND PARK',
        RegAddrState: 'KS',
        RegAddrZip: 66204,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '5425 W 100TH ST',
        RegAddrCity: 'OVERLAND PARK',
        RegAddrState: 'KS',
        RegAddrZip: 66207,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '9700 LINDEN ST',
        RegAddrCity: 'OVERLAND PARK',
        RegAddrState: 'KS',
        RegAddrZip: 66207,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '10000 DELMAR LN',
        RegAddrCity: 'OVERLAND PARK',
        RegAddrState: 'KS',
        RegAddrZip: 66207,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '10200 REEDS DR',
        RegAddrCity: 'OVERLAND PARK',
        RegAddrState: 'KS',
        RegAddrZip: 66207,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '7909 W 114TH ST',
        RegAddrCity: 'OVERLAND PARK',
        RegAddrState: 'KS',
        RegAddrZip: 66210,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '9315 W 113TH ST',
        RegAddrCity: 'OVERLAND PARK',
        RegAddrState: 'KS',
        RegAddrZip: 66210,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '9812 W 96TH TER',
        RegAddrCity: 'OVERLAND PARK',
        RegAddrState: 'KS',
        RegAddrZip: 66212,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '10044 HARDY DR',
        RegAddrCity: 'OVERLAND PARK',
        RegAddrState: 'KS',
        RegAddrZip: 66212,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '9012 W 93RD ST',
        RegAddrCity: 'OVERLAND PARK',
        RegAddrState: 'KS',
        RegAddrZip: 66212,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '7615 W 100TH PL',
        RegAddrCity: 'OVERLAND PARK',
        RegAddrState: 'KS',
        RegAddrZip: 66212,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '10709 W 131ST ST',
        RegAddrCity: 'OVERLAND PARK',
        RegAddrState: 'KS',
        RegAddrZip: 66213,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '12539 CONNELL DR',
        RegAddrCity: 'OVERLAND PARK',
        RegAddrState: 'KS',
        RegAddrZip: 66213,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '10808 W 107TH ST',
        RegAddrCity: 'OVERLAND PARK',
        RegAddrState: 'KS',
        RegAddrZip: 66214,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '11436 W 105TH TER',
        RegAddrCity: 'OVERLAND PARK',
        RegAddrState: 'KS',
        RegAddrZip: 66214,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '10566 BRADSHAW ST',
        RegAddrCity: 'OVERLAND PARK',
        RegAddrState: 'KS',
        RegAddrZip: 66215,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '15605 SLATER ST',
        RegAddrCity: 'OVERLAND PARK',
        RegAddrState: 'KS',
        RegAddrZip: 66221,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '11205 W 164TH ST',
        RegAddrCity: 'OVERLAND PARK',
        RegAddrState: 'KS',
        RegAddrZip: 66221,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '15346 MONROVIA ST',
        RegAddrCity: 'OVERLAND PARK',
        RegAddrState: 'KS',
        RegAddrZip: 66221,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '14409 GRANDVIEW ST',
        RegAddrCity: 'OVERLAND PARK',
        RegAddrState: 'KS',
        RegAddrZip: 66221,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '13828 HAUSER ST',
        RegAddrCity: 'OVERLAND PARK',
        RegAddrState: 'KS',
        RegAddrZip: 66221,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '12406 W 154TH TER',
        RegAddrCity: 'OVERLAND PARK',
        RegAddrState: 'KS',
        RegAddrZip: 66221,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '6208 W 155TH ST',
        RegAddrCity: 'OVERLAND PARK',
        RegAddrState: 'KS',
        RegAddrZip: 66223,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '5884 EDGEWATER DR',
        RegAddrCity: 'OVERLAND PARK',
        RegAddrState: 'KS',
        RegAddrZip: 66223,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '14322 WOODWARD ST',
        RegAddrCity: 'OVERLAND PARK',
        RegAddrState: 'KS',
        RegAddrZip: 66223,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '15628 BARKLEY ST',
        RegAddrCity: 'OVERLAND PARK',
        RegAddrState: 'KS',
        RegAddrZip: 66223,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '8600 W 153RD ST',
        RegAddrCity: 'OVERLAND PARK',
        RegAddrState: 'KS',
        RegAddrZip: 66223,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '15012 BROADMOOR ST',
        RegAddrCity: 'OVERLAND PARK',
        RegAddrState: 'KS',
        RegAddrZip: 66223,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '4101 W 90TH ST',
        RegAddrCity: 'PRAIRIE VLG',
        RegAddrState: 'KS',
        RegAddrZip: 66207,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '7735 FAIRWAY ST',
        RegAddrCity: 'PRAIRIE VLG',
        RegAddrState: 'KS',
        RegAddrZip: 66208,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '2227 W 74TH TER',
        RegAddrCity: 'PRAIRIE VLG',
        RegAddrState: 'KS',
        RegAddrZip: 66208,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '7050 LINDEN ST',
        RegAddrCity: 'PRAIRIE VLG',
        RegAddrState: 'KS',
        RegAddrZip: 66208,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '5308 SYCAMORE DR',
        RegAddrCity: 'ROELAND PARK',
        RegAddrState: 'KS',
        RegAddrZip: 66205,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '5203 PAWNEE DR',
        RegAddrCity: 'ROELAND PARK',
        RegAddrState: 'KS',
        RegAddrZip: 66205,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '4104 ELLEDGE DR',
        RegAddrCity: 'ROELAND PARK',
        RegAddrState: 'KS',
        RegAddrZip: 66205,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '4720 MOHAWK DR',
        RegAddrCity: 'ROELAND PARK',
        RegAddrState: 'KS',
        RegAddrZip: 66205,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '10502 W 75TH TER',
        RegAddrCity: 'SHAWNEE',
        RegAddrState: 'KS',
        RegAddrZip: 66214,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '6718 HAUSER DR',
        RegAddrCity: 'SHAWNEE',
        RegAddrState: 'KS',
        RegAddrZip: 66216,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '13237 W 54TH TER',
        RegAddrCity: 'SHAWNEE',
        RegAddrState: 'KS',
        RegAddrZip: 66216,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '6633 PARK ST',
        RegAddrCity: 'SHAWNEE',
        RegAddrState: 'KS',
        RegAddrZip: 66216,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '12409 W 68TH TER',
        RegAddrCity: 'SHAWNEE',
        RegAddrState: 'KS',
        RegAddrZip: 66216,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '5820 PARK CIR',
        RegAddrCity: 'SHAWNEE',
        RegAddrState: 'KS',
        RegAddrZip: 66216,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '6624 HALSEY ST',
        RegAddrCity: 'SHAWNEE',
        RegAddrState: 'KS',
        RegAddrZip: 66216,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '7219 WOODLAND DR',
        RegAddrCity: 'SHAWNEE',
        RegAddrState: 'KS',
        RegAddrZip: 66218,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '5609 PAYNE ST',
        RegAddrCity: 'SHAWNEE',
        RegAddrState: 'KS',
        RegAddrZip: 66226,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '4735 NOBLE ST',
        RegAddrCity: 'SHAWNEE',
        RegAddrState: 'KS',
        RegAddrZip: 66226,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '22605 W 72ND ST',
        RegAddrCity: 'SHAWNEE',
        RegAddrState: 'KS',
        RegAddrZip: 66227,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '22705 W 72ND ST',
        RegAddrCity: 'SHAWNEE',
        RegAddrState: 'KS',
        RegAddrZip: 66227,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '20953 W 226TH CT',
        RegAddrCity: 'SPRING HILL',
        RegAddrState: 'KS',
        RegAddrZip: 66083,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '18424 W 193RD TER',
        RegAddrCity: 'SPRING HILL',
        RegAddrState: 'KS',
        RegAddrZip: 66083,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '19022 LAKECREST ST',
        RegAddrCity: 'SPRING HILL',
        RegAddrState: 'KS',
        RegAddrZip: 66083,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '20639 W 219TH TER',
        RegAddrCity: 'SPRING HILL',
        RegAddrState: 'KS',
        RegAddrZip: 66083,
        CongressionalDistrict: 3,
      },
    ],
  },
  {
    name: 'KS-4',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/KS-4?ref=gh-pages',
    state: 'KS',
    state_and_number: 'KS-4',
    zip: 66842,
    congressionalDistrict: 4,
    addressesInDistrict: [
      {
        RegAddrLine1: '1300 N GLANCEY ST',
        RegAddrCity: 'ANDOVER',
        RegAddrState: 'KS',
        RegAddrZip: 67002,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '120 E LAFAYETTE ST',
        RegAddrCity: 'ANDOVER',
        RegAddrState: 'KS',
        RegAddrZip: 67002,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '709 E WOODSTONE CT',
        RegAddrCity: 'ANDOVER',
        RegAddrState: 'KS',
        RegAddrZip: 67002,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2321 N GILMORE DR',
        RegAddrCity: 'ANDOVER',
        RegAddrState: 'KS',
        RegAddrZip: 67002,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '320 N MAIN ST',
        RegAddrCity: 'ARGONIA',
        RegAddrState: 'KS',
        RegAddrZip: 67004,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '10775 284TH RD',
        RegAddrCity: 'ARKANSAS CITY',
        RegAddrState: 'KS',
        RegAddrZip: 67005,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '519 S 2ND ST',
        RegAddrCity: 'ARKANSAS CITY',
        RegAddrState: 'KS',
        RegAddrZip: 67005,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '418 E CEDAR AVE',
        RegAddrCity: 'ARKANSAS CITY',
        RegAddrState: 'KS',
        RegAddrZip: 67005,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '12435 SW SHUMWAY RD',
        RegAddrCity: 'AUGUSTA',
        RegAddrState: 'KS',
        RegAddrZip: 67010,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '729 E KELLY AVE',
        RegAddrCity: 'AUGUSTA',
        RegAddrState: 'KS',
        RegAddrZip: 67010,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '304 GREGG ST',
        RegAddrCity: 'AUGUSTA',
        RegAddrState: 'KS',
        RegAddrZip: 67010,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '4305 N EAGLE LAKE DR',
        RegAddrCity: 'BEL AIRE',
        RegAddrState: 'KS',
        RegAddrZip: 67220,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '5240 E 48TH ST N',
        RegAddrCity: 'BEL AIRE',
        RegAddrState: 'KS',
        RegAddrZip: 67220,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '6920 E ODESSA CT',
        RegAddrCity: 'BEL AIRE',
        RegAddrState: 'KS',
        RegAddrZip: 67226,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '939 260TH AVE',
        RegAddrCity: 'BELPRE',
        RegAddrState: 'KS',
        RegAddrZip: 67519,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '302 S RENO AVE',
        RegAddrCity: 'BURRTON',
        RegAddrState: 'KS',
        RegAddrZip: 67020,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '4715 NE SUNSET LN',
        RegAddrCity: 'CHENEY',
        RegAddrState: 'KS',
        RegAddrZip: 67025,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '818 STREAMSIDE LN',
        RegAddrCity: 'CLEARWATER',
        RegAddrState: 'KS',
        RegAddrZip: 67026,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '501 SOUTHEAST DR',
        RegAddrCity: 'CLEARWATER',
        RegAddrState: 'KS',
        RegAddrZip: 67026,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '8015 S BUTTERFLY CT',
        RegAddrCity: 'CLEARWATER',
        RegAddrState: 'KS',
        RegAddrZip: 67026,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '610 W SAINT LOUIS ST',
        RegAddrCity: 'CONWAY SPGS',
        RegAddrState: 'KS',
        RegAddrZip: 67031,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1507 E PHEASANT RUN ST',
        RegAddrCity: 'DERBY',
        RegAddrState: 'KS',
        RegAddrZip: 67037,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '655 N OAK CT',
        RegAddrCity: 'DERBY',
        RegAddrState: 'KS',
        RegAddrZip: 67037,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2243 E WALNUT CREEK CT',
        RegAddrCity: 'DERBY',
        RegAddrState: 'KS',
        RegAddrZip: 67037,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1039 N BALTIMORE AVE',
        RegAddrCity: 'DERBY',
        RegAddrState: 'KS',
        RegAddrZip: 67037,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '700 HILLSIDE DR',
        RegAddrCity: 'DOUGLASS',
        RegAddrState: 'KS',
        RegAddrZip: 67039,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '15337 SW 220TH ST',
        RegAddrCity: 'DOUGLASS',
        RegAddrState: 'KS',
        RegAddrZip: 67039,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '612 N ALLEGHANY ST',
        RegAddrCity: 'EL DORADO',
        RegAddrState: 'KS',
        RegAddrZip: 67042,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '4509 NW STATE ROAD 196',
        RegAddrCity: 'EL DORADO',
        RegAddrState: 'KS',
        RegAddrZip: 67042,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '525 N JOHNSON ST',
        RegAddrCity: 'ELBING',
        RegAddrState: 'KS',
        RegAddrZip: 67041,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '310 S ILLINOIS ST',
        RegAddrCity: 'EUREKA',
        RegAddrState: 'KS',
        RegAddrZip: 67045,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '224 NE 100 AVE',
        RegAddrCity: 'FREEPORT',
        RegAddrState: 'KS',
        RegAddrZip: 67049,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2300 S LEO ST',
        RegAddrCity: 'GODDARD',
        RegAddrState: 'KS',
        RegAddrZip: 67052,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '908 N OAK RIDGE CT',
        RegAddrCity: 'GODDARD',
        RegAddrState: 'KS',
        RegAddrZip: 67052,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1400 E SUNSET CT',
        RegAddrCity: 'GODDARD',
        RegAddrState: 'KS',
        RegAddrZip: 67052,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2109 E SUNSET ST',
        RegAddrCity: 'GODDARD',
        RegAddrState: 'KS',
        RegAddrZip: 67052,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '415 W 7TH ST',
        RegAddrCity: 'HAYSVILLE',
        RegAddrState: 'KS',
        RegAddrZip: 67060,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2214 W SCHOOLHOUSE ST',
        RegAddrCity: 'HAYSVILLE',
        RegAddrState: 'KS',
        RegAddrZip: 67060,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '8008 N WEST RD',
        RegAddrCity: 'HESSTON',
        RegAddrState: 'KS',
        RegAddrZip: 67062,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '605 HOWARD ST',
        RegAddrCity: 'HUTCHINSON',
        RegAddrState: 'KS',
        RegAddrZip: 67501,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '411 E GRANT AVE',
        RegAddrCity: 'KINGMAN',
        RegAddrState: 'KS',
        RegAddrZip: 67068,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1501 N SPRUCE ST',
        RegAddrCity: 'KINGMAN',
        RegAddrState: 'KS',
        RegAddrZip: 67068,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1760 SE HIGHWAY 400',
        RegAddrCity: 'LEON',
        RegAddrState: 'KS',
        RegAddrZip: 67074,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2875 KILLDEER',
        RegAddrCity: 'LONGTON',
        RegAddrState: 'KS',
        RegAddrZip: 67352,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '3431 P RD',
        RegAddrCity: 'MADISON',
        RegAddrState: 'KS',
        RegAddrZip: 66860,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '4427 N JILL ST',
        RegAddrCity: 'MAIZE',
        RegAddrState: 'KS',
        RegAddrZip: 67101,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '11331 W WILKINSON CT',
        RegAddrCity: 'MAIZE',
        RegAddrState: 'KS',
        RegAddrZip: 67101,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '714 LISA LN',
        RegAddrCity: 'MULVANE',
        RegAddrState: 'KS',
        RegAddrZip: 67110,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '805 RIDGE POINT DR',
        RegAddrCity: 'MULVANE',
        RegAddrState: 'KS',
        RegAddrZip: 67110,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '945 RIVERA DR',
        RegAddrCity: 'MULVANE',
        RegAddrState: 'KS',
        RegAddrZip: 67110,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '9 ROLLING HILLS CT',
        RegAddrCity: 'NEWTON',
        RegAddrState: 'KS',
        RegAddrZip: 67114,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '402 E 10TH ST',
        RegAddrCity: 'NEWTON',
        RegAddrState: 'KS',
        RegAddrZip: 67114,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '211 W 9TH ST',
        RegAddrCity: 'NEWTON',
        RegAddrState: 'KS',
        RegAddrZip: 67114,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '208 HIGHWAY 50',
        RegAddrCity: 'OFFERLE',
        RegAddrState: 'KS',
        RegAddrZip: 67563,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1236 E BEARHILL CIR',
        RegAddrCity: 'PARK CITY',
        RegAddrState: 'KS',
        RegAddrZip: 67147,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '6260 N INDEPENDENCE ST',
        RegAddrCity: 'PARK CITY',
        RegAddrState: 'KS',
        RegAddrZip: 67219,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '120 W MARSHALL AVE',
        RegAddrCity: 'POTWIN',
        RegAddrState: 'KS',
        RegAddrZip: 67123,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '501 TERRACE DR',
        RegAddrCity: 'PRATT',
        RegAddrState: 'KS',
        RegAddrZip: 67124,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '109 W BLAINE ST',
        RegAddrCity: 'PRATT',
        RegAddrState: 'KS',
        RegAddrZip: 67124,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '13426 SW 190TH ST',
        RegAddrCity: 'ROSE HILL',
        RegAddrState: 'KS',
        RegAddrZip: 67133,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '12830 W 117TH ST N',
        RegAddrCity: 'SEDGWICK',
        RegAddrState: 'KS',
        RegAddrZip: 67135,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '5079 SW FULTON RD',
        RegAddrCity: 'TOWANDA',
        RegAddrState: 'KS',
        RegAddrZip: 67144,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '80270 NE 80TH AVE',
        RegAddrCity: 'TURON',
        RegAddrState: 'KS',
        RegAddrZip: 67583,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1400 SUNNYDALE LAKES EST',
        RegAddrCity: 'VALLEY CENTER',
        RegAddrState: 'KS',
        RegAddrZip: 67147,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '343 S SHERIDAN AVE',
        RegAddrCity: 'VALLEY CENTER',
        RegAddrState: 'KS',
        RegAddrZip: 67147,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '611 N WASHINGTON AVE',
        RegAddrCity: 'WELLINGTON',
        RegAddrState: 'KS',
        RegAddrZip: 67152,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '618 W 22ND ST',
        RegAddrCity: 'WELLINGTON',
        RegAddrState: 'KS',
        RegAddrZip: 67152,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '3703 W 8TH ST N',
        RegAddrCity: 'WICHITA',
        RegAddrState: 'KS',
        RegAddrZip: 67203,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '3401 W 13TH ST N',
        RegAddrCity: 'WICHITA',
        RegAddrState: 'KS',
        RegAddrZip: 67203,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1728 N JEANETTE AVE',
        RegAddrCity: 'WICHITA',
        RegAddrState: 'KS',
        RegAddrZip: 67203,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2422 W MARTHA ST',
        RegAddrCity: 'WICHITA',
        RegAddrState: 'KS',
        RegAddrZip: 67203,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1225 N WOODROW AVE',
        RegAddrCity: 'WICHITA',
        RegAddrState: 'KS',
        RegAddrZip: 67203,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2468 N WOODLAND ST',
        RegAddrCity: 'WICHITA',
        RegAddrState: 'KS',
        RegAddrZip: 67204,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1940 W 37TH CT N',
        RegAddrCity: 'WICHITA',
        RegAddrState: 'KS',
        RegAddrZip: 67204,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '5704 N ATHENIAN AVE',
        RegAddrCity: 'WICHITA',
        RegAddrState: 'KS',
        RegAddrZip: 67204,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1220 W 57TH ST N',
        RegAddrCity: 'WICHITA',
        RegAddrState: 'KS',
        RegAddrZip: 67204,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '9426 W STERLING CT',
        RegAddrCity: 'WICHITA',
        RegAddrState: 'KS',
        RegAddrZip: 67205,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '101 N PENROSE DR',
        RegAddrCity: 'WICHITA',
        RegAddrState: 'KS',
        RegAddrZip: 67206,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '8931 E CHURCHILL CIR',
        RegAddrCity: 'WICHITA',
        RegAddrState: 'KS',
        RegAddrZip: 67206,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '19 E HAWTHORNE ST',
        RegAddrCity: 'WICHITA',
        RegAddrState: 'KS',
        RegAddrZip: 67206,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1116 N GATEWOOD CT',
        RegAddrCity: 'WICHITA',
        RegAddrState: 'KS',
        RegAddrZip: 67206,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '663 S EASTRIDGE ST',
        RegAddrCity: 'WICHITA',
        RegAddrState: 'KS',
        RegAddrZip: 67207,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1802 S LONGFORD CT',
        RegAddrCity: 'WICHITA',
        RegAddrState: 'KS',
        RegAddrZip: 67207,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '521 S BARLOW ST',
        RegAddrCity: 'WICHITA',
        RegAddrState: 'KS',
        RegAddrZip: 67207,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '8929 E PARKMONT DR',
        RegAddrCity: 'WICHITA',
        RegAddrState: 'KS',
        RegAddrZip: 67207,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1014 S WICKER ST',
        RegAddrCity: 'WICHITA',
        RegAddrState: 'KS',
        RegAddrZip: 67207,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '708 S ROYAL RD',
        RegAddrCity: 'WICHITA',
        RegAddrState: 'KS',
        RegAddrZip: 67207,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1710 S CHRISTOPHER CIR',
        RegAddrCity: 'WICHITA',
        RegAddrState: 'KS',
        RegAddrZip: 67207,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '308 N PARKWOOD LN',
        RegAddrCity: 'WICHITA',
        RegAddrState: 'KS',
        RegAddrZip: 67208,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1620 KENMAR ST',
        RegAddrCity: 'WICHITA',
        RegAddrState: 'KS',
        RegAddrZip: 67208,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '4101 REGENTS LN',
        RegAddrCity: 'WICHITA',
        RegAddrState: 'KS',
        RegAddrZip: 67208,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '10023 W MAY ST',
        RegAddrCity: 'WICHITA',
        RegAddrState: 'KS',
        RegAddrZip: 67209,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '155 S NEVADA ST',
        RegAddrCity: 'WICHITA',
        RegAddrState: 'KS',
        RegAddrZip: 67209,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1545 S YUCCA PL',
        RegAddrCity: 'WICHITA',
        RegAddrState: 'KS',
        RegAddrZip: 67209,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '4248 E ROSS PKWY',
        RegAddrCity: 'WICHITA',
        RegAddrState: 'KS',
        RegAddrZip: 67210,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2047 S TOPEKA AVE',
        RegAddrCity: 'WICHITA',
        RegAddrState: 'KS',
        RegAddrZip: 67211,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '111 1/2 S HYDRAULIC ST',
        RegAddrCity: 'WICHITA',
        RegAddrState: 'KS',
        RegAddrZip: 67211,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2308 S GREENWOOD ST',
        RegAddrCity: 'WICHITA',
        RegAddrState: 'KS',
        RegAddrZip: 67211,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1521 E SKINNER ST',
        RegAddrCity: 'WICHITA',
        RegAddrState: 'KS',
        RegAddrZip: 67211,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '3017 E OAKLAND AVE',
        RegAddrCity: 'WICHITA',
        RegAddrState: 'KS',
        RegAddrZip: 67211,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1615 S ELLIS ST',
        RegAddrCity: 'WICHITA',
        RegAddrState: 'KS',
        RegAddrZip: 67211,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2016 N AMARADO ST',
        RegAddrCity: 'WICHITA',
        RegAddrState: 'KS',
        RegAddrZip: 67212,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1326 N SANDPLUM LN',
        RegAddrCity: 'WICHITA',
        RegAddrState: 'KS',
        RegAddrZip: 67212,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '954 N DENMARK AVE',
        RegAddrCity: 'WICHITA',
        RegAddrState: 'KS',
        RegAddrZip: 67212,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '11410 W MURDOCK ST',
        RegAddrCity: 'WICHITA',
        RegAddrState: 'KS',
        RegAddrZip: 67212,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '9228 W HICKORY LN',
        RegAddrCity: 'WICHITA',
        RegAddrState: 'KS',
        RegAddrZip: 67212,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '11321 W PONDEROSA ST',
        RegAddrCity: 'WICHITA',
        RegAddrState: 'KS',
        RegAddrZip: 67212,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1831 N CRAMER ST',
        RegAddrCity: 'WICHITA',
        RegAddrState: 'KS',
        RegAddrZip: 67212,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '549 N COUNTRY ACRES AVE',
        RegAddrCity: 'WICHITA',
        RegAddrState: 'KS',
        RegAddrZip: 67212,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2033 S WICHITA ST',
        RegAddrCity: 'WICHITA',
        RegAddrState: 'KS',
        RegAddrZip: 67213,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '861 S ELIZABETH ST',
        RegAddrCity: 'WICHITA',
        RegAddrState: 'KS',
        RegAddrZip: 67213,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '122 S HANDLEY ST',
        RegAddrCity: 'WICHITA',
        RegAddrState: 'KS',
        RegAddrZip: 67213,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '152 N POPLAR AVE',
        RegAddrCity: 'WICHITA',
        RegAddrState: 'KS',
        RegAddrZip: 67214,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '317 N NEW YORK AVE',
        RegAddrCity: 'WICHITA',
        RegAddrState: 'KS',
        RegAddrZip: 67214,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '345 N GROVE DR',
        RegAddrCity: 'WICHITA',
        RegAddrState: 'KS',
        RegAddrZip: 67214,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1812 N GROVE AVE',
        RegAddrCity: 'WICHITA',
        RegAddrState: 'KS',
        RegAddrZip: 67214,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '4575 S JUNIPER ST',
        RegAddrCity: 'WICHITA',
        RegAddrState: 'KS',
        RegAddrZip: 67216,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2000 E 52ND ST S',
        RegAddrCity: 'WICHITA',
        RegAddrState: 'KS',
        RegAddrZip: 67216,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1607 E BERKELEY ST',
        RegAddrCity: 'WICHITA',
        RegAddrState: 'KS',
        RegAddrZip: 67216,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2209 E 53RD ST S',
        RegAddrCity: 'WICHITA',
        RegAddrState: 'KS',
        RegAddrZip: 67216,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '3329 S HIRAM AVE',
        RegAddrCity: 'WICHITA',
        RegAddrState: 'KS',
        RegAddrZip: 67217,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2003 W 35TH ST S',
        RegAddrCity: 'WICHITA',
        RegAddrState: 'KS',
        RegAddrZip: 67217,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '3341 S RICHMOND AVE',
        RegAddrCity: 'WICHITA',
        RegAddrState: 'KS',
        RegAddrZip: 67217,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '5301 S SYCAMORE AVE',
        RegAddrCity: 'WICHITA',
        RegAddrState: 'KS',
        RegAddrZip: 67217,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1937 ROANOKE ST',
        RegAddrCity: 'WICHITA',
        RegAddrState: 'KS',
        RegAddrZip: 67218,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1254 S PERSHING AVE',
        RegAddrCity: 'WICHITA',
        RegAddrState: 'KS',
        RegAddrZip: 67218,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2269 S GLENDALE ST',
        RegAddrCity: 'WICHITA',
        RegAddrState: 'KS',
        RegAddrZip: 67218,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1739 S FABRIQUE DR',
        RegAddrCity: 'WICHITA',
        RegAddrState: 'KS',
        RegAddrZip: 67218,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '5510 E PEMBROOK ST',
        RegAddrCity: 'WICHITA',
        RegAddrState: 'KS',
        RegAddrZip: 67220,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2522 N PARKWOOD CT',
        RegAddrCity: 'WICHITA',
        RegAddrState: 'KS',
        RegAddrZip: 67220,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2634 N RUSHWOOD ST',
        RegAddrCity: 'WICHITA',
        RegAddrState: 'KS',
        RegAddrZip: 67226,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2525 N 159TH ST E',
        RegAddrCity: 'WICHITA',
        RegAddrState: 'KS',
        RegAddrZip: 67228,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1508 N RIDGEHURST ST',
        RegAddrCity: 'WICHITA',
        RegAddrState: 'KS',
        RegAddrZip: 67230,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1026 N AKSARBEN ST',
        RegAddrCity: 'WICHITA',
        RegAddrState: 'KS',
        RegAddrZip: 67235,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '14005 W SHERIAC ST',
        RegAddrCity: 'WICHITA',
        RegAddrState: 'KS',
        RegAddrZip: 67235,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '606 N BAY COUNTRY ST',
        RegAddrCity: 'WICHITA',
        RegAddrState: 'KS',
        RegAddrZip: 67235,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '13822 W RIDGEPOINT ST',
        RegAddrCity: 'WICHITA',
        RegAddrState: 'KS',
        RegAddrZip: 67235,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1122 GARY ST',
        RegAddrCity: 'WINFIELD',
        RegAddrState: 'KS',
        RegAddrZip: 67156,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '501 E 11TH AVE',
        RegAddrCity: 'WINFIELD',
        RegAddrState: 'KS',
        RegAddrZip: 67156,
        CongressionalDistrict: 4,
      },
    ],
  },
  {
    name: 'KY-1',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/KY-1?ref=gh-pages',
    state: 'KY',
    state_and_number: 'KY-1',
    zip: 38079,
    congressionalDistrict: 1,
    addressesInDistrict: [
      {
        RegAddrLine1: '2377 FLEET RD',
        RegAddrCity: 'ADOLPHUS',
        RegAddrState: 'KY',
        RegAddrZip: 42120,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1003 3RD ST',
        RegAddrCity: 'ALBANY',
        RegAddrState: 'KY',
        RegAddrZip: 42602,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '366 KEMPTON RD',
        RegAddrCity: 'ALBANY',
        RegAddrState: 'KY',
        RegAddrZip: 42602,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1153 WAYSIDE INN RD',
        RegAddrCity: 'BARLOW',
        RegAddrState: 'KY',
        RegAddrZip: 42024,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '274 9TH ST',
        RegAddrCity: 'BARLOW',
        RegAddrState: 'KY',
        RegAddrZip: 42024,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1202 JOE CREASON DR',
        RegAddrCity: 'BENTON',
        RegAddrState: 'KY',
        RegAddrZip: 42025,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '3387 INDEPENDENCE RIDGE RD',
        RegAddrCity: 'BREEDING',
        RegAddrState: 'KY',
        RegAddrZip: 42715,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '375 FUDGE RD',
        RegAddrCity: 'BREEDING',
        RegAddrState: 'KY',
        RegAddrZip: 42715,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '20 ROMANS LN',
        RegAddrCity: 'BREMEN',
        RegAddrState: 'KY',
        RegAddrZip: 42325,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '123 SHORT ST',
        RegAddrCity: 'BURKESVILLE',
        RegAddrState: 'KY',
        RegAddrZip: 42717,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1798 W FORK RD',
        RegAddrCity: 'BURKESVILLE',
        RegAddrState: 'KY',
        RegAddrZip: 42717,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '3651 HARDY RD',
        RegAddrCity: 'CADIZ',
        RegAddrState: 'KY',
        RegAddrZip: 42211,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '130 E 7TH ST',
        RegAddrCity: 'CALHOUN',
        RegAddrState: 'KY',
        RegAddrZip: 42327,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '148 CONNECTOR ST',
        RegAddrCity: 'CALVERT CITY',
        RegAddrState: 'KY',
        RegAddrZip: 42029,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1220 CHANEY PIKE',
        RegAddrCity: 'CAMPBELLSVILLE',
        RegAddrState: 'KY',
        RegAddrZip: 42718,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '328 EVE CT',
        RegAddrCity: 'CAMPBELLSVLLE',
        RegAddrState: 'KY',
        RegAddrZip: 42718,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '3584 STATE ROUTE 85 W',
        RegAddrCity: 'CENTERTOWN',
        RegAddrState: 'KY',
        RegAddrZip: 42328,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '327 E FRAZIER AVE',
        RegAddrCity: 'COLUMBIA',
        RegAddrState: 'KY',
        RegAddrZip: 42728,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '721 E WALNUT ST',
        RegAddrCity: 'DAWSON SPGS',
        RegAddrState: 'KY',
        RegAddrZip: 42408,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '158 US HIGHWAY 41A N',
        RegAddrCity: 'DIXON',
        RegAddrState: 'KY',
        RegAddrZip: 42409,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '305 PINE ST',
        RegAddrCity: 'EDDYVILLE',
        RegAddrState: 'KY',
        RegAddrZip: 42038,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '200 E FAIRVIEW AVE',
        RegAddrCity: 'EDDYVILLE',
        RegAddrState: 'KY',
        RegAddrZip: 42038,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '115 FOREST LN',
        RegAddrCity: 'EDDYVILLE',
        RegAddrState: 'KY',
        RegAddrZip: 42038,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '748 RALPH EDWARDS RD',
        RegAddrCity: 'EDMONTON',
        RegAddrState: 'KY',
        RegAddrZip: 42129,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '202 LOVE ST',
        RegAddrCity: 'EDMONTON',
        RegAddrState: 'KY',
        RegAddrZip: 42129,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '399 CLYDE MORAN RD',
        RegAddrCity: 'EDMONTON',
        RegAddrState: 'KY',
        RegAddrZip: 42129,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '3564 BASS RIDGE RD',
        RegAddrCity: 'ELK HORN',
        RegAddrState: 'KY',
        RegAddrZip: 42733,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '13480 ALLEGRE RD',
        RegAddrCity: 'ELKTON',
        RegAddrState: 'KY',
        RegAddrZip: 42220,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '106 LUCKY LN',
        RegAddrCity: 'ELKTON',
        RegAddrState: 'KY',
        RegAddrZip: 42220,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '608 S STREETS AVE',
        RegAddrCity: 'ELKTON',
        RegAddrState: 'KY',
        RegAddrZip: 42220,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '500 GAME RESERVE RD',
        RegAddrCity: 'FARMINGTON',
        RegAddrState: 'KY',
        RegAddrZip: 42040,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '116 CHERRY ST',
        RegAddrCity: 'FRANKLIN',
        RegAddrState: 'KY',
        RegAddrZip: 42134,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1325 VALLEY RIDGE RD',
        RegAddrCity: 'FRANKLIN',
        RegAddrState: 'KY',
        RegAddrZip: 42134,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '9505 STATE ROUTE 94 E',
        RegAddrCity: 'FULTON',
        RegAddrState: 'KY',
        RegAddrZip: 42041,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '634 STATE ROUTE 385 S',
        RegAddrCity: 'FULTON',
        RegAddrState: 'KY',
        RegAddrZip: 42041,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '242 JUSTIN LN',
        RegAddrCity: 'GILBERTSVILLE',
        RegAddrState: 'KY',
        RegAddrZip: 42044,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '432 ELI LN',
        RegAddrCity: 'GRAHAM',
        RegAddrState: 'KY',
        RegAddrZip: 42344,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '746 ROLLING MEADOWS RD',
        RegAddrCity: 'GRAND RIVERS',
        RegAddrState: 'KY',
        RegAddrZip: 42045,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1263 PARHAM LN',
        RegAddrCity: 'GREENVILLE',
        RegAddrState: 'KY',
        RegAddrZip: 42345,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1265 STATE ROUTE 189 S',
        RegAddrCity: 'GREENVILLE',
        RegAddrState: 'KY',
        RegAddrZip: 42345,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '3573 STATE ROUTE 601',
        RegAddrCity: 'GREENVILLE',
        RegAddrState: 'KY',
        RegAddrZip: 42345,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1635 HERMON RD',
        RegAddrCity: 'GUTHRIE',
        RegAddrState: 'KY',
        RegAddrZip: 42234,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '278 A G DANIEL RD',
        RegAddrCity: 'HARTFORD',
        RegAddrState: 'KY',
        RegAddrZip: 42347,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2514 WOOD DR',
        RegAddrCity: 'HENDERSON',
        RegAddrState: 'KY',
        RegAddrZip: 42420,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '125 N HOLLOWAY ST',
        RegAddrCity: 'HENDERSON',
        RegAddrState: 'KY',
        RegAddrZip: 42420,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '9288 US HIGHWAY 41 S',
        RegAddrCity: 'HENDERSON',
        RegAddrState: 'KY',
        RegAddrZip: 42420,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '142 BARREN CHURCH RD S',
        RegAddrCity: 'HENDERSON',
        RegAddrState: 'KY',
        RegAddrZip: 42420,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '256 COLT DR',
        RegAddrCity: 'HOPKINSVILLE',
        RegAddrState: 'KY',
        RegAddrZip: 42240,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1303 SUMMIT ST',
        RegAddrCity: 'HOPKINSVILLE',
        RegAddrState: 'KY',
        RegAddrZip: 42240,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2608 S VIRGINIA ST',
        RegAddrCity: 'HOPKINSVILLE',
        RegAddrState: 'KY',
        RegAddrZip: 42240,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '8684 UPPER BRUSH CREEK RD',
        RegAddrCity: 'HUSTONVILLE',
        RegAddrState: 'KY',
        RegAddrZip: 40437,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '486 CLARKS FERRY RD',
        RegAddrCity: 'LEDBETTER',
        RegAddrState: 'KY',
        RegAddrZip: 42058,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '6395 HIGHLAND LICK RD',
        RegAddrCity: 'LEWISBURG',
        RegAddrState: 'KY',
        RegAddrZip: 42256,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '948 WATSON CHAPEL RD',
        RegAddrCity: 'LIBERTY',
        RegAddrState: 'KY',
        RegAddrZip: 42539,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1070 WIMSATT RD',
        RegAddrCity: 'LORETTO',
        RegAddrState: 'KY',
        RegAddrZip: 40037,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '746 INDEPENDENCE DR',
        RegAddrCity: 'MADISONVILLE',
        RegAddrState: 'KY',
        RegAddrZip: 42431,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '231 S HARRIG ST',
        RegAddrCity: 'MADISONVILLE',
        RegAddrState: 'KY',
        RegAddrZip: 42431,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '265 MANITOU LOOP',
        RegAddrCity: 'MANITOU',
        RegAddrState: 'KY',
        RegAddrZip: 42436,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '555 UNDERDOWN CEMETERY RD',
        RegAddrCity: 'MARION',
        RegAddrState: 'KY',
        RegAddrZip: 42064,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '3735 STATE ROUTE 303',
        RegAddrCity: 'MAYFIELD',
        RegAddrState: 'KY',
        RegAddrZip: 42066,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '844 S 3RD ST',
        RegAddrCity: 'MAYFIELD',
        RegAddrState: 'KY',
        RegAddrZip: 42066,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '235 SKIMMER DR',
        RegAddrCity: 'MURRAY',
        RegAddrState: 'KY',
        RegAddrZip: 42071,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1301A VALLEYWOOD DR',
        RegAddrCity: 'MURRAY',
        RegAddrState: 'KY',
        RegAddrZip: 42071,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1752 STATE ROUTE 121 S',
        RegAddrCity: 'MURRAY',
        RegAddrState: 'KY',
        RegAddrZip: 42071,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '824 WASHINGTON AVE',
        RegAddrCity: 'OAK GROVE',
        RegAddrState: 'KY',
        RegAddrZip: 42262,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '265 WINDMILL DR',
        RegAddrCity: 'PADUCAH',
        RegAddrState: 'KY',
        RegAddrZip: 42001,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '650 N 42ND ST',
        RegAddrCity: 'PADUCAH',
        RegAddrState: 'KY',
        RegAddrZip: 42001,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '3509 PINES RD',
        RegAddrCity: 'PADUCAH',
        RegAddrState: 'KY',
        RegAddrZip: 42001,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '3308 QUAIL COVEY CT',
        RegAddrCity: 'PADUCAH',
        RegAddrState: 'KY',
        RegAddrZip: 42001,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1641 CREEKVIEW DR',
        RegAddrCity: 'PADUCAH',
        RegAddrState: 'KY',
        RegAddrZip: 42003,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '200 PETTER ST',
        RegAddrCity: 'PADUCAH',
        RegAddrState: 'KY',
        RegAddrZip: 42003,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '825 MAPLE ST',
        RegAddrCity: 'PRINCETON',
        RegAddrState: 'KY',
        RegAddrZip: 42445,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '309 ROBIN RD',
        RegAddrCity: 'PRINCETON',
        RegAddrState: 'KY',
        RegAddrZip: 42445,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '268 SUMMER LN',
        RegAddrCity: 'RUSSELL SPGS',
        RegAddrState: 'KY',
        RegAddrZip: 42642,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '7808 BOWLING GREEN RD',
        RegAddrCity: 'SCOTTSVILLE',
        RegAddrState: 'KY',
        RegAddrZip: 42164,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '107 COTTAGE DR',
        RegAddrCity: 'SCOTTSVILLE',
        RegAddrState: 'KY',
        RegAddrZip: 42164,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '316 W CHERRY ST',
        RegAddrCity: 'SCOTTSVILLE',
        RegAddrState: 'KY',
        RegAddrZip: 42164,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '26 W JEFFERSON',
        RegAddrCity: 'SEBREE',
        RegAddrState: 'KY',
        RegAddrZip: 42455,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '9275 BRADFORD LN',
        RegAddrCity: 'WEST PADUCAH',
        RegAddrState: 'KY',
        RegAddrZip: 42086,
        CongressionalDistrict: 1,
      },
    ],
  },
  {
    name: 'KY-2',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/KY-2?ref=gh-pages',
    state: 'KY',
    state_and_number: 'KY-2',
    zip: 40004,
    congressionalDistrict: 2,
    addressesInDistrict: [
      {
        RegAddrLine1: '2027 FARMAWAY DR',
        RegAddrCity: 'BARDSTOWN',
        RegAddrState: 'KY',
        RegAddrZip: 40004,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '114 COUNCIL DR',
        RegAddrCity: 'BARDSTOWN',
        RegAddrState: 'KY',
        RegAddrZip: 40004,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '8401 KY HIGHWAY 259 N',
        RegAddrCity: 'BEE SPRING',
        RegAddrState: 'KY',
        RegAddrZip: 42207,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1647 MURPHY RD',
        RegAddrCity: 'BOWLING GREEN',
        RegAddrState: 'KY',
        RegAddrZip: 42101,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '917 BOATLANDING RD',
        RegAddrCity: 'BOWLING GREEN',
        RegAddrState: 'KY',
        RegAddrZip: 42101,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '529 KITCHENS SOUTH RD',
        RegAddrCity: 'BOWLING GREEN',
        RegAddrState: 'KY',
        RegAddrZip: 42101,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '321 CREEKWOOD AVE',
        RegAddrCity: 'BOWLING GREEN',
        RegAddrState: 'KY',
        RegAddrZip: 42101,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '656 AUTUMNSTONE LN',
        RegAddrCity: 'BOWLING GREEN',
        RegAddrState: 'KY',
        RegAddrZip: 42103,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '152 COLIN POWELL CT',
        RegAddrCity: 'BOWLING GREEN',
        RegAddrState: 'KY',
        RegAddrZip: 42104,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '394 DILLARD RD',
        RegAddrCity: 'BOWLING GREEN',
        RegAddrState: 'KY',
        RegAddrZip: 42104,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '101 PRIMROSE LN',
        RegAddrCity: 'BRANDENBURG',
        RegAddrState: 'KY',
        RegAddrZip: 40108,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '125 GHEN RD',
        RegAddrCity: 'BRANDENBURG',
        RegAddrState: 'KY',
        RegAddrZip: 40108,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '844 FERRILL HILL RD',
        RegAddrCity: 'BUFFALO',
        RegAddrState: 'KY',
        RegAddrZip: 42716,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '308 HOWARD ST',
        RegAddrCity: 'CAMPBELLSVILLE',
        RegAddrState: 'KY',
        RegAddrZip: 42718,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '7304 RABBIT FLAT RD',
        RegAddrCity: 'CANEYVILLE',
        RegAddrState: 'KY',
        RegAddrZip: 42721,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '217 LEIGH AVE',
        RegAddrCity: 'CLARKSON',
        RegAddrState: 'KY',
        RegAddrZip: 42726,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '315 MILLERSTOWN ST',
        RegAddrCity: 'CLARKSON',
        RegAddrState: 'KY',
        RegAddrZip: 42726,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1018 W HOUSTON ST',
        RegAddrCity: 'CLOVERPORT',
        RegAddrState: 'KY',
        RegAddrZip: 40111,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '151 PEABODY LOOP',
        RegAddrCity: 'COXS CREEK',
        RegAddrState: 'KY',
        RegAddrZip: 40013,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '304 BERMAN WELLS RD',
        RegAddrCity: 'COXS CREEK',
        RegAddrState: 'KY',
        RegAddrZip: 40013,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '458 S 4TH ST',
        RegAddrCity: 'DANVILLE',
        RegAddrState: 'KY',
        RegAddrZip: 40422,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '9 E AIRVIEW DR',
        RegAddrCity: 'ELIZABETHTOWN',
        RegAddrState: 'KY',
        RegAddrZip: 42701,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1703 ORIOLE DR',
        RegAddrCity: 'ELIZABETHTOWN',
        RegAddrState: 'KY',
        RegAddrZip: 42701,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '7311 BARDSTOWN RD',
        RegAddrCity: 'ELIZABETHTOWN',
        RegAddrState: 'KY',
        RegAddrZip: 42701,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '403 SAINT JOHN RD',
        RegAddrCity: 'ELIZABETHTOWN',
        RegAddrState: 'KY',
        RegAddrZip: 42701,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '18 E RAINBOW WAY',
        RegAddrCity: 'ELIZABETHTOWN',
        RegAddrState: 'KY',
        RegAddrZip: 42701,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '247 JUNCTION RD',
        RegAddrCity: 'FALLS ROUGH',
        RegAddrState: 'KY',
        RegAddrZip: 40119,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '201 WESTERN HILLS RD',
        RegAddrCity: 'GLASGOW',
        RegAddrState: 'KY',
        RegAddrZip: 42141,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '208 SHANGRI LA PL',
        RegAddrCity: 'GLASGOW',
        RegAddrState: 'KY',
        RegAddrZip: 42141,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '177 HILLWOOD RD',
        RegAddrCity: 'GLASGOW',
        RegAddrState: 'KY',
        RegAddrZip: 42141,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '701 OSCAR MILBY LN',
        RegAddrCity: 'GREENSBURG',
        RegAddrState: 'KY',
        RegAddrZip: 42743,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '468 DAVIESS ST',
        RegAddrCity: 'HARRODSBURG',
        RegAddrState: 'KY',
        RegAddrZip: 40330,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '714 PARKWAY AVE',
        RegAddrCity: 'HARRODSBURG',
        RegAddrState: 'KY',
        RegAddrZip: 40330,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '380 HARRISON ST',
        RegAddrCity: 'HAWESVILLE',
        RegAddrState: 'KY',
        RegAddrZip: 42348,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2354 STATE ROUTE 144 W',
        RegAddrCity: 'HAWESVILLE',
        RegAddrState: 'KY',
        RegAddrZip: 42348,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '364 S GREENSBURG ST',
        RegAddrCity: 'HODGENVILLE',
        RegAddrState: 'KY',
        RegAddrZip: 42748,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '325 BELLVIEW ST',
        RegAddrCity: 'JUNCTION CITY',
        RegAddrState: 'KY',
        RegAddrZip: 40440,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '3264 RICHMOND RD',
        RegAddrCity: 'LANCASTER',
        RegAddrState: 'KY',
        RegAddrZip: 40444,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '112 RANSDELL CT',
        RegAddrCity: 'LANCASTER',
        RegAddrState: 'KY',
        RegAddrZip: 40444,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '9640 BATTLE TRAINING RD',
        RegAddrCity: 'LEBANON JCT',
        RegAddrState: 'KY',
        RegAddrZip: 40150,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '222 KING RD',
        RegAddrCity: 'LEITCHFIELD',
        RegAddrState: 'KY',
        RegAddrZip: 42754,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '480 PELL ST',
        RegAddrCity: 'LEWISPORT',
        RegAddrState: 'KY',
        RegAddrZip: 42351,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1595 RACHEL CT',
        RegAddrCity: 'LEWISPORT',
        RegAddrState: 'KY',
        RegAddrZip: 42351,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2 S CALUMET CT',
        RegAddrCity: 'LOUISVILLE',
        RegAddrState: 'KY',
        RegAddrZip: 40214,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2308 ALICE KAY DR',
        RegAddrCity: 'LOUISVILLE',
        RegAddrState: 'KY',
        RegAddrZip: 40214,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '169 ORCHID CT',
        RegAddrCity: 'LOUISVILLE',
        RegAddrState: 'KY',
        RegAddrZip: 40229,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '8936 SACRA DR',
        RegAddrCity: 'MACEO',
        RegAddrState: 'KY',
        RegAddrZip: 42355,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1900 PIKEVIEW RD',
        RegAddrCity: 'MAGNOLIA',
        RegAddrState: 'KY',
        RegAddrZip: 42757,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '9391 NEW JACKSON HWY',
        RegAddrCity: 'MAGNOLIA',
        RegAddrState: 'KY',
        RegAddrZip: 42757,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2952 LOGANSPORT RD',
        RegAddrCity: 'MORGANTOWN',
        RegAddrState: 'KY',
        RegAddrZip: 42261,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '117 BROOKDALE RD',
        RegAddrCity: 'MT WASHINGTON',
        RegAddrState: 'KY',
        RegAddrZip: 40047,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '724 COLORADO DR',
        RegAddrCity: 'OWENSBORO',
        RegAddrState: 'KY',
        RegAddrZip: 42301,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2410 HAVILAND DR',
        RegAddrCity: 'OWENSBORO',
        RegAddrState: 'KY',
        RegAddrZip: 42301,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '3609 N DOVE LOOP',
        RegAddrCity: 'OWENSBORO',
        RegAddrState: 'KY',
        RegAddrZip: 42301,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2514 SOUTHTOWN BLVD',
        RegAddrCity: 'OWENSBORO',
        RegAddrState: 'KY',
        RegAddrZip: 42301,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1738 THISTLE CT',
        RegAddrCity: 'OWENSBORO',
        RegAddrState: 'KY',
        RegAddrZip: 42301,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '7383 LAMPLITE CIR',
        RegAddrCity: 'OWENSBORO',
        RegAddrState: 'KY',
        RegAddrZip: 42303,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '3500 RIVA RIDGE CT',
        RegAddrCity: 'OWENSBORO',
        RegAddrState: 'KY',
        RegAddrZip: 42303,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2225 SUMMER WALK',
        RegAddrCity: 'OWENSBORO',
        RegAddrState: 'KY',
        RegAddrZip: 42303,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '3774 PINE LAKE CT',
        RegAddrCity: 'OWENSBORO',
        RegAddrState: 'KY',
        RegAddrZip: 42303,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '933 DIXIANA DR',
        RegAddrCity: 'OWENSBORO',
        RegAddrState: 'KY',
        RegAddrZip: 42303,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '4531 TAYLOR DR',
        RegAddrCity: 'OWENSBORO',
        RegAddrState: 'KY',
        RegAddrZip: 42303,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '11810 HIGHWAY 52 E',
        RegAddrCity: 'PAINT LICK',
        RegAddrState: 'KY',
        RegAddrZip: 40461,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2035 CHESTNUT GROVE RD',
        RegAddrCity: 'PARKSVILLE',
        RegAddrState: 'KY',
        RegAddrZip: 40464,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '51 MASDEN ST',
        RegAddrCity: 'RADCLIFF',
        RegAddrState: 'KY',
        RegAddrZip: 40160,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '226 PEACEFUL WAY',
        RegAddrCity: 'SHEPHERDSVILLE',
        RegAddrState: 'KY',
        RegAddrZip: 40165,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '193 NIPPER CT',
        RegAddrCity: 'SHEPHERDSVLLE',
        RegAddrState: 'KY',
        RegAddrZip: 40165,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '382 S BUCKMAN ST',
        RegAddrCity: 'SHEPHERDSVLLE',
        RegAddrState: 'KY',
        RegAddrZip: 40165,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1057 CHALYBEATE SCHOOL RD',
        RegAddrCity: 'SMITHS GROVE',
        RegAddrState: 'KY',
        RegAddrZip: 42171,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1131 HILLSBORO LN',
        RegAddrCity: 'SPRINGFIELD',
        RegAddrState: 'KY',
        RegAddrZip: 40069,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '420 BEECHLAND RD',
        RegAddrCity: 'SPRINGFIELD',
        RegAddrState: 'KY',
        RegAddrZip: 40069,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2541 LOVE LN',
        RegAddrCity: 'TAYLORSVILLE',
        RegAddrState: 'KY',
        RegAddrZip: 40071,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '3561 KINGS CHURCH RD',
        RegAddrCity: 'TAYLORSVILLE',
        RegAddrState: 'KY',
        RegAddrZip: 40071,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '4094 STATE ROUTE 764',
        RegAddrCity: 'UTICA',
        RegAddrState: 'KY',
        RegAddrZip: 42376,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '11132 FIELDS RD',
        RegAddrCity: 'UTICA',
        RegAddrState: 'KY',
        RegAddrZip: 42376,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '506 S MAIN ST',
        RegAddrCity: 'VERSAILLES',
        RegAddrState: 'KY',
        RegAddrZip: 40383,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '55 ELM CT',
        RegAddrCity: 'VINE GROVE',
        RegAddrState: 'KY',
        RegAddrZip: 40175,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '11046 STATE ROUTE 764',
        RegAddrCity: 'WHITESVILLE',
        RegAddrState: 'KY',
        RegAddrZip: 42378,
        CongressionalDistrict: 2,
      },
    ],
  },
  {
    name: 'KY-3',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/KY-3?ref=gh-pages',
    state: 'KY',
    state_and_number: 'KY-3',
    zip: 40025,
    congressionalDistrict: 3,
    addressesInDistrict: [
      {
        RegAddrLine1: '9210 SISSONE DR',
        RegAddrCity: 'FAIRDALE',
        RegAddrState: 'KY',
        RegAddrZip: 40118,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '10310 WOODED HOBBS TRL',
        RegAddrCity: 'FAIRDALE',
        RegAddrState: 'KY',
        RegAddrZip: 40118,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1314 HOLLY AVE',
        RegAddrCity: 'FAIRDALE',
        RegAddrState: 'KY',
        RegAddrZip: 40118,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '404 N FIRST AVE',
        RegAddrCity: 'LAGRANGE',
        RegAddrState: 'KY',
        RegAddrZip: 40031,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '434 N 20TH ST',
        RegAddrCity: 'LOUISVILLE',
        RegAddrState: 'KY',
        RegAddrZip: 40203,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '2209 DOUGLASS BLVD',
        RegAddrCity: 'LOUISVILLE',
        RegAddrState: 'KY',
        RegAddrZip: 40205,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '3127 SUNNY LN',
        RegAddrCity: 'LOUISVILLE',
        RegAddrState: 'KY',
        RegAddrZip: 40205,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '408 WYNFIELD CLOSE CT',
        RegAddrCity: 'LOUISVILLE',
        RegAddrState: 'KY',
        RegAddrZip: 40206,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '603 ROYER CT',
        RegAddrCity: 'LOUISVILLE',
        RegAddrState: 'KY',
        RegAddrZip: 40206,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '311 NORBOURNE BLVD',
        RegAddrCity: 'LOUISVILLE',
        RegAddrState: 'KY',
        RegAddrZip: 40207,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '431 BLANKENBAKER LN',
        RegAddrCity: 'LOUISVILLE',
        RegAddrState: 'KY',
        RegAddrZip: 40207,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1523 W KENTUCKY ST',
        RegAddrCity: 'LOUISVILLE',
        RegAddrState: 'KY',
        RegAddrZip: 40210,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '659 S 41ST ST',
        RegAddrCity: 'LOUISVILLE',
        RegAddrState: 'KY',
        RegAddrZip: 40211,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '3508 W BROADWAY',
        RegAddrCity: 'LOUISVILLE',
        RegAddrState: 'KY',
        RegAddrZip: 40211,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '3400 SWANSEA CT',
        RegAddrCity: 'LOUISVILLE',
        RegAddrState: 'KY',
        RegAddrZip: 40211,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '917 S 40TH ST',
        RegAddrCity: 'LOUISVILLE',
        RegAddrState: 'KY',
        RegAddrZip: 40211,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '2401 MONTGOMERY ST',
        RegAddrCity: 'LOUISVILLE',
        RegAddrState: 'KY',
        RegAddrZip: 40212,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '421 N 28TH ST',
        RegAddrCity: 'LOUISVILLE',
        RegAddrState: 'KY',
        RegAddrZip: 40212,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '2820 MARIGOLD AVE',
        RegAddrCity: 'LOUISVILLE',
        RegAddrState: 'KY',
        RegAddrZip: 40213,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '4910 WABASH PL',
        RegAddrCity: 'LOUISVILLE',
        RegAddrState: 'KY',
        RegAddrZip: 40214,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '163 E FRANCIS AVE',
        RegAddrCity: 'LOUISVILLE',
        RegAddrState: 'KY',
        RegAddrZip: 40214,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '5705 MARY ELLEN DR',
        RegAddrCity: 'LOUISVILLE',
        RegAddrState: 'KY',
        RegAddrZip: 40214,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '7320 SOUTHSIDE DR',
        RegAddrCity: 'LOUISVILLE',
        RegAddrState: 'KY',
        RegAddrZip: 40214,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '3607 HENRY AVE',
        RegAddrCity: 'LOUISVILLE',
        RegAddrState: 'KY',
        RegAddrZip: 40215,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1220 W WOODLAWN AVE',
        RegAddrCity: 'LOUISVILLE',
        RegAddrState: 'KY',
        RegAddrZip: 40215,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '824 W EVELYN AVE',
        RegAddrCity: 'LOUISVILLE',
        RegAddrState: 'KY',
        RegAddrZip: 40215,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '4026 PARTHENIA AVE',
        RegAddrCity: 'LOUISVILLE',
        RegAddrState: 'KY',
        RegAddrZip: 40215,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1562 KNIGHT RD',
        RegAddrCity: 'LOUISVILLE',
        RegAddrState: 'KY',
        RegAddrZip: 40216,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '6606 UPPER HUNTERS TRCE',
        RegAddrCity: 'LOUISVILLE',
        RegAddrState: 'KY',
        RegAddrZip: 40216,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '2511 PADDOCK CT',
        RegAddrCity: 'LOUISVILLE',
        RegAddrState: 'KY',
        RegAddrZip: 40216,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '3003 DIXIE HWY',
        RegAddrCity: 'LOUISVILLE',
        RegAddrState: 'KY',
        RegAddrZip: 40216,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '4028 CHEVIOT DR',
        RegAddrCity: 'LOUISVILLE',
        RegAddrState: 'KY',
        RegAddrZip: 40216,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '2603 PINDELL AVE',
        RegAddrCity: 'LOUISVILLE',
        RegAddrState: 'KY',
        RegAddrZip: 40217,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '2622 DELOR AVE',
        RegAddrCity: 'LOUISVILLE',
        RegAddrState: 'KY',
        RegAddrZip: 40217,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1900 BASHFORD MANOR LN',
        RegAddrCity: 'LOUISVILLE',
        RegAddrState: 'KY',
        RegAddrZip: 40218,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '5704 TARRAGON CT',
        RegAddrCity: 'LOUISVILLE',
        RegAddrState: 'KY',
        RegAddrZip: 40219,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '6111 GREEN MANOR DR',
        RegAddrCity: 'LOUISVILLE',
        RegAddrState: 'KY',
        RegAddrZip: 40219,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '5613 JEANINE DR',
        RegAddrCity: 'LOUISVILLE',
        RegAddrState: 'KY',
        RegAddrZip: 40219,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '3430 CHATEAU LN',
        RegAddrCity: 'LOUISVILLE',
        RegAddrState: 'KY',
        RegAddrZip: 40219,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '5418 MONACO DR',
        RegAddrCity: 'LOUISVILLE',
        RegAddrState: 'KY',
        RegAddrZip: 40219,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '3621 BROOKHOLLOW DR',
        RegAddrCity: 'LOUISVILLE',
        RegAddrState: 'KY',
        RegAddrZip: 40220,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '2514 ASHBROOK AVE',
        RegAddrCity: 'LOUISVILLE',
        RegAddrState: 'KY',
        RegAddrZip: 40220,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '8912 LANTERN LITE PKWY',
        RegAddrCity: 'LOUISVILLE',
        RegAddrState: 'KY',
        RegAddrZip: 40220,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '8702 STOCKPORT RD',
        RegAddrCity: 'LOUISVILLE',
        RegAddrState: 'KY',
        RegAddrZip: 40222,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '8020 POST OAK PL',
        RegAddrCity: 'LOUISVILLE',
        RegAddrState: 'KY',
        RegAddrZip: 40222,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1827 MILBURT DR',
        RegAddrCity: 'LOUISVILLE',
        RegAddrState: 'KY',
        RegAddrZip: 40223,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '9804 PHOENIX TRL',
        RegAddrCity: 'LOUISVILLE',
        RegAddrState: 'KY',
        RegAddrZip: 40223,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '6006 WOODHAVEN PLACE CIR',
        RegAddrCity: 'LOUISVILLE',
        RegAddrState: 'KY',
        RegAddrZip: 40228,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '4000 OLD BROWNSBORO HILLS RD',
        RegAddrCity: 'LOUISVILLE',
        RegAddrState: 'KY',
        RegAddrZip: 40241,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '8313 FREEMONT RD',
        RegAddrCity: 'LOUISVILLE',
        RegAddrState: 'KY',
        RegAddrZip: 40242,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '6611 HUNTERS CREEK BLVD',
        RegAddrCity: 'LOUISVILLE',
        RegAddrState: 'KY',
        RegAddrZip: 40258,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '8901 SEAFORTH DR',
        RegAddrCity: 'LOUISVILLE',
        RegAddrState: 'KY',
        RegAddrZip: 40258,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '8217 LATANIA DR',
        RegAddrCity: 'LOUISVILLE',
        RegAddrState: 'KY',
        RegAddrZip: 40258,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '7817 PLEASURE WALK CIR',
        RegAddrCity: 'LOUISVILLE',
        RegAddrState: 'KY',
        RegAddrZip: 40258,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '7223 NIGHT ACRES LN',
        RegAddrCity: 'LOUISVILLE',
        RegAddrState: 'KY',
        RegAddrZip: 40258,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '10213 EVE DR',
        RegAddrCity: 'LOUISVILLE',
        RegAddrState: 'KY',
        RegAddrZip: 40272,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '5501 FRUITWOOD DR',
        RegAddrCity: 'LOUISVILLE',
        RegAddrState: 'KY',
        RegAddrZip: 40272,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '8305 CREEK TRAIL CT',
        RegAddrCity: 'LOUISVILLE',
        RegAddrState: 'KY',
        RegAddrZip: 40291,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '10205 EL COCO CT',
        RegAddrCity: 'LOUISVILLE',
        RegAddrState: 'KY',
        RegAddrZip: 40291,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '7905 AVANTI WAY',
        RegAddrCity: 'LOUISVILLE',
        RegAddrState: 'KY',
        RegAddrZip: 40291,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '6805 RIGGS DR',
        RegAddrCity: 'LOUISVILLE',
        RegAddrState: 'KY',
        RegAddrZip: 40291,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '4819 NAPA RIDGE WAY',
        RegAddrCity: 'LOUISVILLE',
        RegAddrState: 'KY',
        RegAddrZip: 40299,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '9205 TALITHA DR',
        RegAddrCity: 'LOUISVILLE',
        RegAddrState: 'KY',
        RegAddrZip: 40299,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '10901 MEETING ST',
        RegAddrCity: 'PROSPECT',
        RegAddrState: 'KY',
        RegAddrZip: 40059,
        CongressionalDistrict: 3,
      },
    ],
  },
  {
    name: 'KY-4',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/KY-4?ref=gh-pages',
    state: 'KY',
    state_and_number: 'KY-4',
    zip: 40003,
    congressionalDistrict: 4,
    addressesInDistrict: [
      {
        RegAddrLine1: '111 CLEARMEADOW DR',
        RegAddrCity: 'ALEXANDRIA',
        RegAddrState: 'KY',
        RegAddrZip: 41001,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '10 TERRACE DR',
        RegAddrCity: 'ALEXANDRIA',
        RegAddrState: 'KY',
        RegAddrZip: 41001,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '319 BELLEFONTE DR',
        RegAddrCity: 'ASHLAND',
        RegAddrState: 'KY',
        RegAddrZip: 41101,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2916 NEWMAN ST',
        RegAddrCity: 'ASHLAND',
        RegAddrState: 'KY',
        RegAddrZip: 41101,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '781 FAIRVIEW AVE',
        RegAddrCity: 'ASHLAND',
        RegAddrState: 'KY',
        RegAddrZip: 41102,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '6517 THURBURY RD',
        RegAddrCity: 'ASHLAND',
        RegAddrState: 'KY',
        RegAddrZip: 41102,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '303 ELIZABETH ST',
        RegAddrCity: 'AUGUSTA',
        RegAddrState: 'KY',
        RegAddrZip: 41002,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '310 HILLSDALE RD',
        RegAddrCity: 'AUGUSTA',
        RegAddrState: 'KY',
        RegAddrZip: 41002,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '420 E HEATHER RENEE FRENCH BLVD',
        RegAddrCity: 'AUGUSTA',
        RegAddrState: 'KY',
        RegAddrZip: 41002,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1911 CEDARMORE RD',
        RegAddrCity: 'BAGDAD',
        RegAddrState: 'KY',
        RegAddrZip: 40003,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1175 JOYCE MILLS RD',
        RegAddrCity: 'BEDFORD',
        RegAddrState: 'KY',
        RegAddrZip: 40006,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '812 TAYLOR AVE',
        RegAddrCity: 'BELLEVUE',
        RegAddrState: 'KY',
        RegAddrZip: 41073,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '127 BLAKE LN',
        RegAddrCity: 'BERRY',
        RegAddrState: 'KY',
        RegAddrZip: 41003,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '320 FRANKFORT ST',
        RegAddrCity: 'BROOKSVILLE',
        RegAddrState: 'KY',
        RegAddrZip: 41004,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '14356 HIGHWAY 10 N',
        RegAddrCity: 'BUTLER',
        RegAddrState: 'KY',
        RegAddrZip: 41006,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '3099 MOUND HILL RD',
        RegAddrCity: 'CARROLLTON',
        RegAddrState: 'KY',
        RegAddrZip: 41008,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '6 ROSA PL',
        RegAddrCity: 'COLD SPRING',
        RegAddrState: 'KY',
        RegAddrZip: 41076,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1723 RUSSELL ST',
        RegAddrCity: 'COVINGTON',
        RegAddrState: 'KY',
        RegAddrZip: 41011,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '505 W 21ST ST',
        RegAddrCity: 'COVINGTON',
        RegAddrState: 'KY',
        RegAddrZip: 41014,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1202 HILLVIEW CIR',
        RegAddrCity: 'CRESTWOOD',
        RegAddrState: 'KY',
        RegAddrZip: 40014,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '7220 E ORCHARD GRASS BLVD',
        RegAddrCity: 'CRESTWOOD',
        RegAddrState: 'KY',
        RegAddrZip: 40014,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '7519 E ORCHARD GRASS BLVD',
        RegAddrCity: 'CRESTWOOD',
        RegAddrState: 'KY',
        RegAddrZip: 40014,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '6201 OAKLEA DR',
        RegAddrCity: 'CRESTWOOD',
        RegAddrState: 'KY',
        RegAddrZip: 40014,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '15677 OLD LEXINGTON PIKE',
        RegAddrCity: 'CRITTENDEN',
        RegAddrState: 'KY',
        RegAddrZip: 41030,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '283 NEWTOWN LEESBURG RD',
        RegAddrCity: 'CYNTHIANA',
        RegAddrState: 'KY',
        RegAddrZip: 41031,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '532 METCALF DR',
        RegAddrCity: 'EDGEWOOD',
        RegAddrState: 'KY',
        RegAddrZip: 41017,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '135 KENTON ST',
        RegAddrCity: 'ELSMERE',
        RegAddrState: 'KY',
        RegAddrZip: 41018,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '3358 SPRUCE TREE LN',
        RegAddrCity: 'ERLANGER',
        RegAddrState: 'KY',
        RegAddrZip: 41018,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '3905 LLOYD AVE',
        RegAddrCity: 'ERLANGER',
        RegAddrState: 'KY',
        RegAddrZip: 41018,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '3 SURREY PL',
        RegAddrCity: 'ERLANGER',
        RegAddrState: 'KY',
        RegAddrZip: 41018,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '3973 LOFTYVIEW PL',
        RegAddrCity: 'ERLANGER',
        RegAddrState: 'KY',
        RegAddrZip: 41018,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '245 WHITSON RD',
        RegAddrCity: 'FALMOUTH',
        RegAddrState: 'KY',
        RegAddrZip: 41040,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1100 GRANDVIEW DR',
        RegAddrCity: 'FLATWOODS',
        RegAddrState: 'KY',
        RegAddrZip: 41139,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '6522 TALL OAKS DR',
        RegAddrCity: 'FLORENCE',
        RegAddrState: 'KY',
        RegAddrZip: 41042,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '253 BELAIR CIR',
        RegAddrCity: 'FLORENCE',
        RegAddrState: 'KY',
        RegAddrZip: 41042,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '44 KATHRYN AVE',
        RegAddrCity: 'FLORENCE',
        RegAddrState: 'KY',
        RegAddrZip: 41042,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '8605 ELLINGSWORTH WAY',
        RegAddrCity: 'FLORENCE',
        RegAddrState: 'KY',
        RegAddrZip: 41042,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '14 LLOYD AVE',
        RegAddrCity: 'FLORENCE',
        RegAddrState: 'KY',
        RegAddrZip: 41042,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '8335 WOODCREEK DR',
        RegAddrCity: 'FLORENCE',
        RegAddrState: 'KY',
        RegAddrZip: 41042,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '406 OLIVIA LN',
        RegAddrCity: 'FT WRIGHT',
        RegAddrState: 'KY',
        RegAddrZip: 41011,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '61 MORRIS PL',
        RegAddrCity: 'FT WRIGHT',
        RegAddrState: 'KY',
        RegAddrZip: 41011,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: 'PO BOX 385',
        RegAddrCity: 'GARRISON',
        RegAddrState: 'KY',
        RegAddrZip: 41141,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2208 KY HIGHWAY 47',
        RegAddrCity: 'GHENT',
        RegAddrState: 'KY',
        RegAddrZip: 41045,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1108 CLIFFWOOD DR',
        RegAddrCity: 'GOSHEN',
        RegAddrState: 'KY',
        RegAddrZip: 40026,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '902 WINIFRED ST',
        RegAddrCity: 'GREENUP',
        RegAddrState: 'KY',
        RegAddrZip: 41144,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1087 MCKAY RD',
        RegAddrCity: 'GREENUP',
        RegAddrState: 'KY',
        RegAddrZip: 41144,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1653 CHERRY BLOSSOM CT',
        RegAddrCity: 'HEBRON',
        RegAddrState: 'KY',
        RegAddrZip: 41048,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1861 COACHTRAIL DR',
        RegAddrCity: 'HEBRON',
        RegAddrState: 'KY',
        RegAddrZip: 41048,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '53 HIDDEN VALLEY DR',
        RegAddrCity: 'HIGHLAND HGTS',
        RegAddrState: 'KY',
        RegAddrZip: 41076,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '5921 MARBLE WAY',
        RegAddrCity: 'HIGHLAND HGTS',
        RegAddrState: 'KY',
        RegAddrZip: 41076,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '3 JILLIAN CT',
        RegAddrCity: 'HIGHLAND HGTS',
        RegAddrState: 'KY',
        RegAddrZip: 41076,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1397 MEADOW BREEZE LN',
        RegAddrCity: 'INDEPENDENCE',
        RegAddrState: 'KY',
        RegAddrZip: 41051,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '10767 PARKER DR',
        RegAddrCity: 'INDEPENDENCE',
        RegAddrState: 'KY',
        RegAddrZip: 41051,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '6209 WHITEBARK CT',
        RegAddrCity: 'INDEPENDENCE',
        RegAddrState: 'KY',
        RegAddrZip: 41051,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '822 RIDGEPOINT DR',
        RegAddrCity: 'INDEPENDENCE',
        RegAddrState: 'KY',
        RegAddrZip: 41051,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '11558 BUNKER HILL CT',
        RegAddrCity: 'INDEPENDENCE',
        RegAddrState: 'KY',
        RegAddrZip: 41051,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '11412 DEHAM DR',
        RegAddrCity: 'LOUISVILLE',
        RegAddrState: 'KY',
        RegAddrZip: 40241,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '17707 CURRY BRANCH RD',
        RegAddrCity: 'LOUISVILLE',
        RegAddrState: 'KY',
        RegAddrZip: 40245,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '711 INSPIRATION WAY',
        RegAddrCity: 'LOUISVILLE',
        RegAddrState: 'KY',
        RegAddrZip: 40245,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '17114 DRY RIDGE RD',
        RegAddrCity: 'LOUISVILLE',
        RegAddrState: 'KY',
        RegAddrZip: 40299,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '48 CARNEAL ST',
        RegAddrCity: 'LUDLOW',
        RegAddrState: 'KY',
        RegAddrZip: 41016,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2197 HEMPFLING RD',
        RegAddrCity: 'MORNING VIEW',
        RegAddrState: 'KY',
        RegAddrZip: 41063,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '425 CHESTNUT WAY',
        RegAddrCity: 'NEWPORT',
        RegAddrState: 'KY',
        RegAddrZip: 41071,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '226 SUNSET DR',
        RegAddrCity: 'NEWPORT',
        RegAddrState: 'KY',
        RegAddrZip: 41076,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '600 BOHANNON RD',
        RegAddrCity: 'PLEASUREVILLE',
        RegAddrState: 'KY',
        RegAddrZip: 40057,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '5030 WOODS PIKE',
        RegAddrCity: 'PLEASUREVILLE',
        RegAddrState: 'KY',
        RegAddrZip: 40057,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '10719 WORTHINGTON LN',
        RegAddrCity: 'PROSPECT',
        RegAddrState: 'KY',
        RegAddrZip: 40059,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '624 SOUTHLAWN DR',
        RegAddrCity: 'SHELBYVILLE',
        RegAddrState: 'KY',
        RegAddrZip: 40065,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1729 FINCHVILLE RD',
        RegAddrCity: 'SHELBYVILLE',
        RegAddrState: 'KY',
        RegAddrZip: 40065,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '3550 EMINENCE PIKE',
        RegAddrCity: 'SHELBYVILLE',
        RegAddrState: 'KY',
        RegAddrZip: 40065,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '3602 BALLARD TRACE DR',
        RegAddrCity: 'SMITHFIELD',
        RegAddrState: 'KY',
        RegAddrZip: 40068,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '195 HOMESTEAD DR',
        RegAddrCity: 'SPARTA',
        RegAddrState: 'KY',
        RegAddrZip: 41086,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1868 BENSON PL',
        RegAddrCity: 'UNION',
        RegAddrState: 'KY',
        RegAddrZip: 41091,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '545 CONCORD RD',
        RegAddrCity: 'VERONA',
        RegAddrState: 'KY',
        RegAddrZip: 41092,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '13653 CARR RD',
        RegAddrCity: 'VERONA',
        RegAddrState: 'KY',
        RegAddrZip: 41092,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '613 ROGERS RD',
        RegAddrCity: 'VILLA HILLS',
        RegAddrState: 'KY',
        RegAddrZip: 41017,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '724 RIVER RD',
        RegAddrCity: 'VILLA HILLS',
        RegAddrState: 'KY',
        RegAddrZip: 41017,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1301 RICHWOOD RD',
        RegAddrCity: 'WALTON',
        RegAddrState: 'KY',
        RegAddrZip: 41094,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '886 KNOXVILLE GARDNERSVILLE RD',
        RegAddrCity: 'WILLIAMSTOWN',
        RegAddrState: 'KY',
        RegAddrZip: 41097,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '24 SAYERS DR',
        RegAddrCity: 'WILLIAMSTOWN',
        RegAddrState: 'KY',
        RegAddrZip: 41097,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1213 FOOTHILLS CT',
        RegAddrCity: 'WURTLAND',
        RegAddrState: 'KY',
        RegAddrZip: 41144,
        CongressionalDistrict: 4,
      },
    ],
  },
  {
    name: 'KY-5',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/KY-5?ref=gh-pages',
    state: 'KY',
    state_and_number: 'KY-5',
    zip: 40313,
    congressionalDistrict: 5,
    addressesInDistrict: [
      {
        RegAddrLine1: '9488 HIGHWAY 32',
        RegAddrCity: 'ADAMS',
        RegAddrState: 'KY',
        RegAddrZip: 41201,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '2385 S KY 11',
        RegAddrCity: 'BARBOURVILLE',
        RegAddrState: 'KY',
        RegAddrZip: 40906,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '104 GRACIE LN',
        RegAddrCity: 'BEATTYVILLE',
        RegAddrState: 'KY',
        RegAddrZip: 41311,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '116 EVELYN LN',
        RegAddrCity: 'BLEDSOE',
        RegAddrState: 'KY',
        RegAddrZip: 40810,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '36 GILLIAM ST',
        RegAddrCity: 'CLEARFIELD',
        RegAddrState: 'KY',
        RegAddrZip: 40313,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '7491 S US HIGHWAY 25',
        RegAddrCity: 'CORBIN',
        RegAddrState: 'KY',
        RegAddrZip: 40701,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '47 HOLLOW VIEW RD',
        RegAddrCity: 'CORBIN',
        RegAddrState: 'KY',
        RegAddrZip: 40701,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '118 RUFFIAN TRL',
        RegAddrCity: 'CORBIN',
        RegAddrState: 'KY',
        RegAddrZip: 40701,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '2215 LEVEL GREEN RD',
        RegAddrCity: 'CORBIN',
        RegAddrState: 'KY',
        RegAddrZip: 40701,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '189 FRESH MEADOWS RD',
        RegAddrCity: 'DAYHOIT',
        RegAddrState: 'KY',
        RegAddrZip: 40824,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '350 MCWILLIAMS RD',
        RegAddrCity: 'EUBANK',
        RegAddrState: 'KY',
        RegAddrZip: 42567,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '129 MOSSWOOD LN',
        RegAddrCity: 'GRAY',
        RegAddrState: 'KY',
        RegAddrZip: 40734,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '1675 COOK CRK',
        RegAddrCity: 'GRAYSON',
        RegAddrState: 'KY',
        RegAddrZip: 41143,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '585 WILLIAMS BRANCH RD',
        RegAddrCity: 'HAZARD',
        RegAddrState: 'KY',
        RegAddrZip: 41701,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '390 MEMORY MOUNTAIN LN',
        RegAddrCity: 'HAZARD',
        RegAddrState: 'KY',
        RegAddrZip: 41701,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '369 BARGO HL',
        RegAddrCity: 'HEIDRICK',
        RegAddrState: 'KY',
        RegAddrZip: 40949,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '4027 HIGHWAY 15 S',
        RegAddrCity: 'JACKSON',
        RegAddrState: 'KY',
        RegAddrZip: 41339,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '155 BELLIARE DR',
        RegAddrCity: 'JENKINS',
        RegAddrState: 'KY',
        RegAddrZip: 41537,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '12226 HIGHWAY 805',
        RegAddrCity: 'JENKINS',
        RegAddrState: 'KY',
        RegAddrZip: 41537,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '180 TURKEY CRK',
        RegAddrCity: 'LANGLEY',
        RegAddrState: 'KY',
        RegAddrZip: 41645,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '2628 WILSON CRK',
        RegAddrCity: 'LANGLEY',
        RegAddrState: 'KY',
        RegAddrZip: 41645,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '132 HOLLOW HORN LN',
        RegAddrCity: 'LIVINGSTON',
        RegAddrState: 'KY',
        RegAddrZip: 40445,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '54 TORINO LN',
        RegAddrCity: 'LIVINGSTON',
        RegAddrState: 'KY',
        RegAddrZip: 40445,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '7308 JOHNSON RD',
        RegAddrCity: 'LONDON',
        RegAddrState: 'KY',
        RegAddrZip: 40741,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '790 BLACKWATER CHURCH RD',
        RegAddrCity: 'LONDON',
        RegAddrState: 'KY',
        RegAddrZip: 40744,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '790 BLAKELY RD',
        RegAddrCity: 'LONDON',
        RegAddrState: 'KY',
        RegAddrZip: 40744,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '177 HUFFMAN LN',
        RegAddrCity: 'LONDON',
        RegAddrState: 'KY',
        RegAddrZip: 40744,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '105 GUY GREENE RD',
        RegAddrCity: 'LONDON',
        RegAddrState: 'KY',
        RegAddrZip: 40744,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '6210 BARBOURVILLE RD',
        RegAddrCity: 'LONDON',
        RegAddrState: 'KY',
        RegAddrZip: 40744,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '617 LOWER RIVER CANEY RD',
        RegAddrCity: 'LOST CREEK',
        RegAddrState: 'KY',
        RegAddrZip: 41348,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '3789 S HIGHWAY 3',
        RegAddrCity: 'LOUISA',
        RegAddrState: 'KY',
        RegAddrZip: 41230,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '1364 OLD FALLSBURG RD',
        RegAddrCity: 'LOUISA',
        RegAddrState: 'KY',
        RegAddrZip: 41230,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '624 HIGHWAY 644',
        RegAddrCity: 'LOUISA',
        RegAddrState: 'KY',
        RegAddrZip: 41230,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '4364 RIVER FRONT RD',
        RegAddrCity: 'LOVELY',
        RegAddrState: 'KY',
        RegAddrZip: 41231,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: 'RR 292',
        RegAddrCity: 'LOVELY',
        RegAddrState: 'KY',
        RegAddrZip: 41231,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '211 W MAIN ST',
        RegAddrCity: 'LYNCH',
        RegAddrState: 'KY',
        RegAddrZip: 40855,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '270 FINANCE HOLW',
        RegAddrCity: 'MARTIN',
        RegAddrState: 'KY',
        RegAddrZip: 41649,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '3884 HIGHWAY 119 N',
        RegAddrCity: 'MAYKING',
        RegAddrState: 'KY',
        RegAddrZip: 41837,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '2286 POND CREEK RD',
        RegAddrCity: 'MC ANDREWS',
        RegAddrState: 'KY',
        RegAddrZip: 41543,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '1526 HIGHWAY 89 N',
        RegAddrCity: 'MC KEE',
        RegAddrState: 'KY',
        RegAddrZip: 40447,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '3415 EBERLE RD',
        RegAddrCity: 'MCKEE',
        RegAddrState: 'KY',
        RegAddrZip: 40447,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '4401 W CUMBERLAND AVE',
        RegAddrCity: 'MIDDLESBORO',
        RegAddrState: 'KY',
        RegAddrZip: 40965,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: 'PO BOX 352',
        RegAddrCity: 'MIDDLESBORO',
        RegAddrState: 'KY',
        RegAddrZip: 40965,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '48 MARTIN MIRACLE RD',
        RegAddrCity: 'MIRACLE',
        RegAddrState: 'KY',
        RegAddrZip: 40856,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '127 LOCKETT ST',
        RegAddrCity: 'MONTICELLO',
        RegAddrState: 'KY',
        RegAddrZip: 42633,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '336 BARTLESTON SUBDIVISION RD',
        RegAddrCity: 'MONTICELLO',
        RegAddrState: 'KY',
        RegAddrZip: 42633,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '536 FRAZER LOOP',
        RegAddrCity: 'MONTICELLO',
        RegAddrState: 'KY',
        RegAddrZip: 42633,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '477 VIRGINIA AVE',
        RegAddrCity: 'MONTICELLO',
        RegAddrState: 'KY',
        RegAddrZip: 42633,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '87 IDLEWOOD LN',
        RegAddrCity: 'MOREHEAD',
        RegAddrState: 'KY',
        RegAddrZip: 40351,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '102 CARTMELL HALL',
        RegAddrCity: 'MOREHEAD',
        RegAddrState: 'KY',
        RegAddrZip: 40351,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '416 UNIVERSITY BLVD',
        RegAddrCity: 'MOREHEAD',
        RegAddrState: 'KY',
        RegAddrZip: 40351,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '194 ISHMAEL LN',
        RegAddrCity: 'NANCY',
        RegAddrState: 'KY',
        RegAddrZip: 42544,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '704 COURT ST',
        RegAddrCity: 'PAINTSVILLE',
        RegAddrState: 'KY',
        RegAddrZip: 41240,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '410 STATE HIGHWAY 194 E',
        RegAddrCity: 'PHYLLIS',
        RegAddrState: 'KY',
        RegAddrZip: 41554,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '2855 COLLINS HWY',
        RegAddrCity: 'PIKEVILLE',
        RegAddrState: 'KY',
        RegAddrZip: 41501,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '445 KNOB FORK RD',
        RegAddrCity: 'PIKEVILLE',
        RegAddrState: 'KY',
        RegAddrZip: 41501,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '5645 JOES CREEK RD',
        RegAddrCity: 'PIKEVILLE',
        RegAddrState: 'KY',
        RegAddrZip: 41501,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '3640 CHLOE RD',
        RegAddrCity: 'PIKEVILLE',
        RegAddrState: 'KY',
        RegAddrZip: 41501,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '3282 KY ROUTE 321',
        RegAddrCity: 'PRESTONSBURG',
        RegAddrState: 'KY',
        RegAddrZip: 41653,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '14409 TRACE RD',
        RegAddrCity: 'RUSH',
        RegAddrState: 'KY',
        RegAddrZip: 41168,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '370 BRAD DR',
        RegAddrCity: 'SALYERSVILLE',
        RegAddrState: 'KY',
        RegAddrZip: 41465,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '2339 KY 557',
        RegAddrCity: 'SANDY HOOK',
        RegAddrState: 'KY',
        RegAddrZip: 41171,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '148 RIGHT GREASY CAMP ST',
        RegAddrCity: 'SHELBIANA',
        RegAddrState: 'KY',
        RegAddrZip: 41562,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '426 COLLEGE ST',
        RegAddrCity: 'SOMERSET',
        RegAddrState: 'KY',
        RegAddrZip: 42501,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '14900 HIGHWAY 192',
        RegAddrCity: 'SOMERSET',
        RegAddrState: 'KY',
        RegAddrZip: 42501,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '186 DENTON PHELPS RD',
        RegAddrCity: 'SOMERSET',
        RegAddrState: 'KY',
        RegAddrZip: 42501,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '4849 KY HIGHWAY 698',
        RegAddrCity: 'STANFORD',
        RegAddrState: 'KY',
        RegAddrZip: 40484,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '160 SUN VALLEY DR',
        RegAddrCity: 'STANFORD',
        RegAddrState: 'KY',
        RegAddrZip: 40484,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '111 PETTUS CT',
        RegAddrCity: 'STANFORD',
        RegAddrState: 'KY',
        RegAddrZip: 40484,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '1604 MULLEN FRK',
        RegAddrCity: 'STONE',
        RegAddrState: 'KY',
        RegAddrZip: 41567,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '182 BIG DADDY LN',
        RegAddrCity: 'THORNTON',
        RegAddrState: 'KY',
        RegAddrZip: 41855,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '931 KY ROUTE 993',
        RegAddrCity: 'TUTOR KEY',
        RegAddrState: 'KY',
        RegAddrZip: 41263,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '72 LITTLE ROBINSON CRK',
        RegAddrCity: 'VIRGIE',
        RegAddrState: 'KY',
        RegAddrZip: 41572,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '2444 JONES CREEK RD',
        RegAddrCity: 'WEST LIBERTY',
        RegAddrState: 'KY',
        RegAddrZip: 41472,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '820 OLD HIGHWAY 172',
        RegAddrCity: 'WEST LIBERTY',
        RegAddrState: 'KY',
        RegAddrZip: 41472,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '134 GRAND VIEW ACRES',
        RegAddrCity: 'WILLIAMSBURG',
        RegAddrState: 'KY',
        RegAddrZip: 40769,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '5340 E HIGHWAY 92',
        RegAddrCity: 'WILLIAMSBURG',
        RegAddrState: 'KY',
        RegAddrZip: 40769,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '1576 LITTLE INDIAN CREEK RD',
        RegAddrCity: 'WOODBINE',
        RegAddrState: 'KY',
        RegAddrZip: 40771,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '779 BIG BRANCH RD',
        RegAddrCity: 'WOOTON',
        RegAddrState: 'KY',
        RegAddrZip: 41776,
        CongressionalDistrict: 5,
      },
    ],
  },
  {
    name: 'KY-6',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/KY-6?ref=gh-pages',
    state: 'KY',
    state_and_number: 'KY-6',
    zip: 40311,
    congressionalDistrict: 6,
    addressesInDistrict: [
      {
        RegAddrLine1: '108 BLUE RIDGE DR',
        RegAddrCity: 'BEREA',
        RegAddrState: 'KY',
        RegAddrZip: 40403,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '1020 TREVOR DR',
        RegAddrCity: 'BEREA',
        RegAddrState: 'KY',
        RegAddrZip: 40403,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '260 PROSPECT ST',
        RegAddrCity: 'BEREA',
        RegAddrState: 'KY',
        RegAddrZip: 40403,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '309 HAMPTON HALL DR',
        RegAddrCity: 'BEREA',
        RegAddrState: 'KY',
        RegAddrZip: 40403,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '122 JACKSON ST',
        RegAddrCity: 'BEREA',
        RegAddrState: 'KY',
        RegAddrZip: 40403,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '2526 BATTLEFIELD MEMORIAL HWY',
        RegAddrCity: 'BEREA',
        RegAddrState: 'KY',
        RegAddrZip: 40403,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '196 LOGSTON LN',
        RegAddrCity: 'BEREA',
        RegAddrState: 'KY',
        RegAddrZip: 40403,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '201 GARR AVE',
        RegAddrCity: 'FLEMINGSBURG',
        RegAddrState: 'KY',
        RegAddrZip: 41041,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '1630 KELLEY PIKE',
        RegAddrCity: 'FLEMINGSBURG',
        RegAddrState: 'KY',
        RegAddrZip: 41041,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '217 TANGLEWOOD DR',
        RegAddrCity: 'FRANKFORT',
        RegAddrState: 'KY',
        RegAddrZip: 40601,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '1005 COTTONWOOD RD',
        RegAddrCity: 'FRANKFORT',
        RegAddrState: 'KY',
        RegAddrZip: 40601,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '309 HIAWATHA TRL',
        RegAddrCity: 'FRANKFORT',
        RegAddrState: 'KY',
        RegAddrZip: 40601,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '109 WINNING COLORS CT',
        RegAddrCity: 'GEORGETOWN',
        RegAddrState: 'KY',
        RegAddrZip: 40324,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '116 KINGSTON DR',
        RegAddrCity: 'GEORGETOWN',
        RegAddrState: 'KY',
        RegAddrZip: 40324,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '112 CREEKSIDE DR',
        RegAddrCity: 'GEORGETOWN',
        RegAddrState: 'KY',
        RegAddrZip: 40324,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '1015 PARKSIDE DR',
        RegAddrCity: 'GEORGETOWN',
        RegAddrState: 'KY',
        RegAddrZip: 40324,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '114 ANNE JENNINGS WAY',
        RegAddrCity: 'GEORGETOWN',
        RegAddrState: 'KY',
        RegAddrZip: 40324,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '1036 MARSHALL PARK DR',
        RegAddrCity: 'GEORGETOWN',
        RegAddrState: 'KY',
        RegAddrZip: 40324,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '60 PATSY RD',
        RegAddrCity: 'IRVINE',
        RegAddrState: 'KY',
        RegAddrZip: 40336,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '1361 HWY 1050',
        RegAddrCity: 'JEFFERSONVILLE',
        RegAddrState: 'KY',
        RegAddrZip: 40337,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '465 SAWMILL RD',
        RegAddrCity: 'JEFFERSONVLLE',
        RegAddrState: 'KY',
        RegAddrZip: 40337,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '1085 HARBOUR LN',
        RegAddrCity: 'LAWRENCEBURG',
        RegAddrState: 'KY',
        RegAddrZip: 40342,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '412 FORREST DR',
        RegAddrCity: 'LAWRENCEBURG',
        RegAddrState: 'KY',
        RegAddrZip: 40342,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '4006 BRIAR CREEK RD',
        RegAddrCity: 'LAWRENCEBURG',
        RegAddrState: 'KY',
        RegAddrZip: 40342,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '1027 MACLAND RD',
        RegAddrCity: 'LAWRENCEBURG',
        RegAddrState: 'KY',
        RegAddrZip: 40342,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '741 HEARTSBROOK LN',
        RegAddrCity: 'LEXINGTON',
        RegAddrState: 'KY',
        RegAddrZip: 40504,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '1806 BARWICK DR',
        RegAddrCity: 'LEXINGTON',
        RegAddrState: 'KY',
        RegAddrZip: 40505,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '358 FOREMAN AVE',
        RegAddrCity: 'LEXINGTON',
        RegAddrState: 'KY',
        RegAddrZip: 40508,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '620 REPUBLIC CT',
        RegAddrCity: 'LEXINGTON',
        RegAddrState: 'KY',
        RegAddrZip: 40509,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '2172 MARKET GARDEN LN',
        RegAddrCity: 'LEXINGTON',
        RegAddrState: 'KY',
        RegAddrZip: 40509,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '2421 GOOSEBERRY CIR',
        RegAddrCity: 'LEXINGTON',
        RegAddrState: 'KY',
        RegAddrZip: 40509,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '4605 MARLBERRY PL',
        RegAddrCity: 'LEXINGTON',
        RegAddrState: 'KY',
        RegAddrZip: 40509,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '1885 REDD RD',
        RegAddrCity: 'LEXINGTON',
        RegAddrState: 'KY',
        RegAddrZip: 40510,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '542 LIMA DR',
        RegAddrCity: 'LEXINGTON',
        RegAddrState: 'KY',
        RegAddrZip: 40511,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '2460 CALENDULA RD',
        RegAddrCity: 'LEXINGTON',
        RegAddrState: 'KY',
        RegAddrZip: 40511,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '1961 SANDERSVILLE RD',
        RegAddrCity: 'LEXINGTON',
        RegAddrState: 'KY',
        RegAddrZip: 40511,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '4391 BETHEL RD',
        RegAddrCity: 'LEXINGTON',
        RegAddrState: 'KY',
        RegAddrZip: 40511,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '2745 RED CLOVER LN',
        RegAddrCity: 'LEXINGTON',
        RegAddrState: 'KY',
        RegAddrZip: 40511,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '2032 GLADE LN',
        RegAddrCity: 'LEXINGTON',
        RegAddrState: 'KY',
        RegAddrZip: 40513,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '2382 HARRODS POINTE TRCE',
        RegAddrCity: 'LEXINGTON',
        RegAddrState: 'KY',
        RegAddrZip: 40514,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '6309 JACKS CREEK PIKE',
        RegAddrCity: 'LEXINGTON',
        RegAddrState: 'KY',
        RegAddrZip: 40515,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '4632 WINDSTAR WAY',
        RegAddrCity: 'LEXINGTON',
        RegAddrState: 'KY',
        RegAddrZip: 40515,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '3504 BROOKVIEW DR',
        RegAddrCity: 'LEXINGTON',
        RegAddrState: 'KY',
        RegAddrZip: 40517,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '3772 RED RIVER DR',
        RegAddrCity: 'LEXINGTON',
        RegAddrState: 'KY',
        RegAddrZip: 40517,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '106 BRIGADOON PKWY',
        RegAddrCity: 'LEXINGTON',
        RegAddrState: 'KY',
        RegAddrZip: 40517,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '3633 LEISURE CREEK CT',
        RegAddrCity: 'LEXINGTON',
        RegAddrState: 'KY',
        RegAddrZip: 40517,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '7154 LEISURE CT',
        RegAddrCity: 'LOUISVILLE',
        RegAddrState: 'KY',
        RegAddrZip: 40229,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '410 W MAIN ST',
        RegAddrCity: 'MT STERLING',
        RegAddrState: 'KY',
        RegAddrZip: 40353,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '121 APPERSON HTS',
        RegAddrCity: 'MT STERLING',
        RegAddrState: 'KY',
        RegAddrZip: 40353,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '2325 BETHEL RD',
        RegAddrCity: 'NICHOLASVILLE',
        RegAddrState: 'KY',
        RegAddrZip: 40356,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '212 MILLHOUSE DR',
        RegAddrCity: 'NICHOLASVILLE',
        RegAddrState: 'KY',
        RegAddrZip: 40356,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '213 RIDGECREST DR',
        RegAddrCity: 'NICHOLASVILLE',
        RegAddrState: 'KY',
        RegAddrZip: 40356,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '285 OAKLAND RD',
        RegAddrCity: 'OWINGSVILLE',
        RegAddrState: 'KY',
        RegAddrZip: 40360,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '204 TREADWAY DR',
        RegAddrCity: 'OWINGSVILLE',
        RegAddrState: 'KY',
        RegAddrZip: 40360,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '254 REDMON RD',
        RegAddrCity: 'PARIS',
        RegAddrState: 'KY',
        RegAddrZip: 40361,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '300 COLLEGE RD',
        RegAddrCity: 'PARIS',
        RegAddrState: 'KY',
        RegAddrZip: 40361,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '137 JEMIMA DR',
        RegAddrCity: 'RICHMOND',
        RegAddrState: 'KY',
        RegAddrZip: 40475,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '108 BELLEVUE DR',
        RegAddrCity: 'RICHMOND',
        RegAddrState: 'KY',
        RegAddrZip: 40475,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '123 E BROADWAY AVE',
        RegAddrCity: 'RICHMOND',
        RegAddrState: 'KY',
        RegAddrZip: 40475,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '3306 TATES CREEK RD',
        RegAddrCity: 'RICHMOND',
        RegAddrState: 'KY',
        RegAddrZip: 40475,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: 'PO BOX 118',
        RegAddrCity: 'ROGERS',
        RegAddrState: 'KY',
        RegAddrZip: 41365,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '948 DAVIS TURKEYFOOT RD',
        RegAddrCity: 'SADIEVILLE',
        RegAddrState: 'KY',
        RegAddrZip: 40370,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '2773 MOUNT PLEASANT RD',
        RegAddrCity: 'SHARPSBURG',
        RegAddrState: 'KY',
        RegAddrZip: 40374,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '694 N MAIN ST',
        RegAddrCity: 'STANTON',
        RegAddrState: 'KY',
        RegAddrZip: 40380,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '6202 FURNACE RD',
        RegAddrCity: 'STANTON',
        RegAddrState: 'KY',
        RegAddrZip: 40380,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '701 DELANEY WAY',
        RegAddrCity: 'VERSAILLES',
        RegAddrState: 'KY',
        RegAddrZip: 40383,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '118 GAYBOURNE WAY',
        RegAddrCity: 'VERSAILLES',
        RegAddrState: 'KY',
        RegAddrZip: 40383,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '136 WALNUT ST',
        RegAddrCity: 'VERSAILLES',
        RegAddrState: 'KY',
        RegAddrZip: 40383,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '2880 FORDS MILL RD',
        RegAddrCity: 'VERSAILLES',
        RegAddrState: 'KY',
        RegAddrZip: 40383,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '902 SCOTTS FERRY RD',
        RegAddrCity: 'VERSAILLES',
        RegAddrState: 'KY',
        RegAddrZip: 40383,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '170 FALLING BRANCH RD',
        RegAddrCity: 'WACO',
        RegAddrState: 'KY',
        RegAddrZip: 40385,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '3085 MCCLURE RD',
        RegAddrCity: 'WINCHESTER',
        RegAddrState: 'KY',
        RegAddrZip: 40391,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '301 NEWCASTLE LN',
        RegAddrCity: 'WINCHESTER',
        RegAddrState: 'KY',
        RegAddrZip: 40391,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '27 ALABAMA ST',
        RegAddrCity: 'WINCHESTER',
        RegAddrState: 'KY',
        RegAddrZip: 40391,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '116 DIXON WAY',
        RegAddrCity: 'WINCHESTER',
        RegAddrState: 'KY',
        RegAddrZip: 40391,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '646 LAURA DR',
        RegAddrCity: 'WINCHESTER',
        RegAddrState: 'KY',
        RegAddrZip: 40391,
        CongressionalDistrict: 6,
      },
    ],
  },
  {
    name: 'LA-1',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/LA-1?ref=gh-pages',
    state: 'LA',
    state_and_number: 'LA-1',
    zip: 70001,
    congressionalDistrict: 1,
    addressesInDistrict: [
      {
        RegAddrLine1: '70370 RAVINE ST',
        RegAddrCity: 'ABITA SPRINGS',
        RegAddrState: 'LA',
        RegAddrZip: 70420,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '73283 ABITA SPRINGS DR',
        RegAddrCity: 'ABITA SPRINGS',
        RegAddrState: 'LA',
        RegAddrZip: 70420,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '116 CONEY DR',
        RegAddrCity: 'ARABI',
        RegAddrState: 'LA',
        RegAddrZip: 70032,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '110 NU ST',
        RegAddrCity: 'BELLE CHASSE',
        RegAddrState: 'LA',
        RegAddrZip: 70037,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '126 RUE ACADIAN',
        RegAddrCity: 'BELLE CHASSE',
        RegAddrState: 'LA',
        RegAddrZip: 70037,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '101 RAVEY ST',
        RegAddrCity: 'BELLE CHASSE',
        RegAddrState: 'LA',
        RegAddrZip: 70037,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '8421 COLONEL DR',
        RegAddrCity: 'CHALMETTE',
        RegAddrState: 'LA',
        RegAddrZip: 70043,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2921 MUMPHREY RD',
        RegAddrCity: 'CHALMETTE',
        RegAddrState: 'LA',
        RegAddrZip: 70043,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '711 COVINGTON POINT DR',
        RegAddrCity: 'COVINGTON',
        RegAddrState: 'LA',
        RegAddrZip: 70433,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '810 W 16TH AVE',
        RegAddrCity: 'COVINGTON',
        RegAddrState: 'LA',
        RegAddrZip: 70433,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '72389 MAGNOLIA DR',
        RegAddrCity: 'COVINGTON',
        RegAddrState: 'LA',
        RegAddrZip: 70433,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '82093 QUEENIE LN',
        RegAddrCity: 'COVINGTON',
        RegAddrState: 'LA',
        RegAddrZip: 70435,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '199 NORTHRIDGE DR',
        RegAddrCity: 'COVINGTON',
        RegAddrState: 'LA',
        RegAddrZip: 70435,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '339 W 57TH ST',
        RegAddrCity: 'CUT OFF',
        RegAddrState: 'LA',
        RegAddrZip: 70345,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '15788 IRENE RD',
        RegAddrCity: 'FOLSOM',
        RegAddrState: 'LA',
        RegAddrZip: 70437,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1111 MADISON ST',
        RegAddrCity: 'GRETNA',
        RegAddrState: 'LA',
        RegAddrZip: 70053,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '111 MARLEY DR',
        RegAddrCity: 'HAMMOND',
        RegAddrState: 'LA',
        RegAddrZip: 70401,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '44071 SELSERS LN',
        RegAddrCity: 'HAMMOND',
        RegAddrState: 'LA',
        RegAddrZip: 70403,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1113 SMITH SQ',
        RegAddrCity: 'HAMMOND',
        RegAddrState: 'LA',
        RegAddrZip: 70403,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '142 DEL RIO DR',
        RegAddrCity: 'HOUMA',
        RegAddrState: 'LA',
        RegAddrZip: 70360,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '100 MILL QUARTERS DR',
        RegAddrCity: 'HOUMA',
        RegAddrState: 'LA',
        RegAddrZip: 70360,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '103 PETIT RUE DR',
        RegAddrCity: 'HOUMA',
        RegAddrState: 'LA',
        RegAddrZip: 70363,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '324 COACH CT',
        RegAddrCity: 'HOUMA',
        RegAddrState: 'LA',
        RegAddrZip: 70363,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '800 WRIGHT AVE',
        RegAddrCity: 'HOUMA',
        RegAddrState: 'LA',
        RegAddrZip: 70364,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '175 MEGAN DR',
        RegAddrCity: 'HOUMA',
        RegAddrState: 'LA',
        RegAddrZip: 70364,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2237 INDIANA AVE',
        RegAddrCity: 'KENNER',
        RegAddrState: 'LA',
        RegAddrZip: 70062,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '4308 INDIANA AVE',
        RegAddrCity: 'KENNER',
        RegAddrState: 'LA',
        RegAddrZip: 70065,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '7 CHATEAU PONTET CANET DR',
        RegAddrCity: 'KENNER',
        RegAddrState: 'LA',
        RegAddrZip: 70065,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '3269 PRESIDENTIAL DR',
        RegAddrCity: 'KENNER',
        RegAddrState: 'LA',
        RegAddrZip: 70065,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '4831 JEAN LAFITTE BLVD',
        RegAddrCity: 'LAFITTE',
        RegAddrState: 'LA',
        RegAddrZip: 70067,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '3929 THORNTON ST',
        RegAddrCity: 'LAKE CHARLES',
        RegAddrState: 'LA',
        RegAddrZip: 70615,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '207 FRENCH TURN CT',
        RegAddrCity: 'LAROSE',
        RegAddrState: 'LA',
        RegAddrZip: 70373,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1704 ROMY DR',
        RegAddrCity: 'LOCKPORT',
        RegAddrState: 'LA',
        RegAddrZip: 70374,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '109 COVINGTON ST',
        RegAddrCity: 'MADISONVILLE',
        RegAddrState: 'LA',
        RegAddrZip: 70447,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '78 RIVER BLUFF DR',
        RegAddrCity: 'MADISONVILLE',
        RegAddrState: 'LA',
        RegAddrZip: 70447,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1443 FOX CT',
        RegAddrCity: 'MANDEVILLE',
        RegAddrState: 'LA',
        RegAddrZip: 70448,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '57 COLONY TRAIL DR',
        RegAddrCity: 'MANDEVILLE',
        RegAddrState: 'LA',
        RegAddrZip: 70448,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '8055 WINNERS CIR',
        RegAddrCity: 'MANDEVILLE',
        RegAddrState: 'LA',
        RegAddrZip: 70448,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '114 LAZY CREEK DR',
        RegAddrCity: 'MANDEVILLE',
        RegAddrState: 'LA',
        RegAddrZip: 70471,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '613 CANARY PINE CT',
        RegAddrCity: 'MANDEVILLE',
        RegAddrState: 'LA',
        RegAddrZip: 70471,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2600 SANDPIPER CIR',
        RegAddrCity: 'MARRERO',
        RegAddrState: 'LA',
        RegAddrZip: 70072,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1704 GIUFFRIAS AVE',
        RegAddrCity: 'METAIRIE',
        RegAddrState: 'LA',
        RegAddrZip: 70001,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '4808 EVANGELINE ST',
        RegAddrCity: 'METAIRIE',
        RegAddrState: 'LA',
        RegAddrZip: 70001,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '4208 GIRARD ST',
        RegAddrCity: 'METAIRIE',
        RegAddrState: 'LA',
        RegAddrZip: 70001,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '604 RIDGEWOOD DR',
        RegAddrCity: 'METAIRIE',
        RegAddrState: 'LA',
        RegAddrZip: 70001,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '4520 CARTHAGE ST',
        RegAddrCity: 'METAIRIE',
        RegAddrState: 'LA',
        RegAddrZip: 70002,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '5104 ALEXANDER DR',
        RegAddrCity: 'METAIRIE',
        RegAddrState: 'LA',
        RegAddrZip: 70003,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '6309 CARMEN ST',
        RegAddrCity: 'METAIRIE',
        RegAddrState: 'LA',
        RegAddrZip: 70003,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2509 MARGIE ST',
        RegAddrCity: 'METAIRIE',
        RegAddrState: 'LA',
        RegAddrZip: 70003,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '5029 BURKE DR',
        RegAddrCity: 'METAIRIE',
        RegAddrState: 'LA',
        RegAddrZip: 70003,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '6109 FLOWER DR',
        RegAddrCity: 'METAIRIE',
        RegAddrState: 'LA',
        RegAddrZip: 70003,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '5801 VIRGINIA PL',
        RegAddrCity: 'METAIRIE',
        RegAddrState: 'LA',
        RegAddrZip: 70003,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '125 SYCAMORE DR',
        RegAddrCity: 'METAIRIE',
        RegAddrState: 'LA',
        RegAddrZip: 70005,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '215 VINCENT AVE',
        RegAddrCity: 'METAIRIE',
        RegAddrState: 'LA',
        RegAddrZip: 70005,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '4712 KAWANEE AVE',
        RegAddrCity: 'METAIRIE',
        RegAddrState: 'LA',
        RegAddrZip: 70006,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1021 AUDUBON ST',
        RegAddrCity: 'NEW ORLEANS',
        RegAddrState: 'LA',
        RegAddrZip: 70118,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2113 CALHOUN ST',
        RegAddrCity: 'NEW ORLEANS',
        RegAddrState: 'LA',
        RegAddrZip: 70118,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '6145 ARGONNE BLVD',
        RegAddrCity: 'NEW ORLEANS',
        RegAddrState: 'LA',
        RegAddrZip: 70124,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '5544 ROSEMARY PL',
        RegAddrCity: 'NEW ORLEANS',
        RegAddrState: 'LA',
        RegAddrZip: 70124,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '509 POLK ST',
        RegAddrCity: 'NEW ORLEANS',
        RegAddrState: 'LA',
        RegAddrZip: 70124,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '20266 CLEMSON WAY',
        RegAddrCity: 'PONCHATOULA',
        RegAddrState: 'LA',
        RegAddrZip: 70454,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1092 N HAVEN DR',
        RegAddrCity: 'PONCHATOULA',
        RegAddrState: 'LA',
        RegAddrZip: 70454,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '39266 RIDGDELL RD',
        RegAddrCity: 'PONCHATOULA',
        RegAddrState: 'LA',
        RegAddrZip: 70454,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '4 KINDER LN',
        RegAddrCity: 'RIVER RIDGE',
        RegAddrState: 'LA',
        RegAddrZip: 70123,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '872 ASHEVILLE DR',
        RegAddrCity: 'SLIDELL',
        RegAddrState: 'LA',
        RegAddrZip: 70458,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1605 CUTTYSARK CV',
        RegAddrCity: 'SLIDELL',
        RegAddrState: 'LA',
        RegAddrZip: 70458,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '125 HEATHER DR',
        RegAddrCity: 'SLIDELL',
        RegAddrState: 'LA',
        RegAddrZip: 70458,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '220 MERRIMAC DR',
        RegAddrCity: 'SLIDELL',
        RegAddrState: 'LA',
        RegAddrZip: 70460,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '35147 LAURENT RD',
        RegAddrCity: 'SLIDELL',
        RegAddrState: 'LA',
        RegAddrZip: 70460,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1405 CHERRY ST',
        RegAddrCity: 'SLIDELL',
        RegAddrState: 'LA',
        RegAddrZip: 70460,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1234 SAINT CHRISTOPHER ST',
        RegAddrCity: 'SLIDELL',
        RegAddrState: 'LA',
        RegAddrZip: 70460,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '127 RUE ESPLANADE',
        RegAddrCity: 'SLIDELL',
        RegAddrState: 'LA',
        RegAddrZip: 70461,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '215 BROOKTER ST',
        RegAddrCity: 'SLIDELL',
        RegAddrState: 'LA',
        RegAddrZip: 70461,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '40738 HAYES RD',
        RegAddrCity: 'SLIDELL',
        RegAddrState: 'LA',
        RegAddrZip: 70461,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '317 BROOKHAVEN CT',
        RegAddrCity: 'SLIDELL',
        RegAddrState: 'LA',
        RegAddrZip: 70461,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '109 PALM SWIFT DR',
        RegAddrCity: 'SLIDELL',
        RegAddrState: 'LA',
        RegAddrZip: 70461,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '70 RANDAZZO DR',
        RegAddrCity: 'ST BERNARD',
        RegAddrState: 'LA',
        RegAddrZip: 70085,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '16307 TYCER LN',
        RegAddrCity: 'TICKFAW',
        RegAddrState: 'LA',
        RegAddrZip: 70466,
        CongressionalDistrict: 1,
      },
    ],
  },
  {
    name: 'LA-2',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/LA-2?ref=gh-pages',
    state: 'LA',
    state_and_number: 'LA-2',
    zip: 70031,
    congressionalDistrict: 2,
    addressesInDistrict: [
      {
        RegAddrLine1: '844 EDDIE ROBINSON SR DR',
        RegAddrCity: 'BATON ROUGE',
        RegAddrState: 'LA',
        RegAddrZip: 70802,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1816 N 17TH ST',
        RegAddrCity: 'BATON ROUGE',
        RegAddrState: 'LA',
        RegAddrZip: 70802,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '4104 TUSCARORA ST',
        RegAddrCity: 'BATON ROUGE',
        RegAddrState: 'LA',
        RegAddrZip: 70805,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '4040 W BROOKSTOWN DR',
        RegAddrCity: 'BATON ROUGE',
        RegAddrState: 'LA',
        RegAddrZip: 70805,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '3755 WYANDOTTE ST',
        RegAddrCity: 'BATON ROUGE',
        RegAddrState: 'LA',
        RegAddrZip: 70805,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '5079 JACKSON AVE',
        RegAddrCity: 'BATON ROUGE',
        RegAddrState: 'LA',
        RegAddrZip: 70806,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '7649 PHEBUS DR',
        RegAddrCity: 'BATON ROUGE',
        RegAddrState: 'LA',
        RegAddrZip: 70812,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '6555 SUMRALL DR',
        RegAddrCity: 'BATON ROUGE',
        RegAddrState: 'LA',
        RegAddrZip: 70812,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '0 SU SHADE HALL 106D',
        RegAddrCity: 'BATON ROUGE',
        RegAddrState: 'LA',
        RegAddrZip: 70813,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '7035 HIGHWAY 1 A',
        RegAddrCity: 'BELLE ROSE',
        RegAddrState: 'LA',
        RegAddrZip: 70341,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '357 WOODLAND DR',
        RegAddrCity: 'BOUTTE',
        RegAddrState: 'LA',
        RegAddrZip: 70039,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '3343 RANDALL GAY ST',
        RegAddrCity: 'BRUSLY',
        RegAddrState: 'LA',
        RegAddrZip: 70719,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '4377 HIGHWAY 306',
        RegAddrCity: 'DES ALLEMANDS',
        RegAddrState: 'LA',
        RegAddrZip: 70030,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1407 MCKINLEY ST',
        RegAddrCity: 'DONALDSONVILLE',
        RegAddrState: 'LA',
        RegAddrZip: 70346,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '7508 HIGHWAY 1 S',
        RegAddrCity: 'DONALDSONVLLE',
        RegAddrState: 'LA',
        RegAddrZip: 70346,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '3535 HIGHWAY 1 S',
        RegAddrCity: 'DONALDSONVLLE',
        RegAddrState: 'LA',
        RegAddrZip: 70346,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1501 E SILVERLEAF ST',
        RegAddrCity: 'GONZALES',
        RegAddrState: 'LA',
        RegAddrZip: 70737,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '176 WILLOW DR',
        RegAddrCity: 'GRETNA',
        RegAddrState: 'LA',
        RegAddrZip: 70053,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1408 MONROE ST',
        RegAddrCity: 'GRETNA',
        RegAddrState: 'LA',
        RegAddrZip: 70053,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '119 LAVOISIER ST',
        RegAddrCity: 'GRETNA',
        RegAddrState: 'LA',
        RegAddrZip: 70053,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '252 DALE AVE',
        RegAddrCity: 'GRETNA',
        RegAddrState: 'LA',
        RegAddrZip: 70056,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '613 GROVEWOOD DR',
        RegAddrCity: 'GRETNA',
        RegAddrState: 'LA',
        RegAddrZip: 70056,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2068 VINEYARD LN',
        RegAddrCity: 'HARVEY',
        RegAddrState: 'LA',
        RegAddrZip: 70058,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '3729 BURNTWOOD DR',
        RegAddrCity: 'HARVEY',
        RegAddrState: 'LA',
        RegAddrZip: 70058,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '3805 TIMBERVIEW LN',
        RegAddrCity: 'HARVEY',
        RegAddrState: 'LA',
        RegAddrZip: 70058,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1519 KINGS RD',
        RegAddrCity: 'HARVEY',
        RegAddrState: 'LA',
        RegAddrZip: 70058,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '525 27TH ST',
        RegAddrCity: 'KENNER',
        RegAddrState: 'LA',
        RegAddrZip: 70062,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2209 TIFFANY DR',
        RegAddrCity: 'LA PLACE',
        RegAddrState: 'LA',
        RegAddrZip: 70068,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1964 REBECCA LN',
        RegAddrCity: 'LA PLACE',
        RegAddrState: 'LA',
        RegAddrZip: 70068,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '115 OAK LN',
        RegAddrCity: 'LULING',
        RegAddrState: 'LA',
        RegAddrZip: 70070,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '229 MIMOSA AVE',
        RegAddrCity: 'LULING',
        RegAddrState: 'LA',
        RegAddrZip: 70070,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '766 JUSTICE CT',
        RegAddrCity: 'MARRERO',
        RegAddrState: 'LA',
        RegAddrZip: 70072,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '5173 OAK BAYOU AVE',
        RegAddrCity: 'MARRERO',
        RegAddrState: 'LA',
        RegAddrZip: 70072,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2331 2ND ST',
        RegAddrCity: 'NEW ORLEANS',
        RegAddrState: 'LA',
        RegAddrZip: 70113,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '229 ALIX ST',
        RegAddrCity: 'NEW ORLEANS',
        RegAddrState: 'LA',
        RegAddrZip: 70114,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '3315 DIANA ST',
        RegAddrCity: 'NEW ORLEANS',
        RegAddrState: 'LA',
        RegAddrZip: 70114,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '318 BERMUDA ST',
        RegAddrCity: 'NEW ORLEANS',
        RegAddrState: 'LA',
        RegAddrZip: 70114,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '3486 VESPASIAN BLVD',
        RegAddrCity: 'NEW ORLEANS',
        RegAddrState: 'LA',
        RegAddrZip: 70114,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1436 TOLEDANO ST',
        RegAddrCity: 'NEW ORLEANS',
        RegAddrState: 'LA',
        RegAddrZip: 70115,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2415 TOLEDANO ST',
        RegAddrCity: 'NEW ORLEANS',
        RegAddrState: 'LA',
        RegAddrZip: 70115,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1816 MILAN ST',
        RegAddrCity: 'NEW ORLEANS',
        RegAddrState: 'LA',
        RegAddrZip: 70115,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2323 LOUISIANA AVE',
        RegAddrCity: 'NEW ORLEANS',
        RegAddrState: 'LA',
        RegAddrZip: 70115,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1932 DELACHAISE ST',
        RegAddrCity: 'NEW ORLEANS',
        RegAddrState: 'LA',
        RegAddrZip: 70115,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2520 ALABO ST',
        RegAddrCity: 'NEW ORLEANS',
        RegAddrState: 'LA',
        RegAddrZip: 70117,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1529 LESSEPS ST',
        RegAddrCity: 'NEW ORLEANS',
        RegAddrState: 'LA',
        RegAddrZip: 70117,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '7515 DOMINICAN ST',
        RegAddrCity: 'NEW ORLEANS',
        RegAddrState: 'LA',
        RegAddrZip: 70118,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1721 EAGLE ST',
        RegAddrCity: 'NEW ORLEANS',
        RegAddrState: 'LA',
        RegAddrZip: 70118,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '8220 SYCAMORE PL',
        RegAddrCity: 'NEW ORLEANS',
        RegAddrState: 'LA',
        RegAddrZip: 70118,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '139 S DUPRE ST',
        RegAddrCity: 'NEW ORLEANS',
        RegAddrState: 'LA',
        RegAddrZip: 70119,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2055 N ROCHEBLAVE ST',
        RegAddrCity: 'NEW ORLEANS',
        RegAddrState: 'LA',
        RegAddrZip: 70119,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '3214 IBERVILLE ST',
        RegAddrCity: 'NEW ORLEANS',
        RegAddrState: 'LA',
        RegAddrZip: 70119,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1711 N GALVEZ ST',
        RegAddrCity: 'NEW ORLEANS',
        RegAddrState: 'LA',
        RegAddrZip: 70119,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '5326 WINGATE DR',
        RegAddrCity: 'NEW ORLEANS',
        RegAddrState: 'LA',
        RegAddrZip: 70122,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '7608 EBBTIDE DR',
        RegAddrCity: 'NEW ORLEANS',
        RegAddrState: 'LA',
        RegAddrZip: 70126,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '3939 GENTILLY BLVD',
        RegAddrCity: 'NEW ORLEANS',
        RegAddrState: 'LA',
        RegAddrZip: 70126,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '7703 WALES ST',
        RegAddrCity: 'NEW ORLEANS',
        RegAddrState: 'LA',
        RegAddrZip: 70126,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '7143 BUNKER HILL RD',
        RegAddrCity: 'NEW ORLEANS',
        RegAddrState: 'LA',
        RegAddrZip: 70127,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '4750 HAUCK DR',
        RegAddrCity: 'NEW ORLEANS',
        RegAddrState: 'LA',
        RegAddrZip: 70127,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '7661 PEBBLE DR',
        RegAddrCity: 'NEW ORLEANS',
        RegAddrState: 'LA',
        RegAddrZip: 70128,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '5521 PATIO WAY',
        RegAddrCity: 'NEW ORLEANS',
        RegAddrState: 'LA',
        RegAddrZip: 70129,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '5129 WILLOWBROOK DR',
        RegAddrCity: 'NEW ORLEANS',
        RegAddrState: 'LA',
        RegAddrZip: 70129,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '3708 PRESTON PL',
        RegAddrCity: 'NEW ORLEANS',
        RegAddrState: 'LA',
        RegAddrZip: 70131,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '3800 S POST OAK AVE',
        RegAddrCity: 'NEW ORLEANS',
        RegAddrState: 'LA',
        RegAddrZip: 70131,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '3680 PLYMOUTH PL',
        RegAddrCity: 'NEW ORLEANS',
        RegAddrState: 'LA',
        RegAddrZip: 70131,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '15095 HIGHWAY 631',
        RegAddrCity: 'PARADIS',
        RegAddrState: 'LA',
        RegAddrZip: 70080,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '3456 LA 44',
        RegAddrCity: 'PAULINA',
        RegAddrState: 'LA',
        RegAddrZip: 70763,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '23905 JASE ST',
        RegAddrCity: 'PLAQUEMINE',
        RegAddrState: 'LA',
        RegAddrZip: 70764,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '22571 ONEIL RD',
        RegAddrCity: 'PLAQUEMINE',
        RegAddrState: 'LA',
        RegAddrZip: 70764,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1079 OREGON AVE',
        RegAddrCity: 'PORT ALLEN',
        RegAddrState: 'LA',
        RegAddrZip: 70767,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '234 W 1ST ST',
        RegAddrCity: 'RESERVE',
        RegAddrState: 'LA',
        RegAddrZip: 70084,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '156 ANNEX DR',
        RegAddrCity: 'RESERVE',
        RegAddrState: 'LA',
        RegAddrZip: 70084,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '6400 MARGARET DR',
        RegAddrCity: 'SAINT GABRIEL',
        RegAddrState: 'LA',
        RegAddrZip: 70776,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '145 TERRY PKWY',
        RegAddrCity: 'TERRYTOWN',
        RegAddrState: 'LA',
        RegAddrZip: 70056,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '20 CLIFFORD CT',
        RegAddrCity: 'WAGGAMAN',
        RegAddrState: 'LA',
        RegAddrZip: 70094,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '712 AZALEA DR',
        RegAddrCity: 'WESTWEGO',
        RegAddrState: 'LA',
        RegAddrZip: 70094,
        CongressionalDistrict: 2,
      },
    ],
  },
  {
    name: 'LA-3',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/LA-3?ref=gh-pages',
    state: 'LA',
    state_and_number: 'LA-3',
    zip: 70339,
    congressionalDistrict: 3,
    addressesInDistrict: [
      {
        RegAddrLine1: '14935 S GUEGNON ST',
        RegAddrCity: 'ABBEVILLE',
        RegAddrState: 'LA',
        RegAddrZip: 70510,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '19728 ZEITZ RD',
        RegAddrCity: 'ABBEVILLE',
        RegAddrState: 'LA',
        RegAddrZip: 70510,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '8006 US HIGHWAY 167',
        RegAddrCity: 'ABBEVILLE',
        RegAddrState: 'LA',
        RegAddrZip: 70510,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '206 LOVERS LN',
        RegAddrCity: 'ABBEVILLE',
        RegAddrState: 'LA',
        RegAddrZip: 70510,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '121 ELAINE ST',
        RegAddrCity: 'ABBEVILLE',
        RegAddrState: 'LA',
        RegAddrZip: 70510,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '730 VETERANS MEMORIAL DR',
        RegAddrCity: 'ABBEVILLE',
        RegAddrState: 'LA',
        RegAddrZip: 70510,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '7114 WHITE OAK HWY',
        RegAddrCity: 'BRANCH',
        RegAddrState: 'LA',
        RegAddrZip: 70516,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1017 LATIOLAIS LOOP',
        RegAddrCity: 'BREAUX BRIDGE',
        RegAddrState: 'LA',
        RegAddrZip: 70517,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '529 NICHOLAS ST',
        RegAddrCity: 'BREAUX BRIDGE',
        RegAddrState: 'LA',
        RegAddrZip: 70517,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '203 COURVILLE ST',
        RegAddrCity: 'BREAUX BRIDGE',
        RegAddrState: 'LA',
        RegAddrZip: 70517,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '103 ADALINE ST',
        RegAddrCity: 'CARENCRO',
        RegAddrState: 'LA',
        RegAddrZip: 70520,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '201 RAINBOW ST',
        RegAddrCity: 'CARENCRO',
        RegAddrState: 'LA',
        RegAddrZip: 70520,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '112 GREENFIELD DR',
        RegAddrCity: 'CARENCRO',
        RegAddrState: 'LA',
        RegAddrZip: 70520,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '420 POPE DR',
        RegAddrCity: 'CARENCRO',
        RegAddrState: 'LA',
        RegAddrZip: 70520,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '524 S MCMILLAN ST',
        RegAddrCity: 'CHURCH POINT',
        RegAddrState: 'LA',
        RegAddrZip: 70525,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '196 BUCK SWEENEY DR',
        RegAddrCity: 'CROWLEY',
        RegAddrState: 'LA',
        RegAddrZip: 70526,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1018 E 4TH ST',
        RegAddrCity: 'CROWLEY',
        RegAddrState: 'LA',
        RegAddrZip: 70526,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1127 N AVENUE K',
        RegAddrCity: 'CROWLEY',
        RegAddrState: 'LA',
        RegAddrZip: 70526,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '11 W BAYOU DR',
        RegAddrCity: 'CROWLEY',
        RegAddrState: 'LA',
        RegAddrZip: 70526,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '407 COLE ST',
        RegAddrCity: 'DEQUINCY',
        RegAddrState: 'LA',
        RegAddrZip: 70633,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '103 N MAIN ST',
        RegAddrCity: 'DEQUINCY',
        RegAddrState: 'LA',
        RegAddrZip: 70633,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1589 ATTEBERRY RD',
        RegAddrCity: 'EUNICE',
        RegAddrState: 'LA',
        RegAddrZip: 70535,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '418 9TH ST',
        RegAddrCity: 'FRANKLIN',
        RegAddrState: 'LA',
        RegAddrZip: 70538,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '404 HIGHWAY 317',
        RegAddrCity: 'FRANKLIN',
        RegAddrState: 'LA',
        RegAddrZip: 70538,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '115 ISABELLA ST',
        RegAddrCity: 'FRANKLIN',
        RegAddrState: 'LA',
        RegAddrZip: 70538,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '7503 HIGHWAY 182',
        RegAddrCity: 'FRANKLIN',
        RegAddrState: 'LA',
        RegAddrZip: 70538,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '5758 HIGHLAND HILLS BLVD',
        RegAddrCity: 'IOWA',
        RegAddrState: 'LA',
        RegAddrZip: 70647,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '20469 BOURQUE RD',
        RegAddrCity: 'IOWA',
        RegAddrState: 'LA',
        RegAddrZip: 70647,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '621 CHESTNUT ST',
        RegAddrCity: 'IOWA',
        RegAddrState: 'LA',
        RegAddrZip: 70647,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '2106 TIME ST',
        RegAddrCity: 'JEANERETTE',
        RegAddrState: 'LA',
        RegAddrZip: 70544,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1022 ROLLIN ACRES RD',
        RegAddrCity: 'JENNINGS',
        RegAddrState: 'LA',
        RegAddrZip: 70546,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '515 MAY ST',
        RegAddrCity: 'JENNINGS',
        RegAddrState: 'LA',
        RegAddrZip: 70546,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '718 2ND ST',
        RegAddrCity: 'JENNINGS',
        RegAddrState: 'LA',
        RegAddrZip: 70546,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '201 SIMON CV',
        RegAddrCity: 'JENNINGS',
        RegAddrState: 'LA',
        RegAddrZip: 70546,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '603 ACADIAN DR',
        RegAddrCity: 'JENNINGS',
        RegAddrState: 'LA',
        RegAddrZip: 70546,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '19116 DULVA RD',
        RegAddrCity: 'KAPLAN',
        RegAddrState: 'LA',
        RegAddrZip: 70548,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '403 HAIG ST',
        RegAddrCity: 'LAFAYETTE',
        RegAddrState: 'LA',
        RegAddrZip: 70501,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '102 WILKINS ST',
        RegAddrCity: 'LAFAYETTE',
        RegAddrState: 'LA',
        RegAddrZip: 70501,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '411 GUIDRY ST',
        RegAddrCity: 'LAFAYETTE',
        RegAddrState: 'LA',
        RegAddrZip: 70501,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '118 ANTIGUA DR',
        RegAddrCity: 'LAFAYETTE',
        RegAddrState: 'LA',
        RegAddrZip: 70503,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '108 HERMITAGE AVE',
        RegAddrCity: 'LAFAYETTE',
        RegAddrState: 'LA',
        RegAddrZip: 70503,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '724 ALICE DR',
        RegAddrCity: 'LAFAYETTE',
        RegAddrState: 'LA',
        RegAddrZip: 70503,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '226 ELMWOOD DR',
        RegAddrCity: 'LAFAYETTE',
        RegAddrState: 'LA',
        RegAddrZip: 70503,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '404 WHITE OAK DR',
        RegAddrCity: 'LAFAYETTE',
        RegAddrState: 'LA',
        RegAddrZip: 70506,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '106 ROSEWOOD AVE',
        RegAddrCity: 'LAFAYETTE',
        RegAddrState: 'LA',
        RegAddrZip: 70506,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '900 ROBERT LEE CIR',
        RegAddrCity: 'LAFAYETTE',
        RegAddrState: 'LA',
        RegAddrZip: 70506,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '120 OAKBEND DR',
        RegAddrCity: 'LAFAYETTE',
        RegAddrState: 'LA',
        RegAddrZip: 70506,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '40 PHLOX DR',
        RegAddrCity: 'LAFAYETTE',
        RegAddrState: 'LA',
        RegAddrZip: 70507,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '211 MITZE DR',
        RegAddrCity: 'LAFAYETTE',
        RegAddrState: 'LA',
        RegAddrZip: 70507,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '216 ROBINHOOD CIR',
        RegAddrCity: 'LAFAYETTE',
        RegAddrState: 'LA',
        RegAddrZip: 70508,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1718 E MILTON AVE',
        RegAddrCity: 'LAFAYETTE',
        RegAddrState: 'LA',
        RegAddrZip: 70508,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '108 BOULDER DR',
        RegAddrCity: 'LAFAYETTE',
        RegAddrState: 'LA',
        RegAddrZip: 70508,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '107 SUNSWEPT BRIDGE DR',
        RegAddrCity: 'LAFAYETTE',
        RegAddrState: 'LA',
        RegAddrZip: 70508,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '107 SHELL BEACH DR',
        RegAddrCity: 'LAKE CHARLES',
        RegAddrState: 'LA',
        RegAddrZip: 70601,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1800 8TH AVE',
        RegAddrCity: 'LAKE CHARLES',
        RegAddrState: 'LA',
        RegAddrZip: 70601,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '806 14TH ST',
        RegAddrCity: 'LAKE CHARLES',
        RegAddrState: 'LA',
        RegAddrZip: 70601,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '2340 S KINGSWOOD ST',
        RegAddrCity: 'LAKE CHARLES',
        RegAddrState: 'LA',
        RegAddrZip: 70605,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '3828 JANET LN',
        RegAddrCity: 'LAKE CHARLES',
        RegAddrState: 'LA',
        RegAddrZip: 70605,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '3546 KNIGHT LN',
        RegAddrCity: 'LAKE CHARLES',
        RegAddrState: 'LA',
        RegAddrZip: 70605,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '4280 PERSIMMON WAY',
        RegAddrCity: 'LAKE CHARLES',
        RegAddrState: 'LA',
        RegAddrZip: 70605,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1436 MITCHELL ST',
        RegAddrCity: 'LAKE CHARLES',
        RegAddrState: 'LA',
        RegAddrZip: 70607,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '4025 W BRIARFIELD ST',
        RegAddrCity: 'LAKE CHARLES',
        RegAddrState: 'LA',
        RegAddrZip: 70607,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '2302 SMITH RD',
        RegAddrCity: 'LAKE CHARLES',
        RegAddrState: 'LA',
        RegAddrZip: 70607,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '8511 GULF HWY',
        RegAddrCity: 'LAKE CHARLES',
        RegAddrState: 'LA',
        RegAddrZip: 70607,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1389 TRISHA CT',
        RegAddrCity: 'LAKE CHARLES',
        RegAddrState: 'LA',
        RegAddrZip: 70611,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '2536 PHILS LN',
        RegAddrCity: 'LAKE CHARLES',
        RegAddrState: 'LA',
        RegAddrZip: 70611,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '2808 B NAQUIN RD',
        RegAddrCity: 'MAMOU',
        RegAddrState: 'LA',
        RegAddrZip: 70554,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '6209 WILSON RD',
        RegAddrCity: 'MAURICE',
        RegAddrState: 'LA',
        RegAddrZip: 70555,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1825 FILMORE ST',
        RegAddrCity: 'MORGAN CITY',
        RegAddrState: 'LA',
        RegAddrZip: 70380,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '3007 KEITH ST',
        RegAddrCity: 'MORGAN CITY',
        RegAddrState: 'LA',
        RegAddrZip: 70380,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '317 ORANGE GROVE DR',
        RegAddrCity: 'NEW IBERIA',
        RegAddrState: 'LA',
        RegAddrZip: 70560,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1017 SHELTON AVE',
        RegAddrCity: 'NEW IBERIA',
        RegAddrState: 'LA',
        RegAddrZip: 70560,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1406 HOWARD ST',
        RegAddrCity: 'NEW IBERIA',
        RegAddrState: 'LA',
        RegAddrZip: 70560,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '7302 DASPIT RD',
        RegAddrCity: 'NEW IBERIA',
        RegAddrState: 'LA',
        RegAddrZip: 70563,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '2204 TURNBERRY ST',
        RegAddrCity: 'NEW IBERIA',
        RegAddrState: 'LA',
        RegAddrZip: 70563,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '4610 HIGHWAY 182 W',
        RegAddrCity: 'PATTERSON',
        RegAddrState: 'LA',
        RegAddrZip: 70392,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '901 RED CYPRESS RD',
        RegAddrCity: 'PATTERSON',
        RegAddrState: 'LA',
        RegAddrZip: 70392,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1150 BARRAS RD',
        RegAddrCity: 'ST MARTINVLLE',
        RegAddrState: 'LA',
        RegAddrZip: 70582,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1007 JOSEPH RD',
        RegAddrCity: 'ST MARTINVLLE',
        RegAddrState: 'LA',
        RegAddrZip: 70582,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '5160 MAIN HWY',
        RegAddrCity: 'ST MARTINVLLE',
        RegAddrState: 'LA',
        RegAddrZip: 70582,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '5012 MANDY DR',
        RegAddrCity: 'SULPHUR',
        RegAddrState: 'LA',
        RegAddrZip: 70663,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '257 MADISON CROSSING DR',
        RegAddrCity: 'SULPHUR',
        RegAddrState: 'LA',
        RegAddrZip: 70665,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '4259 PETE SEAY RD',
        RegAddrCity: 'SULPHUR',
        RegAddrState: 'LA',
        RegAddrZip: 70665,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '507 CADDO DR',
        RegAddrCity: 'VINTON',
        RegAddrState: 'LA',
        RegAddrZip: 70668,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '305 SECOND ST',
        RegAddrCity: 'YOUNGSVILLE',
        RegAddrState: 'LA',
        RegAddrZip: 70592,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '118 HUNDRED OAKS DR',
        RegAddrCity: 'YOUNGSVILLE',
        RegAddrState: 'LA',
        RegAddrZip: 70592,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '118 ARROWWOOD RD',
        RegAddrCity: 'YOUNGSVILLE',
        RegAddrState: 'LA',
        RegAddrZip: 70592,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '103 SHADOWBROOK LN',
        RegAddrCity: 'YOUNGSVILLE',
        RegAddrState: 'LA',
        RegAddrZip: 70592,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '208 SADDLEBACK CIR',
        RegAddrCity: 'YOUNGSVILLE',
        RegAddrState: 'LA',
        RegAddrZip: 70592,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '106 SAN SEBASTIAN DR',
        RegAddrCity: 'YOUNGSVILLE',
        RegAddrState: 'LA',
        RegAddrZip: 70592,
        CongressionalDistrict: 3,
      },
    ],
  },
  {
    name: 'LA-4',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/LA-4?ref=gh-pages',
    state: 'LA',
    state_and_number: 'LA-4',
    zip: 70524,
    congressionalDistrict: 4,
    addressesInDistrict: [
      {
        RegAddrLine1: '1646 CARTER RD',
        RegAddrCity: 'BELMONT',
        RegAddrState: 'LA',
        RegAddrZip: 71406,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '3112 MALVERN ST',
        RegAddrCity: 'BOSSIER CITY',
        RegAddrState: 'LA',
        RegAddrZip: 71111,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '405 ADAIR ST',
        RegAddrCity: 'BOSSIER CITY',
        RegAddrState: 'LA',
        RegAddrZip: 71111,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '206 S MADISON CT',
        RegAddrCity: 'BOSSIER CITY',
        RegAddrState: 'LA',
        RegAddrZip: 71111,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1408 AZALEA ST',
        RegAddrCity: 'BOSSIER CITY',
        RegAddrState: 'LA',
        RegAddrZip: 71112,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2183 BRITANY HWY',
        RegAddrCity: 'CHURCH POINT',
        RegAddrState: 'LA',
        RegAddrZip: 70525,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '408 LEE ST',
        RegAddrCity: 'CULLEN',
        RegAddrState: 'LA',
        RegAddrZip: 71021,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1245 ALLISON DR',
        RegAddrCity: 'DERIDDER',
        RegAddrState: 'LA',
        RegAddrZip: 70634,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1110 LUCIUS DR',
        RegAddrCity: 'DERIDDER',
        RegAddrState: 'LA',
        RegAddrZip: 70634,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2416 HIGHWAY 95',
        RegAddrCity: 'EUNICE',
        RegAddrState: 'LA',
        RegAddrZip: 70535,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '279 CARTER SETTLEMENT RD',
        RegAddrCity: 'FIELDS',
        RegAddrState: 'LA',
        RegAddrZip: 70653,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '725 BATES RD',
        RegAddrCity: 'FRIERSON',
        RegAddrState: 'LA',
        RegAddrZip: 71027,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '937 VILLA LN',
        RegAddrCity: 'HAUGHTON',
        RegAddrState: 'LA',
        RegAddrZip: 71037,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2058 NELSON DR',
        RegAddrCity: 'HAYNESVILLE',
        RegAddrState: 'LA',
        RegAddrZip: 71038,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '711 HOLLENSHEAD RD',
        RegAddrCity: 'HAYNESVILLE',
        RegAddrState: 'LA',
        RegAddrZip: 71038,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '8139 HIGHWAY 534',
        RegAddrCity: 'HAYNESVILLE',
        RegAddrState: 'LA',
        RegAddrZip: 71038,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '222 PINE ST',
        RegAddrCity: 'HEFLIN',
        RegAddrState: 'LA',
        RegAddrZip: 71039,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '352 NORTH LOOP',
        RegAddrCity: 'HORNBECK',
        RegAddrState: 'LA',
        RegAddrZip: 71439,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '335 BOSWELL RD',
        RegAddrCity: 'LEANDER',
        RegAddrState: 'LA',
        RegAddrZip: 71438,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '164 COOPER DR',
        RegAddrCity: 'LEESVILLE',
        RegAddrState: 'LA',
        RegAddrZip: 71446,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '142 GEORGE AVE',
        RegAddrCity: 'LEESVILLE',
        RegAddrState: 'LA',
        RegAddrZip: 71446,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '211 HIGHWAY 549',
        RegAddrCity: 'MARION',
        RegAddrState: 'LA',
        RegAddrZip: 71260,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '452 DALE LOVE RD',
        RegAddrCity: 'MARION',
        RegAddrState: 'LA',
        RegAddrZip: 71260,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '112 ALLEN DR',
        RegAddrCity: 'MINDEN',
        RegAddrState: 'LA',
        RegAddrZip: 71055,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '287 NORTHWOOD LN',
        RegAddrCity: 'NATCHITOCHES',
        RegAddrState: 'LA',
        RegAddrZip: 71457,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '107 CYPRESS AVE',
        RegAddrCity: 'NATCHITOCHES',
        RegAddrState: 'LA',
        RegAddrZip: 71457,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '210 WHITFIELD DR',
        RegAddrCity: 'NATCHITOCHES',
        RegAddrState: 'LA',
        RegAddrZip: 71457,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '162 VIENNA RD',
        RegAddrCity: 'NATCHITOCHES',
        RegAddrState: 'LA',
        RegAddrZip: 71457,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '316 E DIXIE ST',
        RegAddrCity: 'OAKDALE',
        RegAddrState: 'LA',
        RegAddrZip: 71463,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '701 E DIXIE ST',
        RegAddrCity: 'OAKDALE',
        RegAddrState: 'LA',
        RegAddrZip: 71463,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '306 N 16TH ST',
        RegAddrCity: 'OAKDALE',
        RegAddrState: 'LA',
        RegAddrZip: 71463,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2262 HIGHWAY 3043',
        RegAddrCity: 'OPELOUSAS',
        RegAddrState: 'LA',
        RegAddrZip: 70570,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '145 PETITE RUE DE LANDON',
        RegAddrCity: 'OPELOUSAS',
        RegAddrState: 'LA',
        RegAddrZip: 70570,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1305 PRAYER HOUSE RD',
        RegAddrCity: 'OPELOUSAS',
        RegAddrState: 'LA',
        RegAddrZip: 70570,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '129 PROVIDENCE LN',
        RegAddrCity: 'OPELOUSAS',
        RegAddrState: 'LA',
        RegAddrZip: 70570,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '208 SAINT MARY RD',
        RegAddrCity: 'PLAIN DEALING',
        RegAddrState: 'LA',
        RegAddrZip: 71064,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '190 CAROL DR',
        RegAddrCity: 'RAGLEY',
        RegAddrState: 'LA',
        RegAddrZip: 70657,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '575 FIELDER RD',
        RegAddrCity: 'RINGGOLD',
        RegAddrState: 'LA',
        RegAddrZip: 71068,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1882 KING ORCHARD RD',
        RegAddrCity: 'SAREPTA',
        RegAddrState: 'LA',
        RegAddrZip: 71071,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '648 WILDER PL',
        RegAddrCity: 'SHREVEPORT',
        RegAddrState: 'LA',
        RegAddrZip: 71104,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '754 RUTHERFORD ST',
        RegAddrCity: 'SHREVEPORT',
        RegAddrState: 'LA',
        RegAddrZip: 71104,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '5001 DIXIE GARDEN DR',
        RegAddrCity: 'SHREVEPORT',
        RegAddrState: 'LA',
        RegAddrZip: 71105,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1101 ELLERBE CT',
        RegAddrCity: 'SHREVEPORT',
        RegAddrState: 'LA',
        RegAddrZip: 71106,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '561 JANET LN',
        RegAddrCity: 'SHREVEPORT',
        RegAddrState: 'LA',
        RegAddrZip: 71106,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '406 RAY ST',
        RegAddrCity: 'SHREVEPORT',
        RegAddrState: 'LA',
        RegAddrZip: 71106,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '620 OAK HILL DR',
        RegAddrCity: 'SHREVEPORT',
        RegAddrState: 'LA',
        RegAddrZip: 71106,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '510 W 76TH ST',
        RegAddrCity: 'SHREVEPORT',
        RegAddrState: 'LA',
        RegAddrZip: 71106,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '3058 LAKEVIEW RD',
        RegAddrCity: 'SHREVEPORT',
        RegAddrState: 'LA',
        RegAddrZip: 71107,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '8010 PATHWAY TRL',
        RegAddrCity: 'SHREVEPORT',
        RegAddrState: 'LA',
        RegAddrZip: 71107,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1663 RUSSELL RD',
        RegAddrCity: 'SHREVEPORT',
        RegAddrState: 'LA',
        RegAddrZip: 71107,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '4686 NORTHWOOD HILLS DR',
        RegAddrCity: 'SHREVEPORT',
        RegAddrState: 'LA',
        RegAddrZip: 71107,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '246 GARFIELD AVE',
        RegAddrCity: 'SHREVEPORT',
        RegAddrState: 'LA',
        RegAddrZip: 71107,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '7100 W CANAL BLVD',
        RegAddrCity: 'SHREVEPORT',
        RegAddrState: 'LA',
        RegAddrZip: 71108,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '3302 W COLLEGE ST',
        RegAddrCity: 'SHREVEPORT',
        RegAddrState: 'LA',
        RegAddrZip: 71109,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2801 PENICK ST',
        RegAddrCity: 'SHREVEPORT',
        RegAddrState: 'LA',
        RegAddrZip: 71109,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '9017 VILLANOVA DR',
        RegAddrCity: 'SHREVEPORT',
        RegAddrState: 'LA',
        RegAddrZip: 71118,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1026 RIDING CLUB LN',
        RegAddrCity: 'SHREVEPORT',
        RegAddrState: 'LA',
        RegAddrZip: 71118,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '9447 KROETZ DR',
        RegAddrCity: 'SHREVEPORT',
        RegAddrState: 'LA',
        RegAddrZip: 71118,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2310 FERNBROOK CIR',
        RegAddrCity: 'SHREVEPORT',
        RegAddrState: 'LA',
        RegAddrZip: 71118,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '3705 LAKESIDE DR',
        RegAddrCity: 'SHREVEPORT',
        RegAddrState: 'LA',
        RegAddrZip: 71119,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '9249 KIMBERLY RD S',
        RegAddrCity: 'SHREVEPORT',
        RegAddrState: 'LA',
        RegAddrZip: 71129,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '7300 BUNCOMBE RD',
        RegAddrCity: 'SHREVEPORT',
        RegAddrState: 'LA',
        RegAddrZip: 71129,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '101 WILLIAMS TRL',
        RegAddrCity: 'SIBLEY',
        RegAddrState: 'LA',
        RegAddrZip: 71073,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '247 DALTON RD',
        RegAddrCity: 'STONEWALL',
        RegAddrState: 'LA',
        RegAddrZip: 71078,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '166 TIMBER TRL',
        RegAddrCity: 'SUNSET',
        RegAddrState: 'LA',
        RegAddrZip: 70584,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1097 JESSICA LOOP',
        RegAddrCity: 'VILLE PLATTE',
        RegAddrState: 'LA',
        RegAddrZip: 70586,
        CongressionalDistrict: 4,
      },
    ],
  },
  {
    name: 'LA-5',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/LA-5?ref=gh-pages',
    state: 'LA',
    state_and_number: 'LA-5',
    zip: 70422,
    congressionalDistrict: 5,
    addressesInDistrict: [
      {
        RegAddrLine1: '1426 ASHLEY AVE',
        RegAddrCity: 'ALEXANDRIA',
        RegAddrState: 'LA',
        RegAddrZip: 71301,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '3218 OLCUTT ST',
        RegAddrCity: 'ALEXANDRIA',
        RegAddrState: 'LA',
        RegAddrZip: 71301,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '6329 LANDMARK DR',
        RegAddrCity: 'ALEXANDRIA',
        RegAddrState: 'LA',
        RegAddrZip: 71301,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '24 MARY ST',
        RegAddrCity: 'ALEXANDRIA',
        RegAddrState: 'LA',
        RegAddrZip: 71301,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '2527 JACKSON ST',
        RegAddrCity: 'ALEXANDRIA',
        RegAddrState: 'LA',
        RegAddrZip: 71301,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '4911 BLUEBIRD LN',
        RegAddrCity: 'ALEXANDRIA',
        RegAddrState: 'LA',
        RegAddrZip: 71303,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '722 TERRA AVE',
        RegAddrCity: 'ALEXANDRIA',
        RegAddrState: 'LA',
        RegAddrZip: 71303,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '61604 WILLIE MCCOY RD',
        RegAddrCity: 'AMITE',
        RegAddrState: 'LA',
        RegAddrZip: 70422,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '409 W PINE ST',
        RegAddrCity: 'AMITE',
        RegAddrState: 'LA',
        RegAddrZip: 70422,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '32175 JERRY MOSES RD',
        RegAddrCity: 'ANGIE',
        RegAddrState: 'LA',
        RegAddrZip: 70426,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '199 NORTHWOOD RD',
        RegAddrCity: 'ARNAUDVILLE',
        RegAddrState: 'LA',
        RegAddrZip: 70512,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '6614 EASTBROOK TRCE',
        RegAddrCity: 'BALL',
        RegAddrState: 'LA',
        RegAddrZip: 71405,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '5457 STEVENSON RD',
        RegAddrCity: 'BASTROP',
        RegAddrState: 'LA',
        RegAddrZip: 71220,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '913 E CYPRESS AVE',
        RegAddrCity: 'BASTROP',
        RegAddrState: 'LA',
        RegAddrZip: 71220,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '407 TULANE ST',
        RegAddrCity: 'BASTROP',
        RegAddrState: 'LA',
        RegAddrZip: 71220,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '619 BANKSTON DR',
        RegAddrCity: 'BOGALUSA',
        RegAddrState: 'LA',
        RegAddrZip: 70427,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '17698 LANE RD',
        RegAddrCity: 'BOGALUSA',
        RegAddrState: 'LA',
        RegAddrZip: 70427,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '1501 N ROOSEVELT ST',
        RegAddrCity: 'BOGALUSA',
        RegAddrState: 'LA',
        RegAddrZip: 70427,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '3864 HIGHWAY 121',
        RegAddrCity: 'BOYCE',
        RegAddrState: 'LA',
        RegAddrZip: 71409,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '211 SUGAR LOAF RD',
        RegAddrCity: 'CALHOUN',
        RegAddrState: 'LA',
        RegAddrZip: 71225,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '2055 HIGHWAY 144',
        RegAddrCity: 'CALHOUN',
        RegAddrState: 'LA',
        RegAddrZip: 71225,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '1063 BAUDRY RD',
        RegAddrCity: 'COLFAX',
        RegAddrState: 'LA',
        RegAddrZip: 71417,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '483 LEACH RD',
        RegAddrCity: 'DODSON',
        RegAddrState: 'LA',
        RegAddrZip: 71422,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '191 GOOSE CREEK LN',
        RegAddrCity: 'DUBACH',
        RegAddrState: 'LA',
        RegAddrZip: 71235,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '1122 HAYES CEMETERY RD',
        RegAddrCity: 'EFFIE',
        RegAddrState: 'LA',
        RegAddrZip: 71331,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '343 GOUDEAU RD',
        RegAddrCity: 'EVERGREEN',
        RegAddrState: 'LA',
        RegAddrZip: 71333,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '138 TORRES LN',
        RegAddrCity: 'FERRIDAY',
        RegAddrState: 'LA',
        RegAddrZip: 71334,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '49633 HIGHWAY 436',
        RegAddrCity: 'FRANKLINTON',
        RegAddrState: 'LA',
        RegAddrZip: 70438,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '8653 HIGHWAY 112',
        RegAddrCity: 'GLENMORA',
        RegAddrState: 'LA',
        RegAddrZip: 71433,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '46190 LAVELLE DR',
        RegAddrCity: 'HAMMOND',
        RegAddrState: 'LA',
        RegAddrZip: 70401,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '207 T WILLIAM RD',
        RegAddrCity: 'HESSMER',
        RegAddrState: 'LA',
        RegAddrZip: 71341,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '21177 PINE HILLS SUBDIVISION RD',
        RegAddrCity: 'HUSSER',
        RegAddrState: 'LA',
        RegAddrZip: 70442,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '146 RAGUSA RD',
        RegAddrCity: 'INDEPENDENCE',
        RegAddrState: 'LA',
        RegAddrZip: 70443,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '1108 HIGHWAY 952',
        RegAddrCity: 'JACKSON',
        RegAddrState: 'LA',
        RegAddrZip: 70748,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '125 CLAUDE LN',
        RegAddrCity: 'JENA',
        RegAddrState: 'LA',
        RegAddrZip: 71342,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '166 TRIPPLE W LOOP',
        RegAddrCity: 'JENA',
        RegAddrState: 'LA',
        RegAddrZip: 71342,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '904 ML KING DR',
        RegAddrCity: 'JONESVILLE',
        RegAddrState: 'LA',
        RegAddrZip: 71343,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '645 CROOKS RD',
        RegAddrCity: 'JONESVILLE',
        RegAddrState: 'LA',
        RegAddrZip: 71343,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '20299 HIGHWAY 10',
        RegAddrCity: 'KENTWOOD',
        RegAddrState: 'LA',
        RegAddrZip: 70444,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '24645 HIGHWAY 440',
        RegAddrCity: 'KENTWOOD',
        RegAddrState: 'LA',
        RegAddrZip: 70444,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '1205 8TH ST',
        RegAddrCity: 'LAKE PROVIDENCE',
        RegAddrState: 'LA',
        RegAddrZip: 71254,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '19095 JAELYN DR',
        RegAddrCity: 'LORANGER',
        RegAddrState: 'LA',
        RegAddrZip: 70446,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '21330 CHAPPEPEELA RD',
        RegAddrCity: 'LORANGER',
        RegAddrState: 'LA',
        RegAddrZip: 70446,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '238 PORET RD',
        RegAddrCity: 'MANSURA',
        RegAddrState: 'LA',
        RegAddrZip: 71350,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '332 BROUILLETTE ST',
        RegAddrCity: 'MARKSVILLE',
        RegAddrState: 'LA',
        RegAddrZip: 71351,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '208 W 19TH ST',
        RegAddrCity: 'MER ROUGE',
        RegAddrState: 'LA',
        RegAddrZip: 71261,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '808 S 20TH ST',
        RegAddrCity: 'MONROE',
        RegAddrState: 'LA',
        RegAddrZip: 71201,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '1606 N 6TH ST',
        RegAddrCity: 'MONROE',
        RegAddrState: 'LA',
        RegAddrZip: 71201,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '606 GLENMAR AVE',
        RegAddrCity: 'MONROE',
        RegAddrState: 'LA',
        RegAddrZip: 71201,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '711 S 6TH ST',
        RegAddrCity: 'MONROE',
        RegAddrState: 'LA',
        RegAddrZip: 71202,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '115 MAISON DR',
        RegAddrCity: 'MONROE',
        RegAddrState: 'LA',
        RegAddrZip: 71203,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '715 MCCAIN DR',
        RegAddrCity: 'MONROE',
        RegAddrState: 'LA',
        RegAddrZip: 71203,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '119 MEDALIST RD',
        RegAddrCity: 'MONROE',
        RegAddrState: 'LA',
        RegAddrZip: 71203,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '304 TAFT DR',
        RegAddrCity: 'MONROE',
        RegAddrState: 'LA',
        RegAddrZip: 71203,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '10304 HIGHWAY 451',
        RegAddrCity: 'MOREAUVILLE',
        RegAddrState: 'LA',
        RegAddrZip: 71355,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '421 E FRANKLIN ST',
        RegAddrCity: 'OPELOUSAS',
        RegAddrState: 'LA',
        RegAddrZip: 70570,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '7014 HWY 71',
        RegAddrCity: 'PALMETTO',
        RegAddrState: 'LA',
        RegAddrZip: 71358,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '340 MALLARD CV',
        RegAddrCity: 'PINEVILLE',
        RegAddrState: 'LA',
        RegAddrZip: 71360,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '1022 TRANQUILITY DR',
        RegAddrCity: 'PINEVILLE',
        RegAddrState: 'LA',
        RegAddrZip: 71360,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '917 RIDGEVIEW DR',
        RegAddrCity: 'PINEVILLE',
        RegAddrState: 'LA',
        RegAddrZip: 71360,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '112 LAKE DR',
        RegAddrCity: 'PINEVILLE',
        RegAddrState: 'LA',
        RegAddrZip: 71360,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '5587 HIGHWAY 577',
        RegAddrCity: 'PIONEER',
        RegAddrState: 'LA',
        RegAddrZip: 71266,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '1621 STEWART ST',
        RegAddrCity: 'POLLOCK',
        RegAddrState: 'LA',
        RegAddrZip: 71467,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '114 SENECA ST',
        RegAddrCity: 'PORT BARRE',
        RegAddrState: 'LA',
        RegAddrZip: 70577,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '1647 ARCADIA HWY',
        RegAddrCity: 'QUITMAN',
        RegAddrState: 'LA',
        RegAddrZip: 71268,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '89 CLARK RD',
        RegAddrCity: 'RAYVILLE',
        RegAddrState: 'LA',
        RegAddrZip: 71269,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '6355 FEDERAL 80 HWY',
        RegAddrCity: 'RAYVILLE',
        RegAddrState: 'LA',
        RegAddrZip: 71269,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '207 LEO ST',
        RegAddrCity: 'RAYVILLE',
        RegAddrState: 'LA',
        RegAddrZip: 71269,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '406 BARBER DR',
        RegAddrCity: 'RUSTON',
        RegAddrState: 'LA',
        RegAddrZip: 71270,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '501 AUDUBON DR',
        RegAddrCity: 'RUSTON',
        RegAddrState: 'LA',
        RegAddrZip: 71270,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '3919 GREEN MOUNTAIN DR',
        RegAddrCity: 'RUSTON',
        RegAddrState: 'LA',
        RegAddrZip: 71270,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '2104 WINCHESTER DR',
        RegAddrCity: 'RUSTON',
        RegAddrState: 'LA',
        RegAddrZip: 71270,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '13869 LA HIGHWAY 10',
        RegAddrCity: 'SAINT FRANCISVILLE',
        RegAddrState: 'LA',
        RegAddrZip: 70775,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '118 JOHNS ST',
        RegAddrCity: 'TALLULAH',
        RegAddrState: 'LA',
        RegAddrZip: 71282,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '1406 LOUISIANA ST',
        RegAddrCity: 'TALLULAH',
        RegAddrState: 'LA',
        RegAddrZip: 71282,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '10072 DRAKE LN',
        RegAddrCity: 'TICKFAW',
        RegAddrState: 'LA',
        RegAddrZip: 70466,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '611 WALNUT ST',
        RegAddrCity: 'VIDALIA',
        RegAddrState: 'LA',
        RegAddrZip: 71373,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '341 GREEN ACRES RD',
        RegAddrCity: 'VIDALIA',
        RegAddrState: 'LA',
        RegAddrZip: 71373,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '408 FLANAGAN ST',
        RegAddrCity: 'WEST MONROE',
        RegAddrState: 'LA',
        RegAddrZip: 71291,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '1450 STROZIER RD',
        RegAddrCity: 'WEST MONROE',
        RegAddrState: 'LA',
        RegAddrZip: 71291,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '605 MOUNT PLEASANT RD',
        RegAddrCity: 'WEST MONROE',
        RegAddrState: 'LA',
        RegAddrZip: 71292,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '319 MILLER SHELBY RD',
        RegAddrCity: 'WEST MONROE',
        RegAddrState: 'LA',
        RegAddrZip: 71292,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '8808 HIGHWAY 501',
        RegAddrCity: 'WINNFIELD',
        RegAddrState: 'LA',
        RegAddrZip: 71483,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '908 BROWN ST',
        RegAddrCity: 'WINNFIELD',
        RegAddrState: 'LA',
        RegAddrZip: 71483,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '202 FOSTER RD',
        RegAddrCity: 'WINNFIELD',
        RegAddrState: 'LA',
        RegAddrZip: 71483,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '5954 HIGHWAY 4',
        RegAddrCity: 'WINNSBORO',
        RegAddrState: 'LA',
        RegAddrZip: 71295,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '913 JACKSON ST',
        RegAddrCity: 'WINNSBORO',
        RegAddrState: 'LA',
        RegAddrZip: 71295,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '1306 RUTH ST',
        RegAddrCity: 'WINNSBORO',
        RegAddrState: 'LA',
        RegAddrZip: 71295,
        CongressionalDistrict: 5,
      },
    ],
  },
  {
    name: 'LA-6',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/LA-6?ref=gh-pages',
    state: 'LA',
    state_and_number: 'LA-6',
    zip: 70079,
    congressionalDistrict: 6,
    addressesInDistrict: [
      {
        RegAddrLine1: '28431 LONGFELLOW LN',
        RegAddrCity: 'ALBANY',
        RegAddrState: 'LA',
        RegAddrZip: 70711,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '4691 GIBBENS PAYNE AVE',
        RegAddrCity: 'BAKER',
        RegAddrState: 'LA',
        RegAddrZip: 70714,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '817 ARKANSAS ST',
        RegAddrCity: 'BAKER',
        RegAddrState: 'LA',
        RegAddrZip: 70714,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '428 EVERGREEN DR',
        RegAddrCity: 'BATON ROUGE',
        RegAddrState: 'LA',
        RegAddrZip: 70806,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '764 WILTZ DR',
        RegAddrCity: 'BATON ROUGE',
        RegAddrState: 'LA',
        RegAddrZip: 70806,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '1659 BELMONT AVE',
        RegAddrCity: 'BATON ROUGE',
        RegAddrState: 'LA',
        RegAddrZip: 70808,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '8051 WALDEN RD',
        RegAddrCity: 'BATON ROUGE',
        RegAddrState: 'LA',
        RegAddrZip: 70808,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '251 CLARA DR',
        RegAddrCity: 'BATON ROUGE',
        RegAddrState: 'LA',
        RegAddrZip: 70808,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '10370 OLIPHANT RD',
        RegAddrCity: 'BATON ROUGE',
        RegAddrState: 'LA',
        RegAddrZip: 70809,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '9935 HILLYARD AVE',
        RegAddrCity: 'BATON ROUGE',
        RegAddrState: 'LA',
        RegAddrZip: 70809,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '9270 PECAN TREE DR',
        RegAddrCity: 'BATON ROUGE',
        RegAddrState: 'LA',
        RegAddrZip: 70810,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '10153 HIGHLAND RD',
        RegAddrCity: 'BATON ROUGE',
        RegAddrState: 'LA',
        RegAddrZip: 70810,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '3433 WHITE SANDS DR',
        RegAddrCity: 'BATON ROUGE',
        RegAddrState: 'LA',
        RegAddrZip: 70814,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '11668 BISCAYNE DR',
        RegAddrCity: 'BATON ROUGE',
        RegAddrState: 'LA',
        RegAddrZip: 70814,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '9865 CHATEAU DR',
        RegAddrCity: 'BATON ROUGE',
        RegAddrState: 'LA',
        RegAddrZip: 70815,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '1748 W FAIRVIEW DR',
        RegAddrCity: 'BATON ROUGE',
        RegAddrState: 'LA',
        RegAddrZip: 70816,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '1332 CATILINE PL',
        RegAddrCity: 'BATON ROUGE',
        RegAddrState: 'LA',
        RegAddrZip: 70816,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '17988 SHADOW CREEK AVE',
        RegAddrCity: 'BATON ROUGE',
        RegAddrState: 'LA',
        RegAddrZip: 70816,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '5971 FORT CLARKE DR N',
        RegAddrCity: 'BATON ROUGE',
        RegAddrState: 'LA',
        RegAddrZip: 70817,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '17814 WILLOW TRAIL DR',
        RegAddrCity: 'BATON ROUGE',
        RegAddrState: 'LA',
        RegAddrZip: 70817,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '15022 WAX MYRTLE AVE',
        RegAddrCity: 'BATON ROUGE',
        RegAddrState: 'LA',
        RegAddrZip: 70817,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '7752 BRONCO AVE',
        RegAddrCity: 'BATON ROUGE',
        RegAddrState: 'LA',
        RegAddrZip: 70818,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '14524 MORA DR',
        RegAddrCity: 'BATON ROUGE',
        RegAddrState: 'LA',
        RegAddrZip: 70819,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '602 BANKSTON DR',
        RegAddrCity: 'BOGALUSA',
        RegAddrState: 'LA',
        RegAddrZip: 70427,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '507 N KIRKLAND DR',
        RegAddrCity: 'BRUSLY',
        RegAddrState: 'LA',
        RegAddrZip: 70719,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '5105 ELLWOOD RD',
        RegAddrCity: 'BRUSLY',
        RegAddrState: 'LA',
        RegAddrZip: 70719,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '365 GENOLA RD',
        RegAddrCity: 'CLINTON',
        RegAddrState: 'LA',
        RegAddrZip: 70722,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '35024 MADISONVILLE DR',
        RegAddrCity: 'DENHAM SPGS',
        RegAddrState: 'LA',
        RegAddrZip: 70706,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '34090 LA HIGHWAY 1019',
        RegAddrCity: 'DENHAM SPGS',
        RegAddrState: 'LA',
        RegAddrZip: 70706,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '36341 CANE MARKET RD',
        RegAddrCity: 'DENHAM SPGS',
        RegAddrState: 'LA',
        RegAddrZip: 70706,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '13847 AVOCADO DR',
        RegAddrCity: 'DENHAM SPGS',
        RegAddrState: 'LA',
        RegAddrZip: 70726,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '25914 JEFFERSON AVE',
        RegAddrCity: 'DENHAM SPGS',
        RegAddrState: 'LA',
        RegAddrZip: 70726,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '11281 CADDO DR',
        RegAddrCity: 'DENHAM SPGS',
        RegAddrState: 'LA',
        RegAddrZip: 70726,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '11378 ASHWOOD CT',
        RegAddrCity: 'DENHAM SPGS',
        RegAddrState: 'LA',
        RegAddrZip: 70726,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '257 MURRAY HILL DR',
        RegAddrCity: 'DESTREHAN',
        RegAddrState: 'LA',
        RegAddrZip: 70047,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '103 JENKINS ST',
        RegAddrCity: 'GIBSON',
        RegAddrState: 'LA',
        RegAddrZip: 70356,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '42165 WEBER CITY RD',
        RegAddrCity: 'GONZALES',
        RegAddrState: 'LA',
        RegAddrZip: 70737,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '1103 E TIFFANI ST',
        RegAddrCity: 'GONZALES',
        RegAddrState: 'LA',
        RegAddrZip: 70737,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '5437 COURTYARD DR',
        RegAddrCity: 'GONZALES',
        RegAddrState: 'LA',
        RegAddrZip: 70737,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '13085 GEORGE ROUYEA RD',
        RegAddrCity: 'GONZALES',
        RegAddrState: 'LA',
        RegAddrZip: 70737,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '5688 MAPLETON DR',
        RegAddrCity: 'GREENWEL SPGS',
        RegAddrState: 'LA',
        RegAddrZip: 70739,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '15833 SHETLAND AVE',
        RegAddrCity: 'GREENWEL SPGS',
        RegAddrState: 'LA',
        RegAddrZip: 70739,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '31982 N DOYLE RD',
        RegAddrCity: 'HOLDEN',
        RegAddrState: 'LA',
        RegAddrZip: 70744,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '126 ROYCE ST',
        RegAddrCity: 'HOUMA',
        RegAddrState: 'LA',
        RegAddrZip: 70364,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '908 BROADMOOR AVE',
        RegAddrCity: 'HOUMA',
        RegAddrState: 'LA',
        RegAddrZip: 70364,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '330 S CANTERBURY DR',
        RegAddrCity: 'LAPLACE',
        RegAddrState: 'LA',
        RegAddrZip: 70068,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '18652 LARRY WATTS RD',
        RegAddrCity: 'LIVINGSTON',
        RegAddrState: 'LA',
        RegAddrZip: 70754,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '19901 SAW MILL LN',
        RegAddrCity: 'LIVINGSTON',
        RegAddrState: 'LA',
        RegAddrZip: 70754,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '106 COUNTRY VILLAGE DR',
        RegAddrCity: 'MORGAN CITY',
        RegAddrState: 'LA',
        RegAddrZip: 70380,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '1005 E MAIN ST',
        RegAddrCity: 'NEW ROADS',
        RegAddrState: 'LA',
        RegAddrZip: 70760,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '1105 NEW ROADS ST',
        RegAddrCity: 'NEW ROADS',
        RegAddrState: 'LA',
        RegAddrZip: 70760,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '7233 N RIVER RD',
        RegAddrCity: 'PORT ALLEN',
        RegAddrState: 'LA',
        RegAddrZip: 70767,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '16566 LIVE OAK DR',
        RegAddrCity: 'PRAIRIEVILLE',
        RegAddrState: 'LA',
        RegAddrZip: 70769,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '17482 SIMONE DR',
        RegAddrCity: 'PRAIRIEVILLE',
        RegAddrState: 'LA',
        RegAddrZip: 70769,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '38356 SILVERSTONE AVE',
        RegAddrCity: 'PRAIRIEVILLE',
        RegAddrState: 'LA',
        RegAddrZip: 70769,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '37532 IRVING AVE',
        RegAddrCity: 'PRAIRIEVILLE',
        RegAddrState: 'LA',
        RegAddrZip: 70769,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '39346 MEADOWBROOK AVE',
        RegAddrCity: 'PRAIRIEVILLE',
        RegAddrState: 'LA',
        RegAddrZip: 70769,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '37101 KATHLEEN AVE',
        RegAddrCity: 'PRAIRIEVILLE',
        RegAddrState: 'LA',
        RegAddrZip: 70769,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '42453 PEBBLESTONE AVE',
        RegAddrCity: 'PRAIRIEVILLE',
        RegAddrState: 'LA',
        RegAddrZip: 70769,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '45392 STRINGER BRIDGE RD',
        RegAddrCity: 'SAINT AMANT',
        RegAddrState: 'LA',
        RegAddrZip: 70774,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '123 MAIN PROJECT RD',
        RegAddrCity: 'SCHRIEVER',
        RegAddrState: 'LA',
        RegAddrZip: 70395,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '523 WEST AVE',
        RegAddrCity: 'SLAUGHTER',
        RegAddrState: 'LA',
        RegAddrZip: 70777,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '158 E OAKLAND ST',
        RegAddrCity: 'ST ROSE',
        RegAddrState: 'LA',
        RegAddrZip: 70087,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '932 SAINT CHARLES ST',
        RegAddrCity: 'THIBODAUX',
        RegAddrState: 'LA',
        RegAddrZip: 70301,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '803 HIGHWAY 308',
        RegAddrCity: 'THIBODAUX',
        RegAddrState: 'LA',
        RegAddrZip: 70301,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '401 MENARD ST',
        RegAddrCity: 'THIBODAUX',
        RegAddrState: 'LA',
        RegAddrZip: 70301,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '103 APRICOT ST',
        RegAddrCity: 'THIBODAUX',
        RegAddrState: 'LA',
        RegAddrZip: 70301,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '2110 AUDUBON AVE',
        RegAddrCity: 'THIBODAUX',
        RegAddrState: 'LA',
        RegAddrZip: 70301,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '30262 BROWDEN ST',
        RegAddrCity: 'WALKER',
        RegAddrState: 'LA',
        RegAddrZip: 70785,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '32692 CANE MARKET RD',
        RegAddrCity: 'WALKER',
        RegAddrState: 'LA',
        RegAddrZip: 70785,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '29539 ASTER ST',
        RegAddrCity: 'WALKER',
        RegAddrState: 'LA',
        RegAddrZip: 70785,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '32836 CANE MARKET RD',
        RegAddrCity: 'WALKER',
        RegAddrState: 'LA',
        RegAddrZip: 70785,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '28910 S ANNE DR',
        RegAddrCity: 'WALKER',
        RegAddrState: 'LA',
        RegAddrZip: 70785,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '19962 BUCKHORN DR',
        RegAddrCity: 'ZACHARY',
        RegAddrState: 'LA',
        RegAddrZip: 70791,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '22145 LIBERTY RD',
        RegAddrCity: 'ZACHARY',
        RegAddrState: 'LA',
        RegAddrZip: 70791,
        CongressionalDistrict: 6,
      },
    ],
  },
  {
    name: 'MA-1',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/MA-1?ref=gh-pages',
    state: 'MA',
    state_and_number: 'MA-1',
    zip: 1001,
    congressionalDistrict: 1,
    addressesInDistrict: [
      {
        RegAddrLine1: '24 WEST ST',
        RegAddrCity: 'ADAMS',
        RegAddrState: 'MA',
        RegAddrZip: 1220,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '182 N SUMMER ST',
        RegAddrCity: 'ADAMS',
        RegAddrState: 'MA',
        RegAddrZip: 1220,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '157 FLORIDA DR',
        RegAddrCity: 'AGAWAM',
        RegAddrState: 'MA',
        RegAddrZip: 1001,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '19 MAPLE ST',
        RegAddrCity: 'AGAWAM',
        RegAddrState: 'MA',
        RegAddrZip: 1001,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '114 ALBERT ST',
        RegAddrCity: 'AGAWAM',
        RegAddrState: 'MA',
        RegAddrZip: 1001,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '3 MEADOW LN',
        RegAddrCity: 'ASHFIELD',
        RegAddrState: 'MA',
        RegAddrZip: 1330,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '201 LANCELOT LN',
        RegAddrCity: 'BECKET',
        RegAddrState: 'MA',
        RegAddrZip: 1223,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '72 EDEN TRL',
        RegAddrCity: 'BERNARDSTON',
        RegAddrState: 'MA',
        RegAddrZip: 1337,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '79 FREEMAN RD',
        RegAddrCity: 'CHARLTON',
        RegAddrState: 'MA',
        RegAddrZip: 1507,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '35 HUNT SEAT DR',
        RegAddrCity: 'CHICOPEE',
        RegAddrState: 'MA',
        RegAddrZip: 1013,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '143 LUKASIK ST',
        RegAddrCity: 'CHICOPEE',
        RegAddrState: 'MA',
        RegAddrZip: 1020,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '61 KON TIKI CIR',
        RegAddrCity: 'CHICOPEE',
        RegAddrState: 'MA',
        RegAddrZip: 1020,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '972 GRANBY RD',
        RegAddrCity: 'CHICOPEE',
        RegAddrState: 'MA',
        RegAddrZip: 1020,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '79 FAIRGROUNDS RD',
        RegAddrCity: 'CUMMINGTON',
        RegAddrState: 'MA',
        RegAddrZip: 1026,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '39 HAYDEN POND RD',
        RegAddrCity: 'DUDLEY',
        RegAddrState: 'MA',
        RegAddrZip: 1571,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '44 W MAIN ST',
        RegAddrCity: 'DUDLEY',
        RegAddrState: 'MA',
        RegAddrZip: 1571,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '694 PODUNK RD',
        RegAddrCity: 'E BROOKFIELD',
        RegAddrState: 'MA',
        RegAddrZip: 1515,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '855 SOMERS RD',
        RegAddrCity: 'E LONGMEADOW',
        RegAddrState: 'MA',
        RegAddrZip: 1028,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '53 GRANT ST',
        RegAddrCity: 'EASTHAMPTON',
        RegAddrState: 'MA',
        RegAddrZip: 1027,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '33 1ST AVE',
        RegAddrCity: 'EASTHAMPTON',
        RegAddrState: 'MA',
        RegAddrZip: 1027,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '8 GRANDVIEW LN',
        RegAddrCity: 'EASTHAMPTON',
        RegAddrState: 'MA',
        RegAddrZip: 1027,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '41 ELMAR DR',
        RegAddrCity: 'FEEDING HILLS',
        RegAddrState: 'MA',
        RegAddrZip: 1030,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '198 BATCHELOR ST',
        RegAddrCity: 'GRANBY',
        RegAddrState: 'MA',
        RegAddrZip: 1033,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '65 BLANDFORD RD',
        RegAddrCity: 'GRANVILLE',
        RegAddrState: 'MA',
        RegAddrZip: 1034,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '90 BENNETT RD',
        RegAddrCity: 'HAMPDEN',
        RegAddrState: 'MA',
        RegAddrZip: 1036,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '15 BALDWIN DR',
        RegAddrCity: 'HAMPDEN',
        RegAddrState: 'MA',
        RegAddrZip: 1036,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '9 PERENNIAL LN',
        RegAddrCity: 'HAMPDEN',
        RegAddrState: 'MA',
        RegAddrZip: 1036,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '6 PLUNKETT AVE',
        RegAddrCity: 'HINSDALE',
        RegAddrState: 'MA',
        RegAddrZip: 1235,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '110 CHERRY ST',
        RegAddrCity: 'HOLYOKE',
        RegAddrState: 'MA',
        RegAddrZip: 1040,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '33 SAINT JEROME AVE',
        RegAddrCity: 'HOLYOKE',
        RegAddrState: 'MA',
        RegAddrZip: 1040,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '100 BENEDICT TER',
        RegAddrCity: 'LONGMEADOW',
        RegAddrState: 'MA',
        RegAddrZip: 1106,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '365 WALNUT ST',
        RegAddrCity: 'NORTH ADAMS',
        RegAddrState: 'MA',
        RegAddrZip: 1247,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '169 BRAYTON HILL TER',
        RegAddrCity: 'NORTH ADAMS',
        RegAddrState: 'MA',
        RegAddrZip: 1247,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '17 LABONTE AVE',
        RegAddrCity: 'NORTH ADAMS',
        RegAddrState: 'MA',
        RegAddrZip: 1247,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '363 WARE ST',
        RegAddrCity: 'PALMER',
        RegAddrState: 'MA',
        RegAddrZip: 1069,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '24 KARA DR',
        RegAddrCity: 'PITTSFIELD',
        RegAddrState: 'MA',
        RegAddrZip: 1201,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '344 HUNGERFORD ST',
        RegAddrCity: 'PITTSFIELD',
        RegAddrState: 'MA',
        RegAddrZip: 1201,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '176 COLUMBUS AVE',
        RegAddrCity: 'PITTSFIELD',
        RegAddrState: 'MA',
        RegAddrZip: 1201,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '254 DAWES AVE',
        RegAddrCity: 'PITTSFIELD',
        RegAddrState: 'MA',
        RegAddrZip: 1201,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '110 GENERAL KNOX RD',
        RegAddrCity: 'RUSSELL',
        RegAddrState: 'MA',
        RegAddrZip: 1071,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '464 HEWINS ST',
        RegAddrCity: 'SHEFFIELD',
        RegAddrState: 'MA',
        RegAddrZip: 1257,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '191 E MAIN ST 2',
        RegAddrCity: 'SHEFFIELD',
        RegAddrState: 'MA',
        RegAddrZip: 1257,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '8 COLLEGE VIEW HTS',
        RegAddrCity: 'SOUTH HADLEY',
        RegAddrState: 'MA',
        RegAddrZip: 1075,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '169 RIVERBOAT VILLAGE RD',
        RegAddrCity: 'SOUTH HADLEY',
        RegAddrState: 'MA',
        RegAddrZip: 1075,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '24 W SUMMIT ST KENLEE GDNS',
        RegAddrCity: 'SOUTH HADLEY',
        RegAddrState: 'MA',
        RegAddrZip: 1075,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '10 EDWARD AVE',
        RegAddrCity: 'SOUTHAMPTON',
        RegAddrState: 'MA',
        RegAddrZip: 1073,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '102 FOMER RD',
        RegAddrCity: 'SOUTHAMPTON',
        RegAddrState: 'MA',
        RegAddrZip: 1073,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '74 HARRINGTON ST',
        RegAddrCity: 'SOUTHBRIDGE',
        RegAddrState: 'MA',
        RegAddrZip: 1550,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '36 WYNNFIELD CIR',
        RegAddrCity: 'SOUTHWICK',
        RegAddrState: 'MA',
        RegAddrZip: 1077,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '205 EDDY ST',
        RegAddrCity: 'SPRINGFIELD',
        RegAddrState: 'MA',
        RegAddrZip: 1104,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '92 SANTA BARBARA ST',
        RegAddrCity: 'SPRINGFIELD',
        RegAddrState: 'MA',
        RegAddrZip: 1104,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '159 KNOLLWOOD ST',
        RegAddrCity: 'SPRINGFIELD',
        RegAddrState: 'MA',
        RegAddrZip: 1104,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '51 ADAMS ST',
        RegAddrCity: 'SPRINGFIELD',
        RegAddrState: 'MA',
        RegAddrZip: 1105,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '65 SHEFFORD ST',
        RegAddrCity: 'SPRINGFIELD',
        RegAddrState: 'MA',
        RegAddrZip: 1107,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '24 EDGEWOOD ST',
        RegAddrCity: 'SPRINGFIELD',
        RegAddrState: 'MA',
        RegAddrZip: 1109,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '522 KING ST',
        RegAddrCity: 'SPRINGFIELD',
        RegAddrState: 'MA',
        RegAddrZip: 1109,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '53 BUCKINGHAM ST',
        RegAddrCity: 'SPRINGFIELD',
        RegAddrState: 'MA',
        RegAddrZip: 1109,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '68 COLONIAL AVE',
        RegAddrCity: 'SPRINGFIELD',
        RegAddrState: 'MA',
        RegAddrZip: 1109,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '26 LENN RD',
        RegAddrCity: 'SPRINGFIELD',
        RegAddrState: 'MA',
        RegAddrZip: 1118,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '68 MANOR CT',
        RegAddrCity: 'SPRINGFIELD',
        RegAddrState: 'MA',
        RegAddrZip: 1118,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '49 WRENTHAM RD',
        RegAddrCity: 'SPRINGFIELD',
        RegAddrState: 'MA',
        RegAddrZip: 1119,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '97 KANE ST',
        RegAddrCity: 'SPRINGFIELD',
        RegAddrState: 'MA',
        RegAddrZip: 1119,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '6 BIRCH GLEN RD',
        RegAddrCity: 'SPRINGFIELD',
        RegAddrState: 'MA',
        RegAddrZip: 1119,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '222 BROOKFIELD RD',
        RegAddrCity: 'STURBRIDGE',
        RegAddrState: 'MA',
        RegAddrZip: 1518,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '95 DEER RUN RD',
        RegAddrCity: 'W SPRINGFIELD',
        RegAddrState: 'MA',
        RegAddrZip: 1089,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '204 WOODBROOK TER',
        RegAddrCity: 'W SPRINGFIELD',
        RegAddrState: 'MA',
        RegAddrZip: 1089,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '45 HIGH ST',
        RegAddrCity: 'W STOCKBRIDGE',
        RegAddrState: 'MA',
        RegAddrZip: 1266,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '38 ELM ST',
        RegAddrCity: 'WARREN',
        RegAddrState: 'MA',
        RegAddrZip: 1083,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '64 CHURCH ST',
        RegAddrCity: 'WEST SPRINGFIELD',
        RegAddrState: 'MA',
        RegAddrZip: 1089,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '32 ANGELINE ST',
        RegAddrCity: 'WEST SPRINGFIELD',
        RegAddrState: 'MA',
        RegAddrZip: 1089,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '49 ELY ST',
        RegAddrCity: 'WESTFIELD',
        RegAddrState: 'MA',
        RegAddrZip: 1085,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '12 GLENWOOD DR',
        RegAddrCity: 'WESTFIELD',
        RegAddrState: 'MA',
        RegAddrZip: 1085,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '30 CASTLE HILL RD',
        RegAddrCity: 'WESTFIELD',
        RegAddrState: 'MA',
        RegAddrZip: 1085,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '29 HONEY POT RD',
        RegAddrCity: 'WESTFIELD',
        RegAddrState: 'MA',
        RegAddrZip: 1085,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '0 CHAPIN HALL DR IA',
        RegAddrCity: 'WILLIAMSTOWN',
        RegAddrState: 'MA',
        RegAddrZip: 1267,
        CongressionalDistrict: 1,
      },
    ],
  },
  {
    name: 'MA-2',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/MA-2?ref=gh-pages',
    state: 'MA',
    state_and_number: 'MA-2',
    zip: 1002,
    congressionalDistrict: 2,
    addressesInDistrict: [
      {
        RegAddrLine1: '335 MIDDLE ST',
        RegAddrCity: 'AMHERST',
        RegAddrState: 'MA',
        RegAddrZip: 1002,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '8 HEDGEROW LN',
        RegAddrCity: 'AMHERST',
        RegAddrState: 'MA',
        RegAddrZip: 1002,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '380 RIVERGLADE DR',
        RegAddrCity: 'AMHERST',
        RegAddrState: 'MA',
        RegAddrZip: 1002,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '381 RIVERGLADE DR',
        RegAddrCity: 'AMHERST',
        RegAddrState: 'MA',
        RegAddrZip: 1002,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '106 FREDETTE ST',
        RegAddrCity: 'ATHOL',
        RegAddrState: 'MA',
        RegAddrZip: 1331,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '785 SILVER LAKE ST',
        RegAddrCity: 'ATHOL',
        RegAddrState: 'MA',
        RegAddrZip: 1331,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '417 PINEDALE RD',
        RegAddrCity: 'ATHOL',
        RegAddrState: 'MA',
        RegAddrZip: 1331,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '255 LEICESTER ST',
        RegAddrCity: 'AUBURN',
        RegAddrState: 'MA',
        RegAddrZip: 1501,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '23 GODDARD DR',
        RegAddrCity: 'AUBURN',
        RegAddrState: 'MA',
        RegAddrZip: 1501,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '612 WHEELWRIGHT RD',
        RegAddrCity: 'BARRE',
        RegAddrState: 'MA',
        RegAddrZip: 1005,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '999A SHELDON RD',
        RegAddrCity: 'BARRE',
        RegAddrState: 'MA',
        RegAddrZip: 1005,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '326 STEBBINS ST',
        RegAddrCity: 'BELCHERTOWN',
        RegAddrState: 'MA',
        RegAddrZip: 1007,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '231 FRANKLIN ST',
        RegAddrCity: 'BELCHERTOWN',
        RegAddrState: 'MA',
        RegAddrZip: 1007,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2 COMPASS CIR',
        RegAddrCity: 'BOYLSTON',
        RegAddrState: 'MA',
        RegAddrZip: 1505,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '92 SOUTH ST',
        RegAddrCity: 'DOUGLAS',
        RegAddrState: 'MA',
        RegAddrZip: 1516,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '119 SE MAIN ST',
        RegAddrCity: 'DOUGLAS',
        RegAddrState: 'MA',
        RegAddrZip: 1516,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '166 MAPLE ST',
        RegAddrCity: 'DOUGLAS',
        RegAddrState: 'MA',
        RegAddrZip: 1516,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '21 NOTTINGHAM RD',
        RegAddrCity: 'GRAFTON',
        RegAddrState: 'MA',
        RegAddrZip: 1519,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '402 DAVIS ST',
        RegAddrCity: 'GREENFIELD',
        RegAddrState: 'MA',
        RegAddrZip: 1301,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '33 NEWELL POND RD',
        RegAddrCity: 'GREENFIELD',
        RegAddrState: 'MA',
        RegAddrZip: 1301,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '20 N MAPLE ST',
        RegAddrCity: 'HADLEY',
        RegAddrState: 'MA',
        RegAddrZip: 1035,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '49 NORTH ST',
        RegAddrCity: 'HATFIELD',
        RegAddrState: 'MA',
        RegAddrZip: 1038,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '16 GEORDIE LN',
        RegAddrCity: 'HUBBARDSTON',
        RegAddrState: 'MA',
        RegAddrZip: 1452,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '14 LINDA ST',
        RegAddrCity: 'LEOMINSTER',
        RegAddrState: 'MA',
        RegAddrZip: 1453,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '138 WASHINGTON ST',
        RegAddrCity: 'LEOMINSTER',
        RegAddrState: 'MA',
        RegAddrZip: 1453,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '97 LELAND AVE',
        RegAddrCity: 'LEOMINSTER',
        RegAddrState: 'MA',
        RegAddrZip: 1453,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '16 WESTVIEW RD',
        RegAddrCity: 'LEOMINSTER',
        RegAddrState: 'MA',
        RegAddrZip: 1453,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '37 CULLEN AVE',
        RegAddrCity: 'LEOMINSTER',
        RegAddrState: 'MA',
        RegAddrZip: 1453,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '15 IRENE CT',
        RegAddrCity: 'MILLBURY',
        RegAddrState: 'MA',
        RegAddrZip: 1527,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '24 TAINTER HILL RD',
        RegAddrCity: 'MILLBURY',
        RegAddrState: 'MA',
        RegAddrZip: 1527,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '84 S MAIN ST',
        RegAddrCity: 'MILLBURY',
        RegAddrState: 'MA',
        RegAddrZip: 1527,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2 PARK VILLA DR A',
        RegAddrCity: 'MONTAGUE',
        RegAddrState: 'MA',
        RegAddrZip: 1376,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '115 BRIGHAM HILL RD',
        RegAddrCity: 'N GRAFTON',
        RegAddrState: 'MA',
        RegAddrZip: 1536,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '210 MILLERS FALLS RD',
        RegAddrCity: 'NORTHFIELD',
        RegAddrState: 'MA',
        RegAddrZip: 1360,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '28 MAYFAIR CIR',
        RegAddrCity: 'OXFORD',
        RegAddrState: 'MA',
        RegAddrZip: 1540,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '15 DOLGE CT',
        RegAddrCity: 'OXFORD',
        RegAddrState: 'MA',
        RegAddrZip: 1540,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '49 MAPLE AVE',
        RegAddrCity: 'RUTLAND',
        RegAddrState: 'MA',
        RegAddrZip: 1543,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1 VALLEY VIEW CIR',
        RegAddrCity: 'RUTLAND',
        RegAddrState: 'MA',
        RegAddrZip: 1543,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '17 OAK RIDGE WAY',
        RegAddrCity: 'SHREWSBURY',
        RegAddrState: 'MA',
        RegAddrZip: 1545,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '17 JANET CIR',
        RegAddrCity: 'SHREWSBURY',
        RegAddrState: 'MA',
        RegAddrZip: 1545,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '15 ELMA CIR',
        RegAddrCity: 'SHREWSBURY',
        RegAddrState: 'MA',
        RegAddrZip: 1545,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '6 OLD CART RD',
        RegAddrCity: 'SHREWSBURY',
        RegAddrState: 'MA',
        RegAddrZip: 1545,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '3 MERRITT AVE',
        RegAddrCity: 'SHREWSBURY',
        RegAddrState: 'MA',
        RegAddrZip: 1545,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '149 WENDELL RD',
        RegAddrCity: 'SHUTESBURY',
        RegAddrState: 'MA',
        RegAddrZip: 1072,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '43 EASTERN AVE',
        RegAddrCity: 'SOUTH DEERFIELD',
        RegAddrState: 'MA',
        RegAddrZip: 1373,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '49 BOUTELLE RD',
        RegAddrCity: 'STERLING',
        RegAddrState: 'MA',
        RegAddrZip: 1564,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '49 OLD PRINCETON RD W',
        RegAddrCity: 'STERLING',
        RegAddrState: 'MA',
        RegAddrZip: 1564,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '15 MUDDY POND RD',
        RegAddrCity: 'STERLING',
        RegAddrState: 'MA',
        RegAddrZip: 1564,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '18 DANA HILL RD',
        RegAddrCity: 'STERLING',
        RegAddrState: 'MA',
        RegAddrZip: 1564,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '3 RUNAWAY BROOK RD',
        RegAddrCity: 'STERLING',
        RegAddrState: 'MA',
        RegAddrZip: 1564,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '21 PROSPECT ST',
        RegAddrCity: 'UPTON',
        RegAddrState: 'MA',
        RegAddrZip: 1568,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '15 GERVAIS WAY',
        RegAddrCity: 'UXBRIDGE',
        RegAddrState: 'MA',
        RegAddrZip: 1569,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '24 SUSAN PKWY',
        RegAddrCity: 'UXBRIDGE',
        RegAddrState: 'MA',
        RegAddrZip: 1569,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '220 DOUGLAS ST',
        RegAddrCity: 'UXBRIDGE',
        RegAddrState: 'MA',
        RegAddrZip: 1569,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '26 EAGLE ST',
        RegAddrCity: 'WARE',
        RegAddrState: 'MA',
        RegAddrZip: 1082,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '12 NELSON ST',
        RegAddrCity: 'WEBSTER',
        RegAddrState: 'MA',
        RegAddrZip: 1570,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '418 WORCESTER ST',
        RegAddrCity: 'WEST BOYLSTON',
        RegAddrState: 'MA',
        RegAddrZip: 1583,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '11 CENTRAL ST',
        RegAddrCity: 'WEST BROOKFIELD',
        RegAddrState: 'MA',
        RegAddrZip: 1585,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '127 HILLCREST RD',
        RegAddrCity: 'WHITINSVILLE',
        RegAddrState: 'MA',
        RegAddrZip: 1588,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '21 HERITAGE DR',
        RegAddrCity: 'WHITINSVILLE',
        RegAddrState: 'MA',
        RegAddrZip: 1588,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '26 CONGER RD',
        RegAddrCity: 'WORCESTER',
        RegAddrState: 'MA',
        RegAddrZip: 1602,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '18 BAUER ST',
        RegAddrCity: 'WORCESTER',
        RegAddrState: 'MA',
        RegAddrZip: 1603,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '11 GENESSEE ST',
        RegAddrCity: 'WORCESTER',
        RegAddrState: 'MA',
        RegAddrZip: 1603,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '61 VINCENT CIR',
        RegAddrCity: 'WORCESTER',
        RegAddrState: 'MA',
        RegAddrZip: 1604,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '58 ACTON ST',
        RegAddrCity: 'WORCESTER',
        RegAddrState: 'MA',
        RegAddrZip: 1604,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '99 UNCATENA AVE',
        RegAddrCity: 'WORCESTER',
        RegAddrState: 'MA',
        RegAddrZip: 1606,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '29 HEROULT RD',
        RegAddrCity: 'WORCESTER',
        RegAddrState: 'MA',
        RegAddrZip: 1606,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '87 CALUMET AVE',
        RegAddrCity: 'WORCESTER',
        RegAddrState: 'MA',
        RegAddrZip: 1606,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2 MATTEO ST',
        RegAddrCity: 'WORCESTER',
        RegAddrState: 'MA',
        RegAddrZip: 1606,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '5 LILAC LN',
        RegAddrCity: 'WORCESTER',
        RegAddrState: 'MA',
        RegAddrZip: 1607,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '275 PLEASANT ST',
        RegAddrCity: 'WORCESTER',
        RegAddrState: 'MA',
        RegAddrZip: 1609,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '74 WILLIAM ST',
        RegAddrCity: 'WORCESTER',
        RegAddrState: 'MA',
        RegAddrZip: 1609,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '4 ALLEN ST',
        RegAddrCity: 'WORCESTER',
        RegAddrState: 'MA',
        RegAddrZip: 1610,
        CongressionalDistrict: 2,
      },
    ],
  },
  {
    name: 'MA-3',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/MA-3?ref=gh-pages',
    state: 'MA',
    state_and_number: 'MA-3',
    zip: 1430,
    congressionalDistrict: 3,
    addressesInDistrict: [
      {
        RegAddrLine1: '5 WINGATE LN',
        RegAddrCity: 'ACTON',
        RegAddrState: 'MA',
        RegAddrZip: 1720,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1 AYER RD',
        RegAddrCity: 'ACTON',
        RegAddrState: 'MA',
        RegAddrZip: 1720,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '27 GREENWOOD RD',
        RegAddrCity: 'ANDOVER',
        RegAddrState: 'MA',
        RegAddrZip: 1810,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '131 CHANDLER RD',
        RegAddrCity: 'ANDOVER',
        RegAddrState: 'MA',
        RegAddrZip: 1810,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '29 STRAWBERRY HILL RD',
        RegAddrCity: 'ANDOVER',
        RegAddrState: 'MA',
        RegAddrZip: 1810,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '156 BEACON ST',
        RegAddrCity: 'ANDOVER',
        RegAddrState: 'MA',
        RegAddrZip: 1810,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '8 DANFORTH CIR',
        RegAddrCity: 'ANDOVER',
        RegAddrState: 'MA',
        RegAddrZip: 1810,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '11 BRECHIN TER',
        RegAddrCity: 'ANDOVER',
        RegAddrState: 'MA',
        RegAddrZip: 1810,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '179 WHITNEY RD',
        RegAddrCity: 'ASHBY',
        RegAddrState: 'MA',
        RegAddrZip: 1431,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '270 JACKSON RD',
        RegAddrCity: 'AYER',
        RegAddrState: 'MA',
        RegAddrZip: 1434,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '371 EAST ST',
        RegAddrCity: 'CARLISLE',
        RegAddrState: 'MA',
        RegAddrZip: 1741,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '28 STEADMAN ST',
        RegAddrCity: 'CHELMSFORD',
        RegAddrState: 'MA',
        RegAddrZip: 1824,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '350 BOSTON RD',
        RegAddrCity: 'CHELMSFORD',
        RegAddrState: 'MA',
        RegAddrZip: 1824,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '12 SUNRISE AVE',
        RegAddrCity: 'CHELMSFORD',
        RegAddrState: 'MA',
        RegAddrZip: 1824,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '105 PEARL ST',
        RegAddrCity: 'CLINTON',
        RegAddrState: 'MA',
        RegAddrZip: 1510,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '51 SPRAGUE RD',
        RegAddrCity: 'CLINTON',
        RegAddrState: 'MA',
        RegAddrZip: 1510,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '39 GARFIELD RD',
        RegAddrCity: 'CONCORD',
        RegAddrState: 'MA',
        RegAddrZip: 1742,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '73 RIDGE RD',
        RegAddrCity: 'CONCORD',
        RegAddrState: 'MA',
        RegAddrZip: 1742,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '61 TURGEON AVE',
        RegAddrCity: 'DRACUT',
        RegAddrState: 'MA',
        RegAddrZip: 1826,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '350 PARKER RD',
        RegAddrCity: 'DRACUT',
        RegAddrState: 'MA',
        RegAddrZip: 1826,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '129 COLONY RD',
        RegAddrCity: 'FITCHBURG',
        RegAddrState: 'MA',
        RegAddrZip: 1420,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '299 ROLLSTONE ST',
        RegAddrCity: 'FITCHBURG',
        RegAddrState: 'MA',
        RegAddrZip: 1420,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '153 MYRTLE AVE',
        RegAddrCity: 'FITCHBURG',
        RegAddrState: 'MA',
        RegAddrZip: 1420,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '11 WILLOW ST',
        RegAddrCity: 'FITCHBURG',
        RegAddrState: 'MA',
        RegAddrZip: 1420,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '101 DOUGLAS RD',
        RegAddrCity: 'GARDNER',
        RegAddrState: 'MA',
        RegAddrZip: 1440,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '21 BOULDER DR',
        RegAddrCity: 'GARDNER',
        RegAddrState: 'MA',
        RegAddrZip: 1440,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '159 SAWYER ST',
        RegAddrCity: 'GARDNER',
        RegAddrState: 'MA',
        RegAddrZip: 1440,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '423 PARTRIDGE ST',
        RegAddrCity: 'GARDNER',
        RegAddrState: 'MA',
        RegAddrZip: 1440,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '30 LOST LAKE DR',
        RegAddrCity: 'GROTON',
        RegAddrState: 'MA',
        RegAddrZip: 1450,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '530 LONGLEY RD',
        RegAddrCity: 'GROTON',
        RegAddrState: 'MA',
        RegAddrZip: 1450,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '47 CROSSWINDS DR',
        RegAddrCity: 'GROTON',
        RegAddrState: 'MA',
        RegAddrZip: 1450,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '20 QUARRY LN',
        RegAddrCity: 'HARVARD',
        RegAddrState: 'MA',
        RegAddrZip: 1451,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '9 ORCHARD HL',
        RegAddrCity: 'HARVARD',
        RegAddrState: 'MA',
        RegAddrZip: 1451,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '5 HARBOR DR',
        RegAddrCity: 'HAVERHILL',
        RegAddrState: 'MA',
        RegAddrZip: 1830,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '380 MIDDLE RD',
        RegAddrCity: 'HAVERHILL',
        RegAddrState: 'MA',
        RegAddrZip: 1830,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '25 10TH AVE',
        RegAddrCity: 'HAVERHILL',
        RegAddrState: 'MA',
        RegAddrZip: 1830,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '10 PRIMROSE WAY',
        RegAddrCity: 'HAVERHILL',
        RegAddrState: 'MA',
        RegAddrZip: 1830,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '550 W LOWELL AVE',
        RegAddrCity: 'HAVERHILL',
        RegAddrState: 'MA',
        RegAddrZip: 1832,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '6 COLBURN CIR',
        RegAddrCity: 'HUDSON',
        RegAddrState: 'MA',
        RegAddrZip: 1749,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '9 OETMAN WAY',
        RegAddrCity: 'LANCASTER',
        RegAddrState: 'MA',
        RegAddrZip: 1523,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '172 LAWRENCE ST',
        RegAddrCity: 'LAWRENCE',
        RegAddrState: 'MA',
        RegAddrZip: 1841,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '208 HIGH ST',
        RegAddrCity: 'LAWRENCE',
        RegAddrState: 'MA',
        RegAddrZip: 1841,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '202 WILLOW ST',
        RegAddrCity: 'LAWRENCE',
        RegAddrState: 'MA',
        RegAddrZip: 1841,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '4 HEY ST',
        RegAddrCity: 'LAWRENCE',
        RegAddrState: 'MA',
        RegAddrZip: 1841,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '9 BOYD ST',
        RegAddrCity: 'LAWRENCE',
        RegAddrState: 'MA',
        RegAddrZip: 1843,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '38 JENNIFER ST',
        RegAddrCity: 'LITTLETON',
        RegAddrState: 'MA',
        RegAddrZip: 1460,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '45 13TH ST',
        RegAddrCity: 'LOWELL',
        RegAddrState: 'MA',
        RegAddrZip: 1850,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '461 WESTFORD ST',
        RegAddrCity: 'LOWELL',
        RegAddrState: 'MA',
        RegAddrZip: 1851,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '204 PRINCETON BLVD',
        RegAddrCity: 'LOWELL',
        RegAddrState: 'MA',
        RegAddrZip: 1851,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '107 BLOSSOM ST',
        RegAddrCity: 'LOWELL',
        RegAddrState: 'MA',
        RegAddrZip: 1852,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '16 LYONS ST',
        RegAddrCity: 'LOWELL',
        RegAddrState: 'MA',
        RegAddrZip: 1852,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '157 REA ST',
        RegAddrCity: 'LOWELL',
        RegAddrState: 'MA',
        RegAddrZip: 1852,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '100 PAWTUCKET ST',
        RegAddrCity: 'LOWELL',
        RegAddrState: 'MA',
        RegAddrZip: 1854,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '322 FLAT HILL RD',
        RegAddrCity: 'LUNENBURG',
        RegAddrState: 'MA',
        RegAddrZip: 1462,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '30 POND ST',
        RegAddrCity: 'LUNENBURG',
        RegAddrState: 'MA',
        RegAddrZip: 1462,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '50 HAMILTON CIR',
        RegAddrCity: 'MARLBOROUGH',
        RegAddrState: 'MA',
        RegAddrZip: 1752,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '649 ROBIN HILL ST',
        RegAddrCity: 'MARLBOROUGH',
        RegAddrState: 'MA',
        RegAddrZip: 1752,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '117 WALTHAM ST',
        RegAddrCity: 'MAYNARD',
        RegAddrState: 'MA',
        RegAddrZip: 1754,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '6 RANDALL RD',
        RegAddrCity: 'MAYNARD',
        RegAddrState: 'MA',
        RegAddrZip: 1754,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '29 ARCHIBALD AVE',
        RegAddrCity: 'METHUEN',
        RegAddrState: 'MA',
        RegAddrZip: 1844,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '26 STILLWATER RD',
        RegAddrCity: 'METHUEN',
        RegAddrState: 'MA',
        RegAddrZip: 1844,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '45 LANDING DR',
        RegAddrCity: 'METHUEN',
        RegAddrState: 'MA',
        RegAddrZip: 1844,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '100 LOWELL ST',
        RegAddrCity: 'METHUEN',
        RegAddrState: 'MA',
        RegAddrZip: 1844,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '3 WALLACE ST',
        RegAddrCity: 'METHUEN',
        RegAddrState: 'MA',
        RegAddrZip: 1844,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '6 GREENMEADOW DR',
        RegAddrCity: 'NORTH BILLERICA',
        RegAddrState: 'MA',
        RegAddrZip: 1862,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '12 FAIRCHILD CT',
        RegAddrCity: 'PEPPERELL',
        RegAddrState: 'MA',
        RegAddrZip: 1463,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '279 GLEASONDALE RD',
        RegAddrCity: 'STOW',
        RegAddrState: 'MA',
        RegAddrZip: 1775,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '3 WORCESTER RD',
        RegAddrCity: 'TOWNSEND',
        RegAddrState: 'MA',
        RegAddrZip: 1469,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '10222 BAY DR',
        RegAddrCity: 'WESTFORD',
        RegAddrState: 'MA',
        RegAddrZip: 1886,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '4 PATRIOT LN',
        RegAddrCity: 'WESTFORD',
        RegAddrState: 'MA',
        RegAddrZip: 1886,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '2 PADDOCK LN',
        RegAddrCity: 'WESTFORD',
        RegAddrState: 'MA',
        RegAddrZip: 1886,
        CongressionalDistrict: 3,
      },
    ],
  },
  {
    name: 'MA-4',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/MA-4?ref=gh-pages',
    state: 'MA',
    state_and_number: 'MA-4',
    zip: 1747,
    congressionalDistrict: 4,
    addressesInDistrict: [
      {
        RegAddrLine1: '30 S HILLSIDE ST',
        RegAddrCity: 'ASSONET',
        RegAddrState: 'MA',
        RegAddrZip: 2702,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '20 BILTMORE ST',
        RegAddrCity: 'ATTLEBORO',
        RegAddrState: 'MA',
        RegAddrZip: 2703,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '48 HOLDEN ST',
        RegAddrCity: 'ATTLEBORO',
        RegAddrState: 'MA',
        RegAddrZip: 2703,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '155 PARK ST',
        RegAddrCity: 'ATTLEBORO',
        RegAddrState: 'MA',
        RegAddrZip: 2703,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '75 GLENDALE RD',
        RegAddrCity: 'ATTLEBORO',
        RegAddrState: 'MA',
        RegAddrZip: 2703,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '190 WASHBURN RD',
        RegAddrCity: 'BARRE',
        RegAddrState: 'MA',
        RegAddrZip: 1005,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '8 BARRETT LN',
        RegAddrCity: 'BELLINGHAM',
        RegAddrState: 'MA',
        RegAddrZip: 2019,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '43 CHESTNUT ST',
        RegAddrCity: 'BELLINGHAM',
        RegAddrState: 'MA',
        RegAddrZip: 2019,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '8 POND ST',
        RegAddrCity: 'BELLINGHAM',
        RegAddrState: 'MA',
        RegAddrZip: 2019,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '4 FRANKLIN CT',
        RegAddrCity: 'BROOKLINE',
        RegAddrState: 'MA',
        RegAddrZip: 2445,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '121 GERRY RD',
        RegAddrCity: 'CHESTNUT HILL',
        RegAddrState: 'MA',
        RegAddrZip: 2467,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '8 SADDLE RIDGE RD',
        RegAddrCity: 'DOVER',
        RegAddrState: 'MA',
        RegAddrZip: 2030,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '730 MERIDIAN ST',
        RegAddrCity: 'FALL RIVER',
        RegAddrState: 'MA',
        RegAddrZip: 2720,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '94 SPRUCE ST',
        RegAddrCity: 'FOXBOROUGH',
        RegAddrState: 'MA',
        RegAddrZip: 2035,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '174 CANNON FORGE DR',
        RegAddrCity: 'FOXBOROUGH',
        RegAddrState: 'MA',
        RegAddrZip: 2035,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '7 CHILDS LN',
        RegAddrCity: 'FOXBOROUGH',
        RegAddrState: 'MA',
        RegAddrZip: 2035,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '15 STONE RIDGE RD',
        RegAddrCity: 'FRANKLIN',
        RegAddrState: 'MA',
        RegAddrZip: 2038,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '21 LOST HORSE TRL',
        RegAddrCity: 'FRANKLIN',
        RegAddrState: 'MA',
        RegAddrZip: 2038,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '45 WESTCOTT RD',
        RegAddrCity: 'HOPEDALE',
        RegAddrState: 'MA',
        RegAddrZip: 1747,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '28 WESTCOTT RD',
        RegAddrCity: 'HOPEDALE',
        RegAddrState: 'MA',
        RegAddrZip: 1747,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '12B MENDON ST',
        RegAddrCity: 'HOPEDALE',
        RegAddrState: 'MA',
        RegAddrZip: 1747,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1 WHALEN RD',
        RegAddrCity: 'HOPKINTON',
        RegAddrState: 'MA',
        RegAddrZip: 1748,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '18 WEDGEWOOD DR',
        RegAddrCity: 'HOPKINTON',
        RegAddrState: 'MA',
        RegAddrZip: 1748,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2 DAVIS RD',
        RegAddrCity: 'HOPKINTON',
        RegAddrState: 'MA',
        RegAddrZip: 1748,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '8 GLEN RD',
        RegAddrCity: 'HOPKINTON',
        RegAddrState: 'MA',
        RegAddrZip: 1748,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '19 PICKENS ST',
        RegAddrCity: 'LAKEVILLE',
        RegAddrState: 'MA',
        RegAddrZip: 2347,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '70 VAUGHAN ST',
        RegAddrCity: 'LAKEVILLE',
        RegAddrState: 'MA',
        RegAddrZip: 2347,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '56 KING ARTHUR WAY',
        RegAddrCity: 'MANSFIELD',
        RegAddrState: 'MA',
        RegAddrZip: 2048,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '29 PEDERZINI DR',
        RegAddrCity: 'MEDFIELD',
        RegAddrState: 'MA',
        RegAddrZip: 2052,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '20 SUMMER HILL RD',
        RegAddrCity: 'MEDWAY',
        RegAddrState: 'MA',
        RegAddrZip: 2053,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '26 MILFORD ST',
        RegAddrCity: 'MEDWAY',
        RegAddrState: 'MA',
        RegAddrZip: 2053,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1 IVY LN',
        RegAddrCity: 'MEDWAY',
        RegAddrState: 'MA',
        RegAddrZip: 2053,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '15 CRESTVIEW AVE',
        RegAddrCity: 'MEDWAY',
        RegAddrState: 'MA',
        RegAddrZip: 2053,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '17 SUNSET DR',
        RegAddrCity: 'MEDWAY',
        RegAddrState: 'MA',
        RegAddrZip: 2053,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '14 MILL POND CIR',
        RegAddrCity: 'MILFORD',
        RegAddrState: 'MA',
        RegAddrZip: 1757,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '10 TOMASO RD',
        RegAddrCity: 'MILFORD',
        RegAddrState: 'MA',
        RegAddrZip: 1757,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '246 PLAIN ST',
        RegAddrCity: 'MILLIS',
        RegAddrState: 'MA',
        RegAddrZip: 2054,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '26 WOODCHIP SQ',
        RegAddrCity: 'N ATTLEBORO',
        RegAddrState: 'MA',
        RegAddrZip: 2760,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '210 ELM TER',
        RegAddrCity: 'N ATTLEBORO',
        RegAddrState: 'MA',
        RegAddrZip: 2760,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '328 TREMONT ST',
        RegAddrCity: 'N DIGHTON',
        RegAddrState: 'MA',
        RegAddrZip: 2764,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '51 ALDEN RD',
        RegAddrCity: 'NEEDHAM',
        RegAddrState: 'MA',
        RegAddrZip: 2492,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '38 REDINGTON RD',
        RegAddrCity: 'NEEDHAM',
        RegAddrState: 'MA',
        RegAddrZip: 2492,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '49 HORACE ST',
        RegAddrCity: 'NEEDHAM',
        RegAddrState: 'MA',
        RegAddrZip: 2494,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '225 KENRICK ST',
        RegAddrCity: 'NEWTON',
        RegAddrState: 'MA',
        RegAddrZip: 2458,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '140 PEARL ST',
        RegAddrCity: 'NEWTON',
        RegAddrState: 'MA',
        RegAddrZip: 2458,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '158 CLARK ST',
        RegAddrCity: 'NEWTON',
        RegAddrState: 'MA',
        RegAddrZip: 2459,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '36 VINEYARD RD',
        RegAddrCity: 'NEWTON',
        RegAddrState: 'MA',
        RegAddrZip: 2459,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '233 GRANT AVE',
        RegAddrCity: 'NEWTON',
        RegAddrState: 'MA',
        RegAddrZip: 2459,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '37 CHAPIN RD',
        RegAddrCity: 'NEWTON',
        RegAddrState: 'MA',
        RegAddrZip: 2459,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '75 COMMONWEALTH PARK W',
        RegAddrCity: 'NEWTON',
        RegAddrState: 'MA',
        RegAddrZip: 2459,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '31 DANIEL ST',
        RegAddrCity: 'NEWTON',
        RegAddrState: 'MA',
        RegAddrZip: 2459,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '30 PAYNE RD',
        RegAddrCity: 'NEWTON',
        RegAddrState: 'MA',
        RegAddrZip: 2461,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '37 ELLIS RD',
        RegAddrCity: 'NEWTON',
        RegAddrState: 'MA',
        RegAddrZip: 2465,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '15 BULLOUGH PARK',
        RegAddrCity: 'NEWTONVILLE',
        RegAddrState: 'MA',
        RegAddrZip: 2460,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '21 WORCESTER RD',
        RegAddrCity: 'NORFOLK',
        RegAddrState: 'MA',
        RegAddrZip: 2056,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '11 CRANBERRY MEADOW RD',
        RegAddrCity: 'NORFOLK',
        RegAddrState: 'MA',
        RegAddrZip: 2056,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2 STACEY RD',
        RegAddrCity: 'NORFOLK',
        RegAddrState: 'MA',
        RegAddrZip: 2056,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '42 SERENADE PARK',
        RegAddrCity: 'NORTH EASTON',
        RegAddrState: 'MA',
        RegAddrZip: 2356,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '54 MASSAPOAG AVE',
        RegAddrCity: 'NORTH EASTON',
        RegAddrState: 'MA',
        RegAddrZip: 2356,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '16 DANIEL DR',
        RegAddrCity: 'NORTH EASTON',
        RegAddrState: 'MA',
        RegAddrZip: 2356,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '21 WILBUR ST',
        RegAddrCity: 'NORTON',
        RegAddrState: 'MA',
        RegAddrZip: 2766,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '13 CROSS ST',
        RegAddrCity: 'NORTON',
        RegAddrState: 'MA',
        RegAddrZip: 2766,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '57 SOUTH ST',
        RegAddrCity: 'PLAINVILLE',
        RegAddrState: 'MA',
        RegAddrZip: 2762,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '26 NOTTINGHAM DR',
        RegAddrCity: 'RAYNHAM',
        RegAddrState: 'MA',
        RegAddrZip: 2767,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '172 PLAIN ST',
        RegAddrCity: 'REHOBOTH',
        RegAddrState: 'MA',
        RegAddrZip: 2769,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '10 GLASGOW ST',
        RegAddrCity: 'SEEKONK',
        RegAddrState: 'MA',
        RegAddrZip: 2771,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '30 DEHART AVE',
        RegAddrCity: 'SHARON',
        RegAddrState: 'MA',
        RegAddrZip: 2067,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '80 N MAIN ST',
        RegAddrCity: 'SHARON',
        RegAddrState: 'MA',
        RegAddrZip: 2067,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '6 MAGNOLIA RD',
        RegAddrCity: 'SHARON',
        RegAddrState: 'MA',
        RegAddrZip: 2067,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '110 WASHINGTON AVE',
        RegAddrCity: 'SOMERSET',
        RegAddrState: 'MA',
        RegAddrZip: 2726,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1035 ALMY RD',
        RegAddrCity: 'SOMERSET',
        RegAddrState: 'MA',
        RegAddrZip: 2726,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '19 KATHLEEN GRANT RD',
        RegAddrCity: 'SOUTH EASTON',
        RegAddrState: 'MA',
        RegAddrZip: 2375,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '146 OCEAN VIEW AVE',
        RegAddrCity: 'SWANSEA',
        RegAddrState: 'MA',
        RegAddrZip: 2777,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '24 SILVERWOOD DR',
        RegAddrCity: 'TAUNTON',
        RegAddrState: 'MA',
        RegAddrZip: 2780,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '840 COUNTY ST 102',
        RegAddrCity: 'TAUNTON',
        RegAddrState: 'MA',
        RegAddrZip: 2780,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '124 WILLIAMS ST',
        RegAddrCity: 'TAUNTON',
        RegAddrState: 'MA',
        RegAddrZip: 2780,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '60 PLAIN ST',
        RegAddrCity: 'TAUNTON',
        RegAddrState: 'MA',
        RegAddrZip: 2780,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '6 PINEWOOD CIR',
        RegAddrCity: 'WELLESLEY',
        RegAddrState: 'MA',
        RegAddrZip: 2482,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '38 RUSSELL RD',
        RegAddrCity: 'WELLESLEY',
        RegAddrState: 'MA',
        RegAddrZip: 2482,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '23 IVY RD',
        RegAddrCity: 'WELLESLEY',
        RegAddrState: 'MA',
        RegAddrZip: 2482,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '39 FARRINGTON AVE',
        RegAddrCity: 'WRENTHAM',
        RegAddrState: 'MA',
        RegAddrZip: 2093,
        CongressionalDistrict: 4,
      },
    ],
  },
  {
    name: 'MA-5',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/MA-5?ref=gh-pages',
    state: 'MA',
    state_and_number: 'MA-5',
    zip: 1701,
    congressionalDistrict: 5,
    addressesInDistrict: [
      {
        RegAddrLine1: '35 BURCH ST',
        RegAddrCity: 'ARLINGTON',
        RegAddrState: 'MA',
        RegAddrZip: 2474,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '25 JOHNSON RD',
        RegAddrCity: 'ARLINGTON',
        RegAddrState: 'MA',
        RegAddrZip: 2474,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '145 FLORENCE AVE',
        RegAddrCity: 'ARLINGTON',
        RegAddrState: 'MA',
        RegAddrZip: 2476,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '67 BARTLETT AVE',
        RegAddrCity: 'ARLINGTON',
        RegAddrState: 'MA',
        RegAddrZip: 2476,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '147 TRAILSIDE WAY',
        RegAddrCity: 'ASHLAND',
        RegAddrState: 'MA',
        RegAddrZip: 1721,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '153 PLEASANT ST',
        RegAddrCity: 'ASHLAND',
        RegAddrState: 'MA',
        RegAddrZip: 1721,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '100 LONG AVE',
        RegAddrCity: 'BELMONT',
        RegAddrState: 'MA',
        RegAddrZip: 2478,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '92 CLARK ST',
        RegAddrCity: 'BELMONT',
        RegAddrState: 'MA',
        RegAddrZip: 2478,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '12 CHILTON ST',
        RegAddrCity: 'CAMBRIDGE',
        RegAddrState: 'MA',
        RegAddrZip: 2138,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '49 WALKER ST',
        RegAddrCity: 'CAMBRIDGE',
        RegAddrState: 'MA',
        RegAddrZip: 2138,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '10 SIBLEY CT',
        RegAddrCity: 'CAMBRIDGE',
        RegAddrState: 'MA',
        RegAddrZip: 2138,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '13 FENWOOD ST',
        RegAddrCity: 'FRAMINGHAM',
        RegAddrState: 'MA',
        RegAddrZip: 1701,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '7 MEADOW LN',
        RegAddrCity: 'FRAMINGHAM',
        RegAddrState: 'MA',
        RegAddrZip: 1701,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '2 BONITO DR',
        RegAddrCity: 'FRAMINGHAM',
        RegAddrState: 'MA',
        RegAddrZip: 1701,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '14 FAIRBROOK RD',
        RegAddrCity: 'FRAMINGHAM',
        RegAddrState: 'MA',
        RegAddrZip: 1701,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '21 POPLAR ST',
        RegAddrCity: 'FRAMINGHAM',
        RegAddrState: 'MA',
        RegAddrZip: 1701,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '6 LOUIS RD',
        RegAddrCity: 'FRAMINGHAM',
        RegAddrState: 'MA',
        RegAddrZip: 1702,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '35 GUILD RD',
        RegAddrCity: 'FRAMINGHAM',
        RegAddrState: 'MA',
        RegAddrZip: 1702,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '3 MERCHANT RD',
        RegAddrCity: 'FRAMINGHAM',
        RegAddrState: 'MA',
        RegAddrZip: 1702,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '15 CAROL LN',
        RegAddrCity: 'LEXINGTON',
        RegAddrState: 'MA',
        RegAddrZip: 2420,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '18 WYMAN RD',
        RegAddrCity: 'LEXINGTON',
        RegAddrState: 'MA',
        RegAddrZip: 2420,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '30 COLONY RD',
        RegAddrCity: 'LEXINGTON',
        RegAddrState: 'MA',
        RegAddrZip: 2420,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '5 CURRIER CT',
        RegAddrCity: 'LEXINGTON',
        RegAddrState: 'MA',
        RegAddrZip: 2420,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '3 FAIRLAND ST',
        RegAddrCity: 'LEXINGTON',
        RegAddrState: 'MA',
        RegAddrZip: 2421,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '6221 LEXINGTON RIDGE DR',
        RegAddrCity: 'LEXINGTON',
        RegAddrState: 'MA',
        RegAddrZip: 2421,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '34 GOOSE POND RD',
        RegAddrCity: 'LINCOLN',
        RegAddrState: 'MA',
        RegAddrZip: 1773,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '11 BYRON ST',
        RegAddrCity: 'MALDEN',
        RegAddrState: 'MA',
        RegAddrZip: 2148,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '20 WYOMING AVE',
        RegAddrCity: 'MALDEN',
        RegAddrState: 'MA',
        RegAddrZip: 2148,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '160 HAWTHORNE ST',
        RegAddrCity: 'MALDEN',
        RegAddrState: 'MA',
        RegAddrZip: 2148,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '18 JOY TER',
        RegAddrCity: 'MALDEN',
        RegAddrState: 'MA',
        RegAddrZip: 2148,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '21 WHEELER ST',
        RegAddrCity: 'MALDEN',
        RegAddrState: 'MA',
        RegAddrZip: 2148,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '9 BRADBURY AVE',
        RegAddrCity: 'MEDFORD',
        RegAddrState: 'MA',
        RegAddrZip: 2155,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '32 LOCUST ST',
        RegAddrCity: 'MEDFORD',
        RegAddrState: 'MA',
        RegAddrZip: 2155,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '11 PEARL ST',
        RegAddrCity: 'MEDFORD',
        RegAddrState: 'MA',
        RegAddrZip: 2155,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '69 FOUNTAIN ST',
        RegAddrCity: 'MEDFORD',
        RegAddrState: 'MA',
        RegAddrZip: 2155,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '11 DEXTER ST',
        RegAddrCity: 'MEDFORD',
        RegAddrState: 'MA',
        RegAddrZip: 2155,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '387 BOSTON AVE',
        RegAddrCity: 'MEDFORD',
        RegAddrState: 'MA',
        RegAddrZip: 2155,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '8 WHITMAN RD',
        RegAddrCity: 'MEDFORD',
        RegAddrState: 'MA',
        RegAddrZip: 2155,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '58 SEARS AVE',
        RegAddrCity: 'MELROSE',
        RegAddrState: 'MA',
        RegAddrZip: 2176,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '10 EDGEMERE ST',
        RegAddrCity: 'MELROSE',
        RegAddrState: 'MA',
        RegAddrZip: 2176,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '115 UPHAM ST',
        RegAddrCity: 'MELROSE',
        RegAddrState: 'MA',
        RegAddrZip: 2176,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '17 CURTIS RD',
        RegAddrCity: 'NATICK',
        RegAddrState: 'MA',
        RegAddrZip: 1760,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '17 VALLEY RD',
        RegAddrCity: 'NATICK',
        RegAddrState: 'MA',
        RegAddrZip: 1760,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '17 LAKEVIEW AVE',
        RegAddrCity: 'NATICK',
        RegAddrState: 'MA',
        RegAddrZip: 1760,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '39 CASE DR',
        RegAddrCity: 'REVERE',
        RegAddrState: 'MA',
        RegAddrZip: 2151,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '153 SOUTHVILLE RD',
        RegAddrCity: 'SOUTHBOROUGH',
        RegAddrState: 'MA',
        RegAddrZip: 1772,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '8 LYMAN ST',
        RegAddrCity: 'SOUTHBOROUGH',
        RegAddrState: 'MA',
        RegAddrZip: 1772,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '7 DANIEL DR',
        RegAddrCity: 'STONEHAM',
        RegAddrState: 'MA',
        RegAddrZip: 2180,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '56 HIGH ST',
        RegAddrCity: 'STONEHAM',
        RegAddrState: 'MA',
        RegAddrZip: 2180,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '18 MONTCLAIR AVE',
        RegAddrCity: 'WALTHAM',
        RegAddrState: 'MA',
        RegAddrZip: 2451,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '175 SUMMER ST',
        RegAddrCity: 'WALTHAM',
        RegAddrState: 'MA',
        RegAddrZip: 2452,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '415 SOUTH ST',
        RegAddrCity: 'WALTHAM',
        RegAddrState: 'MA',
        RegAddrZip: 2453,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '25 CHESTER ST',
        RegAddrCity: 'WATERTOWN',
        RegAddrState: 'MA',
        RegAddrZip: 2472,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '248 BOYLSTON ST',
        RegAddrCity: 'WATERTOWN',
        RegAddrState: 'MA',
        RegAddrZip: 2472,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '84 PIERCE RD',
        RegAddrCity: 'WATERTOWN',
        RegAddrState: 'MA',
        RegAddrZip: 2472,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '15 MELENDY AVE',
        RegAddrCity: 'WATERTOWN',
        RegAddrState: 'MA',
        RegAddrZip: 2472,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '32 HIGHLAND CIR',
        RegAddrCity: 'WAYLAND',
        RegAddrState: 'MA',
        RegAddrZip: 1778,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '10 WESTWOOD DR',
        RegAddrCity: 'WESTFIELD',
        RegAddrState: 'MA',
        RegAddrZip: 1085,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '48 THORNBERRY RD',
        RegAddrCity: 'WINCHESTER',
        RegAddrState: 'MA',
        RegAddrZip: 1890,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '388 CAMBRIDGE ST',
        RegAddrCity: 'WINCHESTER',
        RegAddrState: 'MA',
        RegAddrZip: 1890,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '36 HARVARD ST',
        RegAddrCity: 'WINCHESTER',
        RegAddrState: 'MA',
        RegAddrZip: 1890,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '19 PIERREPONT RD',
        RegAddrCity: 'WINCHESTER',
        RegAddrState: 'MA',
        RegAddrZip: 1890,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '43 COURT RD',
        RegAddrCity: 'WINTHROP',
        RegAddrState: 'MA',
        RegAddrZip: 2152,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '8 VALLEY RD',
        RegAddrCity: 'WOBURN',
        RegAddrState: 'MA',
        RegAddrZip: 1801,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '13 MAYWOOD TER',
        RegAddrCity: 'WOBURN',
        RegAddrState: 'MA',
        RegAddrZip: 1801,
        CongressionalDistrict: 5,
      },
    ],
  },
  {
    name: 'MA-6',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/MA-6?ref=gh-pages',
    state: 'MA',
    state_and_number: 'MA-6',
    zip: 1803,
    congressionalDistrict: 6,
    addressesInDistrict: [
      {
        RegAddrLine1: '63 HUNTINGTON AVE',
        RegAddrCity: 'AMESBURY',
        RegAddrState: 'MA',
        RegAddrZip: 1913,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '15 BELMONT ST',
        RegAddrCity: 'AMESBURY',
        RegAddrState: 'MA',
        RegAddrZip: 1913,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '6 WELLS AVE',
        RegAddrCity: 'AMESBURY',
        RegAddrState: 'MA',
        RegAddrZip: 1913,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '143 PLEASANT VALLEY RD',
        RegAddrCity: 'AMESBURY',
        RegAddrState: 'MA',
        RegAddrZip: 1913,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '295 CONCORD RD',
        RegAddrCity: 'BEDFORD',
        RegAddrState: 'MA',
        RegAddrZip: 1730,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '13 NORMA RD',
        RegAddrCity: 'BEDFORD',
        RegAddrState: 'MA',
        RegAddrZip: 1730,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '3 CONIFER WAY',
        RegAddrCity: 'BEVERLY',
        RegAddrState: 'MA',
        RegAddrZip: 1915,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '56 STORY AVE',
        RegAddrCity: 'BEVERLY',
        RegAddrState: 'MA',
        RegAddrZip: 1915,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '10 PALMER RD',
        RegAddrCity: 'BEVERLY',
        RegAddrState: 'MA',
        RegAddrZip: 1915,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '402 BROUGHTON DR',
        RegAddrCity: 'BEVERLY',
        RegAddrState: 'MA',
        RegAddrZip: 1915,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '24 BERTRAM ST',
        RegAddrCity: 'BEVERLY',
        RegAddrState: 'MA',
        RegAddrZip: 1915,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '7 LEECH ST',
        RegAddrCity: 'BEVERLY',
        RegAddrState: 'MA',
        RegAddrZip: 1915,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '3 VINCENT ST',
        RegAddrCity: 'BILLERICA',
        RegAddrState: 'MA',
        RegAddrZip: 1821,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '141 BALDWIN RD',
        RegAddrCity: 'BILLERICA',
        RegAddrState: 'MA',
        RegAddrZip: 1821,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '34 SHAWSHEEN RD',
        RegAddrCity: 'BILLERICA',
        RegAddrState: 'MA',
        RegAddrZip: 1821,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '42 BALDWIN RD',
        RegAddrCity: 'BILLERICA',
        RegAddrState: 'MA',
        RegAddrZip: 1821,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '109 BARE HILL RD',
        RegAddrCity: 'BOXFORD',
        RegAddrState: 'MA',
        RegAddrZip: 1921,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '53 SILVERMINE RD',
        RegAddrCity: 'BOXFORD',
        RegAddrState: 'MA',
        RegAddrZip: 1921,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '396 MAIN ST',
        RegAddrCity: 'BOXFORD',
        RegAddrState: 'MA',
        RegAddrZip: 1921,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '31 CRYSTAL CIR',
        RegAddrCity: 'BURLINGTON',
        RegAddrState: 'MA',
        RegAddrZip: 1803,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '105 WILMINGTON RD',
        RegAddrCity: 'BURLINGTON',
        RegAddrState: 'MA',
        RegAddrZip: 1803,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '124 ARBORETUM WAY',
        RegAddrCity: 'BURLINGTON',
        RegAddrState: 'MA',
        RegAddrZip: 1803,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '6 GORMAN RD',
        RegAddrCity: 'DANVERS',
        RegAddrState: 'MA',
        RegAddrZip: 1923,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '40 LAWRENCE ST',
        RegAddrCity: 'DANVERS',
        RegAddrState: 'MA',
        RegAddrZip: 1923,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '4 CROSSMAN LN',
        RegAddrCity: 'DANVERS',
        RegAddrState: 'MA',
        RegAddrZip: 1923,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '10223 BAY DR',
        RegAddrCity: 'DANVERS',
        RegAddrState: 'MA',
        RegAddrZip: 1923,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '3 WALDINGFIELD RD',
        RegAddrCity: 'GEORGETOWN',
        RegAddrState: 'MA',
        RegAddrZip: 1833,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '3 MILLETT ST',
        RegAddrCity: 'GLOUCESTER',
        RegAddrState: 'MA',
        RegAddrZip: 1930,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '9 DODGE ST',
        RegAddrCity: 'GLOUCESTER',
        RegAddrState: 'MA',
        RegAddrZip: 1930,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '22 WILLOWOOD RD',
        RegAddrCity: 'GLOUCESTER',
        RegAddrState: 'MA',
        RegAddrZip: 1930,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '52 CHERRY ST',
        RegAddrCity: 'GLOUCESTER',
        RegAddrState: 'MA',
        RegAddrZip: 1930,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '199 ARGILLA RD',
        RegAddrCity: 'IPSWICH',
        RegAddrState: 'MA',
        RegAddrZip: 1938,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '39 BROADWAY AVENUE EXT',
        RegAddrCity: 'IPSWICH',
        RegAddrState: 'MA',
        RegAddrZip: 1938,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '69 LEXINGTON ST',
        RegAddrCity: 'LYNN',
        RegAddrState: 'MA',
        RegAddrZip: 1902,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '14 MCKINLEY TER',
        RegAddrCity: 'LYNN',
        RegAddrState: 'MA',
        RegAddrZip: 1902,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '364 ESSEX ST',
        RegAddrCity: 'LYNN',
        RegAddrState: 'MA',
        RegAddrZip: 1902,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '41 CARTER RD',
        RegAddrCity: 'LYNN',
        RegAddrState: 'MA',
        RegAddrZip: 1904,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '132 COMMONWEALTH RD',
        RegAddrCity: 'LYNN',
        RegAddrState: 'MA',
        RegAddrZip: 1904,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '64 FLINT ST',
        RegAddrCity: 'LYNN',
        RegAddrState: 'MA',
        RegAddrZip: 1905,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '103 WARREN ST',
        RegAddrCity: 'LYNN',
        RegAddrState: 'MA',
        RegAddrZip: 1905,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '64 ELMWOOD AVE',
        RegAddrCity: 'LYNN',
        RegAddrState: 'MA',
        RegAddrZip: 1905,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '41 FAIRVIEW RD',
        RegAddrCity: 'LYNNFIELD',
        RegAddrState: 'MA',
        RegAddrZip: 1940,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '14 HUNTINGDON RD',
        RegAddrCity: 'LYNNFIELD',
        RegAddrState: 'MA',
        RegAddrZip: 1940,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '92B SUMMER ST',
        RegAddrCity: 'MANCHESTER',
        RegAddrState: 'MA',
        RegAddrZip: 1944,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '49 STONY BROOK RD',
        RegAddrCity: 'MARBLEHEAD',
        RegAddrState: 'MA',
        RegAddrZip: 1945,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '43 PILGRIM RD',
        RegAddrCity: 'MARBLEHEAD',
        RegAddrState: 'MA',
        RegAddrZip: 1945,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '334 OCEAN AVE',
        RegAddrCity: 'MARBLEHEAD',
        RegAddrState: 'MA',
        RegAddrZip: 1945,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '29 ROSE AVE',
        RegAddrCity: 'MARBLEHEAD',
        RegAddrState: 'MA',
        RegAddrZip: 1945,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '3 HEATH BROOK RD',
        RegAddrCity: 'MERRIMAC',
        RegAddrState: 'MA',
        RegAddrZip: 1860,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '129B RIVER ST',
        RegAddrCity: 'MIDDLETON',
        RegAddrState: 'MA',
        RegAddrZip: 1949,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '17 PARLMONT PARK',
        RegAddrCity: 'N BILLERICA',
        RegAddrState: 'MA',
        RegAddrZip: 1862,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '52A FERRY RD',
        RegAddrCity: 'NEWBURYPORT',
        RegAddrState: 'MA',
        RegAddrZip: 1950,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '7 BONNY LN',
        RegAddrCity: 'NORTH ANDOVER',
        RegAddrState: 'MA',
        RegAddrZip: 1845,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '275 WEBSTER WOODS LN',
        RegAddrCity: 'NORTH ANDOVER',
        RegAddrState: 'MA',
        RegAddrZip: 1845,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '24 POOR ST',
        RegAddrCity: 'NORTH ANDOVER',
        RegAddrState: 'MA',
        RegAddrZip: 1845,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '5 BRAGG ST',
        RegAddrCity: 'PEABODY',
        RegAddrState: 'MA',
        RegAddrZip: 1960,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '6232 CRANE BROOK WAY',
        RegAddrCity: 'PEABODY',
        RegAddrState: 'MA',
        RegAddrZip: 1960,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '72 GOODALE ST',
        RegAddrCity: 'PEABODY',
        RegAddrState: 'MA',
        RegAddrZip: 1960,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '22 BROAD ST',
        RegAddrCity: 'PEABODY',
        RegAddrState: 'MA',
        RegAddrZip: 1960,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '9 JOY RD',
        RegAddrCity: 'PEABODY',
        RegAddrState: 'MA',
        RegAddrZip: 1960,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '107 LYNN ST',
        RegAddrCity: 'PEABODY',
        RegAddrState: 'MA',
        RegAddrZip: 1960,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '55 HEATHER DR',
        RegAddrCity: 'READING',
        RegAddrState: 'MA',
        RegAddrZip: 1867,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '9 BENTON CIR',
        RegAddrCity: 'READING',
        RegAddrState: 'MA',
        RegAddrZip: 1867,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '4 EASTWAY',
        RegAddrCity: 'READING',
        RegAddrState: 'MA',
        RegAddrZip: 1867,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '20 RUTHERN WAY',
        RegAddrCity: 'ROCKPORT',
        RegAddrState: 'MA',
        RegAddrZip: 1966,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '4 APPALOOSA LN',
        RegAddrCity: 'S HAMILTON',
        RegAddrState: 'MA',
        RegAddrZip: 1982,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '53 TREMONT ST',
        RegAddrCity: 'SALEM',
        RegAddrState: 'MA',
        RegAddrZip: 1970,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '37 ORD ST',
        RegAddrCity: 'SALEM',
        RegAddrState: 'MA',
        RegAddrZip: 1970,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '44 CROWDIS ST',
        RegAddrCity: 'SALEM',
        RegAddrState: 'MA',
        RegAddrZip: 1970,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '44 NORTH END BLVD',
        RegAddrCity: 'SALISBURY',
        RegAddrState: 'MA',
        RegAddrZip: 1952,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '31 INTERVALE AVE',
        RegAddrCity: 'SAUGUS',
        RegAddrState: 'MA',
        RegAddrZip: 1906,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '102 BASSWOOD AVE',
        RegAddrCity: 'SAUGUS',
        RegAddrState: 'MA',
        RegAddrZip: 1906,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '12 MOUNT PLEASANT ST',
        RegAddrCity: 'SAUGUS',
        RegAddrState: 'MA',
        RegAddrZip: 1906,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '468 HUMPHREY ST',
        RegAddrCity: 'SWAMPSCOTT',
        RegAddrState: 'MA',
        RegAddrZip: 1907,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '15 PINE ST',
        RegAddrCity: 'TEWKSBURY',
        RegAddrState: 'MA',
        RegAddrZip: 1876,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '70 OVERLOOK DR',
        RegAddrCity: 'TEWKSBURY',
        RegAddrState: 'MA',
        RegAddrZip: 1876,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '28 FREDERICK CIR',
        RegAddrCity: 'TEWKSBURY',
        RegAddrState: 'MA',
        RegAddrZip: 1876,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '160 N BILLERICA RD',
        RegAddrCity: 'TEWKSBURY',
        RegAddrState: 'MA',
        RegAddrZip: 1876,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '58 PROSPECT ST',
        RegAddrCity: 'TOPSFIELD',
        RegAddrState: 'MA',
        RegAddrZip: 1983,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '25 WAKEFIELD AVE',
        RegAddrCity: 'WAKEFIELD',
        RegAddrState: 'MA',
        RegAddrZip: 1880,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '42 PLEASANT ST',
        RegAddrCity: 'WAKEFIELD',
        RegAddrState: 'MA',
        RegAddrZip: 1880,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '7 RHODE ISLAND RD',
        RegAddrCity: 'WILMINGTON',
        RegAddrState: 'MA',
        RegAddrZip: 1887,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '6 STATE ST',
        RegAddrCity: 'WILMINGTON',
        RegAddrState: 'MA',
        RegAddrZip: 1887,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '160 GROVE AVE',
        RegAddrCity: 'WILMINGTON',
        RegAddrState: 'MA',
        RegAddrZip: 1887,
        CongressionalDistrict: 6,
      },
    ],
  },
  {
    name: 'MA-7',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/MA-7?ref=gh-pages',
    state: 'MA',
    state_and_number: 'MA-7',
    zip: 2110,
    congressionalDistrict: 7,
    addressesInDistrict: [
      {
        RegAddrLine1: '379 BEACON ST',
        RegAddrCity: 'BOSTON',
        RegAddrState: 'MA',
        RegAddrZip: 2116,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '889 HARRISON AVE',
        RegAddrCity: 'BOSTON',
        RegAddrState: 'MA',
        RegAddrZip: 2118,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '3 HILTON TER',
        RegAddrCity: 'BOSTON',
        RegAddrState: 'MA',
        RegAddrZip: 2119,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '59 OLNEY ST',
        RegAddrCity: 'BOSTON',
        RegAddrState: 'MA',
        RegAddrZip: 2121,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '32 GREENWOOD ST',
        RegAddrCity: 'BOSTON',
        RegAddrState: 'MA',
        RegAddrZip: 2121,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '17 WELLESLEY PARK',
        RegAddrCity: 'BOSTON',
        RegAddrState: 'MA',
        RegAddrZip: 2124,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '243 ITASCA ST',
        RegAddrCity: 'BOSTON',
        RegAddrState: 'MA',
        RegAddrZip: 2126,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '76 GLADESIDE AVE',
        RegAddrCity: 'BOSTON',
        RegAddrState: 'MA',
        RegAddrZip: 2126,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '1239 MORTON ST',
        RegAddrCity: 'BOSTON',
        RegAddrState: 'MA',
        RegAddrZip: 2126,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '18 CLIPPER SHIP LN',
        RegAddrCity: 'BOSTON',
        RegAddrState: 'MA',
        RegAddrZip: 2128,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '68 MARGINAL ST',
        RegAddrCity: 'BOSTON',
        RegAddrState: 'MA',
        RegAddrZip: 2128,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '37 WINTON ST',
        RegAddrCity: 'BOSTON',
        RegAddrState: 'MA',
        RegAddrZip: 2131,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '271 BLUE LEDGE DR',
        RegAddrCity: 'BOSTON',
        RegAddrState: 'MA',
        RegAddrZip: 2131,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '32 KENRICK ST',
        RegAddrCity: 'BOSTON',
        RegAddrState: 'MA',
        RegAddrZip: 2135,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '35 WAVERLY ST',
        RegAddrCity: 'BOSTON',
        RegAddrState: 'MA',
        RegAddrZip: 2135,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '10 CLIFFORD ST',
        RegAddrCity: 'BOSTON',
        RegAddrState: 'MA',
        RegAddrZip: 2136,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '18 DONNA TER',
        RegAddrCity: 'BOSTON',
        RegAddrState: 'MA',
        RegAddrZip: 2136,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '9 PERLEY LN',
        RegAddrCity: 'BOXFORD',
        RegAddrState: 'MA',
        RegAddrZip: 1921,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '11 CLY CLYDE ST',
        RegAddrCity: 'CHELSEA',
        RegAddrState: 'MA',
        RegAddrZip: 2150,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '23 LINCOLN ST',
        RegAddrCity: 'EVERETT',
        RegAddrState: 'MA',
        RegAddrZip: 2149,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '129 BELL ROCK ST',
        RegAddrCity: 'EVERETT',
        RegAddrState: 'MA',
        RegAddrZip: 2149,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '25 DUNCAN RD',
        RegAddrCity: 'EVERETT',
        RegAddrState: 'MA',
        RegAddrZip: 2149,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '135 S HUNTINGTON AVE',
        RegAddrCity: 'JAMAICA PLAIN',
        RegAddrState: 'MA',
        RegAddrZip: 2130,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '482 ATHERTON ST',
        RegAddrCity: 'MILTON',
        RegAddrState: 'MA',
        RegAddrZip: 2186,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '1000 BLUE HILL AVE',
        RegAddrCity: 'MILTON',
        RegAddrState: 'MA',
        RegAddrZip: 2186,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '16 N RICHWOOD AVE',
        RegAddrCity: 'RANDOLPH',
        RegAddrState: 'MA',
        RegAddrZip: 2368,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '53 FITCH TER',
        RegAddrCity: 'RANDOLPH',
        RegAddrState: 'MA',
        RegAddrZip: 2368,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '134 ALLEN ST',
        RegAddrCity: 'RANDOLPH',
        RegAddrState: 'MA',
        RegAddrZip: 2368,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '37 CEDAR ST',
        RegAddrCity: 'ROXBURY',
        RegAddrState: 'MA',
        RegAddrZip: 2119,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '8 BOWDOIN ST',
        RegAddrCity: 'SOMERVILLE',
        RegAddrState: 'MA',
        RegAddrZip: 2143,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '451 SOMERVILLE AVE',
        RegAddrCity: 'SOMERVILLE',
        RegAddrState: 'MA',
        RegAddrZip: 2143,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '6 ALDERSEY ST',
        RegAddrCity: 'SOMERVILLE',
        RegAddrState: 'MA',
        RegAddrZip: 2143,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '160 HOLLAND ST',
        RegAddrCity: 'SOMERVILLE',
        RegAddrState: 'MA',
        RegAddrZip: 2144,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '144 MORRISON AVE',
        RegAddrCity: 'SOMERVILLE',
        RegAddrState: 'MA',
        RegAddrZip: 2144,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '12 GOVERNOR WINTHROP RD',
        RegAddrCity: 'SOMERVILLE',
        RegAddrState: 'MA',
        RegAddrZip: 2145,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '2 SAGAMORE RD',
        RegAddrCity: 'WELLESLEY HILLS',
        RegAddrState: 'MA',
        RegAddrZip: 2481,
        CongressionalDistrict: 7,
      },
    ],
  },
  {
    name: 'MA-8',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/MA-8?ref=gh-pages',
    state: 'MA',
    state_and_number: 'MA-8',
    zip: 2021,
    congressionalDistrict: 8,
    addressesInDistrict: [
      {
        RegAddrLine1: '65 PILGRIM ST',
        RegAddrCity: 'ABINGTON',
        RegAddrState: 'MA',
        RegAddrZip: 2351,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '18 EASTVIEW AVE',
        RegAddrCity: 'BILLERICA',
        RegAddrState: 'MA',
        RegAddrZip: 1821,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '5 CHAMPNEY PL',
        RegAddrCity: 'BOSTON',
        RegAddrState: 'MA',
        RegAddrZip: 2114,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '51 BOUTWELL ST',
        RegAddrCity: 'BOSTON',
        RegAddrState: 'MA',
        RegAddrZip: 2122,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '130 DORCHESTER ST',
        RegAddrCity: 'BOSTON',
        RegAddrState: 'MA',
        RegAddrZip: 2127,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '575 E 8TH ST',
        RegAddrCity: 'BOSTON',
        RegAddrState: 'MA',
        RegAddrZip: 2127,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '1200 CENTRE ST',
        RegAddrCity: 'BOSTON',
        RegAddrState: 'MA',
        RegAddrZip: 2131,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '8 FLETCHER ST',
        RegAddrCity: 'BOSTON',
        RegAddrState: 'MA',
        RegAddrZip: 2131,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '11 HILLOCK ST',
        RegAddrCity: 'BOSTON',
        RegAddrState: 'MA',
        RegAddrZip: 2131,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '75 HASTINGS ST',
        RegAddrCity: 'BOSTON',
        RegAddrState: 'MA',
        RegAddrZip: 2132,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '280 BELLEVUE ST',
        RegAddrCity: 'BOSTON',
        RegAddrState: 'MA',
        RegAddrZip: 2132,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '592 LAGRANGE ST',
        RegAddrCity: 'BOSTON',
        RegAddrState: 'MA',
        RegAddrZip: 2132,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '36 ELEANOR DR',
        RegAddrCity: 'BRAINTREE',
        RegAddrState: 'MA',
        RegAddrZip: 2184,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '96 WILKINS RD',
        RegAddrCity: 'BRAINTREE',
        RegAddrState: 'MA',
        RegAddrZip: 2184,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '451 QUINCY AVE',
        RegAddrCity: 'BRAINTREE',
        RegAddrState: 'MA',
        RegAddrZip: 2184,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '95 COMMERCIAL ST',
        RegAddrCity: 'BRAINTREE',
        RegAddrState: 'MA',
        RegAddrZip: 2184,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '95 WALNUT ST',
        RegAddrCity: 'BRIDGEWATER',
        RegAddrState: 'MA',
        RegAddrZip: 2324,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '530 PLEASANT ST',
        RegAddrCity: 'BRIDGEWATER',
        RegAddrState: 'MA',
        RegAddrZip: 2324,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '133 SOUTH DR',
        RegAddrCity: 'BRIDGEWATER',
        RegAddrState: 'MA',
        RegAddrZip: 2324,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '68 HILLCREST AVE',
        RegAddrCity: 'BROCKTON',
        RegAddrState: 'MA',
        RegAddrZip: 2301,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '1237 PLEASANT ST',
        RegAddrCity: 'BROCKTON',
        RegAddrState: 'MA',
        RegAddrZip: 2301,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '83 MARTLAND AVE',
        RegAddrCity: 'BROCKTON',
        RegAddrState: 'MA',
        RegAddrZip: 2301,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '101 CHERRY ST',
        RegAddrCity: 'BROCKTON',
        RegAddrState: 'MA',
        RegAddrZip: 2301,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '396 W CHESTNUT ST',
        RegAddrCity: 'BROCKTON',
        RegAddrState: 'MA',
        RegAddrZip: 2301,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '163 CONCORD ST',
        RegAddrCity: 'BROCKTON',
        RegAddrState: 'MA',
        RegAddrZip: 2302,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '5 CEDAR DR',
        RegAddrCity: 'CANTON',
        RegAddrState: 'MA',
        RegAddrZip: 2021,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '26 INDIAN WOODS WAY',
        RegAddrCity: 'CANTON',
        RegAddrState: 'MA',
        RegAddrZip: 2021,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '42 BAYVIEW DR',
        RegAddrCity: 'COHASSET',
        RegAddrState: 'MA',
        RegAddrZip: 2025,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '309 S MAIN ST',
        RegAddrCity: 'COHASSET',
        RegAddrState: 'MA',
        RegAddrZip: 2025,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '51 COMMON ST',
        RegAddrCity: 'DEDHAM',
        RegAddrState: 'MA',
        RegAddrZip: 2026,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '12 RIVER RD',
        RegAddrCity: 'E BRIDGEWTR',
        RegAddrState: 'MA',
        RegAddrZip: 2333,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '14 BRADLEY PARK DR',
        RegAddrCity: 'HINGHAM',
        RegAddrState: 'MA',
        RegAddrZip: 2043,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '7 MARTINS COVE RD',
        RegAddrCity: 'HINGHAM',
        RegAddrState: 'MA',
        RegAddrZip: 2043,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '51 BEAL ST',
        RegAddrCity: 'HINGHAM',
        RegAddrState: 'MA',
        RegAddrZip: 2043,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '122R HULL ST',
        RegAddrCity: 'HINGHAM',
        RegAddrState: 'MA',
        RegAddrZip: 2043,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '40 SUNFLOWER RD',
        RegAddrCity: 'HOLBROOK',
        RegAddrState: 'MA',
        RegAddrZip: 2343,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '56 BATES ST',
        RegAddrCity: 'HULL',
        RegAddrState: 'MA',
        RegAddrZip: 2045,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '21 REVERE ST',
        RegAddrCity: 'HULL',
        RegAddrState: 'MA',
        RegAddrZip: 2045,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '81 GERALD RD',
        RegAddrCity: 'MILTON',
        RegAddrState: 'MA',
        RegAddrZip: 2186,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '60 MARILYN RD',
        RegAddrCity: 'MILTON',
        RegAddrState: 'MA',
        RegAddrZip: 2186,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '26 EDGEHILL RD',
        RegAddrCity: 'NORWOOD',
        RegAddrState: 'MA',
        RegAddrZip: 2062,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '43 MARION AVE',
        RegAddrCity: 'NORWOOD',
        RegAddrState: 'MA',
        RegAddrZip: 2062,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '26 MARION AVE',
        RegAddrCity: 'NORWOOD',
        RegAddrState: 'MA',
        RegAddrZip: 2062,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '73 TERNE RD',
        RegAddrCity: 'QUINCY',
        RegAddrState: 'MA',
        RegAddrZip: 2169,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '199 LIBERTY ST',
        RegAddrCity: 'QUINCY',
        RegAddrState: 'MA',
        RegAddrZip: 2169,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '29 BROCKTON AVE',
        RegAddrCity: 'QUINCY',
        RegAddrState: 'MA',
        RegAddrZip: 2169,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '121 LIBERTY ST',
        RegAddrCity: 'QUINCY',
        RegAddrState: 'MA',
        RegAddrZip: 2169,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '160 CONNELL ST',
        RegAddrCity: 'QUINCY',
        RegAddrState: 'MA',
        RegAddrZip: 2169,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '30 EBBETT AVE',
        RegAddrCity: 'QUINCY',
        RegAddrState: 'MA',
        RegAddrZip: 2170,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '170 ELLIOT AVE',
        RegAddrCity: 'QUINCY',
        RegAddrState: 'MA',
        RegAddrZip: 2171,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '64 MILL ST',
        RegAddrCity: 'RANDOLPH',
        RegAddrState: 'MA',
        RegAddrZip: 2368,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '29 ELEANOR RD',
        RegAddrCity: 'RAYNHAM',
        RegAddrState: 'MA',
        RegAddrZip: 2767,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '13 6TH AVE',
        RegAddrCity: 'SCITUATE',
        RegAddrState: 'MA',
        RegAddrZip: 2066,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '147 CLAPP RD',
        RegAddrCity: 'SCITUATE',
        RegAddrState: 'MA',
        RegAddrZip: 2066,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '46 MARA CIR',
        RegAddrCity: 'STOUGHTON',
        RegAddrState: 'MA',
        RegAddrZip: 2072,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '100 GRACE LN',
        RegAddrCity: 'STOUGHTON',
        RegAddrState: 'MA',
        RegAddrZip: 2072,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '190 WILLIAM KELLEY RD',
        RegAddrCity: 'STOUGHTON',
        RegAddrState: 'MA',
        RegAddrZip: 2072,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '47 MEADOWOOD DR',
        RegAddrCity: 'STOUGHTON',
        RegAddrState: 'MA',
        RegAddrZip: 2072,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '9 WOODROW LN',
        RegAddrCity: 'W BRIDGEWATER',
        RegAddrState: 'MA',
        RegAddrZip: 2379,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '11 ALBANY RD',
        RegAddrCity: 'WALPOLE',
        RegAddrState: 'MA',
        RegAddrZip: 2081,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '50 BEDFORD ST',
        RegAddrCity: 'WEST BRIDGEWATER',
        RegAddrState: 'MA',
        RegAddrZip: 2379,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '109 FEARING DR',
        RegAddrCity: 'WESTWOOD',
        RegAddrState: 'MA',
        RegAddrZip: 2090,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '627 HIGH ST',
        RegAddrCity: 'WESTWOOD',
        RegAddrState: 'MA',
        RegAddrZip: 2090,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '80 KINGMAN ST',
        RegAddrCity: 'WEYMOUTH',
        RegAddrState: 'MA',
        RegAddrZip: 2188,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '81 MEREDITH WAY',
        RegAddrCity: 'WEYMOUTH',
        RegAddrState: 'MA',
        RegAddrZip: 2188,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '15 MANDALAY RD',
        RegAddrCity: 'WEYMOUTH',
        RegAddrState: 'MA',
        RegAddrZip: 2190,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '33 MAY TER',
        RegAddrCity: 'WEYMOUTH',
        RegAddrState: 'MA',
        RegAddrZip: 2190,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '911 FRANKLIN ST',
        RegAddrCity: 'WHITMAN',
        RegAddrState: 'MA',
        RegAddrZip: 2382,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '53 COUNTRY WAY',
        RegAddrCity: 'WHITMAN',
        RegAddrState: 'MA',
        RegAddrZip: 2382,
        CongressionalDistrict: 8,
      },
    ],
  },
  {
    name: 'MA-9',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/MA-9?ref=gh-pages',
    state: 'MA',
    state_and_number: 'MA-9',
    zip: 2061,
    congressionalDistrict: 9,
    addressesInDistrict: [
      {
        RegAddrLine1: '2 WILDROSE LN',
        RegAddrCity: 'ACUSHNET',
        RegAddrState: 'MA',
        RegAddrZip: 2743,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '1505 MAIN ST',
        RegAddrCity: 'ACUSHNET',
        RegAddrState: 'MA',
        RegAddrZip: 2743,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '1551 MAIN ST',
        RegAddrCity: 'ACUSHNET',
        RegAddrState: 'MA',
        RegAddrZip: 2743,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '40 BOURNEDALE RD',
        RegAddrCity: 'BOURNE',
        RegAddrState: 'MA',
        RegAddrZip: 2532,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '523 THOUSAND OAKS DR',
        RegAddrCity: 'BREWSTER',
        RegAddrState: 'MA',
        RegAddrZip: 2631,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '23 CHILTON LN',
        RegAddrCity: 'BREWSTER',
        RegAddrState: 'MA',
        RegAddrZip: 2631,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '101 S MEADOW RD',
        RegAddrCity: 'CARVER',
        RegAddrState: 'MA',
        RegAddrZip: 2330,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '2 FRANKLIN RD',
        RegAddrCity: 'CARVER',
        RegAddrState: 'MA',
        RegAddrZip: 2330,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '121 LAKEVIEW AVE',
        RegAddrCity: 'CHATHAM',
        RegAddrState: 'MA',
        RegAddrZip: 2633,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '64 SQUIBNOCKET FARM RD',
        RegAddrCity: 'CHILMARK',
        RegAddrState: 'MA',
        RegAddrZip: 2535,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '9 NORTHFIELD LN',
        RegAddrCity: 'DARTMOUTH',
        RegAddrState: 'MA',
        RegAddrZip: 2747,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '460 SMITH NECK RD',
        RegAddrCity: 'DARTMOUTH',
        RegAddrState: 'MA',
        RegAddrZip: 2748,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '558 RUSSELLS MILLS RD',
        RegAddrCity: 'DARTMOUTH',
        RegAddrState: 'MA',
        RegAddrZip: 2748,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '40 COBBS GRV',
        RegAddrCity: 'DENNIS',
        RegAddrState: 'MA',
        RegAddrZip: 2641,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '15 TREETOP LN',
        RegAddrCity: 'DUXBURY',
        RegAddrState: 'MA',
        RegAddrZip: 2332,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '1371 SANDWICH RD',
        RegAddrCity: 'E FALMOUTH',
        RegAddrState: 'MA',
        RegAddrZip: 2536,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '63 OLD BAYBERRY LN',
        RegAddrCity: 'E FALMOUTH',
        RegAddrState: 'MA',
        RegAddrZip: 2536,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '90 DEER POND RD',
        RegAddrCity: 'E FALMOUTH',
        RegAddrState: 'MA',
        RegAddrZip: 2536,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '27 FORTALEZA DR',
        RegAddrCity: 'E FALMOUTH',
        RegAddrState: 'MA',
        RegAddrZip: 2536,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '15 PALM ST',
        RegAddrCity: 'E FALMOUTH',
        RegAddrState: 'MA',
        RegAddrZip: 2536,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '2 OAK HILL PARK',
        RegAddrCity: 'E SANDWICH',
        RegAddrState: 'MA',
        RegAddrZip: 2537,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '15 NOTTINGHAM DR',
        RegAddrCity: 'E SANDWICH',
        RegAddrState: 'MA',
        RegAddrZip: 2537,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '41 WAREHAM LAKE SHORE DR',
        RegAddrCity: 'E WAREHAM',
        RegAddrState: 'MA',
        RegAddrZip: 2538,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '8 VERANDA AVE',
        RegAddrCity: 'FAIRHAVEN',
        RegAddrState: 'MA',
        RegAddrZip: 2719,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '7 DEANE ST',
        RegAddrCity: 'FAIRHAVEN',
        RegAddrState: 'MA',
        RegAddrZip: 2719,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '175 GRINNELL ST',
        RegAddrCity: 'FALL RIVER',
        RegAddrState: 'MA',
        RegAddrZip: 2721,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '112 KING ST',
        RegAddrCity: 'FALL RIVER',
        RegAddrState: 'MA',
        RegAddrZip: 2724,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '153 BOWEN ST',
        RegAddrCity: 'FALL RIVER',
        RegAddrState: 'MA',
        RegAddrZip: 2724,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '77 HAMLIN AVE',
        RegAddrCity: 'FALMOUTH',
        RegAddrState: 'MA',
        RegAddrZip: 2540,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '10 ESTES RD',
        RegAddrCity: 'HANOVER',
        RegAddrState: 'MA',
        RegAddrZip: 2339,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '3 PADDOCK DR',
        RegAddrCity: 'HARWICH',
        RegAddrState: 'MA',
        RegAddrZip: 2645,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '46 BUMPUS RD',
        RegAddrCity: 'HYANNIS',
        RegAddrState: 'MA',
        RegAddrZip: 2601,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '33 STETSON ST',
        RegAddrCity: 'HYANNIS',
        RegAddrState: 'MA',
        RegAddrZip: 2601,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '3 WHITE PINE LN',
        RegAddrCity: 'KINGSTON',
        RegAddrState: 'MA',
        RegAddrZip: 2364,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '12 SYCAMORE DR',
        RegAddrCity: 'KINGSTON',
        RegAddrState: 'MA',
        RegAddrZip: 2364,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '870 MAIN ST',
        RegAddrCity: 'MARSHFIELD',
        RegAddrState: 'MA',
        RegAddrZip: 2050,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '1605 MAIN ST',
        RegAddrCity: 'MARSHFIELD',
        RegAddrState: 'MA',
        RegAddrZip: 2050,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '66 MARYLAND ST',
        RegAddrCity: 'MARSHFIELD',
        RegAddrState: 'MA',
        RegAddrZip: 2050,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '125 WINDING COVE RD',
        RegAddrCity: 'MARSTONS MILLS',
        RegAddrState: 'MA',
        RegAddrZip: 2648,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '3 FLEETWOOD PATH',
        RegAddrCity: 'MARSTONS MLS',
        RegAddrState: 'MA',
        RegAddrZip: 2648,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '157 FLEETWOOD PATH',
        RegAddrCity: 'MARSTONS MLS',
        RegAddrState: 'MA',
        RegAddrZip: 2648,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '40 OCEAN DR',
        RegAddrCity: 'MATTAPOISETT',
        RegAddrState: 'MA',
        RegAddrZip: 2739,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '54 MONTELLO ST',
        RegAddrCity: 'MIDDLEBORO',
        RegAddrState: 'MA',
        RegAddrZip: 2346,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '98 PLAIN ST',
        RegAddrCity: 'MIDDLEBOROUGH',
        RegAddrState: 'MA',
        RegAddrZip: 2346,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '125 PLEASANT ST',
        RegAddrCity: 'MIDDLEBOROUGH',
        RegAddrState: 'MA',
        RegAddrZip: 2346,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '6 CORBY WAY',
        RegAddrCity: 'NANTUCKET',
        RegAddrState: 'MA',
        RegAddrZip: 2554,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '350 PURCHASE ST',
        RegAddrCity: 'NEW BEDFORD',
        RegAddrState: 'MA',
        RegAddrZip: 2740,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '370 DURFEE ST',
        RegAddrCity: 'NEW BEDFORD',
        RegAddrState: 'MA',
        RegAddrZip: 2740,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '24 LARCH ST',
        RegAddrCity: 'NEW BEDFORD',
        RegAddrState: 'MA',
        RegAddrZip: 2740,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '419 SAWYER ST',
        RegAddrCity: 'NEW BEDFORD',
        RegAddrState: 'MA',
        RegAddrZip: 2746,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '311 HIGHLAND ST',
        RegAddrCity: 'NEW BEDFORD',
        RegAddrState: 'MA',
        RegAddrZip: 2746,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '135 MAIN ST',
        RegAddrCity: 'NORWELL',
        RegAddrState: 'MA',
        RegAddrZip: 2061,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '80 LIBERTY LN',
        RegAddrCity: 'ORLEANS',
        RegAddrState: 'MA',
        RegAddrZip: 2653,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '12 CRANBERRY LN',
        RegAddrCity: 'PEMBROKE',
        RegAddrState: 'MA',
        RegAddrZip: 2359,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '81 STANDISH ST',
        RegAddrCity: 'PEMBROKE',
        RegAddrState: 'MA',
        RegAddrZip: 2359,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '37 BRICK KILN LN',
        RegAddrCity: 'PEMBROKE',
        RegAddrState: 'MA',
        RegAddrZip: 2359,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '2148 STATE RD',
        RegAddrCity: 'PLYMOUTH',
        RegAddrState: 'MA',
        RegAddrZip: 2360,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '37 WEATHERVANE',
        RegAddrCity: 'PLYMOUTH',
        RegAddrState: 'MA',
        RegAddrZip: 2360,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '4 HIGHLAND PL',
        RegAddrCity: 'PLYMOUTH',
        RegAddrState: 'MA',
        RegAddrZip: 2360,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '19 SHINGLEWOOD',
        RegAddrCity: 'PLYMOUTH',
        RegAddrState: 'MA',
        RegAddrZip: 2360,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '40 YALE AVE',
        RegAddrCity: 'PLYMOUTH',
        RegAddrState: 'MA',
        RegAddrZip: 2360,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '35 KATHLEEN DR',
        RegAddrCity: 'PLYMOUTH',
        RegAddrState: 'MA',
        RegAddrZip: 2360,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '85 UPLAND RD',
        RegAddrCity: 'PLYMPTON',
        RegAddrState: 'MA',
        RegAddrZip: 2367,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '346 W PLEASANT ST',
        RegAddrCity: 'ROCKLAND',
        RegAddrState: 'MA',
        RegAddrZip: 2370,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '4 INDIAN HEAD LN',
        RegAddrCity: 'ROCKLAND',
        RegAddrState: 'MA',
        RegAddrZip: 2370,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '11 NAUHAUGHT RD',
        RegAddrCity: 'S YARMOUTH',
        RegAddrState: 'MA',
        RegAddrZip: 2664,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '11 JENNIFER RD',
        RegAddrCity: 'SANDWICH',
        RegAddrState: 'MA',
        RegAddrZip: 2563,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '216 BLACKMORE POND RD',
        RegAddrCity: 'W WAREHAM',
        RegAddrState: 'MA',
        RegAddrZip: 2576,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '27 HARDING LN',
        RegAddrCity: 'W YARMOUTH',
        RegAddrState: 'MA',
        RegAddrZip: 2673,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '164 HATHAWAY ST',
        RegAddrCity: 'WAREHAM',
        RegAddrState: 'MA',
        RegAddrZip: 2571,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '30 CHEQUESSETT NECK RD',
        RegAddrCity: 'WELLFLEET',
        RegAddrState: 'MA',
        RegAddrZip: 2667,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '62 PANHANDLE RD',
        RegAddrCity: 'WEST TISBURY',
        RegAddrState: 'MA',
        RegAddrZip: 2575,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '543 ROUTE 28',
        RegAddrCity: 'WEST YARMOUTH',
        RegAddrState: 'MA',
        RegAddrZip: 2673,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '80 HIGHLAND AVE',
        RegAddrCity: 'WESTPORT',
        RegAddrState: 'MA',
        RegAddrZip: 2790,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '8 MEADOWBROOK LN',
        RegAddrCity: 'WESTPORT',
        RegAddrState: 'MA',
        RegAddrZip: 2790,
        CongressionalDistrict: 9,
      },
    ],
  },
  {
    name: 'MD-1',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/MD-1?ref=gh-pages',
    state: 'MD',
    state_and_number: 'MD-1',
    zip: 21001,
    congressionalDistrict: 1,
    addressesInDistrict: [
      {
        RegAddrLine1: '726 CARSINS RUN RD',
        RegAddrCity: 'ABERDEEN',
        RegAddrState: 'MD',
        RegAddrZip: 21001,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1107 HOOKERS MILL RD',
        RegAddrCity: 'ABINGDON',
        RegAddrState: 'MD',
        RegAddrZip: 21009,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '3 BREWER LANDING CT',
        RegAddrCity: 'BALTIMORE',
        RegAddrState: 'MD',
        RegAddrZip: 21234,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '734 REEDY CIR',
        RegAddrCity: 'BEL AIR',
        RegAddrState: 'MD',
        RegAddrZip: 21014,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1513 REGENT CT',
        RegAddrCity: 'BEL AIR',
        RegAddrState: 'MD',
        RegAddrZip: 21014,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1537 SWEARINGEN DR',
        RegAddrCity: 'BEL AIR',
        RegAddrState: 'MD',
        RegAddrZip: 21014,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2219 HUNTERS CHASE',
        RegAddrCity: 'BEL AIR',
        RegAddrState: 'MD',
        RegAddrZip: 21015,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1926 THOMAS RUN CIR',
        RegAddrCity: 'BEL AIR',
        RegAddrState: 'MD',
        RegAddrZip: 21015,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1814 PRINDLE DR',
        RegAddrCity: 'BEL AIR',
        RegAddrState: 'MD',
        RegAddrZip: 21015,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '626 GAIRLOCH PL',
        RegAddrCity: 'BEL AIR',
        RegAddrState: 'MD',
        RegAddrZip: 21015,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '7156 CHANDLER DR',
        RegAddrCity: 'BERLIN',
        RegAddrState: 'MD',
        RegAddrZip: 21811,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '9761 TIMMONSTOWN RD',
        RegAddrCity: 'BERLIN',
        RegAddrState: 'MD',
        RegAddrZip: 21811,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '626 DOUGLAS ST',
        RegAddrCity: 'CAMBRIDGE',
        RegAddrState: 'MD',
        RegAddrZip: 21613,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1607 PAXMORE LN',
        RegAddrCity: 'CAMBRIDGE',
        RegAddrState: 'MD',
        RegAddrZip: 21613,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '12 S FRIENDSHIP CT',
        RegAddrCity: 'COLORA',
        RegAddrState: 'MD',
        RegAddrZip: 21917,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '28157 CANTERBURY CT',
        RegAddrCity: 'EASTON',
        RegAddrState: 'MD',
        RegAddrZip: 21601,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '30 HALL RD',
        RegAddrCity: 'ELKTON',
        RegAddrState: 'MD',
        RegAddrZip: 21921,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '14 MCARTHUR LN',
        RegAddrCity: 'ELKTON',
        RegAddrState: 'MD',
        RegAddrZip: 21921,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '464 ELK MILLS RD',
        RegAddrCity: 'ELKTON',
        RegAddrState: 'MD',
        RegAddrZip: 21921,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '14 PINEHURST CT',
        RegAddrCity: 'ELKTON',
        RegAddrState: 'MD',
        RegAddrZip: 21921,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '3218 CANTERBURY LN',
        RegAddrCity: 'FALLSTON',
        RegAddrState: 'MD',
        RegAddrZip: 21047,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2633 MEADOWBROOK RD',
        RegAddrCity: 'FEDERALSBURG',
        RegAddrState: 'MD',
        RegAddrZip: 21632,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '308 HOLT ST',
        RegAddrCity: 'FEDERALSBURG',
        RegAddrState: 'MD',
        RegAddrZip: 21632,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1631 GREEN MILL RD',
        RegAddrCity: 'FINKSBURG',
        RegAddrState: 'MD',
        RegAddrZip: 21048,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1617 DAVINDA DR',
        RegAddrCity: 'FINKSBURG',
        RegAddrState: 'MD',
        RegAddrZip: 21048,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '605 MILL LN',
        RegAddrCity: 'GALENA',
        RegAddrState: 'MD',
        RegAddrZip: 21635,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2608 MOUNTAIN RD',
        RegAddrCity: 'JOPPA',
        RegAddrState: 'MD',
        RegAddrZip: 21085,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '106 SPRY ISLAND RD',
        RegAddrCity: 'JOPPA',
        RegAddrState: 'MD',
        RegAddrZip: 21085,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '25773 GRIST MILL DR',
        RegAddrCity: 'MARDELA SPGS',
        RegAddrState: 'MD',
        RegAddrZip: 21837,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '21 COTTONWOOD CT',
        RegAddrCity: 'OCEAN PINES',
        RegAddrState: 'MD',
        RegAddrZip: 21811,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '10 AUDUBON CIR',
        RegAddrCity: 'OCEAN PINES',
        RegAddrState: 'MD',
        RegAddrZip: 21811,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '71 WATERTOWN RD',
        RegAddrCity: 'OCEAN PINES',
        RegAddrState: 'MD',
        RegAddrZip: 21811,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '11002 BREWERS DR',
        RegAddrCity: 'PERRY HALL',
        RegAddrState: 'MD',
        RegAddrZip: 21128,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '9309 GEORGIA BELLE DR',
        RegAddrCity: 'PERRY HALL',
        RegAddrState: 'MD',
        RegAddrZip: 21128,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '8958 WHITETAIL CT',
        RegAddrCity: 'PERRY HALL',
        RegAddrState: 'MD',
        RegAddrZip: 21128,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2105 BYPASS RD',
        RegAddrCity: 'POCOMOKE CITY',
        RegAddrState: 'MD',
        RegAddrZip: 21851,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '30681 MC CAIN DR',
        RegAddrCity: 'PRINCESS ANNE',
        RegAddrState: 'MD',
        RegAddrZip: 21853,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '11385 STEWART NECK RD',
        RegAddrCity: 'PRINCESS ANNE',
        RegAddrState: 'MD',
        RegAddrZip: 21853,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2 GREENRIDGE AVE',
        RegAddrCity: 'RIDGELY',
        RegAddrState: 'MD',
        RegAddrZip: 21660,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '8235 BURNT BRANCH DR',
        RegAddrCity: 'SALISBURY',
        RegAddrState: 'MD',
        RegAddrZip: 21801,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1010 TUSCOLA AVE',
        RegAddrCity: 'SALISBURY',
        RegAddrState: 'MD',
        RegAddrZip: 21801,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '803 LAKE ST',
        RegAddrCity: 'SALISBURY',
        RegAddrState: 'MD',
        RegAddrZip: 21801,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '6090 STRAWBERRY WAY',
        RegAddrCity: 'SALISBURY',
        RegAddrState: 'MD',
        RegAddrZip: 21801,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1927 PINE WAY',
        RegAddrCity: 'SALISBURY',
        RegAddrState: 'MD',
        RegAddrZip: 21804,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '925 RIVERVIEW TER',
        RegAddrCity: 'ST MICHAELS',
        RegAddrState: 'MD',
        RegAddrZip: 21663,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '26621 TRUSTEE ST',
        RegAddrCity: 'STILL POND',
        RegAddrState: 'MD',
        RegAddrZip: 21667,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1734 JERRYS RD',
        RegAddrCity: 'STREET',
        RegAddrState: 'MD',
        RegAddrZip: 21154,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '3885 HARRISON CT',
        RegAddrCity: 'TRAPPE',
        RegAddrState: 'MD',
        RegAddrZip: 21673,
        CongressionalDistrict: 1,
      },
    ],
  },
  {
    name: 'MD-2',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/MD-2?ref=gh-pages',
    state: 'MD',
    state_and_number: 'MD-2',
    zip: 20701,
    congressionalDistrict: 2,
    addressesInDistrict: [
      {
        RegAddrLine1: '1032 DONINGTON CIR',
        RegAddrCity: 'BALTIMORE',
        RegAddrState: 'MD',
        RegAddrZip: 21204,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '4110 LASALLE AVE',
        RegAddrCity: 'BALTIMORE',
        RegAddrState: 'MD',
        RegAddrZip: 21206,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '4613 FRANKFORD AVE',
        RegAddrCity: 'BALTIMORE',
        RegAddrState: 'MD',
        RegAddrZip: 21206,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '805 KINGSTON RD',
        RegAddrCity: 'BALTIMORE',
        RegAddrState: 'MD',
        RegAddrZip: 21212,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '3403 RAMONA AVE',
        RegAddrCity: 'BALTIMORE',
        RegAddrState: 'MD',
        RegAddrZip: 21213,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2506 LODGE FARM RD',
        RegAddrCity: 'BALTIMORE',
        RegAddrState: 'MD',
        RegAddrZip: 21219,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '946 KAYDEN LN',
        RegAddrCity: 'BALTIMORE',
        RegAddrState: 'MD',
        RegAddrZip: 21221,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '7818 NEW BATTLE GROVE RD',
        RegAddrCity: 'BALTIMORE',
        RegAddrState: 'MD',
        RegAddrZip: 21222,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '7615 CARSON AVE',
        RegAddrCity: 'BALTIMORE',
        RegAddrState: 'MD',
        RegAddrZip: 21224,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '3814 SAINT MARGARET ST',
        RegAddrCity: 'BALTIMORE',
        RegAddrState: 'MD',
        RegAddrZip: 21225,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '9824 HARFORD RD',
        RegAddrCity: 'BALTIMORE',
        RegAddrState: 'MD',
        RegAddrZip: 21234,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1319 REGESTER AVE',
        RegAddrCity: 'BALTIMORE',
        RegAddrState: 'MD',
        RegAddrZip: 21239,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '32 SIX POINT CT',
        RegAddrCity: 'BALTIMORE',
        RegAddrState: 'MD',
        RegAddrZip: 21244,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '244 RIDGE AVE',
        RegAddrCity: 'BALTIMORE',
        RegAddrState: 'MD',
        RegAddrZip: 21286,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2 MARYLAND AVE',
        RegAddrCity: 'BALTIMORE',
        RegAddrState: 'MD',
        RegAddrZip: 21286,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '4202 LYTLE WAY',
        RegAddrCity: 'BELCAMP',
        RegAddrState: 'MD',
        RegAddrZip: 21017,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1205 OVERTON SQ',
        RegAddrCity: 'BELCAMP',
        RegAddrState: 'MD',
        RegAddrZip: 21017,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '614 HARTWOOD LN',
        RegAddrCity: 'EDGEWOOD',
        RegAddrState: 'MD',
        RegAddrZip: 21040,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '114 WILSON ST',
        RegAddrCity: 'HVRE DE GRACE',
        RegAddrState: 'MD',
        RegAddrZip: 21078,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '909A PINE RD',
        RegAddrCity: 'JOPPA',
        RegAddrState: 'MD',
        RegAddrZip: 21085,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2122 FOLKSTONE RD',
        RegAddrCity: 'LUTHVLE TIMON',
        RegAddrState: 'MD',
        RegAddrZip: 21093,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '12407 OLD GREY MARE CT',
        RegAddrCity: 'REISTERSTOWN',
        RegAddrState: 'MD',
        RegAddrZip: 21136,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '7875 SUNHAVEN WAY',
        RegAddrCity: 'SEVERN',
        RegAddrState: 'MD',
        RegAddrZip: 21144,
        CongressionalDistrict: 2,
      },
    ],
  },
  {
    name: 'MD-3',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/MD-3?ref=gh-pages',
    state: 'MD',
    state_and_number: 'MD-3',
    zip: 20705,
    congressionalDistrict: 3,
    addressesInDistrict: [
      {
        RegAddrLine1: '718 CRISFIELD WAY',
        RegAddrCity: 'ANNAPOLIS',
        RegAddrState: 'MD',
        RegAddrZip: 21401,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1403 POPLAR AVE',
        RegAddrCity: 'ANNAPOLIS',
        RegAddrState: 'MD',
        RegAddrZip: 21401,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1185 BAY HIGHLANDS DR',
        RegAddrCity: 'ANNAPOLIS',
        RegAddrState: 'MD',
        RegAddrZip: 21403,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1303 W JOPPA RD',
        RegAddrCity: 'BALTIMORE',
        RegAddrState: 'MD',
        RegAddrZip: 21204,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '7527 STREAM CROSSING RD',
        RegAddrCity: 'BALTIMORE',
        RegAddrState: 'MD',
        RegAddrZip: 21209,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '351 OLD TRAIL RD',
        RegAddrCity: 'BALTIMORE',
        RegAddrState: 'MD',
        RegAddrZip: 21212,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '918 FRANCIS AVE',
        RegAddrCity: 'BALTIMORE',
        RegAddrState: 'MD',
        RegAddrZip: 21227,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1828 LIGHT ST',
        RegAddrCity: 'BALTIMORE',
        RegAddrState: 'MD',
        RegAddrZip: 21230,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '501 E FORT AVE',
        RegAddrCity: 'BALTIMORE',
        RegAddrState: 'MD',
        RegAddrZip: 21230,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '2100 PITNEY RD',
        RegAddrCity: 'BALTIMORE',
        RegAddrState: 'MD',
        RegAddrZip: 21234,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '7917 VERNON AVE',
        RegAddrCity: 'BALTIMORE',
        RegAddrState: 'MD',
        RegAddrZip: 21236,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '5127 HENRY AVE',
        RegAddrCity: 'BALTIMORE',
        RegAddrState: 'MD',
        RegAddrZip: 21236,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1 GLADE AVE',
        RegAddrCity: 'BALTIMORE',
        RegAddrState: 'MD',
        RegAddrZip: 21236,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '6022 RIVER MEADOWS DR',
        RegAddrCity: 'COLUMBIA',
        RegAddrState: 'MD',
        RegAddrZip: 21045,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '9446 CLOCKTOWER LN',
        RegAddrCity: 'COLUMBIA',
        RegAddrState: 'MD',
        RegAddrZip: 21046,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '8969 SKYROCK CT',
        RegAddrCity: 'COLUMBIA',
        RegAddrState: 'MD',
        RegAddrZip: 21046,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '6009 TOOMEY LN',
        RegAddrCity: 'ELKRIDGE',
        RegAddrState: 'MD',
        RegAddrZip: 21075,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '11438 ELLINGTON ST',
        RegAddrCity: 'FULTON',
        RegAddrState: 'MD',
        RegAddrZip: 20759,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '304 HOWARDS TRUST CT',
        RegAddrCity: 'GLEN BURNIE',
        RegAddrState: 'MD',
        RegAddrZip: 21060,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '7859 FREETOWN RD',
        RegAddrCity: 'GLEN BURNIE',
        RegAddrState: 'MD',
        RegAddrZip: 21060,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '3 BALTIMORE AVE',
        RegAddrCity: 'GLEN BURNIE',
        RegAddrState: 'MD',
        RegAddrZip: 21061,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '643 CADBURY DR',
        RegAddrCity: 'ODENTON',
        RegAddrState: 'MD',
        RegAddrZip: 21113,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '3 PREAKNESS CT',
        RegAddrCity: 'OWINGS MILLS',
        RegAddrState: 'MD',
        RegAddrZip: 21117,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '356 NATURE WALK LN',
        RegAddrCity: 'PASADENA',
        RegAddrState: 'MD',
        RegAddrZip: 21122,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '7901 OAK POINT CT',
        RegAddrCity: 'PASADENA',
        RegAddrState: 'MD',
        RegAddrZip: 21122,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '761 FAWNELM RD',
        RegAddrCity: 'SEVERN',
        RegAddrState: 'MD',
        RegAddrZip: 21144,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '9823 HEDIN DR',
        RegAddrCity: 'SILVER SPRING',
        RegAddrState: 'MD',
        RegAddrZip: 20903,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1102 OAKVIEW DR',
        RegAddrCity: 'SILVER SPRING',
        RegAddrState: 'MD',
        RegAddrZip: 20903,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '15315 DONNA DR',
        RegAddrCity: 'SILVER SPRING',
        RegAddrState: 'MD',
        RegAddrZip: 20905,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '9 BRYANTS NURSERY RD',
        RegAddrCity: 'SILVER SPRING',
        RegAddrState: 'MD',
        RegAddrZip: 20905,
        CongressionalDistrict: 3,
      },
    ],
  },
  {
    name: 'MD-4',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/MD-4?ref=gh-pages',
    state: 'MD',
    state_and_number: 'MD-4',
    zip: 20706,
    congressionalDistrict: 4,
    addressesInDistrict: [
      {
        RegAddrLine1: '2663 PEMAQUID CT',
        RegAddrCity: 'ANNAPOLIS',
        RegAddrState: 'MD',
        RegAddrZip: 21401,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1012 ROSSLARE CT',
        RegAddrCity: 'ARNOLD',
        RegAddrState: 'MD',
        RegAddrZip: 21012,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '6008 WALTON AVE',
        RegAddrCity: 'CAMP SPRINGS',
        RegAddrState: 'MD',
        RegAddrZip: 20746,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '3203 CHEVERLY AVE',
        RegAddrCity: 'CHEVERLY',
        RegAddrState: 'MD',
        RegAddrZip: 20785,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '3026 CREST AVE',
        RegAddrCity: 'CHEVERLY',
        RegAddrState: 'MD',
        RegAddrZip: 20785,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2811 ANDY CT',
        RegAddrCity: 'CROFTON',
        RegAddrState: 'MD',
        RegAddrZip: 21114,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1407 ASHEVILLE RD',
        RegAddrCity: 'FORESTVILLE',
        RegAddrState: 'MD',
        RegAddrZip: 20747,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2506 KINGSWAY RD',
        RegAddrCity: 'FT WASHINGTON',
        RegAddrState: 'MD',
        RegAddrZip: 20744,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '922 FALL RIDGE WAY',
        RegAddrCity: 'GAMBRILLS',
        RegAddrState: 'MD',
        RegAddrZip: 21054,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '7820 GLENARDEN PKWY',
        RegAddrCity: 'GLENARDEN',
        RegAddrState: 'MD',
        RegAddrZip: 20706,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '6511 FLANDERS DR',
        RegAddrCity: 'HYATTSVILLE',
        RegAddrState: 'MD',
        RegAddrZip: 20783,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '6903 SHEPHERD ST',
        RegAddrCity: 'HYATTSVILLE',
        RegAddrState: 'MD',
        RegAddrZip: 20784,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '3503 65TH AVE',
        RegAddrCity: 'HYATTSVILLE',
        RegAddrState: 'MD',
        RegAddrZip: 20784,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '8005 E NALLEY RD',
        RegAddrCity: 'LANDOVER',
        RegAddrState: 'MD',
        RegAddrZip: 20785,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1718 MERRIMAC DR',
        RegAddrCity: 'LANGLEY PARK',
        RegAddrState: 'MD',
        RegAddrZip: 20783,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '6805 OREM DR',
        RegAddrCity: 'LAUREL',
        RegAddrState: 'MD',
        RegAddrZip: 20707,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '8406 ADARE CT',
        RegAddrCity: 'MILLERSVILLE',
        RegAddrState: 'MD',
        RegAddrZip: 21108,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '4012 31ST ST',
        RegAddrCity: 'MOUNT RAINIER',
        RegAddrState: 'MD',
        RegAddrZip: 20712,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2327 CHAPEL HILL BLVD',
        RegAddrCity: 'ODENTON',
        RegAddrState: 'MD',
        RegAddrZip: 21113,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1603 OWENS RD',
        RegAddrCity: 'OXON HILL',
        RegAddrState: 'MD',
        RegAddrZip: 20745,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '766 G ST',
        RegAddrCity: 'PASADENA',
        RegAddrState: 'MD',
        RegAddrZip: 21122,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '7901 PEPPERBOX LN',
        RegAddrCity: 'PASADENA',
        RegAddrState: 'MD',
        RegAddrZip: 21122,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '6113 ADDISON RD',
        RegAddrCity: 'SEAT PLEASANT',
        RegAddrState: 'MD',
        RegAddrZip: 20743,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '786 TREMAINE WAY',
        RegAddrCity: 'SEVERNA PARK',
        RegAddrState: 'MD',
        RegAddrZip: 21146,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '144 TRUCK HOUSE RD',
        RegAddrCity: 'SEVERNA PARK',
        RegAddrState: 'MD',
        RegAddrZip: 21146,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1012 MERRIMAC DR',
        RegAddrCity: 'SILVER SPRING',
        RegAddrState: 'MD',
        RegAddrZip: 20903,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2303 JAMESON ST',
        RegAddrCity: 'TEMPLE HILLS',
        RegAddrState: 'MD',
        RegAddrZip: 20748,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '3805 MEADOWHILL RD',
        RegAddrCity: 'UPPER MARLBORO',
        RegAddrState: 'MD',
        RegAddrZip: 20774,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '14710 TURNER WOOTTON PKWY',
        RegAddrCity: 'UPPR MARLBORO',
        RegAddrState: 'MD',
        RegAddrZip: 20774,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '3810 PENTLAND HILLS DR',
        RegAddrCity: 'UPPR MARLBORO',
        RegAddrState: 'MD',
        RegAddrZip: 20774,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '905 FABER PL',
        RegAddrCity: 'UPPR MARLBORO',
        RegAddrState: 'MD',
        RegAddrZip: 20774,
        CongressionalDistrict: 4,
      },
    ],
  },
  {
    name: 'MD-5',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/MD-5?ref=gh-pages',
    state: 'MD',
    state_and_number: 'MD-5',
    zip: 20601,
    congressionalDistrict: 5,
    addressesInDistrict: [
      {
        RegAddrLine1: '8914 57TH AVE',
        RegAddrCity: 'BERWYN HTS',
        RegAddrState: 'MD',
        RegAddrZip: 20740,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '8006 GOLD CUP LN',
        RegAddrCity: 'BOWIE',
        RegAddrState: 'MD',
        RegAddrZip: 20715,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '2811 BIRDSEYE LN',
        RegAddrCity: 'BOWIE',
        RegAddrState: 'MD',
        RegAddrZip: 20715,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '12041 TWIN CEDAR LN',
        RegAddrCity: 'BOWIE',
        RegAddrState: 'MD',
        RegAddrZip: 20715,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '15012 RIDGE CHASE CT',
        RegAddrCity: 'BOWIE',
        RegAddrState: 'MD',
        RegAddrZip: 20715,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '15421 DOVEHEART LN',
        RegAddrCity: 'BOWIE',
        RegAddrState: 'MD',
        RegAddrZip: 20721,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '15170 OAKS RD',
        RegAddrCity: 'CHARLOTTE HALL',
        RegAddrState: 'MD',
        RegAddrZip: 20622,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '10200 ROCK OAK TER',
        RegAddrCity: 'CHELTENHAM',
        RegAddrState: 'MD',
        RegAddrZip: 20623,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '3801 CHESAPEAKE AVE',
        RegAddrCity: 'CHESAPEAK BCH',
        RegAddrState: 'MD',
        RegAddrZip: 20732,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '3710 CLYDESDALE RD',
        RegAddrCity: 'EDGEWATER',
        RegAddrState: 'MD',
        RegAddrZip: 21037,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '4140 CADLE CREEK RD',
        RegAddrCity: 'EDGEWATER',
        RegAddrState: 'MD',
        RegAddrZip: 21037,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '3424 RED ADMIRAL CT',
        RegAddrCity: 'EDGEWATER',
        RegAddrState: 'MD',
        RegAddrZip: 21037,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '10110 SUNSET VW',
        RegAddrCity: 'FT WASHINGTON',
        RegAddrState: 'MD',
        RegAddrZip: 20744,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '44588 REEDER SANDERS LN',
        RegAddrCity: 'HOLLYWOOD',
        RegAddrState: 'MD',
        RegAddrZip: 20636,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '23263 NICHOLSON ST',
        RegAddrCity: 'HOLLYWOOD',
        RegAddrState: 'MD',
        RegAddrZip: 20636,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '6950 ORCHARD VIEW LN',
        RegAddrCity: 'HUGHESVILLE',
        RegAddrState: 'MD',
        RegAddrZip: 20637,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '5131 SOLOMONS ISLAND RD',
        RegAddrCity: 'HUNTINGTOWN',
        RegAddrState: 'MD',
        RegAddrZip: 20639,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '1445 ABBEY LN',
        RegAddrCity: 'HUNTINGTOWN',
        RegAddrState: 'MD',
        RegAddrZip: 20639,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '9640 TERRI DR',
        RegAddrCity: 'LA PLATA',
        RegAddrState: 'MD',
        RegAddrZip: 20646,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '9108 5TH ST',
        RegAddrCity: 'LANHAM',
        RegAddrState: 'MD',
        RegAddrZip: 20706,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '12610 N POINT LN',
        RegAddrCity: 'LAUREL',
        RegAddrState: 'MD',
        RegAddrZip: 20708,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '4202 SOMERVILLE CT',
        RegAddrCity: 'LAUREL',
        RegAddrState: 'MD',
        RegAddrZip: 20708,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '26466 POINT LOOKOUT RD',
        RegAddrCity: 'LEONARDTOWN',
        RegAddrState: 'MD',
        RegAddrZip: 20650,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '23244 TOWN CREEK DR',
        RegAddrCity: 'LEXINGTON PARK',
        RegAddrState: 'MD',
        RegAddrZip: 20653,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '22860 MAPLE RD',
        RegAddrCity: 'LEXINGTON PK',
        RegAddrState: 'MD',
        RegAddrZip: 20653,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '17624 POINT LOOKOUT RD',
        RegAddrCity: 'LEXINGTON PK',
        RegAddrState: 'MD',
        RegAddrZip: 20653,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '200 BOONES DR',
        RegAddrCity: 'LOTHIAN',
        RegAddrState: 'MD',
        RegAddrZip: 20711,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '916 ALGONQUIN CT',
        RegAddrCity: 'LUSBY',
        RegAddrState: 'MD',
        RegAddrZip: 20657,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '12117 DRY FORD LN',
        RegAddrCity: 'LUSBY',
        RegAddrState: 'MD',
        RegAddrZip: 20657,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '39900 GRANDVIEW HAVEN DR',
        RegAddrCity: 'MECHANICSVLLE',
        RegAddrState: 'MD',
        RegAddrZip: 20659,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '27804 MECHANICSVILLE RD',
        RegAddrCity: 'MECHANICSVLLE',
        RegAddrState: 'MD',
        RegAddrZip: 20659,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '26135 BARNES CT',
        RegAddrCity: 'MECHANICSVLLE',
        RegAddrState: 'MD',
        RegAddrZip: 20659,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '26302 T WOOD DR',
        RegAddrCity: 'MECHANICSVLLE',
        RegAddrState: 'MD',
        RegAddrZip: 20659,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '14402 SECRETARIAT DR',
        RegAddrCity: 'MITCHELLVILLE',
        RegAddrState: 'MD',
        RegAddrZip: 20721,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '6407 85TH PL',
        RegAddrCity: 'NEW CARROLLTN',
        RegAddrState: 'MD',
        RegAddrZip: 20784,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '10130 POPES CREEK RD',
        RegAddrCity: 'NEWBURG',
        RegAddrState: 'MD',
        RegAddrZip: 20664,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '9700 ORLAND PARK RD',
        RegAddrCity: 'NEWBURG',
        RegAddrState: 'MD',
        RegAddrZip: 20664,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '9131 ATLANTIC AVE',
        RegAddrCity: 'NORTH BEACH',
        RegAddrState: 'MD',
        RegAddrZip: 20714,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '9444 OLD SOLOMONS ISLAND RD',
        RegAddrCity: 'OWINGS',
        RegAddrState: 'MD',
        RegAddrZip: 20736,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '44721 LIGHTHOUSE RD',
        RegAddrCity: 'PINEY POINT',
        RegAddrState: 'MD',
        RegAddrZip: 20674,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '4568 STRATFORD RD',
        RegAddrCity: 'POMFRET',
        RegAddrState: 'MD',
        RegAddrZip: 20675,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '1081 COLD POND CT',
        RegAddrCity: 'PRNC FREDERCK',
        RegAddrState: 'MD',
        RegAddrZip: 20678,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '47 GRAY INN CT',
        RegAddrCity: 'PRNC FREDERCK',
        RegAddrState: 'MD',
        RegAddrZip: 20678,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '9500 WELLINGTON ST',
        RegAddrCity: 'SEABROOK',
        RegAddrState: 'MD',
        RegAddrZip: 20706,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '5727 OAKCREST DR',
        RegAddrCity: 'ST LEONARD',
        RegAddrState: 'MD',
        RegAddrZip: 20685,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '6501 SOLOMONS ISLAND RD N',
        RegAddrCity: 'SUNDERLAND',
        RegAddrState: 'MD',
        RegAddrZip: 20689,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '10909 TIMBERLINE DR',
        RegAddrCity: 'UPPR MARLBORO',
        RegAddrState: 'MD',
        RegAddrZip: 20772,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '14517 MARLBOROUGH CIR',
        RegAddrCity: 'UPPR MARLBORO',
        RegAddrState: 'MD',
        RegAddrZip: 20772,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '44668 SPRINGER RD',
        RegAddrCity: 'VALLEY LEE',
        RegAddrState: 'MD',
        RegAddrZip: 20692,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '2058 TANGLEWOOD DR',
        RegAddrCity: 'WALDORF',
        RegAddrState: 'MD',
        RegAddrZip: 20601,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '6066 RED SQUIRREL PL',
        RegAddrCity: 'WALDORF',
        RegAddrState: 'MD',
        RegAddrZip: 20603,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '10425 MARKBY CT',
        RegAddrCity: 'WHITE PLAINS',
        RegAddrState: 'MD',
        RegAddrZip: 20695,
        CongressionalDistrict: 5,
      },
    ],
  },
  {
    name: 'MD-6',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/MD-6?ref=gh-pages',
    state: 'MD',
    state_and_number: 'MD-6',
    zip: 20817,
    congressionalDistrict: 6,
    addressesInDistrict: [
      {
        RegAddrLine1: '24204 NEW EVA LN SW',
        RegAddrCity: 'BARTON',
        RegAddrState: 'MD',
        RegAddrZip: 21521,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '19900 W HUNTER RD',
        RegAddrCity: 'BEALLSVILLE',
        RegAddrState: 'MD',
        RegAddrZip: 20839,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '25506 LARRABEE AVE',
        RegAddrCity: 'CASCADE',
        RegAddrState: 'MD',
        RegAddrZip: 21719,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '13217 ROCKDALE RD',
        RegAddrCity: 'CLEAR SPRING',
        RegAddrState: 'MD',
        RegAddrZip: 21722,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '12236 BIG POOL RD',
        RegAddrCity: 'CLEAR SPRING',
        RegAddrState: 'MD',
        RegAddrZip: 21722,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '14220 WALTER DR SW',
        RegAddrCity: 'CUMBERLAND',
        RegAddrState: 'MD',
        RegAddrZip: 21502,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '11 BELLEVUE ST',
        RegAddrCity: 'CUMBERLAND',
        RegAddrState: 'MD',
        RegAddrZip: 21502,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '50 OAK ST',
        RegAddrCity: 'CUMBERLAND',
        RegAddrState: 'MD',
        RegAddrZip: 21502,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '15 W ALL SAINTS ST',
        RegAddrCity: 'FREDERICK',
        RegAddrState: 'MD',
        RegAddrZip: 21701,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '1820 LATHAM DR',
        RegAddrCity: 'FREDERICK',
        RegAddrState: 'MD',
        RegAddrZip: 21701,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '5517 WESTCOTT CIR',
        RegAddrCity: 'FREDERICK',
        RegAddrState: 'MD',
        RegAddrZip: 21703,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '9018 HARRIS ST',
        RegAddrCity: 'FREDERICK',
        RegAddrState: 'MD',
        RegAddrZip: 21704,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '9425 CORNWELL MEADOW ST',
        RegAddrCity: 'FREDERICK',
        RegAddrState: 'MD',
        RegAddrZip: 21704,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '8406 WILLIAMS DR',
        RegAddrCity: 'FREDERICK',
        RegAddrState: 'MD',
        RegAddrZip: 21704,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '6892 CRANESVILLE RD',
        RegAddrCity: 'FRIENDSVILLE',
        RegAddrState: 'MD',
        RegAddrZip: 21531,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '1456 GRAVEL HILL RD',
        RegAddrCity: 'FROSTBURG',
        RegAddrState: 'MD',
        RegAddrZip: 21532,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '202 W MECHANIC ST',
        RegAddrCity: 'FROSTBURG',
        RegAddrState: 'MD',
        RegAddrZip: 21532,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '508 ROCK LODGE RD',
        RegAddrCity: 'GAITHERSBURG',
        RegAddrState: 'MD',
        RegAddrZip: 20877,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '6 SEURAT CT',
        RegAddrCity: 'GAITHERSBURG',
        RegAddrState: 'MD',
        RegAddrZip: 20878,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '12650 LLOYDMINSTER DR',
        RegAddrCity: 'GAITHERSBURG',
        RegAddrState: 'MD',
        RegAddrZip: 20878,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '7620 ELIOAK TER',
        RegAddrCity: 'GAITHERSBURG',
        RegAddrState: 'MD',
        RegAddrZip: 20879,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '9102 CENTERWAY RD',
        RegAddrCity: 'GAITHERSBURG',
        RegAddrState: 'MD',
        RegAddrZip: 20879,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '9133 GOSHEN VALLEY DR',
        RegAddrCity: 'GAITHERSBURG',
        RegAddrState: 'MD',
        RegAddrZip: 20882,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '19209 FOREST BROOK RD',
        RegAddrCity: 'GERMANTOWN',
        RegAddrState: 'MD',
        RegAddrZip: 20874,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '20307 WATERS ROW TER',
        RegAddrCity: 'GERMANTOWN',
        RegAddrState: 'MD',
        RegAddrZip: 20874,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '21013 SOJOURN CT',
        RegAddrCity: 'GERMANTOWN',
        RegAddrState: 'MD',
        RegAddrZip: 20876,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '11520 DELLWYN DR',
        RegAddrCity: 'HAGERSTOWN',
        RegAddrState: 'MD',
        RegAddrZip: 21740,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '12845 RESH RD',
        RegAddrCity: 'HAGERSTOWN',
        RegAddrState: 'MD',
        RegAddrZip: 21740,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '23 WESTSIDE AVE',
        RegAddrCity: 'HAGERSTOWN',
        RegAddrState: 'MD',
        RegAddrZip: 21740,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '24 BERNER AVE',
        RegAddrCity: 'HAGERSTOWN',
        RegAddrState: 'MD',
        RegAddrZip: 21740,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '12245 WILD ROSE LN',
        RegAddrCity: 'HAGERSTOWN',
        RegAddrState: 'MD',
        RegAddrZip: 21742,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '14431 TOLLGATE RDG',
        RegAddrCity: 'HANCOCK',
        RegAddrState: 'MD',
        RegAddrZip: 21750,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '14 HELMAN DR',
        RegAddrCity: 'LAVALE',
        RegAddrState: 'MD',
        RegAddrZip: 21502,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '20201 YANKEE HARBOR PL',
        RegAddrCity: 'MONTGOMRY VLG',
        RegAddrState: 'MD',
        RegAddrZip: 20886,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '13403 REED RD NW',
        RegAddrCity: 'MOUNT SAVAGE',
        RegAddrState: 'MD',
        RegAddrZip: 21545,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '2552 SAND FLAT RD',
        RegAddrCity: 'OAKLAND',
        RegAddrState: 'MD',
        RegAddrZip: 21550,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '21 LAUREL BROOK DR',
        RegAddrCity: 'OAKLAND',
        RegAddrState: 'MD',
        RegAddrZip: 21550,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '15507 CRESAP MILL RD SE',
        RegAddrCity: 'OLDTOWN',
        RegAddrState: 'MD',
        RegAddrZip: 21555,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '12012 EDGEPARK CT',
        RegAddrCity: 'POTOMAC',
        RegAddrState: 'MD',
        RegAddrZip: 20854,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '8082 INVERNESS RIDGE RD',
        RegAddrCity: 'POTOMAC',
        RegAddrState: 'MD',
        RegAddrZip: 20854,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '9825 KENTSDALE DR',
        RegAddrCity: 'POTOMAC',
        RegAddrState: 'MD',
        RegAddrZip: 20854,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '5304 CRESTEDGE LN',
        RegAddrCity: 'ROCKVILLE',
        RegAddrState: 'MD',
        RegAddrZip: 20853,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '15004 WESTBURY RD',
        RegAddrCity: 'ROCKVILLE',
        RegAddrState: 'MD',
        RegAddrZip: 20853,
        CongressionalDistrict: 6,
      },
    ],
  },
  {
    name: 'MD-7',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/MD-7?ref=gh-pages',
    state: 'MD',
    state_and_number: 'MD-7',
    zip: 21036,
    congressionalDistrict: 7,
    addressesInDistrict: [
      {
        RegAddrLine1: '419 E OLIVER ST',
        RegAddrCity: 'BALTIMORE',
        RegAddrState: 'MD',
        RegAddrZip: 21202,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '1922 ENGLEWOOD AVE',
        RegAddrCity: 'BALTIMORE',
        RegAddrState: 'MD',
        RegAddrZip: 21207,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '2217 LUKEWOOD DR',
        RegAddrCity: 'BALTIMORE',
        RegAddrState: 'MD',
        RegAddrZip: 21207,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '5520 LIBERTY HEIGHTS AVE',
        RegAddrCity: 'BALTIMORE',
        RegAddrState: 'MD',
        RegAddrZip: 21207,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '17 BRUBAR CT',
        RegAddrCity: 'BALTIMORE',
        RegAddrState: 'MD',
        RegAddrZip: 21207,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '1500 INGLESIDE AVE',
        RegAddrCity: 'BALTIMORE',
        RegAddrState: 'MD',
        RegAddrZip: 21207,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '7110 PAHLS FARM WAY',
        RegAddrCity: 'BALTIMORE',
        RegAddrState: 'MD',
        RegAddrZip: 21208,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '2324 PENNYROYAL TER',
        RegAddrCity: 'BALTIMORE',
        RegAddrState: 'MD',
        RegAddrZip: 21209,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '419 TUXEDO ST',
        RegAddrCity: 'BALTIMORE',
        RegAddrState: 'MD',
        RegAddrZip: 21211,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '3114 E FEDERAL ST',
        RegAddrCity: 'BALTIMORE',
        RegAddrState: 'MD',
        RegAddrZip: 21213,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '4629 PARK HEIGHTS AVE',
        RegAddrCity: 'BALTIMORE',
        RegAddrState: 'MD',
        RegAddrZip: 21215,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '3808 HAYWARD AVE',
        RegAddrCity: 'BALTIMORE',
        RegAddrState: 'MD',
        RegAddrZip: 21215,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '2833 EDGECOMBE CIR N',
        RegAddrCity: 'BALTIMORE',
        RegAddrState: 'MD',
        RegAddrZip: 21215,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '4136 WOODHAVEN AVE',
        RegAddrCity: 'BALTIMORE',
        RegAddrState: 'MD',
        RegAddrZip: 21216,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '2239 BROOKFIELD AVE',
        RegAddrCity: 'BALTIMORE',
        RegAddrState: 'MD',
        RegAddrZip: 21217,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '524 SANFORD PL',
        RegAddrCity: 'BALTIMORE',
        RegAddrState: 'MD',
        RegAddrZip: 21217,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '581 LAURENS ST',
        RegAddrCity: 'BALTIMORE',
        RegAddrState: 'MD',
        RegAddrZip: 21217,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '2114 LAKE AVE',
        RegAddrCity: 'BALTIMORE',
        RegAddrState: 'MD',
        RegAddrZip: 21218,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '924 E 41ST ST',
        RegAddrCity: 'BALTIMORE',
        RegAddrState: 'MD',
        RegAddrZip: 21218,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '622 E 29TH ST',
        RegAddrCity: 'BALTIMORE',
        RegAddrState: 'MD',
        RegAddrZip: 21218,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '228 N KENWOOD AVE',
        RegAddrCity: 'BALTIMORE',
        RegAddrState: 'MD',
        RegAddrZip: 21224,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '1273 MAPLE AVE',
        RegAddrCity: 'BALTIMORE',
        RegAddrState: 'MD',
        RegAddrZip: 21227,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '601 MAIDEN CHOICE LN',
        RegAddrCity: 'BALTIMORE',
        RegAddrState: 'MD',
        RegAddrZip: 21228,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '38 HOLMEHURST AVE',
        RegAddrCity: 'BALTIMORE',
        RegAddrState: 'MD',
        RegAddrZip: 21228,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '561 BRISBANE RD',
        RegAddrCity: 'BALTIMORE',
        RegAddrState: 'MD',
        RegAddrZip: 21229,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '409 KINGSTON RD',
        RegAddrCity: 'BALTIMORE',
        RegAddrState: 'MD',
        RegAddrZip: 21229,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '1607 WALTERSWOOD RD',
        RegAddrCity: 'BALTIMORE',
        RegAddrState: 'MD',
        RegAddrZip: 21239,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '10425 KINGSBRIDGE RD',
        RegAddrCity: 'ELLICOTT CITY',
        RegAddrState: 'MD',
        RegAddrZip: 21042,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '4010 CHATHAM RD',
        RegAddrCity: 'ELLICOTT CITY',
        RegAddrState: 'MD',
        RegAddrZip: 21042,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '9115 FATHERS LEGACY',
        RegAddrCity: 'ELLICOTT CITY',
        RegAddrState: 'MD',
        RegAddrZip: 21042,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '8530 ROSECROFT TER',
        RegAddrCity: 'ELLICOTT CITY',
        RegAddrState: 'MD',
        RegAddrZip: 21043,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '4508 RUSTY GATE',
        RegAddrCity: 'ELLICOTT CITY',
        RegAddrState: 'MD',
        RegAddrZip: 21043,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '3230 WEST SPRINGS DR',
        RegAddrCity: 'ELLICOTT CITY',
        RegAddrState: 'MD',
        RegAddrZip: 21043,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '14830 SAPLING WAY',
        RegAddrCity: 'GLENELG',
        RegAddrState: 'MD',
        RegAddrZip: 21737,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '1120 HUNT CREEK LN',
        RegAddrCity: 'SPARKS GLENCO',
        RegAddrState: 'MD',
        RegAddrZip: 21152,
        CongressionalDistrict: 7,
      },
    ],
  },
  {
    name: 'MD-8',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/MD-8?ref=gh-pages',
    state: 'MD',
    state_and_number: 'MD-8',
    zip: 20812,
    congressionalDistrict: 8,
    addressesInDistrict: [
      {
        RegAddrLine1: '4505 TOURNAY RD',
        RegAddrCity: 'BETHESDA',
        RegAddrState: 'MD',
        RegAddrZip: 20816,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '9930 JULLIARD DR',
        RegAddrCity: 'BETHESDA',
        RegAddrState: 'MD',
        RegAddrZip: 20817,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '7514 NEVIS RD',
        RegAddrCity: 'BETHESDA',
        RegAddrState: 'MD',
        RegAddrZip: 20817,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '5619 ARTESIAN DR',
        RegAddrCity: 'DERWOOD',
        RegAddrState: 'MD',
        RegAddrZip: 20855,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '7659 HAMPTON VALLEY RD',
        RegAddrCity: 'EMMITSBURG',
        RegAddrState: 'MD',
        RegAddrZip: 21727,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '6155 FIELDCREST DR',
        RegAddrCity: 'FREDERICK',
        RegAddrState: 'MD',
        RegAddrZip: 21701,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '5550 BALLENGER CREEK PIKE',
        RegAddrCity: 'FREDERICK',
        RegAddrState: 'MD',
        RegAddrZip: 21703,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '6835 MARYLAND AVE',
        RegAddrCity: 'FREDERICK',
        RegAddrState: 'MD',
        RegAddrZip: 21703,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '5902 LEBEN DR',
        RegAddrCity: 'FREDERICK',
        RegAddrState: 'MD',
        RegAddrZip: 21703,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '2228 MICHAEL RD',
        RegAddrCity: 'MYERSVILLE',
        RegAddrState: 'MD',
        RegAddrZip: 21773,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '7028 FOX CHASE RD',
        RegAddrCity: 'NEW MARKET',
        RegAddrState: 'MD',
        RegAddrZip: 21774,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '5719 WAINWRIGHT AVE',
        RegAddrCity: 'ROCKVILLE',
        RegAddrState: 'MD',
        RegAddrZip: 20851,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '209 LEIGHTON AVE',
        RegAddrCity: 'SILVER SPRING',
        RegAddrState: 'MD',
        RegAddrZip: 20901,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '11510 COLLEGE VIEW DR',
        RegAddrCity: 'SILVER SPRING',
        RegAddrState: 'MD',
        RegAddrZip: 20902,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '1500 GRIDLEY LN',
        RegAddrCity: 'SILVER SPRING',
        RegAddrState: 'MD',
        RegAddrZip: 20902,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '13942 VALLEYFIELD DR',
        RegAddrCity: 'SILVER SPRING',
        RegAddrState: 'MD',
        RegAddrZip: 20906,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '12723 HATHAWAY DR',
        RegAddrCity: 'SILVER SPRING',
        RegAddrState: 'MD',
        RegAddrZip: 20906,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '2036 RED RIVER RD',
        RegAddrCity: 'SYKESVILLE',
        RegAddrState: 'MD',
        RegAddrZip: 21784,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '7180 NORRIS AVE',
        RegAddrCity: 'SYKESVILLE',
        RegAddrState: 'MD',
        RegAddrZip: 21784,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '6817 WHITE ROCK RD',
        RegAddrCity: 'SYKESVILLE',
        RegAddrState: 'MD',
        RegAddrZip: 21784,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '2071 HARVEST FARM RD',
        RegAddrCity: 'SYKESVILLE',
        RegAddrState: 'MD',
        RegAddrZip: 21784,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '609 FANNIE DORSEY RD',
        RegAddrCity: 'SYKESVILLE',
        RegAddrState: 'MD',
        RegAddrZip: 21784,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '402 BOYD AVE',
        RegAddrCity: 'TAKOMA PARK',
        RegAddrState: 'MD',
        RegAddrZip: 20912,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '539 GENTRY CT',
        RegAddrCity: 'WESTMINSTER',
        RegAddrState: 'MD',
        RegAddrZip: 21157,
        CongressionalDistrict: 8,
      },
    ],
  },
  {
    name: 'ME-1',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/ME-1?ref=gh-pages',
    state: 'ME',
    state_and_number: 'ME-1',
    zip: 3901,
    congressionalDistrict: 1,
    addressesInDistrict: [
      {
        RegAddrLine1: '1613 H RD',
        RegAddrCity: 'ACTON',
        RegAddrState: 'ME',
        RegAddrZip: 4001,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2059 MILTON MILLS RD',
        RegAddrCity: 'ACTON',
        RegAddrState: 'ME',
        RegAddrZip: 4001,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '253 SANFORD RD',
        RegAddrCity: 'ALFRED',
        RegAddrState: 'ME',
        RegAddrZip: 4002,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '21 DOCK RD',
        RegAddrCity: 'ALNA',
        RegAddrState: 'ME',
        RegAddrZip: 4535,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1050 GUINEA RIDGE RD',
        RegAddrCity: 'APPLETON',
        RegAddrState: 'ME',
        RegAddrZip: 4862,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '703 LIMERICK RD',
        RegAddrCity: 'ARUNDEL',
        RegAddrState: 'ME',
        RegAddrZip: 4046,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '836 OLD POST RD',
        RegAddrCity: 'ARUNDEL',
        RegAddrState: 'ME',
        RegAddrZip: 4046,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1781 PORTLAND RD',
        RegAddrCity: 'ARUNDEL',
        RegAddrState: 'ME',
        RegAddrZip: 4046,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '3 IRIS CT',
        RegAddrCity: 'AUGUSTA',
        RegAddrState: 'ME',
        RegAddrZip: 4330,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '93 ALBEE RD',
        RegAddrCity: 'AUGUSTA',
        RegAddrState: 'ME',
        RegAddrZip: 4330,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '51 FAIRVIEW AVE',
        RegAddrCity: 'AUGUSTA',
        RegAddrState: 'ME',
        RegAddrZip: 4330,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '31 LONGWOOD AVE',
        RegAddrCity: 'AUGUSTA',
        RegAddrState: 'ME',
        RegAddrZip: 4330,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '71 BOLTON HILL RD',
        RegAddrCity: 'AUGUSTA',
        RegAddrState: 'ME',
        RegAddrZip: 4330,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '7 TOWNSEND ST',
        RegAddrCity: 'AUGUSTA',
        RegAddrState: 'ME',
        RegAddrZip: 4330,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '16 HIDDEN HAVEN LN',
        RegAddrCity: 'AUGUSTA',
        RegAddrState: 'ME',
        RegAddrZip: 4330,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '27 LYON LN',
        RegAddrCity: 'AUGUSTA',
        RegAddrState: 'ME',
        RegAddrZip: 4330,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '403 LARK ST',
        RegAddrCity: 'BATH',
        RegAddrState: 'ME',
        RegAddrZip: 4530,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '31 VALLEY RD',
        RegAddrCity: 'BATH',
        RegAddrState: 'ME',
        RegAddrZip: 4530,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '36 HEATH LN',
        RegAddrCity: 'BATH',
        RegAddrState: 'ME',
        RegAddrZip: 4530,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '616 HIGH ST',
        RegAddrCity: 'BATH',
        RegAddrState: 'ME',
        RegAddrZip: 4530,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '363 CRANBERRY MEADOW RD',
        RegAddrCity: 'BERWICK',
        RegAddrState: 'ME',
        RegAddrZip: 3901,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '85B SULLIVAN ST',
        RegAddrCity: 'BERWICK',
        RegAddrState: 'ME',
        RegAddrZip: 3901,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '41 TALL TIMBERS DR',
        RegAddrCity: 'BERWICK',
        RegAddrState: 'ME',
        RegAddrZip: 3901,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1 CATHEDRAL OAKS DR',
        RegAddrCity: 'BIDDEFORD',
        RegAddrState: 'ME',
        RegAddrZip: 4005,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '78 GREEN ST',
        RegAddrCity: 'BIDDEFORD',
        RegAddrState: 'ME',
        RegAddrZip: 4005,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '506 MAIN ST',
        RegAddrCity: 'BIDDEFORD',
        RegAddrState: 'ME',
        RegAddrZip: 4005,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '173 GRAHAM ST',
        RegAddrCity: 'BIDDEFORD',
        RegAddrState: 'ME',
        RegAddrZip: 4005,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '9 RED OAK LN',
        RegAddrCity: 'BIDDEFORD',
        RegAddrState: 'ME',
        RegAddrZip: 4005,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '36 GREENFIELD LN',
        RegAddrCity: 'BIDDEFORD',
        RegAddrState: 'ME',
        RegAddrZip: 4005,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '10 MAY ST',
        RegAddrCity: 'BIDDEFORD',
        RegAddrState: 'ME',
        RegAddrZip: 4005,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '42 CENTER ST',
        RegAddrCity: 'BIDDEFORD',
        RegAddrState: 'ME',
        RegAddrZip: 4005,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '5 NORTHERN DR',
        RegAddrCity: 'BOOTHBAY',
        RegAddrState: 'ME',
        RegAddrZip: 4537,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '28 EASTERN AVE',
        RegAddrCity: 'BOOTHBAY HARBOR',
        RegAddrState: 'ME',
        RegAddrZip: 4538,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '47 REED RD',
        RegAddrCity: 'BOOTHBAY HARBOR',
        RegAddrState: 'ME',
        RegAddrZip: 4538,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '14 FOSTER LN',
        RegAddrCity: 'BOWDOINHAM',
        RegAddrState: 'ME',
        RegAddrZip: 4008,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1061 POST RD',
        RegAddrCity: 'BOWDOINHAM',
        RegAddrState: 'ME',
        RegAddrZip: 4008,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '173 DINGLEY RD',
        RegAddrCity: 'BOWDOINHAM',
        RegAddrState: 'ME',
        RegAddrZip: 4008,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '917 BISCAY RD',
        RegAddrCity: 'BREMEN',
        RegAddrState: 'ME',
        RegAddrZip: 4551,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '192 MAIN ST',
        RegAddrCity: 'BRIDGTON',
        RegAddrState: 'ME',
        RegAddrZip: 4009,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '748 RIVER RD',
        RegAddrCity: 'BRUNSWICK',
        RegAddrState: 'ME',
        RegAddrZip: 4011,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '420 WOODSIDE RD',
        RegAddrCity: 'BRUNSWICK',
        RegAddrState: 'ME',
        RegAddrZip: 4011,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '815 MERE POINT RD',
        RegAddrCity: 'BRUNSWICK',
        RegAddrState: 'ME',
        RegAddrZip: 4011,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '5 COLES TOWER DR',
        RegAddrCity: 'BRUNSWICK',
        RegAddrState: 'ME',
        RegAddrZip: 4011,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '240 CHURCH RD',
        RegAddrCity: 'BRUNSWICK',
        RegAddrState: 'ME',
        RegAddrZip: 4011,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '7 LEEWARD COVE RD',
        RegAddrCity: 'BRUNSWICK',
        RegAddrState: 'ME',
        RegAddrZip: 4011,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '28 JEWELL ST',
        RegAddrCity: 'BRUNSWICK',
        RegAddrState: 'ME',
        RegAddrZip: 4011,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '32 MAGEAN ST',
        RegAddrCity: 'BRUNSWICK',
        RegAddrState: 'ME',
        RegAddrZip: 4011,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '53 GLEED DR',
        RegAddrCity: 'BRUNSWICK',
        RegAddrState: 'ME',
        RegAddrZip: 4011,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '14 JUSTAMERE RD',
        RegAddrCity: 'BRUNSWICK',
        RegAddrState: 'ME',
        RegAddrZip: 4011,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '18 WINDY MEADOW RD',
        RegAddrCity: 'BUXTON',
        RegAddrState: 'ME',
        RegAddrZip: 4093,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '311 MARY JANE RD',
        RegAddrCity: 'BUXTON',
        RegAddrState: 'ME',
        RegAddrZip: 4093,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '993C LONG PLAINS RD',
        RegAddrCity: 'BUXTON',
        RegAddrState: 'ME',
        RegAddrZip: 4093,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '236 PEASE RD',
        RegAddrCity: 'BUXTON',
        RegAddrState: 'ME',
        RegAddrZip: 4093,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '85 GROVEVILLE RD',
        RegAddrCity: 'BUXTON',
        RegAddrState: 'ME',
        RegAddrZip: 4093,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '337 TURKEY LN',
        RegAddrCity: 'BUXTON',
        RegAddrState: 'ME',
        RegAddrZip: 4093,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '43 MAIN ST',
        RegAddrCity: 'CAMDEN',
        RegAddrState: 'ME',
        RegAddrZip: 4843,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '22 EATON AVE',
        RegAddrCity: 'CAMDEN',
        RegAddrState: 'ME',
        RegAddrZip: 4843,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '15 MARINE AVE',
        RegAddrCity: 'CAMDEN',
        RegAddrState: 'ME',
        RegAddrZip: 4843,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '34 CONCORD PL',
        RegAddrCity: 'CAPE ELIZ',
        RegAddrState: 'ME',
        RegAddrZip: 4107,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '3 CEDAR LEDGE RD',
        RegAddrCity: 'CAPE ELIZ',
        RegAddrState: 'ME',
        RegAddrZip: 4107,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '9 FENWAY RD',
        RegAddrCity: 'CAPE ELIZ',
        RegAddrState: 'ME',
        RegAddrZip: 4107,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '43 COTTAGE FARMS RD',
        RegAddrCity: 'CAPE ELIZ',
        RegAddrState: 'ME',
        RegAddrZip: 4107,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '6 ELMWOOD RD',
        RegAddrCity: 'CAPE ELIZABETH',
        RegAddrState: 'ME',
        RegAddrZip: 4107,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '37 EASTMAN RD',
        RegAddrCity: 'CAPE ELIZABETH',
        RegAddrState: 'ME',
        RegAddrZip: 4107,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '48 HIGGINS HL',
        RegAddrCity: 'CASCO',
        RegAddrState: 'ME',
        RegAddrZip: 4015,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '27 LAKE SHORE DR',
        RegAddrCity: 'CASCO',
        RegAddrState: 'ME',
        RegAddrZip: 4015,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '39 THOMAS POND SHORE RD',
        RegAddrCity: 'CASCO',
        RegAddrState: 'ME',
        RegAddrZip: 4015,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '84 COLLINS RD',
        RegAddrCity: 'CHELSEA',
        RegAddrState: 'ME',
        RegAddrZip: 4330,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '27 HALLET ST',
        RegAddrCity: 'CHELSEA',
        RegAddrState: 'ME',
        RegAddrZip: 4330,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '179 DIRIGO RD',
        RegAddrCity: 'CHINA',
        RegAddrState: 'ME',
        RegAddrZip: 4358,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1354 ROUTE 3',
        RegAddrCity: 'CHINA',
        RegAddrState: 'ME',
        RegAddrZip: 4358,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '277 DEER HILL RD',
        RegAddrCity: 'CHINA',
        RegAddrState: 'ME',
        RegAddrZip: 4358,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '43 SOKOKIS TRL',
        RegAddrCity: 'CORNISH',
        RegAddrState: 'ME',
        RegAddrZip: 4020,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '272 HIGH RD',
        RegAddrCity: 'CORNISH',
        RegAddrState: 'ME',
        RegAddrZip: 4020,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '56 BIRCH DR',
        RegAddrCity: 'CORNISH',
        RegAddrState: 'ME',
        RegAddrZip: 4020,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '27 CARRIAGE RD',
        RegAddrCity: 'CUMB FORESIDE',
        RegAddrState: 'ME',
        RegAddrZip: 4110,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '37 STIRLING WAY',
        RegAddrCity: 'CUMBERLAND',
        RegAddrState: 'ME',
        RegAddrZip: 4021,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '7 LINDEN CT',
        RegAddrCity: 'CUMBERLAND',
        RegAddrState: 'ME',
        RegAddrZip: 4021,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '11 WOODSIDE DR',
        RegAddrCity: 'CUMBERLAND',
        RegAddrState: 'ME',
        RegAddrZip: 4021,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '20 HYLER COVE LN',
        RegAddrCity: 'CUSHING',
        RegAddrState: 'ME',
        RegAddrZip: 4563,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '492 EGYPT RD',
        RegAddrCity: 'DAMARISCOTTA',
        RegAddrState: 'ME',
        RegAddrZip: 4543,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '38 GOODWINS ACRES',
        RegAddrCity: 'DAYTON',
        RegAddrState: 'ME',
        RegAddrZip: 4005,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '165 RIVER RD',
        RegAddrCity: 'DRESDEN',
        RegAddrState: 'ME',
        RegAddrZip: 4342,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '354 GARDINER RD',
        RegAddrCity: 'DRESDEN',
        RegAddrState: 'ME',
        RegAddrZip: 4342,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '11 HERITAGE DR',
        RegAddrCity: 'ELIOT',
        RegAddrState: 'ME',
        RegAddrZip: 3903,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '40 HOUDE RD',
        RegAddrCity: 'ELIOT',
        RegAddrState: 'ME',
        RegAddrZip: 3903,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '179 DEPOT RD',
        RegAddrCity: 'ELIOT',
        RegAddrState: 'ME',
        RegAddrZip: 3903,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '989 MAIN ST',
        RegAddrCity: 'ELIOT',
        RegAddrState: 'ME',
        RegAddrZip: 3903,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '150 ROLLINGWOOD RD',
        RegAddrCity: 'ELIOT',
        RegAddrState: 'ME',
        RegAddrZip: 3903,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '343 FALMOUTH RD',
        RegAddrCity: 'FALMOUTH',
        RegAddrState: 'ME',
        RegAddrZip: 4105,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '21 MILL RD',
        RegAddrCity: 'FALMOUTH',
        RegAddrState: 'ME',
        RegAddrZip: 4105,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '4 BRIARWOOD LN',
        RegAddrCity: 'FALMOUTH',
        RegAddrState: 'ME',
        RegAddrZip: 4105,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '4 CRUSTON WAY',
        RegAddrCity: 'FALMOUTH',
        RegAddrState: 'ME',
        RegAddrZip: 4105,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '6 APPLEGATE LN',
        RegAddrCity: 'FALMOUTH',
        RegAddrState: 'ME',
        RegAddrZip: 4105,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '6 OAK ST',
        RegAddrCity: 'FARMINGDALE',
        RegAddrState: 'ME',
        RegAddrZip: 4344,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '78 SOUTH ST',
        RegAddrCity: 'FREEPORT',
        RegAddrState: 'ME',
        RegAddrZip: 4032,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1 HARVEY BROOK DR',
        RegAddrCity: 'FREEPORT',
        RegAddrState: 'ME',
        RegAddrZip: 4032,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '6 OLD COUNTY RD',
        RegAddrCity: 'FREEPORT',
        RegAddrState: 'ME',
        RegAddrZip: 4032,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '238 WALDOBORO RD',
        RegAddrCity: 'FRIENDSHIP',
        RegAddrState: 'ME',
        RegAddrZip: 4547,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '11 WEEKS RD',
        RegAddrCity: 'GORHAM',
        RegAddrState: 'ME',
        RegAddrZip: 4038,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '236 MOSHER RD',
        RegAddrCity: 'GORHAM',
        RegAddrState: 'ME',
        RegAddrZip: 4038,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '60 SMALL POND RD',
        RegAddrCity: 'GORHAM',
        RegAddrState: 'ME',
        RegAddrZip: 4038,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '305 WOODWARD HALL',
        RegAddrCity: 'GORHAM',
        RegAddrState: 'ME',
        RegAddrZip: 4038,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '11 JOSEPH DR',
        RegAddrCity: 'GORHAM',
        RegAddrState: 'ME',
        RegAddrZip: 4038,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '168 OSSIPEE TRL',
        RegAddrCity: 'GORHAM',
        RegAddrState: 'ME',
        RegAddrZip: 4038,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1 ASPEN LN',
        RegAddrCity: 'GORHAM',
        RegAddrState: 'ME',
        RegAddrZip: 4038,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '403 UPTON HALL',
        RegAddrCity: 'GORHAM',
        RegAddrState: 'ME',
        RegAddrZip: 4038,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '58 UNDERHILL DR',
        RegAddrCity: 'GORHAM',
        RegAddrState: 'ME',
        RegAddrZip: 4038,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '13 LEGROW RD',
        RegAddrCity: 'GRAY',
        RegAddrState: 'ME',
        RegAddrZip: 4039,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '68 FOREST LAKE RD',
        RegAddrCity: 'GRAY',
        RegAddrState: 'ME',
        RegAddrZip: 4039,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '21 MAY MEADOW DR',
        RegAddrCity: 'GRAY',
        RegAddrState: 'ME',
        RegAddrZip: 4039,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '117 CENTRAL ST',
        RegAddrCity: 'HALLOWELL',
        RegAddrState: 'ME',
        RegAddrZip: 4347,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '19 UNION ST',
        RegAddrCity: 'HALLOWELL',
        RegAddrState: 'ME',
        RegAddrZip: 4347,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1 WILDER ST',
        RegAddrCity: 'HALLOWELL',
        RegAddrState: 'ME',
        RegAddrZip: 4347,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '62 WALLACE SHORE RD',
        RegAddrCity: 'HARPSWELL',
        RegAddrState: 'ME',
        RegAddrZip: 4079,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '13 EISENHOWER COVE RD',
        RegAddrCity: 'HARPSWELL',
        RegAddrState: 'ME',
        RegAddrZip: 4079,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '7 BRIDGE VIEW LN',
        RegAddrCity: 'HARPSWELL',
        RegAddrState: 'ME',
        RegAddrZip: 4079,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '7 TRUE LN',
        RegAddrCity: 'HOPE',
        RegAddrState: 'ME',
        RegAddrZip: 4847,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '288 GATES RD',
        RegAddrCity: 'JEFFERSON',
        RegAddrState: 'ME',
        RegAddrZip: 4348,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '8 COUNTRYFIELD CIR',
        RegAddrCity: 'KENNEBUNK',
        RegAddrState: 'ME',
        RegAddrZip: 4043,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '14 OAK BLUFF RD',
        RegAddrCity: 'KENNEBUNK',
        RegAddrState: 'ME',
        RegAddrZip: 4043,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '86 BROWN ST',
        RegAddrCity: 'KENNEBUNK',
        RegAddrState: 'ME',
        RegAddrZip: 4043,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '284 GOOSE ROCKS RD',
        RegAddrCity: 'KENNEBUNKPORT',
        RegAddrState: 'ME',
        RegAddrZip: 4046,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '51 BEACHWOOD AVE',
        RegAddrCity: 'KENNEBUNKPORT',
        RegAddrState: 'ME',
        RegAddrZip: 4046,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '8 TYLER BROOK RD',
        RegAddrCity: 'KENNEBUNKPORT',
        RegAddrState: 'ME',
        RegAddrZip: 4046,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '152 OAK RIDGE RD',
        RegAddrCity: 'KENNEBUNKPORT',
        RegAddrState: 'ME',
        RegAddrZip: 4046,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '6 JUNIPER KNOLL LN',
        RegAddrCity: 'KENNEBUNKPORT',
        RegAddrState: 'ME',
        RegAddrZip: 4046,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '7 BRIDGE ST',
        RegAddrCity: 'KITTERY',
        RegAddrState: 'ME',
        RegAddrZip: 3904,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '26 GOVERNMENT ST',
        RegAddrCity: 'KITTERY',
        RegAddrState: 'ME',
        RegAddrZip: 3904,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '10 PRINCE AVE',
        RegAddrCity: 'KITTERY',
        RegAddrState: 'ME',
        RegAddrZip: 3904,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '8 ELLA WOODS DR',
        RegAddrCity: 'KITTERY',
        RegAddrState: 'ME',
        RegAddrZip: 3904,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '5 BARTERS CREEK RD',
        RegAddrCity: 'KITTERY POINT',
        RegAddrState: 'ME',
        RegAddrZip: 3905,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '99 MILLS RD',
        RegAddrCity: 'LEBANON',
        RegAddrState: 'ME',
        RegAddrZip: 4027,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '211 ZEKES WAY',
        RegAddrCity: 'LEBANON',
        RegAddrState: 'ME',
        RegAddrZip: 4027,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '66 CHAMPION ST',
        RegAddrCity: 'LEBANON',
        RegAddrState: 'ME',
        RegAddrZip: 4027,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '50 AXELSEN RD',
        RegAddrCity: 'LIMINGTON',
        RegAddrState: 'ME',
        RegAddrZip: 4049,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '202 HUNTINGTON HILL RD',
        RegAddrCity: 'LITCHFIELD',
        RegAddrState: 'ME',
        RegAddrZip: 4350,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '139 FERN AVE',
        RegAddrCity: 'LONG ISLAND',
        RegAddrState: 'ME',
        RegAddrZip: 4050,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '51 S WATERBORO RD',
        RegAddrCity: 'LYMAN',
        RegAddrState: 'ME',
        RegAddrZip: 4002,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2091 ALFRED RD',
        RegAddrCity: 'LYMAN',
        RegAddrState: 'ME',
        RegAddrZip: 4002,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1054 WESTERN AVE',
        RegAddrCity: 'MANCHESTER',
        RegAddrState: 'ME',
        RegAddrZip: 4351,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '29 SUNSET DR',
        RegAddrCity: 'MANCHESTER',
        RegAddrState: 'ME',
        RegAddrZip: 4351,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '102 WHARF HL',
        RegAddrCity: 'MONHEGAN',
        RegAddrState: 'ME',
        RegAddrZip: 4852,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '65 PINE RIDGE RD',
        RegAddrCity: 'N YARMOUTH',
        RegAddrState: 'ME',
        RegAddrZip: 4097,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '15 FIELDSTONE DR',
        RegAddrCity: 'N YARMOUTH',
        RegAddrState: 'ME',
        RegAddrZip: 4097,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '740 SEBAGO RD',
        RegAddrCity: 'NAPLES',
        RegAddrState: 'ME',
        RegAddrZip: 4055,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '17 RUSTIC WAY',
        RegAddrCity: 'NEW GLOUCESTR',
        RegAddrState: 'ME',
        RegAddrZip: 4260,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '42 BRYANA WAY',
        RegAddrCity: 'NEW GLOUCESTR',
        RegAddrState: 'ME',
        RegAddrZip: 4260,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '14 LADY OF LK',
        RegAddrCity: 'NEW GLOUCESTR',
        RegAddrState: 'ME',
        RegAddrZip: 4260,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '23 APTD MILLS RD',
        RegAddrCity: 'NEWCASTLE',
        RegAddrState: 'ME',
        RegAddrZip: 4553,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '34 BENNER LN',
        RegAddrCity: 'NOBLEBORO',
        RegAddrState: 'ME',
        RegAddrZip: 4555,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '21 UPPER CROSS RD',
        RegAddrCity: 'NOBLEBORO',
        RegAddrState: 'ME',
        RegAddrZip: 4555,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '180 OAK WOODS RD',
        RegAddrCity: 'NORTH BERWICK',
        RegAddrState: 'ME',
        RegAddrZip: 3906,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '461 BAUNEG BEG HILL RD',
        RegAddrCity: 'NORTH BERWICK',
        RegAddrState: 'ME',
        RegAddrZip: 3906,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '168 RANDALL RD',
        RegAddrCity: 'NORTH BERWICK',
        RegAddrState: 'ME',
        RegAddrZip: 3906,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '18 MADISON ST',
        RegAddrCity: 'NORTH BERWICK',
        RegAddrState: 'ME',
        RegAddrZip: 3906,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '553 NEW GLOUCESTER RD',
        RegAddrCity: 'NORTH YARMOUTH',
        RegAddrState: 'ME',
        RegAddrZip: 4097,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '11 OVERLOOK RD',
        RegAddrCity: 'NORTHPORT',
        RegAddrState: 'ME',
        RegAddrZip: 4849,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '15 SCHOOL ST',
        RegAddrCity: 'OLD ORCHARD BEACH',
        RegAddrState: 'ME',
        RegAddrZip: 4064,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '21 BIRCH LN',
        RegAddrCity: 'OLD ORCHD BCH',
        RegAddrState: 'ME',
        RegAddrZip: 4064,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '6 KING PHILLIP DR',
        RegAddrCity: 'OLD ORCHD BCH',
        RegAddrState: 'ME',
        RegAddrZip: 4064,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '207 SACO AVE',
        RegAddrCity: 'OLD ORCHD BCH',
        RegAddrState: 'ME',
        RegAddrZip: 4064,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '16 GARDEN ST',
        RegAddrCity: 'OLD ORCHD BCH',
        RegAddrState: 'ME',
        RegAddrZip: 4064,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '62 BAYSIDE W',
        RegAddrCity: 'OWLS HEAD',
        RegAddrState: 'ME',
        RegAddrZip: 4854,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '347 MUDGETT RD',
        RegAddrCity: 'PARSONSFIELD',
        RegAddrState: 'ME',
        RegAddrZip: 4047,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '740 MOUNTAIN RD',
        RegAddrCity: 'PARSONSFIELD',
        RegAddrState: 'ME',
        RegAddrZip: 4047,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '14 CAMPBELL LN',
        RegAddrCity: 'PHIPPSBURG',
        RegAddrState: 'ME',
        RegAddrZip: 4562,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '565 WISCASSET RD',
        RegAddrCity: 'PITTSTON',
        RegAddrState: 'ME',
        RegAddrZip: 4345,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1202 WISCASSET RD',
        RegAddrCity: 'PITTSTON',
        RegAddrState: 'ME',
        RegAddrZip: 4345,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '123 CONGRESS ST',
        RegAddrCity: 'PORTLAND',
        RegAddrState: 'ME',
        RegAddrZip: 4101,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '24 MAYO ST',
        RegAddrCity: 'PORTLAND',
        RegAddrState: 'ME',
        RegAddrZip: 4101,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '202 CONGRESS ST',
        RegAddrCity: 'PORTLAND',
        RegAddrState: 'ME',
        RegAddrZip: 4101,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '273 CUMBERLAND AVE',
        RegAddrCity: 'PORTLAND',
        RegAddrState: 'ME',
        RegAddrZip: 4101,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '24 COBBERT RD',
        RegAddrCity: 'PORTLAND',
        RegAddrState: 'ME',
        RegAddrZip: 4102,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '74 NEAL ST',
        RegAddrCity: 'PORTLAND',
        RegAddrState: 'ME',
        RegAddrZip: 4102,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '15 FREDERIC ST',
        RegAddrCity: 'PORTLAND',
        RegAddrState: 'ME',
        RegAddrZip: 4102,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '30 GLENRIDGE',
        RegAddrCity: 'PORTLAND',
        RegAddrState: 'ME',
        RegAddrZip: 4102,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '17 JOSSLYN ST',
        RegAddrCity: 'PORTLAND',
        RegAddrState: 'ME',
        RegAddrZip: 4102,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '145 WOODFIELD RD',
        RegAddrCity: 'PORTLAND',
        RegAddrState: 'ME',
        RegAddrZip: 4102,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1761 FOREST AVE',
        RegAddrCity: 'PORTLAND',
        RegAddrState: 'ME',
        RegAddrZip: 4103,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '39 BACK COVE EST',
        RegAddrCity: 'PORTLAND',
        RegAddrState: 'ME',
        RegAddrZip: 4103,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '58 PRIMROSE LN',
        RegAddrCity: 'PORTLAND',
        RegAddrState: 'ME',
        RegAddrZip: 4103,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '11 WALL ST',
        RegAddrCity: 'PORTLAND',
        RegAddrState: 'ME',
        RegAddrZip: 4103,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '191 DELAWARE CT',
        RegAddrCity: 'PORTLAND',
        RegAddrState: 'ME',
        RegAddrZip: 4103,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '121 RIVERTON DR',
        RegAddrCity: 'PORTLAND',
        RegAddrState: 'ME',
        RegAddrZip: 4103,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '88 HERSEY ST',
        RegAddrCity: 'PORTLAND',
        RegAddrState: 'ME',
        RegAddrZip: 4103,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '17 W LYNNE AVE',
        RegAddrCity: 'PORTLAND',
        RegAddrState: 'ME',
        RegAddrZip: 4103,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '22 EPPING ST',
        RegAddrCity: 'PORTLAND',
        RegAddrState: 'ME',
        RegAddrZip: 4103,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '19 FALMOUTH ST',
        RegAddrCity: 'PORTLAND',
        RegAddrState: 'ME',
        RegAddrZip: 4103,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '53 CLINTON ST',
        RegAddrCity: 'PORTLAND',
        RegAddrState: 'ME',
        RegAddrZip: 4103,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '48 WELLSTONE DR',
        RegAddrCity: 'PORTLAND',
        RegAddrState: 'ME',
        RegAddrZip: 4103,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '84 RUSTIC LN',
        RegAddrCity: 'PORTLAND',
        RegAddrState: 'ME',
        RegAddrZip: 4103,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '716 STEVENS AVE',
        RegAddrCity: 'PORTLAND',
        RegAddrState: 'ME',
        RegAddrZip: 4103,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '132 NOYES ST',
        RegAddrCity: 'PORTLAND',
        RegAddrState: 'ME',
        RegAddrZip: 4103,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1205 WASHINGTON AVE',
        RegAddrCity: 'PORTLAND',
        RegAddrState: 'ME',
        RegAddrZip: 4103,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '641 ALLEN AVE',
        RegAddrCity: 'PORTLAND',
        RegAddrState: 'ME',
        RegAddrZip: 4103,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '134 SAUGUS ST',
        RegAddrCity: 'PORTLAND',
        RegAddrState: 'ME',
        RegAddrZip: 4103,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '71 LINCOLN ST',
        RegAddrCity: 'PORTLAND',
        RegAddrState: 'ME',
        RegAddrZip: 4103,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '378 RAY ST',
        RegAddrCity: 'PORTLAND',
        RegAddrState: 'ME',
        RegAddrZip: 4103,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '30 W LYNNE AVE',
        RegAddrCity: 'PORTLAND',
        RegAddrState: 'ME',
        RegAddrZip: 4103,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '81 ALLEN RD',
        RegAddrCity: 'POWNAL',
        RegAddrState: 'ME',
        RegAddrZip: 4069,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '211 ELMWOOD RD',
        RegAddrCity: 'POWNAL',
        RegAddrState: 'ME',
        RegAddrZip: 4069,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '4 BROWN RD',
        RegAddrCity: 'RAYMOND',
        RegAddrState: 'ME',
        RegAddrZip: 4071,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '4 GLEN RD',
        RegAddrCity: 'RAYMOND',
        RegAddrState: 'ME',
        RegAddrZip: 4071,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '74 MOUNTAIN RD',
        RegAddrCity: 'RAYMOND',
        RegAddrState: 'ME',
        RegAddrZip: 4071,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '103 BALSAM DR',
        RegAddrCity: 'READFIELD',
        RegAddrState: 'ME',
        RegAddrZip: 4355,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '151 PLEASANT ST',
        RegAddrCity: 'RICHMOND',
        RegAddrState: 'ME',
        RegAddrZip: 4357,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '51 SAMOSET RD',
        RegAddrCity: 'ROCKLAND',
        RegAddrState: 'ME',
        RegAddrZip: 4841,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '38 N MAIN ST',
        RegAddrCity: 'ROCKLAND',
        RegAddrState: 'ME',
        RegAddrZip: 4841,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '108 WALDO AVE',
        RegAddrCity: 'ROCKLAND',
        RegAddrState: 'ME',
        RegAddrZip: 4841,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '74 CEDAR ST',
        RegAddrCity: 'ROCKLAND',
        RegAddrState: 'ME',
        RegAddrZip: 4841,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '150 RUSSELL AVE',
        RegAddrCity: 'ROCKPORT',
        RegAddrState: 'ME',
        RegAddrZip: 4856,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '895 STATE ROUTE 32',
        RegAddrCity: 'ROUND POND',
        RegAddrState: 'ME',
        RegAddrZip: 4564,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '789 HIGHLAND AVE',
        RegAddrCity: 'S PORTLAND',
        RegAddrState: 'ME',
        RegAddrZip: 4106,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2 DEAKE ST',
        RegAddrCity: 'S PORTLAND',
        RegAddrState: 'ME',
        RegAddrZip: 4106,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '363 HIGHLAND AVE',
        RegAddrCity: 'S PORTLAND',
        RegAddrState: 'ME',
        RegAddrZip: 4106,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '54 MACARTHUR CIR W',
        RegAddrCity: 'S PORTLAND',
        RegAddrState: 'ME',
        RegAddrZip: 4106,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '12 ALLEN RD',
        RegAddrCity: 'S PORTLAND',
        RegAddrState: 'ME',
        RegAddrZip: 4106,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '25 WAINWRIGHT CIR E',
        RegAddrCity: 'S PORTLAND',
        RegAddrState: 'ME',
        RegAddrZip: 4106,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '44 PLEASANT BEACH RD',
        RegAddrCity: 'S THOMASTON',
        RegAddrState: 'ME',
        RegAddrZip: 4858,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '6 HORSE FARM RD',
        RegAddrCity: 'S THOMASTON',
        RegAddrState: 'ME',
        RegAddrZip: 4858,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '33 ALSAK FARM RD',
        RegAddrCity: 'S THOMASTON',
        RegAddrState: 'ME',
        RegAddrZip: 4858,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '9 WHARF ST',
        RegAddrCity: 'SACO',
        RegAddrState: 'ME',
        RegAddrZip: 4072,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '25 WATER ST',
        RegAddrCity: 'SACO',
        RegAddrState: 'ME',
        RegAddrZip: 4072,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '64 NEW COUNTY RD',
        RegAddrCity: 'SACO',
        RegAddrState: 'ME',
        RegAddrZip: 4072,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '13 WOOD AVE',
        RegAddrCity: 'SACO',
        RegAddrState: 'ME',
        RegAddrZip: 4072,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '7 FAIRHAVEN AVE',
        RegAddrCity: 'SACO',
        RegAddrState: 'ME',
        RegAddrZip: 4072,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '3 MOUNTAIN VIEW RD',
        RegAddrCity: 'SACO',
        RegAddrState: 'ME',
        RegAddrZip: 4072,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '501 BUXTON RD',
        RegAddrCity: 'SACO',
        RegAddrState: 'ME',
        RegAddrZip: 4072,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '29 RICHARDS WAY',
        RegAddrCity: 'SACO',
        RegAddrState: 'ME',
        RegAddrZip: 4072,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '189 JENKINS RD',
        RegAddrCity: 'SACO',
        RegAddrState: 'ME',
        RegAddrZip: 4072,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '10 HAPPY DIRT RD',
        RegAddrCity: 'SAINT GEORGE',
        RegAddrState: 'ME',
        RegAddrZip: 4860,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '43 BATEMAN ST',
        RegAddrCity: 'SANFORD',
        RegAddrState: 'ME',
        RegAddrZip: 4073,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '31 LEBANON ST',
        RegAddrCity: 'SANFORD',
        RegAddrState: 'ME',
        RegAddrZip: 4073,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '60A SCHOOL ST',
        RegAddrCity: 'SANFORD',
        RegAddrState: 'ME',
        RegAddrZip: 4073,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '72 PIONEER AVE',
        RegAddrCity: 'SANFORD',
        RegAddrState: 'ME',
        RegAddrZip: 4073,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '3 ST LOUIS ST',
        RegAddrCity: 'SANFORD',
        RegAddrState: 'ME',
        RegAddrZip: 4073,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1B AUGER ST',
        RegAddrCity: 'SANFORD',
        RegAddrState: 'ME',
        RegAddrZip: 4073,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '11 BENNETT ST',
        RegAddrCity: 'SANFORD',
        RegAddrState: 'ME',
        RegAddrZip: 4073,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '498 LEBANON ST',
        RegAddrCity: 'SANFORD',
        RegAddrState: 'ME',
        RegAddrZip: 4073,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '3 DOLLYS WAY',
        RegAddrCity: 'SCARBOROUGH',
        RegAddrState: 'ME',
        RegAddrZip: 4074,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '168 HOLMES RD',
        RegAddrCity: 'SCARBOROUGH',
        RegAddrState: 'ME',
        RegAddrZip: 4074,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '28 WHISTLERS LNDG',
        RegAddrCity: 'SCARBOROUGH',
        RegAddrState: 'ME',
        RegAddrZip: 4074,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '6 HERBERT DR',
        RegAddrCity: 'SCARBOROUGH',
        RegAddrState: 'ME',
        RegAddrZip: 4074,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '9 JONES CREEK DR',
        RegAddrCity: 'SCARBOROUGH',
        RegAddrState: 'ME',
        RegAddrZip: 4074,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '249 BEECH RIDGE RD',
        RegAddrCity: 'SCARBOROUGH',
        RegAddrState: 'ME',
        RegAddrZip: 4074,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '52 JASPER ST',
        RegAddrCity: 'SCARBOROUGH',
        RegAddrState: 'ME',
        RegAddrZip: 4074,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '20 TEAL POINT DR',
        RegAddrCity: 'SCARBOROUGH',
        RegAddrState: 'ME',
        RegAddrZip: 4074,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '354 PAYNE RD',
        RegAddrCity: 'SCARBOROUGH',
        RegAddrState: 'ME',
        RegAddrZip: 4074,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '23 OLD NECK RD',
        RegAddrCity: 'SCARBOROUGH',
        RegAddrState: 'ME',
        RegAddrZip: 4074,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '9 FAIRFIELD RD',
        RegAddrCity: 'SCARBOROUGH',
        RegAddrState: 'ME',
        RegAddrZip: 4074,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '8 NONESUCH COVE RD',
        RegAddrCity: 'SCARBOROUGH',
        RegAddrState: 'ME',
        RegAddrZip: 4074,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '320 SEBAGO RD',
        RegAddrCity: 'SEBAGO',
        RegAddrState: 'ME',
        RegAddrZip: 4029,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '58 EARLS RD',
        RegAddrCity: 'SOUTH BERWICK',
        RegAddrState: 'ME',
        RegAddrZip: 3908,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '53RD FIREROAD',
        RegAddrCity: 'SOUTH CHINA',
        RegAddrState: 'ME',
        RegAddrZip: 4358,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '79 S RICHLAND ST',
        RegAddrCity: 'SOUTH PORTLAND',
        RegAddrState: 'ME',
        RegAddrZip: 4106,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '41 ELIOT ST',
        RegAddrCity: 'SOUTH PORTLAND',
        RegAddrState: 'ME',
        RegAddrZip: 4106,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '103 COACH RD',
        RegAddrCity: 'SOUTH PORTLAND',
        RegAddrState: 'ME',
        RegAddrZip: 4106,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '22 RANKIN ST',
        RegAddrCity: 'SPRINGVALE',
        RegAddrState: 'ME',
        RegAddrZip: 4083,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '21 COUNTRY LN',
        RegAddrCity: 'SPRINGVALE',
        RegAddrState: 'ME',
        RegAddrZip: 4083,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '214 HEARTHSIDE RD',
        RegAddrCity: 'STANDISH',
        RegAddrState: 'ME',
        RegAddrZip: 4084,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '278 WHITES BRIDGE RD',
        RegAddrCity: 'STANDISH',
        RegAddrState: 'ME',
        RegAddrZip: 4084,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '29 ELM ST',
        RegAddrCity: 'TOPSHAM',
        RegAddrState: 'ME',
        RegAddrZip: 4086,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '21B RIVER RD',
        RegAddrCity: 'TOPSHAM',
        RegAddrState: 'ME',
        RegAddrZip: 4086,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '7 HILL DR',
        RegAddrCity: 'TOPSHAM',
        RegAddrState: 'ME',
        RegAddrZip: 4086,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '174 TEDFORD RD',
        RegAddrCity: 'TOPSHAM',
        RegAddrState: 'ME',
        RegAddrZip: 4086,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '255 SEAWARD MILLS RD',
        RegAddrCity: 'VASSALBORO',
        RegAddrState: 'ME',
        RegAddrZip: 4989,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '155 DARK HARBOUR RD',
        RegAddrCity: 'VASSALBORO',
        RegAddrState: 'ME',
        RegAddrZip: 4989,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1920 FRIENDSHIP RD',
        RegAddrCity: 'WALDOBORO',
        RegAddrState: 'ME',
        RegAddrZip: 4572,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '28 PITCHER RD',
        RegAddrCity: 'WALDOBORO',
        RegAddrState: 'ME',
        RegAddrZip: 4572,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '362 RALPH WINK RD',
        RegAddrCity: 'WALDOBORO',
        RegAddrState: 'ME',
        RegAddrZip: 4572,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '594 DUCK PUDDLE RD',
        RegAddrCity: 'WALDOBORO',
        RegAddrState: 'ME',
        RegAddrZip: 4572,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1759 FRIENDSHIP RD',
        RegAddrCity: 'WALDOBORO',
        RegAddrState: 'ME',
        RegAddrZip: 4572,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '190 POWDER MILL RD',
        RegAddrCity: 'WARREN',
        RegAddrState: 'ME',
        RegAddrZip: 4864,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '450 CARROLL RD',
        RegAddrCity: 'WARREN',
        RegAddrState: 'ME',
        RegAddrZip: 4864,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '705 OLD AUGUSTA RD',
        RegAddrCity: 'WARREN',
        RegAddrState: 'ME',
        RegAddrZip: 4864,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '756 MAIN ST',
        RegAddrCity: 'WATERBORO',
        RegAddrState: 'ME',
        RegAddrZip: 4087,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '14 WOODLAND DR',
        RegAddrCity: 'WATERBORO',
        RegAddrState: 'ME',
        RegAddrZip: 4087,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: 'BRACKETT LN',
        RegAddrCity: 'WATERBORO',
        RegAddrState: 'ME',
        RegAddrZip: 4087,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '23 ARCADIA LN',
        RegAddrCity: 'WATERBORO',
        RegAddrState: 'ME',
        RegAddrZip: 4087,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1 COUNTRY WAY',
        RegAddrCity: 'WATERVILLE',
        RegAddrState: 'ME',
        RegAddrZip: 4901,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '25 WINTER ST',
        RegAddrCity: 'WATERVILLE',
        RegAddrState: 'ME',
        RegAddrZip: 4901,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '5 NICOLE DR',
        RegAddrCity: 'WATERVILLE',
        RegAddrState: 'ME',
        RegAddrZip: 4901,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '5 GREENWOOD ST',
        RegAddrCity: 'WATERVILLE',
        RegAddrState: 'ME',
        RegAddrZip: 4901,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '147 W RIVER RD',
        RegAddrCity: 'WATERVILLE',
        RegAddrState: 'ME',
        RegAddrZip: 4901,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '7510 MAYFLOWER HL',
        RegAddrCity: 'WATERVILLE',
        RegAddrState: 'ME',
        RegAddrZip: 4901,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1 PURINGTON ST',
        RegAddrCity: 'WATERVILLE',
        RegAddrState: 'ME',
        RegAddrZip: 4901,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '101 SILVER ST',
        RegAddrCity: 'WATERVILLE',
        RegAddrState: 'ME',
        RegAddrZip: 4901,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '8221 MAYFLOWER HL',
        RegAddrCity: 'WATERVILLE',
        RegAddrState: 'ME',
        RegAddrZip: 4901,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '3 WEBHANNET DR',
        RegAddrCity: 'WELLS',
        RegAddrState: 'ME',
        RegAddrZip: 4090,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1649 LITTLEFIELD RD',
        RegAddrCity: 'WELLS',
        RegAddrState: 'ME',
        RegAddrZip: 4090,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '175 PENNYMEADOW WALK',
        RegAddrCity: 'WELLS',
        RegAddrState: 'ME',
        RegAddrZip: 4090,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1035 LITTLEFIELD RD',
        RegAddrCity: 'WELLS',
        RegAddrState: 'ME',
        RegAddrZip: 4090,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '70 CANTERBURY RD',
        RegAddrCity: 'WELLS',
        RegAddrState: 'ME',
        RegAddrZip: 4090,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '173 RABBIT RUN',
        RegAddrCity: 'WELLS',
        RegAddrState: 'ME',
        RegAddrZip: 4090,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '58 DAM COVE RD',
        RegAddrCity: 'WEST BATH',
        RegAddrState: 'ME',
        RegAddrZip: 4530,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '749 MAPLEWOOD RD',
        RegAddrCity: 'WEST NEWFIELD',
        RegAddrState: 'ME',
        RegAddrZip: 4095,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '96 LOST MILE RD',
        RegAddrCity: 'WEST NEWFIELD',
        RegAddrState: 'ME',
        RegAddrZip: 4095,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '138 LOST MILE RD',
        RegAddrCity: 'WEST NEWFIELD',
        RegAddrState: 'ME',
        RegAddrZip: 4095,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '6 VILLAGE LN',
        RegAddrCity: 'WESTBROOK',
        RegAddrState: 'ME',
        RegAddrZip: 4092,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '7 VIRGINIA ST',
        RegAddrCity: 'WESTBROOK',
        RegAddrState: 'ME',
        RegAddrZip: 4092,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '36 BERKELEY ST',
        RegAddrCity: 'WESTBROOK',
        RegAddrState: 'ME',
        RegAddrZip: 4092,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '49 MITCHELL ST',
        RegAddrCity: 'WESTBROOK',
        RegAddrState: 'ME',
        RegAddrZip: 4092,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '229 CENTRAL ST',
        RegAddrCity: 'WESTBROOK',
        RegAddrState: 'ME',
        RegAddrZip: 4092,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '136 BROWN ST',
        RegAddrCity: 'WESTBROOK',
        RegAddrState: 'ME',
        RegAddrZip: 4092,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '31 WILDWOOD CIR',
        RegAddrCity: 'WESTBROOK',
        RegAddrState: 'ME',
        RegAddrZip: 4092,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '19 EVERETT ST',
        RegAddrCity: 'WESTBROOK',
        RegAddrState: 'ME',
        RegAddrZip: 4092,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '44 RICHARDS ST',
        RegAddrCity: 'WESTBROOK',
        RegAddrState: 'ME',
        RegAddrZip: 4092,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '21 TREESIDE DR',
        RegAddrCity: 'WESTPORT IS',
        RegAddrState: 'ME',
        RegAddrZip: 4578,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '27 DOGGETT RD',
        RegAddrCity: 'WESTPORT IS',
        RegAddrState: 'ME',
        RegAddrZip: 4578,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '428 TOWNHOUSE RD',
        RegAddrCity: 'WHITEFIELD',
        RegAddrState: 'ME',
        RegAddrZip: 4353,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '14 SERENITY LN',
        RegAddrCity: 'WHITEFIELD',
        RegAddrState: 'ME',
        RegAddrZip: 4353,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '15 BAXTER WOODS TRL',
        RegAddrCity: 'WINDHAM',
        RegAddrState: 'ME',
        RegAddrZip: 4062,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '61 FALL RIDGE RD',
        RegAddrCity: 'WINDHAM',
        RegAddrState: 'ME',
        RegAddrZip: 4062,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '49 ISLAND DR',
        RegAddrCity: 'WINDHAM',
        RegAddrState: 'ME',
        RegAddrZip: 4062,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '551 ROOSEVELT TRL',
        RegAddrCity: 'WINDHAM',
        RegAddrState: 'ME',
        RegAddrZip: 4062,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '90 LINNELL RD',
        RegAddrCity: 'WINDHAM',
        RegAddrState: 'ME',
        RegAddrZip: 4062,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '122 VARNEY MILL RD',
        RegAddrCity: 'WINDHAM',
        RegAddrState: 'ME',
        RegAddrZip: 4062,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '8 FOREST AVE',
        RegAddrCity: 'WINDHAM',
        RegAddrState: 'ME',
        RegAddrZip: 4062,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '20 CARMICHAEL LN',
        RegAddrCity: 'WINDHAM',
        RegAddrState: 'ME',
        RegAddrZip: 4062,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '3 DAMIEN DR',
        RegAddrCity: 'WINDHAM',
        RegAddrState: 'ME',
        RegAddrZip: 4062,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '187 LEGION PARK RD',
        RegAddrCity: 'WINDSOR',
        RegAddrState: 'ME',
        RegAddrZip: 4363,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '978 CHINA RD',
        RegAddrCity: 'WINSLOW',
        RegAddrState: 'ME',
        RegAddrZip: 4901,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1 SAM ST',
        RegAddrCity: 'WINSLOW',
        RegAddrState: 'ME',
        RegAddrZip: 4901,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '18 WHIPPLE ST',
        RegAddrCity: 'WINSLOW',
        RegAddrState: 'ME',
        RegAddrZip: 4901,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '4 CONE ST',
        RegAddrCity: 'WINSLOW',
        RegAddrState: 'ME',
        RegAddrZip: 4901,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1189 US ROUTE 202',
        RegAddrCity: 'WINTHROP',
        RegAddrState: 'ME',
        RegAddrZip: 4364,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '60 PLEASANT ST',
        RegAddrCity: 'WINTHROP',
        RegAddrState: 'ME',
        RegAddrZip: 4364,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '15 MOSSY OAKS DR',
        RegAddrCity: 'WISCASSET',
        RegAddrState: 'ME',
        RegAddrZip: 4578,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1505 MIDDLE RD',
        RegAddrCity: 'WOOLWICH',
        RegAddrState: 'ME',
        RegAddrZip: 4579,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '22 HOCKOMOCK RD',
        RegAddrCity: 'WOOLWICH',
        RegAddrState: 'ME',
        RegAddrZip: 4579,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '488 NORTH RD',
        RegAddrCity: 'YARMOUTH',
        RegAddrState: 'ME',
        RegAddrZip: 4096,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '41 WOODBURY ST',
        RegAddrCity: 'YARMOUTH',
        RegAddrState: 'ME',
        RegAddrZip: 4096,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1 RIVERBEND DR',
        RegAddrCity: 'YARMOUTH',
        RegAddrState: 'ME',
        RegAddrZip: 4096,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '99 ANDERSON AVE',
        RegAddrCity: 'YARMOUTH',
        RegAddrState: 'ME',
        RegAddrZip: 4096,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '222 ROGERS RD',
        RegAddrCity: 'YARMOUTH',
        RegAddrState: 'ME',
        RegAddrZip: 4096,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '24 RUMSEY RD',
        RegAddrCity: 'YORK',
        RegAddrState: 'ME',
        RegAddrZip: 3909,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '27 CARRIE LYNN LN',
        RegAddrCity: 'YORK',
        RegAddrState: 'ME',
        RegAddrZip: 3909,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '3 WITHAM WAY',
        RegAddrCity: 'YORK',
        RegAddrState: 'ME',
        RegAddrZip: 3909,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '7 DEACON DR',
        RegAddrCity: 'YORK',
        RegAddrState: 'ME',
        RegAddrZip: 3909,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '215 BEECH RIDGE RD',
        RegAddrCity: 'YORK',
        RegAddrState: 'ME',
        RegAddrZip: 3909,
        CongressionalDistrict: 1,
      },
    ],
  },
  {
    name: 'ME-2',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/ME-2?ref=gh-pages',
    state: 'ME',
    state_and_number: 'ME-2',
    zip: 3579,
    congressionalDistrict: 2,
    addressesInDistrict: [
      {
        RegAddrLine1: 'UNITY RD',
        RegAddrCity: 'ALBION',
        RegAddrState: 'ME',
        RegAddrZip: 4910,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '40 PINE TREE SHR',
        RegAddrCity: 'ALEXANDER',
        RegAddrState: 'ME',
        RegAddrZip: 4694,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '220 WALKER BROOK RD',
        RegAddrCity: 'ALLAGASH',
        RegAddrState: 'ME',
        RegAddrZip: 4774,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '15 PINE ST',
        RegAddrCity: 'ANSON',
        RegAddrState: 'ME',
        RegAddrZip: 4911,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '7 WILSON ST',
        RegAddrCity: 'ANSON',
        RegAddrState: 'ME',
        RegAddrZip: 4911,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '48 QUIMBY ST',
        RegAddrCity: 'ASHLAND',
        RegAddrState: 'ME',
        RegAddrZip: 4732,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '83 EXCHANGE ST',
        RegAddrCity: 'ASHLAND',
        RegAddrState: 'ME',
        RegAddrZip: 4732,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '360 RANGE RD',
        RegAddrCity: 'ATKINSON',
        RegAddrState: 'ME',
        RegAddrZip: 4426,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '130 DYER RD',
        RegAddrCity: 'ATKINSON',
        RegAddrState: 'ME',
        RegAddrZip: 4426,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '63 FIFTH ST',
        RegAddrCity: 'AUBURN',
        RegAddrState: 'ME',
        RegAddrZip: 4210,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '504 STEVENS MILL RD',
        RegAddrCity: 'AUBURN',
        RegAddrState: 'ME',
        RegAddrZip: 4210,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '104 CONANT AVE',
        RegAddrCity: 'AUBURN',
        RegAddrState: 'ME',
        RegAddrZip: 4210,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '25 CUSHMAN PL',
        RegAddrCity: 'AUBURN',
        RegAddrState: 'ME',
        RegAddrZip: 4210,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '27 ROCHELLE ST',
        RegAddrCity: 'AUBURN',
        RegAddrState: 'ME',
        RegAddrZip: 4210,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '73 RUSSELL AVE',
        RegAddrCity: 'AUBURN',
        RegAddrState: 'ME',
        RegAddrZip: 4210,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '14 BEACON AVE',
        RegAddrCity: 'AUBURN',
        RegAddrState: 'ME',
        RegAddrZip: 4210,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1250 POWNAL RD',
        RegAddrCity: 'AUBURN',
        RegAddrState: 'ME',
        RegAddrZip: 4210,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '122 WHITNEY ST',
        RegAddrCity: 'AUBURN',
        RegAddrState: 'ME',
        RegAddrZip: 4210,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '200 LANE RD',
        RegAddrCity: 'AUBURN',
        RegAddrState: 'ME',
        RegAddrZip: 4210,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '564 SUMMER ST',
        RegAddrCity: 'AUBURN',
        RegAddrState: 'ME',
        RegAddrZip: 4210,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '26 BOLSTER ST',
        RegAddrCity: 'AUBURN',
        RegAddrState: 'ME',
        RegAddrZip: 4210,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '128 COVE RD',
        RegAddrCity: 'AUBURN',
        RegAddrState: 'ME',
        RegAddrZip: 4210,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '102 FERN ST',
        RegAddrCity: 'BANGOR',
        RegAddrState: 'ME',
        RegAddrZip: 4401,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '66 THORNTON RD',
        RegAddrCity: 'BANGOR',
        RegAddrState: 'ME',
        RegAddrZip: 4401,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '312 UNION ST',
        RegAddrCity: 'BANGOR',
        RegAddrState: 'ME',
        RegAddrZip: 4401,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '246 MAPLE ST',
        RegAddrCity: 'BANGOR',
        RegAddrState: 'ME',
        RegAddrZip: 4401,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '266 ESSEX ST',
        RegAddrCity: 'BANGOR',
        RegAddrState: 'ME',
        RegAddrZip: 4401,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '93 DREW LN',
        RegAddrCity: 'BANGOR',
        RegAddrState: 'ME',
        RegAddrZip: 4401,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '62 FALVEY ST',
        RegAddrCity: 'BANGOR',
        RegAddrState: 'ME',
        RegAddrZip: 4401,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2126 OHIO ST',
        RegAddrCity: 'BANGOR',
        RegAddrState: 'ME',
        RegAddrZip: 4401,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '27 MARCH ST',
        RegAddrCity: 'BANGOR',
        RegAddrState: 'ME',
        RegAddrZip: 4401,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '10 BLACKSTONE ST',
        RegAddrCity: 'BANGOR',
        RegAddrState: 'ME',
        RegAddrZip: 4401,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '41 2ND ST',
        RegAddrCity: 'BANGOR',
        RegAddrState: 'ME',
        RegAddrZip: 4401,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '36 CORTLAND CIR',
        RegAddrCity: 'BANGOR',
        RegAddrState: 'ME',
        RegAddrZip: 4401,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '61 EAGLE LAKE RD',
        RegAddrCity: 'BAR HARBOR',
        RegAddrState: 'ME',
        RegAddrZip: 4609,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '23 HIGHBROOK RD',
        RegAddrCity: 'BAR HARBOR',
        RegAddrState: 'ME',
        RegAddrZip: 4609,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '41 FRANKLIN ST',
        RegAddrCity: 'BELFAST',
        RegAddrState: 'ME',
        RegAddrZip: 4915,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '44 OAKWOOD DR',
        RegAddrCity: 'BELGRADE',
        RegAddrState: 'ME',
        RegAddrZip: 4917,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '121 HATCH COVE RD',
        RegAddrCity: 'BELGRADE',
        RegAddrState: 'ME',
        RegAddrZip: 4917,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '698 UNITY RD',
        RegAddrCity: 'BENTON',
        RegAddrState: 'ME',
        RegAddrZip: 4901,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '168 KIMBALL HILL RD',
        RegAddrCity: 'BETHEL',
        RegAddrState: 'ME',
        RegAddrZip: 4217,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '25 MYRICK RD',
        RegAddrCity: 'BIRCH HARBOR',
        RegAddrState: 'ME',
        RegAddrZip: 4613,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '33 MILITARY RD',
        RegAddrCity: 'BLAINE',
        RegAddrState: 'ME',
        RegAddrZip: 4734,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1 WHARF RD',
        RegAddrCity: 'BLUE HILL',
        RegAddrState: 'ME',
        RegAddrZip: 4614,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '40 MAIN ST',
        RegAddrCity: 'BLUE HILL',
        RegAddrState: 'ME',
        RegAddrZip: 4614,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '39 TURKEY FARM RD',
        RegAddrCity: 'BLUE HILL',
        RegAddrState: 'ME',
        RegAddrZip: 4614,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '81 ELLSWORTH RD',
        RegAddrCity: 'BLUE HILL',
        RegAddrState: 'ME',
        RegAddrZip: 4614,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '60 MAIN RD',
        RegAddrCity: 'BRADFORD',
        RegAddrState: 'ME',
        RegAddrZip: 4410,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '57 STATION RD',
        RegAddrCity: 'BRADFORD',
        RegAddrState: 'ME',
        RegAddrZip: 4410,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '134 TEN RD',
        RegAddrCity: 'BRADLEY',
        RegAddrState: 'ME',
        RegAddrZip: 4411,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '212 STATE ST',
        RegAddrCity: 'BREWER',
        RegAddrState: 'ME',
        RegAddrZip: 4412,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '27 HOLYOKE ST',
        RegAddrCity: 'BREWER',
        RegAddrState: 'ME',
        RegAddrZip: 4412,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '47 CLOVER LN',
        RegAddrCity: 'BREWER',
        RegAddrState: 'ME',
        RegAddrZip: 4412,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '88 MONTIETH RD',
        RegAddrCity: 'BRIDGEWATER',
        RegAddrState: 'ME',
        RegAddrZip: 4735,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '333 SAM BROWN HILL RD',
        RegAddrCity: 'BROWNFIELD',
        RegAddrState: 'ME',
        RegAddrZip: 4010,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '445 N HILL RD',
        RegAddrCity: 'BUCKFIELD',
        RegAddrState: 'ME',
        RegAddrZip: 4220,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '61 US ROUTE 1',
        RegAddrCity: 'BUCKSPORT',
        RegAddrState: 'ME',
        RegAddrZip: 4416,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '376 DUCK COVE RD',
        RegAddrCity: 'BUCKSPORT',
        RegAddrState: 'ME',
        RegAddrZip: 4416,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '45 ROBIN HOOD CIR',
        RegAddrCity: 'BUCKSPORT',
        RegAddrState: 'ME',
        RegAddrZip: 4416,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '3 COOKSON RD',
        RegAddrCity: 'BURNHAM',
        RegAddrState: 'ME',
        RegAddrZip: 4922,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '16 STILLSON ST',
        RegAddrCity: 'CALAIS',
        RegAddrState: 'ME',
        RegAddrZip: 4619,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '7 ODEY LN',
        RegAddrCity: 'CALAIS',
        RegAddrState: 'ME',
        RegAddrZip: 4619,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '195 RIVER RD',
        RegAddrCity: 'CALAIS',
        RegAddrState: 'ME',
        RegAddrZip: 4619,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '219 SALISBURY RD',
        RegAddrCity: 'CANAAN',
        RegAddrState: 'ME',
        RegAddrZip: 4924,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '4 TOBEY RD',
        RegAddrCity: 'CANAAN',
        RegAddrState: 'ME',
        RegAddrZip: 4924,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: 'MAHONEY',
        RegAddrCity: 'CANTON',
        RegAddrState: 'ME',
        RegAddrZip: 4221,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '80 WATSON MEMORIAL DR',
        RegAddrCity: 'CARIBOU',
        RegAddrState: 'ME',
        RegAddrZip: 4736,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '70 SPRING ST',
        RegAddrCity: 'CARIBOU',
        RegAddrState: 'ME',
        RegAddrZip: 4736,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '101 OLD WASHBURN RD',
        RegAddrCity: 'CARIBOU',
        RegAddrState: 'ME',
        RegAddrZip: 4736,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1058 VAN BUREN RD',
        RegAddrCity: 'CARIBOU',
        RegAddrState: 'ME',
        RegAddrZip: 4736,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '22 LYNN DR',
        RegAddrCity: 'CARIBOU',
        RegAddrState: 'ME',
        RegAddrZip: 4736,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1212 MAIN RD',
        RegAddrCity: 'CARMEL',
        RegAddrState: 'ME',
        RegAddrZip: 4419,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '57 GARLAND RD',
        RegAddrCity: 'CARMEL',
        RegAddrState: 'ME',
        RegAddrZip: 4419,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '3 EAGLE LN',
        RegAddrCity: 'CHARLOTTE',
        RegAddrState: 'ME',
        RegAddrZip: 4666,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '18 BARBER LN',
        RegAddrCity: 'CHERRYFIELD',
        RegAddrState: 'ME',
        RegAddrZip: 4622,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '75 MILBRIDGE RD',
        RegAddrCity: 'CHERRYFIELD',
        RegAddrState: 'ME',
        RegAddrZip: 4622,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '174 VIENNA RD',
        RegAddrCity: 'CHESTERVILLE',
        RegAddrState: 'ME',
        RegAddrZip: 4938,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '84 GOODRICH RD',
        RegAddrCity: 'CLINTON',
        RegAddrState: 'ME',
        RegAddrZip: 4927,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1067 HILL RD',
        RegAddrCity: 'CLINTON',
        RegAddrState: 'ME',
        RegAddrZip: 4927,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '14 WORTHEN RD',
        RegAddrCity: 'CORINNA',
        RegAddrState: 'ME',
        RegAddrZip: 4928,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '183 GREENBUSH RD',
        RegAddrCity: 'CORINNA',
        RegAddrState: 'ME',
        RegAddrZip: 4928,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '25 DEXTER RD',
        RegAddrCity: 'CORINNA',
        RegAddrState: 'ME',
        RegAddrZip: 4928,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '386 MCCARD RD',
        RegAddrCity: 'CORINTH',
        RegAddrState: 'ME',
        RegAddrZip: 4427,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '416 BEANS MILL RD',
        RegAddrCity: 'CORINTH',
        RegAddrState: 'ME',
        RegAddrZip: 4427,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '27 VILLAGE DR',
        RegAddrCity: 'CORINTH',
        RegAddrState: 'ME',
        RegAddrZip: 4427,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '137 THURSTON RD',
        RegAddrCity: 'CORNVILLE',
        RegAddrState: 'ME',
        RegAddrZip: 4976,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '3 STEAMBOAT RD',
        RegAddrCity: 'CUTLER',
        RegAddrState: 'ME',
        RegAddrZip: 4626,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '510 UPPER DEDHAM RD',
        RegAddrCity: 'DEDHAM',
        RegAddrState: 'ME',
        RegAddrZip: 4429,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '587 N DEER ISLE RD',
        RegAddrCity: 'DEER ISLE',
        RegAddrState: 'ME',
        RegAddrZip: 4627,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '38 CENTER DISTRICT XRD',
        RegAddrCity: 'DEER ISLE',
        RegAddrState: 'ME',
        RegAddrZip: 4627,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '628 BULL RING RD',
        RegAddrCity: 'DENMARK',
        RegAddrState: 'ME',
        RegAddrZip: 4022,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '6 HARDING LN',
        RegAddrCity: 'DIXFIELD',
        RegAddrState: 'ME',
        RegAddrZip: 4224,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '230 SIMPSON CORNER RD',
        RegAddrCity: 'DIXMONT',
        RegAddrState: 'ME',
        RegAddrZip: 4932,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '13 STATE PARK RD',
        RegAddrCity: 'DOVER FOXCROFT',
        RegAddrState: 'ME',
        RegAddrZip: 4426,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '36 LINCOLN ST',
        RegAddrCity: 'DOVR FOXCROFT',
        RegAddrState: 'ME',
        RegAddrZip: 4426,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '44 LARRABEE LN',
        RegAddrCity: 'DURHAM',
        RegAddrState: 'ME',
        RegAddrZip: 4222,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1471 DYER BROOK RD',
        RegAddrCity: 'DYER BROOK',
        RegAddrState: 'ME',
        RegAddrZip: 4747,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '7 MAPLE ST',
        RegAddrCity: 'E MILLINOCKET',
        RegAddrState: 'ME',
        RegAddrZip: 4430,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '36 CEDAR ST',
        RegAddrCity: 'E MILLINOCKET',
        RegAddrState: 'ME',
        RegAddrZip: 4430,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '23 ORCHARD ST',
        RegAddrCity: 'E MILLINOCKET',
        RegAddrState: 'ME',
        RegAddrZip: 4430,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '24 MEANS COTTAGE RD',
        RegAddrCity: 'EAST MACHIAS',
        RegAddrState: 'ME',
        RegAddrZip: 4630,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '7 COUNTY ST',
        RegAddrCity: 'EAST MILLINOCKET',
        RegAddrState: 'ME',
        RegAddrZip: 4430,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '74 COUNTY RD',
        RegAddrCity: 'EASTPORT',
        RegAddrState: 'ME',
        RegAddrZip: 4631,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '374 CHRISTIAN RIDGE RD',
        RegAddrCity: 'ELLSWORTH',
        RegAddrState: 'ME',
        RegAddrZip: 4605,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '74 DODLIN RD',
        RegAddrCity: 'ENFIELD',
        RegAddrState: 'ME',
        RegAddrZip: 4493,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '23 CRANE DR',
        RegAddrCity: 'FAIRFIELD',
        RegAddrState: 'ME',
        RegAddrZip: 4937,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '282 MARTIN STREAM RD',
        RegAddrCity: 'FAIRFIELD',
        RegAddrState: 'ME',
        RegAddrZip: 4937,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '20 WILLOW ST',
        RegAddrCity: 'FAIRFIELD',
        RegAddrState: 'ME',
        RegAddrZip: 4937,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '125 BRIER LN',
        RegAddrCity: 'FARMINGTON',
        RegAddrState: 'ME',
        RegAddrZip: 4938,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '117 PERKINS ST',
        RegAddrCity: 'FARMINGTON',
        RegAddrState: 'ME',
        RegAddrZip: 4938,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '119 PLEASANT ST',
        RegAddrCity: 'FARMINGTON',
        RegAddrState: 'ME',
        RegAddrZip: 4938,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '300 WATSON HEIGHTS RD',
        RegAddrCity: 'FAYETTE',
        RegAddrState: 'ME',
        RegAddrZip: 4349,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '93 NORTH RD',
        RegAddrCity: 'FAYETTE',
        RegAddrState: 'ME',
        RegAddrZip: 4349,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '603 S PERLEY BROOK RD',
        RegAddrCity: 'FORT KENT',
        RegAddrState: 'ME',
        RegAddrZip: 4743,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '151 N SEARSPORT RD',
        RegAddrCity: 'FRANKFORT',
        RegAddrState: 'ME',
        RegAddrZip: 4438,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '244 GEORGES POND RD',
        RegAddrCity: 'FRANKLIN',
        RegAddrState: 'ME',
        RegAddrZip: 4634,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '76 TOWNHOUSE RD',
        RegAddrCity: 'FREEDOM',
        RegAddrState: 'ME',
        RegAddrZip: 4941,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '470 BAKER HILL RD',
        RegAddrCity: 'FREEMAN TWP',
        RegAddrState: 'ME',
        RegAddrZip: 4983,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '124 LOVEWELL POND RD',
        RegAddrCity: 'FRYEBURG',
        RegAddrState: 'ME',
        RegAddrZip: 4037,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '191 WEEKS RD',
        RegAddrCity: 'GARDINER',
        RegAddrState: 'ME',
        RegAddrZip: 4345,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '57 SCHOOL ST',
        RegAddrCity: 'GARDINER',
        RegAddrState: 'ME',
        RegAddrZip: 4345,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '990 OLIVER HILL RD',
        RegAddrCity: 'GARLAND',
        RegAddrState: 'ME',
        RegAddrZip: 4939,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '68 MISTY WAY',
        RegAddrCity: 'GLENBURN',
        RegAddrState: 'ME',
        RegAddrZip: 4401,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '22 MERRYMAN RD',
        RegAddrCity: 'GLENBURN',
        RegAddrState: 'ME',
        RegAddrZip: 4401,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '979 HUDSON RD',
        RegAddrCity: 'GLENBURN',
        RegAddrState: 'ME',
        RegAddrZip: 4401,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1208 ROUTE 1',
        RegAddrCity: 'GOULDSBORO',
        RegAddrState: 'ME',
        RegAddrZip: 4607,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '133 MAIN ST',
        RegAddrCity: 'GRAND ISLE',
        RegAddrState: 'ME',
        RegAddrZip: 4746,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '156 CARDVILLE RD',
        RegAddrCity: 'GREENBUSH',
        RegAddrState: 'ME',
        RegAddrZip: 4418,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '101 TODD RD',
        RegAddrCity: 'GREENE',
        RegAddrState: 'ME',
        RegAddrZip: 4236,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '999998 MISSING ADDRESS',
        RegAddrCity: 'GREENE',
        RegAddrState: 'ME',
        RegAddrZip: 4236,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '224 MEADOW HILL RD',
        RegAddrCity: 'GREENE',
        RegAddrState: 'ME',
        RegAddrZip: 4236,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '12 DAGGETT HILL RD',
        RegAddrCity: 'GREENE',
        RegAddrState: 'ME',
        RegAddrZip: 4236,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '10 LAWRENCE AVE',
        RegAddrCity: 'GREENVILLE',
        RegAddrState: 'ME',
        RegAddrZip: 4441,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '26 MURRAY ST',
        RegAddrCity: 'GREENVILLE',
        RegAddrState: 'ME',
        RegAddrZip: 4441,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '301 WHARFF RD',
        RegAddrCity: 'GUILFORD',
        RegAddrState: 'ME',
        RegAddrZip: 4443,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '12 ELLIS AVE',
        RegAddrCity: 'GUILFORD',
        RegAddrState: 'ME',
        RegAddrZip: 4443,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '63 PATTERSON RD',
        RegAddrCity: 'HAMPDEN',
        RegAddrState: 'ME',
        RegAddrZip: 4444,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '277 SHAW HILL RD',
        RegAddrCity: 'HAMPDEN',
        RegAddrState: 'ME',
        RegAddrZip: 4444,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '159 MAIN RD N',
        RegAddrCity: 'HAMPDEN',
        RegAddrState: 'ME',
        RegAddrZip: 4444,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '40 CHICKADEE LN',
        RegAddrCity: 'HAMPDEN',
        RegAddrState: 'ME',
        RegAddrZip: 4444,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '86 HUGHES BLVD',
        RegAddrCity: 'HAMPDEN',
        RegAddrState: 'ME',
        RegAddrZip: 4444,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '93 OLD ROUTE 1',
        RegAddrCity: 'HANCOCK',
        RegAddrState: 'ME',
        RegAddrZip: 4640,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '57 CHICKADEE DR',
        RegAddrCity: 'HARMONY',
        RegAddrState: 'ME',
        RegAddrZip: 4942,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: 'COMMERCIAL ST',
        RegAddrCity: 'HARTLAND',
        RegAddrState: 'ME',
        RegAddrZip: 4943,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '99 ANNIS RD',
        RegAddrCity: 'HERMON',
        RegAddrState: 'ME',
        RegAddrZip: 4401,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2244 ROUTE 2',
        RegAddrCity: 'HERMON',
        RegAddrState: 'ME',
        RegAddrZip: 4401,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '169 KLATTE RD',
        RegAddrCity: 'HERMON',
        RegAddrState: 'ME',
        RegAddrZip: 4401,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '177 RICHARDSON RD',
        RegAddrCity: 'HIRAM',
        RegAddrState: 'ME',
        RegAddrZip: 4041,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '246 CALAIS RD',
        RegAddrCity: 'HODGDON',
        RegAddrState: 'ME',
        RegAddrZip: 4730,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '868 LEVENSELLER RD',
        RegAddrCity: 'HOLDEN',
        RegAddrState: 'ME',
        RegAddrZip: 4429,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '570 MANN HILL RD',
        RegAddrCity: 'HOLDEN',
        RegAddrState: 'ME',
        RegAddrZip: 4429,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '75 MOOERS RD',
        RegAddrCity: 'HOULTON',
        RegAddrState: 'ME',
        RegAddrZip: 4730,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '6 DAVIS ST',
        RegAddrCity: 'HOWLAND',
        RegAddrState: 'ME',
        RegAddrZip: 4448,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '251 INDUSTRY RD',
        RegAddrCity: 'INDUSTRY',
        RegAddrState: 'ME',
        RegAddrZip: 4938,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '5 BUTTERFIELD RD',
        RegAddrCity: 'INDUSTRY',
        RegAddrState: 'ME',
        RegAddrZip: 4938,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2 MOOSE POINT RD',
        RegAddrCity: 'JACKMAN',
        RegAddrState: 'ME',
        RegAddrZip: 4945,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '47 PLEASANT DR',
        RegAddrCity: 'JAY',
        RegAddrState: 'ME',
        RegAddrZip: 4239,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '45 OSGOOD RD',
        RegAddrCity: 'JAY',
        RegAddrState: 'ME',
        RegAddrZip: 4239,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '90 W MAIN ST',
        RegAddrCity: 'JONESPORT',
        RegAddrState: 'ME',
        RegAddrZip: 4649,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1103 SHORE RD',
        RegAddrCity: 'LAMOINE',
        RegAddrState: 'ME',
        RegAddrZip: 4605,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '77 WEIR POND RD',
        RegAddrCity: 'LEE',
        RegAddrState: 'ME',
        RegAddrZip: 4455,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '60 COBB RD',
        RegAddrCity: 'LEE',
        RegAddrState: 'ME',
        RegAddrZip: 4455,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '49 ALDEN RD',
        RegAddrCity: 'LEEDS',
        RegAddrState: 'ME',
        RegAddrZip: 4263,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '86 FOREST HILLS DR',
        RegAddrCity: 'LEVANT',
        RegAddrState: 'ME',
        RegAddrZip: 4456,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '7 TUCKER ST',
        RegAddrCity: 'LEWISTON',
        RegAddrState: 'ME',
        RegAddrZip: 4240,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '99 RUSSELL ST',
        RegAddrCity: 'LEWISTON',
        RegAddrState: 'ME',
        RegAddrZip: 4240,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '44 NICHOLS ST',
        RegAddrCity: 'LEWISTON',
        RegAddrState: 'ME',
        RegAddrZip: 4240,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1 LEWIS LN',
        RegAddrCity: 'LEWISTON',
        RegAddrState: 'ME',
        RegAddrZip: 4240,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '81 MERRILL RD',
        RegAddrCity: 'LEWISTON',
        RegAddrState: 'ME',
        RegAddrZip: 4240,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '270 COLLEGE ST',
        RegAddrCity: 'LEWISTON',
        RegAddrState: 'ME',
        RegAddrZip: 4240,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '69 ALBERT ST',
        RegAddrCity: 'LEWISTON',
        RegAddrState: 'ME',
        RegAddrZip: 4240,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '36 THORNE AVE',
        RegAddrCity: 'LEWISTON',
        RegAddrState: 'ME',
        RegAddrZip: 4240,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '28 FRYE ST',
        RegAddrCity: 'LEWISTON',
        RegAddrState: 'ME',
        RegAddrZip: 4240,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '257 COLLEGE ST',
        RegAddrCity: 'LEWISTON',
        RegAddrState: 'ME',
        RegAddrZip: 4240,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '10 FRYE ST',
        RegAddrCity: 'LEWISTON',
        RegAddrState: 'ME',
        RegAddrZip: 4240,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '96 DUMONT AVE',
        RegAddrCity: 'LEWISTON',
        RegAddrState: 'ME',
        RegAddrZip: 4240,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '287 COLLEGE ST',
        RegAddrCity: 'LEWISTON',
        RegAddrState: 'ME',
        RegAddrZip: 4240,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '61 BARDWELL ST',
        RegAddrCity: 'LEWISTON',
        RegAddrState: 'ME',
        RegAddrZip: 4240,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1239 LONG FALLS DAM RD',
        RegAddrCity: 'LEXINGTON TWP',
        RegAddrState: 'ME',
        RegAddrZip: 4961,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '665 BELFAST AUGUSTA RD',
        RegAddrCity: 'LIBERTY',
        RegAddrState: 'ME',
        RegAddrZip: 4949,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '35 MONTGOMERY ST',
        RegAddrCity: 'LIMESTONE',
        RegAddrState: 'ME',
        RegAddrZip: 4750,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '26 SCHOOL AVE',
        RegAddrCity: 'LIMESTONE',
        RegAddrState: 'ME',
        RegAddrZip: 4750,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '36 MONTANA RD',
        RegAddrCity: 'LIMESTONE',
        RegAddrState: 'ME',
        RegAddrZip: 4750,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '534 W BROADWAY',
        RegAddrCity: 'LINCOLN',
        RegAddrState: 'ME',
        RegAddrZip: 4457,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '14 BLAKE DR',
        RegAddrCity: 'LINCOLN',
        RegAddrState: 'ME',
        RegAddrZip: 4457,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '8 BEDFORD FARM RD',
        RegAddrCity: 'LINCOLN',
        RegAddrState: 'ME',
        RegAddrZip: 4457,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '304 BEACH RD',
        RegAddrCity: 'LINCOLNVILLE',
        RegAddrState: 'ME',
        RegAddrZip: 4849,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '226 HOPE RD',
        RegAddrCity: 'LINCOLNVILLE',
        RegAddrState: 'ME',
        RegAddrZip: 4849,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '13 HENDERSON LN',
        RegAddrCity: 'LINNEUS',
        RegAddrState: 'ME',
        RegAddrZip: 4730,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '53 FERRY RD',
        RegAddrCity: 'LISBON',
        RegAddrState: 'ME',
        RegAddrZip: 4250,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '6 CHERYL DEE AVE',
        RegAddrCity: 'LISBON',
        RegAddrState: 'ME',
        RegAddrZip: 4250,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '83 RIDGE RD',
        RegAddrCity: 'LISBON',
        RegAddrState: 'ME',
        RegAddrZip: 4252,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '8 FREE ST',
        RegAddrCity: 'LISBON',
        RegAddrState: 'ME',
        RegAddrZip: 4252,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '11 BULICK DR',
        RegAddrCity: 'LISBON',
        RegAddrState: 'ME',
        RegAddrZip: 4252,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '42 GETCHELL LN',
        RegAddrCity: 'LITCHFIELD',
        RegAddrState: 'ME',
        RegAddrZip: 4350,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '11 OAK HILL RD',
        RegAddrCity: 'LITCHFIELD',
        RegAddrState: 'ME',
        RegAddrZip: 4350,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '47 HAYDEN HILL RD',
        RegAddrCity: 'LITCHFIELD',
        RegAddrState: 'ME',
        RegAddrZip: 4350,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '38 MILITARY LN',
        RegAddrCity: 'LITCHFIELD',
        RegAddrState: 'ME',
        RegAddrZip: 4350,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '127 STEVENSTOWN RD',
        RegAddrCity: 'LITCHFIELD',
        RegAddrState: 'ME',
        RegAddrZip: 4350,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '414 OAK HILL RD',
        RegAddrCity: 'LITCHFIELD',
        RegAddrState: 'ME',
        RegAddrZip: 4350,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '453 TURKEY LANE RD',
        RegAddrCity: 'LIVERMORE',
        RegAddrState: 'ME',
        RegAddrZip: 4253,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '40 TURMEL RD',
        RegAddrCity: 'LIVERMORE FALLS',
        RegAddrState: 'ME',
        RegAddrZip: 4254,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '24 MOOSE HILL RD',
        RegAddrCity: 'LIVERMORE FALLS',
        RegAddrState: 'ME',
        RegAddrZip: 4254,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '147 PINE RIDGE LOOP',
        RegAddrCity: 'LIVERMORE FALLS',
        RegAddrState: 'ME',
        RegAddrZip: 4254,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '93 KEZAR LAKE RD',
        RegAddrCity: 'LOVELL',
        RegAddrState: 'ME',
        RegAddrZip: 4051,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '286 W OLD MAIN RD',
        RegAddrCity: 'LOWELL',
        RegAddrState: 'ME',
        RegAddrZip: 4493,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '84 W KENNEBEC RD',
        RegAddrCity: 'MACHIAS',
        RegAddrState: 'ME',
        RegAddrZip: 4654,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '74 KENNEBEC RD',
        RegAddrCity: 'MACHIAS',
        RegAddrState: 'ME',
        RegAddrZip: 4654,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '749 GATEWAY ST',
        RegAddrCity: 'MADAWASKA',
        RegAddrState: 'ME',
        RegAddrZip: 4756,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: 'MAPLETON RD',
        RegAddrCity: 'MAPLETON',
        RegAddrState: 'ME',
        RegAddrZip: 4757,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '198 GRENDELL RD',
        RegAddrCity: 'MAPLETON',
        RegAddrState: 'ME',
        RegAddrZip: 4757,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '30 HATHAWAY RD',
        RegAddrCity: 'MATTAWAMKEAG',
        RegAddrState: 'ME',
        RegAddrZip: 4459,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '38 PINE ST',
        RegAddrCity: 'MECHANIC FLS',
        RegAddrState: 'ME',
        RegAddrZip: 4256,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '64 DECKER RD',
        RegAddrCity: 'MERCER',
        RegAddrState: 'ME',
        RegAddrZip: 4957,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '13 CHASE AVE',
        RegAddrCity: 'MEXICO',
        RegAddrState: 'ME',
        RegAddrZip: 4257,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '11 KELLEY RD',
        RegAddrCity: 'MILBRIDGE',
        RegAddrState: 'ME',
        RegAddrZip: 4658,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '6 BULLEN ST',
        RegAddrCity: 'MILFORD',
        RegAddrState: 'ME',
        RegAddrZip: 4461,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '171 MAIN RD',
        RegAddrCity: 'MILFORD',
        RegAddrState: 'ME',
        RegAddrZip: 4461,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '35 COUNTY RD',
        RegAddrCity: 'MILFORD',
        RegAddrState: 'ME',
        RegAddrZip: 4461,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '13 GOULD ST',
        RegAddrCity: 'MILO',
        RegAddrState: 'ME',
        RegAddrZip: 4463,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '168 RIVERSIDE ST',
        RegAddrCity: 'MILO',
        RegAddrState: 'ME',
        RegAddrZip: 4463,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '189 OLD WOODMAN HILL RD',
        RegAddrCity: 'MINOT',
        RegAddrState: 'ME',
        RegAddrZip: 4258,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '117 TRUES POND RD',
        RegAddrCity: 'MONTVILLE',
        RegAddrState: 'ME',
        RegAddrZip: 4941,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '11 KINGS PARK WAY',
        RegAddrCity: 'MOUNT DESERT',
        RegAddrState: 'ME',
        RegAddrZip: 4660,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '16 PINE LEDGE LN',
        RegAddrCity: 'MOUNT DESERT',
        RegAddrState: 'ME',
        RegAddrZip: 4660,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '568 WILSON POND RD',
        RegAddrCity: 'N MONMOUTH',
        RegAddrState: 'ME',
        RegAddrZip: 4265,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1761 COUNTY RD',
        RegAddrCity: 'NEW LIMERICK',
        RegAddrState: 'ME',
        RegAddrZip: 4761,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '14 COLEGROVE RD',
        RegAddrCity: 'NEW PORTLAND',
        RegAddrState: 'ME',
        RegAddrZip: 4961,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '155 WEEKS MILLS RD',
        RegAddrCity: 'NEW SHARON',
        RegAddrState: 'ME',
        RegAddrZip: 4955,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1 NADEAU LN',
        RegAddrCity: 'NEW SHARON',
        RegAddrState: 'ME',
        RegAddrZip: 4955,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1125 NEW VINEYARD RD',
        RegAddrCity: 'NEW VINEYARD',
        RegAddrState: 'ME',
        RegAddrZip: 4956,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '346 BEAR RIVER RD',
        RegAddrCity: 'NEWRY',
        RegAddrState: 'ME',
        RegAddrZip: 4261,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '13 FATHER RASLE RD',
        RegAddrCity: 'NORRIDGEWOCK',
        RegAddrState: 'ME',
        RegAddrZip: 4957,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '8 BEECH HILL RD',
        RegAddrCity: 'NORRIDGEWOCK',
        RegAddrState: 'ME',
        RegAddrZip: 4957,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '30 GEORGE ST',
        RegAddrCity: 'NORTHPORT',
        RegAddrState: 'ME',
        RegAddrZip: 4849,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1085 ATLANTIC HWY',
        RegAddrCity: 'NORTHPORT',
        RegAddrState: 'ME',
        RegAddrZip: 4849,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '54 SAUNDERS DR',
        RegAddrCity: 'NORWAY',
        RegAddrState: 'ME',
        RegAddrZip: 4268,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '40 PIKES HL',
        RegAddrCity: 'NORWAY',
        RegAddrState: 'ME',
        RegAddrZip: 4268,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '575 GREENWOOD RD',
        RegAddrCity: 'NORWAY',
        RegAddrState: 'ME',
        RegAddrZip: 4268,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '51 PIKES HL',
        RegAddrCity: 'NORWAY',
        RegAddrState: 'ME',
        RegAddrZip: 4268,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '141 MIDDLE RD',
        RegAddrCity: 'OAKLAND',
        RegAddrState: 'ME',
        RegAddrZip: 4963,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '301 OAK ST',
        RegAddrCity: 'OAKLAND',
        RegAddrState: 'ME',
        RegAddrZip: 4963,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '85 E SIDE TRL',
        RegAddrCity: 'OAKLAND',
        RegAddrState: 'ME',
        RegAddrZip: 4963,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '93 OAK HILL DR',
        RegAddrCity: 'OAKLAND',
        RegAddrState: 'ME',
        RegAddrZip: 4963,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '200 VEAZIE ST',
        RegAddrCity: 'OLD TOWN',
        RegAddrState: 'ME',
        RegAddrZip: 4468,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '451 STILLWATER AVE',
        RegAddrCity: 'OLD TOWN',
        RegAddrState: 'ME',
        RegAddrZip: 4468,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '45 SEWALL DR',
        RegAddrCity: 'OLD TOWN',
        RegAddrState: 'ME',
        RegAddrZip: 4468,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '89 BODWELL ST',
        RegAddrCity: 'OLD TOWN',
        RegAddrState: 'ME',
        RegAddrZip: 4468,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '64 MARTHAS WAY',
        RegAddrCity: 'OLD TOWN',
        RegAddrState: 'ME',
        RegAddrZip: 4468,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '262 BOUNDARY RD',
        RegAddrCity: 'ORIENT',
        RegAddrState: 'ME',
        RegAddrZip: 4471,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '796 CASTINE RD',
        RegAddrCity: 'ORLAND',
        RegAddrState: 'ME',
        RegAddrZip: 4472,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1 HUDSON ST THE AVE',
        RegAddrCity: 'ORONO',
        RegAddrState: 'ME',
        RegAddrZip: 4473,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '25 WINTERHAVEN DR',
        RegAddrCity: 'ORONO',
        RegAddrState: 'ME',
        RegAddrZip: 4473,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '43 SALSBURY RIDGE RD',
        RegAddrCity: 'ORRINGTON',
        RegAddrState: 'ME',
        RegAddrZip: 4474,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: 'PO BOX 133',
        RegAddrCity: 'ORRINGTON',
        RegAddrState: 'ME',
        RegAddrZip: 4474,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '980 OTIS RD',
        RegAddrCity: 'OTIS',
        RegAddrState: 'ME',
        RegAddrZip: 4605,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '135 GORE RD',
        RegAddrCity: 'OXFORD',
        RegAddrState: 'ME',
        RegAddrZip: 4270,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '31 LUNTS POINT LN',
        RegAddrCity: 'OXFORD',
        RegAddrState: 'ME',
        RegAddrZip: 4270,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '20 REPUBLICAN AVE',
        RegAddrCity: 'OXFORD',
        RegAddrState: 'ME',
        RegAddrZip: 4270,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '306 PARMENTER RD',
        RegAddrCity: 'PALERMO',
        RegAddrState: 'ME',
        RegAddrZip: 4354,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '398 ESTES AVE',
        RegAddrCity: 'PALMYRA',
        RegAddrState: 'ME',
        RegAddrZip: 4965,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '320 HIGH MEADOW RD',
        RegAddrCity: 'PERHAM',
        RegAddrState: 'ME',
        RegAddrZip: 4766,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '404 GIN COVE RD',
        RegAddrCity: 'PERRY',
        RegAddrState: 'ME',
        RegAddrZip: 4667,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '82 SPRING RD',
        RegAddrCity: 'PITTSFIELD',
        RegAddrState: 'ME',
        RegAddrZip: 4967,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '366 WEBB RD',
        RegAddrCity: 'PITTSFIELD',
        RegAddrState: 'ME',
        RegAddrZip: 4967,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '27 ORGANIC FARM RD',
        RegAddrCity: 'PITTSFIELD',
        RegAddrState: 'ME',
        RegAddrZip: 4967,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '308 BAKERSTOWN RD',
        RegAddrCity: 'POLAND',
        RegAddrState: 'ME',
        RegAddrZip: 4274,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '254 TIGER HILL RD',
        RegAddrCity: 'POLAND',
        RegAddrState: 'ME',
        RegAddrZip: 4274,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '222 DUNN RD',
        RegAddrCity: 'POLAND',
        RegAddrState: 'ME',
        RegAddrZip: 4274,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '21 SCHOOL ST',
        RegAddrCity: 'PORTER',
        RegAddrState: 'ME',
        RegAddrZip: 4068,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '417 HOULTON RD',
        RegAddrCity: 'PRESQUE ISLE',
        RegAddrState: 'ME',
        RegAddrZip: 4769,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '107B CENTERLINE RD',
        RegAddrCity: 'PRESQUE ISLE',
        RegAddrState: 'ME',
        RegAddrZip: 4769,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '108 LOMBARD ST',
        RegAddrCity: 'PRESQUE ISLE',
        RegAddrState: 'ME',
        RegAddrZip: 4769,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '60 LOMBARD ST',
        RegAddrCity: 'PRESQUE ISLE',
        RegAddrState: 'ME',
        RegAddrZip: 4769,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '22 PLEASANT ST',
        RegAddrCity: 'PRESQUE ISLE',
        RegAddrState: 'ME',
        RegAddrZip: 4769,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '398 GREEN VALLEY RD',
        RegAddrCity: 'PROSPECT',
        RegAddrState: 'ME',
        RegAddrZip: 4981,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '68 PARTRIDGE RD',
        RegAddrCity: 'PROSPECT',
        RegAddrState: 'ME',
        RegAddrZip: 4981,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '55 PLEASANT ST',
        RegAddrCity: 'RANGELEY',
        RegAddrState: 'ME',
        RegAddrZip: 4970,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '578 MAIN ST',
        RegAddrCity: 'REED PLT',
        RegAddrState: 'ME',
        RegAddrZip: 4497,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '30 US RTE 1',
        RegAddrCity: 'ROBBINSTON',
        RegAddrState: 'ME',
        RegAddrZip: 4671,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '3 LAKE ST',
        RegAddrCity: 'ROCKWOOD',
        RegAddrState: 'ME',
        RegAddrZip: 4478,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '34 AUGUSTA RD',
        RegAddrCity: 'ROME',
        RegAddrState: 'ME',
        RegAddrZip: 4963,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '320 CUMBERLAND ST',
        RegAddrCity: 'RUMFORD',
        RegAddrState: 'ME',
        RegAddrZip: 4276,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '57 WASHINGTON ST',
        RegAddrCity: 'RUMFORD',
        RegAddrState: 'ME',
        RegAddrZip: 4276,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1373 ROUTE 2',
        RegAddrCity: 'RUMFORD',
        RegAddrState: 'ME',
        RegAddrZip: 4276,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '412 SWAIN RD',
        RegAddrCity: 'RUMFORD',
        RegAddrState: 'ME',
        RegAddrZip: 4276,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '43 WHIPPOORWILL RD',
        RegAddrCity: 'RUMFORD',
        RegAddrState: 'ME',
        RegAddrZip: 4276,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '203 CORINNA RD',
        RegAddrCity: 'SAINT ALBANS',
        RegAddrState: 'ME',
        RegAddrZip: 4971,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: 'HIGHVIEW MNR',
        RegAddrCity: 'SAINT DAVID',
        RegAddrState: 'ME',
        RegAddrZip: 4773,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '3 SCHOOL ST',
        RegAddrCity: 'SANGERVILLE',
        RegAddrState: 'ME',
        RegAddrZip: 4479,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '53 LINE RD',
        RegAddrCity: 'SANGERVILLE',
        RegAddrState: 'ME',
        RegAddrZip: 4479,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '386 LINCOLNVILLE AVE',
        RegAddrCity: 'SEARSMONT',
        RegAddrState: 'ME',
        RegAddrZip: 4973,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '20 MOUNT EPHRAIM RD',
        RegAddrCity: 'SEARSPORT',
        RegAddrState: 'ME',
        RegAddrZip: 4974,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '79 BOWEN RD',
        RegAddrCity: 'SEARSPORT',
        RegAddrState: 'ME',
        RegAddrZip: 4974,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '4 CHURCH ST',
        RegAddrCity: 'SHERMAN',
        RegAddrState: 'ME',
        RegAddrZip: 4776,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2029 SUMMERHAVEN RD',
        RegAddrCity: 'SIDNEY',
        RegAddrState: 'ME',
        RegAddrZip: 4330,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '86 SINCLAIR RD',
        RegAddrCity: 'SINCLAIR',
        RegAddrState: 'ME',
        RegAddrZip: 4779,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '45 NORTH AVE',
        RegAddrCity: 'SKOWHEGAN',
        RegAddrState: 'ME',
        RegAddrZip: 4976,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '430 CANAAN RD',
        RegAddrCity: 'SKOWHEGAN',
        RegAddrState: 'ME',
        RegAddrZip: 4976,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '59 W FRONT ST',
        RegAddrCity: 'SKOWHEGAN',
        RegAddrState: 'ME',
        RegAddrZip: 4976,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '25 BEECH ST',
        RegAddrCity: 'SKOWHEGAN',
        RegAddrState: 'ME',
        RegAddrZip: 4976,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '31 CRESCENT ST',
        RegAddrCity: 'SKOWHEGAN',
        RegAddrState: 'ME',
        RegAddrZip: 4976,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '39 NORTH AVE',
        RegAddrCity: 'SKOWHEGAN',
        RegAddrState: 'ME',
        RegAddrZip: 4976,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '565 VILLAGE RD',
        RegAddrCity: 'SMITHFIELD',
        RegAddrState: 'ME',
        RegAddrZip: 4978,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '44 PLEASANT ST',
        RegAddrCity: 'SOLON',
        RegAddrState: 'ME',
        RegAddrZip: 4979,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1143 RIVER RD',
        RegAddrCity: 'SOLON',
        RegAddrState: 'ME',
        RegAddrZip: 4979,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '31 MCQUILKIN RD',
        RegAddrCity: 'SOLON',
        RegAddrState: 'ME',
        RegAddrZip: 4979,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '44 NICHOL ST',
        RegAddrCity: 'SOUTH PARIS',
        RegAddrState: 'ME',
        RegAddrZip: 4281,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '91 BUCKFIELD RD',
        RegAddrCity: 'SOUTH PARIS',
        RegAddrState: 'ME',
        RegAddrZip: 4281,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '3 PINE RIDGE RD',
        RegAddrCity: 'SOUTH PARIS',
        RegAddrState: 'ME',
        RegAddrZip: 4281,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '65 HERRICK RD',
        RegAddrCity: 'SOUTHWEST HARBOR',
        RegAddrState: 'ME',
        RegAddrZip: 4679,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '344 PARK ST',
        RegAddrCity: 'SPRINGFIELD',
        RegAddrState: 'ME',
        RegAddrZip: 4487,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: 'INDUSTRY RD',
        RegAddrCity: 'STARKS',
        RegAddrState: 'ME',
        RegAddrZip: 4911,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '49 WOLFBORO RD',
        RegAddrCity: 'STETSON',
        RegAddrState: 'ME',
        RegAddrZip: 4488,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '146 CROSS RD',
        RegAddrCity: 'STETSON',
        RegAddrState: 'ME',
        RegAddrZip: 4488,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '35 ALLEN RD',
        RegAddrCity: 'STONEHAM',
        RegAddrState: 'ME',
        RegAddrZip: 4231,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '109 CHURCH HL',
        RegAddrCity: 'STRONG',
        RegAddrState: 'ME',
        RegAddrZip: 4983,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '207 BERT GRAY RD',
        RegAddrCity: 'SULLIVAN',
        RegAddrState: 'ME',
        RegAddrZip: 4664,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '595 BONNEY RD',
        RegAddrCity: 'SUMNER',
        RegAddrState: 'ME',
        RegAddrZip: 4292,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1235 MAIN ST',
        RegAddrCity: 'SUMNER',
        RegAddrState: 'ME',
        RegAddrZip: 4292,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '159 RIDLONVILLE RD',
        RegAddrCity: 'SWEDEN',
        RegAddrState: 'ME',
        RegAddrZip: 4040,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '122 LOPAUS POINT RD',
        RegAddrCity: 'TREMONT',
        RegAddrState: 'ME',
        RegAddrZip: 4612,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '390 OAK POINT RD',
        RegAddrCity: 'TRENTON',
        RegAddrState: 'ME',
        RegAddrZip: 4605,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '863 DETROIT RD',
        RegAddrCity: 'TROY',
        RegAddrState: 'ME',
        RegAddrZip: 4987,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '390 HOWES CORNER RD',
        RegAddrCity: 'TURNER',
        RegAddrState: 'ME',
        RegAddrZip: 4282,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '124 MANCINE RD',
        RegAddrCity: 'TURNER',
        RegAddrState: 'ME',
        RegAddrZip: 4282,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '9 WATERWAY TER',
        RegAddrCity: 'TURNER',
        RegAddrState: 'ME',
        RegAddrZip: 4282,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: 'PO BOX 3',
        RegAddrCity: 'UNITY',
        RegAddrState: 'ME',
        RegAddrZip: 4988,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '83 QUAKER HILL RD',
        RegAddrCity: 'UNITY',
        RegAddrState: 'ME',
        RegAddrZip: 4988,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '126 FULTON ST',
        RegAddrCity: 'VAN BUREN',
        RegAddrState: 'ME',
        RegAddrZip: 4785,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1126 MAIN ST',
        RegAddrCity: 'VEAZIE',
        RegAddrState: 'ME',
        RegAddrZip: 4401,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1117 MAIN ST',
        RegAddrCity: 'VEAZIE',
        RegAddrState: 'ME',
        RegAddrZip: 4401,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '940 GARDNER CREEK RD',
        RegAddrCity: 'WADE',
        RegAddrState: 'ME',
        RegAddrZip: 4786,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '47 GADDIS RD',
        RegAddrCity: 'WHITING',
        RegAddrState: 'ME',
        RegAddrZip: 4691,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '8 SUNRISE AVE',
        RegAddrCity: 'WILTON',
        RegAddrState: 'ME',
        RegAddrZip: 4294,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '160 STREAM RD',
        RegAddrCity: 'WINTERPORT',
        RegAddrState: 'ME',
        RegAddrZip: 4496,
        CongressionalDistrict: 2,
      },
    ],
  },
  {
    name: 'MI-1',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/MI-1?ref=gh-pages',
    state: 'MI',
    state_and_number: 'MI-1',
    zip: 48619,
    congressionalDistrict: 1,
    addressesInDistrict: [
      {
        RegAddrLine1: '1051 PINE RD',
        RegAddrCity: 'ALPENA',
        RegAddrState: 'MI',
        RegAddrZip: 49707,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '10333 W LONG LAKE RD',
        RegAddrCity: 'ALPENA',
        RegAddrState: 'MI',
        RegAddrZip: 49707,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '11280 WOODLAND AVE',
        RegAddrCity: 'ATLANTA',
        RegAddrState: 'MI',
        RegAddrZip: 49709,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '10541 W IRISH LINE RD',
        RegAddrCity: 'BRIMLEY',
        RegAddrState: 'MI',
        RegAddrZip: 49715,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '42 LINDSTROM LN',
        RegAddrCity: 'CARNEY',
        RegAddrState: 'MI',
        RegAddrZip: 49812,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '8715 WESTERN AVE',
        RegAddrCity: 'CHARLEVOIX',
        RegAddrState: 'MI',
        RegAddrZip: 49720,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '8842 ROSS RD',
        RegAddrCity: 'CHEBOYGAN',
        RegAddrState: 'MI',
        RegAddrZip: 49721,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '7344 W US 23 HWY',
        RegAddrCity: 'CHEBOYGAN',
        RegAddrState: 'MI',
        RegAddrZip: 49721,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '18623 OAK ST',
        RegAddrCity: 'COPEMISH',
        RegAddrState: 'MI',
        RegAddrZip: 49625,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '272 LONG LAKE RD',
        RegAddrCity: 'CRYSTAL FALLS',
        RegAddrState: 'MI',
        RegAddrZip: 49920,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '912 WATER ST',
        RegAddrCity: 'EAST JORDAN',
        RegAddrState: 'MI',
        RegAddrZip: 49727,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '4218 M 32',
        RegAddrCity: 'EAST JORDAN',
        RegAddrState: 'MI',
        RegAddrZip: 49727,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '11108 CHURCH RD',
        RegAddrCity: 'ELLSWORTH',
        RegAddrState: 'MI',
        RegAddrZip: 49729,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2915 3RD AVE S',
        RegAddrCity: 'ESCANABA',
        RegAddrState: 'MI',
        RegAddrZip: 49829,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '611 S CEDAR ST',
        RegAddrCity: 'EWEN',
        RegAddrState: 'MI',
        RegAddrZip: 49925,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1150 HLYWA DR',
        RegAddrCity: 'GAYLORD',
        RegAddrState: 'MI',
        RegAddrZip: 49735,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '5834 VANCE RD',
        RegAddrCity: 'GRAWN',
        RegAddrState: 'MI',
        RegAddrZip: 49637,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '51179 M 26',
        RegAddrCity: 'HANCOCK',
        RegAddrState: 'MI',
        RegAddrZip: 49930,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '303 E CHURCH ST',
        RegAddrCity: 'HARRISVILLE',
        RegAddrState: 'MI',
        RegAddrZip: 48740,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: 'W6218 US HIGHWAY 2',
        RegAddrCity: 'HERMANSVILLE',
        RegAddrState: 'MI',
        RegAddrZip: 49847,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '52022 DUNCAN AVE',
        RegAddrCity: 'HUBBELL',
        RegAddrState: 'MI',
        RegAddrZip: 49934,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '191 OLD BEECHWOOD RD',
        RegAddrCity: 'IRON RIVER',
        RegAddrState: 'MI',
        RegAddrZip: 49935,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '9051 OSMO ST',
        RegAddrCity: 'KALEVA',
        RegAddrState: 'MI',
        RegAddrZip: 49645,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '11890 SUMMIT CITY RD',
        RegAddrCity: 'KINGSLEY',
        RegAddrState: 'MI',
        RegAddrZip: 49649,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '208 CLARK ST',
        RegAddrCity: 'KINGSLEY',
        RegAddrState: 'MI',
        RegAddrZip: 49649,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1011 STATE RD',
        RegAddrCity: 'MANISTIQUE',
        RegAddrState: 'MI',
        RegAddrZip: 49854,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '109 GARFIELD AVE',
        RegAddrCity: 'MARQUETTE',
        RegAddrState: 'MI',
        RegAddrZip: 49855,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '321 CEDAR ST',
        RegAddrCity: 'MARQUETTE',
        RegAddrState: 'MI',
        RegAddrZip: 49855,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '130 MIDWAY DR',
        RegAddrCity: 'NEGAUNEE',
        RegAddrState: 'MI',
        RegAddrZip: 49866,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: 'W5417 CURRY LN',
        RegAddrCity: 'NORWAY',
        RegAddrState: 'MI',
        RegAddrZip: 49870,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '187 EVERGREEN',
        RegAddrCity: 'PAINESDALE',
        RegAddrState: 'MI',
        RegAddrZip: 49955,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '204 ISABELLA AVE',
        RegAddrCity: 'PALMER',
        RegAddrState: 'MI',
        RegAddrZip: 49871,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1020 OLD TANNERY CREEK RD',
        RegAddrCity: 'PETOSKEY',
        RegAddrState: 'MI',
        RegAddrZip: 49770,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '410 CROOKED TREE DR',
        RegAddrCity: 'PETOSKEY',
        RegAddrState: 'MI',
        RegAddrZip: 49770,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '4240 BOARDMAN RIVER RD SW',
        RegAddrCity: 'S BOARDMAN',
        RegAddrState: 'MI',
        RegAddrZip: 49680,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '922 LIZZIE ST',
        RegAddrCity: 'SAULT S MARIE',
        RegAddrState: 'MI',
        RegAddrZip: 49783,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '3901 S MAPLE VALLEY RD',
        RegAddrCity: 'SUTTONS BAY',
        RegAddrState: 'MI',
        RegAddrZip: 49682,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '10361 E GRANDVIEW RD',
        RegAddrCity: 'TRAVERSE CITY',
        RegAddrState: 'MI',
        RegAddrZip: 49684,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '4263 BLAIR TOWNHALL RD',
        RegAddrCity: 'TRAVERSE CITY',
        RegAddrState: 'MI',
        RegAddrZip: 49685,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '4272 APPLE TREE LN',
        RegAddrCity: 'TRAVERSE CITY',
        RegAddrState: 'MI',
        RegAddrZip: 49685,
        CongressionalDistrict: 1,
      },
    ],
  },
  {
    name: 'MI-10',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/MI-10?ref=gh-pages',
    state: 'MI',
    state_and_number: 'MI-10',
    zip: 48001,
    congressionalDistrict: 10,
    addressesInDistrict: [
      {
        RegAddrLine1: '5091 BRADLEYVILLE RD',
        RegAddrCity: 'AKRON',
        RegAddrState: 'MI',
        RegAddrZip: 48701,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '79720 KIDDER RD',
        RegAddrCity: 'BRUCE TWP',
        RegAddrState: 'MI',
        RegAddrZip: 48065,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '5657 GREENLAND RD',
        RegAddrCity: 'CASS CITY',
        RegAddrState: 'MI',
        RegAddrZip: 48726,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '3800 KING RD',
        RegAddrCity: 'CHINA',
        RegAddrState: 'MI',
        RegAddrZip: 48054,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '5859 MAYVILLE RD',
        RegAddrCity: 'CLIFFORD',
        RegAddrState: 'MI',
        RegAddrZip: 48727,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '5801 SUTTON RD',
        RegAddrCity: 'DRYDEN',
        RegAddrState: 'MI',
        RegAddrZip: 48428,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '3179 N MILL RD',
        RegAddrCity: 'DRYDEN',
        RegAddrState: 'MI',
        RegAddrZip: 48428,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '11965 BRYCE RD',
        RegAddrCity: 'EMMETT',
        RegAddrState: 'MI',
        RegAddrZip: 48022,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '163 S KIRK RD',
        RegAddrCity: 'FAIRGROVE',
        RegAddrState: 'MI',
        RegAddrZip: 48733,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '5284 LAKESHORE RD',
        RegAddrCity: 'FORT GRATIOT',
        RegAddrState: 'MI',
        RegAddrZip: 48059,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '4588 24TH AVE',
        RegAddrCity: 'FORT GRATIOT',
        RegAddrState: 'MI',
        RegAddrZip: 48059,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '27663 N WILLOWOOD DR',
        RegAddrCity: 'HARRISON TOWNSHIP',
        RegAddrState: 'MI',
        RegAddrZip: 48045,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '2515 BRISTOL RD',
        RegAddrCity: 'IMLAY CITY',
        RegAddrState: 'MI',
        RegAddrZip: 48444,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '4895 PINNEBOG RD',
        RegAddrCity: 'KINDE',
        RegAddrState: 'MI',
        RegAddrZip: 48445,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '323 JEFFERSON ST',
        RegAddrCity: 'LAPEER',
        RegAddrState: 'MI',
        RegAddrZip: 48446,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '94 BIRMINGALE AVE',
        RegAddrCity: 'LAPEER',
        RegAddrState: 'MI',
        RegAddrZip: 48446,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '52703 STAG RIDGE DR',
        RegAddrCity: 'MACOMB',
        RegAddrState: 'MI',
        RegAddrZip: 48042,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '55130 CORBIN DR',
        RegAddrCity: 'MACOMB',
        RegAddrState: 'MI',
        RegAddrZip: 48042,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '20476 WINDHAM DR',
        RegAddrCity: 'MACOMB',
        RegAddrState: 'MI',
        RegAddrZip: 48044,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '46724 SPRINGWOOD DR',
        RegAddrCity: 'MACOMB',
        RegAddrState: 'MI',
        RegAddrZip: 48044,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '49695 GUY DR',
        RegAddrCity: 'MACOMB',
        RegAddrState: 'MI',
        RegAddrZip: 48044,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '362 1/2 WOODWORTH ST',
        RegAddrCity: 'MARINE CITY',
        RegAddrState: 'MI',
        RegAddrZip: 48039,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '701 GEORGIA AVE',
        RegAddrCity: 'MARYSVILLE',
        RegAddrState: 'MI',
        RegAddrZip: 48040,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '35942 GLENVILLE DR',
        RegAddrCity: 'NEW BALTIMORE',
        RegAddrState: 'MI',
        RegAddrZip: 48047,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '28462 TIFFIN DR',
        RegAddrCity: 'NEW BALTIMORE',
        RegAddrState: 'MI',
        RegAddrZip: 48047,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '49406 MONTE RD',
        RegAddrCity: 'NEW BALTIMORE',
        RegAddrState: 'MI',
        RegAddrZip: 48047,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '51719 LESHAN DR',
        RegAddrCity: 'NEW BALTIMORE',
        RegAddrState: 'MI',
        RegAddrZip: 48047,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '53092 W RIDGE DR',
        RegAddrCity: 'NEW BALTIMORE',
        RegAddrState: 'MI',
        RegAddrZip: 48051,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '2061 NORTH BRANCH RD',
        RegAddrCity: 'NORTH BRANCH',
        RegAddrState: 'MI',
        RegAddrZip: 48461,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '731 GRISWOLD ST',
        RegAddrCity: 'PORT HURON',
        RegAddrState: 'MI',
        RegAddrZip: 48060,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '1019 GLENWOOD AVE',
        RegAddrCity: 'PORT HURON',
        RegAddrState: 'MI',
        RegAddrZip: 48060,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '69578 WELDING RD',
        RegAddrCity: 'RICHMOND',
        RegAddrState: 'MI',
        RegAddrZip: 48062,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '8400 PIPPEN DR',
        RegAddrCity: 'ROMEO',
        RegAddrState: 'MI',
        RegAddrZip: 48065,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '957 S 11TH ST',
        RegAddrCity: 'SAINT CLAIR',
        RegAddrState: 'MI',
        RegAddrZip: 48079,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '52489 BUTTERNUT DR',
        RegAddrCity: 'SHELBY TOWNSHIP',
        RegAddrState: 'MI',
        RegAddrZip: 48316,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '48587 MONTELEPRE DR',
        RegAddrCity: 'SHELBY TWP',
        RegAddrState: 'MI',
        RegAddrZip: 48315,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '47515 PINECREST DR',
        RegAddrCity: 'SHELBY TWP',
        RegAddrState: 'MI',
        RegAddrZip: 48317,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '42817 SYCAMORE DR',
        RegAddrCity: 'STERLING HTS',
        RegAddrState: 'MI',
        RegAddrZip: 48313,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '8921 RADNER DR',
        RegAddrCity: 'STERLING HTS',
        RegAddrState: 'MI',
        RegAddrZip: 48314,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '4763 BAY PARK RD',
        RegAddrCity: 'UNIONVILLE',
        RegAddrState: 'MI',
        RegAddrZip: 48767,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '45171 KENSINGTON ST',
        RegAddrCity: 'UTICA',
        RegAddrState: 'MI',
        RegAddrZip: 48317,
        CongressionalDistrict: 10,
      },
    ],
  },
  {
    name: 'MI-11',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/MI-11?ref=gh-pages',
    state: 'MI',
    state_and_number: 'MI-11',
    zip: 48083,
    congressionalDistrict: 11,
    addressesInDistrict: [
      {
        RegAddrLine1: '511 HENLEY ST',
        RegAddrCity: 'BIRMINGHAM',
        RegAddrState: 'MI',
        RegAddrZip: 48009,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '1431 RAND RD',
        RegAddrCity: 'CANTON',
        RegAddrState: 'MI',
        RegAddrZip: 48187,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '6665 WOONSOCKET ST',
        RegAddrCity: 'CANTON',
        RegAddrState: 'MI',
        RegAddrZip: 48187,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '6733 BROOKSHIRE DR',
        RegAddrCity: 'CANTON',
        RegAddrState: 'MI',
        RegAddrZip: 48187,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '45233 PATRICK DR',
        RegAddrCity: 'CANTON',
        RegAddrState: 'MI',
        RegAddrZip: 48187,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '1900 HAWKS NEST CT',
        RegAddrCity: 'CANTON',
        RegAddrState: 'MI',
        RegAddrZip: 48188,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '42544 BEECHWOOD DR',
        RegAddrCity: 'CANTON',
        RegAddrState: 'MI',
        RegAddrZip: 48188,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '137 VILLAGE WAY',
        RegAddrCity: 'CANTON',
        RegAddrState: 'MI',
        RegAddrZip: 48188,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '517 GARDNER AVE',
        RegAddrCity: 'CLAWSON',
        RegAddrState: 'MI',
        RegAddrZip: 48017,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '2040 QUAIL RUN DR',
        RegAddrCity: 'COMMERCE TWP',
        RegAddrState: 'MI',
        RegAddrZip: 48390,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '4890 HARVEY LAKE RD',
        RegAddrCity: 'HIGHLAND',
        RegAddrState: 'MI',
        RegAddrZip: 48356,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '37780 GRANTLAND ST',
        RegAddrCity: 'LIVONIA',
        RegAddrState: 'MI',
        RegAddrZip: 48150,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '30331 WESTFIELD ST',
        RegAddrCity: 'LIVONIA',
        RegAddrState: 'MI',
        RegAddrZip: 48150,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '31089 MINTON ST',
        RegAddrCity: 'LIVONIA',
        RegAddrState: 'MI',
        RegAddrZip: 48150,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '36287 JAMISON ST',
        RegAddrCity: 'LIVONIA',
        RegAddrState: 'MI',
        RegAddrZip: 48154,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '14192 ELLEN DR',
        RegAddrCity: 'LIVONIA',
        RegAddrState: 'MI',
        RegAddrZip: 48154,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '15130 SUNBURY ST',
        RegAddrCity: 'LIVONIA',
        RegAddrState: 'MI',
        RegAddrZip: 48154,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '16812 WOODSIDE ST',
        RegAddrCity: 'LIVONIA',
        RegAddrState: 'MI',
        RegAddrZip: 48154,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '15940 NEWBURGH RD',
        RegAddrCity: 'LIVONIA',
        RegAddrState: 'MI',
        RegAddrZip: 48154,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '3750 W BUNO RD',
        RegAddrCity: 'MILFORD',
        RegAddrState: 'MI',
        RegAddrZip: 48380,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '1801 N LABADIE',
        RegAddrCity: 'MILFORD',
        RegAddrState: 'MI',
        RegAddrZip: 48380,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '1452 EDGEWOOD LN',
        RegAddrCity: 'MILFORD',
        RegAddrState: 'MI',
        RegAddrZip: 48381,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '4018 KINGSWOOD CT',
        RegAddrCity: 'MILFORD',
        RegAddrState: 'MI',
        RegAddrZip: 48381,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '29368 TONESTER CIR',
        RegAddrCity: 'NEW HUDSON',
        RegAddrState: 'MI',
        RegAddrZip: 48165,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '125 RANDOLPH ST',
        RegAddrCity: 'NORTHVILLE',
        RegAddrState: 'MI',
        RegAddrZip: 48167,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '27471 HARRINGTON WAY',
        RegAddrCity: 'NOVI',
        RegAddrState: 'MI',
        RegAddrZip: 48374,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '24955 WHITE PLAINS DR',
        RegAddrCity: 'NOVI',
        RegAddrState: 'MI',
        RegAddrZip: 48374,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '41693 CHESTERFIELD CT',
        RegAddrCity: 'NOVI',
        RegAddrState: 'MI',
        RegAddrZip: 48375,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '28930 HEARTHSTONE DR',
        RegAddrCity: 'NOVI',
        RegAddrState: 'MI',
        RegAddrZip: 48377,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '1004 JUNCTION ST',
        RegAddrCity: 'PLYMOUTH',
        RegAddrState: 'MI',
        RegAddrZip: 48170,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '3071 YORK RD',
        RegAddrCity: 'ROCHESTER HILLS',
        RegAddrState: 'MI',
        RegAddrZip: 48309,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '620 HILL HOUSE',
        RegAddrCity: 'ROCHESTER HILLS',
        RegAddrState: 'MI',
        RegAddrZip: 48309,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '457 FLAMINGO ST',
        RegAddrCity: 'ROCHESTER HILLS',
        RegAddrState: 'MI',
        RegAddrZip: 48309,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '223 LYONS DR',
        RegAddrCity: 'TROY',
        RegAddrState: 'MI',
        RegAddrZip: 48083,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '2219 HEMPSTEAD DR',
        RegAddrCity: 'TROY',
        RegAddrState: 'MI',
        RegAddrZip: 48083,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '2652 KENWYCK DR',
        RegAddrCity: 'TROY',
        RegAddrState: 'MI',
        RegAddrZip: 48085,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '3865 OAK KNOLL RD',
        RegAddrCity: 'WATERFORD',
        RegAddrState: 'MI',
        RegAddrZip: 48328,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '1017 OTTER AVE',
        RegAddrCity: 'WATERFORD',
        RegAddrState: 'MI',
        RegAddrZip: 48328,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '4873 SHORELINE BLVD',
        RegAddrCity: 'WATERFORD',
        RegAddrState: 'MI',
        RegAddrZip: 48329,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '5030 OAK ST',
        RegAddrCity: 'WEBBERVILLE',
        RegAddrState: 'MI',
        RegAddrZip: 48892,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '5520 POPPLETON CT',
        RegAddrCity: 'WEST BLOOMFIELD',
        RegAddrState: 'MI',
        RegAddrZip: 48324,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '137 WANDA ST',
        RegAddrCity: 'WOLVERINE LK',
        RegAddrState: 'MI',
        RegAddrZip: 48390,
        CongressionalDistrict: 11,
      },
    ],
  },
  {
    name: 'MI-12',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/MI-12?ref=gh-pages',
    state: 'MI',
    state_and_number: 'MI-12',
    zip: 48101,
    congressionalDistrict: 12,
    addressesInDistrict: [
      {
        RegAddrLine1: '17066 HAMILTON AVE',
        RegAddrCity: 'ALLEN PARK',
        RegAddrState: 'MI',
        RegAddrZip: 48101,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '606 BROOKS ST',
        RegAddrCity: 'ANN ARBOR',
        RegAddrState: 'MI',
        RegAddrZip: 48103,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '908 SYBIL ST',
        RegAddrCity: 'ANN ARBOR',
        RegAddrState: 'MI',
        RegAddrZip: 48104,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '2645 EASY ST',
        RegAddrCity: 'ANN ARBOR',
        RegAddrState: 'MI',
        RegAddrZip: 48104,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '1125 WOLVERHAMPTON LN',
        RegAddrCity: 'ANN ARBOR',
        RegAddrState: 'MI',
        RegAddrZip: 48105,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '2833 DEAKE AVE',
        RegAddrCity: 'ANN ARBOR',
        RegAddrState: 'MI',
        RegAddrZip: 48108,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '6182 GYERS MEADOW LN',
        RegAddrCity: 'ANN ARBOR',
        RegAddrState: 'MI',
        RegAddrZip: 48108,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '719 ADDINGTON LN',
        RegAddrCity: 'ANN ARBOR',
        RegAddrState: 'MI',
        RegAddrZip: 48108,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '16010 CULPEPPER RD',
        RegAddrCity: 'BROWNSTWN TWP',
        RegAddrState: 'MI',
        RegAddrZip: 48173,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '207 S LAFAYETTE ST',
        RegAddrCity: 'DEARBORN',
        RegAddrState: 'MI',
        RegAddrZip: 48124,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '24843 UNION ST',
        RegAddrCity: 'DEARBORN',
        RegAddrState: 'MI',
        RegAddrZip: 48124,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '8 LEMINGTON CT',
        RegAddrCity: 'DEARBORN',
        RegAddrState: 'MI',
        RegAddrZip: 48126,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '4918 WILLIAMSON ST',
        RegAddrCity: 'DEARBORN',
        RegAddrState: 'MI',
        RegAddrZip: 48126,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '28467 OAKWOOD AVE',
        RegAddrCity: 'FLAT ROCK',
        RegAddrState: 'MI',
        RegAddrZip: 48134,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '486 CLEVELAND AVE',
        RegAddrCity: 'LINCOLN PARK',
        RegAddrState: 'MI',
        RegAddrZip: 48146,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '18220 FOX GLEN DR',
        RegAddrCity: 'RIVERVIEW',
        RegAddrState: 'MI',
        RegAddrZip: 48193,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '15710 MCCANN ST',
        RegAddrCity: 'SOUTHGATE',
        RegAddrState: 'MI',
        RegAddrZip: 48195,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '14310 GAGE ST',
        RegAddrCity: 'TAYLOR',
        RegAddrState: 'MI',
        RegAddrZip: 48180,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '8541 MONROE BLVD',
        RegAddrCity: 'TAYLOR',
        RegAddrState: 'MI',
        RegAddrZip: 48180,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '7833 CAMPBELL ST',
        RegAddrCity: 'TAYLOR',
        RegAddrState: 'MI',
        RegAddrZip: 48180,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '7659 BIRCH ST',
        RegAddrCity: 'TAYLOR',
        RegAddrState: 'MI',
        RegAddrZip: 48180,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '7720 MONROE BLVD',
        RegAddrCity: 'TAYLOR',
        RegAddrState: 'MI',
        RegAddrZip: 48180,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '3360 EDSEL ST',
        RegAddrCity: 'TRENTON',
        RegAddrState: 'MI',
        RegAddrZip: 48183,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '2259 CHURCHILL AVE',
        RegAddrCity: 'TRENTON',
        RegAddrState: 'MI',
        RegAddrZip: 48183,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '3563 SALEM DR',
        RegAddrCity: 'TRENTON',
        RegAddrState: 'MI',
        RegAddrZip: 48183,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '2744 1ST ST',
        RegAddrCity: 'WYANDOTTE',
        RegAddrState: 'MI',
        RegAddrZip: 48192,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '633 NORTH DR',
        RegAddrCity: 'WYANDOTTE',
        RegAddrState: 'MI',
        RegAddrZip: 48192,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '4676 WALNUT CT',
        RegAddrCity: 'YPSILANTI',
        RegAddrState: 'MI',
        RegAddrZip: 48197,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '8192 LAKE CREST DR',
        RegAddrCity: 'YPSILANTI',
        RegAddrState: 'MI',
        RegAddrZip: 48197,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '939 PARKWOOD AVE',
        RegAddrCity: 'YPSILANTI',
        RegAddrState: 'MI',
        RegAddrZip: 48198,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '1657 CARDIFF ROW',
        RegAddrCity: 'YPSILANTI',
        RegAddrState: 'MI',
        RegAddrZip: 48198,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '1133 STUDEBAKER AVE',
        RegAddrCity: 'YPSILANTI',
        RegAddrState: 'MI',
        RegAddrZip: 48198,
        CongressionalDistrict: 12,
      },
    ],
  },
  {
    name: 'MI-13',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/MI-13?ref=gh-pages',
    state: 'MI',
    state_and_number: 'MI-13',
    zip: 48135,
    congressionalDistrict: 13,
    addressesInDistrict: [
      {
        RegAddrLine1: '24655 MCDONALD ST',
        RegAddrCity: 'DEARBORN HEIGHTS',
        RegAddrState: 'MI',
        RegAddrZip: 48125,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '27359 CRANFORD LN',
        RegAddrCity: 'DEARBORN HEIGHTS',
        RegAddrState: 'MI',
        RegAddrZip: 48127,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '6602 FENTON ST',
        RegAddrCity: 'DEARBORN HTS',
        RegAddrState: 'MI',
        RegAddrZip: 48127,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '5713 MAYBURN ST',
        RegAddrCity: 'DEARBORN HTS',
        RegAddrState: 'MI',
        RegAddrZip: 48127,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '499 LAWRENCE ST',
        RegAddrCity: 'DETROIT',
        RegAddrState: 'MI',
        RegAddrZip: 48202,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '1251 ELIJAH MCCOY DR',
        RegAddrCity: 'DETROIT',
        RegAddrState: 'MI',
        RegAddrZip: 48202,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '9400 QUINCY ST',
        RegAddrCity: 'DETROIT',
        RegAddrState: 'MI',
        RegAddrZip: 48204,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '1747 LONGFELLOW ST',
        RegAddrCity: 'DETROIT',
        RegAddrState: 'MI',
        RegAddrZip: 48206,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '2954 MONTGOMERY ST',
        RegAddrCity: 'DETROIT',
        RegAddrState: 'MI',
        RegAddrZip: 48206,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '2296 HAZELWOOD ST',
        RegAddrCity: 'DETROIT',
        RegAddrState: 'MI',
        RegAddrZip: 48206,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '2630 HOOKER ST',
        RegAddrCity: 'DETROIT',
        RegAddrState: 'MI',
        RegAddrZip: 48208,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '6134 16TH ST',
        RegAddrCity: 'DETROIT',
        RegAddrState: 'MI',
        RegAddrZip: 48208,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '20091 FENTON ST',
        RegAddrCity: 'DETROIT',
        RegAddrState: 'MI',
        RegAddrZip: 48219,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '19415 LAHSER RD',
        RegAddrCity: 'DETROIT',
        RegAddrState: 'MI',
        RegAddrZip: 48219,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '17179 WESTMORELAND RD',
        RegAddrCity: 'DETROIT',
        RegAddrState: 'MI',
        RegAddrZip: 48219,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '20580 KENTFIELD ST',
        RegAddrCity: 'DETROIT',
        RegAddrState: 'MI',
        RegAddrZip: 48219,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '14113 WARWICK ST',
        RegAddrCity: 'DETROIT',
        RegAddrState: 'MI',
        RegAddrZip: 48223,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '12847 BRAILE ST',
        RegAddrCity: 'DETROIT',
        RegAddrState: 'MI',
        RegAddrZip: 48223,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '14225 CRESCENT DR',
        RegAddrCity: 'DETROIT',
        RegAddrState: 'MI',
        RegAddrZip: 48223,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '12274 WOODMONT AVE',
        RegAddrCity: 'DETROIT',
        RegAddrState: 'MI',
        RegAddrZip: 48227,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '16048 HACKETT ST',
        RegAddrCity: 'DETROIT',
        RegAddrState: 'MI',
        RegAddrZip: 48227,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '8681 ARTESIAN ST',
        RegAddrCity: 'DETROIT',
        RegAddrState: 'MI',
        RegAddrZip: 48228,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '9387 SORRENTO ST',
        RegAddrCity: 'DETROIT',
        RegAddrState: 'MI',
        RegAddrZip: 48228,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '6072 PENROD ST',
        RegAddrCity: 'DETROIT',
        RegAddrState: 'MI',
        RegAddrZip: 48228,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '9991 STAHELIN AVE',
        RegAddrCity: 'DETROIT',
        RegAddrState: 'MI',
        RegAddrZip: 48228,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '20548 BLOOM ST',
        RegAddrCity: 'DETROIT',
        RegAddrState: 'MI',
        RegAddrZip: 48234,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '23 KNOX ST',
        RegAddrCity: 'ECORSE',
        RegAddrState: 'MI',
        RegAddrZip: 48229,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '251 HELEN ST',
        RegAddrCity: 'GARDEN CITY',
        RegAddrState: 'MI',
        RegAddrZip: 48135,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '28347 AVONDALE ST',
        RegAddrCity: 'INKSTER',
        RegAddrState: 'MI',
        RegAddrZip: 48141,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '27817 ROSEWOOD ST',
        RegAddrCity: 'INKSTER',
        RegAddrState: 'MI',
        RegAddrZip: 48141,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '12955 LENORE',
        RegAddrCity: 'REDFORD',
        RegAddrState: 'MI',
        RegAddrZip: 48239,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '15448 LEXINGTON',
        RegAddrCity: 'REDFORD',
        RegAddrState: 'MI',
        RegAddrZip: 48239,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '32 STONER ST',
        RegAddrCity: 'RIVER ROUGE',
        RegAddrState: 'MI',
        RegAddrZip: 48218,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '5024 BIDDLE ST',
        RegAddrCity: 'WAYNE',
        RegAddrState: 'MI',
        RegAddrZip: 48184,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '35443 GLENWOOD RD',
        RegAddrCity: 'WAYNE',
        RegAddrState: 'MI',
        RegAddrZip: 48184,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '38173 CAROLON BLVD',
        RegAddrCity: 'WESTLAND',
        RegAddrState: 'MI',
        RegAddrZip: 48185,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '32626 MONTCALM ST',
        RegAddrCity: 'WESTLAND',
        RegAddrState: 'MI',
        RegAddrZip: 48186,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '31436 HAZELWOOD ST',
        RegAddrCity: 'WESTLAND',
        RegAddrState: 'MI',
        RegAddrZip: 48186,
        CongressionalDistrict: 13,
      },
    ],
  },
  {
    name: 'MI-14',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/MI-14?ref=gh-pages',
    state: 'MI',
    state_and_number: 'MI-14',
    zip: 48033,
    congressionalDistrict: 14,
    addressesInDistrict: [
      {
        RegAddrLine1: '123 E MARGARET ST',
        RegAddrCity: 'DETROIT',
        RegAddrState: 'MI',
        RegAddrZip: 48203,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '18976 GOULBURN ST',
        RegAddrCity: 'DETROIT',
        RegAddrState: 'MI',
        RegAddrZip: 48205,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '20224 REGENT DR',
        RegAddrCity: 'DETROIT',
        RegAddrState: 'MI',
        RegAddrZip: 48205,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '19921 PELKEY ST',
        RegAddrCity: 'DETROIT',
        RegAddrState: 'MI',
        RegAddrZip: 48205,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '1117 CENTRAL ST',
        RegAddrCity: 'DETROIT',
        RegAddrState: 'MI',
        RegAddrZip: 48209,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '14237 WADE ST',
        RegAddrCity: 'DETROIT',
        RegAddrState: 'MI',
        RegAddrZip: 48213,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '12234 ROSEMARY ST',
        RegAddrCity: 'DETROIT',
        RegAddrState: 'MI',
        RegAddrZip: 48213,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '20022 STOEPEL ST',
        RegAddrCity: 'DETROIT',
        RegAddrState: 'MI',
        RegAddrZip: 48221,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '17368 INDIANA ST',
        RegAddrCity: 'DETROIT',
        RegAddrState: 'MI',
        RegAddrZip: 48221,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '20109 MONICA ST',
        RegAddrCity: 'DETROIT',
        RegAddrState: 'MI',
        RegAddrZip: 48221,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '17394 BIRWOOD ST',
        RegAddrCity: 'DETROIT',
        RegAddrState: 'MI',
        RegAddrZip: 48221,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '10362 BRITAIN ST',
        RegAddrCity: 'DETROIT',
        RegAddrState: 'MI',
        RegAddrZip: 48224,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '6110 UNIVERSITY PL',
        RegAddrCity: 'DETROIT',
        RegAddrState: 'MI',
        RegAddrZip: 48224,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '3564 MARYLAND ST',
        RegAddrCity: 'DETROIT',
        RegAddrState: 'MI',
        RegAddrZip: 48224,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '15882 APPOLINE ST',
        RegAddrCity: 'DETROIT',
        RegAddrState: 'MI',
        RegAddrZip: 48227,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '17180 ARDMORE ST',
        RegAddrCity: 'DETROIT',
        RegAddrState: 'MI',
        RegAddrZip: 48235,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '19700 HUBBELL ST',
        RegAddrCity: 'DETROIT',
        RegAddrState: 'MI',
        RegAddrZip: 48235,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '19199 TYRONE ST',
        RegAddrCity: 'DETROIT',
        RegAddrState: 'MI',
        RegAddrZip: 48236,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '29355 WEATHERVANE AVE',
        RegAddrCity: 'FARMINGTON HILLS',
        RegAddrState: 'MI',
        RegAddrZip: 48331,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '21406 PARKSIDE BLVD',
        RegAddrCity: 'FERNDALE',
        RegAddrState: 'MI',
        RegAddrZip: 48220,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '2217 HOLLYWOOD AVE',
        RegAddrCity: 'GROSSE PT WDS',
        RegAddrState: 'MI',
        RegAddrZip: 48236,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '1738 LITTLESTONE RD',
        RegAddrCity: 'GROSSE PT WDS',
        RegAddrState: 'MI',
        RegAddrZip: 48236,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '1903 RUSTIC LN',
        RegAddrCity: 'KEEGO HARBOR',
        RegAddrState: 'MI',
        RegAddrZip: 48320,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '18701 SAN QUENTIN DR',
        RegAddrCity: 'LATHRUP VLG',
        RegAddrState: 'MI',
        RegAddrZip: 48076,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '18141 ROSELAND BLVD',
        RegAddrCity: 'LATHRUP VLG',
        RegAddrState: 'MI',
        RegAddrZip: 48076,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '15 OAK CREEK LN',
        RegAddrCity: 'PONTIAC',
        RegAddrState: 'MI',
        RegAddrZip: 48340,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '764 W HURON ST',
        RegAddrCity: 'PONTIAC',
        RegAddrState: 'MI',
        RegAddrZip: 48341,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '59 MOHAWK RD',
        RegAddrCity: 'PONTIAC',
        RegAddrState: 'MI',
        RegAddrZip: 48341,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '25041 STONYCROFT DR',
        RegAddrCity: 'SOUTHFIELD',
        RegAddrState: 'MI',
        RegAddrZip: 48033,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '20168 WILLOWICK DR',
        RegAddrCity: 'SOUTHFIELD',
        RegAddrState: 'MI',
        RegAddrZip: 48076,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '29960 PLEASANT TRL',
        RegAddrCity: 'SOUTHFIELD',
        RegAddrState: 'MI',
        RegAddrZip: 48076,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '6233 BRANFORD DR',
        RegAddrCity: 'W BLOOMFIELD',
        RegAddrState: 'MI',
        RegAddrZip: 48322,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '5175 BANTRY DR',
        RegAddrCity: 'WEST BLOOMFIELD',
        RegAddrState: 'MI',
        RegAddrZip: 48322,
        CongressionalDistrict: 14,
      },
    ],
  },
  {
    name: 'MI-2',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/MI-2?ref=gh-pages',
    state: 'MI',
    state_and_number: 'MI-2',
    zip: 49303,
    congressionalDistrict: 2,
    addressesInDistrict: [
      {
        RegAddrLine1: '6276 TIMBER DR',
        RegAddrCity: 'ALLENDALE',
        RegAddrState: 'MI',
        RegAddrZip: 49401,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '640 W RANDALL ST',
        RegAddrCity: 'COOPERSVILLE',
        RegAddrState: 'MI',
        RegAddrZip: 49404,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '3949 FARR RD',
        RegAddrCity: 'FRUITPORT',
        RegAddrState: 'MI',
        RegAddrZip: 49415,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '257 HOBART ST SE',
        RegAddrCity: 'GRAND RAPIDS',
        RegAddrState: 'MI',
        RegAddrZip: 49507,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: 'O-10995 MOUNTAIN ASH AVE NW',
        RegAddrCity: 'GRAND RAPIDS',
        RegAddrState: 'MI',
        RegAddrZip: 49534,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '6060 W 112TH ST',
        RegAddrCity: 'GRANT',
        RegAddrState: 'MI',
        RegAddrZip: 49327,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '460 COLLEGE AVE',
        RegAddrCity: 'HOLLAND',
        RegAddrState: 'MI',
        RegAddrZip: 49423,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '12930 SAPPHIRE PKWY',
        RegAddrCity: 'HOLLAND',
        RegAddrState: 'MI',
        RegAddrZip: 49424,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '17044 JAMES ST',
        RegAddrCity: 'HOLLAND',
        RegAddrState: 'MI',
        RegAddrZip: 49424,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '11530 RIDGEWAY DR',
        RegAddrCity: 'HOLLAND',
        RegAddrState: 'MI',
        RegAddrZip: 49424,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '6825 64TH AVE',
        RegAddrCity: 'HUDSONVILLE',
        RegAddrState: 'MI',
        RegAddrZip: 49426,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2048 JACLYN DR',
        RegAddrCity: 'HUDSONVILLE',
        RegAddrState: 'MI',
        RegAddrZip: 49426,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '7457 BOULDER BLUFF DR',
        RegAddrCity: 'JENISON',
        RegAddrState: 'MI',
        RegAddrZip: 49428,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1499 SKYLARK DR',
        RegAddrCity: 'JENISON',
        RegAddrState: 'MI',
        RegAddrZip: 49428,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2985 N LINCOLN RD',
        RegAddrCity: 'LUDINGTON',
        RegAddrState: 'MI',
        RegAddrZip: 49431,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '815 W LARCH AVE',
        RegAddrCity: 'MUSKEGON',
        RegAddrState: 'MI',
        RegAddrZip: 49441,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1866 CONTINENTAL ST',
        RegAddrCity: 'MUSKEGON',
        RegAddrState: 'MI',
        RegAddrZip: 49442,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '331 YUBA ST',
        RegAddrCity: 'MUSKEGON',
        RegAddrState: 'MI',
        RegAddrZip: 49442,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2215 RIORDAN ST',
        RegAddrCity: 'MUSKEGON',
        RegAddrState: 'MI',
        RegAddrZip: 49444,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2980 STRAND RD',
        RegAddrCity: 'MUSKEGON',
        RegAddrState: 'MI',
        RegAddrZip: 49445,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1539 N PETERSON RD',
        RegAddrCity: 'MUSKEGON',
        RegAddrState: 'MI',
        RegAddrZip: 49445,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1931 W GRANT RD',
        RegAddrCity: 'NEW ERA',
        RegAddrState: 'MI',
        RegAddrZip: 49446,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '8596 E 56TH ST',
        RegAddrCity: 'NEWAYGO',
        RegAddrState: 'MI',
        RegAddrZip: 49337,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '7392 NORTHERN DR',
        RegAddrCity: 'PENTWATER',
        RegAddrState: 'MI',
        RegAddrZip: 49449,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '3569 CENTER ST',
        RegAddrCity: 'RAVENNA',
        RegAddrState: 'MI',
        RegAddrZip: 49451,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '12211 S ELM AVE',
        RegAddrCity: 'SAND LAKE',
        RegAddrState: 'MI',
        RegAddrZip: 49343,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '6668 W JOHNSON RD',
        RegAddrCity: 'SHELBY',
        RegAddrState: 'MI',
        RegAddrZip: 49455,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '183 LAKE COURT RD',
        RegAddrCity: 'TWIN LAKE',
        RegAddrState: 'MI',
        RegAddrZip: 49457,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '9135 WINTERBERRY DR',
        RegAddrCity: 'WEST OLIVE',
        RegAddrState: 'MI',
        RegAddrZip: 49460,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '9828 LAKE MICHIGAN DR',
        RegAddrCity: 'WEST OLIVE',
        RegAddrState: 'MI',
        RegAddrZip: 49460,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '6157 BELL RD',
        RegAddrCity: 'WHITEHALL',
        RegAddrState: 'MI',
        RegAddrZip: 49461,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '5408 MEADOW RUN DR SW',
        RegAddrCity: 'WYOMING',
        RegAddrState: 'MI',
        RegAddrZip: 49509,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2661 LONGFELLOW AVE SW',
        RegAddrCity: 'WYOMING',
        RegAddrState: 'MI',
        RegAddrZip: 49509,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2933 SHARON AVE SW',
        RegAddrCity: 'WYOMING',
        RegAddrState: 'MI',
        RegAddrZip: 49519,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '4062 JEFFERSON AVE SE',
        RegAddrCity: 'WYOMING',
        RegAddrState: 'MI',
        RegAddrZip: 49548,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '9999 SERENGETI LN',
        RegAddrCity: 'ZEELAND',
        RegAddrState: 'MI',
        RegAddrZip: 49464,
        CongressionalDistrict: 2,
      },
    ],
  },
  {
    name: 'MI-3',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/MI-3?ref=gh-pages',
    state: 'MI',
    state_and_number: 'MI-3',
    zip: 48809,
    congressionalDistrict: 3,
    addressesInDistrict: [
      {
        RegAddrLine1: '16442 26 MILE RD',
        RegAddrCity: 'ALBION',
        RegAddrState: 'MI',
        RegAddrZip: 49224,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '65 GOGUAC ST W',
        RegAddrCity: 'BATTLE CREEK',
        RegAddrState: 'MI',
        RegAddrZip: 49015,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '43 MONTFORD ST',
        RegAddrCity: 'BATTLE CREEK',
        RegAddrState: 'MI',
        RegAddrZip: 49017,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '115 PEPPER LN',
        RegAddrCity: 'BATTLE CREEK',
        RegAddrState: 'MI',
        RegAddrZip: 49037,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '63 FRISBIE AVE',
        RegAddrCity: 'BATTLE CREEK',
        RegAddrState: 'MI',
        RegAddrZip: 49037,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '7800 DREAM ISLE DR NE',
        RegAddrCity: 'BELDING',
        RegAddrState: 'MI',
        RegAddrZip: 48809,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '6639 AVALON CT SE',
        RegAddrCity: 'CALEDONIA',
        RegAddrState: 'MI',
        RegAddrZip: 49316,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '4191 BAUMHOFF AVE NW',
        RegAddrCity: 'COMSTOCK PARK',
        RegAddrState: 'MI',
        RegAddrZip: 49321,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1039 NORWICH AVE SW',
        RegAddrCity: 'GRAND RAPIDS',
        RegAddrState: 'MI',
        RegAddrZip: 49503,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '843 COVELL AVE NW',
        RegAddrCity: 'GRAND RAPIDS',
        RegAddrState: 'MI',
        RegAddrZip: 49504,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1565 COVELL AVE NW',
        RegAddrCity: 'GRAND RAPIDS',
        RegAddrState: 'MI',
        RegAddrZip: 49504,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '2036 PAYTON LN NE',
        RegAddrCity: 'GRAND RAPIDS',
        RegAddrState: 'MI',
        RegAddrZip: 49505,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '2744 MIDLAND DR SE',
        RegAddrCity: 'GRAND RAPIDS',
        RegAddrState: 'MI',
        RegAddrZip: 49506,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '110 HALL ST SE',
        RegAddrCity: 'GRAND RAPIDS',
        RegAddrState: 'MI',
        RegAddrZip: 49507,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '423 BURTON ST SE',
        RegAddrCity: 'GRAND RAPIDS',
        RegAddrState: 'MI',
        RegAddrZip: 49507,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '325 GRIGGS ST SE',
        RegAddrCity: 'GRAND RAPIDS',
        RegAddrState: 'MI',
        RegAddrZip: 49507,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '4204 CASTLE DR SE',
        RegAddrCity: 'GRAND RAPIDS',
        RegAddrState: 'MI',
        RegAddrZip: 49546,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '3432 HIDDEN HILLS AVE SE',
        RegAddrCity: 'GRAND RAPIDS',
        RegAddrState: 'MI',
        RegAddrZip: 49546,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '6580 PARIS AVE SE',
        RegAddrCity: 'GRAND RAPIDS',
        RegAddrState: 'MI',
        RegAddrZip: 49548,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '10950 DEBBIE DR',
        RegAddrCity: 'GREENVILLE',
        RegAddrState: 'MI',
        RegAddrZip: 48838,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '11801 13 MILE RD NE',
        RegAddrCity: 'GREENVILLE',
        RegAddrState: 'MI',
        RegAddrZip: 48838,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '252 WELCOME RD',
        RegAddrCity: 'HASTINGS',
        RegAddrState: 'MI',
        RegAddrZip: 49058,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '4661 S M 37 HWY',
        RegAddrCity: 'HASTINGS',
        RegAddrState: 'MI',
        RegAddrZip: 49058,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '314 N HILLSDALE ST',
        RegAddrCity: 'HOMER',
        RegAddrState: 'MI',
        RegAddrZip: 49245,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '205 RUSSELL ST',
        RegAddrCity: 'HUBBARDSTON',
        RegAddrState: 'MI',
        RegAddrZip: 48845,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '320 E MAIN ST',
        RegAddrCity: 'IONIA',
        RegAddrState: 'MI',
        RegAddrZip: 48846,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '3133 COOPER RD',
        RegAddrCity: 'IONIA',
        RegAddrState: 'MI',
        RegAddrZip: 48846,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '615 PRAIRIE AVE',
        RegAddrCity: 'KALAMAZOO',
        RegAddrState: 'MI',
        RegAddrZip: 49006,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '737 5TH AVE',
        RegAddrCity: 'LAKE ODESSA',
        RegAddrState: 'MI',
        RegAddrZip: 48849,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '518 N JEFFERSON ST',
        RegAddrCity: 'LOWELL',
        RegAddrState: 'MI',
        RegAddrZip: 49331,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '2655 CASEY ROSE DR',
        RegAddrCity: 'LOWELL',
        RegAddrState: 'MI',
        RegAddrZip: 49331,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '540 EAST DR',
        RegAddrCity: 'MARSHALL',
        RegAddrState: 'MI',
        RegAddrZip: 49068,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1381 59TH ST',
        RegAddrCity: 'PULLMAN',
        RegAddrState: 'MI',
        RegAddrZip: 49450,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '6472 FOX RUN DR NE',
        RegAddrCity: 'ROCKFORD',
        RegAddrState: 'MI',
        RegAddrZip: 49341,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '7471 NORTHPORT AVE NE',
        RegAddrCity: 'ROCKFORD',
        RegAddrState: 'MI',
        RegAddrZip: 49341,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '3567 JACOBS CORNER RD NE',
        RegAddrCity: 'ROCKFORD',
        RegAddrState: 'MI',
        RegAddrZip: 49341,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '708 W HARMONY DR',
        RegAddrCity: 'SPARTA',
        RegAddrState: 'MI',
        RegAddrZip: 49345,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '12450 BAYVIEW DR',
        RegAddrCity: 'WAYLAND',
        RegAddrState: 'MI',
        RegAddrZip: 49348,
        CongressionalDistrict: 3,
      },
    ],
  },
  {
    name: 'MI-4',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/MI-4?ref=gh-pages',
    state: 'MI',
    state_and_number: 'MI-4',
    zip: 48414,
    congressionalDistrict: 4,
    addressesInDistrict: [
      {
        RegAddrLine1: '514 E ELIZABETH ST',
        RegAddrCity: 'ALMA',
        RegAddrState: 'MI',
        RegAddrZip: 48801,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '423 W CENTER ST',
        RegAddrCity: 'ALMA',
        RegAddrState: 'MI',
        RegAddrZip: 48801,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2366 LONG RD',
        RegAddrCity: 'BEAVERTON',
        RegAddrState: 'MI',
        RegAddrZip: 48612,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '14120 WILDWOOD DR',
        RegAddrCity: 'BIG RAPIDS',
        RegAddrState: 'MI',
        RegAddrZip: 49307,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '619 MAPLE ST',
        RegAddrCity: 'BIG RAPIDS',
        RegAddrState: 'MI',
        RegAddrZip: 49307,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '101 MARTINA ST',
        RegAddrCity: 'CADILLAC',
        RegAddrState: 'MI',
        RegAddrZip: 49601,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '7645 W 50TH RD',
        RegAddrCity: 'CADILLAC',
        RegAddrState: 'MI',
        RegAddrZip: 49601,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '211 N 1ST ST',
        RegAddrCity: 'CHESANING',
        RegAddrState: 'MI',
        RegAddrZip: 48616,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '8675 N WISE RD',
        RegAddrCity: 'CLARE',
        RegAddrState: 'MI',
        RegAddrZip: 48617,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2883 BEAMISH RD',
        RegAddrCity: 'COLEMAN',
        RegAddrState: 'MI',
        RegAddrZip: 48618,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '103 E WASHINGTON ST',
        RegAddrCity: 'COLEMAN',
        RegAddrState: 'MI',
        RegAddrZip: 48618,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '5547 S BYRON RD',
        RegAddrCity: 'DURAND',
        RegAddrState: 'MI',
        RegAddrZip: 48429,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '7003 E RIDGE RD',
        RegAddrCity: 'ELSIE',
        RegAddrState: 'MI',
        RegAddrZip: 48831,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '11023 N WHITEVILLE RD',
        RegAddrCity: 'FARWELL',
        RegAddrState: 'MI',
        RegAddrZip: 48622,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '520 GEYER ST',
        RegAddrCity: 'FRANKENMUTH',
        RegAddrState: 'MI',
        RegAddrZip: 48734,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '791 WREN RD',
        RegAddrCity: 'FRANKENMUTH',
        RegAddrState: 'MI',
        RegAddrZip: 48734,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '9622 MEADOWLARK CT',
        RegAddrCity: 'FREELAND',
        RegAddrState: 'MI',
        RegAddrZip: 48623,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '8707 CENTRAL PL',
        RegAddrCity: 'FREELAND',
        RegAddrState: 'MI',
        RegAddrZip: 48623,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1442 LAKEVIEW DR',
        RegAddrCity: 'GLADWIN',
        RegAddrState: 'MI',
        RegAddrZip: 48624,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '7813 FOREST VIEW DR',
        RegAddrCity: 'HASLETT',
        RegAddrState: 'MI',
        RegAddrZip: 48840,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '22952 190TH AVE',
        RegAddrCity: 'HERSEY',
        RegAddrState: 'MI',
        RegAddrZip: 49639,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '9412 CUTLER RD',
        RegAddrCity: 'LAINGSBURG',
        RegAddrState: 'MI',
        RegAddrZip: 48848,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '4717 W PHELPS RD',
        RegAddrCity: 'LAKE CITY',
        RegAddrState: 'MI',
        RegAddrZip: 49651,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '6950 W EATON HWY',
        RegAddrCity: 'LANSING',
        RegAddrState: 'MI',
        RegAddrZip: 48906,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '4659 S SAGINAW RD',
        RegAddrCity: 'MIDLAND',
        RegAddrState: 'MI',
        RegAddrZip: 48640,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1304 W STEWART RD',
        RegAddrCity: 'MIDLAND',
        RegAddrState: 'MI',
        RegAddrZip: 48640,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2490 S SMITH CROSSING RD',
        RegAddrCity: 'MIDLAND',
        RegAddrState: 'MI',
        RegAddrZip: 48640,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '5646 N JEFFERSON RD',
        RegAddrCity: 'MIDLAND',
        RegAddrState: 'MI',
        RegAddrZip: 48642,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2805 CANTERBURY DR',
        RegAddrCity: 'MIDLAND',
        RegAddrState: 'MI',
        RegAddrZip: 48642,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2102 WILMINGTON DR',
        RegAddrCity: 'MIDLAND',
        RegAddrState: 'MI',
        RegAddrZip: 48642,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '295 S BAMBER RD',
        RegAddrCity: 'MT PLEASANT',
        RegAddrState: 'MI',
        RegAddrZip: 48858,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1945 OSAUKIE RD',
        RegAddrCity: 'OWOSSO',
        RegAddrState: 'MI',
        RegAddrZip: 48867,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1215 N WASHINGTON ST',
        RegAddrCity: 'OWOSSO',
        RegAddrState: 'MI',
        RegAddrZip: 48867,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '19890 W KIMBALL RD',
        RegAddrCity: 'PIERSON',
        RegAddrState: 'MI',
        RegAddrZip: 49339,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '7794 N HARRISON RD',
        RegAddrCity: 'ROSCOMMON',
        RegAddrState: 'MI',
        RegAddrZip: 48653,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '23 LUMBERMEN WAY',
        RegAddrCity: 'SAGINAW',
        RegAddrState: 'MI',
        RegAddrZip: 48603,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '5932 QUAIL DR',
        RegAddrCity: 'SAINT HELEN',
        RegAddrState: 'MI',
        RegAddrZip: 48656,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '597 E SANFORD RD',
        RegAddrCity: 'SANFORD',
        RegAddrState: 'MI',
        RegAddrZip: 48657,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '6751 SENATOR RD',
        RegAddrCity: 'SHERIDAN',
        RegAddrState: 'MI',
        RegAddrZip: 48884,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '3089 S LAKE RD',
        RegAddrCity: 'SIDNEY',
        RegAddrState: 'MI',
        RegAddrZip: 48885,
        CongressionalDistrict: 4,
      },
    ],
  },
  {
    name: 'MI-5',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/MI-5?ref=gh-pages',
    state: 'MI',
    state_and_number: 'MI-5',
    zip: 48411,
    congressionalDistrict: 5,
    addressesInDistrict: [
      {
        RegAddrLine1: '4807 MONICA ST',
        RegAddrCity: 'AUBURN',
        RegAddrState: 'MI',
        RegAddrZip: 48611,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '2894 WESTWOOD DR',
        RegAddrCity: 'BAY CITY',
        RegAddrState: 'MI',
        RegAddrZip: 48706,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '1200 E SMITH ST',
        RegAddrCity: 'BAY CITY',
        RegAddrState: 'MI',
        RegAddrZip: 48706,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '27 WHEELER RD',
        RegAddrCity: 'BAY CITY',
        RegAddrState: 'MI',
        RegAddrZip: 48706,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '2332 WHEELER RD',
        RegAddrCity: 'BAY CITY',
        RegAddrState: 'MI',
        RegAddrZip: 48706,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '1993 RUSSELL RD',
        RegAddrCity: 'BAY CITY',
        RegAddrState: 'MI',
        RegAddrZip: 48708,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '14150 WEIR RD',
        RegAddrCity: 'CLIO',
        RegAddrState: 'MI',
        RegAddrZip: 48420,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '484 HARRISON DR',
        RegAddrCity: 'DAVISON',
        RegAddrState: 'MI',
        RegAddrZip: 48423,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '7251 N STATE RD',
        RegAddrCity: 'DAVISON',
        RegAddrState: 'MI',
        RegAddrZip: 48423,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '3814 FOLEY GLEN CIR',
        RegAddrCity: 'FENTON',
        RegAddrState: 'MI',
        RegAddrZip: 48430,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '1906 MAGNOLIA AVE',
        RegAddrCity: 'FLINT',
        RegAddrState: 'MI',
        RegAddrZip: 48503,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '1910 CAMBRIDGE AVE',
        RegAddrCity: 'FLINT',
        RegAddrState: 'MI',
        RegAddrZip: 48503,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '3111 CORUNNA RD',
        RegAddrCity: 'FLINT',
        RegAddrState: 'MI',
        RegAddrZip: 48503,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '1501 DUPONT ST',
        RegAddrCity: 'FLINT',
        RegAddrState: 'MI',
        RegAddrZip: 48504,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: 'G5486 DETROIT ST',
        RegAddrCity: 'FLINT',
        RegAddrState: 'MI',
        RegAddrZip: 48505,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '638 W ALMA AVE',
        RegAddrCity: 'FLINT',
        RegAddrState: 'MI',
        RegAddrZip: 48505,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '1418 BELLE AVE',
        RegAddrCity: 'FLINT',
        RegAddrState: 'MI',
        RegAddrZip: 48506,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '3244 HUGGINS AVE',
        RegAddrCity: 'FLINT',
        RegAddrState: 'MI',
        RegAddrZip: 48506,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '4146 BRISTOLWOOD DR',
        RegAddrCity: 'FLINT',
        RegAddrState: 'MI',
        RegAddrZip: 48507,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '5056 DELAND RD',
        RegAddrCity: 'FLUSHING',
        RegAddrState: 'MI',
        RegAddrZip: 48433,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '9135 HIDDEN OAKS DR',
        RegAddrCity: 'GRAND BLANC',
        RegAddrState: 'MI',
        RegAddrZip: 48439,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '8390 N LINDEN RD',
        RegAddrCity: 'MOUNT MORRIS',
        RegAddrState: 'MI',
        RegAddrZip: 48458,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '1233 E FRANCES RD',
        RegAddrCity: 'MOUNT MORRIS',
        RegAddrState: 'MI',
        RegAddrZip: 48458,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '1407 FLORENCE ST',
        RegAddrCity: 'NATIONAL CITY',
        RegAddrState: 'MI',
        RegAddrZip: 48748,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '3433 WOODLAND CT',
        RegAddrCity: 'SAGINAW',
        RegAddrState: 'MI',
        RegAddrZip: 48601,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '5260 INDIANTOWN RD',
        RegAddrCity: 'SAGINAW',
        RegAddrState: 'MI',
        RegAddrZip: 48601,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '923 N GRANGER ST',
        RegAddrCity: 'SAGINAW',
        RegAddrState: 'MI',
        RegAddrZip: 48602,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '1835 JORDAN ST',
        RegAddrCity: 'SAGINAW',
        RegAddrState: 'MI',
        RegAddrZip: 48602,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '3042 MONROE ST',
        RegAddrCity: 'SAGINAW',
        RegAddrState: 'MI',
        RegAddrZip: 48604,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '818 NYLON ST',
        RegAddrCity: 'SAGINAW',
        RegAddrState: 'MI',
        RegAddrZip: 48604,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '5985 SWAN CREEK RD',
        RegAddrCity: 'SAGINAW',
        RegAddrState: 'MI',
        RegAddrZip: 48609,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '306 CONRAD RD',
        RegAddrCity: 'STANDISH',
        RegAddrState: 'MI',
        RegAddrZip: 48658,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '741 CONRAD RD',
        RegAddrCity: 'STANDISH',
        RegAddrState: 'MI',
        RegAddrZip: 48658,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '5516 LINDEN RD',
        RegAddrCity: 'SWARTZ CREEK',
        RegAddrState: 'MI',
        RegAddrZip: 48473,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '7180 SHARP RD',
        RegAddrCity: 'SWARTZ CREEK',
        RegAddrState: 'MI',
        RegAddrZip: 48473,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '4460 SOYA AVE',
        RegAddrCity: 'SWARTZ CREEK',
        RegAddrState: 'MI',
        RegAddrZip: 48473,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '8037 REID RD',
        RegAddrCity: 'SWARTZ CREEK',
        RegAddrState: 'MI',
        RegAddrZip: 48473,
        CongressionalDistrict: 5,
      },
    ],
  },
  {
    name: 'MI-6',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/MI-6?ref=gh-pages',
    state: 'MI',
    state_and_number: 'MI-6',
    zip: 49001,
    congressionalDistrict: 6,
    addressesInDistrict: [
      {
        RegAddrLine1: '108 LINCOLN AVE',
        RegAddrCity: 'BANGOR',
        RegAddrState: 'MI',
        RegAddrZip: 49013,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '343 MONTEZUMA RD',
        RegAddrCity: 'BENTON HARBOR',
        RegAddrState: 'MI',
        RegAddrZip: 49022,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '350 BROWNWAY',
        RegAddrCity: 'BENTON HARBOR',
        RegAddrState: 'MI',
        RegAddrZip: 49022,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '64928 LUTZ RD',
        RegAddrCity: 'CONSTANTINE',
        RegAddrState: 'MI',
        RegAddrZip: 49042,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '62365 CONSTANTINE RD',
        RegAddrCity: 'CONSTANTINE',
        RegAddrState: 'MI',
        RegAddrZip: 49042,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '4228 WATSON RD',
        RegAddrCity: 'EAU CLAIRE',
        RegAddrState: 'MI',
        RegAddrZip: 49111,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '213 S HAVER ST',
        RegAddrCity: 'HARTFORD',
        RegAddrState: 'MI',
        RegAddrZip: 49057,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '2201 DORCHESTER AVE',
        RegAddrCity: 'KALAMAZOO',
        RegAddrState: 'MI',
        RegAddrZip: 49001,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '2718 CORLOT ST',
        RegAddrCity: 'KALAMAZOO',
        RegAddrState: 'MI',
        RegAddrZip: 49004,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '1925 RING RD N',
        RegAddrCity: 'KALAMAZOO',
        RegAddrState: 'MI',
        RegAddrZip: 49008,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '225 DRAPER HALL',
        RegAddrCity: 'KALAMAZOO',
        RegAddrState: 'MI',
        RegAddrZip: 49008,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '1519 S VANKAL ST',
        RegAddrCity: 'KALAMAZOO',
        RegAddrState: 'MI',
        RegAddrZip: 49009,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '196 W CROOKED LAKE DR',
        RegAddrCity: 'KALAMAZOO',
        RegAddrState: 'MI',
        RegAddrZip: 49009,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '1014 FOSTER AVE',
        RegAddrCity: 'KALAMAZOO',
        RegAddrState: 'MI',
        RegAddrZip: 49048,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '200 S CHICAGO ST',
        RegAddrCity: 'NEW BUFFALO',
        RegAddrState: 'MI',
        RegAddrZip: 49117,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '1210 JEROME ST',
        RegAddrCity: 'NILES',
        RegAddrState: 'MI',
        RegAddrZip: 49120,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '617 S LINCOLN AVE',
        RegAddrCity: 'NILES',
        RegAddrState: 'MI',
        RegAddrZip: 49120,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '1604 MAPLE ST',
        RegAddrCity: 'NILES',
        RegAddrState: 'MI',
        RegAddrZip: 49120,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '34885 38TH AVE',
        RegAddrCity: 'PAW PAW',
        RegAddrState: 'MI',
        RegAddrZip: 49079,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '494 105TH AVE',
        RegAddrCity: 'PLAINWELL',
        RegAddrState: 'MI',
        RegAddrZip: 49080,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '2408 HEMLOCK AVE',
        RegAddrCity: 'PORTAGE',
        RegAddrState: 'MI',
        RegAddrZip: 49024,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '5770 SPRINGRIDGE ST',
        RegAddrCity: 'PORTAGE',
        RegAddrState: 'MI',
        RegAddrZip: 49024,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '7751 INNISBROOK DR',
        RegAddrCity: 'PORTAGE',
        RegAddrState: 'MI',
        RegAddrZip: 49024,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '741 56TH ST',
        RegAddrCity: 'PULLMAN',
        RegAddrState: 'MI',
        RegAddrZip: 49450,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '690 JEWELL RD',
        RegAddrCity: 'SAINT JOSEPH',
        RegAddrState: 'MI',
        RegAddrZip: 49085,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '283 MARINA DR',
        RegAddrCity: 'SAINT JOSEPH',
        RegAddrState: 'MI',
        RegAddrZip: 49085,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '1022 MICHIGAN AVE',
        RegAddrCity: 'SAINT JOSEPH',
        RegAddrState: 'MI',
        RegAddrZip: 49085,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '3446 HOLLAND ST',
        RegAddrCity: 'SAUGATUCK',
        RegAddrState: 'MI',
        RegAddrZip: 49453,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '14300 S 4TH ST',
        RegAddrCity: 'SCHOOLCRAFT',
        RegAddrState: 'MI',
        RegAddrZip: 49087,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '1064 HAZEL ST',
        RegAddrCity: 'SOUTH HAVEN',
        RegAddrState: 'MI',
        RegAddrZip: 49090,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '7316 101ST AVE',
        RegAddrCity: 'SOUTH HAVEN',
        RegAddrState: 'MI',
        RegAddrZip: 49090,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '25526 FAWN RIVER RD',
        RegAddrCity: 'STURGIS',
        RegAddrState: 'MI',
        RegAddrZip: 49091,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '414 5TH AVE',
        RegAddrCity: 'THREE RIVERS',
        RegAddrState: 'MI',
        RegAddrZip: 49093,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '14551 M 216',
        RegAddrCity: 'THREE RIVERS',
        RegAddrState: 'MI',
        RegAddrZip: 49093,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '12368 SPRINKLE RD',
        RegAddrCity: 'VICKSBURG',
        RegAddrState: 'MI',
        RegAddrZip: 49097,
        CongressionalDistrict: 6,
      },
    ],
  },
  {
    name: 'MI-7',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/MI-7?ref=gh-pages',
    state: 'MI',
    state_and_number: 'MI-7',
    zip: 48103,
    congressionalDistrict: 7,
    addressesInDistrict: [
      {
        RegAddrLine1: '4760 FORRISTER RD',
        RegAddrCity: 'ADRIAN',
        RegAddrState: 'MI',
        RegAddrZip: 49221,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '808 E MAUMEE ST',
        RegAddrCity: 'ADRIAN',
        RegAddrState: 'MI',
        RegAddrZip: 49221,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '2139 HAROLD ST',
        RegAddrCity: 'ADRIAN',
        RegAddrState: 'MI',
        RegAddrZip: 49221,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '6051 W CHICAGO RD',
        RegAddrCity: 'ALLEN',
        RegAddrState: 'MI',
        RegAddrZip: 49227,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '1066 SILER DR',
        RegAddrCity: 'BRONSON',
        RegAddrState: 'MI',
        RegAddrZip: 49028,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '9700 HAWKINS HWY',
        RegAddrCity: 'BROOKLYN',
        RegAddrState: 'MI',
        RegAddrZip: 49230,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '1564 RABBIT RDG',
        RegAddrCity: 'CHARLOTTE',
        RegAddrState: 'MI',
        RegAddrZip: 48813,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '3814 QUEEN OAKS DR',
        RegAddrCity: 'CHELSEA',
        RegAddrState: 'MI',
        RegAddrZip: 48118,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '3111 JEANETTE DR',
        RegAddrCity: 'CHELSEA',
        RegAddrState: 'MI',
        RegAddrZip: 48118,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '292 NASH RD',
        RegAddrCity: 'COLDWATER',
        RegAddrState: 'MI',
        RegAddrZip: 49036,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '609 DORRANCE RD',
        RegAddrCity: 'COLDWATER',
        RegAddrState: 'MI',
        RegAddrZip: 49036,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '4655 S COUNTY LINE HWY',
        RegAddrCity: 'DEERFIELD',
        RegAddrState: 'MI',
        RegAddrZip: 49238,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '3622 N DANCER RD',
        RegAddrCity: 'DEXTER',
        RegAddrState: 'MI',
        RegAddrZip: 48130,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '9401 TRINKLE RD',
        RegAddrCity: 'DEXTER',
        RegAddrState: 'MI',
        RegAddrZip: 48130,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '6107 HOLMES HWY',
        RegAddrCity: 'EATON RAPIDS',
        RegAddrState: 'MI',
        RegAddrZip: 48827,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '5898 E BELLEVUE HWY',
        RegAddrCity: 'EATON RAPIDS',
        RegAddrState: 'MI',
        RegAddrZip: 48827,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '12675 W GREENFIELD RD',
        RegAddrCity: 'GRAND LEDGE',
        RegAddrState: 'MI',
        RegAddrZip: 48837,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '204 QUEENS CT',
        RegAddrCity: 'GRAND LEDGE',
        RegAddrState: 'MI',
        RegAddrZip: 48837,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '6193 E SAINT JOE HWY',
        RegAddrCity: 'GRAND LEDGE',
        RegAddrState: 'MI',
        RegAddrZip: 48837,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '521 MAITLAND DR',
        RegAddrCity: 'HORTON',
        RegAddrState: 'MI',
        RegAddrZip: 49246,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '3800 HANOVER RD',
        RegAddrCity: 'HORTON',
        RegAddrState: 'MI',
        RegAddrZip: 49246,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '6421 LEWIS AVE',
        RegAddrCity: 'IDA',
        RegAddrState: 'MI',
        RegAddrZip: 48140,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '7631 NAPOLEON RD',
        RegAddrCity: 'JACKSON',
        RegAddrState: 'MI',
        RegAddrZip: 49201,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '215 E ARGYLE ST',
        RegAddrCity: 'JACKSON',
        RegAddrState: 'MI',
        RegAddrZip: 49202,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '115 1/2 WALL ST',
        RegAddrCity: 'JACKSON',
        RegAddrState: 'MI',
        RegAddrZip: 49203,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '7637 TREAT HWY',
        RegAddrCity: 'JASPER',
        RegAddrState: 'MI',
        RegAddrZip: 49248,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '10125 SHARON HOLLOW RD',
        RegAddrCity: 'MANCHESTER',
        RegAddrState: 'MI',
        RegAddrZip: 48158,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '10163 FAETANO LN',
        RegAddrCity: 'MILAN',
        RegAddrState: 'MI',
        RegAddrZip: 48160,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '418 W 8TH ST',
        RegAddrCity: 'MONROE',
        RegAddrState: 'MI',
        RegAddrZip: 48161,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '405 THEODORE DR',
        RegAddrCity: 'MONROE',
        RegAddrState: 'MI',
        RegAddrZip: 48162,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '1910 LASALLE RD',
        RegAddrCity: 'MONROE',
        RegAddrState: 'MI',
        RegAddrZip: 48162,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '18350 TODD RD',
        RegAddrCity: 'PETERSBURG',
        RegAddrState: 'MI',
        RegAddrZip: 49270,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '2412 HAWTHORNE WAY',
        RegAddrCity: 'SALINE',
        RegAddrState: 'MI',
        RegAddrZip: 48176,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '689 BABCOCK RD',
        RegAddrCity: 'SHERWOOD',
        RegAddrState: 'MI',
        RegAddrZip: 49089,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '10135 JACKMAN RD',
        RegAddrCity: 'TEMPERANCE',
        RegAddrState: 'MI',
        RegAddrZip: 48182,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '278 HEATHER',
        RegAddrCity: 'TEMPERANCE',
        RegAddrState: 'MI',
        RegAddrZip: 48182,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '126 BARRY ST',
        RegAddrCity: 'UNION CITY',
        RegAddrState: 'MI',
        RegAddrZip: 49094,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '21535 MAYFAIR ST',
        RegAddrCity: 'WOODHAVEN',
        RegAddrState: 'MI',
        RegAddrZip: 48183,
        CongressionalDistrict: 7,
      },
    ],
  },
  {
    name: 'MI-8',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/MI-8?ref=gh-pages',
    state: 'MI',
    state_and_number: 'MI-8',
    zip: 48114,
    congressionalDistrict: 8,
    addressesInDistrict: [
      {
        RegAddrLine1: '2932 HIDEAWAY BEACH DR',
        RegAddrCity: 'BRIGHTON',
        RegAddrState: 'MI',
        RegAddrZip: 48114,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '196 ASHFORD ST',
        RegAddrCity: 'BRIGHTON',
        RegAddrState: 'MI',
        RegAddrZip: 48114,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '307 S 2ND ST',
        RegAddrCity: 'BRIGHTON',
        RegAddrState: 'MI',
        RegAddrZip: 48116,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '6986 RATTALEE LAKE RD',
        RegAddrCity: 'CLARKSTON',
        RegAddrState: 'MI',
        RegAddrZip: 48348,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '5234 FOREST VALLEY DR',
        RegAddrCity: 'CLARKSTON',
        RegAddrState: 'MI',
        RegAddrZip: 48348,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '8025 E CIRCLE DR',
        RegAddrCity: 'CLARKSTON',
        RegAddrState: 'MI',
        RegAddrZip: 48348,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '16629 DIXIE HWY',
        RegAddrCity: 'DAVISBURG',
        RegAddrState: 'MI',
        RegAddrZip: 48350,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '1525 ROXBURGH AVE',
        RegAddrCity: 'EAST LANSING',
        RegAddrState: 'MI',
        RegAddrZip: 48823,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '638 VIRGINIA AVE',
        RegAddrCity: 'EAST LANSING',
        RegAddrState: 'MI',
        RegAddrZip: 48823,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '1134 SOUTHLAWN AVE',
        RegAddrCity: 'EAST LANSING',
        RegAddrState: 'MI',
        RegAddrZip: 48823,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '5291 HOGBACK RD',
        RegAddrCity: 'FOWLERVILLE',
        RegAddrState: 'MI',
        RegAddrZip: 48836,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '5816 BEUNA PKWY',
        RegAddrCity: 'HASLETT',
        RegAddrState: 'MI',
        RegAddrZip: 48840,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '16145 LANCASTER WAY',
        RegAddrCity: 'HOLLY',
        RegAddrState: 'MI',
        RegAddrZip: 48442,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '3262 HERRINGTON DR',
        RegAddrCity: 'HOLLY',
        RegAddrState: 'MI',
        RegAddrZip: 48442,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '5515 POCASSETT WAY',
        RegAddrCity: 'HOLT',
        RegAddrState: 'MI',
        RegAddrZip: 48842,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '4110 BROOKSTONE CT',
        RegAddrCity: 'HOWELL',
        RegAddrState: 'MI',
        RegAddrZip: 48843,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '3836 SUGARBUSH DR',
        RegAddrCity: 'HOWELL',
        RegAddrState: 'MI',
        RegAddrZip: 48843,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '4135 INDIAN CAMP TRL',
        RegAddrCity: 'HOWELL',
        RegAddrState: 'MI',
        RegAddrZip: 48855,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '1824 GILMORE ST',
        RegAddrCity: 'HOWELL',
        RegAddrState: 'MI',
        RegAddrZip: 48855,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '2150 CURDY RD',
        RegAddrCity: 'HOWELL',
        RegAddrState: 'MI',
        RegAddrZip: 48855,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '1603 DUCK DR',
        RegAddrCity: 'HOWELL',
        RegAddrState: 'MI',
        RegAddrZip: 48855,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '2730 GEMINI DR',
        RegAddrCity: 'LAKE ORION',
        RegAddrState: 'MI',
        RegAddrZip: 48360,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '921 ABSEGUAMI TRL',
        RegAddrCity: 'LAKE ORION',
        RegAddrState: 'MI',
        RegAddrZip: 48362,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '1235 ELKHORN LAKE RD',
        RegAddrCity: 'LAKE ORION',
        RegAddrState: 'MI',
        RegAddrZip: 48362,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '2917 ANDREW AVE',
        RegAddrCity: 'LANSING',
        RegAddrState: 'MI',
        RegAddrZip: 48906,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '4824 DUVERNAY DR',
        RegAddrCity: 'LANSING',
        RegAddrState: 'MI',
        RegAddrZip: 48910,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '4506 CHRISTIANSEN RD',
        RegAddrCity: 'LANSING',
        RegAddrState: 'MI',
        RegAddrZip: 48910,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '503 S DEXTER DR',
        RegAddrCity: 'LANSING',
        RegAddrState: 'MI',
        RegAddrZip: 48910,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '1201 REO RD',
        RegAddrCity: 'LANSING',
        RegAddrState: 'MI',
        RegAddrZip: 48910,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '1012 COMFORT ST',
        RegAddrCity: 'LANSING',
        RegAddrState: 'MI',
        RegAddrZip: 48915,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '5350 STATE RD',
        RegAddrCity: 'LESLIE',
        RegAddrState: 'MI',
        RegAddrZip: 49251,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '1195 CLAYTON CT',
        RegAddrCity: 'MASON',
        RegAddrState: 'MI',
        RegAddrZip: 48854,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '1334 STATE PARK RD',
        RegAddrCity: 'ORTONVILLE',
        RegAddrState: 'MI',
        RegAddrZip: 48462,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '493 EDWARD ST',
        RegAddrCity: 'ORTONVILLE',
        RegAddrState: 'MI',
        RegAddrZip: 48462,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '1212 OAKMONT DR',
        RegAddrCity: 'OXFORD',
        RegAddrState: 'MI',
        RegAddrZip: 48371,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '1410 OAKRIDGE DR',
        RegAddrCity: 'ROCHESTER HLS',
        RegAddrState: 'MI',
        RegAddrZip: 48307,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '1076 HACKBERRY CT',
        RegAddrCity: 'ROCHESTER HLS',
        RegAddrState: 'MI',
        RegAddrZip: 48309,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '10025 PHEASANT LAKE RD',
        RegAddrCity: 'SOUTH LYON',
        RegAddrState: 'MI',
        RegAddrZip: 48178,
        CongressionalDistrict: 8,
      },
    ],
  },
  {
    name: 'MI-9',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/MI-9?ref=gh-pages',
    state: 'MI',
    state_and_number: 'MI-9',
    zip: 48009,
    congressionalDistrict: 9,
    addressesInDistrict: [
      {
        RegAddrLine1: '350 S GLENGARRY RD',
        RegAddrCity: 'BLOOMFLD HLS',
        RegAddrState: 'MI',
        RegAddrZip: 48301,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '1400 INWOODS CIR',
        RegAddrCity: 'BLOOMFLD HLS',
        RegAddrState: 'MI',
        RegAddrZip: 48302,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '2820 MACKINTOSH LN',
        RegAddrCity: 'BLOOMFLD HLS',
        RegAddrState: 'MI',
        RegAddrZip: 48302,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '753 FOX RIVER DR',
        RegAddrCity: 'BLOOMFLD HLS',
        RegAddrState: 'MI',
        RegAddrZip: 48304,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '24246 PRIMROSE CT',
        RegAddrCity: 'CLINTON TOWNSHIP',
        RegAddrState: 'MI',
        RegAddrZip: 48036,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '20242 ELECTRA ST',
        RegAddrCity: 'CLINTON TWP',
        RegAddrState: 'MI',
        RegAddrZip: 48035,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '16518 FESTIAN DR',
        RegAddrCity: 'CLINTON TWP',
        RegAddrState: 'MI',
        RegAddrZip: 48035,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '23550 DEANHURST ST',
        RegAddrCity: 'CLINTON TWP',
        RegAddrState: 'MI',
        RegAddrZip: 48035,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '16821 E 8 MILE RD',
        RegAddrCity: 'EASTPOINTE',
        RegAddrState: 'MI',
        RegAddrZip: 48021,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '18150 JULIANA AVE',
        RegAddrCity: 'EASTPOINTE',
        RegAddrState: 'MI',
        RegAddrZip: 48021,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '10425 NADINE AVE',
        RegAddrCity: 'HUNTINGTN WDS',
        RegAddrState: 'MI',
        RegAddrZip: 48070,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '12708 BORGMAN AVE',
        RegAddrCity: 'HUNTINGTN WDS',
        RegAddrState: 'MI',
        RegAddrZip: 48070,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '27096 PALMER BLVD',
        RegAddrCity: 'MADISON HTS',
        RegAddrState: 'MI',
        RegAddrZip: 48071,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '30759 ALGER BLVD',
        RegAddrCity: 'MADISON HTS',
        RegAddrState: 'MI',
        RegAddrZip: 48071,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '27736 BRUSH ST',
        RegAddrCity: 'MADISON HTS',
        RegAddrState: 'MI',
        RegAddrZip: 48071,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '130 S WILSON BLVD',
        RegAddrCity: 'MOUNT CLEMENS',
        RegAddrState: 'MI',
        RegAddrZip: 48043,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '163 CROCKER BLVD',
        RegAddrCity: 'MOUNT CLEMENS',
        RegAddrState: 'MI',
        RegAddrZip: 48043,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '26210 MARLENE ST',
        RegAddrCity: 'ROSEVILLE',
        RegAddrState: 'MI',
        RegAddrZip: 48066,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '18845 VICTOR AVE',
        RegAddrCity: 'ROSEVILLE',
        RegAddrState: 'MI',
        RegAddrZip: 48066,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '16663 ROBERTS ST',
        RegAddrCity: 'ROSEVILLE',
        RegAddrState: 'MI',
        RegAddrZip: 48066,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '17813 PETERS ST',
        RegAddrCity: 'ROSEVILLE',
        RegAddrState: 'MI',
        RegAddrZip: 48066,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '1818 W 12 MILE RD',
        RegAddrCity: 'ROYAL OAK',
        RegAddrState: 'MI',
        RegAddrZip: 48073,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '1902 BROOKWOOD AVE',
        RegAddrCity: 'ROYAL OAK',
        RegAddrState: 'MI',
        RegAddrZip: 48073,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '22709 FURTON ST',
        RegAddrCity: 'SAINT CLAIR SHORES',
        RegAddrState: 'MI',
        RegAddrZip: 48082,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '23330 NORCREST DR',
        RegAddrCity: 'ST CLR SHORES',
        RegAddrState: 'MI',
        RegAddrZip: 48080,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '34842 MORAVIAN DR',
        RegAddrCity: 'STERLING HEIGHTS',
        RegAddrState: 'MI',
        RegAddrZip: 48312,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '12099 CORVAIR DR',
        RegAddrCity: 'STERLING HTS',
        RegAddrState: 'MI',
        RegAddrZip: 48312,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '3824 MAPLE ST',
        RegAddrCity: 'WARREN',
        RegAddrState: 'MI',
        RegAddrZip: 48091,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '2103 GOULSON AVE',
        RegAddrCity: 'WARREN',
        RegAddrState: 'MI',
        RegAddrZip: 48091,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '3665 STEPHENS RD',
        RegAddrCity: 'WARREN',
        RegAddrState: 'MI',
        RegAddrZip: 48091,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '21022 PANAMA ST',
        RegAddrCity: 'WARREN',
        RegAddrState: 'MI',
        RegAddrZip: 48091,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '3259 E 11 MILE RD',
        RegAddrCity: 'WARREN',
        RegAddrState: 'MI',
        RegAddrZip: 48092,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '30044 BLANCKE DR',
        RegAddrCity: 'WARREN',
        RegAddrState: 'MI',
        RegAddrZip: 48093,
        CongressionalDistrict: 9,
      },
    ],
  },
  {
    name: 'MN-1',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/MN-1?ref=gh-pages',
    state: 'MN',
    state_and_number: 'MN-1',
    zip: 55019,
    congressionalDistrict: 1,
    addressesInDistrict: [
      {
        RegAddrLine1: '818 MINNIE MADDERN ST',
        RegAddrCity: 'ALBERT LEA',
        RegAddrState: 'MN',
        RegAddrZip: 56007,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1423 TODD AVE',
        RegAddrCity: 'ALBERT LEA',
        RegAddrState: 'MN',
        RegAddrZip: 56007,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1401 16TH AVE SW',
        RegAddrCity: 'AUSTIN',
        RegAddrState: 'MN',
        RegAddrZip: 55912,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '3705 6TH PL SW',
        RegAddrCity: 'AUSTIN',
        RegAddrState: 'MN',
        RegAddrZip: 55912,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '404 20TH ST SW',
        RegAddrCity: 'AUSTIN',
        RegAddrState: 'MN',
        RegAddrZip: 55912,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '17734 570TH AVE',
        RegAddrCity: 'AUSTIN',
        RegAddrState: 'MN',
        RegAddrZip: 55912,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1901 12TH ST SW',
        RegAddrCity: 'AUSTIN',
        RegAddrState: 'MN',
        RegAddrZip: 55912,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '468 PLEASANT ST SW',
        RegAddrCity: 'CHATFIELD',
        RegAddrState: 'MN',
        RegAddrZip: 55923,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '470 RIVER ST',
        RegAddrCity: 'DAKOTA',
        RegAddrState: 'MN',
        RegAddrZip: 55925,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '9183 COUNTY 2',
        RegAddrCity: 'EITZEN',
        RegAddrState: 'MN',
        RegAddrZip: 55931,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '12351 COUNTY ROAD 3',
        RegAddrCity: 'ELLENDALE',
        RegAddrState: 'MN',
        RegAddrZip: 56026,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '3715 CEDAR CREEK CT',
        RegAddrCity: 'FAIRMONT',
        RegAddrState: 'MN',
        RegAddrZip: 56031,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '956 RICE ST',
        RegAddrCity: 'FARIBAULT',
        RegAddrState: 'MN',
        RegAddrZip: 55021,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '18146 FARIBAULT BLVD',
        RegAddrCity: 'FARIBAULT',
        RegAddrState: 'MN',
        RegAddrZip: 55021,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '6990 263RD ST E',
        RegAddrCity: 'FARIBAULT',
        RegAddrState: 'MN',
        RegAddrZip: 55021,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '710 LAKESHORE DR',
        RegAddrCity: 'FREEBORN',
        RegAddrState: 'MN',
        RegAddrZip: 56032,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '307 REYNOLDS ST',
        RegAddrCity: 'GRANADA',
        RegAddrState: 'MN',
        RegAddrZip: 56039,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '202 MAIN ST',
        RegAddrCity: 'HAYWARD',
        RegAddrState: 'MN',
        RegAddrZip: 56043,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '13686 STATE 16',
        RegAddrCity: 'HOUSTON',
        RegAddrState: 'MN',
        RegAddrZip: 55943,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '27621 615TH ST',
        RegAddrCity: 'KASSON',
        RegAddrState: 'MN',
        RegAddrZip: 55944,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '991 TOWN HALL RD',
        RegAddrCity: 'LA CRESCENT',
        RegAddrState: 'MN',
        RegAddrZip: 55947,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '406 S OAKLAND ST',
        RegAddrCity: 'LAKE CRYSTAL',
        RegAddrState: 'MN',
        RegAddrZip: 56055,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '34067 220TH ST',
        RegAddrCity: 'LANESBORO',
        RegAddrState: 'MN',
        RegAddrZip: 55949,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '604 S 4TH ST',
        RegAddrCity: 'LE SUEUR',
        RegAddrState: 'MN',
        RegAddrZip: 56058,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '27297 COUNTY ROAD 25',
        RegAddrCity: 'LEWISTON',
        RegAddrState: 'MN',
        RegAddrZip: 55952,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '4905 BEST ST',
        RegAddrCity: 'LONSDALE',
        RegAddrState: 'MN',
        RegAddrZip: 55046,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '501 JAMES ST',
        RegAddrCity: 'LUVERNE',
        RegAddrState: 'MN',
        RegAddrZip: 56156,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '800 N OAKLEY ST',
        RegAddrCity: 'LUVERNE',
        RegAddrState: 'MN',
        RegAddrZip: 56156,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '15 COTTAGE PATH',
        RegAddrCity: 'MANKATO',
        RegAddrState: 'MN',
        RegAddrZip: 56001,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '304 THRO AVE',
        RegAddrCity: 'MANKATO',
        RegAddrState: 'MN',
        RegAddrZip: 56001,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '718 MOUND AVE',
        RegAddrCity: 'MANKATO',
        RegAddrState: 'MN',
        RegAddrZip: 56001,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '327 N BELMONT DR',
        RegAddrCity: 'MANKATO',
        RegAddrState: 'MN',
        RegAddrZip: 56001,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '55370 201ST LN',
        RegAddrCity: 'MANKATO',
        RegAddrState: 'MN',
        RegAddrZip: 56001,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '803 WALNUT ST',
        RegAddrCity: 'MANTORVILLE',
        RegAddrState: 'MN',
        RegAddrZip: 55955,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '223 8TH AVE NE',
        RegAddrCity: 'MAPLETON',
        RegAddrState: 'MN',
        RegAddrZip: 56065,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '20729 170TH AVE',
        RegAddrCity: 'NEW ULM',
        RegAddrState: 'MN',
        RegAddrZip: 56073,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '17796 COUNTY ROAD 27',
        RegAddrCity: 'NEW ULM',
        RegAddrState: 'MN',
        RegAddrZip: 56073,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '23823 185TH AVE',
        RegAddrCity: 'NEW ULM',
        RegAddrState: 'MN',
        RegAddrZip: 56073,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '228 GARFIELD AVE',
        RegAddrCity: 'NORTH MANKATO',
        RegAddrState: 'MN',
        RegAddrZip: 56003,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1532 JAMES DR',
        RegAddrCity: 'NORTH MANKATO',
        RegAddrState: 'MN',
        RegAddrZip: 56003,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '51906 421ST AVE',
        RegAddrCity: 'NORTH MANKATO',
        RegAddrState: 'MN',
        RegAddrZip: 56003,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1073 FISHERMAN DR NW',
        RegAddrCity: 'ORONOCO',
        RegAddrState: 'MN',
        RegAddrZip: 55960,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '633 13TH ST SE',
        RegAddrCity: 'OWATONNA',
        RegAddrState: 'MN',
        RegAddrZip: 55060,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '445 W MCKINLEY ST',
        RegAddrCity: 'OWATONNA',
        RegAddrState: 'MN',
        RegAddrZip: 55060,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2260 1ST AVE NE',
        RegAddrCity: 'OWATONNA',
        RegAddrState: 'MN',
        RegAddrZip: 55060,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2627 TUXEDO LN NW',
        RegAddrCity: 'ROCHESTER',
        RegAddrState: 'MN',
        RegAddrZip: 55901,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1429 20TH ST NW',
        RegAddrCity: 'ROCHESTER',
        RegAddrState: 'MN',
        RegAddrZip: 55901,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '6066 51ST ST NW',
        RegAddrCity: 'ROCHESTER',
        RegAddrState: 'MN',
        RegAddrZip: 55901,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1946 OLD VALLEY RD SW',
        RegAddrCity: 'ROCHESTER',
        RegAddrState: 'MN',
        RegAddrZip: 55902,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2330 18TH AVE SW',
        RegAddrCity: 'ROCHESTER',
        RegAddrState: 'MN',
        RegAddrZip: 55902,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1320 1ST ST SE',
        RegAddrCity: 'ROCHESTER',
        RegAddrState: 'MN',
        RegAddrZip: 55904,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1118 5TH AVE SE',
        RegAddrCity: 'ROCHESTER',
        RegAddrState: 'MN',
        RegAddrZip: 55904,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '3724 STONE POINT DR NE',
        RegAddrCity: 'ROCHESTER',
        RegAddrState: 'MN',
        RegAddrZip: 55906,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '187 MAIN ST',
        RegAddrCity: 'ROLLINGSTONE',
        RegAddrState: 'MN',
        RegAddrZip: 55969,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '208 N MANYASKA ST',
        RegAddrCity: 'SHERBURN',
        RegAddrState: 'MN',
        RegAddrZip: 56171,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '10007 FRISBY DR',
        RegAddrCity: 'ST CHARLES',
        RegAddrState: 'MN',
        RegAddrZip: 55972,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2200 MOCKINGBIRD PL NW',
        RegAddrCity: 'STEWARTVILLE',
        RegAddrState: 'MN',
        RegAddrZip: 55976,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '804 GRANDVIEW CT SE',
        RegAddrCity: 'STEWARTVILLE',
        RegAddrState: 'MN',
        RegAddrZip: 55976,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '405 PAQUIN ST E',
        RegAddrCity: 'WATERVILLE',
        RegAddrState: 'MN',
        RegAddrZip: 56096,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '477 1ST AVE SE',
        RegAddrCity: 'WELLS',
        RegAddrState: 'MN',
        RegAddrZip: 56097,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '53806 STATE HIGHWAY 57',
        RegAddrCity: 'WEST CONCORD',
        RegAddrState: 'MN',
        RegAddrZip: 55985,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '12290 HESSELROTH AVE',
        RegAddrCity: 'WILMONT',
        RegAddrState: 'MN',
        RegAddrZip: 56185,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '49882 COUNTY ROAD 17',
        RegAddrCity: 'WINDOM',
        RegAddrState: 'MN',
        RegAddrZip: 56101,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '706 E 2ND ST',
        RegAddrCity: 'WINONA',
        RegAddrState: 'MN',
        RegAddrZip: 55987,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1285 WINCREST DR',
        RegAddrCity: 'WINONA',
        RegAddrState: 'MN',
        RegAddrZip: 55987,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '27299 COUNTY HIGHWAY 35',
        RegAddrCity: 'WORTHINGTON',
        RegAddrState: 'MN',
        RegAddrZip: 56187,
        CongressionalDistrict: 1,
      },
    ],
  },
  {
    name: 'MN-2',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/MN-2?ref=gh-pages',
    state: 'MN',
    state_and_number: 'MN-2',
    zip: 55001,
    congressionalDistrict: 2,
    addressesInDistrict: [
      {
        RegAddrLine1: '15934 FINCH LN',
        RegAddrCity: 'APPLE VALLEY',
        RegAddrState: 'MN',
        RegAddrZip: 55124,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '12803 ESSEX WAY',
        RegAddrCity: 'APPLE VALLEY',
        RegAddrState: 'MN',
        RegAddrZip: 55124,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '13171 FORDHAM CT',
        RegAddrCity: 'APPLE VALLEY',
        RegAddrState: 'MN',
        RegAddrZip: 55124,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '7850 UPPER 145TH CT W',
        RegAddrCity: 'APPLE VALLEY',
        RegAddrState: 'MN',
        RegAddrZip: 55124,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '6471 129TH ST W',
        RegAddrCity: 'APPLE VALLEY',
        RegAddrState: 'MN',
        RegAddrZip: 55124,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '5334 142ND PATH W',
        RegAddrCity: 'APPLE VALLEY',
        RegAddrState: 'MN',
        RegAddrZip: 55124,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '216 E CENTURY ST',
        RegAddrCity: 'BELLE PLAINE',
        RegAddrState: 'MN',
        RegAddrZip: 56011,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '170 92ND AVE NE',
        RegAddrCity: 'BLAINE',
        RegAddrState: 'MN',
        RegAddrZip: 55434,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1009 HILLOWAY CIR',
        RegAddrCity: 'BURNSVILLE',
        RegAddrState: 'MN',
        RegAddrZip: 55306,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '14825 WILDWOOD RD',
        RegAddrCity: 'BURNSVILLE',
        RegAddrState: 'MN',
        RegAddrZip: 55306,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '3116 FOXPOINT CIR',
        RegAddrCity: 'BURNSVILLE',
        RegAddrState: 'MN',
        RegAddrZip: 55337,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '13109 THOMAS AVE S',
        RegAddrCity: 'BURNSVILLE',
        RegAddrState: 'MN',
        RegAddrZip: 55337,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2704 RIVER HILLS DR',
        RegAddrCity: 'BURNSVILLE',
        RegAddrState: 'MN',
        RegAddrZip: 55337,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '13804 SCOTT ST',
        RegAddrCity: 'BURNSVILLE',
        RegAddrState: 'MN',
        RegAddrZip: 55337,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '14136 FRONTIER LN',
        RegAddrCity: 'BURNSVILLE',
        RegAddrState: 'MN',
        RegAddrZip: 55337,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '11460 367TH ST',
        RegAddrCity: 'CANNON FALLS',
        RegAddrState: 'MN',
        RegAddrZip: 55009,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '9871 HEATH AVE S',
        RegAddrCity: 'COTTAGE GROVE',
        RegAddrState: 'MN',
        RegAddrZip: 55016,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '6671 MANNING AVE S',
        RegAddrCity: 'COTTAGE GROVE',
        RegAddrState: 'MN',
        RegAddrZip: 55016,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '9074 JAREAU AVE S',
        RegAddrCity: 'COTTAGE GROVE',
        RegAddrState: 'MN',
        RegAddrZip: 55016,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '34241 HIGHWAY 56 BLVD',
        RegAddrCity: 'DENNISON',
        RegAddrState: 'MN',
        RegAddrZip: 55018,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '3135 ALDEN POND LN',
        RegAddrCity: 'EAGAN',
        RegAddrState: 'MN',
        RegAddrZip: 55121,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1949 N RUBY CT',
        RegAddrCity: 'EAGAN',
        RegAddrState: 'MN',
        RegAddrZip: 55122,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1823 SENJA CT',
        RegAddrCity: 'EAGAN',
        RegAddrState: 'MN',
        RegAddrZip: 55122,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '4232 BLACKHAWK RD',
        RegAddrCity: 'EAGAN',
        RegAddrState: 'MN',
        RegAddrZip: 55122,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '4805 WESTON HILLS DR',
        RegAddrCity: 'EAGAN',
        RegAddrState: 'MN',
        RegAddrZip: 55123,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '4561 OAK CHASE CIR',
        RegAddrCity: 'EAGAN',
        RegAddrState: 'MN',
        RegAddrZip: 55123,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '5402 186TH ST W',
        RegAddrCity: 'FARMINGTON',
        RegAddrState: 'MN',
        RegAddrZip: 55024,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '17083 FRAZER PATH',
        RegAddrCity: 'FARMINGTON',
        RegAddrState: 'MN',
        RegAddrZip: 55024,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '400 ELM ST',
        RegAddrCity: 'FARMINGTON',
        RegAddrState: 'MN',
        RegAddrZip: 55024,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '22220 TWIN PONDS CIR',
        RegAddrCity: 'FARMINGTON',
        RegAddrState: 'MN',
        RegAddrZip: 55024,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '718 BAILEY ST',
        RegAddrCity: 'HASTINGS',
        RegAddrState: 'MN',
        RegAddrZip: 55033,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '645 BOHLKEN DR',
        RegAddrCity: 'HASTINGS',
        RegAddrState: 'MN',
        RegAddrZip: 55033,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '16455 200TH ST E',
        RegAddrCity: 'HASTINGS',
        RegAddrState: 'MN',
        RegAddrZip: 55033,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '6775 BLAINE AVE',
        RegAddrCity: 'INVER GROVE',
        RegAddrState: 'MN',
        RegAddrZip: 55076,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '4898 BITTERMAN PATH',
        RegAddrCity: 'INVER GROVE',
        RegAddrState: 'MN',
        RegAddrZip: 55076,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '8395 ASIATIC AVE',
        RegAddrCity: 'INVER GROVE',
        RegAddrState: 'MN',
        RegAddrZip: 55077,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '10588 ALISON WAY',
        RegAddrCity: 'INVER GROVE',
        RegAddrState: 'MN',
        RegAddrZip: 55077,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '813 HICKORY PL',
        RegAddrCity: 'JORDAN',
        RegAddrState: 'MN',
        RegAddrZip: 55352,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '27460 708TH ST',
        RegAddrCity: 'LAKE CITY',
        RegAddrState: 'MN',
        RegAddrZip: 55041,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '9197 161ST ST W',
        RegAddrCity: 'LAKEVILLE',
        RegAddrState: 'MN',
        RegAddrZip: 55044,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '17049 KINGS PL',
        RegAddrCity: 'LAKEVILLE',
        RegAddrState: 'MN',
        RegAddrZip: 55044,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '5158 167TH ST W',
        RegAddrCity: 'LAKEVILLE',
        RegAddrState: 'MN',
        RegAddrZip: 55044,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '19435 195TH CT W',
        RegAddrCity: 'LAKEVILLE',
        RegAddrState: 'MN',
        RegAddrZip: 55044,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '21215 INSEL LN',
        RegAddrCity: 'LAKEVILLE',
        RegAddrState: 'MN',
        RegAddrZip: 55044,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '8913 170TH ST E',
        RegAddrCity: 'NERSTRAND',
        RegAddrState: 'MN',
        RegAddrZip: 55053,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2620 ANNA CT',
        RegAddrCity: 'NEW MARKET',
        RegAddrState: 'MN',
        RegAddrZip: 55054,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '0 FACULTY CLUB CARLETON',
        RegAddrCity: 'NORTHFIELD',
        RegAddrState: 'MN',
        RegAddrZip: 55057,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '0 NOURSE HALL CARLETON CLG',
        RegAddrCity: 'NORTHFIELD',
        RegAddrState: 'MN',
        RegAddrZip: 55057,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1900 MICHIGAN DR',
        RegAddrCity: 'NORTHFIELD',
        RegAddrState: 'MN',
        RegAddrZip: 55057,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1612 ARCHIBALD ST',
        RegAddrCity: 'NORTHFIELD',
        RegAddrState: 'MN',
        RegAddrZip: 55057,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1630 8TH ST SE',
        RegAddrCity: 'PINE ISLAND',
        RegAddrState: 'MN',
        RegAddrZip: 55963,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '5534 BOUNTY ST SE',
        RegAddrCity: 'PRIOR LAKE',
        RegAddrState: 'MN',
        RegAddrZip: 55372,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '4037 HERITAGE LN SE',
        RegAddrCity: 'PRIOR LAKE',
        RegAddrState: 'MN',
        RegAddrZip: 55372,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '17491 KILLARNEY AVE',
        RegAddrCity: 'PRIOR LAKE',
        RegAddrState: 'MN',
        RegAddrZip: 55372,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '714 BLUFF ST',
        RegAddrCity: 'RED WING',
        RegAddrState: 'MN',
        RegAddrZip: 55066,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '3433 UPPER 149TH CT W',
        RegAddrCity: 'ROSEMOUNT',
        RegAddrState: 'MN',
        RegAddrZip: 55068,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '12568 DANBURY WAY',
        RegAddrCity: 'ROSEMOUNT',
        RegAddrState: 'MN',
        RegAddrZip: 55068,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '13554 ATHENA WAY',
        RegAddrCity: 'ROSEMOUNT',
        RegAddrState: 'MN',
        RegAddrZip: 55068,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '14612 MEADOWOOD DR',
        RegAddrCity: 'SAVAGE',
        RegAddrState: 'MN',
        RegAddrZip: 55378,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '7444 150TH ST W',
        RegAddrCity: 'SAVAGE',
        RegAddrState: 'MN',
        RegAddrZip: 55378,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2160 FLAMINGO DR',
        RegAddrCity: 'SHAKOPEE',
        RegAddrState: 'MN',
        RegAddrZip: 55379,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '885 BASENJI CURV',
        RegAddrCity: 'SHAKOPEE',
        RegAddrState: 'MN',
        RegAddrZip: 55379,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1247 QUINCY CIR',
        RegAddrCity: 'SHAKOPEE',
        RegAddrState: 'MN',
        RegAddrZip: 55379,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1340 3RD AVE W',
        RegAddrCity: 'SHAKOPEE',
        RegAddrState: 'MN',
        RegAddrZip: 55379,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '616 4TH AVE S',
        RegAddrCity: 'SOUTH ST PAUL',
        RegAddrState: 'MN',
        RegAddrZip: 55075,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '425 18TH AVE S',
        RegAddrCity: 'SOUTH ST PAUL',
        RegAddrState: 'MN',
        RegAddrZip: 55075,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '740 9TH AVE S',
        RegAddrCity: 'SOUTH ST PAUL',
        RegAddrState: 'MN',
        RegAddrZip: 55075,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '814 HASTINGS AVE',
        RegAddrCity: 'ST PAUL PARK',
        RegAddrState: 'MN',
        RegAddrZip: 55071,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '12401 480TH ST',
        RegAddrCity: 'WANAMINGO',
        RegAddrState: 'MN',
        RegAddrZip: 55983,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '157 STANLEY ST',
        RegAddrCity: 'WEST ST PAUL',
        RegAddrState: 'MN',
        RegAddrZip: 55118,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1041 SEMINOLE AVE',
        RegAddrCity: 'WEST ST PAUL',
        RegAddrState: 'MN',
        RegAddrZip: 55118,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '395 STANLEY ST',
        RegAddrCity: 'WEST ST PAUL',
        RegAddrState: 'MN',
        RegAddrZip: 55118,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '859 DEPPE ST',
        RegAddrCity: 'WEST ST PAUL',
        RegAddrState: 'MN',
        RegAddrZip: 55118,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '63603 COUNTY ROAD 68',
        RegAddrCity: 'ZUMBRO FALLS',
        RegAddrState: 'MN',
        RegAddrZip: 55991,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '17852 WEDGEWOOD DR',
        RegAddrCity: 'ZUMBROTA',
        RegAddrState: 'MN',
        RegAddrZip: 55992,
        CongressionalDistrict: 2,
      },
    ],
  },
  {
    name: 'MN-3',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/MN-3?ref=gh-pages',
    state: 'MN',
    state_and_number: 'MN-3',
    zip: 55304,
    congressionalDistrict: 3,
    addressesInDistrict: [
      {
        RegAddrLine1: '9409 5TH AVE S',
        RegAddrCity: 'BLOOMINGTON',
        RegAddrState: 'MN',
        RegAddrZip: 55420,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '8506 3RD AVE S',
        RegAddrCity: 'BLOOMINGTON',
        RegAddrState: 'MN',
        RegAddrZip: 55420,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '8710 SHERIDAN AVE S',
        RegAddrCity: 'BLOOMINGTON',
        RegAddrState: 'MN',
        RegAddrZip: 55431,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '8800 IRVING AVE S',
        RegAddrCity: 'BLOOMINGTON',
        RegAddrState: 'MN',
        RegAddrZip: 55431,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '4116 W 100TH ST',
        RegAddrCity: 'BLOOMINGTON',
        RegAddrState: 'MN',
        RegAddrZip: 55437,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '4516 NORMANDALE HIGHLANDS CIR',
        RegAddrCity: 'BLOOMINGTON',
        RegAddrState: 'MN',
        RegAddrZip: 55437,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '11660 NORMANDALE BLVD',
        RegAddrCity: 'BLOOMINGTON',
        RegAddrState: 'MN',
        RegAddrZip: 55437,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '6008 W 104TH ST',
        RegAddrCity: 'BLOOMINGTON',
        RegAddrState: 'MN',
        RegAddrZip: 55438,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '6504 DOUGLAS DR N',
        RegAddrCity: 'BROOKLYN PARK',
        RegAddrState: 'MN',
        RegAddrZip: 55429,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '4208 81ST AVE N',
        RegAddrCity: 'BROOKLYN PARK',
        RegAddrState: 'MN',
        RegAddrZip: 55443,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '10017 JUNIPER AVE N',
        RegAddrCity: 'BROOKLYN PARK',
        RegAddrState: 'MN',
        RegAddrZip: 55443,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '7916 KYLE AVE N',
        RegAddrCity: 'BROOKLYN PARK',
        RegAddrState: 'MN',
        RegAddrZip: 55443,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '5323 107TH AVE N',
        RegAddrCity: 'BROOKLYN PARK',
        RegAddrState: 'MN',
        RegAddrZip: 55443,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1652 84TH CT N',
        RegAddrCity: 'BROOKLYN PARK',
        RegAddrState: 'MN',
        RegAddrZip: 55444,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '2465 PEARSON PKWY',
        RegAddrCity: 'BROOKLYN PARK',
        RegAddrState: 'MN',
        RegAddrZip: 55444,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '12060 PORTER DR',
        RegAddrCity: 'CHAMPLIN',
        RegAddrState: 'MN',
        RegAddrZip: 55316,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '328 SUNRISE LN',
        RegAddrCity: 'CHAMPLIN',
        RegAddrState: 'MN',
        RegAddrZip: 55316,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '9825 129TH LN N',
        RegAddrCity: 'CHAMPLIN',
        RegAddrState: 'MN',
        RegAddrZip: 55316,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '11740 TEXAS AVE N',
        RegAddrCity: 'CHAMPLIN',
        RegAddrState: 'MN',
        RegAddrZip: 55316,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '7317 PONTIAC CIR',
        RegAddrCity: 'CHANHASSEN',
        RegAddrState: 'MN',
        RegAddrZip: 55317,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1041 WILDWOOD WAY',
        RegAddrCity: 'CHASKA',
        RegAddrState: 'MN',
        RegAddrZip: 55318,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '223 N PINE ST',
        RegAddrCity: 'CHASKA',
        RegAddrState: 'MN',
        RegAddrZip: 55318,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '365 HIGHWOOD DRIVE CIR',
        RegAddrCity: 'CHASKA',
        RegAddrState: 'MN',
        RegAddrZip: 55318,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '10899 THRUSH ST NW',
        RegAddrCity: 'COON RAPIDS',
        RegAddrState: 'MN',
        RegAddrZip: 55433,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '9754 NORWAY ST NW',
        RegAddrCity: 'COON RAPIDS',
        RegAddrState: 'MN',
        RegAddrZip: 55433,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '12200 QUINN ST NW',
        RegAddrCity: 'COON RAPIDS',
        RegAddrState: 'MN',
        RegAddrZip: 55448,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1250 130TH LN NW',
        RegAddrCity: 'COON RAPIDS',
        RegAddrState: 'MN',
        RegAddrZip: 55448,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '2328 131ST AVE NW',
        RegAddrCity: 'COON RAPIDS',
        RegAddrState: 'MN',
        RegAddrZip: 55448,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '2905 127TH CIR NW',
        RegAddrCity: 'COON RAPIDS',
        RegAddrState: 'MN',
        RegAddrZip: 55448,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1123 NORTON CIR',
        RegAddrCity: 'CROOKSTON',
        RegAddrState: 'MN',
        RegAddrZip: 56716,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '3993 HILLCREST RD',
        RegAddrCity: 'DEEPHAVEN',
        RegAddrState: 'MN',
        RegAddrZip: 55391,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '19250 DALE AVE',
        RegAddrCity: 'DEEPHAVEN',
        RegAddrState: 'MN',
        RegAddrZip: 55391,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '8540 CARDIFF LN',
        RegAddrCity: 'EDEN PRAIRIE',
        RegAddrState: 'MN',
        RegAddrZip: 55344,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '6802 IDLEWOOD WAY',
        RegAddrCity: 'EDEN PRAIRIE',
        RegAddrState: 'MN',
        RegAddrZip: 55346,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '17500 RUSTIC HILLS DR',
        RegAddrCity: 'EDEN PRAIRIE',
        RegAddrState: 'MN',
        RegAddrZip: 55346,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '6796 LORENA LN',
        RegAddrCity: 'EDEN PRAIRIE',
        RegAddrState: 'MN',
        RegAddrZip: 55346,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '17545 GEORGE MORAN DR',
        RegAddrCity: 'EDEN PRAIRIE',
        RegAddrState: 'MN',
        RegAddrZip: 55347,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '9785 DORSET LN',
        RegAddrCity: 'EDEN PRAIRIE',
        RegAddrState: 'MN',
        RegAddrZip: 55347,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '10346 TUSCANY WAY',
        RegAddrCity: 'EDEN PRAIRIE',
        RegAddrState: 'MN',
        RegAddrZip: 55347,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '8590 SARATOGA LN',
        RegAddrCity: 'EDEN PRAIRIE',
        RegAddrState: 'MN',
        RegAddrZip: 55347,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '15361 VILLAGE WOODS DR',
        RegAddrCity: 'EDEN PRAIRIE',
        RegAddrState: 'MN',
        RegAddrZip: 55347,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '5609 GATE PARK RD',
        RegAddrCity: 'EDINA',
        RegAddrState: 'MN',
        RegAddrZip: 55436,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '5801 VIEW LN',
        RegAddrCity: 'EDINA',
        RegAddrState: 'MN',
        RegAddrZip: 55436,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '6425 INDIAN HILLS RD',
        RegAddrCity: 'EDINA',
        RegAddrState: 'MN',
        RegAddrZip: 55439,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '4213 FISHER BND',
        RegAddrCity: 'EXCELSIOR',
        RegAddrState: 'MN',
        RegAddrZip: 55331,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '6210 BARBERRY CIR',
        RegAddrCity: 'EXCELSIOR',
        RegAddrState: 'MN',
        RegAddrZip: 55331,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '17400 92ND AVE N',
        RegAddrCity: 'MAPLE GROVE',
        RegAddrState: 'MN',
        RegAddrZip: 55311,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '6889 KIMBERLY LN N',
        RegAddrCity: 'MAPLE GROVE',
        RegAddrState: 'MN',
        RegAddrZip: 55311,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '6222 CHESSHIRE LN N',
        RegAddrCity: 'MAPLE GROVE',
        RegAddrState: 'MN',
        RegAddrZip: 55311,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '9111 HOLLY LN N',
        RegAddrCity: 'MAPLE GROVE',
        RegAddrState: 'MN',
        RegAddrZip: 55311,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '10001 NATHAN LN N',
        RegAddrCity: 'MAPLE GROVE',
        RegAddrState: 'MN',
        RegAddrZip: 55369,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '12900 84TH AVE N',
        RegAddrCity: 'MAPLE GROVE',
        RegAddrState: 'MN',
        RegAddrZip: 55369,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '5365 PAINTER CREEK GRN',
        RegAddrCity: 'MAPLE PLAIN',
        RegAddrState: 'MN',
        RegAddrZip: 55359,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '617 DEER CREEK RD',
        RegAddrCity: 'MAPLE PLAIN',
        RegAddrState: 'MN',
        RegAddrZip: 55359,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '11449 ASPEN WAY',
        RegAddrCity: 'MINNETONKA',
        RegAddrState: 'MN',
        RegAddrZip: 55305,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '11813 RIDGEMOUNT AVE W',
        RegAddrCity: 'MINNETONKA',
        RegAddrState: 'MN',
        RegAddrZip: 55305,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '14848 KRAL RD',
        RegAddrCity: 'MINNETONKA',
        RegAddrState: 'MN',
        RegAddrZip: 55345,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '5322 MICHAELE LN',
        RegAddrCity: 'MINNETONKA',
        RegAddrState: 'MN',
        RegAddrZip: 55345,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '4916 BEDFORD RD',
        RegAddrCity: 'MOUND',
        RegAddrState: 'MN',
        RegAddrZip: 55364,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1614 MAPLE MANORS RD',
        RegAddrCity: 'MOUND',
        RegAddrState: 'MN',
        RegAddrZip: 55364,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '11815 OLD ROCKFORD RD',
        RegAddrCity: 'PLYMOUTH',
        RegAddrState: 'MN',
        RegAddrZip: 55441,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '10720 38TH AVE N',
        RegAddrCity: 'PLYMOUTH',
        RegAddrState: 'MN',
        RegAddrZip: 55441,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '10525 31ST AVE N',
        RegAddrCity: 'PLYMOUTH',
        RegAddrState: 'MN',
        RegAddrZip: 55441,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '3815 XENIUM CT N',
        RegAddrCity: 'PLYMOUTH',
        RegAddrState: 'MN',
        RegAddrZip: 55441,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '10220 56TH AVE N',
        RegAddrCity: 'PLYMOUTH',
        RegAddrState: 'MN',
        RegAddrZip: 55442,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '11535 48TH AVE N',
        RegAddrCity: 'PLYMOUTH',
        RegAddrState: 'MN',
        RegAddrZip: 55442,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '3850 WALNUT GROVE LN N',
        RegAddrCity: 'PLYMOUTH',
        RegAddrState: 'MN',
        RegAddrZip: 55446,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '5535 YELLOWSTONE LN N',
        RegAddrCity: 'PLYMOUTH',
        RegAddrState: 'MN',
        RegAddrZip: 55446,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '14913 GLEASON LAKE DR',
        RegAddrCity: 'PLYMOUTH',
        RegAddrState: 'MN',
        RegAddrZip: 55447,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '2616 SHENANDOAH LN N',
        RegAddrCity: 'PLYMOUTH',
        RegAddrState: 'MN',
        RegAddrZip: 55447,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '9815 RUSH CREEK BLVD',
        RegAddrCity: 'ROGERS',
        RegAddrState: 'MN',
        RegAddrZip: 55374,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '13940 JACYLYN DR',
        RegAddrCity: 'ROGERS',
        RegAddrState: 'MN',
        RegAddrZip: 55374,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1240 79TH ST',
        RegAddrCity: 'VICTORIA',
        RegAddrState: 'MN',
        RegAddrZip: 55386,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '816 8TH AVE NW',
        RegAddrCity: 'WASECA',
        RegAddrState: 'MN',
        RegAddrZip: 56093,
        CongressionalDistrict: 3,
      },
    ],
  },
  {
    name: 'MN-4',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/MN-4?ref=gh-pages',
    state: 'MN',
    state_and_number: 'MN-4',
    zip: 55003,
    congressionalDistrict: 4,
    addressesInDistrict: [
      {
        RegAddrLine1: '1785 LAKE VALENTINE RD',
        RegAddrCity: 'ARDEN HILLS',
        RegAddrState: 'MN',
        RegAddrZip: 55112,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '3900 BETHEL DR',
        RegAddrCity: 'ARDEN HILLS',
        RegAddrState: 'MN',
        RegAddrZip: 55112,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1757 MAPLE CT',
        RegAddrCity: 'FALCON HGTS',
        RegAddrState: 'MN',
        RegAddrZip: 55113,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '8909 37TH ST N',
        RegAddrCity: 'LAKE ELMO',
        RegAddrState: 'MN',
        RegAddrZip: 55042,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '964 CONNOR AVE E',
        RegAddrCity: 'MAPLEWOOD',
        RegAddrState: 'MN',
        RegAddrZip: 55109,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '3013 BARTELMY CT',
        RegAddrCity: 'MAPLEWOOD',
        RegAddrState: 'MN',
        RegAddrZip: 55109,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2517 BARCLAY ST',
        RegAddrCity: 'MAPLEWOOD',
        RegAddrState: 'MN',
        RegAddrZip: 55109,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1450 RIPLEY AVE',
        RegAddrCity: 'MAPLEWOOD',
        RegAddrState: 'MN',
        RegAddrZip: 55109,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1709 COUNTY ROAD B E',
        RegAddrCity: 'MAPLEWOOD',
        RegAddrState: 'MN',
        RegAddrZip: 55109,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '321 LARPENTEUR AVE E',
        RegAddrCity: 'MAPLEWOOD',
        RegAddrState: 'MN',
        RegAddrZip: 55117,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1714 EDGEMONT ST',
        RegAddrCity: 'MAPLEWOOD',
        RegAddrState: 'MN',
        RegAddrZip: 55117,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '921 OAKWOOD DR',
        RegAddrCity: 'NEW BRIGHTON',
        RegAddrState: 'MN',
        RegAddrZip: 55112,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '511 REDWOOD LN',
        RegAddrCity: 'NEW BRIGHTON',
        RegAddrState: 'MN',
        RegAddrZip: 55112,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '124 WINDSOR CT',
        RegAddrCity: 'NEW BRIGHTON',
        RegAddrState: 'MN',
        RegAddrZip: 55112,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1850 8TH AVE',
        RegAddrCity: 'NEWPORT',
        RegAddrState: 'MN',
        RegAddrZip: 55055,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2101 COWERN PL E',
        RegAddrCity: 'NORTH ST PAUL',
        RegAddrState: 'MN',
        RegAddrZip: 55109,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1177 GROSPOINT AVE N',
        RegAddrCity: 'OAKDALE',
        RegAddrState: 'MN',
        RegAddrZip: 55128,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '7681 18TH ST N',
        RegAddrCity: 'OAKDALE',
        RegAddrState: 'MN',
        RegAddrZip: 55128,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2704 SHELDON ST',
        RegAddrCity: 'ROSEVILLE',
        RegAddrState: 'MN',
        RegAddrZip: 55113,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '3107 EVELYN ST',
        RegAddrCity: 'ROSEVILLE',
        RegAddrState: 'MN',
        RegAddrZip: 55113,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '240 GRANDVIEW AVE W',
        RegAddrCity: 'ROSEVILLE',
        RegAddrState: 'MN',
        RegAddrZip: 55113,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '566 EUSTIS ST',
        RegAddrCity: 'SAINT PAUL',
        RegAddrState: 'MN',
        RegAddrZip: 55104,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1249 BLAIR AVE',
        RegAddrCity: 'SAINT PAUL',
        RegAddrState: 'MN',
        RegAddrZip: 55104,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2205 DAYTON AVE',
        RegAddrCity: 'SAINT PAUL',
        RegAddrState: 'MN',
        RegAddrZip: 55104,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1184 BUSH AVE',
        RegAddrCity: 'SAINT PAUL',
        RegAddrState: 'MN',
        RegAddrZip: 55106,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1540 WINCHELL ST',
        RegAddrCity: 'SAINT PAUL',
        RegAddrState: 'MN',
        RegAddrZip: 55106,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1213 CONWAY ST',
        RegAddrCity: 'SAINT PAUL',
        RegAddrState: 'MN',
        RegAddrZip: 55106,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1687 IVY AVE E',
        RegAddrCity: 'SAINT PAUL',
        RegAddrState: 'MN',
        RegAddrZip: 55106,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '183 CONGRESS ST W',
        RegAddrCity: 'SAINT PAUL',
        RegAddrState: 'MN',
        RegAddrZip: 55107,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1878 HIGHLAND PKWY',
        RegAddrCity: 'SAINT PAUL',
        RegAddrState: 'MN',
        RegAddrZip: 55116,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '864 SAINT PAUL AVE',
        RegAddrCity: 'SAINT PAUL',
        RegAddrState: 'MN',
        RegAddrZip: 55116,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '113 MANITOBA AVE',
        RegAddrCity: 'SAINT PAUL',
        RegAddrState: 'MN',
        RegAddrZip: 55117,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1134 MARION ST',
        RegAddrCity: 'SAINT PAUL',
        RegAddrState: 'MN',
        RegAddrZip: 55117,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1862 ORANGE AVE E',
        RegAddrCity: 'SAINT PAUL',
        RegAddrState: 'MN',
        RegAddrZip: 55119,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '435 HOWARD ST N',
        RegAddrCity: 'SAINT PAUL',
        RegAddrState: 'MN',
        RegAddrZip: 55119,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '435 UNIVERSITY AVE E',
        RegAddrCity: 'SAINT PAUL',
        RegAddrState: 'MN',
        RegAddrZip: 55130,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '593 CASE AVE',
        RegAddrCity: 'SAINT PAUL',
        RegAddrState: 'MN',
        RegAddrZip: 55130,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '4516 CHATSWORTH ST N',
        RegAddrCity: 'SHOREVIEW',
        RegAddrState: 'MN',
        RegAddrZip: 55126,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1623 KNIGHT ST',
        RegAddrCity: 'SHOREVIEW',
        RegAddrState: 'MN',
        RegAddrZip: 55126,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '78 MARYKNOLL DR',
        RegAddrCity: 'STILLWATER',
        RegAddrState: 'MN',
        RegAddrZip: 55082,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '15720 100TH ST N',
        RegAddrCity: 'STILLWATER',
        RegAddrState: 'MN',
        RegAddrZip: 55082,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '616 5TH ST N',
        RegAddrCity: 'STILLWATER',
        RegAddrState: 'MN',
        RegAddrZip: 55082,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '10787 MYERON RD N',
        RegAddrCity: 'STILLWATER',
        RegAddrState: 'MN',
        RegAddrZip: 55082,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '5881 OXBORO AVE N',
        RegAddrCity: 'STILLWATER',
        RegAddrState: 'MN',
        RegAddrZip: 55082,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2284 4TH ST',
        RegAddrCity: 'WHITE BEAR LAKE',
        RegAddrState: 'MN',
        RegAddrZip: 55110,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '4425 BAY LN',
        RegAddrCity: 'WHITE BEAR LAKE',
        RegAddrState: 'MN',
        RegAddrZip: 55110,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '4050 CRANBROOK DR',
        RegAddrCity: 'WHITE BEAR LK',
        RegAddrState: 'MN',
        RegAddrZip: 55110,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '5467 LAKEVIEW AVE',
        RegAddrCity: 'WHITE BEAR TP',
        RegAddrState: 'MN',
        RegAddrZip: 55110,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '3582 OXFORD DR',
        RegAddrCity: 'WOODBURY',
        RegAddrState: 'MN',
        RegAddrZip: 55125,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1016 CEDARWOOD DR',
        RegAddrCity: 'WOODBURY',
        RegAddrState: 'MN',
        RegAddrZip: 55125,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2722 MALLARD DR',
        RegAddrCity: 'WOODBURY',
        RegAddrState: 'MN',
        RegAddrZip: 55125,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '3768 MONTICELLO DR',
        RegAddrCity: 'WOODBURY',
        RegAddrState: 'MN',
        RegAddrZip: 55129,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2241 WOODCREST DR',
        RegAddrCity: 'WOODBURY',
        RegAddrState: 'MN',
        RegAddrZip: 55129,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '8730 GRANITE CIR',
        RegAddrCity: 'WOODBURY',
        RegAddrState: 'MN',
        RegAddrZip: 55129,
        CongressionalDistrict: 4,
      },
    ],
  },
  {
    name: 'MN-5',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/MN-5?ref=gh-pages',
    state: 'MN',
    state_and_number: 'MN-5',
    zip: 55111,
    congressionalDistrict: 5,
    addressesInDistrict: [
      {
        RegAddrLine1: '5735 MAJOR AVE N',
        RegAddrCity: 'BROOKLYN CENTER',
        RegAddrState: 'MN',
        RegAddrZip: 55429,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '5413 HUMBOLDT AVE N',
        RegAddrCity: 'BROOKLYN CENTER',
        RegAddrState: 'MN',
        RegAddrZip: 55430,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '5320 RUSSELL AVE N',
        RegAddrCity: 'BROOKLYN CTR',
        RegAddrState: 'MN',
        RegAddrZip: 55430,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '820 50 1/2 AVE NE',
        RegAddrCity: 'COLUMBIA HEIGHTS',
        RegAddrState: 'MN',
        RegAddrZip: 55421,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '4401 4TH ST NE',
        RegAddrCity: 'COLUMBIA HTS',
        RegAddrState: 'MN',
        RegAddrZip: 55421,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '4409 2ND ST NE',
        RegAddrCity: 'COLUMBIA HTS',
        RegAddrState: 'MN',
        RegAddrZip: 55421,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '6824 MEDICINE LAKE RD',
        RegAddrCity: 'CRYSTAL',
        RegAddrState: 'MN',
        RegAddrZip: 55427,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '3810 KENTUCKY AVE N',
        RegAddrCity: 'CRYSTAL',
        RegAddrState: 'MN',
        RegAddrZip: 55427,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '6806 45TH PL N',
        RegAddrCity: 'CRYSTAL',
        RegAddrState: 'MN',
        RegAddrZip: 55428,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '7501 4TH ST NE',
        RegAddrCity: 'FRIDLEY',
        RegAddrState: 'MN',
        RegAddrZip: 55432,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '6150 RAINBOW DR NE',
        RegAddrCity: 'FRIDLEY',
        RegAddrState: 'MN',
        RegAddrZip: 55432,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '2943 ORCHARD AVE N',
        RegAddrCity: 'GOLDEN VALLEY',
        RegAddrState: 'MN',
        RegAddrZip: 55422,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '1871 VALDERS AVE N',
        RegAddrCity: 'GOLDEN VALLEY',
        RegAddrState: 'MN',
        RegAddrZip: 55427,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '2437 GARFIELD AVE',
        RegAddrCity: 'MINNEAPOLIS',
        RegAddrState: 'MN',
        RegAddrZip: 55405,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '3833 27TH AVE S',
        RegAddrCity: 'MINNEAPOLIS',
        RegAddrState: 'MN',
        RegAddrZip: 55406,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '2644 36TH AVE S',
        RegAddrCity: 'MINNEAPOLIS',
        RegAddrState: 'MN',
        RegAddrZip: 55406,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '4005 OAKLAND AVE',
        RegAddrCity: 'MINNEAPOLIS',
        RegAddrState: 'MN',
        RegAddrZip: 55407,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '3720 23RD AVE S',
        RegAddrCity: 'MINNEAPOLIS',
        RegAddrState: 'MN',
        RegAddrZip: 55407,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '4229 18TH AVE S',
        RegAddrCity: 'MINNEAPOLIS',
        RegAddrState: 'MN',
        RegAddrZip: 55407,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '5628 WASHBURN AVE S',
        RegAddrCity: 'MINNEAPOLIS',
        RegAddrState: 'MN',
        RegAddrZip: 55410,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '2822 17TH AVE N',
        RegAddrCity: 'MINNEAPOLIS',
        RegAddrState: 'MN',
        RegAddrZip: 55411,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '816 21ST AVE N',
        RegAddrCity: 'MINNEAPOLIS',
        RegAddrState: 'MN',
        RegAddrZip: 55411,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '4539 UPTON AVE N',
        RegAddrCity: 'MINNEAPOLIS',
        RegAddrState: 'MN',
        RegAddrZip: 55412,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '3555 HUMBOLDT AVE N',
        RegAddrCity: 'MINNEAPOLIS',
        RegAddrState: 'MN',
        RegAddrZip: 55412,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '4518 VINCENT AVE N',
        RegAddrCity: 'MINNEAPOLIS',
        RegAddrState: 'MN',
        RegAddrZip: 55412,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '30 ORLIN AVE SE',
        RegAddrCity: 'MINNEAPOLIS',
        RegAddrState: 'MN',
        RegAddrZip: 55414,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '235 7TH ST SE',
        RegAddrCity: 'MINNEAPOLIS',
        RegAddrState: 'MN',
        RegAddrZip: 55414,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '1225 LAKEVIEW AVE S',
        RegAddrCity: 'MINNEAPOLIS',
        RegAddrState: 'MN',
        RegAddrZip: 55416,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '5757 26TH AVE S',
        RegAddrCity: 'MINNEAPOLIS',
        RegAddrState: 'MN',
        RegAddrZip: 55417,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '5237 ELLIOT AVE',
        RegAddrCity: 'MINNEAPOLIS',
        RegAddrState: 'MN',
        RegAddrZip: 55417,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '5037 41ST AVE S',
        RegAddrCity: 'MINNEAPOLIS',
        RegAddrState: 'MN',
        RegAddrZip: 55417,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '5608 28TH AVE S',
        RegAddrCity: 'MINNEAPOLIS',
        RegAddrState: 'MN',
        RegAddrZip: 55417,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '3119 MCKINLEY ST NE',
        RegAddrCity: 'MINNEAPOLIS',
        RegAddrState: 'MN',
        RegAddrZip: 55418,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '5521 DUPONT AVE S',
        RegAddrCity: 'MINNEAPOLIS',
        RegAddrState: 'MN',
        RegAddrZip: 55419,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '4909 4TH AVE S',
        RegAddrCity: 'MINNEAPOLIS',
        RegAddrState: 'MN',
        RegAddrZip: 55419,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '5124 LYNDALE AVE S',
        RegAddrCity: 'MINNEAPOLIS',
        RegAddrState: 'MN',
        RegAddrZip: 55419,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '4444 DUPONT AVE S',
        RegAddrCity: 'MINNEAPOLIS',
        RegAddrState: 'MN',
        RegAddrZip: 55419,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '4923 SHERIDAN AVE N',
        RegAddrCity: 'MINNEAPOLIS',
        RegAddrState: 'MN',
        RegAddrZip: 55430,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '8948 47 1/2 AVE N',
        RegAddrCity: 'NEW HOPE',
        RegAddrState: 'MN',
        RegAddrZip: 55428,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '7533 EMERSON AVE S',
        RegAddrCity: 'RICHFIELD',
        RegAddrState: 'MN',
        RegAddrZip: 55423,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '6519 LOGAN AVE S',
        RegAddrCity: 'RICHFIELD',
        RegAddrState: 'MN',
        RegAddrZip: 55423,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '7024 OAKLAND AVE',
        RegAddrCity: 'RICHFIELD',
        RegAddrState: 'MN',
        RegAddrZip: 55423,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '3944 VERA CRUZ AVE N',
        RegAddrCity: 'ROBBINSDALE',
        RegAddrState: 'MN',
        RegAddrZip: 55422,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '3946 ALABAMA AVE S',
        RegAddrCity: 'ST LOUIS PARK',
        RegAddrState: 'MN',
        RegAddrZip: 55416,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '3747 INGLEWOOD AVE S',
        RegAddrCity: 'ST LOUIS PARK',
        RegAddrState: 'MN',
        RegAddrZip: 55416,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '2649 INGLEWOOD AVE S',
        RegAddrCity: 'ST LOUIS PARK',
        RegAddrState: 'MN',
        RegAddrZip: 55416,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '3952 ZARTHAN AVE S',
        RegAddrCity: 'ST LOUIS PARK',
        RegAddrState: 'MN',
        RegAddrZip: 55416,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '2937 MARYLAND AVE S',
        RegAddrCity: 'ST LOUIS PARK',
        RegAddrState: 'MN',
        RegAddrZip: 55426,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '3620 PHILLIPS PKWY',
        RegAddrCity: 'ST LOUIS PARK',
        RegAddrState: 'MN',
        RegAddrZip: 55426,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '3029 FLORIDA AVE S',
        RegAddrCity: 'ST LOUIS PARK',
        RegAddrState: 'MN',
        RegAddrZip: 55426,
        CongressionalDistrict: 5,
      },
    ],
  },
  {
    name: 'MN-6',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/MN-6?ref=gh-pages',
    state: 'MN',
    state_and_number: 'MN-6',
    zip: 55005,
    congressionalDistrict: 6,
    addressesInDistrict: [
      {
        RegAddrLine1: '10461 KARSTON CIR',
        RegAddrCity: 'ALBERTVILLE',
        RegAddrState: 'MN',
        RegAddrZip: 55301,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '14865 BLACKFOOT ST NW',
        RegAddrCity: 'ANDOVER',
        RegAddrState: 'MN',
        RegAddrZip: 55304,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '15738 DRAKE ST NW',
        RegAddrCity: 'ANDOVER',
        RegAddrState: 'MN',
        RegAddrZip: 55304,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '17545 BUTTERNUT ST NW',
        RegAddrCity: 'ANDOVER',
        RegAddrState: 'MN',
        RegAddrZip: 55304,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '4425 149TH AVE NW',
        RegAddrCity: 'ANDOVER',
        RegAddrState: 'MN',
        RegAddrZip: 55304,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '15370 XEON ST NW',
        RegAddrCity: 'ANDOVER',
        RegAddrState: 'MN',
        RegAddrZip: 55304,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '2615 PINE RIDGE DR',
        RegAddrCity: 'ANOKA',
        RegAddrState: 'MN',
        RegAddrZip: 55303,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '2500 4TH AVE',
        RegAddrCity: 'ANOKA',
        RegAddrState: 'MN',
        RegAddrZip: 55303,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '3621 QUARRY AVE',
        RegAddrCity: 'ANOKA',
        RegAddrState: 'MN',
        RegAddrZip: 55303,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '6450 185TH AVE NW',
        RegAddrCity: 'ANOKA',
        RegAddrState: 'MN',
        RegAddrZip: 55303,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '15884 390TH ST',
        RegAddrCity: 'AVON',
        RegAddrState: 'MN',
        RegAddrZip: 56310,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '18174 221ST AVE NW',
        RegAddrCity: 'BIG LAKE',
        RegAddrState: 'MN',
        RegAddrZip: 55309,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '19219 180TH AVE NW',
        RegAddrCity: 'BIG LAKE',
        RegAddrState: 'MN',
        RegAddrZip: 55309,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '336 IDAHO AVE',
        RegAddrCity: 'BIG LAKE',
        RegAddrState: 'MN',
        RegAddrZip: 55309,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '16485 253 1/2 AVE NW',
        RegAddrCity: 'BIG LAKE',
        RegAddrState: 'MN',
        RegAddrZip: 55309,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '348 TERRITORIAL RD NE',
        RegAddrCity: 'BLAINE',
        RegAddrState: 'MN',
        RegAddrZip: 55434,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '349 109TH AVE NE',
        RegAddrCity: 'BLAINE',
        RegAddrState: 'MN',
        RegAddrZip: 55434,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '11973 OAK PARK CT NE',
        RegAddrCity: 'BLAINE',
        RegAddrState: 'MN',
        RegAddrZip: 55434,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '9065 POLK ST NE',
        RegAddrCity: 'BLAINE',
        RegAddrState: 'MN',
        RegAddrZip: 55434,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '9624 TAYLOR ST NE',
        RegAddrCity: 'BLAINE',
        RegAddrState: 'MN',
        RegAddrZip: 55434,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '11863 DUNKIRK CIR NE',
        RegAddrCity: 'BLAINE',
        RegAddrState: 'MN',
        RegAddrZip: 55449,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '2047 130TH CT NE',
        RegAddrCity: 'BLAINE',
        RegAddrState: 'MN',
        RegAddrZip: 55449,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '2104 LONGHORN LN',
        RegAddrCity: 'BUFFALO',
        RegAddrState: 'MN',
        RegAddrZip: 55313,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '404 UPPLANDA ST',
        RegAddrCity: 'BUFFALO',
        RegAddrState: 'MN',
        RegAddrZip: 55313,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '90 ALADDIN CIR NW',
        RegAddrCity: 'BUFFALO',
        RegAddrState: 'MN',
        RegAddrZip: 55313,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '2380 IBSON AVE SE',
        RegAddrCity: 'BUFFALO',
        RegAddrState: 'MN',
        RegAddrZip: 55313,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '6870 MALLARD WAY',
        RegAddrCity: 'CENTERVILLE',
        RegAddrState: 'MN',
        RegAddrZip: 55038,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '7230 BRIAN DR',
        RegAddrCity: 'CENTERVILLE',
        RegAddrState: 'MN',
        RegAddrZip: 55038,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '104B SOUTH DR',
        RegAddrCity: 'CIRCLE PINES',
        RegAddrState: 'MN',
        RegAddrZip: 55014,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '7063 100TH AVE SE',
        RegAddrCity: 'CLEAR LAKE',
        RegAddrState: 'MN',
        RegAddrZip: 55319,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '315 ELM ST',
        RegAddrCity: 'CLEARWATER',
        RegAddrState: 'MN',
        RegAddrZip: 55320,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '2733 NEVENS AVE SW',
        RegAddrCity: 'COKATO',
        RegAddrState: 'MN',
        RegAddrZip: 55321,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '404 12TH ST N',
        RegAddrCity: 'COLD SPRING',
        RegAddrState: 'MN',
        RegAddrZip: 56320,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '1080 2ND ST SW',
        RegAddrCity: 'DELANO',
        RegAddrState: 'MN',
        RegAddrZip: 55328,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '400 EVANS AVE NW',
        RegAddrCity: 'ELK RIVER',
        RegAddrState: 'MN',
        RegAddrZip: 55330,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '7459 205TH AVE NW',
        RegAddrCity: 'ELK RIVER',
        RegAddrState: 'MN',
        RegAddrZip: 55330,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '19164 CONCORD ST NW',
        RegAddrCity: 'ELK RIVER',
        RegAddrState: 'MN',
        RegAddrZip: 55330,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '19567 TWIN LAKES RD NW',
        RegAddrCity: 'ELK RIVER',
        RegAddrState: 'MN',
        RegAddrZip: 55330,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '604 1ST ST NE',
        RegAddrCity: 'FOREST LAKE',
        RegAddrState: 'MN',
        RegAddrZip: 55025,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '22810 IMPERIAL AVE N',
        RegAddrCity: 'FOREST LAKE',
        RegAddrState: 'MN',
        RegAddrZip: 55025,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '2409 BUNKER LAKE BLVD NE',
        RegAddrCity: 'HAM LAKE',
        RegAddrState: 'MN',
        RegAddrZip: 55304,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '3923 COUNTY ROAD 6 SW',
        RegAddrCity: 'HOWARD LAKE',
        RegAddrState: 'MN',
        RegAddrZip: 55349,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '14262 GARDEN WAY N',
        RegAddrCity: 'HUGO',
        RegAddrState: 'MN',
        RegAddrZip: 55038,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '8711 DUNLAP AVE',
        RegAddrCity: 'LEXINGTON',
        RegAddrState: 'MN',
        RegAddrZip: 55014,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '9312 RYAN PL',
        RegAddrCity: 'LEXINGTON',
        RegAddrState: 'MN',
        RegAddrZip: 55014,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '7168 SUNFISH CT',
        RegAddrCity: 'LINO LAKES',
        RegAddrState: 'MN',
        RegAddrZip: 55014,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '285 MORNING DR',
        RegAddrCity: 'MAYER',
        RegAddrState: 'MN',
        RegAddrZip: 55360,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '9325 JABER AVE NE',
        RegAddrCity: 'MONTICELLO',
        RegAddrState: 'MN',
        RegAddrZip: 55362,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '6839 GINGHAM CT',
        RegAddrCity: 'MONTICELLO',
        RegAddrState: 'MN',
        RegAddrZip: 55362,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '6105 MACLYNN AVE NE',
        RegAddrCity: 'OTSEGO',
        RegAddrState: 'MN',
        RegAddrZip: 55301,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '6386 MANCHESTER WAY NE',
        RegAddrCity: 'OTSEGO',
        RegAddrState: 'MN',
        RegAddrZip: 55301,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '10285 99TH ST NE',
        RegAddrCity: 'OTSEGO',
        RegAddrState: 'MN',
        RegAddrZip: 55362,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '9301 KAGAN CIR NE',
        RegAddrCity: 'OTSEGO',
        RegAddrState: 'MN',
        RegAddrZip: 55362,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '5921 142ND AVE NW',
        RegAddrCity: 'RAMSEY',
        RegAddrState: 'MN',
        RegAddrZip: 55303,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '1608 37TH ST S',
        RegAddrCity: 'SAINT CLOUD',
        RegAddrState: 'MN',
        RegAddrZip: 56301,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '3615 CREEKVIEW RD',
        RegAddrCity: 'SAINT CLOUD',
        RegAddrState: 'MN',
        RegAddrZip: 56301,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '1939 10TH AVE S',
        RegAddrCity: 'SAINT CLOUD',
        RegAddrState: 'MN',
        RegAddrZip: 56301,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '2600 15TH ST N',
        RegAddrCity: 'SAINT CLOUD',
        RegAddrState: 'MN',
        RegAddrZip: 56303,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '1003 17TH AVE N',
        RegAddrCity: 'SAINT CLOUD',
        RegAddrState: 'MN',
        RegAddrZip: 56303,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '1125 21ST AVE N',
        RegAddrCity: 'SAINT CLOUD',
        RegAddrState: 'MN',
        RegAddrZip: 56303,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '12778 43RD ST NE',
        RegAddrCity: 'SAINT MICHAEL',
        RegAddrState: 'MN',
        RegAddrZip: 55376,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '1136 3RD ST N',
        RegAddrCity: 'SARTELL',
        RegAddrState: 'MN',
        RegAddrZip: 56377,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '3300 SOUTHBRIDGE AVE N',
        RegAddrCity: 'SARTELL',
        RegAddrState: 'MN',
        RegAddrZip: 56377,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '7050 55TH ST NE',
        RegAddrCity: 'SAUK RAPIDS',
        RegAddrState: 'MN',
        RegAddrZip: 56379,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '1010 10TH AVE N',
        RegAddrCity: 'SAUK RAPIDS',
        RegAddrState: 'MN',
        RegAddrZip: 56379,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '5914 5TH AVE NE',
        RegAddrCity: 'SAUK RAPIDS',
        RegAddrState: 'MN',
        RegAddrZip: 56379,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '421 4TH ST S',
        RegAddrCity: 'SAUK RAPIDS',
        RegAddrState: 'MN',
        RegAddrZip: 56379,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '13581 CAMPFIRE CIR',
        RegAddrCity: 'SOUTH HAVEN',
        RegAddrState: 'MN',
        RegAddrZip: 55382,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '2850 237TH AVE NW',
        RegAddrCity: 'ST FRANCIS',
        RegAddrState: 'MN',
        RegAddrZip: 55070,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '219 13TH AVE SE',
        RegAddrCity: 'ST JOSEPH',
        RegAddrState: 'MN',
        RegAddrZip: 56374,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '630 GUMTREE CT NE',
        RegAddrCity: 'ST JOSEPH',
        RegAddrState: 'MN',
        RegAddrZip: 56374,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '12050 SQUARE LAKE TRAIL CT N',
        RegAddrCity: 'STILLWATER',
        RegAddrState: 'MN',
        RegAddrZip: 55082,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '1165 GREENFIELD CIR',
        RegAddrCity: 'WACONIA',
        RegAddrState: 'MN',
        RegAddrZip: 55387,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '1662 SAINT GEORGE ST',
        RegAddrCity: 'WACONIA',
        RegAddrState: 'MN',
        RegAddrZip: 55387,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '6730 215TH AVE NE',
        RegAddrCity: 'WYOMING',
        RegAddrState: 'MN',
        RegAddrZip: 55092,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '11335 246TH CIR',
        RegAddrCity: 'ZIMMERMAN',
        RegAddrState: 'MN',
        RegAddrZip: 55398,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '28794 133RD ST NW',
        RegAddrCity: 'ZIMMERMAN',
        RegAddrState: 'MN',
        RegAddrZip: 55398,
        CongressionalDistrict: 6,
      },
    ],
  },
  {
    name: 'MN-7',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/MN-7?ref=gh-pages',
    state: 'MN',
    state_and_number: 'MN-7',
    zip: 55307,
    congressionalDistrict: 7,
    addressesInDistrict: [
      {
        RegAddrLine1: '802 4TH AVE E',
        RegAddrCity: 'ADA',
        RegAddrState: 'MN',
        RegAddrZip: 56510,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '2930 W LAKE JESSIE DR SE',
        RegAddrCity: 'ALEXANDRIA',
        RegAddrState: 'MN',
        RegAddrZip: 56308,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '1955 TROUSIL DR SW',
        RegAddrCity: 'ALEXANDRIA',
        RegAddrState: 'MN',
        RegAddrZip: 56308,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '1303 SCENIC HEIGHTS RD NE',
        RegAddrCity: 'ALEXANDRIA',
        RegAddrState: 'MN',
        RegAddrZip: 56308,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '5228 COUNTY ROAD 21 SW',
        RegAddrCity: 'ALEXANDRIA',
        RegAddrState: 'MN',
        RegAddrZip: 56308,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '1110 DOUGLAS ST',
        RegAddrCity: 'ALEXANDRIA',
        RegAddrState: 'MN',
        RegAddrZip: 56308,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '1319 COUNTY ROAD 22 NW',
        RegAddrCity: 'ALEXANDRIA',
        RegAddrState: 'MN',
        RegAddrZip: 56308,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '602 2ND ST S',
        RegAddrCity: 'ATWATER',
        RegAddrState: 'MN',
        RegAddrZip: 56209,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '475 LARK ST',
        RegAddrCity: 'AUDUBON',
        RegAddrState: 'MN',
        RegAddrZip: 56511,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '2604 OL WHISPERING PINES RD SW',
        RegAddrCity: 'BEMIDJI',
        RegAddrState: 'MN',
        RegAddrZip: 56601,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '1354 205TH AVE N',
        RegAddrCity: 'CANBY',
        RegAddrState: 'MN',
        RegAddrZip: 56220,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '68408 215TH ST',
        RegAddrCity: 'DARWIN',
        RegAddrState: 'MN',
        RegAddrZip: 55324,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '16794 705TH AVE',
        RegAddrCity: 'DASSEL',
        RegAddrState: 'MN',
        RegAddrZip: 55325,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '403 N 4TH ST',
        RegAddrCity: 'DAWSON',
        RegAddrState: 'MN',
        RegAddrZip: 56232,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '44052 COUNTY HIGHWAY 35',
        RegAddrCity: 'DENT',
        RegAddrState: 'MN',
        RegAddrZip: 56528,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '2226 COUNTRY CT NW',
        RegAddrCity: 'E GRAND FORKS',
        RegAddrState: 'MN',
        RegAddrZip: 56721,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '3 WYLIE CT',
        RegAddrCity: 'E GRAND FORKS',
        RegAddrState: 'MN',
        RegAddrZip: 56721,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '909 GREENWOOD DR SE',
        RegAddrCity: 'E GRAND FORKS',
        RegAddrState: 'MN',
        RegAddrZip: 56721,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '230 PARK AVE',
        RegAddrCity: 'EDGERTON',
        RegAddrState: 'MN',
        RegAddrZip: 56128,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '32517 PANFISH RD',
        RegAddrCity: 'ERHARD',
        RegAddrState: 'MN',
        RegAddrZip: 56534,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '45567 CENTER AVE S',
        RegAddrCity: 'FERTILE',
        RegAddrState: 'MN',
        RegAddrZip: 56540,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '4759 86TH CIR',
        RegAddrCity: 'GLENCOE',
        RegAddrState: 'MN',
        RegAddrZip: 55336,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '16659 411TH AVE',
        RegAddrCity: 'GLENCOE',
        RegAddrState: 'MN',
        RegAddrZip: 55336,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '224 16TH ST E',
        RegAddrCity: 'GLENCOE',
        RegAddrState: 'MN',
        RegAddrZip: 55336,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '14737 80TH AVE S',
        RegAddrCity: 'GLYNDON',
        RegAddrState: 'MN',
        RegAddrZip: 56547,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '28858 US HIGHWAY 75',
        RegAddrCity: 'GRACEVILLE',
        RegAddrState: 'MN',
        RegAddrZip: 56240,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '581 8TH ST',
        RegAddrCity: 'HANCOCK',
        RegAddrState: 'MN',
        RegAddrZip: 56244,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '13053 310TH AVE',
        RegAddrCity: 'HERMAN',
        RegAddrState: 'MN',
        RegAddrZip: 56248,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '13565 210TH ST',
        RegAddrCity: 'HUTCHINSON',
        RegAddrState: 'MN',
        RegAddrZip: 55350,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '3438 250TH ST',
        RegAddrCity: 'LAKE BRONSON',
        RegAddrState: 'MN',
        RegAddrZip: 56734,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '708 1ST AVE W',
        RegAddrCity: 'LAMBERTON',
        RegAddrState: 'MN',
        RegAddrZip: 56152,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '1015 PRAIRIE RIDGE LN',
        RegAddrCity: 'LESTER PR',
        RegAddrState: 'MN',
        RegAddrZip: 55354,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '224 HARMON LN',
        RegAddrCity: 'LITCHFIELD',
        RegAddrState: 'MN',
        RegAddrZip: 55355,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '241 AURORA AVE',
        RegAddrCity: 'LOWRY',
        RegAddrState: 'MN',
        RegAddrZip: 56349,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '311 ACORN CT',
        RegAddrCity: 'LYND',
        RegAddrState: 'MN',
        RegAddrZip: 56157,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '2207 470TH ST',
        RegAddrCity: 'MAHNOMEN',
        RegAddrState: 'MN',
        RegAddrZip: 56557,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '407 DONITA AVE',
        RegAddrCity: 'MARSHALL',
        RegAddrState: 'MN',
        RegAddrZip: 56258,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '14856 WALNUT LN NE',
        RegAddrCity: 'MILTONA',
        RegAddrState: 'MN',
        RegAddrZip: 56354,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '1617 BENSON RD',
        RegAddrCity: 'MONTEVIDEO',
        RegAddrState: 'MN',
        RegAddrZip: 56265,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '104 BUCKEYE DR',
        RegAddrCity: 'MONTEVIDEO',
        RegAddrState: 'MN',
        RegAddrZip: 56265,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '1810 32ND ST S',
        RegAddrCity: 'MOORHEAD',
        RegAddrState: 'MN',
        RegAddrZip: 56560,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '2123 18TH ST S',
        RegAddrCity: 'MOORHEAD',
        RegAddrState: 'MN',
        RegAddrZip: 56560,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '1525 34TH AVE S',
        RegAddrCity: 'MOORHEAD',
        RegAddrState: 'MN',
        RegAddrZip: 56560,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '43792 240TH ST',
        RegAddrCity: 'MORGAN',
        RegAddrState: 'MN',
        RegAddrZip: 56266,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '109 MEADOW LN',
        RegAddrCity: 'MORRIS',
        RegAddrState: 'MN',
        RegAddrZip: 56267,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '46602 POSS BEACH RD',
        RegAddrCity: 'PEL RAPIDS',
        RegAddrState: 'MN',
        RegAddrZip: 56572,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '344 5TH AVE SW',
        RegAddrCity: 'PERHAM',
        RegAddrState: 'MN',
        RegAddrZip: 56573,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '33931 304TH ST',
        RegAddrCity: 'ROSEAU',
        RegAddrState: 'MN',
        RegAddrZip: 56751,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '244 MAIN AVE',
        RegAddrCity: 'SHEVLIN',
        RegAddrState: 'MN',
        RegAddrZip: 56676,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '640 MOWBRAY ST',
        RegAddrCity: 'STEWART',
        RegAddrState: 'MN',
        RegAddrZip: 55385,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '13435 STATE HIGHWAY 1 NW',
        RegAddrCity: 'THIEF RVR FLS',
        RegAddrState: 'MN',
        RegAddrZip: 56701,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '11333 180TH ST',
        RegAddrCity: 'TRACY',
        RegAddrState: 'MN',
        RegAddrZip: 56175,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '1330 120TH ST',
        RegAddrCity: 'VERDI',
        RegAddrState: 'MN',
        RegAddrZip: 56164,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '26808 170TH AVE NW',
        RegAddrCity: 'VIKING',
        RegAddrState: 'MN',
        RegAddrZip: 56760,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '605 12TH AVE SE',
        RegAddrCity: 'WASECA',
        RegAddrState: 'MN',
        RegAddrZip: 56093,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '5859 COUNTY ROAD 15',
        RegAddrCity: 'WHEATON',
        RegAddrState: 'MN',
        RegAddrZip: 56296,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '504 13TH ST SW',
        RegAddrCity: 'WILLMAR',
        RegAddrState: 'MN',
        RegAddrZip: 56201,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '202 8TH ST NW',
        RegAddrCity: 'WILLMAR',
        RegAddrState: 'MN',
        RegAddrZip: 56201,
        CongressionalDistrict: 7,
      },
    ],
  },
  {
    name: 'MN-8',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/MN-8?ref=gh-pages',
    state: 'MN',
    state_and_number: 'MN-8',
    zip: 55006,
    congressionalDistrict: 8,
    addressesInDistrict: [
      {
        RegAddrLine1: '110 GRACESON AVE N',
        RegAddrCity: 'AKELEY',
        RegAddrState: 'MN',
        RegAddrZip: 56433,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '61816 BANNING LINE RD',
        RegAddrCity: 'ASKOV',
        RegAddrState: 'MN',
        RegAddrZip: 55704,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '4453 CARVER RD NE',
        RegAddrCity: 'BEMIDJI',
        RegAddrState: 'MN',
        RegAddrZip: 56601,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '7939 LONG LAKE DR NE',
        RegAddrCity: 'BEMIDJI',
        RegAddrState: 'MN',
        RegAddrZip: 56601,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '201 SCENIC ESTATES DR',
        RegAddrCity: 'BIGFORK',
        RegAddrState: 'MN',
        RegAddrZip: 56628,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '401 3RD AVE N',
        RegAddrCity: 'BIWABIK',
        RegAddrState: 'MN',
        RegAddrZip: 55708,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '20434 W SWAN RIVER RD',
        RegAddrCity: 'BOVEY',
        RegAddrState: 'MN',
        RegAddrZip: 55709,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '11876 RED PINE RD',
        RegAddrCity: 'BRAINERD',
        RegAddrState: 'MN',
        RegAddrZip: 56401,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '8836 DOROTHY AVE',
        RegAddrCity: 'BRAINERD',
        RegAddrState: 'MN',
        RegAddrZip: 56401,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '9427 LIBBY LN',
        RegAddrCity: 'BRAINERD',
        RegAddrState: 'MN',
        RegAddrZip: 56401,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '31833 CASTLEWOOD CT',
        RegAddrCity: 'BREEZY POINT',
        RegAddrState: 'MN',
        RegAddrZip: 56472,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '8421 NELSON RD',
        RegAddrCity: 'BRITT',
        RegAddrState: 'MN',
        RegAddrZip: 55710,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '5101 297TH AVE NW',
        RegAddrCity: 'CAMBRIDGE',
        RegAddrState: 'MN',
        RegAddrZip: 55008,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '1360 32ND AVE SW',
        RegAddrCity: 'CAMBRIDGE',
        RegAddrState: 'MN',
        RegAddrZip: 55008,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '6356 152ND ST NW',
        RegAddrCity: 'CASS LAKE',
        RegAddrState: 'MN',
        RegAddrZip: 56633,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '32819 KALE AVE',
        RegAddrCity: 'CHISAGO CITY',
        RegAddrState: 'MN',
        RegAddrZip: 55013,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '12 9 1/2 ST NW',
        RegAddrCity: 'CHISHOLM',
        RegAddrState: 'MN',
        RegAddrZip: 55719,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '1215 SLATE ST',
        RegAddrCity: 'CLOQUET',
        RegAddrState: 'MN',
        RegAddrZip: 55720,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '512 SKYLINE BLVD',
        RegAddrCity: 'CLOQUET',
        RegAddrState: 'MN',
        RegAddrZip: 55720,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '2502 KELLY AVE',
        RegAddrCity: 'CLOQUET',
        RegAddrState: 'MN',
        RegAddrZip: 55720,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '38837 N SUGAR LAKE RD',
        RegAddrCity: 'COHASSET',
        RegAddrState: 'MN',
        RegAddrZip: 55721,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '15161 CROOKED LAKE RD',
        RegAddrCity: 'DEERWOOD',
        RegAddrState: 'MN',
        RegAddrZip: 56444,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '24164 CUYUNA GREENS DR',
        RegAddrCity: 'DEERWOOD',
        RegAddrState: 'MN',
        RegAddrZip: 56444,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '3844 BIRCHWOOD RD',
        RegAddrCity: 'DULUTH',
        RegAddrState: 'MN',
        RegAddrZip: 55803,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '6931 BEAR ISLAND RD',
        RegAddrCity: 'DULUTH',
        RegAddrState: 'MN',
        RegAddrZip: 55803,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '5027 AVONDALE ST',
        RegAddrCity: 'DULUTH',
        RegAddrState: 'MN',
        RegAddrZip: 55804,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '5597 N SHORE DR',
        RegAddrCity: 'DULUTH',
        RegAddrState: 'MN',
        RegAddrZip: 55804,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '3823 W 4TH ST',
        RegAddrCity: 'DULUTH',
        RegAddrState: 'MN',
        RegAddrZip: 55807,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '17 ENGLAND AVE',
        RegAddrCity: 'DULUTH',
        RegAddrState: 'MN',
        RegAddrZip: 55808,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '9232 LAWN ST',
        RegAddrCity: 'DULUTH',
        RegAddrState: 'MN',
        RegAddrZip: 55810,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '4014 BAYVIEW DR NW',
        RegAddrCity: 'HACKENSACK',
        RegAddrState: 'MN',
        RegAddrZip: 56452,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '1307 13TH AVE',
        RegAddrCity: 'INTL FALLS',
        RegAddrState: 'MN',
        RegAddrZip: 56649,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '54595 SHADOW OAK RD',
        RegAddrCity: 'KERRICK',
        RegAddrState: 'MN',
        RegAddrZip: 55756,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '216 RIVERVIEW ST',
        RegAddrCity: 'KNIFE RIVER',
        RegAddrState: 'MN',
        RegAddrZip: 55609,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '37623 NORTHSTAR DR',
        RegAddrCity: 'LAKE GEORGE',
        RegAddrState: 'MN',
        RegAddrZip: 56458,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '25675 COUNTY 16',
        RegAddrCity: 'LAPORTE',
        RegAddrState: 'MN',
        RegAddrZip: 56461,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '12600 LAKE BLVD',
        RegAddrCity: 'LINDSTROM',
        RegAddrState: 'MN',
        RegAddrZip: 55045,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '16947 MISSISSIPPI BLVD',
        RegAddrCity: 'LITTLE FALLS',
        RegAddrState: 'MN',
        RegAddrZip: 56345,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '6557 COUNTY ROAD 126 NE',
        RegAddrCity: 'LONGVILLE',
        RegAddrState: 'MN',
        RegAddrZip: 56655,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '606 ALICE AVE',
        RegAddrCity: 'MARBLE',
        RegAddrState: 'MN',
        RegAddrZip: 55764,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '21783 COUNTY ROAD 3',
        RegAddrCity: 'MERRIFIELD',
        RegAddrState: 'MN',
        RegAddrZip: 56465,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '5218 170TH ST',
        RegAddrCity: 'MILACA',
        RegAddrState: 'MN',
        RegAddrZip: 56353,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '4663 WEST RD',
        RegAddrCity: 'MOOSE LAKE',
        RegAddrState: 'MN',
        RegAddrZip: 55767,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '32238 HIGHWAY 47',
        RegAddrCity: 'MORA',
        RegAddrState: 'MN',
        RegAddrZip: 55051,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '704 BLUEBIRD ST',
        RegAddrCity: 'MORA',
        RegAddrState: 'MN',
        RegAddrZip: 55051,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '2113 NAPLES ST',
        RegAddrCity: 'MORA',
        RegAddrState: 'MN',
        RegAddrZip: 55051,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '12387 71ST AVE SW',
        RegAddrCity: 'MOTLEY',
        RegAddrState: 'MN',
        RegAddrZip: 56466,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '18193 LITTLE MC CARTHY LAKE RD',
        RegAddrCity: 'NASHWAUK',
        RegAddrState: 'MN',
        RegAddrZip: 55769,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '5101 381ST LN',
        RegAddrCity: 'NORTH BRANCH',
        RegAddrState: 'MN',
        RegAddrZip: 55056,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '38152 EVERTON AVE',
        RegAddrCity: 'NORTH BRANCH',
        RegAddrState: 'MN',
        RegAddrZip: 55056,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '19669 COUNTY 4',
        RegAddrCity: 'PARK RAPIDS',
        RegAddrState: 'MN',
        RegAddrZip: 56470,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '202 4TH ST E',
        RegAddrCity: 'PARK RAPIDS',
        RegAddrState: 'MN',
        RegAddrZip: 56470,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '18642 BASSWOOD LN',
        RegAddrCity: 'PINE CITY',
        RegAddrState: 'MN',
        RegAddrZip: 55063,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '311 4TH AVE S',
        RegAddrCity: 'PRINCETON',
        RegAddrState: 'MN',
        RegAddrZip: 55371,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '321 N BIRCH ST',
        RegAddrCity: 'ROYALTON',
        RegAddrState: 'MN',
        RegAddrZip: 56373,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '55 E 4TH ST',
        RegAddrCity: 'RUSH CITY',
        RegAddrState: 'MN',
        RegAddrZip: 55069,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '51490 RUSH LAKE TRL',
        RegAddrCity: 'RUSH CITY',
        RegAddrState: 'MN',
        RegAddrZip: 55069,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '181 CRAMER RD',
        RegAddrCity: 'SCHROEDER',
        RegAddrState: 'MN',
        RegAddrZip: 55613,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '21179 260TH ST',
        RegAddrCity: 'SEBEKA',
        RegAddrState: 'MN',
        RegAddrZip: 56477,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '24896 BAYLOR ST NE',
        RegAddrCity: 'STACY',
        RegAddrState: 'MN',
        RegAddrZip: 55079,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '1408 3RD ST NE',
        RegAddrCity: 'STAPLES',
        RegAddrState: 'MN',
        RegAddrZip: 56479,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '1377 TWO HARBORS RD',
        RegAddrCity: 'TWO HARBORS',
        RegAddrState: 'MN',
        RegAddrZip: 55616,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '714 12TH AVE',
        RegAddrCity: 'TWO HARBORS',
        RegAddrState: 'MN',
        RegAddrZip: 55616,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '1716 HIGHWAY 61',
        RegAddrCity: 'TWO HARBORS',
        RegAddrState: 'MN',
        RegAddrZip: 55616,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '18558 205TH AVE',
        RegAddrCity: 'VERNDALE',
        RegAddrState: 'MN',
        RegAddrZip: 56481,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '1005 S 10TH AVE',
        RegAddrCity: 'VIRGINIA',
        RegAddrState: 'MN',
        RegAddrZip: 55792,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '613 9TH ST N',
        RegAddrCity: 'VIRGINIA',
        RegAddrState: 'MN',
        RegAddrZip: 55792,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '5645 273RD ST',
        RegAddrCity: 'WYOMING',
        RegAddrState: 'MN',
        RegAddrZip: 55092,
        CongressionalDistrict: 8,
      },
    ],
  },
  {
    name: 'MO-1',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/MO-1?ref=gh-pages',
    state: 'MO',
    state_and_number: 'MO-1',
    zip: 63031,
    congressionalDistrict: 1,
    addressesInDistrict: [
      {
        RegAddrLine1: '2673 TENBROOK RD',
        RegAddrCity: 'ARNOLD',
        RegAddrState: 'MO',
        RegAddrZip: 63010,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1345 PATTERSON RD',
        RegAddrCity: 'FLORISSANT',
        RegAddrState: 'MO',
        RegAddrZip: 63031,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '655 WIETHAUPT RD',
        RegAddrCity: 'FLORISSANT',
        RegAddrState: 'MO',
        RegAddrZip: 63031,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1879 HIGH SUN DR',
        RegAddrCity: 'FLORISSANT',
        RegAddrState: 'MO',
        RegAddrZip: 63031,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '250 S NEW FLORISSANT RD',
        RegAddrCity: 'FLORISSANT',
        RegAddrState: 'MO',
        RegAddrZip: 63031,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1007 GIFFORD CT',
        RegAddrCity: 'FLORISSANT',
        RegAddrState: 'MO',
        RegAddrZip: 63031,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1665 ZURICH DR',
        RegAddrCity: 'FLORISSANT',
        RegAddrState: 'MO',
        RegAddrZip: 63031,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '601 BLUFF PARKS DR',
        RegAddrCity: 'FLORISSANT',
        RegAddrState: 'MO',
        RegAddrZip: 63031,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '500 S JEFFERSON ST',
        RegAddrCity: 'FLORISSANT',
        RegAddrState: 'MO',
        RegAddrZip: 63031,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '13021 KINSLEY HEIGHTS DR',
        RegAddrCity: 'FLORISSANT',
        RegAddrState: 'MO',
        RegAddrZip: 63033,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '6361 SANDYCREEK DR',
        RegAddrCity: 'FLORISSANT',
        RegAddrState: 'MO',
        RegAddrZip: 63033,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '11879 GALBA DR',
        RegAddrCity: 'FLORISSANT',
        RegAddrState: 'MO',
        RegAddrZip: 63033,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '4183 MILETUS DR',
        RegAddrCity: 'FLORISSANT',
        RegAddrState: 'MO',
        RegAddrZip: 63033,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '5381 TRAILBEND DR',
        RegAddrCity: 'FLORISSANT',
        RegAddrState: 'MO',
        RegAddrZip: 63033,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '4131 90TH AVE',
        RegAddrCity: 'FLORISSANT',
        RegAddrState: 'MO',
        RegAddrZip: 63034,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '3645 CANDLEWYCK GREEN CT',
        RegAddrCity: 'FLORISSANT',
        RegAddrState: 'MO',
        RegAddrZip: 63034,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '590 VILLAGE SQUARE DR',
        RegAddrCity: 'HAZELWOOD',
        RegAddrState: 'MO',
        RegAddrZip: 63042,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '605 COACH HOUSE DR',
        RegAddrCity: 'HAZELWOOD',
        RegAddrState: 'MO',
        RegAddrZip: 63042,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '4813 PLUM TREE LN',
        RegAddrCity: 'HAZELWOOD',
        RegAddrState: 'MO',
        RegAddrZip: 63042,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '10612 SAINT MATTHEW LN',
        RegAddrCity: 'SAINT ANN',
        RegAddrState: 'MO',
        RegAddrZip: 63074,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '3119 SAINT DAVID LN',
        RegAddrCity: 'SAINT ANN',
        RegAddrState: 'MO',
        RegAddrZip: 63074,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '11075 GERTRUDE AVE',
        RegAddrCity: 'SAINT ANN',
        RegAddrState: 'MO',
        RegAddrZip: 63074,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '3575 LONG DR',
        RegAddrCity: 'SAINT ANN',
        RegAddrState: 'MO',
        RegAddrZip: 63074,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '800 N TUCKER BLVD',
        RegAddrCity: 'SAINT LOUIS',
        RegAddrState: 'MO',
        RegAddrZip: 63101,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1601 CARR DR',
        RegAddrCity: 'SAINT LOUIS',
        RegAddrState: 'MO',
        RegAddrZip: 63106,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2324 SAINT LOUIS AVE',
        RegAddrCity: 'SAINT LOUIS',
        RegAddrState: 'MO',
        RegAddrZip: 63106,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '3710 PALM ST',
        RegAddrCity: 'SAINT LOUIS',
        RegAddrState: 'MO',
        RegAddrZip: 63107,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '3910 SULLIVAN AVE',
        RegAddrCity: 'SAINT LOUIS',
        RegAddrState: 'MO',
        RegAddrZip: 63107,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2719 DODIER ST',
        RegAddrCity: 'SAINT LOUIS',
        RegAddrState: 'MO',
        RegAddrZip: 63107,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1407 FARRAR ST',
        RegAddrCity: 'SAINT LOUIS',
        RegAddrState: 'MO',
        RegAddrZip: 63107,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '5419 CLIFTON AVE',
        RegAddrCity: 'SAINT LOUIS',
        RegAddrState: 'MO',
        RegAddrZip: 63109,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '3718 BERGER AVE',
        RegAddrCity: 'SAINT LOUIS',
        RegAddrState: 'MO',
        RegAddrZip: 63109,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '6455 MURDOCH AVE',
        RegAddrCity: 'SAINT LOUIS',
        RegAddrState: 'MO',
        RegAddrZip: 63109,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '5111 MCCAUSLAND AVE',
        RegAddrCity: 'SAINT LOUIS',
        RegAddrState: 'MO',
        RegAddrZip: 63109,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '5890 EICHELBERGER ST',
        RegAddrCity: 'SAINT LOUIS',
        RegAddrState: 'MO',
        RegAddrZip: 63109,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '5812 THOLOZAN AVE',
        RegAddrCity: 'SAINT LOUIS',
        RegAddrState: 'MO',
        RegAddrZip: 63109,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '929 FILLMORE ST',
        RegAddrCity: 'SAINT LOUIS',
        RegAddrState: 'MO',
        RegAddrZip: 63111,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '9231 BRISTOL AVE',
        RegAddrCity: 'SAINT LOUIS',
        RegAddrState: 'MO',
        RegAddrZip: 63114,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1829 NORTHFIELD DR',
        RegAddrCity: 'SAINT LOUIS',
        RegAddrState: 'MO',
        RegAddrZip: 63114,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '9445 MINERVA AVE',
        RegAddrCity: 'SAINT LOUIS',
        RegAddrState: 'MO',
        RegAddrZip: 63114,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '9939 EDMIL AVE',
        RegAddrCity: 'SAINT LOUIS',
        RegAddrState: 'MO',
        RegAddrZip: 63114,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '3678 CHANEY CT',
        RegAddrCity: 'SAINT LOUIS',
        RegAddrState: 'MO',
        RegAddrZip: 63114,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2811 CALVERT AVE',
        RegAddrCity: 'SAINT LOUIS',
        RegAddrState: 'MO',
        RegAddrZip: 63114,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '5454 UNION BLVD',
        RegAddrCity: 'SAINT LOUIS',
        RegAddrState: 'MO',
        RegAddrZip: 63115,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '4660 KORTE AVE',
        RegAddrCity: 'SAINT LOUIS',
        RegAddrState: 'MO',
        RegAddrZip: 63115,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '7109 YATES ST',
        RegAddrCity: 'SAINT LOUIS',
        RegAddrState: 'MO',
        RegAddrZip: 63116,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '4330 DEWEY AVE',
        RegAddrCity: 'SAINT LOUIS',
        RegAddrState: 'MO',
        RegAddrZip: 63116,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '3652 HARTFORD ST',
        RegAddrCity: 'SAINT LOUIS',
        RegAddrState: 'MO',
        RegAddrZip: 63116,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '3856 UTAH PL',
        RegAddrCity: 'SAINT LOUIS',
        RegAddrState: 'MO',
        RegAddrZip: 63116,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '3137 MICHIGAN AVE',
        RegAddrCity: 'SAINT LOUIS',
        RegAddrState: 'MO',
        RegAddrZip: 63118,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '3415 JUNIATA ST',
        RegAddrCity: 'SAINT LOUIS',
        RegAddrState: 'MO',
        RegAddrZip: 63118,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '4127 S COMPTON AVE',
        RegAddrCity: 'SAINT LOUIS',
        RegAddrState: 'MO',
        RegAddrZip: 63118,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '10 NEWPORT AVE',
        RegAddrCity: 'SAINT LOUIS',
        RegAddrState: 'MO',
        RegAddrZip: 63119,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '832 PROVIDENCE AVE',
        RegAddrCity: 'SAINT LOUIS',
        RegAddrState: 'MO',
        RegAddrZip: 63119,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '14 CLYDEHURST DR',
        RegAddrCity: 'SAINT LOUIS',
        RegAddrState: 'MO',
        RegAddrZip: 63119,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '4948 GENEVIEVE AVE',
        RegAddrCity: 'SAINT LOUIS',
        RegAddrState: 'MO',
        RegAddrZip: 63120,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '7241 NORMANDY PL',
        RegAddrCity: 'SAINT LOUIS',
        RegAddrState: 'MO',
        RegAddrZip: 63121,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '6211 GREYLING DR',
        RegAddrCity: 'SAINT LOUIS',
        RegAddrState: 'MO',
        RegAddrZip: 63121,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '7216 CANTERBURY DR',
        RegAddrCity: 'SAINT LOUIS',
        RegAddrState: 'MO',
        RegAddrZip: 63121,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '811 LISAKAY DR',
        RegAddrCity: 'SAINT LOUIS',
        RegAddrState: 'MO',
        RegAddrZip: 63122,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '8730 W KINGSBURY AVE',
        RegAddrCity: 'SAINT LOUIS',
        RegAddrState: 'MO',
        RegAddrZip: 63124,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '7459 DREXEL DR',
        RegAddrCity: 'SAINT LOUIS',
        RegAddrState: 'MO',
        RegAddrZip: 63130,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '6840 KINGSBURY BLVD',
        RegAddrCity: 'SAINT LOUIS',
        RegAddrState: 'MO',
        RegAddrZip: 63130,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '7727 STANFORD AVE',
        RegAddrCity: 'SAINT LOUIS',
        RegAddrState: 'MO',
        RegAddrZip: 63130,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '7362 CHAMBERLAIN AVE',
        RegAddrCity: 'SAINT LOUIS',
        RegAddrState: 'MO',
        RegAddrZip: 63130,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '7323 TEASDALE AVE',
        RegAddrCity: 'SAINT LOUIS',
        RegAddrState: 'MO',
        RegAddrZip: 63130,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1262 HAFNER PL',
        RegAddrCity: 'SAINT LOUIS',
        RegAddrState: 'MO',
        RegAddrZip: 63130,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '34 HEATHER HILL LN',
        RegAddrCity: 'SAINT LOUIS',
        RegAddrState: 'MO',
        RegAddrZip: 63132,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '6 BRANFORD DR',
        RegAddrCity: 'SAINT LOUIS',
        RegAddrState: 'MO',
        RegAddrZip: 63132,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '11 ARBOR RD',
        RegAddrCity: 'SAINT LOUIS',
        RegAddrState: 'MO',
        RegAddrZip: 63132,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1843 ENGELHOLM AVE',
        RegAddrCity: 'SAINT LOUIS',
        RegAddrState: 'MO',
        RegAddrZip: 63133,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '9808 HAROLD DR',
        RegAddrCity: 'SAINT LOUIS',
        RegAddrState: 'MO',
        RegAddrZip: 63134,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '6633 TORLINA DR',
        RegAddrCity: 'SAINT LOUIS',
        RegAddrState: 'MO',
        RegAddrZip: 63134,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1021 EDGEHILL DR',
        RegAddrCity: 'SAINT LOUIS',
        RegAddrState: 'MO',
        RegAddrZip: 63135,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '325 KIRK DR',
        RegAddrCity: 'SAINT LOUIS',
        RegAddrState: 'MO',
        RegAddrZip: 63135,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1021 EDGEHILL DR',
        RegAddrCity: 'SAINT LOUIS',
        RegAddrState: 'MO',
        RegAddrZip: 63135,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '9720 CAMBRIA DR',
        RegAddrCity: 'SAINT LOUIS',
        RegAddrState: 'MO',
        RegAddrZip: 63136,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '5642 CURRY AVE',
        RegAddrCity: 'SAINT LOUIS',
        RegAddrState: 'MO',
        RegAddrZip: 63136,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '10118 VISCOUNT DR',
        RegAddrCity: 'SAINT LOUIS',
        RegAddrState: 'MO',
        RegAddrZip: 63136,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2227 ARMITAGE DR',
        RegAddrCity: 'SAINT LOUIS',
        RegAddrState: 'MO',
        RegAddrZip: 63136,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '10100 ELBA LN',
        RegAddrCity: 'SAINT LOUIS',
        RegAddrState: 'MO',
        RegAddrZip: 63137,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '9744 GLOUCESTER DR',
        RegAddrCity: 'SAINT LOUIS',
        RegAddrState: 'MO',
        RegAddrZip: 63137,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '432 LANCASHIRE RD',
        RegAddrCity: 'SAINT LOUIS',
        RegAddrState: 'MO',
        RegAddrZip: 63137,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1351 DUNN RD',
        RegAddrCity: 'SAINT LOUIS',
        RegAddrState: 'MO',
        RegAddrZip: 63138,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '5014 FYLER AVE',
        RegAddrCity: 'SAINT LOUIS',
        RegAddrState: 'MO',
        RegAddrZip: 63139,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '4950 FAIRVIEW AVE',
        RegAddrCity: 'SAINT LOUIS',
        RegAddrState: 'MO',
        RegAddrZip: 63139,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '5624 S MAGNOLIA AVE',
        RegAddrCity: 'SAINT LOUIS',
        RegAddrState: 'MO',
        RegAddrZip: 63139,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '6515 SCANLAN AVE',
        RegAddrCity: 'SAINT LOUIS',
        RegAddrState: 'MO',
        RegAddrZip: 63139,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2050 BLENDON PL',
        RegAddrCity: 'SAINT LOUIS',
        RegAddrState: 'MO',
        RegAddrZip: 63143,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '6 GAST PL',
        RegAddrCity: 'SAINT LOUIS',
        RegAddrState: 'MO',
        RegAddrZip: 63147,
        CongressionalDistrict: 1,
      },
    ],
  },
  {
    name: 'MO-2',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/MO-2?ref=gh-pages',
    state: 'MO',
    state_and_number: 'MO-2',
    zip: 63005,
    congressionalDistrict: 2,
    addressesInDistrict: [
      {
        RegAddrLine1: '1522 W HIGHVIEW DR',
        RegAddrCity: 'ARNOLD',
        RegAddrState: 'MO',
        RegAddrZip: 63010,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '3186 ROSEDALE DR',
        RegAddrCity: 'ARNOLD',
        RegAddrState: 'MO',
        RegAddrZip: 63010,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '621 FOX CHASE',
        RegAddrCity: 'ARNOLD',
        RegAddrState: 'MO',
        RegAddrZip: 63010,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1417 DUTCH MILL DR',
        RegAddrCity: 'ARNOLD',
        RegAddrState: 'MO',
        RegAddrZip: 63010,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '658 NORTHVIEW DR',
        RegAddrCity: 'ARNOLD',
        RegAddrState: 'MO',
        RegAddrZip: 63010,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1501 ENGLEBROOK DR',
        RegAddrCity: 'BALLWIN',
        RegAddrState: 'MO',
        RegAddrZip: 63011,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '256 WHITE TREE LN',
        RegAddrCity: 'BALLWIN',
        RegAddrState: 'MO',
        RegAddrZip: 63011,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '501 PROSPECTOR RIDGE DR',
        RegAddrCity: 'BALLWIN',
        RegAddrState: 'MO',
        RegAddrZip: 63011,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '424 IRON LANTERN DR',
        RegAddrCity: 'BALLWIN',
        RegAddrState: 'MO',
        RegAddrZip: 63011,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '242 DICKENS FARM LN',
        RegAddrCity: 'BALLWIN',
        RegAddrState: 'MO',
        RegAddrZip: 63021,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1519 DIETRICH CHASE LN',
        RegAddrCity: 'BALLWIN',
        RegAddrState: 'MO',
        RegAddrZip: 63021,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '603 CARMAN VIEW CT',
        RegAddrCity: 'BALLWIN',
        RegAddrState: 'MO',
        RegAddrZip: 63021,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '190 CASCADE TERRACE DR',
        RegAddrCity: 'BALLWIN',
        RegAddrState: 'MO',
        RegAddrZip: 63021,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '3 RAINFIELD CT',
        RegAddrCity: 'BALLWIN',
        RegAddrState: 'MO',
        RegAddrZip: 63021,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '3343 TUSCANY HILLS CT',
        RegAddrCity: 'BRIDGETON',
        RegAddrState: 'MO',
        RegAddrZip: 63044,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '201 DEJOURNET DR',
        RegAddrCity: 'CHESTERFIELD',
        RegAddrState: 'MO',
        RegAddrZip: 63005,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '16949 CRYSTAL SPRINGS DR',
        RegAddrCity: 'CHESTERFIELD',
        RegAddrState: 'MO',
        RegAddrZip: 63005,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '13190 SOUTH OUTER 40 RD',
        RegAddrCity: 'CHESTERFIELD',
        RegAddrState: 'MO',
        RegAddrZip: 63017,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2393 BROADMONT CT',
        RegAddrCity: 'CHESTERFIELD',
        RegAddrState: 'MO',
        RegAddrZip: 63017,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '15509 CANYON VIEW CT',
        RegAddrCity: 'CHESTERFIELD',
        RegAddrState: 'MO',
        RegAddrZip: 63017,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '14410 TIENDA DR',
        RegAddrCity: 'CHESTERFIELD',
        RegAddrState: 'MO',
        RegAddrZip: 63017,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '713 SUMMER OAK DR',
        RegAddrCity: 'ELLISVILLE',
        RegAddrState: 'MO',
        RegAddrZip: 63021,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '911 PRINCE CHARLES WAY',
        RegAddrCity: 'ELLISVILLE',
        RegAddrState: 'MO',
        RegAddrZip: 63021,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '4613 HICKORY RIDGE VIEW CT',
        RegAddrCity: 'EUREKA',
        RegAddrState: 'MO',
        RegAddrZip: 63025,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '621 HOENE RIDGE ESTATES DR',
        RegAddrCity: 'EUREKA',
        RegAddrState: 'MO',
        RegAddrZip: 63025,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '498 CHURCHILL DOWNS MANOR CT',
        RegAddrCity: 'FENTON',
        RegAddrState: 'MO',
        RegAddrZip: 63026,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2 HARBOR SPRINGS CT',
        RegAddrCity: 'FENTON',
        RegAddrState: 'MO',
        RegAddrZip: 63026,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '18356 WOODLANDS RIDGE DR',
        RegAddrCity: 'GLENCOE',
        RegAddrState: 'MO',
        RegAddrZip: 63038,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '306 HARBOUR POINTE DR',
        RegAddrCity: 'GROVER',
        RegAddrState: 'MO',
        RegAddrZip: 63040,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2711 W SCHELLRIDGE RD',
        RegAddrCity: 'JEFFERSON CITY',
        RegAddrState: 'MO',
        RegAddrZip: 65109,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1320 WISSMANN DR',
        RegAddrCity: 'MANCHESTER',
        RegAddrState: 'MO',
        RegAddrZip: 63011,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '7 GLENMARK CT',
        RegAddrCity: 'MARYLAND HTS',
        RegAddrState: 'MO',
        RegAddrZip: 63043,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '12245 BENNINGTON PL',
        RegAddrCity: 'MARYLAND HTS',
        RegAddrState: 'MO',
        RegAddrZip: 63043,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '11684 CELESTIAL DR',
        RegAddrCity: 'MARYLAND HTS',
        RegAddrState: 'MO',
        RegAddrZip: 63043,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '282 ROYALLSPRINGS PKWY',
        RegAddrCity: 'O FALLON',
        RegAddrState: 'MO',
        RegAddrZip: 63368,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '7239 WATSONS PARISH DR',
        RegAddrCity: 'O FALLON',
        RegAddrState: 'MO',
        RegAddrZip: 63368,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '3 SWAN LAKE CT',
        RegAddrCity: 'O FALLON',
        RegAddrState: 'MO',
        RegAddrZip: 63368,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '3233 BRIGHTWOOD DR',
        RegAddrCity: 'SAINT CHARLES',
        RegAddrState: 'MO',
        RegAddrZip: 63303,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '524 LEXINGTON LANDING DR',
        RegAddrCity: 'SAINT CHARLES',
        RegAddrState: 'MO',
        RegAddrZip: 63303,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '108 TRAIL CREEK CT',
        RegAddrCity: 'SAINT CHARLES',
        RegAddrState: 'MO',
        RegAddrZip: 63304,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '4581 CLEARBROOK DR',
        RegAddrCity: 'SAINT CHARLES',
        RegAddrState: 'MO',
        RegAddrZip: 63304,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '5327 INDEPENDENCE RD',
        RegAddrCity: 'SAINT CHARLES',
        RegAddrState: 'MO',
        RegAddrZip: 63304,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '153 PEZOLD DR',
        RegAddrCity: 'SAINT CHARLES',
        RegAddrState: 'MO',
        RegAddrZip: 63304,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '68 MADEIRA CT',
        RegAddrCity: 'SAINT CHARLES',
        RegAddrState: 'MO',
        RegAddrZip: 63304,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '9758 WARSON PL',
        RegAddrCity: 'SAINT LOUIS',
        RegAddrState: 'MO',
        RegAddrZip: 63119,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '26 EDWIN AVE',
        RegAddrCity: 'SAINT LOUIS',
        RegAddrState: 'MO',
        RegAddrZip: 63122,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1360 N SAPPINGTON RD',
        RegAddrCity: 'SAINT LOUIS',
        RegAddrState: 'MO',
        RegAddrZip: 63122,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '357 HALCYON DR',
        RegAddrCity: 'SAINT LOUIS',
        RegAddrState: 'MO',
        RegAddrZip: 63122,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '104 S BRENT AVE',
        RegAddrCity: 'SAINT LOUIS',
        RegAddrState: 'MO',
        RegAddrZip: 63122,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '414 ALICE AVE',
        RegAddrCity: 'SAINT LOUIS',
        RegAddrState: 'MO',
        RegAddrZip: 63122,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '815 SIMMONS AVE',
        RegAddrCity: 'SAINT LOUIS',
        RegAddrState: 'MO',
        RegAddrZip: 63122,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '7940 HARLAN AVE',
        RegAddrCity: 'SAINT LOUIS',
        RegAddrState: 'MO',
        RegAddrZip: 63123,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '4708 KOGLIN CT',
        RegAddrCity: 'SAINT LOUIS',
        RegAddrState: 'MO',
        RegAddrZip: 63123,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '8538 GENERAL GRANT LN',
        RegAddrCity: 'SAINT LOUIS',
        RegAddrState: 'MO',
        RegAddrZip: 63123,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '10191 WINDING RIDGE RD',
        RegAddrCity: 'SAINT LOUIS',
        RegAddrState: 'MO',
        RegAddrZip: 63124,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '9544 PARK LN',
        RegAddrCity: 'SAINT LOUIS',
        RegAddrState: 'MO',
        RegAddrZip: 63124,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '10 UPPER WARSON RD',
        RegAddrCity: 'SAINT LOUIS',
        RegAddrState: 'MO',
        RegAddrZip: 63124,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '3826 JACINTO DR',
        RegAddrCity: 'SAINT LOUIS',
        RegAddrState: 'MO',
        RegAddrZip: 63125,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '116 W VELMA AVE',
        RegAddrCity: 'SAINT LOUIS',
        RegAddrState: 'MO',
        RegAddrZip: 63125,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '207 E VELMA AVE',
        RegAddrCity: 'SAINT LOUIS',
        RegAddrState: 'MO',
        RegAddrZip: 63125,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2808 ANCELL LN',
        RegAddrCity: 'SAINT LOUIS',
        RegAddrState: 'MO',
        RegAddrZip: 63125,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '8907 HARWICH DR',
        RegAddrCity: 'SAINT LOUIS',
        RegAddrState: 'MO',
        RegAddrZip: 63126,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '11063 TEAKWOOD DR',
        RegAddrCity: 'SAINT LOUIS',
        RegAddrState: 'MO',
        RegAddrZip: 63126,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '9232 LURLINE DR',
        RegAddrCity: 'SAINT LOUIS',
        RegAddrState: 'MO',
        RegAddrZip: 63126,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '13012 PAGADA PKWY',
        RegAddrCity: 'SAINT LOUIS',
        RegAddrState: 'MO',
        RegAddrZip: 63127,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '11618 SAPPINGTON BARRACKS RD',
        RegAddrCity: 'SAINT LOUIS',
        RegAddrState: 'MO',
        RegAddrZip: 63127,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '4734 SUNNYVIEW DR',
        RegAddrCity: 'SAINT LOUIS',
        RegAddrState: 'MO',
        RegAddrZip: 63128,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '4758 SUNNYVIEW DR',
        RegAddrCity: 'SAINT LOUIS',
        RegAddrState: 'MO',
        RegAddrZip: 63128,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '4956 BRUNSTON DR',
        RegAddrCity: 'SAINT LOUIS',
        RegAddrState: 'MO',
        RegAddrZip: 63128,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '10066 KALINDA LN',
        RegAddrCity: 'SAINT LOUIS',
        RegAddrState: 'MO',
        RegAddrZip: 63128,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '6070 SOUTHCREST WAY',
        RegAddrCity: 'SAINT LOUIS',
        RegAddrState: 'MO',
        RegAddrZip: 63129,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '6012 SADDLERIDGE FARM CT',
        RegAddrCity: 'SAINT LOUIS',
        RegAddrState: 'MO',
        RegAddrZip: 63129,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '4820 MEHL AVE',
        RegAddrCity: 'SAINT LOUIS',
        RegAddrState: 'MO',
        RegAddrZip: 63129,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2554 CATHEDRAL DR',
        RegAddrCity: 'SAINT LOUIS',
        RegAddrState: 'MO',
        RegAddrZip: 63129,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '6824 COFFEE SPRINGS DR S',
        RegAddrCity: 'SAINT LOUIS',
        RegAddrState: 'MO',
        RegAddrZip: 63129,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2719 MERAMAR DR',
        RegAddrCity: 'SAINT LOUIS',
        RegAddrState: 'MO',
        RegAddrZip: 63129,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '805 BLUESPRING LN',
        RegAddrCity: 'SAINT LOUIS',
        RegAddrState: 'MO',
        RegAddrZip: 63131,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '17 OUTER LADUE DR',
        RegAddrCity: 'SAINT LOUIS',
        RegAddrState: 'MO',
        RegAddrZip: 63131,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1559 FAWNVALLEY DR',
        RegAddrCity: 'SAINT LOUIS',
        RegAddrState: 'MO',
        RegAddrZip: 63131,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2401 ANNALEE AVE',
        RegAddrCity: 'SAINT LOUIS',
        RegAddrState: 'MO',
        RegAddrZip: 63144,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2418 HIGH SCHOOL DR',
        RegAddrCity: 'SAINT LOUIS',
        RegAddrState: 'MO',
        RegAddrZip: 63144,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '11123 APACHE TRL',
        RegAddrCity: 'SAINT LOUIS',
        RegAddrState: 'MO',
        RegAddrZip: 63146,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '22 CHIEFTAIN DR',
        RegAddrCity: 'SAINT LOUIS',
        RegAddrState: 'MO',
        RegAddrZip: 63146,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1442 DAUTEL LN',
        RegAddrCity: 'SAINT LOUIS',
        RegAddrState: 'MO',
        RegAddrZip: 63146,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '11906 SHALLOWBROOK DR',
        RegAddrCity: 'SAINT LOUIS',
        RegAddrState: 'MO',
        RegAddrZip: 63146,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '78 OAKRIDGE WEST DR',
        RegAddrCity: 'SAINT PETERS',
        RegAddrState: 'MO',
        RegAddrZip: 63376,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1090 PEARVIEW DR',
        RegAddrCity: 'ST PETERS',
        RegAddrState: 'MO',
        RegAddrZip: 63376,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '103 HACIENDA CT',
        RegAddrCity: 'ST PETERS',
        RegAddrState: 'MO',
        RegAddrZip: 63376,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '369 ROSE MARIE CT',
        RegAddrCity: 'ST PETERS',
        RegAddrState: 'MO',
        RegAddrZip: 63376,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '116 PATRICIA HILL DR',
        RegAddrCity: 'VALLEY PARK',
        RegAddrState: 'MO',
        RegAddrZip: 63088,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '3380 LAKE BEND DR',
        RegAddrCity: 'VALLEY PARK',
        RegAddrState: 'MO',
        RegAddrZip: 63088,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1207 N STATE HIGHWAY 47',
        RegAddrCity: 'WARRENTON',
        RegAddrState: 'MO',
        RegAddrZip: 63383,
        CongressionalDistrict: 2,
      },
    ],
  },
  {
    name: 'MO-3',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/MO-3?ref=gh-pages',
    state: 'MO',
    state_and_number: 'MO-3',
    zip: 63012,
    congressionalDistrict: 3,
    addressesInDistrict: [
      {
        RegAddrLine1: '3854 CRYSTAL WOOD DR',
        RegAddrCity: 'ARNOLD',
        RegAddrState: 'MO',
        RegAddrZip: 63010,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '3831 PAULINA DR',
        RegAddrCity: 'ARNOLD',
        RegAddrState: 'MO',
        RegAddrZip: 63010,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '4506 GARY RD',
        RegAddrCity: 'ARNOLD',
        RegAddrState: 'MO',
        RegAddrZip: 63010,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '60 REX AIRE CT',
        RegAddrCity: 'ARNOLD',
        RegAddrState: 'MO',
        RegAddrZip: 63010,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '402 OAK ST',
        RegAddrCity: 'AUXVASSE',
        RegAddrState: 'MO',
        RegAddrZip: 65231,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '7114 OLD STATE ROUTE 21',
        RegAddrCity: 'BARNHART',
        RegAddrState: 'MO',
        RegAddrZip: 63012,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '74 HILL N DALE DR',
        RegAddrCity: 'CAMDENTON',
        RegAddrState: 'MO',
        RegAddrZip: 65020,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '946 MEADOW DR',
        RegAddrCity: 'CATAWISSA',
        RegAddrState: 'MO',
        RegAddrZip: 63015,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '6249 CEDAR BROOK DR',
        RegAddrCity: 'CEDAR HILL',
        RegAddrState: 'MO',
        RegAddrZip: 63016,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '8309 GRAHAM RD',
        RegAddrCity: 'CEDAR HILL',
        RegAddrState: 'MO',
        RegAddrZip: 63016,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '402 MISSISSIPPI AVE',
        RegAddrCity: 'CRYSTAL CITY',
        RegAddrState: 'MO',
        RegAddrZip: 63019,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '401 MISSISSIPPI AVE',
        RegAddrCity: 'CRYSTAL CITY',
        RegAddrState: 'MO',
        RegAddrZip: 63019,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '481 MATTS WAY',
        RegAddrCity: 'DEFIANCE',
        RegAddrState: 'MO',
        RegAddrZip: 63341,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '117 HOWELL RD',
        RegAddrCity: 'DEFIANCE',
        RegAddrState: 'MO',
        RegAddrZip: 63341,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '39550 HIGHWAY BB',
        RegAddrCity: 'DIXON',
        RegAddrState: 'MO',
        RegAddrZip: 65459,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '198 BLACKWELL LN',
        RegAddrCity: 'EOLIA',
        RegAddrState: 'MO',
        RegAddrZip: 63344,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1801 SUNSHINE DR',
        RegAddrCity: 'FESTUS',
        RegAddrState: 'MO',
        RegAddrZip: 63028,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1002 HERRING DR',
        RegAddrCity: 'FULTON',
        RegAddrState: 'MO',
        RegAddrZip: 65251,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1954 HIGHWAY F',
        RegAddrCity: 'HERMANN',
        RegAddrState: 'MO',
        RegAddrZip: 65041,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '89 MOTLEY RD',
        RegAddrCity: 'HERMANN',
        RegAddrState: 'MO',
        RegAddrZip: 65041,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1603 STOLPE RD',
        RegAddrCity: 'HERMANN',
        RegAddrState: 'MO',
        RegAddrZip: 65041,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '5613 HILL DR',
        RegAddrCity: 'HIGH RIDGE',
        RegAddrState: 'MO',
        RegAddrZip: 63049,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1016 HALIFAX RD',
        RegAddrCity: 'HOLTS SUMMIT',
        RegAddrState: 'MO',
        RegAddrZip: 65043,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '2565 JAZEL LN',
        RegAddrCity: 'HOLTS SUMMIT',
        RegAddrState: 'MO',
        RegAddrZip: 65043,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '4880 SCOTTSDALE RD',
        RegAddrCity: 'HOUSE SPRINGS',
        RegAddrState: 'MO',
        RegAddrZip: 63051,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '6119 MISTY MEADOW DR',
        RegAddrCity: 'HOUSE SPRINGS',
        RegAddrState: 'MO',
        RegAddrZip: 63051,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '98 RABBIT HEAD RD',
        RegAddrCity: 'IBERIA',
        RegAddrState: 'MO',
        RegAddrZip: 65486,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '3948 BUCKEYE CT',
        RegAddrCity: 'IMPERIAL',
        RegAddrState: 'MO',
        RegAddrZip: 63052,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1869 COTTONWOOD DR',
        RegAddrCity: 'IMPERIAL',
        RegAddrState: 'MO',
        RegAddrZip: 63052,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1123 JAMES DR',
        RegAddrCity: 'IMPERIAL',
        RegAddrState: 'MO',
        RegAddrZip: 63052,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1701 STONYWOLD RD',
        RegAddrCity: 'IMPERIAL',
        RegAddrState: 'MO',
        RegAddrZip: 63052,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '857 COUNTRY HAVEN DR',
        RegAddrCity: 'IMPERIAL',
        RegAddrState: 'MO',
        RegAddrZip: 63052,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '220 ROCK BEACON RD',
        RegAddrCity: 'JEFFERSON CTY',
        RegAddrState: 'MO',
        RegAddrZip: 65109,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '821 CARI ANN DR',
        RegAddrCity: 'JEFFERSON CTY',
        RegAddrState: 'MO',
        RegAddrZip: 65109,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1625 NORTHPORT DR',
        RegAddrCity: 'JEFFERSON CTY',
        RegAddrState: 'MO',
        RegAddrZip: 65109,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '5702 HERITAGE HWY',
        RegAddrCity: 'JEFFERSON CTY',
        RegAddrState: 'MO',
        RegAddrZip: 65109,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '300 NORRIS DR',
        RegAddrCity: 'JEFFERSON CTY',
        RegAddrState: 'MO',
        RegAddrZip: 65109,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '904 CARI ANN DR',
        RegAddrCity: 'JEFFERSON CTY',
        RegAddrState: 'MO',
        RegAddrZip: 65109,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '744 JOSEPH DR',
        RegAddrCity: 'JEFFERSON CTY',
        RegAddrState: 'MO',
        RegAddrZip: 65109,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '2904 DAWNWOOD CIR',
        RegAddrCity: 'JEFFERSON CTY',
        RegAddrState: 'MO',
        RegAddrZip: 65109,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '304 BELAIR DR',
        RegAddrCity: 'JEFFERSON CTY',
        RegAddrState: 'MO',
        RegAddrZip: 65109,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '5299 COUNTY ROAD 220',
        RegAddrCity: 'KINGDOM CITY',
        RegAddrState: 'MO',
        RegAddrZip: 65262,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '658 DAISY LN',
        RegAddrCity: 'LABADIE',
        RegAddrState: 'MO',
        RegAddrZip: 63055,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1453 S LAKE SHERWOOD DR',
        RegAddrCity: 'LAKE SHERWOOD',
        RegAddrState: 'MO',
        RegAddrZip: 63357,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '51 RUE GRAND DR',
        RegAddrCity: 'LAKE ST LOUIS',
        RegAddrState: 'MO',
        RegAddrZip: 63367,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1349 BRIARCHASE DR',
        RegAddrCity: 'LAKE ST LOUIS',
        RegAddrState: 'MO',
        RegAddrZip: 63367,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '111 LEMANS CT',
        RegAddrCity: 'LAKE ST LOUIS',
        RegAddrState: 'MO',
        RegAddrZip: 63367,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '410 COUNTRY DOWNS DR',
        RegAddrCity: 'LAKE ST LOUIS',
        RegAddrState: 'MO',
        RegAddrZip: 63367,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '211 RUE GRAND DR',
        RegAddrCity: 'LAKE ST LOUIS',
        RegAddrState: 'MO',
        RegAddrZip: 63367,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1453 LAKE KNOLL DR',
        RegAddrCity: 'LAKE ST LOUIS',
        RegAddrState: 'MO',
        RegAddrZip: 63367,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '31300 HIGHWAY 133',
        RegAddrCity: 'META',
        RegAddrState: 'MO',
        RegAddrZip: 65058,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1055 BISHOP LN',
        RegAddrCity: 'MONTGOMERY CY',
        RegAddrState: 'MO',
        RegAddrZip: 63361,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '410 W BELL ST',
        RegAddrCity: 'MONTGOMERY CY',
        RegAddrState: 'MO',
        RegAddrZip: 63361,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '38 KILLARNEY LN',
        RegAddrCity: 'MOSCOW MILLS',
        RegAddrState: 'MO',
        RegAddrZip: 63362,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '116 ELDORADO DR',
        RegAddrCity: 'MOSCOW MILLS',
        RegAddrState: 'MO',
        RegAddrZip: 63362,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '285 ROBLLER VINEYARD RD',
        RegAddrCity: 'NEW HAVEN',
        RegAddrState: 'MO',
        RegAddrZip: 63068,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1506 NORWOOD HILLS DR',
        RegAddrCity: 'O FALLON',
        RegAddrState: 'MO',
        RegAddrZip: 63366,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '407 WOODLAWN AVE',
        RegAddrCity: 'O FALLON',
        RegAddrState: 'MO',
        RegAddrZip: 63366,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '227 ENGLAND DR',
        RegAddrCity: 'O FALLON',
        RegAddrState: 'MO',
        RegAddrZip: 63366,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '15 BRIARMIST CT',
        RegAddrCity: 'O FALLON',
        RegAddrState: 'MO',
        RegAddrZip: 63366,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1536 HAWK ISLAND DR',
        RegAddrCity: 'OSAGE BEACH',
        RegAddrState: 'MO',
        RegAddrZip: 65065,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1119 GLENWOOD DR',
        RegAddrCity: 'OSAGE BEACH',
        RegAddrState: 'MO',
        RegAddrZip: 65065,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1518 HAWK ISLAND DR',
        RegAddrCity: 'OSAGE BEACH',
        RegAddrState: 'MO',
        RegAddrZip: 65065,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '94 CEDAR RIDGE DR',
        RegAddrCity: 'PACIFIC',
        RegAddrState: 'MO',
        RegAddrZip: 63069,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '9083 LYNNWOOD DR',
        RegAddrCity: 'PEVELY',
        RegAddrState: 'MO',
        RegAddrZip: 63070,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '28624 GRAVOIS VILLAGE LN',
        RegAddrCity: 'ROCKY MOUNT',
        RegAddrState: 'MO',
        RegAddrZip: 65072,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '903 N 2ND ST',
        RegAddrCity: 'SAINT CHARLES',
        RegAddrState: 'MO',
        RegAddrZip: 63301,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '3321 HIGHGATE LN',
        RegAddrCity: 'SAINT CHARLES',
        RegAddrState: 'MO',
        RegAddrZip: 63301,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '112 S OVERBROOK DR',
        RegAddrCity: 'SAINT CHARLES',
        RegAddrState: 'MO',
        RegAddrZip: 63301,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '803 S BENTON AVE',
        RegAddrCity: 'SAINT CHARLES',
        RegAddrState: 'MO',
        RegAddrZip: 63301,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '2912 W ADAMS ST',
        RegAddrCity: 'SAINT CHARLES',
        RegAddrState: 'MO',
        RegAddrZip: 63301,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '13 ELMWOOD CT',
        RegAddrCity: 'SAINT CHARLES',
        RegAddrState: 'MO',
        RegAddrZip: 63301,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '16 E CHESHIRE DR',
        RegAddrCity: 'SAINT CHARLES',
        RegAddrState: 'MO',
        RegAddrZip: 63303,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '125 CASCADES DR',
        RegAddrCity: 'SAINT CHARLES',
        RegAddrState: 'MO',
        RegAddrZip: 63303,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1118 LAKESHORE DR',
        RegAddrCity: 'SAINT CHARLES',
        RegAddrState: 'MO',
        RegAddrZip: 63303,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '222 MUIRFIELD WOODS CT',
        RegAddrCity: 'SAINT CHARLES',
        RegAddrState: 'MO',
        RegAddrZip: 63304,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '470 RED BIRD LN',
        RegAddrCity: 'SAINT CLAIR',
        RegAddrState: 'MO',
        RegAddrZip: 63077,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1192 HIGHWAY U',
        RegAddrCity: 'SAINT JAMES',
        RegAddrState: 'MO',
        RegAddrZip: 65559,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '832 LANVALE DR',
        RegAddrCity: 'SAINT LOUIS',
        RegAddrState: 'MO',
        RegAddrZip: 63119,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '3072 VALLEY VISTA DR N',
        RegAddrCity: 'SAINT PETERS',
        RegAddrState: 'MO',
        RegAddrZip: 63376,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '218 UNIVERSAL DR',
        RegAddrCity: 'ST PETERS',
        RegAddrState: 'MO',
        RegAddrZip: 63376,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '15 COACH DR',
        RegAddrCity: 'ST PETERS',
        RegAddrState: 'MO',
        RegAddrZip: 63376,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '29 SAINT LAWRENCE DR',
        RegAddrCity: 'ST PETERS',
        RegAddrState: 'MO',
        RegAddrZip: 63376,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '26 VALLEY VIEW DR',
        RegAddrCity: 'ST PETERS',
        RegAddrState: 'MO',
        RegAddrZip: 63376,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '783 WATSON RD',
        RegAddrCity: 'SULLIVAN',
        RegAddrState: 'MO',
        RegAddrZip: 63080,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '449 S PARK ST',
        RegAddrCity: 'SULLIVAN',
        RegAddrState: 'MO',
        RegAddrZip: 63080,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '190 DENA CT',
        RegAddrCity: 'TROY',
        RegAddrState: 'MO',
        RegAddrZip: 63379,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '110 DENA CT',
        RegAddrCity: 'TROY',
        RegAddrState: 'MO',
        RegAddrZip: 63379,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '151 FIELDSTONE DR',
        RegAddrCity: 'TROY',
        RegAddrState: 'MO',
        RegAddrZip: 63379,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '221 W PERSHING ST',
        RegAddrCity: 'TROY',
        RegAddrState: 'MO',
        RegAddrZip: 63379,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '3115 HIGHWAY UU',
        RegAddrCity: 'UNION',
        RegAddrState: 'MO',
        RegAddrZip: 63084,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '2026 BACHELOR CREEK RD',
        RegAddrCity: 'UNION',
        RegAddrState: 'MO',
        RegAddrZip: 63084,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '3445 AITCH RD',
        RegAddrCity: 'UNION',
        RegAddrState: 'MO',
        RegAddrZip: 63084,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '31510 HIGHWAY 63 N',
        RegAddrCity: 'VIENNA',
        RegAddrState: 'MO',
        RegAddrZip: 65582,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '12540 MARIES ROAD 327',
        RegAddrCity: 'VIENNA',
        RegAddrState: 'MO',
        RegAddrZip: 65582,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1814 VAN LOO LN',
        RegAddrCity: 'WARDSVILLE',
        RegAddrState: 'MO',
        RegAddrZip: 65101,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1184 LILAC CIR',
        RegAddrCity: 'WARRENTON',
        RegAddrState: 'MO',
        RegAddrZip: 63383,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1481 N ROCK CHURCH RD',
        RegAddrCity: 'WARRENTON',
        RegAddrState: 'MO',
        RegAddrZip: 63383,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1160 COLUMBUS CIR',
        RegAddrCity: 'WARRENTON',
        RegAddrState: 'MO',
        RegAddrZip: 63383,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1210 CAROLINE DR',
        RegAddrCity: 'WASHINGTON',
        RegAddrState: 'MO',
        RegAddrZip: 63090,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '2723 HIGHWAY A',
        RegAddrCity: 'WASHINGTON',
        RegAddrState: 'MO',
        RegAddrZip: 63090,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '25 HUNTLEIGH VIEW CT',
        RegAddrCity: 'WENTZVILLE',
        RegAddrState: 'MO',
        RegAddrZip: 63385,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '218 HARTFORD GLEN CT',
        RegAddrCity: 'WENTZVILLE',
        RegAddrState: 'MO',
        RegAddrZip: 63385,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '27 MILTON BRENNAN MANOR CT',
        RegAddrCity: 'WENTZVILLE',
        RegAddrState: 'MO',
        RegAddrZip: 63385,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '18 JUNO DR',
        RegAddrCity: 'WENTZVILLE',
        RegAddrState: 'MO',
        RegAddrZip: 63385,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '722 CHANCELLORSVILLE DR',
        RegAddrCity: 'WENTZVILLE',
        RegAddrState: 'MO',
        RegAddrZip: 63385,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '216 NIAGRA DR',
        RegAddrCity: 'WENTZVILLE',
        RegAddrState: 'MO',
        RegAddrZip: 63385,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1143 SILO BEND DR',
        RegAddrCity: 'WENTZVILLE',
        RegAddrState: 'MO',
        RegAddrZip: 63385,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '137 RICHARD DR',
        RegAddrCity: 'WEST ALTON',
        RegAddrState: 'MO',
        RegAddrZip: 63386,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '3210 GALLOWAY RD',
        RegAddrCity: 'WINFIELD',
        RegAddrState: 'MO',
        RegAddrZip: 63389,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '12 THOROUGHBRED DR',
        RegAddrCity: 'WRIGHT CITY',
        RegAddrState: 'MO',
        RegAddrZip: 63390,
        CongressionalDistrict: 3,
      },
    ],
  },
  {
    name: 'MO-4',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/MO-4?ref=gh-pages',
    state: 'MO',
    state_and_number: 'MO-4',
    zip: 63352,
    congressionalDistrict: 4,
    addressesInDistrict: [
      {
        RegAddrLine1: '107 W 3RD ST',
        RegAddrCity: 'APPLETON CITY',
        RegAddrState: 'MO',
        RegAddrZip: 64724,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '15100 S HAGANS RD',
        RegAddrCity: 'ASHLAND',
        RegAddrState: 'MO',
        RegAddrZip: 65010,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '719 2ND ST',
        RegAddrCity: 'BARNETT',
        RegAddrState: 'MO',
        RegAddrZip: 65011,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '843 NW 1661ST RD',
        RegAddrCity: 'BATES CITY',
        RegAddrState: 'MO',
        RegAddrZip: 64011,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '413 CORRAL DR',
        RegAddrCity: 'BELTON',
        RegAddrState: 'MO',
        RegAddrZip: 64012,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '16008 ANN AVE',
        RegAddrCity: 'BELTON',
        RegAddrState: 'MO',
        RegAddrZip: 64012,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2330 CHOUTEAU LN',
        RegAddrCity: 'BOONVILLE',
        RegAddrState: 'MO',
        RegAddrZip: 65233,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1300 11TH ST',
        RegAddrCity: 'BOONVILLE',
        RegAddrState: 'MO',
        RegAddrZip: 65233,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '900 N WATER ST',
        RegAddrCity: 'BUTLER',
        RegAddrState: 'MO',
        RegAddrZip: 64730,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '10729 NE COUNTY ROAD 5004',
        RegAddrCity: 'BUTLER',
        RegAddrState: 'MO',
        RegAddrZip: 64730,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '803 W OHIO ST',
        RegAddrCity: 'BUTLER',
        RegAddrState: 'MO',
        RegAddrZip: 64730,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '604 NE 501 RD',
        RegAddrCity: 'CALHOUN',
        RegAddrState: 'MO',
        RegAddrZip: 65323,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '140 BANK BRANCH DR',
        RegAddrCity: 'CAMDENTON',
        RegAddrState: 'MO',
        RegAddrZip: 65020,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '230 S DENTON ST',
        RegAddrCity: 'CENTRALIA',
        RegAddrState: 'MO',
        RegAddrZip: 65240,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '6397 HIGHWAY P',
        RegAddrCity: 'CLARK',
        RegAddrState: 'MO',
        RegAddrZip: 65243,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '129 NW 251ST RD',
        RegAddrCity: 'CLINTON',
        RegAddrState: 'MO',
        RegAddrZip: 64735,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2416 SANTA ROSA CT',
        RegAddrCity: 'COLUMBIA',
        RegAddrState: 'MO',
        RegAddrZip: 65201,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '4813 E SAINT CHARLES RD',
        RegAddrCity: 'COLUMBIA',
        RegAddrState: 'MO',
        RegAddrZip: 65201,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '3300 NEW HAVEN RD',
        RegAddrCity: 'COLUMBIA',
        RegAddrState: 'MO',
        RegAddrZip: 65201,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2104 SPECK CT',
        RegAddrCity: 'COLUMBIA',
        RegAddrState: 'MO',
        RegAddrZip: 65202,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '3501 WOODLAND DR',
        RegAddrCity: 'COLUMBIA',
        RegAddrState: 'MO',
        RegAddrZip: 65202,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '601 NORWALK DR',
        RegAddrCity: 'COLUMBIA',
        RegAddrState: 'MO',
        RegAddrZip: 65202,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '4261 W BETHANY DR',
        RegAddrCity: 'COLUMBIA',
        RegAddrState: 'MO',
        RegAddrZip: 65203,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '706 HUNT AVE',
        RegAddrCity: 'COLUMBIA',
        RegAddrState: 'MO',
        RegAddrZip: 65203,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '28 E CLARKSON RD',
        RegAddrCity: 'COLUMBIA',
        RegAddrState: 'MO',
        RegAddrZip: 65203,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1100 RAINBOW TROUT CIR',
        RegAddrCity: 'COLUMBIA',
        RegAddrState: 'MO',
        RegAddrZip: 65203,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '907 BROADHEAD ST',
        RegAddrCity: 'COLUMBIA',
        RegAddrState: 'MO',
        RegAddrZip: 65203,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '12700 S ROUTE N',
        RegAddrCity: 'COLUMBIA',
        RegAddrState: 'MO',
        RegAddrZip: 65203,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2504 HILLSHIRE DR',
        RegAddrCity: 'COLUMBIA',
        RegAddrState: 'MO',
        RegAddrZip: 65203,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1 S SCOTT BLVD',
        RegAddrCity: 'COLUMBIA',
        RegAddrState: 'MO',
        RegAddrZip: 65203,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2702 POST OAK DR',
        RegAddrCity: 'COLUMBIA',
        RegAddrState: 'MO',
        RegAddrZip: 65203,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '511 S GLENWOOD AVE',
        RegAddrCity: 'COLUMBIA',
        RegAddrState: 'MO',
        RegAddrZip: 65203,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '21378 HAWK DR',
        RegAddrCity: 'DIXON',
        RegAddrState: 'MO',
        RegAddrZip: 65459,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '211 COUNTRY CLUB RD',
        RegAddrCity: 'DIXON',
        RegAddrState: 'MO',
        RegAddrZip: 65459,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '30966 CRESCENT MOON DR',
        RegAddrCity: 'EDWARDS',
        RegAddrState: 'MO',
        RegAddrZip: 65326,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '31715 FORTHVIEW RD',
        RegAddrCity: 'EDWARDS',
        RegAddrState: 'MO',
        RegAddrZip: 65326,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '238 DROVER DR',
        RegAddrCity: 'FAIR GROVE',
        RegAddrState: 'MO',
        RegAddrZip: 65648,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '729 SALINE ST',
        RegAddrCity: 'GLASGOW',
        RegAddrState: 'MO',
        RegAddrZip: 65254,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '30383 BULLOCK DR',
        RegAddrCity: 'GRAVOIS MILLS',
        RegAddrState: 'MO',
        RegAddrZip: 65037,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '32333 ISLAND VIEW RD',
        RegAddrCity: 'GRAVOIS MILLS',
        RegAddrState: 'MO',
        RegAddrZip: 65037,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '6125 E LOW CROSSINGS RD',
        RegAddrCity: 'HALLSVILLE',
        RegAddrState: 'MO',
        RegAddrZip: 65255,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '14410 CONCERT AVE',
        RegAddrCity: 'HALLSVILLE',
        RegAddrState: 'MO',
        RegAddrZip: 65255,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '23100 E 291ST ST',
        RegAddrCity: 'HARRISONVILLE',
        RegAddrState: 'MO',
        RegAddrZip: 64701,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1004 GARDEN WAY',
        RegAddrCity: 'HARRISONVILLE',
        RegAddrState: 'MO',
        RegAddrZip: 64701,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1100 E CEDAR TREE LN',
        RegAddrCity: 'HARTSBURG',
        RegAddrState: 'MO',
        RegAddrZip: 65039,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '604 NW 1501ST RD',
        RegAddrCity: 'HOLDEN',
        RegAddrState: 'MO',
        RegAddrZip: 64040,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '762 SW 1601ST RD',
        RegAddrCity: 'HOLDEN',
        RegAddrState: 'MO',
        RegAddrZip: 64040,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '750 NW 1371ST RD',
        RegAddrCity: 'HOLDEN',
        RegAddrState: 'MO',
        RegAddrZip: 64040,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '15537 LUPUS RD',
        RegAddrCity: 'JAMESTOWN',
        RegAddrState: 'MO',
        RegAddrZip: 65046,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '503 DOGWOOD DR',
        RegAddrCity: 'KNOB NOSTER',
        RegAddrState: 'MO',
        RegAddrZip: 65336,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '300 GULF ST',
        RegAddrCity: 'LAMAR',
        RegAddrState: 'MO',
        RegAddrZip: 64759,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '708 CHERRY ST',
        RegAddrCity: 'LAMAR',
        RegAddrState: 'MO',
        RegAddrZip: 64759,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '26168 RUSTIC DR',
        RegAddrCity: 'LEBANON',
        RegAddrState: 'MO',
        RegAddrZip: 65536,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '19943 OSTRICH DR',
        RegAddrCity: 'LEBANON',
        RegAddrState: 'MO',
        RegAddrZip: 65536,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '716 ROSEWOOD DR',
        RegAddrCity: 'LEBANON',
        RegAddrState: 'MO',
        RegAddrZip: 65536,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '215 LOGAN ST',
        RegAddrCity: 'LEBANON',
        RegAddrState: 'MO',
        RegAddrZip: 65536,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '179 SETH ST',
        RegAddrCity: 'LINCOLN',
        RegAddrState: 'MO',
        RegAddrZip: 65338,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '239 SHELLY BLVD',
        RegAddrCity: 'LINCOLN',
        RegAddrState: 'MO',
        RegAddrZip: 65338,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '196 SHOEMAKER DR',
        RegAddrCity: 'MACKS CREEK',
        RegAddrState: 'MO',
        RegAddrZip: 65786,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '816 S CLARK ST',
        RegAddrCity: 'MEXICO',
        RegAddrState: 'MO',
        RegAddrZip: 65265,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1918 W LIBERTY ST',
        RegAddrCity: 'MEXICO',
        RegAddrState: 'MO',
        RegAddrZip: 65265,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '3546 HIGHWAY 15',
        RegAddrCity: 'MEXICO',
        RegAddrState: 'MO',
        RegAddrZip: 65265,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '433 TARA PARK',
        RegAddrCity: 'MOBERLY',
        RegAddrState: 'MO',
        RegAddrZip: 65270,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '717 N AULT ST',
        RegAddrCity: 'MOBERLY',
        RegAddrState: 'MO',
        RegAddrZip: 65270,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1227 CONESTOGA TRCE',
        RegAddrCity: 'MOBERLY',
        RegAddrState: 'MO',
        RegAddrZip: 65270,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '318 HALLECK ST',
        RegAddrCity: 'MOBERLY',
        RegAddrState: 'MO',
        RegAddrZip: 65270,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1235 N CEDAR ST',
        RegAddrCity: 'NEVADA',
        RegAddrState: 'MO',
        RegAddrZip: 64772,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '731 TANGLEWOOD LN',
        RegAddrCity: 'NEVADA',
        RegAddrState: 'MO',
        RegAddrZip: 64772,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '11909 E 214TH TER',
        RegAddrCity: 'PECULIAR',
        RegAddrState: 'MO',
        RegAddrZip: 64078,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '21621 S COUNTRYSIDE DR',
        RegAddrCity: 'PECULIAR',
        RegAddrState: 'MO',
        RegAddrZip: 64078,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '10894 BIRCH RD',
        RegAddrCity: 'PHILLIPSBURG',
        RegAddrState: 'MO',
        RegAddrZip: 65722,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '400 BENTON ST',
        RegAddrCity: 'PLEASANT HILL',
        RegAddrState: 'MO',
        RegAddrZip: 64080,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '701 LAKESHORE PL',
        RegAddrCity: 'RAYMORE',
        RegAddrState: 'MO',
        RegAddrZip: 64083,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '401 N JEFFERSON ST',
        RegAddrCity: 'RAYMORE',
        RegAddrState: 'MO',
        RegAddrZip: 64083,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '602 OLD PAINT RD',
        RegAddrCity: 'RAYMORE',
        RegAddrState: 'MO',
        RegAddrZip: 64083,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '107 E JEFFERSON AVE',
        RegAddrCity: 'RICHLAND',
        RegAddrState: 'MO',
        RegAddrZip: 65556,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '21800 REINDEER DR',
        RegAddrCity: 'RICHLAND',
        RegAddrState: 'MO',
        RegAddrZip: 65556,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2700 WING AVE',
        RegAddrCity: 'SEDALIA',
        RegAddrState: 'MO',
        RegAddrZip: 65301,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2435 1ST STREET TER',
        RegAddrCity: 'SEDALIA',
        RegAddrState: 'MO',
        RegAddrZip: 65301,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '721 W 4TH ST',
        RegAddrCity: 'SEDALIA',
        RegAddrState: 'MO',
        RegAddrZip: 65301,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1624 W 5TH ST',
        RegAddrCity: 'SEDALIA',
        RegAddrState: 'MO',
        RegAddrZip: 65301,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1810 HEDGE APPLE DR',
        RegAddrCity: 'SEDALIA',
        RegAddrState: 'MO',
        RegAddrZip: 65301,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '147 DAX DR',
        RegAddrCity: 'SEYMOUR',
        RegAddrState: 'MO',
        RegAddrZip: 65746,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '219 N CORDIE ST',
        RegAddrCity: 'SEYMOUR',
        RegAddrState: 'MO',
        RegAddrZip: 65746,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2448 W SPRING WATER ST',
        RegAddrCity: 'SPRINGFIELD',
        RegAddrState: 'MO',
        RegAddrZip: 65803,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '14328 TORPEDO DR',
        RegAddrCity: 'ST ROBERT',
        RegAddrState: 'MO',
        RegAddrZip: 65584,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '21576 HIGHWAY Y',
        RegAddrCity: 'ST ROBERT',
        RegAddrState: 'MO',
        RegAddrZip: 65584,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '811 OWENS MILL RD',
        RegAddrCity: 'STOCKTON',
        RegAddrState: 'MO',
        RegAddrZip: 65785,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '3398 LOLLAR BRANCH RD',
        RegAddrCity: 'SULLIVAN',
        RegAddrState: 'MO',
        RegAddrZip: 63080,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '33270 MARTIN RD',
        RegAddrCity: 'SUNRISE BEACH',
        RegAddrState: 'MO',
        RegAddrZip: 65079,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1251 S FORK LN',
        RegAddrCity: 'TIPTON',
        RegAddrState: 'MO',
        RegAddrZip: 65081,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '16856 S HIGHLAND RIDGE DR',
        RegAddrCity: 'VLG LOCH LOYD',
        RegAddrState: 'MO',
        RegAddrZip: 64012,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1441 GRANDVIEW DR',
        RegAddrCity: 'WARRENSBURG',
        RegAddrState: 'MO',
        RegAddrZip: 64093,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '417 W HARRISON ST',
        RegAddrCity: 'WARSAW',
        RegAddrState: 'MO',
        RegAddrZip: 65355,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '31531 MCLAUGHLIN AVE',
        RegAddrCity: 'WARSAW',
        RegAddrState: 'MO',
        RegAddrZip: 65355,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '609 E MAIN ST',
        RegAddrCity: 'WARSAW',
        RegAddrState: 'MO',
        RegAddrZip: 65355,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '23061 HOUK AVE',
        RegAddrCity: 'WARSAW',
        RegAddrState: 'MO',
        RegAddrZip: 65355,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '21124 LADLE LN',
        RegAddrCity: 'WAYNESVILLE',
        RegAddrState: 'MO',
        RegAddrZip: 65583,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '24450 REDWING RD',
        RegAddrCity: 'WAYNESVILLE',
        RegAddrState: 'MO',
        RegAddrZip: 65583,
        CongressionalDistrict: 4,
      },
    ],
  },
  {
    name: 'MO-5',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/MO-5?ref=gh-pages',
    state: 'MO',
    state_and_number: 'MO-5',
    zip: 64001,
    congressionalDistrict: 5,
    addressesInDistrict: [
      {
        RegAddrLine1: '3434 HIGHWAY Z',
        RegAddrCity: 'BATES CITY',
        RegAddrState: 'MO',
        RegAddrZip: 64011,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '830 DOTY SCHOOL RD',
        RegAddrCity: 'BATES CITY',
        RegAddrState: 'MO',
        RegAddrZip: 64011,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '520 N MAIN ST',
        RegAddrCity: 'CORDER',
        RegAddrState: 'MO',
        RegAddrZip: 64021,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '1108 NE 68TH TER',
        RegAddrCity: 'GLADSTONE',
        RegAddrState: 'MO',
        RegAddrZip: 64118,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '6517 N INDIANA AVE',
        RegAddrCity: 'GLADSTONE',
        RegAddrState: 'MO',
        RegAddrZip: 64119,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '709 SW NELSON DR',
        RegAddrCity: 'GRAIN VALLEY',
        RegAddrState: 'MO',
        RegAddrZip: 64029,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '515 NE GREENVIEW DR',
        RegAddrCity: 'GRAIN VALLEY',
        RegAddrState: 'MO',
        RegAddrZip: 64029,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '13128 LOWELL AVE',
        RegAddrCity: 'GRANDVIEW',
        RegAddrState: 'MO',
        RegAddrZip: 64030,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '15106 FULLER AVE',
        RegAddrCity: 'GRANDVIEW',
        RegAddrState: 'MO',
        RegAddrZip: 64030,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '13119 8TH ST',
        RegAddrCity: 'GRANDVIEW',
        RegAddrState: 'MO',
        RegAddrZip: 64030,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '13706 11TH TER',
        RegAddrCity: 'GRANDVIEW',
        RegAddrState: 'MO',
        RegAddrZip: 64030,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '6901 E 135TH ST',
        RegAddrCity: 'GRANDVIEW',
        RegAddrState: 'MO',
        RegAddrZip: 64030,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '1901 ALLENDALE DR',
        RegAddrCity: 'GREENWOOD',
        RegAddrState: 'MO',
        RegAddrZip: 64034,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '1903 PEACH ST',
        RegAddrCity: 'HIGGINSVILLE',
        RegAddrState: 'MO',
        RegAddrZip: 64037,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '3403 N MCCOY ST',
        RegAddrCity: 'INDEPENDENCE',
        RegAddrState: 'MO',
        RegAddrZip: 64050,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '802 S CRANE ST',
        RegAddrCity: 'INDEPENDENCE',
        RegAddrState: 'MO',
        RegAddrZip: 64050,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '1503 N HOCKER AVE',
        RegAddrCity: 'INDEPENDENCE',
        RegAddrState: 'MO',
        RegAddrZip: 64050,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '11911 E 37TH ST S',
        RegAddrCity: 'INDEPENDENCE',
        RegAddrState: 'MO',
        RegAddrZip: 64052,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '11102 E 11TH ST S',
        RegAddrCity: 'INDEPENDENCE',
        RegAddrState: 'MO',
        RegAddrZip: 64054,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '15202 E 41ST ST S',
        RegAddrCity: 'INDEPENDENCE',
        RegAddrState: 'MO',
        RegAddrZip: 64055,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '1313 S EMERY AVE',
        RegAddrCity: 'INDEPENDENCE',
        RegAddrState: 'MO',
        RegAddrZip: 64055,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '13015 E 50TH TER S',
        RegAddrCity: 'INDEPENDENCE',
        RegAddrState: 'MO',
        RegAddrZip: 64055,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '12303 E 48TH ST S',
        RegAddrCity: 'INDEPENDENCE',
        RegAddrState: 'MO',
        RegAddrZip: 64055,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '4410 S ATHERTON CT',
        RegAddrCity: 'INDEPENDENCE',
        RegAddrState: 'MO',
        RegAddrZip: 64055,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '18705 E INDEPENDENCE AVE',
        RegAddrCity: 'INDEPENDENCE',
        RegAddrState: 'MO',
        RegAddrZip: 64056,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '420 N BLY RD',
        RegAddrCity: 'INDEPENDENCE',
        RegAddrState: 'MO',
        RegAddrZip: 64056,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '16710 E 5TH ST N',
        RegAddrCity: 'INDEPENDENCE',
        RegAddrState: 'MO',
        RegAddrZip: 64056,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '20902 E 13TH TERRACE CT S',
        RegAddrCity: 'INDEPENDENCE',
        RegAddrState: 'MO',
        RegAddrZip: 64057,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '2813 BERRY LN',
        RegAddrCity: 'INDEPENDENCE',
        RegAddrState: 'MO',
        RegAddrZip: 64057,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '1800 S SWOPE DR',
        RegAddrCity: 'INDEPENDENCE',
        RegAddrState: 'MO',
        RegAddrZip: 64057,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '2016 N COLONY LN',
        RegAddrCity: 'INDEPENDENCE',
        RegAddrState: 'MO',
        RegAddrZip: 64058,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '2322 MERCIER ST',
        RegAddrCity: 'KANSAS CITY',
        RegAddrState: 'MO',
        RegAddrZip: 64108,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '3516 HOLMES ST',
        RegAddrCity: 'KANSAS CITY',
        RegAddrState: 'MO',
        RegAddrZip: 64109,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '3347 FLORA AVE',
        RegAddrCity: 'KANSAS CITY',
        RegAddrState: 'MO',
        RegAddrZip: 64109,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '3803 FLORA AVE',
        RegAddrCity: 'KANSAS CITY',
        RegAddrState: 'MO',
        RegAddrZip: 64109,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '4115 VIRGINIA AVE',
        RegAddrCity: 'KANSAS CITY',
        RegAddrState: 'MO',
        RegAddrZip: 64110,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '6226 HARRISON ST',
        RegAddrCity: 'KANSAS CITY',
        RegAddrState: 'MO',
        RegAddrZip: 64110,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '416 E 63RD TER',
        RegAddrCity: 'KANSAS CITY',
        RegAddrState: 'MO',
        RegAddrZip: 64110,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '3434 W COLEMAN RD',
        RegAddrCity: 'KANSAS CITY',
        RegAddrState: 'MO',
        RegAddrZip: 64111,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '444 W 62ND ST',
        RegAddrCity: 'KANSAS CITY',
        RegAddrState: 'MO',
        RegAddrZip: 64113,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '6033 WYANDOTTE ST',
        RegAddrCity: 'KANSAS CITY',
        RegAddrState: 'MO',
        RegAddrZip: 64113,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '7918 PENNSYLVANIA AVE',
        RegAddrCity: 'KANSAS CITY',
        RegAddrState: 'MO',
        RegAddrZip: 64114,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '7243 WYANDOTTE ST',
        RegAddrCity: 'KANSAS CITY',
        RegAddrState: 'MO',
        RegAddrZip: 64114,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '3809 N WAYNE AVE',
        RegAddrCity: 'KANSAS CITY',
        RegAddrState: 'MO',
        RegAddrZip: 64116,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '3308 MOHAWK LN',
        RegAddrCity: 'KANSAS CITY',
        RegAddrState: 'MO',
        RegAddrZip: 64116,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '7201 NE 46TH ST',
        RegAddrCity: 'KANSAS CITY',
        RegAddrState: 'MO',
        RegAddrZip: 64117,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '620 GARLAND AVE',
        RegAddrCity: 'KANSAS CITY',
        RegAddrState: 'MO',
        RegAddrZip: 64120,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '411 N OAKLEY AVE',
        RegAddrCity: 'KANSAS CITY',
        RegAddrState: 'MO',
        RegAddrZip: 64123,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '407 N ELMWOOD AVE',
        RegAddrCity: 'KANSAS CITY',
        RegAddrState: 'MO',
        RegAddrZip: 64123,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '112 OAKLEY AVE',
        RegAddrCity: 'KANSAS CITY',
        RegAddrState: 'MO',
        RegAddrZip: 64123,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '1323 PARK AVE',
        RegAddrCity: 'KANSAS CITY',
        RegAddrState: 'MO',
        RegAddrZip: 64127,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '2318 COLLEGE AVE',
        RegAddrCity: 'KANSAS CITY',
        RegAddrState: 'MO',
        RegAddrZip: 64127,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '3742 INDIANA AVE',
        RegAddrCity: 'KANSAS CITY',
        RegAddrState: 'MO',
        RegAddrZip: 64128,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '2942 POPLAR AVE',
        RegAddrCity: 'KANSAS CITY',
        RegAddrState: 'MO',
        RegAddrZip: 64128,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '4910 MICHIGAN AVE',
        RegAddrCity: 'KANSAS CITY',
        RegAddrState: 'MO',
        RegAddrZip: 64130,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '11606 CHERRY ST',
        RegAddrCity: 'KANSAS CITY',
        RegAddrState: 'MO',
        RegAddrZip: 64131,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '3822 E 68TH TER',
        RegAddrCity: 'KANSAS CITY',
        RegAddrState: 'MO',
        RegAddrZip: 64132,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '5120 WILLOW AVE',
        RegAddrCity: 'KANSAS CITY',
        RegAddrState: 'MO',
        RegAddrZip: 64133,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '11414 DELMAR ST',
        RegAddrCity: 'KANSAS CITY',
        RegAddrState: 'MO',
        RegAddrZip: 64134,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '11117 BRISTOL TER',
        RegAddrCity: 'KANSAS CITY',
        RegAddrState: 'MO',
        RegAddrZip: 64134,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '7504 E 117TH TER',
        RegAddrCity: 'KANSAS CITY',
        RegAddrState: 'MO',
        RegAddrZip: 64134,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '15409 E 49TH ST',
        RegAddrCity: 'KANSAS CITY',
        RegAddrState: 'MO',
        RegAddrZip: 64136,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '4512 E 105TH ST',
        RegAddrCity: 'KANSAS CITY',
        RegAddrState: 'MO',
        RegAddrZip: 64137,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '4020 E 115TH ST',
        RegAddrCity: 'KANSAS CITY',
        RegAddrState: 'MO',
        RegAddrZip: 64137,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '35 A ST',
        RegAddrCity: 'LAKE LOTAWANA',
        RegAddrState: 'MO',
        RegAddrZip: 64086,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '16289 W 162ND ST',
        RegAddrCity: 'LAWSON',
        RegAddrState: 'MO',
        RegAddrZip: 64062,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '7611 NW LEES SUMMIT RD',
        RegAddrCity: 'LEES SUMMIT',
        RegAddrState: 'MO',
        RegAddrZip: 64064,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '736 SW WINTERGARDEN DR',
        RegAddrCity: 'LEES SUMMIT',
        RegAddrState: 'MO',
        RegAddrZip: 64081,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '1612 SW WHITE RIDGE CT',
        RegAddrCity: 'LEES SUMMIT',
        RegAddrState: 'MO',
        RegAddrZip: 64081,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '2460 NW VALLEY VIEW DR',
        RegAddrCity: 'LEES SUMMIT',
        RegAddrState: 'MO',
        RegAddrZip: 64081,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '1514 SW 1ST ST',
        RegAddrCity: 'LEES SUMMIT',
        RegAddrState: 'MO',
        RegAddrZip: 64081,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '1217 SE LONDON WAY',
        RegAddrCity: 'LEES SUMMIT',
        RegAddrState: 'MO',
        RegAddrZip: 64081,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '3832 SW HARBOR DR',
        RegAddrCity: 'LEES SUMMIT',
        RegAddrState: 'MO',
        RegAddrZip: 64082,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '3916 SW BRIAN LN',
        RegAddrCity: 'LEES SUMMIT',
        RegAddrState: 'MO',
        RegAddrZip: 64082,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '4301 SW FLINTROCK DR',
        RegAddrCity: 'LEES SUMMIT',
        RegAddrState: 'MO',
        RegAddrZip: 64082,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '30774 240TH RD',
        RegAddrCity: 'MARSHALL',
        RegAddrState: 'MO',
        RegAddrZip: 65340,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '1138 STONEHAVEN AVE',
        RegAddrCity: 'MARSHALL',
        RegAddrState: 'MO',
        RegAddrZip: 65340,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '33455 220TH RD',
        RegAddrCity: 'MARSHALL',
        RegAddrState: 'MO',
        RegAddrZip: 65340,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '717 W LEAWOOD DR',
        RegAddrCity: 'MARSHALL',
        RegAddrState: 'MO',
        RegAddrZip: 65340,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '608 S APACHE DR',
        RegAddrCity: 'MARSHALL',
        RegAddrState: 'MO',
        RegAddrZip: 65340,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '816 E 23RD AVE',
        RegAddrCity: 'N KANSAS CITY',
        RegAddrState: 'MO',
        RegAddrZip: 64116,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '1020 E 21ST AVE',
        RegAddrCity: 'N KANSAS CITY',
        RegAddrState: 'MO',
        RegAddrZip: 64116,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '8316 S CORN RD',
        RegAddrCity: 'OAK GROVE',
        RegAddrState: 'MO',
        RegAddrZip: 64075,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '9584 GREENTON RD',
        RegAddrCity: 'ODESSA',
        RegAddrState: 'MO',
        RegAddrZip: 64076,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '206 W NORTH FRONT ST',
        RegAddrCity: 'ORRICK',
        RegAddrState: 'MO',
        RegAddrZip: 64077,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '302 KIRKHAM ST',
        RegAddrCity: 'ORRICK',
        RegAddrState: 'MO',
        RegAddrZip: 64077,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '5400 WILLOW AVE',
        RegAddrCity: 'RAYTOWN',
        RegAddrState: 'MO',
        RegAddrZip: 64133,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '5921 LAUREL AVE',
        RegAddrCity: 'RAYTOWN',
        RegAddrState: 'MO',
        RegAddrZip: 64133,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '9940 E 61ST ST',
        RegAddrCity: 'RAYTOWN',
        RegAddrState: 'MO',
        RegAddrZip: 64133,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '804 WOLLARD BLVD',
        RegAddrCity: 'RICHMOND',
        RegAddrState: 'MO',
        RegAddrZip: 64085,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '39463 W 160TH ST',
        RegAddrCity: 'RICHMOND',
        RegAddrState: 'MO',
        RegAddrZip: 64085,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '13265 110TH LN',
        RegAddrCity: 'SWEET SPRINGS',
        RegAddrState: 'MO',
        RegAddrZip: 65351,
        CongressionalDistrict: 5,
      },
    ],
  },
  {
    name: 'MO-6',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/MO-6?ref=gh-pages',
    state: 'MO',
    state_and_number: 'MO-6',
    zip: 63330,
    congressionalDistrict: 6,
    addressesInDistrict: [
      {
        RegAddrLine1: '17439 IGUANA RD',
        RegAddrCity: 'ATLANTA',
        RegAddrState: 'MO',
        RegAddrZip: 63530,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '2407 NE 2ND ST',
        RegAddrCity: 'BLUE SPRINGS',
        RegAddrState: 'MO',
        RegAddrZip: 64014,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '1500 NW AMESBURY DR',
        RegAddrCity: 'BLUE SPRINGS',
        RegAddrState: 'MO',
        RegAddrZip: 64015,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '5070 SW MARGUERITE ST',
        RegAddrCity: 'BLUE SPRINGS',
        RegAddrState: 'MO',
        RegAddrZip: 64015,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '400 NW LEANNE LN',
        RegAddrCity: 'BLUE SPRINGS',
        RegAddrState: 'MO',
        RegAddrZip: 64015,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '1613 NW ASHLAND PL',
        RegAddrCity: 'BLUE SPRINGS',
        RegAddrState: 'MO',
        RegAddrZip: 64015,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '2408 SW 10TH ST',
        RegAddrCity: 'BLUE SPRINGS',
        RegAddrState: 'MO',
        RegAddrZip: 64015,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '903 SW EAGLE CT',
        RegAddrCity: 'BLUE SPRINGS',
        RegAddrState: 'MO',
        RegAddrZip: 64015,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '13220 NE APPLEWOOD DR',
        RegAddrCity: 'BRECKENRIDGE',
        RegAddrState: 'MO',
        RegAddrZip: 64625,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '1504 VINE ST',
        RegAddrCity: 'CHILLICOTHE',
        RegAddrState: 'MO',
        RegAddrZip: 64601,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '11803 SW FRANK ROSS RD',
        RegAddrCity: 'CLARKSDALE',
        RegAddrState: 'MO',
        RegAddrZip: 64430,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '12518 LAKELAND DR',
        RegAddrCity: 'COUNTRY CLUB',
        RegAddrState: 'MO',
        RegAddrZip: 64506,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '11000 SE STATE ROUTE T',
        RegAddrCity: 'EASTON',
        RegAddrState: 'MO',
        RegAddrZip: 64443,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '450 SE 112TH RD',
        RegAddrCity: 'EASTON',
        RegAddrState: 'MO',
        RegAddrZip: 64443,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '104 N 5TH ST',
        RegAddrCity: 'EDINA',
        RegAddrState: 'MO',
        RegAddrZip: 63537,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '104 S 5TH ST',
        RegAddrCity: 'EDINA',
        RegAddrState: 'MO',
        RegAddrZip: 63537,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '160 E ELSOM ST',
        RegAddrCity: 'EOLIA',
        RegAddrState: 'MO',
        RegAddrZip: 63344,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '1369 HIGHWAY D',
        RegAddrCity: 'EWING',
        RegAddrState: 'MO',
        RegAddrZip: 63440,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '27156 NAPA ST',
        RegAddrCity: 'EWING',
        RegAddrState: 'MO',
        RegAddrZip: 63440,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '412 OLD ORCHARD ST',
        RegAddrCity: 'EXCELSIOR SPG',
        RegAddrState: 'MO',
        RegAddrZip: 64024,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '2300 NE SUMMERFIELD DR',
        RegAddrCity: 'GRAIN VALLEY',
        RegAddrState: 'MO',
        RegAddrZip: 64029,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '1249 NW HIGH VIEW DR',
        RegAddrCity: 'GRAIN VALLEY',
        RegAddrState: 'MO',
        RegAddrZip: 64029,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '1912 NE LIVITHA PL',
        RegAddrCity: 'GRAIN VALLEY',
        RegAddrState: 'MO',
        RegAddrZip: 64029,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '11 PAULINA DR',
        RegAddrCity: 'HANNIBAL',
        RegAddrState: 'MO',
        RegAddrZip: 63401,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '62372 WINDY BLUFFS RD',
        RegAddrCity: 'HANNIBAL',
        RegAddrState: 'MO',
        RegAddrZip: 63401,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '113 SATURN DR',
        RegAddrCity: 'HANNIBAL',
        RegAddrState: 'MO',
        RegAddrZip: 63401,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '7191 SE SNOW DR',
        RegAddrCity: 'HOLT',
        RegAddrState: 'MO',
        RegAddrZip: 64048,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '1444 NW 64TH TER',
        RegAddrCity: 'KANSAS CITY',
        RegAddrState: 'MO',
        RegAddrZip: 64118,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '7005 N WYOMING AVE',
        RegAddrCity: 'KANSAS CITY',
        RegAddrState: 'MO',
        RegAddrZip: 64118,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '7815 N REVERE DR',
        RegAddrCity: 'KANSAS CITY',
        RegAddrState: 'MO',
        RegAddrZip: 64151,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '5805 N BEDFORD AVE',
        RegAddrCity: 'KANSAS CITY',
        RegAddrState: 'MO',
        RegAddrZip: 64151,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '6115 N EVANS AVE',
        RegAddrCity: 'KANSAS CITY',
        RegAddrState: 'MO',
        RegAddrZip: 64151,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '6125 NW 49TH TER',
        RegAddrCity: 'KANSAS CITY',
        RegAddrState: 'MO',
        RegAddrZip: 64151,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '8909 N POLK AVE',
        RegAddrCity: 'KANSAS CITY',
        RegAddrState: 'MO',
        RegAddrZip: 64154,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '11507 N TRACY AVE',
        RegAddrCity: 'KANSAS CITY',
        RegAddrState: 'MO',
        RegAddrZip: 64155,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '8704 N LEWIS AVE',
        RegAddrCity: 'KANSAS CITY',
        RegAddrState: 'MO',
        RegAddrZip: 64157,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '11201 N BOOTH AVE',
        RegAddrCity: 'KANSAS CITY',
        RegAddrState: 'MO',
        RegAddrZip: 64157,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '8806 NE 100TH ST',
        RegAddrCity: 'KANSAS CITY',
        RegAddrState: 'MO',
        RegAddrZip: 64157,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '9108 N OXFORD AVE',
        RegAddrCity: 'KANSAS CITY',
        RegAddrState: 'MO',
        RegAddrZip: 64157,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '8804 NE 74TH TER',
        RegAddrCity: 'KANSAS CITY',
        RegAddrState: 'MO',
        RegAddrZip: 64158,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '7505 NE 74TH TER',
        RegAddrCity: 'KANSAS CITY',
        RegAddrState: 'MO',
        RegAddrZip: 64158,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '7437 N SMALLEY AVE',
        RegAddrCity: 'KANSAS CITY',
        RegAddrState: 'MO',
        RegAddrZip: 64158,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '12104 N STARK AVE',
        RegAddrCity: 'KANSAS CITY',
        RegAddrState: 'MO',
        RegAddrZip: 64167,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '14612 HILLS RD',
        RegAddrCity: 'KEARNEY',
        RegAddrState: 'MO',
        RegAddrZip: 64060,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '16714 ENDICOTT RD',
        RegAddrCity: 'KEARNEY',
        RegAddrState: 'MO',
        RegAddrZip: 64060,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '212 E 22ND ST',
        RegAddrCity: 'KEARNEY',
        RegAddrState: 'MO',
        RegAddrZip: 64060,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '4009 STATE HIGHWAY U',
        RegAddrCity: 'KING CITY',
        RegAddrState: 'MO',
        RegAddrZip: 64463,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '506 W ELIZABETH ST',
        RegAddrCity: 'KIRKSVILLE',
        RegAddrState: 'MO',
        RegAddrZip: 63501,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '23632 BURK TRL',
        RegAddrCity: 'KIRKSVILLE',
        RegAddrState: 'MO',
        RegAddrZip: 63501,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '66 DOCKSIDE DR',
        RegAddrCity: 'LAKE TAPWINGO',
        RegAddrState: 'MO',
        RegAddrZip: 64015,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '6905 PLATINUM LN',
        RegAddrCity: 'LATHROP',
        RegAddrState: 'MO',
        RegAddrZip: 64465,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '1404 NE KENWOOD CIR',
        RegAddrCity: 'LEES SUMMIT',
        RegAddrState: 'MO',
        RegAddrZip: 64064,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '404 NE DOGWOOD LN',
        RegAddrCity: 'LEES SUMMIT',
        RegAddrState: 'MO',
        RegAddrZip: 64086,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '25301 NE COLBERN RD',
        RegAddrCity: 'LEES SUMMIT',
        RegAddrState: 'MO',
        RegAddrZip: 64086,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '1408 NE OAKWOOD DR',
        RegAddrCity: 'LEES SUMMIT',
        RegAddrState: 'MO',
        RegAddrZip: 64086,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '817 MAGNOLIA CT',
        RegAddrCity: 'LIBERTY',
        RegAddrState: 'MO',
        RegAddrZip: 64068,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '143 S LEONARD ST',
        RegAddrCity: 'LIBERTY',
        RegAddrState: 'MO',
        RegAddrZip: 64068,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '568 N SKYLINE DR',
        RegAddrCity: 'LIBERTY',
        RegAddrState: 'MO',
        RegAddrZip: 64068,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '1315 TIMBER RIDGE CT',
        RegAddrCity: 'LIBERTY',
        RegAddrState: 'MO',
        RegAddrZip: 64068,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '32514 LASER DR',
        RegAddrCity: 'MARCELINE',
        RegAddrState: 'MO',
        RegAddrZip: 64658,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '555 W 9TH ST',
        RegAddrCity: 'MARYVILLE',
        RegAddrState: 'MO',
        RegAddrZip: 64468,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '512 E DAVISON SQ',
        RegAddrCity: 'MARYVILLE',
        RegAddrState: 'MO',
        RegAddrZip: 64468,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '703 KATY DR',
        RegAddrCity: 'MARYVILLE',
        RegAddrState: 'MO',
        RegAddrZip: 64468,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '777 NW MAYS RD',
        RegAddrCity: 'MAYSVILLE',
        RegAddrState: 'MO',
        RegAddrZip: 64469,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '308 LONG ST',
        RegAddrCity: 'MENDON',
        RegAddrState: 'MO',
        RegAddrZip: 64660,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '107 SOMMERSET PL',
        RegAddrCity: 'MERCER',
        RegAddrState: 'MO',
        RegAddrZip: 64661,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '511 E SUMMER ST',
        RegAddrCity: 'MONROE CITY',
        RegAddrState: 'MO',
        RegAddrZip: 63456,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '40030 HIGHWAY W',
        RegAddrCity: 'MONROE CITY',
        RegAddrState: 'MO',
        RegAddrZip: 63456,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '6495 N NEVADA AVE',
        RegAddrCity: 'PARKVILLE',
        RegAddrState: 'MO',
        RegAddrZip: 64152,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '9407 NW 67TH ST',
        RegAddrCity: 'PARKVILLE',
        RegAddrState: 'MO',
        RegAddrZip: 64152,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '6712 NW EASTSIDE DR',
        RegAddrCity: 'PARKVILLE',
        RegAddrState: 'MO',
        RegAddrZip: 64152,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '6614 NW VALLEY VIEW ST',
        RegAddrCity: 'PARKVILLE',
        RegAddrState: 'MO',
        RegAddrZip: 64152,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '12685 OAK HARBOR DR',
        RegAddrCity: 'PLATTE CITY',
        RegAddrState: 'MO',
        RegAddrZip: 64079,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '1891 WILKERSON ST',
        RegAddrCity: 'PLATTE CITY',
        RegAddrState: 'MO',
        RegAddrZip: 64079,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '109 CARTER DR',
        RegAddrCity: 'PLATTSBURG',
        RegAddrState: 'MO',
        RegAddrZip: 64477,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '4275 SW 220TH ST',
        RegAddrCity: 'PLATTSBURG',
        RegAddrState: 'MO',
        RegAddrZip: 64477,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '12392 US HIGHWAY 275',
        RegAddrCity: 'ROCK PORT',
        RegAddrState: 'MO',
        RegAddrZip: 64482,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '1715 S 11TH ST',
        RegAddrCity: 'SAINT JOSEPH',
        RegAddrState: 'MO',
        RegAddrZip: 64503,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '2215 S 12TH ST',
        RegAddrCity: 'SAINT JOSEPH',
        RegAddrState: 'MO',
        RegAddrZip: 64503,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '1509 SE BUSH RD',
        RegAddrCity: 'SAINT JOSEPH',
        RegAddrState: 'MO',
        RegAddrZip: 64504,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '3801 SW CHRISTIE TER',
        RegAddrCity: 'SAINT JOSEPH',
        RegAddrState: 'MO',
        RegAddrZip: 64504,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '2709 QUAIL DR',
        RegAddrCity: 'SAINT JOSEPH',
        RegAddrState: 'MO',
        RegAddrZip: 64506,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '8910 NE STATE ROUTE 6',
        RegAddrCity: 'SAINT JOSEPH',
        RegAddrState: 'MO',
        RegAddrZip: 64507,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '707 WARSAW AVE',
        RegAddrCity: 'SAINT JOSEPH',
        RegAddrState: 'MO',
        RegAddrZip: 64507,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '5213 STONERIDGE DR',
        RegAddrCity: 'SAINT JOSEPH',
        RegAddrState: 'MO',
        RegAddrZip: 64507,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '704 W BENTON ST',
        RegAddrCity: 'SAVANNAH',
        RegAddrState: 'MO',
        RegAddrZip: 64485,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '1074 S GREENTREE AVE',
        RegAddrCity: 'SPRINGFIELD',
        RegAddrState: 'MO',
        RegAddrZip: 65809,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '1607 N 10TH ST',
        RegAddrCity: 'ST JOSEPH',
        RegAddrState: 'MO',
        RegAddrZip: 64501,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '217 W ISABELLE ST',
        RegAddrCity: 'ST JOSEPH',
        RegAddrState: 'MO',
        RegAddrZip: 64501,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '613 N HIGH ST',
        RegAddrCity: 'STANBERRY',
        RegAddrState: 'MO',
        RegAddrZip: 64489,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '805 BIRCH ST',
        RegAddrCity: 'TARKIO',
        RegAddrState: 'MO',
        RegAddrZip: 64491,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '429 W 12TH ST',
        RegAddrCity: 'TRENTON',
        RegAddrState: 'MO',
        RegAddrZip: 64683,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '307 N HIGHWAY 169',
        RegAddrCity: 'TRIMBLE',
        RegAddrState: 'MO',
        RegAddrZip: 64492,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '41427 STATE HWY W',
        RegAddrCity: 'UNIONVILLE',
        RegAddrState: 'MO',
        RegAddrZip: 63565,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '13104 LINDEN LN',
        RegAddrCity: 'UNIONVILLE',
        RegAddrState: 'MO',
        RegAddrZip: 63565,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '11950 STATE HIGHWAY 129',
        RegAddrCity: 'UNIONVILLE',
        RegAddrState: 'MO',
        RegAddrZip: 63565,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '109 W BLAND ST',
        RegAddrCity: 'VANDALIA',
        RegAddrState: 'MO',
        RegAddrZip: 63382,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '416 N MAIN ST',
        RegAddrCity: 'VANDALIA',
        RegAddrState: 'MO',
        RegAddrZip: 63382,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '18435 SCHABACK CIR',
        RegAddrCity: 'WESTON',
        RegAddrState: 'MO',
        RegAddrZip: 64098,
        CongressionalDistrict: 6,
      },
    ],
  },
  {
    name: 'MO-7',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/MO-7?ref=gh-pages',
    state: 'MO',
    state_and_number: 'MO-7',
    zip: 64801,
    congressionalDistrict: 7,
    addressesInDistrict: [
      {
        RegAddrLine1: '509 W CAVE ST',
        RegAddrCity: 'ASH GROVE',
        RegAddrState: 'MO',
        RegAddrZip: 65604,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '1700 S HUDSON AVE',
        RegAddrCity: 'AURORA',
        RegAddrState: 'MO',
        RegAddrZip: 65605,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '4948 HIGHWAY F',
        RegAddrCity: 'BOLIVAR',
        RegAddrState: 'MO',
        RegAddrZip: 65613,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '1855 E 523RD RD',
        RegAddrCity: 'BOLIVAR',
        RegAddrState: 'MO',
        RegAddrZip: 65613,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '1188 E 412TH RD',
        RegAddrCity: 'BOLIVAR',
        RegAddrState: 'MO',
        RegAddrZip: 65613,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '995 STATE HIGHWAY 165',
        RegAddrCity: 'BRANSON',
        RegAddrState: 'MO',
        RegAddrZip: 65616,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '166 INDIAN LN',
        RegAddrCity: 'BRANSON',
        RegAddrState: 'MO',
        RegAddrZip: 65616,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '190 BUNKER HILL DR',
        RegAddrCity: 'BRANSON',
        RegAddrState: 'MO',
        RegAddrZip: 65616,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '132 MAPLEWOOD DR',
        RegAddrCity: 'BRANSON',
        RegAddrState: 'MO',
        RegAddrZip: 65616,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '272 SUMMERBROOKE LN',
        RegAddrCity: 'BRANSON',
        RegAddrState: 'MO',
        RegAddrZip: 65616,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '191 MARK TWAIN DR',
        RegAddrCity: 'BRANSON WEST',
        RegAddrState: 'MO',
        RegAddrZip: 65737,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '4855 S LOUISE RD',
        RegAddrCity: 'BROOKLINE',
        RegAddrState: 'MO',
        RegAddrZip: 65619,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '916 LARSON ST',
        RegAddrCity: 'CARL JUNCTION',
        RegAddrState: 'MO',
        RegAddrZip: 64834,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '1815 WYNWOOD DR',
        RegAddrCity: 'CARTHAGE',
        RegAddrState: 'MO',
        RegAddrZip: 64836,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '12240 STATE HIGHWAY 96',
        RegAddrCity: 'CARTHAGE',
        RegAddrState: 'MO',
        RegAddrZip: 64836,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '839 OLIVE ST',
        RegAddrCity: 'CARTHAGE',
        RegAddrState: 'MO',
        RegAddrZip: 64836,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '101 KATHY DR',
        RegAddrCity: 'CASSVILLE',
        RegAddrState: 'MO',
        RegAddrZip: 65625,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '314 SUGAR LN',
        RegAddrCity: 'CLEVER',
        RegAddrState: 'MO',
        RegAddrZip: 65631,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '156 LISTEN LN',
        RegAddrCity: 'CLEVER',
        RegAddrState: 'MO',
        RegAddrZip: 65631,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '42196 STATE HIGHWAY 413',
        RegAddrCity: 'CRANE',
        RegAddrState: 'MO',
        RegAddrZip: 65633,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '3910 HIGHWAY O',
        RegAddrCity: 'DUNNEGAN',
        RegAddrState: 'MO',
        RegAddrZip: 65640,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '3883 HIGHWAY O',
        RegAddrCity: 'DUNNEGAN',
        RegAddrState: 'MO',
        RegAddrZip: 65640,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '333 KELLEN ST',
        RegAddrCity: 'FAIR GROVE',
        RegAddrState: 'MO',
        RegAddrZip: 65648,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '131 CEDAR BROOKE ESTATES DR',
        RegAddrCity: 'FORSYTH',
        RegAddrState: 'MO',
        RegAddrZip: 65653,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '23100 E MOCKINGBIRD DR',
        RegAddrCity: 'GOLDEN',
        RegAddrState: 'MO',
        RegAddrZip: 65658,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '110 SCOTCH PINE CT',
        RegAddrCity: 'HOLLISTER',
        RegAddrState: 'MO',
        RegAddrZip: 65672,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '439 PENNSYLVANIA AVE',
        RegAddrCity: 'JASPER',
        RegAddrState: 'MO',
        RegAddrZip: 64755,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '2130 N SAINT CHARLES AVE',
        RegAddrCity: 'JOPLIN',
        RegAddrState: 'MO',
        RegAddrZip: 64801,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '3715 RUSTWOOD RD',
        RegAddrCity: 'JOPLIN',
        RegAddrState: 'MO',
        RegAddrZip: 64801,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '2400 E 10TH ST',
        RegAddrCity: 'JOPLIN',
        RegAddrState: 'MO',
        RegAddrZip: 64801,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '4882 E 7TH ST',
        RegAddrCity: 'JOPLIN',
        RegAddrState: 'MO',
        RegAddrZip: 64801,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '2328 S TYLER AVE',
        RegAddrCity: 'JOPLIN',
        RegAddrState: 'MO',
        RegAddrZip: 64804,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '6982 ELAND RD',
        RegAddrCity: 'JOPLIN',
        RegAddrState: 'MO',
        RegAddrZip: 64804,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '2702 WISCONSIN AVE',
        RegAddrCity: 'JOPLIN',
        RegAddrState: 'MO',
        RegAddrZip: 64804,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '2841 S MINA AVE',
        RegAddrCity: 'JOPLIN',
        RegAddrState: 'MO',
        RegAddrZip: 64804,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '231 W 41ST ST',
        RegAddrCity: 'JOPLIN',
        RegAddrState: 'MO',
        RegAddrZip: 64804,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '126 THE LODGES DR',
        RegAddrCity: 'LAMPE',
        RegAddrState: 'MO',
        RegAddrZip: 65681,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '500 S NECESSITY',
        RegAddrCity: 'MARIONVILLE',
        RegAddrState: 'MO',
        RegAddrZip: 65705,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '498 S NECESSITY',
        RegAddrCity: 'MARIONVILLE',
        RegAddrState: 'MO',
        RegAddrZip: 65705,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '16258 LAWRENCE 1241',
        RegAddrCity: 'MARIONVILLE',
        RegAddrState: 'MO',
        RegAddrZip: 65705,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '104 PRYOR ST',
        RegAddrCity: 'MONETT',
        RegAddrState: 'MO',
        RegAddrZip: 65708,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '14417 LAWRENCE 1137',
        RegAddrCity: 'MOUNT VERNON',
        RegAddrState: 'MO',
        RegAddrZip: 65712,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '901 W 1ST ST',
        RegAddrCity: 'MOUNTAIN GROVE',
        RegAddrState: 'MO',
        RegAddrZip: 65711,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '116 SUMMIT VIEW CT',
        RegAddrCity: 'NEOSHO',
        RegAddrState: 'MO',
        RegAddrZip: 64850,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '12738 IRIS RD',
        RegAddrCity: 'NEOSHO',
        RegAddrState: 'MO',
        RegAddrZip: 64850,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '407 W EVERWOOD WAY',
        RegAddrCity: 'NIXA',
        RegAddrState: 'MO',
        RegAddrZip: 65714,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '605 N MONTCLAIR WAY',
        RegAddrCity: 'NIXA',
        RegAddrState: 'MO',
        RegAddrZip: 65714,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '102 W OAK ST',
        RegAddrCity: 'NIXA',
        RegAddrState: 'MO',
        RegAddrZip: 65714,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '801 E STRIPER DR',
        RegAddrCity: 'NIXA',
        RegAddrState: 'MO',
        RegAddrZip: 65714,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '711 ROBINWOOD LN',
        RegAddrCity: 'NIXA',
        RegAddrState: 'MO',
        RegAddrZip: 65714,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '4215 ROUTE DD',
        RegAddrCity: 'NOEL',
        RegAddrState: 'MO',
        RegAddrZip: 64854,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '712 GRANDVIEW ST',
        RegAddrCity: 'NOEL',
        RegAddrState: 'MO',
        RegAddrZip: 64854,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '433 W AMBER DR',
        RegAddrCity: 'ORONOGO',
        RegAddrState: 'MO',
        RegAddrZip: 64855,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '602 E WARREN AVE',
        RegAddrCity: 'OZARK',
        RegAddrState: 'MO',
        RegAddrZip: 65721,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '1214 W MCGUFFEY ST',
        RegAddrCity: 'OZARK',
        RegAddrState: 'MO',
        RegAddrZip: 65721,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '1061 S 13TH AVE',
        RegAddrCity: 'OZARK',
        RegAddrState: 'MO',
        RegAddrZip: 65721,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '1006 W CORY ST',
        RegAddrCity: 'OZARK',
        RegAddrState: 'MO',
        RegAddrZip: 65721,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '400 N ELM ST',
        RegAddrCity: 'PIERCE CITY',
        RegAddrState: 'MO',
        RegAddrZip: 65723,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '22713 LAWRENCE 1026',
        RegAddrCity: 'PIERCE CITY',
        RegAddrState: 'MO',
        RegAddrZip: 65723,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '11312 FARM ROAD 1045',
        RegAddrCity: 'PURDY',
        RegAddrState: 'MO',
        RegAddrZip: 65734,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '302 E MONROE ST',
        RegAddrCity: 'PURDY',
        RegAddrState: 'MO',
        RegAddrZip: 65734,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '313 N MAPLE AVE',
        RegAddrCity: 'REPUBLIC',
        RegAddrState: 'MO',
        RegAddrZip: 65738,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '8915 E FARM ROAD 186',
        RegAddrCity: 'ROGERSVILLE',
        RegAddrState: 'MO',
        RegAddrZip: 65742,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '33993 US HIGHWAY 160',
        RegAddrCity: 'RUETER',
        RegAddrState: 'MO',
        RegAddrZip: 65744,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '205 S 9TH ST',
        RegAddrCity: 'SARCOXIE',
        RegAddrState: 'MO',
        RegAddrZip: 64862,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '3303 COUNTY LINE RD',
        RegAddrCity: 'SENECA',
        RegAddrState: 'MO',
        RegAddrZip: 64865,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '5600 W PECAN ST',
        RegAddrCity: 'SPRINGFIELD',
        RegAddrState: 'MO',
        RegAddrZip: 65802,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '1007 N FREMONT AVE',
        RegAddrCity: 'SPRINGFIELD',
        RegAddrState: 'MO',
        RegAddrZip: 65802,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '4020 W WOODSHILL CT',
        RegAddrCity: 'SPRINGFIELD',
        RegAddrState: 'MO',
        RegAddrZip: 65802,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '2345 N CITATION AVE',
        RegAddrCity: 'SPRINGFIELD',
        RegAddrState: 'MO',
        RegAddrZip: 65802,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '920 N BROADWAY AVE',
        RegAddrCity: 'SPRINGFIELD',
        RegAddrState: 'MO',
        RegAddrZip: 65802,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '3734 E KENSINGTON DR',
        RegAddrCity: 'SPRINGFIELD',
        RegAddrState: 'MO',
        RegAddrZip: 65802,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '721 W TURNER ST',
        RegAddrCity: 'SPRINGFIELD',
        RegAddrState: 'MO',
        RegAddrZip: 65803,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '836 N HOMEWOOD AVE',
        RegAddrCity: 'SPRINGFIELD',
        RegAddrState: 'MO',
        RegAddrZip: 65803,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '2650 N EAST AVE',
        RegAddrCity: 'SPRINGFIELD',
        RegAddrState: 'MO',
        RegAddrZip: 65803,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '3739 N MILL RIDGE AVE',
        RegAddrCity: 'SPRINGFIELD',
        RegAddrState: 'MO',
        RegAddrZip: 65803,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '1310 S PRINCE LN',
        RegAddrCity: 'SPRINGFIELD',
        RegAddrState: 'MO',
        RegAddrZip: 65804,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '2212 S PICKWICK AVE',
        RegAddrCity: 'SPRINGFIELD',
        RegAddrState: 'MO',
        RegAddrZip: 65804,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '1345 E ANTHONY ST',
        RegAddrCity: 'SPRINGFIELD',
        RegAddrState: 'MO',
        RegAddrZip: 65804,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '215 S CAMPBELL AVE',
        RegAddrCity: 'SPRINGFIELD',
        RegAddrState: 'MO',
        RegAddrZip: 65806,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '925 W WOODLAND ST',
        RegAddrCity: 'SPRINGFIELD',
        RegAddrState: 'MO',
        RegAddrZip: 65807,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '2548 W VILLAGE LN',
        RegAddrCity: 'SPRINGFIELD',
        RegAddrState: 'MO',
        RegAddrZip: 65807,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '1019 E CAMBRIDGE ST',
        RegAddrCity: 'SPRINGFIELD',
        RegAddrState: 'MO',
        RegAddrZip: 65807,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '625 E SEMINOLE ST',
        RegAddrCity: 'SPRINGFIELD',
        RegAddrState: 'MO',
        RegAddrZip: 65807,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '3481 S DORIS AVE',
        RegAddrCity: 'SPRINGFIELD',
        RegAddrState: 'MO',
        RegAddrZip: 65807,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '606 W PORTLAND ST',
        RegAddrCity: 'SPRINGFIELD',
        RegAddrState: 'MO',
        RegAddrZip: 65807,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '2946 W LATOKA ST',
        RegAddrCity: 'SPRINGFIELD',
        RegAddrState: 'MO',
        RegAddrZip: 65807,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '3446 S WARREN AVE',
        RegAddrCity: 'SPRINGFIELD',
        RegAddrState: 'MO',
        RegAddrZip: 65807,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '720 S FARM ROAD 197',
        RegAddrCity: 'SPRINGFIELD',
        RegAddrState: 'MO',
        RegAddrZip: 65809,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '737 S BROADVIEW DR',
        RegAddrCity: 'SPRINGFIELD',
        RegAddrState: 'MO',
        RegAddrZip: 65809,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '4331 E GASTONBURY PL',
        RegAddrCity: 'SPRINGFIELD',
        RegAddrState: 'MO',
        RegAddrZip: 65809,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '2930 W WESTCHESTER CT',
        RegAddrCity: 'SPRINGFIELD',
        RegAddrState: 'MO',
        RegAddrZip: 65810,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '5547 S KIMBROUGH AVE',
        RegAddrCity: 'SPRINGFIELD',
        RegAddrState: 'MO',
        RegAddrZip: 65810,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '4520 S WARREN RD',
        RegAddrCity: 'SPRINGFIELD',
        RegAddrState: 'MO',
        RegAddrZip: 65810,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '5641 S FARM ROAD 163',
        RegAddrCity: 'SPRINGFIELD',
        RegAddrState: 'MO',
        RegAddrZip: 65810,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '5484 S JEFFERSON AVE',
        RegAddrCity: 'SPRINGFIELD',
        RegAddrState: 'MO',
        RegAddrZip: 65810,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '104 NORTH ST',
        RegAddrCity: 'STARK CITY',
        RegAddrState: 'MO',
        RegAddrZip: 64866,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '868 DUFFIELD RD',
        RegAddrCity: 'STELLA',
        RegAddrState: 'MO',
        RegAddrZip: 64867,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '3132 N FAIR HAVEN LOOP',
        RegAddrCity: 'STRAFFORD',
        RegAddrState: 'MO',
        RegAddrZip: 65757,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '811 N MAIN ST',
        RegAddrCity: 'WEBB CITY',
        RegAddrState: 'MO',
        RegAddrZip: 64870,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '24706 STATE HIGHWAY 171',
        RegAddrCity: 'WEBB CITY',
        RegAddrState: 'MO',
        RegAddrZip: 64870,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '326 CRESTWOOD DR',
        RegAddrCity: 'WEBB CITY',
        RegAddrState: 'MO',
        RegAddrZip: 64870,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '304 WILLEY ST',
        RegAddrCity: 'WILLARD',
        RegAddrState: 'MO',
        RegAddrZip: 65781,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '400 W WALNUT LN',
        RegAddrCity: 'WILLARD',
        RegAddrState: 'MO',
        RegAddrZip: 65781,
        CongressionalDistrict: 7,
      },
    ],
  },
  {
    name: 'MO-8',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/MO-8?ref=gh-pages',
    state: 'MO',
    state_and_number: 'MO-8',
    zip: 63020,
    congressionalDistrict: 8,
    addressesInDistrict: [
      {
        RegAddrLine1: '277 COUNTY ROAD 309',
        RegAddrCity: 'ALTON',
        RegAddrState: 'MO',
        RegAddrZip: 65606,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '1202 CRAIN ST',
        RegAddrCity: 'AVA',
        RegAddrState: 'MO',
        RegAddrZip: 65608,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '13704 WEBSTER RD',
        RegAddrCity: 'BELGRADE',
        RegAddrState: 'MO',
        RegAddrZip: 63622,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '3415 DIANNE DR',
        RegAddrCity: 'BERNIE',
        RegAddrState: 'MO',
        RegAddrZip: 63822,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '70 COUNTY ROAD 434',
        RegAddrCity: 'BIRCH TREE',
        RegAddrState: 'MO',
        RegAddrZip: 65438,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: 'PO BOX 251',
        RegAddrCity: 'BIRCH TREE',
        RegAddrState: 'MO',
        RegAddrZip: 65438,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '705 MULBERRY ST',
        RegAddrCity: 'BISMARCK',
        RegAddrState: 'MO',
        RegAddrZip: 63624,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '607 W BLOOMFIELD AVE',
        RegAddrCity: 'BLOOMFIELD',
        RegAddrState: 'MO',
        RegAddrZip: 63825,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '9555 MEADOW LN',
        RegAddrCity: 'BONNE TERRE',
        RegAddrState: 'MO',
        RegAddrZip: 63628,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '1593 RUE RIVIERA',
        RegAddrCity: 'BONNE TERRE',
        RegAddrState: 'MO',
        RegAddrZip: 63628,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '35 E JOHNSON ST',
        RegAddrCity: 'BONNE TERRE',
        RegAddrState: 'MO',
        RegAddrZip: 63628,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '232 RED FOX DR',
        RegAddrCity: 'BOURBON',
        RegAddrState: 'MO',
        RegAddrZip: 65441,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '716 STATE HIGHWAY U',
        RegAddrCity: 'BURFORDVILLE',
        RegAddrState: 'MO',
        RegAddrZip: 63739,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '1330 GRANT ST',
        RegAddrCity: 'CABOOL',
        RegAddrState: 'MO',
        RegAddrZip: 65689,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '11073 WICKET RD',
        RegAddrCity: 'CADET',
        RegAddrState: 'MO',
        RegAddrZip: 63630,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '2551 MASTERS DR',
        RegAddrCity: 'CAPE GIRARDEAU',
        RegAddrState: 'MO',
        RegAddrZip: 63701,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '10769 DITTMER CATAWISSA RD',
        RegAddrCity: 'CATAWISSA',
        RegAddrState: 'MO',
        RegAddrZip: 63015,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '308 S 3RD ST',
        RegAddrCity: 'CHAFFEE',
        RegAddrState: 'MO',
        RegAddrZip: 63740,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '197 FAWN DR',
        RegAddrCity: 'CHAFFEE',
        RegAddrState: 'MO',
        RegAddrZip: 63740,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '31020 COUNTY ROAD 322A',
        RegAddrCity: 'CLARKTON',
        RegAddrState: 'MO',
        RegAddrZip: 63837,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '1941 WEISSINGER LN W',
        RegAddrCity: 'CPE GIRARDEAU',
        RegAddrState: 'MO',
        RegAddrZip: 63701,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '1617 THEMIS ST',
        RegAddrCity: 'CPE GIRARDEAU',
        RegAddrState: 'MO',
        RegAddrZip: 63701,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '1937 DAVID ST',
        RegAddrCity: 'CPE GIRARDEAU',
        RegAddrState: 'MO',
        RegAddrZip: 63701,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '767 CEDAR LN',
        RegAddrCity: 'CPE GIRARDEAU',
        RegAddrState: 'MO',
        RegAddrZip: 63701,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '232 BARBERRY ST',
        RegAddrCity: 'CPE GIRARDEAU',
        RegAddrState: 'MO',
        RegAddrZip: 63703,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '13502 KLONDIKE RD',
        RegAddrCity: 'DE SOTO',
        RegAddrState: 'MO',
        RegAddrZip: 63020,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '105 S 5TH ST',
        RegAddrCity: 'DE SOTO',
        RegAddrState: 'MO',
        RegAddrZip: 63020,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '318 LEMBECK TRL',
        RegAddrCity: 'DE SOTO',
        RegAddrState: 'MO',
        RegAddrZip: 63020,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '1841 RIPLEY 142E-20B',
        RegAddrCity: 'DONIPHAN',
        RegAddrState: 'MO',
        RegAddrZip: 63935,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '19 BETTY SUE DR',
        RegAddrCity: 'EAST PRAIRIE',
        RegAddrState: 'MO',
        RegAddrZip: 63845,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '98 DEER RUN DR',
        RegAddrCity: 'ELLINGTON',
        RegAddrState: 'MO',
        RegAddrZip: 63638,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '359 CARTER 259',
        RegAddrCity: 'ELLSINORE',
        RegAddrState: 'MO',
        RegAddrZip: 63937,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '1009 STONE CREEK RD',
        RegAddrCity: 'FARMINGTON',
        RegAddrState: 'MO',
        RegAddrZip: 63640,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '9 WINCHESTER RD',
        RegAddrCity: 'FARMINGTON',
        RegAddrState: 'MO',
        RegAddrZip: 63640,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '434 EVERGLADES DR',
        RegAddrCity: 'FARMINGTON',
        RegAddrState: 'MO',
        RegAddrZip: 63640,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '316 E 1ST ST',
        RegAddrCity: 'FARMINGTON',
        RegAddrState: 'MO',
        RegAddrZip: 63640,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '2010 ROCK N HORSE FARMS DR',
        RegAddrCity: 'FESTUS',
        RegAddrState: 'MO',
        RegAddrZip: 63028,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '506 S 5TH ST',
        RegAddrCity: 'FESTUS',
        RegAddrState: 'MO',
        RegAddrZip: 63028,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '3224 CAMBRIDGE DR',
        RegAddrCity: 'FESTUS',
        RegAddrState: 'MO',
        RegAddrZip: 63028,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '802 MARSHALL ST',
        RegAddrCity: 'FREDERICKTOWN',
        RegAddrState: 'MO',
        RegAddrZip: 63645,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '406 W MAIN ST',
        RegAddrCity: 'FREDERICKTOWN',
        RegAddrState: 'MO',
        RegAddrZip: 63645,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '8633 OLD JACKSON RD',
        RegAddrCity: 'FREDERICKTOWN',
        RegAddrState: 'MO',
        RegAddrZip: 63645,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '210 E KELLY ST',
        RegAddrCity: 'FREDERICKTOWN',
        RegAddrState: 'MO',
        RegAddrZip: 63645,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '1199 STATE HIGHWAY 5',
        RegAddrCity: 'GAINESVILLE',
        RegAddrState: 'MO',
        RegAddrZip: 65655,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '130 W SOUTH ST',
        RegAddrCity: 'HARTVILLE',
        RegAddrState: 'MO',
        RegAddrZip: 65667,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '710 E PATRICIA DR',
        RegAddrCity: 'HAYTI',
        RegAddrState: 'MO',
        RegAddrZip: 63851,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '9870 W VISTA DR',
        RegAddrCity: 'HILLSBORO',
        RegAddrState: 'MO',
        RegAddrZip: 63050,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '11487 STATE ROUTE 21',
        RegAddrCity: 'HILLSBORO',
        RegAddrState: 'MO',
        RegAddrZip: 63050,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '9936 BECKMANN DR',
        RegAddrCity: 'HILLSBORO',
        RegAddrState: 'MO',
        RegAddrZip: 63050,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '23470 STATE HIGHWAY 25',
        RegAddrCity: 'HOLCOMB',
        RegAddrState: 'MO',
        RegAddrZip: 63852,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '18387 BRUSHY CREEK RD',
        RegAddrCity: 'HOUSTON',
        RegAddrState: 'MO',
        RegAddrZip: 65483,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '7901 LONE STAR DR',
        RegAddrCity: 'HOUSTON',
        RegAddrState: 'MO',
        RegAddrZip: 65483,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '13052 CEDAR CREEK RD',
        RegAddrCity: 'IRONDALE',
        RegAddrState: 'MO',
        RegAddrZip: 63648,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '343 EIDSON CT',
        RegAddrCity: 'IRONTON',
        RegAddrState: 'MO',
        RegAddrZip: 63650,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '1602 COLUMBIA DR',
        RegAddrCity: 'JACKSON',
        RegAddrState: 'MO',
        RegAddrZip: 63755,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '167 SUN VALLEY CT',
        RegAddrCity: 'JACKSON',
        RegAddrState: 'MO',
        RegAddrZip: 63755,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '1019 VINE ST',
        RegAddrCity: 'KENNETT',
        RegAddrState: 'MO',
        RegAddrZip: 63857,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '304 JONES ST',
        RegAddrCity: 'KENNETT',
        RegAddrState: 'MO',
        RegAddrZip: 63857,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '1424 W DUNKLIN ST',
        RegAddrCity: 'KENNETT',
        RegAddrState: 'MO',
        RegAddrZip: 63857,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '24 WOODLAWN DR',
        RegAddrCity: 'LEADINGTON',
        RegAddrState: 'MO',
        RegAddrZip: 63601,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '518 NORMAN DR',
        RegAddrCity: 'MALDEN',
        RegAddrState: 'MO',
        RegAddrZip: 63863,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '316 W 7TH ST',
        RegAddrCity: 'MOUNTAIN GRV',
        RegAddrState: 'MO',
        RegAddrZip: 65711,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '1143 N OAKLAND AVE',
        RegAddrCity: 'MOUNTAIN GRV',
        RegAddrState: 'MO',
        RegAddrZip: 65711,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '210 W 3RD ST',
        RegAddrCity: 'MOUNTAIN VIEW',
        RegAddrState: 'MO',
        RegAddrZip: 65548,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '1664 COUNTY ROAD 2990',
        RegAddrCity: 'MOUNTAIN VIEW',
        RegAddrState: 'MO',
        RegAddrZip: 65548,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '1117 SCOTT ST',
        RegAddrCity: 'NEW MADRID',
        RegAddrState: 'MO',
        RegAddrZip: 63869,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '334 SAINT PAUL DR',
        RegAddrCity: 'NEW MADRID',
        RegAddrState: 'MO',
        RegAddrZip: 63869,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '13331 STATE ROUTE W',
        RegAddrCity: 'NEWBURG',
        RegAddrState: 'MO',
        RegAddrZip: 65550,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '905 PAMELA CT',
        RegAddrCity: 'PARK HILLS',
        RegAddrState: 'MO',
        RegAddrZip: 63601,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: 'PO BOX 2515',
        RegAddrCity: 'PATTON',
        RegAddrState: 'MO',
        RegAddrZip: 63662,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '376 PCR 350',
        RegAddrCity: 'PERRYVILLE',
        RegAddrState: 'MO',
        RegAddrZip: 63775,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '3580 WAYNE 339',
        RegAddrCity: 'PIEDMONT',
        RegAddrState: 'MO',
        RegAddrZip: 63957,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '950 IVADENE DR',
        RegAddrCity: 'POPLAR BLUFF',
        RegAddrState: 'MO',
        RegAddrZip: 63901,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '1511 ATKINS RD',
        RegAddrCity: 'POPLAR BLUFF',
        RegAddrState: 'MO',
        RegAddrZip: 63901,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '0 UNKNOWN ST',
        RegAddrCity: 'POPLAR BLUFF',
        RegAddrState: 'MO',
        RegAddrZip: 63901,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '2114 WOODS ST',
        RegAddrCity: 'POPLAR BLUFF',
        RegAddrState: 'MO',
        RegAddrZip: 63901,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '411 ROBIN LN',
        RegAddrCity: 'PORTAGEVILLE',
        RegAddrState: 'MO',
        RegAddrZip: 63873,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '10519 GUN CLUB RD',
        RegAddrCity: 'POTOSI',
        RegAddrState: 'MO',
        RegAddrZip: 63664,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '5835 STATE HIGHWAY T',
        RegAddrCity: 'PUXICO',
        RegAddrState: 'MO',
        RegAddrZip: 63960,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '10157 FALCON RD',
        RegAddrCity: 'RICHWOODS',
        RegAddrState: 'MO',
        RegAddrZip: 63071,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '1500 RAMSEY PLACE RD',
        RegAddrCity: 'ROLLA',
        RegAddrState: 'MO',
        RegAddrZip: 65401,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '14191 COUNTY ROAD 8120',
        RegAddrCity: 'ROLLA',
        RegAddrState: 'MO',
        RegAddrZip: 65401,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '818 HELEN ST',
        RegAddrCity: 'SAINT JAMES',
        RegAddrState: 'MO',
        RegAddrZip: 65559,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '812 E 4TH ST',
        RegAddrCity: 'SALEM',
        RegAddrState: 'MO',
        RegAddrZip: 65560,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '412 DEARBORN ST',
        RegAddrCity: 'SCOTT CITY',
        RegAddrState: 'MO',
        RegAddrZip: 63780,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '591 HIGHWAY KK',
        RegAddrCity: 'SEDGEWICKVLLE',
        RegAddrState: 'MO',
        RegAddrZip: 63781,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '208 MILLER ST',
        RegAddrCity: 'SIKESTON',
        RegAddrState: 'MO',
        RegAddrZip: 63801,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '721 SIKES AVE',
        RegAddrCity: 'SIKESTON',
        RegAddrState: 'MO',
        RegAddrZip: 63801,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '228 WATSON ST',
        RegAddrCity: 'SIKESTON',
        RegAddrState: 'MO',
        RegAddrZip: 63801,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '2103 STATE HIGHWAY AA',
        RegAddrCity: 'SIKESTON',
        RegAddrState: 'MO',
        RegAddrZip: 63801,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '13389 LAKEWOOD DR',
        RegAddrCity: 'STE GENEVIEVE',
        RegAddrState: 'MO',
        RegAddrZip: 63670,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '164 N KLEIN RD',
        RegAddrCity: 'STEELVILLE',
        RegAddrState: 'MO',
        RegAddrZip: 65565,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '631 VINE ST',
        RegAddrCity: 'THAYER',
        RegAddrState: 'MO',
        RegAddrZip: 65791,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '235 MARLER DR',
        RegAddrCity: 'WAPPAPELLO',
        RegAddrState: 'MO',
        RegAddrZip: 63966,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '1940 E STATE ROUTE K',
        RegAddrCity: 'WEST PLAINS',
        RegAddrState: 'MO',
        RegAddrZip: 65775,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '311 PINE ST',
        RegAddrCity: 'WEST PLAINS',
        RegAddrState: 'MO',
        RegAddrZip: 65775,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '7584 COUNTY ROAD 1930',
        RegAddrCity: 'WEST PLAINS',
        RegAddrState: 'MO',
        RegAddrZip: 65775,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '8503 COUNTY ROAD 6750',
        RegAddrCity: 'WEST PLAINS',
        RegAddrState: 'MO',
        RegAddrZip: 65775,
        CongressionalDistrict: 8,
      },
    ],
  },
  {
    name: 'MP-0',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/MP-0?ref=gh-pages',
    state: 'MP',
    state_and_number: 'MP-0',
    congressionalDistrict: 0,
    addressesInDistrict: [],
  },
  {
    name: 'MS-1',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/MS-1?ref=gh-pages',
    state: 'MS',
    state_and_number: 'MS-1',
    zip: 38326,
    congressionalDistrict: 1,
    addressesInDistrict: [
      {
        RegAddrLine1: '502 TREAS LAKE RD',
        RegAddrCity: 'ABERDEEN',
        RegAddrState: 'MS',
        RegAddrZip: 39730,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '208 DRAKE AVE',
        RegAddrCity: 'ABERDEEN',
        RegAddrState: 'MS',
        RegAddrZip: 39730,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '516 S MERIDIAN ST',
        RegAddrCity: 'ABERDEEN',
        RegAddrState: 'MS',
        RegAddrZip: 39730,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '30937 COONTAIL RD',
        RegAddrCity: 'ABERDEEN',
        RegAddrState: 'MS',
        RegAddrZip: 39730,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '60048 PHILLIPS SCHOOLHOUSE RD',
        RegAddrCity: 'AMORY',
        RegAddrState: 'MS',
        RegAddrZip: 38821,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '75 HUNT RD',
        RegAddrCity: 'ASHLAND',
        RegAddrState: 'MS',
        RegAddrZip: 38603,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1571 SNOW LAKE DR',
        RegAddrCity: 'ASHLAND',
        RegAddrState: 'MS',
        RegAddrZip: 38603,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '321 HOLLY DR',
        RegAddrCity: 'BALDWYN',
        RegAddrState: 'MS',
        RegAddrZip: 38824,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '124 DRIVE',
        RegAddrCity: 'BALDWYN',
        RegAddrState: 'MS',
        RegAddrZip: 38824,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1812 HIGHWAY 370',
        RegAddrCity: 'BALDWYN',
        RegAddrState: 'MS',
        RegAddrZip: 38824,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '76 WOOD DR',
        RegAddrCity: 'BELMONT',
        RegAddrState: 'MS',
        RegAddrZip: 38827,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1347 COUNTY ROAD 143',
        RegAddrCity: 'BLUE MOUNTAIN',
        RegAddrState: 'MS',
        RegAddrZip: 38610,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1151 COUNTY ROAD 197',
        RegAddrCity: 'BLUE SPRINGS',
        RegAddrState: 'MS',
        RegAddrZip: 38828,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '485 HIGHWAY 4 W',
        RegAddrCity: 'BOONEVILLE',
        RegAddrState: 'MS',
        RegAddrZip: 38829,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '275 COUNTY ROAD 5111',
        RegAddrCity: 'BOONEVILLE',
        RegAddrState: 'MS',
        RegAddrZip: 38829,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '100 WALDEN DR',
        RegAddrCity: 'BOONEVILLE',
        RegAddrState: 'MS',
        RegAddrZip: 38829,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '8 COUNTY ROAD 7130',
        RegAddrCity: 'BOONEVILLE',
        RegAddrState: 'MS',
        RegAddrZip: 38829,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '109 OAK DR',
        RegAddrCity: 'BOONEVILLE',
        RegAddrState: 'MS',
        RegAddrZip: 38829,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '503 S BRYANT ST',
        RegAddrCity: 'BOONEVILLE',
        RegAddrState: 'MS',
        RegAddrZip: 38829,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '53 COUNTY ROAD 221',
        RegAddrCity: 'BRUCE',
        RegAddrState: 'MS',
        RegAddrZip: 38915,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '856 CAYCE RD',
        RegAddrCity: 'BYHALIA',
        RegAddrState: 'MS',
        RegAddrZip: 38611,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '110 FLOWER CIR',
        RegAddrCity: 'COLDWATER',
        RegAddrState: 'MS',
        RegAddrZip: 38618,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '69 SANDRIDGE RD',
        RegAddrCity: 'COLDWATER',
        RegAddrState: 'MS',
        RegAddrZip: 38618,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '853 PARKWAY ST',
        RegAddrCity: 'COLDWATER',
        RegAddrState: 'MS',
        RegAddrZip: 38618,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '430 SMITH RD',
        RegAddrCity: 'COLDWATER',
        RegAddrState: 'MS',
        RegAddrZip: 38618,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '690 MOUNT ARIERY CHURCH RD',
        RegAddrCity: 'COLUMBUS',
        RegAddrState: 'MS',
        RegAddrZip: 39701,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '402 6TH ST S',
        RegAddrCity: 'COLUMBUS',
        RegAddrState: 'MS',
        RegAddrZip: 39701,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1015 4TH AVE S',
        RegAddrCity: 'COLUMBUS',
        RegAddrState: 'MS',
        RegAddrZip: 39701,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '605 20TH AVE N',
        RegAddrCity: 'COLUMBUS',
        RegAddrState: 'MS',
        RegAddrZip: 39701,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '824 3 ROAD AVE S',
        RegAddrCity: 'COLUMBUS',
        RegAddrState: 'MS',
        RegAddrZip: 39701,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1708 14TH AVE N',
        RegAddrCity: 'COLUMBUS',
        RegAddrState: 'MS',
        RegAddrZip: 39701,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '60 SUMMER LANE DR',
        RegAddrCity: 'COLUMBUS',
        RegAddrState: 'MS',
        RegAddrZip: 39702,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '188 MADERIA DR',
        RegAddrCity: 'COLUMBUS',
        RegAddrState: 'MS',
        RegAddrZip: 39702,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '15 MCKNIGHT DR',
        RegAddrCity: 'COLUMBUS',
        RegAddrState: 'MS',
        RegAddrZip: 39702,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '888 ALOMAR RD',
        RegAddrCity: 'COLUMBUS',
        RegAddrState: 'MS',
        RegAddrZip: 39705,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '144 LOWNDES AVE',
        RegAddrCity: 'COLUMBUS',
        RegAddrState: 'MS',
        RegAddrZip: 39705,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '61 CRESTWOOD DR',
        RegAddrCity: 'COLUMBUS',
        RegAddrState: 'MS',
        RegAddrZip: 39705,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '72 WOOD GLEN LN',
        RegAddrCity: 'COLUMBUS',
        RegAddrState: 'MS',
        RegAddrZip: 39705,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '234 SMITH RD S',
        RegAddrCity: 'COLUMBUS',
        RegAddrState: 'MS',
        RegAddrZip: 39705,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '147 COUNTY ROAD 520',
        RegAddrCity: 'CORINTH',
        RegAddrState: 'MS',
        RegAddrZip: 38834,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '46 COUNTY ROAD 161',
        RegAddrCity: 'CORINTH',
        RegAddrState: 'MS',
        RegAddrZip: 38834,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '333 COUNTY ROAD 632',
        RegAddrCity: 'CORINTH',
        RegAddrState: 'MS',
        RegAddrZip: 38834,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '36 COUNTY ROAD 109',
        RegAddrCity: 'CORINTH',
        RegAddrState: 'MS',
        RegAddrZip: 38834,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2113 N HICKORY RD',
        RegAddrCity: 'CORINTH',
        RegAddrState: 'MS',
        RegAddrZip: 38834,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '5420 N HARPER RD',
        RegAddrCity: 'CORINTH',
        RegAddrState: 'MS',
        RegAddrZip: 38834,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '111 CAROLINE ST',
        RegAddrCity: 'CORINTH',
        RegAddrState: 'MS',
        RegAddrZip: 38834,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1003 MITCHELL ST',
        RegAddrCity: 'CORINTH',
        RegAddrState: 'MS',
        RegAddrZip: 38834,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '979 JOHNSTON BND',
        RegAddrCity: 'ECRU',
        RegAddrState: 'MS',
        RegAddrZip: 38841,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '3832 MS HIGHWAY 182',
        RegAddrCity: 'EUPORA',
        RegAddrState: 'MS',
        RegAddrZip: 39744,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '30435 HIGHWAY 25 N',
        RegAddrCity: 'GOLDEN',
        RegAddrState: 'MS',
        RegAddrZip: 38847,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '266 COUNTY ROAD 355',
        RegAddrCity: 'GORE SPRINGS',
        RegAddrState: 'MS',
        RegAddrZip: 38929,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1337 DANIELS ST',
        RegAddrCity: 'GREENVILLE',
        RegAddrState: 'MS',
        RegAddrZip: 38701,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '40037 BARTAHATCHIE RD',
        RegAddrCity: 'HAMILTON',
        RegAddrState: 'MS',
        RegAddrZip: 39746,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1814 DANAS CV',
        RegAddrCity: 'HERNANDO',
        RegAddrState: 'MS',
        RegAddrZip: 38632,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2913 COLLEGE ST',
        RegAddrCity: 'HERNANDO',
        RegAddrState: 'MS',
        RegAddrZip: 38632,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '5252 WILLIAMS RD',
        RegAddrCity: 'HERNANDO',
        RegAddrState: 'MS',
        RegAddrZip: 38632,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '4502 HOLLY SPRINGS ROAD EXT',
        RegAddrCity: 'HERNANDO',
        RegAddrState: 'MS',
        RegAddrZip: 38632,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '4468 MCCRACKEN RD',
        RegAddrCity: 'HERNANDO',
        RegAddrState: 'MS',
        RegAddrZip: 38632,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '432 BEACON HILL DR',
        RegAddrCity: 'HERNANDO',
        RegAddrState: 'MS',
        RegAddrZip: 38632,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '150 RUST AVE',
        RegAddrCity: 'HOLLY SPRINGS',
        RegAddrState: 'MS',
        RegAddrZip: 38635,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '7412 BRIARWOOD CV',
        RegAddrCity: 'HORN LAKE',
        RegAddrState: 'MS',
        RegAddrZip: 38637,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1000 SUTTON PL',
        RegAddrCity: 'HORN LAKE',
        RegAddrState: 'MS',
        RegAddrZip: 38637,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '5562 COREY CV',
        RegAddrCity: 'HORN LAKE',
        RegAddrState: 'MS',
        RegAddrZip: 38637,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '3045 CAITLYNN CIR N',
        RegAddrCity: 'HORN LAKE',
        RegAddrState: 'MS',
        RegAddrZip: 38637,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1757 HIGHWAY 32 W',
        RegAddrCity: 'HOULKA',
        RegAddrState: 'MS',
        RegAddrZip: 38850,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '201 VIOLA ST',
        RegAddrCity: 'HOULKA',
        RegAddrState: 'MS',
        RegAddrZip: 38850,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '335 COUNTY ROAD 166',
        RegAddrCity: 'HOUSTON',
        RegAddrState: 'MS',
        RegAddrZip: 38851,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '190 COUNTY ROAD 025',
        RegAddrCity: 'HOUSTON',
        RegAddrState: 'MS',
        RegAddrZip: 38851,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '165 COUNTY ROAD 173',
        RegAddrCity: 'IUKA',
        RegAddrState: 'MS',
        RegAddrZip: 38852,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '371 COUNTY ROAD 956',
        RegAddrCity: 'IUKA',
        RegAddrState: 'MS',
        RegAddrZip: 38852,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '783 BATTS RD',
        RegAddrCity: 'LAMAR',
        RegAddrState: 'MS',
        RegAddrZip: 38642,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '330 DAVIE RD',
        RegAddrCity: 'LOUISVILLE',
        RegAddrState: 'MS',
        RegAddrZip: 39339,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '565 MAX FULCHER RD',
        RegAddrCity: 'LOUISVILLE',
        RegAddrState: 'MS',
        RegAddrZip: 39339,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1548 MCCULLOUGH RD',
        RegAddrCity: 'LOUISVILLE',
        RegAddrState: 'MS',
        RegAddrZip: 39339,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '356 JIMBO RD',
        RegAddrCity: 'LOUISVILLE',
        RegAddrState: 'MS',
        RegAddrZip: 39339,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1920 GILLESPIE RD',
        RegAddrCity: 'MACON',
        RegAddrState: 'MS',
        RegAddrZip: 39341,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '29827 HIGHWAY 14 E',
        RegAddrCity: 'MACON',
        RegAddrState: 'MS',
        RegAddrZip: 39341,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1972 FAWN GROVE RD',
        RegAddrCity: 'MANTACHIE',
        RegAddrState: 'MS',
        RegAddrZip: 38855,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1866 DONIVAN RD',
        RegAddrCity: 'MARIETTA',
        RegAddrState: 'MS',
        RegAddrZip: 38856,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '46 WELCH ST',
        RegAddrCity: 'MATHISTON',
        RegAddrState: 'MS',
        RegAddrZip: 39752,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2065 FELLOWSHIP RD',
        RegAddrCity: 'MATHISTON',
        RegAddrState: 'MS',
        RegAddrZip: 39752,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '7258 CLARKSON RD',
        RegAddrCity: 'MATHISTON',
        RegAddrState: 'MS',
        RegAddrZip: 39752,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1237 HARRIS CHAPEL RD',
        RegAddrCity: 'MICHIGAN CITY',
        RegAddrState: 'MS',
        RegAddrZip: 38647,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: 'PO BOX',
        RegAddrCity: 'MOOREVILLE',
        RegAddrState: 'MS',
        RegAddrZip: 38857,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2715 BIRCH ISLAND DR',
        RegAddrCity: 'NESBIT',
        RegAddrState: 'MS',
        RegAddrZip: 38651,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '30151 METTS RD',
        RegAddrCity: 'NETTLETON',
        RegAddrState: 'MS',
        RegAddrZip: 38858,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '32950 HIGHWAY 45 N',
        RegAddrCity: 'NETTLETON',
        RegAddrState: 'MS',
        RegAddrZip: 38858,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1001 STATE HIGHWAY 15 S',
        RegAddrCity: 'NEW ALBANY',
        RegAddrState: 'MS',
        RegAddrZip: 38652,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1206 COUNTY ROAD 87',
        RegAddrCity: 'NEW ALBANY',
        RegAddrState: 'MS',
        RegAddrZip: 38652,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1444 COUNTY ROAD 107',
        RegAddrCity: 'NEW ALBANY',
        RegAddrState: 'MS',
        RegAddrZip: 38652,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '7594 STATELINE RD',
        RegAddrCity: 'OLIVE BRANCH',
        RegAddrState: 'MS',
        RegAddrZip: 38654,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '4551 STONE PARK BLVD',
        RegAddrCity: 'OLIVE BRANCH',
        RegAddrState: 'MS',
        RegAddrZip: 38654,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '14245 MILLER STATION LN',
        RegAddrCity: 'OLIVE BRANCH',
        RegAddrState: 'MS',
        RegAddrZip: 38654,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '6901 DAKOTA CIR N',
        RegAddrCity: 'OLIVE BRANCH',
        RegAddrState: 'MS',
        RegAddrZip: 38654,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '4597 STONE GROVE DR',
        RegAddrCity: 'OLIVE BRANCH',
        RegAddrState: 'MS',
        RegAddrZip: 38654,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '13230 COLDWATER DR',
        RegAddrCity: 'OLIVE BRANCH',
        RegAddrState: 'MS',
        RegAddrZip: 38654,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '7688 ALEXANDERS CROSSING DR',
        RegAddrCity: 'OLIVE BRANCH',
        RegAddrState: 'MS',
        RegAddrZip: 38654,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '3146 SUMMERHILL DR',
        RegAddrCity: 'OLIVE BRANCH',
        RegAddrState: 'MS',
        RegAddrZip: 38654,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '4175 PAYNE PARKWAY ST',
        RegAddrCity: 'OLIVE BRANCH',
        RegAddrState: 'MS',
        RegAddrZip: 38654,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '10404 KRISTEN DR S',
        RegAddrCity: 'OLIVE BRANCH',
        RegAddrState: 'MS',
        RegAddrZip: 38654,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '7400 MAYWOOD DR',
        RegAddrCity: 'OLIVE BRANCH',
        RegAddrState: 'MS',
        RegAddrZip: 38654,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '7333 EASTOVER BLVD',
        RegAddrCity: 'OLIVE BRANCH',
        RegAddrState: 'MS',
        RegAddrZip: 38654,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '201 CREEKWOOD CV',
        RegAddrCity: 'OXFORD',
        RegAddrState: 'MS',
        RegAddrZip: 38655,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '623 SWEETGUM LN',
        RegAddrCity: 'OXFORD',
        RegAddrState: 'MS',
        RegAddrZip: 38655,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '103 COUNTY ROAD 4078',
        RegAddrCity: 'OXFORD',
        RegAddrState: 'MS',
        RegAddrZip: 38655,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '501 COUNTY ROAD 101',
        RegAddrCity: 'OXFORD',
        RegAddrState: 'MS',
        RegAddrZip: 38655,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '700 LONG MEADOW DR',
        RegAddrCity: 'OXFORD',
        RegAddrState: 'MS',
        RegAddrZip: 38655,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '109 OLD PLANTERS RD',
        RegAddrCity: 'PLANTERSVILLE',
        RegAddrState: 'MS',
        RegAddrZip: 38862,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '118 LOCHINVAR DR',
        RegAddrCity: 'PONTOTOC',
        RegAddrState: 'MS',
        RegAddrZip: 38863,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: 'DATAMIGRATION',
        RegAddrCity: 'RED BANKS',
        RegAddrState: 'MS',
        RegAddrZip: 38661,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1289 TASKA RD',
        RegAddrCity: 'RED BANKS',
        RegAddrState: 'MS',
        RegAddrZip: 38661,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2640 COUNTY ROAD 540',
        RegAddrCity: 'RIPLEY',
        RegAddrState: 'MS',
        RegAddrZip: 38663,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '105 PEACOCK HOLW',
        RegAddrCity: 'RIPLEY',
        RegAddrState: 'MS',
        RegAddrZip: 38663,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '116 GARY ST',
        RegAddrCity: 'RIPLEY',
        RegAddrState: 'MS',
        RegAddrZip: 38663,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1241 COUNTY ROAD 515',
        RegAddrCity: 'RIPLEY',
        RegAddrState: 'MS',
        RegAddrZip: 38663,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '114 FAY LN',
        RegAddrCity: 'SALTILLO',
        RegAddrState: 'MS',
        RegAddrZip: 38866,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '174 BOLIVAR TRL',
        RegAddrCity: 'SALTILLO',
        RegAddrState: 'MS',
        RegAddrZip: 38866,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '154 KOPF RD',
        RegAddrCity: 'SENATOBIA',
        RegAddrState: 'MS',
        RegAddrZip: 38668,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1911 SALEM RD',
        RegAddrCity: 'SENATOBIA',
        RegAddrState: 'MS',
        RegAddrZip: 38668,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '275 ROAD 530',
        RegAddrCity: 'SHANNON',
        RegAddrState: 'MS',
        RegAddrZip: 38868,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '340 ROAD 805',
        RegAddrCity: 'SHANNON',
        RegAddrState: 'MS',
        RegAddrZip: 38868,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '494 HIGHWAY 178',
        RegAddrCity: 'SHERMAN',
        RegAddrState: 'MS',
        RegAddrZip: 38869,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: 'DATAMIGRATION',
        RegAddrCity: 'SMITHVILLE',
        RegAddrState: 'MS',
        RegAddrZip: 38870,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1803 GILLESS PT S',
        RegAddrCity: 'SOUTHAVEN',
        RegAddrState: 'MS',
        RegAddrZip: 38671,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '7686 CHARLESTON DR',
        RegAddrCity: 'SOUTHAVEN',
        RegAddrState: 'MS',
        RegAddrZip: 38671,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1914 BRENTWOOD TRCE',
        RegAddrCity: 'SOUTHAVEN',
        RegAddrState: 'MS',
        RegAddrZip: 38671,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1572 BRENTWOOD TRCE',
        RegAddrCity: 'SOUTHAVEN',
        RegAddrState: 'MS',
        RegAddrZip: 38671,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '3228 CHATEAU CIR S',
        RegAddrCity: 'SOUTHAVEN',
        RegAddrState: 'MS',
        RegAddrZip: 38672,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '3647 ESREY RD',
        RegAddrCity: 'SOUTHAVEN',
        RegAddrState: 'MS',
        RegAddrZip: 38672,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '665 STOCKARD RD',
        RegAddrCity: 'THAXTON',
        RegAddrState: 'MS',
        RegAddrZip: 38871,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '6251 COUNTY ROAD 200',
        RegAddrCity: 'TIPLERSVILLE',
        RegAddrState: 'MS',
        RegAddrZip: 38674,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '915 GARFIELD ST',
        RegAddrCity: 'TUPELO',
        RegAddrState: 'MS',
        RegAddrZip: 38801,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '213 N FOSTER DR',
        RegAddrCity: 'TUPELO',
        RegAddrState: 'MS',
        RegAddrZip: 38801,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1830 LAKEFIELD DR',
        RegAddrCity: 'TUPELO',
        RegAddrState: 'MS',
        RegAddrZip: 38801,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '109 QUALITY LN',
        RegAddrCity: 'TUPELO',
        RegAddrState: 'MS',
        RegAddrZip: 38801,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1018 COTTONWOOD LN',
        RegAddrCity: 'TUPELO',
        RegAddrState: 'MS',
        RegAddrZip: 38801,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '5000 BALLARDSVILLE RD',
        RegAddrCity: 'TUPELO',
        RegAddrState: 'MS',
        RegAddrZip: 38804,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '536 W JEFFERSON ST',
        RegAddrCity: 'TUPELO',
        RegAddrState: 'MS',
        RegAddrZip: 38804,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1523 DEBRO ST',
        RegAddrCity: 'TUPELO',
        RegAddrState: 'MS',
        RegAddrZip: 38804,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1101 STOCKARD HALL',
        RegAddrCity: 'UNIVERSITY',
        RegAddrState: 'MS',
        RegAddrZip: 38677,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '118 NABORS CIR',
        RegAddrCity: 'VARDAMAN',
        RegAddrState: 'MS',
        RegAddrZip: 38878,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '6438 ANNA MAY DR',
        RegAddrCity: 'WALLS',
        RegAddrState: 'MS',
        RegAddrZip: 38680,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '7480 HIGHWAY 161',
        RegAddrCity: 'WALLS',
        RegAddrState: 'MS',
        RegAddrZip: 38680,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1449 LONE OAK DR',
        RegAddrCity: 'WEST POINT',
        RegAddrState: 'MS',
        RegAddrZip: 39773,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '804 E JORDAN AVENUE EXT',
        RegAddrCity: 'WEST POINT',
        RegAddrState: 'MS',
        RegAddrZip: 39773,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '92 MEADOWBROOK CIR',
        RegAddrCity: 'WEST POINT',
        RegAddrState: 'MS',
        RegAddrZip: 39773,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: 'PO BOX 63',
        RegAddrCity: 'WOODLAND',
        RegAddrState: 'MS',
        RegAddrZip: 39776,
        CongressionalDistrict: 1,
      },
    ],
  },
  {
    name: 'MS-2',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/MS-2?ref=gh-pages',
    state: 'MS',
    state_and_number: 'MS-2',
    zip: 38614,
    congressionalDistrict: 2,
    addressesInDistrict: [
      {
        RegAddrLine1: '114 SECOND ST',
        RegAddrCity: 'ANGUILLA',
        RegAddrState: 'MS',
        RegAddrZip: 38721,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1526 HERMITAGE RD',
        RegAddrCity: 'ARCOLA',
        RegAddrState: 'MS',
        RegAddrZip: 38722,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '220 ROBIN LN',
        RegAddrCity: 'BATESVILLE',
        RegAddrState: 'MS',
        RegAddrZip: 38606,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '395 JOHN BRANCH RD',
        RegAddrCity: 'BATESVILLE',
        RegAddrState: 'MS',
        RegAddrZip: 38606,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '226 DOGWOOD LN',
        RegAddrCity: 'BATESVILLE',
        RegAddrState: 'MS',
        RegAddrZip: 38606,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '407 LEE ST',
        RegAddrCity: 'BELZONI',
        RegAddrState: 'MS',
        RegAddrZip: 39038,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '5747 GOODEN LAKE RD',
        RegAddrCity: 'BELZONI',
        RegAddrState: 'MS',
        RegAddrZip: 39038,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '20 JACKSON DR',
        RegAddrCity: 'BENTONIA',
        RegAddrState: 'MS',
        RegAddrZip: 39040,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '134 CROSBY RD',
        RegAddrCity: 'BOYLE',
        RegAddrState: 'MS',
        RegAddrZip: 38730,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1808 CHRISTINE DR',
        RegAddrCity: 'BYRAM',
        RegAddrState: 'MS',
        RegAddrZip: 39272,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '15 BELLWOOD GRV',
        RegAddrCity: 'BYRAM',
        RegAddrState: 'MS',
        RegAddrZip: 39272,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '126 S MADISON ST',
        RegAddrCity: 'CANTON',
        RegAddrState: 'MS',
        RegAddrZip: 39046,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '111 KIT CARSON RD',
        RegAddrCity: 'CANTON',
        RegAddrState: 'MS',
        RegAddrZip: 39046,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '791 COUNTY ROAD 120',
        RegAddrCity: 'CARROLLTON',
        RegAddrState: 'MS',
        RegAddrZip: 38917,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '610 MAPLEWOOD RD',
        RegAddrCity: 'CARTHAGE',
        RegAddrState: 'MS',
        RegAddrZip: 39051,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '65 LINDY LN',
        RegAddrCity: 'CARTHAGE',
        RegAddrState: 'MS',
        RegAddrZip: 39051,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '7624 EBENEZER RD',
        RegAddrCity: 'CARTHAGE',
        RegAddrState: 'MS',
        RegAddrZip: 39051,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1133 MORNING STAR DR',
        RegAddrCity: 'CHARLESTON',
        RegAddrState: 'MS',
        RegAddrZip: 38921,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '640 SCHOOL ST',
        RegAddrCity: 'CLARKSDALE',
        RegAddrState: 'MS',
        RegAddrZip: 38614,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1322 W 2ND ST',
        RegAddrCity: 'CLARKSDALE',
        RegAddrState: 'MS',
        RegAddrZip: 38614,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '621 LINCOLN PL',
        RegAddrCity: 'CLARKSDALE',
        RegAddrState: 'MS',
        RegAddrZip: 38614,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1835 RIVERSIDE AVE',
        RegAddrCity: 'CLARKSDALE',
        RegAddrState: 'MS',
        RegAddrZip: 38614,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '10945 NEW AFRICA RD',
        RegAddrCity: 'CLARKSDALE',
        RegAddrState: 'MS',
        RegAddrZip: 38614,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1021 NEWSOME AVE',
        RegAddrCity: 'CLEVELAND',
        RegAddrState: 'MS',
        RegAddrZip: 38732,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '321 S SHARPE AVE',
        RegAddrCity: 'CLEVELAND',
        RegAddrState: 'MS',
        RegAddrZip: 38732,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '717 ALOE AVE',
        RegAddrCity: 'CLEVELAND',
        RegAddrState: 'MS',
        RegAddrZip: 38732,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '108 MARLER ST',
        RegAddrCity: 'CLEVELAND',
        RegAddrState: 'MS',
        RegAddrZip: 38732,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1109 DEERING ST',
        RegAddrCity: 'CLEVELAND',
        RegAddrState: 'MS',
        RegAddrZip: 38732,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '607 LEE ST',
        RegAddrCity: 'CLEVELAND',
        RegAddrState: 'MS',
        RegAddrZip: 38732,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '54 CASCADES CIR W',
        RegAddrCity: 'CLINTON',
        RegAddrState: 'MS',
        RegAddrZip: 39056,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '122 BENTWOOD DR',
        RegAddrCity: 'CLINTON',
        RegAddrState: 'MS',
        RegAddrZip: 39056,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '136 CAMBRIDGE CV',
        RegAddrCity: 'CLINTON',
        RegAddrState: 'MS',
        RegAddrZip: 39056,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '194 MAGNOLIA RD',
        RegAddrCity: 'CLINTON',
        RegAddrState: 'MS',
        RegAddrZip: 39056,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1501 HIGHWAY 80 E',
        RegAddrCity: 'CLINTON',
        RegAddrState: 'MS',
        RegAddrZip: 39056,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1700 CLINTON RAYMOND RD',
        RegAddrCity: 'CLINTON',
        RegAddrState: 'MS',
        RegAddrZip: 39056,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '108 PATTON DR',
        RegAddrCity: 'CLINTON',
        RegAddrState: 'MS',
        RegAddrZip: 39056,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1623 MELROSE PL',
        RegAddrCity: 'CLINTON',
        RegAddrState: 'MS',
        RegAddrZip: 39056,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '714 CARTER RD',
        RegAddrCity: 'COFFEEVILLE',
        RegAddrState: 'MS',
        RegAddrZip: 38922,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '307 CHURCH ST',
        RegAddrCity: 'COMO',
        RegAddrState: 'MS',
        RegAddrZip: 38619,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1431 SMART RD',
        RegAddrCity: 'COMO',
        RegAddrState: 'MS',
        RegAddrZip: 38619,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '12644 OLD PANOLA RD',
        RegAddrCity: 'COMO',
        RegAddrState: 'MS',
        RegAddrZip: 38619,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1630 BELL RD',
        RegAddrCity: 'COURTLAND',
        RegAddrState: 'MS',
        RegAddrZip: 38620,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '808 ASKEW RD',
        RegAddrCity: 'CRENSHAW',
        RegAddrState: 'MS',
        RegAddrZip: 38621,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '228 BANGER RD',
        RegAddrCity: 'CRENSHAW',
        RegAddrState: 'MS',
        RegAddrZip: 38621,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: 'DATAMIGRATION',
        RegAddrCity: 'DARLING',
        RegAddrState: 'MS',
        RegAddrZip: 38623,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '35 POWELL AVE',
        RegAddrCity: 'DREW',
        RegAddrState: 'MS',
        RegAddrZip: 38737,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '150 JEFFERSON DAVIS ST',
        RegAddrCity: 'DUCK HILL',
        RegAddrState: 'MS',
        RegAddrZip: 38925,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1066 GILMORE DR',
        RegAddrCity: 'EDWARDS',
        RegAddrState: 'MS',
        RegAddrZip: 39066,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: 'PO BOX 1082',
        RegAddrCity: 'FAYETTE',
        RegAddrState: 'MS',
        RegAddrZip: 39069,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2313 CORBAN RD',
        RegAddrCity: 'FAYETTE',
        RegAddrState: 'MS',
        RegAddrZip: 39069,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2446 ATTALA ROAD 2134',
        RegAddrCity: 'FRENCH CAMP',
        RegAddrState: 'MS',
        RegAddrZip: 39745,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1096 SANDIFER LN',
        RegAddrCity: 'GEORGETOWN',
        RegAddrState: 'MS',
        RegAddrZip: 39078,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '553 BAILEY RD',
        RegAddrCity: 'GLEN ALLAN',
        RegAddrState: 'MS',
        RegAddrZip: 38744,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '112 HOWARD QTR RD',
        RegAddrCity: 'GOODMAN',
        RegAddrState: 'MS',
        RegAddrZip: 39079,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '322 3RD ST',
        RegAddrCity: 'GREENVILLE',
        RegAddrState: 'MS',
        RegAddrZip: 38701,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '343 W REED RD',
        RegAddrCity: 'GREENVILLE',
        RegAddrState: 'MS',
        RegAddrZip: 38701,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '357 LAKE ST',
        RegAddrCity: 'GREENVILLE',
        RegAddrState: 'MS',
        RegAddrZip: 38701,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '512 NELSON ST',
        RegAddrCity: 'GREENVILLE',
        RegAddrState: 'MS',
        RegAddrZip: 38701,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '3506 S WOODLAWN DR',
        RegAddrCity: 'GREENVILLE',
        RegAddrState: 'MS',
        RegAddrZip: 38703,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '216 CANARY CV',
        RegAddrCity: 'GREENWOOD',
        RegAddrState: 'MS',
        RegAddrZip: 38930,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '712 AVENUE J',
        RegAddrCity: 'GREENWOOD',
        RegAddrState: 'MS',
        RegAddrZip: 38930,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '314 QUAIL TRL',
        RegAddrCity: 'GREENWOOD',
        RegAddrState: 'MS',
        RegAddrZip: 38930,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '203 W BARTON AVE',
        RegAddrCity: 'GREENWOOD',
        RegAddrState: 'MS',
        RegAddrZip: 38930,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '314 QUAIL TRL',
        RegAddrCity: 'GREENWOOD',
        RegAddrState: 'MS',
        RegAddrZip: 38930,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '407 LINCOLN AVE',
        RegAddrCity: 'GREENWOOD',
        RegAddrState: 'MS',
        RegAddrZip: 38930,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '213 E CLEVELAND AVE',
        RegAddrCity: 'GREENWOOD',
        RegAddrState: 'MS',
        RegAddrZip: 38930,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '427 PROVIDENCE RD',
        RegAddrCity: 'GRENADA',
        RegAddrState: 'MS',
        RegAddrZip: 38901,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '109 GRAY DR',
        RegAddrCity: 'HAZLEHURST',
        RegAddrState: 'MS',
        RegAddrZip: 39083,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1014 HACKETT LN',
        RegAddrCity: 'HAZLEHURST',
        RegAddrState: 'MS',
        RegAddrZip: 39083,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1023 WHITEHURST RD',
        RegAddrCity: 'HAZLEHURST',
        RegAddrState: 'MS',
        RegAddrZip: 39083,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2072 SPARTA RD',
        RegAddrCity: 'HOLCOMB',
        RegAddrState: 'MS',
        RegAddrZip: 38940,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1177 BILLUPS RD',
        RegAddrCity: 'HOLCOMB',
        RegAddrState: 'MS',
        RegAddrZip: 38940,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '205 MERCER AVE',
        RegAddrCity: 'HOLLANDALE',
        RegAddrState: 'MS',
        RegAddrZip: 38748,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1009 W T R CLAY DR',
        RegAddrCity: 'HOLLANDALE',
        RegAddrState: 'MS',
        RegAddrZip: 38748,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '717 S ELMWOOD CIR',
        RegAddrCity: 'INDIANOLA',
        RegAddrState: 'MS',
        RegAddrZip: 38751,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '210 S DAVIS CIR',
        RegAddrCity: 'INDIANOLA',
        RegAddrState: 'MS',
        RegAddrZip: 38751,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '224 MEDGAR EVERS BLVD',
        RegAddrCity: 'ITTA BENA',
        RegAddrState: 'MS',
        RegAddrZip: 38941,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '301 SUNFLOWER RD',
        RegAddrCity: 'ITTA BENA',
        RegAddrState: 'MS',
        RegAddrZip: 38941,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '635 W ASH ST',
        RegAddrCity: 'JACKSON',
        RegAddrState: 'MS',
        RegAddrZip: 39203,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '617 ERIE ST',
        RegAddrCity: 'JACKSON',
        RegAddrState: 'MS',
        RegAddrZip: 39203,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1465 DIANNE DR',
        RegAddrCity: 'JACKSON',
        RegAddrState: 'MS',
        RegAddrZip: 39204,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1564 WOOD GLEN DR',
        RegAddrCity: 'JACKSON',
        RegAddrState: 'MS',
        RegAddrZip: 39204,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1915 FLOWERS DR',
        RegAddrCity: 'JACKSON',
        RegAddrState: 'MS',
        RegAddrZip: 39204,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '4179 PLAZA ST',
        RegAddrCity: 'JACKSON',
        RegAddrState: 'MS',
        RegAddrZip: 39206,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '5390 CEDAR PARK DR',
        RegAddrCity: 'JACKSON',
        RegAddrState: 'MS',
        RegAddrZip: 39206,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '5628 PLEMON ST',
        RegAddrCity: 'JACKSON',
        RegAddrState: 'MS',
        RegAddrZip: 39206,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '206 COLEBROOK AVE',
        RegAddrCity: 'JACKSON',
        RegAddrState: 'MS',
        RegAddrZip: 39209,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2828 GENTILLY DR',
        RegAddrCity: 'JACKSON',
        RegAddrState: 'MS',
        RegAddrZip: 39209,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '206 COLEBROOK AVE',
        RegAddrCity: 'JACKSON',
        RegAddrState: 'MS',
        RegAddrZip: 39209,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '111 ELCREST ST',
        RegAddrCity: 'JACKSON',
        RegAddrState: 'MS',
        RegAddrZip: 39209,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '163 WITCHITA DR',
        RegAddrCity: 'JACKSON',
        RegAddrState: 'MS',
        RegAddrZip: 39209,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '203 ROWLAND AVE',
        RegAddrCity: 'JACKSON',
        RegAddrState: 'MS',
        RegAddrZip: 39209,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1428 PLUM ST',
        RegAddrCity: 'JACKSON',
        RegAddrState: 'MS',
        RegAddrZip: 39209,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '4221 OAK LAKE DR',
        RegAddrCity: 'JACKSON',
        RegAddrState: 'MS',
        RegAddrZip: 39212,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2713 REVERE ST',
        RegAddrCity: 'JACKSON',
        RegAddrState: 'MS',
        RegAddrZip: 39212,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '4961 OAK CREEK DR',
        RegAddrCity: 'JACKSON',
        RegAddrState: 'MS',
        RegAddrZip: 39212,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '120 WILDWOOD CIR',
        RegAddrCity: 'JACKSON',
        RegAddrState: 'MS',
        RegAddrZip: 39212,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2808 GREENWOOD AVE',
        RegAddrCity: 'JACKSON',
        RegAddrState: 'MS',
        RegAddrZip: 39212,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '3821 SKYLINE DR',
        RegAddrCity: 'JACKSON',
        RegAddrState: 'MS',
        RegAddrZip: 39213,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '3551 EDWARDS AVE',
        RegAddrCity: 'JACKSON',
        RegAddrState: 'MS',
        RegAddrZip: 39213,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '105 HERITAGE DR',
        RegAddrCity: 'KOSCIUSKO',
        RegAddrState: 'MS',
        RegAddrZip: 39090,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '329 HAMMOND CIR',
        RegAddrCity: 'KOSCIUSKO',
        RegAddrState: 'MS',
        RegAddrZip: 39090,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '500 WEST ST',
        RegAddrCity: 'KOSCIUSKO',
        RegAddrState: 'MS',
        RegAddrZip: 39090,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '13273 HIGHWAY 12 W',
        RegAddrCity: 'KOSCIUSKO',
        RegAddrState: 'MS',
        RegAddrZip: 39090,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1125 N NATCHEZ ST',
        RegAddrCity: 'KOSCIUSKO',
        RegAddrState: 'MS',
        RegAddrZip: 39090,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '103 SINCLAIR AVE',
        RegAddrCity: 'LELAND',
        RegAddrState: 'MS',
        RegAddrZip: 38756,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '405 DEAN ST',
        RegAddrCity: 'LELAND',
        RegAddrState: 'MS',
        RegAddrZip: 38756,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '4017 BLACK BAYOU RD',
        RegAddrCity: 'LELAND',
        RegAddrState: 'MS',
        RegAddrZip: 38756,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '388 EMORY RD',
        RegAddrCity: 'LEXINGTON',
        RegAddrState: 'MS',
        RegAddrZip: 39095,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '12535 HIGHWAY 17',
        RegAddrCity: 'LEXINGTON',
        RegAddrState: 'MS',
        RegAddrZip: 39095,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '413 FIFTH ST',
        RegAddrCity: 'LEXINGTON',
        RegAddrState: 'MS',
        RegAddrZip: 39095,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '6208 DENNIS CROSS RD',
        RegAddrCity: 'LORMAN',
        RegAddrState: 'MS',
        RegAddrZip: 39096,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '134 SHARP ST',
        RegAddrCity: 'LULA',
        RegAddrState: 'MS',
        RegAddrZip: 38644,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '201 3RD ST',
        RegAddrCity: 'MARKS',
        RegAddrState: 'MS',
        RegAddrZip: 38646,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '501 HOLLY ST',
        RegAddrCity: 'MARKS',
        RegAddrState: 'MS',
        RegAddrZip: 38646,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2687 WINTERS ST',
        RegAddrCity: 'MCCOOL',
        RegAddrState: 'MS',
        RegAddrZip: 39108,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '566 COUNTY ROAD 204',
        RegAddrCity: 'OAKLAND',
        RegAddrState: 'MS',
        RegAddrZip: 38948,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '40 PARCHMAN RD',
        RegAddrCity: 'PARCHMAN',
        RegAddrState: 'MS',
        RegAddrZip: 38738,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2057 LACASHE SUB 2',
        RegAddrCity: 'PATTISON',
        RegAddrState: 'MS',
        RegAddrZip: 39144,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '3910 POPE WATER VALLEY RD',
        RegAddrCity: 'POPE',
        RegAddrState: 'MS',
        RegAddrZip: 38658,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '505 CHINA RD',
        RegAddrCity: 'POPE',
        RegAddrState: 'MS',
        RegAddrZip: 38658,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '308 RODNEY RD',
        RegAddrCity: 'PORT GIBSON',
        RegAddrState: 'MS',
        RegAddrZip: 39150,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1008 LAKE DR',
        RegAddrCity: 'RAYMOND',
        RegAddrState: 'MS',
        RegAddrZip: 39154,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '100 RANDOM OAK CV',
        RegAddrCity: 'RAYMOND',
        RegAddrState: 'MS',
        RegAddrZip: 39154,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '21810 HIGHWAY 18',
        RegAddrCity: 'RAYMOND',
        RegAddrState: 'MS',
        RegAddrZip: 39154,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '121 WEST AVE',
        RegAddrCity: 'ROLLING FORK',
        RegAddrState: 'MS',
        RegAddrZip: 39159,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '512 ELM ST',
        RegAddrCity: 'RULEVILLE',
        RegAddrState: 'MS',
        RegAddrZip: 38771,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '823 BISHOP SMITH CARTER AVE',
        RegAddrCity: 'RULEVILLE',
        RegAddrState: 'MS',
        RegAddrZip: 38771,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '511 MYLES FOSTER AVE',
        RegAddrCity: 'RULEVILLE',
        RegAddrState: 'MS',
        RegAddrZip: 38771,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: 'RR 2',
        RegAddrCity: 'SALLIS',
        RegAddrState: 'MS',
        RegAddrZip: 39160,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '918 HIGHWAY 442',
        RegAddrCity: 'SHAW',
        RegAddrState: 'MS',
        RegAddrZip: 38773,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '548 DAWSON RD',
        RegAddrCity: 'TCHULA',
        RegAddrState: 'MS',
        RegAddrZip: 39169,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '642 N UTICA ST',
        RegAddrCity: 'TERRY',
        RegAddrState: 'MS',
        RegAddrZip: 39170,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2584 OLD HIGHWAY 61 N',
        RegAddrCity: 'TUNICA',
        RegAddrState: 'MS',
        RegAddrZip: 38676,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '4145 CURTIS LUSTER RD',
        RegAddrCity: 'UTICA',
        RegAddrState: 'MS',
        RegAddrZip: 39175,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '3746 MORRISON RD',
        RegAddrCity: 'UTICA',
        RegAddrState: 'MS',
        RegAddrZip: 39175,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '795 COUNTY ROAD 3',
        RegAddrCity: 'VAIDEN',
        RegAddrState: 'MS',
        RegAddrZip: 39176,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '11491 VAUGHAN RD',
        RegAddrCity: 'VAUGHAN',
        RegAddrState: 'MS',
        RegAddrZip: 39179,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1315 BENNETT RD',
        RegAddrCity: 'VAUGHAN',
        RegAddrState: 'MS',
        RegAddrZip: 39179,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1159 BUTLER RD',
        RegAddrCity: 'VAUGHAN',
        RegAddrState: 'MS',
        RegAddrZip: 39179,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '12 LAKE BLVD',
        RegAddrCity: 'VICKSBURG',
        RegAddrState: 'MS',
        RegAddrZip: 39180,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '820 POLK ST',
        RegAddrCity: 'VICKSBURG',
        RegAddrState: 'MS',
        RegAddrZip: 39180,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '212 GRINDERS PL',
        RegAddrCity: 'VICKSBURG',
        RegAddrState: 'MS',
        RegAddrZip: 39180,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '117 WINDY LAKE CIR',
        RegAddrCity: 'VICKSBURG',
        RegAddrState: 'MS',
        RegAddrZip: 39183,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '229 HILDEGARDE TER',
        RegAddrCity: 'VICKSBURG',
        RegAddrState: 'MS',
        RegAddrZip: 39183,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1197 SHERMAN AVE',
        RegAddrCity: 'VICKSBURG',
        RegAddrState: 'MS',
        RegAddrZip: 39183,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '300 UNION AVE',
        RegAddrCity: 'VICKSBURG',
        RegAddrState: 'MS',
        RegAddrZip: 39183,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '191 COUNTY ROAD 102',
        RegAddrCity: 'WATER VALLEY',
        RegAddrState: 'MS',
        RegAddrZip: 38965,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '715 LAKE SHORE DR',
        RegAddrCity: 'YAZOO CITY',
        RegAddrState: 'MS',
        RegAddrZip: 39194,
        CongressionalDistrict: 2,
      },
    ],
  },
  {
    name: 'MS-3',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/MS-3?ref=gh-pages',
    state: 'MS',
    state_and_number: 'MS-3',
    zip: 39042,
    congressionalDistrict: 3,
    addressesInDistrict: [
      {
        RegAddrLine1: 'PO BOX 171',
        RegAddrCity: 'BASSFIELD',
        RegAddrState: 'MS',
        RegAddrZip: 39421,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '113 EMERALD ST',
        RegAddrCity: 'BRANDON',
        RegAddrState: 'MS',
        RegAddrZip: 39042,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '101 BELLA VISTA DR',
        RegAddrCity: 'BRANDON',
        RegAddrState: 'MS',
        RegAddrZip: 39042,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '116 FAIRDALE PL',
        RegAddrCity: 'BRANDON',
        RegAddrState: 'MS',
        RegAddrZip: 39042,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '255 PENNY LN',
        RegAddrCity: 'BRANDON',
        RegAddrState: 'MS',
        RegAddrZip: 39042,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '313 BUSICK WELL RD',
        RegAddrCity: 'BRANDON',
        RegAddrState: 'MS',
        RegAddrZip: 39042,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '347 TOWNE ST',
        RegAddrCity: 'BRANDON',
        RegAddrState: 'MS',
        RegAddrZip: 39042,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '70 TERRAPIN DR',
        RegAddrCity: 'BRANDON',
        RegAddrState: 'MS',
        RegAddrZip: 39042,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '116 SHENANDOAH RD N',
        RegAddrCity: 'BRANDON',
        RegAddrState: 'MS',
        RegAddrZip: 39047,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '210 HUNTINGTON HOLW',
        RegAddrCity: 'BRANDON',
        RegAddrState: 'MS',
        RegAddrZip: 39047,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '345 WICKLOW CIR',
        RegAddrCity: 'BRANDON',
        RegAddrState: 'MS',
        RegAddrZip: 39047,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '436 RIDGE CIR',
        RegAddrCity: 'BRANDON',
        RegAddrState: 'MS',
        RegAddrZip: 39047,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '106 PEACH TREE LN',
        RegAddrCity: 'BRANDON',
        RegAddrState: 'MS',
        RegAddrZip: 39047,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '748 W LINCOLN DR SW',
        RegAddrCity: 'BROOKHAVEN',
        RegAddrState: 'MS',
        RegAddrZip: 39601,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '205 FULTON ST',
        RegAddrCity: 'BROOKHAVEN',
        RegAddrState: 'MS',
        RegAddrZip: 39601,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '525 URBAN LN',
        RegAddrCity: 'BROOKHAVEN',
        RegAddrState: 'MS',
        RegAddrZip: 39601,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '104 PERRY CV',
        RegAddrCity: 'CANTON',
        RegAddrState: 'MS',
        RegAddrZip: 39046,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '148 HAMPTON HILLS BLVD',
        RegAddrCity: 'CANTON',
        RegAddrState: 'MS',
        RegAddrZip: 39046,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '940 MCDONALD CHAPEL RD',
        RegAddrCity: 'COLLINS',
        RegAddrState: 'MS',
        RegAddrZip: 39428,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '358 THREE NOTCH RD',
        RegAddrCity: 'COLLINS',
        RegAddrState: 'MS',
        RegAddrZip: 39428,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '8139 WILSONDALE RD',
        RegAddrCity: 'COLLINSVILLE',
        RegAddrState: 'MS',
        RegAddrZip: 39325,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '2300 STAR RD',
        RegAddrCity: 'FLORENCE',
        RegAddrState: 'MS',
        RegAddrZip: 39073,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '671 FALCON LN',
        RegAddrCity: 'FLORENCE',
        RegAddrState: 'MS',
        RegAddrZip: 39073,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '139 FRASIER RD',
        RegAddrCity: 'FLORENCE',
        RegAddrState: 'MS',
        RegAddrZip: 39073,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1751 MONTEREY RD',
        RegAddrCity: 'FLORENCE',
        RegAddrState: 'MS',
        RegAddrZip: 39073,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '228 WILLIAMS CIR',
        RegAddrCity: 'FLOWOOD',
        RegAddrState: 'MS',
        RegAddrZip: 39232,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '424 FANNIN RD',
        RegAddrCity: 'FLOWOOD',
        RegAddrState: 'MS',
        RegAddrZip: 39232,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '2009 BRADFORD PL',
        RegAddrCity: 'FLOWOOD',
        RegAddrState: 'MS',
        RegAddrZip: 39232,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '108 BEULAH RD',
        RegAddrCity: 'FOREST',
        RegAddrState: 'MS',
        RegAddrZip: 39074,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '518 1/2 W HILL ST',
        RegAddrCity: 'FOREST',
        RegAddrState: 'MS',
        RegAddrZip: 39074,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '2350 ROBERT BUTLER RD',
        RegAddrCity: 'FOREST',
        RegAddrState: 'MS',
        RegAddrZip: 39074,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '34 HARRIS ST',
        RegAddrCity: 'HICKORY',
        RegAddrState: 'MS',
        RegAddrZip: 39332,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1244 S GALLATIN ST',
        RegAddrCity: 'JACKSON',
        RegAddrState: 'MS',
        RegAddrZip: 39201,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '4001 CABELL LN',
        RegAddrCity: 'JACKSON',
        RegAddrState: 'MS',
        RegAddrZip: 39206,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '322 SOUTHBROOK DR',
        RegAddrCity: 'JACKSON',
        RegAddrState: 'MS',
        RegAddrZip: 39211,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '2445 N CHERYL DR',
        RegAddrCity: 'JACKSON',
        RegAddrState: 'MS',
        RegAddrZip: 39211,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '2315 IRVING PL',
        RegAddrCity: 'JACKSON',
        RegAddrState: 'MS',
        RegAddrZip: 39211,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '213 RIVER PL',
        RegAddrCity: 'JACKSON',
        RegAddrState: 'MS',
        RegAddrZip: 39211,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '2416 OVERBROOK DR',
        RegAddrCity: 'JACKSON',
        RegAddrState: 'MS',
        RegAddrZip: 39213,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '235 DARBUN RD',
        RegAddrCity: 'JAYESS',
        RegAddrState: 'MS',
        RegAddrZip: 39641,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '22 BOYD RUTLAND RD',
        RegAddrCity: 'JAYESS',
        RegAddrState: 'MS',
        RegAddrZip: 39641,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '3676 ALICE DR',
        RegAddrCity: 'LAUDERDALE',
        RegAddrState: 'MS',
        RegAddrZip: 39335,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '993 N SANDHILL RD',
        RegAddrCity: 'LENA',
        RegAddrState: 'MS',
        RegAddrZip: 39094,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '4167 HIGHWAY 569 N',
        RegAddrCity: 'LIBERTY',
        RegAddrState: 'MS',
        RegAddrZip: 39645,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1580 C R 24',
        RegAddrCity: 'LOUIN',
        RegAddrState: 'MS',
        RegAddrZip: 39338,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '369 C R 168',
        RegAddrCity: 'LOUIN',
        RegAddrState: 'MS',
        RegAddrZip: 39338,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '202 DENT ST',
        RegAddrCity: 'MACON',
        RegAddrState: 'MS',
        RegAddrZip: 39341,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '58 PRAIRIE ST',
        RegAddrCity: 'MACON',
        RegAddrState: 'MS',
        RegAddrZip: 39341,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '805 EIGHTH ST',
        RegAddrCity: 'MACON',
        RegAddrState: 'MS',
        RegAddrZip: 39341,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '241 KIOWA DR',
        RegAddrCity: 'MADISON',
        RegAddrState: 'MS',
        RegAddrZip: 39110,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '420 LONGWOOD TRL',
        RegAddrCity: 'MADISON',
        RegAddrState: 'MS',
        RegAddrZip: 39110,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '248 BUCKHEAD DR',
        RegAddrCity: 'MADISON',
        RegAddrState: 'MS',
        RegAddrZip: 39110,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '239 BAYVIEW DR',
        RegAddrCity: 'MADISON',
        RegAddrState: 'MS',
        RegAddrZip: 39110,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '610 TWIN HARBOR PL',
        RegAddrCity: 'MADISON',
        RegAddrState: 'MS',
        RegAddrZip: 39110,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '137 KENZIE DR',
        RegAddrCity: 'MADISON',
        RegAddrState: 'MS',
        RegAddrZip: 39110,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '118 SOUTHAMPTON CIR',
        RegAddrCity: 'MADISON',
        RegAddrState: 'MS',
        RegAddrZip: 39110,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '269 SIMPSON HIGHWAY 28 E',
        RegAddrCity: 'MAGEE',
        RegAddrState: 'MS',
        RegAddrZip: 39111,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1388 AIRPORT RD',
        RegAddrCity: 'MAGEE',
        RegAddrState: 'MS',
        RegAddrZip: 39111,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '320 5TH AVE NW',
        RegAddrCity: 'MAGEE',
        RegAddrState: 'MS',
        RegAddrZip: 39111,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '622 11TH AVE NW',
        RegAddrCity: 'MAGEE',
        RegAddrState: 'MS',
        RegAddrZip: 39111,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '7043 MAGNOLIA PROGRESS RD',
        RegAddrCity: 'MAGNOLIA',
        RegAddrState: 'MS',
        RegAddrZip: 39652,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '3069 MAGHVILLE RD',
        RegAddrCity: 'MAGNOLIA',
        RegAddrState: 'MS',
        RegAddrZip: 39652,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '5029 MAGHVILLE RD',
        RegAddrCity: 'MAGNOLIA',
        RegAddrState: 'MS',
        RegAddrZip: 39652,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '723 PONTA ST',
        RegAddrCity: 'MARION',
        RegAddrState: 'MS',
        RegAddrZip: 39342,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '6434 DALE DR',
        RegAddrCity: 'MARION',
        RegAddrState: 'MS',
        RegAddrZip: 39342,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '6468 CONFEDERATE DR',
        RegAddrCity: 'MARION',
        RegAddrState: 'MS',
        RegAddrZip: 39342,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '3178 FERNWOOD RD',
        RegAddrCity: 'MCCOMB',
        RegAddrState: 'MS',
        RegAddrZip: 39648,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '221 21ST ST',
        RegAddrCity: 'MCCOMB',
        RegAddrState: 'MS',
        RegAddrZip: 39648,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1166 DAVID BENJAMIN DR',
        RegAddrCity: 'MCCOMB',
        RegAddrState: 'MS',
        RegAddrZip: 39648,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '203 OAK ST',
        RegAddrCity: 'MCCOMB',
        RegAddrState: 'MS',
        RegAddrZip: 39648,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '121 HUFFMASTER DR',
        RegAddrCity: 'MENDENHALL',
        RegAddrState: 'MS',
        RegAddrZip: 39114,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '5338 SPRINGHILL LOOP',
        RegAddrCity: 'MERIDIAN',
        RegAddrState: 'MS',
        RegAddrZip: 39301,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1430 OBIE CLARK AVE',
        RegAddrCity: 'MERIDIAN',
        RegAddrState: 'MS',
        RegAddrZip: 39301,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1147 SANDFLAT RD',
        RegAddrCity: 'MERIDIAN',
        RegAddrState: 'MS',
        RegAddrZip: 39301,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '2506 24TH AVE',
        RegAddrCity: 'MERIDIAN',
        RegAddrState: 'MS',
        RegAddrZip: 39301,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '2283 GEORGE BUTLER RD',
        RegAddrCity: 'MERIDIAN',
        RegAddrState: 'MS',
        RegAddrZip: 39301,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '4215 N HILLS ST',
        RegAddrCity: 'MERIDIAN',
        RegAddrState: 'MS',
        RegAddrZip: 39305,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '218 FULLER RD',
        RegAddrCity: 'MERIDIAN',
        RegAddrState: 'MS',
        RegAddrZip: 39305,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '6122 20TH STREET EXT',
        RegAddrCity: 'MERIDIAN',
        RegAddrState: 'MS',
        RegAddrZip: 39307,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1316 37TH AVE',
        RegAddrCity: 'MERIDIAN',
        RegAddrState: 'MS',
        RegAddrZip: 39307,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '8161 SCR 77',
        RegAddrCity: 'MIZE',
        RegAddrState: 'MS',
        RegAddrZip: 39116,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: 'N/A',
        RegAddrCity: 'MONTICELLO',
        RegAddrState: 'MS',
        RegAddrZip: 39654,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '942 OLD HIGHWAY 27 N',
        RegAddrCity: 'MONTICELLO',
        RegAddrState: 'MS',
        RegAddrZip: 39654,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '286 SUNSET RD',
        RegAddrCity: 'MOUNT OLIVE',
        RegAddrState: 'MS',
        RegAddrZip: 39119,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1403 JAYNESVILLE RD',
        RegAddrCity: 'MOUNT OLIVE',
        RegAddrState: 'MS',
        RegAddrZip: 39119,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '320 SCR 44',
        RegAddrCity: 'MOUNT OLIVE',
        RegAddrState: 'MS',
        RegAddrZip: 39119,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '115 MALLEY RD',
        RegAddrCity: 'MOUNT OLIVE',
        RegAddrState: 'MS',
        RegAddrZip: 39119,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1526 MARTIN LUTHER KING JR RD',
        RegAddrCity: 'NATCHEZ',
        RegAddrState: 'MS',
        RegAddrZip: 39120,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1255 DAISY ST',
        RegAddrCity: 'NATCHEZ',
        RegAddrState: 'MS',
        RegAddrZip: 39120,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '111 TANGLEWOOD RD',
        RegAddrCity: 'NATCHEZ',
        RegAddrState: 'MS',
        RegAddrZip: 39120,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '113 GAILE AVE',
        RegAddrCity: 'NATCHEZ',
        RegAddrState: 'MS',
        RegAddrZip: 39120,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '100 N TEMPLE RD',
        RegAddrCity: 'NATCHEZ',
        RegAddrState: 'MS',
        RegAddrZip: 39120,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '6 MATTHEWS ST',
        RegAddrCity: 'NATCHEZ',
        RegAddrState: 'MS',
        RegAddrZip: 39120,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '190 CARDINAL DR',
        RegAddrCity: 'NATCHEZ',
        RegAddrState: 'MS',
        RegAddrZip: 39120,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '584 TURKEY CREEK RD',
        RegAddrCity: 'NEWTON',
        RegAddrState: 'MS',
        RegAddrZip: 39345,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '714 COUNTRY PLACE DR',
        RegAddrCity: 'PEARL',
        RegAddrState: 'MS',
        RegAddrZip: 39208,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '409 ROBERTS ST',
        RegAddrCity: 'PEARL',
        RegAddrState: 'MS',
        RegAddrZip: 39208,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '2581 NAPOLEON AVE',
        RegAddrCity: 'PEARL',
        RegAddrState: 'MS',
        RegAddrZip: 39208,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '200 RED OAK RD',
        RegAddrCity: 'PELAHATCHIE',
        RegAddrState: 'MS',
        RegAddrZip: 39145,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '10271 ROAD 630',
        RegAddrCity: 'PHILADELPHIA',
        RegAddrState: 'MS',
        RegAddrZip: 39350,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '12280 ROAD 383',
        RegAddrCity: 'PHILADELPHIA',
        RegAddrState: 'MS',
        RegAddrZip: 39350,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '10491 ROAD 642',
        RegAddrCity: 'PHILADELPHIA',
        RegAddrState: 'MS',
        RegAddrZip: 39350,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '294 BYRD AVE N',
        RegAddrCity: 'PHILADELPHIA',
        RegAddrState: 'MS',
        RegAddrZip: 39350,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '514 W SAINT STEPHENS RD',
        RegAddrCity: 'PRENTISS',
        RegAddrState: 'MS',
        RegAddrZip: 39474,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '2681 S COUNTY LINE RD',
        RegAddrCity: 'PRESTON',
        RegAddrState: 'MS',
        RegAddrZip: 39354,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '4983 HIGHWAY 18 E',
        RegAddrCity: 'QUITMAN',
        RegAddrState: 'MS',
        RegAddrZip: 39355,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '2034 COUNTY ROAD 420',
        RegAddrCity: 'QUITMAN',
        RegAddrState: 'MS',
        RegAddrZip: 39355,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '139 NAPP ST',
        RegAddrCity: 'QUITMAN',
        RegAddrState: 'MS',
        RegAddrZip: 39355,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '225 LOWE CIR',
        RegAddrCity: 'RICHLAND',
        RegAddrState: 'MS',
        RegAddrZip: 39218,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '603 CAMDENPARK DR',
        RegAddrCity: 'RIDGELAND',
        RegAddrState: 'MS',
        RegAddrZip: 39157,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '53 ENCLAVE CIR',
        RegAddrCity: 'RIDGELAND',
        RegAddrState: 'MS',
        RegAddrZip: 39157,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '123 KELTINGHAM CT',
        RegAddrCity: 'RIDGELAND',
        RegAddrState: 'MS',
        RegAddrZip: 39157,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '309 HIGHLAND PARK BLVD',
        RegAddrCity: 'RIDGELAND',
        RegAddrState: 'MS',
        RegAddrZip: 39157,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '824 SUSSEX PL',
        RegAddrCity: 'RIDGELAND',
        RegAddrState: 'MS',
        RegAddrZip: 39157,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '612 WINTERIDGE PL',
        RegAddrCity: 'RIDGELAND',
        RegAddrState: 'MS',
        RegAddrZip: 39157,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '26 FIELDVIEW DR',
        RegAddrCity: 'ROXIE',
        RegAddrState: 'MS',
        RegAddrZip: 39661,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '253 HIGHWAY 598',
        RegAddrCity: 'SEMINARY',
        RegAddrState: 'MS',
        RegAddrZip: 39479,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '522 COUNTY ROAD 114',
        RegAddrCity: 'SHUBUTA',
        RegAddrState: 'MS',
        RegAddrZip: 39360,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '613 SPRUILL PL',
        RegAddrCity: 'STARKVILLE',
        RegAddrState: 'MS',
        RegAddrZip: 39759,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '527 WHITFIELD ST',
        RegAddrCity: 'STARKVILLE',
        RegAddrState: 'MS',
        RegAddrZip: 39759,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '58 PINEDALE LN',
        RegAddrCity: 'STARKVILLE',
        RegAddrState: 'MS',
        RegAddrZip: 39759,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1408 CEDAR CREEK LN',
        RegAddrCity: 'STARKVILLE',
        RegAddrState: 'MS',
        RegAddrZip: 39759,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '113 DUNBROOK DR',
        RegAddrCity: 'STARKVILLE',
        RegAddrState: 'MS',
        RegAddrZip: 39759,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '205 LYNN LN',
        RegAddrCity: 'STARKVILLE',
        RegAddrState: 'MS',
        RegAddrZip: 39759,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1106 PALMETT ST',
        RegAddrCity: 'SUMMIT',
        RegAddrState: 'MS',
        RegAddrZip: 39666,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '2029 TED THOMPSON RD',
        RegAddrCity: 'SUMMIT',
        RegAddrState: 'MS',
        RegAddrZip: 39666,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '6105A WOMACK RD',
        RegAddrCity: 'SUMMIT',
        RegAddrState: 'MS',
        RegAddrZip: 39666,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1059 SCR 4',
        RegAddrCity: 'TAYLORSVILLE',
        RegAddrState: 'MS',
        RegAddrZip: 39168,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '82 MOUNT OLIVE RD',
        RegAddrCity: 'TYLERTOWN',
        RegAddrState: 'MS',
        RegAddrZip: 39667,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '42 W MOUNT OLIVE RD',
        RegAddrCity: 'TYLERTOWN',
        RegAddrState: 'MS',
        RegAddrZip: 39667,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '123 S SANDIFER ST',
        RegAddrCity: 'TYLERTOWN',
        RegAddrState: 'MS',
        RegAddrZip: 39667,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '304 SANDRA DR',
        RegAddrCity: 'TYLERTOWN',
        RegAddrState: 'MS',
        RegAddrZip: 39667,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '286 KNOXO RD',
        RegAddrCity: 'TYLERTOWN',
        RegAddrState: 'MS',
        RegAddrZip: 39667,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '10590 ROAD 234',
        RegAddrCity: 'UNION',
        RegAddrState: 'MS',
        RegAddrZip: 39365,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '3568 HIGHWAY 494',
        RegAddrCity: 'UNION',
        RegAddrState: 'MS',
        RegAddrZip: 39365,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '10160 ROAD 2238',
        RegAddrCity: 'UNION',
        RegAddrState: 'MS',
        RegAddrZip: 39365,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '225 BOLDEN ST',
        RegAddrCity: 'UNION',
        RegAddrState: 'MS',
        RegAddrZip: 39365,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '711 RUFUS GILL RD',
        RegAddrCity: 'UNION',
        RegAddrState: 'MS',
        RegAddrZip: 39365,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '2528 SUNSET RD NE',
        RegAddrCity: 'WESSON',
        RegAddrState: 'MS',
        RegAddrZip: 39191,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '473 SHILOH CHURCH RD',
        RegAddrCity: 'WOODVILLE',
        RegAddrState: 'MS',
        RegAddrZip: 39669,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '72 DENNIS RD',
        RegAddrCity: 'WOODVILLE',
        RegAddrState: 'MS',
        RegAddrZip: 39669,
        CongressionalDistrict: 3,
      },
    ],
  },
  {
    name: 'MS-4',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/MS-4?ref=gh-pages',
    state: 'MS',
    state_and_number: 'MS-4',
    zip: 39322,
    congressionalDistrict: 4,
    addressesInDistrict: [
      {
        RegAddrLine1: '6061 DOGWOOD DR',
        RegAddrCity: 'BAY ST LOUIS',
        RegAddrState: 'MS',
        RegAddrZip: 39520,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '513 GENIN ST',
        RegAddrCity: 'BAY ST LOUIS',
        RegAddrState: 'MS',
        RegAddrZip: 39520,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1020 LOUIS PIERNAS DR',
        RegAddrCity: 'BAY ST LOUIS',
        RegAddrState: 'MS',
        RegAddrZip: 39520,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: 'HIGHWAY 98 WEST 420',
        RegAddrCity: 'BEAUMONT',
        RegAddrState: 'MS',
        RegAddrZip: 39423,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '15 GUS LOTT RD',
        RegAddrCity: 'BEAUMONT',
        RegAddrState: 'MS',
        RegAddrZip: 39423,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '15 COURTNEY LN',
        RegAddrCity: 'BEAUMONT',
        RegAddrState: 'MS',
        RegAddrZip: 39423,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '259 CRAWFORD ST',
        RegAddrCity: 'BILOXI',
        RegAddrState: 'MS',
        RegAddrZip: 39530,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '221 PRINGLE AVE',
        RegAddrCity: 'BILOXI',
        RegAddrState: 'MS',
        RegAddrZip: 39530,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '857 BEEMAN DR',
        RegAddrCity: 'BILOXI',
        RegAddrState: 'MS',
        RegAddrZip: 39530,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: 'W BILOXI STA',
        RegAddrCity: 'BILOXI',
        RegAddrState: 'MS',
        RegAddrZip: 39530,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '401 VIOLET ST',
        RegAddrCity: 'BILOXI',
        RegAddrState: 'MS',
        RegAddrZip: 39530,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '135 BARQ AVE',
        RegAddrCity: 'BILOXI',
        RegAddrState: 'MS',
        RegAddrZip: 39531,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '132 EDGEWATER DR',
        RegAddrCity: 'BILOXI',
        RegAddrState: 'MS',
        RegAddrZip: 39531,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '854 AUBURN DR',
        RegAddrCity: 'BILOXI',
        RegAddrState: 'MS',
        RegAddrZip: 39532,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '15544 HUDSON KROHN RD',
        RegAddrCity: 'BILOXI',
        RegAddrState: 'MS',
        RegAddrZip: 39532,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '15601 BELMONT DR',
        RegAddrCity: 'BILOXI',
        RegAddrState: 'MS',
        RegAddrZip: 39532,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2282 MILES CT',
        RegAddrCity: 'BILOXI',
        RegAddrState: 'MS',
        RegAddrZip: 39532,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '16 LYNNE DR',
        RegAddrCity: 'CARRIERE',
        RegAddrState: 'MS',
        RegAddrZip: 39426,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '11120 PREACHER POWELL RD',
        RegAddrCity: 'CARRIERE',
        RegAddrState: 'MS',
        RegAddrZip: 39426,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '130 GEORGE FORD RD',
        RegAddrCity: 'CARRIERE',
        RegAddrState: 'MS',
        RegAddrZip: 39426,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '221 GLENWOOD DR',
        RegAddrCity: 'CARRIERE',
        RegAddrState: 'MS',
        RegAddrZip: 39426,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '803 LYNCREST AVE',
        RegAddrCity: 'COLUMBIA',
        RegAddrState: 'MS',
        RegAddrZip: 39429,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '916 DUANE ST',
        RegAddrCity: 'COLUMBIA',
        RegAddrState: 'MS',
        RegAddrZip: 39429,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '8858 KAILUA PL',
        RegAddrCity: 'DIAMONDHEAD',
        RegAddrState: 'MS',
        RegAddrZip: 39525,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '277 OLD SCHOOLHOUSE RD',
        RegAddrCity: 'ELLISVILLE',
        RegAddrState: 'MS',
        RegAddrZip: 39437,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '501 N CHURCH ST',
        RegAddrCity: 'ELLISVILLE',
        RegAddrState: 'MS',
        RegAddrZip: 39437,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '44 E MARION ST',
        RegAddrCity: 'FOXWORTH',
        RegAddrState: 'MS',
        RegAddrZip: 39483,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2853 W PARK DR',
        RegAddrCity: 'GAUTIER',
        RegAddrState: 'MS',
        RegAddrZip: 39553,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '3204 SMITH DR',
        RegAddrCity: 'GAUTIER',
        RegAddrState: 'MS',
        RegAddrZip: 39553,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2101 LADNIER RD',
        RegAddrCity: 'GAUTIER',
        RegAddrState: 'MS',
        RegAddrZip: 39553,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '5621 CARRIE ST',
        RegAddrCity: 'GAUTIER',
        RegAddrState: 'MS',
        RegAddrZip: 39553,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '8333 MEADOWDALE DR',
        RegAddrCity: 'GAUTIER',
        RegAddrState: 'MS',
        RegAddrZip: 39553,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '808 MURPHY ST',
        RegAddrCity: 'GULFPORT',
        RegAddrState: 'MS',
        RegAddrZip: 39501,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '4300 MICHIGAN AVE',
        RegAddrCity: 'GULFPORT',
        RegAddrState: 'MS',
        RegAddrZip: 39501,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '3300 20TH ST',
        RegAddrCity: 'GULFPORT',
        RegAddrState: 'MS',
        RegAddrZip: 39501,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '803 RICH AVE',
        RegAddrCity: 'GULFPORT',
        RegAddrState: 'MS',
        RegAddrZip: 39501,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '622 E RAILROAD ST',
        RegAddrCity: 'GULFPORT',
        RegAddrState: 'MS',
        RegAddrZip: 39501,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2508 6TH AVE',
        RegAddrCity: 'GULFPORT',
        RegAddrState: 'MS',
        RegAddrZip: 39501,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '15316 PARKWOOD DR N',
        RegAddrCity: 'GULFPORT',
        RegAddrState: 'MS',
        RegAddrZip: 39503,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '17212 MORAN RD',
        RegAddrCity: 'GULFPORT',
        RegAddrState: 'MS',
        RegAddrZip: 39503,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '900 E BIRCH DR',
        RegAddrCity: 'GULFPORT',
        RegAddrState: 'MS',
        RegAddrZip: 39503,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '13211 HICKORY CT',
        RegAddrCity: 'GULFPORT',
        RegAddrState: 'MS',
        RegAddrZip: 39503,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '15393 PINEWOOD CT',
        RegAddrCity: 'GULFPORT',
        RegAddrState: 'MS',
        RegAddrZip: 39503,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '111 CONNIE DR',
        RegAddrCity: 'GULFPORT',
        RegAddrState: 'MS',
        RegAddrZip: 39503,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2612 W DAVID DR',
        RegAddrCity: 'GULFPORT',
        RegAddrState: 'MS',
        RegAddrZip: 39503,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '15291 PARKWOOD DR N',
        RegAddrCity: 'GULFPORT',
        RegAddrState: 'MS',
        RegAddrZip: 39503,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '307 ROBERT DALE DR',
        RegAddrCity: 'GULFPORT',
        RegAddrState: 'MS',
        RegAddrZip: 39503,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '15712 CEMETERY RD',
        RegAddrCity: 'GULFPORT',
        RegAddrState: 'MS',
        RegAddrZip: 39503,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2511 DEMARET DR',
        RegAddrCity: 'GULFPORT',
        RegAddrState: 'MS',
        RegAddrZip: 39507,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2411 DEMARET DR',
        RegAddrCity: 'GULFPORT',
        RegAddrState: 'MS',
        RegAddrZip: 39507,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '518 ELIZABETH AVE',
        RegAddrCity: 'HATTIESBURG',
        RegAddrState: 'MS',
        RegAddrZip: 39401,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '403 CRESTMONT AVE',
        RegAddrCity: 'HATTIESBURG',
        RegAddrState: 'MS',
        RegAddrZip: 39401,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '507 HALL AVE',
        RegAddrCity: 'HATTIESBURG',
        RegAddrState: 'MS',
        RegAddrZip: 39401,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '101 NUGGETT CT',
        RegAddrCity: 'HATTIESBURG',
        RegAddrState: 'MS',
        RegAddrZip: 39401,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '940 J B HORNE RD',
        RegAddrCity: 'HATTIESBURG',
        RegAddrState: 'MS',
        RegAddrZip: 39401,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1008 FLOWERS ST',
        RegAddrCity: 'HATTIESBURG',
        RegAddrState: 'MS',
        RegAddrZip: 39401,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '102 S 20TH AVE',
        RegAddrCity: 'HATTIESBURG',
        RegAddrState: 'MS',
        RegAddrZip: 39401,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '598C COUNTY DR',
        RegAddrCity: 'HATTIESBURG',
        RegAddrState: 'MS',
        RegAddrZip: 39401,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '62 ROGERS RD',
        RegAddrCity: 'HATTIESBURG',
        RegAddrState: 'MS',
        RegAddrZip: 39401,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1503 MONROE RD',
        RegAddrCity: 'HATTIESBURG',
        RegAddrState: 'MS',
        RegAddrZip: 39401,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '105 BEAVER RD',
        RegAddrCity: 'HATTIESBURG',
        RegAddrState: 'MS',
        RegAddrZip: 39402,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '602 MACKWOOD DR',
        RegAddrCity: 'HATTIESBURG',
        RegAddrState: 'MS',
        RegAddrZip: 39402,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '4505 OAK FORREST DR',
        RegAddrCity: 'HATTIESBURG',
        RegAddrState: 'MS',
        RegAddrZip: 39402,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '115 LEXINGTON DR',
        RegAddrCity: 'HATTIESBURG',
        RegAddrState: 'MS',
        RegAddrZip: 39402,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '116 W RAY DR',
        RegAddrCity: 'HATTIESBURG',
        RegAddrState: 'MS',
        RegAddrZip: 39402,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '209 W LAKEWOOD DR',
        RegAddrCity: 'HATTIESBURG',
        RegAddrState: 'MS',
        RegAddrZip: 39402,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2916 WILLIAMSBURG RD',
        RegAddrCity: 'HATTIESBURG',
        RegAddrState: 'MS',
        RegAddrZip: 39402,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2071 ROAD 363',
        RegAddrCity: 'KILN',
        RegAddrState: 'MS',
        RegAddrZip: 39556,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '19069 BLUEBERRY HILL RD',
        RegAddrCity: 'KILN',
        RegAddrState: 'MS',
        RegAddrZip: 39556,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '742 E 16TH ST',
        RegAddrCity: 'LAUREL',
        RegAddrState: 'MS',
        RegAddrZip: 39440,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1641 N MISSISSIPPI AVE',
        RegAddrCity: 'LAUREL',
        RegAddrState: 'MS',
        RegAddrZip: 39440,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '313 N 15TH AVE',
        RegAddrCity: 'LAUREL',
        RegAddrState: 'MS',
        RegAddrZip: 39440,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '752 NORTHWOOD DR',
        RegAddrCity: 'LAUREL',
        RegAddrState: 'MS',
        RegAddrZip: 39440,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '437 FAIRWAY PL',
        RegAddrCity: 'LAUREL',
        RegAddrState: 'MS',
        RegAddrZip: 39440,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1019 W 9TH ST',
        RegAddrCity: 'LAUREL',
        RegAddrState: 'MS',
        RegAddrZip: 39440,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: 'PO BOX 1143',
        RegAddrCity: 'LAUREL',
        RegAddrState: 'MS',
        RegAddrZip: 39441,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '30 GARDNER RD',
        RegAddrCity: 'LAUREL',
        RegAddrState: 'MS',
        RegAddrZip: 39443,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '261 POOLE CREEK RD',
        RegAddrCity: 'LAUREL',
        RegAddrState: 'MS',
        RegAddrZip: 39443,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '27 TRACE RD',
        RegAddrCity: 'LAUREL',
        RegAddrState: 'MS',
        RegAddrZip: 39443,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1763 HIGHWAY 184',
        RegAddrCity: 'LAUREL',
        RegAddrState: 'MS',
        RegAddrZip: 39443,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '569 JOE PERRETT RD',
        RegAddrCity: 'LAUREL',
        RegAddrState: 'MS',
        RegAddrZip: 39443,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2109 EVANS AVE',
        RegAddrCity: 'LEAKESVILLE',
        RegAddrState: 'MS',
        RegAddrZip: 39451,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '908 DEER PARK RD',
        RegAddrCity: 'LEAKESVILLE',
        RegAddrState: 'MS',
        RegAddrZip: 39451,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '752 ARBOR STATION DR',
        RegAddrCity: 'LONG BEACH',
        RegAddrState: 'MS',
        RegAddrZip: 39560,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '376 MORRIS GREEN RD',
        RegAddrCity: 'LUCEDALE',
        RegAddrState: 'MS',
        RegAddrZip: 39452,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1187 BASIN REFUGE RD',
        RegAddrCity: 'LUCEDALE',
        RegAddrState: 'MS',
        RegAddrZip: 39452,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '119 GAY PIERCE RD',
        RegAddrCity: 'LUCEDALE',
        RegAddrState: 'MS',
        RegAddrZip: 39452,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2123 ELLIS HODGE RD',
        RegAddrCity: 'LUCEDALE',
        RegAddrState: 'MS',
        RegAddrZip: 39452,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '23501 STATE LINE RD',
        RegAddrCity: 'LUCEDALE',
        RegAddrState: 'MS',
        RegAddrZip: 39452,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '107 DUNCAN ST',
        RegAddrCity: 'LUCEDALE',
        RegAddrState: 'MS',
        RegAddrZip: 39452,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '5401 CERES FARM RD',
        RegAddrCity: 'LUCEDALE',
        RegAddrState: 'MS',
        RegAddrZip: 39452,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '406 THOMAS SCHOOL RD',
        RegAddrCity: 'LUMBERTON',
        RegAddrState: 'MS',
        RegAddrZip: 39455,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1213 CARNES RD',
        RegAddrCity: 'LUMBERTON',
        RegAddrState: 'MS',
        RegAddrZip: 39455,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '104 N 7TH ST',
        RegAddrCity: 'LUMBERTON',
        RegAddrState: 'MS',
        RegAddrZip: 39455,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '573 R V LINDLEY RD',
        RegAddrCity: 'MOSELLE',
        RegAddrState: 'MS',
        RegAddrZip: 39459,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '14413 HIGHWAY 613',
        RegAddrCity: 'MOSS POINT',
        RegAddrState: 'MS',
        RegAddrZip: 39562,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '5932 ORANGE GROVE RD',
        RegAddrCity: 'MOSS POINT',
        RegAddrState: 'MS',
        RegAddrZip: 39562,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '4918 WILSON ST',
        RegAddrCity: 'MOSS POINT',
        RegAddrState: 'MS',
        RegAddrZip: 39563,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '6512 DELORES CIR',
        RegAddrCity: 'MOSS POINT',
        RegAddrState: 'MS',
        RegAddrZip: 39563,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '4107 DANTZLER ST',
        RegAddrCity: 'MOSS POINT',
        RegAddrState: 'MS',
        RegAddrZip: 39563,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '6103 MARTIN LUTHER KING BLVD',
        RegAddrCity: 'MOSS POINT',
        RegAddrState: 'MS',
        RegAddrZip: 39563,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '432 BILLS AVE',
        RegAddrCity: 'OCEAN SPRINGS',
        RegAddrState: 'MS',
        RegAddrZip: 39564,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '8909 DAVIS ST',
        RegAddrCity: 'OCEAN SPRINGS',
        RegAddrState: 'MS',
        RegAddrZip: 39564,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '9125 EDGEWATER BLVD',
        RegAddrCity: 'OCEAN SPRINGS',
        RegAddrState: 'MS',
        RegAddrZip: 39564,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '112 BRYANT ST',
        RegAddrCity: 'OCEAN SPRINGS',
        RegAddrState: 'MS',
        RegAddrZip: 39564,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2602 CALAI CIR',
        RegAddrCity: 'OCEAN SPRINGS',
        RegAddrState: 'MS',
        RegAddrZip: 39564,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2017 IONE CIR',
        RegAddrCity: 'OCEAN SPRINGS',
        RegAddrState: 'MS',
        RegAddrZip: 39564,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '95 LA BRANCHE AVE',
        RegAddrCity: 'OCEAN SPRINGS',
        RegAddrState: 'MS',
        RegAddrZip: 39564,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '4507 CEDAR ST',
        RegAddrCity: 'PASCAGOULA',
        RegAddrState: 'MS',
        RegAddrZip: 39567,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1505 CHERUBUSCO ST',
        RegAddrCity: 'PASCAGOULA',
        RegAddrState: 'MS',
        RegAddrZip: 39567,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2011 8TH ST',
        RegAddrCity: 'PASCAGOULA',
        RegAddrState: 'MS',
        RegAddrZip: 39567,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2057 MARKET ST',
        RegAddrCity: 'PASCAGOULA',
        RegAddrState: 'MS',
        RegAddrZip: 39567,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1040 DEL NORTE CIR',
        RegAddrCity: 'PASCAGOULA',
        RegAddrState: 'MS',
        RegAddrZip: 39581,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '29211 SHENANDOAH DR',
        RegAddrCity: 'PASS CHRIS',
        RegAddrState: 'MS',
        RegAddrZip: 39571,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '124 ELM LN',
        RegAddrCity: 'PASS CHRIS',
        RegAddrState: 'MS',
        RegAddrZip: 39571,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '13370 PERCY LADNER RD',
        RegAddrCity: 'PASS CHRIS',
        RegAddrState: 'MS',
        RegAddrZip: 39571,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '4025 TAHITI RD',
        RegAddrCity: 'PEARLINGTON',
        RegAddrState: 'MS',
        RegAddrZip: 39572,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1528 HIGHWAY 53',
        RegAddrCity: 'PERKINSTON',
        RegAddrState: 'MS',
        RegAddrZip: 39573,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1140 WIRE RD W',
        RegAddrCity: 'PERKINSTON',
        RegAddrState: 'MS',
        RegAddrZip: 39573,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '28553 SHILOH CHURCH RD',
        RegAddrCity: 'PERKINSTON',
        RegAddrState: 'MS',
        RegAddrZip: 39573,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '561 MORRISTON RD',
        RegAddrCity: 'PETAL',
        RegAddrState: 'MS',
        RegAddrZip: 39465,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '300 PETAL DR',
        RegAddrCity: 'PETAL',
        RegAddrState: 'MS',
        RegAddrZip: 39465,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '17 LAKELAND CIR',
        RegAddrCity: 'PETAL',
        RegAddrState: 'MS',
        RegAddrZip: 39465,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2033 OLD RICHTON RD',
        RegAddrCity: 'PETAL',
        RegAddrState: 'MS',
        RegAddrZip: 39465,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '111 EASTABUCHIE RD',
        RegAddrCity: 'PETAL',
        RegAddrState: 'MS',
        RegAddrZip: 39465,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '39 WILDWOOD TRL',
        RegAddrCity: 'PETAL',
        RegAddrState: 'MS',
        RegAddrZip: 39465,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '161 EASTABUCHIE RD',
        RegAddrCity: 'PETAL',
        RegAddrState: 'MS',
        RegAddrZip: 39465,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '310 COUNTRY CLUB DR',
        RegAddrCity: 'PICAYUNE',
        RegAddrState: 'MS',
        RegAddrZip: 39466,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '187 BIG SPRING RD',
        RegAddrCity: 'PICAYUNE',
        RegAddrState: 'MS',
        RegAddrZip: 39466,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '3022 BURNT BRIDGE RD',
        RegAddrCity: 'PICAYUNE',
        RegAddrState: 'MS',
        RegAddrZip: 39466,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '624 F Z GOSS RD',
        RegAddrCity: 'PICAYUNE',
        RegAddrState: 'MS',
        RegAddrZip: 39466,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '17 CORNELL LN',
        RegAddrCity: 'POPLARVILLE',
        RegAddrState: 'MS',
        RegAddrZip: 39470,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1236 HIGHWAY 26 E',
        RegAddrCity: 'POPLARVILLE',
        RegAddrState: 'MS',
        RegAddrZip: 39470,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '84 SHERLOCK HERRING RD',
        RegAddrCity: 'PURVIS',
        RegAddrState: 'MS',
        RegAddrZip: 39475,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '48 AZALEA TRL',
        RegAddrCity: 'PURVIS',
        RegAddrState: 'MS',
        RegAddrZip: 39475,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '710 BROWN AVE',
        RegAddrCity: 'PURVIS',
        RegAddrState: 'MS',
        RegAddrZip: 39475,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '745 HIGHWAY 589',
        RegAddrCity: 'PURVIS',
        RegAddrState: 'MS',
        RegAddrZip: 39475,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '132 W A PITTS RD',
        RegAddrCity: 'RICHTON',
        RegAddrState: 'MS',
        RegAddrZip: 39476,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '19279 ELDRIDGE CT',
        RegAddrCity: 'SAUCIER',
        RegAddrState: 'MS',
        RegAddrZip: 39574,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '24398 BOWEEVILLE RD',
        RegAddrCity: 'SAUCIER',
        RegAddrState: 'MS',
        RegAddrZip: 39574,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '39 DAUGHTRY RD',
        RegAddrCity: 'SEMINARY',
        RegAddrState: 'MS',
        RegAddrZip: 39479,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '608 STATE LINE BATTLES RD',
        RegAddrCity: 'STATE LINE',
        RegAddrState: 'MS',
        RegAddrZip: 39362,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '17 WILLIAMSBURG RD',
        RegAddrCity: 'SUMRALL',
        RegAddrState: 'MS',
        RegAddrZip: 39482,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '17111 SPRING LAKE DR W',
        RegAddrCity: 'VANCLEAVE',
        RegAddrState: 'MS',
        RegAddrZip: 39565,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '805 SPRUCE ST',
        RegAddrCity: 'WAVELAND',
        RegAddrState: 'MS',
        RegAddrZip: 39576,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '17 GENE GREEN DR',
        RegAddrCity: 'WAYNESBORO',
        RegAddrState: 'MS',
        RegAddrZip: 39367,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '437 OLD HIGHWAY 84 LOOP RD',
        RegAddrCity: 'WAYNESBORO',
        RegAddrState: 'MS',
        RegAddrZip: 39367,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '855 COUNTY ROAD 622',
        RegAddrCity: 'WAYNESBORO',
        RegAddrState: 'MS',
        RegAddrZip: 39367,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '148 TOBY LANDRUM RD',
        RegAddrCity: 'WAYNESBORO',
        RegAddrState: 'MS',
        RegAddrZip: 39367,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '913 BUSBY DR',
        RegAddrCity: 'WAYNESBORO',
        RegAddrState: 'MS',
        RegAddrZip: 39367,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '621 MCGREGOR ST S',
        RegAddrCity: 'WIGGINS',
        RegAddrState: 'MS',
        RegAddrZip: 39577,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '3013 HIGHWAY 26',
        RegAddrCity: 'WIGGINS',
        RegAddrState: 'MS',
        RegAddrZip: 39577,
        CongressionalDistrict: 4,
      },
    ],
  },
  {
    name: 'MT-0',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/MT-0?ref=gh-pages',
    state: 'MT',
    state_and_number: 'MT-0',
    zip: 57717,
    congressionalDistrict: 0,
    addressesInDistrict: [
      {
        RegAddrLine1: '2994 HIGHWAY 78',
        RegAddrCity: 'ABSAROKEE',
        RegAddrState: 'MT',
        RegAddrZip: 59001,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '52 STAR HAVEN DR',
        RegAddrCity: 'ABSAROKEE',
        RegAddrState: 'MT',
        RegAddrZip: 59001,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '2540 HIGHWAY 78',
        RegAddrCity: 'ABSAROKEE',
        RegAddrState: 'MT',
        RegAddrZip: 59001,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '45 SMITHVILLE DR',
        RegAddrCity: 'ANACONDA',
        RegAddrState: 'MT',
        RegAddrZip: 59711,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '709 W COMMERCIAL AVE',
        RegAddrCity: 'ANACONDA',
        RegAddrState: 'MT',
        RegAddrZip: 59711,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '3771 GALEN RD',
        RegAddrCity: 'ANACONDA',
        RegAddrState: 'MT',
        RegAddrZip: 59711,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '139 SHIRLEY WAY',
        RegAddrCity: 'ANACONDA',
        RegAddrState: 'MT',
        RegAddrZip: 59711,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1209 W PARK AVE',
        RegAddrCity: 'ANACONDA',
        RegAddrState: 'MT',
        RegAddrZip: 59711,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '71394 STRAWBERRY LN',
        RegAddrCity: 'ARLEE',
        RegAddrState: 'MT',
        RegAddrZip: 59821,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '34 BROKEN CIRCLE RANCH DR',
        RegAddrCity: 'ARLEE',
        RegAddrState: 'MT',
        RegAddrZip: 59821,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '25500 US HIGHWAY 93 N',
        RegAddrCity: 'ARLEE',
        RegAddrState: 'MT',
        RegAddrZip: 59821,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '5982 TONGUE RIVER RD',
        RegAddrCity: 'ASHLAND',
        RegAddrState: 'MT',
        RegAddrZip: 59003,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '13 BOULDER ST',
        RegAddrCity: 'BASIN',
        RegAddrState: 'MT',
        RegAddrZip: 59631,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '85 STAG ST',
        RegAddrCity: 'BELGRADE',
        RegAddrState: 'MT',
        RegAddrZip: 59714,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '154 4 DOT LN',
        RegAddrCity: 'BELGRADE',
        RegAddrState: 'MT',
        RegAddrZip: 59714,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '307 E MAIN ST',
        RegAddrCity: 'BELGRADE',
        RegAddrState: 'MT',
        RegAddrZip: 59714,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '2657 SHEEPSHANK DR',
        RegAddrCity: 'BELGRADE',
        RegAddrState: 'MT',
        RegAddrZip: 59714,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '91 N OLD PLACE LN',
        RegAddrCity: 'BELGRADE',
        RegAddrState: 'MT',
        RegAddrZip: 59714,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '59 SKYVIEW DR',
        RegAddrCity: 'BELGRADE',
        RegAddrState: 'MT',
        RegAddrZip: 59714,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '171 PEBBLE BROOK LN',
        RegAddrCity: 'BELGRADE',
        RegAddrState: 'MT',
        RegAddrZip: 59714,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '601 BELT CREEK RD',
        RegAddrCity: 'BELT',
        RegAddrState: 'MT',
        RegAddrZip: 59412,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '316 OTTER CREEK RD',
        RegAddrCity: 'BIG TIMBER',
        RegAddrState: 'MT',
        RegAddrZip: 59011,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '241 BIG TIMBER CANYON RD',
        RegAddrCity: 'BIG TIMBER',
        RegAddrState: 'MT',
        RegAddrZip: 59011,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '284 GLASSTON RD',
        RegAddrCity: 'BIG TIMBER',
        RegAddrState: 'MT',
        RegAddrZip: 59011,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '412 W 2ND AVE',
        RegAddrCity: 'BIG TIMBER',
        RegAddrState: 'MT',
        RegAddrZip: 59011,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1180 SWAN HORSESHOE DR',
        RegAddrCity: 'BIGFORK',
        RegAddrState: 'MT',
        RegAddrZip: 59911,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1704 LA BRANT RD',
        RegAddrCity: 'BIGFORK',
        RegAddrState: 'MT',
        RegAddrZip: 59911,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '182 CRESTVIEW DR',
        RegAddrCity: 'BIGFORK',
        RegAddrState: 'MT',
        RegAddrZip: 59911,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '989 LAKE OF THE WOODS LN',
        RegAddrCity: 'BIGFORK',
        RegAddrState: 'MT',
        RegAddrZip: 59911,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '630 MCCAFFERY RD',
        RegAddrCity: 'BIGFORK',
        RegAddrState: 'MT',
        RegAddrZip: 59911,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '241 AVENUE B',
        RegAddrCity: 'BILLINGS',
        RegAddrState: 'MT',
        RegAddrZip: 59101,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '3822 CAMBRIDGE DR',
        RegAddrCity: 'BILLINGS',
        RegAddrState: 'MT',
        RegAddrZip: 59101,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '4444 CLEVENGER AVE',
        RegAddrCity: 'BILLINGS',
        RegAddrState: 'MT',
        RegAddrZip: 59101,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '3224 BECRAFT LN',
        RegAddrCity: 'BILLINGS',
        RegAddrState: 'MT',
        RegAddrZip: 59101,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '2803 3RD AVE S',
        RegAddrCity: 'BILLINGS',
        RegAddrState: 'MT',
        RegAddrZip: 59101,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1101 N 24TH ST',
        RegAddrCity: 'BILLINGS',
        RegAddrState: 'MT',
        RegAddrZip: 59101,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '910 TERRY AVE',
        RegAddrCity: 'BILLINGS',
        RegAddrState: 'MT',
        RegAddrZip: 59101,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '36 MONROE ST',
        RegAddrCity: 'BILLINGS',
        RegAddrState: 'MT',
        RegAddrZip: 59101,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '616 N 24TH ST',
        RegAddrCity: 'BILLINGS',
        RegAddrState: 'MT',
        RegAddrZip: 59101,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '906 ROCHESTER DR',
        RegAddrCity: 'BILLINGS',
        RegAddrState: 'MT',
        RegAddrZip: 59101,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '3109 7TH AVE N',
        RegAddrCity: 'BILLINGS',
        RegAddrState: 'MT',
        RegAddrZip: 59101,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1001 S 27TH ST',
        RegAddrCity: 'BILLINGS',
        RegAddrState: 'MT',
        RegAddrZip: 59101,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '647 WASHINGTON ST',
        RegAddrCity: 'BILLINGS',
        RegAddrState: 'MT',
        RegAddrZip: 59101,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1212 FAIRVALE DR',
        RegAddrCity: 'BILLINGS',
        RegAddrState: 'MT',
        RegAddrZip: 59101,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '3203 US HIGHWAY 87 E',
        RegAddrCity: 'BILLINGS',
        RegAddrState: 'MT',
        RegAddrZip: 59101,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '3155 AVENUE C',
        RegAddrCity: 'BILLINGS',
        RegAddrState: 'MT',
        RegAddrZip: 59102,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '847 POLY DR',
        RegAddrCity: 'BILLINGS',
        RegAddrState: 'MT',
        RegAddrZip: 59102,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1015 COOK AVE',
        RegAddrCity: 'BILLINGS',
        RegAddrState: 'MT',
        RegAddrZip: 59102,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '223 S SANTA FE DR',
        RegAddrCity: 'BILLINGS',
        RegAddrState: 'MT',
        RegAddrZip: 59102,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1309 AVENUE E',
        RegAddrCity: 'BILLINGS',
        RegAddrState: 'MT',
        RegAddrZip: 59102,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '2300 ELIZABETH ST',
        RegAddrCity: 'BILLINGS',
        RegAddrState: 'MT',
        RegAddrZip: 59102,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '2511 EMERSON PL',
        RegAddrCity: 'BILLINGS',
        RegAddrState: 'MT',
        RegAddrZip: 59102,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '3634 DECATHLON PKWY',
        RegAddrCity: 'BILLINGS',
        RegAddrState: 'MT',
        RegAddrZip: 59102,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '2002 AVENUE D',
        RegAddrCity: 'BILLINGS',
        RegAddrState: 'MT',
        RegAddrZip: 59102,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1303 19TH ST W',
        RegAddrCity: 'BILLINGS',
        RegAddrState: 'MT',
        RegAddrZip: 59102,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '3580 GRANGER AVE W',
        RegAddrCity: 'BILLINGS',
        RegAddrState: 'MT',
        RegAddrZip: 59102,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '304 16TH ST W',
        RegAddrCity: 'BILLINGS',
        RegAddrState: 'MT',
        RegAddrZip: 59102,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '3684 SPARTAN CIR',
        RegAddrCity: 'BILLINGS',
        RegAddrState: 'MT',
        RegAddrZip: 59102,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '2635 HOWARD AVE',
        RegAddrCity: 'BILLINGS',
        RegAddrState: 'MT',
        RegAddrZip: 59102,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '2904 RADCLIFFE DR',
        RegAddrCity: 'BILLINGS',
        RegAddrState: 'MT',
        RegAddrZip: 59102,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '929 ARONSON AVE',
        RegAddrCity: 'BILLINGS',
        RegAddrState: 'MT',
        RegAddrZip: 59105,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '799 AQUARIUS PL',
        RegAddrCity: 'BILLINGS',
        RegAddrState: 'MT',
        RegAddrZip: 59105,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1639 WICKS LN',
        RegAddrCity: 'BILLINGS',
        RegAddrState: 'MT',
        RegAddrZip: 59105,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '153 WINDSOR CIR S',
        RegAddrCity: 'BILLINGS',
        RegAddrState: 'MT',
        RegAddrZip: 59105,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1726 BREWINGTON DR',
        RegAddrCity: 'BILLINGS',
        RegAddrState: 'MT',
        RegAddrZip: 59105,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '51 KING ARTHUR DR',
        RegAddrCity: 'BILLINGS',
        RegAddrState: 'MT',
        RegAddrZip: 59105,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '2701 MAST CIR',
        RegAddrCity: 'BILLINGS',
        RegAddrState: 'MT',
        RegAddrZip: 59105,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '419 LAURIE LN',
        RegAddrCity: 'BILLINGS',
        RegAddrState: 'MT',
        RegAddrZip: 59105,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '5238 WAGON CREEK LN',
        RegAddrCity: 'BILLINGS',
        RegAddrState: 'MT',
        RegAddrZip: 59105,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '927 WICKS LN',
        RegAddrCity: 'BILLINGS',
        RegAddrState: 'MT',
        RegAddrZip: 59105,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '709 KEY CITY DR',
        RegAddrCity: 'BILLINGS',
        RegAddrState: 'MT',
        RegAddrZip: 59105,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '2529 CONSTELLATION TRL',
        RegAddrCity: 'BILLINGS',
        RegAddrState: 'MT',
        RegAddrZip: 59105,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '8412 MONAD RD',
        RegAddrCity: 'BILLINGS',
        RegAddrState: 'MT',
        RegAddrZip: 59106,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '3044 LLOYD MANGRUM LN',
        RegAddrCity: 'BILLINGS',
        RegAddrState: 'MT',
        RegAddrZip: 59106,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '103 SADDLE TREE PL',
        RegAddrCity: 'BILLINGS',
        RegAddrState: 'MT',
        RegAddrZip: 59106,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '6028 SAM SNEAD TRL',
        RegAddrCity: 'BILLINGS',
        RegAddrState: 'MT',
        RegAddrZip: 59106,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '3217 FLAMINGO WAY',
        RegAddrCity: 'BILLINGS',
        RegAddrState: 'MT',
        RegAddrZip: 59106,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '4342 RANGEVIEW DR',
        RegAddrCity: 'BILLINGS',
        RegAddrState: 'MT',
        RegAddrZip: 59106,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '3203 WESTFIELD DR',
        RegAddrCity: 'BILLINGS',
        RegAddrState: 'MT',
        RegAddrZip: 59106,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '2601 BURLWOOD DR',
        RegAddrCity: 'BILLINGS',
        RegAddrState: 'MT',
        RegAddrZip: 59106,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '4719 GEORGINA DR',
        RegAddrCity: 'BILLINGS',
        RegAddrState: 'MT',
        RegAddrZip: 59106,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '28830 ARKANSAS CREEK RD E',
        RegAddrCity: 'BONNER',
        RegAddrState: 'MT',
        RegAddrZip: 59823,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '240 EAGLE ROCK RD',
        RegAddrCity: 'BOX ELDER',
        RegAddrState: 'MT',
        RegAddrZip: 59521,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '115 BITTERROOT DR',
        RegAddrCity: 'BOX ELDER',
        RegAddrState: 'MT',
        RegAddrZip: 59521,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '513 S FRESNO RD',
        RegAddrCity: 'BOX ELDER',
        RegAddrState: 'MT',
        RegAddrZip: 59521,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '34297 FRONTAGE RD',
        RegAddrCity: 'BOZEMAN',
        RegAddrState: 'MT',
        RegAddrZip: 59715,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '115 W STORY ST',
        RegAddrCity: 'BOZEMAN',
        RegAddrState: 'MT',
        RegAddrZip: 59715,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1208 N CEDARVIEW DR',
        RegAddrCity: 'BOZEMAN',
        RegAddrState: 'MT',
        RegAddrZip: 59715,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1102 S 3RD AVE',
        RegAddrCity: 'BOZEMAN',
        RegAddrState: 'MT',
        RegAddrZip: 59715,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '2750 RUSTY NAIL RD',
        RegAddrCity: 'BOZEMAN',
        RegAddrState: 'MT',
        RegAddrZip: 59715,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1410 S MONTANA AVE',
        RegAddrCity: 'BOZEMAN',
        RegAddrState: 'MT',
        RegAddrZip: 59715,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '200 S HEDGES HALL',
        RegAddrCity: 'BOZEMAN',
        RegAddrState: 'MT',
        RegAddrZip: 59715,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1109 S CHURCH AVE',
        RegAddrCity: 'BOZEMAN',
        RegAddrState: 'MT',
        RegAddrZip: 59715,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '517 1/2 N WILLSON AVE',
        RegAddrCity: 'BOZEMAN',
        RegAddrState: 'MT',
        RegAddrZip: 59715,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '510 1/2 W OLIVE ST',
        RegAddrCity: 'BOZEMAN',
        RegAddrState: 'MT',
        RegAddrZip: 59715,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '8136 ROLLING HILLS DR',
        RegAddrCity: 'BOZEMAN',
        RegAddrState: 'MT',
        RegAddrZip: 59715,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '6830 SYPES CANYON RD',
        RegAddrCity: 'BOZEMAN',
        RegAddrState: 'MT',
        RegAddrZip: 59715,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '79 MILKY WAY DR',
        RegAddrCity: 'BOZEMAN',
        RegAddrState: 'MT',
        RegAddrZip: 59718,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '157 S RELIANCE AVE',
        RegAddrCity: 'BOZEMAN',
        RegAddrState: 'MT',
        RegAddrZip: 59718,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '12974 COTTONWOOD RD',
        RegAddrCity: 'BOZEMAN',
        RegAddrState: 'MT',
        RegAddrZip: 59718,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '2521 W BABCOCK ST',
        RegAddrCity: 'BOZEMAN',
        RegAddrState: 'MT',
        RegAddrZip: 59718,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '228 SACAJAWEA PEAK DR',
        RegAddrCity: 'BOZEMAN',
        RegAddrState: 'MT',
        RegAddrZip: 59718,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '2987 ROSE ST',
        RegAddrCity: 'BOZEMAN',
        RegAddrState: 'MT',
        RegAddrZip: 59718,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '2752 DURSTON RD',
        RegAddrCity: 'BOZEMAN',
        RegAddrState: 'MT',
        RegAddrZip: 59718,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '311 DONEY WAY',
        RegAddrCity: 'BOZEMAN',
        RegAddrState: 'MT',
        RegAddrZip: 59718,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '16 E KIMBERLY CT',
        RegAddrCity: 'BOZEMAN',
        RegAddrState: 'MT',
        RegAddrZip: 59718,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '68 BUSHNELL RD',
        RegAddrCity: 'BOZEMAN',
        RegAddrState: 'MT',
        RegAddrZip: 59718,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '675 SUNNY ACRES DR',
        RegAddrCity: 'BOZEMAN',
        RegAddrState: 'MT',
        RegAddrZip: 59718,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '3614 ANNIE ST',
        RegAddrCity: 'BOZEMAN',
        RegAddrState: 'MT',
        RegAddrZip: 59718,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '316 N WESTERN DR',
        RegAddrCity: 'BOZEMAN',
        RegAddrState: 'MT',
        RegAddrZip: 59718,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '90 CHANCE RD',
        RegAddrCity: 'BRADY',
        RegAddrState: 'MT',
        RegAddrZip: 59416,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1546 MC ARTHUR RD',
        RegAddrCity: 'BRADY',
        RegAddrState: 'MT',
        RegAddrZip: 59416,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '',
        RegAddrCity: 'BROADUS',
        RegAddrState: 'MT',
        RegAddrZip: 59317,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '16726 HWY 3',
        RegAddrCity: 'BROADVIEW',
        RegAddrState: 'MT',
        RegAddrZip: 59015,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '108 SCOTTY AVE',
        RegAddrCity: 'BROWNING',
        RegAddrState: 'MT',
        RegAddrZip: 59417,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1703 N GLACIER HOMES',
        RegAddrCity: 'BROWNING',
        RegAddrState: 'MT',
        RegAddrZip: 59417,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '45 BADGER CREEK RD',
        RegAddrCity: 'BROWNING',
        RegAddrState: 'MT',
        RegAddrZip: 59417,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '5307 FLATIRON RD',
        RegAddrCity: 'BROWNING',
        RegAddrState: 'MT',
        RegAddrZip: 59417,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '435 HIGHWAY 464',
        RegAddrCity: 'BROWNING',
        RegAddrState: 'MT',
        RegAddrZip: 59417,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '2515 PLACER ST',
        RegAddrCity: 'BUTTE',
        RegAddrState: 'MT',
        RegAddrZip: 59701,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1109 W PARK ST',
        RegAddrCity: 'BUTTE',
        RegAddrState: 'MT',
        RegAddrZip: 59701,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '2855 NETTIE ST',
        RegAddrCity: 'BUTTE',
        RegAddrState: 'MT',
        RegAddrZip: 59701,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1524 A ST',
        RegAddrCity: 'BUTTE',
        RegAddrState: 'MT',
        RegAddrZip: 59701,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '3540 WHITE WAY',
        RegAddrCity: 'BUTTE',
        RegAddrState: 'MT',
        RegAddrZip: 59701,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1515 OREGON AVE',
        RegAddrCity: 'BUTTE',
        RegAddrState: 'MT',
        RegAddrZip: 59701,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '76 PRAIRIE DR',
        RegAddrCity: 'BUTTE',
        RegAddrState: 'MT',
        RegAddrZip: 59701,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '528 S DAKOTA ST',
        RegAddrCity: 'BUTTE',
        RegAddrState: 'MT',
        RegAddrZip: 59701,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '4840 HELENE DR',
        RegAddrCity: 'BUTTE',
        RegAddrState: 'MT',
        RegAddrZip: 59701,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '2820 STATE ST',
        RegAddrCity: 'BUTTE',
        RegAddrState: 'MT',
        RegAddrZip: 59701,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '216 S WASHINGTON ST',
        RegAddrCity: 'BUTTE',
        RegAddrState: 'MT',
        RegAddrZip: 59701,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '3014 PRINCETON ST',
        RegAddrCity: 'BUTTE',
        RegAddrState: 'MT',
        RegAddrZip: 59701,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1410 5TH ST',
        RegAddrCity: 'BUTTE',
        RegAddrState: 'MT',
        RegAddrZip: 59701,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '129 2ND ST N',
        RegAddrCity: 'CASCADE',
        RegAddrState: 'MT',
        RegAddrZip: 59421,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '31 1ST ST S',
        RegAddrCity: 'CASCADE',
        RegAddrState: 'MT',
        RegAddrZip: 59421,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '210 1ST ST W',
        RegAddrCity: 'CHARLO',
        RegAddrState: 'MT',
        RegAddrZip: 59824,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '115 2ND ST E',
        RegAddrCity: 'CHESTER',
        RegAddrState: 'MT',
        RegAddrZip: 59522,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '111 8TH ST W',
        RegAddrCity: 'CHINOOK',
        RegAddrState: 'MT',
        RegAddrZip: 59523,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '20 3RD AVE SW',
        RegAddrCity: 'CHOTEAU',
        RegAddrState: 'MT',
        RegAddrZip: 59422,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1004 4TH ST NW',
        RegAddrCity: 'CHOTEAU',
        RegAddrState: 'MT',
        RegAddrZip: 59422,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '20 1ST AVE SW',
        RegAddrCity: 'CHOTEAU',
        RegAddrState: 'MT',
        RegAddrZip: 59422,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '205 HOLMES GULCH RD',
        RegAddrCity: 'CLANCY',
        RegAddrState: 'MT',
        RegAddrZip: 59634,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '21275 E MULLAN RD',
        RegAddrCity: 'CLINTON',
        RegAddrState: 'MT',
        RegAddrZip: 59825,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '22650 WALLACE CREEK RD',
        RegAddrCity: 'CLINTON',
        RegAddrState: 'MT',
        RegAddrZip: 59825,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '6760 HOLLISTER DR',
        RegAddrCity: 'COLSTRIP',
        RegAddrState: 'MT',
        RegAddrZip: 59323,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '659 MARTINI LN',
        RegAddrCity: 'COLUMBIA FLS',
        RegAddrState: 'MT',
        RegAddrZip: 59912,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '878 LAKE DR',
        RegAddrCity: 'COLUMBIA FLS',
        RegAddrState: 'MT',
        RegAddrZip: 59912,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '34 11TH ST N',
        RegAddrCity: 'COLUMBUS',
        RegAddrState: 'MT',
        RegAddrZip: 59019,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '565 ARLEY LN',
        RegAddrCity: 'CORVALLIS',
        RegAddrState: 'MT',
        RegAddrZip: 59828,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '374 WOOD LN',
        RegAddrCity: 'CORVALLIS',
        RegAddrState: 'MT',
        RegAddrZip: 59828,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '620 3RD AVE',
        RegAddrCity: 'CUSTER',
        RegAddrState: 'MT',
        RegAddrZip: 59024,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '913 1ST ST SE',
        RegAddrCity: 'CUT BANK',
        RegAddrState: 'MT',
        RegAddrZip: 59427,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '302 2ND ST SE',
        RegAddrCity: 'CUT BANK',
        RegAddrState: 'MT',
        RegAddrZip: 59427,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '4574 EMERY RD',
        RegAddrCity: 'DEER LODGE',
        RegAddrState: 'MT',
        RegAddrZip: 59722,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '609 MILWAUKEE AVE',
        RegAddrCity: 'DEER LODGE',
        RegAddrState: 'MT',
        RegAddrZip: 59722,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '716 MILWAUKEE AVE',
        RegAddrCity: 'DEER LODGE',
        RegAddrState: 'MT',
        RegAddrZip: 59722,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '909 LEHMAN AVE',
        RegAddrCity: 'DENTON',
        RegAddrState: 'MT',
        RegAddrZip: 59430,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '120 AURORA LN',
        RegAddrCity: 'DILLON',
        RegAddrState: 'MT',
        RegAddrZip: 59725,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1350 STONE CREEK RD',
        RegAddrCity: 'DILLON',
        RegAddrState: 'MT',
        RegAddrZip: 59725,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '22825 MT HIGHWAY 278',
        RegAddrCity: 'DILLON',
        RegAddrState: 'MT',
        RegAddrZip: 59725,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '324 S DAKOTA ST',
        RegAddrCity: 'DILLON',
        RegAddrState: 'MT',
        RegAddrZip: 59725,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '420 MONTANA AVE',
        RegAddrCity: 'DODSON',
        RegAddrState: 'MT',
        RegAddrZip: 59524,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '160 W BROAD ST',
        RegAddrCity: 'DRUMMOND',
        RegAddrState: 'MT',
        RegAddrZip: 59832,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '',
        RegAddrCity: 'EAST GLACIER',
        RegAddrState: 'MT',
        RegAddrZip: 59434,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '3022 SKYLARK DR',
        RegAddrCity: 'EAST HELENA',
        RegAddrState: 'MT',
        RegAddrZip: 59635,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '114 N MONTANA AVE',
        RegAddrCity: 'EAST HELENA',
        RegAddrState: 'MT',
        RegAddrZip: 59635,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '235 N CENTRAL AVE',
        RegAddrCity: 'EKALAKA',
        RegAddrState: 'MT',
        RegAddrZip: 59324,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '751 6TH RD NE',
        RegAddrCity: 'FAIRFIELD',
        RegAddrState: 'MT',
        RegAddrZip: 59436,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '322 W 2ND ST',
        RegAddrCity: 'FAIRVIEW',
        RegAddrState: 'MT',
        RegAddrZip: 59221,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '',
        RegAddrCity: 'FLORENCE',
        RegAddrState: 'MT',
        RegAddrZip: 59833,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '622 OUT BACK RD',
        RegAddrCity: 'FLORENCE',
        RegAddrState: 'MT',
        RegAddrZip: 59833,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '6048 RAY LN',
        RegAddrCity: 'FLORENCE',
        RegAddrState: 'MT',
        RegAddrZip: 59833,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1218 WASHINGTON ST',
        RegAddrCity: 'FORT BENTON',
        RegAddrState: 'MT',
        RegAddrZip: 59442,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '90 SAGE DR',
        RegAddrCity: 'GALLATIN GATEWAY',
        RegAddrState: 'MT',
        RegAddrZip: 59730,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '299 OUSEL FALLS RD',
        RegAddrCity: 'GALLATIN GTWY',
        RegAddrState: 'MT',
        RegAddrZip: 59730,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '320 3RD AVE N',
        RegAddrCity: 'GLASGOW',
        RegAddrState: 'MT',
        RegAddrZip: 59230,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1240 2ND AVE S',
        RegAddrCity: 'GLASGOW',
        RegAddrState: 'MT',
        RegAddrZip: 59230,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '103 GARDEN TER',
        RegAddrCity: 'GLASGOW',
        RegAddrState: 'MT',
        RegAddrZip: 59230,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '507 CLAY ST',
        RegAddrCity: 'GLENDIVE',
        RegAddrState: 'MT',
        RegAddrZip: 59330,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '45 ROAD 261',
        RegAddrCity: 'GLENDIVE',
        RegAddrState: 'MT',
        RegAddrZip: 59330,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '205 JUNIPER AVE',
        RegAddrCity: 'GLENDIVE',
        RegAddrState: 'MT',
        RegAddrZip: 59330,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '821 B AVE E',
        RegAddrCity: 'GLENDIVE',
        RegAddrState: 'MT',
        RegAddrZip: 59330,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '374 MAPLE LEAF',
        RegAddrCity: 'GOLD CREEK',
        RegAddrState: 'MT',
        RegAddrZip: 59733,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '106009 US HIGHWAY 87',
        RegAddrCity: 'GRASS RANGE',
        RegAddrState: 'MT',
        RegAddrZip: 59032,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '606 7TH AVE N',
        RegAddrCity: 'GREAT FALLS',
        RegAddrState: 'MT',
        RegAddrZip: 59401,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1813 1ST AVE N',
        RegAddrCity: 'GREAT FALLS',
        RegAddrState: 'MT',
        RegAddrZip: 59401,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1122 8TH AVE N',
        RegAddrCity: 'GREAT FALLS',
        RegAddrState: 'MT',
        RegAddrZip: 59401,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1716 8TH AVE N',
        RegAddrCity: 'GREAT FALLS',
        RegAddrState: 'MT',
        RegAddrZip: 59401,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '2004 4TH AVE N',
        RegAddrCity: 'GREAT FALLS',
        RegAddrState: 'MT',
        RegAddrZip: 59401,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '255 DUNE DR',
        RegAddrCity: 'GREAT FALLS',
        RegAddrState: 'MT',
        RegAddrZip: 59404,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '423 27TH ST NW',
        RegAddrCity: 'GREAT FALLS',
        RegAddrState: 'MT',
        RegAddrZip: 59404,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '2781 GREENBRIAR DR',
        RegAddrCity: 'GREAT FALLS',
        RegAddrState: 'MT',
        RegAddrZip: 59404,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '924 36TH AVE NE',
        RegAddrCity: 'GREAT FALLS',
        RegAddrState: 'MT',
        RegAddrZip: 59404,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '181 HIGHWOOD DR',
        RegAddrCity: 'GREAT FALLS',
        RegAddrState: 'MT',
        RegAddrZip: 59404,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1323 10TH AVE SW',
        RegAddrCity: 'GREAT FALLS',
        RegAddrState: 'MT',
        RegAddrZip: 59404,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '119 MEADOWS LOOP',
        RegAddrCity: 'GREAT FALLS',
        RegAddrState: 'MT',
        RegAddrZip: 59404,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '410 VAN BUREN AVE',
        RegAddrCity: 'GREAT FALLS',
        RegAddrState: 'MT',
        RegAddrZip: 59404,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1109 HARRISON ST',
        RegAddrCity: 'GREAT FALLS',
        RegAddrState: 'MT',
        RegAddrZip: 59404,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '155 CHANDELLE LN',
        RegAddrCity: 'GREAT FALLS',
        RegAddrState: 'MT',
        RegAddrZip: 59404,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '4 PROSPECT DR',
        RegAddrCity: 'GREAT FALLS',
        RegAddrState: 'MT',
        RegAddrZip: 59405,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '3211 5TH AVE S',
        RegAddrCity: 'GREAT FALLS',
        RegAddrState: 'MT',
        RegAddrZip: 59405,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '4101 CENTRAL AVE',
        RegAddrCity: 'GREAT FALLS',
        RegAddrState: 'MT',
        RegAddrZip: 59405,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '4916 9TH AVE S',
        RegAddrCity: 'GREAT FALLS',
        RegAddrState: 'MT',
        RegAddrZip: 59405,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '351 CAROL DR',
        RegAddrCity: 'GREAT FALLS',
        RegAddrState: 'MT',
        RegAddrZip: 59405,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '5919 OLIVE CT',
        RegAddrCity: 'GREAT FALLS',
        RegAddrState: 'MT',
        RegAddrZip: 59405,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '128 MARILLYN DR',
        RegAddrCity: 'GREAT FALLS',
        RegAddrState: 'MT',
        RegAddrZip: 59405,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1317 15TH ST S',
        RegAddrCity: 'GREAT FALLS',
        RegAddrState: 'MT',
        RegAddrZip: 59405,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '2613 9TH AVE S',
        RegAddrCity: 'GREAT FALLS',
        RegAddrState: 'MT',
        RegAddrZip: 59405,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1119 WALNUT ST',
        RegAddrCity: 'GREAT FALLS',
        RegAddrState: 'MT',
        RegAddrZip: 59405,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '121 DANIEL CT',
        RegAddrCity: 'HAMILTON',
        RegAddrState: 'MT',
        RegAddrZip: 59840,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '534 DUTCH HILL RD',
        RegAddrCity: 'HAMILTON',
        RegAddrState: 'MT',
        RegAddrZip: 59840,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '865 BOWMAN RD',
        RegAddrCity: 'HAMILTON',
        RegAddrState: 'MT',
        RegAddrZip: 59840,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '225 CRESTWOOD LN',
        RegAddrCity: 'HAMILTON',
        RegAddrState: 'MT',
        RegAddrZip: 59840,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '712 W DESTA ST',
        RegAddrCity: 'HAMILTON',
        RegAddrState: 'MT',
        RegAddrZip: 59840,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1514 CROW CREEK RD',
        RegAddrCity: 'HAMMOND',
        RegAddrState: 'MT',
        RegAddrZip: 59332,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '220 12TH ST W',
        RegAddrCity: 'HARDIN',
        RegAddrState: 'MT',
        RegAddrZip: 59034,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1360 TURNER RD',
        RegAddrCity: 'HARLEM',
        RegAddrState: 'MT',
        RegAddrZip: 59526,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '34 WILLIAMS RD',
        RegAddrCity: 'HARRISON',
        RegAddrState: 'MT',
        RegAddrZip: 59735,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '624 9TH ST',
        RegAddrCity: 'HAVRE',
        RegAddrState: 'MT',
        RegAddrZip: 59501,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1556 3RD ST',
        RegAddrCity: 'HAVRE',
        RegAddrState: 'MT',
        RegAddrZip: 59501,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '5316 ASSINNIBOINE RD',
        RegAddrCity: 'HAVRE',
        RegAddrState: 'MT',
        RegAddrZip: 59501,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1236 CLEVELAND AVE',
        RegAddrCity: 'HAVRE',
        RegAddrState: 'MT',
        RegAddrZip: 59501,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1335 MONROE AVE',
        RegAddrCity: 'HAVRE',
        RegAddrState: 'MT',
        RegAddrZip: 59501,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '814 N RODNEY ST',
        RegAddrCity: 'HELENA',
        RegAddrState: 'MT',
        RegAddrZip: 59601,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '14 S RALEIGH ST',
        RegAddrCity: 'HELENA',
        RegAddrState: 'MT',
        RegAddrZip: 59601,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '2650 PARK DR',
        RegAddrCity: 'HELENA',
        RegAddrState: 'MT',
        RegAddrZip: 59601,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '726 E 6TH AVE',
        RegAddrCity: 'HELENA',
        RegAddrState: 'MT',
        RegAddrZip: 59601,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '503 TWILIGHT ST',
        RegAddrCity: 'HELENA',
        RegAddrState: 'MT',
        RegAddrZip: 59601,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '6570 W US HIGHWAY 12',
        RegAddrCity: 'HELENA',
        RegAddrState: 'MT',
        RegAddrZip: 59601,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '931 N WARREN ST',
        RegAddrCity: 'HELENA',
        RegAddrState: 'MT',
        RegAddrZip: 59601,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '157 FAIRWAY DR',
        RegAddrCity: 'HELENA',
        RegAddrState: 'MT',
        RegAddrZip: 59601,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1440 POPLAR ST',
        RegAddrCity: 'HELENA',
        RegAddrState: 'MT',
        RegAddrZip: 59601,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '2523 BELT VIEW DR',
        RegAddrCity: 'HELENA',
        RegAddrState: 'MT',
        RegAddrZip: 59601,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '537 5TH AVE',
        RegAddrCity: 'HELENA',
        RegAddrState: 'MT',
        RegAddrZip: 59601,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '2650 KNIGHT ST',
        RegAddrCity: 'HELENA',
        RegAddrState: 'MT',
        RegAddrZip: 59601,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '701 BRECKENRIDGE ST',
        RegAddrCity: 'HELENA',
        RegAddrState: 'MT',
        RegAddrZip: 59601,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '4574 SILVER CREEK RD',
        RegAddrCity: 'HELENA',
        RegAddrState: 'MT',
        RegAddrZip: 59602,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '4155 PANORAMA RD',
        RegAddrCity: 'HELENA',
        RegAddrState: 'MT',
        RegAddrZip: 59602,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '8745 TIMBERLINE DR',
        RegAddrCity: 'HELENA',
        RegAddrState: 'MT',
        RegAddrZip: 59602,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '3626 WYLIE DR',
        RegAddrCity: 'HELENA',
        RegAddrState: 'MT',
        RegAddrZip: 59602,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '3150 STILLWELL DR',
        RegAddrCity: 'HELENA',
        RegAddrState: 'MT',
        RegAddrZip: 59602,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '2765 SNOWDRIFT RD',
        RegAddrCity: 'HELENA',
        RegAddrState: 'MT',
        RegAddrZip: 59602,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '5526 YORK RD',
        RegAddrCity: 'HELENA',
        RegAddrState: 'MT',
        RegAddrZip: 59602,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1601 N BENTON AVE',
        RegAddrCity: 'HELENA',
        RegAddrState: 'MT',
        RegAddrZip: 59625,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1601 N BENTON AVE',
        RegAddrCity: 'HELENA',
        RegAddrState: 'MT',
        RegAddrZip: 59625,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1792 WINIFRED HWY',
        RegAddrCity: 'HILGER',
        RegAddrState: 'MT',
        RegAddrZip: 59451,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '140 MINNESOTA ST',
        RegAddrCity: 'HINSDALE',
        RegAddrState: 'MT',
        RegAddrZip: 59241,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '245 1ST AVE S',
        RegAddrCity: 'HUNGRY HORSE',
        RegAddrState: 'MT',
        RegAddrZip: 59919,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '2116 PURPLE SAGE TRL',
        RegAddrCity: 'HUNTLEY',
        RegAddrState: 'MT',
        RegAddrZip: 59037,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '17718 REMOUNT RD',
        RegAddrCity: 'HUSON',
        RegAddrState: 'MT',
        RegAddrZip: 59846,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '27087 RIVER RUN RD',
        RegAddrCity: 'HUSON',
        RegAddrState: 'MT',
        RegAddrZip: 59846,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '6200 SKINNER MEADOW RD',
        RegAddrCity: 'JACKSON',
        RegAddrState: 'MT',
        RegAddrZip: 59736,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '404 W FRONT AVE',
        RegAddrCity: 'JOLIET',
        RegAddrState: 'MT',
        RegAddrZip: 59041,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '',
        RegAddrCity: 'JUDITH GAP',
        RegAddrState: 'MT',
        RegAddrZip: 59453,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1146 4TH AVE E',
        RegAddrCity: 'KALISPELL',
        RegAddrState: 'MT',
        RegAddrZip: 59901,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '165 LUPINE DR',
        RegAddrCity: 'KALISPELL',
        RegAddrState: 'MT',
        RegAddrZip: 59901,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '495 ANDERSON LN',
        RegAddrCity: 'KALISPELL',
        RegAddrState: 'MT',
        RegAddrZip: 59901,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '845 E OREGON ST',
        RegAddrCity: 'KALISPELL',
        RegAddrState: 'MT',
        RegAddrZip: 59901,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1445 FOOTHILL RD',
        RegAddrCity: 'KALISPELL',
        RegAddrState: 'MT',
        RegAddrZip: 59901,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '176 ALI LOOP',
        RegAddrCity: 'KALISPELL',
        RegAddrState: 'MT',
        RegAddrZip: 59901,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '336 4TH AVE W',
        RegAddrCity: 'KALISPELL',
        RegAddrState: 'MT',
        RegAddrZip: 59901,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '655 PEBBLE DR',
        RegAddrCity: 'KALISPELL',
        RegAddrState: 'MT',
        RegAddrZip: 59901,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '23 PROSPERITY LN',
        RegAddrCity: 'KALISPELL',
        RegAddrState: 'MT',
        RegAddrZip: 59901,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '3700 LOWER VALLEY RD',
        RegAddrCity: 'KALISPELL',
        RegAddrState: 'MT',
        RegAddrZip: 59901,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '184 CHEVIOT LOOP',
        RegAddrCity: 'KALISPELL',
        RegAddrState: 'MT',
        RegAddrZip: 59901,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '52 WILLOW DR',
        RegAddrCity: 'KALISPELL',
        RegAddrState: 'MT',
        RegAddrZip: 59901,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '28 BAR H DR',
        RegAddrCity: 'KALISPELL',
        RegAddrState: 'MT',
        RegAddrZip: 59901,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '355 BLUE SPRUCE LN',
        RegAddrCity: 'KALISPELL',
        RegAddrState: 'MT',
        RegAddrZip: 59901,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '805 3RD AVE E',
        RegAddrCity: 'KALISPELL',
        RegAddrState: 'MT',
        RegAddrZip: 59901,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '136 GREENBRIAR DR',
        RegAddrCity: 'KALISPELL',
        RegAddrState: 'MT',
        RegAddrZip: 59901,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '677 SCENIC DR',
        RegAddrCity: 'KALISPELL',
        RegAddrState: 'MT',
        RegAddrZip: 59901,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '2134 MERGANSER DR',
        RegAddrCity: 'KALISPELL',
        RegAddrState: 'MT',
        RegAddrZip: 59901,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '844 BIRCH GROVE RD',
        RegAddrCity: 'KALISPELL',
        RegAddrState: 'MT',
        RegAddrZip: 59901,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '105 PALMER DR',
        RegAddrCity: 'KALISPELL',
        RegAddrState: 'MT',
        RegAddrZip: 59901,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '15 E NORTHVIEW LOOP',
        RegAddrCity: 'KALISPELL',
        RegAddrState: 'MT',
        RegAddrZip: 59901,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '111 GARLAND ST',
        RegAddrCity: 'KALISPELL',
        RegAddrState: 'MT',
        RegAddrZip: 59901,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '699 MOUNTAIN VIEW DR',
        RegAddrCity: 'KALISPELL',
        RegAddrState: 'MT',
        RegAddrZip: 59901,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '819 BOUNDARY ST',
        RegAddrCity: 'LAME DEER',
        RegAddrState: 'MT',
        RegAddrZip: 59043,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1026 GREAT NORTHERN RD',
        RegAddrCity: 'LAUREL',
        RegAddrState: 'MT',
        RegAddrZip: 59044,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '504 W 1ST ST',
        RegAddrCity: 'LAUREL',
        RegAddrState: 'MT',
        RegAddrZip: 59044,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1445 DOWNY RD',
        RegAddrCity: 'LAUREL',
        RegAddrState: 'MT',
        RegAddrZip: 59044,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1107 SUNHAVEN DR',
        RegAddrCity: 'LAUREL',
        RegAddrState: 'MT',
        RegAddrZip: 59044,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1030 13TH AVE',
        RegAddrCity: 'LAUREL',
        RegAddrState: 'MT',
        RegAddrZip: 59044,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '14 1ST AVE E',
        RegAddrCity: 'LAVINA',
        RegAddrState: 'MT',
        RegAddrZip: 59046,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '114 E LAKE AVE',
        RegAddrCity: 'LEWISTOWN',
        RegAddrState: 'MT',
        RegAddrZip: 59457,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '524 W EVELYN ST',
        RegAddrCity: 'LEWISTOWN',
        RegAddrState: 'MT',
        RegAddrZip: 59457,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '816 W EVELYN ST',
        RegAddrCity: 'LEWISTOWN',
        RegAddrState: 'MT',
        RegAddrZip: 59457,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '115 WHITE AVE',
        RegAddrCity: 'LIBBY',
        RegAddrState: 'MT',
        RegAddrZip: 59923,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '52 LAKEVIEW RD',
        RegAddrCity: 'LIBBY',
        RegAddrState: 'MT',
        RegAddrZip: 59923,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '414 NEVADA AVE',
        RegAddrCity: 'LIBBY',
        RegAddrState: 'MT',
        RegAddrZip: 59923,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '309 E LINCOLN BLVD',
        RegAddrCity: 'LIBBY',
        RegAddrState: 'MT',
        RegAddrZip: 59923,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '639 RESERVE RD',
        RegAddrCity: 'LIBBY',
        RegAddrState: 'MT',
        RegAddrZip: 59923,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '4098 DALTON MOUNTAIN RD',
        RegAddrCity: 'LINCOLN',
        RegAddrState: 'MT',
        RegAddrZip: 59639,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '320 W SUMMIT ST',
        RegAddrCity: 'LIVINGSTON',
        RegAddrState: 'MT',
        RegAddrZip: 59047,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '211 S 8TH ST',
        RegAddrCity: 'LIVINGSTON',
        RegAddrState: 'MT',
        RegAddrZip: 59047,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '317 S E ST',
        RegAddrCity: 'LIVINGSTON',
        RegAddrState: 'MT',
        RegAddrZip: 59047,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '615 CATTLE DRIVE LN',
        RegAddrCity: 'LLOYD',
        RegAddrState: 'MT',
        RegAddrZip: 59535,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '429 LAKESIDE DR',
        RegAddrCity: 'LOLO',
        RegAddrState: 'MT',
        RegAddrZip: 59847,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '10320 LAKEWOOD PL',
        RegAddrCity: 'LOLO',
        RegAddrState: 'MT',
        RegAddrZip: 59847,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '5568 EXPLORER CT',
        RegAddrCity: 'LOLO',
        RegAddrState: 'MT',
        RegAddrZip: 59847,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '59 HARNEY CREEK LN',
        RegAddrCity: 'LUTHER',
        RegAddrState: 'MT',
        RegAddrZip: 59068,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '105 N BROADWAY ST',
        RegAddrCity: 'MANHATTAN',
        RegAddrState: 'MT',
        RegAddrZip: 59741,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '957 BADGER HOLLOW TRL',
        RegAddrCity: 'MARION',
        RegAddrState: 'MT',
        RegAddrZip: 59925,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '519 MINNEHAN ST',
        RegAddrCity: 'MEDICINE LAKE',
        RegAddrState: 'MT',
        RegAddrZip: 59247,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1215 KNIGHT ST',
        RegAddrCity: 'MILES CITY',
        RegAddrState: 'MT',
        RegAddrZip: 59301,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '10630 VALLEY DR E',
        RegAddrCity: 'MILES CITY',
        RegAddrState: 'MT',
        RegAddrZip: 59301,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1400 TOMPY ST',
        RegAddrCity: 'MILES CITY',
        RegAddrState: 'MT',
        RegAddrZip: 59301,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1302 HIGHWAY 59 N',
        RegAddrCity: 'MILES CITY',
        RegAddrState: 'MT',
        RegAddrZip: 59301,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '2815 WILSON ST',
        RegAddrCity: 'MILES CITY',
        RegAddrState: 'MT',
        RegAddrZip: 59301,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '210 S WINCHESTER AVE',
        RegAddrCity: 'MILES CITY',
        RegAddrState: 'MT',
        RegAddrZip: 59301,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '912 S STACY AVE',
        RegAddrCity: 'MILES CITY',
        RegAddrState: 'MT',
        RegAddrZip: 59301,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '620 W BECKWITH ST E',
        RegAddrCity: 'MISSOULA',
        RegAddrState: 'MT',
        RegAddrZip: 59801,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '7 JOYCE DR',
        RegAddrCity: 'MISSOULA',
        RegAddrState: 'MT',
        RegAddrZip: 59801,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1545 W SUSSEX AVE',
        RegAddrCity: 'MISSOULA',
        RegAddrState: 'MT',
        RegAddrZip: 59801,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '533 E SUSSEX AVE',
        RegAddrCity: 'MISSOULA',
        RegAddrState: 'MT',
        RegAddrZip: 59801,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '2808 BANCROFT ST',
        RegAddrCity: 'MISSOULA',
        RegAddrState: 'MT',
        RegAddrZip: 59801,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '646 S 6TH ST W',
        RegAddrCity: 'MISSOULA',
        RegAddrState: 'MT',
        RegAddrZip: 59801,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1612 BEL AIR PL',
        RegAddrCity: 'MISSOULA',
        RegAddrState: 'MT',
        RegAddrZip: 59801,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1027 S 6TH ST W',
        RegAddrCity: 'MISSOULA',
        RegAddrState: 'MT',
        RegAddrZip: 59801,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '534 S 4TH ST W',
        RegAddrCity: 'MISSOULA',
        RegAddrState: 'MT',
        RegAddrZip: 59801,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '340 WOODWORTH AVE',
        RegAddrCity: 'MISSOULA',
        RegAddrState: 'MT',
        RegAddrZip: 59801,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '331 S 4TH ST W',
        RegAddrCity: 'MISSOULA',
        RegAddrState: 'MT',
        RegAddrZip: 59801,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1339 RIVER ST',
        RegAddrCity: 'MISSOULA',
        RegAddrState: 'MT',
        RegAddrZip: 59801,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1505 ST ANNS DR',
        RegAddrCity: 'MISSOULA',
        RegAddrState: 'MT',
        RegAddrZip: 59802,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1013 SHERWOOD ST',
        RegAddrCity: 'MISSOULA',
        RegAddrState: 'MT',
        RegAddrZip: 59802,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1038 TURNER ST',
        RegAddrCity: 'MISSOULA',
        RegAddrState: 'MT',
        RegAddrZip: 59802,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '514 W ALDER ST',
        RegAddrCity: 'MISSOULA',
        RegAddrState: 'MT',
        RegAddrZip: 59802,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '2501 WYLIE AVE',
        RegAddrCity: 'MISSOULA',
        RegAddrState: 'MT',
        RegAddrZip: 59802,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '115 SOMMERS ST',
        RegAddrCity: 'MISSOULA',
        RegAddrState: 'MT',
        RegAddrZip: 59802,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '7016 RATTLESNAKE DR',
        RegAddrCity: 'MISSOULA',
        RegAddrState: 'MT',
        RegAddrZip: 59802,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '508 MINNESOTA AVE',
        RegAddrCity: 'MISSOULA',
        RegAddrState: 'MT',
        RegAddrZip: 59802,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1203 SHERWOOD ST',
        RegAddrCity: 'MISSOULA',
        RegAddrState: 'MT',
        RegAddrZip: 59802,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '6020 APRIL LN',
        RegAddrCity: 'MISSOULA',
        RegAddrState: 'MT',
        RegAddrZip: 59803,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '5702 LONGVIEW DR',
        RegAddrCity: 'MISSOULA',
        RegAddrState: 'MT',
        RegAddrZip: 59803,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '6010 HAUGAN DR',
        RegAddrCity: 'MISSOULA',
        RegAddrState: 'MT',
        RegAddrZip: 59803,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '805 WHITAKER DR',
        RegAddrCity: 'MISSOULA',
        RegAddrState: 'MT',
        RegAddrZip: 59803,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '6045 APRIL LN',
        RegAddrCity: 'MISSOULA',
        RegAddrState: 'MT',
        RegAddrZip: 59803,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '2515 GUNSIGHT CT',
        RegAddrCity: 'MISSOULA',
        RegAddrState: 'MT',
        RegAddrZip: 59804,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '223 STONE ST',
        RegAddrCity: 'MISSOULA',
        RegAddrState: 'MT',
        RegAddrZip: 59804,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '11800 VIRGINIA LN',
        RegAddrCity: 'MISSOULA',
        RegAddrState: 'MT',
        RegAddrZip: 59804,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '9600 OBRIEN CREEK RD',
        RegAddrCity: 'MISSOULA',
        RegAddrState: 'MT',
        RegAddrZip: 59804,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '4700 SOUTH AVE W',
        RegAddrCity: 'MISSOULA',
        RegAddrState: 'MT',
        RegAddrZip: 59804,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '5412 BONANZA PL',
        RegAddrCity: 'MISSOULA',
        RegAddrState: 'MT',
        RegAddrZip: 59808,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '9705 GLACIER LILY CT',
        RegAddrCity: 'MISSOULA',
        RegAddrState: 'MT',
        RegAddrZip: 59808,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '7 EMERALD DR',
        RegAddrCity: 'MISSOULA',
        RegAddrState: 'MT',
        RegAddrZip: 59808,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1651 STILLWATER RIVER RD',
        RegAddrCity: 'NYE',
        RegAddrState: 'MT',
        RegAddrZip: 59061,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '733 WARREN CREEK RD',
        RegAddrCity: 'OVANDO',
        RegAddrState: 'MT',
        RegAddrZip: 59854,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '55 WESCHENFELDER RD',
        RegAddrCity: 'PARK CITY',
        RegAddrState: 'MT',
        RegAddrZip: 59063,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '119 WHITETAIL LN',
        RegAddrCity: 'PLAINS',
        RegAddrState: 'MT',
        RegAddrZip: 59859,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '95 OLD CEDAR CREEK RD',
        RegAddrCity: 'PLAINS',
        RegAddrState: 'MT',
        RegAddrZip: 59859,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '779 MT HIGHWAY 382',
        RegAddrCity: 'PLAINS',
        RegAddrState: 'MT',
        RegAddrZip: 59859,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '323 SHERIDAN ST',
        RegAddrCity: 'PLENTYWOOD',
        RegAddrState: 'MT',
        RegAddrZip: 59254,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '204 CEDAR AVE',
        RegAddrCity: 'PLENTYWOOD',
        RegAddrState: 'MT',
        RegAddrZip: 59254,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '265 POLEBRIDGE LOOP',
        RegAddrCity: 'POLEBRIDGE',
        RegAddrState: 'MT',
        RegAddrZip: 59928,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '880 TRAIL CREEK RD',
        RegAddrCity: 'POLEBRIDGE',
        RegAddrState: 'MT',
        RegAddrZip: 59928,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '28984 BLACK POINT RD',
        RegAddrCity: 'POLSON',
        RegAddrState: 'MT',
        RegAddrZip: 59860,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '702 5TH AVE E',
        RegAddrCity: 'POLSON',
        RegAddrState: 'MT',
        RegAddrZip: 59860,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '42113 SKID TRL',
        RegAddrCity: 'POLSON',
        RegAddrState: 'MT',
        RegAddrZip: 59860,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '37280 TURTLE LAKE RD',
        RegAddrCity: 'POLSON',
        RegAddrState: 'MT',
        RegAddrZip: 59860,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '107 BROADWAY',
        RegAddrCity: 'PONY',
        RegAddrState: 'MT',
        RegAddrZip: 59747,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '5989 ROAD 1054',
        RegAddrCity: 'POPLAR',
        RegAddrState: 'MT',
        RegAddrZip: 59255,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '30543 COUNTY ROAD 141',
        RegAddrCity: 'POPLAR',
        RegAddrState: 'MT',
        RegAddrZip: 59255,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '716 S GRANT AVE',
        RegAddrCity: 'RED LODGE',
        RegAddrState: 'MT',
        RegAddrZip: 59068,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1 S OAKES AVE',
        RegAddrCity: 'RED LODGE',
        RegAddrState: 'MT',
        RegAddrZip: 59068,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '209 S PLATT AVE',
        RegAddrCity: 'RED LODGE',
        RegAddrState: 'MT',
        RegAddrZip: 59068,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '221 1ST ST E',
        RegAddrCity: 'RICHEY',
        RegAddrState: 'MT',
        RegAddrZip: 59259,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '46451 STAGECOACH TRL',
        RegAddrCity: 'RONAN',
        RegAddrState: 'MT',
        RegAddrZip: 59864,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '42304 LEIGHTON RD',
        RegAddrCity: 'RONAN',
        RegAddrState: 'MT',
        RegAddrZip: 59864,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1422 CARTERSVILLE RD',
        RegAddrCity: 'ROSEBUD',
        RegAddrState: 'MT',
        RegAddrZip: 59347,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1124 2ND ST E',
        RegAddrCity: 'ROUNDUP',
        RegAddrState: 'MT',
        RegAddrZip: 59072,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '62 PINE CREST RD',
        RegAddrCity: 'ROUNDUP',
        RegAddrState: 'MT',
        RegAddrZip: 59072,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '510 1ST AVE E',
        RegAddrCity: 'RYEGATE',
        RegAddrState: 'MT',
        RegAddrZip: 59074,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '11251 COUNTY ROAD 345',
        RegAddrCity: 'SAVAGE',
        RegAddrState: 'MT',
        RegAddrZip: 59262,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '46 ROAD 526',
        RegAddrCity: 'SAVAGE',
        RegAddrState: 'MT',
        RegAddrZip: 59262,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '107 MILL ST',
        RegAddrCity: 'SCOBEY',
        RegAddrState: 'MT',
        RegAddrZip: 59263,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '514 COZY MEADOW LN',
        RegAddrCity: 'SHELBY',
        RegAddrState: 'MT',
        RegAddrZip: 59474,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '7425 EAGLE BEND BLVD',
        RegAddrCity: 'SHEPHERD',
        RegAddrState: 'MT',
        RegAddrZip: 59079,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '10807 C A RD',
        RegAddrCity: 'SHEPHERD',
        RegAddrState: 'MT',
        RegAddrZip: 59079,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '22 MOCCASIN',
        RegAddrCity: 'SHERIDAN',
        RegAddrState: 'MT',
        RegAddrZip: 59749,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '12260 COUNTY ROAD 348',
        RegAddrCity: 'SIDNEY',
        RegAddrState: 'MT',
        RegAddrZip: 59270,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '34637 COUNTY ROAD 117',
        RegAddrCity: 'SIDNEY',
        RegAddrState: 'MT',
        RegAddrZip: 59270,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '320 MARCO BAY RD',
        RegAddrCity: 'SOMERS',
        RegAddrState: 'MT',
        RegAddrZip: 59932,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '124 TOWEEPAH LN',
        RegAddrCity: 'ST IGNATIUS',
        RegAddrState: 'MT',
        RegAddrZip: 59865,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '340 MISSION DR',
        RegAddrCity: 'ST IGNATIUS',
        RegAddrState: 'MT',
        RegAddrZip: 59865,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '73015 US HIGHWAY 93',
        RegAddrCity: 'ST IGNATIUS',
        RegAddrState: 'MT',
        RegAddrZip: 59865,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '435 RED HILL RD',
        RegAddrCity: 'ST REGIS',
        RegAddrState: 'MT',
        RegAddrZip: 59866,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '403 PINE ST',
        RegAddrCity: 'STEVENSVILLE',
        RegAddrState: 'MT',
        RegAddrZip: 59870,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '469 N KOOTENAI CREEK RD',
        RegAddrCity: 'STEVENSVILLE',
        RegAddrState: 'MT',
        RegAddrZip: 59870,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '82 RIVER VISTA CT',
        RegAddrCity: 'STEVENSVILLE',
        RegAddrState: 'MT',
        RegAddrZip: 59870,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '8218 US HIGHWAY 93 S',
        RegAddrCity: 'SULA',
        RegAddrState: 'MT',
        RegAddrZip: 59871,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '51 BROCKWAY LN',
        RegAddrCity: 'SUPERIOR',
        RegAddrState: 'MT',
        RegAddrZip: 59872,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '41296 SALMON PRAIRIE LN',
        RegAddrCity: 'SWAN LAKE',
        RegAddrState: 'MT',
        RegAddrZip: 59911,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '115 CENTRAL AVE',
        RegAddrCity: 'SWEET GRASS',
        RegAddrState: 'MT',
        RegAddrZip: 59484,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '606 GARFIELD AVE S',
        RegAddrCity: 'TERRY',
        RegAddrState: 'MT',
        RegAddrZip: 59349,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '7447 OLD YELLOWSTONE TRL',
        RegAddrCity: 'THREE FORKS',
        RegAddrState: 'MT',
        RegAddrZip: 59752,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '7 E ADAMS ST',
        RegAddrCity: 'THREE FORKS',
        RegAddrState: 'MT',
        RegAddrZip: 59752,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '60 SHODDY SPRINGS RD',
        RegAddrCity: 'THREE FORKS',
        RegAddrState: 'MT',
        RegAddrZip: 59752,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '443 HOMESTEAD RD',
        RegAddrCity: 'THREE FORKS',
        RegAddrState: 'MT',
        RegAddrZip: 59752,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '44 MUDDY LANE RD',
        RegAddrCity: 'TOSTON',
        RegAddrState: 'MT',
        RegAddrZip: 59643,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '21 ALASKA TRL',
        RegAddrCity: 'TOWNSEND',
        RegAddrState: 'MT',
        RegAddrZip: 59644,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1700 FORTINE CREEK RD',
        RegAddrCity: 'TREGO',
        RegAddrState: 'MT',
        RegAddrZip: 59934,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '19 JEWEL LN',
        RegAddrCity: 'TROUT CREEK',
        RegAddrState: 'MT',
        RegAddrZip: 59874,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '3655 US HIGHWAY 2',
        RegAddrCity: 'TROY',
        RegAddrState: 'MT',
        RegAddrZip: 59935,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '337 SNOWSHOE LN',
        RegAddrCity: 'TROY',
        RegAddrState: 'MT',
        RegAddrZip: 59935,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '934 LUEBBE RD',
        RegAddrCity: 'ULM',
        RegAddrState: 'MT',
        RegAddrZip: 59485,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '107 4TH ST',
        RegAddrCity: 'VAUGHN',
        RegAddrState: 'MT',
        RegAddrZip: 59487,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '274 TUSHAPAW RD',
        RegAddrCity: 'VICTOR',
        RegAddrState: 'MT',
        RegAddrZip: 59875,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '121 MEADOW LN',
        RegAddrCity: 'VICTOR',
        RegAddrState: 'MT',
        RegAddrZip: 59875,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '823 BRAIG RD',
        RegAddrCity: 'WHITEFISH',
        RegAddrState: 'MT',
        RegAddrZip: 59937,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '3060 RIVER LAKES DR',
        RegAddrCity: 'WHITEFISH',
        RegAddrState: 'MT',
        RegAddrZip: 59937,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1146 2ND ST',
        RegAddrCity: 'WHITEFISH',
        RegAddrState: 'MT',
        RegAddrZip: 59937,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '355 TWIN BRIDGES RD',
        RegAddrCity: 'WHITEFISH',
        RegAddrState: 'MT',
        RegAddrZip: 59937,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '203 MINNESOTA AVE',
        RegAddrCity: 'WHITEFISH',
        RegAddrState: 'MT',
        RegAddrZip: 59937,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1856 LACY LN',
        RegAddrCity: 'WHITEFISH',
        RegAddrState: 'MT',
        RegAddrZip: 59937,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '246 MT HIGHWAY 2 E',
        RegAddrCity: 'WHITEHALL',
        RegAddrState: 'MT',
        RegAddrZip: 59759,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '77 YELLOWSTONE TRL',
        RegAddrCity: 'WHITEHALL',
        RegAddrState: 'MT',
        RegAddrZip: 59759,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1071 MT HIGHWAY 2 W',
        RegAddrCity: 'WHITEHALL',
        RegAddrState: 'MT',
        RegAddrZip: 59759,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '242 MT HIGHWAY 55',
        RegAddrCity: 'WHITEHALL',
        RegAddrState: 'MT',
        RegAddrZip: 59759,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '495 SEDAN RD',
        RegAddrCity: 'WILSALL',
        RegAddrState: 'MT',
        RegAddrZip: 59086,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '7562 BIA ROUTE 167',
        RegAddrCity: 'WOLF POINT',
        RegAddrState: 'MT',
        RegAddrZip: 59201,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '49 7TH AVE N',
        RegAddrCity: 'WOLF POINT',
        RegAddrState: 'MT',
        RegAddrZip: 59201,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '630 6TH AVE S',
        RegAddrCity: 'WOLF POINT',
        RegAddrState: 'MT',
        RegAddrZip: 59201,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '520 DAWSON ST',
        RegAddrCity: 'WOLF POINT',
        RegAddrState: 'MT',
        RegAddrZip: 59201,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1486 ASH ST',
        RegAddrCity: 'WORDEN',
        RegAddrState: 'MT',
        RegAddrZip: 59088,
        CongressionalDistrict: 0,
      },
    ],
  },
  {
    name: 'NC-1',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/NC-1?ref=gh-pages',
    state: 'NC',
    state_and_number: 'NC-1',
    zip: 23893,
    congressionalDistrict: 1,
    addressesInDistrict: [
      {
        RegAddrLine1: '133 MORRIS RD',
        RegAddrCity: 'AHOSKIE',
        RegAddrState: 'NC',
        RegAddrZip: 27910,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2528 REPUBLICAN RD',
        RegAddrCity: 'AULANDER',
        RegAddrState: 'NC',
        RegAddrZip: 27805,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '6080 DEANS ST',
        RegAddrCity: 'BAILEY',
        RegAddrState: 'NC',
        RegAddrZip: 27807,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '111 TOMS DR',
        RegAddrCity: 'DUDLEY',
        RegAddrState: 'NC',
        RegAddrZip: 28333,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '3647 LONGLEAF DR NE',
        RegAddrCity: 'ELM CITY',
        RegAddrState: 'NC',
        RegAddrZip: 27822,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '34 DENTON RD',
        RegAddrCity: 'ENFIELD',
        RegAddrState: 'NC',
        RegAddrZip: 27823,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '109 E DOE TRL',
        RegAddrCity: 'GOLDSBORO',
        RegAddrState: 'NC',
        RegAddrZip: 27530,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1217 OLD SMITHFIELD RD',
        RegAddrCity: 'GOLDSBORO',
        RegAddrState: 'NC',
        RegAddrZip: 27530,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '614 E RAINTREE LN',
        RegAddrCity: 'GOLDSBORO',
        RegAddrState: 'NC',
        RegAddrZip: 27534,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '889 PORTER RD',
        RegAddrCity: 'GREENVILLE',
        RegAddrState: 'NC',
        RegAddrZip: 27834,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '104 N LONGMEADOW RD',
        RegAddrCity: 'GREENVILLE',
        RegAddrState: 'NC',
        RegAddrZip: 27858,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '503 ALEXANDER AVE',
        RegAddrCity: 'HENDERSON',
        RegAddrState: 'NC',
        RegAddrZip: 27536,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '167 LINCOLN ST',
        RegAddrCity: 'HENDERSON',
        RegAddrState: 'NC',
        RegAddrZip: 27536,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '949 MEADOW LN',
        RegAddrCity: 'HENDERSON',
        RegAddrState: 'NC',
        RegAddrZip: 27536,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1100 CEDAR LN',
        RegAddrCity: 'JAMESVILLE',
        RegAddrState: 'NC',
        RegAddrZip: 27846,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '340 PINE ST',
        RegAddrCity: 'LEWISTON',
        RegAddrState: 'NC',
        RegAddrZip: 27849,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '153 BENTHALL BRIDGE RD',
        RegAddrCity: 'MURFREESBORO',
        RegAddrState: 'NC',
        RegAddrZip: 27855,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '9985 COOPER RD',
        RegAddrCity: 'NASHVILLE',
        RegAddrState: 'NC',
        RegAddrZip: 27856,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '3020 BARNES HILL CHURCH RD',
        RegAddrCity: 'NASHVILLE',
        RegAddrState: 'NC',
        RegAddrZip: 27856,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '116 TURNER CIR',
        RegAddrCity: 'NORLINA',
        RegAddrState: 'NC',
        RegAddrZip: 27563,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '101 US HIGHWAY 158',
        RegAddrCity: 'ROANOKE RAPID',
        RegAddrState: 'NC',
        RegAddrZip: 27870,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2044 CLARK RD',
        RegAddrCity: 'ROBERSONVILLE',
        RegAddrState: 'NC',
        RegAddrZip: 27871,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2419 VANCE ST',
        RegAddrCity: 'ROCKY MOUNT',
        RegAddrState: 'NC',
        RegAddrZip: 27801,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '4538 MORNING STAR CT',
        RegAddrCity: 'ROCKY MOUNT',
        RegAddrState: 'NC',
        RegAddrZip: 27804,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '307 LEASBURG RD',
        RegAddrCity: 'ROXBORO',
        RegAddrState: 'NC',
        RegAddrZip: 27573,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '735 MARK HERRING RD',
        RegAddrCity: 'SEVEN SPRINGS',
        RegAddrState: 'NC',
        RegAddrZip: 28578,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '3025 SHEEP PASTURE RD',
        RegAddrCity: 'SPRING HOPE',
        RegAddrState: 'NC',
        RegAddrZip: 27882,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '3259 CLEMMONS SCHOOL RD',
        RegAddrCity: 'STOKES',
        RegAddrState: 'NC',
        RegAddrZip: 27884,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1011 W HOWARD AVE',
        RegAddrCity: 'TARBORO',
        RegAddrState: 'NC',
        RegAddrZip: 27886,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '6556 GRESHAM LN',
        RegAddrCity: 'WASHINGTON',
        RegAddrState: 'NC',
        RegAddrZip: 27889,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '3106 BAILEY RD',
        RegAddrCity: 'WILLIAMSTON',
        RegAddrState: 'NC',
        RegAddrZip: 27892,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '5411 1ST RD',
        RegAddrCity: 'WILSON',
        RegAddrState: 'NC',
        RegAddrZip: 27893,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '105 PLAZA DR W',
        RegAddrCity: 'WILSON',
        RegAddrState: 'NC',
        RegAddrZip: 27893,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1712 MEADOWBROOK LN W',
        RegAddrCity: 'WILSON',
        RegAddrState: 'NC',
        RegAddrZip: 27893,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1011 OAK FOREST DR NW',
        RegAddrCity: 'WILSON',
        RegAddrState: 'NC',
        RegAddrZip: 27896,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '577 SHADOW RIDGE DR',
        RegAddrCity: 'WINTERVILLE',
        RegAddrState: 'NC',
        RegAddrZip: 28590,
        CongressionalDistrict: 1,
      },
    ],
  },
  {
    name: 'NC-10',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/NC-10?ref=gh-pages',
    state: 'NC',
    state_and_number: 'NC-10',
    zip: 28006,
    congressionalDistrict: 10,
    addressesInDistrict: [
      {
        RegAddrLine1: '1004 STUART LN',
        RegAddrCity: 'BOONVILLE',
        RegAddrState: 'NC',
        RegAddrZip: 27011,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '2180 WRIGHTS LAKE DR',
        RegAddrCity: 'CLEMMONS',
        RegAddrState: 'NC',
        RegAddrZip: 27012,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '4758 COUNTY HOME RD',
        RegAddrCity: 'CONOVER',
        RegAddrState: 'NC',
        RegAddrZip: 28613,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '5750 EARNEST DR',
        RegAddrCity: 'CONOVER',
        RegAddrState: 'NC',
        RegAddrZip: 28613,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '219 SHADY GROVE RD',
        RegAddrCity: 'EDEN',
        RegAddrState: 'NC',
        RegAddrZip: 27288,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '1018 E MEADOW RD',
        RegAddrCity: 'EDEN',
        RegAddrState: 'NC',
        RegAddrZip: 27288,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '11614 NC 268',
        RegAddrCity: 'ELKIN',
        RegAddrState: 'NC',
        RegAddrZip: 28621,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '477 17TH AVE NE',
        RegAddrCity: 'HICKORY',
        RegAddrState: 'NC',
        RegAddrZip: 28601,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '1430 JACKIE LN',
        RegAddrCity: 'HICKORY',
        RegAddrState: 'NC',
        RegAddrZip: 28601,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '625 7TH AVE NE',
        RegAddrCity: 'HICKORY',
        RegAddrState: 'NC',
        RegAddrZip: 28601,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '146 14TH ST SE',
        RegAddrCity: 'HICKORY',
        RegAddrState: 'NC',
        RegAddrZip: 28602,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '6603 G W CARSON RD',
        RegAddrCity: 'HICKORY',
        RegAddrState: 'NC',
        RegAddrZip: 28602,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '802 3RD ST SE',
        RegAddrCity: 'HICKORY',
        RegAddrState: 'NC',
        RegAddrZip: 28602,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '1108 PISGAH CHURCH RD',
        RegAddrCity: 'HIDDENITE',
        RegAddrState: 'NC',
        RegAddrZip: 28636,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '3514 RANDLEMAN RD',
        RegAddrCity: 'IRON STATION',
        RegAddrState: 'NC',
        RegAddrZip: 28080,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '350 OAKLAND BLVD',
        RegAddrCity: 'JONESVILLE',
        RegAddrState: 'NC',
        RegAddrZip: 28642,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '5648 CALDWELL RD',
        RegAddrCity: 'JONESVILLE',
        RegAddrState: 'NC',
        RegAddrZip: 28642,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '937 AQUAVIEW DR',
        RegAddrCity: 'KERNERSVILLE',
        RegAddrState: 'NC',
        RegAddrZip: 27284,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '6221 NC 66 HWY S',
        RegAddrCity: 'KING',
        RegAddrState: 'NC',
        RegAddrZip: 27021,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '1302 HELMS LN',
        RegAddrCity: 'LINCOLNTON',
        RegAddrState: 'NC',
        RegAddrZip: 28092,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '1563 HAMLET ST',
        RegAddrCity: 'LINCOLNTON',
        RegAddrState: 'NC',
        RegAddrZip: 28092,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '2647 GOLD RUSH DR',
        RegAddrCity: 'LINCOLNTON',
        RegAddrState: 'NC',
        RegAddrZip: 28092,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '4946 MAIDEN HWY',
        RegAddrCity: 'MAIDEN',
        RegAddrState: 'NC',
        RegAddrZip: 28650,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '230 BLOSSOM RIDGE DR',
        RegAddrCity: 'MOORESVILLE',
        RegAddrState: 'NC',
        RegAddrZip: 28117,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '134 LAKE SPRING LOOP',
        RegAddrCity: 'MOORESVILLE',
        RegAddrState: 'NC',
        RegAddrZip: 28117,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '199 GWYN AVE',
        RegAddrCity: 'MOUNT AIRY',
        RegAddrState: 'NC',
        RegAddrZip: 27030,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '113 HOPPER LN',
        RegAddrCity: 'MOUNT AIRY',
        RegAddrState: 'NC',
        RegAddrZip: 27030,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '270 GEITNER AVE',
        RegAddrCity: 'NEWTON',
        RegAddrState: 'NC',
        RegAddrZip: 28658,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '355 CLEO CAIN RD',
        RegAddrCity: 'PILOT MTN',
        RegAddrState: 'NC',
        RegAddrZip: 27041,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '1108 ARBOR LN',
        RegAddrCity: 'REIDSVILLE',
        RegAddrState: 'NC',
        RegAddrZip: 27320,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '1507 GIBBS RD',
        RegAddrCity: 'REIDSVILLE',
        RegAddrState: 'NC',
        RegAddrZip: 27320,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '495 ELLINGTON RD',
        RegAddrCity: 'REIDSVILLE',
        RegAddrState: 'NC',
        RegAddrZip: 27320,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '3130 NC 770 HWY',
        RegAddrCity: 'SANDY RIDGE',
        RegAddrState: 'NC',
        RegAddrZip: 27046,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '9561 ISLAND POINT RD',
        RegAddrCity: 'SHERRILLS FRD',
        RegAddrState: 'NC',
        RegAddrZip: 28673,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '7369 SEDGEBROOK DR W',
        RegAddrCity: 'STANLEY',
        RegAddrState: 'NC',
        RegAddrZip: 28164,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '135 GRAYSON PARK RD',
        RegAddrCity: 'STATESVILLE',
        RegAddrState: 'NC',
        RegAddrZip: 28625,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '309 DILLON DR',
        RegAddrCity: 'STATESVILLE',
        RegAddrState: 'NC',
        RegAddrZip: 28625,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '914 ROBYS PL',
        RegAddrCity: 'STATESVILLE',
        RegAddrState: 'NC',
        RegAddrZip: 28625,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '111 WHEATRIDGE DR',
        RegAddrCity: 'STATESVILLE',
        RegAddrState: 'NC',
        RegAddrZip: 28625,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '2420 E GREENBRIAR RD',
        RegAddrCity: 'STATESVILLE',
        RegAddrState: 'NC',
        RegAddrZip: 28625,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '305 WINONA ST',
        RegAddrCity: 'STATESVILLE',
        RegAddrState: 'NC',
        RegAddrZip: 28677,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '10152 US HIGHWAY 220',
        RegAddrCity: 'STONEVILLE',
        RegAddrState: 'NC',
        RegAddrZip: 27048,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '128 SUZUKI DR',
        RegAddrCity: 'TROUTMAN',
        RegAddrState: 'NC',
        RegAddrZip: 28166,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '6673 RIVERTON RD',
        RegAddrCity: 'WALKERTOWN',
        RegAddrState: 'NC',
        RegAddrZip: 27051,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '1020 SHADY HOLLOW DR',
        RegAddrCity: 'WALNUT COVE',
        RegAddrState: 'NC',
        RegAddrZip: 27052,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '1062 PUCKETT RD',
        RegAddrCity: 'WESTFIELD',
        RegAddrState: 'NC',
        RegAddrZip: 27053,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '4935 FLEETWOOD CIR',
        RegAddrCity: 'WINSTON SALEM',
        RegAddrState: 'NC',
        RegAddrZip: 27106,
        CongressionalDistrict: 10,
      },
    ],
  },
  {
    name: 'NC-11',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/NC-11?ref=gh-pages',
    state: 'NC',
    state_and_number: 'NC-11',
    zip: 28619,
    congressionalDistrict: 11,
    addressesInDistrict: [
      {
        RegAddrLine1: '9 BROOKSIDE RD',
        RegAddrCity: 'ASHEVILLE',
        RegAddrState: 'NC',
        RegAddrZip: 28803,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '9 HOLLY HILL RD',
        RegAddrCity: 'ASHEVILLE',
        RegAddrState: 'NC',
        RegAddrZip: 28803,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '38 CLUBSIDE DR',
        RegAddrCity: 'ASHEVILLE',
        RegAddrState: 'NC',
        RegAddrZip: 28804,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '428 LOWER GRASSY BRANCH RD',
        RegAddrCity: 'ASHEVILLE',
        RegAddrState: 'NC',
        RegAddrZip: 28805,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '26 IMPALA TRL',
        RegAddrCity: 'ASHEVILLE',
        RegAddrState: 'NC',
        RegAddrZip: 28806,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '38 MALLARD DR',
        RegAddrCity: 'ASHEVILLE',
        RegAddrState: 'NC',
        RegAddrZip: 28806,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '108 ROCK CHURCH ST',
        RegAddrCity: 'BLACK MTN',
        RegAddrState: 'NC',
        RegAddrZip: 28711,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '619 HAMMER TOWN RD',
        RegAddrCity: 'BURNSVILLE',
        RegAddrState: 'NC',
        RegAddrZip: 28714,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '23 WINTER CREST DR',
        RegAddrCity: 'CANDLER',
        RegAddrState: 'NC',
        RegAddrZip: 28715,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '11 ASBURY ACRES DR',
        RegAddrCity: 'CANDLER',
        RegAddrState: 'NC',
        RegAddrZip: 28715,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '59 TRAMMELL AVE',
        RegAddrCity: 'CANTON',
        RegAddrState: 'NC',
        RegAddrZip: 28716,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '499 MEMORIAL DR',
        RegAddrCity: 'CULLOWHEE',
        RegAddrState: 'NC',
        RegAddrZip: 28723,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '134 MOCKINGBIRD HILL DR',
        RegAddrCity: 'ETOWAH',
        RegAddrState: 'NC',
        RegAddrZip: 28729,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '958 GARREN CREEK RD',
        RegAddrCity: 'FAIRVIEW',
        RegAddrState: 'NC',
        RegAddrZip: 28730,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '23 LAURELWOOD DR',
        RegAddrCity: 'FLETCHER',
        RegAddrState: 'NC',
        RegAddrZip: 28732,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '51 FOWLER LN',
        RegAddrCity: 'FLETCHER',
        RegAddrState: 'NC',
        RegAddrZip: 28732,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '266 JACKSON RD',
        RegAddrCity: 'FLETCHER',
        RegAddrState: 'NC',
        RegAddrZip: 28732,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '123 EDGEWOOD AVE',
        RegAddrCity: 'FRANKLIN',
        RegAddrState: 'NC',
        RegAddrZip: 28734,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '302 CABIN HILL RD',
        RegAddrCity: 'GREEN MTN',
        RegAddrState: 'NC',
        RegAddrZip: 28740,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '103 HOLLY TREE CIR',
        RegAddrCity: 'HENDERSONVILLE',
        RegAddrState: 'NC',
        RegAddrZip: 28792,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '1111 DAVIS MOUNTAIN RD',
        RegAddrCity: 'HENDERSONVLLE',
        RegAddrState: 'NC',
        RegAddrZip: 28739,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '126 EXETER CT',
        RegAddrCity: 'HENDERSONVLLE',
        RegAddrState: 'NC',
        RegAddrZip: 28791,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '192 KINGSWOOD DR',
        RegAddrCity: 'HENDERSONVLLE',
        RegAddrState: 'NC',
        RegAddrZip: 28792,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '420 SHORTOFF RD',
        RegAddrCity: 'HIGHLANDS',
        RegAddrState: 'NC',
        RegAddrZip: 28741,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '226 STUART CIR',
        RegAddrCity: 'LK JUNALUSKA',
        RegAddrState: 'NC',
        RegAddrZip: 28745,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '758 DEEP WOODS DR',
        RegAddrCity: 'MARION',
        RegAddrState: 'NC',
        RegAddrZip: 28752,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '1059 SHADY LN',
        RegAddrCity: 'MARION',
        RegAddrState: 'NC',
        RegAddrZip: 28752,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '685 WORLEY RD',
        RegAddrCity: 'MARION',
        RegAddrState: 'NC',
        RegAddrZip: 28752,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '131 BOYD COVE RD',
        RegAddrCity: 'MARSHALL',
        RegAddrState: 'NC',
        RegAddrZip: 28753,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '55 POSSUM PASS',
        RegAddrCity: 'MURPHY',
        RegAddrState: 'NC',
        RegAddrZip: 28906,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '2739 S US 19E HWY',
        RegAddrCity: 'NEWLAND',
        RegAddrState: 'NC',
        RegAddrZip: 28657,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '1810 COWEETA LAB RD',
        RegAddrCity: 'OTTO',
        RegAddrState: 'NC',
        RegAddrZip: 28763,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '968 GOLD MINE RD',
        RegAddrCity: 'ROBBINSVILLE',
        RegAddrState: 'NC',
        RegAddrZip: 28771,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '168 TINY CREEK DR',
        RegAddrCity: 'RUTHERFORDTON',
        RegAddrState: 'NC',
        RegAddrZip: 28139,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '17 SUNSET CT',
        RegAddrCity: 'SAPPHIRE',
        RegAddrState: 'NC',
        RegAddrZip: 28774,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '7 WARBLERS NEST',
        RegAddrCity: 'SWANNANOA',
        RegAddrState: 'NC',
        RegAddrZip: 28778,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '39 BREEZY MOUNTAIN RD',
        RegAddrCity: 'SYLVA',
        RegAddrState: 'NC',
        RegAddrZip: 28779,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '460 LONG VIEW RD',
        RegAddrCity: 'SYLVA',
        RegAddrState: 'NC',
        RegAddrZip: 28779,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '574 GOLDEN RD',
        RegAddrCity: 'TRYON',
        RegAddrState: 'NC',
        RegAddrZip: 28782,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '286 LAWING MILL RD',
        RegAddrCity: 'UNION MILLS',
        RegAddrState: 'NC',
        RegAddrZip: 28167,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '55 ROUNDTOP RD',
        RegAddrCity: 'WAYNESVILLE',
        RegAddrState: 'NC',
        RegAddrZip: 28785,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '887 JONATHAN CREEK RD',
        RegAddrCity: 'WAYNESVILLE',
        RegAddrState: 'NC',
        RegAddrZip: 28785,
        CongressionalDistrict: 11,
      },
    ],
  },
  {
    name: 'NC-12',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/NC-12?ref=gh-pages',
    state: 'NC',
    state_and_number: 'NC-12',
    zip: 28031,
    congressionalDistrict: 12,
    addressesInDistrict: [
      {
        RegAddrLine1: '425 W 5TH ST',
        RegAddrCity: 'CHARLOTTE',
        RegAddrState: 'NC',
        RegAddrZip: 28202,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '1503 COLLIER CT',
        RegAddrCity: 'CHARLOTTE',
        RegAddrState: 'NC',
        RegAddrZip: 28205,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '2727 SHAMROCK DR',
        RegAddrCity: 'CHARLOTTE',
        RegAddrState: 'NC',
        RegAddrZip: 28205,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '4000 HIDDENBROOK DR',
        RegAddrCity: 'CHARLOTTE',
        RegAddrState: 'NC',
        RegAddrZip: 28205,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '2524 ELKWOOD CIR',
        RegAddrCity: 'CHARLOTTE',
        RegAddrState: 'NC',
        RegAddrZip: 28205,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '1929 DUNHILL DR',
        RegAddrCity: 'CHARLOTTE',
        RegAddrState: 'NC',
        RegAddrZip: 28205,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '4509 GAINESBOROUGH RD',
        RegAddrCity: 'CHARLOTTE',
        RegAddrState: 'NC',
        RegAddrZip: 28205,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '3908 GLORY ST',
        RegAddrCity: 'CHARLOTTE',
        RegAddrState: 'NC',
        RegAddrZip: 28206,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '2135 MALVERN RD',
        RegAddrCity: 'CHARLOTTE',
        RegAddrState: 'NC',
        RegAddrZip: 28207,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '1319 SANTEE ST',
        RegAddrCity: 'CHARLOTTE',
        RegAddrState: 'NC',
        RegAddrZip: 28208,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '839 HEATHER LN',
        RegAddrCity: 'CHARLOTTE',
        RegAddrState: 'NC',
        RegAddrZip: 28209,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '3817 SELWYN AVE',
        RegAddrCity: 'CHARLOTTE',
        RegAddrState: 'NC',
        RegAddrZip: 28209,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '1143 SHADOWBROOK LN',
        RegAddrCity: 'CHARLOTTE',
        RegAddrState: 'NC',
        RegAddrZip: 28211,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '7410 HILLBOURN DR',
        RegAddrCity: 'CHARLOTTE',
        RegAddrState: 'NC',
        RegAddrZip: 28212,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '10123 BRADSTREET COMMONS WAY',
        RegAddrCity: 'CHARLOTTE',
        RegAddrState: 'NC',
        RegAddrZip: 28215,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '10215 BRADSTREET COMMONS WAY',
        RegAddrCity: 'CHARLOTTE',
        RegAddrState: 'NC',
        RegAddrZip: 28215,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '5120 TRACEWOOD CT',
        RegAddrCity: 'CHARLOTTE',
        RegAddrState: 'NC',
        RegAddrZip: 28215,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '7002 COBLE AVE',
        RegAddrCity: 'CHARLOTTE',
        RegAddrState: 'NC',
        RegAddrZip: 28215,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '6328 ELLIOTT DR',
        RegAddrCity: 'CHARLOTTE',
        RegAddrState: 'NC',
        RegAddrZip: 28216,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '4127 DOVER DOWNS DR',
        RegAddrCity: 'CHARLOTTE',
        RegAddrState: 'NC',
        RegAddrZip: 28216,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '1126 ROCK HAVEN DR',
        RegAddrCity: 'CHARLOTTE',
        RegAddrState: 'NC',
        RegAddrZip: 28216,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '9201 UNIVERSITY CITY BLVD',
        RegAddrCity: 'CHARLOTTE',
        RegAddrState: 'NC',
        RegAddrZip: 28223,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '9102 MAGNOLIA LILY AVE',
        RegAddrCity: 'CHARLOTTE',
        RegAddrState: 'NC',
        RegAddrZip: 28227,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '3719 OTTINGTON PL',
        RegAddrCity: 'CHARLOTTE',
        RegAddrState: 'NC',
        RegAddrZip: 28262,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '13511 PORTER CREEK RD',
        RegAddrCity: 'CHARLOTTE',
        RegAddrState: 'NC',
        RegAddrZip: 28262,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '1711 THOMAS COMBS DR',
        RegAddrCity: 'CHARLOTTE',
        RegAddrState: 'NC',
        RegAddrZip: 28262,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '4725 PINELEAF DR',
        RegAddrCity: 'CHARLOTTE',
        RegAddrState: 'NC',
        RegAddrZip: 28269,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '6226 DUMONT LN',
        RegAddrCity: 'CHARLOTTE',
        RegAddrState: 'NC',
        RegAddrZip: 28269,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '13813 POPPLETON CT',
        RegAddrCity: 'CHARLOTTE',
        RegAddrState: 'NC',
        RegAddrZip: 28273,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '14900 SHOPTON RD W',
        RegAddrCity: 'CHARLOTTE',
        RegAddrState: 'NC',
        RegAddrZip: 28278,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '18605 REFLECTION ROCK CT',
        RegAddrCity: 'DAVIDSON',
        RegAddrState: 'NC',
        RegAddrZip: 28036,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '9406 HARLOW CREEK RD',
        RegAddrCity: 'HUNTERSVILLE',
        RegAddrState: 'NC',
        RegAddrZip: 28078,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '7728 EPPING FOREST DR',
        RegAddrCity: 'HUNTERSVILLE',
        RegAddrState: 'NC',
        RegAddrZip: 28078,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '3600 CEDARBARK DR',
        RegAddrCity: 'MATTHEWS',
        RegAddrState: 'NC',
        RegAddrZip: 28105,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '407 MORNING DALE RD',
        RegAddrCity: 'MATTHEWS',
        RegAddrState: 'NC',
        RegAddrZip: 28105,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '9017 GLADDEN HILL LN',
        RegAddrCity: 'PINEVILLE',
        RegAddrState: 'NC',
        RegAddrZip: 28134,
        CongressionalDistrict: 12,
      },
    ],
  },
  {
    name: 'NC-13',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/NC-13?ref=gh-pages',
    state: 'NC',
    state_and_number: 'NC-13',
    zip: 27006,
    congressionalDistrict: 13,
    addressesInDistrict: [
      {
        RegAddrLine1: '568 BALTIMORE RD',
        RegAddrCity: 'ADVANCE',
        RegAddrState: 'NC',
        RegAddrZip: 27006,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '309 GARDINER RD',
        RegAddrCity: 'ASHEBORO',
        RegAddrState: 'NC',
        RegAddrZip: 27203,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '1342 TWIN CRYSTAL TRL',
        RegAddrCity: 'ASHEBORO',
        RegAddrState: 'NC',
        RegAddrZip: 27205,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '112 WEST ST',
        RegAddrCity: 'ASHEBORO',
        RegAddrState: 'NC',
        RegAddrZip: 27205,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '2766 OLD HUMBLE MILL RD',
        RegAddrCity: 'ASHEBORO',
        RegAddrState: 'NC',
        RegAddrZip: 27205,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '2205 RACE TRACK RD',
        RegAddrCity: 'BURLINGTON',
        RegAddrState: 'NC',
        RegAddrZip: 27215,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '1489 FARM HOUSE TRL',
        RegAddrCity: 'BURLINGTON',
        RegAddrState: 'NC',
        RegAddrZip: 27215,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '4608 MEADOWLARK CT',
        RegAddrCity: 'BURLINGTON',
        RegAddrState: 'NC',
        RegAddrZip: 27215,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '2602 PASSAVANT CT',
        RegAddrCity: 'BURLINGTON',
        RegAddrState: 'NC',
        RegAddrZip: 27215,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '2745 WESTCHESTER DR',
        RegAddrCity: 'BURLINGTON',
        RegAddrState: 'NC',
        RegAddrZip: 27217,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '1960 DURHAM ST',
        RegAddrCity: 'BURLINGTON',
        RegAddrState: 'NC',
        RegAddrZip: 27217,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '289 MOONSTONE CT',
        RegAddrCity: 'GIBSONVILLE',
        RegAddrState: 'NC',
        RegAddrZip: 27249,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '1355 HANFORD HILLS RD',
        RegAddrCity: 'GRAHAM',
        RegAddrState: 'NC',
        RegAddrZip: 27253,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '1855 MURPHY CT',
        RegAddrCity: 'GRAHAM',
        RegAddrState: 'NC',
        RegAddrZip: 27253,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '725 SPINNING WHEEL PT',
        RegAddrCity: 'HIGH POINT',
        RegAddrState: 'NC',
        RegAddrZip: 27265,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '906 CHINA GROVE RD',
        RegAddrCity: 'KANNAPOLIS',
        RegAddrState: 'NC',
        RegAddrZip: 28083,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '148 COYOTE TRL',
        RegAddrCity: 'LEXINGTON',
        RegAddrState: 'NC',
        RegAddrZip: 27292,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '262 JOHN T FARABEE LN',
        RegAddrCity: 'LEXINGTON',
        RegAddrState: 'NC',
        RegAddrZip: 27295,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '279 B R HUNT RD',
        RegAddrCity: 'LEXINGTON',
        RegAddrState: 'NC',
        RegAddrZip: 27295,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '561 ARNOLD RD',
        RegAddrCity: 'LEXINGTON',
        RegAddrState: 'NC',
        RegAddrZip: 27295,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '5350 OSCAR GAMMON RD',
        RegAddrCity: 'MEBANE',
        RegAddrState: 'NC',
        RegAddrZip: 27302,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '5655 NC HIGHWAY 801 S',
        RegAddrCity: 'MOCKSVILLE',
        RegAddrState: 'NC',
        RegAddrZip: 27028,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '141 MURCHISON RD',
        RegAddrCity: 'MOCKSVILLE',
        RegAddrState: 'NC',
        RegAddrZip: 27028,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '191 SCOTTISH DR',
        RegAddrCity: 'MOORESVILLE',
        RegAddrState: 'NC',
        RegAddrZip: 28115,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '1087 FLAT RIVER CHURCH RD',
        RegAddrCity: 'ROXBORO',
        RegAddrState: 'NC',
        RegAddrZip: 27574,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '701 W MONROE ST',
        RegAddrCity: 'SALISBURY',
        RegAddrState: 'NC',
        RegAddrZip: 28144,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '195 BONANZA DR',
        RegAddrCity: 'SALISBURY',
        RegAddrState: 'NC',
        RegAddrZip: 28144,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '410 VANCE AVE',
        RegAddrCity: 'SALISBURY',
        RegAddrState: 'NC',
        RegAddrZip: 28144,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '1240 CHEZ CHAROLAIS RD',
        RegAddrCity: 'SALISBURY',
        RegAddrState: 'NC',
        RegAddrZip: 28146,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '1518 DOGWOOD ACRES DR',
        RegAddrCity: 'SANFORD',
        RegAddrState: 'NC',
        RegAddrZip: 27330,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '2213 SUTPHIN RD',
        RegAddrCity: 'SANFORD',
        RegAddrState: 'NC',
        RegAddrZip: 27330,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '1583 HENLEY RD',
        RegAddrCity: 'SANFORD',
        RegAddrState: 'NC',
        RegAddrZip: 27330,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '3984 MANESS RD',
        RegAddrCity: 'SEAGROVE',
        RegAddrState: 'NC',
        RegAddrZip: 27341,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '1106 SUMMITT DR',
        RegAddrCity: 'SILER CITY',
        RegAddrState: 'NC',
        RegAddrZip: 27344,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '1065 JOE MOORE RD',
        RegAddrCity: 'THOMASVILLE',
        RegAddrState: 'NC',
        RegAddrZip: 27360,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '525 BALL RD',
        RegAddrCity: 'THOMASVILLE',
        RegAddrState: 'NC',
        RegAddrZip: 27360,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '1553 ROSS WOOD RD',
        RegAddrCity: 'TRINITY',
        RegAddrState: 'NC',
        RegAddrZip: 27370,
        CongressionalDistrict: 13,
      },
    ],
  },
  {
    name: 'NC-2',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/NC-2?ref=gh-pages',
    state: 'NC',
    state_and_number: 'NC-2',
    zip: 27330,
    congressionalDistrict: 2,
    addressesInDistrict: [
      {
        RegAddrLine1: '1472 BIG LEAF LOOP',
        RegAddrCity: 'APEX',
        RegAddrState: 'NC',
        RegAddrZip: 27502,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2016 KELLY GLEN DR',
        RegAddrCity: 'APEX',
        RegAddrState: 'NC',
        RegAddrZip: 27502,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '4001 GREYHAWK PL',
        RegAddrCity: 'APEX',
        RegAddrState: 'NC',
        RegAddrZip: 27539,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '508 STONECREEK DR',
        RegAddrCity: 'APEX',
        RegAddrState: 'NC',
        RegAddrZip: 27539,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '826 MALCOLM VALLEY PL',
        RegAddrCity: 'CARY',
        RegAddrState: 'NC',
        RegAddrZip: 27511,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1310 DOYLIN DR',
        RegAddrCity: 'CARY',
        RegAddrState: 'NC',
        RegAddrZip: 27511,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '109 VALENTA CT',
        RegAddrCity: 'CARY',
        RegAddrState: 'NC',
        RegAddrZip: 27513,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '373 ROBERTS RIDGE DR',
        RegAddrCity: 'CARY',
        RegAddrState: 'NC',
        RegAddrZip: 27513,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '119 PARSON WOODS LN',
        RegAddrCity: 'CARY',
        RegAddrState: 'NC',
        RegAddrZip: 27518,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '714 AFTON MEADOW LN',
        RegAddrCity: 'CARY',
        RegAddrState: 'NC',
        RegAddrZip: 27518,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '255 NORTHLANDS DR',
        RegAddrCity: 'CARY',
        RegAddrState: 'NC',
        RegAddrZip: 27519,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '127 LANTERN RIDGE LN',
        RegAddrCity: 'CARY',
        RegAddrState: 'NC',
        RegAddrZip: 27519,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2605 HIDDEN MEADOW DR',
        RegAddrCity: 'FUQUAY VARINA',
        RegAddrState: 'NC',
        RegAddrZip: 27526,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1606 KENBROOK DR',
        RegAddrCity: 'GARNER',
        RegAddrState: 'NC',
        RegAddrZip: 27529,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '108 BRUSH HILL LN',
        RegAddrCity: 'HOLLY SPRINGS',
        RegAddrState: 'NC',
        RegAddrZip: 27540,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '505 BARBADOS CT',
        RegAddrCity: 'HOLLY SPRINGS',
        RegAddrState: 'NC',
        RegAddrZip: 27540,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '109 MEADOWCREST PL',
        RegAddrCity: 'HOLLY SPRINGS',
        RegAddrState: 'NC',
        RegAddrZip: 27540,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '100 TEMPLE DR',
        RegAddrCity: 'KNIGHTDALE',
        RegAddrState: 'NC',
        RegAddrZip: 27545,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '5209 TRACKWAY DR',
        RegAddrCity: 'KNIGHTDALE',
        RegAddrState: 'NC',
        RegAddrZip: 27545,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '3121 RISE DR',
        RegAddrCity: 'MORRISVILLE',
        RegAddrState: 'NC',
        RegAddrZip: 27560,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1513 HEMBY RIDGE LN',
        RegAddrCity: 'MORRISVILLE',
        RegAddrState: 'NC',
        RegAddrZip: 27560,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '202 TROLLEY CAR WAY',
        RegAddrCity: 'MORRISVILLE',
        RegAddrState: 'NC',
        RegAddrZip: 27560,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1437 LENOXPLACE CIR',
        RegAddrCity: 'RALEIGH',
        RegAddrState: 'NC',
        RegAddrZip: 27603,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1133 MARSHALL ST',
        RegAddrCity: 'RALEIGH',
        RegAddrState: 'NC',
        RegAddrZip: 27604,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '3029 BETHWICKE CT',
        RegAddrCity: 'RALEIGH',
        RegAddrState: 'NC',
        RegAddrZip: 27604,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1012 OBERLIN RD',
        RegAddrCity: 'RALEIGH',
        RegAddrState: 'NC',
        RegAddrZip: 27605,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '4202 WHISTLER CT',
        RegAddrCity: 'RALEIGH',
        RegAddrState: 'NC',
        RegAddrZip: 27606,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '0 NCSU LEE',
        RegAddrCity: 'RALEIGH',
        RegAddrState: 'NC',
        RegAddrZip: 27607,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '0 NCSU OWEN',
        RegAddrCity: 'RALEIGH',
        RegAddrState: 'NC',
        RegAddrZip: 27607,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '5507 BURNLEE PL',
        RegAddrCity: 'RALEIGH',
        RegAddrState: 'NC',
        RegAddrZip: 27609,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1778 PROCTOR RD',
        RegAddrCity: 'RALEIGH',
        RegAddrState: 'NC',
        RegAddrZip: 27610,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '6036 ROCK QUARRY RD',
        RegAddrCity: 'RALEIGH',
        RegAddrState: 'NC',
        RegAddrZip: 27610,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '733 CROSS LINK RD',
        RegAddrCity: 'RALEIGH',
        RegAddrState: 'NC',
        RegAddrZip: 27610,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '3813 TRYON RIDGE DR',
        RegAddrCity: 'RALEIGH',
        RegAddrState: 'NC',
        RegAddrZip: 27610,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '6108 CHOWNING CT',
        RegAddrCity: 'RALEIGH',
        RegAddrState: 'NC',
        RegAddrZip: 27612,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '8316 AMBER LANTERN ST',
        RegAddrCity: 'RALEIGH',
        RegAddrState: 'NC',
        RegAddrZip: 27613,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '8309 CLEAR BROOK DR',
        RegAddrCity: 'RALEIGH',
        RegAddrState: 'NC',
        RegAddrZip: 27615,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1433 OPAL CT',
        RegAddrCity: 'RALEIGH',
        RegAddrState: 'NC',
        RegAddrZip: 27615,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '6551 HEARTHSTONE DR',
        RegAddrCity: 'RALEIGH',
        RegAddrState: 'NC',
        RegAddrZip: 27615,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '4636 POOH CORNER DR',
        RegAddrCity: 'RALEIGH',
        RegAddrState: 'NC',
        RegAddrZip: 27616,
        CongressionalDistrict: 2,
      },
    ],
  },
  {
    name: 'NC-3',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/NC-3?ref=gh-pages',
    state: 'NC',
    state_and_number: 'NC-3',
    zip: 27806,
    congressionalDistrict: 3,
    addressesInDistrict: [
      {
        RegAddrLine1: '111 WINDING WOODS WAY',
        RegAddrCity: 'BEAUFORT',
        RegAddrState: 'NC',
        RegAddrZip: 28516,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '87 FORT CANAL DR',
        RegAddrCity: 'CHOCOWINITY',
        RegAddrState: 'NC',
        RegAddrZip: 27817,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '103 KATIES TRL',
        RegAddrCity: 'ELIZABETH CTY',
        RegAddrState: 'NC',
        RegAddrZip: 27909,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '124 CHANCEY DR',
        RegAddrCity: 'ELIZABETH CTY',
        RegAddrState: 'NC',
        RegAddrZip: 27909,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '810 COMPASS DR',
        RegAddrCity: 'ELIZABETH CTY',
        RegAddrState: 'NC',
        RegAddrZip: 27909,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '3909 DUNHAGAN RD',
        RegAddrCity: 'GREENVILLE',
        RegAddrState: 'NC',
        RegAddrZip: 27858,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '6 DERRICK CIR',
        RegAddrCity: 'HAVELOCK',
        RegAddrState: 'NC',
        RegAddrZip: 28532,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '605 LEE DR',
        RegAddrCity: 'HAVELOCK',
        RegAddrState: 'NC',
        RegAddrZip: 28532,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '120 BERTS RD',
        RegAddrCity: 'HAVELOCK',
        RegAddrState: 'NC',
        RegAddrZip: 28532,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '656 NEW HOPE RD',
        RegAddrCity: 'HERTFORD',
        RegAddrState: 'NC',
        RegAddrZip: 27944,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '402 SALVO CT',
        RegAddrCity: 'HOLLY RIDGE',
        RegAddrState: 'NC',
        RegAddrZip: 28445,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '214 CHESWICK DR',
        RegAddrCity: 'HOLLY RIDGE',
        RegAddrState: 'NC',
        RegAddrZip: 28445,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '115 CUNNIFFE LN',
        RegAddrCity: 'HUBERT',
        RegAddrState: 'NC',
        RegAddrZip: 28539,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '108 GLENWOOD DR',
        RegAddrCity: 'HUBERT',
        RegAddrState: 'NC',
        RegAddrZip: 28539,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '202 NEW RIVER DR',
        RegAddrCity: 'JACKSONVILLE',
        RegAddrState: 'NC',
        RegAddrZip: 28540,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '110 HEARTHSTONE CT',
        RegAddrCity: 'JACKSONVILLE',
        RegAddrState: 'NC',
        RegAddrZip: 28546,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '706 PARROTT AVE',
        RegAddrCity: 'KINSTON',
        RegAddrState: 'NC',
        RegAddrZip: 28501,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '3366 HWY 55 W',
        RegAddrCity: 'KINSTON',
        RegAddrState: 'NC',
        RegAddrZip: 28504,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '2521 DOGWOOD LN',
        RegAddrCity: 'KINSTON',
        RegAddrState: 'NC',
        RegAddrZip: 28504,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '2941 BYRD RD',
        RegAddrCity: 'LA GRANGE',
        RegAddrState: 'NC',
        RegAddrZip: 28551,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '125 CEDROW AVE',
        RegAddrCity: 'MANTEO',
        RegAddrState: 'NC',
        RegAddrZip: 27954,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '4700 SALZMAN DR',
        RegAddrCity: 'MIDWAY PARK',
        RegAddrState: 'NC',
        RegAddrZip: 28544,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '200 MANSFIELD PKWY',
        RegAddrCity: 'MOREHEAD CITY',
        RegAddrState: 'NC',
        RegAddrZip: 28557,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '101 MUSTANG TRL',
        RegAddrCity: 'MOYOCK',
        RegAddrState: 'NC',
        RegAddrZip: 27958,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1802 OLD CHERRY POINT RD',
        RegAddrCity: 'NEW BERN',
        RegAddrState: 'NC',
        RegAddrZip: 28560,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '2108 RAIL CT',
        RegAddrCity: 'NEW BERN',
        RegAddrState: 'NC',
        RegAddrZip: 28560,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '4438 OLD PINK HILL RD',
        RegAddrCity: 'PINK HILL',
        RegAddrState: 'NC',
        RegAddrZip: 28572,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '128 N INDIAN TOWN RD',
        RegAddrCity: 'SHAWBORO',
        RegAddrState: 'NC',
        RegAddrZip: 27973,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '222 WOODLAND DR',
        RegAddrCity: 'SOUTHERN SHORES',
        RegAddrState: 'NC',
        RegAddrZip: 27949,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '600 BRIDLE CT',
        RegAddrCity: 'SWANSBORO',
        RegAddrState: 'NC',
        RegAddrZip: 28584,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '154 CAMP QUEEN RD',
        RegAddrCity: 'SWANSBORO',
        RegAddrState: 'NC',
        RegAddrZip: 28584,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '536 CALICO BAY RD',
        RegAddrCity: 'TEACHEY',
        RegAddrState: 'NC',
        RegAddrZip: 28464,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '115 PINE RD',
        RegAddrCity: 'WALLACE',
        RegAddrState: 'NC',
        RegAddrZip: 28466,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '127 RC JAMES LN',
        RegAddrCity: 'WALLACE',
        RegAddrState: 'NC',
        RegAddrZip: 28466,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '425 W BAY ST',
        RegAddrCity: 'WARSAW',
        RegAddrState: 'NC',
        RegAddrZip: 28398,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '514 E MAIN ST',
        RegAddrCity: 'WASHINGTON',
        RegAddrState: 'NC',
        RegAddrZip: 27889,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '304 W 13TH ST',
        RegAddrCity: 'WASHINGTON',
        RegAddrState: 'NC',
        RegAddrZip: 27889,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '708 N MARKET ST',
        RegAddrCity: 'WASHINGTON',
        RegAddrState: 'NC',
        RegAddrZip: 27889,
        CongressionalDistrict: 3,
      },
    ],
  },
  {
    name: 'NC-4',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/NC-4?ref=gh-pages',
    state: 'NC',
    state_and_number: 'NC-4',
    zip: 27231,
    congressionalDistrict: 4,
    addressesInDistrict: [
      {
        RegAddrLine1: '203 ASHDOWN FOREST LN',
        RegAddrCity: 'CARY',
        RegAddrState: 'NC',
        RegAddrZip: 27519,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '102 CHRISTINE CT',
        RegAddrCity: 'CHAPEL HILL',
        RegAddrState: 'NC',
        RegAddrZip: 27516,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '3220 OLD GREENSBORO RD',
        RegAddrCity: 'CHAPEL HILL',
        RegAddrState: 'NC',
        RegAddrZip: 27516,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '804 WARD ST',
        RegAddrCity: 'CHAPEL HILL',
        RegAddrState: 'NC',
        RegAddrZip: 27516,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '213 CATES FARM RD',
        RegAddrCity: 'CHAPEL HILL',
        RegAddrState: 'NC',
        RegAddrZip: 27516,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2402 GEMENA RD',
        RegAddrCity: 'CHAPEL HILL',
        RegAddrState: 'NC',
        RegAddrZip: 27516,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '707 WHITE CROSS RD',
        RegAddrCity: 'CHAPEL HILL',
        RegAddrState: 'NC',
        RegAddrZip: 27516,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '285 SUMMERWALK CIR',
        RegAddrCity: 'CHAPEL HILL',
        RegAddrState: 'NC',
        RegAddrZip: 27517,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '48 MAXWELL RD',
        RegAddrCity: 'CHAPEL HILL',
        RegAddrState: 'NC',
        RegAddrZip: 27517,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '5 KENDALL DR',
        RegAddrCity: 'CHAPEL HILL',
        RegAddrState: 'NC',
        RegAddrZip: 27517,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1578 POPE RD',
        RegAddrCity: 'CREEDMOOR',
        RegAddrState: 'NC',
        RegAddrZip: 27522,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '5012 ROLLING MEADOWS DR',
        RegAddrCity: 'DURHAM',
        RegAddrState: 'NC',
        RegAddrZip: 27703,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1124 BETA RD',
        RegAddrCity: 'DURHAM',
        RegAddrState: 'NC',
        RegAddrZip: 27703,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '10 TRESCOTT DR',
        RegAddrCity: 'DURHAM',
        RegAddrState: 'NC',
        RegAddrZip: 27703,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2 SCOTTYBROOK CT',
        RegAddrCity: 'DURHAM',
        RegAddrState: 'NC',
        RegAddrZip: 27703,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '111 VANDERBILT CT',
        RegAddrCity: 'DURHAM',
        RegAddrState: 'NC',
        RegAddrZip: 27705,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1708 GLASGOW ST',
        RegAddrCity: 'DURHAM',
        RegAddrState: 'NC',
        RegAddrZip: 27705,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2819 CREST ST',
        RegAddrCity: 'DURHAM',
        RegAddrState: 'NC',
        RegAddrZip: 27705,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2218 SHOREHAM ST',
        RegAddrCity: 'DURHAM',
        RegAddrState: 'NC',
        RegAddrZip: 27707,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '3712 SUNNINGDALE WAY',
        RegAddrCity: 'DURHAM',
        RegAddrState: 'NC',
        RegAddrZip: 27707,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '9 DRUMMOND CT',
        RegAddrCity: 'DURHAM',
        RegAddrState: 'NC',
        RegAddrZip: 27713,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '3650 WHISPERING PINES LN',
        RegAddrCity: 'FRANKLINTON',
        RegAddrState: 'NC',
        RegAddrZip: 27525,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '94 WINDING WAY',
        RegAddrCity: 'FRANKLINTON',
        RegAddrState: 'NC',
        RegAddrZip: 27525,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2203 WOODBURY DR',
        RegAddrCity: 'HILLSBOROUGH',
        RegAddrState: 'NC',
        RegAddrZip: 27278,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '120 KEENELAND DR',
        RegAddrCity: 'OXFORD',
        RegAddrState: 'NC',
        RegAddrZip: 27565,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '169 LAUREN ROSE LN',
        RegAddrCity: 'PITTSBORO',
        RegAddrState: 'NC',
        RegAddrZip: 27312,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '4405 PLEASANT GROVE CHURCH RD',
        RegAddrCity: 'RALEIGH',
        RegAddrState: 'NC',
        RegAddrZip: 27613,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '10114 THOUGHTFUL SPOT WAY',
        RegAddrCity: 'RALEIGH',
        RegAddrState: 'NC',
        RegAddrZip: 27614,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '13030 SARGAS ST',
        RegAddrCity: 'RALEIGH',
        RegAddrState: 'NC',
        RegAddrZip: 27614,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '10204 GRAFTON RD',
        RegAddrCity: 'RALEIGH',
        RegAddrState: 'NC',
        RegAddrZip: 27615,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '3443 SUNCREST VILLAGE LN',
        RegAddrCity: 'RALEIGH',
        RegAddrState: 'NC',
        RegAddrZip: 27616,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '7017 HASENTREE WAY',
        RegAddrCity: 'WAKE FOREST',
        RegAddrState: 'NC',
        RegAddrZip: 27587,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '254 TILLAMOOK DR',
        RegAddrCity: 'WAKE FOREST',
        RegAddrState: 'NC',
        RegAddrZip: 27587,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '8508 TERRITORY TRL',
        RegAddrCity: 'WAKE FOREST',
        RegAddrState: 'NC',
        RegAddrZip: 27587,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '4804 STUDBURY HALL CT',
        RegAddrCity: 'WAKE FOREST',
        RegAddrState: 'NC',
        RegAddrZip: 27587,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '8001 KEYLAND PL',
        RegAddrCity: 'WAKE FOREST',
        RegAddrState: 'NC',
        RegAddrZip: 27587,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '10025 SEAWELL DR',
        RegAddrCity: 'WAKE FOREST',
        RegAddrState: 'NC',
        RegAddrZip: 27587,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '70 HOLDING YOUNG RD',
        RegAddrCity: 'YOUNGSVILLE',
        RegAddrState: 'NC',
        RegAddrZip: 27596,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '3007 WILD IRIS DR',
        RegAddrCity: 'ZEBULON',
        RegAddrState: 'NC',
        RegAddrZip: 27597,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '102 PINEWOODS DR',
        RegAddrCity: 'ZEBULON',
        RegAddrState: 'NC',
        RegAddrZip: 27597,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '431 JOHNSON TOWN RD',
        RegAddrCity: 'ZEBULON',
        RegAddrState: 'NC',
        RegAddrZip: 27597,
        CongressionalDistrict: 4,
      },
    ],
  },
  {
    name: 'NC-5',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/NC-5?ref=gh-pages',
    state: 'NC',
    state_and_number: 'NC-5',
    zip: 24053,
    congressionalDistrict: 5,
    addressesInDistrict: [
      {
        RegAddrLine1: '5326 S NEW HOPE RD',
        RegAddrCity: 'BELMONT',
        RegAddrState: 'NC',
        RegAddrZip: 28012,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '213 CLYDE WILLIAMS DR',
        RegAddrCity: 'BOONE',
        RegAddrState: 'NC',
        RegAddrZip: 28607,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '126 SETTLEMYRE RD',
        RegAddrCity: 'DREXEL',
        RegAddrState: 'NC',
        RegAddrZip: 28619,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '302 MIMOSA AVE',
        RegAddrCity: 'DREXEL',
        RegAddrState: 'NC',
        RegAddrZip: 28619,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '1223 BEAVERBROOK DR',
        RegAddrCity: 'GASTONIA',
        RegAddrState: 'NC',
        RegAddrZip: 28052,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '1267 KENNETT AVE',
        RegAddrCity: 'GASTONIA',
        RegAddrState: 'NC',
        RegAddrZip: 28054,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '3740 SUGAR SPRING RD',
        RegAddrCity: 'GASTONIA',
        RegAddrState: 'NC',
        RegAddrZip: 28054,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '409 N EDGEMONT AVE',
        RegAddrCity: 'GASTONIA',
        RegAddrState: 'NC',
        RegAddrZip: 28054,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '2657 WELLSHIRE CT',
        RegAddrCity: 'GASTONIA',
        RegAddrState: 'NC',
        RegAddrZip: 28056,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '3320 SU SAN FARMS RD',
        RegAddrCity: 'GASTONIA',
        RegAddrState: 'NC',
        RegAddrZip: 28056,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '2155 TRIPLETT RD',
        RegAddrCity: 'GRANITE FALLS',
        RegAddrState: 'NC',
        RegAddrZip: 28630,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '1551 CAJAH MOUNTAIN RD',
        RegAddrCity: 'HUDSON',
        RegAddrState: 'NC',
        RegAddrZip: 28638,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '5661 SHERIFFS RD',
        RegAddrCity: 'LENOIR',
        RegAddrState: 'NC',
        RegAddrZip: 28645,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '700 OLD NC 18',
        RegAddrCity: 'MORGANTON',
        RegAddrState: 'NC',
        RegAddrZip: 28655,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '1315 AVERY RD',
        RegAddrCity: 'MORGANTON',
        RegAddrState: 'NC',
        RegAddrZip: 28655,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '2883 EMERALD CIR',
        RegAddrCity: 'MORGANTON',
        RegAddrState: 'NC',
        RegAddrZip: 28655,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '996 HOPEWELL RD',
        RegAddrCity: 'MORGANTON',
        RegAddrState: 'NC',
        RegAddrZip: 28655,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '116 DEVYNN RIDGE CT',
        RegAddrCity: 'MOUNT HOLLY',
        RegAddrState: 'NC',
        RegAddrZip: 28120,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '7602 ELKIN HWY',
        RegAddrCity: 'ROARING RIVER',
        RegAddrState: 'NC',
        RegAddrZip: 28669,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '161 ALLEN LOOP RD',
        RegAddrCity: 'RUTHERFORDTON',
        RegAddrState: 'NC',
        RegAddrZip: 28139,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '140 FASHION AVE',
        RegAddrCity: 'RUTHERFRD CLG',
        RegAddrState: 'NC',
        RegAddrZip: 28671,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '4425 MOUNTAIN VIEW DR',
        RegAddrCity: 'SHELBY',
        RegAddrState: 'NC',
        RegAddrZip: 28150,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '813 RHODANN DR',
        RegAddrCity: 'SHELBY',
        RegAddrState: 'NC',
        RegAddrZip: 28152,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '6015 DEEP GREEN DR',
        RegAddrCity: 'SHELBY',
        RegAddrState: 'NC',
        RegAddrZip: 28152,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '841 COUNCIL WARD RD',
        RegAddrCity: 'SUGAR GROVE',
        RegAddrState: 'NC',
        RegAddrZip: 28679,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '2684 VASHTI RD',
        RegAddrCity: 'TAYLORSVILLE',
        RegAddrState: 'NC',
        RegAddrZip: 28681,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '669 CEDAR WOODS RD',
        RegAddrCity: 'TAYLORSVILLE',
        RegAddrState: 'NC',
        RegAddrZip: 28681,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '295 CROUCH RD',
        RegAddrCity: 'TAYLORSVILLE',
        RegAddrState: 'NC',
        RegAddrZip: 28681,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '198 BURLEY LN',
        RegAddrCity: 'VILAS',
        RegAddrState: 'NC',
        RegAddrZip: 28692,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '283 JACEY WYNNE LN',
        RegAddrCity: 'W JEFFERSON',
        RegAddrState: 'NC',
        RegAddrZip: 28694,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '878 CLINT JOHNSON RD',
        RegAddrCity: 'WILKESBORO',
        RegAddrState: 'NC',
        RegAddrZip: 28697,
        CongressionalDistrict: 5,
      },
    ],
  },
  {
    name: 'NC-6',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/NC-6?ref=gh-pages',
    state: 'NC',
    state_and_number: 'NC-6',
    zip: 24069,
    congressionalDistrict: 6,
    addressesInDistrict: [
      {
        RegAddrLine1: '4610 SOUTHERN WEBBING MILL RD',
        RegAddrCity: 'GREENSBORO',
        RegAddrState: 'NC',
        RegAddrZip: 27405,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '4416 GRAY WOLF WAY',
        RegAddrCity: 'GREENSBORO',
        RegAddrState: 'NC',
        RegAddrZip: 27406,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '308 W MEADOWVIEW RD',
        RegAddrCity: 'GREENSBORO',
        RegAddrState: 'NC',
        RegAddrZip: 27406,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '4817 ADAMS RIDGE DR',
        RegAddrCity: 'GREENSBORO',
        RegAddrState: 'NC',
        RegAddrZip: 27407,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '1515 ILCHESTER DR',
        RegAddrCity: 'GREENSBORO',
        RegAddrState: 'NC',
        RegAddrZip: 27408,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '3027 STRATFORD DR',
        RegAddrCity: 'GREENSBORO',
        RegAddrState: 'NC',
        RegAddrZip: 27408,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '304 WAYCROSS CT',
        RegAddrCity: 'GREENSBORO',
        RegAddrState: 'NC',
        RegAddrZip: 27410,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '1318 WESTRIDGE RD',
        RegAddrCity: 'GREENSBORO',
        RegAddrState: 'NC',
        RegAddrZip: 27410,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '5509 ROBINRIDGE RD',
        RegAddrCity: 'GREENSBORO',
        RegAddrState: 'NC',
        RegAddrZip: 27410,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '3503 CHARING CROSS RD',
        RegAddrCity: 'GREENSBORO',
        RegAddrState: 'NC',
        RegAddrZip: 27455,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '6023 STONE VALLEY WAY',
        RegAddrCity: 'GREENSBORO',
        RegAddrState: 'NC',
        RegAddrZip: 27455,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '1001 GRANBY AVE',
        RegAddrCity: 'HIGH POINT',
        RegAddrState: 'NC',
        RegAddrZip: 27260,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '315 MODEL FARM RD',
        RegAddrCity: 'HIGH POINT',
        RegAddrState: 'NC',
        RegAddrZip: 27263,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '2723 JOINER ST',
        RegAddrCity: 'HIGH POINT',
        RegAddrState: 'NC',
        RegAddrZip: 27263,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '4613 SANDY CAMP RD',
        RegAddrCity: 'HIGH POINT',
        RegAddrState: 'NC',
        RegAddrZip: 27265,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '1006 NEW HAMPSHIRE DR',
        RegAddrCity: 'JAMESTOWN',
        RegAddrState: 'NC',
        RegAddrZip: 27282,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '3931 LAKE MEADOW DR',
        RegAddrCity: 'JAMESTOWN',
        RegAddrState: 'NC',
        RegAddrZip: 27282,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '935 KAYE GIBBONS CT',
        RegAddrCity: 'KERNERSVILLE',
        RegAddrState: 'NC',
        RegAddrZip: 27284,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '7505 KEATING DR',
        RegAddrCity: 'OAK RIDGE',
        RegAddrState: 'NC',
        RegAddrZip: 27310,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '2058 BEESON RD',
        RegAddrCity: 'OAK RIDGE',
        RegAddrState: 'NC',
        RegAddrZip: 27310,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '8309 HUDSON JAMES RD',
        RegAddrCity: 'SUMMERFIELD',
        RegAddrState: 'NC',
        RegAddrZip: 27358,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '6201 BIRDIE CT',
        RegAddrCity: 'WHITSETT',
        RegAddrState: 'NC',
        RegAddrZip: 27377,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '5850 REGENT VILLAGE DR',
        RegAddrCity: 'WINSTON SALEM',
        RegAddrState: 'NC',
        RegAddrZip: 27103,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '711 MELROSE ST',
        RegAddrCity: 'WINSTON SALEM',
        RegAddrState: 'NC',
        RegAddrZip: 27103,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '2637 WYNBROOK DR',
        RegAddrCity: 'WINSTON SALEM',
        RegAddrState: 'NC',
        RegAddrZip: 27103,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '1919 RUNNYMEDE RD',
        RegAddrCity: 'WINSTON SALEM',
        RegAddrState: 'NC',
        RegAddrZip: 27104,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '5065 BUTTERFIELD DR',
        RegAddrCity: 'WINSTON SALEM',
        RegAddrState: 'NC',
        RegAddrZip: 27105,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '3140 BEESON ACRES RD',
        RegAddrCity: 'WINSTON SALEM',
        RegAddrState: 'NC',
        RegAddrZip: 27105,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '4515 CARVER SCHOOL RD',
        RegAddrCity: 'WINSTON SALEM',
        RegAddrState: 'NC',
        RegAddrZip: 27105,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '5219 HUNT PARK CT',
        RegAddrCity: 'WINSTON SALEM',
        RegAddrState: 'NC',
        RegAddrZip: 27106,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '144 MOUNT OLIVET DR',
        RegAddrCity: 'WINSTON SALEM',
        RegAddrState: 'NC',
        RegAddrZip: 27107,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '1517 HEATHER TRACE LN',
        RegAddrCity: 'WINSTON SALEM',
        RegAddrState: 'NC',
        RegAddrZip: 27127,
        CongressionalDistrict: 6,
      },
    ],
  },
  {
    name: 'NC-7',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/NC-7?ref=gh-pages',
    state: 'NC',
    state_and_number: 'NC-7',
    zip: 27530,
    congressionalDistrict: 7,
    addressesInDistrict: [
      {
        RegAddrLine1: '109 PLEASANT PINE DR',
        RegAddrCity: 'ANGIER',
        RegAddrState: 'NC',
        RegAddrZip: 27501,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '200 FOX TROT LN',
        RegAddrCity: 'ANGIER',
        RegAddrState: 'NC',
        RegAddrZip: 27501,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '101 WILBUR LN',
        RegAddrCity: 'ANGIER',
        RegAddrState: 'NC',
        RegAddrZip: 27501,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '234 FERRIS DR',
        RegAddrCity: 'ATKINSON',
        RegAddrState: 'NC',
        RegAddrZip: 28421,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '6229 OLD FAIRGROUND RD',
        RegAddrCity: 'BENSON',
        RegAddrState: 'NC',
        RegAddrZip: 27504,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '351 N PLEASANT COATES RD',
        RegAddrCity: 'BENSON',
        RegAddrState: 'NC',
        RegAddrZip: 27504,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '206 BRITT ST',
        RegAddrCity: 'BLADENBORO',
        RegAddrState: 'NC',
        RegAddrZip: 28320,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '255 W MAIN ST',
        RegAddrCity: 'BURGAW',
        RegAddrState: 'NC',
        RegAddrZip: 28425,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '379 HICKMAN RD NW',
        RegAddrCity: 'CALABASH',
        RegAddrState: 'NC',
        RegAddrZip: 28467,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '103 WHITE SEDGE DR',
        RegAddrCity: 'CARY',
        RegAddrState: 'NC',
        RegAddrZip: 27513,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '5310 CASTLE HAYNE RD',
        RegAddrCity: 'CASTLE HAYNE',
        RegAddrState: 'NC',
        RegAddrZip: 28429,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '2431 OAKLEY RD',
        RegAddrCity: 'CASTLE HAYNE',
        RegAddrState: 'NC',
        RegAddrZip: 28429,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '57 COSTA CT',
        RegAddrCity: 'CLAYTON',
        RegAddrState: 'NC',
        RegAddrZip: 27527,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '321 UNDERWOOD ST',
        RegAddrCity: 'CLINTON',
        RegAddrState: 'NC',
        RegAddrZip: 28328,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '140 N RAILROAD ST',
        RegAddrCity: 'COATS',
        RegAddrState: 'NC',
        RegAddrZip: 27521,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '180 SKEET RANGE RD',
        RegAddrCity: 'COATS',
        RegAddrState: 'NC',
        RegAddrZip: 27521,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '9466 GREEN PATH RD',
        RegAddrCity: 'DUNN',
        RegAddrState: 'NC',
        RegAddrZip: 28334,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '9809 HAYNES LENNON HWY',
        RegAddrCity: 'EVERGREEN',
        RegAddrState: 'NC',
        RegAddrZip: 28438,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '1200 MURCHISON RD',
        RegAddrCity: 'FAYETTEVILLE',
        RegAddrState: 'NC',
        RegAddrZip: 28301,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '506 N CHURCH ST',
        RegAddrCity: 'FOUR OAKS',
        RegAddrState: 'NC',
        RegAddrZip: 27524,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '115 CLARENCE LN',
        RegAddrCity: 'GARNER',
        RegAddrState: 'NC',
        RegAddrZip: 27529,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '726 AIREDALE TRL',
        RegAddrCity: 'GARNER',
        RegAddrState: 'NC',
        RegAddrZip: 27529,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '110 CAPTAINS CV',
        RegAddrCity: 'HAMPSTEAD',
        RegAddrState: 'NC',
        RegAddrZip: 28443,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '673 PINE BRANCHES CIR SE',
        RegAddrCity: 'LELAND',
        RegAddrState: 'NC',
        RegAddrZip: 28451,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '709 RALEIGH ST',
        RegAddrCity: 'NEWTON GROVE',
        RegAddrState: 'NC',
        RegAddrZip: 28366,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '216 SW 15TH ST',
        RegAddrCity: 'OAK ISLAND',
        RegAddrState: 'NC',
        RegAddrZip: 28465,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '1502 W YACHT DR',
        RegAddrCity: 'OAK ISLAND',
        RegAddrState: 'NC',
        RegAddrZip: 28465,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '2760 CORINTH CHURCH RD',
        RegAddrCity: 'ROSEBORO',
        RegAddrState: 'NC',
        RegAddrZip: 28382,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '2066 US HIGHWAY 70A E',
        RegAddrCity: 'SELMA',
        RegAddrState: 'NC',
        RegAddrZip: 27576,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '4559 STEVENS CHAPEL RD',
        RegAddrCity: 'SMITHFIELD',
        RegAddrState: 'NC',
        RegAddrZip: 27577,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '273 BEULAH CHURCH RD',
        RegAddrCity: 'TURKEY',
        RegAddrState: 'NC',
        RegAddrZip: 28393,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '2861 NC 231 HWY',
        RegAddrCity: 'WENDELL',
        RegAddrState: 'NC',
        RegAddrZip: 27591,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '219 MA MAC RD',
        RegAddrCity: 'WHITEVILLE',
        RegAddrState: 'NC',
        RegAddrZip: 28472,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '3007 WILLARD RD',
        RegAddrCity: 'WILLARD',
        RegAddrState: 'NC',
        RegAddrZip: 28478,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '1006 BRYAN AVE',
        RegAddrCity: 'WILMINGTON',
        RegAddrState: 'NC',
        RegAddrZip: 28403,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '310 N 25TH ST',
        RegAddrCity: 'WILMINGTON',
        RegAddrState: 'NC',
        RegAddrZip: 28405,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '1300 LANDFALL DR',
        RegAddrCity: 'WILMINGTON',
        RegAddrState: 'NC',
        RegAddrZip: 28405,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '1316 LOVINGSTON LN',
        RegAddrCity: 'WILMINGTON',
        RegAddrState: 'NC',
        RegAddrZip: 28409,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '8670 GRAYSON PARK DR',
        RegAddrCity: 'WILMINGTON',
        RegAddrState: 'NC',
        RegAddrZip: 28411,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '6946 NEWBURY WAY',
        RegAddrCity: 'WILMINGTON',
        RegAddrState: 'NC',
        RegAddrZip: 28411,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '621 INLET ACRES DR',
        RegAddrCity: 'WILMINGTON',
        RegAddrState: 'NC',
        RegAddrZip: 28412,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '201 OLD MURPHY RD',
        RegAddrCity: 'ZEBULON',
        RegAddrState: 'NC',
        RegAddrZip: 27597,
        CongressionalDistrict: 7,
      },
    ],
  },
  {
    name: 'NC-8',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/NC-8?ref=gh-pages',
    state: 'NC',
    state_and_number: 'NC-8',
    zip: 27209,
    congressionalDistrict: 8,
    addressesInDistrict: [
      {
        RegAddrLine1: '32055 MILLINGPORT RD',
        RegAddrCity: 'ALBEMARLE',
        RegAddrState: 'NC',
        RegAddrZip: 28001,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '20893A AUSTIN RD',
        RegAddrCity: 'ALBEMARLE',
        RegAddrState: 'NC',
        RegAddrZip: 28001,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '213 N BROOME ST',
        RegAddrCity: 'ALBEMARLE',
        RegAddrState: 'NC',
        RegAddrZip: 28001,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '1032 AIRPORT RD',
        RegAddrCity: 'CARTHAGE',
        RegAddrState: 'NC',
        RegAddrZip: 28327,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '586 CRESTSIDE DR SE',
        RegAddrCity: 'CONCORD',
        RegAddrState: 'NC',
        RegAddrZip: 28025,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '456 BROOK VALLEY CT NE',
        RegAddrCity: 'CONCORD',
        RegAddrState: 'NC',
        RegAddrZip: 28025,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '260 BROOKWOOD AVE NE',
        RegAddrCity: 'CONCORD',
        RegAddrState: 'NC',
        RegAddrZip: 28025,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '616 NANNYBERRY LN',
        RegAddrCity: 'CONCORD',
        RegAddrState: 'NC',
        RegAddrZip: 28025,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '471 HARRIS ST NW',
        RegAddrCity: 'CONCORD',
        RegAddrState: 'NC',
        RegAddrZip: 28025,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '932 RAY SUGGS PL NW',
        RegAddrCity: 'CONCORD',
        RegAddrState: 'NC',
        RegAddrZip: 28027,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '4023 CLOVER RD NW',
        RegAddrCity: 'CONCORD',
        RegAddrState: 'NC',
        RegAddrZip: 28027,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '61 BROWN ST SW',
        RegAddrCity: 'CONCORD',
        RegAddrState: 'NC',
        RegAddrZip: 28027,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '60 BARBEE RD SW',
        RegAddrCity: 'CONCORD',
        RegAddrState: 'NC',
        RegAddrZip: 28027,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '421 MCIVER ST',
        RegAddrCity: 'FAYETTEVILLE',
        RegAddrState: 'NC',
        RegAddrZip: 28301,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '633 ORANGE ST',
        RegAddrCity: 'FAYETTEVILLE',
        RegAddrState: 'NC',
        RegAddrZip: 28301,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '5605 BLYTHEWOOD LN',
        RegAddrCity: 'FAYETTEVILLE',
        RegAddrState: 'NC',
        RegAddrZip: 28311,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '433 HUGHES RD',
        RegAddrCity: 'FAYETTEVILLE',
        RegAddrState: 'NC',
        RegAddrZip: 28312,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '256 WHITEHEAD RD',
        RegAddrCity: 'FAYETTEVILLE',
        RegAddrState: 'NC',
        RegAddrZip: 28312,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '3866 GLENCORRA DR',
        RegAddrCity: 'FAYETTEVILLE',
        RegAddrState: 'NC',
        RegAddrZip: 28314,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '6790 SEAFORD DR',
        RegAddrCity: 'FAYETTEVILLE',
        RegAddrState: 'NC',
        RegAddrZip: 28314,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '6619 GRISTMILL RD',
        RegAddrCity: 'FAYETTEVILLE',
        RegAddrState: 'NC',
        RegAddrZip: 28314,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '5730 IVANHOE CT',
        RegAddrCity: 'FAYETTEVILLE',
        RegAddrState: 'NC',
        RegAddrZip: 28314,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '6493 RIVER HILLS DR',
        RegAddrCity: 'HARRISBURG',
        RegAddrState: 'NC',
        RegAddrZip: 28075,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '2949 HIGH PLAINS DR',
        RegAddrCity: 'HOPE MILLS',
        RegAddrState: 'NC',
        RegAddrZip: 28348,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '3207 BYRD CIR',
        RegAddrCity: 'HOPE MILLS',
        RegAddrState: 'NC',
        RegAddrZip: 28348,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '2002 CAROLINA AVE',
        RegAddrCity: 'KANNAPOLIS',
        RegAddrState: 'NC',
        RegAddrZip: 28083,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '116 FLYWAY DR',
        RegAddrCity: 'LILLINGTON',
        RegAddrState: 'NC',
        RegAddrZip: 27546,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '18962 JACOB RD',
        RegAddrCity: 'LOCUST',
        RegAddrState: 'NC',
        RegAddrZip: 28097,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '412 S CENTRAL AVE',
        RegAddrCity: 'LOCUST',
        RegAddrState: 'NC',
        RegAddrZip: 28097,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '2805 ALISH TRL',
        RegAddrCity: 'MOUNT PLEASANT',
        RegAddrState: 'NC',
        RegAddrZip: 28124,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '260 W MAGNOLIA DR',
        RegAddrCity: 'ROBBINS',
        RegAddrState: 'NC',
        RegAddrZip: 27325,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '165 ROLLING PINES DR',
        RegAddrCity: 'SPRING LAKE',
        RegAddrState: 'NC',
        RegAddrZip: 28390,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '3327 DONNETT CT',
        RegAddrCity: 'SPRING LAKE',
        RegAddrState: 'NC',
        RegAddrZip: 28390,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '310 ZIGLER CIR',
        RegAddrCity: 'SPRING LAKE',
        RegAddrState: 'NC',
        RegAddrZip: 28390,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '2656 LOVE JOY RD',
        RegAddrCity: 'TROY',
        RegAddrState: 'NC',
        RegAddrZip: 27371,
        CongressionalDistrict: 8,
      },
    ],
  },
  {
    name: 'NC-9',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/NC-9?ref=gh-pages',
    state: 'NC',
    state_and_number: 'NC-9',
    zip: 28007,
    congressionalDistrict: 9,
    addressesInDistrict: [
      {
        RegAddrLine1: '113 ISLEWORTH PL',
        RegAddrCity: 'ABERDEEN',
        RegAddrState: 'NC',
        RegAddrZip: 28315,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '6817 KNIGHTSWOOD DR',
        RegAddrCity: 'CHARLOTTE',
        RegAddrState: 'NC',
        RegAddrZip: 28226,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '3516 CHILHAM PL',
        RegAddrCity: 'CHARLOTTE',
        RegAddrState: 'NC',
        RegAddrZip: 28226,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '751 LANSDOWNE RD',
        RegAddrCity: 'CHARLOTTE',
        RegAddrState: 'NC',
        RegAddrZip: 28270,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '13240 WHISPER CREEK DR',
        RegAddrCity: 'CHARLOTTE',
        RegAddrState: 'NC',
        RegAddrZip: 28277,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '12719 LINDRICK LN',
        RegAddrCity: 'CHARLOTTE',
        RegAddrState: 'NC',
        RegAddrZip: 28277,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '305 MARKET ST',
        RegAddrCity: 'FAIRMONT',
        RegAddrState: 'NC',
        RegAddrZip: 28340,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '128 DIXIE TRL',
        RegAddrCity: 'HAMLET',
        RegAddrState: 'NC',
        RegAddrZip: 28345,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '904 N CEDAR ST',
        RegAddrCity: 'LUMBERTON',
        RegAddrState: 'NC',
        RegAddrZip: 28358,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '2202 MUSSELWHITE CIR',
        RegAddrCity: 'LUMBERTON',
        RegAddrState: 'NC',
        RegAddrZip: 28358,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '4165 NELSON WAY',
        RegAddrCity: 'LUMBERTON',
        RegAddrState: 'NC',
        RegAddrZip: 28360,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '2037 FITZHUGH LN',
        RegAddrCity: 'MATTHEWS',
        RegAddrState: 'NC',
        RegAddrZip: 28104,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '2115 FLAGSTICK DR',
        RegAddrCity: 'MATTHEWS',
        RegAddrState: 'NC',
        RegAddrZip: 28104,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '3117 SMITH FARM RD',
        RegAddrCity: 'MATTHEWS',
        RegAddrState: 'NC',
        RegAddrZip: 28104,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '730 ABLOW DR',
        RegAddrCity: 'MATTHEWS',
        RegAddrState: 'NC',
        RegAddrZip: 28105,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '802 E ROCKINGHAM RD',
        RegAddrCity: 'MAXTON',
        RegAddrState: 'NC',
        RegAddrZip: 28364,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '2303 JACOBS CT',
        RegAddrCity: 'MONROE',
        RegAddrState: 'NC',
        RegAddrZip: 28110,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '3133 CHIPPENDALE RD',
        RegAddrCity: 'MONROE',
        RegAddrState: 'NC',
        RegAddrZip: 28110,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '212 MARVIN WATKINS RD',
        RegAddrCity: 'MONROE',
        RegAddrState: 'NC',
        RegAddrZip: 28112,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '800 SAINT ANDREWS DR',
        RegAddrCity: 'PINEHURST',
        RegAddrState: 'NC',
        RegAddrZip: 28374,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '75 WHISTLING STRAIGHT RD',
        RegAddrCity: 'PINEHURST',
        RegAddrState: 'NC',
        RegAddrZip: 28374,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '14636 HAWICK MANOR LN',
        RegAddrCity: 'PINEVILLE',
        RegAddrState: 'NC',
        RegAddrZip: 28134,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '2479 MINERAL SPRINGS CHURCH RD',
        RegAddrCity: 'POLKTON',
        RegAddrState: 'NC',
        RegAddrZip: 28135,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '481 JOHN BROWN RD',
        RegAddrCity: 'RAEFORD',
        RegAddrState: 'NC',
        RegAddrZip: 28376,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '100 VIOLA CT',
        RegAddrCity: 'RAEFORD',
        RegAddrState: 'NC',
        RegAddrZip: 28376,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '128 WILDERNESS DR',
        RegAddrCity: 'ROCKINGHAM',
        RegAddrState: 'NC',
        RegAddrZip: 28379,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '101 N CHICKEN RD',
        RegAddrCity: 'ROWLAND',
        RegAddrState: 'NC',
        RegAddrZip: 28383,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '161 HILDRETH RD',
        RegAddrCity: 'WADESBORO',
        RegAddrState: 'NC',
        RegAddrZip: 28170,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '24000 NAZARETH CHURCH RD',
        RegAddrCity: 'WAGRAM',
        RegAddrState: 'NC',
        RegAddrZip: 28396,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '1113 BALTUSROL LN',
        RegAddrCity: 'WAXHAW',
        RegAddrState: 'NC',
        RegAddrZip: 28173,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '5008 HUDSON MILL DR',
        RegAddrCity: 'WAXHAW',
        RegAddrState: 'NC',
        RegAddrZip: 28173,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '3600 HERMITAGE PLACE DR',
        RegAddrCity: 'WAXHAW',
        RegAddrState: 'NC',
        RegAddrZip: 28173,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '1122 DEEP HOLLOW CT',
        RegAddrCity: 'WAXHAW',
        RegAddrState: 'NC',
        RegAddrZip: 28173,
        CongressionalDistrict: 9,
      },
    ],
  },
  {
    name: 'ND-0',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/ND-0?ref=gh-pages',
    state: 'ND',
    state_and_number: 'ND-0',
    zip: 57638,
    congressionalDistrict: 0,
    addressesInDistrict: [
      {
        RegAddrLine1: '15332 59TH ST NE',
        RegAddrCity: 'ARDOCH',
        RegAddrState: 'ND',
        RegAddrZip: 58261,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '160 3RD AVE SE',
        RegAddrCity: 'BEACH',
        RegAddrState: 'ND',
        RegAddrZip: 58621,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '3693 BIA ROAD 10',
        RegAddrCity: 'BELCOURT',
        RegAddrState: 'ND',
        RegAddrZip: 58316,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '3736 SULLY ST',
        RegAddrCity: 'BELFIELD',
        RegAddrState: 'ND',
        RegAddrZip: 58622,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '609 MAIN ST N',
        RegAddrCity: 'BERTHOLD',
        RegAddrState: 'ND',
        RegAddrZip: 58718,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '206 RIVIERA DR',
        RegAddrCity: 'BEULAH',
        RegAddrState: 'ND',
        RegAddrZip: 58523,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1425 N 33RD ST',
        RegAddrCity: 'BISMARCK',
        RegAddrState: 'ND',
        RegAddrZip: 58501,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1707 VALLE MOOR PL',
        RegAddrCity: 'BISMARCK',
        RegAddrState: 'ND',
        RegAddrZip: 58501,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '314 E AVENUE C',
        RegAddrCity: 'BISMARCK',
        RegAddrState: 'ND',
        RegAddrZip: 58501,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '808 N 3RD ST',
        RegAddrCity: 'BISMARCK',
        RegAddrState: 'ND',
        RegAddrZip: 58501,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '716 N 3RD ST',
        RegAddrCity: 'BISMARCK',
        RegAddrState: 'ND',
        RegAddrZip: 58501,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '118 W AVENUE B',
        RegAddrCity: 'BISMARCK',
        RegAddrState: 'ND',
        RegAddrZip: 58501,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1318 N 20TH ST',
        RegAddrCity: 'BISMARCK',
        RegAddrState: 'ND',
        RegAddrZip: 58501,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1508 NORTHVIEW LN',
        RegAddrCity: 'BISMARCK',
        RegAddrState: 'ND',
        RegAddrZip: 58501,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '6680 VALLEY VISTA LN',
        RegAddrCity: 'BISMARCK',
        RegAddrState: 'ND',
        RegAddrZip: 58503,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '6500 SHOAL DR',
        RegAddrCity: 'BISMARCK',
        RegAddrState: 'ND',
        RegAddrZip: 58503,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '513 PORTAGE DR',
        RegAddrCity: 'BISMARCK',
        RegAddrState: 'ND',
        RegAddrZip: 58503,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '3314 NORTHROP DR',
        RegAddrCity: 'BISMARCK',
        RegAddrState: 'ND',
        RegAddrZip: 58503,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '6524 PRAIRIE CLOVER PL',
        RegAddrCity: 'BISMARCK',
        RegAddrState: 'ND',
        RegAddrZip: 58503,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '609 SUDBURY AVE',
        RegAddrCity: 'BISMARCK',
        RegAddrState: 'ND',
        RegAddrZip: 58503,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '7416 RUNNEL RD',
        RegAddrCity: 'BISMARCK',
        RegAddrState: 'ND',
        RegAddrZip: 58503,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '807 SADDLE RIDGE RD',
        RegAddrCity: 'BISMARCK',
        RegAddrState: 'ND',
        RegAddrZip: 58503,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '3208 MONTANA DR',
        RegAddrCity: 'BISMARCK',
        RegAddrState: 'ND',
        RegAddrZip: 58503,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '4301 SANDY RIVER DR',
        RegAddrCity: 'BISMARCK',
        RegAddrState: 'ND',
        RegAddrZip: 58503,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '9100 BAINE DR',
        RegAddrCity: 'BISMARCK',
        RegAddrState: 'ND',
        RegAddrZip: 58503,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1416 PORTLAND DR',
        RegAddrCity: 'BISMARCK',
        RegAddrState: 'ND',
        RegAddrZip: 58504,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '216 NINA LN',
        RegAddrCity: 'BISMARCK',
        RegAddrState: 'ND',
        RegAddrZip: 58504,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '2120 SHERMAN DR',
        RegAddrCity: 'BISMARCK',
        RegAddrState: 'ND',
        RegAddrZip: 58504,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '3315 UNIVERSITY DR',
        RegAddrCity: 'BISMARCK',
        RegAddrState: 'ND',
        RegAddrZip: 58504,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '816 CALYPSO DR',
        RegAddrCity: 'BISMARCK',
        RegAddrState: 'ND',
        RegAddrZip: 58504,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '611 YORKSHIRE LN',
        RegAddrCity: 'BISMARCK',
        RegAddrState: 'ND',
        RegAddrZip: 58504,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '9859 COUNTY ROAD 49',
        RegAddrCity: 'BOTTINEAU',
        RegAddrState: 'ND',
        RegAddrZip: 58318,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '918 THOMPSON ST',
        RegAddrCity: 'BOTTINEAU',
        RegAddrState: 'ND',
        RegAddrZip: 58318,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '801 4TH ST N',
        RegAddrCity: 'CASSELTON',
        RegAddrState: 'ND',
        RegAddrZip: 58012,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '616 RIVER ST',
        RegAddrCity: 'CAVALIER',
        RegAddrState: 'ND',
        RegAddrZip: 58220,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '485 150TH AVE SE',
        RegAddrCity: 'CLIFFORD',
        RegAddrState: 'ND',
        RegAddrZip: 58016,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '702 14TH ST NE',
        RegAddrCity: 'COOPERSTOWN',
        RegAddrState: 'ND',
        RegAddrZip: 58425,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '426 4TH ST',
        RegAddrCity: 'CRYSTAL',
        RegAddrState: 'ND',
        RegAddrZip: 58222,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '419 7TH AVE SE',
        RegAddrCity: 'DEVILS LAKE',
        RegAddrState: 'ND',
        RegAddrZip: 58301,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '4548 79TH AVE NE',
        RegAddrCity: 'DEVILS LAKE',
        RegAddrState: 'ND',
        RegAddrZip: 58301,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '7965 COUNTY 1',
        RegAddrCity: 'DEVILS LAKE',
        RegAddrState: 'ND',
        RegAddrZip: 58301,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '302 12TH ST NW',
        RegAddrCity: 'DEVILS LAKE',
        RegAddrState: 'ND',
        RegAddrZip: 58301,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1080 FOSTER DR',
        RegAddrCity: 'DICKINSON',
        RegAddrState: 'ND',
        RegAddrZip: 58601,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1350 20TH ST SW',
        RegAddrCity: 'DICKINSON',
        RegAddrState: 'ND',
        RegAddrZip: 58601,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1287 14TH ST W',
        RegAddrCity: 'DICKINSON',
        RegAddrState: 'ND',
        RegAddrZip: 58601,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '818 PARK AVE',
        RegAddrCity: 'DICKINSON',
        RegAddrState: 'ND',
        RegAddrZip: 58601,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '717 1ST ST N',
        RegAddrCity: 'ELLENDALE',
        RegAddrState: 'ND',
        RegAddrZip: 58436,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1610 27TH ST NE',
        RegAddrCity: 'EMERADO',
        RegAddrState: 'ND',
        RegAddrZip: 58228,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '2010 8TH ST N',
        RegAddrCity: 'FARGO',
        RegAddrState: 'ND',
        RegAddrZip: 58102,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '2702 EVERGREEN RD N',
        RegAddrCity: 'FARGO',
        RegAddrState: 'ND',
        RegAddrZip: 58102,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1807 4TH ST N',
        RegAddrCity: 'FARGO',
        RegAddrState: 'ND',
        RegAddrZip: 58102,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '2701 ELM ST N',
        RegAddrCity: 'FARGO',
        RegAddrState: 'ND',
        RegAddrZip: 58102,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '3219 PAR ST N',
        RegAddrCity: 'FARGO',
        RegAddrState: 'ND',
        RegAddrZip: 58102,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '81 N WOODCREST DR N',
        RegAddrCity: 'FARGO',
        RegAddrState: 'ND',
        RegAddrZip: 58102,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '108 E BISON CT',
        RegAddrCity: 'FARGO',
        RegAddrState: 'ND',
        RegAddrZip: 58102,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '3102 23RD ST S',
        RegAddrCity: 'FARGO',
        RegAddrState: 'ND',
        RegAddrZip: 58103,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '3029 18TH ST S',
        RegAddrCity: 'FARGO',
        RegAddrState: 'ND',
        RegAddrZip: 58103,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '417 8TH AVE S',
        RegAddrCity: 'FARGO',
        RegAddrState: 'ND',
        RegAddrZip: 58103,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1834 15TH ST S',
        RegAddrCity: 'FARGO',
        RegAddrState: 'ND',
        RegAddrZip: 58103,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1421 9TH AVE S',
        RegAddrCity: 'FARGO',
        RegAddrState: 'ND',
        RegAddrZip: 58103,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '3314 23RD AVE S',
        RegAddrCity: 'FARGO',
        RegAddrState: 'ND',
        RegAddrZip: 58103,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '4422 BLUESTEM CT S',
        RegAddrCity: 'FARGO',
        RegAddrState: 'ND',
        RegAddrZip: 58104,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '5523 SUNFLOWER LN S',
        RegAddrCity: 'FARGO',
        RegAddrState: 'ND',
        RegAddrZip: 58104,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '5122 48TH AVE S',
        RegAddrCity: 'FARGO',
        RegAddrState: 'ND',
        RegAddrZip: 58104,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '5062 LODEN CT S',
        RegAddrCity: 'FARGO',
        RegAddrState: 'ND',
        RegAddrZip: 58104,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '3301 17TH ST S',
        RegAddrCity: 'FARGO',
        RegAddrState: 'ND',
        RegAddrZip: 58104,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '4388 52ND ST S',
        RegAddrCity: 'FARGO',
        RegAddrState: 'ND',
        RegAddrZip: 58104,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '3240 46TH AVE S',
        RegAddrCity: 'FARGO',
        RegAddrState: 'ND',
        RegAddrZip: 58104,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: 'PO BOX 5348',
        RegAddrCity: 'FARGO',
        RegAddrState: 'ND',
        RegAddrZip: 58105,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '303 7TH ST E',
        RegAddrCity: 'FINLEY',
        RegAddrState: 'ND',
        RegAddrZip: 58230,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '310 WATER TOWER RD',
        RegAddrCity: 'FORT TOTTEN',
        RegAddrState: 'ND',
        RegAddrZip: 58335,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '306 HAWK AVE',
        RegAddrCity: 'FORT YATES',
        RegAddrState: 'ND',
        RegAddrZip: 58538,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '309 W ELM AVE',
        RegAddrCity: 'GLEN ULLIN',
        RegAddrState: 'ND',
        RegAddrZip: 58631,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '7015 COUNTY ROAD 4',
        RegAddrCity: 'GRAFTON',
        RegAddrState: 'ND',
        RegAddrZip: 58237,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1625 LESSARD AVE',
        RegAddrCity: 'GRAFTON',
        RegAddrState: 'ND',
        RegAddrZip: 58237,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '436 HARVEST MOON CIR',
        RegAddrCity: 'GRAFTON',
        RegAddrState: 'ND',
        RegAddrZip: 58237,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '6679 148TH AVE NE',
        RegAddrCity: 'GRAFTON',
        RegAddrState: 'ND',
        RegAddrZip: 58237,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1311 S 18TH ST',
        RegAddrCity: 'GRAND FORKS',
        RegAddrState: 'ND',
        RegAddrZip: 58201,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1103 19TH ST NE',
        RegAddrCity: 'GRAND FORKS',
        RegAddrState: 'ND',
        RegAddrZip: 58201,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '924 S 30TH ST',
        RegAddrCity: 'GRAND FORKS',
        RegAddrState: 'ND',
        RegAddrZip: 58201,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '817 CAMPBELL DR',
        RegAddrCity: 'GRAND FORKS',
        RegAddrState: 'ND',
        RegAddrZip: 58201,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1420 S 10TH ST',
        RegAddrCity: 'GRAND FORKS',
        RegAddrState: 'ND',
        RegAddrZip: 58201,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1224 WALNUT ST',
        RegAddrCity: 'GRAND FORKS',
        RegAddrState: 'ND',
        RegAddrZip: 58201,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1516 S 14TH ST',
        RegAddrCity: 'GRAND FORKS',
        RegAddrState: 'ND',
        RegAddrZip: 58201,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '5501 CHARLIE RAY DR',
        RegAddrCity: 'GRAND FORKS',
        RegAddrState: 'ND',
        RegAddrZip: 58201,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1145 18TH ST NE',
        RegAddrCity: 'GRAND FORKS',
        RegAddrState: 'ND',
        RegAddrZip: 58201,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '2311 S WASHINGTON ST',
        RegAddrCity: 'GRAND FORKS',
        RegAddrState: 'ND',
        RegAddrZip: 58201,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1918 S 17TH ST',
        RegAddrCity: 'GRAND FORKS',
        RegAddrState: 'ND',
        RegAddrZip: 58201,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '2217 FALLCREEK CT',
        RegAddrCity: 'GRAND FORKS',
        RegAddrState: 'ND',
        RegAddrZip: 58201,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '315 WALSH HALL',
        RegAddrCity: 'GRAND FORKS',
        RegAddrState: 'ND',
        RegAddrZip: 58202,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1718 5TH AVE N',
        RegAddrCity: 'GRAND FORKS',
        RegAddrState: 'ND',
        RegAddrZip: 58203,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '2820 UNIVERSITY AVE',
        RegAddrCity: 'GRAND FORKS',
        RegAddrState: 'ND',
        RegAddrZip: 58203,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '2774 17TH ST NE',
        RegAddrCity: 'HARVEY',
        RegAddrState: 'ND',
        RegAddrZip: 58341,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '318 KENNEDY AVE',
        RegAddrCity: 'HARVEY',
        RegAddrState: 'ND',
        RegAddrZip: 58341,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '2170 29TH AVE NE',
        RegAddrCity: 'HARVEY',
        RegAddrState: 'ND',
        RegAddrZip: 58341,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '915 ANTELOPE DR',
        RegAddrCity: 'HAZEN',
        RegAddrState: 'ND',
        RegAddrZip: 58545,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1524 SUMMIT AVE',
        RegAddrCity: 'HEBRON',
        RegAddrState: 'ND',
        RegAddrZip: 58638,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '22 5TH ST NE',
        RegAddrCity: 'HILLSBORO',
        RegAddrState: 'ND',
        RegAddrZip: 58045,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '128 168TH AVE NE',
        RegAddrCity: 'HILLSBORO',
        RegAddrState: 'ND',
        RegAddrZip: 58045,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '422 4TH ST E',
        RegAddrCity: 'HORACE',
        RegAddrState: 'ND',
        RegAddrZip: 58047,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '424 MAUST WAY',
        RegAddrCity: 'HORACE',
        RegAddrState: 'ND',
        RegAddrZip: 58047,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1520 6TH AVE NE',
        RegAddrCity: 'JAMESTOWN',
        RegAddrState: 'ND',
        RegAddrZip: 58401,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '8152 31ST ST SE',
        RegAddrCity: 'JAMESTOWN',
        RegAddrState: 'ND',
        RegAddrZip: 58401,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1303 2ND AVE NW',
        RegAddrCity: 'JAMESTOWN',
        RegAddrState: 'ND',
        RegAddrZip: 58401,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '602 10TH ST SW',
        RegAddrCity: 'JAMESTOWN',
        RegAddrState: 'ND',
        RegAddrZip: 58401,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1610 ISLAND PARK DR',
        RegAddrCity: 'JAMESTOWN',
        RegAddrState: 'ND',
        RegAddrZip: 58401,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '305 BUSINESS LOOP W',
        RegAddrCity: 'JAMESTOWN',
        RegAddrState: 'ND',
        RegAddrZip: 58401,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '510 3RD AVE NW',
        RegAddrCity: 'KENMARE',
        RegAddrState: 'ND',
        RegAddrZip: 58746,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '44540 HIGHWAY 52 N',
        RegAddrCity: 'KENMARE',
        RegAddrState: 'ND',
        RegAddrZip: 58746,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '205 SOO ST',
        RegAddrCity: 'KENSAL',
        RegAddrState: 'ND',
        RegAddrZip: 58455,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '401 15TH AVE',
        RegAddrCity: 'LANGDON',
        RegAddrState: 'ND',
        RegAddrZip: 58249,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '112 BDWY ST S',
        RegAddrCity: 'LINTON',
        RegAddrState: 'ND',
        RegAddrZip: 58552,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '905 ASH ST',
        RegAddrCity: 'LISBON',
        RegAddrState: 'ND',
        RegAddrZip: 58054,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '500 8TH AVE E',
        RegAddrCity: 'LISBON',
        RegAddrState: 'ND',
        RegAddrZip: 58054,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '512 5TH AVE NW',
        RegAddrCity: 'MANDAN',
        RegAddrState: 'ND',
        RegAddrZip: 58554,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '708 2ND AVE NW',
        RegAddrCity: 'MANDAN',
        RegAddrState: 'ND',
        RegAddrZip: 58554,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '4235 COUNTY ROAD 83',
        RegAddrCity: 'MANDAN',
        RegAddrState: 'ND',
        RegAddrZip: 58554,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1106 SUNFLOWER LN SW',
        RegAddrCity: 'MANDAN',
        RegAddrState: 'ND',
        RegAddrZip: 58554,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '3211 CRIMSON ST NW',
        RegAddrCity: 'MANDAN',
        RegAddrState: 'ND',
        RegAddrZip: 58554,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '707 1/2 6TH AVE NW',
        RegAddrCity: 'MANDAN',
        RegAddrState: 'ND',
        RegAddrZip: 58554,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '307 5TH AVE NE',
        RegAddrCity: 'MANDAN',
        RegAddrState: 'ND',
        RegAddrZip: 58554,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '2283 BUSINESS LOOP I94',
        RegAddrCity: 'MANDAN',
        RegAddrState: 'ND',
        RegAddrZip: 58554,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '3601 ATHENS DR S',
        RegAddrCity: 'MANDAN',
        RegAddrState: 'ND',
        RegAddrZip: 58554,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '202 DIVISION ST NW',
        RegAddrCity: 'MANDAN',
        RegAddrState: 'ND',
        RegAddrZip: 58554,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '27 MERIDIAN RD',
        RegAddrCity: 'MAPLETON',
        RegAddrState: 'ND',
        RegAddrZip: 58059,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '2660 24TH AVE NE',
        RegAddrCity: 'MARTIN',
        RegAddrState: 'ND',
        RegAddrZip: 58758,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '108 E NYHUS AVE',
        RegAddrCity: 'MCVILLE',
        RegAddrState: 'ND',
        RegAddrZip: 58254,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '4085 51ST AVE SE',
        RegAddrCity: 'MEDINA',
        RegAddrState: 'ND',
        RegAddrZip: 58467,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '310 54TH AVE SW',
        RegAddrCity: 'MINOT',
        RegAddrState: 'ND',
        RegAddrZip: 58701,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '812 BEACON ST',
        RegAddrCity: 'MINOT',
        RegAddrState: 'ND',
        RegAddrZip: 58701,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '10701 111TH AVE SE',
        RegAddrCity: 'MINOT',
        RegAddrState: 'ND',
        RegAddrZip: 58701,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1917 4TH ST SW',
        RegAddrCity: 'MINOT',
        RegAddrState: 'ND',
        RegAddrZip: 58701,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '921 2ND AVE SE',
        RegAddrCity: 'MINOT',
        RegAddrState: 'ND',
        RegAddrZip: 58701,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1609 1ST ST SE',
        RegAddrCity: 'MINOT',
        RegAddrState: 'ND',
        RegAddrZip: 58701,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '800 15 1/2 AVE SE',
        RegAddrCity: 'MINOT',
        RegAddrState: 'ND',
        RegAddrZip: 58701,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '2801 11TH ST SE',
        RegAddrCity: 'MINOT',
        RegAddrState: 'ND',
        RegAddrZip: 58701,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '814 1ST AVE SW',
        RegAddrCity: 'MINOT',
        RegAddrState: 'ND',
        RegAddrZip: 58701,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '538 VALLEY ST',
        RegAddrCity: 'MINOT',
        RegAddrState: 'ND',
        RegAddrZip: 58701,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1700 10TH ST SW',
        RegAddrCity: 'MINOT',
        RegAddrState: 'ND',
        RegAddrZip: 58701,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1540 12TH ST NW',
        RegAddrCity: 'MINOT',
        RegAddrState: 'ND',
        RegAddrZip: 58703,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '425 20TH ST NW',
        RegAddrCity: 'MINOT',
        RegAddrState: 'ND',
        RegAddrZip: 58703,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '508 24TH AVE NW',
        RegAddrCity: 'MINOT',
        RegAddrState: 'ND',
        RegAddrZip: 58703,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '408 24TH ST NW',
        RegAddrCity: 'MINOT',
        RegAddrState: 'ND',
        RegAddrZip: 58703,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '221 8TH ST NW',
        RegAddrCity: 'MINOT',
        RegAddrState: 'ND',
        RegAddrZip: 58703,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '2021 SKYLINE DR',
        RegAddrCity: 'MINOT',
        RegAddrState: 'ND',
        RegAddrZip: 58703,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '505 8TH AVE NE',
        RegAddrCity: 'MINOT',
        RegAddrState: 'ND',
        RegAddrZip: 58703,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1913 9TH ST NW',
        RegAddrCity: 'MINOT',
        RegAddrState: 'ND',
        RegAddrZip: 58703,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '301 25TH ST NW',
        RegAddrCity: 'MINOT',
        RegAddrState: 'ND',
        RegAddrZip: 58703,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '2002 4TH ST NW',
        RegAddrCity: 'MINOT',
        RegAddrState: 'ND',
        RegAddrZip: 58703,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '148 HODSON',
        RegAddrCity: 'MINTO',
        RegAddrState: 'ND',
        RegAddrZip: 58261,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '618 12TH ST N',
        RegAddrCity: 'NEW TOWN',
        RegAddrState: 'ND',
        RegAddrZip: 58763,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '5678 123RD AVE SE',
        RegAddrCity: 'NOME',
        RegAddrState: 'ND',
        RegAddrZip: 58062,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '412 S RAYMOND ST',
        RegAddrCity: 'NORTHWOOD',
        RegAddrState: 'ND',
        RegAddrZip: 58267,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '104 S 8TH ST',
        RegAddrCity: 'OAKES',
        RegAddrState: 'ND',
        RegAddrZip: 58474,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '438 TRENT JONES DR',
        RegAddrCity: 'OXBOW',
        RegAddrState: 'ND',
        RegAddrZip: 58047,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '7448 111TH AVE SW',
        RegAddrCity: 'REGENT',
        RegAddrState: 'ND',
        RegAddrZip: 58650,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '3611 50TH ST N',
        RegAddrCity: 'REILES ACRES',
        RegAddrState: 'ND',
        RegAddrZip: 58102,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '310 2ND ST NE',
        RegAddrCity: 'ROLLA',
        RegAddrState: 'ND',
        RegAddrZip: 58367,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '128 DAKOTA ST',
        RegAddrCity: 'RUTLAND',
        RegAddrState: 'ND',
        RegAddrZip: 58067,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '14600 205TH AVE SE',
        RegAddrCity: 'SAWYER',
        RegAddrState: 'ND',
        RegAddrZip: 58781,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '12609 99TH ST SW',
        RegAddrCity: 'SCRANTON',
        RegAddrState: 'ND',
        RegAddrZip: 58653,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '8806 133RD AVE SW',
        RegAddrCity: 'SCRANTON',
        RegAddrState: 'ND',
        RegAddrZip: 58653,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '141 LAKE AVE',
        RegAddrCity: 'SIBLEY',
        RegAddrState: 'ND',
        RegAddrZip: 58429,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '249 106TH ST NW',
        RegAddrCity: 'SOURIS',
        RegAddrState: 'ND',
        RegAddrZip: 58783,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '3729 BIA ROAD 4',
        RegAddrCity: 'ST JOHN',
        RegAddrState: 'ND',
        RegAddrZip: 58369,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '621 1ST ST SW',
        RegAddrCity: 'STEELE',
        RegAddrState: 'ND',
        RegAddrZip: 58482,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '604 SURREY AVE',
        RegAddrCity: 'SURREY',
        RegAddrState: 'ND',
        RegAddrZip: 58785,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '207 GARFIELD AVE',
        RegAddrCity: 'UNDERWOOD',
        RegAddrState: 'ND',
        RegAddrZip: 58576,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '3373 119TH AVE SE',
        RegAddrCity: 'VALLEY CITY',
        RegAddrState: 'ND',
        RegAddrZip: 58072,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '812 4TH AVE SW',
        RegAddrCity: 'VALLEY CITY',
        RegAddrState: 'ND',
        RegAddrZip: 58072,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1523 6TH AVE N',
        RegAddrCity: 'WAHPETON',
        RegAddrState: 'ND',
        RegAddrZip: 58075,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '418 1ST ST N',
        RegAddrCity: 'WAHPETON',
        RegAddrState: 'ND',
        RegAddrZip: 58075,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '705 18TH AVE N',
        RegAddrCity: 'WAHPETON',
        RegAddrState: 'ND',
        RegAddrZip: 58075,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '2104 2ND ST N',
        RegAddrCity: 'WAHPETON',
        RegAddrState: 'ND',
        RegAddrZip: 58075,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '105 5TH ST SE',
        RegAddrCity: 'WATFORD CITY',
        RegAddrState: 'ND',
        RegAddrZip: 58854,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '418 3RD ST E',
        RegAddrCity: 'WEST FARGO',
        RegAddrState: 'ND',
        RegAddrZip: 58078,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '540 PRESCOTT PL',
        RegAddrCity: 'WEST FARGO',
        RegAddrState: 'ND',
        RegAddrZip: 58078,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1514 SHEYENNE ST',
        RegAddrCity: 'WEST FARGO',
        RegAddrState: 'ND',
        RegAddrZip: 58078,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '153 17TH AVE W',
        RegAddrCity: 'WEST FARGO',
        RegAddrState: 'ND',
        RegAddrZip: 58078,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '2084 2ND AVE E',
        RegAddrCity: 'WEST FARGO',
        RegAddrState: 'ND',
        RegAddrZip: 58078,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '3256 9TH ST W',
        RegAddrCity: 'WEST FARGO',
        RegAddrState: 'ND',
        RegAddrZip: 58078,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '109 1ST AVE W',
        RegAddrCity: 'WEST FARGO',
        RegAddrState: 'ND',
        RegAddrZip: 58078,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1623 8TH ST E',
        RegAddrCity: 'WEST FARGO',
        RegAddrState: 'ND',
        RegAddrZip: 58078,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '618 11TH ST W',
        RegAddrCity: 'WILLISTON',
        RegAddrState: 'ND',
        RegAddrZip: 58801,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '3001 29TH ST W',
        RegAddrCity: 'WILLISTON',
        RegAddrState: 'ND',
        RegAddrZip: 58801,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '809 27TH ST W',
        RegAddrCity: 'WILLISTON',
        RegAddrState: 'ND',
        RegAddrZip: 58801,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '14129 HIGHWAY 2',
        RegAddrCity: 'WILLISTON',
        RegAddrState: 'ND',
        RegAddrZip: 58801,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1506 2ND AVE E',
        RegAddrCity: 'WILLISTON',
        RegAddrState: 'ND',
        RegAddrZip: 58801,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '2416 15TH ST E',
        RegAddrCity: 'WILLISTON',
        RegAddrState: 'ND',
        RegAddrZip: 58801,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '13385 53X CT',
        RegAddrCity: 'WILLISTON',
        RegAddrState: 'ND',
        RegAddrZip: 58801,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1017 18TH ST W',
        RegAddrCity: 'WILLISTON',
        RegAddrState: 'ND',
        RegAddrZip: 58801,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '13701 KRISTINA ST',
        RegAddrCity: 'WILLISTON',
        RegAddrState: 'ND',
        RegAddrZip: 58801,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '320 S 5TH ST',
        RegAddrCity: 'WISHEK',
        RegAddrState: 'ND',
        RegAddrZip: 58495,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '515 S 4TH ST',
        RegAddrCity: 'WISHEK',
        RegAddrState: 'ND',
        RegAddrZip: 58495,
        CongressionalDistrict: 0,
      },
    ],
  },
  {
    name: 'NE-1',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/NE-1?ref=gh-pages',
    state: 'NE',
    state_and_number: 'NE-1',
    zip: 68001,
    congressionalDistrict: 1,
    addressesInDistrict: [
      {
        RegAddrLine1: '1771 51ST HWY',
        RegAddrCity: 'BANCROFT',
        RegAddrState: 'NE',
        RegAddrZip: 68004,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2601 WAYNE ST',
        RegAddrCity: 'BELLEVUE',
        RegAddrState: 'NE',
        RegAddrZip: 68005,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1103 KOUNTZE MEMORIAL DR',
        RegAddrCity: 'BELLEVUE',
        RegAddrState: 'NE',
        RegAddrZip: 68005,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2530 JEFFERSON ST',
        RegAddrCity: 'BELLEVUE',
        RegAddrState: 'NE',
        RegAddrZip: 68005,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '3724 GAYLE AVE',
        RegAddrCity: 'BELLEVUE',
        RegAddrState: 'NE',
        RegAddrZip: 68123,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '11803 S 31ST ST',
        RegAddrCity: 'BELLEVUE',
        RegAddrState: 'NE',
        RegAddrZip: 68123,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '13603 S 27TH ST',
        RegAddrCity: 'BELLEVUE',
        RegAddrState: 'NE',
        RegAddrZip: 68123,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '13311 S 28TH ST',
        RegAddrCity: 'BELLEVUE',
        RegAddrState: 'NE',
        RegAddrZip: 68123,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '14208 S 22ND ST',
        RegAddrCity: 'BELLEVUE',
        RegAddrState: 'NE',
        RegAddrZip: 68123,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '7106 S 50TH ST',
        RegAddrCity: 'BELLEVUE',
        RegAddrState: 'NE',
        RegAddrZip: 68157,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '822 N 25TH AVE',
        RegAddrCity: 'BLAIR',
        RegAddrState: 'NE',
        RegAddrZip: 68008,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2708 SUNRISE DR',
        RegAddrCity: 'BLAIR',
        RegAddrState: 'NE',
        RegAddrZip: 68008,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '3619 21ST ST',
        RegAddrCity: 'COLUMBUS',
        RegAddrState: 'NE',
        RegAddrZip: 68601,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1653 27TH AVE',
        RegAddrCity: 'COLUMBUS',
        RegAddrState: 'NE',
        RegAddrZip: 68601,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '6168 COUNTRY CLUB DR',
        RegAddrCity: 'COLUMBUS',
        RegAddrState: 'NE',
        RegAddrZip: 68601,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '32100 122ND AVE',
        RegAddrCity: 'COLUMBUS',
        RegAddrState: 'NE',
        RegAddrZip: 68601,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '3804 H RD',
        RegAddrCity: 'DUNBAR',
        RegAddrState: 'NE',
        RegAddrZip: 68346,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '814 214TH ST',
        RegAddrCity: 'EAGLE',
        RegAddrState: 'NE',
        RegAddrZip: 68347,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1320 N SOMERS AVE',
        RegAddrCity: 'FREMONT',
        RegAddrState: 'NE',
        RegAddrZip: 68025,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '4235 PRAIRIE RD',
        RegAddrCity: 'FREMONT',
        RegAddrState: 'NE',
        RegAddrZip: 68025,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2506 E 10TH ST',
        RegAddrCity: 'FREMONT',
        RegAddrState: 'NE',
        RegAddrZip: 68025,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '943 E 6TH ST',
        RegAddrCity: 'FREMONT',
        RegAddrState: 'NE',
        RegAddrZip: 68025,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '10298 250TH ST',
        RegAddrCity: 'GREENWOOD',
        RegAddrState: 'NE',
        RegAddrZip: 68366,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '25656 SW 86TH ST',
        RegAddrCity: 'HALLAM',
        RegAddrState: 'NE',
        RegAddrZip: 68368,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '3437 S 29TH ST',
        RegAddrCity: 'LINCOLN',
        RegAddrState: 'NE',
        RegAddrZip: 68502,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '601 N 46TH ST',
        RegAddrCity: 'LINCOLN',
        RegAddrState: 'NE',
        RegAddrZip: 68503,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2601 DUDLEY ST',
        RegAddrCity: 'LINCOLN',
        RegAddrState: 'NE',
        RegAddrZip: 68503,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1723 N 24TH ST',
        RegAddrCity: 'LINCOLN',
        RegAddrState: 'NE',
        RegAddrZip: 68503,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '4833 MEREDETH ST',
        RegAddrCity: 'LINCOLN',
        RegAddrState: 'NE',
        RegAddrZip: 68506,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '3727 S 78TH ST',
        RegAddrCity: 'LINCOLN',
        RegAddrState: 'NE',
        RegAddrZip: 68506,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '4850 HILLSIDE ST',
        RegAddrCity: 'LINCOLN',
        RegAddrState: 'NE',
        RegAddrZip: 68506,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '6004 KEARNEY AVE',
        RegAddrCity: 'LINCOLN',
        RegAddrState: 'NE',
        RegAddrZip: 68507,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1801 J ST',
        RegAddrCity: 'LINCOLN',
        RegAddrState: 'NE',
        RegAddrZip: 68508,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '334 S 28TH ST',
        RegAddrCity: 'LINCOLN',
        RegAddrState: 'NE',
        RegAddrZip: 68510,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '711 S 37TH ST',
        RegAddrCity: 'LINCOLN',
        RegAddrState: 'NE',
        RegAddrZip: 68510,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '4701 LIVINGSTON PL',
        RegAddrCity: 'LINCOLN',
        RegAddrState: 'NE',
        RegAddrZip: 68510,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1100 TWIN RIDGE RD',
        RegAddrCity: 'LINCOLN',
        RegAddrState: 'NE',
        RegAddrZip: 68510,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '205 S 35TH ST',
        RegAddrCity: 'LINCOLN',
        RegAddrState: 'NE',
        RegAddrZip: 68510,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2211 JENNA LN',
        RegAddrCity: 'LINCOLN',
        RegAddrState: 'NE',
        RegAddrZip: 68512,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '6700 RIDGE RD',
        RegAddrCity: 'LINCOLN',
        RegAddrState: 'NE',
        RegAddrZip: 68512,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '5020 SUGAR CREEK RD',
        RegAddrCity: 'LINCOLN',
        RegAddrState: 'NE',
        RegAddrZip: 68516,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '3001 RIDGEGATE',
        RegAddrCity: 'LINCOLN',
        RegAddrState: 'NE',
        RegAddrZip: 68516,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '7045 BEAVER HOLLOW CIR',
        RegAddrCity: 'LINCOLN',
        RegAddrState: 'NE',
        RegAddrZip: 68516,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '9121 S 80TH ST',
        RegAddrCity: 'LINCOLN',
        RegAddrState: 'NE',
        RegAddrZip: 68516,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1404 STONYHILL RD',
        RegAddrCity: 'LINCOLN',
        RegAddrState: 'NE',
        RegAddrZip: 68520,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '5501 NW 10TH ST',
        RegAddrCity: 'LINCOLN',
        RegAddrState: 'NE',
        RegAddrZip: 68521,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '7430 N 16TH ST',
        RegAddrCity: 'LINCOLN',
        RegAddrState: 'NE',
        RegAddrZip: 68521,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '900 W DAWES AVE',
        RegAddrCity: 'LINCOLN',
        RegAddrState: 'NE',
        RegAddrZip: 68521,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '7042 NW 19TH ST',
        RegAddrCity: 'LINCOLN',
        RegAddrState: 'NE',
        RegAddrZip: 68521,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1235 GARBER AVE',
        RegAddrCity: 'LINCOLN',
        RegAddrState: 'NE',
        RegAddrZip: 68521,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1830 SW 17TH ST',
        RegAddrCity: 'LINCOLN',
        RegAddrState: 'NE',
        RegAddrZip: 68522,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '801 W PROSPECTOR PL',
        RegAddrCity: 'LINCOLN',
        RegAddrState: 'NE',
        RegAddrZip: 68522,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1908 SW 21ST ST',
        RegAddrCity: 'LINCOLN',
        RegAddrState: 'NE',
        RegAddrZip: 68522,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2401 SW PAUL WHITEHEAD LN',
        RegAddrCity: 'LINCOLN',
        RegAddrState: 'NE',
        RegAddrZip: 68522,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '5631 S 93RD PL',
        RegAddrCity: 'LINCOLN',
        RegAddrState: 'NE',
        RegAddrZip: 68526,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '',
        RegAddrCity: 'Macy',
        RegAddrState: 'NE',
        RegAddrZip: 68039,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '317 4TH ST',
        RegAddrCity: 'MILFORD',
        RegAddrState: 'NE',
        RegAddrZip: 68405,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '819 1ST ST',
        RegAddrCity: 'MILFORD',
        RegAddrState: 'NE',
        RegAddrZip: 68405,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '107 21ST DR',
        RegAddrCity: 'NORFOLK',
        RegAddrState: 'NE',
        RegAddrZip: 68701,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '408 ROLAND ST',
        RegAddrCity: 'NORFOLK',
        RegAddrState: 'NE',
        RegAddrZip: 68701,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '102 N OAKLAND AVE',
        RegAddrCity: 'OAKLAND',
        RegAddrState: 'NE',
        RegAddrZip: 68045,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '11000 S 27TH ST',
        RegAddrCity: 'ROCA',
        RegAddrState: 'NE',
        RegAddrZip: 68430,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '407 W 20TH ST',
        RegAddrCity: 'SCHUYLER',
        RegAddrState: 'NE',
        RegAddrZip: 68661,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1023 ROAD 14',
        RegAddrCity: 'SCHUYLER',
        RegAddrState: 'NE',
        RegAddrZip: 68661,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '630 B ST',
        RegAddrCity: 'UTICA',
        RegAddrState: 'NE',
        RegAddrZip: 68456,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1862 COUNTY ROAD INTERSTATE',
        RegAddrCity: 'WAHOO',
        RegAddrState: 'NE',
        RegAddrZip: 68066,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1040 W 10TH ST',
        RegAddrCity: 'WAHOO',
        RegAddrState: 'NE',
        RegAddrZip: 68066,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '3032 HIGHWAY 94',
        RegAddrCity: 'WALTHILL',
        RegAddrState: 'NE',
        RegAddrZip: 68067,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '14005 N 162ND ST',
        RegAddrCity: 'WAVERLY',
        RegAddrState: 'NE',
        RegAddrZip: 68462,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '16211 HIGHWAY 1',
        RegAddrCity: 'WEEPING WATER',
        RegAddrState: 'NE',
        RegAddrZip: 68463,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '232 22ND RD',
        RegAddrCity: 'WEST POINT',
        RegAddrState: 'NE',
        RegAddrZip: 68788,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '531 ELM ST',
        RegAddrCity: 'WINNEBAGO',
        RegAddrState: 'NE',
        RegAddrZip: 68071,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '812 9TH ST',
        RegAddrCity: 'WISNER',
        RegAddrState: 'NE',
        RegAddrZip: 68791,
        CongressionalDistrict: 1,
      },
    ],
  },
  {
    name: 'NE-2',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/NE-2?ref=gh-pages',
    state: 'NE',
    state_and_number: 'NE-2',
    zip: 68010,
    congressionalDistrict: 2,
    addressesInDistrict: [
      {
        RegAddrLine1: '8889 N 180TH ST',
        RegAddrCity: 'BENNINGTON',
        RegAddrState: 'NE',
        RegAddrZip: 68007,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '20275 HOPPER ST',
        RegAddrCity: 'ELKHORN',
        RegAddrState: 'NE',
        RegAddrZip: 68022,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '3916 N 208TH ST',
        RegAddrCity: 'ELKHORN',
        RegAddrState: 'NE',
        RegAddrZip: 68022,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '18810 LAFAYETTE AVE',
        RegAddrCity: 'ELKHORN',
        RegAddrState: 'NE',
        RegAddrZip: 68022,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '330 W GLENMORE DR',
        RegAddrCity: 'GRETNA',
        RegAddrState: 'NE',
        RegAddrZip: 68028,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '13155 S 219TH ST',
        RegAddrCity: 'GRETNA',
        RegAddrState: 'NE',
        RegAddrZip: 68028,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '7929 S 69TH ST',
        RegAddrCity: 'LA VISTA',
        RegAddrState: 'NE',
        RegAddrZip: 68128,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '3121 MEADOW CT',
        RegAddrCity: 'NORTH PLATTE',
        RegAddrState: 'NE',
        RegAddrZip: 69101,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1702 N 60TH ST',
        RegAddrCity: 'OMAHA',
        RegAddrState: 'NE',
        RegAddrZip: 68104,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2625 N 67TH ST',
        RegAddrCity: 'OMAHA',
        RegAddrState: 'NE',
        RegAddrZip: 68104,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '5104 DECATUR ST',
        RegAddrCity: 'OMAHA',
        RegAddrState: 'NE',
        RegAddrZip: 68104,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '6653 NEBRASKA AVE',
        RegAddrCity: 'OMAHA',
        RegAddrState: 'NE',
        RegAddrZip: 68104,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '4802 WIRT ST',
        RegAddrCity: 'OMAHA',
        RegAddrState: 'NE',
        RegAddrZip: 68104,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '3943 N 54TH ST',
        RegAddrCity: 'OMAHA',
        RegAddrState: 'NE',
        RegAddrZip: 68104,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '3001 S 43RD ST',
        RegAddrCity: 'OMAHA',
        RegAddrState: 'NE',
        RegAddrZip: 68105,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '506 S 34TH ST',
        RegAddrCity: 'OMAHA',
        RegAddrState: 'NE',
        RegAddrZip: 68105,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '4366 MASON ST',
        RegAddrCity: 'OMAHA',
        RegAddrState: 'NE',
        RegAddrZip: 68105,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '4888 VINTON ST',
        RegAddrCity: 'OMAHA',
        RegAddrState: 'NE',
        RegAddrZip: 68106,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '5816 VALLEY CIR',
        RegAddrCity: 'OMAHA',
        RegAddrState: 'NE',
        RegAddrZip: 68106,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2730 MADISON ST',
        RegAddrCity: 'OMAHA',
        RegAddrState: 'NE',
        RegAddrZip: 68107,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '4612 S 32ND ST',
        RegAddrCity: 'OMAHA',
        RegAddrState: 'NE',
        RegAddrZip: 68107,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2464 S 3RD STREET PLZ',
        RegAddrCity: 'OMAHA',
        RegAddrState: 'NE',
        RegAddrZip: 68108,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2423 S 3RD STREET PLZ',
        RegAddrCity: 'OMAHA',
        RegAddrState: 'NE',
        RegAddrZip: 68108,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2632 S 15TH ST',
        RegAddrCity: 'OMAHA',
        RegAddrState: 'NE',
        RegAddrZip: 68108,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '3510 LAUREL AVE',
        RegAddrCity: 'OMAHA',
        RegAddrState: 'NE',
        RegAddrZip: 68111,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2812 PRATT ST',
        RegAddrCity: 'OMAHA',
        RegAddrState: 'NE',
        RegAddrZip: 68111,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '3922 IDA ST',
        RegAddrCity: 'OMAHA',
        RegAddrState: 'NE',
        RegAddrZip: 68112,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '7718 N 28TH ST',
        RegAddrCity: 'OMAHA',
        RegAddrState: 'NE',
        RegAddrZip: 68112,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2438 TITUS AVE',
        RegAddrCity: 'OMAHA',
        RegAddrState: 'NE',
        RegAddrZip: 68112,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1018 N 78TH ST',
        RegAddrCity: 'OMAHA',
        RegAddrState: 'NE',
        RegAddrZip: 68114,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '15415 BURDETTE ST',
        RegAddrCity: 'OMAHA',
        RegAddrState: 'NE',
        RegAddrZip: 68116,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '15225 JAYNES ST',
        RegAddrCity: 'OMAHA',
        RegAddrState: 'NE',
        RegAddrZip: 68116,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2616 N 145TH AVE',
        RegAddrCity: 'OMAHA',
        RegAddrState: 'NE',
        RegAddrZip: 68116,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '4615 N 165TH ST',
        RegAddrCity: 'OMAHA',
        RegAddrState: 'NE',
        RegAddrZip: 68116,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '16914 BROWNE ST',
        RegAddrCity: 'OMAHA',
        RegAddrState: 'NE',
        RegAddrZip: 68116,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '5029 S 56TH ST',
        RegAddrCity: 'OMAHA',
        RegAddrState: 'NE',
        RegAddrZip: 68117,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '210 N 159TH CIR',
        RegAddrCity: 'OMAHA',
        RegAddrState: 'NE',
        RegAddrZip: 68118,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1221 S 107TH ST',
        RegAddrCity: 'OMAHA',
        RegAddrState: 'NE',
        RegAddrZip: 68124,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2009 S 88TH ST',
        RegAddrCity: 'OMAHA',
        RegAddrState: 'NE',
        RegAddrZip: 68124,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '8119 CASTELAR ST',
        RegAddrCity: 'OMAHA',
        RegAddrState: 'NE',
        RegAddrZip: 68124,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1148 S 93RD AVE',
        RegAddrCity: 'OMAHA',
        RegAddrState: 'NE',
        RegAddrZip: 68124,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '4941 S 106TH CIR',
        RegAddrCity: 'OMAHA',
        RegAddrState: 'NE',
        RegAddrZip: 68127,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '5336 S 107TH AVE',
        RegAddrCity: 'OMAHA',
        RegAddrState: 'NE',
        RegAddrZip: 68127,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '8911 Q ST',
        RegAddrCity: 'OMAHA',
        RegAddrState: 'NE',
        RegAddrZip: 68127,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '3816 DAVENPORT ST',
        RegAddrCity: 'OMAHA',
        RegAddrState: 'NE',
        RegAddrZip: 68131,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '6617 GLENWOOD RD',
        RegAddrCity: 'OMAHA',
        RegAddrState: 'NE',
        RegAddrZip: 68132,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '4914 1/2 CALIFORNIA ST',
        RegAddrCity: 'OMAHA',
        RegAddrState: 'NE',
        RegAddrZip: 68132,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '10048 GRAND PLZ',
        RegAddrCity: 'OMAHA',
        RegAddrState: 'NE',
        RegAddrZip: 68134,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '3624 N 92ND CIR',
        RegAddrCity: 'OMAHA',
        RegAddrState: 'NE',
        RegAddrZip: 68134,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '9437 TAYLOR ST',
        RegAddrCity: 'OMAHA',
        RegAddrState: 'NE',
        RegAddrZip: 68134,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '16008 ORCHARD CIR',
        RegAddrCity: 'OMAHA',
        RegAddrState: 'NE',
        RegAddrZip: 68135,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '17234 POLK CIR',
        RegAddrCity: 'OMAHA',
        RegAddrState: 'NE',
        RegAddrZip: 68135,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '5606 S 160TH ST',
        RegAddrCity: 'OMAHA',
        RegAddrState: 'NE',
        RegAddrZip: 68135,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '17831 ENGLEWOOD CIR',
        RegAddrCity: 'OMAHA',
        RegAddrState: 'NE',
        RegAddrZip: 68135,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '8020 S 167TH ST',
        RegAddrCity: 'OMAHA',
        RegAddrState: 'NE',
        RegAddrZip: 68136,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '16103 GERTRUDE ST',
        RegAddrCity: 'OMAHA',
        RegAddrState: 'NE',
        RegAddrZip: 68136,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '16713 GERTRUDE ST',
        RegAddrCity: 'OMAHA',
        RegAddrState: 'NE',
        RegAddrZip: 68136,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '13980 BIRCHWOOD AVE',
        RegAddrCity: 'OMAHA',
        RegAddrState: 'NE',
        RegAddrZip: 68137,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '12724 SKY PARK DR',
        RegAddrCity: 'OMAHA',
        RegAddrState: 'NE',
        RegAddrZip: 68137,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '13308 CHANDLER CT',
        RegAddrCity: 'OMAHA',
        RegAddrState: 'NE',
        RegAddrZip: 68138,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '15239 GROVER ST',
        RegAddrCity: 'OMAHA',
        RegAddrState: 'NE',
        RegAddrZip: 68144,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '3116 S 117TH ST',
        RegAddrCity: 'OMAHA',
        RegAddrState: 'NE',
        RegAddrZip: 68144,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '12442 C ST',
        RegAddrCity: 'OMAHA',
        RegAddrState: 'NE',
        RegAddrZip: 68144,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '6142 COUNTRY CLUB RD',
        RegAddrCity: 'OMAHA',
        RegAddrState: 'NE',
        RegAddrZip: 68152,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '11811 MASON PLZ',
        RegAddrCity: 'OMAHA',
        RegAddrState: 'NE',
        RegAddrZip: 68154,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '914 N 143RD CIR',
        RegAddrCity: 'OMAHA',
        RegAddrState: 'NE',
        RegAddrZip: 68154,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '12006 FARNAM ST',
        RegAddrCity: 'OMAHA',
        RegAddrState: 'NE',
        RegAddrZip: 68154,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '13320 LAKE ST',
        RegAddrCity: 'OMAHA',
        RegAddrState: 'NE',
        RegAddrZip: 68164,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '5606 N 112TH CIR',
        RegAddrCity: 'OMAHA',
        RegAddrState: 'NE',
        RegAddrZip: 68164,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2429 N 112TH ST',
        RegAddrCity: 'OMAHA',
        RegAddrState: 'NE',
        RegAddrZip: 68164,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '4406 N 143RD ST',
        RegAddrCity: 'OMAHA',
        RegAddrState: 'NE',
        RegAddrZip: 68164,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '445 S MADISON ST',
        RegAddrCity: 'PAPILLION',
        RegAddrState: 'NE',
        RegAddrZip: 68046,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '802 LEXINGTON LN',
        RegAddrCity: 'PAPILLION',
        RegAddrState: 'NE',
        RegAddrZip: 68046,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '820 OAKLAND LN',
        RegAddrCity: 'PAPILLION',
        RegAddrState: 'NE',
        RegAddrZip: 68046,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2008 SUNBURST DR',
        RegAddrCity: 'PAPILLION',
        RegAddrState: 'NE',
        RegAddrZip: 68046,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '912 CLAUDINE AVE',
        RegAddrCity: 'PAPILLION',
        RegAddrState: 'NE',
        RegAddrZip: 68046,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1503 BEECHWOOD AVE',
        RegAddrCity: 'PAPILLION',
        RegAddrState: 'NE',
        RegAddrZip: 68133,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '18006 COTTONWOOD LN',
        RegAddrCity: 'SPRINGFIELD',
        RegAddrState: 'NE',
        RegAddrZip: 68059,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '3804 N 269TH AVE',
        RegAddrCity: 'VALLEY',
        RegAddrState: 'NE',
        RegAddrZip: 68064,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '340 SHOREWOOD LN',
        RegAddrCity: 'WATERLOO',
        RegAddrState: 'NE',
        RegAddrZip: 68069,
        CongressionalDistrict: 2,
      },
    ],
  },
  {
    name: 'NE-3',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/NE-3?ref=gh-pages',
    state: 'NE',
    state_and_number: 'NE-3',
    zip: 57078,
    congressionalDistrict: 3,
    addressesInDistrict: [
      {
        RegAddrLine1: '394 N MAPLE ST',
        RegAddrCity: 'AINSWORTH',
        RegAddrState: 'NE',
        RegAddrZip: 69210,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '42041 DRIVE 728',
        RegAddrCity: 'ARAPAHOE',
        RegAddrState: 'NE',
        RegAddrZip: 68922,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1718 9TH ST',
        RegAddrCity: 'AURORA',
        RegAddrState: 'NE',
        RegAddrZip: 68818,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '2940 W PONY EXPRESS RD',
        RegAddrCity: 'AYR',
        RegAddrState: 'NE',
        RegAddrZip: 68925,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1505 N 11TH ST',
        RegAddrCity: 'BEATRICE',
        RegAddrState: 'NE',
        RegAddrZip: 68310,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '209 N 11TH ST',
        RegAddrCity: 'BEATRICE',
        RegAddrState: 'NE',
        RegAddrZip: 68310,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '43070 MCKINLEY RD',
        RegAddrCity: 'BROKEN BOW',
        RegAddrState: 'NE',
        RegAddrZip: 68822,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1408 BIRDIE BLVD',
        RegAddrCity: 'CAIRO',
        RegAddrState: 'NE',
        RegAddrZip: 68824,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '2595 15TH RD',
        RegAddrCity: 'CENTRAL CITY',
        RegAddrState: 'NE',
        RegAddrZip: 68826,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '245 MAPLE ST',
        RegAddrCity: 'CHADRON',
        RegAddrState: 'NE',
        RegAddrZip: 69337,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '713 THAYER AVE',
        RegAddrCity: 'CHESTER',
        RegAddrState: 'NE',
        RegAddrZip: 68327,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '313 E THURBER ST',
        RegAddrCity: 'CLAY CENTER',
        RegAddrState: 'NE',
        RegAddrZip: 68933,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '73631 627 AVE',
        RegAddrCity: 'COOK',
        RegAddrState: 'NE',
        RegAddrZip: 68329,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '55511 894 RD',
        RegAddrCity: 'CROFTON',
        RegAddrState: 'NE',
        RegAddrZip: 68730,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '73055 AVENUE 376A',
        RegAddrCity: 'CULBERTSON',
        RegAddrState: 'NE',
        RegAddrZip: 69024,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '56960 HIGHWAY 4',
        RegAddrCity: 'DAYKIN',
        RegAddrState: 'NE',
        RegAddrZip: 68338,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '70307 HIGHWAY 50',
        RegAddrCity: 'DUBOIS',
        RegAddrState: 'NE',
        RegAddrZip: 68345,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1228 D ST',
        RegAddrCity: 'FAIRBURY',
        RegAddrState: 'NE',
        RegAddrZip: 68352,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '421 E 25TH ST',
        RegAddrCity: 'FALLS CITY',
        RegAddrState: 'NE',
        RegAddrZip: 68355,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '88766 559 AVE',
        RegAddrCity: 'FORDYCE',
        RegAddrState: 'NE',
        RegAddrZip: 68736,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1008 18TH AVE',
        RegAddrCity: 'FRANKLIN',
        RegAddrState: 'NE',
        RegAddrZip: 68939,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '426 CARL ST',
        RegAddrCity: 'FULLERTON',
        RegAddrState: 'NE',
        RegAddrZip: 68638,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1935 S ST',
        RegAddrCity: 'GERING',
        RegAddrState: 'NE',
        RegAddrZip: 69341,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '668 GROFF ST',
        RegAddrCity: 'GRAND ISLAND',
        RegAddrState: 'NE',
        RegAddrZip: 68801,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '623 LINDEN AVE',
        RegAddrCity: 'GRAND ISLAND',
        RegAddrState: 'NE',
        RegAddrZip: 68801,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '216 LAKESIDE DR',
        RegAddrCity: 'GRAND ISLAND',
        RegAddrState: 'NE',
        RegAddrZip: 68801,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '3224 W 18TH ST',
        RegAddrCity: 'GRAND ISLAND',
        RegAddrState: 'NE',
        RegAddrZip: 68803,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '395 SOWBELLY RD',
        RegAddrCity: 'HARRISON',
        RegAddrState: 'NE',
        RegAddrZip: 69346,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '2704 W 5TH ST',
        RegAddrCity: 'HASTINGS',
        RegAddrState: 'NE',
        RegAddrZip: 68901,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1060 ROAD 6900',
        RegAddrCity: 'HEBRON',
        RegAddrState: 'NE',
        RegAddrZip: 68370,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '707 MILES ST',
        RegAddrCity: 'HEMINGFORD',
        RegAddrState: 'NE',
        RegAddrZip: 69348,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1104 ROAD B',
        RegAddrCity: 'HENDERSON',
        RegAddrState: 'NE',
        RegAddrZip: 68371,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '419 LOGAN ST',
        RegAddrCity: 'HOLDREGE',
        RegAddrState: 'NE',
        RegAddrZip: 68949,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '72717 631 AVE',
        RegAddrCity: 'JOHNSON',
        RegAddrState: 'NE',
        RegAddrZip: 68378,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '415 W 32ND ST',
        RegAddrCity: 'KEARNEY',
        RegAddrState: 'NE',
        RegAddrZip: 68845,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1111 D AVE',
        RegAddrCity: 'KEARNEY',
        RegAddrState: 'NE',
        RegAddrZip: 68847,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '4020 I AVE',
        RegAddrCity: 'KEARNEY',
        RegAddrState: 'NE',
        RegAddrZip: 68847,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '5107 S AVE',
        RegAddrCity: 'KEARNEY',
        RegAddrState: 'NE',
        RegAddrZip: 68847,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '503 E 8TH ST',
        RegAddrCity: 'LEXINGTON',
        RegAddrState: 'NE',
        RegAddrZip: 68850,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '78849 HIGHWAY 58',
        RegAddrCity: 'LOUP CITY',
        RegAddrState: 'NE',
        RegAddrZip: 68853,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '522 M ST',
        RegAddrCity: 'LOUP CITY',
        RegAddrState: 'NE',
        RegAddrZip: 68853,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '113 TIMBERCOVE RD',
        RegAddrCity: 'MARQUETTE',
        RegAddrState: 'NE',
        RegAddrZip: 68854,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '280094 COUNTY ROAD P',
        RegAddrCity: 'MINATARE',
        RegAddrState: 'NE',
        RegAddrZip: 69356,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1503 19TH ST',
        RegAddrCity: 'MITCHELL',
        RegAddrState: 'NE',
        RegAddrZip: 69357,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '615 W 5TH ST',
        RegAddrCity: 'NORTH PLATTE',
        RegAddrState: 'NE',
        RegAddrZip: 69101,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1120 WILLIAM CT',
        RegAddrCity: 'NORTH PLATTE',
        RegAddrState: 'NE',
        RegAddrZip: 69101,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '802 CLEARWATER CT',
        RegAddrCity: 'NORTH PLATTE',
        RegAddrState: 'NE',
        RegAddrZip: 69101,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '170 WATSON DR',
        RegAddrCity: 'NORTH PLATTE',
        RegAddrState: 'NE',
        RegAddrZip: 69101,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1929 ROAD 4300',
        RegAddrCity: 'OAK',
        RegAddrState: 'NE',
        RegAddrZip: 68964,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '102 W 3 ST',
        RegAddrCity: 'PAXTON',
        RegAddrState: 'NE',
        RegAddrZip: 69155,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '110 PLATTE VIEW DR',
        RegAddrCity: 'PHILLIPS',
        RegAddrState: 'NE',
        RegAddrZip: 68865,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '84561 COTTONWOOD AVE',
        RegAddrCity: 'PURDUM',
        RegAddrState: 'NE',
        RegAddrZip: 69157,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '36840 SAINT MICHAEL RD',
        RegAddrCity: 'RAVENNA',
        RegAddrState: 'NE',
        RegAddrZip: 68869,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '508 E 2ND ST',
        RegAddrCity: 'RUSHVILLE',
        RegAddrState: 'NE',
        RegAddrZip: 69360,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '124 E 21ST ST',
        RegAddrCity: 'S SIOUX CITY',
        RegAddrState: 'NE',
        RegAddrZip: 68776,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '3649 EVERGREEN DR',
        RegAddrCity: 'SCOTTSBLUFF',
        RegAddrState: 'NE',
        RegAddrZip: 69361,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1410 AVENUE G',
        RegAddrCity: 'SCOTTSBLUFF',
        RegAddrState: 'NE',
        RegAddrZip: 69361,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1806 ELM ST',
        RegAddrCity: 'SIDNEY',
        RegAddrState: 'NE',
        RegAddrZip: 69162,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '508 12TH AVE',
        RegAddrCity: 'SIDNEY',
        RegAddrState: 'NE',
        RegAddrZip: 69162,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '87142 470TH AVE',
        RegAddrCity: 'STUART',
        RegAddrState: 'NE',
        RegAddrZip: 68780,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1104 N PARK ST',
        RegAddrCity: 'SUPERIOR',
        RegAddrState: 'NE',
        RegAddrZip: 68978,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '101 N ANTELOPE ST',
        RegAddrCity: 'TILDEN',
        RegAddrState: 'NE',
        RegAddrZip: 68781,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1380 E ROSEDALE RD',
        RegAddrCity: 'TRUMBULL',
        RegAddrState: 'NE',
        RegAddrZip: 68980,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '43233 S HIGHWAY 25',
        RegAddrCity: 'WALLACE',
        RegAddrState: 'NE',
        RegAddrZip: 69169,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '201 N GARRISON AVE',
        RegAddrCity: 'WALLACE',
        RegAddrState: 'NE',
        RegAddrZip: 69169,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '58945 869 RD',
        RegAddrCity: 'WATERBURY',
        RegAddrState: 'NE',
        RegAddrZip: 68785,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '174 N WILLOW',
        RegAddrCity: 'WAUNETA',
        RegAddrState: 'NE',
        RegAddrZip: 69045,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1210 ERIN ST',
        RegAddrCity: 'WAYNE',
        RegAddrState: 'NE',
        RegAddrZip: 68787,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '141 WHITNEY SPUR',
        RegAddrCity: 'WHITNEY',
        RegAddrState: 'NE',
        RegAddrZip: 69367,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '110 W 13TH ST',
        RegAddrCity: 'WOOD RIVER',
        RegAddrState: 'NE',
        RegAddrZip: 68883,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '202 E 6TH ST',
        RegAddrCity: 'WYNOT',
        RegAddrState: 'NE',
        RegAddrZip: 68792,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '912 SAINT ANDREWS CT',
        RegAddrCity: 'YORK',
        RegAddrState: 'NE',
        RegAddrZip: 68467,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1017 N BLACKBURN AVE',
        RegAddrCity: 'YORK',
        RegAddrState: 'NE',
        RegAddrZip: 68467,
        CongressionalDistrict: 3,
      },
    ],
  },
  {
    name: 'NH-1',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/NH-1?ref=gh-pages',
    state: 'NH',
    state_and_number: 'NH-1',
    zip: 3031,
    congressionalDistrict: 1,
    addressesInDistrict: [
      {
        RegAddrLine1: '48 SWAN LAKE TRL',
        RegAddrCity: 'ALTON',
        RegAddrState: 'NH',
        RegAddrZip: 3809,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1095 BEAUTY HILL RD',
        RegAddrCity: 'BARNSTEAD',
        RegAddrState: 'NH',
        RegAddrZip: 3218,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '124 HIGHLAND RIDGE RD',
        RegAddrCity: 'BARRINGTON',
        RegAddrState: 'NH',
        RegAddrZip: 3825,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '8 CRICKET LN',
        RegAddrCity: 'BARRINGTON',
        RegAddrState: 'NH',
        RegAddrZip: 3825,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '13 FORD DR',
        RegAddrCity: 'BARRINGTON',
        RegAddrState: 'NH',
        RegAddrZip: 3825,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '40 WEEKS LN',
        RegAddrCity: 'BARRINGTON',
        RegAddrState: 'NH',
        RegAddrZip: 3825,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '41 BIRCH LEDGE RD',
        RegAddrCity: 'BARTLETT',
        RegAddrState: 'NH',
        RegAddrZip: 3812,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '16 ROLLING RIDGE RD',
        RegAddrCity: 'BARTLETT',
        RegAddrState: 'NH',
        RegAddrZip: 3812,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '26 CHADBOURNE RD',
        RegAddrCity: 'BARTLETT',
        RegAddrState: 'NH',
        RegAddrZip: 3838,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '37 PIMLICO CT',
        RegAddrCity: 'BEDFORD',
        RegAddrState: 'NH',
        RegAddrZip: 3110,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '24 OLD BEDFORD RD',
        RegAddrCity: 'BEDFORD',
        RegAddrState: 'NH',
        RegAddrZip: 3110,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '48 EDINBURGH DR',
        RegAddrCity: 'BEDFORD',
        RegAddrState: 'NH',
        RegAddrZip: 3110,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '21 FRENCH DR',
        RegAddrCity: 'BEDFORD',
        RegAddrState: 'NH',
        RegAddrZip: 3110,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '46 ELK DR',
        RegAddrCity: 'BEDFORD',
        RegAddrState: 'NH',
        RegAddrZip: 3110,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '406 DONALD ST',
        RegAddrCity: 'BEDFORD',
        RegAddrState: 'NH',
        RegAddrZip: 3110,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '120 DEPOT ST',
        RegAddrCity: 'BELMONT',
        RegAddrState: 'NH',
        RegAddrZip: 3220,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '306 HORNE RD',
        RegAddrCity: 'BELMONT',
        RegAddrState: 'NH',
        RegAddrZip: 3220,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '171 LACONIA RD',
        RegAddrCity: 'BELMONT',
        RegAddrState: 'NH',
        RegAddrZip: 3220,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '332 SOUTH RD',
        RegAddrCity: 'BRENTWOOD',
        RegAddrState: 'NH',
        RegAddrZip: 3833,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '41 HOMESTEAD LN',
        RegAddrCity: 'BRENTWOOD',
        RegAddrState: 'NH',
        RegAddrZip: 3833,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '15 ROWELL RD E',
        RegAddrCity: 'BRENTWOOD',
        RegAddrState: 'NH',
        RegAddrZip: 3833,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '60 MIDDLE RD',
        RegAddrCity: 'BRENTWOOD',
        RegAddrState: 'NH',
        RegAddrZip: 3833,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '347 SOUTH RD',
        RegAddrCity: 'BRENTWOOD',
        RegAddrState: 'NH',
        RegAddrZip: 3833,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '14 AUTUMN LN',
        RegAddrCity: 'BRENTWOOD',
        RegAddrState: 'NH',
        RegAddrZip: 3833,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '59 WESTON WOODS CIR',
        RegAddrCity: 'CAMPTON',
        RegAddrState: 'NH',
        RegAddrZip: 3223,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1176 NH ROUTE 175',
        RegAddrCity: 'CAMPTON',
        RegAddrState: 'NH',
        RegAddrZip: 3223,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '76 SUNDEW LN',
        RegAddrCity: 'CAMPTON',
        RegAddrState: 'NH',
        RegAddrZip: 3223,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '108 BELL VALLEY RD',
        RegAddrCity: 'CAMPTON',
        RegAddrState: 'NH',
        RegAddrZip: 3223,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '5 PODUNK RD',
        RegAddrCity: 'CANDIA',
        RegAddrState: 'NH',
        RegAddrZip: 3034,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '31 DON DR',
        RegAddrCity: 'CENTER CONWAY',
        RegAddrState: 'NH',
        RegAddrZip: 3813,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1595 E CONWAY RD',
        RegAddrCity: 'CENTER CONWAY',
        RegAddrState: 'NH',
        RegAddrZip: 3813,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '419 DERRY RD',
        RegAddrCity: 'CHESTER',
        RegAddrState: 'NH',
        RegAddrZip: 3036,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '628 FREMONT RD',
        RegAddrCity: 'CHESTER',
        RegAddrState: 'NH',
        RegAddrZip: 3036,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '489 HOLMES RD',
        RegAddrCity: 'CTR BARNSTEAD',
        RegAddrState: 'NH',
        RegAddrZip: 3225,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '122 OXBOW RD',
        RegAddrCity: 'CTR BARNSTEAD',
        RegAddrState: 'NH',
        RegAddrZip: 3225,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '125 WINWOOD DR',
        RegAddrCity: 'CTR BARNSTEAD',
        RegAddrState: 'NH',
        RegAddrZip: 3225,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '434 N BARNSTEAD RD',
        RegAddrCity: 'CTR BARNSTEAD',
        RegAddrState: 'NH',
        RegAddrZip: 3225,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '75 DORE ST',
        RegAddrCity: 'CTR OSSIPEE',
        RegAddrState: 'NH',
        RegAddrZip: 3814,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '47 WILLOW AVE',
        RegAddrCity: 'CTR TUFTNBORO',
        RegAddrState: 'NH',
        RegAddrZip: 3816,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '177 BEACH PLAIN RD',
        RegAddrCity: 'DANVILLE',
        RegAddrState: 'NH',
        RegAddrZip: 3819,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '23 DEVON DR',
        RegAddrCity: 'DANVILLE',
        RegAddrState: 'NH',
        RegAddrZip: 3819,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '76 KINGSTON RD',
        RegAddrCity: 'DANVILLE',
        RegAddrState: 'NH',
        RegAddrZip: 3819,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '40 AUTUMN ST',
        RegAddrCity: 'DANVILLE',
        RegAddrState: 'NH',
        RegAddrZip: 3819,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '4 KACIE LN',
        RegAddrCity: 'DANVILLE',
        RegAddrState: 'NH',
        RegAddrZip: 3819,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '93 ROCKINGHAM RD',
        RegAddrCity: 'DERRY',
        RegAddrState: 'NH',
        RegAddrZip: 3038,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '11 HERITAGE LN',
        RegAddrCity: 'DERRY',
        RegAddrState: 'NH',
        RegAddrZip: 3038,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '41 BEDARD AVE',
        RegAddrCity: 'DERRY',
        RegAddrState: 'NH',
        RegAddrZip: 3038,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '48 WINDHAM RD',
        RegAddrCity: 'DERRY',
        RegAddrState: 'NH',
        RegAddrZip: 3038,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '38 PEARL ST',
        RegAddrCity: 'DOVER',
        RegAddrState: 'NH',
        RegAddrZip: 3820,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '19 GERRISH RD',
        RegAddrCity: 'DOVER',
        RegAddrState: 'NH',
        RegAddrZip: 3820,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '55 SPRUCE LN',
        RegAddrCity: 'DOVER',
        RegAddrState: 'NH',
        RegAddrZip: 3820,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '88 EMERALD LN',
        RegAddrCity: 'DOVER',
        RegAddrState: 'NH',
        RegAddrZip: 3820,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '57 UNION ST',
        RegAddrCity: 'DOVER',
        RegAddrState: 'NH',
        RegAddrZip: 3820,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '20 VILLAGE DR',
        RegAddrCity: 'DOVER',
        RegAddrState: 'NH',
        RegAddrZip: 3820,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '733 LILAC LN',
        RegAddrCity: 'DOVER',
        RegAddrState: 'NH',
        RegAddrZip: 3820,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '357 MIDDLE RD',
        RegAddrCity: 'DOVER',
        RegAddrState: 'NH',
        RegAddrZip: 3820,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '9 MORNINGSIDE DR',
        RegAddrCity: 'DOVER',
        RegAddrState: 'NH',
        RegAddrZip: 3820,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '925 LILAC LN',
        RegAddrCity: 'DOVER',
        RegAddrState: 'NH',
        RegAddrZip: 3820,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '565 CENTRAL AVE',
        RegAddrCity: 'DOVER',
        RegAddrState: 'NH',
        RegAddrZip: 3820,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1 1/2 BELKNAP ST',
        RegAddrCity: 'DOVER',
        RegAddrState: 'NH',
        RegAddrZip: 3820,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '9 APPALOOSA DR',
        RegAddrCity: 'DOVER',
        RegAddrState: 'NH',
        RegAddrZip: 3820,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '127 GROVE ST',
        RegAddrCity: 'DOVER',
        RegAddrState: 'NH',
        RegAddrZip: 3820,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '51 ATKINSON ST',
        RegAddrCity: 'DOVER',
        RegAddrState: 'NH',
        RegAddrZip: 3820,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '399 BACK RD',
        RegAddrCity: 'DOVER',
        RegAddrState: 'NH',
        RegAddrZip: 3820,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '11 SUNNYBROOKE DR',
        RegAddrCity: 'DOVER',
        RegAddrState: 'NH',
        RegAddrZip: 3820,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2 FITTS FARM DR',
        RegAddrCity: 'DURHAM',
        RegAddrState: 'NH',
        RegAddrZip: 3824,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '47 CANNEY RD',
        RegAddrCity: 'DURHAM',
        RegAddrState: 'NH',
        RegAddrZip: 3824,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '10 WOODMAN RD',
        RegAddrCity: 'DURHAM',
        RegAddrState: 'NH',
        RegAddrZip: 3824,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '28 WOODRIDGE RD',
        RegAddrCity: 'DURHAM',
        RegAddrState: 'NH',
        RegAddrZip: 3824,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '25 MADBURY RD',
        RegAddrCity: 'DURHAM',
        RegAddrState: 'NH',
        RegAddrZip: 3824,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '139 S DORR WAY',
        RegAddrCity: 'E WAKEFIELD',
        RegAddrState: 'NH',
        RegAddrZip: 3830,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '121 SOUTH RD',
        RegAddrCity: 'EAST KINGSTON',
        RegAddrState: 'NH',
        RegAddrZip: 3827,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '97 SANBORN RD',
        RegAddrCity: 'EAST KINGSTON',
        RegAddrState: 'NH',
        RegAddrZip: 3827,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '4 CHANDLER LN',
        RegAddrCity: 'EPPING',
        RegAddrState: 'NH',
        RegAddrZip: 3042,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '12 CHEROKEE DR',
        RegAddrCity: 'EPPING',
        RegAddrState: 'NH',
        RegAddrZip: 3042,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '362 N RIVER RD',
        RegAddrCity: 'EPPING',
        RegAddrState: 'NH',
        RegAddrZip: 3042,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '6 BLAKE RD',
        RegAddrCity: 'EPPING',
        RegAddrState: 'NH',
        RegAddrZip: 3042,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '128 EPPING RD',
        RegAddrCity: 'EXETER',
        RegAddrState: 'NH',
        RegAddrZip: 3833,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '4 MARILYN AVE',
        RegAddrCity: 'EXETER',
        RegAddrState: 'NH',
        RegAddrZip: 3833,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '4 MILLSTREAM DR',
        RegAddrCity: 'EXETER',
        RegAddrState: 'NH',
        RegAddrZip: 3833,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '273 MAIN ST',
        RegAddrCity: 'FARMINGTON',
        RegAddrState: 'NH',
        RegAddrZip: 3835,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '568 MAIN ST',
        RegAddrCity: 'FARMINGTON',
        RegAddrState: 'NH',
        RegAddrZip: 3835,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '110 SPRUCE DR',
        RegAddrCity: 'FARMINGTON',
        RegAddrState: 'NH',
        RegAddrZip: 3835,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '57 SPRUCE DR',
        RegAddrCity: 'FARMINGTON',
        RegAddrState: 'NH',
        RegAddrZip: 3835,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '346 RIDGE RD',
        RegAddrCity: 'FARMINGTON',
        RegAddrState: 'NH',
        RegAddrZip: 3835,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '22 LITTLE KNOLL CIRCUIT',
        RegAddrCity: 'FREEDOM',
        RegAddrState: 'NH',
        RegAddrZip: 3836,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '22 COOPER DR',
        RegAddrCity: 'FREMONT',
        RegAddrState: 'NH',
        RegAddrZip: 3044,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '364 SANDOWN RD',
        RegAddrCity: 'FREMONT',
        RegAddrState: 'NH',
        RegAddrZip: 3044,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '8 TARAH WAY',
        RegAddrCity: 'FREMONT',
        RegAddrState: 'NH',
        RegAddrZip: 3044,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '18 POND LN',
        RegAddrCity: 'FREMONT',
        RegAddrState: 'NH',
        RegAddrZip: 3044,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '9 LILY POND RD',
        RegAddrCity: 'GILFORD',
        RegAddrState: 'NH',
        RegAddrZip: 3249,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '103 STONE RD',
        RegAddrCity: 'GILMANTON',
        RegAddrState: 'NH',
        RegAddrZip: 3237,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '4 STOCKWELL HILL RD',
        RegAddrCity: 'GILMANTON',
        RegAddrState: 'NH',
        RegAddrZip: 3237,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '80 BIRCH LEDGE RD',
        RegAddrCity: 'GLEN',
        RegAddrState: 'NH',
        RegAddrZip: 3838,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '73 INCLINE AVE',
        RegAddrCity: 'GOFFSTOWN',
        RegAddrState: 'NH',
        RegAddrZip: 3045,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '81 CENTER ST',
        RegAddrCity: 'GOFFSTOWN',
        RegAddrState: 'NH',
        RegAddrZip: 3045,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '47 SHIRLEY PARK RD',
        RegAddrCity: 'GOFFSTOWN',
        RegAddrState: 'NH',
        RegAddrZip: 3045,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '100 ST ANSELMS DR 329',
        RegAddrCity: 'GOFFSTOWN',
        RegAddrState: 'NH',
        RegAddrZip: 3102,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '48 MOULTON AVE',
        RegAddrCity: 'GREENLAND',
        RegAddrState: 'NH',
        RegAddrZip: 3840,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '94 DEARBORN RD',
        RegAddrCity: 'GREENLAND',
        RegAddrState: 'NH',
        RegAddrZip: 3840,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1125 PORTSMOUTH AVE',
        RegAddrCity: 'GREENLAND',
        RegAddrState: 'NH',
        RegAddrZip: 3840,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '58 MCSHANE AVE',
        RegAddrCity: 'GREENLAND',
        RegAddrState: 'NH',
        RegAddrZip: 3840,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '6 MARILYN PARK DR',
        RegAddrCity: 'HAMPSTEAD',
        RegAddrState: 'NH',
        RegAddrZip: 3841,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '139 FREEDOM HILL RD',
        RegAddrCity: 'HAMPSTEAD',
        RegAddrState: 'NH',
        RegAddrZip: 3841,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '13 FALCONE CIR',
        RegAddrCity: 'HAMPTON',
        RegAddrState: 'NH',
        RegAddrZip: 3842,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '76 N SHORE RD',
        RegAddrCity: 'HAMPTON',
        RegAddrState: 'NH',
        RegAddrZip: 3842,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '135 HAMPTON MDWS',
        RegAddrCity: 'HAMPTON',
        RegAddrState: 'NH',
        RegAddrZip: 3842,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '23 HALE AVE',
        RegAddrCity: 'HOOKSETT',
        RegAddrState: 'NH',
        RegAddrZip: 3106,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '22 STIRLING AVE',
        RegAddrCity: 'HOOKSETT',
        RegAddrState: 'NH',
        RegAddrZip: 3106,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '5 LAFOND AVE',
        RegAddrCity: 'HOOKSETT',
        RegAddrState: 'NH',
        RegAddrZip: 3106,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '3 CINDY DR',
        RegAddrCity: 'HOOKSETT',
        RegAddrState: 'NH',
        RegAddrZip: 3106,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '30 GOLDEN GATE DR',
        RegAddrCity: 'HOOKSETT',
        RegAddrState: 'NH',
        RegAddrZip: 3106,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '148 SKYLINE DR',
        RegAddrCity: 'INTERVALE',
        RegAddrState: 'NH',
        RegAddrZip: 3845,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '67 MOODY FARM RD',
        RegAddrCity: 'JACKSON',
        RegAddrState: 'NH',
        RegAddrZip: 3846,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '128 SOUTH RD',
        RegAddrCity: 'KENSINGTON',
        RegAddrState: 'NH',
        RegAddrZip: 3833,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '77 AMESBURY RD',
        RegAddrCity: 'KENSINGTON',
        RegAddrState: 'NH',
        RegAddrZip: 3833,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '123 SOUTH RD',
        RegAddrCity: 'KENSINGTON',
        RegAddrState: 'NH',
        RegAddrZip: 3833,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '9 MAPLE ST',
        RegAddrCity: 'KINGSTON',
        RegAddrState: 'NH',
        RegAddrZip: 3848,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '9 PILLSBURY PASTURE RD',
        RegAddrCity: 'KINGSTON',
        RegAddrState: 'NH',
        RegAddrZip: 3848,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '27 MEADOWWOOD RD',
        RegAddrCity: 'KINGSTON',
        RegAddrState: 'NH',
        RegAddrZip: 3848,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '180 HILLCROFT RD',
        RegAddrCity: 'LACONIA',
        RegAddrState: 'NH',
        RegAddrZip: 3246,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '93 HILLCREST DR',
        RegAddrCity: 'LACONIA',
        RegAddrState: 'NH',
        RegAddrZip: 3246,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '22 HOLMAN ST',
        RegAddrCity: 'LACONIA',
        RegAddrState: 'NH',
        RegAddrZip: 3246,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '17 RIVERSIDE FARM DR',
        RegAddrCity: 'LEE',
        RegAddrState: 'NH',
        RegAddrZip: 3861,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '195 WADLEIGH FALLS RD',
        RegAddrCity: 'LEE',
        RegAddrState: 'NH',
        RegAddrZip: 3861,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '67 DEMERITT AVE',
        RegAddrCity: 'LEE',
        RegAddrState: 'NH',
        RegAddrZip: 3861,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '21 ALLENS AVE',
        RegAddrCity: 'LEE',
        RegAddrState: 'NH',
        RegAddrZip: 3861,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '128 PILLSBURY RD',
        RegAddrCity: 'LONDONDERRY',
        RegAddrState: 'NH',
        RegAddrZip: 3053,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '16 CORTLAND ST',
        RegAddrCity: 'LONDONDERRY',
        RegAddrState: 'NH',
        RegAddrZip: 3053,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '44 LAWSON FARM RD',
        RegAddrCity: 'LONDONDERRY',
        RegAddrState: 'NH',
        RegAddrZip: 3053,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '3 OLDE COUNTRY VILLAGE RD',
        RegAddrCity: 'LONDONDERRY',
        RegAddrState: 'NH',
        RegAddrZip: 3053,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '26 WILSON RD',
        RegAddrCity: 'LONDONDERRY',
        RegAddrState: 'NH',
        RegAddrZip: 3053,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '53 CONSTITUTION DR',
        RegAddrCity: 'LONDONDERRY',
        RegAddrState: 'NH',
        RegAddrZip: 3053,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '227 E MADISON RD',
        RegAddrCity: 'MADISON',
        RegAddrState: 'NH',
        RegAddrZip: 3849,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '60 HUTTWILL DR',
        RegAddrCity: 'MADISON',
        RegAddrState: 'NH',
        RegAddrZip: 3849,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '443 GRANITE ST',
        RegAddrCity: 'MANCHESTER',
        RegAddrState: 'NH',
        RegAddrZip: 3102,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '110 CUMBERLAND ST',
        RegAddrCity: 'MANCHESTER',
        RegAddrState: 'NH',
        RegAddrZip: 3102,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '28 PASTURE DR',
        RegAddrCity: 'MANCHESTER',
        RegAddrState: 'NH',
        RegAddrZip: 3102,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '284 PUTNAM ST',
        RegAddrCity: 'MANCHESTER',
        RegAddrState: 'NH',
        RegAddrZip: 3102,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '357 SAINT JAMES AVE',
        RegAddrCity: 'MANCHESTER',
        RegAddrState: 'NH',
        RegAddrZip: 3102,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '249 HIDDEN OAK WAY',
        RegAddrCity: 'MANCHESTER',
        RegAddrState: 'NH',
        RegAddrZip: 3102,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '546 DIX ST',
        RegAddrCity: 'MANCHESTER',
        RegAddrState: 'NH',
        RegAddrZip: 3103,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '85 W ELMWOOD AVE',
        RegAddrCity: 'MANCHESTER',
        RegAddrState: 'NH',
        RegAddrZip: 3103,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '305 CEDAR ST',
        RegAddrCity: 'MANCHESTER',
        RegAddrState: 'NH',
        RegAddrZip: 3103,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '210 YOUNG ST',
        RegAddrCity: 'MANCHESTER',
        RegAddrState: 'NH',
        RegAddrZip: 3103,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '25 JOSHUA DR',
        RegAddrCity: 'MANCHESTER',
        RegAddrState: 'NH',
        RegAddrZip: 3103,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '151 CYPRESS ST',
        RegAddrCity: 'MANCHESTER',
        RegAddrState: 'NH',
        RegAddrZip: 3103,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '88 SUNDIAL AVE',
        RegAddrCity: 'MANCHESTER',
        RegAddrState: 'NH',
        RegAddrZip: 3103,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '581 LAKE AVE',
        RegAddrCity: 'MANCHESTER',
        RegAddrState: 'NH',
        RegAddrZip: 3103,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '225 KNOWLTON ST',
        RegAddrCity: 'MANCHESTER',
        RegAddrState: 'NH',
        RegAddrZip: 3103,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '7 POND DR',
        RegAddrCity: 'MANCHESTER',
        RegAddrState: 'NH',
        RegAddrZip: 3103,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '207 W ELMWOOD AVE',
        RegAddrCity: 'MANCHESTER',
        RegAddrState: 'NH',
        RegAddrZip: 3103,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1326 WELLINGTON RD',
        RegAddrCity: 'MANCHESTER',
        RegAddrState: 'NH',
        RegAddrZip: 3104,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '333 WHITTINGTON ST',
        RegAddrCity: 'MANCHESTER',
        RegAddrState: 'NH',
        RegAddrZip: 3104,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '96 BAYBERRY LN',
        RegAddrCity: 'MANCHESTER',
        RegAddrState: 'NH',
        RegAddrZip: 3104,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '343 FOX HOLLOW WAY',
        RegAddrCity: 'MANCHESTER',
        RegAddrState: 'NH',
        RegAddrZip: 3104,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '41 WHIG DR',
        RegAddrCity: 'MANCHESTER',
        RegAddrState: 'NH',
        RegAddrZip: 3104,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '246 BRUCE RD',
        RegAddrCity: 'MANCHESTER',
        RegAddrState: 'NH',
        RegAddrZip: 3104,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2500 N RIVER RD',
        RegAddrCity: 'MANCHESTER',
        RegAddrState: 'NH',
        RegAddrZip: 3106,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '261 LOVERING ST',
        RegAddrCity: 'MANCHESTER',
        RegAddrState: 'NH',
        RegAddrZip: 3109,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '50 ALLIED ST',
        RegAddrCity: 'MANCHESTER',
        RegAddrState: 'NH',
        RegAddrZip: 3109,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '15 TAVERNIER WAY',
        RegAddrCity: 'MANCHESTER',
        RegAddrState: 'NH',
        RegAddrZip: 3109,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '76 HUNTINGTON AVE',
        RegAddrCity: 'MANCHESTER',
        RegAddrState: 'NH',
        RegAddrZip: 3109,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '375 BODWELL RD',
        RegAddrCity: 'MANCHESTER',
        RegAddrState: 'NH',
        RegAddrZip: 3109,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '24 CHASE RD',
        RegAddrCity: 'MEREDITH',
        RegAddrState: 'NH',
        RegAddrZip: 3253,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '51 OAK KNOLL RD',
        RegAddrCity: 'MEREDITH',
        RegAddrState: 'NH',
        RegAddrZip: 3253,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '51 PATRICIAN SHORES CIR',
        RegAddrCity: 'MEREDITH',
        RegAddrState: 'NH',
        RegAddrZip: 3253,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '29 NEAL SHORE RD',
        RegAddrCity: 'MEREDITH',
        RegAddrState: 'NH',
        RegAddrZip: 3253,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '7 GOULD AVE',
        RegAddrCity: 'MEREDITH',
        RegAddrState: 'NH',
        RegAddrZip: 3253,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '80 PATTEN RD',
        RegAddrCity: 'MERRIMACK',
        RegAddrState: 'NH',
        RegAddrZip: 3054,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '3 SEAVERNS BRIDGE RD',
        RegAddrCity: 'MERRIMACK',
        RegAddrState: 'NH',
        RegAddrZip: 3054,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '140 BABOOSIC LAKE RD',
        RegAddrCity: 'MERRIMACK',
        RegAddrState: 'NH',
        RegAddrZip: 3054,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '5 GREATSTONE DR',
        RegAddrCity: 'MERRIMACK',
        RegAddrState: 'NH',
        RegAddrZip: 3054,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2 SHORE DR',
        RegAddrCity: 'MIDDLETON',
        RegAddrState: 'NH',
        RegAddrZip: 3887,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '423 SILVER ST',
        RegAddrCity: 'MILTON',
        RegAddrState: 'NH',
        RegAddrZip: 3851,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '292 HARE RD',
        RegAddrCity: 'MILTON',
        RegAddrState: 'NH',
        RegAddrZip: 3851,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '17 MAIN ST',
        RegAddrCity: 'MILTON MILLS',
        RegAddrState: 'NH',
        RegAddrZip: 3852,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '15 BRICKFIELD WAY',
        RegAddrCity: 'MIRROR LAKE',
        RegAddrState: 'NH',
        RegAddrZip: 3853,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '159 MOULTONBORO NECK RD',
        RegAddrCity: 'MOULTONBORO',
        RegAddrState: 'NH',
        RegAddrZip: 3254,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '98 HANSON MILL RD',
        RegAddrCity: 'MOULTONBORO',
        RegAddrState: 'NH',
        RegAddrZip: 3254,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '382 BIRCH HILL RD',
        RegAddrCity: 'NEW DURHAM',
        RegAddrState: 'NH',
        RegAddrZip: 3855,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '23 FAIRWAY DR',
        RegAddrCity: 'NEW HAMPTON',
        RegAddrState: 'NH',
        RegAddrZip: 3256,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '75 EVERGREEN DR',
        RegAddrCity: 'NEW HAMPTON',
        RegAddrState: 'NH',
        RegAddrZip: 3256,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '51 ANCHORAGE RD',
        RegAddrCity: 'NEW HAMPTON',
        RegAddrState: 'NH',
        RegAddrZip: 3256,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '100 MAGOON RD',
        RegAddrCity: 'NEW HAMPTON',
        RegAddrState: 'NH',
        RegAddrZip: 3256,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '77 MAIN ST',
        RegAddrCity: 'NEW HAMPTON',
        RegAddrState: 'NH',
        RegAddrZip: 3256,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '256A LITTLE BAY RD',
        RegAddrCity: 'NEWINGTON',
        RegAddrState: 'NH',
        RegAddrZip: 3801,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2 CHAPEL ST',
        RegAddrCity: 'NEWMARKET',
        RegAddrState: 'NH',
        RegAddrZip: 3857,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2 PISCASSIC ST',
        RegAddrCity: 'NEWMARKET',
        RegAddrState: 'NH',
        RegAddrZip: 3857,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '133 EXETER RD',
        RegAddrCity: 'NEWMARKET',
        RegAddrState: 'NH',
        RegAddrZip: 3857,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1 UOCAVA',
        RegAddrCity: 'NEWMARKET',
        RegAddrState: 'NH',
        RegAddrZip: 3857,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '26 MAPLE AVE',
        RegAddrCity: 'NEWTON',
        RegAddrState: 'NH',
        RegAddrZip: 3858,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '8 HIGHLAND ST',
        RegAddrCity: 'NEWTON',
        RegAddrState: 'NH',
        RegAddrZip: 3858,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '25 W MAIN ST',
        RegAddrCity: 'NEWTON',
        RegAddrState: 'NH',
        RegAddrZip: 3858,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '35 NEW BOSTON RD',
        RegAddrCity: 'NEWTON',
        RegAddrState: 'NH',
        RegAddrZip: 3858,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '9 GALE VILLAGE RD',
        RegAddrCity: 'NEWTON',
        RegAddrState: 'NH',
        RegAddrZip: 3858,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '9999 COBB FARM RD',
        RegAddrCity: 'NORTH CONWAY',
        RegAddrState: 'NH',
        RegAddrZip: 3860,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '112 ATLANTIC AVE',
        RegAddrCity: 'NORTH HAMPTON',
        RegAddrState: 'NH',
        RegAddrZip: 3862,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '233 POST RD',
        RegAddrCity: 'NORTH HAMPTON',
        RegAddrState: 'NH',
        RegAddrZip: 3862,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '11 COTTON FARM LN',
        RegAddrCity: 'NORTH HAMPTON',
        RegAddrState: 'NH',
        RegAddrZip: 3862,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '9 FERN RD',
        RegAddrCity: 'NORTH HAMPTON',
        RegAddrState: 'NH',
        RegAddrZip: 3862,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '75 LEDGE FARM RD',
        RegAddrCity: 'NOTTINGHAM',
        RegAddrState: 'NH',
        RegAddrZip: 3290,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '9 PARTRIDGE LN',
        RegAddrCity: 'PLAISTOW',
        RegAddrState: 'NH',
        RegAddrZip: 3865,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '32 KELLEY RD',
        RegAddrCity: 'PLAISTOW',
        RegAddrState: 'NH',
        RegAddrZip: 3865,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '229 LESLIE DR',
        RegAddrCity: 'PORTSMOUTH',
        RegAddrState: 'NH',
        RegAddrZip: 3801,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '235 AUSTIN ST',
        RegAddrCity: 'PORTSMOUTH',
        RegAddrState: 'NH',
        RegAddrZip: 3801,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '161 ESSEX AVE',
        RegAddrCity: 'PORTSMOUTH',
        RegAddrState: 'NH',
        RegAddrZip: 3801,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '5 ARTHUR RD',
        RegAddrCity: 'PORTSMOUTH',
        RegAddrState: 'NH',
        RegAddrZip: 3801,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '245 THORNTON ST',
        RegAddrCity: 'PORTSMOUTH',
        RegAddrState: 'NH',
        RegAddrZip: 3801,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '55 LAFAYETTE RD',
        RegAddrCity: 'PORTSMOUTH',
        RegAddrState: 'NH',
        RegAddrZip: 3801,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '61 HOLLY LN',
        RegAddrCity: 'PORTSMOUTH',
        RegAddrState: 'NH',
        RegAddrZip: 3801,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '5 HARDING RD',
        RegAddrCity: 'PORTSMOUTH',
        RegAddrState: 'NH',
        RegAddrZip: 3801,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '17 DUNLIN WAY',
        RegAddrCity: 'PORTSMOUTH',
        RegAddrState: 'NH',
        RegAddrZip: 3801,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '61 COLONIAL DR',
        RegAddrCity: 'PORTSMOUTH',
        RegAddrState: 'NH',
        RegAddrZip: 3801,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '220 F W HARTFORD DR',
        RegAddrCity: 'PORTSMOUTH',
        RegAddrState: 'NH',
        RegAddrZip: 3801,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '24 HOLMES CT',
        RegAddrCity: 'PORTSMOUTH',
        RegAddrState: 'NH',
        RegAddrZip: 3801,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '207 MYRTLE AVE',
        RegAddrCity: 'PORTSMOUTH',
        RegAddrState: 'NH',
        RegAddrZip: 3801,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '24 TAFT RD',
        RegAddrCity: 'PORTSMOUTH',
        RegAddrState: 'NH',
        RegAddrZip: 3801,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '241 CONCORD WAY',
        RegAddrCity: 'PORTSMOUTH',
        RegAddrState: 'NH',
        RegAddrZip: 3801,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '508 UNION ST',
        RegAddrCity: 'PORTSMOUTH',
        RegAddrState: 'NH',
        RegAddrZip: 3801,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1618 ISLINGTON ST',
        RegAddrCity: 'PORTSMOUTH',
        RegAddrState: 'NH',
        RegAddrZip: 3801,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '36 TAYLOR LN',
        RegAddrCity: 'PORTSMOUTH',
        RegAddrState: 'NH',
        RegAddrZip: 3801,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '134 BLUE HERON DR',
        RegAddrCity: 'PORTSMOUTH',
        RegAddrState: 'NH',
        RegAddrZip: 3801,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '348 UNION ST',
        RegAddrCity: 'PORTSMOUTH',
        RegAddrState: 'NH',
        RegAddrZip: 3801,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '998 SOUTH ST',
        RegAddrCity: 'PORTSMOUTH',
        RegAddrState: 'NH',
        RegAddrZip: 3801,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '123 MAIN ST',
        RegAddrCity: 'RAYMOND',
        RegAddrState: 'NH',
        RegAddrZip: 3077,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '38 PATRIOTS WAY',
        RegAddrCity: 'RAYMOND',
        RegAddrState: 'NH',
        RegAddrZip: 3077,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '113 FREMONT RD',
        RegAddrCity: 'RAYMOND',
        RegAddrState: 'NH',
        RegAddrZip: 3077,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '9 GLEN RIDGE RD',
        RegAddrCity: 'RAYMOND',
        RegAddrState: 'NH',
        RegAddrZip: 3077,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '20 DEBRA AVE',
        RegAddrCity: 'RAYMOND',
        RegAddrState: 'NH',
        RegAddrZip: 3077,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '4 DURHAM LN',
        RegAddrCity: 'ROCHESTER',
        RegAddrState: 'NH',
        RegAddrZip: 3839,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '36B TRINITY CIR',
        RegAddrCity: 'ROCHESTER',
        RegAddrState: 'NH',
        RegAddrZip: 3839,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '161 CHESLEY HILL RD',
        RegAddrCity: 'ROCHESTER',
        RegAddrState: 'NH',
        RegAddrZip: 3839,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '24 BRENDA LN',
        RegAddrCity: 'ROCHESTER',
        RegAddrState: 'NH',
        RegAddrZip: 3867,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '26 QUARRY DR',
        RegAddrCity: 'ROCHESTER',
        RegAddrState: 'NH',
        RegAddrZip: 3867,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '352 BLACKWATER RD',
        RegAddrCity: 'ROCHESTER',
        RegAddrState: 'NH',
        RegAddrZip: 3867,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '10 FOUR ROD RD',
        RegAddrCity: 'ROCHESTER',
        RegAddrState: 'NH',
        RegAddrZip: 3867,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '16A LEONARD ST',
        RegAddrCity: 'ROCHESTER',
        RegAddrState: 'NH',
        RegAddrZip: 3867,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '3 MEADERBORO RD',
        RegAddrCity: 'ROCHESTER',
        RegAddrState: 'NH',
        RegAddrZip: 3867,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '3 HAMPSHIRE AVE',
        RegAddrCity: 'ROCHESTER',
        RegAddrState: 'NH',
        RegAddrZip: 3867,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '45 CHESTNUT ST',
        RegAddrCity: 'ROCHESTER',
        RegAddrState: 'NH',
        RegAddrZip: 3867,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '8 RILEY AVE',
        RegAddrCity: 'ROCHESTER',
        RegAddrState: 'NH',
        RegAddrZip: 3867,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '6 MEADOW LN',
        RegAddrCity: 'ROCHESTER',
        RegAddrState: 'NH',
        RegAddrZip: 3867,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '11 SILVER ST',
        RegAddrCity: 'ROCHESTER',
        RegAddrState: 'NH',
        RegAddrZip: 3867,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2 PAWTUCKETTS WAY',
        RegAddrCity: 'ROCHESTER',
        RegAddrState: 'NH',
        RegAddrZip: 3867,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '90 WALNUT ST',
        RegAddrCity: 'ROCHESTER',
        RegAddrState: 'NH',
        RegAddrZip: 3867,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '326 CHESTNUT HILL RD',
        RegAddrCity: 'ROCHESTER',
        RegAddrState: 'NH',
        RegAddrZip: 3867,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2 VERNON AVE',
        RegAddrCity: 'ROCHESTER',
        RegAddrState: 'NH',
        RegAddrZip: 3867,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '10 VINEWOOD LN',
        RegAddrCity: 'ROCHESTER',
        RegAddrState: 'NH',
        RegAddrZip: 3867,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '211 OLD DOVER RD',
        RegAddrCity: 'ROCHESTER',
        RegAddrState: 'NH',
        RegAddrZip: 3867,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '33 JAY WAY',
        RegAddrCity: 'ROCHESTER',
        RegAddrState: 'NH',
        RegAddrZip: 3868,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '8A HICKEY ST',
        RegAddrCity: 'ROCHESTER',
        RegAddrState: 'NH',
        RegAddrZip: 3868,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '25 WOODLAND GRN',
        RegAddrCity: 'ROCHESTER',
        RegAddrState: 'NH',
        RegAddrZip: 3868,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '45 MELROSE DR',
        RegAddrCity: 'ROCHESTER',
        RegAddrState: 'NH',
        RegAddrZip: 3868,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '460 BECCARIS DR',
        RegAddrCity: 'ROLLINSFORD',
        RegAddrState: 'NH',
        RegAddrZip: 3869,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '659 LONG JOHN RD',
        RegAddrCity: 'RYE',
        RegAddrState: 'NH',
        RegAddrZip: 3870,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '172 WASHINGTON RD',
        RegAddrCity: 'RYE',
        RegAddrState: 'NH',
        RegAddrZip: 3870,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '795 WASHINGTON RD',
        RegAddrCity: 'RYE',
        RegAddrState: 'NH',
        RegAddrZip: 3870,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '25 PERKINS RD',
        RegAddrCity: 'RYE',
        RegAddrState: 'NH',
        RegAddrZip: 3870,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '3 ELIZABETH LN',
        RegAddrCity: 'RYE',
        RegAddrState: 'NH',
        RegAddrZip: 3870,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '33 OCEANVIEW AVE',
        RegAddrCity: 'RYE',
        RegAddrState: 'NH',
        RegAddrZip: 3870,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '528 STAGE RD',
        RegAddrCity: 'SANBORNTON',
        RegAddrState: 'NH',
        RegAddrZip: 3269,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '22 SETH DR',
        RegAddrCity: 'SANBORNTON',
        RegAddrState: 'NH',
        RegAddrZip: 3269,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '439 POND RD',
        RegAddrCity: 'SANBORNVILLE',
        RegAddrState: 'NH',
        RegAddrZip: 3872,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '68 LEISURE LN',
        RegAddrCity: 'SANBORNVILLE',
        RegAddrState: 'NH',
        RegAddrZip: 3872,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '13 TOTE RD',
        RegAddrCity: 'SANDOWN',
        RegAddrState: 'NH',
        RegAddrZip: 3873,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '35 GLASTOMBURY DR',
        RegAddrCity: 'SANDOWN',
        RegAddrState: 'NH',
        RegAddrZip: 3873,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '10 MICHAEL CIR',
        RegAddrCity: 'SANDOWN',
        RegAddrState: 'NH',
        RegAddrZip: 3873,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '51 TRICIA ST',
        RegAddrCity: 'SEABROOK',
        RegAddrState: 'NH',
        RegAddrZip: 3874,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '199 TILTON ST',
        RegAddrCity: 'SEABROOK',
        RegAddrState: 'NH',
        RegAddrZip: 3874,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '15 BLUEBERRY LN',
        RegAddrCity: 'SEABROOK',
        RegAddrState: 'NH',
        RegAddrZip: 3874,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '67 S MAIN ST',
        RegAddrCity: 'SEABROOK',
        RegAddrState: 'NH',
        RegAddrZip: 3874,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1 LEDGE RD',
        RegAddrCity: 'SEABROOK',
        RegAddrState: 'NH',
        RegAddrZip: 3874,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '41B WORTHLEY AVE',
        RegAddrCity: 'SEABROOK',
        RegAddrState: 'NH',
        RegAddrZip: 3874,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1912 PLAINS RD',
        RegAddrCity: 'SILVER LAKE',
        RegAddrState: 'NH',
        RegAddrZip: 3875,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '71 BUFFUMSVILLE RD',
        RegAddrCity: 'SOMERSWORTH',
        RegAddrState: 'NH',
        RegAddrZip: 3878,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '7 NASH PKWY',
        RegAddrCity: 'SOMERSWORTH',
        RegAddrState: 'NH',
        RegAddrZip: 3878,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '11 ROCKY HILL RD',
        RegAddrCity: 'SOMERSWORTH',
        RegAddrState: 'NH',
        RegAddrZip: 3878,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '253 HIGH ST',
        RegAddrCity: 'SOMERSWORTH',
        RegAddrState: 'NH',
        RegAddrZip: 3878,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '80 HIGHLAND RD',
        RegAddrCity: 'SOUTH HAMPTON',
        RegAddrState: 'NH',
        RegAddrZip: 3827,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '58 POINTE TRINITY DR',
        RegAddrCity: 'STRAFFORD',
        RegAddrState: 'NH',
        RegAddrZip: 3884,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '582 1ST CROWN POINT RD',
        RegAddrCity: 'STRAFFORD',
        RegAddrState: 'NH',
        RegAddrZip: 3884,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '22 LAURISTON LN',
        RegAddrCity: 'STRAFFORD',
        RegAddrState: 'NH',
        RegAddrZip: 3884,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '61 STRATHAM HEIGHTS RD',
        RegAddrCity: 'STRATHAM',
        RegAddrState: 'NH',
        RegAddrZip: 3885,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '3 APPLE WAY',
        RegAddrCity: 'STRATHAM',
        RegAddrState: 'NH',
        RegAddrZip: 3885,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '20 PHEASANT RUN LN',
        RegAddrCity: 'STRATHAM',
        RegAddrState: 'NH',
        RegAddrZip: 3885,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '18 POINT OF ROCKS TER',
        RegAddrCity: 'STRATHAM',
        RegAddrState: 'NH',
        RegAddrZip: 3885,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '178B WINNICUTT RD',
        RegAddrCity: 'STRATHAM',
        RegAddrState: 'NH',
        RegAddrZip: 3885,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '529 TAMWORTH RD',
        RegAddrCity: 'TAMWORTH',
        RegAddrState: 'NH',
        RegAddrZip: 3886,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '420 TAMWORTH RD',
        RegAddrCity: 'TAMWORTH',
        RegAddrState: 'NH',
        RegAddrZip: 3886,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '28 PROSPECT ST',
        RegAddrCity: 'TILTON',
        RegAddrState: 'NH',
        RegAddrZip: 3276,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '54 CHESTNUT ST',
        RegAddrCity: 'TILTON',
        RegAddrState: 'NH',
        RegAddrZip: 3276,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '7 HOLIDAY DR',
        RegAddrCity: 'UNION',
        RegAddrState: 'NH',
        RegAddrZip: 3887,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '305 DAVIS RD',
        RegAddrCity: 'WAKEFIELD',
        RegAddrState: 'NH',
        RegAddrZip: 3872,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '335 SEWALL RD',
        RegAddrCity: 'WOLFEBORO',
        RegAddrState: 'NH',
        RegAddrZip: 3894,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '713 PLEASANT VALLEY RD',
        RegAddrCity: 'WOLFEBORO',
        RegAddrState: 'NH',
        RegAddrZip: 3894,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '38 BIRCH HILL ESTATES RD',
        RegAddrCity: 'WOLFEBORO',
        RegAddrState: 'NH',
        RegAddrZip: 3894,
        CongressionalDistrict: 1,
      },
    ],
  },
  {
    name: 'NH-2',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/NH-2?ref=gh-pages',
    state: 'NH',
    state_and_number: 'NH-2',
    zip: 3033,
    congressionalDistrict: 2,
    addressesInDistrict: [
      {
        RegAddrLine1: '155 LYNN HILL RD',
        RegAddrCity: 'ACWORTH',
        RegAddrState: 'NH',
        RegAddrZip: 3601,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '75 PATTEE HILL RD',
        RegAddrCity: 'ALEXANDRIA',
        RegAddrState: 'NH',
        RegAddrZip: 3222,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1148 WASHBURN RD',
        RegAddrCity: 'ALEXANDRIA',
        RegAddrState: 'NH',
        RegAddrZip: 3222,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '16 TOWNHOUSE RD',
        RegAddrCity: 'ALLENSTOWN',
        RegAddrState: 'NH',
        RegAddrZip: 3275,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '14 DOWST RD',
        RegAddrCity: 'ALLENSTOWN',
        RegAddrState: 'NH',
        RegAddrZip: 3275,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '23 GILBERT RD',
        RegAddrCity: 'ALLENSTOWN',
        RegAddrState: 'NH',
        RegAddrZip: 3275,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '7 ELM ST',
        RegAddrCity: 'ALLENSTOWN',
        RegAddrState: 'NH',
        RegAddrZip: 3275,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '26 RIVER ST',
        RegAddrCity: 'ALSTEAD',
        RegAddrState: 'NH',
        RegAddrZip: 3602,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '12 HICKORY DR',
        RegAddrCity: 'AMHERST',
        RegAddrState: 'NH',
        RegAddrZip: 3031,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '24 JOSIAH BARTLETT RD',
        RegAddrCity: 'AMHERST',
        RegAddrState: 'NH',
        RegAddrZip: 3031,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '8 LAKE OUTLET RD',
        RegAddrCity: 'AMHERST',
        RegAddrState: 'NH',
        RegAddrZip: 3031,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '13 MULBERRY LN',
        RegAddrCity: 'AMHERST',
        RegAddrState: 'NH',
        RegAddrZip: 3031,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '12 MONT VERNON RD',
        RegAddrCity: 'AMHERST',
        RegAddrState: 'NH',
        RegAddrZip: 3031,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '95B HOYT RD',
        RegAddrCity: 'ANDOVER',
        RegAddrState: 'NH',
        RegAddrZip: 3216,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '242 ROUTE 4A',
        RegAddrCity: 'ANDOVER',
        RegAddrState: 'NH',
        RegAddrZip: 3216,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '22 CHASE HILL RD',
        RegAddrCity: 'ANDOVER',
        RegAddrState: 'NH',
        RegAddrZip: 3231,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '32 HIGH ST',
        RegAddrCity: 'ANTRIM',
        RegAddrState: 'NH',
        RegAddrZip: 3440,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '82 MAIN ST',
        RegAddrCity: 'ANTRIM',
        RegAddrState: 'NH',
        RegAddrZip: 3440,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '62 WINTER ST',
        RegAddrCity: 'ASHLAND',
        RegAddrState: 'NH',
        RegAddrZip: 3217,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '23 ASHUELOT MAIN ST',
        RegAddrCity: 'ASHUELOT',
        RegAddrState: 'NH',
        RegAddrZip: 3441,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '85 MAPLE AVE',
        RegAddrCity: 'ATKINSON',
        RegAddrState: 'NH',
        RegAddrZip: 3811,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '4 DEARBORN RIDGE RD',
        RegAddrCity: 'ATKINSON',
        RegAddrState: 'NH',
        RegAddrZip: 3811,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '14 SWITZER RD',
        RegAddrCity: 'BENNINGTON',
        RegAddrState: 'NH',
        RegAddrZip: 3442,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '816 3RD AVE',
        RegAddrCity: 'BERLIN',
        RegAddrState: 'NH',
        RegAddrZip: 3570,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '328 COLUMBIA AVE',
        RegAddrCity: 'BERLIN',
        RegAddrState: 'NH',
        RegAddrZip: 3570,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '178 BRIDGE ST',
        RegAddrCity: 'BERLIN',
        RegAddrState: 'NH',
        RegAddrZip: 3570,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '22 ARLINGTON ST',
        RegAddrCity: 'BERLIN',
        RegAddrState: 'NH',
        RegAddrZip: 3570,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1083 PROSPECT ST',
        RegAddrCity: 'BETHLEHEM',
        RegAddrState: 'NH',
        RegAddrZip: 3574,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '247 QUEEN ST',
        RegAddrCity: 'BOSCAWEN',
        RegAddrState: 'NH',
        RegAddrZip: 3303,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '32 GOODHUE RD',
        RegAddrCity: 'BOSCAWEN',
        RegAddrState: 'NH',
        RegAddrZip: 3303,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '325 DANIEL WEBSTER HWY',
        RegAddrCity: 'BOSCAWEN',
        RegAddrState: 'NH',
        RegAddrZip: 3303,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '250 QUEEN ST',
        RegAddrCity: 'BOSCAWEN',
        RegAddrState: 'NH',
        RegAddrZip: 3303,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '13 BRIARWOOD DR',
        RegAddrCity: 'BOW',
        RegAddrState: 'NH',
        RegAddrZip: 3304,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '12 ALBIN RD',
        RegAddrCity: 'BOW',
        RegAddrState: 'NH',
        RegAddrZip: 3304,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '4 BOW CENTER RD',
        RegAddrCity: 'BOW',
        RegAddrState: 'NH',
        RegAddrZip: 3304,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '31 TIMBER LN',
        RegAddrCity: 'BRISTOL',
        RegAddrState: 'NH',
        RegAddrZip: 3222,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '3970 RIVER RD',
        RegAddrCity: 'BRISTOL',
        RegAddrState: 'NH',
        RegAddrZip: 3222,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '16 AMES RD',
        RegAddrCity: 'BROOKLINE',
        RegAddrState: 'NH',
        RegAddrZip: 3033,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '43 TAYLOR DR',
        RegAddrCity: 'BROOKLINE',
        RegAddrState: 'NH',
        RegAddrZip: 3033,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '13 PARKER RD',
        RegAddrCity: 'BROOKLINE',
        RegAddrState: 'NH',
        RegAddrZip: 3033,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '9 WOODLAND DR',
        RegAddrCity: 'BROOKLINE',
        RegAddrState: 'NH',
        RegAddrZip: 3033,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '39 LAUREL CREST DR',
        RegAddrCity: 'BROOKLINE',
        RegAddrState: 'NH',
        RegAddrZip: 3033,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '33 BRUCE RD',
        RegAddrCity: 'CANAAN',
        RegAddrState: 'NH',
        RegAddrZip: 3741,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '670 NH ROUTE 118',
        RegAddrCity: 'CANAAN',
        RegAddrState: 'NH',
        RegAddrZip: 3741,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '562 RIVER RD',
        RegAddrCity: 'CANAAN',
        RegAddrState: 'NH',
        RegAddrZip: 3741,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '14 NEW RD',
        RegAddrCity: 'CANTERBURY',
        RegAddrState: 'NH',
        RegAddrZip: 3224,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '89 CANTERBURY SHORE DR',
        RegAddrCity: 'CANTERBURY',
        RegAddrState: 'NH',
        RegAddrZip: 3224,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '97 NEW STRAW RD',
        RegAddrCity: 'CARROLL',
        RegAddrState: 'NH',
        RegAddrZip: 3598,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '75 APPLEBY CLOSE',
        RegAddrCity: 'CARROLL',
        RegAddrState: 'NH',
        RegAddrZip: 3598,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '128 ROUTE 302 W',
        RegAddrCity: 'CARROLL',
        RegAddrState: 'NH',
        RegAddrZip: 3598,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '23 PINE ST',
        RegAddrCity: 'CHARLESTOWN',
        RegAddrState: 'NH',
        RegAddrZip: 3603,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '18 MORWAYS PARK',
        RegAddrCity: 'CHARLESTOWN',
        RegAddrState: 'NH',
        RegAddrZip: 3603,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '11 ERIN CT',
        RegAddrCity: 'CHARLESTOWN',
        RegAddrState: 'NH',
        RegAddrZip: 3603,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '166 LOVERS LANE RD',
        RegAddrCity: 'CHARLESTOWN',
        RegAddrState: 'NH',
        RegAddrZip: 3603,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '8 ERBHOF RD 11',
        RegAddrCity: 'CHESTERFIELD',
        RegAddrState: 'NH',
        RegAddrZip: 3462,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '33 MAIN ST',
        RegAddrCity: 'CHICHESTER',
        RegAddrState: 'NH',
        RegAddrZip: 3258,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '349 DOVER RD',
        RegAddrCity: 'CHICHESTER',
        RegAddrState: 'NH',
        RegAddrZip: 3258,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '11 MCKENZIE DR',
        RegAddrCity: 'CLAREMONT',
        RegAddrState: 'NH',
        RegAddrZip: 3743,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '40 WINDY HILL RD',
        RegAddrCity: 'CLAREMONT',
        RegAddrState: 'NH',
        RegAddrZip: 3743,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '74 WOODLAND ST',
        RegAddrCity: 'CLAREMONT',
        RegAddrState: 'NH',
        RegAddrZip: 3743,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '94 ROBERTS HILL RD',
        RegAddrCity: 'CLAREMONT',
        RegAddrState: 'NH',
        RegAddrZip: 3743,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '97 SUGAR RIVER DR',
        RegAddrCity: 'CLAREMONT',
        RegAddrState: 'NH',
        RegAddrZip: 3743,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '201 ROBERTS HILL RD',
        RegAddrCity: 'CLAREMONT',
        RegAddrState: 'NH',
        RegAddrZip: 3743,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '40 RUSSELL JARVIS HOMESTEAD RD',
        RegAddrCity: 'CLAREMONT',
        RegAddrState: 'NH',
        RegAddrZip: 3743,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '23 FISKE PL',
        RegAddrCity: 'CLAREMONT',
        RegAddrState: 'NH',
        RegAddrZip: 3743,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '8 LINDY AVE',
        RegAddrCity: 'CLAREMONT',
        RegAddrState: 'NH',
        RegAddrZip: 3743,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '18 BOND ST',
        RegAddrCity: 'CLAREMONT',
        RegAddrState: 'NH',
        RegAddrZip: 3743,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '12 CHARLES ST',
        RegAddrCity: 'CLAREMONT',
        RegAddrState: 'NH',
        RegAddrZip: 3743,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '38 COLBY ST',
        RegAddrCity: 'COLEBROOK',
        RegAddrState: 'NH',
        RegAddrZip: 3576,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '43 BUNGY RD',
        RegAddrCity: 'COLEBROOK',
        RegAddrState: 'NH',
        RegAddrZip: 3576,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '141 DIAMOND POND RD',
        RegAddrCity: 'COLEBROOK',
        RegAddrState: 'NH',
        RegAddrZip: 3576,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '5 ENGEL ST',
        RegAddrCity: 'CONCORD',
        RegAddrState: 'NH',
        RegAddrZip: 3301,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '119 PEMBROKE RD',
        RegAddrCity: 'CONCORD',
        RegAddrState: 'NH',
        RegAddrZip: 3301,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '46 EASTMAN ST',
        RegAddrCity: 'CONCORD',
        RegAddrState: 'NH',
        RegAddrZip: 3301,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '120 SEWALLS FALLS RD',
        RegAddrCity: 'CONCORD',
        RegAddrState: 'NH',
        RegAddrZip: 3301,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '12 COTE ST',
        RegAddrCity: 'CONCORD',
        RegAddrState: 'NH',
        RegAddrZip: 3301,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '4 WOODMAN ST',
        RegAddrCity: 'CONCORD',
        RegAddrState: 'NH',
        RegAddrZip: 3301,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '5 FOGG ST',
        RegAddrCity: 'CONCORD',
        RegAddrState: 'NH',
        RegAddrZip: 3301,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '18 N SPRING ST',
        RegAddrCity: 'CONCORD',
        RegAddrState: 'NH',
        RegAddrZip: 3301,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '6 OAKMONT DR',
        RegAddrCity: 'CONCORD',
        RegAddrState: 'NH',
        RegAddrZip: 3301,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '224 LITTLE POND RD',
        RegAddrCity: 'CONCORD',
        RegAddrState: 'NH',
        RegAddrZip: 3301,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '57 DUNKLEE ST',
        RegAddrCity: 'CONCORD',
        RegAddrState: 'NH',
        RegAddrZip: 3301,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '109 GARVINS FALLS RD',
        RegAddrCity: 'CONCORD',
        RegAddrState: 'NH',
        RegAddrZip: 3301,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '16 E SIDE DR',
        RegAddrCity: 'CONCORD',
        RegAddrState: 'NH',
        RegAddrZip: 3301,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '77 DOMINIQUE DR',
        RegAddrCity: 'CONCORD',
        RegAddrState: 'NH',
        RegAddrZip: 3301,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '11 CRESTWOOD DR',
        RegAddrCity: 'CONCORD',
        RegAddrState: 'NH',
        RegAddrZip: 3301,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '194 PORTSMOUTH ST',
        RegAddrCity: 'CONCORD',
        RegAddrState: 'NH',
        RegAddrZip: 3301,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '17 BRADLEY ST',
        RegAddrCity: 'CONCORD',
        RegAddrState: 'NH',
        RegAddrZip: 3301,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '36 CLINTON ST',
        RegAddrCity: 'CONCORD',
        RegAddrState: 'NH',
        RegAddrZip: 3301,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '17 PRINCETON ST',
        RegAddrCity: 'CONCORD',
        RegAddrState: 'NH',
        RegAddrZip: 3301,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '203 PORTSMOUTH ST',
        RegAddrCity: 'CONCORD',
        RegAddrState: 'NH',
        RegAddrZip: 3301,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '437 JOSIAH BARTLETT RD',
        RegAddrCity: 'CONCORD',
        RegAddrState: 'NH',
        RegAddrZip: 3301,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '4 DOGWOOD TER',
        RegAddrCity: 'CONCORD',
        RegAddrState: 'NH',
        RegAddrZip: 3303,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '12 CHARLES ST',
        RegAddrCity: 'CONCORD',
        RegAddrState: 'NH',
        RegAddrZip: 3303,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '20 WHITEWATER DR',
        RegAddrCity: 'CONCORD',
        RegAddrState: 'NH',
        RegAddrZip: 3303,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '7 RICHMOND DR',
        RegAddrCity: 'CONCORD',
        RegAddrState: 'NH',
        RegAddrZip: 3303,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '74 W PARISH RD',
        RegAddrCity: 'CONCORD',
        RegAddrState: 'NH',
        RegAddrZip: 3303,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '300 CENTER RD',
        RegAddrCity: 'CORNISH',
        RegAddrState: 'NH',
        RegAddrZip: 3745,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '305 FRENCH RD',
        RegAddrCity: 'DALTON',
        RegAddrState: 'NH',
        RegAddrZip: 3598,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '282 NORTH RD',
        RegAddrCity: 'DANBURY',
        RegAddrState: 'NH',
        RegAddrZip: 3230,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '121 MOUNT DELIGHT RD',
        RegAddrCity: 'DEERFIELD',
        RegAddrState: 'NH',
        RegAddrZip: 3037,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '210 SOUTH RD',
        RegAddrCity: 'DEERFIELD',
        RegAddrState: 'NH',
        RegAddrZip: 3037,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '302 OLD COUNTY RD',
        RegAddrCity: 'DEERING',
        RegAddrState: 'NH',
        RegAddrZip: 3244,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '174 DOOE RD',
        RegAddrCity: 'DUBLIN',
        RegAddrState: 'NH',
        RegAddrZip: 3444,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '34 STARK HWY N',
        RegAddrCity: 'DUNBARTON',
        RegAddrState: 'NH',
        RegAddrZip: 3046,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2 MORSE RD',
        RegAddrCity: 'DUNBARTON',
        RegAddrState: 'NH',
        RegAddrZip: 3046,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '181 CHOATE RD',
        RegAddrCity: 'ENFIELD',
        RegAddrState: 'NH',
        RegAddrZip: 3748,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '77 CHOATE RD',
        RegAddrCity: 'ENFIELD',
        RegAddrState: 'NH',
        RegAddrZip: 3748,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '20 STEVENS ST',
        RegAddrCity: 'ENFIELD',
        RegAddrState: 'NH',
        RegAddrZip: 3748,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '111 N PEMBROKE RD',
        RegAddrCity: 'EPSOM',
        RegAddrState: 'NH',
        RegAddrZip: 3234,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1583 DOVER RD',
        RegAddrCity: 'EPSOM',
        RegAddrState: 'NH',
        RegAddrZip: 3234,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '313 LOCKES HILL RD',
        RegAddrCity: 'EPSOM',
        RegAddrState: 'NH',
        RegAddrZip: 3234,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '137 GAP MOUNTAIN RD',
        RegAddrCity: 'FITZWILLIAM',
        RegAddrState: 'NH',
        RegAddrZip: 3447,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '220 OLD COUNTY RD S',
        RegAddrCity: 'FRANCESTOWN',
        RegAddrState: 'NH',
        RegAddrZip: 3043,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '302 PLANTATION RD',
        RegAddrCity: 'FRANCONIA',
        RegAddrState: 'NH',
        RegAddrZip: 3580,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1 WOODBINE DR',
        RegAddrCity: 'FRANKLIN',
        RegAddrState: 'NH',
        RegAddrZip: 3235,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '24 GLORY AVE',
        RegAddrCity: 'FRANKLIN',
        RegAddrState: 'NH',
        RegAddrZip: 3235,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '137 WINNIPESAUKEE ST',
        RegAddrCity: 'FRANKLIN',
        RegAddrState: 'NH',
        RegAddrZip: 3235,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '45 W BOW ST',
        RegAddrCity: 'FRANKLIN',
        RegAddrState: 'NH',
        RegAddrZip: 3235,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '10 UPLAND DR',
        RegAddrCity: 'FRANKLIN',
        RegAddrState: 'NH',
        RegAddrZip: 3235,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1061 LAKE AVE',
        RegAddrCity: 'GEORGES MILLS',
        RegAddrState: 'NH',
        RegAddrZip: 3751,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '7 HERITAGE LN',
        RegAddrCity: 'GILMANTON',
        RegAddrState: 'NH',
        RegAddrZip: 3237,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '38 VESSELL ROCK RD',
        RegAddrCity: 'GILSUM',
        RegAddrState: 'NH',
        RegAddrZip: 3448,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '13 DUBLIN ST',
        RegAddrCity: 'GORHAM',
        RegAddrState: 'NH',
        RegAddrZip: 3581,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '72 EVANS ST',
        RegAddrCity: 'GORHAM',
        RegAddrState: 'NH',
        RegAddrZip: 3581,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '18 CASCADE FLT',
        RegAddrCity: 'GORHAM',
        RegAddrState: 'NH',
        RegAddrZip: 3581,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '33 BANGOR ST',
        RegAddrCity: 'GORHAM',
        RegAddrState: 'NH',
        RegAddrZip: 3581,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '4 SUNSET ST',
        RegAddrCity: 'GORHAM',
        RegAddrState: 'NH',
        RegAddrZip: 3581,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '14 ANDROSCOGGIN ST',
        RegAddrCity: 'GORHAM',
        RegAddrState: 'NH',
        RegAddrZip: 3581,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1295 BROOK RD',
        RegAddrCity: 'GOSHEN',
        RegAddrState: 'NH',
        RegAddrZip: 3752,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '351 MILLER POND RD',
        RegAddrCity: 'GRANTHAM',
        RegAddrState: 'NH',
        RegAddrZip: 3753,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '605 DARLING HILL RD',
        RegAddrCity: 'GREENVILLE',
        RegAddrState: 'NH',
        RegAddrZip: 3048,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '47 MILL ST',
        RegAddrCity: 'GREENVILLE',
        RegAddrState: 'NH',
        RegAddrZip: 3048,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1549 N GROTON RD',
        RegAddrCity: 'GROTON',
        RegAddrState: 'NH',
        RegAddrZip: 3266,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '62 PETERBOROUGH RD',
        RegAddrCity: 'HANCOCK',
        RegAddrState: 'NH',
        RegAddrZip: 3449,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '89 LONGVIEW RD',
        RegAddrCity: 'HANCOCK',
        RegAddrState: 'NH',
        RegAddrZip: 3449,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '261 FOREST RD',
        RegAddrCity: 'HANCOCK',
        RegAddrState: 'NH',
        RegAddrZip: 3449,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '6 BERRILL FARMS LN',
        RegAddrCity: 'HANOVER',
        RegAddrState: 'NH',
        RegAddrZip: 3755,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1 MCCULLOCH HALL 209',
        RegAddrCity: 'HANOVER',
        RegAddrState: 'NH',
        RegAddrZip: 3755,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1 MCCULLOCH HALL 108',
        RegAddrCity: 'HANOVER',
        RegAddrState: 'NH',
        RegAddrZip: 3755,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '64 SILVER RD',
        RegAddrCity: 'HARRISVILLE',
        RegAddrState: 'NH',
        RegAddrZip: 3450,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '695 DARTMOUTH COLLEGE HWY',
        RegAddrCity: 'HAVERHILL',
        RegAddrState: 'NH',
        RegAddrZip: 3765,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '51 COURT ST',
        RegAddrCity: 'HAVERHILL',
        RegAddrState: 'NH',
        RegAddrZip: 3765,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '588 HIGHLAND DR',
        RegAddrCity: 'HENNIKER',
        RegAddrState: 'NH',
        RegAddrZip: 3242,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '398 FOSTER HILL RD',
        RegAddrCity: 'HENNIKER',
        RegAddrState: 'NH',
        RegAddrZip: 3242,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '55 UNION ST',
        RegAddrCity: 'HENNIKER',
        RegAddrState: 'NH',
        RegAddrZip: 3242,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1237 QUAKER ST',
        RegAddrCity: 'HENNIKER',
        RegAddrState: 'NH',
        RegAddrZip: 3242,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '161 MAPLE ST',
        RegAddrCity: 'HENNIKER',
        RegAddrState: 'NH',
        RegAddrZip: 3242,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '46 HENNIKER ST',
        RegAddrCity: 'HILLSBOROUGH',
        RegAddrState: 'NH',
        RegAddrZip: 3244,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '37 BOULDER RD',
        RegAddrCity: 'HILLSBOROUGH',
        RegAddrState: 'NH',
        RegAddrZip: 3244,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '4 HOYT LN',
        RegAddrCity: 'HILLSBOROUGH',
        RegAddrState: 'NH',
        RegAddrZip: 3244,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '18 PINE ST',
        RegAddrCity: 'HINSDALE',
        RegAddrState: 'NH',
        RegAddrZip: 3451,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '49 JEWETT LN',
        RegAddrCity: 'HOLLIS',
        RegAddrState: 'NH',
        RegAddrZip: 3049,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '151 DEPOT RD',
        RegAddrCity: 'HOLLIS',
        RegAddrState: 'NH',
        RegAddrZip: 3049,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '143 DOW RD',
        RegAddrCity: 'HOLLIS',
        RegAddrState: 'NH',
        RegAddrZip: 3049,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '151 FARLEY RD',
        RegAddrCity: 'HOLLIS',
        RegAddrState: 'NH',
        RegAddrZip: 3049,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '13 CAMERON DR',
        RegAddrCity: 'HOLLIS',
        RegAddrState: 'NH',
        RegAddrZip: 3049,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '146 WITCHES SPRING RD',
        RegAddrCity: 'HOLLIS',
        RegAddrState: 'NH',
        RegAddrZip: 3049,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '53 BROOKWOOD LN',
        RegAddrCity: 'HOPKINTON',
        RegAddrState: 'NH',
        RegAddrZip: 3229,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '79 BARTON CORNER RD',
        RegAddrCity: 'HOPKINTON',
        RegAddrState: 'NH',
        RegAddrZip: 3229,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '36 EDGEWOOD DR',
        RegAddrCity: 'HOPKINTON',
        RegAddrState: 'NH',
        RegAddrZip: 3229,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1390 SOUTH RD',
        RegAddrCity: 'HOPKINTON',
        RegAddrState: 'NH',
        RegAddrZip: 3229,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '63A KIENIA RD',
        RegAddrCity: 'HUDSON',
        RegAddrState: 'NH',
        RegAddrZip: 3051,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '6 TAMARACK ST',
        RegAddrCity: 'HUDSON',
        RegAddrState: 'NH',
        RegAddrZip: 3051,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '58 WASON RD',
        RegAddrCity: 'HUDSON',
        RegAddrState: 'NH',
        RegAddrZip: 3051,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '47 CHARLONNE ST',
        RegAddrCity: 'JAFFREY',
        RegAddrState: 'NH',
        RegAddrZip: 3452,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '197 TURNPIKE RD',
        RegAddrCity: 'JAFFREY',
        RegAddrState: 'NH',
        RegAddrZip: 3452,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '43 MILL BROOK GARDEN RD',
        RegAddrCity: 'JEFFERSON',
        RegAddrState: 'NH',
        RegAddrZip: 3583,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '0 FISKE HALL KSC',
        RegAddrCity: 'KEENE',
        RegAddrState: 'NH',
        RegAddrZip: 3431,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '36 DOVER ST',
        RegAddrCity: 'KEENE',
        RegAddrState: 'NH',
        RegAddrZip: 3431,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '34 SHADOW LN',
        RegAddrCity: 'KEENE',
        RegAddrState: 'NH',
        RegAddrZip: 3431,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '75 TIMBERLANE DR',
        RegAddrCity: 'KEENE',
        RegAddrState: 'NH',
        RegAddrZip: 3431,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '606 MARLBORO ST',
        RegAddrCity: 'KEENE',
        RegAddrState: 'NH',
        RegAddrZip: 3431,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '9 WILCOX TER',
        RegAddrCity: 'KEENE',
        RegAddrState: 'NH',
        RegAddrZip: 3431,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '11 HOLT PL',
        RegAddrCity: 'KEENE',
        RegAddrState: 'NH',
        RegAddrZip: 3431,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '83 ORIOLE AVE',
        RegAddrCity: 'KEENE',
        RegAddrState: 'NH',
        RegAddrZip: 3431,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '52 RIDGEWOOD AVE',
        RegAddrCity: 'KEENE',
        RegAddrState: 'NH',
        RegAddrZip: 3431,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '0 MONADNOCK HALL KSC 221',
        RegAddrCity: 'KEENE',
        RegAddrState: 'NH',
        RegAddrZip: 3431,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '23 ALLEN CT',
        RegAddrCity: 'KEENE',
        RegAddrState: 'NH',
        RegAddrZip: 3431,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '15 SKYVIEW CIR',
        RegAddrCity: 'KEENE',
        RegAddrState: 'NH',
        RegAddrZip: 3431,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '64 PARK AVE',
        RegAddrCity: 'KEENE',
        RegAddrState: 'NH',
        RegAddrZip: 3431,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '17 CENTER ST',
        RegAddrCity: 'KEENE',
        RegAddrState: 'NH',
        RegAddrZip: 3431,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '0 RANDALL HALL KSC 109A',
        RegAddrCity: 'KEENE',
        RegAddrState: 'NH',
        RegAddrZip: 3431,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '16 FOXWOOD AVE',
        RegAddrCity: 'KEENE',
        RegAddrState: 'NH',
        RegAddrZip: 3431,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '184 ELM ST',
        RegAddrCity: 'KEENE',
        RegAddrState: 'NH',
        RegAddrZip: 3431,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '0 LIVING & LEARNING COMMONS KS',
        RegAddrCity: 'KEENE',
        RegAddrState: 'NH',
        RegAddrZip: 3435,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '6 1ST ST',
        RegAddrCity: 'LANCASTER',
        RegAddrState: 'NH',
        RegAddrZip: 3584,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '88 GRANGE RD',
        RegAddrCity: 'LANCASTER',
        RegAddrState: 'NH',
        RegAddrZip: 3584,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '59 GORE RD',
        RegAddrCity: 'LANCASTER',
        RegAddrState: 'NH',
        RegAddrZip: 3584,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '53 HIGH ST',
        RegAddrCity: 'LANCASTER',
        RegAddrState: 'NH',
        RegAddrZip: 3584,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '120 WALKER HILL RD',
        RegAddrCity: 'LANGDON',
        RegAddrState: 'NH',
        RegAddrZip: 3602,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '5 CEDAR ST',
        RegAddrCity: 'LEBANON',
        RegAddrState: 'NH',
        RegAddrZip: 3766,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '24 LEMPSTER ST',
        RegAddrCity: 'LEMPSTER',
        RegAddrState: 'NH',
        RegAddrZip: 3605,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '12 SHRENK RD',
        RegAddrCity: 'LEMPSTER',
        RegAddrState: 'NH',
        RegAddrZip: 3605,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '46 MALTAIS FARM RD',
        RegAddrCity: 'LINCOLN',
        RegAddrState: 'NH',
        RegAddrZip: 3251,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1450 SUGAR HILL RD',
        RegAddrCity: 'LISBON',
        RegAddrState: 'NH',
        RegAddrZip: 3585,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '150 SCHOOL ST',
        RegAddrCity: 'LISBON',
        RegAddrState: 'NH',
        RegAddrZip: 3585,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '12 NESENKEAG DR',
        RegAddrCity: 'LITCHFIELD',
        RegAddrState: 'NH',
        RegAddrZip: 3052,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '16 WEATHERSTONE RD',
        RegAddrCity: 'LITCHFIELD',
        RegAddrState: 'NH',
        RegAddrZip: 3052,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '19 FERNWOOD DR',
        RegAddrCity: 'LITCHFIELD',
        RegAddrState: 'NH',
        RegAddrZip: 3052,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2 TALENT RD',
        RegAddrCity: 'LITCHFIELD',
        RegAddrState: 'NH',
        RegAddrZip: 3052,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '115 TALENT RD',
        RegAddrCity: 'LITCHFIELD',
        RegAddrState: 'NH',
        RegAddrZip: 3052,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2090 N LITTLETON RD',
        RegAddrCity: 'LITTLETON',
        RegAddrState: 'NH',
        RegAddrZip: 3561,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '50 RICHMOND ST',
        RegAddrCity: 'LITTLETON',
        RegAddrState: 'NH',
        RegAddrZip: 3561,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '108 LOVERING AVE',
        RegAddrCity: 'LOUDON',
        RegAddrState: 'NH',
        RegAddrZip: 3307,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1579 CENTER RD',
        RegAddrCity: 'LYNDEBOROUGH',
        RegAddrState: 'NH',
        RegAddrZip: 3082,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '62 RAND ST',
        RegAddrCity: 'MANCHESTER',
        RegAddrState: 'NH',
        RegAddrZip: 3109,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '186 JAFFREY RD',
        RegAddrCity: 'MARLBOROUGH',
        RegAddrState: 'NH',
        RegAddrZip: 3455,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '108 GUSTIN POND RD',
        RegAddrCity: 'MARLOW',
        RegAddrState: 'NH',
        RegAddrZip: 3456,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '636 NH ROUTE 10',
        RegAddrCity: 'MARLOW',
        RegAddrState: 'NH',
        RegAddrZip: 3456,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '40 BRIDGE ST',
        RegAddrCity: 'MILFORD',
        RegAddrState: 'NH',
        RegAddrZip: 3055,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '20 SOUHEGAN ST',
        RegAddrCity: 'MILFORD',
        RegAddrState: 'NH',
        RegAddrZip: 3055,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '16 ELM ST',
        RegAddrCity: 'MILFORD',
        RegAddrState: 'NH',
        RegAddrZip: 3055,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '48 FRANCESTOWN TPKE',
        RegAddrCity: 'MONT VERNON',
        RegAddrState: 'NH',
        RegAddrZip: 3057,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2923 DARTMOUTH COLLEGE HWY',
        RegAddrCity: 'N HAVERHILL',
        RegAddrState: 'NH',
        RegAddrZip: 3774,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1084 BRIAR HILL RD',
        RegAddrCity: 'N HAVERHILL',
        RegAddrState: 'NH',
        RegAddrZip: 3774,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1242 NORTH RD',
        RegAddrCity: 'N SUTTON',
        RegAddrState: 'NH',
        RegAddrZip: 3260,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '4 DORA ST',
        RegAddrCity: 'NASHUA',
        RegAddrState: 'NH',
        RegAddrZip: 3060,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '8 NUTT ST',
        RegAddrCity: 'NASHUA',
        RegAddrState: 'NH',
        RegAddrZip: 3060,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '105 MONROE ST',
        RegAddrCity: 'NASHUA',
        RegAddrState: 'NH',
        RegAddrZip: 3060,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '10 W GLENWOOD ST',
        RegAddrCity: 'NASHUA',
        RegAddrState: 'NH',
        RegAddrZip: 3060,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '45 CENTRAL ST',
        RegAddrCity: 'NASHUA',
        RegAddrState: 'NH',
        RegAddrZip: 3060,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '76 BOWERS ST',
        RegAddrCity: 'NASHUA',
        RegAddrState: 'NH',
        RegAddrZip: 3060,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '54 KINGSTON DR',
        RegAddrCity: 'NASHUA',
        RegAddrState: 'NH',
        RegAddrZip: 3060,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '10 BENRUS ST',
        RegAddrCity: 'NASHUA',
        RegAddrState: 'NH',
        RegAddrZip: 3060,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '4A PINE GROVE AVE',
        RegAddrCity: 'NASHUA',
        RegAddrState: 'NH',
        RegAddrZip: 3060,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '11 VAN BUREN ST',
        RegAddrCity: 'NASHUA',
        RegAddrState: 'NH',
        RegAddrZip: 3060,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '107 LINWOOD ST',
        RegAddrCity: 'NASHUA',
        RegAddrState: 'NH',
        RegAddrZip: 3060,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '7 DEMANCHE ST',
        RegAddrCity: 'NASHUA',
        RegAddrState: 'NH',
        RegAddrZip: 3060,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '39 ASTER CT',
        RegAddrCity: 'NASHUA',
        RegAddrState: 'NH',
        RegAddrZip: 3062,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '49 PINEHURST AVE',
        RegAddrCity: 'NASHUA',
        RegAddrState: 'NH',
        RegAddrZip: 3062,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '93 CADOGAN WAY',
        RegAddrCity: 'NASHUA',
        RegAddrState: 'NH',
        RegAddrZip: 3062,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '32 PINEBROOK RD',
        RegAddrCity: 'NASHUA',
        RegAddrState: 'NH',
        RegAddrZip: 3062,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '181 E DUNSTABLE RD',
        RegAddrCity: 'NASHUA',
        RegAddrState: 'NH',
        RegAddrZip: 3062,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '29 VIECKIS DR',
        RegAddrCity: 'NASHUA',
        RegAddrState: 'NH',
        RegAddrZip: 3062,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '16 COUNTRY SIDE DR',
        RegAddrCity: 'NASHUA',
        RegAddrState: 'NH',
        RegAddrZip: 3062,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '14 WHITMAN RD',
        RegAddrCity: 'NASHUA',
        RegAddrState: 'NH',
        RegAddrZip: 3062,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '107 CADOGAN WAY',
        RegAddrCity: 'NASHUA',
        RegAddrState: 'NH',
        RegAddrZip: 3062,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '15 UPSTONE DR',
        RegAddrCity: 'NASHUA',
        RegAddrState: 'NH',
        RegAddrZip: 3063,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '79 DEERWOOD DR',
        RegAddrCity: 'NASHUA',
        RegAddrState: 'NH',
        RegAddrZip: 3063,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '5 FREEDOM ST',
        RegAddrCity: 'NASHUA',
        RegAddrState: 'NH',
        RegAddrZip: 3063,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '15 BAYMEADOW DR',
        RegAddrCity: 'NASHUA',
        RegAddrState: 'NH',
        RegAddrZip: 3063,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '8 KANATA DR',
        RegAddrCity: 'NASHUA',
        RegAddrState: 'NH',
        RegAddrZip: 3063,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '6 INDIAN ROCK RD',
        RegAddrCity: 'NASHUA',
        RegAddrState: 'NH',
        RegAddrZip: 3063,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '5 CLERGY CIR',
        RegAddrCity: 'NASHUA',
        RegAddrState: 'NH',
        RegAddrZip: 3063,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2 DAVIS CT',
        RegAddrCity: 'NASHUA',
        RegAddrState: 'NH',
        RegAddrZip: 3064,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '6 BIRCHWOOD DR',
        RegAddrCity: 'NASHUA',
        RegAddrState: 'NH',
        RegAddrZip: 3064,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '56 LOCK ST',
        RegAddrCity: 'NASHUA',
        RegAddrState: 'NH',
        RegAddrZip: 3064,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '15 FURNIVAL RD',
        RegAddrCity: 'NASHUA',
        RegAddrState: 'NH',
        RegAddrZip: 3064,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '360 APPLE HILL RD',
        RegAddrCity: 'NELSON',
        RegAddrState: 'NH',
        RegAddrZip: 3457,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '279 WEARE RD',
        RegAddrCity: 'NEW BOSTON',
        RegAddrState: 'NH',
        RegAddrZip: 3070,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '77 BEDFORD RD',
        RegAddrCity: 'NEW BOSTON',
        RegAddrState: 'NH',
        RegAddrZip: 3070,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '92 BYAM RD',
        RegAddrCity: 'NEW BOSTON',
        RegAddrState: 'NH',
        RegAddrZip: 3070,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '674 BEDFORD RD',
        RegAddrCity: 'NEW BOSTON',
        RegAddrState: 'NH',
        RegAddrZip: 3070,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '140 MOUNTAIN VIEW DR',
        RegAddrCity: 'NEW IPSWICH',
        RegAddrState: 'NH',
        RegAddrZip: 3071,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '541 MAIN ST',
        RegAddrCity: 'NEW LONDON',
        RegAddrState: 'NH',
        RegAddrZip: 3257,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '541 MAIN ST',
        RegAddrCity: 'NEW LONDON',
        RegAddrState: 'NH',
        RegAddrZip: 3257,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '156 DAVIS HILL RD',
        RegAddrCity: 'NEW LONDON',
        RegAddrState: 'NH',
        RegAddrZip: 3257,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '23 HIGHLAND RIDGE RD',
        RegAddrCity: 'NEW LONDON',
        RegAddrState: 'NH',
        RegAddrZip: 3257,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '186 BOWLES RD',
        RegAddrCity: 'NEWBURY',
        RegAddrState: 'NH',
        RegAddrZip: 3255,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '17 PIKE HILL RD',
        RegAddrCity: 'NEWPORT',
        RegAddrState: 'NH',
        RegAddrZip: 3773,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '50 MCDONOUGH RD',
        RegAddrCity: 'NEWPORT',
        RegAddrState: 'NH',
        RegAddrZip: 3773,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '469 E MOUNTAIN RD',
        RegAddrCity: 'NEWPORT',
        RegAddrState: 'NH',
        RegAddrZip: 3773,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '10 ARCH ST',
        RegAddrCity: 'NORTHFIELD',
        RegAddrState: 'NH',
        RegAddrZip: 3276,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '60 SANDOGARDY POND RD',
        RegAddrCity: 'NORTHFIELD',
        RegAddrState: 'NH',
        RegAddrZip: 3276,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '55 SPRUCE RD',
        RegAddrCity: 'NORTHUMBERLND',
        RegAddrState: 'NH',
        RegAddrZip: 3582,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '113 WEMYSS DR',
        RegAddrCity: 'NORTHUMBERLND',
        RegAddrState: 'NH',
        RegAddrZip: 3582,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '4 GOULET ST',
        RegAddrCity: 'NORTHUMBERLND',
        RegAddrState: 'NH',
        RegAddrZip: 3582,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '164 LONG POND RD',
        RegAddrCity: 'NORTHWOOD',
        RegAddrState: 'NH',
        RegAddrZip: 3261,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '71 PENDER RD',
        RegAddrCity: 'NORTHWOOD',
        RegAddrState: 'NH',
        RegAddrZip: 3261,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1232 1ST NH TPKE',
        RegAddrCity: 'NORTHWOOD',
        RegAddrState: 'NH',
        RegAddrZip: 3261,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '397 CATAMOUNT RD',
        RegAddrCity: 'NORTHWOOD',
        RegAddrState: 'NH',
        RegAddrZip: 3261,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '382 BOW LAKE RD',
        RegAddrCity: 'NORTHWOOD',
        RegAddrState: 'NH',
        RegAddrZip: 3261,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '25 RIVER RD',
        RegAddrCity: 'ORFORD',
        RegAddrState: 'NH',
        RegAddrZip: 3777,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '114 JEREMY HILL RD',
        RegAddrCity: 'PELHAM',
        RegAddrState: 'NH',
        RegAddrZip: 3076,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '133 PATRIOT DR',
        RegAddrCity: 'PELHAM',
        RegAddrState: 'NH',
        RegAddrZip: 3076,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '57 HEATHER LEE LN',
        RegAddrCity: 'PELHAM',
        RegAddrState: 'NH',
        RegAddrZip: 3076,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '14 BOW LN',
        RegAddrCity: 'PEMBROKE',
        RegAddrState: 'NH',
        RegAddrZip: 3275,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '155 TINA DR',
        RegAddrCity: 'PEMBROKE',
        RegAddrState: 'NH',
        RegAddrZip: 3275,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '16 CORAL ST',
        RegAddrCity: 'PENACOOK',
        RegAddrState: 'NH',
        RegAddrZip: 3303,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '20 PINE ST',
        RegAddrCity: 'PETERBOROUGH',
        RegAddrState: 'NH',
        RegAddrZip: 3458,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '267 OLD STREET RD',
        RegAddrCity: 'PETERBOROUGH',
        RegAddrState: 'NH',
        RegAddrZip: 3458,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '384 SAND HILL RD',
        RegAddrCity: 'PETERBOROUGH',
        RegAddrState: 'NH',
        RegAddrZip: 3458,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '4 MACDOWELL RD',
        RegAddrCity: 'PETERBOROUGH',
        RegAddrState: 'NH',
        RegAddrZip: 3458,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '20 WEBB RD',
        RegAddrCity: 'PETERBOROUGH',
        RegAddrState: 'NH',
        RegAddrZip: 3458,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '4 OAK ST',
        RegAddrCity: 'PETERBOROUGH',
        RegAddrState: 'NH',
        RegAddrZip: 3458,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '108 WINANT RD',
        RegAddrCity: 'PITTSFIELD',
        RegAddrState: 'NH',
        RegAddrZip: 3263,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '298 STAGE RD',
        RegAddrCity: 'PLAINFIELD',
        RegAddrState: 'NH',
        RegAddrZip: 3781,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '13 PETERSON RD',
        RegAddrCity: 'PLAINFIELD',
        RegAddrState: 'NH',
        RegAddrZip: 3781,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '286 LADIEU RD',
        RegAddrCity: 'PLAINFIELD',
        RegAddrState: 'NH',
        RegAddrZip: 3781,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '25 MERRILL ST',
        RegAddrCity: 'PLYMOUTH',
        RegAddrState: 'NH',
        RegAddrZip: 3264,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '520 PIKE HILL RD',
        RegAddrCity: 'PLYMOUTH',
        RegAddrState: 'NH',
        RegAddrZip: 3264,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '688 DURAND RD',
        RegAddrCity: 'RANDOLPH',
        RegAddrState: 'NH',
        RegAddrZip: 3593,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '51 CAMRI CT',
        RegAddrCity: 'RINDGE',
        RegAddrState: 'NH',
        RegAddrZip: 3461,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '99 W MAIN ST',
        RegAddrCity: 'RINDGE',
        RegAddrState: 'NH',
        RegAddrZip: 3461,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '46 SKYVIEW DR',
        RegAddrCity: 'RINDGE',
        RegAddrState: 'NH',
        RegAddrZip: 3461,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '40 UNIVERSITY DR',
        RegAddrCity: 'RINDGE',
        RegAddrState: 'NH',
        RegAddrZip: 3461,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '40 UNIVERSITY DR',
        RegAddrCity: 'RINDGE',
        RegAddrState: 'NH',
        RegAddrZip: 3461,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '180 MIDDLETOWN RD',
        RegAddrCity: 'ROXBURY',
        RegAddrState: 'NH',
        RegAddrZip: 3431,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '261 RUMNEY ROUTE 25',
        RegAddrCity: 'RUMNEY',
        RegAddrState: 'NH',
        RegAddrZip: 3266,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '93 PROSPECT HILL RD',
        RegAddrCity: 'RUMNEY',
        RegAddrState: 'NH',
        RegAddrZip: 3266,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '4 JOYCE HEARD AVE',
        RegAddrCity: 'SALEM',
        RegAddrState: 'NH',
        RegAddrZip: 3079,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '5 HUTCH RD',
        RegAddrCity: 'SALEM',
        RegAddrState: 'NH',
        RegAddrZip: 3079,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2 FAIRMONT RD',
        RegAddrCity: 'SALEM',
        RegAddrState: 'NH',
        RegAddrZip: 3079,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '55 NOYES RD',
        RegAddrCity: 'STEWARTSTOWN',
        RegAddrState: 'NH',
        RegAddrZip: 3576,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '867 HADLEY RD',
        RegAddrCity: 'SUGAR HILL',
        RegAddrState: 'NH',
        RegAddrZip: 3586,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '95 BOYNTON RD',
        RegAddrCity: 'SULLIVAN',
        RegAddrState: 'NH',
        RegAddrZip: 3445,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '479 CENTRE ST',
        RegAddrCity: 'SULLIVAN',
        RegAddrState: 'NH',
        RegAddrZip: 3445,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '326 NORTH RD',
        RegAddrCity: 'SUNAPEE',
        RegAddrState: 'NH',
        RegAddrZip: 3782,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1250 ROUTE 11',
        RegAddrCity: 'SUNAPEE',
        RegAddrState: 'NH',
        RegAddrZip: 3782,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '19 N SHORE RD',
        RegAddrCity: 'SUNAPEE',
        RegAddrState: 'NH',
        RegAddrZip: 3782,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '179 POND RD',
        RegAddrCity: 'SURRY',
        RegAddrState: 'NH',
        RegAddrZip: 3431,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '662 ROUTE 12A',
        RegAddrCity: 'SURRY',
        RegAddrState: 'NH',
        RegAddrZip: 3431,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '50 VILLAGE RD',
        RegAddrCity: 'SURRY',
        RegAddrState: 'NH',
        RegAddrZip: 3431,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '494 BAKER HILL RD',
        RegAddrCity: 'SUTTON',
        RegAddrState: 'NH',
        RegAddrZip: 3221,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '72 ROWELL HILL RD',
        RegAddrCity: 'SUTTON',
        RegAddrState: 'NH',
        RegAddrZip: 3260,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1146 ROUTE 114',
        RegAddrCity: 'SUTTON',
        RegAddrState: 'NH',
        RegAddrZip: 3273,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '76 GOODELL AVE',
        RegAddrCity: 'SWANZEY',
        RegAddrState: 'NH',
        RegAddrZip: 3446,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '8 WESTBROOK CT',
        RegAddrCity: 'SWANZEY',
        RegAddrState: 'NH',
        RegAddrZip: 3446,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '196 SOUTH RD',
        RegAddrCity: 'SWANZEY',
        RegAddrState: 'NH',
        RegAddrZip: 3446,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '67 SAWYERS CROSSING RD',
        RegAddrCity: 'SWANZEY',
        RegAddrState: 'NH',
        RegAddrZip: 3446,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '11 PONY FARM LN',
        RegAddrCity: 'TEMPLE',
        RegAddrState: 'NH',
        RegAddrZip: 3084,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '3 OLD REVOLUTIONARY RD',
        RegAddrCity: 'TEMPLE',
        RegAddrState: 'NH',
        RegAddrZip: 3084,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '67 FISH RD',
        RegAddrCity: 'TEMPLE',
        RegAddrState: 'NH',
        RegAddrZip: 3084,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '58 NORTHPOINTE RD',
        RegAddrCity: 'THORNTON',
        RegAddrState: 'NH',
        RegAddrZip: 3285,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '150 WOODWINDS RD',
        RegAddrCity: 'THORNTON',
        RegAddrState: 'NH',
        RegAddrZip: 3285,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '21 GRANITE LEDGE RD',
        RegAddrCity: 'THORNTON',
        RegAddrState: 'NH',
        RegAddrZip: 3285,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '133 TOLMAN RD',
        RegAddrCity: 'TROY',
        RegAddrState: 'NH',
        RegAddrZip: 3465,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '189 POOR RD',
        RegAddrCity: 'W CHESTERFLD',
        RegAddrState: 'NH',
        RegAddrZip: 3466,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '4 WESTBORO WOODS LN',
        RegAddrCity: 'W LEBANON',
        RegAddrState: 'NH',
        RegAddrZip: 3784,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '104 BREWERY RD',
        RegAddrCity: 'WALPOLE',
        RegAddrState: 'NH',
        RegAddrZip: 3608,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '25 MAIN ST',
        RegAddrCity: 'WALPOLE',
        RegAddrState: 'NH',
        RegAddrZip: 3608,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '75 1ST RD',
        RegAddrCity: 'WARNER',
        RegAddrState: 'NH',
        RegAddrZip: 3278,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '4 LAING BRIDGE LN',
        RegAddrCity: 'WARNER',
        RegAddrState: 'NH',
        RegAddrZip: 3278,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '3 W MAIN ST',
        RegAddrCity: 'WARNER',
        RegAddrState: 'NH',
        RegAddrZip: 3278,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '160 RED OAK HILL RD',
        RegAddrCity: 'WARREN',
        RegAddrState: 'NH',
        RegAddrZip: 3279,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '7 MOODY LN',
        RegAddrCity: 'WARREN',
        RegAddrState: 'NH',
        RegAddrZip: 3279,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '118 GOULD HILL RD',
        RegAddrCity: 'WARREN',
        RegAddrState: 'NH',
        RegAddrZip: 3279,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2458 VALLEY RD',
        RegAddrCity: 'WASHINGTON',
        RegAddrState: 'NH',
        RegAddrZip: 3280,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1129 HALFMOON POND RD',
        RegAddrCity: 'WASHINGTON',
        RegAddrState: 'NH',
        RegAddrZip: 3280,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '31 BROWN RIDGE RD',
        RegAddrCity: 'WEARE',
        RegAddrState: 'NH',
        RegAddrZip: 3281,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '31 SEWELL HOYT RD',
        RegAddrCity: 'WEARE',
        RegAddrState: 'NH',
        RegAddrZip: 3281,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1234 PLEASANT ST',
        RegAddrCity: 'WEBSTER',
        RegAddrState: 'NH',
        RegAddrZip: 3303,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '223 DEER MEADOW RD',
        RegAddrCity: 'WEBSTER',
        RegAddrState: 'NH',
        RegAddrZip: 3303,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '47 AUGUSTE RD',
        RegAddrCity: 'WENTWORTH',
        RegAddrState: 'NH',
        RegAddrZip: 3282,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '23 LAUREL LN',
        RegAddrCity: 'WILMOT',
        RegAddrState: 'NH',
        RegAddrZip: 3287,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '38 BUNKER HILL RD',
        RegAddrCity: 'WILMOT',
        RegAddrState: 'NH',
        RegAddrZip: 3287,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '24 EVANS RD',
        RegAddrCity: 'WILMOT',
        RegAddrState: 'NH',
        RegAddrZip: 3287,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '37 HEARTHSTONE',
        RegAddrCity: 'WILTON',
        RegAddrState: 'NH',
        RegAddrZip: 3086,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '19 HIGH ST',
        RegAddrCity: 'WINCHESTER',
        RegAddrState: 'NH',
        RegAddrZip: 3470,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '448 FOREST LAKE RD',
        RegAddrCity: 'WINCHESTER',
        RegAddrState: 'NH',
        RegAddrZip: 3470,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '80 KENDALL POND RD',
        RegAddrCity: 'WINDHAM',
        RegAddrState: 'NH',
        RegAddrZip: 3087,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '14 BEAR HILL RD',
        RegAddrCity: 'WINDHAM',
        RegAddrState: 'NH',
        RegAddrZip: 3087,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '3 HAYES HART RD',
        RegAddrCity: 'WINDHAM',
        RegAddrState: 'NH',
        RegAddrZip: 3087,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '183 RANGE RD',
        RegAddrCity: 'WINDHAM',
        RegAddrState: 'NH',
        RegAddrZip: 3087,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '48 RAILROAD ST',
        RegAddrCity: 'WOODSVILLE',
        RegAddrState: 'NH',
        RegAddrZip: 3785,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '28 BOOMHOWER RD',
        RegAddrCity: 'WOODSVILLE',
        RegAddrState: 'NH',
        RegAddrZip: 3785,
        CongressionalDistrict: 2,
      },
    ],
  },
  {
    name: 'NJ-1',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/NJ-1?ref=gh-pages',
    state: 'NJ',
    state_and_number: 'NJ-1',
    zip: 8002,
    congressionalDistrict: 1,
    addressesInDistrict: [
      {
        RegAddrLine1: '942 N 32ND ST',
        RegAddrCity: 'CAMDEN',
        RegAddrState: 'NJ',
        RegAddrZip: 8105,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '30 CHESTNUT TER',
        RegAddrCity: 'CHERRY HILL',
        RegAddrState: 'NJ',
        RegAddrZip: 8002,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '321 SOCIETY HILL BLVD',
        RegAddrCity: 'CHERRY HILL',
        RegAddrState: 'NJ',
        RegAddrZip: 8003,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '331 MONMOUTH ST',
        RegAddrCity: 'GLOUCESTER CITY',
        RegAddrState: 'NJ',
        RegAddrZip: 8030,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '501 MORRIS ST',
        RegAddrCity: 'GLOUCESTER CY',
        RegAddrState: 'NJ',
        RegAddrZip: 8030,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '129 WESTMONT AVE',
        RegAddrCity: 'HADDONFIELD',
        RegAddrState: 'NJ',
        RegAddrZip: 8033,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '4 DOGWOOD LN',
        RegAddrCity: 'HAMMONTON',
        RegAddrState: 'NJ',
        RegAddrZip: 8037,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '12 DERBY RD',
        RegAddrCity: 'LOGAN TWP',
        RegAddrState: 'NJ',
        RegAddrZip: 8085,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '211 W LINWOOD AVE',
        RegAddrCity: 'MAPLE SHADE',
        RegAddrState: 'NJ',
        RegAddrZip: 8052,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '7 ROYAL CT',
        RegAddrCity: 'VOORHEES',
        RegAddrState: 'NJ',
        RegAddrZip: 8043,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '100 YORK RD',
        RegAddrCity: 'WEST DEPTFORD',
        RegAddrState: 'NJ',
        RegAddrZip: 8086,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '308 4TH ST',
        RegAddrCity: 'WILLIAMSTOWN',
        RegAddrState: 'NJ',
        RegAddrZip: 8094,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '372 N TUCKAHOE RD',
        RegAddrCity: 'WILLIAMSTOWN',
        RegAddrState: 'NJ',
        RegAddrZip: 8094,
        CongressionalDistrict: 1,
      },
    ],
  },
  {
    name: 'NJ-10',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/NJ-10?ref=gh-pages',
    state: 'NJ',
    state_and_number: 'NJ-10',
    zip: 7018,
    congressionalDistrict: 10,
    addressesInDistrict: [
      {
        RegAddrLine1: '148 LINCOLN PL',
        RegAddrCity: 'IRVINGTON',
        RegAddrState: 'NJ',
        RegAddrZip: 7111,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '283 OCEAN AVE',
        RegAddrCity: 'JERSEY CITY',
        RegAddrState: 'NJ',
        RegAddrZip: 7305,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '256 GROVE ST',
        RegAddrCity: 'MONTCLAIR',
        RegAddrState: 'NJ',
        RegAddrZip: 7042,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '77 MONTGOMERY ST',
        RegAddrCity: 'NEWARK',
        RegAddrState: 'NJ',
        RegAddrZip: 7103,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '188 S 10TH ST',
        RegAddrCity: 'NEWARK',
        RegAddrState: 'NJ',
        RegAddrZip: 7107,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '7 WEEQUAHIC AVE',
        RegAddrCity: 'NEWARK',
        RegAddrState: 'NJ',
        RegAddrZip: 7112,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '2340 WHITTIER ST',
        RegAddrCity: 'RAHWAY',
        RegAddrState: 'NJ',
        RegAddrZip: 7065,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '1360 OMARA DR',
        RegAddrCity: 'UNION',
        RegAddrState: 'NJ',
        RegAddrZip: 7083,
        CongressionalDistrict: 10,
      },
    ],
  },
  {
    name: 'NJ-11',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/NJ-11?ref=gh-pages',
    state: 'NJ',
    state_and_number: 'NJ-11',
    zip: 7004,
    congressionalDistrict: 11,
    addressesInDistrict: [
      {
        RegAddrLine1: '10 RIVER RD',
        RegAddrCity: 'BOONTON TWP',
        RegAddrState: 'NJ',
        RegAddrZip: 7005,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '71 BIRCH RD',
        RegAddrCity: 'CEDAR GROVE',
        RegAddrState: 'NJ',
        RegAddrZip: 7009,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '30 WILSON AVE',
        RegAddrCity: 'CHATHAM',
        RegAddrState: 'NJ',
        RegAddrZip: 7928,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '49 ROWAN RD',
        RegAddrCity: 'CHATHAM',
        RegAddrState: 'NJ',
        RegAddrZip: 7928,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '18 PRICE CT',
        RegAddrCity: 'DENVILLE',
        RegAddrState: 'NJ',
        RegAddrZip: 7834,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '4 SAW MILL RD',
        RegAddrCity: 'KINNELON',
        RegAddrState: 'NJ',
        RegAddrZip: 7405,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '28 MOHAWK AVE',
        RegAddrCity: 'LINCOLN PARK',
        RegAddrState: 'NJ',
        RegAddrZip: 7035,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '15 MONTGOMERY AVE',
        RegAddrCity: 'MONTVILLE',
        RegAddrState: 'NJ',
        RegAddrZip: 7045,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '9 HANCOCK CT',
        RegAddrCity: 'MONTVILLE',
        RegAddrState: 'NJ',
        RegAddrZip: 7045,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '69 HIGH ST',
        RegAddrCity: 'NUTLEY',
        RegAddrState: 'NJ',
        RegAddrZip: 7110,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '25 ORCHARD ST',
        RegAddrCity: 'NUTLEY',
        RegAddrState: 'NJ',
        RegAddrZip: 7110,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '446 CHESTNUT ST',
        RegAddrCity: 'NUTLEY',
        RegAddrState: 'NJ',
        RegAddrZip: 7110,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '49 SHERBROOK DR',
        RegAddrCity: 'ROCKAWAY',
        RegAddrState: 'NJ',
        RegAddrZip: 7866,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '9 SUTTON AVE',
        RegAddrCity: 'TOTOWA',
        RegAddrState: 'NJ',
        RegAddrZip: 7512,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '9 DAVIES CT',
        RegAddrCity: 'WAYNE',
        RegAddrState: 'NJ',
        RegAddrZip: 7470,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '2 APPLE LN',
        RegAddrCity: 'WAYNE',
        RegAddrState: 'NJ',
        RegAddrZip: 7470,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '6 STUART LN',
        RegAddrCity: 'WAYNE',
        RegAddrState: 'NJ',
        RegAddrZip: 7470,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '66 HART DR',
        RegAddrCity: 'WEST ORANGE',
        RegAddrState: 'NJ',
        RegAddrZip: 7052,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '15 FAIRVIEW AVE',
        RegAddrCity: 'WEST ORANGE',
        RegAddrState: 'NJ',
        RegAddrZip: 7052,
        CongressionalDistrict: 11,
      },
    ],
  },
  {
    name: 'NJ-12',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/NJ-12?ref=gh-pages',
    state: 'NJ',
    state_and_number: 'NJ-12',
    zip: 7023,
    congressionalDistrict: 12,
    addressesInDistrict: [
      {
        RegAddrLine1: '110 CHESTNUT ST',
        RegAddrCity: 'BOUND BROOK',
        RegAddrState: 'NJ',
        RegAddrZip: 8805,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '302 S WASHINGTON AVE',
        RegAddrCity: 'DUNELLEN',
        RegAddrState: 'NJ',
        RegAddrZip: 8812,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '14 LENCH RD',
        RegAddrCity: 'E BRUNSWICK',
        RegAddrState: 'NJ',
        RegAddrZip: 8816,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '41 TOWNSEND CT',
        RegAddrCity: 'FRANKLIN PARK',
        RegAddrState: 'NJ',
        RegAddrZip: 8823,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '23 HOPATCONG DR',
        RegAddrCity: 'LAWRENCE',
        RegAddrState: 'NJ',
        RegAddrZip: 8648,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '5 CRESTHILL RD',
        RegAddrCity: 'LAWRENCE',
        RegAddrState: 'NJ',
        RegAddrZip: 8648,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '46 LAWRENCIA DR',
        RegAddrCity: 'LAWRENCEVILLE',
        RegAddrState: 'NJ',
        RegAddrZip: 8648,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '84 HARWOOD RD',
        RegAddrCity: 'MONROE',
        RegAddrState: 'NJ',
        RegAddrZip: 8831,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '16 IRELAND BROOK DR',
        RegAddrCity: 'N BRUNSWICK',
        RegAddrState: 'NJ',
        RegAddrZip: 8902,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '4 BENEDEK RD',
        RegAddrCity: 'PRINCETON',
        RegAddrState: 'NJ',
        RegAddrZip: 8540,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '321 VICTOR ST',
        RegAddrCity: 'SCOTCH PLAINS',
        RegAddrState: 'NJ',
        RegAddrZip: 7076,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '46 HILLCREST AVE',
        RegAddrCity: 'SOMERSET',
        RegAddrState: 'NJ',
        RegAddrZip: 8873,
        CongressionalDistrict: 12,
      },
    ],
  },
  {
    name: 'NJ-2',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/NJ-2?ref=gh-pages',
    state: 'NJ',
    state_and_number: 'NJ-2',
    zip: 8001,
    congressionalDistrict: 2,
    addressesInDistrict: [
      {
        RegAddrLine1: '90166 S BURLINGTON RD',
        RegAddrCity: 'BRIDGETON',
        RegAddrState: 'NJ',
        RegAddrZip: 8302,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '605 S HARDING HWY',
        RegAddrCity: 'BUENA',
        RegAddrState: 'NJ',
        RegAddrZip: 8310,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1210 6TH AVE',
        RegAddrCity: 'EGG HARBOR CY',
        RegAddrState: 'NJ',
        RegAddrZip: 8215,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '157 STEELMANVILLE RD',
        RegAddrCity: 'EGG HBR TWP',
        RegAddrState: 'NJ',
        RegAddrZip: 8234,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '106 IRIS DR',
        RegAddrCity: 'EGG HBR TWP',
        RegAddrState: 'NJ',
        RegAddrZip: 8234,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '150 7TH AVE W',
        RegAddrCity: 'ESTELL MANOR',
        RegAddrState: 'NJ',
        RegAddrZip: 8319,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '38 GOLF DR',
        RegAddrCity: 'HAMMONTON',
        RegAddrState: 'NJ',
        RegAddrZip: 8037,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '26 PORRECA DR',
        RegAddrCity: 'MILLVILLE',
        RegAddrState: 'NJ',
        RegAddrZip: 8332,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '17 EAGLE DR',
        RegAddrCity: 'MULLICA HILL',
        RegAddrState: 'NJ',
        RegAddrZip: 8062,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '105 WATERWAY RD',
        RegAddrCity: 'OCEAN CITY',
        RegAddrState: 'NJ',
        RegAddrZip: 8226,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '127 S BROAD ST',
        RegAddrCity: 'PENNS GROVE',
        RegAddrState: 'NJ',
        RegAddrZip: 8069,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '245 CENTER ST',
        RegAddrCity: 'TUCKERTON',
        RegAddrState: 'NJ',
        RegAddrZip: 8087,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1514 W WHEAT RD',
        RegAddrCity: 'VINELAND',
        RegAddrState: 'NJ',
        RegAddrZip: 8360,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2220 DELMAR AVE',
        RegAddrCity: 'VINELAND',
        RegAddrState: 'NJ',
        RegAddrZip: 8360,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '154 FORGE RD',
        RegAddrCity: 'WEST CREEK',
        RegAddrState: 'NJ',
        RegAddrZip: 8092,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '13 N MEADOW RIDGE LN',
        RegAddrCity: 'WOODBINE',
        RegAddrState: 'NJ',
        RegAddrZip: 8270,
        CongressionalDistrict: 2,
      },
    ],
  },
  {
    name: 'NJ-3',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/NJ-3?ref=gh-pages',
    state: 'NJ',
    state_and_number: 'NJ-3',
    zip: 7731,
    congressionalDistrict: 3,
    addressesInDistrict: [
      {
        RegAddrLine1: '6 MALLARD WAY',
        RegAddrCity: 'BARNEGAT',
        RegAddrState: 'NJ',
        RegAddrZip: 8005,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '3706 SUSSEX CT',
        RegAddrCity: 'BEVERLY',
        RegAddrState: 'NJ',
        RegAddrZip: 8010,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '45 WATERFORD DR',
        RegAddrCity: 'BORDENTOWN',
        RegAddrState: 'NJ',
        RegAddrZip: 8505,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1 EBB TIDE DR',
        RegAddrCity: 'BRICK',
        RegAddrState: 'NJ',
        RegAddrZip: 8723,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '7 DEVON RD',
        RegAddrCity: 'CINNAMINSON',
        RegAddrState: 'NJ',
        RegAddrZip: 8077,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '21 ANDREA DR',
        RegAddrCity: 'COLUMBUS',
        RegAddrState: 'NJ',
        RegAddrZip: 8022,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '7 WARREN CT',
        RegAddrCity: 'MANAHAWKIN',
        RegAddrState: 'NJ',
        RegAddrZip: 8050,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '129 W DOMINION DR',
        RegAddrCity: 'MARLTON',
        RegAddrState: 'NJ',
        RegAddrZip: 8053,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '49 S LOCUST AVE',
        RegAddrCity: 'MARLTON',
        RegAddrState: 'NJ',
        RegAddrZip: 8053,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '145 APACHE TRL',
        RegAddrCity: 'MEDFORD',
        RegAddrState: 'NJ',
        RegAddrZip: 8055,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '204 WASHINGTON ST',
        RegAddrCity: 'MOUNT HOLLY',
        RegAddrState: 'NJ',
        RegAddrZip: 8060,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '504 RIVERWOOD AVE',
        RegAddrCity: 'PT PLEASANT',
        RegAddrState: 'NJ',
        RegAddrZip: 8742,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '155 TWILIGHT CT',
        RegAddrCity: 'TOMS RIVER',
        RegAddrState: 'NJ',
        RegAddrZip: 8753,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '168 FORT DE FRANCE AVE',
        RegAddrCity: 'TOMS RIVER',
        RegAddrState: 'NJ',
        RegAddrZip: 8757,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '8 MEDFORD LN',
        RegAddrCity: 'WILLINGBORO',
        RegAddrState: 'NJ',
        RegAddrZip: 8046,
        CongressionalDistrict: 3,
      },
    ],
  },
  {
    name: 'NJ-4',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/NJ-4?ref=gh-pages',
    state: 'NJ',
    state_and_number: 'NJ-4',
    zip: 7701,
    congressionalDistrict: 4,
    addressesInDistrict: [
      {
        RegAddrLine1: '94 HOLMES MILL RD',
        RegAddrCity: 'CREAM RIDGE',
        RegAddrState: 'NJ',
        RegAddrZip: 8514,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '301 ADAMS ST',
        RegAddrCity: 'EATONTOWN',
        RegAddrState: 'NJ',
        RegAddrZip: 7724,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '77 JACKSON ST',
        RegAddrCity: 'FAIR HAVEN',
        RegAddrState: 'NJ',
        RegAddrZip: 7704,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '8 GRANT ST',
        RegAddrCity: 'FARMINGDALE',
        RegAddrState: 'NJ',
        RegAddrZip: 7727,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '35 HAMPTON DR',
        RegAddrCity: 'FREEHOLD',
        RegAddrState: 'NJ',
        RegAddrZip: 7728,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '311 ROCK OAK RD',
        RegAddrCity: 'FREEHOLD',
        RegAddrState: 'NJ',
        RegAddrZip: 7728,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '18 SHACKAMAXON DR',
        RegAddrCity: 'HAMILTON',
        RegAddrState: 'NJ',
        RegAddrZip: 8690,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '20 WEYBURNE RD',
        RegAddrCity: 'HAMILTON',
        RegAddrState: 'NJ',
        RegAddrZip: 8690,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '869 HOLMDEL RD',
        RegAddrCity: 'HOLMDEL',
        RegAddrState: 'NJ',
        RegAddrZip: 7733,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '6 TANGLEWOOD LN',
        RegAddrCity: 'HOLMDEL',
        RegAddrState: 'NJ',
        RegAddrZip: 7733,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '9 ARCHER SQ',
        RegAddrCity: 'JACKSON',
        RegAddrState: 'NJ',
        RegAddrZip: 8527,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '357 RIDGE AVE',
        RegAddrCity: 'LAKEWOOD',
        RegAddrState: 'NJ',
        RegAddrZip: 8701,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2 WICKATUNK RD',
        RegAddrCity: 'MANALAPAN',
        RegAddrState: 'NJ',
        RegAddrZip: 7726,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '8 QUAIL CT',
        RegAddrCity: 'MANALAPAN',
        RegAddrState: 'NJ',
        RegAddrZip: 7726,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2356 HUCKLEBERRY RD',
        RegAddrCity: 'MANCHESTER',
        RegAddrState: 'NJ',
        RegAddrZip: 8759,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '14 FALSTAFF DR',
        RegAddrCity: 'MANCHESTER',
        RegAddrState: 'NJ',
        RegAddrZip: 8759,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '314 ATLANTIC AVE',
        RegAddrCity: 'POINT PLEASANT BEACH',
        RegAddrState: 'NJ',
        RegAddrZip: 8742,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '117 CHATHAM LN',
        RegAddrCity: 'PT PLEASANT',
        RegAddrState: 'NJ',
        RegAddrZip: 8742,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '107 BURNET CRES',
        RegAddrCity: 'ROBBINSVILLE',
        RegAddrState: 'NJ',
        RegAddrZip: 8691,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2032 FOX FIELD CIR',
        RegAddrCity: 'WALL TOWNSHIP',
        RegAddrState: 'NJ',
        RegAddrZip: 7719,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '385 DEUCE DR',
        RegAddrCity: 'WALL TOWNSHIP',
        RegAddrState: 'NJ',
        RegAddrZip: 7719,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '53B SUNSET RD',
        RegAddrCity: 'WHITING',
        RegAddrState: 'NJ',
        RegAddrZip: 8759,
        CongressionalDistrict: 4,
      },
    ],
  },
  {
    name: 'NJ-5',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/NJ-5?ref=gh-pages',
    state: 'NJ',
    state_and_number: 'NJ-5',
    zip: 7401,
    congressionalDistrict: 5,
    addressesInDistrict: [
      {
        RegAddrLine1: '29 SCENIC DR',
        RegAddrCity: 'ANDOVER',
        RegAddrState: 'NJ',
        RegAddrZip: 7821,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '679 ACKERMAN AVE',
        RegAddrCity: 'GLEN ROCK',
        RegAddrState: 'NJ',
        RegAddrZip: 7452,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '201 DAVIS AVE',
        RegAddrCity: 'HACKENSACK',
        RegAddrState: 'NJ',
        RegAddrZip: 7601,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '25 MOCKINGBIRD',
        RegAddrCity: 'HACKETTSTOWN',
        RegAddrState: 'NJ',
        RegAddrZip: 7840,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '33 WINDING BROOK RD',
        RegAddrCity: 'HAMBURG',
        RegAddrState: 'NJ',
        RegAddrZip: 7419,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '62 CEDAR TREE DR',
        RegAddrCity: 'HIGHLAND LKS',
        RegAddrState: 'NJ',
        RegAddrZip: 7422,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '190 CLINTON AVE',
        RegAddrCity: 'NEW MILFORD',
        RegAddrState: 'NJ',
        RegAddrZip: 7646,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '367 VOMEL DR',
        RegAddrCity: 'NEW MILFORD',
        RegAddrState: 'NJ',
        RegAddrZip: 7646,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '374 LEONARD DR',
        RegAddrCity: 'NEW MILFORD',
        RegAddrState: 'NJ',
        RegAddrZip: 7646,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '8 GREEN LN',
        RegAddrCity: 'OAK RIDGE',
        RegAddrState: 'NJ',
        RegAddrZip: 7438,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '29 S 3RD ST',
        RegAddrCity: 'PARK RIDGE',
        RegAddrState: 'NJ',
        RegAddrZip: 7656,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '253 CAPRI TER',
        RegAddrCity: 'PARK RIDGE',
        RegAddrState: 'NJ',
        RegAddrZip: 7656,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '834 5TH AVE',
        RegAddrCity: 'RIVER EDGE',
        RegAddrState: 'NJ',
        RegAddrZip: 7661,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '10 LAKE STOCKHOLM TER',
        RegAddrCity: 'STOCKHOLM',
        RegAddrState: 'NJ',
        RegAddrZip: 7460,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '1608 ARDSLEY CT',
        RegAddrCity: 'TEANECK',
        RegAddrState: 'NJ',
        RegAddrZip: 7666,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '43 DIMMIG RD',
        RegAddrCity: 'U SADDLE RIV',
        RegAddrState: 'NJ',
        RegAddrZip: 7458,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '32 SKYTOP RD',
        RegAddrCity: 'WANTAGE',
        RegAddrState: 'NJ',
        RegAddrZip: 7461,
        CongressionalDistrict: 5,
      },
    ],
  },
  {
    name: 'NJ-6',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/NJ-6?ref=gh-pages',
    state: 'NJ',
    state_and_number: 'NJ-6',
    zip: 7001,
    congressionalDistrict: 6,
    addressesInDistrict: [
      {
        RegAddrLine1: '148 EASTWICK CT',
        RegAddrCity: 'ABERDEEN',
        RegAddrState: 'NJ',
        RegAddrZip: 7747,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '134 LONGFELLOW ST',
        RegAddrCity: 'CARTERET',
        RegAddrState: 'NJ',
        RegAddrZip: 7008,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '20 ARBOR CIR',
        RegAddrCity: 'EDISON',
        RegAddrState: 'NJ',
        RegAddrZip: 8837,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '16 VIOLA AVE',
        RegAddrCity: 'LEONARDO',
        RegAddrState: 'NJ',
        RegAddrZip: 7737,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '35 ROLFE PL',
        RegAddrCity: 'METUCHEN',
        RegAddrState: 'NJ',
        RegAddrZip: 8840,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '5 HOUSTON ST',
        RegAddrCity: 'METUCHEN',
        RegAddrState: 'NJ',
        RegAddrZip: 8840,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '77 WERAH PL',
        RegAddrCity: 'OCEANPORT',
        RegAddrState: 'NJ',
        RegAddrZip: 7757,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '372 ALPINE ST',
        RegAddrCity: 'PERTH AMBOY',
        RegAddrState: 'NJ',
        RegAddrZip: 8861,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '486 GREAT BEDS CT',
        RegAddrCity: 'PERTH AMBOY',
        RegAddrState: 'NJ',
        RegAddrZip: 8861,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '22 EWING DR',
        RegAddrCity: 'PISCATAWAY',
        RegAddrState: 'NJ',
        RegAddrZip: 8854,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '191 PRINCETON RD',
        RegAddrCity: 'PISCATAWAY',
        RegAddrState: 'NJ',
        RegAddrZip: 8854,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '149 ORCHARD AVE',
        RegAddrCity: 'SOUTH AMBOY',
        RegAddrState: 'NJ',
        RegAddrZip: 8879,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '187 GABRIEL TER',
        RegAddrCity: 'SOUTH AMBOY',
        RegAddrState: 'NJ',
        RegAddrZip: 8879,
        CongressionalDistrict: 6,
      },
    ],
  },
  {
    name: 'NJ-7',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/NJ-7?ref=gh-pages',
    state: 'NJ',
    state_and_number: 'NJ-7',
    zip: 7016,
    congressionalDistrict: 7,
    addressesInDistrict: [
      {
        RegAddrLine1: '864 SOUTH BLVD',
        RegAddrCity: 'ALPHA',
        RegAddrState: 'NJ',
        RegAddrZip: 8865,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '75 INDEPENDENCE DR',
        RegAddrCity: 'BASKING RIDGE',
        RegAddrState: 'NJ',
        RegAddrZip: 7920,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '116 BIRCH LN',
        RegAddrCity: 'BLOOMSBURY',
        RegAddrState: 'NJ',
        RegAddrZip: 8804,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '557 STATE ROUTE 24',
        RegAddrCity: 'CHESTER',
        RegAddrState: 'NJ',
        RegAddrZip: 7930,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '11 CONGER WAY',
        RegAddrCity: 'CLARK',
        RegAddrState: 'NJ',
        RegAddrZip: 7066,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '35 SPRING ST',
        RegAddrCity: 'FLEMINGTON',
        RegAddrState: 'NJ',
        RegAddrZip: 8822,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '137 DRAKESTOWN RD',
        RegAddrCity: 'HACKETTSTOWN',
        RegAddrState: 'NJ',
        RegAddrZip: 7840,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '7 CORNELL TRL',
        RegAddrCity: 'HILLSBOROUGH',
        RegAddrState: 'NJ',
        RegAddrZip: 8844,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '27 LONG VALLEY BLVD',
        RegAddrCity: 'LONG VALLEY',
        RegAddrState: 'NJ',
        RegAddrZip: 7853,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '30 SOUTHERN SLOPE DR',
        RegAddrCity: 'MILLBURN',
        RegAddrState: 'NJ',
        RegAddrZip: 7041,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '80 GREENWOOD DR',
        RegAddrCity: 'MILLBURN',
        RegAddrState: 'NJ',
        RegAddrZip: 7041,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '1 WYCKOFF DR',
        RegAddrCity: 'PITTSTOWN',
        RegAddrState: 'NJ',
        RegAddrZip: 8867,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '173 ORCHARD RD',
        RegAddrCity: 'SKILLMAN',
        RegAddrState: 'NJ',
        RegAddrZip: 8558,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '121 BYRAM KINGWOOD RD',
        RegAddrCity: 'STOCKTON',
        RegAddrState: 'NJ',
        RegAddrZip: 8559,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '3 IRIS RD',
        RegAddrCity: 'SUMMIT',
        RegAddrState: 'NJ',
        RegAddrZip: 7901,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '8 FOLKSTONE DR',
        RegAddrCity: 'WESTFIELD',
        RegAddrState: 'NJ',
        RegAddrZip: 7090,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '45 CODDINGTON RD',
        RegAddrCity: 'WHITEHOUSE STATION',
        RegAddrState: 'NJ',
        RegAddrZip: 8889,
        CongressionalDistrict: 7,
      },
    ],
  },
  {
    name: 'NJ-8',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/NJ-8?ref=gh-pages',
    state: 'NJ',
    state_and_number: 'NJ-8',
    zip: 7002,
    congressionalDistrict: 8,
    addressesInDistrict: [
      {
        RegAddrLine1: '72 BEVAN ST',
        RegAddrCity: 'JERSEY CITY',
        RegAddrState: 'NJ',
        RegAddrZip: 7306,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '42 HALSTEAD ST',
        RegAddrCity: 'KEARNY',
        RegAddrState: 'NJ',
        RegAddrZip: 7032,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '734 N 8TH ST',
        RegAddrCity: 'NEWARK',
        RegAddrState: 'NJ',
        RegAddrZip: 7107,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '403 4TH ST',
        RegAddrCity: 'UNION CITY',
        RegAddrState: 'NJ',
        RegAddrZip: 7087,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '426 64TH ST',
        RegAddrCity: 'WEST NEW YORK',
        RegAddrState: 'NJ',
        RegAddrZip: 7093,
        CongressionalDistrict: 8,
      },
    ],
  },
  {
    name: 'NJ-9',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/NJ-9?ref=gh-pages',
    state: 'NJ',
    state_and_number: 'NJ-9',
    zip: 7010,
    congressionalDistrict: 9,
    addressesInDistrict: [
      {
        RegAddrLine1: '170 CLINTON AVE',
        RegAddrCity: 'CLIFTON',
        RegAddrState: 'NJ',
        RegAddrZip: 7011,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '72 HADLEY AVE',
        RegAddrCity: 'CLIFTON',
        RegAddrState: 'NJ',
        RegAddrZip: 7011,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '125 VAN RIPER AVE',
        RegAddrCity: 'CLIFTON',
        RegAddrState: 'NJ',
        RegAddrZip: 7011,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '38 CHURCHILL RD',
        RegAddrCity: 'CRESSKILL',
        RegAddrState: 'NJ',
        RegAddrZip: 7626,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '10 SUSAN CT',
        RegAddrCity: 'ELMWOOD PARK',
        RegAddrState: 'NJ',
        RegAddrZip: 7407,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '88 MINERVA AVE',
        RegAddrCity: 'HAWTHORNE',
        RegAddrState: 'NJ',
        RegAddrZip: 7506,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '482 PINE HILL RD',
        RegAddrCity: 'LEONIA',
        RegAddrState: 'NJ',
        RegAddrZip: 7605,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '376 PACIFIC ST',
        RegAddrCity: 'PATERSON',
        RegAddrState: 'NJ',
        RegAddrZip: 7503,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '14-35 PLESINGER PL',
        RegAddrCity: 'PATERSON',
        RegAddrState: 'NJ',
        RegAddrZip: 7514,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '891 BANTA PL',
        RegAddrCity: 'RIDGEFIELD',
        RegAddrState: 'NJ',
        RegAddrZip: 7657,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '196 HAYWARD PL',
        RegAddrCity: 'WALLINGTON',
        RegAddrState: 'NJ',
        RegAddrZip: 7057,
        CongressionalDistrict: 9,
      },
    ],
  },
  {
    name: 'NM-1',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/NM-1?ref=gh-pages',
    state: 'NM',
    state_and_number: 'NM-1',
    zip: 87001,
    congressionalDistrict: 1,
    addressesInDistrict: [
      {
        RegAddrLine1: '120 3RD ST NW',
        RegAddrCity: 'ALBUQUERQUE',
        RegAddrState: 'NM',
        RegAddrZip: 87102,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '509 SILVER AVE SE',
        RegAddrCity: 'ALBUQUERQUE',
        RegAddrState: 'NM',
        RegAddrZip: 87102,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '434 PROSPECT AVE NW',
        RegAddrCity: 'ALBUQUERQUE',
        RegAddrState: 'NM',
        RegAddrZip: 87102,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '604 ETHLYN AVE SE',
        RegAddrCity: 'ALBUQUERQUE',
        RegAddrState: 'NM',
        RegAddrZip: 87102,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '404 LA VEGA DR SW',
        RegAddrCity: 'ALBUQUERQUE',
        RegAddrState: 'NM',
        RegAddrZip: 87105,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1511 SEVERO RD SW',
        RegAddrCity: 'ALBUQUERQUE',
        RegAddrState: 'NM',
        RegAddrZip: 87105,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1111 DAVID CT SW',
        RegAddrCity: 'ALBUQUERQUE',
        RegAddrState: 'NM',
        RegAddrZip: 87105,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '301 60TH ST NW',
        RegAddrCity: 'ALBUQUERQUE',
        RegAddrState: 'NM',
        RegAddrZip: 87105,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1943 GUN CLUB RD SW',
        RegAddrCity: 'ALBUQUERQUE',
        RegAddrState: 'NM',
        RegAddrZip: 87105,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1316 ATRISCO DR SW',
        RegAddrCity: 'ALBUQUERQUE',
        RegAddrState: 'NM',
        RegAddrZip: 87105,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '3807 RIVERVIEW CT NW',
        RegAddrCity: 'ALBUQUERQUE',
        RegAddrState: 'NM',
        RegAddrZip: 87105,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1038 ANTHONY LN SW',
        RegAddrCity: 'ALBUQUERQUE',
        RegAddrState: 'NM',
        RegAddrZip: 87105,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1820 VAIL CT SE',
        RegAddrCity: 'ALBUQUERQUE',
        RegAddrState: 'NM',
        RegAddrZip: 87106,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '3209 LOMA VISTA PL NE',
        RegAddrCity: 'ALBUQUERQUE',
        RegAddrState: 'NM',
        RegAddrZip: 87106,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1009 TULANE DR NE',
        RegAddrCity: 'ALBUQUERQUE',
        RegAddrState: 'NM',
        RegAddrZip: 87106,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1504 GOLD AVE SE',
        RegAddrCity: 'ALBUQUERQUE',
        RegAddrState: 'NM',
        RegAddrZip: 87106,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '522 MAPLE ST SE',
        RegAddrCity: 'ALBUQUERQUE',
        RegAddrState: 'NM',
        RegAddrZip: 87106,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1310 VAN CLEAVE RD NW',
        RegAddrCity: 'ALBUQUERQUE',
        RegAddrState: 'NM',
        RegAddrZip: 87107,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '627 FAIRWAY RD NW',
        RegAddrCity: 'ALBUQUERQUE',
        RegAddrState: 'NM',
        RegAddrZip: 87107,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '509 CAGUA DR SE',
        RegAddrCity: 'ALBUQUERQUE',
        RegAddrState: 'NM',
        RegAddrZip: 87108,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '320 ALISO DR SE',
        RegAddrCity: 'ALBUQUERQUE',
        RegAddrState: 'NM',
        RegAddrZip: 87108,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1504 MONROE PL SE',
        RegAddrCity: 'ALBUQUERQUE',
        RegAddrState: 'NM',
        RegAddrZip: 87108,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '6101 CENTRAL AVE NE',
        RegAddrCity: 'ALBUQUERQUE',
        RegAddrState: 'NM',
        RegAddrZip: 87108,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '6439 LINN AVE NE',
        RegAddrCity: 'ALBUQUERQUE',
        RegAddrState: 'NM',
        RegAddrZip: 87108,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '105 TEXAS ST SE',
        RegAddrCity: 'ALBUQUERQUE',
        RegAddrState: 'NM',
        RegAddrZip: 87108,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '4401 MONTGOMERY BLVD NE',
        RegAddrCity: 'ALBUQUERQUE',
        RegAddrState: 'NM',
        RegAddrZip: 87109,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '8238 KRIM DR NE',
        RegAddrCity: 'ALBUQUERQUE',
        RegAddrState: 'NM',
        RegAddrZip: 87109,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '4209 SHEPARD RD NE',
        RegAddrCity: 'ALBUQUERQUE',
        RegAddrState: 'NM',
        RegAddrZip: 87110,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2408 HENDOLA DR NE',
        RegAddrCity: 'ALBUQUERQUE',
        RegAddrState: 'NM',
        RegAddrZip: 87110,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1701 INEZ DR NE',
        RegAddrCity: 'ALBUQUERQUE',
        RegAddrState: 'NM',
        RegAddrZip: 87110,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '7409 MORROW AVE NE',
        RegAddrCity: 'ALBUQUERQUE',
        RegAddrState: 'NM',
        RegAddrZip: 87110,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '5504 EL ENCANTO PL NE',
        RegAddrCity: 'ALBUQUERQUE',
        RegAddrState: 'NM',
        RegAddrZip: 87110,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '12320 BAJA DR NE',
        RegAddrCity: 'ALBUQUERQUE',
        RegAddrState: 'NM',
        RegAddrZip: 87111,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '5500 AMISTAD RD NE',
        RegAddrCity: 'ALBUQUERQUE',
        RegAddrState: 'NM',
        RegAddrZip: 87111,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '9336 OSUNA PL NE',
        RegAddrCity: 'ALBUQUERQUE',
        RegAddrState: 'NM',
        RegAddrZip: 87111,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '12919 CHITALPA PL NE',
        RegAddrCity: 'ALBUQUERQUE',
        RegAddrState: 'NM',
        RegAddrZip: 87111,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '9601 LONA LN NE',
        RegAddrCity: 'ALBUQUERQUE',
        RegAddrState: 'NM',
        RegAddrZip: 87111,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '11208 WOODMAR LN NE',
        RegAddrCity: 'ALBUQUERQUE',
        RegAddrState: 'NM',
        RegAddrZip: 87111,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2616 EASTRIDGE DR NE',
        RegAddrCity: 'ALBUQUERQUE',
        RegAddrState: 'NM',
        RegAddrZip: 87112,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '10801 CONSTITUTION AVE NE',
        RegAddrCity: 'ALBUQUERQUE',
        RegAddrState: 'NM',
        RegAddrZip: 87112,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1812 PAIGE PL NE',
        RegAddrCity: 'ALBUQUERQUE',
        RegAddrState: 'NM',
        RegAddrZip: 87112,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '11205 HANNETT AVE NE',
        RegAddrCity: 'ALBUQUERQUE',
        RegAddrState: 'NM',
        RegAddrZip: 87112,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '13124 SUMMER PL NE',
        RegAddrCity: 'ALBUQUERQUE',
        RegAddrState: 'NM',
        RegAddrZip: 87112,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1300 NARCISCO CT NE',
        RegAddrCity: 'ALBUQUERQUE',
        RegAddrState: 'NM',
        RegAddrZip: 87112,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '10708 SNOWHEIGHTS BLVD NE',
        RegAddrCity: 'ALBUQUERQUE',
        RegAddrState: 'NM',
        RegAddrZip: 87112,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '9101 FAIRBANKS RD NE',
        RegAddrCity: 'ALBUQUERQUE',
        RegAddrState: 'NM',
        RegAddrZip: 87112,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '13131 VERBENA PL NE',
        RegAddrCity: 'ALBUQUERQUE',
        RegAddrState: 'NM',
        RegAddrZip: 87112,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '6501 AGAVE VERDE WAY NE',
        RegAddrCity: 'ALBUQUERQUE',
        RegAddrState: 'NM',
        RegAddrZip: 87113,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '5100 TIMAN AVE NW',
        RegAddrCity: 'ALBUQUERQUE',
        RegAddrState: 'NM',
        RegAddrZip: 87114,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '3305 CALLE CUERVO NW',
        RegAddrCity: 'ALBUQUERQUE',
        RegAddrState: 'NM',
        RegAddrZip: 87114,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '10335 RIO LOS PINOS DR NW',
        RegAddrCity: 'ALBUQUERQUE',
        RegAddrState: 'NM',
        RegAddrZip: 87114,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '4243 RIDINGCIRCLE RD NW',
        RegAddrCity: 'ALBUQUERQUE',
        RegAddrState: 'NM',
        RegAddrZip: 87114,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '5936 TAURUS AVE NW',
        RegAddrCity: 'ALBUQUERQUE',
        RegAddrState: 'NM',
        RegAddrZip: 87114,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '11035 CALANDRIAS ST NW',
        RegAddrCity: 'ALBUQUERQUE',
        RegAddrState: 'NM',
        RegAddrZip: 87114,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '10808 MONTEREY PIER DR NW',
        RegAddrCity: 'ALBUQUERQUE',
        RegAddrState: 'NM',
        RegAddrZip: 87114,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '7708 SHERWOOD DR NW',
        RegAddrCity: 'ALBUQUERQUE',
        RegAddrState: 'NM',
        RegAddrZip: 87120,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '4905 ROSEMARY DR NW',
        RegAddrCity: 'ALBUQUERQUE',
        RegAddrState: 'NM',
        RegAddrZip: 87120,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '6720 UPPER CANYON CT NW',
        RegAddrCity: 'ALBUQUERQUE',
        RegAddrState: 'NM',
        RegAddrZip: 87120,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '7736 RANCHWOOD DR NW',
        RegAddrCity: 'ALBUQUERQUE',
        RegAddrState: 'NM',
        RegAddrZip: 87120,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '8044 VINEMONT PL NW',
        RegAddrCity: 'ALBUQUERQUE',
        RegAddrState: 'NM',
        RegAddrZip: 87120,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '6528 CARNEY AVE NW',
        RegAddrCity: 'ALBUQUERQUE',
        RegAddrState: 'NM',
        RegAddrZip: 87120,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '416 69TH ST SW',
        RegAddrCity: 'ALBUQUERQUE',
        RegAddrState: 'NM',
        RegAddrZip: 87121,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '485 TIMOTHY CT SW',
        RegAddrCity: 'ALBUQUERQUE',
        RegAddrState: 'NM',
        RegAddrZip: 87121,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '9315 DAISY SUMMER AVE SW',
        RegAddrCity: 'ALBUQUERQUE',
        RegAddrState: 'NM',
        RegAddrZip: 87121,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '3219 LAZY DAY DR SW',
        RegAddrCity: 'ALBUQUERQUE',
        RegAddrState: 'NM',
        RegAddrZip: 87121,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1418 DEL REY PL SW',
        RegAddrCity: 'ALBUQUERQUE',
        RegAddrState: 'NM',
        RegAddrZip: 87121,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '402 BATAAN DR SW',
        RegAddrCity: 'ALBUQUERQUE',
        RegAddrState: 'NM',
        RegAddrZip: 87121,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1115 TELSTAR LOOP NW',
        RegAddrCity: 'ALBUQUERQUE',
        RegAddrState: 'NM',
        RegAddrZip: 87121,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '3014 DON PABLITO PL SW',
        RegAddrCity: 'ALBUQUERQUE',
        RegAddrState: 'NM',
        RegAddrZip: 87121,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1406 DEL MONTE TRL SW',
        RegAddrCity: 'ALBUQUERQUE',
        RegAddrState: 'NM',
        RegAddrZip: 87121,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '5600 WINCHESTER RD SW',
        RegAddrCity: 'ALBUQUERQUE',
        RegAddrState: 'NM',
        RegAddrZip: 87121,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '714 MALACHITE DR SW',
        RegAddrCity: 'ALBUQUERQUE',
        RegAddrState: 'NM',
        RegAddrZip: 87121,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '8409 HAMPTON AVE NE',
        RegAddrCity: 'ALBUQUERQUE',
        RegAddrState: 'NM',
        RegAddrZip: 87122,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '9202 NIGHT SKY LN NE',
        RegAddrCity: 'ALBUQUERQUE',
        RegAddrState: 'NM',
        RegAddrZip: 87122,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '7101 EUBANK BLVD NE',
        RegAddrCity: 'ALBUQUERQUE',
        RegAddrState: 'NM',
        RegAddrZip: 87122,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '9520 MODESTO AVE NE',
        RegAddrCity: 'ALBUQUERQUE',
        RegAddrState: 'NM',
        RegAddrZip: 87122,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '558 SANDLER DR NE',
        RegAddrCity: 'ALBUQUERQUE',
        RegAddrState: 'NM',
        RegAddrZip: 87123,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '9 PENASCO RD NE',
        RegAddrCity: 'ALBUQUERQUE',
        RegAddrState: 'NM',
        RegAddrZip: 87123,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '3 TRIAD LOOP NE',
        RegAddrCity: 'ALBUQUERQUE',
        RegAddrState: 'NM',
        RegAddrZip: 87123,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '31 SNOWLINE NORTE',
        RegAddrCity: 'CEDAR CREST',
        RegAddrState: 'NM',
        RegAddrZip: 87008,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '196 CAMINITO ALEGRE',
        RegAddrCity: 'CORRALES',
        RegAddrState: 'NM',
        RegAddrZip: 87048,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '107 PARADISE MEADOW LOOP',
        RegAddrCity: 'EDGEWOOD',
        RegAddrState: 'NM',
        RegAddrZip: 87015,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '101 METZGER RD',
        RegAddrCity: 'ESTANCIA',
        RegAddrState: 'NM',
        RegAddrZip: 87016,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '913 ANTELOPE TRL',
        RegAddrCity: 'LAS CRUCES',
        RegAddrState: 'NM',
        RegAddrZip: 88001,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '5650 SHADOW HILLS RD',
        RegAddrCity: 'LAS CRUCES',
        RegAddrState: 'NM',
        RegAddrZip: 88012,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '35 SANTA MONICA AVE',
        RegAddrCity: 'LOS LUNAS',
        RegAddrState: 'NM',
        RegAddrZip: 87031,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1002 ACEQUIA TRL NW',
        RegAddrCity: 'LOS RANCHOS',
        RegAddrState: 'NM',
        RegAddrZip: 87107,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '8150 RIO GRANDE BLVD NW',
        RegAddrCity: 'LOS RANCHOS',
        RegAddrState: 'NM',
        RegAddrZip: 87114,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '51 CAMINO DE LAS HUERTAS',
        RegAddrCity: 'PLACITAS',
        RegAddrState: 'NM',
        RegAddrZip: 87043,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1824 CLEARWATER LOOP NE',
        RegAddrCity: 'RIO RANCHO',
        RegAddrState: 'NM',
        RegAddrZip: 87144,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '8 KUHN DR',
        RegAddrCity: 'TIJERAS',
        RegAddrState: 'NM',
        RegAddrZip: 87059,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '28 LAGARTO RD',
        RegAddrCity: 'TIJERAS',
        RegAddrState: 'NM',
        RegAddrZip: 87059,
        CongressionalDistrict: 1,
      },
    ],
  },
  {
    name: 'NM-2',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/NM-2?ref=gh-pages',
    state: 'NM',
    state_and_number: 'NM-2',
    zip: 79346,
    congressionalDistrict: 2,
    addressesInDistrict: [
      {
        RegAddrLine1: '1408 CRESCENT DR',
        RegAddrCity: 'ALAMOGORDO',
        RegAddrState: 'NM',
        RegAddrZip: 88310,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '206 SANTA FE DR',
        RegAddrCity: 'ALAMOGORDO',
        RegAddrState: 'NM',
        RegAddrZip: 88310,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '3 MOONDALE RD',
        RegAddrCity: 'ALAMOGORDO',
        RegAddrState: 'NM',
        RegAddrZip: 88310,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '3894 TRAWOOD AVE',
        RegAddrCity: 'ALAMOGORDO',
        RegAddrState: 'NM',
        RegAddrZip: 88310,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '290 STATE HIGHWAY 220',
        RegAddrCity: 'ALTO',
        RegAddrState: 'NM',
        RegAddrZip: 88312,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2710 ANTHONY DR',
        RegAddrCity: 'ANTHONY',
        RegAddrState: 'NM',
        RegAddrZip: 88021,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1005 CHURCH ST',
        RegAddrCity: 'ANTHONY',
        RegAddrState: 'NM',
        RegAddrZip: 88021,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1300 VINTON RD',
        RegAddrCity: 'ANTHONY',
        RegAddrState: 'NM',
        RegAddrZip: 88021,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1803 W BRISCOE AVE',
        RegAddrCity: 'ARTESIA',
        RegAddrState: 'NM',
        RegAddrZip: 88210,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '30 BILLY DR',
        RegAddrCity: 'ARTESIA',
        RegAddrState: 'NM',
        RegAddrZip: 88210,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '502 ORCHARD ST',
        RegAddrCity: 'BAYARD',
        RegAddrState: 'NM',
        RegAddrZip: 88023,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '413 WISCONSIN AVE',
        RegAddrCity: 'BELEN',
        RegAddrState: 'NM',
        RegAddrZip: 87002,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '9 VICENTE RD',
        RegAddrCity: 'BELEN',
        RegAddrState: 'NM',
        RegAddrZip: 87002,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '39 TRIBAL ROAD 28',
        RegAddrCity: 'BOSQUE FARMS',
        RegAddrState: 'NM',
        RegAddrZip: 87068,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1215 LA VEGA RD',
        RegAddrCity: 'BOSQUE FARMS',
        RegAddrState: 'NM',
        RegAddrZip: 87068,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '117 N JAMES ST',
        RegAddrCity: 'CARLSBAD',
        RegAddrState: 'NM',
        RegAddrZip: 88220,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1117 W EDWARDS ST',
        RegAddrCity: 'CARLSBAD',
        RegAddrState: 'NM',
        RegAddrZip: 88220,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '853 MESILLA DR',
        RegAddrCity: 'CHAPARRAL',
        RegAddrState: 'NM',
        RegAddrZip: 88081,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '216 STEPHANIE',
        RegAddrCity: 'CHAPARRAL',
        RegAddrState: 'NM',
        RegAddrZip: 88081,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '468 BOX CANYON RD',
        RegAddrCity: 'CLIFF',
        RegAddrState: 'NM',
        RegAddrZip: 88028,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '108 E ORLIN AVE',
        RegAddrCity: 'COLUMBUS',
        RegAddrState: 'NM',
        RegAddrZip: 88029,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '614 SAN JUAN ST',
        RegAddrCity: 'DEMING',
        RegAddrState: 'NM',
        RegAddrZip: 88030,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1120 W OAK ST',
        RegAddrCity: 'DEMING',
        RegAddrState: 'NM',
        RegAddrZip: 88030,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '402 S TIN ST',
        RegAddrCity: 'DEMING',
        RegAddrState: 'NM',
        RegAddrZip: 88030,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '508 MESCAL CIR',
        RegAddrCity: 'ELEPHANT BTTE',
        RegAddrState: 'NM',
        RegAddrZip: 87935,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '807 TWENTIETH ST',
        RegAddrCity: 'EUNICE',
        RegAddrState: 'NM',
        RegAddrZip: 88231,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: 'HWY 180 MILE 43.5',
        RegAddrCity: 'GLENWOOD',
        RegAddrState: 'NM',
        RegAddrZip: 88039,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '8 DEER HORN LN',
        RegAddrCity: 'HIGH ROLLS MTN PARK',
        RegAddrState: 'NM',
        RegAddrZip: 88325,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1820 N FOWLER ST',
        RegAddrCity: 'HOBBS',
        RegAddrState: 'NM',
        RegAddrZip: 88240,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '222 NW COUNTY RD',
        RegAddrCity: 'HOBBS',
        RegAddrState: 'NM',
        RegAddrZip: 88240,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1603 S COCHRAN ST',
        RegAddrCity: 'HOBBS',
        RegAddrState: 'NM',
        RegAddrZip: 88240,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1130 W CALLE SUR ST',
        RegAddrCity: 'HOBBS',
        RegAddrState: 'NM',
        RegAddrZip: 88240,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '600 W COPPER AVE',
        RegAddrCity: 'HOBBS',
        RegAddrState: 'NM',
        RegAddrZip: 88240,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2420 W COLLEGE LN',
        RegAddrCity: 'HOBBS',
        RegAddrState: 'NM',
        RegAddrZip: 88242,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '13201 N CALLE BONITA ST',
        RegAddrCity: 'HOBBS',
        RegAddrState: 'NM',
        RegAddrZip: 88242,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '402 FRAN DR',
        RegAddrCity: 'HURLEY',
        RegAddrState: 'NM',
        RegAddrZip: 88043,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '885 LISTER DR',
        RegAddrCity: 'LA MESA',
        RegAddrState: 'NM',
        RegAddrZip: 88044,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1115 POPLAR AVE',
        RegAddrCity: 'LAS CRUCES',
        RegAddrState: 'NM',
        RegAddrZip: 88001,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '812 CANTERBURY ARC',
        RegAddrCity: 'LAS CRUCES',
        RegAddrState: 'NM',
        RegAddrZip: 88005,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '304 CAPRI ARC',
        RegAddrCity: 'LAS CRUCES',
        RegAddrState: 'NM',
        RegAddrZip: 88005,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '720 BROWN RD',
        RegAddrCity: 'LAS CRUCES',
        RegAddrState: 'NM',
        RegAddrZip: 88005,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '9415 N VALLEY DR',
        RegAddrCity: 'LAS CRUCES',
        RegAddrState: 'NM',
        RegAddrZip: 88007,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1077 MOON RIVER LOOP',
        RegAddrCity: 'LAS CRUCES',
        RegAddrState: 'NM',
        RegAddrZip: 88007,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2072 REINA DR',
        RegAddrCity: 'LAS CRUCES',
        RegAddrState: 'NM',
        RegAddrZip: 88007,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1685 STONE MOUNTAIN LN',
        RegAddrCity: 'LAS CRUCES',
        RegAddrState: 'NM',
        RegAddrZip: 88011,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '4538 SPANISH DAGGER ST',
        RegAddrCity: 'LAS CRUCES',
        RegAddrState: 'NM',
        RegAddrZip: 88011,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2225 LARAMIE DR',
        RegAddrCity: 'LAS CRUCES',
        RegAddrState: 'NM',
        RegAddrZip: 88011,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '795 STONE CANYON DR',
        RegAddrCity: 'LAS CRUCES',
        RegAddrState: 'NM',
        RegAddrZip: 88011,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '4667 PYRITE RD',
        RegAddrCity: 'LAS CRUCES',
        RegAddrState: 'NM',
        RegAddrZip: 88012,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '4651 PYRAMID PEAK DR',
        RegAddrCity: 'LAS CRUCES',
        RegAddrState: 'NM',
        RegAddrZip: 88012,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '6699 BRAHMAN RD',
        RegAddrCity: 'LAS CRUCES',
        RegAddrState: 'NM',
        RegAddrZip: 88012,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1225 LEA RANCH DR',
        RegAddrCity: 'LAS CRUCES',
        RegAddrState: 'NM',
        RegAddrZip: 88012,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '6485 SEXTON LN',
        RegAddrCity: 'LAS CRUCES',
        RegAddrState: 'NM',
        RegAddrZip: 88012,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '445 A COUNTY RD',
        RegAddrCity: 'LEMITAR',
        RegAddrState: 'NM',
        RegAddrZip: 87823,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '535 SOCORRO DR SW',
        RegAddrCity: 'LOS LUNAS',
        RegAddrState: 'NM',
        RegAddrZip: 87031,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '901 LANCELOT ST SW',
        RegAddrCity: 'LOS LUNAS',
        RegAddrState: 'NM',
        RegAddrZip: 87031,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '3039 STATE HIGHWAY 47',
        RegAddrCity: 'LOS LUNAS',
        RegAddrState: 'NM',
        RegAddrZip: 87031,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2017 CASTILLO DR SW',
        RegAddrCity: 'LOS LUNAS',
        RegAddrState: 'NM',
        RegAddrZip: 87031,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1153 CANAL BLVD SW',
        RegAddrCity: 'LOS LUNAS',
        RegAddrState: 'NM',
        RegAddrZip: 87031,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '45 MANZANO DR',
        RegAddrCity: 'LOS LUNAS',
        RegAddrState: 'NM',
        RegAddrZip: 87031,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '633 S PRAIRIEVIEW RD',
        RegAddrCity: 'LOVINGTON',
        RegAddrState: 'NM',
        RegAddrZip: 88260,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2924 APACHE DR',
        RegAddrCity: 'LOVINGTON',
        RegAddrState: 'NM',
        RegAddrZip: 88260,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '109 DUARTE RD',
        RegAddrCity: 'MALAGA',
        RegAddrState: 'NM',
        RegAddrZip: 88263,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '70 SILVER FOX TRL',
        RegAddrCity: 'MAYHILL',
        RegAddrState: 'NM',
        RegAddrZip: 88339,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '5 MAIN ST',
        RegAddrCity: 'MAYHILL',
        RegAddrState: 'NM',
        RegAddrZip: 88339,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: 'EAST/CHURCH ENCINAL VLG',
        RegAddrCity: 'NEW LAGUNA',
        RegAddrState: 'NM',
        RegAddrZip: 87038,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: 'PO BOX 100',
        RegAddrCity: 'NEW LAGUNA',
        RegAddrState: 'NM',
        RegAddrZip: 87038,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '29 WESTERN SKY RD',
        RegAddrCity: 'NEW LAGUNA',
        RegAddrState: 'NM',
        RegAddrZip: 87038,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '9 BLACK QUAIL RD',
        RegAddrCity: 'PERALTA',
        RegAddrState: 'NM',
        RegAddrZip: 87042,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '10 FOREST RD',
        RegAddrCity: 'PIE TOWN',
        RegAddrState: 'NM',
        RegAddrZip: 87827,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '4700 ZUNI DR',
        RegAddrCity: 'ROSWELL',
        RegAddrState: 'NM',
        RegAddrZip: 88201,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '341 E 8TH ST',
        RegAddrCity: 'ROSWELL',
        RegAddrState: 'NM',
        RegAddrZip: 88201,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '702 TRAILING HEART RD',
        RegAddrCity: 'ROSWELL',
        RegAddrState: 'NM',
        RegAddrZip: 88201,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '318 JINGLE BOB ST',
        RegAddrCity: 'ROSWELL',
        RegAddrState: 'NM',
        RegAddrZip: 88203,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '8 FOREST PL',
        RegAddrCity: 'ROSWELL',
        RegAddrState: 'NM',
        RegAddrZip: 88203,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2408 HINKLE ST',
        RegAddrCity: 'ROSWELL',
        RegAddrState: 'NM',
        RegAddrZip: 88203,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '24 LOMA LINDA DR',
        RegAddrCity: 'ROSWELL',
        RegAddrState: 'NM',
        RegAddrZip: 88203,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '614 E BLAND ST',
        RegAddrCity: 'ROSWELL',
        RegAddrState: 'NM',
        RegAddrZip: 88203,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '607 CARRIZO CANYON RD',
        RegAddrCity: 'RUIDOSO',
        RegAddrState: 'NM',
        RegAddrZip: 88345,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '41 W CIRCLE DR',
        RegAddrCity: 'SAN RAFAEL',
        RegAddrState: 'NM',
        RegAddrZip: 87051,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1041 CORTEZ AVE',
        RegAddrCity: 'SANTA ROSA',
        RegAddrState: 'NM',
        RegAddrZip: 88435,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '315 LLANO',
        RegAddrCity: 'SANTA ROSA',
        RegAddrState: 'NM',
        RegAddrZip: 88435,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '127 STAR SPIRIT RD',
        RegAddrCity: 'SANTA TERESA',
        RegAddrState: 'NM',
        RegAddrZip: 88008,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '900 HOLLY PARK AVE',
        RegAddrCity: 'SANTA TERESA',
        RegAddrState: 'NM',
        RegAddrZip: 88008,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2315 BOSWORTH DR',
        RegAddrCity: 'SILVER CITY',
        RegAddrState: 'NM',
        RegAddrZip: 88061,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '400 N ARIZONA ST',
        RegAddrCity: 'SILVER CITY',
        RegAddrState: 'NM',
        RegAddrZip: 88061,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '708 N BLACK ST',
        RegAddrCity: 'SILVER CITY',
        RegAddrState: 'NM',
        RegAddrZip: 88061,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1014 GOAD ST',
        RegAddrCity: 'SOCORRO',
        RegAddrState: 'NM',
        RegAddrZip: 87801,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '207 COLORADO DR',
        RegAddrCity: 'SUNLAND PARK',
        RegAddrState: 'NM',
        RegAddrZip: 88063,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '28 LOWER CUCHILLO CREEK RD',
        RegAddrCity: 'T OR C',
        RegAddrState: 'NM',
        RegAddrZip: 87901,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '401 DURAZNO ST',
        RegAddrCity: 'TULAROSA',
        RegAddrState: 'NM',
        RegAddrZip: 88352,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '315 CHALCOCITE ST',
        RegAddrCity: 'TYRONE',
        RegAddrState: 'NM',
        RegAddrZip: 88065,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '25 B SALT LAKE RD',
        RegAddrCity: 'ZUNI',
        RegAddrState: 'NM',
        RegAddrZip: 87327,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '6128 T EDAAKIE ST',
        RegAddrCity: 'ZUNI',
        RegAddrState: 'NM',
        RegAddrZip: 87327,
        CongressionalDistrict: 2,
      },
    ],
  },
  {
    name: 'NM-3',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/NM-3?ref=gh-pages',
    state: 'NM',
    state_and_number: 'NM-3',
    zip: 79022,
    congressionalDistrict: 3,
    addressesInDistrict: [
      {
        RegAddrLine1: '6924 CANYON CLIFF RD NW',
        RegAddrCity: 'ALBUQUERQUE',
        RegAddrState: 'NM',
        RegAddrZip: 87114,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '7223 WILLIAMSBURG RD NW',
        RegAddrCity: 'ALBUQUERQUE',
        RegAddrState: 'NM',
        RegAddrZip: 87114,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '7101 LUZ DEL SOL PL NW',
        RegAddrCity: 'ALBUQUERQUE',
        RegAddrState: 'NM',
        RegAddrZip: 87114,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '28 ROAD 3275',
        RegAddrCity: 'AZTEC',
        RegAddrState: 'NM',
        RegAddrZip: 87410,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '507 KIVA AVE',
        RegAddrCity: 'AZTEC',
        RegAddrState: 'NM',
        RegAddrZip: 87410,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '44 ROAD 2395',
        RegAddrCity: 'AZTEC',
        RegAddrState: 'NM',
        RegAddrZip: 87410,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '5936 QUAY ROAD G',
        RegAddrCity: 'BARD',
        RegAddrState: 'NM',
        RegAddrZip: 88411,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '24285 HIGHWAY 84',
        RegAddrCity: 'CEBOLLA',
        RegAddrState: 'NM',
        RegAddrZip: 87518,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '702 OAK ST',
        RegAddrCity: 'CLAYTON',
        RegAddrState: 'NM',
        RegAddrZip: 88415,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '113 E YUCCA AVE',
        RegAddrCity: 'CLOVIS',
        RegAddrState: 'NM',
        RegAddrZip: 88101,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '2124 RALPH BOONE',
        RegAddrCity: 'CLOVIS',
        RegAddrState: 'NM',
        RegAddrZip: 88101,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1908 WILSHIRE BLVD',
        RegAddrCity: 'CLOVIS',
        RegAddrState: 'NM',
        RegAddrZip: 88101,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '917 L CASILLAS BLVD',
        RegAddrCity: 'CLOVIS',
        RegAddrState: 'NM',
        RegAddrZip: 88101,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '6660 VOOSCANE AVE',
        RegAddrCity: 'COCHITI LAKE',
        RegAddrState: 'NM',
        RegAddrZip: 87083,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: 'COUNTY ROAD 81',
        RegAddrCity: 'CORDOVA',
        RegAddrState: 'NM',
        RegAddrZip: 87523,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1240 HOLLYWOOD BLVD',
        RegAddrCity: 'CORRALES',
        RegAddrState: 'NM',
        RegAddrZip: 87048,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1228 W LA ENTRADA',
        RegAddrCity: 'CORRALES',
        RegAddrState: 'NM',
        RegAddrZip: 87048,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: 'MM199 HIGHWAY 550',
        RegAddrCity: 'COUNSELORS',
        RegAddrState: 'NM',
        RegAddrZip: 87018,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '2015 MERCY ST',
        RegAddrCity: 'CROWNPOINT',
        RegAddrState: 'NM',
        RegAddrZip: 87313,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '13778 HIGHWAY 64',
        RegAddrCity: 'DULCE',
        RegAddrState: 'NM',
        RegAddrZip: 87528,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '7 GARRETT CT',
        RegAddrCity: 'EDGEWOOD',
        RegAddrState: 'NM',
        RegAddrZip: 87015,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '71 ASPEN RD',
        RegAddrCity: 'EDGEWOOD',
        RegAddrState: 'NM',
        RegAddrZip: 87015,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '901 CALLE LOPEZ',
        RegAddrCity: 'ESPANOLA',
        RegAddrState: 'NM',
        RegAddrZip: 87532,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '205B N CORONADO AVE',
        RegAddrCity: 'ESPANOLA',
        RegAddrState: 'NM',
        RegAddrZip: 87532,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '2800 NORTHWOOD DR',
        RegAddrCity: 'FARMINGTON',
        RegAddrState: 'NM',
        RegAddrZip: 87401,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '811 SPRUCE ST',
        RegAddrCity: 'FARMINGTON',
        RegAddrState: 'NM',
        RegAddrZip: 87401,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1219 FOUR SEASONS PKWY',
        RegAddrCity: 'FARMINGTON',
        RegAddrState: 'NM',
        RegAddrZip: 87401,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '2408 E 24TH ST',
        RegAddrCity: 'FARMINGTON',
        RegAddrState: 'NM',
        RegAddrZip: 87401,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '4402 CALLE MIO AVE',
        RegAddrCity: 'FARMINGTON',
        RegAddrState: 'NM',
        RegAddrZip: 87401,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '2305 E 17TH ST',
        RegAddrCity: 'FARMINGTON',
        RegAddrState: 'NM',
        RegAddrZip: 87401,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '8 ROAD 5587',
        RegAddrCity: 'FARMINGTON',
        RegAddrState: 'NM',
        RegAddrZip: 87401,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '5011 TAMPICO WAY',
        RegAddrCity: 'FARMINGTON',
        RegAddrState: 'NM',
        RegAddrZip: 87402,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '6441 HAWK EYE ST',
        RegAddrCity: 'FARMINGTON',
        RegAddrState: 'NM',
        RegAddrZip: 87402,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: 'BEHIND NENAHNEZAD CHPT',
        RegAddrCity: 'FRUITLAND',
        RegAddrState: 'NM',
        RegAddrZip: 87416,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '791D UPROTTED TREE RD',
        RegAddrCity: 'GALLUP',
        RegAddrState: 'NM',
        RegAddrZip: 87301,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1312 KIT CARSON DR',
        RegAddrCity: 'GALLUP',
        RegAddrState: 'NM',
        RegAddrZip: 87301,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '607 E WILSON AVE',
        RegAddrCity: 'GALLUP',
        RegAddrState: 'NM',
        RegAddrZip: 87301,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '51 BLUEBIRD MESA',
        RegAddrCity: 'JEMEZ PUEBLO',
        RegAddrState: 'NM',
        RegAddrZip: 87024,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '53 S ARROYO LN',
        RegAddrCity: 'JEMEZ PUEBLO',
        RegAddrState: 'NM',
        RegAddrZip: 87024,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '21425 HIGHWAY 4',
        RegAddrCity: 'JEMEZ SPRINGS',
        RegAddrState: 'NM',
        RegAddrZip: 87025,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '11 ROAD 6319',
        RegAddrCity: 'KIRTLAND',
        RegAddrState: 'NM',
        RegAddrZip: 87417,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '6 ROAD 6257',
        RegAddrCity: 'KIRTLAND',
        RegAddrState: 'NM',
        RegAddrZip: 87417,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '16 CAMINO SALADO',
        RegAddrCity: 'LA JARA',
        RegAddrState: 'NM',
        RegAddrZip: 87027,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '103 STATE ROAD 519',
        RegAddrCity: 'LA MADERA',
        RegAddrState: 'NM',
        RegAddrZip: 87539,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1204 6TH ST',
        RegAddrCity: 'LAS VEGAS',
        RegAddrState: 'NM',
        RegAddrZip: 87701,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '9 BOSQUE DR',
        RegAddrCity: 'LAS VEGAS',
        RegAddrState: 'NM',
        RegAddrZip: 87701,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '113 INDEPENDENCE AVE',
        RegAddrCity: 'LAS VEGAS',
        RegAddrState: 'NM',
        RegAddrZip: 87701,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '168 DOS BRAZOS ST',
        RegAddrCity: 'LOS ALAMOS',
        RegAddrState: 'NM',
        RegAddrZip: 87544,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '712 KRIS CT',
        RegAddrCity: 'LOS ALAMOS',
        RegAddrState: 'NM',
        RegAddrZip: 87547,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '350 W HISTORIC HIGHWAY 66',
        RegAddrCity: 'MANUELITO',
        RegAddrState: 'NM',
        RegAddrZip: 87301,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: 'NHA HOUSING #23 MARIANO LK',
        RegAddrCity: 'MARIANO LAKE',
        RegAddrState: 'NM',
        RegAddrZip: 87365,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '340 COUNTY ROAD 140',
        RegAddrCity: 'MEDANALES',
        RegAddrState: 'NM',
        RegAddrZip: 87548,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '7 MI W OF TSAYATOH CHPT',
        RegAddrCity: 'MENTMORE',
        RegAddrState: 'NM',
        RegAddrZip: 87319,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1253 NM HIGHWAY 65',
        RegAddrCity: 'MONTEZUMA',
        RegAddrState: 'NM',
        RegAddrZip: 87731,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1614 S GLOBE AVE',
        RegAddrCity: 'PORTALES',
        RegAddrState: 'NM',
        RegAddrZip: 88130,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1356 NEW MEXICO 236',
        RegAddrCity: 'PORTALES',
        RegAddrState: 'NM',
        RegAddrZip: 88130,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '188 NEW MEXICO 467',
        RegAddrCity: 'PORTALES',
        RegAddrState: 'NM',
        RegAddrZip: 88130,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '3 1/2 M E PUEBLO PINTADO CHAPTER H',
        RegAddrCity: 'PUEBLO PINTADO',
        RegAddrState: 'NM',
        RegAddrZip: 87013,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '15 EAGLE ROCK RD',
        RegAddrCity: 'QUESTA',
        RegAddrState: 'NM',
        RegAddrZip: 87556,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '82 EL TROS RD',
        RegAddrCity: 'RANCHOS DE TAOS',
        RegAddrState: 'NM',
        RegAddrZip: 87557,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '10 COUNTY ROAD B40B',
        RegAddrCity: 'RIBERA',
        RegAddrState: 'NM',
        RegAddrZip: 87560,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '4971 NICKEL DR NE',
        RegAddrCity: 'RIO RANCHO',
        RegAddrState: 'NM',
        RegAddrZip: 87124,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1973 CORTINA LOOP SE',
        RegAddrCity: 'RIO RANCHO',
        RegAddrState: 'NM',
        RegAddrZip: 87124,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '20 LYNWOOD DR SE',
        RegAddrCity: 'RIO RANCHO',
        RegAddrState: 'NM',
        RegAddrZip: 87124,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '3032 CASCADES TRL SE',
        RegAddrCity: 'RIO RANCHO',
        RegAddrState: 'NM',
        RegAddrZip: 87124,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '66 15TH AVE SW',
        RegAddrCity: 'RIO RANCHO',
        RegAddrState: 'NM',
        RegAddrZip: 87124,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '905 PECOS LOOP SE',
        RegAddrCity: 'RIO RANCHO',
        RegAddrState: 'NM',
        RegAddrZip: 87124,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '2125 DEER TRAIL LOOP NE',
        RegAddrCity: 'RIO RANCHO',
        RegAddrState: 'NM',
        RegAddrZip: 87124,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '613 SEDONA MEADOWS DR NE',
        RegAddrCity: 'RIO RANCHO',
        RegAddrState: 'NM',
        RegAddrZip: 87144,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '5633 PIKES PEAK LOOP NE',
        RegAddrCity: 'RIO RANCHO',
        RegAddrState: 'NM',
        RegAddrZip: 87144,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1529 BLUE SPRUCE DR NE',
        RegAddrCity: 'RIO RANCHO',
        RegAddrState: 'NM',
        RegAddrZip: 87144,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1340 TESUQUE CT NE',
        RegAddrCity: 'RIO RANCHO',
        RegAddrState: 'NM',
        RegAddrZip: 87144,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '317 PEACEFUL MEADOWS DR NE',
        RegAddrCity: 'RIO RANCHO',
        RegAddrState: 'NM',
        RegAddrZip: 87144,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '3459 FLAT IRON RD NE',
        RegAddrCity: 'RIO RANCHO',
        RegAddrState: 'NM',
        RegAddrZip: 87144,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '27 CALLE MIGUEL',
        RegAddrCity: 'RNCH DE TAOS',
        RegAddrState: 'NM',
        RegAddrZip: 87557,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '131 NM 63',
        RegAddrCity: 'ROWE',
        RegAddrState: 'NM',
        RegAddrZip: 87562,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1002 N 10TH ST',
        RegAddrCity: 'SAN JON',
        RegAddrState: 'NM',
        RegAddrZip: 88434,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1 1/2 MI SOUTH OF SANOSTEE CHPT RT',
        RegAddrCity: 'SANOSTEE',
        RegAddrState: 'NM',
        RegAddrZip: 87461,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '315 CALLE LOMA NORTE',
        RegAddrCity: 'SANTA FE',
        RegAddrState: 'NM',
        RegAddrZip: 87501,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '404 ALEJANDRO ST',
        RegAddrCity: 'SANTA FE',
        RegAddrState: 'NM',
        RegAddrZip: 87501,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1078 CALLE CARMILITA',
        RegAddrCity: 'SANTA FE',
        RegAddrState: 'NM',
        RegAddrZip: 87505,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '2192 CANDELERO ST',
        RegAddrCity: 'SANTA FE',
        RegAddrState: 'NM',
        RegAddrZip: 87505,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '2708 VIA VENADO',
        RegAddrCity: 'SANTA FE',
        RegAddrState: 'NM',
        RegAddrZip: 87505,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '424 ABEYTA ST',
        RegAddrCity: 'SANTA FE',
        RegAddrState: 'NM',
        RegAddrZip: 87505,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '110 CAMINO TERESA',
        RegAddrCity: 'SANTA FE',
        RegAddrState: 'NM',
        RegAddrZip: 87505,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '5 CERRO DE PALOMAS',
        RegAddrCity: 'SANTA FE',
        RegAddrState: 'NM',
        RegAddrZip: 87506,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '7 PLAZA MOLLENO',
        RegAddrCity: 'SANTA FE',
        RegAddrState: 'NM',
        RegAddrZip: 87506,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '14A SUMMER RD',
        RegAddrCity: 'SANTA FE',
        RegAddrState: 'NM',
        RegAddrZip: 87506,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '34 B COUNTY RD',
        RegAddrCity: 'SANTA FE',
        RegAddrState: 'NM',
        RegAddrZip: 87506,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '10 CAMINO TORCIDO LOOP',
        RegAddrCity: 'SANTA FE',
        RegAddrState: 'NM',
        RegAddrZip: 87507,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '3324 JAMES AVE',
        RegAddrCity: 'SANTA FE',
        RegAddrState: 'NM',
        RegAddrZip: 87507,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '392 PASEO REAL',
        RegAddrCity: 'SANTA FE',
        RegAddrState: 'NM',
        RegAddrZip: 87507,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '2813 PLAZA VERDE',
        RegAddrCity: 'SANTA FE',
        RegAddrState: 'NM',
        RegAddrZip: 87507,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '4759 MORNING ST',
        RegAddrCity: 'SANTA FE',
        RegAddrState: 'NM',
        RegAddrZip: 87507,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '7 AUTUMN LIGHT PL',
        RegAddrCity: 'SANTA FE',
        RegAddrState: 'NM',
        RegAddrZip: 87508,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '2 1/2 MILES NE OF SHIPROCK',
        RegAddrCity: 'SHIPROCK',
        RegAddrState: 'NM',
        RegAddrZip: 87420,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '710 CASAMERO LAKE LOOP',
        RegAddrCity: 'SMITH LAKE',
        RegAddrState: 'NM',
        RegAddrZip: 87365,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: 'POJOAQUE ST',
        RegAddrCity: 'ST DMNG PBLO',
        RegAddrState: 'NM',
        RegAddrZip: 87052,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '823 UPPER RANCHITOS RD',
        RegAddrCity: 'TAOS',
        RegAddrState: 'NM',
        RegAddrZip: 87571,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '220 ALBRIGHT ST',
        RegAddrCity: 'TAOS',
        RegAddrState: 'NM',
        RegAddrZip: 87571,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '508 TURNER',
        RegAddrCity: 'TEXICO',
        RegAddrState: 'NM',
        RegAddrZip: 88135,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1 MI S OF NASCHITTI TP',
        RegAddrCity: 'TOHATCHI',
        RegAddrState: 'NM',
        RegAddrZip: 87325,
        CongressionalDistrict: 3,
      },
    ],
  },
  {
    name: 'NV-1',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/NV-1?ref=gh-pages',
    state: 'NV',
    state_and_number: 'NV-1',
    zip: 89011,
    congressionalDistrict: 1,
    addressesInDistrict: [
      {
        RegAddrLine1: '6757 GRANDOLA DR',
        RegAddrCity: 'LAS VEGAS',
        RegAddrState: 'NV',
        RegAddrZip: 89103,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '609 TWIN LAKES DR',
        RegAddrCity: 'LAS VEGAS',
        RegAddrState: 'NV',
        RegAddrZip: 89107,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '3612 WOODGLEN CT',
        RegAddrCity: 'LAS VEGAS',
        RegAddrState: 'NV',
        RegAddrZip: 89108,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2830 QUEENS COURTYARD DR',
        RegAddrCity: 'LAS VEGAS',
        RegAddrState: 'NV',
        RegAddrZip: 89109,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1119 DEVON LAKE ST',
        RegAddrCity: 'LAS VEGAS',
        RegAddrState: 'NV',
        RegAddrZip: 89110,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '4529 SODA ASH AVE',
        RegAddrCity: 'LAS VEGAS',
        RegAddrState: 'NV',
        RegAddrZip: 89110,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '3900 NIXSON DR',
        RegAddrCity: 'LAS VEGAS',
        RegAddrState: 'NV',
        RegAddrZip: 89110,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2 CARTHAY CIR',
        RegAddrCity: 'LAS VEGAS',
        RegAddrState: 'NV',
        RegAddrZip: 89110,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '6608 SOLITARY AVE',
        RegAddrCity: 'LAS VEGAS',
        RegAddrState: 'NV',
        RegAddrZip: 89110,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '5871 MESCAL WAY',
        RegAddrCity: 'LAS VEGAS',
        RegAddrState: 'NV',
        RegAddrZip: 89110,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1293 STOKES ST',
        RegAddrCity: 'LAS VEGAS',
        RegAddrState: 'NV',
        RegAddrZip: 89110,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '6212 ELMIRA DR',
        RegAddrCity: 'LAS VEGAS',
        RegAddrState: 'NV',
        RegAddrZip: 89118,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '5176 SPENCER ST',
        RegAddrCity: 'LAS VEGAS',
        RegAddrState: 'NV',
        RegAddrZip: 89119,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2411 PARADISE VILLAGE WAY',
        RegAddrCity: 'LAS VEGAS',
        RegAddrState: 'NV',
        RegAddrZip: 89120,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '4794 TERRA LINDA AVE',
        RegAddrCity: 'LAS VEGAS',
        RegAddrState: 'NV',
        RegAddrZip: 89120,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '4465 EL CARNAL WAY',
        RegAddrCity: 'LAS VEGAS',
        RegAddrState: 'NV',
        RegAddrZip: 89121,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '4140 ROYALHILL AVE',
        RegAddrCity: 'LAS VEGAS',
        RegAddrState: 'NV',
        RegAddrZip: 89121,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '4778 FUENTES WAY',
        RegAddrCity: 'LAS VEGAS',
        RegAddrState: 'NV',
        RegAddrZip: 89121,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '4342 SEGOVIA TER',
        RegAddrCity: 'LAS VEGAS',
        RegAddrState: 'NV',
        RegAddrZip: 89121,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2846 MCLEOD DR',
        RegAddrCity: 'LAS VEGAS',
        RegAddrState: 'NV',
        RegAddrZip: 89121,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1001 WASHINGTON OAKS ST',
        RegAddrCity: 'LAS VEGAS',
        RegAddrState: 'NV',
        RegAddrZip: 89128,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '5741 RUBY CREEK DR',
        RegAddrCity: 'LAS VEGAS',
        RegAddrState: 'NV',
        RegAddrZip: 89142,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1825 LINDELL RD',
        RegAddrCity: 'LAS VEGAS',
        RegAddrState: 'NV',
        RegAddrZip: 89146,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '3248 MOONFLOWER DR',
        RegAddrCity: 'LAS VEGAS',
        RegAddrState: 'NV',
        RegAddrZip: 89146,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '7542 MYCROFT CT',
        RegAddrCity: 'LAS VEGAS',
        RegAddrState: 'NV',
        RegAddrZip: 89147,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '6436 KELL LN',
        RegAddrCity: 'LAS VEGAS',
        RegAddrState: 'NV',
        RegAddrZip: 89156,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1968 PALMA VISTA AVE',
        RegAddrCity: 'LAS VEGAS',
        RegAddrState: 'NV',
        RegAddrZip: 89169,
        CongressionalDistrict: 1,
      },
    ],
  },
  {
    name: 'NV-2',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/NV-2?ref=gh-pages',
    state: 'NV',
    state_and_number: 'NV-2',
    zip: 89310,
    congressionalDistrict: 2,
    addressesInDistrict: [
      {
        RegAddrLine1: '1220 JANAS WAY',
        RegAddrCity: 'CARSON CITY',
        RegAddrState: 'NV',
        RegAddrZip: 89701,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '3691 LAKEVIEW RD',
        RegAddrCity: 'CARSON CITY',
        RegAddrState: 'NV',
        RegAddrZip: 89703,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1334 MOUNTAIN PARK DR',
        RegAddrCity: 'CARSON CITY',
        RegAddrState: 'NV',
        RegAddrZip: 89706,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '6 TESLA CIR',
        RegAddrCity: 'CARSON CITY',
        RegAddrState: 'NV',
        RegAddrZip: 89706,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '205 CARNOUSTIE CT',
        RegAddrCity: 'DAYTON',
        RegAddrState: 'NV',
        RegAddrZip: 89403,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '3355 BELL AVE',
        RegAddrCity: 'ELKO',
        RegAddrState: 'NV',
        RegAddrZip: 89801,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2247 HONDO LN',
        RegAddrCity: 'ELKO',
        RegAddrState: 'NV',
        RegAddrZip: 89801,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '325 W FRONT ST',
        RegAddrCity: 'FALLON',
        RegAddrState: 'NV',
        RegAddrZip: 89406,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '5170 RIVERS EDGE DR',
        RegAddrCity: 'FALLON',
        RegAddrState: 'NV',
        RegAddrZip: 89406,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '6074 WORKMAN RD',
        RegAddrCity: 'FALLON',
        RegAddrState: 'NV',
        RegAddrZip: 89406,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '610 WEDGE LN',
        RegAddrCity: 'FERNLEY',
        RegAddrState: 'NV',
        RegAddrZip: 89408,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1125 AMARILLO DR',
        RegAddrCity: 'GARDNERVILLE',
        RegAddrState: 'NV',
        RegAddrZip: 89460,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1316 MARY JO DR',
        RegAddrCity: 'GARDNERVILLE',
        RegAddrState: 'NV',
        RegAddrZip: 89460,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1006 SILVERANCH DR',
        RegAddrCity: 'GARDNERVILLE',
        RegAddrState: 'NV',
        RegAddrZip: 89460,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '300 DOGWOOD ST',
        RegAddrCity: 'GERLACH',
        RegAddrState: 'NV',
        RegAddrZip: 89412,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '815 ORIOLE WAY',
        RegAddrCity: 'INCLINE VLG',
        RegAddrState: 'NV',
        RegAddrZip: 89451,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '485 13TH ST',
        RegAddrCity: 'LOVELOCK',
        RegAddrState: 'NV',
        RegAddrZip: 89419,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '4230 BISMARCK DR',
        RegAddrCity: 'RENO',
        RegAddrState: 'NV',
        RegAddrZip: 89502,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '888 WILLOW ST',
        RegAddrCity: 'RENO',
        RegAddrState: 'NV',
        RegAddrZip: 89502,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '5585 S SCARSDALE CIR',
        RegAddrCity: 'RENO',
        RegAddrState: 'NV',
        RegAddrZip: 89502,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1470 SEVERN DR',
        RegAddrCity: 'RENO',
        RegAddrState: 'NV',
        RegAddrZip: 89503,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '38 TUCK CIR',
        RegAddrCity: 'RENO',
        RegAddrState: 'NV',
        RegAddrZip: 89506,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '9175 ANDRASTE WAY',
        RegAddrCity: 'RENO',
        RegAddrState: 'NV',
        RegAddrZip: 89506,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '5005 ECHO AVE',
        RegAddrCity: 'RENO',
        RegAddrState: 'NV',
        RegAddrZip: 89506,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '9644 CANYON MEADOWS DR',
        RegAddrCity: 'RENO',
        RegAddrState: 'NV',
        RegAddrZip: 89506,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '560 BECKWOURTH DR',
        RegAddrCity: 'RENO',
        RegAddrState: 'NV',
        RegAddrZip: 89506,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '930 PINEBROOK RD',
        RegAddrCity: 'RENO',
        RegAddrState: 'NV',
        RegAddrZip: 89509,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '14295 DOMINGO CT',
        RegAddrCity: 'RENO',
        RegAddrState: 'NV',
        RegAddrZip: 89511,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '9865 GAINSBOROUGH LN',
        RegAddrCity: 'RENO',
        RegAddrState: 'NV',
        RegAddrZip: 89521,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1400 ORCHARD PARK TRL',
        RegAddrCity: 'RENO',
        RegAddrState: 'NV',
        RegAddrZip: 89523,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2785 E 6TH ST',
        RegAddrCity: 'SILVER SPGS',
        RegAddrState: 'NV',
        RegAddrZip: 89429,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1007 TYLER WAY',
        RegAddrCity: 'SPARKS',
        RegAddrState: 'NV',
        RegAddrZip: 89431,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '4401 GARRATT CIR',
        RegAddrCity: 'SPARKS',
        RegAddrState: 'NV',
        RegAddrZip: 89436,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1418 ROSY FINCH DR',
        RegAddrCity: 'SPARKS',
        RegAddrState: 'NV',
        RegAddrZip: 89441,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '5340 SIDEHILL DR',
        RegAddrCity: 'SUN VALLEY',
        RegAddrState: 'NV',
        RegAddrZip: 89433,
        CongressionalDistrict: 2,
      },
    ],
  },
  {
    name: 'NV-3',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/NV-3?ref=gh-pages',
    state: 'NV',
    state_and_number: 'NV-3',
    zip: 89002,
    congressionalDistrict: 3,
    addressesInDistrict: [
      {
        RegAddrLine1: '625 AVENUE L',
        RegAddrCity: 'BOULDER CITY',
        RegAddrState: 'NV',
        RegAddrZip: 89005,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1641 LEFTY GARCIA WAY',
        RegAddrCity: 'HENDERSON',
        RegAddrState: 'NV',
        RegAddrZip: 89002,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1540 SUNDOWN DR',
        RegAddrCity: 'HENDERSON',
        RegAddrState: 'NV',
        RegAddrZip: 89002,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1671 DERBY DR',
        RegAddrCity: 'HENDERSON',
        RegAddrState: 'NV',
        RegAddrZip: 89002,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '630 BACKBONE MOUNTAIN DR',
        RegAddrCity: 'HENDERSON',
        RegAddrState: 'NV',
        RegAddrZip: 89012,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '855 SUMNER RANCH RD',
        RegAddrCity: 'HENDERSON',
        RegAddrState: 'NV',
        RegAddrZip: 89012,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '3153 LA MANCHA WAY',
        RegAddrCity: 'HENDERSON',
        RegAddrState: 'NV',
        RegAddrZip: 89014,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '424 LOST TRAIL DR',
        RegAddrCity: 'HENDERSON',
        RegAddrState: 'NV',
        RegAddrZip: 89014,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '576 ESTANCIA CT',
        RegAddrCity: 'HENDERSON',
        RegAddrState: 'NV',
        RegAddrZip: 89015,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '2172 CUMBERLAND HILL DR',
        RegAddrCity: 'HENDERSON',
        RegAddrState: 'NV',
        RegAddrZip: 89052,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1550 VIA DELLA SCALA',
        RegAddrCity: 'HENDERSON',
        RegAddrState: 'NV',
        RegAddrZip: 89052,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '11114 ARCADIA SUNRISE DR',
        RegAddrCity: 'HENDERSON',
        RegAddrState: 'NV',
        RegAddrZip: 89052,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1149 SCENIC CREST DR',
        RegAddrCity: 'HENDERSON',
        RegAddrState: 'NV',
        RegAddrZip: 89052,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1011 WELKIN ST',
        RegAddrCity: 'HENDERSON',
        RegAddrState: 'NV',
        RegAddrZip: 89052,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '2847 FOREST GROVE DR',
        RegAddrCity: 'HENDERSON',
        RegAddrState: 'NV',
        RegAddrZip: 89052,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '165 COVENTRY DR',
        RegAddrCity: 'HENDERSON',
        RegAddrState: 'NV',
        RegAddrZip: 89074,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '2029 PINION SPRINGS DR',
        RegAddrCity: 'HENDERSON',
        RegAddrState: 'NV',
        RegAddrZip: 89074,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '2970 SEASONS AVE',
        RegAddrCity: 'HENDERSON',
        RegAddrState: 'NV',
        RegAddrZip: 89074,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '9909 DUSTY WINDS AVE',
        RegAddrCity: 'LAS VEGAS',
        RegAddrState: 'NV',
        RegAddrZip: 89117,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '2013 MARBLE GORGE DR',
        RegAddrCity: 'LAS VEGAS',
        RegAddrState: 'NV',
        RegAddrZip: 89117,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '9099 LAVA BLUFF CT',
        RegAddrCity: 'LAS VEGAS',
        RegAddrState: 'NV',
        RegAddrZip: 89123,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '9058 MANALANG RD',
        RegAddrCity: 'LAS VEGAS',
        RegAddrState: 'NV',
        RegAddrZip: 89123,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '9615 SILVER CITY DR',
        RegAddrCity: 'LAS VEGAS',
        RegAddrState: 'NV',
        RegAddrZip: 89123,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '6018 DARKFEATHER WAY',
        RegAddrCity: 'LAS VEGAS',
        RegAddrState: 'NV',
        RegAddrZip: 89139,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '10985 AMPUS PL',
        RegAddrCity: 'LAS VEGAS',
        RegAddrState: 'NV',
        RegAddrZip: 89141,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '11024 VALLEROSA ST',
        RegAddrCity: 'LAS VEGAS',
        RegAddrState: 'NV',
        RegAddrZip: 89141,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '11167 TUSCOLANA ST',
        RegAddrCity: 'LAS VEGAS',
        RegAddrState: 'NV',
        RegAddrZip: 89141,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '804 ROYAL ELM LN',
        RegAddrCity: 'LAS VEGAS',
        RegAddrState: 'NV',
        RegAddrZip: 89144,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '10701 ELK LAKE DR',
        RegAddrCity: 'LAS VEGAS',
        RegAddrState: 'NV',
        RegAddrZip: 89144,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1713 PACIFIC CASTLE PL',
        RegAddrCity: 'LAS VEGAS',
        RegAddrState: 'NV',
        RegAddrZip: 89144,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '9213 WORSLEY PARK PL',
        RegAddrCity: 'LAS VEGAS',
        RegAddrState: 'NV',
        RegAddrZip: 89145,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '3576 DUNE GRASS ST',
        RegAddrCity: 'LAS VEGAS',
        RegAddrState: 'NV',
        RegAddrZip: 89147,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '7416 CORCORAN ST',
        RegAddrCity: 'LAS VEGAS',
        RegAddrState: 'NV',
        RegAddrZip: 89148,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '6215 VAN EPS CT',
        RegAddrCity: 'LAS VEGAS',
        RegAddrState: 'NV',
        RegAddrZip: 89148,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '5566 JINAN ST',
        RegAddrCity: 'LAS VEGAS',
        RegAddrState: 'NV',
        RegAddrZip: 89148,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '10678 RABBIT RIDGE CT',
        RegAddrCity: 'LAS VEGAS',
        RegAddrState: 'NV',
        RegAddrZip: 89183,
        CongressionalDistrict: 3,
      },
    ],
  },
  {
    name: 'NV-4',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/NV-4?ref=gh-pages',
    state: 'NV',
    state_and_number: 'NV-4',
    zip: 84034,
    congressionalDistrict: 4,
    addressesInDistrict: [
      {
        RegAddrLine1: '1952 LEONA ST',
        RegAddrCity: 'LAS VEGAS',
        RegAddrState: 'NV',
        RegAddrZip: 89106,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '5817 MARY WAY',
        RegAddrCity: 'LAS VEGAS',
        RegAddrState: 'NV',
        RegAddrZip: 89108,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '3525 N BRONCO ST',
        RegAddrCity: 'LAS VEGAS',
        RegAddrState: 'NV',
        RegAddrZip: 89108,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '3818 CLEAR LAKE CT',
        RegAddrCity: 'LAS VEGAS',
        RegAddrState: 'NV',
        RegAddrZip: 89115,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '4068 PISTACHIO NUT AVE',
        RegAddrCity: 'LAS VEGAS',
        RegAddrState: 'NV',
        RegAddrZip: 89115,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2469 SAN MARCOS ST',
        RegAddrCity: 'LAS VEGAS',
        RegAddrState: 'NV',
        RegAddrZip: 89115,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '4174 SPRUCE FERN LN',
        RegAddrCity: 'LAS VEGAS',
        RegAddrState: 'NV',
        RegAddrZip: 89115,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '3525 MOUNTAIN PARK ST',
        RegAddrCity: 'LAS VEGAS',
        RegAddrState: 'NV',
        RegAddrZip: 89129,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '7453 ALICE SPRINGS CIR',
        RegAddrCity: 'LAS VEGAS',
        RegAddrState: 'NV',
        RegAddrZip: 89129,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '4235 N VALADEZ ST',
        RegAddrCity: 'LAS VEGAS',
        RegAddrState: 'NV',
        RegAddrZip: 89129,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '6330 BULLRING LN',
        RegAddrCity: 'LAS VEGAS',
        RegAddrState: 'NV',
        RegAddrZip: 89130,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '6488 ARROW COTTAGE CT',
        RegAddrCity: 'LAS VEGAS',
        RegAddrState: 'NV',
        RegAddrZip: 89130,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '4505 BRADPOINT DR',
        RegAddrCity: 'LAS VEGAS',
        RegAddrState: 'NV',
        RegAddrZip: 89130,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '6232 W LA MADRE WAY',
        RegAddrCity: 'LAS VEGAS',
        RegAddrState: 'NV',
        RegAddrZip: 89130,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '5933 BAYLOR RANCH CT',
        RegAddrCity: 'LAS VEGAS',
        RegAddrState: 'NV',
        RegAddrZip: 89131,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '8728 APIARY WIND ST',
        RegAddrCity: 'LAS VEGAS',
        RegAddrState: 'NV',
        RegAddrZip: 89131,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '8944 BROOK BAY CT',
        RegAddrCity: 'LAS VEGAS',
        RegAddrState: 'NV',
        RegAddrZip: 89134,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '10728 PARADISE POINT DR',
        RegAddrCity: 'LAS VEGAS',
        RegAddrState: 'NV',
        RegAddrZip: 89134,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '6874 CEDAR BASIN AVE',
        RegAddrCity: 'LAS VEGAS',
        RegAddrState: 'NV',
        RegAddrZip: 89142,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '8440 TWINKLING TOPAZ AVE',
        RegAddrCity: 'LAS VEGAS',
        RegAddrState: 'NV',
        RegAddrZip: 89143,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '7808 DESERTBUCK CT',
        RegAddrCity: 'LAS VEGAS',
        RegAddrState: 'NV',
        RegAddrZip: 89149,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '6075 CASTLEMONT AVE',
        RegAddrCity: 'LAS VEGAS',
        RegAddrState: 'NV',
        RegAddrZip: 89156,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2305 LOS FELIZ ST',
        RegAddrCity: 'LAS VEGAS',
        RegAddrState: 'NV',
        RegAddrZip: 89156,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '9538 DIAMOND BRIDGE AVE',
        RegAddrCity: 'LAS VEGAS',
        RegAddrState: 'NV',
        RegAddrZip: 89166,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '10652 MOUNT JEFFERSON AVE',
        RegAddrCity: 'LAS VEGAS',
        RegAddrState: 'NV',
        RegAddrZip: 89166,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1324 REV WILSON AVE',
        RegAddrCity: 'N LAS VEGAS',
        RegAddrState: 'NV',
        RegAddrZip: 89030,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '309 VIOLETTA AVE',
        RegAddrCity: 'N LAS VEGAS',
        RegAddrState: 'NV',
        RegAddrZip: 89031,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1812 MONTE ALBAN DR',
        RegAddrCity: 'N LAS VEGAS',
        RegAddrState: 'NV',
        RegAddrZip: 89031,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '4368 ACROPOLIS AVE',
        RegAddrCity: 'N LAS VEGAS',
        RegAddrState: 'NV',
        RegAddrZip: 89031,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '4148 THOMAS PATRICK AVE',
        RegAddrCity: 'N LAS VEGAS',
        RegAddrState: 'NV',
        RegAddrZip: 89032,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '5862 HOLLINGSHED CT',
        RegAddrCity: 'N LAS VEGAS',
        RegAddrState: 'NV',
        RegAddrZip: 89081,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '7082 SEABIRDS PL',
        RegAddrCity: 'N LAS VEGAS',
        RegAddrState: 'NV',
        RegAddrZip: 89084,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '4017 CACKLING GOOSE DR',
        RegAddrCity: 'N LAS VEGAS',
        RegAddrState: 'NV',
        RegAddrZip: 89084,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '3905 ROBIN KNOT CT',
        RegAddrCity: 'N LAS VEGAS',
        RegAddrState: 'NV',
        RegAddrZip: 89084,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '355 RIVER GLIDER AVE',
        RegAddrCity: 'N LAS VEGAS',
        RegAddrState: 'NV',
        RegAddrZip: 89084,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '4504 DUSTY TRAIL ST',
        RegAddrCity: 'NORTH LAS VEGAS',
        RegAddrState: 'NV',
        RegAddrZip: 89031,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '3642 ROSE CANYON DR',
        RegAddrCity: 'NORTH LAS VEGAS',
        RegAddrState: 'NV',
        RegAddrZip: 89032,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '4111 JACOB ST',
        RegAddrCity: 'PAHRUMP',
        RegAddrState: 'NV',
        RegAddrZip: 89048,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1801 CRAWFORD WAY',
        RegAddrCity: 'PAHRUMP',
        RegAddrState: 'NV',
        RegAddrZip: 89048,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '3900 DART DR',
        RegAddrCity: 'PAHRUMP',
        RegAddrState: 'NV',
        RegAddrZip: 89060,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '4770 LORENZO WAY',
        RegAddrCity: 'PAHRUMP',
        RegAddrState: 'NV',
        RegAddrZip: 89061,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2 BOYD CT',
        RegAddrCity: 'ROUND MTN',
        RegAddrState: 'NV',
        RegAddrZip: 89045,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '528 S MAIN ST',
        RegAddrCity: 'YERINGTON',
        RegAddrState: 'NV',
        RegAddrZip: 89447,
        CongressionalDistrict: 4,
      },
    ],
  },
  {
    name: 'NY-1',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/NY-1?ref=gh-pages',
    state: 'NY',
    state_and_number: 'NY-1',
    zip: 6390,
    congressionalDistrict: 1,
    addressesInDistrict: [
      {
        RegAddrLine1: '18 KIM CT',
        RegAddrCity: 'CENTEREACH',
        RegAddrState: 'NY',
        RegAddrZip: 11720,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '26 SONGSPARROW LN',
        RegAddrCity: 'CENTEREACH',
        RegAddrState: 'NY',
        RegAddrZip: 11720,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '7 LAKE PLACID CT',
        RegAddrCity: 'CORAM',
        RegAddrState: 'NY',
        RegAddrZip: 11727,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '852 TAYLOR AVE',
        RegAddrCity: 'E PATCHOGUE',
        RegAddrState: 'NY',
        RegAddrZip: 11772,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2 HEWLETT AVE',
        RegAddrCity: 'E PATCHOGUE',
        RegAddrState: 'NY',
        RegAddrZip: 11772,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '13 STALLER DR',
        RegAddrCity: 'E QUOGUE',
        RegAddrState: 'NY',
        RegAddrZip: 11942,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '400 BAY RD',
        RegAddrCity: 'GREENPORT',
        RegAddrState: 'NY',
        RegAddrZip: 11944,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '60 W TIANA RD',
        RegAddrCity: 'HAMPTON BAYS',
        RegAddrState: 'NY',
        RegAddrZip: 11946,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '61 FALCON DR',
        RegAddrCity: 'HAUPPAUGE',
        RegAddrState: 'NY',
        RegAddrZip: 11788,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '19 LIBRARY LN',
        RegAddrCity: 'HOLBROOK',
        RegAddrState: 'NY',
        RegAddrZip: 11741,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '130 STORM DR',
        RegAddrCity: 'HOLTSVILLE',
        RegAddrState: 'NY',
        RegAddrZip: 11742,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '227 MAPLECREST DR',
        RegAddrCity: 'LK RONKONKOMA',
        RegAddrState: 'NY',
        RegAddrZip: 11779,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '183 WINTERS DR',
        RegAddrCity: 'MASTIC',
        RegAddrState: 'NY',
        RegAddrZip: 11950,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '9 DEERING CT',
        RegAddrCity: 'MEDFORD',
        RegAddrState: 'NY',
        RegAddrZip: 11763,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '14 ASH DR',
        RegAddrCity: 'MEDFORD',
        RegAddrState: 'NY',
        RegAddrZip: 11763,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '640 BIRCHWOOD PARK DR',
        RegAddrCity: 'MIDDLE ISLAND',
        RegAddrState: 'NY',
        RegAddrZip: 11953,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '849 SPRING LAKE DR',
        RegAddrCity: 'MIDDLE ISLAND',
        RegAddrState: 'NY',
        RegAddrZip: 11953,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '5 BARNSLEY CRES',
        RegAddrCity: 'MOUNT SINAI',
        RegAddrState: 'NY',
        RegAddrZip: 11766,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '86 LILLIAN RD',
        RegAddrCity: 'NESCONSET',
        RegAddrState: 'NY',
        RegAddrZip: 11767,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '5 MARGARET ST',
        RegAddrCity: 'PORT JEFF STA',
        RegAddrState: 'NY',
        RegAddrZip: 11776,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '23 CONDOR RD',
        RegAddrCity: 'ROCKY POINT',
        RegAddrState: 'NY',
        RegAddrZip: 11778,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '19 ACTORS COLONY RD',
        RegAddrCity: 'SAG HARBOR',
        RegAddrState: 'NY',
        RegAddrZip: 11963,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '145 W END AVE',
        RegAddrCity: 'SHIRLEY',
        RegAddrState: 'NY',
        RegAddrZip: 11967,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '279 GRANT AVE',
        RegAddrCity: 'ST JAMES',
        RegAddrState: 'NY',
        RegAddrZip: 11780,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '3 SYCAMORE DR',
        RegAddrCity: 'STONY BROOK',
        RegAddrState: 'NY',
        RegAddrZip: 11790,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '114 BEACH RD',
        RegAddrCity: 'W HAMPTON BCH',
        RegAddrState: 'NY',
        RegAddrZip: 11978,
        CongressionalDistrict: 1,
      },
    ],
  },
  {
    name: 'NY-10',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/NY-10?ref=gh-pages',
    state: 'NY',
    state_and_number: 'NY-10',
    zip: 10001,
    congressionalDistrict: 10,
    addressesInDistrict: [
      {
        RegAddrLine1: '1757 74TH ST',
        RegAddrCity: 'BROOKLYN',
        RegAddrState: 'NY',
        RegAddrZip: 11204,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '1126 53RD ST',
        RegAddrCity: 'BROOKLYN',
        RegAddrState: 'NY',
        RegAddrZip: 11219,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '1646 W 1ST ST',
        RegAddrCity: 'BROOKLYN',
        RegAddrState: 'NY',
        RegAddrZip: 11223,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '2036 W 10TH ST',
        RegAddrCity: 'BROOKLYN',
        RegAddrState: 'NY',
        RegAddrZip: 11223,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '1149 E 5TH ST',
        RegAddrCity: 'BROOKLYN',
        RegAddrState: 'NY',
        RegAddrZip: 11230,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '1660A E 4TH ST',
        RegAddrCity: 'BROOKLYN',
        RegAddrState: 'NY',
        RegAddrZip: 11230,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '88 CENTRAL PARK W',
        RegAddrCity: 'NEW YORK',
        RegAddrState: 'NY',
        RegAddrZip: 10023,
        CongressionalDistrict: 10,
      },
    ],
  },
  {
    name: 'NY-11',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/NY-11?ref=gh-pages',
    state: 'NY',
    state_and_number: 'NY-11',
    zip: 10301,
    congressionalDistrict: 11,
    addressesInDistrict: [
      {
        RegAddrLine1: '9726 3RD AVE',
        RegAddrCity: 'BROOKLYN',
        RegAddrState: 'NY',
        RegAddrZip: 11209,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '522 76TH ST',
        RegAddrCity: 'BROOKLYN',
        RegAddrState: 'NY',
        RegAddrZip: 11209,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '336 BAY 11TH ST',
        RegAddrCity: 'BROOKLYN',
        RegAddrState: 'NY',
        RegAddrZip: 11228,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '6915 7TH AVE',
        RegAddrCity: 'BROOKLYN',
        RegAddrState: 'NY',
        RegAddrZip: 11228,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '2325 E 14TH ST',
        RegAddrCity: 'BROOKLYN',
        RegAddrState: 'NY',
        RegAddrZip: 11229,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '166 BENZIGER AVE',
        RegAddrCity: 'STATEN ISLAND',
        RegAddrState: 'NY',
        RegAddrZip: 10301,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '29 AMITY PL',
        RegAddrCity: 'STATEN ISLAND',
        RegAddrState: 'NY',
        RegAddrZip: 10303,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '247 N RAILROAD AVE',
        RegAddrCity: 'STATEN ISLAND',
        RegAddrState: 'NY',
        RegAddrZip: 10304,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '87 NORWOOD AVE',
        RegAddrCity: 'STATEN ISLAND',
        RegAddrState: 'NY',
        RegAddrZip: 10304,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '32B SYLVA LN',
        RegAddrCity: 'STATEN ISLAND',
        RegAddrState: 'NY',
        RegAddrZip: 10305,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '50 ARDSLEY ST',
        RegAddrCity: 'STATEN ISLAND',
        RegAddrState: 'NY',
        RegAddrZip: 10306,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '2 BARTOW ST',
        RegAddrCity: 'STATEN ISLAND',
        RegAddrState: 'NY',
        RegAddrZip: 10308,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '175 TANGLEWOOD DR',
        RegAddrCity: 'STATEN ISLAND',
        RegAddrState: 'NY',
        RegAddrZip: 10308,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '36 DUNHILL AVE',
        RegAddrCity: 'STATEN ISLAND',
        RegAddrState: 'NY',
        RegAddrZip: 10309,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '6949 AMBOY RD',
        RegAddrCity: 'STATEN ISLAND',
        RegAddrState: 'NY',
        RegAddrZip: 10309,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '485 BRADFORD AVE',
        RegAddrCity: 'STATEN ISLAND',
        RegAddrState: 'NY',
        RegAddrZip: 10309,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '53B BROADWAY',
        RegAddrCity: 'STATEN ISLAND',
        RegAddrState: 'NY',
        RegAddrZip: 10310,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '209 MYRTLE AVE',
        RegAddrCity: 'STATEN ISLAND',
        RegAddrState: 'NY',
        RegAddrZip: 10310,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '186 HEFFERNAN ST',
        RegAddrCity: 'STATEN ISLAND',
        RegAddrState: 'NY',
        RegAddrZip: 10312,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '58 ADA DR',
        RegAddrCity: 'STATEN ISLAND',
        RegAddrState: 'NY',
        RegAddrZip: 10314,
        CongressionalDistrict: 11,
      },
    ],
  },
  {
    name: 'NY-12',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/NY-12?ref=gh-pages',
    state: 'NY',
    state_and_number: 'NY-12',
    zip: 10010,
    congressionalDistrict: 12,
    addressesInDistrict: [
      {
        RegAddrLine1: '2594 42ND ST',
        RegAddrCity: 'ASTORIA',
        RegAddrState: 'NY',
        RegAddrZip: 11103,
        CongressionalDistrict: 12,
      },
    ],
  },
  {
    name: 'NY-13',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/NY-13?ref=gh-pages',
    state: 'NY',
    state_and_number: 'NY-13',
    zip: 10030,
    congressionalDistrict: 13,
    addressesInDistrict: [
      {
        RegAddrLine1: '5025 BROADWAY 5 C',
        RegAddrCity: 'NEW YORK',
        RegAddrState: 'NY',
        RegAddrZip: 10034,
        CongressionalDistrict: 13,
      },
    ],
  },
  {
    name: 'NY-14',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/NY-14?ref=gh-pages',
    state: 'NY',
    state_and_number: 'NY-14',
    zip: 10460,
    congressionalDistrict: 14,
    addressesInDistrict: [
      {
        RegAddrLine1: '9702 CORONA AVE',
        RegAddrCity: 'CORONA',
        RegAddrState: 'NY',
        RegAddrZip: 11368,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '2550 77TH ST',
        RegAddrCity: 'EAST ELMHURST',
        RegAddrState: 'NY',
        RegAddrZip: 11370,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '4319 56TH ST',
        RegAddrCity: 'WOODSIDE',
        RegAddrState: 'NY',
        RegAddrZip: 11377,
        CongressionalDistrict: 14,
      },
    ],
  },
  {
    name: 'NY-15',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/NY-15?ref=gh-pages',
    state: 'NY',
    state_and_number: 'NY-15',
    zip: 10451,
    congressionalDistrict: 15,
    addressesInDistrict: [
      {
        RegAddrLine1: '4133 PARK AVE',
        RegAddrCity: 'BRONX',
        RegAddrState: 'NY',
        RegAddrZip: 10457,
        CongressionalDistrict: 15,
      },
    ],
  },
  {
    name: 'NY-16',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/NY-16?ref=gh-pages',
    state: 'NY',
    state_and_number: 'NY-16',
    zip: 10470,
    congressionalDistrict: 16,
    addressesInDistrict: [
      {
        RegAddrLine1: '4170 WILDER AVE',
        RegAddrCity: 'BRONX',
        RegAddrState: 'NY',
        RegAddrZip: 10466,
        CongressionalDistrict: 16,
      },
      {
        RegAddrLine1: '843 E 232ND ST',
        RegAddrCity: 'BRONX',
        RegAddrState: 'NY',
        RegAddrZip: 10466,
        CongressionalDistrict: 16,
      },
      {
        RegAddrLine1: '732 E 242ND ST',
        RegAddrCity: 'BRONX',
        RegAddrState: 'NY',
        RegAddrZip: 10470,
        CongressionalDistrict: 16,
      },
      {
        RegAddrLine1: '30 CLUB WAY',
        RegAddrCity: 'HARTSDALE',
        RegAddrState: 'NY',
        RegAddrZip: 10530,
        CongressionalDistrict: 16,
      },
      {
        RegAddrLine1: '3 PRYER LN',
        RegAddrCity: 'LARCHMONT',
        RegAddrState: 'NY',
        RegAddrZip: 10538,
        CongressionalDistrict: 16,
      },
      {
        RegAddrLine1: '935 THE PARKWAY',
        RegAddrCity: 'MAMARONECK',
        RegAddrState: 'NY',
        RegAddrZip: 10543,
        CongressionalDistrict: 16,
      },
      {
        RegAddrLine1: '368 E 5TH ST',
        RegAddrCity: 'MT VERNON',
        RegAddrState: 'NY',
        RegAddrZip: 10553,
        CongressionalDistrict: 16,
      },
      {
        RegAddrLine1: '11 LAFAYETTE ST',
        RegAddrCity: 'NEW ROCHELLE',
        RegAddrState: 'NY',
        RegAddrZip: 10805,
        CongressionalDistrict: 16,
      },
      {
        RegAddrLine1: '250 1ST ST',
        RegAddrCity: 'PELHAM',
        RegAddrState: 'NY',
        RegAddrZip: 10803,
        CongressionalDistrict: 16,
      },
      {
        RegAddrLine1: '21 CLOVERDALE LN',
        RegAddrCity: 'RYE',
        RegAddrState: 'NY',
        RegAddrZip: 10580,
        CongressionalDistrict: 16,
      },
      {
        RegAddrLine1: '21 EDGEMONT CIR',
        RegAddrCity: 'SCARSDALE',
        RegAddrState: 'NY',
        RegAddrZip: 10583,
        CongressionalDistrict: 16,
      },
      {
        RegAddrLine1: '30 POCONO AVE',
        RegAddrCity: 'YONKERS',
        RegAddrState: 'NY',
        RegAddrZip: 10701,
        CongressionalDistrict: 16,
      },
      {
        RegAddrLine1: '41 SAINT JOHNS AVE',
        RegAddrCity: 'YONKERS',
        RegAddrState: 'NY',
        RegAddrZip: 10704,
        CongressionalDistrict: 16,
      },
      {
        RegAddrLine1: '41 SPRINGER AVE',
        RegAddrCity: 'YONKERS',
        RegAddrState: 'NY',
        RegAddrZip: 10704,
        CongressionalDistrict: 16,
      },
      {
        RegAddrLine1: '53 WINFRED AVE',
        RegAddrCity: 'YONKERS',
        RegAddrState: 'NY',
        RegAddrZip: 10704,
        CongressionalDistrict: 16,
      },
      {
        RegAddrLine1: '69 REMSEN CIR',
        RegAddrCity: 'YONKERS',
        RegAddrState: 'NY',
        RegAddrZip: 10710,
        CongressionalDistrict: 16,
      },
    ],
  },
  {
    name: 'NY-17',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/NY-17?ref=gh-pages',
    state: 'NY',
    state_and_number: 'NY-17',
    zip: 10503,
    congressionalDistrict: 17,
    addressesInDistrict: [
      {
        RegAddrLine1: '7 GROVE LN',
        RegAddrCity: 'ARDSLEY',
        RegAddrState: 'NY',
        RegAddrZip: 10502,
        CongressionalDistrict: 17,
      },
      {
        RegAddrLine1: '8 MAPLE MOOR LN',
        RegAddrCity: 'CORTLANDT MNR',
        RegAddrState: 'NY',
        RegAddrZip: 10567,
        CongressionalDistrict: 17,
      },
      {
        RegAddrLine1: '1300 JOURNEYS END RD',
        RegAddrCity: 'CROTON HDSN',
        RegAddrState: 'NY',
        RegAddrZip: 10520,
        CongressionalDistrict: 17,
      },
      {
        RegAddrLine1: '10 ARLINGTON DR',
        RegAddrCity: 'CROTON HDSN',
        RegAddrState: 'NY',
        RegAddrZip: 10520,
        CongressionalDistrict: 17,
      },
      {
        RegAddrLine1: '200 HARRISON AVE',
        RegAddrCity: 'HARRISON',
        RegAddrState: 'NY',
        RegAddrZip: 10528,
        CongressionalDistrict: 17,
      },
      {
        RegAddrLine1: '22 AMHERST PL',
        RegAddrCity: 'HARTSDALE',
        RegAddrState: 'NY',
        RegAddrZip: 10530,
        CongressionalDistrict: 17,
      },
      {
        RegAddrLine1: '3306 FOUNTAINVIEW DR',
        RegAddrCity: 'MONSEY',
        RegAddrState: 'NY',
        RegAddrZip: 10952,
        CongressionalDistrict: 17,
      },
      {
        RegAddrLine1: '5 TREE TOP LN',
        RegAddrCity: 'MONSEY',
        RegAddrState: 'NY',
        RegAddrZip: 10952,
        CongressionalDistrict: 17,
      },
      {
        RegAddrLine1: '2090 ALBANY POST RD',
        RegAddrCity: 'MONTROSE',
        RegAddrState: 'NY',
        RegAddrZip: 10548,
        CongressionalDistrict: 17,
      },
      {
        RegAddrLine1: '20 SIDNEY DR',
        RegAddrCity: 'NANUET',
        RegAddrState: 'NY',
        RegAddrZip: 10954,
        CongressionalDistrict: 17,
      },
      {
        RegAddrLine1: '43 POPLAR ST',
        RegAddrCity: 'NANUET',
        RegAddrState: 'NY',
        RegAddrZip: 10954,
        CongressionalDistrict: 17,
      },
      {
        RegAddrLine1: '16 GERKE AVE',
        RegAddrCity: 'NEW CITY',
        RegAddrState: 'NY',
        RegAddrZip: 10956,
        CongressionalDistrict: 17,
      },
      {
        RegAddrLine1: '7 KENDALL DR',
        RegAddrCity: 'NEW CITY',
        RegAddrState: 'NY',
        RegAddrZip: 10956,
        CongressionalDistrict: 17,
      },
      {
        RegAddrLine1: '216 GLENBROOK RD',
        RegAddrCity: 'NYACK',
        RegAddrState: 'NY',
        RegAddrZip: 10960,
        CongressionalDistrict: 17,
      },
      {
        RegAddrLine1: '250 RIVER RD',
        RegAddrCity: 'NYACK',
        RegAddrState: 'NY',
        RegAddrZip: 10960,
        CongressionalDistrict: 17,
      },
      {
        RegAddrLine1: '32 PARK DR',
        RegAddrCity: 'OSSINING',
        RegAddrState: 'NY',
        RegAddrZip: 10562,
        CongressionalDistrict: 17,
      },
      {
        RegAddrLine1: '3492 STONEY ST',
        RegAddrCity: 'SHRUB OAK',
        RegAddrState: 'NY',
        RegAddrZip: 10588,
        CongressionalDistrict: 17,
      },
      {
        RegAddrLine1: '1 LENOX CT',
        RegAddrCity: 'SUFFERN',
        RegAddrState: 'NY',
        RegAddrZip: 10901,
        CongressionalDistrict: 17,
      },
      {
        RegAddrLine1: '19 ROCKLAND TER',
        RegAddrCity: 'SUFFERN',
        RegAddrState: 'NY',
        RegAddrZip: 10901,
        CongressionalDistrict: 17,
      },
      {
        RegAddrLine1: '6 GISELE CT',
        RegAddrCity: 'SUFFERN',
        RegAddrState: 'NY',
        RegAddrZip: 10901,
        CongressionalDistrict: 17,
      },
      {
        RegAddrLine1: '279 MARTLING AVE',
        RegAddrCity: 'TARRYTOWN',
        RegAddrState: 'NY',
        RegAddrZip: 10591,
        CongressionalDistrict: 17,
      },
      {
        RegAddrLine1: '20 LOCUST DR',
        RegAddrCity: 'THIELLS',
        RegAddrState: 'NY',
        RegAddrZip: 10984,
        CongressionalDistrict: 17,
      },
      {
        RegAddrLine1: '21 COPPER BEECH CIR',
        RegAddrCity: 'WHITE PLAINS',
        RegAddrState: 'NY',
        RegAddrZip: 10605,
        CongressionalDistrict: 17,
      },
      {
        RegAddrLine1: '3191 COURT ST',
        RegAddrCity: 'YORKTOWN HTS',
        RegAddrState: 'NY',
        RegAddrZip: 10598,
        CongressionalDistrict: 17,
      },
    ],
  },
  {
    name: 'NY-18',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/NY-18?ref=gh-pages',
    state: 'NY',
    state_and_number: 'NY-18',
    zip: 10501,
    congressionalDistrict: 18,
    addressesInDistrict: [
      {
        RegAddrLine1: '10 ANGELA CT',
        RegAddrCity: 'BEACON',
        RegAddrState: 'NY',
        RegAddrZip: 12508,
        CongressionalDistrict: 18,
      },
      {
        RegAddrLine1: '62 PANORAMA DR',
        RegAddrCity: 'BREWSTER',
        RegAddrState: 'NY',
        RegAddrZip: 10509,
        CongressionalDistrict: 18,
      },
      {
        RegAddrLine1: '4 HOWLAND DR',
        RegAddrCity: 'CROSS RIVER',
        RegAddrState: 'NY',
        RegAddrZip: 10518,
        CongressionalDistrict: 18,
      },
      {
        RegAddrLine1: '1642 ROUTE 9',
        RegAddrCity: 'GARRISON',
        RegAddrState: 'NY',
        RegAddrZip: 10524,
        CongressionalDistrict: 18,
      },
      {
        RegAddrLine1: '33 ZERNER BLVD',
        RegAddrCity: 'HOPEWELL JCT',
        RegAddrState: 'NY',
        RegAddrZip: 12533,
        CongressionalDistrict: 18,
      },
      {
        RegAddrLine1: '22 RIDGE LN',
        RegAddrCity: 'MAHOPAC',
        RegAddrState: 'NY',
        RegAddrZip: 10541,
        CongressionalDistrict: 18,
      },
      {
        RegAddrLine1: '22 MANDY LN',
        RegAddrCity: 'MAHOPAC',
        RegAddrState: 'NY',
        RegAddrZip: 10541,
        CongressionalDistrict: 18,
      },
      {
        RegAddrLine1: '30 EUCLID AVE',
        RegAddrCity: 'MIDDLETOWN',
        RegAddrState: 'NY',
        RegAddrZip: 10940,
        CongressionalDistrict: 18,
      },
      {
        RegAddrLine1: '276 TEMPLE HILL RD',
        RegAddrCity: 'NEW WINDSOR',
        RegAddrState: 'NY',
        RegAddrZip: 12553,
        CongressionalDistrict: 18,
      },
      {
        RegAddrLine1: '12 STONECREST DR',
        RegAddrCity: 'NEW WINDSOR',
        RegAddrState: 'NY',
        RegAddrZip: 12553,
        CongressionalDistrict: 18,
      },
      {
        RegAddrLine1: '22 ALLISON AVE',
        RegAddrCity: 'NEWBURGH',
        RegAddrState: 'NY',
        RegAddrZip: 12550,
        CongressionalDistrict: 18,
      },
      {
        RegAddrLine1: '657 S ST B',
        RegAddrCity: 'NEWBURGH',
        RegAddrState: 'NY',
        RegAddrZip: 12550,
        CongressionalDistrict: 18,
      },
      {
        RegAddrLine1: '38 HOMESTEAD LN',
        RegAddrCity: 'OTISVILLE',
        RegAddrState: 'NY',
        RegAddrZip: 10963,
        CongressionalDistrict: 18,
      },
      {
        RegAddrLine1: '48 BROOKLAND FARMS RD',
        RegAddrCity: 'POUGHKEEPSIE',
        RegAddrState: 'NY',
        RegAddrZip: 12601,
        CongressionalDistrict: 18,
      },
      {
        RegAddrLine1: '5 DELAFIELD ST',
        RegAddrCity: 'POUGHKEEPSIE',
        RegAddrState: 'NY',
        RegAddrZip: 12601,
        CongressionalDistrict: 18,
      },
      {
        RegAddrLine1: '32 ROMBOUT RIDGE RD',
        RegAddrCity: 'POUGHKEEPSIE',
        RegAddrState: 'NY',
        RegAddrZip: 12603,
        CongressionalDistrict: 18,
      },
      {
        RegAddrLine1: '8 HACK GREEN RD',
        RegAddrCity: 'POUND RIDGE',
        RegAddrState: 'NY',
        RegAddrZip: 10576,
        CongressionalDistrict: 18,
      },
      {
        RegAddrLine1: '24 MIRROR LAKE RD',
        RegAddrCity: 'SPRING VALLEY',
        RegAddrState: 'NY',
        RegAddrZip: 10977,
        CongressionalDistrict: 18,
      },
      {
        RegAddrLine1: '64 KIM LN',
        RegAddrCity: 'STORMVILLE',
        RegAddrState: 'NY',
        RegAddrZip: 12582,
        CongressionalDistrict: 18,
      },
      {
        RegAddrLine1: '73 MEAD ST',
        RegAddrCity: 'WACCABUC',
        RegAddrState: 'NY',
        RegAddrZip: 10597,
        CongressionalDistrict: 18,
      },
      {
        RegAddrLine1: '464 STATE ROUTE 32',
        RegAddrCity: 'WALLKILL',
        RegAddrState: 'NY',
        RegAddrZip: 12589,
        CongressionalDistrict: 18,
      },
      {
        RegAddrLine1: '35 MONTFORT RD',
        RegAddrCity: 'WAPPINGERS FL',
        RegAddrState: 'NY',
        RegAddrZip: 12590,
        CongressionalDistrict: 18,
      },
      {
        RegAddrLine1: '8 GILDEA LN',
        RegAddrCity: 'WESTTOWN',
        RegAddrState: 'NY',
        RegAddrZip: 10998,
        CongressionalDistrict: 18,
      },
    ],
  },
  {
    name: 'NY-19',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/NY-19?ref=gh-pages',
    state: 'NY',
    state_and_number: 'NY-19',
    zip: 12015,
    congressionalDistrict: 19,
    addressesInDistrict: [
      {
        RegAddrLine1: '53 HIGH ST',
        RegAddrCity: 'BLOOMINGBURG',
        RegAddrState: 'NY',
        RegAddrZip: 12721,
        CongressionalDistrict: 19,
      },
      {
        RegAddrLine1: '419 MAPLE LAWN RD',
        RegAddrCity: 'CAIRO',
        RegAddrState: 'NY',
        RegAddrZip: 12413,
        CongressionalDistrict: 19,
      },
      {
        RegAddrLine1: '1178 CORNWALLVILLE RD',
        RegAddrCity: 'DURHAM',
        RegAddrState: 'NY',
        RegAddrZip: 12422,
        CongressionalDistrict: 19,
      },
      {
        RegAddrLine1: '5464 COUNTY HIGHWAY 10',
        RegAddrCity: 'EAST MEREDITH',
        RegAddrState: 'NY',
        RegAddrZip: 13757,
        CongressionalDistrict: 19,
      },
      {
        RegAddrLine1: '15 BACKMAN AVE',
        RegAddrCity: 'ELLENVILLE',
        RegAddrState: 'NY',
        RegAddrZip: 12428,
        CongressionalDistrict: 19,
      },
      {
        RegAddrLine1: '179 DILLENBECK RD',
        RegAddrCity: 'FULTONVILLE',
        RegAddrState: 'NY',
        RegAddrZip: 12072,
        CongressionalDistrict: 19,
      },
      {
        RegAddrLine1: '5040 STATE ROUTE 81',
        RegAddrCity: 'GREENVILLE',
        RegAddrState: 'NY',
        RegAddrZip: 12083,
        CongressionalDistrict: 19,
      },
      {
        RegAddrLine1: '3588 COUNTY ROUTE 7',
        RegAddrCity: 'HILLSDALE',
        RegAddrState: 'NY',
        RegAddrZip: 12529,
        CongressionalDistrict: 19,
      },
      {
        RegAddrLine1: '1004 CLUM HILL RD',
        RegAddrCity: 'HUNTER',
        RegAddrState: 'NY',
        RegAddrZip: 12442,
        CongressionalDistrict: 19,
      },
      {
        RegAddrLine1: '11 KIRCHNER AVE',
        RegAddrCity: 'HYDE PARK',
        RegAddrState: 'NY',
        RegAddrZip: 12538,
        CongressionalDistrict: 19,
      },
      {
        RegAddrLine1: '205 MALL RD',
        RegAddrCity: 'JEFFERSONVLLE',
        RegAddrState: 'NY',
        RegAddrZip: 12748,
        CongressionalDistrict: 19,
      },
      {
        RegAddrLine1: '592 OTIS ST',
        RegAddrCity: 'KINGSTON',
        RegAddrState: 'NY',
        RegAddrZip: 12401,
        CongressionalDistrict: 19,
      },
      {
        RegAddrLine1: '2562 STATE HIGHWAY 206',
        RegAddrCity: 'MASONVILLE',
        RegAddrState: 'NY',
        RegAddrZip: 13804,
        CongressionalDistrict: 19,
      },
      {
        RegAddrLine1: '654 FORD RD',
        RegAddrCity: 'MELROSE',
        RegAddrState: 'NY',
        RegAddrZip: 12121,
        CongressionalDistrict: 19,
      },
      {
        RegAddrLine1: '635 SHUNPIKE',
        RegAddrCity: 'MILLBROOK',
        RegAddrState: 'NY',
        RegAddrZip: 12545,
        CongressionalDistrict: 19,
      },
      {
        RegAddrLine1: '394 STATE ROUTE 17B',
        RegAddrCity: 'MONTICELLO',
        RegAddrState: 'NY',
        RegAddrZip: 12701,
        CongressionalDistrict: 19,
      },
      {
        RegAddrLine1: '430 WINNEY HILL RD',
        RegAddrCity: 'ONEONTA',
        RegAddrState: 'NY',
        RegAddrZip: 13820,
        CongressionalDistrict: 19,
      },
      {
        RegAddrLine1: '545 NY 346',
        RegAddrCity: 'PETERSBURGH',
        RegAddrState: 'NY',
        RegAddrZip: 12138,
        CongressionalDistrict: 19,
      },
      {
        RegAddrLine1: '25 RONDOUT HBR',
        RegAddrCity: 'PORT EWEN',
        RegAddrState: 'NY',
        RegAddrZip: 12466,
        CongressionalDistrict: 19,
      },
      {
        RegAddrLine1: '98 LEO LN',
        RegAddrCity: 'POUGHQUAG',
        RegAddrState: 'NY',
        RegAddrZip: 12570,
        CongressionalDistrict: 19,
      },
      {
        RegAddrLine1: '7 HOEFER RD',
        RegAddrCity: 'RED HOOK',
        RegAddrState: 'NY',
        RegAddrZip: 12571,
        CongressionalDistrict: 19,
      },
      {
        RegAddrLine1: '10 EVERGREEN LN',
        RegAddrCity: 'RHINEBECK',
        RegAddrState: 'NY',
        RegAddrZip: 12572,
        CongressionalDistrict: 19,
      },
      {
        RegAddrLine1: '13 MOUNT RUTSEN RD',
        RegAddrCity: 'RHINEBECK',
        RegAddrState: 'NY',
        RegAddrZip: 12572,
        CongressionalDistrict: 19,
      },
      {
        RegAddrLine1: '25 SOLWAY RD',
        RegAddrCity: 'SAUGERTIES',
        RegAddrState: 'NY',
        RegAddrZip: 12477,
        CongressionalDistrict: 19,
      },
      {
        RegAddrLine1: '178 RIDGE RD',
        RegAddrCity: 'SCHENEVUS',
        RegAddrState: 'NY',
        RegAddrZip: 12155,
        CongressionalDistrict: 19,
      },
      {
        RegAddrLine1: '196 MOUNTAIN RD',
        RegAddrCity: 'SHOKAN',
        RegAddrState: 'NY',
        RegAddrZip: 12481,
        CongressionalDistrict: 19,
      },
      {
        RegAddrLine1: '14475 COUNTY HIGHWAY 27',
        RegAddrCity: 'SIDNEY CENTER',
        RegAddrState: 'NY',
        RegAddrZip: 13839,
        CongressionalDistrict: 19,
      },
      {
        RegAddrLine1: '35 E SHORE DR',
        RegAddrCity: 'VALATIE',
        RegAddrState: 'NY',
        RegAddrZip: 12184,
        CongressionalDistrict: 19,
      },
      {
        RegAddrLine1: '96 COUNTY ROUTE 56',
        RegAddrCity: 'WINDHAM',
        RegAddrState: 'NY',
        RegAddrZip: 12496,
        CongressionalDistrict: 19,
      },
    ],
  },
  {
    name: 'NY-2',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/NY-2?ref=gh-pages',
    state: 'NY',
    state_and_number: 'NY-2',
    zip: 11701,
    congressionalDistrict: 2,
    addressesInDistrict: [
      {
        RegAddrLine1: '1457 POTTER BLVD',
        RegAddrCity: 'BAY SHORE',
        RegAddrState: 'NY',
        RegAddrZip: 11706,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '40 ROOSEVELT DR',
        RegAddrCity: 'BETHPAGE',
        RegAddrState: 'NY',
        RegAddrZip: 11714,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1025 OCEAN AVE',
        RegAddrCity: 'BOHEMIA',
        RegAddrState: 'NY',
        RegAddrZip: 11716,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '16 SAINT ANDREWS DR',
        RegAddrCity: 'BRENTWOOD',
        RegAddrState: 'NY',
        RegAddrZip: 11717,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '141 COCOANUT ST',
        RegAddrCity: 'BRENTWOOD',
        RegAddrState: 'NY',
        RegAddrZip: 11717,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '91 FELLER DR',
        RegAddrCity: 'CENTRAL ISLIP',
        RegAddrState: 'NY',
        RegAddrZip: 11722,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '335 W 7TH ST',
        RegAddrCity: 'DEER PARK',
        RegAddrState: 'NY',
        RegAddrZip: 11729,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '125 SHINNECOCK LN',
        RegAddrCity: 'E ISLIP',
        RegAddrState: 'NY',
        RegAddrZip: 11730,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '208 E NASSAU ST',
        RegAddrCity: 'ISLIP TERRACE',
        RegAddrState: 'NY',
        RegAddrZip: 11752,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '68 EMERSON AVE',
        RegAddrCity: 'LEVITTOWN',
        RegAddrState: 'NY',
        RegAddrZip: 11756,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '108 HAVEN LN',
        RegAddrCity: 'LEVITTOWN',
        RegAddrState: 'NY',
        RegAddrZip: 11756,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '230 S 14TH ST',
        RegAddrCity: 'LINDENHURST',
        RegAddrState: 'NY',
        RegAddrZip: 11757,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '47 FRANK ST',
        RegAddrCity: 'LINDENHURST',
        RegAddrState: 'NY',
        RegAddrZip: 11757,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '7 DOMINICK CT',
        RegAddrCity: 'LINDENHURST',
        RegAddrState: 'NY',
        RegAddrZip: 11757,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '20 CAMP RD',
        RegAddrCity: 'MASSAPEQUA',
        RegAddrState: 'NY',
        RegAddrZip: 11758,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '164 GREENWAY W',
        RegAddrCity: 'NEW HYDE PARK',
        RegAddrState: 'NY',
        RegAddrZip: 11040,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '225 PLEASANT ST',
        RegAddrCity: 'RONKONKOMA',
        RegAddrState: 'NY',
        RegAddrZip: 11779,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '205 WYANDANCH RD',
        RegAddrCity: 'SAYVILLE',
        RegAddrState: 'NY',
        RegAddrZip: 11782,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '737 CARLTON RD',
        RegAddrCity: 'W BABYLON',
        RegAddrState: 'NY',
        RegAddrZip: 11704,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '130 MILLARD AVE',
        RegAddrCity: 'W BABYLON',
        RegAddrState: 'NY',
        RegAddrZip: 11704,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '7 SEXTON DR',
        RegAddrCity: 'W ISLIP',
        RegAddrState: 'NY',
        RegAddrZip: 11795,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1941 JACKSON AVE',
        RegAddrCity: 'W ISLIP',
        RegAddrState: 'NY',
        RegAddrZip: 11795,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '758 TANGLEWOOD RD',
        RegAddrCity: 'W ISLIP',
        RegAddrState: 'NY',
        RegAddrZip: 11795,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '31 RED MAPLE DR N',
        RegAddrCity: 'WANTAGH',
        RegAddrState: 'NY',
        RegAddrZip: 11793,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '17 WATERFORD DR',
        RegAddrCity: 'WHEATLEY HTS',
        RegAddrState: 'NY',
        RegAddrZip: 11798,
        CongressionalDistrict: 2,
      },
    ],
  },
  {
    name: 'NY-20',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/NY-20?ref=gh-pages',
    state: 'NY',
    state_and_number: 'NY-20',
    zip: 12007,
    congressionalDistrict: 20,
    addressesInDistrict: [
      {
        RegAddrLine1: '85 2ND AVE',
        RegAddrCity: 'ALBANY',
        RegAddrState: 'NY',
        RegAddrZip: 12202,
        CongressionalDistrict: 20,
      },
      {
        RegAddrLine1: '34 N PINE AVE',
        RegAddrCity: 'ALBANY',
        RegAddrState: 'NY',
        RegAddrZip: 12203,
        CongressionalDistrict: 20,
      },
      {
        RegAddrLine1: '17 CLERMONT ST',
        RegAddrCity: 'ALBANY',
        RegAddrState: 'NY',
        RegAddrZip: 12203,
        CongressionalDistrict: 20,
      },
      {
        RegAddrLine1: '145 TERRACE AVE',
        RegAddrCity: 'ALBANY',
        RegAddrState: 'NY',
        RegAddrZip: 12203,
        CongressionalDistrict: 20,
      },
      {
        RegAddrLine1: '25 IRIS LN',
        RegAddrCity: 'ALBANY',
        RegAddrState: 'NY',
        RegAddrZip: 12205,
        CongressionalDistrict: 20,
      },
      {
        RegAddrLine1: '7 SANDRA SUE DR',
        RegAddrCity: 'ALBANY',
        RegAddrState: 'NY',
        RegAddrZip: 12211,
        CongressionalDistrict: 20,
      },
      {
        RegAddrLine1: '',
        RegAddrCity: 'ALBANY',
        RegAddrState: 'NY',
        RegAddrZip: 12222,
        CongressionalDistrict: 20,
      },
      {
        RegAddrLine1: '40 LONGWOOD DR',
        RegAddrCity: 'CLIFTON PARK',
        RegAddrState: 'NY',
        RegAddrZip: 12065,
        CongressionalDistrict: 20,
      },
      {
        RegAddrLine1: '7 CANDLELIGHT CT A',
        RegAddrCity: 'CLIFTON PARK',
        RegAddrState: 'NY',
        RegAddrZip: 12065,
        CongressionalDistrict: 20,
      },
      {
        RegAddrLine1: '188 DUNSBACH FERRY RD',
        RegAddrCity: 'COHOES',
        RegAddrState: 'NY',
        RegAddrZip: 12047,
        CongressionalDistrict: 20,
      },
      {
        RegAddrLine1: '29 STACEY CT',
        RegAddrCity: 'COHOES',
        RegAddrState: 'NY',
        RegAddrZip: 12047,
        CongressionalDistrict: 20,
      },
      {
        RegAddrLine1: '10 BLOOMINGDALE AVE',
        RegAddrCity: 'E GREENBUSH',
        RegAddrState: 'NY',
        RegAddrZip: 12061,
        CongressionalDistrict: 20,
      },
      {
        RegAddrLine1: '8 EASY ST',
        RegAddrCity: 'MECHANICVILLE',
        RegAddrState: 'NY',
        RegAddrZip: 12118,
        CongressionalDistrict: 20,
      },
      {
        RegAddrLine1: '6 EQUESTRIAN LN',
        RegAddrCity: 'SARATOGA SPGS',
        RegAddrState: 'NY',
        RegAddrZip: 12866,
        CongressionalDistrict: 20,
      },
      {
        RegAddrLine1: '108 GEORGE ENDRIES DR',
        RegAddrCity: 'SCHENECTADY',
        RegAddrState: 'NY',
        RegAddrZip: 12303,
        CongressionalDistrict: 20,
      },
      {
        RegAddrLine1: '129 ROTTERDAM ST',
        RegAddrCity: 'SCHENECTADY',
        RegAddrState: 'NY',
        RegAddrZip: 12306,
        CongressionalDistrict: 20,
      },
      {
        RegAddrLine1: '4 BERKLEY RD',
        RegAddrCity: 'SCOTIA',
        RegAddrState: 'NY',
        RegAddrZip: 12302,
        CongressionalDistrict: 20,
      },
      {
        RegAddrLine1: '39 WALLACE ST',
        RegAddrCity: 'SCOTIA',
        RegAddrState: 'NY',
        RegAddrZip: 12302,
        CongressionalDistrict: 20,
      },
      {
        RegAddrLine1: '14 WYNANTSKILL WAY',
        RegAddrCity: 'TROY',
        RegAddrState: 'NY',
        RegAddrZip: 12180,
        CongressionalDistrict: 20,
      },
      {
        RegAddrLine1: '478 OLD BEST RD',
        RegAddrCity: 'W SAND LAKE',
        RegAddrState: 'NY',
        RegAddrZip: 12196,
        CongressionalDistrict: 20,
      },
      {
        RegAddrLine1: '425 7TH AVE',
        RegAddrCity: 'WATERVLIET',
        RegAddrState: 'NY',
        RegAddrZip: 12189,
        CongressionalDistrict: 20,
      },
    ],
  },
  {
    name: 'NY-21',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/NY-21?ref=gh-pages',
    state: 'NY',
    state_and_number: 'NY-21',
    zip: 12025,
    congressionalDistrict: 21,
    addressesInDistrict: [
      {
        RegAddrLine1: '10800 COBBVILLE RD',
        RegAddrCity: 'ADAMS',
        RegAddrState: 'NY',
        RegAddrZip: 13605,
        CongressionalDistrict: 21,
      },
      {
        RegAddrLine1: '28 MECHANIC ST',
        RegAddrCity: 'BALLSTON SPA',
        RegAddrState: 'NY',
        RegAddrZip: 12020,
        CongressionalDistrict: 21,
      },
      {
        RegAddrLine1: '23931 STATE ROUTE 180',
        RegAddrCity: 'DEXTER',
        RegAddrState: 'NY',
        RegAddrZip: 13634,
        CongressionalDistrict: 21,
      },
      {
        RegAddrLine1: '286 WHITE BIRCH EST',
        RegAddrCity: 'FORT EDWARD',
        RegAddrState: 'NY',
        RegAddrZip: 12828,
        CongressionalDistrict: 21,
      },
      {
        RegAddrLine1: '15 SHUVEE LN',
        RegAddrCity: 'GANSEVOORT',
        RegAddrState: 'NY',
        RegAddrZip: 12831,
        CongressionalDistrict: 21,
      },
      {
        RegAddrLine1: '493 HURRICANE RD',
        RegAddrCity: 'KEENE',
        RegAddrState: 'NY',
        RegAddrZip: 12942,
        CongressionalDistrict: 21,
      },
      {
        RegAddrLine1: '25 JACKSON ST',
        RegAddrCity: 'KEESEVILLE',
        RegAddrState: 'NY',
        RegAddrZip: 12944,
        CongressionalDistrict: 21,
      },
      {
        RegAddrLine1: '1071 COUNTY ROUTE 25',
        RegAddrCity: 'MALONE',
        RegAddrState: 'NY',
        RegAddrZip: 12953,
        CongressionalDistrict: 21,
      },
      {
        RegAddrLine1: '25 PARK ST',
        RegAddrCity: 'NORWOOD',
        RegAddrState: 'NY',
        RegAddrZip: 13668,
        CongressionalDistrict: 21,
      },
      {
        RegAddrLine1: '255 RIVER RD E',
        RegAddrCity: 'OGDENSBURG',
        RegAddrState: 'NY',
        RegAddrZip: 13669,
        CongressionalDistrict: 21,
      },
      {
        RegAddrLine1: '122 RIVERVIEW TER',
        RegAddrCity: 'OLD FORGE',
        RegAddrState: 'NY',
        RegAddrZip: 13420,
        CongressionalDistrict: 21,
      },
      {
        RegAddrLine1: '1175 OREGON PLAINS RD',
        RegAddrCity: 'ONCHIOTA',
        RegAddrState: 'NY',
        RegAddrZip: 12989,
        CongressionalDistrict: 21,
      },
      {
        RegAddrLine1: '4881 S CATHERINE ST',
        RegAddrCity: 'PLATTSBURGH',
        RegAddrState: 'NY',
        RegAddrZip: 12901,
        CongressionalDistrict: 21,
      },
      {
        RegAddrLine1: '',
        RegAddrCity: 'PLATTSBURGH',
        RegAddrState: 'NY',
        RegAddrZip: 12901,
        CongressionalDistrict: 21,
      },
      {
        RegAddrLine1: '7026 W MAIN ST',
        RegAddrCity: 'PORT LEYDEN',
        RegAddrState: 'NY',
        RegAddrZip: 13433,
        CongressionalDistrict: 21,
      },
      {
        RegAddrLine1: '470 W MOUNTAIN RD',
        RegAddrCity: 'QUEENSBURY',
        RegAddrState: 'NY',
        RegAddrZip: 12804,
        CongressionalDistrict: 21,
      },
      {
        RegAddrLine1: '123 GILLIS HILL LN',
        RegAddrCity: 'SALEM',
        RegAddrState: 'NY',
        RegAddrZip: 12865,
        CongressionalDistrict: 21,
      },
      {
        RegAddrLine1: '290 IRONDALE RD',
        RegAddrCity: 'SALISBURY CTR',
        RegAddrState: 'NY',
        RegAddrZip: 13454,
        CongressionalDistrict: 21,
      },
      {
        RegAddrLine1: '2230 BLACK POINT RD',
        RegAddrCity: 'TICONDEROGA',
        RegAddrState: 'NY',
        RegAddrZip: 12883,
        CongressionalDistrict: 21,
      },
      {
        RegAddrLine1: '2 1/2 TERRACE AVE',
        RegAddrCity: 'WARRENSBURG',
        RegAddrState: 'NY',
        RegAddrZip: 12885,
        CongressionalDistrict: 21,
      },
      {
        RegAddrLine1: '24165 STATE ROUTE 12',
        RegAddrCity: 'WATERTOWN',
        RegAddrState: 'NY',
        RegAddrZip: 13601,
        CongressionalDistrict: 21,
      },
      {
        RegAddrLine1: '18 CHAMPLAIN AVE',
        RegAddrCity: 'WHITEHALL',
        RegAddrState: 'NY',
        RegAddrZip: 12887,
        CongressionalDistrict: 21,
      },
    ],
  },
  {
    name: 'NY-22',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/NY-22?ref=gh-pages',
    state: 'NY',
    state_and_number: 'NY-22',
    zip: 13028,
    congressionalDistrict: 22,
    addressesInDistrict: [
      {
        RegAddrLine1: '',
        RegAddrCity: 'BINGHAMTON',
        RegAddrState: 'NY',
        RegAddrZip: 13901,
        CongressionalDistrict: 22,
      },
      {
        RegAddrLine1: '6 ALPINE RD',
        RegAddrCity: 'BINGHAMTON',
        RegAddrState: 'NY',
        RegAddrZip: 13903,
        CongressionalDistrict: 22,
      },
      {
        RegAddrLine1: '428 HILLSBORO RD',
        RegAddrCity: 'CAMDEN',
        RegAddrState: 'NY',
        RegAddrZip: 13316,
        CongressionalDistrict: 22,
      },
      {
        RegAddrLine1: '40 KELLY DR',
        RegAddrCity: 'CENTRAL SQ',
        RegAddrState: 'NY',
        RegAddrZip: 13036,
        CongressionalDistrict: 22,
      },
      {
        RegAddrLine1: '131 GILDNER RD',
        RegAddrCity: 'CENTRAL SQ',
        RegAddrState: 'NY',
        RegAddrZip: 13036,
        CongressionalDistrict: 22,
      },
      {
        RegAddrLine1: '99 CEMETARY RD',
        RegAddrCity: 'CONSTANTIA',
        RegAddrState: 'NY',
        RegAddrZip: 13044,
        CongressionalDistrict: 22,
      },
      {
        RegAddrLine1: '3008 PHYLLIS ST',
        RegAddrCity: 'ENDICOTT',
        RegAddrState: 'NY',
        RegAddrZip: 13760,
        CongressionalDistrict: 22,
      },
      {
        RegAddrLine1: '2740 WELSHBUSH RD',
        RegAddrCity: 'FRANKFORT',
        RegAddrState: 'NY',
        RegAddrZip: 13340,
        CongressionalDistrict: 22,
      },
      {
        RegAddrLine1: '1031 HIGHLAND AVE',
        RegAddrCity: 'HERKIMER',
        RegAddrState: 'NY',
        RegAddrZip: 13350,
        CongressionalDistrict: 22,
      },
      {
        RegAddrLine1: '9293 STATE ROUTE 365',
        RegAddrCity: 'HOLLAND PATNT',
        RegAddrState: 'NY',
        RegAddrZip: 13354,
        CongressionalDistrict: 22,
      },
      {
        RegAddrLine1: '2149 FARM TO MARKET RD',
        RegAddrCity: 'JOHNSON CITY',
        RegAddrState: 'NY',
        RegAddrZip: 13790,
        CongressionalDistrict: 22,
      },
      {
        RegAddrLine1: '915 OAKDALE RD',
        RegAddrCity: 'JOHNSON CITY',
        RegAddrState: 'NY',
        RegAddrZip: 13790,
        CongressionalDistrict: 22,
      },
      {
        RegAddrLine1: '2159 STATE ROUTE 8',
        RegAddrCity: 'LEONARDSVILLE',
        RegAddrState: 'NY',
        RegAddrZip: 13364,
        CongressionalDistrict: 22,
      },
      {
        RegAddrLine1: '230 CHURCH ST',
        RegAddrCity: 'LITTLE FALLS',
        RegAddrState: 'NY',
        RegAddrZip: 13365,
        CongressionalDistrict: 22,
      },
      {
        RegAddrLine1: '1780 COUNTY ROAD 2',
        RegAddrCity: 'MC DONOUGH',
        RegAddrState: 'NY',
        RegAddrZip: 13801,
        CongressionalDistrict: 22,
      },
      {
        RegAddrLine1: '38 NEW HARTFORD ST',
        RegAddrCity: 'NEW YORK MLS',
        RegAddrState: 'NY',
        RegAddrZip: 13417,
        CongressionalDistrict: 22,
      },
      {
        RegAddrLine1: '1054 COUNTY ROUTE 52',
        RegAddrCity: 'RICHLAND',
        RegAddrState: 'NY',
        RegAddrZip: 13144,
        CongressionalDistrict: 22,
      },
      {
        RegAddrLine1: '512 1/2 WILLIAM ST',
        RegAddrCity: 'ROME',
        RegAddrState: 'NY',
        RegAddrZip: 13440,
        CongressionalDistrict: 22,
      },
      {
        RegAddrLine1: '611 ROUND POND RD',
        RegAddrCity: 'SMITHVLE FLTS',
        RegAddrState: 'NY',
        RegAddrZip: 13841,
        CongressionalDistrict: 22,
      },
      {
        RegAddrLine1: '406 CYPRESS ST',
        RegAddrCity: 'UTICA',
        RegAddrState: 'NY',
        RegAddrZip: 13502,
        CongressionalDistrict: 22,
      },
      {
        RegAddrLine1: '1309 WOODBURY DR',
        RegAddrCity: 'UTICA',
        RegAddrState: 'NY',
        RegAddrZip: 13502,
        CongressionalDistrict: 22,
      },
      {
        RegAddrLine1: '5378 OTTMAN RD',
        RegAddrCity: 'VERNON',
        RegAddrState: 'NY',
        RegAddrZip: 13476,
        CongressionalDistrict: 22,
      },
      {
        RegAddrLine1: '1210 STATE ROUTE 12',
        RegAddrCity: 'WATERVILLE',
        RegAddrState: 'NY',
        RegAddrZip: 13480,
        CongressionalDistrict: 22,
      },
      {
        RegAddrLine1: '7248 E CARTER RD',
        RegAddrCity: 'WESTMORELAND',
        RegAddrState: 'NY',
        RegAddrZip: 13490,
        CongressionalDistrict: 22,
      },
    ],
  },
  {
    name: 'NY-23',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/NY-23?ref=gh-pages',
    state: 'NY',
    state_and_number: 'NY-23',
    zip: 13062,
    congressionalDistrict: 23,
    addressesInDistrict: [
      {
        RegAddrLine1: '5173 LITTLE RD',
        RegAddrCity: 'ADDISON',
        RegAddrState: 'NY',
        RegAddrZip: 14801,
        CongressionalDistrict: 23,
      },
      {
        RegAddrLine1: '3231 MORGAN HOLLOW RD',
        RegAddrCity: 'ALLEGANY',
        RegAddrState: 'NY',
        RegAddrZip: 14706,
        CongressionalDistrict: 23,
      },
      {
        RegAddrLine1: '3944 WING HOLLOW RD',
        RegAddrCity: 'ALLEGANY',
        RegAddrState: 'NY',
        RegAddrZip: 14706,
        CongressionalDistrict: 23,
      },
      {
        RegAddrLine1: '1030 COUNTY ROAD 16',
        RegAddrCity: 'BEAVER DAMS',
        RegAddrState: 'NY',
        RegAddrZip: 14812,
        CongressionalDistrict: 23,
      },
      {
        RegAddrLine1: '51 SAINT HEDWIG AVE',
        RegAddrCity: 'DUNKIRK',
        RegAddrState: 'NY',
        RegAddrZip: 14048,
        CongressionalDistrict: 23,
      },
      {
        RegAddrLine1: '215 KING ST',
        RegAddrCity: 'DUNKIRK',
        RegAddrState: 'NY',
        RegAddrZip: 14048,
        CongressionalDistrict: 23,
      },
      {
        RegAddrLine1: '401 REDWOOD DR',
        RegAddrCity: 'ELMIRA',
        RegAddrState: 'NY',
        RegAddrZip: 14904,
        CongressionalDistrict: 23,
      },
      {
        RegAddrLine1: '1504 W CHURCH ST',
        RegAddrCity: 'ELMIRA',
        RegAddrState: 'NY',
        RegAddrZip: 14905,
        CongressionalDistrict: 23,
      },
      {
        RegAddrLine1: '10 MAPLE AVE',
        RegAddrCity: 'FRANKLINVILLE',
        RegAddrState: 'NY',
        RegAddrZip: 14737,
        CongressionalDistrict: 23,
      },
      {
        RegAddrLine1: '71 N MAIN ST',
        RegAddrCity: 'GENEVA',
        RegAddrState: 'NY',
        RegAddrZip: 14456,
        CongressionalDistrict: 23,
      },
      {
        RegAddrLine1: '6041 WYNN RD',
        RegAddrCity: 'HECTOR',
        RegAddrState: 'NY',
        RegAddrZip: 14841,
        CongressionalDistrict: 23,
      },
      {
        RegAddrLine1: '227 VINCENT ST',
        RegAddrCity: 'HORNELL',
        RegAddrState: 'NY',
        RegAddrZip: 14843,
        CongressionalDistrict: 23,
      },
      {
        RegAddrLine1: '209 N HULETT AVE',
        RegAddrCity: 'HORSEHEADS',
        RegAddrState: 'NY',
        RegAddrZip: 14845,
        CongressionalDistrict: 23,
      },
      {
        RegAddrLine1: '915 E STATE ST',
        RegAddrCity: 'ITHACA',
        RegAddrState: 'NY',
        RegAddrZip: 14850,
        CongressionalDistrict: 23,
      },
      {
        RegAddrLine1: '143 BROOKTONDALE RD',
        RegAddrCity: 'ITHACA',
        RegAddrState: 'NY',
        RegAddrZip: 14850,
        CongressionalDistrict: 23,
      },
      {
        RegAddrLine1: '129 HAWTHORNE PL',
        RegAddrCity: 'ITHACA',
        RegAddrState: 'NY',
        RegAddrZip: 14850,
        CongressionalDistrict: 23,
      },
      {
        RegAddrLine1: '89 LAKIN AVE',
        RegAddrCity: 'JAMESTOWN',
        RegAddrState: 'NY',
        RegAddrZip: 14701,
        CongressionalDistrict: 23,
      },
      {
        RegAddrLine1: '9 FRANKLIN DR',
        RegAddrCity: 'LANSING',
        RegAddrState: 'NY',
        RegAddrZip: 14882,
        CongressionalDistrict: 23,
      },
      {
        RegAddrLine1: '5666 NICHOLS RUN',
        RegAddrCity: 'LIMESTONE',
        RegAddrState: 'NY',
        RegAddrZip: 14753,
        CongressionalDistrict: 23,
      },
      {
        RegAddrLine1: '588 PULTZ HILL RD',
        RegAddrCity: 'OWEGO',
        RegAddrState: 'NY',
        RegAddrZip: 13827,
        CongressionalDistrict: 23,
      },
      {
        RegAddrLine1: '',
        RegAddrCity: 'OWEGO',
        RegAddrState: 'NY',
        RegAddrZip: 13827,
        CongressionalDistrict: 23,
      },
      {
        RegAddrLine1: '5 EAGLE DR',
        RegAddrCity: 'PAINTED POST',
        RegAddrState: 'NY',
        RegAddrZip: 14870,
        CongressionalDistrict: 23,
      },
      {
        RegAddrLine1: '4873 MILLER RD',
        RegAddrCity: 'RIPLEY',
        RegAddrState: 'NY',
        RegAddrZip: 14775,
        CongressionalDistrict: 23,
      },
      {
        RegAddrLine1: '6524 COUNTY ROUTE 17',
        RegAddrCity: 'SAVONA',
        RegAddrState: 'NY',
        RegAddrZip: 14879,
        CongressionalDistrict: 23,
      },
      {
        RegAddrLine1: '5 PARK ST',
        RegAddrCity: 'SINCLAIRVILLE',
        RegAddrState: 'NY',
        RegAddrZip: 14782,
        CongressionalDistrict: 23,
      },
      {
        RegAddrLine1: '1083 BUTCHER RD',
        RegAddrCity: 'SOUTH DAYTON',
        RegAddrState: 'NY',
        RegAddrZip: 14138,
        CongressionalDistrict: 23,
      },
      {
        RegAddrLine1: '20 LUNN CT',
        RegAddrCity: 'WELLSVILLE',
        RegAddrState: 'NY',
        RegAddrZip: 14895,
        CongressionalDistrict: 23,
      },
    ],
  },
  {
    name: 'NY-24',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/NY-24?ref=gh-pages',
    state: 'NY',
    state_and_number: 'NY-24',
    zip: 13020,
    congressionalDistrict: 24,
    addressesInDistrict: [
      {
        RegAddrLine1: '83 BRADFORD ST',
        RegAddrCity: 'AUBURN',
        RegAddrState: 'NY',
        RegAddrZip: 13021,
        CongressionalDistrict: 24,
      },
      {
        RegAddrLine1: '65 RICHLEE DR',
        RegAddrCity: 'CAMILLUS',
        RegAddrState: 'NY',
        RegAddrZip: 13031,
        CongressionalDistrict: 24,
      },
      {
        RegAddrLine1: '2984 POMPEY HOLLOW RD',
        RegAddrCity: 'CAZENOVIA',
        RegAddrState: 'NY',
        RegAddrZip: 13035,
        CongressionalDistrict: 24,
      },
      {
        RegAddrLine1: '503 CALHOUN ST',
        RegAddrCity: 'EAST SYRACUSE',
        RegAddrState: 'NY',
        RegAddrZip: 13057,
        CongressionalDistrict: 24,
      },
      {
        RegAddrLine1: '6656 WINSLOW DR',
        RegAddrCity: 'FAYETTEVILLE',
        RegAddrState: 'NY',
        RegAddrZip: 13066,
        CongressionalDistrict: 24,
      },
      {
        RegAddrLine1: '4592 JAMESVILLE RD',
        RegAddrCity: 'JAMESVILLE',
        RegAddrState: 'NY',
        RegAddrZip: 13078,
        CongressionalDistrict: 24,
      },
      {
        RegAddrLine1: '7648 ANCHOR DR',
        RegAddrCity: 'LIVERPOOL',
        RegAddrState: 'NY',
        RegAddrZip: 13090,
        CongressionalDistrict: 24,
      },
      {
        RegAddrLine1: '211 OLD COVE RD',
        RegAddrCity: 'LIVERPOOL',
        RegAddrState: 'NY',
        RegAddrZip: 13090,
        CongressionalDistrict: 24,
      },
      {
        RegAddrLine1: '401 EVERWILD LN',
        RegAddrCity: 'MACEDON',
        RegAddrState: 'NY',
        RegAddrZip: 14502,
        CongressionalDistrict: 24,
      },
      {
        RegAddrLine1: '8900 PECK HILL RD',
        RegAddrCity: 'MANLIUS',
        RegAddrState: 'NY',
        RegAddrZip: 13104,
        CongressionalDistrict: 24,
      },
      {
        RegAddrLine1: '4909 LIMELEDGE RD',
        RegAddrCity: 'MARCELLUS',
        RegAddrState: 'NY',
        RegAddrZip: 13108,
        CongressionalDistrict: 24,
      },
      {
        RegAddrLine1: '3181 FALLS RD',
        RegAddrCity: 'MARCELLUS',
        RegAddrState: 'NY',
        RegAddrZip: 13108,
        CongressionalDistrict: 24,
      },
      {
        RegAddrLine1: '3207 CAMBIER RD',
        RegAddrCity: 'MARION',
        RegAddrState: 'NY',
        RegAddrZip: 14505,
        CongressionalDistrict: 24,
      },
      {
        RegAddrLine1: '248 ESTHER ST',
        RegAddrCity: 'MINOA',
        RegAddrState: 'NY',
        RegAddrZip: 13116,
        CongressionalDistrict: 24,
      },
      {
        RegAddrLine1: '10244 CATCHPOLE RD',
        RegAddrCity: 'NORTH ROSE',
        RegAddrState: 'NY',
        RegAddrZip: 14516,
        CongressionalDistrict: 24,
      },
      {
        RegAddrLine1: '3913 COUNTY ROUTE 57',
        RegAddrCity: 'OSWEGO',
        RegAddrState: 'NY',
        RegAddrZip: 13126,
        CongressionalDistrict: 24,
      },
      {
        RegAddrLine1: '13819 TAMARACK DR',
        RegAddrCity: 'RED CREEK',
        RegAddrState: 'NY',
        RegAddrZip: 13143,
        CongressionalDistrict: 24,
      },
      {
        RegAddrLine1: '7443 RIDGE RD',
        RegAddrCity: 'SODUS',
        RegAddrState: 'NY',
        RegAddrZip: 14551,
        CongressionalDistrict: 24,
      },
      {
        RegAddrLine1: '116 ATKINSON AVE',
        RegAddrCity: 'SYRACUSE',
        RegAddrState: 'NY',
        RegAddrZip: 13207,
        CongressionalDistrict: 24,
      },
      {
        RegAddrLine1: '101 HURON AVE S',
        RegAddrCity: 'SYRACUSE',
        RegAddrState: 'NY',
        RegAddrZip: 13209,
        CongressionalDistrict: 24,
      },
      {
        RegAddrLine1: '2589 EARL ST',
        RegAddrCity: 'WEEDSPORT',
        RegAddrState: 'NY',
        RegAddrZip: 13166,
        CongressionalDistrict: 24,
      },
    ],
  },
  {
    name: 'NY-25',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/NY-25?ref=gh-pages',
    state: 'NY',
    state_and_number: 'NY-25',
    zip: 14416,
    congressionalDistrict: 25,
    addressesInDistrict: [
      {
        RegAddrLine1: '30 LAKE RD',
        RegAddrCity: 'AVON',
        RegAddrState: 'NY',
        RegAddrZip: 14414,
        CongressionalDistrict: 25,
      },
      {
        RegAddrLine1: '7 KEABLE CT',
        RegAddrCity: 'BROCKPORT',
        RegAddrState: 'NY',
        RegAddrZip: 14420,
        CongressionalDistrict: 25,
      },
      {
        RegAddrLine1: '167 BLUHM RD',
        RegAddrCity: 'FAIRPORT',
        RegAddrState: 'NY',
        RegAddrZip: 14450,
        CongressionalDistrict: 25,
      },
      {
        RegAddrLine1: '82 BRENTWOOD LN',
        RegAddrCity: 'FAIRPORT',
        RegAddrState: 'NY',
        RegAddrZip: 14450,
        CongressionalDistrict: 25,
      },
      {
        RegAddrLine1: '79 CHADWICK MNR',
        RegAddrCity: 'FAIRPORT',
        RegAddrState: 'NY',
        RegAddrZip: 14450,
        CongressionalDistrict: 25,
      },
      {
        RegAddrLine1: '3182 E HENRIETTA RD',
        RegAddrCity: 'HENRIETTA',
        RegAddrState: 'NY',
        RegAddrZip: 14467,
        CongressionalDistrict: 25,
      },
      {
        RegAddrLine1: '67 N GREECE RD',
        RegAddrCity: 'HILTON',
        RegAddrState: 'NY',
        RegAddrZip: 14468,
        CongressionalDistrict: 25,
      },
      {
        RegAddrLine1: '62 HILLSIDE DR',
        RegAddrCity: 'HILTON',
        RegAddrState: 'NY',
        RegAddrZip: 14468,
        CongressionalDistrict: 25,
      },
      {
        RegAddrLine1: '9 MILLFORD XING',
        RegAddrCity: 'PENFIELD',
        RegAddrState: 'NY',
        RegAddrZip: 14526,
        CongressionalDistrict: 25,
      },
      {
        RegAddrLine1: '265 TUMBLEWEED DR',
        RegAddrCity: 'PITTSFORD',
        RegAddrState: 'NY',
        RegAddrZip: 14534,
        CongressionalDistrict: 25,
      },
      {
        RegAddrLine1: '37 IROQUOIS ST',
        RegAddrCity: 'ROCHESTER',
        RegAddrState: 'NY',
        RegAddrZip: 14609,
        CongressionalDistrict: 25,
      },
      {
        RegAddrLine1: '51 SALINA ST',
        RegAddrCity: 'ROCHESTER',
        RegAddrState: 'NY',
        RegAddrZip: 14611,
        CongressionalDistrict: 25,
      },
      {
        RegAddrLine1: '213 LARKINS XING',
        RegAddrCity: 'ROCHESTER',
        RegAddrState: 'NY',
        RegAddrZip: 14612,
        CongressionalDistrict: 25,
      },
      {
        RegAddrLine1: '30 CARLISLE ST',
        RegAddrCity: 'ROCHESTER',
        RegAddrState: 'NY',
        RegAddrZip: 14615,
        CongressionalDistrict: 25,
      },
      {
        RegAddrLine1: '4255 MOUNT READ BLVD',
        RegAddrCity: 'ROCHESTER',
        RegAddrState: 'NY',
        RegAddrZip: 14616,
        CongressionalDistrict: 25,
      },
      {
        RegAddrLine1: '37 TARRYTOWN RD',
        RegAddrCity: 'ROCHESTER',
        RegAddrState: 'NY',
        RegAddrZip: 14618,
        CongressionalDistrict: 25,
      },
      {
        RegAddrLine1: '87 THOMAS ST',
        RegAddrCity: 'ROCHESTER',
        RegAddrState: 'NY',
        RegAddrZip: 14621,
        CongressionalDistrict: 25,
      },
      {
        RegAddrLine1: '65 DURAND BLVD',
        RegAddrCity: 'ROCHESTER',
        RegAddrState: 'NY',
        RegAddrZip: 14622,
        CongressionalDistrict: 25,
      },
      {
        RegAddrLine1: '60 BROWER RD',
        RegAddrCity: 'ROCHESTER',
        RegAddrState: 'NY',
        RegAddrZip: 14622,
        CongressionalDistrict: 25,
      },
      {
        RegAddrLine1: '45 KARRAT DR',
        RegAddrCity: 'ROCHESTER',
        RegAddrState: 'NY',
        RegAddrZip: 14622,
        CongressionalDistrict: 25,
      },
      {
        RegAddrLine1: '65 STONY POINT RD',
        RegAddrCity: 'ROCHESTER',
        RegAddrState: 'NY',
        RegAddrZip: 14624,
        CongressionalDistrict: 25,
      },
      {
        RegAddrLine1: '230 BAYWAY DR',
        RegAddrCity: 'WEBSTER',
        RegAddrState: 'NY',
        RegAddrZip: 14580,
        CongressionalDistrict: 25,
      },
      {
        RegAddrLine1: '1832 STATE RD',
        RegAddrCity: 'WEBSTER',
        RegAddrState: 'NY',
        RegAddrZip: 14580,
        CongressionalDistrict: 25,
      },
    ],
  },
  {
    name: 'NY-26',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/NY-26?ref=gh-pages',
    state: 'NY',
    state_and_number: 'NY-26',
    zip: 14043,
    congressionalDistrict: 26,
    addressesInDistrict: [
      {
        RegAddrLine1: '24 LORFIELD DR',
        RegAddrCity: 'AMHERST',
        RegAddrState: 'NY',
        RegAddrZip: 14226,
        CongressionalDistrict: 26,
      },
      {
        RegAddrLine1: '247 N OGDEN ST',
        RegAddrCity: 'BUFFALO',
        RegAddrState: 'NY',
        RegAddrZip: 14206,
        CongressionalDistrict: 26,
      },
      {
        RegAddrLine1: '133 CABLE ST',
        RegAddrCity: 'BUFFALO',
        RegAddrState: 'NY',
        RegAddrZip: 14206,
        CongressionalDistrict: 26,
      },
      {
        RegAddrLine1: '67 COCHRANE ST',
        RegAddrCity: 'BUFFALO',
        RegAddrState: 'NY',
        RegAddrZip: 14206,
        CongressionalDistrict: 26,
      },
      {
        RegAddrLine1: '101 SAINT LOUIS AVE',
        RegAddrCity: 'BUFFALO',
        RegAddrState: 'NY',
        RegAddrZip: 14211,
        CongressionalDistrict: 26,
      },
      {
        RegAddrLine1: '41 TACOMA AVE',
        RegAddrCity: 'BUFFALO',
        RegAddrState: 'NY',
        RegAddrZip: 14216,
        CongressionalDistrict: 26,
      },
      {
        RegAddrLine1: '250 CONANT DR',
        RegAddrCity: 'BUFFALO',
        RegAddrState: 'NY',
        RegAddrZip: 14223,
        CongressionalDistrict: 26,
      },
      {
        RegAddrLine1: '83 ALPINE PL',
        RegAddrCity: 'CHEEKTOWAGA',
        RegAddrState: 'NY',
        RegAddrZip: 14225,
        CongressionalDistrict: 26,
      },
      {
        RegAddrLine1: '101 COUNTRYSIDE LN',
        RegAddrCity: 'GRAND ISLAND',
        RegAddrState: 'NY',
        RegAddrZip: 14072,
        CongressionalDistrict: 26,
      },
      {
        RegAddrLine1: '445 TREMONT ST',
        RegAddrCity: 'N TONAWANDA',
        RegAddrState: 'NY',
        RegAddrZip: 14120,
        CongressionalDistrict: 26,
      },
      {
        RegAddrLine1: '20 OAKHILL DR',
        RegAddrCity: 'WEST SENECA',
        RegAddrState: 'NY',
        RegAddrZip: 14224,
        CongressionalDistrict: 26,
      },
      {
        RegAddrLine1: '26 DOVER DR',
        RegAddrCity: 'WEST SENECA',
        RegAddrState: 'NY',
        RegAddrZip: 14224,
        CongressionalDistrict: 26,
      },
      {
        RegAddrLine1: '1604 PARK LANE VILLAS DR',
        RegAddrCity: 'WEST SENECA',
        RegAddrState: 'NY',
        RegAddrZip: 14224,
        CongressionalDistrict: 26,
      },
      {
        RegAddrLine1: '59 DOGWOOD RD',
        RegAddrCity: 'WILLIAMSVILLE',
        RegAddrState: 'NY',
        RegAddrZip: 14221,
        CongressionalDistrict: 26,
      },
      {
        RegAddrLine1: '15 HIDDEN CREEK CT',
        RegAddrCity: 'WILLIAMSVILLE',
        RegAddrState: 'NY',
        RegAddrZip: 14221,
        CongressionalDistrict: 26,
      },
      {
        RegAddrLine1: '18 ROSEWOOD DR',
        RegAddrCity: 'WILLIAMSVILLE',
        RegAddrState: 'NY',
        RegAddrZip: 14221,
        CongressionalDistrict: 26,
      },
    ],
  },
  {
    name: 'NY-27',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/NY-27?ref=gh-pages',
    state: 'NY',
    state_and_number: 'NY-27',
    zip: 14001,
    congressionalDistrict: 27,
    addressesInDistrict: [
      {
        RegAddrLine1: '31 WESTGATE AVE',
        RegAddrCity: 'AKRON',
        RegAddrState: 'NY',
        RegAddrZip: 14001,
        CongressionalDistrict: 27,
      },
      {
        RegAddrLine1: '100 POLE BRIDGE RD',
        RegAddrCity: 'AVON',
        RegAddrState: 'NY',
        RegAddrZip: 14414,
        CongressionalDistrict: 27,
      },
      {
        RegAddrLine1: '8752 DONAHUE RD',
        RegAddrCity: 'BATAVIA',
        RegAddrState: 'NY',
        RegAddrZip: 14020,
        CongressionalDistrict: 27,
      },
      {
        RegAddrLine1: '5117 BATAVIA ELBA TOWNLINE RD',
        RegAddrCity: 'BATAVIA',
        RegAddrState: 'NY',
        RegAddrZip: 14020,
        CongressionalDistrict: 27,
      },
      {
        RegAddrLine1: '5000 HILLCREST DR',
        RegAddrCity: 'CLARENCE',
        RegAddrState: 'NY',
        RegAddrZip: 14031,
        CongressionalDistrict: 27,
      },
      {
        RegAddrLine1: '5370 SHIMERVILLE RD',
        RegAddrCity: 'CLARENCE',
        RegAddrState: 'NY',
        RegAddrZip: 14031,
        CongressionalDistrict: 27,
      },
      {
        RegAddrLine1: '9615 HIGH ST',
        RegAddrCity: 'CLARENCE CTR',
        RegAddrState: 'NY',
        RegAddrZip: 14032,
        CongressionalDistrict: 27,
      },
      {
        RegAddrLine1: '14774 WILSON RD',
        RegAddrCity: 'COLLINS',
        RegAddrState: 'NY',
        RegAddrZip: 14034,
        CongressionalDistrict: 27,
      },
      {
        RegAddrLine1: '6411 MARSHALL RD',
        RegAddrCity: 'CONESUS',
        RegAddrState: 'NY',
        RegAddrZip: 14435,
        CongressionalDistrict: 27,
      },
      {
        RegAddrLine1: '10455 SHAY RD',
        RegAddrCity: 'DANSVILLE',
        RegAddrState: 'NY',
        RegAddrZip: 14437,
        CongressionalDistrict: 27,
      },
      {
        RegAddrLine1: '7022 BRANDYWINE DR',
        RegAddrCity: 'DERBY',
        RegAddrState: 'NY',
        RegAddrZip: 14047,
        CongressionalDistrict: 27,
      },
      {
        RegAddrLine1: '6462 CEDAR CREEK WAY',
        RegAddrCity: 'FARMINGTON',
        RegAddrState: 'NY',
        RegAddrZip: 14425,
        CongressionalDistrict: 27,
      },
      {
        RegAddrLine1: '3038 OLD LAKEVIEW RD',
        RegAddrCity: 'HAMBURG',
        RegAddrState: 'NY',
        RegAddrZip: 14075,
        CongressionalDistrict: 27,
      },
      {
        RegAddrLine1: '3457 PARKWOOD CMN',
        RegAddrCity: 'HAMBURG',
        RegAddrState: 'NY',
        RegAddrZip: 14075,
        CongressionalDistrict: 27,
      },
      {
        RegAddrLine1: '4934 WINTERWAY LN',
        RegAddrCity: 'HAMBURG',
        RegAddrState: 'NY',
        RegAddrZip: 14075,
        CongressionalDistrict: 27,
      },
      {
        RegAddrLine1: '14 OLD BROOK TRL',
        RegAddrCity: 'HONEOYE FALLS',
        RegAddrState: 'NY',
        RegAddrZip: 14472,
        CongressionalDistrict: 27,
      },
      {
        RegAddrLine1: '78 EAST ST',
        RegAddrCity: 'HONEOYE FALLS',
        RegAddrState: 'NY',
        RegAddrZip: 14472,
        CongressionalDistrict: 27,
      },
      {
        RegAddrLine1: '22 BLUEJAY CIR',
        RegAddrCity: 'LANCASTER',
        RegAddrState: 'NY',
        RegAddrZip: 14086,
        CongressionalDistrict: 27,
      },
      {
        RegAddrLine1: '',
        RegAddrCity: 'LOCKPORT',
        RegAddrState: 'NY',
        RegAddrZip: 14094,
        CongressionalDistrict: 27,
      },
      {
        RegAddrLine1: '11652 PLATTEN RD',
        RegAddrCity: 'LYNDONVILLE',
        RegAddrState: 'NY',
        RegAddrZip: 14098,
        CongressionalDistrict: 27,
      },
      {
        RegAddrLine1: '2216 WILLISTON HTS',
        RegAddrCity: 'MARILLA',
        RegAddrState: 'NY',
        RegAddrZip: 14102,
        CongressionalDistrict: 27,
      },
      {
        RegAddrLine1: '7161 LENMART DR',
        RegAddrCity: 'N TONAWANDA',
        RegAddrState: 'NY',
        RegAddrZip: 14120,
        CongressionalDistrict: 27,
      },
      {
        RegAddrLine1: '7264 NASH RD',
        RegAddrCity: 'N TONAWANDA',
        RegAddrState: 'NY',
        RegAddrZip: 14120,
        CongressionalDistrict: 27,
      },
      {
        RegAddrLine1: '2496 REYNOLDS RD',
        RegAddrCity: 'NIAGARA FALLS',
        RegAddrState: 'NY',
        RegAddrZip: 14304,
        CongressionalDistrict: 27,
      },
      {
        RegAddrLine1: '25 OLD FARM RD',
        RegAddrCity: 'ORCHARD PARK',
        RegAddrState: 'NY',
        RegAddrZip: 14127,
        CongressionalDistrict: 27,
      },
      {
        RegAddrLine1: '33 SPRING ST',
        RegAddrCity: 'PERRY',
        RegAddrState: 'NY',
        RegAddrZip: 14530,
        CongressionalDistrict: 27,
      },
      {
        RegAddrLine1: '2 FISHERS HILL TOP DR',
        RegAddrCity: 'PITTSFORD',
        RegAddrState: 'NY',
        RegAddrZip: 14534,
        CongressionalDistrict: 27,
      },
      {
        RegAddrLine1: '2247 MANLEY RD',
        RegAddrCity: 'STRYKERSVILLE',
        RegAddrState: 'NY',
        RegAddrZip: 14145,
        CongressionalDistrict: 27,
      },
      {
        RegAddrLine1: '1349 FIELDCREST LN',
        RegAddrCity: 'VICTOR',
        RegAddrState: 'NY',
        RegAddrZip: 14564,
        CongressionalDistrict: 27,
      },
      {
        RegAddrLine1: '371 BRIDLEWOOD LN',
        RegAddrCity: 'VICTOR',
        RegAddrState: 'NY',
        RegAddrZip: 14564,
        CongressionalDistrict: 27,
      },
      {
        RegAddrLine1: '115 WESTGATE BLVD',
        RegAddrCity: 'WEST SENECA',
        RegAddrState: 'NY',
        RegAddrZip: 14224,
        CongressionalDistrict: 27,
      },
    ],
  },
  {
    name: 'NY-3',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/NY-3?ref=gh-pages',
    state: 'NY',
    state_and_number: 'NY-3',
    zip: 11001,
    congressionalDistrict: 3,
    addressesInDistrict: [
      {
        RegAddrLine1: '54 DORSET AVE',
        RegAddrCity: 'ALBERTSON',
        RegAddrState: 'NY',
        RegAddrZip: 11507,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1856 CORPORAL KENNEDY ST',
        RegAddrCity: 'BAYSIDE',
        RegAddrState: 'NY',
        RegAddrZip: 11360,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '11 BAYVIEW AVE',
        RegAddrCity: 'BAYVILLE',
        RegAddrState: 'NY',
        RegAddrZip: 11709,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '9 EDNA AVE',
        RegAddrCity: 'BETHPAGE',
        RegAddrState: 'NY',
        RegAddrZip: 11714,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '104 TAYLOR ST',
        RegAddrCity: 'CENTERPORT',
        RegAddrState: 'NY',
        RegAddrZip: 11721,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '3 KEVIN RD',
        RegAddrCity: 'COMMACK',
        RegAddrState: 'NY',
        RegAddrZip: 11725,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '122 WICKS RD',
        RegAddrCity: 'COMMACK',
        RegAddrState: 'NY',
        RegAddrZip: 11725,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '5 REDWOOD DR',
        RegAddrCity: 'DIX HILLS',
        RegAddrState: 'NY',
        RegAddrZip: 11746,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '22 SABRINA CT',
        RegAddrCity: 'DIX HILLS',
        RegAddrState: 'NY',
        RegAddrZip: 11746,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '477 ELWOOD RD',
        RegAddrCity: 'E NORTHPORT',
        RegAddrState: 'NY',
        RegAddrZip: 11731,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '513 3RD AVE',
        RegAddrCity: 'E NORTHPORT',
        RegAddrState: 'NY',
        RegAddrZip: 11731,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '11 PORTER PL',
        RegAddrCity: 'GLEN COVE',
        RegAddrState: 'NY',
        RegAddrZip: 11542,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '46 WASHINGTON AVE',
        RegAddrCity: 'GLEN HEAD',
        RegAddrState: 'NY',
        RegAddrZip: 11545,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '7718 LITTLE NECK PKWY',
        RegAddrCity: 'GLEN OAKS',
        RegAddrState: 'NY',
        RegAddrZip: 11004,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '271-20 77TH AVE',
        RegAddrCity: 'GLEN OAKS',
        RegAddrState: 'NY',
        RegAddrZip: 11004,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '71 WESTMINSTER RD',
        RegAddrCity: 'GREAT NECK',
        RegAddrState: 'NY',
        RegAddrZip: 11020,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '18 STATION RD',
        RegAddrCity: 'GREAT NECK',
        RegAddrState: 'NY',
        RegAddrZip: 11023,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '49 RED BROOK RD',
        RegAddrCity: 'GREAT NECK',
        RegAddrState: 'NY',
        RegAddrZip: 11024,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '30 FULTON AVE',
        RegAddrCity: 'HICKSVILLE',
        RegAddrState: 'NY',
        RegAddrZip: 11801,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '238 OHIO ST',
        RegAddrCity: 'HICKSVILLE',
        RegAddrState: 'NY',
        RegAddrZip: 11801,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '18 7TH AVE N',
        RegAddrCity: 'HUNTINGTN STA',
        RegAddrState: 'NY',
        RegAddrZip: 11746,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '58 FOLSOM AVE',
        RegAddrCity: 'HUNTINGTN STA',
        RegAddrState: 'NY',
        RegAddrZip: 11746,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '7 OAK HILL RD',
        RegAddrCity: 'HUNTINGTON',
        RegAddrState: 'NY',
        RegAddrZip: 11743,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '69 ELMTREE LN',
        RegAddrCity: 'JERICHO',
        RegAddrState: 'NY',
        RegAddrZip: 11753,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '205 BEVERLY RD',
        RegAddrCity: 'LITTLE NECK',
        RegAddrState: 'NY',
        RegAddrZip: 11363,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '37 LORDS WAY',
        RegAddrCity: 'NEW HYDE PARK',
        RegAddrState: 'NY',
        RegAddrZip: 11040,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '18 FOREST DR',
        RegAddrCity: 'PLAINVIEW',
        RegAddrState: 'NY',
        RegAddrZip: 11803,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '2 ARIEL CT',
        RegAddrCity: 'PRT WASHINGTN',
        RegAddrState: 'NY',
        RegAddrZip: 11050,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '50 SPRUCE DR',
        RegAddrCity: 'ROSLYN',
        RegAddrState: 'NY',
        RegAddrZip: 11576,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '25 BONWIT RD',
        RegAddrCity: 'RYE BROOK',
        RegAddrState: 'NY',
        RegAddrZip: 10573,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '26 LINCREST ST',
        RegAddrCity: 'SYOSSET',
        RegAddrState: 'NY',
        RegAddrZip: 11791,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1584 LAUREL HOLLOW RD',
        RegAddrCity: 'SYOSSET',
        RegAddrState: 'NY',
        RegAddrZip: 11791,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '14727 7TH AVE',
        RegAddrCity: 'WHITESTONE',
        RegAddrState: 'NY',
        RegAddrZip: 11357,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '15039 8TH AVE',
        RegAddrCity: 'WHITESTONE',
        RegAddrState: 'NY',
        RegAddrZip: 11357,
        CongressionalDistrict: 3,
      },
    ],
  },
  {
    name: 'NY-4',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/NY-4?ref=gh-pages',
    state: 'NY',
    state_and_number: 'NY-4',
    zip: 11003,
    congressionalDistrict: 4,
    addressesInDistrict: [
      {
        RegAddrLine1: '1503 GRAND AVE',
        RegAddrCity: 'BALDWIN',
        RegAddrState: 'NY',
        RegAddrZip: 11510,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2303 MILBURN AVE',
        RegAddrCity: 'BALDWIN',
        RegAddrState: 'NY',
        RegAddrZip: 11510,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '717 SALISBURY PARK DR',
        RegAddrCity: 'EAST MEADOW',
        RegAddrState: 'NY',
        RegAddrZip: 11554,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '17 DIAMOND AVE',
        RegAddrCity: 'EAST MEADOW',
        RegAddrState: 'NY',
        RegAddrZip: 11554,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '21 MARTHA TER',
        RegAddrCity: 'FLORAL PARK',
        RegAddrState: 'NY',
        RegAddrZip: 11001,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '333 CHILDS AVE',
        RegAddrCity: 'GARDEN CITY',
        RegAddrState: 'NY',
        RegAddrZip: 11530,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '117 W FULTON ST',
        RegAddrCity: 'LONG BEACH',
        RegAddrState: 'NY',
        RegAddrZip: 11561,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '12 GATES AVE',
        RegAddrCity: 'MALVERNE',
        RegAddrState: 'NY',
        RegAddrZip: 11565,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '10 NASSAU BLVD',
        RegAddrCity: 'MALVERNE',
        RegAddrState: 'NY',
        RegAddrZip: 11565,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '8 VAN ROO AVE',
        RegAddrCity: 'MERRICK',
        RegAddrState: 'NY',
        RegAddrZip: 11566,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '254 ROYAL AVE',
        RegAddrCity: 'MINEOLA',
        RegAddrState: 'NY',
        RegAddrZip: 11501,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '16 REGINA AVE',
        RegAddrCity: 'N BELLMORE',
        RegAddrState: 'NY',
        RegAddrZip: 11710,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '916 JAY DR',
        RegAddrCity: 'N BELLMORE',
        RegAddrState: 'NY',
        RegAddrZip: 11710,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1795 NEW YORK AVE',
        RegAddrCity: 'N BELLMORE',
        RegAddrState: 'NY',
        RegAddrZip: 11710,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '3328 5TH ST',
        RegAddrCity: 'OCEANSIDE',
        RegAddrState: 'NY',
        RegAddrZip: 11572,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2767 ESSEX CT',
        RegAddrCity: 'OCEANSIDE',
        RegAddrState: 'NY',
        RegAddrZip: 11572,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '523 JERUSALEM AVE',
        RegAddrCity: 'UNIONDALE',
        RegAddrState: 'NY',
        RegAddrZip: 11553,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '844 ARTHUR ST',
        RegAddrCity: 'W HEMPSTEAD',
        RegAddrState: 'NY',
        RegAddrZip: 11552,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '121 TRINITY PL',
        RegAddrCity: 'W HEMPSTEAD',
        RegAddrState: 'NY',
        RegAddrZip: 11552,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '50 CENTRE ST',
        RegAddrCity: 'WOODMERE',
        RegAddrState: 'NY',
        RegAddrZip: 11598,
        CongressionalDistrict: 4,
      },
    ],
  },
  {
    name: 'NY-5',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/NY-5?ref=gh-pages',
    state: 'NY',
    state_and_number: 'NY-5',
    zip: 11366,
    congressionalDistrict: 5,
    addressesInDistrict: [
      {
        RegAddrLine1: '11715 224TH ST',
        RegAddrCity: 'CAMBRIA HTS',
        RegAddrState: 'NY',
        RegAddrZip: 11411,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '91 WALDORF AVE',
        RegAddrCity: 'ELMONT',
        RegAddrState: 'NY',
        RegAddrZip: 11003,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '20009 100TH AVE',
        RegAddrCity: 'HOLLIS',
        RegAddrState: 'NY',
        RegAddrZip: 11423,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '8815 164TH ST',
        RegAddrCity: 'JAMAICA',
        RegAddrState: 'NY',
        RegAddrZip: 11432,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '15547 116TH RD',
        RegAddrCity: 'JAMAICA',
        RegAddrState: 'NY',
        RegAddrZip: 11434,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '15029 FOCH BLVD',
        RegAddrCity: 'JAMAICA',
        RegAddrState: 'NY',
        RegAddrZip: 11434,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '8832 216TH ST',
        RegAddrCity: 'QUEENS VLG',
        RegAddrState: 'NY',
        RegAddrZip: 11427,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '137 BEACH 127TH ST',
        RegAddrCity: 'ROCKAWAY PARK',
        RegAddrState: 'NY',
        RegAddrZip: 11694,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '14407 249TH ST',
        RegAddrCity: 'ROSEDALE',
        RegAddrState: 'NY',
        RegAddrZip: 11422,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '11142 113TH ST',
        RegAddrCity: 'S OZONE PARK',
        RegAddrState: 'NY',
        RegAddrZip: 11420,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '11124 128TH ST',
        RegAddrCity: 'S OZONE PARK',
        RegAddrState: 'NY',
        RegAddrZip: 11420,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '10320 127TH ST',
        RegAddrCity: 'S RICHMOND HL',
        RegAddrState: 'NY',
        RegAddrZip: 11419,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '20007 116TH AVE',
        RegAddrCity: 'SAINT ALBANS',
        RegAddrState: 'NY',
        RegAddrZip: 11412,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '11217 203RD ST',
        RegAddrCity: 'SAINT ALBANS',
        RegAddrState: 'NY',
        RegAddrZip: 11412,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '14428 230TH ST',
        RegAddrCity: 'SPRINGFIELD GARDENS',
        RegAddrState: 'NY',
        RegAddrZip: 11413,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '23115 125TH AVE',
        RegAddrCity: 'SPRNGFLD GDNS',
        RegAddrState: 'NY',
        RegAddrZip: 11413,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '145114 225TH ST',
        RegAddrCity: 'SPRNGFLD GDNS',
        RegAddrState: 'NY',
        RegAddrZip: 11413,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '1599 GREENWAY BLVD',
        RegAddrCity: 'VALLEY STREAM',
        RegAddrState: 'NY',
        RegAddrZip: 11580,
        CongressionalDistrict: 5,
      },
    ],
  },
  {
    name: 'NY-6',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/NY-6?ref=gh-pages',
    state: 'NY',
    state_and_number: 'NY-6',
    zip: 11351,
    congressionalDistrict: 6,
    addressesInDistrict: [
      {
        RegAddrLine1: '8313 DONGAN AVE',
        RegAddrCity: 'ELMHURST',
        RegAddrState: 'NY',
        RegAddrZip: 11373,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '4635 193RD ST',
        RegAddrCity: 'FLUSHING',
        RegAddrState: 'NY',
        RegAddrZip: 11358,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '8309 170TH ST',
        RegAddrCity: 'JAMAICA',
        RegAddrState: 'NY',
        RegAddrZip: 11432,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '6520 80TH ST',
        RegAddrCity: 'MIDDLE VLG',
        RegAddrState: 'NY',
        RegAddrZip: 11379,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '6424 CLOVERDALE BLVD',
        RegAddrCity: 'OAKLAND GDNS',
        RegAddrState: 'NY',
        RegAddrZip: 11364,
        CongressionalDistrict: 6,
      },
    ],
  },
  {
    name: 'NY-7',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/NY-7?ref=gh-pages',
    state: 'NY',
    state_and_number: 'NY-7',
    zip: 10002,
    congressionalDistrict: 7,
    addressesInDistrict: [
      {
        RegAddrLine1: '48 CEDAR ST',
        RegAddrCity: 'BROOKLYN',
        RegAddrState: 'NY',
        RegAddrZip: 11221,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '215 CORNELIA ST',
        RegAddrCity: 'BROOKLYN',
        RegAddrState: 'NY',
        RegAddrZip: 11221,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '7611 97TH AVE',
        RegAddrCity: 'OZONE PARK',
        RegAddrState: 'NY',
        RegAddrZip: 11416,
        CongressionalDistrict: 7,
      },
    ],
  },
  {
    name: 'NY-8',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/NY-8?ref=gh-pages',
    state: 'NY',
    state_and_number: 'NY-8',
    zip: 11210,
    congressionalDistrict: 8,
    addressesInDistrict: [
      {
        RegAddrLine1: '33 CLERMONT AVE',
        RegAddrCity: 'BROOKLYN',
        RegAddrState: 'NY',
        RegAddrZip: 11205,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '245 ADELPHI ST',
        RegAddrCity: 'BROOKLYN',
        RegAddrState: 'NY',
        RegAddrZip: 11205,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '260 VERNON AVE',
        RegAddrCity: 'BROOKLYN',
        RegAddrState: 'NY',
        RegAddrZip: 11206,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '80A ABERDEEN ST',
        RegAddrCity: 'BROOKLYN',
        RegAddrState: 'NY',
        RegAddrZip: 11207,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '544 MADISON ST',
        RegAddrCity: 'BROOKLYN',
        RegAddrState: 'NY',
        RegAddrZip: 11221,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '7524 AVENUE X',
        RegAddrCity: 'BROOKLYN',
        RegAddrState: 'NY',
        RegAddrZip: 11234,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '1228 E 82ND ST',
        RegAddrCity: 'BROOKLYN',
        RegAddrState: 'NY',
        RegAddrZip: 11236,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '1230 E 85TH ST',
        RegAddrCity: 'BROOKLYN',
        RegAddrState: 'NY',
        RegAddrZip: 11236,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '1280 E 93RD ST',
        RegAddrCity: 'BROOKLYN',
        RegAddrState: 'NY',
        RegAddrZip: 11236,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '366 ST MARKS AVE 3L',
        RegAddrCity: 'BROOKLYN',
        RegAddrState: 'NY',
        RegAddrZip: 11238,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '15515 99TH ST',
        RegAddrCity: 'HOWARD BEACH',
        RegAddrState: 'NY',
        RegAddrZip: 11414,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '9627 ROCKAWAY BLVD',
        RegAddrCity: 'OZONE PARK',
        RegAddrState: 'NY',
        RegAddrZip: 11417,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '9616 133RD AVE',
        RegAddrCity: 'OZONE PARK',
        RegAddrState: 'NY',
        RegAddrZip: 11417,
        CongressionalDistrict: 8,
      },
    ],
  },
  {
    name: 'NY-9',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/NY-9?ref=gh-pages',
    state: 'NY',
    state_and_number: 'NY-9',
    zip: 11203,
    congressionalDistrict: 9,
    addressesInDistrict: [
      {
        RegAddrLine1: '273 E 43RD ST',
        RegAddrCity: 'BROOKLYN',
        RegAddrState: 'NY',
        RegAddrZip: 11203,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '831 E 45TH ST',
        RegAddrCity: 'BROOKLYN',
        RegAddrState: 'NY',
        RegAddrZip: 11203,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '1234 E 34TH ST',
        RegAddrCity: 'BROOKLYN',
        RegAddrState: 'NY',
        RegAddrZip: 11210,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '3401 AVENUE K',
        RegAddrCity: 'BROOKLYN',
        RegAddrState: 'NY',
        RegAddrZip: 11210,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '1276 PRESIDENT ST',
        RegAddrCity: 'BROOKLYN',
        RegAddrState: 'NY',
        RegAddrZip: 11213,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '2330 E 29TH ST',
        RegAddrCity: 'BROOKLYN',
        RegAddrState: 'NY',
        RegAddrZip: 11229,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '969 E 9TH ST',
        RegAddrCity: 'BROOKLYN',
        RegAddrState: 'NY',
        RegAddrZip: 11230,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '765 E 18TH ST',
        RegAddrCity: 'BROOKLYN',
        RegAddrState: 'NY',
        RegAddrZip: 11230,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '2612 AVENUE Y',
        RegAddrCity: 'BROOKLYN',
        RegAddrState: 'NY',
        RegAddrZip: 11235,
        CongressionalDistrict: 9,
      },
    ],
  },
  {
    name: 'OH-1',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/OH-1?ref=gh-pages',
    state: 'OH',
    state_and_number: 'OH-1',
    zip: 45001,
    congressionalDistrict: 1,
    addressesInDistrict: [
      {
        RegAddrLine1: '7880 STONEHOUSE CT',
        RegAddrCity: 'CENTERVILLE',
        RegAddrState: 'OH',
        RegAddrZip: 45459,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1213 FAIRBANKS AVE',
        RegAddrCity: 'CINCINNATI',
        RegAddrState: 'OH',
        RegAddrZip: 45205,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '3333 GLENWAY AVE',
        RegAddrCity: 'CINCINNATI',
        RegAddrState: 'OH',
        RegAddrZip: 45205,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1006 FISK AVE',
        RegAddrCity: 'CINCINNATI',
        RegAddrState: 'OH',
        RegAddrZip: 45205,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '466 W MCMICKEN AVE',
        RegAddrCity: 'CINCINNATI',
        RegAddrState: 'OH',
        RegAddrZip: 45214,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1201 DIXIE CT',
        RegAddrCity: 'CINCINNATI',
        RegAddrState: 'OH',
        RegAddrZip: 45215,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1742 LAURELWOOD CIR',
        RegAddrCity: 'CINCINNATI',
        RegAddrState: 'OH',
        RegAddrZip: 45224,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2474 WILLIAMSBURG DR',
        RegAddrCity: 'CINCINNATI',
        RegAddrState: 'OH',
        RegAddrZip: 45225,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1159 HICKORYLAKE DR',
        RegAddrCity: 'CINCINNATI',
        RegAddrState: 'OH',
        RegAddrZip: 45233,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '3496 JESSUP RD',
        RegAddrCity: 'CINCINNATI',
        RegAddrState: 'OH',
        RegAddrZip: 45239,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1045 HERITAGE LAKE DR',
        RegAddrCity: 'CINCINNATI',
        RegAddrState: 'OH',
        RegAddrZip: 45242,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '651 CHARWOOD DR',
        RegAddrCity: 'CINCINNATI',
        RegAddrState: 'OH',
        RegAddrZip: 45244,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '6024 W FORK RD',
        RegAddrCity: 'CINCINNATI',
        RegAddrState: 'OH',
        RegAddrZip: 45247,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '6099 THOMPSON RD',
        RegAddrCity: 'CINCINNATI',
        RegAddrState: 'OH',
        RegAddrZip: 45247,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '4751 EBENEZER RD',
        RegAddrCity: 'CINCINNATI',
        RegAddrState: 'OH',
        RegAddrZip: 45248,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '11540 IRONLIEGE LN',
        RegAddrCity: 'CINCINNATI',
        RegAddrState: 'OH',
        RegAddrZip: 45249,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '10020 WINDSWEPT LN',
        RegAddrCity: 'CINCINNATI',
        RegAddrState: 'OH',
        RegAddrZip: 45251,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '8238 JORDAN RIDGE DR',
        RegAddrCity: 'CLEVES',
        RegAddrState: 'OH',
        RegAddrZip: 45002,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '7151 FORT SCOTT BLVD',
        RegAddrCity: 'HARRISON',
        RegAddrState: 'OH',
        RegAddrZip: 45030,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '10414 HARRISON RD',
        RegAddrCity: 'LOVELAND',
        RegAddrState: 'OH',
        RegAddrZip: 45140,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '918 OVERBROOK AVE',
        RegAddrCity: 'MAINEVILLE',
        RegAddrState: 'OH',
        RegAddrZip: 45039,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '5611 GLEN OAK',
        RegAddrCity: 'MASON',
        RegAddrState: 'OH',
        RegAddrZip: 45040,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '4258 N HAVEN DR',
        RegAddrCity: 'MASON',
        RegAddrState: 'OH',
        RegAddrZip: 45040,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '3050 COUNTRYSIDE LN',
        RegAddrCity: 'MIAMISBURG',
        RegAddrState: 'OH',
        RegAddrZip: 45342,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '364 2ND ST',
        RegAddrCity: 'MORROW',
        RegAddrState: 'OH',
        RegAddrZip: 45152,
        CongressionalDistrict: 1,
      },
    ],
  },
  {
    name: 'OH-10',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/OH-10?ref=gh-pages',
    state: 'OH',
    state_and_number: 'OH-10',
    zip: 43106,
    congressionalDistrict: 10,
    addressesInDistrict: [
      {
        RegAddrLine1: '7774 PLEASANT PLAIN RD',
        RegAddrCity: 'BROOKVILLE',
        RegAddrState: 'OH',
        RegAddrZip: 45309,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '361 SHIRLEY ANN DR',
        RegAddrCity: 'CENTERVILLE',
        RegAddrState: 'OH',
        RegAddrZip: 45458,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '1020 STILLCREEK DR',
        RegAddrCity: 'CENTERVILLE',
        RegAddrState: 'OH',
        RegAddrZip: 45458,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '1705 WOODLEY RD',
        RegAddrCity: 'DAYTON',
        RegAddrState: 'OH',
        RegAddrZip: 45403,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '407 SANDALWOOD DR',
        RegAddrCity: 'DAYTON',
        RegAddrState: 'OH',
        RegAddrZip: 45405,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '1615 EARLHAM DR',
        RegAddrCity: 'DAYTON',
        RegAddrState: 'OH',
        RegAddrZip: 45406,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '210 EDGAR AVE',
        RegAddrCity: 'DAYTON',
        RegAddrState: 'OH',
        RegAddrZip: 45410,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '1166 LANSDALE CT',
        RegAddrCity: 'DAYTON',
        RegAddrState: 'OH',
        RegAddrZip: 45414,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '7076 QUAKER TRACE CT',
        RegAddrCity: 'DAYTON',
        RegAddrState: 'OH',
        RegAddrZip: 45414,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '1886 PALISADES DR',
        RegAddrCity: 'DAYTON',
        RegAddrState: 'OH',
        RegAddrZip: 45414,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '6051 RANGEVIEW DR',
        RegAddrCity: 'DAYTON',
        RegAddrState: 'OH',
        RegAddrZip: 45415,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '4266 CROWNWOOD AVE',
        RegAddrCity: 'DAYTON',
        RegAddrState: 'OH',
        RegAddrZip: 45415,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '5789 WIN DR',
        RegAddrCity: 'DAYTON',
        RegAddrState: 'OH',
        RegAddrZip: 45415,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '5108 ROBB CT',
        RegAddrCity: 'DAYTON',
        RegAddrState: 'OH',
        RegAddrZip: 45417,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '600 WOODS RD',
        RegAddrCity: 'DAYTON',
        RegAddrState: 'OH',
        RegAddrZip: 45419,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '2837 BLACKHAWK RD',
        RegAddrCity: 'DAYTON',
        RegAddrState: 'OH',
        RegAddrZip: 45420,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '4083 RUSHTON DR',
        RegAddrCity: 'DAYTON',
        RegAddrState: 'OH',
        RegAddrZip: 45431,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '300 COLLEGE PARK AVE',
        RegAddrCity: 'DAYTON',
        RegAddrState: 'OH',
        RegAddrZip: 45469,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '1222 COTTAGE COURT DR',
        RegAddrCity: 'FAIRBORN',
        RegAddrState: 'OH',
        RegAddrZip: 45324,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '1103 OAKHILL AVE',
        RegAddrCity: 'FAIRBORN',
        RegAddrState: 'OH',
        RegAddrZip: 45324,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '3681 SHAWNEE TRL',
        RegAddrCity: 'JAMESTOWN',
        RegAddrState: 'OH',
        RegAddrZip: 45335,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '1500 BERWIN AVE',
        RegAddrCity: 'KETTERING',
        RegAddrState: 'OH',
        RegAddrZip: 45429,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '3005 SUTTON AVE',
        RegAddrCity: 'KETTERING',
        RegAddrState: 'OH',
        RegAddrZip: 45429,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '249 AVON WAY',
        RegAddrCity: 'KETTERING',
        RegAddrState: 'OH',
        RegAddrZip: 45429,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '5245 UPPERTON DR',
        RegAddrCity: 'MIAMISBURG',
        RegAddrState: 'OH',
        RegAddrZip: 45342,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '9493 ARBORIDGE LN',
        RegAddrCity: 'MIAMISBURG',
        RegAddrState: 'OH',
        RegAddrZip: 45342,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '9382 VAUGHN RD',
        RegAddrCity: 'MIAMISBURG',
        RegAddrState: 'OH',
        RegAddrZip: 45342,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '806 E MAIN ST',
        RegAddrCity: 'XENIA',
        RegAddrState: 'OH',
        RegAddrZip: 45385,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '398 CHESTNUT ST',
        RegAddrCity: 'XENIA',
        RegAddrState: 'OH',
        RegAddrZip: 45385,
        CongressionalDistrict: 10,
      },
    ],
  },
  {
    name: 'OH-11',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/OH-11?ref=gh-pages',
    state: 'OH',
    state_and_number: 'OH-11',
    zip: 44022,
    congressionalDistrict: 11,
    addressesInDistrict: [
      {
        RegAddrLine1: '399 SILVER ST',
        RegAddrCity: 'AKRON',
        RegAddrState: 'OH',
        RegAddrZip: 44303,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '488 MORELEY AVE',
        RegAddrCity: 'AKRON',
        RegAddrState: 'OH',
        RegAddrZip: 44320,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '24015 E SILSBY RD',
        RegAddrCity: 'BEACHWOOD',
        RegAddrState: 'OH',
        RegAddrZip: 44122,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '3850 BEECHMONT OVAL',
        RegAddrCity: 'BEACHWOOD',
        RegAddrState: 'OH',
        RegAddrZip: 44122,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '24405 ELDRIDGE BLVD',
        RegAddrCity: 'BEDFORD HTS',
        RegAddrState: 'OH',
        RegAddrZip: 44146,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '8013 LONGVIEW RD',
        RegAddrCity: 'BROADVIEW HTS',
        RegAddrState: 'OH',
        RegAddrZip: 44147,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '7946 MCCREARY RD',
        RegAddrCity: 'BROADVIEW HTS',
        RegAddrState: 'OH',
        RegAddrZip: 44147,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '2051 W 87TH ST',
        RegAddrCity: 'CLEVELAND',
        RegAddrState: 'OH',
        RegAddrZip: 44102,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '2947 E 111TH ST',
        RegAddrCity: 'CLEVELAND',
        RegAddrState: 'OH',
        RegAddrZip: 44104,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '636 E 131ST ST',
        RegAddrCity: 'CLEVELAND',
        RegAddrState: 'OH',
        RegAddrZip: 44108,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '2219 PAYNE AVE',
        RegAddrCity: 'CLEVELAND',
        RegAddrState: 'OH',
        RegAddrZip: 44114,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '1744 E 37TH ST',
        RegAddrCity: 'CLEVELAND',
        RegAddrState: 'OH',
        RegAddrZip: 44114,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '18114 NEFF RD',
        RegAddrCity: 'CLEVELAND',
        RegAddrState: 'OH',
        RegAddrZip: 44119,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '2829 E 116TH ST',
        RegAddrCity: 'CLEVELAND',
        RegAddrState: 'OH',
        RegAddrZip: 44120,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '14526 SUPERIOR RD',
        RegAddrCity: 'CLEVELAND HTS',
        RegAddrState: 'OH',
        RegAddrZip: 44118,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '25445 EUCLID AVE',
        RegAddrCity: 'EUCLID',
        RegAddrState: 'OH',
        RegAddrZip: 44117,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '280 E 270TH ST',
        RegAddrCity: 'EUCLID',
        RegAddrState: 'OH',
        RegAddrZip: 44132,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '25250 BRIARDALE AVE',
        RegAddrCity: 'EUCLID',
        RegAddrState: 'OH',
        RegAddrZip: 44132,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '2946 GREENSPIRE CIR',
        RegAddrCity: 'FAIRLAWN',
        RegAddrState: 'OH',
        RegAddrZip: 44333,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '320 GRETNA GREEN DR',
        RegAddrCity: 'HIGHLAND HTS',
        RegAddrState: 'OH',
        RegAddrZip: 44143,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '19517 FAIRWAY AVE',
        RegAddrCity: 'MAPLE HEIGHTS',
        RegAddrState: 'OH',
        RegAddrZip: 44137,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '4001 E 54TH ST',
        RegAddrCity: 'NEWBURGH HTS',
        RegAddrState: 'OH',
        RegAddrZip: 44105,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '18210 FERNWAY RD',
        RegAddrCity: 'SHAKER HEIGHTS',
        RegAddrState: 'OH',
        RegAddrZip: 44122,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '21700 HALWORTH RD',
        RegAddrCity: 'SHAKER HTS',
        RegAddrState: 'OH',
        RegAddrZip: 44122,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '2607 DYSART RD',
        RegAddrCity: 'UNIVERSITY HEIGHTS',
        RegAddrState: 'OH',
        RegAddrZip: 44118,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '19313 BROOKFIELD LN',
        RegAddrCity: 'WARRENSVILLE HTS',
        RegAddrState: 'OH',
        RegAddrZip: 44122,
        CongressionalDistrict: 11,
      },
    ],
  },
  {
    name: 'OH-12',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/OH-12?ref=gh-pages',
    state: 'OH',
    state_and_number: 'OH-12',
    zip: 43001,
    congressionalDistrict: 12,
    addressesInDistrict: [
      {
        RegAddrLine1: '7041 BOLD FORBES CT',
        RegAddrCity: 'BLACKLICK',
        RegAddrState: 'OH',
        RegAddrZip: 43004,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '5204 ROBERTS RD',
        RegAddrCity: 'CALEDONIA',
        RegAddrState: 'OH',
        RegAddrZip: 43314,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '2856 FINDLEY AVE',
        RegAddrCity: 'COLUMBUS',
        RegAddrState: 'OH',
        RegAddrZip: 43202,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '89 W LAKEVIEW AVE',
        RegAddrCity: 'COLUMBUS',
        RegAddrState: 'OH',
        RegAddrZip: 43202,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '909 DEACON CIR',
        RegAddrCity: 'COLUMBUS',
        RegAddrState: 'OH',
        RegAddrZip: 43214,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '4999 FOX DR',
        RegAddrCity: 'COLUMBUS',
        RegAddrState: 'OH',
        RegAddrZip: 43220,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '1162 BAUMOCK BURN DR',
        RegAddrCity: 'COLUMBUS',
        RegAddrState: 'OH',
        RegAddrZip: 43235,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '1395 BEECHLAKE DR',
        RegAddrCity: 'COLUMBUS',
        RegAddrState: 'OH',
        RegAddrZip: 43235,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '199 VILLAGE GATE BLVD',
        RegAddrCity: 'DELAWARE',
        RegAddrState: 'OH',
        RegAddrZip: 43015,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '246 GRISWOLD ST',
        RegAddrCity: 'DELAWARE',
        RegAddrState: 'OH',
        RegAddrZip: 43015,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '5802 TUTTLES GROVE BLVD',
        RegAddrCity: 'DUBLIN',
        RegAddrState: 'OH',
        RegAddrZip: 43016,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '6398 MONAGHAN DR',
        RegAddrCity: 'DUBLIN',
        RegAddrState: 'OH',
        RegAddrZip: 43016,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '6277 MUIRLOCH CT S',
        RegAddrCity: 'DUBLIN',
        RegAddrState: 'OH',
        RegAddrZip: 43017,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '3544 S OLD 3C HWY',
        RegAddrCity: 'GALENA',
        RegAddrState: 'OH',
        RegAddrZip: 43021,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '191 STRAUB RD W',
        RegAddrCity: 'MANSFIELD',
        RegAddrState: 'OH',
        RegAddrZip: 44907,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '521 PRICE RD',
        RegAddrCity: 'NEWARK',
        RegAddrState: 'OH',
        RegAddrZip: 43055,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '63 3RD AVE SW',
        RegAddrCity: 'PATASKALA',
        RegAddrState: 'OH',
        RegAddrZip: 43062,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '2475 PATTERSON RD SW',
        RegAddrCity: 'PATASKALA',
        RegAddrState: 'OH',
        RegAddrZip: 43062,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '113 BROOKSEDGE DR',
        RegAddrCity: 'PATASKALA',
        RegAddrState: 'OH',
        RegAddrZip: 43062,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '4706 VILLAGE CLUB DR',
        RegAddrCity: 'POWELL',
        RegAddrState: 'OH',
        RegAddrZip: 43065,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '188 NORTHVIEW DR SW',
        RegAddrCity: 'REYNOLDSBURG',
        RegAddrState: 'OH',
        RegAddrZip: 43068,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '909 CUTHBERT CT',
        RegAddrCity: 'REYNOLDSBURG',
        RegAddrState: 'OH',
        RegAddrZip: 43068,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '4183 N 3 BS AND K RD',
        RegAddrCity: 'SUNBURY',
        RegAddrState: 'OH',
        RegAddrZip: 43074,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '6018 FOLLENSBY DR',
        RegAddrCity: 'WESTERVILLE',
        RegAddrState: 'OH',
        RegAddrZip: 43081,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '1012 DANVERS AVE',
        RegAddrCity: 'WESTERVILLE',
        RegAddrState: 'OH',
        RegAddrZip: 43081,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '5636 CENTRAL COLLEGE RD',
        RegAddrCity: 'WESTERVILLE',
        RegAddrState: 'OH',
        RegAddrZip: 43081,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '6555 SPRING RUN DR',
        RegAddrCity: 'WESTERVILLE',
        RegAddrState: 'OH',
        RegAddrZip: 43082,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '6754 CHARLES RD',
        RegAddrCity: 'WESTERVILLE',
        RegAddrState: 'OH',
        RegAddrZip: 43082,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '3940 STURTZ DR',
        RegAddrCity: 'ZANESVILLE',
        RegAddrState: 'OH',
        RegAddrZip: 43701,
        CongressionalDistrict: 12,
      },
    ],
  },
  {
    name: 'OH-13',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/OH-13?ref=gh-pages',
    state: 'OH',
    state_and_number: 'OH-13',
    zip: 16159,
    congressionalDistrict: 13,
    addressesInDistrict: [
      {
        RegAddrLine1: '1239 PONDVIEW AVE',
        RegAddrCity: 'AKRON',
        RegAddrState: 'OH',
        RegAddrZip: 44305,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '955 UTE AVE',
        RegAddrCity: 'AKRON',
        RegAddrState: 'OH',
        RegAddrZip: 44305,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '1090 TERRELL DR',
        RegAddrCity: 'AKRON',
        RegAddrState: 'OH',
        RegAddrZip: 44313,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '1710 LEIGHTON AVE',
        RegAddrCity: 'AKRON',
        RegAddrState: 'OH',
        RegAddrZip: 44314,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '904 SUTHERLAND AVE',
        RegAddrCity: 'AKRON',
        RegAddrState: 'OH',
        RegAddrZip: 44314,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '141 ALDRICH RD',
        RegAddrCity: 'AUSTINTOWN',
        RegAddrState: 'OH',
        RegAddrZip: 44515,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '335 COLLEGE ST',
        RegAddrCity: 'BARBERTON',
        RegAddrState: 'OH',
        RegAddrZip: 44203,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '480 WILDWOOD DR',
        RegAddrCity: 'BOARDMAN',
        RegAddrState: 'OH',
        RegAddrZip: 44512,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '143 SAINT ANDREWS',
        RegAddrCity: 'CORTLAND',
        RegAddrState: 'OH',
        RegAddrZip: 44410,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '600 TALLMADGE RD',
        RegAddrCity: 'CUYAHOGA FLS',
        RegAddrState: 'OH',
        RegAddrZip: 44221,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '864 N HIGHLAND AVE',
        RegAddrCity: 'GIRARD',
        RegAddrState: 'OH',
        RegAddrZip: 44420,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '2551 POPLAR ST',
        RegAddrCity: 'GIRARD',
        RegAddrState: 'OH',
        RegAddrZip: 44420,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '1174 W LIBERTY ST',
        RegAddrCity: 'HUBBARD',
        RegAddrState: 'OH',
        RegAddrZip: 44425,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '1017 WALNUT ST',
        RegAddrCity: 'KENT',
        RegAddrState: 'OH',
        RegAddrZip: 44240,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '497 HARVEY ST',
        RegAddrCity: 'KENT',
        RegAddrState: 'OH',
        RegAddrZip: 44240,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '550 SENHAUSER DR',
        RegAddrCity: 'KENT',
        RegAddrState: 'OH',
        RegAddrZip: 44243,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '65 DUNCAN DR',
        RegAddrCity: 'POLAND',
        RegAddrState: 'OH',
        RegAddrZip: 44514,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '231 CREED ST',
        RegAddrCity: 'STRUTHERS',
        RegAddrState: 'OH',
        RegAddrZip: 44471,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '584 IDORA LN',
        RegAddrCity: 'STRUTHERS',
        RegAddrState: 'OH',
        RegAddrZip: 44471,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '5530 WALNUT GROVE CIR',
        RegAddrCity: 'STRUTHERS',
        RegAddrState: 'OH',
        RegAddrZip: 44471,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '2048 SODOM HUTCHINGS RD NE',
        RegAddrCity: 'VIENNA',
        RegAddrState: 'OH',
        RegAddrZip: 44473,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '1320 MAHONING AVE NW',
        RegAddrCity: 'WARREN',
        RegAddrState: 'OH',
        RegAddrZip: 44483,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '3268 TOD AVE NW',
        RegAddrCity: 'WARREN',
        RegAddrState: 'OH',
        RegAddrZip: 44485,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '622 COMMERCE AVE NW',
        RegAddrCity: 'WARREN',
        RegAddrState: 'OH',
        RegAddrZip: 44485,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '1315 POLAND AVE',
        RegAddrCity: 'YOUNGSTOWN',
        RegAddrState: 'OH',
        RegAddrZip: 44502,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '3125 IDLEWOOD AVE',
        RegAddrCity: 'YOUNGSTOWN',
        RegAddrState: 'OH',
        RegAddrZip: 44511,
        CongressionalDistrict: 13,
      },
    ],
  },
  {
    name: 'OH-14',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/OH-14?ref=gh-pages',
    state: 'OH',
    state_and_number: 'OH-14',
    zip: 44003,
    congressionalDistrict: 14,
    addressesInDistrict: [
      {
        RegAddrLine1: '6397 STANHOPE KELLOGGSVILLE RD',
        RegAddrCity: 'ANDOVER',
        RegAddrState: 'OH',
        RegAddrZip: 44003,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '18071 CINNAMON TRL',
        RegAddrCity: 'AUBURN TWP',
        RegAddrState: 'OH',
        RegAddrZip: 44023,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '5553 FOREST LN',
        RegAddrCity: 'BRECKSVILLE',
        RegAddrState: 'OH',
        RegAddrZip: 44141,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '8944 CINNABAR DR',
        RegAddrCity: 'BRECKSVILLE',
        RegAddrState: 'OH',
        RegAddrZip: 44141,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '17781 AUBURN RD',
        RegAddrCity: 'CHAGRIN FALLS',
        RegAddrState: 'OH',
        RegAddrZip: 44023,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '3424 WILSON ST',
        RegAddrCity: 'CUYAHOGA FLS',
        RegAddrState: 'OH',
        RegAddrZip: 44221,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '1310 E 331ST ST',
        RegAddrCity: 'EASTLAKE',
        RegAddrState: 'OH',
        RegAddrZip: 44095,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '5537 S RIVER RD W',
        RegAddrCity: 'GENEVA',
        RegAddrState: 'OH',
        RegAddrZip: 44041,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '33 OWEN BROWN ST',
        RegAddrCity: 'HUDSON',
        RegAddrState: 'OH',
        RegAddrZip: 44236,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '1717 MAYFLOWER LN',
        RegAddrCity: 'HUDSON',
        RegAddrState: 'OH',
        RegAddrZip: 44236,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '2084 EAGLEVILLE RD',
        RegAddrCity: 'JEFFERSON',
        RegAddrState: 'OH',
        RegAddrZip: 44047,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '1336 W MORGAN RD',
        RegAddrCity: 'JEFFERSON',
        RegAddrState: 'OH',
        RegAddrZip: 44047,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '4751 BECKWITH RD',
        RegAddrCity: 'KINGSVILLE',
        RegAddrState: 'OH',
        RegAddrZip: 44048,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '1421 PARKVIEW DR',
        RegAddrCity: 'MADISON',
        RegAddrState: 'OH',
        RegAddrZip: 44057,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '10867 DIAGONAL RD',
        RegAddrCity: 'MANTUA',
        RegAddrState: 'OH',
        RegAddrZip: 44255,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '1341 ORCHARD HEIGHTS DR',
        RegAddrCity: 'MAYFIELD HTS',
        RegAddrState: 'OH',
        RegAddrZip: 44124,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '7599 MOUNTAIN PARK DR',
        RegAddrCity: 'MENTOR',
        RegAddrState: 'OH',
        RegAddrZip: 44060,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '7888 N BOYDEN RD',
        RegAddrCity: 'NORTHFIELD',
        RegAddrState: 'OH',
        RegAddrZip: 44067,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '9261 PEKIN RD',
        RegAddrCity: 'NOVELTY',
        RegAddrState: 'OH',
        RegAddrZip: 44072,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '54 PINEVIEW DR',
        RegAddrCity: 'PAINESVILLE',
        RegAddrState: 'OH',
        RegAddrZip: 44077,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '60 QUARTZ AVE',
        RegAddrCity: 'PENINSULA',
        RegAddrState: 'OH',
        RegAddrZip: 44264,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '6933 FEE RD',
        RegAddrCity: 'ROME',
        RegAddrState: 'OH',
        RegAddrZip: 44085,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '32517 SPRINGSIDE LN',
        RegAddrCity: 'SOLON',
        RegAddrState: 'OH',
        RegAddrZip: 44139,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '3195 WEXFORD BLVD',
        RegAddrCity: 'STOW',
        RegAddrState: 'OH',
        RegAddrZip: 44224,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '7072 SIDLEY RD',
        RegAddrCity: 'THOMPSON',
        RegAddrState: 'OH',
        RegAddrZip: 44086,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '14859 PAINESVILLE WARREN RD',
        RegAddrCity: 'THOMPSON',
        RegAddrState: 'OH',
        RegAddrZip: 44086,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '5177 STRAWBERRY LN',
        RegAddrCity: 'WILLOUGHBY',
        RegAddrState: 'OH',
        RegAddrZip: 44094,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '30330 FORESTGROVE RD',
        RegAddrCity: 'WILLOWICK',
        RegAddrState: 'OH',
        RegAddrZip: 44095,
        CongressionalDistrict: 14,
      },
    ],
  },
  {
    name: 'OH-15',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/OH-15?ref=gh-pages',
    state: 'OH',
    state_and_number: 'OH-15',
    zip: 43101,
    congressionalDistrict: 15,
    addressesInDistrict: [
      {
        RegAddrLine1: '2915 SENEY RD',
        RegAddrCity: 'CHILLICOTHE',
        RegAddrState: 'OH',
        RegAddrZip: 45601,
        CongressionalDistrict: 15,
      },
      {
        RegAddrLine1: '286 MACE DR',
        RegAddrCity: 'CIRCLEVILLE',
        RegAddrState: 'OH',
        RegAddrZip: 43113,
        CongressionalDistrict: 15,
      },
      {
        RegAddrLine1: '1627 COWAN CREEK RD',
        RegAddrCity: 'CLARKSVILLE',
        RegAddrState: 'OH',
        RegAddrZip: 45113,
        CongressionalDistrict: 15,
      },
      {
        RegAddrLine1: '247 E MITHOFF ST',
        RegAddrCity: 'COLUMBUS',
        RegAddrState: 'OH',
        RegAddrZip: 43206,
        CongressionalDistrict: 15,
      },
      {
        RegAddrLine1: '411 COLTON RD',
        RegAddrCity: 'COLUMBUS',
        RegAddrState: 'OH',
        RegAddrZip: 43207,
        CongressionalDistrict: 15,
      },
      {
        RegAddrLine1: '3832 FERGUS RD',
        RegAddrCity: 'COLUMBUS',
        RegAddrState: 'OH',
        RegAddrZip: 43207,
        CongressionalDistrict: 15,
      },
      {
        RegAddrLine1: '2615 NORTHWEST BLVD',
        RegAddrCity: 'COLUMBUS',
        RegAddrState: 'OH',
        RegAddrZip: 43221,
        CongressionalDistrict: 15,
      },
      {
        RegAddrLine1: '2245 SHADE CREEK RD',
        RegAddrCity: 'GUYSVILLE',
        RegAddrState: 'OH',
        RegAddrZip: 45735,
        CongressionalDistrict: 15,
      },
      {
        RegAddrLine1: '1637 CARDINAL HILL LN',
        RegAddrCity: 'HIDE A WAY HILLS',
        RegAddrState: 'OH',
        RegAddrZip: 43107,
        CongressionalDistrict: 15,
      },
      {
        RegAddrLine1: '2980 HONEYSUCKLE LN',
        RegAddrCity: 'HILLIARD',
        RegAddrState: 'OH',
        RegAddrZip: 43026,
        CongressionalDistrict: 15,
      },
      {
        RegAddrLine1: '4860 STATE ROUTE 37 W',
        RegAddrCity: 'JUNCTION CITY',
        RegAddrState: 'OH',
        RegAddrZip: 43748,
        CongressionalDistrict: 15,
      },
      {
        RegAddrLine1: '140 SHAWNEE DR',
        RegAddrCity: 'LANCASTER',
        RegAddrState: 'OH',
        RegAddrZip: 43130,
        CongressionalDistrict: 15,
      },
      {
        RegAddrLine1: '160 N PASCAL RD',
        RegAddrCity: 'MALTA',
        RegAddrState: 'OH',
        RegAddrZip: 43758,
        CongressionalDistrict: 15,
      },
      {
        RegAddrLine1: '59885 US HIGHWAY 50',
        RegAddrCity: 'MCARTHUR',
        RegAddrState: 'OH',
        RegAddrZip: 45651,
        CongressionalDistrict: 15,
      },
      {
        RegAddrLine1: '790 PRESTON TRAILS DR',
        RegAddrCity: 'PICKERINGTON',
        RegAddrState: 'OH',
        RegAddrZip: 43147,
        CongressionalDistrict: 15,
      },
      {
        RegAddrLine1: '109 ALBERT CT',
        RegAddrCity: 'PICKERINGTON',
        RegAddrState: 'OH',
        RegAddrZip: 43147,
        CongressionalDistrict: 15,
      },
      {
        RegAddrLine1: '10129 BERKSHIRE ST',
        RegAddrCity: 'PICKERINGTON',
        RegAddrState: 'OH',
        RegAddrZip: 43147,
        CongressionalDistrict: 15,
      },
      {
        RegAddrLine1: '6830 STEMEN RD',
        RegAddrCity: 'PICKERINGTON',
        RegAddrState: 'OH',
        RegAddrZip: 43147,
        CongressionalDistrict: 15,
      },
      {
        RegAddrLine1: '7095 WINNOW CT',
        RegAddrCity: 'REYNOLDSBURG',
        RegAddrState: 'OH',
        RegAddrZip: 43068,
        CongressionalDistrict: 15,
      },
      {
        RegAddrLine1: '205 VINE ST',
        RegAddrCity: 'SABINA',
        RegAddrState: 'OH',
        RegAddrZip: 45169,
        CongressionalDistrict: 15,
      },
      {
        RegAddrLine1: '5790 HURST HILL RD',
        RegAddrCity: 'SUGAR GROVE',
        RegAddrState: 'OH',
        RegAddrZip: 43155,
        CongressionalDistrict: 15,
      },
    ],
  },
  {
    name: 'OH-16',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/OH-16?ref=gh-pages',
    state: 'OH',
    state_and_number: 'OH-16',
    zip: 44070,
    congressionalDistrict: 16,
    addressesInDistrict: [
      {
        RegAddrLine1: '1087 BIG FALLS AVE',
        RegAddrCity: 'AKRON',
        RegAddrState: 'OH',
        RegAddrZip: 44310,
        CongressionalDistrict: 16,
      },
      {
        RegAddrLine1: '3788 S MAIN ST',
        RegAddrCity: 'AKRON',
        RegAddrState: 'OH',
        RegAddrZip: 44319,
        CongressionalDistrict: 16,
      },
      {
        RegAddrLine1: '7645 GAIL DR',
        RegAddrCity: 'APPLE CREEK',
        RegAddrState: 'OH',
        RegAddrZip: 44606,
        CongressionalDistrict: 16,
      },
      {
        RegAddrLine1: '6335 TAYLOR RD',
        RegAddrCity: 'CLINTON',
        RegAddrState: 'OH',
        RegAddrZip: 44216,
        CongressionalDistrict: 16,
      },
      {
        RegAddrLine1: '110 1/2 N MILL ST',
        RegAddrCity: 'FREDERICKSBRG',
        RegAddrState: 'OH',
        RegAddrZip: 44627,
        CongressionalDistrict: 16,
      },
      {
        RegAddrLine1: '1841 LEDGE RD',
        RegAddrCity: 'HINCKLEY',
        RegAddrState: 'OH',
        RegAddrZip: 44233,
        CongressionalDistrict: 16,
      },
      {
        RegAddrLine1: '3981 WILLOW WAY',
        RegAddrCity: 'KENT',
        RegAddrState: 'OH',
        RegAddrZip: 44240,
        CongressionalDistrict: 16,
      },
      {
        RegAddrLine1: '11213 ESSEX CIR',
        RegAddrCity: 'N ROYALTON',
        RegAddrState: 'OH',
        RegAddrZip: 44133,
        CongressionalDistrict: 16,
      },
      {
        RegAddrLine1: '1145 S MAIN ST',
        RegAddrCity: 'NORTH CANTON',
        RegAddrState: 'OH',
        RegAddrZip: 44720,
        CongressionalDistrict: 16,
      },
      {
        RegAddrLine1: '937 CLEARMOUNT AVE SE',
        RegAddrCity: 'NORTH CANTON',
        RegAddrState: 'OH',
        RegAddrZip: 44720,
        CongressionalDistrict: 16,
      },
      {
        RegAddrLine1: '8353 EDINBURGH WOODS RD',
        RegAddrCity: 'ORRVILLE',
        RegAddrState: 'OH',
        RegAddrZip: 44667,
        CongressionalDistrict: 16,
      },
      {
        RegAddrLine1: '8245 W PLEASANT VALLEY RD',
        RegAddrCity: 'PARMA',
        RegAddrState: 'OH',
        RegAddrZip: 44130,
        CongressionalDistrict: 16,
      },
      {
        RegAddrLine1: '7000 EDGECLIFF DR',
        RegAddrCity: 'PARMA',
        RegAddrState: 'OH',
        RegAddrZip: 44134,
        CongressionalDistrict: 16,
      },
      {
        RegAddrLine1: '3207 STANFIELD DR',
        RegAddrCity: 'PARMA',
        RegAddrState: 'OH',
        RegAddrZip: 44134,
        CongressionalDistrict: 16,
      },
      {
        RegAddrLine1: '14513 WILLOW CIR',
        RegAddrCity: 'STRONGSVILLE',
        RegAddrState: 'OH',
        RegAddrZip: 44136,
        CongressionalDistrict: 16,
      },
      {
        RegAddrLine1: '1502 WOODLAND DR',
        RegAddrCity: 'UNIONTOWN',
        RegAddrState: 'OH',
        RegAddrZip: 44685,
        CongressionalDistrict: 16,
      },
      {
        RegAddrLine1: '10705 HOOVER AVE NW',
        RegAddrCity: 'UNIONTOWN',
        RegAddrState: 'OH',
        RegAddrZip: 44685,
        CongressionalDistrict: 16,
      },
      {
        RegAddrLine1: '5663 FAIRFIELD LN',
        RegAddrCity: 'WADSWORTH',
        RegAddrState: 'OH',
        RegAddrZip: 44281,
        CongressionalDistrict: 16,
      },
      {
        RegAddrLine1: '950 QUEENS GATE WAY',
        RegAddrCity: 'WADSWORTH',
        RegAddrState: 'OH',
        RegAddrZip: 44281,
        CongressionalDistrict: 16,
      },
      {
        RegAddrLine1: '5835 CAMP RD',
        RegAddrCity: 'WEST SALEM',
        RegAddrState: 'OH',
        RegAddrZip: 44287,
        CongressionalDistrict: 16,
      },
      {
        RegAddrLine1: '29367 HUMMINGBIRD CIR',
        RegAddrCity: 'WESTLAKE',
        RegAddrState: 'OH',
        RegAddrZip: 44145,
        CongressionalDistrict: 16,
      },
      {
        RegAddrLine1: '1559 WEYMOUTH CIR',
        RegAddrCity: 'WESTLAKE',
        RegAddrState: 'OH',
        RegAddrZip: 44145,
        CongressionalDistrict: 16,
      },
    ],
  },
  {
    name: 'OH-2',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/OH-2?ref=gh-pages',
    state: 'OH',
    state_and_number: 'OH-2',
    zip: 45101,
    congressionalDistrict: 2,
    addressesInDistrict: [
      {
        RegAddrLine1: '3610 N HEARTWOOD RD',
        RegAddrCity: 'AMELIA',
        RegAddrState: 'OH',
        RegAddrZip: 45102,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '5 OTTER CT',
        RegAddrCity: 'AMELIA',
        RegAddrState: 'OH',
        RegAddrZip: 45102,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1217 TRADITIONS TURN',
        RegAddrCity: 'BATAVIA',
        RegAddrState: 'OH',
        RegAddrZip: 45103,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2410 HALF HILL RD',
        RegAddrCity: 'BETHEL',
        RegAddrState: 'OH',
        RegAddrZip: 45106,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '4039 TAYLOR AVE',
        RegAddrCity: 'CINCINNATI',
        RegAddrState: 'OH',
        RegAddrZip: 45209,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '4107 HUSTON AVE',
        RegAddrCity: 'CINCINNATI',
        RegAddrState: 'OH',
        RegAddrZip: 45212,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '3618 AIKENSIDE AVE',
        RegAddrCity: 'CINCINNATI',
        RegAddrState: 'OH',
        RegAddrZip: 45213,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2189 JULIE TER',
        RegAddrCity: 'CINCINNATI',
        RegAddrState: 'OH',
        RegAddrZip: 45215,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '730 MILLSDALE ST',
        RegAddrCity: 'CINCINNATI',
        RegAddrState: 'OH',
        RegAddrZip: 45216,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '858 W NORTH BEND RD',
        RegAddrCity: 'CINCINNATI',
        RegAddrState: 'OH',
        RegAddrZip: 45224,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '4565 HARRISON AVE',
        RegAddrCity: 'CINCINNATI',
        RegAddrState: 'OH',
        RegAddrZip: 45236,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '4560 LAMONT AVE',
        RegAddrCity: 'CINCINNATI',
        RegAddrState: 'OH',
        RegAddrZip: 45242,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2209 ENDOVALLEY DR',
        RegAddrCity: 'CINCINNATI',
        RegAddrState: 'OH',
        RegAddrZip: 45244,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '7253 ANDERSON WOODS DR',
        RegAddrCity: 'CINCINNATI',
        RegAddrState: 'OH',
        RegAddrZip: 45244,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '3440 DRAKE ST',
        RegAddrCity: 'CINCINNATI',
        RegAddrState: 'OH',
        RegAddrZip: 45244,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '675 E LAKE LN',
        RegAddrCity: 'CINCINNATI',
        RegAddrState: 'OH',
        RegAddrZip: 45244,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '867 LOCUST LN',
        RegAddrCity: 'CINCINNATI',
        RegAddrState: 'OH',
        RegAddrZip: 45245,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '6441 PARK ST',
        RegAddrCity: 'DECATUR',
        RegAddrState: 'OH',
        RegAddrZip: 45115,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '6257 MANILA RD',
        RegAddrCity: 'GOSHEN',
        RegAddrState: 'OH',
        RegAddrZip: 45122,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '7050 HIGHTOP RD',
        RegAddrCity: 'HILLSBORO',
        RegAddrState: 'OH',
        RegAddrZip: 45133,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '106 LEXINGTON DR',
        RegAddrCity: 'LOVELAND',
        RegAddrState: 'OH',
        RegAddrZip: 45140,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '6804 STAGGE RD',
        RegAddrCity: 'LOVELAND',
        RegAddrState: 'OH',
        RegAddrZip: 45140,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '3925 STATE ROUTE 134',
        RegAddrCity: 'MOUNT ORAB',
        RegAddrState: 'OH',
        RegAddrZip: 45154,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '3125 STATE ROUTE 139',
        RegAddrCity: 'PORTSMOUTH',
        RegAddrState: 'OH',
        RegAddrZip: 45662,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1046 STONEY RUN RD',
        RegAddrCity: 'W PORTSMOUTH',
        RegAddrState: 'OH',
        RegAddrZip: 45663,
        CongressionalDistrict: 2,
      },
    ],
  },
  {
    name: 'OH-3',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/OH-3?ref=gh-pages',
    state: 'OH',
    state_and_number: 'OH-3',
    zip: 43004,
    congressionalDistrict: 3,
    addressesInDistrict: [
      {
        RegAddrLine1: '18946 SALT RUN RD',
        RegAddrCity: 'CALDWELL',
        RegAddrState: 'OH',
        RegAddrZip: 43724,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '55 KING AVE',
        RegAddrCity: 'COLUMBUS',
        RegAddrState: 'OH',
        RegAddrZip: 43201,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '335 WOODLAND AVE',
        RegAddrCity: 'COLUMBUS',
        RegAddrState: 'OH',
        RegAddrZip: 43203,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '3513 SANITA CT',
        RegAddrCity: 'COLUMBUS',
        RegAddrState: 'OH',
        RegAddrZip: 43204,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '908 KELTON AVE',
        RegAddrCity: 'COLUMBUS',
        RegAddrState: 'OH',
        RegAddrZip: 43206,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1274 S OHIO AVE',
        RegAddrCity: 'COLUMBUS',
        RegAddrState: 'OH',
        RegAddrZip: 43206,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1191 S TWENTY SECOND ST',
        RegAddrCity: 'COLUMBUS',
        RegAddrState: 'OH',
        RegAddrZip: 43206,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '339 LEMERY DR',
        RegAddrCity: 'COLUMBUS',
        RegAddrState: 'OH',
        RegAddrZip: 43213,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '3618 ASHRIDGE ST',
        RegAddrCity: 'COLUMBUS',
        RegAddrState: 'OH',
        RegAddrZip: 43219,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1652 CORONET DR',
        RegAddrCity: 'COLUMBUS',
        RegAddrState: 'OH',
        RegAddrZip: 43224,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '255 DANHURST RD',
        RegAddrCity: 'COLUMBUS',
        RegAddrState: 'OH',
        RegAddrZip: 43228,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1185 BACCARAT DR',
        RegAddrCity: 'COLUMBUS',
        RegAddrState: 'OH',
        RegAddrZip: 43228,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '2192 GREEN ISLAND DR',
        RegAddrCity: 'COLUMBUS',
        RegAddrState: 'OH',
        RegAddrZip: 43228,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1256 DOGWOOD DR',
        RegAddrCity: 'COLUMBUS',
        RegAddrState: 'OH',
        RegAddrZip: 43228,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '5506 THORNHAM DR',
        RegAddrCity: 'COLUMBUS',
        RegAddrState: 'OH',
        RegAddrZip: 43228,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '2280 CANYON TREE DR',
        RegAddrCity: 'COLUMBUS',
        RegAddrState: 'OH',
        RegAddrZip: 43229,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '3287 RAMSEY CT',
        RegAddrCity: 'COLUMBUS',
        RegAddrState: 'OH',
        RegAddrZip: 43231,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '6120 RADEKIN RD',
        RegAddrCity: 'COLUMBUS',
        RegAddrState: 'OH',
        RegAddrZip: 43232,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '2087 CREEKVIEW CT',
        RegAddrCity: 'REYNOLDSBURG',
        RegAddrState: 'OH',
        RegAddrZip: 43068,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1647 PARKINSON DR',
        RegAddrCity: 'REYNOLDSBURG',
        RegAddrState: 'OH',
        RegAddrZip: 43068,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '3462 DEMPSEY RD',
        RegAddrCity: 'WESTERVILLE',
        RegAddrState: 'OH',
        RegAddrZip: 43081,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '3408 DAHLGREEN DR',
        RegAddrCity: 'WESTERVILLE',
        RegAddrState: 'OH',
        RegAddrZip: 43081,
        CongressionalDistrict: 3,
      },
    ],
  },
  {
    name: 'OH-4',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/OH-4?ref=gh-pages',
    state: 'OH',
    state_and_number: 'OH-4',
    zip: 43009,
    congressionalDistrict: 4,
    addressesInDistrict: [
      {
        RegAddrLine1: '141 WESTCHESTER DR',
        RegAddrCity: 'AMHERST',
        RegAddrState: 'OH',
        RegAddrZip: 44001,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '11286 COUNTY ROAD 106',
        RegAddrCity: 'BELLE CENTER',
        RegAddrState: 'OH',
        RegAddrZip: 43310,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '241 N LAWN AVE',
        RegAddrCity: 'BLUFFTON',
        RegAddrState: 'OH',
        RegAddrZip: 45817,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1383 KNAUSS RD',
        RegAddrCity: 'BUCYRUS',
        RegAddrState: 'OH',
        RegAddrZip: 44820,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '501 W RENSSELAER ST',
        RegAddrCity: 'BUCYRUS',
        RegAddrState: 'OH',
        RegAddrZip: 44820,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '4400 EAST RD',
        RegAddrCity: 'ELIDA',
        RegAddrState: 'OH',
        RegAddrZip: 45807,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2748 CAROLYN DR',
        RegAddrCity: 'ELIDA',
        RegAddrState: 'OH',
        RegAddrZip: 45807,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '864 OAKWOOD DR',
        RegAddrCity: 'ELYRIA',
        RegAddrState: 'OH',
        RegAddrZip: 44035,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '119 WREN CIR',
        RegAddrCity: 'ELYRIA',
        RegAddrState: 'OH',
        RegAddrZip: 44035,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '549 MAPLE ST',
        RegAddrCity: 'FOSTORIA',
        RegAddrState: 'OH',
        RegAddrZip: 44830,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1900 FAIRVIEW RD',
        RegAddrCity: 'GALION',
        RegAddrState: 'OH',
        RegAddrZip: 44833,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '904 FINDLAY RD',
        RegAddrCity: 'LIMA',
        RegAddrState: 'OH',
        RegAddrZip: 45801,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '950 MADISON AVE',
        RegAddrCity: 'LIMA',
        RegAddrState: 'OH',
        RegAddrZip: 45804,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '410 S KENILWORTH AVE',
        RegAddrCity: 'LIMA',
        RegAddrState: 'OH',
        RegAddrZip: 45805,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '206 N SEFFNER AVE',
        RegAddrCity: 'MARION',
        RegAddrState: 'OH',
        RegAddrZip: 43302,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '407 NORMANDY DR',
        RegAddrCity: 'MARION',
        RegAddrState: 'OH',
        RegAddrZip: 43302,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1220 BAY LAUREL DR',
        RegAddrCity: 'MARYSVILLE',
        RegAddrState: 'OH',
        RegAddrZip: 43040,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '7235 S TR 115',
        RegAddrCity: 'MCCUTCHENVILLE',
        RegAddrState: 'OH',
        RegAddrZip: 44844,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '19100 E SHELBY RD',
        RegAddrCity: 'NEW KNOXVILLE',
        RegAddrState: 'OH',
        RegAddrZip: 45871,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '3132 COUNTY ROAD 14',
        RegAddrCity: 'NEW RIEGEL',
        RegAddrState: 'OH',
        RegAddrZip: 44853,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '62 E ELM ST',
        RegAddrCity: 'NORWALK',
        RegAddrState: 'OH',
        RegAddrZip: 44857,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '284 GOLDSMITH DR',
        RegAddrCity: 'OBERLIN',
        RegAddrState: 'OH',
        RegAddrZip: 44074,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '367 N PROSPECT ST',
        RegAddrCity: 'OBERLIN',
        RegAddrState: 'OH',
        RegAddrZip: 44074,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '20311 PEMBERVILLE RD',
        RegAddrCity: 'PEMBERVILLE',
        RegAddrState: 'OH',
        RegAddrZip: 43450,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '18778 MAIN ST',
        RegAddrCity: 'RAYMOND',
        RegAddrState: 'OH',
        RegAddrZip: 43067,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '139 BLAINE AVE',
        RegAddrCity: 'RICHWOOD',
        RegAddrState: 'OH',
        RegAddrZip: 43344,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '8241 S TR 28',
        RegAddrCity: 'TIFFIN',
        RegAddrState: 'OH',
        RegAddrZip: 44883,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '3300 MIDDLE URBANA RD',
        RegAddrCity: 'URBANA',
        RegAddrState: 'OH',
        RegAddrZip: 43078,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2333 SHORT CUT RD',
        RegAddrCity: 'URBANA',
        RegAddrState: 'OH',
        RegAddrZip: 43078,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '137 ORCHARD DR',
        RegAddrCity: 'URBANA',
        RegAddrState: 'OH',
        RegAddrZip: 43078,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '102 N BLACKHOOF ST',
        RegAddrCity: 'WAPAKONETA',
        RegAddrState: 'OH',
        RegAddrZip: 45895,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1649 PARK RD',
        RegAddrCity: 'WOODSTOCK',
        RegAddrState: 'OH',
        RegAddrZip: 43084,
        CongressionalDistrict: 4,
      },
    ],
  },
  {
    name: 'OH-5',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/OH-5?ref=gh-pages',
    state: 'OH',
    state_and_number: 'OH-5',
    zip: 43323,
    congressionalDistrict: 5,
    addressesInDistrict: [
      {
        RegAddrLine1: '3433 TOWNSHIP ROAD 27',
        RegAddrCity: 'BLUFFTON',
        RegAddrState: 'OH',
        RegAddrZip: 45817,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '765 N MAIN ST',
        RegAddrCity: 'BRYAN',
        RegAddrState: 'OH',
        RegAddrZip: 43506,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '6356 COUNTY HIGHWAY 96',
        RegAddrCity: 'CAREY',
        RegAddrState: 'OH',
        RegAddrZip: 43316,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '603 HOYING ST',
        RegAddrCity: 'CELINA',
        RegAddrState: 'OH',
        RegAddrZip: 45822,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '2504 ROAD 193',
        RegAddrCity: 'CLOVERDALE',
        RegAddrState: 'OH',
        RegAddrZip: 45827,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '14846 ROAD P',
        RegAddrCity: 'COLUMBUS GRV',
        RegAddrState: 'OH',
        RegAddrZip: 45830,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '1755 IROQUOIS CT',
        RegAddrCity: 'DEFIANCE',
        RegAddrState: 'OH',
        RegAddrZip: 43512,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '14042 HIGHLAND CENTER RD',
        RegAddrCity: 'DEFIANCE',
        RegAddrState: 'OH',
        RegAddrZip: 43512,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '334 HURON ST',
        RegAddrCity: 'ELMORE',
        RegAddrState: 'OH',
        RegAddrZip: 43416,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '17249 ROAD 17N',
        RegAddrCity: 'FORT JENNINGS',
        RegAddrState: 'OH',
        RegAddrZip: 45844,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '723 BERKSHIRE DR',
        RegAddrCity: 'FOSTORIA',
        RegAddrState: 'OH',
        RegAddrZip: 44830,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '11979 LYDIA LN',
        RegAddrCity: 'KENTON',
        RegAddrState: 'OH',
        RegAddrZip: 43326,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '7949 COUNTY ROAD 16',
        RegAddrCity: 'MC COMB',
        RegAddrState: 'OH',
        RegAddrZip: 45858,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '1054 ANSONIA ST',
        RegAddrCity: 'OREGON',
        RegAddrState: 'OH',
        RegAddrZip: 43616,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '12271 ROAD G',
        RegAddrCity: 'OTTAWA',
        RegAddrState: 'OH',
        RegAddrZip: 45875,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '13621 KAY ST',
        RegAddrCity: 'PAULDING',
        RegAddrState: 'OH',
        RegAddrZip: 45879,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '9616 BISHOPSWOOD LN',
        RegAddrCity: 'PERRYSBURG',
        RegAddrState: 'OH',
        RegAddrZip: 43551,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '11011 NEIDERHOUSE RD',
        RegAddrCity: 'PERRYSBURG',
        RegAddrState: 'OH',
        RegAddrZip: 43551,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '214 MADDIE ST',
        RegAddrCity: 'SWANTON',
        RegAddrState: 'OH',
        RegAddrZip: 43558,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '2935 PERSIMMON DR',
        RegAddrCity: 'SYLVANIA',
        RegAddrState: 'OH',
        RegAddrZip: 43560,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '7164 FOREST BROOK DR',
        RegAddrCity: 'SYLVANIA',
        RegAddrState: 'OH',
        RegAddrZip: 43560,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '2523 GRACEWOOD RD',
        RegAddrCity: 'TOLEDO',
        RegAddrState: 'OH',
        RegAddrZip: 43613,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '2446 KING RD',
        RegAddrCity: 'TOLEDO',
        RegAddrState: 'OH',
        RegAddrZip: 43617,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '4606 HOMERDALE AVE',
        RegAddrCity: 'TOLEDO',
        RegAddrState: 'OH',
        RegAddrZip: 43623,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '101 WESTBROOK BLVD',
        RegAddrCity: 'UPPER SANDSKY',
        RegAddrState: 'OH',
        RegAddrZip: 43351,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '300 ELM ST',
        RegAddrCity: 'WALBRIDGE',
        RegAddrState: 'OH',
        RegAddrZip: 43465,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '1091 WESTRIDGE DR',
        RegAddrCity: 'WATERVILLE',
        RegAddrState: 'OH',
        RegAddrZip: 43566,
        CongressionalDistrict: 5,
      },
    ],
  },
  {
    name: 'OH-6',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/OH-6?ref=gh-pages',
    state: 'OH',
    state_and_number: 'OH-6',
    zip: 43701,
    congressionalDistrict: 6,
    addressesInDistrict: [
      {
        RegAddrLine1: '57599 S ELEANOR ST',
        RegAddrCity: 'BRIDGEPORT',
        RegAddrState: 'OH',
        RegAddrZip: 43912,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '18424 WOLF RUN RD',
        RegAddrCity: 'CALDWELL',
        RegAddrState: 'OH',
        RegAddrZip: 43724,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '12 SUNRISE DR',
        RegAddrCity: 'CAMBRIDGE',
        RegAddrState: 'OH',
        RegAddrZip: 43725,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '40 WILLOW WAY',
        RegAddrCity: 'CANFIELD',
        RegAddrState: 'OH',
        RegAddrZip: 44406,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '2123 PLEDGE RD SE',
        RegAddrCity: 'CARROLLTON',
        RegAddrState: 'OH',
        RegAddrZip: 44615,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '123 ALLEN AVE',
        RegAddrCity: 'COLUMBIANA',
        RegAddrState: 'OH',
        RegAddrZip: 44408,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '462 N MAIN ST',
        RegAddrCity: 'COLUMBIANA',
        RegAddrState: 'OH',
        RegAddrZip: 44408,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '78 CENTER ST',
        RegAddrCity: 'CROWN CITY',
        RegAddrState: 'OH',
        RegAddrZip: 45623,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '76 POWERHOUSE RD',
        RegAddrCity: 'DILLONVALE',
        RegAddrState: 'OH',
        RegAddrZip: 43917,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '527 WILLIAMS AVE',
        RegAddrCity: 'E LIVERPOOL',
        RegAddrState: 'OH',
        RegAddrZip: 43920,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '20 FRENCH GRANT RD',
        RegAddrCity: 'FRANKLN FRNCE',
        RegAddrState: 'OH',
        RegAddrZip: 45629,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '46 1/2 MILL CREEK RD',
        RegAddrCity: 'GALLIPOLIS',
        RegAddrState: 'OH',
        RegAddrZip: 45631,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '924 S 10TH ST',
        RegAddrCity: 'IRONTON',
        RegAddrState: 'OH',
        RegAddrZip: 45638,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '236 N MARKET ST',
        RegAddrCity: 'LISBON',
        RegAddrState: 'OH',
        RegAddrZip: 44432,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '1800 MUSKINGUM RIVER RD',
        RegAddrCity: 'LOWELL',
        RegAddrState: 'OH',
        RegAddrZip: 45744,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '651D DEVER BRANCH RD',
        RegAddrCity: 'LUCASVILLE',
        RegAddrState: 'OH',
        RegAddrZip: 45648,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '12065 VETO RD',
        RegAddrCity: 'MARIETTA',
        RegAddrState: 'OH',
        RegAddrZip: 45750,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '503 ADELAIDE ST',
        RegAddrCity: 'MINERVA',
        RegAddrState: 'OH',
        RegAddrZip: 44657,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '9309 STATE ROUTE 139',
        RegAddrCity: 'MINFORD',
        RegAddrState: 'OH',
        RegAddrZip: 45653,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '6850 RAGOR DR',
        RegAddrCity: 'NASHPORT',
        RegAddrState: 'OH',
        RegAddrZip: 43830,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '48-A P D 477 TWP RD',
        RegAddrCity: 'PEDRO',
        RegAddrState: 'OH',
        RegAddrZip: 45659,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '34211 BROWN RD',
        RegAddrCity: 'POMEROY',
        RegAddrState: 'OH',
        RegAddrZip: 45769,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '979 LIBERTY ST',
        RegAddrCity: 'SALEM',
        RegAddrState: 'OH',
        RegAddrZip: 44460,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '2239 1/2 CHERRY AVE',
        RegAddrCity: 'STEUBENVILLE',
        RegAddrState: 'OH',
        RegAddrZip: 43952,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '610 E FERNWOOD DR',
        RegAddrCity: 'TORONTO',
        RegAddrState: 'OH',
        RegAddrZip: 43964,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '5035 BOGGS RD',
        RegAddrCity: 'ZANESVILLE',
        RegAddrState: 'OH',
        RegAddrZip: 43701,
        CongressionalDistrict: 6,
      },
    ],
  },
  {
    name: 'OH-7',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/OH-7?ref=gh-pages',
    state: 'OH',
    state_and_number: 'OH-7',
    zip: 43005,
    congressionalDistrict: 7,
    addressesInDistrict: [
      {
        RegAddrLine1: '1970 TR 1095',
        RegAddrCity: 'ASHLAND',
        RegAddrState: 'OH',
        RegAddrZip: 44805,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '3338 PARKVIEW DR',
        RegAddrCity: 'AVON',
        RegAddrState: 'OH',
        RegAddrZip: 44011,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '37107 HUNTERS TRL',
        RegAddrCity: 'AVON',
        RegAddrState: 'OH',
        RegAddrZip: 44011,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '511 S CHURCH AVE',
        RegAddrCity: 'BEACH CITY',
        RegAddrState: 'OH',
        RegAddrZip: 44608,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '156 PARKVIEW DR',
        RegAddrCity: 'BOLIVAR',
        RegAddrState: 'OH',
        RegAddrZip: 44612,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '9613 SCOTTSDALE DR',
        RegAddrCity: 'BROADVIEW HTS',
        RegAddrState: 'OH',
        RegAddrZip: 44147,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '4635 SAWGRASS AVE',
        RegAddrCity: 'BRUNSWICK',
        RegAddrState: 'OH',
        RegAddrZip: 44212,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '3725 SLEEPY HOLLOW RD',
        RegAddrCity: 'BRUNSWICK',
        RegAddrState: 'OH',
        RegAddrZip: 44212,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '817 GILMORE AVE NW',
        RegAddrCity: 'CANTON',
        RegAddrState: 'OH',
        RegAddrZip: 44703,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '2711 JOSEPH PL NW',
        RegAddrCity: 'CANTON',
        RegAddrState: 'OH',
        RegAddrZip: 44708,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '4813 CRANBERRY AVE NW',
        RegAddrCity: 'CANTON',
        RegAddrState: 'OH',
        RegAddrZip: 44709,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '13610 COWLEY RD',
        RegAddrCity: 'COLUMBIA STA',
        RegAddrState: 'OH',
        RegAddrZip: 44028,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '1314 OAK AVE',
        RegAddrCity: 'COSHOCTON',
        RegAddrState: 'OH',
        RegAddrZip: 43812,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '1855 FAIRBROOK AVE NE',
        RegAddrCity: 'EAST CANTON',
        RegAddrState: 'OH',
        RegAddrZip: 44730,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '1005 S PROSPECT AVE',
        RegAddrCity: 'HARTVILLE',
        RegAddrState: 'OH',
        RegAddrZip: 44632,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '2724 SMITH KRAMER ST NE',
        RegAddrCity: 'HARTVILLE',
        RegAddrState: 'OH',
        RegAddrZip: 44632,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '10224 LYNCHBURG RD',
        RegAddrCity: 'KENSINGTON',
        RegAddrState: 'OH',
        RegAddrZip: 44427,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '329 S WATER ST',
        RegAddrCity: 'LOUDONVILLE',
        RegAddrState: 'OH',
        RegAddrZip: 44842,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '1882 KNOB ST',
        RegAddrCity: 'LOUISVILLE',
        RegAddrState: 'OH',
        RegAddrZip: 44641,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '1438 N STEWART RD',
        RegAddrCity: 'MANSFIELD',
        RegAddrState: 'OH',
        RegAddrZip: 44903,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '669 SCOTT DR',
        RegAddrCity: 'MANSFIELD',
        RegAddrState: 'OH',
        RegAddrZip: 44906,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '1825 CARRIAGE HILL ST NW',
        RegAddrCity: 'MASSILLON',
        RegAddrState: 'OH',
        RegAddrZip: 44646,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '11713 LINCOLN WAY NW',
        RegAddrCity: 'MASSILLON',
        RegAddrState: 'OH',
        RegAddrZip: 44647,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '8212 W SMITH RD',
        RegAddrCity: 'MEDINA',
        RegAddrState: 'OH',
        RegAddrZip: 44256,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '608 RANDY DR',
        RegAddrCity: 'MOUNT VERNON',
        RegAddrState: 'OH',
        RegAddrZip: 43050,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '15043 WOOSTER RD',
        RegAddrCity: 'MOUNT VERNON',
        RegAddrState: 'OH',
        RegAddrZip: 43050,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '19333 COSHOCTON RD',
        RegAddrCity: 'MOUNT VERNON',
        RegAddrState: 'OH',
        RegAddrZip: 43050,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '37037 CHADDWYCK LN',
        RegAddrCity: 'N RIDGEVILLE',
        RegAddrState: 'OH',
        RegAddrZip: 44039,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '7645 ERIE AVE SW',
        RegAddrCity: 'NAVARRE',
        RegAddrState: 'OH',
        RegAddrZip: 44662,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '1690 BALTZLEY VALLEY RD SE',
        RegAddrCity: 'NEW PHILA',
        RegAddrState: 'OH',
        RegAddrZip: 44663,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '6638 BUEHLER HILL RD SW',
        RegAddrCity: 'STONE CREEK',
        RegAddrState: 'OH',
        RegAddrZip: 43840,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '1807 STATION RD',
        RegAddrCity: 'VALLEY CITY',
        RegAddrState: 'OH',
        RegAddrZip: 44280,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '19858 STATE ROUTE 301',
        RegAddrCity: 'WELLINGTON',
        RegAddrState: 'OH',
        RegAddrZip: 44090,
        CongressionalDistrict: 7,
      },
    ],
  },
  {
    name: 'OH-8',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/OH-8?ref=gh-pages',
    state: 'OH',
    state_and_number: 'OH-8',
    zip: 43010,
    congressionalDistrict: 8,
    addressesInDistrict: [
      {
        RegAddrLine1: '9093 STILLWATER DR',
        RegAddrCity: 'BRADFORD',
        RegAddrState: 'OH',
        RegAddrZip: 45308,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '6251 STATE ROUTE 274',
        RegAddrCity: 'CELINA',
        RegAddrState: 'OH',
        RegAddrZip: 45822,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '7992 COUNTY ROAD 219A',
        RegAddrCity: 'CELINA',
        RegAddrState: 'OH',
        RegAddrZip: 45822,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '10128 INDIAN CREEK DR',
        RegAddrCity: 'CINCINNATI',
        RegAddrState: 'OH',
        RegAddrZip: 45241,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '2925 BENTBROOK DR',
        RegAddrCity: 'CINCINNATI',
        RegAddrState: 'OH',
        RegAddrZip: 45251,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '5596 WINTON RD',
        RegAddrCity: 'FAIRFIELD',
        RegAddrState: 'OH',
        RegAddrZip: 45014,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '7619 NEWKIRK DR',
        RegAddrCity: 'FAIRFIELD TWP',
        RegAddrState: 'OH',
        RegAddrZip: 45011,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '112 GRANT CIR',
        RegAddrCity: 'HAMILTON',
        RegAddrState: 'OH',
        RegAddrZip: 45011,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '6722 EAGLE CREEK DR',
        RegAddrCity: 'HAMILTON',
        RegAddrState: 'OH',
        RegAddrZip: 45011,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '784 BELLE AVE',
        RegAddrCity: 'HAMILTON',
        RegAddrState: 'OH',
        RegAddrZip: 45015,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '4197 BEDFORD AVE',
        RegAddrCity: 'HAMILTON',
        RegAddrState: 'OH',
        RegAddrZip: 45015,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '5212 SUNRISE VIEW CIR',
        RegAddrCity: 'LIBERTY TWP',
        RegAddrState: 'OH',
        RegAddrZip: 45044,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '5001 LESLEY LN',
        RegAddrCity: 'MIDDLETOWN',
        RegAddrState: 'OH',
        RegAddrZip: 45042,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '2220 CAROLINA ST',
        RegAddrCity: 'MIDDLETOWN',
        RegAddrState: 'OH',
        RegAddrZip: 45044,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '2113 FERNWOOD ST',
        RegAddrCity: 'MIDDLETOWN',
        RegAddrState: 'OH',
        RegAddrZip: 45044,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '615 ETHEL CT',
        RegAddrCity: 'MIDDLETOWN',
        RegAddrState: 'OH',
        RegAddrZip: 45044,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '2823 PLYMOUTH ST',
        RegAddrCity: 'MIDDLETOWN',
        RegAddrState: 'OH',
        RegAddrZip: 45044,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '337 LEBANON ST',
        RegAddrCity: 'MONROE',
        RegAddrState: 'OH',
        RegAddrZip: 45050,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '2075 MCFEELEY PETRY RD',
        RegAddrCity: 'NEW WESTON',
        RegAddrState: 'OH',
        RegAddrZip: 45348,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '5750 JENKINS RD',
        RegAddrCity: 'OKEANA',
        RegAddrState: 'OH',
        RegAddrZip: 45053,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '3670 W FARRINGTON RD',
        RegAddrCity: 'PIQUA',
        RegAddrState: 'OH',
        RegAddrZip: 45356,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '212 S JOHNSON RD',
        RegAddrCity: 'PLEASANT HILL',
        RegAddrState: 'OH',
        RegAddrZip: 45359,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '101 CONNIE LN',
        RegAddrCity: 'ROSSBURG',
        RegAddrState: 'OH',
        RegAddrZip: 45362,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '4995 SELMA RD',
        RegAddrCity: 'SPRINGFIELD',
        RegAddrState: 'OH',
        RegAddrZip: 45502,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '2381 BALLENTINE PIKE',
        RegAddrCity: 'SPRINGFIELD',
        RegAddrState: 'OH',
        RegAddrZip: 45502,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '1693 CALAIS DR',
        RegAddrCity: 'SPRINGFIELD',
        RegAddrState: 'OH',
        RegAddrZip: 45503,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '5815 NEW CARLISLE PIKE',
        RegAddrCity: 'SPRINGFIELD',
        RegAddrState: 'OH',
        RegAddrZip: 45504,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '1103 SUGAR MAPLE LOOP N',
        RegAddrCity: 'TIPP CITY',
        RegAddrState: 'OH',
        RegAddrZip: 45371,
        CongressionalDistrict: 8,
      },
    ],
  },
  {
    name: 'OH-9',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/OH-9?ref=gh-pages',
    state: 'OH',
    state_and_number: 'OH-9',
    zip: 43433,
    congressionalDistrict: 9,
    addressesInDistrict: [
      {
        RegAddrLine1: '4480 JAYCOX RD',
        RegAddrCity: 'AVON',
        RegAddrState: 'OH',
        RegAddrZip: 44011,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '32474 WALKER RD',
        RegAddrCity: 'AVON LAKE',
        RegAddrState: 'OH',
        RegAddrZip: 44012,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '14418 SHELDON RD',
        RegAddrCity: 'BROOK PARK',
        RegAddrState: 'OH',
        RegAddrZip: 44142,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '15595 ASHLAND DR',
        RegAddrCity: 'BROOK PARK',
        RegAddrState: 'OH',
        RegAddrZip: 44142,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '15556 RADEMAKER DR',
        RegAddrCity: 'BROOK PARK',
        RegAddrState: 'OH',
        RegAddrZip: 44142,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '3250 W 61ST ST',
        RegAddrCity: 'CLEVELAND',
        RegAddrState: 'OH',
        RegAddrZip: 44102,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '4210 W 140TH ST',
        RegAddrCity: 'CLEVELAND',
        RegAddrState: 'OH',
        RegAddrZip: 44135,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '2210 WASCANA AVE',
        RegAddrCity: 'LAKEWOOD',
        RegAddrState: 'OH',
        RegAddrZip: 44107,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '4017 W 120TH ST',
        RegAddrCity: 'LINNDALE',
        RegAddrState: 'OH',
        RegAddrZip: 44135,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '1908 E 29TH ST',
        RegAddrCity: 'LORAIN',
        RegAddrState: 'OH',
        RegAddrZip: 44055,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '6982 EAST HARBOR RD',
        RegAddrCity: 'MARBLEHEAD',
        RegAddrState: 'OH',
        RegAddrZip: 43440,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '3360 HARDWOOD HOLLOW RD',
        RegAddrCity: 'MEDINA',
        RegAddrState: 'OH',
        RegAddrZip: 44256,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '9629 W HOLLYWOOD DR',
        RegAddrCity: 'OAK HARBOR',
        RegAddrState: 'OH',
        RegAddrZip: 43449,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '2144 ARKANSAS ST',
        RegAddrCity: 'OREGON',
        RegAddrState: 'OH',
        RegAddrZip: 43616,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '4437 BAYSHORE RD',
        RegAddrCity: 'OREGON',
        RegAddrState: 'OH',
        RegAddrZip: 43616,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '802 W BOGART RD',
        RegAddrCity: 'SANDUSKY',
        RegAddrState: 'OH',
        RegAddrZip: 44870,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '409 COVE ST',
        RegAddrCity: 'SANDUSKY',
        RegAddrState: 'OH',
        RegAddrZip: 44870,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '348 MULBERRY ST',
        RegAddrCity: 'TOLEDO',
        RegAddrState: 'OH',
        RegAddrZip: 43604,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '1215 WALBRIDGE AVE',
        RegAddrCity: 'TOLEDO',
        RegAddrState: 'OH',
        RegAddrZip: 43609,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '2205 KEEN AVE',
        RegAddrCity: 'TOLEDO',
        RegAddrState: 'OH',
        RegAddrZip: 43611,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '4820 298TH ST',
        RegAddrCity: 'TOLEDO',
        RegAddrState: 'OH',
        RegAddrZip: 43611,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '4833 296TH ST',
        RegAddrCity: 'TOLEDO',
        RegAddrState: 'OH',
        RegAddrZip: 43611,
        CongressionalDistrict: 9,
      },
    ],
  },
  {
    name: 'OK-1',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/OK-1?ref=gh-pages',
    state: 'OK',
    state_and_number: 'OK-1',
    zip: 74003,
    congressionalDistrict: 1,
    addressesInDistrict: [
      {
        RegAddrLine1: '1832 HARNED DR',
        RegAddrCity: 'BARTLESVILLE',
        RegAddrState: 'OK',
        RegAddrZip: 74006,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '5602 REVERE WAY CT',
        RegAddrCity: 'BARTLESVILLE',
        RegAddrState: 'OK',
        RegAddrZip: 74006,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1218 MANOR DR',
        RegAddrCity: 'BARTLESVILLE',
        RegAddrState: 'OK',
        RegAddrZip: 74006,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1808 GLYNNWOOD DR',
        RegAddrCity: 'BARTLESVILLE',
        RegAddrState: 'OK',
        RegAddrZip: 74006,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1025 HAZEL CT',
        RegAddrCity: 'BARTLESVILLE',
        RegAddrState: 'OK',
        RegAddrZip: 74006,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2508 KINGSTON DR',
        RegAddrCity: 'BARTLESVILLE',
        RegAddrState: 'OK',
        RegAddrZip: 74006,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '6077 E 147TH ST S',
        RegAddrCity: 'BIXBY',
        RegAddrState: 'OK',
        RegAddrZip: 74008,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '13712 S MINGO RD',
        RegAddrCity: 'BIXBY',
        RegAddrState: 'OK',
        RegAddrZip: 74008,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '3648 E 191ST ST S',
        RegAddrCity: 'BIXBY',
        RegAddrState: 'OK',
        RegAddrZip: 74008,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '12206 S 100TH EAST AVE',
        RegAddrCity: 'BIXBY',
        RegAddrState: 'OK',
        RegAddrZip: 74008,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '5301 S JUNIPER AVE',
        RegAddrCity: 'BROKEN ARROW',
        RegAddrState: 'OK',
        RegAddrZip: 74011,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '3812 S DOGWOOD AVE',
        RegAddrCity: 'BROKEN ARROW',
        RegAddrState: 'OK',
        RegAddrZip: 74011,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '8119 S DATE PL',
        RegAddrCity: 'BROKEN ARROW',
        RegAddrState: 'OK',
        RegAddrZip: 74011,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '18811 E 114TH ST',
        RegAddrCity: 'BROKEN ARROW',
        RegAddrState: 'OK',
        RegAddrZip: 74011,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '4417 S BUTTERNUT AVE',
        RegAddrCity: 'BROKEN ARROW',
        RegAddrState: 'OK',
        RegAddrZip: 74011,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2401 W BOSTON ST',
        RegAddrCity: 'BROKEN ARROW',
        RegAddrState: 'OK',
        RegAddrZip: 74012,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '3233 S 16TH ST',
        RegAddrCity: 'BROKEN ARROW',
        RegAddrState: 'OK',
        RegAddrZip: 74012,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '4412 W PRINCETON ST',
        RegAddrCity: 'BROKEN ARROW',
        RegAddrState: 'OK',
        RegAddrZip: 74012,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '4220 W FREEPORT ST',
        RegAddrCity: 'BROKEN ARROW',
        RegAddrState: 'OK',
        RegAddrZip: 74012,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '3402 W EDGEWATER PL',
        RegAddrCity: 'BROKEN ARROW',
        RegAddrState: 'OK',
        RegAddrZip: 74012,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '4409 N JUNIPER AVE',
        RegAddrCity: 'BROKEN ARROW',
        RegAddrState: 'OK',
        RegAddrZip: 74012,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1516 W OAKRIDGE CT',
        RegAddrCity: 'BROKEN ARROW',
        RegAddrState: 'OK',
        RegAddrZip: 74012,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1641 E FARGO ST',
        RegAddrCity: 'BROKEN ARROW',
        RegAddrState: 'OK',
        RegAddrZip: 74012,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '7804 E DALLAS ST',
        RegAddrCity: 'BROKEN ARROW',
        RegAddrState: 'OK',
        RegAddrZip: 74014,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '4965 S 202ND EAST AVE',
        RegAddrCity: 'BROKEN ARROW',
        RegAddrState: 'OK',
        RegAddrZip: 74014,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1316 N 31ST ST',
        RegAddrCity: 'BROKEN ARROW',
        RegAddrState: 'OK',
        RegAddrZip: 74014,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '20415 E 33RD PL N',
        RegAddrCity: 'CATOOSA',
        RegAddrState: 'OK',
        RegAddrZip: 74015,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '13255 E 137TH ST N',
        RegAddrCity: 'COLLINSVILLE',
        RegAddrState: 'OK',
        RegAddrZip: 74021,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '104 N DELAWARE ST',
        RegAddrCity: 'DEWEY',
        RegAddrState: 'OK',
        RegAddrZip: 74029,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '605 TAFT LN',
        RegAddrCity: 'DEWEY',
        RegAddrState: 'OK',
        RegAddrZip: 74029,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2516 CYPRESS RD',
        RegAddrCity: 'DURANT',
        RegAddrState: 'OK',
        RegAddrZip: 74701,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '11928 S HOLLEY ST',
        RegAddrCity: 'JENKS',
        RegAddrState: 'OK',
        RegAddrZip: 74037,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '11352 S DATE ST',
        RegAddrCity: 'JENKS',
        RegAddrState: 'OK',
        RegAddrZip: 74037,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2812 W 114TH ST S',
        RegAddrCity: 'JENKS',
        RegAddrState: 'OK',
        RegAddrZip: 74037,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '28744 N 3964 RD',
        RegAddrCity: 'OCHELATA',
        RegAddrState: 'OK',
        RegAddrZip: 74051,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '7007 N 123RD EAST AVE',
        RegAddrCity: 'OWASSO',
        RegAddrState: 'OK',
        RegAddrZip: 74055,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '6530 E 89TH ST N',
        RegAddrCity: 'OWASSO',
        RegAddrState: 'OK',
        RegAddrZip: 74055,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '8515 N 123RD EAST AVE',
        RegAddrCity: 'OWASSO',
        RegAddrState: 'OK',
        RegAddrZip: 74055,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '8804 N 120TH EAST AVE',
        RegAddrCity: 'OWASSO',
        RegAddrState: 'OK',
        RegAddrZip: 74055,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1025 N ADAMS RD',
        RegAddrCity: 'SAND SPRINGS',
        RegAddrState: 'OK',
        RegAddrZip: 74063,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '4525 S EVERETT ST',
        RegAddrCity: 'SAND SPRINGS',
        RegAddrState: 'OK',
        RegAddrZip: 74063,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '14336 N 50TH WEST AVE',
        RegAddrCity: 'SKIATOOK',
        RegAddrState: 'OK',
        RegAddrZip: 74070,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '18305 N LEWIS AVE',
        RegAddrCity: 'SKIATOOK',
        RegAddrState: 'OK',
        RegAddrZip: 74070,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '316 W 92ND ST N',
        RegAddrCity: 'SPERRY',
        RegAddrState: 'OK',
        RegAddrZip: 74073,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2622 E 20TH ST',
        RegAddrCity: 'TULSA',
        RegAddrState: 'OK',
        RegAddrZip: 74104,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2243 E 10TH ST',
        RegAddrCity: 'TULSA',
        RegAddrState: 'OK',
        RegAddrZip: 74104,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '4802 S DETROIT AVE',
        RegAddrCity: 'TULSA',
        RegAddrState: 'OK',
        RegAddrZip: 74105,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '3844 S ATLANTA PL',
        RegAddrCity: 'TULSA',
        RegAddrState: 'OK',
        RegAddrZip: 74105,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '4457 S ZUNIS AVE',
        RegAddrCity: 'TULSA',
        RegAddrState: 'OK',
        RegAddrZip: 74105,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '4243 S DETROIT AVE',
        RegAddrCity: 'TULSA',
        RegAddrState: 'OK',
        RegAddrZip: 74105,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1233 N CHEYENNE AVE',
        RegAddrCity: 'TULSA',
        RegAddrState: 'OK',
        RegAddrZip: 74106,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '4115 N FRANKFORT AVE',
        RegAddrCity: 'TULSA',
        RegAddrState: 'OK',
        RegAddrZip: 74106,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '7345 W 36TH ST',
        RegAddrCity: 'TULSA',
        RegAddrState: 'OK',
        RegAddrZip: 74107,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '713 W 23RD ST',
        RegAddrCity: 'TULSA',
        RegAddrState: 'OK',
        RegAddrZip: 74107,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '3750 S 60TH WEST AVE',
        RegAddrCity: 'TULSA',
        RegAddrState: 'OK',
        RegAddrZip: 74107,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '4218 S ZENITH AVE',
        RegAddrCity: 'TULSA',
        RegAddrState: 'OK',
        RegAddrZip: 74107,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '267 S 163RD EAST AVE',
        RegAddrCity: 'TULSA',
        RegAddrState: 'OK',
        RegAddrZip: 74108,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1822 N EVANSTON PL',
        RegAddrCity: 'TULSA',
        RegAddrState: 'OK',
        RegAddrZip: 74110,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2536 E ADMIRAL CT',
        RegAddrCity: 'TULSA',
        RegAddrState: 'OK',
        RegAddrZip: 74110,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '37 S 73RD EAST AVE',
        RegAddrCity: 'TULSA',
        RegAddrState: 'OK',
        RegAddrZip: 74112,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1218 S MARION AVE',
        RegAddrCity: 'TULSA',
        RegAddrState: 'OK',
        RegAddrZip: 74112,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2160 S NORFOLK TER',
        RegAddrCity: 'TULSA',
        RegAddrState: 'OK',
        RegAddrZip: 74114,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1169 N 169TH EAST AVE',
        RegAddrCity: 'TULSA',
        RegAddrState: 'OK',
        RegAddrZip: 74116,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1008 E 19TH ST',
        RegAddrCity: 'TULSA',
        RegAddrState: 'OK',
        RegAddrZip: 74120,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '814 E 52ND ST N',
        RegAddrCity: 'TULSA',
        RegAddrState: 'OK',
        RegAddrZip: 74126,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '14 E 50TH CT N',
        RegAddrCity: 'TULSA',
        RegAddrState: 'OK',
        RegAddrZip: 74126,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '414 S 60TH WEST AVE',
        RegAddrCity: 'TULSA',
        RegAddrState: 'OK',
        RegAddrZip: 74127,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2833 S 115 E AVE',
        RegAddrCity: 'TULSA',
        RegAddrState: 'OK',
        RegAddrZip: 74129,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '8619 E 30TH PL',
        RegAddrCity: 'TULSA',
        RegAddrState: 'OK',
        RegAddrZip: 74129,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2185 S 73RD EAST AVE',
        RegAddrCity: 'TULSA',
        RegAddrState: 'OK',
        RegAddrZip: 74129,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2645 S 74TH EAST AVE',
        RegAddrCity: 'TULSA',
        RegAddrState: 'OK',
        RegAddrZip: 74129,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '5017 N WHEELING AVE',
        RegAddrCity: 'TULSA',
        RegAddrState: 'OK',
        RegAddrZip: 74130,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '6461 S 28TH WEST AVE',
        RegAddrCity: 'TULSA',
        RegAddrState: 'OK',
        RegAddrZip: 74132,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '8820 S 70TH EAST AVE',
        RegAddrCity: 'TULSA',
        RegAddrState: 'OK',
        RegAddrZip: 74133,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '10701 S 89TH EAST AVE',
        RegAddrCity: 'TULSA',
        RegAddrState: 'OK',
        RegAddrZip: 74133,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '8405 S 112TH EAST AVE',
        RegAddrCity: 'TULSA',
        RegAddrState: 'OK',
        RegAddrZip: 74133,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '8338 E 61ST ST',
        RegAddrCity: 'TULSA',
        RegAddrState: 'OK',
        RegAddrZip: 74133,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '4833 S 163RD EAST AVE',
        RegAddrCity: 'TULSA',
        RegAddrState: 'OK',
        RegAddrZip: 74134,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '3356 S 141ST EAST AVE',
        RegAddrCity: 'TULSA',
        RegAddrState: 'OK',
        RegAddrZip: 74134,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '4730 S GRANITE PL',
        RegAddrCity: 'TULSA',
        RegAddrState: 'OK',
        RegAddrZip: 74135,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '6104 E 67TH CT',
        RegAddrCity: 'TULSA',
        RegAddrState: 'OK',
        RegAddrZip: 74136,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '5148 E 107TH ST',
        RegAddrCity: 'TULSA',
        RegAddrState: 'OK',
        RegAddrZip: 74137,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '8436 S FLORENCE AVE',
        RegAddrCity: 'TULSA',
        RegAddrState: 'OK',
        RegAddrZip: 74137,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '5116 E 91ST PL',
        RegAddrCity: 'TULSA',
        RegAddrState: 'OK',
        RegAddrZip: 74137,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '8845 E 46TH PL',
        RegAddrCity: 'TULSA',
        RegAddrState: 'OK',
        RegAddrZip: 74145,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '7225 E 32ND PL',
        RegAddrCity: 'TULSA',
        RegAddrState: 'OK',
        RegAddrZip: 74145,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2328 DOVE CREEK DR',
        RegAddrCity: 'TUTTLE',
        RegAddrState: 'OK',
        RegAddrZip: 73089,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '3770 E 80TH ST N',
        RegAddrCity: 'WAGONER',
        RegAddrState: 'OK',
        RegAddrZip: 74467,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '68415 S 316 CT',
        RegAddrCity: 'WAGONER',
        RegAddrState: 'OK',
        RegAddrZip: 74467,
        CongressionalDistrict: 1,
      },
    ],
  },
  {
    name: 'OK-2',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/OK-2?ref=gh-pages',
    state: 'OK',
    state_and_number: 'OK-2',
    zip: 73401,
    congressionalDistrict: 2,
    addressesInDistrict: [
      {
        RegAddrLine1: '30585 S 4480 RD',
        RegAddrCity: 'AFTON',
        RegAddrState: 'OK',
        RegAddrZip: 74331,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '801 SE 6TH ST',
        RegAddrCity: 'ANTLERS',
        RegAddrState: 'OK',
        RegAddrZip: 74523,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '13378 S FORREST HILL RD',
        RegAddrCity: 'ATOKA',
        RegAddrState: 'OK',
        RegAddrZip: 74525,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '3648 S SHAW RD',
        RegAddrCity: 'ATOKA',
        RegAddrState: 'OK',
        RegAddrZip: 74525,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '5403 N COAL CREEK RD',
        RegAddrCity: 'ATOKA',
        RegAddrState: 'OK',
        RegAddrZip: 74525,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '404 W 11TH ST',
        RegAddrCity: 'BEGGS',
        RegAddrState: 'OK',
        RegAddrZip: 74421,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '33777 S 4390 RD',
        RegAddrCity: 'BIG CABIN',
        RegAddrState: 'OK',
        RegAddrZip: 74332,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '75 B ST',
        RegAddrCity: 'CALERA',
        RegAddrState: 'OK',
        RegAddrZip: 74730,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: 'OAKWOOD HBR',
        RegAddrCity: 'CHECOTAH',
        RegAddrState: 'OK',
        RegAddrZip: 74426,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '8310 S 4220TH RD',
        RegAddrCity: 'CHELSEA',
        RegAddrState: 'OK',
        RegAddrZip: 74016,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '215 E ORR ST',
        RegAddrCity: 'CHOUTEAU',
        RegAddrState: 'OK',
        RegAddrZip: 74337,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1404 N MIAMI ST',
        RegAddrCity: 'CHOUTEAU',
        RegAddrState: 'OK',
        RegAddrZip: 74337,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '18177 S QUAIL MEADOW DR',
        RegAddrCity: 'CLAREMORE',
        RegAddrState: 'OK',
        RegAddrZip: 74017,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1329 N MCFARLAND PL',
        RegAddrCity: 'CLAREMORE',
        RegAddrState: 'OK',
        RegAddrZip: 74017,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '18606 S OAKRIDGE WAY',
        RegAddrCity: 'CLAREMORE',
        RegAddrState: 'OK',
        RegAddrZip: 74017,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '18103 S 4180 RD',
        RegAddrCity: 'CLAREMORE',
        RegAddrState: 'OK',
        RegAddrZip: 74017,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '14123 E 480 RD',
        RegAddrCity: 'CLAREMORE',
        RegAddrState: 'OK',
        RegAddrZip: 74017,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '10018 N 209 E AVE',
        RegAddrCity: 'CLAREMORE',
        RegAddrState: 'OK',
        RegAddrZip: 74019,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '25472 HUMPHRIES CIR',
        RegAddrCity: 'CLAREMORE',
        RegAddrState: 'OK',
        RegAddrZip: 74019,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '25609 S GREENWAY DR',
        RegAddrCity: 'CLAREMORE',
        RegAddrState: 'OK',
        RegAddrZip: 74019,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1 S MICHIGAN ST',
        RegAddrCity: 'COALGATE',
        RegAddrState: 'OK',
        RegAddrZip: 74538,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: 'SEC 13 01N 10E',
        RegAddrCity: 'COALGATE',
        RegAddrState: 'OK',
        RegAddrZip: 74538,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '308 N FRANKLIN ST',
        RegAddrCity: 'COLBERT',
        RegAddrState: 'OK',
        RegAddrZip: 74733,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '317 S CEDAR ST',
        RegAddrCity: 'COMMERCE',
        RegAddrState: 'OK',
        RegAddrZip: 74339,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '7212 US HWY 377N',
        RegAddrCity: 'CONNERVILLE',
        RegAddrState: 'OK',
        RegAddrZip: 74836,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '31775 S 533 RD',
        RegAddrCity: 'COOKSON',
        RegAddrState: 'OK',
        RegAddrZip: 74427,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '36695 E 141ST ST S',
        RegAddrCity: 'COWETA',
        RegAddrState: 'OK',
        RegAddrZip: 74429,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '306 W 6TH ST',
        RegAddrCity: 'DEWAR',
        RegAddrState: 'OK',
        RegAddrZip: 74431,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '812 STONEBROOK WAY',
        RegAddrCity: 'DURANT',
        RegAddrState: 'OK',
        RegAddrZip: 74701,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1425 FOLSOM RD',
        RegAddrCity: 'DURANT',
        RegAddrState: 'OK',
        RegAddrZip: 74701,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '910 W MULBERRY ST',
        RegAddrCity: 'DURANT',
        RegAddrState: 'OK',
        RegAddrZip: 74701,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '724 RIDGE CREST RD',
        RegAddrCity: 'EUCHA',
        RegAddrState: 'OK',
        RegAddrZip: 74342,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '25816 S 622 RD',
        RegAddrCity: 'GROVE',
        RegAddrState: 'OK',
        RegAddrZip: 74344,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '60703 E 323 RD',
        RegAddrCity: 'GROVE',
        RegAddrState: 'OK',
        RegAddrZip: 74344,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '65690 E 254 RD',
        RegAddrCity: 'GROVE',
        RegAddrState: 'OK',
        RegAddrZip: 74344,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '553 CARPENTERS BLUFF RD',
        RegAddrCity: 'HENDRIX',
        RegAddrState: 'OK',
        RegAddrZip: 74741,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '32050 S 200 RD',
        RegAddrCity: 'HENRYETTA',
        RegAddrState: 'OK',
        RegAddrZip: 74437,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1002 S LUTHER ST',
        RegAddrCity: 'HUGO',
        RegAddrState: 'OK',
        RegAddrZip: 74743,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '702 N F ST',
        RegAddrCity: 'HUGO',
        RegAddrState: 'OK',
        RegAddrZip: 74743,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2108 MEADOWLARK LN',
        RegAddrCity: 'IDABEL',
        RegAddrState: 'OK',
        RegAddrZip: 74745,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '301 NE 4TH ST',
        RegAddrCity: 'IDABEL',
        RegAddrState: 'OK',
        RegAddrZip: 74745,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '41987 S 610TH RD',
        RegAddrCity: 'JAY',
        RegAddrState: 'OK',
        RegAddrZip: 74346,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '57110 US HIGHWAY 59',
        RegAddrCity: 'KANSAS',
        RegAddrState: 'OK',
        RegAddrZip: 74347,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '45 MI ECR 1195D RD',
        RegAddrCity: 'KEOTA',
        RegAddrState: 'OK',
        RegAddrZip: 74941,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '4105 MAGIC PATH',
        RegAddrCity: 'KINGSTON',
        RegAddrState: 'OK',
        RegAddrZip: 73439,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '589 EAGLE POINT RD',
        RegAddrCity: 'LANGLEY',
        RegAddrState: 'OK',
        RegAddrZip: 74350,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '9725 S 445 RD',
        RegAddrCity: 'LOCUST GROVE',
        RegAddrState: 'OK',
        RegAddrZip: 74352,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2596 E 599',
        RegAddrCity: 'LOCUST GROVE',
        RegAddrState: 'OK',
        RegAddrZip: 74352,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '274 W TURNPIKE RD',
        RegAddrCity: 'MCALESTER',
        RegAddrState: 'OK',
        RegAddrZip: 74501,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '219 E ELECTRIC AVE',
        RegAddrCity: 'MCALESTER',
        RegAddrState: 'OK',
        RegAddrZip: 74501,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1520 S 9TH ST',
        RegAddrCity: 'MCALESTER',
        RegAddrState: 'OK',
        RegAddrZip: 74501,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '609 C ST SE',
        RegAddrCity: 'MIAMI',
        RegAddrState: 'OK',
        RegAddrZip: 74354,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '315 B ST NE',
        RegAddrCity: 'MIAMI',
        RegAddrState: 'OK',
        RegAddrZip: 74354,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '53630 E 120 RD',
        RegAddrCity: 'MIAMI',
        RegAddrState: 'OK',
        RegAddrZip: 74354,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '104 S HUGHES AVE',
        RegAddrCity: 'MORRIS',
        RegAddrState: 'OK',
        RegAddrZip: 74445,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '3507 BREWER ST',
        RegAddrCity: 'MUSKOGEE',
        RegAddrState: 'OK',
        RegAddrZip: 74401,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2422 OKLAHOMA ST',
        RegAddrCity: 'MUSKOGEE',
        RegAddrState: 'OK',
        RegAddrZip: 74401,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '3500 HYDE PARK AVE',
        RegAddrCity: 'MUSKOGEE',
        RegAddrState: 'OK',
        RegAddrZip: 74403,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '3602 CLUB ESTATES DR',
        RegAddrCity: 'MUSKOGEE',
        RegAddrState: 'OK',
        RegAddrZip: 74403,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '4400 GIBSON ST',
        RegAddrCity: 'MUSKOGEE',
        RegAddrState: 'OK',
        RegAddrZip: 74403,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2291 E 93RD ST S',
        RegAddrCity: 'MUSKOGEE',
        RegAddrState: 'OK',
        RegAddrZip: 74403,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '531 FRANKFORT AVE',
        RegAddrCity: 'MUSKOGEE',
        RegAddrState: 'OK',
        RegAddrZip: 74403,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '378130 E 980 RD',
        RegAddrCity: 'OKEMAH',
        RegAddrState: 'OK',
        RegAddrZip: 74859,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '107111 N 3780 RD',
        RegAddrCity: 'OKEMAH',
        RegAddrState: 'OK',
        RegAddrZip: 74859,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '518 S 5TH ST',
        RegAddrCity: 'OKEMAH',
        RegAddrState: 'OK',
        RegAddrZip: 74859,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '25326 WILSON RD',
        RegAddrCity: 'OKMULGEE',
        RegAddrState: 'OK',
        RegAddrZip: 74447,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '300 W 8TH ST',
        RegAddrCity: 'PADEN',
        RegAddrState: 'OK',
        RegAddrZip: 74860,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '443574 HIGHWAY 28',
        RegAddrCity: 'PENSACOLA',
        RegAddrState: 'OK',
        RegAddrZip: 74301,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '507 W MCKENZIE AVE',
        RegAddrCity: 'POCOLA',
        RegAddrState: 'OK',
        RegAddrZip: 74902,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '201 ELLIS LOOP',
        RegAddrCity: 'POCOLA',
        RegAddrState: 'OK',
        RegAddrZip: 74902,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '10062 E 263 ST S',
        RegAddrCity: 'PORUM',
        RegAddrState: 'OK',
        RegAddrZip: 74455,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '319 SE 8TH PL',
        RegAddrCity: 'PRYOR',
        RegAddrState: 'OK',
        RegAddrZip: 74361,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '6450 NE 270TH HWY',
        RegAddrCity: 'RED OAK',
        RegAddrState: 'OK',
        RegAddrZip: 74563,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '111904 S 4779 RD',
        RegAddrCity: 'ROLAND',
        RegAddrState: 'OK',
        RegAddrZip: 74954,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1709 N MAPLE ST',
        RegAddrCity: 'SALLISAW',
        RegAddrState: 'OK',
        RegAddrZip: 74955,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '104082 US HIGHWAY 59',
        RegAddrCity: 'SALLISAW',
        RegAddrState: 'OK',
        RegAddrZip: 74955,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '616 S HOLLY ST',
        RegAddrCity: 'SALLISAW',
        RegAddrState: 'OK',
        RegAddrZip: 74955,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '0 MAIN ST 400',
        RegAddrCity: 'SMITHVILLE',
        RegAddrState: 'OK',
        RegAddrZip: 74957,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '403 ETCHEN BLVD',
        RegAddrCity: "SO C'VILLE",
        RegAddrState: 'OK',
        RegAddrZip: 74072,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '414 N ASH ST',
        RegAddrCity: 'SPIRO',
        RegAddrState: 'OK',
        RegAddrZip: 74959,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2 MI N OF STID SCH N SD RD',
        RegAddrCity: 'STIDHAM',
        RegAddrState: 'OK',
        RegAddrZip: 74461,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '164 N BK 700 RD',
        RegAddrCity: 'STIGLER',
        RegAddrState: 'OK',
        RegAddrZip: 74462,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '506 NW A ST',
        RegAddrCity: 'STIGLER',
        RegAddrState: 'OK',
        RegAddrZip: 74462,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '6.5 S M HWY 59 ST',
        RegAddrCity: 'STILWELL',
        RegAddrState: 'OK',
        RegAddrZip: 74960,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '202 REMINGTON PL',
        RegAddrCity: 'TAHLEQUAH',
        RegAddrState: 'OK',
        RegAddrZip: 74464,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '403 EARL ST',
        RegAddrCity: 'TAHLEQUAH',
        RegAddrState: 'OK',
        RegAddrZip: 74464,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '612 N LEWIS AVE',
        RegAddrCity: 'TAHLEQUAH',
        RegAddrState: 'OK',
        RegAddrZip: 74464,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1000 JACKSON AVE',
        RegAddrCity: 'TAHLEQUAH',
        RegAddrState: 'OK',
        RegAddrZip: 74464,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '3300 S PENNINGTON CREEK RD',
        RegAddrCity: 'TISHOMINGO',
        RegAddrState: 'OK',
        RegAddrZip: 73460,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '4465 US HIGHWAY 70 W',
        RegAddrCity: 'VALLIANT',
        RegAddrState: 'OK',
        RegAddrZip: 74764,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '456242 E 966 RD',
        RegAddrCity: 'VIAN',
        RegAddrState: 'OK',
        RegAddrZip: 74962,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '435226 E 310 RD',
        RegAddrCity: 'VINITA',
        RegAddrState: 'OK',
        RegAddrZip: 74301,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '501 S THOMPSON ST',
        RegAddrCity: 'VINITA',
        RegAddrState: 'OK',
        RegAddrZip: 74301,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '192 N SPRINGFIELD ST',
        RegAddrCity: 'WELCH',
        RegAddrState: 'OK',
        RegAddrZip: 74369,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2454 NE 113TH RD',
        RegAddrCity: 'WILBURTON',
        RegAddrState: 'OK',
        RegAddrZip: 74578,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2163 SW LIMESTONE RD',
        RegAddrCity: 'WILBURTON',
        RegAddrState: 'OK',
        RegAddrZip: 74578,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1304 N MAGNOLIA AVE',
        RegAddrCity: 'WISTER',
        RegAddrState: 'OK',
        RegAddrZip: 74966,
        CongressionalDistrict: 2,
      },
    ],
  },
  {
    name: 'OK-3',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/OK-3?ref=gh-pages',
    state: 'OK',
    state_and_number: 'OK-3',
    zip: 73005,
    congressionalDistrict: 3,
    addressesInDistrict: [
      {
        RegAddrLine1: '1609 CLEVELAND ST',
        RegAddrCity: 'ALTUS',
        RegAddrState: 'OK',
        RegAddrZip: 73521,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '800 S PARK LN',
        RegAddrCity: 'ALTUS',
        RegAddrState: 'OK',
        RegAddrZip: 73521,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '303 E WALNUT ST',
        RegAddrCity: 'ALTUS',
        RegAddrState: 'OK',
        RegAddrZip: 73521,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '305 LIBRA ST',
        RegAddrCity: 'ALTUS',
        RegAddrState: 'OK',
        RegAddrZip: 73521,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1501 YOUNG ST',
        RegAddrCity: 'ALVA',
        RegAddrState: 'OK',
        RegAddrZip: 73717,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1205 10TH ST',
        RegAddrCity: 'ALVA',
        RegAddrState: 'OK',
        RegAddrZip: 73717,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '829 5TH ST',
        RegAddrCity: 'ALVA',
        RegAddrState: 'OK',
        RegAddrZip: 73717,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '214 E MORAN DR',
        RegAddrCity: 'ANADARKO',
        RegAddrState: 'OK',
        RegAddrZip: 73005,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '225 N COBLAKE ST',
        RegAddrCity: 'APACHE',
        RegAddrState: 'OK',
        RegAddrZip: 73006,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '41166 CS 2510',
        RegAddrCity: 'APACHE',
        RegAddrState: 'OK',
        RegAddrZip: 73006,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '232 W 8TH AVE',
        RegAddrCity: 'BRISTOW',
        RegAddrState: 'OK',
        RegAddrZip: 74010,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '408 E 9TH AVE',
        RegAddrCity: 'BRISTOW',
        RegAddrState: 'OK',
        RegAddrZip: 74010,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '500 E 1ST AVE',
        RegAddrCity: 'BRISTOW',
        RegAddrState: 'OK',
        RegAddrZip: 74010,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '204 POTOMAC ST B',
        RegAddrCity: 'BURNS FLAT',
        RegAddrState: 'OK',
        RegAddrZip: 73624,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: 'NW/4 SEC 21-16-3',
        RegAddrCity: 'CARNEY',
        RegAddrState: 'OK',
        RegAddrZip: 74832,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '4S S 1 12W 3/4S',
        RegAddrCity: 'CHANDLER',
        RegAddrState: 'OK',
        RegAddrZip: 74834,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '17762 E 950 RD',
        RegAddrCity: 'CHEYENNE',
        RegAddrState: 'OK',
        RegAddrZip: 73628,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '311 S JORDAN ST',
        RegAddrCity: 'CLEVELAND',
        RegAddrState: 'OK',
        RegAddrZip: 74020,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1215 BROADWAY AVE',
        RegAddrCity: 'CLINTON',
        RegAddrState: 'OK',
        RegAddrZip: 73601,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '619 S 19TH ST',
        RegAddrCity: 'CLINTON',
        RegAddrState: 'OK',
        RegAddrZip: 73601,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '912 N 19TH ST',
        RegAddrCity: 'CLINTON',
        RegAddrState: 'OK',
        RegAddrZip: 73601,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '11672 HIGHWAY 54',
        RegAddrCity: 'COLONY',
        RegAddrState: 'OK',
        RegAddrZip: 73021,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '406 W JEFFERSON ST',
        RegAddrCity: 'COVINGTON',
        RegAddrState: 'OK',
        RegAddrZip: 73730,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '935 E MAIN ST',
        RegAddrCity: 'CUSHING',
        RegAddrState: 'OK',
        RegAddrZip: 74023,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '705 S ROCK ISLAND AVE',
        RegAddrCity: 'EL RENO',
        RegAddrState: 'OK',
        RegAddrZip: 73036,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1401 RINEHART DR',
        RegAddrCity: 'EL RENO',
        RegAddrState: 'OK',
        RegAddrZip: 73036,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1011 W B AVE',
        RegAddrCity: 'ELK CITY',
        RegAddrState: 'OK',
        RegAddrZip: 73644,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '423 W INDIANA AVE',
        RegAddrCity: 'ENID',
        RegAddrState: 'OK',
        RegAddrZip: 73701,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1713 N MEADOWBROOK DR',
        RegAddrCity: 'ENID',
        RegAddrState: 'OK',
        RegAddrZip: 73701,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '305 W HICKORY AVE',
        RegAddrCity: 'ENID',
        RegAddrState: 'OK',
        RegAddrZip: 73701,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1222 W OWEN K GARRIOTT RD',
        RegAddrCity: 'ENID',
        RegAddrState: 'OK',
        RegAddrZip: 73703,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '2601 STRATFORD DR',
        RegAddrCity: 'ENID',
        RegAddrState: 'OK',
        RegAddrZip: 73703,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '314 S SADDLE RD',
        RegAddrCity: 'ENID',
        RegAddrState: 'OK',
        RegAddrZip: 73703,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '27 LEGION DR',
        RegAddrCity: 'FAIRVIEW',
        RegAddrState: 'OK',
        RegAddrZip: 73737,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '12848 HIGHWAY 60',
        RegAddrCity: 'FAIRVIEW',
        RegAddrState: 'OK',
        RegAddrZip: 73737,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '403 W INDEPENDENCE',
        RegAddrCity: 'GAGE',
        RegAddrState: 'OK',
        RegAddrZip: 73843,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1402 E CLEVELAND AVE',
        RegAddrCity: 'GUTHRIE',
        RegAddrState: 'OK',
        RegAddrZip: 73044,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '617 E CLEVELAND AVE',
        RegAddrCity: 'GUTHRIE',
        RegAddrState: 'OK',
        RegAddrZip: 73044,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '2250 W COUNTY ROAD 75',
        RegAddrCity: 'GUTHRIE',
        RegAddrState: 'OK',
        RegAddrZip: 73044,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '5345 KAPALUA CT',
        RegAddrCity: 'GUTHRIE',
        RegAddrState: 'OK',
        RegAddrZip: 73044,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '707 N ROOSEVELT ST',
        RegAddrCity: 'GUYMON',
        RegAddrState: 'OK',
        RegAddrZip: 73942,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '629 NE 17TH ST',
        RegAddrCity: 'GUYMON',
        RegAddrState: 'OK',
        RegAddrZip: 73942,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '521 NW 9TH ST',
        RegAddrCity: 'GUYMON',
        RegAddrState: 'OK',
        RegAddrZip: 73942,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '81/51 4 1/2 W2',
        RegAddrCity: 'HENNESSEY',
        RegAddrState: 'OK',
        RegAddrZip: 73742,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1012 W 5TH ST',
        RegAddrCity: 'HOBART',
        RegAddrState: 'OK',
        RegAddrZip: 73651,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '4473 STATE HIGHWAY 99',
        RegAddrCity: 'HOMINY',
        RegAddrState: 'OK',
        RegAddrZip: 74035,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '55629 S 3606TH RD',
        RegAddrCity: 'JENNINGS',
        RegAddrState: 'OK',
        RegAddrZip: 74038,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '120 N 4TH ST',
        RegAddrCity: 'JET',
        RegAddrState: 'OK',
        RegAddrZip: 73749,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: 'SEC25-T16N-R19W',
        RegAddrCity: 'LEEDEY',
        RegAddrState: 'OK',
        RegAddrZip: 73654,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '27709 COUNTY ROAD 950',
        RegAddrCity: 'MEDFORD',
        RegAddrState: 'OK',
        RegAddrZip: 73759,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '213233 E COUNTY ROAD 58',
        RegAddrCity: 'MUTUAL',
        RegAddrState: 'OK',
        RegAddrZip: 73853,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '3104 ROCKHAMPTON AVE',
        RegAddrCity: 'OKLAHOMA CITY',
        RegAddrState: 'OK',
        RegAddrZip: 73179,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '16292 S COUNTY ROAD 198',
        RegAddrCity: 'OLUSTEE',
        RegAddrState: 'OK',
        RegAddrZip: 73560,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1723 CLAREMORE AVE',
        RegAddrCity: 'PAWHUSKA',
        RegAddrState: 'OK',
        RegAddrZip: 74056,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1107 CROZIER ST',
        RegAddrCity: 'PERKINS',
        RegAddrState: 'OK',
        RegAddrZip: 74059,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1403 PECAN ST',
        RegAddrCity: 'PERRY',
        RegAddrState: 'OK',
        RegAddrZip: 73077,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '528 S 14TH ST',
        RegAddrCity: 'PONCA CITY',
        RegAddrState: 'OK',
        RegAddrZip: 74601,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '60 WHITE EAGLE DR',
        RegAddrCity: 'PONCA CITY',
        RegAddrState: 'OK',
        RegAddrZip: 74601,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '158 OAKDALE RD',
        RegAddrCity: 'PONCA CITY',
        RegAddrState: 'OK',
        RegAddrZip: 74604,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '2553 OLIVEWOOD CT',
        RegAddrCity: 'PONCA CITY',
        RegAddrState: 'OK',
        RegAddrZip: 74604,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '201 N GUSS AVE',
        RegAddrCity: 'RIPLEY',
        RegAddrState: 'OK',
        RegAddrZip: 74062,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '9501 WIND RIDGE DR',
        RegAddrCity: 'SAND SPRINGS',
        RegAddrState: 'OK',
        RegAddrZip: 74063,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '4853 ROBERT E LEE TER',
        RegAddrCity: 'SAND SPRINGS',
        RegAddrState: 'OK',
        RegAddrZip: 74063,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '515 N HODGE ST',
        RegAddrCity: 'SAPULPA',
        RegAddrState: 'OK',
        RegAddrZip: 74066,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '120 SAHOMA LAKE RD',
        RegAddrCity: 'SAPULPA',
        RegAddrState: 'OK',
        RegAddrZip: 74066,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '27390 W 33RD HWY',
        RegAddrCity: 'SAPULPA',
        RegAddrState: 'OK',
        RegAddrZip: 74066,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '15496 W 188 SOUTH PL 82',
        RegAddrCity: 'SAPULPA',
        RegAddrState: 'OK',
        RegAddrZip: 74066,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '711 W MARY LYNN DR',
        RegAddrCity: 'SAPULPA',
        RegAddrState: 'OK',
        RegAddrZip: 74066,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '2112 S JAVINE CT',
        RegAddrCity: 'SKIATOOK',
        RegAddrState: 'OK',
        RegAddrZip: 74070,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '3613 W CHESTNUT ST',
        RegAddrCity: 'SKIATOOK',
        RegAddrState: 'OK',
        RegAddrZip: 74070,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1601 S BOULDER CREEK DR',
        RegAddrCity: 'STILLWATER',
        RegAddrState: 'OK',
        RegAddrZip: 74074,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '3615 W 15TH AVE',
        RegAddrCity: 'STILLWATER',
        RegAddrState: 'OK',
        RegAddrZip: 74074,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '3309 S WEST ST',
        RegAddrCity: 'STILLWATER',
        RegAddrState: 'OK',
        RegAddrZip: 74074,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '27 SUMMIT CIR',
        RegAddrCity: 'STILLWATER',
        RegAddrState: 'OK',
        RegAddrZip: 74075,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '301 E MARIE DR',
        RegAddrCity: 'STILLWATER',
        RegAddrState: 'OK',
        RegAddrZip: 74075,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1211 DRUMMOND HALL',
        RegAddrCity: 'STILLWATER',
        RegAddrState: 'OK',
        RegAddrZip: 74077,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '635 S 433RD WEST AVE',
        RegAddrCity: 'TERLTON',
        RegAddrState: 'OK',
        RegAddrZip: 74081,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '320 W 11TH ST',
        RegAddrCity: 'WATONGA',
        RegAddrState: 'OK',
        RegAddrZip: 73772,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '412 W 4TH ST',
        RegAddrCity: 'WATONGA',
        RegAddrState: 'OK',
        RegAddrZip: 73772,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '716 N WORKMAN AVE',
        RegAddrCity: 'WATONGA',
        RegAddrState: 'OK',
        RegAddrZip: 73772,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '116 E TOM STAFFORD ST',
        RegAddrCity: 'WEATHERFORD',
        RegAddrState: 'OK',
        RegAddrZip: 73096,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1213 SYCAMORE ST',
        RegAddrCity: 'WEATHERFORD',
        RegAddrState: 'OK',
        RegAddrZip: 73096,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '940386 S 3340TH RD',
        RegAddrCity: 'WELLSTON',
        RegAddrState: 'OK',
        RegAddrZip: 74881,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '335130 E 880 RD',
        RegAddrCity: 'WELLSTON',
        RegAddrState: 'OK',
        RegAddrZip: 74881,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '404 FIR ST',
        RegAddrCity: 'WELLSTON',
        RegAddrState: 'OK',
        RegAddrZip: 74881,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1233 W WOODLAWN ST',
        RegAddrCity: 'WESTPORT',
        RegAddrState: 'OK',
        RegAddrZip: 74020,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '11737 SW 25TH TER',
        RegAddrCity: 'YUKON',
        RegAddrState: 'OK',
        RegAddrZip: 73099,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '10301 SUNDANCE DR',
        RegAddrCity: 'YUKON',
        RegAddrState: 'OK',
        RegAddrZip: 73099,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '11925 NW 7TH ST',
        RegAddrCity: 'YUKON',
        RegAddrState: 'OK',
        RegAddrZip: 73099,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '136 E VAIL DR',
        RegAddrCity: 'YUKON',
        RegAddrState: 'OK',
        RegAddrZip: 73099,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '9904 EASTBLAKE LANDING RD',
        RegAddrCity: 'YUKON',
        RegAddrState: 'OK',
        RegAddrZip: 73099,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '4151 COVE DR',
        RegAddrCity: 'YUKON',
        RegAddrState: 'OK',
        RegAddrZip: 73099,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '13028 SW 4TH ST',
        RegAddrCity: 'YUKON',
        RegAddrState: 'OK',
        RegAddrZip: 73099,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '11715 HOLLYROCK CT',
        RegAddrCity: 'YUKON',
        RegAddrState: 'OK',
        RegAddrZip: 73099,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '2012 MACKENZIE WAY',
        RegAddrCity: 'YUKON',
        RegAddrState: 'OK',
        RegAddrZip: 73099,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '4917 BYRON CIR',
        RegAddrCity: 'YUKON',
        RegAddrState: 'OK',
        RegAddrZip: 73099,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '736 KINGSTON DR',
        RegAddrCity: 'YUKON',
        RegAddrState: 'OK',
        RegAddrZip: 73099,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1109 MOOSE ST',
        RegAddrCity: 'YUKON',
        RegAddrState: 'OK',
        RegAddrZip: 73099,
        CongressionalDistrict: 3,
      },
    ],
  },
  {
    name: 'OK-4',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/OK-4?ref=gh-pages',
    state: 'OK',
    state_and_number: 'OK-4',
    zip: 73002,
    congressionalDistrict: 4,
    addressesInDistrict: [
      {
        RegAddrLine1: '319 E 16TH ST',
        RegAddrCity: 'ADA',
        RegAddrState: 'OK',
        RegAddrZip: 74820,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '19854 COUNTY ROAD 1542',
        RegAddrCity: 'ADA',
        RegAddrState: 'OK',
        RegAddrZip: 74820,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '18220 COUNTY ROAD 3570',
        RegAddrCity: 'ADA',
        RegAddrState: 'OK',
        RegAddrZip: 74820,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '17865 COUNTY ROAD 1500',
        RegAddrCity: 'ADA',
        RegAddrState: 'OK',
        RegAddrZip: 74820,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '13710 COUNTY ROAD 1550',
        RegAddrCity: 'ADA',
        RegAddrState: 'OK',
        RegAddrZip: 74820,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '530 VALLEY RANCH RD',
        RegAddrCity: 'ARDMORE',
        RegAddrState: 'OK',
        RegAddrZip: 73401,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2601 DICKSON RD',
        RegAddrCity: 'ARDMORE',
        RegAddrState: 'OK',
        RegAddrZip: 73401,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2209 SAM NOBLE PKWY',
        RegAddrCity: 'ARDMORE',
        RegAddrState: 'OK',
        RegAddrZip: 73401,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '106 G NW ST',
        RegAddrCity: 'ARDMORE',
        RegAddrState: 'OK',
        RegAddrZip: 73401,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '325 12 SE AVE',
        RegAddrCity: 'ARDMORE',
        RegAddrState: 'OK',
        RegAddrZip: 73401,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1015 S COMMERCE ST',
        RegAddrCity: 'ARDMORE',
        RegAddrState: 'OK',
        RegAddrZip: 73401,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '203 SE 4TH ST',
        RegAddrCity: 'BLANCHARD',
        RegAddrState: 'OK',
        RegAddrZip: 73010,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2026 S COUNTY LINE AVE',
        RegAddrCity: 'BLANCHARD',
        RegAddrState: 'OK',
        RegAddrZip: 73010,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '15624 230TH ST',
        RegAddrCity: 'BLANCHARD',
        RegAddrState: 'OK',
        RegAddrZip: 73010,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1067 COUNTY ROAD 1383',
        RegAddrCity: 'CHICKASHA',
        RegAddrState: 'OK',
        RegAddrZip: 73018,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '421 W ILLINOIS AVE',
        RegAddrCity: 'CHICKASHA',
        RegAddrState: 'OK',
        RegAddrZip: 73018,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2814 CS 2870 ST',
        RegAddrCity: 'CHICKASHA',
        RegAddrState: 'OK',
        RegAddrZip: 73018,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '106 CIRCLE DR',
        RegAddrCity: 'DAVIS',
        RegAddrState: 'OK',
        RegAddrZip: 73030,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '3221 GLENWOOD CT',
        RegAddrCity: 'DUNCAN',
        RegAddrState: 'OK',
        RegAddrZip: 73533,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2810 STAGESTAND RD',
        RegAddrCity: 'DUNCAN',
        RegAddrState: 'OK',
        RegAddrZip: 73533,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '806 W ELM AVE',
        RegAddrCity: 'DUNCAN',
        RegAddrState: 'OK',
        RegAddrZip: 73533,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '178254 N 2900 RD',
        RegAddrCity: 'DUNCAN',
        RegAddrState: 'OK',
        RegAddrZip: 73533,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '613 N K ST',
        RegAddrCity: 'DUNCAN',
        RegAddrState: 'OK',
        RegAddrZip: 73533,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '37 NE SUNSET DR',
        RegAddrCity: 'ELGIN',
        RegAddrState: 'OK',
        RegAddrZip: 73538,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '404 S TEXAS ST',
        RegAddrCity: 'ELMORE CITY',
        RegAddrState: 'OK',
        RegAddrZip: 73433,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '312 SUNNYBROOK DR',
        RegAddrCity: 'FREDERICK',
        RegAddrState: 'OK',
        RegAddrZip: 73542,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '912 E CALLA AVE',
        RegAddrCity: 'FREDERICK',
        RegAddrState: 'OK',
        RegAddrZip: 73542,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '22373 COUNTY ROAD EW 180',
        RegAddrCity: 'FREDERICK',
        RegAddrState: 'OK',
        RegAddrZip: 73542,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '189 E MAPLE RD',
        RegAddrCity: 'GOLDSBY',
        RegAddrState: 'OK',
        RegAddrZip: 73093,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '368 LAKEVIEW AVE',
        RegAddrCity: 'HEALDTON',
        RegAddrState: 'OK',
        RegAddrZip: 73438,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '5911 NW DEARBORN AVE',
        RegAddrCity: 'LAWTON',
        RegAddrState: 'OK',
        RegAddrZip: 73505,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '4637 NW SANTA FE AVE',
        RegAddrCity: 'LAWTON',
        RegAddrState: 'OK',
        RegAddrZip: 73505,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '343 NW 65TH ST',
        RegAddrCity: 'LAWTON',
        RegAddrState: 'OK',
        RegAddrZip: 73505,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '3907 SW PARKRIDGE BLVD',
        RegAddrCity: 'LAWTON',
        RegAddrState: 'OK',
        RegAddrZip: 73505,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '4 NW WINDMERE CIR',
        RegAddrCity: 'LAWTON',
        RegAddrState: 'OK',
        RegAddrZip: 73505,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '3410 NW WILLIAMS AVE',
        RegAddrCity: 'LAWTON',
        RegAddrState: 'OK',
        RegAddrZip: 73505,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '6412 NW ARROWHEAD DR',
        RegAddrCity: 'LAWTON',
        RegAddrState: 'OK',
        RegAddrZip: 73505,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '303 NW ARLINGTON AVE',
        RegAddrCity: 'LAWTON',
        RegAddrState: 'OK',
        RegAddrZip: 73507,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '304 MOUNT SHERIDAN RD',
        RegAddrCity: 'LAWTON',
        RegAddrState: 'OK',
        RegAddrZip: 73507,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '10201 60TH ST',
        RegAddrCity: 'LEXINGTON',
        RegAddrState: 'OK',
        RegAddrZip: 73051,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '401 E CENTER ST',
        RegAddrCity: 'LEXINGTON',
        RegAddrState: 'OK',
        RegAddrZip: 73051,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '14808 E COUNTY ROAD 1520',
        RegAddrCity: 'LINDSAY',
        RegAddrState: 'OK',
        RegAddrZip: 73052,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '57 JADE ST',
        RegAddrCity: 'LONE GROVE',
        RegAddrState: 'OK',
        RegAddrZip: 73443,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '132 FRUIT ST',
        RegAddrCity: 'LONE GROVE',
        RegAddrState: 'OK',
        RegAddrZip: 73443,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '195 GOOSEBERRY ST',
        RegAddrCity: 'LONE GROVE',
        RegAddrState: 'OK',
        RegAddrZip: 73443,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '406 N 8TH AVE',
        RegAddrCity: 'MARIETTA',
        RegAddrState: 'OK',
        RegAddrZip: 73448,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1401 S 9TH ST',
        RegAddrCity: 'MARLOW',
        RegAddrState: 'OK',
        RegAddrZip: 73055,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '294127 E 1650 RD',
        RegAddrCity: 'MARLOW',
        RegAddrState: 'OK',
        RegAddrZip: 73055,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '405 W ERCOUPE DR',
        RegAddrCity: 'MIDWEST CITY',
        RegAddrState: 'OK',
        RegAddrZip: 73110,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '3705 ROLLING LANE CIR',
        RegAddrCity: 'MIDWEST CITY',
        RegAddrState: 'OK',
        RegAddrZip: 73110,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '3709 N RIDGEWOOD DR',
        RegAddrCity: 'MIDWEST CITY',
        RegAddrState: 'OK',
        RegAddrZip: 73110,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '805 STANSELL DR',
        RegAddrCity: 'MIDWEST CITY',
        RegAddrState: 'OK',
        RegAddrZip: 73110,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2361 TURTLEWOOD RIVER RD',
        RegAddrCity: 'MIDWEST CITY',
        RegAddrState: 'OK',
        RegAddrZip: 73130,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '10412 SE 25TH ST',
        RegAddrCity: 'MIDWEST CITY',
        RegAddrState: 'OK',
        RegAddrZip: 73130,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '11637 MARK ST',
        RegAddrCity: 'MIDWEST CITY',
        RegAddrState: 'OK',
        RegAddrZip: 73130,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '10811 TURTLE BACK DR',
        RegAddrCity: 'MIDWEST CITY',
        RegAddrState: 'OK',
        RegAddrZip: 73130,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1605 SE 8TH ST',
        RegAddrCity: 'MOORE',
        RegAddrState: 'OK',
        RegAddrZip: 73160,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1713 GALE',
        RegAddrCity: 'MOORE',
        RegAddrState: 'OK',
        RegAddrZip: 73160,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '923 ASHWOOD LN',
        RegAddrCity: 'MOORE',
        RegAddrState: 'OK',
        RegAddrZip: 73160,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '501 NE 14TH ST',
        RegAddrCity: 'MOORE',
        RegAddrState: 'OK',
        RegAddrZip: 73160,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1401 CLEAR CREEK RD',
        RegAddrCity: 'MOORE',
        RegAddrState: 'OK',
        RegAddrZip: 73160,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '736 SW 4TH PL',
        RegAddrCity: 'MOORE',
        RegAddrState: 'OK',
        RegAddrZip: 73160,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2213 OAK DR',
        RegAddrCity: 'MOORE',
        RegAddrState: 'OK',
        RegAddrZip: 73170,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2135 GOODRICH DR',
        RegAddrCity: 'MOORE',
        RegAddrState: 'OK',
        RegAddrZip: 73170,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '512 W PERRY DR',
        RegAddrCity: 'MUSTANG',
        RegAddrState: 'OK',
        RegAddrZip: 73064,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '19132 SE 149TH ST',
        RegAddrCity: 'NEWALLA',
        RegAddrState: 'OK',
        RegAddrZip: 74857,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1317 SPRINGLAKE RD',
        RegAddrCity: 'NEWCASTLE',
        RegAddrState: 'OK',
        RegAddrZip: 73065,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '3800 NW 32ND ST',
        RegAddrCity: 'NEWCASTLE',
        RegAddrState: 'OK',
        RegAddrZip: 73065,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1416 BRENT MOORE CT',
        RegAddrCity: 'NEWCASTLE',
        RegAddrState: 'OK',
        RegAddrZip: 73065,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '9750 KYLE DR',
        RegAddrCity: 'NORMAN',
        RegAddrState: 'OK',
        RegAddrZip: 73026,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '822 W BROOKS ST',
        RegAddrCity: 'NORMAN',
        RegAddrState: 'OK',
        RegAddrZip: 73069,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1118 W COMANCHE ST',
        RegAddrCity: 'NORMAN',
        RegAddrState: 'OK',
        RegAddrZip: 73069,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1128 MCGEE DR',
        RegAddrCity: 'NORMAN',
        RegAddrState: 'OK',
        RegAddrZip: 73069,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '718 W HUGHBERT ST',
        RegAddrCity: 'NORMAN',
        RegAddrState: 'OK',
        RegAddrZip: 73069,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1708 WINDHAM CT',
        RegAddrCity: 'NORMAN',
        RegAddrState: 'OK',
        RegAddrZip: 73071,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2 WOODCREST CIR',
        RegAddrCity: 'NORMAN',
        RegAddrState: 'OK',
        RegAddrZip: 73071,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1605 SUNRISE ST',
        RegAddrCity: 'NORMAN',
        RegAddrState: 'OK',
        RegAddrZip: 73071,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1513 GOLDFINCH CT',
        RegAddrCity: 'NORMAN',
        RegAddrState: 'OK',
        RegAddrZip: 73071,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '4403 WILLOWPOINT DR',
        RegAddrCity: 'NORMAN',
        RegAddrState: 'OK',
        RegAddrZip: 73072,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1104 FOUNTAIN GATE CT',
        RegAddrCity: 'NORMAN',
        RegAddrState: 'OK',
        RegAddrZip: 73072,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '18402 BLACK BEAR TRL',
        RegAddrCity: 'NORMAN',
        RegAddrState: 'OK',
        RegAddrZip: 73072,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '604 WILLOWBEND RD',
        RegAddrCity: 'NORMAN',
        RegAddrState: 'OK',
        RegAddrZip: 73072,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '6609 BRANIFF DR',
        RegAddrCity: 'OKLAHOMA CITY',
        RegAddrState: 'OK',
        RegAddrZip: 73105,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2412 NW 12TH ST',
        RegAddrCity: 'OKLAHOMA CITY',
        RegAddrState: 'OK',
        RegAddrZip: 73107,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '6117 SE 55TH ST',
        RegAddrCity: 'OKLAHOMA CITY',
        RegAddrState: 'OK',
        RegAddrZip: 73135,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '712 SW 104TH PL',
        RegAddrCity: 'OKLAHOMA CITY',
        RegAddrState: 'OK',
        RegAddrZip: 73139,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '7113 LEVESCY RD',
        RegAddrCity: 'OKLAHOMA CITY',
        RegAddrState: 'OK',
        RegAddrZip: 73150,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '13313 SE 149TH ST',
        RegAddrCity: 'OKLAHOMA CITY',
        RegAddrState: 'OK',
        RegAddrZip: 73165,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '505 SW 156TH CT',
        RegAddrCity: 'OKLAHOMA CITY',
        RegAddrState: 'OK',
        RegAddrZip: 73170,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1304 SW 126TH ST',
        RegAddrCity: 'OKLAHOMA CITY',
        RegAddrState: 'OK',
        RegAddrZip: 73170,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1617 SW 113TH PL',
        RegAddrCity: 'OKLAHOMA CITY',
        RegAddrState: 'OK',
        RegAddrZip: 73170,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '11901 FORREST SPRING DR',
        RegAddrCity: 'OKLAHOMA CITY',
        RegAddrState: 'OK',
        RegAddrZip: 73173,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '388 COUNTY ROAD 1530',
        RegAddrCity: 'RUSH SPRINGS',
        RegAddrState: 'OK',
        RegAddrZip: 73082,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '3 ST',
        RegAddrCity: 'RYAN',
        RegAddrState: 'OK',
        RegAddrZip: 73565,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '606 W OKLAHOMA ST',
        RegAddrCity: 'TEMPLE',
        RegAddrState: 'OK',
        RegAddrZip: 73568,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '3017 E 37TH HWY',
        RegAddrCity: 'TUTTLE',
        RegAddrState: 'OK',
        RegAddrZip: 73089,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1902 EXCALIBER DR',
        RegAddrCity: 'TUTTLE',
        RegAddrState: 'OK',
        RegAddrZip: 73089,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '258160 E 1810 RD',
        RegAddrCity: 'WALTERS',
        RegAddrState: 'OK',
        RegAddrZip: 73572,
        CongressionalDistrict: 4,
      },
    ],
  },
  {
    name: 'OK-5',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/OK-5?ref=gh-pages',
    state: 'OK',
    state_and_number: 'OK-5',
    zip: 73003,
    congressionalDistrict: 5,
    addressesInDistrict: [
      {
        RegAddrLine1: '7604 NW 32ND ST',
        RegAddrCity: 'BETHANY',
        RegAddrState: 'OK',
        RegAddrZip: 73008,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '8108 NW 28TH TER',
        RegAddrCity: 'BETHANY',
        RegAddrState: 'OK',
        RegAddrZip: 73008,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '1346 S CHOCTAW RD',
        RegAddrCity: 'CHOCTAW',
        RegAddrState: 'OK',
        RegAddrZip: 73020,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '1709 CIMARRON TRL',
        RegAddrCity: 'CHOCTAW',
        RegAddrState: 'OK',
        RegAddrZip: 73020,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '3901 PINE AVE',
        RegAddrCity: 'DEL CITY',
        RegAddrState: 'OK',
        RegAddrZip: 73115,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '3932 SE 10TH PL',
        RegAddrCity: 'DEL CITY',
        RegAddrState: 'OK',
        RegAddrZip: 73115,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '3708 SE 23RD ST',
        RegAddrCity: 'DEL CITY',
        RegAddrState: 'OK',
        RegAddrZip: 73115,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '4804 SE 24TH ST',
        RegAddrCity: 'DEL CITY',
        RegAddrState: 'OK',
        RegAddrZip: 73115,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '2917 CEDARBEND CT',
        RegAddrCity: 'EDMOND',
        RegAddrState: 'OK',
        RegAddrZip: 73003,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '3805 HIGHRIDGE',
        RegAddrCity: 'EDMOND',
        RegAddrState: 'OK',
        RegAddrZip: 73003,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '432 HOLLOWDALE',
        RegAddrCity: 'EDMOND',
        RegAddrState: 'OK',
        RegAddrZip: 73003,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '1009 NW 166TH TER',
        RegAddrCity: 'EDMOND',
        RegAddrState: 'OK',
        RegAddrZip: 73012,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '17513 ZINC',
        RegAddrCity: 'EDMOND',
        RegAddrState: 'OK',
        RegAddrZip: 73012,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '17504 IRON LN',
        RegAddrCity: 'EDMOND',
        RegAddrState: 'OK',
        RegAddrZip: 73012,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '500 HARWARD CT',
        RegAddrCity: 'EDMOND',
        RegAddrState: 'OK',
        RegAddrZip: 73013,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '1709 SHADOW CT',
        RegAddrCity: 'EDMOND',
        RegAddrState: 'OK',
        RegAddrZip: 73013,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '2917 S BROKEN BOW RD',
        RegAddrCity: 'EDMOND',
        RegAddrState: 'OK',
        RegAddrZip: 73013,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '2708 AMESBURY LAKE DR',
        RegAddrCity: 'EDMOND',
        RegAddrState: 'OK',
        RegAddrZip: 73013,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '15901 SONADOR DR',
        RegAddrCity: 'EDMOND',
        RegAddrState: 'OK',
        RegAddrZip: 73013,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '16017 BRENTON CT',
        RegAddrCity: 'EDMOND',
        RegAddrState: 'OK',
        RegAddrZip: 73013,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '2108 S BOULEVARD',
        RegAddrCity: 'EDMOND',
        RegAddrState: 'OK',
        RegAddrZip: 73013,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '3120 TALON RD',
        RegAddrCity: 'EDMOND',
        RegAddrState: 'OK',
        RegAddrZip: 73013,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '2421 NW 151ST ST',
        RegAddrCity: 'EDMOND',
        RegAddrState: 'OK',
        RegAddrZip: 73013,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '3512 LYTAL LN',
        RegAddrCity: 'EDMOND',
        RegAddrState: 'OK',
        RegAddrZip: 73013,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '11701 BRAVADA DR',
        RegAddrCity: 'EDMOND',
        RegAddrState: 'OK',
        RegAddrZip: 73013,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '548 CONQUISTADOR CT',
        RegAddrCity: 'EDMOND',
        RegAddrState: 'OK',
        RegAddrZip: 73025,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '5720 N AIR DEPOT BLVD',
        RegAddrCity: 'EDMOND',
        RegAddrState: 'OK',
        RegAddrZip: 73034,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '2909 ASHETON CT',
        RegAddrCity: 'EDMOND',
        RegAddrState: 'OK',
        RegAddrZip: 73034,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '8203 REDHAWK LN',
        RegAddrCity: 'EDMOND',
        RegAddrState: 'OK',
        RegAddrZip: 73034,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '19426 NE 10TH ST',
        RegAddrCity: 'HARRAH',
        RegAddrState: 'OK',
        RegAddrZip: 73045,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '13711 NS 3550',
        RegAddrCity: 'KONAWA',
        RegAddrState: 'OK',
        RegAddrZip: 74849,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '20400 LAZY LN',
        RegAddrCity: 'LUTHER',
        RegAddrState: 'OK',
        RegAddrZip: 73054,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '1320 JUNIPER CIR',
        RegAddrCity: 'MIDWEST CITY',
        RegAddrState: 'OK',
        RegAddrZip: 73110,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '201 N SHARTEL AVE',
        RegAddrCity: 'OKLAHOMA CITY',
        RegAddrState: 'OK',
        RegAddrZip: 73102,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '1325 N ELLISON AVE',
        RegAddrCity: 'OKLAHOMA CITY',
        RegAddrState: 'OK',
        RegAddrZip: 73106,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '2337 NW 13TH ST',
        RegAddrCity: 'OKLAHOMA CITY',
        RegAddrState: 'OK',
        RegAddrZip: 73107,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '3021 N UTAH AVE',
        RegAddrCity: 'OKLAHOMA CITY',
        RegAddrState: 'OK',
        RegAddrZip: 73107,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '3741 NW 26TH ST',
        RegAddrCity: 'OKLAHOMA CITY',
        RegAddrState: 'OK',
        RegAddrZip: 73107,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '2544 NW 27TH ST',
        RegAddrCity: 'OKLAHOMA CITY',
        RegAddrState: 'OK',
        RegAddrZip: 73107,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '3216 NW 18TH ST',
        RegAddrCity: 'OKLAHOMA CITY',
        RegAddrState: 'OK',
        RegAddrZip: 73107,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '3516 NW 14TH ST',
        RegAddrCity: 'OKLAHOMA CITY',
        RegAddrState: 'OK',
        RegAddrZip: 73107,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '1024 SW 53RD ST',
        RegAddrCity: 'OKLAHOMA CITY',
        RegAddrState: 'OK',
        RegAddrZip: 73109,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '5805 S HARVEY AVE',
        RegAddrCity: 'OKLAHOMA CITY',
        RegAddrState: 'OK',
        RegAddrZip: 73109,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '1221 SW 49TH ST',
        RegAddrCity: 'OKLAHOMA CITY',
        RegAddrState: 'OK',
        RegAddrZip: 73109,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '738 SW 32ND ST',
        RegAddrCity: 'OKLAHOMA CITY',
        RegAddrState: 'OK',
        RegAddrZip: 73109,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '1216 NE 55TH ST',
        RegAddrCity: 'OKLAHOMA CITY',
        RegAddrState: 'OK',
        RegAddrZip: 73111,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '3001 NW 33RD ST',
        RegAddrCity: 'OKLAHOMA CITY',
        RegAddrState: 'OK',
        RegAddrZip: 73112,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '2215 W EUBANKS ST',
        RegAddrCity: 'OKLAHOMA CITY',
        RegAddrState: 'OK',
        RegAddrZip: 73112,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '1416 NW 106TH ST',
        RegAddrCity: 'OKLAHOMA CITY',
        RegAddrState: 'OK',
        RegAddrZip: 73114,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '424 NE 115TH ST',
        RegAddrCity: 'OKLAHOMA CITY',
        RegAddrState: 'OK',
        RegAddrZip: 73114,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '413 NE 115TH ST',
        RegAddrCity: 'OKLAHOMA CITY',
        RegAddrState: 'OK',
        RegAddrZip: 73114,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '4038 FONTANA DR',
        RegAddrCity: 'OKLAHOMA CITY',
        RegAddrState: 'OK',
        RegAddrZip: 73116,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '1305 NE 10TH ST',
        RegAddrCity: 'OKLAHOMA CITY',
        RegAddrState: 'OK',
        RegAddrZip: 73117,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '833 NW 46TH ST',
        RegAddrCity: 'OKLAHOMA CITY',
        RegAddrState: 'OK',
        RegAddrZip: 73118,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '4314 S AGNEW AVE',
        RegAddrCity: 'OKLAHOMA CITY',
        RegAddrState: 'OK',
        RegAddrZip: 73119,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '13525 APPLE VALLEY DR',
        RegAddrCity: 'OKLAHOMA CITY',
        RegAddrState: 'OK',
        RegAddrZip: 73120,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '10708 SUNNYMEADE PL',
        RegAddrCity: 'OKLAHOMA CITY',
        RegAddrState: 'OK',
        RegAddrZip: 73120,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '3132 GOSHEN DR',
        RegAddrCity: 'OKLAHOMA CITY',
        RegAddrState: 'OK',
        RegAddrZip: 73120,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '5925 NW 56TH ST',
        RegAddrCity: 'OKLAHOMA CITY',
        RegAddrState: 'OK',
        RegAddrZip: 73122,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '2508 E OVERHOLSER DR',
        RegAddrCity: 'OKLAHOMA CITY',
        RegAddrState: 'OK',
        RegAddrZip: 73127,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '3017 N EAGLE LN',
        RegAddrCity: 'OKLAHOMA CITY',
        RegAddrState: 'OK',
        RegAddrZip: 73127,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '4911 SW 26TH ST',
        RegAddrCity: 'OKLAHOMA CITY',
        RegAddrState: 'OK',
        RegAddrZip: 73128,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '1900 S HIGH AVE',
        RegAddrCity: 'OKLAHOMA CITY',
        RegAddrState: 'OK',
        RegAddrZip: 73129,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '9008 PASEO DEL VITA',
        RegAddrCity: 'OKLAHOMA CITY',
        RegAddrState: 'OK',
        RegAddrZip: 73131,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '5821 NW 86TH ST',
        RegAddrCity: 'OKLAHOMA CITY',
        RegAddrState: 'OK',
        RegAddrZip: 73132,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '5805 NW 70TH ST',
        RegAddrCity: 'OKLAHOMA CITY',
        RegAddrState: 'OK',
        RegAddrZip: 73132,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '7705 RUMSEY RD',
        RegAddrCity: 'OKLAHOMA CITY',
        RegAddrState: 'OK',
        RegAddrZip: 73132,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '8620 NE 17TH ST',
        RegAddrCity: 'OKLAHOMA CITY',
        RegAddrState: 'OK',
        RegAddrZip: 73141,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '7841 NW 132ND TER',
        RegAddrCity: 'OKLAHOMA CITY',
        RegAddrState: 'OK',
        RegAddrZip: 73142,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '8609 NW 125TH ST',
        RegAddrCity: 'OKLAHOMA CITY',
        RegAddrState: 'OK',
        RegAddrZip: 73142,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '12805 WESTCOURT',
        RegAddrCity: 'OKLAHOMA CITY',
        RegAddrState: 'OK',
        RegAddrZip: 73142,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '7609 JESSE TRL',
        RegAddrCity: 'OKLAHOMA CITY',
        RegAddrState: 'OK',
        RegAddrZip: 73150,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '1300 SW 71ST TER',
        RegAddrCity: 'OKLAHOMA CITY',
        RegAddrState: 'OK',
        RegAddrZip: 73159,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '2100 SW 77TH PL',
        RegAddrCity: 'OKLAHOMA CITY',
        RegAddrState: 'OK',
        RegAddrZip: 73159,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '3116 SW 85TH ST',
        RegAddrCity: 'OKLAHOMA CITY',
        RegAddrState: 'OK',
        RegAddrZip: 73159,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '8609 NW 111TH ST',
        RegAddrCity: 'OKLAHOMA CITY',
        RegAddrState: 'OK',
        RegAddrZip: 73162,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '5312 NW 110TH ST',
        RegAddrCity: 'OKLAHOMA CITY',
        RegAddrState: 'OK',
        RegAddrZip: 73162,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '8821 NW 114TH ST',
        RegAddrCity: 'OKLAHOMA CITY',
        RegAddrState: 'OK',
        RegAddrZip: 73162,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '1322 VAN DR',
        RegAddrCity: 'SEMINOLE',
        RegAddrState: 'OK',
        RegAddrZip: 74868,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '712 HIDDEN LAKE DR',
        RegAddrCity: 'SEMINOLE',
        RegAddrState: 'OK',
        RegAddrZip: 74868,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '1104 MUIRFIELD DR',
        RegAddrCity: 'SHAWNEE',
        RegAddrState: 'OK',
        RegAddrZip: 74801,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '1211 E WALLACE ST',
        RegAddrCity: 'SHAWNEE',
        RegAddrState: 'OK',
        RegAddrZip: 74801,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '318 S KICKAPOO AVE',
        RegAddrCity: 'SHAWNEE',
        RegAddrState: 'OK',
        RegAddrZip: 74801,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '122 COTTON TAIL DR',
        RegAddrCity: 'SHAWNEE',
        RegAddrState: 'OK',
        RegAddrZip: 74804,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '201 E FEDERAL ST',
        RegAddrCity: 'SHAWNEE',
        RegAddrState: 'OK',
        RegAddrZip: 74804,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '109 LEE ANN LN',
        RegAddrCity: 'SHAWNEE',
        RegAddrState: 'OK',
        RegAddrZip: 74804,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '23426 RANGELINE RD',
        RegAddrCity: 'TECUMSEH',
        RegAddrState: 'OK',
        RegAddrZip: 74873,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '11824 E 15TH ST',
        RegAddrCity: 'TULSA',
        RegAddrState: 'OK',
        RegAddrZip: 74128,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '600 S SASAKWA AVE',
        RegAddrCity: 'WEWOKA',
        RegAddrState: 'OK',
        RegAddrZip: 74884,
        CongressionalDistrict: 5,
      },
    ],
  },
  {
    name: 'OR-1',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/OR-1?ref=gh-pages',
    state: 'OR',
    state_and_number: 'OR-1',
    zip: 97005,
    congressionalDistrict: 1,
    addressesInDistrict: [
      {
        RegAddrLine1: '15550 NW BARKTON ST',
        RegAddrCity: 'BEAVERTON',
        RegAddrState: 'OR',
        RegAddrZip: 97006,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '17227 NW WIDGI CREEK CT',
        RegAddrCity: 'BEAVERTON',
        RegAddrState: 'OR',
        RegAddrZip: 97006,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '13860 SW BUTNER RD',
        RegAddrCity: 'BEAVERTON',
        RegAddrState: 'OR',
        RegAddrZip: 97006,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '15230 SW DIVISION ST',
        RegAddrCity: 'BEAVERTON',
        RegAddrState: 'OR',
        RegAddrZip: 97007,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '7565 SW 149TH AVE',
        RegAddrCity: 'BEAVERTON',
        RegAddrState: 'OR',
        RegAddrZip: 97007,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '6090 SW 152ND AVE',
        RegAddrCity: 'BEAVERTON',
        RegAddrState: 'OR',
        RegAddrZip: 97007,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '15410 SW HERON CT',
        RegAddrCity: 'BEAVERTON',
        RegAddrState: 'OR',
        RegAddrZip: 97007,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '8205 SW 191ST AVE',
        RegAddrCity: 'BEAVERTON',
        RegAddrState: 'OR',
        RegAddrZip: 97007,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '19957 SW SOUTHVIEW ST',
        RegAddrCity: 'BEAVERTON',
        RegAddrState: 'OR',
        RegAddrZip: 97078,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '3739 W CHINOOK AVE',
        RegAddrCity: 'CANNON BEACH',
        RegAddrState: 'OR',
        RegAddrZip: 97110,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '76716 DELENA MAYGER RD',
        RegAddrCity: 'CLATSKANIE',
        RegAddrState: 'OR',
        RegAddrZip: 97016,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1825 S GINGER ST',
        RegAddrCity: 'CORNELIUS',
        RegAddrState: 'OR',
        RegAddrZip: 97113,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1125 FERRY ST',
        RegAddrCity: 'DAYTON',
        RegAddrState: 'OR',
        RegAddrZip: 97114,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2823 22ND AVE',
        RegAddrCity: 'FOREST GROVE',
        RegAddrState: 'OR',
        RegAddrZip: 97116,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '50045 SW PATTON VALLEY RD',
        RegAddrCity: 'GASTON',
        RegAddrState: 'OR',
        RegAddrZip: 97119,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '3627 SE STARLING PL',
        RegAddrCity: 'HILLSBORO',
        RegAddrState: 'OR',
        RegAddrZip: 97123,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1939 SE OAK CREST DR',
        RegAddrCity: 'HILLSBORO',
        RegAddrState: 'OR',
        RegAddrZip: 97123,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '192 SE CRESTVIEW DR',
        RegAddrCity: 'HILLSBORO',
        RegAddrState: 'OR',
        RegAddrZip: 97123,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '6518 NE DEER RUN ST',
        RegAddrCity: 'HILLSBORO',
        RegAddrState: 'OR',
        RegAddrZip: 97124,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '881 NE 5TH DR',
        RegAddrCity: 'HILLSBORO',
        RegAddrState: 'OR',
        RegAddrZip: 97124,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '797 NW QUEENS CT',
        RegAddrCity: 'HILLSBORO',
        RegAddrState: 'OR',
        RegAddrZip: 97124,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1800 SW NICOLE DR',
        RegAddrCity: 'MCMINNVILLE',
        RegAddrState: 'OR',
        RegAddrZip: 97128,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1104 NE 5TH ST',
        RegAddrCity: 'MCMINNVILLE',
        RegAddrState: 'OR',
        RegAddrZip: 97128,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '440 NW HILLSIDE PARK WAY',
        RegAddrCity: 'MCMINNVILLE',
        RegAddrState: 'OR',
        RegAddrZip: 97128,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1612 N MAIN ST',
        RegAddrCity: 'NEWBERG',
        RegAddrState: 'OR',
        RegAddrZip: 97132,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1861 N CRATER LN',
        RegAddrCity: 'NEWBERG',
        RegAddrState: 'OR',
        RegAddrZip: 97132,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '412 N BLAINE ST',
        RegAddrCity: 'NEWBERG',
        RegAddrState: 'OR',
        RegAddrZip: 97132,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '208 E QUAIL DR',
        RegAddrCity: 'NEWBERG',
        RegAddrState: 'OR',
        RegAddrZip: 97132,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2848 NW SAVIER ST',
        RegAddrCity: 'PORTLAND',
        RegAddrState: 'OR',
        RegAddrZip: 97210,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '12276 SW 114TH TER',
        RegAddrCity: 'PORTLAND',
        RegAddrState: 'OR',
        RegAddrZip: 97223,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '13372 SW CLEARVIEW WAY',
        RegAddrCity: 'PORTLAND',
        RegAddrState: 'OR',
        RegAddrZip: 97223,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '8750 SW PINEBROOK ST',
        RegAddrCity: 'PORTLAND',
        RegAddrState: 'OR',
        RegAddrZip: 97224,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '12719 SW OVERGAARD ST',
        RegAddrCity: 'PORTLAND',
        RegAddrState: 'OR',
        RegAddrZip: 97224,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '12575 SW GLENHAVEN ST',
        RegAddrCity: 'PORTLAND',
        RegAddrState: 'OR',
        RegAddrZip: 97225,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '7340 SW CANYON DR',
        RegAddrCity: 'PORTLAND',
        RegAddrState: 'OR',
        RegAddrZip: 97225,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '11150 SW FOOTHILL DR',
        RegAddrCity: 'PORTLAND',
        RegAddrState: 'OR',
        RegAddrZip: 97225,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '6770 SW RALEIGHWOOD LN',
        RegAddrCity: 'PORTLAND',
        RegAddrState: 'OR',
        RegAddrZip: 97225,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '10650 NW LAIDLAW RD',
        RegAddrCity: 'PORTLAND',
        RegAddrState: 'OR',
        RegAddrZip: 97229,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '20240 NW PAULINA DR',
        RegAddrCity: 'PORTLAND',
        RegAddrState: 'OR',
        RegAddrZip: 97229,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '13236 NW MCNAMEE RD',
        RegAddrCity: 'PORTLAND',
        RegAddrState: 'OR',
        RegAddrZip: 97231,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '3728 SW DOSCH RD',
        RegAddrCity: 'PORTLAND',
        RegAddrState: 'OR',
        RegAddrZip: 97239,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '73796 FERN HILL RD',
        RegAddrCity: 'RAINIER',
        RegAddrState: 'OR',
        RegAddrZip: 97048,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '52381 SW 2ND ST',
        RegAddrCity: 'SCAPPOOSE',
        RegAddrState: 'OR',
        RegAddrZip: 97056,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '53821 RING A RING RD',
        RegAddrCity: 'SCAPPOOSE',
        RegAddrState: 'OR',
        RegAddrZip: 97056,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '11044 SW SAGE TER',
        RegAddrCity: 'TIGARD',
        RegAddrState: 'OR',
        RegAddrZip: 97223,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '10495 SW KELLOGG DR',
        RegAddrCity: 'TUALATIN',
        RegAddrState: 'OR',
        RegAddrZip: 97062,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '842 ROSE AVE',
        RegAddrCity: 'VERNONIA',
        RegAddrState: 'OR',
        RegAddrZip: 97064,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '369 SW MAIN CT',
        RegAddrCity: 'WARRENTON',
        RegAddrState: 'OR',
        RegAddrZip: 97146,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '90380 LEWIS RD',
        RegAddrCity: 'WARRENTON',
        RegAddrState: 'OR',
        RegAddrZip: 97146,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '19200 NW ENGER LN',
        RegAddrCity: 'YAMHILL',
        RegAddrState: 'OR',
        RegAddrZip: 97148,
        CongressionalDistrict: 1,
      },
    ],
  },
  {
    name: 'OR-2',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/OR-2?ref=gh-pages',
    state: 'OR',
    state_and_number: 'OR-2',
    zip: 97001,
    congressionalDistrict: 2,
    addressesInDistrict: [
      {
        RegAddrLine1: '910 MOUNTAIN MEADOWS CIR',
        RegAddrCity: 'ASHLAND',
        RegAddrState: 'OR',
        RegAddrZip: 97520,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '55 GRANITE ST',
        RegAddrCity: 'ASHLAND',
        RegAddrState: 'OR',
        RegAddrZip: 97520,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '659 OAK KNOLL DR',
        RegAddrCity: 'ASHLAND',
        RegAddrState: 'OR',
        RegAddrZip: 97520,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2545 COLLEGE ST',
        RegAddrCity: 'BAKER CITY',
        RegAddrState: 'OR',
        RegAddrZip: 97814,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '20971 LUPINE AVE',
        RegAddrCity: 'BEND',
        RegAddrState: 'OR',
        RegAddrZip: 97701,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '3379 NE STONEBROOK LOOP',
        RegAddrCity: 'BEND',
        RegAddrState: 'OR',
        RegAddrZip: 97701,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '20595 WOODSIDE CT',
        RegAddrCity: 'BEND',
        RegAddrState: 'OR',
        RegAddrZip: 97702,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '22060 QUEBEC DR',
        RegAddrCity: 'BEND',
        RegAddrState: 'OR',
        RegAddrZip: 97702,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '61173 TETON LN',
        RegAddrCity: 'BEND',
        RegAddrState: 'OR',
        RegAddrZip: 97702,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '65385 78TH ST',
        RegAddrCity: 'BEND',
        RegAddrState: 'OR',
        RegAddrZip: 97703,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '94 NW RIVERSIDE BLVD',
        RegAddrCity: 'BEND',
        RegAddrState: 'OR',
        RegAddrZip: 97703,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '5596 GEBHARD RD',
        RegAddrCity: 'CENTRAL POINT',
        RegAddrState: 'OR',
        RegAddrZip: 97502,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '12245 TABLE ROCK RD',
        RegAddrCity: 'CENTRAL POINT',
        RegAddrState: 'OR',
        RegAddrZip: 97502,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '41 ROGUE RIVER HWY',
        RegAddrCity: 'GOLD HILL',
        RegAddrState: 'OR',
        RegAddrZip: 97525,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '3064 CAMP JOY RD',
        RegAddrCity: 'GRANTS PASS',
        RegAddrState: 'OR',
        RegAddrZip: 97526,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '540 SW FOUNDRY ST',
        RegAddrCity: 'GRANTS PASS',
        RegAddrState: 'OR',
        RegAddrZip: 97526,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '418 NE QUAIL CT',
        RegAddrCity: 'HERMISTON',
        RegAddrState: 'OR',
        RegAddrZip: 97838,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1155 SW SANDY DR',
        RegAddrCity: 'HERMISTON',
        RegAddrState: 'OR',
        RegAddrZip: 97838,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '80304 COX LN',
        RegAddrCity: 'HERMISTON',
        RegAddrState: 'OR',
        RegAddrZip: 97838,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '3226 DEE HWY',
        RegAddrCity: 'HOOD RIVER',
        RegAddrState: 'OR',
        RegAddrZip: 97031,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '525 HIGHLINE RD',
        RegAddrCity: 'HOOD RIVER',
        RegAddrState: 'OR',
        RegAddrZip: 97031,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2030 EASTSIDE RD',
        RegAddrCity: 'HOOD RIVER',
        RegAddrState: 'OR',
        RegAddrZip: 97031,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '275 W CALIFORNIA LN',
        RegAddrCity: 'IRRIGON',
        RegAddrState: 'OR',
        RegAddrZip: 97844,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '3230 STITZEL RD',
        RegAddrCity: 'JORDAN VALLEY',
        RegAddrState: 'OR',
        RegAddrZip: 97910,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '4011 MAZAMA DR',
        RegAddrCity: 'KLAMATH FALLS',
        RegAddrState: 'OR',
        RegAddrZip: 97603,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '10796 MCGUIRE AVE',
        RegAddrCity: 'KLAMATH FALLS',
        RegAddrState: 'OR',
        RegAddrZip: 97603,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '5235 SHASTA WAY',
        RegAddrCity: 'KLAMATH FALLS',
        RegAddrState: 'OR',
        RegAddrZip: 97603,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '3326 BARRY AVE',
        RegAddrCity: 'KLAMATH FALLS',
        RegAddrState: 'OR',
        RegAddrZip: 97603,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1107 CARLSON DR',
        RegAddrCity: 'KLAMATH FALLS',
        RegAddrState: 'OR',
        RegAddrZip: 97603,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '53666 CENTRAL WAY',
        RegAddrCity: 'LA PINE',
        RegAddrState: 'OR',
        RegAddrZip: 97739,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '485 SW WILLIES DR',
        RegAddrCity: 'MADRAS',
        RegAddrState: 'OR',
        RegAddrZip: 97741,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '842 SE KRISTIN WAY',
        RegAddrCity: 'MADRAS',
        RegAddrState: 'OR',
        RegAddrZip: 97741,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '673 S STAGE RD',
        RegAddrCity: 'MEDFORD',
        RegAddrState: 'OR',
        RegAddrZip: 97501,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1308 N ROSS LN',
        RegAddrCity: 'MEDFORD',
        RegAddrState: 'OR',
        RegAddrZip: 97501,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2130 W STEWART AVE',
        RegAddrCity: 'MEDFORD',
        RegAddrState: 'OR',
        RegAddrZip: 97501,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '15 SUMMIT AVE',
        RegAddrCity: 'MEDFORD',
        RegAddrState: 'OR',
        RegAddrZip: 97501,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2354 TEMPLE DR',
        RegAddrCity: 'MEDFORD',
        RegAddrState: 'OR',
        RegAddrZip: 97504,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '639 ENTERPRISE AVE',
        RegAddrCity: 'NYSSA',
        RegAddrState: 'OR',
        RegAddrZip: 97913,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2241 ROCK SPRINGS CANYON RD',
        RegAddrCity: 'NYSSA',
        RegAddrState: 'OR',
        RegAddrZip: 97913,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '776 MORGAN AVE',
        RegAddrCity: 'ONTARIO',
        RegAddrState: 'OR',
        RegAddrZip: 97914,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2117 SW OLSON AVE',
        RegAddrCity: 'PENDLETON',
        RegAddrState: 'OR',
        RegAddrZip: 97801,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2415 SE MELROSE DR',
        RegAddrCity: 'PRINEVILLE',
        RegAddrState: 'OR',
        RegAddrZip: 97754,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '231 SW MEADOW LAKES DR',
        RegAddrCity: 'PRINEVILLE',
        RegAddrState: 'OR',
        RegAddrZip: 97754,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '3163 SW NEWBERRY AVE',
        RegAddrCity: 'REDMOND',
        RegAddrState: 'OR',
        RegAddrZip: 97756,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '69489 GREEN RIDGE LOOP',
        RegAddrCity: 'SISTERS',
        RegAddrState: 'OR',
        RegAddrZip: 97759,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '24525 TABLELAND RD',
        RegAddrCity: 'SPRAGUE RIVER',
        RegAddrState: 'OR',
        RegAddrZip: 97639,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '222 W 11TH ST',
        RegAddrCity: 'THE DALLES',
        RegAddrState: 'OR',
        RegAddrZip: 97058,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '904 E 14TH ST',
        RegAddrCity: 'THE DALLES',
        RegAddrState: 'OR',
        RegAddrZip: 97058,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1418 E 10TH ST',
        RegAddrCity: 'THE DALLES',
        RegAddrState: 'OR',
        RegAddrZip: 97058,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '473 S COLLEGE ST',
        RegAddrCity: 'UNION',
        RegAddrState: 'OR',
        RegAddrZip: 97883,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '503 W 3RD ST',
        RegAddrCity: 'WALLOWA',
        RegAddrState: 'OR',
        RegAddrZip: 97885,
        CongressionalDistrict: 2,
      },
    ],
  },
  {
    name: 'OR-3',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/OR-3?ref=gh-pages',
    state: 'OR',
    state_and_number: 'OR-3',
    zip: 97004,
    congressionalDistrict: 3,
    addressesInDistrict: [
      {
        RegAddrLine1: '14190 SE FILBERT CREEK DR',
        RegAddrCity: 'CLACKAMAS',
        RegAddrState: 'OR',
        RegAddrZip: 97015,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '10695 SE 222ND DR',
        RegAddrCity: 'DAMASCUS',
        RegAddrState: 'OR',
        RegAddrZip: 97089,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '23196 SE MERCER CT',
        RegAddrCity: 'DAMASCUS',
        RegAddrState: 'OR',
        RegAddrZip: 97089,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '420 NW ZOBRIST ST',
        RegAddrCity: 'ESTACADA',
        RegAddrState: 'OR',
        RegAddrZip: 97023,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1238 NW WONDERVIEW DR',
        RegAddrCity: 'GRESHAM',
        RegAddrState: 'OR',
        RegAddrZip: 97030,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '3005 NE 18TH CT',
        RegAddrCity: 'GRESHAM',
        RegAddrState: 'OR',
        RegAddrZip: 97030,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1491 NE GREENWAY DR',
        RegAddrCity: 'GRESHAM',
        RegAddrState: 'OR',
        RegAddrZip: 97030,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '2568 SW 42ND ST',
        RegAddrCity: 'GRESHAM',
        RegAddrState: 'OR',
        RegAddrZip: 97080,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1595 SE PALMBLAD RD',
        RegAddrCity: 'GRESHAM',
        RegAddrState: 'OR',
        RegAddrZip: 97080,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '702 SE WENDY LN',
        RegAddrCity: 'GRESHAM',
        RegAddrState: 'OR',
        RegAddrZip: 97080,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '228 SW BELLA VISTA LN',
        RegAddrCity: 'GRESHAM',
        RegAddrState: 'OR',
        RegAddrZip: 97080,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '16421 S UNION MILLS RD',
        RegAddrCity: 'MULINO',
        RegAddrState: 'OR',
        RegAddrZip: 97042,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '20711 BEAVERCREEK RD',
        RegAddrCity: 'OREGON CITY',
        RegAddrState: 'OR',
        RegAddrZip: 97045,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '20380 S FERGUSON RD',
        RegAddrCity: 'OREGON CITY',
        RegAddrState: 'OR',
        RegAddrZip: 97045,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '4121 SE RAYMOND ST',
        RegAddrCity: 'PORTLAND',
        RegAddrState: 'OR',
        RegAddrZip: 97202,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '3015 SE 29TH AVE',
        RegAddrCity: 'PORTLAND',
        RegAddrState: 'OR',
        RegAddrZip: 97202,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '4237 SE 9TH AVE',
        RegAddrCity: 'PORTLAND',
        RegAddrState: 'OR',
        RegAddrZip: 97202,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1343 SE FLAVEL ST',
        RegAddrCity: 'PORTLAND',
        RegAddrState: 'OR',
        RegAddrZip: 97202,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '5266 N OBERLIN ST',
        RegAddrCity: 'PORTLAND',
        RegAddrState: 'OR',
        RegAddrZip: 97203,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '6812 SE KNIGHT ST',
        RegAddrCity: 'PORTLAND',
        RegAddrState: 'OR',
        RegAddrZip: 97206,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '6613 SE 76TH AVE',
        RegAddrCity: 'PORTLAND',
        RegAddrState: 'OR',
        RegAddrZip: 97206,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '5827 SE WOODWARD ST',
        RegAddrCity: 'PORTLAND',
        RegAddrState: 'OR',
        RegAddrZip: 97206,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '7443 SE 69TH AVE',
        RegAddrCity: 'PORTLAND',
        RegAddrState: 'OR',
        RegAddrZip: 97206,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '5916 SE GLADSTONE ST',
        RegAddrCity: 'PORTLAND',
        RegAddrState: 'OR',
        RegAddrZip: 97206,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '7533 SE STEELE ST',
        RegAddrCity: 'PORTLAND',
        RegAddrState: 'OR',
        RegAddrZip: 97206,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '6723 SE CENTER ST',
        RegAddrCity: 'PORTLAND',
        RegAddrState: 'OR',
        RegAddrZip: 97206,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '3219 NE MASON ST',
        RegAddrCity: 'PORTLAND',
        RegAddrState: 'OR',
        RegAddrZip: 97211,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '4131 NE CESAR E CHAVEZ BLVD',
        RegAddrCity: 'PORTLAND',
        RegAddrState: 'OR',
        RegAddrZip: 97211,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '3930 NE 76TH AVE',
        RegAddrCity: 'PORTLAND',
        RegAddrState: 'OR',
        RegAddrZip: 97213,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '5931 NE DAVIS ST',
        RegAddrCity: 'PORTLAND',
        RegAddrState: 'OR',
        RegAddrZip: 97213,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '3805 SE ALDER ST',
        RegAddrCity: 'PORTLAND',
        RegAddrState: 'OR',
        RegAddrZip: 97214,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '7101 SE HARRISON ST',
        RegAddrCity: 'PORTLAND',
        RegAddrState: 'OR',
        RegAddrZip: 97215,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '130 N SARATOGA ST',
        RegAddrCity: 'PORTLAND',
        RegAddrState: 'OR',
        RegAddrZip: 97217,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '415 N HAYDEN BAY DR',
        RegAddrCity: 'PORTLAND',
        RegAddrState: 'OR',
        RegAddrZip: 97217,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '6032 N DELAWARE AVE',
        RegAddrCity: 'PORTLAND',
        RegAddrState: 'OR',
        RegAddrZip: 97217,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '3823 N BALDWIN ST',
        RegAddrCity: 'PORTLAND',
        RegAddrState: 'OR',
        RegAddrZip: 97217,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '4809 NE 73RD AVE',
        RegAddrCity: 'PORTLAND',
        RegAddrState: 'OR',
        RegAddrZip: 97218,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '4650 NE SIMPSON ST',
        RegAddrCity: 'PORTLAND',
        RegAddrState: 'OR',
        RegAddrZip: 97218,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '5515 NE WYGANT ST',
        RegAddrCity: 'PORTLAND',
        RegAddrState: 'OR',
        RegAddrZip: 97218,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '9545 SW 12TH DR',
        RegAddrCity: 'PORTLAND',
        RegAddrState: 'OR',
        RegAddrZip: 97219,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '8520 SW 20TH AVE',
        RegAddrCity: 'PORTLAND',
        RegAddrState: 'OR',
        RegAddrZip: 97219,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '631 SW EVANS ST',
        RegAddrCity: 'PORTLAND',
        RegAddrState: 'OR',
        RegAddrZip: 97219,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '11222 SW SOUTHRIDGE DR',
        RegAddrCity: 'PORTLAND',
        RegAddrState: 'OR',
        RegAddrZip: 97219,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1205 NE 113TH AVE',
        RegAddrCity: 'PORTLAND',
        RegAddrState: 'OR',
        RegAddrZip: 97220,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '121 NE 199TH AVE',
        RegAddrCity: 'PORTLAND',
        RegAddrState: 'OR',
        RegAddrZip: 97230,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '13122 NE MORRIS CT',
        RegAddrCity: 'PORTLAND',
        RegAddrState: 'OR',
        RegAddrZip: 97230,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '925 NE 162ND AVE',
        RegAddrCity: 'PORTLAND',
        RegAddrState: 'OR',
        RegAddrZip: 97230,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '211 NE 164TH AVE',
        RegAddrCity: 'PORTLAND',
        RegAddrState: 'OR',
        RegAddrZip: 97230,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '2124 NE FLANDERS ST',
        RegAddrCity: 'PORTLAND',
        RegAddrState: 'OR',
        RegAddrZip: 97232,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '14415 SE MORRISON ST',
        RegAddrCity: 'PORTLAND',
        RegAddrState: 'OR',
        RegAddrZip: 97233,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '5234 SE 115TH AVE',
        RegAddrCity: 'PORTLAND',
        RegAddrState: 'OR',
        RegAddrZip: 97266,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '49951 SE WILDCAT MOUNTAIN DR',
        RegAddrCity: 'SANDY',
        RegAddrState: 'OR',
        RegAddrZip: 97055,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '38628 GALWAY ST',
        RegAddrCity: 'SANDY',
        RegAddrState: 'OR',
        RegAddrZip: 97055,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1901 SW 29TH ST',
        RegAddrCity: 'TROUTDALE',
        RegAddrState: 'OR',
        RegAddrZip: 97060,
        CongressionalDistrict: 3,
      },
    ],
  },
  {
    name: 'OR-4',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/OR-4?ref=gh-pages',
    state: 'OR',
    state_and_number: 'OR-4',
    zip: 97321,
    congressionalDistrict: 4,
    addressesInDistrict: [
      {
        RegAddrLine1: '6802 PARKER LN NE',
        RegAddrCity: 'ALBANY',
        RegAddrState: 'OR',
        RegAddrZip: 97321,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '3231 23RD AVE SE',
        RegAddrCity: 'ALBANY',
        RegAddrState: 'OR',
        RegAddrZip: 97322,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '280 QUEEN AVE SE',
        RegAddrCity: 'ALBANY',
        RegAddrState: 'OR',
        RegAddrZip: 97322,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '890 12TH ST SW',
        RegAddrCity: 'BANDON',
        RegAddrState: 'OR',
        RegAddrZip: 97411,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '27965 PINE VIEW RD',
        RegAddrCity: 'BROWNSVILLE',
        RegAddrState: 'OR',
        RegAddrZip: 97327,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '5401 TAKILMA RD',
        RegAddrCity: 'CAVE JUNCTION',
        RegAddrState: 'OR',
        RegAddrZip: 97523,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '93650 EMPIRE LN',
        RegAddrCity: 'COOS BAY',
        RegAddrState: 'OR',
        RegAddrZip: 97420,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '57747 HIDDEN RD',
        RegAddrCity: 'COQUILLE',
        RegAddrState: 'OR',
        RegAddrZip: 97423,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '5455 NW CRESCENT VALLEY DR',
        RegAddrCity: 'CORVALLIS',
        RegAddrState: 'OR',
        RegAddrZip: 97330,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2810 NW GARFIELD AVE',
        RegAddrCity: 'CORVALLIS',
        RegAddrState: 'OR',
        RegAddrZip: 97330,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '3020 SW MORRIS AVE',
        RegAddrCity: 'CORVALLIS',
        RegAddrState: 'OR',
        RegAddrZip: 97333,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '675 SW 55TH ST',
        RegAddrCity: 'CORVALLIS',
        RegAddrState: 'OR',
        RegAddrZip: 97333,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '6850 SW AIRPORT AVE',
        RegAddrCity: 'CORVALLIS',
        RegAddrState: 'OR',
        RegAddrZip: 97333,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2110 CEMETERY RD',
        RegAddrCity: 'COTTAGE GROVE',
        RegAddrState: 'OR',
        RegAddrZip: 97424,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2209 CAL YOUNG RD',
        RegAddrCity: 'EUGENE',
        RegAddrState: 'OR',
        RegAddrZip: 97401,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2770 EDISON ST',
        RegAddrCity: 'EUGENE',
        RegAddrState: 'OR',
        RegAddrZip: 97402,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1820 KONA ST',
        RegAddrCity: 'EUGENE',
        RegAddrState: 'OR',
        RegAddrZip: 97403,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '3248 STARK ST',
        RegAddrCity: 'EUGENE',
        RegAddrState: 'OR',
        RegAddrZip: 97404,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '260 BLACKFOOT AVE',
        RegAddrCity: 'EUGENE',
        RegAddrState: 'OR',
        RegAddrZip: 97404,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '195 HARVEY AVE',
        RegAddrCity: 'EUGENE',
        RegAddrState: 'OR',
        RegAddrZip: 97404,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '270 KNAPP LN',
        RegAddrCity: 'EUGENE',
        RegAddrState: 'OR',
        RegAddrZip: 97404,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '290 E 38TH AVE',
        RegAddrCity: 'EUGENE',
        RegAddrState: 'OR',
        RegAddrZip: 97405,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1192 E 41ST AVE',
        RegAddrCity: 'EUGENE',
        RegAddrState: 'OR',
        RegAddrZip: 97405,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1860 ARTHUR ST',
        RegAddrCity: 'EUGENE',
        RegAddrState: 'OR',
        RegAddrZip: 97405,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2761 TULIP ST',
        RegAddrCity: 'EUGENE',
        RegAddrState: 'OR',
        RegAddrZip: 97408,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '120 EL CAMINO WAY',
        RegAddrCity: 'GRANTS PASS',
        RegAddrState: 'OR',
        RegAddrZip: 97526,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '11789 WILLIAMS HWY',
        RegAddrCity: 'GRANTS PASS',
        RegAddrState: 'OR',
        RegAddrZip: 97527,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '24495 POWERLINE RD',
        RegAddrCity: 'HARRISBURG',
        RegAddrState: 'OR',
        RegAddrZip: 97446,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '93182 PRAIRIE RD',
        RegAddrCity: 'JUNCTION CITY',
        RegAddrState: 'OR',
        RegAddrZip: 97448,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2108 DESIREE PL',
        RegAddrCity: 'LEBANON',
        RegAddrState: 'OR',
        RegAddrZip: 97355,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '785 S 7TH ST',
        RegAddrCity: 'LEBANON',
        RegAddrState: 'OR',
        RegAddrZip: 97355,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '323 KAPOK ST',
        RegAddrCity: 'LYONS',
        RegAddrState: 'OR',
        RegAddrZip: 97358,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '24183 NICHOLS RD',
        RegAddrCity: 'MONROE',
        RegAddrState: 'OR',
        RegAddrZip: 97456,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1007 CORNUTT ST',
        RegAddrCity: 'MYRTLE CREEK',
        RegAddrState: 'OR',
        RegAddrZip: 97457,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '737 SPRING BROOK RD',
        RegAddrCity: 'MYRTLE CREEK',
        RegAddrState: 'OR',
        RegAddrZip: 97457,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '3525 CHINOOK AVE',
        RegAddrCity: 'NORTH BEND',
        RegAddrState: 'OR',
        RegAddrZip: 97459,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '94091 PETITT LN',
        RegAddrCity: 'NORTH BEND',
        RegAddrState: 'OR',
        RegAddrZip: 97459,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '35245 REDWOOD HWY',
        RegAddrCity: 'O BRIEN',
        RegAddrState: 'OR',
        RegAddrZip: 97534,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '3395 HOGAN RD',
        RegAddrCity: 'OAKLAND',
        RegAddrState: 'OR',
        RegAddrZip: 97462,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2137 APPLEGATE ST',
        RegAddrCity: 'PHILOMATH',
        RegAddrState: 'OR',
        RegAddrZip: 97370,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '85900 EDENVALE RD',
        RegAddrCity: 'PLEASANT HILL',
        RegAddrState: 'OR',
        RegAddrZip: 97455,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '275 RICO BLVD',
        RegAddrCity: 'RIDDLE',
        RegAddrState: 'OR',
        RegAddrZip: 97469,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '832 SE FULLERTON ST',
        RegAddrCity: 'ROSEBURG',
        RegAddrState: 'OR',
        RegAddrZip: 97470,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '110 ISABELL AVE',
        RegAddrCity: 'ROSEBURG',
        RegAddrState: 'OR',
        RegAddrZip: 97470,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '682 MEADOW AVE',
        RegAddrCity: 'ROSEBURG',
        RegAddrState: 'OR',
        RegAddrZip: 97470,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2302 NW KELSAY CT',
        RegAddrCity: 'ROSEBURG',
        RegAddrState: 'OR',
        RegAddrZip: 97471,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '806 EVELYN ST',
        RegAddrCity: 'ROSEBURG',
        RegAddrState: 'OR',
        RegAddrZip: 97471,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '462 CHAMPAGNE CREEK DR',
        RegAddrCity: 'ROSEBURG',
        RegAddrState: 'OR',
        RegAddrZip: 97471,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2490 5TH ST',
        RegAddrCity: 'SPRINGFIELD',
        RegAddrState: 'OR',
        RegAddrZip: 97477,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2540 E ST',
        RegAddrCity: 'SPRINGFIELD',
        RegAddrState: 'OR',
        RegAddrZip: 97477,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1835 F ST',
        RegAddrCity: 'SPRINGFIELD',
        RegAddrState: 'OR',
        RegAddrZip: 97477,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '604 T ST',
        RegAddrCity: 'SPRINGFIELD',
        RegAddrState: 'OR',
        RegAddrZip: 97477,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1345 R ST',
        RegAddrCity: 'SPRINGFIELD',
        RegAddrState: 'OR',
        RegAddrZip: 97477,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '6310 FERNHILL CT',
        RegAddrCity: 'SPRINGFIELD',
        RegAddrState: 'OR',
        RegAddrZip: 97478,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '26171 OLD HOLLEY RD',
        RegAddrCity: 'SWEET HOME',
        RegAddrState: 'OR',
        RegAddrZip: 97386,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '25781 JEANS RD',
        RegAddrCity: 'VENETA',
        RegAddrState: 'OR',
        RegAddrZip: 97487,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '5998 CANARY RD',
        RegAddrCity: 'WESTLAKE',
        RegAddrState: 'OR',
        RegAddrZip: 97493,
        CongressionalDistrict: 4,
      },
    ],
  },
  {
    name: 'OR-5',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/OR-5?ref=gh-pages',
    state: 'OR',
    state_and_number: 'OR-5',
    zip: 97002,
    congressionalDistrict: 5,
    addressesInDistrict: [
      {
        RegAddrLine1: '2101 NW NORTH ALBANY RD',
        RegAddrCity: 'ALBANY',
        RegAddrState: 'OR',
        RegAddrZip: 97321,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '1470 NW PENNY LN',
        RegAddrCity: 'ALBANY',
        RegAddrState: 'OR',
        RegAddrZip: 97321,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '6584 SE YAMPO RD',
        RegAddrCity: 'AMITY',
        RegAddrState: 'OR',
        RegAddrZip: 97101,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '62975 POWELL BUTTE HWY',
        RegAddrCity: 'BEND',
        RegAddrState: 'OR',
        RegAddrZip: 97701,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '1509 NE 10TH PL',
        RegAddrCity: 'CANBY',
        RegAddrState: 'OR',
        RegAddrZip: 97013,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '452 N IVY ST',
        RegAddrCity: 'CANBY',
        RegAddrState: 'OR',
        RegAddrZip: 97013,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '9802 SE TALBERT ST',
        RegAddrCity: 'CLACKAMAS',
        RegAddrState: 'OR',
        RegAddrZip: 97015,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '30776 S OSWALT RD',
        RegAddrCity: 'COLTON',
        RegAddrState: 'OR',
        RegAddrZip: 97017,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '1199 SE MONMOUTH CUT OFF RD',
        RegAddrCity: 'DALLAS',
        RegAddrState: 'OR',
        RegAddrZip: 97338,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '890 MESQUITE LN NE',
        RegAddrCity: 'GERVAIS',
        RegAddrState: 'OR',
        RegAddrZip: 97026,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '1193 WINFIELD ST NE',
        RegAddrCity: 'GERVAIS',
        RegAddrState: 'OR',
        RegAddrZip: 97026,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '18380 CHRIS CT',
        RegAddrCity: 'GLADSTONE',
        RegAddrState: 'OR',
        RegAddrZip: 97027,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '11158 SE LENORE ST',
        RegAddrCity: 'HAPPY VALLEY',
        RegAddrState: 'OR',
        RegAddrZip: 97086,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '11485 SE STEVENS RD',
        RegAddrCity: 'HAPPY VALLEY',
        RegAddrState: 'OR',
        RegAddrZip: 97086,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '35780 HIGHWAY 22',
        RegAddrCity: 'HEBO',
        RegAddrState: 'OR',
        RegAddrZip: 97122,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '5049 JOAN DR N',
        RegAddrCity: 'KEIZER',
        RegAddrState: 'OR',
        RegAddrZip: 97303,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '778 JAMES ST NE',
        RegAddrCity: 'KEIZER',
        RegAddrState: 'OR',
        RegAddrZip: 97303,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '1499 RUSHMORE AVE N',
        RegAddrCity: 'KEIZER',
        RegAddrState: 'OR',
        RegAddrZip: 97303,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '17826 MARYLCREEK DR',
        RegAddrCity: 'LAKE OSWEGO',
        RegAddrState: 'OR',
        RegAddrZip: 97034,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '9554 SE 42ND AVE',
        RegAddrCity: 'MILWAUKIE',
        RegAddrState: 'OR',
        RegAddrZip: 97222,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '5180 SE LOGUS RD',
        RegAddrCity: 'MILWAUKIE',
        RegAddrState: 'OR',
        RegAddrZip: 97222,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '4375 SE COTTONWOOD CT',
        RegAddrCity: 'MILWAUKIE',
        RegAddrState: 'OR',
        RegAddrZip: 97267,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '16811 SE BLANTON ST',
        RegAddrCity: 'MILWAUKIE',
        RegAddrState: 'OR',
        RegAddrZip: 97267,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '782 MEADOWLAWN PL',
        RegAddrCity: 'MOLALLA',
        RegAddrState: 'OR',
        RegAddrZip: 97038,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '625 LEO ST',
        RegAddrCity: 'MOUNT ANGEL',
        RegAddrState: 'OR',
        RegAddrZip: 97362,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '1836 NE CRESTVIEW PL',
        RegAddrCity: 'NEWPORT',
        RegAddrState: 'OR',
        RegAddrZip: 97365,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '20174 CANTERWOOD CT',
        RegAddrCity: 'OREGON CITY',
        RegAddrState: 'OR',
        RegAddrZip: 97045,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '16614 APPERSON BLVD',
        RegAddrCity: 'OREGON CITY',
        RegAddrState: 'OR',
        RegAddrZip: 97045,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '13126 KING SALMON CT',
        RegAddrCity: 'OREGON CITY',
        RegAddrState: 'OR',
        RegAddrZip: 97045,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '654 ILLINOIS AVE NE',
        RegAddrCity: 'SALEM',
        RegAddrState: 'OR',
        RegAddrZip: 97301,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '1495 16TH ST NE',
        RegAddrCity: 'SALEM',
        RegAddrState: 'OR',
        RegAddrZip: 97301,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '2271 LEE ST SE',
        RegAddrCity: 'SALEM',
        RegAddrState: 'OR',
        RegAddrZip: 97301,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '2220 BROADWAY ST NE',
        RegAddrCity: 'SALEM',
        RegAddrState: 'OR',
        RegAddrZip: 97301,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '3952 FRIAR CT SE',
        RegAddrCity: 'SALEM',
        RegAddrState: 'OR',
        RegAddrZip: 97302,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '740 WINDGATE ST S',
        RegAddrCity: 'SALEM',
        RegAddrState: 'OR',
        RegAddrZip: 97302,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '4216 CAMELLIA DR S',
        RegAddrCity: 'SALEM',
        RegAddrState: 'OR',
        RegAddrZip: 97302,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '434 SALEM HEIGHTS AVE S',
        RegAddrCity: 'SALEM',
        RegAddrState: 'OR',
        RegAddrZip: 97302,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '948 EISENHOWER DR NW',
        RegAddrCity: 'SALEM',
        RegAddrState: 'OR',
        RegAddrZip: 97304,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '2398 EMERALD DR NW',
        RegAddrCity: 'SALEM',
        RegAddrState: 'OR',
        RegAddrZip: 97304,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '3079 MOORELAND AVE NE',
        RegAddrCity: 'SALEM',
        RegAddrState: 'OR',
        RegAddrZip: 97305,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '2045 NOCTURNE CT SE',
        RegAddrCity: 'SALEM',
        RegAddrState: 'OR',
        RegAddrZip: 97306,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '2201 SOAPSTONE AVE SE',
        RegAddrCity: 'SALEM',
        RegAddrState: 'OR',
        RegAddrZip: 97306,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '6136 EXODUS ST SE',
        RegAddrCity: 'SALEM',
        RegAddrState: 'OR',
        RegAddrZip: 97306,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '3316 FIR TREE DR SE',
        RegAddrCity: 'SALEM',
        RegAddrState: 'OR',
        RegAddrZip: 97317,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '4110 MAHRT AVE SE',
        RegAddrCity: 'SALEM',
        RegAddrState: 'OR',
        RegAddrZip: 97317,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '4866 NETARTS HWY W',
        RegAddrCity: 'TILLAMOOK',
        RegAddrState: 'OR',
        RegAddrZip: 97141,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '3325 NORTHWOOD WAY N',
        RegAddrCity: 'TILLAMOOK',
        RegAddrState: 'OR',
        RegAddrZip: 97141,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '802 ASH AVE',
        RegAddrCity: 'TILLAMOOK',
        RegAddrState: 'OR',
        RegAddrZip: 97141,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '888 NW 5TH ST',
        RegAddrCity: 'TOLEDO',
        RegAddrState: 'OR',
        RegAddrZip: 97391,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '209 HAPPY VALLEY RD',
        RegAddrCity: 'TOLEDO',
        RegAddrState: 'OR',
        RegAddrZip: 97391,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '6555 LITTLE RD SE',
        RegAddrCity: 'TURNER',
        RegAddrState: 'OR',
        RegAddrZip: 97392,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '3385 QUAIL RIDGE CT',
        RegAddrCity: 'WEST LINN',
        RegAddrState: 'OR',
        RegAddrZip: 97068,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '4782 COHO LN',
        RegAddrCity: 'WEST LINN',
        RegAddrState: 'OR',
        RegAddrZip: 97068,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '473 SW ALDERWOOD DR',
        RegAddrCity: 'WEST LINN',
        RegAddrState: 'OR',
        RegAddrZip: 97068,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '10880 SW MATZEN DR',
        RegAddrCity: 'WILSONVILLE',
        RegAddrState: 'OR',
        RegAddrZip: 97070,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '30900 SW PARKWAY AVE',
        RegAddrCity: 'WILSONVILLE',
        RegAddrState: 'OR',
        RegAddrZip: 97070,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '11809 SW OSLO ST',
        RegAddrCity: 'WILSONVILLE',
        RegAddrState: 'OR',
        RegAddrZip: 97070,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '29735 SW JACKSON WAY',
        RegAddrCity: 'WILSONVILLE',
        RegAddrState: 'OR',
        RegAddrZip: 97070,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '1028 DEER RUN LN',
        RegAddrCity: 'WOODBURN',
        RegAddrState: 'OR',
        RegAddrZip: 97071,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '3526 NE EAST LINE RD',
        RegAddrCity: 'YACHATS',
        RegAddrState: 'OR',
        RegAddrZip: 97498,
        CongressionalDistrict: 5,
      },
    ],
  },
  {
    name: 'PA-1',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/PA-1?ref=gh-pages',
    state: 'PA',
    state_and_number: 'PA-1',
    zip: 19013,
    congressionalDistrict: 1,
    addressesInDistrict: [
      {
        RegAddrLine1: '575 MAPLE CT',
        RegAddrCity: 'BENSALEM',
        RegAddrState: 'PA',
        RegAddrZip: 19020,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1220 FLORIDA AVE',
        RegAddrCity: 'BENSALEM',
        RegAddrState: 'PA',
        RegAddrZip: 19020,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '3231 LEELAND DR',
        RegAddrCity: 'BENSALEM',
        RegAddrState: 'PA',
        RegAddrZip: 19020,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '268 HARRISON ST',
        RegAddrCity: 'BRISTOL',
        RegAddrState: 'PA',
        RegAddrZip: 19007,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '3520 DIXON AVE',
        RegAddrCity: 'BRISTOL',
        RegAddrState: 'PA',
        RegAddrZip: 19007,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '129 KRISTA CT',
        RegAddrCity: 'CHALFONT',
        RegAddrState: 'PA',
        RegAddrZip: 18914,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '134 STEEPLECHASE DR',
        RegAddrCity: 'DOYLESTOWN',
        RegAddrState: 'PA',
        RegAddrZip: 18901,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '202 YORKSHIRE LN',
        RegAddrCity: 'FAIRLESS HLS',
        RegAddrState: 'PA',
        RegAddrZip: 19030,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '84 APPLE HILL RD',
        RegAddrCity: 'FURLONG',
        RegAddrState: 'PA',
        RegAddrZip: 18925,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2295 REBECCA DR',
        RegAddrCity: 'HATFIELD',
        RegAddrState: 'PA',
        RegAddrZip: 19440,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2432 MEREL DR',
        RegAddrCity: 'HATFIELD',
        RegAddrState: 'PA',
        RegAddrZip: 19440,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '56 LINCOLN AVE',
        RegAddrCity: 'IVYLAND',
        RegAddrState: 'PA',
        RegAddrZip: 18974,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1545 ALMSHOUSE RD',
        RegAddrCity: 'JAMISON',
        RegAddrState: 'PA',
        RegAddrZip: 18929,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '234 OAKRIDGE DR',
        RegAddrCity: 'LANGHORNE',
        RegAddrState: 'PA',
        RegAddrZip: 19047,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '72 SUMMIT TRACE RD',
        RegAddrCity: 'LANGHORNE',
        RegAddrState: 'PA',
        RegAddrZip: 19047,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '749 HULMEVILLE RD',
        RegAddrCity: 'LANGHORNE',
        RegAddrState: 'PA',
        RegAddrZip: 19047,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2208 HARPERS XING',
        RegAddrCity: 'LANGHORNE',
        RegAddrState: 'PA',
        RegAddrZip: 19047,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '184 WILLOW DR',
        RegAddrCity: 'LEVITTOWN',
        RegAddrState: 'PA',
        RegAddrZip: 19054,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '242 PINEWOOD DR',
        RegAddrCity: 'LEVITTOWN',
        RegAddrState: 'PA',
        RegAddrZip: 19054,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '37 RAVINE LN',
        RegAddrCity: 'LEVITTOWN',
        RegAddrState: 'PA',
        RegAddrZip: 19055,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '8 MIDDLE RD',
        RegAddrCity: 'LEVITTOWN',
        RegAddrState: 'PA',
        RegAddrZip: 19056,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '481 HAMILTON BLVD',
        RegAddrCity: 'MORRISVILLE',
        RegAddrState: 'PA',
        RegAddrZip: 19067,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '116 WINDING WAY',
        RegAddrCity: 'MORRISVILLE',
        RegAddrState: 'PA',
        RegAddrZip: 19067,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '150 MATHEWS AVE',
        RegAddrCity: 'NEW BRITAIN',
        RegAddrState: 'PA',
        RegAddrZip: 18901,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '61 OAK DR',
        RegAddrCity: 'NEW BRITAIN',
        RegAddrState: 'PA',
        RegAddrZip: 18901,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '210 CREEKSIDE DR',
        RegAddrCity: 'NEW HOPE',
        RegAddrState: 'PA',
        RegAddrZip: 18938,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '8 MILLERS RD',
        RegAddrCity: 'NEWTOWN',
        RegAddrState: 'PA',
        RegAddrZip: 18940,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2515 SOCIETY PL',
        RegAddrCity: 'NEWTOWN',
        RegAddrState: 'PA',
        RegAddrZip: 18940,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1628 WRIGHTSTOWN RD',
        RegAddrCity: 'NEWTOWN',
        RegAddrState: 'PA',
        RegAddrZip: 18940,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1250 FOUNTAIN RD',
        RegAddrCity: 'NEWTOWN',
        RegAddrState: 'PA',
        RegAddrZip: 18940,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '313 WEXLEY DR',
        RegAddrCity: 'NEWTOWN',
        RegAddrState: 'PA',
        RegAddrZip: 18940,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '514 PENNY LN',
        RegAddrCity: 'PERKASIE',
        RegAddrState: 'PA',
        RegAddrZip: 18944,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '12806 CLIFFE DR',
        RegAddrCity: 'PHILADELPHIA',
        RegAddrState: 'PA',
        RegAddrZip: 19154,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '6168 MOUNTAIN LAUREL CT',
        RegAddrCity: 'PIPERSVILLE',
        RegAddrState: 'PA',
        RegAddrZip: 18947,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2045 BRINKMAN RD',
        RegAddrCity: 'QUAKERTOWN',
        RegAddrState: 'PA',
        RegAddrZip: 18951,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '322 MAGNOLIA DR',
        RegAddrCity: 'QUAKERTOWN',
        RegAddrState: 'PA',
        RegAddrZip: 18951,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '7 JACQUELINE CIR',
        RegAddrCity: 'RICHBORO',
        RegAddrState: 'PA',
        RegAddrZip: 18954,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1111 EASTON RD',
        RegAddrCity: 'RIEGELSVILLE',
        RegAddrState: 'PA',
        RegAddrZip: 18077,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '264 HAMPSHIRE DR',
        RegAddrCity: 'SELLERSVILLE',
        RegAddrState: 'PA',
        RegAddrZip: 18960,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '933 WASHINGTON AVE',
        RegAddrCity: 'SELLERSVILLE',
        RegAddrState: 'PA',
        RegAddrZip: 18960,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '3250 STATE RD',
        RegAddrCity: 'SELLERSVILLE',
        RegAddrState: 'PA',
        RegAddrZip: 18960,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '157 MILLER DR',
        RegAddrCity: 'SOUTHAMPTON',
        RegAddrState: 'PA',
        RegAddrZip: 18966,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1327 LEDDYS LN',
        RegAddrCity: 'UPPR BLCK EDY',
        RegAddrState: 'PA',
        RegAddrZip: 18972,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '64 BRISTOL ROAD W RD',
        RegAddrCity: 'WARMINSTER',
        RegAddrState: 'PA',
        RegAddrZip: 18974,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1518 HUNTLY DR',
        RegAddrCity: 'WARRINGTON',
        RegAddrState: 'PA',
        RegAddrZip: 18976,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2615 PICKERTOWN RD',
        RegAddrCity: 'WARRINGTON',
        RegAddrState: 'PA',
        RegAddrZip: 18976,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '25 CANAL RUN W RUN',
        RegAddrCity: 'WSHNGTN XING',
        RegAddrState: 'PA',
        RegAddrZip: 18977,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '199 CINNABAR LN',
        RegAddrCity: 'YARDLEY',
        RegAddrState: 'PA',
        RegAddrZip: 19067,
        CongressionalDistrict: 1,
      },
    ],
  },
  {
    name: 'PA-10',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/PA-10?ref=gh-pages',
    state: 'PA',
    state_and_number: 'PA-10',
    zip: 16911,
    congressionalDistrict: 10,
    addressesInDistrict: [
      {
        RegAddrLine1: '4 MARINA DR',
        RegAddrCity: 'CAMP HILL',
        RegAddrState: 'PA',
        RegAddrZip: 17011,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '3803 HARNESS LN',
        RegAddrCity: 'CAMP HILL',
        RegAddrState: 'PA',
        RegAddrZip: 17011,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '124 N 27TH ST',
        RegAddrCity: 'CAMP HILL',
        RegAddrState: 'PA',
        RegAddrZip: 17011,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '148 A ST',
        RegAddrCity: 'CARLISLE',
        RegAddrState: 'PA',
        RegAddrZip: 17013,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '1124 REDWOOD DR',
        RegAddrCity: 'CARLISLE',
        RegAddrState: 'PA',
        RegAddrZip: 17013,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '180 E SPRING VALLEY RD',
        RegAddrCity: 'DILLSBURG',
        RegAddrState: 'PA',
        RegAddrZip: 17019,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '2060 WYATT CIR',
        RegAddrCity: 'DOVER',
        RegAddrState: 'PA',
        RegAddrZip: 17315,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '3235 NIGHT IN GALE DR',
        RegAddrCity: 'DOVER',
        RegAddrState: 'PA',
        RegAddrZip: 17315,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '3250 N WYND AVE',
        RegAddrCity: 'DOVER',
        RegAddrState: 'PA',
        RegAddrZip: 17315,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '255 ZEIGLER RD',
        RegAddrCity: 'DOVER',
        RegAddrState: 'PA',
        RegAddrZip: 17315,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '137 W BROAD ST',
        RegAddrCity: 'ELIZABETHVILLE',
        RegAddrState: 'PA',
        RegAddrZip: 17023,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '601 HILL POINT DR',
        RegAddrCity: 'ETTERS',
        RegAddrState: 'PA',
        RegAddrZip: 17319,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '320 MANADA BOTTOM RD',
        RegAddrCity: 'GRANTVILLE',
        RegAddrState: 'PA',
        RegAddrZip: 17028,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '162 TIMBER FALLS DR',
        RegAddrCity: 'HALIFAX',
        RegAddrState: 'PA',
        RegAddrZip: 17032,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '1411 PENN ST',
        RegAddrCity: 'HARRISBURG',
        RegAddrState: 'PA',
        RegAddrZip: 17102,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '208 MARY ST',
        RegAddrCity: 'HARRISBURG',
        RegAddrState: 'PA',
        RegAddrZip: 17104,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '263 SADDLE RIDGE DR',
        RegAddrCity: 'HARRISBURG',
        RegAddrState: 'PA',
        RegAddrZip: 17110,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '6562 NEW PROVIDENCE DR',
        RegAddrCity: 'HARRISBURG',
        RegAddrState: 'PA',
        RegAddrZip: 17111,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '6131 HOCKER DR',
        RegAddrCity: 'HARRISBURG',
        RegAddrState: 'PA',
        RegAddrZip: 17111,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '5076 STACEY DR E',
        RegAddrCity: 'HARRISBURG',
        RegAddrState: 'PA',
        RegAddrZip: 17111,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '7221 HIDDEN MEADOW LN',
        RegAddrCity: 'HARRISBURG',
        RegAddrState: 'PA',
        RegAddrZip: 17112,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '2312 ABBEY LN',
        RegAddrCity: 'HARRISBURG',
        RegAddrState: 'PA',
        RegAddrZip: 17112,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '716 BACHMANVILLE RD',
        RegAddrCity: 'HERSHEY',
        RegAddrState: 'PA',
        RegAddrZip: 17033,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '2 BEAVER RD',
        RegAddrCity: 'HERSHEY',
        RegAddrState: 'PA',
        RegAddrZip: 17033,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '108 E AREBA AVE',
        RegAddrCity: 'HERSHEY',
        RegAddrState: 'PA',
        RegAddrZip: 17033,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '129 WOODS EDGE DR',
        RegAddrCity: 'HUMMELSTOWN',
        RegAddrState: 'PA',
        RegAddrZip: 17036,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '44 S HOERNERSTOWN RD',
        RegAddrCity: 'HUMMELSTOWN',
        RegAddrState: 'PA',
        RegAddrZip: 17036,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '508 OHIO AVE',
        RegAddrCity: 'LEMOYNE',
        RegAddrState: 'PA',
        RegAddrZip: 17043,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '828 SILVER LAKE RD',
        RegAddrCity: 'LEWISBERRY',
        RegAddrState: 'PA',
        RegAddrZip: 17339,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '4833 E TRINDLE RD',
        RegAddrCity: 'MECHANICSBURG',
        RegAddrState: 'PA',
        RegAddrZip: 17050,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '19 ASHBURG DR',
        RegAddrCity: 'MECHANICSBURG',
        RegAddrState: 'PA',
        RegAddrZip: 17050,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '79 HONEYSUCKLE DR',
        RegAddrCity: 'MECHANICSBURG',
        RegAddrState: 'PA',
        RegAddrZip: 17050,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '6349 MERCURY DR',
        RegAddrCity: 'MECHANICSBURG',
        RegAddrState: 'PA',
        RegAddrZip: 17050,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '705 GENEVIEVE DR',
        RegAddrCity: 'MECHANICSBURG',
        RegAddrState: 'PA',
        RegAddrZip: 17055,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '119 WINDRUSH LN',
        RegAddrCity: 'MECHANICSBURG',
        RegAddrState: 'PA',
        RegAddrZip: 17055,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '1785 SPRINGWILLOW DR',
        RegAddrCity: 'MECHANICSBURG',
        RegAddrState: 'PA',
        RegAddrZip: 17055,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '21 STEPHANIA DR',
        RegAddrCity: 'MIDDLETOWN',
        RegAddrState: 'PA',
        RegAddrZip: 17057,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '165 ABBEY DR',
        RegAddrCity: 'MOUNT WOLF',
        RegAddrState: 'PA',
        RegAddrZip: 17347,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '526 LEWISBERRY RD',
        RegAddrCity: 'NEW CUMBERLAND',
        RegAddrState: 'PA',
        RegAddrZip: 17070,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '820 FAIRWAYS DR',
        RegAddrCity: 'OAKMONT',
        RegAddrState: 'PA',
        RegAddrZip: 15139,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '209 N 2ND ST',
        RegAddrCity: 'WORMLEYSBURG',
        RegAddrState: 'PA',
        RegAddrZip: 17043,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '282 PARK ST',
        RegAddrCity: 'YORK',
        RegAddrState: 'PA',
        RegAddrZip: 17401,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '2934 5TH AVE',
        RegAddrCity: 'YORK',
        RegAddrState: 'PA',
        RegAddrZip: 17402,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '2449 CAMBRIDGE RD',
        RegAddrCity: 'YORK',
        RegAddrState: 'PA',
        RegAddrZip: 17402,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '636 QUEEN ST S ST',
        RegAddrCity: 'YORK',
        RegAddrState: 'PA',
        RegAddrZip: 17403,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '79 FOX RUN DR',
        RegAddrCity: 'YORK',
        RegAddrState: 'PA',
        RegAddrZip: 17403,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '1577 HOLLYWOOD PKWY',
        RegAddrCity: 'YORK',
        RegAddrState: 'PA',
        RegAddrZip: 17403,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '644 KYLE RD',
        RegAddrCity: 'YORK',
        RegAddrState: 'PA',
        RegAddrZip: 17404,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '80 DRESSAGE CT',
        RegAddrCity: 'YORK',
        RegAddrState: 'PA',
        RegAddrZip: 17404,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '2570 TARA LN',
        RegAddrCity: 'YORK',
        RegAddrState: 'PA',
        RegAddrZip: 17404,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '432 WOODLAND VIEW DR',
        RegAddrCity: 'YORK',
        RegAddrState: 'PA',
        RegAddrZip: 17406,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '38 COPPERWOOD CT',
        RegAddrCity: 'YORK',
        RegAddrState: 'PA',
        RegAddrZip: 17408,
        CongressionalDistrict: 10,
      },
    ],
  },
  {
    name: 'PA-11',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/PA-11?ref=gh-pages',
    state: 'PA',
    state_and_number: 'PA-11',
    zip: 17005,
    congressionalDistrict: 11,
    addressesInDistrict: [
      {
        RegAddrLine1: '43 S 8TH ST',
        RegAddrCity: 'COLUMBIA',
        RegAddrState: 'PA',
        RegAddrZip: 17512,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '442 S 6TH ST',
        RegAddrCity: 'DENVER',
        RegAddrState: 'PA',
        RegAddrZip: 17517,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '13 OAKMONT CT',
        RegAddrCity: 'DENVER',
        RegAddrState: 'PA',
        RegAddrZip: 17517,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '25 SCHOOL CT',
        RegAddrCity: 'DENVER',
        RegAddrState: 'PA',
        RegAddrZip: 17517,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '615 MAPLE ST',
        RegAddrCity: 'EAST EARL',
        RegAddrState: 'PA',
        RegAddrZip: 17519,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '5882 WILD LILAC DR',
        RegAddrCity: 'EAST PETERSBURG',
        RegAddrState: 'PA',
        RegAddrZip: 17520,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '180 OLD HERSHEY RD',
        RegAddrCity: 'ELIZABETHTOWN',
        RegAddrState: 'PA',
        RegAddrZip: 17022,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '333 WHEAT RIDGE DR',
        RegAddrCity: 'EPHRATA',
        RegAddrState: 'PA',
        RegAddrZip: 17522,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '407 WASHINGTON AVE',
        RegAddrCity: 'EPHRATA',
        RegAddrState: 'PA',
        RegAddrZip: 17522,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '252 MEADOW VALLEY RD',
        RegAddrCity: 'EPHRATA',
        RegAddrState: 'PA',
        RegAddrZip: 17522,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '7190 SCOUT RD',
        RegAddrCity: 'FELTON',
        RegAddrState: 'PA',
        RegAddrZip: 17322,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '13781 ACORN CT',
        RegAddrCity: 'GLEN ROCK',
        RegAddrState: 'PA',
        RegAddrZip: 17327,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '43 ORCHARD DR',
        RegAddrCity: 'HANOVER',
        RegAddrState: 'PA',
        RegAddrZip: 17331,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '9 FROCK DR',
        RegAddrCity: 'HANOVER',
        RegAddrState: 'PA',
        RegAddrZip: 17331,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '514 HARTMAN AVE',
        RegAddrCity: 'HANOVER',
        RegAddrState: 'PA',
        RegAddrZip: 17331,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '306 3RD ST',
        RegAddrCity: 'HANOVER',
        RegAddrState: 'PA',
        RegAddrZip: 17331,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '2993 HEARTHSIDE LN',
        RegAddrCity: 'LANCASTER',
        RegAddrState: 'PA',
        RegAddrZip: 17601,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '3131 DALE DR',
        RegAddrCity: 'LANCASTER',
        RegAddrState: 'PA',
        RegAddrZip: 17601,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '1550 PRINCESS ANNE DR',
        RegAddrCity: 'LANCASTER',
        RegAddrState: 'PA',
        RegAddrZip: 17601,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '1929 BLOOMINGDALE AVE',
        RegAddrCity: 'LANCASTER',
        RegAddrState: 'PA',
        RegAddrZip: 17601,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '1108 BENTLEY RIDGE BLVD',
        RegAddrCity: 'LANCASTER',
        RegAddrState: 'PA',
        RegAddrZip: 17602,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '303 W GRANT ST',
        RegAddrCity: 'LANCASTER',
        RegAddrState: 'PA',
        RegAddrZip: 17603,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '1054 SAINT JOSEPH ST',
        RegAddrCity: 'LANCASTER',
        RegAddrState: 'PA',
        RegAddrZip: 17603,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '121 BANYAN CIRCLE DR',
        RegAddrCity: 'LANCASTER',
        RegAddrState: 'PA',
        RegAddrZip: 17603,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '824 3RD ST',
        RegAddrCity: 'LANCASTER',
        RegAddrState: 'PA',
        RegAddrZip: 17603,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '418 N MARY ST',
        RegAddrCity: 'LANCASTER',
        RegAddrState: 'PA',
        RegAddrZip: 17603,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '328 W WALNUT ST',
        RegAddrCity: 'LANCASTER',
        RegAddrState: 'PA',
        RegAddrZip: 17603,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '1950 EDINGTON PL',
        RegAddrCity: 'LANCASTER',
        RegAddrState: 'PA',
        RegAddrZip: 17603,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '2901 HARRISBURG PIKE',
        RegAddrCity: 'LANDISVILLE',
        RegAddrState: 'PA',
        RegAddrZip: 17538,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '455 HOLLY ANN DR',
        RegAddrCity: 'LANDISVILLE',
        RegAddrState: 'PA',
        RegAddrZip: 17538,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '52 GREEN ACRE RD',
        RegAddrCity: 'LITITZ',
        RegAddrState: 'PA',
        RegAddrZip: 17543,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '1508 E NEWPORT RD',
        RegAddrCity: 'LITITZ',
        RegAddrState: 'PA',
        RegAddrZip: 17543,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '133 OLDE FIELD DR',
        RegAddrCity: 'LITITZ',
        RegAddrState: 'PA',
        RegAddrZip: 17543,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '134 PEPPERTON CT',
        RegAddrCity: 'LITITZ',
        RegAddrState: 'PA',
        RegAddrZip: 17543,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '250 MAIN ST S ST',
        RegAddrCity: 'LOGANVILLE',
        RegAddrState: 'PA',
        RegAddrZip: 17342,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '321 RIVERMOOR DR',
        RegAddrCity: 'MARIETTA',
        RegAddrState: 'PA',
        RegAddrZip: 17547,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '72 SILVERLEAF DR',
        RegAddrCity: 'MOUNT JOY',
        RegAddrState: 'PA',
        RegAddrZip: 17552,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '448 HILL ST',
        RegAddrCity: 'MOUNT JOY',
        RegAddrState: 'PA',
        RegAddrZip: 17552,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '395 MUSSER RD',
        RegAddrCity: 'MOUNT JOY',
        RegAddrState: 'PA',
        RegAddrZip: 17552,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '254 WHITE OAK RD',
        RegAddrCity: 'NEW HOLLAND',
        RegAddrState: 'PA',
        RegAddrZip: 17557,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '287 SNYDER HOLLOW RD',
        RegAddrCity: 'NEW PROVIDNCE',
        RegAddrState: 'PA',
        RegAddrZip: 17560,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '20 E E 4TH ST',
        RegAddrCity: 'QUARRYVILLE',
        RegAddrState: 'PA',
        RegAddrZip: 17566,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '18 CLIFFWOOD DR',
        RegAddrCity: 'QUARRYVILLE',
        RegAddrState: 'PA',
        RegAddrZip: 17566,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '525 LAKEVIEW DR',
        RegAddrCity: 'SPRING GROVE',
        RegAddrState: 'PA',
        RegAddrZip: 17362,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '26 CHURCH ST',
        RegAddrCity: 'STEWARTSTOWN',
        RegAddrState: 'PA',
        RegAddrZip: 17363,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '8 ROBBY LN',
        RegAddrCity: 'STRASBURG',
        RegAddrState: 'PA',
        RegAddrZip: 17579,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '18 S DECATUR ST',
        RegAddrCity: 'STRASBURG',
        RegAddrState: 'PA',
        RegAddrZip: 17579,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '1028 MILLERS SPRING RD',
        RegAddrCity: 'YORK',
        RegAddrState: 'PA',
        RegAddrZip: 17406,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '6380 LEADER DR',
        RegAddrCity: 'YORK',
        RegAddrState: 'PA',
        RegAddrZip: 17407,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '102 MAIN ST N ST',
        RegAddrCity: 'YORK',
        RegAddrState: 'PA',
        RegAddrZip: 17407,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '97 MAIN ST N ST',
        RegAddrCity: 'YORK NW SALEM',
        RegAddrState: 'PA',
        RegAddrZip: 17371,
        CongressionalDistrict: 11,
      },
    ],
  },
  {
    name: 'PA-12',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/PA-12?ref=gh-pages',
    state: 'PA',
    state_and_number: 'PA-12',
    zip: 15001,
    congressionalDistrict: 12,
    addressesInDistrict: [
      {
        RegAddrLine1: '3655 STATE ROUTE 44',
        RegAddrCity: 'ALLENWOOD',
        RegAddrState: 'PA',
        RegAddrZip: 17810,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '355 MOORE RD',
        RegAddrCity: 'ATHENS',
        RegAddrState: 'PA',
        RegAddrZip: 18810,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '206 SHAWNEE DR',
        RegAddrCity: 'BELLEVILLE',
        RegAddrState: 'PA',
        RegAddrZip: 17004,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '131 MARBETH AVE',
        RegAddrCity: 'CARLISLE',
        RegAddrState: 'PA',
        RegAddrZip: 17013,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '3 PEET ST',
        RegAddrCity: 'COUDERSPORT',
        RegAddrState: 'PA',
        RegAddrZip: 16915,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '204 ORCHARD RD',
        RegAddrCity: 'DALMATIA',
        RegAddrState: 'PA',
        RegAddrZip: 17017,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '223 SUMMER ST',
        RegAddrCity: 'DUBOISTOWN',
        RegAddrState: 'PA',
        RegAddrZip: 17702,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '186 COUNTRY CLUB RD',
        RegAddrCity: 'EAGLES MERE',
        RegAddrState: 'PA',
        RegAddrZip: 17731,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '633 RAILROAD ST',
        RegAddrCity: 'FOREST CITY',
        RegAddrState: 'PA',
        RegAddrZip: 18421,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '2955 SR 87',
        RegAddrCity: 'FORKSTON TWP',
        RegAddrState: 'PA',
        RegAddrZip: 18629,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '3636 THOMPSON HILL RD',
        RegAddrCity: 'GILLETT',
        RegAddrState: 'PA',
        RegAddrZip: 16925,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '104 MAPLE ST',
        RegAddrCity: 'GREAT BEND',
        RegAddrState: 'PA',
        RegAddrZip: 18821,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '28 RAILROAD AVE',
        RegAddrCity: 'HALLSTEAD',
        RegAddrState: 'PA',
        RegAddrZip: 18822,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '241 OLD FORGE HILL RD',
        RegAddrCity: 'JERSEY SHORE',
        RegAddrState: 'PA',
        RegAddrZip: 17740,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '102 PEASE HILL RD',
        RegAddrCity: 'LAWRENCEVILLE',
        RegAddrState: 'PA',
        RegAddrZip: 16929,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '310 S 17TH ST',
        RegAddrCity: 'LEWISBURG',
        RegAddrState: 'PA',
        RegAddrZip: 17837,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '560 CHRISTY MATHEWSON DR',
        RegAddrCity: 'LEWISBURG',
        RegAddrState: 'PA',
        RegAddrZip: 17837,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '459 VILLA VISTA AVE',
        RegAddrCity: 'LEWISBURG',
        RegAddrState: 'PA',
        RegAddrZip: 17837,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '585 COLEMAN HALL DR',
        RegAddrCity: 'LEWISBURG',
        RegAddrState: 'PA',
        RegAddrZip: 17837,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '700 HARVARD ST',
        RegAddrCity: 'LEWISTOWN',
        RegAddrState: 'PA',
        RegAddrZip: 17044,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '42 N WALNUT ST',
        RegAddrCity: 'LEWISTOWN',
        RegAddrState: 'PA',
        RegAddrZip: 17044,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '278 MAIN LINE DR',
        RegAddrCity: 'LEWISTOWN',
        RegAddrState: 'PA',
        RegAddrZip: 17044,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '401 N FAIRVIEW ST',
        RegAddrCity: 'LOCK HAVEN',
        RegAddrState: 'PA',
        RegAddrZip: 17745,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '401 MARKET ST',
        RegAddrCity: 'MIFFLINBURG',
        RegAddrState: 'PA',
        RegAddrZip: 17844,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '129 BRITCHER DR',
        RegAddrCity: 'MIFFLINTOWN',
        RegAddrState: 'PA',
        RegAddrZip: 17059,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '2165 COCOLAMUS CREEK RD',
        RegAddrCity: 'MILLERSTOWN',
        RegAddrState: 'PA',
        RegAddrZip: 17062,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '1379 SIGLERVILLE PIKE',
        RegAddrCity: 'MILROY',
        RegAddrState: 'PA',
        RegAddrZip: 17063,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '1131 MOUNTAIN RD',
        RegAddrCity: 'MONTOURSVILLE',
        RegAddrState: 'PA',
        RegAddrZip: 17754,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '35 PINE ST',
        RegAddrCity: 'MONTROSE',
        RegAddrState: 'PA',
        RegAddrZip: 18801,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '155 SPROUT RD',
        RegAddrCity: 'MONTROSE',
        RegAddrState: 'PA',
        RegAddrZip: 18801,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '1637 GUITON RD',
        RegAddrCity: 'MONTROSE',
        RegAddrState: 'PA',
        RegAddrZip: 18801,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '3751 ROUTE 414',
        RegAddrCity: 'MORRIS',
        RegAddrState: 'PA',
        RegAddrZip: 16938,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '105 W WATER ST',
        RegAddrCity: 'MUNCY',
        RegAddrState: 'PA',
        RegAddrZip: 17756,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '138 ROTH RD',
        RegAddrCity: 'NEW BLOOMFLD',
        RegAddrState: 'PA',
        RegAddrZip: 17068,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '1995 TROUT AVE',
        RegAddrCity: 'NEWPORT',
        RegAddrState: 'PA',
        RegAddrZip: 17074,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '126 DORNSIFE RD',
        RegAddrCity: 'NORTHUMBERLND',
        RegAddrState: 'PA',
        RegAddrZip: 17857,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '1066 5TH ST',
        RegAddrCity: 'RIVERSIDE',
        RegAddrState: 'PA',
        RegAddrZip: 17868,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '1569 W W MOUNTAIN AVE',
        RegAddrCity: 'S WILLIAMSPORT',
        RegAddrState: 'PA',
        RegAddrZip: 17702,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '518 LINCOLN ST',
        RegAddrCity: 'SAYRE',
        RegAddrState: 'PA',
        RegAddrZip: 18840,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '2607 GENERAL POTTER HWY',
        RegAddrCity: 'SPRING MILLS',
        RegAddrState: 'PA',
        RegAddrZip: 16875,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '132 SOUTHVIEW CIR',
        RegAddrCity: 'STATE COLLEGE',
        RegAddrState: 'PA',
        RegAddrZip: 16801,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '116 LONGMEADOW LN',
        RegAddrCity: 'STATE COLLEGE',
        RegAddrState: 'PA',
        RegAddrZip: 16803,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '6462 SHESHEQUIN RD',
        RegAddrCity: 'ULSTER',
        RegAddrState: 'PA',
        RegAddrZip: 18850,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: 'THOMPSON HALL 0341',
        RegAddrCity: 'UNIVERSITY PK',
        RegAddrState: 'PA',
        RegAddrZip: 16802,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: 'HARTRANFT HALL 406',
        RegAddrCity: 'UNIVERSITY PK',
        RegAddrState: 'PA',
        RegAddrZip: 16802,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '2864 STONY FORK RD',
        RegAddrCity: 'WELLSBORO',
        RegAddrState: 'PA',
        RegAddrZip: 16901,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '529 HOWARD ST',
        RegAddrCity: 'WILLIAMSPORT',
        RegAddrState: 'PA',
        RegAddrZip: 17701,
        CongressionalDistrict: 12,
      },
    ],
  },
  {
    name: 'PA-13',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/PA-13?ref=gh-pages',
    state: 'PA',
    state_and_number: 'PA-13',
    zip: 18936,
    congressionalDistrict: 13,
    addressesInDistrict: [
      {
        RegAddrLine1: '8667 RIVER RD',
        RegAddrCity: 'ALEXANDRIA',
        RegAddrState: 'PA',
        RegAddrZip: 16611,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '3000 IVYSIDE PARK',
        RegAddrCity: 'ALTOONA',
        RegAddrState: 'PA',
        RegAddrZip: 16601,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '1456 SPOOK HOLLOW RD',
        RegAddrCity: 'ALTOONA',
        RegAddrState: 'PA',
        RegAddrZip: 16601,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '227 LEXINGTON AVE',
        RegAddrCity: 'ALTOONA',
        RegAddrState: 'PA',
        RegAddrZip: 16601,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '213 WALNUT AVE',
        RegAddrCity: 'ALTOONA',
        RegAddrState: 'PA',
        RegAddrZip: 16601,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '244 GETTYSBURG ST',
        RegAddrCity: 'ARENDTSVILLE',
        RegAddrState: 'PA',
        RegAddrZip: 17303,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '3691 BUSINESS 220',
        RegAddrCity: 'BEDFORD',
        RegAddrState: 'PA',
        RegAddrZip: 15522,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '128 K ST',
        RegAddrCity: 'BEDFORD',
        RegAddrState: 'PA',
        RegAddrZip: 15522,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '322 CLARK ST',
        RegAddrCity: 'BELLWOOD',
        RegAddrState: 'PA',
        RegAddrZip: 16617,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '1900 BROTHERTON RD',
        RegAddrCity: 'BERLIN',
        RegAddrState: 'PA',
        RegAddrZip: 15530,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '42 CLAY RD',
        RegAddrCity: 'CARLISLE',
        RegAddrState: 'PA',
        RegAddrZip: 17015,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '3632 VALLEY VIEW DR',
        RegAddrCity: 'CHAMBERSBURG',
        RegAddrState: 'PA',
        RegAddrZip: 17202,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '330 WEAVER RD',
        RegAddrCity: 'EVERETT',
        RegAddrState: 'PA',
        RegAddrZip: 15537,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '215 STULTZ RD',
        RegAddrCity: 'FAIRFIELD',
        RegAddrState: 'PA',
        RegAddrZip: 17320,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '220 BON OX RD',
        RegAddrCity: 'GETTYSBURG',
        RegAddrState: 'PA',
        RegAddrZip: 17325,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '412 LONGSTREET DR',
        RegAddrCity: 'GETTYSBURG',
        RegAddrState: 'PA',
        RegAddrZip: 17325,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '774 LEITERSBURG RD',
        RegAddrCity: 'GREENCASTLE',
        RegAddrState: 'PA',
        RegAddrZip: 17225,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '34 N ALLWOOD DR',
        RegAddrCity: 'HANOVER',
        RegAddrState: 'PA',
        RegAddrZip: 17331,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '127 COMMANCHE TRL',
        RegAddrCity: 'HANOVER',
        RegAddrState: 'PA',
        RegAddrZip: 17331,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '118 PHILLIPS ST',
        RegAddrCity: 'JEROME',
        RegAddrState: 'PA',
        RegAddrZip: 15937,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '216 COLDREN ST',
        RegAddrCity: 'JOHNSTOWN',
        RegAddrState: 'PA',
        RegAddrZip: 15904,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '220 COLUMBIA ST',
        RegAddrCity: 'JOHNSTOWN',
        RegAddrState: 'PA',
        RegAddrZip: 15905,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '559 BEECHWOOD DR',
        RegAddrCity: 'LATROBE',
        RegAddrState: 'PA',
        RegAddrZip: 15650,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '130 WHITFIELD DR',
        RegAddrCity: 'LATROBE',
        RegAddrState: 'PA',
        RegAddrZip: 15650,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '731 LAUREL DR',
        RegAddrCity: 'LIGONIER',
        RegAddrState: 'PA',
        RegAddrZip: 15658,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '8910 FORT LOUDON RD',
        RegAddrCity: 'MERCERSBURG',
        RegAddrState: 'PA',
        RegAddrZip: 17236,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '15497 CUMMINGS RD',
        RegAddrCity: 'MOUNT UNION',
        RegAddrState: 'PA',
        RegAddrZip: 17066,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '505 DAUBERTON DR',
        RegAddrCity: 'NEW OXFORD',
        RegAddrState: 'PA',
        RegAddrZip: 17350,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '8395 NEWBURG RD',
        RegAddrCity: 'NEWBURG',
        RegAddrState: 'PA',
        RegAddrZip: 17240,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '7 VAUGHN RD',
        RegAddrCity: 'NEWVILLE',
        RegAddrState: 'PA',
        RegAddrZip: 17241,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '1080 GRAHAMS WOOD RD',
        RegAddrCity: 'NEWVILLE',
        RegAddrState: 'PA',
        RegAddrZip: 17241,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '1309 BOBS CREEK RD',
        RegAddrCity: 'OSTERBURG',
        RegAddrState: 'PA',
        RegAddrZip: 16667,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '119 HEUSTON RD',
        RegAddrCity: 'ROARING SPG',
        RegAddrState: 'PA',
        RegAddrZip: 16673,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '1077 MILL RD',
        RegAddrCity: 'SCHELLSBURG',
        RegAddrState: 'PA',
        RegAddrZip: 15559,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '8702 PEIPER RD',
        RegAddrCity: 'SHIPPENSBURG',
        RegAddrState: 'PA',
        RegAddrZip: 17257,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '540 WHITETAIL RD',
        RegAddrCity: 'SOMERSET',
        RegAddrState: 'PA',
        RegAddrZip: 15501,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '137 BRENT LN',
        RegAddrCity: 'SOMERSET',
        RegAddrState: 'PA',
        RegAddrZip: 15501,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: 'PO BOX 24',
        RegAddrCity: 'SPRING RUN',
        RegAddrState: 'PA',
        RegAddrZip: 17262,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '4072 SPRUCE CREEK RD',
        RegAddrCity: 'SPRUCE CREEK',
        RegAddrState: 'PA',
        RegAddrZip: 16683,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '4154 MAIN ST',
        RegAddrCity: 'STAHLSTOWN',
        RegAddrState: 'PA',
        RegAddrZip: 15687,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: 'PO BOX 82',
        RegAddrCity: 'TIPTON',
        RegAddrState: 'PA',
        RegAddrZip: 16684,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '512 OAK LN',
        RegAddrCity: 'TYRONE',
        RegAddrState: 'PA',
        RegAddrZip: 16686,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '2553 HUNDRED SPRINGS RD',
        RegAddrCity: 'TYRONE',
        RegAddrState: 'PA',
        RegAddrZip: 16686,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '753 PARK AVE',
        RegAddrCity: 'TYRONE',
        RegAddrState: 'PA',
        RegAddrZip: 16686,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '11343 WHARF RD',
        RegAddrCity: 'WAYNESBORO',
        RegAddrState: 'PA',
        RegAddrZip: 17268,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '14068 GARDNER AVE',
        RegAddrCity: 'WAYNESBORO',
        RegAddrState: 'PA',
        RegAddrZip: 17268,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '901 VINCENT DR',
        RegAddrCity: 'WINDBER',
        RegAddrState: 'PA',
        RegAddrZip: 15963,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '347 STRAYER RD',
        RegAddrCity: 'YORK SPRINGS',
        RegAddrState: 'PA',
        RegAddrZip: 17372,
        CongressionalDistrict: 13,
      },
    ],
  },
  {
    name: 'PA-14',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/PA-14?ref=gh-pages',
    state: 'PA',
    state_and_number: 'PA-14',
    zip: 15014,
    congressionalDistrict: 14,
    addressesInDistrict: [
      {
        RegAddrLine1: '136 RIVER ST',
        RegAddrCity: 'ADAH',
        RegAddrState: 'PA',
        RegAddrZip: 15410,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '17 MINER AVE',
        RegAddrCity: 'BENTLEYVILLE',
        RegAddrState: 'PA',
        RegAddrZip: 15314,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: 'LARIMER AVE',
        RegAddrCity: 'BOBTOWN',
        RegAddrState: 'PA',
        RegAddrZip: 15315,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '12 LONG ST',
        RegAddrCity: 'BURGETTSTOWN',
        RegAddrState: 'PA',
        RegAddrZip: 15021,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '109 CHAMBERS LN',
        RegAddrCity: 'CANONSBURG',
        RegAddrState: 'PA',
        RegAddrZip: 15317,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '679 LINNWOOD RD',
        RegAddrCity: 'CANONSBURG',
        RegAddrState: 'PA',
        RegAddrZip: 15317,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '515 NORTH AVE',
        RegAddrCity: 'CONNELLSVILLE',
        RegAddrState: 'PA',
        RegAddrZip: 15425,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '143 S 9TH ST',
        RegAddrCity: 'CONNELLSVILLE',
        RegAddrState: 'PA',
        RegAddrZip: 15425,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '319 MCELHANEY AVE',
        RegAddrCity: 'CONNELLSVILLE',
        RegAddrState: 'PA',
        RegAddrZip: 15425,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '44 MANORFIELD CIR',
        RegAddrCity: 'DELMONT',
        RegAddrState: 'PA',
        RegAddrZip: 15626,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '385 LAUREL HILL RD',
        RegAddrCity: 'DUNBAR',
        RegAddrState: 'PA',
        RegAddrZip: 15431,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '712 MCKINLEY AVE',
        RegAddrCity: 'E VANDERGRIFT',
        RegAddrState: 'PA',
        RegAddrZip: 15629,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '475 GREEN VALLEY RD',
        RegAddrCity: 'EIGHTY FOUR',
        RegAddrState: 'PA',
        RegAddrZip: 15330,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '5917 CLARIDGE RD',
        RegAddrCity: 'EXPORT',
        RegAddrState: 'PA',
        RegAddrZip: 15632,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '5838 GRANT ST',
        RegAddrCity: 'EXPORT',
        RegAddrState: 'PA',
        RegAddrZip: 15632,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '11 S OAK ST',
        RegAddrCity: 'FAIRCHANCE',
        RegAddrState: 'PA',
        RegAddrZip: 15436,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '102 MCINTOSH DR',
        RegAddrCity: 'FINLEYVILLE',
        RegAddrState: 'PA',
        RegAddrZip: 15332,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '400 WOOD DUCK DR',
        RegAddrCity: 'GREENSBURG',
        RegAddrState: 'PA',
        RegAddrZip: 15601,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: 'I-11 W HILLS DR APT',
        RegAddrCity: 'GREENSBURG',
        RegAddrState: 'PA',
        RegAddrZip: 15601,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '12 GLENOAK ST',
        RegAddrCity: 'GREENSBURG',
        RegAddrState: 'PA',
        RegAddrZip: 15601,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '45 TIMBER TRAIL DR',
        RegAddrCity: 'GREENSBURG',
        RegAddrState: 'PA',
        RegAddrZip: 15601,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '1010 IVY ST',
        RegAddrCity: 'GREENSBURG',
        RegAddrState: 'PA',
        RegAddrZip: 15601,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '1523 BRAZNELL RD',
        RegAddrCity: 'GRINDSTONE',
        RegAddrState: 'PA',
        RegAddrZip: 15442,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '130 WHISTLE DR',
        RegAddrCity: 'HARRISON CITY',
        RegAddrState: 'PA',
        RegAddrZip: 15636,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '168 SKYLINE DR',
        RegAddrCity: 'HICKORY',
        RegAddrState: 'PA',
        RegAddrZip: 15340,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '130 AVELLA RD',
        RegAddrCity: 'HICKORY',
        RegAddrState: 'PA',
        RegAddrZip: 15340,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '4548 ROBIN DR',
        RegAddrCity: 'JEANNETTE',
        RegAddrState: 'PA',
        RegAddrZip: 15644,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '232 EASTERN DR',
        RegAddrCity: 'LOWER BURRELL',
        RegAddrState: 'PA',
        RegAddrZip: 15068,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '306 S MAIN ST',
        RegAddrCity: 'MASONTOWN',
        RegAddrState: 'PA',
        RegAddrZip: 15461,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '337 MARUCA DR',
        RegAddrCity: 'MT PLEASANT',
        RegAddrState: 'PA',
        RegAddrZip: 15666,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '1798 SANDY HILL RD',
        RegAddrCity: 'N HUNTINGDON',
        RegAddrState: 'PA',
        RegAddrZip: 15642,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '1441 MARCIA DR',
        RegAddrCity: 'N HUNTINGDON',
        RegAddrState: 'PA',
        RegAddrZip: 15642,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '2020 NORTHWEST DR',
        RegAddrCity: 'N HUNTINGDON',
        RegAddrState: 'PA',
        RegAddrZip: 15642,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '130 LOCUST DR',
        RegAddrCity: 'NEW ALEXANDRI',
        RegAddrState: 'PA',
        RegAddrZip: 15670,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '376 DINNER BELL RD',
        RegAddrCity: 'OHIOPYLE',
        RegAddrState: 'PA',
        RegAddrZip: 15470,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '407 CYRUS ST',
        RegAddrCity: 'POINT MARION',
        RegAddrState: 'PA',
        RegAddrZip: 15474,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '404 ONTARIO ST',
        RegAddrCity: 'POINT MARION',
        RegAddrState: 'PA',
        RegAddrZip: 15474,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '236 VAN METER RD',
        RegAddrCity: 'ROSTRAVER TWP',
        RegAddrState: 'PA',
        RegAddrZip: 15012,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '755 CHURCH ST',
        RegAddrCity: 'SMOCK',
        RegAddrState: 'PA',
        RegAddrZip: 15480,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '309 IRON BITE RD',
        RegAddrCity: 'TARRS',
        RegAddrState: 'PA',
        RegAddrZip: 15688,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '107 PAINTER ST',
        RegAddrCity: 'TRAFFORD',
        RegAddrState: 'PA',
        RegAddrZip: 15085,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '11 SNYDER TER',
        RegAddrCity: 'UNIONTOWN',
        RegAddrState: 'PA',
        RegAddrZip: 15401,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '47 ORCHARD LN',
        RegAddrCity: 'UNIONTOWN',
        RegAddrState: 'PA',
        RegAddrZip: 15401,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '800 ASHWOOD DR',
        RegAddrCity: 'VENETIA',
        RegAddrState: 'PA',
        RegAddrZip: 15367,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '805 ASHWOOD DR',
        RegAddrCity: 'VENETIA',
        RegAddrState: 'PA',
        RegAddrZip: 15367,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '1315 W WYLIE AVE',
        RegAddrCity: 'WASHINGTON',
        RegAddrState: 'PA',
        RegAddrZip: 15301,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '1925 S MAIN ST',
        RegAddrCity: 'WASHINGTON',
        RegAddrState: 'PA',
        RegAddrZip: 15301,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '124 3RD AVE',
        RegAddrCity: 'WAYNESBURG',
        RegAddrState: 'PA',
        RegAddrZip: 15370,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '565 W HIGH ST',
        RegAddrCity: 'WAYNESBURG',
        RegAddrState: 'PA',
        RegAddrZip: 15370,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '205 JEFFERSON AVE',
        RegAddrCity: 'WEST BROWNSVILLE',
        RegAddrState: 'PA',
        RegAddrZip: 15417,
        CongressionalDistrict: 14,
      },
    ],
  },
  {
    name: 'PA-15',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/PA-15?ref=gh-pages',
    state: 'PA',
    state_and_number: 'PA-15',
    zip: 17003,
    congressionalDistrict: 15,
    addressesInDistrict: [
      {
        RegAddrLine1: '10394 ROUTE 119 HWY S',
        RegAddrCity: 'BLAIRSVILLE',
        RegAddrState: 'PA',
        RegAddrZip: 15717,
        CongressionalDistrict: 15,
      },
      {
        RegAddrLine1: '112 BOGGY RUN RD',
        RegAddrCity: 'BROCKPORT',
        RegAddrState: 'PA',
        RegAddrZip: 15823,
        CongressionalDistrict: 15,
      },
      {
        RegAddrLine1: '76 EUCLID AVE',
        RegAddrCity: 'BROOKVILLE',
        RegAddrState: 'PA',
        RegAddrZip: 15825,
        CongressionalDistrict: 15,
      },
      {
        RegAddrLine1: '226 FRANKLIN AVE',
        RegAddrCity: 'BROOKVILLE',
        RegAddrState: 'PA',
        RegAddrZip: 15825,
        CongressionalDistrict: 15,
      },
      {
        RegAddrLine1: '223 BISH RD',
        RegAddrCity: 'CHICORA',
        RegAddrState: 'PA',
        RegAddrZip: 16025,
        CongressionalDistrict: 15,
      },
      {
        RegAddrLine1: '19636 ROUTE 957',
        RegAddrCity: 'COLUMBUS',
        RegAddrState: 'PA',
        RegAddrZip: 16405,
        CongressionalDistrict: 15,
      },
      {
        RegAddrLine1: '702 CROSSROADS RD',
        RegAddrCity: 'DAYTON',
        RegAddrState: 'PA',
        RegAddrZip: 16222,
        CongressionalDistrict: 15,
      },
      {
        RegAddrLine1: '239 FLAMINGO RD',
        RegAddrCity: 'DUBOIS',
        RegAddrState: 'PA',
        RegAddrZip: 15801,
        CongressionalDistrict: 15,
      },
      {
        RegAddrLine1: '1050 WILMORE RD',
        RegAddrCity: 'EBENSBURG',
        RegAddrState: 'PA',
        RegAddrZip: 15931,
        CongressionalDistrict: 15,
      },
      {
        RegAddrLine1: '122 S CAROLINE ST',
        RegAddrCity: 'EBENSBURG',
        RegAddrState: 'PA',
        RegAddrZip: 15931,
        CongressionalDistrict: 15,
      },
      {
        RegAddrLine1: '911 CLEARFIELD RD',
        RegAddrCity: 'FENELTON',
        RegAddrState: 'PA',
        RegAddrZip: 16034,
        CongressionalDistrict: 15,
      },
      {
        RegAddrLine1: '115 MIDWAY ST',
        RegAddrCity: 'FORD CITY',
        RegAddrState: 'PA',
        RegAddrZip: 16226,
        CongressionalDistrict: 15,
      },
      {
        RegAddrLine1: '108 RACEWAY ST',
        RegAddrCity: 'FORD CITY',
        RegAddrState: 'PA',
        RegAddrZip: 16226,
        CongressionalDistrict: 15,
      },
      {
        RegAddrLine1: '98 2ND AVE',
        RegAddrCity: 'FRANKLIN',
        RegAddrState: 'PA',
        RegAddrZip: 16323,
        CongressionalDistrict: 15,
      },
      {
        RegAddrLine1: '2456 CLAGHORN RD',
        RegAddrCity: 'HOMER CITY',
        RegAddrState: 'PA',
        RegAddrZip: 15748,
        CongressionalDistrict: 15,
      },
      {
        RegAddrLine1: '76 JUNIATA AVE EXT',
        RegAddrCity: 'HOUTZDALE',
        RegAddrState: 'PA',
        RegAddrZip: 16651,
        CongressionalDistrict: 15,
      },
      {
        RegAddrLine1: '947 SEXTON RD',
        RegAddrCity: 'INDIANA',
        RegAddrState: 'PA',
        RegAddrZip: 15701,
        CongressionalDistrict: 15,
      },
      {
        RegAddrLine1: '695 KNOX ST',
        RegAddrCity: 'INDIANA',
        RegAddrState: 'PA',
        RegAddrZip: 15701,
        CongressionalDistrict: 15,
      },
      {
        RegAddrLine1: '124 HOSTETLER RD',
        RegAddrCity: 'JOHNSTOWN',
        RegAddrState: 'PA',
        RegAddrZip: 15904,
        CongressionalDistrict: 15,
      },
      {
        RegAddrLine1: '188 BIRTLE RD',
        RegAddrCity: 'JOHNSTOWN',
        RegAddrState: 'PA',
        RegAddrZip: 15904,
        CongressionalDistrict: 15,
      },
      {
        RegAddrLine1: '130 SKYLINE DR',
        RegAddrCity: 'KERSEY',
        RegAddrState: 'PA',
        RegAddrZip: 15846,
        CongressionalDistrict: 15,
      },
      {
        RegAddrLine1: '142 ALLEGHENY AVE',
        RegAddrCity: 'KITTANNING',
        RegAddrState: 'PA',
        RegAddrZip: 16201,
        CongressionalDistrict: 15,
      },
      {
        RegAddrLine1: '200 S JEFFERSON ST',
        RegAddrCity: 'KITTANNING',
        RegAddrState: 'PA',
        RegAddrZip: 16201,
        CongressionalDistrict: 15,
      },
      {
        RegAddrLine1: '412 WASHINGTON ST',
        RegAddrCity: 'LILLY',
        RegAddrState: 'PA',
        RegAddrZip: 15938,
        CongressionalDistrict: 15,
      },
      {
        RegAddrLine1: '225 NAGEL RD',
        RegAddrCity: 'OIL CITY',
        RegAddrState: 'PA',
        RegAddrZip: 16301,
        CongressionalDistrict: 15,
      },
      {
        RegAddrLine1: '209 RAIL LN',
        RegAddrCity: 'PATTON',
        RegAddrState: 'PA',
        RegAddrZip: 16668,
        CongressionalDistrict: 15,
      },
      {
        RegAddrLine1: '87 CROSSARMS LN',
        RegAddrCity: 'PITTSFIELD',
        RegAddrState: 'PA',
        RegAddrZip: 16340,
        CongressionalDistrict: 15,
      },
      {
        RegAddrLine1: '151 SUNSET AVE',
        RegAddrCity: 'PLEASANT GAP',
        RegAddrState: 'PA',
        RegAddrZip: 16823,
        CongressionalDistrict: 15,
      },
      {
        RegAddrLine1: '162 MERRICK ST',
        RegAddrCity: 'PLEASANTVILLE',
        RegAddrState: 'PA',
        RegAddrZip: 16341,
        CongressionalDistrict: 15,
      },
      {
        RegAddrLine1: '1086 COLEMAN MILLS RD',
        RegAddrCity: 'PORT ALLEGANY',
        RegAddrState: 'PA',
        RegAddrZip: 16743,
        CongressionalDistrict: 15,
      },
      {
        RegAddrLine1: '126 ZIMMERMANS LN',
        RegAddrCity: 'PORTAGE',
        RegAddrState: 'PA',
        RegAddrZip: 15946,
        CongressionalDistrict: 15,
      },
      {
        RegAddrLine1: '37 SPRUCE LN',
        RegAddrCity: 'RIMERSBURG',
        RegAddrState: 'PA',
        RegAddrZip: 16248,
        CongressionalDistrict: 15,
      },
      {
        RegAddrLine1: '912 STATE ROUTE 2003',
        RegAddrCity: 'RURAL VALLEY',
        RegAddrState: 'PA',
        RegAddrZip: 16249,
        CongressionalDistrict: 15,
      },
      {
        RegAddrLine1: '461 LUDWICK RD',
        RegAddrCity: 'RUSSELL',
        RegAddrState: 'PA',
        RegAddrZip: 16345,
        CongressionalDistrict: 15,
      },
      {
        RegAddrLine1: '183 CREEK SIDE DR',
        RegAddrCity: 'SARVER',
        RegAddrState: 'PA',
        RegAddrZip: 16055,
        CongressionalDistrict: 15,
      },
      {
        RegAddrLine1: '113 S MAIN ST',
        RegAddrCity: 'SENECA',
        RegAddrState: 'PA',
        RegAddrZip: 16346,
        CongressionalDistrict: 15,
      },
      {
        RegAddrLine1: '1957 AUSTIN HILL RD',
        RegAddrCity: 'SHEFFIELD',
        RegAddrState: 'PA',
        RegAddrZip: 16347,
        CongressionalDistrict: 15,
      },
      {
        RegAddrLine1: '1448 MCCREIGHT RD',
        RegAddrCity: 'SHELOCTA',
        RegAddrState: 'PA',
        RegAddrZip: 15774,
        CongressionalDistrict: 15,
      },
      {
        RegAddrLine1: '17449 ROUTE 68',
        RegAddrCity: 'SLIGO',
        RegAddrState: 'PA',
        RegAddrZip: 16255,
        CongressionalDistrict: 15,
      },
      {
        RegAddrLine1: '100 FRONT ST',
        RegAddrCity: 'SLIGO',
        RegAddrState: 'PA',
        RegAddrZip: 16255,
        CongressionalDistrict: 15,
      },
      {
        RegAddrLine1: '547 IRISH HOLW',
        RegAddrCity: 'SMETHPORT',
        RegAddrState: 'PA',
        RegAddrZip: 16749,
        CongressionalDistrict: 15,
      },
      {
        RegAddrLine1: '547 IRISH HOLW',
        RegAddrCity: 'SMETHPORT',
        RegAddrState: 'PA',
        RegAddrZip: 16749,
        CongressionalDistrict: 15,
      },
      {
        RegAddrLine1: '207 E WATER ST',
        RegAddrCity: 'SMETHPORT',
        RegAddrState: 'PA',
        RegAddrZip: 16749,
        CongressionalDistrict: 15,
      },
      {
        RegAddrLine1: '231 PIKE ST',
        RegAddrCity: 'SMITHMILL',
        RegAddrState: 'PA',
        RegAddrZip: 16680,
        CongressionalDistrict: 15,
      },
      {
        RegAddrLine1: '234 MARTIN RD',
        RegAddrCity: 'ST MARYS',
        RegAddrState: 'PA',
        RegAddrZip: 15857,
        CongressionalDistrict: 15,
      },
      {
        RegAddrLine1: '262 DUTCH RD',
        RegAddrCity: 'VENUS',
        RegAddrState: 'PA',
        RegAddrZip: 16364,
        CongressionalDistrict: 15,
      },
      {
        RegAddrLine1: '745 YOUNG RD',
        RegAddrCity: 'WESTOVER',
        RegAddrState: 'PA',
        RegAddrZip: 16692,
        CongressionalDistrict: 15,
      },
      {
        RegAddrLine1: '171 GLORY DR',
        RegAddrCity: 'WINDBER',
        RegAddrState: 'PA',
        RegAddrZip: 15963,
        CongressionalDistrict: 15,
      },
      {
        RegAddrLine1: '4068 EGYPT RD',
        RegAddrCity: 'WOODLAND',
        RegAddrState: 'PA',
        RegAddrZip: 16881,
        CongressionalDistrict: 15,
      },
    ],
  },
  {
    name: 'PA-16',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/PA-16?ref=gh-pages',
    state: 'PA',
    state_and_number: 'PA-16',
    zip: 17501,
    congressionalDistrict: 16,
    addressesInDistrict: [
      {
        RegAddrLine1: '49 PARK AVE',
        RegAddrCity: 'ALBION',
        RegAddrState: 'PA',
        RegAddrZip: 16401,
        CongressionalDistrict: 16,
      },
      {
        RegAddrLine1: '112 EAGLE RIDGE LN',
        RegAddrCity: 'BUTLER',
        RegAddrState: 'PA',
        RegAddrZip: 16001,
        CongressionalDistrict: 16,
      },
      {
        RegAddrLine1: '604 STONERIDGE BLVD',
        RegAddrCity: 'BUTLER',
        RegAddrState: 'PA',
        RegAddrZip: 16001,
        CongressionalDistrict: 16,
      },
      {
        RegAddrLine1: '16988 STATE HIGHWAY 89',
        RegAddrCity: 'CENTERVILLE',
        RegAddrState: 'PA',
        RegAddrZip: 16404,
        CongressionalDistrict: 16,
      },
      {
        RegAddrLine1: '2592 MALLORY RD',
        RegAddrCity: 'COCHRANTON',
        RegAddrState: 'PA',
        RegAddrZip: 16314,
        CongressionalDistrict: 16,
      },
      {
        RegAddrLine1: '340 MAPLE ST',
        RegAddrCity: 'CONNEAUT LAKE',
        RegAddrState: 'PA',
        RegAddrZip: 16316,
        CongressionalDistrict: 16,
      },
      {
        RegAddrLine1: '168 OAKVIEW DR',
        RegAddrCity: 'CRANBERRY TWP',
        RegAddrState: 'PA',
        RegAddrZip: 16066,
        CongressionalDistrict: 16,
      },
      {
        RegAddrLine1: '123 SOUTHRIDGE DR',
        RegAddrCity: 'CRANBERRY TWP',
        RegAddrState: 'PA',
        RegAddrZip: 16066,
        CongressionalDistrict: 16,
      },
      {
        RegAddrLine1: '7150 ROUTE 6N',
        RegAddrCity: 'EDINBORO',
        RegAddrState: 'PA',
        RegAddrZip: 16412,
        CongressionalDistrict: 16,
      },
      {
        RegAddrLine1: '945 BORDER AVE',
        RegAddrCity: 'ELLWOOD CITY',
        RegAddrState: 'PA',
        RegAddrZip: 16117,
        CongressionalDistrict: 16,
      },
      {
        RegAddrLine1: '256 W 20TH ST',
        RegAddrCity: 'ERIE',
        RegAddrState: 'PA',
        RegAddrZip: 16502,
        CongressionalDistrict: 16,
      },
      {
        RegAddrLine1: '403 E 36TH ST',
        RegAddrCity: 'ERIE',
        RegAddrState: 'PA',
        RegAddrZip: 16504,
        CongressionalDistrict: 16,
      },
      {
        RegAddrLine1: '422 MADISON AVE',
        RegAddrCity: 'ERIE',
        RegAddrState: 'PA',
        RegAddrZip: 16505,
        CongressionalDistrict: 16,
      },
      {
        RegAddrLine1: '3619 CAMEO WAY',
        RegAddrCity: 'ERIE',
        RegAddrState: 'PA',
        RegAddrZip: 16506,
        CongressionalDistrict: 16,
      },
      {
        RegAddrLine1: '3117 ZUCK RD',
        RegAddrCity: 'ERIE',
        RegAddrState: 'PA',
        RegAddrZip: 16506,
        CongressionalDistrict: 16,
      },
      {
        RegAddrLine1: '4463 ASBURY RD',
        RegAddrCity: 'ERIE',
        RegAddrState: 'PA',
        RegAddrZip: 16506,
        CongressionalDistrict: 16,
      },
      {
        RegAddrLine1: '4850 THOROUGHBRED LOOP',
        RegAddrCity: 'ERIE',
        RegAddrState: 'PA',
        RegAddrZip: 16506,
        CongressionalDistrict: 16,
      },
      {
        RegAddrLine1: '5263 KNOYLE RD',
        RegAddrCity: 'ERIE',
        RegAddrState: 'PA',
        RegAddrZip: 16510,
        CongressionalDistrict: 16,
      },
      {
        RegAddrLine1: '2543 E GRANDVIEW BLVD',
        RegAddrCity: 'ERIE',
        RegAddrState: 'PA',
        RegAddrZip: 16510,
        CongressionalDistrict: 16,
      },
      {
        RegAddrLine1: '1021 A TACOMA RD 2',
        RegAddrCity: 'ERIE',
        RegAddrState: 'PA',
        RegAddrZip: 16511,
        CongressionalDistrict: 16,
      },
      {
        RegAddrLine1: '1235 PRIESTLEY AVE',
        RegAddrCity: 'ERIE',
        RegAddrState: 'PA',
        RegAddrZip: 16511,
        CongressionalDistrict: 16,
      },
      {
        RegAddrLine1: '4149 BELL ST',
        RegAddrCity: 'ERIE',
        RegAddrState: 'PA',
        RegAddrZip: 16511,
        CongressionalDistrict: 16,
      },
      {
        RegAddrLine1: '2971 HANNA DR',
        RegAddrCity: 'FAIRVIEW',
        RegAddrState: 'PA',
        RegAddrZip: 16415,
        CongressionalDistrict: 16,
      },
      {
        RegAddrLine1: '3180 FRANCIS DR',
        RegAddrCity: 'FAIRVIEW',
        RegAddrState: 'PA',
        RegAddrZip: 16415,
        CongressionalDistrict: 16,
      },
      {
        RegAddrLine1: '169 2ND ST',
        RegAddrCity: 'FREDONIA',
        RegAddrState: 'PA',
        RegAddrZip: 16124,
        CongressionalDistrict: 16,
      },
      {
        RegAddrLine1: '80 FREDONIA RD',
        RegAddrCity: 'GREENVILLE',
        RegAddrState: 'PA',
        RegAddrZip: 16125,
        CongressionalDistrict: 16,
      },
      {
        RegAddrLine1: '624 FREDONIA HADLEY RD',
        RegAddrCity: 'GREENVILLE',
        RegAddrState: 'PA',
        RegAddrZip: 16125,
        CongressionalDistrict: 16,
      },
      {
        RegAddrLine1: '716 TERRACE AVE',
        RegAddrCity: 'GROVE CITY',
        RegAddrState: 'PA',
        RegAddrZip: 16127,
        CongressionalDistrict: 16,
      },
      {
        RegAddrLine1: '80 KIMBERLY DR',
        RegAddrCity: 'GROVE CITY',
        RegAddrState: 'PA',
        RegAddrZip: 16127,
        CongressionalDistrict: 16,
      },
      {
        RegAddrLine1: '1317 VILLA SITES AVE',
        RegAddrCity: 'HARBORCREEK',
        RegAddrState: 'PA',
        RegAddrZip: 16421,
        CongressionalDistrict: 16,
      },
      {
        RegAddrLine1: '805 BRANDYWINE DR',
        RegAddrCity: 'HERMITAGE',
        RegAddrState: 'PA',
        RegAddrZip: 16148,
        CongressionalDistrict: 16,
      },
      {
        RegAddrLine1: '194 N MAIN ST',
        RegAddrCity: 'MEADVILLE',
        RegAddrState: 'PA',
        RegAddrZip: 16335,
        CongressionalDistrict: 16,
      },
      {
        RegAddrLine1: '2513 RIDGEWOOD CT',
        RegAddrCity: 'NEW CASTLE',
        RegAddrState: 'PA',
        RegAddrZip: 16101,
        CongressionalDistrict: 16,
      },
      {
        RegAddrLine1: '8 SLATE ST',
        RegAddrCity: 'NEW CASTLE',
        RegAddrState: 'PA',
        RegAddrZip: 16101,
        CongressionalDistrict: 16,
      },
      {
        RegAddrLine1: '111 BATTERY B ST',
        RegAddrCity: 'NEW CASTLE',
        RegAddrState: 'PA',
        RegAddrZip: 16102,
        CongressionalDistrict: 16,
      },
      {
        RegAddrLine1: '206 E ELIZABETH ST',
        RegAddrCity: 'NEW CASTLE',
        RegAddrState: 'PA',
        RegAddrZip: 16105,
        CongressionalDistrict: 16,
      },
      {
        RegAddrLine1: '3013 STATE ROUTE 956',
        RegAddrCity: 'NEW CASTLE',
        RegAddrState: 'PA',
        RegAddrZip: 16105,
        CongressionalDistrict: 16,
      },
      {
        RegAddrLine1: '206 DAISY LN',
        RegAddrCity: 'RENFREW',
        RegAddrState: 'PA',
        RegAddrZip: 16053,
        CongressionalDistrict: 16,
      },
      {
        RegAddrLine1: '416 SPENCER AVE',
        RegAddrCity: 'SHARON',
        RegAddrState: 'PA',
        RegAddrZip: 16146,
        CongressionalDistrict: 16,
      },
      {
        RegAddrLine1: '201 OVERLOOK DR',
        RegAddrCity: 'TITUSVILLE',
        RegAddrState: 'PA',
        RegAddrZip: 16354,
        CongressionalDistrict: 16,
      },
      {
        RegAddrLine1: '9541 CONCORD RD',
        RegAddrCity: 'UNION CITY',
        RegAddrState: 'PA',
        RegAddrZip: 16438,
        CongressionalDistrict: 16,
      },
      {
        RegAddrLine1: '14834 OLD WATTSBURG RD',
        RegAddrCity: 'UNION CITY',
        RegAddrState: 'PA',
        RegAddrZip: 16438,
        CongressionalDistrict: 16,
      },
      {
        RegAddrLine1: '2077 STATE ROUTE 956 B',
        RegAddrCity: 'VOLANT',
        RegAddrState: 'PA',
        RegAddrZip: 16156,
        CongressionalDistrict: 16,
      },
      {
        RegAddrLine1: '125 WANSACK RD',
        RegAddrCity: 'W MIDDLESEX',
        RegAddrState: 'PA',
        RegAddrZip: 16159,
        CongressionalDistrict: 16,
      },
      {
        RegAddrLine1: '380 SHARON BEDFORD RD',
        RegAddrCity: 'W MIDDLESEX',
        RegAddrState: 'PA',
        RegAddrZip: 16159,
        CongressionalDistrict: 16,
      },
    ],
  },
  {
    name: 'PA-17',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/PA-17?ref=gh-pages',
    state: 'PA',
    state_and_number: 'PA-17',
    zip: 17901,
    congressionalDistrict: 17,
    addressesInDistrict: [
      {
        RegAddrLine1: '435 CHAPEL RD',
        RegAddrCity: 'ALIQUIPPA',
        RegAddrState: 'PA',
        RegAddrZip: 15001,
        CongressionalDistrict: 17,
      },
      {
        RegAddrLine1: '1621 BOUNDARY ST',
        RegAddrCity: 'ALIQUIPPA',
        RegAddrState: 'PA',
        RegAddrZip: 15001,
        CongressionalDistrict: 17,
      },
      {
        RegAddrLine1: '9800 THREE DEGREE RD',
        RegAddrCity: 'ALLISON PARK',
        RegAddrState: 'PA',
        RegAddrZip: 15101,
        CongressionalDistrict: 17,
      },
      {
        RegAddrLine1: '4238 TIMBERLANE DR',
        RegAddrCity: 'ALLISON PARK',
        RegAddrState: 'PA',
        RegAddrZip: 15101,
        CongressionalDistrict: 17,
      },
      {
        RegAddrLine1: '1932 RED COACH RD',
        RegAddrCity: 'ALLISON PARK',
        RegAddrState: 'PA',
        RegAddrZip: 15101,
        CongressionalDistrict: 17,
      },
      {
        RegAddrLine1: '999 MAPLEWOOD AVE',
        RegAddrCity: 'AMBRIDGE',
        RegAddrState: 'PA',
        RegAddrZip: 15003,
        CongressionalDistrict: 17,
      },
      {
        RegAddrLine1: '1841 BARCLAY HILL RD',
        RegAddrCity: 'BEAVER',
        RegAddrState: 'PA',
        RegAddrZip: 15009,
        CongressionalDistrict: 17,
      },
      {
        RegAddrLine1: '113 SOUTHVUE DR',
        RegAddrCity: 'BEAVER FALLS',
        RegAddrState: 'PA',
        RegAddrZip: 15010,
        CongressionalDistrict: 17,
      },
      {
        RegAddrLine1: '900 3RD AVE',
        RegAddrCity: 'BEAVER FALLS',
        RegAddrState: 'PA',
        RegAddrZip: 15010,
        CongressionalDistrict: 17,
      },
      {
        RegAddrLine1: '1701 3RD AVE',
        RegAddrCity: 'BEAVER FALLS',
        RegAddrState: 'PA',
        RegAddrZip: 15010,
        CongressionalDistrict: 17,
      },
      {
        RegAddrLine1: '634 GORMLEY AVE',
        RegAddrCity: 'CARNEGIE',
        RegAddrState: 'PA',
        RegAddrZip: 15106,
        CongressionalDistrict: 17,
      },
      {
        RegAddrLine1: '473 CANTERBURY CIR',
        RegAddrCity: 'CARNEGIE',
        RegAddrState: 'PA',
        RegAddrZip: 15106,
        CongressionalDistrict: 17,
      },
      {
        RegAddrLine1: '715 FREEPORT RD',
        RegAddrCity: 'CHESWICK',
        RegAddrState: 'PA',
        RegAddrZip: 15024,
        CongressionalDistrict: 17,
      },
      {
        RegAddrLine1: '176 DARLENE DR',
        RegAddrCity: 'CORAOPOLIS',
        RegAddrState: 'PA',
        RegAddrZip: 15108,
        CongressionalDistrict: 17,
      },
      {
        RegAddrLine1: '132 CLIFF MINE RD',
        RegAddrCity: 'CORAOPOLIS',
        RegAddrState: 'PA',
        RegAddrZip: 15108,
        CongressionalDistrict: 17,
      },
      {
        RegAddrLine1: '200 CLIFF MINE RD',
        RegAddrCity: 'CORAOPOLIS',
        RegAddrState: 'PA',
        RegAddrZip: 15108,
        CongressionalDistrict: 17,
      },
      {
        RegAddrLine1: '214 KIRKWOOD DR',
        RegAddrCity: 'CRANBERRY TOWNSHIP',
        RegAddrState: 'PA',
        RegAddrZip: 16066,
        CongressionalDistrict: 17,
      },
      {
        RegAddrLine1: '1501 CRANBERRY PT',
        RegAddrCity: 'CRANBERRY TWP',
        RegAddrState: 'PA',
        RegAddrZip: 16066,
        CongressionalDistrict: 17,
      },
      {
        RegAddrLine1: '112 OPAL CT',
        RegAddrCity: 'CRANBERRY TWP',
        RegAddrState: 'PA',
        RegAddrZip: 16066,
        CongressionalDistrict: 17,
      },
      {
        RegAddrLine1: '509 5TH ST',
        RegAddrCity: 'DARLINGTON',
        RegAddrState: 'PA',
        RegAddrZip: 16115,
        CongressionalDistrict: 17,
      },
      {
        RegAddrLine1: '366 TOMLINSON RUN CHURCH RD',
        RegAddrCity: 'GEORGETOWN',
        RegAddrState: 'PA',
        RegAddrZip: 15043,
        CongressionalDistrict: 17,
      },
      {
        RegAddrLine1: '5932 FARVUE DR',
        RegAddrCity: 'GIBSONIA',
        RegAddrState: 'PA',
        RegAddrZip: 15044,
        CongressionalDistrict: 17,
      },
      {
        RegAddrLine1: '4659 BAKERSTOWN CULMERVILLE RD',
        RegAddrCity: 'GIBSONIA',
        RegAddrState: 'PA',
        RegAddrZip: 15044,
        CongressionalDistrict: 17,
      },
      {
        RegAddrLine1: '109 RACCOON WAY',
        RegAddrCity: 'GIBSONIA',
        RegAddrState: 'PA',
        RegAddrZip: 15044,
        CongressionalDistrict: 17,
      },
      {
        RegAddrLine1: '204 PLEASANT HILL RD',
        RegAddrCity: 'HOOKSTOWN',
        RegAddrState: 'PA',
        RegAddrZip: 15050,
        CongressionalDistrict: 17,
      },
      {
        RegAddrLine1: '213 DUPONT DR',
        RegAddrCity: 'MC DONALD',
        RegAddrState: 'PA',
        RegAddrZip: 15057,
        CongressionalDistrict: 17,
      },
      {
        RegAddrLine1: '64 CALVIN DR',
        RegAddrCity: 'MC KEES ROCKS',
        RegAddrState: 'PA',
        RegAddrZip: 15136,
        CongressionalDistrict: 17,
      },
      {
        RegAddrLine1: '1360 BECHTEL ST',
        RegAddrCity: 'MONACA',
        RegAddrState: 'PA',
        RegAddrZip: 15061,
        CongressionalDistrict: 17,
      },
      {
        RegAddrLine1: '623 FRANKFORT RD',
        RegAddrCity: 'MONACA',
        RegAddrState: 'PA',
        RegAddrZip: 15061,
        CongressionalDistrict: 17,
      },
      {
        RegAddrLine1: '1120 LIBERTY AVE',
        RegAddrCity: 'NATRONA HTS',
        RegAddrState: 'PA',
        RegAddrZip: 15065,
        CongressionalDistrict: 17,
      },
      {
        RegAddrLine1: '869 FAIRWAYS DR',
        RegAddrCity: 'OAKMONT',
        RegAddrState: 'PA',
        RegAddrZip: 15139,
        CongressionalDistrict: 17,
      },
      {
        RegAddrLine1: '234 3RD ST',
        RegAddrCity: 'OAKMONT',
        RegAddrState: 'PA',
        RegAddrZip: 15139,
        CongressionalDistrict: 17,
      },
      {
        RegAddrLine1: '25 BANBURY LN',
        RegAddrCity: 'PITTSBURGH',
        RegAddrState: 'PA',
        RegAddrZip: 15202,
        CongressionalDistrict: 17,
      },
      {
        RegAddrLine1: '420 MAXWELL ST',
        RegAddrCity: 'PITTSBURGH',
        RegAddrState: 'PA',
        RegAddrZip: 15205,
        CongressionalDistrict: 17,
      },
      {
        RegAddrLine1: '2768 MOUNT TROY RD',
        RegAddrCity: 'PITTSBURGH',
        RegAddrState: 'PA',
        RegAddrZip: 15212,
        CongressionalDistrict: 17,
      },
      {
        RegAddrLine1: '1623 SHARPS HILL RD',
        RegAddrCity: 'PITTSBURGH',
        RegAddrState: 'PA',
        RegAddrZip: 15215,
        CongressionalDistrict: 17,
      },
      {
        RegAddrLine1: '903 W WALDHEIM RD',
        RegAddrCity: 'PITTSBURGH',
        RegAddrState: 'PA',
        RegAddrZip: 15215,
        CongressionalDistrict: 17,
      },
      {
        RegAddrLine1: '1423 KEY AVE',
        RegAddrCity: 'PITTSBURGH',
        RegAddrState: 'PA',
        RegAddrZip: 15216,
        CongressionalDistrict: 17,
      },
      {
        RegAddrLine1: '136 BEVERLY RD',
        RegAddrCity: 'PITTSBURGH',
        RegAddrState: 'PA',
        RegAddrZip: 15216,
        CongressionalDistrict: 17,
      },
      {
        RegAddrLine1: '3406 WALLACE DR',
        RegAddrCity: 'PITTSBURGH',
        RegAddrState: 'PA',
        RegAddrZip: 15227,
        CongressionalDistrict: 17,
      },
      {
        RegAddrLine1: '119 HOODRIDGE DR',
        RegAddrCity: 'PITTSBURGH',
        RegAddrState: 'PA',
        RegAddrZip: 15228,
        CongressionalDistrict: 17,
      },
      {
        RegAddrLine1: '305 ORCHARD DR',
        RegAddrCity: 'PITTSBURGH',
        RegAddrState: 'PA',
        RegAddrZip: 15228,
        CongressionalDistrict: 17,
      },
      {
        RegAddrLine1: '305 PIKE ST',
        RegAddrCity: 'PITTSBURGH',
        RegAddrState: 'PA',
        RegAddrZip: 15235,
        CongressionalDistrict: 17,
      },
      {
        RegAddrLine1: '318 THORNBERRY CT',
        RegAddrCity: 'PITTSBURGH',
        RegAddrState: 'PA',
        RegAddrZip: 15237,
        CongressionalDistrict: 17,
      },
      {
        RegAddrLine1: '6108 RIVERFRONT DR',
        RegAddrCity: 'PITTSBURGH',
        RegAddrState: 'PA',
        RegAddrZip: 15238,
        CongressionalDistrict: 17,
      },
      {
        RegAddrLine1: '116 SHADOW RIDGE DR',
        RegAddrCity: 'PITTSBURGH',
        RegAddrState: 'PA',
        RegAddrZip: 15238,
        CongressionalDistrict: 17,
      },
      {
        RegAddrLine1: '1130 LAKEMONT DR',
        RegAddrCity: 'PITTSBURGH',
        RegAddrState: 'PA',
        RegAddrZip: 15243,
        CongressionalDistrict: 17,
      },
      {
        RegAddrLine1: '841 VALLEYVIEW RD',
        RegAddrCity: 'PITTSBURGH',
        RegAddrState: 'PA',
        RegAddrZip: 15243,
        CongressionalDistrict: 17,
      },
      {
        RegAddrLine1: '603 TIMBER LN',
        RegAddrCity: 'SEWICKLEY',
        RegAddrState: 'PA',
        RegAddrZip: 15143,
        CongressionalDistrict: 17,
      },
      {
        RegAddrLine1: '911 LINCOLN AVE',
        RegAddrCity: 'SPRINGDALE',
        RegAddrState: 'PA',
        RegAddrZip: 15144,
        CongressionalDistrict: 17,
      },
      {
        RegAddrLine1: '500 SCHOOL ST',
        RegAddrCity: 'SPRINGDALE',
        RegAddrState: 'PA',
        RegAddrZip: 15144,
        CongressionalDistrict: 17,
      },
      {
        RegAddrLine1: '105 GLENHURST DR',
        RegAddrCity: 'VERONA',
        RegAddrState: 'PA',
        RegAddrZip: 15147,
        CongressionalDistrict: 17,
      },
      {
        RegAddrLine1: '201 HILL RD',
        RegAddrCity: 'WEXFORD',
        RegAddrState: 'PA',
        RegAddrZip: 15090,
        CongressionalDistrict: 17,
      },
      {
        RegAddrLine1: '111 FARMINGDALE RD',
        RegAddrCity: 'WEXFORD',
        RegAddrState: 'PA',
        RegAddrZip: 15090,
        CongressionalDistrict: 17,
      },
    ],
  },
  {
    name: 'PA-18',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/PA-18?ref=gh-pages',
    state: 'PA',
    state_and_number: 'PA-18',
    zip: 15004,
    congressionalDistrict: 18,
    addressesInDistrict: [
      {
        RegAddrLine1: '511 FRENCIK ST',
        RegAddrCity: 'BUENA VISTA',
        RegAddrState: 'PA',
        RegAddrZip: 15018,
        CongressionalDistrict: 18,
      },
      {
        RegAddrLine1: '1404 MARION CIR',
        RegAddrCity: 'CLAIRTON',
        RegAddrState: 'PA',
        RegAddrZip: 15025,
        CongressionalDistrict: 18,
      },
      {
        RegAddrLine1: '21 3RD ST N ST',
        RegAddrCity: 'DUQUESNE',
        RegAddrState: 'PA',
        RegAddrZip: 15110,
        CongressionalDistrict: 18,
      },
      {
        RegAddrLine1: '975 5TH AVE',
        RegAddrCity: 'E MCKEESPORT',
        RegAddrState: 'PA',
        RegAddrZip: 15035,
        CongressionalDistrict: 18,
      },
      {
        RegAddrLine1: '609 DUKE DR',
        RegAddrCity: 'ELIZABETH',
        RegAddrState: 'PA',
        RegAddrZip: 15037,
        CongressionalDistrict: 18,
      },
      {
        RegAddrLine1: '216 OLD CLAIRTON RD',
        RegAddrCity: 'JEFFERSON HILLS',
        RegAddrState: 'PA',
        RegAddrZip: 15025,
        CongressionalDistrict: 18,
      },
      {
        RegAddrLine1: '521 REEDSDALE DR',
        RegAddrCity: 'JEFFERSON HLS',
        RegAddrState: 'PA',
        RegAddrZip: 15025,
        CongressionalDistrict: 18,
      },
      {
        RegAddrLine1: '2715 MOHAWK ST',
        RegAddrCity: 'MCKEESPORT',
        RegAddrState: 'PA',
        RegAddrZip: 15131,
        CongressionalDistrict: 18,
      },
      {
        RegAddrLine1: '515 CHAUNCEY CIR',
        RegAddrCity: 'MCKEESPORT',
        RegAddrState: 'PA',
        RegAddrZip: 15132,
        CongressionalDistrict: 18,
      },
      {
        RegAddrLine1: '709 13TH AVENUE E AVE',
        RegAddrCity: 'MUNHALL',
        RegAddrState: 'PA',
        RegAddrZip: 15120,
        CongressionalDistrict: 18,
      },
      {
        RegAddrLine1: '6713 DEARY ST',
        RegAddrCity: 'PITTSBURGH',
        RegAddrState: 'PA',
        RegAddrZip: 15206,
        CongressionalDistrict: 18,
      },
      {
        RegAddrLine1: '2361 BROWNSVILLE RD',
        RegAddrCity: 'PITTSBURGH',
        RegAddrState: 'PA',
        RegAddrZip: 15210,
        CongressionalDistrict: 18,
      },
      {
        RegAddrLine1: '1208 MARSHALL AVE',
        RegAddrCity: 'PITTSBURGH',
        RegAddrState: 'PA',
        RegAddrZip: 15212,
        CongressionalDistrict: 18,
      },
      {
        RegAddrLine1: '124 HENDERSON ST',
        RegAddrCity: 'PITTSBURGH',
        RegAddrState: 'PA',
        RegAddrZip: 15212,
        CongressionalDistrict: 18,
      },
      {
        RegAddrLine1: '3990 B 5TH AVE 504-2',
        RegAddrCity: 'PITTSBURGH',
        RegAddrState: 'PA',
        RegAddrZip: 15213,
        CongressionalDistrict: 18,
      },
      {
        RegAddrLine1: '5000 FORBES AVE',
        RegAddrCity: 'PITTSBURGH',
        RegAddrState: 'PA',
        RegAddrZip: 15213,
        CongressionalDistrict: 18,
      },
      {
        RegAddrLine1: '3782 EAST ST',
        RegAddrCity: 'PITTSBURGH',
        RegAddrState: 'PA',
        RegAddrZip: 15214,
        CongressionalDistrict: 18,
      },
      {
        RegAddrLine1: '220 LURAY ST',
        RegAddrCity: 'PITTSBURGH',
        RegAddrState: 'PA',
        RegAddrZip: 15214,
        CongressionalDistrict: 18,
      },
      {
        RegAddrLine1: '224 LONGMORE AVE',
        RegAddrCity: 'PITTSBURGH',
        RegAddrState: 'PA',
        RegAddrZip: 15216,
        CongressionalDistrict: 18,
      },
      {
        RegAddrLine1: '5627 FORBES AVE',
        RegAddrCity: 'PITTSBURGH',
        RegAddrState: 'PA',
        RegAddrZip: 15217,
        CongressionalDistrict: 18,
      },
      {
        RegAddrLine1: '5307 BEELER ST',
        RegAddrCity: 'PITTSBURGH',
        RegAddrState: 'PA',
        RegAddrZip: 15217,
        CongressionalDistrict: 18,
      },
      {
        RegAddrLine1: '1133 RICHMOND ST',
        RegAddrCity: 'PITTSBURGH',
        RegAddrState: 'PA',
        RegAddrZip: 15218,
        CongressionalDistrict: 18,
      },
      {
        RegAddrLine1: '212 BRADDOCK RD',
        RegAddrCity: 'PITTSBURGH',
        RegAddrState: 'PA',
        RegAddrZip: 15221,
        CongressionalDistrict: 18,
      },
      {
        RegAddrLine1: '1807 MONTIER ST',
        RegAddrCity: 'PITTSBURGH',
        RegAddrState: 'PA',
        RegAddrZip: 15221,
        CongressionalDistrict: 18,
      },
      {
        RegAddrLine1: '4206 MILGATE ST',
        RegAddrCity: 'PITTSBURGH',
        RegAddrState: 'PA',
        RegAddrZip: 15224,
        CongressionalDistrict: 18,
      },
      {
        RegAddrLine1: '210 GROSS ST',
        RegAddrCity: 'PITTSBURGH',
        RegAddrState: 'PA',
        RegAddrZip: 15224,
        CongressionalDistrict: 18,
      },
      {
        RegAddrLine1: '1587 REAMER ST',
        RegAddrCity: 'PITTSBURGH',
        RegAddrState: 'PA',
        RegAddrZip: 15226,
        CongressionalDistrict: 18,
      },
      {
        RegAddrLine1: '15 ODETTE ST',
        RegAddrCity: 'PITTSBURGH',
        RegAddrState: 'PA',
        RegAddrZip: 15227,
        CongressionalDistrict: 18,
      },
      {
        RegAddrLine1: '366 GRAHAM ST S ST',
        RegAddrCity: 'PITTSBURGH',
        RegAddrState: 'PA',
        RegAddrZip: 15232,
        CongressionalDistrict: 18,
      },
      {
        RegAddrLine1: '5812 KENTUCKY AVE',
        RegAddrCity: 'PITTSBURGH',
        RegAddrState: 'PA',
        RegAddrZip: 15232,
        CongressionalDistrict: 18,
      },
      {
        RegAddrLine1: '346 MILLET LN',
        RegAddrCity: 'PITTSBURGH',
        RegAddrState: 'PA',
        RegAddrZip: 15236,
        CongressionalDistrict: 18,
      },
      {
        RegAddrLine1: '213 CRESTVIEW DR',
        RegAddrCity: 'PITTSBURGH',
        RegAddrState: 'PA',
        RegAddrZip: 15236,
        CongressionalDistrict: 18,
      },
      {
        RegAddrLine1: '5083 RAINTREE DR',
        RegAddrCity: 'PITTSBURGH',
        RegAddrState: 'PA',
        RegAddrZip: 15236,
        CongressionalDistrict: 18,
      },
      {
        RegAddrLine1: '347 MACASSAR DR',
        RegAddrCity: 'PITTSBURGH',
        RegAddrState: 'PA',
        RegAddrZip: 15236,
        CongressionalDistrict: 18,
      },
      {
        RegAddrLine1: '743 PINE VALLEY DR',
        RegAddrCity: 'PITTSBURGH',
        RegAddrState: 'PA',
        RegAddrZip: 15239,
        CongressionalDistrict: 18,
      },
      {
        RegAddrLine1: '633 CARNIVAL DR',
        RegAddrCity: 'PITTSBURGH',
        RegAddrState: 'PA',
        RegAddrZip: 15239,
        CongressionalDistrict: 18,
      },
      {
        RegAddrLine1: '513 REGENCY DR',
        RegAddrCity: 'PITTSBURGH',
        RegAddrState: 'PA',
        RegAddrZip: 15239,
        CongressionalDistrict: 18,
      },
      {
        RegAddrLine1: '2441 CORTELAND DR',
        RegAddrCity: 'PITTSBURGH',
        RegAddrState: 'PA',
        RegAddrZip: 15241,
        CongressionalDistrict: 18,
      },
    ],
  },
  {
    name: 'PA-2',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/PA-2?ref=gh-pages',
    state: 'PA',
    state_and_number: 'PA-2',
    zip: 19003,
    congressionalDistrict: 2,
    addressesInDistrict: [
      {
        RegAddrLine1: '1165 HELLERMAN ST',
        RegAddrCity: 'PHILADELPHIA',
        RegAddrState: 'PA',
        RegAddrZip: 19111,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1832 GRIFFITH ST',
        RegAddrCity: 'PHILADELPHIA',
        RegAddrState: 'PA',
        RegAddrZip: 19111,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '6203 DELAIRE LANDING RD',
        RegAddrCity: 'PHILADELPHIA',
        RegAddrState: 'PA',
        RegAddrZip: 19114,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '3839 KIRKWOOD RD',
        RegAddrCity: 'PHILADELPHIA',
        RegAddrState: 'PA',
        RegAddrZip: 19114,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '3347 S KESWICK RD',
        RegAddrCity: 'PHILADELPHIA',
        RegAddrState: 'PA',
        RegAddrZip: 19114,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '71107 DELAIRE LANDING RD',
        RegAddrCity: 'PHILADELPHIA',
        RegAddrState: 'PA',
        RegAddrZip: 19114,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '39303 DELAIRE LANDING RD',
        RegAddrCity: 'PHILADELPHIA',
        RegAddrState: 'PA',
        RegAddrZip: 19114,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '14010 TREVOSE RD',
        RegAddrCity: 'PHILADELPHIA',
        RegAddrState: 'PA',
        RegAddrZip: 19116,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '535 E SANGER ST',
        RegAddrCity: 'PHILADELPHIA',
        RegAddrState: 'PA',
        RegAddrZip: 19120,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '233 W RUSCOMB ST',
        RegAddrCity: 'PHILADELPHIA',
        RegAddrState: 'PA',
        RegAddrZip: 19120,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '5505 N FAIRHILL ST',
        RegAddrCity: 'PHILADELPHIA',
        RegAddrState: 'PA',
        RegAddrZip: 19120,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '601 E ANNSBURY ST',
        RegAddrCity: 'PHILADELPHIA',
        RegAddrState: 'PA',
        RegAddrZip: 19120,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '5618 ORMES ST',
        RegAddrCity: 'PHILADELPHIA',
        RegAddrState: 'PA',
        RegAddrZip: 19120,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '136 COVINGTON RD',
        RegAddrCity: 'PHILADELPHIA',
        RegAddrState: 'PA',
        RegAddrZip: 19120,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '5202 N 08TH ST',
        RegAddrCity: 'PHILADELPHIA',
        RegAddrState: 'PA',
        RegAddrZip: 19120,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1715 N FRANKLIN ST',
        RegAddrCity: 'PHILADELPHIA',
        RegAddrState: 'PA',
        RegAddrZip: 19122,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '913 N 12TH ST',
        RegAddrCity: 'PHILADELPHIA',
        RegAddrState: 'PA',
        RegAddrZip: 19123,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '5221 MARLOW ST',
        RegAddrCity: 'PHILADELPHIA',
        RegAddrState: 'PA',
        RegAddrZip: 19124,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2532 N WATER ST',
        RegAddrCity: 'PHILADELPHIA',
        RegAddrState: 'PA',
        RegAddrZip: 19125,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2608 E CUMBERLAND ST',
        RegAddrCity: 'PHILADELPHIA',
        RegAddrState: 'PA',
        RegAddrZip: 19125,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '6600 N 03RD ST',
        RegAddrCity: 'PHILADELPHIA',
        RegAddrState: 'PA',
        RegAddrZip: 19126,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2233 N HANCOCK ST',
        RegAddrCity: 'PHILADELPHIA',
        RegAddrState: 'PA',
        RegAddrZip: 19133,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2743 N 11TH ST',
        RegAddrCity: 'PHILADELPHIA',
        RegAddrState: 'PA',
        RegAddrZip: 19133,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2711 E ANN ST',
        RegAddrCity: 'PHILADELPHIA',
        RegAddrState: 'PA',
        RegAddrZip: 19134,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '3317 KENSINGTON AVE',
        RegAddrCity: 'PHILADELPHIA',
        RegAddrState: 'PA',
        RegAddrZip: 19134,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2818 B ST',
        RegAddrCity: 'PHILADELPHIA',
        RegAddrState: 'PA',
        RegAddrZip: 19134,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2006 E ORLEANS ST',
        RegAddrCity: 'PHILADELPHIA',
        RegAddrState: 'PA',
        RegAddrZip: 19134,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '3454 JOYCE ST',
        RegAddrCity: 'PHILADELPHIA',
        RegAddrState: 'PA',
        RegAddrZip: 19134,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2952 RORER ST',
        RegAddrCity: 'PHILADELPHIA',
        RegAddrState: 'PA',
        RegAddrZip: 19134,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '6541 TULIP ST',
        RegAddrCity: 'PHILADELPHIA',
        RegAddrState: 'PA',
        RegAddrZip: 19135,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '7228 MONTAGUE ST',
        RegAddrCity: 'PHILADELPHIA',
        RegAddrState: 'PA',
        RegAddrZip: 19135,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '3754 N FRANKLIN ST',
        RegAddrCity: 'PHILADELPHIA',
        RegAddrState: 'PA',
        RegAddrZip: 19140,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '3916 N 13TH ST',
        RegAddrCity: 'PHILADELPHIA',
        RegAddrState: 'PA',
        RegAddrZip: 19140,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '3922 N MARSHALL ST',
        RegAddrCity: 'PHILADELPHIA',
        RegAddrState: 'PA',
        RegAddrZip: 19140,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '3229 FANSHAWE ST',
        RegAddrCity: 'PHILADELPHIA',
        RegAddrState: 'PA',
        RegAddrZip: 19149,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2936 TYSON AVE',
        RegAddrCity: 'PHILADELPHIA',
        RegAddrState: 'PA',
        RegAddrZip: 19149,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '6033 LARGE ST',
        RegAddrCity: 'PHILADELPHIA',
        RegAddrState: 'PA',
        RegAddrZip: 19149,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1828 TUSTIN ST',
        RegAddrCity: 'PHILADELPHIA',
        RegAddrState: 'PA',
        RegAddrZip: 19152,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '8847 CALVERT ST',
        RegAddrCity: 'PHILADELPHIA',
        RegAddrState: 'PA',
        RegAddrZip: 19152,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '8813 BROCKLEHURST ST',
        RegAddrCity: 'PHILADELPHIA',
        RegAddrState: 'PA',
        RegAddrZip: 19152,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '7341 ROOSEVELT BLVD',
        RegAddrCity: 'PHILADELPHIA',
        RegAddrState: 'PA',
        RegAddrZip: 19152,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '218 PARKVIEW RD',
        RegAddrCity: 'PHILADELPHIA',
        RegAddrState: 'PA',
        RegAddrZip: 19154,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2024 RIVERSIDE DR',
        RegAddrCity: 'PHILADELPHIA',
        RegAddrState: 'PA',
        RegAddrZip: 19154,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '10806 PEDRICK RD',
        RegAddrCity: 'PHILADELPHIA',
        RegAddrState: 'PA',
        RegAddrZip: 19154,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '3233 TARA RD',
        RegAddrCity: 'PHILADELPHIA',
        RegAddrState: 'PA',
        RegAddrZip: 19154,
        CongressionalDistrict: 2,
      },
    ],
  },
  {
    name: 'PA-3',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/PA-3?ref=gh-pages',
    state: 'PA',
    state_and_number: 'PA-3',
    zip: 15044,
    congressionalDistrict: 3,
    addressesInDistrict: [
      {
        RegAddrLine1: '124 N MOLE ST',
        RegAddrCity: 'PHILADELPHIA',
        RegAddrState: 'PA',
        RegAddrZip: 19102,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '3820 LOCUST WALK',
        RegAddrCity: 'PHILADELPHIA',
        RegAddrState: 'PA',
        RegAddrZip: 19104,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '4400 W GIRARD AVE',
        RegAddrCity: 'PHILADELPHIA',
        RegAddrState: 'PA',
        RegAddrZip: 19104,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '527 N 38TH ST',
        RegAddrCity: 'PHILADELPHIA',
        RegAddrState: 'PA',
        RegAddrZip: 19104,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '4008 PINE ST',
        RegAddrCity: 'PHILADELPHIA',
        RegAddrState: 'PA',
        RegAddrZip: 19104,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '141 N 02ND ST 3E',
        RegAddrCity: 'PHILADELPHIA',
        RegAddrState: 'PA',
        RegAddrZip: 19106,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '6400 GREENE ST',
        RegAddrCity: 'PHILADELPHIA',
        RegAddrState: 'PA',
        RegAddrZip: 19119,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '2704 CECIL B MOORE AVE',
        RegAddrCity: 'PHILADELPHIA',
        RegAddrState: 'PA',
        RegAddrZip: 19121,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '2115 W SEYBERT ST',
        RegAddrCity: 'PHILADELPHIA',
        RegAddrState: 'PA',
        RegAddrZip: 19121,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '7023 MATTHIAS ST',
        RegAddrCity: 'PHILADELPHIA',
        RegAddrState: 'PA',
        RegAddrZip: 19128,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '3412 OSMOND ST',
        RegAddrCity: 'PHILADELPHIA',
        RegAddrState: 'PA',
        RegAddrZip: 19129,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '3658 HAYWOOD ST',
        RegAddrCity: 'PHILADELPHIA',
        RegAddrState: 'PA',
        RegAddrZip: 19129,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '3213 N SPANGLER ST',
        RegAddrCity: 'PHILADELPHIA',
        RegAddrState: 'PA',
        RegAddrZip: 19129,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '2228 MOUNT VERNON ST',
        RegAddrCity: 'PHILADELPHIA',
        RegAddrState: 'PA',
        RegAddrZip: 19130,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1314 N FRAZIER ST',
        RegAddrCity: 'PHILADELPHIA',
        RegAddrState: 'PA',
        RegAddrZip: 19131,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '2459 N CORLIES ST',
        RegAddrCity: 'PHILADELPHIA',
        RegAddrState: 'PA',
        RegAddrZip: 19132,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '6413 N 20TH ST',
        RegAddrCity: 'PHILADELPHIA',
        RegAddrState: 'PA',
        RegAddrZip: 19138,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1528 E DUVAL ST',
        RegAddrCity: 'PHILADELPHIA',
        RegAddrState: 'PA',
        RegAddrZip: 19138,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '5732 SANSOM ST',
        RegAddrCity: 'PHILADELPHIA',
        RegAddrState: 'PA',
        RegAddrZip: 19139,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '4526 N 19TH ST',
        RegAddrCity: 'PHILADELPHIA',
        RegAddrState: 'PA',
        RegAddrZip: 19140,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '5820 BALTIMORE AVE',
        RegAddrCity: 'PHILADELPHIA',
        RegAddrState: 'PA',
        RegAddrZip: 19143,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '437 S 57TH ST',
        RegAddrCity: 'PHILADELPHIA',
        RegAddrState: 'PA',
        RegAddrZip: 19143,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '718 S 49TH ST',
        RegAddrCity: 'PHILADELPHIA',
        RegAddrState: 'PA',
        RegAddrZip: 19143,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '240 W JOHNSON ST',
        RegAddrCity: 'PHILADELPHIA',
        RegAddrState: 'PA',
        RegAddrZip: 19144,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '445 W ABBOTTSFORD AVE',
        RegAddrCity: 'PHILADELPHIA',
        RegAddrState: 'PA',
        RegAddrZip: 19144,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '5930 PULASKI AVE',
        RegAddrCity: 'PHILADELPHIA',
        RegAddrState: 'PA',
        RegAddrZip: 19144,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '2122 MORRIS ST',
        RegAddrCity: 'PHILADELPHIA',
        RegAddrState: 'PA',
        RegAddrZip: 19145,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1012 S 18TH ST',
        RegAddrCity: 'PHILADELPHIA',
        RegAddrState: 'PA',
        RegAddrZip: 19146,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1904 REED ST',
        RegAddrCity: 'PHILADELPHIA',
        RegAddrState: 'PA',
        RegAddrZip: 19146,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '2120 WHARTON ST',
        RegAddrCity: 'PHILADELPHIA',
        RegAddrState: 'PA',
        RegAddrZip: 19146,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '136 PEMBERTON ST',
        RegAddrCity: 'PHILADELPHIA',
        RegAddrState: 'PA',
        RegAddrZip: 19147,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1136 TITAN ST',
        RegAddrCity: 'PHILADELPHIA',
        RegAddrState: 'PA',
        RegAddrZip: 19147,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '422 DICKINSON ST',
        RegAddrCity: 'PHILADELPHIA',
        RegAddrState: 'PA',
        RegAddrZip: 19147,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1423 S 09TH ST',
        RegAddrCity: 'PHILADELPHIA',
        RegAddrState: 'PA',
        RegAddrZip: 19147,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1155 E PHIL ELLENA ST',
        RegAddrCity: 'PHILADELPHIA',
        RegAddrState: 'PA',
        RegAddrZip: 19150,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '6606 HAVERFORD AVE',
        RegAddrCity: 'PHILADELPHIA',
        RegAddrState: 'PA',
        RegAddrZip: 19151,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1642 N 60TH ST',
        RegAddrCity: 'PHILADELPHIA',
        RegAddrState: 'PA',
        RegAddrZip: 19151,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '7511 BRENTWOOD RD',
        RegAddrCity: 'PHILADELPHIA',
        RegAddrState: 'PA',
        RegAddrZip: 19151,
        CongressionalDistrict: 3,
      },
    ],
  },
  {
    name: 'PA-4',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/PA-4?ref=gh-pages',
    state: 'PA',
    state_and_number: 'PA-4',
    zip: 17011,
    congressionalDistrict: 4,
    addressesInDistrict: [
      {
        RegAddrLine1: '1008 WELSH ROAD W RD',
        RegAddrCity: 'AMBLER',
        RegAddrState: 'PA',
        RegAddrZip: 19002,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2812 SANDPIPER DR',
        RegAddrCity: 'AUDUBON',
        RegAddrState: 'PA',
        RegAddrZip: 19403,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '710 CATHCART RD',
        RegAddrCity: 'BLUE BELL',
        RegAddrState: 'PA',
        RegAddrZip: 19422,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '7818 OAK LANE RD',
        RegAddrCity: 'CHELTENHAM',
        RegAddrState: 'PA',
        RegAddrZip: 19012,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '3847 YERKES RD',
        RegAddrCity: 'COLLEGEVILLE',
        RegAddrState: 'PA',
        RegAddrZip: 19426,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '118 WESTIN RD',
        RegAddrCity: 'DOUGLASSVILLE',
        RegAddrState: 'PA',
        RegAddrZip: 19518,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '8308 FAIRVIEW RD',
        RegAddrCity: 'ELKINS PARK',
        RegAddrState: 'PA',
        RegAddrZip: 19027,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2114 SWAMP PIKE',
        RegAddrCity: 'GILBERTSVILLE',
        RegAddrState: 'PA',
        RegAddrZip: 19525,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '218 EASTON ROAD N RD F11',
        RegAddrCity: 'GLENSIDE',
        RegAddrState: 'PA',
        RegAddrZip: 19038,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '534 ABINGTON AVE',
        RegAddrCity: 'GLENSIDE',
        RegAddrState: 'PA',
        RegAddrZip: 19038,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '700 N HILLS AVE',
        RegAddrCity: 'GLENSIDE',
        RegAddrState: 'PA',
        RegAddrZip: 19038,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2044 FORT BEVON RD',
        RegAddrCity: 'HARLEYSVILLE',
        RegAddrState: 'PA',
        RegAddrZip: 19438,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2621 BURTON RD',
        RegAddrCity: 'HARLEYSVILLE',
        RegAddrState: 'PA',
        RegAddrZip: 19438,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '213 MONTGOMERY AVENUE E AVE',
        RegAddrCity: 'HATBORO',
        RegAddrState: 'PA',
        RegAddrZip: 19040,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '204 FITCH RD',
        RegAddrCity: 'HATBORO',
        RegAddrState: 'PA',
        RegAddrZip: 19040,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '115 PARKWAY RD',
        RegAddrCity: 'HATBORO',
        RegAddrState: 'PA',
        RegAddrZip: 19040,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '420 YORK ROAD S RD A-120',
        RegAddrCity: 'HATBORO',
        RegAddrState: 'PA',
        RegAddrZip: 19040,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '5 LATCHSTRING LN',
        RegAddrCity: 'HATBORO',
        RegAddrState: 'PA',
        RegAddrZip: 19040,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '401 ABBOTT RD',
        RegAddrCity: 'HATBORO',
        RegAddrState: 'PA',
        RegAddrZip: 19040,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2749 COWPATH RD',
        RegAddrCity: 'HATFIELD',
        RegAddrState: 'PA',
        RegAddrZip: 19440,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1966 HERITAGE RD',
        RegAddrCity: 'HUNTINGDON VY',
        RegAddrState: 'PA',
        RegAddrZip: 19006,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1616 HUNTINGDON PIKE',
        RegAddrCity: 'JENKINTOWN',
        RegAddrState: 'PA',
        RegAddrZip: 19046,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '409 CEDAR ST',
        RegAddrCity: 'JENKINTOWN',
        RegAddrState: 'PA',
        RegAddrZip: 19046,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '472 GLENN ROSE CIR',
        RegAddrCity: 'KING OF PRUSSIA',
        RegAddrState: 'PA',
        RegAddrZip: 19406,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2002 JOSHUA RD',
        RegAddrCity: 'LAFAYETTE HILL',
        RegAddrState: 'PA',
        RegAddrZip: 19444,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '4053 HILLSIDE RD',
        RegAddrCity: 'LAFAYETTE HL',
        RegAddrState: 'PA',
        RegAddrZip: 19444,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1001 STERIGERE ST',
        RegAddrCity: 'NORRISTOWN',
        RegAddrState: 'PA',
        RegAddrZip: 19401,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '416 LOGAN ST W ST B11',
        RegAddrCity: 'NORRISTOWN',
        RegAddrState: 'PA',
        RegAddrZip: 19401,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1229 W MARSHALL ST',
        RegAddrCity: 'NORRISTOWN',
        RegAddrState: 'PA',
        RegAddrZip: 19401,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '3002 BRAMBLING LN',
        RegAddrCity: 'NORRISTOWN',
        RegAddrState: 'PA',
        RegAddrZip: 19403,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '708 WOODLAND AVE',
        RegAddrCity: 'NORRISTOWN',
        RegAddrState: 'PA',
        RegAddrZip: 19403,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '6 KEARNEY DR',
        RegAddrCity: 'NORTH WALES',
        RegAddrState: 'PA',
        RegAddrZip: 19454,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '624 JENKINS LN',
        RegAddrCity: 'NORTH WALES',
        RegAddrState: 'PA',
        RegAddrZip: 19454,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2110 BRIDLE LN',
        RegAddrCity: 'ORELAND',
        RegAddrState: 'PA',
        RegAddrZip: 19075,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2288 BUCK ROAD E RD',
        RegAddrCity: 'PENNSBURG',
        RegAddrState: 'PA',
        RegAddrZip: 18073,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '3147 COLONY LN',
        RegAddrCity: 'PLYMOUTH MTNG',
        RegAddrState: 'PA',
        RegAddrZip: 19462,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2468 SCHAFFER RD',
        RegAddrCity: 'POTTSTOWN',
        RegAddrState: 'PA',
        RegAddrZip: 19464,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1693 BRYNNE LN',
        RegAddrCity: 'POTTSTOWN',
        RegAddrState: 'PA',
        RegAddrZip: 19464,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '215 W 8TH ST',
        RegAddrCity: 'RED HILL',
        RegAddrState: 'PA',
        RegAddrZip: 18076,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '322 LAUREL DR',
        RegAddrCity: 'ROYERSFORD',
        RegAddrState: 'PA',
        RegAddrZip: 19468,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '118 ABBEY DR',
        RegAddrCity: 'ROYERSFORD',
        RegAddrState: 'PA',
        RegAddrZip: 19468,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '11 WHITE CROW CIR',
        RegAddrCity: 'ROYERSFORD',
        RegAddrState: 'PA',
        RegAddrZip: 19468,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '24 MENG RD',
        RegAddrCity: 'SCHWENKSVILLE',
        RegAddrState: 'PA',
        RegAddrZip: 19473,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1138 BRIDGE RD',
        RegAddrCity: 'SCHWENKSVILLE',
        RegAddrState: 'PA',
        RegAddrZip: 19473,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '301 GREEN HILL RD',
        RegAddrCity: 'TELFORD',
        RegAddrState: 'PA',
        RegAddrZip: 18969,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '156 HARVARD DR',
        RegAddrCity: 'TRAPPE',
        RegAddrState: 'PA',
        RegAddrZip: 19426,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '240 BARKER RD',
        RegAddrCity: 'WYNCOTE',
        RegAddrState: 'PA',
        RegAddrZip: 19095,
        CongressionalDistrict: 4,
      },
    ],
  },
  {
    name: 'PA-5',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/PA-5?ref=gh-pages',
    state: 'PA',
    state_and_number: 'PA-5',
    zip: 15711,
    congressionalDistrict: 5,
    addressesInDistrict: [
      {
        RegAddrLine1: '317 ALDAN AVE',
        RegAddrCity: 'ALDAN',
        RegAddrState: 'PA',
        RegAddrZip: 19018,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '133 WHITEMARSH RD',
        RegAddrCity: 'ARDMORE',
        RegAddrState: 'PA',
        RegAddrZip: 19003,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '3027 FRANCISCAN WAY',
        RegAddrCity: 'ASTON',
        RegAddrState: 'PA',
        RegAddrZip: 19014,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '4206 UPLAND DR',
        RegAddrCity: 'BOOTHWYN',
        RegAddrState: 'PA',
        RegAddrZip: 19061,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '150 E CHELTON RD',
        RegAddrCity: 'BROOKHAVEN',
        RegAddrState: 'PA',
        RegAddrZip: 19015,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '865 PENN ST',
        RegAddrCity: 'BRYN MAWR',
        RegAddrState: 'PA',
        RegAddrZip: 19010,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '208 W 15TH ST',
        RegAddrCity: 'CHESTER',
        RegAddrState: 'PA',
        RegAddrZip: 19013,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '1030 PARKER ST',
        RegAddrCity: 'CHESTER',
        RegAddrState: 'PA',
        RegAddrZip: 19013,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '1013 KERLIN ST',
        RegAddrCity: 'CHESTER',
        RegAddrState: 'PA',
        RegAddrZip: 19013,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: 'PO BOX 1',
        RegAddrCity: 'CHEYNEY',
        RegAddrState: 'PA',
        RegAddrZip: 19319,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '143 HANSEN TER',
        RegAddrCity: 'COLLINGDALE',
        RegAddrState: 'PA',
        RegAddrZip: 19023,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '458 CHILDS AVE',
        RegAddrCity: 'DREXEL HILL',
        RegAddrState: 'PA',
        RegAddrZip: 19026,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '153 WILDE AVE',
        RegAddrCity: 'DREXEL HILL',
        RegAddrState: 'PA',
        RegAddrZip: 19026,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '948 FAIRFAX RD',
        RegAddrCity: 'DREXEL HILL',
        RegAddrState: 'PA',
        RegAddrZip: 19026,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '1217 DREXEL AVE',
        RegAddrCity: 'DREXEL HILL',
        RegAddrState: 'PA',
        RegAddrZip: 19026,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '228 PRINTZ AVE',
        RegAddrCity: 'ESSINGTON',
        RegAddrState: 'PA',
        RegAddrZip: 19029,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '1905 CARTER RD',
        RegAddrCity: 'FOLCROFT',
        RegAddrState: 'PA',
        RegAddrZip: 19032,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '320 GORSUCH ST',
        RegAddrCity: 'FOLSOM',
        RegAddrState: 'PA',
        RegAddrZip: 19033,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '10 HENLEY DR',
        RegAddrCity: 'GLEN MILLS',
        RegAddrState: 'PA',
        RegAddrZip: 19342,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '9 HUNTINGDON FARM DR',
        RegAddrCity: 'GLEN MILLS',
        RegAddrState: 'PA',
        RegAddrZip: 19342,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '205 MERRYBROOK DR',
        RegAddrCity: 'HAVERTOWN',
        RegAddrState: 'PA',
        RegAddrZip: 19083,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '320 HOLMES RD',
        RegAddrCity: 'HOLMES',
        RegAddrState: 'PA',
        RegAddrZip: 19043,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '521 WILLIAMSON CIR',
        RegAddrCity: 'MEDIA',
        RegAddrState: 'PA',
        RegAddrZip: 19063,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '22 TOFT WOODS WAY',
        RegAddrCity: 'MEDIA',
        RegAddrState: 'PA',
        RegAddrZip: 19063,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '308 PARK AVE',
        RegAddrCity: 'NORWOOD',
        RegAddrState: 'PA',
        RegAddrZip: 19074,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '572 MONASTERY AVE',
        RegAddrCity: 'PHILADELPHIA',
        RegAddrState: 'PA',
        RegAddrZip: 19128,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '6535 GUYER AVE',
        RegAddrCity: 'PHILADELPHIA',
        RegAddrState: 'PA',
        RegAddrZip: 19142,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '6714 EGRET PL',
        RegAddrCity: 'PHILADELPHIA',
        RegAddrState: 'PA',
        RegAddrZip: 19142,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '1822 DALY ST',
        RegAddrCity: 'PHILADELPHIA',
        RegAddrState: 'PA',
        RegAddrZip: 19145,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '3120 S 13TH ST',
        RegAddrCity: 'PHILADELPHIA',
        RegAddrState: 'PA',
        RegAddrZip: 19148,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '917 MCKEAN ST',
        RegAddrCity: 'PHILADELPHIA',
        RegAddrState: 'PA',
        RegAddrZip: 19148,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '433 OAK AVE',
        RegAddrCity: 'SHARON HILL',
        RegAddrState: 'PA',
        RegAddrZip: 19079,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '449 MAPLEWOOD RD',
        RegAddrCity: 'SPRINGFIELD',
        RegAddrState: 'PA',
        RegAddrZip: 19064,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '1015 LINCOLN AVE',
        RegAddrCity: 'SPRINGFIELD',
        RegAddrState: 'PA',
        RegAddrZip: 19064,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '114 RAMBLING WAY',
        RegAddrCity: 'SPRINGFIELD',
        RegAddrState: 'PA',
        RegAddrZip: 19064,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '212 ROSEMONT LN',
        RegAddrCity: 'SWARTHMORE',
        RegAddrState: 'PA',
        RegAddrZip: 19081,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '306 BAYARD RD',
        RegAddrCity: 'UPPER DARBY',
        RegAddrState: 'PA',
        RegAddrZip: 19082,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '315 HAMPDEN RD',
        RegAddrCity: 'UPPER DARBY',
        RegAddrState: 'PA',
        RegAddrZip: 19082,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '4361 TROPHY DR',
        RegAddrCity: 'UPPR CHICHSTR',
        RegAddrState: 'PA',
        RegAddrZip: 19061,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '9 GREEN VALLEY RD',
        RegAddrCity: 'WALLINGFORD',
        RegAddrState: 'PA',
        RegAddrZip: 19086,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '58 OAKFORD RD',
        RegAddrCity: 'WAYNE',
        RegAddrState: 'PA',
        RegAddrZip: 19087,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '127 TYLER AVE',
        RegAddrCity: 'WOODLYN',
        RegAddrState: 'PA',
        RegAddrZip: 19094,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '350 MORRIS AVE',
        RegAddrCity: 'WOODLYN',
        RegAddrState: 'PA',
        RegAddrZip: 19094,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '236 ROCK GLEN RD',
        RegAddrCity: 'WYNNEWOOD',
        RegAddrState: 'PA',
        RegAddrZip: 19096,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '1343 INDIAN CREEK DR',
        RegAddrCity: 'WYNNEWOOD',
        RegAddrState: 'PA',
        RegAddrZip: 19096,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '537 FERN ST',
        RegAddrCity: 'YEADON',
        RegAddrState: 'PA',
        RegAddrZip: 19050,
        CongressionalDistrict: 5,
      },
    ],
  },
  {
    name: 'PA-6',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/PA-6?ref=gh-pages',
    state: 'PA',
    state_and_number: 'PA-6',
    zip: 17016,
    congressionalDistrict: 6,
    addressesInDistrict: [
      {
        RegAddrLine1: '367 LINDSEY DR',
        RegAddrCity: 'BERWYN',
        RegAddrState: 'PA',
        RegAddrZip: 19312,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '50 BARRINGTON LN',
        RegAddrCity: 'CHESTER SPRGS',
        RegAddrState: 'PA',
        RegAddrZip: 19425,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '151 LOOMIS AVE',
        RegAddrCity: 'COATESVILLE',
        RegAddrState: 'PA',
        RegAddrZip: 19320,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '734 OLD WILMINGTON RD',
        RegAddrCity: 'COATESVILLE',
        RegAddrState: 'PA',
        RegAddrZip: 19320,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '351 RANDALL LN',
        RegAddrCity: 'COATESVILLE',
        RegAddrState: 'PA',
        RegAddrZip: 19320,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '127 GLENROSE RD',
        RegAddrCity: 'COATESVILLE',
        RegAddrState: 'PA',
        RegAddrZip: 19320,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '19 OLD FORGE XING',
        RegAddrCity: 'DEVON',
        RegAddrState: 'PA',
        RegAddrZip: 19333,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '268 PARK DR',
        RegAddrCity: 'DOWNINGTOWN',
        RegAddrState: 'PA',
        RegAddrZip: 19335,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '1530 ELK RUN RD',
        RegAddrCity: 'DOWNINGTOWN',
        RegAddrState: 'PA',
        RegAddrZip: 19335,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '223 ROOSEVELT AVE',
        RegAddrCity: 'DOWNINGTOWN',
        RegAddrState: 'PA',
        RegAddrZip: 19335,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '122 CRUMP RD',
        RegAddrCity: 'EXTON',
        RegAddrState: 'PA',
        RegAddrZip: 19341,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '135 CAERNARVON CT',
        RegAddrCity: 'EXTON',
        RegAddrState: 'PA',
        RegAddrZip: 19341,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '875 BIRDELL RD',
        RegAddrCity: 'HONEY BROOK',
        RegAddrState: 'PA',
        RegAddrZip: 19344,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '804 POTTER DR',
        RegAddrCity: 'KENNETT SQ',
        RegAddrState: 'PA',
        RegAddrZip: 19348,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '14 RAVEN LN',
        RegAddrCity: 'KENNETT SQ',
        RegAddrState: 'PA',
        RegAddrZip: 19348,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '148 ELKDALE RD',
        RegAddrCity: 'LINCOLN UNIV',
        RegAddrState: 'PA',
        RegAddrZip: 19352,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '17 ATTERBURY DR',
        RegAddrCity: 'MALVERN',
        RegAddrState: 'PA',
        RegAddrZip: 19355,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '304 APPLEBROOK DR',
        RegAddrCity: 'MALVERN',
        RegAddrState: 'PA',
        RegAddrZip: 19355,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '2210 WOODVIEW WAY',
        RegAddrCity: 'MALVERN',
        RegAddrState: 'PA',
        RegAddrZip: 19355,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '2078 DUTTON MILL RD',
        RegAddrCity: 'NEWTOWN SQ',
        RegAddrState: 'PA',
        RegAddrZip: 19073,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '530 WHEELER BLVD',
        RegAddrCity: 'OXFORD',
        RegAddrState: 'PA',
        RegAddrZip: 19363,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '2167 KIMBERTON RD',
        RegAddrCity: 'PHOENIXVILLE',
        RegAddrState: 'PA',
        RegAddrZip: 19460,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '1198 LAUREL LN',
        RegAddrCity: 'PHOENIXVILLE',
        RegAddrState: 'PA',
        RegAddrZip: 19460,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '1014 MEADOW CREST DR',
        RegAddrCity: 'PHOENIXVILLE',
        RegAddrState: 'PA',
        RegAddrZip: 19460,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '105 TRINLEY ST',
        RegAddrCity: 'POTTSTOWN',
        RegAddrState: 'PA',
        RegAddrZip: 19465,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '1654 LAURELWOOD RD',
        RegAddrCity: 'POTTSTOWN',
        RegAddrState: 'PA',
        RegAddrZip: 19465,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '1330 CHURCH ST',
        RegAddrCity: 'READING',
        RegAddrState: 'PA',
        RegAddrZip: 19601,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '916 WASHINGTON ST',
        RegAddrCity: 'READING',
        RegAddrState: 'PA',
        RegAddrZip: 19601,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '1508 GREENVIEW AVE',
        RegAddrCity: 'READING',
        RegAddrState: 'PA',
        RegAddrZip: 19601,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '1109 DOUGLASS ST',
        RegAddrCity: 'READING',
        RegAddrState: 'PA',
        RegAddrZip: 19604,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '5203 E LORANE RD',
        RegAddrCity: 'READING',
        RegAddrState: 'PA',
        RegAddrZip: 19606,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '464 SAINT BERNARDINE ST',
        RegAddrCity: 'READING',
        RegAddrState: 'PA',
        RegAddrZip: 19607,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '201 INSPIRATION BLVD',
        RegAddrCity: 'READING',
        RegAddrState: 'PA',
        RegAddrZip: 19607,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '604 DORCHESTER AVE',
        RegAddrCity: 'READING',
        RegAddrState: 'PA',
        RegAddrZip: 19609,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '2231 READING AVE',
        RegAddrCity: 'READING',
        RegAddrState: 'PA',
        RegAddrZip: 19609,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '18 TIMBERLINE DR',
        RegAddrCity: 'READING',
        RegAddrState: 'PA',
        RegAddrZip: 19610,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '1415 PARKSIDE DR N',
        RegAddrCity: 'READING',
        RegAddrState: 'PA',
        RegAddrZip: 19610,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '905 IVY CT',
        RegAddrCity: 'READING',
        RegAddrState: 'PA',
        RegAddrZip: 19610,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '39 BUCKWALTER RD',
        RegAddrCity: 'SPRING CITY',
        RegAddrState: 'PA',
        RegAddrZip: 19475,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '903 HOLLOW RD',
        RegAddrCity: 'WAYNE',
        RegAddrState: 'PA',
        RegAddrZip: 19087,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '323 W CHESTNUT ST',
        RegAddrCity: 'WEST CHESTER',
        RegAddrState: 'PA',
        RegAddrZip: 19380,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '816 CHURCH AVE',
        RegAddrCity: 'WEST CHESTER',
        RegAddrState: 'PA',
        RegAddrZip: 19382,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '408 CIRCLE TOP LN',
        RegAddrCity: 'WEST CHESTER',
        RegAddrState: 'PA',
        RegAddrZip: 19382,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '1023 WARREN RD',
        RegAddrCity: 'WEST CHESTER',
        RegAddrState: 'PA',
        RegAddrZip: 19382,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '21 W BARNARD ST',
        RegAddrCity: 'WEST CHESTER',
        RegAddrState: 'PA',
        RegAddrZip: 19382,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '125 GOSHEN HALL',
        RegAddrCity: 'WEST CHESTER',
        RegAddrState: 'PA',
        RegAddrZip: 19383,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '1 BRIARCLIFF CIR',
        RegAddrCity: 'WEST GROVE',
        RegAddrState: 'PA',
        RegAddrZip: 19390,
        CongressionalDistrict: 6,
      },
    ],
  },
  {
    name: 'PA-7',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/PA-7?ref=gh-pages',
    state: 'PA',
    state_and_number: 'PA-7',
    zip: 17505,
    congressionalDistrict: 7,
    addressesInDistrict: [
      {
        RegAddrLine1: '257 FRANKLIN ST',
        RegAddrCity: 'ALBURTIS',
        RegAddrState: 'PA',
        RegAddrZip: 18011,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '125 W TILGHMAN ST',
        RegAddrCity: 'ALLENTOWN',
        RegAddrState: 'PA',
        RegAddrZip: 18102,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '822 N 07TH ST',
        RegAddrCity: 'ALLENTOWN',
        RegAddrState: 'PA',
        RegAddrZip: 18102,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '432 W TURNER ST',
        RegAddrCity: 'ALLENTOWN',
        RegAddrState: 'PA',
        RegAddrZip: 18102,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '2550 GREEN ACRES DR',
        RegAddrCity: 'ALLENTOWN',
        RegAddrState: 'PA',
        RegAddrZip: 18103,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '2938 FERNOR ST',
        RegAddrCity: 'ALLENTOWN',
        RegAddrState: 'PA',
        RegAddrZip: 18103,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '115 W FEDERAL ST',
        RegAddrCity: 'ALLENTOWN',
        RegAddrState: 'PA',
        RegAddrZip: 18103,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '2144 W WASHINGTON ST',
        RegAddrCity: 'ALLENTOWN',
        RegAddrState: 'PA',
        RegAddrZip: 18104,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '426 BARN SWALLOW LN',
        RegAddrCity: 'ALLENTOWN',
        RegAddrState: 'PA',
        RegAddrZip: 18104,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: 'MUHLENBERG COLLEGE 2298',
        RegAddrCity: 'ALLENTOWN',
        RegAddrState: 'PA',
        RegAddrZip: 18104,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '1499 MORNING STAR DR',
        RegAddrCity: 'ALLENTOWN',
        RegAddrState: 'PA',
        RegAddrZip: 18106,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '616 SHERMAN ST',
        RegAddrCity: 'ALLENTOWN',
        RegAddrState: 'PA',
        RegAddrZip: 18109,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '527 E PACKER AVE',
        RegAddrCity: 'BETHLEHEM',
        RegAddrState: 'PA',
        RegAddrZip: 18015,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '2085 JOHNSTON AVE',
        RegAddrCity: 'BETHLEHEM',
        RegAddrState: 'PA',
        RegAddrZip: 18015,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '1617 FINCHES GARDEN RD',
        RegAddrCity: 'BETHLEHEM',
        RegAddrState: 'PA',
        RegAddrZip: 18015,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '3107 LUCAS DR',
        RegAddrCity: 'BETHLEHEM',
        RegAddrState: 'PA',
        RegAddrZip: 18017,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '1552 LUZERNE ST',
        RegAddrCity: 'BETHLEHEM',
        RegAddrState: 'PA',
        RegAddrZip: 18017,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '3179 PATTERSON DR',
        RegAddrCity: 'BETHLEHEM',
        RegAddrState: 'PA',
        RegAddrZip: 18017,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '7041 ROTTERDAM SQ',
        RegAddrCity: 'BETHLEHEM',
        RegAddrState: 'PA',
        RegAddrZip: 18017,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '315 E UNION BLVD',
        RegAddrCity: 'BETHLEHEM',
        RegAddrState: 'PA',
        RegAddrZip: 18018,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '636 14TH AVE',
        RegAddrCity: 'BETHLEHEM',
        RegAddrState: 'PA',
        RegAddrZip: 18018,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '40 VALLEY PARK S',
        RegAddrCity: 'BETHLEHEM',
        RegAddrState: 'PA',
        RegAddrZip: 18018,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '1519 SILO HILL LN',
        RegAddrCity: 'BREINIGSVILLE',
        RegAddrState: 'PA',
        RegAddrZip: 18031,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '315 PEACH ST',
        RegAddrCity: 'CATASAUQUA',
        RegAddrState: 'PA',
        RegAddrZip: 18032,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '4459 OLD BETHLEHEM PIKE',
        RegAddrCity: 'CENTER VALLEY',
        RegAddrState: 'PA',
        RegAddrZip: 18034,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '5202 VERA CRUZ RD',
        RegAddrCity: 'CENTER VALLEY',
        RegAddrState: 'PA',
        RegAddrZip: 18034,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '3891 JENNY DR',
        RegAddrCity: 'DANIELSVILLE',
        RegAddrState: 'PA',
        RegAddrZip: 18038,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '4105 TRILLIUM TER',
        RegAddrCity: 'E STROUDSBURG',
        RegAddrState: 'PA',
        RegAddrZip: 18301,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '200 PROSPECT ST',
        RegAddrCity: 'EAST STROUDSBURG',
        RegAddrState: 'PA',
        RegAddrZip: 18301,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '1740 DEER PATH RD',
        RegAddrCity: 'EASTON',
        RegAddrState: 'PA',
        RegAddrZip: 18040,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '308 TUSCANY DR',
        RegAddrCity: 'EASTON',
        RegAddrState: 'PA',
        RegAddrZip: 18040,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '1525 DEER PATH RD',
        RegAddrCity: 'EASTON',
        RegAddrState: 'PA',
        RegAddrZip: 18040,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '162 W WILKES BARRE ST',
        RegAddrCity: 'EASTON',
        RegAddrState: 'PA',
        RegAddrZip: 18042,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '34 S 6TH ST',
        RegAddrCity: 'EASTON',
        RegAddrState: 'PA',
        RegAddrZip: 18042,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '3881 DANBERRY DR',
        RegAddrCity: 'EASTON',
        RegAddrState: 'PA',
        RegAddrZip: 18045,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '239 RIDGEWOOD RD',
        RegAddrCity: 'EASTON',
        RegAddrState: 'PA',
        RegAddrZip: 18045,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '30 GROVE ST',
        RegAddrCity: 'EASTON',
        RegAddrState: 'PA',
        RegAddrZip: 18045,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '930 BUTTONWOOD ST',
        RegAddrCity: 'EMMAUS',
        RegAddrState: 'PA',
        RegAddrZip: 18049,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '8058 KINGS HWY',
        RegAddrCity: 'NEW TRIPOLI',
        RegAddrState: 'PA',
        RegAddrZip: 18066,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '8076 SASSAFRAS RD',
        RegAddrCity: 'NEW TRIPOLI',
        RegAddrState: 'PA',
        RegAddrZip: 18066,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '1516 LAUBACH AVE',
        RegAddrCity: 'NORTHAMPTON',
        RegAddrState: 'PA',
        RegAddrZip: 18067,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '5421 WILLOW WAY',
        RegAddrCity: 'OREFIELD',
        RegAddrState: 'PA',
        RegAddrZip: 18069,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '11 GRANITE WAY',
        RegAddrCity: 'OTTSVILLE',
        RegAddrState: 'PA',
        RegAddrZip: 18942,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '70 BALLEK RD',
        RegAddrCity: 'RIEGELSVILLE',
        RegAddrState: 'PA',
        RegAddrZip: 18077,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '1210 BLUE MOUNTAIN CIR',
        RegAddrCity: 'SAYLORSBURG',
        RegAddrState: 'PA',
        RegAddrZip: 18353,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '104 SPRING HOUSE DR',
        RegAddrCity: 'SAYLORSBURG',
        RegAddrState: 'PA',
        RegAddrZip: 18353,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '7771 PENN ST',
        RegAddrCity: 'SLATINGTON',
        RegAddrState: 'PA',
        RegAddrZip: 18080,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '4227 MANOR DR',
        RegAddrCity: 'STROUDSBURG',
        RegAddrState: 'PA',
        RegAddrZip: 18360,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '528 WOODHAVEN LN',
        RegAddrCity: 'STROUDSBURG',
        RegAddrState: 'PA',
        RegAddrZip: 18360,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '440 WOODLAND RD',
        RegAddrCity: 'WALNUTPORT',
        RegAddrState: 'PA',
        RegAddrZip: 18088,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '6005 WOODLAWN DR',
        RegAddrCity: 'ZIONSVILLE',
        RegAddrState: 'PA',
        RegAddrZip: 18092,
        CongressionalDistrict: 7,
      },
    ],
  },
  {
    name: 'PA-8',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/PA-8?ref=gh-pages',
    state: 'PA',
    state_and_number: 'PA-8',
    zip: 18036,
    congressionalDistrict: 8,
    addressesInDistrict: [
      {
        RegAddrLine1: '142 LAMPMAN ST',
        RegAddrCity: 'AVOCA',
        RegAddrState: 'PA',
        RegAddrZip: 18641,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '33 LAUREL RUN RD',
        RegAddrCity: 'BEAR CREEK TW',
        RegAddrState: 'PA',
        RegAddrZip: 18702,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '379 POCONO BLVD',
        RegAddrCity: 'BUSHKILL',
        RegAddrState: 'PA',
        RegAddrZip: 18324,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '1660 FOREST ACRES DR',
        RegAddrCity: 'CLARKS SUMMIT',
        RegAddrState: 'PA',
        RegAddrZip: 18411,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '601 W GROVE ST',
        RegAddrCity: 'CLARKS SUMMIT',
        RegAddrState: 'PA',
        RegAddrZip: 18411,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '348 WILD ACRES DR',
        RegAddrCity: 'DINGMANS FERRY',
        RegAddrState: 'PA',
        RegAddrZip: 18328,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '433 OLD AIRPORT RD',
        RegAddrCity: 'DRUMS',
        RegAddrState: 'PA',
        RegAddrZip: 18222,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '154 BEAR RUN DR',
        RegAddrCity: 'DRUMS',
        RegAddrState: 'PA',
        RegAddrZip: 18222,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '702 BRENDA LN',
        RegAddrCity: 'DUNMORE',
        RegAddrState: 'PA',
        RegAddrZip: 18512,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '1261 SYLVAN LN',
        RegAddrCity: 'E STROUDSBURG',
        RegAddrState: 'PA',
        RegAddrZip: 18301,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '13 AUBURN WAY',
        RegAddrCity: 'E STROUDSBURG',
        RegAddrState: 'PA',
        RegAddrZip: 18302,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '27 RUSSELL RIDGE RD',
        RegAddrCity: 'EAST STROUDSBURG',
        RegAddrState: 'PA',
        RegAddrZip: 18302,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '403 BIRCHWOOD EST',
        RegAddrCity: 'EXETER',
        RegAddrState: 'PA',
        RegAddrZip: 18643,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '25 LAUREL LN',
        RegAddrCity: 'FREELAND',
        RegAddrState: 'PA',
        RegAddrZip: 18224,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '1524 COUNTY PARK RD',
        RegAddrCity: 'GILBERT',
        RegAddrState: 'PA',
        RegAddrZip: 18331,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '581 FAIRGROUNDS RD',
        RegAddrCity: 'GILBERT',
        RegAddrState: 'PA',
        RegAddrZip: 18331,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '100 LEE PARK AVE',
        RegAddrCity: 'HANOVER TWP',
        RegAddrState: 'PA',
        RegAddrZip: 18706,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '149 HATTON RD',
        RegAddrCity: 'HAWLEY',
        RegAddrState: 'PA',
        RegAddrZip: 18428,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '52 KENNEDY RD',
        RegAddrCity: 'HONESDALE',
        RegAddrState: 'PA',
        RegAddrZip: 18431,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '426 1/2 RIDGE ST',
        RegAddrCity: 'HONESDALE',
        RegAddrState: 'PA',
        RegAddrZip: 18431,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '1161 MOUNT COBB RD',
        RegAddrCity: 'JEFFERSON TWP',
        RegAddrState: 'PA',
        RegAddrZip: 18436,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '396 SCHUYLER AVE',
        RegAddrCity: 'KINGSTON',
        RegAddrState: 'PA',
        RegAddrZip: 18704,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '702 3RD AVE',
        RegAddrCity: 'KINGSTON',
        RegAddrState: 'PA',
        RegAddrZip: 18704,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '202 MARCY ST',
        RegAddrCity: 'LARKSVILLE',
        RegAddrState: 'PA',
        RegAddrZip: 18651,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '726 BENNETT ST',
        RegAddrCity: 'LUZERNE',
        RegAddrState: 'PA',
        RegAddrZip: 18709,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '105 GLENN COMBE CIR',
        RegAddrCity: 'MILFORD',
        RegAddrState: 'PA',
        RegAddrZip: 18337,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '133 MAGNOLIA LN',
        RegAddrCity: 'MILFORD',
        RegAddrState: 'PA',
        RegAddrZip: 18337,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '3609 LAWRENCE AVE',
        RegAddrCity: 'MOOSIC',
        RegAddrState: 'PA',
        RegAddrZip: 18507,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '606 HIDEAWAY DR',
        RegAddrCity: 'MOSCOW',
        RegAddrState: 'PA',
        RegAddrZip: 18444,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '906 DEER RACK DR',
        RegAddrCity: 'MOUNTAIN TOP',
        RegAddrState: 'PA',
        RegAddrZip: 18707,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '36 SHADY TREE DR',
        RegAddrCity: 'MOUNTAIN TOP',
        RegAddrState: 'PA',
        RegAddrZip: 18707,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '163 FOREST RD',
        RegAddrCity: 'MOUNTAIN TOP',
        RegAddrState: 'PA',
        RegAddrZip: 18707,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '215 S VALLEY AVE',
        RegAddrCity: 'OLYPHANT',
        RegAddrState: 'PA',
        RegAddrZip: 18447,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '55 FROST LN',
        RegAddrCity: 'REEDERS',
        RegAddrState: 'PA',
        RegAddrZip: 18352,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '214 MAJESTIC DR',
        RegAddrCity: 'SAYLORSBURG',
        RegAddrState: 'PA',
        RegAddrZip: 18353,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '327 JUSTUS BLVD',
        RegAddrCity: 'SCOTT TWP',
        RegAddrState: 'PA',
        RegAddrZip: 18447,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '204 FRANCES AVE',
        RegAddrCity: 'SCRANTON',
        RegAddrState: 'PA',
        RegAddrZip: 18504,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '1500 EUCLID AVE',
        RegAddrCity: 'SCRANTON',
        RegAddrState: 'PA',
        RegAddrZip: 18504,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '201 WILBUR ST',
        RegAddrCity: 'SCRANTON',
        RegAddrState: 'PA',
        RegAddrZip: 18508,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '535 MONROE AVE',
        RegAddrCity: 'SCRANTON',
        RegAddrState: 'PA',
        RegAddrZip: 18510,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '540 HARRISON AVE',
        RegAddrCity: 'SCRANTON',
        RegAddrState: 'PA',
        RegAddrZip: 18510,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '8914 MOOSE LN',
        RegAddrCity: 'TOBYHANNA',
        RegAddrState: 'PA',
        RegAddrZip: 18466,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '579 LAUREL DR',
        RegAddrCity: 'TOBYHANNA',
        RegAddrState: 'PA',
        RegAddrZip: 18466,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '4 CHESTNUT LN',
        RegAddrCity: 'WHITE HAVEN',
        RegAddrState: 'PA',
        RegAddrZip: 18661,
        CongressionalDistrict: 8,
      },
    ],
  },
  {
    name: 'PA-9',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/PA-9?ref=gh-pages',
    state: 'PA',
    state_and_number: 'PA-9',
    zip: 15012,
    congressionalDistrict: 9,
    addressesInDistrict: [
      {
        RegAddrLine1: '36 GROUSE TRL',
        RegAddrCity: 'ALBRIGHTSVLLE',
        RegAddrState: 'PA',
        RegAddrZip: 18210,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '43 3RD ST',
        RegAddrCity: 'BEAVER MDWS',
        RegAddrState: 'PA',
        RegAddrZip: 18216,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '555 HILLSIDE DR',
        RegAddrCity: 'BERWICK',
        RegAddrState: 'PA',
        RegAddrZip: 18603,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '2819 LIMEKILN RD',
        RegAddrCity: 'BIRDSBORO',
        RegAddrState: 'PA',
        RegAddrZip: 19508,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '649 BARLET RD',
        RegAddrCity: 'BLANDON',
        RegAddrState: 'PA',
        RegAddrZip: 19510,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '804 E 2ND ST',
        RegAddrCity: 'BLOOMSBURG',
        RegAddrState: 'PA',
        RegAddrZip: 17815,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '216 MOUNTAIN MARY RD',
        RegAddrCity: 'BOYERTOWN',
        RegAddrState: 'PA',
        RegAddrZip: 19512,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '34 SUNNY MEADOW DR',
        RegAddrCity: 'CATAWISSA',
        RegAddrState: 'PA',
        RegAddrZip: 17820,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '27 DEER RD',
        RegAddrCity: 'CATAWISSA',
        RegAddrState: 'PA',
        RegAddrZip: 17820,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '49 SMITHS POND RD',
        RegAddrCity: 'DALLAS',
        RegAddrState: 'PA',
        RegAddrZip: 18612,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '315 GREEN MEADOW DR',
        RegAddrCity: 'DOUGLASSVILLE',
        RegAddrState: 'PA',
        RegAddrZip: 19518,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '502 BLANDON RD',
        RegAddrCity: 'FLEETWOOD',
        RegAddrState: 'PA',
        RegAddrZip: 19522,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '125 W LAUREL ST',
        RegAddrCity: 'FRACKVILLE',
        RegAddrState: 'PA',
        RegAddrZip: 17931,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '213 N 3RD ST',
        RegAddrCity: 'HAMBURG',
        RegAddrState: 'PA',
        RegAddrZip: 19526,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '1189 HALOWICH RD',
        RegAddrCity: 'HARVEYS LAKE',
        RegAddrState: 'PA',
        RegAddrZip: 18618,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '2857 STATE ROUTE 72',
        RegAddrCity: 'JONESTOWN',
        RegAddrState: 'PA',
        RegAddrZip: 17038,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '229 HAWK MOUNTAIN RD',
        RegAddrCity: 'KEMPTON',
        RegAddrState: 'PA',
        RegAddrZip: 19529,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '1321 CHESTNUT ST',
        RegAddrCity: 'KULPMONT',
        RegAddrState: 'PA',
        RegAddrZip: 17834,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '135 VERMONT DR',
        RegAddrCity: 'KULPMONT',
        RegAddrState: 'PA',
        RegAddrZip: 17834,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '57 RED FOX CT',
        RegAddrCity: 'LAKE HARMONY',
        RegAddrState: 'PA',
        RegAddrZip: 18624,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '206 PLAZA APARTMENTS',
        RegAddrCity: 'LEBANON',
        RegAddrState: 'PA',
        RegAddrZip: 17042,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '730 ASPEN LN',
        RegAddrCity: 'LEBANON',
        RegAddrState: 'PA',
        RegAddrZip: 17042,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '132 LEBANON VLG',
        RegAddrCity: 'LEBANON',
        RegAddrState: 'PA',
        RegAddrZip: 17046,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '244 NICHOLS ST',
        RegAddrCity: 'LEESPORT',
        RegAddrState: 'PA',
        RegAddrZip: 19533,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '145 HAWK RD',
        RegAddrCity: 'LEHIGHTON',
        RegAddrState: 'PA',
        RegAddrZip: 18235,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '130 TIFFANY LN',
        RegAddrCity: 'LEHIGHTON',
        RegAddrState: 'PA',
        RegAddrZip: 18235,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '207 HADDON DR',
        RegAddrCity: 'MACUNGIE',
        RegAddrState: 'PA',
        RegAddrZip: 18062,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '333 NORTH ST',
        RegAddrCity: 'MINERSVILLE',
        RegAddrState: 'PA',
        RegAddrZip: 17954,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '691 HICKORY RD',
        RegAddrCity: 'MOHRSVILLE',
        RegAddrState: 'PA',
        RegAddrZip: 19541,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '31 W MAIN AVE',
        RegAddrCity: 'MYERSTOWN',
        RegAddrState: 'PA',
        RegAddrZip: 17067,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '194 ROBERT ST',
        RegAddrCity: 'NANTICOKE',
        RegAddrState: 'PA',
        RegAddrZip: 18634,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '804 ZENITH RD',
        RegAddrCity: 'NESCOPECK',
        RegAddrState: 'PA',
        RegAddrZip: 18635,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '172 KIMBER ST',
        RegAddrCity: 'NEW PHILA',
        RegAddrState: 'PA',
        RegAddrZip: 17959,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '3 CHERRY LN',
        RegAddrCity: 'NEWMANSTOWN',
        RegAddrState: 'PA',
        RegAddrZip: 17073,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '132 GRAVEL HILL RD',
        RegAddrCity: 'NEWMANSTOWN',
        RegAddrState: 'PA',
        RegAddrZip: 17073,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '801 HARNISH ST',
        RegAddrCity: 'PALMYRA',
        RegAddrState: 'PA',
        RegAddrZip: 17078,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '560 PENN ST',
        RegAddrCity: 'PORT CLINTON',
        RegAddrState: 'PA',
        RegAddrZip: 19549,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '109 PENN ST',
        RegAddrCity: 'PORT CLINTON',
        RegAddrState: 'PA',
        RegAddrZip: 19549,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '625 N 2ND ST',
        RegAddrCity: 'POTTSVILLE',
        RegAddrState: 'PA',
        RegAddrZip: 17901,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '555 KENWOOD DR',
        RegAddrCity: 'POTTSVILLE',
        RegAddrState: 'PA',
        RegAddrZip: 17901,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '972 VICTORY CIR',
        RegAddrCity: 'READING',
        RegAddrState: 'PA',
        RegAddrZip: 19605,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '325 HARRY AVE',
        RegAddrCity: 'ROBESONIA',
        RegAddrState: 'PA',
        RegAddrZip: 19551,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '404 ORCHARD AVE',
        RegAddrCity: 'SCHUYKL HAVN',
        RegAddrState: 'PA',
        RegAddrZip: 17972,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '64 CENTER AVE',
        RegAddrCity: 'SCHUYKL HAVN',
        RegAddrState: 'PA',
        RegAddrZip: 17972,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '52 S 2ND ST',
        RegAddrCity: 'SHAMOKIN',
        RegAddrState: 'PA',
        RegAddrZip: 17872,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '8 POST OFFICE RD',
        RegAddrCity: 'SWEET VALLEY',
        RegAddrState: 'PA',
        RegAddrZip: 18656,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '1211 VALLEY RD',
        RegAddrCity: 'TAMAQUA',
        RegAddrState: 'PA',
        RegAddrZip: 18252,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '4550 SEFRANKA RD',
        RegAddrCity: 'TEMPLE',
        RegAddrState: 'PA',
        RegAddrZip: 19560,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '3002 PRICETOWN RD',
        RegAddrCity: 'TEMPLE',
        RegAddrState: 'PA',
        RegAddrZip: 19560,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '2150 GEORGIA RD',
        RegAddrCity: 'TEMPLE',
        RegAddrState: 'PA',
        RegAddrZip: 19560,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '236 E MAIN ST',
        RegAddrCity: 'TREMONT',
        RegAddrState: 'PA',
        RegAddrZip: 17981,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '635 LILY LAKE RD',
        RegAddrCity: 'WAPWALLOPEN',
        RegAddrState: 'PA',
        RegAddrZip: 18660,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '46 E WILSON AVE',
        RegAddrCity: 'WERNERSVILLE',
        RegAddrState: 'PA',
        RegAddrZip: 19565,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '121 CRANBERRY RUN RD',
        RegAddrCity: 'ZION GROVE',
        RegAddrState: 'PA',
        RegAddrZip: 17985,
        CongressionalDistrict: 9,
      },
    ],
  },
  {
    name: 'PR-0',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/PR-0?ref=gh-pages',
    state: 'PR',
    state_and_number: 'PR-0',
    congressionalDistrict: 0,
    addressesInDistrict: [],
  },
  {
    name: 'RI-1',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/RI-1?ref=gh-pages',
    state: 'RI',
    state_and_number: 'RI-1',
    zip: 2802,
    congressionalDistrict: 1,
    addressesInDistrict: [
      {
        RegAddrLine1: '82 FERRY LN',
        RegAddrCity: 'BARRINGTON',
        RegAddrState: 'RI',
        RegAddrZip: 2806,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2 HOWARD ST',
        RegAddrCity: 'BARRINGTON',
        RegAddrState: 'RI',
        RegAddrZip: 2806,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '285 RUMSTICK RD',
        RegAddrCity: 'BARRINGTON',
        RegAddrState: 'RI',
        RegAddrZip: 2806,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2 BEAVER RD',
        RegAddrCity: 'BARRINGTON',
        RegAddrState: 'RI',
        RegAddrZip: 2806,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '20 STARBROOK DR',
        RegAddrCity: 'BARRINGTON',
        RegAddrState: 'RI',
        RegAddrZip: 2806,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '87 ALFRED DROWN RD',
        RegAddrCity: 'BARRINGTON',
        RegAddrState: 'RI',
        RegAddrZip: 2806,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '9 STONE TOWER LN',
        RegAddrCity: 'BARRINGTON',
        RegAddrState: 'RI',
        RegAddrZip: 2806,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '43 LAMSON RD',
        RegAddrCity: 'BARRINGTON',
        RegAddrState: 'RI',
        RegAddrZip: 2806,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '15 DUFFIELD RD',
        RegAddrCity: 'BRISTOL',
        RegAddrState: 'RI',
        RegAddrZip: 2809,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '6 TILBURY DR',
        RegAddrCity: 'BRISTOL',
        RegAddrState: 'RI',
        RegAddrZip: 2809,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '91 HIGHLAND RD',
        RegAddrCity: 'BRISTOL',
        RegAddrState: 'RI',
        RegAddrZip: 2809,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '11 3RD ST',
        RegAddrCity: 'BRISTOL',
        RegAddrState: 'RI',
        RegAddrZip: 2809,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '35 SHERMAN AVE',
        RegAddrCity: 'BRISTOL',
        RegAddrState: 'RI',
        RegAddrZip: 2809,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '7 FORT HILL RD',
        RegAddrCity: 'BRISTOL',
        RegAddrState: 'RI',
        RegAddrZip: 2809,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '480 METACOM AVE',
        RegAddrCity: 'BRISTOL',
        RegAddrState: 'RI',
        RegAddrZip: 2809,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '15 ACACIA RD',
        RegAddrCity: 'BRISTOL',
        RegAddrState: 'RI',
        RegAddrZip: 2809,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '587 PINE ST',
        RegAddrCity: 'CENTRAL FALLS',
        RegAddrState: 'RI',
        RegAddrZip: 2863,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1485 HIGH ST',
        RegAddrCity: 'CENTRAL FALLS',
        RegAddrState: 'RI',
        RegAddrZip: 2863,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '26 DARLING ST',
        RegAddrCity: 'CENTRAL FALLS',
        RegAddrState: 'RI',
        RegAddrZip: 2863,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '24 SUNNYSIDE DR',
        RegAddrCity: 'CUMBERLAND',
        RegAddrState: 'RI',
        RegAddrZip: 2864,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '84 CRESTWOOD CT',
        RegAddrCity: 'CUMBERLAND',
        RegAddrState: 'RI',
        RegAddrZip: 2864,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '31 BRITTS RDG',
        RegAddrCity: 'CUMBERLAND',
        RegAddrState: 'RI',
        RegAddrZip: 2864,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '17 STEERE RD',
        RegAddrCity: 'CUMBERLAND',
        RegAddrState: 'RI',
        RegAddrZip: 2864,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '261 CURRAN RD',
        RegAddrCity: 'CUMBERLAND',
        RegAddrState: 'RI',
        RegAddrZip: 2864,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '23 LEMIEUX AVE',
        RegAddrCity: 'CUMBERLAND',
        RegAddrState: 'RI',
        RegAddrZip: 2864,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '94 OAK HILL DR',
        RegAddrCity: 'CUMBERLAND',
        RegAddrState: 'RI',
        RegAddrZip: 2864,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '5 ANGUS DR',
        RegAddrCity: 'CUMBERLAND',
        RegAddrState: 'RI',
        RegAddrZip: 2864,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '9 CARLSON DR',
        RegAddrCity: 'CUMBERLAND',
        RegAddrState: 'RI',
        RegAddrZip: 2864,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '24 DEXTER ST',
        RegAddrCity: 'CUMBERLAND',
        RegAddrState: 'RI',
        RegAddrZip: 2864,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '129 N HULL ST',
        RegAddrCity: 'E PROVIDENCE',
        RegAddrState: 'RI',
        RegAddrZip: 2914,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '740 VETERANS MEMORIAL PKWY',
        RegAddrCity: 'E PROVIDENCE',
        RegAddrState: 'RI',
        RegAddrZip: 2914,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '105 7TH ST',
        RegAddrCity: 'EAST PROVIDENCE',
        RegAddrState: 'RI',
        RegAddrZip: 2914,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '99 FARNUM ST',
        RegAddrCity: 'EAST PROVIDENCE',
        RegAddrState: 'RI',
        RegAddrZip: 2914,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '45 BEVERLY CIR',
        RegAddrCity: 'GREENVILLE',
        RegAddrState: 'RI',
        RegAddrZip: 2828,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '56 MAPLE AVE',
        RegAddrCity: 'JAMESTOWN',
        RegAddrState: 'RI',
        RegAddrZip: 2835,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '30 STEAMBOAT ST',
        RegAddrCity: 'JAMESTOWN',
        RegAddrState: 'RI',
        RegAddrZip: 2835,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '7 OCEAN AVE',
        RegAddrCity: 'JAMESTOWN',
        RegAddrState: 'RI',
        RegAddrZip: 2835,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '25 ELM ST',
        RegAddrCity: 'LINCOLN',
        RegAddrState: 'RI',
        RegAddrZip: 2838,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1208 LONSDALE AVE',
        RegAddrCity: 'LINCOLN',
        RegAddrState: 'RI',
        RegAddrZip: 2865,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '847 SMITHFIELD AVE',
        RegAddrCity: 'LINCOLN',
        RegAddrState: 'RI',
        RegAddrZip: 2865,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '7 LINDA ST',
        RegAddrCity: 'LINCOLN',
        RegAddrState: 'RI',
        RegAddrZip: 2865,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '11 IRON FORGE RD',
        RegAddrCity: 'LINCOLN',
        RegAddrState: 'RI',
        RegAddrZip: 2865,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '4 WINTERBERRY RD',
        RegAddrCity: 'LINCOLN',
        RegAddrState: 'RI',
        RegAddrZip: 2865,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '16 BRIARWOOD RD',
        RegAddrCity: 'LINCOLN',
        RegAddrState: 'RI',
        RegAddrZip: 2865,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '89 OLD RIVER RD',
        RegAddrCity: 'LINCOLN',
        RegAddrState: 'RI',
        RegAddrZip: 2865,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '221 COMPTON VIEW DR',
        RegAddrCity: 'MIDDLETOWN',
        RegAddrState: 'RI',
        RegAddrZip: 2842,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '93 PURITAN DR',
        RegAddrCity: 'MIDDLETOWN',
        RegAddrState: 'RI',
        RegAddrZip: 2842,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '240 OLIPHANT LN',
        RegAddrCity: 'MIDDLETOWN',
        RegAddrState: 'RI',
        RegAddrZip: 2842,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '606 SMITHFIELD RD',
        RegAddrCity: 'N PROVIDENCE',
        RegAddrState: 'RI',
        RegAddrZip: 2904,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '17 MARIGOLD CIR',
        RegAddrCity: 'N PROVIDENCE',
        RegAddrState: 'RI',
        RegAddrZip: 2904,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '13 SALEM DR',
        RegAddrCity: 'N PROVIDENCE',
        RegAddrState: 'RI',
        RegAddrZip: 2904,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '22 BROWN ST',
        RegAddrCity: 'N PROVIDENCE',
        RegAddrState: 'RI',
        RegAddrZip: 2904,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '46 WELLESLEY AVE',
        RegAddrCity: 'N PROVIDENCE',
        RegAddrState: 'RI',
        RegAddrZip: 2911,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '8 JULIA DR',
        RegAddrCity: 'N PROVIDENCE',
        RegAddrState: 'RI',
        RegAddrZip: 2911,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '36 HOPE ST',
        RegAddrCity: 'N PROVIDENCE',
        RegAddrState: 'RI',
        RegAddrZip: 2911,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '14 BRUCE DR',
        RegAddrCity: 'N SMITHFIELD',
        RegAddrState: 'RI',
        RegAddrZip: 2896,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '20 TOM LEE DR',
        RegAddrCity: 'N SMITHFIELD',
        RegAddrState: 'RI',
        RegAddrZip: 2896,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '9 SUNNYCREST AVE',
        RegAddrCity: 'N SMITHFIELD',
        RegAddrState: 'RI',
        RegAddrZip: 2896,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '21 SHIMODA WAY',
        RegAddrCity: 'NEWPORT',
        RegAddrState: 'RI',
        RegAddrZip: 2840,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '7 WARNER ST',
        RegAddrCity: 'NEWPORT',
        RegAddrState: 'RI',
        RegAddrZip: 2840,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '9 ROBINSON ST',
        RegAddrCity: 'NEWPORT',
        RegAddrState: 'RI',
        RegAddrZip: 2840,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '28 BURDICK AVE',
        RegAddrCity: 'NEWPORT',
        RegAddrState: 'RI',
        RegAddrZip: 2840,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '126 FILLMORE ST',
        RegAddrCity: 'PAWTUCKET',
        RegAddrState: 'RI',
        RegAddrZip: 2860,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '123 ARMISTICE BLVD',
        RegAddrCity: 'PAWTUCKET',
        RegAddrState: 'RI',
        RegAddrZip: 2860,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '52 EAST ST',
        RegAddrCity: 'PAWTUCKET',
        RegAddrState: 'RI',
        RegAddrZip: 2860,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '93 VIVIAN AVE',
        RegAddrCity: 'PAWTUCKET',
        RegAddrState: 'RI',
        RegAddrZip: 2860,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '6 WELLESLEY AVE',
        RegAddrCity: 'PAWTUCKET',
        RegAddrState: 'RI',
        RegAddrZip: 2860,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '35 SENECA AVE',
        RegAddrCity: 'PAWTUCKET',
        RegAddrState: 'RI',
        RegAddrZip: 2860,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '124 HILLSIDE AVE',
        RegAddrCity: 'PAWTUCKET',
        RegAddrState: 'RI',
        RegAddrZip: 2860,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '24 GROVE ST',
        RegAddrCity: 'PAWTUCKET',
        RegAddrState: 'RI',
        RegAddrZip: 2860,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '79 MEMORIAL DR',
        RegAddrCity: 'PAWTUCKET',
        RegAddrState: 'RI',
        RegAddrZip: 2860,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '467 POWER RD',
        RegAddrCity: 'PAWTUCKET',
        RegAddrState: 'RI',
        RegAddrZip: 2860,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '21 CLARK AVE',
        RegAddrCity: 'PAWTUCKET',
        RegAddrState: 'RI',
        RegAddrZip: 2860,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '11 FARNUM ST',
        RegAddrCity: 'PAWTUCKET',
        RegAddrState: 'RI',
        RegAddrZip: 2861,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '55 RIDGEWOOD RD',
        RegAddrCity: 'PAWTUCKET',
        RegAddrState: 'RI',
        RegAddrZip: 2861,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '120 VINE ST',
        RegAddrCity: 'PAWTUCKET',
        RegAddrState: 'RI',
        RegAddrZip: 2861,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '749 YORK AVE',
        RegAddrCity: 'PAWTUCKET',
        RegAddrState: 'RI',
        RegAddrZip: 2861,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '339 ORIENT AVE',
        RegAddrCity: 'PAWTUCKET',
        RegAddrState: 'RI',
        RegAddrZip: 2861,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '31 BLOOMFIELD ST',
        RegAddrCity: 'PAWTUCKET',
        RegAddrState: 'RI',
        RegAddrZip: 2861,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '28 MILES AVE',
        RegAddrCity: 'PAWTUCKET',
        RegAddrState: 'RI',
        RegAddrZip: 2861,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '878 WAPPING RD',
        RegAddrCity: 'PORTSMOUTH',
        RegAddrState: 'RI',
        RegAddrZip: 2871,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '260 FISCHER CIR',
        RegAddrCity: 'PORTSMOUTH',
        RegAddrState: 'RI',
        RegAddrZip: 2871,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '7 SCOTTY DR',
        RegAddrCity: 'PORTSMOUTH',
        RegAddrState: 'RI',
        RegAddrZip: 2871,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '159 FREEBORN ST',
        RegAddrCity: 'PORTSMOUTH',
        RegAddrState: 'RI',
        RegAddrZip: 2871,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '28 MAGGIE LN',
        RegAddrCity: 'PORTSMOUTH',
        RegAddrState: 'RI',
        RegAddrZip: 2871,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '95 HEIDI DR',
        RegAddrCity: 'PORTSMOUTH',
        RegAddrState: 'RI',
        RegAddrZip: 2871,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '18 FOXBORO AVE',
        RegAddrCity: 'PORTSMOUTH',
        RegAddrState: 'RI',
        RegAddrZip: 2871,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '35 EYLER DR',
        RegAddrCity: 'PORTSMOUTH',
        RegAddrState: 'RI',
        RegAddrZip: 2871,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2 COLLEGE ST',
        RegAddrCity: 'PROVIDENCE',
        RegAddrState: 'RI',
        RegAddrZip: 2903,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '275 POTTERS AVE',
        RegAddrCity: 'PROVIDENCE',
        RegAddrState: 'RI',
        RegAddrZip: 2905,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '115 CALLA ST',
        RegAddrCity: 'PROVIDENCE',
        RegAddrState: 'RI',
        RegAddrZip: 2905,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '22 APPLEGATE LN',
        RegAddrCity: 'PROVIDENCE',
        RegAddrState: 'RI',
        RegAddrZip: 2905,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '19 MASSIE AVE',
        RegAddrCity: 'PROVIDENCE',
        RegAddrState: 'RI',
        RegAddrZip: 2905,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '251 ALABAMA AVE',
        RegAddrCity: 'PROVIDENCE',
        RegAddrState: 'RI',
        RegAddrZip: 2905,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '219 WATERMAN ST',
        RegAddrCity: 'PROVIDENCE',
        RegAddrState: 'RI',
        RegAddrZip: 2906,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '18 RAY ST',
        RegAddrCity: 'PROVIDENCE',
        RegAddrState: 'RI',
        RegAddrZip: 2906,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '34 7TH ST',
        RegAddrCity: 'PROVIDENCE',
        RegAddrState: 'RI',
        RegAddrZip: 2906,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '74 ARNOLD ST',
        RegAddrCity: 'PROVIDENCE',
        RegAddrState: 'RI',
        RegAddrZip: 2906,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '29 BRENTON AVE',
        RegAddrCity: 'PROVIDENCE',
        RegAddrState: 'RI',
        RegAddrZip: 2906,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '220 PLEASANT ST',
        RegAddrCity: 'PROVIDENCE',
        RegAddrState: 'RI',
        RegAddrZip: 2906,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '166 WATERMAN ST',
        RegAddrCity: 'PROVIDENCE',
        RegAddrState: 'RI',
        RegAddrZip: 2906,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '182 ROGER WILLIAMS AVE',
        RegAddrCity: 'PROVIDENCE',
        RegAddrState: 'RI',
        RegAddrZip: 2907,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '142 LENOX AVE',
        RegAddrCity: 'PROVIDENCE',
        RegAddrState: 'RI',
        RegAddrZip: 2907,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '16 STADDEN ST',
        RegAddrCity: 'PROVIDENCE',
        RegAddrState: 'RI',
        RegAddrZip: 2907,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '38 GODDARD ST',
        RegAddrCity: 'PROVIDENCE',
        RegAddrState: 'RI',
        RegAddrZip: 2908,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '38 VEAZIE ST',
        RegAddrCity: 'PROVIDENCE',
        RegAddrState: 'RI',
        RegAddrZip: 2908,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '163 OAKLAND AVE',
        RegAddrCity: 'PROVIDENCE',
        RegAddrState: 'RI',
        RegAddrZip: 2908,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '58 ANCHOR ST',
        RegAddrCity: 'PROVIDENCE',
        RegAddrState: 'RI',
        RegAddrZip: 2908,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '75 WATERMAN ST',
        RegAddrCity: 'PROVIDENCE',
        RegAddrState: 'RI',
        RegAddrZip: 2912,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '75 WATERMAN ST',
        RegAddrCity: 'PROVIDENCE',
        RegAddrState: 'RI',
        RegAddrZip: 2912,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '44 CUMBERLAND RD',
        RegAddrCity: 'RIVERSIDE',
        RegAddrState: 'RI',
        RegAddrZip: 2915,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '21 TIMBERLAND DR',
        RegAddrCity: 'RIVERSIDE',
        RegAddrState: 'RI',
        RegAddrZip: 2915,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '39 PECK AVE',
        RegAddrCity: 'RIVERSIDE',
        RegAddrState: 'RI',
        RegAddrZip: 2915,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '35 WOODBINE ST',
        RegAddrCity: 'RIVERSIDE',
        RegAddrState: 'RI',
        RegAddrZip: 2915,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '539 BULLOCKS POINT AVE',
        RegAddrCity: 'RIVERSIDE',
        RegAddrState: 'RI',
        RegAddrZip: 2915,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '182 HOYT AVE',
        RegAddrCity: 'RUMFORD',
        RegAddrState: 'RI',
        RegAddrZip: 2916,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '29 WEEDEN AVE',
        RegAddrCity: 'RUMFORD',
        RegAddrState: 'RI',
        RegAddrZip: 2916,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '121 CATLIN AVE',
        RegAddrCity: 'RUMFORD',
        RegAddrState: 'RI',
        RegAddrZip: 2916,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '32 SOPHIA LN',
        RegAddrCity: 'SMITHFIELD',
        RegAddrState: 'RI',
        RegAddrZip: 2828,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1 ELMHURST DR',
        RegAddrCity: 'SMITHFIELD',
        RegAddrState: 'RI',
        RegAddrZip: 2828,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '21 DANECROFT AVE',
        RegAddrCity: 'SMITHFIELD',
        RegAddrState: 'RI',
        RegAddrZip: 2828,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '8 FAIR OAKS LN',
        RegAddrCity: 'SMITHFIELD',
        RegAddrState: 'RI',
        RegAddrZip: 2828,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '16 HERBERT ST',
        RegAddrCity: 'SMITHFIELD',
        RegAddrState: 'RI',
        RegAddrZip: 2828,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '3 CEDAR MEADOWS DR',
        RegAddrCity: 'SMITHFIELD',
        RegAddrState: 'RI',
        RegAddrZip: 2917,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '263 MOUNTAINDALE RD',
        RegAddrCity: 'SMITHFIELD',
        RegAddrState: 'RI',
        RegAddrZip: 2917,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '6 COTTONWOOD CT',
        RegAddrCity: 'SMITHFIELD',
        RegAddrState: 'RI',
        RegAddrZip: 2917,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '78 ASH AVE',
        RegAddrCity: 'TIVERTON',
        RegAddrState: 'RI',
        RegAddrZip: 2878,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '841 STAFFORD RD',
        RegAddrCity: 'TIVERTON',
        RegAddrState: 'RI',
        RegAddrZip: 2878,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '958 LAKE RD',
        RegAddrCity: 'TIVERTON',
        RegAddrState: 'RI',
        RegAddrZip: 2878,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '207 BRIARWOOD AVE',
        RegAddrCity: 'TIVERTON',
        RegAddrState: 'RI',
        RegAddrZip: 2878,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '233 CHACE AVE',
        RegAddrCity: 'TIVERTON',
        RegAddrState: 'RI',
        RegAddrZip: 2878,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '38 CLEMENT ST',
        RegAddrCity: 'TIVERTON',
        RegAddrState: 'RI',
        RegAddrZip: 2878,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '37 E KING RD',
        RegAddrCity: 'TIVERTON',
        RegAddrState: 'RI',
        RegAddrZip: 2878,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '219 OAKLEY RD',
        RegAddrCity: 'WOONSOCKET',
        RegAddrState: 'RI',
        RegAddrZip: 2895,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '103 BELLINGHAM ST',
        RegAddrCity: 'WOONSOCKET',
        RegAddrState: 'RI',
        RegAddrZip: 2895,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '374 10TH AVE',
        RegAddrCity: 'WOONSOCKET',
        RegAddrState: 'RI',
        RegAddrZip: 2895,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '344 LYDIA AVE',
        RegAddrCity: 'WOONSOCKET',
        RegAddrState: 'RI',
        RegAddrZip: 2895,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '148 MEADOW RD',
        RegAddrCity: 'WOONSOCKET',
        RegAddrState: 'RI',
        RegAddrZip: 2895,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '215 CARRINGTON AVE',
        RegAddrCity: 'WOONSOCKET',
        RegAddrState: 'RI',
        RegAddrZip: 2895,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '431 WOODLAND RD',
        RegAddrCity: 'WOONSOCKET',
        RegAddrState: 'RI',
        RegAddrZip: 2895,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '161 LOUISE ST',
        RegAddrCity: 'WOONSOCKET',
        RegAddrState: 'RI',
        RegAddrZip: 2895,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '478 KNOLLWOOD DR',
        RegAddrCity: 'WOONSOCKET',
        RegAddrState: 'RI',
        RegAddrZip: 2895,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '736 SOCIAL ST',
        RegAddrCity: 'WOONSOCKET',
        RegAddrState: 'RI',
        RegAddrZip: 2895,
        CongressionalDistrict: 1,
      },
    ],
  },
  {
    name: 'RI-2',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/RI-2?ref=gh-pages',
    state: 'RI',
    state_and_number: 'RI-2',
    zip: 2804,
    congressionalDistrict: 2,
    addressesInDistrict: [
      {
        RegAddrLine1: '52 LAUREL ST',
        RegAddrCity: 'ASHAWAY',
        RegAddrState: 'RI',
        RegAddrZip: 2804,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '77 KUEHN RD',
        RegAddrCity: 'ASHAWAY',
        RegAddrState: 'RI',
        RegAddrZip: 2804,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '640 LAPHAM FARM RD',
        RegAddrCity: 'BURRILLVILLE',
        RegAddrState: 'RI',
        RegAddrZip: 2839,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '41 PIETILA RD',
        RegAddrCity: 'CHARLESTOWN',
        RegAddrState: 'RI',
        RegAddrZip: 2813,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '340 ROSS HILL RD',
        RegAddrCity: 'CHARLESTOWN',
        RegAddrState: 'RI',
        RegAddrZip: 2813,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '12 CONGDON ST',
        RegAddrCity: 'COVENTRY',
        RegAddrState: 'RI',
        RegAddrZip: 2816,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '10 MYRA RD',
        RegAddrCity: 'COVENTRY',
        RegAddrState: 'RI',
        RegAddrZip: 2816,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1104 CARLEY DR',
        RegAddrCity: 'COVENTRY',
        RegAddrState: 'RI',
        RegAddrZip: 2816,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '11 VANDERBILT TER',
        RegAddrCity: 'COVENTRY',
        RegAddrState: 'RI',
        RegAddrZip: 2816,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '31 BRASSIE CT',
        RegAddrCity: 'COVENTRY',
        RegAddrState: 'RI',
        RegAddrZip: 2816,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '26 DIXIE RD',
        RegAddrCity: 'COVENTRY',
        RegAddrState: 'RI',
        RegAddrZip: 2816,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '6 BEACH ST',
        RegAddrCity: 'COVENTRY',
        RegAddrState: 'RI',
        RegAddrZip: 2816,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '37 TORCH LN',
        RegAddrCity: 'COVENTRY',
        RegAddrState: 'RI',
        RegAddrZip: 2816,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '546 MAIN ST',
        RegAddrCity: 'COVENTRY',
        RegAddrState: 'RI',
        RegAddrZip: 2816,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '4103 MULHEARN DR',
        RegAddrCity: 'COVENTRY',
        RegAddrState: 'RI',
        RegAddrZip: 2816,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '140 BLACKROCK RD',
        RegAddrCity: 'COVENTRY',
        RegAddrState: 'RI',
        RegAddrZip: 2816,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '40 MAUDE AVE',
        RegAddrCity: 'COVENTRY',
        RegAddrState: 'RI',
        RegAddrZip: 2816,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '17 NORTHUP PLAT RD',
        RegAddrCity: 'COVENTRY',
        RegAddrState: 'RI',
        RegAddrZip: 2816,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '11 OLD SUMMIT RD',
        RegAddrCity: 'COVENTRY',
        RegAddrState: 'RI',
        RegAddrZip: 2827,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '142 WENTWORTH AVE',
        RegAddrCity: 'CRANSTON',
        RegAddrState: 'RI',
        RegAddrZip: 2905,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '145 ARNOLD AVE',
        RegAddrCity: 'CRANSTON',
        RegAddrState: 'RI',
        RegAddrZip: 2905,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '126 WAITE AVE',
        RegAddrCity: 'CRANSTON',
        RegAddrState: 'RI',
        RegAddrZip: 2905,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '112 BEACHMONT AVE',
        RegAddrCity: 'CRANSTON',
        RegAddrState: 'RI',
        RegAddrZip: 2905,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '11 MAPLETON ST',
        RegAddrCity: 'CRANSTON',
        RegAddrState: 'RI',
        RegAddrZip: 2910,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '119 JORDAN AVE',
        RegAddrCity: 'CRANSTON',
        RegAddrState: 'RI',
        RegAddrZip: 2910,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '135 BECKWITH ST',
        RegAddrCity: 'CRANSTON',
        RegAddrState: 'RI',
        RegAddrZip: 2910,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '31 RUGBY ST',
        RegAddrCity: 'CRANSTON',
        RegAddrState: 'RI',
        RegAddrZip: 2910,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '15 WOODLAWN DR',
        RegAddrCity: 'CRANSTON',
        RegAddrState: 'RI',
        RegAddrZip: 2920,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '23 SHERMAN AVE',
        RegAddrCity: 'CRANSTON',
        RegAddrState: 'RI',
        RegAddrZip: 2920,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '51 BATCHELLER AVE',
        RegAddrCity: 'CRANSTON',
        RegAddrState: 'RI',
        RegAddrZip: 2920,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '81 REDWOOD DR',
        RegAddrCity: 'CRANSTON',
        RegAddrState: 'RI',
        RegAddrZip: 2920,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '30 CASINO AVE',
        RegAddrCity: 'CRANSTON',
        RegAddrState: 'RI',
        RegAddrZip: 2920,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '66 HILLTOP DR',
        RegAddrCity: 'CRANSTON',
        RegAddrState: 'RI',
        RegAddrZip: 2920,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '46 TILDEN ST',
        RegAddrCity: 'CRANSTON',
        RegAddrState: 'RI',
        RegAddrZip: 2920,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '85 ECHO LN',
        RegAddrCity: 'CRANSTON',
        RegAddrState: 'RI',
        RegAddrZip: 2921,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '6 PRISCILLA DR',
        RegAddrCity: 'CRANSTON',
        RegAddrState: 'RI',
        RegAddrZip: 2921,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '100 TWIN BIRCH DR',
        RegAddrCity: 'CRANSTON',
        RegAddrState: 'RI',
        RegAddrZip: 2921,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '15 MEADOW LARK DR',
        RegAddrCity: 'CRANSTON',
        RegAddrState: 'RI',
        RegAddrZip: 2921,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '406 SCITUATE VISTA DR',
        RegAddrCity: 'CRANSTON',
        RegAddrState: 'RI',
        RegAddrZip: 2921,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '40 PARDONS WOOD LN',
        RegAddrCity: 'E GREENWICH',
        RegAddrState: 'RI',
        RegAddrZip: 2818,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '132 WUNNEGIN CIR',
        RegAddrCity: 'E GREENWICH',
        RegAddrState: 'RI',
        RegAddrZip: 2818,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '30 TRAPPERS LN',
        RegAddrCity: 'E GREENWICH',
        RegAddrState: 'RI',
        RegAddrZip: 2818,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1990 SOUTH RD',
        RegAddrCity: 'E GREENWICH',
        RegAddrState: 'RI',
        RegAddrZip: 2818,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '159 PURGATORY RD',
        RegAddrCity: 'EXETER',
        RegAddrState: 'RI',
        RegAddrZip: 2822,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '49 MILL RD',
        RegAddrCity: 'FOSTER',
        RegAddrState: 'RI',
        RegAddrZip: 2825,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '217 HARTFORD PIKE',
        RegAddrCity: 'FOSTER',
        RegAddrState: 'RI',
        RegAddrZip: 2825,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '479 DURFEE HILL RD',
        RegAddrCity: 'GLOCESTER',
        RegAddrState: 'RI',
        RegAddrZip: 2814,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '23 FRANCES BARBER DR',
        RegAddrCity: 'HOPE VALLEY',
        RegAddrState: 'RI',
        RegAddrZip: 2832,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '157 NORTH RD',
        RegAddrCity: 'HOPKINTON',
        RegAddrState: 'RI',
        RegAddrZip: 2833,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '24 RIVER DR',
        RegAddrCity: 'JOHNSTON',
        RegAddrState: 'RI',
        RegAddrZip: 2919,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2308 HARTFORD AVE',
        RegAddrCity: 'JOHNSTON',
        RegAddrState: 'RI',
        RegAddrZip: 2919,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '5 HEBDEEN ST',
        RegAddrCity: 'JOHNSTON',
        RegAddrState: 'RI',
        RegAddrZip: 2919,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '77 CHERRY HILL RD',
        RegAddrCity: 'JOHNSTON',
        RegAddrState: 'RI',
        RegAddrZip: 2919,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '27 ROLLINGWOOD DR',
        RegAddrCity: 'JOHNSTON',
        RegAddrState: 'RI',
        RegAddrZip: 2919,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2154 HARTFORD AVE',
        RegAddrCity: 'JOHNSTON',
        RegAddrState: 'RI',
        RegAddrZip: 2919,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '62 WHITE HORN DR',
        RegAddrCity: 'KINGSTON',
        RegAddrState: 'RI',
        RegAddrZip: 2881,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '169 DAHLIA DR',
        RegAddrCity: 'N KINGSTOWN',
        RegAddrState: 'RI',
        RegAddrZip: 2852,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '80 LONGFELLOW DR',
        RegAddrCity: 'N KINGSTOWN',
        RegAddrState: 'RI',
        RegAddrZip: 2852,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '102 CYDOT DR',
        RegAddrCity: 'N KINGSTOWN',
        RegAddrState: 'RI',
        RegAddrZip: 2852,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '50 BEA DR',
        RegAddrCity: 'N KINGSTOWN',
        RegAddrState: 'RI',
        RegAddrZip: 2852,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '53 DAYTON CT',
        RegAddrCity: 'N KINGSTOWN',
        RegAddrState: 'RI',
        RegAddrZip: 2852,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '469 TOWER HILL RD',
        RegAddrCity: 'N KINGSTOWN',
        RegAddrState: 'RI',
        RegAddrZip: 2852,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '47 SANDY BROOK RD',
        RegAddrCity: 'N SCITUATE',
        RegAddrState: 'RI',
        RegAddrZip: 2857,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '102 INDIAN TRL S',
        RegAddrCity: 'NARRAGANSETT',
        RegAddrState: 'RI',
        RegAddrZip: 2879,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '198 INDIAN TRL S',
        RegAddrCity: 'NARRAGANSETT',
        RegAddrState: 'RI',
        RegAddrZip: 2879,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '57 EDGEWATER RD',
        RegAddrCity: 'NARRAGANSETT',
        RegAddrState: 'RI',
        RegAddrZip: 2882,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '55 MAPLEHURST DR',
        RegAddrCity: 'NARRAGANSETT',
        RegAddrState: 'RI',
        RegAddrZip: 2882,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '79 WEST BAY DR',
        RegAddrCity: 'NARRAGANSETT',
        RegAddrState: 'RI',
        RegAddrZip: 2882,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '7 LARCH AVE',
        RegAddrCity: 'NARRAGANSETT',
        RegAddrState: 'RI',
        RegAddrZip: 2882,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '79 VILLAGE HILL LN',
        RegAddrCity: 'NORTH KINGSTOWN',
        RegAddrState: 'RI',
        RegAddrZip: 2852,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '30 PRINCESS PINE CT',
        RegAddrCity: 'NORTH KINGSTOWN',
        RegAddrState: 'RI',
        RegAddrZip: 2852,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '134 OLDE MILL LN',
        RegAddrCity: 'NORTH KINGSTOWN',
        RegAddrState: 'RI',
        RegAddrZip: 2852,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '245 KNIBB RD',
        RegAddrCity: 'PASCOAG',
        RegAddrState: 'RI',
        RegAddrZip: 2859,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '309 CENTENNIAL ST',
        RegAddrCity: 'PASCOAG',
        RegAddrState: 'RI',
        RegAddrZip: 2859,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1161 STEERE FARM RD',
        RegAddrCity: 'PASCOAG',
        RegAddrState: 'RI',
        RegAddrZip: 2859,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '445 PUBLIC ST',
        RegAddrCity: 'PROVIDENCE',
        RegAddrState: 'RI',
        RegAddrZip: 2907,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '383 BLACKSTONE ST',
        RegAddrCity: 'PROVIDENCE',
        RegAddrState: 'RI',
        RegAddrZip: 2907,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '323 BLACKSTONE ST',
        RegAddrCity: 'PROVIDENCE',
        RegAddrState: 'RI',
        RegAddrZip: 2907,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '615 MOUNT PLEASANT AVE',
        RegAddrCity: 'PROVIDENCE',
        RegAddrState: 'RI',
        RegAddrZip: 2908,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '128 CATHEDRAL AVE',
        RegAddrCity: 'PROVIDENCE',
        RegAddrState: 'RI',
        RegAddrZip: 2908,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '73 BERLIN ST',
        RegAddrCity: 'PROVIDENCE',
        RegAddrState: 'RI',
        RegAddrZip: 2908,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '259 MOUNT PLEASANT AVE',
        RegAddrCity: 'PROVIDENCE',
        RegAddrState: 'RI',
        RegAddrZip: 2908,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '200 MODENA AVE',
        RegAddrCity: 'PROVIDENCE',
        RegAddrState: 'RI',
        RegAddrZip: 2908,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '126 LAUREL HILL AVE',
        RegAddrCity: 'PROVIDENCE',
        RegAddrState: 'RI',
        RegAddrZip: 2909,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '58 IDA ST',
        RegAddrCity: 'PROVIDENCE',
        RegAddrState: 'RI',
        RegAddrZip: 2909,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '19 HAMMOND ST',
        RegAddrCity: 'PROVIDENCE',
        RegAddrState: 'RI',
        RegAddrZip: 2909,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '74 KING PHILIP ST',
        RegAddrCity: 'PROVIDENCE',
        RegAddrState: 'RI',
        RegAddrZip: 2909,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '18 CELIA ST',
        RegAddrCity: 'PROVIDENCE',
        RegAddrState: 'RI',
        RegAddrZip: 2909,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1198 WESTMINSTER ST',
        RegAddrCity: 'PROVIDENCE',
        RegAddrState: 'RI',
        RegAddrZip: 2909,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '263 WAVERLY ST',
        RegAddrCity: 'PROVIDENCE',
        RegAddrState: 'RI',
        RegAddrZip: 2909,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '215 ROOSEVELT ST',
        RegAddrCity: 'PROVIDENCE',
        RegAddrState: 'RI',
        RegAddrZip: 2909,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '26 SHANNOCK HILL RD',
        RegAddrCity: 'RICHMOND',
        RegAddrState: 'RI',
        RegAddrZip: 2812,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1 OLD SWITCH RD',
        RegAddrCity: 'RICHMOND',
        RegAddrState: 'RI',
        RegAddrZip: 2832,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '68 CLEARVIEW DR',
        RegAddrCity: 'RICHMOND',
        RegAddrState: 'RI',
        RegAddrZip: 2892,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '223 TABLE ROCK RD',
        RegAddrCity: 'S KINGSTOWN',
        RegAddrState: 'RI',
        RegAddrZip: 2879,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '185 DENDRON RD',
        RegAddrCity: 'S KINGSTOWN',
        RegAddrState: 'RI',
        RegAddrZip: 2879,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '668 MATUNUCK BEACH RD',
        RegAddrCity: 'S KINGSTOWN',
        RegAddrState: 'RI',
        RegAddrZip: 2879,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '290 KETTLE POND DR',
        RegAddrCity: 'S KINGSTOWN',
        RegAddrState: 'RI',
        RegAddrZip: 2879,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '196 KENYON AVE',
        RegAddrCity: 'S KINGSTOWN',
        RegAddrState: 'RI',
        RegAddrZip: 2879,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '223 HUNT AVE',
        RegAddrCity: 'S KINGSTOWN',
        RegAddrState: 'RI',
        RegAddrZip: 2879,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '47 SANDY BOTTOM SHORES DR',
        RegAddrCity: 'S KINGSTOWN',
        RegAddrState: 'RI',
        RegAddrZip: 2879,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1925 MATUNUCK SCHOOL HOUSE RD',
        RegAddrCity: 'S KINGSTOWN',
        RegAddrState: 'RI',
        RegAddrZip: 2879,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '56 SUGARBUSH TRL',
        RegAddrCity: 'SAUNDERSTOWN',
        RegAddrState: 'RI',
        RegAddrZip: 2874,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '97 HOWARD AVE',
        RegAddrCity: 'SCITUATE',
        RegAddrState: 'RI',
        RegAddrZip: 2831,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '35 RESERVATION DR',
        RegAddrCity: 'SCITUATE',
        RegAddrState: 'RI',
        RegAddrZip: 2831,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '10 MIDWAY ST',
        RegAddrCity: 'SCITUATE',
        RegAddrState: 'RI',
        RegAddrZip: 2857,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '15 COUNTRY VIEW LN',
        RegAddrCity: 'SCITUATE',
        RegAddrState: 'RI',
        RegAddrZip: 2857,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '699 DANIELSON PIKE',
        RegAddrCity: 'SCITUATE',
        RegAddrState: 'RI',
        RegAddrZip: 2857,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '167 HAMPTON WAY',
        RegAddrCity: 'SOUTH KINGSTOWN',
        RegAddrState: 'RI',
        RegAddrZip: 2879,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '50 WOODED GROVE CIR',
        RegAddrCity: 'SOUTH KINGSTOWN',
        RegAddrState: 'RI',
        RegAddrZip: 2892,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '10 BLUEBERRY HTS',
        RegAddrCity: 'W GREENWICH',
        RegAddrState: 'RI',
        RegAddrZip: 2817,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '43 SHARPE ST',
        RegAddrCity: 'W GREENWICH',
        RegAddrState: 'RI',
        RegAddrZip: 2817,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '119 HENRY BROWN RD',
        RegAddrCity: 'W GREENWICH',
        RegAddrState: 'RI',
        RegAddrZip: 2817,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '98 PLAIN MEETING HOUSE RD',
        RegAddrCity: 'W GREENWICH',
        RegAddrState: 'RI',
        RegAddrZip: 2817,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '102 WICKABOXET DR',
        RegAddrCity: 'W GREENWICH',
        RegAddrState: 'RI',
        RegAddrZip: 2817,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '356 ALLEN AVE',
        RegAddrCity: 'WAKEFIELD',
        RegAddrState: 'RI',
        RegAddrZip: 2879,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '902 HALIFAX DR',
        RegAddrCity: 'WARWICK',
        RegAddrState: 'RI',
        RegAddrZip: 2886,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '61 LUCILE ST',
        RegAddrCity: 'WARWICK',
        RegAddrState: 'RI',
        RegAddrZip: 2886,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '39 TIMBERLINE RD',
        RegAddrCity: 'WARWICK',
        RegAddrState: 'RI',
        RegAddrZip: 2886,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '4000 POST RD',
        RegAddrCity: 'WARWICK',
        RegAddrState: 'RI',
        RegAddrZip: 2886,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '122 GEORGE ARDEN AVE',
        RegAddrCity: 'WARWICK',
        RegAddrState: 'RI',
        RegAddrZip: 2886,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '24 OLD LYME DR',
        RegAddrCity: 'WARWICK',
        RegAddrState: 'RI',
        RegAddrZip: 2886,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '93 MYRTLE AVE',
        RegAddrCity: 'WARWICK',
        RegAddrState: 'RI',
        RegAddrZip: 2886,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '50 NORMANDY DR',
        RegAddrCity: 'WARWICK',
        RegAddrState: 'RI',
        RegAddrZip: 2886,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '75 BAKERS CREEK RD',
        RegAddrCity: 'WARWICK',
        RegAddrState: 'RI',
        RegAddrZip: 2886,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '235 CAPRON FARM DR',
        RegAddrCity: 'WARWICK',
        RegAddrState: 'RI',
        RegAddrZip: 2886,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '211 COUNTRY CLUB DR',
        RegAddrCity: 'WARWICK',
        RegAddrState: 'RI',
        RegAddrZip: 2888,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '270 POST RD',
        RegAddrCity: 'WARWICK',
        RegAddrState: 'RI',
        RegAddrZip: 2888,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '307 NORWOOD AVE',
        RegAddrCity: 'WARWICK',
        RegAddrState: 'RI',
        RegAddrZip: 2888,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '26 MAYFAIR RD',
        RegAddrCity: 'WARWICK',
        RegAddrState: 'RI',
        RegAddrZip: 2888,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '150 HEATH AVE',
        RegAddrCity: 'WARWICK',
        RegAddrState: 'RI',
        RegAddrZip: 2888,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '112 ABORN AVE',
        RegAddrCity: 'WARWICK',
        RegAddrState: 'RI',
        RegAddrZip: 2888,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '50 ASPINET DR',
        RegAddrCity: 'WARWICK',
        RegAddrState: 'RI',
        RegAddrZip: 2888,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '322 POST RD',
        RegAddrCity: 'WARWICK',
        RegAddrState: 'RI',
        RegAddrZip: 2888,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2020 ELMWOOD AVE',
        RegAddrCity: 'WARWICK',
        RegAddrState: 'RI',
        RegAddrZip: 2888,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '109 PARTITION ST',
        RegAddrCity: 'WARWICK',
        RegAddrState: 'RI',
        RegAddrZip: 2888,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '16 EAGLE AVE',
        RegAddrCity: 'WARWICK',
        RegAddrState: 'RI',
        RegAddrZip: 2889,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '20 DEFIANCE RD',
        RegAddrCity: 'WARWICK',
        RegAddrState: 'RI',
        RegAddrZip: 2889,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '29 COMMUNITY RD',
        RegAddrCity: 'WARWICK',
        RegAddrState: 'RI',
        RegAddrZip: 2889,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '55 YATES AVE',
        RegAddrCity: 'WARWICK',
        RegAddrState: 'RI',
        RegAddrZip: 2889,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '63 FLETCHER ST',
        RegAddrCity: 'WARWICK',
        RegAddrState: 'RI',
        RegAddrZip: 2889,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2 DAYTON AVE',
        RegAddrCity: 'WARWICK',
        RegAddrState: 'RI',
        RegAddrZip: 2889,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '332 GROVE AVE',
        RegAddrCity: 'WARWICK',
        RegAddrState: 'RI',
        RegAddrZip: 2889,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '93 TELL ST',
        RegAddrCity: 'WARWICK',
        RegAddrState: 'RI',
        RegAddrZip: 2889,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '17 DINSDALE CT',
        RegAddrCity: 'WEST WARWICK',
        RegAddrState: 'RI',
        RegAddrZip: 2893,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '97 SETIAN LN',
        RegAddrCity: 'WEST WARWICK',
        RegAddrState: 'RI',
        RegAddrZip: 2893,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '38 FAIRVIEW AVE',
        RegAddrCity: 'WEST WARWICK',
        RegAddrState: 'RI',
        RegAddrZip: 2893,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '141 PHENIX AVE',
        RegAddrCity: 'WEST WARWICK',
        RegAddrState: 'RI',
        RegAddrZip: 2893,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '490 WAKEFIELD ST',
        RegAddrCity: 'WEST WARWICK',
        RegAddrState: 'RI',
        RegAddrZip: 2893,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '11 MATTESON AVE',
        RegAddrCity: 'WEST WARWICK',
        RegAddrState: 'RI',
        RegAddrZip: 2893,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '3 BRANCH ST',
        RegAddrCity: 'WESTERLY',
        RegAddrState: 'RI',
        RegAddrZip: 2891,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '20 SARATOGA AVE',
        RegAddrCity: 'WESTERLY',
        RegAddrState: 'RI',
        RegAddrZip: 2891,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '31 JOHN ST',
        RegAddrCity: 'WESTERLY',
        RegAddrState: 'RI',
        RegAddrZip: 2891,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '39 HIGH ST',
        RegAddrCity: 'WESTERLY',
        RegAddrState: 'RI',
        RegAddrZip: 2891,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '19 2ND ST',
        RegAddrCity: 'WESTERLY',
        RegAddrState: 'RI',
        RegAddrZip: 2891,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '21 GALLUP ST',
        RegAddrCity: 'WESTERLY',
        RegAddrState: 'RI',
        RegAddrZip: 2891,
        CongressionalDistrict: 2,
      },
    ],
  },
  {
    name: 'SC-1',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/SC-1?ref=gh-pages',
    state: 'SC',
    state_and_number: 'SC-1',
    zip: 29401,
    congressionalDistrict: 1,
    addressesInDistrict: [
      {
        RegAddrLine1: '104 PALMER LN',
        RegAddrCity: 'BEAUFORT',
        RegAddrState: 'SC',
        RegAddrZip: 29902,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '9 MILLEDGE VILLAGE RD',
        RegAddrCity: 'BEAUFORT',
        RegAddrState: 'SC',
        RegAddrZip: 29906,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '164 BELLA WAY',
        RegAddrCity: 'BEAUFORT',
        RegAddrState: 'SC',
        RegAddrZip: 29906,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '12 MILLER DR W',
        RegAddrCity: 'BEAUFORT',
        RegAddrState: 'SC',
        RegAddrZip: 29907,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '10 MEETING ST',
        RegAddrCity: 'BEAUFORT',
        RegAddrState: 'SC',
        RegAddrZip: 29907,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '91 MUIRFIELD DR',
        RegAddrCity: 'BLUFFTON',
        RegAddrState: 'SC',
        RegAddrZip: 29909,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '8 DONCASTER LN',
        RegAddrCity: 'BLUFFTON',
        RegAddrState: 'SC',
        RegAddrZip: 29909,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '38 SAWMILL FOREST DR',
        RegAddrCity: 'BLUFFTON',
        RegAddrState: 'SC',
        RegAddrZip: 29910,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '244 WHITEOAKS CIR',
        RegAddrCity: 'BLUFFTON',
        RegAddrState: 'SC',
        RegAddrZip: 29910,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '80 FORDING CT',
        RegAddrCity: 'BLUFFTON',
        RegAddrState: 'SC',
        RegAddrZip: 29910,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '28 WENTWORTH DR',
        RegAddrCity: 'BLUFFTON',
        RegAddrState: 'SC',
        RegAddrZip: 29910,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '8 LIBERTY ST',
        RegAddrCity: 'CHARLESTON',
        RegAddrState: 'SC',
        RegAddrZip: 29401,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1379 PARKSHORE DR',
        RegAddrCity: 'CHARLESTON',
        RegAddrState: 'SC',
        RegAddrZip: 29407,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1612 SEIGNIOUS DR',
        RegAddrCity: 'CHARLESTON',
        RegAddrState: 'SC',
        RegAddrZip: 29407,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1122 BROWNING RD',
        RegAddrCity: 'CHARLESTON',
        RegAddrState: 'SC',
        RegAddrZip: 29407,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1034 JAMSIE COVE DR',
        RegAddrCity: 'CHARLESTON',
        RegAddrState: 'SC',
        RegAddrZip: 29412,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1355 FIDDLE WAY',
        RegAddrCity: 'CHARLESTON',
        RegAddrState: 'SC',
        RegAddrZip: 29412,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1205 SEASIDE PLANTATION DR',
        RegAddrCity: 'CHARLESTON',
        RegAddrState: 'SC',
        RegAddrZip: 29412,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1365 PINNACLE LN',
        RegAddrCity: 'CHARLESTON',
        RegAddrState: 'SC',
        RegAddrZip: 29412,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '703 LONDON DR',
        RegAddrCity: 'CHARLESTON',
        RegAddrState: 'SC',
        RegAddrZip: 29412,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2920 CATHEDRAL LN',
        RegAddrCity: 'CHARLESTON',
        RegAddrState: 'SC',
        RegAddrZip: 29414,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '3100 ASHLEY TOWN CENTER DR',
        RegAddrCity: 'CHARLESTON',
        RegAddrState: 'SC',
        RegAddrZip: 29414,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2311 TREESCAPE DR',
        RegAddrCity: 'CHARLESTON',
        RegAddrState: 'SC',
        RegAddrZip: 29414,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '410 HUTTY ST',
        RegAddrCity: 'CHARLESTON',
        RegAddrState: 'SC',
        RegAddrZip: 29492,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '29 CRYSTAL SPRINGS DR',
        RegAddrCity: 'GOOSE CREEK',
        RegAddrState: 'SC',
        RegAddrZip: 29445,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '153 FOXBOROUGH RD',
        RegAddrCity: 'GOOSE CREEK',
        RegAddrState: 'SC',
        RegAddrZip: 29445,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '800 SNOW GOOSE CIR',
        RegAddrCity: 'GOOSE CREEK',
        RegAddrState: 'SC',
        RegAddrZip: 29445,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '604 GREENCASTLE DR',
        RegAddrCity: 'GOOSE CREEK',
        RegAddrState: 'SC',
        RegAddrZip: 29445,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '327 RIVERSIDE CIR',
        RegAddrCity: 'GOOSE CREEK',
        RegAddrState: 'SC',
        RegAddrZip: 29445,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '30 CRYSTAL SPRINGS DR',
        RegAddrCity: 'GOOSE CREEK',
        RegAddrState: 'SC',
        RegAddrZip: 29445,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1204 WESTWARD DR',
        RegAddrCity: 'GOOSE CREEK',
        RegAddrState: 'SC',
        RegAddrZip: 29445,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '104 CARLYLE ST',
        RegAddrCity: 'GOOSE CREEK',
        RegAddrState: 'SC',
        RegAddrZip: 29445,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1075 SPRING VALLEY DR',
        RegAddrCity: 'HANAHAN',
        RegAddrState: 'SC',
        RegAddrZip: 29410,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '42 N BASILICA AVE',
        RegAddrCity: 'HANAHAN',
        RegAddrState: 'SC',
        RegAddrZip: 29410,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1084 STONEHENGE DR',
        RegAddrCity: 'HANAHAN',
        RegAddrState: 'SC',
        RegAddrZip: 29410,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1008 SPRING VALLEY DR',
        RegAddrCity: 'HANAHAN',
        RegAddrState: 'SC',
        RegAddrZip: 29410,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '52 CROSSTREE DR',
        RegAddrCity: 'HILTON HEAD',
        RegAddrState: 'SC',
        RegAddrZip: 29926,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1 EAGIN CT',
        RegAddrCity: 'HILTON HEAD',
        RegAddrState: 'SC',
        RegAddrZip: 29926,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '4 ELMWOOD CT',
        RegAddrCity: 'HILTON HEAD',
        RegAddrState: 'SC',
        RegAddrZip: 29926,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '97 FOREST CV',
        RegAddrCity: 'HILTON HEAD',
        RegAddrState: 'SC',
        RegAddrZip: 29928,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '61 BIG WOODS DR',
        RegAddrCity: 'HILTON HEAD ISLAND',
        RegAddrState: 'SC',
        RegAddrZip: 29926,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '14 HERITAGE CT',
        RegAddrCity: 'HILTON HEAD ISLAND',
        RegAddrState: 'SC',
        RegAddrZip: 29928,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '3191 TIMBERLINE DR',
        RegAddrCity: 'JOHNS ISLAND',
        RegAddrState: 'SC',
        RegAddrZip: 29455,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '3110 LEGAREVILLE RD',
        RegAddrCity: 'JOHNS ISLAND',
        RegAddrState: 'SC',
        RegAddrZip: 29455,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '116 SAW PALM DR',
        RegAddrCity: 'LADSON',
        RegAddrState: 'SC',
        RegAddrZip: 29456,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '9468 DOWNY BOG CT',
        RegAddrCity: 'LADSON',
        RegAddrState: 'SC',
        RegAddrZip: 29456,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '165 TALL PINES RD',
        RegAddrCity: 'LADSON',
        RegAddrState: 'SC',
        RegAddrZip: 29456,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1312 OLD FORT RD',
        RegAddrCity: 'MONCKS CORNER',
        RegAddrState: 'SC',
        RegAddrZip: 29461,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '211 GRACELAND DR',
        RegAddrCity: 'MONCKS CORNER',
        RegAddrState: 'SC',
        RegAddrZip: 29461,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '526 SWEET BAY LN',
        RegAddrCity: 'MONCKS CORNER',
        RegAddrState: 'SC',
        RegAddrZip: 29461,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1207 VENTURA PL',
        RegAddrCity: 'MT PLEASANT',
        RegAddrState: 'SC',
        RegAddrZip: 29464,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '686 CAIN DR',
        RegAddrCity: 'MT PLEASANT',
        RegAddrState: 'SC',
        RegAddrZip: 29464,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1609 CAMBRIDGE LAKES DR',
        RegAddrCity: 'MT PLEASANT',
        RegAddrState: 'SC',
        RegAddrZip: 29464,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '405 KING ST',
        RegAddrCity: 'MT PLEASANT',
        RegAddrState: 'SC',
        RegAddrZip: 29464,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2483 COTTON CREEK DR',
        RegAddrCity: 'MT PLEASANT',
        RegAddrState: 'SC',
        RegAddrZip: 29466,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '3488 KAYAK LN',
        RegAddrCity: 'MT PLEASANT',
        RegAddrState: 'SC',
        RegAddrZip: 29466,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1548 OAKHURST DR',
        RegAddrCity: 'MT PLEASANT',
        RegAddrState: 'SC',
        RegAddrZip: 29466,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '3352 BILLINGS ST',
        RegAddrCity: 'MT PLEASANT',
        RegAddrState: 'SC',
        RegAddrZip: 29466,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1565 BRYDEN LN',
        RegAddrCity: 'MT PLEASANT',
        RegAddrState: 'SC',
        RegAddrZip: 29466,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '8835 JENNY LIND ST',
        RegAddrCity: 'N CHARLESTON',
        RegAddrState: 'SC',
        RegAddrZip: 29406,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '8166 SHERBROOKE LN',
        RegAddrCity: 'N CHARLESTON',
        RegAddrState: 'SC',
        RegAddrZip: 29418,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '4218 SAWGRASS DR',
        RegAddrCity: 'N CHARLESTON',
        RegAddrState: 'SC',
        RegAddrZip: 29420,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '8345 LOGGERS RUN',
        RegAddrCity: 'N CHARLESTON',
        RegAddrState: 'SC',
        RegAddrZip: 29420,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '652 S REEVE RD',
        RegAddrCity: 'ST HELENA IS',
        RegAddrState: 'SC',
        RegAddrZip: 29920,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '34 MELODY LN',
        RegAddrCity: 'ST HELENA IS',
        RegAddrState: 'SC',
        RegAddrZip: 29920,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '820 ISLAND CIR W',
        RegAddrCity: 'ST HELENA IS',
        RegAddrState: 'SC',
        RegAddrZip: 29920,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '115 CADY DR',
        RegAddrCity: 'SUMMERVILLE',
        RegAddrState: 'SC',
        RegAddrZip: 29483,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1026 PROVIDENCE WAY',
        RegAddrCity: 'SUMMERVILLE',
        RegAddrState: 'SC',
        RegAddrZip: 29483,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '179 GREYBACK RD',
        RegAddrCity: 'SUMMERVILLE',
        RegAddrState: 'SC',
        RegAddrZip: 29483,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '305 PINE BLUFF DR',
        RegAddrCity: 'SUMMERVILLE',
        RegAddrState: 'SC',
        RegAddrZip: 29483,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2012 FARM SPRINGS RD',
        RegAddrCity: 'SUMMERVILLE',
        RegAddrState: 'SC',
        RegAddrZip: 29483,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '125 HAMMERBECK RD',
        RegAddrCity: 'SUMMERVILLE',
        RegAddrState: 'SC',
        RegAddrZip: 29483,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '5028 BLAIR RD',
        RegAddrCity: 'SUMMERVILLE',
        RegAddrState: 'SC',
        RegAddrZip: 29483,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '124 BLACKWATER DR',
        RegAddrCity: 'SUMMERVILLE',
        RegAddrState: 'SC',
        RegAddrZip: 29485,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '313 WYNFIELD FOREST DR',
        RegAddrCity: 'SUMMERVILLE',
        RegAddrState: 'SC',
        RegAddrZip: 29485,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '9128 FIELDSTONE TRCE',
        RegAddrCity: 'SUMMERVILLE',
        RegAddrState: 'SC',
        RegAddrZip: 29485,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '106 WHITE PINE WAY',
        RegAddrCity: 'SUMMERVILLE',
        RegAddrState: 'SC',
        RegAddrZip: 29485,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '5018 WHITFIELD CT',
        RegAddrCity: 'SUMMERVILLE',
        RegAddrState: 'SC',
        RegAddrZip: 29485,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '200 BOONE DR',
        RegAddrCity: 'SUMMERVILLE',
        RegAddrState: 'SC',
        RegAddrZip: 29485,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '222 OLD BRIDGE LN',
        RegAddrCity: 'SUMMERVILLE',
        RegAddrState: 'SC',
        RegAddrZip: 29485,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '118 CARMON ST',
        RegAddrCity: 'SUMMERVILLE',
        RegAddrState: 'SC',
        RegAddrZip: 29485,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '405 STRATFORD DR',
        RegAddrCity: 'SUMMERVILLE',
        RegAddrState: 'SC',
        RegAddrZip: 29485,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '115 ASHWOOD DR',
        RegAddrCity: 'SUMMERVILLE',
        RegAddrState: 'SC',
        RegAddrZip: 29486,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '104 SCHOONER BEND AVE',
        RegAddrCity: 'SUMMERVILLE',
        RegAddrState: 'SC',
        RegAddrZip: 29486,
        CongressionalDistrict: 1,
      },
    ],
  },
  {
    name: 'SC-2',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/SC-2?ref=gh-pages',
    state: 'SC',
    state_and_number: 'SC-2',
    zip: 29006,
    congressionalDistrict: 2,
    addressesInDistrict: [
      {
        RegAddrLine1: '1013 CHATFIELD ST NE',
        RegAddrCity: 'AIKEN',
        RegAddrState: 'SC',
        RegAddrZip: 29801,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '356 YORK ST NE',
        RegAddrCity: 'AIKEN',
        RegAddrState: 'SC',
        RegAddrZip: 29801,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '112 HILLHEAD CT',
        RegAddrCity: 'AIKEN',
        RegAddrState: 'SC',
        RegAddrZip: 29801,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '103 KALMIA FOREST DR',
        RegAddrCity: 'AIKEN',
        RegAddrState: 'SC',
        RegAddrZip: 29801,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '445 KERSHAW PL SE',
        RegAddrCity: 'AIKEN',
        RegAddrState: 'SC',
        RegAddrZip: 29801,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '129 FOX TRACE CT',
        RegAddrCity: 'AIKEN',
        RegAddrState: 'SC',
        RegAddrZip: 29803,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '132 RIVIERA RD',
        RegAddrCity: 'AIKEN',
        RegAddrState: 'SC',
        RegAddrZip: 29803,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '940 WATSONIA DR',
        RegAddrCity: 'AIKEN',
        RegAddrState: 'SC',
        RegAddrZip: 29803,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '337 LAKESIDE DR',
        RegAddrCity: 'AIKEN',
        RegAddrState: 'SC',
        RegAddrZip: 29803,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '121 CHELTENHAM DR',
        RegAddrCity: 'AIKEN',
        RegAddrState: 'SC',
        RegAddrZip: 29803,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '135 CONWAY RD',
        RegAddrCity: 'BARNWELL',
        RegAddrState: 'SC',
        RegAddrZip: 29812,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '114 HENRY ST',
        RegAddrCity: 'BATESBURG',
        RegAddrState: 'SC',
        RegAddrZip: 29006,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '493 PEPPER BRANCH RD',
        RegAddrCity: 'BEECH ISLAND',
        RegAddrState: 'SC',
        RegAddrZip: 29842,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2053 MANCHESTER ST',
        RegAddrCity: 'BEECH ISLAND',
        RegAddrState: 'SC',
        RegAddrZip: 29842,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '469 WILLISTON RD',
        RegAddrCity: 'BEECH ISLAND',
        RegAddrState: 'SC',
        RegAddrZip: 29842,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '313 SERPENTINE DR',
        RegAddrCity: 'BELVEDERE',
        RegAddrState: 'SC',
        RegAddrZip: 29841,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '6822 BARNWELL RD',
        RegAddrCity: 'BLACKVILLE',
        RegAddrState: 'SC',
        RegAddrZip: 29817,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1079 COOGLER CROSSING DR',
        RegAddrCity: 'BLYTHEWOOD',
        RegAddrState: 'SC',
        RegAddrZip: 29016,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '224 CAMP AGAPE RD',
        RegAddrCity: 'BLYTHEWOOD',
        RegAddrState: 'SC',
        RegAddrZip: 29016,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '400 SHADY LN',
        RegAddrCity: 'CAYCE',
        RegAddrState: 'SC',
        RegAddrZip: 29033,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1416 DUNBAR RD',
        RegAddrCity: 'CAYCE',
        RegAddrState: 'SC',
        RegAddrZip: 29033,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1109 SILVER POINT RD',
        RegAddrCity: 'CHAPIN',
        RegAddrState: 'SC',
        RegAddrZip: 29036,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '105 MILLS LAKE CT',
        RegAddrCity: 'CHAPIN',
        RegAddrState: 'SC',
        RegAddrZip: 29036,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '3935 DEVEREAUX RD',
        RegAddrCity: 'COLUMBIA',
        RegAddrState: 'SC',
        RegAddrZip: 29205,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '3700 OAKLEAF RD',
        RegAddrCity: 'COLUMBIA',
        RegAddrState: 'SC',
        RegAddrZip: 29206,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1338 SINKLER RD',
        RegAddrCity: 'COLUMBIA',
        RegAddrState: 'SC',
        RegAddrZip: 29206,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '5066 MCWHORTER CT',
        RegAddrCity: 'COLUMBIA',
        RegAddrState: 'SC',
        RegAddrZip: 29206,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '6120 KEMBERLY ST',
        RegAddrCity: 'COLUMBIA',
        RegAddrState: 'SC',
        RegAddrZip: 29209,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '11 PAPERBARK CT',
        RegAddrCity: 'COLUMBIA',
        RegAddrState: 'SC',
        RegAddrZip: 29209,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '400 OLD WOODLANDS RD',
        RegAddrCity: 'COLUMBIA',
        RegAddrState: 'SC',
        RegAddrZip: 29209,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '889 GARDENDALE DR',
        RegAddrCity: 'COLUMBIA',
        RegAddrState: 'SC',
        RegAddrZip: 29210,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '303 WINDING WAY',
        RegAddrCity: 'COLUMBIA',
        RegAddrState: 'SC',
        RegAddrZip: 29212,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '111 OLD HOME PL',
        RegAddrCity: 'COLUMBIA',
        RegAddrState: 'SC',
        RegAddrZip: 29212,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '102 STAFFORD RD',
        RegAddrCity: 'COLUMBIA',
        RegAddrState: 'SC',
        RegAddrZip: 29223,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '236 PHILMONT DR',
        RegAddrCity: 'COLUMBIA',
        RegAddrState: 'SC',
        RegAddrZip: 29223,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '25 HARRINGTON CT',
        RegAddrCity: 'COLUMBIA',
        RegAddrState: 'SC',
        RegAddrZip: 29223,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '105 THORNFIELD RD',
        RegAddrCity: 'COLUMBIA',
        RegAddrState: 'SC',
        RegAddrZip: 29229,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '336 ELDERS POND CIR',
        RegAddrCity: 'COLUMBIA',
        RegAddrState: 'SC',
        RegAddrZip: 29229,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1915 CANNON BRIDGE RD',
        RegAddrCity: 'CORDOVA',
        RegAddrState: 'SC',
        RegAddrZip: 29039,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1343 NE MILES RD',
        RegAddrCity: 'ELGIN',
        RegAddrState: 'SC',
        RegAddrZip: 29045,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1104 TAMWOOD WAY',
        RegAddrCity: 'ELGIN',
        RegAddrState: 'SC',
        RegAddrZip: 29045,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '108 ROBIN HEIGHTS RD',
        RegAddrCity: 'ELGIN',
        RegAddrState: 'SC',
        RegAddrZip: 29045,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '110 STAGECOACH RD',
        RegAddrCity: 'GASTON',
        RegAddrState: 'SC',
        RegAddrZip: 29053,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '620 BOY SCOUT RD',
        RegAddrCity: 'GASTON',
        RegAddrState: 'SC',
        RegAddrZip: 29053,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '141 CONNECTOR RD',
        RegAddrCity: 'GRANITEVILLE',
        RegAddrState: 'SC',
        RegAddrZip: 29829,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '128 BUCKHEAD DR',
        RegAddrCity: 'IRMO',
        RegAddrState: 'SC',
        RegAddrZip: 29063,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '42 PALMETTO WOOD CT',
        RegAddrCity: 'IRMO',
        RegAddrState: 'SC',
        RegAddrZip: 29063,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '214 BARGER CIR',
        RegAddrCity: 'IRMO',
        RegAddrState: 'SC',
        RegAddrZip: 29063,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '422 BEECH BRANCH DR',
        RegAddrCity: 'IRMO',
        RegAddrState: 'SC',
        RegAddrZip: 29063,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '509 WHITEWATER DR',
        RegAddrCity: 'IRMO',
        RegAddrState: 'SC',
        RegAddrZip: 29063,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '743 CHADFORD RD',
        RegAddrCity: 'IRMO',
        RegAddrState: 'SC',
        RegAddrZip: 29063,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '286 BROWN RD',
        RegAddrCity: 'JACKSON',
        RegAddrState: 'SC',
        RegAddrZip: 29831,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '232 DIXIELAND TRL',
        RegAddrCity: 'LEESVILLE',
        RegAddrState: 'SC',
        RegAddrZip: 29070,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '354 PEACE ST',
        RegAddrCity: 'LEESVILLE',
        RegAddrState: 'SC',
        RegAddrZip: 29070,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '116 GANDER CT',
        RegAddrCity: 'LEXINGTON',
        RegAddrState: 'SC',
        RegAddrZip: 29072,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '652 BRAEKEL WAY',
        RegAddrCity: 'LEXINGTON',
        RegAddrState: 'SC',
        RegAddrZip: 29072,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '220 PAYNE LN',
        RegAddrCity: 'LEXINGTON',
        RegAddrState: 'SC',
        RegAddrZip: 29072,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '101 LAUREL DR',
        RegAddrCity: 'LEXINGTON',
        RegAddrState: 'SC',
        RegAddrZip: 29072,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '421 BUCKTHORNE DR',
        RegAddrCity: 'LEXINGTON',
        RegAddrState: 'SC',
        RegAddrZip: 29072,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '663 SPRING HILL RD',
        RegAddrCity: 'LEXINGTON',
        RegAddrState: 'SC',
        RegAddrZip: 29072,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '105 KEYSTONE CT',
        RegAddrCity: 'LEXINGTON',
        RegAddrState: 'SC',
        RegAddrZip: 29073,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '188 DEER MOSS TRL',
        RegAddrCity: 'LEXINGTON',
        RegAddrState: 'SC',
        RegAddrZip: 29073,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '205 SYCAMORE TREE RD',
        RegAddrCity: 'LEXINGTON',
        RegAddrState: 'SC',
        RegAddrZip: 29073,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '100 HIDDEN VALLEY DR',
        RegAddrCity: 'LEXINGTON',
        RegAddrState: 'SC',
        RegAddrZip: 29073,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1548 NAZARETH RD',
        RegAddrCity: 'LEXINGTON',
        RegAddrState: 'SC',
        RegAddrZip: 29073,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '201 CRESTWOOD ARCH',
        RegAddrCity: 'LEXINGTON',
        RegAddrState: 'SC',
        RegAddrZip: 29073,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '235 ASHTON CIR',
        RegAddrCity: 'LEXINGTON',
        RegAddrState: 'SC',
        RegAddrZip: 29073,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1251 WEST AVE',
        RegAddrCity: 'NORTH AUGUSTA',
        RegAddrState: 'SC',
        RegAddrZip: 29841,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '201 REDBUD DR',
        RegAddrCity: 'NORTH AUGUSTA',
        RegAddrState: 'SC',
        RegAddrZip: 29860,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '137 YONCE RD',
        RegAddrCity: 'PELION',
        RegAddrState: 'SC',
        RegAddrZip: 29123,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '324 HIGHWAY 178',
        RegAddrCity: 'PELION',
        RegAddrState: 'SC',
        RegAddrZip: 29123,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '245 TAMMARON LN',
        RegAddrCity: 'SPRINGFIELD',
        RegAddrState: 'SC',
        RegAddrZip: 29146,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '923 SAINT MATTHEWS RD',
        RegAddrCity: 'SWANSEA',
        RegAddrState: 'SC',
        RegAddrZip: 29160,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '718 LAIRDS HILL RD',
        RegAddrCity: 'SWANSEA',
        RegAddrState: 'SC',
        RegAddrZip: 29160,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '124 SALUDA WOODS CT',
        RegAddrCity: 'WEST COLUMBIA',
        RegAddrState: 'SC',
        RegAddrZip: 29169,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '120 REM CT',
        RegAddrCity: 'WEST COLUMBIA',
        RegAddrState: 'SC',
        RegAddrZip: 29169,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1122 BROOKWOOD CIR',
        RegAddrCity: 'WEST COLUMBIA',
        RegAddrState: 'SC',
        RegAddrZip: 29169,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '428 DICKSON HILL CIR',
        RegAddrCity: 'WEST COLUMBIA',
        RegAddrState: 'SC',
        RegAddrZip: 29170,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '112 PINEHURST CT',
        RegAddrCity: 'WEST COLUMBIA',
        RegAddrState: 'SC',
        RegAddrZip: 29170,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '118 KINGHILL DR',
        RegAddrCity: 'WEST COLUMBIA',
        RegAddrState: 'SC',
        RegAddrZip: 29172,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '3900 HIGHWAY 321',
        RegAddrCity: 'WEST COLUMBIA',
        RegAddrState: 'SC',
        RegAddrZip: 29172,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1240 LILLIE AVE',
        RegAddrCity: 'WEST COLUMBIA',
        RegAddrState: 'SC',
        RegAddrZip: 29172,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '217 JAYWOOD RD',
        RegAddrCity: 'WILLISTON',
        RegAddrState: 'SC',
        RegAddrZip: 29853,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '171 LELAND ST',
        RegAddrCity: 'WILLISTON',
        RegAddrState: 'SC',
        RegAddrZip: 29853,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '123 ANGEL LN',
        RegAddrCity: 'WINDSOR',
        RegAddrState: 'SC',
        RegAddrZip: 29856,
        CongressionalDistrict: 2,
      },
    ],
  },
  {
    name: 'SC-3',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/SC-3?ref=gh-pages',
    state: 'SC',
    state_and_number: 'SC-3',
    zip: 29037,
    congressionalDistrict: 3,
    addressesInDistrict: [
      {
        RegAddrLine1: '802 RAINBOW RD',
        RegAddrCity: 'ANDERSON',
        RegAddrState: 'SC',
        RegAddrZip: 29621,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '138 EASY GAP RD',
        RegAddrCity: 'ANDERSON',
        RegAddrState: 'SC',
        RegAddrZip: 29621,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1123 HURRICANE CIR',
        RegAddrCity: 'ANDERSON',
        RegAddrState: 'SC',
        RegAddrZip: 29621,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '3634 KEYS ST',
        RegAddrCity: 'ANDERSON',
        RegAddrState: 'SC',
        RegAddrZip: 29624,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '607 ASHWOOD DR',
        RegAddrCity: 'ANDERSON',
        RegAddrState: 'SC',
        RegAddrZip: 29624,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '101 CEDAR RD',
        RegAddrCity: 'ANDERSON',
        RegAddrState: 'SC',
        RegAddrZip: 29624,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '2440 WHITEHALL RD',
        RegAddrCity: 'ANDERSON',
        RegAddrState: 'SC',
        RegAddrZip: 29625,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '143 SOPHIE LN',
        RegAddrCity: 'ANDERSON',
        RegAddrState: 'SC',
        RegAddrZip: 29625,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '200 NORTH AVE',
        RegAddrCity: 'ANDERSON',
        RegAddrState: 'SC',
        RegAddrZip: 29625,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '115 WILSON RD',
        RegAddrCity: 'ANDERSON',
        RegAddrState: 'SC',
        RegAddrZip: 29625,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '30 FISHER JENKINS RD',
        RegAddrCity: 'ANDERSON',
        RegAddrState: 'SC',
        RegAddrZip: 29625,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '113 PINERIDGE DR',
        RegAddrCity: 'BELTON',
        RegAddrState: 'SC',
        RegAddrZip: 29627,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '106 SAN LUCAS LN',
        RegAddrCity: 'BELTON',
        RegAddrState: 'SC',
        RegAddrZip: 29627,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '151 MAJOR MEADOWS RD',
        RegAddrCity: 'BELTON',
        RegAddrState: 'SC',
        RegAddrZip: 29627,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '205 MOUNTAIN VIEW LN',
        RegAddrCity: 'CLEMSON',
        RegAddrState: 'SC',
        RegAddrZip: 29631,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '106 POOLE LN',
        RegAddrCity: 'CLEMSON',
        RegAddrState: 'SC',
        RegAddrZip: 29631,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '102 VINEYARD RD',
        RegAddrCity: 'CLEMSON',
        RegAddrState: 'SC',
        RegAddrZip: 29631,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '6599 HIGHWAY 252',
        RegAddrCity: 'DONALDS',
        RegAddrState: 'SC',
        RegAddrZip: 29638,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '241 27TH ST',
        RegAddrCity: 'EASLEY',
        RegAddrState: 'SC',
        RegAddrZip: 29640,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '200 ROLLINGWOOD WAY',
        RegAddrCity: 'EASLEY',
        RegAddrState: 'SC',
        RegAddrZip: 29640,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '553 HUNTS BRIDGE RD',
        RegAddrCity: 'EASLEY',
        RegAddrState: 'SC',
        RegAddrZip: 29640,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '122 MERRITT DR',
        RegAddrCity: 'EASLEY',
        RegAddrState: 'SC',
        RegAddrZip: 29642,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '147 RED MAPLE CIR',
        RegAddrCity: 'EASLEY',
        RegAddrState: 'SC',
        RegAddrZip: 29642,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '504 CARDINAL WOODS WAY',
        RegAddrCity: 'EASLEY',
        RegAddrState: 'SC',
        RegAddrZip: 29642,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '305 FRANK ST',
        RegAddrCity: 'EASLEY',
        RegAddrState: 'SC',
        RegAddrZip: 29642,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '144 ARMISTEAD LN',
        RegAddrCity: 'EASLEY',
        RegAddrState: 'SC',
        RegAddrZip: 29642,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '222 SELSEA DR',
        RegAddrCity: 'EASLEY',
        RegAddrState: 'SC',
        RegAddrZip: 29642,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '109 HARROGATE LN',
        RegAddrCity: 'EASLEY',
        RegAddrState: 'SC',
        RegAddrZip: 29642,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '421 SPIRIT MOUNTAIN LN',
        RegAddrCity: 'EASLEY',
        RegAddrState: 'SC',
        RegAddrZip: 29642,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '234 PICKENS ST',
        RegAddrCity: 'EDGEFIELD',
        RegAddrState: 'SC',
        RegAddrZip: 29824,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1057 US HIGHWAY 25 N',
        RegAddrCity: 'EDGEFIELD',
        RegAddrState: 'SC',
        RegAddrZip: 29824,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '275 SHELOR FERRY RD',
        RegAddrCity: 'FAIR PLAY',
        RegAddrState: 'SC',
        RegAddrZip: 29643,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '2488 GREENPOND RD',
        RegAddrCity: 'FOUNTAIN INN',
        RegAddrState: 'SC',
        RegAddrZip: 29644,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '200 IVY WOODS CT',
        RegAddrCity: 'FOUNTAIN INN',
        RegAddrState: 'SC',
        RegAddrZip: 29644,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '205 PEPPER MILL RD',
        RegAddrCity: 'GRAY COURT',
        RegAddrState: 'SC',
        RegAddrZip: 29645,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '218 RIDGE BAY CT',
        RegAddrCity: 'GREENVILLE',
        RegAddrState: 'SC',
        RegAddrZip: 29611,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '147 HACKETT AVE',
        RegAddrCity: 'GREENWOOD',
        RegAddrState: 'SC',
        RegAddrZip: 29646,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '108 SCOTCH CROSS RD E',
        RegAddrCity: 'GREENWOOD',
        RegAddrState: 'SC',
        RegAddrZip: 29646,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '211 SCOTCH CROSS RD E',
        RegAddrCity: 'GREENWOOD',
        RegAddrState: 'SC',
        RegAddrZip: 29646,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '900 BRIARWOOD RD',
        RegAddrCity: 'GREENWOOD',
        RegAddrState: 'SC',
        RegAddrZip: 29646,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '996 EDGEFIELD ST',
        RegAddrCity: 'GREENWOOD',
        RegAddrState: 'SC',
        RegAddrZip: 29646,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '820 REYNOLDS AVE',
        RegAddrCity: 'GREENWOOD',
        RegAddrState: 'SC',
        RegAddrZip: 29649,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '103 FULTON ST',
        RegAddrCity: 'GREENWOOD',
        RegAddrState: 'SC',
        RegAddrZip: 29649,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '120 FRENCH WOODS RD',
        RegAddrCity: 'HONEA PATH',
        RegAddrState: 'SC',
        RegAddrZip: 29654,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '5183 KEOWEE RD',
        RegAddrCity: 'HONEA PATH',
        RegAddrState: 'SC',
        RegAddrZip: 29654,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '4508 HIGHWAY 201',
        RegAddrCity: 'IVA',
        RegAddrState: 'SC',
        RegAddrZip: 29655,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1010 PROJECT RD',
        RegAddrCity: 'IVA',
        RegAddrState: 'SC',
        RegAddrZip: 29655,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '833 WARREN WATT RD',
        RegAddrCity: 'IVA',
        RegAddrState: 'SC',
        RegAddrZip: 29655,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '414 BRANDWOOD LN',
        RegAddrCity: 'LAURENS',
        RegAddrState: 'SC',
        RegAddrZip: 29360,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '200 SAMARITAN DR',
        RegAddrCity: 'LAURENS',
        RegAddrState: 'SC',
        RegAddrZip: 29360,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '4618 S HARPER STREET EXT',
        RegAddrCity: 'LAURENS',
        RegAddrState: 'SC',
        RegAddrZip: 29360,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '808 PRATERS CREEK RD',
        RegAddrCity: 'LIBERTY',
        RegAddrState: 'SC',
        RegAddrZip: 29657,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '301 GILLESPIE RD',
        RegAddrCity: 'LIBERTY',
        RegAddrState: 'SC',
        RegAddrZip: 29657,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '387 RICES CREEK CHURCH RD',
        RegAddrCity: 'LIBERTY',
        RegAddrState: 'SC',
        RegAddrZip: 29657,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '117 PINE KNL',
        RegAddrCity: 'LIBERTY',
        RegAddrState: 'SC',
        RegAddrZip: 29657,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '121 BAYWOOD CIR',
        RegAddrCity: 'NINETY SIX',
        RegAddrState: 'SC',
        RegAddrZip: 29666,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '117 PINEHAVEN DR',
        RegAddrCity: 'NINETY SIX',
        RegAddrState: 'SC',
        RegAddrZip: 29666,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '345 BRIGGS RD',
        RegAddrCity: 'NORTH AUGUSTA',
        RegAddrState: 'SC',
        RegAddrZip: 29860,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '325 DAVIS RD',
        RegAddrCity: 'PELZER',
        RegAddrState: 'SC',
        RegAddrZip: 29669,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '778 BELLE SHOALS RD',
        RegAddrCity: 'PICKENS',
        RegAddrState: 'SC',
        RegAddrZip: 29671,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '142 CHEROKEE HILLS DR',
        RegAddrCity: 'PICKENS',
        RegAddrState: 'SC',
        RegAddrZip: 29671,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '237 HELEN DR',
        RegAddrCity: 'PIEDMONT',
        RegAddrState: 'SC',
        RegAddrZip: 29673,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '211 CARDINGTON AVE',
        RegAddrCity: 'PIEDMONT',
        RegAddrState: 'SC',
        RegAddrZip: 29673,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '25 GALLMAN RD',
        RegAddrCity: 'PROSPERITY',
        RegAddrState: 'SC',
        RegAddrZip: 29127,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '177 HOLSONBACK DR',
        RegAddrCity: 'PROSPERITY',
        RegAddrState: 'SC',
        RegAddrZip: 29127,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '618 PENCREEK RD',
        RegAddrCity: 'SALUDA',
        RegAddrState: 'SC',
        RegAddrZip: 29138,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '206 JOHNSON COVE RD',
        RegAddrCity: 'SENECA',
        RegAddrState: 'SC',
        RegAddrZip: 29672,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '214 ASHTON PLACE CT',
        RegAddrCity: 'SENECA',
        RegAddrState: 'SC',
        RegAddrZip: 29678,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '143 ROTHELL DR',
        RegAddrCity: 'SENECA',
        RegAddrState: 'SC',
        RegAddrZip: 29678,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '478 TURNER RD',
        RegAddrCity: 'SENECA',
        RegAddrState: 'SC',
        RegAddrZip: 29678,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '217 CORONADO CT',
        RegAddrCity: 'SIMPSONVILLE',
        RegAddrState: 'SC',
        RegAddrZip: 29680,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '310 SCHOONER CT',
        RegAddrCity: 'SIMPSONVILLE',
        RegAddrState: 'SC',
        RegAddrZip: 29680,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '204 COMMON OAKS CT',
        RegAddrCity: 'SIMPSONVILLE',
        RegAddrState: 'SC',
        RegAddrZip: 29681,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '425 LEE VAUGHN RD',
        RegAddrCity: 'SIMPSONVILLE',
        RegAddrState: 'SC',
        RegAddrZip: 29681,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '153 STEWART RD',
        RegAddrCity: 'SIX MILE',
        RegAddrState: 'SC',
        RegAddrZip: 29682,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '2420 RAINEY RD',
        RegAddrCity: 'STARR',
        RegAddrState: 'SC',
        RegAddrZip: 29684,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '324 ODELL SHOALS RD',
        RegAddrCity: 'WALHALLA',
        RegAddrState: 'SC',
        RegAddrZip: 29691,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '852 CRENSHAW DR',
        RegAddrCity: 'WALHALLA',
        RegAddrState: 'SC',
        RegAddrZip: 29691,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '214 SHOP RD',
        RegAddrCity: 'WARD',
        RegAddrState: 'SC',
        RegAddrZip: 29166,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '403 PUMP HOUSE RD',
        RegAddrCity: 'WESTMINSTER',
        RegAddrState: 'SC',
        RegAddrZip: 29693,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '251 MOUNT PLEASANT RD',
        RegAddrCity: 'WESTMINSTER',
        RegAddrState: 'SC',
        RegAddrZip: 29693,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '403 BUTLER SCHOOL RD',
        RegAddrCity: 'WESTMINSTER',
        RegAddrState: 'SC',
        RegAddrZip: 29693,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '217 MEADOW LARK DR',
        RegAddrCity: 'WESTMINSTER',
        RegAddrState: 'SC',
        RegAddrZip: 29693,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '108 LAZY WILLOW CT',
        RegAddrCity: 'WILLIAMSTON',
        RegAddrState: 'SC',
        RegAddrZip: 29697,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '16 BREAZEALE DR',
        RegAddrCity: 'WILLIAMSTON',
        RegAddrState: 'SC',
        RegAddrZip: 29697,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '12 RIDGE CT',
        RegAddrCity: 'WILLIAMSTON',
        RegAddrState: 'SC',
        RegAddrZip: 29697,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '127 PARKER ST',
        RegAddrCity: 'WILLIAMSTON',
        RegAddrState: 'SC',
        RegAddrZip: 29697,
        CongressionalDistrict: 3,
      },
    ],
  },
  {
    name: 'SC-4',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/SC-4?ref=gh-pages',
    state: 'SC',
    state_and_number: 'SC-4',
    zip: 29301,
    congressionalDistrict: 4,
    addressesInDistrict: [
      {
        RegAddrLine1: '226 STEWARTS LNDG',
        RegAddrCity: 'BOILING SPGS',
        RegAddrState: 'SC',
        RegAddrZip: 29316,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '627 SHADOW DANCE LN',
        RegAddrCity: 'BOILING SPGS',
        RegAddrState: 'SC',
        RegAddrZip: 29316,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1530 RODDY RD',
        RegAddrCity: 'CAMPOBELLO',
        RegAddrState: 'SC',
        RegAddrZip: 29322,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1128 LAKE ST',
        RegAddrCity: 'COWPENS',
        RegAddrState: 'SC',
        RegAddrZip: 29330,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1200 OLD JOHNSON RD',
        RegAddrCity: 'COWPENS',
        RegAddrState: 'SC',
        RegAddrZip: 29330,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '112 EASTBERRYS CREEK RD',
        RegAddrCity: 'DUNCAN',
        RegAddrState: 'SC',
        RegAddrZip: 29334,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1050 GRACE CHAPEL RD',
        RegAddrCity: 'ENOREE',
        RegAddrState: 'SC',
        RegAddrZip: 29335,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '203 QUAIL RUN CIR',
        RegAddrCity: 'FOUNTAIN INN',
        RegAddrState: 'SC',
        RegAddrZip: 29644,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '306 LLOYD ST',
        RegAddrCity: 'GREENVILLE',
        RegAddrState: 'SC',
        RegAddrZip: 29601,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '116 BRANTFORD LN',
        RegAddrCity: 'GREENVILLE',
        RegAddrState: 'SC',
        RegAddrZip: 29605,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '18 PONCE DE LEON DR',
        RegAddrCity: 'GREENVILLE',
        RegAddrState: 'SC',
        RegAddrZip: 29605,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '104 PENNINE DR',
        RegAddrCity: 'GREENVILLE',
        RegAddrState: 'SC',
        RegAddrZip: 29605,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '607 CAROLINA AVE',
        RegAddrCity: 'GREENVILLE',
        RegAddrState: 'SC',
        RegAddrZip: 29607,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '9 WIUKA AVE',
        RegAddrCity: 'GREENVILLE',
        RegAddrState: 'SC',
        RegAddrZip: 29607,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '11 WOODVILLE AVE',
        RegAddrCity: 'GREENVILLE',
        RegAddrState: 'SC',
        RegAddrZip: 29607,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '22 SIR ABBOTT ST',
        RegAddrCity: 'GREENVILLE',
        RegAddrState: 'SC',
        RegAddrZip: 29607,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1210 HALF MILE WAY',
        RegAddrCity: 'GREENVILLE',
        RegAddrState: 'SC',
        RegAddrZip: 29609,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '342 LOOP STATE',
        RegAddrCity: 'GREENVILLE',
        RegAddrState: 'SC',
        RegAddrZip: 29609,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '234 STONE LAKE DR',
        RegAddrCity: 'GREENVILLE',
        RegAddrState: 'SC',
        RegAddrZip: 29609,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '200 W DARBY RD',
        RegAddrCity: 'GREENVILLE',
        RegAddrState: 'SC',
        RegAddrZip: 29609,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '15 VESTA DR',
        RegAddrCity: 'GREENVILLE',
        RegAddrState: 'SC',
        RegAddrZip: 29611,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '542 CLUB DR',
        RegAddrCity: 'GREENVILLE',
        RegAddrState: 'SC',
        RegAddrZip: 29611,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '18 SIRRINE ST',
        RegAddrCity: 'GREENVILLE',
        RegAddrState: 'SC',
        RegAddrZip: 29611,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '12 N WASHINGTON AVE',
        RegAddrCity: 'GREENVILLE',
        RegAddrState: 'SC',
        RegAddrZip: 29611,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '130 CURRENT DR',
        RegAddrCity: 'GREENVILLE',
        RegAddrState: 'SC',
        RegAddrZip: 29611,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '101 TRELLIS ST',
        RegAddrCity: 'GREENVILLE',
        RegAddrState: 'SC',
        RegAddrZip: 29615,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '314 MITCHELL RD',
        RegAddrCity: 'GREENVILLE',
        RegAddrState: 'SC',
        RegAddrZip: 29615,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '205 ROSEBANK WAY',
        RegAddrCity: 'GREENVILLE',
        RegAddrState: 'SC',
        RegAddrZip: 29615,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '116 LEATHERTON WAY',
        RegAddrCity: 'GREENVILLE',
        RegAddrState: 'SC',
        RegAddrZip: 29615,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '50 GLENWOOD RD',
        RegAddrCity: 'GREENVILLE',
        RegAddrState: 'SC',
        RegAddrZip: 29615,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '316 SUNRISE VALLEY RD',
        RegAddrCity: 'GREENVILLE',
        RegAddrState: 'SC',
        RegAddrZip: 29617,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '6 HATHAWAY CIR',
        RegAddrCity: 'GREENVILLE',
        RegAddrState: 'SC',
        RegAddrZip: 29617,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '157 MONTAGUE RD',
        RegAddrCity: 'GREENVILLE',
        RegAddrState: 'SC',
        RegAddrZip: 29617,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '9 TIMBER CT',
        RegAddrCity: 'GREENVILLE',
        RegAddrState: 'SC',
        RegAddrZip: 29617,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '686 E SUBER RD',
        RegAddrCity: 'GREER',
        RegAddrState: 'SC',
        RegAddrZip: 29650,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '421 PHILLIPS LN',
        RegAddrCity: 'GREER',
        RegAddrState: 'SC',
        RegAddrZip: 29650,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '119 HANCOCK LN',
        RegAddrCity: 'GREER',
        RegAddrState: 'SC',
        RegAddrZip: 29650,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '610 MEADOW GROVE WAY',
        RegAddrCity: 'GREER',
        RegAddrState: 'SC',
        RegAddrZip: 29650,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '8 JUDGES LN',
        RegAddrCity: 'GREER',
        RegAddrState: 'SC',
        RegAddrZip: 29651,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2 SADDLE CREEK CT',
        RegAddrCity: 'GREER',
        RegAddrState: 'SC',
        RegAddrZip: 29651,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '337 HARKINS BLUFF DR',
        RegAddrCity: 'GREER',
        RegAddrState: 'SC',
        RegAddrZip: 29651,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '104 BRIARCLIFF WAY',
        RegAddrCity: 'GREER',
        RegAddrState: 'SC',
        RegAddrZip: 29651,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '141 DEYOUNG MEADOWS DR',
        RegAddrCity: 'GREER',
        RegAddrState: 'SC',
        RegAddrZip: 29651,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '221 PEACH ST',
        RegAddrCity: 'INMAN',
        RegAddrState: 'SC',
        RegAddrZip: 29349,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '127 PINEVIEW DR',
        RegAddrCity: 'INMAN',
        RegAddrState: 'SC',
        RegAddrZip: 29349,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '221 ANON DR',
        RegAddrCity: 'INMAN',
        RegAddrState: 'SC',
        RegAddrZip: 29349,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '770 SEAY RD',
        RegAddrCity: 'INMAN',
        RegAddrState: 'SC',
        RegAddrZip: 29349,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '164 TIMBERLAKE DR',
        RegAddrCity: 'INMAN',
        RegAddrState: 'SC',
        RegAddrZip: 29349,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '4 WINERY CT',
        RegAddrCity: 'MAULDIN',
        RegAddrState: 'SC',
        RegAddrZip: 29662,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '105 SHEARBROOK DR',
        RegAddrCity: 'MAULDIN',
        RegAddrState: 'SC',
        RegAddrZip: 29662,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '324 COUNTRYSIDE CIR',
        RegAddrCity: 'MOORE',
        RegAddrState: 'SC',
        RegAddrZip: 29369,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '301 GREENRIDGE DR',
        RegAddrCity: 'MOORE',
        RegAddrState: 'SC',
        RegAddrZip: 29369,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '173 FLEMING AVE',
        RegAddrCity: 'PACOLET',
        RegAddrState: 'SC',
        RegAddrZip: 29372,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '252 EVANSDALE WAY',
        RegAddrCity: 'SIMPSONVILLE',
        RegAddrState: 'SC',
        RegAddrZip: 29680,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '204 WAGONCREEK DR',
        RegAddrCity: 'SIMPSONVILLE',
        RegAddrState: 'SC',
        RegAddrZip: 29681,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '10 SHADOW MIST DR',
        RegAddrCity: 'SIMPSONVILLE',
        RegAddrState: 'SC',
        RegAddrZip: 29681,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '8 CRANSTON CT',
        RegAddrCity: 'SIMPSONVILLE',
        RegAddrState: 'SC',
        RegAddrZip: 29681,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '102 GARY AVE',
        RegAddrCity: 'SIMPSONVILLE',
        RegAddrState: 'SC',
        RegAddrZip: 29681,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '101 CARRIAGE LN',
        RegAddrCity: 'SIMPSONVILLE',
        RegAddrState: 'SC',
        RegAddrZip: 29681,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '100 SHALOM DR',
        RegAddrCity: 'SIMPSONVILLE',
        RegAddrState: 'SC',
        RegAddrZip: 29681,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '120 FIELDSTONE RD',
        RegAddrCity: 'SPARTANBURG',
        RegAddrState: 'SC',
        RegAddrZip: 29301,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '724 BRIMSTONE LN',
        RegAddrCity: 'SPARTANBURG',
        RegAddrState: 'SC',
        RegAddrZip: 29301,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '230 TIBBS DR',
        RegAddrCity: 'SPARTANBURG',
        RegAddrState: 'SC',
        RegAddrZip: 29301,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '869 IRON ORE RD',
        RegAddrCity: 'SPARTANBURG',
        RegAddrState: 'SC',
        RegAddrZip: 29303,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '105 EDISON CIR',
        RegAddrCity: 'SPARTANBURG',
        RegAddrState: 'SC',
        RegAddrZip: 29303,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '755 S LIBERTY ST',
        RegAddrCity: 'SPARTANBURG',
        RegAddrState: 'SC',
        RegAddrZip: 29306,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '570 CRESCENT AVE',
        RegAddrCity: 'SPARTANBURG',
        RegAddrState: 'SC',
        RegAddrZip: 29306,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '815 RIDGEDALE DR',
        RegAddrCity: 'SPARTANBURG',
        RegAddrState: 'SC',
        RegAddrZip: 29306,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '8 ELLINGTON CT',
        RegAddrCity: 'TAYLORS',
        RegAddrState: 'SC',
        RegAddrZip: 29687,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '11 CAVENDISH CLOSE',
        RegAddrCity: 'TAYLORS',
        RegAddrState: 'SC',
        RegAddrZip: 29687,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '83 RISING MEADOW LN',
        RegAddrCity: 'TAYLORS',
        RegAddrState: 'SC',
        RegAddrZip: 29687,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '16 DANCHRIS CT',
        RegAddrCity: 'TAYLORS',
        RegAddrState: 'SC',
        RegAddrZip: 29687,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '60 SCHOOL HOUSE RD',
        RegAddrCity: 'TRAVELERS RST',
        RegAddrState: 'SC',
        RegAddrZip: 29690,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '141 RUSSELL RD',
        RegAddrCity: 'WELLFORD',
        RegAddrState: 'SC',
        RegAddrZip: 29385,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '772 OLD SPARTANBURG HWY',
        RegAddrCity: 'WELLFORD',
        RegAddrState: 'SC',
        RegAddrZip: 29385,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '569 HAWTHORNE AVE',
        RegAddrCity: 'WOODRUFF',
        RegAddrState: 'SC',
        RegAddrZip: 29388,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '135 MILL ST',
        RegAddrCity: 'WOODRUFF',
        RegAddrState: 'SC',
        RegAddrZip: 29388,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2355 TRAMMEL RD',
        RegAddrCity: 'WOODRUFF',
        RegAddrState: 'SC',
        RegAddrZip: 29388,
        CongressionalDistrict: 4,
      },
    ],
  },
  {
    name: 'SC-5',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/SC-5?ref=gh-pages',
    state: 'SC',
    state_and_number: 'SC-5',
    zip: 29009,
    congressionalDistrict: 5,
    addressesInDistrict: [
      {
        RegAddrLine1: '403 N HEYWARD ST',
        RegAddrCity: 'BISHOPVILLE',
        RegAddrState: 'SC',
        RegAddrZip: 29010,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '598 CALVARY CHURCH RD',
        RegAddrCity: 'BISHOPVILLE',
        RegAddrState: 'SC',
        RegAddrZip: 29010,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '164 MARTIN RIDGE RD',
        RegAddrCity: 'BLACKSBURG',
        RegAddrState: 'SC',
        RegAddrZip: 29702,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '927 DRAVO RD',
        RegAddrCity: 'BLACKSBURG',
        RegAddrState: 'SC',
        RegAddrZip: 29702,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '1811 W CHEROKEE ST',
        RegAddrCity: 'BLACKSBURG',
        RegAddrState: 'SC',
        RegAddrZip: 29702,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '122 WOODHAVEN LN',
        RegAddrCity: 'BLACKSBURG',
        RegAddrState: 'SC',
        RegAddrZip: 29702,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '300 KELLY CT',
        RegAddrCity: 'BLAIR',
        RegAddrState: 'SC',
        RegAddrZip: 29015,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '516 TRUESDALE RD',
        RegAddrCity: 'CAMDEN',
        RegAddrState: 'SC',
        RegAddrZip: 29020,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '504 BLACK RIVER RD',
        RegAddrCity: 'CAMDEN',
        RegAddrState: 'SC',
        RegAddrZip: 29020,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '804 ELMORE ST',
        RegAddrCity: 'CAMDEN',
        RegAddrState: 'SC',
        RegAddrZip: 29020,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '1323 ELECTRIC LN',
        RegAddrCity: 'CASSATT',
        RegAddrState: 'SC',
        RegAddrZip: 29032,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '942 PARK RD',
        RegAddrCity: 'CASSATT',
        RegAddrState: 'SC',
        RegAddrZip: 29032,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '128 GRAY FOX RUN',
        RegAddrCity: 'CHESNEE',
        RegAddrState: 'SC',
        RegAddrZip: 29323,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '143 CANTRELL ST',
        RegAddrCity: 'CHESNEE',
        RegAddrState: 'SC',
        RegAddrZip: 29323,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '1405 HENDERSON RD',
        RegAddrCity: 'CHESNEE',
        RegAddrState: 'SC',
        RegAddrZip: 29323,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '202 BERKELEY ST',
        RegAddrCity: 'CHESNEE',
        RegAddrState: 'SC',
        RegAddrZip: 29323,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '120 RUTH LN',
        RegAddrCity: 'CHESNEE',
        RegAddrState: 'SC',
        RegAddrZip: 29323,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '895 DAVIS DR',
        RegAddrCity: 'CHESTER',
        RegAddrState: 'SC',
        RegAddrZip: 29706,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '1988 ADAMS FARM RD',
        RegAddrCity: 'CHESTER',
        RegAddrState: 'SC',
        RegAddrZip: 29706,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '949 MEADOWIND CT',
        RegAddrCity: 'CLOVER',
        RegAddrState: 'SC',
        RegAddrZip: 29710,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '3009 KILBEGGAN DR',
        RegAddrCity: 'CLOVER',
        RegAddrState: 'SC',
        RegAddrZip: 29710,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '2323 KINGS MOUNTAIN DR',
        RegAddrCity: 'DALZELL',
        RegAddrState: 'SC',
        RegAddrZip: 29040,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '70 LEONARD LN',
        RegAddrCity: 'ELGIN',
        RegAddrState: 'SC',
        RegAddrZip: 29045,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '1520 HIGHWAY CHURCH RD',
        RegAddrCity: 'ELGIN',
        RegAddrState: 'SC',
        RegAddrZip: 29045,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '61 KIMPTON DR',
        RegAddrCity: 'ELGIN',
        RegAddrState: 'SC',
        RegAddrZip: 29045,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '358 ASHLEY CREEK DR',
        RegAddrCity: 'ELGIN',
        RegAddrState: 'SC',
        RegAddrZip: 29045,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '377 BLACKSTOCK RD',
        RegAddrCity: 'ENOREE',
        RegAddrState: 'SC',
        RegAddrZip: 29335,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '208 RIVER CLAY RD',
        RegAddrCity: 'FORT MILL',
        RegAddrState: 'SC',
        RegAddrZip: 29708,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '1058 GARDENIA ST',
        RegAddrCity: 'FORT MILL',
        RegAddrState: 'SC',
        RegAddrZip: 29708,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '215 GRIMBALL LN',
        RegAddrCity: 'FORT MILL',
        RegAddrState: 'SC',
        RegAddrZip: 29715,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '668 DEWEY CT',
        RegAddrCity: 'FORT MILL',
        RegAddrState: 'SC',
        RegAddrZip: 29715,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '103 SHADE TREE CIR',
        RegAddrCity: 'FORT MILL',
        RegAddrState: 'SC',
        RegAddrZip: 29715,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '206 BROOKSIDE DR',
        RegAddrCity: 'FORT MILL',
        RegAddrState: 'SC',
        RegAddrZip: 29715,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '491 WALDEN PARK DR',
        RegAddrCity: 'FORT MILL',
        RegAddrState: 'SC',
        RegAddrZip: 29715,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '2119 HANGING ROCK RD',
        RegAddrCity: 'FORT MILL',
        RegAddrState: 'SC',
        RegAddrZip: 29715,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '409 E JEFFERIES ST',
        RegAddrCity: 'GAFFNEY',
        RegAddrState: 'SC',
        RegAddrZip: 29340,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '3629 UNION HWY',
        RegAddrCity: 'GAFFNEY',
        RegAddrState: 'SC',
        RegAddrZip: 29340,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '165 IDLEWOOD RD',
        RegAddrCity: 'GAFFNEY',
        RegAddrState: 'SC',
        RegAddrZip: 29340,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '197 HIDDEN ACRES DR',
        RegAddrCity: 'GAFFNEY',
        RegAddrState: 'SC',
        RegAddrZip: 29341,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '4347 STEWART PLACE RD',
        RegAddrCity: 'HEATH SPRINGS',
        RegAddrState: 'SC',
        RegAddrZip: 29058,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '937 BAYNESWOOD LN',
        RegAddrCity: 'INDIAN LAND',
        RegAddrState: 'SC',
        RegAddrZip: 29707,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '3065 AZALEA DR',
        RegAddrCity: 'INDIAN LAND',
        RegAddrState: 'SC',
        RegAddrZip: 29707,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '7584 KERSHAW CAMDEN HWY',
        RegAddrCity: 'KERSHAW',
        RegAddrState: 'SC',
        RegAddrZip: 29067,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '4526 CATAWBA RD',
        RegAddrCity: 'KERSHAW',
        RegAddrState: 'SC',
        RegAddrZip: 29067,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '955 MONROE HWY',
        RegAddrCity: 'LANCASTER',
        RegAddrState: 'SC',
        RegAddrZip: 29720,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '1254 W SHORE DR',
        RegAddrCity: 'LANCASTER',
        RegAddrState: 'SC',
        RegAddrZip: 29720,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '1823 WISDOM RD',
        RegAddrCity: 'LANCASTER',
        RegAddrState: 'SC',
        RegAddrZip: 29720,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '1627 CATOE RD',
        RegAddrCity: 'LANCASTER',
        RegAddrState: 'SC',
        RegAddrZip: 29720,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '931 13TH ST',
        RegAddrCity: 'LANCASTER',
        RegAddrState: 'SC',
        RegAddrZip: 29720,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '2211 DERRILL AVE',
        RegAddrCity: 'NEWBERRY',
        RegAddrState: 'SC',
        RegAddrZip: 29108,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '2445 STATE PARK RD',
        RegAddrCity: 'PROSPERITY',
        RegAddrState: 'SC',
        RegAddrZip: 29127,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '2420 SEIBERT RD',
        RegAddrCity: 'PROSPERITY',
        RegAddrState: 'SC',
        RegAddrZip: 29127,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '145 MAGGIE DR',
        RegAddrCity: 'RICHBURG',
        RegAddrState: 'SC',
        RegAddrZip: 29729,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '1036 DUNLAP RODDEY RD',
        RegAddrCity: 'ROCK HILL',
        RegAddrState: 'SC',
        RegAddrZip: 29730,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '702 WOFFORD ST',
        RegAddrCity: 'ROCK HILL',
        RegAddrState: 'SC',
        RegAddrZip: 29730,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '531 ROUGH HEWN LN',
        RegAddrCity: 'ROCK HILL',
        RegAddrState: 'SC',
        RegAddrZip: 29730,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '830 S STONEWALL ST',
        RegAddrCity: 'ROCK HILL',
        RegAddrState: 'SC',
        RegAddrZip: 29730,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '304 HUTCHINSON ST',
        RegAddrCity: 'ROCK HILL',
        RegAddrState: 'SC',
        RegAddrZip: 29730,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '132 PRIDE ST',
        RegAddrCity: 'ROCK HILL',
        RegAddrState: 'SC',
        RegAddrZip: 29730,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '1723 HICKORY RIDGE RD',
        RegAddrCity: 'ROCK HILL',
        RegAddrState: 'SC',
        RegAddrZip: 29732,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '6542 IMPULSE LN',
        RegAddrCity: 'ROCK HILL',
        RegAddrState: 'SC',
        RegAddrZip: 29732,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '1275 SAINT KATHERINES WAY',
        RegAddrCity: 'ROCK HILL',
        RegAddrState: 'SC',
        RegAddrZip: 29732,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '2342 MANCKE DR',
        RegAddrCity: 'ROCK HILL',
        RegAddrState: 'SC',
        RegAddrZip: 29732,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '8082 LOCKHART RD',
        RegAddrCity: 'SHARON',
        RegAddrState: 'SC',
        RegAddrZip: 29742,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '2085 TUDOR ST',
        RegAddrCity: 'SUMTER',
        RegAddrState: 'SC',
        RegAddrZip: 29150,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '3070 LACOSTA CT',
        RegAddrCity: 'SUMTER',
        RegAddrState: 'SC',
        RegAddrZip: 29150,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '27 RAST ST',
        RegAddrCity: 'SUMTER',
        RegAddrState: 'SC',
        RegAddrZip: 29150,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '60 COVEY PT',
        RegAddrCity: 'SUMTER',
        RegAddrState: 'SC',
        RegAddrZip: 29150,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '1395 GRANVILLE CT',
        RegAddrCity: 'SUMTER',
        RegAddrState: 'SC',
        RegAddrZip: 29150,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '524 W CALHOUN ST',
        RegAddrCity: 'SUMTER',
        RegAddrState: 'SC',
        RegAddrZip: 29150,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '3310 S DARLINGTON HWY',
        RegAddrCity: 'SUMTER',
        RegAddrState: 'SC',
        RegAddrZip: 29153,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '260 WENDEMERE DR',
        RegAddrCity: 'SUMTER',
        RegAddrState: 'SC',
        RegAddrZip: 29153,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '3200 NAZARENE CHURCH RD',
        RegAddrCity: 'SUMTER',
        RegAddrState: 'SC',
        RegAddrZip: 29154,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '4128 WADE ST',
        RegAddrCity: 'SUMTER',
        RegAddrState: 'SC',
        RegAddrZip: 29154,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '10028 BORA BORA',
        RegAddrCity: 'TEGA CAY',
        RegAddrState: 'SC',
        RegAddrZip: 29708,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '1109 PALMYRA DR',
        RegAddrCity: 'TEGA CAY',
        RegAddrState: 'SC',
        RegAddrZip: 29708,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '2088 LAKE FOREST DR',
        RegAddrCity: 'TEGA CAY',
        RegAddrState: 'SC',
        RegAddrZip: 29708,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '817 BELTLINE RD',
        RegAddrCity: 'UNION',
        RegAddrState: 'SC',
        RegAddrZip: 29379,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '131 MITCHELL RD',
        RegAddrCity: 'UNION',
        RegAddrState: 'SC',
        RegAddrZip: 29379,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '2930 SANTUC CARLISLE HWY',
        RegAddrCity: 'UNION',
        RegAddrState: 'SC',
        RegAddrZip: 29379,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '1211 S PINCKNEY ST',
        RegAddrCity: 'UNION',
        RegAddrState: 'SC',
        RegAddrZip: 29379,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '109 STATE HIGHWAY 34 E',
        RegAddrCity: 'WINNSBORO',
        RegAddrState: 'SC',
        RegAddrZip: 29180,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '106 CEMETERY ST',
        RegAddrCity: 'WINNSBORO',
        RegAddrState: 'SC',
        RegAddrZip: 29180,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '1123 ROSS BROOK TRCE',
        RegAddrCity: 'YORK',
        RegAddrState: 'SC',
        RegAddrZip: 29745,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '271 AUSTIN DR',
        RegAddrCity: 'YORK',
        RegAddrState: 'SC',
        RegAddrZip: 29745,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '936 CHAMBERS RD',
        RegAddrCity: 'YORK',
        RegAddrState: 'SC',
        RegAddrZip: 29745,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '1127 WELLINGTON SQUARE DR',
        RegAddrCity: 'YORK',
        RegAddrState: 'SC',
        RegAddrZip: 29745,
        CongressionalDistrict: 5,
      },
    ],
  },
  {
    name: 'SC-6',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/SC-6?ref=gh-pages',
    state: 'SC',
    state_and_number: 'SC-6',
    zip: 29001,
    congressionalDistrict: 6,
    addressesInDistrict: [
      {
        RegAddrLine1: '7236 PARKERS FERRY RD',
        RegAddrCity: 'ADAMS RUN',
        RegAddrState: 'SC',
        RegAddrZip: 29426,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '1146 BLACKWOOD LN',
        RegAddrCity: 'ALCOLU',
        RegAddrState: 'SC',
        RegAddrZip: 29001,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '115 RHODUS AVE',
        RegAddrCity: 'ANDREWS',
        RegAddrState: 'SC',
        RegAddrZip: 29510,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '582 RIGBY RD',
        RegAddrCity: 'BRANCHVILLE',
        RegAddrState: 'SC',
        RegAddrZip: 29432,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '1 RIVERSIDE DR',
        RegAddrCity: 'CHARLESTON',
        RegAddrState: 'SC',
        RegAddrZip: 29403,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '215 CITADEL RICHARDSON AVE',
        RegAddrCity: 'CHARLESTON',
        RegAddrState: 'SC',
        RegAddrZip: 29409,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '923 LAURENS ST',
        RegAddrCity: 'COLUMBIA',
        RegAddrState: 'SC',
        RegAddrZip: 29201,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '511 GERVAIS ST',
        RegAddrCity: 'COLUMBIA',
        RegAddrState: 'SC',
        RegAddrZip: 29201,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '1106 BEAUFORT ST',
        RegAddrCity: 'COLUMBIA',
        RegAddrState: 'SC',
        RegAddrZip: 29201,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '641 HENDERSON ST',
        RegAddrCity: 'COLUMBIA',
        RegAddrState: 'SC',
        RegAddrZip: 29201,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '567 ROMFORD RD',
        RegAddrCity: 'COLUMBIA',
        RegAddrState: 'SC',
        RegAddrZip: 29203,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '539 PROVIDENCE CROSSING DR',
        RegAddrCity: 'COLUMBIA',
        RegAddrState: 'SC',
        RegAddrZip: 29203,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '1530 HARDEN ST',
        RegAddrCity: 'COLUMBIA',
        RegAddrState: 'SC',
        RegAddrZip: 29204,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '621 SALUDA AVE',
        RegAddrCity: 'COLUMBIA',
        RegAddrState: 'SC',
        RegAddrZip: 29205,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '2705 BRINKLEY LN',
        RegAddrCity: 'COLUMBIA',
        RegAddrState: 'SC',
        RegAddrZip: 29210,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '90 NEWPORT DR',
        RegAddrCity: 'COLUMBIA',
        RegAddrState: 'SC',
        RegAddrZip: 29223,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '116 CHARMONT DR',
        RegAddrCity: 'COLUMBIA',
        RegAddrState: 'SC',
        RegAddrZip: 29223,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '8009 SPRINGPOND RD',
        RegAddrCity: 'COLUMBIA',
        RegAddrState: 'SC',
        RegAddrZip: 29223,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '218 WILLOW OAK DR',
        RegAddrCity: 'COLUMBIA',
        RegAddrState: 'SC',
        RegAddrZip: 29223,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '750 BRANNIGAN LN',
        RegAddrCity: 'COLUMBIA',
        RegAddrState: 'SC',
        RegAddrZip: 29229,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '694 FORTRESS RD',
        RegAddrCity: 'EHRHARDT',
        RegAddrState: 'SC',
        RegAddrZip: 29081,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '113 BARKLEY ST',
        RegAddrCity: 'EUTAWVILLE',
        RegAddrState: 'SC',
        RegAddrZip: 29048,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: 'S LAURENS AVE',
        RegAddrCity: 'FAIRFAX',
        RegAddrState: 'SC',
        RegAddrZip: 29827,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '1958 SALEM RD',
        RegAddrCity: 'GABLE',
        RegAddrState: 'SC',
        RegAddrZip: 29051,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '11 INDEPENDENCE CIR',
        RegAddrCity: 'GREELEYVILLE',
        RegAddrState: 'SC',
        RegAddrZip: 29056,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '352 STINEY RD',
        RegAddrCity: 'HARDEEVILLE',
        RegAddrState: 'SC',
        RegAddrZip: 29927,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '964 CORAL REEF WAY',
        RegAddrCity: 'HARDEEVILLE',
        RegAddrState: 'SC',
        RegAddrZip: 29927,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '336 E MAIN ST',
        RegAddrCity: 'HARLEYVILLE',
        RegAddrState: 'SC',
        RegAddrZip: 29448,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '56 BRUCES RD',
        RegAddrCity: 'HEMINGWAY',
        RegAddrState: 'SC',
        RegAddrZip: 29554,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '1205 TORRINGTON RD',
        RegAddrCity: 'HOLLY HILL',
        RegAddrState: 'SC',
        RegAddrZip: 29059,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '8526 LELAND SMALLS RD',
        RegAddrCity: 'HOLLYWOOD',
        RegAddrState: 'SC',
        RegAddrZip: 29449,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '5311 HIGHWAY 162',
        RegAddrCity: 'HOLLYWOOD',
        RegAddrState: 'SC',
        RegAddrZip: 29449,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '201 STARLING GOODSON RD',
        RegAddrCity: 'HOPKINS',
        RegAddrState: 'SC',
        RegAddrZip: 29061,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '2613 S PARTRIDGE CIR',
        RegAddrCity: 'HOPKINS',
        RegAddrState: 'SC',
        RegAddrZip: 29061,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '145 FIELD PINE AVE',
        RegAddrCity: 'HOPKINS',
        RegAddrState: 'SC',
        RegAddrZip: 29061,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '306 VANESSA TRL',
        RegAddrCity: 'HUGER',
        RegAddrState: 'SC',
        RegAddrZip: 29450,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '1201 TRIFALIA RD',
        RegAddrCity: 'LAKE CITY',
        RegAddrState: 'SC',
        RegAddrZip: 29560,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '201 S COUNTRY CLUB RD',
        RegAddrCity: 'LAKE CITY',
        RegAddrState: 'SC',
        RegAddrZip: 29560,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '211 RIDGE LAKE DR',
        RegAddrCity: 'MANNING',
        RegAddrState: 'SC',
        RegAddrZip: 29102,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '4606 FLOUNDER LAKE DR',
        RegAddrCity: 'MEGGETT',
        RegAddrState: 'SC',
        RegAddrZip: 29449,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '123 OLD SAVANNAH CT',
        RegAddrCity: 'MONCKS CORNER',
        RegAddrState: 'SC',
        RegAddrZip: 29461,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '4634 GLENN ST',
        RegAddrCity: 'N CHARLESTON',
        RegAddrState: 'SC',
        RegAddrZip: 29405,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '2118 ABERDEEN AVE',
        RegAddrCity: 'N CHARLESTON',
        RegAddrState: 'SC',
        RegAddrZip: 29405,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '3841 HOTTINGER AVE',
        RegAddrCity: 'N CHARLESTON',
        RegAddrState: 'SC',
        RegAddrZip: 29405,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '2133 COMMANDER RD',
        RegAddrCity: 'N CHARLESTON',
        RegAddrState: 'SC',
        RegAddrZip: 29405,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '7700 EAGLE LAKE RD',
        RegAddrCity: 'N CHARLESTON',
        RegAddrState: 'SC',
        RegAddrZip: 29418,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '7760 CHERRYWOOD DR',
        RegAddrCity: 'N CHARLESTON',
        RegAddrState: 'SC',
        RegAddrZip: 29418,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '1498 PLAYER RD',
        RegAddrCity: 'NEW ZION',
        RegAddrState: 'SC',
        RegAddrZip: 29111,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '831 TRAVERS ST',
        RegAddrCity: 'ORANGEBURG',
        RegAddrState: 'SC',
        RegAddrZip: 29115,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '131 BOULEVARD ST',
        RegAddrCity: 'ORANGEBURG',
        RegAddrState: 'SC',
        RegAddrZip: 29115,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '1550 SIFLY RD',
        RegAddrCity: 'ORANGEBURG',
        RegAddrState: 'SC',
        RegAddrZip: 29118,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '1450 CLECKLEY BLVD',
        RegAddrCity: 'ORANGEBURG',
        RegAddrState: 'SC',
        RegAddrZip: 29118,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '1440 SHEPPARD RD',
        RegAddrCity: 'ORANGEBURG',
        RegAddrState: 'SC',
        RegAddrZip: 29118,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '2769 FRANK RHAMES RD',
        RegAddrCity: 'PINEWOOD',
        RegAddrState: 'SC',
        RegAddrZip: 29125,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '4270 JACOBS POINT CT',
        RegAddrCity: 'RAVENEL',
        RegAddrState: 'SC',
        RegAddrZip: 29470,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '158 SQUIRREL ALY',
        RegAddrCity: 'RIDGELAND',
        RegAddrState: 'SC',
        RegAddrZip: 29936,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '118 E MAIN ST',
        RegAddrCity: 'RIDGELAND',
        RegAddrState: 'SC',
        RegAddrZip: 29936,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '55 LAKEVIEW DR',
        RegAddrCity: 'RIDGELAND',
        RegAddrState: 'SC',
        RegAddrZip: 29936,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '484 AZALEA PATCH RD',
        RegAddrCity: 'RUFFIN',
        RegAddrState: 'SC',
        RegAddrZip: 29475,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '2618 FELDERVILLE RD',
        RegAddrCity: 'SANTEE',
        RegAddrState: 'SC',
        RegAddrZip: 29142,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '10 BENJAMIN SMALLS RD',
        RegAddrCity: 'SEABROOK',
        RegAddrState: 'SC',
        RegAddrZip: 29940,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '89 FAMILY LN',
        RegAddrCity: 'SMOAKS',
        RegAddrState: 'SC',
        RegAddrZip: 29481,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '36 CARIBOU CT',
        RegAddrCity: 'ST MATTHEWS',
        RegAddrState: 'SC',
        RegAddrZip: 29135,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '49 BECKHAM LN',
        RegAddrCity: 'ST MATTHEWS',
        RegAddrState: 'SC',
        RegAddrZip: 29135,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '1432 PRINCESS TRACE CIR',
        RegAddrCity: 'SUMMERTON',
        RegAddrState: 'SC',
        RegAddrZip: 29148,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '12 ANNIE TINDAL RD',
        RegAddrCity: 'SUMMERTON',
        RegAddrState: 'SC',
        RegAddrZip: 29148,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '335 MOONEYHAM RD',
        RegAddrCity: 'SUMTER',
        RegAddrState: 'SC',
        RegAddrZip: 29153,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '158 ANDREW CHAPEL RD',
        RegAddrCity: 'SWANSEA',
        RegAddrState: 'SC',
        RegAddrZip: 29160,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '107 W PINE ST',
        RegAddrCity: 'VARNVILLE',
        RegAddrState: 'SC',
        RegAddrZip: 29944,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '314 BOWMAN LN',
        RegAddrCity: 'WALTERBORO',
        RegAddrState: 'SC',
        RegAddrZip: 29488,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '107 ROWELL ST',
        RegAddrCity: 'YEMASSEE',
        RegAddrState: 'SC',
        RegAddrZip: 29945,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '149 CENTY LN',
        RegAddrCity: 'YEMASSEE',
        RegAddrState: 'SC',
        RegAddrZip: 29945,
        CongressionalDistrict: 6,
      },
    ],
  },
  {
    name: 'SC-7',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/SC-7?ref=gh-pages',
    state: 'SC',
    state_and_number: 'SC-7',
    zip: 29079,
    congressionalDistrict: 7,
    addressesInDistrict: [
      {
        RegAddrLine1: '109 CLAIR ST',
        RegAddrCity: 'ANDREWS',
        RegAddrState: 'SC',
        RegAddrZip: 29510,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '110 TEC RD',
        RegAddrCity: 'CHERAW',
        RegAddrState: 'SC',
        RegAddrZip: 29520,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '129 BASIN DR',
        RegAddrCity: 'CHERAW',
        RegAddrState: 'SC',
        RegAddrZip: 29520,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '101 ORA LN',
        RegAddrCity: 'CHESTERFIELD',
        RegAddrState: 'SC',
        RegAddrZip: 29709,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '504 SOCIETY ST',
        RegAddrCity: 'CLIO',
        RegAddrState: 'SC',
        RegAddrZip: 29525,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '252 GRASMERE LAKE CIR',
        RegAddrCity: 'CONWAY',
        RegAddrState: 'SC',
        RegAddrZip: 29526,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '318 MILLEDGE DR',
        RegAddrCity: 'CONWAY',
        RegAddrState: 'SC',
        RegAddrZip: 29526,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '4441 WILLOW SPRINGS RD',
        RegAddrCity: 'CONWAY',
        RegAddrState: 'SC',
        RegAddrZip: 29527,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '3015 HUGO RD',
        RegAddrCity: 'CONWAY',
        RegAddrState: 'SC',
        RegAddrZip: 29527,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '9288 PEE DEE HWY',
        RegAddrCity: 'CONWAY',
        RegAddrState: 'SC',
        RegAddrZip: 29527,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '301 KEYSFIELD CIR',
        RegAddrCity: 'CONWAY',
        RegAddrState: 'SC',
        RegAddrZip: 29527,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '904 SKYVIEW DR',
        RegAddrCity: 'DARLINGTON',
        RegAddrState: 'SC',
        RegAddrZip: 29532,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '657 HEATHWOOD DR',
        RegAddrCity: 'DARLINGTON',
        RegAddrState: 'SC',
        RegAddrZip: 29540,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '830 SEED TICK DR',
        RegAddrCity: 'DARLINGTON',
        RegAddrState: 'SC',
        RegAddrZip: 29540,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '425 LOCKEMY HWY',
        RegAddrCity: 'DILLON',
        RegAddrState: 'SC',
        RegAddrZip: 29536,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '700 EDGEWOOD BLVD',
        RegAddrCity: 'DILLON',
        RegAddrState: 'SC',
        RegAddrZip: 29536,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '812 S 9TH AVE',
        RegAddrCity: 'DILLON',
        RegAddrState: 'SC',
        RegAddrZip: 29536,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '3437 BUCKYS LN',
        RegAddrCity: 'EFFINGHAM',
        RegAddrState: 'SC',
        RegAddrZip: 29541,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '3709 COLONIAL RD',
        RegAddrCity: 'FLORENCE',
        RegAddrState: 'SC',
        RegAddrZip: 29501,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '4107 HEATHER DR',
        RegAddrCity: 'FLORENCE',
        RegAddrState: 'SC',
        RegAddrZip: 29501,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '607 WENSLEY CT',
        RegAddrCity: 'FLORENCE',
        RegAddrState: 'SC',
        RegAddrZip: 29501,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '2916 W PALMETTO ST',
        RegAddrCity: 'FLORENCE',
        RegAddrState: 'SC',
        RegAddrZip: 29501,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '3560 CHERRYWOOD RD',
        RegAddrCity: 'FLORENCE',
        RegAddrState: 'SC',
        RegAddrZip: 29501,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '3212 STRADA ANGELO',
        RegAddrCity: 'FLORENCE',
        RegAddrState: 'SC',
        RegAddrZip: 29501,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '1921 BARNWELL DR',
        RegAddrCity: 'FLORENCE',
        RegAddrState: 'SC',
        RegAddrZip: 29501,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '2110 FERNLEAF LN',
        RegAddrCity: 'FLORENCE',
        RegAddrState: 'SC',
        RegAddrZip: 29501,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '2519 KINGSTON DR',
        RegAddrCity: 'FLORENCE',
        RegAddrState: 'SC',
        RegAddrZip: 29505,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '507 SILVER LEAF RD',
        RegAddrCity: 'FLORENCE',
        RegAddrState: 'SC',
        RegAddrZip: 29505,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '1873 BRIGADOONE LN',
        RegAddrCity: 'FLORENCE',
        RegAddrState: 'SC',
        RegAddrZip: 29505,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '444 E GLENDALE DR',
        RegAddrCity: 'FLORENCE',
        RegAddrState: 'SC',
        RegAddrZip: 29506,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '910 SEWARD ST',
        RegAddrCity: 'FLORENCE',
        RegAddrState: 'SC',
        RegAddrZip: 29506,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '153 ROSE AVE',
        RegAddrCity: 'GEORGETOWN',
        RegAddrState: 'SC',
        RegAddrZip: 29440,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '782 COMANCHE DR',
        RegAddrCity: 'GEORGETOWN',
        RegAddrState: 'SC',
        RegAddrZip: 29440,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '1204 CUTTINO ST',
        RegAddrCity: 'GEORGETOWN',
        RegAddrState: 'SC',
        RegAddrZip: 29440,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '2214 MCKINNON DOVE RD',
        RegAddrCity: 'GRESHAM',
        RegAddrState: 'SC',
        RegAddrZip: 29546,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '2205 KNOTTY PINE CT',
        RegAddrCity: 'HAMER',
        RegAddrState: 'SC',
        RegAddrZip: 29547,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '437 JAMES AVE',
        RegAddrCity: 'HARTSVILLE',
        RegAddrState: 'SC',
        RegAddrZip: 29550,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '307 CHURCH AVE',
        RegAddrCity: 'HARTSVILLE',
        RegAddrState: 'SC',
        RegAddrZip: 29550,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '1237 SCOTT POND RD',
        RegAddrCity: 'HARTSVILLE',
        RegAddrState: 'SC',
        RegAddrZip: 29550,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '1804 GRANDVIEW DR',
        RegAddrCity: 'HARTSVILLE',
        RegAddrState: 'SC',
        RegAddrZip: 29550,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '309 BLUFF RD',
        RegAddrCity: 'HARTSVILLE',
        RegAddrState: 'SC',
        RegAddrZip: 29550,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '11026 CAROLINE ACRES RD',
        RegAddrCity: 'INDIAN LAND',
        RegAddrState: 'SC',
        RegAddrZip: 29707,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '505 CAMP RD',
        RegAddrCity: 'LATTA',
        RegAddrState: 'SC',
        RegAddrZip: 29565,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '4225 COQUINA HARBOUR DR',
        RegAddrCity: 'LITTLE RIVER',
        RegAddrState: 'SC',
        RegAddrZip: 29566,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '310 CIRCLE HEIGHTS DR',
        RegAddrCity: 'LORIS',
        RegAddrState: 'SC',
        RegAddrZip: 29569,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '125 TWIN CITY CIR',
        RegAddrCity: 'LORIS',
        RegAddrState: 'SC',
        RegAddrZip: 29569,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '132 KECIA RD',
        RegAddrCity: 'LORIS',
        RegAddrState: 'SC',
        RegAddrZip: 29569,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '1979 CANE BRANCH RD',
        RegAddrCity: 'LORIS',
        RegAddrState: 'SC',
        RegAddrZip: 29569,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '1516 EADDY CT',
        RegAddrCity: 'MARION',
        RegAddrState: 'SC',
        RegAddrZip: 29571,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '404 S BETHEA ST',
        RegAddrCity: 'MARION',
        RegAddrState: 'SC',
        RegAddrZip: 29571,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '312 S SEVENTH ST',
        RegAddrCity: 'MC BEE',
        RegAddrState: 'SC',
        RegAddrZip: 29101,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '19708 HWY 145',
        RegAddrCity: 'MCBEE',
        RegAddrState: 'SC',
        RegAddrZip: 29101,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '2604 RAILROAD AVE',
        RegAddrCity: 'MULLINS',
        RegAddrState: 'SC',
        RegAddrZip: 29574,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '3917 MARBLE RD',
        RegAddrCity: 'MULLINS',
        RegAddrState: 'SC',
        RegAddrZip: 29574,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '107 ROBIN CT',
        RegAddrCity: 'MURRELLS INLT',
        RegAddrState: 'SC',
        RegAddrZip: 29576,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '186 GRAYTWIG CIR',
        RegAddrCity: 'MURRELLS INLT',
        RegAddrState: 'SC',
        RegAddrZip: 29576,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '308 INLET RD',
        RegAddrCity: 'MURRELLS INLT',
        RegAddrState: 'SC',
        RegAddrZip: 29576,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '93 CENTER DR',
        RegAddrCity: 'MYRTLE BEACH',
        RegAddrState: 'SC',
        RegAddrZip: 29572,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '1784 CROOKED PINE DR',
        RegAddrCity: 'MYRTLE BEACH',
        RegAddrState: 'SC',
        RegAddrZip: 29575,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '1500 HIGHWAY 15',
        RegAddrCity: 'MYRTLE BEACH',
        RegAddrState: 'SC',
        RegAddrZip: 29577,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '4371 WILLOUGHBY LN',
        RegAddrCity: 'MYRTLE BEACH',
        RegAddrState: 'SC',
        RegAddrZip: 29577,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '3211 S OCEAN BLVD',
        RegAddrCity: 'MYRTLE BEACH',
        RegAddrState: 'SC',
        RegAddrZip: 29577,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '1385 CULBERTSON AVE',
        RegAddrCity: 'MYRTLE BEACH',
        RegAddrState: 'SC',
        RegAddrZip: 29577,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '529 MERLOT CT',
        RegAddrCity: 'MYRTLE BEACH',
        RegAddrState: 'SC',
        RegAddrZip: 29579,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '1204 WELFORD CT',
        RegAddrCity: 'MYRTLE BEACH',
        RegAddrState: 'SC',
        RegAddrZip: 29579,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '809 ASHLEAF DR',
        RegAddrCity: 'MYRTLE BEACH',
        RegAddrState: 'SC',
        RegAddrZip: 29579,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '4309 HUNTING BOW TRL',
        RegAddrCity: 'MYRTLE BEACH',
        RegAddrState: 'SC',
        RegAddrZip: 29579,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '9415 APPLESAUCE DR',
        RegAddrCity: 'MYRTLE BEACH',
        RegAddrState: 'SC',
        RegAddrZip: 29588,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '285 ARCHDALE ST',
        RegAddrCity: 'MYRTLE BEACH',
        RegAddrState: 'SC',
        RegAddrZip: 29588,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '105 PALMETTO GLEN DR',
        RegAddrCity: 'MYRTLE BEACH',
        RegAddrState: 'SC',
        RegAddrZip: 29588,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '708 GARLAND CT',
        RegAddrCity: 'MYRTLE BEACH',
        RegAddrState: 'SC',
        RegAddrZip: 29588,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '703 JERDON CIR',
        RegAddrCity: 'N MYRTLE BCH',
        RegAddrState: 'SC',
        RegAddrZip: 29582,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '1406 CIRCLE DR',
        RegAddrCity: 'N MYRTLE BCH',
        RegAddrState: 'SC',
        RegAddrZip: 29582,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '708 COMPASS POINTE DR',
        RegAddrCity: 'N MYRTLE BCH',
        RegAddrState: 'SC',
        RegAddrZip: 29582,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '7122 HORNSBORO RD',
        RegAddrCity: 'PAGELAND',
        RegAddrState: 'SC',
        RegAddrZip: 29728,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '1633 POND RD',
        RegAddrCity: 'PAMPLICO',
        RegAddrState: 'SC',
        RegAddrZip: 29583,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '634 E SHIRLEY RD',
        RegAddrCity: 'PAMPLICO',
        RegAddrState: 'SC',
        RegAddrZip: 29583,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '48 MILLBRANCH LN',
        RegAddrCity: 'PAWLEYS ISL',
        RegAddrState: 'SC',
        RegAddrZip: 29585,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '37 HEATHER DR',
        RegAddrCity: 'PAWLEYS ISL',
        RegAddrState: 'SC',
        RegAddrZip: 29585,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '285 SELLERS WATSON LN',
        RegAddrCity: 'RUBY',
        RegAddrState: 'SC',
        RegAddrZip: 29741,
        CongressionalDistrict: 7,
      },
    ],
  },
  {
    name: 'SD-0',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/SD-0?ref=gh-pages',
    state: 'SD',
    state_and_number: 'SD-0',
    zip: 57001,
    congressionalDistrict: 0,
    addressesInDistrict: [
      {
        RegAddrLine1: '379846 S SHORE DR',
        RegAddrCity: 'ABERDEEN',
        RegAddrState: 'SD',
        RegAddrZip: 57401,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '701 N KLINE ST',
        RegAddrCity: 'ABERDEEN',
        RegAddrState: 'SD',
        RegAddrZip: 57401,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '809 17TH AVE NE',
        RegAddrCity: 'ABERDEEN',
        RegAddrState: 'SD',
        RegAddrZip: 57401,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '317 S JACKSON ST',
        RegAddrCity: 'ABERDEEN',
        RegAddrState: 'SD',
        RegAddrZip: 57401,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '2017 N JAY ST',
        RegAddrCity: 'ABERDEEN',
        RegAddrState: 'SD',
        RegAddrZip: 57401,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '39923 SD HIGHWAY 44',
        RegAddrCity: 'ARMOUR',
        RegAddrState: 'SD',
        RegAddrZip: 57313,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1304 RIVERVIEW CIR',
        RegAddrCity: 'BELLE FOURCHE',
        RegAddrState: 'SD',
        RegAddrZip: 57717,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1707 8TH AVE',
        RegAddrCity: 'BELLE FOURCHE',
        RegAddrState: 'SD',
        RegAddrZip: 57717,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1001 W SPRUCE ST',
        RegAddrCity: 'BERESFORD',
        RegAddrState: 'SD',
        RegAddrZip: 57004,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '514 AMERICAS WAY',
        RegAddrCity: 'BOX ELDER',
        RegAddrState: 'SD',
        RegAddrZip: 57719,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '733 OLD CAVALRY RD',
        RegAddrCity: 'BOX ELDER',
        RegAddrState: 'SD',
        RegAddrZip: 57719,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '22719 155TH AVE',
        RegAddrCity: 'BOX ELDER',
        RegAddrState: 'SD',
        RegAddrZip: 57719,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '110 BOX ELDER RD',
        RegAddrCity: 'BOX ELDER',
        RegAddrState: 'SD',
        RegAddrZip: 57719,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '205 W GREENWOOD ST',
        RegAddrCity: 'BRANDON',
        RegAddrState: 'SD',
        RegAddrZip: 57005,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '26568 484TH AVE',
        RegAddrCity: 'BRANDON',
        RegAddrState: 'SD',
        RegAddrZip: 57005,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '112 E CEDAR ST',
        RegAddrCity: 'BRANDON',
        RegAddrState: 'SD',
        RegAddrZip: 57005,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '26014 478TH AVE',
        RegAddrCity: 'BRANDON',
        RegAddrState: 'SD',
        RegAddrZip: 57005,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: 'PO BOX 134',
        RegAddrCity: 'BRITTON',
        RegAddrState: 'SD',
        RegAddrZip: 57430,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1811 MORNINGSIDE DR',
        RegAddrCity: 'BROOKINGS',
        RegAddrState: 'SD',
        RegAddrZip: 57006,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '201 PINE RIDGE RD',
        RegAddrCity: 'BROOKINGS',
        RegAddrState: 'SD',
        RegAddrZip: 57006,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1724 TETON PASS',
        RegAddrCity: 'BROOKINGS',
        RegAddrState: 'SD',
        RegAddrZip: 57006,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '508 SOUTHVIEW DR',
        RegAddrCity: 'BROOKINGS',
        RegAddrState: 'SD',
        RegAddrZip: 57006,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1721 15TH ST S',
        RegAddrCity: 'BROOKINGS',
        RegAddrState: 'SD',
        RegAddrZip: 57006,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '2336 CASCADE CIR',
        RegAddrCity: 'BROOKINGS',
        RegAddrState: 'SD',
        RegAddrZip: 57006,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '351 CALDWELL HALL',
        RegAddrCity: 'BROOKINGS',
        RegAddrState: 'SD',
        RegAddrZip: 57007,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '121 N 4TH AVE',
        RegAddrCity: 'CANISTOTA',
        RegAddrState: 'SD',
        RegAddrZip: 57012,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '19221 404TH AVE',
        RegAddrCity: 'CARPENTER',
        RegAddrState: 'SD',
        RegAddrZip: 57322,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '45570 295TH ST',
        RegAddrCity: 'CENTERVILLE',
        RegAddrState: 'SD',
        RegAddrZip: 57014,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '213 W KELLAM AVE',
        RegAddrCity: 'CHAMBERLAIN',
        RegAddrState: 'SD',
        RegAddrZip: 57325,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '200 PROSPECT AVE',
        RegAddrCity: 'CHAMBERLAIN',
        RegAddrState: 'SD',
        RegAddrZip: 57325,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '43020 SILVER LAKE DR',
        RegAddrCity: 'CLARK',
        RegAddrState: 'SD',
        RegAddrZip: 57225,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '226 NW 3RD ST',
        RegAddrCity: 'COLMAN',
        RegAddrState: 'SD',
        RegAddrZip: 57017,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '401 FAIRWAY DR',
        RegAddrCity: 'COLMAN',
        RegAddrState: 'SD',
        RegAddrZip: 57017,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '47039 254TH ST',
        RegAddrCity: 'CROOKS',
        RegAddrState: 'SD',
        RegAddrZip: 57020,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '404 W 9TH ST',
        RegAddrCity: 'DELL RAPIDS',
        RegAddrState: 'SD',
        RegAddrZip: 57022,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '203 W 7TH ST',
        RegAddrCity: 'DELL RAPIDS',
        RegAddrState: 'SD',
        RegAddrZip: 57022,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '539 KNOTS LNDG',
        RegAddrCity: 'EAGLE BUTTE',
        RegAddrState: 'SD',
        RegAddrZip: 57625,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: 'NO111 A ST BLUE GOVERNOR',
        RegAddrCity: 'EAGLE BUTTE',
        RegAddrState: 'SD',
        RegAddrZip: 57625,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: 'N HEART 1068 RD',
        RegAddrCity: 'EAGLE BUTTE',
        RegAddrState: 'SD',
        RegAddrZip: 57625,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '31775 477TH AVE',
        RegAddrCity: 'ELK POINT',
        RegAddrState: 'SD',
        RegAddrZip: 57025,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1701 JACK NICKLAUS DR',
        RegAddrCity: 'ELK POINT',
        RegAddrState: 'SD',
        RegAddrZip: 57025,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '2630 ARNOLD DR',
        RegAddrCity: 'ELLSWORTH AFB',
        RegAddrState: 'SD',
        RegAddrZip: 57706,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '306 6TH ST S',
        RegAddrCity: 'ESTELLINE',
        RegAddrState: 'SD',
        RegAddrZip: 57234,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '208 4TH ST N',
        RegAddrCity: 'ESTELLINE',
        RegAddrState: 'SD',
        RegAddrZip: 57234,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1116 VFW RD',
        RegAddrCity: 'FAULKTON',
        RegAddrState: 'SD',
        RegAddrZip: 57438,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '809 N MARION ST',
        RegAddrCity: 'FORT PIERRE',
        RegAddrState: 'SD',
        RegAddrZip: 57532,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '412 DAKOTA AVE',
        RegAddrCity: 'FORT PIERRE',
        RegAddrState: 'SD',
        RegAddrZip: 57532,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '202 S ALBERT ST',
        RegAddrCity: 'FREEMAN',
        RegAddrState: 'SD',
        RegAddrZip: 57029,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '212 N WALNUT ST',
        RegAddrCity: 'FREEMAN',
        RegAddrState: 'SD',
        RegAddrZip: 57029,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '48130 250TH ST',
        RegAddrCity: 'GARRETSON',
        RegAddrState: 'SD',
        RegAddrZip: 57030,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '2918 EDGEWATER DR',
        RegAddrCity: 'GARY',
        RegAddrState: 'SD',
        RegAddrZip: 57237,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '402 WHITTECAR AVE',
        RegAddrCity: 'GREGORY',
        RegAddrState: 'SD',
        RegAddrZip: 57533,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '511 N WASHINGTON ST',
        RegAddrCity: 'GROTON',
        RegAddrState: 'SD',
        RegAddrZip: 57445,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '500 E MAPLE ST',
        RegAddrCity: 'HARRISBURG',
        RegAddrState: 'SD',
        RegAddrZip: 57032,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '502 S SHEBAL AVE',
        RegAddrCity: 'HARRISBURG',
        RegAddrState: 'SD',
        RegAddrZip: 57032,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '105 PERRY LN',
        RegAddrCity: 'HARRISBURG',
        RegAddrState: 'SD',
        RegAddrZip: 57032,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '26442 464TH AVE',
        RegAddrCity: 'HARTFORD',
        RegAddrState: 'SD',
        RegAddrZip: 57033,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '306 N MAIN AVE',
        RegAddrCity: 'HARTFORD',
        RegAddrState: 'SD',
        RegAddrZip: 57033,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '18480 397TH AVE',
        RegAddrCity: 'HITCHCOCK',
        RegAddrState: 'SD',
        RegAddrZip: 57348,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '12742 US HIGHWAY 18',
        RegAddrCity: 'HOT SPRINGS',
        RegAddrState: 'SD',
        RegAddrZip: 57747,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '27555 DENISE DR',
        RegAddrCity: 'HOT SPRINGS',
        RegAddrState: 'SD',
        RegAddrZip: 57747,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '557 1/2 DAKOTA AVE N',
        RegAddrCity: 'HURON',
        RegAddrState: 'SD',
        RegAddrZip: 57350,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '753 NEBRASKA AVE SW',
        RegAddrCity: 'HURON',
        RegAddrState: 'SD',
        RegAddrZip: 57350,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '40425 202ND ST',
        RegAddrCity: 'HURON',
        RegAddrState: 'SD',
        RegAddrZip: 57350,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: 'PO BOX 123',
        RegAddrCity: 'IRENE',
        RegAddrState: 'SD',
        RegAddrZip: 57037,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '41169 210TH ST',
        RegAddrCity: 'IROQUOIS',
        RegAddrState: 'SD',
        RegAddrZip: 57353,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: 'RURAL ISABEL',
        RegAddrCity: 'ISABEL',
        RegAddrState: 'SD',
        RegAddrZip: 57633,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: 'MATO PTE BLO KA AVE',
        RegAddrCity: 'KYLE',
        RegAddrState: 'SD',
        RegAddrZip: 57752,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '310 MAIN ST',
        RegAddrCity: 'LEOLA',
        RegAddrState: 'SD',
        RegAddrZip: 57456,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '508 NE 3RD ST',
        RegAddrCity: 'MADISON',
        RegAddrState: 'SD',
        RegAddrZip: 57042,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: 'PO BOX 432',
        RegAddrCity: 'MANDERSON',
        RegAddrState: 'SD',
        RegAddrZip: 57756,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: 'PO BOX 38',
        RegAddrCity: 'MARTIN',
        RegAddrState: 'SD',
        RegAddrZip: 57551,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '22507 297TH ST',
        RegAddrCity: 'MARTIN',
        RegAddrState: 'SD',
        RegAddrZip: 57551,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '602 MAIN ST',
        RegAddrCity: 'MC LAUGHLIN',
        RegAddrState: 'SD',
        RegAddrZip: 57642,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '23467 US HIGHWAY 14',
        RegAddrCity: 'MIDLAND',
        RegAddrState: 'SD',
        RegAddrZip: 57552,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '25437 CALHOON RD',
        RegAddrCity: 'MIDLAND',
        RegAddrState: 'SD',
        RegAddrZip: 57552,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '301 S 7TH ST',
        RegAddrCity: 'MILBANK',
        RegAddrState: 'SD',
        RegAddrZip: 57252,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '14417 479TH AVE',
        RegAddrCity: 'MILBANK',
        RegAddrState: 'SD',
        RegAddrZip: 57252,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1501 ELMWOOD AVE',
        RegAddrCity: 'MILBANK',
        RegAddrState: 'SD',
        RegAddrZip: 57252,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '517 S GRANT ST',
        RegAddrCity: 'MILBANK',
        RegAddrState: 'SD',
        RegAddrZip: 57252,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '36191 218TH ST',
        RegAddrCity: 'MILLER',
        RegAddrState: 'SD',
        RegAddrZip: 57362,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '36884 143RD ST',
        RegAddrCity: 'MINA',
        RegAddrState: 'SD',
        RegAddrZip: 57451,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '612 S WISCONSIN ST',
        RegAddrCity: 'MITCHELL',
        RegAddrState: 'SD',
        RegAddrZip: 57301,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '905 W HANSON AVE',
        RegAddrCity: 'MITCHELL',
        RegAddrState: 'SD',
        RegAddrZip: 57301,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '415 S EDMUNDS ST',
        RegAddrCity: 'MITCHELL',
        RegAddrState: 'SD',
        RegAddrZip: 57301,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '620 S ANDERSON ST',
        RegAddrCity: 'MITCHELL',
        RegAddrState: 'SD',
        RegAddrZip: 57301,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '707 E 7TH AVE',
        RegAddrCity: 'MITCHELL',
        RegAddrState: 'SD',
        RegAddrZip: 57301,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '39690 253RD ST',
        RegAddrCity: 'MOUNT VERNON',
        RegAddrState: 'SD',
        RegAddrZip: 57363,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: 'PO BOX 534',
        RegAddrCity: 'MURDO',
        RegAddrState: 'SD',
        RegAddrZip: 57559,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '35 SPANISH BAY',
        RegAddrCity: 'N SIOUX CITY',
        RegAddrState: 'SD',
        RegAddrZip: 57049,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '124 COTTS DR',
        RegAddrCity: 'N SIOUX CITY',
        RegAddrState: 'SD',
        RegAddrZip: 57049,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '107 W 8TH ST',
        RegAddrCity: 'OELRICHS',
        RegAddrState: 'SD',
        RegAddrZip: 57763,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '31030 179TH ST',
        RegAddrCity: 'ONIDA',
        RegAddrState: 'SD',
        RegAddrZip: 57564,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '207 S BEN ST',
        RegAddrCity: 'PARKSTON',
        RegAddrState: 'SD',
        RegAddrZip: 57366,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '510 E DUPREE ST',
        RegAddrCity: 'PHILIP',
        RegAddrState: 'SD',
        RegAddrZip: 57567,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '511 JAMES AVE',
        RegAddrCity: 'PICKSTOWN',
        RegAddrState: 'SD',
        RegAddrZip: 57367,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '12061 LITTLE ELK CREEK RD',
        RegAddrCity: 'PIEDMONT',
        RegAddrState: 'SD',
        RegAddrZip: 57769,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '29718 SD HIGHWAY 248',
        RegAddrCity: 'PRESHO',
        RegAddrState: 'SD',
        RegAddrZip: 57568,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '112 STOCKWELL ST',
        RegAddrCity: 'PUKWANA',
        RegAddrState: 'SD',
        RegAddrZip: 57370,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '44439 226TH ST',
        RegAddrCity: 'RAMONA',
        RegAddrState: 'SD',
        RegAddrZip: 57054,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '817 E ELK ST',
        RegAddrCity: 'RAPID CITY',
        RegAddrState: 'SD',
        RegAddrZip: 57701,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '610 ALTA VISTA DR',
        RegAddrCity: 'RAPID CITY',
        RegAddrState: 'SD',
        RegAddrZip: 57701,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '801 SAINT JOSEPH ST',
        RegAddrCity: 'RAPID CITY',
        RegAddrState: 'SD',
        RegAddrZip: 57701,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '216 SAINT PATRICK ST',
        RegAddrCity: 'RAPID CITY',
        RegAddrState: 'SD',
        RegAddrZip: 57701,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '107 FRANKLIN ST',
        RegAddrCity: 'RAPID CITY',
        RegAddrState: 'SD',
        RegAddrZip: 57701,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1620 N 7TH ST',
        RegAddrCity: 'RAPID CITY',
        RegAddrState: 'SD',
        RegAddrZip: 57701,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '2003 PRAIRIE AVE',
        RegAddrCity: 'RAPID CITY',
        RegAddrState: 'SD',
        RegAddrZip: 57701,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '23565 BURGESS RD',
        RegAddrCity: 'RAPID CITY',
        RegAddrState: 'SD',
        RegAddrZip: 57702,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '4200 CALLE BAJA ST',
        RegAddrCity: 'RAPID CITY',
        RegAddrState: 'SD',
        RegAddrZip: 57702,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '4313 TIMOTHY ST',
        RegAddrCity: 'RAPID CITY',
        RegAddrState: 'SD',
        RegAddrZip: 57702,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '12510 HORSE CREEK RD',
        RegAddrCity: 'RAPID CITY',
        RegAddrState: 'SD',
        RegAddrZip: 57702,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '8003 ALBERTTA DR',
        RegAddrCity: 'RAPID CITY',
        RegAddrState: 'SD',
        RegAddrZip: 57702,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '3507 WESTRIDGE RD',
        RegAddrCity: 'RAPID CITY',
        RegAddrState: 'SD',
        RegAddrZip: 57702,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '3425 WESSON RD',
        RegAddrCity: 'RAPID CITY',
        RegAddrState: 'SD',
        RegAddrZip: 57703,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '3208 JOHNSTON LN',
        RegAddrCity: 'RAPID CITY',
        RegAddrState: 'SD',
        RegAddrZip: 57703,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '2220 PLATEAU LN',
        RegAddrCity: 'RAPID CITY',
        RegAddrState: 'SD',
        RegAddrZip: 57703,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '37304 SD HIGHWAY 26',
        RegAddrCity: 'REDFIELD',
        RegAddrState: 'SD',
        RegAddrZip: 57469,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '708 E 7TH AVE',
        RegAddrCity: 'REDFIELD',
        RegAddrState: 'SD',
        RegAddrZip: 57469,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: 'PO BOX 728',
        RegAddrCity: 'ROSEBUD',
        RegAddrState: 'SD',
        RegAddrZip: 57570,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '5 JORDAN ST',
        RegAddrCity: 'ROSEBUD',
        RegAddrState: 'SD',
        RegAddrZip: 57570,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '47245 100TH ST',
        RegAddrCity: 'ROSHOLT',
        RegAddrState: 'SD',
        RegAddrZip: 57260,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '# 314',
        RegAddrCity: 'Saint Francis',
        RegAddrState: 'SD',
        RegAddrZip: 57572,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '4416 E SCRANTON ST',
        RegAddrCity: 'SIOUX FALLS',
        RegAddrState: 'SD',
        RegAddrZip: 57103,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '2905 E STONEHEDGE LN',
        RegAddrCity: 'SIOUX FALLS',
        RegAddrState: 'SD',
        RegAddrZip: 57103,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '417 S THOMPSON AVE',
        RegAddrCity: 'SIOUX FALLS',
        RegAddrState: 'SD',
        RegAddrZip: 57103,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '504 N INDIANA AVE',
        RegAddrCity: 'SIOUX FALLS',
        RegAddrState: 'SD',
        RegAddrZip: 57103,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '4217 S SOUTHEASTERN AVE',
        RegAddrCity: 'SIOUX FALLS',
        RegAddrState: 'SD',
        RegAddrZip: 57103,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '918 W 4TH ST',
        RegAddrCity: 'SIOUX FALLS',
        RegAddrState: 'SD',
        RegAddrZip: 57104,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '3916 N POTSDAM AVE',
        RegAddrCity: 'SIOUX FALLS',
        RegAddrState: 'SD',
        RegAddrZip: 57104,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '703 N PRAIRIE AVE',
        RegAddrCity: 'SIOUX FALLS',
        RegAddrState: 'SD',
        RegAddrZip: 57104,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '333 S SPRING AVE',
        RegAddrCity: 'SIOUX FALLS',
        RegAddrState: 'SD',
        RegAddrZip: 57104,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '3916 N POTSDAM AVE',
        RegAddrCity: 'SIOUX FALLS',
        RegAddrState: 'SD',
        RegAddrZip: 57104,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '522 N WARNER BROTHERS PL',
        RegAddrCity: 'SIOUX FALLS',
        RegAddrState: 'SD',
        RegAddrZip: 57104,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1717 S WEST AVE',
        RegAddrCity: 'SIOUX FALLS',
        RegAddrState: 'SD',
        RegAddrZip: 57105,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1924 S 4TH AVE',
        RegAddrCity: 'SIOUX FALLS',
        RegAddrState: 'SD',
        RegAddrZip: 57105,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '920 W 36TH ST',
        RegAddrCity: 'SIOUX FALLS',
        RegAddrState: 'SD',
        RegAddrZip: 57105,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '312 E SUNNYBROOK DR',
        RegAddrCity: 'SIOUX FALLS',
        RegAddrState: 'SD',
        RegAddrZip: 57105,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '6812 W 64TH ST',
        RegAddrCity: 'SIOUX FALLS',
        RegAddrState: 'SD',
        RegAddrZip: 57106,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '4412 S TRIBBEY TRL',
        RegAddrCity: 'SIOUX FALLS',
        RegAddrState: 'SD',
        RegAddrZip: 57106,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '5813 W SIOUX K CT',
        RegAddrCity: 'SIOUX FALLS',
        RegAddrState: 'SD',
        RegAddrZip: 57106,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '5101 W 40TH ST',
        RegAddrCity: 'SIOUX FALLS',
        RegAddrState: 'SD',
        RegAddrZip: 57106,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '5704 W WESTMINSTER DR',
        RegAddrCity: 'SIOUX FALLS',
        RegAddrState: 'SD',
        RegAddrZip: 57106,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '4800 S SERTOMA AVE',
        RegAddrCity: 'SIOUX FALLS',
        RegAddrState: 'SD',
        RegAddrZip: 57106,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '46729 DOROTHY DR',
        RegAddrCity: 'SIOUX FALLS',
        RegAddrState: 'SD',
        RegAddrZip: 57107,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '7312 S GRAND ARBOR CT',
        RegAddrCity: 'SIOUX FALLS',
        RegAddrState: 'SD',
        RegAddrZip: 57108,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '5503 S WOODSEDGE TRL',
        RegAddrCity: 'SIOUX FALLS',
        RegAddrState: 'SD',
        RegAddrZip: 57108,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '2821 W 95TH ST',
        RegAddrCity: 'SIOUX FALLS',
        RegAddrState: 'SD',
        RegAddrZip: 57108,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '4909 S LEWIS AVE',
        RegAddrCity: 'SIOUX FALLS',
        RegAddrState: 'SD',
        RegAddrZip: 57108,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '5501 E RYAN PL',
        RegAddrCity: 'SIOUX FALLS',
        RegAddrState: 'SD',
        RegAddrZip: 57110,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '4701 E CYNTHIA DR',
        RegAddrCity: 'SIOUX FALLS',
        RegAddrState: 'SD',
        RegAddrZip: 57110,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '528 N DUBUQUE AVE',
        RegAddrCity: 'SIOUX FALLS',
        RegAddrState: 'SD',
        RegAddrZip: 57110,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '4709 E 42ND ST',
        RegAddrCity: 'SIOUX FALLS',
        RegAddrState: 'SD',
        RegAddrZip: 57110,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '612 SPRUCE ST W',
        RegAddrCity: 'SISSETON',
        RegAddrState: 'SD',
        RegAddrZip: 57262,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '225 W OHIO ST',
        RegAddrCity: 'SPEARFISH',
        RegAddrState: 'SD',
        RegAddrZip: 57783,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '230 HARNEY PEAK CT',
        RegAddrCity: 'SPEARFISH',
        RegAddrState: 'SD',
        RegAddrZip: 57783,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1806 RIVER VIEW CIR',
        RegAddrCity: 'SPEARFISH',
        RegAddrState: 'SD',
        RegAddrZip: 57783,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '301 3RD ST NW',
        RegAddrCity: 'ST LAWRENCE',
        RegAddrState: 'SD',
        RegAddrZip: 57373,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '20470 SD HIGHWAY 79',
        RegAddrCity: 'STURGIS',
        RegAddrState: 'SD',
        RegAddrZip: 57785,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1209 PINE VIEW DR',
        RegAddrCity: 'STURGIS',
        RegAddrState: 'SD',
        RegAddrZip: 57785,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '20758 MOUNTAIN CT',
        RegAddrCity: 'STURGIS',
        RegAddrState: 'SD',
        RegAddrZip: 57785,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '7195 MULBERRY DR',
        RegAddrCity: 'SUMMERSET',
        RegAddrState: 'SD',
        RegAddrZip: 57718,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '15114 458TH AVE',
        RegAddrCity: 'SUMMIT',
        RegAddrState: 'SD',
        RegAddrZip: 57266,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '110 4TH ST',
        RegAddrCity: 'TIMBER LAKE',
        RegAddrState: 'SD',
        RegAddrZip: 57656,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1209 E CHERRY ST',
        RegAddrCity: 'VERMILLION',
        RegAddrState: 'SD',
        RegAddrZip: 57069,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '2817 PETTERSEN RD',
        RegAddrCity: 'VERMILLION',
        RegAddrState: 'SD',
        RegAddrZip: 57069,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '608 S CRAWFORD RD',
        RegAddrCity: 'VERMILLION',
        RegAddrState: 'SD',
        RegAddrZip: 57069,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '46048 TIMBER RD',
        RegAddrCity: 'VERMILLION',
        RegAddrState: 'SD',
        RegAddrZip: 57069,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '207 HIGH AVE NW',
        RegAddrCity: 'WAGNER',
        RegAddrState: 'SD',
        RegAddrZip: 57380,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '3420 11TH AVE SW',
        RegAddrCity: 'WATERTOWN',
        RegAddrState: 'SD',
        RegAddrZip: 57201,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1420 N HIGHWAY 20',
        RegAddrCity: 'WATERTOWN',
        RegAddrState: 'SD',
        RegAddrZip: 57201,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '163 8TH ST NE',
        RegAddrCity: 'WATERTOWN',
        RegAddrState: 'SD',
        RegAddrZip: 57201,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1805 7TH ST NE',
        RegAddrCity: 'WATERTOWN',
        RegAddrState: 'SD',
        RegAddrZip: 57201,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '421 2ND ST SE',
        RegAddrCity: 'WATERTOWN',
        RegAddrState: 'SD',
        RegAddrZip: 57201,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '815 W KEMP AVE',
        RegAddrCity: 'WATERTOWN',
        RegAddrState: 'SD',
        RegAddrZip: 57201,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: 'PO BOX 420',
        RegAddrCity: 'WHITE RIVER',
        RegAddrState: 'SD',
        RegAddrZip: 57579,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '512 N MCKINLEY AVE',
        RegAddrCity: 'WHITE RIVER',
        RegAddrState: 'SD',
        RegAddrZip: 57579,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '900 LAUREL ST',
        RegAddrCity: 'WHITEWOOD',
        RegAddrState: 'SD',
        RegAddrZip: 57793,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '27200 SD HIGHWAY 44',
        RegAddrCity: 'WITTEN',
        RegAddrState: 'SD',
        RegAddrZip: 57584,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: 'PO BOX 153',
        RegAddrCity: 'WOONSOCKET',
        RegAddrState: 'SD',
        RegAddrZip: 57385,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '30426 437TH AVE',
        RegAddrCity: 'YANKTON',
        RegAddrState: 'SD',
        RegAddrZip: 57078,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1210 PEYTON LN',
        RegAddrCity: 'YANKTON',
        RegAddrState: 'SD',
        RegAddrZip: 57078,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1000 W 11TH ST',
        RegAddrCity: 'YANKTON',
        RegAddrState: 'SD',
        RegAddrZip: 57078,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '42985 SD HIGHWAY 52',
        RegAddrCity: 'YANKTON',
        RegAddrState: 'SD',
        RegAddrZip: 57078,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '30500 US HIGHWAY 81',
        RegAddrCity: 'YANKTON',
        RegAddrState: 'SD',
        RegAddrZip: 57078,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1114 WHITING DR',
        RegAddrCity: 'YANKTON',
        RegAddrState: 'SD',
        RegAddrZip: 57078,
        CongressionalDistrict: 0,
      },
    ],
  },
  {
    name: 'TN-1',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/TN-1?ref=gh-pages',
    state: 'TN',
    state_and_number: 'TN-1',
    zip: 37601,
    congressionalDistrict: 1,
    addressesInDistrict: [
      {
        RegAddrLine1: '467 BUNCOMBE RD',
        RegAddrCity: 'BLOUNTVILLE',
        RegAddrState: 'TN',
        RegAddrZip: 37617,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '454 UNDERWOOD SPRINGS BRANCH RD',
        RegAddrCity: 'BLUFF CITY',
        RegAddrState: 'TN',
        RegAddrZip: 37618,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '201 SPRING BROOK DR',
        RegAddrCity: 'BRISTOL',
        RegAddrState: 'TN',
        RegAddrZip: 37620,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2138 6TH ST',
        RegAddrCity: 'BRISTOL',
        RegAddrState: 'TN',
        RegAddrZip: 37620,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '3012 TONY DR',
        RegAddrCity: 'BULLS GAP',
        RegAddrState: 'TN',
        RegAddrZip: 37711,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '5037 HIGHWAY 321',
        RegAddrCity: 'BUTLER',
        RegAddrState: 'TN',
        RegAddrZip: 37640,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '242 AIRPORT RD',
        RegAddrCity: 'BYBEE',
        RegAddrState: 'TN',
        RegAddrZip: 37713,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '215 CANNON LOOP',
        RegAddrCity: 'CHUCKEY',
        RegAddrState: 'TN',
        RegAddrZip: 37641,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1108 GRANADA CT',
        RegAddrCity: 'CHURCH HILL',
        RegAddrState: 'TN',
        RegAddrZip: 37642,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '125 STURBRIDGE LN',
        RegAddrCity: 'CHURCH HILL',
        RegAddrState: 'TN',
        RegAddrZip: 37642,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '538 PUNKTON RD',
        RegAddrCity: 'DEL RIO',
        RegAddrState: 'TN',
        RegAddrZip: 37727,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '212 E G ST',
        RegAddrCity: 'ELIZABETHTON',
        RegAddrState: 'TN',
        RegAddrZip: 37643,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '401 FERGUSON AVE',
        RegAddrCity: 'ELIZABETHTON',
        RegAddrState: 'TN',
        RegAddrZip: 37643,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '114 RIDGECREST DR',
        RegAddrCity: 'ELIZABETHTON',
        RegAddrState: 'TN',
        RegAddrZip: 37643,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1226 OLDE OAKS DR',
        RegAddrCity: 'GRAY',
        RegAddrState: 'TN',
        RegAddrZip: 37615,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '132 REBECCAS LN',
        RegAddrCity: 'GRAY',
        RegAddrState: 'TN',
        RegAddrZip: 37615,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1914 W MAIN ST',
        RegAddrCity: 'GREENEVILLE',
        RegAddrState: 'TN',
        RegAddrZip: 37743,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '111 CATES COR',
        RegAddrCity: 'HAMPTON',
        RegAddrState: 'TN',
        RegAddrZip: 37658,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '132 CEDAR GROVE RD',
        RegAddrCity: 'JOHNSON CITY',
        RegAddrState: 'TN',
        RegAddrZip: 37601,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '125 W MAIN ST',
        RegAddrCity: 'JOHNSON CITY',
        RegAddrState: 'TN',
        RegAddrZip: 37604,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2 WALNUT GROVE CT',
        RegAddrCity: 'JONESBOROUGH',
        RegAddrState: 'TN',
        RegAddrZip: 37659,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '108 HERITAGE CT',
        RegAddrCity: 'JONESBOROUGH',
        RegAddrState: 'TN',
        RegAddrZip: 37659,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '450 SAND VALLEY RD',
        RegAddrCity: 'JONESBOROUGH',
        RegAddrState: 'TN',
        RegAddrZip: 37659,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '397 SWEETGRASS LN',
        RegAddrCity: 'JONESBOROUGH',
        RegAddrState: 'TN',
        RegAddrZip: 37659,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '4016 BLOOMINGDALE RD',
        RegAddrCity: 'KINGSPORT',
        RegAddrState: 'TN',
        RegAddrZip: 37660,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1919 BIRCHFIELD PVT CT',
        RegAddrCity: 'KINGSPORT',
        RegAddrState: 'TN',
        RegAddrZip: 37660,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '524 KALLEN DR',
        RegAddrCity: 'KINGSPORT',
        RegAddrState: 'TN',
        RegAddrZip: 37660,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1341 MCGREGOR DR',
        RegAddrCity: 'KINGSPORT',
        RegAddrState: 'TN',
        RegAddrZip: 37660,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1189 KENDRICK CREEK RD',
        RegAddrCity: 'KINGSPORT',
        RegAddrState: 'TN',
        RegAddrZip: 37663,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1478 LEBANON RD',
        RegAddrCity: 'KINGSPORT',
        RegAddrState: 'TN',
        RegAddrZip: 37663,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '3917 INGRAM ST',
        RegAddrCity: 'KINGSPORT',
        RegAddrState: 'TN',
        RegAddrZip: 37664,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1316 TERRAPIN CREEK RD',
        RegAddrCity: 'LOBELVILLE',
        RegAddrState: 'TN',
        RegAddrZip: 37097,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '4444 DANBURY DR',
        RegAddrCity: 'MORRISTOWN',
        RegAddrState: 'TN',
        RegAddrZip: 37813,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1057 JAYBIRD RD',
        RegAddrCity: 'MORRISTOWN',
        RegAddrState: 'TN',
        RegAddrZip: 37814,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1016 TENNESSEE HILLS DR',
        RegAddrCity: 'MORRISTOWN',
        RegAddrState: 'TN',
        RegAddrZip: 37814,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '843 HEMLOCK CIR',
        RegAddrCity: 'MORRISTOWN',
        RegAddrState: 'TN',
        RegAddrZip: 37814,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '418 FENWAY DR',
        RegAddrCity: 'PIGEON FORGE',
        RegAddrState: 'TN',
        RegAddrZip: 37863,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '185 SUGAR HOLLOW RD',
        RegAddrCity: 'ROAN MOUNTAIN',
        RegAddrState: 'TN',
        RegAddrZip: 37687,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '209 CONWAY ST',
        RegAddrCity: 'ROAN MOUNTAIN',
        RegAddrState: 'TN',
        RegAddrZip: 37687,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '3428 COVE MEADOWS DR',
        RegAddrCity: 'SEVIERVILLE',
        RegAddrState: 'TN',
        RegAddrZip: 37862,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '255 PIGEON RIVER RD',
        RegAddrCity: 'SEVIERVILLE',
        RegAddrState: 'TN',
        RegAddrZip: 37862,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '4059 WEARS COVE RD',
        RegAddrCity: 'SEVIERVILLE',
        RegAddrState: 'TN',
        RegAddrZip: 37862,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2040 OLD NEWPORT HWY',
        RegAddrCity: 'SEVIERVILLE',
        RegAddrState: 'TN',
        RegAddrZip: 37876,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1303 NEWSOME RD',
        RegAddrCity: 'SEVIERVILLE',
        RegAddrState: 'TN',
        RegAddrZip: 37876,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '630 HILLS GATE CIR',
        RegAddrCity: 'SEYMOUR',
        RegAddrState: 'TN',
        RegAddrZip: 37865,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1685 CANEY VALLEY LOOP',
        RegAddrCity: 'SURGOINSVILLE',
        RegAddrState: 'TN',
        RegAddrZip: 37873,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1152 BARKLEY RD',
        RegAddrCity: 'TELFORD',
        RegAddrState: 'TN',
        RegAddrZip: 37690,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '4476 HIGHWAY 131',
        RegAddrCity: 'THORN HILL',
        RegAddrState: 'TN',
        RegAddrZip: 37881,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1356 SUNTREE RD',
        RegAddrCity: 'UNICOI',
        RegAddrState: 'TN',
        RegAddrZip: 37692,
        CongressionalDistrict: 1,
      },
    ],
  },
  {
    name: 'TN-2',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/TN-2?ref=gh-pages',
    state: 'TN',
    state_and_number: 'TN-2',
    zip: 37701,
    congressionalDistrict: 2,
    addressesInDistrict: [
      {
        RegAddrLine1: '732 CLIFF TOP RD',
        RegAddrCity: 'BLAINE',
        RegAddrState: 'TN',
        RegAddrZip: 37709,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '7702 KARNES RD',
        RegAddrCity: 'CORRYTON',
        RegAddrState: 'TN',
        RegAddrZip: 37721,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '122 BUCK TRL',
        RegAddrCity: 'CUMB GAP',
        RegAddrState: 'TN',
        RegAddrZip: 37724,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '125 PANTHER DR',
        RegAddrCity: 'CUMB GAP',
        RegAddrState: 'TN',
        RegAddrZip: 37724,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '4039 BIG SPRINGS RIDGE RD',
        RegAddrCity: 'FRIENDSVILLE',
        RegAddrState: 'TN',
        RegAddrZip: 37737,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '5057 GREGORY RD',
        RegAddrCity: 'GREENBACK',
        RegAddrState: 'TN',
        RegAddrZip: 37742,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1832 AMHERST RD',
        RegAddrCity: 'KNOXVILLE',
        RegAddrState: 'TN',
        RegAddrZip: 37909,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '5605 CHESSWOOD DR',
        RegAddrCity: 'KNOXVILLE',
        RegAddrState: 'TN',
        RegAddrZip: 37912,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '4715 SUNSET RD',
        RegAddrCity: 'KNOXVILLE',
        RegAddrState: 'TN',
        RegAddrZip: 37914,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1518 ROBERT HUFF LN',
        RegAddrCity: 'KNOXVILLE',
        RegAddrState: 'TN',
        RegAddrZip: 37914,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '700 GRAVES ST',
        RegAddrCity: 'KNOXVILLE',
        RegAddrState: 'TN',
        RegAddrZip: 37915,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1410 GRAINGER AVE',
        RegAddrCity: 'KNOXVILLE',
        RegAddrState: 'TN',
        RegAddrZip: 37917,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1524 ELLERY LN',
        RegAddrCity: 'KNOXVILLE',
        RegAddrState: 'TN',
        RegAddrZip: 37918,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '3006 RENNOC RD',
        RegAddrCity: 'KNOXVILLE',
        RegAddrState: 'TN',
        RegAddrZip: 37918,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '5606 DOGWOOD RD',
        RegAddrCity: 'KNOXVILLE',
        RegAddrState: 'TN',
        RegAddrZip: 37918,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '831 BROWN RD',
        RegAddrCity: 'KNOXVILLE',
        RegAddrState: 'TN',
        RegAddrZip: 37920,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '5209 WEBBER RD',
        RegAddrCity: 'KNOXVILLE',
        RegAddrState: 'TN',
        RegAddrZip: 37920,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '8833 JETT RD',
        RegAddrCity: 'KNOXVILLE',
        RegAddrState: 'TN',
        RegAddrZip: 37920,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '4001 MALONEY RD',
        RegAddrCity: 'KNOXVILLE',
        RegAddrState: 'TN',
        RegAddrZip: 37920,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '901 KNOXVILLE COLLEGE DR',
        RegAddrCity: 'KNOXVILLE',
        RegAddrState: 'TN',
        RegAddrZip: 37921,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '5802 METROPOLITAN WAY',
        RegAddrCity: 'KNOXVILLE',
        RegAddrState: 'TN',
        RegAddrZip: 37921,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '3112 BIRCHWOOD RD',
        RegAddrCity: 'KNOXVILLE',
        RegAddrState: 'TN',
        RegAddrZip: 37921,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '712 ELKMONT RD',
        RegAddrCity: 'KNOXVILLE',
        RegAddrState: 'TN',
        RegAddrZip: 37922,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1606 DUNRAVEN DR',
        RegAddrCity: 'KNOXVILLE',
        RegAddrState: 'TN',
        RegAddrZip: 37922,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '9612 VALLEY WOODS LN',
        RegAddrCity: 'KNOXVILLE',
        RegAddrState: 'TN',
        RegAddrZip: 37922,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1516 WESTIN PL',
        RegAddrCity: 'KNOXVILLE',
        RegAddrState: 'TN',
        RegAddrZip: 37922,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '9532 MOBILE DR',
        RegAddrCity: 'KNOXVILLE',
        RegAddrState: 'TN',
        RegAddrZip: 37923,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '5808 MILLERTOWN PIKE',
        RegAddrCity: 'KNOXVILLE',
        RegAddrState: 'TN',
        RegAddrZip: 37924,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2715 HAWK HAVEN LN',
        RegAddrCity: 'KNOXVILLE',
        RegAddrState: 'TN',
        RegAddrZip: 37931,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1305 CHIPWOOD CIR',
        RegAddrCity: 'KNOXVILLE',
        RegAddrState: 'TN',
        RegAddrZip: 37932,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '411 MAPLETREE DR',
        RegAddrCity: 'KNOXVILLE',
        RegAddrState: 'TN',
        RegAddrZip: 37934,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '231 SHADOWFAX RD',
        RegAddrCity: 'KNOXVILLE',
        RegAddrState: 'TN',
        RegAddrZip: 37934,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '818 BROCHARDT BLVD',
        RegAddrCity: 'KNOXVILLE',
        RegAddrState: 'TN',
        RegAddrZip: 37934,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '7828 SADDLEBROOKE DR',
        RegAddrCity: 'KNOXVILLE',
        RegAddrState: 'TN',
        RegAddrZip: 37938,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '5307 CLARK DR',
        RegAddrCity: 'KNOXVILLE',
        RegAddrState: 'TN',
        RegAddrZip: 37938,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1121 E EMORY RD',
        RegAddrCity: 'KNOXVILLE',
        RegAddrState: 'TN',
        RegAddrZip: 37938,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '675 CARRINGTON BLVD',
        RegAddrCity: 'LENOIR CITY',
        RegAddrState: 'TN',
        RegAddrZip: 37771,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '210 FLORA DR',
        RegAddrCity: 'LENOIR CITY',
        RegAddrState: 'TN',
        RegAddrZip: 37771,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '307 CHEESTANA WAY',
        RegAddrCity: 'LOUDON',
        RegAddrState: 'TN',
        RegAddrZip: 37774,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '4617 AGGIE DR',
        RegAddrCity: 'MARYVILLE',
        RegAddrState: 'TN',
        RegAddrZip: 37803,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '431 SAVANNAH VILLAGE DR',
        RegAddrCity: 'MARYVILLE',
        RegAddrState: 'TN',
        RegAddrZip: 37803,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '3309 SIX MILE RD',
        RegAddrCity: 'MARYVILLE',
        RegAddrState: 'TN',
        RegAddrZip: 37803,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2533 CECELIA AVE',
        RegAddrCity: 'MARYVILLE',
        RegAddrState: 'TN',
        RegAddrZip: 37804,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '734 HELTON RD',
        RegAddrCity: 'MARYVILLE',
        RegAddrState: 'TN',
        RegAddrZip: 37804,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '118 SWANEE DR',
        RegAddrCity: 'MARYVILLE',
        RegAddrState: 'TN',
        RegAddrZip: 37804,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2518 HICKLE RD',
        RegAddrCity: 'NEW MARKET',
        RegAddrState: 'TN',
        RegAddrZip: 37820,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '7300 CLARENDON RD',
        RegAddrCity: 'POWELL',
        RegAddrState: 'TN',
        RegAddrZip: 37849,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '8808 ELLIS LN',
        RegAddrCity: 'POWELL',
        RegAddrState: 'TN',
        RegAddrZip: 37849,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2855 HIGHWAY 92',
        RegAddrCity: 'RUTLEDGE',
        RegAddrState: 'TN',
        RegAddrZip: 37861,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '3234 FLAT HOLLOW RD',
        RegAddrCity: 'SPEEDWELL',
        RegAddrState: 'TN',
        RegAddrZip: 37870,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '9638 CLIFT RD',
        RegAddrCity: 'STRAW PLAINS',
        RegAddrState: 'TN',
        RegAddrZip: 37871,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '5109 CALDERWOOD HWY',
        RegAddrCity: 'TALLASSEE',
        RegAddrState: 'TN',
        RegAddrZip: 37878,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '137 WHITAKER LN',
        RegAddrCity: 'TAZEWELL',
        RegAddrState: 'TN',
        RegAddrZip: 37879,
        CongressionalDistrict: 2,
      },
    ],
  },
  {
    name: 'TN-3',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/TN-3?ref=gh-pages',
    state: 'TN',
    state_and_number: 'TN-3',
    zip: 37302,
    congressionalDistrict: 3,
    addressesInDistrict: [
      {
        RegAddrLine1: '513 BRYSON ST',
        RegAddrCity: 'ATHENS',
        RegAddrState: 'TN',
        RegAddrZip: 37303,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '153 HEDDEN LN',
        RegAddrCity: 'BENTON',
        RegAddrState: 'TN',
        RegAddrZip: 37307,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '136 YATES DR',
        RegAddrCity: 'BENTON',
        RegAddrState: 'TN',
        RegAddrZip: 37307,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '201 COUNTY ROAD 6',
        RegAddrCity: 'CALHOUN',
        RegAddrState: 'TN',
        RegAddrZip: 37309,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '160 N CREST RD',
        RegAddrCity: 'CHATTANOOGA',
        RegAddrState: 'TN',
        RegAddrZip: 37404,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '902 TREMONT ST',
        RegAddrCity: 'CHATTANOOGA',
        RegAddrState: 'TN',
        RegAddrZip: 37405,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '3111 WOOD AVE',
        RegAddrCity: 'CHATTANOOGA',
        RegAddrState: 'TN',
        RegAddrZip: 37406,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '6003 OLD DAYTON PIKE',
        RegAddrCity: 'CHATTANOOGA',
        RegAddrState: 'TN',
        RegAddrZip: 37415,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '358 MAGNOLIA VALE DR',
        RegAddrCity: 'CHATTANOOGA',
        RegAddrState: 'TN',
        RegAddrZip: 37419,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '7117 GLENFIELD LN',
        RegAddrCity: 'CHATTANOOGA',
        RegAddrState: 'TN',
        RegAddrZip: 37421,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '894 THOMPSON SPRINGS RD SE',
        RegAddrCity: 'CLEVELAND',
        RegAddrState: 'TN',
        RegAddrZip: 37323,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '100 VISCOUNT LN',
        RegAddrCity: 'CLINTON',
        RegAddrState: 'TN',
        RegAddrZip: 37716,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '105 COUNTY ROAD 856',
        RegAddrCity: 'DELANO',
        RegAddrState: 'TN',
        RegAddrZip: 37325,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '3501 CATHY LN',
        RegAddrCity: 'EAST RIDGE',
        RegAddrState: 'TN',
        RegAddrZip: 37412,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '3612 PARHAM DR',
        RegAddrCity: 'EAST RIDGE',
        RegAddrState: 'TN',
        RegAddrZip: 37412,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '2196 BLUFF RD',
        RegAddrCity: 'HARRIMAN',
        RegAddrState: 'TN',
        RegAddrZip: 37748,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '165 RIDGECREST RD',
        RegAddrCity: 'HARRIMAN',
        RegAddrState: 'TN',
        RegAddrZip: 37748,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '8743 HIGHWAY 58',
        RegAddrCity: 'HARRISON',
        RegAddrState: 'TN',
        RegAddrZip: 37341,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '7258 SHORE DR',
        RegAddrCity: 'HARRISON',
        RegAddrState: 'TN',
        RegAddrZip: 37341,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '5369 HICKORY VALLEY RD',
        RegAddrCity: 'HEISKELL',
        RegAddrState: 'TN',
        RegAddrZip: 37754,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '407 HAWKWOOD CT',
        RegAddrCity: 'HIXSON',
        RegAddrState: 'TN',
        RegAddrZip: 37343,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '445 BROOKMONT LN',
        RegAddrCity: 'HIXSON',
        RegAddrState: 'TN',
        RegAddrZip: 37343,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '7124 MOSES RD',
        RegAddrCity: 'HIXSON',
        RegAddrState: 'TN',
        RegAddrZip: 37343,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '250 GARDNER LN',
        RegAddrCity: 'JACKSBORO',
        RegAddrState: 'TN',
        RegAddrZip: 37757,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '837 ALMA LN',
        RegAddrCity: 'KINGSTON',
        RegAddrState: 'TN',
        RegAddrZip: 37763,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '9046 CANDLEWOOD DR',
        RegAddrCity: 'KNOXVILLE',
        RegAddrState: 'TN',
        RegAddrZip: 37923,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '8 PARK LN',
        RegAddrCity: 'LOOKOUT MTN',
        RegAddrState: 'TN',
        RegAddrZip: 37350,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '194 REDBUD CIR',
        RegAddrCity: 'LUTTRELL',
        RegAddrState: 'TN',
        RegAddrZip: 37779,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '141 HUNTERS RDG',
        RegAddrCity: 'MADISONVILLE',
        RegAddrState: 'TN',
        RegAddrZip: 37354,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '727 OLD STORE RD',
        RegAddrCity: 'MADISONVILLE',
        RegAddrState: 'TN',
        RegAddrZip: 37354,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '13 RIDGEWAY RD',
        RegAddrCity: 'NORRIS',
        RegAddrState: 'TN',
        RegAddrZip: 37828,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '7564 PINEWOOD RD',
        RegAddrCity: 'NUNNELLY',
        RegAddrState: 'TN',
        RegAddrZip: 37137,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '101 E SOUTHWOOD LN',
        RegAddrCity: 'OAK RIDGE',
        RegAddrState: 'TN',
        RegAddrZip: 37830,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '100 WHIPPOORWILL DR',
        RegAddrCity: 'OAK RIDGE',
        RegAddrState: 'TN',
        RegAddrZip: 37830,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '145 CALIFORNIA AVE',
        RegAddrCity: 'OAK RIDGE',
        RegAddrState: 'TN',
        RegAddrZip: 37830,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '196 DAVIS CURBOW RD SE',
        RegAddrCity: 'OLD FORT',
        RegAddrState: 'TN',
        RegAddrZip: 37362,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '203 SHADY RD',
        RegAddrCity: 'OLIVER SPGS',
        RegAddrState: 'TN',
        RegAddrZip: 37840,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '221 W 1ST AVE',
        RegAddrCity: 'ONEIDA',
        RegAddrState: 'TN',
        RegAddrZip: 37841,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '7417 ROYAL HARBOUR CIR',
        RegAddrCity: 'OOLTEWAH',
        RegAddrState: 'TN',
        RegAddrZip: 37363,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '527 TURTLE XING',
        RegAddrCity: 'OOLTEWAH',
        RegAddrState: 'TN',
        RegAddrZip: 37363,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '7993 JONATHAN DR',
        RegAddrCity: 'OOLTEWAH',
        RegAddrState: 'TN',
        RegAddrZip: 37363,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '14611 DAYTON PIKE',
        RegAddrCity: 'SALE CREEK',
        RegAddrState: 'TN',
        RegAddrZip: 37373,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '8 HIGHDOWN CT',
        RegAddrCity: 'SIGNAL MTN',
        RegAddrState: 'TN',
        RegAddrZip: 37377,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '11402 CLIPPER BAY DR',
        RegAddrCity: 'SODDY DAISY',
        RegAddrState: 'TN',
        RegAddrZip: 37379,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '172 HIDEAWAY COVE TRL',
        RegAddrCity: 'TURTLETOWN',
        RegAddrState: 'TN',
        RegAddrZip: 37391,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '507 PETIT LN',
        RegAddrCity: 'WARTBURG',
        RegAddrState: 'TN',
        RegAddrZip: 37887,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '166 OLD GOBEY RD',
        RegAddrCity: 'WARTBURG',
        RegAddrState: 'TN',
        RegAddrZip: 37887,
        CongressionalDistrict: 3,
      },
    ],
  },
  {
    name: 'TN-4',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/TN-4?ref=gh-pages',
    state: 'TN',
    state_and_number: 'TN-4',
    zip: 37014,
    congressionalDistrict: 4,
    addressesInDistrict: [
      {
        RegAddrLine1: 'SKYMONT COMMUNITY',
        RegAddrCity: 'ALTAMONT',
        RegAddrState: 'TN',
        RegAddrZip: 37301,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '7640 ALMAVILLE RD',
        RegAddrCity: 'ARRINGTON',
        RegAddrState: 'TN',
        RegAddrZip: 37014,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '3824 GEORGETOWN RD NW',
        RegAddrCity: 'CLEVELAND',
        RegAddrState: 'TN',
        RegAddrZip: 37312,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '179 BENT OAK TRL SE',
        RegAddrCity: 'CLEVELAND',
        RegAddrState: 'TN',
        RegAddrZip: 37323,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '3004 BROOKSIDE DR',
        RegAddrCity: 'COLUMBIA',
        RegAddrState: 'TN',
        RegAddrZip: 38401,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1717 CREEKSTONE DR',
        RegAddrCity: 'COLUMBIA',
        RegAddrState: 'TN',
        RegAddrZip: 38401,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '3817 TALLEY MOORE RD',
        RegAddrCity: 'COLUMBIA',
        RegAddrState: 'TN',
        RegAddrZip: 38401,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2350 TRACY LN',
        RegAddrCity: 'CULLEOKA',
        RegAddrState: 'TN',
        RegAddrZip: 38451,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '4970 STIVERSVILLE RD',
        RegAddrCity: 'CULLEOKA',
        RegAddrState: 'TN',
        RegAddrZip: 38451,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '208 RIVERBEND DR',
        RegAddrCity: 'DAYTON',
        RegAddrState: 'TN',
        RegAddrZip: 37321,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '415 RUSSELL ST',
        RegAddrCity: 'DUNLAP',
        RegAddrState: 'TN',
        RegAddrZip: 37327,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '29 ELMWOOD CIR',
        RegAddrCity: 'FAYETTEVILLE',
        RegAddrState: 'TN',
        RegAddrZip: 37334,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '134 MILLER ST',
        RegAddrCity: 'GRAYSVILLE',
        RegAddrState: 'TN',
        RegAddrZip: 37338,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '124 KIMBALL COVE RD',
        RegAddrCity: 'JASPER',
        RegAddrState: 'TN',
        RegAddrZip: 37347,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1002 HUDSON AVE',
        RegAddrCity: 'JASPER',
        RegAddrState: 'TN',
        RegAddrZip: 37347,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '42 TEAL HOLLOW RD',
        RegAddrCity: 'KELSO',
        RegAddrState: 'TN',
        RegAddrZip: 37348,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '415 WOODED VALLEY CT',
        RegAddrCity: 'LA VERGNE',
        RegAddrState: 'TN',
        RegAddrZip: 37086,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '644 GREYSTONE DR',
        RegAddrCity: 'LASCASSAS',
        RegAddrState: 'TN',
        RegAddrZip: 37085,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '691 JACKSON AVE',
        RegAddrCity: 'LEWISBURG',
        RegAddrState: 'TN',
        RegAddrZip: 37091,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '560 HIGHWAY 99',
        RegAddrCity: 'LEWISBURG',
        RegAddrState: 'TN',
        RegAddrZip: 37091,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '4407 HARRISON FERRY RD',
        RegAddrCity: 'MCMINNVILLE',
        RegAddrState: 'TN',
        RegAddrZip: 37110,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '216 HOMEWOOD DR',
        RegAddrCity: 'MURFREESBORO',
        RegAddrState: 'TN',
        RegAddrZip: 37127,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '3310 WOODBURY PIKE',
        RegAddrCity: 'MURFREESBORO',
        RegAddrState: 'TN',
        RegAddrZip: 37127,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '3846 SABER CT',
        RegAddrCity: 'MURFREESBORO',
        RegAddrState: 'TN',
        RegAddrZip: 37128,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '3708 CHIPPEWA PL',
        RegAddrCity: 'MURFREESBORO',
        RegAddrState: 'TN',
        RegAddrZip: 37128,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1802 JONES BLVD',
        RegAddrCity: 'MURFREESBORO',
        RegAddrState: 'TN',
        RegAddrZip: 37129,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1710 GRAYBAR LN',
        RegAddrCity: 'MURFREESBORO',
        RegAddrState: 'TN',
        RegAddrZip: 37129,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '6418 BAKER RD',
        RegAddrCity: 'MURFREESBORO',
        RegAddrState: 'TN',
        RegAddrZip: 37129,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1903 CENTRAL VALLEY RD',
        RegAddrCity: 'MURFREESBORO',
        RegAddrState: 'TN',
        RegAddrZip: 37129,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1718 JOSE WAY',
        RegAddrCity: 'MURFREESBORO',
        RegAddrState: 'TN',
        RegAddrZip: 37130,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2218 HAVEN DR',
        RegAddrCity: 'MURFREESBORO',
        RegAddrState: 'TN',
        RegAddrZip: 37130,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '195 BELLVIEW RD',
        RegAddrCity: 'PIKEVILLE',
        RegAddrState: 'TN',
        RegAddrZip: 37367,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '916 SHERWOOD OAKS DR',
        RegAddrCity: 'ROCKVALE',
        RegAddrState: 'TN',
        RegAddrZip: 37153,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '201 BERRYWOOD DR',
        RegAddrCity: 'SMYRNA',
        RegAddrState: 'TN',
        RegAddrZip: 37167,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '111 HANKINS DR',
        RegAddrCity: 'SMYRNA',
        RegAddrState: 'TN',
        RegAddrZip: 37167,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1012 CARNATION DR',
        RegAddrCity: 'SMYRNA',
        RegAddrState: 'TN',
        RegAddrZip: 37167,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1104 CHORLEYWOOD CT',
        RegAddrCity: 'SMYRNA',
        RegAddrState: 'TN',
        RegAddrZip: 37167,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '158 YUKON RD',
        RegAddrCity: 'TAFT',
        RegAddrState: 'TN',
        RegAddrZip: 38488,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '120 CHARLOTTE CIR',
        RegAddrCity: 'TULLAHOMA',
        RegAddrState: 'TN',
        RegAddrZip: 37388,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1191 RAILROAD RD',
        RegAddrCity: 'WARTRACE',
        RegAddrState: 'TN',
        RegAddrZip: 37183,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '307 4TH AVE SW',
        RegAddrCity: 'WINCHESTER',
        RegAddrState: 'TN',
        RegAddrZip: 37398,
        CongressionalDistrict: 4,
      },
    ],
  },
  {
    name: 'TN-5',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/TN-5?ref=gh-pages',
    state: 'TN',
    state_and_number: 'TN-5',
    zip: 37013,
    congressionalDistrict: 5,
    addressesInDistrict: [
      {
        RegAddrLine1: '529 BROOK VIEW ESTATES DR',
        RegAddrCity: 'ANTIOCH',
        RegAddrState: 'TN',
        RegAddrZip: 37013,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '3616 BURWICK PL',
        RegAddrCity: 'ANTIOCH',
        RegAddrState: 'TN',
        RegAddrZip: 37013,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '4612 BILLINGSGATE RD',
        RegAddrCity: 'ANTIOCH',
        RegAddrState: 'TN',
        RegAddrZip: 37013,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '1908 HICKORY HOLLOW TER',
        RegAddrCity: 'ANTIOCH',
        RegAddrState: 'TN',
        RegAddrZip: 37013,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '2805 WINDCREST TRL',
        RegAddrCity: 'ANTIOCH',
        RegAddrState: 'TN',
        RegAddrZip: 37013,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '420 BARKLEY CT',
        RegAddrCity: 'CANE RIDGE',
        RegAddrState: 'TN',
        RegAddrZip: 37013,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '7862 RAINEY DR',
        RegAddrCity: 'CANE RIDGE',
        RegAddrState: 'TN',
        RegAddrZip: 37013,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '1113 HAZEL DR',
        RegAddrCity: 'CLARKSVILLE',
        RegAddrState: 'TN',
        RegAddrZip: 37043,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '109 STEPHEN NICKS DR',
        RegAddrCity: 'DICKSON',
        RegAddrState: 'TN',
        RegAddrZip: 37055,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '103 RICK ST',
        RegAddrCity: 'DICKSON',
        RegAddrState: 'TN',
        RegAddrZip: 37055,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '1220 N CHARLOTTE ST',
        RegAddrCity: 'DICKSON',
        RegAddrState: 'TN',
        RegAddrZip: 37055,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '2010 STATE LINE RD',
        RegAddrCity: 'ELIZABETHTON',
        RegAddrState: 'TN',
        RegAddrZip: 37643,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '4853 PENINSULA POINTE DR',
        RegAddrCity: 'HERMITAGE',
        RegAddrState: 'TN',
        RegAddrZip: 37076,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '1400 BLOSSOM CT',
        RegAddrCity: 'HERMITAGE',
        RegAddrState: 'TN',
        RegAddrZip: 37076,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '3509 CRYSTAL SPRING LN',
        RegAddrCity: 'HERMITAGE',
        RegAddrState: 'TN',
        RegAddrZip: 37076,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '711 E CAMPBELL RD',
        RegAddrCity: 'MADISON',
        RegAddrState: 'TN',
        RegAddrZip: 37115,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '627 E CAMPBELL RD',
        RegAddrCity: 'MADISON',
        RegAddrState: 'TN',
        RegAddrZip: 37115,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '754 BROWNLEE DR',
        RegAddrCity: 'NASHVILLE',
        RegAddrState: 'TN',
        RegAddrZip: 37205,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '113 SCOTT AVE',
        RegAddrCity: 'NASHVILLE',
        RegAddrState: 'TN',
        RegAddrZip: 37206,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '1305 BOSCOBEL ST',
        RegAddrCity: 'NASHVILLE',
        RegAddrState: 'TN',
        RegAddrZip: 37206,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '2000 SALEM MASON DR',
        RegAddrCity: 'NASHVILLE',
        RegAddrState: 'TN',
        RegAddrZip: 37208,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '6539 MARAUDER DR',
        RegAddrCity: 'NASHVILLE',
        RegAddrState: 'TN',
        RegAddrZip: 37209,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '3500 JOHN A MERRITT BLVD',
        RegAddrCity: 'NASHVILLE',
        RegAddrState: 'TN',
        RegAddrZip: 37209,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '214 PEACHTREE ST',
        RegAddrCity: 'NASHVILLE',
        RegAddrState: 'TN',
        RegAddrZip: 37210,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '201 WILOWEN DR',
        RegAddrCity: 'NASHVILLE',
        RegAddrState: 'TN',
        RegAddrZip: 37210,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '5019 W DURRETT DR',
        RegAddrCity: 'NASHVILLE',
        RegAddrState: 'TN',
        RegAddrZip: 37211,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '2613A BELCOURT AVE',
        RegAddrCity: 'NASHVILLE',
        RegAddrState: 'TN',
        RegAddrZip: 37212,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '2405 BARTON AVE',
        RegAddrCity: 'NASHVILLE',
        RegAddrState: 'TN',
        RegAddrZip: 37212,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '813 RAGSDALE CT',
        RegAddrCity: 'NASHVILLE',
        RegAddrState: 'TN',
        RegAddrZip: 37214,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '3260 NIAGARA DR',
        RegAddrCity: 'NASHVILLE',
        RegAddrState: 'TN',
        RegAddrZip: 37214,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '4625 CHALMERS DR',
        RegAddrCity: 'NASHVILLE',
        RegAddrState: 'TN',
        RegAddrZip: 37215,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '3801 BRUSH HILL RD',
        RegAddrCity: 'NASHVILLE',
        RegAddrState: 'TN',
        RegAddrZip: 37216,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '4206 BRUSH HILL RD',
        RegAddrCity: 'NASHVILLE',
        RegAddrState: 'TN',
        RegAddrZip: 37216,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '4777 OLD HICKORY BLVD',
        RegAddrCity: 'NASHVILLE',
        RegAddrState: 'TN',
        RegAddrZip: 37218,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '3340 HOSPITAL LN',
        RegAddrCity: 'NASHVILLE',
        RegAddrState: 'TN',
        RegAddrZip: 37218,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '5228 STALLWORTH DR',
        RegAddrCity: 'NASHVILLE',
        RegAddrState: 'TN',
        RegAddrZip: 37220,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '608 BRENTLAWN RD',
        RegAddrCity: 'NASHVILLE',
        RegAddrState: 'TN',
        RegAddrZip: 37220,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '8461 INDIAN HILLS DR',
        RegAddrCity: 'NASHVILLE',
        RegAddrState: 'TN',
        RegAddrZip: 37221,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '720 BRIKSBERRY CT',
        RegAddrCity: 'NASHVILLE',
        RegAddrState: 'TN',
        RegAddrZip: 37221,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '924 FALLING WATER CT',
        RegAddrCity: 'NASHVILLE',
        RegAddrState: 'TN',
        RegAddrZip: 37221,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '7348 MIDDLEBROOK CIR',
        RegAddrCity: 'NASHVILLE',
        RegAddrState: 'TN',
        RegAddrZip: 37221,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '130 PARK CIR',
        RegAddrCity: 'OLD HICKORY',
        RegAddrState: 'TN',
        RegAddrZip: 37138,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '118 RIVERBEND DR',
        RegAddrCity: 'PEGRAM',
        RegAddrState: 'TN',
        RegAddrZip: 37143,
        CongressionalDistrict: 5,
      },
    ],
  },
  {
    name: 'TN-6',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/TN-6?ref=gh-pages',
    state: 'TN',
    state_and_number: 'TN-6',
    zip: 37010,
    congressionalDistrict: 6,
    addressesInDistrict: [
      {
        RegAddrLine1: '90 ECHOLS RD',
        RegAddrCity: 'BETHPAGE',
        RegAddrState: 'TN',
        RegAddrZip: 37022,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '13 EARL STEEL RD W',
        RegAddrCity: 'BRUSH CREEK',
        RegAddrState: 'TN',
        RegAddrZip: 38547,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '1515 BILBREY PARK DR',
        RegAddrCity: 'COOKEVILLE',
        RegAddrState: 'TN',
        RegAddrZip: 38501,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '104 HORSESHOE DR',
        RegAddrCity: 'COOKEVILLE',
        RegAddrState: 'TN',
        RegAddrZip: 38506,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '167 JUNE CHAPEL RD',
        RegAddrCity: 'COOKEVILLE',
        RegAddrState: 'TN',
        RegAddrZip: 38506,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '1934 MIKONDA DR',
        RegAddrCity: 'COOKEVILLE',
        RegAddrState: 'TN',
        RegAddrZip: 38506,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '380 AVEY CIR',
        RegAddrCity: 'COOKEVILLE',
        RegAddrState: 'TN',
        RegAddrZip: 38506,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '4251 FORREST HILL RD',
        RegAddrCity: 'COOKEVILLE',
        RegAddrState: 'TN',
        RegAddrZip: 38506,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '1047 NEW DEAL POTTS RD',
        RegAddrCity: 'COTTONTOWN',
        RegAddrState: 'TN',
        RegAddrZip: 37048,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '620 HAWKS BLUFF RD',
        RegAddrCity: 'CRAWFORD',
        RegAddrState: 'TN',
        RegAddrZip: 38554,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '4302 EASTSIDE DR',
        RegAddrCity: 'CROSS PLAINS',
        RegAddrState: 'TN',
        RegAddrZip: 37049,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '1704 SPRUCE LOOP',
        RegAddrCity: 'CROSSVILLE',
        RegAddrState: 'TN',
        RegAddrZip: 38555,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '1572 S MAIN ST',
        RegAddrCity: 'CROSSVILLE',
        RegAddrState: 'TN',
        RegAddrZip: 38555,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '337 LAKE CATHERINE CIR',
        RegAddrCity: 'CROSSVILLE',
        RegAddrState: 'TN',
        RegAddrZip: 38558,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '40 FLETCHER DR',
        RegAddrCity: 'CROSSVILLE',
        RegAddrState: 'TN',
        RegAddrZip: 38571,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '931 SUNSET RIDGE DR',
        RegAddrCity: 'CROSSVILLE',
        RegAddrState: 'TN',
        RegAddrZip: 38571,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '3862 DRY CREEK RD',
        RegAddrCity: 'DOWELLTOWN',
        RegAddrState: 'TN',
        RegAddrZip: 37059,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '312 HIX HOLLOW RD',
        RegAddrCity: 'GAINESBORO',
        RegAddrState: 'TN',
        RegAddrZip: 38562,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '279 SAINT BLAISE RD',
        RegAddrCity: 'GALLATIN',
        RegAddrState: 'TN',
        RegAddrZip: 37066,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '1847 CAIRO RD',
        RegAddrCity: 'GALLATIN',
        RegAddrState: 'TN',
        RegAddrZip: 37066,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '5874 DIVIDING RIDGE RD',
        RegAddrCity: 'GOODLETTSVLLE',
        RegAddrState: 'TN',
        RegAddrZip: 37072,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '756 DRAUGHON DR',
        RegAddrCity: 'GREENBRIER',
        RegAddrState: 'TN',
        RegAddrZip: 37073,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '1015 GANNETT RD',
        RegAddrCity: 'HENDERSONVILLE',
        RegAddrState: 'TN',
        RegAddrZip: 37075,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '165 GROVE LN S',
        RegAddrCity: 'HENDERSONVLLE',
        RegAddrState: 'TN',
        RegAddrZip: 37075,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '334 SANDERS FERRY RD',
        RegAddrCity: 'HENDERSONVLLE',
        RegAddrState: 'TN',
        RegAddrZip: 37075,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '933 BLUE SPRINGS RD',
        RegAddrCity: 'HILLSBORO',
        RegAddrState: 'TN',
        RegAddrZip: 37342,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '1107 MCGEE RD',
        RegAddrCity: 'JAMESTOWN',
        RegAddrState: 'TN',
        RegAddrZip: 38556,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '214 HALL LN',
        RegAddrCity: 'LAFAYETTE',
        RegAddrState: 'TN',
        RegAddrZip: 37083,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '1791 COLEYTOWN RD',
        RegAddrCity: 'LAFAYETTE',
        RegAddrState: 'TN',
        RegAddrZip: 37083,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '1002 SPRING HILL CIR',
        RegAddrCity: 'LEBANON',
        RegAddrState: 'TN',
        RegAddrZip: 37087,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '47 SUGGS DR',
        RegAddrCity: 'LEBANON',
        RegAddrState: 'TN',
        RegAddrZip: 37087,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '1781 SUGAR FLAT RD',
        RegAddrCity: 'LEBANON',
        RegAddrState: 'TN',
        RegAddrZip: 37087,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '308 HILLCREEK DR',
        RegAddrCity: 'LEBANON',
        RegAddrState: 'TN',
        RegAddrZip: 37087,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '236 MEANDERING DR',
        RegAddrCity: 'LEBANON',
        RegAddrState: 'TN',
        RegAddrZip: 37090,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '1850 FRANKLIN RD',
        RegAddrCity: 'LEBANON',
        RegAddrState: 'TN',
        RegAddrZip: 37090,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '1992 FREDONIA RD',
        RegAddrCity: 'MANCHESTER',
        RegAddrState: 'TN',
        RegAddrZip: 37355,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '2514 MURFREESBORO HWY',
        RegAddrCity: 'MANCHESTER',
        RegAddrState: 'TN',
        RegAddrZip: 37355,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '802 GREEN VALLEY RD',
        RegAddrCity: 'MT JULIET',
        RegAddrState: 'TN',
        RegAddrZip: 37122,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '3465 NONAVILLE RD',
        RegAddrCity: 'MT JULIET',
        RegAddrState: 'TN',
        RegAddrZip: 37122,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '102 DUE WEST DR',
        RegAddrCity: 'MT JULIET',
        RegAddrState: 'TN',
        RegAddrZip: 37122,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '149 PRIVATEER LN',
        RegAddrCity: 'MT JULIET',
        RegAddrState: 'TN',
        RegAddrZip: 37122,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '821 OVERHILLS DR',
        RegAddrCity: 'OLD HICKORY',
        RegAddrState: 'TN',
        RegAddrZip: 37138,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '634 HIGHWAY 52 E',
        RegAddrCity: 'PORTLAND',
        RegAddrState: 'TN',
        RegAddrZip: 37148,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '1103 ASH ST',
        RegAddrCity: 'PORTLAND',
        RegAddrState: 'TN',
        RegAddrZip: 37148,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '210 WOOTEN PARK RD',
        RegAddrCity: 'RED BLNG SPGS',
        RegAddrState: 'TN',
        RegAddrZip: 37150,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '2063 NIMM RD',
        RegAddrCity: 'RED BOILING SPRINGS',
        RegAddrState: 'TN',
        RegAddrZip: 37150,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '173 MOULDER RD',
        RegAddrCity: 'SHELBYVILLE',
        RegAddrState: 'TN',
        RegAddrZip: 37160,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '195 WILDWOOD RD',
        RegAddrCity: 'SMITHVILLE',
        RegAddrState: 'TN',
        RegAddrZip: 37166,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '121 JACOBS PILLAR RD',
        RegAddrCity: 'SMITHVILLE',
        RegAddrState: 'TN',
        RegAddrZip: 37166,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '492 OLD HIGHWAY RD',
        RegAddrCity: 'SPARTA',
        RegAddrState: 'TN',
        RegAddrZip: 38583,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '130 RICKS LN',
        RegAddrCity: 'SPARTA',
        RegAddrState: 'TN',
        RegAddrZip: 38583,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '5127 HANCOCK RD',
        RegAddrCity: 'SPRINGFIELD',
        RegAddrState: 'TN',
        RegAddrZip: 37172,
        CongressionalDistrict: 6,
      },
    ],
  },
  {
    name: 'TN-7',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/TN-7?ref=gh-pages',
    state: 'TN',
    state_and_number: 'TN-7',
    zip: 37023,
    congressionalDistrict: 7,
    addressesInDistrict: [
      {
        RegAddrLine1: '3501 DRAKE RD',
        RegAddrCity: 'ADAMS',
        RegAddrState: 'TN',
        RegAddrZip: 37010,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '326 MIMOSA ST',
        RegAddrCity: 'ADAMSVILLE',
        RegAddrState: 'TN',
        RegAddrZip: 38310,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '514 W JACKSON ST',
        RegAddrCity: 'BOLIVAR',
        RegAddrState: 'TN',
        RegAddrZip: 38008,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '8864 OLD LOCUST CREEK RD',
        RegAddrCity: 'BON AQUA',
        RegAddrState: 'TN',
        RegAddrZip: 37025,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '620 SPLIT RAIL DR',
        RegAddrCity: 'BRENTWOOD',
        RegAddrState: 'TN',
        RegAddrZip: 37027,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '291 HIGHWAY 100 W',
        RegAddrCity: 'CENTERVILLE',
        RegAddrState: 'TN',
        RegAddrZip: 37033,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '100 GLENWOOD DR',
        RegAddrCity: 'CLARKSVILLE',
        RegAddrState: 'TN',
        RegAddrZip: 37040,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '3766 BRET DR',
        RegAddrCity: 'CLARKSVILLE',
        RegAddrState: 'TN',
        RegAddrZip: 37040,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '1423 AMBLESIDE DR',
        RegAddrCity: 'CLARKSVILLE',
        RegAddrState: 'TN',
        RegAddrZip: 37040,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '1774 CABANA DR',
        RegAddrCity: 'CLARKSVILLE',
        RegAddrState: 'TN',
        RegAddrZip: 37042,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '118 CUNNINGHAM PL',
        RegAddrCity: 'CLARKSVILLE',
        RegAddrState: 'TN',
        RegAddrZip: 37042,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '291 MELINDA DR',
        RegAddrCity: 'CLARKSVILLE',
        RegAddrState: 'TN',
        RegAddrZip: 37042,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '1028 FOXMOOR DR',
        RegAddrCity: 'CLARKSVILLE',
        RegAddrState: 'TN',
        RegAddrZip: 37042,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '3 E BEL AIR BLVD',
        RegAddrCity: 'CLARKSVILLE',
        RegAddrState: 'TN',
        RegAddrZip: 37042,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '130 AIRPORT RD',
        RegAddrCity: 'CLARKSVILLE',
        RegAddrState: 'TN',
        RegAddrZip: 37042,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '204 KINGSWOOD DR',
        RegAddrCity: 'CLARKSVILLE',
        RegAddrState: 'TN',
        RegAddrZip: 37043,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '8 LACY LN',
        RegAddrCity: 'CLARKSVILLE',
        RegAddrState: 'TN',
        RegAddrZip: 37043,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '304 LONGWOOD CT',
        RegAddrCity: 'CLARKSVILLE',
        RegAddrState: 'TN',
        RegAddrZip: 37043,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '2108 POWELL RD',
        RegAddrCity: 'CLARKSVILLE',
        RegAddrState: 'TN',
        RegAddrZip: 37043,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '1681 GRAVEL HILL RD',
        RegAddrCity: 'COLUMBIA',
        RegAddrState: 'TN',
        RegAddrZip: 38401,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '100 REAVES COVE RD',
        RegAddrCity: 'COLUMBIA',
        RegAddrState: 'TN',
        RegAddrZip: 38401,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '851 W JAMES M CAMPBELL BLVD',
        RegAddrCity: 'COLUMBIA',
        RegAddrState: 'TN',
        RegAddrZip: 38401,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '2095 PEACE CHAPEL RD',
        RegAddrCity: 'DECATURVILLE',
        RegAddrState: 'TN',
        RegAddrZip: 38329,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '8469 PETTUS RD',
        RegAddrCity: 'EAGLEVILLE',
        RegAddrState: 'TN',
        RegAddrZip: 37060,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '4786 E MAIN ST',
        RegAddrCity: 'ERIN',
        RegAddrState: 'TN',
        RegAddrZip: 37061,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '3320 BUFFALO RD',
        RegAddrCity: 'ETHRIDGE',
        RegAddrState: 'TN',
        RegAddrZip: 38456,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '5510 PARKER BRANCH RD',
        RegAddrCity: 'FRANKLIN',
        RegAddrState: 'TN',
        RegAddrZip: 37064,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '4237 OLD ARNO RD',
        RegAddrCity: 'FRANKLIN',
        RegAddrState: 'TN',
        RegAddrZip: 37064,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '5950 GREENBRIAR RD',
        RegAddrCity: 'FRANKLIN',
        RegAddrState: 'TN',
        RegAddrZip: 37064,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '1605 LEWISBURG PIKE',
        RegAddrCity: 'FRANKLIN',
        RegAddrState: 'TN',
        RegAddrZip: 37064,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '137 CLIFFE RUN',
        RegAddrCity: 'FRANKLIN',
        RegAddrState: 'TN',
        RegAddrZip: 37067,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '4202 CROWNE BROOK CIR',
        RegAddrCity: 'FRANKLIN',
        RegAddrState: 'TN',
        RegAddrZip: 37067,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '121 DEERCREST CIR',
        RegAddrCity: 'FRANKLIN',
        RegAddrState: 'TN',
        RegAddrZip: 37069,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '175 CLARENDON CIR',
        RegAddrCity: 'FRANKLIN',
        RegAddrState: 'TN',
        RegAddrZip: 37069,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '1242 DARBYTOWN RD',
        RegAddrCity: 'HOHENWALD',
        RegAddrState: 'TN',
        RegAddrZip: 38462,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '114 SUNSET DR',
        RegAddrCity: 'HOHENWALD',
        RegAddrState: 'TN',
        RegAddrZip: 38462,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '630 PONDEROSA ST',
        RegAddrCity: 'HOHENWALD',
        RegAddrState: 'TN',
        RegAddrZip: 38462,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '116 LADYBUG LN',
        RegAddrCity: 'INDIAN MOUND',
        RegAddrState: 'TN',
        RegAddrZip: 37079,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '27 OK RD',
        RegAddrCity: 'LAWRENCEBURG',
        RegAddrState: 'TN',
        RegAddrZip: 38464,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '555 KENT ST',
        RegAddrCity: 'LAWRENCEBURG',
        RegAddrState: 'TN',
        RegAddrZip: 38464,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '458 DAKOTA LN',
        RegAddrCity: 'LEOMA',
        RegAddrState: 'TN',
        RegAddrZip: 38468,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '2320 HIGHWAY 43 S',
        RegAddrCity: 'LEOMA',
        RegAddrState: 'TN',
        RegAddrZip: 38468,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '2725 BIBLE GROVE RD',
        RegAddrCity: 'LEXINGTON',
        RegAddrState: 'TN',
        RegAddrZip: 38351,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '215 WHITE ST',
        RegAddrCity: 'LEXINGTON',
        RegAddrState: 'TN',
        RegAddrZip: 38351,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '7798 WRIGLEY RD',
        RegAddrCity: 'LYLES',
        RegAddrState: 'TN',
        RegAddrZip: 37098,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '10084 US HIGHWAY 70 E',
        RegAddrCity: 'MCEWEN',
        RegAddrState: 'TN',
        RegAddrZip: 37101,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '29870 HIGHWAY 69',
        RegAddrCity: 'MORRIS CHAPEL',
        RegAddrState: 'TN',
        RegAddrZip: 38361,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '225 IRON LN',
        RegAddrCity: 'PARSONS',
        RegAddrState: 'TN',
        RegAddrZip: 38363,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '205 OWENS RD',
        RegAddrCity: 'PULASKI',
        RegAddrState: 'TN',
        RegAddrZip: 38478,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '7310 LIBERTY RD',
        RegAddrCity: 'SCOTTS HILL',
        RegAddrState: 'TN',
        RegAddrZip: 38374,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '321 W COURT AVE',
        RegAddrCity: 'SELMER',
        RegAddrState: 'TN',
        RegAddrZip: 38375,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '6006 TROTWOOD LN',
        RegAddrCity: 'SPRING HILL',
        RegAddrState: 'TN',
        RegAddrZip: 37174,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '3713 BUFFALO RD',
        RegAddrCity: 'SUMMERTOWN',
        RegAddrState: 'TN',
        RegAddrZip: 38483,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '2704 BRENDA ST',
        RegAddrCity: 'THOMPSONS STN',
        RegAddrState: 'TN',
        RegAddrZip: 37179,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '3505 UNION VILLAGE RD',
        RegAddrCity: 'THOMPSONS STN',
        RegAddrState: 'TN',
        RegAddrZip: 37179,
        CongressionalDistrict: 7,
      },
    ],
  },
  {
    name: 'TN-8',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/TN-8?ref=gh-pages',
    state: 'TN',
    state_and_number: 'TN-8',
    zip: 38001,
    congressionalDistrict: 8,
    addressesInDistrict: [
      {
        RegAddrLine1: '11939 HENNING OAKS LN',
        RegAddrCity: 'ARLINGTON',
        RegAddrState: 'TN',
        RegAddrZip: 38002,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '6111 EWING BLVD',
        RegAddrCity: 'ARLINGTON',
        RegAddrState: 'TN',
        RegAddrZip: 38002,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '7249 SHADY OAKS DR',
        RegAddrCity: 'BARTLETT',
        RegAddrState: 'TN',
        RegAddrZip: 38133,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '4323 ELLENDALE RD',
        RegAddrCity: 'BARTLETT',
        RegAddrState: 'TN',
        RegAddrZip: 38135,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '7069 DEERFIELD RD',
        RegAddrCity: 'BARTLETT',
        RegAddrState: 'TN',
        RegAddrZip: 38135,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '3857 OAK FOREST DR',
        RegAddrCity: 'BARTLETT',
        RegAddrState: 'TN',
        RegAddrZip: 38135,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '127 BRADFORD ACRES',
        RegAddrCity: 'BRADFORD',
        RegAddrState: 'TN',
        RegAddrZip: 38316,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '290 CHAPMAN ST',
        RegAddrCity: 'BROWNSVILLE',
        RegAddrState: 'TN',
        RegAddrZip: 38012,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '447 FRIENDSHIP RD',
        RegAddrCity: 'BROWNSVILLE',
        RegAddrState: 'TN',
        RegAddrZip: 38012,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '214 IVY BROOK LN',
        RegAddrCity: 'COLLIERVILLE',
        RegAddrState: 'TN',
        RegAddrZip: 38017,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '1304 RAIN HOLLOW CIR W',
        RegAddrCity: 'COLLIERVILLE',
        RegAddrState: 'TN',
        RegAddrZip: 38017,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '330B E SHERROD AVE',
        RegAddrCity: 'COVINGTON',
        RegAddrState: 'TN',
        RegAddrZip: 38019,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '849 N MAIN ST',
        RegAddrCity: 'DYER',
        RegAddrState: 'TN',
        RegAddrZip: 38330,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '311 BROADWAY AVE',
        RegAddrCity: 'DYERSBURG',
        RegAddrState: 'TN',
        RegAddrZip: 38024,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '2398 FAIRWAY DR',
        RegAddrCity: 'DYERSBURG',
        RegAddrState: 'TN',
        RegAddrZip: 38024,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '2115 BRIERBROOK RD',
        RegAddrCity: 'GERMANTOWN',
        RegAddrState: 'TN',
        RegAddrZip: 38138,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '1701 KIMBROUGH RD',
        RegAddrCity: 'GERMANTOWN',
        RegAddrState: 'TN',
        RegAddrZip: 38138,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '9440 DOE MEADOW DR',
        RegAddrCity: 'GERMANTOWN',
        RegAddrState: 'TN',
        RegAddrZip: 38139,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '50 ROLLING MEADOWS RD N',
        RegAddrCity: 'HENRY',
        RegAddrState: 'TN',
        RegAddrZip: 38231,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '660 CHERRY CREEK LN',
        RegAddrCity: 'HOLLOW ROCK',
        RegAddrState: 'TN',
        RegAddrZip: 38342,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '1211 FORT HILL CIR',
        RegAddrCity: 'HUMBOLDT',
        RegAddrState: 'TN',
        RegAddrZip: 38343,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '184 STATE ROUTE 152 W',
        RegAddrCity: 'HUMBOLDT',
        RegAddrState: 'TN',
        RegAddrZip: 38343,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '806 N 30TH AVE',
        RegAddrCity: 'HUMBOLDT',
        RegAddrState: 'TN',
        RegAddrZip: 38343,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '400 JAMISON DR',
        RegAddrCity: 'HUNTINGDON',
        RegAddrState: 'TN',
        RegAddrZip: 38344,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '145 WINDALE DR',
        RegAddrCity: 'JACKSON',
        RegAddrState: 'TN',
        RegAddrZip: 38305,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '500 ZACHARY LN',
        RegAddrCity: 'JACKSON',
        RegAddrState: 'TN',
        RegAddrZip: 38305,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '226 TURTLE CREEK DR',
        RegAddrCity: 'JACKSON',
        RegAddrState: 'TN',
        RegAddrZip: 38305,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '5292 KNOBBY PINE LN',
        RegAddrCity: 'LAKELAND',
        RegAddrState: 'TN',
        RegAddrZip: 38002,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '10090 HIGHWAY 59 S',
        RegAddrCity: 'MASON',
        RegAddrState: 'TN',
        RegAddrZip: 38049,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '101 SMITH AVE',
        RegAddrCity: 'MC KENZIE',
        RegAddrState: 'TN',
        RegAddrZip: 38201,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '6470 HIGHWAY 190',
        RegAddrCity: 'MC KENZIE',
        RegAddrState: 'TN',
        RegAddrZip: 38201,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '191 GREENBRIAR DR',
        RegAddrCity: 'MEMPHIS',
        RegAddrState: 'TN',
        RegAddrZip: 38117,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '5299 REVERE RD',
        RegAddrCity: 'MEMPHIS',
        RegAddrState: 'TN',
        RegAddrZip: 38120,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '5361 WILTON AVE',
        RegAddrCity: 'MEMPHIS',
        RegAddrState: 'TN',
        RegAddrZip: 38120,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '5308 SHADY GROVE RD',
        RegAddrCity: 'MEMPHIS',
        RegAddrState: 'TN',
        RegAddrZip: 38120,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '8900 BARRET RD',
        RegAddrCity: 'MILLINGTON',
        RegAddrState: 'TN',
        RegAddrZip: 38053,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '394 JOE JOYNER RD',
        RegAddrCity: 'MUNFORD',
        RegAddrState: 'TN',
        RegAddrZip: 38058,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '4028 HAMER RD',
        RegAddrCity: 'NEWBERN',
        RegAddrState: 'TN',
        RegAddrZip: 38059,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '110 HARRISON ST',
        RegAddrCity: 'PARIS',
        RegAddrState: 'TN',
        RegAddrZip: 38242,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '150 ALMAN CEMETERY RD',
        RegAddrCity: 'PARIS',
        RegAddrState: 'TN',
        RegAddrZip: 38242,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '6671 HIGHWAY 19 W',
        RegAddrCity: 'RIPLEY',
        RegAddrState: 'TN',
        RegAddrZip: 38063,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '7730 JERNIGAN DR',
        RegAddrCity: 'SOMERVILLE',
        RegAddrState: 'TN',
        RegAddrZip: 38068,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '9900 JERNIGAN DR',
        RegAddrCity: 'SOMERVILLE',
        RegAddrState: 'TN',
        RegAddrZip: 38068,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '815 S SOMERVILLE ST',
        RegAddrCity: 'SOMERVILLE',
        RegAddrState: 'TN',
        RegAddrZip: 38068,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '2777 ROY VOWELL RD',
        RegAddrCity: 'SOUTH FULTON',
        RegAddrState: 'TN',
        RegAddrZip: 38257,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '385 PLEASANT GROVE RD',
        RegAddrCity: 'SPRINGVILLE',
        RegAddrState: 'TN',
        RegAddrZip: 38256,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '1157 CIRCLE DR',
        RegAddrCity: 'UNION CITY',
        RegAddrState: 'TN',
        RegAddrZip: 38261,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '1211 ACORN ST',
        RegAddrCity: 'UNION CITY',
        RegAddrState: 'TN',
        RegAddrZip: 38261,
        CongressionalDistrict: 8,
      },
    ],
  },
  {
    name: 'TN-9',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/TN-9?ref=gh-pages',
    state: 'TN',
    state_and_number: 'TN-9',
    zip: 38054,
    congressionalDistrict: 9,
    addressesInDistrict: [
      {
        RegAddrLine1: '1173 VALMONT DR',
        RegAddrCity: 'CORDOVA',
        RegAddrState: 'TN',
        RegAddrZip: 38016,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '386 N WALNUT BEND RD',
        RegAddrCity: 'CORDOVA',
        RegAddrState: 'TN',
        RegAddrZip: 38018,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '1013 DUOMO CV',
        RegAddrCity: 'CORDOVA',
        RegAddrState: 'TN',
        RegAddrZip: 38018,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '7231 BRIDGEFIELD DR',
        RegAddrCity: 'CORDOVA',
        RegAddrState: 'TN',
        RegAddrZip: 38018,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '2015 PEABODY AVE',
        RegAddrCity: 'MEMPHIS',
        RegAddrState: 'TN',
        RegAddrZip: 38104,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '2162 EVELYN AVE',
        RegAddrCity: 'MEMPHIS',
        RegAddrState: 'TN',
        RegAddrZip: 38104,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '1786 CARR AVE',
        RegAddrCity: 'MEMPHIS',
        RegAddrState: 'TN',
        RegAddrZip: 38104,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '2531 FONTAINE RD',
        RegAddrCity: 'MEMPHIS',
        RegAddrState: 'TN',
        RegAddrZip: 38106,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '1470 E ALCY RD',
        RegAddrCity: 'MEMPHIS',
        RegAddrState: 'TN',
        RegAddrZip: 38106,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '1777 CRUMP AVE',
        RegAddrCity: 'MEMPHIS',
        RegAddrState: 'TN',
        RegAddrZip: 38107,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '1407 HYDE PARK BLVD',
        RegAddrCity: 'MEMPHIS',
        RegAddrState: 'TN',
        RegAddrZip: 38108,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '3040 TRAVIS RD',
        RegAddrCity: 'MEMPHIS',
        RegAddrState: 'TN',
        RegAddrZip: 38109,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '4956 JONETTA ST',
        RegAddrCity: 'MEMPHIS',
        RegAddrState: 'TN',
        RegAddrZip: 38109,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '234 W FRANK AVE',
        RegAddrCity: 'MEMPHIS',
        RegAddrState: 'TN',
        RegAddrZip: 38109,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '4740 FAIRLEY RD',
        RegAddrCity: 'MEMPHIS',
        RegAddrState: 'TN',
        RegAddrZip: 38109,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '144 INGLE AVE',
        RegAddrCity: 'MEMPHIS',
        RegAddrState: 'TN',
        RegAddrZip: 38109,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '399 BUNTYN ST',
        RegAddrCity: 'MEMPHIS',
        RegAddrState: 'TN',
        RegAddrZip: 38111,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '3731 HEALEY RD',
        RegAddrCity: 'MEMPHIS',
        RegAddrState: 'TN',
        RegAddrZip: 38111,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '235 HAWTHORNE ST',
        RegAddrCity: 'MEMPHIS',
        RegAddrState: 'TN',
        RegAddrZip: 38112,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '1640 FORREST AVE',
        RegAddrCity: 'MEMPHIS',
        RegAddrState: 'TN',
        RegAddrZip: 38112,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '1855 YOUNG AVE',
        RegAddrCity: 'MEMPHIS',
        RegAddrState: 'TN',
        RegAddrZip: 38114,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '5606 FOX MEADOWS CV',
        RegAddrCity: 'MEMPHIS',
        RegAddrState: 'TN',
        RegAddrZip: 38115,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '5021 DAVIS CV',
        RegAddrCity: 'MEMPHIS',
        RegAddrState: 'TN',
        RegAddrZip: 38116,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '4597 HUDGINS RD',
        RegAddrCity: 'MEMPHIS',
        RegAddrState: 'TN',
        RegAddrZip: 38116,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '1976 KILARNEY AVE',
        RegAddrCity: 'MEMPHIS',
        RegAddrState: 'TN',
        RegAddrZip: 38116,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '1356 FINLEY RD',
        RegAddrCity: 'MEMPHIS',
        RegAddrState: 'TN',
        RegAddrZip: 38116,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '5153 PILGRIM RD',
        RegAddrCity: 'MEMPHIS',
        RegAddrState: 'TN',
        RegAddrZip: 38116,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '3297 ANSNOW LN',
        RegAddrCity: 'MEMPHIS',
        RegAddrState: 'TN',
        RegAddrZip: 38118,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '4998 HOLLY RIDGE DR',
        RegAddrCity: 'MEMPHIS',
        RegAddrState: 'TN',
        RegAddrZip: 38118,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '4783 MYRLEN WAY',
        RegAddrCity: 'MEMPHIS',
        RegAddrState: 'TN',
        RegAddrZip: 38118,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '3987 BISHOPS BRIDGE RD',
        RegAddrCity: 'MEMPHIS',
        RegAddrState: 'TN',
        RegAddrZip: 38118,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '895 WRENWOOD ST',
        RegAddrCity: 'MEMPHIS',
        RegAddrState: 'TN',
        RegAddrZip: 38122,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '586 SHARON DR',
        RegAddrCity: 'MEMPHIS',
        RegAddrState: 'TN',
        RegAddrZip: 38122,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '4426 GIVEN AVE',
        RegAddrCity: 'MEMPHIS',
        RegAddrState: 'TN',
        RegAddrZip: 38122,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '8271 WHISPERING ELM DR',
        RegAddrCity: 'MEMPHIS',
        RegAddrState: 'TN',
        RegAddrZip: 38125,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '7707 SPRING MORNING CT',
        RegAddrCity: 'MEMPHIS',
        RegAddrState: 'TN',
        RegAddrZip: 38125,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '1604 JAY CV',
        RegAddrCity: 'MEMPHIS',
        RegAddrState: 'TN',
        RegAddrZip: 38127,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '3614 HALLBROOK ST',
        RegAddrCity: 'MEMPHIS',
        RegAddrState: 'TN',
        RegAddrZip: 38127,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '555 ISLAND FORTY RD',
        RegAddrCity: 'MEMPHIS',
        RegAddrState: 'TN',
        RegAddrZip: 38127,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '4013 KNOB DR',
        RegAddrCity: 'MEMPHIS',
        RegAddrState: 'TN',
        RegAddrZip: 38127,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '4218 BACON ST',
        RegAddrCity: 'MEMPHIS',
        RegAddrState: 'TN',
        RegAddrZip: 38128,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '2598 NEW RALEIGH RD',
        RegAddrCity: 'MEMPHIS',
        RegAddrState: 'TN',
        RegAddrZip: 38128,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '6120 HAMLET RD',
        RegAddrCity: 'MILLINGTON',
        RegAddrState: 'TN',
        RegAddrZip: 38053,
        CongressionalDistrict: 9,
      },
    ],
  },
  {
    name: 'TX-1',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/TX-1?ref=gh-pages',
    state: 'TX',
    state_and_number: 'TX-1',
    zip: 75451,
    congressionalDistrict: 1,
    addressesInDistrict: [
      {
        RegAddrLine1: '5603 HWY 79 SOX',
        RegAddrCity: 'HENDERSON',
        RegAddrState: 'TX',
        RegAddrZip: 75654,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '526 WOODSIDE DR',
        RegAddrCity: 'HIDEAWAY',
        RegAddrState: 'TX',
        RegAddrZip: 75771,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '437 TIMBERLINE RD',
        RegAddrCity: 'KILGORE',
        RegAddrState: 'TX',
        RegAddrZip: 75662,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1902 WIMBERLY ST',
        RegAddrCity: 'LONGVIEW',
        RegAddrState: 'TX',
        RegAddrZip: 75601,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1117 RICHWOOD ST',
        RegAddrCity: 'LONGVIEW',
        RegAddrState: 'TX',
        RegAddrZip: 75604,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2904 GRANITE RD',
        RegAddrCity: 'LONGVIEW',
        RegAddrState: 'TX',
        RegAddrZip: 75604,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '202 ENGLEWOOD DR',
        RegAddrCity: 'LUFKIN',
        RegAddrState: 'TX',
        RegAddrZip: 75901,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2101 PALMORE RD',
        RegAddrCity: 'LUFKIN',
        RegAddrState: 'TX',
        RegAddrZip: 75904,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '705 LOTHROP ST',
        RegAddrCity: 'MARSHALL',
        RegAddrState: 'TX',
        RegAddrZip: 75670,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '504 DENISE DR',
        RegAddrCity: 'MARSHALL',
        RegAddrState: 'TX',
        RegAddrZip: 75672,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1526 S ACADEMY ST',
        RegAddrCity: 'TYLER',
        RegAddrState: 'TX',
        RegAddrZip: 75701,
        CongressionalDistrict: 1,
      },
    ],
  },
  {
    name: 'TX-10',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/TX-10?ref=gh-pages',
    state: 'TX',
    state_and_number: 'TX-10',
    zip: 77412,
    congressionalDistrict: 10,
    addressesInDistrict: [
      {
        RegAddrLine1: '34 WOODSTONE SQ',
        RegAddrCity: 'AUSTIN',
        RegAddrState: 'TX',
        RegAddrZip: 78703,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '5706 DUVAL ST',
        RegAddrCity: 'AUSTIN',
        RegAddrState: 'TX',
        RegAddrZip: 78752,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '2107 PASADENA DR',
        RegAddrCity: 'AUSTIN',
        RegAddrState: 'TX',
        RegAddrZip: 78757,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '8714 BLUEGRASS DR',
        RegAddrCity: 'AUSTIN',
        RegAddrState: 'TX',
        RegAddrZip: 78759,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '775 LANGE LAKE RD',
        RegAddrCity: 'BRENHAM',
        RegAddrState: 'TX',
        RegAddrZip: 77833,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '12922 RED OAK GLEN DR',
        RegAddrCity: 'CYPRESS',
        RegAddrState: 'TX',
        RegAddrZip: 77429,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '13310 GOLDEN VALLEY DR',
        RegAddrCity: 'CYPRESS',
        RegAddrState: 'TX',
        RegAddrZip: 77429,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '13326 AUTUMN VALLEY DR',
        RegAddrCity: 'CYPRESS',
        RegAddrState: 'TX',
        RegAddrZip: 77429,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '19531 CLIFF SAGE CT',
        RegAddrCity: 'CYPRESS',
        RegAddrState: 'TX',
        RegAddrZip: 77433,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '21830 SILENT JASMIN CT',
        RegAddrCity: 'CYPRESS',
        RegAddrState: 'TX',
        RegAddrZip: 77433,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '1037 PRIVATE ROAD 4307',
        RegAddrCity: 'DIME BOX',
        RegAddrState: 'TX',
        RegAddrZip: 77853,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '708 COLORADO ST',
        RegAddrCity: 'GLIDDEN',
        RegAddrState: 'TX',
        RegAddrZip: 78943,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '1406 MAIN ST',
        RegAddrCity: 'INDUSTRY',
        RegAddrState: 'TX',
        RegAddrZip: 78944,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '20610 APACHE LAKE DR',
        RegAddrCity: 'KATY',
        RegAddrState: 'TX',
        RegAddrZip: 77449,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '13420 BRIARCREEK LOOP',
        RegAddrCity: 'MANOR',
        RegAddrState: 'TX',
        RegAddrZip: 78653,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '115 MOUNTAIN VIEW DR',
        RegAddrCity: 'PFLUGERVILLE',
        RegAddrState: 'TX',
        RegAddrZip: 78660,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '28700 E STALLION LN',
        RegAddrCity: 'WALLER',
        RegAddrState: 'TX',
        RegAddrZip: 77484,
        CongressionalDistrict: 10,
      },
    ],
  },
  {
    name: 'TX-11',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/TX-11?ref=gh-pages',
    state: 'TX',
    state_and_number: 'TX-11',
    zip: 76033,
    congressionalDistrict: 11,
    addressesInDistrict: [
      {
        RegAddrLine1: '17 NW GRACE DR',
        RegAddrCity: 'ANDREWS',
        RegAddrState: 'TX',
        RegAddrZip: 79714,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '4601 COUNTY ROAD 613',
        RegAddrCity: 'EARLY',
        RegAddrState: 'TX',
        RegAddrZip: 76802,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '1312 SLAY ST',
        RegAddrCity: 'EASTLAND',
        RegAddrState: 'TX',
        RegAddrZip: 76448,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '129 HIGHWAY 679',
        RegAddrCity: 'GORMAN',
        RegAddrState: 'TX',
        RegAddrZip: 76454,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '1936 ROADRUNNER RD',
        RegAddrCity: 'GRAFORD',
        RegAddrState: 'TX',
        RegAddrZip: 76449,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '1018 N 19TH ST',
        RegAddrCity: 'LAMESA',
        RegAddrState: 'TX',
        RegAddrZip: 79331,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '104 CHICAGO DR',
        RegAddrCity: 'LAMESA',
        RegAddrState: 'TX',
        RegAddrZip: 79331,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '204 PR',
        RegAddrCity: 'LOHN',
        RegAddrState: 'TX',
        RegAddrZip: 76852,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '2300 CAMP DR',
        RegAddrCity: 'MIDLAND',
        RegAddrState: 'TX',
        RegAddrZip: 79701,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '1105 MOGFORD ST',
        RegAddrCity: 'MIDLAND',
        RegAddrState: 'TX',
        RegAddrZip: 79701,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '9851 N HIGHWAY 281',
        RegAddrCity: 'MINERAL WELLS',
        RegAddrState: 'TX',
        RegAddrZip: 76067,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '2537 E 10TH ST',
        RegAddrCity: 'ODESSA',
        RegAddrState: 'TX',
        RegAddrZip: 79761,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '211 W VERMONT ST',
        RegAddrCity: 'ODESSA',
        RegAddrState: 'TX',
        RegAddrZip: 79764,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '9021 LAMAR AVE',
        RegAddrCity: 'ODESSA',
        RegAddrState: 'TX',
        RegAddrZip: 79765,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '7202 BELLINI ST',
        RegAddrCity: 'ODESSA',
        RegAddrState: 'TX',
        RegAddrZip: 79765,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '1515 WILLOW ST',
        RegAddrCity: 'SAN ANGELO',
        RegAddrState: 'TX',
        RegAddrZip: 76901,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '3806 DEERFIELD RD',
        RegAddrCity: 'SAN ANGELO',
        RegAddrState: 'TX',
        RegAddrZip: 76904,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '3166 PAINT ROCK RD',
        RegAddrCity: 'SAN ANGELO',
        RegAddrState: 'TX',
        RegAddrZip: 76905,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '161 NUCHOLS ST',
        RegAddrCity: 'SAN ANGELO',
        RegAddrState: 'TX',
        RegAddrZip: 76908,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '1102 E PEARL ST',
        RegAddrCity: 'STEPHENVILLE',
        RegAddrState: 'TX',
        RegAddrZip: 76401,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '254 W FREY ST',
        RegAddrCity: 'STEPHENVILLE',
        RegAddrState: 'TX',
        RegAddrZip: 76401,
        CongressionalDistrict: 11,
      },
    ],
  },
  {
    name: 'TX-12',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/TX-12?ref=gh-pages',
    state: 'TX',
    state_and_number: 'TX-12',
    zip: 76008,
    congressionalDistrict: 12,
    addressesInDistrict: [
      {
        RegAddrLine1: '132 TY LN',
        RegAddrCity: 'AZLE',
        RegAddrState: 'TX',
        RegAddrZip: 76020,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '12527 LIBERTY SCHOOL RD',
        RegAddrCity: 'AZLE',
        RegAddrState: 'TX',
        RegAddrZip: 76020,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '4012 BIRCHMAN AVE',
        RegAddrCity: 'FORT WORTH',
        RegAddrState: 'TX',
        RegAddrZip: 76107,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '8516 MICHAEL ST',
        RegAddrCity: 'FORT WORTH',
        RegAddrState: 'TX',
        RegAddrZip: 76108,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '10407 LITTLE VALLEY RD',
        RegAddrCity: 'FORT WORTH',
        RegAddrState: 'TX',
        RegAddrZip: 76108,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '4829 DILWORTH CT',
        RegAddrCity: 'FORT WORTH',
        RegAddrState: 'TX',
        RegAddrZip: 76116,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '5925 SIDEWINDER TRL',
        RegAddrCity: 'FORT WORTH',
        RegAddrState: 'TX',
        RegAddrZip: 76131,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '2117 RED BRANGUS TRL',
        RegAddrCity: 'FORT WORTH',
        RegAddrState: 'TX',
        RegAddrZip: 76131,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '3908 WINDFLOWER LN',
        RegAddrCity: 'FORT WORTH',
        RegAddrState: 'TX',
        RegAddrZip: 76137,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '3008 PEYTON BROOK DR',
        RegAddrCity: 'FORT WORTH',
        RegAddrState: 'TX',
        RegAddrZip: 76137,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '660 FOSSIL WOOD DR',
        RegAddrCity: 'FORT WORTH',
        RegAddrState: 'TX',
        RegAddrZip: 76179,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '7621 LAKE HIGHLANDS DR',
        RegAddrCity: 'FORT WORTH',
        RegAddrState: 'TX',
        RegAddrZip: 76179,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '1316 7TH AVE',
        RegAddrCity: 'FT WORTH',
        RegAddrState: 'TX',
        RegAddrZip: 76104,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '1505 OAK KNOLL DR',
        RegAddrCity: 'HALTOM CITY',
        RegAddrState: 'TX',
        RegAddrZip: 76117,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '2100 POE PRAIRIE RD',
        RegAddrCity: 'MILLSAP',
        RegAddrState: 'TX',
        RegAddrZip: 76066,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '6321 PEBBLE BEACH CT',
        RegAddrCity: 'N RICHLND HLS',
        RegAddrState: 'TX',
        RegAddrZip: 76180,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '139 CREASSER LN',
        RegAddrCity: 'RHOME',
        RegAddrState: 'TX',
        RegAddrZip: 76078,
        CongressionalDistrict: 12,
      },
      {
        RegAddrLine1: '101 PLEASANT VALLEY LN',
        RegAddrCity: 'WEATHERFORD',
        RegAddrState: 'TX',
        RegAddrZip: 76087,
        CongressionalDistrict: 12,
      },
    ],
  },
  {
    name: 'TX-13',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/TX-13?ref=gh-pages',
    state: 'TX',
    state_and_number: 'TX-13',
    zip: 76073,
    congressionalDistrict: 13,
    addressesInDistrict: [
      {
        RegAddrLine1: '2600 S MIRROR ST',
        RegAddrCity: 'AMARILLO',
        RegAddrState: 'TX',
        RegAddrZip: 79103,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '915 N CAROLINA ST',
        RegAddrCity: 'AMARILLO',
        RegAddrState: 'TX',
        RegAddrZip: 79106,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '411 NE 3RD AVE',
        RegAddrCity: 'AMARILLO',
        RegAddrState: 'TX',
        RegAddrZip: 79107,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '6009 PAVILLARD DR',
        RegAddrCity: 'AMARILLO',
        RegAddrState: 'TX',
        RegAddrZip: 79108,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '3502 CARLTON DR',
        RegAddrCity: 'AMARILLO',
        RegAddrState: 'TX',
        RegAddrZip: 79109,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '4022 CLEARWELL ST',
        RegAddrCity: 'AMARILLO',
        RegAddrState: 'TX',
        RegAddrZip: 79109,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '208 LOMA LINDA LN',
        RegAddrCity: 'AMARILLO',
        RegAddrState: 'TX',
        RegAddrZip: 79118,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '439 W COLLEGE ST',
        RegAddrCity: 'BURKBURNETT',
        RegAddrState: 'TX',
        RegAddrZip: 76354,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '18000 MORNING STAR RD',
        RegAddrCity: 'BUSHLAND',
        RegAddrState: 'TX',
        RegAddrZip: 79012,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '1077 COUNTY ROAD 301',
        RegAddrCity: 'GAINESVILLE',
        RegAddrState: 'TX',
        RegAddrZip: 76240,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '1308 HILLCREST BLVD',
        RegAddrCity: 'GAINESVILLE',
        RegAddrState: 'TX',
        RegAddrZip: 76240,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '106 S FORDHAM ST',
        RegAddrCity: 'PERRYTON',
        RegAddrState: 'TX',
        RegAddrZip: 79070,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '1810 EAGLE ST',
        RegAddrCity: 'VERNON',
        RegAddrState: 'TX',
        RegAddrZip: 76384,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '10829 E HIGHWAY 82',
        RegAddrCity: 'WHITESBORO',
        RegAddrState: 'TX',
        RegAddrZip: 76273,
        CongressionalDistrict: 13,
      },
      {
        RegAddrLine1: '22 TOWN AND COUNTRY DR',
        RegAddrCity: 'WICHITA FALLS',
        RegAddrState: 'TX',
        RegAddrZip: 76306,
        CongressionalDistrict: 13,
      },
    ],
  },
  {
    name: 'TX-14',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/TX-14?ref=gh-pages',
    state: 'TX',
    state_and_number: 'TX-14',
    zip: 77422,
    congressionalDistrict: 14,
    addressesInDistrict: [
      {
        RegAddrLine1: '3 WAGON LANE LOOP',
        RegAddrCity: 'ANGLETON',
        RegAddrState: 'TX',
        RegAddrZip: 77515,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '4220 COOLIDGE ST',
        RegAddrCity: 'BEAUMONT',
        RegAddrState: 'TX',
        RegAddrZip: 77706,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '3749 CHAMPIONS DR',
        RegAddrCity: 'BEAUMONT',
        RegAddrState: 'TX',
        RegAddrZip: 77707,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '2510 24TH ST',
        RegAddrCity: 'DICKINSON',
        RegAddrState: 'TX',
        RegAddrZip: 77539,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '4212 KANSAS AVE',
        RegAddrCity: 'DICKINSON',
        RegAddrState: 'TX',
        RegAddrZip: 77539,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '7714 BELUCHE DR',
        RegAddrCity: 'GALVESTON',
        RegAddrState: 'TX',
        RegAddrZip: 77551,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '246 CREEKSIDE DR',
        RegAddrCity: 'HOLIDAY LAKES',
        RegAddrState: 'TX',
        RegAddrZip: 77515,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '1306 W HOUSTON DR',
        RegAddrCity: 'LA MARQUE',
        RegAddrState: 'TX',
        RegAddrZip: 77568,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '109 HUISACHE ST',
        RegAddrCity: 'LAKE JACKSON',
        RegAddrState: 'TX',
        RegAddrZip: 77566,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '114 MAPLE ST',
        RegAddrCity: 'LAKE JACKSON',
        RegAddrState: 'TX',
        RegAddrZip: 77566,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '213 CENTER WAY ST',
        RegAddrCity: 'LAKE JACKSON',
        RegAddrState: 'TX',
        RegAddrZip: 77566,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '3609 TALLOW FOREST ST',
        RegAddrCity: 'LEAGUE CITY',
        RegAddrState: 'TX',
        RegAddrZip: 77573,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '6254 MURPHY WOOD DR',
        RegAddrCity: 'LEAGUE CITY',
        RegAddrState: 'TX',
        RegAddrZip: 77573,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '6600 9TH AVE',
        RegAddrCity: 'PORT ARTHUR',
        RegAddrState: 'TX',
        RegAddrZip: 77642,
        CongressionalDistrict: 14,
      },
      {
        RegAddrLine1: '17127 LAKE RIDGE CIR',
        RegAddrCity: 'ROSHARON',
        RegAddrState: 'TX',
        RegAddrZip: 77583,
        CongressionalDistrict: 14,
      },
    ],
  },
  {
    name: 'TX-15',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/TX-15?ref=gh-pages',
    state: 'TX',
    state_and_number: 'TX-15',
    zip: 78008,
    congressionalDistrict: 15,
    addressesInDistrict: [
      {
        RegAddrLine1: '5117 RIO GRANDE CARE RD',
        RegAddrCity: 'EDINBURG',
        RegAddrState: 'TX',
        RegAddrZip: 78541,
        CongressionalDistrict: 15,
      },
      {
        RegAddrLine1: '5318 GINKO DR',
        RegAddrCity: 'EDINBURG',
        RegAddrState: 'TX',
        RegAddrZip: 78542,
        CongressionalDistrict: 15,
      },
      {
        RegAddrLine1: '1107 E DAVIS RD',
        RegAddrCity: 'EDINBURG',
        RegAddrState: 'TX',
        RegAddrZip: 78542,
        CongressionalDistrict: 15,
      },
      {
        RegAddrLine1: '1519 WAYNE DR',
        RegAddrCity: 'EDINBURG',
        RegAddrState: 'TX',
        RegAddrZip: 78542,
        CongressionalDistrict: 15,
      },
      {
        RegAddrLine1: '5110 MILLS BENNETT RD',
        RegAddrCity: 'FREER',
        RegAddrState: 'TX',
        RegAddrZip: 78357,
        CongressionalDistrict: 15,
      },
      {
        RegAddrLine1: '1013 E CAMELIA AVE',
        RegAddrCity: 'HIDALGO',
        RegAddrState: 'TX',
        RegAddrZip: 78557,
        CongressionalDistrict: 15,
      },
      {
        RegAddrLine1: '2705 HELENA AVE',
        RegAddrCity: 'MCALLEN',
        RegAddrState: 'TX',
        RegAddrZip: 78503,
        CongressionalDistrict: 15,
      },
      {
        RegAddrLine1: '5105 N 1ST ST',
        RegAddrCity: 'MCALLEN',
        RegAddrState: 'TX',
        RegAddrZip: 78504,
        CongressionalDistrict: 15,
      },
      {
        RegAddrLine1: '544 AMIGOS LN',
        RegAddrCity: 'MERCEDES',
        RegAddrState: 'TX',
        RegAddrZip: 78570,
        CongressionalDistrict: 15,
      },
      {
        RegAddrLine1: '3505 SAN EUGENIO',
        RegAddrCity: 'MISSION',
        RegAddrState: 'TX',
        RegAddrZip: 78572,
        CongressionalDistrict: 15,
      },
      {
        RegAddrLine1: '5817 N LA HOMA RD',
        RegAddrCity: 'MISSION',
        RegAddrState: 'TX',
        RegAddrZip: 78574,
        CongressionalDistrict: 15,
      },
      {
        RegAddrLine1: '4640 KLEIN MDWS',
        RegAddrCity: 'NEW BRAUNFELS',
        RegAddrState: 'TX',
        RegAddrZip: 78130,
        CongressionalDistrict: 15,
      },
      {
        RegAddrLine1: '704 S DOGWOOD ST',
        RegAddrCity: 'PHARR',
        RegAddrState: 'TX',
        RegAddrZip: 78577,
        CongressionalDistrict: 15,
      },
      {
        RegAddrLine1: '808 W SABATINA LN',
        RegAddrCity: 'PHARR',
        RegAddrState: 'TX',
        RegAddrZip: 78577,
        CongressionalDistrict: 15,
      },
      {
        RegAddrLine1: '3406 SOFTRAIN',
        RegAddrCity: 'SAN ANTONIO',
        RegAddrState: 'TX',
        RegAddrZip: 78259,
        CongressionalDistrict: 15,
      },
      {
        RegAddrLine1: '605 GLORIA DR',
        RegAddrCity: 'SEGUIN',
        RegAddrState: 'TX',
        RegAddrZip: 78155,
        CongressionalDistrict: 15,
      },
      {
        RegAddrLine1: '1721 E LLANO GRANDE ST',
        RegAddrCity: 'WESLACO',
        RegAddrState: 'TX',
        RegAddrZip: 78596,
        CongressionalDistrict: 15,
      },
    ],
  },
  {
    name: 'TX-16',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/TX-16?ref=gh-pages',
    state: 'TX',
    state_and_number: 'TX-16',
    zip: 79821,
    congressionalDistrict: 16,
    addressesInDistrict: [
      {
        RegAddrLine1: '2924 DEVILS TOWER CIR',
        RegAddrCity: 'EL PASO',
        RegAddrState: 'TX',
        RegAddrZip: 79904,
        CongressionalDistrict: 16,
      },
      {
        RegAddrLine1: '8373 PARK HAVEN AVE',
        RegAddrCity: 'EL PASO',
        RegAddrState: 'TX',
        RegAddrZip: 79907,
        CongressionalDistrict: 16,
      },
      {
        RegAddrLine1: '6721 MESA GRANDE AVE',
        RegAddrCity: 'EL PASO',
        RegAddrState: 'TX',
        RegAddrZip: 79912,
        CongressionalDistrict: 16,
      },
      {
        RegAddrLine1: '5508 FAIRBANKS DR',
        RegAddrCity: 'EL PASO',
        RegAddrState: 'TX',
        RegAddrZip: 79924,
        CongressionalDistrict: 16,
      },
      {
        RegAddrLine1: '6015 MORNING GLORY CIR',
        RegAddrCity: 'EL PASO',
        RegAddrState: 'TX',
        RegAddrZip: 79924,
        CongressionalDistrict: 16,
      },
      {
        RegAddrLine1: '10172 SAIGON DR',
        RegAddrCity: 'EL PASO',
        RegAddrState: 'TX',
        RegAddrZip: 79925,
        CongressionalDistrict: 16,
      },
      {
        RegAddrLine1: '12611 KARI ANNE DR',
        RegAddrCity: 'EL PASO',
        RegAddrState: 'TX',
        RegAddrZip: 79928,
        CongressionalDistrict: 16,
      },
      {
        RegAddrLine1: '1929 KENTUCKY ST',
        RegAddrCity: 'EL PASO',
        RegAddrState: 'TX',
        RegAddrZip: 79930,
        CongressionalDistrict: 16,
      },
      {
        RegAddrLine1: '1566 DALE DOUGLAS DR',
        RegAddrCity: 'EL PASO',
        RegAddrState: 'TX',
        RegAddrZip: 79936,
        CongressionalDistrict: 16,
      },
      {
        RegAddrLine1: '11428 BUNKY HENRY LN',
        RegAddrCity: 'EL PASO',
        RegAddrState: 'TX',
        RegAddrZip: 79936,
        CongressionalDistrict: 16,
      },
      {
        RegAddrLine1: '1954 BAY CITY PL',
        RegAddrCity: 'EL PASO',
        RegAddrState: 'TX',
        RegAddrZip: 79936,
        CongressionalDistrict: 16,
      },
      {
        RegAddrLine1: '11708 GWEN EVANS LN',
        RegAddrCity: 'EL PASO',
        RegAddrState: 'TX',
        RegAddrZip: 79936,
        CongressionalDistrict: 16,
      },
      {
        RegAddrLine1: '11732 ROYAL BANNER LN',
        RegAddrCity: 'EL PASO',
        RegAddrState: 'TX',
        RegAddrZip: 79936,
        CongressionalDistrict: 16,
      },
      {
        RegAddrLine1: '14262 DON JOHNSON CT',
        RegAddrCity: 'EL PASO',
        RegAddrState: 'TX',
        RegAddrZip: 79938,
        CongressionalDistrict: 16,
      },
    ],
  },
  {
    name: 'TX-17',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/TX-17?ref=gh-pages',
    state: 'TX',
    state_and_number: 'TX-17',
    zip: 75838,
    congressionalDistrict: 17,
    addressesInDistrict: [
      {
        RegAddrLine1: '15013 MALLARD GREEN LN',
        RegAddrCity: 'AUSTIN',
        RegAddrState: 'TX',
        RegAddrZip: 78728,
        CongressionalDistrict: 17,
      },
      {
        RegAddrLine1: '11600 BIG TRL',
        RegAddrCity: 'AUSTIN',
        RegAddrState: 'TX',
        RegAddrZip: 78759,
        CongressionalDistrict: 17,
      },
      {
        RegAddrLine1: '4301 NAGLE ST',
        RegAddrCity: 'BRYAN',
        RegAddrState: 'TX',
        RegAddrZip: 77801,
        CongressionalDistrict: 17,
      },
      {
        RegAddrLine1: '2616 MELBA CIR',
        RegAddrCity: 'BRYAN',
        RegAddrState: 'TX',
        RegAddrZip: 77802,
        CongressionalDistrict: 17,
      },
      {
        RegAddrLine1: '8502 HARRIS LN',
        RegAddrCity: 'BRYAN',
        RegAddrState: 'TX',
        RegAddrZip: 77808,
        CongressionalDistrict: 17,
      },
      {
        RegAddrLine1: '1706 STARLING DR',
        RegAddrCity: 'COLLEGE STA',
        RegAddrState: 'TX',
        RegAddrZip: 77845,
        CongressionalDistrict: 17,
      },
      {
        RegAddrLine1: '700 DOVER DR',
        RegAddrCity: 'COLLEGE STA',
        RegAddrState: 'TX',
        RegAddrZip: 77845,
        CongressionalDistrict: 17,
      },
      {
        RegAddrLine1: '10654 W HENRY PRAIRIE RD',
        RegAddrCity: 'HEARNE',
        RegAddrState: 'TX',
        RegAddrZip: 77859,
        CongressionalDistrict: 17,
      },
      {
        RegAddrLine1: '10035 PR',
        RegAddrCity: 'JEWETT',
        RegAddrState: 'TX',
        RegAddrZip: 75846,
        CongressionalDistrict: 17,
      },
      {
        RegAddrLine1: '125 CROCKETT ST',
        RegAddrCity: 'MCGREGOR',
        RegAddrState: 'TX',
        RegAddrZip: 76657,
        CongressionalDistrict: 17,
      },
      {
        RegAddrLine1: '1032 S BELKNAP ST',
        RegAddrCity: 'MEXIA',
        RegAddrState: 'TX',
        RegAddrZip: 76667,
        CongressionalDistrict: 17,
      },
      {
        RegAddrLine1: '3140 BEAUFORD ST',
        RegAddrCity: 'ROBINSON',
        RegAddrState: 'TX',
        RegAddrZip: 76706,
        CongressionalDistrict: 17,
      },
      {
        RegAddrLine1: '393 PEEVEY LN',
        RegAddrCity: 'ROBINSON',
        RegAddrState: 'TX',
        RegAddrZip: 76706,
        CongressionalDistrict: 17,
      },
      {
        RegAddrLine1: '805 CISCO VALLEY CV',
        RegAddrCity: 'ROUND ROCK',
        RegAddrState: 'TX',
        RegAddrZip: 78664,
        CongressionalDistrict: 17,
      },
      {
        RegAddrLine1: '1701 LIVE OAK VALLEY CIR',
        RegAddrCity: 'WACO',
        RegAddrState: 'TX',
        RegAddrZip: 76710,
        CongressionalDistrict: 17,
      },
      {
        RegAddrLine1: '2800 AUSTIN AVE',
        RegAddrCity: 'WACO',
        RegAddrState: 'TX',
        RegAddrZip: 76710,
        CongressionalDistrict: 17,
      },
      {
        RegAddrLine1: '3066 BLUHM RD',
        RegAddrCity: 'WEST',
        RegAddrState: 'TX',
        RegAddrZip: 76691,
        CongressionalDistrict: 17,
      },
    ],
  },
  {
    name: 'TX-18',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/TX-18?ref=gh-pages',
    state: 'TX',
    state_and_number: 'TX-18',
    zip: 77003,
    congressionalDistrict: 18,
    addressesInDistrict: [
      {
        RegAddrLine1: '307 W 17TH ST',
        RegAddrCity: 'HOUSTON',
        RegAddrState: 'TX',
        RegAddrZip: 77008,
        CongressionalDistrict: 18,
      },
      {
        RegAddrLine1: '1033 HIGHLAND ST',
        RegAddrCity: 'HOUSTON',
        RegAddrState: 'TX',
        RegAddrZip: 77009,
        CongressionalDistrict: 18,
      },
      {
        RegAddrLine1: '7632 CHARLESMONT ST',
        RegAddrCity: 'HOUSTON',
        RegAddrState: 'TX',
        RegAddrZip: 77016,
        CongressionalDistrict: 18,
      },
      {
        RegAddrLine1: '5106 NOLRIDGE DR',
        RegAddrCity: 'HOUSTON',
        RegAddrState: 'TX',
        RegAddrZip: 77016,
        CongressionalDistrict: 18,
      },
      {
        RegAddrLine1: '8613 AUTUMN LN',
        RegAddrCity: 'HOUSTON',
        RegAddrState: 'TX',
        RegAddrZip: 77016,
        CongressionalDistrict: 18,
      },
      {
        RegAddrLine1: '4355 ROSSLYN RD',
        RegAddrCity: 'HOUSTON',
        RegAddrState: 'TX',
        RegAddrZip: 77018,
        CongressionalDistrict: 18,
      },
      {
        RegAddrLine1: '837 SARA ROSE ST',
        RegAddrCity: 'HOUSTON',
        RegAddrState: 'TX',
        RegAddrZip: 77018,
        CongressionalDistrict: 18,
      },
      {
        RegAddrLine1: '1605 MYSTIC ST',
        RegAddrCity: 'HOUSTON',
        RegAddrState: 'TX',
        RegAddrZip: 77020,
        CongressionalDistrict: 18,
      },
      {
        RegAddrLine1: '1803 STAPLES ST',
        RegAddrCity: 'HOUSTON',
        RegAddrState: 'TX',
        RegAddrZip: 77020,
        CongressionalDistrict: 18,
      },
      {
        RegAddrLine1: '1822 PANNELL ST',
        RegAddrCity: 'HOUSTON',
        RegAddrState: 'TX',
        RegAddrZip: 77020,
        CongressionalDistrict: 18,
      },
      {
        RegAddrLine1: '8014 DARLINGTON DR',
        RegAddrCity: 'HOUSTON',
        RegAddrState: 'TX',
        RegAddrZip: 77028,
        CongressionalDistrict: 18,
      },
      {
        RegAddrLine1: '8507 FILLMORE ST',
        RegAddrCity: 'HOUSTON',
        RegAddrState: 'TX',
        RegAddrZip: 77029,
        CongressionalDistrict: 18,
      },
      {
        RegAddrLine1: '7606 SMILING WOOD LN',
        RegAddrCity: 'HOUSTON',
        RegAddrState: 'TX',
        RegAddrZip: 77086,
        CongressionalDistrict: 18,
      },
      {
        RegAddrLine1: '2722 ROYAL SAGE DR',
        RegAddrCity: 'HOUSTON',
        RegAddrState: 'TX',
        RegAddrZip: 77088,
        CongressionalDistrict: 18,
      },
      {
        RegAddrLine1: '23143 CALICO CORNERS LN',
        RegAddrCity: 'SPRING',
        RegAddrState: 'TX',
        RegAddrZip: 77373,
        CongressionalDistrict: 18,
      },
      {
        RegAddrLine1: '4930 MONTEITH DR',
        RegAddrCity: 'SPRING',
        RegAddrState: 'TX',
        RegAddrZip: 77373,
        CongressionalDistrict: 18,
      },
    ],
  },
  {
    name: 'TX-19',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/TX-19?ref=gh-pages',
    state: 'TX',
    state_and_number: 'TX-19',
    zip: 76372,
    congressionalDistrict: 19,
    addressesInDistrict: [
      {
        RegAddrLine1: '782 ROSS AVE',
        RegAddrCity: 'ABILENE',
        RegAddrState: 'TX',
        RegAddrZip: 79605,
        CongressionalDistrict: 19,
      },
      {
        RegAddrLine1: '5 HOYLAKE DR',
        RegAddrCity: 'ABILENE',
        RegAddrState: 'TX',
        RegAddrZip: 79606,
        CongressionalDistrict: 19,
      },
      {
        RegAddrLine1: '721 E MAIN ST',
        RegAddrCity: 'BROWNFIELD',
        RegAddrState: 'TX',
        RegAddrZip: 79316,
        CongressionalDistrict: 19,
      },
      {
        RegAddrLine1: '241 DIANNA DR',
        RegAddrCity: 'GRAHAM',
        RegAddrState: 'TX',
        RegAddrZip: 76450,
        CongressionalDistrict: 19,
      },
      {
        RegAddrLine1: '104 OAK ST',
        RegAddrCity: 'LEVELLAND',
        RegAddrState: 'TX',
        RegAddrZip: 79336,
        CongressionalDistrict: 19,
      },
      {
        RegAddrLine1: '2506 E COUNTY ROAD 5300',
        RegAddrCity: 'LUBBOCK',
        RegAddrState: 'TX',
        RegAddrZip: 79403,
        CongressionalDistrict: 19,
      },
      {
        RegAddrLine1: '5406 MAGNOLIA AVE',
        RegAddrCity: 'LUBBOCK',
        RegAddrState: 'TX',
        RegAddrZip: 79404,
        CongressionalDistrict: 19,
      },
      {
        RegAddrLine1: '2218 39TH ST',
        RegAddrCity: 'LUBBOCK',
        RegAddrState: 'TX',
        RegAddrZip: 79412,
        CongressionalDistrict: 19,
      },
      {
        RegAddrLine1: '4808 61ST ST',
        RegAddrCity: 'LUBBOCK',
        RegAddrState: 'TX',
        RegAddrZip: 79414,
        CongressionalDistrict: 19,
      },
      {
        RegAddrLine1: '2820 COLGATE ST',
        RegAddrCity: 'LUBBOCK',
        RegAddrState: 'TX',
        RegAddrZip: 79415,
        CongressionalDistrict: 19,
      },
      {
        RegAddrLine1: '6024 3RD ST',
        RegAddrCity: 'LUBBOCK',
        RegAddrState: 'TX',
        RegAddrZip: 79416,
        CongressionalDistrict: 19,
      },
      {
        RegAddrLine1: '3001 COUNTY ROAD 7730',
        RegAddrCity: 'LUBBOCK',
        RegAddrState: 'TX',
        RegAddrZip: 79423,
        CongressionalDistrict: 19,
      },
      {
        RegAddrLine1: '6904 87TH ST',
        RegAddrCity: 'LUBBOCK',
        RegAddrState: 'TX',
        RegAddrZip: 79424,
        CongressionalDistrict: 19,
      },
      {
        RegAddrLine1: '408 SE 3RD ST',
        RegAddrCity: 'MORTON',
        RegAddrState: 'TX',
        RegAddrZip: 79346,
        CongressionalDistrict: 19,
      },
      {
        RegAddrLine1: '33 COUNTY ROAD 103',
        RegAddrCity: 'MULESHOE',
        RegAddrState: 'TX',
        RegAddrZip: 79347,
        CongressionalDistrict: 19,
      },
      {
        RegAddrLine1: '2218 N 1ST ST',
        RegAddrCity: 'TAHOKA',
        RegAddrState: 'TX',
        RegAddrZip: 79373,
        CongressionalDistrict: 19,
      },
      {
        RegAddrLine1: '102 AMANDA LN',
        RegAddrCity: 'TYE',
        RegAddrState: 'TX',
        RegAddrZip: 79563,
        CongressionalDistrict: 19,
      },
      {
        RegAddrLine1: '1602 GREEN AVE',
        RegAddrCity: 'WILSON',
        RegAddrState: 'TX',
        RegAddrZip: 79381,
        CongressionalDistrict: 19,
      },
    ],
  },
  {
    name: 'TX-2',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/TX-2?ref=gh-pages',
    state: 'TX',
    state_and_number: 'TX-2',
    zip: 77002,
    congressionalDistrict: 2,
    addressesInDistrict: [
      {
        RegAddrLine1: '2315 WROXTON RD',
        RegAddrCity: 'HOUSTON',
        RegAddrState: 'TX',
        RegAddrZip: 77005,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2027 WELCH ST',
        RegAddrCity: 'HOUSTON',
        RegAddrState: 'TX',
        RegAddrZip: 77019,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1419 MONTROSE BLVD',
        RegAddrCity: 'HOUSTON',
        RegAddrState: 'TX',
        RegAddrZip: 77019,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '9123 CHATSWORTH DR',
        RegAddrCity: 'HOUSTON',
        RegAddrState: 'TX',
        RegAddrZip: 77024,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '8010 CHORALE CT',
        RegAddrCity: 'HOUSTON',
        RegAddrState: 'TX',
        RegAddrZip: 77040,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '13726 NESTON DR',
        RegAddrCity: 'HOUSTON',
        RegAddrState: 'TX',
        RegAddrZip: 77041,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '9745 LONG BRANCH LN',
        RegAddrCity: 'HOUSTON',
        RegAddrState: 'TX',
        RegAddrZip: 77055,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '8119 BRUSHFIELD RD',
        RegAddrCity: 'HOUSTON',
        RegAddrState: 'TX',
        RegAddrZip: 77064,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '8638 RAYSON RD',
        RegAddrCity: 'HOUSTON',
        RegAddrState: 'TX',
        RegAddrZip: 77080,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2526 PINE VILLAGE DR',
        RegAddrCity: 'HOUSTON',
        RegAddrState: 'TX',
        RegAddrZip: 77080,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '14315 S STONEYGROVE LOOP',
        RegAddrCity: 'HOUSTON',
        RegAddrState: 'TX',
        RegAddrZip: 77084,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '16630 STRATHMORE MANOR LN',
        RegAddrCity: 'HOUSTON',
        RegAddrState: 'TX',
        RegAddrZip: 77090,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '24227 WHITESAIL DR',
        RegAddrCity: 'HUFFMAN',
        RegAddrState: 'TX',
        RegAddrZip: 77336,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '17007 PERDIDO KEY LN',
        RegAddrCity: 'HUMBLE',
        RegAddrState: 'TX',
        RegAddrZip: 77346,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '23810 SPRING FORK DR',
        RegAddrCity: 'SPRING',
        RegAddrState: 'TX',
        RegAddrZip: 77373,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '4822 BRIDGEMONT LN',
        RegAddrCity: 'SPRING',
        RegAddrState: 'TX',
        RegAddrZip: 77388,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '3911 ROLLING TERRACE DR',
        RegAddrCity: 'SPRING',
        RegAddrState: 'TX',
        RegAddrZip: 77388,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '19306 COUNTRY VILLAGE DR',
        RegAddrCity: 'SPRING',
        RegAddrState: 'TX',
        RegAddrZip: 77388,
        CongressionalDistrict: 2,
      },
    ],
  },
  {
    name: 'TX-20',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/TX-20?ref=gh-pages',
    state: 'TX',
    state_and_number: 'TX-20',
    zip: 78023,
    congressionalDistrict: 20,
    addressesInDistrict: [
      {
        RegAddrLine1: '1639 LEE HALL',
        RegAddrCity: 'SAN ANTONIO',
        RegAddrState: 'TX',
        RegAddrZip: 78201,
        CongressionalDistrict: 20,
      },
      {
        RegAddrLine1: '407 BRIGGS ST',
        RegAddrCity: 'SAN ANTONIO',
        RegAddrState: 'TX',
        RegAddrZip: 78211,
        CongressionalDistrict: 20,
      },
      {
        RegAddrLine1: '338 BARRETT PL',
        RegAddrCity: 'SAN ANTONIO',
        RegAddrState: 'TX',
        RegAddrZip: 78225,
        CongressionalDistrict: 20,
      },
      {
        RegAddrLine1: '610 ELLOR DR',
        RegAddrCity: 'SAN ANTONIO',
        RegAddrState: 'TX',
        RegAddrZip: 78228,
        CongressionalDistrict: 20,
      },
      {
        RegAddrLine1: '523 ALICE FAY AVE',
        RegAddrCity: 'SAN ANTONIO',
        RegAddrState: 'TX',
        RegAddrZip: 78237,
        CongressionalDistrict: 20,
      },
      {
        RegAddrLine1: '7706 DEER LN',
        RegAddrCity: 'SAN ANTONIO',
        RegAddrState: 'TX',
        RegAddrZip: 78240,
        CongressionalDistrict: 20,
      },
      {
        RegAddrLine1: '7523 HAPPY VALLEY DR',
        RegAddrCity: 'SAN ANTONIO',
        RegAddrState: 'TX',
        RegAddrZip: 78242,
        CongressionalDistrict: 20,
      },
      {
        RegAddrLine1: '7322 RED DEER PASS',
        RegAddrCity: 'SAN ANTONIO',
        RegAddrState: 'TX',
        RegAddrZip: 78249,
        CongressionalDistrict: 20,
      },
      {
        RegAddrLine1: '7810 BRAUN CIR',
        RegAddrCity: 'SAN ANTONIO',
        RegAddrState: 'TX',
        RegAddrZip: 78250,
        CongressionalDistrict: 20,
      },
      {
        RegAddrLine1: '12310 WAGON BOSS',
        RegAddrCity: 'SAN ANTONIO',
        RegAddrState: 'TX',
        RegAddrZip: 78254,
        CongressionalDistrict: 20,
      },
      {
        RegAddrLine1: '9715 SUMMERBROOK',
        RegAddrCity: 'SAN ANTONIO',
        RegAddrState: 'TX',
        RegAddrZip: 78254,
        CongressionalDistrict: 20,
      },
    ],
  },
  {
    name: 'TX-21',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/TX-21?ref=gh-pages',
    state: 'TX',
    state_and_number: 'TX-21',
    zip: 78003,
    congressionalDistrict: 21,
    addressesInDistrict: [
      {
        RegAddrLine1: '7404 ALBERT RD',
        RegAddrCity: 'AUSTIN',
        RegAddrState: 'TX',
        RegAddrZip: 78745,
        CongressionalDistrict: 21,
      },
      {
        RegAddrLine1: '11305 BLAIRVIEW LN',
        RegAddrCity: 'AUSTIN',
        RegAddrState: 'TX',
        RegAddrZip: 78748,
        CongressionalDistrict: 21,
      },
      {
        RegAddrLine1: '153 OUTLOOK PT',
        RegAddrCity: 'BOERNE',
        RegAddrState: 'TX',
        RegAddrZip: 78006,
        CongressionalDistrict: 21,
      },
      {
        RegAddrLine1: '1146 ARCADIA DR',
        RegAddrCity: 'CANYON LAKE',
        RegAddrState: 'TX',
        RegAddrZip: 78133,
        CongressionalDistrict: 21,
      },
      {
        RegAddrLine1: '414 CREST RIDGE DR',
        RegAddrCity: 'KERRVILLE',
        RegAddrState: 'TX',
        RegAddrZip: 78028,
        CongressionalDistrict: 21,
      },
      {
        RegAddrLine1: '1602 1/2 DEER TRL',
        RegAddrCity: 'KERRVILLE',
        RegAddrState: 'TX',
        RegAddrZip: 78028,
        CongressionalDistrict: 21,
      },
      {
        RegAddrLine1: '814 GUMNUT GRV',
        RegAddrCity: 'NEW BRAUNFELS',
        RegAddrState: 'TX',
        RegAddrZip: 78132,
        CongressionalDistrict: 21,
      },
      {
        RegAddrLine1: '114 OAKLEAF DR',
        RegAddrCity: 'SAN ANTONIO',
        RegAddrState: 'TX',
        RegAddrZip: 78209,
        CongressionalDistrict: 21,
      },
      {
        RegAddrLine1: '9618 BARCELONA ST',
        RegAddrCity: 'SAN ANTONIO',
        RegAddrState: 'TX',
        RegAddrZip: 78230,
        CongressionalDistrict: 21,
      },
      {
        RegAddrLine1: '2819 BEE CAVE ST',
        RegAddrCity: 'SAN ANTONIO',
        RegAddrState: 'TX',
        RegAddrZip: 78231,
        CongressionalDistrict: 21,
      },
      {
        RegAddrLine1: '2210 STALLION RUN',
        RegAddrCity: 'SAN ANTONIO',
        RegAddrState: 'TX',
        RegAddrZip: 78259,
        CongressionalDistrict: 21,
      },
      {
        RegAddrLine1: '25807 ENSENDA PARK',
        RegAddrCity: 'SAN ANTONIO',
        RegAddrState: 'TX',
        RegAddrZip: 78261,
        CongressionalDistrict: 21,
      },
      {
        RegAddrLine1: '304 RACING OAK LOOP',
        RegAddrCity: 'SAN MARCOS',
        RegAddrState: 'TX',
        RegAddrZip: 78666,
        CongressionalDistrict: 21,
      },
      {
        RegAddrLine1: '620 BLANCO RIVER RANCH RD',
        RegAddrCity: 'SAN MARCOS',
        RegAddrState: 'TX',
        RegAddrZip: 78666,
        CongressionalDistrict: 21,
      },
    ],
  },
  {
    name: 'TX-22',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/TX-22?ref=gh-pages',
    state: 'TX',
    state_and_number: 'TX-22',
    zip: 77034,
    congressionalDistrict: 22,
    addressesInDistrict: [
      {
        RegAddrLine1: '1206 N TEAL ESTATES CIR',
        RegAddrCity: 'FRESNO',
        RegAddrState: 'TX',
        RegAddrZip: 77545,
        CongressionalDistrict: 22,
      },
      {
        RegAddrLine1: '3115 GENESSEE CREEK LN',
        RegAddrCity: 'FRIENDSWOOD',
        RegAddrState: 'TX',
        RegAddrZip: 77546,
        CongressionalDistrict: 22,
      },
      {
        RegAddrLine1: '3714 MYSTIC MEADOWS LN',
        RegAddrCity: 'FRIENDSWOOD',
        RegAddrState: 'TX',
        RegAddrZip: 77546,
        CongressionalDistrict: 22,
      },
      {
        RegAddrLine1: '11310 WAXWOOD DR',
        RegAddrCity: 'HOUSTON',
        RegAddrState: 'TX',
        RegAddrZip: 77089,
        CongressionalDistrict: 22,
      },
      {
        RegAddrLine1: '4818 DUBUQUE PKWY',
        RegAddrCity: 'IOWA COLONY',
        RegAddrState: 'TX',
        RegAddrZip: 77583,
        CongressionalDistrict: 22,
      },
      {
        RegAddrLine1: '6019 WALKABOUT WAY',
        RegAddrCity: 'KATY',
        RegAddrState: 'TX',
        RegAddrZip: 77450,
        CongressionalDistrict: 22,
      },
      {
        RegAddrLine1: '5206 CUNNINGHAM DR',
        RegAddrCity: 'PEARLAND',
        RegAddrState: 'TX',
        RegAddrZip: 77581,
        CongressionalDistrict: 22,
      },
      {
        RegAddrLine1: '3302 SEQUOIA LAKE TRL',
        RegAddrCity: 'PEARLAND',
        RegAddrState: 'TX',
        RegAddrZip: 77581,
        CongressionalDistrict: 22,
      },
      {
        RegAddrLine1: '2705 FIELD HOLLOW DR',
        RegAddrCity: 'PEARLAND',
        RegAddrState: 'TX',
        RegAddrZip: 77584,
        CongressionalDistrict: 22,
      },
      {
        RegAddrLine1: '3506 PICKERING LN',
        RegAddrCity: 'PEARLAND',
        RegAddrState: 'TX',
        RegAddrZip: 77584,
        CongressionalDistrict: 22,
      },
      {
        RegAddrLine1: '12408 SILENT CREEK DR',
        RegAddrCity: 'PEARLAND',
        RegAddrState: 'TX',
        RegAddrZip: 77584,
        CongressionalDistrict: 22,
      },
      {
        RegAddrLine1: '1706 MORTON LEAGUE RD',
        RegAddrCity: 'RICHMOND',
        RegAddrState: 'TX',
        RegAddrZip: 77406,
        CongressionalDistrict: 22,
      },
      {
        RegAddrLine1: '26402 MISTY EMBER LN',
        RegAddrCity: 'RICHMOND',
        RegAddrState: 'TX',
        RegAddrZip: 77406,
        CongressionalDistrict: 22,
      },
      {
        RegAddrLine1: '419 RICHMOND PLACE DR',
        RegAddrCity: 'RICHMOND',
        RegAddrState: 'TX',
        RegAddrZip: 77469,
        CongressionalDistrict: 22,
      },
      {
        RegAddrLine1: '7207 LILAC MANOR LN',
        RegAddrCity: 'RICHMOND',
        RegAddrState: 'TX',
        RegAddrZip: 77469,
        CongressionalDistrict: 22,
      },
      {
        RegAddrLine1: '118 OXFORD CT',
        RegAddrCity: 'RICHMOND',
        RegAddrState: 'TX',
        RegAddrZip: 77469,
        CongressionalDistrict: 22,
      },
      {
        RegAddrLine1: '7214 NATCHEZ DR',
        RegAddrCity: 'RICHMOND',
        RegAddrState: 'TX',
        RegAddrZip: 77469,
        CongressionalDistrict: 22,
      },
      {
        RegAddrLine1: '12011 ORCHARD CT',
        RegAddrCity: 'STAFFORD',
        RegAddrState: 'TX',
        RegAddrZip: 77477,
        CongressionalDistrict: 22,
      },
      {
        RegAddrLine1: '9114 MORNINGSTAR DR',
        RegAddrCity: 'SUGAR LAND',
        RegAddrState: 'TX',
        RegAddrZip: 77479,
        CongressionalDistrict: 22,
      },
    ],
  },
  {
    name: 'TX-23',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/TX-23?ref=gh-pages',
    state: 'TX',
    state_and_number: 'TX-23',
    zip: 76932,
    congressionalDistrict: 23,
    addressesInDistrict: [
      {
        RegAddrLine1: '711 W UVALDE ST',
        RegAddrCity: 'CRYSTAL CITY',
        RegAddrState: 'TX',
        RegAddrZip: 78839,
        CongressionalDistrict: 23,
      },
      {
        RegAddrLine1: '501 E DE LA ROSA ST',
        RegAddrCity: 'DEL RIO',
        RegAddrState: 'TX',
        RegAddrZip: 78840,
        CongressionalDistrict: 23,
      },
      {
        RegAddrLine1: '1275 FM 2523',
        RegAddrCity: 'DEL RIO',
        RegAddrState: 'TX',
        RegAddrZip: 78840,
        CongressionalDistrict: 23,
      },
      {
        RegAddrLine1: '180 PRIVATE ROAD 4760',
        RegAddrCity: 'DILLEY',
        RegAddrState: 'TX',
        RegAddrZip: 78017,
        CongressionalDistrict: 23,
      },
      {
        RegAddrLine1: '19312 GARY LEE',
        RegAddrCity: 'EL PASO',
        RegAddrState: 'TX',
        RegAddrZip: 79938,
        CongressionalDistrict: 23,
      },
      {
        RegAddrLine1: '605 W MURCHISON AVE',
        RegAddrCity: 'ELDORADO',
        RegAddrState: 'TX',
        RegAddrZip: 76936,
        CongressionalDistrict: 23,
      },
      {
        RegAddrLine1: '216 NW EUBANKS ST',
        RegAddrCity: 'FABENS',
        RegAddrState: 'TX',
        RegAddrZip: 79838,
        CongressionalDistrict: 23,
      },
      {
        RegAddrLine1: '737 NE WALKER AVE',
        RegAddrCity: 'FABENS',
        RegAddrState: 'TX',
        RegAddrZip: 79838,
        CongressionalDistrict: 23,
      },
      {
        RegAddrLine1: '2049 E 50TH LN',
        RegAddrCity: 'FORT STOCKTON',
        RegAddrState: 'TX',
        RegAddrZip: 79735,
        CongressionalDistrict: 23,
      },
      {
        RegAddrLine1: '3699 FM 2200 W',
        RegAddrCity: 'MOORE',
        RegAddrState: 'TX',
        RegAddrZip: 78057,
        CongressionalDistrict: 23,
      },
      {
        RegAddrLine1: '123 N WILLOW ST',
        RegAddrCity: 'PEARSALL',
        RegAddrState: 'TX',
        RegAddrZip: 78061,
        CongressionalDistrict: 23,
      },
      {
        RegAddrLine1: '946 HAGEN WAY',
        RegAddrCity: 'SAN ANTONIO',
        RegAddrState: 'TX',
        RegAddrZip: 78221,
        CongressionalDistrict: 23,
      },
      {
        RegAddrLine1: '1314 JANICE GAIL DR',
        RegAddrCity: 'SAN ANTONIO',
        RegAddrState: 'TX',
        RegAddrZip: 78224,
        CongressionalDistrict: 23,
      },
      {
        RegAddrLine1: '8622 STILL MOON',
        RegAddrCity: 'SAN ANTONIO',
        RegAddrState: 'TX',
        RegAddrZip: 78245,
        CongressionalDistrict: 23,
      },
      {
        RegAddrLine1: '11647 ZINNIA FLDS',
        RegAddrCity: 'SAN ANTONIO',
        RegAddrState: 'TX',
        RegAddrZip: 78245,
        CongressionalDistrict: 23,
      },
      {
        RegAddrLine1: '910 ROLLING ROCK DR',
        RegAddrCity: 'SAN ANTONIO',
        RegAddrState: 'TX',
        RegAddrZip: 78245,
        CongressionalDistrict: 23,
      },
      {
        RegAddrLine1: '10622 CHERRY LAUREL',
        RegAddrCity: 'SAN ANTONIO',
        RegAddrState: 'TX',
        RegAddrZip: 78245,
        CongressionalDistrict: 23,
      },
      {
        RegAddrLine1: '2830 CHERRY FIELD DR',
        RegAddrCity: 'SAN ANTONIO',
        RegAddrState: 'TX',
        RegAddrZip: 78245,
        CongressionalDistrict: 23,
      },
      {
        RegAddrLine1: '519 BENTLEY MNR',
        RegAddrCity: 'SAN ANTONIO',
        RegAddrState: 'TX',
        RegAddrZip: 78249,
        CongressionalDistrict: 23,
      },
      {
        RegAddrLine1: '25050 SUMMIT CRK',
        RegAddrCity: 'SAN ANTONIO',
        RegAddrState: 'TX',
        RegAddrZip: 78258,
        CongressionalDistrict: 23,
      },
      {
        RegAddrLine1: '23835 STATELY OAKS',
        RegAddrCity: 'SAN ANTONIO',
        RegAddrState: 'TX',
        RegAddrZip: 78260,
        CongressionalDistrict: 23,
      },
      {
        RegAddrLine1: '19618 DOVE HILL LN',
        RegAddrCity: 'SOMERSET',
        RegAddrState: 'TX',
        RegAddrZip: 78069,
        CongressionalDistrict: 23,
      },
      {
        RegAddrLine1: '999 W MAIN ST',
        RegAddrCity: 'UVALDE',
        RegAddrState: 'TX',
        RegAddrZip: 78801,
        CongressionalDistrict: 23,
      },
    ],
  },
  {
    name: 'TX-24',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/TX-24?ref=gh-pages',
    state: 'TX',
    state_and_number: 'TX-24',
    zip: 75001,
    congressionalDistrict: 24,
    addressesInDistrict: [
      {
        RegAddrLine1: '9651 BLANCO DR',
        RegAddrCity: 'ARGYLE',
        RegAddrState: 'TX',
        RegAddrZip: 76226,
        CongressionalDistrict: 24,
      },
      {
        RegAddrLine1: '908 GREGORY AVE',
        RegAddrCity: 'BEDFORD',
        RegAddrState: 'TX',
        RegAddrZip: 76022,
        CongressionalDistrict: 24,
      },
      {
        RegAddrLine1: '3120 GLENMERE CT',
        RegAddrCity: 'CARROLLTON',
        RegAddrState: 'TX',
        RegAddrZip: 75007,
        CongressionalDistrict: 24,
      },
      {
        RegAddrLine1: '1976 RAMBLING RIDGE LN',
        RegAddrCity: 'CARROLLTON',
        RegAddrState: 'TX',
        RegAddrZip: 75007,
        CongressionalDistrict: 24,
      },
      {
        RegAddrLine1: '2108 FALCON RIDGE DR',
        RegAddrCity: 'CARROLLTON',
        RegAddrState: 'TX',
        RegAddrZip: 75010,
        CongressionalDistrict: 24,
      },
      {
        RegAddrLine1: '4008 AMBLESIDE DR',
        RegAddrCity: 'COLLEYVILLE',
        RegAddrState: 'TX',
        RegAddrZip: 76034,
        CongressionalDistrict: 24,
      },
      {
        RegAddrLine1: '3507 FOX MEADOWS DR',
        RegAddrCity: 'COLLEYVILLE',
        RegAddrState: 'TX',
        RegAddrZip: 76034,
        CongressionalDistrict: 24,
      },
      {
        RegAddrLine1: '3913 AMBLESIDE DR',
        RegAddrCity: 'COLLEYVILLE',
        RegAddrState: 'TX',
        RegAddrZip: 76034,
        CongressionalDistrict: 24,
      },
      {
        RegAddrLine1: '132 CHERRYBARK DR',
        RegAddrCity: 'COPPELL',
        RegAddrState: 'TX',
        RegAddrZip: 75019,
        CongressionalDistrict: 24,
      },
      {
        RegAddrLine1: '3634 NORCROSS LN',
        RegAddrCity: 'DALLAS',
        RegAddrState: 'TX',
        RegAddrZip: 75229,
        CongressionalDistrict: 24,
      },
      {
        RegAddrLine1: '3083 PONDER DR',
        RegAddrCity: 'DALLAS',
        RegAddrState: 'TX',
        RegAddrZip: 75229,
        CongressionalDistrict: 24,
      },
      {
        RegAddrLine1: '524 VAL OAKS CT',
        RegAddrCity: 'HURST',
        RegAddrState: 'TX',
        RegAddrZip: 76053,
        CongressionalDistrict: 24,
      },
      {
        RegAddrLine1: '1129 HIGHLAND DR',
        RegAddrCity: 'MANSFIELD',
        RegAddrState: 'TX',
        RegAddrZip: 76063,
        CongressionalDistrict: 24,
      },
      {
        RegAddrLine1: '5712 LAKE DISTRICT DR',
        RegAddrCity: 'THE COLONY',
        RegAddrState: 'TX',
        RegAddrZip: 75056,
        CongressionalDistrict: 24,
      },
    ],
  },
  {
    name: 'TX-25',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/TX-25?ref=gh-pages',
    state: 'TX',
    state_and_number: 'TX-25',
    zip: 76009,
    congressionalDistrict: 25,
    addressesInDistrict: [
      {
        RegAddrLine1: '3203 BREEZE TER',
        RegAddrCity: 'AUSTIN',
        RegAddrState: 'TX',
        RegAddrZip: 78722,
        CongressionalDistrict: 25,
      },
      {
        RegAddrLine1: '5209 OVERBROOK DR',
        RegAddrCity: 'AUSTIN',
        RegAddrState: 'TX',
        RegAddrZip: 78723,
        CongressionalDistrict: 25,
      },
      {
        RegAddrLine1: '1606 GLENVALLEY DR',
        RegAddrCity: 'AUSTIN',
        RegAddrState: 'TX',
        RegAddrZip: 78723,
        CongressionalDistrict: 25,
      },
      {
        RegAddrLine1: '6524 ADEN LN',
        RegAddrCity: 'AUSTIN',
        RegAddrState: 'TX',
        RegAddrZip: 78739,
        CongressionalDistrict: 25,
      },
      {
        RegAddrLine1: '5812 MARCHMONT LN',
        RegAddrCity: 'AUSTIN',
        RegAddrState: 'TX',
        RegAddrZip: 78749,
        CongressionalDistrict: 25,
      },
      {
        RegAddrLine1: '1001 STOCKTON DR',
        RegAddrCity: 'BURLESON',
        RegAddrState: 'TX',
        RegAddrZip: 76028,
        CongressionalDistrict: 25,
      },
      {
        RegAddrLine1: '1206 JESTER CT',
        RegAddrCity: 'COPPERAS COVE',
        RegAddrState: 'TX',
        RegAddrZip: 76522,
        CongressionalDistrict: 25,
      },
      {
        RegAddrLine1: '1435 E CREEK DR',
        RegAddrCity: 'DRIPPING SPGS',
        RegAddrState: 'TX',
        RegAddrZip: 78620,
        CongressionalDistrict: 25,
      },
      {
        RegAddrLine1: '321 APACHE RD',
        RegAddrCity: 'GATESVILLE',
        RegAddrState: 'TX',
        RegAddrZip: 76528,
        CongressionalDistrict: 25,
      },
      {
        RegAddrLine1: '6649 COUNTY ROAD 1126',
        RegAddrCity: 'GODLEY',
        RegAddrState: 'TX',
        RegAddrZip: 76044,
        CongressionalDistrict: 25,
      },
      {
        RegAddrLine1: '7632 COUNTY ROAD 421',
        RegAddrCity: 'GRANDVIEW',
        RegAddrState: 'TX',
        RegAddrZip: 76050,
        CongressionalDistrict: 25,
      },
      {
        RegAddrLine1: '612 E WALNUT ST',
        RegAddrCity: 'HILLSBORO',
        RegAddrState: 'TX',
        RegAddrZip: 76645,
        CongressionalDistrict: 25,
      },
      {
        RegAddrLine1: '3305 LORENA DR',
        RegAddrCity: 'KILLEEN',
        RegAddrState: 'TX',
        RegAddrZip: 76549,
        CongressionalDistrict: 25,
      },
      {
        RegAddrLine1: '613 LAKE ESTATES DR',
        RegAddrCity: 'LAKEWAY',
        RegAddrState: 'TX',
        RegAddrZip: 78734,
        CongressionalDistrict: 25,
      },
      {
        RegAddrLine1: '621 E MORGAN',
        RegAddrCity: 'MERIDIAN',
        RegAddrState: 'TX',
        RegAddrZip: 76665,
        CongressionalDistrict: 25,
      },
      {
        RegAddrLine1: '22200 VERBENA PKWY',
        RegAddrCity: 'SPICEWOOD',
        RegAddrState: 'TX',
        RegAddrZip: 78669,
        CongressionalDistrict: 25,
      },
      {
        RegAddrLine1: '1900 FM 1807',
        RegAddrCity: 'VENUS',
        RegAddrState: 'TX',
        RegAddrZip: 76084,
        CongressionalDistrict: 25,
      },
      {
        RegAddrLine1: '42035 BAY HILL DR',
        RegAddrCity: 'WHITNEY',
        RegAddrState: 'TX',
        RegAddrZip: 76692,
        CongressionalDistrict: 25,
      },
      {
        RegAddrLine1: '114 FOX RUN',
        RegAddrCity: 'WHITNEY',
        RegAddrState: 'TX',
        RegAddrZip: 76692,
        CongressionalDistrict: 25,
      },
      {
        RegAddrLine1: '109 W CAMBRIDGE RD',
        RegAddrCity: 'WIMBERLEY',
        RegAddrState: 'TX',
        RegAddrZip: 78676,
        CongressionalDistrict: 25,
      },
    ],
  },
  {
    name: 'TX-26',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/TX-26?ref=gh-pages',
    state: 'TX',
    state_and_number: 'TX-26',
    zip: 75057,
    congressionalDistrict: 26,
    addressesInDistrict: [
      {
        RegAddrLine1: '1918 WICKERSHAM LN',
        RegAddrCity: 'CORINTH',
        RegAddrState: 'TX',
        RegAddrZip: 76210,
        CongressionalDistrict: 26,
      },
      {
        RegAddrLine1: '2204 PEMBROOKE PL',
        RegAddrCity: 'DENTON',
        RegAddrState: 'TX',
        RegAddrZip: 76205,
        CongressionalDistrict: 26,
      },
      {
        RegAddrLine1: '2420 EMERSON LN',
        RegAddrCity: 'DENTON',
        RegAddrState: 'TX',
        RegAddrZip: 76209,
        CongressionalDistrict: 26,
      },
      {
        RegAddrLine1: '2820 MISSING ADDRESS 427',
        RegAddrCity: 'DENTON',
        RegAddrState: 'TX',
        RegAddrZip: 76210,
        CongressionalDistrict: 26,
      },
      {
        RegAddrLine1: '2929 ELMRIDGE DR',
        RegAddrCity: 'FLOWER MOUND',
        RegAddrState: 'TX',
        RegAddrZip: 75022,
        CongressionalDistrict: 26,
      },
      {
        RegAddrLine1: '6005 ROCK RIDGE DR',
        RegAddrCity: 'FLOWER MOUND',
        RegAddrState: 'TX',
        RegAddrZip: 75028,
        CongressionalDistrict: 26,
      },
      {
        RegAddrLine1: '3315 CROSS TIMBERS RD',
        RegAddrCity: 'FLOWER MOUND',
        RegAddrState: 'TX',
        RegAddrZip: 75028,
        CongressionalDistrict: 26,
      },
      {
        RegAddrLine1: '8320 BLAIR RD',
        RegAddrCity: 'JUSTIN',
        RegAddrState: 'TX',
        RegAddrZip: 76247,
        CongressionalDistrict: 26,
      },
      {
        RegAddrLine1: '212 KILKENNY CT',
        RegAddrCity: 'KELLER',
        RegAddrState: 'TX',
        RegAddrZip: 76248,
        CongressionalDistrict: 26,
      },
      {
        RegAddrLine1: '1628 YELLOWTHROAT DR',
        RegAddrCity: 'LITTLE ELM',
        RegAddrState: 'TX',
        RegAddrZip: 75068,
        CongressionalDistrict: 26,
      },
      {
        RegAddrLine1: '7105 ASHWORTH DR',
        RegAddrCity: 'N RICHLND HLS',
        RegAddrState: 'TX',
        RegAddrZip: 76182,
        CongressionalDistrict: 26,
      },
      {
        RegAddrLine1: '10040 CEDAR LAKE DR',
        RegAddrCity: 'PROVIDENCE VILLAGE',
        RegAddrState: 'TX',
        RegAddrZip: 76227,
        CongressionalDistrict: 26,
      },
      {
        RegAddrLine1: '10316 FRANKLIN DR',
        RegAddrCity: 'PROVIDNCE VLG',
        RegAddrState: 'TX',
        RegAddrZip: 76227,
        CongressionalDistrict: 26,
      },
      {
        RegAddrLine1: '2514 TROPHY CLUB DR',
        RegAddrCity: 'TROPHY CLUB',
        RegAddrState: 'TX',
        RegAddrZip: 76262,
        CongressionalDistrict: 26,
      },
    ],
  },
  {
    name: 'TX-27',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/TX-27?ref=gh-pages',
    state: 'TX',
    state_and_number: 'TX-27',
    zip: 77414,
    congressionalDistrict: 27,
    addressesInDistrict: [
      {
        RegAddrLine1: '5148 FM 1069 N',
        RegAddrCity: 'ARANSAS PASS',
        RegAddrState: 'TX',
        RegAddrZip: 78336,
        CongressionalDistrict: 27,
      },
      {
        RegAddrLine1: '206 LUNA ST',
        RegAddrCity: 'CORP CHRISTI',
        RegAddrState: 'TX',
        RegAddrZip: 78408,
        CongressionalDistrict: 27,
      },
      {
        RegAddrLine1: '4549 TEAL DR',
        RegAddrCity: 'CORP CHRISTI',
        RegAddrState: 'TX',
        RegAddrZip: 78410,
        CongressionalDistrict: 27,
      },
      {
        RegAddrLine1: '4029 LINDEN ST',
        RegAddrCity: 'CORP CHRISTI',
        RegAddrState: 'TX',
        RegAddrZip: 78411,
        CongressionalDistrict: 27,
      },
      {
        RegAddrLine1: '521 PASADENA PL',
        RegAddrCity: 'CORP CHRISTI',
        RegAddrState: 'TX',
        RegAddrZip: 78411,
        CongressionalDistrict: 27,
      },
      {
        RegAddrLine1: '4234 JUNIOR TER',
        RegAddrCity: 'CORP CHRISTI',
        RegAddrState: 'TX',
        RegAddrZip: 78412,
        CongressionalDistrict: 27,
      },
      {
        RegAddrLine1: '6713 PEMBROCK',
        RegAddrCity: 'CORP CHRISTI',
        RegAddrState: 'TX',
        RegAddrZip: 78414,
        CongressionalDistrict: 27,
      },
      {
        RegAddrLine1: '15849 PUNTA ESPADA LOOP',
        RegAddrCity: 'CORP CHRISTI',
        RegAddrState: 'TX',
        RegAddrZip: 78418,
        CongressionalDistrict: 27,
      },
      {
        RegAddrLine1: '15309 KEY LARGO CT',
        RegAddrCity: 'CORP CHRISTI',
        RegAddrState: 'TX',
        RegAddrZip: 78418,
        CongressionalDistrict: 27,
      },
      {
        RegAddrLine1: '15246 CARAVEL DR',
        RegAddrCity: 'CORP CHRISTI',
        RegAddrState: 'TX',
        RegAddrZip: 78418,
        CongressionalDistrict: 27,
      },
      {
        RegAddrLine1: '4121 LAGUNA SHORES RD',
        RegAddrCity: 'CORP CHRISTI',
        RegAddrState: 'TX',
        RegAddrZip: 78418,
        CongressionalDistrict: 27,
      },
      {
        RegAddrLine1: '1217 VERNON DR',
        RegAddrCity: 'CORPUS CHRISTI',
        RegAddrState: 'TX',
        RegAddrZip: 78407,
        CongressionalDistrict: 27,
      },
      {
        RegAddrLine1: '7550 OLD COLONY LINE RD',
        RegAddrCity: 'DALE',
        RegAddrState: 'TX',
        RegAddrZip: 78616,
        CongressionalDistrict: 27,
      },
      {
        RegAddrLine1: '117 HIGH POINT DR',
        RegAddrCity: 'DEL VALLE',
        RegAddrState: 'TX',
        RegAddrZip: 78617,
        CongressionalDistrict: 27,
      },
      {
        RegAddrLine1: '1005 ALICE AVE',
        RegAddrCity: 'EL CAMPO',
        RegAddrState: 'TX',
        RegAddrZip: 77437,
        CongressionalDistrict: 27,
      },
      {
        RegAddrLine1: '500 ALGIRITA ST',
        RegAddrCity: 'LULING',
        RegAddrState: 'TX',
        RegAddrZip: 78648,
        CongressionalDistrict: 27,
      },
      {
        RegAddrLine1: '101 S LANCASTER',
        RegAddrCity: 'MOULTON',
        RegAddrState: 'TX',
        RegAddrZip: 77975,
        CongressionalDistrict: 27,
      },
      {
        RegAddrLine1: '2201 OAK WOOD DR',
        RegAddrCity: 'PORTLAND',
        RegAddrState: 'TX',
        RegAddrZip: 78374,
        CongressionalDistrict: 27,
      },
      {
        RegAddrLine1: '500 W AVENUE D',
        RegAddrCity: 'ROBSTOWN',
        RegAddrState: 'TX',
        RegAddrZip: 78380,
        CongressionalDistrict: 27,
      },
      {
        RegAddrLine1: '2981 FM 1069',
        RegAddrCity: 'ROCKPORT',
        RegAddrState: 'TX',
        RegAddrZip: 78382,
        CongressionalDistrict: 27,
      },
      {
        RegAddrLine1: '986 COUNTY ROAD 372',
        RegAddrCity: 'SHINER',
        RegAddrState: 'TX',
        RegAddrZip: 77984,
        CongressionalDistrict: 27,
      },
      {
        RegAddrLine1: '604 S WHEELER ST',
        RegAddrCity: 'VICTORIA',
        RegAddrState: 'TX',
        RegAddrZip: 77901,
        CongressionalDistrict: 27,
      },
      {
        RegAddrLine1: '403 CHARLESTON DR',
        RegAddrCity: 'VICTORIA',
        RegAddrState: 'TX',
        RegAddrZip: 77904,
        CongressionalDistrict: 27,
      },
      {
        RegAddrLine1: '2587 KOHUTEK RD',
        RegAddrCity: 'VICTORIA',
        RegAddrState: 'TX',
        RegAddrZip: 77904,
        CongressionalDistrict: 27,
      },
      {
        RegAddrLine1: '2525 NELGA RD',
        RegAddrCity: 'WHARTON',
        RegAddrState: 'TX',
        RegAddrZip: 77488,
        CongressionalDistrict: 27,
      },
      {
        RegAddrLine1: '618 BELL ST',
        RegAddrCity: 'WHARTON',
        RegAddrState: 'TX',
        RegAddrZip: 77488,
        CongressionalDistrict: 27,
      },
    ],
  },
  {
    name: 'TX-28',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/TX-28?ref=gh-pages',
    state: 'TX',
    state_and_number: 'TX-28',
    zip: 78007,
    congressionalDistrict: 28,
    addressesInDistrict: [
      {
        RegAddrLine1: '44 E COQUATE RCH RD',
        RegAddrCity: 'ENCINAL',
        RegAddrState: 'TX',
        RegAddrZip: 78019,
        CongressionalDistrict: 28,
      },
      {
        RegAddrLine1: '4042 COUNTY ROAD 305',
        RegAddrCity: 'FLORESVILLE',
        RegAddrState: 'TX',
        RegAddrZip: 78114,
        CongressionalDistrict: 28,
      },
      {
        RegAddrLine1: '2417 SANTA CLEOTILDE AVE',
        RegAddrCity: 'LAREDO',
        RegAddrState: 'TX',
        RegAddrZip: 78040,
        CongressionalDistrict: 28,
      },
      {
        RegAddrLine1: '2803 MONTERREY AVE',
        RegAddrCity: 'LAREDO',
        RegAddrState: 'TX',
        RegAddrZip: 78040,
        CongressionalDistrict: 28,
      },
      {
        RegAddrLine1: '802 ROSARIO ST',
        RegAddrCity: 'LAREDO',
        RegAddrState: 'TX',
        RegAddrZip: 78040,
        CongressionalDistrict: 28,
      },
      {
        RegAddrLine1: '308 LAKE LOUISE CT',
        RegAddrCity: 'LAREDO',
        RegAddrState: 'TX',
        RegAddrZip: 78041,
        CongressionalDistrict: 28,
      },
      {
        RegAddrLine1: '3313 TIGER CTS',
        RegAddrCity: 'LAREDO',
        RegAddrState: 'TX',
        RegAddrZip: 78045,
        CongressionalDistrict: 28,
      },
      {
        RegAddrLine1: '1702 ARIES ST',
        RegAddrCity: 'MISSION',
        RegAddrState: 'TX',
        RegAddrZip: 78572,
        CongressionalDistrict: 28,
      },
      {
        RegAddrLine1: '4246 SUNRISE COVE DR',
        RegAddrCity: 'SAN ANTONIO',
        RegAddrState: 'TX',
        RegAddrZip: 78244,
        CongressionalDistrict: 28,
      },
      {
        RegAddrLine1: '137 MEDICINE ROCK',
        RegAddrCity: 'UNIVERSAL CTY',
        RegAddrState: 'TX',
        RegAddrZip: 78148,
        CongressionalDistrict: 28,
      },
      {
        RegAddrLine1: '541 BALFOUR DR',
        RegAddrCity: 'WINDCREST',
        RegAddrState: 'TX',
        RegAddrZip: 78239,
        CongressionalDistrict: 28,
      },
    ],
  },
  {
    name: 'TX-29',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/TX-29?ref=gh-pages',
    state: 'TX',
    state_and_number: 'TX-29',
    zip: 77017,
    congressionalDistrict: 29,
    addressesInDistrict: [
      {
        RegAddrLine1: '415 FOREST HILL BLVD',
        RegAddrCity: 'HOUSTON',
        RegAddrState: 'TX',
        RegAddrZip: 77011,
        CongressionalDistrict: 29,
      },
      {
        RegAddrLine1: '14137 VICTORIA ST',
        RegAddrCity: 'HOUSTON',
        RegAddrState: 'TX',
        RegAddrZip: 77015,
        CongressionalDistrict: 29,
      },
      {
        RegAddrLine1: '14347 FORCE ST',
        RegAddrCity: 'HOUSTON',
        RegAddrState: 'TX',
        RegAddrZip: 77015,
        CongressionalDistrict: 29,
      },
      {
        RegAddrLine1: '8306 WYNBROOK ST',
        RegAddrCity: 'HOUSTON',
        RegAddrState: 'TX',
        RegAddrZip: 77061,
        CongressionalDistrict: 29,
      },
      {
        RegAddrLine1: '813 COLONY LN',
        RegAddrCity: 'HOUSTON',
        RegAddrState: 'TX',
        RegAddrZip: 77076,
        CongressionalDistrict: 29,
      },
      {
        RegAddrLine1: '10403 BANTUM ST',
        RegAddrCity: 'HOUSTON',
        RegAddrState: 'TX',
        RegAddrZip: 77093,
        CongressionalDistrict: 29,
      },
      {
        RegAddrLine1: '4114 DONERAIL DR',
        RegAddrCity: 'PASADENA',
        RegAddrState: 'TX',
        RegAddrZip: 77503,
        CongressionalDistrict: 29,
      },
      {
        RegAddrLine1: '2129 PRESTON AVE',
        RegAddrCity: 'PASADENA',
        RegAddrState: 'TX',
        RegAddrZip: 77503,
        CongressionalDistrict: 29,
      },
      {
        RegAddrLine1: '301 W HART AVE',
        RegAddrCity: 'PASADENA',
        RegAddrState: 'TX',
        RegAddrZip: 77506,
        CongressionalDistrict: 29,
      },
      {
        RegAddrLine1: '812 IOWA ST',
        RegAddrCity: 'SOUTH HOUSTON',
        RegAddrState: 'TX',
        RegAddrZip: 77587,
        CongressionalDistrict: 29,
      },
    ],
  },
  {
    name: 'TX-3',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/TX-3?ref=gh-pages',
    state: 'TX',
    state_and_number: 'TX-3',
    zip: 75002,
    congressionalDistrict: 3,
    addressesInDistrict: [
      {
        RegAddrLine1: '1526 REDEEMER RD',
        RegAddrCity: 'ALLEN',
        RegAddrState: 'TX',
        RegAddrZip: 75002,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '915 GLEN ROSE DR',
        RegAddrCity: 'ALLEN',
        RegAddrState: 'TX',
        RegAddrZip: 75013,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '513 HOME PL',
        RegAddrCity: 'FAIRVIEW',
        RegAddrState: 'TX',
        RegAddrZip: 75069,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '10454 BALSAM CT',
        RegAddrCity: 'FRISCO',
        RegAddrState: 'TX',
        RegAddrZip: 75033,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '5761 PRESTON NORTH DR',
        RegAddrCity: 'FRISCO',
        RegAddrState: 'TX',
        RegAddrZip: 75034,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '5629 DOMER DR',
        RegAddrCity: 'FRISCO',
        RegAddrState: 'TX',
        RegAddrZip: 75035,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '2004 MEANDERING WAY',
        RegAddrCity: 'MCKINNEY',
        RegAddrState: 'TX',
        RegAddrZip: 75071,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '11712 BECKTON ST',
        RegAddrCity: 'MCKINNEY',
        RegAddrState: 'TX',
        RegAddrZip: 75071,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '506 CREEKSIDE DR',
        RegAddrCity: 'MURPHY',
        RegAddrState: 'TX',
        RegAddrZip: 75094,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '4009 GARRISON PL',
        RegAddrCity: 'PLANO',
        RegAddrState: 'TX',
        RegAddrZip: 75023,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '5716 CAVENDER DR',
        RegAddrCity: 'PLANO',
        RegAddrState: 'TX',
        RegAddrZip: 75093,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1720 TWIN HILLS WAY',
        RegAddrCity: 'PRINCETON',
        RegAddrState: 'TX',
        RegAddrZip: 75407,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '809 CLEARWATER DR',
        RegAddrCity: 'RICHARDSON',
        RegAddrState: 'TX',
        RegAddrZip: 75080,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '2800 TELLURIDE LN',
        RegAddrCity: 'RICHARDSON',
        RegAddrState: 'TX',
        RegAddrZip: 75082,
        CongressionalDistrict: 3,
      },
    ],
  },
  {
    name: 'TX-30',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/TX-30?ref=gh-pages',
    state: 'TX',
    state_and_number: 'TX-30',
    zip: 75115,
    congressionalDistrict: 30,
    addressesInDistrict: [
      {
        RegAddrLine1: '4920 W UNIVERSITY BLVD',
        RegAddrCity: 'DALLAS',
        RegAddrState: 'TX',
        RegAddrZip: 75209,
        CongressionalDistrict: 30,
      },
      {
        RegAddrLine1: '5124 BOURQUIN ST',
        RegAddrCity: 'DALLAS',
        RegAddrState: 'TX',
        RegAddrZip: 75210,
        CongressionalDistrict: 30,
      },
      {
        RegAddrLine1: '4736 STOKES ST',
        RegAddrCity: 'DALLAS',
        RegAddrState: 'TX',
        RegAddrZip: 75216,
        CongressionalDistrict: 30,
      },
      {
        RegAddrLine1: '1306 FORDHAM RD',
        RegAddrCity: 'DALLAS',
        RegAddrState: 'TX',
        RegAddrZip: 75216,
        CongressionalDistrict: 30,
      },
      {
        RegAddrLine1: '10019 SHAYNA DR',
        RegAddrCity: 'DALLAS',
        RegAddrState: 'TX',
        RegAddrZip: 75217,
        CongressionalDistrict: 30,
      },
      {
        RegAddrLine1: '2233 MAJOR DR',
        RegAddrCity: 'DALLAS',
        RegAddrState: 'TX',
        RegAddrZip: 75227,
        CongressionalDistrict: 30,
      },
      {
        RegAddrLine1: '7212 BLACKWILLOW LN',
        RegAddrCity: 'DALLAS',
        RegAddrState: 'TX',
        RegAddrZip: 75249,
        CongressionalDistrict: 30,
      },
      {
        RegAddrLine1: '923 BRECKENRIDGE DR',
        RegAddrCity: 'DESOTO',
        RegAddrState: 'TX',
        RegAddrZip: 75115,
        CongressionalDistrict: 30,
      },
      {
        RegAddrLine1: '204 SHADYBROOK DR',
        RegAddrCity: 'DESOTO',
        RegAddrState: 'TX',
        RegAddrZip: 75115,
        CongressionalDistrict: 30,
      },
      {
        RegAddrLine1: '707 JOHNSON DR',
        RegAddrCity: 'DUNCANVILLE',
        RegAddrState: 'TX',
        RegAddrZip: 75116,
        CongressionalDistrict: 30,
      },
      {
        RegAddrLine1: '535 HOLLY LN',
        RegAddrCity: 'DUNCANVILLE',
        RegAddrState: 'TX',
        RegAddrZip: 75116,
        CongressionalDistrict: 30,
      },
    ],
  },
  {
    name: 'TX-31',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/TX-31?ref=gh-pages',
    state: 'TX',
    state_and_number: 'TX-31',
    zip: 76501,
    congressionalDistrict: 31,
    addressesInDistrict: [
      {
        RegAddrLine1: '11100 LOS COMANCHEROS RD',
        RegAddrCity: 'AUSTIN',
        RegAddrState: 'TX',
        RegAddrZip: 78717,
        CongressionalDistrict: 31,
      },
      {
        RegAddrLine1: '9114 HAZELHURST DR',
        RegAddrCity: 'AUSTIN',
        RegAddrState: 'TX',
        RegAddrZip: 78729,
        CongressionalDistrict: 31,
      },
      {
        RegAddrLine1: '10505 SALT BLOCK CIR',
        RegAddrCity: 'AUSTIN',
        RegAddrState: 'TX',
        RegAddrZip: 78750,
        CongressionalDistrict: 31,
      },
      {
        RegAddrLine1: '108 N HILLVIEW DR',
        RegAddrCity: 'GEORGETOWN',
        RegAddrState: 'TX',
        RegAddrZip: 78628,
        CongressionalDistrict: 31,
      },
      {
        RegAddrLine1: '510 MONARCH TRL',
        RegAddrCity: 'GEORGETOWN',
        RegAddrState: 'TX',
        RegAddrZip: 78633,
        CongressionalDistrict: 31,
      },
      {
        RegAddrLine1: '2550 COUNTY ROAD 248',
        RegAddrCity: 'GEORGETOWN',
        RegAddrState: 'TX',
        RegAddrZip: 78633,
        CongressionalDistrict: 31,
      },
      {
        RegAddrLine1: '313 DELBY ST',
        RegAddrCity: 'HUTTO',
        RegAddrState: 'TX',
        RegAddrZip: 78634,
        CongressionalDistrict: 31,
      },
      {
        RegAddrLine1: '817 E VETERANS MEMORIAL BLVD',
        RegAddrCity: 'KILLEEN',
        RegAddrState: 'TX',
        RegAddrZip: 76541,
        CongressionalDistrict: 31,
      },
      {
        RegAddrLine1: '5007 JOSEPH DR',
        RegAddrCity: 'KILLEEN',
        RegAddrState: 'TX',
        RegAddrZip: 76542,
        CongressionalDistrict: 31,
      },
      {
        RegAddrLine1: '3204 CANTABRIAN DR',
        RegAddrCity: 'KILLEEN',
        RegAddrState: 'TX',
        RegAddrZip: 76542,
        CongressionalDistrict: 31,
      },
      {
        RegAddrLine1: '1207 VALENTINE ST',
        RegAddrCity: 'KILLEEN',
        RegAddrState: 'TX',
        RegAddrZip: 76549,
        CongressionalDistrict: 31,
      },
      {
        RegAddrLine1: '1207 WEST LN',
        RegAddrCity: 'KILLEEN',
        RegAddrState: 'TX',
        RegAddrZip: 76549,
        CongressionalDistrict: 31,
      },
      {
        RegAddrLine1: '420 TULA TRL',
        RegAddrCity: 'LEANDER',
        RegAddrState: 'TX',
        RegAddrZip: 78641,
        CongressionalDistrict: 31,
      },
      {
        RegAddrLine1: '4850 N HIGHWAY 183',
        RegAddrCity: 'LIBERTY HILL',
        RegAddrState: 'TX',
        RegAddrZip: 78642,
        CongressionalDistrict: 31,
      },
      {
        RegAddrLine1: '1012 GREENBRIAR CV',
        RegAddrCity: 'ROUND ROCK',
        RegAddrState: 'TX',
        RegAddrZip: 78664,
        CongressionalDistrict: 31,
      },
      {
        RegAddrLine1: '2209 SPRING HOLLOW PATH',
        RegAddrCity: 'ROUND ROCK',
        RegAddrState: 'TX',
        RegAddrZip: 78681,
        CongressionalDistrict: 31,
      },
      {
        RegAddrLine1: '209 S LAKE CREEK DR',
        RegAddrCity: 'ROUND ROCK',
        RegAddrState: 'TX',
        RegAddrZip: 78681,
        CongressionalDistrict: 31,
      },
      {
        RegAddrLine1: '15897 CROCKETT DR',
        RegAddrCity: 'TEMPLE',
        RegAddrState: 'TX',
        RegAddrZip: 76502,
        CongressionalDistrict: 31,
      },
      {
        RegAddrLine1: '1719 TWISTED OAK DR',
        RegAddrCity: 'TEMPLE',
        RegAddrState: 'TX',
        RegAddrZip: 76502,
        CongressionalDistrict: 31,
      },
      {
        RegAddrLine1: '203 LYDIA LN',
        RegAddrCity: 'THRALL',
        RegAddrState: 'TX',
        RegAddrZip: 76578,
        CongressionalDistrict: 31,
      },
    ],
  },
  {
    name: 'TX-32',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/TX-32?ref=gh-pages',
    state: 'TX',
    state_and_number: 'TX-32',
    zip: 75040,
    congressionalDistrict: 32,
    addressesInDistrict: [
      {
        RegAddrLine1: '5603 VICKERY BLVD',
        RegAddrCity: 'DALLAS',
        RegAddrState: 'TX',
        RegAddrZip: 75206,
        CongressionalDistrict: 32,
      },
      {
        RegAddrLine1: '7323 WAKE FORREST DR',
        RegAddrCity: 'DALLAS',
        RegAddrState: 'TX',
        RegAddrZip: 75214,
        CongressionalDistrict: 32,
      },
      {
        RegAddrLine1: '6725 ORCHID LN',
        RegAddrCity: 'DALLAS',
        RegAddrState: 'TX',
        RegAddrZip: 75230,
        CongressionalDistrict: 32,
      },
      {
        RegAddrLine1: '6441 LAVENDALE AVE',
        RegAddrCity: 'DALLAS',
        RegAddrState: 'TX',
        RegAddrZip: 75230,
        CongressionalDistrict: 32,
      },
      {
        RegAddrLine1: '7843 ALTO CARO DR',
        RegAddrCity: 'DALLAS',
        RegAddrState: 'TX',
        RegAddrZip: 75248,
        CongressionalDistrict: 32,
      },
      {
        RegAddrLine1: '2721 CORLEY DR',
        RegAddrCity: 'GARLAND',
        RegAddrState: 'TX',
        RegAddrZip: 75040,
        CongressionalDistrict: 32,
      },
      {
        RegAddrLine1: '3113 INNSBROOK DR',
        RegAddrCity: 'GARLAND',
        RegAddrState: 'TX',
        RegAddrZip: 75044,
        CongressionalDistrict: 32,
      },
      {
        RegAddrLine1: '2329 OAKCREST DR',
        RegAddrCity: 'GARLAND',
        RegAddrState: 'TX',
        RegAddrZip: 75044,
        CongressionalDistrict: 32,
      },
      {
        RegAddrLine1: '3113 ABBEY CT',
        RegAddrCity: 'GARLAND',
        RegAddrState: 'TX',
        RegAddrZip: 75044,
        CongressionalDistrict: 32,
      },
      {
        RegAddrLine1: '718 DEVONSHIRE DR',
        RegAddrCity: 'RICHARDSON',
        RegAddrState: 'TX',
        RegAddrZip: 75080,
        CongressionalDistrict: 32,
      },
      {
        RegAddrLine1: '1328 CHIPPEWA DR',
        RegAddrCity: 'RICHARDSON',
        RegAddrState: 'TX',
        RegAddrZip: 75080,
        CongressionalDistrict: 32,
      },
      {
        RegAddrLine1: '2240 DAHLIA WAY',
        RegAddrCity: 'RICHARDSON',
        RegAddrState: 'TX',
        RegAddrZip: 75080,
        CongressionalDistrict: 32,
      },
      {
        RegAddrLine1: '626 NEWBERRY DR',
        RegAddrCity: 'RICHARDSON',
        RegAddrState: 'TX',
        RegAddrZip: 75080,
        CongressionalDistrict: 32,
      },
      {
        RegAddrLine1: '1612 MONTCLAIR DR',
        RegAddrCity: 'RICHARDSON',
        RegAddrState: 'TX',
        RegAddrZip: 75081,
        CongressionalDistrict: 32,
      },
      {
        RegAddrLine1: '1003 HAYNES DR',
        RegAddrCity: 'RICHARDSON',
        RegAddrState: 'TX',
        RegAddrZip: 75081,
        CongressionalDistrict: 32,
      },
      {
        RegAddrLine1: '4102 MILLER RD',
        RegAddrCity: 'ROWLETT',
        RegAddrState: 'TX',
        RegAddrZip: 75088,
        CongressionalDistrict: 32,
      },
      {
        RegAddrLine1: '5102 ROYAL BAY DR',
        RegAddrCity: 'ROWLETT',
        RegAddrState: 'TX',
        RegAddrZip: 75089,
        CongressionalDistrict: 32,
      },
      {
        RegAddrLine1: '16 LUCY LN',
        RegAddrCity: 'WYLIE',
        RegAddrState: 'TX',
        RegAddrZip: 75098,
        CongressionalDistrict: 32,
      },
    ],
  },
  {
    name: 'TX-33',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/TX-33?ref=gh-pages',
    state: 'TX',
    state_and_number: 'TX-33',
    zip: 75060,
    congressionalDistrict: 33,
    addressesInDistrict: [
      {
        RegAddrLine1: '3130 HAMMERLY DR',
        RegAddrCity: 'DALLAS',
        RegAddrState: 'TX',
        RegAddrZip: 75212,
        CongressionalDistrict: 33,
      },
      {
        RegAddrLine1: '2819 CHICAGO ST',
        RegAddrCity: 'DALLAS',
        RegAddrState: 'TX',
        RegAddrZip: 75212,
        CongressionalDistrict: 33,
      },
      {
        RegAddrLine1: '8201 BROOKRIVER DR',
        RegAddrCity: 'DALLAS',
        RegAddrState: 'TX',
        RegAddrZip: 75247,
        CongressionalDistrict: 33,
      },
      {
        RegAddrLine1: '4241 S ADAMS ST',
        RegAddrCity: 'FORT WORTH',
        RegAddrState: 'TX',
        RegAddrZip: 76115,
        CongressionalDistrict: 33,
      },
      {
        RegAddrLine1: '3808 OAK HILL ST',
        RegAddrCity: 'FORT WORTH',
        RegAddrState: 'TX',
        RegAddrZip: 76119,
        CongressionalDistrict: 33,
      },
      {
        RegAddrLine1: '4616 WILLOW SPRINGS RD',
        RegAddrCity: 'FORT WORTH',
        RegAddrState: 'TX',
        RegAddrZip: 76119,
        CongressionalDistrict: 33,
      },
      {
        RegAddrLine1: '203 WICHITA ST',
        RegAddrCity: 'FORT WORTH',
        RegAddrState: 'TX',
        RegAddrZip: 76140,
        CongressionalDistrict: 33,
      },
      {
        RegAddrLine1: '506 NW 16TH ST',
        RegAddrCity: 'FORT WORTH',
        RegAddrState: 'TX',
        RegAddrZip: 76164,
        CongressionalDistrict: 33,
      },
      {
        RegAddrLine1: '2509 SE 14TH ST',
        RegAddrCity: 'GRAND PRAIRIE',
        RegAddrState: 'TX',
        RegAddrZip: 75051,
        CongressionalDistrict: 33,
      },
      {
        RegAddrLine1: '2102 BENJAMIN RD',
        RegAddrCity: 'IRVING',
        RegAddrState: 'TX',
        RegAddrZip: 75060,
        CongressionalDistrict: 33,
      },
      {
        RegAddrLine1: '608 CAMPANA CT',
        RegAddrCity: 'IRVING',
        RegAddrState: 'TX',
        RegAddrZip: 75061,
        CongressionalDistrict: 33,
      },
      {
        RegAddrLine1: '2654 W GRAUWYLER RD',
        RegAddrCity: 'IRVING',
        RegAddrState: 'TX',
        RegAddrZip: 75061,
        CongressionalDistrict: 33,
      },
    ],
  },
  {
    name: 'TX-34',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/TX-34?ref=gh-pages',
    state: 'TX',
    state_and_number: 'TX-34',
    zip: 77954,
    congressionalDistrict: 34,
    addressesInDistrict: [
      {
        RegAddrLine1: '1442 E VAN BUREN ST',
        RegAddrCity: 'BROWNSVILLE',
        RegAddrState: 'TX',
        RegAddrZip: 78520,
        CongressionalDistrict: 34,
      },
      {
        RegAddrLine1: '3000 MILITARY RD',
        RegAddrCity: 'BROWNSVILLE',
        RegAddrState: 'TX',
        RegAddrZip: 78520,
        CongressionalDistrict: 34,
      },
      {
        RegAddrLine1: '217 STARCREST DR',
        RegAddrCity: 'BROWNSVILLE',
        RegAddrState: 'TX',
        RegAddrZip: 78521,
        CongressionalDistrict: 34,
      },
      {
        RegAddrLine1: '35 JALISCO CT',
        RegAddrCity: 'BROWNSVILLE',
        RegAddrState: 'TX',
        RegAddrZip: 78526,
        CongressionalDistrict: 34,
      },
      {
        RegAddrLine1: '6390 ACHIEVEMENT AVE',
        RegAddrCity: 'BROWNSVILLE',
        RegAddrState: 'TX',
        RegAddrZip: 78526,
        CongressionalDistrict: 34,
      },
      {
        RegAddrLine1: '2495 COUNTY ROAD 112',
        RegAddrCity: 'COST',
        RegAddrState: 'TX',
        RegAddrZip: 78614,
        CongressionalDistrict: 34,
      },
      {
        RegAddrLine1: '7482 N US HIGHWAY 183',
        RegAddrCity: 'GOLIAD',
        RegAddrState: 'TX',
        RegAddrZip: 77963,
        CongressionalDistrict: 34,
      },
      {
        RegAddrLine1: '1322 OAK CT',
        RegAddrCity: 'HARLINGEN',
        RegAddrState: 'TX',
        RegAddrZip: 78550,
        CongressionalDistrict: 34,
      },
      {
        RegAddrLine1: '22196 HAND RD',
        RegAddrCity: 'HARLINGEN',
        RegAddrState: 'TX',
        RegAddrZip: 78552,
        CongressionalDistrict: 34,
      },
      {
        RegAddrLine1: '801 N RETAMA ST',
        RegAddrCity: 'LA FERIA',
        RegAddrState: 'TX',
        RegAddrZip: 78559,
        CongressionalDistrict: 34,
      },
      {
        RegAddrLine1: '804 AVE B',
        RegAddrCity: 'LA FERIA',
        RegAddrState: 'TX',
        RegAddrZip: 78559,
        CongressionalDistrict: 34,
      },
      {
        RegAddrLine1: '9844 COUNTY ROAD 803',
        RegAddrCity: 'MATHIS',
        RegAddrState: 'TX',
        RegAddrZip: 78368,
        CongressionalDistrict: 34,
      },
      {
        RegAddrLine1: '11755 LOPEZ RD',
        RegAddrCity: 'RAYMONDVILLE',
        RegAddrState: 'TX',
        RegAddrZip: 78580,
        CongressionalDistrict: 34,
      },
      {
        RegAddrLine1: '686 W GEM AVE',
        RegAddrCity: 'RAYMONDVILLE',
        RegAddrState: 'TX',
        RegAddrZip: 78580,
        CongressionalDistrict: 34,
      },
      {
        RegAddrLine1: '226 W CAROLYN DR',
        RegAddrCity: 'S PADRE ISLE',
        RegAddrState: 'TX',
        RegAddrZip: 78597,
        CongressionalDistrict: 34,
      },
      {
        RegAddrLine1: '21918 RABB RD',
        RegAddrCity: 'SANTA ROSA',
        RegAddrState: 'TX',
        RegAddrZip: 78593,
        CongressionalDistrict: 34,
      },
      {
        RegAddrLine1: '520 AVENUE G',
        RegAddrCity: 'SINTON',
        RegAddrState: 'TX',
        RegAddrZip: 78387,
        CongressionalDistrict: 34,
      },
    ],
  },
  {
    name: 'TX-35',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/TX-35?ref=gh-pages',
    state: 'TX',
    state_and_number: 'TX-35',
    zip: 78202,
    congressionalDistrict: 35,
    addressesInDistrict: [
      {
        RegAddrLine1: '7129 WISHING WELL DR',
        RegAddrCity: 'AUSTIN',
        RegAddrState: 'TX',
        RegAddrZip: 78745,
        CongressionalDistrict: 35,
      },
      {
        RegAddrLine1: '12115 BRADSHAW RD',
        RegAddrCity: 'AUSTIN',
        RegAddrState: 'TX',
        RegAddrZip: 78747,
        CongressionalDistrict: 35,
      },
      {
        RegAddrLine1: '13025 LIPTON LOOP',
        RegAddrCity: 'DEL VALLE',
        RegAddrState: 'TX',
        RegAddrZip: 78617,
        CongressionalDistrict: 35,
      },
      {
        RegAddrLine1: '6705 HORSESHOE POND DR',
        RegAddrCity: 'DEL VALLE',
        RegAddrState: 'TX',
        RegAddrZip: 78617,
        CongressionalDistrict: 35,
      },
      {
        RegAddrLine1: '190 DARK FORREST',
        RegAddrCity: 'KYLE',
        RegAddrState: 'TX',
        RegAddrZip: 78640,
        CongressionalDistrict: 35,
      },
      {
        RegAddrLine1: '149 TUPELO DR',
        RegAddrCity: 'KYLE',
        RegAddrState: 'TX',
        RegAddrZip: 78640,
        CongressionalDistrict: 35,
      },
      {
        RegAddrLine1: '411 HAYS ST',
        RegAddrCity: 'SAN ANTONIO',
        RegAddrState: 'TX',
        RegAddrZip: 78202,
        CongressionalDistrict: 35,
      },
      {
        RegAddrLine1: '205 LUCAS ST',
        RegAddrCity: 'SAN ANTONIO',
        RegAddrState: 'TX',
        RegAddrZip: 78209,
        CongressionalDistrict: 35,
      },
      {
        RegAddrLine1: '1121 ESSEX ST',
        RegAddrCity: 'SAN ANTONIO',
        RegAddrState: 'TX',
        RegAddrZip: 78210,
        CongressionalDistrict: 35,
      },
      {
        RegAddrLine1: '347 TOMROB DR',
        RegAddrCity: 'SAN ANTONIO',
        RegAddrState: 'TX',
        RegAddrZip: 78220,
        CongressionalDistrict: 35,
      },
      {
        RegAddrLine1: '6227 FOX CREEK ST',
        RegAddrCity: 'SAN ANTONIO',
        RegAddrState: 'TX',
        RegAddrZip: 78247,
        CongressionalDistrict: 35,
      },
      {
        RegAddrLine1: '3552 MARIETTA LN',
        RegAddrCity: 'SCHERTZ',
        RegAddrState: 'TX',
        RegAddrZip: 78154,
        CongressionalDistrict: 35,
      },
    ],
  },
  {
    name: 'TX-36',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/TX-36?ref=gh-pages',
    state: 'TX',
    state_and_number: 'TX-36',
    zip: 75928,
    congressionalDistrict: 36,
    addressesInDistrict: [
      {
        RegAddrLine1: '113 1/2 FOSTER ST',
        RegAddrCity: 'BAYTOWN',
        RegAddrState: 'TX',
        RegAddrZip: 77520,
        CongressionalDistrict: 36,
      },
      {
        RegAddrLine1: '619 RUTH ST',
        RegAddrCity: 'BAYTOWN',
        RegAddrState: 'TX',
        RegAddrZip: 77520,
        CongressionalDistrict: 36,
      },
      {
        RegAddrLine1: '2466 CORD 639',
        RegAddrCity: 'BUNA',
        RegAddrState: 'TX',
        RegAddrZip: 77612,
        CongressionalDistrict: 36,
      },
      {
        RegAddrLine1: '4906 FELSCHER LN',
        RegAddrCity: 'CROSBY',
        RegAddrState: 'TX',
        RegAddrZip: 77532,
        CongressionalDistrict: 36,
      },
      {
        RegAddrLine1: '421 REIDLAND RD',
        RegAddrCity: 'CROSBY',
        RegAddrState: 'TX',
        RegAddrZip: 77532,
        CongressionalDistrict: 36,
      },
      {
        RegAddrLine1: '6318 HIGHWAY 321',
        RegAddrCity: 'DAYTON',
        RegAddrState: 'TX',
        RegAddrZip: 77535,
        CongressionalDistrict: 36,
      },
      {
        RegAddrLine1: '413 COUNTY ROAD 4871',
        RegAddrCity: 'DAYTON',
        RegAddrState: 'TX',
        RegAddrZip: 77535,
        CongressionalDistrict: 36,
      },
      {
        RegAddrLine1: '3003 HIGH CASTLE CT',
        RegAddrCity: 'HOUSTON',
        RegAddrState: 'TX',
        RegAddrZip: 77059,
        CongressionalDistrict: 36,
      },
      {
        RegAddrLine1: '2155 HEATHER GREEN DR',
        RegAddrCity: 'HOUSTON',
        RegAddrState: 'TX',
        RegAddrZip: 77062,
        CongressionalDistrict: 36,
      },
      {
        RegAddrLine1: '4396 CORD 474',
        RegAddrCity: 'KIRBYVILLE',
        RegAddrState: 'TX',
        RegAddrZip: 75956,
        CongressionalDistrict: 36,
      },
      {
        RegAddrLine1: '10910 SPRUCE DR S',
        RegAddrCity: 'LA PORTE',
        RegAddrState: 'TX',
        RegAddrZip: 77571,
        CongressionalDistrict: 36,
      },
      {
        RegAddrLine1: '10518 SPENCER LNDG N',
        RegAddrCity: 'LA PORTE',
        RegAddrState: 'TX',
        RegAddrZip: 77571,
        CongressionalDistrict: 36,
      },
      {
        RegAddrLine1: '160 HALBERT BRYANT ST',
        RegAddrCity: 'LIVINGSTON',
        RegAddrState: 'TX',
        RegAddrZip: 77351,
        CongressionalDistrict: 36,
      },
      {
        RegAddrLine1: '12322 RIVER RIDGE ST',
        RegAddrCity: 'MONT BELVIEU',
        RegAddrState: 'TX',
        RegAddrZip: 77523,
        CongressionalDistrict: 36,
      },
      {
        RegAddrLine1: '5521 HARMON RD',
        RegAddrCity: 'ORANGE',
        RegAddrState: 'TX',
        RegAddrZip: 77630,
        CongressionalDistrict: 36,
      },
      {
        RegAddrLine1: '3202 HUDSON RD',
        RegAddrCity: 'ORANGE',
        RegAddrState: 'TX',
        RegAddrZip: 77632,
        CongressionalDistrict: 36,
      },
      {
        RegAddrLine1: '8411 MAPLE ST',
        RegAddrCity: 'ORANGE',
        RegAddrState: 'TX',
        RegAddrZip: 77632,
        CongressionalDistrict: 36,
      },
      {
        RegAddrLine1: '2301 INTERNATIONAL AVE',
        RegAddrCity: 'ORANGE',
        RegAddrState: 'TX',
        RegAddrZip: 77632,
        CongressionalDistrict: 36,
      },
      {
        RegAddrLine1: '308 NAVAJO TRL',
        RegAddrCity: 'WALLISVILLE',
        RegAddrState: 'TX',
        RegAddrZip: 77597,
        CongressionalDistrict: 36,
      },
      {
        RegAddrLine1: '379 COUNTY ROAD 4796',
        RegAddrCity: 'WARREN',
        RegAddrState: 'TX',
        RegAddrZip: 77664,
        CongressionalDistrict: 36,
      },
      {
        RegAddrLine1: '1070 27TH ST',
        RegAddrCity: 'WOODVILLE',
        RegAddrState: 'TX',
        RegAddrZip: 75979,
        CongressionalDistrict: 36,
      },
    ],
  },
  {
    name: 'TX-4',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/TX-4?ref=gh-pages',
    state: 'TX',
    state_and_number: 'TX-4',
    zip: 75020,
    congressionalDistrict: 4,
    addressesInDistrict: [
      {
        RegAddrLine1: '10 COUNTY ROAD 4128',
        RegAddrCity: 'ATLANTA',
        RegAddrState: 'TX',
        RegAddrZip: 75551,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '4971 COUNTY ROAD 2714',
        RegAddrCity: 'CADDO MILLS',
        RegAddrState: 'TX',
        RegAddrZip: 75135,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '165 COUNTY ROAD 4893',
        RegAddrCity: 'CASON',
        RegAddrState: 'TX',
        RegAddrZip: 75636,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '428 STABLEFORD ST',
        RegAddrCity: 'CELINA',
        RegAddrState: 'TX',
        RegAddrZip: 75009,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '11705 E TEXAS HIGHWAY 11',
        RegAddrCity: 'COMO',
        RegAddrState: 'TX',
        RegAddrZip: 75431,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '315 BARKER ST',
        RegAddrCity: 'CUMBY',
        RegAddrState: 'TX',
        RegAddrZip: 75433,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '829 S RUNNELS ST',
        RegAddrCity: 'DE KALB',
        RegAddrState: 'TX',
        RegAddrZip: 75559,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1324 W NELSON ST',
        RegAddrCity: 'DENISON',
        RegAddrState: 'TX',
        RegAddrZip: 75020,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '158 MAJESTIC LN',
        RegAddrCity: 'DIANA',
        RegAddrState: 'TX',
        RegAddrZip: 75640,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '154 PRIVATE ROAD 7706',
        RegAddrCity: 'EMORY',
        RegAddrState: 'TX',
        RegAddrZip: 75440,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '7050 PENGUIN RD',
        RegAddrCity: 'GILMER',
        RegAddrState: 'TX',
        RegAddrZip: 75645,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '324 PITCHFORK RD',
        RegAddrCity: 'HOWE',
        RegAddrState: 'TX',
        RegAddrZip: 75459,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1129 REED CIR',
        RegAddrCity: 'HOWE',
        RegAddrState: 'TX',
        RegAddrZip: 75459,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1782 COUNTY ROAD 2747',
        RegAddrCity: 'HUGHES SPGS',
        RegAddrState: 'TX',
        RegAddrZip: 75656,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '4120 LAKESHORE DR',
        RegAddrCity: 'LONE STAR',
        RegAddrState: 'TX',
        RegAddrZip: 75668,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '128 WAYMEADOW DR',
        RegAddrCity: 'NASH',
        RegAddrState: 'TX',
        RegAddrZip: 75569,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '365 WATTS ST',
        RegAddrCity: 'NASH',
        RegAddrState: 'TX',
        RegAddrZip: 75569,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '159 COUNTY ROAD 4130',
        RegAddrCity: 'PITTSBURG',
        RegAddrState: 'TX',
        RegAddrZip: 75686,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '641 DAINGERFIELD ST',
        RegAddrCity: 'PITTSBURG',
        RegAddrState: 'TX',
        RegAddrZip: 75686,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '605 KESSLER BLVD',
        RegAddrCity: 'SHERMAN',
        RegAddrState: 'TX',
        RegAddrZip: 75092,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1021 MULBERRY ST',
        RegAddrCity: 'SULPHUR SPGS',
        RegAddrState: 'TX',
        RegAddrZip: 75482,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '380 COUNTY ROAD 1224',
        RegAddrCity: 'TEXARKANA',
        RegAddrState: 'TX',
        RegAddrZip: 75501,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '7106 GUNSTOCK',
        RegAddrCity: 'TEXARKANA',
        RegAddrState: 'TX',
        RegAddrZip: 75503,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '109 MAIN ST',
        RegAddrCity: 'WESTON',
        RegAddrState: 'TX',
        RegAddrZip: 75097,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2406 JOLINDA LN',
        RegAddrCity: 'WHITESBORO',
        RegAddrState: 'TX',
        RegAddrZip: 76273,
        CongressionalDistrict: 4,
      },
    ],
  },
  {
    name: 'TX-5',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/TX-5?ref=gh-pages',
    state: 'TX',
    state_and_number: 'TX-5',
    zip: 75041,
    congressionalDistrict: 5,
    addressesInDistrict: [
      {
        RegAddrLine1: '4004 LINDA LN',
        RegAddrCity: 'BALCH SPRINGS',
        RegAddrState: 'TX',
        RegAddrZip: 75180,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '7027 GASTON PKWY',
        RegAddrCity: 'DALLAS',
        RegAddrState: 'TX',
        RegAddrZip: 75214,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '1422 VERANO DR',
        RegAddrCity: 'DALLAS',
        RegAddrState: 'TX',
        RegAddrZip: 75218,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '2419 SAN MARCUS AVE',
        RegAddrCity: 'DALLAS',
        RegAddrState: 'TX',
        RegAddrZip: 75228,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '10823 CASTOLON DR',
        RegAddrCity: 'DALLAS',
        RegAddrState: 'TX',
        RegAddrZip: 75228,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '301 BAYBERRY TRL',
        RegAddrCity: 'FORNEY',
        RegAddrState: 'TX',
        RegAddrZip: 75126,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '181 ANTLER TRL',
        RegAddrCity: 'FORNEY',
        RegAddrState: 'TX',
        RegAddrZip: 75126,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '2000 PIN OAK DR',
        RegAddrCity: 'KAUFMAN',
        RegAddrState: 'TX',
        RegAddrZip: 75142,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '8430 COUNTY ROAD 4131',
        RegAddrCity: 'KEMP',
        RegAddrState: 'TX',
        RegAddrZip: 75143,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '10722 FM 90',
        RegAddrCity: 'MABANK',
        RegAddrState: 'TX',
        RegAddrZip: 75147,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '928 S SAM HOUSTON RD',
        RegAddrCity: 'MESQUITE',
        RegAddrState: 'TX',
        RegAddrZip: 75149,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '2005 MOUNT PLEASANT DR',
        RegAddrCity: 'MESQUITE',
        RegAddrState: 'TX',
        RegAddrZip: 75149,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '3909 MORGAN DR',
        RegAddrCity: 'MESQUITE',
        RegAddrState: 'TX',
        RegAddrZip: 75150,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '2008 LA PRADA PKWY',
        RegAddrCity: 'MESQUITE',
        RegAddrState: 'TX',
        RegAddrZip: 75150,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '707 SPRING MILLS RD',
        RegAddrCity: 'MESQUITE',
        RegAddrState: 'TX',
        RegAddrZip: 75181,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '2705 STILLWATER DR',
        RegAddrCity: 'MESQUITE',
        RegAddrState: 'TX',
        RegAddrZip: 75181,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '1217 S FRANCES ST',
        RegAddrCity: 'TERRELL',
        RegAddrState: 'TX',
        RegAddrZip: 75160,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '230 MATADOR RANCH RD',
        RegAddrCity: 'TRINIDAD',
        RegAddrState: 'TX',
        RegAddrZip: 75163,
        CongressionalDistrict: 5,
      },
    ],
  },
  {
    name: 'TX-6',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/TX-6?ref=gh-pages',
    state: 'TX',
    state_and_number: 'TX-6',
    zip: 75050,
    congressionalDistrict: 6,
    addressesInDistrict: [
      {
        RegAddrLine1: '2715 SHADOW WOOD DR',
        RegAddrCity: 'ARLINGTON',
        RegAddrState: 'TX',
        RegAddrZip: 76006,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '7111 BLACKBERRY DR',
        RegAddrCity: 'ARLINGTON',
        RegAddrState: 'TX',
        RegAddrZip: 76016,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '3514 OAK BEND DR',
        RegAddrCity: 'ARLINGTON',
        RegAddrState: 'TX',
        RegAddrZip: 76016,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '201 MOSS HILL DR',
        RegAddrCity: 'ARLINGTON',
        RegAddrState: 'TX',
        RegAddrZip: 76018,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '1414 SEELY ST',
        RegAddrCity: 'ARLINGTON',
        RegAddrState: 'TX',
        RegAddrZip: 76018,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '1905 ROSELLE CT',
        RegAddrCity: 'ARLINGTON',
        RegAddrState: 'TX',
        RegAddrZip: 76018,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '1720 W PARK AVE',
        RegAddrCity: 'CORSICANA',
        RegAddrState: 'TX',
        RegAddrZip: 75110,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '400 HAVNER ST',
        RegAddrCity: 'CORSICANA',
        RegAddrState: 'TX',
        RegAddrZip: 75110,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '204 S HEIGHTS DR',
        RegAddrCity: 'CROWLEY',
        RegAddrState: 'TX',
        RegAddrZip: 76036,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '921 UNION HILL RD',
        RegAddrCity: 'ENNIS',
        RegAddrState: 'TX',
        RegAddrZip: 75119,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '1006 EDGEWOOD ST',
        RegAddrCity: 'ENNIS',
        RegAddrState: 'TX',
        RegAddrZip: 75119,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '4054 HUNTERS CREEK DR',
        RegAddrCity: 'FORT WORTH',
        RegAddrState: 'TX',
        RegAddrZip: 76123,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '171 COTILLION RD',
        RegAddrCity: 'FORT WORTH',
        RegAddrState: 'TX',
        RegAddrZip: 76134,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '8512 WHISPERING CREEK TRL',
        RegAddrCity: 'FORT WORTH',
        RegAddrState: 'TX',
        RegAddrZip: 76134,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '1806 SAN RAFAEL ST',
        RegAddrCity: 'FORT WORTH',
        RegAddrState: 'TX',
        RegAddrZip: 76134,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '2770 BARDIN RD',
        RegAddrCity: 'GRAND PRAIRIE',
        RegAddrState: 'TX',
        RegAddrZip: 75052,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '7059 MIRAMAR',
        RegAddrCity: 'GRAND PRAIRIE',
        RegAddrState: 'TX',
        RegAddrZip: 75054,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '3313 EDITH LN',
        RegAddrCity: 'HALTOM CITY',
        RegAddrState: 'TX',
        RegAddrZip: 76117,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '920 GREENFIELD CT',
        RegAddrCity: 'KENNEDALE',
        RegAddrState: 'TX',
        RegAddrZip: 76060,
        CongressionalDistrict: 6,
      },
    ],
  },
  {
    name: 'TX-7',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/TX-7?ref=gh-pages',
    state: 'TX',
    state_and_number: 'TX-7',
    zip: 77027,
    congressionalDistrict: 7,
    addressesInDistrict: [
      {
        RegAddrLine1: '5428 COMMUNITY DR',
        RegAddrCity: 'HOUSTON',
        RegAddrState: 'TX',
        RegAddrZip: 77005,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '3127 MID LN',
        RegAddrCity: 'HOUSTON',
        RegAddrState: 'TX',
        RegAddrZip: 77027,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '1302 BEUTEL DR',
        RegAddrCity: 'HOUSTON',
        RegAddrState: 'TX',
        RegAddrZip: 77055,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '6328 ELLA LEE LN',
        RegAddrCity: 'HOUSTON',
        RegAddrState: 'TX',
        RegAddrZip: 77057,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '1014 STONEY HILL DR',
        RegAddrCity: 'HOUSTON',
        RegAddrState: 'TX',
        RegAddrZip: 77077,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '12745 SANDRI LN',
        RegAddrCity: 'HOUSTON',
        RegAddrState: 'TX',
        RegAddrZip: 77077,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '2023 SHADYBRIAR DR',
        RegAddrCity: 'HOUSTON',
        RegAddrState: 'TX',
        RegAddrZip: 77077,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '8343 MISTY TRAIL DR',
        RegAddrCity: 'HOUSTON',
        RegAddrState: 'TX',
        RegAddrZip: 77095,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '7243 SKYBRIGHT LN',
        RegAddrCity: 'HOUSTON',
        RegAddrState: 'TX',
        RegAddrZip: 77095,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '10014 BARR LAKE DR',
        RegAddrCity: 'HOUSTON',
        RegAddrState: 'TX',
        RegAddrZip: 77095,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '5602 BRIARBEND DR',
        RegAddrCity: 'HOUSTON',
        RegAddrState: 'TX',
        RegAddrZip: 77096,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '21475 BISCAYNE VALLEY LN',
        RegAddrCity: 'KATY',
        RegAddrState: 'TX',
        RegAddrZip: 77449,
        CongressionalDistrict: 7,
      },
    ],
  },
  {
    name: 'TX-8',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/TX-8?ref=gh-pages',
    state: 'TX',
    state_and_number: 'TX-8',
    zip: 75831,
    congressionalDistrict: 8,
    addressesInDistrict: [
      {
        RegAddrLine1: '11764 HOLLY DR',
        RegAddrCity: 'CONROE',
        RegAddrState: 'TX',
        RegAddrZip: 77303,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '2468 RIPPLEWOOD CT',
        RegAddrCity: 'CONROE',
        RegAddrState: 'TX',
        RegAddrZip: 77384,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '1725 FARM MARKET 2076',
        RegAddrCity: 'CROCKETT',
        RegAddrState: 'TX',
        RegAddrZip: 75835,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '213 MIZE ST',
        RegAddrCity: 'HUNTSVILLE',
        RegAddrState: 'TX',
        RegAddrZip: 77320,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '224 OBANNON RANCH RD',
        RegAddrCity: 'HUNTSVILLE',
        RegAddrState: 'TX',
        RegAddrZip: 77320,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '2609 MONTGOMERY RD',
        RegAddrCity: 'HUNTSVILLE',
        RegAddrState: 'TX',
        RegAddrZip: 77340,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '20630 LAZY CREEK DR',
        RegAddrCity: 'NEW CANEY',
        RegAddrState: 'TX',
        RegAddrZip: 77357,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '11 PINE ST',
        RegAddrCity: 'NEW WAVERLY',
        RegAddrState: 'TX',
        RegAddrZip: 77358,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '20133 BITTER ROOT DR',
        RegAddrCity: 'PORTER',
        RegAddrState: 'TX',
        RegAddrZip: 77365,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '13727 MORGAN DR',
        RegAddrCity: 'SPLENDORA',
        RegAddrState: 'TX',
        RegAddrZip: 77372,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '2555 MAGNOLIA FAIR WAY',
        RegAddrCity: 'SPRING',
        RegAddrState: 'TX',
        RegAddrZip: 77386,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '35 AVALON OAKS CT',
        RegAddrCity: 'THE WOODLANDS',
        RegAddrState: 'TX',
        RegAddrZip: 77381,
        CongressionalDistrict: 8,
      },
    ],
  },
  {
    name: 'TX-9',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/TX-9?ref=gh-pages',
    state: 'TX',
    state_and_number: 'TX-9',
    zip: 77031,
    congressionalDistrict: 9,
    addressesInDistrict: [
      {
        RegAddrLine1: '1603 WHEELER ST',
        RegAddrCity: 'HOUSTON',
        RegAddrState: 'TX',
        RegAddrZip: 77004,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '5623 BELMARK ST',
        RegAddrCity: 'HOUSTON',
        RegAddrState: 'TX',
        RegAddrZip: 77033,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '3922 EBBTIDE DR',
        RegAddrCity: 'HOUSTON',
        RegAddrState: 'TX',
        RegAddrZip: 77045,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '14010 CONCORD MEADOW LN',
        RegAddrCity: 'HOUSTON',
        RegAddrState: 'TX',
        RegAddrZip: 77047,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '7905 GLADSTONE ST',
        RegAddrCity: 'HOUSTON',
        RegAddrState: 'TX',
        RegAddrZip: 77051,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '13703 ARCOLA RIDGE CT',
        RegAddrCity: 'HOUSTON',
        RegAddrState: 'TX',
        RegAddrZip: 77083,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '8622 HUMPHREYS DR',
        RegAddrCity: 'HOUSTON',
        RegAddrState: 'TX',
        RegAddrZip: 77083,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '14802 EL GRANDE DR',
        RegAddrCity: 'HOUSTON',
        RegAddrState: 'TX',
        RegAddrZip: 77083,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '11802 GLENWOLDE DR',
        RegAddrCity: 'HOUSTON',
        RegAddrState: 'TX',
        RegAddrZip: 77099,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '3231 TRUESDALE DR',
        RegAddrCity: 'MISSOURI CITY',
        RegAddrState: 'TX',
        RegAddrZip: 77459,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '1335 NEW TREE LN',
        RegAddrCity: 'MISSOURI CITY',
        RegAddrState: 'TX',
        RegAddrZip: 77489,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '14815 WAVERTON CT',
        RegAddrCity: 'SUGAR LAND',
        RegAddrState: 'TX',
        RegAddrZip: 77498,
        CongressionalDistrict: 9,
      },
    ],
  },
  {
    name: 'UT-1',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/UT-1?ref=gh-pages',
    state: 'UT',
    state_and_number: 'UT-1',
    zip: 82930,
    congressionalDistrict: 1,
    addressesInDistrict: [
      {
        RegAddrLine1: '1515 N 3500 E',
        RegAddrCity: 'BALLARD',
        RegAddrState: 'UT',
        RegAddrZip: 84066,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1020 FISHBURN DR',
        RegAddrCity: 'BRIGHAM CITY',
        RegAddrState: 'UT',
        RegAddrZip: 84302,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '652 S 300 E',
        RegAddrCity: 'BRIGHAM CITY',
        RegAddrState: 'UT',
        RegAddrZip: 84302,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '53 S 200 W',
        RegAddrCity: 'BRIGHAM CITY',
        RegAddrState: 'UT',
        RegAddrZip: 84302,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '459 W 2400 S',
        RegAddrCity: 'CLEARFIELD',
        RegAddrState: 'UT',
        RegAddrZip: 84015,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2179 W 2475 N',
        RegAddrCity: 'CLEARFIELD',
        RegAddrState: 'UT',
        RegAddrZip: 84015,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1629 W 1800 N',
        RegAddrCity: 'CLINTON',
        RegAddrState: 'UT',
        RegAddrZip: 84015,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1938 N 1200 W',
        RegAddrCity: 'CLINTON',
        RegAddrState: 'UT',
        RegAddrZip: 84015,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '9500 W 10495TH S',
        RegAddrCity: 'DUCHESNE',
        RegAddrState: 'UT',
        RegAddrZip: 84021,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '9000 S 10255TH W',
        RegAddrCity: 'DUCHESNE',
        RegAddrState: 'UT',
        RegAddrZip: 84021,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1329 W 1100 N',
        RegAddrCity: 'FARR WEST',
        RegAddrState: 'UT',
        RegAddrZip: 84404,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '7945 E 200TH N',
        RegAddrCity: 'FORT DUCHESNE',
        RegAddrState: 'UT',
        RegAddrZip: 84026,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2360 SPRING HOLLOW RD',
        RegAddrCity: 'FRANCIS',
        RegAddrState: 'UT',
        RegAddrZip: 84036,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '175 WILLOW LN',
        RegAddrCity: 'GARLAND',
        RegAddrState: 'UT',
        RegAddrZip: 84312,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '5672 W 5100 S',
        RegAddrCity: 'HOOPER',
        RegAddrState: 'UT',
        RegAddrZip: 84315,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '6205 W 4700 S',
        RegAddrCity: 'HOOPER',
        RegAddrState: 'UT',
        RegAddrZip: 84315,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '799 E 300 N',
        RegAddrCity: 'HYDE PARK',
        RegAddrState: 'UT',
        RegAddrZip: 84318,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '6540 S 1800 W',
        RegAddrCity: 'HYRUM',
        RegAddrState: 'UT',
        RegAddrZip: 84319,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '153 E 500 S',
        RegAddrCity: 'HYRUM',
        RegAddrState: 'UT',
        RegAddrZip: 84319,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '9655 E HIGHWAY 40',
        RegAddrCity: 'JENSEN',
        RegAddrState: 'UT',
        RegAddrZip: 84035,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '289 E BOYNTON RD',
        RegAddrCity: 'KAYSVILLE',
        RegAddrState: 'UT',
        RegAddrZip: 84037,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '731 E 600 S',
        RegAddrCity: 'KAYSVILLE',
        RegAddrState: 'UT',
        RegAddrZip: 84037,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '880 E BROOKSHIRE DR',
        RegAddrCity: 'KAYSVILLE',
        RegAddrState: 'UT',
        RegAddrZip: 84037,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '202 E SHEPARD LN',
        RegAddrCity: 'KAYSVILLE',
        RegAddrState: 'UT',
        RegAddrZip: 84037,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1144 N ALFRED AVE',
        RegAddrCity: 'KAYSVILLE',
        RegAddrState: 'UT',
        RegAddrZip: 84037,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1382 VALLEY VIEW DR',
        RegAddrCity: 'LAYTON',
        RegAddrState: 'UT',
        RegAddrZip: 84040,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '944 WOODRIDGE DR',
        RegAddrCity: 'LAYTON',
        RegAddrState: 'UT',
        RegAddrZip: 84040,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2782 BRINTON WAY',
        RegAddrCity: 'LAYTON',
        RegAddrState: 'UT',
        RegAddrZip: 84040,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1535 N 2800 E',
        RegAddrCity: 'LAYTON',
        RegAddrState: 'UT',
        RegAddrZip: 84040,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1314 NALDER CIR',
        RegAddrCity: 'LAYTON',
        RegAddrState: 'UT',
        RegAddrZip: 84040,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '552 N ALLANWOOD PL',
        RegAddrCity: 'LAYTON',
        RegAddrState: 'UT',
        RegAddrZip: 84040,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '585 S 850 E',
        RegAddrCity: 'LAYTON',
        RegAddrState: 'UT',
        RegAddrZip: 84041,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2275 W 525 S',
        RegAddrCity: 'LAYTON',
        RegAddrState: 'UT',
        RegAddrZip: 84041,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '930 WEAVER LN',
        RegAddrCity: 'LAYTON',
        RegAddrState: 'UT',
        RegAddrZip: 84041,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1089 W 2325 N',
        RegAddrCity: 'LAYTON',
        RegAddrState: 'UT',
        RegAddrZip: 84041,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '109 N 700 W',
        RegAddrCity: 'LAYTON',
        RegAddrState: 'UT',
        RegAddrZip: 84041,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '101 MERRILL HALL',
        RegAddrCity: 'LOGAN',
        RegAddrState: 'UT',
        RegAddrZip: 84321,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '203 MERRILL HALL',
        RegAddrCity: 'LOGAN',
        RegAddrState: 'UT',
        RegAddrZip: 84321,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '67 S 1400 E',
        RegAddrCity: 'LOGAN',
        RegAddrState: 'UT',
        RegAddrZip: 84321,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '263 E 300 S',
        RegAddrCity: 'LOGAN',
        RegAddrState: 'UT',
        RegAddrZip: 84321,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '236 W 1330 N',
        RegAddrCity: 'LOGAN',
        RegAddrState: 'UT',
        RegAddrZip: 84341,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '293 E CENTER ST',
        RegAddrCity: 'MILLVILLE',
        RegAddrState: 'UT',
        RegAddrZip: 84326,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '3795 W 3900 N',
        RegAddrCity: 'MORGAN',
        RegAddrState: 'UT',
        RegAddrZip: 84050,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '255 HOUND ST',
        RegAddrCity: 'MORGAN',
        RegAddrState: 'UT',
        RegAddrZip: 84050,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '7169 N 5500 W',
        RegAddrCity: 'NEWTON',
        RegAddrState: 'UT',
        RegAddrZip: 84327,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '889 E 2900 N',
        RegAddrCity: 'NORTH LOGAN',
        RegAddrState: 'UT',
        RegAddrZip: 84341,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '3507 N 400 E',
        RegAddrCity: 'NORTH OGDEN',
        RegAddrState: 'UT',
        RegAddrZip: 84414,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2365 N 1225 E',
        RegAddrCity: 'NORTH OGDEN',
        RegAddrState: 'UT',
        RegAddrZip: 84414,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2211 MONROE BLVD',
        RegAddrCity: 'OGDEN',
        RegAddrState: 'UT',
        RegAddrZip: 84401,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2068 CUSTER AVE',
        RegAddrCity: 'OGDEN',
        RegAddrState: 'UT',
        RegAddrZip: 84401,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1025 24TH ST',
        RegAddrCity: 'OGDEN',
        RegAddrState: 'UT',
        RegAddrZip: 84401,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1718 25TH ST',
        RegAddrCity: 'OGDEN',
        RegAddrState: 'UT',
        RegAddrZip: 84401,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1144 25TH ST',
        RegAddrCity: 'OGDEN',
        RegAddrState: 'UT',
        RegAddrZip: 84401,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '5145 IROQUOI WAY',
        RegAddrCity: 'OGDEN',
        RegAddrState: 'UT',
        RegAddrZip: 84403,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2840 BUCHANAN AVE',
        RegAddrCity: 'OGDEN',
        RegAddrState: 'UT',
        RegAddrZip: 84403,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '986 MAPLE ST',
        RegAddrCity: 'OGDEN',
        RegAddrState: 'UT',
        RegAddrZip: 84403,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1311 5TH ST',
        RegAddrCity: 'OGDEN',
        RegAddrState: 'UT',
        RegAddrZip: 84404,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1684 13TH ST',
        RegAddrCity: 'OGDEN',
        RegAddrState: 'UT',
        RegAddrZip: 84404,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '596 N PORTER AVE',
        RegAddrCity: 'OGDEN',
        RegAddrState: 'UT',
        RegAddrZip: 84404,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '461 E 475 N',
        RegAddrCity: 'OGDEN',
        RegAddrState: 'UT',
        RegAddrZip: 84404,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1617 E 1250 S',
        RegAddrCity: 'OGDEN',
        RegAddrState: 'UT',
        RegAddrZip: 84404,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '5555 W 300 N',
        RegAddrCity: 'OGDEN',
        RegAddrState: 'UT',
        RegAddrZip: 84404,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '7901 S HIGHWAY 165',
        RegAddrCity: 'PARADISE',
        RegAddrState: 'UT',
        RegAddrZip: 84328,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '253 ONTARIO AVE',
        RegAddrCity: 'PARK CITY',
        RegAddrState: 'UT',
        RegAddrZip: 84060,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '7721 SUSANS CIR',
        RegAddrCity: 'PARK CITY',
        RegAddrState: 'UT',
        RegAddrZip: 84098,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '785 MOUNTAIN HOLLY RD',
        RegAddrCity: 'PARK CITY',
        RegAddrState: 'UT',
        RegAddrZip: 84098,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '6144 OLD RANCH RD',
        RegAddrCity: 'PARK CITY',
        RegAddrState: 'UT',
        RegAddrZip: 84098,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2515 PEACH ST',
        RegAddrCity: 'PERRY',
        RegAddrState: 'UT',
        RegAddrZip: 84302,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1638 N 4650 W',
        RegAddrCity: 'PLAIN CITY',
        RegAddrState: 'UT',
        RegAddrZip: 84404,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '35 E 100 S',
        RegAddrCity: 'PROVIDENCE',
        RegAddrState: 'UT',
        RegAddrZip: 84332,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '748 W 4375 S',
        RegAddrCity: 'RIVERDALE',
        RegAddrState: 'UT',
        RegAddrZip: 84405,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '12712 S BERGEN CIR',
        RegAddrCity: 'RIVERTON',
        RegAddrState: 'UT',
        RegAddrZip: 84065,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '4202 W 6000 S',
        RegAddrCity: 'ROY',
        RegAddrState: 'UT',
        RegAddrZip: 84067,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '5110 MIDLAND DR',
        RegAddrCity: 'ROY',
        RegAddrState: 'UT',
        RegAddrZip: 84067,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '4236 S 2900 W',
        RegAddrCity: 'ROY',
        RegAddrState: 'UT',
        RegAddrZip: 84067,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '4219 W 5400 S',
        RegAddrCity: 'ROY',
        RegAddrState: 'UT',
        RegAddrZip: 84067,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '296 W 200 S',
        RegAddrCity: 'SMITHFIELD',
        RegAddrState: 'UT',
        RegAddrZip: 84335,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1029 E 5285 S',
        RegAddrCity: 'SOUTH OGDEN',
        RegAddrState: 'UT',
        RegAddrZip: 84403,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1934 WILLOW WOOD LN',
        RegAddrCity: 'SOUTH OGDEN',
        RegAddrState: 'UT',
        RegAddrZip: 84403,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '5756 WILLOW WOOD LN',
        RegAddrCity: 'SOUTH OGDEN',
        RegAddrState: 'UT',
        RegAddrZip: 84403,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2408 S 2050 W',
        RegAddrCity: 'SYRACUSE',
        RegAddrState: 'UT',
        RegAddrZip: 84075,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '4337 W 1315 S',
        RegAddrCity: 'SYRACUSE',
        RegAddrState: 'UT',
        RegAddrZip: 84075,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1886 S 1485 W',
        RegAddrCity: 'SYRACUSE',
        RegAddrState: 'UT',
        RegAddrZip: 84075,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2358 S 3300 W',
        RegAddrCity: 'SYRACUSE',
        RegAddrState: 'UT',
        RegAddrZip: 84075,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '831 S 560 W',
        RegAddrCity: 'TREMONTON',
        RegAddrState: 'UT',
        RegAddrZip: 84337,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1203 E MAIN ST',
        RegAddrCity: 'TREMONTON',
        RegAddrState: 'UT',
        RegAddrZip: 84337,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '870 S 400 E',
        RegAddrCity: 'TRENTON',
        RegAddrState: 'UT',
        RegAddrZip: 84338,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2599 S 500 W',
        RegAddrCity: 'VERNAL',
        RegAddrState: 'UT',
        RegAddrZip: 84078,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1735 S 1500 W',
        RegAddrCity: 'VERNAL',
        RegAddrState: 'UT',
        RegAddrZip: 84078,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '3400 S 1500 E',
        RegAddrCity: 'VERNAL',
        RegAddrState: 'UT',
        RegAddrZip: 84078,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '548 W 5000 S',
        RegAddrCity: 'WASHINGTON TERRACE',
        RegAddrState: 'UT',
        RegAddrZip: 84405,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '4415 W 625 S',
        RegAddrCity: 'WEST POINT',
        RegAddrState: 'UT',
        RegAddrZip: 84015,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '705 N 4100 W',
        RegAddrCity: 'WEST POINT',
        RegAddrState: 'UT',
        RegAddrZip: 84015,
        CongressionalDistrict: 1,
      },
    ],
  },
  {
    name: 'UT-2',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/UT-2?ref=gh-pages',
    state: 'UT',
    state_and_number: 'UT-2',
    zip: 84010,
    congressionalDistrict: 2,
    addressesInDistrict: [
      {
        RegAddrLine1: '645 E 200 N',
        RegAddrCity: 'BEAVER',
        RegAddrState: 'UT',
        RegAddrZip: 84713,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2230 N 5000 W',
        RegAddrCity: 'BERYL',
        RegAddrState: 'UT',
        RegAddrZip: 84714,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2871 S 300 W',
        RegAddrCity: 'BOUNTIFUL',
        RegAddrState: 'UT',
        RegAddrZip: 84010,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '3337 S 475 W',
        RegAddrCity: 'BOUNTIFUL',
        RegAddrState: 'UT',
        RegAddrZip: 84010,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2028 RIDGEHILL DR',
        RegAddrCity: 'BOUNTIFUL',
        RegAddrState: 'UT',
        RegAddrZip: 84010,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '3717 MONARCH DR',
        RegAddrCity: 'BOUNTIFUL',
        RegAddrState: 'UT',
        RegAddrZip: 84010,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '72 W 2550 S',
        RegAddrCity: 'BOUNTIFUL',
        RegAddrState: 'UT',
        RegAddrZip: 84010,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '94 N 300 W',
        RegAddrCity: 'CEDAR CITY',
        RegAddrState: 'UT',
        RegAddrZip: 84720,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2041 W 460 S',
        RegAddrCity: 'CEDAR CITY',
        RegAddrState: 'UT',
        RegAddrZip: 84720,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1823 N 3450 W',
        RegAddrCity: 'CEDAR CITY',
        RegAddrState: 'UT',
        RegAddrZip: 84721,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '509 APPLEWOOD DR',
        RegAddrCity: 'CENTERVILLE',
        RegAddrState: 'UT',
        RegAddrZip: 84014,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '495 W 1500 N',
        RegAddrCity: 'CENTERVILLE',
        RegAddrState: 'UT',
        RegAddrZip: 84014,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '281 W PHEASANTBROOK DR',
        RegAddrCity: 'CENTERVILLE',
        RegAddrState: 'UT',
        RegAddrZip: 84014,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '4468 N PIONEER DR',
        RegAddrCity: 'ENOCH',
        RegAddrState: 'UT',
        RegAddrZip: 84721,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '350 S 50TH W',
        RegAddrCity: 'EPHRAIM',
        RegAddrState: 'UT',
        RegAddrZip: 84627,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '4908 HEIDI WAY',
        RegAddrCity: 'ERDA',
        RegAddrState: 'UT',
        RegAddrZip: 84074,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '635 W PAINT HORSE CV',
        RegAddrCity: 'ERDA',
        RegAddrState: 'UT',
        RegAddrZip: 84074,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1993 W RANCH RD',
        RegAddrCity: 'FARMINGTON',
        RegAddrState: 'UT',
        RegAddrZip: 84025,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '4320 N 3200 W',
        RegAddrCity: 'FILLMORE',
        RegAddrState: 'UT',
        RegAddrZip: 84631,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '223 E BOX ELDER DR',
        RegAddrCity: 'GRANTSVILLE',
        RegAddrState: 'UT',
        RegAddrZip: 84029,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '373 WRANGLER WAY',
        RegAddrCity: 'GRANTSVILLE',
        RegAddrState: 'UT',
        RegAddrZip: 84029,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '586 S 920 W',
        RegAddrCity: 'HURRICANE',
        RegAddrState: 'UT',
        RegAddrZip: 84737,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '36 N 2770 W',
        RegAddrCity: 'HURRICANE',
        RegAddrState: 'UT',
        RegAddrZip: 84737,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '239 S MAIN ST',
        RegAddrCity: 'HURRICANE',
        RegAddrState: 'UT',
        RegAddrZip: 84737,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1017 N 2500 W',
        RegAddrCity: 'HURRICANE',
        RegAddrState: 'UT',
        RegAddrZip: 84737,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '121 E 755 S',
        RegAddrCity: 'IVINS',
        RegAddrState: 'UT',
        RegAddrZip: 84738,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '46 N 150 W',
        RegAddrCity: 'IVINS',
        RegAddrState: 'UT',
        RegAddrZip: 84738,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '3170 S VIVIAN ST',
        RegAddrCity: 'MAGNA',
        RegAddrState: 'UT',
        RegAddrZip: 84044,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '3134 S 7200 W',
        RegAddrCity: 'MAGNA',
        RegAddrState: 'UT',
        RegAddrZip: 84044,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '3066 S BREEZE DR',
        RegAddrCity: 'MAGNA',
        RegAddrState: 'UT',
        RegAddrZip: 84044,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '191 S 90 W',
        RegAddrCity: 'MANDERFIELD',
        RegAddrState: 'UT',
        RegAddrZip: 84713,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '415 E UNION ST',
        RegAddrCity: 'MANTI',
        RegAddrState: 'UT',
        RegAddrZip: 84642,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '45 N 400 W',
        RegAddrCity: 'MANTI',
        RegAddrState: 'UT',
        RegAddrZip: 84642,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '370 S 200 E',
        RegAddrCity: 'MONROE',
        RegAddrState: 'UT',
        RegAddrZip: 84754,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2221 N WASHBURNVILLE RD',
        RegAddrCity: 'MONROE',
        RegAddrState: 'UT',
        RegAddrZip: 84754,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '815 DAVID WAY',
        RegAddrCity: 'N SALT LAKE',
        RegAddrState: 'UT',
        RegAddrZip: 84054,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1260 W 300 N',
        RegAddrCity: 'SAINT GEORGE',
        RegAddrState: 'UT',
        RegAddrZip: 84770,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '315 W 670 NORTH CIR',
        RegAddrCity: 'SAINT GEORGE',
        RegAddrState: 'UT',
        RegAddrZip: 84770,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1629 S 980 EAST CIR',
        RegAddrCity: 'SAINT GEORGE',
        RegAddrState: 'UT',
        RegAddrZip: 84790,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2673 E 400 N',
        RegAddrCity: 'SAINT GEORGE',
        RegAddrState: 'UT',
        RegAddrZip: 84790,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '4893 MORANE MANOR DR',
        RegAddrCity: 'SAINT GEORGE',
        RegAddrState: 'UT',
        RegAddrZip: 84790,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '197 N 2750 E',
        RegAddrCity: 'SAINT GEORGE',
        RegAddrState: 'UT',
        RegAddrZip: 84790,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1974 HEDERA PL',
        RegAddrCity: 'SAINT GEORGE',
        RegAddrState: 'UT',
        RegAddrZip: 84790,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '915 S 1500 E',
        RegAddrCity: 'SALT LAKE CITY',
        RegAddrState: 'UT',
        RegAddrZip: 84105,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1664 E ROOSEVELT AVE',
        RegAddrCity: 'SALT LAKE CITY',
        RegAddrState: 'UT',
        RegAddrZip: 84105,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1083 S 400 E',
        RegAddrCity: 'SALT LAKE CITY',
        RegAddrState: 'UT',
        RegAddrZip: 84111,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '451 E BISHOP FEDERAL LN',
        RegAddrCity: 'SALT LAKE CITY',
        RegAddrState: 'UT',
        RegAddrZip: 84115,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '86 B ST',
        RegAddrCity: 'SALT LAKE CTY',
        RegAddrState: 'UT',
        RegAddrZip: 84103,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '125 M ST',
        RegAddrCity: 'SALT LAKE CTY',
        RegAddrState: 'UT',
        RegAddrZip: 84103,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '924 W MONTAGUE AVE',
        RegAddrCity: 'SALT LAKE CTY',
        RegAddrState: 'UT',
        RegAddrZip: 84104,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1895 S 1000 E',
        RegAddrCity: 'SALT LAKE CTY',
        RegAddrState: 'UT',
        RegAddrZip: 84105,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1156 E BRYAN AVE',
        RegAddrCity: 'SALT LAKE CTY',
        RegAddrState: 'UT',
        RegAddrZip: 84105,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2399 E SAINT MARYS DR',
        RegAddrCity: 'SALT LAKE CTY',
        RegAddrState: 'UT',
        RegAddrZip: 84108,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2449 HERITAGE CTR',
        RegAddrCity: 'SALT LAKE CTY',
        RegAddrState: 'UT',
        RegAddrZip: 84112,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '102 CONNOR ST',
        RegAddrCity: 'SALT LAKE CTY',
        RegAddrState: 'UT',
        RegAddrZip: 84112,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1556 W ROSE PARK CIR',
        RegAddrCity: 'SALT LAKE CTY',
        RegAddrState: 'UT',
        RegAddrZip: 84116,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '913 W 900 N',
        RegAddrCity: 'SALT LAKE CTY',
        RegAddrState: 'UT',
        RegAddrZip: 84116,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '773 COUNTRY CLB',
        RegAddrCity: 'STANSBURY PK',
        RegAddrState: 'UT',
        RegAddrZip: 84074,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '24 S 6TH ST',
        RegAddrCity: 'TOOELE',
        RegAddrState: 'UT',
        RegAddrZip: 84074,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '615 N BROADWAY ST',
        RegAddrCity: 'TOOELE',
        RegAddrState: 'UT',
        RegAddrZip: 84074,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '936 W 500 S',
        RegAddrCity: 'TOOELE',
        RegAddrState: 'UT',
        RegAddrZip: 84074,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '698 N BROOK AVE',
        RegAddrCity: 'TOOELE',
        RegAddrState: 'UT',
        RegAddrZip: 84074,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '862 LEFT HAND FORK DR',
        RegAddrCity: 'TOOELE',
        RegAddrState: 'UT',
        RegAddrZip: 84074,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '261 W 100 S',
        RegAddrCity: 'TROPIC',
        RegAddrState: 'UT',
        RegAddrZip: 84776,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '6140 W 4180 S',
        RegAddrCity: 'W VALLEY CITY',
        RegAddrState: 'UT',
        RegAddrZip: 84128,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '6234 W 3705 S',
        RegAddrCity: 'W VALLEY CITY',
        RegAddrState: 'UT',
        RegAddrZip: 84128,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2671 E SPRING CANYON DR',
        RegAddrCity: 'WASHINGTON',
        RegAddrState: 'UT',
        RegAddrZip: 84780,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '3561 S MARICOPA CIR',
        RegAddrCity: 'WEST VALLEY CITY',
        RegAddrState: 'UT',
        RegAddrZip: 84120,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1220 W 1500 S',
        RegAddrCity: 'WOODS CROSS',
        RegAddrState: 'UT',
        RegAddrZip: 84087,
        CongressionalDistrict: 2,
      },
    ],
  },
  {
    name: 'UT-3',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/UT-3?ref=gh-pages',
    state: 'UT',
    state_and_number: 'UT-3',
    zip: 84003,
    congressionalDistrict: 3,
    addressesInDistrict: [
      {
        RegAddrLine1: '942 HEALEY BLVD',
        RegAddrCity: 'ALPINE',
        RegAddrState: 'UT',
        RegAddrZip: 84004,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '407 MOUNTAINVILLE DR',
        RegAddrCity: 'ALPINE',
        RegAddrState: 'UT',
        RegAddrZip: 84004,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '337 N 100 W',
        RegAddrCity: 'AMERICAN FORK',
        RegAddrState: 'UT',
        RegAddrZip: 84003,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '292 N 860 E',
        RegAddrCity: 'AMERICAN FORK',
        RegAddrState: 'UT',
        RegAddrZip: 84003,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '125 W 100 S',
        RegAddrCity: 'AMERICAN FORK',
        RegAddrState: 'UT',
        RegAddrZip: 84003,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1268 E 600 NORTH CIR',
        RegAddrCity: 'AMERICAN FORK',
        RegAddrState: 'UT',
        RegAddrZip: 84003,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '354 S 300 W',
        RegAddrCity: 'BLANDING',
        RegAddrState: 'UT',
        RegAddrZip: 84511,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '3754 E PROSPECTOR CIR',
        RegAddrCity: 'COTTONWD HTS',
        RegAddrState: 'UT',
        RegAddrZip: 84121,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '3580 E RUSTIC SPRING LN',
        RegAddrCity: 'COTTONWD HTS',
        RegAddrState: 'UT',
        RegAddrZip: 84121,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '8166 S WASATCH BLVD',
        RegAddrCity: 'COTTONWOOD HEIGHTS',
        RegAddrState: 'UT',
        RegAddrZip: 84121,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1508 E CREEKVIEW CV',
        RegAddrCity: 'COTTONWOOD HEIGHTS',
        RegAddrState: 'UT',
        RegAddrZip: 84121,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '209 E 100 N',
        RegAddrCity: 'DELTA',
        RegAddrState: 'UT',
        RegAddrZip: 84624,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '13287 S ASHWOOD GLEN WAY',
        RegAddrCity: 'DRAPER',
        RegAddrState: 'UT',
        RegAddrZip: 84020,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '15349 S EAGLE CREST DR',
        RegAddrCity: 'DRAPER',
        RegAddrState: 'UT',
        RegAddrZip: 84020,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1768 E SOMERSET RIDGE DR',
        RegAddrCity: 'DRAPER',
        RegAddrState: 'UT',
        RegAddrZip: 84020,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1164 E LONE PEAK LN',
        RegAddrCity: 'DRAPER',
        RegAddrState: 'UT',
        RegAddrZip: 84020,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '13454 S LONE PEAK LN',
        RegAddrCity: 'DRAPER',
        RegAddrState: 'UT',
        RegAddrZip: 84020,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '165 W 450TH N',
        RegAddrCity: 'FERRON',
        RegAddrState: 'UT',
        RegAddrZip: 84523,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '35 S STATE ST',
        RegAddrCity: 'FERRON',
        RegAddrState: 'UT',
        RegAddrZip: 84523,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '512 S MAIN ST',
        RegAddrCity: 'HEBER CITY',
        RegAddrState: 'UT',
        RegAddrZip: 84032,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1971 S BAXTER DR',
        RegAddrCity: 'HEBER CITY',
        RegAddrState: 'UT',
        RegAddrZip: 84032,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '595 N 100 W',
        RegAddrCity: 'HEBER CITY',
        RegAddrState: 'UT',
        RegAddrZip: 84032,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '11218 N SHORELINE DR',
        RegAddrCity: 'HIGHLAND',
        RegAddrState: 'UT',
        RegAddrZip: 84003,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '5367 W WOODLAND DR',
        RegAddrCity: 'HIGHLAND',
        RegAddrState: 'UT',
        RegAddrZip: 84003,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1578 E DELAWARE LN',
        RegAddrCity: 'HOLLADAY',
        RegAddrState: 'UT',
        RegAddrZip: 84117,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1803 E GLENBROOK CIR',
        RegAddrCity: 'HOLLADAY',
        RegAddrState: 'UT',
        RegAddrZip: 84121,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '773 N 650 E',
        RegAddrCity: 'LINDON',
        RegAddrState: 'UT',
        RegAddrZip: 84042,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '674 W 100 S',
        RegAddrCity: 'LINDON',
        RegAddrState: 'UT',
        RegAddrZip: 84042,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '739 E 100 N',
        RegAddrCity: 'LINDON',
        RegAddrState: 'UT',
        RegAddrZip: 84042,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '360 E UNION ST',
        RegAddrCity: 'MANTI',
        RegAddrState: 'UT',
        RegAddrZip: 84642,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '185 W 2000 N',
        RegAddrCity: 'MAPLETON',
        RegAddrState: 'UT',
        RegAddrZip: 84664,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '6929 S 775 E',
        RegAddrCity: 'MIDVALE',
        RegAddrState: 'UT',
        RegAddrZip: 84047,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '2449 SAN JOSE RD',
        RegAddrCity: 'MOAB',
        RegAddrState: 'UT',
        RegAddrZip: 84532,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '257 S 300 E',
        RegAddrCity: 'MOAB',
        RegAddrState: 'UT',
        RegAddrZip: 84532,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '3 MI NW OF MC ON DIRT RD',
        RegAddrCity: 'MONTEZUMA CRK',
        RegAddrState: 'UT',
        RegAddrZip: 84534,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '64 S MOUNTAIN VIEW DR',
        RegAddrCity: 'MONTICELLO',
        RegAddrState: 'UT',
        RegAddrZip: 84535,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1169 N 750 W',
        RegAddrCity: 'OREM',
        RegAddrState: 'UT',
        RegAddrZip: 84057,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '373 E 340TH N',
        RegAddrCity: 'OREM',
        RegAddrState: 'UT',
        RegAddrZip: 84057,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '231 N 300 E',
        RegAddrCity: 'OREM',
        RegAddrState: 'UT',
        RegAddrZip: 84057,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1075 W 1360 S',
        RegAddrCity: 'OREM',
        RegAddrState: 'UT',
        RegAddrZip: 84058,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '592 W 1428 S',
        RegAddrCity: 'OREM',
        RegAddrState: 'UT',
        RegAddrZip: 84058,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '213 E 1838 S',
        RegAddrCity: 'OREM',
        RegAddrState: 'UT',
        RegAddrZip: 84058,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '686 E 1650 S',
        RegAddrCity: 'OREM',
        RegAddrState: 'UT',
        RegAddrZip: 84097,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '823 S 920 E',
        RegAddrCity: 'OREM',
        RegAddrState: 'UT',
        RegAddrZip: 84097,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '654 S 450 E',
        RegAddrCity: 'OREM',
        RegAddrState: 'UT',
        RegAddrZip: 84097,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '390 S 600 W',
        RegAddrCity: 'PAYSON',
        RegAddrState: 'UT',
        RegAddrZip: 84651,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1244 NATHANIEL DR',
        RegAddrCity: 'PLEASANT GRV',
        RegAddrState: 'UT',
        RegAddrZip: 84062,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '885 N 100 W',
        RegAddrCity: 'PLEASANT GRV',
        RegAddrState: 'UT',
        RegAddrZip: 84062,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1182 W 1080 N',
        RegAddrCity: 'PLEASANT GRV',
        RegAddrState: 'UT',
        RegAddrZip: 84062,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1085 GROVE CREEK DR',
        RegAddrCity: 'PLEASANT GRV',
        RegAddrState: 'UT',
        RegAddrZip: 84062,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '223 W RAILROAD AVE',
        RegAddrCity: 'PRICE',
        RegAddrState: 'UT',
        RegAddrZip: 84501,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1105 W 450 N',
        RegAddrCity: 'PROVO',
        RegAddrState: 'UT',
        RegAddrZip: 84601,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '3285 CHEROKEE LN',
        RegAddrCity: 'PROVO',
        RegAddrState: 'UT',
        RegAddrZip: 84604,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1119 W 1460 N',
        RegAddrCity: 'PROVO',
        RegAddrState: 'UT',
        RegAddrZip: 84604,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1064 HILLSIDE CIR',
        RegAddrCity: 'PROVO',
        RegAddrState: 'UT',
        RegAddrZip: 84604,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1345 ALPINE LOOP',
        RegAddrCity: 'PROVO',
        RegAddrState: 'UT',
        RegAddrZip: 84606,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '35 E 800 N',
        RegAddrCity: 'PROVO',
        RegAddrState: 'UT',
        RegAddrZip: 84606,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1137 E 230 S',
        RegAddrCity: 'PROVO',
        RegAddrState: 'UT',
        RegAddrZip: 84606,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '602 E 600 N',
        RegAddrCity: 'PROVO',
        RegAddrState: 'UT',
        RegAddrZip: 84606,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1300 E CENTER ST',
        RegAddrCity: 'PROVO',
        RegAddrState: 'UT',
        RegAddrZip: 84606,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '420 RED MESA 16',
        RegAddrCity: 'RED MESA',
        RegAddrState: 'UT',
        RegAddrZip: 84510,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '2016 E JUSTIN PARK DR',
        RegAddrCity: 'SANDY',
        RegAddrState: 'UT',
        RegAddrZip: 84092,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1918 E BRANDON PARK TER',
        RegAddrCity: 'SANDY',
        RegAddrState: 'UT',
        RegAddrZip: 84092,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '10886 S PLEASANT HILL DR',
        RegAddrCity: 'SANDY',
        RegAddrState: 'UT',
        RegAddrZip: 84092,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '9163 S CANYON GATE RD',
        RegAddrCity: 'SANDY',
        RegAddrState: 'UT',
        RegAddrZip: 84093,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '9214 S STONE VIEW CV',
        RegAddrCity: 'SANDY',
        RegAddrState: 'UT',
        RegAddrZip: 84093,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '8538 S SUGARLOAF LN',
        RegAddrCity: 'SANDY',
        RegAddrState: 'UT',
        RegAddrZip: 84093,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '8845 S RENEGADE RD',
        RegAddrCity: 'SANDY',
        RegAddrState: 'UT',
        RegAddrZip: 84093,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '8067 S BIG PINE DR',
        RegAddrCity: 'SANDY',
        RegAddrState: 'UT',
        RegAddrZip: 84094,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1239 E PLANTATION DR',
        RegAddrCity: 'SANDY',
        RegAddrState: 'UT',
        RegAddrZip: 84094,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '863 S 200 E',
        RegAddrCity: 'SANTAQUIN',
        RegAddrState: 'UT',
        RegAddrZip: 84655,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1564 S 1900 E',
        RegAddrCity: 'SPANISH FORK',
        RegAddrState: 'UT',
        RegAddrZip: 84660,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1003 S RIVER RIDGE LN',
        RegAddrCity: 'SPANISH FORK',
        RegAddrState: 'UT',
        RegAddrZip: 84660,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '535 E 100 S',
        RegAddrCity: 'SPANISH FORK',
        RegAddrState: 'UT',
        RegAddrZip: 84660,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '934 E 900 S',
        RegAddrCity: 'SPANISH FORK',
        RegAddrState: 'UT',
        RegAddrZip: 84660,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '428 S 800 W',
        RegAddrCity: 'SPANISH FORK',
        RegAddrState: 'UT',
        RegAddrZip: 84660,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '2509 E 1610 S',
        RegAddrCity: 'SPANISH FORK',
        RegAddrState: 'UT',
        RegAddrZip: 84660,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '508 W 200 S',
        RegAddrCity: 'SPRINGVILLE',
        RegAddrState: 'UT',
        RegAddrZip: 84663,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '651 S 525 W',
        RegAddrCity: 'SPRINGVILLE',
        RegAddrState: 'UT',
        RegAddrZip: 84663,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1398 N MILLPOND DR',
        RegAddrCity: 'SPRINGVILLE',
        RegAddrState: 'UT',
        RegAddrZip: 84663,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '465 EDGEHILL DR',
        RegAddrCity: 'SUNNYSIDE',
        RegAddrState: 'UT',
        RegAddrZip: 84539,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '86 S HOLDAWAY RD',
        RegAddrCity: 'VINEYARD',
        RegAddrState: 'UT',
        RegAddrZip: 84059,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '849 W 550 N',
        RegAddrCity: 'WEST BOUNTIFUL',
        RegAddrState: 'UT',
        RegAddrZip: 84087,
        CongressionalDistrict: 3,
      },
    ],
  },
  {
    name: 'UT-4',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/UT-4?ref=gh-pages',
    state: 'UT',
    state_and_number: 'UT-4',
    zip: 84005,
    congressionalDistrict: 4,
    addressesInDistrict: [
      {
        RegAddrLine1: '609 S 100 W',
        RegAddrCity: 'AMERICAN FORK',
        RegAddrState: 'UT',
        RegAddrZip: 84003,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1348 E HARRIER ST',
        RegAddrCity: 'EAGLE MTN',
        RegAddrState: 'UT',
        RegAddrZip: 84005,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '3451 E HEYWARD CT',
        RegAddrCity: 'EAGLE MTN',
        RegAddrState: 'UT',
        RegAddrZip: 84005,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '10120 E 22000TH N',
        RegAddrCity: 'FAIRVIEW',
        RegAddrState: 'UT',
        RegAddrZip: 84629,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1233 RIDGE LINE DR',
        RegAddrCity: 'HEBER CITY',
        RegAddrState: 'UT',
        RegAddrZip: 84032,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '5377 W BRIAR PARK RD',
        RegAddrCity: 'HERRIMAN',
        RegAddrState: 'UT',
        RegAddrZip: 84096,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '5282 W WEATHERFORD LN',
        RegAddrCity: 'HERRIMAN',
        RegAddrState: 'UT',
        RegAddrZip: 84096,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '14216 S BOX MEADOW LN',
        RegAddrCity: 'HERRIMAN',
        RegAddrState: 'UT',
        RegAddrZip: 84096,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1368 N 1700 W',
        RegAddrCity: 'LEHI',
        RegAddrState: 'UT',
        RegAddrZip: 84043,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '3043 W WILLOW REED',
        RegAddrCity: 'LEHI',
        RegAddrState: 'UT',
        RegAddrZip: 84043,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '110 S 100TH E',
        RegAddrCity: 'LEVAN',
        RegAddrState: 'UT',
        RegAddrZip: 84639,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '650 E DOWNING ST',
        RegAddrCity: 'MIDVALE',
        RegAddrState: 'UT',
        RegAddrZip: 84047,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1226 N OLD HWY 91',
        RegAddrCity: 'MONA',
        RegAddrState: 'UT',
        RegAddrZip: 84645,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '4562 S COTTAGE GROVE LN',
        RegAddrCity: 'MURRAY',
        RegAddrState: 'UT',
        RegAddrZip: 84107,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '5065 S ARROWHEAD CIR',
        RegAddrCity: 'MURRAY',
        RegAddrState: 'UT',
        RegAddrZip: 84107,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '6387 S 725 E',
        RegAddrCity: 'MURRAY',
        RegAddrState: 'UT',
        RegAddrZip: 84107,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '729 W RIVER GLEN DR',
        RegAddrCity: 'MURRAY',
        RegAddrState: 'UT',
        RegAddrZip: 84123,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '866 E 950 S',
        RegAddrCity: 'OREM',
        RegAddrState: 'UT',
        RegAddrZip: 84097,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2486 W MONT SUR DR',
        RegAddrCity: 'RIVERTON',
        RegAddrState: 'UT',
        RegAddrZip: 84065,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1735 W NEWBOLD LN',
        RegAddrCity: 'RIVERTON',
        RegAddrState: 'UT',
        RegAddrZip: 84065,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '12184 S SUMMIT HOLLOW CIR',
        RegAddrCity: 'RIVERTON',
        RegAddrState: 'UT',
        RegAddrZip: 84065,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '11870 S 2240 W',
        RegAddrCity: 'RIVERTON',
        RegAddrState: 'UT',
        RegAddrZip: 84065,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1182 W 13200 S',
        RegAddrCity: 'RIVERTON',
        RegAddrState: 'UT',
        RegAddrZip: 84065,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '12964 S IBAPAH CT',
        RegAddrCity: 'RIVERTON',
        RegAddrState: 'UT',
        RegAddrZip: 84096,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2150 S MAIN ST',
        RegAddrCity: 'S SALT LAKE',
        RegAddrState: 'UT',
        RegAddrZip: 84115,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1930 E WALNUT MILL CV',
        RegAddrCity: 'SALT LAKE CITY',
        RegAddrState: 'UT',
        RegAddrZip: 84106,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '842 E POND VIEW WAY',
        RegAddrCity: 'SALT LAKE CTY',
        RegAddrState: 'UT',
        RegAddrZip: 84106,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2648 S 1900 E',
        RegAddrCity: 'SALT LAKE CTY',
        RegAddrState: 'UT',
        RegAddrZip: 84106,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1106 E CHARLTON AVE',
        RegAddrCity: 'SALT LAKE CTY',
        RegAddrState: 'UT',
        RegAddrZip: 84106,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '3461 S MONTE VERDE DR',
        RegAddrCity: 'SALT LAKE CTY',
        RegAddrState: 'UT',
        RegAddrZip: 84109,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2395 E 3000 S',
        RegAddrCity: 'SALT LAKE CTY',
        RegAddrState: 'UT',
        RegAddrZip: 84109,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2919 S CONNOR ST',
        RegAddrCity: 'SALT LAKE CTY',
        RegAddrState: 'UT',
        RegAddrZip: 84109,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '3231 E GREGSON AVE',
        RegAddrCity: 'SALT LAKE CTY',
        RegAddrState: 'UT',
        RegAddrZip: 84109,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '68 E LOUISE AVE',
        RegAddrCity: 'SALT LAKE CTY',
        RegAddrState: 'UT',
        RegAddrZip: 84115,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '6185 S CIRRUS CIR',
        RegAddrCity: 'SALT LAKE CTY',
        RegAddrState: 'UT',
        RegAddrZip: 84118,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '4541 W 5335 S',
        RegAddrCity: 'SALT LAKE CTY',
        RegAddrState: 'UT',
        RegAddrZip: 84118,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '5436 W LEWIS CLARK DR',
        RegAddrCity: 'SALT LAKE CTY',
        RegAddrState: 'UT',
        RegAddrZip: 84118,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '5535 W POACHER CIR',
        RegAddrCity: 'SALT LAKE CTY',
        RegAddrState: 'UT',
        RegAddrZip: 84118,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '5998 S TUMERICK CT',
        RegAddrCity: 'SALT LAKE CTY',
        RegAddrState: 'UT',
        RegAddrZip: 84118,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '4735 S 4720 W',
        RegAddrCity: 'SALT LAKE CTY',
        RegAddrState: 'UT',
        RegAddrZip: 84118,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '5671 S HONEYSUCKLE WAY',
        RegAddrCity: 'SALT LAKE CTY',
        RegAddrState: 'UT',
        RegAddrZip: 84118,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '4512 S THORNWOOD AVE',
        RegAddrCity: 'SALT LAKE CTY',
        RegAddrState: 'UT',
        RegAddrZip: 84123,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '10285 S 2165 E',
        RegAddrCity: 'SANDY',
        RegAddrState: 'UT',
        RegAddrZip: 84092,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1396 CEDAR PASS DR',
        RegAddrCity: 'SANTAQUIN',
        RegAddrState: 'UT',
        RegAddrZip: 84655,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2448 S SHOREWOOD DR',
        RegAddrCity: 'SARATOGA SPGS',
        RegAddrState: 'UT',
        RegAddrZip: 84045,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '859 W RED HEN RD',
        RegAddrCity: 'SARATOGA SPGS',
        RegAddrState: 'UT',
        RegAddrZip: 84045,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '26 E HARMONY CT',
        RegAddrCity: 'SARATOGA SPGS',
        RegAddrState: 'UT',
        RegAddrZip: 84045,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '9507 S 4030 W',
        RegAddrCity: 'SOUTH JORDAN',
        RegAddrState: 'UT',
        RegAddrZip: 84009,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '10006 S BIRDIE WAY',
        RegAddrCity: 'SOUTH JORDAN',
        RegAddrState: 'UT',
        RegAddrZip: 84009,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '9873 S STONEHAVEN ST',
        RegAddrCity: 'SOUTH JORDAN',
        RegAddrState: 'UT',
        RegAddrZip: 84009,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '11310 S GREEN GRASS CT',
        RegAddrCity: 'SOUTH JORDAN',
        RegAddrState: 'UT',
        RegAddrZip: 84095,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2321 W SAND POINTE LN',
        RegAddrCity: 'SOUTH JORDAN',
        RegAddrState: 'UT',
        RegAddrZip: 84095,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '112 W 200 N',
        RegAddrCity: 'SPRING CITY',
        RegAddrState: 'UT',
        RegAddrZip: 84662,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '6088 S SHIRL ST',
        RegAddrCity: 'TAYLORSVILLE',
        RegAddrState: 'UT',
        RegAddrZip: 84123,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '4704 S YUCCA DR',
        RegAddrCity: 'TAYLORSVILLE',
        RegAddrState: 'UT',
        RegAddrZip: 84123,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '4875 S 1130 W',
        RegAddrCity: 'TAYLORSVILLE',
        RegAddrState: 'UT',
        RegAddrZip: 84123,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '4514 S BALSAM AVE',
        RegAddrCity: 'TAYLORSVILLE',
        RegAddrState: 'UT',
        RegAddrZip: 84123,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '3965 W BLUE MEADOW DR',
        RegAddrCity: 'TAYLORSVILLE',
        RegAddrState: 'UT',
        RegAddrZip: 84129,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '5170 S JORDAN CANAL RD',
        RegAddrCity: 'TAYLORSVILLE',
        RegAddrState: 'UT',
        RegAddrZip: 84129,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2090 W ROCKY RD',
        RegAddrCity: 'TAYLORSVILLE',
        RegAddrState: 'UT',
        RegAddrZip: 84129,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '6320 S 2200 W',
        RegAddrCity: 'TAYLORSVILLE',
        RegAddrState: 'UT',
        RegAddrZip: 84129,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '3292 W NORTHBOROUGH DR',
        RegAddrCity: 'TAYLORSVILLE',
        RegAddrState: 'UT',
        RegAddrZip: 84129,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '4047 W 6445 S',
        RegAddrCity: 'TAYLORSVILLE',
        RegAddrState: 'UT',
        RegAddrZip: 84129,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1527 W SUNSET AVE',
        RegAddrCity: 'W VALLEY CITY',
        RegAddrState: 'UT',
        RegAddrZip: 84119,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '3754 S APPLESEED RD',
        RegAddrCity: 'W VALLEY CITY',
        RegAddrState: 'UT',
        RegAddrZip: 84119,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '3780 S 3025 W',
        RegAddrCity: 'W VALLEY CITY',
        RegAddrState: 'UT',
        RegAddrZip: 84119,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '3683 S DUNHAM LN',
        RegAddrCity: 'W VALLEY CITY',
        RegAddrState: 'UT',
        RegAddrZip: 84119,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2836 S 3145 W',
        RegAddrCity: 'W VALLEY CITY',
        RegAddrState: 'UT',
        RegAddrZip: 84119,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '4557 S POSEIDON DR',
        RegAddrCity: 'W VALLEY CITY',
        RegAddrState: 'UT',
        RegAddrZip: 84120,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '4112 S JOHN WAY',
        RegAddrCity: 'W VALLEY CITY',
        RegAddrState: 'UT',
        RegAddrZip: 84120,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '4178 W 4135 S',
        RegAddrCity: 'W VALLEY CITY',
        RegAddrState: 'UT',
        RegAddrZip: 84120,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '8421 S SKYLINE ARCH DR',
        RegAddrCity: 'WEST JORDAN',
        RegAddrState: 'UT',
        RegAddrZip: 84081,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '4844 W DIAMONDLEAF WAY',
        RegAddrCity: 'WEST JORDAN',
        RegAddrState: 'UT',
        RegAddrZip: 84081,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '4948 W GASKILL WAY',
        RegAddrCity: 'WEST JORDAN',
        RegAddrState: 'UT',
        RegAddrZip: 84081,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1860 W 7265 S',
        RegAddrCity: 'WEST JORDAN',
        RegAddrState: 'UT',
        RegAddrZip: 84084,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2097 W ROBIN WAY',
        RegAddrCity: 'WEST JORDAN',
        RegAddrState: 'UT',
        RegAddrZip: 84084,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '7247 S SANTA MARIA CIR',
        RegAddrCity: 'WEST JORDAN',
        RegAddrState: 'UT',
        RegAddrZip: 84084,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '6703 S INTERLOCHIN LN',
        RegAddrCity: 'WEST JORDAN',
        RegAddrState: 'UT',
        RegAddrZip: 84084,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2613 W FERN CIR',
        RegAddrCity: 'WEST JORDAN',
        RegAddrState: 'UT',
        RegAddrZip: 84084,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2525 W BUENO VISTA DR',
        RegAddrCity: 'WEST JORDAN',
        RegAddrState: 'UT',
        RegAddrZip: 84088,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1547 W ALMOND LN',
        RegAddrCity: 'WEST JORDAN',
        RegAddrState: 'UT',
        RegAddrZip: 84088,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1628 W DOVE MEADOWS LN',
        RegAddrCity: 'WEST JORDAN',
        RegAddrState: 'UT',
        RegAddrZip: 84088,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2356 W SILVER MEADOW WAY',
        RegAddrCity: 'WEST JORDAN',
        RegAddrState: 'UT',
        RegAddrZip: 84088,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '4255 S BROOKFIELD WAY',
        RegAddrCity: 'WEST VALLEY CITY',
        RegAddrState: 'UT',
        RegAddrZip: 84120,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '3717 W TOULOUSE ST',
        RegAddrCity: 'WEST VALLEY CITY',
        RegAddrState: 'UT',
        RegAddrZip: 84120,
        CongressionalDistrict: 4,
      },
    ],
  },
  {
    name: 'VA-1',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/VA-1?ref=gh-pages',
    state: 'VA',
    state_and_number: 'VA-1',
    zip: 20109,
    congressionalDistrict: 1,
    addressesInDistrict: [
      {
        RegAddrLine1: '12109 HERMON FARMS LN',
        RegAddrCity: 'ASHLAND',
        RegAddrState: 'VA',
        RegAddrZip: 23005,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '324 ARBOR OAK DR',
        RegAddrCity: 'ASHLAND',
        RegAddrState: 'VA',
        RegAddrZip: 23005,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '6638 BROOKSIDE CT',
        RegAddrCity: 'BEALETON',
        RegAddrState: 'VA',
        RegAddrZip: 22712,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '13310 MARSH RD',
        RegAddrCity: 'BEALETON',
        RegAddrState: 'VA',
        RegAddrZip: 22712,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '12434 CALYPSO FALLS WAY',
        RegAddrCity: 'BRISTOW',
        RegAddrState: 'VA',
        RegAddrZip: 20136,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '11792 DAWKINS RIDGE LN',
        RegAddrCity: 'BRISTOW',
        RegAddrState: 'VA',
        RegAddrZip: 20136,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '84 CEDAR LN',
        RegAddrCity: 'CALLAO',
        RegAddrState: 'VA',
        RegAddrZip: 22435,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '410 LAKEVIEW DR',
        RegAddrCity: 'COLONIAL BCH',
        RegAddrState: 'VA',
        RegAddrZip: 22443,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '30 LONGFIELD RD',
        RegAddrCity: 'COLONIAL BCH',
        RegAddrState: 'VA',
        RegAddrZip: 22443,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '10 RIVERBANK DR',
        RegAddrCity: 'DELTAVILLE',
        RegAddrState: 'VA',
        RegAddrZip: 23043,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '16213 SHEFFIELD DR',
        RegAddrCity: 'DUMFRIES',
        RegAddrState: 'VA',
        RegAddrZip: 22025,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '16605 CHALICE CT',
        RegAddrCity: 'DUMFRIES',
        RegAddrState: 'VA',
        RegAddrZip: 22025,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '234 KELLOGG MILL RD',
        RegAddrCity: 'FREDERICKSBRG',
        RegAddrState: 'VA',
        RegAddrZip: 22406,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '120 DENISON ST',
        RegAddrCity: 'FREDERICKSBRG',
        RegAddrState: 'VA',
        RegAddrZip: 22406,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '406 FALKIRK CT',
        RegAddrCity: 'FREDERICKSBRG',
        RegAddrState: 'VA',
        RegAddrZip: 22406,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2603 SCHUMANN ST',
        RegAddrCity: 'FREDERICKSBRG',
        RegAddrState: 'VA',
        RegAddrZip: 22408,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '203 HUDGINS RD',
        RegAddrCity: 'FREDERICKSBRG',
        RegAddrState: 'VA',
        RegAddrZip: 22408,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2109 CHAMPIONS WAY',
        RegAddrCity: 'FREDERICKSBRG',
        RegAddrState: 'VA',
        RegAddrZip: 22408,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1838 OLD FERRY RD',
        RegAddrCity: 'GWYNN',
        RegAddrState: 'VA',
        RegAddrZip: 23066,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '10492 GOULD HILL RD',
        RegAddrCity: 'HANOVER',
        RegAddrState: 'VA',
        RegAddrZip: 23069,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '196 BEACH DR',
        RegAddrCity: 'HARTFIELD',
        RegAddrState: 'VA',
        RegAddrZip: 23071,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2454 CANOE HOUSE RD',
        RegAddrCity: 'JAMAICA',
        RegAddrState: 'VA',
        RegAddrZip: 23079,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '16460 MACHODOC CREEK LN',
        RegAddrCity: 'KING GEORGE',
        RegAddrState: 'VA',
        RegAddrZip: 22485,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '10884 PENNYCRESS ST',
        RegAddrCity: 'MANASSAS',
        RegAddrState: 'VA',
        RegAddrZip: 20110,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '9487 PERENNIAL ST',
        RegAddrCity: 'MANASSAS',
        RegAddrState: 'VA',
        RegAddrZip: 20110,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '11350 COLES DR',
        RegAddrCity: 'MANASSAS',
        RegAddrState: 'VA',
        RegAddrZip: 20112,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2252 MAHIXON RD',
        RegAddrCity: 'MANQUIN',
        RegAddrState: 'VA',
        RegAddrZip: 23106,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '8242 ANGELA NICOLE LN',
        RegAddrCity: 'MECHANICSVLLE',
        RegAddrState: 'VA',
        RegAddrZip: 23111,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '7937 WYNBROOK LN',
        RegAddrCity: 'MECHANICSVLLE',
        RegAddrState: 'VA',
        RegAddrZip: 23111,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '9815 PINE CREEK CT',
        RegAddrCity: 'MECHANICSVLLE',
        RegAddrState: 'VA',
        RegAddrZip: 23116,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '11301 SILVERSTONE DR',
        RegAddrCity: 'MECHANICSVLLE',
        RegAddrState: 'VA',
        RegAddrZip: 23116,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '14040 KREUTLER RD',
        RegAddrCity: 'MONTPELIER',
        RegAddrState: 'VA',
        RegAddrZip: 23192,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '12409 NOKESVILLE RD',
        RegAddrCity: 'NOKESVILLE',
        RegAddrState: 'VA',
        RegAddrZip: 20181,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '8623 ARBEE CT',
        RegAddrCity: 'NOKESVILLE',
        RegAddrState: 'VA',
        RegAddrZip: 20181,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '14713 FLEETWOOD DR',
        RegAddrCity: 'NOKESVILLE',
        RegAddrState: 'VA',
        RegAddrZip: 20181,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '3665 VIRGINIA RAIL DR',
        RegAddrCity: 'PROVDENCE FRG',
        RegAddrState: 'VA',
        RegAddrZip: 23140,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '406 MUNSONS HILL CT',
        RegAddrCity: 'STAFFORD',
        RegAddrState: 'VA',
        RegAddrZip: 22554,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '209 WIND RIDGE DR',
        RegAddrCity: 'STAFFORD',
        RegAddrState: 'VA',
        RegAddrZip: 22554,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '3014 CRUISER DR',
        RegAddrCity: 'STAFFORD',
        RegAddrState: 'VA',
        RegAddrZip: 22554,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '93 ALGRACE BLVD',
        RegAddrCity: 'STAFFORD',
        RegAddrState: 'VA',
        RegAddrZip: 22556,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '838 LONG POINT LN',
        RegAddrCity: 'TOPPING',
        RegAddrState: 'VA',
        RegAddrZip: 23169,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '414 THE HOOK RD',
        RegAddrCity: 'WARSAW',
        RegAddrState: 'VA',
        RegAddrZip: 22572,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '3210 KING WILLIAM AVE',
        RegAddrCity: 'WEST POINT',
        RegAddrState: 'VA',
        RegAddrZip: 23181,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2500 W WHITTAKER CLOSE',
        RegAddrCity: 'WILLIAMSBURG',
        RegAddrState: 'VA',
        RegAddrZip: 23185,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '105 FALL E',
        RegAddrCity: 'WILLIAMSBURG',
        RegAddrState: 'VA',
        RegAddrZip: 23188,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '3505 DANBURY PL',
        RegAddrCity: 'WILLIAMSBURG',
        RegAddrState: 'VA',
        RegAddrZip: 23188,
        CongressionalDistrict: 1,
      },
    ],
  },
  {
    name: 'VA-10',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/VA-10?ref=gh-pages',
    state: 'VA',
    state_and_number: 'VA-10',
    zip: 20105,
    congressionalDistrict: 10,
    addressesInDistrict: [
      {
        RegAddrLine1: '39480 BUCHANNON GAP RD',
        RegAddrCity: 'ALDIE',
        RegAddrState: 'VA',
        RegAddrZip: 20105,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '21075 ROAMING SHORES TER',
        RegAddrCity: 'ASHBURN',
        RegAddrState: 'VA',
        RegAddrZip: 20147,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '43373 DEEPSPRING CT',
        RegAddrCity: 'ASHBURN',
        RegAddrState: 'VA',
        RegAddrZip: 20147,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '20619 DUXBURY TER',
        RegAddrCity: 'ASHBURN',
        RegAddrState: 'VA',
        RegAddrZip: 20147,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '43235 KIMBERLY ANNE CT',
        RegAddrCity: 'ASHBURN',
        RegAddrState: 'VA',
        RegAddrZip: 20147,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '42579 HIGHGATE TER',
        RegAddrCity: 'BRAMBLETON',
        RegAddrState: 'VA',
        RegAddrZip: 20148,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '5602 ROCKY RUN DR',
        RegAddrCity: 'CENTREVILLE',
        RegAddrState: 'VA',
        RegAddrZip: 20120,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '6726 JENNY LEIGH CT',
        RegAddrCity: 'CENTREVILLE',
        RegAddrState: 'VA',
        RegAddrZip: 20121,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '4391 POPLAR TREE CT',
        RegAddrCity: 'CHANTILLY',
        RegAddrState: 'VA',
        RegAddrZip: 20151,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '4713 BENJAMIN CROSS CT',
        RegAddrCity: 'CHANTILLY',
        RegAddrState: 'VA',
        RegAddrZip: 20151,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '25563 GWYNNETH SQ',
        RegAddrCity: 'CHANTILLY',
        RegAddrState: 'VA',
        RegAddrZip: 20152,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '43340 MIDDLECOFF LN',
        RegAddrCity: 'CHANTILLY',
        RegAddrState: 'VA',
        RegAddrZip: 20152,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '25362 ASHBURY DR',
        RegAddrCity: 'CHANTILLY',
        RegAddrState: 'VA',
        RegAddrZip: 20152,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '13604 BRIDGELAND LN',
        RegAddrCity: 'CLIFTON',
        RegAddrState: 'VA',
        RegAddrZip: 20124,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '1026 REYNOLDS RD',
        RegAddrCity: 'CROSS JCT',
        RegAddrState: 'VA',
        RegAddrZip: 22625,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '9623 LAUREL OAK PL',
        RegAddrCity: 'FAIRFAX STA',
        RegAddrState: 'VA',
        RegAddrZip: 22039,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '14280 NEWBERN LOOP',
        RegAddrCity: 'GAINESVILLE',
        RegAddrState: 'VA',
        RegAddrZip: 20155,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '4232 LAWNVALE DR',
        RegAddrCity: 'GAINESVILLE',
        RegAddrState: 'VA',
        RegAddrZip: 20155,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '5887 WATERLOO BRIDGE CIR',
        RegAddrCity: 'HAYMARKET',
        RegAddrState: 'VA',
        RegAddrZip: 20169,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '12610 BUILDERS RD',
        RegAddrCity: 'HERNDON',
        RegAddrState: 'VA',
        RegAddrZip: 20170,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '18293 ST GEORGES CT',
        RegAddrCity: 'LEESBURG',
        RegAddrState: 'VA',
        RegAddrZip: 20176,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '520 DOYLE TER NE',
        RegAddrCity: 'LEESBURG',
        RegAddrState: 'VA',
        RegAddrZip: 20176,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '41871 BENINGBROUGH PL',
        RegAddrCity: 'LEESBURG',
        RegAddrState: 'VA',
        RegAddrZip: 20176,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '19497 MILL DAM PL',
        RegAddrCity: 'LEESBURG',
        RegAddrState: 'VA',
        RegAddrZip: 20176,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '9111 PARK AVE',
        RegAddrCity: 'MANASSAS',
        RegAddrState: 'VA',
        RegAddrZip: 20110,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '11563 LUCASVILLE RD',
        RegAddrCity: 'MANASSAS',
        RegAddrState: 'VA',
        RegAddrZip: 20112,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '103 PRICE DR',
        RegAddrCity: 'MANASSAS PARK',
        RegAddrState: 'VA',
        RegAddrZip: 20111,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '325 MOSEBY CT',
        RegAddrCity: 'MANASSAS PARK',
        RegAddrState: 'VA',
        RegAddrZip: 20111,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '6603 JILL CT',
        RegAddrCity: 'MCLEAN',
        RegAddrState: 'VA',
        RegAddrZip: 22101,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '1308 SUNNY SIDE LN',
        RegAddrCity: 'MCLEAN',
        RegAddrState: 'VA',
        RegAddrZip: 22102,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '1260 SPRING HILL RD',
        RegAddrCity: 'MCLEAN',
        RegAddrState: 'VA',
        RegAddrZip: 22102,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '17260 PICKWICK DR',
        RegAddrCity: 'PURCELLVILLE',
        RegAddrState: 'VA',
        RegAddrZip: 20132,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '18495 BRANDY RIDGE LN',
        RegAddrCity: 'PURCELLVILLE',
        RegAddrState: 'VA',
        RegAddrZip: 20132,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '35396 GLENCOE CT',
        RegAddrCity: 'ROUND HILL',
        RegAddrState: 'VA',
        RegAddrZip: 20141,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '7730 MODISTO LN',
        RegAddrCity: 'SPRINGFIELD',
        RegAddrState: 'VA',
        RegAddrZip: 22153,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '1110 E REFUGE CHURCH RD',
        RegAddrCity: 'STEPHENS CITY',
        RegAddrState: 'VA',
        RegAddrZip: 22655,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '229 HIGH BANKS RD',
        RegAddrCity: 'STEPHENSON',
        RegAddrState: 'VA',
        RegAddrZip: 22656,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '117 N CYPRESS RD',
        RegAddrCity: 'STERLING',
        RegAddrState: 'VA',
        RegAddrZip: 20164,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '9 MOSS RD',
        RegAddrCity: 'STERLING',
        RegAddrState: 'VA',
        RegAddrZip: 20165,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '20877 COSWORTH TER',
        RegAddrCity: 'STERLING',
        RegAddrState: 'VA',
        RegAddrZip: 20165,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '1442 CARRINGTON RIDGE LN',
        RegAddrCity: 'VIENNA',
        RegAddrState: 'VA',
        RegAddrZip: 22182,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '1415 RICHVIEW CT',
        RegAddrCity: 'VIENNA',
        RegAddrState: 'VA',
        RegAddrZip: 22182,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '109 APPRENTICE LN',
        RegAddrCity: 'WHITE POST',
        RegAddrState: 'VA',
        RegAddrZip: 22663,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '507 NATIONAL AVE',
        RegAddrCity: 'WINCHESTER',
        RegAddrState: 'VA',
        RegAddrZip: 22601,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '781 CARTERS LN',
        RegAddrCity: 'WINCHESTER',
        RegAddrState: 'VA',
        RegAddrZip: 22602,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '205 BOUNDARY AVE',
        RegAddrCity: 'WINCHESTER',
        RegAddrState: 'VA',
        RegAddrZip: 22602,
        CongressionalDistrict: 10,
      },
    ],
  },
  {
    name: 'VA-11',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/VA-11?ref=gh-pages',
    state: 'VA',
    state_and_number: 'VA-11',
    zip: 22027,
    congressionalDistrict: 11,
    addressesInDistrict: [
      {
        RegAddrLine1: '5800 LANE DR',
        RegAddrCity: 'ALEXANDRIA',
        RegAddrState: 'VA',
        RegAddrZip: 22310,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '3903 IVYDALE DR',
        RegAddrCity: 'ANNANDALE',
        RegAddrState: 'VA',
        RegAddrZip: 22003,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '9017 BRAEBURN DR',
        RegAddrCity: 'ANNANDALE',
        RegAddrState: 'VA',
        RegAddrZip: 22003,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '9904 NATICK RD',
        RegAddrCity: 'BURKE',
        RegAddrState: 'VA',
        RegAddrZip: 22015,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '5223 BRADFIELD DR',
        RegAddrCity: 'BURKE',
        RegAddrState: 'VA',
        RegAddrZip: 22015,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '5051 VILLAGE FOUNTAIN PL',
        RegAddrCity: 'CENTREVILLE',
        RegAddrState: 'VA',
        RegAddrZip: 20120,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '14105 WOOD ROCK WAY',
        RegAddrCity: 'CENTREVILLE',
        RegAddrState: 'VA',
        RegAddrZip: 20121,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '13998 GUNNERS PL',
        RegAddrCity: 'CENTREVILLE',
        RegAddrState: 'VA',
        RegAddrZip: 20121,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '12616 LAKE NORMANDY LN',
        RegAddrCity: 'FAIRFAX',
        RegAddrState: 'VA',
        RegAddrZip: 22030,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '12008 ENGLEMEADE DR',
        RegAddrCity: 'FAIRFAX',
        RegAddrState: 'VA',
        RegAddrZip: 22030,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '2965 FRANCISCAN LN',
        RegAddrCity: 'FAIRFAX',
        RegAddrState: 'VA',
        RegAddrZip: 22031,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '9255 BAILEY LN',
        RegAddrCity: 'FAIRFAX',
        RegAddrState: 'VA',
        RegAddrZip: 22031,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '3904 ESTEL RD',
        RegAddrCity: 'FAIRFAX',
        RegAddrState: 'VA',
        RegAddrZip: 22031,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '3208 PROSPERITY AVE',
        RegAddrCity: 'FAIRFAX',
        RegAddrState: 'VA',
        RegAddrZip: 22031,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '5421 GAINSBOROUGH DR',
        RegAddrCity: 'FAIRFAX',
        RegAddrState: 'VA',
        RegAddrZip: 22032,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '5067 COLERIDGE DR',
        RegAddrCity: 'FAIRFAX',
        RegAddrState: 'VA',
        RegAddrZip: 22032,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '4006 TIMBER OAK TRL',
        RegAddrCity: 'FAIRFAX',
        RegAddrState: 'VA',
        RegAddrZip: 22033,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '10504 DAYSAILER DR',
        RegAddrCity: 'FAIRFAX STATION',
        RegAddrState: 'VA',
        RegAddrZip: 22039,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '11652 GILMAN LN',
        RegAddrCity: 'HERNDON',
        RegAddrState: 'VA',
        RegAddrZip: 20170,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '13683 VENTURI LN',
        RegAddrCity: 'HERNDON',
        RegAddrState: 'VA',
        RegAddrZip: 20171,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '8325 LINDEN OAKS CT',
        RegAddrCity: 'LORTON',
        RegAddrState: 'VA',
        RegAddrZip: 22079,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '2945 MILLER HEIGHTS RD',
        RegAddrCity: 'OAKTON',
        RegAddrState: 'VA',
        RegAddrZip: 22124,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '2201 LOFTY HEIGHTS PL',
        RegAddrCity: 'RESTON',
        RegAddrState: 'VA',
        RegAddrZip: 20191,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '8803 TRAFALGAR CT',
        RegAddrCity: 'SPRINGFIELD',
        RegAddrState: 'VA',
        RegAddrZip: 22151,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '7916 BIRCHTREE CT',
        RegAddrCity: 'SPRINGFIELD',
        RegAddrState: 'VA',
        RegAddrZip: 22152,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '8224 MAPLE RIDGE AVE',
        RegAddrCity: 'SPRINGFIELD',
        RegAddrState: 'VA',
        RegAddrZip: 22153,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '2786 GROVEMORE LN',
        RegAddrCity: 'VIENNA',
        RegAddrState: 'VA',
        RegAddrZip: 22180,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '2147 JENNINGS ST',
        RegAddrCity: 'WOODBRIDGE',
        RegAddrState: 'VA',
        RegAddrZip: 22191,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '16448 KRAMER ESTATE DR',
        RegAddrCity: 'WOODBRIDGE',
        RegAddrState: 'VA',
        RegAddrZip: 22191,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '12777 LOTTE DR',
        RegAddrCity: 'WOODBRIDGE',
        RegAddrState: 'VA',
        RegAddrZip: 22192,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '1754 TIGER LILY CIR',
        RegAddrCity: 'WOODBRIDGE',
        RegAddrState: 'VA',
        RegAddrZip: 22192,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '14870 DORIAN DR',
        RegAddrCity: 'WOODBRIDGE',
        RegAddrState: 'VA',
        RegAddrZip: 22193,
        CongressionalDistrict: 11,
      },
      {
        RegAddrLine1: '12845 APOLLO DR',
        RegAddrCity: 'WOODBRIDGE',
        RegAddrState: 'VA',
        RegAddrZip: 22193,
        CongressionalDistrict: 11,
      },
    ],
  },
  {
    name: 'VA-2',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/VA-2?ref=gh-pages',
    state: 'VA',
    state_and_number: 'VA-2',
    zip: 23187,
    congressionalDistrict: 2,
    addressesInDistrict: [
      {
        RegAddrLine1: '5167 BEVERLY LN',
        RegAddrCity: 'CAPE CHARLES',
        RegAddrState: 'VA',
        RegAddrZip: 23310,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '21227 STOAKLEY CT',
        RegAddrCity: 'CAPE CHARLES',
        RegAddrState: 'VA',
        RegAddrZip: 23310,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '6024 HARE VALLEY CT',
        RegAddrCity: 'EXMORE',
        RegAddrState: 'VA',
        RegAddrZip: 23350,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '106 E KELLY AVE',
        RegAddrCity: 'HAMPTON',
        RegAddrState: 'VA',
        RegAddrZip: 23663,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '13543 DEERFIELD TRL',
        RegAddrCity: 'MACHIPONGO',
        RegAddrState: 'VA',
        RegAddrZip: 23405,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '28039 BEACON RD',
        RegAddrCity: 'MELFA',
        RegAddrState: 'VA',
        RegAddrZip: 23410,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '8528 MILLARD ST',
        RegAddrCity: 'NORFOLK',
        RegAddrState: 'VA',
        RegAddrZip: 23518,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '8324 KANTER AVE',
        RegAddrCity: 'NORFOLK',
        RegAddrState: 'VA',
        RegAddrZip: 23518,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2407 HEUTTE DR',
        RegAddrCity: 'NORFOLK',
        RegAddrState: 'VA',
        RegAddrZip: 23518,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '19 RENS RD',
        RegAddrCity: 'POQUOSON',
        RegAddrState: 'VA',
        RegAddrZip: 23662,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '910 TERRACE AVE',
        RegAddrCity: 'VIRGINIA BCH',
        RegAddrState: 'VA',
        RegAddrZip: 23451,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2725 CANAL RD',
        RegAddrCity: 'VIRGINIA BCH',
        RegAddrState: 'VA',
        RegAddrZip: 23451,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '3900 BRENTWOOD CRES',
        RegAddrCity: 'VIRGINIA BCH',
        RegAddrState: 'VA',
        RegAddrZip: 23452,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '640 LYNN SHORES DR',
        RegAddrCity: 'VIRGINIA BCH',
        RegAddrState: 'VA',
        RegAddrZip: 23452,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '365 DODGE DR',
        RegAddrCity: 'VIRGINIA BCH',
        RegAddrState: 'VA',
        RegAddrZip: 23452,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '3230 YORKBOROUGH WAY',
        RegAddrCity: 'VIRGINIA BCH',
        RegAddrState: 'VA',
        RegAddrZip: 23453,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1500 WOODBRIDGE PL',
        RegAddrCity: 'VIRGINIA BCH',
        RegAddrState: 'VA',
        RegAddrZip: 23453,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '853 OLD CUTLER RD',
        RegAddrCity: 'VIRGINIA BCH',
        RegAddrState: 'VA',
        RegAddrZip: 23454,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1111 WOODCOCK LN',
        RegAddrCity: 'VIRGINIA BCH',
        RegAddrState: 'VA',
        RegAddrZip: 23454,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1116 FIVE FORKS RD',
        RegAddrCity: 'VIRGINIA BCH',
        RegAddrState: 'VA',
        RegAddrZip: 23455,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1220 SMITH COVE CIR',
        RegAddrCity: 'VIRGINIA BCH',
        RegAddrState: 'VA',
        RegAddrZip: 23455,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '4909 LEATHERNECK RD',
        RegAddrCity: 'VIRGINIA BCH',
        RegAddrState: 'VA',
        RegAddrZip: 23455,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '516 CHESWICK ARCH',
        RegAddrCity: 'VIRGINIA BCH',
        RegAddrState: 'VA',
        RegAddrZip: 23455,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '664 BOTTINO LN',
        RegAddrCity: 'VIRGINIA BCH',
        RegAddrState: 'VA',
        RegAddrZip: 23455,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2416 TREYSON TRL',
        RegAddrCity: 'VIRGINIA BCH',
        RegAddrState: 'VA',
        RegAddrZip: 23456,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '3012 LOOKING GLASS CT',
        RegAddrCity: 'VIRGINIA BCH',
        RegAddrState: 'VA',
        RegAddrZip: 23456,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '3948 BORDER WAY',
        RegAddrCity: 'VIRGINIA BCH',
        RegAddrState: 'VA',
        RegAddrZip: 23456,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1729 LIVE OAK TRL',
        RegAddrCity: 'VIRGINIA BCH',
        RegAddrState: 'VA',
        RegAddrZip: 23456,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1745 GREY FRIARS CHASE',
        RegAddrCity: 'VIRGINIA BCH',
        RegAddrState: 'VA',
        RegAddrZip: 23456,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2261 WIDGEON LN',
        RegAddrCity: 'VIRGINIA BCH',
        RegAddrState: 'VA',
        RegAddrZip: 23456,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '3701 CREEKWOOD DR',
        RegAddrCity: 'VIRGINIA BCH',
        RegAddrState: 'VA',
        RegAddrZip: 23456,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '3781 CHIMNEY CREEK DR',
        RegAddrCity: 'VIRGINIA BCH',
        RegAddrState: 'VA',
        RegAddrZip: 23462,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '3729 CAINHOY LN',
        RegAddrCity: 'VIRGINIA BCH',
        RegAddrState: 'VA',
        RegAddrZip: 23462,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '282 MAYO RD',
        RegAddrCity: 'VIRGINIA BCH',
        RegAddrState: 'VA',
        RegAddrZip: 23462,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '5757 LUCK LN',
        RegAddrCity: 'VIRGINIA BCH',
        RegAddrState: 'VA',
        RegAddrZip: 23464,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '637 REASOR DR',
        RegAddrCity: 'VIRGINIA BCH',
        RegAddrState: 'VA',
        RegAddrZip: 23464,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '645 REASOR DR',
        RegAddrCity: 'VIRGINIA BCH',
        RegAddrState: 'VA',
        RegAddrZip: 23464,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1020 RIVERBEND RD',
        RegAddrCity: 'VIRGINIA BEACH',
        RegAddrState: 'VA',
        RegAddrZip: 23452,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1232 WHISPERING WATERS WAY',
        RegAddrCity: 'VIRGINIA BEACH',
        RegAddrState: 'VA',
        RegAddrZip: 23454,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '101 JIB CT',
        RegAddrCity: 'WILLIAMSBURG',
        RegAddrState: 'VA',
        RegAddrZip: 23185,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2131 LAKE POWELL RD',
        RegAddrCity: 'WILLIAMSBURG',
        RegAddrState: 'VA',
        RegAddrZip: 23185,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '226 RICHMOND RD',
        RegAddrCity: 'WILLIAMSBURG',
        RegAddrState: 'VA',
        RegAddrZip: 23186,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '227 DARE RD',
        RegAddrCity: 'YORKTOWN',
        RegAddrState: 'VA',
        RegAddrZip: 23692,
        CongressionalDistrict: 2,
      },
    ],
  },
  {
    name: 'VA-3',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/VA-3?ref=gh-pages',
    state: 'VA',
    state_and_number: 'VA-3',
    zip: 23304,
    congressionalDistrict: 3,
    addressesInDistrict: [
      {
        RegAddrLine1: '4008 BIRCHWOOD AVE',
        RegAddrCity: 'CHESAPEAKE',
        RegAddrState: 'VA',
        RegAddrZip: 23321,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '2308 LLOYD DR',
        RegAddrCity: 'CHESAPEAKE',
        RegAddrState: 'VA',
        RegAddrZip: 23325,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '2904 EUSTIS AVE',
        RegAddrCity: 'CHESAPEAKE',
        RegAddrState: 'VA',
        RegAddrZip: 23325,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '204 MORTON ST',
        RegAddrCity: 'FRANKLIN',
        RegAddrState: 'VA',
        RegAddrZip: 23851,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '13 ROUNDTREE CIR',
        RegAddrCity: 'HAMPTON',
        RegAddrState: 'VA',
        RegAddrZip: 23661,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '28 THOROUGHBRED DR',
        RegAddrCity: 'HAMPTON',
        RegAddrState: 'VA',
        RegAddrZip: 23666,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '40 KENILWORTH DR',
        RegAddrCity: 'HAMPTON',
        RegAddrState: 'VA',
        RegAddrZip: 23666,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '32 REDWOOD ST',
        RegAddrCity: 'HAMPTON',
        RegAddrState: 'VA',
        RegAddrZip: 23669,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '343 WOODSIDE DR',
        RegAddrCity: 'HAMPTON',
        RegAddrState: 'VA',
        RegAddrZip: 23669,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '22 FRANCES ST',
        RegAddrCity: 'NEWPORT NEWS',
        RegAddrState: 'VA',
        RegAddrZip: 23601,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '616 BRIARFIELD RD',
        RegAddrCity: 'NEWPORT NEWS',
        RegAddrState: 'VA',
        RegAddrZip: 23605,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '440 WINTERHAVEN DR',
        RegAddrCity: 'NEWPORT NEWS',
        RegAddrState: 'VA',
        RegAddrZip: 23606,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '906 SIR LIONEL CT',
        RegAddrCity: 'NEWPORT NEWS',
        RegAddrState: 'VA',
        RegAddrZip: 23608,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '265 CASTLE KEEP CT',
        RegAddrCity: 'NEWPORT NEWS',
        RegAddrState: 'VA',
        RegAddrZip: 23608,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '840 SHIELDS RD',
        RegAddrCity: 'NEWPORT NEWS',
        RegAddrState: 'VA',
        RegAddrZip: 23608,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '910 VILLA CIR',
        RegAddrCity: 'NORFOLK',
        RegAddrState: 'VA',
        RegAddrZip: 23504,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '610 BURLEIGH AVE',
        RegAddrCity: 'NORFOLK',
        RegAddrState: 'VA',
        RegAddrZip: 23505,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '704 W 29TH ST',
        RegAddrCity: 'NORFOLK',
        RegAddrState: 'VA',
        RegAddrZip: 23508,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '735 WAUKESHA AVE',
        RegAddrCity: 'NORFOLK',
        RegAddrState: 'VA',
        RegAddrZip: 23509,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '946 AVENUE H',
        RegAddrCity: 'NORFOLK',
        RegAddrState: 'VA',
        RegAddrZip: 23513,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '132 HARDY AVE',
        RegAddrCity: 'NORFOLK',
        RegAddrState: 'VA',
        RegAddrZip: 23523,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '314 NAVAJO TRL',
        RegAddrCity: 'PORTSMOUTH',
        RegAddrState: 'VA',
        RegAddrZip: 23701,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '211 ROBIN RD',
        RegAddrCity: 'PORTSMOUTH',
        RegAddrState: 'VA',
        RegAddrZip: 23701,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '601 SUSAN ST',
        RegAddrCity: 'PORTSMOUTH',
        RegAddrState: 'VA',
        RegAddrZip: 23701,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '5683 RIVERMILL CIR',
        RegAddrCity: 'PORTSMOUTH',
        RegAddrState: 'VA',
        RegAddrZip: 23703,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '2429 BIDGOOD DR',
        RegAddrCity: 'PORTSMOUTH',
        RegAddrState: 'VA',
        RegAddrZip: 23703,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '812 CAMBRIDGE AVE',
        RegAddrCity: 'PORTSMOUTH',
        RegAddrState: 'VA',
        RegAddrZip: 23707,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '169 LANE CRES',
        RegAddrCity: 'SMITHFIELD',
        RegAddrState: 'VA',
        RegAddrZip: 23430,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '132 LANE CRES',
        RegAddrCity: 'SMITHFIELD',
        RegAddrState: 'VA',
        RegAddrZip: 23430,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1721 PINE ACRES',
        RegAddrCity: 'SUFFOLK',
        RegAddrState: 'VA',
        RegAddrZip: 23432,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '126 RABEY FARM RD',
        RegAddrCity: 'SUFFOLK',
        RegAddrState: 'VA',
        RegAddrZip: 23435,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '5025 BAY CIR',
        RegAddrCity: 'SUFFOLK',
        RegAddrState: 'VA',
        RegAddrZip: 23435,
        CongressionalDistrict: 3,
      },
    ],
  },
  {
    name: 'VA-4',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/VA-4?ref=gh-pages',
    state: 'VA',
    state_and_number: 'VA-4',
    zip: 23030,
    congressionalDistrict: 4,
    addressesInDistrict: [
      {
        RegAddrLine1: '15163 BROAD ST',
        RegAddrCity: 'BRANCHVILLE',
        RegAddrState: 'VA',
        RegAddrZip: 23828,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '6821 CHURCH LN',
        RegAddrCity: 'CHARLES CITY',
        RegAddrState: 'VA',
        RegAddrZip: 23030,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '349 WATERS RD',
        RegAddrCity: 'CHESAPEAKE',
        RegAddrState: 'VA',
        RegAddrZip: 23322,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1044 HEAD OF RIVER RD',
        RegAddrCity: 'CHESAPEAKE',
        RegAddrState: 'VA',
        RegAddrZip: 23322,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '738 S LAKE CIR',
        RegAddrCity: 'CHESAPEAKE',
        RegAddrState: 'VA',
        RegAddrZip: 23322,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '813 DAWSON CIR',
        RegAddrCity: 'CHESAPEAKE',
        RegAddrState: 'VA',
        RegAddrZip: 23322,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '3100 DOUGLAS RD',
        RegAddrCity: 'CHESAPEAKE',
        RegAddrState: 'VA',
        RegAddrZip: 23322,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '324 MILL STONE RD',
        RegAddrCity: 'CHESAPEAKE',
        RegAddrState: 'VA',
        RegAddrZip: 23322,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '10637 MICHMAR DR',
        RegAddrCity: 'CHESTER',
        RegAddrState: 'VA',
        RegAddrZip: 23831,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '5662 TETERLING CT',
        RegAddrCity: 'CHESTER',
        RegAddrState: 'VA',
        RegAddrZip: 23831,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '12501 NIGHTINGALE DR',
        RegAddrCity: 'CHESTER',
        RegAddrState: 'VA',
        RegAddrZip: 23836,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '3511 WARSAW TER',
        RegAddrCity: 'GLEN ALLEN',
        RegAddrState: 'VA',
        RegAddrZip: 23060,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '8121 FOUR MILE RUN PKWY',
        RegAddrCity: 'HENRICO',
        RegAddrState: 'VA',
        RegAddrZip: 23231,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '6820 KILCHURN CT',
        RegAddrCity: 'HENRICO',
        RegAddrState: 'VA',
        RegAddrZip: 23231,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1515 OLD IRON RD',
        RegAddrCity: 'HOPEWELL',
        RegAddrState: 'VA',
        RegAddrZip: 23860,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '3218 SOUTHERN PINES DR',
        RegAddrCity: 'N PRINCE GEO',
        RegAddrState: 'VA',
        RegAddrZip: 23860,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '9500 KENDRICK RD',
        RegAddrCity: 'NORTH CHESTERFIELD',
        RegAddrState: 'VA',
        RegAddrZip: 23236,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '737 COURT ST',
        RegAddrCity: 'PETERSBURG',
        RegAddrState: 'VA',
        RegAddrZip: 23803,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1211 HAMILTON ST',
        RegAddrCity: 'PETERSBURG',
        RegAddrState: 'VA',
        RegAddrZip: 23803,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1640 JOHNSON RD',
        RegAddrCity: 'PETERSBURG',
        RegAddrState: 'VA',
        RegAddrZip: 23805,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '11316 CEDAR RUN RD',
        RegAddrCity: 'PETERSBURG',
        RegAddrState: 'VA',
        RegAddrZip: 23805,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '421 PARK DR S',
        RegAddrCity: 'PETERSBURG',
        RegAddrState: 'VA',
        RegAddrZip: 23805,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '3433 FRANCIS ST',
        RegAddrCity: 'PETERSBURG',
        RegAddrState: 'VA',
        RegAddrZip: 23805,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2706 STUART AVE',
        RegAddrCity: 'RICHMOND',
        RegAddrState: 'VA',
        RegAddrZip: 23220,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '21 N ROWLAND ST',
        RegAddrCity: 'RICHMOND',
        RegAddrState: 'VA',
        RegAddrZip: 23220,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2420 HANOVER AVE',
        RegAddrCity: 'RICHMOND',
        RegAddrState: 'VA',
        RegAddrZip: 23220,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '517 POLLOCK ST',
        RegAddrCity: 'RICHMOND',
        RegAddrState: 'VA',
        RegAddrZip: 23222,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '4010 AUSTIN AVE',
        RegAddrCity: 'RICHMOND',
        RegAddrState: 'VA',
        RegAddrZip: 23222,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '7643 ROCKFALLS DR',
        RegAddrCity: 'RICHMOND',
        RegAddrState: 'VA',
        RegAddrZip: 23225,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2919 LAURADALE LN',
        RegAddrCity: 'RICHMOND',
        RegAddrState: 'VA',
        RegAddrZip: 23234,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '13055 COMANS WELL RD',
        RegAddrCity: 'STONY CREEK',
        RegAddrState: 'VA',
        RegAddrZip: 23882,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2568 INDIAN TRL',
        RegAddrCity: 'SUFFOLK',
        RegAddrState: 'VA',
        RegAddrZip: 23434,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1811 MOUNTAINSIDE AVE',
        RegAddrCity: 'SUFFOLK',
        RegAddrState: 'VA',
        RegAddrZip: 23434,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '3936 COLONIAL TRL E',
        RegAddrCity: 'SURRY',
        RegAddrState: 'VA',
        RegAddrZip: 23883,
        CongressionalDistrict: 4,
      },
    ],
  },
  {
    name: 'VA-5',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/VA-5?ref=gh-pages',
    state: 'VA',
    state_and_number: 'VA-5',
    zip: 20106,
    congressionalDistrict: 5,
    addressesInDistrict: [
      {
        RegAddrLine1: '5994 FLINTSTONE DR',
        RegAddrCity: 'BARBOURSVILLE',
        RegAddrState: 'VA',
        RegAddrZip: 22923,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '1324 BRUNMORE CIR',
        RegAddrCity: 'BEDFORD',
        RegAddrState: 'VA',
        RegAddrZip: 24523,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '5656 BEVERLEYS MILL RD',
        RegAddrCity: 'BROAD RUN',
        RegAddrState: 'VA',
        RegAddrZip: 20137,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '803 RAINIER RD',
        RegAddrCity: 'CHARLOTTESVILLE',
        RegAddrState: 'VA',
        RegAddrZip: 22903,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '913 BLENHEIM AVE',
        RegAddrCity: 'CHARLOTTESVLE',
        RegAddrState: 'VA',
        RegAddrZip: 22902,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '313 10 1/2 ST NW',
        RegAddrCity: 'CHARLOTTESVLE',
        RegAddrState: 'VA',
        RegAddrZip: 22903,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '2529 WOODLAND DR',
        RegAddrCity: 'CHARLOTTESVLE',
        RegAddrState: 'VA',
        RegAddrZip: 22903,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '3411 MOUBRY LN',
        RegAddrCity: 'CHARLOTTESVLE',
        RegAddrState: 'VA',
        RegAddrZip: 22911,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '7647 BIRCHWOOD HILL RD',
        RegAddrCity: 'CROZET',
        RegAddrState: 'VA',
        RegAddrZip: 22932,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '927 JENKINS CHURCH RD',
        RegAddrCity: 'CUMBERLAND',
        RegAddrState: 'VA',
        RegAddrZip: 23040,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '2139 BAXTER ST',
        RegAddrCity: 'DANVILLE',
        RegAddrState: 'VA',
        RegAddrZip: 24540,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '310 LOCUST LN',
        RegAddrCity: 'DANVILLE',
        RegAddrState: 'VA',
        RegAddrZip: 24540,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '626 WESTVIEW DR',
        RegAddrCity: 'DANVILLE',
        RegAddrState: 'VA',
        RegAddrZip: 24540,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '772 ROCKWOOD DR',
        RegAddrCity: 'DANVILLE',
        RegAddrState: 'VA',
        RegAddrZip: 24541,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '1304 BRANCH ST',
        RegAddrCity: 'DANVILLE',
        RegAddrState: 'VA',
        RegAddrZip: 24541,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '301 SALEM RD',
        RegAddrCity: 'EVINGTON',
        RegAddrState: 'VA',
        RegAddrZip: 24550,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '2243 TOWN FORK RD',
        RegAddrCity: 'EVINGTON',
        RegAddrState: 'VA',
        RegAddrZip: 24550,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '259 MILLER LAKE RD',
        RegAddrCity: 'GREEN BAY',
        RegAddrState: 'VA',
        RegAddrZip: 23942,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '2081 RASH MADDUX DR',
        RegAddrCity: 'KENBRIDGE',
        RegAddrState: 'VA',
        RegAddrZip: 23944,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '322 WYNDHURST DR',
        RegAddrCity: 'LYNCHBURG',
        RegAddrState: 'VA',
        RegAddrZip: 24502,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '223 PERVIS RD',
        RegAddrCity: 'MARTINSVILLE',
        RegAddrState: 'VA',
        RegAddrZip: 24112,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '125 JUSTIN DR',
        RegAddrCity: 'PALMYRA',
        RegAddrState: 'VA',
        RegAddrZip: 22963,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '340 MANOR BLVD',
        RegAddrCity: 'PALMYRA',
        RegAddrState: 'VA',
        RegAddrZip: 22963,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '5832 PATRICK HENRY HWY',
        RegAddrCity: 'PHENIX',
        RegAddrState: 'VA',
        RegAddrZip: 23959,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '18021 RED HOUSE RD',
        RegAddrCity: 'RED HOUSE',
        RegAddrState: 'VA',
        RegAddrZip: 23963,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '11603 JAMES MADISON ST',
        RegAddrCity: 'REMINGTON',
        RegAddrState: 'VA',
        RegAddrZip: 22734,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '332 BUNKER LN',
        RegAddrCity: 'RINGGOLD',
        RegAddrState: 'VA',
        RegAddrZip: 24586,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '2660 FISHBURN MOUNTAIN RD',
        RegAddrCity: 'ROCKY MOUNT',
        RegAddrState: 'VA',
        RegAddrZip: 24151,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '35 HILLCREST DR',
        RegAddrCity: 'ROCKY MOUNT',
        RegAddrState: 'VA',
        RegAddrZip: 24151,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '810 RIVERBEND DR',
        RegAddrCity: 'ROCKY MOUNT',
        RegAddrState: 'VA',
        RegAddrZip: 24151,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '8093 PATRICK HENRY HWY',
        RegAddrCity: 'ROSELAND',
        RegAddrState: 'VA',
        RegAddrZip: 22967,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '2594 RED HOUSE RD',
        RegAddrCity: 'RUSTBURG',
        RegAddrState: 'VA',
        RegAddrZip: 24588,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '4996 W RIVER RD',
        RegAddrCity: 'SCOTTSVILLE',
        RegAddrState: 'VA',
        RegAddrZip: 24590,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '1210 TANGLEWOOD DR',
        RegAddrCity: 'SOUTH HILL',
        RegAddrState: 'VA',
        RegAddrZip: 23970,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '614 CHAPTICO RD',
        RegAddrCity: 'SOUTH HILL',
        RegAddrState: 'VA',
        RegAddrZip: 23970,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '416 CEDAR RIDGE RD',
        RegAddrCity: 'UNION HALL',
        RegAddrState: 'VA',
        RegAddrZip: 24176,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '8402 ROGUES RD',
        RegAddrCity: 'WARRENTON',
        RegAddrState: 'VA',
        RegAddrZip: 20187,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '4651 SPRING RUN RD',
        RegAddrCity: 'WARRENTON',
        RegAddrState: 'VA',
        RegAddrZip: 20187,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '1788 MALLARD POINT RD',
        RegAddrCity: 'WIRTZ',
        RegAddrState: 'VA',
        RegAddrZip: 24184,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '1236 ALEAN RD',
        RegAddrCity: 'WIRTZ',
        RegAddrState: 'VA',
        RegAddrZip: 24184,
        CongressionalDistrict: 5,
      },
    ],
  },
  {
    name: 'VA-6',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/VA-6?ref=gh-pages',
    state: 'VA',
    state_and_number: 'VA-6',
    zip: 22610,
    congressionalDistrict: 6,
    addressesInDistrict: [
      {
        RegAddrLine1: '225 TURKEY MOUNTAIN RD',
        RegAddrCity: 'AMHERST',
        RegAddrState: 'VA',
        RegAddrZip: 24521,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '173 MOUNT PLEASANT RD',
        RegAddrCity: 'AMHERST',
        RegAddrState: 'VA',
        RegAddrZip: 24521,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '193 ROBERTS RUN',
        RegAddrCity: 'BASYE',
        RegAddrState: 'VA',
        RegAddrZip: 22810,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '102 BENJAMIN CT',
        RegAddrCity: 'BEDFORD',
        RegAddrState: 'VA',
        RegAddrZip: 24523,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '6634 FRANKLIN RD',
        RegAddrCity: 'BOONES MILL',
        RegAddrState: 'VA',
        RegAddrZip: 24065,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '105 POPLAR AVE',
        RegAddrCity: 'BUENA VISTA',
        RegAddrState: 'VA',
        RegAddrZip: 24416,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '4682 READ MOUNTAIN RD',
        RegAddrCity: 'CLOVERDALE',
        RegAddrState: 'VA',
        RegAddrZip: 24077,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '1897 KNIGHT RD',
        RegAddrCity: 'COLEMAN FALLS',
        RegAddrState: 'VA',
        RegAddrZip: 24536,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '10089 JESS AREY LN',
        RegAddrCity: 'DAYTON',
        RegAddrState: 'VA',
        RegAddrZip: 22821,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '325 ASHBY ST',
        RegAddrCity: 'DAYTON',
        RegAddrState: 'VA',
        RegAddrZip: 22821,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '268 MAIN ST',
        RegAddrCity: 'DAYTON',
        RegAddrState: 'VA',
        RegAddrZip: 22821,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '2762 BLUE MOUNTAIN RD',
        RegAddrCity: 'FRONT ROYAL',
        RegAddrState: 'VA',
        RegAddrZip: 22630,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '318 BLUE RIDGE AVE',
        RegAddrCity: 'FRONT ROYAL',
        RegAddrState: 'VA',
        RegAddrZip: 22630,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '1206 FAIRGROUND RD',
        RegAddrCity: 'FRONT ROYAL',
        RegAddrState: 'VA',
        RegAddrZip: 22630,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '416 E ROCK ST',
        RegAddrCity: 'HARRISONBURG',
        RegAddrState: 'VA',
        RegAddrZip: 22802,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '7 MILEY CT',
        RegAddrCity: 'LEXINGTON',
        RegAddrState: 'VA',
        RegAddrZip: 24450,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '13 CANAAN ST',
        RegAddrCity: 'LURAY',
        RegAddrState: 'VA',
        RegAddrZip: 22835,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '521 MANSFIELD AVE',
        RegAddrCity: 'LYNCHBURG',
        RegAddrState: 'VA',
        RegAddrZip: 24501,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '1508 AUGUSTA ST',
        RegAddrCity: 'LYNCHBURG',
        RegAddrState: 'VA',
        RegAddrZip: 24501,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '3114 RICHMOND ST',
        RegAddrCity: 'LYNCHBURG',
        RegAddrState: 'VA',
        RegAddrZip: 24501,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '1004 ROUNDELAY RD',
        RegAddrCity: 'LYNCHBURG',
        RegAddrState: 'VA',
        RegAddrZip: 24502,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '201 LILLIAN LN',
        RegAddrCity: 'LYNCHBURG',
        RegAddrState: 'VA',
        RegAddrZip: 24502,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '1110 VIRGINIA ST',
        RegAddrCity: 'LYNCHBURG',
        RegAddrState: 'VA',
        RegAddrZip: 24504,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '55 OLD QUARRY RD',
        RegAddrCity: 'MOUNT SOLON',
        RegAddrState: 'VA',
        RegAddrZip: 22843,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '3125 HOLLINS RD NE',
        RegAddrCity: 'ROANOKE',
        RegAddrState: 'VA',
        RegAddrZip: 24012,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '2012 BUNCHE DR NW',
        RegAddrCity: 'ROANOKE',
        RegAddrState: 'VA',
        RegAddrZip: 24012,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '2723 EDISON ST NE',
        RegAddrCity: 'ROANOKE',
        RegAddrState: 'VA',
        RegAddrZip: 24012,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '3101 MEDALLION CIR SE',
        RegAddrCity: 'ROANOKE',
        RegAddrState: 'VA',
        RegAddrZip: 24014,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '1217 FLOYD AVE SW',
        RegAddrCity: 'ROANOKE',
        RegAddrState: 'VA',
        RegAddrZip: 24015,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '1117 AMHERST ST SW',
        RegAddrCity: 'ROANOKE',
        RegAddrState: 'VA',
        RegAddrZip: 24015,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '5468 STEARNES AVE',
        RegAddrCity: 'ROANOKE',
        RegAddrState: 'VA',
        RegAddrZip: 24018,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '1805 WESTCHESTER AVE SW',
        RegAddrCity: 'ROANOKE',
        RegAddrState: 'VA',
        RegAddrZip: 24018,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '7251 OLD MILL RD',
        RegAddrCity: 'ROANOKE',
        RegAddrState: 'VA',
        RegAddrZip: 24018,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '5022 JONATHAN LN',
        RegAddrCity: 'ROANOKE',
        RegAddrState: 'VA',
        RegAddrZip: 24019,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '58 HULL HILLS LN',
        RegAddrCity: 'STAUNTON',
        RegAddrState: 'VA',
        RegAddrZip: 24401,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '613 HILLTOP DR',
        RegAddrCity: 'STAUNTON',
        RegAddrState: 'VA',
        RegAddrZip: 24401,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '103 MIDVALE XING',
        RegAddrCity: 'STAUNTON',
        RegAddrState: 'VA',
        RegAddrZip: 24401,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '126 STRASBURG RESERVOIR RD',
        RegAddrCity: 'STRASBURG',
        RegAddrState: 'VA',
        RegAddrZip: 22657,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '726 CHRISTIANSEN DR',
        RegAddrCity: 'STRASBURG',
        RegAddrState: 'VA',
        RegAddrZip: 22657,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '256 CHICKORY LN',
        RegAddrCity: 'STRASBURG',
        RegAddrState: 'VA',
        RegAddrZip: 22657,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '779 OLD WHITE HILL RD',
        RegAddrCity: 'STUARTS DRAFT',
        RegAddrState: 'VA',
        RegAddrZip: 24477,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '131 CANTON LN',
        RegAddrCity: 'STUARTS DRAFT',
        RegAddrState: 'VA',
        RegAddrZip: 24477,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '20 WELLINGTON PL',
        RegAddrCity: 'WAYNESBORO',
        RegAddrState: 'VA',
        RegAddrZip: 22980,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '8398 GREENHOUSE RD',
        RegAddrCity: 'WEYERS CAVE',
        RegAddrState: 'VA',
        RegAddrZip: 24486,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '447 HICKORY LN',
        RegAddrCity: 'WOODSTOCK',
        RegAddrState: 'VA',
        RegAddrZip: 22664,
        CongressionalDistrict: 6,
      },
    ],
  },
  {
    name: 'VA-7',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/VA-7?ref=gh-pages',
    state: 'VA',
    state_and_number: 'VA-7',
    zip: 22433,
    congressionalDistrict: 7,
    addressesInDistrict: [
      {
        RegAddrLine1: '15800 DUNN ST',
        RegAddrCity: 'AMELIA CT HSE',
        RegAddrState: 'VA',
        RegAddrZip: 23002,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '17080 BLACK OAK DR',
        RegAddrCity: 'BRANDY STA',
        RegAddrState: 'VA',
        RegAddrZip: 22714,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '4831 NEWBYS BRIDGE RD',
        RegAddrCity: 'CHESTERFIELD',
        RegAddrState: 'VA',
        RegAddrZip: 23832,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '1745 GENITO RD',
        RegAddrCity: 'CROZIER',
        RegAddrState: 'VA',
        RegAddrZip: 23039,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '21034 DOVE HILL RD',
        RegAddrCity: 'CULPEPER',
        RegAddrState: 'VA',
        RegAddrZip: 22701,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '6816 ORCHID LN',
        RegAddrCity: 'FREDERICKSBRG',
        RegAddrState: 'VA',
        RegAddrZip: 22407,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '12318 ASHTON MILL TER',
        RegAddrCity: 'GLEN ALLEN',
        RegAddrState: 'VA',
        RegAddrZip: 23059,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '3525 HUNTON RIDGE DR',
        RegAddrCity: 'GLEN ALLEN',
        RegAddrState: 'VA',
        RegAddrZip: 23059,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '3810 BARNYARD TRL',
        RegAddrCity: 'GLEN ALLEN',
        RegAddrState: 'VA',
        RegAddrZip: 23060,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '1907 LIESFELD PKWY',
        RegAddrCity: 'GLEN ALLEN',
        RegAddrState: 'VA',
        RegAddrZip: 23060,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '70 POWER DR',
        RegAddrCity: 'GORDONSVILLE',
        RegAddrState: 'VA',
        RegAddrZip: 22942,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '18094 VERLING DR',
        RegAddrCity: 'GORDONSVILLE',
        RegAddrState: 'VA',
        RegAddrZip: 22942,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '1701 ASHCLIFF WAY',
        RegAddrCity: 'HENRICO',
        RegAddrState: 'VA',
        RegAddrZip: 23228,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '63 GATEWAY EAST',
        RegAddrCity: 'HENRICO',
        RegAddrState: 'VA',
        RegAddrZip: 23229,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '7500 SHERYL RD',
        RegAddrCity: 'HENRICO',
        RegAddrState: 'VA',
        RegAddrZip: 23229,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '12700 COPPERAS LN',
        RegAddrCity: 'HENRICO',
        RegAddrState: 'VA',
        RegAddrZip: 23233,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '10804 CHIPEWYAN DR',
        RegAddrCity: 'HENRICO',
        RegAddrState: 'VA',
        RegAddrZip: 23238,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '2500 ARRINGTON RD',
        RegAddrCity: 'HENRICO',
        RegAddrState: 'VA',
        RegAddrZip: 23294,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '505 STRATFORD CIR',
        RegAddrCity: 'LOCUST GROVE',
        RegAddrState: 'VA',
        RegAddrZip: 22508,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '221 FOXBROOK LN',
        RegAddrCity: 'LOUISA',
        RegAddrState: 'VA',
        RegAddrZip: 23093,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '7901 SECRETARIAT DR',
        RegAddrCity: 'MIDLOTHIAN',
        RegAddrState: 'VA',
        RegAddrZip: 23112,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '538 WINDYKNIGHT RD',
        RegAddrCity: 'MONTPELIER',
        RegAddrState: 'VA',
        RegAddrZip: 23192,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '3142 KIM DR',
        RegAddrCity: 'N CHESTERFLD',
        RegAddrState: 'VA',
        RegAddrZip: 23224,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '5840 BELMONT RD',
        RegAddrCity: 'N CHESTERFLD',
        RegAddrState: 'VA',
        RegAddrZip: 23234,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '4808 MASON HOLLOW DR',
        RegAddrCity: 'N CHESTERFLD',
        RegAddrState: 'VA',
        RegAddrZip: 23234,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '7904 PROVINCETOWN DR',
        RegAddrCity: 'N CHESTERFLD',
        RegAddrState: 'VA',
        RegAddrZip: 23235,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '2801 NEWQUAY LN',
        RegAddrCity: 'N CHESTERFLD',
        RegAddrState: 'VA',
        RegAddrZip: 23236,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '4529 MATTOX CROSSING CT',
        RegAddrCity: 'POWHATAN',
        RegAddrState: 'VA',
        RegAddrZip: 23139,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '2580 GLENRIDGE CT',
        RegAddrCity: 'POWHATAN',
        RegAddrState: 'VA',
        RegAddrZip: 23139,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '1877 HOPE MEADOW WAY',
        RegAddrCity: 'POWHATAN',
        RegAddrState: 'VA',
        RegAddrZip: 23139,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '13503 TOWER RD',
        RegAddrCity: 'UNIONVILLE',
        RegAddrState: 'VA',
        RegAddrZip: 22567,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '6603 MARYE RD',
        RegAddrCity: 'WOODFORD',
        RegAddrState: 'VA',
        RegAddrZip: 22580,
        CongressionalDistrict: 7,
      },
    ],
  },
  {
    name: 'VA-8',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/VA-8?ref=gh-pages',
    state: 'VA',
    state_and_number: 'VA-8',
    zip: 22003,
    congressionalDistrict: 8,
    addressesInDistrict: [
      {
        RegAddrLine1: '2616 FARMINGTON DR',
        RegAddrCity: 'ALEXANDRIA',
        RegAddrState: 'VA',
        RegAddrZip: 22303,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '2920 SYCAMORE ST',
        RegAddrCity: 'ALEXANDRIA',
        RegAddrState: 'VA',
        RegAddrZip: 22305,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '6307 OLMI LANDRITH DR',
        RegAddrCity: 'ALEXANDRIA',
        RegAddrState: 'VA',
        RegAddrZip: 22307,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '8304 SIMSBURY PL',
        RegAddrCity: 'ALEXANDRIA',
        RegAddrState: 'VA',
        RegAddrZip: 22308,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '1506 LAFAYETTE DR',
        RegAddrCity: 'ALEXANDRIA',
        RegAddrState: 'VA',
        RegAddrZip: 22308,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '8421 RICHMOND AVE',
        RegAddrCity: 'ALEXANDRIA',
        RegAddrState: 'VA',
        RegAddrZip: 22309,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '8682 VENOY CT',
        RegAddrCity: 'ALEXANDRIA',
        RegAddrState: 'VA',
        RegAddrZip: 22309,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '5034 DOMAIN PL',
        RegAddrCity: 'ALEXANDRIA',
        RegAddrState: 'VA',
        RegAddrZip: 22311,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '539 COLECROFT CT',
        RegAddrCity: 'ALEXANDRIA',
        RegAddrState: 'VA',
        RegAddrZip: 22314,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '6929 ROLLING CREEK WAY',
        RegAddrCity: 'ALEXANDRIA',
        RegAddrState: 'VA',
        RegAddrZip: 22315,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '7240 CHERWELL LN',
        RegAddrCity: 'ALEXANDRIA',
        RegAddrState: 'VA',
        RegAddrZip: 22315,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '6855 ROLLING CREEK WAY',
        RegAddrCity: 'ALEXANDRIA',
        RegAddrState: 'VA',
        RegAddrZip: 22315,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '1626 N ADAMS ST',
        RegAddrCity: 'ARLINGTON',
        RegAddrState: 'VA',
        RegAddrZip: 22201,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '6035 20TH ST N',
        RegAddrCity: 'ARLINGTON',
        RegAddrState: 'VA',
        RegAddrZip: 22205,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '2108 N KENTUCKY ST',
        RegAddrCity: 'ARLINGTON',
        RegAddrState: 'VA',
        RegAddrZip: 22205,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '959 N MADISON ST',
        RegAddrCity: 'ARLINGTON',
        RegAddrState: 'VA',
        RegAddrZip: 22205,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '2801B S ABINGDON ST',
        RegAddrCity: 'ARLINGTON',
        RegAddrState: 'VA',
        RegAddrZip: 22206,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '3408 N VENICE ST',
        RegAddrCity: 'ARLINGTON',
        RegAddrState: 'VA',
        RegAddrZip: 22207,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '3303 DURBIN PL',
        RegAddrCity: 'FALLS CHURCH',
        RegAddrState: 'VA',
        RegAddrZip: 22041,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '2907 BROOK DR',
        RegAddrCity: 'FALLS CHURCH',
        RegAddrState: 'VA',
        RegAddrZip: 22042,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '6903 CEDARBROOKE CT',
        RegAddrCity: 'FALLS CHURCH',
        RegAddrState: 'VA',
        RegAddrZip: 22042,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '6805 SUPREME CT',
        RegAddrCity: 'SPRINGFIELD',
        RegAddrState: 'VA',
        RegAddrZip: 22150,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '6921 CABIN JOHN RD',
        RegAddrCity: 'SPRINGFIELD',
        RegAddrState: 'VA',
        RegAddrZip: 22150,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '7086 LEESTONE ST',
        RegAddrCity: 'SPRINGFIELD',
        RegAddrState: 'VA',
        RegAddrZip: 22151,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '7737 DURER CT',
        RegAddrCity: 'SPRINGFIELD',
        RegAddrState: 'VA',
        RegAddrZip: 22153,
        CongressionalDistrict: 8,
      },
    ],
  },
  {
    name: 'VA-9',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/VA-9?ref=gh-pages',
    state: 'VA',
    state_and_number: 'VA-9',
    zip: 24058,
    congressionalDistrict: 9,
    addressesInDistrict: [
      {
        RegAddrLine1: '368 ROBERTA ST SW',
        RegAddrCity: 'ABINGDON',
        RegAddrState: 'VA',
        RegAddrZip: 24210,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '194 HIGHLAND ST SE',
        RegAddrCity: 'ABINGDON',
        RegAddrState: 'VA',
        RegAddrZip: 24210,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '84 PINE GROVE RD',
        RegAddrCity: 'AUSTINVILLE',
        RegAddrState: 'VA',
        RegAddrZip: 24312,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '213 WESTOVER DR',
        RegAddrCity: 'BASSETT',
        RegAddrState: 'VA',
        RegAddrZip: 24055,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '615 14TH ST W',
        RegAddrCity: 'BIG STONE GAP',
        RegAddrState: 'VA',
        RegAddrZip: 24219,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '1326 NEW HAMPSHIRE AVE',
        RegAddrCity: 'BRISTOL',
        RegAddrState: 'VA',
        RegAddrZip: 24201,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '4218 POTTER RD',
        RegAddrCity: 'BRISTOL',
        RegAddrState: 'VA',
        RegAddrZip: 24202,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '22730 OSPREY RIDGE RD',
        RegAddrCity: 'BRISTOL',
        RegAddrState: 'VA',
        RegAddrZip: 24202,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '542 DIAMOND KNOB RD NE',
        RegAddrCity: 'CHECK',
        RegAddrState: 'VA',
        RegAddrZip: 24072,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '216 VANCE DR',
        RegAddrCity: 'CHILHOWIE',
        RegAddrState: 'VA',
        RegAddrZip: 24319,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '2325 PEAKLAND WAY',
        RegAddrCity: 'CHRISTIANSBRG',
        RegAddrState: 'VA',
        RegAddrZip: 24073,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '137 FALCON RIDGE RD',
        RegAddrCity: 'CLIFTON FORGE',
        RegAddrState: 'VA',
        RegAddrZip: 24422,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '1769 THE LAKE RD',
        RegAddrCity: 'CLINTWOOD',
        RegAddrState: 'VA',
        RegAddrZip: 24228,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '55 PARKWOOD CT',
        RegAddrCity: 'COLLINSVILLE',
        RegAddrState: 'VA',
        RegAddrZip: 24078,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '5854 OLD ROUTE 11',
        RegAddrCity: 'DUBLIN',
        RegAddrState: 'VA',
        RegAddrZip: 24084,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '1748 BIG STONEY CREEK RD',
        RegAddrCity: 'DUNGANNON',
        RegAddrState: 'VA',
        RegAddrZip: 24245,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '3630 CHURCH HILL RD',
        RegAddrCity: 'EGGLESTON',
        RegAddrState: 'VA',
        RegAddrZip: 24086,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '947 MARSON QUILLEN RD',
        RegAddrCity: 'EWING',
        RegAddrState: 'VA',
        RegAddrZip: 24248,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '457 FAIRVIEW CHURCH RD SW',
        RegAddrCity: 'FLOYD',
        RegAddrState: 'VA',
        RegAddrZip: 24091,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '763 WINDING RD',
        RegAddrCity: 'FRIES',
        RegAddrState: 'VA',
        RegAddrZip: 24330,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '2203 DEER HAVEN RD',
        RegAddrCity: 'GALAX',
        RegAddrState: 'VA',
        RegAddrZip: 24333,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '50 BRONCO RD',
        RegAddrCity: 'HILLSVILLE',
        RegAddrState: 'VA',
        RegAddrZip: 24343,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '144 JETT ST',
        RegAddrCity: 'HILLSVILLE',
        RegAddrState: 'VA',
        RegAddrZip: 24343,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '2403 REYNOLDS LN',
        RegAddrCity: 'HONAKER',
        RegAddrState: 'VA',
        RegAddrZip: 24260,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '4027 KNOX CREEK DR',
        RegAddrCity: 'HURLEY',
        RegAddrState: 'VA',
        RegAddrZip: 24620,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '106 SWEET APPLE LN',
        RegAddrCity: 'NEWPORT',
        RegAddrState: 'VA',
        RegAddrZip: 24128,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '413 EASTON RD',
        RegAddrCity: 'PEARISBURG',
        RegAddrState: 'VA',
        RegAddrZip: 24134,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '126 14TH ST NW',
        RegAddrCity: 'PULASKI',
        RegAddrState: 'VA',
        RegAddrZip: 24301,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '2001 4TH ST',
        RegAddrCity: 'RADFORD',
        RegAddrState: 'VA',
        RegAddrZip: 24141,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '2815 PAGE ST',
        RegAddrCity: 'RICHLANDS',
        RegAddrState: 'VA',
        RegAddrZip: 24641,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '15100 E HILL RD',
        RegAddrCity: 'SAINT PAUL',
        RegAddrState: 'VA',
        RegAddrZip: 24283,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '440 HIGHFIELD RD',
        RegAddrCity: 'SALEM',
        RegAddrState: 'VA',
        RegAddrZip: 24153,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '1008 ROANOKE BLVD',
        RegAddrCity: 'SALEM',
        RegAddrState: 'VA',
        RegAddrZip: 24153,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '120 PAR DR',
        RegAddrCity: 'SALEM',
        RegAddrState: 'VA',
        RegAddrZip: 24153,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '169 LICK SKILLET RD',
        RegAddrCity: 'SALTVILLE',
        RegAddrState: 'VA',
        RegAddrZip: 24370,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '263 JARVIS HOLLOW RD',
        RegAddrCity: 'SUGAR GROVE',
        RegAddrState: 'VA',
        RegAddrZip: 24375,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '7095 THOMPSON VALLEY RD',
        RegAddrCity: 'TAZEWELL',
        RegAddrState: 'VA',
        RegAddrZip: 24651,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '6405 YELLOW POPLAR ST',
        RegAddrCity: 'WISE',
        RegAddrState: 'VA',
        RegAddrZip: 24293,
        CongressionalDistrict: 9,
      },
    ],
  },
  {
    name: 'VI-0',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/VI-0?ref=gh-pages',
    state: 'VI',
    state_and_number: 'VI-0',
    congressionalDistrict: 0,
    addressesInDistrict: [],
  },
  {
    name: 'VT-0',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/VT-0?ref=gh-pages',
    state: 'VT',
    state_and_number: 'VT-0',
    zip: 5001,
    congressionalDistrict: 0,
    addressesInDistrict: [
      {
        RegAddrLine1: '**',
        RegAddrCity: 'ALBURG',
        RegAddrState: 'VT',
        RegAddrZip: 5440,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '656 US ROUTE 2 S',
        RegAddrCity: 'ALBURG',
        RegAddrState: 'VT',
        RegAddrZip: 5440,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '395 RED MOUNTAIN RD',
        RegAddrCity: 'ARLINGTON',
        RegAddrState: 'VT',
        RegAddrZip: 5250,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '259 HARRIS RD',
        RegAddrCity: 'BALTIMORE',
        RegAddrState: 'VT',
        RegAddrZip: 5143,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '6365 STAGE RD',
        RegAddrCity: 'BARNARD',
        RegAddrState: 'VT',
        RegAddrZip: 5031,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '121 INWOOD LN',
        RegAddrCity: 'BARNET',
        RegAddrState: 'VT',
        RegAddrZip: 5821,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '15 BROOK ST',
        RegAddrCity: 'BARRE',
        RegAddrState: 'VT',
        RegAddrZip: 5641,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '8 CRESCENT LN',
        RegAddrCity: 'BARRE',
        RegAddrState: 'VT',
        RegAddrZip: 5641,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '141 CHURCH ST',
        RegAddrCity: 'BARRE',
        RegAddrState: 'VT',
        RegAddrZip: 5641,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '103 MAPLEWOOD AVE',
        RegAddrCity: 'BARRE',
        RegAddrState: 'VT',
        RegAddrZip: 5641,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '80 PLEASANT ST',
        RegAddrCity: 'BARRE',
        RegAddrState: 'VT',
        RegAddrZip: 5641,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '99 BROOKLYN ST',
        RegAddrCity: 'BARRE',
        RegAddrState: 'VT',
        RegAddrZip: 5641,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '108 SUGARWOODS RD',
        RegAddrCity: 'BARRE',
        RegAddrState: 'VT',
        RegAddrZip: 5641,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '21 MCHUGH RD',
        RegAddrCity: 'BARRE TOWN',
        RegAddrState: 'VT',
        RegAddrZip: 5641,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '3307 BURTON HILL RD',
        RegAddrCity: 'BARTON',
        RegAddrState: 'VT',
        RegAddrZip: 5822,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '260 HUNT LN',
        RegAddrCity: 'BARTON',
        RegAddrState: 'VT',
        RegAddrZip: 5822,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '6 SHARON DR',
        RegAddrCity: 'BENNINGTON',
        RegAddrState: 'VT',
        RegAddrZip: 5201,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '214 PARK ST',
        RegAddrCity: 'BENNINGTON',
        RegAddrState: 'VT',
        RegAddrZip: 5201,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '31 MECHANIC ST',
        RegAddrCity: 'BENNINGTON',
        RegAddrState: 'VT',
        RegAddrZip: 5201,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '7 GREENVIEW DR',
        RegAddrCity: 'BENNINGTON',
        RegAddrState: 'VT',
        RegAddrZip: 5201,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '935 HOUGHTON LN',
        RegAddrCity: 'BENNINGTON',
        RegAddrState: 'VT',
        RegAddrZip: 5201,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '204 BEECH ST',
        RegAddrCity: 'BENNINGTON',
        RegAddrState: 'VT',
        RegAddrZip: 5201,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '4 WILLOW BROOK DR',
        RegAddrCity: 'BENNINGTON',
        RegAddrState: 'VT',
        RegAddrZip: 5201,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '207 CRESCENT BLVD',
        RegAddrCity: 'BENNINGTON',
        RegAddrState: 'VT',
        RegAddrZip: 5201,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '5 DUSTIN DR',
        RegAddrCity: 'BENNINGTON',
        RegAddrState: 'VT',
        RegAddrZip: 5201,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '125 CEDAR DR',
        RegAddrCity: 'BERLIN',
        RegAddrState: 'VT',
        RegAddrZip: 5641,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '123 POINT RIDGE RD',
        RegAddrCity: 'BERLIN',
        RegAddrState: 'VT',
        RegAddrZip: 5641,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '198 N MAIN ST',
        RegAddrCity: 'BETHEL',
        RegAddrState: 'VT',
        RegAddrZip: 5032,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '795 SANDERS RD',
        RegAddrCity: 'BETHEL',
        RegAddrState: 'VT',
        RegAddrZip: 5032,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '454 OLD STAGE COACH RD',
        RegAddrCity: 'BRADFORD',
        RegAddrState: 'VT',
        RegAddrZip: 5033,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: 'BEAR NOTCH RD',
        RegAddrCity: 'BRADFORD',
        RegAddrState: 'VT',
        RegAddrZip: 5033,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '298 CLOUGH RD',
        RegAddrCity: 'BRAINTREE',
        RegAddrState: 'VT',
        RegAddrZip: 5060,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '596 FLINT RD',
        RegAddrCity: 'BRAINTREE',
        RegAddrState: 'VT',
        RegAddrZip: 5060,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '2768 BENT HILL RD',
        RegAddrCity: 'BRAINTREE',
        RegAddrState: 'VT',
        RegAddrZip: 5060,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '557 HAWK VIEW RD',
        RegAddrCity: 'BRANDON',
        RegAddrState: 'VT',
        RegAddrZip: 5733,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '529 NORTH ST',
        RegAddrCity: 'BRANDON',
        RegAddrState: 'VT',
        RegAddrZip: 5733,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '67 SCHOOLHOUSE RD',
        RegAddrCity: 'BRANDON',
        RegAddrState: 'VT',
        RegAddrZip: 5733,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1063 WESTERN AVE',
        RegAddrCity: 'BRATTLEBORO',
        RegAddrState: 'VT',
        RegAddrZip: 5301,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '72 MORELAND AVE',
        RegAddrCity: 'BRATTLEBORO',
        RegAddrState: 'VT',
        RegAddrZip: 5301,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '99 SIGNAL HILL DR',
        RegAddrCity: 'BRATTLEBORO',
        RegAddrState: 'VT',
        RegAddrZip: 5301,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '34 CHERRY ST',
        RegAddrCity: 'BRATTLEBORO',
        RegAddrState: 'VT',
        RegAddrZip: 5301,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '202 PUTNEY RD',
        RegAddrCity: 'BRATTLEBORO',
        RegAddrState: 'VT',
        RegAddrZip: 5301,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '12 CAPY WAY',
        RegAddrCity: 'BRATTLEBORO',
        RegAddrState: 'VT',
        RegAddrZip: 5301,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '99 MARLBORO AVE',
        RegAddrCity: 'BRATTLEBORO',
        RegAddrState: 'VT',
        RegAddrZip: 5301,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '15 BUTTERFLY LN',
        RegAddrCity: 'BRATTLEBORO',
        RegAddrState: 'VT',
        RegAddrZip: 5301,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '84 BRETS RD',
        RegAddrCity: 'BRATTLEBORO',
        RegAddrState: 'VT',
        RegAddrZip: 5301,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '87 COVEY RD',
        RegAddrCity: 'BRATTLEBORO',
        RegAddrState: 'VT',
        RegAddrZip: 5301,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '80 MAPLE ST',
        RegAddrCity: 'BRATTLEBORO',
        RegAddrState: 'VT',
        RegAddrZip: 5301,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '161 HILLWINDS RD',
        RegAddrCity: 'BRATTLEBORO',
        RegAddrState: 'VT',
        RegAddrZip: 5301,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '45 SPENO CT',
        RegAddrCity: 'BRATTLEBORO',
        RegAddrState: 'VT',
        RegAddrZip: 5301,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '68 HIGHLAWN RD',
        RegAddrCity: 'BRATTLEBORO',
        RegAddrState: 'VT',
        RegAddrZip: 5301,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1724 EAST ST',
        RegAddrCity: 'BRIDPORT',
        RegAddrState: 'VT',
        RegAddrZip: 5734,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '410 FIDDLERS LN',
        RegAddrCity: 'BRIDPORT',
        RegAddrState: 'VT',
        RegAddrZip: 5734,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '789 VT ROUTE 125',
        RegAddrCity: 'BRIDPORT',
        RegAddrState: 'VT',
        RegAddrZip: 5734,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '379 DERBY ST',
        RegAddrCity: 'BRIGHTON',
        RegAddrState: 'VT',
        RegAddrZip: 5846,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '83 MOUNTAIN ST',
        RegAddrCity: 'BRISTOL',
        RegAddrState: 'VT',
        RegAddrZip: 5443,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '147 HEMLOCK DR',
        RegAddrCity: 'BRISTOL',
        RegAddrState: 'VT',
        RegAddrZip: 5443,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '288 WOODLAND DR',
        RegAddrCity: 'BRISTOL',
        RegAddrState: 'VT',
        RegAddrZip: 5443,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '11 LAFOUNTAIN ST',
        RegAddrCity: 'BURLINGTON',
        RegAddrState: 'VT',
        RegAddrZip: 5401,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '12 OAKBEACH DR',
        RegAddrCity: 'BURLINGTON',
        RegAddrState: 'VT',
        RegAddrZip: 5401,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '549 S WILLARD ST',
        RegAddrCity: 'BURLINGTON',
        RegAddrState: 'VT',
        RegAddrZip: 5401,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '222 NORTH ST',
        RegAddrCity: 'BURLINGTON',
        RegAddrState: 'VT',
        RegAddrZip: 5401,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '44 S UNION ST',
        RegAddrCity: 'BURLINGTON',
        RegAddrState: 'VT',
        RegAddrZip: 5401,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '82 NORTH ST',
        RegAddrCity: 'BURLINGTON',
        RegAddrState: 'VT',
        RegAddrZip: 5401,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '100 MANSFIELD AVE',
        RegAddrCity: 'BURLINGTON',
        RegAddrState: 'VT',
        RegAddrZip: 5401,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '16 HARBOR WATCH RD',
        RegAddrCity: 'BURLINGTON',
        RegAddrState: 'VT',
        RegAddrZip: 5401,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '62 BUELL ST',
        RegAddrCity: 'BURLINGTON',
        RegAddrState: 'VT',
        RegAddrZip: 5401,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '31 SCHOOL ST',
        RegAddrCity: 'BURLINGTON',
        RegAddrState: 'VT',
        RegAddrZip: 5401,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '359 SAINT PAUL ST',
        RegAddrCity: 'BURLINGTON',
        RegAddrState: 'VT',
        RegAddrZip: 5401,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '67 BUELL ST',
        RegAddrCity: 'BURLINGTON',
        RegAddrState: 'VT',
        RegAddrZip: 5401,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '82 PARK ST',
        RegAddrCity: 'BURLINGTON',
        RegAddrState: 'VT',
        RegAddrZip: 5401,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '50 BRIGHT ST',
        RegAddrCity: 'BURLINGTON',
        RegAddrState: 'VT',
        RegAddrZip: 5401,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '31 N WILLARD ST',
        RegAddrCity: 'BURLINGTON',
        RegAddrState: 'VT',
        RegAddrZip: 5401,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '295 MAPLE ST',
        RegAddrCity: 'BURLINGTON',
        RegAddrState: 'VT',
        RegAddrZip: 5401,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '51 N CHAMPLAIN ST',
        RegAddrCity: 'BURLINGTON',
        RegAddrState: 'VT',
        RegAddrZip: 5401,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '217 SAINT PAUL ST',
        RegAddrCity: 'BURLINGTON',
        RegAddrState: 'VT',
        RegAddrZip: 5401,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '179 BAYBERRY CIR',
        RegAddrCity: 'BURLINGTON',
        RegAddrState: 'VT',
        RegAddrZip: 5401,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '31 SPEAR ST',
        RegAddrCity: 'BURLINGTON',
        RegAddrState: 'VT',
        RegAddrZip: 5405,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '236 COLCHESTER AVE',
        RegAddrCity: 'BURLINGTON',
        RegAddrState: 'VT',
        RegAddrZip: 5405,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '95 WOODLAWN RD',
        RegAddrCity: 'BURLINGTON',
        RegAddrState: 'VT',
        RegAddrZip: 5408,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '953 NORTH AVE',
        RegAddrCity: 'BURLINGTON',
        RegAddrState: 'VT',
        RegAddrZip: 5408,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '123 DODDS CT',
        RegAddrCity: 'BURLINGTON',
        RegAddrState: 'VT',
        RegAddrZip: 5408,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '60 BEACHCREST DR',
        RegAddrCity: 'BURLINGTON',
        RegAddrState: 'VT',
        RegAddrZip: 5408,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '77 VENUS AVE',
        RegAddrCity: 'BURLINGTON',
        RegAddrState: 'VT',
        RegAddrZip: 5408,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '24 VAN PATTEN PKWY',
        RegAddrCity: 'BURLINGTON',
        RegAddrState: 'VT',
        RegAddrZip: 5408,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '90 LOALDO DR',
        RegAddrCity: 'BURLINGTON',
        RegAddrState: 'VT',
        RegAddrZip: 5408,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '60 VEST HAVEN DR',
        RegAddrCity: 'BURLINGTON',
        RegAddrState: 'VT',
        RegAddrZip: 5408,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '11 EDSON ST',
        RegAddrCity: 'BURLINGTON',
        RegAddrState: 'VT',
        RegAddrZip: 5408,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1123 COITS POND RD',
        RegAddrCity: 'CABOT',
        RegAddrState: 'VT',
        RegAddrZip: 5647,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '752 DUBRAY RD',
        RegAddrCity: 'CABOT',
        RegAddrState: 'VT',
        RegAddrZip: 5647,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '162 PLATEAU RD',
        RegAddrCity: 'CALAIS',
        RegAddrState: 'VT',
        RegAddrZip: 5648,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '758 BAYNE COMOLLI RD',
        RegAddrCity: 'CALAIS',
        RegAddrState: 'VT',
        RegAddrZip: 5648,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1926 STEBBINS RD',
        RegAddrCity: 'CAMBRIDGE',
        RegAddrState: 'VT',
        RegAddrZip: 5444,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '92 NOTCH GLEN DR',
        RegAddrCity: 'CAMBRIDGE',
        RegAddrState: 'VT',
        RegAddrZip: 5444,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '',
        RegAddrCity: 'CAMBRIDGE',
        RegAddrState: 'VT',
        RegAddrZip: 5444,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '607 VT ROUTE 114',
        RegAddrCity: 'CANAAN',
        RegAddrState: 'VT',
        RegAddrZip: 5903,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '2435 E HUBBARDTON RD',
        RegAddrCity: 'CASTLETON',
        RegAddrState: 'VT',
        RegAddrZip: 5735,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '717 BARKER HILL RD',
        RegAddrCity: 'CASTLETON',
        RegAddrState: 'VT',
        RegAddrZip: 5735,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '165 MAPLE RIDGE RD',
        RegAddrCity: 'CHARLESTON',
        RegAddrState: 'VT',
        RegAddrZip: 5833,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '191 MOUNTAINS EDGE',
        RegAddrCity: 'CHARLOTTE',
        RegAddrState: 'VT',
        RegAddrZip: 5445,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '15 DORSET ST',
        RegAddrCity: 'CHARLOTTE',
        RegAddrState: 'VT',
        RegAddrZip: 5445,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '177 PRINDLE RD',
        RegAddrCity: 'CHARLOTTE',
        RegAddrState: 'VT',
        RegAddrZip: 5445,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '358 MONKTON RD',
        RegAddrCity: 'CHARLOTTE',
        RegAddrState: 'VT',
        RegAddrZip: 5445,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '289 UPPER MEADOWS LN',
        RegAddrCity: 'CHARLOTTE',
        RegAddrState: 'VT',
        RegAddrZip: 5445,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '16 WASHINGTON TPKE',
        RegAddrCity: 'CHELSEA',
        RegAddrState: 'VT',
        RegAddrZip: 5038,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: 'PO BOX 215',
        RegAddrCity: 'CHESTER',
        RegAddrState: 'VT',
        RegAddrZip: 5143,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '165 CUMMINGS RD',
        RegAddrCity: 'CHESTER',
        RegAddrState: 'VT',
        RegAddrZip: 5143,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: 'PO BOX 363',
        RegAddrCity: 'CHESTER',
        RegAddrState: 'VT',
        RegAddrZip: 5143,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: 'PO BOX 59',
        RegAddrCity: 'CHESTER',
        RegAddrState: 'VT',
        RegAddrZip: 5143,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '265 CHURCH ST',
        RegAddrCity: 'CHESTER',
        RegAddrState: 'VT',
        RegAddrZip: 5143,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '14 POWERHOUSE RD',
        RegAddrCity: 'CHITTENDEN',
        RegAddrState: 'VT',
        RegAddrZip: 5737,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '102 CHITTENDEN RD',
        RegAddrCity: 'CHITTENDEN',
        RegAddrState: 'VT',
        RegAddrZip: 5737,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '102 REA JANET DR',
        RegAddrCity: 'COLCHESTER',
        RegAddrState: 'VT',
        RegAddrZip: 5446,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '601 CAMP KINIYA RD',
        RegAddrCity: 'COLCHESTER',
        RegAddrState: 'VT',
        RegAddrZip: 5446,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '62 NOTTINGHAM CT',
        RegAddrCity: 'COLCHESTER',
        RegAddrState: 'VT',
        RegAddrZip: 5446,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '553 CAMP KINIYA RD',
        RegAddrCity: 'COLCHESTER',
        RegAddrState: 'VT',
        RegAddrZip: 5446,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '418 BIRCHWOOD DR',
        RegAddrCity: 'COLCHESTER',
        RegAddrState: 'VT',
        RegAddrZip: 5446,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '141 RIDGE TOP WAY',
        RegAddrCity: 'COLCHESTER',
        RegAddrState: 'VT',
        RegAddrZip: 5446,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '642 SAND RD',
        RegAddrCity: 'COLCHESTER',
        RegAddrState: 'VT',
        RegAddrZip: 5446,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '748 SHORE ACRES DR',
        RegAddrCity: 'COLCHESTER',
        RegAddrState: 'VT',
        RegAddrZip: 5446,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '268 BAKER LN',
        RegAddrCity: 'CONCORD',
        RegAddrState: 'VT',
        RegAddrZip: 5824,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '5133 MAPLEWOOD RD CORINTH',
        RegAddrCity: 'CORINTH',
        RegAddrState: 'VT',
        RegAddrZip: 5039,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1273 PIKE HILL RD',
        RegAddrCity: 'CORINTH',
        RegAddrState: 'VT',
        RegAddrZip: 5039,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '657 N BINGHAM ST',
        RegAddrCity: 'CORNWALL',
        RegAddrState: 'VT',
        RegAddrZip: 5753,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '6259 ROUTE 30',
        RegAddrCity: 'CORNWALL',
        RegAddrState: 'VT',
        RegAddrZip: 5753,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '205 LARAMEE RD',
        RegAddrCity: 'COVENTRY',
        RegAddrState: 'VT',
        RegAddrZip: 5825,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '4842 VT ROUTE 14',
        RegAddrCity: 'CRAFTSBURY',
        RegAddrState: 'VT',
        RegAddrZip: 5826,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '369 FLORY HTS',
        RegAddrCity: 'CTR RUTLAND',
        RegAddrState: 'VT',
        RegAddrZip: 5736,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '623 CASCADE RD',
        RegAddrCity: 'DANBY',
        RegAddrState: 'VT',
        RegAddrZip: 5739,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '331 EXCELSIOR FARM RD',
        RegAddrCity: 'DANVILLE',
        RegAddrState: 'VT',
        RegAddrZip: 5828,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '103 LOUIS ST',
        RegAddrCity: 'DERBY',
        RegAddrState: 'VT',
        RegAddrZip: 5829,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: 'PO BOX 875',
        RegAddrCity: 'DERBY',
        RegAddrState: 'VT',
        RegAddrZip: 5829,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '3597 DARLING HILL RD',
        RegAddrCity: 'DERBY',
        RegAddrState: 'VT',
        RegAddrZip: 5829,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '296 BIRCH DR DERBY',
        RegAddrCity: 'DERBY',
        RegAddrState: 'VT',
        RegAddrZip: 5829,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '100 VT ROUTE 30',
        RegAddrCity: 'DORSET',
        RegAddrState: 'VT',
        RegAddrZip: 5251,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '171 MEADOW LN',
        RegAddrCity: 'DORSET',
        RegAddrState: 'VT',
        RegAddrZip: 5251,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '30 SNOW RD',
        RegAddrCity: 'DOVER',
        RegAddrState: 'VT',
        RegAddrZip: 5341,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '549 HAGUE RD',
        RegAddrCity: 'DUMMERSTON',
        RegAddrState: 'VT',
        RegAddrZip: 5301,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '3085 RIVER RD',
        RegAddrCity: 'DUXBURY',
        RegAddrState: 'VT',
        RegAddrZip: 5676,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '373 GREEN PEAK ORCH',
        RegAddrCity: 'E DORSET',
        RegAddrState: 'VT',
        RegAddrZip: 5253,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '319 NEW ST',
        RegAddrCity: 'E FAIRFIELD',
        RegAddrState: 'VT',
        RegAddrZip: 5448,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '20 KINGS ROW',
        RegAddrCity: 'E MIDDLEBURY',
        RegAddrState: 'VT',
        RegAddrZip: 5740,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1079 VT ROUTE 114',
        RegAddrCity: 'EAST HAVEN',
        RegAddrState: 'VT',
        RegAddrZip: 5837,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: 'PO BOX 188',
        RegAddrCity: 'EAST MONTPELIER',
        RegAddrState: 'VT',
        RegAddrZip: 5651,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: 'PO BOX 78',
        RegAddrCity: 'EAST SAINT JOHNSBURY',
        RegAddrState: 'VT',
        RegAddrZip: 5838,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1172 WHITE RD',
        RegAddrCity: 'EDEN',
        RegAddrState: 'VT',
        RegAddrZip: 5652,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '4459 ENOSBURG MTN RD',
        RegAddrCity: 'ENOSBURG FLS',
        RegAddrState: 'VT',
        RegAddrZip: 5450,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '5782 W BERKSHIRE RD',
        RegAddrCity: 'ENOSBURG FLS',
        RegAddrState: 'VT',
        RegAddrZip: 5450,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '62 BEECH ST',
        RegAddrCity: 'ESSEX JCT',
        RegAddrState: 'VT',
        RegAddrZip: 5452,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '17 ALDERBROOK RD',
        RegAddrCity: 'ESSEX JCT',
        RegAddrState: 'VT',
        RegAddrZip: 5452,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '14 PARK AVE',
        RegAddrCity: 'ESSEX JCT',
        RegAddrState: 'VT',
        RegAddrZip: 5452,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '4 STANNARD DR',
        RegAddrCity: 'ESSEX JCT',
        RegAddrState: 'VT',
        RegAddrZip: 5452,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '33 LINCOLN ST',
        RegAddrCity: 'ESSEX JCT',
        RegAddrState: 'VT',
        RegAddrZip: 5452,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '193 BROWNS RIVER RD',
        RegAddrCity: 'ESSEX JCT',
        RegAddrState: 'VT',
        RegAddrZip: 5452,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '114 JERICHO RD',
        RegAddrCity: 'ESSEX JCT',
        RegAddrState: 'VT',
        RegAddrZip: 5452,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '55 MARION AVE',
        RegAddrCity: 'ESSEX JCT',
        RegAddrState: 'VT',
        RegAddrZip: 5452,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '9 FULLER PL',
        RegAddrCity: 'ESSEX JCT',
        RegAddrState: 'VT',
        RegAddrZip: 5452,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '14 GREENBRIAR DR',
        RegAddrCity: 'ESSEX JCT',
        RegAddrState: 'VT',
        RegAddrZip: 5452,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '111 BEECH ST',
        RegAddrCity: 'ESSEX JCT',
        RegAddrState: 'VT',
        RegAddrZip: 5452,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '14 BLACKBERRY RD',
        RegAddrCity: 'ESSEX JCT',
        RegAddrState: 'VT',
        RegAddrZip: 5452,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '27 2ND ST',
        RegAddrCity: 'FAIR HAVEN',
        RegAddrState: 'VT',
        RegAddrZip: 5743,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '68 MONTGIVEN AVE',
        RegAddrCity: 'FAIR HAVEN',
        RegAddrState: 'VT',
        RegAddrZip: 5743,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '19 WIGGINS RD',
        RegAddrCity: 'FAIRFAX',
        RegAddrState: 'VT',
        RegAddrZip: 5454,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '161 MEADE RD',
        RegAddrCity: 'FAIRFAX',
        RegAddrState: 'VT',
        RegAddrZip: 5454,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '814 BUCK HOLLOW RD',
        RegAddrCity: 'FAIRFAX',
        RegAddrState: 'VT',
        RegAddrZip: 5454,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '40 W STREET RD',
        RegAddrCity: 'FAIRFAX',
        RegAddrState: 'VT',
        RegAddrZip: 5454,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '628 BUCK HOLLOW RD',
        RegAddrCity: 'FAIRFAX',
        RegAddrState: 'VT',
        RegAddrZip: 5454,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '349 SAM WEBB RD',
        RegAddrCity: 'FAIRFAX',
        RegAddrState: 'VT',
        RegAddrZip: 5454,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '4367 VT ROUTE 36',
        RegAddrCity: 'FAIRFIELD',
        RegAddrState: 'VT',
        RegAddrZip: 5455,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '2487 DODD RD',
        RegAddrCity: 'FAIRFIELD',
        RegAddrState: 'VT',
        RegAddrZip: 5455,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '2139 SHENANG RD',
        RegAddrCity: 'FAIRFIELD',
        RegAddrState: 'VT',
        RegAddrZip: 5455,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1311 RUGG RD',
        RegAddrCity: 'FAIRFIELD',
        RegAddrState: 'VT',
        RegAddrZip: 5455,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '26 BRIDGE ST',
        RegAddrCity: 'FAIRLEE',
        RegAddrState: 'VT',
        RegAddrZip: 5045,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '843 TERRY HILL RD',
        RegAddrCity: 'FAIRLEE',
        RegAddrState: 'VT',
        RegAddrZip: 5045,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '99 MOUNTAIN MDWS',
        RegAddrCity: 'FERRISBURGH',
        RegAddrState: 'VT',
        RegAddrZip: 5456,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1034 RILEY RD',
        RegAddrCity: 'FRANKLIN',
        RegAddrState: 'VT',
        RegAddrZip: 5457,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '911 GEORGIA MTN RD',
        RegAddrCity: 'GEORGIA',
        RegAddrState: 'VT',
        RegAddrZip: 5454,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '514 NOTTINGHAM DR',
        RegAddrCity: 'GEORGIA',
        RegAddrState: 'VT',
        RegAddrZip: 5468,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '227 FONTAINE DR',
        RegAddrCity: 'GEORGIA',
        RegAddrState: 'VT',
        RegAddrZip: 5468,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '80 PARENT FARM VIEW LN',
        RegAddrCity: 'GEORGIA',
        RegAddrState: 'VT',
        RegAddrZip: 5468,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '5563 GEORGIA SHORE RD',
        RegAddrCity: 'GEORGIA',
        RegAddrState: 'VT',
        RegAddrZip: 5478,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1758 REYNOLDS RD',
        RegAddrCity: 'GEORGIA',
        RegAddrState: 'VT',
        RegAddrZip: 5478,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1700 BAPTIST HL',
        RegAddrCity: 'GILMAN',
        RegAddrState: 'VT',
        RegAddrZip: 5904,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1296 ROUTE 121 E',
        RegAddrCity: 'GRAFTON',
        RegAddrState: 'VT',
        RegAddrZip: 5146,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '65 PEARL ST',
        RegAddrCity: 'GRAND ISLE',
        RegAddrState: 'VT',
        RegAddrZip: 5458,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '46 ORCHARD TER',
        RegAddrCity: 'GRANITEVILLE',
        RegAddrState: 'VT',
        RegAddrZip: 5654,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '4814 VT ROUTE 100',
        RegAddrCity: 'GRANVILLE',
        RegAddrState: 'VT',
        RegAddrZip: 5747,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '295 ROBINSON RD',
        RegAddrCity: 'GROTON',
        RegAddrState: 'VT',
        RegAddrZip: 5046,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1043 MOSQUITOVILLE RD',
        RegAddrCity: 'GROTON',
        RegAddrState: 'VT',
        RegAddrZip: 5046,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '4057 HINESBURG RD',
        RegAddrCity: 'GUILFORD',
        RegAddrState: 'VT',
        RegAddrZip: 5301,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1054 WEATHERHEAD HOLLOW RD',
        RegAddrCity: 'GUILFORD',
        RegAddrState: 'VT',
        RegAddrZip: 5301,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '223 MILL ST',
        RegAddrCity: 'HARDWICK',
        RegAddrState: 'VT',
        RegAddrZip: 5843,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '42 CAREY RD',
        RegAddrCity: 'HARDWICK',
        RegAddrState: 'VT',
        RegAddrZip: 5843,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: 'PO BOX 1029',
        RegAddrCity: 'HARDWICK',
        RegAddrState: 'VT',
        RegAddrZip: 5843,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '664 OLD RIVER RD',
        RegAddrCity: 'HARTFORD',
        RegAddrState: 'VT',
        RegAddrZip: 5047,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '11 SYCAMORE DR',
        RegAddrCity: 'HARTFORD',
        RegAddrState: 'VT',
        RegAddrZip: 5047,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: 'PO BOX 511',
        RegAddrCity: 'HARTFORD',
        RegAddrState: 'VT',
        RegAddrZip: 5047,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1613 VT ROUTE 14',
        RegAddrCity: 'HARTFORD',
        RegAddrState: 'VT',
        RegAddrZip: 5047,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '77 DEPOT RD',
        RegAddrCity: 'HARTLAND',
        RegAddrState: 'VT',
        RegAddrZip: 5048,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '515 QUECHEE RD',
        RegAddrCity: 'HARTLAND',
        RegAddrState: 'VT',
        RegAddrZip: 5048,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '45 STIMPSON HL',
        RegAddrCity: 'HARTLAND',
        RegAddrState: 'VT',
        RegAddrZip: 5048,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '140 PENNY LN',
        RegAddrCity: 'HIGHGATE',
        RegAddrState: 'VT',
        RegAddrZip: 5459,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '78 LYMAN PARK RD',
        RegAddrCity: 'HINESBURG',
        RegAddrState: 'VT',
        RegAddrZip: 5461,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '12005 ROUTE 116',
        RegAddrCity: 'HINESBURG',
        RegAddrState: 'VT',
        RegAddrZip: 5461,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '13312 ROUTE 116',
        RegAddrCity: 'HINESBURG',
        RegAddrState: 'VT',
        RegAddrZip: 5461,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '341 MECHANICSVILLE RD',
        RegAddrCity: 'HINESBURG',
        RegAddrState: 'VT',
        RegAddrZip: 5461,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '86 HINESBURG HOLLOW RD',
        RegAddrCity: 'HUNTINGTON',
        RegAddrState: 'VT',
        RegAddrZip: 5462,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '5211 VT 100',
        RegAddrCity: 'HYDE PARK',
        RegAddrState: 'VT',
        RegAddrZip: 5655,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '97 EDEN ST',
        RegAddrCity: 'HYDE PARK',
        RegAddrState: 'VT',
        RegAddrZip: 5655,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '148 EBENEZER PL',
        RegAddrCity: 'HYDE PARK',
        RegAddrState: 'VT',
        RegAddrZip: 5655,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: 'PO BOX 44',
        RegAddrCity: 'ISLAND POND',
        RegAddrState: 'VT',
        RegAddrZip: 5846,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '415 PINNACLE LN',
        RegAddrCity: 'JAMAICA',
        RegAddrState: 'VT',
        RegAddrZip: 5343,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '255 JAY PEAK VILLAGE RD',
        RegAddrCity: 'JAY PEAK',
        RegAddrState: 'VT',
        RegAddrZip: 5859,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '296 CRAIG LN',
        RegAddrCity: 'JEFFERSONVLLE',
        RegAddrState: 'VT',
        RegAddrZip: 5464,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '254 LABARRON LN',
        RegAddrCity: 'JEFFERSONVLLE',
        RegAddrState: 'VT',
        RegAddrZip: 5464,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '2183 BARTLETT HILL RD',
        RegAddrCity: 'JEFFERSONVLLE',
        RegAddrState: 'VT',
        RegAddrZip: 5464,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '146 PACKARD RD',
        RegAddrCity: 'JERICHO',
        RegAddrState: 'VT',
        RegAddrZip: 5465,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '42 BOLGER HILL RD',
        RegAddrCity: 'JERICHO',
        RegAddrState: 'VT',
        RegAddrZip: 5465,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '505 COLLEGE HL GOVERNORS 352',
        RegAddrCity: 'JOHNSON',
        RegAddrState: 'VT',
        RegAddrZip: 5656,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '337 COLLEGE HL',
        RegAddrCity: 'JOHNSON',
        RegAddrState: 'VT',
        RegAddrZip: 5656,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '970 GOULD HL',
        RegAddrCity: 'JOHNSON',
        RegAddrState: 'VT',
        RegAddrZip: 5656,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '536 E MOUNTAIN RD',
        RegAddrCity: 'KILLINGTON',
        RegAddrState: 'VT',
        RegAddrZip: 5751,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '487 DEAN HILL RD',
        RegAddrCity: 'KILLINGTON',
        RegAddrState: 'VT',
        RegAddrZip: 5751,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1852 GOODALEVILLE RD',
        RegAddrCity: 'LONDONDERRY',
        RegAddrState: 'VT',
        RegAddrZip: 5148,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '25 SOUTHER RD',
        RegAddrCity: 'LOWELL',
        RegAddrState: 'VT',
        RegAddrZip: 5847,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '660 HAZEN NOTCH RD',
        RegAddrCity: 'LOWELL',
        RegAddrState: 'VT',
        RegAddrZip: 5847,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '744 S HILL RD',
        RegAddrCity: 'LUDLOW',
        RegAddrState: 'VT',
        RegAddrZip: 5149,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '7 CARPENTERS POINT RD',
        RegAddrCity: 'LUDLOW',
        RegAddrState: 'VT',
        RegAddrZip: 5149,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '23 PLEASANT ST EXT',
        RegAddrCity: 'LUDLOW',
        RegAddrState: 'VT',
        RegAddrZip: 5149,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '32 S LUNENBURG RD',
        RegAddrCity: 'LUNENBURG',
        RegAddrState: 'VT',
        RegAddrZip: 5906,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '350 TOBYNE RD',
        RegAddrCity: 'LUNENBURG',
        RegAddrState: 'VT',
        RegAddrZip: 5906,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '27 SLATE LN',
        RegAddrCity: 'LYNDON',
        RegAddrState: 'VT',
        RegAddrZip: 5849,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '98 DARLING HILL RD',
        RegAddrCity: 'LYNDON',
        RegAddrState: 'VT',
        RegAddrZip: 5849,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '92 BOURN BROOK RD',
        RegAddrCity: 'MANCHESTER',
        RegAddrState: 'VT',
        RegAddrZip: 5254,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '646 RICHVILLE RD',
        RegAddrCity: 'MANCHESTER',
        RegAddrState: 'VT',
        RegAddrZip: 5254,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: 'PO BOX 423',
        RegAddrCity: 'MANCHESTER CENTER',
        RegAddrState: 'VT',
        RegAddrZip: 5255,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '53 ELM ST',
        RegAddrCity: 'MANCHESTR CTR',
        RegAddrState: 'VT',
        RegAddrZip: 5255,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '36 AUGUR HOLE RD',
        RegAddrCity: 'MARLBORO',
        RegAddrState: 'VT',
        RegAddrZip: 5344,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '147 MOOSEWOOD RDG',
        RegAddrCity: 'MARLBORO',
        RegAddrState: 'VT',
        RegAddrZip: 5344,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1887 GRANT RD',
        RegAddrCity: 'MARLBORO',
        RegAddrState: 'VT',
        RegAddrZip: 5344,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '5 GRIST MILL RD',
        RegAddrCity: 'MIDDLEBURY',
        RegAddrState: 'VT',
        RegAddrZip: 5753,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '16 SYDNEY WAY',
        RegAddrCity: 'MIDDLEBURY',
        RegAddrState: 'VT',
        RegAddrZip: 5753,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '804 CASE ST',
        RegAddrCity: 'MIDDLEBURY',
        RegAddrState: 'VT',
        RegAddrZip: 5753,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '456 WEYBRIDGE ST',
        RegAddrCity: 'MIDDLEBURY',
        RegAddrState: 'VT',
        RegAddrZip: 5753,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '35 WRENWOOD LN',
        RegAddrCity: 'MIDDLESEX',
        RegAddrState: 'VT',
        RegAddrZip: 5602,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '693 W HILL RD',
        RegAddrCity: 'MIDDLESEX',
        RegAddrState: 'VT',
        RegAddrZip: 5602,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '70 WESTFORD RD',
        RegAddrCity: 'MILTON',
        RegAddrState: 'VT',
        RegAddrZip: 5468,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '77 MEADOW RD',
        RegAddrCity: 'MILTON',
        RegAddrState: 'VT',
        RegAddrZip: 5468,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '17 PINNACLE DR',
        RegAddrCity: 'MILTON',
        RegAddrState: 'VT',
        RegAddrZip: 5468,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '145 COBBLE HILL RD',
        RegAddrCity: 'MILTON',
        RegAddrState: 'VT',
        RegAddrZip: 5468,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '191 MCMULLEN RD',
        RegAddrCity: 'MILTON',
        RegAddrState: 'VT',
        RegAddrZip: 5468,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '36 LAMOILLE TER',
        RegAddrCity: 'MILTON',
        RegAddrState: 'VT',
        RegAddrZip: 5468,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '3565 S MAIN ST',
        RegAddrCity: 'MONTGOMERY',
        RegAddrState: 'VT',
        RegAddrZip: 5470,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '3056 BLACK FALLS RD',
        RegAddrCity: 'MONTGOMERY',
        RegAddrState: 'VT',
        RegAddrZip: 5470,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '568 N HILL RD',
        RegAddrCity: 'MONTGOMERY',
        RegAddrState: 'VT',
        RegAddrZip: 5470,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: 'PO BOX 292',
        RegAddrCity: 'MONTGOMERY CENTER',
        RegAddrState: 'VT',
        RegAddrZip: 5471,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '2949 S MAIN ST',
        RegAddrCity: 'MONTGOMRY CTR',
        RegAddrState: 'VT',
        RegAddrZip: 5471,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '77 BAILEY AVE',
        RegAddrCity: 'MONTPELIER',
        RegAddrState: 'VT',
        RegAddrZip: 5602,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '35 LIBERTY ST',
        RegAddrCity: 'MONTPELIER',
        RegAddrState: 'VT',
        RegAddrZip: 5602,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '644 NORTH ST',
        RegAddrCity: 'MONTPELIER',
        RegAddrState: 'VT',
        RegAddrZip: 5602,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '17 ISABEL CIR',
        RegAddrCity: 'MONTPELIER',
        RegAddrState: 'VT',
        RegAddrZip: 5602,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '74 CLARENDON AVE',
        RegAddrCity: 'MONTPELIER',
        RegAddrState: 'VT',
        RegAddrZip: 5602,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '45 SUMMER ST',
        RegAddrCity: 'MONTPELIER',
        RegAddrState: 'VT',
        RegAddrZip: 5602,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '104 BARRE ST',
        RegAddrCity: 'MONTPELIER',
        RegAddrState: 'VT',
        RegAddrZip: 5602,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1234 JONES BROOK RD',
        RegAddrCity: 'MORETOWN',
        RegAddrState: 'VT',
        RegAddrZip: 5660,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '345 BROOKLYN ST',
        RegAddrCity: 'MORRISTOWN',
        RegAddrState: 'VT',
        RegAddrZip: 5661,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '207 MAPLE ST',
        RegAddrCity: 'MORRISTOWN',
        RegAddrState: 'VT',
        RegAddrZip: 5661,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1058 STAGECOACH RD',
        RegAddrCity: 'MORRISTOWN',
        RegAddrState: 'VT',
        RegAddrZip: 5661,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '590 OLD TURNPIKE RD',
        RegAddrCity: 'MOUNT HOLLY',
        RegAddrState: 'VT',
        RegAddrZip: 5758,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '540 VT ROUTE 7B N',
        RegAddrCity: 'N CLARENDON',
        RegAddrState: 'VT',
        RegAddrZip: 5759,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '2400 EAST ST',
        RegAddrCity: 'N CLARENDON',
        RegAddrState: 'VT',
        RegAddrZip: 5759,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '61 SOUTH ST',
        RegAddrCity: 'NEW HAVEN',
        RegAddrState: 'VT',
        RegAddrZip: 5472,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '2332 BOWEN RD',
        RegAddrCity: 'NEWBURY',
        RegAddrState: 'VT',
        RegAddrZip: 5051,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '4963 MAIN ST S',
        RegAddrCity: 'NEWBURY',
        RegAddrState: 'VT',
        RegAddrZip: 5051,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '17 VT ROUTE 30',
        RegAddrCity: 'NEWFANE',
        RegAddrState: 'VT',
        RegAddrZip: 5345,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '95 PINE GROVE CEMETARY RD',
        RegAddrCity: 'NEWPORT CITY',
        RegAddrState: 'VT',
        RegAddrZip: 5855,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '52 BLANCHARD AVE',
        RegAddrCity: 'NEWPORT CITY',
        RegAddrState: 'VT',
        RegAddrZip: 5855,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '844 LEADVILLE RD',
        RegAddrCity: 'NEWPORT CTR',
        RegAddrState: 'VT',
        RegAddrZip: 5857,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '106 OVERLOOK DR',
        RegAddrCity: 'NEWPORT CTR',
        RegAddrState: 'VT',
        RegAddrZip: 5857,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '22 SUNSET DR',
        RegAddrCity: 'NORTH BENNINGTON',
        RegAddrState: 'VT',
        RegAddrZip: 5257,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '245 S MAIN ST',
        RegAddrCity: 'NORTHFIELD',
        RegAddrState: 'VT',
        RegAddrZip: 5663,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '66 MILL ST',
        RegAddrCity: 'NORTHFIELD',
        RegAddrState: 'VT',
        RegAddrZip: 5663,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '43 KOCH RD',
        RegAddrCity: 'NORWICH',
        RegAddrState: 'VT',
        RegAddrZip: 5055,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '47 OLD COACH RD',
        RegAddrCity: 'NORWICH',
        RegAddrState: 'VT',
        RegAddrZip: 5055,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '15 NEEDHAM HILL RD',
        RegAddrCity: 'ORWELL',
        RegAddrState: 'VT',
        RegAddrZip: 5760,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '105 GRISWOLD LN',
        RegAddrCity: 'ORWELL',
        RegAddrState: 'VT',
        RegAddrZip: 5760,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '354 BASIN HARBOR RD',
        RegAddrCity: 'PANTON',
        RegAddrState: 'VT',
        RegAddrZip: 5491,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '308 SARGENT HL',
        RegAddrCity: 'PAWLET',
        RegAddrState: 'VT',
        RegAddrZip: 5761,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1087 E PEACHAM RD',
        RegAddrCity: 'PEACHAM',
        RegAddrState: 'VT',
        RegAddrZip: 5862,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '42 GREAT RD',
        RegAddrCity: 'PEACHAM',
        RegAddrState: 'VT',
        RegAddrZip: 5862,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '4175 VT ROUTE 100',
        RegAddrCity: 'PITTSFIELD',
        RegAddrState: 'VT',
        RegAddrZip: 5762,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '3925 ROUTE 100',
        RegAddrCity: 'PITTSFIELD',
        RegAddrState: 'VT',
        RegAddrZip: 5762,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '46 ALPINE ACRES',
        RegAddrCity: 'PITTSFORD',
        RegAddrState: 'VT',
        RegAddrZip: 5763,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '4595 E HILL RD',
        RegAddrCity: 'PLAINFIELD',
        RegAddrState: 'VT',
        RegAddrZip: 5667,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '190 NORTHWOOD DR',
        RegAddrCity: 'PLAINFIELD',
        RegAddrState: 'VT',
        RegAddrZip: 5667,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '203 GONYEAU RD',
        RegAddrCity: 'PLAINFIELD',
        RegAddrState: 'VT',
        RegAddrZip: 5667,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '107 CHURCH ST',
        RegAddrCity: 'POULTNEY',
        RegAddrState: 'VT',
        RegAddrZip: 5764,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '181 HILLSIDE RD',
        RegAddrCity: 'POULTNEY',
        RegAddrState: 'VT',
        RegAddrZip: 5764,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '763 E MAIN ST',
        RegAddrCity: 'POULTNEY',
        RegAddrState: 'VT',
        RegAddrZip: 5764,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1105 E MAIN ST',
        RegAddrCity: 'POULTNEY',
        RegAddrState: 'VT',
        RegAddrZip: 5764,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '399 VT ROUTE 30 S',
        RegAddrCity: 'POULTNEY',
        RegAddrState: 'VT',
        RegAddrZip: 5764,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '280 MAIN ST',
        RegAddrCity: 'POULTNEY',
        RegAddrState: 'VT',
        RegAddrZip: 5764,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '606 CARPENTER HILL RD W',
        RegAddrCity: 'POWNAL',
        RegAddrState: 'VT',
        RegAddrZip: 5261,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1906 CARPENTER HILL RD',
        RegAddrCity: 'POWNAL',
        RegAddrState: 'VT',
        RegAddrZip: 5261,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '256 ORCHARD ST',
        RegAddrCity: 'POWNAL',
        RegAddrState: 'VT',
        RegAddrZip: 5261,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '3199 VT ROUTE 346',
        RegAddrCity: 'POWNAL',
        RegAddrState: 'VT',
        RegAddrZip: 5261,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '35 PINE CIR',
        RegAddrCity: 'POWNAL',
        RegAddrState: 'VT',
        RegAddrZip: 5261,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '49 GREEN MOUNTAIN TRAILER PARK',
        RegAddrCity: 'POWNAL',
        RegAddrState: 'VT',
        RegAddrZip: 5261,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '866 MAIN ST',
        RegAddrCity: 'PROCTORSVILLE',
        RegAddrState: 'VT',
        RegAddrZip: 5153,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '16 SAND HILL RD',
        RegAddrCity: 'PUTNEY',
        RegAddrState: 'VT',
        RegAddrZip: 5346,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '3093 RIDGE RD',
        RegAddrCity: 'RANDOLPH CTR',
        RegAddrState: 'VT',
        RegAddrZip: 5061,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '93 DEPOT ST',
        RegAddrCity: 'READSBORO',
        RegAddrState: 'VT',
        RegAddrZip: 5350,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '925 N BRANCH RD',
        RegAddrCity: 'RICHFORD',
        RegAddrState: 'VT',
        RegAddrZip: 5476,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '407 DUGWAY RD',
        RegAddrCity: 'RICHMOND',
        RegAddrState: 'VT',
        RegAddrZip: 5477,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1094 SOUTHVIEW DR',
        RegAddrCity: 'RICHMOND',
        RegAddrState: 'VT',
        RegAddrZip: 5477,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '55 THOMPSON RD',
        RegAddrCity: 'RICHMOND',
        RegAddrState: 'VT',
        RegAddrZip: 5477,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '193 WENTWORTH RD',
        RegAddrCity: 'RICHMOND',
        RegAddrState: 'VT',
        RegAddrZip: 5477,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '155 LOWER CIR',
        RegAddrCity: 'RICHMOND',
        RegAddrState: 'VT',
        RegAddrZip: 5477,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '52 CHURCH ST',
        RegAddrCity: 'RICHMOND',
        RegAddrState: 'VT',
        RegAddrZip: 5477,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '125 PEAVINE DR',
        RegAddrCity: 'ROCHESTER',
        RegAddrState: 'VT',
        RegAddrZip: 5767,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '33 SOUTH ST',
        RegAddrCity: 'ROCKINGHAM',
        RegAddrState: 'VT',
        RegAddrZip: 5101,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '10 MAIN ST',
        RegAddrCity: 'ROCKINGHAM',
        RegAddrState: 'VT',
        RegAddrZip: 5101,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '2 MILL ST SAXTONS RIV',
        RegAddrCity: 'ROCKINGHAM',
        RegAddrState: 'VT',
        RegAddrZip: 5101,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '66 ROUTE 315',
        RegAddrCity: 'RUPERT',
        RegAddrState: 'VT',
        RegAddrZip: 5768,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '36 CATHERINE DR',
        RegAddrCity: 'RUTLAND',
        RegAddrState: 'VT',
        RegAddrZip: 5701,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '51 PIEDMONT POND RD',
        RegAddrCity: 'RUTLAND',
        RegAddrState: 'VT',
        RegAddrZip: 5701,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '99 FOREST ST',
        RegAddrCity: 'RUTLAND',
        RegAddrState: 'VT',
        RegAddrZip: 5701,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '59 MEADOW ST',
        RegAddrCity: 'RUTLAND',
        RegAddrState: 'VT',
        RegAddrZip: 5701,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '12 VICTOR PL',
        RegAddrCity: 'RUTLAND',
        RegAddrState: 'VT',
        RegAddrZip: 5701,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '132 KILLINGTON AVE',
        RegAddrCity: 'RUTLAND',
        RegAddrState: 'VT',
        RegAddrZip: 5701,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '159 1/2 GRANGER ST',
        RegAddrCity: 'RUTLAND',
        RegAddrState: 'VT',
        RegAddrZip: 5701,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '200 LINCOLN AVE',
        RegAddrCity: 'RUTLAND',
        RegAddrState: 'VT',
        RegAddrZip: 5701,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '373 WEST ST',
        RegAddrCity: 'RUTLAND',
        RegAddrState: 'VT',
        RegAddrZip: 5701,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '43 KENDALL AVE',
        RegAddrCity: 'RUTLAND',
        RegAddrState: 'VT',
        RegAddrZip: 5701,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '29 LAFAYETTE ST',
        RegAddrCity: 'RUTLAND',
        RegAddrState: 'VT',
        RegAddrZip: 5701,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '10 CHARLES ST',
        RegAddrCity: 'RUTLAND',
        RegAddrState: 'VT',
        RegAddrZip: 5701,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '147 FOREST ST',
        RegAddrCity: 'RUTLAND',
        RegAddrState: 'VT',
        RegAddrZip: 5701,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '40 CAMPBELL RD',
        RegAddrCity: 'RUTLAND',
        RegAddrState: 'VT',
        RegAddrZip: 5701,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '516 MCKINLEY AVE',
        RegAddrCity: 'RUTLAND TOWN',
        RegAddrState: 'VT',
        RegAddrZip: 5701,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '999 VICTORIA DR',
        RegAddrCity: 'RUTLAND TOWN',
        RegAddrState: 'VT',
        RegAddrZip: 5701,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1514 POST RD',
        RegAddrCity: 'RUTLAND TOWN',
        RegAddrState: 'VT',
        RegAddrZip: 5701,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '',
        RegAddrCity: 'RYEGATE',
        RegAddrState: 'VT',
        RegAddrZip: 5042,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '18 STONINGTON CIR',
        RegAddrCity: 'S BURLINGTON',
        RegAddrState: 'VT',
        RegAddrZip: 5403,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '12 EAST TER',
        RegAddrCity: 'S BURLINGTON',
        RegAddrState: 'VT',
        RegAddrZip: 5403,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '30 PINE ST',
        RegAddrCity: 'S BURLINGTON',
        RegAddrState: 'VT',
        RegAddrZip: 5403,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '258 FOUR SISTERS RD',
        RegAddrCity: 'S BURLINGTON',
        RegAddrState: 'VT',
        RegAddrZip: 5403,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '35 SHERRY RD',
        RegAddrCity: 'S BURLINGTON',
        RegAddrState: 'VT',
        RegAddrZip: 5403,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '4 RICHARD TER',
        RegAddrCity: 'S BURLINGTON',
        RegAddrState: 'VT',
        RegAddrZip: 5403,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '27 PHEASANT WAY',
        RegAddrCity: 'S BURLINGTON',
        RegAddrState: 'VT',
        RegAddrZip: 5403,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '101 ELDREDGE ST',
        RegAddrCity: 'S BURLINGTON',
        RegAddrState: 'VT',
        RegAddrZip: 5403,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '107 JOHN FAY RD',
        RegAddrCity: 'S BURLINGTON',
        RegAddrState: 'VT',
        RegAddrZip: 5403,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '16 MARYLAND ST',
        RegAddrCity: 'S BURLINGTON',
        RegAddrState: 'VT',
        RegAddrZip: 5403,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1 OLDE ORCHARD PARK',
        RegAddrCity: 'S BURLINGTON',
        RegAddrState: 'VT',
        RegAddrZip: 5403,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '45 STONINGTON CIR',
        RegAddrCity: 'S BURLINGTON',
        RegAddrState: 'VT',
        RegAddrZip: 5403,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '102 QUARRY HILL RD',
        RegAddrCity: 'S BURLINGTON',
        RegAddrState: 'VT',
        RegAddrZip: 5403,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '12 LILY LN',
        RegAddrCity: 'S BURLINGTON',
        RegAddrState: 'VT',
        RegAddrZip: 5403,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1 GILBERT ST',
        RegAddrCity: 'S BURLINGTON',
        RegAddrState: 'VT',
        RegAddrZip: 5403,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '25 BAYCREST DR',
        RegAddrCity: 'S BURLINGTON',
        RegAddrState: 'VT',
        RegAddrZip: 5403,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '19 FLORAL ST',
        RegAddrCity: 'S BURLINGTON',
        RegAddrState: 'VT',
        RegAddrZip: 5403,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '71 HINESBURG RD',
        RegAddrCity: 'S BURLINGTON',
        RegAddrState: 'VT',
        RegAddrZip: 5403,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '3156 WINHALL HOLLOW RD',
        RegAddrCity: 'S LONDONDERRY',
        RegAddrState: 'VT',
        RegAddrZip: 5155,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '115 FAIRVIEW TER',
        RegAddrCity: 'S ROYALTON',
        RegAddrState: 'VT',
        RegAddrZip: 5068,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1501 S WINDSOR ST',
        RegAddrCity: 'S ROYALTON',
        RegAddrState: 'VT',
        RegAddrZip: 5068,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1311 VT ROUTE 110',
        RegAddrCity: 'S ROYALTON',
        RegAddrState: 'VT',
        RegAddrZip: 5068,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '131 S WINDSOR ST',
        RegAddrCity: 'S ROYALTON',
        RegAddrState: 'VT',
        RegAddrZip: 5068,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '306 S MAIN ST',
        RegAddrCity: 'SAINT ALBANS',
        RegAddrState: 'VT',
        RegAddrZip: 5478,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '2124 SHELDON RD',
        RegAddrCity: 'SAINT ALBANS',
        RegAddrState: 'VT',
        RegAddrZip: 5478,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '34 N MAIN ST',
        RegAddrCity: 'SAINT ALBANS',
        RegAddrState: 'VT',
        RegAddrZip: 5478,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1195 LOWER NEWTON RD',
        RegAddrCity: 'SAINT ALBANS',
        RegAddrState: 'VT',
        RegAddrZip: 5478,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '526 HARBOR VIEW DR',
        RegAddrCity: 'SAINT ALBANS',
        RegAddrState: 'VT',
        RegAddrZip: 5478,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '60 HUNTINGTON ST',
        RegAddrCity: 'SAINT ALBANS',
        RegAddrState: 'VT',
        RegAddrZip: 5478,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '235 LAKE ST',
        RegAddrCity: 'SAINT ALBANS',
        RegAddrState: 'VT',
        RegAddrZip: 5478,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1245 LOWER NEWTON RD',
        RegAddrCity: 'SAINT ALBANS',
        RegAddrState: 'VT',
        RegAddrZip: 5478,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '16 S ELM ST',
        RegAddrCity: 'SAINT ALBANS',
        RegAddrState: 'VT',
        RegAddrZip: 5478,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '353 MAPLE ST',
        RegAddrCity: 'SALISBURY',
        RegAddrState: 'VT',
        RegAddrZip: 5769,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '645 UPPER PLAINS RD',
        RegAddrCity: 'SALISBURY',
        RegAddrState: 'VT',
        RegAddrZip: 5769,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '887 BUCK HILL RD',
        RegAddrCity: 'SHAFTSBURY',
        RegAddrState: 'VT',
        RegAddrZip: 5262,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '318 ELEANORS WAY',
        RegAddrCity: 'SHAFTSBURY',
        RegAddrState: 'VT',
        RegAddrZip: 5262,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1464 MURPHY HILL RD',
        RegAddrCity: 'SHAFTSBURY',
        RegAddrState: 'VT',
        RegAddrZip: 5262,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '249 BIRCH HILL RD',
        RegAddrCity: 'SHAFTSBURY',
        RegAddrState: 'VT',
        RegAddrZip: 5262,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '396 THOMPSON RD',
        RegAddrCity: 'SHELBURNE',
        RegAddrState: 'VT',
        RegAddrZip: 5482,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '695 BARSTOW RD',
        RegAddrCity: 'SHELBURNE',
        RegAddrState: 'VT',
        RegAddrZip: 5482,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '226 RIDGEFIELD RD',
        RegAddrCity: 'SHELBURNE',
        RegAddrState: 'VT',
        RegAddrZip: 5482,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '279 OLD STAGE LN',
        RegAddrCity: 'SHELBURNE',
        RegAddrState: 'VT',
        RegAddrZip: 5482,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '587 RIDGEFIELD RD',
        RegAddrCity: 'SHELBURNE',
        RegAddrState: 'VT',
        RegAddrZip: 5482,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '836 BEAVER CREEK RD',
        RegAddrCity: 'SHELBURNE',
        RegAddrState: 'VT',
        RegAddrZip: 5482,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '672 FALLS RD',
        RegAddrCity: 'SHELBURNE',
        RegAddrState: 'VT',
        RegAddrZip: 5482,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '205 HILLSIDE TER',
        RegAddrCity: 'SHELBURNE',
        RegAddrState: 'VT',
        RegAddrZip: 5482,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '903 BISHOP RD',
        RegAddrCity: 'SHELBURNE',
        RegAddrState: 'VT',
        RegAddrZip: 5482,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '32 BAYFIELD DR',
        RegAddrCity: 'SHELBURNE',
        RegAddrState: 'VT',
        RegAddrZip: 5482,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '409 MAECK FARM RD',
        RegAddrCity: 'SHELBURNE',
        RegAddrState: 'VT',
        RegAddrZip: 5482,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '671 CROWE HILL RD',
        RegAddrCity: 'SHELDON',
        RegAddrState: 'VT',
        RegAddrZip: 5483,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '419 WEBSTER RD',
        RegAddrCity: 'SHOREHAM',
        RegAddrState: 'VT',
        RegAddrZip: 5770,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '658 HARRINGTON HILL RD',
        RegAddrCity: 'SHOREHAM',
        RegAddrState: 'VT',
        RegAddrZip: 5770,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '673 VT ROUTE 74 E',
        RegAddrCity: 'SHOREHAM',
        RegAddrState: 'VT',
        RegAddrZip: 5770,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '99 INN RD',
        RegAddrCity: 'SHOREHAM',
        RegAddrState: 'VT',
        RegAddrZip: 5770,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '258 NICE RD',
        RegAddrCity: 'SHREWSBURY',
        RegAddrState: 'VT',
        RegAddrZip: 5738,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1020 CROWN POINT RD',
        RegAddrCity: 'SHREWSBURY',
        RegAddrState: 'VT',
        RegAddrZip: 5738,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: 'B5 STONEHEDGE DR',
        RegAddrCity: 'SOUTH BURLINGTON',
        RegAddrState: 'VT',
        RegAddrZip: 5403,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '175 KENNEDY DR',
        RegAddrCity: 'SOUTH BURLINGTON',
        RegAddrState: 'VT',
        RegAddrZip: 5403,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '51 OVERLOOK DR',
        RegAddrCity: 'SOUTH BURLINGTON',
        RegAddrState: 'VT',
        RegAddrZip: 5403,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '17 LUPINE LN',
        RegAddrCity: 'SOUTH BURLINGTON',
        RegAddrState: 'VT',
        RegAddrZip: 5403,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '148 STELLAFANE RD',
        RegAddrCity: 'SPRINGFIELD',
        RegAddrState: 'VT',
        RegAddrZip: 5156,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '12 LOCKWOOD AVE',
        RegAddrCity: 'SPRINGFIELD',
        RegAddrState: 'VT',
        RegAddrZip: 5156,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '147 PARKER HILL RD',
        RegAddrCity: 'SPRINGFIELD',
        RegAddrState: 'VT',
        RegAddrZip: 5156,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '59 MONUMENT HILL RD',
        RegAddrCity: 'SPRINGFIELD',
        RegAddrState: 'VT',
        RegAddrZip: 5156,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '300 CHESTERFIELD HLW RD',
        RegAddrCity: 'ST JOHNSBURY',
        RegAddrState: 'VT',
        RegAddrZip: 5819,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '143 CALEDONIA ST',
        RegAddrCity: 'ST JOHNSBURY',
        RegAddrState: 'VT',
        RegAddrZip: 5819,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '435 PLEASANT ST',
        RegAddrCity: 'ST JOHNSBURY',
        RegAddrState: 'VT',
        RegAddrZip: 5819,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '695 DEER CREEK LN',
        RegAddrCity: 'ST JOHNSBURY',
        RegAddrState: 'VT',
        RegAddrZip: 5819,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '584 AVENUE A',
        RegAddrCity: 'ST JOHNSBURY',
        RegAddrState: 'VT',
        RegAddrZip: 5819,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '12 BIG HOLLOW RD',
        RegAddrCity: 'STARKSBORO',
        RegAddrState: 'VT',
        RegAddrZip: 5487,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '77 WORCESTER RD',
        RegAddrCity: 'STOWE',
        RegAddrState: 'VT',
        RegAddrZip: 5672,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '5785 MOUNTAIN RD',
        RegAddrCity: 'STOWE',
        RegAddrState: 'VT',
        RegAddrZip: 5672,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '2197 BROWNSVILLE RD',
        RegAddrCity: 'STOWE',
        RegAddrState: 'VT',
        RegAddrZip: 5672,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '3681 MOUNTAIN RD',
        RegAddrCity: 'STOWE',
        RegAddrState: 'VT',
        RegAddrZip: 5672,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1944 PUCKER ST',
        RegAddrCity: 'STOWE',
        RegAddrState: 'VT',
        RegAddrZip: 5672,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '46 COBURN RD',
        RegAddrCity: 'STRAFFORD',
        RegAddrState: 'VT',
        RegAddrZip: 5072,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '411 BURKE HILL RD',
        RegAddrCity: 'SUTTON',
        RegAddrState: 'VT',
        RegAddrZip: 5867,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '2493 HIGHGATE RD',
        RegAddrCity: 'SWANTON',
        RegAddrState: 'VT',
        RegAddrZip: 5488,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '195 WOODS HILL RD',
        RegAddrCity: 'SWANTON',
        RegAddrState: 'VT',
        RegAddrZip: 5488,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '92 HOG ISLAND RD',
        RegAddrCity: 'SWANTON',
        RegAddrState: 'VT',
        RegAddrZip: 5488,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: 'PO BOX 12',
        RegAddrCity: 'TAFTSVILLE',
        RegAddrState: 'VT',
        RegAddrZip: 5073,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '524 HOUGHTON HILL RD',
        RegAddrCity: 'THETFORD',
        RegAddrState: 'VT',
        RegAddrZip: 5074,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '896 ROUTE 132',
        RegAddrCity: 'THETFORD',
        RegAddrState: 'VT',
        RegAddrZip: 5074,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '393 MOUNTAIN VIEW RD',
        RegAddrCity: 'TINMOUTH',
        RegAddrState: 'VT',
        RegAddrZip: 5773,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '321 DEER VALLEY RD',
        RegAddrCity: 'TOWNSHEND',
        RegAddrState: 'VT',
        RegAddrZip: 5353,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '267 MAIN ST',
        RegAddrCity: 'TROY',
        RegAddrState: 'VT',
        RegAddrZip: 5868,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '',
        RegAddrCity: 'TROY',
        RegAddrState: 'VT',
        RegAddrZip: 5868,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '10 BROCKLEBANK RD',
        RegAddrCity: 'TUNBRIDGE',
        RegAddrState: 'VT',
        RegAddrZip: 5077,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '84 SPRING RD',
        RegAddrCity: 'TUNBRIDGE',
        RegAddrState: 'VT',
        RegAddrZip: 5077,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '79 PLEASANT VALLEY RD',
        RegAddrCity: 'UNDERHILL',
        RegAddrState: 'VT',
        RegAddrZip: 5489,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '12 MAPLE RDG RD',
        RegAddrCity: 'UNDERHILL',
        RegAddrState: 'VT',
        RegAddrZip: 5489,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '15 CHURCH ST',
        RegAddrCity: 'VERGENNES',
        RegAddrState: 'VT',
        RegAddrZip: 5491,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '7 COUNTRY CMNS',
        RegAddrCity: 'VERGENNES',
        RegAddrState: 'VT',
        RegAddrZip: 5491,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '40 MONKTON RD',
        RegAddrCity: 'VERGENNES',
        RegAddrState: 'VT',
        RegAddrZip: 5491,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '2864 FORT BRIDGMAN RD',
        RegAddrCity: 'VERNON',
        RegAddrState: 'VT',
        RegAddrZip: 5354,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '116 NEWTON HILL RD',
        RegAddrCity: 'VERNON',
        RegAddrState: 'VT',
        RegAddrZip: 5354,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '2777 FORT BRIDGMAN RD',
        RegAddrCity: 'VERNON',
        RegAddrState: 'VT',
        RegAddrZip: 5354,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '2513 PARKER RD',
        RegAddrCity: 'VERSHIRE',
        RegAddrState: 'VT',
        RegAddrZip: 5079,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '734 FAY BOYDEN RD',
        RegAddrCity: 'W WARDSBORO',
        RegAddrState: 'VT',
        RegAddrZip: 5360,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '976 LONG RD',
        RegAddrCity: 'WAITSFIELD',
        RegAddrState: 'VT',
        RegAddrZip: 5673,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '382 STEVENS HL W',
        RegAddrCity: 'WALDEN',
        RegAddrState: 'VT',
        RegAddrZip: 5873,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '868 BIXBY RD',
        RegAddrCity: 'WALLINGFORD',
        RegAddrState: 'VT',
        RegAddrZip: 5773,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '301 TOWNSHEND DAM RD',
        RegAddrCity: 'WARDSBORO',
        RegAddrState: 'VT',
        RegAddrZip: 5355,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '702 PRICKLY MOUNTAIN RD',
        RegAddrCity: 'WARREN',
        RegAddrState: 'VT',
        RegAddrZip: 5674,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '71 S MAIN ST',
        RegAddrCity: 'WATERBURY',
        RegAddrState: 'VT',
        RegAddrZip: 5676,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '4711 WATERBURY-STOWE RD',
        RegAddrCity: 'WATERBURY CENTER',
        RegAddrState: 'VT',
        RegAddrZip: 5677,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1287 RIPLEY RD',
        RegAddrCity: 'WATERBURY CTR',
        RegAddrState: 'VT',
        RegAddrZip: 5677,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '993 E VILLAGE RD',
        RegAddrCity: 'WATERFORD',
        RegAddrState: 'VT',
        RegAddrZip: 5819,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '96 OLD SILO RD',
        RegAddrCity: 'WATERFORD',
        RegAddrState: 'VT',
        RegAddrZip: 5819,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '244 FENOFF HEIGHTS LN',
        RegAddrCity: 'WATERFORD',
        RegAddrState: 'VT',
        RegAddrZip: 5819,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '469 TENNEY HILL RD',
        RegAddrCity: 'WEATHERSFIELD',
        RegAddrState: 'VT',
        RegAddrZip: 5156,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1141 BOWEN HILL RD',
        RegAddrCity: 'WEATHERSFIELD',
        RegAddrState: 'VT',
        RegAddrZip: 5156,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '955 PLAINS RD',
        RegAddrCity: 'WEATHERSFIELD',
        RegAddrState: 'VT',
        RegAddrZip: 5156,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '708 BUTTERNUT HILL RD',
        RegAddrCity: 'WELLS',
        RegAddrState: 'VT',
        RegAddrZip: 5774,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '429 HACKADAM RD',
        RegAddrCity: 'WEST HAVEN',
        RegAddrState: 'VT',
        RegAddrZip: 5743,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '70 BAXTER ST',
        RegAddrCity: 'WEST RUTLAND',
        RegAddrState: 'VT',
        RegAddrZip: 5777,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '30 WESTSIDE CT',
        RegAddrCity: 'WEST RUTLAND',
        RegAddrState: 'VT',
        RegAddrZip: 5777,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '95 MOUNTAINS EDGE RD',
        RegAddrCity: 'WEST WINDSOR',
        RegAddrState: 'VT',
        RegAddrZip: 5089,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '3 BIXBY HL',
        RegAddrCity: 'WESTFORD',
        RegAddrState: 'VT',
        RegAddrZip: 5494,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1699 OLD STAGE RD',
        RegAddrCity: 'WESTFORD',
        RegAddrState: 'VT',
        RegAddrZip: 5494,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '78 OLD NUMBER 11 RD',
        RegAddrCity: 'WESTFORD',
        RegAddrState: 'VT',
        RegAddrZip: 5494,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '103 SCHOOL ST',
        RegAddrCity: 'WESTMINSTER',
        RegAddrState: 'VT',
        RegAddrZip: 5158,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '369 PICKNELL RD',
        RegAddrCity: 'WESTMINSTER',
        RegAddrState: 'VT',
        RegAddrZip: 5158,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '2378 BACK WESTMINSTER RD',
        RegAddrCity: 'WESTMINSTER',
        RegAddrState: 'VT',
        RegAddrZip: 5158,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '162 PEENE HILL RD',
        RegAddrCity: 'WESTMORE',
        RegAddrState: 'VT',
        RegAddrZip: 5822,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '4050 SNAKE MOUNTAIN RD',
        RegAddrCity: 'WEYBRIDGE',
        RegAddrState: 'VT',
        RegAddrZip: 5753,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1392 ROUTE 8A',
        RegAddrCity: 'WHITINGHAM',
        RegAddrState: 'VT',
        RegAddrZip: 5361,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: 'PO BOX 656',
        RegAddrCity: 'WILDER',
        RegAddrState: 'VT',
        RegAddrZip: 5088,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '216 OLD TOWN RD',
        RegAddrCity: 'WILLIAMSTOWN',
        RegAddrState: 'VT',
        RegAddrZip: 5679,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '152 JENSEN RD',
        RegAddrCity: 'WILLISTON',
        RegAddrState: 'VT',
        RegAddrZip: 5495,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '425 MOUNTAIN VIEW RD',
        RegAddrCity: 'WILLISTON',
        RegAddrState: 'VT',
        RegAddrZip: 5495,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '34 HALL RD',
        RegAddrCity: 'WILMINGTON',
        RegAddrState: 'VT',
        RegAddrZip: 5363,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '26 RICH RD',
        RegAddrCity: 'WILMINGTON',
        RegAddrState: 'VT',
        RegAddrZip: 5363,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '75 HAPPY HAVEN RD',
        RegAddrCity: 'WILMINGTON',
        RegAddrState: 'VT',
        RegAddrZip: 5363,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '30 SHAFTER ST',
        RegAddrCity: 'WILMINGTON',
        RegAddrState: 'VT',
        RegAddrZip: 5363,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '5855 WINDHAM HILL RD',
        RegAddrCity: 'WINDHAM',
        RegAddrState: 'VT',
        RegAddrZip: 5359,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '111 STATE ST',
        RegAddrCity: 'WINDSOR',
        RegAddrState: 'VT',
        RegAddrZip: 5089,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '21 SNOWFLAKE LN',
        RegAddrCity: 'WINHALL',
        RegAddrState: 'VT',
        RegAddrZip: 5340,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '348 MAIN ST',
        RegAddrCity: 'WINOOSKI',
        RegAddrState: 'VT',
        RegAddrZip: 5404,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '156 WEST ST',
        RegAddrCity: 'WINOOSKI',
        RegAddrState: 'VT',
        RegAddrZip: 5404,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '146 WEST ST',
        RegAddrCity: 'WINOOSKI',
        RegAddrState: 'VT',
        RegAddrZip: 5404,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '95 SHEPARD ST',
        RegAddrCity: 'WINOOSKI',
        RegAddrState: 'VT',
        RegAddrZip: 5404,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '793 W WOODBURY RD',
        RegAddrCity: 'WOODBURY',
        RegAddrState: 'VT',
        RegAddrZip: 5681,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '5727 W WOODSTOCK RD',
        RegAddrCity: 'WOODSTOCK',
        RegAddrState: 'VT',
        RegAddrZip: 5091,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '2 CHURCH ST',
        RegAddrCity: 'WOODSTOCK',
        RegAddrState: 'VT',
        RegAddrZip: 5091,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '17 MAPLE ST',
        RegAddrCity: 'WOODSTOCK',
        RegAddrState: 'VT',
        RegAddrZip: 5091,
        CongressionalDistrict: 0,
      },
    ],
  },
  {
    name: 'WA-1',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/WA-1?ref=gh-pages',
    state: 'WA',
    state_and_number: 'WA-1',
    zip: 98004,
    congressionalDistrict: 1,
    addressesInDistrict: [
      {
        RegAddrLine1: '26919 53RD AVE NE',
        RegAddrCity: 'ARLINGTON',
        RegAddrState: 'WA',
        RegAddrZip: 98223,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '13620 103RD AVE NE',
        RegAddrCity: 'ARLINGTON',
        RegAddrState: 'WA',
        RegAddrZip: 98223,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '18616 45TH DR SE',
        RegAddrCity: 'BOTHELL',
        RegAddrState: 'WA',
        RegAddrZip: 98012,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '21353 11TH DR SE',
        RegAddrCity: 'BOTHELL',
        RegAddrState: 'WA',
        RegAddrZip: 98021,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '3977 ALDERGROVE RD',
        RegAddrCity: 'FERNDALE',
        RegAddrState: 'WA',
        RegAddrZip: 98248,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '17110 STATE ROUTE 92',
        RegAddrCity: 'GRANITE FALLS',
        RegAddrState: 'WA',
        RegAddrZip: 98252,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '135 10TH AVE S',
        RegAddrCity: 'KIRKLAND',
        RegAddrState: 'WA',
        RegAddrZip: 98033,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '614 10TH AVE W',
        RegAddrCity: 'KIRKLAND',
        RegAddrState: 'WA',
        RegAddrZip: 98033,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '12014 NE 99TH LN',
        RegAddrCity: 'KIRKLAND',
        RegAddrState: 'WA',
        RegAddrZip: 98033,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '6105 116TH AVE NE',
        RegAddrCity: 'KIRKLAND',
        RegAddrState: 'WA',
        RegAddrZip: 98033,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '8115 NE 110TH PL',
        RegAddrCity: 'KIRKLAND',
        RegAddrState: 'WA',
        RegAddrZip: 98034,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '11728 NE 150TH PL',
        RegAddrCity: 'KIRKLAND',
        RegAddrState: 'WA',
        RegAddrZip: 98034,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '7910 GRACE LN',
        RegAddrCity: 'LAKE STEVENS',
        RegAddrState: 'WA',
        RegAddrZip: 98258,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '404 LA VILLA DR',
        RegAddrCity: 'LYNDEN',
        RegAddrState: 'WA',
        RegAddrZip: 98264,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '102 E CEDAR DR',
        RegAddrCity: 'LYNDEN',
        RegAddrState: 'WA',
        RegAddrZip: 98264,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '3212 135TH PL SE',
        RegAddrCity: 'MILL CREEK',
        RegAddrState: 'WA',
        RegAddrZip: 98012,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1805 CONTINENTAL PL',
        RegAddrCity: 'MOUNT VERNON',
        RegAddrState: 'WA',
        RegAddrZip: 98273,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '8212 172ND AVE NE',
        RegAddrCity: 'REDMOND',
        RegAddrState: 'WA',
        RegAddrZip: 98052,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '11743 173RD PL NE',
        RegAddrCity: 'REDMOND',
        RegAddrState: 'WA',
        RegAddrZip: 98052,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1621 CONNORS RD',
        RegAddrCity: 'SNOHOMISH',
        RegAddrState: 'WA',
        RegAddrZip: 98290,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '7010 81ST AVE SE',
        RegAddrCity: 'SNOHOMISH',
        RegAddrState: 'WA',
        RegAddrZip: 98290,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '13325 NE 152ND ST',
        RegAddrCity: 'WOODINVILLE',
        RegAddrState: 'WA',
        RegAddrZip: 98072,
        CongressionalDistrict: 1,
      },
    ],
  },
  {
    name: 'WA-10',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/WA-10?ref=gh-pages',
    state: 'WA',
    state_and_number: 'WA-10',
    zip: 98303,
    congressionalDistrict: 10,
    addressesInDistrict: [
      {
        RegAddrLine1: '2906 110TH AVE E',
        RegAddrCity: 'EDGEWOOD',
        RegAddrState: 'WA',
        RegAddrZip: 98372,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '5510 34TH AVE SE',
        RegAddrCity: 'LACEY',
        RegAddrState: 'WA',
        RegAddrZip: 98503,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '4324 HARSTENE ST NE',
        RegAddrCity: 'LACEY',
        RegAddrState: 'WA',
        RegAddrZip: 98516,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '11604 MOUNTBROOK LN SW',
        RegAddrCity: 'LAKEWOOD',
        RegAddrState: 'WA',
        RegAddrZip: 98499,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '8505 BRIDGEPORT WAY SW',
        RegAddrCity: 'LAKEWOOD',
        RegAddrState: 'WA',
        RegAddrZip: 98499,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '4148 RIVERWOOD DR SE',
        RegAddrCity: 'OLYMPIA',
        RegAddrState: 'WA',
        RegAddrZip: 98501,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '7827 25TH AVE SE',
        RegAddrCity: 'OLYMPIA',
        RegAddrState: 'WA',
        RegAddrZip: 98503,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '1621 PROSPECT AVE NE',
        RegAddrCity: 'OLYMPIA',
        RegAddrState: 'WA',
        RegAddrZip: 98506,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '12901 81ST AVENUE CT E',
        RegAddrCity: 'PUYALLUP',
        RegAddrState: 'WA',
        RegAddrZip: 98373,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '18626 LOGANBERRY ST SW',
        RegAddrCity: 'ROCHESTER',
        RegAddrState: 'WA',
        RegAddrZip: 98579,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '852 172ND ST E',
        RegAddrCity: 'SPANAWAY',
        RegAddrState: 'WA',
        RegAddrZip: 98387,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '1305 182ND STREET CT E',
        RegAddrCity: 'SPANAWAY',
        RegAddrState: 'WA',
        RegAddrZip: 98387,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '920 E 62ND ST',
        RegAddrCity: 'TACOMA',
        RegAddrState: 'WA',
        RegAddrZip: 98404,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '502 102ND STREET CT S',
        RegAddrCity: 'TACOMA',
        RegAddrState: 'WA',
        RegAddrZip: 98444,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '752 133RD ST S',
        RegAddrCity: 'TACOMA',
        RegAddrState: 'WA',
        RegAddrZip: 98444,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '11510 108TH ST SW',
        RegAddrCity: 'TACOMA',
        RegAddrState: 'WA',
        RegAddrZip: 98498,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '822 143RD AVE SE',
        RegAddrCity: 'TENINO',
        RegAddrState: 'WA',
        RegAddrZip: 98589,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '214 MCKENA ST SW',
        RegAddrCity: 'TUMWATER',
        RegAddrState: 'WA',
        RegAddrZip: 98512,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '7004 50TH ST W',
        RegAddrCity: 'UNIVERSITY PL',
        RegAddrState: 'WA',
        RegAddrZip: 98467,
        CongressionalDistrict: 10,
      },
      {
        RegAddrLine1: '15332 159TH LN SE',
        RegAddrCity: 'YELM',
        RegAddrState: 'WA',
        RegAddrZip: 98597,
        CongressionalDistrict: 10,
      },
    ],
  },
  {
    name: 'WA-2',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/WA-2?ref=gh-pages',
    state: 'WA',
    state_and_number: 'WA-2',
    zip: 98026,
    congressionalDistrict: 2,
    addressesInDistrict: [
      {
        RegAddrLine1: '6744 CAROLINA ST',
        RegAddrCity: 'ANACORTES',
        RegAddrState: 'WA',
        RegAddrZip: 98221,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '16406 42ND AVE NE',
        RegAddrCity: 'ARLINGTON',
        RegAddrState: 'WA',
        RegAddrZip: 98223,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '18601 35TH AVE NE',
        RegAddrCity: 'ARLINGTON',
        RegAddrState: 'WA',
        RegAddrZip: 98223,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '117 UNDERHILL RD',
        RegAddrCity: 'BELLINGHAM',
        RegAddrState: 'WA',
        RegAddrZip: 98225,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2515 VICTOR ST',
        RegAddrCity: 'BELLINGHAM',
        RegAddrState: 'WA',
        RegAddrZip: 98225,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '800 NORTHSHORE DR',
        RegAddrCity: 'BELLINGHAM',
        RegAddrState: 'WA',
        RegAddrZip: 98226,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1317 235TH PL SW',
        RegAddrCity: 'BOTHELL',
        RegAddrState: 'WA',
        RegAddrZip: 98021,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1313 STATE ROUTE 20',
        RegAddrCity: 'BURLINGTON',
        RegAddrState: 'WA',
        RegAddrZip: 98233,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '4497 ROLLINGHILL RD',
        RegAddrCity: 'CLINTON',
        RegAddrState: 'WA',
        RegAddrZip: 98236,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '4808 151ST ST SW',
        RegAddrCity: 'EDMONDS',
        RegAddrState: 'WA',
        RegAddrZip: 98026,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1820 OAKES AVE',
        RegAddrCity: 'EVERETT',
        RegAddrState: 'WA',
        RegAddrZip: 98201,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1226 59TH PL SW',
        RegAddrCity: 'EVERETT',
        RegAddrState: 'WA',
        RegAddrZip: 98203,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '5329 NARBECK AVE',
        RegAddrCity: 'EVERETT',
        RegAddrState: 'WA',
        RegAddrZip: 98203,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1716 EDGEMOOR LN',
        RegAddrCity: 'EVERETT',
        RegAddrState: 'WA',
        RegAddrZip: 98203,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1621 FLORIDA DR',
        RegAddrCity: 'EVERETT',
        RegAddrState: 'WA',
        RegAddrZip: 98203,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '198 GOAT PEN RD',
        RegAddrCity: 'FRIDAY HARBOR',
        RegAddrState: 'WA',
        RegAddrZip: 98250,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '418 2ND ST',
        RegAddrCity: 'LANGLEY',
        RegAddrState: 'WA',
        RegAddrZip: 98260,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '19208 11TH PL W',
        RegAddrCity: 'LYNNWOOD',
        RegAddrState: 'WA',
        RegAddrZip: 98036,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '618 200TH PL SW',
        RegAddrCity: 'LYNNWOOD',
        RegAddrState: 'WA',
        RegAddrZip: 98036,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '20220 75TH PL W',
        RegAddrCity: 'LYNNWOOD',
        RegAddrState: 'WA',
        RegAddrZip: 98036,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '21905 43RD CT W',
        RegAddrCity: 'MOUNTLAKE TER',
        RegAddrState: 'WA',
        RegAddrZip: 98043,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2820 88TH ST NE',
        RegAddrCity: 'QUIL CEDA VLG',
        RegAddrState: 'WA',
        RegAddrZip: 98271,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '7018 CHURCH CREEK LOOP NW',
        RegAddrCity: 'STANWOOD',
        RegAddrState: 'WA',
        RegAddrZip: 98292,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '19125 MARINE DR',
        RegAddrCity: 'STANWOOD',
        RegAddrState: 'WA',
        RegAddrZip: 98292,
        CongressionalDistrict: 2,
      },
    ],
  },
  {
    name: 'WA-3',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/WA-3?ref=gh-pages',
    state: 'WA',
    state_and_number: 'WA-3',
    zip: 98304,
    congressionalDistrict: 3,
    addressesInDistrict: [
      {
        RegAddrLine1: '517 SE 8TH PL',
        RegAddrCity: 'BATTLE GROUND',
        RegAddrState: 'WA',
        RegAddrZip: 98604,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '24711 NE 123RD CIR',
        RegAddrCity: 'BRUSH PRAIRIE',
        RegAddrState: 'WA',
        RegAddrZip: 98606,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '2341 NW WALDEN DR',
        RegAddrCity: 'CAMAS',
        RegAddrState: 'WA',
        RegAddrZip: 98607,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '137 GREEN ACRES DR',
        RegAddrCity: 'CASTLE ROCK',
        RegAddrState: 'WA',
        RegAddrZip: 98611,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '2455 WILDWOOD RD',
        RegAddrCity: 'CURTIS',
        RegAddrState: 'WA',
        RegAddrZip: 98538,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '2200 WASHINGTON AVE N',
        RegAddrCity: 'LONG BEACH',
        RegAddrState: 'WA',
        RegAddrZip: 98631,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '50 LOVE CT',
        RegAddrCity: 'LONGVIEW',
        RegAddrState: 'WA',
        RegAddrZip: 98632,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '3031 OAK ST',
        RegAddrCity: 'LONGVIEW',
        RegAddrState: 'WA',
        RegAddrZip: 98632,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '3769 GRACIE PL',
        RegAddrCity: 'LONGVIEW',
        RegAddrState: 'WA',
        RegAddrZip: 98632,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '500 SE 7TH ST',
        RegAddrCity: 'NORTH BEND',
        RegAddrState: 'WA',
        RegAddrZip: 98045,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '17117 NE NE 14TH PL',
        RegAddrCity: 'RIDGEFIELD',
        RegAddrState: 'WA',
        RegAddrZip: 98642,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1816 S 15TH CIR',
        RegAddrCity: 'RIDGEFIELD',
        RegAddrState: 'WA',
        RegAddrZip: 98642,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '123 CORE RD',
        RegAddrCity: 'SILVER CREEK',
        RegAddrState: 'WA',
        RegAddrZip: 98585,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '401 W 31ST ST',
        RegAddrCity: 'VANCOUVER',
        RegAddrState: 'WA',
        RegAddrZip: 98660,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '9006 NE 78TH CIR',
        RegAddrCity: 'VANCOUVER',
        RegAddrState: 'WA',
        RegAddrZip: 98662,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1519 NE EDGE PARK DR',
        RegAddrCity: 'VANCOUVER',
        RegAddrState: 'WA',
        RegAddrZip: 98663,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '2242 NE 50TH CIR',
        RegAddrCity: 'VANCOUVER',
        RegAddrState: 'WA',
        RegAddrZip: 98663,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '8219 NE 21ST AVE',
        RegAddrCity: 'VANCOUVER',
        RegAddrState: 'WA',
        RegAddrZip: 98665,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '13007 NE 91ST CIR',
        RegAddrCity: 'VANCOUVER',
        RegAddrState: 'WA',
        RegAddrZip: 98682,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '12008 NW 40TH AVE',
        RegAddrCity: 'VANCOUVER',
        RegAddrState: 'WA',
        RegAddrZip: 98685,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '42121 NE 44TH AVE',
        RegAddrCity: 'WOODLAND',
        RegAddrState: 'WA',
        RegAddrZip: 98674,
        CongressionalDistrict: 3,
      },
    ],
  },
  {
    name: 'WA-4',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/WA-4?ref=gh-pages',
    state: 'WA',
    state_and_number: 'WA-4',
    zip: 98022,
    congressionalDistrict: 4,
    addressesInDistrict: [
      {
        RegAddrLine1: '822H MONSE RIVER RD',
        RegAddrCity: 'BREWSTER',
        RegAddrState: 'WA',
        RegAddrZip: 98812,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '675 RINGHOFF RD',
        RegAddrCity: 'BURBANK',
        RegAddrState: 'WA',
        RegAddrZip: 99323,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '156 MINERAL HILL RD',
        RegAddrCity: 'CONCONULLY',
        RegAddrState: 'WA',
        RegAddrZip: 98819,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '306 COLUMBIA AVE',
        RegAddrCity: 'COULEE DAM',
        RegAddrState: 'WA',
        RegAddrZip: 99116,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '11355 DODSON RD NW',
        RegAddrCity: 'EPHRATA',
        RegAddrState: 'WA',
        RegAddrZip: 98823,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '12 D ST NE',
        RegAddrCity: 'EPHRATA',
        RegAddrState: 'WA',
        RegAddrZip: 98823,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1904 WYANT WAY',
        RegAddrCity: 'GRANDVIEW',
        RegAddrState: 'WA',
        RegAddrZip: 98930,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '6906 W VICTORIA AVE',
        RegAddrCity: 'KENNEWICK',
        RegAddrState: 'WA',
        RegAddrZip: 99336,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '5304 S COULEE VISTA DR',
        RegAddrCity: 'KENNEWICK',
        RegAddrState: 'WA',
        RegAddrZip: 99338,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1815 N 12TH AVE',
        RegAddrCity: 'PASCO',
        RegAddrState: 'WA',
        RegAddrZip: 99301,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2208 N SNYDER RD',
        RegAddrCity: 'RITZVILLE',
        RegAddrState: 'WA',
        RegAddrZip: 99169,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '804 JAMIE DR',
        RegAddrCity: 'SELAH',
        RegAddrState: 'WA',
        RegAddrZip: 98942,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '5103 DOVE LN',
        RegAddrCity: 'WEST RICHLAND',
        RegAddrState: 'WA',
        RegAddrZip: 99353,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1103 S 38TH AVE',
        RegAddrCity: 'WEST RICHLAND',
        RegAddrState: 'WA',
        RegAddrZip: 99353,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '990 ELTON RD',
        RegAddrCity: 'YAKIMA',
        RegAddrState: 'WA',
        RegAddrZip: 98901,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1518 S 16TH ST',
        RegAddrCity: 'YAKIMA',
        RegAddrState: 'WA',
        RegAddrZip: 98901,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1109 S 11TH AVE',
        RegAddrCity: 'YAKIMA',
        RegAddrState: 'WA',
        RegAddrZip: 98902,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '53 W PIERCE ST',
        RegAddrCity: 'YAKIMA',
        RegAddrState: 'WA',
        RegAddrZip: 98902,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '11806 STUMP RD',
        RegAddrCity: 'YAKIMA',
        RegAddrState: 'WA',
        RegAddrZip: 98908,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2210 OLD NACHES HWY',
        RegAddrCity: 'YAKIMA',
        RegAddrState: 'WA',
        RegAddrZip: 98908,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '8 N 86TH AVE',
        RegAddrCity: 'YAKIMA',
        RegAddrState: 'WA',
        RegAddrZip: 98908,
        CongressionalDistrict: 4,
      },
    ],
  },
  {
    name: 'WA-5',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/WA-5?ref=gh-pages',
    state: 'WA',
    state_and_number: 'WA-5',
    zip: 99001,
    congressionalDistrict: 5,
    addressesInDistrict: [
      {
        RegAddrLine1: '1019 14TH ST',
        RegAddrCity: 'CLARKSTON',
        RegAddrState: 'WA',
        RegAddrZip: 99403,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '1304 FRANCIS AVE',
        RegAddrCity: 'CLARKSTON',
        RegAddrState: 'WA',
        RegAddrZip: 99403,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '5604 E CARGILL LN',
        RegAddrCity: 'COLBERT',
        RegAddrState: 'WA',
        RegAddrZip: 99005,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '125 W CEDAR LOOP',
        RegAddrCity: 'COLVILLE',
        RegAddrState: 'WA',
        RegAddrZip: 99114,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '2584 BRIDGEMAN RETTINGER RD',
        RegAddrCity: 'KETTLE FALLS',
        RegAddrState: 'WA',
        RegAddrZip: 99141,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '4917 N VINCENT RD',
        RegAddrCity: 'NEWMAN LAKE',
        RegAddrState: 'WA',
        RegAddrZip: 99025,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '839 SE KLEMGARD ST',
        RegAddrCity: 'PULLMAN',
        RegAddrState: 'WA',
        RegAddrZip: 99163,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '1055 NE INDIANA ST',
        RegAddrCity: 'PULLMAN',
        RegAddrState: 'WA',
        RegAddrZip: 99163,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '2111 E 6TH AVE',
        RegAddrCity: 'SPOKANE',
        RegAddrState: 'WA',
        RegAddrZip: 99202,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '910 W LACROSSE AVE',
        RegAddrCity: 'SPOKANE',
        RegAddrState: 'WA',
        RegAddrZip: 99205,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '5521 N C ST',
        RegAddrCity: 'SPOKANE',
        RegAddrState: 'WA',
        RegAddrZip: 99205,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '1505 E CROWN AVE',
        RegAddrCity: 'SPOKANE',
        RegAddrState: 'WA',
        RegAddrZip: 99207,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '8212 N PANORAMA DR',
        RegAddrCity: 'SPOKANE',
        RegAddrState: 'WA',
        RegAddrZip: 99208,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '8816 N FIVE MILE RD',
        RegAddrCity: 'SPOKANE',
        RegAddrState: 'WA',
        RegAddrZip: 99208,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '8526 E BRIDGEPORT AVE',
        RegAddrCity: 'SPOKANE',
        RegAddrState: 'WA',
        RegAddrZip: 99212,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '8824 N COLTON ST',
        RegAddrCity: 'SPOKANE',
        RegAddrState: 'WA',
        RegAddrZip: 99218,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '1321 N UNIVERSITY RD',
        RegAddrCity: 'SPOKANE VLY',
        RegAddrState: 'WA',
        RegAddrZip: 99206,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '14905 E 2ND AVE',
        RegAddrCity: 'SPOKANE VLY',
        RegAddrState: 'WA',
        RegAddrZip: 99216,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '14111 E 3RD AVE',
        RegAddrCity: 'SPOKANE VLY',
        RegAddrState: 'WA',
        RegAddrZip: 99216,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '6518 ELIJAH RD',
        RegAddrCity: 'WELLPINIT',
        RegAddrState: 'WA',
        RegAddrZip: 99040,
        CongressionalDistrict: 5,
      },
    ],
  },
  {
    name: 'WA-6',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/WA-6?ref=gh-pages',
    state: 'WA',
    state_and_number: 'WA-6',
    zip: 98110,
    congressionalDistrict: 6,
    addressesInDistrict: [
      {
        RegAddrLine1: '90 E OLYMPIC CT',
        RegAddrCity: 'ALLYN',
        RegAddrState: 'WA',
        RegAddrZip: 98524,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '11653 NE SUNSET LOOP',
        RegAddrCity: 'BAINBRIDGE IS',
        RegAddrState: 'WA',
        RegAddrZip: 98110,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '7069 SIGNAL LN NE',
        RegAddrCity: 'BREMERTON',
        RegAddrState: 'WA',
        RegAddrZip: 98311,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '655 NE LIBRA LN',
        RegAddrCity: 'BREMERTON',
        RegAddrState: 'WA',
        RegAddrZip: 98311,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '7214 90TH STREET CT NW',
        RegAddrCity: 'GIG HARBOR',
        RegAddrState: 'WA',
        RegAddrZip: 98332,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '1510 VALLEY DR NW',
        RegAddrCity: 'GIG HARBOR',
        RegAddrState: 'WA',
        RegAddrZip: 98332,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '6855 WHITMORE DR NW',
        RegAddrCity: 'GIG HARBOR',
        RegAddrState: 'WA',
        RegAddrZip: 98335,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '24550 SOUTH KINGSTON RD NE',
        RegAddrCity: 'KINGSTON',
        RegAddrState: 'WA',
        RegAddrZip: 98346,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '13214 HADFIELD RD SE',
        RegAddrCity: 'OLALLA',
        RegAddrState: 'WA',
        RegAddrZip: 98359,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '53 UPHILL DR',
        RegAddrCity: 'PORT ANGELES',
        RegAddrState: 'WA',
        RegAddrZip: 98362,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '5014 MOUNT PLEASANT RD',
        RegAddrCity: 'PORT ANGELES',
        RegAddrState: 'WA',
        RegAddrZip: 98362,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '112 WATERHOUSE LN',
        RegAddrCity: 'PORT LUDLOW',
        RegAddrState: 'WA',
        RegAddrZip: 98365,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '1148 SIDNEY AVE',
        RegAddrCity: 'PORT ORCHARD',
        RegAddrState: 'WA',
        RegAddrZip: 98366,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '6388 PULLMAN PL SW',
        RegAddrCity: 'PORT ORCHARD',
        RegAddrState: 'WA',
        RegAddrZip: 98367,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '1059 QUINCY ST',
        RegAddrCity: 'PORT TOWNSEND',
        RegAddrState: 'WA',
        RegAddrZip: 98368,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '571 NE BERNT RD',
        RegAddrCity: 'POULSBO',
        RegAddrState: 'WA',
        RegAddrZip: 98370,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '13943 NW ADRIAN LN',
        RegAddrCity: 'SEABECK',
        RegAddrState: 'WA',
        RegAddrZip: 98380,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '18241 W WINTER GREEN LN',
        RegAddrCity: 'SEABECK',
        RegAddrState: 'WA',
        RegAddrZip: 98380,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '303 QUAILS ROOST RD',
        RegAddrCity: 'SEQUIM',
        RegAddrState: 'WA',
        RegAddrZip: 98382,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '1602 S ALDER ST',
        RegAddrCity: 'TACOMA',
        RegAddrState: 'WA',
        RegAddrZip: 98405,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '1611 S GRANT AVE',
        RegAddrCity: 'TACOMA',
        RegAddrState: 'WA',
        RegAddrZip: 98405,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '1309 N HEATHERWOOD W',
        RegAddrCity: 'TACOMA',
        RegAddrState: 'WA',
        RegAddrZip: 98406,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '6720 N PARKSIDE LN',
        RegAddrCity: 'TACOMA',
        RegAddrState: 'WA',
        RegAddrZip: 98407,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '7841 S J ST',
        RegAddrCity: 'TACOMA',
        RegAddrState: 'WA',
        RegAddrZip: 98408,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '515 S 38TH ST',
        RegAddrCity: 'TACOMA',
        RegAddrState: 'WA',
        RegAddrZip: 98418,
        CongressionalDistrict: 6,
      },
    ],
  },
  {
    name: 'WA-7',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/WA-7?ref=gh-pages',
    state: 'WA',
    state_and_number: 'WA-7',
    zip: 98020,
    congressionalDistrict: 7,
    addressesInDistrict: [
      {
        RegAddrLine1: '8520 MAPLE LN',
        RegAddrCity: 'EDMONDS',
        RegAddrState: 'WA',
        RegAddrZip: 98026,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '5034 16TH AVE NE',
        RegAddrCity: 'SEATTLE',
        RegAddrState: 'WA',
        RegAddrZip: 98105,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '9743 26TH AVE SW',
        RegAddrCity: 'SEATTLE',
        RegAddrState: 'WA',
        RegAddrZip: 98106,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '6102 32ND AVE NW',
        RegAddrCity: 'SEATTLE',
        RegAddrState: 'WA',
        RegAddrZip: 98107,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '2419 NW 58TH ST',
        RegAddrCity: 'SEATTLE',
        RegAddrState: 'WA',
        RegAddrZip: 98107,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '1701 2ND AVE N',
        RegAddrCity: 'SEATTLE',
        RegAddrState: 'WA',
        RegAddrZip: 98109,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '6847 55TH AVE NE',
        RegAddrCity: 'SEATTLE',
        RegAddrState: 'WA',
        RegAddrZip: 98115,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '7516 28TH AVE NE',
        RegAddrCity: 'SEATTLE',
        RegAddrState: 'WA',
        RegAddrZip: 98115,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '1721 44TH AVE SW',
        RegAddrCity: 'SEATTLE',
        RegAddrState: 'WA',
        RegAddrZip: 98116,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '1718 16TH AVE',
        RegAddrCity: 'SEATTLE',
        RegAddrState: 'WA',
        RegAddrZip: 98122,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '12746 42ND AVE NE',
        RegAddrCity: 'SEATTLE',
        RegAddrState: 'WA',
        RegAddrZip: 98125,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '9400 37TH AVE SW',
        RegAddrCity: 'SEATTLE',
        RegAddrState: 'WA',
        RegAddrZip: 98126,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '11747 FREMONT AVE N',
        RegAddrCity: 'SEATTLE',
        RegAddrState: 'WA',
        RegAddrZip: 98133,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '8422 FAUNTLEROY WAY SW',
        RegAddrCity: 'SEATTLE',
        RegAddrState: 'WA',
        RegAddrZip: 98136,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '4423 30TH AVE W',
        RegAddrCity: 'SEATTLE',
        RegAddrState: 'WA',
        RegAddrZip: 98199,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '17010 12TH AVE NW',
        RegAddrCity: 'SHORELINE',
        RegAddrState: 'WA',
        RegAddrZip: 98177,
        CongressionalDistrict: 7,
      },
    ],
  },
  {
    name: 'WA-8',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/WA-8?ref=gh-pages',
    state: 'WA',
    state_and_number: 'WA-8',
    zip: 98001,
    congressionalDistrict: 8,
    addressesInDistrict: [
      {
        RegAddrLine1: '5531 S 321ST ST',
        RegAddrCity: 'AUBURN',
        RegAddrState: 'WA',
        RegAddrZip: 98001,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '20519 SE 330TH ST',
        RegAddrCity: 'AUBURN',
        RegAddrState: 'WA',
        RegAddrZip: 98092,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '21808 116TH STREET CT E',
        RegAddrCity: 'BONNEY LAKE',
        RegAddrState: 'WA',
        RegAddrZip: 98391,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '11018 185TH AVE E',
        RegAddrCity: 'BONNEY LAKE',
        RegAddrState: 'WA',
        RegAddrZip: 98391,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '2227 W PROSPECT ST',
        RegAddrCity: 'CHELAN',
        RegAddrState: 'WA',
        RegAddrZip: 98816,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '1051 PAYS RD',
        RegAddrCity: 'CLE ELUM',
        RegAddrState: 'WA',
        RegAddrZip: 98922,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '7640 HWY 10',
        RegAddrCity: 'ELLENSBURG',
        RegAddrState: 'WA',
        RegAddrZip: 98926,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '404 S WALNUT ST',
        RegAddrCity: 'ELLENSBURG',
        RegAddrState: 'WA',
        RegAddrZip: 98926,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '27211 MERIDIAN AVE E',
        RegAddrCity: 'GRAHAM',
        RegAddrState: 'WA',
        RegAddrZip: 98338,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '12204 UPPER PRESTON RD SE',
        RegAddrCity: 'ISSAQUAH',
        RegAddrState: 'WA',
        RegAddrZip: 98027,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '840 GREENWOOD BLVD SW',
        RegAddrCity: 'ISSAQUAH',
        RegAddrState: 'WA',
        RegAddrZip: 98027,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '25511 122ND PL SE',
        RegAddrCity: 'KENT',
        RegAddrState: 'WA',
        RegAddrZip: 98030,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '24117 139TH AVE SE',
        RegAddrCity: 'KENT',
        RegAddrState: 'WA',
        RegAddrZip: 98042,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '26926 150TH PL SE',
        RegAddrCity: 'KENT',
        RegAddrState: 'WA',
        RegAddrZip: 98042,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '19617 SE 277TH ST',
        RegAddrCity: 'KENT',
        RegAddrState: 'WA',
        RegAddrZip: 98042,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '21631 SE 297TH ST',
        RegAddrCity: 'KENT',
        RegAddrState: 'WA',
        RegAddrZip: 98042,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '18310 DRIFTWOOD DR E',
        RegAddrCity: 'LAKE TAPPS',
        RegAddrState: 'WA',
        RegAddrZip: 98391,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '8935 E LEAVENWORTH RD',
        RegAddrCity: 'LEAVENWORTH',
        RegAddrState: 'WA',
        RegAddrZip: 98826,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '23429 SE 263RD ST',
        RegAddrCity: 'MAPLE VALLEY',
        RegAddrState: 'WA',
        RegAddrZip: 98038,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '13721 409TH AVE SE',
        RegAddrCity: 'NORTH BEND',
        RegAddrState: 'WA',
        RegAddrZip: 98045,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '1102 WILLIAMS ST NW',
        RegAddrCity: 'ORTING',
        RegAddrState: 'WA',
        RegAddrZip: 98360,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '19101 206TH ST E',
        RegAddrCity: 'ORTING',
        RegAddrState: 'WA',
        RegAddrZip: 98360,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '13018 151ST ST E',
        RegAddrCity: 'PUYALLUP',
        RegAddrState: 'WA',
        RegAddrZip: 98374,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '15000 SE 181ST ST',
        RegAddrCity: 'RENTON',
        RegAddrState: 'WA',
        RegAddrZip: 98058,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '4023 258TH WAY SE',
        RegAddrCity: 'SAMMAMISH',
        RegAddrState: 'WA',
        RegAddrZip: 98029,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '2051 E BEAVER LAKE DR SE',
        RegAddrCity: 'SAMMAMISH',
        RegAddrState: 'WA',
        RegAddrZip: 98075,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '22504 SE 47TH PL',
        RegAddrCity: 'SAMMAMISH',
        RegAddrState: 'WA',
        RegAddrZip: 98075,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '1024 APPLELAND DR',
        RegAddrCity: 'WENATCHEE',
        RegAddrState: 'WA',
        RegAddrZip: 98801,
        CongressionalDistrict: 8,
      },
    ],
  },
  {
    name: 'WA-9',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/WA-9?ref=gh-pages',
    state: 'WA',
    state_and_number: 'WA-9',
    zip: 98005,
    congressionalDistrict: 9,
    addressesInDistrict: [
      {
        RegAddrLine1: '5534 174TH PL SE',
        RegAddrCity: 'BELLEVUE',
        RegAddrState: 'WA',
        RegAddrZip: 98006,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '24910 MARINE VIEW DR S',
        RegAddrCity: 'DES MOINES',
        RegAddrState: 'WA',
        RegAddrZip: 98198,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '26804 17TH AVE S',
        RegAddrCity: 'DES MOINES',
        RegAddrState: 'WA',
        RegAddrZip: 98198,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '29311 7TH PL S',
        RegAddrCity: 'FEDERAL WAY',
        RegAddrState: 'WA',
        RegAddrZip: 98003,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '36632 2ND AVE SW',
        RegAddrCity: 'FEDERAL WAY',
        RegAddrState: 'WA',
        RegAddrZip: 98023,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '4620 SW 317TH PL',
        RegAddrCity: 'FEDERAL WAY',
        RegAddrState: 'WA',
        RegAddrZip: 98023,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '814 REITEN RD',
        RegAddrCity: 'KENT',
        RegAddrState: 'WA',
        RegAddrZip: 98030,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '23705 65TH AVE S',
        RegAddrCity: 'KENT',
        RegAddrState: 'WA',
        RegAddrZip: 98032,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '3725 S 253RD ST',
        RegAddrCity: 'KENT',
        RegAddrState: 'WA',
        RegAddrZip: 98032,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '11409 SE 185TH PL',
        RegAddrCity: 'RENTON',
        RegAddrState: 'WA',
        RegAddrZip: 98055,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '6207 NE 7TH CT',
        RegAddrCity: 'RENTON',
        RegAddrState: 'WA',
        RegAddrZip: 98059,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '541 JERICHO AVE NE',
        RegAddrCity: 'RENTON',
        RegAddrState: 'WA',
        RegAddrZip: 98059,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '2614 S LANE ST',
        RegAddrCity: 'SEATTLE',
        RegAddrState: 'WA',
        RegAddrZip: 98144,
        CongressionalDistrict: 9,
      },
      {
        RegAddrLine1: '1712 26TH AVE S',
        RegAddrCity: 'SEATTLE',
        RegAddrState: 'WA',
        RegAddrZip: 98144,
        CongressionalDistrict: 9,
      },
    ],
  },
  {
    name: 'WI-1',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/WI-1?ref=gh-pages',
    state: 'WI',
    state_and_number: 'WI-1',
    zip: 53018,
    congressionalDistrict: 1,
    addressesInDistrict: [
      {
        RegAddrLine1: 'S85W23000 VILLA DR',
        RegAddrCity: 'BIG BEND',
        RegAddrState: 'WI',
        RegAddrZip: 53103,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '516 E WALWORTH AVE',
        RegAddrCity: 'DELAVAN',
        RegAddrState: 'WI',
        RegAddrZip: 53115,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1129 CENTER ST',
        RegAddrCity: 'DELAVAN',
        RegAddrState: 'WI',
        RegAddrZip: 53115,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2813 BORG RD',
        RegAddrCity: 'DELAVAN',
        RegAddrState: 'WI',
        RegAddrZip: 53115,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '220 S AUTUMN DR',
        RegAddrCity: 'DELAVAN',
        RegAddrState: 'WI',
        RegAddrZip: 53115,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: 'N6006 JOHNSON RD',
        RegAddrCity: 'DELAVAN',
        RegAddrState: 'WI',
        RegAddrZip: 53115,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '807 MELBOURNE RD',
        RegAddrCity: 'EAGLE',
        RegAddrState: 'WI',
        RegAddrZip: 53119,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1112 HICKORY LN',
        RegAddrCity: 'ELKHORN',
        RegAddrState: 'WI',
        RegAddrZip: 53121,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '7029 W RAWSON AVE',
        RegAddrCity: 'FRANKLIN',
        RegAddrState: 'WI',
        RegAddrZip: 53132,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '8215 S SHADWELL CIR',
        RegAddrCity: 'FRANKLIN',
        RegAddrState: 'WI',
        RegAddrZip: 53132,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '3445 PIONEER DR',
        RegAddrCity: 'FRANKSVILLE',
        RegAddrState: 'WI',
        RegAddrZip: 53126,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '4144 68TH ST',
        RegAddrCity: 'FRANKSVILLE',
        RegAddrState: 'WI',
        RegAddrZip: 53126,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '316 FENMORE LN',
        RegAddrCity: 'GENOA CITY',
        RegAddrState: 'WI',
        RegAddrZip: 53128,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '8644 WESTLAKE DR',
        RegAddrCity: 'GREENDALE',
        RegAddrState: 'WI',
        RegAddrZip: 53129,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '5960 S 92ND ST',
        RegAddrCity: 'HALES CORNERS',
        RegAddrState: 'WI',
        RegAddrZip: 53130,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2439 CLOVER LN',
        RegAddrCity: 'JANESVILLE',
        RegAddrState: 'WI',
        RegAddrZip: 53545,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '624 HARDING ST',
        RegAddrCity: 'JANESVILLE',
        RegAddrState: 'WI',
        RegAddrZip: 53545,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1524 SOUTHRIDGE DR',
        RegAddrCity: 'JANESVILLE',
        RegAddrState: 'WI',
        RegAddrZip: 53546,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '769 N WRIGHT RD',
        RegAddrCity: 'JANESVILLE',
        RegAddrState: 'WI',
        RegAddrZip: 53546,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1911 8TH PL',
        RegAddrCity: 'KENOSHA',
        RegAddrState: 'WI',
        RegAddrZip: 53140,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2115 21ST ST',
        RegAddrCity: 'KENOSHA',
        RegAddrState: 'WI',
        RegAddrZip: 53140,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '7005 37TH AVE',
        RegAddrCity: 'KENOSHA',
        RegAddrState: 'WI',
        RegAddrZip: 53142,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '5904 80TH PL',
        RegAddrCity: 'KENOSHA',
        RegAddrState: 'WI',
        RegAddrZip: 53142,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '3620 88TH ST',
        RegAddrCity: 'KENOSHA',
        RegAddrState: 'WI',
        RegAddrZip: 53142,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '9807 65TH ST',
        RegAddrCity: 'KENOSHA',
        RegAddrState: 'WI',
        RegAddrZip: 53142,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '6526 7TH AVE',
        RegAddrCity: 'KENOSHA',
        RegAddrState: 'WI',
        RegAddrZip: 53143,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2744 83RD ST',
        RegAddrCity: 'KENOSHA',
        RegAddrState: 'WI',
        RegAddrZip: 53143,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: 'N3303 HAWTHORNE RD',
        RegAddrCity: 'LAKE GENEVA',
        RegAddrState: 'WI',
        RegAddrZip: 53147,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '439 ELM ST',
        RegAddrCity: 'MILTON',
        RegAddrState: 'WI',
        RegAddrZip: 53563,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '920 SUNSET DR',
        RegAddrCity: 'MILTON',
        RegAddrState: 'WI',
        RegAddrZip: 53563,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '3225 W MONTANA ST',
        RegAddrCity: 'MILWAUKEE',
        RegAddrState: 'WI',
        RegAddrZip: 53215,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '3875 W COLLEGE AVE',
        RegAddrCity: 'MILWAUKEE',
        RegAddrState: 'WI',
        RegAddrZip: 53221,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '5341 ZACHARY DR',
        RegAddrCity: 'MT PLEASANT',
        RegAddrState: 'WI',
        RegAddrZip: 53403,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1144 N INDIANA ST',
        RegAddrCity: 'MT PLEASANT',
        RegAddrState: 'WI',
        RegAddrZip: 53405,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '6547 PRIMROSE WAY',
        RegAddrCity: 'MT PLEASANT',
        RegAddrState: 'WI',
        RegAddrZip: 53406,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '935 S STUART RD',
        RegAddrCity: 'MT PLEASANT',
        RegAddrState: 'WI',
        RegAddrZip: 53406,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1271 RIVER PARK CIR E',
        RegAddrCity: 'MUKWONAGO',
        RegAddrState: 'WI',
        RegAddrZip: 53149,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: 'W315S7447 LAKECREST CT',
        RegAddrCity: 'MUKWONAGO',
        RegAddrState: 'WI',
        RegAddrZip: 53149,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: 'S78W20363 PASADENA DR',
        RegAddrCity: 'MUSKEGO',
        RegAddrState: 'WI',
        RegAddrZip: 53150,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: 'W135S6511 WINDSOR RD',
        RegAddrCity: 'MUSKEGO',
        RegAddrState: 'WI',
        RegAddrZip: 53150,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: 'S104W18581 KEISER LN',
        RegAddrCity: 'MUSKEGO',
        RegAddrState: 'WI',
        RegAddrZip: 53150,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '12895 W GRANGE AVE',
        RegAddrCity: 'NEW BERLIN',
        RegAddrState: 'WI',
        RegAddrZip: 53151,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '655 E BONITA DR',
        RegAddrCity: 'OAK CREEK',
        RegAddrState: 'WI',
        RegAddrZip: 53154,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '480 E ARTHUR DR',
        RegAddrCity: 'OAK CREEK',
        RegAddrState: 'WI',
        RegAddrZip: 53154,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2455 W HEAVEN VIEW LN',
        RegAddrCity: 'OAK CREEK',
        RegAddrState: 'WI',
        RegAddrZip: 53154,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '11129 11TH AVE',
        RegAddrCity: 'PLEASANT PR',
        RegAddrState: 'WI',
        RegAddrZip: 53158,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '5217 WILLOWVIEW RD',
        RegAddrCity: 'RACINE',
        RegAddrState: 'WI',
        RegAddrZip: 53402,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '949 WASHINGTON AVE',
        RegAddrCity: 'RACINE',
        RegAddrState: 'WI',
        RegAddrZip: 53403,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1937 HOWE ST',
        RegAddrCity: 'RACINE',
        RegAddrState: 'WI',
        RegAddrZip: 53403,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1909 QUINCY AVE',
        RegAddrCity: 'RACINE',
        RegAddrState: 'WI',
        RegAddrZip: 53403,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2527 BATE ST',
        RegAddrCity: 'RACINE',
        RegAddrState: 'WI',
        RegAddrZip: 53403,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '4621 15TH ST',
        RegAddrCity: 'RACINE',
        RegAddrState: 'WI',
        RegAddrZip: 53405,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '27218 94TH ST',
        RegAddrCity: 'SALEM',
        RegAddrState: 'WI',
        RegAddrZip: 53168,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '9975 269TH CT',
        RegAddrCity: 'TREVOR',
        RegAddrState: 'WI',
        RegAddrZip: 53179,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '331 HOWARD ST',
        RegAddrCity: 'WALWORTH',
        RegAddrState: 'WI',
        RegAddrZip: 53184,
        CongressionalDistrict: 1,
      },
    ],
  },
  {
    name: 'WI-2',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/WI-2?ref=gh-pages',
    state: 'WI',
    state_and_number: 'WI-2',
    zip: 53501,
    congressionalDistrict: 2,
    addressesInDistrict: [
      {
        RegAddrLine1: '353 BLAKE ST',
        RegAddrCity: 'BARABOO',
        RegAddrState: 'WI',
        RegAddrZip: 53913,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '742 BAYLISS AVE',
        RegAddrCity: 'BELOIT',
        RegAddrState: 'WI',
        RegAddrZip: 53511,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1662 OAKWOOD AVE',
        RegAddrCity: 'BELOIT',
        RegAddrState: 'WI',
        RegAddrZip: 53511,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '909 KEELER AVE',
        RegAddrCity: 'BELOIT',
        RegAddrState: 'WI',
        RegAddrZip: 53511,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1116 VINE ST',
        RegAddrCity: 'BELOIT',
        RegAddrState: 'WI',
        RegAddrZip: 53511,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '116 MOORE ST',
        RegAddrCity: 'BELOIT',
        RegAddrState: 'WI',
        RegAddrZip: 53511,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: 'N4222 PANDOW DR',
        RegAddrCity: 'BRODHEAD',
        RegAddrState: 'WI',
        RegAddrZip: 53520,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: 'W2227 ALPINE RD',
        RegAddrCity: 'BROOKLYN',
        RegAddrState: 'WI',
        RegAddrZip: 53521,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '113 N MILL ST',
        RegAddrCity: 'BROWNTOWN',
        RegAddrState: 'WI',
        RegAddrZip: 53522,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '210 SPRING ST',
        RegAddrCity: 'CAMBRIDGE',
        RegAddrState: 'WI',
        RegAddrZip: 53523,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1516 MAIN ST',
        RegAddrCity: 'CROSS PLAINS',
        RegAddrState: 'WI',
        RegAddrZip: 53528,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '927 SOUTH ST',
        RegAddrCity: 'DEFOREST',
        RegAddrState: 'WI',
        RegAddrZip: 53532,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '7045 BRIDGEMAN RD',
        RegAddrCity: 'DEFOREST',
        RegAddrState: 'WI',
        RegAddrZip: 53532,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '627 SEMINOLE WAY',
        RegAddrCity: 'DEFOREST',
        RegAddrState: 'WI',
        RegAddrZip: 53532,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2840 OSMUNDSEN RD',
        RegAddrCity: 'FITCHBURG',
        RegAddrState: 'WI',
        RegAddrZip: 53711,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '6082 CROIX DR',
        RegAddrCity: 'FITCHBURG',
        RegAddrState: 'WI',
        RegAddrZip: 53719,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '32647 US HWY 14',
        RegAddrCity: 'LONE ROCK',
        RegAddrState: 'WI',
        RegAddrZip: 53556,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '627 N LAKE ST',
        RegAddrCity: 'MADISON',
        RegAddrState: 'WI',
        RegAddrZip: 53703,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '383 STARLING LN',
        RegAddrCity: 'MADISON',
        RegAddrState: 'WI',
        RegAddrZip: 53704,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '209 KENSINGTON DR',
        RegAddrCity: 'MADISON',
        RegAddrState: 'WI',
        RegAddrZip: 53704,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1518 SUMAC DR',
        RegAddrCity: 'MADISON',
        RegAddrState: 'WI',
        RegAddrZip: 53705,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '33 CRAIG AVE',
        RegAddrCity: 'MADISON',
        RegAddrState: 'WI',
        RegAddrZip: 53705,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '102 FRIGATE DR',
        RegAddrCity: 'MADISON',
        RegAddrState: 'WI',
        RegAddrZip: 53705,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '6018 HAMMERSLEY RD',
        RegAddrCity: 'MADISON',
        RegAddrState: 'WI',
        RegAddrZip: 53711,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '702 ONEIDA PL',
        RegAddrCity: 'MADISON',
        RegAddrState: 'WI',
        RegAddrZip: 53711,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2703 MCDIVITT RD',
        RegAddrCity: 'MADISON',
        RegAddrState: 'WI',
        RegAddrZip: 53713,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '9030 ASPEN GROVE LN',
        RegAddrCity: 'MADISON',
        RegAddrState: 'WI',
        RegAddrZip: 53717,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '7213 LONGMEADOW RD',
        RegAddrCity: 'MADISON',
        RegAddrState: 'WI',
        RegAddrZip: 53717,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '510 AZTALAN DR',
        RegAddrCity: 'MADISON',
        RegAddrState: 'WI',
        RegAddrZip: 53718,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '33 LATHROP ST',
        RegAddrCity: 'MADISON',
        RegAddrState: 'WI',
        RegAddrZip: 53726,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '420 WATERLOO RD',
        RegAddrCity: 'MARSHALL',
        RegAddrState: 'WI',
        RegAddrZip: 53559,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '6101 PACIFIC CREST RD',
        RegAddrCity: 'MC FARLAND',
        RegAddrState: 'WI',
        RegAddrZip: 53558,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '6020 SOUTH CT',
        RegAddrCity: 'MC FARLAND',
        RegAddrState: 'WI',
        RegAddrZip: 53558,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '4573 ELLINGTON WAY',
        RegAddrCity: 'MIDDLETON',
        RegAddrState: 'WI',
        RegAddrZip: 53562,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '636 30TH ST',
        RegAddrCity: 'MONROE',
        RegAddrState: 'WI',
        RegAddrZip: 53566,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: 'N3689 COUNTY ROAD N',
        RegAddrCity: 'MONROE',
        RegAddrState: 'WI',
        RegAddrZip: 53566,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '637 WASHINGTON ST',
        RegAddrCity: 'MONTICELLO',
        RegAddrState: 'WI',
        RegAddrZip: 53570,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '117 GLEN VIEW RD',
        RegAddrCity: 'MOUNT HOREB',
        RegAddrState: 'WI',
        RegAddrZip: 53572,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '105 S MAPLE ST',
        RegAddrCity: 'NORTH FREEDOM',
        RegAddrState: 'WI',
        RegAddrZip: 53951,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '661 FRENCH GLN',
        RegAddrCity: 'OREGON',
        RegAddrState: 'WI',
        RegAddrZip: 53575,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '455 CONCORD DR',
        RegAddrCity: 'OREGON',
        RegAddrState: 'WI',
        RegAddrZip: 53575,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '703 FOREST GLEN CIR',
        RegAddrCity: 'PR DU SAC',
        RegAddrState: 'WI',
        RegAddrZip: 53578,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1558 N HOLDEN ST',
        RegAddrCity: 'PRT WASHINGTN',
        RegAddrState: 'WI',
        RegAddrZip: 53074,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '914 JUSTIN CIR',
        RegAddrCity: 'REEDSBURG',
        RegAddrState: 'WI',
        RegAddrZip: 53959,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '283 BROADWAY DR',
        RegAddrCity: 'SUN PRAIRIE',
        RegAddrState: 'WI',
        RegAddrZip: 53590,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2883 HILTON LN',
        RegAddrCity: 'SUN PRAIRIE',
        RegAddrState: 'WI',
        RegAddrZip: 53590,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '157 BROOME ST',
        RegAddrCity: 'SUN PRAIRIE',
        RegAddrState: 'WI',
        RegAddrZip: 53590,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '310 GRANDVIEW DR',
        RegAddrCity: 'SUN PRAIRIE',
        RegAddrState: 'WI',
        RegAddrZip: 53590,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '4804 HELGELAND DR',
        RegAddrCity: 'SUN PRAIRIE',
        RegAddrState: 'WI',
        RegAddrZip: 53590,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '3489 WHYTECLIFF WAY',
        RegAddrCity: 'SUN PRAIRIE',
        RegAddrState: 'WI',
        RegAddrZip: 53590,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '913 RING ST',
        RegAddrCity: 'SUN PRAIRIE',
        RegAddrState: 'WI',
        RegAddrZip: 53590,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '307 7TH ST',
        RegAddrCity: 'WAUNAKEE',
        RegAddrState: 'WI',
        RegAddrZip: 53597,
        CongressionalDistrict: 2,
      },
    ],
  },
  {
    name: 'WI-3',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/WI-3?ref=gh-pages',
    state: 'WI',
    state_and_number: 'WI-3',
    zip: 53518,
    congressionalDistrict: 3,
    addressesInDistrict: [
      {
        RegAddrLine1: '27 KALLENBACH DR',
        RegAddrCity: 'ALMENA',
        RegAddrState: 'WI',
        RegAddrZip: 54805,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '316 W BROWN ST',
        RegAddrCity: 'AUGUSTA',
        RegAddrState: 'WI',
        RegAddrZip: 54722,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '518 N STONE ST',
        RegAddrCity: 'AUGUSTA',
        RegAddrState: 'WI',
        RegAddrZip: 54722,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: 'W5590 COUNTY ROAD EE',
        RegAddrCity: 'BAY CITY',
        RegAddrState: 'WI',
        RegAddrZip: 54723,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1021 E TAFT ST',
        RegAddrCity: 'BLAIR',
        RegAddrState: 'WI',
        RegAddrZip: 54616,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: 'W9773 COUNTY ROAD O',
        RegAddrCity: 'BLK RIVER FLS',
        RegAddrState: 'WI',
        RegAddrZip: 54615,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '29191 COUNTY HIGHWAY X',
        RegAddrCity: 'CADOTT',
        RegAddrState: 'WI',
        RegAddrZip: 54727,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '828 W ELM ST',
        RegAddrCity: 'CHIPPEWA FLS',
        RegAddrState: 'WI',
        RegAddrZip: 54729,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '120 K RON LN',
        RegAddrCity: 'COON VALLEY',
        RegAddrState: 'WI',
        RegAddrZip: 54623,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1777 EVERGREEN CT',
        RegAddrCity: 'CUSTER',
        RegAddrState: 'WI',
        RegAddrZip: 54423,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '107 MEADOW LN',
        RegAddrCity: 'EASTMAN',
        RegAddrState: 'WI',
        RegAddrZip: 54626,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '642 UNIVERSITY DR',
        RegAddrCity: 'EAU CLAIRE',
        RegAddrState: 'WI',
        RegAddrZip: 54701,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '2833 NORTHWINDS DR',
        RegAddrCity: 'EAU CLAIRE',
        RegAddrState: 'WI',
        RegAddrZip: 54701,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '612 CHIPPEWA ST',
        RegAddrCity: 'EAU CLAIRE',
        RegAddrState: 'WI',
        RegAddrZip: 54703,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1725 9TH ST',
        RegAddrCity: 'EAU CLAIRE',
        RegAddrState: 'WI',
        RegAddrZip: 54703,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '5574 N SHORE DR',
        RegAddrCity: 'EAU CLAIRE',
        RegAddrState: 'WI',
        RegAddrZip: 54703,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: 'N7004 810TH ST',
        RegAddrCity: 'ELK MOUND',
        RegAddrState: 'WI',
        RegAddrZip: 54739,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: 'W8610 420TH AVE',
        RegAddrCity: 'ELLSWORTH',
        RegAddrState: 'WI',
        RegAddrZip: 54011,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: 'W1031 610TH AVE',
        RegAddrCity: 'ELMWOOD',
        RegAddrState: 'WI',
        RegAddrZip: 54740,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: 'E16425 LINCOLN DR',
        RegAddrCity: 'FALL CREEK',
        RegAddrState: 'WI',
        RegAddrZip: 54742,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: 'S3558 COUNTY ROAD M',
        RegAddrCity: 'FOUNTAIN CITY',
        RegAddrState: 'WI',
        RegAddrZip: 54629,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '8688 PRIDE RD',
        RegAddrCity: 'GLEN HAVEN',
        RegAddrState: 'WI',
        RegAddrZip: 53810,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '904 WINCHESTER LN',
        RegAddrCity: 'HOLMEN',
        RegAddrState: 'WI',
        RegAddrZip: 54636,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '2599 ROSE RD',
        RegAddrCity: 'JUNCTION CITY',
        RegAddrState: 'WI',
        RegAddrZip: 54443,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1602 GLADYS ST',
        RegAddrCity: 'LA CROSSE',
        RegAddrState: 'WI',
        RegAddrZip: 54601,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '2227 LOSEY BLVD S',
        RegAddrCity: 'LA CROSSE',
        RegAddrState: 'WI',
        RegAddrZip: 54601,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '2814 BLACKHAWK PL',
        RegAddrCity: 'LA CROSSE',
        RegAddrState: 'WI',
        RegAddrZip: 54601,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '720 LIVINGSTON ST',
        RegAddrCity: 'LA CROSSE',
        RegAddrState: 'WI',
        RegAddrZip: 54603,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '512 WINDSOR ST',
        RegAddrCity: 'LA CROSSE',
        RegAddrState: 'WI',
        RegAddrZip: 54603,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '850 CITY LIMITS RD',
        RegAddrCity: 'LANCASTER',
        RegAddrState: 'WI',
        RegAddrZip: 53813,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: 'W2073 AMHERST DR',
        RegAddrCity: 'LYNDON STATION',
        RegAddrState: 'WI',
        RegAddrZip: 53944,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: 'S436 WULFF RD',
        RegAddrCity: 'MONDOVI',
        RegAddrState: 'WI',
        RegAddrZip: 54755,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: 'PO BOX 324',
        RegAddrCity: 'MONTFORT',
        RegAddrState: 'WI',
        RegAddrZip: 53569,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '751 KIMBALL AVE',
        RegAddrCity: 'NEKOOSA',
        RegAddrState: 'WI',
        RegAddrZip: 54457,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '867 OLYMPIC DR',
        RegAddrCity: 'ONALASKA',
        RegAddrState: 'WI',
        RegAddrZip: 54650,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '412 OAK AVE N',
        RegAddrCity: 'ONALASKA',
        RegAddrState: 'WI',
        RegAddrZip: 54650,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: 'N5430 CIRCLE DR E',
        RegAddrCity: 'ONALASKA',
        RegAddrState: 'WI',
        RegAddrZip: 54650,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '401 N GARDEN ST',
        RegAddrCity: 'ONTARIO',
        RegAddrState: 'WI',
        RegAddrZip: 54651,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '2801 PLOVER SPRINGS DR',
        RegAddrCity: 'PLOVER',
        RegAddrState: 'WI',
        RegAddrZip: 54467,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '6728 COUNTY ROAD N',
        RegAddrCity: 'POTOSI',
        RegAddrState: 'WI',
        RegAddrZip: 53820,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '901 RIVER TER',
        RegAddrCity: 'PRESCOTT',
        RegAddrState: 'WI',
        RegAddrZip: 54021,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '584 LOCUST ST S',
        RegAddrCity: 'PRESCOTT',
        RegAddrState: 'WI',
        RegAddrZip: 54021,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '605 W MILL ST',
        RegAddrCity: 'READSTOWN',
        RegAddrState: 'WI',
        RegAddrZip: 54652,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '411 S EAST ST',
        RegAddrCity: 'RICHLAND CTR',
        RegAddrState: 'WI',
        RegAddrZip: 53581,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '21626 SALOUTOS LN',
        RegAddrCity: 'RICHLAND CTR',
        RegAddrState: 'WI',
        RegAddrZip: 53581,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '709 BARTOSH LN',
        RegAddrCity: 'RIVER FALLS',
        RegAddrState: 'WI',
        RegAddrZip: 54022,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '16133 HASSOCK AVE',
        RegAddrCity: 'SPARTA',
        RegAddrState: 'WI',
        RegAddrZip: 54656,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '201 RESERVE ST',
        RegAddrCity: 'STEVENS POINT',
        RegAddrState: 'WI',
        RegAddrZip: 54481,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: 'N6288 N SKUTLEY RD',
        RegAddrCity: 'TAYLOR',
        RegAddrState: 'WI',
        RegAddrZip: 54659,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '715 ROGERS AVE',
        RegAddrCity: 'VIROQUA',
        RegAddrState: 'WI',
        RegAddrZip: 54665,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: 'W3075 OLSON RD',
        RegAddrCity: 'WARRENS',
        RegAddrState: 'WI',
        RegAddrZip: 54666,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '708 PIONEER RD',
        RegAddrCity: 'WILSON',
        RegAddrState: 'WI',
        RegAddrZip: 54027,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '7033 LUNDBERG RD',
        RegAddrCity: 'WISC RAPIDS',
        RegAddrState: 'WI',
        RegAddrZip: 54495,
        CongressionalDistrict: 3,
      },
    ],
  },
  {
    name: 'WI-4',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/WI-4?ref=gh-pages',
    state: 'WI',
    state_and_number: 'WI-4',
    zip: 53051,
    congressionalDistrict: 4,
    addressesInDistrict: [
      {
        RegAddrLine1: '8184 N 44TH ST',
        RegAddrCity: 'BROWN DEER',
        RegAddrState: 'WI',
        RegAddrZip: 53223,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '9205 N PEARLETTE LN',
        RegAddrCity: 'BROWN DEER',
        RegAddrState: 'WI',
        RegAddrZip: 53223,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2911 E VOGEL AVE',
        RegAddrCity: 'CUDAHY',
        RegAddrState: 'WI',
        RegAddrZip: 53110,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '6150 N ALBERTA LN',
        RegAddrCity: 'GLENDALE',
        RegAddrState: 'WI',
        RegAddrZip: 53217,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1541 S 6TH ST',
        RegAddrCity: 'MILWAUKEE',
        RegAddrState: 'WI',
        RegAddrZip: 53204,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2310 W NATIONAL AVE',
        RegAddrCity: 'MILWAUKEE',
        RegAddrState: 'WI',
        RegAddrZip: 53204,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2016 W GREENFIELD AVE',
        RegAddrCity: 'MILWAUKEE',
        RegAddrState: 'WI',
        RegAddrZip: 53204,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2422 W MINERAL ST',
        RegAddrCity: 'MILWAUKEE',
        RegAddrState: 'WI',
        RegAddrZip: 53204,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2917 N 19TH ST',
        RegAddrCity: 'MILWAUKEE',
        RegAddrState: 'WI',
        RegAddrZip: 53206,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '3954 N 25TH ST',
        RegAddrCity: 'MILWAUKEE',
        RegAddrState: 'WI',
        RegAddrZip: 53206,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '3930 N 12TH ST',
        RegAddrCity: 'MILWAUKEE',
        RegAddrState: 'WI',
        RegAddrZip: 53206,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2868 N 25TH ST',
        RegAddrCity: 'MILWAUKEE',
        RegAddrState: 'WI',
        RegAddrZip: 53206,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '3154 S MABBETT AVE',
        RegAddrCity: 'MILWAUKEE',
        RegAddrState: 'WI',
        RegAddrZip: 53207,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '3547 S PINE AVE',
        RegAddrCity: 'MILWAUKEE',
        RegAddrState: 'WI',
        RegAddrZip: 53207,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2180 S ROBINSON AVE',
        RegAddrCity: 'MILWAUKEE',
        RegAddrState: 'WI',
        RegAddrZip: 53207,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '4048 S PINE AVE',
        RegAddrCity: 'MILWAUKEE',
        RegAddrState: 'WI',
        RegAddrZip: 53207,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '211 E DEER PL',
        RegAddrCity: 'MILWAUKEE',
        RegAddrState: 'WI',
        RegAddrZip: 53207,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2226 E OKLAHOMA AVE',
        RegAddrCity: 'MILWAUKEE',
        RegAddrState: 'WI',
        RegAddrZip: 53207,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2229 N 34TH ST',
        RegAddrCity: 'MILWAUKEE',
        RegAddrState: 'WI',
        RegAddrZip: 53208,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '4732 N 30TH ST',
        RegAddrCity: 'MILWAUKEE',
        RegAddrState: 'WI',
        RegAddrZip: 53209,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '5249 N 33RD ST',
        RegAddrCity: 'MILWAUKEE',
        RegAddrState: 'WI',
        RegAddrZip: 53209,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '3423 W HAMPTON AVE',
        RegAddrCity: 'MILWAUKEE',
        RegAddrState: 'WI',
        RegAddrZip: 53209,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '4067 N 15TH ST',
        RegAddrCity: 'MILWAUKEE',
        RegAddrState: 'WI',
        RegAddrZip: 53209,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '3042 N 63RD ST',
        RegAddrCity: 'MILWAUKEE',
        RegAddrState: 'WI',
        RegAddrZip: 53210,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2863 N 41ST ST',
        RegAddrCity: 'MILWAUKEE',
        RegAddrState: 'WI',
        RegAddrZip: 53210,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '902 E CHAMBERS ST',
        RegAddrCity: 'MILWAUKEE',
        RegAddrState: 'WI',
        RegAddrZip: 53212,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2327 N BUFFUM ST',
        RegAddrCity: 'MILWAUKEE',
        RegAddrState: 'WI',
        RegAddrZip: 53212,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2967 N 2ND ST',
        RegAddrCity: 'MILWAUKEE',
        RegAddrState: 'WI',
        RegAddrZip: 53212,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '8817 W ADLER ST',
        RegAddrCity: 'MILWAUKEE',
        RegAddrState: 'WI',
        RegAddrZip: 53214,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2203 W FOREST HOME AVE',
        RegAddrCity: 'MILWAUKEE',
        RegAddrState: 'WI',
        RegAddrZip: 53215,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '3306 W ARTHUR AVE',
        RegAddrCity: 'MILWAUKEE',
        RegAddrState: 'WI',
        RegAddrZip: 53215,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1213 S 36TH ST',
        RegAddrCity: 'MILWAUKEE',
        RegAddrState: 'WI',
        RegAddrZip: 53215,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '3135 N 53RD ST',
        RegAddrCity: 'MILWAUKEE',
        RegAddrState: 'WI',
        RegAddrZip: 53216,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '3424 N 48TH ST',
        RegAddrCity: 'MILWAUKEE',
        RegAddrState: 'WI',
        RegAddrZip: 53216,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '5423 N 67TH ST',
        RegAddrCity: 'MILWAUKEE',
        RegAddrState: 'WI',
        RegAddrZip: 53218,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '5768 N 81ST ST',
        RegAddrCity: 'MILWAUKEE',
        RegAddrState: 'WI',
        RegAddrZip: 53218,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '4835 N 45TH ST',
        RegAddrCity: 'MILWAUKEE',
        RegAddrState: 'WI',
        RegAddrZip: 53218,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '8301 W VILLARD AVE',
        RegAddrCity: 'MILWAUKEE',
        RegAddrState: 'WI',
        RegAddrZip: 53218,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '4512 N 58TH ST',
        RegAddrCity: 'MILWAUKEE',
        RegAddrState: 'WI',
        RegAddrZip: 53218,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '3108 S 53RD ST',
        RegAddrCity: 'MILWAUKEE',
        RegAddrState: 'WI',
        RegAddrZip: 53219,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2851 S 51ST ST',
        RegAddrCity: 'MILWAUKEE',
        RegAddrState: 'WI',
        RegAddrZip: 53219,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '6300 W EUCLID AVE',
        RegAddrCity: 'MILWAUKEE',
        RegAddrState: 'WI',
        RegAddrZip: 53219,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '3833 S 52ND ST',
        RegAddrCity: 'MILWAUKEE',
        RegAddrState: 'WI',
        RegAddrZip: 53220,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '9015 W KEEFE AVE',
        RegAddrCity: 'MILWAUKEE',
        RegAddrState: 'WI',
        RegAddrZip: 53222,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '3028 N 83RD ST',
        RegAddrCity: 'MILWAUKEE',
        RegAddrState: 'WI',
        RegAddrZip: 53222,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '9818 W DARNEL AVE',
        RegAddrCity: 'MILWAUKEE',
        RegAddrState: 'WI',
        RegAddrZip: 53224,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '8722 W CONGRESS ST',
        RegAddrCity: 'MILWAUKEE',
        RegAddrState: 'WI',
        RegAddrZip: 53225,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2454 W KILBOURN AVE',
        RegAddrCity: 'MILWAUKEE',
        RegAddrState: 'WI',
        RegAddrZip: 53233,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '1401 RAWSON AVE',
        RegAddrCity: 'S MILWAUKEE',
        RegAddrState: 'WI',
        RegAddrZip: 53172,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '2509 S 70TH ST',
        RegAddrCity: 'WEST ALLIS',
        RegAddrState: 'WI',
        RegAddrZip: 53219,
        CongressionalDistrict: 4,
      },
      {
        RegAddrLine1: '5039 N IDLEWILD AVE',
        RegAddrCity: 'WHITEFISH BAY',
        RegAddrState: 'WI',
        RegAddrZip: 53217,
        CongressionalDistrict: 4,
      },
    ],
  },
  {
    name: 'WI-5',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/WI-5?ref=gh-pages',
    state: 'WI',
    state_and_number: 'WI-5',
    zip: 53002,
    congressionalDistrict: 5,
    addressesInDistrict: [
      {
        RegAddrLine1: '2460 SHERATON RD',
        RegAddrCity: 'BROOKFIELD',
        RegAddrState: 'WI',
        RegAddrZip: 53005,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '14310 WOODMOUNT DR',
        RegAddrCity: 'BROOKFIELD',
        RegAddrState: 'WI',
        RegAddrZip: 53005,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '280 N ROSEDALE DR',
        RegAddrCity: 'BROOKFIELD',
        RegAddrState: 'WI',
        RegAddrZip: 53005,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '17495 SIERRA LN',
        RegAddrCity: 'BROOKFIELD',
        RegAddrState: 'WI',
        RegAddrZip: 53045,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '3348 NAGAWICKA AVE',
        RegAddrCity: 'DELAFIELD',
        RegAddrState: 'WI',
        RegAddrZip: 53018,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '118 ROBERT ST',
        RegAddrCity: 'FORT ATKINSON',
        RegAddrState: 'WI',
        RegAddrZip: 53538,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: 'N119W15496 DANIELS DR',
        RegAddrCity: 'GERMANTOWN',
        RegAddrState: 'WI',
        RegAddrZip: 53022,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '5111 S 43RD ST',
        RegAddrCity: 'GREENFIELD',
        RegAddrState: 'WI',
        RegAddrZip: 53220,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '2102 STATE ROAD 83',
        RegAddrCity: 'HARTFORD',
        RegAddrState: 'WI',
        RegAddrZip: 53027,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '5696 COUNTY ROAD U',
        RegAddrCity: 'HARTFORD',
        RegAddrState: 'WI',
        RegAddrZip: 53027,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: 'N83W28315 CENTER OAK RD',
        RegAddrCity: 'HARTLAND',
        RegAddrState: 'WI',
        RegAddrZip: 53029,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '353 WESTLYN CT',
        RegAddrCity: 'HARTLAND',
        RegAddrState: 'WI',
        RegAddrZip: 53029,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '2026 HUMMINGBIRD DR',
        RegAddrCity: 'JACKSON',
        RegAddrState: 'WI',
        RegAddrZip: 53037,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '1992 ELM TREE DR',
        RegAddrCity: 'KEWASKUM',
        RegAddrState: 'WI',
        RegAddrZip: 53040,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: 'W141N4899 GOLDEN FIELDS DR',
        RegAddrCity: 'MENOMONEE FLS',
        RegAddrState: 'WI',
        RegAddrZip: 53051,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: 'W179N8628 VILLAGE CT',
        RegAddrCity: 'MENOMONEE FLS',
        RegAddrState: 'WI',
        RegAddrZip: 53051,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '1912 E BENNETT AVE',
        RegAddrCity: 'MILWAUKEE',
        RegAddrState: 'WI',
        RegAddrZip: 53207,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '13660 W THOMAS DR',
        RegAddrCity: 'NEW BERLIN',
        RegAddrState: 'WI',
        RegAddrZip: 53151,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '1601 S WILDWOOD DR',
        RegAddrCity: 'NEW BERLIN',
        RegAddrState: 'WI',
        RegAddrZip: 53151,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '14939 W ARROWHEAD LN',
        RegAddrCity: 'NEW BERLIN',
        RegAddrState: 'WI',
        RegAddrZip: 53151,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: 'W393N6018 MARY LN',
        RegAddrCity: 'OCONOMOWOC',
        RegAddrState: 'WI',
        RegAddrZip: 53066,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '1288 NORTHVIEW DR',
        RegAddrCity: 'OCONOMOWOC',
        RegAddrState: 'WI',
        RegAddrZip: 53066,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: 'W335 DELAFIELD RD',
        RegAddrCity: 'OCONOMOWOC',
        RegAddrState: 'WI',
        RegAddrZip: 53066,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: 'W389N7534 PENNSYLVANIA ST',
        RegAddrCity: 'OCONOMOWOC',
        RegAddrState: 'WI',
        RegAddrZip: 53066,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: 'W267N5403 CARLENE DR',
        RegAddrCity: 'PEWAUKEE',
        RegAddrState: 'WI',
        RegAddrZip: 53072,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: 'W285N3280 LAKESIDE RIDGE CT',
        RegAddrCity: 'PEWAUKEE',
        RegAddrState: 'WI',
        RegAddrZip: 53072,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: 'W268N1964 SHOOTING STAR RD',
        RegAddrCity: 'PEWAUKEE',
        RegAddrState: 'WI',
        RegAddrZip: 53072,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: 'N39W23496 BROKEN HILL CIR N',
        RegAddrCity: 'PEWAUKEE',
        RegAddrState: 'WI',
        RegAddrZip: 53072,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '3453 BARK LAKE RD HUBERTUS',
        RegAddrCity: 'RICHFIELD',
        RegAddrState: 'WI',
        RegAddrZip: 53076,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: 'N57W26289 MOUNTAIN MEADOWS DR',
        RegAddrCity: 'SUSSEX',
        RegAddrState: 'WI',
        RegAddrZip: 53089,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '3410 RIDGELINE DR',
        RegAddrCity: 'WAUKESHA',
        RegAddrState: 'WI',
        RegAddrZip: 53188,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: 'W236N1196 KINGS CT',
        RegAddrCity: 'WAUKESHA',
        RegAddrState: 'WI',
        RegAddrZip: 53188,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '825 MADISON ST',
        RegAddrCity: 'WAUKESHA',
        RegAddrState: 'WI',
        RegAddrZip: 53188,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '2906 ROCKY CREEK DR',
        RegAddrCity: 'WAUKESHA',
        RegAddrState: 'WI',
        RegAddrZip: 53189,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '2628 N 83RD ST',
        RegAddrCity: 'WAUWATOSA',
        RegAddrState: 'WI',
        RegAddrZip: 53213,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '11805 W POTTER RD',
        RegAddrCity: 'WAUWATOSA',
        RegAddrState: 'WI',
        RegAddrZip: 53226,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '11816 W BLUEMOUND RD',
        RegAddrCity: 'WAUWATOSA',
        RegAddrState: 'WI',
        RegAddrZip: 53226,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '906 S 76TH ST',
        RegAddrCity: 'WEST ALLIS',
        RegAddrState: 'WI',
        RegAddrZip: 53214,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '1654 S 82ND ST',
        RegAddrCity: 'WEST ALLIS',
        RegAddrState: 'WI',
        RegAddrZip: 53214,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '1933 S 97TH ST',
        RegAddrCity: 'WEST ALLIS',
        RegAddrState: 'WI',
        RegAddrZip: 53227,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '12244 W VERONA CT',
        RegAddrCity: 'WEST ALLIS',
        RegAddrState: 'WI',
        RegAddrZip: 53227,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '3021 JOSHUA LN',
        RegAddrCity: 'WEST BEND',
        RegAddrState: 'WI',
        RegAddrZip: 53095,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '4173 SLEEPING DRAGON RD',
        RegAddrCity: 'WEST BEND',
        RegAddrState: 'WI',
        RegAddrZip: 53095,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '2111 CHESTNUT ST',
        RegAddrCity: 'WEST BEND',
        RegAddrState: 'WI',
        RegAddrZip: 53095,
        CongressionalDistrict: 5,
      },
      {
        RegAddrLine1: '291 COBURN LN',
        RegAddrCity: 'WHITEWATER',
        RegAddrState: 'WI',
        RegAddrZip: 53190,
        CongressionalDistrict: 5,
      },
    ],
  },
  {
    name: 'WI-6',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/WI-6?ref=gh-pages',
    state: 'WI',
    state_and_number: 'WI-6',
    zip: 53001,
    congressionalDistrict: 6,
    addressesInDistrict: [
      {
        RegAddrLine1: '113 WISCONSIN ST',
        RegAddrCity: 'ADELL',
        RegAddrState: 'WI',
        RegAddrZip: 53001,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '112 W DAVIS ST',
        RegAddrCity: 'BEAVER DAM',
        RegAddrState: 'WI',
        RegAddrZip: 53916,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: 'W6241 PARK DR',
        RegAddrCity: 'BURNETT',
        RegAddrState: 'WI',
        RegAddrZip: 53922,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '626 W VAN ALTENA AVE',
        RegAddrCity: 'CEDAR GROVE',
        RegAddrState: 'WI',
        RegAddrZip: 53013,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: 'W63N978 HOLLY LN',
        RegAddrCity: 'CEDARBURG',
        RegAddrState: 'WI',
        RegAddrZip: 53012,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: 'N49W7907 WESTERN RD',
        RegAddrCity: 'CEDARBURG',
        RegAddrState: 'WI',
        RegAddrZip: 53012,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '255 MIDDLETON ST',
        RegAddrCity: 'COLUMBUS',
        RegAddrState: 'WI',
        RegAddrZip: 53925,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: 'W1605 HOMESTEAD LN',
        RegAddrCity: 'EDEN',
        RegAddrState: 'WI',
        RegAddrZip: 53019,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '571 E 10TH ST',
        RegAddrCity: 'FOND DU LAC',
        RegAddrState: 'WI',
        RegAddrZip: 54935,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '1030 N PORT WASHINGTON RD',
        RegAddrCity: 'GRAFTON',
        RegAddrState: 'WI',
        RegAddrZip: 53024,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '1118 1ST ST',
        RegAddrCity: 'KIEL',
        RegAddrState: 'WI',
        RegAddrZip: 53042,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '510 SEMINARY ST',
        RegAddrCity: 'LODI',
        RegAddrState: 'WI',
        RegAddrZip: 53555,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: 'W4027 LINDEN DR',
        RegAddrCity: 'MALONE',
        RegAddrState: 'WI',
        RegAddrZip: 53049,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '2409 S ALVERNO RD',
        RegAddrCity: 'MANITOWOC',
        RegAddrState: 'WI',
        RegAddrZip: 54220,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '3620 FOREST CIR',
        RegAddrCity: 'MANITOWOC',
        RegAddrState: 'WI',
        RegAddrZip: 54220,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '2619 KNUELL ST',
        RegAddrCity: 'MANITOWOC',
        RegAddrState: 'WI',
        RegAddrZip: 54220,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '515 WALDO BLVD',
        RegAddrCity: 'MANITOWOC',
        RegAddrState: 'WI',
        RegAddrZip: 54220,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '1002 E CRESCENT DR',
        RegAddrCity: 'MANITOWOC',
        RegAddrState: 'WI',
        RegAddrZip: 54220,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '4306 DELTA ST',
        RegAddrCity: 'MANITOWOC',
        RegAddrState: 'WI',
        RegAddrZip: 54220,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '1060 BARTLEIN CT',
        RegAddrCity: 'MENASHA',
        RegAddrState: 'WI',
        RegAddrZip: 54952,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '505 2ND ST',
        RegAddrCity: 'MENASHA',
        RegAddrState: 'WI',
        RegAddrZip: 54952,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '1476 LAKEVIEW LN',
        RegAddrCity: 'MENASHA',
        RegAddrState: 'WI',
        RegAddrZip: 54952,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '11270 N REVERE RD',
        RegAddrCity: 'MEQUON',
        RegAddrState: 'WI',
        RegAddrZip: 53092,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '8900 W BONNIWELL RD',
        RegAddrCity: 'MEQUON',
        RegAddrState: 'WI',
        RegAddrZip: 53097,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '202 E SAMZ RD',
        RegAddrCity: 'MISHICOT',
        RegAddrState: 'WI',
        RegAddrZip: 54228,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '111 BRIAR DR',
        RegAddrCity: 'NEENAH',
        RegAddrState: 'WI',
        RegAddrZip: 54956,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '215 ALCOTT DR',
        RegAddrCity: 'NEENAH',
        RegAddrState: 'WI',
        RegAddrZip: 54956,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '256 LAKESHORE AVE',
        RegAddrCity: 'NEENAH',
        RegAddrState: 'WI',
        RegAddrZip: 54956,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: 'N815 24TH AVE',
        RegAddrCity: 'NESHKORO',
        RegAddrState: 'WI',
        RegAddrZip: 54960,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '533 WAUGOO AVE',
        RegAddrCity: 'OSHKOSH',
        RegAddrState: 'WI',
        RegAddrZip: 54901,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '1837 OHIO ST',
        RegAddrCity: 'OSHKOSH',
        RegAddrState: 'WI',
        RegAddrZip: 54902,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '4097 WESTVIEW LN',
        RegAddrCity: 'OSHKOSH',
        RegAddrState: 'WI',
        RegAddrZip: 54904,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '2540 W 20TH AVE',
        RegAddrCity: 'OSHKOSH',
        RegAddrState: 'WI',
        RegAddrZip: 54904,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '3116 SHELDON DR',
        RegAddrCity: 'OSHKOSH',
        RegAddrState: 'WI',
        RegAddrZip: 54904,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: 'W10733 OLDEN RD',
        RegAddrCity: 'PICKETT',
        RegAddrState: 'WI',
        RegAddrZip: 54964,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: 'N6018 HILLCREST DR',
        RegAddrCity: 'PLYMOUTH',
        RegAddrState: 'WI',
        RegAddrZip: 53073,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '430 N HIGHLAND AVE',
        RegAddrCity: 'PLYMOUTH',
        RegAddrState: 'WI',
        RegAddrZip: 53073,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '2420 HAMILTON ST',
        RegAddrCity: 'PORTAGE',
        RegAddrState: 'WI',
        RegAddrZip: 53901,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '2805 HUNTERS TRL',
        RegAddrCity: 'PORTAGE',
        RegAddrState: 'WI',
        RegAddrZip: 53901,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: 'W3930 COUNTY ROAD T',
        RegAddrCity: 'PRINCETON',
        RegAddrState: 'WI',
        RegAddrZip: 54968,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: 'W1396 STATE ROAD 23',
        RegAddrCity: 'PRINCETON',
        RegAddrState: 'WI',
        RegAddrZip: 54968,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '524 BRENTWOOD CT',
        RegAddrCity: 'PRT WASHINGTN',
        RegAddrState: 'WI',
        RegAddrZip: 53074,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: 'W4402 LONG CROSSING RD',
        RegAddrCity: 'RIO',
        RegAddrState: 'WI',
        RegAddrZip: 53960,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '38 WA WA AVE',
        RegAddrCity: 'RIPON',
        RegAddrState: 'WI',
        RegAddrZip: 54971,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '2118 N 12TH ST',
        RegAddrCity: 'SHEBOYGAN',
        RegAddrState: 'WI',
        RegAddrZip: 53081,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '2517 S 9TH ST',
        RegAddrCity: 'SHEBOYGAN',
        RegAddrState: 'WI',
        RegAddrZip: 53081,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: 'N8168 DAIRYLAND DR',
        RegAddrCity: 'SHEBOYGAN',
        RegAddrState: 'WI',
        RegAddrZip: 53083,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '4311 JENS RD',
        RegAddrCity: 'SHEBOYGAN',
        RegAddrState: 'WI',
        RegAddrZip: 53083,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '816 FOREST BLVD',
        RegAddrCity: 'SHEBOYGAN FLS',
        RegAddrState: 'WI',
        RegAddrZip: 53085,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '115 ASHLAND CT',
        RegAddrCity: 'SHEBOYGAN FLS',
        RegAddrState: 'WI',
        RegAddrZip: 53085,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '1410 CHADSWORTH DR',
        RegAddrCity: 'SUN PRAIRIE',
        RegAddrState: 'WI',
        RegAddrZip: 53590,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '1609 17TH ST',
        RegAddrCity: 'TWO RIVERS',
        RegAddrState: 'WI',
        RegAddrZip: 54241,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '2005 HAWTHORNE AVE',
        RegAddrCity: 'TWO RIVERS',
        RegAddrState: 'WI',
        RegAddrZip: 54241,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: 'W11449 CATHERINE CT',
        RegAddrCity: 'WAUPUN',
        RegAddrState: 'WI',
        RegAddrZip: 53963,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: '109 WELCH ST',
        RegAddrCity: 'WAUPUN',
        RegAddrState: 'WI',
        RegAddrZip: 53963,
        CongressionalDistrict: 6,
      },
      {
        RegAddrLine1: 'N9946 RIVER RD',
        RegAddrCity: 'WISC DELLS',
        RegAddrState: 'WI',
        RegAddrZip: 53965,
        CongressionalDistrict: 6,
      },
    ],
  },
  {
    name: 'WI-7',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/WI-7?ref=gh-pages',
    state: 'WI',
    state_and_number: 'WI-7',
    zip: 54001,
    congressionalDistrict: 7,
    addressesInDistrict: [
      {
        RegAddrLine1: '600 E ELM ST',
        RegAddrCity: 'ABBOTSFORD',
        RegAddrState: 'WI',
        RegAddrZip: 54405,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '8225 COUNTY ROAD A',
        RegAddrCity: 'ALVIN',
        RegAddrState: 'WI',
        RegAddrZip: 54542,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: 'N3310 HIGHLAND RD',
        RegAddrCity: 'ANTIGO',
        RegAddrState: 'WI',
        RegAddrZip: 54409,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '11086 US HIGHWAY 51',
        RegAddrCity: 'ARBOR VITAE',
        RegAddrState: 'WI',
        RegAddrZip: 54568,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '68254 OLD ODANAH RD',
        RegAddrCity: 'ASHLAND',
        RegAddrState: 'WI',
        RegAddrZip: 54806,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '58 1/2 S 6TH ST',
        RegAddrCity: 'BARRON',
        RegAddrState: 'WI',
        RegAddrZip: 54812,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '83541 BALSAM LN',
        RegAddrCity: 'BUTTERNUT',
        RegAddrState: 'WI',
        RegAddrZip: 54514,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '320 BABES LN',
        RegAddrCity: 'CAMERON',
        RegAddrState: 'WI',
        RegAddrZip: 54822,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '2148 200TH ST',
        RegAddrCity: 'CENTURIA',
        RegAddrState: 'WI',
        RegAddrZip: 54824,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '2505 10 1/4 AVE',
        RegAddrCity: 'CHETEK',
        RegAddrState: 'WI',
        RegAddrZip: 54728,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '2096 7TH ST',
        RegAddrCity: 'CUMBERLAND',
        RegAddrState: 'WI',
        RegAddrZip: 54829,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: 'W14377 US HIGHWAY 10',
        RegAddrCity: 'FAIRCHILD',
        RegAddrState: 'WI',
        RegAddrZip: 54741,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: 'N7858 COUNTY ROAD H',
        RegAddrCity: 'GLEASON',
        RegAddrState: 'WI',
        RegAddrZip: 54435,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: 'N5770 MILL ST',
        RegAddrCity: 'GLEN FLORA',
        RegAddrState: 'WI',
        RegAddrZip: 54526,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '173160 KONKOL RD',
        RegAddrCity: 'HATLEY',
        RegAddrState: 'WI',
        RegAddrZip: 54440,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '15520 W COUNTY ROAD B',
        RegAddrCity: 'HAYWARD',
        RegAddrState: 'WI',
        RegAddrZip: 54843,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '10676 N COUNTY ROAD K',
        RegAddrCity: 'HAYWARD',
        RegAddrState: 'WI',
        RegAddrZip: 54843,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '9902 N STATE ROAD 27',
        RegAddrCity: 'HAYWARD',
        RegAddrState: 'WI',
        RegAddrZip: 54843,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '406 HUNTER HILL RD',
        RegAddrCity: 'HUDSON',
        RegAddrState: 'WI',
        RegAddrZip: 54016,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '463 W OMAHA RD',
        RegAddrCity: 'HUDSON',
        RegAddrState: 'WI',
        RegAddrZip: 54016,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '8280 COUNTY HIGHWAY A',
        RegAddrCity: 'IRON RIVER',
        RegAddrState: 'WI',
        RegAddrZip: 54847,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '8297 S MINNESUING ACRES DR',
        RegAddrCity: 'LK NEBAGAMON',
        RegAddrState: 'WI',
        RegAddrZip: 54849,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '141955 COUNTY RD N',
        RegAddrCity: 'MARATHON',
        RegAddrState: 'WI',
        RegAddrZip: 54448,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '507 W 5TH ST',
        RegAddrCity: 'MARSHFIELD',
        RegAddrState: 'WI',
        RegAddrZip: 54449,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '39982 ASHLAND BAYFIELD RD',
        RegAddrCity: 'MASON',
        RegAddrState: 'WI',
        RegAddrZip: 54856,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: 'N4491 CASTLE RD',
        RegAddrCity: 'MEDFORD',
        RegAddrState: 'WI',
        RegAddrZip: 54451,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: 'N2343 GEN DR',
        RegAddrCity: 'MERRILL',
        RegAddrState: 'WI',
        RegAddrZip: 54452,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '400 W 17TH ST',
        RegAddrCity: 'NEILLSVILLE',
        RegAddrState: 'WI',
        RegAddrZip: 54456,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: 'W5949 WHISTLING WING DR',
        RegAddrCity: 'NEW LISBON',
        RegAddrState: 'WI',
        RegAddrZip: 53950,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '1822 143RD ST',
        RegAddrCity: 'NEW RICHMOND',
        RegAddrState: 'WI',
        RegAddrZip: 54017,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '1691 HIGHWAY 64',
        RegAddrCity: 'NEW RICHMOND',
        RegAddrState: 'WI',
        RegAddrZip: 54017,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '1462 142ND ST',
        RegAddrCity: 'NEW RICHMOND',
        RegAddrState: 'WI',
        RegAddrZip: 54017,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '1671 POSSUM WAY',
        RegAddrCity: 'NEW RICHMOND',
        RegAddrState: 'WI',
        RegAddrZip: 54017,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '1410 FALCON PL',
        RegAddrCity: 'NEW RICHMOND',
        RegAddrState: 'WI',
        RegAddrZip: 54017,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: 'W8460 MAPLE RD',
        RegAddrCity: 'PARK FALLS',
        RegAddrState: 'WI',
        RegAddrZip: 54552,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '6785 MAPLE RD',
        RegAddrCity: 'RHINELANDER',
        RegAddrState: 'WI',
        RegAddrZip: 54501,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '403 NOBLE AVE',
        RegAddrCity: 'RICE LAKE',
        RegAddrState: 'WI',
        RegAddrZip: 54868,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '7804 FISH LAKE RD',
        RegAddrCity: 'SIREN',
        RegAddrState: 'WI',
        RegAddrZip: 54872,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '603 N ADAMS ST',
        RegAddrCity: 'ST CROIX FLS',
        RegAddrState: 'WI',
        RegAddrZip: 54024,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '4730 S DARROW RD',
        RegAddrCity: 'SUPERIOR',
        RegAddrState: 'WI',
        RegAddrZip: 54880,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '2222 TOWER AVE',
        RegAddrCity: 'SUPERIOR',
        RegAddrState: 'WI',
        RegAddrZip: 54880,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '1715 N 26TH ST',
        RegAddrCity: 'SUPERIOR',
        RegAddrState: 'WI',
        RegAddrZip: 54880,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: 'W5413 ROLLING RIDGE RD',
        RegAddrCity: 'TOMAHAWK',
        RegAddrState: 'WI',
        RegAddrZip: 54487,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '219 CHURCH ST',
        RegAddrCity: 'WARRENS',
        RegAddrState: 'WI',
        RegAddrZip: 54666,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '152722 S MOUNTAIN RD',
        RegAddrCity: 'WAUSAU',
        RegAddrState: 'WI',
        RegAddrZip: 54401,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '2260 CIRCLE DR',
        RegAddrCity: 'WAUSAU',
        RegAddrState: 'WI',
        RegAddrZip: 54401,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '813 W STROWBRIDGE ST',
        RegAddrCity: 'WAUSAU',
        RegAddrState: 'WI',
        RegAddrZip: 54401,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '1209 EASTHILL DR',
        RegAddrCity: 'WAUSAU',
        RegAddrState: 'WI',
        RegAddrZip: 54403,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: 'W8313 COUNTY ROAD D',
        RegAddrCity: 'WESTBORO',
        RegAddrState: 'WI',
        RegAddrZip: 54490,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: '2711 NEUPERT AVE',
        RegAddrCity: 'WESTON',
        RegAddrState: 'WI',
        RegAddrZip: 54476,
        CongressionalDistrict: 7,
      },
      {
        RegAddrLine1: 'N3520 CHURCH ST',
        RegAddrCity: 'WHITE LAKE',
        RegAddrState: 'WI',
        RegAddrZip: 54491,
        CongressionalDistrict: 7,
      },
    ],
  },
  {
    name: 'WI-8',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/WI-8?ref=gh-pages',
    state: 'WI',
    state_and_number: 'WI-8',
    zip: 53088,
    congressionalDistrict: 8,
    addressesInDistrict: [
      {
        RegAddrLine1: '716 DIVISION ST',
        RegAddrCity: 'ALGOMA',
        RegAddrState: 'WI',
        RegAddrZip: 54201,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '514 W PARKWAY BLVD',
        RegAddrCity: 'APPLETON',
        RegAddrState: 'WI',
        RegAddrZip: 54911,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '74 NORTHBREEZE DR',
        RegAddrCity: 'APPLETON',
        RegAddrState: 'WI',
        RegAddrZip: 54911,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '1409 N DIVISION ST',
        RegAddrCity: 'APPLETON',
        RegAddrState: 'WI',
        RegAddrZip: 54911,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: 'N9136 KOLPACK RD',
        RegAddrCity: 'BOWLER',
        RegAddrState: 'WI',
        RegAddrZip: 54416,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: 'N8860 MURPHYS RD',
        RegAddrCity: 'BOWLER',
        RegAddrState: 'WI',
        RegAddrZip: 54416,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: 'N8877 RANDOLPH ST',
        RegAddrCity: 'BRILLION',
        RegAddrState: 'WI',
        RegAddrZip: 54110,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '141 BENTWOOD DR',
        RegAddrCity: 'BRILLION',
        RegAddrState: 'WI',
        RegAddrZip: 54110,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '9435 SCHOOL RD',
        RegAddrCity: 'BRUSSELS',
        RegAddrState: 'WI',
        RegAddrZip: 54204,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: 'N3237 ECKER LAKELAND BCH',
        RegAddrCity: 'CHILTON',
        RegAddrState: 'WI',
        RegAddrZip: 53014,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '4825 183RD ST',
        RegAddrCity: 'CHIPPEWA FLS',
        RegAddrState: 'WI',
        RegAddrZip: 54729,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: 'W8569 CLOVERLEAF LAKE RD',
        RegAddrCity: 'CLINTONVILLE',
        RegAddrState: 'WI',
        RegAddrZip: 54929,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '832 S 9TH ST',
        RegAddrCity: 'DE PERE',
        RegAddrState: 'WI',
        RegAddrZip: 54115,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '1208 MAPLEWOOD CT',
        RegAddrCity: 'DE PERE',
        RegAddrState: 'WI',
        RegAddrZip: 54115,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '431 LEONARD ST',
        RegAddrCity: 'DE PERE',
        RegAddrState: 'WI',
        RegAddrZip: 54115,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '1965 SHELLEY CT',
        RegAddrCity: 'DE PERE',
        RegAddrState: 'WI',
        RegAddrZip: 54115,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '4623 COOPERSTOWN RD',
        RegAddrCity: 'DENMARK',
        RegAddrState: 'WI',
        RegAddrZip: 54208,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: 'N4142 IRONWOOD CT',
        RegAddrCity: 'FREEDOM',
        RegAddrState: 'WI',
        RegAddrZip: 54130,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: 'W3366 APPALOOSA CT',
        RegAddrCity: 'FREEDOM',
        RegAddrState: 'WI',
        RegAddrZip: 54913,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: 'N203 ARROWHEAD RD',
        RegAddrCity: 'FREMONT',
        RegAddrState: 'WI',
        RegAddrZip: 54940,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '675 E EDGEWOOD DR',
        RegAddrCity: 'GRAND CHUTE',
        RegAddrState: 'WI',
        RegAddrZip: 54913,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '5077 W ANITA ST',
        RegAddrCity: 'GRAND CHUTE',
        RegAddrState: 'WI',
        RegAddrZip: 54913,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '5795 W EDGEWOOD DR',
        RegAddrCity: 'GRAND CHUTE',
        RegAddrState: 'WI',
        RegAddrZip: 54913,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '461 SAINT MARYS BLVD',
        RegAddrCity: 'GREEN BAY',
        RegAddrState: 'WI',
        RegAddrZip: 54301,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '2450 BIRCH POINTE CIR',
        RegAddrCity: 'GREEN BAY',
        RegAddrState: 'WI',
        RegAddrZip: 54302,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '1689 WEDGEWOOD DR',
        RegAddrCity: 'GREEN BAY',
        RegAddrState: 'WI',
        RegAddrZip: 54303,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '1031 N TAYLOR ST',
        RegAddrCity: 'GREEN BAY',
        RegAddrState: 'WI',
        RegAddrZip: 54303,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '2271 OLIVER LN',
        RegAddrCity: 'GREEN BAY',
        RegAddrState: 'WI',
        RegAddrZip: 54303,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '1103 KENWOOD ST',
        RegAddrCity: 'GREEN BAY',
        RegAddrState: 'WI',
        RegAddrZip: 54304,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: 'N2365 WEATHERHILL CT',
        RegAddrCity: 'GREENVILLE',
        RegAddrState: 'WI',
        RegAddrZip: 54942,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '237 LAKEVIEW AVE',
        RegAddrCity: 'HORTONVILLE',
        RegAddrState: 'WI',
        RegAddrZip: 54944,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '2105 E WISCONSIN AVE',
        RegAddrCity: 'KAUKAUNA',
        RegAddrState: 'WI',
        RegAddrZip: 54130,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '108 FRANCES ST',
        RegAddrCity: 'KAUKAUNA',
        RegAddrState: 'WI',
        RegAddrZip: 54130,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '105 S RAILROAD ST',
        RegAddrCity: 'KIMBERLY',
        RegAddrState: 'WI',
        RegAddrZip: 54136,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '5618 LINDA LN',
        RegAddrCity: 'LTL SUAMICO',
        RegAddrState: 'WI',
        RegAddrZip: 54141,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '15587 MAIDEN LAKE RD',
        RegAddrCity: 'MOUNTAIN',
        RegAddrState: 'WI',
        RegAddrZip: 54149,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: 'W1416 FOUNDRY RD',
        RegAddrCity: 'NEW HOLSTEIN',
        RegAddrState: 'WI',
        RegAddrZip: 53061,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: 'E9131 KLATT RD',
        RegAddrCity: 'NEW LONDON',
        RegAddrState: 'WI',
        RegAddrZip: 54961,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '411 W LAW ST',
        RegAddrCity: 'NEW LONDON',
        RegAddrState: 'WI',
        RegAddrZip: 54961,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '1108 PIERCE ST',
        RegAddrCity: 'NIAGARA',
        RegAddrState: 'WI',
        RegAddrZip: 54151,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: 'W4349 PORTERFIELD LAKE RD',
        RegAddrCity: 'PORTERFIELD',
        RegAddrState: 'WI',
        RegAddrZip: 54159,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: 'N6262 STATE HIGHWAY 55',
        RegAddrCity: 'SEYMOUR',
        RegAddrState: 'WI',
        RegAddrZip: 54165,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: 'W7354 HILLSIDE DR',
        RegAddrCity: 'SHAWANO',
        RegAddrState: 'WI',
        RegAddrZip: 54166,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: 'W4970 N PATHFINDER CIR',
        RegAddrCity: 'SHAWANO',
        RegAddrState: 'WI',
        RegAddrZip: 54166,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '808 MICHIGAN ST',
        RegAddrCity: 'STURGEON BAY',
        RegAddrState: 'WI',
        RegAddrZip: 54235,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '3798 KEN RIDGE LN',
        RegAddrCity: 'SUAMICO',
        RegAddrState: 'WI',
        RegAddrZip: 54313,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: 'E1570 MCCROSSEN RD',
        RegAddrCity: 'WAUPACA',
        RegAddrState: 'WI',
        RegAddrZip: 54981,
        CongressionalDistrict: 8,
      },
      {
        RegAddrLine1: '238 BURNING TREE CT',
        RegAddrCity: 'WRIGHTSTOWN',
        RegAddrState: 'WI',
        RegAddrZip: 54180,
        CongressionalDistrict: 8,
      },
    ],
  },
  {
    name: 'WV-1',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/WV-1?ref=gh-pages',
    state: 'WV',
    state_and_number: 'WV-1',
    zip: 25267,
    congressionalDistrict: 1,
    addressesInDistrict: [
      {
        RegAddrLine1: '230 SHATZER RD',
        RegAddrCity: 'ALBRIGHT',
        RegAddrState: 'WV',
        RegAddrZip: 26519,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '857 MILLER RD',
        RegAddrCity: 'ALBRIGHT',
        RegAddrState: 'WV',
        RegAddrZip: 26519,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: 'PO BOX 277',
        RegAddrCity: 'BARRACKVILLE',
        RegAddrState: 'WV',
        RegAddrZip: 26559,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: 'PO BOX 166',
        RegAddrCity: 'BAYARD',
        RegAddrState: 'WV',
        RegAddrZip: 26707,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '10464 BARBOUR COUNTY HWY',
        RegAddrCity: 'BELINGTON',
        RegAddrState: 'WV',
        RegAddrZip: 26250,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '175 3RD ST',
        RegAddrCity: 'BRETZ',
        RegAddrState: 'WV',
        RegAddrZip: 26524,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '149 TALL TIMBER LN',
        RegAddrCity: 'CLARKSBURG',
        RegAddrState: 'WV',
        RegAddrZip: 26301,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1224 SERENE DR',
        RegAddrCity: 'FAIRMONT',
        RegAddrState: 'WV',
        RegAddrZip: 26554,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '37 RAINTREE DR',
        RegAddrCity: 'FAIRMONT',
        RegAddrState: 'WV',
        RegAddrZip: 26554,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1285 HILLCREST RD',
        RegAddrCity: 'FAIRMONT',
        RegAddrState: 'WV',
        RegAddrZip: 26554,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2217 BUNNER RIDGE RD',
        RegAddrCity: 'FAIRMONT',
        RegAddrState: 'WV',
        RegAddrZip: 26554,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '556 ROCKDALE RD',
        RegAddrCity: 'FOLLANSBEE',
        RegAddrState: 'WV',
        RegAddrZip: 26037,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '863 WV HIGHWAY 5E',
        RegAddrCity: 'GLENVILLE',
        RegAddrState: 'WV',
        RegAddrZip: 26351,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '323 DOGWOOD LN',
        RegAddrCity: 'GRAFTON',
        RegAddrState: 'WV',
        RegAddrZip: 26354,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '15 DORSEY ST',
        RegAddrCity: 'GRAFTON',
        RegAddrState: 'WV',
        RegAddrZip: 26354,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '3440 GEORGE WASHINGTON HWY',
        RegAddrCity: 'GRAFTON',
        RegAddrState: 'WV',
        RegAddrZip: 26354,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '526 MAPLE AVE',
        RegAddrCity: 'GRAFTON',
        RegAddrState: 'WV',
        RegAddrZip: 26354,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2421 BLUEVILLE DR',
        RegAddrCity: 'GRAFTON',
        RegAddrState: 'WV',
        RegAddrZip: 26354,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '470 MINERS WAY',
        RegAddrCity: 'GRANVILLE',
        RegAddrState: 'WV',
        RegAddrZip: 26534,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '474 MALLOW RD',
        RegAddrCity: 'HENDRICKS',
        RegAddrState: 'WV',
        RegAddrZip: 26271,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: 'GENERAL DELIVERY',
        RegAddrCity: 'HEPZIBAH',
        RegAddrState: 'WV',
        RegAddrZip: 26369,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '4032 NEW CREEK HWY',
        RegAddrCity: 'KEYSER',
        RegAddrState: 'WV',
        RegAddrZip: 26726,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '8230 WAXLER RD',
        RegAddrCity: 'KEYSER',
        RegAddrState: 'WV',
        RegAddrZip: 26726,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '74 OLD COUNTRY LN',
        RegAddrCity: 'KEYSER',
        RegAddrState: 'WV',
        RegAddrZip: 26726,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '453 ROCKLAND OAK FARM DR',
        RegAddrCity: 'KEYSER',
        RegAddrState: 'WV',
        RegAddrZip: 26726,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '521 SIMPLICITY LN',
        RegAddrCity: 'KEYSER',
        RegAddrState: 'WV',
        RegAddrZip: 26726,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '163 ARGYLE ST',
        RegAddrCity: 'KEYSER',
        RegAddrState: 'WV',
        RegAddrZip: 26726,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: 'PO BOX 224',
        RegAddrCity: 'LOST CREEK',
        RegAddrState: 'WV',
        RegAddrZip: 26385,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1801 DENTS RUN RD',
        RegAddrCity: 'MANNINGTON',
        RegAddrState: 'WV',
        RegAddrZip: 26582,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '505 E MAIN ST',
        RegAddrCity: 'MANNINGTON',
        RegAddrState: 'WV',
        RegAddrZip: 26582,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '5 ABBY LN',
        RegAddrCity: 'MAYSVILLE',
        RegAddrState: 'WV',
        RegAddrZip: 26833,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '308 HIGGINBOTHAM RUN RD',
        RegAddrCity: 'METZ',
        RegAddrState: 'WV',
        RegAddrZip: 26585,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '114 CRESCENT DR',
        RegAddrCity: 'MIDDLEBOURNE',
        RegAddrState: 'WV',
        RegAddrZip: 26149,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '3367 ELIZABETH PIKE',
        RegAddrCity: 'MINERAL WELLS',
        RegAddrState: 'WV',
        RegAddrZip: 26150,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '5905 SENECA TRL',
        RegAddrCity: 'MONTROSE',
        RegAddrState: 'WV',
        RegAddrZip: 26283,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '142 BRAND RD',
        RegAddrCity: 'MORGANTOWN',
        RegAddrState: 'WV',
        RegAddrZip: 26501,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '107 POPLAR DR',
        RegAddrCity: 'MORGANTOWN',
        RegAddrState: 'WV',
        RegAddrZip: 26505,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '25 GOOSE HOLLOW RD',
        RegAddrCity: 'MORGANTOWN',
        RegAddrState: 'WV',
        RegAddrZip: 26508,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '16379 PROCTOR CREEK RD',
        RegAddrCity: 'N MARTINSVLLE',
        RegAddrState: 'WV',
        RegAddrZip: 26155,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '330 BLOSSOM AVE',
        RegAddrCity: 'NEWELL',
        RegAddrState: 'WV',
        RegAddrZip: 26050,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: 'NINTH AVE',
        RegAddrCity: 'NEWELL',
        RegAddrState: 'WV',
        RegAddrZip: 26050,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '519 PENNSYLVANIA AVE',
        RegAddrCity: 'NUTTER FORT',
        RegAddrState: 'WV',
        RegAddrZip: 26301,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '106 N 5TH AVE',
        RegAddrCity: 'PADEN CITY',
        RegAddrState: 'WV',
        RegAddrZip: 26159,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '230 E ROBINSON ST',
        RegAddrCity: 'PADEN CITY',
        RegAddrState: 'WV',
        RegAddrZip: 26159,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1309 CLYDE ST',
        RegAddrCity: 'PARKERSBURG',
        RegAddrState: 'WV',
        RegAddrZip: 26101,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '85 WINDY LN',
        RegAddrCity: 'PARKERSBURG',
        RegAddrState: 'WV',
        RegAddrZip: 26101,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2310 DIVISION STREET EXT',
        RegAddrCity: 'PARKERSBURG',
        RegAddrState: 'WV',
        RegAddrZip: 26101,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '96 MARIE ST',
        RegAddrCity: 'PARKERSBURG',
        RegAddrState: 'WV',
        RegAddrZip: 26104,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '3104 BROAD ST',
        RegAddrCity: 'PARKERSBURG',
        RegAddrState: 'WV',
        RegAddrZip: 26104,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '6174 OLD SAINT MARYS PIKE',
        RegAddrCity: 'PARKERSBURG',
        RegAddrState: 'WV',
        RegAddrZip: 26104,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '495 ROGER BOLYARD RD',
        RegAddrCity: 'PARSONS',
        RegAddrState: 'WV',
        RegAddrZip: 26287,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '702 PARK DR',
        RegAddrCity: 'PENNSBORO',
        RegAddrState: 'WV',
        RegAddrZip: 26415,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '134 HIDDEN RIDGE LN',
        RegAddrCity: 'PENNSBORO',
        RegAddrState: 'WV',
        RegAddrZip: 26415,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '4484 N FORK HWY',
        RegAddrCity: 'PETERSBURG',
        RegAddrState: 'WV',
        RegAddrZip: 26847,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1560 BURCH RIDGE RD',
        RegAddrCity: 'PROCTOR',
        RegAddrState: 'WV',
        RegAddrZip: 26055,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '82 BORDER LN',
        RegAddrCity: 'READER',
        RegAddrState: 'WV',
        RegAddrZip: 26167,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: 'PO BOX 165',
        RegAddrCity: 'REYNOLDSVILLE',
        RegAddrState: 'WV',
        RegAddrZip: 26422,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '104 POPLAR ST',
        RegAddrCity: 'RIDGELEY',
        RegAddrState: 'WV',
        RegAddrZip: 26753,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '116 BROOKE LN',
        RegAddrCity: 'RIDGELEY',
        RegAddrState: 'WV',
        RegAddrZip: 26753,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '39 HARMONY HEIGHTS WAY',
        RegAddrCity: 'SAINT MARYS',
        RegAddrState: 'WV',
        RegAddrZip: 26170,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '12348 HEBRON RD',
        RegAddrCity: 'SAINT MARYS',
        RegAddrState: 'WV',
        RegAddrZip: 26170,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '11333 HEBRON RD',
        RegAddrCity: 'SAINT MARYS',
        RegAddrState: 'WV',
        RegAddrZip: 26170,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '208 CAPRI ACRES DR',
        RegAddrCity: 'SHINNSTON',
        RegAddrState: 'WV',
        RegAddrZip: 26431,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '7402 ENERGY HWY',
        RegAddrCity: 'SISTERSVILLE',
        RegAddrState: 'WV',
        RegAddrZip: 26175,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '9948 STAUNTON TPKE',
        RegAddrCity: 'WALKER',
        RegAddrState: 'WV',
        RegAddrZip: 26180,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '299 FOREST WAY',
        RegAddrCity: 'WAVERLY',
        RegAddrState: 'WV',
        RegAddrZip: 26184,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '103 N 21ST ST',
        RegAddrCity: 'WEIRTON',
        RegAddrState: 'WV',
        RegAddrZip: 26062,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '106 GILSON AVE',
        RegAddrCity: 'WEIRTON',
        RegAddrState: 'WV',
        RegAddrZip: 26062,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '115 YOUNGS LN',
        RegAddrCity: 'WELLSBURG',
        RegAddrState: 'WV',
        RegAddrZip: 26070,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '185 BRADYS RIDGE RD',
        RegAddrCity: 'WELLSBURG',
        RegAddrState: 'WV',
        RegAddrZip: 26070,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '1553 WASHINGTON PIKE',
        RegAddrCity: 'WELLSBURG',
        RegAddrState: 'WV',
        RegAddrZip: 26070,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '21 MAPLE LN',
        RegAddrCity: 'WHEELING',
        RegAddrState: 'WV',
        RegAddrZip: 26003,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '35 HANDLAN ST',
        RegAddrCity: 'WHEELING',
        RegAddrState: 'WV',
        RegAddrZip: 26003,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '5 FOREST HILLS DR',
        RegAddrCity: 'WHEELING',
        RegAddrState: 'WV',
        RegAddrZip: 26003,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '12 QUAIL CT',
        RegAddrCity: 'WHEELING',
        RegAddrState: 'WV',
        RegAddrZip: 26003,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '971 GARVINS LN',
        RegAddrCity: 'WHEELING',
        RegAddrState: 'WV',
        RegAddrZip: 26003,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '14 AARONWOODS CT',
        RegAddrCity: 'WHEELING',
        RegAddrState: 'WV',
        RegAddrZip: 26003,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '2 MCCREADY AVE',
        RegAddrCity: 'WHEELING',
        RegAddrState: 'WV',
        RegAddrZip: 26003,
        CongressionalDistrict: 1,
      },
      {
        RegAddrLine1: '61 KITTLE ST',
        RegAddrCity: 'WILLIAMSTOWN',
        RegAddrState: 'WV',
        RegAddrZip: 26187,
        CongressionalDistrict: 1,
      },
    ],
  },
  {
    name: 'WV-2',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/WV-2?ref=gh-pages',
    state: 'WV',
    state_and_number: 'WV-2',
    zip: 25003,
    congressionalDistrict: 2,
    addressesInDistrict: [
      {
        RegAddrLine1: '4865 FORD HILL RD',
        RegAddrCity: 'AUGUSTA',
        RegAddrState: 'WV',
        RegAddrZip: 26704,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2478 HIGHLAND RIDGE RD',
        RegAddrCity: 'BERKELEY SPGS',
        RegAddrState: 'WV',
        RegAddrZip: 25411,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '38 YANKEE LN',
        RegAddrCity: 'BEVERLY',
        RegAddrState: 'WV',
        RegAddrZip: 26253,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '13230 BLUE GRAY TRL',
        RegAddrCity: 'BRANDYWINE',
        RegAddrState: 'WV',
        RegAddrZip: 26802,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '116 FAYETTE ST',
        RegAddrCity: 'BUCKHANNON',
        RegAddrState: 'WV',
        RegAddrZip: 26201,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '633 HICKORY FLAT RD',
        RegAddrCity: 'BUCKHANNON',
        RegAddrState: 'WV',
        RegAddrZip: 26201,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '45 QUARRY RD',
        RegAddrCity: 'BUNKER HILL',
        RegAddrState: 'WV',
        RegAddrZip: 25413,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '60 WENDOVER DR',
        RegAddrCity: 'BUNKER HILL',
        RegAddrState: 'WV',
        RegAddrZip: 25413,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '396 PHILLIPS LN',
        RegAddrCity: 'BUNKER HILL',
        RegAddrState: 'WV',
        RegAddrZip: 25413,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '80 LONG HEN DR',
        RegAddrCity: 'CAPON BRIDGE',
        RegAddrState: 'WV',
        RegAddrZip: 26711,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '123 MILE BRANCH RD',
        RegAddrCity: 'CEDAR GROVE',
        RegAddrState: 'WV',
        RegAddrZip: 25039,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '80 BARLEY LN',
        RegAddrCity: 'CHARLES TOWN',
        RegAddrState: 'WV',
        RegAddrZip: 25414,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2138 BERRYVILLE PIKE',
        RegAddrCity: 'CHARLES TOWN',
        RegAddrState: 'WV',
        RegAddrZip: 25414,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '69 LONE OAK RD',
        RegAddrCity: 'CHARLES TOWN',
        RegAddrState: 'WV',
        RegAddrZip: 25414,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '66 MONUMENTAL DR',
        RegAddrCity: 'CHARLES TOWN',
        RegAddrState: 'WV',
        RegAddrZip: 25414,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '295 JEFFERSON AVE',
        RegAddrCity: 'CHARLES TOWN',
        RegAddrState: 'WV',
        RegAddrZip: 25414,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '456 PLEASANT VALLEY DR',
        RegAddrCity: 'CHARLES TOWN',
        RegAddrState: 'WV',
        RegAddrZip: 25414,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '59 BARKSDALE CT',
        RegAddrCity: 'CHARLES TOWN',
        RegAddrState: 'WV',
        RegAddrZip: 25414,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '310 S MILDRED ST',
        RegAddrCity: 'CHARLES TOWN',
        RegAddrState: 'WV',
        RegAddrZip: 25414,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '498 WESTMORELAND DR',
        RegAddrCity: 'CHARLESTON',
        RegAddrState: 'WV',
        RegAddrZip: 25302,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '209 VINE ST',
        RegAddrCity: 'CHARLESTON',
        RegAddrState: 'WV',
        RegAddrZip: 25302,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '812 ELMORE AVE',
        RegAddrCity: 'CHARLESTON',
        RegAddrState: 'WV',
        RegAddrZip: 25302,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1036 QUAIL POINTE DR',
        RegAddrCity: 'CHARLESTON',
        RegAddrState: 'WV',
        RegAddrZip: 25302,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '308A KANAWHA BLVD W',
        RegAddrCity: 'CHARLESTON',
        RegAddrState: 'WV',
        RegAddrZip: 25302,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '5733 VICTORY AVE',
        RegAddrCity: 'CHARLESTON',
        RegAddrState: 'WV',
        RegAddrZip: 25304,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '4008 KANAWHA AVE SE',
        RegAddrCity: 'CHARLESTON',
        RegAddrState: 'WV',
        RegAddrZip: 25304,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '797 HAWES DR',
        RegAddrCity: 'CHARLESTON',
        RegAddrState: 'WV',
        RegAddrZip: 25306,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1511 VIRGINIA ST E',
        RegAddrCity: 'CHARLESTON',
        RegAddrState: 'WV',
        RegAddrZip: 25311,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '711 HESS DR',
        RegAddrCity: 'CHARLESTON',
        RegAddrState: 'WV',
        RegAddrZip: 25311,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1300 RENAISSANCE CIR',
        RegAddrCity: 'CHARLESTON',
        RegAddrState: 'WV',
        RegAddrZip: 25311,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1616 GREYSTONE PL',
        RegAddrCity: 'CHARLESTON',
        RegAddrState: 'WV',
        RegAddrZip: 25314,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '590 LONG BRANCH RD',
        RegAddrCity: 'CHARLESTON',
        RegAddrState: 'WV',
        RegAddrZip: 25314,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '9734 WIDEN RIDGE RD',
        RegAddrCity: 'CLAY',
        RegAddrState: 'WV',
        RegAddrZip: 25043,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1307 MUDLICK RD',
        RegAddrCity: 'CLENDENIN',
        RegAddrState: 'WV',
        RegAddrZip: 25045,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '904 LEATHERWOOD RD',
        RegAddrCity: 'CLENDENIN',
        RegAddrState: 'WV',
        RegAddrZip: 25045,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '8385 RIPLEY RD',
        RegAddrCity: 'COTTAGEVILLE',
        RegAddrState: 'WV',
        RegAddrZip: 25239,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '5402 MORNING DOVE LN',
        RegAddrCity: 'CROSS LANES',
        RegAddrState: 'WV',
        RegAddrZip: 25313,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1119 MEADOW WAY',
        RegAddrCity: 'CROSS LANES',
        RegAddrState: 'WV',
        RegAddrZip: 25313,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '5415 KENDALL DR',
        RegAddrCity: 'CROSS LANES',
        RegAddrState: 'WV',
        RegAddrZip: 25313,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '5211 PURDY DR',
        RegAddrCity: 'CROSS LANES',
        RegAddrState: 'WV',
        RegAddrZip: 25313,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '4593 VICTORIA RD',
        RegAddrCity: 'CROSS LANES',
        RegAddrState: 'WV',
        RegAddrZip: 25313,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '289 PINE CIR',
        RegAddrCity: 'DUNBAR',
        RegAddrState: 'WV',
        RegAddrZip: 25064,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '4012 SHELVING ROCK RD',
        RegAddrCity: 'ELIZABETH',
        RegAddrState: 'WV',
        RegAddrZip: 26143,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '578 MAPLE DR',
        RegAddrCity: 'ELIZABETH',
        RegAddrState: 'WV',
        RegAddrZip: 26143,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '3986 LITTLE KANAWHA PKWY',
        RegAddrCity: 'ELIZABETH',
        RegAddrState: 'WV',
        RegAddrZip: 26143,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '3 PARK ST',
        RegAddrCity: 'ELKINS',
        RegAddrState: 'WV',
        RegAddrZip: 26241,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '101 MAPLE LN',
        RegAddrCity: 'ELKINS',
        RegAddrState: 'WV',
        RegAddrZip: 26241,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '320 CENTER ST',
        RegAddrCity: 'ELKINS',
        RegAddrState: 'WV',
        RegAddrZip: 26241,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '39 HEAVNER ACRES',
        RegAddrCity: 'ELKINS',
        RegAddrState: 'WV',
        RegAddrZip: 26241,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1074 WALGROVE RD',
        RegAddrCity: 'ELKVIEW',
        RegAddrState: 'WV',
        RegAddrZip: 25071,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '4064 INDIAN CREEK RD',
        RegAddrCity: 'ELKVIEW',
        RegAddrState: 'WV',
        RegAddrZip: 25071,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '174 FRAME RD',
        RegAddrCity: 'ELKVIEW',
        RegAddrState: 'WV',
        RegAddrZip: 25071,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '9670 OLD ELKINS RD',
        RegAddrCity: 'ELLAMORE',
        RegAddrState: 'WV',
        RegAddrZip: 26267,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '116 FOOLISH PLEASURE DR',
        RegAddrCity: 'EVANS',
        RegAddrState: 'WV',
        RegAddrZip: 25241,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '52 HERON LN',
        RegAddrCity: 'FALLING WATERS',
        RegAddrState: 'WV',
        RegAddrZip: 25419,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '82 MORNINGSIDE DR',
        RegAddrCity: 'FALLING WTRS',
        RegAddrState: 'WV',
        RegAddrZip: 25419,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '25 TENDERHEART LN',
        RegAddrCity: 'FALLING WTRS',
        RegAddrState: 'WV',
        RegAddrZip: 25419,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '31 STREAMSIDE PL',
        RegAddrCity: 'FALLING WTRS',
        RegAddrState: 'WV',
        RegAddrZip: 25419,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '149 MONTREAL WAY',
        RegAddrCity: 'FALLING WTRS',
        RegAddrState: 'WV',
        RegAddrZip: 25419,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2496 FRAMETOWN RD',
        RegAddrCity: 'FRAMETOWN',
        RegAddrState: 'WV',
        RegAddrZip: 26623,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: 'PO BOX 423',
        RegAddrCity: 'FRANKLIN',
        RegAddrState: 'WV',
        RegAddrZip: 26807,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '3350 HARMONY RD',
        RegAddrCity: 'GANDEEVILLE',
        RegAddrState: 'WV',
        RegAddrZip: 25243,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '3650 CUTLIP FORK RD',
        RegAddrCity: 'GASSAWAY',
        RegAddrState: 'WV',
        RegAddrZip: 26624,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '39 VIRGINIA ST',
        RegAddrCity: 'GASSAWAY',
        RegAddrState: 'WV',
        RegAddrZip: 26624,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '89 PALE MAGNOLIA DR',
        RegAddrCity: 'GERRARDSTOWN',
        RegAddrState: 'WV',
        RegAddrZip: 25420,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '128 GLAD STONE RD',
        RegAddrCity: 'GREEN SPRING',
        RegAddrState: 'WV',
        RegAddrZip: 26722,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '557 ACORN CIR',
        RegAddrCity: 'HARPERS FERRY',
        RegAddrState: 'WV',
        RegAddrZip: 25425,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '462 CHICKAMAUGA DR',
        RegAddrCity: 'HARPERS FERRY',
        RegAddrState: 'WV',
        RegAddrZip: 25425,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '85 SKYLAR CT',
        RegAddrCity: 'HARPERS FERRY',
        RegAddrState: 'WV',
        RegAddrZip: 25425,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2083 ENGLE SWITCH RD',
        RegAddrCity: 'HARPERS FERRY',
        RegAddrState: 'WV',
        RegAddrZip: 25425,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '157 AERIE LN',
        RegAddrCity: 'HARPERS FERRY',
        RegAddrState: 'WV',
        RegAddrZip: 25425,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '570 MCDOWELL ST',
        RegAddrCity: 'HARPERS FERRY',
        RegAddrState: 'WV',
        RegAddrZip: 25425,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '406 TECUMSEH TRL',
        RegAddrCity: 'HEDGESVILLE',
        RegAddrState: 'WV',
        RegAddrZip: 25427,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '180 BOWYER DR',
        RegAddrCity: 'HORNER',
        RegAddrState: 'WV',
        RegAddrZip: 26372,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '51 KELLY LN',
        RegAddrCity: 'HURRICANE',
        RegAddrState: 'WV',
        RegAddrZip: 25526,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '19 CHRISTOPHER DR',
        RegAddrCity: 'HURRICANE',
        RegAddrState: 'WV',
        RegAddrZip: 25526,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2087 MOUNT VERNON RD',
        RegAddrCity: 'HURRICANE',
        RegAddrState: 'WV',
        RegAddrZip: 25526,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '5077 HURRICANE CREEK RD',
        RegAddrCity: 'HURRICANE',
        RegAddrState: 'WV',
        RegAddrZip: 25526,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '22 LA RUE LN',
        RegAddrCity: 'HURRICANE',
        RegAddrState: 'WV',
        RegAddrZip: 25526,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '371 UPSHUR DR',
        RegAddrCity: 'INWOOD',
        RegAddrState: 'WV',
        RegAddrZip: 25428,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '349 BOISE LN',
        RegAddrCity: 'INWOOD',
        RegAddrState: 'WV',
        RegAddrZip: 25428,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2762 SYCAMORE LICK RD',
        RegAddrCity: 'JANE LEW',
        RegAddrState: 'WV',
        RegAddrZip: 26378,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '137 CLEARVIEW FARM LN',
        RegAddrCity: 'KEARNEYSVILLE',
        RegAddrState: 'WV',
        RegAddrZip: 25430,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '5153 HARTLEY RUN RD',
        RegAddrCity: 'LE ROY',
        RegAddrState: 'WV',
        RegAddrZip: 25252,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: 'PO BOX 1',
        RegAddrCity: 'LITTLE BIRCH',
        RegAddrState: 'WV',
        RegAddrZip: 26629,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '321 W JOHN ST',
        RegAddrCity: 'MARTINSBURG',
        RegAddrState: 'WV',
        RegAddrZip: 25401,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '216 E JOHN ST',
        RegAddrCity: 'MARTINSBURG',
        RegAddrState: 'WV',
        RegAddrZip: 25401,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '608 W KING ST',
        RegAddrCity: 'MARTINSBURG',
        RegAddrState: 'WV',
        RegAddrZip: 25401,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '393 QUALITY TER',
        RegAddrCity: 'MARTINSBURG',
        RegAddrState: 'WV',
        RegAddrZip: 25403,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '143 ABINO HILLS WAY W',
        RegAddrCity: 'MARTINSBURG',
        RegAddrState: 'WV',
        RegAddrZip: 25403,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '326 BETTS WAY',
        RegAddrCity: 'MARTINSBURG',
        RegAddrState: 'WV',
        RegAddrZip: 25404,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '20 CARMODY LN',
        RegAddrCity: 'MARTINSBURG',
        RegAddrState: 'WV',
        RegAddrZip: 25404,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '4379 SHEPHERDSTOWN RD',
        RegAddrCity: 'MARTINSBURG',
        RegAddrState: 'WV',
        RegAddrZip: 25404,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '85 COUNTY LINE DR',
        RegAddrCity: 'MARTINSBURG',
        RegAddrState: 'WV',
        RegAddrZip: 25404,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '42 BIRD ST N',
        RegAddrCity: 'MARTINSBURG',
        RegAddrState: 'WV',
        RegAddrZip: 25405,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '189 WAGNER DR',
        RegAddrCity: 'MARTINSBURG',
        RegAddrState: 'WV',
        RegAddrZip: 25405,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '83 NEAR BETHELS WAY',
        RegAddrCity: 'MARTINSBURG',
        RegAddrState: 'WV',
        RegAddrZip: 25405,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '299 TEAL RD',
        RegAddrCity: 'MARTINSBURG',
        RegAddrState: 'WV',
        RegAddrZip: 25405,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '57 SCHULTZ WAY',
        RegAddrCity: 'MOOREFIELD',
        RegAddrState: 'WV',
        RegAddrZip: 26836,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2137 N 21ST ST',
        RegAddrCity: 'NITRO',
        RegAddrState: 'WV',
        RegAddrZip: 25143,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '6244 SAND RIDGE RD',
        RegAddrCity: 'NORMANTOWN',
        RegAddrState: 'WV',
        RegAddrZip: 25267,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '233 AMELIA ST',
        RegAddrCity: 'PAW PAW',
        RegAddrState: 'WV',
        RegAddrZip: 25434,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '106 DODD ST',
        RegAddrCity: 'POCA',
        RegAddrState: 'WV',
        RegAddrZip: 25159,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '302 BREAKAWAY DR',
        RegAddrCity: 'POINTS',
        RegAddrState: 'WV',
        RegAddrZip: 25437,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '128 DAISY RD',
        RegAddrCity: 'PROCIOUS',
        RegAddrState: 'WV',
        RegAddrZip: 25164,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '30 STAYMAN DR',
        RegAddrCity: 'RANSON',
        RegAddrState: 'WV',
        RegAddrZip: 25438,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '625 WOODLAND ACRES RD',
        RegAddrCity: 'RIPLEY',
        RegAddrState: 'WV',
        RegAddrZip: 25271,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '310 OLD STONE RIDGE RD',
        RegAddrCity: 'RIPLEY',
        RegAddrState: 'WV',
        RegAddrZip: 25271,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '7532 OIL CREEK RD',
        RegAddrCity: 'ROANOKE',
        RegAddrState: 'WV',
        RegAddrZip: 26447,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '689 FOREST CIR',
        RegAddrCity: 'S CHARLESTON',
        RegAddrState: 'WV',
        RegAddrZip: 25303,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1300 RIDGE DR',
        RegAddrCity: 'S CHARLESTON',
        RegAddrState: 'WV',
        RegAddrZip: 25309,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '80 GLEN OAKS LN',
        RegAddrCity: 'S CHARLESTON',
        RegAddrState: 'WV',
        RegAddrZip: 25309,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '13 CAIRO DR',
        RegAddrCity: 'SAINT ALBANS',
        RegAddrState: 'WV',
        RegAddrZip: 25177,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1200 SARVIS FORK RD',
        RegAddrCity: 'SANDYVILLE',
        RegAddrState: 'WV',
        RegAddrZip: 25275,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1741 JOES RUN RD',
        RegAddrCity: 'SANDYVILLE',
        RegAddrState: 'WV',
        RegAddrZip: 25275,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '405 ROSEHILL ACRES',
        RegAddrCity: 'SCOTT DEPOT',
        RegAddrState: 'WV',
        RegAddrZip: 25560,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '234 BEECHWOOD EST',
        RegAddrCity: 'SCOTT DEPOT',
        RegAddrState: 'WV',
        RegAddrZip: 25560,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '1600 HAPPY HOLLOW RD',
        RegAddrCity: 'SISSONVILLE',
        RegAddrState: 'WV',
        RegAddrZip: 25320,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '34 OAK DR',
        RegAddrCity: 'SPENCER',
        RegAddrState: 'WV',
        RegAddrZip: 25276,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '224 3RD ST',
        RegAddrCity: 'SPENCER',
        RegAddrState: 'WV',
        RegAddrZip: 25276,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '231 CHAPMAN AVE',
        RegAddrCity: 'SPENCER',
        RegAddrState: 'WV',
        RegAddrZip: 25276,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '145 SWISHER HOLLOW RD',
        RegAddrCity: 'SPRINGFIELD',
        RegAddrState: 'WV',
        RegAddrZip: 26763,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '558 LLOYD RD',
        RegAddrCity: 'SUMMIT POINT',
        RegAddrState: 'WV',
        RegAddrZip: 25446,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '2775 OLD WOMANS RUN',
        RegAddrCity: 'SUTTON',
        RegAddrState: 'WV',
        RegAddrZip: 26601,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '4541 WALLBACK RD',
        RegAddrCity: 'WALLBACK',
        RegAddrState: 'WV',
        RegAddrZip: 25285,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '8471 COPLEY RD',
        RegAddrCity: 'WESTON',
        RegAddrState: 'WV',
        RegAddrZip: 26452,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '40 CASH LN',
        RegAddrCity: 'WINFIELD',
        RegAddrState: 'WV',
        RegAddrZip: 25213,
        CongressionalDistrict: 2,
      },
      {
        RegAddrLine1: '4517 CAPON RIVER RD',
        RegAddrCity: 'YELLOW SPRING',
        RegAddrState: 'WV',
        RegAddrZip: 26865,
        CongressionalDistrict: 2,
      },
    ],
  },
  {
    name: 'WV-3',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/WV-3?ref=gh-pages',
    state: 'WV',
    state_and_number: 'WV-3',
    zip: 24701,
    congressionalDistrict: 3,
    addressesInDistrict: [
      {
        RegAddrLine1: '602 TOMS FRK',
        RegAddrCity: 'ALUM CREEK',
        RegAddrState: 'WV',
        RegAddrZip: 25003,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '874 SEVEN MILE RIDGE RD',
        RegAddrCity: 'APPLE GROVE',
        RegAddrState: 'WV',
        RegAddrZip: 25502,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '361 SUNSET DR',
        RegAddrCity: 'ATHENS',
        RegAddrState: 'WV',
        RegAddrZip: 24712,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '924 SAUNDERS HOLLOW RD',
        RegAddrCity: 'BARBOURSVILLE',
        RegAddrState: 'WV',
        RegAddrZip: 25504,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '104 STONEHEATH DR',
        RegAddrCity: 'BARBOURSVILLE',
        RegAddrState: 'WV',
        RegAddrZip: 25504,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '113 PATRICK ST',
        RegAddrCity: 'BEAVER',
        RegAddrState: 'WV',
        RegAddrZip: 25813,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '108 KING ST',
        RegAddrCity: 'BECKLEY',
        RegAddrState: 'WV',
        RegAddrZip: 25801,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1352 OLD ECCLES RD',
        RegAddrCity: 'BECKLEY',
        RegAddrState: 'WV',
        RegAddrZip: 25801,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '320 BURGESS ST',
        RegAddrCity: 'BECKLEY',
        RegAddrState: 'WV',
        RegAddrZip: 25801,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '120 WILDWOOD AVE',
        RegAddrCity: 'BECKLEY',
        RegAddrState: 'WV',
        RegAddrZip: 25801,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '822 DRY HILL RD',
        RegAddrCity: 'BECKLEY',
        RegAddrState: 'WV',
        RegAddrZip: 25801,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '108 WALTER ST',
        RegAddrCity: 'BECKLEY',
        RegAddrState: 'WV',
        RegAddrZip: 25801,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '115 MARION ST',
        RegAddrCity: 'BECKLEY',
        RegAddrState: 'WV',
        RegAddrZip: 25801,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '249 IDLE ACRES DR',
        RegAddrCity: 'BLUEFIELD',
        RegAddrState: 'WV',
        RegAddrZip: 24701,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '156 CUTOFF RD',
        RegAddrCity: 'BLUEFIELD',
        RegAddrState: 'WV',
        RegAddrZip: 24701,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1231 SUNRISE DR',
        RegAddrCity: 'BLUEFIELD',
        RegAddrState: 'WV',
        RegAddrZip: 24701,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '71 LONG BR',
        RegAddrCity: 'BRANCHLAND',
        RegAddrState: 'WV',
        RegAddrZip: 25506,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: 'PO BOX 815',
        RegAddrCity: 'CHAPMANVILLE',
        RegAddrState: 'WV',
        RegAddrZip: 25508,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '618 SAUNDERS FORK RD',
        RegAddrCity: 'CHAPMANVILLE',
        RegAddrState: 'WV',
        RegAddrZip: 25508,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: 'CHATTAROY HOLLOW 11 REAR HENRY STREET',
        RegAddrCity: 'CHATTAROY',
        RegAddrState: 'WV',
        RegAddrZip: 25667,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '7152 COAL MOUNTAIN RD',
        RegAddrCity: 'COAL MOUNTAIN',
        RegAddrState: 'WV',
        RegAddrZip: 24823,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '71 SARTIN DR',
        RegAddrCity: 'COWEN',
        RegAddrState: 'WV',
        RegAddrZip: 26206,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '739 WILLIAMS RIVER RD',
        RegAddrCity: 'COWEN',
        RegAddrState: 'WV',
        RegAddrZip: 26206,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '168 VENTURE LN',
        RegAddrCity: 'CRAB ORCHARD',
        RegAddrState: 'WV',
        RegAddrZip: 25827,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '644 SUGAR CAMP RD',
        RegAddrCity: 'CRAIGSVILLE',
        RegAddrState: 'WV',
        RegAddrZip: 26205,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '153 DOGWOOD CT',
        RegAddrCity: 'DANIELS',
        RegAddrState: 'WV',
        RegAddrZip: 25832,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: 'GENERAL DELIVERY',
        RegAddrCity: 'DRY CREEK',
        RegAddrState: 'WV',
        RegAddrZip: 25062,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '7232 ERBACON RD',
        RegAddrCity: 'ERBACON',
        RegAddrState: 'WV',
        RegAddrZip: 26203,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '103 JETTSVILLE RD',
        RegAddrCity: 'FENWICK',
        RegAddrState: 'WV',
        RegAddrZip: 26202,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '7581 FORT GAY RD',
        RegAddrCity: 'FORT GAY',
        RegAddrState: 'WV',
        RegAddrZip: 25514,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '2593 DANIEL BOONE PKWY',
        RegAddrCity: 'FOSTER',
        RegAddrState: 'WV',
        RegAddrZip: 25081,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '661 VALLEY RIDGE CIR',
        RegAddrCity: 'FRANKFORD',
        RegAddrState: 'WV',
        RegAddrZip: 24938,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '7315 FIVE MILE RD',
        RegAddrCity: 'GALIPOLIS FRY',
        RegAddrState: 'WV',
        RegAddrZip: 25515,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: 'VERNER',
        RegAddrCity: 'GILBERT',
        RegAddrState: 'WV',
        RegAddrZip: 25621,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '643 SUN MINE RD',
        RegAddrCity: 'GLEN JEAN',
        RegAddrState: 'WV',
        RegAddrZip: 25846,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '361 RITTER DR',
        RegAddrCity: 'GLEN MORGAN',
        RegAddrState: 'WV',
        RegAddrZip: 25813,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '5346 HANS CREEK RD',
        RegAddrCity: 'GREENVILLE',
        RegAddrState: 'WV',
        RegAddrZip: 24945,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '22 ROUGH RIDGE RD',
        RegAddrCity: 'GRIFFITHSVLE',
        RegAddrState: 'WV',
        RegAddrZip: 25521,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '8050 BRIDGE AVE',
        RegAddrCity: 'HAMLIN',
        RegAddrState: 'WV',
        RegAddrZip: 25523,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '99 HONEYSUCKLE LN',
        RegAddrCity: 'HAMLIN',
        RegAddrState: 'WV',
        RegAddrZip: 25523,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '319 NELSON BRANCH RD',
        RegAddrCity: 'HANOVER',
        RegAddrState: 'WV',
        RegAddrZip: 24839,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '576 LUSK RD',
        RegAddrCity: 'HERNDON',
        RegAddrState: 'WV',
        RegAddrZip: 24726,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '201 DYLAN DR',
        RegAddrCity: 'HUNTINGTON',
        RegAddrState: 'WV',
        RegAddrZip: 25701,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1437 HARVEY RD',
        RegAddrCity: 'HUNTINGTON',
        RegAddrState: 'WV',
        RegAddrZip: 25701,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '4067 GREEN VALLEY RD',
        RegAddrCity: 'HUNTINGTON',
        RegAddrState: 'WV',
        RegAddrZip: 25701,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1016 JOHNSON HTS',
        RegAddrCity: 'HUNTINGTON',
        RegAddrState: 'WV',
        RegAddrZip: 25702,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1812 9TH AVE',
        RegAddrCity: 'HUNTINGTON',
        RegAddrState: 'WV',
        RegAddrZip: 25703,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '2742 ORCHARD AVE',
        RegAddrCity: 'HUNTINGTON',
        RegAddrState: 'WV',
        RegAddrZip: 25704,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '209 MOHAWK CT',
        RegAddrCity: 'HUNTINGTON',
        RegAddrState: 'WV',
        RegAddrZip: 25705,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '6334 ROBERTO DR',
        RegAddrCity: 'HUNTINGTON',
        RegAddrState: 'WV',
        RegAddrZip: 25705,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: 'RR 52',
        RegAddrCity: 'IAEGER',
        RegAddrState: 'WV',
        RegAddrZip: 24844,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '20 3RD HILL ST',
        RegAddrCity: 'ITMANN',
        RegAddrState: 'WV',
        RegAddrZip: 24847,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: 'PO BOX 92',
        RegAddrCity: 'JEFFREY',
        RegAddrState: 'WV',
        RegAddrZip: 25114,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '3 FORKS RD 60 RED ROBIN',
        RegAddrCity: 'JOLO',
        RegAddrState: 'WV',
        RegAddrZip: 24850,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1512 MAPLE ST',
        RegAddrCity: 'KENOVA',
        RegAddrState: 'WV',
        RegAddrZip: 25530,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '54 OAKVIEW DR',
        RegAddrCity: 'KENOVA',
        RegAddrState: 'WV',
        RegAddrZip: 25530,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '10 SALS LNDG',
        RegAddrCity: 'KINCAID',
        RegAddrState: 'WV',
        RegAddrZip: 25119,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: 'BEESON RD',
        RegAddrCity: 'LASHMEET',
        RegAddrState: 'WV',
        RegAddrZip: 24733,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: 'PO BOX 24',
        RegAddrCity: 'LECKIE',
        RegAddrState: 'WV',
        RegAddrZip: 24808,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '5 BLAIR DR',
        RegAddrCity: 'LENORE',
        RegAddrState: 'WV',
        RegAddrZip: 25676,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1609 MANILLA RD',
        RegAddrCity: 'LEON',
        RegAddrState: 'WV',
        RegAddrZip: 25123,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '12078 DEBBIE RD',
        RegAddrCity: 'LEON',
        RegAddrState: 'WV',
        RegAddrZip: 25123,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '87 RADICAL LN',
        RegAddrCity: 'LETART',
        RegAddrState: 'WV',
        RegAddrZip: 25253,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '208 VIA LARGO DR',
        RegAddrCity: 'LEWISBURG',
        RegAddrState: 'WV',
        RegAddrZip: 24901,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '8156 SENECA TRL S',
        RegAddrCity: 'LINDSIDE',
        RegAddrState: 'WV',
        RegAddrZip: 24951,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '68 GODBY ST',
        RegAddrCity: 'LOGAN',
        RegAddrState: 'WV',
        RegAddrZip: 25601,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '140 WYATT ST',
        RegAddrCity: 'LOGAN',
        RegAddrState: 'WV',
        RegAddrZip: 25601,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '2863 BROWNSBURG RD',
        RegAddrCity: 'MARLINTON',
        RegAddrState: 'WV',
        RegAddrZip: 24954,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '47 CENTRAL AVE',
        RegAddrCity: 'MATEWAN',
        RegAddrState: 'WV',
        RegAddrZip: 25678,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: 'RR 49',
        RegAddrCity: 'MATEWAN',
        RegAddrState: 'WV',
        RegAddrZip: 25678,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '8609 BACKUS MTN',
        RegAddrCity: 'MEADOW BRIDGE',
        RegAddrState: 'WV',
        RegAddrZip: 25976,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '331 1ST AVE',
        RegAddrCity: 'MIDWAY',
        RegAddrState: 'WV',
        RegAddrZip: 25878,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1029 N SANDBRANCH RD',
        RegAddrCity: 'MOUNT HOPE',
        RegAddrState: 'WV',
        RegAddrZip: 25880,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '950 MAPLE FORK RD',
        RegAddrCity: 'MOUNT HOPE',
        RegAddrState: 'WV',
        RegAddrZip: 25880,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '64 HUFFMAN ST',
        RegAddrCity: 'MOUNT NEBO',
        RegAddrState: 'WV',
        RegAddrZip: 26679,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '628 CAMPSITE RD',
        RegAddrCity: 'MOUNT NEBO',
        RegAddrState: 'WV',
        RegAddrZip: 26679,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1048 WAHOO RD',
        RegAddrCity: 'MOUNT NEBO',
        RegAddrState: 'WV',
        RegAddrZip: 26679,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '40 WEEPING WILLOW LN',
        RegAddrCity: 'MOUNT NEBO',
        RegAddrState: 'WV',
        RegAddrZip: 26679,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '114 4TH ST',
        RegAddrCity: 'OAK HILL',
        RegAddrState: 'WV',
        RegAddrZip: 25901,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '2509 GRANT GDNS',
        RegAddrCity: 'ONA',
        RegAddrState: 'WV',
        RegAddrZip: 25545,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '23383 COAL RIVER RD',
        RegAddrCity: 'ORGAS',
        RegAddrState: 'WV',
        RegAddrZip: 25148,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: 'NEEDS 911 ADDRESS',
        RegAddrCity: 'PETERSTOWN',
        RegAddrState: 'WV',
        RegAddrZip: 24963,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '960 STRINGERS BRANCH RD',
        RegAddrCity: 'PEYTONA',
        RegAddrState: 'WV',
        RegAddrZip: 25154,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '174 MAPLE AVE',
        RegAddrCity: 'PINEVILLE',
        RegAddrState: 'WV',
        RegAddrZip: 24874,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1090 MOUNT HOREB RD',
        RegAddrCity: 'PRINCETON',
        RegAddrState: 'WV',
        RegAddrZip: 24739,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '271 HIGH PLAINS DR',
        RegAddrCity: 'PRINCETON',
        RegAddrState: 'WV',
        RegAddrZip: 24739,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '103 12TH ST',
        RegAddrCity: 'PRINCETON',
        RegAddrState: 'WV',
        RegAddrZip: 24740,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '106 DAMEWOOD AVE',
        RegAddrCity: 'PRINCETON',
        RegAddrState: 'WV',
        RegAddrZip: 24740,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1576 NEAL ROAD NEAL RD',
        RegAddrCity: 'PT PLEASANT',
        RegAddrState: 'WV',
        RegAddrZip: 25550,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '2406 LINCOLN AVE',
        RegAddrCity: 'PT PLEASANT',
        RegAddrState: 'WV',
        RegAddrZip: 25550,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '903 CIRCLE AVENUE LILLY PARK',
        RegAddrCity: 'RAINELLE',
        RegAddrState: 'WV',
        RegAddrZip: 25962,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '26762 SENECA TRL N',
        RegAddrCity: 'RENICK',
        RegAddrState: 'WV',
        RegAddrZip: 24966,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '243 BALLARD DR',
        RegAddrCity: 'RONCEVERTE',
        RegAddrState: 'WV',
        RegAddrZip: 24970,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '5342 STATE RT 10',
        RegAddrCity: 'SALT ROCK',
        RegAddrState: 'WV',
        RegAddrZip: 25559,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '177 MISTY LN',
        RegAddrCity: 'SHADY SPRING',
        RegAddrState: 'WV',
        RegAddrZip: 25918,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: 'NEEDS ADDRESS',
        RegAddrCity: 'SOD',
        RegAddrState: 'WV',
        RegAddrZip: 25564,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '100 WALKER ST',
        RegAddrCity: 'SOPHIA',
        RegAddrState: 'WV',
        RegAddrZip: 25921,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '31 CASTLE LN',
        RegAddrCity: 'SUMMERSVILLE',
        RegAddrState: 'WV',
        RegAddrZip: 26651,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: 'RR 103',
        RegAddrCity: 'THORPE',
        RegAddrState: 'WV',
        RegAddrZip: 24888,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '289 HOLIDAY BR RD',
        RegAddrCity: 'THORPE',
        RegAddrState: 'WV',
        RegAddrZip: 24888,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '395 TRACE BRANCH RD',
        RegAddrCity: 'UNEEDA',
        RegAddrState: 'WV',
        RegAddrZip: 25205,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '75 LEMONS ST',
        RegAddrCity: 'VERDUNVILLE',
        RegAddrState: 'WV',
        RegAddrZip: 25649,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '1015 EDDS BRANCH RD',
        RegAddrCity: 'WAYNE',
        RegAddrState: 'WV',
        RegAddrZip: 25570,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '460 SUGAR CREEK RD',
        RegAddrCity: 'WEBSTER SPGS',
        RegAddrState: 'WV',
        RegAddrZip: 26288,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '179 MCKINLEY ST',
        RegAddrCity: 'WHARTON',
        RegAddrState: 'WV',
        RegAddrZip: 25208,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '65 BARBERRY LN',
        RegAddrCity: 'WHITMAN',
        RegAddrState: 'WV',
        RegAddrZip: 25652,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '2017 BUFFALO CREEK RD',
        RegAddrCity: 'WILLIAMSON',
        RegAddrState: 'WV',
        RegAddrZip: 25661,
        CongressionalDistrict: 3,
      },
      {
        RegAddrLine1: '730 E 4TH AVE',
        RegAddrCity: 'WILLIAMSON',
        RegAddrState: 'WV',
        RegAddrZip: 25661,
        CongressionalDistrict: 3,
      },
    ],
  },
  {
    name: 'WY-0',
    url:
      'https://api.github.com/repos/unitedstates/districts/contents/cds/2012/WY-0?ref=gh-pages',
    state: 'WY',
    state_and_number: 'WY-0',
    zip: 82001,
    congressionalDistrict: 0,
    addressesInDistrict: [
      {
        RegAddrLine1: '387 ADAMS ST',
        RegAddrCity: 'AFTON',
        RegAddrState: 'WY',
        RegAddrZip: 83110,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '170 BRAVES RD',
        RegAddrCity: 'AFTON',
        RegAddrState: 'WY',
        RegAddrZip: 83110,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '155 E 6TH AVE',
        RegAddrCity: 'AFTON',
        RegAddrState: 'WY',
        RegAddrZip: 83110,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '190 STATE HIGHWAY 111',
        RegAddrCity: 'ALADDIN',
        RegAddrState: 'WY',
        RegAddrZip: 82710,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '317 LEFT HAND DITCH RD',
        RegAddrCity: 'ARAPAHOE',
        RegAddrState: 'WY',
        RegAddrZip: 82510,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '161 STUMP CREEK RD',
        RegAddrCity: 'AUBURN',
        RegAddrState: 'WY',
        RegAddrZip: 83111,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '2110 INCA TRL',
        RegAddrCity: 'BAR NUNN',
        RegAddrState: 'WY',
        RegAddrZip: 82601,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '7 WILLOW DR',
        RegAddrCity: 'BASIN',
        RegAddrState: 'WY',
        RegAddrZip: 82410,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '388 HIGHWAY 20 S',
        RegAddrCity: 'BASIN',
        RegAddrState: 'WY',
        RegAddrZip: 82410,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '603 HOLDREGE AVE',
        RegAddrCity: 'BASIN',
        RegAddrState: 'WY',
        RegAddrZip: 82410,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '188 ROBINSON LN',
        RegAddrCity: 'BEDFORD',
        RegAddrState: 'WY',
        RegAddrZip: 83112,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '508 S 2ND ST',
        RegAddrCity: 'BIG HORN',
        RegAddrState: 'WY',
        RegAddrZip: 82833,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '29537 OLD HIGHWAY 87',
        RegAddrCity: 'BUFFALO',
        RegAddrState: 'WY',
        RegAddrZip: 82834,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '320 N ADAMS AVE',
        RegAddrCity: 'BUFFALO',
        RegAddrState: 'WY',
        RegAddrZip: 82834,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1052 FORT ST',
        RegAddrCity: 'BUFFALO',
        RegAddrState: 'WY',
        RegAddrZip: 82834,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '215 S PRAIRIE AVE',
        RegAddrCity: 'BURNS',
        RegAddrState: 'WY',
        RegAddrZip: 82053,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '29 E MAIN ST',
        RegAddrCity: 'BYRON',
        RegAddrState: 'WY',
        RegAddrZip: 82412,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '36 W PLATTE AVE',
        RegAddrCity: 'BYRON',
        RegAddrState: 'WY',
        RegAddrZip: 82412,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '2721 SAGEWOOD AVE',
        RegAddrCity: 'CASPER',
        RegAddrState: 'WY',
        RegAddrZip: 82601,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '3641 GARDEN CREEK RD',
        RegAddrCity: 'CASPER',
        RegAddrState: 'WY',
        RegAddrZip: 82601,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1621 S ELM ST',
        RegAddrCity: 'CASPER',
        RegAddrState: 'WY',
        RegAddrZip: 82601,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '851 W 45TH ST',
        RegAddrCity: 'CASPER',
        RegAddrState: 'WY',
        RegAddrZip: 82601,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1200 S ELK ST',
        RegAddrCity: 'CASPER',
        RegAddrState: 'WY',
        RegAddrZip: 82601,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '2100 GLENDALE AVE',
        RegAddrCity: 'CASPER',
        RegAddrState: 'WY',
        RegAddrZip: 82601,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '142 W H ST',
        RegAddrCity: 'CASPER',
        RegAddrState: 'WY',
        RegAddrZip: 82601,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1911 S CHESTNUT ST',
        RegAddrCity: 'CASPER',
        RegAddrState: 'WY',
        RegAddrZip: 82601,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '4075 BROKEN TRAIL DR',
        RegAddrCity: 'CASPER',
        RegAddrState: 'WY',
        RegAddrZip: 82601,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '3320 S POPLAR ST',
        RegAddrCity: 'CASPER',
        RegAddrState: 'WY',
        RegAddrZip: 82601,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '2121 E 23RD ST',
        RegAddrCity: 'CASPER',
        RegAddrState: 'WY',
        RegAddrZip: 82601,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '628 E 14TH ST',
        RegAddrCity: 'CASPER',
        RegAddrState: 'WY',
        RegAddrZip: 82601,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '2014 LENNOX AVE',
        RegAddrCity: 'CASPER',
        RegAddrState: 'WY',
        RegAddrZip: 82601,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1512 S SPRUCE ST',
        RegAddrCity: 'CASPER',
        RegAddrState: 'WY',
        RegAddrZip: 82601,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '113 W J ST',
        RegAddrCity: 'CASPER',
        RegAddrState: 'WY',
        RegAddrZip: 82601,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1226 S COTTONWOOD ST',
        RegAddrCity: 'CASPER',
        RegAddrState: 'WY',
        RegAddrZip: 82604,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '3089 HERRINGTON DR',
        RegAddrCity: 'CASPER',
        RegAddrState: 'WY',
        RegAddrZip: 82604,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '2001 KINGSBORO RD',
        RegAddrCity: 'CASPER',
        RegAddrState: 'WY',
        RegAddrZip: 82604,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1414 SHERIDAN DR',
        RegAddrCity: 'CASPER',
        RegAddrState: 'WY',
        RegAddrZip: 82604,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '6289 BRANDT RD',
        RegAddrCity: 'CASPER',
        RegAddrState: 'WY',
        RegAddrZip: 82604,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1354 SYCAMORE ST',
        RegAddrCity: 'CASPER',
        RegAddrState: 'WY',
        RegAddrZip: 82604,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '2577 RAINBOW DR',
        RegAddrCity: 'CASPER',
        RegAddrState: 'WY',
        RegAddrZip: 82604,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1615 BIRCH ST',
        RegAddrCity: 'CASPER',
        RegAddrState: 'WY',
        RegAddrZip: 82604,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '3215 CATTLE TRAIL DR',
        RegAddrCity: 'CASPER',
        RegAddrState: 'WY',
        RegAddrZip: 82604,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '3160 INDIAN SCOUT DR',
        RegAddrCity: 'CASPER',
        RegAddrState: 'WY',
        RegAddrZip: 82604,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '833 DERINGTON AVE',
        RegAddrCity: 'CASPER',
        RegAddrState: 'WY',
        RegAddrZip: 82609,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '941 DUNDEE',
        RegAddrCity: 'CASPER',
        RegAddrState: 'WY',
        RegAddrZip: 82609,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '3530 MEDICINE BOW ST',
        RegAddrCity: 'CASPER',
        RegAddrState: 'WY',
        RegAddrZip: 82609,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '4444 E 24TH ST',
        RegAddrCity: 'CASPER',
        RegAddrState: 'WY',
        RegAddrZip: 82609,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1018 CARRIAGE LN',
        RegAddrCity: 'CASPER',
        RegAddrState: 'WY',
        RegAddrZip: 82609,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '62 KELLY CREEK RD',
        RegAddrCity: 'CENTENNIAL',
        RegAddrState: 'WY',
        RegAddrZip: 82055,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1914 E 16TH ST',
        RegAddrCity: 'CHEYENNE',
        RegAddrState: 'WY',
        RegAddrZip: 82001,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1816 E 21ST ST',
        RegAddrCity: 'CHEYENNE',
        RegAddrState: 'WY',
        RegAddrZip: 82001,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '922 TAFT AVE',
        RegAddrCity: 'CHEYENNE',
        RegAddrState: 'WY',
        RegAddrZip: 82001,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '3905 BENT AVE',
        RegAddrCity: 'CHEYENNE',
        RegAddrState: 'WY',
        RegAddrZip: 82001,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '3317 FRONTIER ST',
        RegAddrCity: 'CHEYENNE',
        RegAddrState: 'WY',
        RegAddrZip: 82001,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '4125 VAN BUREN AVE',
        RegAddrCity: 'CHEYENNE',
        RegAddrState: 'WY',
        RegAddrZip: 82001,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '2117 EVANS AVE',
        RegAddrCity: 'CHEYENNE',
        RegAddrState: 'WY',
        RegAddrZip: 82001,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '3326 FIRE SIDE DR',
        RegAddrCity: 'CHEYENNE',
        RegAddrState: 'WY',
        RegAddrZip: 82001,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '901 COTTONWOOD DR',
        RegAddrCity: 'CHEYENNE',
        RegAddrState: 'WY',
        RegAddrZip: 82001,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '3340 STEVENS DR',
        RegAddrCity: 'CHEYENNE',
        RegAddrState: 'WY',
        RegAddrZip: 82001,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1324 CLEVELAND AVE',
        RegAddrCity: 'CHEYENNE',
        RegAddrState: 'WY',
        RegAddrZip: 82001,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1608 ROLLINS AVE',
        RegAddrCity: 'CHEYENNE',
        RegAddrState: 'WY',
        RegAddrZip: 82001,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1780 OTTO RD',
        RegAddrCity: 'CHEYENNE',
        RegAddrState: 'WY',
        RegAddrZip: 82001,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '2912 CENTRAL AVE',
        RegAddrCity: 'CHEYENNE',
        RegAddrState: 'WY',
        RegAddrZip: 82001,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '244 STINSON AVE',
        RegAddrCity: 'CHEYENNE',
        RegAddrState: 'WY',
        RegAddrZip: 82007,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1218 W LEISHER RD',
        RegAddrCity: 'CHEYENNE',
        RegAddrState: 'WY',
        RegAddrZip: 82007,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '9411 CAMPSTOOL RD',
        RegAddrCity: 'CHEYENNE',
        RegAddrState: 'WY',
        RegAddrZip: 82007,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1910 S 1ST AVE',
        RegAddrCity: 'CHEYENNE',
        RegAddrState: 'WY',
        RegAddrZip: 82007,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '300 BOROUGH ST',
        RegAddrCity: 'CHEYENNE',
        RegAddrState: 'WY',
        RegAddrZip: 82007,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '520 PERSONS RD',
        RegAddrCity: 'CHEYENNE',
        RegAddrState: 'WY',
        RegAddrZip: 82007,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '4308 AVENUE B2',
        RegAddrCity: 'CHEYENNE',
        RegAddrState: 'WY',
        RegAddrZip: 82007,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '708 W 7TH ST',
        RegAddrCity: 'CHEYENNE',
        RegAddrState: 'WY',
        RegAddrZip: 82007,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1331 GOWDY CT',
        RegAddrCity: 'CHEYENNE',
        RegAddrState: 'WY',
        RegAddrZip: 82009,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '3704 WELCHESTER DR',
        RegAddrCity: 'CHEYENNE',
        RegAddrState: 'WY',
        RegAddrZip: 82009,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1524 TRANQUILITY RD',
        RegAddrCity: 'CHEYENNE',
        RegAddrState: 'WY',
        RegAddrZip: 82009,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '461 ANDRA CT',
        RegAddrCity: 'CHEYENNE',
        RegAddrState: 'WY',
        RegAddrZip: 82009,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '10716 SNOW VALLEY CT',
        RegAddrCity: 'CHEYENNE',
        RegAddrState: 'WY',
        RegAddrZip: 82009,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '6822 GRAND TETON CT',
        RegAddrCity: 'CHEYENNE',
        RegAddrState: 'WY',
        RegAddrZip: 82009,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '9819 CHILD RD',
        RegAddrCity: 'CHEYENNE',
        RegAddrState: 'WY',
        RegAddrZip: 82009,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '2303 MCKINNEY DR',
        RegAddrCity: 'CHEYENNE',
        RegAddrState: 'WY',
        RegAddrZip: 82009,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1215 MARIE LN',
        RegAddrCity: 'CHEYENNE',
        RegAddrState: 'WY',
        RegAddrZip: 82009,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '4905 GRIFFITH AVE',
        RegAddrCity: 'CHEYENNE',
        RegAddrState: 'WY',
        RegAddrZip: 82009,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '10718 W POLO PLATE',
        RegAddrCity: 'CHEYENNE',
        RegAddrState: 'WY',
        RegAddrZip: 82009,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '311 MUSCADINE WAY',
        RegAddrCity: 'CHEYENNE',
        RegAddrState: 'WY',
        RegAddrZip: 82009,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '4202 EVERTON DR',
        RegAddrCity: 'CHEYENNE',
        RegAddrState: 'WY',
        RegAddrZip: 82009,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '780 S TABLE MOUNTAIN LOOP',
        RegAddrCity: 'CHEYENNE',
        RegAddrState: 'WY',
        RegAddrZip: 82009,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '7319 HUNTZ DR',
        RegAddrCity: 'CHEYENNE',
        RegAddrState: 'WY',
        RegAddrZip: 82009,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '7212 BOMAR DR',
        RegAddrCity: 'CHEYENNE',
        RegAddrState: 'WY',
        RegAddrZip: 82009,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '4911 GRIFFITH AVE',
        RegAddrCity: 'CHEYENNE',
        RegAddrState: 'WY',
        RegAddrZip: 82009,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1119 19TH ST',
        RegAddrCity: 'CODY',
        RegAddrState: 'WY',
        RegAddrZip: 82414,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '320 TROUT PEAK DR',
        RegAddrCity: 'CODY',
        RegAddrState: 'WY',
        RegAddrZip: 82414,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '775 PLATINUM AVE',
        RegAddrCity: 'CODY',
        RegAddrState: 'WY',
        RegAddrZip: 82414,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '2821 ROCKY RD',
        RegAddrCity: 'CODY',
        RegAddrState: 'WY',
        RegAddrZip: 82414,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1720 26TH ST',
        RegAddrCity: 'CODY',
        RegAddrState: 'WY',
        RegAddrZip: 82414,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '204 F ST',
        RegAddrCity: 'CODY',
        RegAddrState: 'WY',
        RegAddrZip: 82414,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '3116 KENT AVE',
        RegAddrCity: 'CODY',
        RegAddrState: 'WY',
        RegAddrZip: 82414,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '611 CEMETERY RD',
        RegAddrCity: 'DAYTON',
        RegAddrState: 'WY',
        RegAddrZip: 82836,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '311 PAPER COLLAR ROW',
        RegAddrCity: 'DIAMONDVILLE',
        RegAddrState: 'WY',
        RegAddrZip: 83116,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '4 CARRIAGE LN',
        RegAddrCity: 'DUBOIS',
        RegAddrState: 'WY',
        RegAddrZip: 82513,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '109 HORSE CREEK RD',
        RegAddrCity: 'DUBOIS',
        RegAddrState: 'WY',
        RegAddrZip: 82513,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1311 FAIRVIEW DR',
        RegAddrCity: 'DUBOIS',
        RegAddrState: 'WY',
        RegAddrZip: 82513,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '125 MILL CREEK RD',
        RegAddrCity: 'ETHETE',
        RegAddrState: 'WY',
        RegAddrZip: 82520,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '108000 US89',
        RegAddrCity: 'ETNA',
        RegAddrState: 'WY',
        RegAddrZip: 83118,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '209 JARED LN',
        RegAddrCity: 'EVANSTON',
        RegAddrState: 'WY',
        RegAddrZip: 82930,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '8440 STATE HIGHWAY 150 S',
        RegAddrCity: 'EVANSTON',
        RegAddrState: 'WY',
        RegAddrZip: 82930,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '118 NAVAJO CIR',
        RegAddrCity: 'EVANSTON',
        RegAddrState: 'WY',
        RegAddrZip: 82930,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '241 TOMAHAWK DR',
        RegAddrCity: 'EVANSTON',
        RegAddrState: 'WY',
        RegAddrZip: 82930,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '428 THAYER CIR',
        RegAddrCity: 'EVANSTON',
        RegAddrState: 'WY',
        RegAddrZip: 82930,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '292 BEAR RIVER DR',
        RegAddrCity: 'EVANSTON',
        RegAddrState: 'WY',
        RegAddrZip: 82930,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '129 COPPER AVE',
        RegAddrCity: 'EVANSVILLE',
        RegAddrState: 'WY',
        RegAddrZip: 82636,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '371 US HIGHWAY 28',
        RegAddrCity: 'FARSON',
        RegAddrState: 'WY',
        RegAddrZip: 82932,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '416 COUNTY ROAD 224',
        RegAddrCity: 'FORT BRIDGER',
        RegAddrState: 'WY',
        RegAddrZip: 82933,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '686 COUNTY ROAD 260',
        RegAddrCity: 'FORT BRIDGER',
        RegAddrState: 'WY',
        RegAddrZip: 82933,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '305 S LARAMIE AVE',
        RegAddrCity: 'FORT LARAMIE',
        RegAddrState: 'WY',
        RegAddrZip: 82212,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '104 S MILES AVE',
        RegAddrCity: 'FORT LARAMIE',
        RegAddrState: 'WY',
        RegAddrZip: 82212,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '14 ENGAVO LN',
        RegAddrCity: 'FT WASHAKIE',
        RegAddrState: 'WY',
        RegAddrZip: 82514,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '3731 HANNUM RD',
        RegAddrCity: 'GILLETTE',
        RegAddrState: 'WY',
        RegAddrZip: 82716,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '820 E 6TH ST',
        RegAddrCity: 'GILLETTE',
        RegAddrState: 'WY',
        RegAddrZip: 82716,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '4 I CT',
        RegAddrCity: 'GILLETTE',
        RegAddrState: 'WY',
        RegAddrZip: 82716,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '2001 PLUMCREEK CT',
        RegAddrCity: 'GILLETTE',
        RegAddrState: 'WY',
        RegAddrZip: 82716,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '3779 D LN',
        RegAddrCity: 'GILLETTE',
        RegAddrState: 'WY',
        RegAddrZip: 82716,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '507 VENTURA AVE',
        RegAddrCity: 'GILLETTE',
        RegAddrState: 'WY',
        RegAddrZip: 82716,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1201 WAGONHAMMER LN',
        RegAddrCity: 'GILLETTE',
        RegAddrState: 'WY',
        RegAddrZip: 82716,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '120 ALPINE DR',
        RegAddrCity: 'GILLETTE',
        RegAddrState: 'WY',
        RegAddrZip: 82718,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '805 SUTHERLAND COVE LN',
        RegAddrCity: 'GILLETTE',
        RegAddrState: 'WY',
        RegAddrZip: 82718,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '6000 VANTAGE CT',
        RegAddrCity: 'GILLETTE',
        RegAddrState: 'WY',
        RegAddrZip: 82718,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '207 LARIAT ST',
        RegAddrCity: 'GILLETTE',
        RegAddrState: 'WY',
        RegAddrZip: 82718,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1004 CLARION DR',
        RegAddrCity: 'GILLETTE',
        RegAddrState: 'WY',
        RegAddrZip: 82718,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '3661 W DONKEY CREEK DR',
        RegAddrCity: 'GILLETTE',
        RegAddrState: 'WY',
        RegAddrZip: 82718,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1403 BEAVER DR',
        RegAddrCity: 'GILLETTE',
        RegAddrState: 'WY',
        RegAddrZip: 82718,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '54 SUNDOG DR',
        RegAddrCity: 'GILLETTE',
        RegAddrState: 'WY',
        RegAddrZip: 82718,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '203 E LAUREL ST',
        RegAddrCity: 'GILLETTE',
        RegAddrState: 'WY',
        RegAddrZip: 82718,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '3413 CAMEO CT',
        RegAddrCity: 'GILLETTE',
        RegAddrState: 'WY',
        RegAddrZip: 82718,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '6609 ICHABOD AVE',
        RegAddrCity: 'GILLETTE',
        RegAddrState: 'WY',
        RegAddrZip: 82718,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '5403 BROM ST',
        RegAddrCity: 'GILLETTE',
        RegAddrState: 'WY',
        RegAddrZip: 82718,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '5810 MUSKET CT',
        RegAddrCity: 'GILLETTE',
        RegAddrState: 'WY',
        RegAddrZip: 82718,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '280 COLE CREEK RD',
        RegAddrCity: 'GLENROCK',
        RegAddrState: 'WY',
        RegAddrZip: 82637,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '100 BOXELDER RD',
        RegAddrCity: 'GLENROCK',
        RegAddrState: 'WY',
        RegAddrZip: 82637,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '415 MILLER RD',
        RegAddrCity: 'GLENROCK',
        RegAddrState: 'WY',
        RegAddrZip: 82637,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '809 S 1ST ST',
        RegAddrCity: 'GLENROCK',
        RegAddrState: 'WY',
        RegAddrZip: 82637,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '372 SUNSET ST',
        RegAddrCity: 'GREEN RIVER',
        RegAddrState: 'WY',
        RegAddrZip: 82935,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1615 MASSACHUSETTS CT',
        RegAddrCity: 'GREEN RIVER',
        RegAddrState: 'WY',
        RegAddrZip: 82935,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '2640 HAWAII CT',
        RegAddrCity: 'GREEN RIVER',
        RegAddrState: 'WY',
        RegAddrZip: 82935,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '288 EVANS DR',
        RegAddrCity: 'GREEN RIVER',
        RegAddrState: 'WY',
        RegAddrZip: 82935,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '2000 IOWA CIR',
        RegAddrCity: 'GREEN RIVER',
        RegAddrState: 'WY',
        RegAddrZip: 82935,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '245 EVANS ST',
        RegAddrCity: 'GREEN RIVER',
        RegAddrState: 'WY',
        RegAddrZip: 82935,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '616 9TH AVE N',
        RegAddrCity: 'GREYBULL',
        RegAddrState: 'WY',
        RegAddrZip: 82426,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '748 W SNOW KING AVE',
        RegAddrCity: 'JACKSON',
        RegAddrState: 'WY',
        RegAddrZip: 83001,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '3047 RANGEVIEW DR',
        RegAddrCity: 'JACKSON',
        RegAddrState: 'WY',
        RegAddrZip: 83001,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '205 SYLVIA DR',
        RegAddrCity: 'JACKSON',
        RegAddrState: 'WY',
        RegAddrZip: 83001,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '695 RODEO DR',
        RegAddrCity: 'JACKSON',
        RegAddrState: 'WY',
        RegAddrZip: 83001,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '595 PINE DR',
        RegAddrCity: 'JACKSON',
        RegAddrState: 'WY',
        RegAddrZip: 83001,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '9445 COLE CANYON RD',
        RegAddrCity: 'JACKSON',
        RegAddrState: 'WY',
        RegAddrZip: 83001,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1035 W LONGHORN DR',
        RegAddrCity: 'JACKSON',
        RegAddrState: 'WY',
        RegAddrZip: 83001,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '225 OLD BARNUM RD',
        RegAddrCity: 'KAYCEE',
        RegAddrState: 'WY',
        RegAddrZip: 82639,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '9800 N BUDGE LN',
        RegAddrCity: 'KELLY',
        RegAddrState: 'WY',
        RegAddrZip: 83011,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1809 HOLLAND DR',
        RegAddrCity: 'KEMMERER',
        RegAddrState: 'WY',
        RegAddrZip: 83101,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1127 7TH WEST AVE',
        RegAddrCity: 'KEMMERER',
        RegAddrState: 'WY',
        RegAddrZip: 83101,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '823 TOPAZ CT',
        RegAddrCity: 'KEMMERER',
        RegAddrState: 'WY',
        RegAddrZip: 83101,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1670 S SUNLIGHT DR',
        RegAddrCity: 'KEMMERER',
        RegAddrState: 'WY',
        RegAddrZip: 83101,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '181 JOHNSTOWN VALLEY RD',
        RegAddrCity: 'KINNEAR',
        RegAddrState: 'WY',
        RegAddrZip: 82516,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '336 5TH AVE',
        RegAddrCity: 'LA GRANGE',
        RegAddrState: 'WY',
        RegAddrZip: 82221,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '403 A ST',
        RegAddrCity: 'LA GRANGE',
        RegAddrState: 'WY',
        RegAddrZip: 82221,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '707 BELLVUE AVE',
        RegAddrCity: 'LANDER',
        RegAddrState: 'WY',
        RegAddrZip: 82520,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '129 SNYDER RD',
        RegAddrCity: 'LANDER',
        RegAddrState: 'WY',
        RegAddrZip: 82520,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '434 HANCOCK DR',
        RegAddrCity: 'LANDER',
        RegAddrState: 'WY',
        RegAddrZip: 82520,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '68 TUMBLEWEED DR',
        RegAddrCity: 'LANDER',
        RegAddrState: 'WY',
        RegAddrZip: 82520,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '145 WENDY ST',
        RegAddrCity: 'LANDER',
        RegAddrState: 'WY',
        RegAddrZip: 82520,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '801 GARNER DR',
        RegAddrCity: 'LANDER',
        RegAddrState: 'WY',
        RegAddrZip: 82520,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1650 RODEO DR',
        RegAddrCity: 'LANDER',
        RegAddrState: 'WY',
        RegAddrZip: 82520,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '446 MARKET ST',
        RegAddrCity: 'LANDER',
        RegAddrState: 'WY',
        RegAddrZip: 82520,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1211 E GARFIELD ST',
        RegAddrCity: 'LARAMIE',
        RegAddrState: 'WY',
        RegAddrZip: 82070,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '436 S JOHNSON ST',
        RegAddrCity: 'LARAMIE',
        RegAddrState: 'WY',
        RegAddrZip: 82070,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '423 STATE HIGHWAY 11',
        RegAddrCity: 'LARAMIE',
        RegAddrState: 'WY',
        RegAddrZip: 82070,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1809 BARRATT ST',
        RegAddrCity: 'LARAMIE',
        RegAddrState: 'WY',
        RegAddrZip: 82070,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1718 SANDERS DR',
        RegAddrCity: 'LARAMIE',
        RegAddrState: 'WY',
        RegAddrZip: 82070,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1918 THORNBURGH DR',
        RegAddrCity: 'LARAMIE',
        RegAddrState: 'WY',
        RegAddrZip: 82070,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1773 MADISON ST',
        RegAddrCity: 'LARAMIE',
        RegAddrState: 'WY',
        RegAddrZip: 82070,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1015 HERRICK LN',
        RegAddrCity: 'LARAMIE',
        RegAddrState: 'WY',
        RegAddrZip: 82070,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '768 N 8TH ST',
        RegAddrCity: 'LARAMIE',
        RegAddrState: 'WY',
        RegAddrZip: 82072,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '2146 E HANCOCK ST',
        RegAddrCity: 'LARAMIE',
        RegAddrState: 'WY',
        RegAddrZip: 82072,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '2154 N 17TH ST',
        RegAddrCity: 'LARAMIE',
        RegAddrState: 'WY',
        RegAddrZip: 82072,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '859 N 10TH ST',
        RegAddrCity: 'LARAMIE',
        RegAddrState: 'WY',
        RegAddrZip: 82072,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1667 N 15TH ST',
        RegAddrCity: 'LARAMIE',
        RegAddrState: 'WY',
        RegAddrZip: 82072,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '7819A ROAD 37',
        RegAddrCity: 'LINGLE',
        RegAddrState: 'WY',
        RegAddrZip: 82223,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '269 E 6TH ST',
        RegAddrCity: 'LOVELL',
        RegAddrState: 'WY',
        RegAddrZip: 82431,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '180 W MAIN ST',
        RegAddrCity: 'LOVELL',
        RegAddrState: 'WY',
        RegAddrZip: 82431,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '579 STATE HIGHWAY 31',
        RegAddrCity: 'MANDERSON',
        RegAddrState: 'WY',
        RegAddrZip: 82432,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1111 SWORD ST',
        RegAddrCity: 'MILLS',
        RegAddrState: 'WY',
        RegAddrZip: 82644,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '104 JOHNSON ST',
        RegAddrCity: 'MOORCROFT',
        RegAddrState: 'WY',
        RegAddrZip: 82721,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '15200 E HIGHWAY 287',
        RegAddrCity: 'MORAN',
        RegAddrState: 'WY',
        RegAddrZip: 83013,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1570 MUSH CREEK RD',
        RegAddrCity: 'NEWCASTLE',
        RegAddrState: 'WY',
        RegAddrZip: 82701,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '3297 N SAGE CIRCLE DR',
        RegAddrCity: 'NEWCASTLE',
        RegAddrState: 'WY',
        RegAddrZip: 82701,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '202 FRONTIER AVE',
        RegAddrCity: 'NEWCASTLE',
        RegAddrState: 'WY',
        RegAddrZip: 82701,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1570 COUNTY ROAD 161',
        RegAddrCity: 'PINE BLUFFS',
        RegAddrState: 'WY',
        RegAddrZip: 82082,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '27 LAKECOURT DR',
        RegAddrCity: 'PINE HAVEN',
        RegAddrState: 'WY',
        RegAddrZip: 82721,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '6 BLACKBURN LN',
        RegAddrCity: 'PINEDALE',
        RegAddrState: 'WY',
        RegAddrZip: 82941,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '484 JADE ST',
        RegAddrCity: 'PINEDALE',
        RegAddrState: 'WY',
        RegAddrZip: 82941,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '112 SPRUCE ST',
        RegAddrCity: 'PINEDALE',
        RegAddrState: 'WY',
        RegAddrZip: 82941,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '650 PINE ST W',
        RegAddrCity: 'PINEDALE',
        RegAddrState: 'WY',
        RegAddrZip: 82941,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '748 ROAD 4',
        RegAddrCity: 'POWELL',
        RegAddrState: 'WY',
        RegAddrZip: 82435,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1050 ROAD 9',
        RegAddrCity: 'POWELL',
        RegAddrState: 'WY',
        RegAddrZip: 82435,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '999 AVENUE G',
        RegAddrCity: 'POWELL',
        RegAddrState: 'WY',
        RegAddrZip: 82435,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '590 N BENT ST',
        RegAddrCity: 'POWELL',
        RegAddrState: 'WY',
        RegAddrZip: 82435,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '210 STATE HIGHWAY 345',
        RegAddrCity: 'RANCHESTER',
        RegAddrState: 'WY',
        RegAddrZip: 82839,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '2 WHISPER LN',
        RegAddrCity: 'RANCHESTER',
        RegAddrState: 'WY',
        RegAddrZip: 82839,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '410 E MILLER ST',
        RegAddrCity: 'RAWLINS',
        RegAddrState: 'WY',
        RegAddrZip: 82301,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1314 HARSHMAN ST',
        RegAddrCity: 'RAWLINS',
        RegAddrState: 'WY',
        RegAddrZip: 82301,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: 'MTN VIEW ESTS',
        RegAddrCity: 'RAWLINS',
        RegAddrState: 'WY',
        RegAddrZip: 82301,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '221 E BUFFALO ST',
        RegAddrCity: 'RAWLINS',
        RegAddrState: 'WY',
        RegAddrZip: 82301,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '50 RELIANCE RD 24',
        RegAddrCity: 'RELIANCE',
        RegAddrState: 'WY',
        RegAddrZip: 82943,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1023 CLOVERLEAF DR',
        RegAddrCity: 'RIVERTON',
        RegAddrState: 'WY',
        RegAddrZip: 82501,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '2325 W MAIN ST',
        RegAddrCity: 'RIVERTON',
        RegAddrState: 'WY',
        RegAddrZip: 82501,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '3819 TAM O SHANTER DR',
        RegAddrCity: 'RIVERTON',
        RegAddrState: 'WY',
        RegAddrZip: 82501,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '145 MAZET RD',
        RegAddrCity: 'RIVERTON',
        RegAddrState: 'WY',
        RegAddrZip: 82501,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '4650 ROBIN LN',
        RegAddrCity: 'RIVERTON',
        RegAddrState: 'WY',
        RegAddrZip: 82501,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '321 W WASHINGTON AVE',
        RegAddrCity: 'RIVERTON',
        RegAddrState: 'WY',
        RegAddrZip: 82501,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1017 PIEDMONT CT',
        RegAddrCity: 'RIVERTON',
        RegAddrState: 'WY',
        RegAddrZip: 82501,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '360 REIN RD',
        RegAddrCity: 'RIVERTON',
        RegAddrState: 'WY',
        RegAddrZip: 82501,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1200 SAND POINTE CIR',
        RegAddrCity: 'ROCK SPRINGS',
        RegAddrState: 'WY',
        RegAddrZip: 82901,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1723 UTAH AVE',
        RegAddrCity: 'ROCK SPRINGS',
        RegAddrState: 'WY',
        RegAddrZip: 82901,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '102 MOUNTAIN RD',
        RegAddrCity: 'ROCK SPRINGS',
        RegAddrState: 'WY',
        RegAddrZip: 82901,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1718 UTAH AVE',
        RegAddrCity: 'ROCK SPRINGS',
        RegAddrState: 'WY',
        RegAddrZip: 82901,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '916 MCCARTY AVE',
        RegAddrCity: 'ROCK SPRINGS',
        RegAddrState: 'WY',
        RegAddrZip: 82901,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '343 VIA ASSISI DR',
        RegAddrCity: 'ROCK SPRINGS',
        RegAddrState: 'WY',
        RegAddrZip: 82901,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '902 WOOD HAVEN DR',
        RegAddrCity: 'ROCK SPRINGS',
        RegAddrState: 'WY',
        RegAddrZip: 82901,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '394 GANNETT DR',
        RegAddrCity: 'ROCK SPRINGS',
        RegAddrState: 'WY',
        RegAddrZip: 82901,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '732 RUGBY ST',
        RegAddrCity: 'ROCK SPRINGS',
        RegAddrState: 'WY',
        RegAddrZip: 82901,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1110 W ROCHESTER ST',
        RegAddrCity: 'SARATOGA',
        RegAddrState: 'WY',
        RegAddrZip: 82331,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '101 HYLAND CT',
        RegAddrCity: 'SARATOGA',
        RegAddrState: 'WY',
        RegAddrZip: 82331,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1678 STATE HIGHWAY 130',
        RegAddrCity: 'SARATOGA',
        RegAddrState: 'WY',
        RegAddrZip: 82331,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '760 MONTE VISTA ST',
        RegAddrCity: 'SHERIDAN',
        RegAddrState: 'WY',
        RegAddrZip: 82801,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '7 ROCKY LN',
        RegAddrCity: 'SHERIDAN',
        RegAddrState: 'WY',
        RegAddrZip: 82801,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1223 PARK VIEW CT',
        RegAddrCity: 'SHERIDAN',
        RegAddrState: 'WY',
        RegAddrZip: 82801,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '2018 SKYVIEW WEST DR',
        RegAddrCity: 'SHERIDAN',
        RegAddrState: 'WY',
        RegAddrZip: 82801,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1703 POPLAR TRL',
        RegAddrCity: 'SHERIDAN',
        RegAddrState: 'WY',
        RegAddrZip: 82801,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1551 HOLLY PONDS DR',
        RegAddrCity: 'SHERIDAN',
        RegAddrState: 'WY',
        RegAddrZip: 82801,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '18 CEDAR LN',
        RegAddrCity: 'SHERIDAN',
        RegAddrState: 'WY',
        RegAddrZip: 82801,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '731 N CUSTER ST',
        RegAddrCity: 'SHERIDAN',
        RegAddrState: 'WY',
        RegAddrZip: 82801,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '812 OLYMPUS DR',
        RegAddrCity: 'SHERIDAN',
        RegAddrState: 'WY',
        RegAddrZip: 82801,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '605 E BURKITT ST',
        RegAddrCity: 'SHERIDAN',
        RegAddrState: 'WY',
        RegAddrZip: 82801,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '205 BROCK AVE',
        RegAddrCity: 'SHERIDAN',
        RegAddrState: 'WY',
        RegAddrZip: 82801,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '106 N 9TH ST',
        RegAddrCity: 'SINCLAIR',
        RegAddrState: 'WY',
        RegAddrZip: 82334,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '114 SPURWOOD DR',
        RegAddrCity: 'STAR VLY RNCH',
        RegAddrState: 'WY',
        RegAddrZip: 83127,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '8 BLUE SKY CIR',
        RegAddrCity: 'STORY',
        RegAddrState: 'WY',
        RegAddrZip: 82842,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '508 MAIN ST',
        RegAddrCity: 'SUNDANCE',
        RegAddrState: 'WY',
        RegAddrZip: 82729,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1187 US HIGHWAY 20 N',
        RegAddrCity: 'THERMOPOLIS',
        RegAddrState: 'WY',
        RegAddrZip: 82443,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '312 NOSTRUM RD',
        RegAddrCity: 'THERMOPOLIS',
        RegAddrState: 'WY',
        RegAddrZip: 82443,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1834 E M ST',
        RegAddrCity: 'TORRINGTON',
        RegAddrState: 'WY',
        RegAddrZip: 82240,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '191 LUPINE DR',
        RegAddrCity: 'TORRINGTON',
        RegAddrState: 'WY',
        RegAddrZip: 82240,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '520 PARKS RD',
        RegAddrCity: 'WESTON',
        RegAddrState: 'WY',
        RegAddrZip: 82731,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '11 LORETTA DR',
        RegAddrCity: 'WHEATLAND',
        RegAddrState: 'WY',
        RegAddrZip: 82201,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '655 E COLE ST',
        RegAddrCity: 'WHEATLAND',
        RegAddrState: 'WY',
        RegAddrZip: 82201,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '201 14TH ST',
        RegAddrCity: 'WHEATLAND',
        RegAddrState: 'WY',
        RegAddrZip: 82201,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1299 HIGHTOWER RD',
        RegAddrCity: 'WHEATLAND',
        RegAddrState: 'WY',
        RegAddrZip: 82201,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '2690 PIZZA LN',
        RegAddrCity: 'WILSON',
        RegAddrState: 'WY',
        RegAddrZip: 83014,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '6475 W HECK OF A HILL RD',
        RegAddrCity: 'WILSON',
        RegAddrState: 'WY',
        RegAddrZip: 83014,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '808 GRACE AVE',
        RegAddrCity: 'WORLAND',
        RegAddrState: 'WY',
        RegAddrZip: 82401,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1109 RUSSELL AVE',
        RegAddrCity: 'WORLAND',
        RegAddrState: 'WY',
        RegAddrZip: 82401,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1100 CULBERTSON AVE',
        RegAddrCity: 'WORLAND',
        RegAddrState: 'WY',
        RegAddrZip: 82401,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '820 SWAN PL',
        RegAddrCity: 'WORLAND',
        RegAddrState: 'WY',
        RegAddrZip: 82401,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '1215 SOUTH LN',
        RegAddrCity: 'WORLAND',
        RegAddrState: 'WY',
        RegAddrZip: 82401,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '320 SHADOW HILL LN',
        RegAddrCity: 'WRIGHT',
        RegAddrState: 'WY',
        RegAddrZip: 82732,
        CongressionalDistrict: 0,
      },
      {
        RegAddrLine1: '125 OLD FAITHFUL',
        RegAddrCity: 'YELLOWSTONE NATIONAL PARK',
        RegAddrState: 'WY',
        RegAddrZip: 82190,
        CongressionalDistrict: 0,
      },
    ],
  },
];
