module.exports = [
  {
    state: 'AL',
    stateName: 'Alabama',
    districts: [
      'Alabama 01',
      'Alabama 02',
      'Alabama 03',
      'Alabama 04',
      'Alabama 05',
      'Alabama 06',
      'Alabama 07',
    ],
    areaCodes: [205, 251, 256, 334, 938],
  },
  {
    state: 'AK',
    stateName: 'Alaska',
    districts: ['Alaska'],
    areaCodes: [907],
  },
  {
    state: 'AZ',
    stateName: 'Arizona',
    districts: [
      'Arizona 01',
      'Arizona 02',
      'Arizona 03',
      'Arizona 04',
      'Arizona 05',
      'Arizona 06',
      'Arizona 07',
      'Arizona 08',
      'Arizona 09',
    ],
    areaCodes: [480, 520, 602, 623, 928],
  },
  {
    state: 'AR',
    stateName: 'Arkansas',
    districts: ['Arkansas 01', 'Arkansas 02', 'Arkansas 03', 'Arkansas 04'],
    areaCodes: [479, 501, 870],
  },
  {
    state: 'CA',
    stateName: 'California',
    districts: [
      'California 01',
      'California 02',
      'California 03',
      'California 04',
      'California 05',
      'California 06',
      'California 07',
      'California 08',
      'California 09',
      'California 10',
      'California 11',
      'California 12',
      'California 13',
      'California 14',
      'California 15',
      'California 16',
      'California 17',
      'California 18',
      'California 19',
      'California 20',
      'California 21',
      'California 22',
      'California 23',
      'California 24',
      'California 25',
      'California 26',
      'California 27',
      'California 28',
      'California 29',
      'California 30',
      'California 31',
      'California 32',
      'California 33',
      'California 34',
      'California 35',
      'California 36',
      'California 37',
      'California 38',
      'California 39',
      'California 40',
      'California 41',
      'California 42',
      'California 43',
      'California 44',
      'California 45',
      'California 46',
      'California 47',
      'California 48',
      'California 49',
      'California 50',
      'California 51',
      'California 52',
      'California 53',
    ],
    areaCodes: [
      209,
      213,
      310,
      323,
      408,
      415,
      424,
      442,
      510,
      530,
      559,
      562,
      619,
      626,
      650,
      657,
      661,
      669,
      707,
      714,
      747,
      760,
      805,
      818,
      831,
      858,
      909,
      916,
      925,
      949,
      951,
    ],
  },
  {
    state: 'CO',
    stateName: 'Colorado',
    districts: [
      'Colorado 01',
      'Colorado 02',
      'Colorado 03',
      'Colorado 04',
      'Colorado 05',
      'Colorado 06',
      'Colorado 07',
    ],
    areaCodes: [303, 719, 720, 970],
  },
  {
    state: 'CT',
    stateName: 'Connecticut',
    districts: [
      'Connecticut 01',
      'Connecticut 02',
      'Connecticut 03',
      'Connecticut 04',
      'Connecticut 05',
    ],
    areaCodes: [203, 475, 860],
  },
  {
    state: 'DE',
    stateName: 'Delaware',
    districts: ['Delaware'],
    areaCodes: [302],
  },
  {
    state: 'FL',
    stateName: 'Florida',
    districts: [
      'Florida 01',
      'Florida 02',
      'Florida 03',
      'Florida 04',
      'Florida 05',
      'Florida 06',
      'Florida 07',
      'Florida 08',
      'Florida 09',
      'Florida 10',
      'Florida 11',
      'Florida 12',
      'Florida 13',
      'Florida 14',
      'Florida 15',
      'Florida 16',
      'Florida 17',
      'Florida 18',
      'Florida 19',
      'Florida 20',
      'Florida 21',
      'Florida 22',
      'Florida 23',
      'Florida 24',
      'Florida 25',
      'Florida 26',
      'Florida 27',
    ],
    areaCodes: [
      239,
      305,
      321,
      352,
      386,
      407,
      561,
      727,
      754,
      772,
      786,
      813,
      850,
      863,
      904,
      941,
      954,
    ],
  },
  {
    state: 'GA',
    stateName: 'Georgia',
    districts: [
      'Georgia 01',
      'Georgia 02',
      'Georgia 03',
      'Georgia 04',
      'Georgia 05',
      'Georgia 06',
      'Georgia 07',
      'Georgia 08',
      'Georgia 09',
      'Georgia 10',
      'Georgia 11',
      'Georgia 12',
      'Georgia 13',
      'Georgia 14',
    ],
    areaCodes: [229, 404, 470, 478, 678, 706, 762, 770, 912],
  },
  {
    state: 'HI',
    stateName: 'Hawaii',
    districts: ['Hawaii 01', 'Hawaii 02'],
    areaCodes: [808],
  },
  {
    state: 'ID',
    stateName: 'Idaho',
    districts: ['Idaho 01', 'Idaho 02'],
    areaCodes: [208],
  },
  {
    state: 'IL',
    stateName: 'Illinois',
    districts: [
      'Illinois 01',
      'Illinois 02',
      'Illinois 03',
      'Illinois 04',
      'Illinois 05',
      'Illinois 06',
      'Illinois 07',
      'Illinois 08',
      'Illinois 09',
      'Illinois 10',
      'Illinois 11',
      'Illinois 12',
      'Illinois 13',
      'Illinois 14',
      'Illinois 15',
      'Illinois 16',
      'Illinois 17',
      'Illinois 18',
    ],
    areaCodes: [
      217,
      224,
      309,
      312,
      331,
      618,
      630,
      708,
      773,
      779,
      815,
      847,
      872,
    ],
  },
  {
    state: 'IN',
    stateName: 'Indiana',
    districts: [
      'Indiana 01',
      'Indiana 02',
      'Indiana 03',
      'Indiana 04',
      'Indiana 05',
      'Indiana 06',
      'Indiana 07',
      'Indiana 08',
      'Indiana 09',
    ],
    areaCodes: [219, 260, 317, 574, 765, 812],
  },
  {
    state: 'IA',
    stateName: 'Iowa',
    districts: ['Iowa 01', 'Iowa 02', 'Iowa 03', 'Iowa 04'],
    areaCodes: [319, 515, 563, 641, 712],
  },
  {
    state: 'KS',
    stateName: 'Kansas',
    districts: ['Kansas 01', 'Kansas 02', 'Kansas 03', 'Kansas 04'],
    areaCodes: [316, 620, 785, 913],
  },
  {
    state: 'KY',
    stateName: 'Kentucky',
    districts: [
      'Kentucky 01',
      'Kentucky 02',
      'Kentucky 03',
      'Kentucky 04',
      'Kentucky 05',
      'Kentucky 06',
    ],
    areaCodes: [270, 502, 606, 859],
  },
  {
    state: 'LA',
    stateName: 'Louisiana',
    districts: [
      'Louisiana 01',
      'Louisiana 02',
      'Louisiana 03',
      'Louisiana 04',
      'Louisiana 05',
      'Louisiana 06',
    ],
    areaCodes: [225, 318, 337, 504, 985],
  },
  {
    state: 'ME',
    stateName: 'Maine',
    districts: ['Maine 01', 'Maine 02'],
    areaCodes: [207],
  },
  {
    state: 'MD',
    stateName: 'Maryland',
    districts: [
      'Maryland 01',
      'Maryland 02',
      'Maryland 03',
      'Maryland 04',
      'Maryland 05',
      'Maryland 06',
      'Maryland 07',
      'Maryland 08',
    ],
    areaCodes: [240, 301, 410, 443, 667],
  },
  {
    state: 'MA',
    stateName: 'Massachusetts',
    districts: [
      'Massachusetts 01',
      'Massachusetts 02',
      'Massachusetts 03',
      'Massachusetts 04',
      'Massachusetts 05',
      'Massachusetts 06',
      'Massachusetts 07',
      'Massachusetts 08',
      'Massachusetts 09',
    ],
    areaCodes: [339, 351, 413, 508, 617, 774, 781, 857, 978],
  },
  {
    state: 'MI',
    stateName: 'Michigan',
    districts: [
      'Michigan 01',
      'Michigan 02',
      'Michigan 03',
      'Michigan 04',
      'Michigan 05',
      'Michigan 06',
      'Michigan 07',
      'Michigan 08',
      'Michigan 09',
      'Michigan 10',
      'Michigan 11',
      'Michigan 12',
      'Michigan 13',
      'Michigan 14',
    ],
    areaCodes: [231, 248, 269, 313, 517, 586, 616, 734, 810, 906, 947, 989],
  },
  {
    state: 'MN',
    stateName: 'Minnesota',
    districts: [
      'Minnesota 01',
      'Minnesota 02',
      'Minnesota 03',
      'Minnesota 04',
      'Minnesota 05',
      'Minnesota 06',
      'Minnesota 07',
      'Minnesota 08',
    ],
    areaCodes: [218, 320, 507, 612, 651, 763, 952],
  },
  {
    state: 'MS',
    stateName: 'Mississippi',
    districts: [
      'Mississippi 01',
      'Mississippi 02',
      'Mississippi 03',
      'Mississippi 04',
    ],
    areaCodes: [228, 601, 662, 769],
  },
  {
    state: 'MO',
    stateName: 'Missouri',
    districts: [
      'Missouri 01',
      'Missouri 02',
      'Missouri 03',
      'Missouri 04',
      'Missouri 05',
      'Missouri 06',
      'Missouri 07',
      'Missouri 08',
    ],
    areaCodes: [314, 417, 573, 636, 660, 816],
  },
  {
    state: 'MT',
    stateName: 'Montana',
    districts: ['Montana'],
    areaCodes: [406],
  },
  {
    state: 'NE',
    stateName: 'Nebraska',
    districts: ['Nebraska 01', 'Nebraska 02', 'Nebraska 03'],
    areaCodes: [308, 402, 531],
  },
  {
    state: 'NV',
    stateName: 'Nevada',
    districts: ['Nevada 01', 'Nevada 02', 'Nevada 03', 'Nevada 04'],
    areaCodes: [702, 725, 775],
  },
  {
    state: 'NH',
    stateName: 'New Hampshire',
    districts: ['New Hampshire 01', 'New Hampshire 02'],
    areaCodes: [603],
  },
  {
    state: 'NJ',
    stateName: 'New Jersey',
    districts: [
      'New Jersey 01',
      'New Jersey 02',
      'New Jersey 03',
      'New Jersey 04',
      'New Jersey 05',
      'New Jersey 06',
      'New Jersey 07',
      'New Jersey 08',
      'New Jersey 09',
      'New Jersey 10',
      'New Jersey 11',
      'New Jersey 12',
    ],
    areaCodes: [201, 551, 609, 732, 848, 856, 862, 908, 973],
  },
  {
    state: 'NM',
    stateName: 'New Mexico',
    districts: ['New Mexico 01', 'New Mexico 02', 'New Mexico 03'],
    areaCodes: [505, 575],
  },
  {
    state: 'NY',
    stateName: 'New York',
    districts: [
      'New York 01',
      'New York 02',
      'New York 03',
      'New York 04',
      'New York 05',
      'New York 06',
      'New York 07',
      'New York 08',
      'New York 09',
      'New York 10',
      'New York 11',
      'New York 12',
      'New York 13',
      'New York 14',
      'New York 15',
      'New York 16',
      'New York 17',
      'New York 18',
      'New York 19',
      'New York 20',
      'New York 21',
      'New York 22',
      'New York 23',
      'New York 24',
      'New York 25',
      'New York 26',
      'New York 27',
    ],
    areaCodes: [
      212,
      315,
      347,
      516,
      518,
      585,
      607,
      631,
      646,
      716,
      718,
      845,
      914,
      917,
      929,
    ],
  },
  {
    state: 'NC',
    stateName: 'North Carolina',
    districts: [
      'North Carolina 01',
      'North Carolina 02',
      'North Carolina 03',
      'North Carolina 04',
      'North Carolina 05',
      'North Carolina 06',
      'North Carolina 07',
      'North Carolina 08',
      'North Carolina 09',
      'North Carolina 10',
      'North Carolina 11',
      'North Carolina 12',
      'North Carolina 13',
    ],
    areaCodes: [252, 336, 704, 828, 910, 919, 980, 984],
  },
  {
    state: 'ND',
    stateName: 'North Dakota',
    districts: ['North Dakota'],
    areaCodes: [701],
  },
  {
    state: 'OH',
    stateName: 'Ohio',
    districts: [
      'Ohio 01',
      'Ohio 02',
      'Ohio 03',
      'Ohio 04',
      'Ohio 05',
      'Ohio 06',
      'Ohio 07',
      'Ohio 08',
      'Ohio 09',
      'Ohio 10',
      'Ohio 11',
      'Ohio 12',
      'Ohio 13',
      'Ohio 14',
      'Ohio 15',
      'Ohio 16',
    ],
    areaCodes: [216, 234, 330, 419, 440, 513, 567, 614, 740, 937],
  },
  {
    state: 'OK',
    stateName: 'Oklahoma',
    districts: [
      'Oklahoma 01',
      'Oklahoma 02',
      'Oklahoma 03',
      'Oklahoma 04',
      'Oklahoma 05',
    ],
    areaCodes: [405, 539, 580, 918],
  },
  {
    state: 'OR',
    stateName: 'Oregon',
    districts: [
      'Oregon 01',
      'Oregon 02',
      'Oregon 03',
      'Oregon 04',
      'Oregon 05',
    ],
    areaCodes: [458, 503, 541, 971],
  },
  {
    state: 'PA',
    stateName: 'Pennsylvania',
    districts: [
      'Pennsylvania 01',
      'Pennsylvania 02',
      'Pennsylvania 03',
      'Pennsylvania 04',
      'Pennsylvania 05',
      'Pennsylvania 06',
      'Pennsylvania 07',
      'Pennsylvania 08',
      'Pennsylvania 09',
      'Pennsylvania 10',
      'Pennsylvania 11',
      'Pennsylvania 12',
      'Pennsylvania 13',
      'Pennsylvania 14',
      'Pennsylvania 15',
      'Pennsylvania 16',
      'Pennsylvania 17',
      'Pennsylvania 18',
    ],
    areaCodes: [215, 267, 272, 412, 484, 570, 610, 717, 724, 814, 878],
  },
  {
    state: 'RI',
    stateName: 'Rhode Island',
    districts: ['Rhode Island 01', 'Rhode Island 02'],
    areaCodes: [401],
  },
  {
    state: 'SC',
    stateName: 'South Carolina',
    districts: [
      'South Carolina 01',
      'South Carolina 02',
      'South Carolina 03',
      'South Carolina 04',
      'South Carolina 05',
      'South Carolina 06',
      'South Carolina 07',
    ],
    areaCodes: [803, 843, 864],
  },
  {
    state: 'SD',
    stateName: 'South Dakota',
    districts: ['South Dakota'],
    areaCodes: [605],
  },
  {
    state: 'TN',
    stateName: 'Tennessee',
    districts: [
      'Tennessee 01',
      'Tennessee 02',
      'Tennessee 03',
      'Tennessee 04',
      'Tennessee 05',
      'Tennessee 06',
      'Tennessee 07',
      'Tennessee 08',
      'Tennessee 09',
    ],
    areaCodes: [423, 615, 731, 865, 901, 931],
  },
  {
    state: 'TX',
    stateName: 'Texas',
    districts: [
      'Texas 01',
      'Texas 02',
      'Texas 03',
      'Texas 04',
      'Texas 05',
      'Texas 06',
      'Texas 07',
      'Texas 08',
      'Texas 09',
      'Texas 10',
      'Texas 11',
      'Texas 12',
      'Texas 13',
      'Texas 14',
      'Texas 15',
      'Texas 16',
      'Texas 17',
      'Texas 18',
      'Texas 19',
      'Texas 20',
      'Texas 21',
      'Texas 22',
      'Texas 23',
      'Texas 24',
      'Texas 25',
      'Texas 26',
      'Texas 27',
      'Texas 28',
      'Texas 29',
      'Texas 30',
      'Texas 31',
      'Texas 32',
      'Texas 33',
      'Texas 34',
      'Texas 35',
      'Texas 36',
    ],
    areaCodes: [
      210,
      214,
      254,
      281,
      325,
      346,
      361,
      409,
      430,
      432,
      469,
      512,
      682,
      713,
      737,
      806,
      817,
      830,
      832,
      903,
      915,
      936,
      940,
      956,
      972,
      979,
    ],
  },
  {
    state: 'UT',
    stateName: 'Utah',
    districts: ['Utah 01', 'Utah 02', 'Utah 03', 'Utah 04'],
    areaCodes: [385, 435, 801],
  },
  {
    state: 'VT',
    stateName: 'Vermont',
    districts: ['Vermont'],
    areaCodes: [802],
  },
  {
    state: 'VA',
    stateName: 'Virginia',
    districts: [
      'Virginia 01',
      'Virginia 02',
      'Virginia 03',
      'Virginia 04',
      'Virginia 05',
      'Virginia 06',
      'Virginia 07',
      'Virginia 08',
      'Virginia 09',
      'Virginia 10',
      'Virginia 11',
    ],
    areaCodes: [276, 434, 540, 571, 703, 757, 804],
  },
  {
    state: 'WA',
    stateName: 'Washington',
    districts: [
      'Washington 01',
      'Washington 02',
      'Washington 03',
      'Washington 04',
      'Washington 05',
      'Washington 06',
      'Washington 07',
      'Washington 08',
      'Washington 09',
      'Washington 10',
    ],
    areaCodes: [206, 253, 360, 425, 509],
  },
  {
    state: 'WV',
    stateName: 'West Virginia',
    districts: ['West Virginia 01', 'West Virginia 02', 'West Virginia 03'],
    areaCodes: [304, 681],
  },
  {
    state: 'WI',
    stateName: 'Wisconsin',
    districts: [
      'Wisconsin 01',
      'Wisconsin 02',
      'Wisconsin 03',
      'Wisconsin 04',
      'Wisconsin 05',
      'Wisconsin 06',
      'Wisconsin 07',
      'Wisconsin 08',
    ],
    areaCodes: [262, 414, 534, 608, 715, 920],
  },
  {
    state: 'WY',
    stateName: 'Wyoming',
    districts: ['Wyoming'],
    areaCodes: [307],
  },
];
