import React from 'react';

import createGoogleSearchLink from '../../utils/createGoogleSearchLink/createGoogleSearchLink';

import formatDollars from '../../utils/formatDollars/formatDollars';

const CandidateListItem = (props) => {
  const { candidate } = props;
  const {
    name,
    party,
    office_full,
    receipts,
    state,
    messages,
  } = props.candidate;
  const messageCount = messages ? messages.items.length : '0';
  const googleLink = createGoogleSearchLink([
    name,
    state,
    office_full,
    'volunteer',
  ]);
  candidate.googleLink = googleLink;
  const handleClick = (event) => {
    props.clickHandler(candidate);
  };

  const displayIcons = () => {
    const icons = [];
    if (candidate.imported) {
      icons.push({ title: `tracking ${candidate.name}`, icon: 'check_circle' });
    }
    if (candidate.imported && !candidate.candidate_url) {
      icons.push({
        title: `NO WEBSITE ENTERED FOR ${candidate.name}`,
        icon: 'error',
      });
    }
    return icons.map((icon) => (
      <i
        key={icon.icon}
        className={`material-icons ${icon.icon}`}
        title={icon.title}
      >
        {icon.icon}
      </i>
    ));
  };

  return (
    <div className='candidate-list-item'>
      <p>
        <b className='candidate-name'>
          {displayIcons()}
          {name}
        </b>
        {'  '}({party}){' '}
        <button className='as-link' onClick={handleClick}>
          <i className='material-icons'>open_in_browser</i>
        </button>
      </p>
      <p>
        <em> ${formatDollars(receipts)}</em> | messages: {messageCount}
      </p>
    </div>
  );
};

export default CandidateListItem;
