import React from 'react';
import { AmplifyAuthenticator, AmplifySignOut } from '@aws-amplify/ui-react';

import Menu from '../menu/menu';

const App = () => {
  return (
    <div className={`env-${process.env.REACT_APP_ENV}`}>
      <AmplifyAuthenticator>
        <div>
          <Menu />
          <div className='spacer' />
          <AmplifySignOut />
        </div>
      </AmplifyAuthenticator>
    </div>
  );
};

export default App;
