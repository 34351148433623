export const getFecCandidatesAndSkimbotCandidatesByState = /* GraphQL */ `
  query GetFecCandidatesAndSkimbotCandidatesByState($state: String) {
    candidatesByState(state: $state) {
      items {
        id
        candidate_id
        candidate_url
        supporter {
          name
          address1
          address2
          city
          state
          zip
          email
          phone
          congressional_district
        }
        messages {
          items {
            message_text
          }
        }
      }
    }
    getFecCandidatesByState(state: $state) {
      candidate_id
      name
      party_full
      party
      state
      district
      district_number
      office
      office_full
      candidate_status
      federal_funds_flag
      has_raised_funds
      receipts
    }
  }
`;
